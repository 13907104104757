import { LicenceIds } from 'shared/constants';
import { DayOfWeek, NucleusManagementFrequency, NucleusManagementProvider } from '../../../../../shared/api/api';
import { Option } from 'react-dropdown';
import { SxProps } from '@mui/material';
import { localizedDynamic } from '../../../../../shared/state/i18n/i18n';

export const getProvider = (activeLicences: string[] | undefined) => {
	if (activeLicences === undefined) {
		return 'NoNucleusManagementLicence';
	}
	// Danbred id
	if (activeLicences.find(licenceId => licenceId === LicenceIds.NucleusManagementDanBred)) {
		return NucleusManagementProvider.DanBred;
	}
	// Danish genetics id
	if (activeLicences.find(licenceId => licenceId === LicenceIds.NucleusManagementDanishGenetics)) {
		return NucleusManagementProvider.DanishGenetics;
	}
	if (activeLicences.find(licenceId => licenceId === LicenceIds.NucleusManagementSelfManaged)) {
		return NucleusManagementProvider.SelfManaged;
	}
	return 'NoNucleusManagementLicence';
};

export const timePickerFTextStyle: SxProps = {
	'& .MuiFilledInput-root': {
		backgroundColor: 'transparent !important',
		borderRadius: '4px',
		overflow: 'hidden',
		height: '42px',
		color: 'black',
		fontSize: '16px !important',
		marginRight: '-6px',
		opacity: 1,
		'::before': {
			border: 'none',
		},
		':hover:before': {
			borderBottom: 'none',
		},
		'::after': {
			border: 'none',
		},
	},
	'& .MuiFilledInput-root:hover': {
		backgroundColor: 'white',
		border: 'none',
	},
	'& .MuiFilledInput-root.Mui-focused': {
		backgroundColor: 'white',
	},
	'& .MuiFilledInput-input': {
		textAlign: 'end',
		paddingTop: '0',
		paddingBottom: '0',
		fontSize: '16px !important',
	},
};

export const dayOfWeekOptions: Option[] = (Object.keys(DayOfWeek) as Array<keyof typeof DayOfWeek>).map(key => ({
	label: localizedDynamic(key),
	value: key,
}));

export const getDayOfWeekOptions = () => {
	return (Object.keys(DayOfWeek) as Array<keyof typeof DayOfWeek>).map(key => ({
		label: localizedDynamic(key),
		value: key,
	}));
};

export const getFrequencyOptions = () => {
	return (Object.keys(NucleusManagementFrequency) as Array<keyof typeof NucleusManagementFrequency>).map(key => ({
		label: localizedDynamic(key),
		value: key,
	}));
};

export const getCEFNFrequencyOptions = () => {
	return (Object.keys(NucleusManagementFrequency) as Array<keyof typeof NucleusManagementFrequency>)
		.filter(key => key !== 'Monthly' && key !== 'Manual')
		.map(key => ({
			label: localizedDynamic(key),
			value: key,
		}));
};

// Used to make time pickers only accept every 15 minutes
export const shouldDisableTime = (timeValue, clockType) => {
	return clockType === 'minutes' && timeValue % 15 !== 0;
};

export const nucleusManagementLicences = [
	LicenceIds.NucleusManagementDanishGenetics,
	LicenceIds.NucleusManagementDanBred,
	LicenceIds.NucleusManagementSelfManaged,
];
