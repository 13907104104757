import { Action } from 'redux';
import { Diagnose, IDiagnose } from 'shared/api/api';
import { removeValueFromArray, upsertValueInArray } from 'shared/helpers/reducer-helpers';
import { SyncableInitialState } from 'shared/state/models/syncable';
import { SharedAppState } from 'shared/state/store.shared';
import { isType } from 'typescript-fsa';
import { siteChange } from '../profile/actions';
import { getSyncModelData } from '../sync/actions';
import { getImage, getSyncData, saveDiagnose, saveImage } from './actions';
import { DiagnoseState } from './types';
export const initialDiagnoseCategoryState: DiagnoseState = {
	...SyncableInitialState,
	diagnoseImages: [],
	diagoseImageUpdates: [],
	finishedSaving: false,
};

const diagnoseReducer = (state: DiagnoseState = initialDiagnoseCategoryState, action: Action): DiagnoseState => {
	if (isType(action, getSyncData.started)) {
		state = { ...state, syncInProgress: true };
	}

	if (isType(action, getSyncData.done)) {
		if (action.payload.result && action.payload.result.datas && action.payload.result.datas.length) {
			let arr1 = action.payload.result.datas.map(e => e.id);
			let arr2 = state.entities.map(e => e.id);
			let difference = arr1.filter(x => !arr2.includes(x)).concat(arr2.filter(x => !arr1.includes(x)));
			if (difference.length > 0) {
				state = {
					...state,
					entities: action.payload.result.datas,
					lastSyncDate: action.payload.result.syncTime!,
				};
			}
		}

		state = {
			...state,

			syncInProgress: false,
		};
	}

	if (isType(action, getSyncModelData.done)) {
		if (
			action.payload.result.diagnoses &&
			action.payload.result.diagnoses.datas &&
			action.payload.result.diagnoses.datas.length
		) {
			let arr1 = action.payload.result.diagnoses.datas.map(e => e.id);
			let arr2 = state.entities.map(e => e.id);
			let difference = arr1.filter(x => !arr2.includes(x)).concat(arr2.filter(x => !arr1.includes(x)));
			if (difference.length > 0) {
				state = {
					...state,
					entities: action.payload.result.diagnoses.datas,
					lastSyncDate: action.payload.result.diagnoses.syncTime!,
				};
			}
		}

		state = {
			...state,

			syncInProgress: false,
		};
	}

	if (isType(action, getSyncData.failed)) {
		state = { ...state, syncInProgress: false };
	}

	if (isType(action, saveDiagnose.started)) {
		state = { ...state, updates: upsertValueInArray(state.updates, action.payload), finishedSaving: false };
	}

	if (isType(action, saveDiagnose.failed)) {
		if (action.payload.error.code === 500) {
			state = {
				...state,
				updates: removeValueFromArray(state.updates, action.payload.params.id!),
				finishedSaving: false,
			};
		}

		return state;
	}

	if (isType(action, saveDiagnose.done)) {
		if (!action.payload.result.isDeleted) {
			state = { ...state, entities: upsertValueInArray(state.entities, action.payload.result) };
		} else {
			state = { ...state, entities: removeValueFromArray(state.entities, action.payload.result.id!) };
		}
		state = {
			...state,
			updates: removeValueFromArray(state.updates, action.payload.result.id!),
		};

		if (
			(!action.payload.params.isDeleted && !action.payload.result.isDeleted) ||
			(action.payload.params.isDeleted === action.payload.result.isDeleted &&
				action.payload.params.animalKinds &&
				action.payload.result.animalKinds &&
				action.payload.params.animalKinds.length === action.payload.result.animalKinds.length)
		) {
			state = { ...state, finishedSaving: true };
		}
		return state;
	}

	if (isType(action, saveImage.started)) {
		state = { ...state, updates: upsertValueInArray(state.diagoseImageUpdates, action.payload) as IDiagnose[] };
	}

	if (isType(action, saveImage.done)) {
		state = { ...state, diagnoseImages: upsertValueInArray(state.diagnoseImages, action.payload.params) };
		state = {
			...state,
			updates: removeValueFromArray(state.diagoseImageUpdates, action.payload.result) as IDiagnose[],
		};
	}

	if (isType(action, getImage.done)) {
		if (action.payload.result) {
			state = { ...state, diagnoseImages: upsertValueInArray(state.diagnoseImages, action.payload.result) };
		}
	}

	if (isType(action, siteChange.done)) {
		state = initialDiagnoseCategoryState;
	}

	//Ensure Date objects are in fact date and not strings - redux-persist serializes dates to string but dont' deserialize to dates again
	if (action.type === 'persist/REHYDRATE') {
		let a = ((action as any) as { payload: SharedAppState; key: string }) as any;

		if (a.key === 'root') {
			let entities = new Array<Diagnose>();
			let updates = new Array<Diagnose>();
			let lastSyncDate = new Date(-8640000000000000);

			if (a.payload && a.payload.diagnose && a.payload.diagnose.entities) {
				entities = a.payload.diagnose.entities.map((dt: IDiagnose) => {
					let ndt = Diagnose.fromJS({});
					ndt.init(dt);
					return ndt;
				});
			}

			if (a.payload && a.payload.diagnose && a.payload.diagnose.updates) {
				updates = a.payload.diagnose.updates.map((dt: IDiagnose) => {
					let ndt = Diagnose.fromJS({});
					ndt.init(dt);
					return ndt;
				});
			}

			if (a.payload && a.payload.diagnose && a.payload.diagnose.lastSyncDate) {
				lastSyncDate = new Date(a.payload.diagnose.lastSyncDate);
			}

			state = { ...state, entities, updates, lastSyncDate };
		}
	}

	return state;
};

export default diagnoseReducer;
