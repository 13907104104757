import { Dispatch } from 'redux';
import { IAverted, IPregnancyEvent, WeaningListSetting, WorkListType } from 'shared/api/api';
import { ILocationModel } from 'shared/helpers/location-helper';
import { GetLocations } from 'shared/state/ducks/locations/operations';
import * as matingBatchOperation from 'shared/state/ducks/mating-batch/operations';
import * as pregnancyOperation from 'shared/state/ducks/pregnancy-events/operations';
import { DeleteLastPregnancyEvent, SavePregnancyEvent } from 'shared/state/ducks/pregnancy-events/operations';
import * as stemAnimalOperation from 'shared/state/ducks/stem-animals/operations';
import { SetCheckedCount, SetSowsCount } from 'shared/state/ducks/stem-animals/operations';
import * as validationSetupOperation from 'shared/state/ducks/validation-setup/operations';
import { GetSyncData } from 'shared/state/ducks/work-list-settings/operations';
import { SharedAppState } from 'shared/state/store.shared';
import * as GeneralSettingsOperations from 'shared/state/ducks/general-settings/operations';
import { memoizeLocation } from 'shared/helpers/memoize-getters/memoize-getters';
export function setRealTotalWeight(weaning: IAverted) {
	let weaningCopy = { ...weaning };
	weaningCopy.totalWeight =
		weaningCopy && weaningCopy.totalWeight && weaningCopy.numAlive
			? weaningCopy.totalWeight * weaningCopy.numAlive
			: 0;
	return weaningCopy;
}

export const WeaningWorkListMapStateToProps = (state: SharedAppState) => ({
	workListSetting: state.workListSettings.entities.find(
		setting => setting.type === WorkListType.WeaningListSetting,
	) as WeaningListSetting,
	pregnancyEvents: state.pregnancyEvents.entities,
	matingBatches: state.matingBatches.entities,
	validationSetup: state.validationSetup.entity,
	stemAnimals: state.stemAnimals.entities,
	siteId: state.profile.active!.siteId,
	locations: memoizeLocation(
		state.locations.buildings,
		state.locations.sections,
		state.locations.pens,
		state.locations.valves,
	),
	defaultWeightWeaning: state.generalSettings.entity.defaultWeightWeaning,
	generalSettings: state.generalSettings.entity,
});

export const WeaningWorkListMapDispatchToProps = (dispatch: Dispatch, props: {}) => {
	return {
		setSowsCount: (count: number) => SetSowsCount(count)(dispatch),
		SetCheckedCount: (count: number, reset?: boolean) => SetCheckedCount(count, reset)(dispatch),
		savePregnancyEvent: (pregnancyEvent: IPregnancyEvent) => SavePregnancyEvent(pregnancyEvent)(dispatch),
		deleteLastPregnancyEvent: (pregEvent: IPregnancyEvent) => DeleteLastPregnancyEvent(pregEvent)(dispatch),
		savePregnancyEvents: pregEvents => pregnancyOperation.SavePregnancyEvents(pregEvents)(dispatch),
		getWorkListSettingsBySiteId: () => GetSyncData()(dispatch),
		pregnancyEventGetSyncData: () => pregnancyOperation.GetSyncData()(dispatch),
		stemAnimalGetSyncData: () => stemAnimalOperation.GetSyncData()(dispatch),
		matingBatchGetSyncData: () => matingBatchOperation.GetSyncData()(dispatch),
		validationSetupGetSyncData: () => validationSetupOperation.GetSyncData()(dispatch),
		getLocations: () => GetLocations()(dispatch),
		getGeneralSettings: () => GeneralSettingsOperations.GetSyncData()(dispatch),
	};
};

export interface WeaningPropsFromParent {
	defaultDate: Date;
}

export interface WeaningWorkListState {
	columns: any[];
	loading: boolean;
	columnExte: any[];
	workListData: WeaningWorkListItemData[];
	commitAll: boolean;
	defaultWeight: number | undefined;
	toPenId?: string;
	fromSectionId?: string;
	fromPenId?: string;
	fromLocation: ILocationModel | undefined;
	fromSectionUsesPens: boolean;
	countNumAlive: number | undefined;
	countChecked: number;
}

export interface WeaningWorkListItemData {
	workListData?: WeaningWorkListWeaningData;
	listItemData: WeaningWorkListItem;
}

export type WeaningWorkListProps = ReturnType<typeof WeaningWorkListMapStateToProps> &
	ReturnType<typeof WeaningWorkListMapDispatchToProps> &
	WeaningPropsFromParent;

export class WeaningWorkListItem {
	public animalNumber: string | undefined;
	public stemAnimalId: string | undefined;
	public pregnancyId: string | undefined;
	public siteId: string | undefined;
	public animalEvents: IPregnancyEvent[] = [];
	public prevEvent: IPregnancyEvent | undefined;
	public cycleDays: number | undefined;
	public previosulyCommited: boolean = false;
	public href: string | undefined;
	public isNursingSow: boolean = false;
}

export interface WeaningWorkListWeaningData {
	stemAnimalId: string | undefined;
	isChecked: boolean;
	animalEvents: IPregnancyEvent[];
	prevEvent: IPregnancyEvent | undefined;
	Weaning: IAverted;
	order?: number | undefined;
}
