
import { SlaughterhouseCompany, SlaughterhouseConnectionDto } from 'shared/api/api';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import actionCreatorFactory from 'typescript-fsa';
import * as types from './types';

const actionCreator = actionCreatorFactory(GenActionFactoryName('slaughterhouseConnections'));

export const getSlaughterHouseConnections = actionCreator.async<string | undefined, SlaughterhouseConnectionDto[], { code: number; message: string }>(
    types.SYNC_DATA_SLAUGHTERHOUSECONNECTIONS
);

export const postSlaughterHouseConnections = actionCreator.async<SlaughterhouseConnectionDto, SlaughterhouseConnectionDto>(
    types.SAVE_SLAUGHTERHOUSECONNECTION
);

export const putSlaughterHouseConnections = actionCreator.async<SlaughterhouseConnectionDto, SlaughterhouseConnectionDto, { code: number; message: string }>(
    types.PUT_SLAUGHTERHOUSECONNECTION
);

export const deleteSlaughterHouseConnections = actionCreator.async<SlaughterhouseConnectionDto, SlaughterhouseConnectionDto, { code: number; message: string }>(
    types.DELETE_SLAUGHTERHOUSECONNECTION
);

export const setSlaughterHouseConnection = actionCreator<SlaughterhouseCompany>(
    types.SET_SELECTED_SLAUGHTERHOUSECONNECTION
);