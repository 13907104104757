
import { NativeAppState } from 'native/state/store.native';
import { Store } from 'redux';
import { WebAppState } from 'web/state/store.web';
import { SharedAppState } from './store.shared';


export class StoreContainer {
    public static store: Store<SharedAppState | NativeAppState | WebAppState>;
    public static getState() {
        return StoreContainer.store.getState();
    }
    public static dispatchAction(action: any) {
        return StoreContainer.store.dispatch(action);
    }
}
