import memoize from 'memoize-one';
import moment from 'moment';
import {
	AnimalKind,
	IAfterEventTask,
	IMatingBatch,
	IMoveEvent,
	IPregnancyEvent,
	IStemAnimal,
	ITreatment,
	ITreatmentPlan,
	IVaccineListSetting,
	IWorkListToTreatmentPlan,
	MatingBatch,
	PregnancyState,
	VaccinePregnancyType,
	VaccineSubTask,
} from 'shared/api/api';
import { calculateAgeInWeeks, onlyUniqueFilter } from 'shared/helpers/general-helpers';
import { getStemAnimalPen } from 'shared/helpers/location-helper';
import { getMatingBatch } from 'shared/helpers/matingbatch-helper';
import { getCycleDays } from 'shared/helpers/pregnancy-helper/generel-pregnancy-helpers';
import { sortPregnanciesByDate } from 'shared/helpers/pregnancy-helper/sort-pregnancies';
import { getActiveSows } from 'shared/helpers/stemanimal-helper/stem-animal-input-helper';
import { calculateAnimalKind1, calculateLitter1 } from 'shared/helpers/stemanimal-helper/stemanimal-helper';
import { LocationsState } from 'shared/state/ducks/locations';
import { ScanningListTableItem } from '../scanning-list-helpers/scanning-work-list-item';
import { VaccineSubColumn, VaccineWorkListItem } from './vaccine-worklist-items';
import { SubtractDays, isBetween } from 'shared/helpers/date-helpers';

export function getBatchesToUseVaccine(matingBatches: IMatingBatch[], workListSetting: IVaccineListSetting): { matingBatchesToUse: IMatingBatch[], currentBatch: IMatingBatch } {
	let matingBatchesToUse: IMatingBatch[] = [];
	let currentBatch: IMatingBatch = new MatingBatch();
	if (workListSetting && workListSetting.vaccineSubTasks) {
		let daysSets = new Set(workListSetting.vaccineSubTasks.flatMap(vst => vst.afterEventTasks?.filter(e => e.days).map(aet => aet.days)));
		if (daysSets.size === 1) {
			let matingBatchesSorted = matingBatches.slice().sort((a, b) =>  a.matingPeriodStart!.getTime() - b.matingPeriodStart!.getTime());
			// Add prev and next mating batch
			const newDay = new Date().withoutTime();
			newDay.setDate(newDay.getDate() - daysSets.values().next().value);


			let batchesToUse = matingBatchesSorted.findIndex(
				a =>
					a.matingPeriodStart && a.matingPeriodEnd &&
					a.matingPeriodStart <= newDay &&
					a.matingPeriodEnd >= newDay
			);

			if (batchesToUse >= 0) {
				matingBatchesSorted[batchesToUse - 1] && matingBatchesToUse.push(matingBatchesSorted[batchesToUse - 1]);
				matingBatchesSorted[batchesToUse] && matingBatchesToUse.push(matingBatchesSorted[batchesToUse]);
				matingBatchesSorted[batchesToUse + 1] && matingBatchesToUse.push(matingBatchesSorted[batchesToUse + 1]);
				currentBatch = matingBatchesSorted[batchesToUse];
			}
		}


	}
	return { matingBatchesToUse, currentBatch };
}

export const genereateVaccineWorkListData = memoize(
	(
		pregnancyEvents: { [key: string]: IPregnancyEvent[] },
		matingBatches: IMatingBatch[],
		workListSetting: IVaccineListSetting,
		locations: LocationsState,
		moveEvents: IMoveEvent[],
		workListToTreatmentPlans: IWorkListToTreatmentPlan[],
		treatments: ITreatment[],
		treatmentPlans: ITreatmentPlan[],
		week?: string
	) => {

		if (workListSetting.vaccinePregnancyType === VaccinePregnancyType.AgeYoungFemale) {
			return handleVacctionListAgeYoungFemale(pregnancyEvents,
				workListSetting,
				locations,
				moveEvents,
				workListToTreatmentPlans,
				treatments,
				treatmentPlans, week)
		}

		let workListData: VaccineWorkListItem[] = [];
		const batchNumbersInUse: string[] = [];
		let ActiveSows = getActiveSows();
		const batchesInUse = getBatchesToUseVaccine(matingBatches, workListSetting);

		for (let sow of ActiveSows) {
			const pregnancies = pregnancyEvents[sow.id!];
			if (!pregnancies || pregnancies.length < 1) {
				continue;
			}
			const animalKind = calculateAnimalKind1(sow, pregnancies);
			const sortedPregs = sortPregnanciesByDate(pregnancies);

			if (!sortedPregs || sortedPregs.length < 1) {
				continue;
			}
			const lastestPregnancy = sortedPregs.filter(preg => preg.state !== PregnancyState.Scanned)[0];

			if (
				(workListSetting.vaccinePregnancyType === VaccinePregnancyType.Mating &&
					lastestPregnancy.state !== PregnancyState.Mated) ||
				(workListSetting.vaccinePregnancyType === VaccinePregnancyType.Farrowing &&
					lastestPregnancy.state !== PregnancyState.Farrowing) ||
				(workListSetting.vaccinePregnancyType === VaccinePregnancyType.Weaning &&
					lastestPregnancy.state !== PregnancyState.Averted)
			) {
				continue;
			}

			const litter = calculateLitter1(sow, lastestPregnancy.date!, sortedPregs);
			if (litter > workListSetting.includeLitterTo!) {
				continue;
			}

			const batch = getMatingBatch(sow.id!);

			const cycleDays = getCycleDays(sow.id!);

			if (!cycleDays) {
				continue;
			}

			let itemArray = [
				initColumItem(
					sow,
					cycleDays,
					batch && batch.batchNumber ? batch.batchNumber.toString() : '',
					litter,
					1,
				),
				initColumItem(
					sow,
					cycleDays,
					batch && batch.batchNumber ? batch.batchNumber.toString() : '',
					litter,
					2,
				),
			];

			const treatmentDefIds = getTreatmentDefinitionIdsByVaccineSetting(workListSetting).filter(onlyUniqueFilter);

			// Find treatment plans/ treatments for the animal
			const treatmentPlanIds = treatmentPlans
				.filter(
					treatPlan =>
						treatmentDefIds.includes(treatPlan.treatmentDefinitionId!) && treatPlan.stemAnimalId === sow.id,
				)
				.map(treatPlan => treatPlan.id);
			const treatPlanIdsCurrentLitter = treatments
				.filter(
					treatment =>
						treatmentPlanIds.includes(treatment.treatmentPlanId) &&
						treatment.executedDate &&
						treatment.executedDate.withoutTime() >= lastestPregnancy.date!.withoutTime(),
				)
				.map(treat => treat.treatmentPlanId);

			// Already vaccinated identifiers
			const wltps = workListToTreatmentPlans.filter(workToPlan =>
				treatPlanIdsCurrentLitter.includes(workToPlan.treatmentPlanId),
			);
			itemArray.forEach(item => {
				if (sow && !sow.departureDate) {
					const pen = getStemAnimalPen(sow, moveEvents, locations.pens);
					if (pen) {
						const section = locations.sections.find(x => x.id === pen!.sectionId);

						if (section) {
							item.penString = section && section.usePens ? pen!.name : '';

							const building = locations.buildings.find(x => x.id === pen!.buildingId);

							if (building) {
								item.sectionString = building && building.useSections ? section!.name : '';
								item.buildingString = building.name;
							}
						}
					}
				}




				workListSetting.vaccineSubTasks!.forEach(subTask => {
					subTask.afterEventTasks!.forEach(afterEvent => {
						const newDay = new Date().withoutTime();
						newDay.setDate(newDay.getDate() - afterEvent.days);
						let startDate = moment(newDay).startOf('isoWeek').toDate();
						let endDate = moment(newDay).endOf('isoWeek').toDate();

						const valiDate = isBetween(startDate, lastestPregnancy.date, endDate);

						if (
							validateAnimalKind(animalKind, afterEvent) && //validate kind
							afterEvent.days !== undefined &&
							afterEvent.days >= 0 && //valdiate if afterevent days is set

							batch &&
							(batchesInUse.matingBatchesToUse.length === 0 && valiDate || batchesInUse.matingBatchesToUse.find(b => b.id === batch.id)) &&
							subTask.vaccineDefinitionId //validate if vaccine is chosen
						) {
							if (
								batch.batchNumber !== undefined &&
								!batchNumbersInUse.includes(batch.batchNumber.toString())
							) {
								batchNumbersInUse.push(batch.batchNumber.toString());
							}
							// Check if this vaccine already happened
							if (
								afterEvent.afterEventNumber === item.itemNumber &&
								!wltps.find(
									workToPlan =>
										workToPlan.subTaskIdentifier === item.identifier! + subTask.subTaskNumber! &&
										workToPlan.workListSettingId === workListSetting.id,
								)
							) {
								item.useItem = true;
								const col = createVaccineColumn(subTask, lastestPregnancy.date!, afterEvent.days);
								item.vaccineSubColumns!.push(col);
							}
						}
					});
				});
			});

			// Diagnose and treatment definitions to look for, by worklist settings
			itemArray.forEach(item => {
				if (item.useItem) {
					workListData.push(item);
				}
			});
		}

		return { data: workListData, batchNumbersInUse };
	},
);

const handleVacctionListAgeYoungFemale = (
	pregnancyEvents: { [key: string]: IPregnancyEvent[] },
	workListSetting: IVaccineListSetting,
	locations: LocationsState,
	moveEvents: IMoveEvent[],
	workListToTreatmentPlans: IWorkListToTreatmentPlan[],
	treatments: ITreatment[],
	treatmentPlans: ITreatmentPlan[],
	week?: string
) => {
	let itemArray: VaccineWorkListItem[] = [];
	const now = new Date();
	let activeYoungFemale = getActiveSows().filter(stem => stem.id && (!pregnancyEvents[stem.id] || pregnancyEvents[stem.id].length === 0));

	activeYoungFemale.forEach(youngFemale => {
		const treatmentDefIds = getTreatmentDefinitionIdsByVaccineSetting(workListSetting).filter(onlyUniqueFilter);

		// Find treatment plans/ treatments for the animal
		const treatmentPlanIds = treatmentPlans
			.filter(
				treatPlan =>
					treatmentDefIds.includes(treatPlan.treatmentDefinitionId!) && treatPlan.stemAnimalId === youngFemale.id,
			)
			.map(treatPlan => treatPlan.id);
		const treatPlanIdsCurrentLitter = treatments
			.filter(
				treatment =>
					treatmentPlanIds.includes(treatment.treatmentPlanId) &&
					treatment.executedDate

			)
			.map(treat => treat.treatmentPlanId);

		// Already vaccinated identifiers
		const wltps = workListToTreatmentPlans.filter(workToPlan =>
			treatPlanIdsCurrentLitter.includes(workToPlan.treatmentPlanId),
		);

		let item = new VaccineWorkListItem();

		item.animalNumber = youngFemale.animalNumber;
		item.stemAnimalId = youngFemale.id!;
		item.identifier = youngFemale.id!;
		item.isChecked = false;
		item.vaccineSubColumns = [];
		item.useItem = false;
		const ageInWeeks = +calculateAgeInWeeks(youngFemale.birthDate!, now);
		item.ageInWeeks = isNaN(ageInWeeks) ? 0 : ageInWeeks;

		const pen = getStemAnimalPen(youngFemale, moveEvents, locations.pens);
		if (pen) {
			const section = locations.sections.find(x => x.id === pen!.sectionId);

			if (section) {
				item.penString = section && section.usePens ? pen!.name : '';

				const building = locations.buildings.find(x => x.id === pen!.buildingId);

				if (building) {
					item.sectionString = building && building.useSections ? section!.name : '';
					item.buildingString = building.name;
				}
			}
		}

		// convert string date to date
		const dateInWeek = moment(week!)

		workListSetting.vaccineSubTasks!.forEach(subTask => {

			// Calculates the week 7*ageYoungFemale weeks before dateInWeek.
			// If youngFemale's birthDate is in this week and no matching workToPlan exists, 
			// sets item.useItem to true and adds a new VaccineSubColumn to item.vaccineSubColumns with subTask details and week number.
			const subtractedDays = moment(SubtractDays(dateInWeek.toDate(), 7 * subTask.ageYoungFemale))
			let weekStart = moment(subtractedDays).startOf('isoWeek');
			let weekEnd = moment(subtractedDays).endOf('isoWeek');

			if (subTask.ageYoungFemale && youngFemale.birthDate && isBetween(weekStart, moment(youngFemale.birthDate), weekEnd) &&
				!wltps.find(
					workToPlan =>
						workToPlan.subTaskIdentifier === item.identifier! + subTask.subTaskNumber! &&
						workToPlan.workListSettingId === workListSetting.id,
				)
			) {

				item.useItem = true;
				const col = new VaccineSubColumn();
				col.subTaskNumber = subTask.subTaskNumber;
				col
				col.weekNumber = dateInWeek.isoWeek();
				col.treatmentDefinitionId = subTask.vaccineDefinitionId;
				item.vaccineSubColumns!.push(col);
			}


		});
		if (item.vaccineSubColumns.length > 0) {
			itemArray.push(item)
		}

	})

	return { data: itemArray.filter(i => i.useItem) };
}

function validateAnimalKind(kind: AnimalKind, afterEvent: IAfterEventTask) {
	if ((kind === AnimalKind.Sow && afterEvent.treatSows) || (kind === AnimalKind.Gilt && afterEvent.treatGilt)) {
		return true;
	}
	return false;
}

export function isDistinctPen(data: VaccineWorkListItem[] | ScanningListTableItem[]) {
	const dataAny = data as any[];
	const length = [...new Set(dataAny.map(item => item.penString))].length;
	return length <= 1;
}

export function isDistinctBuilding(data: VaccineWorkListItem[] | ScanningListTableItem[]) {
	const dataAny = data as any[];
	const length = [...new Set(dataAny.map(item => item.buildingString))].length;
	return length <= 1;
}

export function getTreatmentDefinitionIdsByVaccineSetting(workListSetting: IVaccineListSetting) {
	const treatmentDefinitionIds = workListSetting.vaccineSubTasks!.map(subTask => {
		if (subTask.vaccineDefinitionId) {
			return subTask.vaccineDefinitionId;
		}
		return '';
	});

	return treatmentDefinitionIds.filter(id => id !== '');
}

function initColumItem(
	sow: IStemAnimal,
	cycleDays: number,
	batch: string | undefined,
	litter: number,
	itemNumber: number,
) {
	let item = new VaccineWorkListItem();
	item.cycleDays = cycleDays;
	item.animalNumber = sow.animalNumber;
	item.stemAnimalId = sow.id!;
	item.identifier = sow.id! + itemNumber;
	item.isChecked = false;
	item.vaccineSubColumns = [];
	item.batch = batch;
	item.litterNumber = litter;
	item.useItem = false;
	item.itemNumber = itemNumber;

	return item;
}

function getVaccineListWeekDay(eventDate: Date, afterEventDays: number) {
	const newDay = new Date(eventDate.withoutTime());
	newDay.setDate(newDay.getDate() + afterEventDays);

	return moment(newDay).isoWeek();
}

function createVaccineColumn(subTask: VaccineSubTask, lastestPregnancyDate: Date, afterEventDays: number) {
	const col = new VaccineSubColumn();
	col.subTaskNumber = subTask.subTaskNumber;
	const weeekday = getVaccineListWeekDay(lastestPregnancyDate, afterEventDays);

	col.weekNumber = weeekday;
	col.treatmentDefinitionId = subTask.vaccineDefinitionId;
	return col;
}
