import { TotalWeaningTableItem, AnimalToFarrowingTableItem, TotalFarrowingTableItem, TotalMatingTableItem, NeedWeaningTableItem } from "shared/api/api";

import { getWebAnimalColorStyleTable } from "web/web-helpers/general-web-helpers";
import { Sorting } from "@devexpress/dx-react-grid";

export const defaultSortingDashboardModals: Sorting[] = [{ columnName: 'pregnantDays', direction: 'desc' }];
export const defaultSortingDashboardWeaningModals: Sorting[] = [{ columnName: 'animalNr', direction: 'asc' }];
export const getWebAnimalColorStyleDashboard = (item: TotalWeaningTableItem | AnimalToFarrowingTableItem | TotalFarrowingTableItem | TotalMatingTableItem | NeedWeaningTableItem ) => {
	return item && item.sowId? getWebAnimalColorStyleTable(item.sowId) : '';
};

export const getAnimalToFarrowingColorStyle = (item:  AnimalToFarrowingTableItem ) => {
	return item && item.hasFarrowed? 'has-farrowed bold' : '';
};

export const getNumberForDashboardTable = (num: number | undefined ) => {
	return num && num> 0? num: undefined;
};