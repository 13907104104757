import React, { FC, useState } from 'react';
import { Option } from 'react-dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { ISlaughterSiteDataDto, SlaughterSiteDataDto } from 'shared/api/api';
import { getDateString } from 'shared/helpers/date-helpers';
import { memoizeHashmapLocation } from 'shared/helpers/memoize-getters/memoize-getters';
import { UpdateSlaughterDataLocation } from 'shared/state/ducks/slaughter-data/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { FormRow } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { SkioldFormInput } from 'web/view/components/skiold-components/skiold-input/skiold-form-input';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { SlaughterDataDropDown } from '../../skiold-components/finisher-data-dropdown/finisher-data-dropdown';
import { Heading } from '../../utils/heading';

interface PropsFromParent {
	item: ISlaughterSiteDataDto;
	closeModal: () => void;
	checkedSlaughterData?: ISlaughterSiteDataDto[];
}

export const EditSlaughterDataLocation: FC<PropsFromParent> = React.memo((props) => {
	const dispatch = useDispatch();
	const state = useSelector((state: WebAppState) => {
		return {
			locations: state.locations,
			memorizedLoca: memoizeHashmapLocation(state.locations.buildings,
				state.locations.sections,
				state.locations.pens),
			site: state.site.site,
			data: props.checkedSlaughterData
				? props.checkedSlaughterData
				: state.SlaughterDatas.slaughteDatas.filter(
						sd =>
							sd.supplier === props.item.supplier &&
							getDateString(sd.date) === getDateString(props.item.date) &&
							sd.sectionId === props.item.sectionId &&
							sd.buildingId === props.item.buildingId,
				  ),
		};
	});
	const item = useState(props.item);
	const onSave = () => {
		const dataToSave = state.data.map(
			slaughterSiteDataDto =>
				SlaughterSiteDataDto.fromJS({
					...slaughterSiteDataDto,
					sectionId: item[0].sectionId,
					buildingId: item[0].buildingId,
				}),
		) as ISlaughterSiteDataDto[];
		UpdateSlaughterDataLocation(dataToSave, item[0].buildingId, item[0].sectionId)(dispatch);
		props.closeModal();
	};
	const slaughterDataOptionChanged = (option: Option) => {
		const copyOfItem = { ...item[0] };
		copyOfItem.sectionId = option.value;
		item[1](copyOfItem);
	};
	return (
		<ViewWeb className="slaughterPigsDataOverview">
			<Heading text={localized('chooseLocationForMove')}></Heading>
			<ViewWeb className="flexDirectionRow alignItemsCenter">
				<TextWeb className="edit-locations-deliver-date">
					{localized('Date')}: {getDateString(item[0].date)}
				</TextWeb>
				<TextWeb className="text flexOne">{localized('MoveToLocation')} </TextWeb>
				<SlaughterDataDropDown sectionId={item[0].sectionId} onValueChanged={slaughterDataOptionChanged} />
			</ViewWeb>
			<ViewWeb className="button-view-style">
				<SkioldButton theme="grey" title={localized('Close')} onPress={props.closeModal} />
				<SkioldButton itemFromParent={item} title={localized('Save')} onPress={onSave} />
			</ViewWeb>
		</ViewWeb>
	);
});

export const GetFormRows = (
	userIdState: [string, React.Dispatch<React.SetStateAction<string>>],
	passwordState: [string, React.Dispatch<React.SetStateAction<string>>],
) => {
	let formRows = new Array<FormRow>();
	formRows.push(getSlaughterHouseHeader());
	formRows.push(getUserIdRow(userIdState));
	formRows.push(getPasswordRow(passwordState));
	return formRows;
};

function getUserIdRow(userIdState: [string, React.Dispatch<React.SetStateAction<string>>]) {
	const updateValue = (op: string) => userIdState[1](op);
	return {
		name: localized('UserId'),
		component: <SkioldFormInput text={userIdState[0]} onChangeText={updateValue}></SkioldFormInput>,
	};
}
function getPasswordRow(passwordState: [string, React.Dispatch<React.SetStateAction<string>>]) {
	const updateValue = (op: string) => passwordState[1](op);
	return {
		name: localized('password'),
		component: <SkioldFormInput text={passwordState[0]} onChangeText={updateValue}></SkioldFormInput>,
	};
}

function getSlaughterHouseHeader(): FormRow {
	return {
		header: localized('LoginInformationDanishCrown'),
	};
}
