import memoize from 'memoize-one';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { DeadPigletsEvent, IDeadPigletsEvent, IReasonDto, IStemAnimal } from 'shared/api/api';
import DeleteIcon from 'shared/assets/src-assets/png/delete_icon.png';
import { getDateString } from 'shared/helpers/date-helpers';
import { getReasonNameById } from 'shared/helpers/reason-helper/reason-helper';
import { SaveDeadPigletsEvent } from 'shared/state/ducks/dead-piglets-event/operations';
import { localized } from 'shared/state/i18n/i18n';
import { SharedAppState } from 'shared/state/store.shared';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { SkioldButton } from '../../skiold-components/skiold-button/skiold-button';
import SkioldTableGrid from '../../skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { SkioldTouchableOpacity } from '../../skiold-components/skiold-touchable-opacity';
import { Heading } from '../../utils/heading';
import { SkioldImage } from '../../utils/svg/skiold-image';
import { SowListConstants } from '../animal-lists/table-constants';
import './sow-card-modals.scss';
import { UpdateYoungAnimal } from '../../../../../shared/state/ducks/stem-animals/operations';

interface PropsFromParent {
	stemAnimalId: string;
	closeModal: () => void;
}

interface State {}

const getDeadPigletsByAnimalID = memoize((stemAnimalId: string, deadPigletsEvents: IDeadPigletsEvent[]) => {
	return deadPigletsEvents.filter(event => event.stemAnimalId === stemAnimalId);
});

const mapStateToProps = (state: SharedAppState, ownProps: PropsFromParent) => {
	return {
		deadPigletsEvents: getDeadPigletsByAnimalID(ownProps.stemAnimalId, state.deadPigletsEvents.entities),
		reasons: state.reasons.entities,
		language: state.profile.active && state.profile.active.language ? state.profile.active.language : 'en',
		departedStemAnimals: state.stemAnimals.departuredAnimals,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
	return {
		saveDeadPigletsEvent: (deadPigletsEvent: IDeadPigletsEvent) => SaveDeadPigletsEvent(deadPigletsEvent)(dispatch),
		removeYoungAnimalDeparture: (youngAnimal: IStemAnimal) => UpdateYoungAnimal(youngAnimal)(dispatch),
	};
};

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

class SowCardDeadPigletsEvents extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {};
	}

	public render() {
		return (
			<ViewWeb>
				<Heading text={localized('deadPigletsEvent')} />
				<ViewWeb className="sow-card-modals">
					{this.renderTable()}
					{this.props.closeModal && (
						<SkioldButton
							className="btn-container"
							title={localized('Close')}
							onPress={this.props.closeModal}
							theme="grey"
						/>
					)}
				</ViewWeb>
			</ViewWeb>
		);
	}

	private generateData = memoize((deadPigletsEvents: IDeadPigletsEvent[], reasons: IReasonDto[]) => {
		return [];
	});

	public renderTable() {
		return (
			<SkioldTableGrid
				data={this.props.deadPigletsEvents}
				columns={this.generateColumns}
				ColumnExtensions={this.getColumnExtenstions}
				filterable={false}
				tableKey={'sowCardLardWidths2'}
				containerClassName={'sow-card-dead-piglets-table'}
			/>
		);
	}

	private getColumnExtenstions = [
		{
			columnName: 'date',
			width: SowListConstants.dateWidth,
		},
		{
			columnName: 'deadPiglets',
			width: SowListConstants.dateWidth,
		},
		{
			columnName: 'reason',
			width: SowListConstants.dateWidth,
		},
		{
			columnName: 'youngAnimalIdNumber',
			width: SowListConstants.dateWidth,
		},
		{
			columnName: 'delete',
			width: SowListConstants.iconWidth,
		},
	];

	private generateColumns = [
		{
			name: 'date',
			title: localized('Date'),
			sortable: false,
			filterable: false,
			getCellValue: (item: DeadPigletsEvent) => getDateString(item.registrationDate),
		},
		{
			name: 'deadPiglets',
			title: localized('amount'),
			sortable: false,
			filterable: false,
		},
		{
			name: 'reason',
			title: localized('reason'),
			sortable: false,
			filterable: false,
			getCellValue: (item: DeadPigletsEvent) =>
				getReasonNameById(item.reasonId, this.props.language, this.props.reasons),
		},
		{
			name: 'youngAnimalIdNumber',
			title: localized('idNumber'),
			sortable: false,
			filterable: false,
		},
		{
			name: 'delete',
			title: ' ',
			sortable: false,
			filterable: false,
			getCellValue: (item: DeadPigletsEvent) => (
				<SkioldTouchableOpacity
					onPress={() => {
						this.deleteDeadPigletsEvent(item.id!);
					}}
				>
					<SkioldImage
						width={SowListConstants.iconSVGWidth}
						height={SowListConstants.iconSVGWidth}
						imageData={DeleteIcon}
					/>
				</SkioldTouchableOpacity>
			),
		},
	];
	private deleteDeadPigletsEvent = (deadPigletId: string) => {
		let deadPigletsEvent = this.props.deadPigletsEvents.find(lse => lse.id === deadPigletId);
		if (deadPigletsEvent) {
			deadPigletsEvent.isDeleted = true;
			this.props.saveDeadPigletsEvent(deadPigletsEvent);

			if (deadPigletsEvent.youngAnimalIdNumber === undefined) {
				return;
			}
			const youngAnimal = this.props.departedStemAnimals.find(
				animal => animal.idNumber !== null && animal.idNumber === deadPigletsEvent?.youngAnimalIdNumber,
			) as IStemAnimal;

			if (youngAnimal) {
				youngAnimal.departureDate = undefined;
				youngAnimal.departureReasonId = undefined;
				youngAnimal.departureType = undefined;
				this.props.removeYoungAnimalDeparture(youngAnimal);
			}
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SowCardDeadPigletsEvents);
