import ObjectID from 'bson-objectid';
import React from 'react';
import { Option } from 'react-dropdown';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
	Equipment,
	IBuilding,
	IPen,
	IProcessEquipment,
	IProcessEquipmentData,
	ISection,
	IUnitToPen,
	UnitData,
	UnitToPen,
} from 'shared/api/api';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { deepCopy2 } from 'shared/helpers/general-helpers';
import { NaturalSort } from 'shared/helpers/natural-sort';
import {
	GetProcessEquipmentData,
	SaveProcessEquipmentDataFromUnitToPenMap,
} from 'shared/state/ducks/process-equipment-data/operations';
import * as UnitsToPenOperations from 'shared/state/ducks/unit-to-pen/operations';
import { SaveUnitToPenLocationRelation } from 'shared/state/ducks/unit-to-pen/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import SkioldFormPenPicker from 'web/view/components/location/location-picker/skiold-form-pen-picker';
import { ShowConfirmAlert } from '../skiold-alert/skiold-alert';
import { SkioldDropdown } from '../skiold-components/skiold-dropdown/skiold-dropdown';
import SkioldTableGrid, {
	SkioldTableGrid as SkioldTableRef,
} from '../skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { GroupedHeader } from '../skiold-components/skiold-table/skiold-table-grid/skiold-table-grid-grouped-header';
import { ViewWeb } from '../utils/web-view';
import './location-relation-table.scss';
import { UnitsOnProcessEquipmentItem } from './LocationRelation-helper';
import { ExtractLocationCode } from 'shared/helpers/process-equipment-helper/process-equipment-helper';

const mapStateToProps = (state: WebAppState) => {
	return {
		buildings: state.locations.buildings,
		sections: state.locations.sections,
		pens: state.locations.pens,
		processUnits: state.processUnits.processUnits,
		unitsToPen: state.unitToPenData.data,
		allProcessEquipmentDatas: state.processEquipmentData.entities,
		siteId: state.profile.active ? state.profile.active.siteId : undefined,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	saveItem: (item: IUnitToPen) => SaveUnitToPenLocationRelation(item)(dispatch),
	saveProcessequipmentData: async (item: IProcessEquipmentData) =>
		await SaveProcessEquipmentDataFromUnitToPenMap(item),
	getSyncData: async () => (await GetProcessEquipmentData())(dispatch),
	getunitsData: () => UnitsToPenOperations.GetSyncData()(dispatch),
});

export enum SaveCloseDontClose {
	saveAndClose,
	close,
	dontClose,
}

interface PropsFromParent {
	processEquipment: IProcessEquipment | undefined;
	save: (method: (calledFromSave: boolean) => Promise<boolean>) => void;
	setAutomaticPensDone: () => void;
	automaticSetPens: boolean;
	building: IBuilding | undefined;
	section: ISection | undefined;
	loading: boolean;
	processEquipmentData: IProcessEquipmentData[];
	checkForUnsavedChanges?: (method: () => Promise<SaveCloseDontClose>) => void;
}
type UnitsOnProcessEquipmentProps = ReturnType<typeof mapStateToProps> &
	ReturnType<typeof mapDispatchToProps> &
	PropsFromParent;

interface UnitsOnProcessEquipmentState {
	columns: any[];
	columnExtension: any[];
	locationRelationItems: UnitsOnProcessEquipmentItem[];
	locationRelationItemsCopy: UnitsOnProcessEquipmentItem[];
	groupedHeaders: GroupedHeader[];
}

export class UnitsOnProcessEquipmentTable extends React.PureComponent<
	UnitsOnProcessEquipmentProps,
	UnitsOnProcessEquipmentState
> {
	public SkioldTableRef: SkioldTableRef | undefined;
	constructor(props: UnitsOnProcessEquipmentProps) {
		super(props);
		this.state = {
			columns: this.generateColumns(true),
			columnExtension: this.generateColumnsExtensions(),
			locationRelationItems: [],
			locationRelationItemsCopy: [],
			groupedHeaders: this.generateGroupedHeaders(),
		};
	}

	public static getDerivedStateFromProps(
		nextProps: UnitsOnProcessEquipmentProps,
		prevState: UnitsOnProcessEquipmentState,
	): Partial<UnitsOnProcessEquipmentState> {
		if (nextProps.loading === true) {
			return {
				locationRelationItems: [],
				locationRelationItemsCopy: [],
			};
		} else if (
			(prevState.locationRelationItems.length === 0 && nextProps.loading === false) ||
			(nextProps.loading === false && nextProps.automaticSetPens)
		) {
			let dataFromProps = UnitsOnProcessEquipmentTable.getData(
				nextProps.processEquipmentData,
				nextProps.unitsToPen,
				nextProps.processEquipment,
				nextProps.pens,
				nextProps.automaticSetPens,
				nextProps.sections,
				nextProps.allProcessEquipmentDatas,
				nextProps.building,
				prevState.locationRelationItems,
			);
			let prevLocationItems = deepCopy2(prevState.locationRelationItems);
			dataFromProps.forEach(item => {
				let prevIndex = prevLocationItems.findIndex(tableItem => tableItem.id === item.id);
				if (!prevLocationItems[prevIndex]) {
					prevLocationItems.push(item);
				} else if (
					item.unitToPen &&
					prevIndex >= 0 &&
					prevLocationItems[prevIndex] &&
					prevLocationItems[prevIndex].unitToPen !== undefined
				) {
					let prevLocationItem = prevLocationItems[prevIndex];

					if (prevLocationItem.unitToPen) {
						prevLocationItem.unitToPen.forEach((utp, utpIndex) => {
							if (
								item.unitToPen &&
								utp.penId !== item.unitToPen[utpIndex].penId &&
								item.unitToPen[utpIndex].penId !== undefined
							) {
								let unitToPens = [...prevLocationItem.unitToPen!];
								unitToPens[utpIndex] = { ...item.unitToPen![utpIndex] };
								prevLocationItem.unitToPen = [...unitToPens];
								prevLocationItems[prevIndex] = prevLocationItem;
							}
						});
					}
				}
			});
			if (nextProps.automaticSetPens && nextProps.setAutomaticPensDone) {
				nextProps.setAutomaticPensDone();
			}

			return {
				locationRelationItems: prevLocationItems,
				locationRelationItemsCopy:
					prevState.locationRelationItemsCopy.length === 0
						? deepCopy2(dataFromProps)
						: prevState.locationRelationItemsCopy,
			};
		}
		return {};
	}

	public componentDidMount() {
		this.props.save(async (calledFromSave: boolean) => await this.save(calledFromSave));
		if (this.props.checkForUnsavedChanges) {
			this.props.checkForUnsavedChanges(this.checkForUnsavedChanges);
		}
	}

	public checkForUnsavedChanges = async () => {
		if (
			this.props.section !== undefined &&
			this.state.locationRelationItems.find(
				lri =>
					this.props.section &&
					lri.sectionId !== this.props.section.id &&
					lri.unitToPen &&
					lri.unitToPen.find(utp => utp.penId && utp.penId.length > 0) === undefined,
			) !== undefined
		) {
			if (
				await ShowConfirmAlert(
					localized(
						ExceptionMessage.VALIDATION_WARNING_SECTION_NOT_BELONGING_TO_SECTION_SELECTED_HAS_NON_SETTED_PENS,
					),
				)
			) {
				return SaveCloseDontClose.dontClose;
			} else {
				return SaveCloseDontClose.close;
			}
		}
		let returnStatement = SaveCloseDontClose.close;
		for (let index = 0; index < this.state.locationRelationItems.length; index++) {
			const item = this.state.locationRelationItems[index];
			if (item.buildingId && item.unitToPen && item.sectionId && this.props.processEquipment) {
				let itemCopy = this.state.locationRelationItemsCopy.find(a => item.id === a.id);
				if (itemCopy) {
					for (let index = 0; index < item.unitToPen.length; index++) {
						const utp = item.unitToPen[index];
						const utpCopy = itemCopy.unitToPen![index];
						if (
							utp.processEquipmentDataId &&
							utp.unitGroupId &&
							utp.siteId &&
							utp.penId !== utpCopy.penId
						) {
							if (await ShowConfirmAlert(localized('unsavedRegisteredData'))) {
								return SaveCloseDontClose.saveAndClose;
							} else {
								return SaveCloseDontClose.close;
							}
						}
					}
				}
			}
		}

		return returnStatement;
	};

	private async save(calledFromSave: boolean) {
		let ArrayOfPromises = Array<Promise<void>>();
		let processEquipmentDataArray: IProcessEquipmentData[] = [];
		for (let peq of this.props.processEquipmentData.filter(a => !a.isFromImport)) {
			peq.unitCodes = [];
			processEquipmentDataArray.push(peq);
		}
		this.state.locationRelationItems.forEach(item => {
			let processEquipmentDataArrayIndex = processEquipmentDataArray.findIndex(
				peq =>
					peq.sectionId === item.sectionId &&
					peq.buildingId === item.buildingId &&
					peq.code === item.locationCode,
			);
			if (item.unitToPen && item.unitToPen.find(utp => utp.penId !== undefined) !== undefined) {
				let itemCopy = this.state.locationRelationItemsCopy.find(a => item.id === a.id);
				if (
					itemCopy &&
					itemCopy.processEquipmentUnitCode &&
					item.sectionId !== itemCopy.sectionId &&
					item.unitToPen &&
					item.unitToPen.find(utp => utp.penId && utp.penId.length >= 1) === undefined
				) {
					processEquipmentDataArrayIndex = processEquipmentDataArray.findIndex(
						peq =>
							itemCopy &&
							peq.sectionId === itemCopy.sectionId &&
							peq.buildingId === itemCopy.buildingId &&
							peq.code === itemCopy.locationCode,
					);
					if (processEquipmentDataArray[processEquipmentDataArrayIndex].unitCodes) {
						processEquipmentDataArray[processEquipmentDataArrayIndex].unitCodes!.push(
							itemCopy.processEquipmentUnitCode,
						);
					}
				}
				if (
					processEquipmentDataArrayIndex >= 0 &&
					processEquipmentDataArray[processEquipmentDataArrayIndex].unitCodes &&
					item.processEquipmentUnitCode &&
					item.unitToPen.find(utp => utp.penId && utp.penId.length > 0)
				) {
					processEquipmentDataArray[processEquipmentDataArrayIndex].unitCodes!.push(
						item.processEquipmentUnitCode,
					);
				} else if (
					item.processEquipmentUnitCode &&
					processEquipmentDataArray.find(
						existingPeq =>
							existingPeq.buildingId === item.buildingId &&
							existingPeq.sectionId === item.sectionId &&
							item.locationCode === existingPeq.code,
					) === undefined &&
					item.unitToPen.find(utp => utp.penId && utp.penId.length > 0)
				) {
					this.CreateNewProcessEquipmentDataIfNotAlreadyExisting(item, processEquipmentDataArray);
				}
			}
		});
		for (let index = 0; index < processEquipmentDataArray.length; index++) {
			const peq = processEquipmentDataArray[index];
			processEquipmentDataArray[index].id = await this.props.saveProcessequipmentData(peq);
		}

		this.state.locationRelationItems.forEach(item => {
			if (item.buildingId && item.unitToPen && item.sectionId && this.props.processEquipment) {
				let itemCopy = this.state.locationRelationItemsCopy.find(a => item.id === a.id);
				const peq = processEquipmentDataArray.find(
					peq =>
						peq.sectionId === item.sectionId &&
						peq.buildingId === item.buildingId &&
						peq.code === item.locationCode,
				);

				if (itemCopy && peq) {
					for (let index = 0; index < item.unitToPen.length; index++) {
						const utp = item.unitToPen[index];
						const utpCopy = itemCopy.unitToPen![index];
						if (peq) {
							utp.processEquipmentDataId = peq.id;
						}
						if (
							itemCopy.unitToPen &&
							itemCopy.unitToPen.find(
								utpFromItemCopy =>
									utpFromItemCopy.processEquipmentDataId === utp.processEquipmentDataId ||
									(utp.penId &&
										utp.penId.length > 0 &&
										utpFromItemCopy.processEquipmentDataId !== utp.processEquipmentDataId),
							) !== undefined &&
							utp.processEquipmentDataId &&
							utp.unitGroupId &&
							utp.siteId &&
							utp.penId !== utpCopy.penId
						) {
							ArrayOfPromises.push(this.props.saveItem(utp));
						}
					}
				}
			}
		});
		ArrayOfPromises.push(this.props.getSyncData());
		ArrayOfPromises.push(this.props.getunitsData());
		this.setState({ locationRelationItemsCopy: deepCopy2(this.state.locationRelationItems) });
		if (ArrayOfPromises.length > 0) {
			await Promise.all(ArrayOfPromises);
		}

		if (
			calledFromSave &&
			this.props.section !== undefined &&
			this.state.locationRelationItems.find(
				lri =>
					this.props.section &&
					lri.sectionId !== this.props.section.id &&
					lri.unitToPen &&
					lri.unitToPen.find(utp => utp.penId && utp.penId.length > 0) === undefined,
			) !== undefined &&
			(await ShowConfirmAlert(
				localized(
					ExceptionMessage.VALIDATION_WARNING_SECTION_NOT_BELONGING_TO_SECTION_SELECTED_HAS_NON_SETTED_PENS,
				),
			))
		) {
			return false;
		}
		return true;
	}
	private CreateNewProcessEquipmentDataIfNotAlreadyExisting(
		item: UnitsOnProcessEquipmentItem,
		processEquipmentDataArray: IProcessEquipmentData[],
	) {
		let peq = this.props.allProcessEquipmentDatas.find(
			a =>
				a.sectionId === item.sectionId &&
				a.buildingId === item.buildingId &&
				a.code === item.locationCode &&
				!a.isFromImport,
		);
		if (item.processEquipmentUnitCode) {
			processEquipmentDataArray.push({
				sectionId: item.sectionId,
				buildingId: item.buildingId,
				siteId: this.props.siteId,
				equipmentType: peq
					? peq.equipmentType
					: this.props.processEquipment
					? this.props.processEquipment.equipment
					: undefined,
				code: item.locationCode,
				buildingCode: peq ? peq.buildingCode : item.buildingCode,
				sectionCode: peq ? peq.sectionCode : item.sectionCode,
				id: peq ? peq.id : new ObjectID().toHexString(),
				unitCodes:
					peq && peq.unitCodes
						? peq.unitCodes
								.filter(
									uc =>
										this.state.locationRelationItems.find(
											a =>
												a.processEquipmentUnitCode &&
												a.processEquipmentUnitCode.unitGroupId === uc.unitGroupId &&
												a.unitToPen &&
												a.unitToPen.find(a => a.penId !== undefined) !== undefined,
										) === undefined,
								)
								.concat([item.processEquipmentUnitCode])
						: [item.processEquipmentUnitCode],
			} as IProcessEquipmentData);
		}
	}

	private static getData(
		dataFromProps: IProcessEquipmentData[],
		unitToPens: IUnitToPen[],
		processEquipment: IProcessEquipment | undefined,
		pens: IPen[],
		automaticSetPens: boolean,
		sections: ISection[],
		allProcessEquipmentData: IProcessEquipmentData[],
		building: IBuilding | undefined,
		currentData: UnitsOnProcessEquipmentItem[],
	) {
		let data: UnitsOnProcessEquipmentItem[] = [];
		let dataToRemove: string[] = [];
		let usedPens: IPen[] = [];
		let sortedPens = pens.sort((a, b) => NaturalSort(a.name, b.name));
		if (dataFromProps) {
			let dataFromPropsCopy = dataFromProps.sort((a, b) => NaturalSort(a.isFromImport, b.isFromImport));
			for (let item of dataFromPropsCopy) {
				if (
					item.buildingId &&
					item.sectionId &&
					processEquipment &&
					item.equipmentType === processEquipment.equipment &&
					item.unitCodes
				) {
					let itemFromOtherSection = allProcessEquipmentData
						.filter(
							uc =>
								!uc.isFromImport &&
								uc.sectionId !== item.sectionId &&
								uc.code === item.code &&
								uc.unitCodes &&
								item.equipmentType === uc.equipmentType,
						)
						.map(uc => uc.unitCodes!);

					let unitCodes: UnitData[] = [];
					for (let i = 0; i < itemFromOtherSection.length; i++) {
						if (itemFromOtherSection && itemFromOtherSection[i]) {
							unitCodes = unitCodes.concat(itemFromOtherSection[i]);
						}
					}
					if (unitCodes !== undefined && !item.isFromImport && unitCodes) {
						for (let unitCode of unitCodes) {
							if (unitCode && unitCode.unitGroupId) {
								dataToRemove.push(unitCode.unitGroupId);
							}
						}
					}

					for (let valveData of item.unitCodes) {
						UnitsOnProcessEquipmentTable.InsertDataIntoDataSet(
							valveData,
							item,
							unitToPens,
							processEquipment,
							sortedPens,
							sections,
							data,
							currentData,
							usedPens,
						);
					}
				}
			}
		}
		if (!building) {
			data = data.filter(
				a =>
					a.processEquipmentUnitCode &&
					a.processEquipmentUnitCode.unitGroupId &&
					!dataToRemove.includes(a.processEquipmentUnitCode.unitGroupId),
			);
		}
		data = data.sort((a, b) => NaturalSort(a.unitNumber, b.unitNumber));
		UnitsOnProcessEquipmentTable.setAutomaticPens(automaticSetPens, processEquipment, data, sortedPens, usedPens);
		return data;
	}

	private static setAutomaticPens(
		automaticSetPens: boolean,
		processEquipment: IProcessEquipment | undefined,
		data: UnitsOnProcessEquipmentItem[],
		sortedPens: IPen[],
		usedPens: IPen[],
	) {
		if (automaticSetPens && processEquipment) {
			for (let allDataIndex = 0; allDataIndex < data.length; allDataIndex++) {
				const currentItem = data[allDataIndex];
				let pensInSectionLeft = UnitsOnProcessEquipmentTable.CheckIfPenNumberIsAlreadyInUse(
					sortedPens,
					usedPens,
					currentItem,
				);
				const unitsOnProcessEquipmentItem = data[allDataIndex];
				if (unitsOnProcessEquipmentItem.unitToPen) {
					let pen: IPen | undefined;
					for (let index = 0; index < (processEquipment.equipment === Equipment.Distriwin ? 2 : 1); index++) {
						const unitToPen = unitsOnProcessEquipmentItem.unitToPen[index];
						({ pensInSectionLeft, pen } = UnitsOnProcessEquipmentTable.CheckIfAbleToSetFirstPen(
							index,
							sortedPens,
							unitToPen,
							currentItem,
							pensInSectionLeft,
							pen,
							usedPens,
						));
						pensInSectionLeft = UnitsOnProcessEquipmentTable.CheckIfSecondPenShouldBeSetForDistriwin(
							index,
							processEquipment,
							pen,
							pensInSectionLeft,
							currentItem,
							unitToPen,
							usedPens,
							unitsOnProcessEquipmentItem,
						);
					}
					data[allDataIndex] = unitsOnProcessEquipmentItem;
				}
			}
		}
	}

	private static CheckIfPenNumberIsAlreadyInUse(
		sortedPens: IPen[],
		usedPens: IPen[],
		currentItem: UnitsOnProcessEquipmentItem,
	) {
		return sortedPens.filter(
			sp =>
				usedPens.find(
					up =>
						up.id === sp.id &&
						up.name &&
						sp.name &&
						UnitsOnProcessEquipmentTable.getPenNameNumberOnly(up.name) ===
							UnitsOnProcessEquipmentTable.getPenNameNumberOnly(sp.name),
				) === undefined && sp.sectionId === currentItem.sectionId,
		);
	}

	private static CheckIfAbleToSetFirstPen(
		index: number,
		sortedPens: IPen[],
		unitToPen: IUnitToPen,
		currentItem: UnitsOnProcessEquipmentItem,
		pensInSectionLeft: IPen[],
		pen: IPen | undefined,
		usedPens: IPen[],
	) {
		if (index === 0) {
			const penInUse = sortedPens.find(sp => sp.id === unitToPen.penId && sp.sectionId === currentItem.sectionId);
			if (unitToPen.penId === undefined || penInUse === undefined) {
				let penToUse = pensInSectionLeft.find(pltu => pltu.sectionId === currentItem.sectionId);
				if (penToUse) {
					pensInSectionLeft = pensInSectionLeft.filter(pltu => penToUse && pltu.id !== penToUse.id);
					unitToPen.penId = penToUse.id;
				}
			}
			pen = sortedPens.find(p => unitToPen && p.id === unitToPen.penId); // required to get the pen number needed for validation
			if (pen) {
				usedPens.push(pen);
			}
		}
		return { pensInSectionLeft, pen };
	}

	private static CheckIfSecondPenShouldBeSetForDistriwin(
		index: number,
		processEquipment: IProcessEquipment,
		pen: IPen | undefined,
		pensInSectionLeft: IPen[],
		currentItem: UnitsOnProcessEquipmentItem,
		unitToPen: IUnitToPen,
		usedPens: IPen[],
		unitsOnProcessEquipmentItem: UnitsOnProcessEquipmentItem,
	) {
		if (index === 1 && processEquipment.equipment === Equipment.Distriwin && pen) {
			let penToUse = pensInSectionLeft.find(
				// eslint-disable-next-line
				p =>
					p.name &&
					pen &&
					p.id !== pen.id &&
					pen.name &&
					UnitsOnProcessEquipmentTable.getPenNameNumberOnly(pen.name) ===
						UnitsOnProcessEquipmentTable.getPenNameNumberOnly(p.name),
			);
			if (penToUse) {
				pensInSectionLeft = pensInSectionLeft.filter(
					pltu => penToUse && pltu.id !== penToUse.id && pltu.sectionId === currentItem.sectionId,
				);
				unitToPen.penId = penToUse.id;
				usedPens.push(penToUse);
			}
		}
		if (unitsOnProcessEquipmentItem.unitToPen) {
			unitsOnProcessEquipmentItem.unitToPen[index] = unitToPen;
		}
		return pensInSectionLeft;
	}

	private static InsertDataIntoDataSet(
		valveData: UnitData,
		item: IProcessEquipmentData,
		unitToPens: IUnitToPen[],
		processEquipment: IProcessEquipment,
		sortedPens: IPen[],
		sections: ISection[],
		data: UnitsOnProcessEquipmentItem[],
		currentData: UnitsOnProcessEquipmentItem[],
		usedPens: IPen[],
	) {
		const currentItem = currentData.find(
			a => a.unitToPen && a.unitToPen.find(a => a.unitGroupId === valveData.unitGroupId) !== undefined,
		);
		if (
			valveData.unitCodes &&
			valveData.unitCodes.length !== 0 &&
			data.find(
				a => a.processEquipmentUnitCode && a.processEquipmentUnitCode.unitGroupId === valveData.unitGroupId,
			) === undefined
		) {
			let itemToAdd: UnitsOnProcessEquipmentItem = UnitsOnProcessEquipmentTable.getInitialUnitOnProcessEquipmentItemData(
				valveData,
				item,
				currentItem,
			);
			const utps = unitToPens.filter(a => a.unitGroupId === valveData.unitGroupId);
			if (itemToAdd.unitToPen) {
				for (let index = 0; index < (processEquipment.equipment === Equipment.Distriwin ? 2 : 1); index++) {
					let unitToPen = utps.find(u => u.number === index);
					if (unitToPen !== undefined) {
						let pen = sortedPens.find(p => unitToPen && p.id === unitToPen.penId);
						const sectionInUse = sections.find(a => pen && a.id === pen.sectionId);
						if (sectionInUse && !sectionInUse.usePens) {
							itemToAdd.usesPens = false;
						}
						if ((!currentItem && pen) || currentItem) {
							if (currentItem) {
								pen = sortedPens.find(
									sp =>
										currentItem.unitToPen &&
										currentItem.unitToPen[index] &&
										sp.id === currentItem.unitToPen[index].penId,
								);
								unitToPen.penId = pen ? pen.id : undefined;
							}
							if (pen) {
								usedPens.push(pen);
							}
						}
						itemToAdd.unitToPen.push(unitToPen);
					} else {
						itemToAdd.unitToPen.push({
							id: new ObjectID().toHexString(),
							unitGroupId: valveData.unitGroupId,
							processEquipmentDataId: item.id,
							number: index,
							penId: undefined,
							siteId: item.siteId,
						} as IUnitToPen);
					}
				}
			}
			if (
				itemToAdd.unitToPen &&
				itemToAdd.unitToPen.find(utp => utp.penId && utp.penId.length > 0 && !item.isFromImport) !== undefined
			) {
				data.push(itemToAdd);
			} else if (
				itemToAdd.unitToPen &&
				itemToAdd.unitToPen.find(utp => !utp.penId || (utp.penId && utp.penId.length === 0))
			) {
				data.push(itemToAdd);
			} else if (
				itemToAdd.unitToPen &&
				item.isFromImport &&
				itemToAdd.unitToPen.find(utp => !utp.penId || (utp.penId && utp.penId.length === 0)) === undefined
			) {
				data.push(itemToAdd);
			}
		}
	}

	private static getPenNameNumberOnly(penName: string) {
		return penName.replace(/[^0-9]/g, '');
	}

	private static getInitialUnitOnProcessEquipmentItemData(
		valveData: UnitData,
		item: IProcessEquipmentData,
		currentItem: UnitsOnProcessEquipmentItem | undefined,
	): UnitsOnProcessEquipmentItem {
		return {
			processEquipmentUnitCode: valveData,
			id: valveData.unitGroupId,
			locationCode: item.code,
			buildingCode: item.buildingCode,
			sectionCode: item.sectionCode,
			isFromImport: item.isFromImport,
			unitToPen: [],
			unitNumber: valveData.unit,
			buildingId: item.buildingId,
			sectionId: currentItem ? currentItem.sectionId : item.sectionId,
			usesPens: true,
		};
	}

	public render() {
		let columns = this.generateColumns(false);
		if (this.state.locationRelationItems.find(a => a.unitToPen && a.unitToPen.length >= 2)) {
			columns = this.generateColumns(true);
		}
		return (
			<ViewWeb className="location-relation-table">
				<SkioldTableGrid
					tableKey={'unitOnProcessEquipmentListTable'}
					columns={columns}
					ref={this.setRef}
					data={this.state.locationRelationItems}
					ColumnExtensions={this.state.columnExtension}
					filterable={false}
					showPagination={true}
					groupedColumns={this.generateGroupedHeaders()}
					headerCapitalized={false}
				/>
			</ViewWeb>
		);
	}

	public setRef = (s: any) => {
		if (s) {
			this.SkioldTableRef = s;
		}
	};

	public generateColumnsExtensions() {
		const columnExtensions: any = [];

		return columnExtensions;
	}

	private generateGroupedHeaders = () => {
		const groupedHeader: GroupedHeader[] = [];

		groupedHeader.push({
			title:
				this.props.processEquipment && this.props.processEquipment.equipment
					? (this.props.processEquipment.equipment === Equipment.Distriwin
							? localized('Valves').toUpperCase()
							: localized('Stations').toUpperCase()) +
					  ' ' +
					  (this.props.processEquipment.equipment ? this.props.processEquipment.equipment.toUpperCase() : '')
					: localized('locationsProcessEquipment'),
			children: [{ columnName: 'valve' }, { columnName: 'unitOne' }, { columnName: 'unitTwo' }],
		});
		groupedHeader.push({
			title: localized('locationsSkioldDigital'),
			children: [
				{ columnName: 'buildingSkioldDigital' },
				{ columnName: 'sectionSkioldDigital' },
				{ columnName: 'pen' },
			],
		});
		return groupedHeader;
	};

	public generateColumns = (unitTwo: boolean) => {
		let columns: any = [
			{
				name: 'valve',
				title: this.props.processEquipment
					? this.props.processEquipment.equipment === Equipment.Distriwin
						? localized('ValveNr')
						: localized('StationNumbers')
					: localized('units'),
				sortable: false,
				getCellValue: this.getValveValue,
			},
			{
				name: 'unitOne',
				title: localized('penNr'),
				sortable: false,
				getCellValue: this.getUnitOneCellValue,
			},
		];

		if (unitTwo) {
			columns.push({
				name: 'unitTwo',
				sortable: false,
				title: localized('penNr'),
				getCellValue: this.getUnitTwoCellValue,
			});
		}
		columns = columns.concat([
			{
				name: 'buildingSkioldDigital',
				title: localized('building'),
				sortable: false,
				getCellValue: this.getTableDataBuildingText,
			},
			{
				name: 'sectionSkioldDigital',
				title: localized('section'),
				sortable: false,
				getCellValue: this.getTableDataSectionText,
			},
		]);

		return columns;
	};
	private getValveValue = (tableData: UnitsOnProcessEquipmentItem) => tableData.unitNumber;
	private getTableDataBuildingText = (tableData: UnitsOnProcessEquipmentItem) =>
		this.getBuildingText(tableData.buildingId);
	private getTableDataSectionText = (tableData: UnitsOnProcessEquipmentItem) => (
		<SkioldDropdown
			usedInTable={true}
			theme={'dark'}
			onValueChanged={(section: Option) => this.sectionChanged(section, tableData)}
			items={this.getSectionOptionsByBuilding(tableData.buildingId)}
			selectedValue={this.getSelectedSectionOption(tableData.sectionId)}
			dropdownIdentifier={'units-on-process-section'}
		/>
	);

	private getSelectedSectionOption = sectionId => {
		if (sectionId && this.props.sections) {
			const sectionExist = this.props.sections.find(section => section.id === sectionId);
			if (sectionExist) {
				return { value: sectionExist.id!, label: sectionExist.name! };
			}
		}
		return { value: '', label: ' ' };
	};

	private getSectionOptionsByBuilding(buildingId) {
		// Don't allow two different controllers to map to the same section/pen
		const forbiddenSectionIds = this.props.allProcessEquipmentDatas
			.filter(
				peq =>
					this.props.processEquipment && ExtractLocationCode(peq.code) !== this.props.processEquipment.code,
			)
			.map(peqSection => peqSection.sectionId);
		return this.props.sections && buildingId
			? this.props.sections
					.filter(section => section.buildingId === buildingId && !forbiddenSectionIds.includes(section.id))
					.map(section => ({ value: section.id!, label: section.name! }))
			: [];
	}

	private sectionChanged = (sectionOption: Option, dataItem: UnitsOnProcessEquipmentItem) => {
		this.setState(prevState => {
			const locationRelationItems = [...prevState.locationRelationItems];
			const dataIndex = locationRelationItems.findIndex(item => item.id === dataItem.id);
			const section = this.props.sections.find(item => item.id === sectionOption.value);
			if (
				section &&
				!section.usePens &&
				locationRelationItems[dataIndex] &&
				locationRelationItems[dataIndex].unitToPen
			) {
				let utps = deepCopy2(locationRelationItems[dataIndex].unitToPen) as UnitToPen[];
				let penToUse = this.props.pens.find(p => p.sectionId === section.id);
				if (penToUse) {
					for (let index = 0; index < utps.length; index++) {
						if (index === 0) {
							utps[index].penId = penToUse.id;
						} else {
							utps[index].penId = ' ';
						}
					}
				}
				locationRelationItems[dataIndex] = {
					...locationRelationItems[dataIndex],
					sectionId: sectionOption.value,
					usesPens: section ? section.usePens : true,
					unitToPen: utps,
				};
			} else {
				locationRelationItems[dataIndex] = {
					...locationRelationItems[dataIndex],
					sectionId: sectionOption.value,
					usesPens: section ? section.usePens : true,
				};
			}
			return { locationRelationItems };
		});
	};

	private getUnitOneCellValue = (tableData: UnitsOnProcessEquipmentItem) =>
		tableData.usesPens ? (
			<SkioldFormPenPicker
				usedInsideTable={true}
				onPenSelected={(selectedPenId: string) => {
					let items = [...this.state.locationRelationItems];
					let itemIndex = this.state.locationRelationItems.findIndex(a => a.id === tableData.id);
					let itemToModify = { ...this.state.locationRelationItems[itemIndex] };
					if (itemToModify && itemToModify.unitToPen) {
						let unitsToPen = [...itemToModify.unitToPen];
						unitsToPen[0].penId = selectedPenId;
						itemToModify.unitToPen = unitsToPen;
					}
					items[itemIndex] = itemToModify;
					this.setState({ locationRelationItems: items });
				}}
				sectionId={tableData.sectionId}
				penId={
					tableData.unitToPen && tableData.unitToPen.length >= 1 ? tableData.unitToPen[0].penId : undefined
				}
				menuClassname="max-height-dropdown-menu"
				dropdownIdentifier={'units-on-process-pen1'}
			/>
		) : (
			<div />
		);

	private getUnitTwoCellValue = (tableData: UnitsOnProcessEquipmentItem) =>
		tableData.usesPens ? (
			<SkioldFormPenPicker
				usedInsideTable={true}
				onPenSelected={(selectedPenId: string) => {
					let items = [...this.state.locationRelationItems];
					let itemIndex = this.state.locationRelationItems.findIndex(a => a.id === tableData.id);
					let itemToModify = { ...this.state.locationRelationItems[itemIndex] };
					if (itemToModify && itemToModify.unitToPen) {
						let unitsToPen = [...itemToModify.unitToPen];
						unitsToPen[1].penId = selectedPenId;
						itemToModify.unitToPen = unitsToPen;
					}
					items[itemIndex] = itemToModify;
					this.setState({ locationRelationItems: items });
				}}
				sectionId={tableData.sectionId}
				penId={
					tableData.unitToPen && tableData.unitToPen.length >= 2 ? tableData.unitToPen[1].penId : undefined
				}
				menuClassname="max-height-dropdown-menu"
				dropdownIdentifier={'units-on-process-pen2'}
			/>
		) : (
			<div />
		);

	private getBuildingText = buildingId => {
		if (buildingId && this.props.buildings) {
			const buildingExist = this.props.buildings.find(building => building.id === buildingId);
			if (buildingExist) {
				return buildingExist.name;
			}
		}
		return ' ';
	};
}
export default connect(mapStateToProps, mapDispatchToProps)(UnitsOnProcessEquipmentTable);
