import React from 'react';
import ReactDOM from 'react-dom';
import Dropdown, { Option } from 'react-dropdown';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import BackArrowWhite from 'shared/assets/src-assets/png/back_arrow_bottom.png';
import BackArrowGrey from 'shared/assets/src-assets/png/back_arrow_bottom_grey.png';
import ForwardArrowWhite from 'shared/assets/src-assets/png/forward_arrow_bottom.png';
import ForwardArrowGrey from 'shared/assets/src-assets/png/forward_arrow_bottom_grey.png';
import { SaveDropdownScrollOption } from 'shared/state/ducks/general-settings/operations';
import { DropdownScrollOption } from 'shared/state/ducks/general-settings/types';
import { SharedAppState } from 'shared/state/store.shared';
import { SkioldImage } from '../../utils/svg/skiold-image';
import './skiold-dropdown.scss';
const rowHeight = 42;
export interface PickerPropsFromParent {
	onValueChanged: (newValue: Option, itemFromParent?: any) => void;
	itemFromParent?: any;
	items: Option[] | undefined;
	selectedValue?: Option;
	containerClassName?: string;
	usedInTable?: boolean;
	disabled?: boolean;
	className?: string;
	style?: any;
	theme?: 'light' | 'dark';
	menuClassname?: string;
	dropdownIdentifier?: string;
	flipDropdownThreshhold?: number;
	hideResult?: boolean;
}

interface State {
	ref: HTMLElement | undefined;
	show: boolean;
}

const mapStateToProps = (state: SharedAppState, props: PickerPropsFromParent) => {
	return {
		dropdownScrollOption: state.generalSettings.dropdownScrollOptions.find(
			option => props.dropdownIdentifier === option.id,
		),
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	saveDropdownScrollOption: (scrollOption: DropdownScrollOption) => SaveDropdownScrollOption(scrollOption)(dispatch),
});

export const DefaultTypeOption: Option = { label: ' ', value: '' };

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PickerPropsFromParent;
// Component representing a Skiold Digital picker on the website
class SkioldDropdownComponent extends React.PureComponent<Props, State> {
	public static defaultProps: Partial<Props> = {
		theme: 'light',
		disabled: false,
		containerClassName: '',
		menuClassname: '',
		flipDropdownThreshhold: 0,
	};

	private iconUp = this.props.theme === 'light' ? ForwardArrowWhite : ForwardArrowGrey;
	private iconDown = this.props.theme === 'light' ? BackArrowWhite : BackArrowGrey;
	private className = this.props.theme + ' ' + this.props.className;
	private arrowClosed = (<SkioldImage className="image-container" imageData={this.iconUp} width="11" height="18" />);
	private arrowOpen = (<SkioldImage className="image-container" imageData={this.iconDown} width="11" height="18" />);

	constructor(props: Props) {
		super(props);
		this.state = {
			ref: undefined,
			show: false,
		};
	}

	public componentDidMount() {
		const ref = ReactDOM.findDOMNode(this) as HTMLElement;
		this.setState({ ref });
	}
	public render() {
		//Hack to get around missing typings - will probably be added in next release
		let arrowOpen = this.arrowOpen;
		let arrowClosed = this.arrowClosed;

		let myProps: any = {
			arrowClosed,
			arrowOpen,
		};
		if (this.props.usedInTable) {
			myProps.menuStyle = this.getRefPosition();
		}

		return (
			<div
				className={`skiold-dropdown ${this.props.containerClassName} ${this.props.disabled ? 'disabled' : ''}`}
				style={this.props.style}
			>
				<Dropdown
					options={this.props.items}
					onChange={this.onValueChanged}
					value={this.props.selectedValue}
					disabled={this.props.disabled}
					menuClassName={`${this.props.menuClassname}`}
					placeholderClassName={this.props.hideResult ? 'display-none' : ''}
					{...myProps}
					className={this.className}
					scrollToElement={this.props.dropdownScrollOption && this.props.dropdownScrollOption.value}
					onFocus={this.onFocus}
					placeholder=""
				/>
			</div>
		);
	}

	public onFocus = (show: any) => {
		this.setState({ show: !this.state.show });
	};

	private onValueChanged = (option: Option) => {
		if (this.props.dropdownIdentifier) {
			this.props.saveDropdownScrollOption({ id: this.props.dropdownIdentifier, value: option.value });
		}
		this.props.onValueChanged(option, this.props.itemFromParent);
	};

	private findAncestor(el: any, cls: any) {
		// tslint:disable-next-line: no-conditional-assignment
		while ((el = el.parentElement) && !el.classList.contains(cls)) {}
		return el;
	}

	private getRefPosition() {
		if (this.state.ref) {
			let rect = this.state.ref.getBoundingClientRect();
			let ancestor = this.findAncestor(this.state.ref, 'react-draggable');
			let left = rect.left;
			let top = rect.top;
			let ancestorPosistionHeight = 0;
			if (ancestor) {
				let ancestorPosistion = ancestor.getBoundingClientRect();
				ancestorPosistionHeight = ancestorPosistion.height;
				top = top - ancestorPosistion.top + rowHeight + ancestor.scrollTop;
				left = left - ancestorPosistion.left + ancestor.scrollLeft;
			}
			return {
				position: 'fixed',
				left,
				top: top,
				width: this.state.ref.offsetWidth,
				transform:
					top + 235 > (ancestorPosistionHeight ? ancestorPosistionHeight : window.innerHeight)
						? `translate3d(0px, calc(-100% - ${rowHeight}px), 0px)`
						: '',
			};
		}
		return {};
	}
}

export const SkioldDropdown = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
	SkioldDropdownComponent,
);
