
import actionCreatorFactory from 'typescript-fsa';
import * as types from './types';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import { IMoveEvent, SyncDataMoveEvent } from 'shared/api/api';

const actionCreator = actionCreatorFactory(GenActionFactoryName('moveEvents'));

export const saveMoveEvent = actionCreator.async<
    IMoveEvent,
    string,
    { code: number; message: string; prevEntity: IMoveEvent | undefined }
>(types.SAVE_MOVE_EVENT);

export const getSyncData = actionCreator.async<{ siteId: string; lastSyncDate: Date; activeSiteId?: string  }, SyncDataMoveEvent>(
    types.SYNC_DATA_MOVEEVENTS
);

export const removeMoveEvent = actionCreator.async<string, string>(types.REMOVE_MOVE_EVENT);
export const getDeparturedMoveEvents = actionCreator.async<{siteId: string; activeSiteId?: string}, IMoveEvent[]>(types.GET_DEPARTURED_MOVE_EVENTS);
