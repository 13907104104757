import { Action } from 'redux';
import { removeValueFromArray, upsertValueInArray } from 'shared/helpers/reducer-helpers';
import { isType } from 'typescript-fsa';
import { getData, saveFeedingCategory } from './actions';
import { FeedingCategoryState } from './types';

export const initialDiagnoseCategoryState: FeedingCategoryState = {
	entities: []
};

const feedingCategoriesReducer = (
	state: FeedingCategoryState = initialDiagnoseCategoryState,
	action: Action
): FeedingCategoryState => {
	if (isType(action, getData.done)) {
		if (action.payload.result.length) {
			state = {
				...state,
				entities: action.payload.result
			};
		}
	}

	if (isType(action, saveFeedingCategory.started)) {
		if (action.payload.data && action.payload.data.isDeleted) {
			state = { ...state, entities: removeValueFromArray(state.entities, action.payload.data.id!) };
		} else {
			state = { ...state, entities: upsertValueInArray(state.entities, action.payload.data!) };
		}
	}

	if (isType(action, saveFeedingCategory.failed)) {
		if (action.payload.error.code === 500) {
			if (action.payload.error.prevEntity) {
				state = { ...state, entities: upsertValueInArray(state.entities, action.payload.error.prevEntity) };
			} else if (action.payload.params.data) {
				state = { ...state, entities: removeValueFromArray(state.entities, action.payload.params.data.id!) };
			}
		}

		return state;
	}

	if (isType(action, saveFeedingCategory.done)) {
		if (action.payload.params.data) {
			action.payload.params.data.id = action.payload.result;

			if (!action.payload.params.data.isDeleted) {
				state = { ...state, entities: upsertValueInArray(state.entities, action.payload.params.data) };
			}
		}

		return state;
	}

	return state;
};

export default feedingCategoriesReducer;
