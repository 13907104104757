import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import { StoreContainer } from 'shared/state/store-container';
import * as Action from './actions';

export function GetStationSyncData() {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;
	const lastSyncDate = state.station.lastSyncDate;
	return AsyncOperationBuilder2(
		Action.getSyncStationData,
		client => client.station_GetbySiteId(siteId, lastSyncDate),
		{ siteId },
	);
}
