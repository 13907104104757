import { default as ObjectID } from 'bson-objectid';
import { Dispatch } from 'redux';
import { DiagnoseCategory, IDiagnoseCategory } from 'shared/api/api';
import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import { StoreContainer } from 'shared/state/store-container';
import * as Action from './actions';

export function GetSyncData() {
	const state = StoreContainer.getState();
	const lastSyncDate = state.diagnoseCategory.lastSyncDate;
	if (state.diagnoseCategory.syncInProgress) {
		return (dispatch: Dispatch<any>) => {
			return Promise.resolve();
		};
	}
	return AsyncOperationBuilder2(Action.getSyncData, client => client.diagnoseCategories_Sync(lastSyncDate), {
		lastSyncDate,
	});
}

export function SaveDiagnoseCategory(diagnoseCategory: IDiagnoseCategory) {
	const state = StoreContainer.getState();
	const prevEntity = state.diagnoseCategory.entities.find(diagCat => diagCat.id === diagnoseCategory.id);
	if (!diagnoseCategory.id) {
		diagnoseCategory.id = new ObjectID().toHexString();
	}

	return AsyncOperationBuilder2(
		Action.saveDiagnoseCategory,
		client => client.diagnoseCategories_Post(DiagnoseCategory.fromJS(diagnoseCategory)),
		DiagnoseCategory.fromJS(diagnoseCategory),
		prevEntity,
	);
}
