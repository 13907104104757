import ObjectID from 'bson-objectid';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
	AnimalKind,
	Gender,
	IMated,
	IPregnancyEvent,
	IStemAnimal,
	Mated,
	PregnancyState,
	SemenType,
	StemAnimal,
} from 'shared/api/api';
import { getDateString } from 'shared/helpers/date-helpers';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { deepCopy } from 'shared/helpers/general-helpers';
import { getLocationString } from 'shared/helpers/location-helper';
import { resendMatedValidation } from 'shared/helpers/nucleus-management-helper/nucleus-validation-interface';
import {
	getLastPregnancyByPregnancyEventId,
	setupPregnancyEventById,
} from 'shared/helpers/pregnancy-helper/generel-pregnancy-helpers';
import { mergeArraysHashmap } from 'shared/helpers/reducer-helpers';
import { ResendPregnancyEvent } from 'shared/helpers/resend-nucleus-helpers';
import { findAnimalById } from 'shared/helpers/stemanimal-helper/stem-animal-input-helper';
import { getLastMoveEventDate, isMoveDateValid } from 'shared/helpers/stemanimal-helper/stemanimal-helper';
import { DeleteLastPregnancyEvent, SavePregnancyEvent } from 'shared/state/ducks/pregnancy-events/operations';
import { memoizeInitialOptions } from 'shared/state/ducks/profile/selectors';
import { selectNucleusManagementOrAssignIdAccess } from 'shared/state/ducks/site/reducer';
import { SaveSow } from 'shared/state/ducks/stem-animals/operations';
import { localized, localizedDynamic } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import RegisterMatingComponent, {
	RegisterMatingComponent as RegisterMatingRef,
} from 'web/view/components/pregnancies/pregnancy-events/register-mating-components';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldFormsWrapper } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import MoveEventBase from 'web/view/components/stem-animal/sow-events/move-event/move-event-base';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { showAlert, ShowConfirmAlert } from '../../skiold-alert/skiold-alert';
import { SkioldCheckbox } from '../../skiold-components/skiold-checkbox/skiold-checkbox';
import { SkioldFormTextField } from '../../skiold-components/skiold-forms-wrapper/skiold-form-text';
import { FormRow } from '../../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { Scroller } from '../../utils/scroller';
import './register-mating.scss';
import { hasDanishNucleusFeatureLicence } from 'shared/helpers/nucleus-management-helper/nucleus-management-helper';

interface PropsFromParent {
	pregnancyEventId?: string;
	stemAnimalId?: string;
	closeEditModal?: () => void;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		pregnancyEvents: mergeArraysHashmap(
			state.pregnancyEvents.entities,
			state.pregnancyEvents.departuredPregnancies,
		),
		stemAnimals: state.stemAnimals.entities,
		moveEvents: state.moveEvents.entities,
		showInitials: state.generalSettings.entity.stemAnimalShowInitials,
		defaultInitial: state.profile.active?.id,
		initials: memoizeInitialOptions(state.profile.entities, state.profile.active),
		nucleusManagement: selectNucleusManagementOrAssignIdAccess(state),
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	savePregnancyEvent: (pregnancyEvent: IPregnancyEvent) => SavePregnancyEvent(pregnancyEvent)(dispatch),
	saveStemAnimal: (boar: IStemAnimal) => SaveSow(boar)(dispatch),
	deleteLastPregnancyEvent: (pregEvent: IPregnancyEvent) => DeleteLastPregnancyEvent(pregEvent)(dispatch),
});

interface MatingState {
	mated: IMated;
	sow: IStemAnimal | undefined;
	registerMoveEvent: boolean;
	boarNumber?: string;
	boarId?: string;
	lastMoveDate: Date | undefined;
	currentState?: IMated;
}

export type MatingProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;
class RegisterMating extends React.PureComponent<MatingProps, MatingState> {
	public registerMatingRef: RegisterMatingRef | undefined;

	public moveEventSave: (() => Promise<boolean>) | undefined;

	private scrollerRef: Scroller | null | undefined;
	constructor(props: MatingProps) {
		super(props);
		this.state = {
			mated: Mated.fromJS({ date: new Date(), state: PregnancyState.Mated }),
			sow: StemAnimal.fromJS({}),
			registerMoveEvent: false,
			boarNumber: undefined,
			boarId: undefined,
			lastMoveDate: undefined,
		};
	}

	public setRef = (s: any) => {
		if (s) {
			this.registerMatingRef = s;
			this.forceUpdate();
		}
	};
	public returnMatedCallback = (mated: IMated, confirmAlert: boolean = false) => this.setMated(mated);

	public componentDidMount() {
		if (this.props.pregnancyEventId) {
			setupPregnancyEventById(
				this.props.pregnancyEvents,
				this.props.pregnancyEventId,
				this.props.stemAnimalId!,
				this.registerMatingRef!.animalNumberChanged,
				(animalNumber, foundPregnancy) => {
					if (foundPregnancy) {
						const foundMated = foundPregnancy as Mated;
						const boar = findAnimalById(foundMated.boarId);
						const boarNumber = boar ? boar.animalNumber! : '';
						const semenType = boar?.entranceKind === AnimalKind.Boar ? SemenType.Nat : SemenType.KS;
						const initOption = this.props.initials.find(init => init.value === foundMated.initials);
						this.registerMatingRef!.setState({
							animalNumber,
							selectedGrade: { label: foundMated.grade!, value: foundMated.grade! },
							boarNumber,
							boar,
							selectedBoar: { label: boar ? boar.animalNumber! : ' ', value: boar ? boar.id! : '' },
							selectedSemenType: { value: semenType, label: semenType },
							selectedInitial: initOption ?? { label: ' ', value: '' },
						});
						this.setState({ boarId: foundMated.boarId, currentState: foundMated });
					}
				},
			);
		}
	}

	public setMated(mated: IMated) {
		const sow = mated.stemAnimalId
			? this.props.stemAnimals.find(stem => stem.id === mated.stemAnimalId)
			: ({} as IStemAnimal);

		const lastMoveDate = sow && sow.id ? getLastMoveEventDate(sow.id, this.props.moveEvents) : undefined;
		const moveValid = isMoveDateValid(lastMoveDate, mated.date);
		this.setState({
			mated,
			sow: sow ? sow : ({} as IStemAnimal),
			lastMoveDate,
			registerMoveEvent: !moveValid ? false : this.state.registerMoveEvent,
		});
	}

	public toggleMoveEvent() {
		const toggle = !this.state.registerMoveEvent;
		this.setState({ registerMoveEvent: toggle });
	}

	public render() {
		return <ViewWeb className="register-mating">{this.renderContent()}</ViewWeb>;
	}

	public renderContent() {
		let formRows = this.getFormRows();
		return (
			<ViewWeb>
				{this.renderButtons()}
				<ViewWeb className="move-event-container-zindex">
					{this.state.registerMoveEvent && (
						<MoveEventBase
							date={this.state.mated.date}
							stableRow={(formRow: FormRow) => formRows.push(formRow)}
							penRow={(formRow: FormRow) => formRows.push(formRow)}
							feedCurveRow={(formRow: FormRow) => formRows.push(formRow)}
							fixedDeviationRow={(formRow: FormRow) => formRows.push(formRow)}
							stemAnimal={this.state.sow}
							pregnancyEvent={this.state.mated}
							update={this.forceUpdateView}
							gender={Gender.Female}
							save={this.saveMoveEvent}
						/>
					)}
					<RegisterMatingComponent
						boarNumber={this.state.boarNumber}
						boarNumberChanged={this.boarNumberChanged}
						returnMatedCallback={this.returnMatedCallback}
						ref={this.setRef}
					/>
					<SkioldFormsWrapper formRows={formRows} containerClassName="forms-wrapper-style" />
				</ViewWeb>
			</ViewWeb>
		);
	}

	public boarNumberChanged = (boarNumber: string, boarId?: string) => {
		if (boarNumber.length > 0 && boarId !== undefined) {
			this.setState({ boarNumber: boarNumber, boarId: boarId });
		} else {
			this.setState({ boarNumber: boarNumber, boarId: undefined });
		}
	};

	public save = async () => {
		let errorMessage = await this.registerMatingRef!.validate();
		if (errorMessage !== '') {
			if (errorMessage !== 'ignore') {
				showAlert(localizedDynamic(errorMessage));
			}
			return;
		}

		if (this.state.registerMoveEvent && this.moveEventSave) {
			const valid = await this.moveEventSave();
			if (!valid) {
				return;
			}
		}

		//Create boar if it doesn't exist
		const mated = deepCopy(this.state.mated);
		if (this.registerMatingRef!.state.boarNumber && !this.state.boarId) {
			const newBoarId = new ObjectID().toHexString();
			this.setState({ boarId: newBoarId });
			const newBoar = StemAnimal.fromJS({
				animalNumber: this.registerMatingRef!.state.boarNumber,
				id: newBoarId,
				siteId: mated.siteId,
				gender: Gender.Male,
				entranceKind: AnimalKind.Boar,
			} as IStemAnimal);
			mated.boarId = newBoarId;
			this.props.saveStemAnimal(newBoar);
		} else {
			mated.boarId = this.state.boarId;
		}

		this.props.savePregnancyEvent(mated);
		this.registerMatingRef!.resetComponent();
		this.setState({
			mated: Mated.fromJS({
				date: new Date(),
				state: PregnancyState.Mated,
				boarId: mated.boarId,
				initials: mated.initials,
				semenType: mated.semenType,
			} as IMated),
		});

		this.editDone();
	};

	public removeEvent = async () => {
		let allow = await ShowConfirmAlert(localized(ExceptionMessage.VALIDATION_WARNING_CONFIRM_DELETEION_MATED));
		if (!allow) {
			return;
		}
		const lastPregnancyEvent = getLastPregnancyByPregnancyEventId(
			this.props.pregnancyEvents[this.state.sow!.id!],
			this.props.pregnancyEventId!,
		);
		if (lastPregnancyEvent && this.state.mated.id === lastPregnancyEvent.id) {
			let pregEvent = deepCopy(this.state.mated);
			pregEvent.isDeleted = true;
			this.props.deleteLastPregnancyEvent(pregEvent);
			this.editDone();
		}
	};

	private saveMoveEvent = (method: () => Promise<boolean>) => (this.moveEventSave = method);
	private closeEditModal = () => this.props.closeEditModal!();
	private forceUpdateView = () => this.forceUpdate();

	private editDone() {
		if (this.props.closeEditModal) {
			this.props.closeEditModal();
		}
	}

	private getFormRows() {
		let formRows = new Array<FormRow>();
		if (this.registerMatingRef) {
			formRows.push(this.getPregnancyAnimalNumberRow());
			formRows.push(this.getLocationRow());
			formRows.push(this.getLastMoveDateRow());
			formRows.push(this.getMatingDate());
			formRows.push(this.getMatingBatchNumber());
			formRows.push(this.getMatingGrade());
			formRows.push(this.getMatingBoarNumber());
			if (this.props.nucleusManagement) {
				formRows.push(this.getSemenType());
			}

			if (this.props.showInitials) {
				formRows.push(this.getInitials());
			}
			formRows.push(this.getToggleMoveEventRow());
		}

		return formRows;
	}

	private getPregnancyAnimalNumberRow(): FormRow {
		return this.registerMatingRef!.getPregnancyAnimalNumberRow(!this.props.pregnancyEventId);
	}

	private getLocationRow(): FormRow {
		return {
			name: localized('location'),
			component: (
				<SkioldFormTextField>
					{this.state.mated.stemAnimalId !== undefined
						? getLocationString(this.props.stemAnimals.find(s => s.id === this.state.mated.stemAnimalId))
						: ''}
				</SkioldFormTextField>
			),
		};
	}

	private getLastMoveDateRow(): FormRow {
		return {
			name: localized('lastMoved'),
			component: <SkioldFormTextField>{getDateString(this.state.lastMoveDate)}</SkioldFormTextField>,
		};
	}

	private getMatingDate(): FormRow {
		return this.registerMatingRef!.getDateRow();
	}

	private getMatingBatchNumber(): FormRow {
		return this.registerMatingRef!.getBatchNumberRow();
	}

	private getMatingGrade(): FormRow {
		return this.registerMatingRef!.getGradePickerRow();
	}

	private getMatingBoarNumber(): FormRow {
		if (this.props.nucleusManagement) {
			return this.registerMatingRef!.getBoarInputRowNucleus();
		}
		return this.registerMatingRef!.getBoarInputRow();
	}

	private getSemenType(): FormRow {
		return this.registerMatingRef!.getSemenTypeRow();
	}

	private getInitials(): FormRow {
		return this.registerMatingRef!.getInitialsRow();
	}

	private getToggleMoveEventRow(): FormRow {
		return {
			name: localized('move'),
			component: (
				<SkioldCheckbox
					onClick={() => {
						this.toggleMoveEvent();
					}}
					containerClassName={'inForm'}
					disabled={!isMoveDateValid(this.state.lastMoveDate, this.state.mated.date)}
					isChecked={this.state.registerMoveEvent}
				/>
			),
		};
	}

	private renderButtons() {
		return (
			<ViewWeb
				className={
					this.props.pregnancyEventId
						? 'button-view-style-upper-corner-edit'
						: 'button-view-style-upper-corner'
				}
			>
				{this.props.closeEditModal && (
					<SkioldButton title={localized('Close')} onPress={this.closeEditModal} theme="grey" />
				)}
				{this.props.pregnancyEventId && hasDanishNucleusFeatureLicence(this.props.nucleusManagement) && (
					<SkioldButton
						title={localized('resend')}
						onPress={this.resendMating}
						disabled={resendMatedValidation(this.state.currentState, {
							...this.state.mated,
							boarId: this.state.boarId,
						} as IMated)}
						theme="grey"
					/>
				)}
				{this.renderDeleteButton()}

				<SkioldButton title={localized('Save')} onPress={this.save} />
			</ViewWeb>
		);
	}

	private resendMating = async () => {
		if (this.state.currentState) {
			ResendPregnancyEvent(this.state.currentState);
		}
	};

	private renderDeleteButton() {
		if (this.state.sow && this.state.sow.id) {
			const lastPregnancyEvent = getLastPregnancyByPregnancyEventId(
				this.props.pregnancyEvents[this.state.sow!.id!],
				this.props.pregnancyEventId!,
			);

			return (
				lastPregnancyEvent &&
				this.props.pregnancyEventId === lastPregnancyEvent.id && (
					<SkioldButton title={localized('Delete')} onPress={this.removeEvent} theme="grey" />
				)
			);
		}

		return null;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterMating);
