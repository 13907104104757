import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { GenerateRefreshTokenForSiteId } from 'shared/state/ducks/connection-slaughter-house/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { ViewWeb } from 'web/view/components/utils/web-view';

export const TicanCallbackPage: FC<any> = React.memo(({ props }) => {
	const state = useSelector((state: WebAppState) => {
		return { navigation: state.navigation, siteId: state.profile.active && state.profile.active.siteId };
	});
	const code = state.navigation.query ? (state.navigation.query['code'] as string) : undefined;
	useEffect(() => {
		if (state.siteId) {
			GenerateRefreshTokenForSiteId(code!, state.siteId)
				.then(() => {
					 window.close();
				})
				.catch(() => {
					alert(localized('Failed_To_Login_Tican'));
					window.close();
				});
		}
	}, [state.siteId,code]);

	return <ViewWeb></ViewWeb>;
});