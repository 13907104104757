import { Sorting } from '@devexpress/dx-react-grid';
import memoize from 'memoize-one';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IDeadPigletsEvent, IPregnancyEvent, IReasonDto, IStemAnimal } from 'shared/api/api';
import sokort_icon from 'shared/assets/src-assets/png/sokort_icon.png';
import { exactStartsWithMethodGrid, onlyUniqueFilter } from 'shared/helpers/general-helpers';
import { NaturalSortDates } from 'shared/helpers/natural-sort';
import { localized, localizedDynamic } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import 'web/view/components/event-lists/pregnancy-event-lists/sow-pregnancy-event-list-styles.scss';
import { SkioldModal } from 'web/view/components/skiold-components/skiold-modal/skiold-modal';
import 'web/view/components/skiold-components/skiold-table/skiold-double-header-table.scss';
import SkioldTableGrid, {
	SkioldTableGrid as SkioldTableRef,
} from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { SkioldTouchableOpacity } from 'web/view/components/skiold-components/skiold-touchable-opacity';
import { SowListConstants } from 'web/view/components/stem-animal/animal-lists/table-constants';
import { SkioldImage } from 'web/view/components/utils/svg/skiold-image';
import SowCard from 'web/view/pages/sow-card/sow-card';
import { getWebAnimalColorStyleTable } from 'web/web-helpers/general-web-helpers';
import { DeadPigletsEventItem, DeadPigletsSummaryItem } from './dead-piglets-event-list-item';
import {
	memoizeAllDeadPigletEvents,
	memoizeAllPregnancyEvents,
	memoizeAllSows,
} from 'shared/helpers/memoize-getters/memoize-getters';
import * as DeadPigletsEventOperations from 'shared/state/ducks/dead-piglets-event/operations';
import { GetDeparturedAnimals, GetSyncData as StemAnimalGetSyncData } from 'shared/state/ducks/stem-animals/operations';
import { calculateLitter1 } from 'shared/helpers/stemanimal-helper/stemanimal-helper';
import { getDateString } from 'shared/helpers/date-helpers';
import { selectNucleusManagementOrAssignIdAccess } from '../../../../../shared/state/ducks/site/reducer';
import { GetDeparturedPregnancies } from 'shared/state/ducks/pregnancy-events/operations';
import { findBatchByFarrowingId } from 'shared/helpers/matingbatch-helper';
import { IStemAnimalDepartured } from 'shared/state/ducks/stem-animals';

interface PropsFromParent {
	dateFrom: Date;
	dateTo: Date;
	onSummaryChanged: (element: JSX.Element) => void;
}

interface State {
	columns: any[];
	columnExte: any[];
	sowCardModal: boolean;
	stemAnimalId?: string;
	deadPigletsTableItems: DeadPigletsEventItem[];
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;
const getPregnanciesByStemIds = memoize(
	(pregnancies: { [key: string]: IPregnancyEvent[] }, deadPigletsEvents: IDeadPigletsEvent[]) => {
		let pregnanciesObject: { [key: string]: IPregnancyEvent[] } = {};
		let stemIds = deadPigletsEvents.map(event => event.stemAnimalId!);
		for (let [key, value] of Object.entries(pregnancies)) {
			if (stemIds.includes(key)) {
				pregnanciesObject[key] = value;
			}
		}
		return pregnanciesObject;
	},
);

const getStemAnimalsById = memoize(
	(stemAnimals: (IStemAnimal | IStemAnimalDepartured)[], deadPigletsEvents: IDeadPigletsEvent[]) => {
		let stemIds = deadPigletsEvents.map(event => event.stemAnimalId!);
		return stemAnimals.filter(stem => stem.id && stemIds.includes(stem.id));
	},
);

const mapStateToProps = (state: WebAppState, ownProps: PropsFromParent) => {
	let deadPigletsEvents = memoizeAllDeadPigletEvents(
		state.deadPigletsEvents.entities,
		state.deadPigletsEvents.departuredDeadPigletEvents,
		ownProps.dateFrom,
		ownProps.dateTo,
	);
	let memoizedSows = memoizeAllSows(state.stemAnimals.entities, state.stemAnimals.departuredAnimals);
	let memoizedPregnancies = memoizeAllPregnancyEvents(
		state.pregnancyEvents.entities,
		state.pregnancyEvents.departuredPregnancies,
	);
	return {
		deadPigletsEvents: deadPigletsEvents,
		stemAnimals: getStemAnimalsById(memoizedSows, deadPigletsEvents),
		pregnancies: getPregnanciesByStemIds(memoizedPregnancies, deadPigletsEvents),
		reasons: state.reasons.entities,
		language: state.profile.active && state.profile.active.language ? state.profile.active.language : 'en',
		generalSettings: state.generalSettings.entity,
		userProfiles: state.profile.entities,
		nucleusManagement: selectNucleusManagementOrAssignIdAccess(state),
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => {
	return {
		deadPigletsEventGetSyncDate: (fromDate: Date, toDate: Date) =>
			DeadPigletsEventOperations.GetPigletEventsByPeriod(fromDate, toDate)(dispatch),
		stemAnimalGetSyncData: () => StemAnimalGetSyncData()(dispatch),
		getDeparturedAnimals: () => GetDeparturedAnimals()(dispatch),
		getDeparturedPregnancies: () => GetDeparturedPregnancies()(dispatch),
	};
};

export class DeadPigletsEventTable extends React.PureComponent<Props, State> {
	public SkioldTableRef: SkioldTableRef | undefined;

	private generateData = memoize(
		(
			deadPigletsEvents: IDeadPigletsEvent[],
			stemAnimals: (IStemAnimal | IStemAnimalDepartured)[],
			pregnancies: { [key: string]: IPregnancyEvent[] },
			reasons: IReasonDto[],
		) => this.genereateListData(deadPigletsEvents, stemAnimals, pregnancies, reasons),
	);

	private defaultSorting = [{ columnName: 'animalNumber', direction: 'asc' }] as Sorting[];
	constructor(props: Props) {
		super(props);
		this.state = {
			columns: this.generateColumns(),
			columnExte: this.generateColumnsExtensions(),
			sowCardModal: false,
			stemAnimalId: undefined,
			deadPigletsTableItems: [],
		};

		setTimeout(() => {}, 5000);
	}

	public genereateListData(
		deadPigletsEvents: IDeadPigletsEvent[],
		stemAnimals: (IStemAnimal | IStemAnimalDepartured)[],
		pregnancies: { [key: string]: IPregnancyEvent[] },
		reasons: IReasonDto[],
	) {
		let tableItems: DeadPigletsEventItem[] = [];
		for (let event of deadPigletsEvents) {
			let stemAnimal = stemAnimals.find(stem => stem.id === event.stemAnimalId);
			if (stemAnimal && event.registrationDate && stemAnimal.id) {
				let litter = calculateLitter1(stemAnimal, event.registrationDate, pregnancies[stemAnimal.id]);
				let reason = reasons.find(reason => reason.reason && reason.reason.id === event.reasonId);
				const user = this.props.userProfiles.find(u => u.id === event.createdBy);
				let tableItem = new DeadPigletsEventItem();
				tableItem.stemAnimalId = stemAnimal.id;
				tableItem.animalNumber = stemAnimal.animalNumber;
				tableItem.date = event.registrationDate;
				tableItem.deadPiglets = event.deadPiglets;
				tableItem.litterNumber = litter;
				tableItem.batch = findBatchByFarrowingId(stemAnimal.id, event.pregnancyId!, this.props.pregnancies);
				tableItem.initial = user?.userSiteId ?? '';
				tableItem.breedRace = stemAnimal.race;
				tableItem.youngAnimalIdNumber = event.youngAnimalIdNumber;
				tableItem.departureType = localizedDynamic(event.departureType!);
				if (reason && reason.reason && reason.reason.name) {
					tableItem.reason = reason.reason.name[this.props.language];
				}
				tableItems.push(tableItem);
			}
		}
		this.generateSummary(tableItems);
		return tableItems;
	}

	public generateColumnsExtensions() {
		let columnExt = [
			{
				columnName: 'sowCard',
				width: SowListConstants.iconWidth,
				filteringEnabled: false,
				sortingEnabled: false,
				resizingEnabled: false,
			},
			{
				columnName: 'animalNumber',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'breedRace',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'litterNumber',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'batch',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'date',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'deadPiglets',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'departureType',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'reason',
				width: SowListConstants.animalNrWidth,
			},
		];
		if (this.props.generalSettings.stemAnimalShowInitials) {
			columnExt.push({
				columnName: 'initial',
				width: SowListConstants.animalNrWidth,
			});
		}

		if (this.props.nucleusManagement) {
			columnExt.push({
				columnName: 'youngAnimalIdNumber',
				width: SowListConstants.animalNrWidth + 30,
			});
		}

		return columnExt;
	}

	public componentDidMount() {
		this.props.deadPigletsEventGetSyncDate(this.props.dateFrom, this.props.dateTo);
		this.props.stemAnimalGetSyncData();
		this.props.getDeparturedAnimals();
		this.props.getDeparturedPregnancies();
	}
	public componentDidUpdate(prevProps: Props) {
		if (prevProps.dateFrom !== this.props.dateFrom || prevProps.dateTo !== this.props.dateTo) {
			this.props.deadPigletsEventGetSyncDate(this.props.dateFrom, this.props.dateTo);
		}
	}
	public render() {
		return (
			<div className="alignTableCenterDefault">
				<SkioldTableGrid
					tableKey={'farrowingEventListTable'}
					columns={this.state.columns}
					ColumnExtensions={this.state.columnExte}
					data={this.generateData(
						this.props.deadPigletsEvents,
						this.props.stemAnimals,
						this.props.pregnancies,
						this.props.reasons,
					)}
					ref={this.setTableRef}
					onFiltersChanged={this.onFiltersChanged}
					sortHeaderId={this.defaultSorting}
				/>

				<SkioldModal
					shouldCloseOnOverlayClick={true}
					padding="0"
					isOpen={this.state.sowCardModal}
					close={this.closeModal}
					justify-content="flex-end"
					max-width="calc(100% - 220px)"
				>
					<SowCard stemAnimalIdFromParent={this.state.stemAnimalId} close={this.closeModal} />
				</SkioldModal>
			</div>
		);
	}

	private onFiltersChanged = (events: DeadPigletsEventItem[]) => {
		this.generateSummary(events);
	};

	private closeModal = () => {
		this.setState({ stemAnimalId: '', sowCardModal: false });
	};

	private showSowCard = (stemAnimalId: string) => {
		this.setState({ sowCardModal: true, stemAnimalId });
	};
	private setTableRef = (m: any) => (m ? (this.SkioldTableRef = m) : {});

	private getStemAnimalNumberColor = (tableItem: DeadPigletsEventItem) => {
		return tableItem && tableItem.stemAnimalId ? getWebAnimalColorStyleTable(tableItem.stemAnimalId) : '';
	};

	private generateColumns() {
		const columns = [
			{
				name: 'sowCard',
				title: ' ',
				headerClassName: 'merged-header',
				sortable: false,
				getCellValue: (d: DeadPigletsEventItem) => (
					<SkioldTouchableOpacity
						onPress={() => {
							this.showSowCard(d.stemAnimalId!);
						}}
					>
						<SkioldImage
							width={SowListConstants.iconSVGWidth}
							height={SowListConstants.iconSVGWidth}
							imageData={sokort_icon}
						/>
					</SkioldTouchableOpacity>
				),
			},
			{
				name: 'animalNumber',
				headerClassName: 'merged-header',
				title: localized('animalNumber'),
				className: this.getStemAnimalNumberColor,
				filterFunction: exactStartsWithMethodGrid,
			},
			{
				name: 'breedRace',
				title: localized('Race'),
			},
			{
				name: 'litterNumber',
				title: localized('LitterNr'),
			},
			{
				name: 'batch',
				title: localized('Batch'),
			},
			{
				name: 'date',
				title: localized('Date'),
				getCellValue: (event: DeadPigletsEventItem) => getDateString(event.date),
				sortFunction: NaturalSortDates,
			},
			{
				name: 'deadPiglets',
				title: localized('dead'),
			},
			{
				name: 'departureType',
				title: localized('departureType'),
			},
			{
				name: 'reason',
				title: localized('reason'),
			},
		];
		if (this.props.generalSettings.stemAnimalShowInitials) {
			columns.push({
				name: 'initial',
				title: localized('InitialsShort'),
			});
		}
		if (this.props.nucleusManagement) {
			columns.push({ name: 'youngAnimalIdNumber', title: localized('idNumber') });
		}

		return columns;
	}

	private generateSummary = (deadPigletItems: DeadPigletsEventItem[]) => {
		let summaryItem: DeadPigletsSummaryItem = new DeadPigletsSummaryItem();

		let stemIds = deadPigletItems.map(item => item.stemAnimalId!).filter(onlyUniqueFilter);
		let totalDeadPiglets = deadPigletItems
			.map(item => item.deadPiglets!)
			.reduce((totalDead, dead) => {
				let d1 = dead ? dead : 0;
				let d2 = totalDead ? totalDead : 0;
				return d2 + d1;
			}, 0);

		summaryItem.sowCount = stemIds.length;
		summaryItem.deadPiglets = totalDeadPiglets;
		summaryItem.avgDeadPigletsPerSow =
			totalDeadPiglets && stemIds.length ? (totalDeadPiglets / stemIds.length).toFixed(2) : '0';
		this.props.onSummaryChanged(
			<div className="marginTopTen">
				<div className="flexDirectionRow">
					<div className="flexDirectionRowWithWidth">
						<div className="summaryTextStyle"> {localized('sowCount')}: </div>
						<div className="summaryTextStyle"> {summaryItem.sowCount} </div>
					</div>
				</div>
				<div className="flexDirectionRow">
					<div className="flexDirectionRowWithWidth">
						<div className="summaryTextStyle"> {localized('totalDeadPiglets')}: </div>
						<div className="summaryTextStyle"> {summaryItem.deadPiglets} </div>
					</div>
				</div>
				<div className="flexDirectionRow">
					<div className="flexDirectionRowWithWidth">
						<div className="summaryTextStyle"> {localized('avgDeadPerSow')}: </div>
						<div className="summaryTextStyle"> {summaryItem.avgDeadPigletsPerSow} </div>
					</div>
				</div>
			</div>,
		);
	};
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(DeadPigletsEventTable) as any;
