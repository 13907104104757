import { getMaxNumberGrowthPigsForAmountRow } from 'native/view/components/growth-pig-treatment/growth-pig-treatment-helper';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from 'shared/assets/src-assets/png/delete_icon_grey.png';
import SaveGreyIcon from 'shared/assets/src-assets/png/gem_web.png';
import PrinterGreyIcon from 'shared/assets/src-assets/png/printer_ikon_grey.png';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { GetGrowthShouldDepartureList } from 'shared/helpers/growth-pigs-helper/growth-pig-event-pdf-helper';
import { setShouldDepartureAsDeparted } from 'shared/helpers/growth-pigs-helper/growth-pig-should-departure-helper.';
import {
	removeGrowthPigsEventByIds,
	upsertGrowthPigsDepartureFromPenEvents,
} from 'shared/state/ducks/growth-pig-events/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { showAlert } from 'web/view/components/skiold-alert/skiold-alert';
import { SkioldDatePicker } from 'web/view/components/skiold-components/skiold-date-picker/skiold-date-picker';
import SkioldTableItemCounter from 'web/view/components/skiold-components/skiold-table/skiold-table-item-counter';
import { SkioldIconSpinner } from 'web/view/components/utils/skiold-icon-spinner';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { useGpeShouldDeparture } from './growth-pig-should-departure-overview';
interface PropsFromParent {}

export const GrowthPigShouldDepartureHeader: FC<PropsFromParent> = React.memo(() => {
	const dispatch = useDispatch();

	const language = useSelector((state: WebAppState) => state.profile.active && state.profile.active.language);
	const siteId = useSelector((state: WebAppState) => state.profile.active && state.profile.active.siteId);
	const {
		tableItems,
		productionType,
		setRegistrationDate,
		registrationDate,
		skioldTableRef,
	} = useGpeShouldDeparture();
	const [checkedCount, setCheckedCount] = useState<number>(0);
	const [totalCount, setTotalCount] = useState<number>(0);

	useEffect(() => {
		let checked = 0;
		let total = 0;
		tableItems.forEach(item => {
			if (item.pigCount) {
				if (item.checked) {
					checked += item.pigCount;
				}
				total += item.pigCount;
			}
		});
		setCheckedCount(checked);
		setTotalCount(total);
	}, [tableItems]);

	const gpes = useSelector((state: WebAppState) => state.growthPigEvents.growthPigEvents);
	const gpesDto = useSelector((state: WebAppState) => state.growthPigEvents.entities);

	const updateShouldDeparture = async () => {
		if (await validate()) {
			const dataToSave = setShouldDepartureAsDeparted(gpes, tableItems);
			if (dataToSave && dataToSave.length) {
				upsertGrowthPigsDepartureFromPenEvents(dataToSave)(dispatch);
			}
		}
	};

	const validate = async () => {
		return tableItems.every(item => {
			if (item.checked) {
				const maxAmount = getMaxNumberGrowthPigsForAmountRow(
					gpesDto,
					productionType,
					item.sectionId,
					item.penId,
				);
				if (item.pigCount && maxAmount < item.pigCount) {
					showAlert(
						ExceptionMessage.VALIDATION_ERROR_COUNT_LARGER_THAN_CURRENT_COUNT_2(
							maxAmount,
							item.locationString + ' - ' + item.penString,
							item.pigCount,
						),
					);
					return false;
				}
			}
			return true;
		});
	};

	const deleteShouldDeparture = () => {
		removeGrowthPigsEventByIds(
			tableItems.filter(tableItem => tableItem.gpeId && tableItem.checked).map(tableItem => tableItem.gpeId!),
		)(dispatch);
	};

	const printShouldDeparture = async () => {
		if (siteId) {
			await GetGrowthShouldDepartureList(
				skioldTableRef.GetSortedData(),
				'GrowthPigShouldDepartureEvents.pdf',
				siteId,
				productionType,
				Intl.DateTimeFormat().resolvedOptions().timeZone,
				language,
			);
		}
	};

	const dateChanged = (date: Date) => {
		setRegistrationDate(date);
	};

	return (
		<ViewWeb className="flex-row-space-between gpe-should-depart-header-container">
			{/* UI Dummy */}
			<ViewWeb className="flex-row gap-between flexOne" />
			<TextWeb className="yellow-text-label flexOne text-align-center">
				{localized('ShouldDepartureList')}
			</TextWeb>
			<ViewWeb className="flex-row flexOne flex-end">
				<SkioldIconSpinner title={'Delete'} icon={DeleteIcon} onPress={deleteShouldDeparture} />
				<ViewWeb className="vertical-dotted-border" />
				<SkioldIconSpinner title={'Save'} icon={SaveGreyIcon} onPress={updateShouldDeparture} />
				<ViewWeb className="vertical-dotted-border" />
				<SkioldIconSpinner title={'Print'} icon={PrinterGreyIcon} onPress={printShouldDeparture} />
			</ViewWeb>
		</ViewWeb>
	);
});
