const WorkListPriority = {
	MatingListSetting: 1,
	MoveMatingToMatingSetting: 2,
	ScanningListSetting: 3,
	ScanningListSetting2: 4,
	MoveFromPregToPregSetting: 5,
	MoveToPregnancyLocation: 6,
	FarrowingListSetting: 7,
	MoveToFarrowingLocation: 8,
	TasksInFarrowingStableSetting: 9,
	TasksInFarrowingStableSetting2: 10,
	WeaningListSetting: 11,
	MoveToMatingLocation: 12,
	VaccineListSetting1: 13,
	VaccineListSetting2: 14,
	VaccineListSetting3: 15,
	AllAnimalsEsfListSetting: 16
};

export function sortWorklistByPriority(a: string, b: string) {
	return WorkListPriority[a] - WorkListPriority[b];
}
