import memoize from 'memoize-one';
import React from 'react';
import { Option } from 'react-dropdown';
import { connect } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { Dispatch } from 'redux';
import {
	IMatingBatch,
	VaccineListSetting1,
	VaccineListSetting2,
	VaccineListSetting3,
	WorkListType,
	IScanningListSettingBase,
	IVaccineListSetting,
	SowTaskSettings1,
	SowTaskSettings2,
	ILardScanningEventListSettingBase,
	SowState,
	IVaccinationSetting,
	VaccinePregnancyType,
	MatingBatch,
} from 'shared/api/api';
import CancelIcon from 'shared/assets/src-assets/png/Annuller_Grey.png';
import DeleteIcon from 'shared/assets/src-assets/png/delete_icon_grey.png';
import SaveGreyIcon from 'shared/assets/src-assets/png/gem_web.png';
import CreateAnimal from 'shared/assets/src-assets/png/opret_dyr@4x.png';
import PrinterGreyIcon from 'shared/assets/src-assets/png/printer_ikon_grey.png';
import { getBatchesToUseReal } from 'shared/helpers/work-list-helpers/scanning-list-helpers/scanning-work-list-helper';
import { getBatchesToUseVaccine } from 'shared/helpers/work-list-helpers/vaccine-list-helpers/vaccine-work-list-helpers';
import * as diagnoseOperation from 'shared/state/ducks/diagnoses/operations';
import * as EsfDailyStatusOperations from 'shared/state/ducks/esf-daily-status/operations';
import * as EsfStatusOperations from 'shared/state/ducks/esf-status/operations';
import { GetLocations } from 'shared/state/ducks/locations/operations';
import * as matingBatchOperation from 'shared/state/ducks/mating-batch/operations';
import * as moveEventOperation from 'shared/state/ducks/move-events/operations';
import { SyncFinished, SyncStarted } from 'shared/state/ducks/offline/operations';
import * as pregnancyOperation from 'shared/state/ducks/pregnancy-events/operations';
import { GetProcessEquipmentDataSyncData } from 'shared/state/ducks/process-equipment-data/operations';
import * as processEquipmentOperation from 'shared/state/ducks/process-equipments/operations';
import * as StationOperations from 'shared/state/ducks/station/operations';
import * as stemAnimalOperation from 'shared/state/ducks/stem-animals/operations';
import { SetCheckedCount } from 'shared/state/ducks/stem-animals/operations';
import * as treatmentDefinitionOperation from 'shared/state/ducks/treatment-definitions/operations';
import * as treatmentPlanOperation from 'shared/state/ducks/treatment-plan/operations';
import * as treatmentOperation from 'shared/state/ducks/treatment/operations';
import * as UnitsToPenOperations from 'shared/state/ducks/unit-to-pen/operations';
import * as validationSetupOperation from 'shared/state/ducks/validation-setup/operations';
import { GetSyncData as WorkListSettingGetSyncData } from 'shared/state/ducks/work-list-settings/operations';
import * as workListToTreatmentPlanOperation from 'shared/state/ducks/work-list-to-treatment-plan/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import PageContainer from 'web/view/components/page-container/page-container';
import { SkioldDatePicker } from 'web/view/components/skiold-components/skiold-date-picker/skiold-date-picker';
import { SkioldDropdown } from 'web/view/components/skiold-components/skiold-dropdown/skiold-dropdown';
import { SkioldModal } from 'web/view/components/skiold-components/skiold-modal/skiold-modal';
import SkioldTableItemCounter from 'web/view/components/skiold-components/skiold-table/skiold-table-item-counter';
import { SkioldTouchableOpacity } from 'web/view/components/skiold-components/skiold-touchable-opacity';
import { WhiteText } from 'web/view/components/Text/white-text';
import { Heading } from 'web/view/components/utils/heading';
import { SkioldIconSpinner } from 'web/view/components/utils/skiold-icon-spinner';
import { SkioldImage } from 'web/view/components/utils/svg/skiold-image';
import { ViewWeb } from 'web/view/components/utils/web-view';
import AllAnimalsEsfList, {
	AllAnimalsEsfList as AllAnimalsEsfListRef,
} from 'web/view/components/work-lists/all-animals-esf-work-list/all-animals-esf-list';
import { DepartureWorkList } from 'web/view/components/work-lists/departure-work-list/departure-work-list';
import { DepartureListTable as DepartureWorkListTableRef } from 'web/view/components/work-lists/departure-work-list/departure-work-list-table';
import FarrowingWorkList, {
	FarrowingWorkList as FarrowingWorkListRef,
} from 'web/view/components/work-lists/farrowing-work-list/farrowing-work-list';
import LardScanningEventWorkList from 'web/view/components/work-lists/lard-scanning-event-work-list/lard-scanning-event-work-list';
import { LardScanningEventListTable as LardScanningEventListTableRef } from 'web/view/components/work-lists/lard-scanning-event-work-list/lard-scanning-event-work-list-table';
import MatingWorkListTable, {
	MatingWorkListTable as MatingWorkListTableRef,
} from 'web/view/components/work-lists/mating-work-list/mating-work-list';
import MoveFromPregToPregListTable, {
	MoveFromPregToPregListTable as MoveFromPregToPregListTableRef,
} from 'web/view/components/work-lists/move-to/move-from-preg-to-preg-list';
import MoveMatingToMatingListTable, {
	MoveMatingToMatingListTable as MoveMatingToMatingListTableRef,
} from 'web/view/components/work-lists/move-to/move-mating-to-mating-list';
import MoveToFarrowingListTable, {
	MoveToFarrowingListTable as MoveToFarrowingListTableRef,
} from 'web/view/components/work-lists/move-to/move-to-farrowing-list';
import MoveToMatingListTable, {
	MoveToMatingListTable as MoveToMatingListTableRef,
} from 'web/view/components/work-lists/move-to/move-to-mating-list';
import MoveToPregnantListTable, {
	MoveToPregnantListTable as MoveToPregnantListTableRef,
} from 'web/view/components/work-lists/move-to/move-to-pregnant-list';
import ScanningWorkList from 'web/view/components/work-lists/scanning-work-list/scanning-work-list';
import { ScanningListTable as ScanningListTableRef } from 'web/view/components/work-lists/scanning-work-list/scanning-work-list-table';
import ShortageEsfList from 'web/view/components/work-lists/shortage-esf-work-list/shortage-esf-list';
import TasksInFarrowingStableList from 'web/view/components/work-lists/tasks-in-farrowing-stable-work-list/tasks-in-farrowing-stable-list';
import { TasksInFarrowingStableTable as TasksInFarrowingStableTableRef } from 'web/view/components/work-lists/tasks-in-farrowing-stable-work-list/tasks-in-farrowing-stable-table';
import VaccineListTable, {
	VaccineListTable as VaccineListTableRef,
} from 'web/view/components/work-lists/vaccine-work-list/vaccine-work-list-table';
import WeaningWorkListTable, {
	WeaningWorkListTable as WeaningWorkListTableRef,
} from 'web/view/components/work-lists/weaning-work-list/weaning-work-list';
import { generateActivityListOptions } from 'web/view/components/work-lists/work-list-helper';
import {
	GetAllAnimalsEsfWorkListPdf,
	GetFarrowingWorkListPdf,
	GetLardScanningEventWorkListPdf,
	GetMatingWorkListPdf,
	GetMovingWorkListPdf,
	GetScanningWorkListPdf,
	GetShortageEsfWorkListPdf,
	GetTasksInFarrowingStablePdf,
	GetVaccineWorkListPdf,
	GetWeaningWorkListPdf,
} from 'web/web-helpers/pdf-helper/work-list-pdf-helper';
import SowEvents from '../sow-events/sow-events';
import './work-lists.scss';
import * as genrealSettingsOperation from 'shared/state/ducks/general-settings/operations';
import { GetSyncData as ProfileGetSyncData } from 'shared/state/ducks/profile/operations';
import { SowTaskWorkListOverview } from 'web/view/components/work-lists/sow-tasks-work-list/sow-task-work-list-overview';
import { getBatchesToUseLardScanning } from 'shared/helpers/work-list-helpers/lard-scanning-event-list-helpers/lard-scanning-event-work-list-helper';
import { getCurrentWeekOption, getPrevPresentAndNextWeek } from 'shared/helpers/date-helpers';
const mapStateToProps = (state: WebAppState) => {
	return {
		workListSettings: state.workListSettings.entities,
		siteId: state.profile.active!.siteId,
		profile: state.profile.active!,
		processEquipments: state.processEquipments.entities,
		isSyncing: state.offline.isSyncing,
		pregnancyEvents: state.pregnancyEvents.entities,
		matingBatches: state.matingBatches.entities,
		stemAnimals: state.stemAnimals.entities,
		treatmentPlans: state.treatmentPlans.entities,
		treatments: state.treatments.entities,
		workListToTreatmentPlans: state.workListToTreatmentPlans.entities,
		moveEvents: state.moveEvents.entities,
		locations: state.locations,
		savedFunction: state.workListSettings.saveFunction,
		deleteFunction: state.workListSettings.deleteFunction,
		printFunction: state.workListSettings.printFunction,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	SetCheckedCount: (count: number, reset?: boolean) => SetCheckedCount(count, reset)(dispatch),
	getWorkListSettingsBySiteId: () => WorkListSettingGetSyncData()(dispatch),
	pregnancyEventGetSyncData: () => pregnancyOperation.GetSyncData()(dispatch),
	stemAnimalGetSyncData: () => stemAnimalOperation.GetSyncData()(dispatch),
	matingBatchGetSyncData: () => matingBatchOperation.GetSyncData()(dispatch),
	validationSetupGetSyncData: () => validationSetupOperation.GetSyncData()(dispatch),
	treatmentGetSyncData: () => treatmentOperation.GetSyncData()(dispatch),
	treatmentPlanGetSyncData: () => treatmentPlanOperation.GetSyncData()(dispatch),
	treatmentDefinitionGetSyncData: () => treatmentDefinitionOperation.GetSyncData()(dispatch),
	moveEventGetSyncData: () => moveEventOperation.GetSyncData()(dispatch),
	workListToTreatmentPlanGetSyncData: () => workListToTreatmentPlanOperation.GetSyncData()(dispatch),
	diagnoseGetSyncData: () => diagnoseOperation.GetSyncData()(dispatch),
	getLocations: () => GetLocations()(dispatch),
	getFeedingCurves: () => EsfStatusOperations.GetEsfFeedCurveSyncData()(dispatch),
	getFeedingStatus: () => EsfStatusOperations.GetEsfFeedingStatusSyncData()(dispatch),
	GetProcessEquipmentDataSyncData: () => GetProcessEquipmentDataSyncData()(dispatch),
	getunitsData: () => UnitsToPenOperations.GetSyncData()(dispatch),
	GetStationSyncData: () => StationOperations.GetStationSyncData()(dispatch),
	GetEsfDailyyncData: () => EsfDailyStatusOperations.GetEsfDailySyncData()(dispatch),
	getProcessEquipmentSyncData: () => processEquipmentOperation.GetSyncData()(dispatch),
	syncStarted: () => SyncStarted()(dispatch),
	syncFinished: () => SyncFinished()(dispatch),
	generalSettingsGetSyncData: () => genrealSettingsOperation.GetSyncData()(dispatch),
	getProfiles: () => ProfileGetSyncData()(dispatch),
});

export interface State {
	previousSelectedList: Option;
	selectedList: Option;
	selectedBatch: Option;
	selectedWeek: Option;
	entranceModalOpen: boolean;
	weaningModalOpen: boolean;
	selectedWorklistDate: Date;
	showSpinner: boolean;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
class WorkLists extends React.PureComponent<Props, State> {
	public WorkListTableRef:
		| MatingWorkListTableRef
		| WeaningWorkListTableRef
		| TasksInFarrowingStableTableRef
		| ScanningListTableRef
		| LardScanningEventListTableRef
		| MoveToPregnantListTableRef
		| MoveToFarrowingListTableRef
		| MoveToMatingListTableRef
		| VaccineListTableRef
		| AllAnimalsEsfListRef
		| MoveFromPregToPregListTableRef
		| MoveMatingToMatingListTableRef
		| DepartureWorkListTableRef
		| undefined;

	public FarrowingWorkListRef: FarrowingWorkListRef | undefined;
	public FunctionalComponentRef: React.RefObject<{ save: any; reset: any }> | undefined;

	private generateData = memoize((workListSettings, processEquipments) => generateActivityListOptions());
	constructor(props: Props) {
		super(props);
		this.FunctionalComponentRef = React.createRef();
		this.state = {
			previousSelectedList: { label: '', value: '' },
			selectedList: { label: '', value: '' },
			selectedBatch: { label: '', value: '' },
			entranceModalOpen: false,
			weaningModalOpen: false,
			selectedWorklistDate: new Date(),
			showSpinner: true,
			selectedWeek: getCurrentWeekOption(),
		};
		this.syncWorkList();
	}

	public syncWorkList = async () => {
		let promisesToAwait: Array<Promise<any>> = [];
		promisesToAwait.push(this.props.getWorkListSettingsBySiteId());
		promisesToAwait.push(this.props.matingBatchGetSyncData());
		promisesToAwait.push(this.props.pregnancyEventGetSyncData());
		promisesToAwait.push(this.props.stemAnimalGetSyncData());
		promisesToAwait.push(this.props.validationSetupGetSyncData());
		promisesToAwait.push(this.props.diagnoseGetSyncData());
		promisesToAwait.push(this.props.moveEventGetSyncData());
		promisesToAwait.push(this.props.treatmentDefinitionGetSyncData());
		promisesToAwait.push(this.props.treatmentGetSyncData());
		promisesToAwait.push(this.props.treatmentPlanGetSyncData());
		promisesToAwait.push(this.props.workListToTreatmentPlanGetSyncData());
		promisesToAwait.push(this.props.getLocations());
		promisesToAwait.push(this.props.getFeedingCurves());
		promisesToAwait.push(this.props.getFeedingStatus());
		promisesToAwait.push(this.props.GetProcessEquipmentDataSyncData());
		promisesToAwait.push(this.props.getunitsData());
		promisesToAwait.push(this.props.GetStationSyncData());
		promisesToAwait.push(this.props.GetEsfDailyyncData());
		promisesToAwait.push(this.props.getProcessEquipmentSyncData());
		promisesToAwait.push(this.props.generalSettingsGetSyncData());
		promisesToAwait.push(this.props.getProfiles());
		Promise.all(promisesToAwait).then(this.toggleSpinner);
	};

	public toggleSpinner = () => {
		this.setState({ showSpinner: !this.state.showSpinner });
	};

	public download = async () => {
		if (this.state.selectedList.value === WorkListType.FarrowingListSetting) {
			await GetFarrowingWorkListPdf(
				this.FarrowingWorkListRef!.GetSortedData(),
				'FarrowingWorkList.pdf',
				this.props.profile.siteId!,
				Intl.DateTimeFormat().resolvedOptions().timeZone,
				this.props.profile.language,
			);
		} else if (this.state.selectedList.value === WorkListType.MatingListSetting) {
			await GetMatingWorkListPdf(
				this.WorkListTableRef!.SkioldTableRef!.GetSortedData(),
				'MatingWorkList.pdf',
				this.props.profile.siteId!,
				Intl.DateTimeFormat().resolvedOptions().timeZone,
				this.props.profile.language,
			);
		} else if (this.state.selectedList.value === WorkListType.WeaningListSetting) {
			await GetWeaningWorkListPdf(
				this.WorkListTableRef!.SkioldTableRef!.GetSortedData(),
				'WeaningWorkList.pdf',
				this.props.profile.siteId!,
				Intl.DateTimeFormat().resolvedOptions().timeZone,
				this.props.profile.language,
			);
		} else if (
			this.state.selectedList.value === WorkListType.ScanningListSetting ||
			this.state.selectedList.value === WorkListType.ScanningListSetting2
		) {
			await GetScanningWorkListPdf(
				this.WorkListTableRef!.SkioldTableRef!.GetSortedData(),
				(this.WorkListTableRef! as ScanningListTableRef).getWorkListSetting(),
				'ScanningWorkList.pdf',
				this.props.profile.siteId!,
				Intl.DateTimeFormat().resolvedOptions().timeZone,
				this.props.profile.language,
			);
		} else if (
			this.state.selectedList.value === WorkListType.TasksInFarrowingStableSetting ||
			this.state.selectedList.value === WorkListType.TasksInFarrowingStableSetting2
		) {
			await GetTasksInFarrowingStablePdf(
				this.WorkListTableRef!.SkioldTableRef!.GetSortedData(),
				(this.WorkListTableRef! as TasksInFarrowingStableTableRef).getWorkListSetting(),
				'TasksInFarrowingStable.pdf',
				this.props.profile.siteId!,
				Intl.DateTimeFormat().resolvedOptions().timeZone,
				this.props.profile.language,
			);
		} else if (this.state.selectedList.value === WorkListType.AllAnimalsEsfListSetting) {
			await GetAllAnimalsEsfWorkListPdf(
				this.WorkListTableRef!.SkioldTableRef!.SkioldTableRef!.GetSortedData(),
				'AllAnimalsESF.pdf',
				this.props.profile.siteId!,
				Intl.DateTimeFormat().resolvedOptions().timeZone,
				this.props.profile.language,
			);
		} else if (this.state.selectedList.value === WorkListType.ShortageEsfListSetting) {
			await GetShortageEsfWorkListPdf(
				this.WorkListTableRef!.SkioldTableRef!.SkioldTableRef!.GetSortedData(),
				'ShortageESF.pdf',
				this.props.profile.siteId!,
				Intl.DateTimeFormat().resolvedOptions().timeZone,
				this.props.profile.language,
			);
		} else if (
			this.state.selectedList.value === WorkListType.VaccineListSetting1 ||
			this.state.selectedList.value === WorkListType.VaccineListSetting2 ||
			this.state.selectedList.value === WorkListType.VaccineListSetting3
		) {
			await GetVaccineWorkListPdf(
				this.WorkListTableRef!.SkioldTableRef!.GetSortedData(),
				(this.WorkListTableRef! as VaccineListTableRef).getWorkListSetting(),
				'VaccineWorkList.pdf',
				this.props.profile.siteId!,
				Intl.DateTimeFormat().resolvedOptions().timeZone,
				this.props.profile.language,
			);
		} else if (this.state.selectedList.value === WorkListType.MoveToPregnancyLocation) {
			await GetMovingWorkListPdf(
				this.WorkListTableRef!.SkioldTableRef!.SkioldTableRef!.GetSortedData(),
				'MoveToPregnancyListWorkList.pdf',
				'MoveToPregnancyList',
				this.props.profile.siteId!,
				Intl.DateTimeFormat().resolvedOptions().timeZone,
				this.props.profile.language,
			);
		} else if (this.state.selectedList.value === WorkListType.MoveToFarrowingLocation) {
			await GetMovingWorkListPdf(
				this.WorkListTableRef!.SkioldTableRef!.SkioldTableRef!.GetSortedData(),
				'MoveToFarrowingListWorkList.pdf',
				'MoveToFarrowingList',
				this.props.profile.siteId!,
				Intl.DateTimeFormat().resolvedOptions().timeZone,
				this.props.profile.language,
			);
		} else if (this.state.selectedList.value === WorkListType.MoveToMatingLocation) {
			await GetMovingWorkListPdf(
				this.WorkListTableRef!.SkioldTableRef!.SkioldTableRef!.GetSortedData(),
				'MoveToMatingListWorkList.pdf',
				'MoveToMatingList',
				this.props.profile.siteId!,
				Intl.DateTimeFormat().resolvedOptions().timeZone,
				this.props.profile.language,
			);
		} else if (this.state.selectedList.value === WorkListType.MoveFromPregToPregSetting) {
			await GetMovingWorkListPdf(
				this.WorkListTableRef!.SkioldTableRef!.SkioldTableRef!.GetSortedData(),
				'MoveFromPregToPregWorkList.pdf',
				'MoveFromPregToPregSetting',
				this.props.profile.siteId!,
				Intl.DateTimeFormat().resolvedOptions().timeZone,
				this.props.profile.language,
			);
		} else if (this.state.selectedList.value === WorkListType.MoveMatingToMatingSetting) {
			await GetMovingWorkListPdf(
				this.WorkListTableRef!.SkioldTableRef!.SkioldTableRef!.GetSortedData(),
				'MoveMatingToMatingSetting.pdf',
				'MoveMatingToMatingSetting',
				this.props.profile.siteId!,
				Intl.DateTimeFormat().resolvedOptions().timeZone,
				this.props.profile.language,
			);
		} else if (
			this.state.selectedList.value === WorkListType.LardScanningEventListSetting ||
			this.state.selectedList.value === WorkListType.LardScanningEventListSetting2
		) {
			await GetLardScanningEventWorkListPdf(
				this.WorkListTableRef!.SkioldTableRef!.GetSortedData(),
				'LardScanningEventWorkList.pdf',
				this.props.profile.siteId!,
				Intl.DateTimeFormat().resolvedOptions().timeZone,
				this.props.profile.language,
			);
		} else if (this.state.selectedList.value === WorkListType.DepartureWorkListSetting) {
			if (this.props.printFunction) {
				await this.props.printFunction();
			}
		}
	};

	public render() {
		return (
			<PageContainer className="work-lists">
				<Heading text={localized('ChooseWorkLists')} />
				<ViewWeb className="ViewContainerStyle">
					{this.state.showSpinner && this.renderSpinner()}
					{!this.state.showSpinner && this.renderTopContainer()}
					{!this.state.showSpinner && this.renderTables()}
				</ViewWeb>

				{this.state.selectedList.value === WorkListType.MatingListSetting && (
					<SkioldModal padding="0" isOpen={this.state.entranceModalOpen} close={this.closeModal}>
						<SowEvents shouldCloseOnSave={true} closeEditModal={this.closeModal} theme="dark" />
					</SkioldModal>
				)}
				{this.state.selectedList.value === WorkListType.WeaningListSetting && (
					<SkioldModal padding="0" isOpen={this.state.weaningModalOpen} close={this.closeWeaningModal}>
						<SowEvents
							shouldCloseOnSave={true}
							closeEditModal={this.closeWeaningModal}
							theme="dark"
							setEventOption={{ label: localized('Weaning'), value: 'weaning' }}
						/>
					</SkioldModal>
				)}
			</PageContainer>
		);
	}

	public renderSpinner = () => {
		return (
			<ViewWeb className="work-list-spinner">
				<ClipLoader color="#f2ac40" size={50} />
			</ViewWeb>
		);
	};

	public renderTables = () => {
		return (
			<ViewWeb style={{ alignSelf: 'center' }}>
				{this.state.selectedList.value === WorkListType.MoveToPregnancyLocation && (
					<MoveToPregnantListTable ref={this.setRefWithWrappedInstance} />
				)}
				{this.state.selectedList.value === WorkListType.MoveToFarrowingLocation && (
					<MoveToFarrowingListTable ref={this.setRefWithWrappedInstance} />
				)}
				{this.state.selectedList.value === WorkListType.MoveToMatingLocation && (
					<MoveToMatingListTable ref={this.setRefWithWrappedInstance} />
				)}

				{this.state.selectedList.value === WorkListType.FarrowingListSetting && (
					<FarrowingWorkList ref={this.setRefForFarrowingWorklist} />
				)}
				{this.state.selectedList.value === WorkListType.DepartureWorkListSetting && (
					<DepartureWorkList refCallback={this.setRef} />
				)}
				{this.state.selectedList.value === WorkListType.MatingListSetting && (
					<MatingWorkListTable
						ref={this.setRefWithWrappedInstance}
						defaultDate={this.state.selectedWorklistDate}
					/>
				)}
				{this.state.selectedList.value === WorkListType.WeaningListSetting && (
					<WeaningWorkListTable
						ref={this.setRefWithWrappedInstance}
						defaultDate={this.state.selectedWorklistDate}
					/>
				)}
				{this.state.selectedList.value === WorkListType.ScanningListSetting && (
					<ScanningWorkList
						batch={this.state.selectedBatch}
						choosedSetting={WorkListType.ScanningListSetting}
						refCallback={this.setRef}
					/>
				)}
				{this.state.selectedList.value === WorkListType.ScanningListSetting2 && (
					<ScanningWorkList
						batch={this.state.selectedBatch}
						choosedSetting={WorkListType.ScanningListSetting2}
						refCallback={this.setRef}
					/>
				)}
				{this.state.selectedList.value === WorkListType.AllAnimalsEsfListSetting && (
					<AllAnimalsEsfList ref={this.setRefWithWrappedInstance} />
				)}
				{this.state.selectedList.value === WorkListType.ShortageEsfListSetting && (
					<ShortageEsfList ref={this.setRefWithWrappedInstance} />
				)}
				{this.state.selectedList.value === WorkListType.VaccineListSetting1 && (
					<VaccineListTable
						ref={this.setRefWithWrappedInstance}
						batch={this.state.selectedBatch}
						week={this.state.selectedWeek}
						workListSetting={
							this.props.workListSettings.find(
								setting => setting.type === this.state.selectedList.value,
							)! as VaccineListSetting1
						}
					/>
				)}
				{this.state.selectedList.value === WorkListType.VaccineListSetting2 && (
					<VaccineListTable
						ref={this.setRefWithWrappedInstance}
						batch={this.state.selectedBatch}
						week={this.state.selectedWeek}
						workListSetting={
							this.props.workListSettings.find(
								setting => setting.type === this.state.selectedList.value,
							)! as VaccineListSetting2
						}
					/>
				)}
				{this.state.selectedList.value === WorkListType.VaccineListSetting3 && (
					<VaccineListTable
						ref={this.setRefWithWrappedInstance}
						batch={this.state.selectedBatch}
						week={this.state.selectedWeek}
						workListSetting={
							this.props.workListSettings.find(
								setting => setting.type === this.state.selectedList.value,
							)! as VaccineListSetting3
						}
					/>
				)}
				{this.state.selectedList.value === WorkListType.TasksInFarrowingStableSetting && (
					<TasksInFarrowingStableList
						refCallback={this.setRef}
						choosedSetting={WorkListType.TasksInFarrowingStableSetting}
					/>
				)}
				{this.state.selectedList.value === WorkListType.TasksInFarrowingStableSetting2 && (
					<TasksInFarrowingStableList
						refCallback={this.setRef}
						choosedSetting={WorkListType.TasksInFarrowingStableSetting2}
					/>
				)}
				{this.state.selectedList.value === WorkListType.MoveFromPregToPregSetting && (
					<MoveFromPregToPregListTable ref={this.setRefWithWrappedInstance} />
				)}
				{this.state.selectedList.value === WorkListType.MoveMatingToMatingSetting && (
					<MoveMatingToMatingListTable ref={this.setRefWithWrappedInstance} />
				)}
				{this.state.selectedList.value === WorkListType.LardScanningEventListSetting && (
					<LardScanningEventWorkList
						choosedSetting={WorkListType.LardScanningEventListSetting}
						refCallback={this.setRef}
						batch={this.state.selectedBatch}
					/>
				)}
				{this.state.selectedList.value === WorkListType.LardScanningEventListSetting2 && (
					<LardScanningEventWorkList
						choosedSetting={WorkListType.LardScanningEventListSetting2}
						refCallback={this.setRef}
						batch={this.state.selectedBatch}
					/>
				)}
				{this.state.selectedList.value === WorkListType.SowTaskSettings1 && (
					<SowTaskWorkListOverview
						ref={this.FunctionalComponentRef}
						setting={
							this.props.workListSettings.find(
								setting => setting.type === this.state.selectedList.value,
							)! as SowTaskSettings1
						}
					/>
				)}
				{this.state.selectedList.value === WorkListType.SowTaskSettings2 && (
					<SowTaskWorkListOverview
						ref={this.FunctionalComponentRef}
						setting={
							this.props.workListSettings.find(
								setting => setting.type === this.state.selectedList.value,
							)! as SowTaskSettings2
						}
					/>
				)}
			</ViewWeb>
		);
	};

	public renderTopContainer = () => {
		return (
			<ViewWeb className="ContentContainerStyle">
				<ViewWeb className="dummy-filler">
					{this.state.selectedList.value !== '' &&
						this.state.selectedList.value !== WorkListType.MoveToMatingLocation &&
						this.state.selectedList.value !== WorkListType.MoveToFarrowingLocation &&
						this.state.selectedList.value !== WorkListType.MoveToPregnancyLocation &&
						this.state.selectedList.value !== WorkListType.MoveFromPregToPregSetting &&
						this.state.selectedList.value !== WorkListType.MoveMatingToMatingSetting && (
							<div className="tableCounterContainer">
								<div className="tableCounterLabel">{localized('Animals')}</div>
								<SkioldTableItemCounter
									useChecked={this.state.selectedList.value !== WorkListType.FarrowingListSetting}
									className="tableCounterValue"
								/>
								{(this.state.selectedList.value === WorkListType.WeaningListSetting ||
									this.state.selectedList.value === WorkListType.MatingListSetting) && (
									<SkioldDatePicker
										onDateChanged={this.worklistDateChanged}
										selectedDate={this.state.selectedWorklistDate}
										text={
											this.state.selectedList.value === WorkListType.WeaningListSetting
												? 'dateForWeaning'
												: 'dateForMating'
										}
										containerClassName="worklist-datepicker-container"
										maxDate={
											this.state.selectedList.value === WorkListType.WeaningListSetting
												? this.allowedWeaningDate()
												: new Date()
										}
									/>
								)}
								{(this.state.selectedList.value === WorkListType.ScanningListSetting ||
									this.state.selectedList.value === WorkListType.ScanningListSetting2 ||
									((this.state.selectedList.value === WorkListType.LardScanningEventListSetting ||
										this.state.selectedList.value === WorkListType.LardScanningEventListSetting2) &&
										(
											this.props.workListSettings.find(
												wls => wls.type === this.state.selectedList.value,
											) as ILardScanningEventListSettingBase
										).sowState !== SowState.Dry)) && (
									<>
										<div className="tableCounterLabel marginLeftTen">{localized('Batch')}</div>
										<SkioldDropdown
											onValueChanged={this.selectBatch}
											selectedValue={this.state.selectedBatch}
											items={this.GetBatchOptions(
												this.state.selectedList.value,
												this.state.previousSelectedList.value as WorkListType,
											)}
											containerClassName="dropdown-container"
										/>
									</>
								)}
								{this.state.selectedList.value === WorkListType.VaccineListSetting1 ||
								this.state.selectedList.value === WorkListType.VaccineListSetting2 ||
								this.state.selectedList.value === WorkListType.VaccineListSetting3 ? (
									(
										this.props.workListSettings.find(
											wls => wls.type === this.state.selectedList.value,
										) as IVaccineListSetting
									).vaccinePregnancyType !== VaccinePregnancyType.AgeYoungFemale ? (
										this.GetBatchOptions(
											this.state.selectedList.value,
											this.state.previousSelectedList.value as WorkListType,
										).length > 0 && (
											<>
												<div className="tableCounterLabel marginLeftTen">
													{localized('Batch')}
												</div>
												<SkioldDropdown
													onValueChanged={this.selectBatch}
													selectedValue={this.state.selectedBatch}
													items={this.GetBatchOptions(
														this.state.selectedList.value,
														this.state.previousSelectedList.value as WorkListType,
													)}
													containerClassName="dropdown-container"
												/>
											</>
										)
									) : (
										<>
											<div className="tableCounterLabel marginLeftTen">{localized('week')}</div>
											<SkioldDropdown
												onValueChanged={this.selectWeek}
												selectedValue={this.state.selectedWeek}
												items={getPrevPresentAndNextWeek()}
												containerClassName="dropdown-container"
											/>
										</>
									)
								) : null}
							</div>
						)}
				</ViewWeb>
				<ViewWeb className="HeaderContainerStyle">
					<ViewWeb>
						<SkioldDropdown
							onValueChanged={this.selectedWorklistChanged}
							selectedValue={this.state.selectedList}
							items={this.generateData(this.props.workListSettings, this.props.processEquipments)}
							containerClassName="dropdown-container"
						/>
					</ViewWeb>
				</ViewWeb>
				<ViewWeb className="HeaderContainerButtonStyle">
					<ViewWeb>
						<ViewWeb className="HeaderItemStyle">
							{this.state.selectedList.value === WorkListType.MatingListSetting && (
								<ViewWeb className="buttonsHeaderViewBorderStyle">
									<ViewWeb className="PrintWorkListStyle">
										<SkioldTouchableOpacity onPress={this.openModal}>
											<SkioldImage width="60" height="60" imageData={CreateAnimal} />
										</SkioldTouchableOpacity>
										<WhiteText className="white-text-container">
											{localized('CreateAnimal')}
										</WhiteText>
									</ViewWeb>
								</ViewWeb>
							)}
							{this.state.selectedList.value &&
								this.state.selectedList.value !== WorkListType.FarrowingListSetting &&
								this.state.selectedList.value !== WorkListType.DepartureWorkListSetting && (
									<ViewWeb className="buttonsHeaderViewBorderStyle">
										<ViewWeb className="PrintWorkListStyle">
											<SkioldTouchableOpacity onPress={this.resetDataWorkLists}>
												<SkioldImage width="60" height="60" imageData={CancelIcon} />
											</SkioldTouchableOpacity>

											<WhiteText className="white-text-container">
												{localized('Cancel')}
											</WhiteText>
										</ViewWeb>
									</ViewWeb>
								)}
							{this.state.selectedList.value &&
								this.state.selectedList.value === WorkListType.DepartureWorkListSetting && (
									<ViewWeb className="buttonsHeaderViewBorderStyle">
										<ViewWeb className="PrintWorkListStyle">
											<SkioldIconSpinner
												title={'delete'}
												icon={DeleteIcon}
												onPress={this.deleteCheckedWorkLists}
											/>
										</ViewWeb>
									</ViewWeb>
								)}
							{this.state.selectedList.value &&
								this.state.selectedList.value === WorkListType.WeaningListSetting && (
									<ViewWeb className="buttonsHeaderViewBorderStyle">
										<ViewWeb className="PrintWorkListStyle">
											<SkioldIconSpinner
												title={'newSow'}
												icon={CreateAnimal}
												onPress={this.openWeaningModal}
											/>
										</ViewWeb>
									</ViewWeb>
								)}
							{this.state.selectedList.value &&
								this.state.selectedList.value !== WorkListType.FarrowingListSetting && (
									<ViewWeb className="buttonsHeaderViewBorderStyle">
										<ViewWeb className="PrintWorkListStyle">
											<SkioldIconSpinner
												title={'Save'}
												icon={SaveGreyIcon}
												onPress={this.saveWorkLists}
											/>
										</ViewWeb>
									</ViewWeb>
								)}
							{this.state.selectedList.value !== '' &&
								this.state.selectedList.value !== WorkListType.SowTaskSettings1 &&
								this.state.selectedList.value !== WorkListType.SowTaskSettings2 && (
									<ViewWeb className="PrintWorkListViewStyle">
										<ViewWeb className="PrintWorkListStyle">
											<SkioldIconSpinner
												title={'Print'}
												icon={PrinterGreyIcon}
												onPress={this.download}
											/>
										</ViewWeb>
									</ViewWeb>
								)}
						</ViewWeb>
					</ViewWeb>
				</ViewWeb>
			</ViewWeb>
		);
	};

	private GetBatchOptions = memoize((selectedList: WorkListType, previousSelectedList: WorkListType) => {
		if (selectedList === WorkListType.ScanningListSetting || selectedList === WorkListType.ScanningListSetting2) {
			let worklistSetting = this.props.workListSettings.find(wls => wls.type === this.state.selectedList.value);
			let current: IMatingBatch = new MatingBatch();
			let batchesInUse: IMatingBatch[] = [];
			if (worklistSetting) {
				let batchesInUseResult = getBatchesToUseReal(
					this.props.matingBatches,
					worklistSetting as IScanningListSettingBase,
				);
				batchesInUse = batchesInUseResult.matingBatchesToUse;
				current = batchesInUseResult.currentBatch;
			}

			if (
				batchesInUse.length > 0 &&
				worklistSetting &&
				(worklistSetting as IScanningListSettingBase).daysAfterMating
			) {
				const newDay = new Date().withoutTime();
				newDay.setDate(newDay.getDate() - (worklistSetting as IScanningListSettingBase).daysAfterMating!);

				if (current) {
					const option = {
						value: current.batchNumber!.toString(),
						label: current.batchNumber!.toString(),
					};
					this.setState({ selectedBatch: option });
				}
			}

			return batchesInUse.map(bn => ({ label: bn.batchNumber!.toString(), value: bn.batchNumber!.toString() }));
		} else if (
			selectedList === WorkListType.LardScanningEventListSetting ||
			selectedList === WorkListType.LardScanningEventListSetting2
		) {
			let worklistSetting = this.props.workListSettings.find(wls => wls.type === this.state.selectedList.value);
			let current: IMatingBatch = new MatingBatch();
			let batchesInUse: IMatingBatch[] = [];
			if (worklistSetting) {
				let batchesInUseResult = getBatchesToUseLardScanning(
					this.props.matingBatches,
					worklistSetting as ILardScanningEventListSettingBase,
				);
				batchesInUse = batchesInUseResult.matingBatchesToUse;
				current = batchesInUseResult.currentBatch;
			}

			if (
				batchesInUse.length > 0 &&
				worklistSetting &&
				(worklistSetting as ILardScanningEventListSettingBase).weeksAfterEvent
			) {
				const newDay = new Date().withoutTime();
				newDay.setDate(
					newDay.getDate() - (worklistSetting as ILardScanningEventListSettingBase).weeksAfterEvent! * 7,
				);

				if (current) {
					const option = {
						value: current.batchNumber!.toString(),
						label: current.batchNumber!.toString(),
					};
					this.setState({ selectedBatch: option });
				}
			}

			return batchesInUse.map(bn => ({ label: bn.batchNumber!.toString(), value: bn.batchNumber!.toString() }));
		} else if (
			selectedList === WorkListType.VaccineListSetting1 ||
			selectedList === WorkListType.VaccineListSetting2 ||
			selectedList === WorkListType.VaccineListSetting3
		) {
			let worklistSetting = this.props.workListSettings.find(wls => wls.type === this.state.selectedList.value);

			let batchesInUse: IMatingBatch[] = [];
			let current: IMatingBatch = new MatingBatch();
			if (worklistSetting) {
				let batchesInUseResult = getBatchesToUseVaccine(
					this.props.matingBatches,
					worklistSetting as IVaccineListSetting,
				);
				batchesInUse = batchesInUseResult.matingBatchesToUse;
				current = batchesInUseResult.currentBatch;
			}

			if (
				batchesInUse.length > 0 &&
				((current.batchNumber !== undefined &&
					current.batchNumber.toString() !== this.state.selectedBatch.value) ||
					this.state.selectedBatch.value === '')
			) {
				const option = {
					value: current.batchNumber!.toString(),
					label: current.batchNumber!.toString(),
				};
				this.setState({ selectedBatch: option });
			}

			return batchesInUse.map(bn => ({ label: bn.batchNumber!.toString(), value: bn.batchNumber!.toString() }));
		}
		return [];
	});

	public allowedWeaningDate = () => {
		const todaysDatePlus3 = new Date();
		todaysDatePlus3.setDate(todaysDatePlus3.getDate() + 3);
		return todaysDatePlus3;
	};

	public worklistDateChanged = (newDate: Date) => {
		this.setState({ selectedWorklistDate: newDate });
	};

	public setRefForFarrowingWorklist = (m: any) => {
		if (m) {
			this.FarrowingWorkListRef = m;
		}
	};
	public setRefWithWrappedInstance = (m: any) => {
		if (m) {
			this.WorkListTableRef = m;
		}
	};
	public setRef = (ref: any) => (this.WorkListTableRef = ref);

	public resetDataWorkLists = async () => {
		if (
			this.state.selectedList.value === WorkListType.MatingListSetting ||
			this.state.selectedList.value === WorkListType.WeaningListSetting ||
			this.state.selectedList.value === WorkListType.TasksInFarrowingStableSetting ||
			this.state.selectedList.value === WorkListType.TasksInFarrowingStableSetting2 ||
			this.state.selectedList.value === WorkListType.ScanningListSetting ||
			this.state.selectedList.value === WorkListType.ScanningListSetting2 ||
			this.state.selectedList.value === WorkListType.VaccineListSetting1 ||
			this.state.selectedList.value === WorkListType.VaccineListSetting2 ||
			this.state.selectedList.value === WorkListType.VaccineListSetting3 ||
			this.state.selectedList.value === WorkListType.AllAnimalsEsfListSetting ||
			this.state.selectedList.value === WorkListType.ShortageEsfListSetting
		) {
			this.WorkListTableRef!.ResetWorkList();
		} else if (
			(this.state.selectedList.value === WorkListType.SowTaskSettings1 ||
				this.state.selectedList.value === WorkListType.SowTaskSettings2) &&
			this.FunctionalComponentRef
		) {
			this.FunctionalComponentRef.current?.reset();
		} else if (this.state.selectedList.value === WorkListType.MoveToPregnancyLocation) {
			await (this.WorkListTableRef! as MoveToPregnantListTableRef).ResetWorkList();
		} else if (this.state.selectedList.value === WorkListType.MoveToFarrowingLocation) {
			await (this.WorkListTableRef! as MoveToFarrowingListTableRef).ResetWorkList();
		} else if (this.state.selectedList.value === WorkListType.MoveToMatingLocation) {
			await (this.WorkListTableRef! as MoveToMatingListTableRef).ResetWorkList();
		} else if (this.state.selectedList.value === WorkListType.MoveFromPregToPregSetting) {
			await (this.WorkListTableRef! as MoveFromPregToPregListTableRef).ResetWorkList();
		}
	};

	public saveWorkLists = async () => {
		if (this.state.selectedList.value === WorkListType.DepartureWorkListSetting && this.props.savedFunction) {
			this.props.savedFunction();
		} else if (
			(this.state.selectedList.value === WorkListType.SowTaskSettings1 ||
				this.state.selectedList.value === WorkListType.SowTaskSettings2) &&
			this.FunctionalComponentRef
		) {
			this.FunctionalComponentRef.current?.save();
		} else if (
			this.state.selectedList.value &&
			this.state.selectedList.value !== WorkListType.FarrowingListSetting
		) {
			await this.WorkListTableRef!.SaveWorklist();
			this.props.SetCheckedCount(0, true);
		}
	};

	public deleteCheckedWorkLists = async () => {
		if (this.state.selectedList.value === WorkListType.DepartureWorkListSetting && this.props.deleteFunction) {
			this.props.deleteFunction();
		} else if (
			this.state.selectedList.value &&
			this.state.selectedList.value === WorkListType.DepartureWorkListSetting
		) {
			await (this.WorkListTableRef! as DepartureWorkListTableRef).DeleteCheckedWorklist();
		}
	};

	private selectedWorklistChanged = async (selected: Option) => {
		if (this.WorkListTableRef !== undefined) {
			let shouldChangeWorklist = await this.WorkListTableRef!.CheckSavePregnancyEventListsStatus();
			if (shouldChangeWorklist) {
				this.setState({
					selectedBatch: { label: '', value: '' },
					selectedList: selected,
					previousSelectedList: this.state.selectedList,
					selectedWorklistDate: new Date(),
				});
			}
		} else {
			this.setState({
				selectedList: selected,
				previousSelectedList: this.state.selectedList,
				selectedWorklistDate: new Date(),
			});
		}
		this.props.SetCheckedCount(0, true);
	};

	private selectBatch = (selectedBatch: Option) => {
		this.setState({ selectedBatch });
	};

	private selectWeek = (selectedWeek: Option) => {
		this.setState({ selectedWeek });
	};

	private closeModal = () => {
		this.setState({ entranceModalOpen: false });
	};

	private openModal = () => {
		this.setState({ entranceModalOpen: true });
	};

	private closeWeaningModal = () => {
		this.setState({ weaningModalOpen: false });
	};

	private openWeaningModal = () => {
		this.setState({ weaningModalOpen: true });
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkLists);
