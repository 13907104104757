import React from 'react';
import { SkioldInput } from '../skiold-input/skiold-input';
import { DecimalNumberChanged } from 'shared/helpers/number-helper';

export interface PropsFromParent {
	onChangeNumber: (newText: number | undefined) => void;
	label: string;
	text?: number;
	theme?: 'light' | 'dark';
	unit?: string;
	disabled?: boolean;
	style?: any;
	className?: string;
}

interface State {
	text?: string;
}

export class SkioldDecimalInput extends React.PureComponent<PropsFromParent, State> {
	public static defaultProps: Partial<PropsFromParent> = {
		className: ''
	};

	constructor(props: PropsFromParent) {
		super(props);

		this.state = {
			text: props.text ? props.text.toString() : undefined
		};
	}

	public componentDidUpdate(prevProps: PropsFromParent, prevState: State) {
		if(this.props.text !== prevProps.text){
			this.setState({ text: this.props.text !== undefined ? this.props.text.toString() : undefined });
		}
	}

	public render() {
		return (
			<SkioldInput
				style={this.props.style}
				onChangeText={this.onTextChanged}
				label={this.props.label}
				text={this.state.text ? this.state.text : ' '}
				theme={this.props.theme}
				suffix={this.props.unit}
				editable={!this.props.disabled}
				className={this.props.className ? this.props.className:''}
			/>
		);
	}

	private onTextChanged = (newText: string) => {
		const textstring = newText
			.replace(/(?!^)-/g, '') // removes any - that is not first character in string
			.replace(/,/g, '.') // replaces , with .
			.replace(/\..*/, c => '.' + c.replace(/\./g, () => '')) // ensures there is at most 1 . in string
			.replace(/ /g, ''); // replaces any spaces with nothing

		let rgx = /^-?[0-9]*\.?[0-9]*$/;

		if (textstring.match(rgx)) {
			this.setState({
				text: textstring
			});
			DecimalNumberChanged(textstring, valueToSet => {
					this.props.onChangeNumber(valueToSet);
			});
		}
	};
}
