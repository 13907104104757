import { Syncable } from "shared/state/models/syncable";
import { ITreatment } from "shared/api/api";


export const SAVE_TREATMENT = 'SAVE_TREATMENT';
export const CREATE_TREATMENTS = 'CREATE_TREATMENTS';
export const GET_TREATMENTS_LOG = 'GET_TREATMENTS_LOG';
export const SAVE_LOG_TREATMENT = 'SAVE_LOG_TREATMENT';
export const SYNC_DATA_TREATMENT = 'SYNC_DATA_TREATMENT';
export const REMOVE_TREATMENTS_BY_ANIMAL = 'REMOVE_TREATMENTS_BY_ANIMAL';
export const GET_TREATMENTS_BY_STEMANIMAL_ID = 'GET_TREATMENTS_BY_STEMANIMAL_ID';
export const SAVE_MANY_TREATMENTS = 'SAVE_MANY_TREATMENTS';

export interface TreatmentState extends Syncable<ITreatment> {
    treatmentsForLog: ITreatment[];
    fetchingTreatmentsForLog: boolean;
}
