import { IUserSiteDTO } from 'shared/api/api';

export const GET_CURRENT_USER_SITES = 'GET_CURRENT_USER_SITES';
export const GET_USER_SITES_FOR_USER = 'GET_USER_SITES_FOR_USER';
export const REMOVE_USER_SITE_ACCESS = 'REMOVE_USER_SITE_ACCESS';

export interface UserSiteState {
	userSites: IUserSiteDTO[];
	loading: boolean;
}
