import React from 'react';
import { IFeedingReportItem } from 'shared/api/api';
import { commaDotFilterMethod, setTwoDecimalsAsNumber } from 'shared/helpers/general-helpers';
import { localized } from 'shared/state/i18n/i18n';
import SkioldTableGrid, {
	SkioldTableGrid as SkioldTableRef
} from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { getWebAnimalColorStyleTableCellColorStyle } from 'web/web-helpers/general-web-helpers';
import './feeding-report-table.scss';

interface PropsFromParent {
	headers: IFeedingReportItem | undefined;
	feedingReportData: IFeedingReportItem[] | undefined;
}

export interface State {
	columns: any[];
}
type Props = PropsFromParent;
export class TableFeedingReport extends React.PureComponent<Props, State> {
	public SkioldTableRef: SkioldTableRef | undefined;

	private readonly getTotalFeed = (item: IFeedingReportItem) => {
		return setTwoDecimalsAsNumber(item && item.totalFeed);
	};

	constructor(props: Props) {
		super(props);
		this.state = { columns: [] };
	}

	public render() {
		return (
			<ViewWeb className="feeding-report-table">
				<SkioldTableGrid
					columns={this.generateColumns()}
					tableKey={'feedingReport'}
					ref={this.setRef}
					data={this.props.feedingReportData}
					ColumnExtensions={this.getColumnExte()}
					showPagination={true}
				/>
			</ViewWeb>
		);
	}

	private setRef = (m: any) => {
		if (m) {
			this.SkioldTableRef = m;
		}
	};

	private generateColumns = () => {
		let columns: any[] = [
			{
				name: 'location',
				title: localized('location'),
			},
			{
				name: 'days',
				title: localized('Days'),
			},
			{
				name: 'animalNumber',
				title: localized('animalNr'),
			},
		];

		for (let i = 0; i < 8; i++) {
			let header =
				this.props.headers &&
				this.props.headers.datesValues &&
				this.props.headers.datesValues.find(e => e.number === i);
			columns.push({
				name: 'date' + i,
				title: header ? header.tableValue : ' ',
				filterFunction: commaDotFilterMethod,
				className: this.generateClassName(i),
				getCellValue: (item: IFeedingReportItem) => {
					let feeding = item.datesValues && item.datesValues.find(e => e.number === i);
					return feeding && feeding.tableValue;
				},
			});
		}
		columns.push({ name: 'totalFeed', title: localized('total'), getCellValue: this.getTotalFeed });
		return columns;
	};

	private getColumnExte = () => {
		let ext = [
			{
				columnName: 'location',
				width: 240,
			},
			{
				columnName: 'days',
				width: 100,
			},
			{
				columnName: 'animalNumber',
				width: 100,
			},
		];

		for (let i = 0; i < 8; i++) {
			ext.push({
				columnName: 'date' + i,
				width: 80,
			});
		}
		ext.push({ columnName: 'totalFeed', width: 100 });
		return ext;
	};

	private generateClassName(i: number) {
		return (item: IFeedingReportItem) => {
			let feeding = item.datesValues && item.datesValues.find(e => e.number === i);
			return feeding && feeding.cellColorStyle
				? getWebAnimalColorStyleTableCellColorStyle(feeding.cellColorStyle)
				: '';
		};
	}
}
