import React from 'react';
import {
	FarrowingItem,
	FeedItem,
	IProductionReportSetting,
	MatingItem,
	ProductionMappedDate,
	ProductionScaleItem,
	Reproduction,
	ScaleFeedReproduction,
	StatusItem,
	WeaningItem,
} from 'shared/api/api';
import { getDateStringNoMinDate } from 'shared/helpers/date-helpers';
import { getFarrowingPeriod, getWeaningPeriod } from 'shared/helpers/matingbatch-helper';
import { localized, localizedDynamic } from 'shared/state/i18n/i18n';
import { pReportDecimals } from 'web/web-helpers/pdf-helper/production-report-helper';

export interface ProductionReportsData {
	description: JSX.Element;
	columns: JSX.Element[];
}

const boldRowsStatus = {
	sowAndGiltPcs: true,
	sowAndGiltEntrance: false,
	sowAndGiltDepartured: false,
	youngFemalePcs: true,
	youngFemaleEntrance: false,
	youngFemaleEntranceToGilt: false,
	youngFemaleDepartured: false,
	boarPcs: true,
	boarEntrance: false,
	boarDepartured: false,
	pigletPcs: true,
	sowAndGiltPregnantDays: true,
};

// Renders

export function renderScaleFeedReproductionItem(
	matingReports: ScaleFeedReproduction | undefined,
	mappedDates: ProductionMappedDate[] | undefined,
	index: number,
	productionReportsData: ProductionReportsData[],
	pReportSetting: IProductionReportSetting,
) {
	if (
		mappedDates &&
		mappedDates[index] &&
		matingReports &&
		matingReports.feedItems &&
		matingReports.feedItems[index] &&
		matingReports.productionScaleItems &&
		matingReports.productionScaleItems[index] &&
		matingReports.reproductions &&
		matingReports.reproductions[index]
	) {
		let scale = matingReports.productionScaleItems[index];
		let feed = matingReports.feedItems[index];
		let reproduction = matingReports.reproductions[index];

		productionReportsData[0].columns[index] = (
			<div className="p-report-date-header">
				<p>{mappedDates[index].fromDate}</p>
				<p>{mappedDates[index].toDate}</p>
				<p>{mappedDates[index].difference}</p>
			</div>
		);

		const scaleKeys = Object.keys(scale);
		const feedKeys = Object.keys(feed);
		const reproductionKeys = Object.keys(reproduction);

		productionReportsData[1].columns[index] = (
			<div className="valueRows">
				<p></p>
				{scaleKeys.map(key => {
					const upperCase = key.charAt(0).toUpperCase() + key.slice(1);
					return (
						pReportSetting.keyNumbers![upperCase] && (
							<p key={key}>
								{!scale[key]
									? scale[key]
									: pReportDecimals[upperCase] !== undefined && scale[key].toFixed
									? scale[key].toFixed(pReportDecimals[upperCase])
									: scale[key]}
							</p>
						)
					);
				})}
				<p></p>
				{feedKeys.map(key => {
					const upperCase = key.charAt(0).toUpperCase() + key.slice(1);
					return (
						pReportSetting.keyNumbers![upperCase] && (
							<p key={key}>
								{!feed[key]
									? feed[key]
									: pReportDecimals[upperCase] !== undefined && feed[key].toFixed
									? feed[key].toFixed(pReportDecimals[upperCase])
									: feed[key]}
							</p>
						)
					);
				})}
				<p></p>
				{reproductionKeys.map(key => {
					const upperCase = key.charAt(0).toUpperCase() + key.slice(1);
					return (
						pReportSetting.keyNumbers![upperCase] && (
							<p key={key}>
								{!reproduction[key]
									? reproduction[key]
									: pReportDecimals[upperCase] !== undefined && reproduction[key].toFixed
									? reproduction[key].toFixed(pReportDecimals[upperCase])
									: reproduction[key]}
							</p>
						)
					);
				})}
			</div>
		);
	} else {
		const scale = ProductionScaleItem.fromJS({});
		const scaleKeys = Object.keys(scale);

		const feed = FeedItem.fromJS({});
		const feedKeys = Object.keys(feed);

		const reproduction = Reproduction.fromJS({});
		const reproductionKeys = Object.keys(reproduction);
		let a = scaleKeys.filter(key => {
			const upperCase = key.charAt(0).toUpperCase() + key.slice(1);
			return pReportSetting.keyNumbers![upperCase];
		}).length;

		let b = feedKeys.filter(key => {
			const upperCase = key.charAt(0).toUpperCase() + key.slice(1);
			return pReportSetting.keyNumbers![upperCase];
		}).length;

		let c = reproductionKeys.filter(key => {
			const upperCase = key.charAt(0).toUpperCase() + key.slice(1);
			return pReportSetting.keyNumbers![upperCase];
		}).length;
		productionReportsData[0].columns[index] = <div />;
		productionReportsData[1].columns[index] = (
			<div className="valueRows">{renderEmptyParagraphs(a + b + c + 3)}</div>
		);
	}
}

export function renderWeaningColumn(
	weaningReports: WeaningItem[] | undefined,
	index: number,
	productionReportsData: ProductionReportsData[],
	pReportSetting: IProductionReportSetting,
) {
	if (weaningReports && weaningReports[index] && weaningReports[index].matingBatch) {
		let weaningReport = weaningReports[index];
		let weaningPeriod = getWeaningPeriod(weaningReport.matingBatch);
		productionReportsData[6].columns[index] = (
			<div className="p-report-date-header">
				<p>{getDateStringNoMinDate(weaningPeriod && weaningPeriod.weaningPeriodStart)}</p>
				<p>{getDateStringNoMinDate(weaningPeriod && weaningPeriod.weaningPeriodEnd)}</p>
			</div>
		);

		const keys = Object.keys(weaningReport);

		productionReportsData[7].columns[index] = (
			<div className="valueRows">
				{keys.map(key => {
					const upperCase = key.charAt(0).toUpperCase() + key.slice(1);
					return pReportSetting.keyNumbers![upperCase] && <p key={key}> {weaningReport[key]} </p>;
				})}
			</div>
		);
	} else {
		const weaningItem = WeaningItem.fromJS({});
		const weaningKeys = Object.keys(weaningItem);

		let weaningCount = weaningKeys.filter(key => {
			const upperCase = key.charAt(0).toUpperCase() + key.slice(1);
			return pReportSetting.keyNumbers![upperCase];
		}).length;
		productionReportsData[6].columns[index] = <div />;
		productionReportsData[7].columns[index] = (
			<div className="valueRows">{renderEmptyParagraphs(weaningCount)}</div>
		);
	}
}

export function renderFarrowingColumn(
	farrowingReports: FarrowingItem[] | undefined,
	index: number,
	productionReportsData: ProductionReportsData[],
	pReportSetting: IProductionReportSetting,
) {
	if (farrowingReports && farrowingReports[index] && farrowingReports[index].matingBatch) {
		let farrowingReport = farrowingReports[index];
		let farrowingPeriod = getFarrowingPeriod(farrowingReport.matingBatch);
		productionReportsData[4].columns[index] = (
			<div className="p-report-date-header">
				<p>{getDateStringNoMinDate(farrowingPeriod && farrowingPeriod.farrowingPeriodStart)}</p>
				<p>{getDateStringNoMinDate(farrowingPeriod && farrowingPeriod.farrowingPeriodEnd)}</p>
			</div>
		);
		const keys = Object.keys(farrowingReport);
		productionReportsData[5].columns[index] = (
			<div className="valueRows">
				{keys.map(key => {
					const upperCase = key.charAt(0).toUpperCase() + key.slice(1);
					return pReportSetting.keyNumbers![upperCase] && <p key={key}> {farrowingReport[key]} </p>;
				})}
			</div>
		);
	} else {
		const farrowingItem = FarrowingItem.fromJS({});
		const farrowingKeys = Object.keys(farrowingItem);

		let farrowingCount = farrowingKeys.filter(key => {
			const upperCase = key.charAt(0).toUpperCase() + key.slice(1);
			return pReportSetting.keyNumbers![upperCase];
		}).length;
		productionReportsData[4].columns[index] = <div />;
		productionReportsData[5].columns[index] = (
			<div className="valueRows">{renderEmptyParagraphs(farrowingCount)}</div>
		);
	}
}

export function renderMatingColumn(
	matingReports: MatingItem[] | undefined,
	index: number,
	productionReportsData: ProductionReportsData[],
	pReportSetting: IProductionReportSetting,
) {
	if (matingReports && matingReports[index] && matingReports[index].matingBatch) {
		let matingReport = matingReports[index];

		productionReportsData[2].columns[index] = (
			<div className="p-report-date-header">
				<p>{getDateStringNoMinDate(matingReport.matingBatch!.matingPeriodStart!)}</p>
				<p>{getDateStringNoMinDate(matingReport.matingBatch!.matingPeriodEnd)}</p>
			</div>
		);
		const keys = Object.keys(matingReport);
		productionReportsData[3].columns[index] = (
			<div className="valueRows">
				{keys.map(key => {
					const upperCase = key.charAt(0).toUpperCase() + key.slice(1);
					return pReportSetting.keyNumbers![upperCase] && <p key={key}> {matingReport[key]} </p>;
				})}
			</div>
		);
	} else {
		const matingItem = MatingItem.fromJS({});
		const matingKeys = Object.keys(matingItem);

		let matingCount = matingKeys.filter(key => {
			const upperCase = key.charAt(0).toUpperCase() + key.slice(1);
			return pReportSetting.keyNumbers![upperCase];
		}).length;
		productionReportsData[2].columns[index] = <div />;
		productionReportsData[3].columns[index] = <div className="valueRows">{renderEmptyParagraphs(matingCount)}</div>;
	}
}

export function renderStatusColumn(
	statusReports: StatusItem[] | undefined,
	mappedDates: ProductionMappedDate[] | undefined,
	index: number,
	productionReportsData: ProductionReportsData[],
) {
	if (statusReports && statusReports[index] && mappedDates && mappedDates[index]) {
		let statusReport = statusReports[index];

		productionReportsData[8].columns[index] = (
			<div className="status-header p-report-date-header">
				<p>{mappedDates[index].toDate}</p>
			</div>
		);
		const keys = Object.keys(statusReport);

		productionReportsData[9].columns[index] = (
			<div className="valueRows">
				{keys.map(key => {
					let style: any = boldRowsStatus[key] ? { fontWeight: 'bold' } : {};
					return (
						<p key={key} style={style}>
							{statusReport[key]}
						</p>
					);
				})}
			</div>
		);
	} else {
		const statusItem = StatusItem.fromJS({});
		const statusKeys = Object.keys(statusItem);

		productionReportsData[8].columns[index] = <div />;
		productionReportsData[9].columns[index] = (
			<div className="valueRows">{renderEmptyParagraphs(statusKeys.length)}</div>
		);
	}
}

export function renderSubResultsColumn(
	subResults: ScaleFeedReproduction | undefined,
	mappedDates: ProductionMappedDate[] | undefined,
	index: number,
	productionReportsData: ProductionReportsData[],
) {
	if (
		mappedDates &&
		mappedDates[index] &&
		subResults &&
		subResults.feedItems &&
		subResults.feedItems[index] &&
		subResults.productionScaleItems &&
		subResults.productionScaleItems[index] &&
		subResults.reproductions &&
		subResults.reproductions[index]
	) {
		let scale = subResults.productionScaleItems[index];
		let reproduction = subResults.reproductions[index];

		productionReportsData[10].columns[index] = (
			<div className="p-report-date-header">
				<p>{mappedDates[index].fromDate}</p>
				<p>{mappedDates[index].toDate}</p>
				<p>{mappedDates[index].difference}</p>
			</div>
		);

		productionReportsData[11].columns[index] = (
			<div className="valueRows">
				<p> {scale.periodFeedDaysSowAndGilt} </p>
				<p> {scale.periodFeedDaysYoungFemale} </p>
				<p> {scale.productionDays} </p>
				<p> {scale.nonProductiveDays} </p>
				<p> {scale.correctedDays} </p>
				<p> {scale.periodYearSowPcs && scale.periodYearSowPcs.toFixed(1)} </p>
				<p> {reproduction.producedLitters && reproduction.producedLitters.toFixed(2)} </p>
			</div>
		);
	} else {
		productionReportsData[10].columns[index] = <div />;
		productionReportsData[11].columns[index] = <div className="valueRows">{renderEmptyParagraphs(7)}</div>;
	}
}

// Descriptions

export function getProductionScaleDescription(pReportSetting: IProductionReportSetting) {
	const scale = ProductionScaleItem.fromJS({});
	const scaleKeys = Object.keys(scale);

	const feed = FeedItem.fromJS({});
	const feedKeys = Object.keys(feed);

	const reproduction = Reproduction.fromJS({});
	const reproductionKeys = Object.keys(reproduction);
	return (
		<div className="valueRows">
			<p style={{ fontWeight: 'bold' }}> {localized('ProductionScale')} </p>
			{scaleKeys.map(key => {
				const upperCase = key.charAt(0).toUpperCase() + key.slice(1);
				return pReportSetting.keyNumbers![upperCase] && <p key={key}> {localizedDynamic(upperCase)} </p>;
			})}
			<p style={{ fontWeight: 'bold' }}> {localized('Feed')} </p>
			{feedKeys.map(key => {
				const upperCase = key.charAt(0).toUpperCase() + key.slice(1);
				return pReportSetting.keyNumbers![upperCase] && <p key={key}> {localizedDynamic(upperCase)} </p>;
			})}
			<p style={{ fontWeight: 'bold' }}> {localized('Reproduction')} </p>
			{reproductionKeys.map(key => {
				const upperCase = key.charAt(0).toUpperCase() + key.slice(1);
				return pReportSetting.keyNumbers![upperCase] && <p key={key}> {localizedDynamic(upperCase)} </p>;
			})}
		</div>
	);
}

export function getMatingDescription(pReportSetting: IProductionReportSetting) {
	const s = MatingItem.fromJS({});
	const keys = Object.keys(s);

	return (
		<div className="valueRows">
			{keys.map(key => {
				const upperCase = key.charAt(0).toUpperCase() + key.slice(1);
				return pReportSetting.keyNumbers![upperCase] && <p key={key}> {localizedDynamic(upperCase)} </p>;
			})}
		</div>
	);
}

export function getFarrowingDescription(pReportSetting: IProductionReportSetting) {
	const s = FarrowingItem.fromJS({});
	const keys = Object.keys(s);

	return (
		<div className="valueRows">
			{keys.map(key => {
				const upperCase = key.charAt(0).toUpperCase() + key.slice(1);
				return pReportSetting.keyNumbers![upperCase] && <p key={key}> {localizedDynamic(upperCase)} </p>;
			})}
		</div>
	);
}

export function getWeaningDescription(pReportSetting: IProductionReportSetting) {
	const s = WeaningItem.fromJS({});
	const keys = Object.keys(s);

	return (
		<div className="valueRows">
			{keys.map(key => {
				const upperCase = key.charAt(0).toUpperCase() + key.slice(1);
				return pReportSetting.keyNumbers![upperCase] && <p key={key}> {localizedDynamic(upperCase)} </p>;
			})}
		</div>
	);
}

export function getStatusDescription(pReportSetting: IProductionReportSetting) {
	const s = StatusItem.fromJS({});
	const keys = Object.keys(s);

	return (
		<div className="valueRows">
			{keys.map(key => {
				let style: any = boldRowsStatus[key] ? { fontWeight: 'bold' } : {};
				const upperCase = key.charAt(0).toUpperCase() + key.slice(1);
				return (
					<p key={key} style={style}>
						{localizedDynamic(upperCase)}
					</p>
				);
			})}
		</div>
	);
}

export function getSubResultDescription() {
	return (
		<div className="valueRows">
			<p> {localized('PeriodFeedDaysSowAndGilt')} </p>
			<p> {localized('PeriodFeedDaysYoungAnimal')} </p>
			<p> {localized('ProductionDays')} </p>
			<p> {localized('NonProductiveDays')} </p>
			<p> {localized('CorrectedDays')} </p>
			<p> {localized('PeriodYearSowPcs')} </p>
			<p> {localized('ProducedLitters')} </p>
		</div>
	);
}

export function renderEmptyParagraphs(length: number) {
	const Paragraphs: JSX.Element[] = [];
	for (let i = 0; i < length; i++) {
		Paragraphs.push(<p key={i} />);
	}
	return Paragraphs;
}
