import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { WebAppState } from 'web/state/store.web';
import { WhiteText } from '../../Text/white-text';

interface PropsFromParent {}

export const GpeDepartureCount: FC<PropsFromParent> = React.memo((props) => {
	// Get data
	const gpeDepartureCount = useSelector((state: WebAppState) => state.growthPigEvents.gpeDepartureCount);

	return (
		<WhiteText className="alignSelfCenter">
			{gpeDepartureCount.totalCount}/{gpeDepartureCount.departureCount}
		</WhiteText>
	);
});
