import {
	SkioldOneClient,
	FileResponse,
	IActiveSow as IActiveSowApi,
	ActiveSow as ActiveSowApi,
	IDadMomTableItem,
	DadMomTableItem,
	StemAnimal,
	YoungAnimalTableItem,
	IYoungAnimalTableItem,
	CellColorStyle,
} from 'shared/api/api';
import { SkioldDigitalApiBaseUrl } from 'shared/constants';
import { savePdfAs } from './general-pdf-helper';
import { ActiveSow } from 'web/view/components/stem-animal/animal-lists/active-sows/active-sows-model';
import { getDateString } from 'shared/helpers/date-helpers';
import { getAnimalNumberColor } from 'shared/helpers/treatment-helper/treatment-helper';
import { localized } from 'shared/state/i18n/i18n';
import { calculateAgeInWeeks } from 'shared/helpers/general-helpers';

export function GetAnimalListPdf(
	activeSows: ActiveSow[],
	fileName: string,
	siteId: string,
	timeZoneId?: string,
	lang?: string,
) {
	let activeSowsApi: IActiveSowApi[] = [];
	activeSows.forEach(activeSow => {
		activeSowsApi.push({
			transponder: activeSow.transponder,
			animalNumber: activeSow.animalNumber,
			entranceType: activeSow.entranceType,
			entranceDate: getDateString(activeSow.entranceDate),
			race: activeSow.race,
			animalKind: localized(activeSow.kind ?? ''),
			batch: activeSow.batch,
			cycleDays: activeSow.cycleDays ? activeSow.cycleDays.toString() : '',
			condition: activeSow.condition,
			teats: activeSow.teats ? activeSow.teats.toString() : '',
			cellColorStyle: getAnimalNumberColor(activeSow.id),
		});
	});

	return new SkioldOneClient(SkioldDigitalApiBaseUrl)
		.animalListReport_Post(activeSowsApi as ActiveSowApi[], siteId, timeZoneId, lang)
		.then((fileResponse: FileResponse | null) => {
			savePdfAs(fileResponse, fileName);
		})
		.catch(() => {
			return;
		});
}

export function GetDadMomTablePdf(
	activeSows: ActiveSow[],
	fileName: string,
	siteId: string,
	timeZoneId?: string,
	lang?: string,
) {
	let activeSowsApi: IDadMomTableItem[] = [];
	activeSows.forEach(activeSow => {
		activeSowsApi.push({
			animalNumber: activeSow.animalNumber,
			birthDate: getDateString(activeSow.birthDate),
			race: activeSow.race,
			dadIdNumber: activeSow.dadIdNumber,
			dadNr: activeSow.dadAnimalNumber,
			idNumber: activeSow.idNumber,
			index: activeSow.breedIndex?.toString(),
			momIdNumber: activeSow.momIdNumber,
			momNr: activeSow.momAnimalNumber,
			raceLine: activeSow.raceLine,
			cellColorStyle: getAnimalNumberColor(activeSow.id),
		});
	});

	return new SkioldOneClient(SkioldDigitalApiBaseUrl)
		.animalListReport_PPrintDadMomTableListst(activeSowsApi as DadMomTableItem[], siteId, timeZoneId, lang)
		.then((fileResponse: FileResponse | null) => {
			savePdfAs(fileResponse, fileName);
		})
		.catch(() => {
			return;
		});
}
export function GetYoungAnimalPdf(
	youngAnimal: StemAnimal[],
	fileName: string,
	siteId: string,
	timeZoneId?: string,
	lang?: string,
) {
	let youngAnimalTableItem: IYoungAnimalTableItem[] = [];
	youngAnimal.forEach(y => {
		youngAnimalTableItem.push({
			idNumber: y.idNumber,
			birthDate: getDateString(y.birthDate),
			race: y.race,
			gender: y.gender,
			dadIdNumber: y.dadIdNumber,
			dadNr: y.dadAnimalNumber,
			index: y.breedIndex?.toString(),
			momIdNumber: y.momIdNumber,
			momNr: y.momAnimalNumber,
			raceLine: y.raceLine,
			cellColorStyle: CellColorStyle.Normal,
			age: y.birthDate ? calculateAgeInWeeks(y.birthDate).toString() : '',
		});
	});

	return new SkioldOneClient(SkioldDigitalApiBaseUrl)
		.animalListReport_Post2(youngAnimalTableItem as ActiveSowApi[], siteId, timeZoneId, lang)
		.then((fileResponse: FileResponse | null) => {
			savePdfAs(fileResponse, fileName);
		})
		.catch(() => {
			return;
		});
}
