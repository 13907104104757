import React, { FC, useCallback, useEffect, useState } from 'react';
import { ViewWeb } from '../../../components/utils/web-view';
import { TextWeb } from '../../../../web-helpers/styled-text-components';
import { localized } from '../../../../../shared/state/i18n/i18n';
import { SkioldTouchableOpacity } from '../../../components/skiold-components/skiold-touchable-opacity';
import './nucleus-management-settings-overview.scss';
import { NucleusManagementConnectionsSettings } from './connection-settings/nucleus-management-connections-settings';
import { NucleusManagementBreedingSettingsSetup } from './breeding-settings/nucleus-management-breeding-settings-setup';
import { NucleusManagementFetchSetupForm } from './communication-settings/nucleus-management-fetch-setup-form';
import { NucleusManagementSendSetupForm } from './communication-settings/nucleus-management-send-setup-form';
import { useSelector } from 'react-redux';
import { selectNucleusManagementOrAssignIdAccess } from 'shared/state/ducks/site/reducer';
import { hasNucleusFeatureCEFN } from 'shared/helpers/nucleus-management-helper/nucleus-management-helper';
import { NucleusManagementArchive } from './archive/nucleus-management-archive';

const options = ['BreedingNumber', 'Fetch', 'Send', 'Connections', 'ArchiveSendData'] as const;
type optionsTypes = typeof options[number];

export const NucleusManagementSettingsOverview: FC = React.memo(() => {
	const [page, setPage] = useState<optionsTypes>('BreedingNumber');
	const [aiOptions, setAIOptions] = useState<optionsTypes[]>(['BreedingNumber', 'Send', 'ArchiveSendData']);
	const nucleusManagement = useSelector(selectNucleusManagementOrAssignIdAccess);

	const handlePageSelect = useCallback(
		(page: optionsTypes) => () => {
			setPage(page);
		},
		[],
	);

	useEffect(() => {
		if (!hasNucleusFeatureCEFN(nucleusManagement)) {
			setAIOptions(['BreedingNumber', 'Fetch', 'Send', 'Connections', 'ArchiveSendData']);
			setPage('BreedingNumber');
		}
	}, [nucleusManagement]);
	return (
		<ViewWeb className={'settings-overview-container'}>
			<TextWeb className={'nucleus-management-header'}>{localized('NucleusManagement')}</TextWeb>
			<ViewWeb className={'settings-content-container'}>
				<ViewWeb className={'link-options-container'}>
					{aiOptions.map(option => (
						<ViewWeb className={'link-container'} key={option}>
							<SkioldTouchableOpacity onPress={handlePageSelect(option)}>
								<TextWeb>{localized(option)}</TextWeb>
							</SkioldTouchableOpacity>
						</ViewWeb>
					))}
				</ViewWeb>
				<ViewWeb className={'child-container'}>
					{page === 'BreedingNumber' && <NucleusManagementBreedingSettingsSetup />}
					{page === 'Fetch' && <NucleusManagementFetchSetupForm />}
					{page === 'Send' && <NucleusManagementSendSetupForm />}
					{page === 'Connections' && <NucleusManagementConnectionsSettings />}
					{page === 'ArchiveSendData' && <NucleusManagementArchive />}
				</ViewWeb>
			</ViewWeb>
		</ViewWeb>
	);
});
