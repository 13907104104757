import { actionCreatorFactory } from 'typescript-fsa';
import * as types from './types';
import { Feeding, IFeeding } from 'shared/api/api';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';

const actionCreator = actionCreatorFactory(GenActionFactoryName('feeding'));

export const saveFeeding = actionCreator.async<Feeding, Feeding>(types.SAVE_FEEDING);
export const deleteFeeding = actionCreator.async<Feeding, string>(types.DELETE_FEEDING);
export const getFeedings = actionCreator.async<{ siteId: string }, IFeeding[]>(types.GET_FEEDINGS);
export const getFeedingsForFeedingLog = actionCreator.async<{}, Feeding[]>(types.GET_FEEDINGS_FOR_FEEDINGS_LOG);