import ObjectID from 'bson-objectid';
import {
	IPregnancyEvent,
	IStemAnimal,
	MatingGrade,
	PregnancyState,
	Scanned,
	ScanResult,
	Averted,
	IPen,
	SowState,
	Equipment,
	LocationType,
	IUnitToPen,
	IProcessEquipmentData,
	SemenType,
	IGeneralSettings,
	DayOfWeek,
} from 'shared/api/api';
import { sortPregnanciesByDate } from './sort-pregnancies';
import { findAnimalById } from '../stemanimal-helper/stem-animal-input-helper';
import { PregnancyAnimalChanged } from './pregnancy-animal-changed';
import { getPreviousEvent, getSowState } from './sow-state-helper';
import { StoreContainer } from 'shared/state/store-container';
import { calculateDays } from '../general-helpers';
import { getPenBySow } from '../location-helper';
import { ExceptionMessage } from '../exception-message';
import { localized } from 'shared/state/i18n/i18n';
import { Option } from 'react-dropdown';
import moment from 'moment';
export function getLastPregnancyByPregnancyEventId(pregnancyEvents: IPregnancyEvent[], pregnancyEventId: string) {
	if (pregnancyEvents !== undefined) {
		let foundPregnancy = pregnancyEvents.find(event => event.id === pregnancyEventId);

		if (foundPregnancy) {
			return sortPregnanciesByDate(pregnancyEvents)[0];
		}
	}

	return undefined;
}

export function setupPregnancyEventById(
	pregnancyEvents: { [key: string]: IPregnancyEvent[] },
	pregnancyEventId: string,
	stemAnimalId: string,
	animalNumberChanged: (
		sow: IStemAnimal,
		animalEvents: IPregnancyEvent[],
		prevEvent: IPregnancyEvent,
		newPregnancyEvent: IPregnancyEvent,
	) => void,
	animalNumberCallback?: (animalNumber: string, pregEvent: IPregnancyEvent) => void,
) {
	let sowPregnancies = pregnancyEvents[stemAnimalId];
	let foundPregnancy = sowPregnancies.find(event => event.id === pregnancyEventId);
	if (foundPregnancy) {
		let foundAnimal = findAnimalById(foundPregnancy!.stemAnimalId);
		if (foundAnimal) {
			PregnancyAnimalChanged(
				animalNumberChanged,
				foundAnimal.animalNumber!,
				foundPregnancy,
				pregnancyEvents,
				stemAnimalId,
			);

			if (animalNumberCallback) {
				animalNumberCallback(foundAnimal.animalNumber!, foundPregnancy);
			}
		}
	}
}

export const GetGradeOptions = [
	{ value: '', label: ' ' },
	{ value: MatingGrade.A, label: MatingGrade.A },
	{ value: MatingGrade.B, label: MatingGrade.B },
	{ value: MatingGrade.C, label: MatingGrade.C },
	{ value: MatingGrade.D, label: MatingGrade.D },
	{ value: MatingGrade.E, label: MatingGrade.E },
];

export const GetDeviceTableGradesForiOS = [
	'Nulstil',
	MatingGrade.A,
	MatingGrade.B,
	MatingGrade.C,
	MatingGrade.D,
	MatingGrade.E,
];

export function SetupPregnancyByStemAnimal(
	setState: (
		sow: IStemAnimal,
		animalEvents: IPregnancyEvent[],
		prevEvent: IPregnancyEvent,
		newPregnancyEvent: IPregnancyEvent,
	) => void,
	sow: IStemAnimal | undefined,
	pregnancyEvent: IPregnancyEvent,
	pregnancyEvents: IPregnancyEvent[],
) {
	if (sow) {
		const prevEvent = getPreviousEvent(pregnancyEvents, pregnancyEvent);

		pregnancyEvent.stemAnimalId = sow.id;
		pregnancyEvent.siteId = sow.siteId;

		if (prevEvent && pregnancyEvent.state !== PregnancyState.Mated) {
			pregnancyEvent.pregnancyId = prevEvent.pregnancyId;
		} else {
			if (!pregnancyEvent.pregnancyId) {
				pregnancyEvent.pregnancyId = new ObjectID().toHexString();
			}
		}

		setState(sow, pregnancyEvents, prevEvent, pregnancyEvent);
	} else {
		pregnancyEvent.stemAnimalId = undefined;
		pregnancyEvent.siteId = undefined;
		setState({} as IStemAnimal, [], {} as IPregnancyEvent, pregnancyEvent);
	}
	return;
}

export function getCycleDays(stemAnimalId: string) {
	const state = StoreContainer.getState();
	return getCycleDays1(stemAnimalId, state.pregnancyEvents.entities[stemAnimalId]);
}

export function getCycleDays1(stemAnimalId: string, pregnancyEvents: IPregnancyEvent[]) {
	if (pregnancyEvents === undefined) {
		return undefined;
	}

	const filteredPregnancyEvents = pregnancyEvents.filter(event => {
		if (event.stemAnimalId === stemAnimalId) {
			if (event.state === PregnancyState.Scanned) {
				const scanned = event as Scanned;
				if (scanned.result !== ScanResult.False) {
					return false;
				}
			}
			if (event.state === PregnancyState.Averted) {
				const scanned = event as Averted;
				if (scanned.isNursingSow) {
					return false;
				}
			}
			return true;
		}
		return false;
	});

	const lastestPregnancyEvent = sortPregnanciesByDate(filteredPregnancyEvents)[0];
	const cycleDays = lastestPregnancyEvent ? calculateDays(lastestPregnancyEvent.date!, new Date()) : undefined;
	return cycleDays ? cycleDays : undefined;
}

export function ValidateAnimalIPregnancyLocationAndEsfIsEnabled(
	sow: IStemAnimal | undefined,
	nextPregnancyEvent: IPregnancyEvent,
	unitToPens: IUnitToPen[],
	processEquipmentData: IProcessEquipmentData[],
	showAlert?: (message: string) => void,
	penToMoveTo?: IPen,
) {
	if (
		sow &&
		sow.id &&
		(nextPregnancyEvent.state === PregnancyState.Mated ||
			nextPregnancyEvent.state === PregnancyState.Farrowing ||
			(nextPregnancyEvent.state === PregnancyState.Scanned &&
				(nextPregnancyEvent as Scanned).result === ScanResult.False))
	) {
		const state = StoreContainer.store.getState();
		let sowState = getSowState(state.pregnancyEvents.entities[sow.id]);
		if (sowState === SowState.Pregnant) {
			const pen = penToMoveTo ? penToMoveTo : getPenBySow(sow);
			const esfExists = state.processEquipments.entities.find(pq => pq.equipment === Equipment.ESF) !== undefined;

			if (
				esfExists &&
				pen &&
				unitToPens.find(
					utp =>
						utp.penId === pen.id &&
						processEquipmentData.find(
							peq => peq.id === utp.processEquipmentDataId && peq.equipmentType === Equipment.ESF,
						) !== undefined,
				)
			) {
				const section = state.locations.sections.find(s => s.id === pen.sectionId);
				if (section && section.type === LocationType.Pregnant && sowState === SowState.Pregnant) {
					if (showAlert) {
						showAlert(
							localized(
								ExceptionMessage.VALIDATION_ERROR_ANIMAL_IS_NOT_PREGNANT_ANYMORE_AND_ANIMAL_IS_ON_PREGNANT_LOCATION,
							),
						);
						return false;
					}
				}
			}
		}
	}
	return true;
}

export function getLastPregnancyEvent(
	stemAnimalId: string | undefined,
	pregnancyState: PregnancyState | undefined,
	pregnancyEvents: { [key: string]: IPregnancyEvent[] },
) {
	if (!stemAnimalId) {
		return undefined;
	}
	let sortedPregnancies = pregnancyEvents[stemAnimalId].concat().sort((a: IPregnancyEvent, b: IPregnancyEvent) => {
		return b.date!.getTime() - a.date!.getTime();
	});

	if (sortedPregnancies.length <= 0) {
		return undefined;
	}

	if (pregnancyState !== undefined) {
		return sortedPregnancies.find(preg => preg.state === pregnancyState);
	}

	return sortedPregnancies[0];
}

export function getLastPregnancyEventByDate(
	stemAnimalId: string | undefined,
	pregnancyState: PregnancyState | undefined,
	pregnancyEvents: { [key: string]: IPregnancyEvent[] },
	date: Date | undefined,
) {
	if (!stemAnimalId || !date) {
		return undefined;
	}
	let sortedPregnancies = pregnancyEvents[stemAnimalId]
		.filter(preg => preg.date && preg.date <= date)
		.sort((a: IPregnancyEvent, b: IPregnancyEvent) => {
			return b.date!.getTime() - a.date!.getTime();
		});

	if (sortedPregnancies.length <= 0) {
		return undefined;
	}

	if (pregnancyState !== undefined) {
		return sortedPregnancies.find(preg => preg.state === pregnancyState);
	}

	return sortedPregnancies[0];
}

export const getSemenTypeOptions = (): Option[] => {
	return [
		{ value: SemenType.Nat, label: SemenType.Nat },
		{ value: SemenType.KS, label: SemenType.KS },
	];
};

export const getFarrowingWeekByGeneralSetting = (setting: IGeneralSettings, day: Date | undefined) => {
	const week = moment(day).isoWeek();

	const weekList = Object.keys(DayOfWeek);
	const correctWeek =
		setting.startWeekDay !== undefined &&
		weekList.findIndex(w => w === setting.startWeekDay) <= moment(day).weekday()
			? week
			: week - 1;

	const prefix = correctWeek <= 9 ? '0' : '';
	return prefix + week;
};

// This is hardcoded values. Each year has its own letter. Keep updated
export const getFarrowingYear = (date: Date | undefined) => {
	const year = moment(date).year();
	switch (year) {
		case 2022:
			return 'W';
		case 2023:
			return 'X';
		case 2024:
			return 'Y';
		default:
			return 'W';
	}
};
