import memoize from 'memoize-one';
import React from 'react';
import { Option } from 'react-dropdown';
import { connect } from 'react-redux';
import { AnimalType } from 'shared/api/api';
import sokort_icon from 'shared/assets/src-assets/png/sokort_icon.png';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { exactFilterMethodGrid, rangeFilterMethodGrid } from 'shared/helpers/general-helpers';
import { DefaultReasonOption } from 'shared/helpers/reason-helper/reason-helper';
import { RefType } from 'shared/helpers/ref-type';
import {
	genereateDepartureListTableData,
	getReasons,
} from 'shared/helpers/work-list-helpers/departure-list-helpers/departure-work-list-helper';
import { checkSaveWorkListStatus } from 'shared/helpers/work-list-helpers/general-work-list-helper';
import { localized } from 'shared/state/i18n/i18n';
import { DepartureTypes } from 'shared/state/models/departure-types';
import { SharedAppState } from 'shared/state/store.shared';
import GetRetentionTime from 'web/view/components/stem-animal/sow-card/get-retention-time';
import SowCard from 'web/view/pages/sow-card/sow-card';
import { getWebAnimalColorRetentionStyleTable } from 'web/web-helpers/general-web-helpers';
import {
	DepartureListMapDispatchToProps,
	DepartureListMapStateToProps,
	DepartureListProps,
	DepartureListState,
	DepartureListTableItem,
} from '../../../../../shared/helpers/work-list-helpers/departure-list-helpers/departure-work-list-item';
import { showAlert, ShowConfirmAlert } from '../../skiold-alert/skiold-alert';
import { SkioldCheckbox } from '../../skiold-components/skiold-checkbox/skiold-checkbox';
import { SkioldDatePicker } from '../../skiold-components/skiold-date-picker/skiold-date-picker';
import { SkioldDropdown } from '../../skiold-components/skiold-dropdown/skiold-dropdown';
import { SkioldModal } from '../../skiold-components/skiold-modal/skiold-modal';
import SkioldTableGrid, {
	SkioldTableGrid as SkioldTableRef,
} from '../../skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { SkioldTouchableOpacity } from '../../skiold-components/skiold-touchable-opacity';
import { SowListConstants } from '../../stem-animal/animal-lists/table-constants';
import { PoolYoungFemaleShouldDepartureOverview } from '../../stem-animal/pool-young-female/pyf-should-departure-overview';
import { SkioldImage } from '../../utils/svg/skiold-image';
import { ViewWeb } from '../../utils/web-view';
import '../list-setup.scss';
import { defaultSortingWorklists, getAnimalNumberText } from '../work-list-helper';
import './departure-work-list-table.scss';

export class DepartureListTable extends React.PureComponent<DepartureListProps, DepartureListState> {
	public SkioldTableRef: SkioldTableRef | undefined;
	public animalOptions = [
		{ value: AnimalType.Sow, label: localized('SowBoarGlit') },
		{ value: AnimalType.YoungFemale, label: localized('poolYoungFemale') },
	];
	public DefaultDepartureTypeOption: Option = { label: localized('SowBoarGlit'), value: AnimalType.Sow };
	private generateData = memoize(animals => genereateDepartureListTableData(this.props, this.state));

	constructor(props: DepartureListProps) {
		super(props);
		const workListData = this.generateData(this.props.stemAnimals);
		this.state = {
			columns: this.generateColumns(workListData),
			columnExte: this.getColumnExtensions,
			loading: false,
			workListData,
			modalOpen: false,
			stemAnimalIdToEdit: '',
			commitAll: false,
			animalTypeOption: this.DefaultDepartureTypeOption,
			date: new Date(),
			departureType: { label: '', value: '' },
		};
	}

	private getColumnExtensions = [
		{
			columnName: 'sowCard',
			filteringEnabled: false,
			sortingEnabled: false,
			resizingEnabled: false,
			width: SowListConstants.iconWidth,
		},
		{
			columnName: 'butcherQuarantine',
			width: SowListConstants.cycleDaysWidth,
		},
		{
			columnName: 'animalNumber',
			width: SowListConstants.animalNrWidth,
		},

		{
			columnName: 'location',
			width: SowListConstants.sectionWidth,
		},
		{
			columnName: 'pen',
			width: SowListConstants.penWidth,
		},
		{
			columnName: 'date',
			width: SowListConstants.cycleDaysWidth,
		},
		{
			columnName: 'departureType',
			width: SowListConstants.departureDateWidth,
		},
		{
			columnName: 'reason',
			width: 200,
		},
		{
			columnName: 'completed',
			width: SowListConstants.entranceTypeWidth,
		},
	];

	public ResetWorkList() {
		let data = this.generateData(this.props.stemAnimals);
		this.setState({ workListData: data });
	}

	private departureTypes = [
		this.DefaultDepartureTypeOption,
		{
			label: localized(DepartureTypes.departureTypeSold),
			value: DepartureTypes.departureTypeSold,
		},
		{
			label: localized(DepartureTypes.departureTypeDead),
			value: DepartureTypes.departureTypeDead,
		},
		{
			label: localized(DepartureTypes.departureTypeKilled),
			value: DepartureTypes.departureTypeKilled,
		},
		{
			label: localized(DepartureTypes.departureTypePutDown),
			value: DepartureTypes.departureTypePutDown,
		},
	];

	private optionChanged = (option: Option) => {
		this.setState({ ...this.state, animalTypeOption: option });
	};

	public render() {
		return (
			<ViewWeb className="departure-work-list-table">
				<SkioldDropdown
					onValueChanged={this.optionChanged}
					selectedValue={this.state.animalTypeOption}
					items={this.animalOptions}
					containerClassName="animalType-dropdown"
				/>
				{this.state.animalTypeOption.value === AnimalType.Sow && (
					<SkioldTableGrid
						tableKey={'departureWorkList'}
						columns={this.state.columns}
						ColumnExtensions={this.state.columnExte}
						className={'work-list-table'}
						ref={this.setRef}
						data={this.state.workListData}
						sortHeaderId={defaultSortingWorklists}
					/>
				)}
				{this.state.animalTypeOption.value === AnimalType.YoungFemale && (
					<PoolYoungFemaleShouldDepartureOverview />
				)}

				<SkioldModal
					shouldCloseOnOverlayClick={true}
					padding="0"
					isOpen={this.state.sowCardModal}
					close={this.closeModal}
					justify-content="flex-end"
					max-width="calc(100% - 220px)"
				>
					<SowCard stemAnimalIdFromParent={this.state.stemAnimalIdToEdit} close={this.closeModal} />
				</SkioldModal>
			</ViewWeb>
		);
	}
	private closeModal = () => {
		this.setState({ stemAnimalIdToEdit: '', sowCardModal: false });
	};
	public async CheckSavePregnancyEventListsStatus() {
		if (await checkSaveWorkListStatus(this.state.workListData, ShowConfirmAlert)) {
			await this.SaveWorklist();
		}
		return true;
	}
	public setRef = (s: any) => {
		if (s) {
			this.SkioldTableRef = s;
			this.forceUpdate();
		}
	};

	public generateCommitAllCheckBox = () => {
		return (
			<ViewWeb className="checkbox-view-filter-style">
				<SkioldCheckbox isChecked={this.state.commitAll} className="checkbox-style" onClick={this.commitAll} />
			</ViewWeb>
		);
	};

	public commitAll = () => {
		const commitAll = !this.state.commitAll;
		this.setState(prevState => {
			const workListData = [...prevState.workListData];
			for (const [i] of workListData.entries()) {
				workListData[i] = { ...workListData[i], isChecked: commitAll };
			}
			this.props.SetCheckedCount(
				workListData.reduce((a, b) => a + (b && b.isChecked ? 1 : 0), 0),
				true,
			);
			return { workListData, commitAll };
		});
	};

	public SaveWorklist = async () => {
		let data = [...this.state.workListData];
		let listOfPromises: Array<Promise<void>> = [];
		for (let index = 0; index < this.state.workListData.length; index++) {
			const item = this.state.workListData[index];
			if (item && item.isChecked) {
				if (this.validate(item)) {
					let stemAnimalToEdit = this.props.stemAnimals.find(a => a.id === item.stemAnimalId);
					if (stemAnimalToEdit) {
						stemAnimalToEdit.departureDate = item.departureDate;
						stemAnimalToEdit.departureType = item.selectedDepartureType.value;
						stemAnimalToEdit.departureReasonId = item.selectedDepartureReason.value;
						listOfPromises.push(this.props.handleDeparture(stemAnimalToEdit));
						listOfPromises.push(this.props.updateTreatmentPlansForDepartedSow(item.stemAnimalId!, false));
						data = data.filter(a => a.stemAnimalId !== item.stemAnimalId);
					}
				}
			}
		}

		await Promise.all(listOfPromises);
		this.setState({ workListData: data, commitAll: false });
	};

	public DeleteCheckedWorklist = async () => {
		let data = [...this.state.workListData];
		let listOfPromises: Array<Promise<void>> = [];
		for (let index = 0; index < this.state.workListData.length; index++) {
			const item = this.state.workListData[index];
			if (item && item.isChecked) {
				let stemAnimalToEdit = this.props.stemAnimals.find(a => a.id === item.stemAnimalId);
				if (stemAnimalToEdit) {
					stemAnimalToEdit.departureDate = undefined;
					stemAnimalToEdit.departureType = undefined;
					stemAnimalToEdit.departureReasonId = undefined;
					listOfPromises.push(this.props.handleDeparture(stemAnimalToEdit));
					listOfPromises.push(this.props.updateTreatmentPlansForDepartedSow(item.stemAnimalId!, true));
					data = data.filter(a => a.stemAnimalId !== item.stemAnimalId);
				}
			}
		}

		await Promise.all(listOfPromises);
		this.setState({ workListData: data, commitAll: false });
	};

	private validate = (item: DepartureListTableItem) => {
		if (!item.departureType || item.selectedDepartureType === this.DefaultDepartureTypeOption) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_DEPARTURE_TYPE_NOT_SET));
			return false;
		}

		if (!item.departureDate) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_DEPARTURE_DATE_NOT_SET));
			return false;
		}
		if (item.departureType === DepartureTypes.departureTypeToBeKilled && item.departureDate < new Date()) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_DEPARTURE_DATE_AFTER_CURRENT_DATE));
			return false;
		}
		return true;
	};
	private showSowCard(stemAnimalId: string) {
		this.setState({ sowCardModal: true, stemAnimalIdToEdit: stemAnimalId });
	}
	private getStemAnimalNumberColor = (sow: DepartureListTableItem) => {
		return sow && sow ? getWebAnimalColorRetentionStyleTable(sow.stemAnimalId) : '';
	};

	private animalNumberFilter = (value: any, filterValue: any, row: DepartureListTableItem) => {
		return (
			row.animalNumber &&
			String(localized(row.animalNumber).toLocaleLowerCase()).startsWith(filterValue.value.toLocaleLowerCase())
		);
	};

	private generateColumns(data: DepartureListTableItem[]) {
		const columns: any = [
			{
				name: 'sowCard',
				title: ' ',
				headerClassName: 'merged-header',
				filterFunction: rangeFilterMethodGrid,
				getCellValue: (tableItem: DepartureListTableItem) => (
					<SkioldTouchableOpacity
						onPress={() => {
							this.showSowCard(tableItem.stemAnimalId!);
						}}
					>
						<SkioldImage
							width={SowListConstants.iconSVGWidth}
							height={SowListConstants.iconSVGWidth}
							imageData={sokort_icon}
						/>
					</SkioldTouchableOpacity>
				),
			},
			{
				name: 'butcherQuarantine',
				headerClassName: 'merged-header',
				title: localized('RetentionTime'),
				filterFunction: exactFilterMethodGrid,
				getCellValue: this.getRetentionTime,
			},
			{
				name: 'animalNumber',
				title: localized('animalNumber'),
				filterFunction: this.animalNumberFilter,
				getCellValue: getAnimalNumberText,
				className: this.getStemAnimalNumberColor,
			},
			{
				name: 'location',
				title: localized('location'),
				filterFunction: rangeFilterMethodGrid,
				getCellValue: this.getBuildingSectionString,
			},
			{
				name: 'pen',
				title: localized('pen'),
				filterFunction: exactFilterMethodGrid,
				getCellValue: this.getPenString,
			},
			{
				name: 'date',
				title: localized('Date'),
				sortable: false,
				filterable: false,
				getCellValue: this.dateColumn,
			},
			{
				name: 'departureType',
				title: localized('departureType'),
				sortable: false,
				filterable: false,
				getCellValue: this.getDepartureTypesDropdown,
			},
			{
				name: 'reason',
				title: localized('reason'),
				sortable: false,
				filterable: false,
				getCellValue: this.getDepartureReasonDropdown,
			},
			{
				name: 'completed',
				title: localized('completed'),
				headerClassName: 'merged-header',
				sortable: false,
				filter: this.generateCommitAllCheckBox,
				getCellValue: (tableItem: DepartureListTableItem) => (
					<ViewWeb className="checkbox-view-style">
						<SkioldCheckbox
							isChecked={tableItem.isChecked!}
							className="checkbox-style"
							onClick={() => {
								this.commitedClicked(tableItem);
							}}
						/>
					</ViewWeb>
				),
			},
		];
		return columns;
	}

	private getRetentionTime = (tableItem: DepartureListTableItem) => (
		<GetRetentionTime stemAnimalId={tableItem.stemAnimalId ? tableItem.stemAnimalId : ''} />
	);

	private dateColumn = (tableItem: DepartureListTableItem) => (
		<SkioldDatePicker
			onDateChanged={newDate => {
				let dataArrayCopy = [...this.state.workListData];
				let index = dataArrayCopy.findIndex(b => b.stemAnimalId === tableItem.stemAnimalId);
				let dataCopy = { ...dataArrayCopy[index] };
				dataCopy.departureDate = newDate;
				dataArrayCopy[index] = dataCopy;
				this.setState({ workListData: dataArrayCopy });
			}}
			containerClassName="datepicker-ctyle"
			selectedDate={tableItem.departureDate}
			theme={'dark'}
			color={'grey'}
			maxDate={new Date(Date.now())}
		/>
	);
	private getDepartureTypesDropdown = (tableItem: DepartureListTableItem) => (
		<SkioldDropdown
			usedInTable={true}
			items={this.departureTypes}
			theme={'dark'}
			className={'inTable'}
			selectedValue={tableItem.selectedDepartureType}
			onValueChanged={(option: Option) => {
				let dataArrayCopy = [...this.state.workListData];
				let index = dataArrayCopy.findIndex(b => b.stemAnimalId === tableItem.stemAnimalId);
				let dataCopy = { ...dataArrayCopy[index] };
				dataCopy.departureType = option.value;
				dataCopy.selectedDepartureType = option;
				dataArrayCopy[index] = dataCopy;
				this.setState({ workListData: dataArrayCopy });
			}}
		/>
	);

	private getDepartureReasonDropdown = (tableItem: DepartureListTableItem) => {
		// If departure has reason, not set on farm setting. dont show anything.
		const reasons = getReasons(tableItem, this.props);
		const hasOption = !!(
			tableItem.selectedDepartureReason && reasons.find(e => e.value === tableItem.selectedDepartureReason.value)
		);
		return (
			<SkioldDropdown
				usedInTable={true}
				items={reasons}
				theme={'dark'}
				className={'inTable'}
				selectedValue={hasOption ? tableItem.selectedDepartureReason : DefaultReasonOption}
				onValueChanged={(option: Option) => {
					let dataArrayCopy = [...this.state.workListData];
					let index = dataArrayCopy.findIndex(b => b.stemAnimalId === tableItem.stemAnimalId);
					let dataCopy = { ...dataArrayCopy[index] };
					dataCopy.departureType = option.value;
					dataCopy.selectedDepartureReason = option;
					dataArrayCopy[index] = dataCopy;
					this.setState({ workListData: dataArrayCopy });
				}}
			/>
		);
	};
	private getBuildingSectionString = (tableItem: DepartureListTableItem) => tableItem.location;
	private getPenString = (tableItem: DepartureListTableItem) => tableItem.pen;

	private commitedClicked = (tableItem: DepartureListTableItem) => {
		const dataIndex = this.state.workListData.findIndex(a => a.stemAnimalId === tableItem.stemAnimalId);

		this.setState(prevState => {
			const workListData = [...prevState.workListData];
			workListData[dataIndex] = { ...workListData[dataIndex], isChecked: !tableItem.isChecked };
			this.props.SetCheckedCount(workListData[dataIndex].isChecked ? 1 : -1);
			return { workListData, commitAll: false };
		});
	};
}

export default connect<
	ReturnType<typeof DepartureListMapStateToProps>,
	ReturnType<typeof DepartureListMapDispatchToProps>,
	RefType,
	SharedAppState
>(DepartureListMapStateToProps, DepartureListMapDispatchToProps, null, { forwardRef: true })(DepartureListTable);
