
import actionCreatorFactory from 'typescript-fsa';
import * as types from './types';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import { IMatingBatchSetting, SyncDataMatingBatchSetting } from 'shared/api/api';

const actionCreator = actionCreatorFactory(GenActionFactoryName('matingBatchSetting'));

export const saveMatingBatchSetting = actionCreator.async<
   IMatingBatchSetting,
    string,
    { code: number; message: string; prevEntity: IMatingBatchSetting | undefined }
>(types.SAVE_MATING_BATCH_SETTING);
export const getSyncData = actionCreator.async<{ siteId: string; lastSyncDate: Date; activeSiteId?: string  }, SyncDataMatingBatchSetting>(
    types.SYNC_DATA_MATINGBATCHSETTING
);
