export default {
	// General terms
	locale: 'fr-fr',
	reset: 'Réinitialiser',
	site: 'Site',
	enterprise: 'Entreprise',
	building: 'Bâtiment',
	section: 'Section',
	buildingShort: 'Construire',
	sectionShort: 'Sec',
	Unit: 'Unité',
	Save: 'Sauvegarder',
	Show: 'Afficher',
	Edit: 'Modifier',
	Handled: 'Manipulation',
	Delete: 'Supprimer',
	Cancel: 'Annuler',
	Count: 'Compter',
	Weight: 'Poids',
	AnimalWeight: 'Poids des animaux',
	FeedWeight: 'Poids des aliments',
	Result: 'Résultat',
	Saved: 'Sauvegardé',
	Overdue: 'En retard',
	Print: 'Imprimer',
	Today: "Aujourd'hui",
	Tomorrow: 'Demain',
	SaveChanges: 'Enregistrer les changements',
	ChooseDate: 'Choisir une date',
	stable: 'Étable',
	pen: 'Enclos',
	ERROR: 'ERREUR',
	Confirm: 'Confirmer',
	Close: 'Fermer',
	avg: 'Moyenne',
	Gender: 'Genre',
	Done: 'Fait',
	Waiting: 'En attente',
	Running: "En cours d'exécution",
	Paused: 'En pause',
	Cancelled: 'Annulé',
	Deleted: 'Supprimé',
	kg: 'kg',
	AreYouSureYouWantToCancel: 'Vous êtes sûr de vouloir annuler ?',
	PleaseWait: 'Veuillez patienter',
	Saving: 'Sauvegarder',
	Retry: 'Réessayer',
	Ok: 'Ok',
	TableNoData: 'Le tableau ne contient pas de données',
	Previous: 'Précédent',
	Next: 'Suivant',
	Loading: 'Chargement',
	January: 'Janvier',
	February: 'Février',
	March: 'Mars',
	April: 'Avril',
	May: 'Mai',
	June: 'Juin',
	July: 'Juillet',
	August: 'Août',
	September: 'Septembre',
	October: 'Octobre',
	November: 'Novembre',
	December: 'Décembre',
	PunctualDaySuccessor: '', // not used in English
	Animals: 'Animaux',
	week: 'Semaine',
	from: 'De',
	to: 'À',
	ml: 'ml',
	outOf: 'de',
	back: 'Retour',
	failed: 'a échoué',
	bluetooth: 'Bluetooth',
	WillYouContinue: 'Voulez-vous continuer ?',
	PlanAndGoal: 'Plan/objectif',
	DefaultWeaningWeight: 'Poids moyen au sevrage',
	allocateWeight: 'Attribuer un poids',
	totalWeanedInPeriod: 'Total des animaux sevrés au cours de la période',
	totalWeightInPeriod: 'Poids total durant la période',
	//Countries
	Denmark: 'Danemark',
	Taiwan: 'Taïwan',
	Australia: 'Australie',
	Sweden: 'Suède',
	Ecuador: 'Équateur',
	Norway: 'Norvège',
	France: 'France',
	'South Africa': 'Afrique du Sud',

	// Modules
	'Sow Module': 'Module truie',
	'Climatepigs Module': 'Module Crise climatique',
	'Finisher Module': 'SlagtesvinModul',
	'Settings Module': 'Module de paramétrage',
	'Status Module': 'Module de statut',
	'Developer Module': 'Module de développement',
	'App Info Module': "Module d'information sur les applications",
	'Adjust Feed': "Ajuster l'alimentation",
	'Pig management': 'Gestion porcine',
	'Delivery Module': 'Module de livraison',
	'Delivery In': 'Livraison dans',
	'Stock Module': 'Module de stock',
	Events: 'Événements',
	pregnancyEventLists: 'Liste des événements',
	// Home2 module icons
	developer: 'développeur',
	order: 'Commander',
	deliveries: 'Livraisons',
	status: 'Statut',
	settings: 'Paramètres',
	treatments: 'Traitements',
	alarms: 'Alertes récentes',
	climate: 'Climat',
	warnings: 'Avertissements récents',
	stock: 'Stock',
	plannedOrders: 'Commandes planifiées',
	reOrders: 'Renouvellement des commandes',
	unmanagedOrders: 'Commandes non gérées',
	plannedDeliveries: 'Livraisons prévues',
	unmanagedDeliveries: 'Livraisons non gérées',
	feedingHistory: 'Historique de l’alimentation',
	profile: 'Profil',
	online: 'En ligne',
	offline: 'Hors ligne',
	activities: 'Activités',
	tasks: 'Tâches',
	silos: 'Silos',
	sow: 'Truie',
	sowLardWidth: 'Épaisseur de la graisse',
	lardScanning: 'Analyse de la graisse',
	pigs: 'Porcs',

	'Alarms by Definition': 'Alarmes par définition',
	'Warnings by Definition': 'Avertissements par définition',
	'total alarms': 'Total des alarmes',
	'total warnings': 'Total des avertissements',
	'total active time': 'Durée totale en activité',
	'average active time': "Durée moyenne d'activité",
	'Feeding System': "Système d'alimentation",
	'Milling System': 'Système de broyage',

	// Settings
	SowManagementSetup: 'Paramètre de gestion des truies',

	// Sow module processes
	addsow: 'Ajouter une truie',
	removesow: 'Retirer une truie',
	movesow: 'Déplacer une truie',
	registertreatment: 'Traitement des registres',
	startadjustfeed: "Adapter l'alimentation",
	startadjustfeedSow: "Adapter l'alimentation",
	startadjustfeedFinisher: "Adapter l'alimentation",
	startadjustfeedWeaner: "Adapter l'alimentation",
	retryAdjustFeedMenuTitle: "Adapter les changements d'alimentation",
	assigntask: 'Attribuer une tâche',
	treatmentlists: 'Listes de traitement',
	departurelist: 'Liste des départs',
	DepartureWorkListSetting: 'Liste des départs',
	events: 'Événements',
	createTreatment: 'Créer un traitement',
	treatmentList: 'Liste des traitements',
	sowCard: 'Carte de la truie',
	foodlists: 'Listes d’alimentation',
	analysisandreports: 'Analyses et rapports',

	// Deliveries modules processes
	deliveryIn: 'Entrée de la livraison',
	deliveryOut: 'Sortie de la livraison',

	// language&profile
	language: 'Langue',
	english: 'Anglais',
	swedish: 'Suédois',
	danish: 'Danois',
	change: 'Changer',
	name: 'Nom',
	en: 'Anglais',
	da: 'Danois',
	logout: 'Déconnexion',
	Login: 'Connexion',
	CHANGEPASSWORD: 'Changer de mot de passe',
	EDITPROFILE: 'Modifier le profil',

	// overlay menu icons
	OVERLAYPIG: 'Porc',
	OVERLAYSOW: 'Truie',
	OVERLAYSTOCK: 'Stock',
	OVERLAYSETTINGS: 'Paramètres',
	OVERLAYSTABLE: 'Étable',
	OVERLAYFEEDMILLS: "Usines d'aliments",
	OVERLAYVALVES: 'Valves',
	OVERLAYNUMBERS: 'Numéros',
	OVERLAYOUTPUT: 'Sortie',
	OVERLAYGROWTH: 'Croissance',
	OVERLAYCLIMATE: 'Climat',
	OVERLAYREPORTS: 'Rapports',
	return: 'Retour',

	// Sow Management menues
	SOWANIMALS: 'Animaux',
	SOWEVENTS: 'ÉVÉNEMENTS',
	SowEvents: 'Événements',
	SOWTREATMENTS: 'Traitements',
	SOWMEDICIN: 'Médicaments/diagnostics',
	SOWDEPARTUREREASONS: 'Types de départ',
	SOWSETTINGS: 'Paramètres',

	//Pig management menues
	PIGSETTINGS: 'Paramètres',

	//Diagnose Picker
	selectDiagnose: 'Sélectionner Diagnostic/Vaccination',

	// Adjust feed process
	startroute: 'Itinéraire de départ',
	adjustfeed: "Ajuster l'alimentation",
	retryAdjustFeed: "Défaut d'ajustement de l'alimentation",
	fetchDataFromDistriwin: 'Obtenir des données de distriwin',
	FetchDataFromDistriwinConfirmDialog:
		'Vous êtes en train de récupérer les dernières données sur toutes les valves dans Distriwin. La mise à jour peut prendre de 5 à 10 minutes. Voulez-vous continuer ?',
	Time: 'Heure',
	FE_Day_Pig: 'FE/jour/porc',
	FE_Day_Sow: 'FE/jour/truie',
	Avr_Day: 'Moyenne/jour',
	'DEV CONST': 'FIXE',
	DEV: 'VAR.',
	DEVIATION: 'VARIABLE',
	DEVIATIONDAYS: 'VARIABLE JOURS',
	DEVPERCENT: 'VAR%',
	DEVIATIONDAYSPERCENT: 'VAR% - JOURS',
	Sows: 'Truies',
	Pigs: 'Porcs',
	DAYS: 'JOURS',
	WEEK: 'Semaine',
	SECTION: 'SECTION',
	FEEDINGCURVE: 'Courbe',
	UNNAMEDFEEDINGCURVE: 'Sans nom',
	NoHistoryAvailable: "Il n'y a pas d'historique des changements disponible",
	FeedingCurveAndRecept: 'Courbe/réception',
	fullSyncFailedMessage: 'Essayez à nouveau, ou contactez SKIOLD',
	wentThrough: 'est passé par',

	deleteMarked: 'Supprimer la sélection',
	SureDeleteMarked: 'Êtes-vous sûr de vouloir supprimer la sélection ?',
	sendMarked: 'Envoyer la sélection',

	// Warnings and Alarms modules
	RECENT_WARNINGS_CAPS: 'AVERTISSEMENTS RÉCENTS',
	RECENT_ALARMS_CAPS: 'ALARMES RÉCENTES',
	details: 'Détails',
	DistriWin: 'DistriWin',
	FlexMixPro: 'FlexMixPro',

	'Recent Alarms': 'Alertes récentes',
	'Recent Warnings': 'Avertissements récents',
	Date: 'Date',
	Event: 'Événement',
	History: 'Historique',

	addwidget: 'AJOUTER WIDGET',
	nowidget: "Vous n'avez plus de widget disponible !",
	HISTORY: 'HISTORIQUE',
	'ADJUST FEED': "AJUSTER L'ALIMENTATION",
	'SELECT SECTION': 'SÉLECTIONNER UNE SECTION',

	// Events
	selectevent: 'Sélectionner un événement',
	entrance: 'Entrée',
	departure: 'Départ',
	select: 'Sélectionner',
	animalNumber: 'Animal n°',
	animalNumberShorten: 'Anim n°',
	'Select Section': 'Sélectionner une section',
	animalNumbers: "Nombre d'animaux",
	setEntranceDateToMatingDate: "Fixer la date d'entrée à la date d'accouplement ",

	PEN: 'ENCLOS',
	VALVE: 'VALVE',
	SOWS: 'TRUIES',
	ANIMALS: 'ANIMAUX',
	FE_DAY: 'FU/JOUR',
	FIXED: 'FIXE',
	FIXEDPERCENT: 'FIXE %',
	fixedPercent: 'FIXE %',
	WEIGHT: 'POIDS',

	// Entrance
	entranceType: 'Type d’entrée',
	entranceTypeBought: 'Achat',
	entranceTypeFromYoungAnimal: 'D’un jeune animal',
	entranceTypeFromPolt: 'Des polts',
	entranceGender: 'Genre',
	selectEntranceType: "Sélectionner le type d'entrée",
	entranceMating: 'Accouplement',
	ChooseIdNumber: "Choisir le numéro d'identification",
	Litters: 'Portées',
	LitterNr: 'N° de portée',
	EntrancePrice: "Prix d'entrée",
	CreateAnimal: 'Créer un animal',
	PrintSowTable: 'Imprimer le tableau des truies',
	PrintList: 'Imprimer la liste',
	searchYoungAnimal: 'Chercher de jeunes animaux',
	youngAnimalNotFound: 'Jeune animal non trouvé',

	// Location Picker
	chooseStableSection: 'Choisir étable - Section',
	StableSection: 'Étable - Section',
	FromStableSection: 'De l’étable - Section',
	MoveToLocation: 'Déplacer à l’endroit',
	FromLocation: "De l'endroit",
	ToLocation: 'À l’endroit',
	FromPen: 'De l’enclos',
	choosePen: 'Choisir un enclos',
	SITEANDFARM: 'SITE/FERME',
	BUILDING: 'BÂTIMENT',
	country: 'Pays',
	farmName: "Nom du site/de l'exploitation",
	address: 'Adresse',
	postalCode: 'Code postal',
	postalCodeShort: 'Code postal',
	city: 'Ville',
	chrNum: 'CHRnr.',
	createBuilding: 'Créer un bâtiment',
	createSite: 'Créer un site',
	number: 'Numéro',
	units: 'Unités',
	amountOfSections: 'Nombre de sections',
	startNumber: 'Numéro de départ',
	amountOfPens: 'Nombre d’enclos',
	capacity: 'Capacité',
	addSections: 'Ajouter des sections',
	addPens: 'Ajouter des enclos',
	chooseCountry: 'Choisir un pays',
	isUsingQRCode: 'Utilisation du code QR',
	isUsingSections: 'Utilisation des sections',
	isUsingPens: 'Utilisation des enclos',
	letterBehindPen: 'Lettre derrière le n° d’enclos',

	chooseLocation: 'Choisir un lieu',
	Active: 'Actif',
	Inactive: 'Inactif',

	Valve: 'Valve',
	ValveNr: 'Numéro de la vanne',
	Pen: 'Enclos',

	OK: 'OK',

	Title: 'Titre',
	Location: 'Lieu',
	LocationShort: 'Lieu',
	currentLocation: 'Lieu actuel',
	newLocation: 'Nouveau lieu',
	Status: 'Statut',
	Description: 'Description',
	'How to Fix': 'Comment réparer',
	'No available curves': 'Pas de courbes disponibles',
	'Key performance indicators': 'Indicateurs clés de performance',
	Value: 'Valeur',
	Num: 'Num',
	Type: 'Type',
	Add: 'Ajouter',
	sort: 'Trier',
	EDITSECTION: 'MODIFIER LA SECTION',
	EDITBUILDING: 'MODIFIER LE BÂTIMENT',
	locationOverview: 'Aperçu des lieux',
	siteSetup: 'Mise en place du site',

	// Departure
	removeDeparture: 'Supprimer le départ',
	departureTypeSold: 'Vendu',
	departureTypeDead: 'Mort',
	departureTypeKilled: 'Tué',
	departureTypeToBeKilled: 'À tuer',
	departureTypeShouldDeparture: 'Au départ',
	departureTypePutDown: 'Abattu',
	departureReason: 'Motif',
	departureType: 'Type de départ',
	selectDepartureType: 'Type de départ',
	putDown: 'Abattu',
	departurePrice: 'Prix de départ',
	selectMarking: 'Sélectionner un marquage',
	selectReason: 'Sélectionner la raison',
	Days: 'Jours',
	Quantity: 'Quantité',

	marking: 'Marquage',

	// Active sows
	animalNr: 'Animal n°',
	kind: 'Genre',
	condition: 'Condition',
	entranceDate: 'Date d’entrée',
	Batch: 'Lot',
	transponder: 'Transpondeur',
	Teats: 'Mamelles',
	price: 'Prix',
	idNumber: "Numéro d'identification",
	birthdate: 'Date de naissance',
	Breed: 'Race',
	dadNr: 'Père n°',
	dadID: 'Identification du père',
	momNr: 'Mère n°',
	momID: 'Identification de la mère',
	index: 'Index',
	indexDate: "Date d'indexation",
	departureDate: 'Date de départ',
	age: 'Âge',
	CreateSow: 'Créer une truie',
	CHOOSELIST: 'CHOISIR UNE LISTE',
	cycleDays: 'Jours de cycle',
	minCycleDays: 'Jours de cycle min',
	maxCycleDays: 'Jours de cycle max',
	DryDays: 'Jours de sevrage',
	moveToMatingWeaningDryDays: 'Sevrage, jours de sevrage',
	moveToMatingWeaningDate: 'Sevrage, date',

	activeSowPdf: 'Active sow.pdf',

	// Animals
	activeSows: 'Truies actives',
	departuredSows: 'Truies sur le départ',
	boars: 'Verrats',
	youngAnimals: 'Jeunes animaux',

	// Gender
	female: 'Truie',
	male: 'Verrat',

	// Kinds
	youngFemale: 'Jeune femelle',
	gilt: 'Cochette',
	boar: 'Verrat',
	Unknown: 'Inconnu',
	YoungFemale: 'Jeune femelle',
	Gilt: 'Cochette',
	Sow: 'Truie',
	Boar: 'Verrat',
	Piglet: 'Porcelet',
	YoungAnimal: 'Jeune animal',
	youngFemales: 'Jeunes femelles',
	sowBoars: 'Truies/verrats',
	AnimalKinds: 'Espèces animales',

	// Import
	chooseImportFile: 'Choisir un fichier',
	chosenFile: 'Fichier choisi',
	import: 'Importer',
	startImport: "Démarrer l'importation",

	// Markings
	Marking: {
		Blue: 'Bleu',
		Red: 'Rouge',
		Green: 'Vert',
		Black: 'Noir',
		White: 'Blanc',
		EarmarkRemoved: 'Marquage supprimé',
		EarmarkAdded: 'Marquage ajouté',
	},

	DrugTypes: 'Types de médicament',
	DrugType: 'Type de médicament',
	AddDrugType: 'Ajouter un type de médicament',

	// Reasons
	createReason: 'Créer un motif',
	reasonName: 'Nom du motif',
	reasonNameDa: 'Nom du motif en danois',
	reasonNameEn: 'Nom du motif en anglais',
	reasonCode: 'Code de priorité',
	editReason: 'Modifier le motif',
	reasons: 'Motifs',
	reason: 'Motif',
	stemAnimalDeparture: 'Départ',
	reasonDead: 'Mort',

	// Pig Types
	piglets: 'Porcelets',
	polts: 'Volailles',

	// Diagnose
	DIAGNOSE_CATEGORIES: 'CATÉGORIES DE DIAGNOSTIC',
	Diagnose: 'Diagnostic',
	categories: 'Catégories',
	addCategory: 'Ajouter une catégorie',
	category: 'Catégorie de diagnostic',
	categoryNameEn: 'Nom de la catégorie (en)',
	categoryNameDa: 'Nom de la catégorie (da)',
	priority: 'Priorité',
	delete: 'Supprimer',
	DIAGNOSES: 'DIAGNOSTICS',
	addDiagnose: 'Ajouter un diagnostic',
	diagnoseNameDa: 'Nom du diagnostic (da)',
	diagnoseNameEn: 'Nom du diagnostic (en)',
	diagnoseDescriptionDa: 'Description du diagnostic (da)',
	diagnoseDescriptionEn: 'Description du diagnostic (en)',
	diagnoses: 'Diagnostics',
	DeleteDiagnosePrompt: 'Supprimer le diagnostic ? Cette action est irréversible.',
	isVaccination: 'Vaccination réelle',
	diagnoseName: 'Diagnostic',
	pigTypes: 'Types de porcs',
	pigType: 'Type de porc',
	diagnoseType: 'Type de diagnostic',
	vaccine: 'Vaccin',
	treatment: 'Traitement',
	create: 'Créer',
	selectDiagnoseDescription: 'Choisir les diagnostics/vaccinations',
	selectAnimalKind: "Choisir le type d'animal",

	//Treatmentplan
	selectTreatmentDefinition: 'Diagnostic',
	noDrugTypesForAnimalTypeVaccine:
		'Vous devez ajouter au stock un médicament de type vaccin pour cette espèce animale, pour créer une nouvelle définition vaccinale',
	noDrugTypesForAnimalTypeMedicine:
		'Vous devez ajouter un médicament de type médicament au stock pour cette espèce animale, pour créer une nouvelle définition diagnostique',
	weightLabel: 'Poids',
	registerTreatment: 'Traitement des registres',
	registerToBeTreated: 'À traiter',
	VALIDATION_ERROR_NoDiagnoseSet: 'Diagnostic nécessaire',
	VALIDATION_ERROR_NoMarkingSet: 'Marquage requis',
	VALIDATION_ERROR_NoWeightSet: 'Poids requis',
	AmountOfPiglets: 'Nombre de porcelets',
	AmountOfPigletsShort: 'Porcelets',
	DrugAmount: 'Quantité de médicament',
	DrugAmountShort: 'Montant',
	DrugAmountPerPig: 'Quantité de médicaments par porc',

	// Error messages
	SYNC_ERROR:
		'Un problème est survenu, les données pourraient ne pas être synchronisées. Réessayez en réinitialisant la page',
	VALIDATION_ERROR_DRUGSTOCK_REASON_REQUIRED: 'Vous devez indiquer la raison du déchet',
	VALIDATION_ERROR_DRUGSTOCK_USE_ADD_INSTEAD: 'Utilisez plutôt « ajouter »',
	VALIDATION_ERROR_DRUGSTOCK_ERROR_EDITING_WASTE_HAS_TO_BE_NEGATIVE: 'Les déchets sont mauvais',
	VALIDATION_ERROR_ANIMAL_IS_NOT_PREGNANT_ANYMORE_AND_ANIMAL_IS_ON_PREGNANT_LOCATION:
		"Vous devez déplacer l'animal vers un lieu qui n'est pas un lieu de gestation",
	VALIDATION_ERROR_ANIMAL_NEEDS_TO_BE_PREGNANT_TO_MOVE_TO_THIS_LOCATION:
		"L'animal doit être en gestation pour être déplacé vers un lieu de gestation",
	VALIDATION_ERROR_ESF_IS_ENABLED_AND_TRANSPONDER_IS_REQUIRED: 'Pas de transpondeur choisi',
	VALIDATION_ERROR_DRUGSTOCK_ERROR_EDITING_ADJUSTMENT_HAS_TO_BE_POSITIVE: 'Les ajustements sont positifs',
	VALIDATION_ERROR_DRUGSTOCK_NOT_SELECTED: 'Aucun type de médicament sélectionné',
	VALIDATION_ERROR_FEEDINGSTOCK_NOT_SELECTED: 'Le sujet Alimentation est obligatoire',
	VALIDATION_ERROR_FEEDINGSTOCK_AMOUNT: 'Aucun montant choisi',
	VALIDATION_ERROR_FEEDINGSTOCK_PRICE: 'Aucun prix choisi',
	VALIDATION_ERROR_NoFeedingSubjectChoosen: 'Vous devez choisir le sujet Alimentation',
	VALIDATION_ERROR_NoFeedingAnimalTypeChoosen: "Vous devez choisir le type d'animal",
	VALIDATION_ERROR_FEEDINGSTOCK_FESOW: 'FEsow doit être choisi - 0 est une valeur acceptable',

	VALIDATION_ERROR_FEEDINGSTOCK_FEPIG: 'FEpig doit être choisi - 0 est une valeur acceptable',

	VALIDATION_ERROR_FEEDINGSTOCK_FOSFOR: 'Pas de phosphore choisi',
	VALIDATION_ERROR_TOTAL_AMOUNT_TO_LOW_TO_CONTAIN_ALREADY_USED_FOOD: 'Votre montant doit être au moins égal à : ',
	VALIDATION_ERROR_TOTAL_AMOUNT_AVAILABLE_WILL_BE_LOWER_THAN_TOTALUSED:
		"Votre quantité totale d'alimentation sera inférieure à votre quantité totale utilisée",
	VALIDATION_ERROR_DRUGSTOCK_AMOUNT: 'Aucun montant choisi',
	VALIDATION_ERROR_DRUGSTOCK_DATE: 'Aucune date sélectionnée ',
	VALIDATION_ERROR_TRANSPONDER_ALREADY_IN_USE_BY_SOW: "Numéro de transpondeur déjà utilisé par l'animal n° ",
	VALIDATION_ERROR_ANIMAL_NUMBER_ALREADY_IN_USE_BY_SOW: "Le numéro de l'animal est déjà utilisé",
	VALIDATION_ERROR_AnimalNumberNotSet: "Le numéro de l'animal n'est pas défini",
	VALIDATION_ERROR_AnimalNumberNotFound: "Le numéro de l'animal n'existe pas",
	VALIDATION_ERROR_AnimalNumberIsDepartured: "Le numéro de l'animal est sur le départ",
	VALIDATION_ERROR_AnimalNumberExists: "Le numéro d'animal existe déjà",
	VALIDATION_ERROR_EntranceDateNotSet: "Date d'entrée non définie",
	VALIDATION_ERROR_PenIdNotSet: 'Enclos non défini',
	VALIDATION_ERROR_STABLE_SECTION_NOT_SET: 'Étable - section non définie',
	VALIDATION_ERROR_SiteNotSet: 'Site non défini',
	VALIDATION_ERROR_MatingEqualsOneLitter:
		"Les portées doivent être au moins au nombre d'une lorsque l'accouplement est fini",
	VALIDATION_ERROR_EntranceTypeNotSet: "Type d'entrée non défini",
	VALIDATION_ERROR_AnimalNotFound: 'Animal non trouvé',
	VALIDATION_ERROR_DepartureTypeNotSet: 'Type de départ non défini',
	VALIDATION_ERROR_DepartureDateNotSet: 'Date de départ non définie',
	VALIDATION_ERROR_DATE_NOT_SET: 'Date non définie',
	VALIDATION_ERROR_LARD_WIDTH_NOT_SET: "L’épaisseur de la graisse n'est pas définie",
	VALIDATION_ERROR_DepartureBoarIsKs: 'Le verrat est un verrat KS',
	VALIDATION_ERROR_DepartureDateMostAfterCurrentDate:
		'La date de départ est généralement postérieure à la date du jour',
	VALIDATION_ERROR_NameNotSet: 'Nom non défini',
	VALIDATION_ERROR_UnitNotSet: 'Unité non définie',
	VALIDATION_ERROR_TransponderOccupied: 'Transpondeur occupé',
	VALIDATION_ERROR_BothNamesRequired: 'Les deux noms sont requis',
	VALIDATION_ERROR_CategoryRequired: 'La catégorie est obligatoire',
	VALIDATION_ERROR_CategoryInUse: "Can't delete category already in use on a diagnose",
	VALIDATION_ERROR_FieldsRequired: 'Tous les champs sont obligatoires',
	VALIDATION_ERROR_NoDrugId: 'Choisir un médicament',
	VALIDATION_ERROR_AnotherVaccineAlreadyExist: 'Un vaccin défini existe déjà',
	VALIDATION_ERROR_CannotBeNegative: 'Les valeurs ne peuvent pas être négatives',
	VALIDATION_ERROR_CannotBeNegativeOrZero: 'Les valeurs ne peuvent pas être négatives ou nulles',
	VALIDATION_ERROR_CategoryNotSet: 'Aucune catégorie choisie',
	VALIDATION_ERROR_NoDiagnoseID: 'Pas de diagnostic',
	VALIDATION_ERROR_NoAnimalKinds: "Pas d'espèces animales définies",
	VALIDATION_ERROR_NoDrugTypeIsChoosed: 'Médicament non défini',
	VALIDATION_ERROR_NoFrequency: 'Fréquence non définie',
	VALIDATION_ERROR_NoNumberOfTreatments: 'Nombre de traitements non défini',
	VALIDATION_ERROR_RefreshError: 'Erreur, recharger la page',
	VALIDATION_ERROR_NoRetentionTime: 'Temps de séjour non défini',
	VALIDATION_ERROR_NoTreatmentType: 'Type de traitement non défini',
	VALIDATION_ERROR_NoDrugAmountValue: 'Quantité de médicaments non définie',
	VALIDATION_ERROR_NoPerUnit: 'Par unité non définie',
	VALIDATION_ERROR_NoUnitType: "Type d'unité non défini",
	VALIDATION_ERROR_BatchNotSet: 'Lot non défini',
	VALIDATION_ERROR_FinishedDateNotSet: 'Date non définie',
	VALIDATION_ERROR_MatingDateAndYoungFemaleError:
		"La date d'entrée de l'animal est postérieure à la date d'accouplement",
	VALIDATION_ERROR_InvalidAgeInterval: "L'âge des jeunes femelles est en dehors de la fourchette ",
	VALIDATION_ERROR_InvalidPregnancyEvent: 'Événement Gestation invalide',
	VALIDATION_ERROR_HasNoPreviousEvent: 'Aucun événement Gestation antérieur',
	VALIDATION_ERROR_SowAlreadyPregnant: 'La truie est déjà en gestation',
	VALIDATION_ERROR_DateIsBeforePreviousEvent: "La date est définie plus tôt que l'événement précédent",
	VALIDATION_ERROR_InvalidMatingToFarrowingInterval:
		"L'intervalle entre l’accouplement et la mise bas n'est pas valable",
	VALIDATION_ERROR_RegistrationErrorPregnancyEvent:
		"Une erreur s'est produite lors de l'enregistrement d'un événement Gestation",
	VALIDATION_WARNING_CHANGING_SECTION_OR_BUILDING_WILL_RESULT_IN_RESET_FOR_CHANGED_BUILDINGS_SECTIONS:
		"Vous avez modifié la relation entre les emplacements numériques Distriwin et SKIOLD, ce qui a pour conséquence que les valves ne sont plus connectées à un emplacement. À partir de la vue d'ensemble des lieux, vous devez reconnecter les vannes à un lieu. Sauvegarder les changements ?",
	VALIDATION_WARNING_SECTION_NOT_BELONGING_TO_SECTION_SELECTED_HAS_NON_SETTED_PENS:
		"Les données ne peuvent pas être sauvegardées, car il y a des sections, qui n'appartiennent pas à la section sélectionnée, sans valves connectées, voulez-vous corriger ?",
	VALIDATION_WARNING_YOU_ARE_CREATING_A_USAGE_THAT_IS_IN_ANOTHER_USAGE_PERIOD:
		'Vous créez une période dans une autre période : ',
	VALIDATION_ERROR_NoAbortionIfAlivePigs: "Impossible d'annuler, si les porcs sont vivants",
	VALIDATION_ERROR_InvalidFarrowingToWeaningInterval:
		"L'intervalle entre la mise bas et le sevrage n'est pas valable",
	VALIDATION_ERROR_SET_WHAT_TO_TREAT:
		'Vous devez choisir si tous les porcs doivent être traités ou seulement les verrats',
	VALIDATION_ERROR_InvalidWeaningToMatingInterval:
		"L'intervalle entre le sevrage et l'accouplement n'est pas valable",
	VALIDATION_ERROR_GiltMatingDateError:
		"Lorsque l'animal est une cochette, la date d'accouplement doit être antérieure à la date d'entrée",
	VALIDATION_ERROR_PregnantDaysNotSet: 'Les jours de gestation ne sont pas définis',
	VALIDATION_ERROR_NursingDaysNotSet: "Les jours d'allaitement ne sont pas définis",
	VALIDATION_ERROR_SHOW_ANIMAL_ON_LIST: "Il n'y a pas de valeurs pour les animaux représentatifs sur la liste",
	VALIDATION_ERROR_StartDateIsNotSet: "La date de début n'est pas définie",
	VALIDATION_ERROR_WeekCycleIsNotSet: "Le cycle hebdomadaire n'est pas défini",
	VALIDATION_ERROR_CycledaysNotSet: 'Les jours de cycle ne sont pas définis',
	VALIDATION_ERROR_MatingPeriodIsNotSet: "La période d'accouplement n'est pas définie",
	VALIDATION_ERROR_PregnantAndNursingDaysExceeded:
		"Les jours de gestation + jours d'allaitement dépassant les semaines du cycle de la truie",
	VALIDATION_ERROR_NoMatingBatchesGenerated: "Il n'y a pas de lot d'accouplement généré",
	VALIDATION_ERROR_EndDateNotSet: "Aucune date de fin n'a été générée",
	VALIDATION_ERROR_OverlapsOtherSettingsDates: 'La date de début ou de fin du paramétrage chevauche une autre',
	VALIDATION_ERROR_DateDoesNotMatchAMatingBatch:
		"La date choisie ne correspond pas à un lot d'accouplement. Veuillez mettre en place des lots d'accouplement  ",
	VALIDATION_ERROR_RegistrationErrorOnMatingBatch:
		"Une erreur s'est produite lors de l'enregistrement du lot d'accouplement",
	VALDIATION_ERROR_UnknownTreatmentDefinitionSet: 'La définition du traitement est inconnue par le serveur',
	VALIDATION_ERROR_TreatmentPlannedDateNotSet: 'Le traitement n’a pas de date prévue',
	VALIDATION_ERROR_TreatmentSiteIdNotSet: "Le traitement n’a pas d'identification du lieu",
	VALIDATION_ERROR_ScanCantBeSameDateAsMated: "Impossible d’analyser la truie le même jour que l'accouplement",
	VALIDATION_ERROR_StartDateIsEarlierThanCurrentDate: 'La date de début doit être postérieure à la date actuelle',
	VALIDATION_ERROR_NewStartDateMustBeEqualToExisting:
		"La date de début doit être postérieure à la date d'aujourd'hui et correspondre à une date de début d'accouplement calculée",
	VALIDATION_ERROR_NoneAlive: 'Aucun animal vivant ne porte ce numéro',
	VALIDATION_ERROR_TransponderInUse: 'Transpondeur déjà utilisé',
	VALIDATION_ERROR_SowDoesNotExist: "La truie n'existe pas",
	VALIDATION_ERROR_AnimalNotSet: 'Animal non défini',
	VALIDATION_ERROR_BuildingNumberNotSet: "Le numéro du bâtiment n'est pas défini",
	VALIDATION_ERROR_BuildingNameNotSet: "Le nom du bâtiment n'est pas défini",
	VALIDATION_ERROR_ProductionFormNotSet: 'Formulaire de production non défini',
	VALIDATION_ERROR_BuildingNumberAlreadyExists: 'Le numéro du bâtiment existe déjà',
	VALIDATION_ERROR_SectionTypeNotSet: 'Type de section non défini',
	VALIDATION_ERROR_DuplicateSectionNumbers:
		'Impossible de générer des sections en raison de la duplication des numéros de section',
	VALIDATION_ERROR_FEEDINGSTOCK_NO_FEEDING_SUBJECT_SELECTED: 'Vous devez choisir le sujet Alimentation',
	VALIDATION_ERROR_DuplicatePenNumbers:
		'Impossible de générer des enclos en raison de la duplication des numéros d’enclos',
	VALIDATION_ERROR_RegistrationError: "Une erreur s'est produite lors de l'enregistrement",
	VALIDATION_ERROR_HasNoSection: "N'a pas de section",
	VALIDATION_ERROR_HasNoBuilding: "N'a pas de bâtiment",
	VALIDATION_ERROR_SetupValidationSetup:
		'Veuillez configurer les intervalles de validation sous les paramètres de la truie',
	VALIDATION_ERROR_InvalidPregnantPeriod:
		"Période de gestation non valable. L'intervalle doit être compris entre 100 et 125 jours",
	VALIDATION_ERROR_invalidPlanPeriod:
		"Période de planification non valable. L'intervalle doit être entre plan - et plan +",
	VALIDATION_ERROR_InvalidNursingPeriod: 'Période d’allaitement non valable. Ne peut être inférieure à 21 jours',
	VALIDATION_ERROR_LocationNotSet: "Le lieu n'est pas défini",
	VALIDATION_ERROR_InvalidFarrowingAlive:
		'Verrats et truies vivants, ne correspondent pas au nombre total de porcs vivants',
	VALIDATION_ERROR_IdNumberNotSet: "Le numéro d'identification n'est pas défini.",
	VALIDATION_ERROR_WeaningNumAliveIsNotSet: "Le sevrage des porcs n'est pas défini",
	VALIDATION_ERROR_MoveDateNotSet: "La date du déplacement n'est pas définie",
	VALIDATION_ERROR_NewPenIdNotSet: "L’enclos n'est pas défini",
	VALIDATION_ERROR_NewPenSameAsCurrent: 'L’enclos choisi est l’enclos actuel',
	VALIDATION_ERROR_FarrowingNumAliveNotSet: "Num vivant n'est pas défini",
	VALIDATION_ERROR_BuildingIsNotEmpty: "Ne peut pas être supprimé, car le bâtiment n'est pas vide",
	VALIDATION_ERROR_SectionIsNotEmpty: "Ne peut pas être supprimée, car la section n'est pas vide",
	VALIDATION_ERROR_MatingBatchOutOfRange: "Les lots d'accouplement ne remontent pas aussi loin dans le passé",
	VALIDATION_ERROR_NotAllowedToDeleteDiagnose: 'Diagnostic encore utilisé dans une ferme',
	VALIDATION_ERROR_DiagnoseAnimalKindStillInUse: "Diagnostiquer le type d'animal encore utilisé dans une ferme",
	VALIDATION_ERROR_ColumnTextIsNotSet: 'Si le diagnostic est choisi, remplir le texte de la colonne',
	VALIDATION_ERROR_BoarProcentageIsNotSet: 'Si le verrat est choisi, inscrire verrat %',
	VALIDATION_ERROR_NursingFromIsEmptyOrNegative: '« Allaitement de » est obligatoire et ne peut pas être négatif',
	VALIDATION_ERROR_NursingToIsEmptyOrNegative:
		'« Allaitement jusqu’à » est obligatoire, moins que « Allaitement de »',
	VALIDATION_ERROR_PregnantFromIsEmptyOrNegative: '« Gestation de » est obligatoire et ne peut pas être négatif',
	VALIDATION_ERROR_PregnantToIsEmptyOrNegative: '« Gestation jusqu’à » est obligatoire, moins que « Gestation de »',
	VALIDATION_ERROR_NursingDaysAreRequiered: "« Jours d'allaitement » est obligatoire",
	VALIDATION_ERROR_AvailableOnNeedsToBeSet: 'Liste disponible sur les besoins à fixer',
	VALIDATION_ERROR_ChoosePregnancyEvent: 'Choisir un événement de gestation',
	VALIDATION_ERROR_IncludeLittersTo: 'Le nombre max. de portées doit être défini',
	VALIDATION_ERROR_AfterEventDaysGreaterThanZero: "Le nombre de jours après l'événement doit être supérieur à zéro",
	VALIDATION_ERROR_ChooseAtleastOneTreatmentDef: 'Choisir au moins une définition de traitement',
	VALIDATION_ERROR_ChooseAnimalNotSet: "Choisir un animal n'est pas défini",
	VALIDATION_ERROR_FEEDINGSTOCK_NO_FEEDING_SUBJECT_NAME: 'Saisir le nom du sujet Alimentation',
	VALIDATION_ERROR_AMOUNT_EXCEED_STOCK: "La quantité dépasse le stock d'alimentation actuel",
	VALIDATION_ERROR_NO_CODE_SET: "Le code n'est pas défini",
	VALIDATION_ERROR_INVALID_CODE_SET: 'Choisir un autre code',
	VALIDATION_ERROR_DEFAULT_CODE_SET: "Default code won't be accepted",
	VALIDATION_ERROR_NO_UNITTYPE_SET: 'Choisir des unités',
	VALIDATION_ERROR_NO_PROCESS_EQUIPMENT_SET: 'Choisir un équipement de traitement',
	VALIDATION_ERROR_InsertWithoutTransponder: 'Impossible de créer un animal dans ESF sans transpondeur',
	VALIDATION_ERROR_CycleDayIsEmpty: "Le jour du cycle n'est pas défini",
	VALIDATION_ERROR_AnimalNumberIsEmpty: "Impossible de mettre à jour un animal dans ESF sans numéro d'animal",
	VALIDATION_ERROR_FeedCurveDoesNotExist: "La courbe d'alimentation choisie n'existe pas",
	VALIDATION_ERROR_LocationIsEmpty: "Le lieu n'est pas défini",
	VALIDATION_ERROR_InvalidAnimalKindOnEsfLocation: "L'animal choisi ne peut pas être créé dans un enclos ESF",
	VALIDATION_ERROR_FARM_HAS_ESF_BUT_ANIMAL_DOES_NOT_HAVE_TRANSPONDER:
		"L'animal doit être muni d'un transpondeur, avant de pouvoir être déplacé vers un endroit équipé d'un transpondeur",
	VALIDATION_ERROR_FEED_CURVE_NUMBER_IS_NOT_SET:
		"La courbe d'alimentation est nécessaire parce que le lieu sélectionné est un lieu ESF",
	VALIDATION_ERROR_ANIMALS_NEED_TRANSPONDER: "Les animaux répertoriés n'ont pas de numéro de transpondeur.",
	VALIDATION_ERROR_INVALID_ESF_DATA: 'Les données sur les animaux scannés sont obsolètes',
	VALIDATION_ERROR_BLE_CHOOSE_LOCATION:
		"Pour utiliser le scanner, choisir l'emplacement par défaut dans l'application.",
	VALIDATION_ERROR_SOW_INVALID_FOR_WORKLIST: "Animal n'est pas valable pour cette liste de travail",
	VALIDATION_ERROR_ENTRACE_SOW_EDIT_LITTER: "Portée doit être de 2 ou plus, car l'animal a été créé comme une truie",
	VALIDATION_ERROR_MATING_BATCH_1_YEAR:
		"Il est nécessaire d'avoir un lot d'accouplement au moins un an après la date d'aujourd'hui. Veuillez générer davantage de lots d'accouplements",
	VALIDATION_ERROR_MUST_BE_START_MATING_BATCH:
		"La date doit être la date de début d'un lot d'accouplement plus tardif que la date actuelle",
	VALIDATION_ERROR_AVG_WEIGHT_POSITIVE: 'Le poids moyen doit être positif',
	VALIDATION_ERROR_DEAD_PIGLETS_NOT_SET: 'Montant non défini - Doit être supérieur à 0',
	VALIDATION_ERROR_MUST_BE_NURSING: 'La truie doit allaitée',
	VALIDATION_ERROR_NO_FEEDING_ADJUSTMENT: "Le type d'aliment ne fait l'objet d'aucun ajustement avant la date fixée",

	//Warning messages
	VALIDATION_WARNING_MatingToFarrowingOutOfInterval:
		"La période de gestation de la truie est en dehors de l'intervalle configuré, voulez-vous quand même sauvegarder ?",
	VALIDATION_ERROR_YOU_ARE_MATING_A_ANIMAL_THAT_IS_NURSING:
		'Vous accouplez un animal qui allaite, voulez-vous quand même sauvegarder ?',
	VALIDATION_WARNING_FarrowingToWeaningOutOfInterval:
		"La période d’allaitement de la truie est en dehors de l'intervalle configuré, voulez-vous quand même sauvegarder ?",
	VALIDATION_WARNING_WeaningToMatingOutOfInterval:
		"La période du sevrage à l’accouplement est en dehors de l'intervalle configuré, voulez-vous quand même sauvegarder ?",
	VALIDATION_WARNING_MatingAgeOutOfInterval:
		"L’âge de l’animal est en dehors de l'intervalle configuré, voulez-vous quand même sauvegarder ?",
	VALIDATION_WARNING_ConfirmDeletionMated: "Effacer l'accouplement choisi ?",
	VALIDATION_WARNING_ConfirmDeletionFeedingStockAdjustment:
		"Êtes-vous sûr de vouloir supprimer l'ajustement de l'alimentation ?",
	VALIDATION_WARNING_ConfirmDeletionFeedingStockUsage:
		"Êtes-vous sûr de vouloir supprimer l'utilisation de l'alimentation ?",
	VALIDATION_WARNING_ConfirmDeletionAdjustments: "Supprimer l'ajustement du médicament choisi ?",
	VALIDATION_WARNING_ConfirmDeletionScanning: 'Supprimer le scanner choisi ?',
	VALIDATION_WARNING_ConfirmDeletionFarrowing: 'Supprimer la mise bas choisie ?',
	VALIDATION_WARNING_ConfirmDeletionWeaning: 'Supprimer le sevrage choisi ?',
	VALIDATION_WARNING_CapacityExceeded1: 'Capacité de l’enclos dépassée :',
	VALIDATION_WARNING_CapacityExceeded2: 'Quantité actuelle de porcs :',
	VALIDATION_WARNING_CapacityExceeded3: 'La capacité est de :',
	VALIDATION_WARNING_ConfirmWarningMessage: 'Vous êtes sûr de vouloir sauvegarder ?',
	VALIDATION_WARNING_ConfirmWarningDataNotSavedMessage: 'Il y a des données non sauvegardées, les sauvegarder ?',
	VALIDATION_WARNING_ConfirmWarningMessageMoveEvent:
		'Êtes-vous sûr de vouloir déplacer cet animal vers le nouveau lieu ?',
	VALIDATION_WARNING_ConfirmDeletionBuilding: 'Êtes-vous sûr de vouloir supprimer ce bâtiment ?',
	VALIDATION_WARNING_ConfirmDeletionSection: 'Êtes-vous sûr de vouloir supprimer cette section ?',
	VALIDATION_WARNING_ValveGroupSectionContainsOldData:
		"La section sélectionnée contient d'anciennes données sur les groupes de vannes",
	VALIDATION_WARNING_ValveGroupsModificationOld:
		"La modification du/des groupe(s) de vannes n'a pas été sauvegardée car elle est obsolète",
	VALIDATION_WARNING_ValveGroupIsNotEditable:
		"Les données sur les vannes datent d'hier et ne peuvent être modifiées en raison de l'ancienneté des données. Via le site web, en ajustant les changements d’alimentation, vous pouvez télécharger les dernières données depuis Distriwin",
	VALIDATION_WARNING_ESF_DATA_IS_NOT_UP_TO_DATE: 'Certaines données ne sont pas à jour avec le contrôleur',
	VALIDATION_ERROR_CURVE_INVALID: 'Courbe à sélectionner pour la sauvegarde',
	VALIDATION_ERROR_FIXED_PERCENT_INVALID: 'Le pourcentage fixé doit être entre 0 et 100 pour pouvoir sauvegarder',
	// Misc
	PromptSureDelete: 'Êtes-vous sûr de vouloir supprimer ?',
	SetupValidationSetup: 'Veuillez entrer les paramètres de validation sur la page des paramètres',

	//Treatment definition
	TreatmentDefinitions: 'Définitions des traitements/vaccins',
	drug: 'Médicament',
	chooseDrugType: 'Choisir un type de médicament',
	RetentionTime: 'Temps de séjour',
	method: 'Méthode',
	amountPerUnit: 'Montant par unité',
	duration: 'Durée',
	interval: 'Intervalle',
	amount: 'Montant',
	numberTreatments: 'Montant',
	per: 'par',
	addTreatDef: 'Ajouter un traitement/vaccin',
	choose: 'Choisir',
	SowBoarGlit: 'Truie/verrat/cochette',
	none: 'Aucun',

	//Treatment type
	Food: 'Alimentation',
	Water: 'Eau',
	Oral: 'Oral',
	Injection: 'Injection',
	OnTheSkin: 'Sur la peau',

	//Treatment unit
	perKgPig: 'Kg de porc',
	perKgFood: 'Kg de nourriture',
	perAnimal: 'Animal',

	//Frequency
	once: 'Une fois',
	everyDay: 'Tous les jours',
	everyOtherDay: 'Tous les deux jours',
	everyWeek: 'Toutes les semaines',

	//Vaccination
	event: 'Événement',
	Days1: 'Jour 1',
	Days2: 'Jour 2',
	Age1: 'Âge 1',
	Age2: 'Âge 2',
	Age3: 'Âge 3',
	Farrowing: 'Mise bas',
	Mating: 'Accouplement',
	Averting: 'Sevrage',
	Birthdate: 'Date de naissance',
	chooseDrug: 'Choisir un médicament',

	//Treatment log
	TREATMENT_LOG: 'TRAITEMENTS',
	Treatments: 'Traitements',
	Treatment: 'Traitement',
	FetchTreatmentPlans: 'Extraire',
	ExecutedDate: 'Exécuté sur',
	Reactivate: 'Réactiver',
	FromDate: 'Date de début',
	ToDate: 'Date de fin',
	User: 'Utilisateur',

	//TreatmentDetails
	treatmentDetails: 'Détails du traitement',
	Treated: 'Traité',
	DeleteTreatmentPlan: 'Supprimer le plan de traitement',
	DeleteTreatmentPlanPrompt: 'Supprimer tout le plan de traitement ? Cette action est irréversible.',
	TreatmentPlanNotFound: 'Plan de traitement non trouvé',
	PerAnimal: 'Par animal',
	Total: 'Total',
	OverdueAlertHeader: 'Traitement en retard !',
	OverdueAlertMessage: 'Quand avez-vous effectué le traitement ?',

	//Pregnancy lists
	PregnancyLists: 'Listes de gestation',
	moveEvents: 'Déplacements',

	//Mating
	mating: 'Accouplement',
	Matings: 'Accouplements',
	Scannings: 'Scanners',
	Farrowings: 'Mises bas',
	Farrowed: 'Mis bas',
	Weanings: 'Sevrages',
	Grade: 'Grade',
	NumAlive: 'Nombre en vie',
	NumDead: 'Nombre de morts',

	//Sow State
	Dry: 'Sec',
	Preg: 'Enceinte',
	Nursing: 'Allaitement',
	PregnantAndNursing: 'Enceinte/Allaitante',
	NursingLong: 'Allaitement',

	//Sow State Distriwin
	Gestation: 'Enceinte',
	Maternity: 'Allaitement',

	//Scanned
	True: 'Oui',
	False: 'Non',
	Maybe: '?',
	scanning: 'Scanner en cours',
	Pregnant: 'Enceinte',
	NonPregant: 'Non enceinte',

	//Farrowing
	farrowing: 'Mise bas',
	alive: 'Vivant',
	dead: 'Mort',
	aliveFemale: 'Porcs femelles vivants',
	aliveMale: 'Porcs mâles vivants',
	abortion: 'Avortement',
	farrowingInProgress: 'Mise bas en cours',

	//weaning
	pcsWithoutDot: 'pcs',
	pcs: 'pcs',
	Pcs: 'Pcs',
	avgWeight: 'Poids moyen/porc',
	avgWeightSlashPig: 'poids/porc',
	avgPriceSlashPig: 'prix/porc',
	totalWeight: 'Poids total',
	nursingSow: 'Truie allaitante',
	avgPrice: 'Prix par porc',
	totalPrice: 'Prix total',
	Weaning: 'Sevrage',
	nursSow: 'N',
	totalPcs: 'Total pcs',
	avgPcs: 'Nombre moyen de pièces',
	defaultWeight: 'Poids par défaut',

	//Mating batch
	matingBatch: "Lot d'accouplement",
	matingBatchUpToAndIncluding: 'Mating batch calculated up to and including',
	weekdayStart: 'Jour de début',
	StartDateForBatch: "Date de début du lot d'accouplement",
	daysForMatingPeriod: "Jour de la période d'accouplement",
	cycleInWeeks: 'Cycles en semaines',
	matingPeriod: "Période d'accouplement",
	FarrowingPeriod: 'Période de mise bas',
	batchNum: 'Numéro de lot',
	pregnantDays: 'Jours de gestation',
	NursingDays: "Jours d'allaitement",
	calculate: 'Calculer',
	addMatingBatch: "Ajouter un lot d'accouplement",
	deleteMatingBatch: "Supprimer le lot d'accouplement",
	weaningPeriod: 'Période de sevrage',
	Start: 'Démarrage',
	End: 'Fin',
	deleteMatingBatchesFromDateAndForward: "Supprimer les lots d'accouplement à partir de cette date et au-delà",
	repeat: 'Répéter',
	manuel: 'Manuel',
	daysExceeded: 'Nombre de jours dépassé',
	batchPerCycle: 'Quantité de lots - commencer avec le lot 1 par',
	timesToRepeat: 'Nombres de fois à répéter',
	setStartDateToNextAvailableBatch: "Fixer la date de début du prochain lot d'accouplement disponible ?",

	/////mating batch report
	matingBatchReport: "Rapport sur les lots d'accouplement",
	printMatingBatchReport: "Imprimer le rapport sur les lots d'accouplement",
	actualWeek: 'Semaine en cours',
	//mating
	TotalMatings: 'Accouplements, pcs',
	YoungAnimalMatings: '\u2022 Dont jeune femelle, pcs',
	TotalDepartures: 'Animaux qui ont quitté le lot, pcs',
	DepartureNonPregnant: '\u2022 Dont pas enceintes, pcs',
	DepartureKilled: '\u2022 Dont abattus/vendus, pcs',
	DepartureDead: '\u2022 Dont morts/tués, pcs',
	AvgWasteDays: 'Nombre moyen de jours non productifs',
	ReturnSows: '\u2022 Dont truie de retour',
	ThereOfAbortions: '\u2022 Dont avortement',
	WeaningToFirstMating: 'Jours du sevrage au premier accouplement',
	AnimalsToFarrowing: 'Animaux pour la mise bas, pcs',
	//farrowing
	TotalFarrowings: 'Mises-bas, pcs',
	NeedFarrowing: "Truies qui n'ont pas encore mis bas, pcs",
	AvgAlive: 'Moyenne des animaux nés vivants, pcs',
	AvgDead: 'Moyenne des animaux mort-nés, pcs',
	FarrowingRate: 'Taux de mise bas pour le lot, %',
	AvgPregnantDays: 'Nombre moyen de jours de gestation',
	ThereOfFirstParity: '\u2022 Dont 1er accouplement',
	AvgLivebornFirstParity: 'Moyenne de nés vivants au 1er accouplement, pcs',
	//weaning
	TotalWeanings: 'Truies sevrées, pcs',
	NursingSows: '\u2022 Dont truies allaitantes, pcs',
	NeedWeaning: 'Truies qui ne sont pas encore sevrées, pcs',
	AvgAlivePerLitter: 'Nombre moyen de naissances par portée, pcs',
	AvgDeadPerLitter: 'Nombre moyen de mort-nés par portée. pcs',
	AvgWeanedPerLitter: 'Moyenne de porcs sevrés par portée, pcs',
	AvgWeight: 'Poids par porc, kg',
	DeadTillWeaning: 'Porcelets morts',
	DeadToAliveRate: 'Porcelets morts en % des vivants',
	AvgNursingDays: "Nombre moyen de jours d'allaitement",
	NursingSowsCount: 'Truies allaitantes',
	AvgAliveShort: 'Moyenne des vivants',
	AvgDeadShort: 'Moyenne des morts',
	AvgPriceShort: 'Prix moyen',
	AvgWeanedPerWeaninig: 'Sevrés par sevrage, pcs',
	TotalWeanedPiglets: 'Total sevré, pcs',

	//Weekdays
	Monday: 'Lundi',
	Tuesday: 'Mardi',
	Wednesday: 'Mercredi',
	Thursday: 'Jeudi',
	Friday: 'Vendredi',
	Saturday: 'Samedi',
	Sunday: 'Dimanche',

	//Feeding stock
	FeedingAdjustment: "Adaptation de l'alimentation",
	FeedingUsage: 'Utilisation de l’alimentation',
	FeedingStock: 'Stock d’alimentation',
	FeedingLog: "Journal d'alimentation",
	CreateFood: 'Créer de la nourriture',
	EditFood: "Modifier l'achat de nourriture",
	EditFoodUsage: 'Rédiger l’utilisation des aliments',
	CreateUsage: 'Créer un usage',
	CreatePurchase: 'Achat d’alimentation',
	FeedingType: "Type d'alimentation",
	FeedingSubject: "Sujet d'alimentation",
	FeedingSubjectName: 'Nom du sujet Alimentation',
	AmountInKgs: 'Montant en kgs',
	FEsoPerKg: 'FE truie/kg',
	FEsvPerKg: 'FE sv/kg',
	purchase: 'Achat',
	RawProtein: 'Protéine brute % du produit',
	FosforGPerKg: 'Phosphore g/kg',
	PricePer100Kgs: 'Prix par 100 kg',
	PeriodFrom: 'Période du',
	PeriodTo: 'Période au',
	UsageKg: 'Usage kg',
	UsageFE: 'Usage FE',
	UsageFEso: 'Usage FEsow',
	UsageFEsv: 'Usage FEsv',
	UsageMoney: "Utilisation de l'argent",
	feedingLog: "Journal d'alimentation",
	FEso: 'FEso',
	FEsv: 'FEsv',
	KG: 'KG',
	//Drug Stock
	DrugStock: 'Stock de médicaments/vaccins',
	adjustmentType: "Type d'ajustement",
	registeredBy: 'Enregistré par',
	medicineLog: 'Journal des médicaments',
	AddToDrugStock: 'Ajouter',
	lastRegulation: 'Dernier réglage',
	Waste: 'Déchets',
	Usage: 'Utilisation',
	UpdateInventory: 'Inventaire',
	AddDrug: 'Ajouter un médicament au stock',
	UpdateDrugStockAmount: 'Mise à jour du montant du stock',
	currentDrugStockAmount: 'Inventaire actuel',
	newDrugStockAmount: 'Nouveau montant du stock',
	createMedication: 'Créer des médicaments',
	Medicine: 'Médecine',
	Vaccine: 'Vaccin',

	//Sow Card
	SOWCARD: 'CARTE DE LA TRUIE',
	location: 'Lieu',
	IDnr: 'IDnum.',
	comment: 'Commentaire',
	move: 'Déplacer',
	wasteDays: 'Jours inutiles',
	wasteDaysShort: 'Déchets',
	weaningShort: 'Sevrage',
	pigYearSo: 'Porcs/année truie',
	pregnantDaysShort: 'Enceinte',
	gradeShort: 'K',
	abortionSowCard: 'A',
	nursingDaysShort: 'Allaitement',
	aliveShort: 'Vivant',
	kilo: 'Kg',
	pregDays: 'Jours de gestation',
	nursDays: 'Jours d’allaitement',
	stillBorn: 'Mort-né',

	//Location types
	Boars: 'Verrats',
	Relief: 'Décharge',
	Holistic: 'Holistique',
	Weaners: 'Sevrés',
	ClimatePigs: 'Climatepigs',
	Finisher: 'Finisseur',
	locationType: 'Type',
	Silo: 'Silo',
	PostponeSows: 'Reporter des truies',
	//Animal types
	FRATS: 'FRATS',
	FeedMill: "Usine d'aliments",
	Weaner: 'Sevré',
	animalType: "Type d'animal",

	//Production
	production: 'Production',
	PigProduction: 'Production porcine',
	GrainMill: 'Usine à grains',
	Feed: 'Alimentation',
	Mills: 'Usines',

	//ValidationSetup
	lowerLimit: 'Limite inférieure',
	upperLimit: 'Limite supérieure',
	plan: 'Plan',
	Sell: 'Vendre',
	ToBeMoved: 'À déplacer',
	validation: 'Validation',
	weaningAnimals: 'Animaux en sevrage',
	usingQRForSows: 'Utilisation des codes QR pour les truies',
	usingQRForLocations: 'Utilisation des codes QR pour les lieux',
	MatingAge: "Âge à l'accouplement pour un jeune animal",
	MatingToFarrowing: "De l'accouplement à la mise bas",
	FarrowingToWeaning: 'De la mise bas au sevrage',
	WeaningToMating: 'Du sevrage au premier accouplement',
	FarrowingToAverted: 'De la mise bas au sevrage',
	AvertedToMating: 'Du sevrage au premier accouplement',
	systemMinimum: 'Système minimum',
	systemMaximum: 'Système maximum',
	deviationMinimum: 'Plan de déviation - minimum',
	deviationMaximim: 'Plan de déviation - maximum',

	// Component input
	Component: 'Composant',
	Destination: 'Destination',
	NewDeliveryIn: 'Nouvelle livraison en',
	NewDeliveryInBulk: 'Nouvelle livraison en vrac en',
	NewDeliveryInBag: 'Nouvelle livraison de sacs en',
	NoComponentsReceived: 'Pas de composants disponibles',
	ChooseComponent: 'Choisir le composant',
	ChooseSupplier: 'Choisir un fournisseur',
	ChooseTruck: 'Choisir un camion',
	ChooseSourceSilo: 'Choisir le silo source',
	ChooseDestinationSilo: 'Choisir le silo de destination',
	ChooseWarehouse: 'Choisir un entrepôt',
	Weighing: 'Pesée',
	FirstWeighing: 'Première pesée',
	FirstWeighingShort: '1ère pesée',
	SecondWeighing: 'Deuxième pesée',
	SecondWeighingShort: '2ème pesée',
	TotalReceived: 'Total reçu',
	StartTransport: 'Démarrer le transport',
	DeliveryInCreating: 'La commande est en cours de création dans FlexMix Pro',
	DeliveryInUpdating: "La commande est en cours d'actualisation dans FlexMix Pro",
	DeliveryInCreatingSuccess: 'La commande a été créée avec succès',
	DeliveryInUpdatingSuccess: 'La commande a été mise à jour avec succès',
	DeliveryInCreatingSuccessFmpOnly:
		'La commande a été créée dans FlexMix Pro, mais il y a eu un problème de sauvegarde dans Skiold Digital',
	DeliveryInUpdatingSuccessFmpOnly:
		'La commande a été mise à jour dans FlexMix Pro, mais il y a eu un problème de sauvegarde dans Skiold Digital',
	DeliveryInSavingFailed: 'Un problème est survenu lors de l’envoi de la commande à FlexMix Pro',
	DeliveryInSavingOrderNumber: 'Le numéro de commande est le suivant',
	SaveAndSendOrder: 'Sauvegarder et envoyer la commande',
	CancelDelivery: "Confirmation de l'annulation de la livraison",
	TotalWeight: 'Poids total',
	Order: 'Commander',
	ActiveOrders: 'Commandes actives',
	OrderNumber: 'Numéro de commande',
	FilterByOrderNumberOrComponent: 'Filtrer par numéro de commande ou par composant',

	//Work lists
	workLists: 'Listes de travail',
	WorkLists: 'Listes de travail',
	saveWorklist: 'Effectuer des actions sélectionnées sur la liste de travail avant de quitter la page ?',
	ChooseWorkLists: 'Choisir une liste de travail',
	Synchronize: 'Synchroniser',
	Setup: 'Configuration',
	pregnantFromDate: 'Enceinte de',
	pregnantToDate: 'Enceinte à',
	chooseAnimalToList: 'Choisir les animaux pour la liste',
	MoveTo: 'Déplacer à',
	MoveToPregnancyLocation: 'Déplacer au lieu de gestation',
	MoveToFarrowingLocation: 'Déplacer au lieu de mise-bas',
	MoveToMatingLocation: 'Déplacer au lieu d’accouplement',
	FarrowingListSetting: 'Liste de mise bas',
	WeaningListSetting: 'Liste de sevrage',
	MatingListSetting: "Liste d'accouplement",
	ScanningListSetting: 'Liste d’analyse 1',
	ScanningListSetting2: 'Liste d’analyse 2',
	LardScanningEventListSetting: 'Liste d’analyse de la graisse 1',
	LardScanningEventListSetting2: 'Liste d’analyse de la graisse 2',
	listAvailableOn: 'Liste disponible sur',
	columnTextShownAs: 'Texte de la colonne/affiché comme',
	daysAfterMating: 'Jours après l’accouplement',
	PregnacyDays: 'Jours de gestation',
	PregnancyDays: 'Jours de gestation',
	MatingBatch: "Lot d'accouplement",
	Web: 'Web',
	App: 'App',
	SetupActivityList: "Mise en place Liste d'activités",
	ActivityList: 'Liste d’activité',
	activityLists: 'Listes d’activité',
	expectedFarrowing: 'Mise-bas estimée',
	completed: 'Terminé',
	VaccineListSetting: 'Vaccination',
	VaccineListSetting1: 'Vaccination 1',
	VaccineListSetting2: 'Vaccination 2',
	VaccineListSetting3: 'Vaccination 3',
	TasksInFarrowingStableSetting: 'Tâches dans un enclos de mise bas',
	TasksInFarrowingStableSetting2: 'Tâches dans un enclos de mise bas 2',
	boarPerLitterPcs: '% de verrats dans la portée',
	treatmentVaccination: 'Traitement/vaccination',
	toBetreated: 'À traiter',
	allPigs: 'Tous les porcs',
	boarsPigs: 'Verrats',
	daysAfterEvent: 'Jours après l’événement',
	vaccineByLitterNumber: "Vaccination jusqu'au numéro de portée",
	showLocationOnList: "Afficher l'emplacement sur la liste",
	calcByVaccinationPregnancyType: 'La vaccination est calculée par',
	showAnimalsOnList: 'Afficher les animaux sur la liste (jours du cycle)',
	expectedFarrowingLong: 'Mise bas prévue',
	dateForWeaning: 'Date de sevrage',
	dateForMating: "Date d'accouplement",
	Blank: 'Vide',
	chooseFeedCurve: "Choisir une courbe d'alimentation pour",
	chooseReader: 'Choisir un lecteur',
	feedCurve: "Courbe d'alimentation",
	MoveFromPregToPregSetting: 'Déplacer entre les lieux de gestation',
	MoveMatingToMatingSetting: "Déplacer entre les lieux d'accouplement",

	//Animal Report
	MatingBatchReportSetting: "Lot d'accouplement",
	ActiveAnimalsReportSetting: 'Animaux actifs',
	reports: 'Rapports',
	report: 'Rapport',
	reportAvailableOn: 'Rapport disponible sur',
	AnimalNumberOnly: "Nb. d'animaux seulement",
	AnimalNumberPerLocation: 'Animaux par lieu',
	howToPresentAnimals: 'Comment présenter les animaux',

	// Process equipment
	FailedToFetchNewestLocations:
		"Impossible d'obtenir les emplacements les plus récents à partir des équipements de traitement",
	removeProcessEquipment: 'Supprimer l’équipement de traitement ?',
	setPensAutomaticValves: 'Mettre Enclos sur Valves',
	setPensAutomaticStations: 'Mettre Enclos sur Stations',
	processEquipment: 'Équipement de traitement',
	penNr: 'N° d’enclos',
	Valves: 'Valves',
	StationNumbers: 'N° de stations',
	locations: 'LIEUX',
	relationProcessEquipment:
		'CRÉER DES RELATIONS ENTRE LES LIEUX EN MATIÈRE D’ÉQUIPEMENT DE TRAITEMENT ET SKIOLD digital',
	Units: 'Unités',
	locationsSkioldDigital: 'LIEUX SKIOLD digital',
	locationsProcessEquipment: 'UNITÉS D’ÉQUIPEMENT DE TRAITEMENT',
	createProcessEquipment: 'Créer un équipement de traitement',
	chooseProcessEquipment: 'Choisir un équipement de traitement',
	chooseUnits: 'Choisir des unités',
	relationLocation1: 'Créer une relation',
	relationLocation2: 'Équipement de traitement/lieu',
	bindProcessEquipment: 'UNITÉ DE LIAISON DES ÉQUIPEMENTS DE TRAITEMENT AUX LIEUX DANS SKIOLD digital',
	unsavedRegisteredData: 'La donnée n’est pas enregistrée. Sauvegarder les changements ?',
	processCode: 'Code',

	// All animals esf
	AllAnimalsEsfListSetting: 'Tous les animaux ESF',
	AWR250StickReader: 'Lecteur de clé AWR 250',
	AWR300StickReader: 'Lecteur de clé AWR 300',
	Reader: 'Lecteur',
	cycleDaysShownNegative: 'Jours de cycle négatifs',
	eatenToday: "Ont mangé aujourd'hui",
	curve: 'Courbe',
	Stations: 'Stations',

	// All animals food esf
	Yesterday: 'Hier',
	DateAndTime: 'Date, heure',
	FE: 'FE',
	ShortageEsfListSetting: 'Liste des pénuries',
	LastFourVisits: 'DERNIÈRES QUATRE VISITES',
	Visit0: 'Dernière visite',
	Visit1: '2e visite',
	Visit2: '3e visite',
	Visit3: '4e visite',
	Rest: 'Repos',

	//Agrident bluetooth
	connectToUnit: 'Connecter à l’unité bluetooth',
	disonnect: 'Déconnecter',
	startScan: 'Démarrer l’analyse',
	bleSelect: 'Sélectionner',
	bleCancel: 'Annuler',
	bleOk: 'Ok',
	bleSave: 'Sauvegarder',
	chooseCurve: 'Choisir la courbe',
	enterFixed: 'Entrer le % fixe',
	Eat: 'Manger',
	animalsOnListWhileDaysRationNotUnfed:
		"Animaux figurant sur la liste lorsque, % de la ration journalière n'est pas vide",
	TodayFE: "Aujourd'hui FE",
	model: 'Modèle',
	ID: 'Identification',
	bluetoothIsDisabled: 'Le Bluetooth est désactivé',
	YesterdayFE: 'Hier FE',
	Station: 'Station',
	restTodayFE: 'Se sont reposés aujourd’hui',
	eatenTodayFE: "Ont mangé aujourd'hui",
	curveFE: 'Courbe',
	restYesterdayFE: 'Repos hier',
	eatenYesterdayFE: 'Mangé hier',
	searchBtUnits: 'Chercher des dispositifs',

	// P-report
	CHOOSEREPORT: 'CHOISIR UN RAPPORT',
	pReport: 'Rapport P',
	period: 'Période',
	newPeriod: 'Nouvelle période',
	specialPeriod: 'Période spéciale',
	ReMatingPercent: 'Réaccouplement, %',
	ThereOfGiltsPcs: '\u2022 Dont cochettes, pcs',
	AlivePerLitterPcs: 'Vivants par portée, pcs',
	DeadPerLitter: 'Morts par portée, pcs',
	FarrowingPercent: 'Pourcentage de mise bas',
	AvgPregnantDaysPerLitter: 'Nombre moyen de jours de gestation par portée',
	ThereOfFirstLitterPcs: '\u2022 Dont première portée, pcs',
	FirstLitterPercent: '\u2022 Première portée, %',
	NursingSowPercent: '\u2022 Pourcentage Truies allaitantes',
	DeadTillWeaningPercent: 'Mort avant le sevrage, %',
	KeyNumbers: 'Chiffres clés',
	clearPeriods: 'Supprimer les périodes',

	// Production scale
	YearSowPcs: 'Truie par an',
	WeanedPigletsPcs: 'Porcs sevrés',
	AvgWeightPerPiglet: 'Poids moyen au sevrage',
	PercentDeadSowGilts: 'Pourcentage de décès de la truie exposée',
	PeriodFeedDaysSowAndGilt: "Jours d'alimentation truie/cochette",
	PeriodFeedDaysYoungAnimal: "Jours d'alimentation jeune femelle",
	ProductionDays: 'Jours productifs',
	NonProductiveDays: 'Jours non productifs',
	CorrectedDays: 'Jours corrigés',
	PeriodYearSowPcs: 'Année truie la période',
	ProductionScale: 'Échelle de production',

	// Feed
	SowFoodFeYearSow: 'Alimentation des truies FE/an truie',
	ThereOfYoungAnimalFood: '\u2022 Dont aliments pour jeunes femelles, FE/année truie',
	FePerYearSowPerDay: 'FE par an truie par jour',
	// Reproduction
	WasteDaysPerDay: 'Jours non productifs par portée',
	ThereOfAdjustedDays: '\u2022 Dont jours corrigés',
	LitterPerYearSowPcs: 'Portée par année truie',
	WeanedPigletsPerYearSowPcs: 'Porcs sevrés par année truie',
	ProducedLitters: 'Portée produite',
	Reproduction: 'Reproduction',

	// Status
	SowAndGiltPcs: 'Truies/cochettes, pcs',
	SowAndGiltEntrance: '\u2022 Entrée, pcs',
	SowAndGiltDepartured: '\u2022 Départ, pcs',
	SowAndGiltFeedDaysPcs: "Jours d'alimentation truies/cochettes, pcs",
	SowAndGiltPregnantDays: 'Jours de gestation truies/cochettes, pcs',
	YoungFemalePcs: 'Jeunes femelles, pcs',
	YoungFemaleEntrance: '\u2022 Entrée, pcs',
	YoungFemaleDepartured: '\u2022 Départ, pcs',
	YoungFemaleFeedDaysPcs: "Jours d'alimentation jeunes femelles, pcs",
	BoarPcs: 'Verrats, pcs',
	BoarEntrance: '\u2022 Entrée, pcs',
	BoarDepartured: '\u2022 Départ, pcs',
	PigletPcs: 'Porcelets, pcs',

	// Feed usage
	FeedUsage: 'Utilisation de l’alimentation',
	kgTotal: 'Total kg',
	feTotal: 'Total FE',
	fePercent: 'FE %',
	kgPercent: 'KG %',
	sowFoodTotal: "Total de l'alimentation pour truies",
	youngFemaleFoodTotal: "Total de l'alimentation des jeunes femelles",
	pigletFoodTotal: "Total de l'alimentation pour porcelets",
	allInAllFood: 'Utilisation de toute l’alimentation',

	// Sub results
	SubResults: 'Sous-résultats',

	// Dead piglets event
	deadPigletsEvent: 'Porcelets morts',

	Updated: 'Actualisé',
};
