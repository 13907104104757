import React from 'react';
import { Option } from 'react-dropdown';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AllAnimalsEsfListSetting, ESFReader, IAllAnimalsEsfListSetting, WorkListType } from 'shared/api/api';
import { deepCopy } from 'shared/helpers/general-helpers';
import { SaveWorkListSetting } from 'shared/state/ducks/work-list-settings/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { SkioldButton } from '../../skiold-components/skiold-button/skiold-button';
import { SkioldCheckbox } from '../../skiold-components/skiold-checkbox/skiold-checkbox';
import { SkioldFormsWrapper } from '../../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from '../../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import '../list-setup.scss';
import { getAvailablityRow, getSkioldInputRow } from '../work-list-helper';

interface PropsFromParent {
	workListType: WorkListType;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		listSetting: state.workListSettings.entities.find(
			wl => wl.type === WorkListType.AllAnimalsEsfListSetting,
		) as IAllAnimalsEsfListSetting,
		siteId: state.profile.active ? state.profile.active.siteId : undefined,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	saveWorkListSetting: (listSetting: IAllAnimalsEsfListSetting) => SaveWorkListSetting(listSetting)(dispatch),
});

export interface State {
	listSetting: IAllAnimalsEsfListSetting;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

class AllAnimalsEsfListSetup extends React.PureComponent<Props, State> {
	public static getDerivedStateFromProps(nextProps: Props, prevState: State): Partial<State> {
		if (!prevState.listSetting.id && nextProps.listSetting) {
			return {
				listSetting: { ...prevState.listSetting, ...nextProps.listSetting },
			};
		}
		return {};
	}
	constructor(props: Props) {
		super(props);
		const listSetting = AllAnimalsEsfListSetting.fromJS({ type: WorkListType.AllAnimalsEsfListSetting });
		this.state = {
			listSetting: listSetting,
		};
	}

	public render() {
		return (
			<ViewWeb className="list-setup">
				<ViewWeb className="view-container">
					<TextWeb className="text-style">{localized('Setup')}</TextWeb>
					<SkioldFormsWrapper formRows={this.getFormRows()} containerClassName="wrapper-container" />
					{this.renderButtons()}
				</ViewWeb>
			</ViewWeb>
		);
	}

	private getFormRows() {
		let formRows = new Array<FormRow>();
		formRows.push(this.getHeader());
		formRows.push(getSkioldInputRow(this.listSetupChanged, this.state.listSetting, 'name', localized('name')));
		formRows.push(this.getNegativeCycleDaysRow());
		formRows.push(
			getAvailablityRow(
				this.toggleShowOnWeb,
				this.toggleShowOnApp,
				this.state.listSetting.showOnWeb!,
				this.state.listSetting.showOnApp!,
			),
		);

		return formRows;
	}

	private save = () => {
		if (!this.validate(this.state.listSetting)) {
			return;
		}

		let farrowingListSetup = deepCopy(this.state.listSetting);
		farrowingListSetup.siteId = this.props.siteId;

		this.props.saveWorkListSetting(farrowingListSetup);
	};

	private validate(listSetting: IAllAnimalsEsfListSetting) {
		return true;
	}

	private getHeader(): FormRow {
		return {
			header: localized(this.props.workListType),
		};
	}

	private getNegativeCycleDaysRow(): FormRow {
		return {
			name: localized('cycleDaysShownNegative'),
			component: (
				<ViewWeb className="two-checkbox-container">
					<SkioldCheckbox
						onClick={this.SetNegativeCycleDaysToTrue}
						isChecked={this.state.listSetting.negativeCycleDays}
					/>
					<TextWeb>{localized('True')}</TextWeb>
					<SkioldCheckbox
						onClick={this.SetNegativeCycleDaysToFalse}
						isChecked={!this.state.listSetting.negativeCycleDays}
					/>
					<TextWeb>{localized('False')}</TextWeb>
				</ViewWeb>
			),
		};
	}

	private toggleShowOnWeb = () => {
		this.setState(prevState => ({
			listSetting: {
				...prevState.listSetting,
				showOnWeb: !this.state.listSetting.showOnWeb,
			},
		}));
	};

	private toggleShowOnApp = () => {
		this.setState(prevState => ({
			listSetting: {
				...prevState.listSetting,
				showOnApp: !this.state.listSetting.showOnApp,
			},
		}));
	};

	private listSetupChanged = (setup: IAllAnimalsEsfListSetting) => {
		this.setState({
			listSetting: setup,
		});
	};

	private onReaderChanged = (option: Option) => {
		this.setState(prevState => ({
			listSetting: {
				...prevState.listSetting,
				reader: option.value !== ' ' ? (option.value as ESFReader) : undefined,
			},
			selectedOption: option,
		}));
	};

	public SetNegativeCycleDaysToFalse = () => {
		this.setState(prevState => ({
			listSetting: {
				...prevState.listSetting,
				negativeCycleDays: false,
			},
		}));
	};

	public SetNegativeCycleDaysToTrue = () => {
		this.setState(prevState => ({
			listSetting: {
				...prevState.listSetting,
				negativeCycleDays: true,
			},
		}));
	};

	private renderButtons() {
		return (
			<ViewWeb className="view-button-container">
				<SkioldButton title={localized('Save')} onPress={this.save} />
			</ViewWeb>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AllAnimalsEsfListSetup);
