import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import * as Action from './actions';
import { StoreContainer } from 'shared/state/store-container';
import { Dispatch } from 'redux';
import ObjectID from 'bson-objectid';
import { IPoolYoungFemale, PoolYoungFemale } from 'shared/api/api';
export function GetSyncData() {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;
	const lastSyncDate = state.poolYoungFemales.lastSyncDate;

	if (state.poolYoungFemales.syncInProgress) {
		return (dispatch: Dispatch<any>) => {
			return Promise.resolve();
		};
	}
	return AsyncOperationBuilder2(
		Action.getSyncPoolYoungFemale,
		client => client.poolYoungFemale_Sync(siteId, lastSyncDate),
		{
			siteId,
			lastSyncDate,
		},
	);
}
export function UpsertPoolYoungFemale(poolYoungFemaleEvent: IPoolYoungFemale) {
	const state = StoreContainer.getState();
	const prevEntity = state.poolYoungFemales.entities.find(
		poolYoungFemale => poolYoungFemale.id === poolYoungFemaleEvent.id,
	);

	if (!poolYoungFemaleEvent.id) {
		poolYoungFemaleEvent.id = new ObjectID().toHexString();
	}

	return AsyncOperationBuilder2(
		Action.upsertPoolYoungFemale,
		client => client.poolYoungFemale_Upsert(PoolYoungFemale.fromJS(poolYoungFemaleEvent)),
		PoolYoungFemale.fromJS(poolYoungFemaleEvent),
		prevEntity,
	);
}

export function DeletePoolYoungFemale(poolYoungFemaleEvent: IPoolYoungFemale) {
	poolYoungFemaleEvent.isDeleted = true;
	return UpsertPoolYoungFemale(poolYoungFemaleEvent);
}

export function SaveSyncData() {
	const state = StoreContainer.getState();
	const updates = state.poolYoungFemales.updates;
	let promises = new Array<Promise<void>>();
	return (dispatch: Dispatch<any>) => {
		if (state.poolYoungFemales.saveSyncInProgress) {
			return Promise.resolve();
		}

		updates.forEach(update => {
			promises.push(UpsertPoolYoungFemale(update)(dispatch));
		});

		return Promise.all(promises);
	};
}

export function UpsertManyPoolYoungFemale(poolYoungFemaleEvents: IPoolYoungFemale[]) {
	const state = StoreContainer.getState();

	const ids = poolYoungFemaleEvents.map(pyf => pyf.id);
	const prevEntity = state.poolYoungFemales.entities.filter(poolYoungFemale => ids.includes(poolYoungFemale.id));

	return AsyncOperationBuilder2(
		Action.upsertManyPoolYoungFemale,
		client => client.poolYoungFemale_UpsertMany(poolYoungFemaleEvents as PoolYoungFemale[]),
		poolYoungFemaleEvents as PoolYoungFemale[],
		prevEntity,
	);
}

export function DeleteManyPoolYoungFemale(poolYoungFemaleEvents: IPoolYoungFemale[]) {
	const state = StoreContainer.getState();

	const ids = poolYoungFemaleEvents.map(pyf => pyf.id);
	const prevEntities = state.poolYoungFemales.entities.filter(poolYoungFemale => ids.includes(poolYoungFemale.id));
	const pyfToDelete = prevEntities.map(pyf => {
		return { ...pyf, isDeleted: true } as PoolYoungFemale;
	});
	return AsyncOperationBuilder2(
		Action.deleteManyPoolYoungFemale,
		client => client.poolYoungFemale_UpsertMany(pyfToDelete as PoolYoungFemale[]),
		pyfToDelete as PoolYoungFemale[],
		prevEntities,
	);
}
