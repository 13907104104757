import ObjectID from 'bson-objectid';
import React from 'react';
import { Option } from 'react-dropdown';
import { connect } from 'react-redux';
import {
	AnimalType,
	GrowthPigEventType,
	GrowthPigMoveEventDto,
	GrowthPigsEvent,
	GrowthPigTuneProduction,
	IGrowthPigMoveEventDto,
	IGrowthPigTuneProduction,
} from 'shared/api/api';
import {
	getCorrectProductionTypeBySectionId,
	getGrowthPigDistributeMoveEventDefaultState,
	HandleFromAndToDataChangedMoveEvent,
	HandleWeightMoved,
	validateDistributeGrowthPigs,
	ValidateGpeCapacity,
} from 'shared/helpers/growth-pigs-helper/growth-pig-event-function-helper';
import {
	DistributeTableDataTypes,
	GrowthPigDistributeMoveEventProps,
	GrowthPigDistributeState,
	GrowthPigEventsMapDispatchToProps,
	GrowthPigEventsMapStateToProps,
	MoveAllTreatedEnum,
} from 'shared/helpers/growth-pigs-helper/growth-pig-event-helper';
import { getSectionBySectionId } from 'shared/helpers/location-helper';
import { Monitoring } from 'shared/helpers/monitoring-service';
import { setGpeBundleIdentifierArray } from 'shared/state/ducks/growth-pig-events/operations';
import { localized } from 'shared/state/i18n/i18n';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { showAlert, ShowConfirmAlert } from '../../skiold-alert/skiold-alert';
import { Heading } from '../../utils/heading';
import GrowthPigDistributeForm from './growth-pig-distribute-form';
import { GrowthPigDistributeTable } from './growth-pig-distribute-table';
import './growth-pig-distribute.scss';

export class GrowthPigDistributeMoveEvent extends React.PureComponent<
	GrowthPigDistributeMoveEventProps,
	GrowthPigDistributeState
> {
	constructor(props: GrowthPigDistributeMoveEventProps) {
		super(props);

		this.state = getGrowthPigDistributeMoveEventDefaultState(props);
	}

	public render() {
		return (
			<ViewWeb className="growth-pig-distribute">
				<Heading text={localized('DistributePigsInSection')}></Heading>

				<ViewWeb className="distribute-container">
					<ViewWeb className="flex-row-space-between-100p">
						<GrowthPigDistributeForm
							OnFromPenChanged={this.OnFromPenChanged}
							OnToPenChanged={this.OnToPenChanged}
							fromPenId={this.state.fromPenId}
							toSectionId={this.props.toSectionId}
							toPenId={this.state.toPenId}
							disablePenSelect={this.state.disablePenColumn}
							amountPigsNotDistributed={this.state.amountPigsNotDistributed}
							totalWeightNotDistributed={this.state.totalWeightNotDistributed}
							blacklistedPenIds={this.state.blacklistedPenIds}
							feedCurveChanged={this.feedCurveChanged}
							recipeChanged={this.recipeChanged}
							productionType={this.props.toProductionType}
						/>
						{this.renderButtons()}
					</ViewWeb>
					<GrowthPigDistributeTable
						data={this.state.data}
						disablePenColumn={this.state.disablePenColumn}
						onAmounthanged={this.onAmounthanged}
						onWeightChanged={this.onWeightChanged}
						onTotalWeightChanged={this.onTotalWeightChanged}
						latestRetentionDate={this.state.latestRetentionDate}
						onLatestRetentionDateChange={this.onLatestRetentionDateChange}
						handleRetentionTime={this.props.handleRetentionTime}
					/>
				</ViewWeb>
			</ViewWeb>
		);
	}
	private feedCurveChanged = (feedCurve: number | undefined) => {
		this.setState({ feedCurve });
	};

	private recipeChanged = (recipeNumber: number | undefined) => {
		this.setState({ recipeNumber });
	};

	private onAmounthanged = (numb: number | undefined, item: IGrowthPigMoveEventDto) => {
		let dataArrayCopy = [...this.state.data];
		const itemToModifyIndex = dataArrayCopy.findIndex(dac => dac.toPenId === item.toPenId);
		dataArrayCopy[itemToModifyIndex] = item;
		const totalWeightNotDistributed = dataArrayCopy.reduce((a: number, b: DistributeTableDataTypes) => {
			return a + (b.amount ?? 0) * (b.avgWeight ?? 0);
		}, 0);
		const totalAmount = dataArrayCopy.reduce((a: number, b: DistributeTableDataTypes) => {
			return a + (b.amount ?? 0);
		}, 0);

		this.setState({
			data: dataArrayCopy,
			amountPigsNotDistributed: (this.state.amount ?? 0) - totalAmount,
			totalWeightNotDistributed: (this.state.totalWeight ?? 0) - totalWeightNotDistributed,
		});
	};

	private onWeightChanged = (numb: number | undefined, item: IGrowthPigMoveEventDto) => {
		let dataArrayCopy = [...this.state.data];
		// let currentTotalWeightNotDistributed = HandleGrowthPigDistributeTotalWeightNotDistributed(
		// 	dataArrayCopy,
		// 	item,
		// 	numb,
		// 	this.state.totalWeightNotDistributed,
		// );

		// this.setState({ data: dataArrayCopy, totalWeightNotDistributed: currentTotalWeightNotDistributed });
		const itemToModifyIndex = dataArrayCopy.findIndex(dac => dac.toPenId === item.toPenId);
		dataArrayCopy[itemToModifyIndex] = item;

		const totalWeightNotDistributed = dataArrayCopy.reduce((a: number, b: DistributeTableDataTypes) => {
			return a + (b.amount ?? 0) * (b.avgWeight ?? 0);
		}, 0);
		this.setState({
			data: dataArrayCopy,
			totalWeightNotDistributed: (this.state.totalWeight ?? 0) - totalWeightNotDistributed,
		});
	};

	private onTotalWeightChanged = (numb: number | undefined, item: IGrowthPigMoveEventDto) => {
		let dataArrayCopy = [...this.state.data];
		const itemToModifyIndex = dataArrayCopy.findIndex(dac => dac.toPenId === item.toPenId);
		dataArrayCopy[itemToModifyIndex] = item;

		const totalWeightNotDistributed = dataArrayCopy.reduce((a: number, b: DistributeTableDataTypes) => {
			return a + (b.amount ?? 0) * (b.avgWeight ?? 0);
		}, 0);
		this.setState({
			data: dataArrayCopy,
			totalWeightNotDistributed: (this.state.totalWeight ?? 0) - totalWeightNotDistributed,
		});
	};

	private onLatestRetentionDateChange = (date: Date | undefined, item: IGrowthPigMoveEventDto) => {
		let dataArrayCopy = [...this.state.data];
		const itemToModifyIndex = dataArrayCopy.findIndex(dac => dac.toPenId === item.toPenId);
		dataArrayCopy[itemToModifyIndex] = { ...item, retentionDate: date };

		this.setState({ data: dataArrayCopy });
	};

	private readonly OnFromPenChanged = (newValue?: Option) => {
		if (newValue) {
			const data = HandleFromAndToDataChangedMoveEvent(this.props, this.state, newValue, this.state.toPenId);
			this.setState({ fromPenId: newValue, data });
		}
	};

	private readonly OnToPenChanged = (newValue?: Option) => {
		if (newValue) {
			const data = HandleFromAndToDataChangedMoveEvent(this.props, this.state, this.state.fromPenId, newValue);
			this.setState({ toPenId: newValue, data });
		}
	};

	private renderButtons() {
		return (
			<ViewWeb className="button-view-style">
				<SkioldButton theme="grey" title={localized('Close')} onPress={this.props.closeModal} />
				<SkioldButton title={localized('Save')} onPress={this.save} />
			</ViewWeb>
		);
	}

	private save = async () => {
		if (
			validateDistributeGrowthPigs(
				this.state.totalWeightNotDistributed,
				this.state.amountPigsNotDistributed,
				showAlert,
			) &&
			this.props.profile
		) {
			const distributed = this.state.data.keyValuePairSingle2('toPenId', 'amount');
			const notifyCapacity =
				this.props.toProductionType === AnimalType.Finisher
					? this.props.generalSettings.notifyCapacityFinisher
					: this.props.generalSettings.notifyCapacityWeaner;

			if (
				!(await ValidateGpeCapacity(
					this.props.toSectionId,
					notifyCapacity,
					this.props.date,
					distributed,
					ShowConfirmAlert,
				))
			) {
				return;
			}

			let dataWithBundleId = setGpeBundleIdentifierArray(this.props.data);
			const itemsToSave: IGrowthPigMoveEventDto[] = HandleWeightMoved(
				this.state.data as IGrowthPigMoveEventDto[],
				dataWithBundleId,
				this.props.date,
				this.props.profile.siteId,
				this.props.fromProductionType,
				this.props.toProductionType,
				getSectionBySectionId(this.props.toSectionId, this.props.hashmapLocations),
				undefined,
				this.state.feedCurve,
				this.state.recipeNumber,
			);
			if (itemsToSave.length > 0) {
				this.props.upsertGrowthPigsMoveEvents(itemsToSave as GrowthPigMoveEventDto[]);
			}
			if (this.props.toSectionId !== this.props.fromSectionId) {
				let regulationsToSave: GrowthPigsEvent[] = [];
				let tuneGpeToSave: GrowthPigTuneProduction[] = [];

				// TODO: Temp disable to see if this solves regulation problem
				// If a pen with negative amount move. Create regluation growth pig event on used pens
				// if (this.props.data.find(x => x.departedFromSection && x.departedFromSection < 0)) {
				// 	this.props.data.forEach(penToRegulate => {
				// 		const testTotalWeight = penToRegulate.rest
				// 			? getTotalWeightByPenId(
				// 					penToRegulate.sectionId,
				// 					penToRegulate.penId,
				// 					new Date(),
				// 					penToRegulate.rest && penToRegulate.departedFromSection
				// 						? penToRegulate.departedFromSection
				// 						: 0,
				// 			  )
				// 			: 0;

				// 		// Because of a misunderstanding, we get correct production by sectionId
				// 		const correctToProduction = getCorrectProductionTypeBySectionId(penToRegulate.sectionId);
				// 		const item = GrowthPigsEvent.fromJS({});
				// 		item.id = new ObjectID().toHexString();
				// 		item.amount = penToRegulate.rest ? penToRegulate.rest : 0;
				// 		item.siteId = this.props.profile ? this.props.profile.siteId : '';
				// 		item.date = this.props.date;
				// 		item.oldTotalWeight = 0;
				// 		item.toProductionType = this.props.fromProductionType;
				// 		item.toBuildingId = penToRegulate.buildingId;
				// 		item.toPenId = penToRegulate.penId;
				// 		item.toSectionId = penToRegulate.sectionId;
				// 		item.correctToProductionType = correctToProduction;
				// 		item.totalWeight = testTotalWeight;
				// 		item.growthPigEventType = GrowthPigEventType.Regulation;

				// 		regulationsToSave.push(item);
				// 	});
				// }
				// Calculate if Section is emptied
				const totalPigsLeftInSection = dataWithBundleId.reduce(
					(acc, item) => acc + (item.rest ? item.rest : 0),
					0,
				);
				dataWithBundleId.forEach(penToRegulate => {
					// Create a regulation, if current amount on pen or 0 is negative
					if (totalPigsLeftInSection <= 0) {
						Monitoring.logTrace(`Empty pen for regulation move`, {
							penId: penToRegulate.penId ? penToRegulate.penId : '',
							rest: penToRegulate.rest ? penToRegulate.rest.toString() : '',
							departedFromSection: penToRegulate.departedFromSection
								? penToRegulate.departedFromSection.toString()
								: '',
							timestamp: this.props.date ? this.props.date.toString() : 'no Date',
						});
						const correctToProduction = getCorrectProductionTypeBySectionId(penToRegulate.sectionId);
						const item = GrowthPigsEvent.fromJS({});
						item.id = new ObjectID().toHexString();
						item.amount = 0;
						item.siteId = this.props.profile!.siteId;
						item.date = this.props.date;
						item.oldTotalWeight =
							(item.amount ? item.amount : 0) *
							(penToRegulate.oldAvgWeight ? penToRegulate.oldAvgWeight : 0);
						item.toProductionType = this.props.fromProductionType;
						item.toBuildingId = penToRegulate.buildingId;
						item.toPenId = penToRegulate.penId;
						item.toSectionId = penToRegulate.sectionId;
						item.correctToProductionType = correctToProduction;
						item.totalWeight = 0;
						item.bundleIdentifier = penToRegulate.bundleIdentifier;
						item.growthPigEventType = GrowthPigEventType.Regulation;

						regulationsToSave.push(item);
					}
				});
				regulationsToSave.forEach(gpe => {
					if (gpe.growthPigEventType === GrowthPigEventType.Regulation) {
						this.props.upsertGrowthPigsEvent(gpe);
					}
				});

				// Clear retention time on pen if all treatment animals is moved
				let pensToUpdate: { [key: string]: Date | undefined } = {};
				if (dataWithBundleId && dataWithBundleId.length > 0) {
					dataWithBundleId.forEach(ev => {
						if (ev.penId && ev.moveAllTreated === MoveAllTreatedEnum.All) {
							pensToUpdate[ev.penId] = undefined;
						}

						// If a location is emptied, save tunepigproduction
						if (totalPigsLeftInSection <= 0) {
							const dataToSave = GrowthPigTuneProduction.fromJS({
								id: new ObjectID().toHexString(),
								date: new Date(),
								buildingId: ev.buildingId,
								sectionId: ev.sectionId,
								animalType: this.props.fromProductionType,
								penId: ev.penId,
								amount: 0,
								weight: 0,
								siteId: this.props.profile ? this.props.profile.siteId : '',
							} as IGrowthPigTuneProduction);
							tuneGpeToSave.push(dataToSave);
						}
					});

					if (tuneGpeToSave && tuneGpeToSave.length > 0) {
						this.props.upsertMultipleGrowthPigTuneProduction(tuneGpeToSave);
					}
					this.props.setRetentionTimeOnPens(pensToUpdate, true);
				}
			}
			this.props.closeModal(true);
		}
	};
}

export default connect(GrowthPigEventsMapStateToProps, GrowthPigEventsMapDispatchToProps)(GrowthPigDistributeMoveEvent);
