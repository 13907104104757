import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import * as adjustFeedOperation from 'shared/state/ducks/adjust-feed/operations';
import * as animalReportSettingsOperation from 'shared/state/ducks/animal-report-settings/operations';
import * as dashboardSettingOperation from 'shared/state/ducks/dashboard-settings/operations';
import * as DeadPigletsEventOperations from 'shared/state/ducks/dead-piglets-event/operations';
import * as diagnoseCategoryOperation from 'shared/state/ducks/diagnoseCategories/operations';
import * as diagnoseOperation from 'shared/state/ducks/diagnoses/operations';
import { GetDistriwinFeedingComponentSyncData } from 'shared/state/ducks/distriwin-feed-component/operations';
import * as drugTypeOperation from 'shared/state/ducks/drugTypes/operations';
import { GetEsfDailySyncData, SaveEsfDailyStatusSyncData } from 'shared/state/ducks/esf-daily-status/operations';
import * as EsfStatusOperations from 'shared/state/ducks/esf-status/operations';
import * as GeneralSettingsOperations from 'shared/state/ducks/general-settings/operations';
import * as LardEventOperations from 'shared/state/ducks/lardScanningEvents/operations';
import * as locationOperation from 'shared/state/ducks/locations/operations';
import * as markingOperation from 'shared/state/ducks/markings/operations';
import * as matingBatchSettingOperation from 'shared/state/ducks/mating-batch-setting/operations';
import * as matingBatchOperation from 'shared/state/ducks/mating-batch/operations';
import { LoadProfileModules } from 'shared/state/ducks/licences/operations';
import * as moveEventOperation from 'shared/state/ducks/move-events/operations';
import * as notificationsOperation from 'shared/state/ducks/notifications/operations';
import { SaveTriggerSync, SyncFinished, SyncStarted } from 'shared/state/ducks/offline/operations';
import * as pregnancyOperation from 'shared/state/ducks/pregnancy-events/operations';
import * as processEquipmentDataOperation from 'shared/state/ducks/process-equipment-data/operations';
import * as processEquipmentOperation from 'shared/state/ducks/process-equipments/operations';
import * as productionReportOperation from 'shared/state/ducks/production-report-settings/operations';
import * as profileOperation from 'shared/state/ducks/profile/operations';
import { SiteChange } from 'shared/state/ducks/profile/operations';
import * as reasonOperation from 'shared/state/ducks/reasons/operations';
import { GetSite } from 'shared/state/ducks/site/operations';
import * as StationOperations from 'shared/state/ducks/station/operations';
import * as stemAnimalOperation from 'shared/state/ducks/stem-animals/operations';
import * as treatmentDefinitionOperation from 'shared/state/ducks/treatment-definitions/operations';
import * as treatmentPlanOperation from 'shared/state/ducks/treatment-plan/operations';
import * as treatmentOperation from 'shared/state/ducks/treatment/operations';
import * as UnitsToPenOperations from 'shared/state/ducks/unit-to-pen/operations';
import * as validationOperation from 'shared/state/ducks/validation-setup/operations';
import * as workListSettingsOperation from 'shared/state/ducks/work-list-settings/operations';
import * as workListToTreatmentPlanOperation from 'shared/state/ducks/work-list-to-treatment-plan/operations';
import { WebAppState } from 'web/state/store.web';
import { getSyncBreedTableItems } from 'shared/state/ducks/breed-table-items/operation';
import { isAuthenticated } from 'shared/helpers/authentication-helper.shared';

const mapStateToProps = (state: WebAppState) => {
	return {
		isConnected: state.offline.isConnected,
		stemAnimalsSyncDate: state.stemAnimals.lastSyncDate,
		pregnancyEventSyncDate: state.pregnancyEvents.lastSyncDate,
		matingBatchesSyncDate: state.matingBatches.lastSyncDate,
		matingBatchSettingsSyncDate: state.matingBatchSetting.lastSyncDate,
		treatmentsSyncDate: state.treatments.lastSyncDate,
		reasonsSyncDate: state.reasons.lastSyncDate,
		treatmentPlansSyncDate: state.treatmentPlans.lastSyncDate,
		treatmentDefinitionsSyncDate: state.treatmentDefinitions.lastSyncDate,
		validationSetupSyncDate: state.validationSetup.lastSyncDate,
		markingsSyncDate: state.markings.lastSyncDate,
		diagnoseSyncDate: state.diagnose.lastSyncDate,
		diagnoseCategorySyncDate: state.diagnoseCategory.lastSyncDate,
		drugTypeSyncDate: state.drugType.lastSyncDate,
		locationsSyncDate: state.locations.lastSyncDate,
		moveEventSyncDate: state.moveEvents.lastSyncDate,
		adjustFeedSyncDate: state.adjustFeed.lastSyncDate,
		workListSyncDate: state.workListSettings.lastSyncDate,
		alarmSyncDate: state.notifications.alarms.syncTime,
		warningsSyncDate: state.notifications.warnings.syncTime,
		isAuthenticated: state.authentication.isAuthenticated,
		isTriggered: state.offline.isTriggered,
		isSyncing: state.offline.isSyncing,
		siteId: state.profile.active ? state.profile.active.siteId : '',
		animalReportSettingSyncDate: state.animalReportSettings.lastSyncDate,
		productionReportSyncDate: state.productionReportSetting.lastSyncDate,
		workListToTreatmentPlanSyncDate: state.workListToTreatmentPlans.lastSyncDate,
		profileSyncDate: state.profile.lastSyncDate,
		processEquipmentSyncDate: state.processEquipments.lastSyncDate,
		processEquipmentDataSyncDate: state.processEquipmentData.lastSyncDate,
		esfStatusFeedCurveSyncDate: state.esfStatus.curveLastSyncDate,
		esfStatusDataSyncDate: state.esfStatus.dataLastSyncDate,
		stationSyncDate: state.station.lastSyncDate,
		unitToPenSyncDate: state.unitToPenData.lastSyncDate,
		esfDailyStatusSyncDate: state.esfDailyStatus.lastSyncDate,
		generalSettingsSyncDate: state.generalSettings.lastSyncDate,
		lardEventSyncDate: state.lardScanningEvents.lastSyncDate,
		deadPigletsEventSyncDate: state.deadPigletsEvents.lastSyncDate,
		dashboardSettingsSyncDate: state.dashboardSettings.lastSyncDate,
		isResettingState: state.profile.isResettingState,
		breedTableItemsSyncDate: state.breedTableItems.lastSyncDate,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
	return {
		stemAnimalGetSyncData: () => stemAnimalOperation.GetSyncData()(dispatch),
		pregnancyEventGetSyncData: () => pregnancyOperation.GetSyncData()(dispatch),
		matingBatchGetSyncData: () => matingBatchOperation.GetSyncData()(dispatch),
		treatmentGetSyncData: () => treatmentOperation.GetSyncData()(dispatch),
		processEquipmentGetSyncData: () => processEquipmentOperation.GetSyncData()(dispatch),
		loadModules: () => LoadProfileModules()(dispatch),
		processEquipmentDataGetSyncData: () =>
			processEquipmentDataOperation.GetProcessEquipmentDataSyncData()(dispatch),
		workListSettingsGetSyncData: () => workListSettingsOperation.GetSyncData()(dispatch),
		reasonGetSyncData: () => reasonOperation.GetSyncData()(dispatch),
		treatmentPlanGetSyncData: () => treatmentPlanOperation.GetSyncData()(dispatch),
		treatmentDefinitionGetSyncData: () => treatmentDefinitionOperation.GetSyncData()(dispatch),
		validationGetSyncData: () => validationOperation.GetSyncData()(dispatch),
		markingGetSyncData: () => markingOperation.GetSyncData()(dispatch),
		drugTypeGetSyncData: () => drugTypeOperation.GetSyncData()(dispatch),
		diagnoseCategoryGetSyncData: () => diagnoseCategoryOperation.GetSyncData()(dispatch),
		diagnoseGetSyncData: () => diagnoseOperation.GetSyncData()(dispatch),
		locationGetSyncData: () => locationOperation.GetLocations()(dispatch),
		moveEventGetSyncData: () => moveEventOperation.GetSyncData()(dispatch),
		matingBatchSettingGetSyncData: () => matingBatchSettingOperation.GetSyncData()(dispatch),
		adjustFeedGetSyncData: () => adjustFeedOperation.GetSyncData()(dispatch),
		animalReportSettingGetSyncData: () => animalReportSettingsOperation.GetSyncData()(dispatch),
		workListToTreatmentPlanGetSyncData: () => workListToTreatmentPlanOperation.GetSyncData()(dispatch),
		productionReportGetSyncData: () => productionReportOperation.GetSyncData()(dispatch),
		stemAnimalSaveSyncData: () => stemAnimalOperation.SaveSyncData()(dispatch),
		pregnancyEventSaveSyncData: () => pregnancyOperation.SaveSyncData()(dispatch),
		matingBatchSaveSyncData: () => matingBatchOperation.SaveSyncData()(dispatch),
		treatmentSaveSyncData: () => treatmentOperation.SaveSyncData()(dispatch),
		treatmentPlanSaveSyncData: () => treatmentPlanOperation.SaveSyncData()(dispatch),
		treatmentDefinitionSaveSyncData: () => treatmentDefinitionOperation.SaveSyncData()(dispatch),
		moveEventSaveSyncData: () => moveEventOperation.SaveSyncData()(dispatch),
		saveTriggerSync: (trigger: boolean) => SaveTriggerSync(trigger)(dispatch),
		saveWorkListSettings: () => workListSettingsOperation.SaveSyncData()(dispatch),
		adjustFeedSaveSyncData: () => adjustFeedOperation.SaveSyncData()(dispatch),
		alarmGetSyncData: () => notificationsOperation.GetAlarms()(dispatch),
		warningsGetSyncData: () => notificationsOperation.GetWarnings()(dispatch),
		saveAnimalReportSettings: () => animalReportSettingsOperation.SaveSyncData()(dispatch),
		saveWorkListToTreatmentPlan: () => workListToTreatmentPlanOperation.SaveSyncData()(dispatch),
		profilesGetSyncData: () => profileOperation.GetSyncData()(dispatch),
		unitToPenGetSyncData: () => UnitsToPenOperations.GetSyncData()(dispatch),
		GetStationSyncData: () => StationOperations.GetStationSyncData()(dispatch),
		esfStatusDataGetSyncData: () => EsfStatusOperations.GetEsfFeedingStatusSyncData()(dispatch),
		esfStatusFeedCurveGetSyncData: () => EsfStatusOperations.GetEsfFeedCurveSyncData()(dispatch),
		esfFeedingStatusSaveSyncData: () => EsfStatusOperations.SaveSyncData()(dispatch),
		saveAcknowledgeData: () => EsfStatusOperations.SaveAcknowledgeData()(dispatch),
		esfDailyStatusGetSyncData: () => GetEsfDailySyncData()(dispatch),
		SaveEsfDailyStatusSyncData: () => SaveEsfDailyStatusSyncData()(dispatch),
		generalSettingsSyncData: () => GeneralSettingsOperations.GetSyncData()(dispatch),
		getSite: (siteId: string) => GetSite(siteId)(dispatch),
		siteChange: () => SiteChange()(dispatch),
		syncStarted: () => SyncStarted()(dispatch),
		syncFinished: () => SyncFinished()(dispatch),
		userLogout: () =>
			dispatch({
				type: 'USER_LOGOUT',
			}),
		lardEventGetSyncDate: () => LardEventOperations.GetSyncData()(dispatch),
		lardEventSaveSyncData: () => LardEventOperations.SaveSyncData()(dispatch),
		deadPigletsEventGetSyncDate: () => DeadPigletsEventOperations.GetSyncData()(dispatch),
		deadPigletsEventSaveSyncData: () => DeadPigletsEventOperations.SaveSyncData()(dispatch),
		dashboardSettingGetSyncData: () => dashboardSettingOperation.GetSyncData()(dispatch),
		SetIsResettingState: (isResettingState: boolean) =>
			profileOperation.SetIsResettingState(isResettingState)(dispatch),
		GetDistriwinFeedingComponentSyncData: (siteId: string) =>
			GetDistriwinFeedingComponentSyncData(siteId)(dispatch),
		getSyncBreedTableItems: () => dispatch(getSyncBreedTableItems() as any),
	};
};

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
let isSyncing = false;
class TriggerSync extends React.PureComponent<Props> {
	private interval: number = 1000 * 60 * 5;
	public async ensureAllUpdates() {
		await this.triggerSaveSync();
	}
	public async componentDidMount() {
		if (this.props.isAuthenticated === true && this.props.siteId) {
			this.props.syncStarted();
			await this.ensureAllUpdates();
			await this.triggerGetSync(true);
			this.props.syncFinished();
		}
	}

	public async componentDidUpdate(prevProps: Props, prevState: any, snapshot: any) {
		if (this.props.siteId && prevProps.siteId !== this.props.siteId) {
			//if changed site
			isSyncing = true;
			this.props.syncStarted();
			await this.ensureAllUpdates();
			this.props.siteChange();
			this.props.syncFinished();
			isSyncing = false;
		}
		if (!this.props.isConnected) {
			return;
		}

		if (this.props.isAuthenticated !== true) {
			return;
		}

		if (this.props.isSyncing || isSyncing) {
			return;
		}
		if (this.props.siteId) {
			if (prevProps.isAuthenticated === false && this.props.isAuthenticated) {
				//if just logged in
				isSyncing = true;
				this.props.syncStarted();
				await this.ensureAllUpdates();
				this.props.siteChange();
				await this.triggerGetSync(true);
				this.props.syncFinished();
			} else if (!this.props.isResettingState && prevProps.isConnected !== this.props.isConnected) {
				//if change from offline => online
				isSyncing = true;
				this.props.syncStarted();
				await this.triggerSync();
				this.props.syncFinished();
			} else if (
				!this.props.isResettingState &&
				this.props.isTriggered &&
				prevProps.isTriggered !== this.props.isTriggered
			) {
				//if sync triggered manually
				isSyncing = true;
				this.props.syncStarted();
				await this.triggerSync(true);
				await this.props.saveTriggerSync(false);
				this.props.syncFinished();
			} else if (this.props.isResettingState) {
				isSyncing = true;
				this.props.SetIsResettingState(false);
				this.props.syncStarted();

				await this.triggerGetSync(true);
				this.props.syncFinished();
			}
		}
		isSyncing = false;
	}

	public render() {
		return null;
	}

	public async triggerSync(forceSync: boolean = false) {
		if (this.props.isConnected) {
			await this.triggerSaveSync();
			await this.triggerGetSync(forceSync);
		}
	}

	private checkInterval(lastSyncTime: number, currentTime: number) {
		return currentTime - lastSyncTime > this.interval;
	}

	private async triggerGetSync(forceSync: boolean) {
		let ArrayOfPromises = Array<Promise<void>>();

		let currentTime = new Date().getTime();

		ArrayOfPromises.push(this.props.loadModules());

		if (this.props.siteId) {
			this.props.getSite(this.props.siteId);
		}

		if (!(await isAuthenticated())) {
			return;
		}

		if (this.checkInterval(this.props.stemAnimalsSyncDate.getTime(), currentTime) || forceSync) {
			ArrayOfPromises.push(this.props.stemAnimalGetSyncData());
		}
		if (this.checkInterval(this.props.pregnancyEventSyncDate.getTime(), currentTime) || forceSync) {
			ArrayOfPromises.push(this.props.pregnancyEventGetSyncData());
		}
		if (this.checkInterval(this.props.matingBatchesSyncDate.getTime(), currentTime) || forceSync) {
			ArrayOfPromises.push(this.props.matingBatchGetSyncData());
		}
		if (this.checkInterval(this.props.treatmentsSyncDate.getTime(), currentTime) || forceSync) {
			ArrayOfPromises.push(this.props.treatmentGetSyncData());
		}
		if (this.checkInterval(this.props.reasonsSyncDate.getTime(), currentTime) || forceSync) {
			ArrayOfPromises.push(this.props.reasonGetSyncData());
		}
		if (this.checkInterval(this.props.treatmentPlansSyncDate.getTime(), currentTime) || forceSync) {
			ArrayOfPromises.push(this.props.treatmentPlanGetSyncData());
		}
		if (this.checkInterval(this.props.treatmentDefinitionsSyncDate.getTime(), currentTime) || forceSync) {
			ArrayOfPromises.push(this.props.treatmentDefinitionGetSyncData());
		}
		if (this.checkInterval(this.props.validationSetupSyncDate.getTime(), currentTime) || forceSync) {
			ArrayOfPromises.push(this.props.validationGetSyncData());
		}
		if (this.checkInterval(this.props.markingsSyncDate.getTime(), currentTime) || forceSync) {
			ArrayOfPromises.push(this.props.markingGetSyncData());
		}
		if (this.checkInterval(this.props.diagnoseSyncDate.getTime(), currentTime) || forceSync) {
			ArrayOfPromises.push(this.props.diagnoseGetSyncData());
		}
		if (this.checkInterval(this.props.diagnoseCategorySyncDate.getTime(), currentTime) || forceSync) {
			ArrayOfPromises.push(this.props.diagnoseCategoryGetSyncData());
		}
		if (this.checkInterval(this.props.drugTypeSyncDate.getTime(), currentTime) || forceSync) {
			ArrayOfPromises.push(this.props.drugTypeGetSyncData());
		}
		if (this.checkInterval(this.props.locationsSyncDate.getTime(), currentTime) || forceSync) {
			ArrayOfPromises.push(this.props.locationGetSyncData());
		}
		if (this.checkInterval(this.props.moveEventSyncDate.getTime(), currentTime) || forceSync) {
			ArrayOfPromises.push(this.props.moveEventGetSyncData());
		}
		if (this.checkInterval(this.props.matingBatchSettingsSyncDate.getTime(), currentTime) || forceSync) {
			ArrayOfPromises.push(this.props.matingBatchSettingGetSyncData());
		}
		if (this.checkInterval(this.props.adjustFeedSyncDate.getTime(), currentTime) || forceSync) {
			ArrayOfPromises.push(this.props.adjustFeedGetSyncData());
		}
		if (this.checkInterval(this.props.workListSyncDate.getTime(), currentTime) || forceSync) {
			ArrayOfPromises.push(this.props.workListSettingsGetSyncData());
		}
		if (this.checkInterval(this.props.animalReportSettingSyncDate.getTime(), currentTime) || forceSync) {
			ArrayOfPromises.push(this.props.animalReportSettingGetSyncData());
		}
		if (this.checkInterval(this.props.generalSettingsSyncDate.getTime(), currentTime) || forceSync) {
			ArrayOfPromises.push(this.props.generalSettingsSyncData());
		}
		if (this.checkInterval(this.props.processEquipmentSyncDate.getTime(), currentTime) || forceSync) {
			ArrayOfPromises.push(this.props.processEquipmentGetSyncData());
		}
		if (this.checkInterval(this.props.processEquipmentDataSyncDate.getTime(), currentTime) || forceSync) {
			ArrayOfPromises.push(this.props.processEquipmentDataGetSyncData());
		}

		if (this.checkInterval(this.props.workListToTreatmentPlanSyncDate.getTime(), currentTime) || forceSync) {
			ArrayOfPromises.push(this.props.workListToTreatmentPlanGetSyncData());
		}
		if (this.checkInterval(this.props.productionReportSyncDate.getTime(), currentTime) || forceSync) {
			ArrayOfPromises.push(this.props.productionReportGetSyncData());
		}
		if (this.checkInterval(this.props.profileSyncDate.getTime(), currentTime) || forceSync) {
			ArrayOfPromises.push(this.props.profilesGetSyncData());
		}

		if (
			(this.props.unitToPenSyncDate &&
				this.checkInterval(this.props.unitToPenSyncDate!.getTime(), currentTime)) ||
			forceSync
		) {
			ArrayOfPromises.push(this.props.unitToPenGetSyncData());
		}
		if (
			(this.props.stationSyncDate && this.checkInterval(this.props.stationSyncDate.getTime(), currentTime)) ||
			forceSync
		) {
			ArrayOfPromises.push(this.props.GetStationSyncData());
		}
		if (
			(this.props.esfStatusDataSyncDate &&
				this.checkInterval(this.props.esfStatusDataSyncDate.getTime(), currentTime)) ||
			forceSync
		) {
			ArrayOfPromises.push(this.props.esfStatusDataGetSyncData());
		}
		if (
			(this.props.esfStatusFeedCurveSyncDate &&
				this.checkInterval(this.props.esfStatusFeedCurveSyncDate.getTime(), currentTime)) ||
			forceSync
		) {
			ArrayOfPromises.push(this.props.esfStatusFeedCurveGetSyncData());
		}
		if (
			(this.props.esfDailyStatusSyncDate &&
				this.checkInterval(this.props.esfDailyStatusSyncDate.getTime(), currentTime)) ||
			forceSync
		) {
			ArrayOfPromises.push(this.props.esfDailyStatusGetSyncData());
		}

		if (
			(this.props.alarmSyncDate && this.checkInterval(this.props.alarmSyncDate.getTime(), currentTime)) ||
			forceSync
		) {
			ArrayOfPromises.push(this.props.alarmGetSyncData());
		}
		if (
			(this.props.warningsSyncDate && this.checkInterval(this.props.warningsSyncDate.getTime(), currentTime)) ||
			forceSync
		) {
			ArrayOfPromises.push(this.props.warningsGetSyncData());
		}
		if (this.checkInterval(this.props.lardEventSyncDate.getTime(), currentTime) || forceSync) {
			ArrayOfPromises.push(this.props.lardEventGetSyncDate());
		}
		if (this.checkInterval(this.props.deadPigletsEventSyncDate.getTime(), currentTime) || forceSync) {
			ArrayOfPromises.push(this.props.deadPigletsEventGetSyncDate());
		}
		if (this.checkInterval(this.props.dashboardSettingsSyncDate.getTime(), currentTime) || forceSync) {
			ArrayOfPromises.push(this.props.dashboardSettingGetSyncData());
		}
		if (this.props.siteId) {
			ArrayOfPromises.push(this.props.GetDistriwinFeedingComponentSyncData(this.props.siteId));
		}
		if (this.checkInterval(this.props.breedTableItemsSyncDate.getTime(), currentTime) || forceSync) {
			ArrayOfPromises.push(this.props.getSyncBreedTableItems());
		}

		await Promise.all(ArrayOfPromises);
	}

	private async triggerSaveSync() {
		// await this.props.stemAnimalSaveSyncData().then(async () => {
		// 	let promise1 = this.props.pregnancyEventSaveSyncData();
		// 	let promise2 = this.props.treatmentPlanSaveSyncData().then(async () => {
		// 		await this.props.treatmentSaveSyncData();
		// 	});

		// 	let promise3 = this.props.moveEventSaveSyncData();
		// 	let promise4 = this.props.saveWorkListSettings();
		// 	let promise5 = this.props.esfFeedingStatusSaveSyncData();
		// 	let promise6 = this.props.SaveEsfDailyStatusSyncData();
		// 	let promise8 = this.props.lardEventSaveSyncData();
		// 	let promise9 = this.props.deadPigletsEventSaveSyncData();
		// 	await promise1;
		// 	await promise2;
		// 	await promise3;
		// 	await promise4;
		// 	await promise5;
		// 	await promise6;
		// 	let promise7 = this.props.saveAcknowledgeData();
		// 	await promise7;
		// 	await promise8;
		// 	await promise9;
		// });
		// await this.props.adjustFeedSaveSyncData();
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TriggerSync);
