import { Action } from 'redux';
import { BalanceWeanedPigEvent, ITotalWeight } from 'shared/api/api';
import { SharedAppState } from 'shared/state/store.shared';
import { isType } from 'typescript-fsa';
import { siteChange } from '../profile/actions';
import * as actions from './actions';
import { BalanceWeanedPigsState } from './types';

export const initialState: BalanceWeanedPigsState = {
	entities: [],
	totalAmountAndTotalWeight: undefined,
	currentPigsOnStable: undefined,
	isFetchingData: false,
};

const BalanceWeanedPigsReducer = (
	state: BalanceWeanedPigsState = initialState,
	action: Action,
): BalanceWeanedPigsState => {
	if (isType(action, actions.getBalanceWeanedPigsData.started)) {
		const result = {
			...state,
			isFetchingData: true,
		};
		return result;
	}

	if (isType(action, actions.getBalanceWeanedPigsData.done)) {
		const result = {
			...state,
			entities: action.payload.result.balanceWeanedPigEvents ? action.payload.result.balanceWeanedPigEvents : [],
			totalAmountAndTotalWeight: action.payload.result.totalWeightToCalculateWith,
			currentPigsOnStable: action.payload.result.currentPigsOnStable,
			isFetchingData: false
		};
		return result;
	}

	if (
		isType(action, actions.UpsertBalanceWeanedPigsData.started) &&
		action.payload.event &&
		action.payload.event.balanceWeanedPigEvents
	) {
		const stateCopy = { ...state };
		stateCopy.entities = mergeArrays(
			[
				...stateCopy.entities.filter(
					en =>
						!action.payload.idsToRemove || !en.eventId || !action.payload.idsToRemove.includes(en.eventId),
				),
			],
			[...action.payload.event.balanceWeanedPigEvents],
		);

		stateCopy.currentPigsOnStable =
			(stateCopy.currentPigsOnStable !== undefined ? stateCopy.currentPigsOnStable : 0) +
			(action.payload.totalAmount !== undefined ? action.payload.totalAmount : 0);

		state = stateCopy;
	}

	if (isType(action, siteChange.done)) {
		state = initialState;
	}

	if (action.type === 'persist/REHYDRATE') {
		let a = (action as any) as { payload: SharedAppState; key: string };

		if (a.key === 'root') {
			let balanceWeanedPigEvents = new Array<BalanceWeanedPigEvent>();
			if (a.payload && a.payload.balanceWeanedPigsData && a.payload.balanceWeanedPigsData.entities) {
				balanceWeanedPigEvents = a.payload.balanceWeanedPigsData.entities.map(dt => {
					let ndt = new BalanceWeanedPigEvent();
					ndt.init(dt);
					return ndt;
				});
			}
			let totalWeight: ITotalWeight | undefined;
			if (a.payload && a.payload.balanceWeanedPigsData && a.payload.balanceWeanedPigsData.entities) {
				totalWeight = a.payload.balanceWeanedPigsData.totalAmountAndTotalWeight;
			}
			let currentPigsOnStable: number | undefined;
			if (a.payload && a.payload.balanceWeanedPigsData && a.payload.balanceWeanedPigsData.currentPigsOnStable) {
				currentPigsOnStable = a.payload.balanceWeanedPigsData.currentPigsOnStable;
			}
			state = {
				...state,
				entities: balanceWeanedPigEvents,
				totalAmountAndTotalWeight: totalWeight,
				currentPigsOnStable: currentPigsOnStable,
			};
		}
	}
	return state;
};

export function mergeArrays(oldArray: BalanceWeanedPigEvent[], updatedArray: BalanceWeanedPigEvent[]) {
	if (!updatedArray || updatedArray.length === 0) {
		return oldArray;
	}

	if (!oldArray || oldArray.length === 0) {
		return updatedArray;
	}

	let clonedOld = [...oldArray];
	updatedArray.forEach(updated => {
		const index = clonedOld.findIndex(old => old.eventId === updated.eventId);
		merge(index, clonedOld, updated);
	});

	return clonedOld;
}

function merge(index: number, clonedOld: BalanceWeanedPigEvent[], updated: BalanceWeanedPigEvent) {
	if (index > -1) {
		clonedOld[index] = updated;
	} else {
		clonedOld.push(updated);
	}
}

export default BalanceWeanedPigsReducer;
