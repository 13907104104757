import { WebAppState } from 'web/state/store.web';
import { memoizeAllPoolYoungFemaleEvents, memoizeHashmapLocation } from '../memoize-getters/memoize-getters';
import { Dispatch } from 'redux';
import {
	IPoolYoungFemale,
	SkioldOneClient,
	FileResponse,
	PoolYoungFemaleShouldDepartureTableItem,
} from 'shared/api/api';
import * as PoolYoungFemaleOperations from 'shared/state/ducks/pool-young-female/operations';
import { SkioldDigitalApiBaseUrl } from 'shared/constants';
import { savePdfAs } from 'web/web-helpers/pdf-helper/general-pdf-helper';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';

export interface PoolYoungFemaleEventListPropsFromParent {
	dateFrom: Date;
	dateTo: Date;
	onSummaryChanged: any;
	navigation: NativeStackNavigationProp<{}>;
}

export interface PoolYoungFemaleEventListState {
	editModalIsOpen: boolean;
	editId: string | undefined;
	summaryItem: PoolYoungFemaleSummaryItem;
}

export type PoolYoungFemaleEventListProps = ReturnType<typeof PoolYoungFemaleEventListMapStateToProps> &
	ReturnType<typeof PoolYoungFemaleEventListMapDispatchToProps> &
	PoolYoungFemaleEventListPropsFromParent;

export const PoolYoungFemaleEventListMapStateToProps = (
	state: WebAppState,
	ownProps: PoolYoungFemaleEventListPropsFromParent,
) => {
	return {
		poolYoungFemaleEvents: memoizeAllPoolYoungFemaleEvents(
			state.poolYoungFemales.entities,
			ownProps.dateFrom,
			ownProps.dateTo,
		),
		profile: state.profile.active,
	};
};

export const PoolYoungFemaleTableMapStateToProps = (state: WebAppState) => {
	return {
		hashmapLocations: memoizeHashmapLocation(
			state.locations.buildings,
			state.locations.sections,
			state.locations.pens,
		),
		generalSettings: state.generalSettings.entity,
		userProfiles: state.profile.entities,
	};
};

export const PoolYoungFemaleEventListMapDispatchToProps = (dispatch: Dispatch, props: {}) => {
	return {
		poolYoungTableGetSyncDate: () => PoolYoungFemaleOperations.GetSyncData()(dispatch),
		removePoolYoungFemale: (item: IPoolYoungFemale) =>
			PoolYoungFemaleOperations.DeletePoolYoungFemale(item)(dispatch),
	};
};

export interface PoolYoungFemaleTablePropsFromParent {
	data: IPoolYoungFemale[];
	openEditModal: (event: IPoolYoungFemale) => void;
	removeItem: (event: IPoolYoungFemale) => void;
	onFiltersChanged: (events: IPoolYoungFemale[]) => void;
	setRef?: (ref: any) => void;
}

export type PoolYoungFemaleTableProps = ReturnType<typeof PoolYoungFemaleTableMapStateToProps> &
	PoolYoungFemaleTablePropsFromParent;

export class PoolYoungFemaleSummaryItem {
	public countBought?: string;
	public countSold?: string;
	public countDead?: string;
	public countPutDown?: string;
	public countFromWeaner?: string;
	public countFromFinisher?: string;
}

export function PrintPoolYoungFemalePdf(
	ids: string[],
	fileName: string,
	siteId: string,
	timeZoneId?: string,
	lang?: string,
) {
	return new SkioldOneClient(SkioldDigitalApiBaseUrl)
		.poolYoungFemalePdf_PoolYoungFemalePdfPrint(ids, siteId, lang)
		.then((fileResponse: FileResponse | null) => {
			savePdfAs(fileResponse, fileName);
		})
		.catch(() => {
			return;
		});
}

export function GetPoolYoungFemaleShouldDepartureList(
	ids: PoolYoungFemaleShouldDepartureTableItem[],
	fileName: string,
	siteId: string,
	timeZoneId?: string,
	lang?: string,
) {
	return new SkioldOneClient(SkioldDigitalApiBaseUrl)
		.poolYoungFemalePdf_PoolYoungFemaleShouldDepartureList(ids, siteId, timeZoneId, lang)
		.then((fileResponse: FileResponse | null) => {
			savePdfAs(fileResponse, fileName);
		})
		.catch(() => {
			return;
		});
}
