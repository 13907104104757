import React, { FC, useEffect, useState } from 'react';
import { Option } from 'react-dropdown';
import { useSelector } from 'react-redux';
import { AnimalKind, AnimalType, PoolYoungFemaleShouldDepartureTableItem } from 'shared/api/api';
import { getDateString } from 'shared/helpers/date-helpers';
import { memoizeHashmapLocation } from 'shared/helpers/memoize-getters/memoize-getters';
import { generatePyfShouldDepartureTableData } from 'shared/helpers/pool-young-female-helper/pool-young-female-helper';
import { DefaultReasonOption, getReasonOptions } from 'shared/helpers/reason-helper/reason-helper';
import { localized } from 'shared/state/i18n/i18n';
import { DepartureTypes } from 'shared/state/models/departure-types';
import { WebAppState } from 'web/state/store.web';
import { SkioldCheckbox } from 'web/view/components/skiold-components/skiold-checkbox/skiold-checkbox';
import { SkioldDropdown } from 'web/view/components/skiold-components/skiold-dropdown/skiold-dropdown';
import { SkioldIntegerInput } from 'web/view/components/skiold-components/skiold-integer-input/skiold-integer-input';
import SkioldTableGrid from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { usePyfShouldDeparture } from './pyf-should-departure-overview';

interface PropsFromParent {}

const retentionCell = (item: PoolYoungFemaleShouldDepartureTableItem) => getDateString(item.retentionDate);

export const PoolYoungFemaleShouldDepartureTable: FC<PropsFromParent> = React.memo(({ ...props }) => {
	const navigationQuery = useSelector((state: WebAppState) => state.navigation);
	const [productionType, setProductionType] = useState(
		navigationQuery.query ? (navigationQuery.query['type'] as AnimalType) : AnimalType.Finisher,
	);

	useEffect(() => {
		setProductionType(navigationQuery.query ? (navigationQuery.query['type'] as AnimalType) : AnimalType.Finisher);
	}, [navigationQuery]);

	const reasons = useSelector((state: WebAppState) => state.reasons.entities);
	const pyfs = useSelector((state: WebAppState) => state.poolYoungFemales.entities);
	const hashMapLocations = useSelector((state: WebAppState) =>
		memoizeHashmapLocation(state.locations.buildings, state.locations.sections, state.locations.pens),
	);
	const { tableItems, setTableItems, setPrintData } = usePyfShouldDeparture();
	const [departureTypeOptions, setDepartureOptions] = useState<Option[]>(
		DepartureTypes.GetDepartureTypesOptions(productionType),
	);

	useEffect(() => {
		setDepartureOptions(DepartureTypes.GetDepartureTypesOptions(productionType));
	}, [productionType]);

	// Genereate table items
	useEffect(() => {
		setTableItems(generatePyfShouldDepartureTableData(pyfs, hashMapLocations));
	}, [pyfs, hashMapLocations, productionType,setTableItems]);

	const departureTypeCell = (item: PoolYoungFemaleShouldDepartureTableItem) => {
		const departureTypeOption = departureTypeOptions.find(op => op.value === item.departureType);
		const departureTypeChanged = (option: Option) => {
			let tableData = [...tableItems];
			const index = tableData.findIndex(pyf => pyf.poolYoungFemaleId === item.poolYoungFemaleId);
			if (index >= 0) {
				const itemChanged = {
					...tableData[index],
					departureType: option.value,
				} as PoolYoungFemaleShouldDepartureTableItem;
				tableData[index] = itemChanged;
				setTableItems(tableData);
			}
		};
		return (
			<SkioldDropdown
				usedInTable={true}
				theme={'dark'}
				items={departureTypeOptions}
				selectedValue={departureTypeOption}
				onValueChanged={departureTypeChanged}
			/>
		);
	};

	const pigCountCell = (item: PoolYoungFemaleShouldDepartureTableItem) => {
		const pigCountChanged = (newPigCount: number | undefined) => {
			let tableData = [...tableItems];
			const index = tableData.findIndex(pyf => pyf.poolYoungFemaleId === item.poolYoungFemaleId);
			if (index >= 0) {
				const itemChanged = {
					...tableData[index],
					pigCount: newPigCount,
				} as PoolYoungFemaleShouldDepartureTableItem;
				tableData[index] = itemChanged;
				setTableItems(tableData);
			}
		};
		return (
			<SkioldIntegerInput className="text-align-right" onChangeNumber={pigCountChanged} text={item.pigCount} />
		);
	};

	const reasonCell = (item: PoolYoungFemaleShouldDepartureTableItem) => {
		const departureReasonChanged = (option: Option) => {
			let tableData = [...tableItems];
			const index = tableData.findIndex(pyf => pyf.poolYoungFemaleId === item.poolYoungFemaleId);
			if (index >= 0) {
				const itemChanged = {
					...tableData[index],
					reasonId: option.value,
					reasonString: option.label,
				} as PoolYoungFemaleShouldDepartureTableItem;
				tableData[index] = itemChanged;
				setTableItems(tableData);
			}
		};

		const options = getReasonOptions(reasons, AnimalKind.YoungFemale, item.departureType!);
		const selectedOption = options.find(o => o.value === item.reasonId);
		return (
			<SkioldDropdown
				usedInTable={true}
				theme={'dark'}
				items={options}
				selectedValue={selectedOption ? selectedOption : DefaultReasonOption}
				onValueChanged={departureReasonChanged}
			/>
		);
	};

	const checkedCell = (item: PoolYoungFemaleShouldDepartureTableItem) => {
		const onCheckChanged = (value: boolean) => {
			let tableData = [...tableItems];
			const index = tableData.findIndex(pyf => pyf.poolYoungFemaleId === item.poolYoungFemaleId);
			if (index >= 0) {
				const itemChanged = { ...tableData[index], checked: value } as PoolYoungFemaleShouldDepartureTableItem;
				tableData[index] = itemChanged;
				setTableItems(tableData);
			}
		};
		return <SkioldCheckbox className="text-align-right" isChecked={item.checked} onClick={onCheckChanged} />;
	};

	const checkAllCell = () => {
		const isCommitAll = tableItems.length <= 0 || tableItems.find(item => !item.checked) ? false : true;
		const onCheckChanged = (value: boolean) => {
			// Set all items to the opposite of isCommitAll
			let tableData = tableItems.map(item => {
				return { ...item, checked: !isCommitAll } as PoolYoungFemaleShouldDepartureTableItem;
			});
			setTableItems(tableData);
		};
		return (
			<ViewWeb className="checkbox-view-filter-style">
				<SkioldCheckbox isChecked={isCommitAll} onClick={onCheckChanged} />
			</ViewWeb>
		);
	};

	const onFilterChanged = (data: PoolYoungFemaleShouldDepartureTableItem[]) => {
		setPrintData(data);
	};

	// Custom filters, due to editable cells
	const customPigCountFilter = (value: any, filterValue: any, row: PoolYoungFemaleShouldDepartureTableItem) => {
		return row.pigCount && String(row.pigCount).startsWith(filterValue.value);
	};

	const departureTypeFilter = (value: any, filterValue: any, row: PoolYoungFemaleShouldDepartureTableItem) => {
		return (
			row.departureType &&
			String(localized(row.departureType).toLocaleLowerCase()).startsWith(filterValue.value.toLocaleLowerCase())
		);
	};
	const reasonFilter = (value: any, filterValue: any, row: PoolYoungFemaleShouldDepartureTableItem) => {
		return (
			row.reasonString &&
			String(row.reasonString.toLocaleLowerCase()).startsWith(filterValue.value.toLocaleLowerCase())
		);
	};

	const generateColumns = () => [
		{
			title: localized('retentionDate'),
			name: 'retentionDate',
			getCellValue: retentionCell,
		},
		{
			title: localized('location'),
			name: 'locationString',
		},
		{
			title: localized('pen'),
			name: 'penString',
		},
		{
			title: localized('pigCount'),
			name: 'pigCount',
			getCellValue: pigCountCell,
			sortable: false,
			filterFunction: customPigCountFilter,
		},
		{
			title: localized('departureType'),
			name: 'departureType',
			getCellValue: departureTypeCell,
			sortable: false,
			filterFunction: departureTypeFilter,
		},
		{
			title: localized('reason'),
			name: 'reasonString',
			filterFunction: reasonFilter,
			sortable: false,
			getCellValue: reasonCell,
		},
		{
			title: localized('completed'),
			name: 'checked',
			getCellValue: checkedCell,
			sortable: false,
			filter: checkAllCell,
		},
	];

	const generateColumnExtes = [
		{
			width: 85,
			columnName: 'retentionDate',
		},
		{
			width: 85,
			columnName: 'locationString',
		},
		{
			width: 85,
			columnName: 'penString',
		},
		{
			width: 85,
			columnName: 'pigCount',
		},
		{
			width: 85,
			columnName: 'departureType',
		},
		{
			width: 85,
			columnName: 'reasonString',
		},
		{
			width: 85,
			columnName: 'checked',
		},
	];

	return (
		<ViewWeb className="flex-row-space-between pyf-should-depart-table-container">
			<SkioldTableGrid
				tableKey={'pyf-should-depart'}
				ignoreSetCount={true}
				ColumnExtensions={generateColumnExtes}
				columns={generateColumns()}
				data={tableItems}
				onFiltersChanged={onFilterChanged}
			/>
		</ViewWeb>
	);
});
