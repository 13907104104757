import { Action } from 'redux';
import { Country, ICountry } from 'shared/api/api';
import { SharedAppState } from 'shared/state/store.shared';
import { isType } from 'typescript-fsa';
import { getCountries } from './actions';
import { CountryState } from './types';

export const initialState: CountryState = {
    countries: []
};

const countryReducer = (state: CountryState = initialState, action: Action): CountryState => {
    if (isType(action, getCountries.done)) {
        const result = { ...state, countries: action.payload.result };
        return result;
    }

    //Ensure Date objects are in fact date and not strings - redux-persist serializes dates to string but doesn't deserialize to dates again
    if (action.type === 'persist/REHYDRATE') {
        let a = (action as any) as { payload: SharedAppState; key: string };

        if (a.key === 'root') {
            let countries = new Array<Country>();

            if (a.payload && a.payload.countries && a.payload.countries.countries) {
                countries = a.payload.countries.countries.map((dt: ICountry) => {
                    let ndt = Country.fromJS({});
                    ndt.init(dt);
                    return ndt;
                });
            }

            state = { ...state, countries };
        }
    }

    return state;
};

export default countryReducer;
