import { localized } from "shared/state/i18n/i18n";

export const TotalMatingsGroupedColumns =() => [
    {
        title: localized('DaysToMatingFrom'),
        children: [{ columnName: 'weaning' }, { columnName: 'mating' }],
    },
    {
        title: localized('DaysFromMatingToDeparture'),
        children: [
            { columnName: 'notPregnant' },
            { columnName: 'abortion' },
            { columnName: 'soldOrKilled' },
            { columnName: 'deadOrPutDown' },
        ],
    },
];