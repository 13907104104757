import { default as ObjectID } from 'bson-objectid';
import { Dispatch } from 'redux';
import { Diagnose, IDiagnose, IImage, Image } from 'shared/api/api';
import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import { StoreContainer } from 'shared/state/store-container';
import * as Action from './actions';

export function GetDiagnoseImage(id: string) {
	return AsyncOperationBuilder2(Action.getImage, client => client.image_Get(id), id);
}

export function SaveDiagnose(diagnose: IDiagnose) {
	const state = StoreContainer.getState();
	const prevEntity = state.diagnose.entities.find(diag => diag.id === diagnose.id);
	if (!diagnose.id) {
		diagnose.id = new ObjectID().toHexString();
	}

	return AsyncOperationBuilder2(
		Action.saveDiagnose,
		client => client.diagnose_Post(diagnose as Diagnose),
		diagnose as Diagnose,
		prevEntity,
	);
}

export function CreateDiagnoseImage(image: IImage) {
	return AsyncOperationBuilder2(Action.saveImage, client => client.image_Post(image as Image), image as Image);
}

export function GetSyncData() {
	const state = StoreContainer.getState();
	const lastSyncDate = state.diagnose.lastSyncDate;

	if (state.diagnose.syncInProgress) {
		return (dispatch: Dispatch<any>) => {
			return Promise.resolve();
		};
	}
	return AsyncOperationBuilder2(Action.getSyncData, client => client.diagnose_Sync(lastSyncDate), {
		lastSyncDate,
	});
}
