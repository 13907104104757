import ObjectID from 'bson-objectid';
import {
	Feeding,
	FeedingAdjustment,
	FeedingAdjustmentBase,
	FeedingAmountType,
	FeedingUsage,
	IUserProfile,
} from 'shared/api/api';
import { getAnimalTypesInUse } from 'shared/helpers/general-helpers';
import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import * as Action from './actions';

export function GetFeedings(siteId: string, userProfile: IUserProfile) {
	return AsyncOperationBuilder2(
		Action.getFeedings,
		client => client.feeding_GetBySiteIdV2(siteId, getAnimalTypesInUse(userProfile)),
		{ siteId },
	);
}

export function saveFeeding(feeding: Feeding) {
	if (!feeding.id) {
		feeding.id = new ObjectID().toHexString();
	}
	feeding.adjustments = feeding.adjustments?.map(getCorrectAnalysisType);
	feeding.adjustments!.sort(sortDataByEarliest());
	return AsyncOperationBuilder2(Action.saveFeeding, client => client.feeding_PutV2(feeding), feeding);
}

function getCorrectAnalysisType(feedingBase: FeedingAdjustmentBase) {
	switch (feedingBase.type) {
		case FeedingAmountType.FeedingAdjustment:
			return FeedingAdjustment.fromJS(feedingBase);
		case FeedingAmountType.FeedingUsage:
			return FeedingUsage.fromJS(feedingBase);

		default:
			return feedingBase as FeedingAdjustmentBase;
	}
}

function sortDataByEarliest(): ((a: FeedingAdjustmentBase, b: FeedingAdjustmentBase) => number) | undefined {
	return (adjustmentA, adjustmentB) => {
		if (
			adjustmentA.type === FeedingAmountType.FeedingAdjustment &&
			adjustmentB.type === FeedingAmountType.FeedingAdjustment
		) {
			if (adjustmentA.date!.getTime() > adjustmentB.date!.getTime()) {
				return 1;
			}
		} else if (
			adjustmentA.type === FeedingAmountType.FeedingUsage &&
			adjustmentB.type === FeedingAmountType.FeedingUsage
		) {
			let periodFromATime = (adjustmentA as FeedingUsage).periodFrom!.getTime();
			let periodFromBTime = (adjustmentB as FeedingUsage).periodFrom!.getTime();
			let periodToATime = (adjustmentA as FeedingUsage).periodTo!.getTime();
			let periodToBTime = (adjustmentB as FeedingUsage).periodTo!.getTime();
			if (periodFromATime >= periodFromBTime) {
				if (
					periodFromATime !== periodFromBTime ||
					(periodFromATime === periodFromBTime && periodToATime > periodToBTime)
				) {
					return 1;
				} else {
					return -1;
				}
			}
		} else if (
			adjustmentA.type === FeedingAmountType.FeedingUsage &&
			adjustmentB.type === FeedingAmountType.FeedingAdjustment
		) {
			return 1;
		}
		return -1;
	};
}
