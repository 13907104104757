import { IPregnancyEvent, IScanned, PregnancyState, Scanned, ScanResult } from 'shared/api/api';

export function sortPregnanciesByDate(pregnancyEvents: IPregnancyEvent[]) {
	if (!pregnancyEvents) {
		return [];
	}

	return [...pregnancyEvents].sort((a, b) => {
		if (b.date!.valueOf() - a.date!.valueOf() === 0) {
			if (
				a.state === PregnancyState.FarrowingInProgress &&
				b.state === PregnancyState.Farrowing &&
				a.pregnancyId === b.pregnancyId
			) {
				return 1;
			} else if (
				a.state === PregnancyState.Farrowing &&
				b.state === PregnancyState.FarrowingInProgress &&
				a.pregnancyId === b.pregnancyId
			) {
				return -1;
			}
			//Makes sure that Scanned events on same date, is in correct order
			//For some reason, the sort method, works different on mobile. So the repeated and almost identical step, is necessary
			if (
				a.state === PregnancyState.Scanned &&
				b.state === PregnancyState.Scanned &&
				a.pregnancyId === b.pregnancyId
			) {
                const first = Scanned.fromJS(a);
                const second = Scanned.fromJS(b);
				if (
					first.result === ScanResult.Maybe &&
					(second.result === ScanResult.True || second.result === ScanResult.False)
				) {
					return 1;
				} else if (
					second.result === ScanResult.Maybe &&
					(first.result === ScanResult.True || first.result === ScanResult.False)
				) {
					return -1;
				}
			}
		}
		return b.date!.valueOf() - a.date!.valueOf();
	});
}

export function sortPregnanciesByDateReversed(pregnancyEvents: IPregnancyEvent[]) {
	return [...sortPregnanciesByDate(pregnancyEvents)].reverse();
}
