import actionCreatorFactory from 'typescript-fsa';
import { GenActionFactoryName, AppError } from 'shared/helpers/redux-helpers';
import {
	IValveGroupUpdate,
	ValveGroupsSyncResponse,
	IValveGroup,
	SyncDataValveGroup,
	ValveGroupUpdate,
	GrowthPigAdjustFeedUpdate,
} from 'shared/api/api';
import * as types from './types';
const actionCreator = actionCreatorFactory(GenActionFactoryName('adjustFeed'));

export const syncAsync = actionCreator.async<ValveGroupUpdate[], ValveGroupsSyncResponse, AppError>(types.SYNC);

export const updateValveGroup = actionCreator<IValveGroupUpdate>(types.UPDATE_VALVE_GROUP);

export const updateValveGroupAPI = actionCreator.async<
	IValveGroupUpdate,
	string,
	{ code: number; message: string; prevEntity: IValveGroup | undefined }
>(types.UPDATE_VALVE_GROUP_API);

export const getSyncData = actionCreator.async<
	{ siteId: string; lastSyncDate: Date; activeSiteId?: string },
	SyncDataValveGroup
>(types.SYNC_DATA_VALVE_GROUPS);

export const setFullSyncSpinner = actionCreator.async<void, boolean>(types.SHOW_FULL_SYNC_SPINNER);

export const setFullSyncNumbers = actionCreator.async<void, types.FullSyncNumbers>(types.SET_FULL_SYNC_NUMBER);

export const fullSyncFailed = actionCreator.async<void, void>(types.FULL_SYNC_FAILED);

export const saveGrowthPigAdjustFeed = actionCreator.async<GrowthPigAdjustFeedUpdate[], string, AppError>(
	types.SAVE_GROWTH_PIG_ADJUST_FEED,
);
