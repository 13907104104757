import React, { CSSProperties } from 'react';
import { Option } from 'react-dropdown';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { GetSyncData as MarkingGetSyncData } from 'shared/state/ducks/markings/operations';
import { localized, localizedDynamic } from 'shared/state/i18n/i18n';
import { WebAppState } from "web/state/store.web";
import { SkioldFormDropdown } from '../skiold-components/skiold-dropdown/skiold-form-dropdown';
import './marking-picker.scss';

interface PropsFromParent {
    onMarkingChanged: (marking?: string) => void;
    marking?: string;
    theme?: 'light' | 'dark';
    placeholderText?: boolean;
    containerStyle?: CSSProperties;
    containerClassName?: string
}

const mapStateToProps = (state: WebAppState) => {
    return {
        markings: state.markings.entities
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
    loadMarkings: () => MarkingGetSyncData()(dispatch)
});

interface State {}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

const DefaultMarkingOptionNoText: Option = { label: ' ', value: ' ' };
const DefaultMarkingOptionText: Option = { label: localized('selectMarking'), value: '' };

class MarkingPicker extends React.PureComponent<Props, State> {
    public static defaultProps: Partial<Props> = {
    };
    private markingOptions: Option[] = [];
    constructor(props: Props) {
        super(props);
        this.props.loadMarkings();
    }

    public render() {
        this.getMarkingOptions();

        return (
            <SkioldFormDropdown
                theme={this.props.theme === 'light' ? 'light' : 'dark'}
                items={this.markingOptions}
                selectedValue={this.findSelectedMarkingOption()}
                onValueChanged={ this.markingChanged}
                containerClassName={`marking-picker ${this.props.containerClassName}`}
            />
        );
    }

    private getDefaultOption() {
        return this.props.placeholderText ? DefaultMarkingOptionText : DefaultMarkingOptionNoText;
    }

    private getMarkingOptions() {
        let markingOptions = [this.getDefaultOption()].concat(
            this.props.markings.map(marking => {
                return {
                    label: marking.translationKey ? localizedDynamic(marking.translationKey) : '',
                    value: marking.translationKey ? marking.translationKey : ''
                };
            })
        );

        this.markingOptions = markingOptions;
    }

    private findSelectedMarkingOption() {
        if (!this.props.marking) {
            return this.getDefaultOption();
        }

        let selectedMarkingOption = this.markingOptions.find(option => option.value === this.props.marking);

        return selectedMarkingOption;
    }

    private  markingChanged = async(markingOption: Option) => {
        this.props.onMarkingChanged(markingOption.value);
    }
}

export default 
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(MarkingPicker)

