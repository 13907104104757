
import actionCreatorFactory from 'typescript-fsa';
import * as types from './types';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';

const actionCreator = actionCreatorFactory(GenActionFactoryName('offline'));

export const saveConnectionStatus = actionCreator.async<boolean, boolean>(types.SAVE_CONNECTION_STATUS);
export const saveTriggerSync = actionCreator.async<boolean, boolean>(types.SAVE_TRIGGER_SYNC);
export const syncStarted = actionCreator(types.SYNC_STARTED);
export const syncFinished = actionCreator(types.SYNC_FINISHED);
