import { Option } from 'react-dropdown';
import { Dispatch } from 'redux';
import { IPregnancyEvent, IScanningListSettingBase, ScanResult, WorkListType } from 'shared/api/api';
import { GetLocations } from 'shared/state/ducks/locations/operations';
import * as moveEventOperation from 'shared/state/ducks/move-events/operations';
import * as pregnancyOperation from 'shared/state/ducks/pregnancy-events/operations';
import { SavePregnancyEvent } from 'shared/state/ducks/pregnancy-events/operations';
import * as stemAnimalOperation from 'shared/state/ducks/stem-animals/operations';
import { SetCheckedCount, SetSowsCount } from 'shared/state/ducks/stem-animals/operations';
import { localized } from 'shared/state/i18n/i18n';
import { SharedAppState } from 'shared/state/store.shared';
import { resetDropdownScrollOptions } from 'shared/state/ducks/general-settings/operations';
import * as workListSettingOperation from 'shared/state/ducks/work-list-settings/operations';
import { memoizeLocation } from 'shared/helpers/memoize-getters/memoize-getters';
import { savePregnancyEvents } from 'shared/state/ducks/pregnancy-events/actions';
export const ScanningWorkListmapStateToProps = (state: SharedAppState) => {
	return {
		workListSettings: state.workListSettings.entities,
	};
};

export const ScanningWorkListmapDispatchToProps = (dispatch: Dispatch, props: {}) => {
	return {};
};

// export const ScanningWorkListStyles = StyleSheet.create({
//     flexRow: { flexDirection: 'row' },
//     containerView: { alignSelf: 'center', width: '100%', flexDirection: 'column' }
// });

export interface ScanningWorkListState {
	workListSetting: IScanningListSettingBase;
}

export interface ScanningWorkListPropsFromParent {
	batch: Option;
	choosedSetting: WorkListType.ScanningListSetting | WorkListType.ScanningListSetting2;
	refCallback: (ref: any) => void;
}
export type ScanningWorkListProps = ReturnType<typeof ScanningWorkListmapStateToProps> &
	ReturnType<typeof ScanningWorkListmapDispatchToProps> &
	ScanningWorkListPropsFromParent;

interface PropsFromParent {
	choosedSetting: WorkListType.ScanningListSetting | WorkListType.ScanningListSetting2;
	workListSetting: IScanningListSettingBase;
	batch: Option;
	setBatchOptions?: (data: ScanningListTableItem[]) => void;
}

export const ScanningListMapStateToProps = (state: SharedAppState) => {
	return {
		pregnancyEvents: state.pregnancyEvents.entities,
		matingBatches: state.matingBatches.entities,
		stemAnimals: state.stemAnimals.entities,
		treatmentPlans: state.treatmentPlans.entities,
		moveEvents: state.moveEvents.entities,
		locations: memoizeLocation(
			state.locations.buildings,
			state.locations.sections,
			state.locations.pens,
			state.locations.valves,
		),
		saveInprogress: state.pregnancyEvents.saveSyncInProgress,
	};
};

export const ScanningListMapDispatchToProps = (dispatch: Dispatch, props: {}) => {
	return {
		setSowsCount: (count: number) => SetSowsCount(count)(dispatch),
		SetCheckedCount: (count: number, reset?: boolean) => SetCheckedCount(count, reset)(dispatch),
		savePregnancyEvent: (pregnancyEvent: IPregnancyEvent) => SavePregnancyEvent(pregnancyEvent)(dispatch),
		getLocations: () => GetLocations()(dispatch),
		pregnancyEventGetSyncData: () => pregnancyOperation.GetSyncData()(dispatch),
		stemAnimalGetSyncData: () => stemAnimalOperation.GetSyncData()(dispatch),
		moveEventGetSyncData: () => moveEventOperation.GetSyncData()(dispatch),
		resetDropdownScrollOptions: () => resetDropdownScrollOptions()(dispatch),
		getWorkListSettingsBySiteId: () => workListSettingOperation.GetSyncData()(dispatch),
		savePregnancyEvents: pregEvents => pregnancyOperation.SavePregnancyEvents(pregEvents)(dispatch),
		stopSavePregsInSync: () => dispatch(savePregnancyEvents.failed({} as any)),
	};
};

export interface ScanningListState {
	columns: any[];
	columnExte: any[];
	groupedColumns: any[];
	loading: boolean;
	workListData: ScanningListTableItem[];
	createTreatmentModal: boolean;
	stemAnimalIdToEdit: string;
	commitAll: boolean;
}

export class ScanningListTableItem {
	public cycleDays: number | undefined;
	public batchNumber: string | undefined;
	public litter: number | undefined;
	public animalNumber: string | undefined;
	public weeks: string | undefined;
	public selectedOption: Option = { label: localized('True'), value: ScanResult.True };
	public pregnancyId: string | undefined;
	public buildingString: string | undefined;
	public penString: string | undefined;
	public sectionString: string | undefined;
	public isChecked: boolean | undefined;
	public stemAnimalId: string | undefined;
	public hasRetentionTime: boolean | undefined;

	// these props are used for overwriting scanned
	public id: string | undefined;
	public createdOn: Date | undefined;
	public order?: number | undefined;
}

export type ScanningListProps = ReturnType<typeof ScanningListMapStateToProps> &
	ReturnType<typeof ScanningListMapDispatchToProps> &
	PropsFromParent;
