import { Sorting } from '@devexpress/dx-react-grid';
import memoize from 'memoize-one';
import moment from 'moment';
import React from 'react';
import { Option } from 'react-dropdown';
import isEqual from 'react-fast-compare';
import { connect } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { Dispatch } from 'redux';
import {
	AnimalType,
	Equipment,
	IProcessEquipment,
	IRetryAdjustFeedTableItem,
	ValveFieldUpdateDto,
} from 'shared/api/api';
import DeleteIcon from 'shared/assets/src-assets/png/delete_icon_grey.png';
import DownloadDataGrey from 'shared/assets/src-assets/png/download_data_grey.png';
import PrinterGreyIcon from 'shared/assets/src-assets/png/printer_ikon_grey.png';
import sendDataGrey from 'shared/assets/src-assets/png/send_data_grey.png';
import { getDateString } from 'shared/helpers/date-helpers';
import { exactFilterMethod } from 'shared/helpers/general-helpers';
import { memoizeGetDistriwinProcessEquipment } from 'shared/helpers/memoize-getters/memoize-getters';
import { NaturalSortDates } from 'shared/helpers/natural-sort';
import { fetchNewestDataFromDistriwin, SetFullSyncSpinner } from 'shared/state/ducks/adjust-feed/operations';
import { GetLocations } from 'shared/state/ducks/locations/operations';
import { RetryAdjustFeedItemApp } from 'shared/state/ducks/retry-adjust-feed';
import {
	DeleteRetryAdjustFeedUpdates,
	GetRetryAdjustFeedTableData,
	RetryAdjustFeedUpdates,
	SetRetryAdjustFeedCommitStatus,
	UpdateCommitStatusOnAll,
} from 'shared/state/ducks/retry-adjust-feed/operations';
import { GetByProfileAsync } from 'shared/state/ducks/site/operations';
import { SetSowsCount } from 'shared/state/ducks/stem-animals/operations';
import { localized, localizedDynamic } from 'shared/state/i18n/i18n';
import styled from 'styled-components';
import { WebAppState } from 'web/state/store.web';
import PageContainer from 'web/view/components/page-container/page-container';
import { showAlert, ShowConfirmAlert } from 'web/view/components/skiold-alert/skiold-alert';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldCheckbox } from 'web/view/components/skiold-components/skiold-checkbox/skiold-checkbox';
import { SkioldDropdown } from 'web/view/components/skiold-components/skiold-dropdown/skiold-dropdown';
import SkioldTableGrid, {
	SkioldTableGrid as SkioldTableRef,
} from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { GroupedHeader } from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid-grouped-header';
import { SkioldTouchableOpacity } from 'web/view/components/skiold-components/skiold-touchable-opacity';
import { SowListConstants } from 'web/view/components/stem-animal/animal-lists/table-constants';
import { WhiteText } from 'web/view/components/Text/white-text';
import { Heading } from 'web/view/components/utils/heading';
import { SkioldIconSpinner } from 'web/view/components/utils/skiold-icon-spinner';
import { SkioldIconSpinnerControlled } from 'web/view/components/utils/skiold-icon-spinner-controlled';
import { SkioldImage } from 'web/view/components/utils/svg/skiold-image';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { PrintRetryAdjustFeed } from 'web/web-helpers/pdf-helper/retry-adjust-feed-helper';
import './retry-adjust-feed.scss';

const OuterDiv = styled.div`
	margin: 16px 0;
`;

const mapStateToProps = (state: WebAppState) => {
	let peqs = memoizeGetDistriwinProcessEquipment(state.processEquipments.entities);
	let options = memoize((peqs: IProcessEquipment[]) => {
		return peqs.map(peq => {
			return { label: peq.name ? peq.name : Equipment[peq.equipment as any], value: peq.id } as Option;
		});
	});
	return {
		profile: state.profile.active!,
		site: state.site.site,
		navigation: state.navigation,
		retryAdjustFeedItems: state.retryAdjustFeed.retryAdjustFeedItems,
		commitAll: state.retryAdjustFeed.commitAll,
		fullSyncSpinner: state.adjustFeed.showFullSyncSpinner,
		fullSyncNumbers: state.adjustFeed.fullSyncNumbers,
		fullSyncFailed: state.adjustFeed.fullSyncFailed,
		processEquipmentDistriwin: peqs,
		processEquipmentDistriwinOptions: options(peqs),
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => {
	return {
		setSowsCount: (count: number) => SetSowsCount(count)(dispatch),
		getTableData: (pigType: AnimalType[]) => GetRetryAdjustFeedTableData(pigType)(dispatch),
		retryAdjustFeed: (a: RetryAdjustFeedItemApp) => RetryAdjustFeedUpdates(a)(dispatch),
		deleteRetryAdjustFeedUpdates: async (a: RetryAdjustFeedItemApp) => DeleteRetryAdjustFeedUpdates(a)(dispatch),
		setRetryAdjustFeedCommitStatus: (a: RetryAdjustFeedItemApp) => SetRetryAdjustFeedCommitStatus(a)(dispatch),
		updateCommitStatusOnAll: (commitAll: boolean) => UpdateCommitStatusOnAll(commitAll)(dispatch),
		loadLocations: () => GetLocations()(dispatch),
		fetchNewestDataFromDistriwin: (processEquipmentId: string) =>
			fetchNewestDataFromDistriwin(processEquipmentId)(dispatch),
		setSpinner: (show: boolean) => SetFullSyncSpinner(show)(dispatch),
		getSite: (profileId: string) => GetByProfileAsync(profileId)(dispatch),
	};
};

export interface State {
	data: retryAdjustFeedItem[];
	columns: any[];
	columnsExte: any[];
	attemptingToUpdateAll: boolean;
	commitAll: boolean;
	type: AnimalType[];
	fullSyncFailed: boolean;
	selectedProcessEquipment: IProcessEquipment | undefined;
	selectedProcessEquipmentOption: Option | undefined;
}

// tslint:disable-next-line: class-name
export interface retryAdjustFeedItem extends IRetryAdjustFeedTableItem {
	ids: string[];
	shouldBeCommited: boolean;
	showSpinner: boolean;
	status: boolean;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
export class RetryAdjustFeed extends React.PureComponent<Props, State> {
	public SkioldTableRef: SkioldTableRef | undefined;
	constructor(props: Props) {
		super(props);

		this.state = {
			data: [],
			columns: this.generateColumns(this.props.navigation.query!.type!.includes('Sow')),
			columnsExte: this.getColumnExtensions(this.props.navigation.query!.type!.includes('Sow')),
			commitAll: false,
			attemptingToUpdateAll: false,
			type: Array.isArray(this.props.navigation.query!.type)
				? this.props.navigation.query!.type
				: [this.props.navigation.query!.type as any],
			fullSyncFailed: false,
			selectedProcessEquipment:
				props.processEquipmentDistriwin && props.processEquipmentDistriwin.length > 0
					? props.processEquipmentDistriwin[0]
					: undefined,
			selectedProcessEquipmentOption:
				props.processEquipmentDistriwin && props.processEquipmentDistriwin.length > 0
					? ({
							label: props.processEquipmentDistriwin[0].name,
							value: props.processEquipmentDistriwin[0].id,
					  } as Option)
					: undefined,
		};
	}

	public async componentDidMount() {
		if (this.props.profile.id) {
			await this.props.getSite(this.props.profile.id);
		}

		await this.props.loadLocations();
		await this.props.getTableData(this.state.type);
	}

	public componentWillUnmount() {
		if (this.props.fullSyncSpinner) {
			this.props.setSpinner(false);
		}
	}

	public async componentDidUpdate(prevProps: Props, prevState: State) {
		if (!prevProps.fullSyncFailed && this.props.fullSyncFailed) {
			showAlert(
				`${localized('ERROR')} \n ${prevProps.fullSyncNumbers.fullSyncCurrent} ${localized('outOf')} ${
					prevProps.fullSyncNumbers.fullSyncTotal
				}  ${localized('wentThrough')}. \n ${localized('fullSyncFailedMessage')}`,
			);
		}

		if (!isEqual(prevProps.navigation.query!.type, this.props.navigation.query!.type)) {
			const type = Array.isArray(this.props.navigation.query!.type)
				? this.props.navigation.query!.type
				: [this.props.navigation.query!.type as any];

			this.setState({
				columns: this.generateColumns(this.props.navigation.query!.type!.includes('Sow')),
				type,
			});

			await this.props.getTableData(type);
		}
	}

	public cancelFullSync = () => {
		if (this.props.fullSyncSpinner) {
			this.props.setSpinner(false);
		}
	};

	public fetchDataFromDistriwin = async () => {
		if (
			!this.props.fullSyncSpinner &&
			this.state.selectedProcessEquipment &&
			this.state.selectedProcessEquipment.id
		) {
			if (await ShowConfirmAlert(localized('FetchDataFromDistriwinConfirmDialog'))) {
				await this.props.fetchNewestDataFromDistriwin(this.state.selectedProcessEquipment.id);
				await this.props.loadLocations();
				await this.props.getTableData(this.state.type);
			}
		}
	};

	public getLastestUpdate(
		valveFieldUpdateDto: ValveFieldUpdateDto[],
		firstAttemptedOnDate: Date,
		retryAdjustFeed: retryAdjustFeedItem[],
	) {
		let d = valveFieldUpdateDto.find(k => k.firstAttemptedOn!.toString() === firstAttemptedOnDate.toString());
		let index = retryAdjustFeed.findIndex(raf => d!.location === raf.location);

		return { index, d };
	}

	public findDataToBeDeleted(valveFieldUpdateDto: ValveFieldUpdateDto[], firstAttemptedOnDate: Date) {
		return valveFieldUpdateDto.filter(k => k.firstAttemptedOn!.toString() !== firstAttemptedOnDate.toString());
	}

	public findLastestEventDate(valveFieldUpdateDto: ValveFieldUpdateDto[]) {
		return new Date(
			Math.max.apply(
				null,
				valveFieldUpdateDto.map(e => {
					return new Date(e.firstAttemptedOn!).getTime();
				}),
			),
		);
	}

	private groupedColumns: GroupedHeader[] = [
		{
			title: localized('location'),
			children: [
				{ columnName: 'building' },
				{ columnName: 'section' },
				{ columnName: 'pen' },
				{ columnName: 'valve' },
			],
		},
	];

	private getColumnExtensions(isSow: boolean) {
		return [
			{ columnName: 'building', width: SowListConstants.sectionWidth },
			{ columnName: 'section', width: SowListConstants.sectionWidth },
			{ columnName: 'pen', width: SowListConstants.sectionWidth },
			{ columnName: 'valve', width: SowListConstants.indexWidth },
			{ columnName: 'Animals', width: SowListConstants.indexWidth },
			...(!isSow
				? [
						{
							columnName: 'weight',
							width: SowListConstants.indexWidth,
						},
				  ]
				: []),
			{ columnName: 'feedingCurve', width: SowListConstants.animalNrWidth },
			...(isSow
				? [
						{
							columnName: 'sowCycle',
							width: SowListConstants.animalNrWidth,
						},
				  ]
				: []),
			...(isSow
				? [
						{
							columnName: 'penDays',
							width: SowListConstants.indexWidth,
						},
				  ]
				: []),
			{ columnName: 'fixedValue', width: SowListConstants.indexWidth },
			{ columnName: 'temporaryModulationDays', width: SowListConstants.animalNrWidth },
			{ columnName: 'Date', width: SowListConstants.dateWidth },
			{ columnName: 'time', width: SowListConstants.indexWidth },
			{ columnName: 'completed', width: SowListConstants.sectionWidth },
		];
	}

	private defaultSorting = [{ columnName: 'building', direction: 'desc' }] as Sorting[];

	public render() {
		return (
			<PageContainer className={'retry-adjust-feed'}>
				<Heading text={localized('retryAdjustFeed')} />

				<ViewWeb className={'viewContainerStyle'}>
					<ViewWeb className={'viewContentStyle'}>
						<ViewWeb className={'end sizing verticalCenter'}>
							{this.renderButtonsForRetryAdjustFeed()}
						</ViewWeb>
					</ViewWeb>

					<OuterDiv className={'centerTable'}>
						<ViewWeb className={'maxWidthForTable'}>
							<SkioldTableGrid
								ref={this.setRef}
								columns={this.state.columns}
								ColumnExtensions={this.state.columnsExte}
								tableKey={'retryAdjustFeed' + this.props.navigation.query!.type!.includes('Sow')}
								className={'work-list-table'}
								groupedColumns={this.groupedColumns}
								sortHeaderId={this.defaultSorting}
								data={this.props.retryAdjustFeedItems}
							/>
						</ViewWeb>
					</OuterDiv>
				</ViewWeb>
			</PageContainer>
		);
	}

	public retryMarked = async () => {
		let listOfPromises: Array<Promise<void | ValveFieldUpdateDto[]>> = [];

		for (const reAdjustFeedItem of this.props.retryAdjustFeedItems) {
			if (reAdjustFeedItem.shouldBeCommited) {
				let promise = this.props.retryAdjustFeed(reAdjustFeedItem);
				listOfPromises.push(promise);
			}
		}
		this.setState({ attemptingToUpdateAll: true });
		await Promise.all(listOfPromises);
		this.setState({ attemptingToUpdateAll: false });
	};

	public async deleteMarked() {
		let listOfPromises: Array<Promise<void>> = [];
		this.props.retryAdjustFeedItems.forEach(async a => {
			if (a.shouldBeCommited === true) {
				let promise = this.props.deleteRetryAdjustFeedUpdates(a);
				listOfPromises.push(promise);
			}
		});
		this.setState({ attemptingToUpdateAll: true });
		await Promise.all(listOfPromises);
		this.setState({ attemptingToUpdateAll: false });
	}

	private setRef = (s: any) => {
		if (s) {
			this.SkioldTableRef = s;
		}
	};

	private processEquipmentChanged = (option: Option) => {
		let peq = this.props.processEquipmentDistriwin.find(pe => pe.id === option.value);
		this.setState({ selectedProcessEquipment: peq, selectedProcessEquipmentOption: option });
	};

	private renderButtonsForRetryAdjustFeed() {
		return (
			<ViewWeb className={'flexDirectionRow'}>
				<SkioldDropdown
					onValueChanged={this.processEquipmentChanged}
					items={this.props.processEquipmentDistriwinOptions}
					selectedValue={this.state.selectedProcessEquipmentOption}
					containerClassName="process-equipment-picker"
					menuClassname="dropdown-menu-container"
				/>
				{this.props.fullSyncSpinner && (
					<ViewWeb className="cancel-container">
						<SkioldButton
							className="cancel-full-sync-button"
							title={localized('Cancel')}
							onPress={this.cancelFullSync}
						/>
					</ViewWeb>
				)}
				<ViewWeb className={'alignItemsCenter'}>
					<SkioldIconSpinnerControlled
						title={
							this.props.fullSyncSpinner
								? `${this.props.fullSyncNumbers.fullSyncCurrent} ${localized('outOf')} ${
										this.props.fullSyncNumbers.fullSyncTotal
								  }`
								: 'fetchDataFromDistriwin'
						}
						icon={DownloadDataGrey}
						onPress={this.fetchDataFromDistriwin}
						showSpinner={this.props.fullSyncSpinner}
					/>
				</ViewWeb>
				<ViewWeb className={'buttonViewStyle'} />
				<ViewWeb className={'alignItemsCenter'}>
					<SkioldIconSpinner
						title={'PrintList'}
						icon={PrinterGreyIcon}
						onPress={async () => {
							await PrintRetryAdjustFeed(
								this.SkioldTableRef!.GetSortedData(),
								this.props.profile.siteId!,
								this.props.profile.language!,
								this.state.type.includes(AnimalType.Sow),
							);
						}}
					/>
				</ViewWeb>

				<ViewWeb className={'buttonViewStyle'} />
				<ViewWeb className={'alignItemsCenter'}>
					<SkioldTouchableOpacity onPress={this.retryMarked}>
						<SkioldImage width="60" height="60" imageData={sendDataGrey} />
					</SkioldTouchableOpacity>
					<WhiteText>{localized('sendMarked')}</WhiteText>
				</ViewWeb>
				<ViewWeb className={'buttonViewStyle'} />
				<ViewWeb className={'alignItemsCenter'}>
					<SkioldTouchableOpacity
						onPress={async () => {
							if (await ShowConfirmAlert(localized('SureDeleteMarked'))) {
								this.deleteMarked();
							}
						}}
					>
						<SkioldImage width="60" height="60" imageData={DeleteIcon} />
					</SkioldTouchableOpacity>
					<WhiteText>{localized('deleteMarked')}</WhiteText>
				</ViewWeb>
			</ViewWeb>
		);
	}

	private generateColumns(isSow: boolean) {
		const columns = [
			{
				name: 'building',
				title: localized('building'),
				width: SowListConstants.sectionWidth,
				getCellValue: (d: RetryAdjustFeedItemApp) => d.building,
			},
			{
				name: 'section',
				title: localized('section'),
				width: SowListConstants.sectionWidth,
				getCellValue: (d: RetryAdjustFeedItemApp) => d.section,
			},
			{
				name: 'pen',
				title: localized('pen'),
				filterable: false,
				width: SowListConstants.sectionWidth,
				getCellValue: (d: RetryAdjustFeedItemApp) => <ViewWeb>{this.generateTextFields(d.pens)}</ViewWeb>,
			},
			{
				name: 'valve',
				title: localized('Valve'),
				width: SowListConstants.indexWidth,
				getCellValue: (d: RetryAdjustFeedItemApp) => d.valve,
			},
			{
				name: 'Animals',
				title: localized('Count'),
				headerClassName: 'merged-header',
				width: SowListConstants.indexWidth,
				getCellValue: (d: RetryAdjustFeedItemApp) => d.pigCount,
			},
			...(isSow === false
				? [
						{
							name: 'weight',
							title: localized('Weight'),
							headerClassName: 'merged-header',
							width: SowListConstants.indexWidth,
							getCellValue: (d: RetryAdjustFeedItemApp) => d.weight,
						},
				  ]
				: []),
			{
				name: 'feedingCurve',
				title: localized('FEEDINGCURVE'),
				headerClassName: 'merged-header',
				width: SowListConstants.animalNrWidth,
				getCellValue: (d: RetryAdjustFeedItemApp) => d.feedingCurve,
			},
			...(isSow
				? [
						{
							name: 'sowCycle',
							title: localized('condition'),
							headerClassName: 'merged-header',
							width: SowListConstants.animalNrWidth,
							getCellValue: (d: RetryAdjustFeedItemApp) => localizedDynamic(d.sowCycle!),
						},
				  ]
				: []),

			...(isSow
				? [
						{
							name: 'penDays',
							title: localized('Days'),
							filterMethod: exactFilterMethod(),
							width: SowListConstants.indexWidth,
							getCellValue: (d: RetryAdjustFeedItemApp) => d.penDays,
						},
				  ]
				: []),

			{
				name: 'fixedValue',
				title: localized('FIXEDPERCENT'),
				headerClassName: 'merged-header',
				width: SowListConstants.indexWidth,
				getCellValue: (d: RetryAdjustFeedItemApp) => d.fixedValue,
			},
			{
				name: 'temporaryModulationDays',
				headerClassName: 'merged-header',
				title: localized('DEVIATIONDAYSPERCENT'),
				width: SowListConstants.animalNrWidth,
				getCellValue: (d: RetryAdjustFeedItemApp) =>
					(d.variableModulation ? d.variableModulation : '') +
					(d.variableModulation && d.variableModulationDays ? ' - ' : '') +
					(d.variableModulationDays ? d.variableModulationDays : ''),
			},
			{
				name: 'Date',
				title: localized('Date'),
				width: SowListConstants.sectionWidth,
				headerClassName: 'merged-header',
				sortFunction: NaturalSortDates,
				getCellValue: (d: RetryAdjustFeedItemApp) => getDateString(d.date),
			},
			{
				name: 'time',
				title: localized('Time'),
				headerClassName: 'merged-header',
				width: SowListConstants.indexWidth,
				getCellValue: (d: RetryAdjustFeedItemApp) => moment(d.date!).format('HH:mm'),
			},

			{
				name: 'completed',
				width: SowListConstants.sectionWidth,
				title: ' ',
				filter: () => (
					<ViewWeb className={'CheckboxViewStyle'}>
						<SkioldCheckbox
							isChecked={this.props.commitAll}
							className={'checkboxStyle'}
							onClick={() => {
								if (this.state.attemptingToUpdateAll === false) {
									this.props.updateCommitStatusOnAll(this.props.commitAll);
								}
							}}
						/>
					</ViewWeb>
				),
				headerClassName: 'merged-header',
				sortable: false,
				getCellValue: (d: RetryAdjustFeedItemApp) => (
					<ViewWeb className={'flexAndDirectionRow'}>
						<ViewWeb className={'CheckboxViewStyle'}>
							<SkioldCheckbox
								isChecked={d.shouldBeCommited}
								className={'checkboxStyle'}
								onClick={async () => {
									if (this.state.attemptingToUpdateAll === false) {
										this.props.setRetryAdjustFeedCommitStatus(d);
									}
								}}
							/>
						</ViewWeb>
						{d.showSpinner === true && (
							<ViewWeb className={'spinnerViewStyle'}>
								<ClipLoader color="#f2ac40" size={25} />
							</ViewWeb>
						)}
					</ViewWeb>
				),
			},
		];

		return columns;
	}
	private generateTextFields(textArray: string[] | undefined) {
		let listOfTextFields: JSX.Element[] = [];
		if (textArray !== undefined) {
			textArray.forEach((text, index) => {
				listOfTextFields.push(<div key={index}> {text}</div>);
			});
		}
		return listOfTextFields;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(RetryAdjustFeed);
