
import { Dispatch } from 'redux';
import * as Action from './actions';

export function SaveConnectionStatus(isConnected: boolean) {
    return (dispatch: Dispatch<any>) => {
        dispatch(Action.saveConnectionStatus.done({ params: isConnected, result: isConnected }));
    };
}

export function SaveTriggerSync(isTriggered: boolean) {
    return (dispatch: Dispatch<any>) => {
        dispatch(Action.saveTriggerSync.done({ params: isTriggered, result: isTriggered }));
    };
}

export function SyncStarted() {
    return (dispatch: Dispatch<any>) => {
        dispatch(Action.syncStarted());
    };
}

export function SyncFinished() {
    return (dispatch: Dispatch<any>) => {
        dispatch(Action.syncFinished());
    };
}
