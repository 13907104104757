import moment from 'moment';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IDistriwinFeedRegistration } from 'shared/api/api';
import { getTimeDateString } from 'shared/helpers/date-helpers';
import { GetDistriwinFeedRegistrations } from 'shared/state/ducks/distriwin-feed-registrations/operations';
import { selectSiteId } from 'shared/state/ducks/site/selectors';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import PageContainer from 'web/view/components/page-container/page-container';
import { SkioldFetch } from 'web/view/components/skiold-components/skiold-fetch/skiold-fetch';
import SkioldTableGrid from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { SowListConstants } from 'web/view/components/stem-animal/animal-lists/table-constants';
import { Heading } from 'web/view/components/utils/heading';
import { ViewWeb } from 'web/view/components/utils/web-view';

interface PropsFromParent {}

export const DistriwinFeedRegistrationOverview: FC<PropsFromParent> = React.memo(() => {
	const dispatch = useDispatch();

	// Get data
	const { distriwinFeedRegistrations } = useSelector((state: WebAppState) => state.distriwinFeedRegistrations);
	const siteId = useSelector(selectSiteId);
	const initFromDate = moment().subtract(7, 'days').toDate().withoutTime();
	const initToDate = new Date().endOfDayTime();
	useEffect(() => {
		GetDistriwinFeedRegistrations(siteId, initFromDate, initToDate)(dispatch);
		//eslint-disable-next-line
	}, [siteId, dispatch]);

	const fetchFeedRegistrations = (fromDate, toDate) => {
		GetDistriwinFeedRegistrations(siteId, fromDate, toDate)(dispatch);
	};

	const generateColumns = [
		{
			name: 'dateRegistration',
			title: localized('Date'),
			getCellValue: (item: IDistriwinFeedRegistration) => getTimeDateString(item.dateRegistration),
		},
		{
			name: 'location',
			title: localized('location'),
		},
		{
			name: 'energyRegistrationValue',
			title: localized('Energy'),
		},
		{
			name: 'feedRegistrationValue',
			title: localized('Feed'),
		},
		{
			name: 'quantityPig',
			title: localized('Quantity'),
		},
		{
			name: 'energyPig',
			title: localized('EnergyPrPig'),
		},
		{
			name: 'curveRation',
			title: localized('CurveRation'),
		},
		{
			name: 'weight',
			title: localized('Weight'),
		},
		{
			name: 'penDays',
			title: localized('PenDays'),
		},
	];

	const generateColumnExtes = [
		{
			columnName: 'dateRegistration',
			width: 200,
		},
		{
			columnName: 'location',
			width: 250,
		},
		{
			columnName: 'energyRegistrationValue',
			width: SowListConstants.dateWidth,
		},
		{
			columnName: 'feedRegistrationValue',
			width: SowListConstants.dateWidth,
		},
		{
			columnName: 'quantityPig',
			width: SowListConstants.dateWidth,
		},
		{
			columnName: 'energyPig',
			width: 200,
		},
		{
			columnName: 'curveRation',
			width: 200,
		},
		{
			columnName: 'weight',
			width: SowListConstants.dateWidth,
		},
		{
			columnName: 'penDays',
			width: SowListConstants.dateWidth,
		},
	];

	return (
		<PageContainer className="dashboardOverview animals">
			<Heading text={localized('ADMIN_FEED_REGISTRATIONS')} />
			<SkioldFetch
				fromDate={initFromDate}
				toDate={initToDate}
				fetchData={fetchFeedRegistrations}
				isControlled={false}
			/>

			<ViewWeb className="align-items-center">
				<SkioldTableGrid
					ignoreSetCount={true}
					ColumnExtensions={generateColumnExtes}
					columns={generateColumns}
					data={distriwinFeedRegistrations}
					tableKey={'DistriwinFeedRegistrations'}
				/>
			</ViewWeb>
		</PageContainer>
	);
});
