import { Dispatch } from 'redux';
import { IStemAnimal, IMoveEvent } from 'shared/api/api';
import { SetSowsCount } from 'shared/state/ducks/stem-animals/operations';
import { WebAppState } from 'web/state/store.web';

export const MoveEventListMapStateToProps = (state: WebAppState) => {
	return {
		moveEvents: state.moveEvents.entities,
		stemAnimals: state.stemAnimals.entities,
		departuredSows: state.stemAnimals.departuredAnimals as IStemAnimal[],
		locations: state.locations,
		siteId: state.profile.active && state.profile.active.siteId,
		generalSettings: state.generalSettings.entity,
		userProfiles: state.profile.entities,
	};
};

export const MoveEventListMapDispatchToProps = (dispatch: Dispatch, props: {}) => {
	return {
		setSowsCount: (count: number) => SetSowsCount(count)(dispatch),
	};
};

interface PropsFromParent {
	dateFrom: Date;
	dateTo: Date;
	onSummaryChanged: (element: JSX.Element) => void;
}

export interface MoveEventListState {
	commitAll: boolean;
	loading: boolean;
	moveEvents: IMoveEvent[];
	listItems: MoveEventListItem[];
}

//Move
export class MoveEventListItem {
	public createdDate: Date | undefined;
	public date: Date | undefined;
	public stemAnimalId: string | undefined;
	public animalNumber: string | undefined;
	public fromBuildingName: string | undefined;
	public fromSectionName: string | undefined;
	public fromPenName: string | undefined;
	public toBuildingName: string | undefined;
	public toSectionName: string | undefined;
	public toPenName: string | undefined;
	public usesSectionsFrom: boolean | undefined;
	public usesSectionsTo: boolean | undefined;
	public usesPensFrom: boolean | undefined;
	public usesPensTo: boolean | undefined;
	public createdBy: string | undefined;
}

export class MoveSummaryItem {
	public sowCount?: string;
}

export type MoveEventListProps = ReturnType<typeof MoveEventListMapStateToProps> &
	ReturnType<typeof MoveEventListMapDispatchToProps> &
	PropsFromParent;
