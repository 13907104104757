import { Option } from 'react-dropdown';
import { Dispatch } from 'redux';
import { ILardScanningEvent, ILardScanningEventListSettingBase, WorkListType } from 'shared/api/api';
import { resetDropdownScrollOptions } from 'shared/state/ducks/general-settings/operations';
import { SaveLardScanningEvent } from 'shared/state/ducks/lardScanningEvents/operations';
import { GetLocations } from 'shared/state/ducks/locations/operations';
import * as moveEventOperation from 'shared/state/ducks/move-events/operations';
import * as pregnancyOperation from 'shared/state/ducks/pregnancy-events/operations';
import * as stemAnimalOperation from 'shared/state/ducks/stem-animals/operations';
import { SetCheckedCount, SetSowsCount } from 'shared/state/ducks/stem-animals/operations';
import * as workListSettingOperation from 'shared/state/ducks/work-list-settings/operations';
import { SharedAppState } from 'shared/state/store.shared';
import { memoizeAllLardEvents, memoizeLocation } from 'shared/helpers/memoize-getters/memoize-getters';
import * as lardScanningsOperation from 'shared/state/ducks/lardScanningEvents/operations';

export const LardScanningEventWorkListmapStateToProps = (state: SharedAppState) => {
	return {
		workListSettings: state.workListSettings.entities,
	};
};

export const LardScanningEventWorkListmapDispatchToProps = (dispatch: Dispatch, props: {}) => {
	return {};
};

// export const ScanningWorkListStyles = StyleSheet.create({
//     flexRow: { flexDirection: 'row' },
//     containerView: { alignSelf: 'center', width: '100%', flexDirection: 'column' }
// });

export interface LardScanningEventWorkListState {
	workListSetting: ILardScanningEventListSettingBase;
}

export interface LardScanningEventWorkListPropsFromParent {
	choosedSetting: WorkListType.LardScanningEventListSetting | WorkListType.LardScanningEventListSetting2;
	batch: Option;
	refCallback: (ref: any) => void;
}
export type LardScanningEventWorkListProps = ReturnType<typeof LardScanningEventWorkListmapStateToProps> &
	ReturnType<typeof LardScanningEventWorkListmapDispatchToProps> &
	LardScanningEventWorkListPropsFromParent;

interface PropsFromParent {
	choosedSetting: WorkListType.LardScanningEventListSetting | WorkListType.LardScanningEventListSetting2;
	workListSetting: ILardScanningEventListSettingBase;
	batch: Option;
}

export const LardScanningEventListMapStateToProps = (state: SharedAppState) => {
	return {
		pregnancyEvents: state.pregnancyEvents.entities,
		stemAnimals: state.stemAnimals.entities,
		treatmentPlans: state.treatmentPlans.entities,
		moveEvents: state.moveEvents.entities,
		locations: memoizeLocation(
			state.locations.buildings,
			state.locations.sections,
			state.locations.pens,
			state.locations.valves,
		),
		matingBatches: state.matingBatches.entities,
		siteId: state.profile.active ? state.profile.active.siteId : undefined,
		lardScanningEvents: memoizeAllLardEvents(
			state.lardScanningEvents.entities,
			state.lardScanningEvents.departuredLardEvents,
		),
	};
};

export const LardScanningEventListMapDispatchToProps = (dispatch: Dispatch, props: {}) => {
	return {
		setSowsCount: (count: number) => SetSowsCount(count)(dispatch),
		SetCheckedCount: (count: number, reset?: boolean) => SetCheckedCount(count, reset)(dispatch),
		saveLardScanningEvent: (lardScanningEvent: ILardScanningEvent) =>
			SaveLardScanningEvent(lardScanningEvent)(dispatch),
		getLocations: () => GetLocations()(dispatch),
		pregnancyEventGetSyncData: () => pregnancyOperation.GetSyncData()(dispatch),
		stemAnimalGetSyncData: () => stemAnimalOperation.GetSyncData()(dispatch),
		moveEventGetSyncData: () => moveEventOperation.GetSyncData()(dispatch),
		resetDropdownScrollOptions: () => resetDropdownScrollOptions()(dispatch),
		getWorkListSettingsBySiteId: () => workListSettingOperation.GetSyncData()(dispatch),
		lardScanningsGetSyncData: () => lardScanningsOperation.GetSyncData()(dispatch),
	};
};

export interface LardScanningEventListState {
	columns: any[];
	columnExte: any[];

	matingBatchOptions: Option[];
	SelectedMatingBatch: Option;
	loading: boolean;
	workListData: LardScanningEventListTableItem[];
	createTreatmentModal: boolean;
	stemAnimalIdToEdit: string;
	commitAll: boolean;
}

export class LardScanningEventListTableItem {
	public cycleDays: number | undefined;
	public litter: number | undefined;
	public animalNumber: string | undefined;
	public date: Date | undefined;
	public lardWidth: number | undefined;
	public isChecked: boolean | undefined;
	public stemAnimalId: string | undefined;
	public hasRetentionTime: boolean | undefined;
	public batchNumber: string | undefined;
	public weekNumber: number | undefined;
	public order?: number | undefined;
}

export type LardScanningEventListProps = ReturnType<typeof LardScanningEventListMapStateToProps> &
	ReturnType<typeof LardScanningEventListMapDispatchToProps> &
	PropsFromParent;
