import * as types from './types';
import actionCreatorFactory from 'typescript-fsa';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import { SyncDataLocations, IValve, IPen, ISection, IBuilding } from 'shared/api/api';
import { CurrentLocation } from './types';

const actionCreator = actionCreatorFactory(GenActionFactoryName('locations'));

export const load = actionCreator.async<
	{ siteId: string; lastSyncDate: Date; activeSiteId?: string },
	SyncDataLocations
>(types.LOAD);

export const upsertValve = actionCreator.async<IValve, string, { code: number; message: string; prevEntity: IValve }>(
	types.UPSERT_VALVE,
);
export const upsertPen = actionCreator.async<IPen, string, { code: number; message: string; prevEntity: IPen }>(
	types.UPSERT_PEN,
);
export const upsertSection = actionCreator.async<
	ISection,
	string,
	{ code: number; message: string; prevEntity: ISection }
>(types.UPSERT_SECTION);
export const upsertBuilding = actionCreator.async<
	types.Building,
	string,
	{ code: number; message: string; prevEntity: IBuilding }
>(types.UPSERT_BUILDING);

export const removeValve = actionCreator.async<string, void, { code: number; message: string }>(types.REMOVE_VALVE);
export const removePen = actionCreator.async<string, void, { code: number; message: string }>(types.REMOVE_PEN);
export const removeSection = actionCreator.async<string, void, { code: number; message: string }>(types.REMOVE_SECTION);
export const removeBuilding = actionCreator.async<string, void, { code: number; message: string }>(
	types.REMOVE_BUILDING,
);

export const setCurrentLocation = actionCreator.async<CurrentLocation, {}, { code: number; message: string }>(
	types.SET_CURRENT_LOCATION,
);

export const setUseCurrentLocation = actionCreator<boolean>(types.SET_USE_CURRENT_LOCATION);

export const setRetentionTimeOnPens = actionCreator.async<types.Pen[], void, { code: number; message: string }>(
	types.SET_RETENTION_TIME_ON_PENS,
);

