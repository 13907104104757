import React from 'react';
import { SkioldFormInput } from '../skiold-input/skiold-form-input';
import { IntegerChanged } from 'shared/helpers/number-helper';
import { isNullOrUndefined } from 'shared/helpers/general-helpers';

export interface PropsFromParent {
	onChangeNumber: (newText: number | undefined, itemFromParent?: any) => void;
	itemFromParent?: any;
	text?: number;
	style?: any;
	className?: string;
	editable?: boolean;
	isNegative?: boolean;
	isPostive?: boolean;
	maxNumber?: number;
	autoFocus?: boolean;
	selectTextOnFocus?: boolean;
	onBlur?: () => void;
}

export class SkioldFormIntegerInput extends React.PureComponent<PropsFromParent> {
	public static defaultProps: Partial<PropsFromParent> = {
		isPostive: true,
	};
	public input: any | null | undefined;
	public render() {
		return (
			<SkioldFormInput
				editable={this.props.editable}
				onChangeText={this.onTextChanged}
				text={isNullOrUndefined(this.props.text) ? '' : this.props.text + ''}
				style={this.props.style}
				className={this.props.className}
				autoFocus={this.props.autoFocus}
				selectTextOnFocus={this.props.selectTextOnFocus}
				ref={ref => {
					this.input = ref;
				}}
				onBlur={this.props.onBlur}
			/>
		);
	}

	private regexToUse =
		this.props.isPostive && this.props.isNegative
			? /[^-?\d*{0,1}\d]/g
			: this.props.isPostive
			? /[^\d]/g
			: /[^-\d]/g;

	private onTextChanged = (newText: string) => {
		let textstring = newText.replace(this.regexToUse, '');

		IntegerChanged(textstring, valueToSet => {
			if (valueToSet !== undefined && this.props.maxNumber !== undefined && valueToSet > this.props.maxNumber) {
				this.props.onChangeNumber(this.props.maxNumber, this.props.itemFromParent);
			} else {
				this.props.onChangeNumber(valueToSet, this.props.itemFromParent);
			}
		});
	};

	public focus() {
		if (this.input) {
			this.input.focus();
		}
	}
}
