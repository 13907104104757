import saveAs from 'file-saver';
import { FileResponse } from 'shared/api/api';

export function savePdfAs(fileResponse: FileResponse | null, fileName: string) {
	if (fileResponse === null) {
		return;
	}
	let blob = new Blob([fileResponse.data], { type: 'data:application/pdf;base64' });
	saveAs(blob, fileName);
}

export function saveCsvAs(fileResponse: FileResponse | null, fileName: string) {
	if (fileResponse === null) {
		return;
	}
	let blob = new Blob([fileResponse.data], { type: 'text/csv;charset=utf-8;' });
	saveAs(blob, fileName);
}
