import React from 'react';
import { ClipLoader } from 'react-spinners';
import './skiold-icon-spinner.scss';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { SkioldTouchableOpacity } from '../skiold-components/skiold-touchable-opacity';
import { SkioldImage } from './svg/skiold-image';
import { WhiteText } from '../Text/white-text';
import { localizedDynamic } from 'shared/state/i18n/i18n';
interface SkioldIconSpinnerProps {
    title: string;
    icon: any;
    onPress: () => void;
    showSpinner: boolean
}

interface State {
  
}

export class SkioldIconSpinnerControlled extends React.PureComponent<SkioldIconSpinnerProps, State> {
    constructor(props: SkioldIconSpinnerProps) {
        super(props);
        this.state = {
           
        };
    }

    public render() {
        return (
            <ViewWeb className='skiold-icon-spinner'>
                <ViewWeb className='image-container'>
                    <SkioldTouchableOpacity className='image-container' onPress={this.props.onPress}>
                        <SkioldImage width="60" height="60" imageData={this.props.icon} />
                    </SkioldTouchableOpacity>
                    {this.props.showSpinner ? (
                        <ViewWeb className='spinner-container-active'>
                            <ClipLoader color="#f2ac40" size={35} />
                        </ViewWeb>
                    ) : (
                        <ViewWeb className='spinner-container-disabled' />
                    )}
                </ViewWeb>
                <WhiteText>{localizedDynamic(this.props.title)}</WhiteText>
            </ViewWeb>
        );
    }
}
