import qs from 'qs';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AccessTokenExpireKey, B2C_AUTHORIZATION_ENDPOINT_POLICY } from 'shared/constants';
import { getAuthStorageEngine } from 'shared/helpers/auth-storage-helper';
import { isAuthenticated, TraceUser } from 'shared/helpers/authentication-helper.shared';
import { Monitoring } from 'shared/helpers/monitoring-service';
import { goToPage } from 'web/state/ducks/navigation/actions';
import { ROUTE_SIGN_IN } from 'web/state/ducks/navigation/types';
import { WebAppState } from 'web/state/store.web';
import { extractAndSaveAccessToken, reauthConfiguration, signOut } from 'web/web-helpers/authentication-helper-web';

const mapStateToProps = (state: WebAppState) => {
	return {
		isAuth: state.authentication.isAuthenticated,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
	return {
		navigateToSignin: () => dispatch(goToPage(ROUTE_SIGN_IN)),
	};
};

const TimeBeforeRefresh = 1800 * 1000; // 30min

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

interface State {
	shouldRenew: boolean;
}

class ReauthorizeComponent extends React.PureComponent<Props, State> {
	private renewalTimeout: undefined | any;
	private iframeRef: any;

	constructor(props: Props) {
		super(props);

		this.state = {
			shouldRenew: false,
		};
	}

	public componentDidMount() {
		this.scheduleTokenRenewal();
	}

	public componentWillUnmount() {
		this.unscheduleRenewal();
	}

	public componentDidUpdate(prevProps: Props) {
		if (prevProps.isAuth === true && (this.props.isAuth === false || this.props.isAuth === undefined)) {
			this.unscheduleRenewal();
		} else if ((prevProps.isAuth === false || prevProps.isAuth === undefined) && this.props.isAuth === true) {
			this.scheduleTokenRenewal();
		}
	}

	public render() {
		if (this.state.shouldRenew) {
			this.scheduleNewRenewalIfFirstFailed();

			return (
				<iframe
					title="renewIframe"
					src={this.getAuthorizationUrl()}
					style={{ width: 0, height: 0, border: 0 }}
					ref={ref => (this.iframeRef = ref)}
					onLoad={async () => {
						await this.reloadAuthState();
						this.scheduleTokenRenewal();
						this.setState({ shouldRenew: false });
					}}
				/>
			);
		}

		return null;
	}

	private scheduleNewRenewalIfFirstFailed = () => {
		//Schedule new token renewal if iframe renewal fails
		setTimeout(async () => {
			let expiresAt = await getAuthStorageEngine().getItem<string>(AccessTokenExpireKey)!;

			if (expiresAt) {
				let expiresAtDate = new Date(expiresAt);
				let timeToExpire = expiresAtDate.getTime() - new Date().getTime();
				if (timeToExpire < TimeBeforeRefresh) {
					this.setState({ shouldRenew: false });
					Monitoring.logTrace('Schedule new renewal - must have failed', {
						timeToExpire: timeToExpire.toString(),
					});
					this.scheduleTokenRenewal();
				}
			} else {
				Monitoring.logTrace('No Access token expire date');
				return;
			}
		}, 10000);
	};

	private getAuthorizationUrl = () => {
		let query = qs.stringify(reauthConfiguration);

		return `${B2C_AUTHORIZATION_ENDPOINT_POLICY}&${query}`;
	};

	private reloadAuthState = async () => {
		if (!this.iframeRef) {
			Monitoring.logTrace('Iframe not defined in reauthorize');
		} else if (this.iframeRef.contentWindow.location.hostname !== window.location.hostname) {
			Monitoring.logTrace(
				`iframe hostname: ${this.iframeRef.contentWindow.location.hostname}, doesn't match origin hostname: ${window.location.hostname}`,
			);
		} else {
			let success: boolean = await extractAndSaveAccessToken(this.iframeRef.contentWindow);
			if (!success) {
				Monitoring.logTrace('No new access token received - redirecting to signin');
				signOut();
			}
		}
	};

	private scheduleTokenRenewal = async () => {
		this.unscheduleRenewal();

		if (!(await isAuthenticated())) {
			TraceUser('Access token has expired');
			return;
		}

		let expiresAt = await getAuthStorageEngine().getItem<string>(AccessTokenExpireKey)!;
		let expiresAtDate = new Date(expiresAt);

		if (expiresAt && expiresAtDate) {
			let timeToRenewal = expiresAtDate.getTime() - new Date().getTime() - TimeBeforeRefresh;

			if (timeToRenewal < 0) {
				timeToRenewal = 0;
			}

			this.renewalTimeout = setTimeout(() => {
				if (this.state.shouldRenew) {
					TraceUser('Previous schedule access token failed');
				}
				this.setState({ shouldRenew: true });
			}, timeToRenewal);
		} else {
			Monitoring.logTrace('No token expire time - cannot schedule renewal');
		}
	};

	private unscheduleRenewal = () => {
		if (this.renewalTimeout) {
			clearTimeout(this.renewalTimeout);
			this.renewalTimeout = undefined;
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ReauthorizeComponent);
