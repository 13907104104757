import memoize from 'memoize-one';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { AnimalType, GrowthPigsEvent, ISlaughterSiteDataDto } from 'shared/api/api';
import { getDateString } from 'shared/helpers/date-helpers';
import { NaturalSortDates } from 'shared/helpers/natural-sort';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import SkioldTableGrid from '../../skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { DepartureTypes } from 'shared/state/models/departure-types';
import { slaughterDataForCalculation } from 'shared/helpers/slaughter-data-helper/slaughter-data-helper';

interface PropsFromParent {
	dateFrom: Date;
	dateTo: Date;
	sectionId?: string;
}

export const CheckSlaughterDataWithDepartureDataTable: FC<PropsFromParent> = React.memo(props => {
	const state = useSelector((state: WebAppState) => {
		return {
			gpeToUse: state.growthPigEvents.growthPigEvents.filter(
				gpe =>
					gpe.fromProductionType === AnimalType.Finisher &&
					gpe.departureType === DepartureTypes.departureTypeKilled &&
					gpe.date &&
					gpe.date <= props.dateTo &&
					gpe.date >= props.dateFrom &&
					(props.sectionId ? gpe.fromSectionId === props.sectionId : true),
			),
			slaughterDataToUse: state.SlaughterDatas.slaughteDatas.filter(
				sd =>
					sd.date &&
					sd.date <= props.dateTo &&
					sd.date >= props.dateFrom &&
					(props.sectionId ? sd.sectionId === props.sectionId : true),
			),
		};
	});

	return (
		<SkioldTableGrid
			containerClassName="alignSelfCenter"
			ColumnExtensions={generateColumnsExtensions()}
			columns={generateColumns()}
			data={generateDataForDifferenceTable(state.slaughterDataToUse, state.gpeToUse)}
			tableKey={'checkSlaughterDataWithDepartureDataTable'}
		/>
	);
});

function generateColumnsExtensions() {
	return [
		{
			columnName: 'date',
			width: 100,
		},
		{
			columnName: 'departureSkioldDigital',
			filteringEnabled: false,
			width: 200,
		},
		{
			columnName: 'departureSlaughterHouse',
			width: 200,
		},
		{
			columnName: 'difference',
			width: 100,
		},
	];
}

const getDateCell = (sd: SlaughterDataWithDepartureTableData) => sd.date;
const getDepartureSkioldDigitalCell = (sd: SlaughterDataWithDepartureTableData) => sd.countSkiold;
const getDepartureSlaughterHouseCell = (sd: SlaughterDataWithDepartureTableData) => sd.countSlaughterHouse;
const getDifferenceCell = (sd: SlaughterDataWithDepartureTableData) => sd.difference;
function generateColumns() {
	return [
		{
			name: 'date',
			filterable: false,
			title: localized('Date'),
			sortFunction: NaturalSortDates,
			getCellValue: getDateCell,
		},
		{
			name: 'departureSkioldDigital',
			title: localized('departureSkioldDigital'),
			shouldOverflow: true,
			getCellValue: getDepartureSkioldDigitalCell,
		},
		{
			name: 'departureSlaughterHouse',
			title: localized('departureSlaughterHouse'),
			getCellValue: getDepartureSlaughterHouseCell,
		},
		{
			name: 'difference',
			title: localized('difference'),
			getCellValue: getDifferenceCell,
		},
	];
}

interface SlaughterDataWithDepartureTableData {
	date: string;
	countSkiold: number;
	countSlaughterHouse: number;
	difference: number;
	sectionId?: string;
}

const generateDataForDifferenceTable = memoize((slaughterData: ISlaughterSiteDataDto[], gpe: GrowthPigsEvent[]) => {
	const data: SlaughterDataWithDepartureTableData[] = [];
	slaughterData.forEach(sd => {
		if (!slaughterDataForCalculation(sd)) {
			return;
		}
		const index = data.findIndex(d => getDateString(sd.date) === d.date);
		if (index >= 0) {
			data[index].countSlaughterHouse += 1;
			data[index].difference -= 1;
		} else {
			data.push({
				date: getDateString(sd.date),
				countSkiold: 0,
				countSlaughterHouse: 1,
				difference: -1,
				sectionId: sd.sectionId,
			});
		}
	});
	gpe.forEach(gpe => {
		const index = data.findIndex(d => getDateString(gpe.date) === d.date);
		if (index >= 0) {
			data[index].countSkiold += gpe.amount ? gpe.amount : 0;
			data[index].difference += gpe.amount ? gpe.amount : 0;
		} else {
			data.push({
				date: getDateString(gpe.date),
				countSkiold: gpe.amount ? gpe.amount : 0,
				countSlaughterHouse: 0,
				difference: gpe.amount ? gpe.amount : 0,
				sectionId: gpe.fromSectionId,
			});
		}
	});
	return data;
});
