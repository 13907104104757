import React from 'react';
import { PickerPropsFromParent, SkioldDropdown } from './skiold-dropdown';
import './skiold-form-dropdown.scss'

export class SkioldFormDropdown extends React.PureComponent<PickerPropsFromParent> {
    public static defaultProps: Partial<PickerPropsFromParent> = {
        theme: 'dark'
    };
    public render() {
        return (
            <SkioldDropdown
                {...this.props}
                theme={this.props.theme}
                containerClassName={`skiold-form-dropdown ${this.props.containerClassName}`}
            />
        );
    }
}
