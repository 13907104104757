import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { localized, localizedInterpolation } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { WhiteText } from 'web/view/components/Text/white-text';
import PageContainer from 'web/view/components/page-container/page-container';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { GetAnimalListPdf, GetDadMomTablePdf } from 'web/web-helpers/pdf-helper/animal-list-helper';

interface PropsFromParent {
	closeModal: () => void;
	printElaboration: () => void;
	printWeekList: () => void;
	batchNumber: number | undefined;
}

export const TotalMatingPrintModal: FC<PropsFromParent> = React.memo(
	({ closeModal, printElaboration, printWeekList, batchNumber }) => {
		const [isDownloading, setIsDownloading] = useState<boolean>();
		const { siteId, language } = useSelector((state: WebAppState) => state.profile.active!);
		const downloadGeneral = async () => {
			setIsDownloading(true);
			try {
				printElaboration();
			} finally {
				closeModal();
				setIsDownloading(false);
			}
		};

		const downloadMomDad = async () => {
			setIsDownloading(true);
			try {
				printWeekList();
			} finally {
				closeModal();
				setIsDownloading(false);
			}
		};

		return (
			<PageContainer>
				<ViewWeb className="align-center">
					<ViewWeb className="flex-row-space-between default-spacing default-gap">
						<WhiteText>{localizedInterpolation('ElaborationMatingBatch', batchNumber)}:</WhiteText>
						<SkioldButton onPress={downloadGeneral} title={localized('Print')} />
					</ViewWeb>
					<ViewWeb className="flex-row-space-between default-spacing default-gap">
						<WhiteText>{localizedInterpolation('weekBatchPdf', batchNumber)}:</WhiteText>
						<SkioldButton onPress={downloadMomDad} title={localized('Print')} />
					</ViewWeb>
					<ViewWeb className="flex-row default-spacing">
						<SkioldButton theme="grey" onPress={closeModal} title={localized('Close')} />
					</ViewWeb>
				</ViewWeb>
			</PageContainer>
		);
	},
);
function printElaboration() {
	throw new Error('Function not implemented.');
}
