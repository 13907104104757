import { IPregnancyEvent, IStemAnimal, PregnancyEvent, SkioldOneClient, StemAnimal } from 'shared/api/api';
import { SkioldDigitalApiBaseUrl } from 'shared/constants';
import { StoreContainer } from 'shared/state/store-container';

export function ResendStemAnimal(stemAnimal: IStemAnimal) {
	return new SkioldOneClient(SkioldDigitalApiBaseUrl)
		.nucleusManagementCommunication_ResendNucleusStemAnimal(stemAnimal as StemAnimal)
		.catch(() => {
			return;
		});
}

export function ResendYoungAnimal(youngAnimal: IStemAnimal) {
	return new SkioldOneClient(SkioldDigitalApiBaseUrl)
		.nucleusManagementCommunication_ResendNucleusYoungAnimal(youngAnimal as StemAnimal)
		.catch(() => {
			return;
		});
}

export function ResendPregnancyEvent(pregnancyEvent: IPregnancyEvent) {
	return new SkioldOneClient(SkioldDigitalApiBaseUrl)
		.nucleusManagementCommunication_ResendNucleusPregnancyEvent(pregnancyEvent as PregnancyEvent)
		.catch(() => {
			return;
		});
}
