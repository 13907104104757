import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IBuilding, ISection } from 'shared/api/api';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { SaveLocation } from 'shared/state/ducks/locations/operations';
import { localized } from 'shared/state/i18n/i18n';
import { LocationCreateType } from 'shared/state/models/locations/location-create-type';
import { PenFormData } from 'shared/state/models/locations/pen-form-data';
import { SectionFormData } from 'shared/state/models/locations/section-form-data';
import { WebAppState } from "web/state/store.web";
import PageContainer from 'web/view/components/page-container/page-container';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import AddPen, { AddPen as AddPenRef } from './add-pen';
import AddSection, { AddSection as AddSectionRef } from './add-section';
import CreateBuilding, { CreateBuilding as CreateBuildingRef } from './create-building';
import './create-location-wrapper.scss';

interface PropsFromParent {
	close: () => void;
	isOpen: boolean;
	createType: LocationCreateType;
	building: IBuilding;
	section?: ISection;
}

const mapStateToProps = (state: WebAppState) => {
	return {};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	saveLocation: (
		createType: LocationCreateType,
		penForm: PenFormData,
		building: IBuilding,
		sectionFormData: SectionFormData | undefined,
		section: ISection | undefined
	) => SaveLocation(createType, penForm, building, sectionFormData, section)(dispatch)
});

interface CreateLocationWrapperState {
	building: IBuilding;
	buildingSectionFormData: SectionFormData | undefined;
	usingPens: boolean;
	penFormData: PenFormData | undefined;
}

type CreateLocationWrapperProps = ReturnType<typeof mapStateToProps> &
	ReturnType<typeof mapDispatchToProps> &
	PropsFromParent;
export class CreateLocationWrapper extends React.PureComponent<CreateLocationWrapperProps, CreateLocationWrapperState> {
	public addSectionRef: AddSectionRef | undefined;
	public addPenRef: AddPenRef | undefined;
	public createBuildingRef: CreateBuildingRef | undefined;

	private readonly usingPensChanged = (usingPens: boolean) => {
		this.setState({
			usingPens
		});
	};

	private readonly penFormChanged = (penForm: PenFormData | undefined) => {
		this.setState({
			penFormData: penForm
		});
	};

	private readonly setAddSectionRef = (s: any) => s ? (this.addSectionRef = s) : {};

	private readonly setAddPenRef = (s: any) => (s ? (this.addPenRef = s) : {});

	private readonly setCreateBuildingRef = (s: any) => s ? (this.createBuildingRef = s) : {};

	private readonly buildingStateChanged = (building: IBuilding) => this.setState({
		building
	});

	private readonly sectionFormChanged = (SectionForm: SectionFormData | undefined) => {
		this.setState({
			buildingSectionFormData: SectionForm
		});
	};

	constructor(props: CreateLocationWrapperProps) {
		super(props);
		this.state = {
			building: this.props.building,
			buildingSectionFormData: undefined,
			penFormData: undefined,
			usingPens: true
		};
	}
	public render() {
		return (
			<PageContainer>
				<ViewWeb className="create-location-wrapper">
					<ViewWeb className="form-container">
						{this.props.createType === LocationCreateType.CreateBuilding && (
							<CreateBuilding
								ref={this.setCreateBuildingRef}
								buildingStateChanged={this.buildingStateChanged}
								sectionFormChanged={this.sectionFormChanged}
								penFormChanged={this.penFormChanged}
							/>
						) }
						{this.props.createType === LocationCreateType.CreateBuilding ||
						this.props.createType === LocationCreateType.AddSection ? (
							<AddSection
								building={this.state.building}
								ref={this.setAddSectionRef
								}
								penFormChanged={this.penFormChanged}
								usingPensChanged={this.usingPensChanged}
								UsesPens={this.state.building && !this.state.building.id? this.state.building.useSections: this.state.usingPens}
								locationCreateType={this.props.createType}
							/>
						) : (
							<ViewWeb />
						)}
						<AddPen
							usingPens={
								this.state.buildingSectionFormData
									? this.state.buildingSectionFormData.UsePens
									: this.state.usingPens
							}
							section={this.props.section!}
							ref={this.setAddPenRef}
						/>

						{this.renderButtons()}
					</ViewWeb>
				</ViewWeb>
			</PageContainer>
		);
	}

	private save = () =>{
		if (
			(!this.createBuildingRef || this.createBuildingRef.validate()) &&
			(!this.addSectionRef ||
				!this.state.building.useSections ||
				(this.state.building.useSections && this.addSectionRef!.validate())) &&
			(this.addPenRef && this.state.usingPens ? this.addPenRef.validate() : true)
		) {
			this.props.saveLocation(
				this.props.createType,
				this.state.penFormData ? this.state.penFormData : this.addPenRef!.save()!,
				this.createBuildingRef ? this.createBuildingRef.save()! : this.props.building,
				this.addSectionRef
					? this.state.buildingSectionFormData
						? this.state.buildingSectionFormData
						: this.addSectionRef.save()
					: undefined,
				this.addSectionRef ? undefined : this.props.section
			);
			this.props.close();
		}
	}

	private renderButtons() {
		let buttons = (
			<ViewWeb
				className='button-container'
			>
				{this.props.close && (
					<SkioldButton title={localized('Close')} onPress={this.props.close} theme="grey" />
				)}

				<SkioldButton title={localized('Save')} onPress={this.save} />
			</ViewWeb>
		);

		return buttons;
	}
}

export default 
	connect(
		mapStateToProps,
		mapDispatchToProps
	)((CreateLocationWrapper));

