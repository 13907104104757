import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AnimalType, IProductionReportSetting } from 'shared/api/api';
import {
	GetSyncData as GetProductionReportSeting,
	SaveProductionReportSetting,
} from 'shared/state/ducks/production-report-settings/operations';
import { localized, localizedDynamic } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldCheckbox } from 'web/view/components/skiold-components/skiold-checkbox/skiold-checkbox';
import { SkioldFormsWrapper } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import './p-report-key-setup.scss';
import { initProductionReportSetting } from './production-report.-helper';

interface PropsFromParent {}

const mapStateToProps = (state: WebAppState) => {
	return {
		productionSetting: state.productionReportSetting.entities.find(a=> !a.animaltype || a.animaltype === AnimalType.Sow),
		siteId: state.profile.active!.siteId,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	saveProductionReportSetting: (report: IProductionReportSetting) => SaveProductionReportSetting(report)(dispatch),
	getProductionReportSetting: () => GetProductionReportSeting()(dispatch),
});

export interface State {
	pReportSetting: IProductionReportSetting;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

export class PReportSetup extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			pReportSetting: initProductionReportSetting(props.productionSetting, props.siteId!, AnimalType.Sow),
		};
	}

	public componentDidMount() {
		this.props.getProductionReportSetting().then(() => {
			if (this.props.productionSetting && this.props.productionSetting.id) {
				this.setState({ pReportSetting: this.props.productionSetting });
			} else {
				this.props.saveProductionReportSetting(this.state.pReportSetting);
			}
		});
	}

	public render() {
		return (
			<ViewWeb>
				<ViewWeb className="p-report-key-setup">
					<ViewWeb className="title-save-container">
						<SkioldButton className="button-container" title={localized('Save')} onPress={this.save} />
						<TextWeb className="setup-text-label">{localized('Setup')}</TextWeb>
					</ViewWeb>

					<this.renderProductionScale />
				</ViewWeb>
			</ViewWeb>
		);
	}

	// all key numbers
	public renderProductionScale = () => {
		let formRows = new Array<FormRow>();
		formRows.push(this.getHeader('ProductionScale'));
		formRows.push(this.getCheckbox('YearSowPcs'));
		formRows.push(this.getCheckbox('WeanedPigletsPcs'));
		formRows.push(this.getCheckbox('AvgWeanedPerLitter'));
		formRows.push(this.getCheckbox('AvgWeightPerPiglet'));
		formRows.push(this.getCheckbox('PercentDeadSowGilts'));

		formRows.push(this.getHeader('Feed'));
		formRows.push(this.getCheckbox('SowFoodFeYearSow'));
		formRows.push(this.getCheckbox('ThereOfYoungAnimalFood'));
		formRows.push(this.getCheckbox('FePerYearSowPerDay'));
		formRows.push(this.getCheckbox('FePerYearYoungFemalePerDay'));

		formRows.push(this.getHeader('Reproduktion'));
		formRows.push(this.getCheckbox('WasteDaysPerDay'));
		formRows.push(this.getCheckbox('ThereOfAdjustedDays'));
		formRows.push(this.getCheckbox('LitterPerYearSowPcs'));
		formRows.push(this.getCheckbox('WeanedPigletsPerYearSowPcs'));

		formRows.push(this.getHeader('Mating'));
		formRows.push(this.getCheckbox('TotalMatings'));
		formRows.push(this.getCheckbox('YoungAnimalMatings'));
		formRows.push(this.getCheckbox('ReMatingPercent'));
		formRows.push(this.getCheckbox('WeaningToFirstMating'));
		formRows.push(this.getCheckbox('AvgWasteDays'));

		formRows.push(this.getHeader('Farrowing'));
		formRows.push(this.getCheckbox('TotalFarrowings'));
		formRows.push(this.getCheckbox('ThereOfGiltsPcs'));

		formRows.push(this.getCheckbox('AlivePerLitterPcs'));
		formRows.push(this.getCheckbox('DeadPerLitter'));
		formRows.push(this.getCheckbox('FarrowingPercent'));
		formRows.push(this.getCheckbox('AvgPregnantDaysPerLitter'));

		formRows.push(this.getHeader('Weaning'));
		formRows.push(this.getCheckbox('TotalWeanings'));
		formRows.push(this.getCheckbox('ThereOfFirstLitterPcs'));
		formRows.push(this.getCheckbox('FirstLitterPercent'));
		formRows.push(this.getCheckbox('NursingSows'));
		formRows.push(this.getCheckbox('NursingSowPercent'));
		formRows.push(this.getCheckbox('AvgAlivePerLitter'));
		formRows.push(this.getCheckbox('AvgDeadPerLitter'));
		formRows.push(this.getCheckbox('AvgWeanedPerLitter'));
		formRows.push(this.getCheckbox('WeanedPerWeaningPcs'));
		formRows.push(this.getCheckbox('AvgWeight'));
		formRows.push(this.getCheckbox('DeadTillWeaningPercent'));
		formRows.push(this.getCheckbox('AvgNursingDays'));

		formRows.push(this.getHeader('FeedUsage'));
		formRows.push(this.getCheckbox('FeedUsage'));

		formRows.push(this.getHeader('Status'));
		formRows.push(this.getCheckbox('Status'));

		formRows.push(this.getHeader('SubResult'));
		formRows.push(this.getCheckbox('SubResult'));
		return <SkioldFormsWrapper formRows={formRows} containerClassName="setup-forms-wrappoer-container" />;
	};

	private getCheckbox = (key: string) => {
		return {
			name: localizedDynamic(key),
			component: (
				<SkioldCheckbox
					itemFromParent={key}
					onClick={this.valueChanged}
					isChecked={this.state.pReportSetting.keyNumbers![key]}
				/>
			),
		};
	};

	private valueChanged = (value, key: string) => {
		this.setState(prevState => ({
			pReportSetting: {
				...prevState.pReportSetting,
				keyNumbers: { ...prevState.pReportSetting.keyNumbers, [key]: value },
			},
		}));
	};

	private getHeader(header: string): FormRow {
		return {
			header: localizedDynamic(header),
		};
	}
	private save = async () => {
		const { pReportSetting } = this.state;
		if (!this.validate(pReportSetting)) {
			return;
		}
		
		this.props.saveProductionReportSetting(pReportSetting);
	};

	private validate(pReportSetting: IProductionReportSetting) {
		return true;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PReportSetup);
