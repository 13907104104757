import { Action } from 'redux';
import { upsertValueInArray } from 'shared/helpers/reducer-helpers';
import { isType } from 'typescript-fsa';
import * as actions from './actions';
import { AnalysisSettingState } from './types';
import { siteChange } from '../../profile/actions';

export const initialState: AnalysisSettingState = {
	analysisSettings: [],
	loading: false
};

const analysisSetttingReducer = (state: AnalysisSettingState = initialState, action: Action): AnalysisSettingState => {
	if (isType(action, actions.getBySiteId.started)) {
		state = { ...state, loading: true };
	}

	if (isType(action, actions.getBySiteId.done)) {
		if (action.payload.params.siteId === action.payload.params.activeSiteId) {
			if (action.payload.result!.length) {
				let settings = action.payload.result!;
			
				state = {
					...state,
					loading: false,
					analysisSettings: settings
				};
			}else{
				state = {
					...state,
					loading: false
				};
			}

			return state;
		} else {
			return {
				...state,
				loading: false
			};
		}
	}

	if (isType(action, actions.getBySiteId.failed)) {
		state = { ...state, loading: false };
	}

	if (isType(action, actions.saveAnalysisSettings.started)) {
		if(action.payload){
			state = { ...state, analysisSettings: upsertValueInArray(state.analysisSettings, action.payload) };
		}

	}

	if (isType(action, actions.saveAnalysisSettings.failed)) {
		return state;
	}



	if (isType(action, siteChange.done)) {
		state = initialState;
	}
	return state;
};

export default analysisSetttingReducer;
