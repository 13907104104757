import React from 'react';
import {
	FarrowingItem,
	IProductionReportItem,
	IProductionReportSetting,
	MatingItem,
	ProductionMappedDate,
	ScaleFeedReproduction,
	StatusItem,
	WeaningItem,
} from 'shared/api/api';
import { localized } from 'shared/state/i18n/i18n';
import { SkioldTable } from 'web/view/components/skiold-components/skiold-table/skiold-table';
import { ViewWeb } from 'web/view/components/utils/web-view';
import {
	getFarrowingDescription,
	getMatingDescription,
	getProductionScaleDescription,
	getStatusDescription,
	getSubResultDescription,
	getWeaningDescription,
	ProductionReportsData,
	renderFarrowingColumn,
	renderMatingColumn,
	renderScaleFeedReproductionItem,
	renderStatusColumn,
	renderSubResultsColumn,
	renderWeaningColumn,
} from './p-report-helper';
import './production-report-table.scss';

// tmp dates are used in the datepicker as they shouldnt come into effect before the fetch button is clicked
interface State {}

interface PropsFromParent {
	productionReportData?: IProductionReportItem;
	isSpecialPeriod: boolean;
	pReportSetting: IProductionReportSetting;
}

type Props = PropsFromParent;
export class ProductionReportTable extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {};
	}

	public render() {
		const columns = this.generateColumns();
		return (
			<div className="report-container">
				<ViewWeb className="p-report-outer-div">
					<SkioldTable className="production-report" columns={columns} data={this.generateData()} />
				</ViewWeb>
			</div>
		);
	}

	public generateData(): ProductionReportsData[] {
		let productionReportsData = new Array<ProductionReportsData>(8);
		productionReportsData[0] = {
			description: (
				<div className="p-report-date-header">
					<p>{localized('FromDate')}</p>
					<p>{localized('ToDate')}</p>
					<p>{localized('Days')}</p>
				</div>
			),

			columns: new Array<JSX.Element>(this.props.isSpecialPeriod ? 1 : 5),
		};
		productionReportsData[1] = {
			description: getProductionScaleDescription(this.props.pReportSetting),
			columns: new Array<JSX.Element>(this.props.isSpecialPeriod ? 1 : 5),
		};
		productionReportsData[2] = {
			description: <div className="p-report-string-header"> {localized('Matings')}</div>,
			columns: new Array<JSX.Element>(this.props.isSpecialPeriod ? 1 : 5),
		};
		productionReportsData[3] = {
			description: getMatingDescription(this.props.pReportSetting),
			columns: new Array<JSX.Element>(this.props.isSpecialPeriod ? 1 : 5),
		};
		productionReportsData[4] = {
			description: <div className="p-report-string-header"> {localized('Farrowings')}</div>,
			columns: new Array<JSX.Element>(this.props.isSpecialPeriod ? 1 : 5),
		};
		productionReportsData[5] = {
			description: getFarrowingDescription(this.props.pReportSetting),
			columns: new Array<JSX.Element>(this.props.isSpecialPeriod ? 1 : 5),
		};
		productionReportsData[6] = {
			description: <div className="p-report-string-header"> {localized('Weanings')}</div>,
			columns: new Array<JSX.Element>(this.props.isSpecialPeriod ? 1 : 5),
		};
		productionReportsData[7] = {
			description: getWeaningDescription(this.props.pReportSetting),
			columns: new Array<JSX.Element>(this.props.isSpecialPeriod ? 1 : 5),
		};
		productionReportsData[8] = {
			description: (
				<div className="status-header-row">
					<p className="p-report-string-header"> {localized('Status')}</p>
					<p className="p-report-date-header">{localized('ToDate')}</p>
				</div>
			),
			columns: new Array<JSX.Element>(this.props.isSpecialPeriod ? 1 : 5),
		};
		productionReportsData[9] = {
			description: getStatusDescription(this.props.pReportSetting),
			columns: new Array<JSX.Element>(this.props.isSpecialPeriod ? 1 : 5),
		};
		productionReportsData[10] = {
			description: <div className="p-report-string-header"> {localized('SubResults')}</div>,
			columns: new Array<JSX.Element>(this.props.isSpecialPeriod ? 1 : 5),
		};
		productionReportsData[11] = {
			description: getSubResultDescription(),
			columns: new Array<JSX.Element>(this.props.isSpecialPeriod ? 1 : 5),
		};
		productionReportsData = this.generateReportsData(productionReportsData);
		return productionReportsData;
	}

	public generateReportsData(productionReportsData: ProductionReportsData[]) {
		let matingReports: MatingItem[] | undefined;
		let farrowingReports: FarrowingItem[] | undefined;
		let weaningReports: WeaningItem[] | undefined;
		let scaleFeedReproduction: ScaleFeedReproduction | undefined;
		let statusReports: StatusItem[] | undefined;
		let mappedDates: ProductionMappedDate[] | undefined;
		if (
			this.props.productionReportData &&
			this.props.productionReportData.matingItems &&
			this.props.productionReportData.farrowingItems &&
			this.props.productionReportData.weaningItems
		) {
			matingReports = this.props.productionReportData.matingItems!;
			farrowingReports = this.props.productionReportData.farrowingItems!;
			weaningReports = this.props.productionReportData.weaningItems!;
			scaleFeedReproduction = this.props.productionReportData.productionScaleFeedReproduction!;
			statusReports = this.props.productionReportData.statusItems;
			mappedDates = this.props.productionReportData.mappedDates!;
		}
		for (let index = 0; index < productionReportsData.length; index++) {
			renderScaleFeedReproductionItem(
				scaleFeedReproduction,
				mappedDates,
				index,
				productionReportsData,
				this.props.pReportSetting,
			);
			renderMatingColumn(matingReports, index, productionReportsData, this.props.pReportSetting);
			renderFarrowingColumn(farrowingReports, index, productionReportsData, this.props.pReportSetting);
			renderWeaningColumn(weaningReports, index, productionReportsData, this.props.pReportSetting);
			renderStatusColumn(statusReports, mappedDates, index, productionReportsData);
			renderSubResultsColumn(scaleFeedReproduction, mappedDates, index, productionReportsData);
		}
		const keyNumbers = this.props.pReportSetting.keyNumbers;

		if (keyNumbers) {
			if (!keyNumbers['SubResult']) {
				productionReportsData.splice(10, 2);
			}
			if (!keyNumbers['Status']) {
				productionReportsData.splice(8, 2);
			}
		}

		return productionReportsData;
	}

	private generateColumns() {
		let columns: any[] = [
			{
				id: 'description',
				filterable: false,
				sortable: false,
				width: 270,
				accessor: (productionReportData: ProductionReportsData) => productionReportData.description,
			},
			{
				id: 'period1',
				filterable: false,
				sortable: false,
				width: this.props.isSpecialPeriod ? 270 : 100,
				accessor: (productionReportData: ProductionReportsData) => productionReportData.columns![0],
			},
		];

		if (!this.props.isSpecialPeriod) {
			columns.push(
				{
					id: 'period2',
					filterable: false,
					sortable: false,
					width: 100,
					accessor: (productionReportData: ProductionReportsData) => productionReportData.columns![1],
				},
				{
					id: 'period3',
					filterable: false,
					sortable: false,
					width: 100,
					accessor: (productionReportData: ProductionReportsData) => productionReportData.columns![2],
				},
				{
					id: 'period4',
					filterable: false,
					sortable: false,
					width: 100,
					accessor: (productionReportData: ProductionReportsData) => productionReportData.columns![3],
				},
				{
					id: 'period5',
					filterable: false,
					sortable: false,
					width: 100,
					accessor: (productionReportData: ProductionReportsData) => productionReportData.columns![4],
				},
			);
		}
		return columns;
	}
}
