import { Button } from 'primereact/button';
import React from 'react';
import './skiold-button.scss';

export interface PropsFromParent {
	title: string;
	buttonStyle?: any;
	containerViewStyle?: any;
	disabled?: boolean;
	itemFromParent?: any;
	onPress: (itemFromParent?: any) => any;
	theme?: 'orange' | 'grey';
	className?: string;
}

export interface State {
	disabledStatus?: boolean;
}

export class SkioldButton extends React.PureComponent<PropsFromParent, State> {
	public static defaultProps: Partial<PropsFromParent> = {
		buttonStyle: {},
		containerViewStyle: {},
		theme: 'orange',
	};

	private _ismounted = false;
	constructor(props: PropsFromParent) {
		super(props);
		this.state = { disabledStatus: undefined };
	}

	public componentDidMount() {
		this._ismounted = true;
	}

	public componentWillUnmount() {
		this._ismounted = false;
	}

	public render() {
		return (
			<Button
				className={`${this.props.className} ${this.props.theme} skiold-button`}
				label={this.props.title}
				onClick={this.onButtonClicked}
				disabled={this.props.disabled ? this.props.disabled : !!this.state.disabledStatus}
			/>
		);
	}

	public onButtonClicked = async () => {
		this.setState({ disabledStatus: true });
		await this.props.onPress(this.props.itemFromParent);

		//If this.props.onPress() removed the button then an error is thrown because we try to call setState on an unmounted component
		if (this._ismounted) {
			this.setState({ disabledStatus: false });
		}
	};
}
