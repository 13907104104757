import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Farrowing, IFarrowing, IPregnancyEvent, IStemAnimal, PregnancyState, StemAnimal } from 'shared/api/api';
import { getLastPregnancyByPregnancyEventId } from 'shared/helpers/pregnancy-helper/generel-pregnancy-helpers';
import { ViewWeb } from 'web/view/components/utils/web-view';
import {
	DeleteLastPregnancyEvent,
	GetSyncData as PregnancyEventGetSyncData,
	SavePregnancyEvent,
} from 'shared/state/ducks/pregnancy-events/operations';
import { GetSyncData as StemAnimalGetSyncData } from 'shared/state/ducks/stem-animals/operations';
import { GetSyncData as ValidationSetupGetSyncData } from 'shared/state/ducks/validation-setup/operations';
import { localized, localizedDynamic } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import RegisterFarrowingBase, { RegisterFarrowingBase as RegisterFarrowingRef } from './register-farrowing-base';
import './register-farrowing.scss';
import { ResendPregnancyEvent } from 'shared/helpers/resend-nucleus-helpers';
import { selectNucleusManagementOrAssignIdAccess } from 'shared/state/ducks/site/reducer';
import { NucleusValidator } from 'shared/helpers/nucleus-management-helper/nucleus-validation-interface';
import { ShowConfirmAlert, showAlert } from 'web/view/components/skiold-alert/skiold-alert';
import { memoizeStemAnimal } from 'shared/helpers/memoize-getters/memoize-getters';
import { hasDanishNucleusFeatureLicence } from 'shared/helpers/nucleus-management-helper/nucleus-management-helper';
import ObjectID from 'bson-objectid';

interface PropsFromParent {
	pregnancyEventId?: string;
	stemAnimalId?: string;
	expectedFarrowingDate?: Date;
	animalNumber?: string;
	closeEditModal?: () => void;
}

const mapStateToProps = (state: WebAppState, props: PropsFromParent) => {
	return {
		siteId: state.profile.active!.siteId,
		pregnancyEvents: state.pregnancyEvents.entities,
		validationSetup: state.validationSetup.entity,
		generalSetting: state.generalSettings.entity,
		nucleusManagement: selectNucleusManagementOrAssignIdAccess(state),
		stemAnimal: memoizeStemAnimal(state.stemAnimals.entities, [], props.stemAnimalId),
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	getValidationSetup: () => ValidationSetupGetSyncData()(dispatch),
	stemAnimalGetSyncData: () => StemAnimalGetSyncData()(dispatch),
	pregnancyEventGetSyncData: () => PregnancyEventGetSyncData()(dispatch),
	savePregnancyEvent: (pregnancyEvent: IPregnancyEvent) => SavePregnancyEvent(pregnancyEvent)(dispatch),
	deleteLastPregnancyEvent: (pregEvent: IPregnancyEvent) => DeleteLastPregnancyEvent(pregEvent)(dispatch),
});

interface FarrowingState {
	sow: IStemAnimal;
	farrowed: IFarrowing;
	disableResend: boolean;
	youngAnimals?: IStemAnimal[];
}

export type FarrowingProps = ReturnType<typeof mapStateToProps> &
	ReturnType<typeof mapDispatchToProps> &
	PropsFromParent;
class RegisterFarrowing extends React.PureComponent<FarrowingProps, FarrowingState> {
	public registerFarrowingRef: RegisterFarrowingRef | undefined;
	constructor(props: FarrowingProps) {
		super(props);
		let date = new Date();
		if (this.props.expectedFarrowingDate) {
			date = this.props.expectedFarrowingDate;
		}
		this.state = {
			sow: StemAnimal.fromJS({}),
			farrowed: Farrowing.fromJS({ date: date, state: PregnancyState.Farrowing,id:  new ObjectID().toHexString(), }),
			disableResend: true,
		};
	}

	public setRef = (s: any) => {
		if (s) {
			this.registerFarrowingRef = s;
			this.forceUpdate();
		}
	};

	public farrowingEventSave = () => {};
	public removeFarrowingEventSave = () => {};
	public componentDidMount() {
		this.props.stemAnimalGetSyncData();
		this.props.pregnancyEventGetSyncData();
		this.props.getValidationSetup();
	}

	public render() {
		return <ViewWeb className="register-farrowing">{this.renderContent()}</ViewWeb>;
	}

	public renderContent() {
		return (
			<ViewWeb>
				<ViewWeb>
					{this.renderButtons()}
					<RegisterFarrowingBase
						ref={this.setRef}
						dateRow={true}
						genderRow={true}
						getPregnancyAnimalNumberRow={true}
						getLocationRow={true}
						getBatchRow={true}
						getAliveRow={true}
						getDeadRow={true}
						getAbortionRow={true}
						getAliveFemaleRow={this.props.generalSetting.farrowingShowAliveFemales}
						getAliveMaleRow={this.props.generalSetting.farrowingShowAliveMales}
						getMoveRow={true}
						getBreedRow={true}
						stableRow={true}
						penRow={true}
						getAssignIdRow={true}
						getLitterWeightRow={true}
						getMumifiedRow={true}
						getLastMoveDateRow={true}
						save={(method: () => void) => (this.farrowingEventSave = method)}
						remove={(method: () => void) => (this.removeFarrowingEventSave = method)}
						disableResend={this.setDisabled}
						farrowed={this.state.farrowed}
						animalNumber={this.props.animalNumber}
						pregnancyEventId={this.props.pregnancyEventId}
						closeEditModal={this.props.closeEditModal}
						isEdit={!!this.props.closeEditModal}
						stemAnimalId={this.props.stemAnimalId}
						getEarNotchRow={true}
						setYoungAnimals={this.setYoungAnimals}
					/>
				</ViewWeb>
			</ViewWeb>
		);
	}

	public  save = async () => {
		await this.farrowingEventSave();
		if (this.props.closeEditModal) {
			this.props.closeEditModal();
		}
	}

	public  removeEvent = async ()=> {
		await this.removeFarrowingEventSave();
		if (this.props.closeEditModal) {
			this.props.closeEditModal();
		}
	}

	private setYoungAnimals = (youngAnimals: IStemAnimal[]) => {
		this.setState({ youngAnimals });
	};

	private closeModalButton = async () => {
		if (
			this.state.youngAnimals &&
			this.state.youngAnimals.length > 0 &&
			(await ShowConfirmAlert(localized('saveWorklist')))
		) {
			this.save();
		} else {
			if (this.props.closeEditModal) {
				this.props.closeEditModal();
			}
		}
	};

	private renderButtons() {
		return (
			<ViewWeb
				className={
					this.props.pregnancyEventId
						? 'button-view-style-upper-corner-edit'
						: 'button-view-style-upper-corner'
				}
			>
				{this.props.closeEditModal && (
					<SkioldButton title={localized('Close')} onPress={this.closeModalButton} theme="grey" />
				)}
				{this.renderDeleteButton()}
				{this.props.expectedFarrowingDate && (
					<SkioldButton title={localized('sowCard')} onPress={this.removeEvent} theme="grey" />
				)}
				{this.props.expectedFarrowingDate && (
					<SkioldButton title={localized('treatment')} onPress={this.removeEvent} theme="grey" />
				)}

				{this.props.pregnancyEventId &&
					this.props.stemAnimal?.breedingNumber &&
					hasDanishNucleusFeatureLicence(this.props.nucleusManagement) && (
						<SkioldButton
							title={localized('resend')}
							onPress={this.resendFarrowing}
							disabled={this.state.disableResend}
							theme="grey"
						/>
					)}

				<SkioldButton title={localized('Save')} onPress={this.save} disabled={false} />
			</ViewWeb>
		);
	}

	private setDisabled = (disable: boolean) => {
		this.setState({ disableResend: disable });
	};

	private resendFarrowing = async () => {
		if (
			this.props.pregnancyEventId &&
			this.props.stemAnimalId &&
			this.props.pregnancyEvents[this.props.stemAnimalId]
		) {
			const farr = this.props.pregnancyEvents[this.props.stemAnimalId].find(
				p => p.id === this.props.pregnancyEventId,
			);
			if (farr && farr.state === PregnancyState.Farrowing) {
				ResendPregnancyEvent(farr);
			}
		}
	};

	private renderDeleteButton() {
		if (this.props.stemAnimalId) {
			const lastPregnancyEvent = getLastPregnancyByPregnancyEventId(
				this.props.pregnancyEvents[this.props.stemAnimalId!],
				this.props.pregnancyEventId!,
			);

			return (
				lastPregnancyEvent &&
				this.props.pregnancyEventId === lastPregnancyEvent.id && (
					<SkioldButton title={localized('Delete')} onPress={this.removeEvent} theme="grey" />
				)
			);
		}

		return null;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterFarrowing);
