import { Dispatch } from 'redux';
import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import { StoreContainer } from 'shared/state/store-container';
import * as Action from './actions';

export function GetSyncData() {
	const state = StoreContainer.getState();
	const lastSyncDate = state.markings.lastSyncDate;

	if (state.markings.syncInProgress) {
		return (dispatch: Dispatch<any>) => {
			return Promise.resolve();
		};
	}

	return AsyncOperationBuilder2(Action.getSyncData, client => client.markings_Sync(lastSyncDate), {
		lastSyncDate,
	});
}
