import { default as ObjectID } from 'bson-objectid';
import { Dispatch } from 'redux';
import { IMatingBatch, MatingBatch } from 'shared/api/api';
import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import { StoreContainer } from 'shared/state/store-container';
import * as Action from './actions';

export function SaveMatingBatches(matingBatches: IMatingBatch[]) {
	matingBatches.forEach(ev => {
		if (!ev.id) {
			ev.id = new ObjectID().toHexString();
		}
	});
	return AsyncOperationBuilder2(
		Action.saveMatingBatches,
		client => client.matingBatch_UpsertMultiple(matingBatches as MatingBatch[]),
		{ data: matingBatches as MatingBatch[] },
	);
}

export function GetSyncData() {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;
	const lastSyncDate = state.matingBatches.lastSyncDate;

	if (state.matingBatches.syncInProgress) {
		return (dispatch: Dispatch<any>) => {
			return Promise.resolve();
		};
	}
	return AsyncOperationBuilder2(Action.getSyncData, client => client.matingBatch_Sync(siteId, lastSyncDate), {
		siteId,
		lastSyncDate,
	});
}

export function SaveSyncData() {
	const state = StoreContainer.getState();
	const updates = state.matingBatches.updates;

	return async (dispatch: Dispatch<any>) => {
		if (state.matingBatches.saveSyncInProgress) {
			return Promise.resolve();
		}
		if (updates && updates.length) {
			await SaveMatingBatches(updates)(dispatch);
		}
	};
}

export function DeleteMatingBatchesByDate(siteId: string, deletionDate: Date) {
	return AsyncOperationBuilder2(
		Action.deleteMatingBatchesByDate,
		client => client.matingBatch_DeleteMatingBatchesByDate(siteId, deletionDate),
		{ siteId, deletionDate },
	);
}
