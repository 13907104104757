import {
	AnimalKind,
	Farrowing,
	IDiagnose,
	IFarrowing,
	ITasksInFarrowingBase,
	ITreatmentDefinition,
	PregnancyState,
	SowState,
	TasksInFarrowingStableSetting,
	TasksInFarrowingStableSetting2,
	WorkListType,
} from 'shared/api/api';
import { onlyUniqueFilter } from 'shared/helpers/general-helpers';
import { getCycleDays } from 'shared/helpers/pregnancy-helper/generel-pregnancy-helpers';
import { sortPregnanciesByDate } from 'shared/helpers/pregnancy-helper/sort-pregnancies';
import { getActiveSows } from 'shared/helpers/stemanimal-helper/stem-animal-input-helper';
import { getState } from 'shared/helpers/stemanimal-helper/stemanimal-helper';
import {
	FarrowingTaskTreatmentColumn,
	TasksInFarrowingStableItem,
	TasksInFarrowingStableProps,
	TasksInFarrowingStableState,
} from './tasks-in-farrowing-stable-item';

export function genereateTasksFarrowingTableData(
	props: TasksInFarrowingStableProps,
	prevState: TasksInFarrowingStableState,
) {
	let workListData: TasksInFarrowingStableItem[] = [];
	let ActiveSows = getActiveSows();
	// Diagnose and treatment definitions to look for, by worklist settings
	const treatmentDefIds = getTreatmentDefinitionIdsBySettingWithOld(
		props.workListSetting,
		props.treatmentDefinitions,
	).filter(onlyUniqueFilter);

	const treatmentDefRealIds = getTreatmentDefinitionIdsBySetting(props.workListSetting).filter(onlyUniqueFilter);

	const diagnoseIdentifiers = generateDiagnoseIdentifiers(
		treatmentDefIds,
		props.diagnoses,
		props.treatmentDefinitions,
	);
	for (let sow of ActiveSows) {
		let sowState = getState(sow.id!);

		if (sowState !== SowState.Nursing && sowState !== SowState.PregnantAndNursing) {
			continue;
		}
		const sortedPregs = sortPregnanciesByDate(
			props.pregnancyEvents[sow.id!].filter(preg => preg.state === PregnancyState.Farrowing),
		);
		if (!sortedPregs || sortedPregs.length < 0) {
			continue;
		}

		const latestFarrowing = sortedPregs[0] as Farrowing;
		const cycleDays = getCycleDays(sow.id!);

		if (
			cycleDays === undefined ||
			(
				(cycleDays < props.workListSetting.nursingDaysFrom! ||
					cycleDays > props.workListSetting.nursingDaysTo!))
		) {
			continue;
		}

		// Find treatment plans/ treatments for the animal
		const treatmentPlanIds = props.treatmentPlans
			.filter(
				treatPlan =>
					treatPlan.isDeleted !== true &&
					treatmentDefIds.includes(treatPlan.treatmentDefinitionId!) &&
					treatPlan.stemAnimalId === sow.id,
			)
			.map(treatPlan => treatPlan.id);
		const treatPlanIdsCurrentLitter = props.treatments
			.filter(
				treatment =>
					treatmentPlanIds.includes(treatment.treatmentPlanId) &&
					treatment.executedDate &&
					treatment.executedDate.withoutTime() >= latestFarrowing.date!.withoutTime(),
			)
			.map(treat => treat.treatmentPlanId);

		// Compare the used treatment definitions
		const treatmentDefinitionsUsed = props.treatmentPlans
			.filter(
				plan =>
					treatPlanIdsCurrentLitter.includes(plan.id) &&
					props.worklistToTreatmentPlans.find(
						a =>
							props.workListSetting.id === a.workListSettingId &&
							a.diagnoseIdentifier === sow.id + diagnoseIdentifiers[plan.treatmentDefinitionId!],
					),
			)
			.map(e => e.treatmentDefinitionId)
			.filter(onlyUniqueFilter) as string[];
		if (treatmentDefinitionsUsed.length >= treatmentDefRealIds.length) {
			continue;
		}

		let item = new TasksInFarrowingStableItem();
		item.farrowingTaskTreatments = generateFarrowingTaskTreatmentByKey(
			props.workListSetting,
			latestFarrowing,
			props.treatmentDefinitions,
			treatmentDefinitionsUsed,
		);
		item.key = sow.animalNumber;
		item.cycleDays = cycleDays;
		item.animalNumber = sow.animalNumber;
		item.stemAnimalId = sow.id;
		item.isChecked = false;

		workListData.push(item);
	}

	return workListData;
}

export function getTreatmentDefinitionIdsBySettingWithOld(
	workListSetting: ITasksInFarrowingBase,
	treatmentDefinitions: ITreatmentDefinition[],
) {
	const treatmentDefinitionIds = workListSetting.farrowingTaskTreatments!.map(subTask => {
		if (subTask.treatmentDefinitionId) {
			return subTask.treatmentDefinitionId;
		}
		return '';
	});
	const diagnoseIds = treatmentDefinitions
		.filter(def => treatmentDefinitionIds.includes(def.id!))
		.map(def => def.diagnoseId);
	const treatDefsToUse = treatmentDefinitions
		.filter(
			def =>
				def.animalKinds && def.animalKinds.includes(AnimalKind.Piglet) && diagnoseIds.includes(def.diagnoseId),
		)
		.map(def => {
			if (def.id) {
				return def.id;
			}
			return '';
		});
	return treatDefsToUse.filter(id => id !== '');
}

export function getTreatmentDefinitionIdsBySetting(workListSetting: ITasksInFarrowingBase) {
	const treatmentDefinitionIds = workListSetting.farrowingTaskTreatments!.map(subTask => {
		if (subTask.treatmentDefinitionId) {
			return subTask.treatmentDefinitionId;
		}
		return '';
	});

	return treatmentDefinitionIds.filter(id => id !== '');
}

function generateFarrowingTaskTreatmentByKey(
	workListSetting: ITasksInFarrowingBase,
	latestFarrowing: IFarrowing,
	treatmentDefinitions: ITreatmentDefinition[],
	usedIds: string[],
) {
	const columns: FarrowingTaskTreatmentColumn[] = [];
	workListSetting.farrowingTaskTreatments!.forEach(subTask => {
		if (subTask.treatmentDefinitionId) {
			const pcs = subTask.treatAll
				? latestFarrowing.numAlive
				: Math.round((latestFarrowing.numAlive! * (workListSetting.boarPcs! / 100) * 1) / 1);
			const treatmentDefinition = treatmentDefinitions.find(
				treatDef =>
					treatDef.id === subTask.treatmentDefinitionId && treatDef.animalKinds!.includes(AnimalKind.Piglet),
			);
			const amount = treatmentDefinition!.subTreatments![0].drugAmount!.amount;
			let taskTreatment = new FarrowingTaskTreatmentColumn();
			taskTreatment.pcs = pcs;
			taskTreatment.amount = amount;
			taskTreatment.treatmentDefinitionId = treatmentDefinition!.id;
			taskTreatment.subTaskNumber = subTask.subTaskNumber;

			const diagnoseUsed = treatmentDefinition!.diagnoseId;
			let realUsedTreatmentDefIds = treatmentDefinitions.find(
				def => def.diagnoseId === diagnoseUsed && usedIds.includes(def.id!),
			);
			if (realUsedTreatmentDefIds) {
				taskTreatment.used = true;
			}
			columns.push(taskTreatment);
		}
	});
	return columns;
}

function generateDiagnoseIdentifiers(
	ids: string[],
	diagnoses: IDiagnose[],
	treatmentDefinitions: ITreatmentDefinition[],
) {
	let keyValue: { [key: string]: string } = {};
	for (let id of ids) {
		const treatDef = treatmentDefinitions.find(def => def.id === id);
		if (treatDef) {
			const diagnose = diagnoses.find(diag => diag.id === treatDef.diagnoseId);
			if (diagnose) {
				keyValue[id] = diagnose.id! + diagnose.diagnoseCategoryId!;
			}
		}
	}
	return keyValue;
}
