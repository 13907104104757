import { IAnalysisSetting } from 'shared/api/api';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import actionCreatorFactory from 'typescript-fsa';
import * as types from './types';

const actionCreator = actionCreatorFactory(GenActionFactoryName('analysisSettings'));

export const saveAnalysisSettings = actionCreator.async<IAnalysisSetting, string>(types.SAVE_ANALYSIS_SETTING);
export const getBySiteId = actionCreator.async<
	{ siteId: string; activeSiteId?: string },
	IAnalysisSetting[]
>(types.GET_ANALYSIS_SETTINGS_BY_SITE);
