
import actionCreatorFactory from 'typescript-fsa';
import * as types from './types';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import { ICountry } from 'shared/api/api';

const actionCreator = actionCreatorFactory(GenActionFactoryName('countries'));

export const getCountries = actionCreator.async<undefined, ICountry[], { code: number; message: string }>(
    types.GET_COUNTRIES
);
