import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnimalType, DistriwinFeedUnitType, Equipment, GeneralSettings, IGeneralSettings } from 'shared/api/api';
import { hasDanishNucleusFeature } from 'shared/helpers/nucleus-management-helper/nucleus-management-helper';
import { SaveGeneralSettings } from 'shared/state/ducks/general-settings/operations';
import { selectNucleusManagementOrAssignIdAccess } from 'shared/state/ducks/site/reducer';
import { localized } from 'shared/state/i18n/i18n';
import { SharedAppState } from 'shared/state/store.shared';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldTouchableOpacity } from 'web/view/components/skiold-components/skiold-touchable-opacity';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { DistriwinFeedUnitSetting } from './distriwin-feed-unit-type-setting';
import { FarrowingSetting } from './farrowing-settings';
import { GeneralSettingsCommunication } from './general-settings-communication';
import { validateGeneralSettings } from './general-settings-helper';
import './general-settings-overview.scss';
import { MatingSettings } from './mating-settings';
import { SendDataToDistriwinSetting } from './send-data-to-distriwin-setting';
import { UseLettersForAnimalSetting } from './use-letters-for-animal-number-setting';
import { FinisherRegistrationSetting } from './finisher-registration-settings';
import { WeaningSetting } from './weaning-settings';
import { NucleusManagementBreedingSettingsSetup } from '../nucleus-management/settings/breeding-settings/nucleus-management-breeding-settings-setup';
import { WeanerRegistrationSetting } from './weaner-registration-settings';

export const GeneralSettingsOverviewContext = React.createContext({} as any);
export const useGeneralSetting = () => useContext<GeneralSettingsOverviewContextModel>(GeneralSettingsOverviewContext);

interface GeneralSettingsOverviewContextModel {
	generalSetting: IGeneralSettings;
	setGeneralSetting: React.Dispatch<React.SetStateAction<IGeneralSettings>>;
	nucleusManagement: string | undefined;
}
interface Props {
	animalType: AnimalType;
}

export const GeneralSettingsOverview: FC<Props> = React.memo(props => {
	const dispatch = useDispatch();
	const siteId = useSelector((state: SharedAppState) => state.profile.active?.siteId);
	const nucleusManagement = useSelector(selectNucleusManagementOrAssignIdAccess);
	const hasDistriwin = useSelector((state: SharedAppState) =>
		state.processEquipments.entities.find(e => e.equipment === Equipment.Distriwin),
	);
	const hasEsf = useSelector((state: SharedAppState) =>
		state.processEquipments.entities.find(e => e.equipment === Equipment.ESF),
	);

	const [generalOptionsSow, setGeneralOptionsSow] = useState<string[]>(['SetupEvent', 'Generalt']);
	const [generalOptionsGpe, setGeneralOptionsGpe] = useState<string[]>(['SetupRegistration']);
	const currentGeneralSetting = useSelector((state: SharedAppState) => state.generalSettings.entity);
	useEffect(() => {
		let sow = ['SetupEvent', 'Generalt'];
		let gpe = ['SetupRegistration'];
		if (hasDistriwin || hasEsf) {
			sow.push('Communication');
		}
		sow.push('Feed');
		gpe.push('Feed');
		if (hasDistriwin) {
			gpe.push('Communication');
		}
		if (currentGeneralSetting.assignNumbersToBornPiglets) {
			sow.push('BreedingNumberAndNumberBatch');
		}
		setGeneralOptionsSow(sow);
		setGeneralOptionsGpe(gpe);
	}, [hasDistriwin, hasEsf, currentGeneralSetting]);

	const [generalSetting, setGeneralSetting] = useState<IGeneralSettings>(
		GeneralSettings.fromJS({
			defaultWeightWeaning: 0,
			distriwinFeedUnitType: DistriwinFeedUnitType.FEsoFEsv,
			siteId,
		}),
	);

	const generalOptions = useMemo(() => {
		return props.animalType === AnimalType.Sow ? generalOptionsSow : generalOptionsGpe;
	}, [props.animalType, generalOptionsSow, generalOptionsGpe]);
	const [generalOption, setGeneralOption] = useState<string>(generalOptions[0]);

	useEffect(() => {
		if (currentGeneralSetting && currentGeneralSetting.siteId) {
			setGeneralSetting(currentGeneralSetting);
		}
	}, [currentGeneralSetting]);

	const saveGeneralSettings = () => {
		if (!validateGeneralSettings(generalSetting)) {
			return;
		}
		SaveGeneralSettings(generalSetting)(dispatch);
	};

	const renderButtons = () => {
		return (
			<ViewWeb className="view-button-container">
				<SkioldButton title={localized('Save')} onPress={saveGeneralSettings} />
			</ViewWeb>
		);
	};

	const renderOption = (option: string) => {
		const onPress = () => {
			setGeneralOption(option);
		};

		return (
			<ViewWeb key={option} className="animal-report-type-picker">
				<SkioldTouchableOpacity onPress={onPress}>
					<ViewWeb className="animal-report-type-picker">
						<TextWeb className="item-text-style">{localized(option)}</TextWeb>
					</ViewWeb>
				</SkioldTouchableOpacity>
			</ViewWeb>
		);
	};

	return (
		<GeneralSettingsOverviewContext.Provider value={{ generalSetting, setGeneralSetting, nucleusManagement }}>
			<ViewWeb className="general-settings-overview">
				<ViewWeb className="sub-container">
					<TextWeb className="header-text-style">{localized('General')}</TextWeb>
					{generalOptions.map(type => renderOption(type))}
				</ViewWeb>
				<ViewWeb className="setup-container">
					{/* This is a generalSetting options, which allow sites to set numbers on piglets */}
					{generalOption === 'BreedingNumberAndNumberBatch' ? (
						<>
							<NucleusManagementBreedingSettingsSetup />
						</>
					) : (
						<ViewWeb className="settings-width-600">
							<TextWeb className="text-style">{localized('Setup')}</TextWeb>
							{generalOption === 'SetupEvent' && (
								<>
									{hasDanishNucleusFeature(nucleusManagement) && <MatingSettings />}
									<FarrowingSetting />
									<WeaningSetting />
								</>
							)}
							{generalOption === 'Generalt' && (
								<>
									<UseLettersForAnimalSetting />
								</>
							)}
							{generalOption === 'SetupRegistration' && props.animalType === AnimalType.Finisher && (
								<>
									<FinisherRegistrationSetting />
								</>
							)}
							{generalOption === 'SetupRegistration' && props.animalType === AnimalType.Weaner && (
								<>
									<WeanerRegistrationSetting />
								</>
							)}
							{generalOption === 'Communication' && (hasDistriwin || hasEsf) && (
								<>
									<GeneralSettingsCommunication animalType={props.animalType} />
									{props.animalType !== AnimalType.Sow && (
										<SendDataToDistriwinSetting animalType={props.animalType} />
									)}
								</>
							)}
							{generalOption === 'Feed' && (
								<>
									<DistriwinFeedUnitSetting />
								</>
							)}

							{generalOption !== 'BreedingNumberAndNumberBatch' && renderButtons()}
						</ViewWeb>
					)}
				</ViewWeb>
			</ViewWeb>
		</GeneralSettingsOverviewContext.Provider>
	);
});
