import { createReducer, createSlice } from '@reduxjs/toolkit';
import { getRecipes } from './operation';
import { RecipeState } from './types';
import { SharedAppState } from 'shared/state/store.shared';
import { IRecipeDto, RecipeDto } from 'shared/api/api';

const initialState: RecipeState = {
	recipes: [],
};

const recipeReducer = createReducer(initialState, builder => {
	builder.addCase(getRecipes.fulfilled, (state, action) => {
		state.recipes = action.payload ?? [];
	});
	builder.addCase(getRecipes.pending, (state, action) => {
	});
	builder.addCase(getRecipes.rejected, (state, action) => {
	});

	builder.addCase('persist/REHYDRATE', (state, action) => {
		let a = ((action as any) as { payload: SharedAppState; key: string }) as any;

		if (a.key === 'root') {
			let entities = new Array<IRecipeDto>();

			if (a.payload && a.payload.recipe.recipes) {
				entities = a.payload.recipe.recipes.map((dt: IRecipeDto) => {
					let ndt = RecipeDto.fromJS({});
					ndt.init(dt);
					return ndt;
				});
			}
			state.recipes = entities;
		}
	});
});

export default recipeReducer;
