import { Dispatch } from 'redux';
import * as Action from './actions';
import { IDashboardSettings, DashboardSettings } from 'shared/api/api';
import { AsyncOperationBuilder2, AsyncOperationHandler } from 'shared/helpers/redux-helpers';
import ObjectID from 'bson-objectid';
import { StoreContainer } from 'shared/state/store-container';
import { createAsyncThunk } from '@reduxjs/toolkit';

export function SaveDashboardSettings(settings: IDashboardSettings) {
	if (!settings.id) {
		settings.id = new ObjectID().toHexString();
	}
	let s = DashboardSettings.fromJS(settings);
	return AsyncOperationBuilder2(Action.saveSettings, client => client.dashboardSettings_Upsert(s), settings);
}

export function GetSyncData() {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;
	const lastSyncDate = state.dashboardSettings.lastSyncDate;

	if (state.dashboardSettings.syncInProgress) {
		return (dispatch: Dispatch<any>) => {
			return Promise.resolve();
		};
	}
	return AsyncOperationBuilder2(Action.getSyncData, client => client.dashboardSettings_Sync(siteId, lastSyncDate), {
		siteId,
		lastSyncDate,
	});
}

export const GetDashBoardSettings = createAsyncThunk('dashboardSettings/getSettings', (siteId: string) => {
	return AsyncOperationHandler(client => client.dashboardSettings_GetSettings(siteId));
});
