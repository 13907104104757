import { Action } from 'redux';
import { ILardScanningEvent, LardScanningEvent } from 'shared/api/api';
import {
	mergeArrays,
	removeMultipleValuesFromArray,
	removeValueFromArray,
	upsertValueInArray,
} from 'shared/helpers/reducer-helpers';
import { SyncableInitialState } from 'shared/state/models/syncable';
import { isType } from 'typescript-fsa';
import { siteChange } from '../profile/actions';
import * as actions from './actions';
import { getSyncData, saveLardScanningEvent } from './actions';
import { LardScanningEventState } from './types';
import { fetchSingleAnimalInfo, getDeparturedAnimalDataByDate } from '../stem-animals/actions';
import { getSyncModelData } from '../sync/actions';

export const initialState: LardScanningEventState = {
	...SyncableInitialState,
	departuredLardEvents: [],
};

const lardScanningEventReducer = (
	state: LardScanningEventState = initialState,
	action: Action,
): LardScanningEventState => {
	if (isType(action, getSyncData.started)) {
		state = { ...state, syncInProgress: true };
	}

	if (isType(action, getSyncModelData.done)) {
		if (action.payload.params.siteId === action.payload.params.activeSiteId) {
			if (action.payload.result.lardScanningEvents && action.payload.result.lardScanningEvents.datas!.length) {
				state = {
					...state,
					entities: mergeArrays(state.entities, action.payload.result.lardScanningEvents.datas!),
					lastSyncDate: action.payload.result.lardScanningEvents.syncTime!,
				};
			}
		}
		state = {
			...state,
			syncInProgress: false,
		};
	}

	if (isType(action, getSyncData.done)) {
		if (action.payload.params.siteId === action.payload.params.activeSiteId) {
			if (action.payload.result.datas!.length) {
				state = {
					...state,
					entities: mergeArrays(state.entities, action.payload.result.datas!),
					lastSyncDate: action.payload.result.syncTime!,
				};
			}
		}
		state = {
			...state,
			syncInProgress: false,
		};
	}

	if (isType(action, getSyncData.failed)) {
		state = { ...state, syncInProgress: false };
	}

	if (isType(action, saveLardScanningEvent.started)) {
		if (action.payload && action.payload.isDeleted && action.payload.id) {
			state = {
				...state,
				entities: removeValueFromArray(state.entities, action.payload.id),
				updates: upsertValueInArray(state.updates, action.payload),
				saveSyncInProgress: true,
			};
		} else if (action.payload) {
			state = {
				...state,
				entities: upsertValueInArray(state.entities, action.payload),
				updates: upsertValueInArray(state.updates, action.payload),
				saveSyncInProgress: true,
			};
		}
	}

	if (isType(action, saveLardScanningEvent.failed)) {
		if (action.payload.error.code === 500 && action.payload.params) {
			if (action.payload.error.prevEntity) {
				state = { ...state, entities: upsertValueInArray(state.entities, action.payload.error.prevEntity) };
			} else {
				state = { ...state, entities: removeValueFromArray(state.entities, action.payload.params.id!) };
			}
			state = { ...state, updates: removeValueFromArray(state.updates, action.payload.params.id!) };
		}

		state = { ...state, saveSyncInProgress: false };
		return state;
	}

	if (isType(action, saveLardScanningEvent.done)) {
		state = { ...state, updates: removeValueFromArray(state.updates, action.payload.result) };
		state = { ...state, saveSyncInProgress: false };
		return state;
	}

	if (isType(action, siteChange.done)) {
		state = initialState;
	}

	if (isType(action, actions.removeLardScanningEvent.started)) {
		const idsToRemove = state.entities
			.filter((lardEvent: any) => lardEvent.stemAnimalId === action.payload)
			.map((lardEvent: any) => lardEvent.id!);
		if (idsToRemove && idsToRemove.length > 0) {
			state = {
				...state,
				entities: removeMultipleValuesFromArray(state.entities, idsToRemove),
			};
		}
	}

	if (isType(action, actions.getDeparturedLardEvents.done)) {
		if (action.payload.params.siteId === action.payload.params.activeSiteId) {
			state = {
				...state,
				departuredLardEvents: action.payload.result,
			};
		}
	}

	if (isType(action, getDeparturedAnimalDataByDate.done)) {
		if (action.payload.result.departedLardScanningEvents) {
			state = {
				...state,
				departuredLardEvents: action.payload.result.departedLardScanningEvents,
			};
		}
	}

	if (isType(action, fetchSingleAnimalInfo.done)) {
		if (action.payload.result.stemAnimal && action.payload.result.lardScanningEvents) {
			if (
				action.payload.result.stemAnimal.departureDate &&
				action.payload.result.stemAnimal.departureDate <= new Date()
			) {
				state = {
					...state,

					departuredLardEvents: mergeArrays(
						state.departuredLardEvents,
						action.payload.result.lardScanningEvents,
					),
				};
			} else {
				state = {
					...state,

					entities: mergeArrays(state.entities, action.payload.result.lardScanningEvents),
				};
			}
		}
	}

	//Ensure Date objects are in fact date and not strings - redux-persist serializes dates to string but doesn't deserialize to dates again
	if (action.type === 'persist/REHYDRATE') {
		let a = (action as any) as { payload: LardScanningEventState; key: string };

		if (a.key === 'lardScanningEvents') {
			let entities = new Array<LardScanningEvent>();
			let updates = new Array<LardScanningEvent>();
			let lastSyncDate = SyncableInitialState.lastSyncDate;

			if (a.payload && a.payload && a.payload.entities) {
				entities = a.payload.entities.map((event: ILardScanningEvent) => {
					let newEvent = LardScanningEvent.fromJS({});
					newEvent.init(event);
					return newEvent;
				});
			}

			if (a.payload && a.payload && a.payload.updates) {
				updates = a.payload.updates.map((event: ILardScanningEvent) => {
					let newEvent = LardScanningEvent.fromJS({});
					newEvent.init(event);
					return newEvent;
				});
			}

			if (a.payload && a.payload && a.payload.lastSyncDate) {
				lastSyncDate = new Date(a.payload.lastSyncDate);
			}

			state = { ...state, entities, updates, lastSyncDate };
		}
	}

	return state;
};

export default lardScanningEventReducer;
