import React from 'react';
import { Option } from 'react-dropdown';
import { connect } from 'react-redux';
import { AnimalKind, IPoolYoungFemale, IStemAnimal, PoolYoungFemale } from 'shared/api/api';
import ForwardArrowGrey from 'shared/assets/src-assets/png/forward_arrow_bottom_grey.png';
import {
	CheckIfSectionUsesPens,
	FindHiddenPenOnSection,
	getFilteredLocationsForYoungFemale,
} from 'shared/helpers/location-helper';
import {
	DefaultEntranceTypeOption,
	RegisterPoolYoungFemaleProps,
	RegisterPoolYoungFemaleState,
	poolYoungFemaleMapDispatchToProps,
	poolYoungFemaleMapStateToProps,
	validatePoolYoungFemale,
} from 'shared/helpers/pool-young-female-helper/pool-young-female-helper';
import { DefaultReasonOption, getReasonOptions } from 'shared/helpers/reason-helper/reason-helper';
import { getYoungAnimals } from 'shared/helpers/stemanimal-helper/stem-animal-input-helper';
import { localized } from 'shared/state/i18n/i18n';
import { DepartureTypes } from 'shared/state/models/departure-types';
import SkioldFormPenPicker from 'web/view/components/location/location-picker/skiold-form-pen-picker';
import SkioldStableSectionPicker from 'web/view/components/location/location-picker/skiold-stable-section-picker';
import { showAlert } from 'web/view/components/skiold-alert/skiold-alert';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldDatePicker } from 'web/view/components/skiold-components/skiold-date-picker/skiold-date-picker';
import { SkioldFormDropdown } from 'web/view/components/skiold-components/skiold-dropdown/skiold-form-dropdown';
import { SkioldFormsWrapper } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { SkioldFormIntegerInput } from 'web/view/components/skiold-components/skiold-integer-input/skiold-form-integer-input';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { SkioldModal } from '../../skiold-components/skiold-modal/skiold-modal';
import { SkioldImage } from '../../utils/svg/skiold-image';
import SearchYoungAnimals from '../sow-events/search-animals/search-young-animals';
import './register-pool-young-female.scss';

export class RegisterPoolYoungFemale extends React.PureComponent<
	RegisterPoolYoungFemaleProps,
	RegisterPoolYoungFemaleState
> {
	// Handles pool young female to edit
	public static getDerivedStateFromProps(
		nextProps: RegisterPoolYoungFemaleProps,
		prevState: RegisterPoolYoungFemaleState,
	) {
		if (
			nextProps.poolYoungFemaleId &&
			(!prevState.editModel || nextProps.poolYoungFemaleId !== prevState.editModel.id)
		) {
			const poolYoungFemale = nextProps.poolYoungFemales.find(event => event.id === nextProps.poolYoungFemaleId);
			if (poolYoungFemale) {
				const reasonOptions = getReasonOptions(
					nextProps.reasons,
					AnimalKind.YoungFemale,
					poolYoungFemale.eventType ? poolYoungFemale.eventType : '',
				);
				const eventOption = prevState.eventTypes.find(type => type.value === poolYoungFemale.eventType);

				const reasonOption = reasonOptions.find(reason => reason.value === poolYoungFemale.departureReasonId);
				const pen = nextProps.locations.pens.find(p => p.id === poolYoungFemale.penId);
				const sectionId = pen ? nextProps.locations.sections.find(sec => sec.id === pen.sectionId) : undefined;
				return {
					date: poolYoungFemale.registrationDate,
					sectionId: sectionId,
					penId: sectionId && poolYoungFemale.penId,
					totalAmount: poolYoungFemale.total,
					eventType: eventOption ? eventOption : DefaultReasonOption,
					reasonOption: reasonOption ? reasonOption : DefaultReasonOption,
					reasonOptions: reasonOptions,
					editModel: poolYoungFemale,
				};
			}
		}
		return {};
	}

	constructor(props: RegisterPoolYoungFemaleProps) {
		super(props);
		this.state = {
			date: new Date(),
			sectionId: undefined,
			penId: undefined,
			totalAmount: undefined,
			modalIsOpen: false,
			youngAnimal: undefined,
			eventType: DefaultReasonOption,
			reasonOptions: [DefaultReasonOption],
			eventTypes: [
				DefaultReasonOption,
				DefaultEntranceTypeOption(),
				{
					label: localized(DepartureTypes.departureTypeSold),
					value: DepartureTypes.departureTypeSold,
				},
				{
					label: localized(DepartureTypes.departureTypeDead),
					value: DepartureTypes.departureTypeDead,
				},
				{
					label: localized(DepartureTypes.departureTypeKilled),
					value: DepartureTypes.departureTypeKilled,
				},
				{
					label: localized(DepartureTypes.departureTypePutDown),
					value: DepartureTypes.departureTypePutDown,
				},
				{
					label: localized(DepartureTypes.departureTypeShouldDeparture),
					value: DepartureTypes.departureTypeShouldDeparture,
				},
			],
			reasonOption: DefaultReasonOption,
			editModel: undefined,
		};
	}

	public dateChanged = (newDate: Date) => {
		this.setState({ date: newDate });
	};

	public totalAmountChanged = (newTotalAmount: number | undefined) => {
		this.setState({ totalAmount: newTotalAmount });
	};

	public eventTypeChanged = (newEventType: Option) => {
		// Reasons is only for departure events
		const type =
			newEventType && newEventType.value && newEventType.value !== DefaultEntranceTypeOption().value
				? newEventType.value
				: '';
		const reasonOptions = getReasonOptions(this.props.reasons, AnimalKind.YoungFemale, type);
		const currentReasonAvailable = reasonOptions.findIndex(
			reason => reason.value === this.state.reasonOption.value,
		);
		let state: any = { eventType: newEventType, reasonOptions };

		// Don't show reason, if now valid for event type
		if (currentReasonAvailable === -1) {
			state = { ...state, reasonOption: DefaultReasonOption };
		}
		this.setState(state);
	};

	public departureReasonChanged = (newDepartureReason: Option) => {
		this.setState({ reasonOption: newDepartureReason });
	};

	private sectionSelected = (selectedSectionId: string) => {
		if (CheckIfSectionUsesPens(selectedSectionId)) {
			if (this.props.locations.pens.find(pen => pen.sectionId === selectedSectionId)) {
				this.setState({ sectionId: selectedSectionId });
			} else {
				this.setState({ penId: '', sectionId: selectedSectionId });
			}
		} else {
			let penId = FindHiddenPenOnSection(selectedSectionId, this.state.penId);
			if (penId) {
				this.setState({ penId, sectionId: selectedSectionId });
			}
		}
	};

	private penSelected = (selectedPenId: string) => {
		this.setState({ penId: selectedPenId });
	};

	public render() {
		return (
			<ViewWeb className="register-pool-young-female">
				{this.renderButtons()}
				<ViewWeb className="content-container">
					<ViewWeb>
						<SkioldFormsWrapper formRows={this.getFormRows()} containerClassName="forms-wrapper-style" />
					</ViewWeb>
				</ViewWeb>
				{this.renderModal()}
			</ViewWeb>
		);
	}

	public save = async () => {
		if (await validatePoolYoungFemale(this.state, showAlert)) {
			let poolYoungFemale: IPoolYoungFemale = PoolYoungFemale.fromJS({});
			const { date, penId, totalAmount, eventType, reasonOption } = this.state;
			if (this.state.editModel) {
				poolYoungFemale = { ...this.state.editModel };
			}

			poolYoungFemale.registrationDate = date!;
			poolYoungFemale.penId = penId;
			poolYoungFemale.total = totalAmount!;
			poolYoungFemale.eventType = eventType.value;
			poolYoungFemale.departureReasonId = reasonOption.value;
			poolYoungFemale.siteId = this.props.active.siteId;
			poolYoungFemale.stemAnimalId = this.state.youngAnimal?.id;
			poolYoungFemale.idNumber = this.state.youngAnimal?.idNumber;
			this.props.savePoolYoungFemale(poolYoungFemale);
			if (this.state.youngAnimal) {
				let saveYoungAnimal = { ...this.state.youngAnimal };
				saveYoungAnimal.departureReasonId = this.state.reasonOption?.value;
				saveYoungAnimal.departureDate = this.state.date;
				saveYoungAnimal.departureType = this.state.eventType.value;
				this.props.updateYoungAnimal(saveYoungAnimal);
			}
			await this.resetComponent();
			this.editDone();
		}
	};

	private getFormRows() {
		let formRows = new Array<FormRow>();
		formRows.push(this.getDateRow());
		formRows.push(this.getStableSectionPickerRow());
		if (this.state.sectionId) {
			if (CheckIfSectionUsesPens(this.state.sectionId)) {
				formRows.push(this.getPenPickerRow());
			}
		}
		formRows.push(this.getTotalAmoutRow());
		formRows.push(this.getEventTypeRow());
		if (this.state.eventType.value && this.state.eventType.value !== DefaultEntranceTypeOption().value) {
			formRows.push(this.getReasonsRow());
		}
		if (
			this.props.generalSettings.registerIdForTurnoverPoolYoungAnimals &&
			[
				DepartureTypes.departureTypeSold,
				DepartureTypes.departureTypeKilled,
				DepartureTypes.departureTypeDead,
				DepartureTypes.departureTypePutDown,
			].includes(this.state.eventType.value)
		) {
			formRows.push(this.getIdNumberRow());
		}

		return formRows;
	}

	private getDateRow(): FormRow {
		return {
			name: localized('Date'),
			component: (
				<SkioldDatePicker
					onDateChanged={this.dateChanged}
					selectedDate={this.state.date!}
					theme={'dark'}
					color={'grey'}
				/>
			),
		};
	}

	private getTotalAmoutRow(): FormRow {
		return {
			name: localized('Count'),
			component: (
				<SkioldFormIntegerInput onChangeNumber={this.totalAmountChanged} text={this.state.totalAmount} />
			),
		};
	}

	private getReasonsRow(): FormRow {
		return {
			name: localized('reasons'),
			component: (
				<SkioldFormDropdown
					items={this.state.reasonOptions}
					selectedValue={this.state.reasonOption}
					onValueChanged={this.departureReasonChanged}
				/>
			),
		};
	}

	private getEventTypeRow(): FormRow {
		return {
			name: localized('Type'),
			component: (
				<SkioldFormDropdown
					items={this.state.eventTypes}
					selectedValue={this.state.eventType}
					onValueChanged={this.eventTypeChanged}
				/>
			),
		};
	}

	private getStableSectionPickerRow(): FormRow {
		return {
			name: localized('StableSection'),
			component: (
				<SkioldStableSectionPicker
					usedInsideForm={true}
					filteredLocations={getFilteredLocationsForYoungFemale(this.props.locations)}
					onStableSectionSelected={this.sectionSelected}
					sectionId={this.state.sectionId}
					penId={this.state.penId}
					isPigProduction={true}
				/>
			),
		};
	}

	private getPenPickerRow(): FormRow {
		return {
			name: localized('Pen'),
			component: (
				<SkioldFormPenPicker
					usedInsideForm={true}
					onPenSelected={this.penSelected}
					sectionId={this.state.sectionId}
					penId={this.state.penId}
				/>
			),
		};
	}
	private getIdNumberRow(): FormRow {
		return {
			name: localized('idNumber'),
			component: (
				<ViewWeb className="margin-right-eight" onClick={this.openModal}>
					<ViewWeb className="display-flex-direction-row">
						<TextWeb className="padding-right-five-font-size-sixteen">
							{this.state.youngAnimal?.idNumber || localized('ChooseIdNumber')}
						</TextWeb>
						<ViewWeb className="picker-young-animals-view-style">
							<SkioldImage
								width={18}
								height={18}
								imageData={ForwardArrowGrey}
								className="image-picker-view-style"
							/>
						</ViewWeb>
					</ViewWeb>
				</ViewWeb>
			),
		};
	}

	private renderButtons() {
		return (
			<ViewWeb className="button-view-style-upper-corner">
				{this.props.closeEditModal && (
					<SkioldButton title={localized('Close')} onPress={this.editDone} theme="grey" />
				)}

				<SkioldButton title={localized('Save')} onPress={this.save} />
			</ViewWeb>
		);
	}

	private openModal = () => {
		if (!this.state.totalAmount || this.state.totalAmount <= 1) {
			this.setState({ modalIsOpen: true });
		} else {
			showAlert(localized('IdNumberDisableIfGreaterThan1'));
		}
	};

	private closeModal = () => this.setState({ modalIsOpen: false });

	private setYoungAnimal = (youngAnimal: IStemAnimal | undefined) => {
		this.setState({
			modalIsOpen: false,
			youngAnimal,
		});
	};

	private renderModal() {
		return (
			<SkioldModal isOpen={this.state.modalIsOpen} close={this.closeModal}>
				<SearchYoungAnimals close={this.setYoungAnimal} youngAnimals={getYoungAnimals()} />
			</SkioldModal>
		);
	}

	private editDone = () => {
		if (this.props.closeEditModal) {
			this.props.closeEditModal();
		}
	};

	// Called after successfull save to remove one-time values
	private async resetComponent() {
		this.setState({ totalAmount: undefined, reasonOption: DefaultReasonOption, youngAnimal: undefined });
	}
}

export default connect(poolYoungFemaleMapStateToProps, poolYoungFemaleMapDispatchToProps)(RegisterPoolYoungFemale);
