import ObjectID from 'bson-objectid';
import React from 'react';
import { connect } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { Dispatch } from 'redux';
import { AnimalKind, IDiagnose, IDiagnoseCategory, IImage, Image } from 'shared/api/api';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { deepCopy } from 'shared/helpers/general-helpers';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { CreateDiagnoseImage, GetDiagnoseImage, SaveDiagnose } from 'shared/state/ducks/diagnoses/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { showAlert } from 'web/view/components/skiold-alert/skiold-alert';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { SkioldButton } from '../skiold-components/skiold-button/skiold-button';
import { SkioldCheckbox } from '../skiold-components/skiold-checkbox/skiold-checkbox';
import { SkioldDropdown } from '../skiold-components/skiold-dropdown/skiold-dropdown';
import { SkioldTextField } from '../skiold-components/skiold-text-field/skiold-text-field';
import './add-diagnose.scss';
import { SkioldTextArea } from '../skiold-components/skiold-text-area/skiold-text-area';
import { Colors } from 'shared/assets/root-assets/colors';
import { Option } from 'react-dropdown';
interface PropsFromParent {
	close: () => void;
	diagnose?: IDiagnose;
	isOpen: boolean;
	diagnoseCategories: IDiagnoseCategory[];
}

const mapStateToProps = (state: WebAppState) => {
	return {
		diagnoses: state.diagnose.entities,
		language: state.profile.active!.language,
		diagnoseImages: state.diagnose.diagnoseImages,
		finishedSaving: state.diagnose.finishedSaving,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	postDiagnose: (diagnose: IDiagnose) => SaveDiagnose(diagnose)(dispatch),
	postImage: (image: IImage) => CreateDiagnoseImage(image)(dispatch),
	getImage: (id: string) => GetDiagnoseImage(id)(dispatch),
});

interface State {
	diagnose: IDiagnose;
	diagnoseImage?: IImage;
	selectedDiagnoseOption?: Option;
	showSpinnerAboveDeleteButton?: boolean;
	showSpinnerAboveSaveButton?: boolean;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;
export class AddDiagnose extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		let diagnose = props.diagnose
			? props.diagnose
			: (({ animalKinds: [], name: {}, description: {} } as unknown) as IDiagnose);
		let diagnoseCategoryOptions = this.getDiagnoseCategorySelectionItems();
		let selectedCategory = diagnoseCategoryOptions.find(option => option.value === diagnose.diagnoseCategoryId);
		this.state = {
			diagnose,
			diagnoseImage: undefined,
			showSpinnerAboveDeleteButton: false,
			showSpinnerAboveSaveButton: false,
			selectedDiagnoseOption: selectedCategory ? selectedCategory : { value: ' ', label: ' ' },
		};
	}

	public componentDidMount() {
		this.getDiagnoseImage();
	}

	public render() {
		return (
			<ViewWeb className="add-diagnose">
				<TextWeb className={'white-text'}>{localized('Diagnose')}</TextWeb>
				<SkioldTextField
					onChangeText={text => this.setName(text, 'da')}
					label={localized('diagnoseNameDa')}
					className={'white-text'}
					value={this.state.diagnose.name!.da}
				/>

				<SkioldTextField
					onChangeText={text => this.setName(text, 'en')}
					className={'white-text'}
					label={localized('diagnoseNameEn')}
					value={this.state.diagnose.name!.en}
				/>

				<SkioldTextArea
					onChangeText={text => this.setDescription(text, 'da')}
					label={localized('diagnoseDescriptionDa')}
					className={'white-text'}
					text={this.state.diagnose.description!.da}
					numberOfLines={2}
				/>

				<SkioldTextArea
					onChangeText={text => this.setDescription(text, 'en')}
					label={localized('diagnoseDescriptionEn')}
					text={this.state.diagnose.description!.en}
					className={'white-text'}
					numberOfLines={2}
				/>

				<ViewWeb>
					<TextWeb className={'white-text'}>{localized('category')}</TextWeb>
					<SkioldDropdown
						items={this.getDiagnoseCategorySelectionItems()}
						selectedValue={this.state.selectedDiagnoseOption}
						theme={'light'}
						onValueChanged={(itemValue: Option) =>
							this.setState(preState => ({
								diagnose: { ...preState.diagnose, diagnoseCategoryId: itemValue.value },
								selectedDiagnoseOption: itemValue,
							}))
						}
					/>
				</ViewWeb>

				<ViewWeb>
					<TextWeb className={'white-text'}>{localized('pigTypes')}</TextWeb>
					<div className="pigtype-checkbox-container">
						<SkioldCheckbox
							title={localized('sowBoars')}
							isChecked={
								this.hasAnimalKind(AnimalKind.Sow, this.state.diagnose) &&
								this.hasAnimalKind(AnimalKind.Boar, this.state.diagnose)
							}
							onClick={this.toggleAnimalsSowBoarsGilt}
						/>
						<TextWeb className={'white-text'}>{localized('sowBoars')}</TextWeb>
					</div>

					<div className="pigtype-checkbox-container">
						<SkioldCheckbox
							title={localized('piglets')}
							isChecked={this.hasAnimalKind(AnimalKind.Piglet, this.state.diagnose)}
							onClick={this.toggleAnimalsPiglets}
						/>
						<TextWeb className={'white-text'}>{localized('piglets')}</TextWeb>
					</div>

					<div className="pigtype-checkbox-container">
						<SkioldCheckbox
							title={localized('youngFemales')}
							isChecked={this.hasAnimalKind(AnimalKind.YoungFemale, this.state.diagnose)}
							onClick={this.toggleAnimalsYoungFemales}
						/>
						<TextWeb className={'white-text'}>{localized('youngFemales')}</TextWeb>
					</div>
				</ViewWeb>

				<form>
					<input
						className={'white-text'}
						type="file"
						onChange={this.inputImageChanged}
						accept=".png,.jpg,.jpeg"
					/>
				</form>

				{this.state.diagnoseImage && (
					<img alt={''} src={this.state.diagnoseImage.base64Data} className="diagnose-image" />
				)}

				<ViewWeb className="button-container">
					<ViewWeb>
						{this.state.showSpinnerAboveSaveButton && (
							<ViewWeb className="clip-loader-view-container-style">
								<ClipLoader color={Colors.white} size={35} />
							</ViewWeb>
						)}
						<SkioldButton title={localized('Save')} onPress={this.save} />
					</ViewWeb>

					{this.state.diagnose.id && (
						<ViewWeb>
							{this.state.showSpinnerAboveDeleteButton && (
								<ViewWeb className="clip-loader-view-container-style">
									<ClipLoader color={Colors.white} size={35} />
								</ViewWeb>
							)}

							<SkioldButton title={localized('delete')} onPress={this.deleteDiagnose} />
						</ViewWeb>
					)}

					<SkioldButton theme="grey" title={localized('Close')} onPress={this.closeModal} />
				</ViewWeb>
			</ViewWeb>
		);
	}

	private closeModal = () => this.props.close();
	private deleteDiagnose = () => this.delete(this.state.diagnose.id!);
	private toggleAnimalsSowBoarsGilt = () =>
		this.toggleAnimalKinds([AnimalKind.Sow, AnimalKind.Boar, AnimalKind.Gilt]);
	private toggleAnimalsPiglets = () => this.toggleAnimalKinds([AnimalKind.Piglet]);
	private toggleAnimalsYoungFemales = () => this.toggleAnimalKinds([AnimalKind.YoungFemale]);
	private inputImageChanged = (res: React.ChangeEvent<HTMLInputElement>) => this.imageChanged(res.target.files);

	private getDiagnoseImage() {
		if (!this.state.diagnose || !this.state.diagnose.imageId) {
			return;
		}

		// See if we already have the image
		const index = this.props.diagnoseImages.findIndex(
			img => img !== null && img.id === this.state.diagnose.imageId,
		);
		if (index > -1) {
			const img = this.props.diagnoseImages[index];
			this.setState({ diagnoseImage: img });
		} else {
			// Else get from backend
			this.props.getImage(this.state.diagnose.imageId).then(res => {
				const imageIndex = this.props.diagnoseImages.findIndex(
					img => img !== null && img.id === this.state.diagnose.imageId,
				);
				if (imageIndex > -1) {
					const img = this.props.diagnoseImages[imageIndex];
					this.setState({ diagnoseImage: img });
				}
			});
		}
	}

	private imageChanged(res: any) {
		const selectedFile = res[0];
		const fr = new FileReader();
		fr.onload = (readResult: any) => {
			const result = readResult.target!.result ? readResult.target!.result : '';

			const newImage = Image.fromJS({});

			newImage.id = new ObjectID().toHexString();

			newImage.base64Data = result;

			this.setState({ diagnoseImage: newImage });
		};
		fr.readAsDataURL(selectedFile);
	}

	private toggleAnimalKinds(animalKinds: AnimalKind[]) {
		const listCopy = deepCopy(this.state.diagnose.animalKinds!);
		for (const kind of animalKinds) {
			const index = listCopy.findIndex(ak => ak === kind);
			if (index > -1) {
				listCopy.splice(index, 1);
			} else {
				listCopy.push(kind);
			}
		}
		this.setState(preState => ({ diagnose: { ...preState.diagnose, animalKinds: listCopy } }));
	}

	private hasAnimalKind(animalKind: AnimalKind, diagnose: IDiagnose) {
		if (diagnose.animalKinds!.findIndex(kind => kind === animalKind) > -1) {
			return true;
		}

		return false;
	}

	private getDiagnoseCategorySelectionItems() {
		if (!this.props.language) {
			return [];
		}

		const categories = this.props.diagnoseCategories.sort((a: IDiagnoseCategory, b: IDiagnoseCategory) => {
			if (a.priority! > b.priority!) {
				return 1;
			}
			if (a.priority! < b.priority!) {
				return -1;
			}
			return 0;
		});

		let pickerItems: Option[] = categories.map(dc => {
			const name = dc.name![this.props.language!];
			const element = { label: name, value: dc.id! };
			return element;
		});

		pickerItems.unshift({ label: localized('select'), value: ' ' });

		return pickerItems;
	}

	private setName(value: string, countryCode: string) {
		const nameDict = deepCopy(this.state.diagnose.name);
		if (countryCode === 'da') {
			nameDict!.da = value;
		} else if (countryCode === 'en') {
			nameDict!.en = value;
		}

		this.setState(prevState => ({ diagnose: { ...prevState.diagnose, name: nameDict } }));
	}

	private setDescription(value: string, countryCode: string) {
		const descriptionDict = deepCopy(this.state.diagnose.description);
		if (countryCode === 'da') {
			descriptionDict!.da = value;
		} else if (countryCode === 'en') {
			descriptionDict!.en = value;
		}

		this.setState(prevState => ({ diagnose: { ...prevState.diagnose, description: descriptionDict } }));
	}

	private save = async () => {
		if (!this.validate()) {
			return;
		}
		this.setState({ showSpinnerAboveSaveButton: true });
		const diag = deepCopy(this.state.diagnose);
		if (diag.diagnoseCategoryId === '5829da250d9c95161474c162') {
			diag.isVaccination = true;
		}
		if (this.state.diagnoseImage && this.state.diagnoseImage.id !== this.state.diagnose.imageId) {
			// Only save new image if the image was actually changed
			await this.props.postImage(this.state.diagnoseImage);
			diag.imageId = deepCopy(this.state.diagnoseImage!.id);
		}
		await this.props.postDiagnose(diag);
		if (this.props.finishedSaving) {
			this.props.close();
		} else {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_DIAGNOSE_ANIMALKIND_STILL_IN_USE));
			this.setState({ showSpinnerAboveSaveButton: false });
		}
	};

	private validate(): boolean {
		if (!this.state.diagnose.name!.da || !this.state.diagnose.name!.en) {
			alert(localized('VALIDATION_ERROR_BothNamesRequired'));
			return false;
		}

		if (!this.state.diagnose.diagnoseCategoryId) {
			alert(localized(ExceptionMessage.VALIDATION_ERROR_CATEGORY_REQ));
			return false;
		}

		return true;
	}

	private async delete(id: string) {
		const res = window.confirm(localized('DeleteDiagnosePrompt'));
		if (res) {
			const diag = deepCopy(this.state.diagnose);
			diag.isDeleted = true;
			this.setState({ showSpinnerAboveDeleteButton: true });
			await this.props.postDiagnose(diag);
			this.setState({ showSpinnerAboveDeleteButton: false });
			if (this.props.finishedSaving) {
				this.props.close();
			} else {
				showAlert(localized(ExceptionMessage.VALIDATION_ERROR_NOT_ALLOWED_TO_DELETE_DIAGNOSE));
			}
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AddDiagnose);
