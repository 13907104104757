import { Dispatch } from 'redux';
import { EsfStatusAcknowledge, FeedingStatusUpdate } from 'shared/api/api';
import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import { StoreContainer } from 'shared/state/store-container';
import * as Action from './actions';

export function GetEsfFeedCurveSyncData() {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;
	const lastSyncDate = state.esfStatus.curveLastSyncDate;
	return AsyncOperationBuilder2(
		Action.getSyncEsfFeedCurveData,
		client => client.esfFeedCurve_Sync(siteId, lastSyncDate),
		{ siteId },
	);
}
export function GetEsfFeedingStatusSyncData() {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;
	const lastSyncDate = state.esfStatus.dataLastSyncDate;
	return AsyncOperationBuilder2(
		Action.getSyncEsfFeedingStatusData,
		client => client.esfFeedingStatus_Sync(siteId, lastSyncDate),
		siteId,
	);
}

export function SaveFeedingStatus(feedingStatusUpdate: FeedingStatusUpdate) {
	return AsyncOperationBuilder2(
		Action.saveEsfFeedingStatus,
		client => client.esfFeedingStatus_UpdateFeeding(feedingStatusUpdate),
		feedingStatusUpdate,
	);
}

export function AcknowledgeFeedingStatus(acknowledge: EsfStatusAcknowledge) {
	return AsyncOperationBuilder2(
		Action.acknowledgeFeedingStatus,
		client => client.esfFeedingStatus_AcknowledgeDate(acknowledge),
		acknowledge,
	);
}

export function SaveSyncData() {
	const state = StoreContainer.getState();
	const updates = state.esfStatus.updatesEsfFeedingStatus;
	let promises = new Array<Promise<void>>();
	return async (dispatch: Dispatch<any>) => {
		if (state.esfStatus.saveSyncInProgress) {
			return Promise.resolve();
		}
		updates.forEach(update => {
			promises.push(SaveFeedingStatus(new FeedingStatusUpdate(update))(dispatch));
		});

		return await Promise.all(promises);
	};
}

export function SaveAcknowledgeData() {
	const state = StoreContainer.getState();
	const updates = state.esfStatus.acknowledgeUpdates;
	let promises = new Array<Promise<void>>();
	return async (dispatch: Dispatch<any>) => {
		updates.forEach(update => {
			promises.push(AcknowledgeFeedingStatus(new EsfStatusAcknowledge(update))(dispatch));
		});

		return await Promise.all(promises);
	};
}

export function RemoveEsfStatus(id: string) {
	return (dispatch: Dispatch) => {
		dispatch(Action.removeEsfStatus.started(id));
	};
}
