import natsort from 'natsort';
import moment from 'moment';
export function NaturalSort(a: any, b: any) {
	let sorter = natsort();
	return sorter(a !== undefined ? a : -1, b !== undefined ? b : -1);
}

export function NaturalSortDates(date1: any, date2: any) {

	if (moment(date1, 'DD.MM.YY') > moment(date2, 'DD.MM.YY') || (date1 && !date2)) {
		return 1;
	} else {
		return -1;
	}
}

export function NaturalSortDatesOnly(date1: any, date2: any) {
	if (moment(date1.withoutTime(), 'DD.MM.YY') > moment(date2.withoutTime(), 'DD.MM.YY') || (date1 && !date2)) {
		return 1;
	} else {
		return -1;
	}
}

export function NaturalSortDatesUndefinedMax(date1: any, date2: any) {
	if (!date1) {
		return 1;
	}
	if (!date2) {
		return -1;
	}
	if (moment(date1, 'DD.MM.YY') > moment(date2, 'DD.MM.YY') || (date1 && !date2)) {
		return 1;
	} else {
		return -1;
	}
}

export const sortByOrderThenByProperty = (ob1: any, ob2: any, property: string, shouldReverse: boolean) => {
	if (shouldReverse) {
		let sortOrder = NaturalSort(ob1.order, ob2.order);
		if (sortOrder !== 0) {
			return sortOrder;
		}
	} else {
		let sortOrder = NaturalSort(ob2.order, ob1.order);
		if (sortOrder !== 0) {
			return sortOrder;
		}
	}

	// Else go to the 2nd item
	let sortProperty = NaturalSort(ob1[property], ob2[property]);
	if (sortProperty !== 0) {
		return sortProperty;
	}
	return 0;
};
