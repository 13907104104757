import { ESFReader, IProcessEquipmentData, IStation, IUnitToPen } from 'shared/api/api';
import { NaturalSort } from 'shared/helpers/natural-sort';
import { localized } from 'shared/state/i18n/i18n';

export const ESFReaderOptions = [
	{ value: ' ', label: ' ' },
	{ value: ESFReader.AWR250StickReader, label: localized(ESFReader.AWR250StickReader) },
	{ value: ESFReader.AWR300StickReader, label: localized(ESFReader.AWR300StickReader) }
];

export function getStationsInUse(
	unitToPens: IUnitToPen[],
	selectedSectionId: string | undefined,
	esfStations: IStation[],
    esfLocationsWithSettedPens: IProcessEquipmentData[],
    allowEmptySectionId: boolean = false
) {
	if (!selectedSectionId && !allowEmptySectionId) {
		return [];
	}
	let stationsInUse: string[] = [];
	const esfLocations = selectedSectionId
		? esfLocationsWithSettedPens
				.filter(esfLoc => esfLoc.sectionId === selectedSectionId && esfLoc.id !== undefined)
				.map(esfLocWithSettedPens => esfLocWithSettedPens.id)
		: esfLocationsWithSettedPens.map(esfLocWithSettedPens => esfLocWithSettedPens.id);
	let utps = unitToPens.filter(utp => esfLocations.includes(utp.processEquipmentDataId));

	for (let utpIndex = 0; utpIndex < utps.length; utpIndex++) {
		let station = esfStations.find(sta => sta.unitGroupId === utps[utpIndex].unitGroupId);
		if (station && station.code && station.code.length > 0) {
			let locArray = station.code.split('.');
			let stationNumber = locArray[locArray.length - 1];
			if (stationsInUse.includes(stationNumber) === false) {
				stationsInUse.push(stationNumber);
			}
		}
	}

	return formatStations(stationsInUse.sort((stationA, stationB) => NaturalSort(stationA, stationB)));
}

function formatStations(stationsInUse: string[]) {
	let formatedStations: string[] = [];
	for (let index = 0; index < stationsInUse.length; index++) {
		const station = stationsInUse[index];
		if (index + 1 === stationsInUse.length) {
			formatedStations.push(station);
		} else {
			formatedStations.push(station + ', ');
		}
	}
	return formatedStations;
}
