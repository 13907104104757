import React from 'react';
import {
	AnimalKind,
	FarrowingTaskTreatment,
	IFarrowingTaskTreatment,
	ITreatmentDefinition,
	TreatmentUnit
} from 'shared/api/api';
import { localized } from 'shared/state/i18n/i18n';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { SkioldCheckbox } from '../../skiold-components/skiold-checkbox/skiold-checkbox';
import { SkioldFormInput } from '../../skiold-components/skiold-input/skiold-form-input';
import TreatmentDefinitionPicker from '../../treatments/treatment-definition/treatment-definition-picker';
import './tasks-in-farrowing-stable-sub-tasks.scss';

export interface PropsFromParent {
	subTask: IFarrowingTaskTreatment;
	callback: (subTask: IFarrowingTaskTreatment, keySubTask: keyof FarrowingTaskTreatment, value: any) => void;
	treatmentDefinitionBlacklist: string[];
	treatmentDefinitions: ITreatmentDefinition[];
	style?: any;
}

// Component representing a Skiold Digital textfield throughout the app
export class TasksInFarrowingSubTask extends React.PureComponent<PropsFromParent> {
	public render() {
		const { subTask, treatmentDefinitions, treatmentDefinitionBlacklist } = this.props;
		const filtertedTreatmentDefs = treatmentDefinitions.filter(
			def =>
				def.subTreatments &&
				def.subTreatments.find(sub => sub.drugAmount && sub.drugAmount.unitType === TreatmentUnit.PerAnimal) &&
				(!treatmentDefinitionBlacklist.includes(def.id!) || def.id === subTask.treatmentDefinitionId)
		);

		return (
			<ViewWeb className="tasks-in-farrowing-stable-sub-tasks" style={this.props.style}>
				<ViewWeb className="align-picker">
					<TreatmentDefinitionPicker
						onValueChanged={this.callbackTreatmentPicker}
						treatmentDefinitionId={subTask.treatmentDefinitionId}
						pigType={AnimalKind.Piglet}
						allowVaccines={true}
						treatmentDefinitionsFromParent={filtertedTreatmentDefs}
					/>
				</ViewWeb>
				<ViewWeb className="border-line-container">
					<SkioldFormInput
						className="column-text-input"
						onChangeText={this.callbackColumnText}
						text={subTask.columnText!}
					/>
				</ViewWeb>

				<ViewWeb className="nursing-from-to-container">
					<ViewWeb className="task-checkbox-container">
						<SkioldCheckbox
							className="taks-checkbox-inner"
							onClick={this.callbackTreatAll}
							isChecked={subTask.treatAll}
							disabled={!subTask.treatmentDefinitionId}
						/>
						<TextWeb>{localized('allPigs')}</TextWeb>
					</ViewWeb>
					<ViewWeb className="task-checkbox-container">
						<SkioldCheckbox
							containerClassName=""
							className="taks-checkbox-inner"
							onClick={this.callbackBoar}
							isChecked={subTask.treatAll === false ? true : false}
							disabled={!subTask.treatmentDefinitionId}
						/>
						<TextWeb>{localized('boarsPigs')}</TextWeb>
					</ViewWeb>
				</ViewWeb>
			</ViewWeb>
		);
	}

	private callbackTreatAll = () => {
		this.props.callback(this.props.subTask, 'treatAll', true);
	};

	private callbackBoar = () => {
		this.props.callback(this.props.subTask, 'treatAll', false);
	};

	private callbackTreatmentPicker = (newTreatment: ITreatmentDefinition | undefined) => {
		this.props.callback(this.props.subTask, 'treatmentDefinitionId', newTreatment ? newTreatment.id : undefined);
	};

	private callbackColumnText = (newValue: string) => {
		this.props.callback(this.props.subTask, 'columnText', newValue);
	};

	private shouldShowChecked = (subTask: IFarrowingTaskTreatment, isReverse: boolean) => {
		if (!subTask.treatmentDefinitionId) {
			return false;
		}
		return isReverse ? !subTask.treatAll! : subTask.treatAll!;
	};
}
