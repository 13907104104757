export class DeadPigletsEventItem {
	public animalNumber: string | undefined;
	public stemAnimalId: string | undefined;
	public litterNumber: number | undefined;
	public date: Date | undefined;
	public reason: string | undefined;
	public deadPiglets: number | undefined;
	public initial: string | undefined;
	public youngAnimalIdNumber: string | undefined;
	public batch: number | undefined;
	public breedRace: string | undefined;
	public departureType: string| undefined;
	
}

export class DeadPigletsSummaryItem {
	public sowCount: number = 0;
	public deadPiglets: number = 0;
	public avgDeadPigletsPerSow?: string;
}
