import React from 'react';
import DeleteIcon from 'shared/assets/src-assets/png/delete_icon.png';
import PenIcon from 'shared/assets/src-assets/png/pen_icon.png';
import { getDateString } from 'shared/helpers/date-helpers';
import {
	exactNumberFilterMethodGrid,
	numberWithCommas,
	rangeFilterMethodGrid,
	setOneDecimalsAsNumber,
} from 'shared/helpers/general-helpers';
import {
	getPenClassName,
	GrowthPigDepartureEventListRow,
	GrowthPigDepartureEventListTablePropsFromParent,
} from 'shared/helpers/growth-pigs-helper/growth-pig-event-list-helper';
import { localized } from 'shared/state/i18n/i18n';
import SkioldTableGrid from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { SkioldTouchableOpacity } from 'web/view/components/skiold-components/skiold-touchable-opacity';
import { SkioldImage } from 'web/view/components/utils/svg/skiold-image';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { SowListConstants } from '../../animal-lists/table-constants';
import { NaturalSortDates } from 'shared/helpers/natural-sort';
import { DepartureTypes } from 'shared/state/models/departure-types';

export class GrowthPigDepartureEventTable extends React.PureComponent<GrowthPigDepartureEventListTablePropsFromParent> {
	public render() {
		return (
			<SkioldTableGrid
				tableKey={'GrowthPigDepartureEventTableNew'}
				ignoreSetCount={true}
				ref={this.props.setRef}
				ColumnExtensions={this.generateColumnExtes}
				onFiltersChanged={this.props.onFilterChanged}
				columns={this.generateColumns()}
				data={this.props.bundleData}
			/>
		);
	}
	private getFromBuildingNameCell = (item: GrowthPigDepartureEventListRow) => item.fromBuildingName;
	private getFromSectionNameCell = (item: GrowthPigDepartureEventListRow) => item.fromSectionName;
	private getFromPenNameCell = (item: GrowthPigDepartureEventListRow) => item.fromPenName;
	private getAmountCell = (item: GrowthPigDepartureEventListRow) => item.pcs;
	private getDepartureCell = (item: GrowthPigDepartureEventListRow) =>
		item.departuredType && localized(item.departuredType);
	private getTotalWeightCell = (item: GrowthPigDepartureEventListRow) =>
		item.departuredType === DepartureTypes.departureTypeKilled && !this.props.usePrivateSlaughterhouse
			? ''
			: numberWithCommas(setOneDecimalsAsNumber(item.departedWeight));
	private getAvgWeightCell = (item: GrowthPigDepartureEventListRow) =>
		item.departuredType === DepartureTypes.departureTypeKilled && !this.props.usePrivateSlaughterhouse
			? ''
			: setOneDecimalsAsNumber(item.avgWeight);
	private getDateCell = (item: GrowthPigDepartureEventListRow) => getDateString(item.date);
	private getReasonCell = (item: GrowthPigDepartureEventListRow) => item.reason;
	private getAnimalIdNumberCell = (item: GrowthPigDepartureEventListRow) => item.animalIdNumber;
	private getEditCell = (event: GrowthPigDepartureEventListRow) => {
		return (
			<SkioldTouchableOpacity onPress={this.props.openEditModal} itemFromParent={event}>
				<SkioldImage
					width={SowListConstants.iconSVGWidth}
					height={SowListConstants.iconSVGWidth}
					imageData={PenIcon}
				/>
			</SkioldTouchableOpacity>
		);
	};

	private getRemoveCell = (event: GrowthPigDepartureEventListRow) => {
		return (
			<SkioldTouchableOpacity onPress={this.props.removeItem} itemFromParent={event}>
				<SkioldImage
					width={SowListConstants.iconSVGWidth}
					height={SowListConstants.iconSVGWidth}
					imageData={DeleteIcon}
				/>
			</SkioldTouchableOpacity>
		);
	};

	private generateColumns = () => [
		{
			name: 'edit',
			title: ' ',
			sortable: false,
			filterable: false,
			isFixedLeft: true,
			getCellValue: this.getEditCell,
		},
		{
			name: 'fromBuilding',
			title: <ViewWeb className="column-word-wrap">{localized('building')}</ViewWeb>,
			getCellValue: this.getFromBuildingNameCell,
		},
		{
			name: 'fromSection',
			className: getPenClassName,
			title: <ViewWeb className="column-word-wrap">{localized('section')}</ViewWeb>,
			getCellValue: this.getFromSectionNameCell,
			filterFunction: exactNumberFilterMethodGrid,
		},
		{
			name: 'fromPen',
			className: getPenClassName,
			title: <ViewWeb className="column-word-wrap">{localized('Pen')}</ViewWeb>,
			getCellValue: this.getFromPenNameCell,
			filterFunction: rangeFilterMethodGrid,
		},
		{
			name: 'date',
			title: localized('Date'),
			getCellValue: this.getDateCell,
			sortFunction: NaturalSortDates,
		},
		{
			filterFunction: rangeFilterMethodGrid,
			name: 'pcs',
			title: localized('pcs'),
			getCellValue: this.getAmountCell,
		},
		{
			filterFunction: rangeFilterMethodGrid,
			name: 'weight',
			title: localized('Weight'),
			getCellValue: this.getTotalWeightCell,
		},
		{
			name: 'avgWeight',
			filterFunction: rangeFilterMethodGrid,
			title: <ViewWeb className="column-word-wrap">{localized('avgWeight')}</ViewWeb>,
			getCellValue: this.getAvgWeightCell,
		},
		{ name: 'departure', title: localized('departure'), getCellValue: this.getDepartureCell },
		{ name: 'animalIdNumber', title: localized('idNumber'), getCellValue: this.getAnimalIdNumberCell },
		{ name: 'departureReason', title: localized('reason'), getCellValue: this.getReasonCell },
		{
			name: 'remove',
			title: ' ',
			isFixedLeft: true,
			getCellValue: this.getRemoveCell,
		},
	];

	private generateColumnExtes = [
		{
			columnName: 'edit',
			width: SowListConstants.iconWidth,
			filteringEnabled: false,
			sortingEnabled: false,
			resizingEnabled: false,
		},
		{
			columnName: 'fromBuilding',
			width: SowListConstants.buildingWidth,
		},
		{
			columnName: 'fromSection',
			width: SowListConstants.buildingWidth,
		},
		{
			columnName: 'fromPen',
			width: SowListConstants.penWidth,
		},
		{
			columnName: 'date',
			width: SowListConstants.dateWidth,
		},
		{
			columnName: 'pcs',
			width: SowListConstants.countWidth,
		},
		{
			columnName: 'weight',
			width: SowListConstants.weightWidth,
		},
		{
			columnName: 'avgWeight',
			width: SowListConstants.avgWeightWidth,
		},
		{
			columnName: 'departure',
			width: SowListConstants.avgWeightWidth,
		},
		{
			columnName: 'animalIdNumber',
			width: SowListConstants.avgWeightWidth,
		},
		{
			columnName: 'departureReason',
			width: SowListConstants.locationWidth,
		},
		{
			columnName: 'remove',
			width: SowListConstants.iconWidth,
			filteringEnabled: false,
			sortingEnabled: false,
			resizingEnabled: false,
		},
	];
	public innerRef = React.createRef();
}
