import ObjectID from 'bson-objectid';
import { Option } from 'react-dropdown';
import {
	IEsfFeedingStatus,
	IMatingBatch,
	IMoveEvent,
	IMoveToFarrowingLocation,
	IMoveToMatingLocation,
	IMoveToPregnancyLocation,
	IPen,
	IPregnancyEvent,
	IStemAnimal,
	IUnitToPen,
	IValidationSetup,
	IWorkListSetting,
	LocationType,
	MoveFromPregToPregSetting,
	MoveToLocationTypes,
	MoveToPregnancyLocation,
	PregnancyState,
	PregnancyValidationType,
	Scanned,
	ScanResult,
	WorkListType,
} from 'shared/api/api';
import { DefaultValidationValues } from 'shared/helpers/default-validation-values';
import { copyFlatObjectArray } from 'shared/helpers/general-helpers';
import {
	CheckIfSectionUsesPens,
	FindHiddenPenOnSection,
	getLocationStringByPenId,
	getStemAnimalPenId,
	ILocationModel,
} from 'shared/helpers/location-helper';
import { sortPregnanciesByDate } from 'shared/helpers/pregnancy-helper/sort-pregnancies';
import { ExtractLocationCode } from 'shared/helpers/process-equipment-helper/process-equipment-helper';
import { findRetentionTime } from 'shared/helpers/treatment-helper/treatment-helper';
import { localized } from 'shared/state/i18n/i18n';
import { StoreContainer } from 'shared/state/store-container';
import { getMoveFromPregToPregItemByAnimal } from './move-from-preg-to-preg-list-helper';
import { getMoveMatingToMatingItemByAnimal } from './move-mating-to-mating-list-helper';
import { MovingListTable } from './move-to-data-model';
import { getMoveToFarrowingItemByAnimal } from './move-to-farrowing-list-helper';
import { getMoveToPregItemByAnimal } from './move-to-pregnant-list-helper';
import { calculateAnimalKind } from 'shared/helpers/stemanimal-helper/stemanimal-helper';
import { getCycleDays1 } from 'shared/helpers/pregnancy-helper/generel-pregnancy-helpers';

export const pickerStyle = {
	app: { paddingTop: 0, paddingBottom: 0, height: 24 },
	web: { width: '100%', maxWidth: 200 },
};

export function GetMoveToOptions() {
	return [
		{ label: localized(MoveToLocationTypes.Location), value: MoveToLocationTypes.Location },
		{
			label: localized(MoveToLocationTypes.MatingBatch),
			value: MoveToLocationTypes.MatingBatch,
		},
		{
			label: localized(MoveToLocationTypes.PregnacyDays),
			value: MoveToLocationTypes.PregnacyDays,
		},
		{
			label: localized(MoveToLocationTypes.Blank),
			value: MoveToLocationTypes.Blank,
		},
	];
}

export function GetMoveFromPregToPregOption() {
	return [
		{ label: localized(MoveToLocationTypes.Location), value: MoveToLocationTypes.Location },
		{
			label: localized(MoveToLocationTypes.MatingBatch),
			value: MoveToLocationTypes.MatingBatch,
		},
		{
			label: localized(MoveToLocationTypes.Blank),
			value: MoveToLocationTypes.Blank,
		},
	];
}

export function GetMoveMatingToMatingOption() {
	return [
		{ label: localized(MoveToLocationTypes.Location), value: MoveToLocationTypes.Location },
		{
			label: localized(MoveToLocationTypes.Blank),
			value: MoveToLocationTypes.Blank,
		},
	];
}

export function getCycleDaysByPregnancyEvents(filteredPregnancyEvents: IPregnancyEvent[]) {
	if (
		filteredPregnancyEvents !== undefined &&
		filteredPregnancyEvents.length > 0 &&
		filteredPregnancyEvents[0].stemAnimalId
	) {
		let cycleDays = getCycleDays1(filteredPregnancyEvents[0].stemAnimalId, filteredPregnancyEvents);
		if (cycleDays) {
			return cycleDays;
		}
	}
	return undefined;
}

export function movingListsCheckCycleDaysPregnancyDaysByPregnancyEvents(
	moveToSetup: IMoveToFarrowingLocation | IMoveToMatingLocation | IMoveToPregnancyLocation,
	ilteredPregnancyEvents: IPregnancyEvent[],
) {
	let cycleDays = getCycleDaysByPregnancyEvents(ilteredPregnancyEvents);
	if (
		cycleDays !== undefined &&
		moveToSetup.cycleDaysFrom !== undefined &&
		moveToSetup.cycleDaysTo !== undefined &&
		cycleDays <= moveToSetup.cycleDaysTo &&
		cycleDays >= moveToSetup.cycleDaysFrom
	) {
		return cycleDays;
	}
	return undefined;
}

export function checkLocation(
	locationOfSow: IPen | undefined,
	locations: ILocationModel,
	locationType: LocationType | LocationType[],
) {
	if (locationOfSow !== undefined) {
		if (
			locationType instanceof Array &&
			locations.sections.find(
				section =>
					section.id === locationOfSow!.sectionId && section.type && locationType.includes(section.type),
			) !== undefined
		) {
			return true;
		} else if (
			locations.sections.find(
				section => section.id === locationOfSow!.sectionId && section.type === locationType,
			) !== undefined
		) {
			return true;
		}
	}
	return false;
}

export function checkMatingBatch(pregnancyEvents: IPregnancyEvent[], matingBatch: IMatingBatch) {
	let filteredPregnancyEvents = sortPregnanciesByDate(pregnancyEvents);
	const eventDate = getEventDate(filteredPregnancyEvents);
	let lastestPregEvent = filteredPregnancyEvents.find(preg => preg.date!.toString() === eventDate.toString());
	if (
		lastestPregEvent &&
		lastestPregEvent.state === PregnancyState.Scanned &&
		(lastestPregEvent as Scanned).result === ScanResult.True
	) {
		lastestPregEvent = filteredPregnancyEvents.find(preg => preg.state === PregnancyState.Mated);
	}
	if (
		!lastestPregEvent ||
		lastestPregEvent.date! < matingBatch.matingPeriodStart! ||
		lastestPregEvent.date! > matingBatch.matingPeriodEnd!
	) {
		return undefined;
	}
	return { filteredPregnancyEvents, lastestPregEvent };
}

export function setMovelistTableItemData(
	sow: IStemAnimal,
	moveToWorkListItem: MovingListTable,
	cycleDays: number | undefined,
	negativeCycleDays: number | undefined,
) {
	moveToWorkListItem.stemAnimalId = sow.id;
	moveToWorkListItem.animalNumber = sow.animalNumber;
	moveToWorkListItem.animalKind = calculateAnimalKind(sow);
	moveToWorkListItem.cycleDays = cycleDays;
	moveToWorkListItem.negativeCycleDays = negativeCycleDays;
	moveToWorkListItem.hasRetentionTime = !!findRetentionTime(sow.id!);
	moveToWorkListItem.sectionId = undefined;
	moveToWorkListItem.penId = undefined;
	moveToWorkListItem.transponder = sow.transponder;
}

export function getEventDate(filteredPregnancyEvents: IPregnancyEvent[]) {
	return new Date(
		Math.max.apply(
			null,
			filteredPregnancyEvents.map(e => {
				return new Date(e.date!).getTime();
			}),
		),
	);
}

export function InsertOldDataSelectedSettings(data: MovingListTable[], moveListData: MovingListTable[]) {
	if (moveListData.length > 0) {
		moveListData.forEach(moveListTableItem => {
			let dataIndex = data.findIndex(
				moveListTable => moveListTable.stemAnimalId === moveListTableItem.stemAnimalId,
			);
			if (dataIndex !== -1) {
				data[dataIndex] = {
					...data[dataIndex],
					isChecked: moveListTableItem.isChecked,
					usesPens: moveListTableItem.usesPens,
					sectionId: moveListTableItem.sectionId,
					penId: moveListTableItem.penId,
					buildingId: moveListTableItem.buildingId,
					selectedCurveNumber: moveListTableItem.selectedCurveNumber,
					savedFromScanner: moveListTableItem.savedFromScanner,
				};
			}
		});
	}
}

export function setMovingListSectionInStore(
	section: string | undefined,
	moveToSetup: IMoveToMatingLocation | IMoveToFarrowingLocation | IMoveToPregnancyLocation,
	moveListData: MovingListTable[],
	penId: string | undefined,
	usesPens: boolean | undefined,
	saveWorkListDataTemporary: (movingListData: MovingListTable[]) => void,
) {
	if (moveToSetup) {
		let moveListCopy = copyFlatObjectArray(moveListData);
		let unitToPens = StoreContainer.getState().unitToPenData.data;
		let isMoveLocationEsf = !!unitToPens.find(utp => utp.penId === penId);
		let newPenId = usesPens ? undefined : penId;
		let defaultFeedCurves = (moveToSetup as IMoveToPregnancyLocation).defaultFeedCurves;
		let processEquipments = StoreContainer.getState().processEquipmentData.entities;
		const utp = unitToPens.find(p => p.penId === newPenId);
		const processEquipmentData =
			processEquipments && processEquipments.find(peq => utp && peq.id === utp.processEquipmentDataId);

		moveListCopy.forEach(moveListItem => {
			moveListItem.sectionId = section;
			if (!usesPens) {
				moveListItem.penId = newPenId;
				moveListItem.usesPens = false;
			} else if (usesPens) {
				moveListItem.penId = undefined;
				moveListItem.usesPens = true;
			} else if (!section) {
				moveListItem.penId = newPenId;
				moveListItem.usesPens = false;
			}
			if (workListUseFeedCurve(moveToSetup)) {
				// Handle moves between 2 ESF controllers
				const state = StoreContainer.getState();
				const currentUtp =
					moveListItem.currentPenId &&
					state.unitToPenData.data &&
					state.unitToPenData.data.find(
						utp => moveListItem.currentPenId && utp.penId === moveListItem.currentPenId,
					);

				const currentStation = state.station.data.find(
					s => currentUtp && s.unitGroupId === currentUtp.unitGroupId,
				);
				const newStation = state.station.data.find(s => utp && s.unitGroupId === utp.unitGroupId);
				moveListItem.fromPenIsEsfLocation =
					currentStation &&
					newStation &&
					ExtractLocationCode(currentStation.code) === ExtractLocationCode(newStation.code);

				let curve =
					defaultFeedCurves &&
					defaultFeedCurves.find(
						d =>
							processEquipmentData &&
							d.animalKind === moveListItem.animalKind &&
							d.locationCode === ExtractLocationCode(processEquipmentData.code),
					);
				moveListItem.selectedCurveNumber = moveListItem.fromPenIsEsfLocation
					? moveListItem.currentSelectedCurveNumber
					: curve
					? curve.feedCurveNumber
					: 1;
				moveListItem.fixedDeviation = moveListItem.fromPenIsEsfLocation ? moveListItem.currentFixed : 100;
				moveListItem.isMoveLocationEsf = isMoveLocationEsf;
			}
		});
		saveWorkListDataTemporary(moveListCopy);
	}
}

export function setMovingListPenInStore(
	pen: string | undefined,
	moveToSetup: IMoveToMatingLocation | IMoveToFarrowingLocation | IMoveToPregnancyLocation,
	moveListData: MovingListTable[],
	moveListSelectedPen: string | undefined,
	saveWorkListDataTemporary: (movingListData: MovingListTable[]) => void,
) {
	if (moveToSetup && pen !== undefined) {
		let moveListDataCopy = copyFlatObjectArray(moveListData);
		let unitToPens = StoreContainer.getState().unitToPenData.data;
		let defaultFeedCurves = (moveToSetup as IMoveToPregnancyLocation).defaultFeedCurves;
		let processEquipments = StoreContainer.getState().processEquipmentData.entities;
		let isMoveLocationEsf = !!unitToPens.find(utp => utp.penId === pen);

		const utp = unitToPens.find(p => p.penId === pen);
		const processEquipmentData =
			processEquipments && processEquipments.find(peq => utp && peq.id === utp.processEquipmentDataId);

		for (let index = 0; index < moveListDataCopy.length; index++) {
			let moveListTableItem = moveListDataCopy[index] as MovingListTable;
			moveListTableItem.penId = pen;

			if (workListUseFeedCurve(moveToSetup)) {
				// Handle moves between 2 ESF controllers
				const state = StoreContainer.getState();
				const currentUtp =
					moveListTableItem.currentPenId &&
					state.unitToPenData.data &&
					state.unitToPenData.data.find(
						utp => moveListTableItem.currentPenId && utp.penId === moveListTableItem.currentPenId,
					);

				const currentStation = state.station.data.find(
					s => currentUtp && s.unitGroupId === currentUtp.unitGroupId,
				);
				const newStation = state.station.data.find(s => utp && s.unitGroupId === utp.unitGroupId);
				moveListTableItem.fromPenIsEsfLocation =
					currentStation &&
					newStation &&
					ExtractLocationCode(currentStation.code) === ExtractLocationCode(newStation.code)
						? true
						: false;

				let curve =
					defaultFeedCurves &&
					defaultFeedCurves.find(
						d =>
							processEquipmentData &&
							d.animalKind === moveListTableItem.animalKind &&
							d.locationCode === ExtractLocationCode(processEquipmentData.code),
					);
				moveListTableItem.selectedCurveNumber = moveListTableItem.fromPenIsEsfLocation
					? moveListTableItem.currentSelectedCurveNumber
					: curve
					? curve.feedCurveNumber
					: 1;
				moveListTableItem.fixedDeviation = moveListTableItem.fromPenIsEsfLocation
					? moveListTableItem.currentFixed
					: 100;
				moveListTableItem.isMoveLocationEsf = isMoveLocationEsf;
			}

			moveListDataCopy[index] = { ...moveListTableItem };
		}

		if (moveListSelectedPen !== pen) {
			saveWorkListDataTemporary(moveListDataCopy);
		}
	}
}

export function GenerateInitialData(
	moveToSetup: IMoveToPregnancyLocation | IMoveToFarrowingLocation,
	locations: ILocationModel,
	locationType: LocationType | LocationType[],
	penId: string | undefined,
	selectedBatch: Option,
	fromPenId: string | undefined,
	generateData: (
		moveToSetup: IMoveToPregnancyLocation | IMoveToFarrowingLocation,
		batchId: string,
		penId: string | undefined,
	) => MovingListTable[],
) {
	let filteredLocations = { ...locations };
	let locationString: string | undefined;
	let tableData: MovingListTable[] = [];
	if (moveToSetup) {
		tableData = generateData(moveToSetup, selectedBatch.value, fromPenId);
		if (locationType instanceof Array) {
			filteredLocations.sections = [
				...filteredLocations.sections.filter(section => section.type && locationType.includes(section.type)),
			];
		} else {
			filteredLocations.sections = [
				...filteredLocations.sections.filter(section => section.type === locationType),
			];
		}

		if (moveToSetup.selectedType === MoveToLocationTypes.Location) {
			locationString = getLocationStringByPenId(moveToSetup.penId);
		}
	}
	return { filteredLocations, locationString, tableData };
}

export function ResetMoveWorkListDataInStore(allData: MovingListTable[]) {
	return allData.map(movingListTableItem => {
		let moveListTable = { ...movingListTableItem } as MovingListTable;
		moveListTable.buildingId = undefined;
		moveListTable.sectionId = undefined;
		moveListTable.penId = undefined;
		moveListTable.isChecked = false;
		moveListTable.usesPens = false;
		moveListTable.isMoveLocationEsf = false;
		return moveListTable;
	});
}

export function getLocationsForMoveToList(locations: ILocationModel, locationType: LocationType) {
	let filteredLocations = locations;
	filteredLocations.sections = [...filteredLocations.sections.filter(section => section.type === locationType)];
	return filteredLocations;
}

export function getLocationsForMoveToSetup(locations: ILocationModel, locationType: LocationType) {
	let filteredLocations = locations;
	filteredLocations.sections = [...filteredLocations.sections.filter(section => section.type === locationType)];
	return filteredLocations;
}

export function getLocationsForMultipleLocationTypes(locations: ILocationModel, locationTypes: LocationType[]) {
	let filteredLocations = locations;
	filteredLocations.sections = [
		...filteredLocations.sections.filter(section => section.type && locationTypes.includes(section.type)),
	];
	return filteredLocations;
}

export function generateMatingBatchSetup(
	workListSettings: IWorkListSetting[],
	matingBatches: IMatingBatch[],
	workListType: WorkListType,
) {
	let moveToSetup = workListSettings.find(setup => setup.type === workListType)! as
		| IMoveToPregnancyLocation
		| IMoveToFarrowingLocation;
	let selectedOption: Option = { label: ' ', value: ' ' };
	let matingBatchesOptions: Option[] = [];
	if (moveToSetup && moveToSetup.selectedType === MoveToLocationTypes.MatingBatch) {
		const sortedBatches = matingBatches.sort((a, b) => {
			return b.matingPeriodStart!.getTime() - a.matingPeriodStart!.getTime();
		});

		let dateToCalculateWith = new Date();
		dateToCalculateWith.setDate(dateToCalculateWith.getDate() - moveToSetup.batchCycleDays!);
		let batchIndex = sortedBatches.findIndex(batch => batch.matingPeriodStart! < dateToCalculateWith);
		if (batchIndex !== -1) {
			if (batchIndex !== 0) {
				matingBatchesOptions.push({
					label: sortedBatches[batchIndex - 1].batchNumber!.toString(),
					value: sortedBatches[batchIndex - 1].id!,
				});
			}
			selectedOption = {
				label: sortedBatches[batchIndex].batchNumber!.toString(),
				value: sortedBatches[batchIndex].id!,
			};
			matingBatchesOptions.push(selectedOption);
			if (batchIndex + 1 !== sortedBatches.length) {
				matingBatchesOptions.push({
					label: sortedBatches[batchIndex + 1].batchNumber!.toString(),
					value: sortedBatches[batchIndex + 1].id!,
				});
			}
			return { selectedOption, matingBatchesOptions, moveToSetup };
		}
	}
	return { selectedOption, matingBatchesOptions, moveToSetup };
}

export function ResetDataOnMoveListSetups(movetoPregSetup: IMoveToFarrowingLocation | IMoveToPregnancyLocation) {
	// tslint:disable-next-line: switch-default
	switch (movetoPregSetup.selectedType) {
		case MoveToLocationTypes.Location:
			movetoPregSetup.batchCycleDays = undefined;
			movetoPregSetup.cycleDaysFrom = undefined;
			movetoPregSetup.cycleDaysTo = undefined;
			break;
		case MoveToLocationTypes.MatingBatch:
			movetoPregSetup.cycleDaysFrom = undefined;
			movetoPregSetup.cycleDaysTo = undefined;
			movetoPregSetup.buildingId = undefined;
			movetoPregSetup.sectionId = undefined;
			movetoPregSetup.penId = undefined;
			break;
		case MoveToLocationTypes.PregnacyDays:
			movetoPregSetup.batchCycleDays = undefined;
			movetoPregSetup.buildingId = undefined;
			movetoPregSetup.sectionId = undefined;
			movetoPregSetup.penId = undefined;
			break;
	}
}

export function shouldShowPen(location?: ILocationModel) {
	return !!(location && location.sections.find(section => section.usePens) !== undefined);
}

export function checkSectionWorkLists(section: string | undefined, pen: string | undefined) {
	let penId = section ? pen : undefined;
	let usesPens = !!section;
	if (section && section.length > 1 && !CheckIfSectionUsesPens(section)) {
		penId = FindHiddenPenOnSection(section!, '');
		usesPens = false;
	}
	return { penId, usesPens };
}

export function checkSectionWorkListsCorrectly(section: string | undefined, pen: string | undefined) {
	let penId;
	let usesPens = !!section;
	if (section && section.length > 1 && !CheckIfSectionUsesPens(section)) {
		penId = FindHiddenPenOnSection(section!, '');
		usesPens = false;
	}
	return { penId, usesPens };
}

export function getValidationSettingPlan(validationSetup: IValidationSetup, validationType: PregnancyValidationType) {
	if (validationSetup && validationSetup.validationIntervals) {
		const interval = validationSetup.validationIntervals.find(val => val.type === validationType);
		if (interval && interval.plan) {
			return interval.plan;
		}
	}
	return validationType === PregnancyValidationType.MatingToFarrowing
		? DefaultValidationValues.DEFAULT_PREGNANT_PERIOD
		: PregnancyValidationType.FarrowingToAverted
		? DefaultValidationValues.DEFAULT_NURSING_PERIOD
		: 0;
}

export function getMoveListItemByAnimal(
	sow: IStemAnimal,
	pregEvents: IPregnancyEvent[],
	moveEvents: IMoveEvent[],
	locations: ILocationModel,
	moveListSettingType: WorkListType | undefined,
	unitToPens?: IUnitToPen[] | undefined,
	esfFeedingStatus?: IEsfFeedingStatus[] | undefined,
	toPenId?: string | undefined,
	validateSettingToSubtract?: number | undefined,
) {
	if (moveListSettingType) {
		if (moveListSettingType === WorkListType.MoveToPregnancyLocation) {
			return getMoveToPregItemByAnimal(
				sow,
				pregEvents,
				moveEvents,
				locations,
				toPenId,
				validateSettingToSubtract,
			);
		}
		if (moveListSettingType === WorkListType.MoveToFarrowingLocation) {
			return getMoveToFarrowingItemByAnimal(sow, pregEvents, moveEvents, locations, validateSettingToSubtract);
		}
		if (moveListSettingType === WorkListType.MoveFromPregToPregSetting) {
			return getMoveFromPregToPregItemByAnimal(
				sow,
				pregEvents,
				moveEvents,
				locations,
				unitToPens,
				esfFeedingStatus,
				toPenId,
				validateSettingToSubtract,
			);
		}
		if (moveListSettingType === WorkListType.MoveMatingToMatingSetting) {
			return getMoveMatingToMatingItemByAnimal(sow, pregEvents, moveEvents, locations, validateSettingToSubtract);
		}
	}

	return undefined;
}

export function getDataByBlankList(moveListData: MovingListTable[]) {
	if (!moveListData || moveListData.length < 1) {
		return [new MovingListTable({ identifier: new ObjectID().toHexString() } as any)];
	}
	return moveListData;
}

export function hasDefaultFeedCurves(worklist: MoveFromPregToPregSetting | MoveToPregnancyLocation) {
	if (worklist.defaultFeedCurves) {
		return worklist.defaultFeedCurves.some(
			defaultCurve => defaultCurve.feedCurveNumber !== null && defaultCurve.feedCurveNumber !== undefined,
		);
	}
	return false;
}

export function workListUseFeedCurve(workList: IWorkListSetting) {
	if (
		workList.type === WorkListType.MoveToPregnancyLocation ||
		(workList.type === WorkListType.MoveFromPregToPregSetting &&
			hasDefaultFeedCurves(workList as MoveFromPregToPregSetting))
	) {
		return true;
	}

	return false;
}
