
import actionCreatorFactory from 'typescript-fsa';
import * as types from './types';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import { IMatingBatch, SyncDataMatingBatch } from 'shared/api/api';

const actionCreator = actionCreatorFactory(GenActionFactoryName('matingBatches'));

export const saveMatingBatches = actionCreator.async<{data:IMatingBatch[]; activeSiteId?: string}, void>(types.SAVE_MATING_BATCHES);
export const getSyncData = actionCreator.async<{ siteId: string; lastSyncDate: Date; activeSiteId?: string  }, SyncDataMatingBatch>(
    types.SYNC_DATA_MATINGBATCH
);
export const deleteMatingBatchesByDate = actionCreator.async<{siteId: string, deletionDate: Date}, void>(types.DELETE_MATING_BATCHES_BY_DATE);
