import { default as ObjectID } from 'bson-objectid';
import { Dispatch } from 'redux';
import { IReasonDto, Reason, ReasonDto, ReasonSettings } from 'shared/api/api';
import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import { StoreContainer } from 'shared/state/store-container';
import * as Action from './actions';

export function SaveReason(reasonDto: IReasonDto) {
	const state = StoreContainer.getState();
	const prevEntity = state.reasons.entities.find(dt => dt.reason!.id === reasonDto.reason!.id);
	if (!reasonDto.reason!.id) {
		reasonDto.reason!.id = new ObjectID().toHexString();
	}

	return AsyncOperationBuilder2(
		Action.saveReason,
		client => client.reason_CreateReason(Reason.fromJS(reasonDto.reason)),
		new ReasonDto(reasonDto),
		prevEntity,
	);
}

export function SaveReasonSettings(reason: IReasonDto) {
	const state = StoreContainer.getState();
	const prevEntity = state.reasons.entities.find(dt => dt.reason!.id === reason.reason!.id);
	if (!reason.reasonSettings!.id) {
		reason.reasonSettings!.id = new ObjectID().toHexString();
		reason.reasonSettings!.reasonId = reason.reason!.id;
	}

	return AsyncOperationBuilder2(
		Action.saveReasonSettings,
		client => client.reason_CreateReasonSettings(ReasonSettings.fromJS(reason.reasonSettings)),
		new ReasonDto(reason),
		prevEntity,
	);
}

export function Sync(reasonDtos: IReasonDto[]) {
	return (dispatch: Dispatch<any>) => {
		reasonDtos.forEach(rdto => {
			if (rdto.reason) {
				SaveReason(rdto)(dispatch);
			}

			if (rdto.reasonSettings) {
				SaveReasonSettings(rdto)(dispatch);
			}
		});
	};
}

export function GetSyncData() {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;
	const lastSyncDate = state.reasons.lastSyncDate;
	if (state.reasons.syncInProgress) {
		return (dispatch: Dispatch<any>) => {
			return Promise.resolve();
		};
	}
	return AsyncOperationBuilder2(Action.getSyncData, client => client.reason_SyncV2(siteId, lastSyncDate), {
		siteId,
		lastSyncDate,
	});
}
