import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
  IWeaningListSetting,
  MoveToLocationTypes,
  WeaningListSetting,
  WeaningListTypes,
  WorkListType
} from 'shared/api/api';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { deepCopy } from 'shared/helpers/general-helpers';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { SaveWorkListSetting } from 'shared/state/ducks/work-list-settings/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from "web/state/store.web";
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { SkioldButton } from '../../skiold-components/skiold-button/skiold-button';
import { SkioldFormsWrapper } from '../../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from '../../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { SkioldFormIntegerInput } from '../../skiold-components/skiold-integer-input/skiold-form-integer-input';
import { getAvailablityRow, getSkioldInputRow } from '../work-list-helper';
import './weaning-list-setup.scss';
import { SkioldFormDropdown } from '../../skiold-components/skiold-dropdown/skiold-form-dropdown';
import { Option } from 'react-dropdown';

interface PropsFromParent {
    workListType: WorkListType;
}

const mapStateToProps = (state: WebAppState) => {
    return {
        WeaningListSetting: state.workListSettings.entities.find(wl => wl.type === WorkListType.WeaningListSetting),
        siteId: state.profile.active!.siteId
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
    saveWorkListSetting: (weaningListSetting: IWeaningListSetting) => SaveWorkListSetting(weaningListSetting)(dispatch)
});

export interface State {
    WeaningListSetting: IWeaningListSetting;
    options: Option[];
    selectedOption: Option;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

class WeaningListSetup extends React.PureComponent<Props, State> {
	private animalToListChanged = (option: Option) => {
		this.setState(prevState => ({
			WeaningListSetting: {
				...prevState.WeaningListSetting,
				selectedType: option.value as WeaningListTypes
			},
			selectedOption: option
		}));
	};

    public static getDerivedStateFromProps(nextProps: Props, prevState: State): Partial<State> {
		if (!prevState.WeaningListSetting.id && nextProps.WeaningListSetting) {
			return {
				WeaningListSetting: { ...prevState.WeaningListSetting, ...nextProps.WeaningListSetting }
			};
		}
		return {};
	}


    public componentDidMount() {
		let initSetting = {};
		if (this.state.WeaningListSetting.selectedType !== undefined) {
			initSetting['selectedOption'] = {
				label: localized(this.state.WeaningListSetting.selectedType),
				value: this.state.WeaningListSetting.selectedType
			};
		}
		this.setState(initSetting);
	}
    constructor(props: Props) {
        super(props);
        this.state = {
            options: [		{ label: localized(WeaningListTypes.Location), value: MoveToLocationTypes.Location },

                {
                    label: localized(WeaningListTypes.PregnancyDays),
                    value:WeaningListTypes.PregnancyDays
                },],
            selectedOption: { label: '', value: '' },
            WeaningListSetting: WeaningListSetting.fromJS({ type: WorkListType.WeaningListSetting })
        };
    }

    public toggleShowOnWeb() {
        this.setState(prevState => ({
            WeaningListSetting: {
                ...prevState.WeaningListSetting,
                showOnWeb: !this.state.WeaningListSetting.showOnWeb
            }
        }));
    }

    public toggleShowOnApp() {
        this.setState(prevState => ({
            WeaningListSetting: {
                ...prevState.WeaningListSetting,
                showOnApp: !this.state.WeaningListSetting.showOnApp
            }
        }));
    }

    public weaningListSetupChanged(setup: IWeaningListSetting) {
        this.setState({
            WeaningListSetting: setup
        });
    }

    public cycleDaysChanged(cycleDays: number | undefined) {
        this.setState(prevState => ({
            WeaningListSetting: { ...prevState.WeaningListSetting, cycleDays: cycleDays! }
        }));
    }

    public render() {
        return (
            <ViewWeb className='weaning-list-setup'>
                <ViewWeb className='view-container'>
                    <TextWeb className='text-style'>{localized('Setup')}</TextWeb>
                    <SkioldFormsWrapper
                        formRows={this.getFormRows()}
                        containerClassName='wrapper-container'
                    />
                    {this.renderButtons()}
                </ViewWeb>
            </ViewWeb>
        );
    }

    private async save() {
        if (!this.validate(this.state.WeaningListSetting)) {
            return;
        }

        let weaningListSetup = deepCopy(this.state.WeaningListSetting);
        weaningListSetup.siteId = this.props.siteId;
        if(weaningListSetup.selectedType === WeaningListTypes.Location){
            weaningListSetup.cycleDays = undefined!;
        }
        this.props.saveWorkListSetting(weaningListSetup);
    }

    private getFormRows() {
        let formRows = new Array<FormRow>();

        formRows.push(this.getBuildingHeader());
        formRows.push(
            getSkioldInputRow(
                setup => this.weaningListSetupChanged(setup),
                this.state.WeaningListSetting,
                'name',
                localized('name')
            )
        );
        formRows.push(this.getAnimalToListRow());
        if( this.state.WeaningListSetting.selectedType === WeaningListTypes.PregnancyDays){
            formRows.push(this.getCycleDaysRow());
        }
        formRows.push(
            getAvailablityRow(
                () => this.toggleShowOnWeb(),
                () => this.toggleShowOnApp(),
                this.state.WeaningListSetting.showOnWeb!,
                this.state.WeaningListSetting.showOnApp!
            )
        );

        return formRows;
    }

	private getAnimalToListRow(): FormRow {
		return {
			name: localized('chooseAnimalToList'),
			component: (
				<SkioldFormDropdown
					items={this.state.options}
					selectedValue={this.state.selectedOption}
					onValueChanged={this.animalToListChanged}
				/>
			)
		};
	}


    private validate(listSetting: IWeaningListSetting) {
        if (!listSetting.selectedType  ) {
            this.showErrorMessage(localized(ExceptionMessage.VALIDATION_ERROR_CHOOSE_ANIMAL_NOT_SET));
            return false;
        }
        if (listSetting.selectedType === WeaningListTypes.PregnancyDays && (!listSetting.cycleDays || (listSetting.cycleDays && listSetting.cycleDays < 0))) {
            this.showErrorMessage(localized(ExceptionMessage.VALIDATION_ERROR_CYCLEDAYS_NOT_SET));
            return false;
        }
        return true;
    }

    private showErrorMessage(errorMessage: string) {
        alert(errorMessage);
    }

    private getBuildingHeader(): FormRow {
        return {
            header: localized(this.props.workListType)
        };
    }

    private getCycleDaysRow(): FormRow {
        return {
            name: localized('cycleDays'),
            component: (
                <SkioldFormIntegerInput
                    onChangeNumber={newText => this.cycleDaysChanged(newText)}
                    text={this.state.WeaningListSetting.cycleDays}
                />
            )
        };
    }

    private getStateRow(): FormRow {
        return {
            name: localized('condition'),
            component: (
                <TextWeb className='condition-container'>
                    {localized('Nursing') + ' (' + localized('PregnantAndNursing') + ')'}
                </TextWeb>
            )
        };
    }

    private renderButtons() {
      return (
          <ViewWeb className='view-button-container'>
            <SkioldButton title={ localized('Save') } onPress={ () => this.save() }/>
          </ViewWeb>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)((WeaningListSetup));
