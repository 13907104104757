import React from 'react';

import { Dispatch } from 'redux';

import { connect } from 'react-redux';
import { WebAppState } from 'web/state/store.web';
import { IDiagnoseCategory } from 'shared/api/api';
import { SaveDiagnoseCategory } from 'shared/state/ducks/diagnoseCategories/operations';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { localized } from 'shared/state/i18n/i18n';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { deepCopy } from 'shared/helpers/general-helpers';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { GetSyncData as DiagnoseGetSyncData } from 'shared/state/ducks/diagnoses/operations';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { SkioldTextField } from '../skiold-components/skiold-text-field/skiold-text-field';
import './add-diagnose-category.scss';
import { SkioldIntegerTextField } from '../skiold-components/skiold-text-field/skiold-integer-text-field';

interface PropsFromParent {
	close: () => void;
	diagnoseCategory?: IDiagnoseCategory;
	isOpen: boolean;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		diagnoseCategories: state.diagnoseCategory.entities,
		diagnoses: state.diagnose.entities,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	postDiagnoseCategory: (diagnoseCategory: IDiagnoseCategory) => SaveDiagnoseCategory(diagnoseCategory)(dispatch),
	getDiagnoses: () => DiagnoseGetSyncData()(dispatch),
});

interface State {
	diagnoseCategory: IDiagnoseCategory;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;
class AddDiagnoseCategory extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			diagnoseCategory: props.diagnoseCategory || ({ priority: 0, name: {} } as IDiagnoseCategory),
		};

		this.props.getDiagnoses();
	}

	public render() {
		return (
			<ViewWeb className="add-diagnose-category">
				<TextWeb className={'white-text'}>{localized('category')}</TextWeb>
				<SkioldTextField
					className={'white-text'}
					onChangeText={text => this.setName(text, 'da')}
					label={localized('categoryNameDa')}
					value={this.state.diagnoseCategory.name!.da}
				/>

				<SkioldTextField
					className={'white-text'}
					onChangeText={text => this.setName(text, 'en')}
					label={localized('categoryNameEn')}
					value={this.state.diagnoseCategory.name!.en}
				/>

				<SkioldIntegerTextField
					onChangeNumber={this.setPriority}
					label={localized('priority')}
					value={this.state.diagnoseCategory.priority}
					className={'white-text'}
				/>

				<ViewWeb className="button-container">
					<SkioldButton title={localized('Save')} onPress={this.save} />
					<SkioldButton title={localized('Close')} onPress={this.props.close} theme="grey" />

					{this.state.diagnoseCategory.id && (
						<SkioldButton title={localized('delete')} onPress={this.delete} theme="grey" />
					)}
				</ViewWeb>
			</ViewWeb>
		);
	}

	private setName(value: string, countryCode: string) {
		const prevNameDict = deepCopy(this.state.diagnoseCategory.name);
		if (countryCode === 'da') {
			prevNameDict!.da = value;
		} else if (countryCode === 'en') {
			prevNameDict!.en = value;
		}
		this.setState(prevState => ({ diagnoseCategory: { ...prevState.diagnoseCategory, name: prevNameDict } }));
	}

	private setPriority = (value: number | undefined) => {
		this.setState(prevState => ({
			diagnoseCategory: { ...prevState.diagnoseCategory, priority: value } as IDiagnoseCategory,
		}));
	};

	private save = () => {
		this.props.postDiagnoseCategory(this.state.diagnoseCategory);
		this.props.close();
	};

	private delete = () => {
		let id = this.state.diagnoseCategory.id!;
		// Dont allow deleting of a category that is in use on a diagnose
		if (this.props.diagnoses.findIndex(diagnose => diagnose.diagnoseCategoryId === id) > -1) {
			alert(localized(ExceptionMessage.VALIDATION_CAT_IN_USE));
			return;
		}

		if (!window.confirm(localized('PromptSureDelete'))) {
			return;
		}
		const diagCategory = deepCopy(this.state.diagnoseCategory);
		diagCategory.isDeleted = true;
		this.props.postDiagnoseCategory(diagCategory);
		this.props.close();
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(AddDiagnoseCategory);
