import React from 'react';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ISection } from 'shared/api/api';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { localized } from 'shared/state/i18n/i18n';
import { PenFormData } from 'shared/state/models/locations/pen-form-data';
import { WebAppState } from "web/state/store.web";
import { showAlert } from 'web/view/components/skiold-alert/skiold-alert';
import { SkioldFormsWrapper } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { SkioldFormInput } from 'web/view/components/skiold-components/skiold-input/skiold-form-input';
import { SkioldFormIntegerInput } from 'web/view/components/skiold-components/skiold-integer-input/skiold-form-integer-input';
import './add-pen.scss';

interface PropsFromParent {
    section: ISection;
    usingPens: boolean;
}

const mapStateToProps = (state: WebAppState) => {
    return {
        pens: state.locations.pens
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({});

interface AddPenState {
    penForm: PenFormData;
}

type AddPenProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;
export class AddPen extends React.PureComponent<AddPenProps, AddPenState> {
    constructor(props: AddPenProps) {
        super(props);
        this.state = {
            penForm: new PenFormData()
        };
    }
    public componentDidMount() {}

    public amountOfPensChanged(numOfPens: number | undefined) {
        this.setState(prevState => ({ penForm: { ...prevState.penForm, numOfPens } }));
    }

    public startNumberChange(startNumber: number | undefined) {
        this.setState(prevState => ({ penForm: { ...prevState.penForm, startNumber } }));
    }

    public capacityChanged(capacity: number | undefined) {
        this.setState(prevState => ({ penForm: { ...prevState.penForm, capacity } }));
    }

    public letterChanged(letter: string) {
        this.setState(prevState => ({ penForm: { ...prevState.penForm, letter: letter.slice(-1) } }));
    }

    public render() {
        return (
            <ViewWeb className='add-pen'>
                <ViewWeb
                    className={`form-container ${this.props.usingPens?'pointer-event-auto':'pointer-event-none'}`}
                >
                    <SkioldFormsWrapper formRows={this.getFormRows()} containerClassName='wrapper-container' />
                </ViewWeb>
            </ViewWeb>
        );
    }

    public save(sectionId?: string) {
        if (!this.validate()) {
            return undefined;
        }
        return this.state.penForm;
    }

    public validate() {
        if (!this.props.usingPens) {
            return true;
        }

        if (!this.state.penForm.numOfPens) {
            showAlert(localized(ExceptionMessage.VALIDATION_ERROR_FIELDS_REQUIRED));
            return false;
        }

        if (this.state.penForm.numOfPens < 1) {
            showAlert(localized(ExceptionMessage.VALIDATION_ERROR_CANNOT_BE_NEGATIVE_OR_ZERO));
            return false;
        }

        if (this.state.penForm.startNumber === undefined) {
            showAlert(localized(ExceptionMessage.VALIDATION_ERROR_FIELDS_REQUIRED));
            return false;
        }

        if (this.state.penForm.startNumber < 0) {
            showAlert(localized(ExceptionMessage.VALIDATION_ERROR_CANNOT_BE_NEGATIVE));
            return false;
        }

        if (!this.state.penForm.capacity) {
            showAlert(localized(ExceptionMessage.VALIDATION_ERROR_FIELDS_REQUIRED));
            return false;
        }

        if (this.state.penForm.capacity < 1) {
            showAlert(localized(ExceptionMessage.VALIDATION_ERROR_CANNOT_BE_NEGATIVE_OR_ZERO));
            return false;
        }
        if (this.props.section) {
            if (this.hasDuplicatePenNumbers(this.props.section.id!)) {
                showAlert(localized(ExceptionMessage.VALIDATION_ERROR_DUPLICATE_PEN_NUMBERS));
                return false;
            }
        }
        return true;
    }

    private getFormRows() {
        let formRows = new Array<FormRow>();
        formRows.push(this.getPenHeader());
        formRows.push(this.getAmountPensRow());
        formRows.push(this.getStartNumberRow());
        formRows.push(this.getCapacityRow());
        formRows.push(this.getPenLetter());

        return formRows;
    }

    private getPenHeader(): FormRow {
        return {
            header: localized('PEN')
        };
    }

    private getAmountPensRow(): FormRow {
        return {
            name: localized('amountOfPens'),
            component: (
                <SkioldFormIntegerInput
                    onChangeNumber={newNumber => this.amountOfPensChanged(newNumber)}
                    text={this.state.penForm.numOfPens}
                />
            )
        };
    }

    private getStartNumberRow(): FormRow {
        return {
            name: localized('startNumber'),
            component: (
                <SkioldFormIntegerInput
                    onChangeNumber={newNumber => this.startNumberChange(newNumber)}
                    text={this.state.penForm.startNumber}
                />
            )
        };
    }

    private getCapacityRow(): FormRow {
        return {
            name: localized('capacity'),
            component: (
                <SkioldFormIntegerInput
                    onChangeNumber={newNumber => this.capacityChanged(newNumber)}
                    text={this.state.penForm.capacity}
                />
            )
        };
    }

    private getPenLetter(): FormRow {
        return {
            name: localized('letterBehindPen'),
            component: (
                <SkioldFormInput
                    onChangeText={newText => this.letterChanged(newText)}
                    text={this.state.penForm.letter}
                />
            )
        };
    }

    private hasDuplicatePenNumbers(sectionId: string) {
        const currentPenNames = this.props.pens.filter(pen => pen.sectionId === sectionId).map(pen => pen.name);
        const newPenNames = [] as string[];
        //generate new section names
        for (
            let i = this.state.penForm.startNumber!;
            i < this.state.penForm.numOfPens! + this.state.penForm.startNumber!;
            i++
        ) {
            newPenNames.push(i.toString() + this.state.penForm.letter);
        }
        let duplicates = currentPenNames.filter(penName => newPenNames.includes(penName!));
        if (duplicates.length > 0) {
            return true;
        } else {
            return false;
        }
        // const currentPens = this.props.pens.filter(pen => pen.sectionId === sectionId);
        // const newPenNumbers = [] as number[];
        // for (
        //     let i = this.state.penForm.startNumber!;
        //     i <= this.state.penForm.numOfPens! + this.state.penForm.startNumber!;
        //     i++
        // ) {
        //     newPenNumbers.push(i);
        // }
        // let duplicates = currentPens.filter(
        //     pen =>
        //         newPenNumbers.includes(pen.number!) &&
        //         (this.state.penForm.letter === pen.letter || (!pen.letter && !this.state.penForm.letter))
        // );
        // if (duplicates.length > 0) {
        //     return true;
        // } else {
        //     return false;
        // }
    }
}

export default (
    connect(
        mapStateToProps,
        mapDispatchToProps,
        null,
        { forwardRef: true }
    )(AddPen) as any
);
