import moment from 'moment';
import React from 'react';
import { Option } from 'react-dropdown';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
	IDeadPigletsEvent,
	IMatingBatchSetting,
	IPregnancyEvent,
	IStemAnimal,
	IUserProfile,
	IValidationSetup,
	SowBoardTableModel,
} from 'shared/api/api';
import DeleteIcon from 'shared/assets/src-assets/png/delete_icon_grey.png';
import ImportYoungAnimals from 'shared/assets/src-assets/png/importungdyr.png';
import ExportYoungAnimals from 'shared/assets/src-assets/png/ExportYoungAnimal.png';
import CreateAnimal from 'shared/assets/src-assets/png/opret_dyr@4x.png';
import PrinterGreyIcon from 'shared/assets/src-assets/png/printer_ikon_grey.png';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { isEmpty } from 'shared/helpers/general-helpers';
import { GetSyncData as MatingBatchGetSyncData } from 'shared/state/ducks/mating-batch/operations';
import { GetSyncData as GetMoveEventSyncData } from 'shared/state/ducks/move-events/operations';
import { GetSyncData as PregnancyEventGetSyncData } from 'shared/state/ducks/pregnancy-events/operations';
import { selectNucleusManagementOrAssignIdAccess } from 'shared/state/ducks/site/reducer';
import { GetSyncData as StemAnimalGetSyncData } from 'shared/state/ducks/stem-animals/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import PageContainer from 'web/view/components/page-container/page-container';
import { showAlert, ShowCustomAlert } from 'web/view/components/skiold-alert/skiold-alert';
import { SkioldDatePicker } from 'web/view/components/skiold-components/skiold-date-picker/skiold-date-picker';
import { SkioldDropdown } from 'web/view/components/skiold-components/skiold-dropdown/skiold-dropdown';
import { SkioldModal } from 'web/view/components/skiold-components/skiold-modal/skiold-modal';
import SkioldTableItemCounter from 'web/view/components/skiold-components/skiold-table/skiold-table-item-counter';
import { SkioldTouchableOpacity } from 'web/view/components/skiold-components/skiold-touchable-opacity';
import { ActiveSowPrint } from 'web/view/components/stem-animal/animal-lists/active-sows/active-sow-print';
import ActiveSows, {
	ActiveSows as ActiveSowsRef,
} from 'web/view/components/stem-animal/animal-lists/active-sows/active-sows';
import Boars from 'web/view/components/stem-animal/animal-lists/boars/boars';
import DepartedBoars from 'web/view/components/stem-animal/animal-lists/boars/departed-boars';
import DeparturedSows from 'web/view/components/stem-animal/animal-lists/departured-sows/departured-sows';
import DepartedYoungAnimals from 'web/view/components/stem-animal/animal-lists/young-animals/departed-young-animals';
import { ExportData } from 'web/view/components/stem-animal/animal-lists/young-animals/export-data';
import ImportData from 'web/view/components/stem-animal/animal-lists/young-animals/import-data';
import YoungAnimals, {
	YoungAnimals as YoungAnimalsRef,
} from 'web/view/components/stem-animal/animal-lists/young-animals/young-animals';
import { WhiteText } from 'web/view/components/Text/white-text';
import { Heading } from 'web/view/components/utils/heading';
import { SkioldIconSpinner } from 'web/view/components/utils/skiold-icon-spinner';
import { SkioldImage } from 'web/view/components/utils/svg/skiold-image';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { GetAnimalListPdf, GetYoungAnimalPdf } from 'web/web-helpers/pdf-helper/animal-list-helper';
import { GenerateDataForSowBoardPdf, GetSowBoardPdf } from 'web/web-helpers/pdf-helper/sow-board-helper';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import SowEvents from '../sow-events/sow-events';
import './animals.scss';

export interface PropsFromState {
	navigation: any;
	profile: IUserProfile;
	animals: IStemAnimal[];
	pregnancyEvents: { [key: string]: IPregnancyEvent[] };
	validationSetup: IValidationSetup;
	matingBatchSetting: IMatingBatchSetting;
	deadPigletsEvents: IDeadPigletsEvent[];
	nucleusManagement: string | undefined;
}

const mapStateToProps = (state: WebAppState): PropsFromState => {
	return {
		navigation: state.navigation,
		profile: state.profile.active!,
		matingBatchSetting: state.matingBatchSetting.currentMatingBatchSetting,
		animals: state.stemAnimals.entities,
		pregnancyEvents: state.pregnancyEvents.entities,
		validationSetup: state.validationSetup.entity,
		deadPigletsEvents: state.deadPigletsEvents.entities,
		nucleusManagement: selectNucleusManagementOrAssignIdAccess(state),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
	return {
		stemAnimalGetSyncData: () => StemAnimalGetSyncData()(dispatch),
		pregnancyEventGetSyncData: () => PregnancyEventGetSyncData()(dispatch),
		matingBatchGetSyncData: () => MatingBatchGetSyncData()(dispatch),
		getMoveEventSyncData: () => GetMoveEventSyncData()(dispatch),
	};
};

export interface State {
	events: Option[];
	selected: Option | undefined;
	fromDate: Date | undefined;
	animalIds: string[];
	activeSowsRef: ActiveSowsRef | undefined;
	animalCount: number;
	toDate: Date | undefined;
	dateProperty: Option;
	entranceModalOpen: boolean;
	importModalOpen: boolean;
	exportModalOpen: boolean;
	activeSowPrintModalOpen: boolean;
	isDownloadingSowBoard: boolean;
	isDownloadingAnimalList: boolean;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class Animals extends React.PureComponent<Props, State> {
	public activeSowsRef: ActiveSowsRef | undefined;
	public departuredSowsRef: ActiveSowsRef | undefined;
	public activeBoarsRef: ActiveSowsRef | undefined;
	public departedBoarsRef: ActiveSowsRef | undefined;
	public departedYoungAnimalsRef: ActiveSowsRef | undefined;
	public youngAnimalsRef: YoungAnimalsRef | undefined;
	public shouldForceUpdate: boolean = false;

	constructor(props: Props) {
		super(props);

		let events = [
			{ label: localized('activeSows'), value: 'activeSows' },
			{ label: localized('departuredSows'), value: 'departuredSows' },
			{ label: localized('activeBoars'), value: 'activeBoars' },
			{ label: localized('departedBoars'), value: 'departedBoars' },
			{ label: localized('youngAnimals'), value: 'youngAnimals' },
			{ label: localized('departedYoungAnimals'), value: 'departedYoungAnimals' },
		];

		this.state = {
			activeSowsRef: undefined,
			selected: { label: localized('activeSows'), value: 'activeSows' },
			animalCount: 0,
			animalIds: [],
			events,
			fromDate: undefined,
			toDate: undefined,
			dateProperty: { label: localized('entranceDate'), value: 'entranceDate' },
			entranceModalOpen: false,
			importModalOpen: false,
			exportModalOpen: false,
			isDownloadingSowBoard: false,
			isDownloadingAnimalList: false,
			activeSowPrintModalOpen: false,
		};

		this.props.stemAnimalGetSyncData();
		this.props.pregnancyEventGetSyncData();
		this.props.matingBatchGetSyncData();
		this.props.getMoveEventSyncData();
	}

	public selectedEventChanged = (option: Option) => {
		if (option.value === 'activeSows' || option.value === 'youngAnimals' || option.value === 'activeBoars') {
			this.setState({
				selected: option,
				fromDate: undefined,
				toDate: undefined,
				dateProperty: { label: localized('entranceDate'), value: 'entranceDate' },
			});
		} else if (
			option.value === 'departuredSows' ||
			option.value === 'departedBoars' ||
			option.value === 'departedYoungAnimals'
		) {
			this.setState({
				selected: option,
				fromDate: moment().subtract(1, 'month').toDate(),
				toDate: new Date(),
				dateProperty: { label: localized('departureDate'), value: 'departureDate' },
			});
		}
	};

	public componentDidUpdate() {}

	public render() {
		return (
			<PageContainer className="animals">
				<Heading text={localized('CHOOSELIST')} />
				<ViewWeb className="viewContainer">
					{/* Header row */}
					<ViewWeb className="viewHeaderRow">
						{/* Left header column */}
						<ViewWeb className="leftHeaderColumn">
							<ViewWeb className="centerItemsHorizontal">
								<SkioldDatePicker
									onDateChanged={newDate => this.fromDateChanged(newDate)}
									selectedDate={this.state.fromDate!}
									text={'FromDate'}
									containerClassName="marginRightTen"
									includeTimeStamp={false}
								/>
								<SkioldDatePicker
									onDateChanged={newDate => this.toDateChanged(newDate)}
									selectedDate={this.state.toDate!}
									text={'ToDate'}
									containerClassName="marginRightTen"
									isEndDate={true}
								/>
								<SkioldDropdown
									items={this.getDateProperties()}
									onValueChanged={(text: Option) => this.setDateItem(text)}
									selectedValue={this.state.dateProperty}
									containerClassName="animals-dropdown"
								/>
								<TextWeb className="label paddingRightFive">{localized('Animals')}: </TextWeb>
								<SkioldTableItemCounter />
							</ViewWeb>
						</ViewWeb>

						{/* Center header column */}
						<ViewWeb className="alignDropdownCenter">
							<SkioldDropdown
								onValueChanged={this.selectedEventChanged}
								selectedValue={this.state.selected}
								items={this.state.events}
								containerClassName="animals-dropdown"
							/>
						</ViewWeb>

						{/* Right header column */}
						<ViewWeb className="end sizing verticalCenter">
							{this.state.selected &&
								this.state.selected.value === 'activeSows' &&
								this.renderButtonsForActiveSows()}

							{this.state.selected &&
								this.state.selected.value === 'youngAnimals' &&
								this.renderImportButton()}
						</ViewWeb>
					</ViewWeb>

					{/* Table row */}
					<ViewWeb className="alignItemsCenter">
						{this.state.selected && this.state.selected.value === 'activeSows' && (
							<ActiveSows
								ref={this.HandleRef}
								fromDate={this.state.fromDate}
								toDate={this.state.toDate}
								dateFilterProperty={
									this.state.dateProperty.value as 'entranceDate' | 'birthDate' | 'breedIndexDate'
								}
							/>
						)}
						{this.state.selected && this.state.selected.value === 'departuredSows' && (
							<DeparturedSows
								ref={this.HandleRef}
								fromDate={this.state.fromDate}
								toDate={this.state.toDate}
								dateFilterProperty={this.state.dateProperty.value}
							/>
						)}
						{this.state.selected && this.state.selected.value === 'activeBoars' && (
							<Boars
								ref={this.HandleRef}
								fromDate={this.state.fromDate}
								toDate={this.state.toDate}
								dateFilterProperty={this.state.dateProperty.value}
							/>
						)}
						{this.state.selected && this.state.selected.value === 'departedBoars' && (
							<DepartedBoars
								ref={this.HandleRef}
								fromDate={this.state.fromDate}
								toDate={this.state.toDate}
								dateFilterProperty={this.state.dateProperty.value}
							/>
						)}
						{this.state.selected && this.state.selected.value === 'youngAnimals' && (
							<YoungAnimals
								ref={this.HandleRef}
								fromDate={this.state.fromDate}
								toDate={this.state.toDate}
								dateFilterProperty={this.state.dateProperty.value}
							/>
						)}
						{this.state.selected && this.state.selected.value === 'departedYoungAnimals' && (
							<DepartedYoungAnimals
								ref={this.HandleRef}
								fromDate={this.state.fromDate}
								toDate={this.state.toDate}
								dateFilterProperty={this.state.dateProperty.value}
							/>
						)}
					</ViewWeb>

					<SkioldModal padding="0" isOpen={this.state.entranceModalOpen} close={this.closeModal}>
						<SowEvents closeEditModal={this.closeModal} theme="dark" />
					</SkioldModal>
					<SkioldModal
						overflowNone={true}
						padding="0"
						isOpen={this.state.importModalOpen}
						close={this.closeModal}
					>
						<ImportData closeModal={this.closeModal} theme="dark" />
					</SkioldModal>
					{this.state.exportModalOpen && (
						<SkioldModal
							overflowNone={true}
							padding="0"
							isOpen={this.state.exportModalOpen}
							close={this.closeModal}
							minWidth="300px"
						>
							<ExportData closeModal={this.closeModal} youngAnimals={this.youngAnimalsRef?.getMarked()} />
						</SkioldModal>
					)}
					{this.state.activeSowPrintModalOpen && (
						<SkioldModal
							overflowNone={true}
							padding="0"
							isOpen={this.state.activeSowPrintModalOpen}
							close={this.closeModal}
							minWidth="300px"
						>
							<ActiveSowPrint
								closeModal={this.closeModal}
								stemAnimals={this.activeSowsRef!.GetSortedData()}
							/>
						</SkioldModal>
					)}
				</ViewWeb>
			</PageContainer>
		);
	}

	private handleOldRefs() {
		this.shouldForceUpdate = true;
		this.activeSowsRef = undefined;
		this.departuredSowsRef = undefined;
		this.activeBoarsRef = undefined;
		this.departedBoarsRef = undefined;
		this.youngAnimalsRef = undefined;
		this.departedYoungAnimalsRef = undefined;
	}

	private HandleRef = (wrappedInstance: any) => {
		if (wrappedInstance && this.state.selected !== undefined) {
			switch (this.state.selected.value) {
				case 'activeSows':
					if (this.activeSowsRef === undefined) {
						this.handleOldRefs();
					}
					this.activeSowsRef = wrappedInstance;

					break;
				case 'departuredSows':
					if (this.departuredSowsRef === undefined) {
						this.handleOldRefs();
					}
					this.departuredSowsRef = wrappedInstance;

					break;
				case 'activeBoars':
					if (this.activeBoarsRef === undefined) {
						this.handleOldRefs();
					}
					this.activeBoarsRef = wrappedInstance;

					break;
				case 'departedBoars':
					if (this.departedBoarsRef === undefined) {
						this.handleOldRefs();
					}
					this.departedBoarsRef = wrappedInstance;

					break;
				case 'youngAnimals':
					if (this.youngAnimalsRef === undefined) {
						this.handleOldRefs();
					}
					this.youngAnimalsRef = wrappedInstance;
					break;
				case 'departedYoungAnimals':
					if (this.departedYoungAnimalsRef === undefined) {
						this.handleOldRefs();
					}
					this.departedYoungAnimalsRef = wrappedInstance;

					break;

				default:
					break;
			}
		}
	};

	private renderButtonsForActiveSows() {
		return (
			<ViewWeb className="flexDirectionRow">
				<ViewWeb className="alignItemsCenter">
					<SkioldTouchableOpacity className="positionRelative" onPress={this.openModal}>
						<SkioldImage width="60" height="60" imageData={CreateAnimal} />
					</SkioldTouchableOpacity>
					<WhiteText>{localized('CreateAnimal')}</WhiteText>
				</ViewWeb>
				<ViewWeb className="borderButtonsView" />
				<SkioldIconSpinner title={'PrintSowTable'} icon={PrinterGreyIcon} onPress={this.download} />
				<ViewWeb className="borderButtonsView" />
				<SkioldIconSpinner title={'PrintList'} icon={PrinterGreyIcon} onPress={this.openActiveSowPrint} />
			</ViewWeb>
		);
	}

	private openActiveSowPrint = () => {
		this.setState({ activeSowPrintModalOpen: true });
	};
	private download = async () => {
		if (isEmpty(this.props.matingBatchSetting)) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_NO_MATING_BATCHES_GENERATED));
			return;
		}
		this.setState({ isDownloadingSowBoard: true });
		let sows: IStemAnimal[] = [];

		let sowcardforanimals: SowBoardTableModel[] = [];
		if (this.activeSowsRef) {
			sows = this.getAnimals(this.activeSowsRef!.GetSortedData());
			sows.forEach(sow => {
				GenerateDataForSowBoardPdf(
					sow,
					sowcardforanimals,
					this.props.pregnancyEvents,
					this.props.validationSetup,
					this.props.deadPigletsEvents,
				);
			});

			await GetSowBoardPdf(
				sowcardforanimals,
				'SoTavle.pdf',
				this.props.profile.language,
				this.props.profile.siteId,
			);
			this.setState({ isDownloadingSowBoard: false });
		}
	};

	private getAnimals(dataFromSkioldTable: any) {
		let animals: IStemAnimal[] = [];
		dataFromSkioldTable.forEach((element: any) => {
			let animal = this.props.animals.find(a => a.id === element.id);
			if (animal) {
				animals.push(animal);
			}
		});
		return animals;
	}

	private downloadYoungAnimal = async () => {
		this.setState({ isDownloadingAnimalList: true });
		try {
			await GetYoungAnimalPdf(
				this.youngAnimalsRef!.GetSortedData(),
				localized('activeYoungAnimals'),
				this.props.profile.siteId!,
				Intl.DateTimeFormat().resolvedOptions().timeZone,
				this.props.profile.language,
			);
		} finally {
			this.setState({ isDownloadingAnimalList: false });
		}
	};

	private renderImportButton() {
		return (
			<ViewWeb className="flexDirectionRow">
				<SkioldIconSpinner title={'PrintList'} icon={PrinterGreyIcon} onPress={this.downloadYoungAnimal} />
				<ViewWeb className="borderButtonsView" />
				<ViewWeb className="import-button">
					<SkioldTouchableOpacity className="positionRelative" onPress={this.deleteMarkedYoungAnimals}>
						<SkioldImage width="60" height="60" imageData={DeleteIcon} />
					</SkioldTouchableOpacity>
					<WhiteText>{localized('deleteMarked')}</WhiteText>
				</ViewWeb>
				<ViewWeb className="borderButtonsView" />
				<ViewWeb className="import-button">
					<SkioldTouchableOpacity
						className="positionRelative"
						onPress={() => this.setState({ importModalOpen: true })}
					>
						<SkioldImage width="60" height="60" imageData={ImportYoungAnimals} />
					</SkioldTouchableOpacity>
					<WhiteText>{localized('import')}</WhiteText>
				</ViewWeb>
				<ViewWeb className="borderButtonsView" />
				<ViewWeb className="import-button">
					<SkioldTouchableOpacity
						className="positionRelative"
						onPress={() => this.setState({ exportModalOpen: true })}
					>
						<SkioldImage width="60" height="60" imageData={ExportYoungAnimals} />
					</SkioldTouchableOpacity>
					<WhiteText>{localized('export')}</WhiteText>
				</ViewWeb>
			</ViewWeb>
		);
	}

	private deleteMarkedYoungAnimals = async () => {
		if (
			(await ShowCustomAlert(localized(ExceptionMessage.VALIDATION_WARNING_DELETE_MARKED_YOUNG_ANIMALS))) &&
			this.youngAnimalsRef
		) {
			this.youngAnimalsRef.deleteMarked();
		}
	};

	private openModal = () => {
		this.setState({ entranceModalOpen: true });
	};

	private closeModal = () => {
		this.setState({
			entranceModalOpen: false,
			importModalOpen: false,
			exportModalOpen: false,
			activeSowPrintModalOpen: false,
		});
	};

	private getDateProperties() {
		let items: Option[] = [
			{ label: localized('entranceDate'), value: 'entranceDate' },
			{ label: localized('birthdate'), value: 'birthDate' },
			{ label: localized('indexDate'), value: 'breedIndexDate' },
		];

		if (
			this.state.selected &&
			(this.state.selected.value === 'departuredSows' ||
				this.state.selected.value === 'departedBoars' ||
				this.state.selected.value === 'departedYoungAnimals')
		) {
			items.push({ label: localized('departureDate'), value: 'departureDate' });
		}

		return items;
	}

	private setDateItem(text: Option) {
		this.setState({ dateProperty: text });
	}

	private fromDateChanged(fromDate: Date) {
		this.setState({ fromDate });
	}

	private toDateChanged(toDate: Date) {
		this.setState({ toDate });
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Animals);
