import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AnimalType, Equipment, Gender, IMoveEvent, IPregnancyEvent, IStemAnimal, MoveEvent } from 'shared/api/api';
import { getDateString } from 'shared/helpers/date-helpers';
import { deepCopy } from 'shared/helpers/general-helpers';
import { CheckIfSectionUsesPens, FindHiddenPenOnSection, getLocationString } from 'shared/helpers/location-helper';
import { ValidateMoveEvent } from 'shared/helpers/stemanimal-helper/stemanimal-helper';
import { GetLocations } from 'shared/state/ducks/locations/operations';
import { GetSyncData as MoveEventGetSyncData, SaveMoveEvent } from 'shared/state/ducks/move-events/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import SkioldFormPenPicker from 'web/view/components/location/location-picker/skiold-form-pen-picker';
import SkioldStableSectionPicker from 'web/view/components/location/location-picker/skiold-stable-section-picker';
import SkioldFeedCurvePicker from 'web/view/components/process-equipment/skiold-feed-curve-picker';
import { showAlert, ShowConfirmAlert } from 'web/view/components/skiold-alert/skiold-alert';
import { SkioldDatePicker } from 'web/view/components/skiold-components/skiold-date-picker/skiold-date-picker';
import { SkioldFormTextField } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-form-text';
import { FormRow } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { SkioldFormIntegerInput } from 'web/view/components/skiold-components/skiold-integer-input/skiold-form-integer-input';
import { GenderPicker } from '../../gender-picker/gender-picker';
import StemAnimalInput, { StemAnimalInput as StemAnimalInputRef } from '../../stem-animal-input/stem-animal-input';

const mapStateToProps = (state: WebAppState) => {
	return {
		siteId: state.profile.active!.siteId,
		moveEvents: state.moveEvents.entities,
		moveEventUpdates: state.moveEvents.updates,
		pens: state.locations.pens,
		unitToPens: state.unitToPenData.data,
		processEquipmentData: state.processEquipmentData.entities,
		stemAnimals: state.stemAnimals.entities,
		esfExists: state.processEquipments.entities.find(pq => pq.equipment === Equipment.ESF) !== undefined,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	saveMoveEvent: (moveEvent: IMoveEvent, maxRetries: number) => SaveMoveEvent(moveEvent, maxRetries)(dispatch),
	getSyncData: () => MoveEventGetSyncData()(dispatch),
	GetLocations: () => GetLocations()(dispatch),
});

interface PropsFromParent {
	stemAnimal?: IStemAnimal | undefined;
	pregnancyEvent?: IPregnancyEvent;
	gender: Gender | undefined;
	date?: Date;
	save: (method: () => Promise<boolean>) => void;
	dateRow?: (row: FormRow) => void;
	genderRow?: (row: FormRow) => void;
	animalNumberRow?: (row: FormRow) => void;
	locationRow?: (row: FormRow) => void;
	stableRow?: (row: FormRow) => void;
	penRow?: (row: FormRow) => void;
	update?: () => void;
	feedCurveRow?: (row: FormRow) => void;
	fixedDeviationRow?: (row: FormRow) => void;
	lastMoveDateRow?: (row: FormRow) => void;
	useCurrentLocationFromParent?: boolean;
}

interface MoveEventState {
	stemAnimal: IStemAnimal | undefined;
	gender: Gender | undefined;
	animalNumber: string | undefined;
	moveEvent: IMoveEvent;
	sectionId?: string;
	penId?: string;
	feedCurveNumber?: number;
	lastMoveDate?: Date;
}

type MoveEventProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;
export class MoveEventBase extends React.PureComponent<MoveEventProps, MoveEventState> {
	public static defaultProps: Partial<MoveEventProps> = {};
	private animalNumInputRef: StemAnimalInputRef | null | undefined;

	public static getDerivedStateFromProps(
		nextProps: MoveEventProps,
		prevState: MoveEventState,
	): Partial<MoveEventState> {
		if (nextProps.stemAnimal && (!prevState.stemAnimal || prevState.stemAnimal.id !== nextProps.stemAnimal.id)) {
			const lastMoveDate = nextProps.moveEvents.find(
				moveEvent => nextProps.stemAnimal && moveEvent.stemAnimalId === nextProps.stemAnimal.id,
			);
			return { stemAnimal: nextProps.stemAnimal, lastMoveDate: lastMoveDate && lastMoveDate.moveDate };
		}

		if (nextProps.date && prevState.moveEvent.moveDate !== nextProps.date) {
			return { moveEvent: { ...prevState.moveEvent, moveDate: nextProps.date } };
		}

		if (nextProps.gender && prevState.gender !== nextProps.gender) {
			return { gender: nextProps.gender };
		}
		return {};
	}
	constructor(props: MoveEventProps) {
		super(props);
		this.state = {
			stemAnimal: undefined,
			gender: props.gender,
			animalNumber: undefined,
			moveEvent: MoveEvent.fromJS({
				moveDate: props.date ? props.date : new Date(),
				fixedDeviation: 100,
			} as IMoveEvent),
			sectionId: undefined,
			penId: undefined,
			feedCurveNumber: undefined,
			lastMoveDate: undefined,
		};
		this.props.GetLocations();
	}

	public componentDidMount() {
		this.props.save(() => this.save());
		this.props.getSyncData();
	}

	public forceUpdateComponent = () => {
		if (this.props.update) {
			this.props.update();
		}
	};
	private fixedDeviationChanged = (value: number | undefined) => {
		this.setState(
			prevState => ({ moveEvent: { ...prevState.moveEvent, fixedDeviation: value } }),
			this.forceUpdateComponent,
		);
	};
	public animalChanged = (newAnimal?: IStemAnimal | undefined): void => {
		//Secure that current moveDate is later than the last move event
		const lastMoveMoveEvent = this.props.moveEvents.find(
			moveEvent => newAnimal && moveEvent.stemAnimalId === newAnimal.id,
		);
		const lastMoveDate = lastMoveMoveEvent && lastMoveMoveEvent.moveDate;
		const currentMoveDate =
			this.state.moveEvent &&
			this.state.moveEvent.moveDate &&
			lastMoveDate &&
			this.state.moveEvent.moveDate < lastMoveDate
				? lastMoveDate
				: this.state.moveEvent.moveDate;
		this.setState(
			prevState => ({
				moveEvent: {
					...prevState.moveEvent,
					moveDate: currentMoveDate,
				},
				stemAnimal: newAnimal,
				lastMoveDate: lastMoveDate,
			}),
			this.forceUpdateComponent,
		);
	};

	public moveDateChanged = (newDate: Date) => {
		this.setState(
			prevState => ({ moveEvent: { ...prevState.moveEvent, moveDate: newDate } }),
			this.forceUpdateComponent,
		);
	};

	public animalNumberChanged = (animalNumber: string | undefined) => {
		this.setState({ animalNumber }, this.forceUpdateComponent);
	};

	public curveChanged = (feedCurveNumber: number | undefined) => {
		this.setState({ feedCurveNumber });
	};

	public render() {
		if (this.props.dateRow) {
			this.props.dateRow(this.getDateRow());
		}
		if (this.props.genderRow) {
			this.props.genderRow(this.getGenderRow());
		}
		if (this.props.animalNumberRow) {
			this.props.animalNumberRow(this.getAnimalNumberRow());
		}
		if (this.props.locationRow) {
			this.props.locationRow(this.getLocationRow());
		}
		if (this.props.lastMoveDateRow) {
			this.props.lastMoveDateRow(this.getLastMoveDateRow());
		}
		if (this.props.stableRow) {
			this.props.stableRow(this.getStableSectionPickerRow());
		}

		if (this.state.sectionId) {
			if (CheckIfSectionUsesPens(this.state.sectionId)) {
				if (this.props.penRow) {
					this.props.penRow(this.getPenPickerRow());
				}
			}
		}

		const sectionHasStation = this.CheckIfSectionHasStation();

		if (this.props.feedCurveRow && sectionHasStation) {
			this.props.feedCurveRow(this.getFeedCurvePickerRow());
		}
		if (this.props.fixedDeviationRow && sectionHasStation) {
			this.props.fixedDeviationRow(this.getFixedDeviationInputRow());
		}
		return null;
	}

	private CheckIfSectionHasStation() {
		return this.props.processEquipmentData.find(
			peq =>
				this.state.sectionId &&
				peq.equipmentType === Equipment.ESF &&
				peq.sectionId === this.state.sectionId &&
				peq.unitCodes &&
				peq.unitCodes.length > 0,
		);
	}

	private getGenderRow(): FormRow {
		return {
			name: localized('Gender'),
			component: <GenderPicker gender={this.state.gender} onGenderChanged={this.genderChanged} />,
		};
	}

	private getAnimalNumberRow(): FormRow {
		return {
			name: localized('animalNr'),
			component: (
				<StemAnimalInput
					onAnimalFound={this.animalChanged}
					onAnimalNumberChanged={this.animalNumberChanged}
					animalNumber={this.state.animalNumber}
					inputType={'form'}
					gender={this.state.gender}
					ref={this.setAnimalNumInputRef}
				/>
			),
		};
	}

	private getDateRow(): FormRow {
		return {
			name: localized('Date'),
			component: (
				<SkioldDatePicker
					onDateChanged={this.moveDateChanged}
					selectedDate={this.state.moveEvent.moveDate}
					theme={'dark'}
					color={'grey'}
					minDate={this.state.lastMoveDate}
				/>
			),
		};
	}

	private getStableSectionPickerRow(): FormRow {
		return {
			name: localized('StableSection'),
			component: (
				<SkioldStableSectionPicker
					usedInsideForm={true}
					AnimalType={AnimalType.Sow}
					allowYoungFemale={true}
					onStableSectionSelected={this.sectionSelected}
					penId={this.state.moveEvent ? this.state.moveEvent.penId : undefined}
					sectionId={this.state.sectionId}
					isPigProduction={true}
					useCurrentLocationFromParent={this.props.useCurrentLocationFromParent}
				/>
			),
		};
	}

	private getLocationRow(): FormRow {
		return {
			name: localized('currentLocation'),
			component: (
				<SkioldFormTextField>
					{this.state.stemAnimal ? getLocationString(this.state.stemAnimal) : ''}
				</SkioldFormTextField>
			),
		};
	}

	private getPenPickerRow(): FormRow {
		return {
			name: localized('Pen'),
			component: (
				<SkioldFormPenPicker
					usedInsideForm={true}
					onPenSelected={this.penSelected}
					sectionId={this.state.sectionId}
					penId={this.state.moveEvent ? this.state.moveEvent.penId : undefined}
					useCurrentLocationFromParent={this.props.useCurrentLocationFromParent}
				/>
			),
		};
	}

	private getFeedCurvePickerRow(): FormRow {
		return {
			name: localized('feedCurve'),
			component: (
				<SkioldFeedCurvePicker
					usedInsideForm={true}
					feedCurveNumber={this.state.feedCurveNumber}
					penId={this.state.penId}
					onChange={this.curveChanged}
				/>
			),
		};
	}

	private getFixedDeviationInputRow(): FormRow {
		return {
			name: localized('fixedPercent'),
			component: (
				<SkioldFormIntegerInput
					onChangeNumber={this.fixedDeviationChanged}
					text={this.state.moveEvent ? this.state.moveEvent.fixedDeviation : undefined}
				/>
			),
		};
	}

	private getLastMoveDateRow(): FormRow {
		return {
			name: localized('lastMoved'),
			component: <SkioldFormTextField>{getDateString(this.state.lastMoveDate)}</SkioldFormTextField>,
		};
	}

	private setAnimalNumInputRef = (ref: StemAnimalInputRef) => {
		this.animalNumInputRef = ref;
	};

	private sectionSelected = (selectedSectionId: string) => {
		if (CheckIfSectionUsesPens(selectedSectionId)) {
			this.setState({ penId: '', sectionId: selectedSectionId });
			if (this.props.update) {
				this.props.update();
			}
		} else {
			let penId = FindHiddenPenOnSection(selectedSectionId, this.state.penId);
			if (penId) {
				this.setState({ penId, sectionId: selectedSectionId });
			}
			if (this.props.update) {
				this.props.update();
			}
		}
	};

	private penSelected = (selectedPenId: string) => {
		this.setState({ penId: selectedPenId });
		if (this.props.update) {
			this.props.update();
		}
	};

	private genderChanged = (gender: Gender) => {
		this.setState({ gender });
	};

	private async save() {
		const moveEvent = deepCopy(this.state.moveEvent);
		moveEvent.stemAnimalId = this.state.stemAnimal ? this.state.stemAnimal.id : undefined;
		moveEvent.siteId = this.props.siteId;
		moveEvent.penId = this.state.penId;
		moveEvent.feedCurveNumber = this.state.feedCurveNumber;

		if (
			!(await ValidateMoveEvent(
				this.state.stemAnimal,
				moveEvent,
				this.state.sectionId,
				this.props.esfExists,
				this.props.pens,
				this.props.moveEvents,
				this.props.unitToPens,
				this.props.processEquipmentData,
				this.props.pregnancyEvent,
				showAlert,
				ShowConfirmAlert,
			))
		) {
			return false;
		}
		if (!this.CheckIfSectionHasStation()) {
			moveEvent.fixedDeviation = undefined;
		}

		// We make sure that moveEvent is saved before pregnancy event.
		// maxRetries is set to 0, to handle offline registrations go through quickly
		// Overall this handles correct order for ESF events
		await this.props.saveMoveEvent(moveEvent, 0);

		this.resetComponent();
		return true;
	}

	// Called after successfull save to remove one-time values
	private async resetComponent() {
		this.setState(
			{
				stemAnimal: undefined,
				moveEvent: MoveEvent.fromJS({
					moveDate: this.state.moveEvent.moveDate ? this.state.moveEvent.moveDate : new Date(),
					fixedDeviation: 100,
				} as IMoveEvent),
				animalNumber: '',
			},
			() => {
				if (this.props.update) {
					this.props.update();
				}

				this.focusAnimalNumber();
			},
		);
	}

	private focusAnimalNumber = () => {
		if (this.animalNumInputRef) {
			this.animalNumInputRef.focus();
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(MoveEventBase);
