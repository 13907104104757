export const SowListConstants = {
	iconWidth: 40,
	dateWidth: 105,
	weightWidth: 70,
	avgWeightWidth: 100,
    weaningDate: 140,
	boarWidth: 80,
	pregnantWidth: 80,
	pregnantDaysWidth: 90,
	pregnantDaysDashboardWidth: 145,
	adjustedPen: 80,
	countWidth: 80,
	buildingWidth: 100,
	penWidth: 80,
	adjustedSection: 100,
	adjustedBuilding: 120,
	adjustedProctionType: 140,
	aliveWidth: 80,
	deadWidth: 115,
	nursingDaysWidth: 90,
	wasteDaysWidth: 90,
	pigYearSowWidth: 110,
	locationWidth: 200,
	diagnoseWidth: 200,
	penIconWidth: 40,
	sectionWidth: 80,
	treatmentExecutedDateWidth: 100,
	treatmentNumberWidth: 100,
	pigletAmountWidth: 80,
	iconSVGWidth: 25,
	kindWidth: 50,
	batchWidth: 55,
	animalNrWidth: 100,
	conditionWidth: 85,
	entranceDateWidth: 125,
	entranceTypeWidth: 125,
	departureDateWidth: 125,
	departureWidth: 105,
	departureReason: 150,
	transponderWidth: 115,
	teatsWidth: 63,
	entrancePrice: 115,
	birthdayWidth: 100,
	departurePriceWidth: 110,
	adjustmentType: 140,
	registeredBy: 110,
	raceWidth: 55,
	dadNrWidth: 100,
	dadIdWidth: 100,
	momNrWidth: 100,
	momIdWidth: 100,
	indexWidth: 65,
	indexDateWidth: 125,
	iDNumberWidth: 110,
	ageWidth: 60,
	priceWidth: 60,
	cycleDaysWidth: 105,
	RetentionTimeWidth: 90,
	drugWidth: 100,
	unitType: 88,
	drugTreatmentsWidth: 160,
	treatmetLogDiagnoseWidth: 210,
	dashboardSettingNameWidth: 280,
	treatDefinitionDrugWidth: 275,
	reasonWidth: 180,
	nursingDaysFullWidth: 135,
	farrowingDate: 125,
	removeIconWidth: 50,
	supplierNrWidth: 130,
	breedingNumber: 80

};

export const TasksInFarrowingStableListConstants = {
	animalNrWidth: 100,
	cycleDaysWidth: 100,
	pcsWidth: 50,
	amountWidth: 50,
	completedWidth: 80,
	createTreatmentWidth: 55,
	iconSVGWidth: 25,
};
