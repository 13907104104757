import React from 'react';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import styled from 'styled-components';
import sokort_icon from 'shared/assets/src-assets/png/sokort_icon.png';
import PenIcon from 'shared/assets/src-assets/png/pen_icon.png';
import { Option } from 'react-dropdown';

import isEqual from 'react-fast-compare';
import { WebAppState } from 'web/state/store.web';
import { Gender, IStemAnimal } from 'shared/api/api';
import { GetDeparturedAnimalDataByDate, GetDeparturedAnimals } from 'shared/state/ducks/stem-animals/operations';
import { GetDeparturedPregnancies } from 'shared/state/ducks/pregnancy-events/operations';
import { GetDeparturedMoveEvents } from 'shared/state/ducks/move-events/operations';
import { localized, localizedDynamic } from 'shared/state/i18n/i18n';
import { SowListConstants } from '../table-constants';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { SkioldModal } from 'web/view/components/skiold-components/skiold-modal/skiold-modal';
import { SkioldImage } from 'web/view/components/utils/svg/skiold-image';
import { exactFilterMethod, exactFilterMethodGrid } from 'shared/helpers/general-helpers';
import { calculateAnimalKind1, getState1, idNumberFilter, printIdNumber } from 'shared/helpers/stemanimal-helper/stemanimal-helper';
import { getDateString } from 'shared/helpers/date-helpers';
import { NaturalSortDates } from 'shared/helpers/natural-sort';
import { findBatch1 } from 'shared/helpers/matingbatch-helper';
import SowEvents from 'web/view/pages/sow-events/sow-events';
import SkioldTableGrid, {
	SkioldTableGrid as SkioldTableRef,
} from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import './departured-sows.scss';
import { SkioldTouchableOpacity } from 'web/view/components/skiold-components/skiold-touchable-opacity';
import { Sorting } from '@devexpress/dx-react-grid';
import SowCard from 'web/view/pages/sow-card/sow-card';
import { GetDeparturedLardEvents } from 'shared/state/ducks/lardScanningEvents/operations';
import { getReasonNameById } from 'shared/helpers/reason-helper/reason-helper';
import { selectNucleusManagementOrAssignIdAccess } from 'shared/state/ducks/site/reducer';
import { hasNucleusFeatureCEFN } from 'shared/helpers/nucleus-management-helper/nucleus-management-helper';

const OuterDiv = styled.div`
	margin: 16px 0;
`;

interface PropsFromParent {
	fromDate: Date | undefined;
	toDate: Date | undefined;
	dateFilterProperty: string;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		siteId: state.profile.active!.siteId,
		profile: state.profile.active!,
		departuredSows: state.stemAnimals.departuredAnimals as IStemAnimal[],
		departuredPregnancies: state.pregnancyEvents.departuredPregnancies, //for render purposes
		matingBatch: state.matingBatches.entities, //for render purposes,
		language: state.profile.active && state.profile.active.language ? state.profile.active.language : 'en',
		reasons: state.reasons.entities,
		generalSettings: state.generalSettings.entity,
		nucleusManagement: selectNucleusManagementOrAssignIdAccess(state),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
	return {
		getDeparturedAnimalDataByDate: (siteId: string, fromDate: Date, toDate: Date) =>
			GetDeparturedAnimalDataByDate(siteId, fromDate, toDate)(dispatch),
	};
};

export interface State {
	events: Array<{ value: string }>;
	selected: string;
	editModalOpen: boolean;
	sowCardModalOpen: boolean;
	stemAnimalIdToEdit: string;
	EventOption: Option;
	departuredSows: IStemAnimal[];
	loading: boolean;
	columns: any[];
	columnExte: any[];
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;
export class DeparturedSows extends React.PureComponent<Props, State> {
	public SkioldTableRef: SkioldTableRef | undefined;
	constructor(props: Props) {
		super(props);

		this.state = {
			events: [],
			selected: '',
			editModalOpen: false,
			sowCardModalOpen: false,
			stemAnimalIdToEdit: '',
			EventOption: { label: localized('departure'), value: 'departure' },
			departuredSows: [],
			columns: this.generateColumns(),
			loading: false,
			columnExte: this.generateColumnsExtensions(),
		};
	}

	public generateColumnsExtensions() {
		let columnExt = [] as any;
		columnExt.push({
			columnName: 'sowCard',
			filteringEnabled: false,
			sortingEnabled: false,
			resizingEnabled: false,
			width: SowListConstants.iconWidth,
		});
		columnExt.push({
			columnName: 'edit',
			sortingEnabled: false,
			filteringEnabled: false,
			resizingEnabled: false,
			width: SowListConstants.iconWidth,
		});
		columnExt.push({
			columnName: 'animalNumber',
			width: SowListConstants.animalNrWidth,
		});
		columnExt.push({
			columnName: 'kind',
			width: SowListConstants.kindWidth,
		});
		columnExt.push({
			columnName: 'condition',
			width: SowListConstants.conditionWidth,
		});
		columnExt.push({
			columnName: 'entranceDate',
			width: SowListConstants.entranceDateWidth,
		});
		columnExt.push({
			columnName: 'entranceType',
			width: SowListConstants.entranceTypeWidth,
		});
		columnExt.push({
			columnName: 'departureDate',
			width: SowListConstants.departureDateWidth,
		});
		columnExt.push({
			columnName: 'departureType',
			width: SowListConstants.departureWidth,
		});
		columnExt.push({
			columnName: 'departureReason',
			width: SowListConstants.departureReason,
		});
		columnExt.push({
			columnName: 'batch',
			width: SowListConstants.batchWidth,
		});
		columnExt.push({
			columnName: 'transponder',
			width: SowListConstants.transponderWidth,
		});
		columnExt.push({
			columnName: 'teats',
			width: SowListConstants.teatsWidth,
		});
		columnExt.push({
			columnName: 'idNumber',
			width: SowListConstants.iDNumberWidth,
		});
		columnExt.push({
			columnName: 'birthdate',
			width: SowListConstants.birthdayWidth,
		});
		columnExt.push({
			columnName: 'race',
			width: SowListConstants.raceWidth,
		});
		if (this.props.nucleusManagement && this.props.generalSettings.showRaceLine) {
			columnExt.push({
				columnName: 'raceLine',
				width: 100,
			});
		}
		columnExt.push({
			columnName: 'index',
			width: SowListConstants.indexWidth,
		});
		columnExt.push({
			columnName: 'indexDate',
			width: SowListConstants.indexDateWidth,
		});

		if (this.props.nucleusManagement) {
			columnExt.push({
				columnName: 'breedingNumber',
				width: SowListConstants.breedingNumber,
			});
		}
		if (hasNucleusFeatureCEFN(this.props.nucleusManagement)) {
			columnExt.push({
				columnName: 'earNotch',
				width: SowListConstants.breedingNumber,
			});
		}
		if (this.props.generalSettings.stemAnimalShowMomDadData) {
			columnExt.push(
				{
					columnName: 'dadAnimalNumber',
					width: SowListConstants.dadNrWidth,
				},
				{
					columnName: 'dadRace',
					width: 100,
				},
				{
					columnName: 'dadIdNumber',
					width: SowListConstants.dadIdWidth,
				},

				{
					columnName: 'momAnimalNumber',
					width: SowListConstants.momNrWidth,
				},
				{
					columnName: 'momRace',
					width: 100,
				},
				{
					columnName: 'momIdNumber',
					width: SowListConstants.momIdWidth,
				},
			);
		}
		return columnExt;
	}
	public componentDidMount() {
		if (this.props.siteId && this.props.fromDate && this.props.toDate) {
			this.props.getDeparturedAnimalDataByDate(this.props.siteId, this.props.fromDate, this.props.toDate);
		}

		this.getFilteredData();
	}

	public componentDidUpdate(prevProps: Props, prevState: State) {
		const sowsEqual = isEqual(this.props.departuredSows, prevProps.departuredSows);
		const pregsEqual = isEqual(this.props.departuredPregnancies, prevProps.departuredPregnancies);
		const fromDateEqual = isEqual(this.props.fromDate, prevProps.fromDate);
		const toDateEqual = isEqual(this.props.toDate, prevProps.toDate);
		const dateFilterPropertyEqual = isEqual(this.props.dateFilterProperty, prevProps.dateFilterProperty);

		if (!sowsEqual || !pregsEqual || !fromDateEqual || !toDateEqual || !dateFilterPropertyEqual) {
			if (this.props.siteId && this.props.fromDate && this.props.toDate) {
				this.props.getDeparturedAnimalDataByDate(this.props.siteId, this.props.fromDate, this.props.toDate);
			}
			this.getFilteredData();
		}
	}

	public render() {
		return (
			<ViewWeb className="departured-sows">
				<OuterDiv>
					<SkioldTableGrid
						tableKey={'departuredSows1'}
						columns={this.state.columns}
						ref={this.setRef}
						data={this.state.departuredSows}
						ColumnExtensions={this.state.columnExte}
						sortHeaderId={this.defaultSorting}
						showPagination={true}
					/>
				</OuterDiv>
				<SkioldModal padding="0" isOpen={this.state.editModalOpen} close={this.closeModal}>
					<SowEvents
						stemAnimalId={this.state.stemAnimalIdToEdit}
						closeEditModal={this.closeModal}
						theme="dark"
						setEventOption={this.state.EventOption}
					/>
				</SkioldModal>
				<SkioldModal
					shouldCloseOnOverlayClick={true}
					padding="0"
					isOpen={this.state.sowCardModalOpen}
					close={this.closeModal}
					justify-content="flex-end"
					max-width="calc(100% - 220px)"
				>
					<SowCard stemAnimalIdFromParent={this.state.stemAnimalIdToEdit} close={this.closeModal} />
				</SkioldModal>
			</ViewWeb>
		);
	}
	private defaultSorting: Sorting[] = [{ columnName: 'animalNumber', direction: 'asc' }];
	private setRef = (m: any) => {
		if (m) {
			this.SkioldTableRef = m;
		}
	};
	private getFilteredData() {
		const { departuredSows } = this.props;

		if (!this.props.dateFilterProperty || !this.props.fromDate || !this.props.toDate) {
			this.setState({ departuredSows });
		}

		const sows = departuredSows.filter(sow => {
			const date = sow[this.props.dateFilterProperty as keyof IStemAnimal];
			return (
				date &&
				date > this.props.fromDate! &&
				date < this.props.toDate! &&
				sow.gender === Gender.Female &&
				sow.animalNumber
			);
		});

		this.setState({ departuredSows: sows });
	}

	private edit(stemAnimalId: string) {
		this.setState({ editModalOpen: true, stemAnimalIdToEdit: stemAnimalId });
	}

	private showSowCard(stemAnimalId: string) {
		this.setState({ sowCardModalOpen: true, stemAnimalIdToEdit: stemAnimalId });
	}
	private closeModal = () => {
		this.setState({ editModalOpen: false, sowCardModalOpen: false, stemAnimalIdToEdit: '' });
	};

	private generateColumns() {
		const columns = [] as any;
		columns.push({
			name: 'sowCard',
			title: ' ',
			sortable: false,
			filterable: false,
			isFixedLeft: true,
			getCellValue: (row: IStemAnimal) => (
				<SkioldTouchableOpacity
					onPress={() => {
						this.showSowCard(row.id!);
					}}
				>
					<SkioldImage
						width={SowListConstants.iconSVGWidth}
						height={SowListConstants.iconSVGWidth}
						imageData={sokort_icon}
					/>
				</SkioldTouchableOpacity>
			),
		});
		columns.push({
			name: 'edit',
			title: ' ',
			sortable: false,
			filterable: false,
			isFixedLeft: true,
			getCellValue: (row: IStemAnimal) => (
				<SkioldTouchableOpacity onPress={() => this.edit(row.id!)}>
					<SkioldImage width="30" height="30" imageData={PenIcon} />
				</SkioldTouchableOpacity>
			),
		});
		columns.push({
			name: 'animalNumber',
			title: localized('animalNr'),
			isFixedLeft: true,
			filterFunction: exactFilterMethodGrid,
			getCellValue: (d: IStemAnimal) => d.animalNumber,
		});
		columns.push({
			name: 'kind',
			title: localized('kind'),
			getCellValue: (d: IStemAnimal) =>
				localizedDynamic(calculateAnimalKind1(d, this.props.departuredPregnancies[d.id!] || [])),
		});
		columns.push({
			name: 'condition',
			title: localized('condition'),
			getCellValue: (d: IStemAnimal) =>
				localizedDynamic(getState1(d.id!, this.props.departuredPregnancies[d.id!] || [])),
		});
		columns.push({
			name: 'entranceDate',
			title: localized('entranceDate'),
			getCellValue: (d: IStemAnimal) => getDateString(d.entranceDate),
			sortFunction: NaturalSortDates,
		});
		columns.push({
			name: 'entranceType',
			title: localized('entranceType'),
			getCellValue: (d: IStemAnimal) => localizedDynamic(d.entranceType!),
		});
		columns.push({
			name: 'departureDate',
			title: localized('departureDate'),
			getCellValue: (d: IStemAnimal) => getDateString(d.departureDate),
			sortFunction: NaturalSortDates,
		});
		columns.push({
			name: 'departureType',
			title: localized('departure'),
			usesLocalizedDynamic: true,
			getCellValue: (d: IStemAnimal) => localizedDynamic(d.departureType!),
		});
		columns.push({
			name: 'departureReason',
			title: localized('departureReason'),
			usesLocalizedDynamic: true,
			getCellValue: (d: IStemAnimal) =>
				getReasonNameById(d.departureReasonId, this.props.language, this.props.reasons),
		});
		columns.push({
			name: 'batch',
			title: localized('Batch'),
			filterFunction: exactFilterMethod(),
			getCellValue: (d: IStemAnimal) => {
				return findBatch1(this.props.departuredPregnancies[d.id!] || [], this.props.matingBatch);
			},
		});
		columns.push({
			name: 'transponder',
			title: localized('transponder'),
			getCellValue: (d: IStemAnimal) => d.transponder,
		});
		columns.push({
			name: 'teats',
			title: localized('Teats'),
			getCellValue: (d: IStemAnimal) => (d.teats !== 0 ? d.teats : ''),
		});
		columns.push({
			name: 'idNumber',
			title: localized('idNumber'),
			getCellValue: (d: IStemAnimal) => printIdNumber(d.idNumber),
			filterFunction: idNumberFilter,
		});
		columns.push({
			name: 'birthdate',
			title: localized('birthdate'),
			getCellValue: (d: IStemAnimal) => getDateString(d.birthDate),
			sortFunction: NaturalSortDates,
		});
		columns.push({
			name: 'race',
			title: localized('Breed'),
			getCellValue: (d: IStemAnimal) => d.race,
		});
		if (this.props.nucleusManagement && this.props.generalSettings.showRaceLine) {
			columns.push({
				name: 'raceLine',
				title: localized('RaceLine'),
			});
		}
		columns.push({
			name: 'index',
			title: localized('index'),
			getCellValue: (d: IStemAnimal) => d.breedIndex,
		});
		columns.push({
			name: 'indexDate',
			title: localized('indexDate'),
			getCellValue: (d: IStemAnimal) => getDateString(d.breedIndexDate),
			sortFunction: NaturalSortDates,
		});

		if (this.props.nucleusManagement) {
			columns.push({
				name: 'breedingNumber',
				title: localized('BreedingNr'),
				getCellValue: (d: IStemAnimal) => d.breedingNumber,
			});
		}
		if (hasNucleusFeatureCEFN(this.props.nucleusManagement)) {
			columns.push({
				name: 'earNotch',
				title: localized('EarNotch'),
				getCellValue: (d: IStemAnimal) => d.earNotch,
			});
		}

		if (this.props.generalSettings.stemAnimalShowMomDadData) {
			columns.push(
				{
					name: 'dadAnimalNumber',
					title: localized('dadNr'),
					getCellValue: (d: IStemAnimal) => d.dadAnimalNumber,
				},
				{
					name: 'dadRace',
					title: localized('dadRace'),
					getCellValue: (d: IStemAnimal) => d.dadRace,
				},
				{
					name: 'dadIdNumber',
					title: localized('dadID'),
					getCellValue: (d: IStemAnimal) => printIdNumber(d.dadIdNumber),
				},

				{
					name: 'momAnimalNumber',
					title: localized('momNr'),
					getCellValue: (d: IStemAnimal) => d.momAnimalNumber,
				},
				{
					name: 'momRace',
					title: localized('momRace'),
					getCellValue: (d: IStemAnimal) => d.momRace,
				},
				{
					name: 'momIdNumber',
					title: localized('momID'),
					getCellValue: (d: IStemAnimal) => printIdNumber(d.momIdNumber),
				},
			);
		}

		return columns;
	}
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(DeparturedSows) as any;
