import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { Option } from 'react-dropdown';
import { WebAppState } from "web/state/store.web";
import { IDiagnose } from 'shared/api/api';
import { getDiagnoseName } from 'shared/helpers/diagnose-helper/diagnose-helper';
import { SkioldFormDropdown } from 'web/view/components/skiold-components/skiold-dropdown/skiold-form-dropdown';
import { GetSyncData as DiagnoseGetSyncData } from 'shared/state/ducks/diagnoses/operations';


interface PropsFromParent {
    value?: IDiagnose;
    onValueChanged?: (diagnose?: IDiagnose) => void;
    onValueChangedId?: (diagnoseId?: string) => void;
    items?: IDiagnose[]; //If not set, uses all diagnoses from WebAppState
    valueId?: string;
}

const mapStateToProps = (state: WebAppState) => {
    return {
        diagnoses: state.diagnose.entities,
        profile: state.profile.active
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getDiagnoses: () => DiagnoseGetSyncData()(dispatch)
});

interface State {
    selectedDiagnose: Option;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

class DiagnosePicker extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        if (!this.props.items) {
            this.props.getDiagnoses();
        }
        this.state = {
            selectedDiagnose: { value: ' ', label: ' ' }
        };
    }

    public componentDidMount() {
        if (this.props.valueId) {
            let diagnose = this.props.diagnoses.find((d: IDiagnose) => d.id === this.props.valueId);
            if (diagnose) {
                this.setState({
                    selectedDiagnose: {
                        value: diagnose.id!,
                        label: getDiagnoseName(diagnose, this.props.profile!.language)
                    }
                });
            }
        }
    }
    public render() {

        return (
            <SkioldFormDropdown
                items={this.createDiagnoses()}
                onValueChanged={newDiagnoseId => this.diagnoseChanged(newDiagnoseId!)}
                selectedValue={this.state.selectedDiagnose}
            />
        );
    }

    private createDiagnoses() {
        let values: Option[] = [{ value: ' ', label: ' ' }];

        let diagnoses = this.props.items ? this.props.items : this.props.diagnoses;

        if (diagnoses.length > 0) {
            let pickerItems = diagnoses.map<Option>(diagnose => {
                return {
                    value: diagnose.id!,
                    label: getDiagnoseName(diagnose, this.props.profile!.language)
                };
            });

            values = values.concat(pickerItems);
        }
        return values;
    }

    private diagnoseChanged(diagnoseOption: Option): void {
        let diagnose = this.props.diagnoses.find((d: IDiagnose) => d.id === diagnoseOption.value);

        if (this.props.onValueChanged) {
            this.props.onValueChanged(diagnose);
        }
        if (this.props.onValueChangedId && diagnose) {
            this.props.onValueChangedId(diagnose.id);
        }
        this.setState({ selectedDiagnose: diagnoseOption });
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)((DiagnosePicker));
