import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GrowthPigsEvent } from 'shared/api/api';
import {
	getAvgWeightParentAndTotalBalanceWeanedPigs,
	validateSellWeanedPigs,
	weanedPigSoldEvent,
} from 'shared/helpers/balance-weaned-pigs-helper/balance-weaned-pigs-helper';
import { getLocationStrinByPenId } from 'shared/helpers/location-helper';
import { localized } from 'shared/state/i18n/i18n';
import { SharedAppState } from 'shared/state/store.shared';
import { WebAppState } from 'web/state/store.web';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldDatePicker } from 'web/view/components/skiold-components/skiold-date-picker/skiold-date-picker';
import { SkioldFormTextField } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-form-text';
import { SkioldFormsWrapper } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { SkioldFormIntegerInput } from 'web/view/components/skiold-components/skiold-integer-input/skiold-form-integer-input';
import { SkioldModal } from 'web/view/components/skiold-components/skiold-modal/skiold-modal';
import { Heading } from 'web/view/components/utils/heading';
import { ViewWeb } from 'web/view/components/utils/web-view';

interface PropsFromParent {
	isOpen: boolean;
	growthPigEvent?: GrowthPigsEvent;
	closeModal: () => void;
}

export const RegisterBalanceWeanedPigDepartModal: FC<PropsFromParent> = React.memo(
	({ isOpen, closeModal, growthPigEvent }) => {
		const [date, setDate] = useState<Date | undefined>(growthPigEvent && growthPigEvent.date? growthPigEvent.date: new Date());
		const penId = useSelector((state: WebAppState) => state.generalSettings.entity.weanedPenId);
		const [amount, setAmount] = useState<number | undefined>(growthPigEvent && growthPigEvent.amount);
		const siteId = useSelector((state: WebAppState) => state.profile.active && state.profile.active.siteId);
		const dispatch = useDispatch();

		const totalWeight = useSelector(
			(state: SharedAppState) => state.balanceWeanedPigsData.totalAmountAndTotalWeight,
		);
		const data = useSelector((state: SharedAppState) => state.balanceWeanedPigsData.entities);

		const { avgWeightParent } = getAvgWeightParentAndTotalBalanceWeanedPigs(totalWeight, data);
		const onChangeNumber = (newNumber: number | undefined, itemFromParent?: any) => {
			setAmount(newNumber);
		};

		const onDateChange = (newDate: Date, itemFromParent?: any) => {
			setDate(newDate);
		};

		const amountRow = {
			name: localized('Count'),
			component: <SkioldFormIntegerInput onChangeNumber={onChangeNumber} text={amount} />,
		};

		const dateRow = {
			name: localized('Date'),
			component: (
				<SkioldDatePicker onDateChanged={onDateChange} selectedDate={date} theme={'dark'} color={'grey'} />
			),
		};

		const locationRow = {
			name: localized('location'),
			component: <SkioldFormTextField>{penId ? getLocationStrinByPenId(penId) : ''}</SkioldFormTextField>,
		};

		const saveSellWeanedPigs = () => {
			if (!validateSellWeanedPigs(date, amount, siteId)) {
				return;
			}

			weanedPigSoldEvent(amount, date, siteId, growthPigEvent, avgWeightParent, dispatch, resetComponent);
			closeModal();
		};

		const resetComponent = () => {
			setAmount(undefined);
			setDate(new Date());
		};

		const formRows = [dateRow, locationRow, amountRow];
		return (
			<SkioldModal padding="0" isOpen={isOpen} close={closeModal}>
				<Heading text={localized('SellWeanedPigs')} />
				<ViewWeb className="sell-weaned-pigs-container">
					<SkioldFormsWrapper formRows={formRows} containerClassName="forms-wrapper-style" />
					<ViewWeb className="button-container">
						<SkioldButton className="save-button" title={localized('Save')} onPress={saveSellWeanedPigs} />
						<SkioldButton
							className="save-button"
							theme="grey"
							title={localized('Close')}
							onPress={closeModal}
						/>
					</ViewWeb>
				</ViewWeb>
			</SkioldModal>
		);
	},
);
