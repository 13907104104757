import { Sorting } from '@devexpress/dx-react-grid';
import memoize from 'memoize-one';
import React from 'react';
import { connect } from 'react-redux';
import { IMoveEvent, IStemAnimal, SkioldOneClient } from 'shared/api/api';
import { getDateString } from 'shared/helpers/date-helpers';
import { exactFilterMethodGrid, exactNumberFilterMethodGrid } from 'shared/helpers/general-helpers';
import { findRetentionTime } from 'shared/helpers/treatment-helper/treatment-helper';
import { LocationsState } from 'shared/state/ducks/locations';
import { localized } from 'shared/state/i18n/i18n';
import 'web/view/components/event-lists/pregnancy-event-lists/sow-pregnancy-event-list-styles.scss';
import 'web/view/components/skiold-components/skiold-table/skiold-double-header-table.scss';
import SkioldTableGrid, {
	SkioldTableGrid as SkioldTableRef,
} from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { SowListConstants } from 'web/view/components/stem-animal/animal-lists/table-constants';
import { getActiveSows } from 'web/view/components/stem-animal/stem-animal-input/stem-animal-input-helper';
import {
	MoveEventListItem,
	MoveEventListMapDispatchToProps,
	MoveEventListMapStateToProps,
	MoveEventListProps,
	MoveEventListState,
	MoveSummaryItem,
} from './move-event-list-item';
import { SkioldDigitalApiBaseUrl } from 'shared/constants';
import { NaturalSortDates } from 'shared/helpers/natural-sort';

export class MoveEventListTable extends React.PureComponent<MoveEventListProps, MoveEventListState> {
	public SkioldTableRef: SkioldTableRef | undefined;

	private generateData = memoize((dateFrom, dateTo, departuredSows, moveEvents) =>
		this.genereateListData(dateFrom, dateTo, departuredSows, moveEvents),
	);

	private defaultSorting = [{ columnName: 'animalNumber', direction: 'asc' }] as Sorting[];
	private readonly getCreatedDateCell = (d: MoveEventListItem) => getDateString(d.createdDate);

	private readonly getDateCell = (d: MoveEventListItem) => getDateString(d.date);

	private readonly getAnimalNumberCell = (d: MoveEventListItem) => d.animalNumber;

	private readonly getFromBuildingNameCell = (d: MoveEventListItem) => d.fromBuildingName;

	private readonly getFromSectionNameCell = (d: MoveEventListItem) => d.fromSectionName;

	private readonly getFromPenNameCell = (d: MoveEventListItem) => d.fromPenName;

	private readonly getToBuildingNameCell = (d: MoveEventListItem) => d.toBuildingName;

	private readonly getToSectionNameCell = (d: MoveEventListItem) => d.toSectionName;

	private readonly getToPenNameCell = (d: MoveEventListItem) => d.toPenName;

	private readonly getInitCell = (d: MoveEventListItem) => {
		if (d) {
			const user = this.props.userProfiles.find(u => u.id === d.createdBy);
			return user?.initials ?? '';
		}
		return ' ';
	};

	constructor(props: MoveEventListProps) {
		super(props);
		this.state = {
			loading: false,
			commitAll: false,
			moveEvents: props.moveEvents,
			listItems: [],
		};
	}

	public genereateListData(dateFrom, dateTo, departuredSows, moveEvents) {
		let activeSows = getActiveSows();
		let listItems: MoveEventListItem[] = [];
		this.insertDataIntoListItems(activeSows, listItems, moveEvents, this.props.locations, dateFrom, dateTo);
		if (departuredSows && departuredSows.length > 0) {
			this.insertDataIntoListItems(departuredSows, listItems, moveEvents, this.props.locations, dateFrom, dateTo);
		}
		this.generateSummary(listItems);
		this.setState({ listItems });
	}

	public generateColumnsExtensions = (data: MoveEventListItem[]) => {
		let columns = [
			{
				columnName: 'CreatedDate',
				width: SowListConstants.reasonWidth,
			},
			{
				columnName: 'Date',
				width: SowListConstants.entranceDateWidth,
			},
			{
				columnName: 'animalNumber',
				width: SowListConstants.animalNrWidth,
			},
		];
		if (data.find(a => a.usesPensFrom && a.usesSectionsFrom)) {
			columns = columns.concat([
				{
					columnName: 'FromBuildingName',
					width: SowListConstants.animalNrWidth,
				},
				{
					columnName: 'FromSectionName',
					width: SowListConstants.animalNrWidth,
				},
				{
					columnName: 'FromPenName',
					width: SowListConstants.animalNrWidth,
				},
			]);
		} else if (data.find(a => !a.usesPensFrom && a.usesSectionsFrom)) {
			columns = columns.concat([
				{
					columnName: 'FromBuildingName',
					width: SowListConstants.animalNrWidth,
				},
				{
					columnName: 'FromSectionName',
					width: SowListConstants.animalNrWidth,
				},
			]);
		} else {
			columns.push({
				columnName: 'FromBuildingName',
				width: SowListConstants.animalNrWidth,
			});
		}
		if (data.find(a => a.usesPensTo && a.usesSectionsTo)) {
			columns = columns.concat([
				{
					columnName: 'ToBuildingName',
					width: SowListConstants.animalNrWidth,
				},
				{
					columnName: 'ToSectionName',
					width: SowListConstants.animalNrWidth,
				},
				{
					columnName: 'ToPenName',
					width: SowListConstants.animalNrWidth,
				},
			]);
		} else if (data.find(a => !a.usesPensTo && a.usesSectionsTo)) {
			columns = columns.concat([
				{
					columnName: 'ToBuildingName',
					width: SowListConstants.animalNrWidth,
				},
				{
					columnName: 'ToSectionName',
					width: SowListConstants.animalNrWidth,
				},
			]);
		} else {
			columns.push({
				columnName: 'ToBuildingName',
				width: SowListConstants.animalNrWidth,
			});
		}
		if (this.props.generalSettings.stemAnimalShowInitials) {
			columns.push({
				columnName: 'initial',
				width: SowListConstants.animalNrWidth,
			});
		}

		return columns;
	};

	private getMoveEventsByDate = memoize((dateFrom, dateTo) =>
		new SkioldOneClient(SkioldDigitalApiBaseUrl)
			.moveEvent_GetMoveEventsByDate(this.props.siteId, dateFrom, dateTo)
			.then((moveEvents: IMoveEvent[]) => {
				this.generateData(this.props.dateFrom, this.props.dateTo, this.props.departuredSows, moveEvents);
			}),
	);

	public componentDidUpdate() {
		this.getMoveEventsByDate(this.props.dateFrom, this.props.dateTo);
	}

	public componentDidMount() {
		new SkioldOneClient(SkioldDigitalApiBaseUrl)
			.moveEvent_GetMoveEventsByDate(this.props.siteId, this.props.dateFrom, this.props.dateTo)
			.then((moveEvents: IMoveEvent[]) => {
				this.generateData(this.props.dateFrom, this.props.dateTo, this.props.departuredSows, moveEvents);
			});

		if (this.SkioldTableRef) {
			this.props.setSowsCount(this.SkioldTableRef.GetSortedData().length);
		}
	}

	public render() {
		let columnExtensions = this.generateColumnsExtensions(this.state.listItems);
		return (
			<div className="alignTableCenterDefault">
				<SkioldTableGrid
					tableKey={'farrowingEventListTable' + columnExtensions.length}
					columns={this.generateColumns(this.state.listItems)}
					ColumnExtensions={columnExtensions}
					data={this.state.listItems}
					groupedColumns={this.groupedColumns}
					ref={this.setTableRef}
					onFiltersChanged={this.onFiltersChanged}
					sortHeaderId={this.defaultSorting}
					handleDynamicBug={true}
				/>
			</div>
		);
	}
	private groupedColumns = [
		{
			title: localized('FromLocation'),
			children: [
				{ columnName: 'FromBuildingName' },
				{ columnName: 'FromSectionName' },
				{ columnName: 'FromPenName' },
			],
		},
		{
			title: localized('ToLocation'),
			children: [{ columnName: 'ToBuildingName' }, { columnName: 'ToSectionName' }, { columnName: 'ToPenName' }],
		},
	];
	private insertDataIntoListItems(
		stemAnimals: IStemAnimal[],
		listItems: MoveEventListItem[],
		moveEvents: IMoveEvent[],
		locations: LocationsState,
		dateFrom: Date,
		dateTo: Date,
	) {
		let groupedMoveEvents = moveEvents
			.sort((a, b) => {
				return (a.moveDate ? a.moveDate.getTime() : 0) - (b.moveDate ? b.moveDate.getTime() : 0);
			})
			.dictionaryBy('stemAnimalId');
		stemAnimals.forEach(sow => {
			let sowMoveEvents = groupedMoveEvents[sow.id!];
			if (sowMoveEvents && sowMoveEvents.length > 0) {
				sowMoveEvents.forEach((moveEvent: IMoveEvent, index: number) => {
					if (
						dateFrom === undefined ||
						dateTo === undefined ||
						(moveEvent.moveDate! >= dateFrom && moveEvent.moveDate! <= dateTo)
					) {
						const toPen = locations.pens.find(a => a.id === moveEvent.penId);
						const toSection = locations.sections.find(a => toPen && a.id === toPen.sectionId);
						const toBuilding = locations.buildings.find(a => toPen && a.id === toPen.buildingId);
						let previousSowPen = sow.entrancePenId;
						if (index > 0) {
							previousSowPen = sowMoveEvents[index - 1].penId;
						}
						const fromPen = locations.pens.find(a => a.id === previousSowPen);
						const fromSection = locations.sections.find(a => fromPen && a.id === fromPen.sectionId);
						const fromBuilding = locations.buildings.find(a => fromPen && a.id === fromPen.buildingId);

						if (toPen && toSection && toBuilding && fromPen && fromSection && fromBuilding) {
							const itemAlreadyExistsIndex = listItems.findIndex(
								a =>
									a.stemAnimalId === moveEvent.stemAnimalId &&
									getDateString(a.date) === getDateString(moveEvent.moveDate),
							);
							let item = new MoveEventListItem();
							item.stemAnimalId = sow.id;
							item.createdDate = moveEvent.createdOn;
							item.date = moveEvent.moveDate;
							item.animalNumber = sow.animalNumber;
							item.toBuildingName = toBuilding.name;
							item.toSectionName = toBuilding.useSections ? toSection.name : ' ';
							item.toPenName = toBuilding.useSections && toSection.usePens ? toPen.name : ' ';
							item.fromBuildingName = fromBuilding.name;
							item.fromSectionName = fromBuilding.useSections ? fromSection.name : ' ';
							item.fromPenName = fromBuilding.useSections && fromSection.usePens ? fromPen.name : ' ';
							item.usesPensFrom = fromBuilding.useSections && fromSection.usePens;
							item.usesSectionsFrom = fromBuilding.useSections;
							item.usesPensTo = toBuilding.useSections && toSection.usePens;
							item.usesSectionsTo = toBuilding.useSections;
							item.createdBy = moveEvent.createdBy;
							if (itemAlreadyExistsIndex >= 0) {
								listItems[itemAlreadyExistsIndex] = item;
							} else {
								listItems.push(item);
							}
						}
					}
				});
			}
		});
	}
	private generateSummary(pregnancyEvents: MoveEventListItem[]) {
		let summaryItem: MoveSummaryItem = {};
		summaryItem.sowCount = pregnancyEvents.length.toString();

		this.props.onSummaryChanged(
			<div className="flexDirectionRowWithWidth">
				<div className="summaryTextStyle"> {localized('moveEvents')}: </div>
				<div className="summaryTextStyle"> {summaryItem.sowCount} </div>
			</div>,
		);
	}

	private onFiltersChanged = (events: MoveEventListItem[]) => {
		this.generateSummary(events);
	};

	private setTableRef = (m: any) => (m ? (this.SkioldTableRef = m) : {});

	private hasRetentionTime = (tableItem: MoveEventListItem) => {
		return tableItem && tableItem.stemAnimalId && findRetentionTime(tableItem.stemAnimalId) !== undefined
			? 'retention-time-list-color'
			: '';
	};

	private generateColumns = (data: MoveEventListItem[]) => {
		let columns: any[] = [
			{
				name: 'CreatedDate',
				title: localized('registeredOn'),
				headerClassName: 'merged-header',
				sortFunction: NaturalSortDates,
				getCellValue: this.getCreatedDateCell,
			},
			{
				name: 'Date',
				title: localized('Date'),
				headerClassName: 'merged-header',
				sortFunction: NaturalSortDates,
				getCellValue: this.getDateCell,
			},
			{
				name: 'animalNumber',
				headerClassName: 'merged-header',
				title: localized('animalNumber'),
				className: this.hasRetentionTime,
				filterFunction: exactFilterMethodGrid,
				getCellValue: this.getAnimalNumberCell,
			},
		];

		if (data.find(a => a.usesPensFrom && a.usesSectionsFrom)) {
			columns = columns.concat([
				{
					name: 'FromBuildingName',
					title: localized('building'),
					getCellValue: this.getFromBuildingNameCell,
				},
				{
					name: 'FromSectionName',
					title: localized('section'),
					getCellValue: this.getFromSectionNameCell,
					filterFunction: exactNumberFilterMethodGrid,
				},
				{
					name: 'FromPenName',
					title: localized('pen'),
					getCellValue: this.getFromPenNameCell,
				},
			]);
		} else if (data.find(a => !a.usesPensFrom && a.usesSectionsFrom)) {
			columns = columns.concat([
				{
					name: 'FromBuildingName',
					title: localized('building'),
					getCellValue: this.getFromBuildingNameCell,
				},
				{
					name: 'FromSectionName',
					title: localized('section'),
					getCellValue: this.getFromSectionNameCell,
					filterFunction: exactNumberFilterMethodGrid,
				},
			]);
		} else {
			columns.push({
				name: 'FromBuildingName',
				title: localized('building'),
				getCellValue: this.getFromBuildingNameCell,
			});
		}
		if (data.find(a => a.usesPensTo && a.usesSectionsTo)) {
			columns = columns.concat([
				{
					name: 'ToBuildingName',
					title: localized('building'),
					getCellValue: this.getToBuildingNameCell,
				},
				{
					name: 'ToSectionName',
					title: localized('section'),
					getCellValue: this.getToSectionNameCell,
					filterFunction: exactNumberFilterMethodGrid,
				},
				{
					name: 'ToPenName',
					title: localized('pen'),
					getCellValue: this.getToPenNameCell,
				},
			]);
		} else if (data.find(a => !a.usesPensTo && a.usesSectionsTo)) {
			columns = columns.concat([
				{
					name: 'ToBuildingName',
					title: localized('building'),
					getCellValue: this.getToBuildingNameCell,
				},
				{
					name: 'ToSectionName',
					title: localized('section'),
					getCellValue: this.getToSectionNameCell,
					filterFunction: exactNumberFilterMethodGrid,
				},
			]);
		} else {
			columns.push({
				name: 'ToBuildingName',
				title: localized('building'),
				getCellValue: this.getToBuildingNameCell,
			});
		}

		if (this.props.generalSettings.stemAnimalShowInitials) {
			columns.push({
				name: 'initial',
				title: localized('InitialsShort'),
				getCellValue: this.getInitCell,
			});
		}

		return columns;
	};
}

export default connect(MoveEventListMapStateToProps, MoveEventListMapDispatchToProps, null, { forwardRef: true })(
	MoveEventListTable,
);
