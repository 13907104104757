import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { saveWorkListToTreatmenPlan, getSyncData } from './actions';
import { WorkListToTreatmentPlanState } from './types';
import { SyncableInitialState } from 'shared/state/models/syncable';
import { mergeArrays, upsertValueInArray, removeValueFromArray } from 'shared/helpers/reducer-helpers';
import { siteChange } from '../profile/actions';
import { WorkListToTreatmentPlan, IWorkListToTreatmentPlan } from 'shared/api/api';
import { SharedAppState } from 'shared/state/store.shared';
import { removeRedundantDataByArray } from 'shared/helpers/general-helpers';
import { getSyncModelData } from '../sync/actions';

export const initialState: WorkListToTreatmentPlanState = {
	...SyncableInitialState,
};

const workListToTreatmentPlanReducer = (
	state: WorkListToTreatmentPlanState = initialState,
	action: Action,
): WorkListToTreatmentPlanState => {
	if (isType(action, getSyncData.started)) {
		state = { ...state, syncInProgress: true };
	}

	if (isType(action, getSyncModelData.done)) {
		if (action.payload.params.siteId === action.payload.params.activeSiteId) {
			if (
				action.payload.result.workListToTreatmentPlans &&
				action.payload.result.workListToTreatmentPlans.datas!.length
			) {
				state = {
					...state,
					entities: removeRedundantDataByArray(
						mergeArrays(state.entities, action.payload.result.workListToTreatmentPlans.datas!),
					) as IWorkListToTreatmentPlan[],
					lastSyncDate: action.payload.result.workListToTreatmentPlans.syncTime!,
				};
			}
			state = {
				...state,

				syncInProgress: false,
			};
		} else {
			state = {
				...state,
				syncInProgress: false,
			};
		}
	}

	if (isType(action, getSyncData.done)) {
		if (action.payload.params.siteId === action.payload.params.activeSiteId) {
			if (action.payload.result.datas!.length) {
				state = {
					...state,
					entities: removeRedundantDataByArray(
						mergeArrays(state.entities, action.payload.result.datas!),
					) as IWorkListToTreatmentPlan[],
				};
			}
			state = {
				...state,
				lastSyncDate: action.payload.result.syncTime!,
				syncInProgress: false,
			};
		} else {
			state = {
				...state,
				syncInProgress: false,
			};
		}
	}

	if (isType(action, saveWorkListToTreatmenPlan.started)) {
		state = {
			...state,
			updates: upsertValueInArray(state.updates, action.payload),
			saveSyncInProgress: true,
			entities: upsertValueInArray(state.entities, action.payload),
		};
	}

	if (isType(action, saveWorkListToTreatmenPlan.failed)) {
		if (action.payload.error.code === 500) {
			if (action.payload.error.prevEntity) {
				state = { ...state, entities: upsertValueInArray(state.entities, action.payload.error.prevEntity) };
			} else {
				state = { ...state, entities: removeValueFromArray(state.entities, action.payload.params.id!) };
			}
			state = { ...state, updates: removeValueFromArray(state.updates, action.payload.params.id!) };
		}

		state = { ...state, saveSyncInProgress: false };
		return state;
	}

	if (isType(action, saveWorkListToTreatmenPlan.done)) {
		state = {
			...state,
			entities: upsertValueInArray(state.entities, action.payload.params),
			updates: removeValueFromArray(state.updates, action.payload.result),
			saveSyncInProgress: false,
		};
		return state;
	}

	if (isType(action, siteChange.done)) {
		state = initialState;
	}

	//Ensure Date objects are in fact date and not strings - redux-persist serializes dates to string but don't deserialize to dates again
	if (action.type === 'persist/REHYDRATE') {
		let a = (action as any) as { payload: SharedAppState; key: string };

		if (a.key === 'root') {
			let entities = new Array<WorkListToTreatmentPlan>();
			let updates = new Array<WorkListToTreatmentPlan>();
			let lastSyncDate = new Date(-8640000000000000);

			if (a.payload && a.payload.workListToTreatmentPlans && a.payload.workListToTreatmentPlans.entities) {
				entities = [];
				a.payload.workListToTreatmentPlans.entities.forEach((dt: IWorkListToTreatmentPlan) => {
					let ndt = WorkListToTreatmentPlan.fromJS(dt);
					if (ndt) {
						ndt.init(dt);
						entities.push(ndt);
					}
				});
			}

			if (a.payload && a.payload.workListToTreatmentPlans && a.payload.workListToTreatmentPlans.updates) {
				updates = [];
				a.payload.workListToTreatmentPlans.updates.forEach((dt: IWorkListToTreatmentPlan) => {
					let ndt = WorkListToTreatmentPlan.fromJS(dt);
					if (ndt) {
						ndt.init(dt);
						updates.push(ndt);
					}
				});
			}
			if (a.payload && a.payload.workListToTreatmentPlans && a.payload.workListToTreatmentPlans.lastSyncDate) {
				lastSyncDate = new Date(a.payload.workListToTreatmentPlans.lastSyncDate);
			}

			state = { ...state, entities, updates, lastSyncDate };
		}
	}

	return state;
};

export default workListToTreatmentPlanReducer;
