
import actionCreatorFactory from 'typescript-fsa';
import * as types from './types';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import { ISupplierDetailDto, SupplierDetailDto } from 'shared/api/api';

const actionCreator = actionCreatorFactory(GenActionFactoryName('SlaughterHouseSupplierDetails'));

export const getSupplierDetails = actionCreator.async<string | undefined, SupplierDetailDto[], { code: number; message: string }>(
    types.SYNC_DATA_SUPPLIER_DETAILSS
);

export const postSupplierDetails = actionCreator.async<SupplierDetailDto, SupplierDetailDto>(
    types.SAVE_SUPPLIER_DETAILS
);

export const putSupplierDetails = actionCreator.async<SupplierDetailDto, SupplierDetailDto, { code: number; message: string }>(
    types.PUT_SUPPLIER_DETAILS
);

export const deleteSupplierDetails = actionCreator.async<SupplierDetailDto, SupplierDetailDto, { code: number; message: string }>(
    types.DELETE_SUPPLIER_DETAILS
);


export const AddSupplier = actionCreator<ISupplierDetailDto>(types.ADD_SUPPLIER_DETAILS);