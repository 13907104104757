import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Gender, IStemAnimal } from 'shared/api/api';
import { dateFormatDash, formatDate } from 'shared/helpers/date-helpers';
import { isNullOrUndefined } from 'shared/helpers/general-helpers';
import { selectActiveSite } from 'shared/state/ducks/site/reducer';
import { UpsertManyYoungAnimal } from 'shared/state/ducks/stem-animals/operations';
import { localized, localizedDynamic } from 'shared/state/i18n/i18n';
import { DepartureTypes } from 'shared/state/models/departure-types';
import { WebAppState } from 'web/state/store.web';
import PageContainer from 'web/view/components/page-container/page-container';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldDatePicker } from 'web/view/components/skiold-components/skiold-date-picker/skiold-date-picker';
import { WhiteText } from 'web/view/components/Text/white-text';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { GetAnimalListPdf, GetDadMomTablePdf } from 'web/web-helpers/pdf-helper/animal-list-helper';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { ActiveSow } from './active-sows-model';

interface PropsFromParent {
	closeModal: () => void;
	stemAnimals: ActiveSow[];
}

export const ActiveSowPrint: FC<PropsFromParent> = React.memo(({ closeModal, stemAnimals }) => {
	const [isDownloading, setIsDownloading] = useState<boolean>();
	const { siteId, language } = useSelector((state: WebAppState) => state.profile.active!);
	const downloadGeneral = async () => {
		setIsDownloading(true);
		try {
			GetAnimalListPdf(
				stemAnimals,
				localized('activeSowPdf'),
				siteId!,
				Intl.DateTimeFormat().resolvedOptions().timeZone,
				language,
			);
			closeModal();
		} finally {
			setIsDownloading(false);
		}
	};

	const downloadMomDad = async () => {
		setIsDownloading(true);
		try {
			GetDadMomTablePdf(
				stemAnimals,
				localized('DadMomPdf'),
				siteId!,
				Intl.DateTimeFormat().resolvedOptions().timeZone,
				language,
			);
			closeModal();
		} finally {
			setIsDownloading(false);
		}
	};

	return (
		<PageContainer>
			<ViewWeb className="align-center">
				<ViewWeb className="flex-row-space-between default-spacing default-gap">
					<WhiteText>{localized('PrintGeneralData')}:</WhiteText>
					<SkioldButton onPress={downloadGeneral} title={localized('Print')} />
				</ViewWeb>
				<ViewWeb className="flex-row-space-between default-spacing default-gap">
					<WhiteText>{localized('PrintMomDadData')}:</WhiteText>
					<SkioldButton onPress={downloadMomDad} title={localized('Print')} />
				</ViewWeb>
				<ViewWeb className="flex-row default-spacing">
					<SkioldButton theme="grey" onPress={closeModal} title={localized('Close')} />
				</ViewWeb>
			</ViewWeb>
		</PageContainer>
	);
});
