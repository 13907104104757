import { SyncableHashmap } from "shared/state/models/syncable";
import { IPregnancyEvent } from "shared/api/api";


export const SAVE_PREGNANCY_EVENT = 'SAVE_PREGNANCY_EVENT';
export const SAVE_LIST_OF_PREGNANCY_EVENTS = 'SAVE_LIST_OF_PREGNANCY_EVENTS';
export const SAVE_PREGNANCY_EVENTS = 'SAVE_PREGNANCY_EVENTS';
export const SAVE_PREGNANCY_EVENT_NO_UPDATE = 'SAVE_PREGNANCY_EVENT_NO_UPDATE';
export const DELETE_LAST_PREGNANCY_EVENT = 'DELETE_LAST_PREGNANCY_EVENT';
export const DELETE_LAST_PREGNANCY_EVENTS = 'DELETE_LAST_PREGNANCY_EVENTS';
export const SYNC_DATA_PREGNANCYEVENTS = 'SYNC_DATA_PREGNANCYEVENTS';
export const RESET_PREGNANCY_STATE = 'RESET_PREGNANCY_STATE';
export const REMOVE_PREGNANCIES_BY_ANIMAL = 'REMOVE_PREGNANCIES_BY_ANIMAL';
export const GET_DEPARTURED_PREGNANCIES = 'GET_DEPARTURED_PREGNANCIES';
export const SAVE_DEPARTURED_PREGNANCY_EVENT = 'SAVE_DEPARTURED_PREGNANCY_EVENT';

export interface PregnancyState extends SyncableHashmap<IPregnancyEvent> {
    departuredPregnancies: { [key: string]: IPregnancyEvent[] };
}
