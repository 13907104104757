import { createAsyncThunk } from '@reduxjs/toolkit';
import { AsyncOperationHandler } from '../../../helpers/redux-helpers';
import {
	INucleusManagementConnection,
	INucleusManagementConnectionCreationDTO,
	INucleusManagementConnectionUpdateDTO,
	NucleusManagementConnection,
	NucleusManagementConnectionCreationDTO,
	NucleusManagementConnectionUpdateDTO,
} from '../../../api/api';
import { localized } from 'shared/state/i18n/i18n';

export const getConnections = createAsyncThunk('nucleusManagementConnections/getConnections', (siteId: string) => {
	return AsyncOperationHandler(client => client.nucleusManagementConnection_Get(siteId));
});

export const createNucleusManagementConnection = createAsyncThunk(
	'nucleusManagementConnections/createNucleusManagementConnection',
	(creationDTO: INucleusManagementConnectionCreationDTO) => {
		return AsyncOperationHandler(
			client =>
				client.nucleusManagementConnection_CreateConnection(
					creationDTO as NucleusManagementConnectionCreationDTO,
				),
			(errorCode, message) => {
				alert(localized(message));
			},
		);
	},
);

export const updateNucleusManagementConnection = createAsyncThunk(
	'nucleusManagementConnections/updateNucleusManagementConnection',
	(updateDTO: INucleusManagementConnectionUpdateDTO) => {
		return AsyncOperationHandler(
			client =>
				client.nucleusManagementConnection_UpdateConnection(updateDTO as NucleusManagementConnectionUpdateDTO),
			(errorCode, message) => {
				alert(localized(message));
			},
		);
	},
);

export const updateNucleusManagementFetchSettings = createAsyncThunk(
	'nucleusManagementConnections/updateFetchSettings',
	(connection: INucleusManagementConnection) => {
		return AsyncOperationHandler(client =>
			client.nucleusManagementConnection_UpdateFetchSettings(connection as NucleusManagementConnection),
		);
	},
);

export const updateNucleusManagementSendSettings = createAsyncThunk(
	'nucleusManagementConnections/updateSendSettings',
	(connection: INucleusManagementConnection) => {
		return AsyncOperationHandler(client =>
			client.nucleusManagementConnection_UpdateSendSettings(connection as NucleusManagementConnection),
		);
	},
);

export const deleteNucleusManagementConnection = createAsyncThunk(
	'nucleusManagementConnections/deleteNucleusManagementConnection',
	(connectionId: string) => {
		return AsyncOperationHandler(client => client.nucleusManagementConnection_DeleteConnection(connectionId));
	},
);

export const sendNucleusEventsManually = createAsyncThunk(
	'nucleusManagementConnections/sendEventsManually',
	(connection: INucleusManagementConnection) => {
		return AsyncOperationHandler(client =>
			client.nucleusManagementCommunication_SendNucleusEventsManually(connection as NucleusManagementConnection),
		);
	},
);
