import { IMarking, Marking } from 'shared/api/api';
import { mergeArrays } from 'shared/helpers/reducer-helpers';
import { SyncableInitialState } from 'shared/state/models/syncable';
import { SharedAppState } from 'shared/state/store.shared';
import { isType } from 'typescript-fsa';
import { getSyncModelData } from '../sync/actions';
import * as actions from './actions';
import { MarkingsState } from './types';

export const initialMarknigState: MarkingsState = {
	...SyncableInitialState,
};

const markingsReducer = (state: MarkingsState = initialMarknigState, action: any): MarkingsState => {
	if (isType(action, actions.getSyncData.started)) {
		state = { ...state, syncInProgress: true };
	}

    if (isType(action, actions.getSyncData.done)) {
		if (action.payload.result && action.payload.result.datas!.length) {
			state = {
				...state,
				entities: mergeArrays(state.entities, action.payload.result.datas!),
				lastSyncDate: action.payload.result.syncTime!,
			};
		}

		state = {
			...state,

			syncInProgress: false,
		};
	}

	if (isType(action, getSyncModelData.done)) {
		if (action.payload.result.markings && action.payload.result.markings.datas!.length) {
			state = {
				...state,
				entities: mergeArrays(state.entities, action.payload.result.markings.datas!),
				lastSyncDate: action.payload.result.markings.syncTime!,
			};
		}

		state = {
			...state,

			syncInProgress: false,
		};
	}

	if (isType(action, actions.getSyncData.failed)) {
		state = { ...state, syncInProgress: false };
	}

	//Ensure Date objects are in fact date and not strings - redux-persist serializes dates to string but doesn't deserialize to dates again
	if (action.type === 'persist/REHYDRATE') {
		let a = ((action as any) as { payload: SharedAppState; key: string }) as any;

		if (a.key === 'root') {
			let entities = new Array<IMarking>();
			let lastSyncDate = new Date(-8640000000000000);

			if (a.payload && a.payload.markings && a.payload.markings.entities) {
				entities = a.payload.markings.entities.map((dt: IMarking) => {
					let ndt = Marking.fromJS({});
					ndt.init(dt);
					return ndt;
				});
			}

			if (a.payload && a.payload.markings && a.payload.markings.lastSyncDate) {
				lastSyncDate = new Date(a.payload.markings.lastSyncDate);
			}

			state = { ...state, entities, lastSyncDate };
		}
	}

	return state;
};

export default markingsReducer;
