import React, { FC, useState, useEffect } from 'react';
import { Keyboard, KeyboardEvent } from 'react-native';
import { SetKeyboardHeight } from 'native/state/ducks/app-state/operations';
import { useDispatch, useSelector } from 'react-redux';
import { FormRow } from '../../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { localized } from 'shared/state/i18n/i18n';
import { SkioldDatePicker } from '../../skiold-components/skiold-date-picker/skiold-date-picker';
import { WebAppState } from 'web/state/store.web';
import { memoizeHashmapLocation } from 'shared/helpers/memoize-getters/memoize-getters';
import { Option } from 'react-dropdown';
import { AnimalType, GrowthPigEventType, GrowthPigsEvent, ISection } from 'shared/api/api';
import {
	getLocationPlaceholder,
	getPenOptionsBySectionId,
	getPenPlaceholder,
	getStableSectionOptionAnimalType,
} from 'shared/helpers/location-helper';
import { SkioldFormDropdown } from '../../skiold-components/skiold-dropdown/skiold-form-dropdown';
import { SkioldFormTextField } from '../../skiold-components/skiold-forms-wrapper/skiold-form-text';
import { SkioldFormDecimalInput } from '../../skiold-components/skiold-decimal-input/skiold-form-decimal-input';
import { ViewWeb } from '../../utils/web-view';
import { SkioldFormsWrapper } from '../../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { setOneDecimalsAsNumber } from 'shared/helpers/general-helpers';
import {
	getCorrectProductionTypeBySectionId,
	getTotalPigsOnPens,
} from 'shared/helpers/growth-pigs-helper/growth-pig-event-function-helper';
import ObjectID from 'bson-objectid';
import { upsertGrowthPigsEvent } from 'shared/state/ducks/growth-pig-events/operations';
import { SkioldButton } from '../../skiold-components/skiold-button/skiold-button';
import { selectSiteId } from 'shared/state/ducks/site/selectors';
import { showAlert } from '../../skiold-alert/skiold-alert';
import { ExceptionMessage } from 'shared/helpers/exception-message';

interface PropsFromParent {
	productionType: AnimalType;
}
export const GrowthPigWeightRegulation: FC<PropsFromParent> = React.memo(({ productionType }) => {
	const hashmapLocations = useSelector((state: WebAppState) =>
		memoizeHashmapLocation(state.locations.buildings, state.locations.sections, state.locations.pens),
	);
	const { sendDataToDistriwinFinisher, sendDataToDistriwinWeaner } = useSelector(
		(state: WebAppState) => state.generalSettings.entity,
	);
	const gpesDto = useSelector((state: WebAppState) => state.growthPigEvents.entities);
	const siteId = useSelector(selectSiteId);
	const dispatch = useDispatch();

	// Setup form
	const [selectedSectionOption, setSelectedSectionOption] = useState<Option>(getLocationPlaceholder());
	const [selectedPen, setSelectedPen] = useState<Option>(getPenPlaceholder());
	const [selectedDate, setSelectedDate] = useState<Date>(new Date());
	const [stableSectionOptions, setStableSectionOptions] = useState<Option[]>([]);
	const [penOptions, setPenOptions] = useState<Option[]>([]);

	const [count, setCount] = useState<number>();
	const [totalWeight, setTotalWeight] = useState<number | undefined>();
	const [avgWeight, setAvgWeight] = useState<number | undefined>();
	const [usePens, setUsePens] = useState<boolean | undefined>(false);

	// Generate Section options
	useEffect(() => {
		setStableSectionOptions(getStableSectionOptionAnimalType(hashmapLocations, productionType));
	}, [hashmapLocations, productionType]);

	// Handle selectedSectionChanged
	useEffect(() => {
		if (selectedSectionOption) {
			const pens = getPenOptionsBySectionId(hashmapLocations, selectedSectionOption.value);
			setPenOptions(pens);
		}
		setSelectedPen(getPenPlaceholder());
		setUsePens(
			hashmapLocations[selectedSectionOption.value] &&
				(hashmapLocations[selectedSectionOption.value] as ISection).usePens,
		);
	}, [selectedSectionOption, hashmapLocations]);

	// Handle selectedPenChanged
	useEffect(() => {
		if (selectedPen && selectedPen.value) {
			const maxCount = getTotalPigsOnPens(
				gpesDto,
				productionType,
				selectedSectionOption.value,
				selectedPen.value,
				selectedDate,
			);
			setCount(maxCount[selectedPen.value]);
		} else {
			setCount(undefined);
		}
	}, [selectedPen, selectedDate, gpesDto, productionType, selectedSectionOption]);

	const getFormRows = () => {
		let formRows = new Array<FormRow>();

		formRows.push(getDateRow());
		formRows.push(getStableSectionRow());
		if (usePens) {
			formRows.push(getPenRow());
		}
		formRows.push(getCountRow());
		formRows.push(getAvgWeightRow());
		formRows.push(getTotalWeightRow());
		return formRows;
	};

	const dateChanged = (newDate: Date) => {
		setSelectedDate(newDate);
	};

	const getDateRow = (): FormRow => {
		const disabled =
			productionType === AnimalType.Finisher ? sendDataToDistriwinFinisher : sendDataToDistriwinWeaner;
		return {
			name: localized('Date'),
			component: (
				<SkioldDatePicker
					onDateChanged={dateChanged}
					selectedDate={selectedDate}
					theme={'dark'}
					color={'grey'}
					disabled={disabled}
					backwardDateSetEndTime={true}
				/>
			),
		};
	};

	const stableSectionChange = (option: Option) => {
		setSelectedSectionOption(option);
	};

	const getStableSectionRow = (): FormRow => {
		return {
			name: localized('Location'),
			component: (
				<SkioldFormDropdown
					items={stableSectionOptions}
					selectedValue={selectedSectionOption}
					onValueChanged={stableSectionChange}
				/>
			),
		};
	};

	const penChange = (option: Option) => {
		setSelectedPen(option);
	};

	const getPenRow = (): FormRow => {
		return {
			name: localized('Pen'),
			component: <SkioldFormDropdown items={penOptions} selectedValue={selectedPen} onValueChanged={penChange} />,
		};
	};

	const getCountRow = (): FormRow => {
		return {
			name: localized('Count'),
			component: <SkioldFormTextField>{count}</SkioldFormTextField>,
		};
	};

	const avgWeightChange = (newWeight: number | undefined) => {
		const weight = setOneDecimalsAsNumber(newWeight);
		setAvgWeight(weight);
		const totalWeight = weight && count && setOneDecimalsAsNumber(weight * count);
		if (totalWeight) {
			setTotalWeight(totalWeight);
		} else {
			setTotalWeight(undefined);
		}
	};

	const totalWeightChange = (newWeight: number | undefined) => {
		const weight = setOneDecimalsAsNumber(newWeight);
		setTotalWeight(weight);
		const avgWeight = weight && count && setOneDecimalsAsNumber(weight / count);
		if (avgWeight) {
			setAvgWeight(avgWeight);
		} else {
			setAvgWeight(undefined);
		}
	};

	const getAvgWeightRow = (): FormRow => {
		return {
			name: localized('avgWeight'),
			component: <SkioldFormDecimalInput onChangeNumber={avgWeightChange} text={avgWeight} />,
		};
	};

	const getTotalWeightRow = (): FormRow => {
		return {
			name: localized('totalWeight'),
			component: <SkioldFormDecimalInput onChangeNumber={totalWeightChange} text={totalWeight} />,
		};
	};

	const saveWeightRegulation = () => {
		if (!validate()) {
			return;
		}
		const correctToProduction = getCorrectProductionTypeBySectionId(selectedSectionOption.value);
		const item = GrowthPigsEvent.fromJS({});
		item.id = new ObjectID().toHexString();
		item.amount = 0;
		item.siteId = siteId;
		item.date = selectedDate;
		item.createdOn = new Date();
		item.toProductionType = productionType;
		item.toBuildingId = (hashmapLocations[selectedSectionOption.value] as ISection).buildingId;
		item.toPenId = selectedPen.value;
		item.toSectionId = selectedSectionOption.value;
		item.correctToProductionType = correctToProduction;
		item.totalWeight = avgWeight!;
		item.growthPigEventType = GrowthPigEventType.RegulationWeight;
		item.isWeightRegulation = true;
		item.oldTotalWeight = avgWeight!;
		upsertGrowthPigsEvent(item)(dispatch);
		resetComponent();
	};

	const validate = () => {
		if (!selectedDate) {
			showAlert(ExceptionMessage.VALIDATION_ERROR_FIELDS_REQUIRED_AREA('Date'));
			return false;
		}
		if (!selectedSectionOption.value) {
			showAlert(ExceptionMessage.VALIDATION_ERROR_FIELDS_REQUIRED_AREA('Location'));
			return false;
		}
		if (!selectedPen.value) {
			showAlert(ExceptionMessage.VALIDATION_ERROR_FIELDS_REQUIRED_AREA('Pen'));
			return false;
		}
		if (!count) {
			showAlert(ExceptionMessage.VALIDATION_ERROR_FIELDS_REQUIRED_AREA('Count'));
			return false;
		}
		if (!avgWeight) {
			showAlert(ExceptionMessage.VALIDATION_ERROR_FIELDS_REQUIRED_AREA('avgWeight'));
			return false;
		}
		if (!totalWeight) {
			showAlert(ExceptionMessage.VALIDATION_ERROR_FIELDS_REQUIRED_AREA('totalWeight'));
			return false;
		}
		return true;
	};

	const resetComponent = () => {
		setSelectedPen(getPenPlaceholder());
		setTotalWeight(undefined);
		setAvgWeight(undefined);
	};

	return (
		<ViewWeb className="sow-entrance">
			<SkioldFormsWrapper formRows={getFormRows()} containerClassName="sow-entrance-forms-wrapper-container" />
			<ViewWeb className="button-view-style">
				<SkioldButton title={localized('Save')} onPress={saveWeightRegulation} />
			</ViewWeb>
		</ViewWeb>
	);
});
