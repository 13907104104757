import React from 'react';
import { connect } from 'react-redux';
import { NOT_FOUND } from 'redux-first-router';
import { IUserProfile } from 'shared/api/api';
import { PreAuthRedirectLocationKey } from 'shared/constants';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import Home from 'web/view/components/modules/home';
import { OverlayMenuUpperData, SubMenuContent } from 'web/view/components/navbar/overlay-menu/overlay-menu-data';
import RegisterFarrowingWorkList from 'web/view/components/pregnancies/pregnancy-events/register-farrowing/register-farrowing-work-list';
import { showAlert } from 'web/view/components/skiold-alert/skiold-alert';
import CreateTreatmentPlan from 'web/view/components/treatments/treatment-plan/create-treatment-plan';
import TreatmentDetails from 'web/view/components/treatments/treatment-plan/treatment-details';
import Profile from 'web/view/pages//profile/profile';
import AnalysisOverviewOverview from 'web/view/pages/analysis/analysis-overview';
import Animals from 'web/view/pages/animal-lists/animals';
import AnimalReportPicker from 'web/view/pages/animal-report-list/animal-report-picker';
import AuthMapper from 'web/view/pages/auth-mapper/auth-mapper';
import CallbackPage from 'web/view/pages/authorizations/callback-page';
import SignInPage from 'web/view/pages/authorizations/signin-page';
import { SignInRedirectPage } from 'web/view/pages/authorizations/signin-redirect-page';
import SignOutRedirectPage from 'web/view/pages/authorizations/signout-redirect-page';
import DashboardOverview from 'web/view/pages/dashboard-overview/dashboard-overview';
import DiagnoseCategories from 'web/view/pages/diagnose-categories/diagnose-categories';
import Diagnoses from 'web/view/pages/diagnoses/diagnoses';
import DrugStockOverview from 'web/view/pages/drug-stock/drug-stock-overview';
import DrugTypes from 'web/view/pages/drug-types/drug-types';
import FeedingStockOverview from 'web/view/pages/feeding-stock/feeding-stock-overview';
import MedicineDiagnoses from 'web/view/pages/medicine-diagnoses/medicine-diagnoses';
import Language from 'web/view/pages/profile/language-list';
import Settings from 'web/view/pages/profile/settings';
import ReasonSettings from 'web/view/pages/reason-settings/reason-settings';
import Reasons from 'web/view/pages/reasons/reasons';
import retryAdjustFeed from 'web/view/pages/retry-adjust-feed/retry-adjust-feed';
import SowCard from 'web/view/pages/sow-card/sow-card';
import SowEventLists from 'web/view/pages/sow-event-lists/sow-event-lists';
import SowEvent from 'web/view/pages/sow-events/sow-events';
import Sow from 'web/view/pages/sow/sow';
import TreatmentDefinitions from 'web/view/pages/treatment-definitions/treatment-definitions';
import TreatmentLog from 'web/view/pages/treatment-log/treatment-log';
import WorkListSettings from 'web/view/pages/work-list-setting/work-list-settings';
import WorkLists from 'web/view/pages/work-lists/work-lists';
import GrowthPigEvents from 'web/view/pages/growth-pigs-events/growth-pig-event';
import GrowthPigsReportOverview from 'web/view/pages/growth-pigs-report/growth-pigs-report-overview';
import { Routes } from './routes';
import { routeType } from './selector';
import {
	RETRY_ADJUST_FEED_PIG,
	RETRY_ADJUST_FEED_SOW,
	ROUTE_ANIMALS,
	ROUTE_SOW_ANALYSIS,
	ROUTE_ANIMAL_REPORT,
	ROUTE_CALLBACK,
	ROUTE_DASHBOARD,
	ROUTE_DIAGNOSES,
	ROUTE_DIAGNOSE_CATEGORIES,
	ROUTE_DRUG_STOCK,
	ROUTE_DRUG_TYPES,
	ROUTE_EVENTS,
	ROUTE_FEEDING_STOCK,
	ROUTE_FEEDING_STOCK_FINISHER,
	ROUTE_FEEDING_STOCK_WEANER,
	ROUTE_HOME,
	ROUTE_LANGUAGE,
	ROUTE_MEDICINE_DIAGNOSES,
	ROUTE_MODULE_SETTINGS,
	ROUTE_FINISHER_SETTINGS,
	ROUTE_WEANER_SETTINGS,
	ROUTE_PREGNANCY_EVENT_LISTS,
	ROUTE_PROFILE,
	ROUTE_REASONS,
	ROUTE_REASONS_SETTINGS,
	ROUTE_REGISTER_TREATMENT,
	ROUTE_SETTINGS,
	ROUTE_SIGN_IN,
	ROUTE_SIGN_IN_REDIRECT,
	ROUTE_SIGN_OUT_REDIRECT,
	ROUTE_SOW,
	ROUTE_SOW_CARD,
	ROUTE_SOW_SETTINGS,
	ROUTE_STATUS,
	ROUTE_TREATMENT_DEFINITIONS,
	ROUTE_TREATMENT_DETAILS,
	ROUTE_TREATMENT_LOG,
	ROUTE_WORK_LISTS,
	ROUTE_WORK_LIST_EVENT,
	ROUTE_WORK_LIST_SETTINGS,
	ROUTE_GROWTH_PIG_EVENT_LISTS_FINISHER,
	ROUTE_DRUG_STOCK_FINISHER,
	ROUTE_DRUG_STOCK_WEANER,
	ROUTE_TREATMENT_DEFINITIONS_FINISHER,
	ROUTE_TREATMENT_DEFINITIONS_WEANER,
	ROUTE_GROWTH_PIG_EVENTS_FINISHER,
	ROUTE_GROWTH_PIG_EVENT_LISTS_WEANER,
	ROUTE_GROWTH_PIG_EVENTS_WEANER,
	ROUTE_TREATMENT_LOG_FINISHER,
	ROUTE_TREATMENT_LOG_WEANER,
	ROUTE_GROWTH_PIG_DASHBOARD_WEANER,
	ROUTE_GROWTH_PIG_DASHBOARD_FINISHER,
	ROUTE_FINISHER_DATA,
	ROUTE_TICAN_CALLBACK,
	ROUTE_GROWTH_PIGS_REPORTS_FINISHER,
	ROUTE_BALANCE_WEANED_PIG,
	ROUTE_GROWTH_PIGS_REPORTS_WEANER,
	ROUTE_BMP,
	ROUTE_GROWTH_PIG_SHOULD_DEPART_LIST_WEANER,
	ROUTE_GROWTH_PIG_SHOULD_DEPART_LIST_FINISHER,
	ROUTE_REGULATIONS,
	ROUTE_MODULE_LICENSES,
	ROUTE_ADMIN_FEED_REGISTRATIONS,
	ROUTE_ADMIN_BREED_TABLE,
	ROUTE_FINISHER_ANALYSIS,
	ROUTE_WEANER_ANALYSIS,
} from './types';
import GrowthPigEventList from 'web/view/pages/growth-pig-events-list/growth-pig-events-list';
import { DashboardOverviewGrowthPigs } from 'web/view/pages/dashboard-overview-growth-pigs/dashboard-overview-growth-pigs';
import { SlaughterPigsDataOverview } from 'web/view/components/slaughter-pigs-data/slaughter-pigs-overview';
import { TicanCallbackPage } from 'web/view/components/growth-pig-setup/finisher/connection-slaughter-house/tican-callback-page';
import { BalanceWeanedPigsOverview } from 'web/view/pages/balance-weaned-pigs/balance-weaned-pigs-overview';
import BMPOverview from 'web/view/pages/bmp/bmp-overview';
import { GrowthPigShouldDepartureOverview } from 'web/view/pages/growth-pig-events-list/growth-pig-should-departure/growth-pig-should-departure-overview';
import { GrowthPigRegulationsOverview } from 'web/view/pages/growth-pig-regulations/growth-pig-regulations-overview';
import { ModuleLicences } from 'web/view/pages/module-licences/module-licences';
import { DistriwinFeedRegistrationOverview } from 'web/view/pages/distriwin-feed-registrations/distriwin-feed-registration-overview';
import { BreedTableOverview } from 'web/view/components/breed-table/breed-table-overview';
import { AnalysisOverviewGpe } from 'web/view/pages/analysis/analysis-overview-gpe';

const routesMap = {
	[ROUTE_HOME]: Home,
	[ROUTE_EVENTS]: SowEvent,
	[ROUTE_SOW]: Sow,
	[ROUTE_SOW_CARD]: SowCard,
	[ROUTE_WORK_LIST_EVENT]: RegisterFarrowingWorkList,
	[ROUTE_REGISTER_TREATMENT]: CreateTreatmentPlan,
	[ROUTE_TREATMENT_DETAILS]: TreatmentDetails,
	[ROUTE_DRUG_TYPES]: DrugTypes,
	[ROUTE_DRUG_STOCK]: DrugStockOverview,
	[ROUTE_DRUG_STOCK_WEANER]: DrugStockOverview,
	[ROUTE_DRUG_STOCK_FINISHER]: DrugStockOverview,
	[ROUTE_FEEDING_STOCK]: FeedingStockOverview,
	[ROUTE_FEEDING_STOCK_FINISHER]: FeedingStockOverview,
	[ROUTE_FEEDING_STOCK_WEANER]: FeedingStockOverview,
	[ROUTE_PREGNANCY_EVENT_LISTS]: SowEventLists,
	[ROUTE_GROWTH_PIG_EVENT_LISTS_FINISHER]: GrowthPigEventList,
	[ROUTE_GROWTH_PIG_EVENTS_FINISHER]: GrowthPigEvents,
	[ROUTE_GROWTH_PIG_EVENT_LISTS_WEANER]: GrowthPigEventList,
	[ROUTE_GROWTH_PIG_EVENTS_WEANER]: GrowthPigEvents,
	[ROUTE_DIAGNOSE_CATEGORIES]: DiagnoseCategories,
	[ROUTE_DIAGNOSES]: Diagnoses,
	[ROUTE_TREATMENT_LOG]: TreatmentLog,
	[ROUTE_TREATMENT_LOG_FINISHER]: TreatmentLog,
	[ROUTE_TREATMENT_LOG_WEANER]: TreatmentLog,
	[ROUTE_DASHBOARD]: DashboardOverview,
	[ROUTE_SETTINGS]: Settings,
	[ROUTE_WORK_LISTS]: WorkLists,
	[ROUTE_ANIMAL_REPORT]: AnimalReportPicker,
	[ROUTE_SOW_ANALYSIS]: AnalysisOverviewOverview,
	[ROUTE_ANIMALS]: Animals,
	[ROUTE_REASONS]: Reasons,
	[ROUTE_FINISHER_DATA]: SlaughterPigsDataOverview,
	[ROUTE_REASONS_SETTINGS]: ReasonSettings,
	[ROUTE_TREATMENT_DEFINITIONS]: TreatmentDefinitions,
	[ROUTE_TREATMENT_DEFINITIONS_WEANER]: TreatmentDefinitions,
	[ROUTE_TREATMENT_DEFINITIONS_FINISHER]: TreatmentDefinitions,
	[RETRY_ADJUST_FEED_SOW]: retryAdjustFeed,
	[RETRY_ADJUST_FEED_PIG]: retryAdjustFeed,
	[ROUTE_SOW_SETTINGS]: Settings,
	[ROUTE_FINISHER_SETTINGS]: Settings,
	[ROUTE_WEANER_SETTINGS]: Settings,
	[ROUTE_MEDICINE_DIAGNOSES]: MedicineDiagnoses,
	[ROUTE_WORK_LIST_SETTINGS]: WorkListSettings,
	[ROUTE_GROWTH_PIG_DASHBOARD_WEANER]: DashboardOverviewGrowthPigs,
	[ROUTE_GROWTH_PIG_DASHBOARD_FINISHER]: DashboardOverviewGrowthPigs,
	[ROUTE_LANGUAGE]: Language,
	[ROUTE_PROFILE]: Profile,
	[ROUTE_STATUS]: Home,
	[ROUTE_MODULE_SETTINGS]: AuthMapper,
	[ROUTE_SIGN_IN]: SignInPage,
	[ROUTE_SIGN_IN_REDIRECT]: SignInRedirectPage,
	[ROUTE_SIGN_OUT_REDIRECT]: SignOutRedirectPage,
	[ROUTE_CALLBACK]: CallbackPage,
	[NOT_FOUND]: Home,
	[ROUTE_TICAN_CALLBACK]: TicanCallbackPage,
	[ROUTE_GROWTH_PIGS_REPORTS_FINISHER]: GrowthPigsReportOverview,
	[ROUTE_BALANCE_WEANED_PIG]: BalanceWeanedPigsOverview,
	[ROUTE_GROWTH_PIGS_REPORTS_WEANER]: GrowthPigsReportOverview,
	[ROUTE_BMP]: BMPOverview,
	[ROUTE_GROWTH_PIG_SHOULD_DEPART_LIST_WEANER]: GrowthPigShouldDepartureOverview,
	[ROUTE_GROWTH_PIG_SHOULD_DEPART_LIST_FINISHER]: GrowthPigShouldDepartureOverview,
	[ROUTE_REGULATIONS]: GrowthPigRegulationsOverview,
	[ROUTE_ADMIN_FEED_REGISTRATIONS]: DistriwinFeedRegistrationOverview,
	[ROUTE_MODULE_LICENSES]: ModuleLicences,
	[ROUTE_ADMIN_BREED_TABLE]: BreedTableOverview,
	[ROUTE_FINISHER_ANALYSIS]: AnalysisOverviewGpe,
	[ROUTE_WEANER_ANALYSIS]: AnalysisOverviewGpe,
} as any;

const mapStateToProps = (state: WebAppState) => ({
	route: routeType(state),
	isAuth: state.authentication.isAuthenticated,
	activeRoute: state.navigation.type,
	moduleAuths: state.authorization.auth!.moduleAuthorizations,
	isAdmin: state.authentication.isAdmin,
	activeUserSite: state.profile.active!,
});
let currentRoute = '';

const Container = ({
	route,
	isAuth,
	activeRoute,
	moduleAuths,
	isAdmin,
	activeUserSite,
}: ReturnType<typeof mapStateToProps>) => {
	const Route = routesMap[route] ? routesMap[route] : routesMap[NOT_FOUND];
	// We don't know if we are auth yet
	if (isAuth === undefined) {
		return null;
	}

	// Always allow these routes - even if we are not auth
	if (route === ROUTE_SIGN_IN_REDIRECT || route === ROUTE_CALLBACK || route === ROUTE_TICAN_CALLBACK) {
		return <Route />;
	}

	// Redirect to sign in when we are not auth
	if (!isAuth) {
		localStorage.setItem(PreAuthRedirectLocationKey, activeRoute);
		return <SignInPage />;
	}

	const routeSetting = Routes[route] as any;
	const isAdminRoute = routeSetting && routeSetting.admin;
	const isGloballyAccessedRoute = routeSetting && routeSetting.global;
	// Redirect to home, when we are trying to access a page we dont have access to

	if (isAdminRoute && !isAdmin) {
		showAlert(localized('ADMINROUTE'));
		return <Home />;
	}

	if (
		!moduleAuths &&
		HandleModulesAndProccessesUserDoesNotHaveAccessTo(
			routeSetting,
			isAdmin,
			isAdminRoute,
			isGloballyAccessedRoute,
			route,
			activeUserSite,
		)
	) {
		if (routeSetting.path !== currentRoute) {
			showAlert(localized('NOACCESSROUTE'));
		}
		currentRoute = routeSetting.path;
		return <Home />;
	}

	// Allow all routes if we are auth
	return <Route />;
};

export const RouteSwitcher = connect(mapStateToProps)(Container);

function HandleModulesAndProccessesUserDoesNotHaveAccessTo(
	routeSetting: any,
	isAdmin: boolean,
	isAdminRoute: any,
	isGloballyAccessedRoute: any,
	route: string,
	activeUserSite: IUserProfile,
) {
	return (
		activeUserSite.id &&
		routeSetting &&
		((!routeSetting.accessAdmin && isAdmin) || !isAdmin) &&
		!isAdminRoute &&
		!isGloballyAccessedRoute &&
		SubMenuContent.sowManagement
			.concat(SubMenuContent.weanersManagement)
			.concat(SubMenuContent.finishersManagement)
			.find(
				subMenu =>
					subMenu.find(
						processes =>
							processes.to === route &&
							processes.id.find(
								id =>
									(activeUserSite.processes && activeUserSite.processes.includes(id)) ||
									(activeUserSite.modules && activeUserSite.modules.includes(id)),
							) !== undefined,
					) !== undefined,
			) === undefined &&
		OverlayMenuUpperData.find(
			overlayMenuUpperData =>
				overlayMenuUpperData.to === route &&
				overlayMenuUpperData.id.find(id => activeUserSite.modules && activeUserSite.modules.includes(id)) !==
					undefined,
		) === undefined
	);
}
