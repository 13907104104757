import {
	AnimalType,
	GrowthPigDepartureFromPenEventDto,
	GrowthPigsEvent,
	GrowthPigShouldDepartureTableItem,
	IGrowthPigDepartureFromPenEventDto,
	IGrowthPigEventDto,
	IGrowthPigShouldDepartureTableItem,
} from 'shared/api/api';
import { DepartureTypes } from 'shared/state/models/departure-types';
import { getLocationStringBySectionId, getOrderById, getPenNameByPenId, getPenRetentionTime } from '../location-helper';
import { getReasonNameById } from '../reason-helper/reason-helper';
import { getTotalWeightByPenId } from './growth-pig-event-function-helper';

export interface GpeShouldDepartTableItem {
	// Table Columns
	retentionDate: Date;
	locationString: string;
	penString: string;
	registrationDate: Date;
	pigCount?: number;
	avgWeight?: number;
	departureType: string;
	reasonString: string;
	checked: boolean;
	reasonId: string;

	// Row data
	gpeId: string;
}

export const generateShouldDepartureTableData = (
	gpes: GrowthPigsEvent[],
	hashMapLocations: {
		[key: string]: any;
	},
	productionType: AnimalType,
	sectionSelected?: string,
) => {
	const tableItems: GrowthPigShouldDepartureTableItem[] = [];
	const shouldDepart = gpes.filter(
		gpe =>
			gpe.departureType === DepartureTypes.departureTypeShouldDeparture &&
			gpe.fromProductionType === productionType &&
			(sectionSelected === undefined || sectionSelected === gpe.fromSectionId),
	);

	shouldDepart.forEach(gpe => {
		const retentionDate = getPenRetentionTime(gpe.fromPenId);

		let reasonString = getReasonNameById(gpe.departureReasonId);
		tableItems.push(
			GrowthPigShouldDepartureTableItem.fromJS({
				gpeId: gpe.id,
				locationString: getLocationStringBySectionId(gpe.fromSectionId, hashMapLocations),
				penString: getPenNameByPenId(gpe.fromPenId, hashMapLocations),
				registrationDate: new Date(),
				pigCount: gpe.amount,
				avgWeight: getTotalWeightByPenId(gpe.fromSectionId, gpe.fromPenId, new Date(), 0, true),
				departureType: reasonString
					? DepartureTypes.departureTypePutDown
					: gpe.fromProductionType === AnimalType.Finisher
					? DepartureTypes.departureTypeKilled
					: DepartureTypes.departureTypeSold,
				reasonString,
				checked: false,
				reasonId: gpe.departureReasonId,
				retentionDate: retentionDate!,
				penOrder: getOrderById(gpe.fromPenId, hashMapLocations)!,
				sectionOrder: getOrderById(gpe.fromSectionId, hashMapLocations)!,
				buildingOrder: getOrderById(gpe.fromBuildingId, hashMapLocations)!,
				sectionId: gpe.fromSectionId,
				penId: gpe.fromPenId,
			} as IGrowthPigShouldDepartureTableItem),
		);
	});

	return tableItems;
};

export const setShouldDepartureAsDeparted = (
	gpes: GrowthPigsEvent[],
	shouldDepartureTableItems: GrowthPigShouldDepartureTableItem[],
	registrationDate?: Date,
) => {
	const checkItems = shouldDepartureTableItems.filter(e => e.checked);
	let dataToSave = [] as GrowthPigDepartureFromPenEventDto[];

	checkItems.forEach(item => {
		const gpe = gpes.find(g => g.id === item.gpeId);
		if (gpe) {
			dataToSave.push(
				GrowthPigDepartureFromPenEventDto.fromJS({
					id: gpe.id,
					siteId: gpe.siteId,
					fromBuildingId: gpe.fromBuildingId,
					fromSectionId: gpe.fromSectionId,
					correctFromProductionType: gpe.correctFromProductionType,
					fromProductionType: gpe.fromProductionType,
					date: registrationDate ? registrationDate : item.registrationDate,
					fromPenId: gpe.fromPenId,

					// Set editable data
					amount: item.pigCount!,
					avgWeight: item.avgWeight!,
					totalWeight: item.pigCount && item.avgWeight ? item.pigCount * item.avgWeight : 0,
					bundleIdentifier: gpe.bundleIdentifier,
					departureType: item.departureType,
					departureReasonId: item.reasonId,
				} as IGrowthPigDepartureFromPenEventDto),
			);
		}
	});

	return dataToSave;
};
