import * as React from 'react';
import { ViewWeb } from 'web/view/components/utils/web-view';
import './page-container.scss';


export interface PageContainerProps {
	requireAuthorization?: boolean;
	className?: string;
	children?: React.ReactNode;
}

export default class PageContainer extends React.PureComponent<PageContainerProps> {
	public static defaultProps: Partial<PageContainerProps> = {
		requireAuthorization: true
	};
	public render() {
		return (
			<ViewWeb className={`page-container ${this.props.className}`}>
				{this.props.children}
			</ViewWeb>
		);
	}
}
