import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AnalysisMatingPregnancyTableItem, ResultAnalysisFarrowingsTableItem } from 'shared/api/api';
import { GetAnalysisMatingPregnancy } from 'shared/state/ducks/analysis/analysis-modal/operation';
import { localized, localizedDynamic } from 'shared/state/i18n/i18n';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { Heading } from '../../../utils/heading';
import { AnalysisModalMapStateToProps, AnalysisModalPropsFromParent } from '../../analysis-helper';
import { AnalysisModalHeader } from '../analysis-modal-header';
import AnalysisResultsMatingsPregnancyTable, {
	AnalysisResultsMatingsPregnancyTable as AnalysisResultsAnalysisFarrowingsTableRef,
} from './analysis-mating-pregnancy-table';
import { generatePeriodDto } from 'web/web-helpers/pdf-helper/analysis-pdf-helpers/analysis-pdf-helper';
import { ClipLoader } from 'react-spinners';

interface State {
	loading: boolean;
	data?: AnalysisMatingPregnancyTableItem;
}

const mapDispatchToProps = (dispatch: Dispatch, props: AnalysisModalPropsFromParent) => {
	return {
		getAnalysisMatingPregnancy: async (siteId: string) =>
			await GetAnalysisMatingPregnancy(siteId, props.fetchDataValue, props.dateFrom, props.dateTo),
	};
};

type Props = ReturnType<typeof AnalysisModalMapStateToProps> &
	ReturnType<typeof mapDispatchToProps> &
	AnalysisModalPropsFromParent;

export class AnalysisMatingPregnancyOverview extends React.PureComponent<Props, State> {
	public analysisResultsAnalysisFarrowingsTableRef: AnalysisResultsAnalysisFarrowingsTableRef | undefined;
	constructor(props: Props) {
		super(props);
		this.state = { loading: true, data: undefined };
	}

	public componentDidMount() {
		this.props
			.getAnalysisMatingPregnancy(this.props.siteId)
			.then(tableData => this.setState({ data: tableData, loading: false }));
	}

	public render() {
		return (
			<ViewWeb>
				<Heading
					text={
						(this.props.header ? localizedDynamic(this.props.header) : '') +
						' - ' +
						localized('Elaboration') +
						': ' +
						localizedDynamic(this.props.fetchDataValue)
					}
				/>
				<AnalysisModalHeader
					mode={this.props.mode}
					closeModal={this.props.closeModal}
					count={this.state.data && this.state.data.matings ? this.state.data.matings : 0}
					value={this.props.fetchDataValue}
					dateFrom={this.props.dateFrom}
					textForCounter={localized('Matings')}
					dateTo={this.props.dateTo}
					printData={this.printData}
				/>
				{this.state.loading ? (
					<ViewWeb className="default-spinner-container">
						<ClipLoader color="#f2ac40" size={70} />
					</ViewWeb>
				) : (
					<AnalysisResultsMatingsPregnancyTable
						mode={this.props.mode}
						dataMode={this.props.fetchDataValue}
					data={
						((this.state.data && this.state.data.tableDatas
							? this.state.data.tableDatas
							: []) as unknown) as ResultAnalysisFarrowingsTableItem[]
					}
						ref={this.setRef as any}
					/>
				)}
			</ViewWeb>
		);
	}

	private setRef = (ref?: AnalysisResultsAnalysisFarrowingsTableRef) =>
		(this.analysisResultsAnalysisFarrowingsTableRef = ref);

	private printData = async () => {
		if (this.analysisResultsAnalysisFarrowingsTableRef) {
			this.analysisResultsAnalysisFarrowingsTableRef.printData(
				generatePeriodDto(this.props.dateFrom, this.props.dateTo),
			);
		}
	};
}

export default connect(AnalysisModalMapStateToProps, mapDispatchToProps)(AnalysisMatingPregnancyOverview);
