
import actionCreatorFactory from 'typescript-fsa';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import { Dashboard, IDashboard } from 'shared/api/api';

const actionCreator = actionCreatorFactory(GenActionFactoryName('widgets'));

export const upsertDashboard = actionCreator.async<Dashboard, string, { code: number; message: string }>(
    'UPSERT_DASHBOARD'
);
export const getDashboard = actionCreator.async<string, IDashboard, { code: number; message: string }>('GET_DASHBOARD');
