import React from 'react';
import { Option } from 'react-dropdown';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AnimalType, GrowthPigEventType, GrowthPigsEvent } from 'shared/api/api';
import { GrowthPigEventOptionsArray } from 'shared/helpers/growth-pigs-helper/growth-pig-event-helper';
import { getGrowthPigsEventsBySiteId } from 'shared/state/ducks/growth-pig-events/operations';
import { GetLocations } from 'shared/state/ducks/locations/operations';
import { GetSyncData as ReasonsGetSyncData } from 'shared/state/ducks/reasons/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import PageContainer from 'web/view/components/page-container/page-container';
import { SkioldDropdown } from 'web/view/components/skiold-components/skiold-dropdown/skiold-dropdown';
import GrowthPigEntrance from 'web/view/components/stem-animal/growth-pig-events/growth-pig-entrance-event';
import GrowthPigMoveEventComponent from 'web/view/components/stem-animal/growth-pig-events/growth-pig-move-event';
import GrowthPigPenDepartureEvent from 'web/view/components/stem-animal/growth-pig-events/growth-pig-pen-departure-event';
import GrowthPigDepartureEventComponent from 'web/view/components/stem-animal/growth-pig-events/growth-pig-section-departure-event';
import { Heading } from 'web/view/components/utils/heading';
import { ViewWeb } from 'web/view/components/utils/web-view';
import isEqual from 'react-fast-compare';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { GrowthPigWeightRegulation } from 'web/view/components/stem-animal/growth-pig-events/growth-pig-weight-regulation';
import YoungAnimalIdNumberDeparture from 'web/view/components/stem-animal/growth-pig-events/young-animal-id-number-departure';

interface PropsFromParent {
	closeEditModal?: () => void;
	event?: GrowthPigsEvent;
}

const mapStateToProps = (state: WebAppState, props: any) => {
	return {
		navigation: state.navigation,
		pregnancyEventIdNavigation: state.navigation.query && state.navigation.query.pregnancy,
		stemAnimalIdNavigation: state.navigation.query && state.navigation.query.stemAnimalId,
		setEventOptionNavigation: state.navigation.query && state.navigation.query.selected,
		departedAnimals: state.stemAnimals.departuredAnimals,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	getGrowthPigsEventsBySiteId: () => getGrowthPigsEventsBySiteId()(dispatch),
	getReasons: () => ReasonsGetSyncData()(dispatch),
	GetLocations: () => GetLocations()(dispatch),
});

export interface GrowthPigEventsState {
	selected: Option | undefined;
	productionType: AnimalType;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;
export class GrowthPigEvents extends React.PureComponent<Props, GrowthPigEventsState> {
	private DefaultEventOption: Option = {
		label: localized(GrowthPigEventType.GrowthPigEntranceEvent),
		value: GrowthPigEventType.GrowthPigEntranceEvent,
	};
	constructor(props: Props) {
		super(props);
		let defaultSelected = this.DefaultEventOption;
		if (props.event && props.event.growthPigEventType) {
			const eventType =
				props.event.growthPigEventType === GrowthPigEventType.PigletMovedEvent
					? GrowthPigEventType.GrowthPigMoveEvent
					: props.event.growthPigEventType;
			defaultSelected = { label: localized(eventType), value: eventType };
		}
		this.state = {
			selected: defaultSelected,
			productionType: this.props.navigation.query
				? (this.props.navigation.query['type'] as AnimalType)
				: AnimalType.FRATS,
		};
		this.props.getGrowthPigsEventsBySiteId();
		this.props.getReasons();
		this.props.GetLocations();
	}

	public componentDidUpdate(prevProps: Props) {
		if (
			!isEqual(
				prevProps.navigation.query ? (prevProps.navigation.query['type'] as AnimalType) : AnimalType.FRATS,
				this.props.navigation.query ? (this.props.navigation.query['type'] as AnimalType) : AnimalType.FRATS,
			)
		) {
			if (this.state.selected && this.state.selected.value === GrowthPigEventType.GrowthPigDepartureEvent) {
				this.setState({
					selected: {
						label:
							this.props.navigation.query &&
							(this.props.navigation.query['type'] as AnimalType) === AnimalType.Weaner
								? localized('Sold')
								: localized(GrowthPigEventType.GrowthPigDepartureEvent),
						value: GrowthPigEventType.GrowthPigDepartureEvent,
					},
					productionType: this.props.navigation.query
						? (this.props.navigation.query['type'] as AnimalType)
						: AnimalType.FRATS,
				});
			}else if (this.state.selected && this.state.selected.value === 'SoldYoungAnimal') {
				this.setState({
					selected: {
						label:
							this.props.navigation.query &&
							(this.props.navigation.query['type'] as AnimalType) === AnimalType.Weaner
							? localized('SoldYoungAnimal')
							: localized('SoldSlaughteredYoungAnimal'),
						value: 'SoldYoungAnimal',
					},
					productionType: this.props.navigation.query
						? (this.props.navigation.query['type'] as AnimalType)
						: AnimalType.FRATS,
				});
			}  else {
				this.setState({
					productionType: this.props.navigation.query
						? (this.props.navigation.query['type'] as AnimalType)
						: AnimalType.FRATS,
				});
			}
		}
	}

	public render() {
		return (
			<PageContainer>
				<Heading text={localized('SOWEVENTS')} />
				<ViewWeb className="sow-events">
					<ViewWeb className="sow-event-content-container">
						<ViewWeb className="sow-event-dropdown-container">
							{this.props.closeEditModal ? (
								<TextWeb className={'sow-event-text-dropdown'}>
									{this.state.selected && this.state.selected.label}
								</TextWeb>
							) : (
								<SkioldDropdown
									onValueChanged={this.onSelectedValueChanged}
									disabled={!!this.props.closeEditModal}
									selectedValue={this.state.selected}
									items={GrowthPigEventOptionsArray(this.state.productionType)}
									containerClassName="dropdown-component-style"
								/>
							)}
						</ViewWeb>
						{this.state.selected &&
							this.state.selected.value === GrowthPigEventType.GrowthPigEntranceEvent && (
								<GrowthPigEntrance
									growthPigsEvent={this.props.event}
									closeEditModal={this.props.closeEditModal}
									productionType={this.state.productionType}
								/>
							)}
						{this.state.selected && this.state.selected.value === GrowthPigEventType.GrowthPigMoveEvent && (
							<GrowthPigMoveEventComponent
								growthPigsEvent={this.props.event}
								closeEditModal={this.props.closeEditModal}
								productionType={this.state.productionType}
							/>
						)}
						{this.state.selected &&
							this.state.selected.value === GrowthPigEventType.GrowthPigDepartureEvent && (
								<GrowthPigDepartureEventComponent
									growthPigsEvent={this.props.event}
									closeEditModal={this.props.closeEditModal}
									productionType={this.state.productionType}
								/>
							)}
						{this.state.selected &&
							this.state.selected.value === GrowthPigEventType.GrowthPigPenDepartureEvent && (
								<GrowthPigPenDepartureEvent
									growthPigsEvent={this.props.event}
									closeEditModal={this.props.closeEditModal}
									productionType={this.state.productionType}
									departedAnimals={this.props.departedAnimals}
								/>
							)}
						{this.state.selected && this.state.selected.value === GrowthPigEventType.Regulation && (
							<GrowthPigWeightRegulation productionType={this.state.productionType} />
						)}
						{this.state.selected && this.state.selected.value === 'SoldYoungAnimal' && (
							<YoungAnimalIdNumberDeparture
								growthPigsEvent={this.props.event}
								closeEditModal={this.props.closeEditModal}
								productionType={this.state.productionType}
								departedAnimals={this.props.departedAnimals}
							/>
						)}
					</ViewWeb>
				</ViewWeb>
			</PageContainer>
		);
	}

	private onSelectedValueChanged = (selected: Option) => {
		this.setState({ selected });
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(GrowthPigEvents);
