import { TreatmentPlanState } from './types';
import { Action } from 'redux';
import * as actions from './actions';
import { saveTreatmentPlan } from './actions';
import { isType } from 'typescript-fsa';
import { SyncableInitialState } from 'shared/state/models/syncable';
import {
	mergeArrays,
	removeMultipleValuesFromArray,
	removeValueFromArray,
	upsertValueInArray,
} from 'shared/helpers/reducer-helpers';
import { siteChange } from '../profile/actions';
import { ITreatmentPlan, TreatmentPlan } from 'shared/api/api';
import { removeRedundantDataByArray } from 'shared/helpers/general-helpers';
import { getSyncModelData } from '../sync/actions';

export const initialState: TreatmentPlanState = {
	...SyncableInitialState,
	treatmentPlansForLog: [],
	fetchingTreatmentPlansForLog: false,
};

const treatmentPlanReducer = (state: TreatmentPlanState = initialState, action: Action): TreatmentPlanState => {
	if (isType(action, actions.getSyncData.started)) {
		state = { ...state, syncInProgress: true };
	}

	if (isType(action, getSyncModelData.done)) {
		if (
			action.payload.result.treatmentPlans &&
			action.payload.params.siteId === action.payload.params.activeSiteId
		) {
			if (action.payload.result.treatmentPlans.datas!.length) {
				state = {
					...state,
					entities: removeRedundantDataByArray(
						mergeArrays(state.entities, action.payload.result.treatmentPlans.datas!),
					) as ITreatmentPlan[],
					lastSyncDate: action.payload.result.treatmentPlans.syncTime!,
				};
			}

			state = {
				...state,

				syncInProgress: false,
			};
		} else {
			state = {
				...state,
				syncInProgress: false,
			};
		}
	}

	if (isType(action, actions.getSyncData.done)) {
		if (action.payload.result && action.payload.params.siteId === action.payload.params.activeSiteId) {
			if (action.payload.result.datas!.length) {
				state = {
					...state,
					entities: removeRedundantDataByArray(
						mergeArrays(state.entities, action.payload.result.datas!),
					) as ITreatmentPlan[],
					lastSyncDate: action.payload.result.syncTime!,
				};
			}

			state = {
				...state,

				syncInProgress: false,
			};
		} else {
			state = {
				...state,
				syncInProgress: false,
			};
		}
	}

	if (isType(action, actions.getSyncData.failed)) {
		state = { ...state, syncInProgress: false };
	}

	if (isType(action, saveTreatmentPlan.started)) {
		// If this was a delete action - remove from the local arrays
		state = { ...state, saveSyncInProgress: true };
		if (action.payload && action.payload.isDeleted) {
			state = {
				...state,
				updates: upsertValueInArray(state.updates, action.payload),
				entities: removeValueFromArray(state.entities, action.payload.id!),
				treatmentPlansForLog: removeValueFromArray(state.treatmentPlansForLog, action.payload.id!),
			};
		} else if (action.payload) {
			state = {
				...state,
				updates: upsertValueInArray(state.updates, action.payload),
				entities: upsertValueInArray(state.entities, action.payload),
			};
		}

		return state;
	}

	if (isType(action, saveTreatmentPlan.failed)) {
		if (action.payload.error.code === 500 && action.payload.params) {
			if (action.payload.error.prevEntity) {
				state = { ...state, entities: upsertValueInArray(state.entities, action.payload.error.prevEntity) };
			} else {
				state = { ...state, entities: removeValueFromArray(state.entities, action.payload.params.id!) };
			}
			state = { ...state, updates: removeValueFromArray(state.updates, action.payload.params.id!) };
		}
		state = { ...state, saveSyncInProgress: false };
		return state;
	}

	if (isType(action, saveTreatmentPlan.done)) {
		return {
			...state,
			updates: removeValueFromArray(state.updates, action.payload.result),
			saveSyncInProgress: false,
		};
	}

	if (isType(action, actions.getTreatmentPlansWithCompleted.started)) {
		return { ...state, fetchingTreatmentPlansForLog: true };
	}

	if (isType(action, actions.getTreatmentPlansWithCompleted.done)) {
		return {
			...state,
			treatmentPlansForLog: mergeArrays(state.treatmentPlansForLog, action.payload.result),
			fetchingTreatmentPlansForLog: false,
		};
	}

	if (isType(action, actions.getTreatmentPlansWithCompleted.failed)) {
		return { ...state, fetchingTreatmentPlansForLog: false };
	}

	if (isType(action, siteChange.done)) {
		state = initialState;
	}

	if (isType(action, actions.removeTreatmentPlansByAnimal)) {
		const idsToRemove = state.entities
			.filter((treatPlan: any) => treatPlan.stemAnimalId === action.payload)
			.map((treatPlan: any) => treatPlan.id!);
		if (idsToRemove && idsToRemove.length > 0) {
			state = {
				...state,
				entities: removeMultipleValuesFromArray(state.entities, idsToRemove),
			};
		}
	}

	if (isType(action, actions.getTreatmentPlansByStemAnimalId.done)) {
		if (action.payload.result.length) {
			state = {
				...state,
				treatmentPlansForLog: mergeArrays(state.treatmentPlansForLog, action.payload.result),
				entities: mergeArrays(state.entities, action.payload.result),
			};
		}

		state = {
			...state,
			syncInProgress: false,
		};
	}

	if (isType(action, actions.saveManyTreatmentPlans.started)) {
		state = {
			...state,
			saveSyncInProgress: true,
			updates: mergeArrays(state.updates, action.payload),
			entities: mergeArrays(state.entities, action.payload),
		};

		return state;
	}

	if (isType(action, actions.saveManyTreatmentPlans.done)) {
		const result = {
			...state,
			updates: removeMultipleValuesFromArray(
				state.updates,
				action.payload.params.map(tp => tp.id!),
			),
			saveSyncInProgress: false,
		};
		return result;
	}

	if (isType(action, actions.saveManyTreatmentPlans.failed)) {
		if (action.payload.error.code === 500 && action.payload.params) {
			state = {
				...state,
				entities: removeMultipleValuesFromArray(
					state.entities,
					action.payload.params.map(tp => tp.id!),
				),
			};

			state = {
				...state,
				updates: removeMultipleValuesFromArray(
					state.updates,
					action.payload.params.map(tp => tp.id!),
				),
			};
		}
		state = { ...state, saveSyncInProgress: false };
		return state;
	}

	//Ensure Date objects are in fact date and not strings - redux-persist serializes dates to string but doesn't deserialize to dates again
	if (action.type === 'persist/REHYDRATE') {
		let a = (action as any) as { payload: TreatmentPlanState; key: string };

		if (a.key === 'treatmentPlans') {
			let entities = new Array<TreatmentPlan>();
			let updates = new Array<TreatmentPlan>();
			let treatmentPlansForLog = new Array<TreatmentPlan>();
			let lastSyncDate = new Date(-8640000000000000);

			if (a.payload && a.payload.entities) {
				entities = a.payload.entities.map((t: ITreatmentPlan) => {
					let ntp = TreatmentPlan.fromJS({});
					ntp.init(t);
					return ntp;
				});
			}

			if (a.payload && a.payload.updates) {
				updates = a.payload.updates.map((t: ITreatmentPlan) => {
					let ntp = TreatmentPlan.fromJS({});
					ntp.init(t);
					return ntp;
				});
			}

			if (a.payload && a.payload.treatmentPlansForLog) {
				treatmentPlansForLog = a.payload.treatmentPlansForLog.map((t: ITreatmentPlan) => {
					let ntp = TreatmentPlan.fromJS({});
					ntp.init(t);
					return ntp;
				});
			}

			if (a.payload && a.payload.lastSyncDate) {
				lastSyncDate = new Date(a.payload.lastSyncDate);
			}

			state = { ...state, entities, updates, treatmentPlansForLog, lastSyncDate };
		}
	}

	return state;
};

export default treatmentPlanReducer;
