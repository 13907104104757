import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import PrinterGreyIcon from 'shared/assets/src-assets/png/printer_ikon_grey.png';
import { RefType } from 'shared/helpers/ref-type';
import { GetAnalysisResultPerLitterData } from 'shared/state/ducks/analysis/results-per-litter/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { SkioldModal } from '../../skiold-components/skiold-modal/skiold-modal';
import { SkioldIconSpinner } from '../../utils/skiold-icon-spinner';
import { AnalysisResultPerLitterItemFromParent } from '../analysis-helper';
import AnalysisResultsAnalysisFarrowingsOverview from '../analysis-modals/analysis-results-analysis-farrowings/analysis-results-analysis-farrowings-overview';
import AnalysisResultsAnalysisMatingsOverview from '../analysis-modals/analysis-results-analysis-matings/analysis-results-analysis-matings-overview';
import AnalysisResultsPerLitterTable, {
	AnalysisResultsPerLitterTable as AnalysisResultsPerLitterTableRef,
} from './analysis-results-per-litter-table';
import AnalysisResultsAnalysisWeaningsOverview from '../analysis-modals/analysis-results-analysis-weanings/analysis-results-analysis-weanings-overview';
import { AnalysisModalMode } from 'shared/state/ducks/analysis/analysis-modal/operation';
import { generatePeriodDto } from 'web/web-helpers/pdf-helper/analysis-pdf-helpers/analysis-pdf-helper';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { SkioldFetchContainer } from '../../skiold-components/skiold-fetch/skiold-fetch-container';
import { SkioldFetchDatePicker } from '../../skiold-components/skiold-fetch/skiold-fetch-date-picker';
import { SkioldFetchBreedDropdown } from '../../skiold-components/skiold-fetch/skiold-fetch-breed-dropdown';
import { SkioldFetchButton } from '../../skiold-components/skiold-fetch/skiold-fetch-button';
import { SkioldFetchState } from 'shared/state/ducks/skiold-fetch/types';

interface PropsFromParent {
	topRight?: (topRight: JSX.Element) => void;
	topLeft?: (topLeft: JSX.Element) => void;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		siteId: state.profile.active!.siteId,
		profile: state.profile.active!,
		loading: state.dashboardOverview.loading,
		dashboardSetting: state.dashboardSettings.entity,
		matingBatches: state.matingBatches.entities,
		dateFrom: state.skioldFetch.fromDate,
		dateTo: state.skioldFetch.toDate,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
	return {
		getAnalysisResultPerLitterData: (fromDate?: Date, toDate?: Date, breed?: string) =>
			GetAnalysisResultPerLitterData(fromDate, toDate, breed)(dispatch),
	};
};

export interface State {
	modalOpen: boolean;
	selectedModal?: string;
	selectedLitter: string;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

export class AnalysisResultsPerLitterOverview extends React.PureComponent<Props, State> {
	private analysisResultsPerLitterTableRef: AnalysisResultsPerLitterTableRef | undefined;
	constructor(props: Props) {
		super(props);
		this.state = { modalOpen: false, selectedModal: undefined, selectedLitter: '0' };
	}

	public componentDidMount() {
		if (this.props.topRight) {
			this.props.topRight(this.renderTopRight());
		}
		if (this.props.topLeft) {
			this.props.topLeft(this.renderTopLeft());
		}
	}

	public render() {
		return (
			<ViewWeb>
				<AnalysisResultsPerLitterTable ref={this.setRef as any} openModal={this.OpenModal} />
				{
					<SkioldModal
						shouldCloseOnOverlayClick={true}
						padding="0"
						isOpen={this.state.modalOpen}
						close={this.closeModal}
						justify-content="flex-end"
						max-width="calc(100% - 150px)"
					>
						{this.state.selectedModal === 'TotalMatings' && (
							<AnalysisResultsAnalysisMatingsOverview
								fetchDataValue={this.state.selectedLitter}
								dateFrom={this.props.dateFrom}
								dateTo={this.props.dateTo}
								closeModal={this.closeModal}
								mode={AnalysisModalMode.ResultPerLitter}
							/>
						)}
						{this.state.selectedModal === 'TotalFarrowings' && (
							<AnalysisResultsAnalysisFarrowingsOverview
								fetchDataValue={this.state.selectedLitter}
								dateFrom={this.props.dateFrom}
								dateTo={this.props.dateTo}
								closeModal={this.closeModal}
								mode={AnalysisModalMode.ResultPerLitter}
							/>
						)}
						{this.state.selectedModal === 'AllWeanings' && (
							<AnalysisResultsAnalysisWeaningsOverview
								fetchDataValue={this.state.selectedLitter}
								dateFrom={this.props.dateFrom}
								dateTo={this.props.dateTo}
								closeModal={this.closeModal}
								mode={AnalysisModalMode.ResultPerLitter}
							/>
						)}
					</SkioldModal>
				}
			</ViewWeb>
		);
	}

	private setRef = (ref?: AnalysisResultsPerLitterTableRef) => (this.analysisResultsPerLitterTableRef = ref);

	private closeModal = () => {
		this.setState({ modalOpen: false, selectedModal: undefined, selectedLitter: '0' });
	};

	private OpenModal = (itemFromParent: AnalysisResultPerLitterItemFromParent) => {
		this.setState({
			modalOpen: true,
			selectedModal: itemFromParent.tableItem.name,
			selectedLitter: itemFromParent.key === 'total' ? '10' : itemFromParent.key.replace(/[^0-9]/g, ''),
		});
	};

	public renderTopRight = () => {
		return (
			<ViewWeb className="alignItemsCenter">
				<SkioldIconSpinner title={localized('PrintList')} icon={PrinterGreyIcon} onPress={this.printData} />
			</ViewWeb>
		);
	};

	public renderTopLeft = () => {
		return (
			<SkioldFetchContainer>
				<SkioldFetchDatePicker />
				<SkioldFetchBreedDropdown />
				<SkioldFetchButton fetchRequest={this.fetchData} />
			</SkioldFetchContainer>
		);
	};

	public fetchData = (params: SkioldFetchState) => {
		if (params && params.fromDate && params.toDate) {
			this.props.getAnalysisResultPerLitterData(params.fromDate, params.toDate, params.selectedBreed);
		}
	};

	private printData = () => {
		if (this.analysisResultsPerLitterTableRef) {
			this.analysisResultsPerLitterTableRef.printData(generatePeriodDto(this.props.dateFrom, this.props.dateTo));
		}
	};
}

export default connect<ReturnType<typeof mapStateToProps>, ReturnType<typeof mapDispatchToProps>, RefType, WebAppState>(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{
		forwardRef: true,
	},
)(AnalysisResultsPerLitterOverview);
