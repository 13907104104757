import { Sorting } from '@devexpress/dx-react-grid';
import memoize from 'memoize-one';
import React from 'react';
import { connect } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import {
	IBuilding,
	IMoveEvent,
	IPen,
	IProcessEquipmentData,
	ISection,
	IStemAnimal,
	IUnitToPen,
	SkioldOneClient,
} from 'shared/api/api';
import sendDataGrey from 'shared/assets/src-assets/png/send_data_grey.png';
import { SkioldDigitalApiBaseUrl } from 'shared/constants';
import { getDateString } from 'shared/helpers/date-helpers';
import { exactFilterMethodGrid, exactNumberFilterMethodGrid } from 'shared/helpers/general-helpers';
import { NaturalSortDates } from 'shared/helpers/natural-sort';
import { getCycleDays } from 'shared/helpers/pregnancy-helper/generel-pregnancy-helpers';
import { findRetentionTime } from 'shared/helpers/treatment-helper/treatment-helper';
import { LocationsState } from 'shared/state/ducks/locations';
import { localized } from 'shared/state/i18n/i18n';
import 'web/view/components/event-lists/pregnancy-event-lists/sow-pregnancy-event-list-styles.scss';
import 'web/view/components/skiold-components/skiold-table/skiold-double-header-table.scss';
import SkioldTableGrid, {
	SkioldTableGrid as SkioldTableRef,
} from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { SowListConstants } from 'web/view/components/stem-animal/animal-lists/table-constants';
import { getActiveSows } from 'web/view/components/stem-animal/stem-animal-input/stem-animal-input-helper';
import { SkioldTouchableOpacity } from '../../skiold-components/skiold-touchable-opacity';
import { WhiteText } from '../../Text/white-text';
import { SkioldImage } from '../../utils/svg/skiold-image';
import { ViewWeb } from '../../utils/web-view';
import {
	ResendEsfListMapDispatchToProps,
	ResendEsfListMapStateToProps,
	ResendEsfListProps,
	ResendEsfListState,
	ResendEsfSummaryItem,
	RetryEsfListItem,
	RetryEsfType,
} from './resend-esf-list-item';
import './resend-esf-list-table.scss';

export class ResendEsfListTable extends React.PureComponent<ResendEsfListProps, ResendEsfListState> {
	public SkioldTableRef: SkioldTableRef | undefined;

	private generateData = memoize((dateFrom, dateTo, departuredSows, moveEvents) =>
		this.genereateListData(dateFrom, dateTo, departuredSows, moveEvents),
	);

	private defaultSorting = [{ columnName: 'animalNumber', direction: 'asc' }] as Sorting[];

	private readonly getDateCell = (d: { row: RetryEsfListItem; index: number }) => getDateString(d.row.date);

	private readonly getAnimalNumberCell = (d: { row: RetryEsfListItem; index: number }) => d.row.animalNumber;

	private readonly getTransponderCell = (d: { row: RetryEsfListItem; index: number }) => d.row.transponder;

	private readonly getCycleDaysCell = (d: { row: RetryEsfListItem; index: number }) => d.row.cycleDays;

	private readonly getTypeCell = (d: { row: RetryEsfListItem; index: number }) =>
		localized(d.row.retryEsfType ? d.row.retryEsfType : '');

	private readonly getFromBuildingNameCell = (d: { row: RetryEsfListItem; index: number }) => d.row.fromBuildingName;

	private readonly getFromSectionNameCell = (d: { row: RetryEsfListItem; index: number }) => d.row.fromSectionName;

	private readonly getFromPenNameCell = (d: { row: RetryEsfListItem; index: number }) => d.row.fromPenName;

	private readonly getToBuildingNameCell = (d: { row: RetryEsfListItem; index: number }) => d.row.toBuildingName;

	private readonly getToSectionNameCell = (d: { row: RetryEsfListItem; index: number }) => d.row.toSectionName;

	private readonly getToPenNameCell = (d: { row: RetryEsfListItem; index: number }) => d.row.toPenName;

	constructor(props: ResendEsfListProps) {
		super(props);
		this.state = {
			loading: false,
			commitAll: false,
			moveEvents: props.moveEvents,
			listItems: [],
			selectedItems: [],
			animalsRetriedThisSession: [],
			calculating: true,
		};
	}

	public genereateListData(dateFrom, dateTo, departuredSows, moveEvents) {
		let activeSows = getActiveSows();
		let listItems: RetryEsfListItem[] = [];
		this.insertDataIntoListItems(activeSows, listItems, moveEvents, this.props.locations, dateFrom, dateTo);
		if (departuredSows && departuredSows.length > 0) {
			this.insertDataIntoListItems(departuredSows, listItems, moveEvents, this.props.locations, dateFrom, dateTo);
		}
		this.generateSummary(listItems);
		this.setState({ listItems, calculating: false });
	}

	public generateColumnsExtensions = (data: RetryEsfListItem[]) => {
		let columns: any[] = [
			{
				columnName: 'Date',
				width: SowListConstants.entranceDateWidth,
			},
			{
				columnName: 'animalNumber',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'transponder',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'CycleDays',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'Type',
				width: SowListConstants.animalNrWidth,
			},
		];
		if (data.find(a => a.usesPensFrom && a.usesSectionsFrom)) {
			columns = columns.concat([
				{
					columnName: 'FromBuildingName',
					width: SowListConstants.animalNrWidth,
				},
				{
					columnName: 'FromSectionName',
					width: SowListConstants.animalNrWidth,
				},
				{
					columnName: 'FromPenName',
					width: SowListConstants.animalNrWidth,
				},
			]);
		} else if (data.find(a => !a.usesPensFrom && a.usesSectionsFrom)) {
			columns = columns.concat([
				{
					columnName: 'FromBuildingName',
					width: SowListConstants.animalNrWidth,
				},
				{
					columnName: 'FromSectionName',
					width: SowListConstants.animalNrWidth,
				},
			]);
		} else {
			columns.push({
				columnName: 'FromBuildingName',
				width: SowListConstants.animalNrWidth,
			});
		}
		if (data.find(a => a.usesPensTo && a.usesSectionsTo)) {
			columns = columns.concat([
				{
					columnName: 'ToBuildingName',
					width: SowListConstants.animalNrWidth,
				},
				{
					columnName: 'ToSectionName',
					width: SowListConstants.animalNrWidth,
				},
				{
					columnName: 'ToPenName',
					width: SowListConstants.animalNrWidth,
				},
			]);
		} else if (data.find(a => !a.usesPensTo && a.usesSectionsTo)) {
			columns = columns.concat([
				{
					columnName: 'ToBuildingName',
					width: SowListConstants.animalNrWidth,
				},
				{
					columnName: 'ToSectionName',
					width: SowListConstants.animalNrWidth,
				},
			]);
		} else {
			columns.push({
				columnName: 'ToBuildingName',
				width: SowListConstants.animalNrWidth,
			});
		}

		return columns;
	};

	private getMoveEventsByDate = memoize((dateFrom, dateTo) => {
		this.setState({ calculating: true });

		return new SkioldOneClient(SkioldDigitalApiBaseUrl)
			.moveEvent_GetMoveEventsByDatesAndStemIds(this.props.siteId, dateFrom, dateTo)
			.then((moveEvents: IMoveEvent[]) => {
				this.generateData(this.props.dateFrom, this.props.dateTo, this.props.departuredSows, moveEvents);
			});
	});

	private generateEsfLocationsWithSettedPensData = memoize((processEquipmentData, unitToPens) =>
		this.getEsfLocationsWithSettedPensData(processEquipmentData, unitToPens),
	);

	public componentDidUpdate() {
		this.getMoveEventsByDate(this.props.dateFrom, this.props.dateTo);
	}

	public componentDidMount() {
		new SkioldOneClient(SkioldDigitalApiBaseUrl)
			.moveEvent_GetMoveEventsByDatesAndStemIds(this.props.siteId, this.props.dateFrom, this.props.dateTo)
			.then((moveEvents: IMoveEvent[]) => {
				this.generateData(this.props.dateFrom, this.props.dateTo, this.props.departuredSows, moveEvents);
			});

		if (this.SkioldTableRef) {
			this.props.setSowsCount(this.SkioldTableRef.GetSortedData().length);
		}
	}

	public render() {
		let columnExtensions = this.generateColumnsExtensions(this.state.listItems);
		return (
			<div className="resend-esf-list-table">
				{this.state.calculating ? (
					this.renderSpinner()
				) : (
					<SkioldTableGrid
						useRowIndex={true}
						tableKey={'resendListTable' + columnExtensions.length}
						columns={this.generateColumns(this.state.listItems)}
						ColumnExtensions={columnExtensions}
						data={this.state.listItems}
						groupedColumns={this.groupedColumns}
						ref={this.setTableRef}
						onFiltersChanged={this.onFiltersChanged}
						sortHeaderId={this.defaultSorting}
						useSelection={true}
						selectedChanged={this.selectedChanged}
						selectionHeader={localized('resend')}
					/>
				)}
			</div>
		);
	}

	public renderSpinner = () => {
		return (
			<ViewWeb className="work-list-spinner">
				<ClipLoader color="#f2ac40" size={50} />
			</ViewWeb>
		);
	};

	private selectedChanged = (selectedItems: RetryEsfListItem[]) => {
		this.setState({ selectedItems });
	};

	private groupedColumns = [
		{
			title: localized('FromLocation'),
			children: [
				{ columnName: 'FromBuildingName' },
				{ columnName: 'FromSectionName' },
				{ columnName: 'FromPenName' },
			],
		},
		{
			title: localized('ToLocation'),
			children: [{ columnName: 'ToBuildingName' }, { columnName: 'ToSectionName' }, { columnName: 'ToPenName' }],
		},
	];

	public getEsfLocationsWithSettedPensData(processEquipmentData: IProcessEquipmentData[], unitToPens: IUnitToPen[]) {
		let penIdsWithEsf: string[] = [];
		processEquipmentData.forEach(peq => {
			const exists = unitToPens.filter(utp => peq.id === utp.processEquipmentDataId).map(x => x.penId!);
			if (exists) {
				penIdsWithEsf = penIdsWithEsf.concat(exists);
			}
		});
		return penIdsWithEsf;
	}

	private insertDataIntoListItems(
		stemAnimals: IStemAnimal[],
		listItems: RetryEsfListItem[],
		moveEvents: IMoveEvent[],
		locations: LocationsState,
		dateFrom: Date,
		dateTo: Date,
	) {
		let groupedMoveEvents = moveEvents
			.sort((a, b) => {
				if ((b.moveDate ? b.moveDate.getTime() : 0) !== (a.moveDate ? a.moveDate.getTime() : 0)) {
					return (b.moveDate ? b.moveDate.getTime() : 0) - (a.moveDate ? a.moveDate.getTime() : 0);
				} else if (
					(b.modifiedOn ? b.modifiedOn.getTime() : 0) !== (a.modifiedOn ? a.modifiedOn.getTime() : 0)
				) {
					return (b.modifiedOn ? b.modifiedOn.getTime() : 0) - (a.modifiedOn ? a.modifiedOn.getTime() : 0);
				} else {
					return 0;
				}
			})
			.dictionaryBy('stemAnimalId');

		// find all pens with ESF, to reducer iteration
		let pensWithEsf = this.generateEsfLocationsWithSettedPensData(
			this.props.processEquipmentData,
			this.props.unitToPens,
		);

		stemAnimals.forEach(sow => {
			// If we already resend for sow, dont show anymore for this session
			// The sow will reappear when navigate away from List
			if (this.state.animalsRetriedThisSession.includes(sow.id!)) {
				return;
			}
			let sowMoveEvents = groupedMoveEvents[sow.id!];

			// Ignore entrance, if moveEvents or departure is registered
			if ((!sowMoveEvents || sowMoveEvents.length <= 0) && !sow.departureDate) {
				if (this.handleEntrance(sow, dateFrom, dateTo, listItems, locations, pensWithEsf)) {
					return;
				}
			}

			// Ignore departure, if departure wasn't from ESF location. (handleDeparture calculate that)
			if (sow.departureDate) {
				if (
					this.handleDeparture(
						sow,
						dateFrom,
						dateTo,
						listItems,
						locations,
						pensWithEsf,
						sowMoveEvents && sowMoveEvents[0],
					)
				) {
					return;
				}
			}
			if (sowMoveEvents && sowMoveEvents.length > 0) {
				// Iterator moveEvents descending, until move event is From or To Esf location
				sowMoveEvents.some((moveEvent: IMoveEvent, index: number) => {
					return this.handleMoveEvent(
						sow,
						moveEvent,
						listItems,
						locations,
						dateFrom,
						dateTo,
						sowMoveEvents,
						index,
						pensWithEsf,
					);
				});
			}
		});
	}

	private handleEntrance = (
		sow: IStemAnimal,
		dateFrom: Date,
		dateTo: Date,
		listItems: RetryEsfListItem[],
		locations: LocationsState,
		pensWithEsf: string[],
	) => {
		if (sow.entranceDate! >= dateFrom && sow.entranceDate! <= dateTo) {
			let entrancePenId = sow.entrancePenId;

			if (entrancePenId && pensWithEsf.includes(entrancePenId)) {
				const toPen = locations.pens.find(a => a.id === entrancePenId);
				const toSection = locations.sections.find(a => toPen && a.id === toPen.sectionId);
				const toBuilding = locations.buildings.find(a => toPen && a.id === toPen.buildingId);

				let item = this.setResendListItem(
					sow,
					sow.entranceDate,
					toBuilding,
					toSection,
					toPen,
					undefined,
					undefined,
					undefined,
				);
				item.retryEsfType = RetryEsfType.Entrance;

				listItems.push(item);
				return true;
			}
		}
		return false;
	};

	private handleDeparture = (
		sow: IStemAnimal,
		dateFrom: Date,
		dateTo: Date,
		listItems: RetryEsfListItem[],
		locations: LocationsState,
		pensWithEsf: string[],
		lastMoveEvent?: IMoveEvent,
	) => {
		if (sow.departureDate! >= dateFrom && sow.departureDate! <= dateTo) {
			let previousSowPen = sow.entrancePenId;
			if (lastMoveEvent) {
				previousSowPen = lastMoveEvent.penId;
			}
			if (previousSowPen && pensWithEsf.includes(previousSowPen)) {
				const fromPen = locations.pens.find(a => a.id === previousSowPen);
				const fromSection = locations.sections.find(a => fromPen && a.id === fromPen.sectionId);
				const fromBuilding = locations.buildings.find(a => fromPen && a.id === fromPen.buildingId);

				let item = this.setResendListItem(
					sow,
					sow.departureDate,
					undefined,
					undefined,
					undefined,
					fromBuilding,
					fromSection,
					fromPen,
				);
				item.retryEsfType = RetryEsfType.Departure;

				listItems.push(item);

				return true;
			}
		}
		return false;
	};

	private handleMoveEvent = (
		sow: IStemAnimal,
		moveEvent: IMoveEvent,
		listItems: RetryEsfListItem[],
		locations: LocationsState,
		dateFrom: Date,
		dateTo: Date,
		sowMoveEvents: IMoveEvent[],
		index: number,
		pensWithEsf: string[],
	) => {
		if (moveEvent.moveDate! >= dateFrom && moveEvent.moveDate! <= dateTo) {
			let previousSowPen = sow.entrancePenId;
			if (index < sowMoveEvents.length - 1) {
				previousSowPen = sowMoveEvents[index + 1].penId;
			}

			// Ignore if none of the penIds is as ESF pen
			if (
				(!moveEvent.penId || !pensWithEsf.includes(moveEvent.penId)) &&
				(!previousSowPen || !pensWithEsf.includes(previousSowPen))
			) {
				return;
			}

			// Get location informartion
			const toPen = locations.pens.find(a => a.id === moveEvent.penId);
			const toSection = locations.sections.find(a => toPen && a.id === toPen.sectionId);
			const toBuilding = locations.buildings.find(a => toPen && a.id === toPen.buildingId);
			const fromPen = locations.pens.find(a => a.id === previousSowPen);
			const fromSection = locations.sections.find(a => fromPen && a.id === fromPen.sectionId);
			const fromBuilding = locations.buildings.find(a => fromPen && a.id === fromPen.buildingId);

			if (toPen && toSection && toBuilding && fromPen && fromSection && fromBuilding) {
				const itemAlreadyExistsIndex = listItems.findIndex(
					a =>
						a.stemAnimalId === moveEvent.stemAnimalId &&
						getDateString(a.date) === getDateString(moveEvent.moveDate),
				);
				let item = this.setResendListItem(
					sow,
					moveEvent.moveDate,
					toBuilding,
					toSection,
					toPen,
					fromBuilding,
					fromSection,
					fromPen,
				);
				item.retryEsfType = RetryEsfType.MoveEvent;
				if (itemAlreadyExistsIndex >= 0) {
					listItems[itemAlreadyExistsIndex] = item;
				} else {
					listItems.push(item);
				}
				return true;
			}
		}
		return false;
	};

	private setResendListItem = (
		sow: IStemAnimal,
		date?: Date,
		toBuilding?: IBuilding,
		toSection?: ISection,
		toPen?: IPen,
		fromBuilding?: IBuilding,
		fromSection?: ISection,
		fromPen?: IPen,
	) => {
		let item = new RetryEsfListItem();
		item.stemAnimalId = sow.id;
		item.date = date;
		item.animalNumber = sow.animalNumber;
		item.toBuildingName = toBuilding && toBuilding.name;
		item.toSectionName = toBuilding && toBuilding.useSections && toSection ? toSection.name : ' ';
		item.toPenName =
			toBuilding && toBuilding.useSections && toSection && toSection.usePens && toPen ? toPen.name : ' ';
		item.toPenId = toPen && toPen.id;
		item.fromBuildingName = fromBuilding && fromBuilding.name;
		item.fromSectionName = fromBuilding && fromSection && fromBuilding.useSections ? fromSection.name : ' ';
		item.fromPenName =
			fromBuilding && fromSection && fromBuilding.useSections && fromPen && fromSection.usePens
				? fromPen.name
				: ' ';
		item.fromPenId = fromPen && fromPen.id;
		item.usesPensFrom = fromBuilding && fromSection && fromBuilding.useSections && fromSection.usePens;
		item.usesSectionsFrom = fromBuilding && fromBuilding.useSections;
		item.usesPensTo = toBuilding && toBuilding.useSections && toSection && toSection.usePens;
		item.usesSectionsTo = toBuilding && toBuilding.useSections;
		item.transponder = sow.transponder;
		item.cycleDays = sow.id ? getCycleDays(sow.id) : undefined;

		return item;
	};

	// This is a generic function, which the name has to be 'generateSummary'
	private generateSummary(pregnancyEvents: RetryEsfListItem[]) {
		let summaryItem: ResendEsfSummaryItem = {};
		summaryItem.sowCount = pregnancyEvents.length.toString();

		this.props.onSummaryChanged(
			<ViewWeb className={'centerResendIcon'}>
				<SkioldTouchableOpacity onPress={this.retryMarked}>
					<SkioldImage width="60" height="60" imageData={sendDataGrey} />
				</SkioldTouchableOpacity>
				<WhiteText>{localized('sendMarked')}</WhiteText>
			</ViewWeb>,
		);
	}

	public retryMarked = async () => {
		let aniamalIds = this.state.selectedItems.map(s => s && s.stemAnimalId!);
		await new SkioldOneClient(SkioldDigitalApiBaseUrl).retryEsf_ResendEsf(aniamalIds, this.props.siteId);

		this.setState({
			listItems: this.state.listItems.filter(
				item => item.stemAnimalId && !aniamalIds.includes(item.stemAnimalId),
			),
			animalsRetriedThisSession: [...this.state.animalsRetriedThisSession, ...aniamalIds],
		});
	};

	private onFiltersChanged = (events: RetryEsfListItem[]) => {
		this.generateSummary(events);
	};

	private setTableRef = (m: any) => (m ? (this.SkioldTableRef = m) : {});

	private hasRetentionTime = (tableItem: RetryEsfListItem) => {
		return tableItem && tableItem.stemAnimalId && findRetentionTime(tableItem.stemAnimalId) !== undefined
			? 'retention-time-list-color'
			: '';
	};

	private generateColumns = (data: RetryEsfListItem[]) => {
		let columns: any[] = [
			{
				name: 'Date',
				title: localized('Date'),
				headerClassName: 'merged-header',
				sortFunction: NaturalSortDates,
				getCellValue: this.getDateCell,
			},
			{
				name: 'animalNumber',
				headerClassName: 'merged-header',
				title: localized('animalNumber'),
				className: this.hasRetentionTime,
				filterFunction: exactFilterMethodGrid,
				getCellValue: this.getAnimalNumberCell,
			},
			{
				name: 'transponder',
				headerClassName: 'merged-header',
				title: localized('transponder'),
				filterFunction: exactFilterMethodGrid,
				getCellValue: this.getTransponderCell,
			},
			{
				name: 'CycleDays',
				title: localized('cycleDays'),
				headerClassName: 'merged-header',
				getCellValue: this.getCycleDaysCell,
			},
			{
				name: 'Type',
				title: localized('SowEvent'),
				headerClassName: 'merged-header',
				getCellValue: this.getTypeCell,
			},
		];

		if (data.find(a => a.usesPensFrom && a.usesSectionsFrom)) {
			columns = columns.concat([
				{
					name: 'FromBuildingName',
					title: localized('building'),
					getCellValue: this.getFromBuildingNameCell,
				},
				{
					name: 'FromSectionName',
					title: localized('section'),
					getCellValue: this.getFromSectionNameCell,
					filterFunction: exactNumberFilterMethodGrid,
				},
				{
					name: 'FromPenName',
					title: localized('pen'),
					getCellValue: this.getFromPenNameCell,
				},
			]);
		} else if (data.find(a => !a.usesPensFrom && a.usesSectionsFrom)) {
			columns = columns.concat([
				{
					name: 'FromBuildingName',
					title: localized('building'),
					getCellValue: this.getFromBuildingNameCell,
				},
				{
					name: 'FromSectionName',
					title: localized('section'),
					getCellValue: this.getFromSectionNameCell,
					filterFunction: exactNumberFilterMethodGrid,
				},
			]);
		} else {
			columns.push({
				name: 'FromBuildingName',
				title: localized('building'),
				getCellValue: this.getFromBuildingNameCell,
			});
		}
		if (data.find(a => a.usesPensTo && a.usesSectionsTo)) {
			columns = columns.concat([
				{
					name: 'ToBuildingName',
					title: localized('building'),
					getCellValue: this.getToBuildingNameCell,
				},
				{
					name: 'ToSectionName',
					title: localized('section'),
					getCellValue: this.getToSectionNameCell,
					filterFunction: exactNumberFilterMethodGrid,
				},
				{
					name: 'ToPenName',
					title: localized('pen'),
					getCellValue: this.getToPenNameCell,
				},
			]);
		} else if (data.find(a => !a.usesPensTo && a.usesSectionsTo)) {
			columns = columns.concat([
				{
					name: 'ToBuildingName',
					title: localized('building'),
					getCellValue: this.getToBuildingNameCell,
				},
				{
					name: 'ToSectionName',
					title: localized('section'),
					getCellValue: this.getToSectionNameCell,
					filterFunction: exactNumberFilterMethodGrid,
				},
			]);
		} else {
			columns.push({
				name: 'ToBuildingName',
				title: localized('building'),
				getCellValue: this.getToBuildingNameCell,
			});
		}

		return columns;
	};
}

export default connect(ResendEsfListMapStateToProps, ResendEsfListMapDispatchToProps, null, { forwardRef: true })(
	ResendEsfListTable,
);
