import memoize from 'memoize-one';
import * as React from 'react';
import Modal, { Styles } from 'react-modal';
import { connect } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { Dispatch } from 'redux';
import { IModule, ISite, IUserProfile, IUserSiteDTO, IWorkProcess } from 'shared/api/api';
import BackArrowIcon from 'shared/assets/src-assets/png/back_arrow.png';
import LogoutIcon from 'shared/assets/src-assets/png/logout.png';
import { LoadProfileModules } from 'shared/state/ducks/licences/operations';
import { DistriwinFeedComponentState } from 'shared/state/ducks/distriwin-feed-component';
import { GetProfile } from 'shared/state/ducks/profile/operations';
import { GetByProfileAsync } from 'shared/state/ducks/site/operations';
import { i18nTranslationKey, localized } from 'shared/state/i18n/i18n';
import { goToPage } from 'web/state/ducks/navigation/actions';
import { RouteKey } from 'web/state/ducks/navigation/routes';
import { AppRoute, ROUTE_PROFILE } from 'web/state/ducks/navigation/types';
import { WebAppState } from 'web/state/store.web';
import {
	OverlayMenuMiddleData,
	OverlayMenuUpperData,
	SubMenuContent,
} from 'web/view/components/navbar/overlay-menu/overlay-menu-data';
import {
	MenuItemTo,
	OverlayMenuItemModel,
	SubMenu,
	SubMenuToItems,
} from 'web/view/components/navbar/overlay-menu/overlay-menu-item';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { signOut } from 'web/web-helpers/authentication-helper-web';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { SkioldFlatList } from '../../skiold-components/skiold-flatlist/skiold-flatlist';
import { SkioldTouchableOpacity } from '../../skiold-components/skiold-touchable-opacity';
import { SkioldImage } from '../../utils/svg/skiold-image';
import { BurgerMenuButton } from './burger-menu-button';
import OverlayMenuHeader from './menu-header';
import { OverlayMenuItem } from './menu-item';
import './overlay-menu.scss';

interface PropsFromState {
	profile?: IUserProfile;
	userSite?: IUserSiteDTO;
	signedIn?: boolean;
	authorizeState?: string;
	modules?: IModule[];
	activeSite?: ISite;
	isAdmin?: boolean;
	isSyncing?: boolean;
	distriwinFeedComponents?: DistriwinFeedComponentState;
}

interface PropsFromParent {
	routes: { [K: string]: any };
}

const mapStateToProps = (state: WebAppState, props: PropsFromState): PropsFromState => {
	return {
		profile: state.profile.active!,
		signedIn: true, //state.auth.signedIn ? state.auth.signedIn : false,
		authorizeState: '', //state.auth.authorizeState,
		modules: state.licences.siteModules,
		isAdmin: state.authentication.isAdmin,
		isSyncing: state.offline.isSyncing,
		activeSite: state.site.site,
		distriwinFeedComponents: state.distriwinFeedComponents,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
	getProfile: () => GetProfile()(dispatch),
	goToScreen: (key: RouteKey, params?: any) => dispatch(goToPage(key, params)),
	getSite: (profileId: string) => GetByProfileAsync(profileId)(dispatch),
	loadModules: () => LoadProfileModules()(dispatch),
});

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type OverlayMenuProps = PropsFromState & PropsFromParent & DispatchProps;

export interface OverlayMenuState {
	isOpen: boolean;
	routes: { [K: string]: AppRoute };
	menuState: MenuItemTo;
	initSpinner: boolean;
}

export const modalStyle = {
	content: {
		top: '0px',
		right: '0px',
		left: 'auto',
		bottom: 'auto',
		marginLeft: '-50%',
		color: 'white',
		backgroundColor: 'rgba(52, 64, 65)',
		border: 'none',
		width: 'auto',
		height: 'auto',
		minHeight: '412px',
	},
	overlay: {
		position: 'fixed',
		top: '50px',
		backgroundColor: 'rgba(255, 255, 255, 0.2)',
		zIndex: 2000,
	},
} as Styles;

class OverlayMenu extends React.PureComponent<OverlayMenuProps, OverlayMenuState> {
	private generateUserEnabledSubModules = memoize((profile, SubMenuContent: SubMenuToItems, subMenu) =>
		this.getUserEnabledSubModules(SubMenuContent, subMenu),
	);

	private generateUserEnabledModules = memoize((profile, modules, OverlayMenuUpperData) =>
		this.getUserEnabledModules(OverlayMenuUpperData),
	);

	private readonly closeMenu = () => {
		this.setState({
			isOpen: false,
		});
	};

	private readonly navigateToProfile = () => this.onItemPressed(ROUTE_PROFILE);

	private readonly renderItem: ((item: any, index?: number | undefined) => JSX.Element) | undefined = item => {
		const showAlert = item.unread && item.unread(this.props.distriwinFeedComponents);
		return (
			<OverlayMenuItem
				name={item.name as i18nTranslationKey}
				svgXmlDataOff={item.icon}
				svgXmlDataOn={item.iconOn}
				textColorOn="orange"
				className="overlay-margin-item"
				itemFromParent={item}
				showAlert={showAlert}
				onClick={this.menuItemClicked}
			/>
		);
	};

	private readonly extractKeySkioldFlatList: ((item: any, index: number) => void) | undefined = (item, index) =>
		'' + index;

	// Navigate ti main overlay menu, if not sowManagement, else Dashboard
	private readonly navigateBackToMainMenu = () => {
		this.setState({
			menuState: 'mainMenu',
		});
	};

	private readonly mapOverlayItems: (value: any, index: number, array: any[]) => JSX.Element = (
		overlayMenuItems: OverlayMenuItemModel[],
		index,
	) => (
		<ViewWeb className="marginTen" key={'subMenuContent' + index}>
			{this.getSkioldFlatList(overlayMenuItems)}
		</ViewWeb>
	);

	constructor(props: OverlayMenuProps) {
		super(props);
		this.state = {
			isOpen: false,
			routes: this.props.routes,
			menuState: 'mainMenu',
			initSpinner: true,
		};
	}

	public async componentDidMount() {
		setTimeout(() => {
			this.setState({ initSpinner: false });
		}, 3000);
		Modal.setAppElement('#root');
		this.props.loadModules();
		this.props.getProfile();

		if (this.props.profile && this.props.profile.id) {
			this.props.getSite(this.props.profile!.id!);
		}

		this.updateState(this.props);
	}

	public UNSAFE_componentWillReceiveProps(props: OverlayMenuProps) {
		this.updateState(props);
	}

	private toggleBurger = () => {
		this.setState({
			isOpen: !this.state.isOpen,
		});
	};

	private updateState(props: OverlayMenuProps) {}

	private onItemPressed(destination: RouteKey) {
		this.setState({
			isOpen: false,
		});
		this.props.goToScreen(destination, this.props.routes[destination].params);
		this.props.getProfile();
	}

	private SignOut = () => {
		signOut();
	};

	private menuItemClicked = (item: OverlayMenuItemModel) => {
		if (item.type === 'navigation') {
			if (item.to) {
				this.onItemPressed(item.to as RouteKey);
			} else {
				console.log(`No route defined for ${item.name}`);
			}
		} else if (item.type === 'submenu' && item.to) {
			const nextState = item.to;
			this.setState({
				menuState: nextState,
			});
		}
	};

	private getSkioldFlatList(data: OverlayMenuItemModel[]) {
		return (
			<SkioldFlatList
				data={data}
				keyExtractor={this.extractKeySkioldFlatList}
				contentContainerClassName="flat-list-container"
				horizontal={true}
				renderItem={this.renderItem}
			/>
		);
	}

	private mainMenu() {
		return (
			<ViewWeb className="overlay-menu-view-container">
				<ViewWeb className="flexDirectionColumn">
					<ViewWeb className="marginTen">
						<OverlayMenuHeader text="Management" />
						{this.getSkioldFlatList(
							this.generateUserEnabledModules(
								this.props.profile,
								this.props.modules,
								OverlayMenuUpperData,
							),
						)}
						{this.getSkioldFlatList(
							this.generateUserEnabledModules(
								this.props.profile,
								this.props.modules,
								OverlayMenuMiddleData,
							),
						)}
					</ViewWeb>
				</ViewWeb>
			</ViewWeb>
		);
	}
	private subMenu(subMenu: SubMenu) {
		return (
			<ViewWeb className="overlay-menu-view-container">
				<ViewWeb className="back-button-container">
					<SkioldTouchableOpacity className="flexDirectionColumn" onPress={this.navigateBackToMainMenu}>
						<TextWeb className="back-button-arrow-container">
							<SkioldImage height={15} width={15} imageData={BackArrowIcon} />
						</TextWeb>
					</SkioldTouchableOpacity>
					<OverlayMenuHeader className="marginLeftTen" text={localized(this.state.menuState)} />
				</ViewWeb>
				<ViewWeb className="flexDirectionColumn marginLeftTen">
					{this.generateUserEnabledSubModules(this.props.profile, SubMenuContent, subMenu).map(
						this.mapOverlayItems,
					)}
				</ViewWeb>
			</ViewWeb>
		);
	}

	private renderMenu() {
		let result: JSX.Element;
		if (this.state.menuState === 'mainMenu') {
			result = this.mainMenu();
		} else {
			result = this.subMenu(this.state.menuState as SubMenu);
		}

		return result;
	}

	// tslint:disable-next-line:member-ordering
	public render() {
		if (!this.props.signedIn) {
			return <ViewWeb />;
		}
		const webOnlyAttr = {
			alt: localized('logout'),
		};
		return (
			<ViewWeb>
				<BurgerMenuButton onPress={this.toggleBurger} open={this.state.isOpen} />
				<Modal
					shouldCloseOnOverlayClick={true}
					isOpen={this.state.isOpen}
					style={modalStyle}
					onRequestClose={this.closeMenu}
				>
					<ViewWeb className="overlay-menu">
						{(this.props.isSyncing || this.state.initSpinner) && (
							<ViewWeb className="over-menu-spinner">
								<ClipLoader color="#f2ac40" size={70} />
							</ViewWeb>
						)}

						{this.renderMenu()}

						<ViewWeb className="overlay-menu-sub-view-container">
							<ViewWeb className="marginFive">
								<ViewWeb className="flexDirectionRow">
									<ViewWeb>
										<SkioldTouchableOpacity onPress={this.navigateToProfile}>
											<TextWeb>{this.props.profile!.name!}</TextWeb>
											<TextWeb className="editProfileText">{localized('EDITPROFILE')}</TextWeb>
										</SkioldTouchableOpacity>
									</ViewWeb>
								</ViewWeb>
							</ViewWeb>

							<ViewWeb className="logOutContainer">
								<SkioldTouchableOpacity className="flexDirectionRow" onPress={this.SignOut}>
									<TextWeb className="logoutText">{localized('logout')}</TextWeb>
									<SkioldImage height={15} width={15} imageData={LogoutIcon} {...webOnlyAttr} />
								</SkioldTouchableOpacity>
							</ViewWeb>
						</ViewWeb>
					</ViewWeb>
				</Modal>
			</ViewWeb>
		);
	}

	private getUserEnabledSubModules(SubMenuContent: SubMenuToItems, subMenu: string): any[] {
		if (this.props.profile && this.props.modules && this.props.modules.length > 0) {
			const res: any[] = [];
			const pModules = this.props.profile.modules;
			if (!pModules) {
				return res;
			}
			let items: any[] = [[], [], []];

			let selectedSubMenu = this.props.modules.find(
				m =>
					this.props.profile !== undefined &&
					this.props.profile.modules !== undefined &&
					this.props.profile.modules.find(
						pm => pm === m.id && this.checkValuesOfSubMenuContent(SubMenuContent[subMenu], pm),
					) !== undefined,
			);
			if (selectedSubMenu !== undefined) {
				let allModuleProcesses = selectedSubMenu!.processes!.filter(
					workProcess =>
						this.props.profile!.processes!.find(pm => workProcess.id === pm) !== undefined &&
						this.props.activeSite?.activeLicences?.includes(workProcess.licenceId!),
				);
				SubMenuContent[subMenu].forEach((row: any, index: number) => {
					row.forEach((item: any) => {
						if (selectedSubMenu && selectedSubMenu.name) {
							if (this.handleSowManagement(item, allModuleProcesses, selectedSubMenu)) {
								items[index].push(item);
							}
						}
					});
				});
			} else if (this.props.isAdmin) {
				SubMenuContent[subMenu].forEach((row: OverlayMenuItemModel[], index: number) => {
					row.forEach((item: OverlayMenuItemModel) => {
						if (item.adminMenu) {
							items[index].push(item);
						}
					});
				});
			}

			return items;
		}

		return [];
	}

	private checkValuesOfSubMenuContent(subMenuContent: OverlayMenuItemModel[][], profileModule: string) {
		for (let modules of subMenuContent) {
			for (let subModules of modules) {
				if (subModules.id.includes(profileModule)) {
					return true;
				}
			}
		}

		return false;
	}

	private handleSowManagement(item: any, workprocesses: IWorkProcess[], subMenu: IModule) {
		if (item.id.includes(subMenu.id)) {
			return true;
		}
		return workprocesses.find(e => item.id.includes(e.id)) !== undefined;
	}

	private getUserEnabledModules(ModulesList: OverlayMenuItemModel[]): OverlayMenuItemModel[] {
		if (this.props.profile && this.props.modules && this.props.modules.length > 0) {
			const res: OverlayMenuItemModel[] = [];
			const pModules = this.props.profile.modules;
			const siteModules = this.props.modules;
			if (!pModules) {
				return res;
			}

			return ModulesList.filter(
				m =>
					(m.adminMenu && this.props.isAdmin) ||
					(m.id &&
						siteModules.find(siteModule => m.id.findIndex(mId => mId === siteModule.id) > -1) &&
						pModules.find(id => m.id.findIndex(mId => mId === id) > -1)),
			);
		}

		return [];
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(OverlayMenu);
