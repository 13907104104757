import memoize from 'memoize-one';
import React from 'react';
import { connect } from 'react-redux';
import { IProcessEquipmentData, IUnitToPen } from 'shared/api/api';
import { RefType } from 'shared/helpers/ref-type';
import {
	allAnimalsEsfListMapDispatchToProps,
	allAnimalsEsfListMapStateToProps,
	AllAnimalsEsfListProps,
	AllAnimalsEsfListState,
} from 'shared/helpers/work-list-helpers/esf-list-helpers/all-animals-esf-helpers';
import {
	GetEsfLocationsWithSettedPensData,
	GetFilteredLocations,
} from 'shared/helpers/work-list-helpers/esf-list-helpers/general-work-list-esf-helpers';
import { getStationsInUse } from 'shared/helpers/work-list-helpers/esf-list-helpers/generel-esf-list-helper';
import { checkSectionWorkLists } from 'shared/helpers/work-list-helpers/move-to-helpers/moving-lists-helper';
import { localized } from 'shared/state/i18n/i18n';
import { SharedAppState } from 'shared/state/store.shared';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import SkioldFormPenPicker from '../../location/location-picker/skiold-form-pen-picker';
import SkioldStableSectionPicker from '../../location/location-picker/skiold-stable-section-picker';
import '../list-setup.scss';
import AllAnimalsEsfListTable from './all-animals-esf-list-table';

export class AllAnimalsEsfList extends React.PureComponent<AllAnimalsEsfListProps, AllAnimalsEsfListState> {
	public SkioldTableRef: any;
	private esfLocationsWithSettedPens: IProcessEquipmentData[] = [];
	private generateEsfLocationsWithSettedPensData = memoize((processEquipmentData, unitToPens) =>
		GetEsfLocationsWithSettedPensData(processEquipmentData, unitToPens),
	);
	constructor(props: AllAnimalsEsfListProps) {
		super(props);
		const filteredLocs = GetFilteredLocations(props, this.generateEsfLocationsWithSettedPensData);
		this.esfLocationsWithSettedPens = filteredLocs.esfLocationsWithSettedPens;
		let stations = getStationsInUse(
			this.props.unitToPens,
			'',
			this.props.stations,
			this.esfLocationsWithSettedPens,
		);

		this.state = {
			stations: stations,
			penId: '',
			sectionId: '',
			usesPens: false,
			filteredLocations: filteredLocs.locations,
		};
	}

	public componentDidMount() {
		this.props.getWorkListSettingsBySiteId();
		this.props.stemAnimalGetSyncData();
		this.props.validationSetupGetSyncData();
		this.props.moveEventGetSyncData();
		this.props.getLocations();
		this.props.getFeedingCurves();
		this.props.getFeedingStatus();
		this.props.GetProcessEquipmentDataSyncData();
		this.props.getunitsData();
		this.props.GetStationSyncData();
	}

	public async ResetWorkList() {
		if (this.SkioldTableRef) {
			this.SkioldTableRef.ResetWorkList();
		}
	}
	public async CheckSavePregnancyEventListsStatus() {
		if (this.SkioldTableRef && this.SkioldTableRef.CheckSavePregnancyEventListsStatus) {
			return await this.SkioldTableRef.CheckSavePregnancyEventListsStatus();
		}
		return true;
	}

	public async SaveWorklist() {
		if (this.SkioldTableRef) {
			this.SkioldTableRef.SaveWorklist();
		}

		return true;
	}

	public render() {
		return (
			<ViewWeb className="work-list flex-direction-row">
				<ViewWeb className="view-container">
					<ViewWeb className="z-index-1000">
						<ViewWeb className="getrows">
							<ViewWeb className="flex-direction-row">
								<ViewWeb className="flexFour flex-direction-row ">
									<ViewWeb className="buttons-no-right-border">
										<TextWeb className="stable-section-text">
											{localized('chooseLocation')}:
										</TextWeb>
										<SkioldStableSectionPicker
											onStableSectionSelected={this.onStableSectionChanged}
											sectionId={this.state.sectionId}
											filteredLocations={this.state.filteredLocations}
											isPigProduction={true}
											containerClassName="picker-width"
										/>
										{this.state.usesPens && (
											<ViewWeb className="buttons-no-right-border">
												<TextWeb className="pen-text">{localized('Pen')}:</TextWeb>
												<SkioldFormPenPicker
													onPenSelected={this.onPenChanged}
													filteredLocations={this.state.filteredLocations}
													sectionId={this.state.sectionId}
													penId={undefined}
													theme="light"
													containerClassName="picker-width"
													usedInsideForm={false}
												/>
											</ViewWeb>
										)}
									</ViewWeb>
								</ViewWeb>
							</ViewWeb>
							<ViewWeb className="flex-direction-row">
								<ViewWeb className="flexFour flex-direction-row ">
									<ViewWeb className="buttons-no-right-border">
										<TextWeb className="stable-section-text">{localized('Stations')}:</TextWeb>
										<TextWeb className="stable-section-text">{this.state.stations}</TextWeb>
									</ViewWeb>
								</ViewWeb>
							</ViewWeb>
						</ViewWeb>
					</ViewWeb>
					<AllAnimalsEsfListTable
						sectionId={this.state.sectionId}
						penId={this.state.penId}
						ref={this.SetRef}
						esfLocationsWithSettedPens={this.esfLocationsWithSettedPens}
					/>
				</ViewWeb>
			</ViewWeb>
		);
	}
	private onPenChanged = penId => {
		this.setState({
			penId: penId,
		});
	};

	private onStableSectionChanged = sectionId => {
		let sectionInfo = checkSectionWorkLists(sectionId, this.state.penId);
		let stations = getStationsInUse(
			this.props.unitToPens,
			sectionId,
			this.props.stations,
			this.esfLocationsWithSettedPens,
		);
		this.setState({
			sectionId: sectionId,
			penId: undefined,
			usesPens: sectionInfo.usesPens,
			stations: stations,
		});
	};
	private SetRef = (m: any) => (m ? (this.SkioldTableRef = m) : {});
}

export default connect<
	ReturnType<typeof allAnimalsEsfListMapStateToProps>,
	ReturnType<typeof allAnimalsEsfListMapDispatchToProps>,
	RefType,
	SharedAppState
>(allAnimalsEsfListMapStateToProps, allAnimalsEsfListMapDispatchToProps, null, { forwardRef: true })(AllAnimalsEsfList);
