import { Action } from 'redux';
import { DrugWithAdjustment, IDrugWithAdjustment } from 'shared/api/api';
import {
	removeValueFromArrayNoUnique,
	upsertValueInArray,
	upsertValueInArrayNoUnique,
} from 'shared/helpers/reducer-helpers';
import { isType } from 'typescript-fsa';
import { DrugState, DrugUpdate } from '.';
import { siteChange } from '../profile/actions';
import { deleteDrug, getDrugs, getDrugsForMedicineLog, removeDrug, saveDrug } from './actions';

export const initialState: DrugState = {
	drugs: [],
	drugsWithAdjustments: [],
	updates: [],
	isFetching: false,
};

const drugReducer = (state: DrugState = initialState, action: Action): DrugState => {
	if (isType(action, saveDrug.done)) {
		let drugsWithAdjustments = [...state.drugsWithAdjustments];
		if (action.payload.params.adjustment && action.payload.params.adjustment.id) {
			let index = drugsWithAdjustments.findIndex(a => a.adjustmentId === action.payload.params.adjustment!.id);
			if (index !== -1) {
				const adjustment = { ...drugsWithAdjustments[index] };
				adjustment.price = action.payload.params.adjustment.price;
				adjustment.amount = action.payload.params.adjustment.amount;
				adjustment.date = action.payload.params.adjustment.date;
				drugsWithAdjustments[index] = adjustment;
			}
		}
		const result = {
			...state,
			updates: removeValueFromArrayNoUnique(
				state.updates,
				action.payload.result,
				elem => (elem as DrugUpdate).drug!.id!,
			),
			drugs: upsertValueInArray(state.drugs, action.payload.params.drug!),
			drugsWithAdjustments,
		};
		return result;
	}

	if (isType(action, deleteDrug.started)) {
		let updates = upsertValueInArrayNoUnique(
			state.updates,
			action.payload,
			elem => (elem as DrugWithAdjustment).id!,
		);
		let drugsWithAdjustments = [...state.drugsWithAdjustments];
		drugsWithAdjustments = drugsWithAdjustments.filter(a => a.adjustmentId !== action.payload.adjustmentId);

		const result = {
			...state,
			updates,
			drugsWithAdjustments,
		};
		return result;
	}

	if (isType(action, deleteDrug.done)) {
		let updates = [...state.updates];
		updates.filter(a => (a as IDrugWithAdjustment).adjustmentId === action.payload.params.adjustmentId);

		const result = {
			...state,
			updates,
		};
		return result;
	}

	if (isType(action, getDrugs.started)) {
		const result = { ...state, isFetching: true };
		return result;
	}

	if (isType(action, removeDrug.done)) {
		const result = { ...state, drugs: state.drugs.filter(a => a.id !== action.payload.params) };
		return result;
	}


	if (isType(action, getDrugs.done)) {
		if (action.payload.params.siteId === action.payload.params.activeSiteId) {
			const result = { ...state, drugs: action.payload.result, isFetching: false };
			return result;
		}
	}

	if (isType(action, getDrugs.failed)) {
		const result = { ...state, isFetching: false };
		return result;
	}

	if (isType(action, getDrugsForMedicineLog.done)) {
		if (action.payload.params.siteId === action.payload.params.activeSiteId) {
			const result = { ...state, drugsWithAdjustments: action.payload.result };
			return result;
		}
	}

	if (isType(action, siteChange.done)) {
		state = initialState;
	}
	if (action.type === 'persist/REHYDRATE') {
		let a = (action as any) as { payload: DrugState; key: string };

		if (a.key === 'root') {
			let updates = new Array<DrugWithAdjustment | DrugUpdate>();

			if (a.payload && a.payload.updates) {
				updates = a.payload.updates.map((t: DrugWithAdjustment | DrugUpdate) => {
					t.init(t);
					return t;
				});
			}


			state = { ...state, updates, };
		}
	}

	return state;
};

export default drugReducer;
