import ObjectID from 'bson-objectid';
import { Option } from 'react-dropdown';
import {
	GrowthPigEntranceEventDto,
	GrowthPigsEvent,
	IGrowthPigEntranceEventDto,
	IGrowthPigEventDto,
	IPen,
} from 'shared/api/api';
import { getCorrectProductionTypeBySectionId } from 'shared/helpers/growth-pigs-helper/growth-pig-event-function-helper';
import { GrowthPigEntranceEventBundleListRow } from 'shared/helpers/growth-pigs-helper/growth-pig-event-list-helper';

export const initEditGpeEntranceData = (
	options: Option[],
	growthPigEvents: GrowthPigsEvent[],
	bundle: GrowthPigEntranceEventBundleListRow,
	hashmapLocations: {
		[key: string]: any;
	},
	siteId?: string,
) => {
	let bundled = growthPigEvents.filter(e => e.bundleIdentifier === bundle.bundleId);
	let initData = [] as GrowthPigEntranceEventDto[];

	// Because of a misunderstanding, we get correct production by sectionId
	const correctProduction = getCorrectProductionTypeBySectionId(bundle.sectionId);

	// Setup gpes to edit, with correct values, by pen Options - Empty pens will show aswell
	options.forEach(o => {
		let gpeToUse = bundled.find(gpe => gpe.toPenId === o.value);
		if (!gpeToUse) {
			let pen = hashmapLocations[o.value] as IPen;
			initData.push(
				GrowthPigEntranceEventDto.fromJS({
					name: o.label,
					siteId: siteId,
					toBuildingId: pen.buildingId,
					toSectionId: pen.sectionId,
					correctToProductionType: correctProduction,
					retentionDate: bundle.retentionDate,
					toProductionType: bundle.productionType!,
					date: bundle.date!,
					toPenId: pen.id,
					amount: 0,
					avgWeight: bundle.avgWeight,
					totalWeight: 0,
					bundleIdentifier: bundle.bundleId,
				} as IGrowthPigEntranceEventDto),
			);
		} else {
			let pen = hashmapLocations[o.value] as IPen;
			initData.push(
				GrowthPigEntranceEventDto.fromJS({
					id: gpeToUse.id,
					name: o.label,
					siteId: siteId,
					toBuildingId: pen.buildingId,
					toSectionId: pen.sectionId,
					correctToProductionType: correctProduction,
					retentionDate: bundle.retentionDate,
					toProductionType: bundle.productionType!,
					date: bundle.date!,
					toPenId: pen.id,
					amount: gpeToUse.amount,
					avgWeight: bundle.avgWeight,
					totalWeight: gpeToUse.totalWeight,
					bundleIdentifier: bundle.bundleId,
				} as IGrowthPigEntranceEventDto),
			);
		}
	});
	return initData;
};

export const HandlePenChangeEntranceGpeEdit = (
	data: GrowthPigEntranceEventDto[],
	pensToUse: Option[],
	bundle: GrowthPigEntranceEventBundleListRow,
) => {
	let tmpTotal = bundle.pcs ? bundle.pcs : 0;
	const copyData = [...data];

	// Skip handles pens which need to be unset - This is to handle correct distribution
	let skipped = 0;
	copyData.forEach((e, index) => {
		const row = pensToUse.find(penOption => penOption.value === e.toPenId);
		if (row) {
			let penCount = Math.ceil(tmpTotal / (pensToUse.length - index + skipped));

			copyData[index] = { ...e, amount: penCount } as GrowthPigEntranceEventDto;
			tmpTotal -= penCount;
		} else {
			copyData[index] = { ...e, amount: 0 } as GrowthPigEntranceEventDto;
			skipped++;
		}
	});

	return copyData;
};

export const handleEntranceEditBeforeSave = (
	dataToChange: GrowthPigEntranceEventDto[],
	pensToUse: string[],
	bundle: GrowthPigEntranceEventBundleListRow,
	date?: Date,
) => {
	let dataToSave = [] as GrowthPigEntranceEventDto[];
	dataToChange.forEach(e => {
		// Overwrite gpe if is exists, otherwise create a new gpe
		const exists = pensToUse.find(penOption => penOption === e.toPenId);
		if (exists) {
			let dataItem = { ...e, date: date ? date : e.date } as GrowthPigEntranceEventDto;
			dataItem.totalWeight = dataItem.amount ? dataItem.amount * bundle.avgWeight : 0;
			dataItem.id = dataItem.id ? dataItem.id : new ObjectID().toHexString();
			dataToSave.push(dataItem);
		} else {
			if (!e.amount && e.id) {
				let dataItem = { ...e } as GrowthPigEntranceEventDto;
				dataItem.totalWeight = 0;
				dataToSave.push(dataItem);
			}
		}
	});
	return dataToSave;
};
