import * as types from './types';
import actionCreatorFactory from 'typescript-fsa';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import {
	GrowthPigDepartureEventDto,
	GrowthPigDepartureFromPenEventDto,
	GrowthPigEntranceEventDto,
	GrowthPigEventDto,
	GrowthPigEventType,
	GrowthPigMoveEventDto,
	GrowthPigPigletMoveEventDtoAndUpdateEvents,
	GrowthPigsEvent,
	SyncGrowthPigEvents,
	UpdateGrowthPigEventDto,
} from 'shared/api/api';
import { GpeDepartureCount } from './types';

const actionCreator = actionCreatorFactory(GenActionFactoryName('growthPigEvents'));

export const upsertEntranceEvent = actionCreator.async<GrowthPigEntranceEventDto[], UpdateGrowthPigEventDto>(
	types.UPSERT_GROWTHPIG_ENTRANCE,
);

export const upsertGrowthPigsPigletMoveEventsAndUpdateEvents = actionCreator.async<
	GrowthPigPigletMoveEventDtoAndUpdateEvents,
	UpdateGrowthPigEventDto
>(types.UPSERT_GROWTH_PIG_PIGLET_MOVE_EVENTS_AND_UPDATE_EVENTS);

export const upsertMoveEvent = actionCreator.async<
	GrowthPigMoveEventDto[],
	UpdateGrowthPigEventDto,
	{ code: number; message: string }
>(types.UPSERT_GROWTHPIG_MOVEEVENT);

export const upsertGrowthPigsEvent = actionCreator.async<GrowthPigsEvent, void>(types.UPSERT_GROWTHPIGSEVENTS);

export const upsertDepartureEvent = actionCreator.async<GrowthPigDepartureEventDto[], GrowthPigEventDto>(
	types.UPSERT_GROWTHPIG_DEPARTURE,
);

export const upsertDepartureFromPenEvent = actionCreator.async<GrowthPigDepartureFromPenEventDto[], GrowthPigEventDto>(
	types.UPSERT_GROWTHPIG_FROM_PEN_DEPARTURE,
);

export const getGrowthPigsEventsBySiteId = actionCreator.async<
	{ siteId: string; lastSyncDate: Date; activeSiteId?: string },
	SyncGrowthPigEvents
>(types.GET_GROWTHPIGEVENTS_BY_SITEID);

export const GetGrowthPigEventsByDates = actionCreator.async<undefined, GrowthPigsEvent[]>(
	types.GET_GROWTHPIGEVENTS_BY_DATES,
);

export const GetGrowthPigEventsRegulationsByDates = actionCreator.async<undefined, GrowthPigsEvent[]>(
	types.GET_GROWTHPIGEVENTS_REGULATIONS_BY_DATES,
);

export const GetGrowthPigEventsByDatesDashboard = actionCreator.async<undefined, GrowthPigsEvent[]>(
	types.GET_GROWTHPIGEVENTS_BY_DATES_DASHBOARD,
);

export const GetEventTypesGrowthPigEventsByDates = actionCreator.async<GrowthPigEventType, GrowthPigsEvent[]>(
	types.GET_EVENTTYPE_GROWTHPIGEVENTS_BY_DATES,
);

export const InitialSaveGrowthPigEvent = actionCreator.async<GrowthPigsEvent[], undefined>(
	types.SAVE_INITIAL_GROWTH_PIG_EVENTS,
);

export const setGpeDepartureCount = actionCreator<GpeDepartureCount>(types.SET_GPE_DEPARTURE_COUNT);
