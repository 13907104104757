import {
	AllAnimalsEsfWorkListTableItem,
	FarrowingTaskTreatmentColumn,
	FarrowingWorkListTableItem,
	FileResponse,
	IScanningListSettingBase,
	ITasksInFarrowingBase,
	IVaccineListSetting,
	MatingWorkListTableItem,
	MovingWorkListTableItemPdf,
	ScanningSubColumn,
	ScanningWorkListPdfData,
	ScanningWorkListTableItem,
	ShortageEsfWorkListTableItem,
	SkioldOneClient,
	TasksInFarrowingStableData,
	TasksInFarrowingStableItemPdf,
	VaccineSubColumn,
	VaccineSubColumnHeader,
	VaccineWorkListPdfData,
	VaccineWorkListTableItem,
	WeaningWorkListTableItem,
	LardScanningEventWorkListTableItem,
} from 'shared/api/api';
import { SkioldDigitalApiBaseUrl } from 'shared/constants';
import { getDateString } from 'shared/helpers/date-helpers';
import { getAnimalNumberColor } from 'shared/helpers/treatment-helper/treatment-helper';
import { AllAnimalsEsfWorkListItem } from 'shared/helpers/work-list-helpers/esf-list-helpers/all-animals-esf-helpers';
import { ShortageEsfWorkListItem } from 'shared/helpers/work-list-helpers/esf-list-helpers/shortage-esf-helpers';
import { MatingWorkListItemData } from 'shared/helpers/work-list-helpers/mating-list-helpers/mating-work-list-item';
import { MovingListTable } from 'shared/helpers/work-list-helpers/move-to-helpers/move-to-data-model';
import { TasksInFarrowingStableItem } from 'shared/helpers/work-list-helpers/tasks-in-farrowing-stable-helper/tasks-in-farrowing-stable-item';
import {
	isDistinctBuilding,
	isDistinctPen,
} from 'shared/helpers/work-list-helpers/vaccine-list-helpers/vaccine-work-list-helpers';
import { savePdfAs } from './general-pdf-helper';
import { ScanningListTableItem } from 'shared/helpers/work-list-helpers/scanning-list-helpers/scanning-work-list-item';
import { VaccineWorkListItem } from 'shared/helpers/work-list-helpers/vaccine-list-helpers/vaccine-worklist-items';
import { LardScanningEventListTableItem } from 'shared/helpers/work-list-helpers/lard-scanning-event-list-helpers/lard-scanning-event-work-list-item';
import { getLocationStringByPenId } from 'shared/helpers/location-helper';
import { getFeedCurvesByPenId } from 'shared/helpers/feed-curve-helper';

export function GetMatingWorkListPdf(
	items: MatingWorkListItemData[],
	fileName: string,
	siteId: string,
	timeZoneId?: string,
	lang?: string,
) {
	let matingWorkListTableItems: MatingWorkListTableItem[] = [];
	items.forEach(matingWorkListItem => {
		let workListTableItem = new MatingWorkListTableItem();
		workListTableItem.animalNumber = matingWorkListItem.listItemData.animalNumber;
		workListTableItem.boar =
			matingWorkListItem.workListData!.boar !== undefined ? matingWorkListItem.workListData!.boar : '';
		workListTableItem.commited = matingWorkListItem.workListData!.isChecked;
		workListTableItem.cycleDays = matingWorkListItem.listItemData.cycleDays
			? matingWorkListItem.listItemData.cycleDays.toString()
			: '';
		workListTableItem.matingDateTime = getDateString(matingWorkListItem.workListData!.mating.date);
		workListTableItem.grade = matingWorkListItem.workListData!.grade.label;
		workListTableItem.cellColorStyle = getAnimalNumberColor(matingWorkListItem.listItemData.stemAnimalId!);
		matingWorkListTableItems.push(workListTableItem);
	});
	return new SkioldOneClient(SkioldDigitalApiBaseUrl)
		.workList_GenerateMatingWorkListPdf(matingWorkListTableItems, siteId, timeZoneId, lang)
		.then((fileResponse: FileResponse | null) => {
			savePdfAs(fileResponse, fileName);
		})
		.catch(() => {
			return;
		});
}

export function GetFarrowingWorkListPdf(
	items: any[],
	fileName: string,
	siteId: string,
	timeZoneId?: string,
	lang?: string,
) {
	let farrowingWorkListTableItems: FarrowingWorkListTableItem[] = [];
	items.forEach(element => {
		let workListTableItem = new FarrowingWorkListTableItem();
		workListTableItem.animalNumber = element.animalNumber;
		workListTableItem.cycleDays = element.cycleDays ? element.cycleDays.toString() : '';
		workListTableItem.expectedFarrowing = getDateString(element.expectedFarrowing);
		workListTableItem.batch = element.matingBatch;
		workListTableItem.cellColorStyle = getAnimalNumberColor(element.stemAnimalId!);
		farrowingWorkListTableItems.push(workListTableItem);
	});
	return new SkioldOneClient(SkioldDigitalApiBaseUrl)
		.workList_GenerateFarrowingWorkListPdf(farrowingWorkListTableItems, siteId, timeZoneId, lang)
		.then((fileResponse: FileResponse | null) => {
			savePdfAs(fileResponse, fileName);
		})
		.catch(() => {
			return;
		});
}

export function GetWeaningWorkListPdf(
	items: any[],
	fileName: string,
	siteId: string,
	timeZoneId?: string,
	lang?: string,
) {
	let WeaningWorkListTableItems: WeaningWorkListTableItem[] = [];
	items.forEach(weaningWorkListItem => {
		let workListTableItem = new WeaningWorkListTableItem();
		workListTableItem.animalNumber = weaningWorkListItem.listItemData.animalNumber;
		workListTableItem.cycleDays = weaningWorkListItem.listItemData.cycleDays
			? weaningWorkListItem.listItemData.cycleDays.toString()
			: '';
		workListTableItem.weaningDateTime = getDateString(weaningWorkListItem.workListData.Weaning.date);
		workListTableItem.numAlive = weaningWorkListItem.workListData.Weaning.numAlive;
		workListTableItem.totalWeight = weaningWorkListItem.workListData.Weaning.totalWeight;
		workListTableItem.isNursing = weaningWorkListItem.workListData.Weaning.isNursingSow;
		workListTableItem.commited = weaningWorkListItem.workListData.isChecked;
		workListTableItem.cellColorStyle = getAnimalNumberColor(weaningWorkListItem.listItemData.stemAnimalId!);
		WeaningWorkListTableItems.push(workListTableItem);
	});
	return new SkioldOneClient(SkioldDigitalApiBaseUrl)
		.workList_GenerateMovingWorkListPdf(WeaningWorkListTableItems, siteId, timeZoneId, lang)
		.then((fileResponse: FileResponse | null) => {
			savePdfAs(fileResponse, fileName);
		})
		.catch(() => {
			return;
		});
}
export function GetScanningWorkListPdf(
	items: ScanningListTableItem[],
	workListSetting: IScanningListSettingBase,
	fileName: string,
	siteId: string,
	timeZoneId?: string,
	lang?: string,
) {
	let scanningWorkListData: ScanningWorkListPdfData = new ScanningWorkListPdfData();
	scanningWorkListData.scanningWorkListTableItems = [];
	scanningWorkListData.scanningSubColumnHeaders = [];
	const shouldShowBuild = !isDistinctBuilding(items);
	const shouldShowPen = !isDistinctPen(items);
	items.forEach(scanningListTableItem => {
		let scanningItem = new ScanningWorkListTableItem();
		if (scanningListTableItem.litter) {
			scanningItem.litterNumber = scanningListTableItem.litter.toString();
		}
		scanningItem.cycleDays = scanningListTableItem.cycleDays ? scanningListTableItem.cycleDays.toString() : '';
		scanningItem.animalNumber = scanningListTableItem.animalNumber;
		scanningItem.batch = scanningListTableItem.batchNumber;
		scanningItem.cellColorStyle = getAnimalNumberColor(scanningListTableItem.stemAnimalId!);
		scanningItem.scanningSubColumns = [];
		scanningItem.buildingString = scanningListTableItem.buildingString;
		scanningItem.penString = scanningListTableItem.penString;
		scanningItem.sectionString = scanningListTableItem.sectionString;
		scanningItem.scanningSubColumns!.push(
			new ScanningSubColumn({
				scanResult: '',
				subTaskNumber: 0,
				weekNumber: scanningListTableItem.weeks,
			}),
		);
		scanningWorkListData.scanningWorkListTableItems!.push(scanningItem);
	});

	scanningWorkListData.showBuilding = shouldShowBuild;
	scanningWorkListData.showLocation = workListSetting.usesLocation;
	scanningWorkListData.showPen = shouldShowPen;
	return new SkioldOneClient(SkioldDigitalApiBaseUrl)
		.workList_GenerateScanningWorkListPdf(scanningWorkListData, siteId, timeZoneId, lang)
		.then((fileResponse: FileResponse | null) => {
			savePdfAs(fileResponse, fileName);
		})
		.catch(() => {
			return;
		});
}

export function GetAllAnimalsEsfWorkListPdf(
	items: AllAnimalsEsfWorkListItem[],
	fileName: string,
	siteId: string,
	timeZoneId?: string,
	lang?: string,
) {
	let WeaningWorkListTableItems: AllAnimalsEsfWorkListTableItem[] = [];

	const locationString = items && items.length >= 1 ? getLocationStringByPenId(items[0].penId) : '';
	const feedCurves = items && items.length >= 1 ? getFeedCurvesByPenId(items[0].penId) : '';
	items.forEach(workListItem => {
		const curve = feedCurves && feedCurves.find(c => c.feedCurveNumber === workListItem.selectedCurveNumber);
		let workListTableItem = new AllAnimalsEsfWorkListTableItem();
		workListTableItem.animalNumber = workListItem.animalNumber;
		workListTableItem.cycleDays = workListItem.cycleDays ? workListItem.cycleDays.toString() : '';
		workListTableItem.curve = curve ? `${curve.feedCurveNumber} - ${curve.feedCurveName}` : '';
		workListTableItem.feDay = workListItem.feDay ? workListItem.feDay : '0';
		workListTableItem.fixedPercent = workListItem.fixedPercentage ? workListItem.fixedPercentage.toFixed() : '0';
		workListTableItem.eatenToday = workListItem.eatenToday ? workListItem.eatenToday : '0';
		workListTableItem.currentLocation = locationString;
		workListTableItem.cellColorStyle = getAnimalNumberColor(workListItem.stemAnimalId!);
		WeaningWorkListTableItems.push(workListTableItem);
	});
	return new SkioldOneClient(SkioldDigitalApiBaseUrl)
		.workList_GenerateAllAnimalsEsfWorkListPdf(WeaningWorkListTableItems, siteId, timeZoneId, lang)
		.then((fileResponse: FileResponse | null) => {
			savePdfAs(fileResponse, fileName);
		})
		.catch(() => {
			return;
		});
}

export function GetShortageEsfWorkListPdf(
	items: ShortageEsfWorkListItem[],
	fileName: string,
	siteId: string,
	timeZoneId?: string,
	lang?: string,
) {
	let WeaningWorkListTableItems: ShortageEsfWorkListTableItem[] = [];
	items.forEach(workListItem => {
		let workListTableItem = new ShortageEsfWorkListTableItem();
		workListTableItem.animalNumber = workListItem.animalNumber;
		workListTableItem.cycleDays = workListItem.cycleDays ? workListItem.cycleDays.toString() : '';
		workListTableItem.curveToday = workListItem.curveToday ? workListItem.curveToday : '';
		workListTableItem.eatenToday = workListItem.eatenToday ? workListItem.eatenToday : '';
		workListTableItem.restToday = workListItem.restToday ? workListItem.restToday : '';
		workListTableItem.curveYesterday = workListItem.curveYesterday ? workListItem.curveYesterday : '';
		workListTableItem.eatenYesterday = workListItem.eatenYesterday ? workListItem.eatenYesterday : '';
		workListTableItem.restYesterday = workListItem.restYesterday ? workListItem.restYesterday : '';
		workListTableItem.currentLocation = workListItem.currentLocation ? workListItem.currentLocation : '';
		workListTableItem.cellColorStyle = getAnimalNumberColor(workListItem.stemAnimalId!);
		WeaningWorkListTableItems.push(workListTableItem);
	});
	return new SkioldOneClient(SkioldDigitalApiBaseUrl)
		.workList_GenerateAllAnimalsEsfWorkListPdf2(WeaningWorkListTableItems, siteId, timeZoneId, lang)
		.then((fileResponse: FileResponse | null) => {
			savePdfAs(fileResponse, fileName);
		})
		.catch(() => {
			return;
		});
}

export function GetMovingWorkListPdf(
	items: MovingListTable[],
	fileName: string,
	workListType:
		| 'MoveToPregnancyList'
		| 'MoveToFarrowingList'
		| 'MoveToMatingList'
		| 'MoveFromPregToPregSetting'
		| 'MoveMatingToMatingSetting',
	siteId: string,
	timeZoneId?: string,
	lang?: string,
) {
	let movingWorkListTableItemPdfItems: MovingWorkListTableItemPdf[] = [];
	items.forEach(movingWorkListTableItem => {
		let workListTableItem = new MovingWorkListTableItemPdf();
		workListTableItem.cycleDays = movingWorkListTableItem.cycleDays
			? movingWorkListTableItem.cycleDays.toString()
			: '';
		workListTableItem.animalNumber = movingWorkListTableItem.animalNumber;
		workListTableItem.cellColorStyle = getAnimalNumberColor(movingWorkListTableItem.stemAnimalId!);
		movingWorkListTableItemPdfItems.push(workListTableItem);
	});
	return new SkioldOneClient(SkioldDigitalApiBaseUrl)
		.workList_GenerateMovingWorkListPdf2(movingWorkListTableItemPdfItems, workListType, siteId, timeZoneId, lang)
		.then((fileResponse: FileResponse | null) => {
			savePdfAs(fileResponse, fileName);
		})
		.catch(() => {
			return;
		});
}

export function GetTasksInFarrowingStablePdf(
	items: TasksInFarrowingStableItem[],
	workListSetting: ITasksInFarrowingBase,
	fileName: string,
	siteId: string,
	timeZoneId?: string,
	lang?: string,
) {
	const filteredSubTasks = workListSetting.farrowingTaskTreatments!.filter(subTask => subTask.treatmentDefinitionId);
	const customHeader1 = filteredSubTasks.find(subTask => subTask.subTaskNumber === 1);
	const customHeader2 = filteredSubTasks.find(subTask => subTask.subTaskNumber === 2);

	const customHeader3 = filteredSubTasks.find(subTask => subTask.subTaskNumber === 3);

	const customHeader4 = filteredSubTasks.find(subTask => subTask.subTaskNumber === 4);

	let tasksInFarrowingStableItems: TasksInFarrowingStableItemPdf[] = [];
	items.forEach(tableItem => {
		let pdfTableItem = new TasksInFarrowingStableItemPdf();
		if (customHeader1) {
			const subTaskToColumn = tableItem.farrowingTaskTreatments!.find(subTask => subTask.subTaskNumber === 1);
			if (subTaskToColumn) {
				const col = new FarrowingTaskTreatmentColumn({
					amount: subTaskToColumn.amount!.toString(),
					pcs: subTaskToColumn.pcs!.toString(),
				});
				pdfTableItem.farrowingTaskTreatmentColumn1 = col;
			}
		}
		if (customHeader2) {
			const subTaskToColumn = tableItem.farrowingTaskTreatments!.find(subTask => subTask.subTaskNumber === 2);
			if (subTaskToColumn) {
				const col = new FarrowingTaskTreatmentColumn({
					amount: subTaskToColumn.amount!.toString(),
					pcs: subTaskToColumn.pcs!.toString(),
				});
				pdfTableItem.farrowingTaskTreatmentColumn2 = col;
			}
		}
		if (customHeader3) {
			const subTaskToColumn = tableItem.farrowingTaskTreatments!.find(subTask => subTask.subTaskNumber === 3);
			if (subTaskToColumn) {
				const col = new FarrowingTaskTreatmentColumn({
					amount: subTaskToColumn.amount!.toString(),
					pcs: subTaskToColumn.pcs!.toString(),
				});
				pdfTableItem.farrowingTaskTreatmentColumn3 = col;
			}
		}
		if (customHeader4) {
			const subTaskToColumn = tableItem.farrowingTaskTreatments!.find(subTask => subTask.subTaskNumber === 4);
			if (subTaskToColumn) {
				const col = new FarrowingTaskTreatmentColumn({
					amount: subTaskToColumn.amount!.toString(),
					pcs: subTaskToColumn.pcs!.toString(),
				});
				pdfTableItem.farrowingTaskTreatmentColumn4 = col;
			}
		}

		pdfTableItem.animalNumber = tableItem.animalNumber;
		pdfTableItem.cycleDays = tableItem.cycleDays ? tableItem.cycleDays.toString() : '';
		pdfTableItem.isChecked = tableItem.isChecked!;
		pdfTableItem.cellColorStyle = getAnimalNumberColor(tableItem.stemAnimalId!);
		tasksInFarrowingStableItems.push(pdfTableItem);
	});

	const pdfData = new TasksInFarrowingStableData({
		farrowingTaskTreatmentHeader1: customHeader1 ? customHeader1.columnText : undefined,
		farrowingTaskTreatmentHeader2: customHeader2 ? customHeader2.columnText : undefined,
		farrowingTaskTreatmentHeader3: customHeader3 ? customHeader3.columnText : undefined,
		farrowingTaskTreatmentHeader4: customHeader4 ? customHeader4.columnText : undefined,
		tasksInFarrowingStableItems,
	});

	return new SkioldOneClient(SkioldDigitalApiBaseUrl)
		.workList_TasksInFarrowingStablePdf(pdfData, siteId, timeZoneId, lang)
		.then((fileResponse: FileResponse | null) => {
			savePdfAs(fileResponse, fileName);
		})
		.catch(() => {
			return;
		});
}

export function GetVaccineWorkListPdf(
	items: VaccineWorkListItem[],
	workListSetting: IVaccineListSetting,
	fileName: string,
	siteId: string,
	timeZoneId?: string,
	lang?: string,
) {
	const colmunHeaders = workListSetting
		.vaccineSubTasks!.filter(subTask => subTask.vaccineDefinitionId)
		.map(subTask => new VaccineSubColumnHeader({ name: subTask.columnText, subTaskNumber: subTask.subTaskNumber }));
	const shouldShowBuild = !isDistinctBuilding(items);
	const shouldShowPen = !isDistinctPen(items);

	const pdfItems = items.map(
		item =>
			new VaccineWorkListTableItem({
				animalNumber: item.animalNumber,
				cycleDays: item.cycleDays ? item.cycleDays.toString() : '',
				batch: item.batch,
				litterNumber: item.litterNumber ? item.litterNumber.toString() : '',
				buildingString: item.buildingString,
				penString: item.penString,
				sectionString: item.sectionString,
				cellColorStyle: getAnimalNumberColor(item.stemAnimalId!),
				vaccineSubColumns: item.vaccineSubColumns!.map(
					subColumn =>
						new VaccineSubColumn({
							weekNumber: subColumn.weekNumber ? subColumn.weekNumber.toString() : '',
							subTaskNumber: subColumn.subTaskNumber!,
						}),
				),
			}),
	);

	const pdfData = new VaccineWorkListPdfData({
		vaccineSubColumnHeaders: colmunHeaders,
		vaccineWorkListTableItems: pdfItems,
		showBuilding: shouldShowBuild,
		showPen: shouldShowPen,
		showLocation: workListSetting.showLocationOnList,
	});

	return new SkioldOneClient(SkioldDigitalApiBaseUrl)
		.workList_VaccineWorkListPdf(pdfData, siteId, timeZoneId, lang)
		.then((fileResponse: FileResponse | null) => {
			savePdfAs(fileResponse, fileName);
		})
		.catch(() => {
			return;
		});
}

export function GetLardScanningEventWorkListPdf(
	items: LardScanningEventListTableItem[],
	fileName: string,
	siteId: string,
	timeZoneId?: string,
	lang?: string,
) {
	let workListTableItems: LardScanningEventWorkListTableItem[] = [];
	items.forEach(workListItem => {
		let workListTableItem = new LardScanningEventWorkListTableItem();
		workListTableItem.animalNumber = workListItem.animalNumber;
		workListTableItem.cycleDays = workListItem.cycleDays ? workListItem.cycleDays.toString() : '';
		workListTableItem.litterNumber = workListItem.litter ? workListItem.litter.toString() : '';
		workListTableItem.cellColorStyle = getAnimalNumberColor(workListItem.stemAnimalId!);
		workListTableItems.push(workListTableItem);
	});
	return new SkioldOneClient(SkioldDigitalApiBaseUrl)
		.workList_GenerateLardScanningEventWorkListPdf(workListTableItems, siteId, timeZoneId, lang)
		.then((fileResponse: FileResponse | null) => {
			savePdfAs(fileResponse, fileName);
		})
		.catch(() => {
			return;
		});
}
