import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { DashboardColumn, IDashboard } from 'shared/api/api';
import 'shared/helpers/global-declarations';
import { GetDashboard, UpsertDashboard } from 'shared/state/ducks/widgets/operations';
import { WebAppState } from 'web/state/store.web';
import PageContainer from '../page-container/page-container';
import './home.scss';
import { Spinner } from '../spinner/spinner';

interface HomeState {
	column?: number;
	details: { [id: string]: string } | null;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		dashboard: state.widgets.dashboard,
		navigation: state.navigation,
		isSyncing: state.offline.isSyncing,
	};
};
const mapDispatchToProps = (dispatch: Dispatch) => ({
	upsertDashboard: (dashboard: IDashboard) => UpsertDashboard(dashboard)(dispatch),
	getDashboard: (name: string) => GetDashboard(name)(dispatch),
});

type HomeProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class Home extends React.PureComponent<HomeProps, HomeState> {
	public UNSAFE_componentWillMount() {
		this.props.getDashboard(
			this.props.navigation.payload.dashboard ? this.props.navigation.payload.dashboard : 'Start',
		);
		this.setState({ details: null });
	}

	public updateColumnTitle(column: number, title: string) {
		const newWidgetColumns: DashboardColumn[] = [...this.props.dashboard!.columns!];
		newWidgetColumns[column] = new DashboardColumn({
			...newWidgetColumns[column],
			title: title.toUpperCase(),
		});
		const d = { ...this.props.dashboard, columns: newWidgetColumns };
		this.props.upsertDashboard(d);
	}

	public removeWidget(column: number, widgetIndex: number) {
		const newWidgetColumns: DashboardColumn[] = [...this.props.dashboard!.columns!];
		const newWidgets = [...newWidgetColumns[column].widgets!];
		newWidgets.splice(widgetIndex, 1);
		newWidgetColumns[column] = new DashboardColumn({
			...newWidgetColumns[column],
			title: newWidgetColumns[column].title!.toUpperCase(),
			widgets: newWidgets,
		});
		const d = { ...this.props.dashboard, columns: newWidgetColumns };
		this.props.upsertDashboard(d);
	}

	public removeColumn(column: number) {
		if (this.props.dashboard!.columns!.length === column + 1) {
			alert('You cannot remove this column.');
			return;
		}
		const newWidgetColumns: DashboardColumn[] = [...this.props.dashboard!.columns!];
		newWidgetColumns.splice(column, 1);
		const d = { ...this.props.dashboard, columns: newWidgetColumns };
		this.props.upsertDashboard(d);
	}

	public render() {
		return (
			<PageContainer className="home">
				{this.props.isSyncing && <Spinner />}
			</PageContainer>
		);
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(Home);
