import React from 'react';
import { Option } from 'react-dropdown';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ClearAnalysisData } from 'shared/state/ducks/analysis/operations';
import { GetAnalysisSettingsBySiteId } from 'shared/state/ducks/analysis/settings/operations';
import { localized } from 'shared/state/i18n/i18n';
import styled from 'styled-components';
import AnalysisDepartureOverview from 'web/view/components/analysis/analysis-departure/analysis-departure-overview';
import AnalysisDeparturePiglet from 'web/view/components/analysis/analysis-departure/analysis-departure-piglet';
import AnalysisResultsPerLitterOverview, {
	AnalysisResultsPerLitterOverview as AnalysisResultsPerLitterOverviewRef,
} from 'web/view/components/analysis/analysis-result-per-litter/analysis-results-per-litter-overview';
import AnalysisResultsPregnancyOverview from 'web/view/components/analysis/analysis-result-pregnancy/analysis-results-pregnancy-overview';
import AnalysisResultsYoungFemaleOverview, {
	AnalysisResultsYoungFemaleOverview as AnalysisResultsYoungFemaleOverviewRef,
} from 'web/view/components/analysis/analysis-result-young-female-age/analysis-result-young-female-age-overview';
import PageContainer from 'web/view/components/page-container/page-container';
import { SkioldDropdown } from 'web/view/components/skiold-components/skiold-dropdown/skiold-dropdown';
import { SkioldFetch } from 'web/view/components/skiold-components/skiold-fetch/skiold-fetch';
import { Heading } from 'web/view/components/utils/heading';
import { ViewWeb } from 'web/view/components/utils/web-view';
import './analysis-overview.scss';

const OuterDiv = styled.div`
	margin: 0px 16px;
`;

type OverviewRefs = AnalysisResultsPerLitterOverviewRef | AnalysisResultsYoungFemaleOverviewRef;

export interface State {
	selectedAnalysis: Option;
	analysisOptions: Option[];
	renderTopRight: JSX.Element;
	renderTopLeft?: JSX.Element;
	dateFrom?: Date;
	dateTo?: Date;
}

const mapDispatchToProps = (dispatch: Dispatch) => {
	return {
		GetAnalysisSettingsBySiteId: () => GetAnalysisSettingsBySiteId()(dispatch),
		ClearAnalysisData: () => ClearAnalysisData()(dispatch),
	};
};

type Props = ReturnType<typeof mapDispatchToProps>;

export class AnalysisOverviewOverview extends React.PureComponent<Props, State> {
	private overviewRef?: OverviewRefs;
	constructor(props: Props) {
		super(props);
		props.GetAnalysisSettingsBySiteId();
		this.state = {
			selectedAnalysis: { value: 'analysisResultsPerLitter', label: localized('analysisResultsPerLitter') },
			renderTopRight: <ViewWeb />,
			renderTopLeft: undefined,
			analysisOptions: [
				{ value: 'analysisResultsPerLitter', label: localized('analysisResultsPerLitter') },
				{ value: 'analysisResultsYoungFemale', label: localized('analysisResultsYoungFemale') },
				{ value: 'analysisResultsPregnancy', label: localized('analysisResultsPregnancy') },
				{ value: 'analysisDepartureSow', label: localized('analysisDepartureSow') },
				{ value: 'AnalysisDeparturePiglets', label: localized('AnalysisDeparturePiglets') },
			],
		};
	}

	public componentWillUnmount() {
		this.props.ClearAnalysisData();
	}

	public render() {
		return (
			<PageContainer className="analysisOverview">
				<Heading text={localized('ChooseAnalysis')} />
				<OuterDiv>
					<ViewWeb className="analysis-top-container">
						{/* Left header column */}
						<ViewWeb className="sizing vertical-center">
							{this.state.renderTopLeft ? (
								this.state.renderTopLeft
							) : (
								<SkioldFetch
									defaultEmpty={true}
									toDate={this.state.dateTo}
									fromDate={this.state.dateFrom}
									fetchData={this.fetchData}
									toDateChange={this.setToDate}
									fromDateChange={this.setfromDate}
									isControlled={true}
								/>
							)}
						</ViewWeb>

						{/* Center header column */}
						<ViewWeb className="align-center">
							<SkioldDropdown
								onValueChanged={this.selectedAnalysisChanged}
								selectedValue={this.state.selectedAnalysis}
								items={this.state.analysisOptions}
								containerClassName="select-report-type sizing"
							/>
						</ViewWeb>

						{/* Right header column */}
						<ViewWeb className="end sizing vertical-center">{this.state.renderTopRight}</ViewWeb>
					</ViewWeb>
					<ViewWeb className="viewContainer">
						{this.state.selectedAnalysis.value === 'analysisResultsPerLitter' && (
							<AnalysisResultsPerLitterOverview
								ref={this.setRef as any}
								topRight={this.setTopRight}
								topLeft={this.setTopLeft}
							/>
						)}
						{this.state.selectedAnalysis.value === 'analysisResultsYoungFemale' && (
							<AnalysisResultsYoungFemaleOverview
								ref={this.setRef as any}
								dateTo={this.state.dateTo}
								dateFrom={this.state.dateFrom}
								topRight={this.setTopRight}
							/>
						)}
						{this.state.selectedAnalysis.value === 'analysisResultsPregnancy' && (
							<AnalysisResultsPregnancyOverview
								ref={this.setRef as any}
								topLeft={this.setTopLeft}
								topRight={this.setTopRight}
							/>
						)}
						{this.state.selectedAnalysis.value === 'analysisDepartureSow' && (
							<AnalysisDepartureOverview
								ref={this.setRef as any}
								topLeft={this.setTopLeft}
								topRight={this.setTopRight}
							/>
						)}
						{this.state.selectedAnalysis.value === 'AnalysisDeparturePiglets' && (
							<AnalysisDeparturePiglet
								ref={this.setRef as any}
								topLeft={this.setTopLeft}
								topRight={this.setTopRight}
							/>
						)}
					</ViewWeb>
				</OuterDiv>
			</PageContainer>
		);
	}

	public setToDate = (toDate: Date) => {
		this.setState({ dateTo: toDate });
	};

	public setfromDate = (fromDate: Date) => {
		this.setState({ dateFrom: fromDate });
	};

	public fetchData = (dateFrom: Date, dateTo: Date) => {
		if (this.overviewRef && dateFrom && dateTo) {
			this.overviewRef.fetchData(dateFrom, dateTo);
		}
	};

	private setRef = (ref?: OverviewRefs) => (this.overviewRef = ref);

	private setTopLeft = (res: JSX.Element) => {
		this.setState({ renderTopLeft: res });
	};

	private setTopRight = (res: JSX.Element) => {
		this.setState({ renderTopRight: res });
	};

	private selectedAnalysisChanged = (option: Option) => {
		if (option.value !== this.state.selectedAnalysis.value) {
			this.props.ClearAnalysisData();
			this.setState({
				selectedAnalysis: option,
				renderTopLeft: undefined,
				dateFrom: undefined,
				dateTo: undefined,
			});
			this.props.ClearAnalysisData();
		}
	};
}

export default connect(null, mapDispatchToProps)(AnalysisOverviewOverview);
