import { Dispatch } from 'redux';
import {
	Averted,
	IMoveEvent,
	IMoveToMatingLocation,
	IPregnancyEvent,
	LocationType,
	PregnancyState,
	PregnancyValidationType,
	SowState,
} from 'shared/api/api';
import { getDateString } from 'shared/helpers/date-helpers';
import { calculateDays } from 'shared/helpers/general-helpers';
import { getStemAnimalPen, ILocationModel } from 'shared/helpers/location-helper';
import { memoizeLocation, memoizeValidationSettingPlan } from 'shared/helpers/memoize-getters/memoize-getters';
import { getActiveSows } from 'shared/helpers/stemanimal-helper/stem-animal-input-helper';
import { getState } from 'shared/helpers/stemanimal-helper/stemanimal-helper';
import {
	checkLocation,
	checkSectionWorkLists,
	getEventDate,
	InsertOldDataSelectedSettings,
	movingListsCheckCycleDaysPregnancyDaysByPregnancyEvents,
	setMovelistTableItemData,
} from 'shared/helpers/work-list-helpers/move-to-helpers/moving-lists-helper';
import { GetLocations } from 'shared/state/ducks/locations/operations';
import * as matingBatchOperation from 'shared/state/ducks/mating-batch/operations';
import * as moveEventOperation from 'shared/state/ducks/move-events/operations';
import { SaveMoveEvent } from 'shared/state/ducks/move-events/operations';
import * as pregnancyOperation from 'shared/state/ducks/pregnancy-events/operations';
import * as stemAnimalOperation from 'shared/state/ducks/stem-animals/operations';
import { GetSyncData } from 'shared/state/ducks/work-list-settings/operations';
import { SharedAppState } from 'shared/state/store.shared';
import { MovingListTable } from './move-to-data-model';

export interface MoveToMatingListState {
	locationString: string | undefined;
	moveToSetup: IMoveToMatingLocation;
	locations: ILocationModel | undefined;
	toSectionId?: string;
	selectedDate?: Date;
	toPenId?: string;
	selectedHeaderSectionUsesPens: boolean;
	moveListData: MovingListTable[];
	moveListInitData: MovingListTable[];
}

export const moveToMatingListMapStateToProps = (state: SharedAppState) => {
	return {
		workListSettings: state.workListSettings.entities,
		pregnancyEvets: state.pregnancyEvents.entities,
		matingBatches: state.matingBatches.entities,
		// feedCurves: state.site.site.feedCurves, // Feed curves is something that is missing in move lists in general, due to ESF need to be implemented for this to be relevant
		siteId: state.profile.active!.siteId!,
		moveEvents: state.moveEvents.entities,
		locations: memoizeLocation(
			state.locations.buildings,
			state.locations.sections,
			state.locations.pens,
			state.locations.valves,
		),
		stemAnimals: state.stemAnimals.entities,
		validationSettingPlan: memoizeValidationSettingPlan(
			state.validationSetup.entity,
			PregnancyValidationType.AvertedToMating,
		),
	};
};

export const moveToMatingListMapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	saveMoveEvent: (moveEvent: IMoveEvent) => SaveMoveEvent(moveEvent)(dispatch),
	getWorkListSettingsBySiteId: () => GetSyncData()(dispatch),
	pregnancyEventGetSyncData: () => pregnancyOperation.GetSyncData()(dispatch),
	stemAnimalGetSyncData: () => stemAnimalOperation.GetSyncData()(dispatch),
	matingBatchGetSyncData: () => matingBatchOperation.GetSyncData()(dispatch),
	moveEventGetSyncData: () => moveEventOperation.GetSyncData()(dispatch),
	getLocations: () => GetLocations()(dispatch),
});

export type MoveToMatingListProps = ReturnType<typeof moveToMatingListMapStateToProps> &
	ReturnType<typeof moveToMatingListMapDispatchToProps>;

export function getDataByPregnancyDaysMoveToMatingList(
	moveEvents: IMoveEvent[],
	locations: ILocationModel,
	pregEvents: { [key: string]: IPregnancyEvent[] },
	moveListData: MovingListTable[],
	moveToSetup: IMoveToMatingLocation,
	toSectionId: string | undefined,
	toPenId: string | undefined,
	validateSettingToSubtract: number | undefined,
) {
	let ActiveSows = getActiveSows();
	let data: MovingListTable[] = [];
	for (let sow of ActiveSows) {
		if (!sow.id) {
			continue;
		}
		let state = getState(sow.id!);
		let animalPregEvents = pregEvents[sow.id!];
		if (
			!animalPregEvents ||
			state !== SowState.Dry ||
			checkLocation(getStemAnimalPen(sow, moveEvents, locations.pens), locations, LocationType.Mating)
		) {
			continue;
		}
		const filteredPregnancyEvents = pregEvents[sow.id!];

		const eventDate = getEventDate(filteredPregnancyEvents);
		const lastestPregEvent = filteredPregnancyEvents.find(preg => preg.date!.toString() === eventDate.toString());
		let cycleDays = movingListsCheckCycleDaysPregnancyDaysByPregnancyEvents(moveToSetup, pregEvents[sow.id]);
		if (
			!filteredPregnancyEvents ||
			!lastestPregEvent ||
			lastestPregEvent.state !== PregnancyState.Averted ||
			cycleDays === undefined
		) {
			continue;
		}
		let moveToWorkListItem = new MovingListTable();
		const negativeCycleDays = validateSettingToSubtract && cycleDays
			? cycleDays - validateSettingToSubtract : 0
		setMovelistTableItemData(sow, moveToWorkListItem, cycleDays, negativeCycleDays);
		let { usesPens } = checkSectionWorkLists(toSectionId, toPenId);
		moveToWorkListItem.sectionId = toSectionId;
		moveToWorkListItem.penId = toPenId;
		moveToWorkListItem.usesPens = usesPens;
		data.push(moveToWorkListItem);
	}
	InsertOldDataSelectedSettings(data, moveListData);
	return data;
}

export function getDataByWeaningDateMoveToMatingList(
	moveEvents: IMoveEvent[],
	locations: ILocationModel,
	pregEvents: { [key: string]: IPregnancyEvent[] },
	moveListData: MovingListTable[],
	weaningDate: Date,
	toSectionId: string | undefined,
	toPenId: string | undefined,
	validateSettingToSubtract: number | undefined,
) {
	let ActiveSows = getActiveSows();
	let data: MovingListTable[] = [];
	for (let sow of ActiveSows) {
		let state = getState(sow.id!);
		let animalPregEvents = pregEvents[sow.id!];
		if (
			!animalPregEvents ||
			state !== SowState.Dry ||
			checkLocation(getStemAnimalPen(sow, moveEvents, locations.pens), locations, LocationType.Mating)
		) {
			continue;
		}
		const filteredPregnancyEvents = pregEvents[sow.id!];
		const lastestPregEvent = filteredPregnancyEvents.find(
			preg =>
				getDateString(preg.date!) === getDateString(weaningDate!) &&
				preg.state === PregnancyState.Averted &&
				(preg as Averted).isNursingSow === false,
		);
		if (!filteredPregnancyEvents || !lastestPregEvent) {
			continue;
		}
		let moveToWorkListItem = new MovingListTable();
		const cycleDays = calculateDays(weaningDate!, new Date());
		const negativeCycleDays = validateSettingToSubtract && cycleDays
			? cycleDays - validateSettingToSubtract : 0
		setMovelistTableItemData(sow, moveToWorkListItem,cycleDays,negativeCycleDays);
		let { usesPens } = checkSectionWorkLists(toSectionId, toPenId);
		moveToWorkListItem.sectionId = toSectionId;
		moveToWorkListItem.penId = toPenId;
		moveToWorkListItem.usesPens = usesPens;
		data.push(moveToWorkListItem);
	}
	InsertOldDataSelectedSettings(data, moveListData);
	return data;
}
