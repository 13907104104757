import { ILardScanningEvent } from "shared/api/api";
import { Syncable } from "shared/state/models/syncable";


export const SAVE_LARD_EVENT = 'SAVE_LARD_EVENT';
export const REMOVE_LARD_EVENT = "REMOVE_LARD_EVENT";
export const SYNC_LARDS_EVENTS = 'SYNC_LARDS_EVENTS';
export const GET_DEPARTURED_LARD_EVENTS = 'GET_DEPARTURED_LARD_EVENTS';

// tslint:disable-next-line: interface-name

export interface LardScanningEventState extends Syncable<ILardScanningEvent> {
    departuredLardEvents: ILardScanningEvent[];
}

