import { IGrowthPigsDashboardDto, DashboardElaborationTableItem } from 'shared/api/api';
import { TableKeyValue } from 'web/view/components/skiold-components/skiold-table/skiold-table-grouped-grid/skiold-table-grouped-grid-helper';

export const GET_GROWTH_PIGS_DASHBOARD_OVERVIEW_TABLE = 'getGrowthPigsDashboardOverviewTable';
export const SET_SUMMARY_ITEM = 'SET_SUMMARY_ITEM';
export const SET_DASHBOARD_ITEMS = 'SET_DASHBOARD_ITEMS';
export interface DashboardGrowthPigsOverviewState {
	loading: boolean;
	data?: IGrowthPigsDashboardDto[];
	summaryItems: { [key: string]: TableKeyValue[] };
	dashboardItems: { [key: string]: DashboardElaborationTableItem[] };
}
