import { IUserProfile } from "shared/api/api";
import { Syncable } from "shared/state/models/syncable";

export const ACTIVATE = 'ACTIVATE';
export const ACTIVATE_SITE_ADMIN = 'ACTIVATE_SITE_ADMIN';
export const GET_PROFILE = 'GET_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const SITE_CHANGE = 'SITE_CHANGE';
export const MODULE_CHANGED = 'MODULE_CHANGED';
export const SYNC_DATA_USERPROFILE = 'SYNC_DATA_USERPROFILE';

export const SET_IS_RESETTING_STATE = 'SET_IS_RESETTING_STATE';

export interface Profile extends IUserProfile {
    name: string;
}

export interface ProfileState extends Syncable<IUserProfile> {
    active?: IUserProfile;
    isResettingState: boolean;

}
