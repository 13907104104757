export default {
	// Tool used to object conversion https://wtools.io/convert-js-object-to-csv
	// TRanspose det generate csv file 
	// General terms
	SkioldDigital: 'SKIOLD digital',
	Clear: 'Clear',
	locale: 'en-gb',
	reset: 'Reset',
	site: 'Site',
	enterprise: 'Enterprise',
	building: 'Building',
	section: 'Section',
	buildingShort: 'Build',
	sectionShort: 'Sec',
	Unit: 'Unit',
	Save: 'Save',
	Show: 'Show',
	Edit: 'Edit',
	Handled: 'Handled',
	Delete: 'Delete',
	Cancel: 'Cancel',
	Count: 'Count',
	Weight: 'Weight',
	AnimalWeight: 'Animal weight',
	FeedWeight: 'Food weight',
	Result: 'Result',
	Saved: 'Saved',
	Overdue: 'Overdue',
	Print: 'Print',
	Today: 'Today',
	Tomorrow: 'Tomorrow',
	SaveChanges: 'Save Changes',
	ChooseDate: 'Choose Date',
	stable: 'Stable',
	pen: 'Pen',
	ERROR: 'ERROR',
	Confirm: 'Confirm',
	Close: 'Close',
	avg: 'Avg.',
	Gender: 'Gender',
	Done: 'Done',
	Waiting: 'Waiting',
	Running: 'Running',
	Paused: 'Paused',
	Cancelled: 'Cancelled',
	Deleted: 'Deleted',
	kg: 'kg',
	AreYouSureYouWantToCancel: 'Are you sure you want to cancel?',
	NoProcessesText: "You don't have access to any processes in this module...",
	PleaseWait: 'Please wait',
	Saving: 'Saving',
	Retry: 'Retry',
	Ok: 'Ok',
	TableNoData: 'Table contains no data',
	Previous: 'Previous',
	Next: 'Next',
	Loading: 'Loading',
	January: 'January',
	February: 'February',
	March: 'March',
	April: 'April',
	May: 'May',
	June: 'June',
	July: 'July',
	August: 'August',
	September: 'September',
	October: 'October',
	November: 'November',
	December: 'December',
	PunctualDaySuccessor: '', // not used in English
	Animals: 'Animals',
	week: 'Week',
	from: 'From',
	to: 'To',
	ml: 'ml',
	outOf: 'out of',
	back: 'Back',
	failed: 'failed',
	bluetooth: 'Bluetooth',
	WillYouContinue: 'Will you continue?',
	PlanAndGoal: 'Plan/goal',
	DefaultWeaningWeight: 'Average weaning weight',
	allocateWeight: 'Allocate weight',
	totalWeanedInPeriod: 'Total weaned in period',
	totalWeightInPeriod: 'Total weight in period',
	InitialsShort: 'Init',
	Email: 'Mail address',
	or: 'or',
	Initials: 'Initials',
	PrintGeneralData: 'General data',
	PrintMomDadData: 'Dad/mom data',

	//Countries
	Denmark: 'Denmark',
	Taiwan: 'Taiwan, Province of China',
	Australia: 'Australia',
	Sweden: 'Sweden',
	Ecuador: 'Ecuador',
	Norway: 'France',
	France: 'France',
	'South Africa': 'South Africa',

	// Modules
	'Sow Module': 'Sows',
	'Climatepigs Module': 'Climatepigs',
	'Finisher Module': 'Finishers',
	'Settings Module': 'Settings',
	'Status Module': 'Status Module',
	'Developer Module': 'Developer Module',
	'Pig management': 'Pig management',
	ControllerStatus: 'Controller status',
	'App Info Module': 'App Info Module',
	'Adjust Feed': 'Adjust Feed',
	'Delivery Module': 'Delivery Module',
	'Delivery In': 'Delivery In',
	'Stock Module': 'Stock Module',
	'Alarm Module': 'Alarms',
	'Warning Module': 'Warnings',
	LicencedModules: 'Licenced modules',
	Events: 'Events',
	addMedicine: 'Add Medicine',
	addMedicineSow: 'Add Medicine',
	addMedicineWeaner: 'Add Medicine',
	addMedicineFinisher: 'Add Medicine',
	registerTreatmentWeaner: 'Register treatment',
	treatmentListWeaner: 'Treatment List',
	registerTreatmentFinisher: 'Register treatment',
	treatmentListFinisher: 'Treatment List',
	ClimateEvents: 'Events',
	weanerPigsEvents: 'Events',
	finisherPigsEvents: 'Events',
	pregnancyEventLists: 'Event list',
	weanerPigsEventsList: 'Event list',
	finisherPigsEventLists: 'Event list',
	finishersManagement: 'Management Finishers',
	sowManagement: 'Management Sows',
	weanersManagement: 'Management Weaners',
	adminPanel: 'Admin',
	// Home2 module icons
	developer: 'developer',
	order: 'Order',
	deliveries: 'Deliveries',
	status: 'Status',
	settings: 'Settings',
	treatments: 'Treatments',
	alarms: 'Alarms',
	climate: 'Climate',
	warnings: 'Warnings',
	stock: 'Stock',
	plannedOrders: 'Planned orders',
	reOrders: 'Re-orders',
	unmanagedOrders: 'Unmanaged orders',
	plannedDeliveries: 'Planned deliveries',
	unmanagedDeliveries: 'Unmanaged Deliveries',
	feedingHistory: 'Feeding history',
	profile: 'Profile',
	online: 'Online',
	offline: 'Offline',
	activities: 'Activities',
	tasks: 'Tasks',
	silos: 'Silos',
	sows: 'Sows',
	sowLardWidth: 'Lard width',
	lardScanning: 'Lard scanning',
	pigs: 'Pigs',
	distriwin: 'Distriwin',

	'Alarms by Definition': 'Alarms by Definition',
	'Warnings by Definition': 'Warnings by Definition',
	'total alarms': 'Total alarms',
	'total warnings': 'Total warnings',
	'total active time': 'Total time in active',
	'average active time': 'Average time in active',
	'Feeding System': 'Feeding System',
	'Milling System': 'Milling System',
	slaughterRetention: 'Retention',
	SowManagementSetup: 'Sows',
	WeanerManagementSetup: 'Weaners',
	FinisherManagementSetup: 'Finishers',
	userManagementSettings: 'User management',
	AnalysisProcessFinisher: 'Analisys',
	DashboardProcessFinisher: 'Dashboard',
	AnalysisProcessWeaner: 'Analisys',
	DashboardProcessWeaner: 'Dashboard',
	// Sow module processes
	addsow: 'Add sow',
	removesow: 'Remove sow',
	movesow: 'Move sow',
	registertreatment: 'Register treatment',
	startadjustfeed: 'Adjust feed',
	startadjustfeedSow: 'Adjust feed',
	startadjustfeedFinisher: 'Adjust feed',
	startadjustfeedWeaner: 'Adjust feed',
	retryAdjustFeedMenuTitle: 'Adjust feed changes',
	assigntask: 'Assign task',
	treatmentlists: 'Treatment lists',
	departurelist: 'Departure list',
	DepartureWorkListSetting: 'Departure list',
	events: 'Events',
	createTreatment: 'Create Treatment',
	treatmentList: 'Treatment List',
	AddMedicineApp: 'Add drug/vaccine',
	TreatmentAppList: 'Create treatments/retreatments',
	TreatmentWebList: 'Treatments-/vaccination definitions',
	sowCard: 'Sow card',
	foodlists: 'Food lists',
	analysisandreports: 'Analysis & reports',

	// Deliveries modules processes
	deliveryIn: 'Delivery IN',
	deliveryOut: 'Delivery OUT',

	// language&profile
	language: 'Language',
	english: 'English',
	swedish: 'Swedish',
	danish: 'Danish',
	change: 'Change',
	name: 'Name',
	en: 'English',
	da: 'Danish',
	logout: 'Log out',
	Login: 'Log in',
	CHANGEPASSWORD: 'Change password',
	EDITPROFILE: 'Edit profile',

	// overlay menu icons
	OVERLAYPIG: 'Climatepigs and finishers',
	OVERLAYSOW: 'Sows',
	OVERLAYFINISHER: 'Finishers',
	OVERLAYWEANER: 'Weaners',
	OVERLAYSTOCK: 'Stock',
	OVERLAYSETTINGS: 'Settings',
	OVERLAYADMIN: 'Admin',
	OVERLAYSTABLE: 'Stable',
	OVERLAYFEEDMILLS: 'Feed Mills',
	OVERLAYVALVES: 'Valves',
	OVERLAYNUMBERS: 'Numbers',
	OVERLAYOUTPUT: 'Output',
	OVERLAYGROWTH: 'Growth',
	OVERLAYCLIMATE: 'Climate',
	OVERLAYREPORTS: 'Reports',
	return: 'Return',
	USER_MANAGEMENT: 'User management',
	NOACCESSROUTE: 'No access to this route.',

	// Admin menu
	ADMIN_DEPARTURE_REASONS: 'Departure reasons',
	ADMIN_DIAGNOSES: 'Diagnoses',
	ADMIN_DIAGNOSES_CATEGORIES: 'Diagnose Categories',
	ADMIN_DRUG_TYPES: 'Drug Types',
	ADMINROUTE: 'This is a Admin route.',
	ADMIN_MODULE_LICENCES: 'Module licences',
	ADMIN_FEED_REGISTRATIONS: 'Feed registrations',
	ADMIN_BREED_TABLE: 'Breed table',

	// Sow Management menues
	SOWANIMALS: 'Animals',
	SOWEVENTS: 'EVENTS',
	SowEvents: 'Events',
	EVENTS: 'Events',
	SOWTREATMENTS: 'Treatments',
	SOWMEDICIN: 'Medicin/Diagnoses',
	SOWDEPARTUREREASONS: 'Departure Reasons',
	SOWSETTINGS: 'Settings',

	//Pig management menues
	PIGSETTINGS: 'Settings',

	//Diagnose Picker
	selectDiagnose: 'Select Diagnose/Vaccination',

	// Adjust feed process
	startroute: 'Start route',
	adjustfeed: 'Adjust Feed',
	retryAdjustFeed: 'Failure adjust feed',
	fetchDataFromDistriwin: 'Fetch data from distriwin',
	FetchDataFromDistriwinConfirmDialog:
		'You are now in the process of retrieving latest data on all valves in Distriwin. The update can take 5-10 minutes. Do you want to continue?',
	Time: 'Time',
	FE_Day_Pig: 'FU/Day/pig',
	FE_Day_Sow: 'FU/Day/Sow',
	Avr_Day: 'Avr./day',
	'CONST DEV': 'FIXED',
	DEV: 'VAR.',
	DEVIATION: 'VARIABLE',
	DEVIATIONDAYS: 'VARIABLE DAYS',
	DEVPERCENT: 'VAR%',
	DEVIATIONDAYSPERCENT: 'VAR% - DAYS',
	Sows: 'Sows',
	Pigs: 'Pigs',
	DAYS: 'DAYS',
	WEEK: 'Week',
	SECTION: 'SECTION',
	FEEDINGCURVE: 'Curve',
	UNNAMEDFEEDINGCURVE: 'Unnamed',
	NoHistoryAvailable: 'There is no change history available',
	FeedingCurveAndRecept: 'Curve/recipe',
	Recipe: 'Recipe',
	fullSyncFailedMessage: 'Try again, or contact SKIOLD',
	wentThrough: 'went through',

	deleteMarked: 'Delete marked',
	moveMarked: 'Move marked',
	SureDeleteMarked: 'Are you sure you want to delete all marked?',
	sendMarked: 'Send Marked',

	ConstantDeviation: 'Fixed deviation',
	Deviation: 'Deviation',
	DeviationDays: 'Deviation days',
	FeedingCurve: 'Feeding Curve',
	SowCycle: 'Cycle days',
	PenDays: 'Pen days',
	PigCount: 'Pig count',
	pigCount: 'Pig count',
	PigletCount: 'Piglet count',
	PrevValue: 'Old',
	NewValue: 'New',
	Property: 'Type',
	NoAdjustmentsToShow: ' No adjustments to show',
	SkipFeeding: 'Skip feeding',

	// Warnings and Alarms modules
	RECENT_WARNINGS_CAPS: 'WARNINGS',
	RECENT_ALARMS_CAPS: 'ALARMS',
	details: 'Details',
	DistriWin: 'DistriWin',
	FlexMixPro: 'FlexMixPro',

	'Recent Alarms': 'Alarms',
	'Recent Warnings': 'Warnings',
	Date: 'Date',
	retentionDate: 'Retention date',
	Event: 'Event',
	History: 'History',

	addwidget: 'ADD WIDGET',
	nowidget: 'You have no more widget available!',
	HISTORY: 'HISTORY',
	HISTORYLast7Days: 'HISTORY last 7 days',
	'ADJUST FEED': 'ADJUST FEED',
	'SELECT SECTION': 'SELECT SECTION',

	// Events
	selectevent: 'Select event',
	entrance: 'Entrance',
	entry: 'Entry',
	departure: 'Departure',
	select: 'Select',
	animalNumber: 'Animal no.',
	animalNumberShorten: 'Anim no.',
	'Select Section': 'Select Section',
	animalNumbers: 'Animal numbers',
	setEntranceDateToMatingDate: 'Set entrance date to mating date ',

	PEN: 'PEN',
	VALVE: 'VALVE',
	SOWS: 'SOWS',
	ANIMALS: 'ANIMALS',
	FE_DAY: 'FU/DAY',
	FIXED: 'FIXED',
	FIXEDPERCENT: 'FIXED%',
	fixedPercent: 'FIXED %',
	WEIGHT: 'WEIGHT',

	// Entrance
	entranceType: 'Entrance Type',
	entranceTypeBought: 'Bought',
	entranceEventTypeBought: 'Bought',
	entranceTypeFromYoungAnimal: 'From young animal',
	entranceTypeFromPolt: 'From polts',
	entranceGender: 'Gender',
	selectEntranceType: 'Select entrance type',
	entranceMating: 'Mating',
	ChooseIdNumber: 'Choose Id Number',
	Litters: 'Litters',
	LitterNr: 'Litter nr.',
	EntrancePrice: 'Entrance Price',
	CreateAnimal: 'Create Animal',
	PrintSowTable: 'Print Sowtable',
	PrintList: 'Print list',
	searchYoungAnimal: 'Search Young Animals',
	searchAnimal: 'Search animal',
	youngAnimalNotFound: 'Young animal not found',
	entranceTypeFromYoungFemale: 'From young female',
	ValidateAnimalNumberLength: 'A maximum of 6 characters is allow, to validate for Danbred',
	ValidateWrongUsageNumber: 'The young animal has usage code "{{area}}" which is not allowed for Nucleus Management',
	ValidateWrongIdNumber: 'ID number must be {{area}} characters, to be allowed by {{area2}}',
	ValidateAge: 'The animal has to be {{area}} days old, to be allowed by {{area2}}',
	ExistingTransponder: 'Existing transponder',
	NewTransponder: "New transponder",
	ChangeTransponderNumber: "Change transponder",
	// Location Picker
	choosenLocationIsEmpty: 'Chosen location is empty',
	chooseStableSection: 'Choose Stable - Section',
	StableSection: 'Stable - Section',
	FromStableSection: 'From Stable - Section',
	MoveToLocation: 'Move to location',
	FromLocation: 'From location',
	ToLocation: 'To location',
	FromPen: 'From pen',
	ToPen: 'To pen',
	choosePen: 'Choose Pen',
	SITEANDFARM: 'SITE/FARM',
	BUILDING: 'BUILDING',
	country: 'Country',
	cvRnum: 'CVRnr.',
	chRnum: 'CHRnr.',
	ownerName: 'Name',
	ownerAddress: 'Address',
	ownerZipCode: 'Zip code',
	ownerCity: 'City',
	siteAddress: 'Address',
	siteZipCode: 'Zip code',
	siteName: 'Name',
	siteCity: 'City',
	SiteName: 'Site/Farm',
	address: 'Address',
	postalCode: 'Postalcode',
	ZipCode: 'ZIP-code.',
	Owner: 'Owner',
	city: 'City',
	chrNum: 'CHRnr.',
	createBuilding: 'Create building',
	createSite: 'Create site',
	number: 'Number',
	units: 'Units',
	amountOfSections: 'Amount of sections',
	startNumber: 'Start number',
	amountOfPens: 'Amount of pens',
	capacity: 'Capacity',
	addSections: 'Add sections',
	addPens: 'Add pens',
	chooseCountry: 'Choose country',
	isUsingQRCode: 'Using QR code',
	isUsingSections: 'Using sections',
	isUsingPens: 'Using pens',
	letterBehindPen: 'Letter behind pen no.',
	NameBehindStartNumber: 'Name (In front of section nr.)',
	longmg: 'per kilo',
	longml: 'per liter',

	chooseLocation: 'Choose location',
	Active: 'Active',
	Inactive: 'Inactive',

	Valve: 'Valve',
	ValveNr: 'Valve Number',
	Pen: 'Pen',

	OK: 'OK',

	Title: 'Title',
	Location: 'Location',
	LocationShort: 'Loc',
	currentLocation: 'Current location',
	newLocation: 'New location',
	Status: 'Status',
	Description: 'Description',
	'How to Fix': 'How to Fix',
	'No available curves': 'No available curves',
	'Key performance indicators': 'Key performance indicators',
	Value: 'Value',
	Num: 'Num',
	Type: 'Type',
	Add: 'Add',
	sort: 'Sort',
	EDITSECTION: 'EDIT SECTION',
	EDITBUILDING: 'EDIT BUILDING',
	locationOverview: 'Location overview',
	siteSetup: 'Site setup',
	siteChrError: 'CHRnum is already used by another farm',
	peqCodeError: 'Location code is already used by another farm',

	// Departure
	removeDeparture: 'Remove departure',
	departureTypeSold: 'Sold',
	departureTypeDead: 'Dead',
	departureTypeKilled: 'Slaughtered',
	departureTypeToBeKilled: 'To be killed',
	departureTypeShouldDeparture: 'Should departure',
	departureTypePutDown: 'Killed',
	departureReason: 'Reason',
	departureType: 'Departure Type',
	selectDepartureType: 'Departure Type',
	putDown: 'Killed',
	departurePrice: 'Departure price',
	selectMarking: 'Select Marking',
	selectReason: 'Select Reason',
	Days: 'Days',
	Quantity: 'Quantity',
	marking: 'Marking',

	// ordered translations
	AdepartureTypeKilled: 'Slaughtered',
	BdepartureTypeDead: 'Dead',
	CdepartureTypePutDown: 'Killed',
	DdepartureTypeSold: 'Sold',

	// Active sows
	animalNr: 'Animal nr.',
	kind: 'Kind',
	condition: 'Condition',
	entranceDate: 'Entrance date',
	Batch: 'Batch',
	transponder: 'Transponder',
	Teats: 'Teats',
	price: 'Price',
	idNumber: 'ID number',
	birthdate: 'Birthdate',
	Breed: 'Breed',
	dadNr: 'Dad nr.',
	dadNumber: 'Dad number',
	selectDadNumber: 'Select dad number',
	dadID: 'Dad id',
	momNr: 'Mom nr.',
	momNumber: 'Mom number',
	selectMomNumber: 'Select mom number',
	momID: 'Mom id',
	index: 'Index',
	indexDate: 'Index date',
	departureDate: 'Departure date',
	age: 'Age',
	CreateSow: 'Create Sow',
	CHOOSELIST: 'CHOOSE LIST',
	cycleDays: 'Cycle days',
	minCycleDays: 'Min Cycle days',
	maxCycleDays: 'Max Cycle days',
	DryDays: 'Dry days',
	moveToMatingWeaningDryDays: 'Weaning, dry days',
	moveToMatingWeaningDate: 'Weaning, date',
	dadRace: 'Dad race',
	momRace: 'Mom race',
	activeSowPdf: 'Active sow.pdf',
	UsageCode: 'Usage code',
	DadMomPdf: 'dad-mom-data.pdf',
	activeYoungAnimals: 'Active young animals.pdf',
	// Animals
	activeSows: 'Active sows',
	departuredSows: 'Departed sows',
	boars: 'Boars',
	youngAnimals: 'Young animals',
	activeBoars: 'Active boars',
	departedBoars: 'Departed boars',
	departedYoungAnimals: 'Departed young Animals',

	// Gender
	female: 'Sow',
	male: 'Boar',

	// Kinds
	youngFemale: 'Young Female',
	gilt: 'Gilt',
	boar: 'Boar',
	Unknown: 'Unknown',
	YoungFemale: 'Young Female',
	Gilt: 'Gilt',
	Sow: 'Sow',
	Boar: 'Boar',
	Piglet: 'Piglet',
	YoungAnimal: 'Young Animal',
	youngFemales: 'Young Females',
	sowBoars: 'Sows/Boars',
	AnimalKinds: 'Animal Kinds',

	// Import
	chooseImportFile: 'Choose file',
	chosenFile: 'Chosen file',
	import: 'Import',
	export: 'Export',
	startImport: 'Start import',
	specifyWeight: 'Specify weight',

	// Export
	ChooseFilePath: 'Choose file path',
	ExportYoungAnimals: 'Export young animals',

	// Markings
	Marking: {
		Blue: 'Blue',
		Red: 'Red',
		Green: 'Green',
		Black: 'Black',
		White: 'White',
		EarmarkRemoved: 'Earmark Removed',
		EarmarkAdded: 'Earmark Added',
	},

	DrugTypes: 'Drug Types',
	DrugType: 'Drug Type',
	AddDrugType: 'Add Drug Type',
	AddToDrugType: 'Add Drug Type',
	DrugTypeNotChoosen: 'Choose Drug type',

	// Reasons
	createReason: 'Create reason',
	reasonName: 'Reason name',
	reasonNameDa: 'Reason name danish',
	reasonNameEn: 'Reason name english',
	reasonCode: 'Priority code',
	editReason: 'Edit reason',
	reasons: 'Reasons',
	reason: 'Reason',
	stemAnimalDeparture: 'Departure',
	reasonDead: 'Dead',
	reasonPutDown: 'Put down',

	// Pig Types
	piglets: 'Piglets',
	polts: 'Polts',

	// Diagnose
	DIAGNOSE_CATEGORIES: 'DIAGNOSE CATEGORIES',
	Diagnose: 'Diagnose',
	categories: 'Categories',
	addCategory: 'Add Category',
	category: 'Diagnose Category',
	categoryNameEn: 'Category Name (en)',
	categoryNameDa: 'Category Name (da)',
	priority: 'Priority',
	delete: 'Delete',
	DIAGNOSES: 'DIAGNOSES',
	addDiagnose: 'Add Diagnose',
	diagnoseNameDa: 'Diagnose Name (da)',
	diagnoseNameEn: 'Diagnose Name (en)',
	diagnoseDescriptionDa: 'Diagnose Description (da)',
	diagnoseDescriptionEn: 'Diagnose Description (en)',
	diagnoses: 'Diagnoses',
	DeleteDiagnosePrompt: 'Really delete the diagnose? This cannot be undone.',
	isVaccination: 'Is Vaccination',
	diagnoseName: 'Diagnose',
	pigTypes: 'Pig Types',
	pigType: 'Pig Type',
	diagnoseType: 'Diagnose Type',
	vaccine: 'Vaccine',
	treatment: 'Treatment',
	create: 'Create',
	selectDiagnoseDescription: 'Pick diagnose/vaccination',
	selectAnimalKind: 'Pick the animal kind',

	//Analysis
	Analysis: 'Analysis',
	matingsFirstMating: 'Mating date 1. mating',
	AllFinishedLitters: '\u2022 Thereof weaned litter, pcs.',
	AllNurseWeanings: '\u2022 Thereof nursing sows litter, pcs.',
	analysisResultsPerLitter: 'Results distributed per litter',
	analysisResultsYoungFemale: 'Result of young female age at mating',
	analysisElaborationYoungFemaleAge: 'Details young female age at mating',
	ChooseAnalysis: 'Choose analysis',
	AllWeanings: 'Weanings, pcs.',
	AvgNursingSow: 'Percentage nursing sows',
	All: 'All',
	Some: 'Some',
	None: 'None',
	Weeks: 'Weeks',
	AgePeriod: 'Age column',
	Elaboration: 'Elaboration',
	SaveSettings: 'Save settings',
	MatingDate: 'Mating date',
	TotalPeriod: 'Total period',
	PercentageRematings: 'Avg. Remated',
	PercentageDeparture: 'Avg. Departed',
	MatingsInTotal: 'Matings in total',
	AnalysisResult: {
		//Matings
		Matings: 'Matings',
		TotalMatings: 'Total Matings',
		TotalFirstMatingToLitter: '1. Mating, pcs.',
		DistributedMatingPercentage: 'Distributed Matings pct.',
		ReturnSows: 'Rematings, pcs.',
		PercentageReturnSows: 'Pct. rematings',
		DepartureNonPregnant: 'Not pregnant, pcs.',
		ThereOfAbortions: 'Abortion, pcs.',
		TotalDepartures: 'Total departures, pcs.',
		DepartureKilledThisLitter: 'Sold/slaughtered, pcs.',
		DepartureDeadThisLitter: 'Dead/killed, pcs.',
		WeaningToFirstMating: 'Weaning to 1. mating, days',
		MatingToNonPregnantDays: 'Mating to not pregnant, days',
		MatingToRemating: 'Mating to remating, days',
		MatingToDeparture: 'Mating to departure, days',
		//Farrowings
		Farrowings: 'Farrowings',
		TotalFarrowings: 'Farrowings, pcs.',
		AvgAlive: 'Aliveborn, pcs.',
		AvgDead: 'Stillborn, pcs.',
		DistributedFarrowingsPercentage: 'Distributed Farrowings, pct.',
		StillbornToAliveRate: 'Stillborn in pct. of total born',
		FarrowingRate: 'Farrowing rate',
		NeedFarrowingFirstLitter: 'Need farrowing 1. litter, pcs.',
		AvgPregnantDays: 'Pregnancy days',

		//Weanings
		Weanings: 'Weanings',
		AllWeanings: 'Weanings, pcs.',
		AllFinishedLitters: '\u2022 Thereof weaning litters, pcs.',
		AllNurseWeanings: '\u2022 Thereof nursing sows, pcs.',
		AvgWeanedPerLitter: 'Weaned per litter, pcs.',
		AvgWeanedPerWeaninig: 'Weaned per weaning, pcs.',
		AvgWeight: 'Weight per piglet, kg.',
		AvgNursingSow: 'Percentage nursing sow',
		TotalWeanedPiglets: 'Total piglets, pcs.',
		DeadToAliveRate: 'Dead till weaning, pct',
		NeedWeaningFirstLitter: 'Need weaning 1. litter, pcs.',
		AvgNursingDays: 'Nursing days',
		//MatingPregnancy
		PercentageRematings: 'Avg. Remated',
		PercentageDepartured: 'Avg. Departed',
		AvgDays: 'Avg. Days',
		Percentage: 'Pct.',
	},
	ResultYoungFemaleFirstMating: 'Young female age',
	WeaningToMating: 'Weaning to mating',
	MatingToNonPregnant: 'Mating to non pregnant',
	MatingToMating: 'Mating to mating',
	MatingTo: 'Mating to',
	Departure: 'Departure',
	Abortion: 'Abortion',
	FromTo: 'From - to',
	IntervalDays: 'Interval in days',
	analysisResultsPregnancy: 'Pregnancy analysis',
	AvgDays: 'Avg. days',
	Percentage: 'Pct.',
	MatingToAbortionAndDeparture: 'Mating to abortion and departure',
	analysisDepartureSow: 'Departure types/departure reasons Sows',
	AnalysisDeparturePiglets: 'Departure reasons piglets',
	AnalysisDepartureAnimalType: 'Departure reasons {{area}}',
	AnalysisDeparture: {
		NumberOfAnimals: 'Number of animals',
		PctAnimalLitterNumber: 'PCT. ANIMALS PER LITTER NUMBER',
		CountAnimalLitterNumber: 'NUMBER OF ANIMALS PER LITTER NUMBER',
		PctAnimalWeek: 'PCT. ANIMALS PER WEEK FOR PERIOD',
		CountAnimalWeek: 'NUMBER OF ANIMALS PER WEEK FOR PERIOD',
	},
	TotalPct: 'Total %',
	TotalWeek: 'Number of animals pr week',
	TotalLitter: 'Number of animals pr litter',
	TotalBorn: 'Number of alive born',
	MortalityRate: 'Pct. dead of born',

	//DashboardOverView
	year: 'year',
	overTen: 'Over 10',
	sowsGilt: 'Sows/gilt',
	goalYear: 'Goal',
	overGoalYear: 'Over under',
	averageYear: 'Average year',
	DASHBOARD: 'Dashboard',
	Dashboard: 'Dashboard',
	ageByFold: 'Age by litter',
	DaysToMatingFrom: 'Days to mating from',
	DaysFromMatingToDeparture: 'Days from mating to departure',
	SoldOrKilled: 'Sold/slaughter',
	Sold: 'Sold',
	soldOrKilled: 'Sold/slaughter',
	deadOrPutDown: 'Dead/Killed',
	DeadOrPutDown: 'Dead/Killed',
	notPregnant: 'Not pregnant',
	AnimalsToFarrowingTable: 'Matings,  animals to farrowing',
	TotalMatingsTable: 'Mating, total matings',
	TotalDepartureTable: 'Matings, total departures',
	TotalFarrowingsTable: 'Farrowings, total farrowings',
	NeedFarrowingTable: 'Farrowings, need farrowing',
	NeedWeaningTable: 'Weaning, need weaning',
	TotalWeaningsTable: 'Weaning: total weaned',
	AliveBorn: 'aliveborn',
	weightPerPigDashboard: 'Weight per pig',
	weanedPerLitterDashboard: 'Weaned per litter',
	weaningDateDashboard: 'Weaning date',
	achievedYear: 'Achieved',
	VALIDATION_ERROR_INTERVALS_MAY_NOT_OVERLAP: 'Intervals in a given section, may not overlap',
	fromMatingBatch: 'From mating batch',
	toMatingBatch: 'To mating batch',
	weaningToMating: 'Weaning to mating',
	matingToNotPregnant: 'Mating to non pregnant',
	matingToMating: 'Mating to mating',
	matingToAbortion: 'Mating to abortion',
	abortionToMating: 'Abortion to mating',
	matingToSoldOrKilled: 'Mating to sold/slaughtered',
	matingTodeadOrPutDown: 'Mating to dead/killed',
	matingsFrom: 'Matings from',
	DashboardSubGoal: 'Sub goal for feed usage/weight at',
	DashboardExpectedWeight: 'Expected weight at sale',
	weekBatchPdf: 'Week batch {{area}}',
	ElaborationMatingBatch: 'Elaboration mating batch {{area}}',
	DeadPiglets: 'Dead piglets',
	PctDeadPiglets: 'Pct. dead piglets of live born',

	//DashboardSettings
	Dates: 'Dates',
	rollingYear: 'Rolling 365 days',
	PerYear: 'Per year',
	matingsPcs: 'Matings pcs.',
	percentageYoungAnimalMatings: '% Young animal matings',
	percentageReMatings: '% rematers',
	daysFromWeaningToFirstMating: 'Days from weaning to first mating',
	averageWasteDaysPerMatingBatch: 'Avg. wastedays per mating batch',
	farrowingsPcs: 'Farrowings pcs.',
	aliveBornPerFarrowingPcs: 'Aliveborn per farrowing, pcs.',
	aliveBornPerFarrowingFirstLegPcs: 'Aliveborn per farrowing 1. læg, pcs.',
	deadBornPerFarrowingPcs: 'Deadborn per farrowing, pcs.',
	weanedPerFarrowingPcs: 'Weaned per farrowing, pcs.',
	weightPerWeanedPig: 'Weight per weaned pig, kg (1 dec.)',
	stillbornToAliveRate: 'Stillborn in pct. of total born',
	daysPerMatingBatch: 'Days per mating batch',
	deviationPercent: 'Deviation by %',
	dashboardDefinition: 'Year definition for Dashboard:',
	startAtMatingBatch: 'Start at mating batch',
	endAtMatingBatch: 'End with mating batch',

	//Treatmentplan
	selectTreatmentDefinition: 'Diagnose',
	noDrugTypesForAnimalTypeVaccine:
		'You have to add a drug of type vaccine to the stock for this animal kind, to create a new vaccine definition',
	noDrugTypesForAnimalTypeMedicine:
		'You have to add a drug of type medicine to the stock for this animal kind, to create a new diagnose definition',
	weightLabel: 'Weight',
	registerTreatment: 'Register treatment',
	registerToBeTreated: 'To be treated',
	VALIDATION_ERROR_NoDiagnoseSet: 'Diagnose required',
	VALIDATION_ERROR_CHECKALL_OR_SELECTPEN: 'Select pen or select all in section',
	VALIDATION_ERROR_NoMarkingSet: 'Marking required',
	VALIDATION_ERROR_NoWeightSet: 'Weight required',
	AmountOfPiglets: 'Amount of piglets',
	AmountOfPigletsShort: 'Piglets',
	DrugAmount: 'Drug Amount',
	DrugUseForAnimals: 'Drug use for animal',
	DrugAmountShort: 'Amount',
	DrugAmountPerPig: 'Drug Amount per pig',
	Dosage: 'Dosage',
	EditTreatment: 'Edit treatment',
	SelectTreatedMoved: 'How many treated pigs should be moved?',
	CantRegisterANursingSow: 'You can not register a nursing sow',
	// Error messages
	SYNC_ERROR: 'Something went wrong, data might not be synced. Try again by reentering this page',
	VALIDATION_ERROR_MISSING_AGE_PERIOD: ': You need to define age period for young female',
	VALIDATION_ERROR_AGE_TO_IS_LESS_THAN_FROM_DATE: ': To age may not be less than from age',
	VALIDATION_ERROR_DRUGSTOCK_REASON_REQUIRED: 'You need to state the reason for the waste',
	VALIDATION_ERROR_DRUGSTOCK_USE_ADD_INSTEAD: 'Use add instead',
	VALIDATION_ERROR_DRUGSTOCK_ERROR_EDITING_WASTE_HAS_TO_BE_NEGATIVE: 'Waste is negative',
	VALIDATION_ERROR_WEIGHT_REQUIRED_FOR_DEPARTED: 'You are trying to move pigs, without defining their weight',
	VALIDATION_ERROR_ANIMAL_IS_NOT_PREGNANT_ANYMORE_AND_ANIMAL_IS_ON_PREGNANT_LOCATION:
		'You have to move the animal to a location, that is not a pregnancy location',
	VALIDATION_ERROR_ANIMAL_NEEDS_TO_BE_PREGNANT_TO_MOVE_TO_THIS_LOCATION:
		'The animal needs to be pregnant, to be moved to a pregnancy location',
	VALIDATION_ERROR_ESF_IS_ENABLED_AND_TRANSPONDER_IS_REQUIRED: 'No transponder chosen',
	VALIDATION_ERROR_DRUGSTOCK_ERROR_EDITING_ADJUSTMENT_HAS_TO_BE_POSITIVE: 'Adjustments is Positive',
	VALIDATION_ERROR_DRUGSTOCK_NOT_SELECTED: 'No drug type selected',
	VALIDATION_ERROR_FEEDINGSTOCK_NOT_SELECTED: 'Feeding subject is required',
	VALIDATION_ERROR_FEEDINGSTOCK_AMOUNT: 'No amount chosen',
	VALIDATION_ERROR_FEEDINGSTOCK_PRICE: 'No price chosen',
	VALIDATION_ERROR_NoFeedingSubjectChoosen: 'You need to choose feeding subject',
	VALIDATION_ERROR_NoFeedingAnimalTypeChoosen: 'You need to choose animal type',
	VALIDATION_ERROR_FEEDINGSTOCK_FESOW: 'FUsow has to be chosen - 0 is a legal value',

	VALIDATION_ERROR_FEEDINGSTOCK_FEPIG: 'FUpig has to be chosen - 0 is a legal value',
	VALIDATION_ERROR_AMOUNT_NOT_SET: 'Amount not set',
	VALIDATION_ERROR_FEEDINGSTOCK_FOSFOR: 'No Phosphor chosen',
	VALIDATION_ERROR_TOTAL_AMOUNT_TO_LOW_TO_CONTAIN_ALREADY_USED_FOOD: 'Your amount needs to be at least: ',
	VALIDATION_ERROR_TOTAL_AMOUNT_AVAILABLE_WILL_BE_LOWER_THAN_TOTALUSED:
		'Your total amount of this feeding subject, will be lower than your total used',
	VALIDATION_ERROR_DRUGSTOCK_AMOUNT: 'No amount chosen',
	VALIDATION_ERROR_DRUGSTOCK_DATE: 'No date selected ',
	VALIDATION_ERROR_TRANSPONDER_ALREADY_IN_USE_BY_SOW: 'Transponder number already in use by animal nr. ',
	VALIDATION_ERROR_ANIMAL_NUMBER_ALREADY_IN_USE_BY_SOW: 'Animal number is already in use',
	VALIDATION_ERROR_AnimalNumberNotSet: 'Animal number not set',
	VALIDATION_ERROR_AnimalNumberNotFound: 'Animal number does not exist',
	VALIDATION_ERROR_AnimalNumberIsDepartured: 'Animal number is departed',
	VALIDATION_ERROR_AnimalNumberExists: 'Animal no. already exists',
	VALIDATION_ERROR_EntranceDateNotSet: 'Entrance date not set',
	VALIDATION_ERROR_PenIdNotSet: 'Pen not set',
	VALIDATION_ERROR_STABLE_SECTION_NOT_SET: 'Stable - section not set',
	VALIDATION_ERROR_NEW_PEN_ID_NOT_SET: 'Pen not set',
	VALIDATION_ERROR_MOVE_STABLE_SECTION_NOT_SET: 'Move stable - section not set',
	VALIDATION_ERROR_MOVE_NEW_PEN_ID_NOT_SET: 'Move pen not set',
	VALIDATION_ERROR_SiteNotSet: 'Site not set',
	VALIDATION_ERROR_MatingEqualsOneLitter: 'Litter must be at least one when mating is set',
	VALIDATION_ERROR_EntranceTypeNotSet: 'Entrance type not set',
	VALIDATION_ERROR_AnimalNotFound: 'Animal not found',
	VALIDATION_ERROR_DepartureTypeNotSet: 'Departure type not set',
	VALIDATION_ERROR_DepartureDateNotSet: 'Departure date not set',
	VALIDATION_ERROR_DATE_NOT_SET: 'Date not set',
	VALIDATION_ERROR_LARD_WIDTH_NOT_SET: 'Lard width not set',
	VALIDATION_ERROR_DepartureBoarIsKs: 'Boar is an KS boar',
	VALIDATION_ERROR_DepartureDateMostAfterCurrentDate: 'Departure date most be after current date',
	VALIDATION_ERROR_DASHBOARD_ENDMATINGBATCH_NEED_TO_BE_LATER:
		'You need to set the end mating batch to be later than current date',
	VALIDATION_ERROR_DASHBOARD_NEED_LATER_ENDMATINGBATCH:
		'New year required for dashboard – settings, Sow management, Plan/goal for production.',
	VALIDATION_ERROR_NameNotSet: 'Name not set',
	VALIDATION_ERROR_UnitNotSet: 'Unit not set',
	VALIDATION_ERROR_TransponderOccupied: 'Transponder occupied',
	VALIDATION_ERROR_BothNamesRequired: 'Both names are required',
	VALIDATION_ERROR_CategoryRequired: 'Category is required',
	VALIDATION_ERROR_CategoryInUse: "Can't delete category already in use on a diagnose",
	VALIDATION_ERROR_FieldsRequired: 'All fields are required',
	VALIDATION_ERROR_NoDrugId: 'Choose a drug',
	VALIDATION_ERROR_AnotherVaccineAlreadyExist: 'A defined vaccine already exists',
	VALIDATION_ERROR_CannotBeNegative: 'Values cannot be negative',
	VALIDATION_ERROR_CannotBeNegativeOrZero: 'Values cannot be negative or zero',
	VALIDATION_ERROR_COUNTRYID_REQUIRED: 'Farm requires a country',
	VALIDATION_ERROR_CHRNUM_REQUIRED: 'Farm requires a CHR number',
	VALIDATION_ERROR_SITE_NAME_REQUIRED: 'Farm requires a name',
	VALIDATION_ERROR_SITE_ADDRESS_REQUIRED: 'Farm requires a address',
	VALIDATION_ERROR_SITE_ZIPCODE_REQUIRED: 'Farm requires a zipcode',
	VALIDATION_ERROR_SITE_CITY_REQUIRED: 'Farm requires a city',
	VALIDATION_ERROR_OWNER_NAME_REQUIRED: 'Owner requires a name',
	VALIDATION_ERROR_OWNER_ADDRESS_REQUIRED: 'Owner requires a address',
	VALIDATION_ERROR_OWNER_ZIPCODE_REQUIRED: 'Owner requires a zipcode',
	VALIDATION_ERROR_OWNER_CITY_REQUIRED: 'Owner requires a city',
	VALIDATION_ERROR_CVRNUM_REQUIRED: 'Owner requires a CVR number',

	VALIDATION_ERROR_CategoryNotSet: 'No category chosen',
	VALIDATION_ERROR_NoDiagnoseID: 'No diagnose',
	VALIDATION_ERROR_NoAnimalKinds: 'No animal kinds set',
	VALIDATION_ERROR_NoDrugTypeIsChoosed: 'Drug not set',
	VALIDATION_ERROR_NoFrequency: 'Frequency not set',
	VALIDATION_ERROR_NoNumberOfTreatments: 'Number of treatments not set',
	VALIDATION_ERROR_RefreshError: 'Error, reload page',
	VALIDATION_ERROR_NoRetentionTime: 'Retention time not set',
	VALIDATION_ERROR_NoTreatmentType: 'Treatment type not set',
	VALIDATION_ERROR_NoDrugAmountValue: 'Drug amount not set',
	VALIDATION_ERROR_NoPerUnit: 'Per unit not set',
	VALIDATION_ERROR_NoUnitType: 'Unit type not set',
	VALIDATION_ERROR_BatchNotSet: 'Batch not set',
	VALIDATION_ERROR_FinishedDateNotSet: 'Date not set',
	VALIDATION_ERROR_MatingDateAndYoungFemaleError: 'Entrance date of animal, is after mating date',
	VALIDATION_ERROR_InvalidAgeInterval: 'The young females age is out of range ',
	VALIDATION_ERROR_InvalidPregnancyEvent: 'Invalid pregnancy event',
	VALIDATION_ERROR_HasNoPreviousEvent: 'No previous pregnancy event',
	VALIDATION_ERROR_SowAlreadyPregnant: 'Sow ís already pregnant',
	VALIDATION_ERROR_DateIsBeforePreviousEvent: 'Date is set earlier than previous event',
	VALIDATION_ERROR_InvalidMatingToFarrowingInterval: '{{area}} days between mating and Farrowing is invalid',
	VALIDATION_ERROR_RegistrationErrorPregnancyEvent: 'An error occurred while registering pregnancy event',
	VALIDATION_WARNING_CHANGING_SECTION_OR_BUILDING_WILL_RESULT_IN_RESET_FOR_CHANGED_BUILDINGS_SECTIONS:
		'You have changed the relation between Distriwin and SKIOLD digital locations, this results in valves no longer is connected to a location. From the location overview you have to yet again connect valves to a location. Save the changes?',
	VALIDATION_WARNING_SECTION_NOT_BELONGING_TO_SECTION_SELECTED_HAS_NON_SETTED_PENS:
		'Data can not be saved, due to the fact that there are sections not belonging to the selected section without connected valves, will you correct this?',
	VALIDATION_WARNING_YOU_ARE_CREATING_A_USAGE_THAT_IS_IN_ANOTHER_USAGE_PERIOD:
		'You are creating a period, that is in another period: ',
	VALIDATION_ERROR_NoAbortionIfAlivePigs: 'Unable to abort, if pigs alive',
	VALIDATION_ERROR_InvalidFarrowingToWeaningInterval: 'Farrowing to weaning interval is invalid',
	VALIDATION_ERROR_SET_WHAT_TO_TREAT: 'You have to choose if its all all pigs that have to be treated or only boars',
	VALIDATION_ERROR_InvalidWeaningToMatingInterval: 'Weaning to Mating interval is invalid',
	VALIDATION_ERROR_GiltMatingDateError: 'When animalkind is gilt, mating date need to be earlier than entrance date',
	VALIDATION_ERROR_PregnantDaysNotSet: 'Pregnant days is not set',
	VALIDATION_ERROR_NursingDaysNotSet: 'Nursing days is not set',
	VALIDATION_ERROR_SHOW_ANIMAL_ON_LIST: 'There is no values for show animal on the list',
	VALIDATION_ERROR_StartDateIsNotSet: 'Start date is not set',
	VALIDATION_ERROR_WeekCycleIsNotSet: 'Week cycle is not set',
	VALIDATION_ERROR_CycledaysNotSet: 'Cycledays is not set',
	VALIDATION_ERROR_MatingPeriodIsNotSet: 'Mating period is not set',
	VALIDATION_ERROR_PregnantAndNursingDaysExceeded: 'Pregnant days + nursing days exceeds sow cycle weeks',
	VALIDATION_ERROR_NoMatingBatchesGenerated: 'There is no generated mating batches',
	VALIDATION_ERROR_EndDateNotSet: 'No end date has been generated',
	VALIDATION_ERROR_OverlapsOtherSettingsDates: 'Start or end date for settings, overlaps another',
	VALIDATION_ERROR_DateDoesNotMatchAMatingBatch:
		'Chosen date does not match a mating batch. Please setup mating batches  ',
	VALIDATION_ERROR_RegistrationErrorOnMatingBatch: 'An error occurred while registering mating batch',
	VALDIATION_ERROR_UnknownTreatmentDefinitionSet: 'The treatment definition is unknown by the server',
	VALIDATION_ERROR_TreatmentPlannedDateNotSet: 'Treatment is missing planned date',
	VALIDATION_ERROR_TreatmentSiteIdNotSet: 'Treatment is missing site id',
	VALIDATION_ERROR_ScanCantBeSameDateAsMated: 'Cannot scan sow, the same day as mating',
	VALIDATION_ERROR_StartDateIsEarlierThanCurrentDate: 'Start date needs to be later than current date',
	VALIDATION_ERROR_NewStartDateMustBeEqualToExisting:
		'Start date should later than todays date and equal a calculated mating start date',
	VALIDATION_ERROR_NoneAlive: 'No living animal with that number',
	VALIDATION_ERROR_TransponderInUse: 'Transponder already in use',
	VALIDATION_ERROR_SowDoesNotExist: 'Sow does not exist',
	VALIDATION_ERROR_AnimalNotSet: 'Animal not set',
	VALIDATION_ERROR_BuildingNumberNotSet: 'Building number is not set',
	VALIDATION_ERROR_BuildingNameNotSet: 'Building name is not set',
	VALIDATION_ERROR_ProductionFormNotSet: 'Production form not set',
	VALIDATION_ERROR_PRODUCTION_TYPE_NOT_SET: 'Production type not set',
	VALIDATION_ERROR_COUNT_NOT_SET: 'Count not set',
	VALIDATION_ERROR_AVG_WEIGHT_NOT_SET: 'Avg. weight not set',
	VALIDATION_ERROR_BuildingNumberAlreadyExists: 'Building number already Exists',
	VALIDATION_ERROR_SectionTypeNotSet: 'Section type not set',
	VALIDATION_ERROR_DuplicateSectionNumbers: 'Unable to generate sections, because of section number duplication',
	VALIDATION_ERROR_FEEDINGSTOCK_NO_FEEDING_SUBJECT_SELECTED: 'You need to choose feeding subject',
	VALIDATION_ERROR_DuplicatePenNumbers: 'Unable to generate pens, because of pen number duplication',
	VALIDATION_ERROR_RegistrationError: 'An error occurred on registration',
	VALIDATION_ERROR_HasNoSection: 'Has no section',
	VALIDATION_ERROR_HasNoBuilding: 'Has no building',
	VALIDATION_ERROR_SetupValidationSetup: 'Please setup validation intervals under sow settings',
	VALIDATION_ERROR_InvalidPregnantPeriod: 'Invalid pregnant period. Interval need to be between 100 - 125 days',
	VALIDATION_ERROR_invalidPlanPeriod: 'Invalid plan period. Interval need to be between plan - and plan +',
	VALIDATION_ERROR_InvalidNursingPeriod: 'Invalid nursing period. Cannot be less than 21 days',
	VALIDATION_ERROR_LocationNotSet: 'Location is not set',
	VALIDATION_ERROR_InvalidFarrowingAlive: 'Alive boar og sow, does not match total alive pigs',
	VALIDATION_ERROR_IdNumberNotSet: 'ID number is not set.',
	VALIDATION_ERROR_WeaningNumAliveIsNotSet: 'Weaning pigs is not set',
	VALIDATION_ERROR_MoveDateNotSet: 'Move date is not set',
	VALIDATION_ERROR_NewPenIdNotSet: 'Pen is not set',
	VALIDATION_ERROR_NewPenSameAsCurrent: 'Chosen pen is the current pen',
	VALIDATION_ERROR_FarrowingNumAliveNotSet: 'Num alive is not set',
	VALIDATION_ERROR_BuildingIsNotEmpty: 'Cannot delete, because building is not empty',
	VALIDATION_ERROR_SectionIsNotEmpty: 'Cannot delete, because section is not empty',
	VALIDATION_ERROR_MatingBatchOutOfRange: 'Mating batches does not reach that far in the past',
	VALIDATION_ERROR_NotAllowedToDeleteDiagnose: 'Diagnose still in use on a farm',
	VALIDATION_ERROR_DiagnoseAnimalKindStillInUse: 'Diagnose animal type still in use on a farm',
	VALIDATION_ERROR_ColumnTextIsNotSet: 'If diagnose is chosen, write column text',
	VALIDATION_ERROR_BoarProcentageIsNotSet: 'If boar is chosen, write boar %',
	VALIDATION_ERROR_NursingFromIsEmptyOrNegative: 'Nursing from is required, and cannot be negative',
	VALIDATION_ERROR_NursingToIsEmptyOrNegative: 'Nursing to is required, lower than nursing from',
	VALIDATION_ERROR_PregnantFromIsEmptyOrNegative: 'Pregnant from is required, and cannot be negative',
	VALIDATION_ERROR_PregnantToIsEmptyOrNegative: 'Pregnant to is required, lower than nursing from',
	VALIDATION_ERROR_NursingDaysAreRequiered: 'Nursing days are required',
	VALIDATION_ERROR_AvailableOnNeedsToBeSet: 'List available on needs to be set',
	VALIDATION_ERROR_ChoosePregnancyEvent: 'Choose pregnancy event',
	VALIDATION_ERROR_IncludeLittersTo: 'Up to litter number needs to be set',
	VALIDATION_ERROR_AfterEventDaysGreaterThanZero: 'Days after event needs to be greater than zero',
	VALIDATION_ERROR_ChooseAtleastOneTreatmentDef: 'Choose atleast one treatment definition',
	VALIDATION_ERROR_ChooseAnimalNotSet: 'Choose animal is not set',
	VALIDATION_ERROR_FEEDINGSTOCK_NO_FEEDING_SUBJECT_NAME: 'Type in feed subject name',
	VALIDATION_ERROR_AMOUNT_EXCEED_STOCK: 'Amount exceeds current feed stock',
	VALIDATION_ERROR_NO_CODE_SET: 'Code is not set',
	VALIDATION_ERROR_INVALID_CODE_SET: 'Choose another code',
	VALIDATION_ERROR_DEFAULT_CODE_SET: "Default code won't be accepted",
	VALIDATION_ERROR_NO_UNITTYPE_SET: 'Choose units',
	VALIDATION_ERROR_NO_PROCESS_EQUIPMENT_SET: 'Choose process equipment',
	VALIDATION_ERROR_InsertWithoutTransponder: 'Cannot create animal in ESF without transponder',
	VALIDATION_ERROR_CycleDayIsEmpty: 'Cycle day is not set',
	VALIDATION_ERROR_AnimalNumberIsEmpty: 'Cannot update animal in ESF without animal number',
	VALIDATION_ERROR_FeedCurveDoesNotExist: 'The chosen feed curve does not exist',
	VALIDATION_ERROR_LocationIsEmpty: 'Location is not set',
	VALIDATION_ERROR_InvalidAnimalKindOnEsfLocation: 'The chosen animalkind cannot be created on an ESF pen',
	VALIDATION_ERROR_FARM_HAS_ESF_BUT_ANIMAL_DOES_NOT_HAVE_TRANSPONDER:
		'Animal needs to have a transponder, before the animal can be moved to an location with a transponder',
	VALIDATION_ERROR_FEED_CURVE_NUMBER_IS_NOT_SET:
		'Feed curve is requiered because selected location is an ESF location',
	VALIDATION_ERROR_ANIMALS_NEED_TRANSPONDER: 'Listed animals does not have a transponder nr.',
	VALIDATION_ERROR_INVALID_ESF_DATA: 'Data on scanned animal is out of date',
	VALIDATION_ERROR_BLE_CHOOSE_LOCATION: 'To use scanner, choose default location on the app.',
	VALIDATION_ERROR_SOW_INVALID_FOR_WORKLIST: 'Animal is not valid for this worklist',
	VALIDATION_ERROR_ENTRACE_SOW_EDIT_LITTER: 'Litter should be 2 or higher, because the animal was created as a sow',
	VALIDATION_ERROR_MATING_BATCH_1_YEAR:
		'It is required to have a mating batch at least 1 year from todays date . Please generate more mating batches',
	VALIDATION_ERROR_MUST_BE_START_MATING_BATCH:
		'Date should be the starting date of a later mating batch than current',
	VALIDATION_ERROR_AVG_WEIGHT_POSITIVE: 'Avg. weight must be positive',
	VALIDATION_ERROR_DEAD_PIGLETS_NOT_SET: 'Amount not set - Must be greater than 0',
	VALIDATION_ERROR_MUST_BE_NURSING: 'Sow needs to be nursing',
	VALIDATION_ERROR_NO_FEEDING_ADJUSTMENT: 'Feed type does not have any adjustments prior to set date',
	VALIDATION_ERROR_DEPARTURE_DATE_IS_EARLIER_THAN_LAST_PREGNANCY:
		'Departure is invalid, because the departure date is earlier than that last pregnancy event.',
	VALIDATION_ERROR_NO_DEPARTURE_ON_NURSING_SOW: 'Departure is invalid, because the sow is in a nursing state',
	VALIDATION_ERROR_MOVE_EVENT_IS_EARLIER_THAN_LAST_EVENT: 'Move date is earlier than the last move date',
	VALIDATION_ERROR_TYPE_NOT_SET: 'Type not set',
	VALIDATION_ERROR_CODE_ALREADY_USED: 'Code is already used',
	VALIDATION_ERROR_REASON_NOT_SET: 'Reason not set',
	VALIDATION_ERROR_NO_SOWS_IN_FILE: 'No sows in file',
	VALIDATION_ERROR_SOME_SOWS_EXISTS: 'Some sows already exists',
	VALIDATION_ERROR_ANIMAL_ID_NUM_EXISTS: 'Id number already exists',
	VALIDATION_ERROR_ANIMAL_IS_NOT_YOUNG_FEMALE: 'The animal(s), is not a young female',
	VALIDATION_ERROR_NO_DEFAULT_WEANING_PEN:
		'A location for weaning pigs is needed. Setup under settings, Sows, General',
	VALIDATION_ERROR_COUNT_LARGER_THAN_CURRENT_COUNT1: 'Only',
	VALIDATION_ERROR_COUNT_LARGER_THAN_CURRENT_COUNT2: 'pigs is currently on chosen locations',
	VALIDATION_ERROR_COUNT_LARGER_THAN_CURRENT_COUNT3: 'There is',
	VALIDATION_ERROR_COUNT_LARGER_THAN_CURRENT_COUNT4: 'pigs on location',
	VALIDATION_ERROR_COUNT_LARGER_THAN_CURRENT_COUNT5: 'Therefore unable to depart',
	VALIDATION_ERROR_FarrowingOutOfInterval:
		'It is not possible to assign ID numbers. The farrowing does not match the interval.',
	VALIDATION_ERROR_DrugHasUsageOrWasteRegistration: 'Drug has usage or waste registration',
	VALIDATION_ERROR_SET_FEED_CURVE: 'Set feed curve',
	VALIDATION_ERROR_SET_RECIPE: 'Set recipe',
	//Warning messages
	VALIDATION_WARNING_MatingToFarrowingOutOfInterval:
		'The animal has {{area}} pregnant days, which is out of configured interval, do you want to save anyway?',
	VALIDATION_ERROR_YOU_ARE_MATING_A_ANIMAL_THAT_IS_NURSING:
		'You are mating a animal, that is nursing, do you want to save anyway?',

	VALIDATION_WARNING_FarrowingToWeaningOutOfInterval1: 'weaning days is',

	VALIDATION_WARNING_FarrowingToWeaningOutOfInterval2: 'and exceed the interval, do you want to save anyway?',

	VALIDATION_WARNING_WeaningToMatingOutOfInterval:
		'The period from Weaning to mated is out of configured interval, do you want to save anyway?',
	VALIDATION_WARNING_MatingAgeOutOfInterval:
		'The age of the animal is out of the configured interval, do you want to save anyway?',
	VALIDATION_WARNING_ConfirmDeletionMated: 'Delete chosen mated?',
	VALIDATION_WARNING_ConfirmDeletionFeedingStockAdjustment: 'Are you sure, you want to delete feeding adjustment?',
	VALIDATION_WARNING_ConfirmDeletionFeedingStockUsage: 'Are you sure, you want to delete feeding usage?',
	VALIDATION_WARNING_ConfirmDeletionAdjustments: 'Delete chosen medicine adjustment?',
	VALIDATION_WARNING_ConfirmDeletionScanning: 'Delete chosen scanning?',
	VALIDATION_WARNING_ConfirmDeletionFarrowing: 'Delete chosen farrowing?',
	VALIDATION_WARNING_ConfirmDeletionWeaning: 'Delete chosen Weaning?',
	VALIDATION_WARNING_CapacityExceeded1: 'Capacity exceeded on pen:',
	VALIDATION_WARNING_CapacityExceeded2: 'Current amount of pigs:',
	VALIDATION_WARNING_CapacityExceeded3: 'Capacity is:',
	VALIDATION_WARNING_ConfirmWarningMessage: 'Are you sure you want to save?',
	VALIDATION_WARNING_ConfirmWarningDataNotSavedMessage: 'There is data, that is not saved, should they be saved?',
	VALIDATION_WARNING_ConfirmWarningMessageMoveEvent: 'Are you sure you want to move this animal to the new location',
	VALIDATION_WARNING_ConfirmDeletionBuilding: 'Are you sure you want to delete this building?',
	VALIDATION_WARNING_ConfirmDeletionSection: 'Are you sure you want to delete this section?',
	VALIDATION_WARNING_ValveGroupSectionContainsOldData: 'Selected section contains old valve group data',
	VALIDATION_WARNING_ValveGroupsModificationOld:
		'Modification on valve group(s) did not save because it was out dated',
	VALIDATION_WARNING_ValveGroupIsNotEditable:
		'Valve data is from yesterday and cannot be edited because of old data. Via the website, adjust feed changes, you can download lastest data from Distriwin',
	VALIDATION_WARNING_ESF_DATA_IS_NOT_UP_TO_DATE: 'Some data is not up to date with the controller',
	VALIDATION_ERROR_CURVE_INVALID: 'Curve need to selected to save',
	VALIDATION_ERROR_FIXED_PERCENT_INVALID: 'Fixed % need to be 0-100 to save',
	VALIDATION_WARNING_DELETE_MARKED_YOUNG_ANIMALS: 'Delete marked young animals?',
	VALIDATION_WARNING_EDIT_GROWTH_PIG_EVENT:
		'The changes will result in a negative quantity of pig, do you want to continue?',
	VALIDATION_WARNING_CAPACITY_EXCEEDED: 'Capacity limit on {{area}}% exceeded, do you want to save anyway?',
	VALIDATION_WARNING_CAPACITY_EXCEEDED_MANY:
		'Capacity limit on {{area}}% exceeded on pen {{area2}}, do you want to save anyway?',
	VALIDATION_WARNING_MOVE_YOUNG_FEMALE_TO_PREGNANT: "You're moving a young female to a pregnant location, are you sure?",

	// SlaughterHouse
	SlaughterHouse: 'Slaughterhouse',
	connectToTican: 'Connect to Tican',
	DanishCrown: 'Danish Crown',
	Tican: 'Tican',
	loginInformation: 'Login information',
	connectionSlaughterHouse: 'Communication Slaughterhouse',
	LoginInformationDanishCrown: 'LOGIN INFORMATION DANISH CROWN',
	UserId: 'User ID:',
	password: 'Password:',
	SupplierNumbers: 'Supplier',
	meatPercentage: 'Meat %',
	otherCosts: 'Other',
	slaughterWeight: 'Slaughter Weight',
	checkSlaughterDataWithDepartureData: 'Check slaughtered with slaughterdata',
	departureSkioldDigital: 'Departure = Slaughter count',
	departureSlaughterHouse: 'Slaughterdata count',
	difference: 'Difference',
	checkSlaughterData: 'Check slaughtered/slaughterdata',
	finisherData: 'Slaughterdata',
	supplier: 'Suppliernr.',
	chooseLocationForMove: 'Choose location for move',
	APig: 'A-pig',
	Discarded: 'Discarded',
	BesBoar: 'Bes. Boar',
	DiscardedSow: 'Discarded sows',
	DiscardedBesBoar: 'Disc. bes. boar',
	RemovedMale: 'Removed male',
	OtherDelievered: 'Other delivered',
	ReturnPig: 'Return pig',
	Barrow: 'Barrow',
	Basic: 'Basic',
	slaughterNumber: 'Slaughternr.',
	noting: 'Note',
	aliveWeight: 'Alive weight',
	SavingChangesTheLocationForAllPigsInThisSectionForThisDelieveryForThisDate:
		'Do you want to change location for the delievery?',
	DoYouWantToChangeTheLocationOfThoseItems: 'Do you want to change the location for the marked',
	DoYouWantToDeleteSlaughterDatas: 'Do you want to delete the marked slaughterdata',
	FinisherDataProcessFinisher: 'Slaughterdata',
	Failed_To_Login_Tican: 'Failure with tican, please try again',
	Error_SlaughterHouse_required: 'Slaughterhouse required',
	avgPricePrKg: 'Kr./kg',
	IncorrectLoginInformation: 'Incorrect login information',
	supplementDeductionPrPig: 'Supplement/deduction per pig',
	spec: 'Spec',
	Note: 'Note',
	priceKgBasis: 'KR/KG BASIS',
	noteText: 'Note text',
	priceWithDeduction: 'Price incl. basis supplements/deductions',

	// Misc
	PromptSureDelete: 'Are you sure you want to delete?',
	SetupValidationSetup: 'Please input validation settings on settings page',

	//Treatment definition
	TreatmentDefinitions: 'Treatment/vaccine Definitions',
	drug: 'Drug',
	chooseDrugType: 'Choose drug type',
	RetentionTime: 'Retention time',
	method: 'Method',
	severiy: 'Severity',
	amountPerUnit: 'Amount per unit',
	duration: 'Duration',
	interval: 'Interval',
	amount: 'Amount',
	numberTreatments: 'Amount',
	per: 'per',
	addTreatDef: 'Add definition',
	choose: 'Choose',
	SowBoarGlit: 'Sow/Boar/Glit',
	none: 'None',
	CanBeRegistration: 'Can be registration',

	// Growth pig reports
	productionKey: 'PRODUCTION SCALE',
	feedKey: 'FEED',
	diverseInformationKey: 'VARIOUS INFORMATION',
	slaughterDataKey: 'SLAUGHTER DATA',
	stateKey: 'STATUS',
	subResultKey: 'SUB RESULTS',
	FeedUsageKey: 'FEED USAGE',
	showFeedUsage: 'Feed usage',
	datagrowthPigPReportSlaughterHouseData: {
		amountBasicPcs: 'Basis, pcs',
		amountOverPcs: 'Over, pcs',
		amountUnderPcs: 'Under, pcs',
		avgSlaughterWeight: 'Avg. Slaughter weight',
		avgMeatPercentage: 'Avg. meat percentage',
		avgDiscarded: 'Pct. discarded',
	},

	datagrowthPigPReportSubResults: {
		feedDays: 'Period feed days',
		periodGrowth: 'Period growth, kg',
	},

	datagrowthPigPReportDiverseInformation: {
		growthPerPigKg: 'Growth per pig, kg',
		avgWeightAtEntry: 'Avg. weight at entry, kg',
		avgWeightAtDeparture: 'Avg. weight departure, kg',
		feedDaysPerProducedPigs: 'Feed days per prod. pigs',
		diversePercentDead: 'Percentage dead',
	},
	datagrowthPigPReportProduction: {
		producedPigsPcs: 'Produced pigs, pcs.',
		dailyGrowth: 'Daily growth, g',
		referenceDailyGrowth: 'Reference daily growth',
	},

	datagrowthPigPReportState: {
		activePcs: 'Active, pcs.',
		totalEntryInPeriod: 'Entry, pcs.',
		entryFromBought: 'Thereof bought, pcs.',
		entryFromTransfered: 'Thereof transfered, pcs.',
		entryFromWeaning: 'Thereof weaned, pcs.',
		avgWeightAtEntry: 'Avg. weight at entry',
		deadPutDownPcs: 'Dead/Killed, pcs.',
		avgWeightDeadPutDown: 'Avg. weight dead, kg',
		soldTransferedPcs: 'Sold/transfered, pcs.',
		transferedToYoungFemale: 'Thereof to young female',
		avgWeightSoldTransfered: 'Avg. weight sold/transfered, kg.',
		delieveredPcs: 'Delievered, pcs.',
		avgWeightDelievered: 'Avg. weight delievered, kg.',
		activeWeight: 'Avg. weight',
		totalSlaughteredRegistrations: 'Departed. slaughtered',
		avgWeightSlaughteredRegistrations: 'Avg. weight slaughtered, kg',
	},

	datagrowthPigPReportFeed: {
		feedPerPigDaily: 'Feed per pig daily, FUsv',
		feedPerKgGrowth: 'Feed per kg growth, FUsv',
		referenceFeedUsage: 'Reference feedusage per kg, FEsv',
		feedPerProducedPig: 'Foder pr. prod. grise, FEsv',
	},
	ProducedPigsPcs: 'Produced pigs, pcs.	',
	DailyGrowth: 'Daily growth, g	',
	ReferenceDailyGrowth: 'Reference daily growth',
	WeanerReferenceDailyGrowth: 'Reference daily growth 7-30 kg',
	FinisherReferenceDailyGrowth: 'Reference daily growth 30-115 kg',
	FRATSReferenceDailyGrowth: 'Reference daily growth 7-115 kg',
	GrowthPerPigKg: 'Growth per pig, kg',
	AvgWeightAtEntry: 'Avg. weight at entry, kg	',
	AvgWeightAtDeparture: 'Avg. weight at departure, kg',
	FeedDaysPerProducedPigs: 'Feed days per prod. pigs',
	DiversePercentDead: 'Percentage dead',
	FRATSActivePcs: 'FRATS, pcs.',
	WeanerActivePcs: 'Weaners, pcs.',
	FinisherActivePcs: 'Finishers, pcs.',
	TotalEntryInPeriod: 'Entry, pcs.',
	EntryFromWeaning: 'Thereof weaned, pcs.',
	EntryFromBought: 'Thereof bought, pcs.',
	EntryFromTransfered: 'Thereof transfered, pcs.',
	DeadPutDownPcs: 'Dead/Killed, pcs.',
	AvgWeightDeadPutDown: 'Avg. weight dead, kg	',
	SoldTransferedPcs: 'Sold/transfered, pcs.',
	TransferedToYoungFemale: 'Thereof to young female',
	AvgWeightSoldTransfered: 'Avg. weight sold/transfered, kg.',
	DelieveredPcs: 'Delievered, pcs.',
	TotalSlaughteredRegistrations: 'Departed. slaughtered',
	AvgWeightSlaughteredRegistrations: 'Avg. weight slaughtered, kg',
	AvgWeightDelievered: 'Avg. weight delievered, kg.',
	FeedDays: 'Period feed days',
	PeriodGrowth: 'Period growth, kg',
	AmountBasicPcs: 'Basic, pcs.',
	AmountOverPcs: 'Over basic, pcs.	',
	AmountUnderPcs: 'Under basic, pcs.	',
	AvgSlaughterWeight: 'Avg. slaughterweight, kg',
	AvgMeatPercentage: 'Avg. meat percentage',
	AvgDiscarded: 'Avg. discarded',
	FeedPerPigDaily: 'Feed per pig daily, FUsv',
	FeedPerKgGrowth: 'Feed per kg growth, FUsv',
	ReferenceFeedUsage: 'Reference feedusage pr. kg',
	WeanerReferenceFeedUsage: 'Reference feedusage per kg  7-30 kg',
	FinisherReferenceFeedUsage: 'Reference feedusage per kg 30-115 kg',
	FRATSReferenceFeedUsage: 'Reference feedusage per kg  7-115 kg',
	FeedPerProducedPig: 'Feed per prod. pigs, FUsv',
	weanerFoodTotal: 'Weaners feed in total',
	finisherFoodTotal: 'Finisher feed in total',
	createDelievery: 'Create dilieverynumber',
	ReportsWeaner: 'Reports',
	ReportsFinisher: 'Reports',
	reportsWeaner: 'Reports',
	reportsFinisher: 'Reports',
	growthPigPReportProduction: 'Production scope',
	growthPigPReportDiverseInformation: 'Various information',
	growthPigPReportFeed: 'Feed',
	growthPigPReportState: 'State',
	growthPigPReportSubResults: 'Sub results',
	growthPigPReportSlaughterHouseData: 'SLAUGHTER DATA',
	Calculated: 'Calculated',
	Tuned: 'Tuned',
	tuneProductionReportWeaner: 'Tune weaners',
	tuneProductionReportFinisher: 'Tune finishers',
	tuneProductionReportFRATS: 'Tune FRATS',
	weanerPigsTuneProductionLists: 'Tune pigs',
	finisherPigsTuneProductionLists: 'Tune pigs',
	Tune: 'Tune',
	ActiveWeight: 'Avg. weight',
	entered: 'Entry',
	internalTransfer: 'Internal transfers',

	//Treatment type
	Food: 'Food',
	Water: 'Water',
	Oral: 'Oral',
	Injection: 'Injection',
	OnTheSkin: 'On the skin',

	//Treatment unit
	perKgPig: 'Kg pig',
	perKgFood: 'Kg food',
	perAnimal: 'Animal',

	//Frequency
	once: 'Once',
	everyDay: 'Every day',
	everyOtherDay: 'Every other day',
	everyWeek: 'Every week',

	//Vaccination
	event: 'Event',
	Days1: 'Days 1',
	Days2: 'Days 2',
	Age1: 'Age 1',
	Age2: 'Age 2',
	Age3: 'Age 3',
	Farrowing: 'Farrowing',
	Mating: 'Mating',
	Averting: 'Weaning',
	Birthdate: 'Birthdate',
	chooseDrug: 'Choose drug',

	//Treatment log
	TREATMENT_LOG: 'TREATMENTS',
	Treatments: 'Treatments',
	Treatment: 'Treatment',
	FetchTreatmentPlans: 'Fetch',
	ExecutedDate: 'Executed on',
	Reactivate: 'Reactivate',
	FromDate: 'From date',
	ToDate: 'To Date',
	User: 'User',
	CountFirstTreatments: 'Number of 1. treatments',
	CountRetreatments: 'Number of retreatments',

	//TreatmentDetails
	treatmentDetails: 'Treatment details',
	Treated: 'Treated',
	DeleteTreatmentPlan: 'Delete treatmentplan',
	DeleteTreatmentPlanPrompt: 'Really delete entire treatmentplan? This cannot be undone.',
	TreatmentPlanNotFound: 'Treatmentplan not found',
	PerAnimal: 'Per animal',
	PerPig: 'Per pig',
	Total: 'Total',
	OverdueAlertHeader: 'Treatment overdue!',
	OverdueAlertMessage: 'When did you perform the treatment?',
	OverdueAlertHeaderDepart: 'Dead/put down',
	OverdueAlertMessageDepart: 'When did you perform the registration',
	//Pregnancy lists
	PregnancyLists: 'Pregnancy Lists',
	moveEvents: 'Moves',

	//GROWTH PIGS
	VALIDATION_ERROR_WEIGHT_NOT_DISTRIBUTED: 'Weight not distributed',
	VALIDATION_ERROR_TO_MUCH_WEIGHT_DISTRIBUTED: 'To much weight distributed',
	VALIDATION_ERROR_PIGS_NOT_DISTRIBUTED: 'Pigs not distributed',
	VALIDATION_ERROR_TO_MANY_PIGS_DISTRIBUTED: 'To many pigs distributed',
	VALIDATION_ERROR_TO_MANY_PIGS_MOVED: 'To many pigs moved',
	VALIDATION_ERROR_TO_MANY_PIGS_DEPARTED: 'To many pigs departed',
	VALIDATION_ERROR_FROM_LOCATION_NOT_SET: 'From location is not set',
	VALIDATION_ERROR_TO_LOCATION_NOT_SET: 'To location is not set',
	VALIDATION_ERROR_FROM_PRODUCTIONTYPE_NOT_SET: 'From production type is not set',
	VALIDATION_ERROR_TO_PRODUCTIONTYPE_NOT_SET: 'To production type is not set',
	VALIDATION_ERROR_NO_PIGS_MOVED: 'No pigs moved',
	VALIDATION_ERROR_NO_PIGS_DEPARTED: 'No pigs departed',
	productionType: 'Production type',
	GrowthPigEntrance: 'Bought',
	GrowthPigMoveEvent: 'Move',
	VALIDATION_REASON_NOT_SET: 'Reason  is not set',
	GrowthPigEntranceEvent: 'Bought',
	GrowthPigDepartureEvent: 'Sold/slaughter',
	GrowthPigPenDepartureEvent: 'Killed/Dead',
	toProductionType: 'To production',
	fromProductionType: 'From production',
	DistributePigsInSection: 'Distribute pigs in section',
	SpecifyWeight: 'Specify weight',
	AmountPigsNotDistributed: 'Amount pigs, that is not distributed',
	TotalWeightNotDistributed: 'Weight, that is not distributed',
	FromWeaner: 'From weaners',
	FromFinisher: 'From finishers',
	FromPiglet: 'From weaned pigs',
	FromBuilding: 'From building',
	FromSection: 'From section',
	ToSection: 'To section',
	ToBuilding: 'To building',
	PenNumber: 'Pen nr.',
	Bought: 'Bought',
	Moved: 'Moved',
	Distribute: 'Distribute',
	DistributeAll: 'Distribute all',
	MoveAll: 'Move all',
	DepartureAll: 'Departure all',
	DistributeWeight: 'Distribute weight',
	fromBuildingName: 'From building',
	fromSectionName: 'From section',
	fromPenName: 'From pen',
	toBuildingName: 'To building',
	toSectionName: 'To section',
	toPenName: 'To pen',
	GrowthPigEventsList: 'Events list',
	EventsList: 'Event list',
	toProctionType: 'To production',
	fromProctionType: 'From production',
	YouHaveToSelectLocation: 'You have to select an location, where the animals should belong.',
	appFinishersManagement: 'Finishers',
	appSowManagement: 'Sows',
	appWeanersManagement: 'Weaners',
	DistributeCount: 'Distribute sale',
	DistributeSlaughter: 'Distribute slaughter',
	TotalPigsInPeriod: 'Total pigs in period',
	ShouldDepartureList: 'Departure list',
	ShouldDepartureListWeaner: 'Departure list',
	ShouldDepartureListFinisher: 'Departure list',
	weanerShouldDepartureOverview: 'Departure list',
	MovePigsOnLocation: 'Move pigs on location',
	finisherShouldDepartureOverview: 'Departure list',
	DistributeMoveFrom: 'Distribute move from',
	DistributeTo: 'Distribute to',
	NegativePigAmountBeforeMove:
		'Some pens have an negative amount, which means, that only {{area}} pigs can be moved. You should equalize the the pig count, on the pens with a positive amount.\n Do you want to move {{area}}',
	SoldYoungAnimal: 'Sold young animal',
	SoldSlaughteredYoungAnimal: 'Sold/slaughtered young animal',

	//Mating
	mating: 'Mating',
	Matings: 'Matings',
	Scannings: 'Scannings',
	Farrowings: 'Farrowings',
	Farrowed: 'Farrowed',
	Weanings: 'Weanings',
	Grade: 'Grade',
	NumAlive: 'Amount alive',
	NumAliveTotal: 'Total aliveborn',
	NumDead: 'Amount dead',
	NumDeadTotal: 'Total deadborn',
	TypeAnimalNumber: 'Type animal number',
	ValidateAgeAtFirstMating: 'Minimum age for the first mating is {{area}} days',
	ValidateSetAgeAtFirstMating: 'Specify age at first mating: Under Settigs, Sows, General, Event, Matings',
	ValidateSetMaxDaysAfterBoarDeparture:
		'Specify Days a boar is allowed after departure: Under Settigs, Sows, General, Event, Matings',
	ValidateMaxDaysAfterBoarDeparture: 'A boar can only be used at max, {{area}} days after departure date',
	ValidateNucleusBoarEntranceDate: 'The boar was not a part of the herd on the chosen date',
	searchBoars: 'Search boar',

	//Sow State
	Dry: 'Dry',
	Preg: 'Preg',
	Nursing: 'Nursing',
	PregnantAndNursing: 'Pregnant/Nursing',
	NursingLong: 'Nursing',

	//Sow State Distriwin
	Gestation: 'Preg',
	Maternity: 'Nursing',

	//Scanned
	True: 'Yes',
	False: 'No',
	Yes: 'Yes',
	No: 'No',
	Maybe: '?',
	scanning: 'Scanning',
	Pregnant: 'Pregnant',
	NonPregant: 'Non pregnant',

	//Farrowing
	farrowing: 'Farrowing',
	alive: 'Alive',
	dead: 'Dead',
	aliveFemale: 'Alive female pigs',
	aliveMale: 'Alive male pigs',
	abortion: 'Abortion',
	farrowingInProgress: 'Farrowing in progress',
	FarrowingDate: 'Farrowing date',
	LitterWeight: 'Litter weight',
	Mumified: 'Mumified',
	Assign: 'Assign',
	AssignId: 'Assign ID numbers',
	EarNotch: 'Ear notch',
	LitterBreed: 'Litter breed',
	ValidateAssignIdCount: 'Assigned ids does not match alive piglets.\n Edit assigned id numbers?',
	CurrentSecondMatingNumber: 'Current 2. mating number.',
	ValidateNucleusAbortion:
		'It is not possible to register an abortion, outside the interval of {{area}}-{{area2}} days',
	ValidateNoAssignedRegistrationsCheck: 'No marking has been made of young animals that need to be created.',

	//weaning
	pcsWithoutDot: 'pcs',
	pcs: 'pcs',
	Pcs: 'Pcs',
	avgWeight: 'Avg. weight/pig',
	avgWeightShort: 'Avg. weight',
	avgWeightSlashPig: 'weight/pig',
	avgPriceSlashPig: 'price/pig',
	totalWeight: 'Total weight',
	nursingSow: 'Nursing sow',
	avgPrice: 'Price per pig',
	totalPrice: 'Total price',
	Weaning: 'Weaning',
	nursSow: 'N',
	totalPcs: 'Total pcs',
	avgPcs: 'Avg. pcs',
	defaultWeight: 'Default weight',
	NoWeaningLocationSet: 'No weaning location set',

	//Mating batch
	matingBatch: 'Mating batch',
	AmountMatingBatchesPerColumn: 'Amount mating batches per. column',
	matingBatchUpToAndIncluding: 'Mating batch calculated up to and including',
	weekdayStart: 'Start weekday',
	StartDateForBatch: 'Start date for mating batch',
	daysForMatingPeriod: 'Day in mating period',
	cycleInWeeks: 'Cycles in weeks',
	matingPeriod: 'Mating period',
	FarrowingPeriod: 'Farrowing period',
	batchNum: 'Batch number',
	pregnantDays: 'Pregnant days',
	NursingDays: 'Nursing days',
	calculate: 'Calculate',
	addMatingBatch: 'Add mating batch',
	deleteMatingBatch: 'Delete mating batch',
	weaningPeriod: 'Weaning period',
	Start: 'Start',
	End: 'End',
	deleteMatingBatchesFromDateAndForward: 'Delete mating batches from this date and forward',
	repeat: 'Repeat',
	manuel: 'Manuel',
	daysExceeded: 'Number of days exceeded',
	batchPerCycle: 'Quantity of bathces - start batch 1 per',
	timesToRepeat: 'Times to repeat',
	setStartDateToNextAvailableBatch: 'Set start date to next available mating batch?',

	/////mating batch report
	matingBatchReport: 'Mating batch report',
	printMatingBatchReport: 'Print mating batch report',
	actualWeek: 'Current week',
	//mating
	TotalMatings: 'Matings, pcs.',
	YoungAnimalMatings: '\u2022 Thereof young female, pcs.',
	TotalDepartures: 'Animals departed from the batch, pcs.',
	DepartureNonPregnant: '\u2022 Thereof not pregnant, pcs.',
	DepartureKilled: '\u2022 Thereof slaughtered/sold, pcs.',
	DepartureDead: '\u2022 Thereof dead/killed, pcs.',
	AvgWasteDays: 'Avg. non-productive days',
	ReturnSows: '\u2022 Thereof return sow',
	ThereOfAbortions: '\u2022 Thereof abortion',
	WeaningToFirstMating: 'Days from weaning to first mating',
	AnimalsToFarrowing: 'Animals for farrowing, pcs.',
	//farrowing
	TotalFarrowings: 'Farrowings, pcs.',
	NeedFarrowing: 'Sows that have not yet farrowed, pcs.',
	AvgAlive: 'Avg. liveborn, pcs.',
	AvgDead: 'Avg. stillborn, pcs.',
	FarrowingRate: 'Farrowing rate for the batch, pct.',
	AvgPregnantDays: 'Avg. pregnant days',
	AvgPregnantDaysPreviousBatch: 'Avg. preg.days previous litter',
	ThereOfFirstParity: '\u2022 Thereof 1st parity',
	AvgLivebornFirstParity: 'Avg. liveborn 1st parity, pcs',
	TotalLiveBorn: 'Total liveborn, pcs',
	StillbornToAliveRate: 'Stillborn in pct. of total born',

	//weaning
	TotalWeanings: 'Weaned sows, pcs.',
	NursingSows: '\u2022 Thereof nursing sows, pcs.',
	NeedWeaning: 'Sows that are not yet weaned, pcs.',
	AvgAlivePerLitter: 'Avg. liveborn per litter, pcs.',
	AvgDeadPerLitter: 'Avg. stillborn per litter. pcs.',
	AvgWeanedPerLitter: 'Avg. pigs weaned per litter, pcs.',
	AvgWeight: 'Weight per pigs, kg',
	DeadTillWeaning: 'Died piglets',
	DeadToAliveRate: 'Dead till weaning, pct',
	AvgNursingDays: 'Avg. nursing days',
	NursingSowsCount: 'Nursing sows',
	AvgAliveShort: 'Avg. alive',
	AvgDeadShort: 'Avg. dead',
	AvgPriceShort: 'Avg. price',
	AvgWeanedPerWeaninig: 'Weaned per weaning, pcs.',
	TotalWeanedPiglets: 'Total weaned, pcs.',

	//Weekdays
	Monday: 'Monday',
	Tuesday: 'Tuesday',
	Wednesday: 'Wednesday',
	Thursday: 'Thursday',
	Friday: 'Friday',
	Saturday: 'Saturday',
	Sunday: 'Sunday',

	//Feeding stock
	FeedingAdjustment: 'Feeding adjustment',
	FeedingAdjustment_purchase: 'Feeding purchase',
	FeedingUsage: 'Feeding usage',
	FeedingStock: 'Feeding stock',
	FeedingLog: 'Feeding log',
	CreateFood: 'Create feed',
	EditFood: 'Edit feed purchase',
	EditFoodUsage: 'Edit feed usage',
	CreateUsage: 'Create usage',
	CreatePurchase: 'Feed purchase',
	FeedingType: 'Feeding type',
	FeedingSubject: 'Feeding subject',
	FeedingSubjectName: 'Feeding subject name',
	AmountInKgs: 'Amount in kgs',
	FEsoPerKg: 'FUsow/kg',
	FEsvPerKg: 'FUsv/kg',
	purchase: 'Purchase',
	RawProtein: 'Raw protein % of product',
	FosforGPerKg: 'Phosphor g/kg',
	PricePer100Kgs: 'Price per 100 kg',
	PeriodFrom: 'Period from',
	PeriodTo: 'Period to',
	UsageKg: 'Usage kg',
	UsageFE: 'Usage FU',
	UsageFEso: 'Usage FUsow',
	UsageFEsv: 'Usage FUsv',
	UsageMoney: 'Usage money',
	feedingLog: 'Feeding log',
	FEso: 'FUso',
	FEsv: 'FUsv',
	KG: 'KG',
	NoRelation: 'No relation',
	//Drug Stock
	DrugStock: 'Drug/vaccine Stock',
	adjustmentType: 'Adjustment type',
	registeredBy: 'Registered by',
	registeredOn: 'Registration date',
	medicineLog: 'Drug Log',
	AddToDrugStock: 'Add',
	lastRegulation: 'Last regulation',
	Waste: 'Waste',
	Usage: 'Usage',
	UpdateInventory: 'Inventory',
	MedicineStockStorage: 'Inventories',
	UpdateMedicineStock: 'Regulation',
	AddDrug: 'Add drug to stock',
	UpdateDrugStockAmount: 'Update stock amount',
	currentDrugStockAmount: 'Current inventory',
	newDrugStockAmount: 'New stock amount',
	createMedication: 'Create Medication',
	Medicine: 'Medicine',
	Vaccine: 'Vaccine',

	//Sow Card
	SOWCARD: 'SOW CARD',
	location: 'Location',
	IDnr: 'IDnum.',
	comment: 'Comment',
	move: 'Move',
	wasteDays: 'Waste days',
	wasteDaysShort: 'Waste',
	weaningShort: 'Wean',
	pigYearSo: 'Pigs/year sow',
	pregnantDaysShort: 'Preg',
	gradeShort: 'K',
	abortionSowCard: 'A',
	nursingDaysShort: 'Nurs',
	aliveShort: 'Alive',
	kilo: 'Kg',
	pregDays: 'Preg days',
	nursDays: 'Nurs days',
	stillBorn: 'Still born',
	lastMoved: 'Last moved',
	departed: 'Departed',
	BreedingNr: 'Breed nr.',
	BreedingNumberAndNumberBatch: 'Breeding number - number batch',
	//Location types
	Boars: 'Boars',
	Relief: 'Relief',
	ReliefFinisher: 'Relief finisher',
	ReliefWeaners: 'Relief Weaners',
	Holistic: 'Holistic',
	Weaners: 'Weaners',
	ClimatePigs: 'Weaners',
	Finisher: 'Finisher',
	locationType: 'Type',
	Silo: 'Silo',
	PostponeSows: 'Postpone sows',
	WeanedPiglets: 'Weaned',

	//Animal types
	FRATS: 'FRATS',
	FeedMill: 'Feed mill',
	Weaner: 'Weaner',
	animalType: 'Animal type',

	//Production
	production: 'Production',
	PigProduction: 'Pig production',
	GrainMill: 'Grain mill',
	Feed: 'Feed',
	Mills: 'Mills',

	//ValidationSetup
	lowerLimit: 'Lower limit',
	upperLimit: 'Upper limit',
	plan: 'Plan',
	Sell: 'Sell',
	ToBeMoved: 'To be moved',
	validation: 'Validation',
	weaningAnimals: 'Weaning animals',
	usingQRForSows: 'Using QR codes for sows',
	usingQRForLocations: 'Using QR codes for locations',
	MatingAge: 'Age at mating for young animal',
	MatingToFarrowing: 'Mating to farrowing',
	FarrowingToWeaning: 'Farrowing to weaning',
	FarrowingToAverted: 'Farrowing to weaning',
	AvertedToMating: 'From weaning to 1. mating',
	systemMinimum: 'System minimum',
	systemMaximum: 'System maximum',
	deviationMinimum: 'Deviation plan - minimum',
	deviationMaximim: 'Deviation plan - maximum',
	weanedPigletsMovedToLocation: 'Weaned pigs moved to location:',

	// Component input
	Component: 'Component',
	Destination: 'Destination',
	NewDeliveryIn: 'New Delivery In',
	NewDeliveryInBulk: 'New Bulk Delivery In',
	NewDeliveryInBag: 'New Bag Delivery In',
	NoComponentsReceived: 'No components available',
	ChooseComponent: 'Choose component',
	ChooseSupplier: 'Choose supplier',
	ChooseTruck: 'Choose truck',
	ChooseSourceSilo: 'Choose source silo',
	ChooseDestinationSilo: 'Choose destination silo',
	ChooseWarehouse: 'Choose warehouse',
	Weighing: 'Weighing',
	FirstWeighing: 'First Weighing',
	FirstWeighingShort: '1st Weighing',
	SecondWeighing: 'Second Weighing',
	SecondWeighingShort: '2nd Weighing',
	TotalReceived: 'Total Received',
	StartTransport: 'Start Transport',
	DeliveryInCreating: 'The order is being created in FlexMix Pro',
	DeliveryInUpdating: 'The order is being updated in FlexMix Pro',
	DeliveryInCreatingSuccess: 'The order has been created successfully',
	DeliveryInUpdatingSuccess: 'The order has been updated successfully',
	DeliveryInCreatingSuccessFmpOnly:
		'The order has been created in FlexMix Pro, but there was an issue saving it in Skiold Digital',
	DeliveryInUpdatingSuccessFmpOnly:
		'The order has been updated in FlexMix Pro, but there was an issue saving it in Skiold Digital',
	DeliveryInSavingFailed: 'There was a problem sending the order to FlexMix Pro',
	DeliveryInSavingOrderNumber: 'The order number is',
	SaveAndSendOrder: 'Save and send order',
	CancelDelivery: 'Confirm cancel delivery',
	TotalWeight: 'Total Weight',
	Order: 'Order',
	ActiveOrders: 'Active Orders',
	OrderNumber: 'Order Number',
	FilterByOrderNumberOrComponent: 'Filter by order number or component',

	//Work lists
	workLists: 'Work Lists',
	WorkLists: 'Work Lists',
	saveWorklist: 'Perform selected actions on work list before leaving the page?',
	ChooseWorkLists: 'Choose work list',
	Synchronize: 'Synchronize',
	Setup: 'Setup',
	pregnantFromDate: 'Pregnant from dato',
	pregnantToDate: 'Pregnant to dato',
	chooseAnimalToList: 'Choose animals for list',
	MoveTo: 'Move to',
	MoveToPregnancyLocation: 'Move to pregnancy location',
	MoveToFarrowingLocation: 'Move to farrowing location',
	MoveToMatingLocation: 'Move to mating location',
	FarrowingListSetting: 'Farrowing list',
	WeaningListSetting: 'Weaning list',
	MatingListSetting: 'Mating list',
	ScanningListSetting: 'Scanning list 1',
	ScanningListSetting2: 'Scanning list 2',
	LardScanningEventListSetting: 'Lard Scanning list 1',
	LardScanningEventListSetting2: 'Lard Scanning list 2',
	listAvailableOn: 'List available on',
	columnTextShownAs: 'Column text/shown as',
	daysAfterMating: 'Days after mating',
	PregnacyDays: 'Pregnant days',
	PregnancyDays: 'Pregnant days',
	MatingBatch: 'Mating batch',
	Web: 'Web',
	App: 'App',
	SetupActivityList: 'Setup Activity list',
	ActivityList: 'Activity list',
	activityLists: 'Activity lists',
	expectedFarrowing: 'Est. Farrowing',
	expectedFarrowingDashboard: 'Estimated farrowing',
	completed: 'Completed',
	VaccineListSetting: 'Vaccination',
	VaccineListSetting1: 'Vaccination 1',
	VaccineListSetting2: 'Vaccination 2',
	VaccineListSetting3: 'Vaccination 3',
	TasksInFarrowingStableSetting: 'Tasks in farrowing stable',
	TasksInFarrowingStableSetting2: 'Tasks in farrowing stable 2',
	boarPerLitterPcs: '% boars in litter',
	treatmentVaccination: 'Treatment/Vaccination',
	toBetreated: 'To be treated',
	allPigs: 'All pigs',
	boarsPigs: 'Boars pigs',
	daysAfterEvent: 'Days after event',
	weeksAfterEvent: 'Weeks after event',
	weeksAfterMating: 'Weeks after mating',
	weeksAfterFarrowing: 'Weeks after farrowing',
	vaccineByLitterNumber: 'Vaccination up to litter number',
	showLocationOnList: 'Show location on list',
	calcByVaccinationPregnancyType: 'Vaccination is calculated by',
	showAnimalsOnList: 'Show animals on list (cycle days)',
	expectedFarrowingLong: 'Expected farrowing',
	dateForWeaning: 'Weaning date',
	dateForMating: 'Mating date',
	Blank: 'Blank',
	chooseFeedCurve: 'Choose feed curve for',
	chooseReader: 'Choose reader',
	feedCurve: 'Feed curve',
	MoveFromPregToPregSetting: 'Move between pregnancy locations',
	MoveMatingToMatingSetting: 'Move between mating locations',
	maxDryDays: 'Max dry days',
	newSow: 'New sow',
	AgeForYoungFemales: 'Age for young animals',
	AgeYoungFemale: 'Age (young animal)',

	//Animal Report
	MatingBatchReportSetting: 'Mating batch',
	ActiveAnimalsReportSetting: 'Active animals',
	ActivePoolYoungFemale: 'Pool animals',
	reports: 'Reports',
	report: 'Report',
	reportAvailableOn: 'Report available on',
	AnimalNumberOnly: 'Animal num. only',
	AnimalNumberPerLocation: 'Animal per location',
	howToPresentAnimals: 'How to present the animals',
	ShowMatingBatchInLeftSide: 'Show mating batch in right side',
	ShowFarrowingDateInRightSide: 'Show farrowing date in right side',
	RepeatAnimalNumberAtBottom: 'Repeat animal number at bottom',
	ShowComment: 'Show comment',
	SowBoardReportSetting: 'Sowboard',

	// Process equipment
	FailedToFetchNewestLocations: 'Failed to fetch newest locations from process equipment',
	removeProcessEquipment: 'Remove process equipment?',
	setPensAutomaticValves: 'Set pens on valves',
	setPensAutomaticStations: 'Set pens on stations',
	processEquipment: 'Process equipment',
	penNr: 'Pen num',
	Valves: 'Valves',

	StationNumbers: 'Stations numbers',
	locations: 'LOCATIONS',
	relationProcessEquipment: 'CREATE RELATIONS BETWEEN LOCATIONS IN PROCESS EQUIPMENT AND SKIOLD digital',
	Units: 'Units',
	locationsSkioldDigital: 'LOCATIONS SKIOLD digital',
	locationsProcessEquipment: 'LOCATIONS PROCESS EQUIPMENT',
	createProcessEquipment: 'Create process equipment',
	chooseProcessEquipment: 'Choose Process equipment',
	chooseUnits: 'Choose units',
	relationLocation1: 'Create relation',
	relationLocation2: 'process equipment/location',
	bindProcessEquipment: 'BIND UNIT FROM PROCESS EQUIPMENT TO LOCATIONS IN SKIOLD digital',
	unsavedRegisteredData: 'The registered data is not save. Save the changes?',
	processCode: 'Code',

	// All animals esf
	AllAnimalsEsfListSetting: 'All animals ESF',
	AWR250StickReader: 'AWR 250 Stick Reader',
	AWR300StickReader: 'AWR 300 Stick Reader',
	Reader: 'Reader',
	cycleDaysShownNegative: 'Cycle days shown negative',
	eatenToday: 'Eaten today',
	curve: 'Curve',
	Stations: 'Stations',

	// All animals food esf
	Yesterday: 'Yesterday',
	DateAndTime: 'Date, Time',
	FE: 'FU',
	ShortageEsfListSetting: 'Shortage list',
	LastFourVisits: 'LAST FOUR VISITS',
	Visit0: 'Last Visit',
	Visit1: '2. Visit',
	Visit2: '3. Visit',
	Visit3: '4. Visit',
	Rest: 'Rest',
	MJEnergy1: 'MJ',
	KCALEnergy1: 'KCAL',
	FEsoFEsvEnergy1: 'FUso',
	FEsoFEsvEnergy2: 'FUsv',
	FEEnergy1: 'FU',
	FEEnergy2: 'FU',
	MJEnergy2: 'MJ',
	KCALEnergy2: 'KCAL',

	//Agrident bluetooth
	connectToUnit: 'Connect to bluetooth unit',
	disonnect: 'Disconnect',
	startScan: 'Start scanning',
	bleSelect: 'Select',
	bleCancel: 'Cancel',
	bleOk: 'Ok',
	bleSave: 'Save',
	chooseCurve: 'Choose curve',
	enterFixed: 'Enter fixed %',
	Eat: 'Eat',
	animalsOnListWhileDaysRationNotUnfed: 'Animals, that have eaten less than\n of the day ration,\n is included',
	TodayFE: 'Today FU',
	model: 'Model',
	ID: 'ID',
	bluetoothIsDisabled: 'Bluetooth is disabled',
	YesterdayFE: 'Yesterday FU',
	Station: 'Station',
	restTodayFE: 'Rest today',
	eatenTodayFE: 'Eaten today',
	curveFE: 'Curve',
	restYesterdayFE: 'Rest yesterday',
	eatenYesterdayFE: 'Eaten yesterday',
	searchBtUnits: 'Search devices',
	enableLocation: 'Location is requiered to use Bluetooth Low Energy service',
	useGPS: 'Enable GPS',
	noCurveChosen: 'No curve chosen',

	// P-report
	CHOOSEREPORT: 'CHOOSE REPORT',
	pReport: 'P-report',
	period: 'Period',
	newPeriod: 'New period',
	specialPeriod: 'Special period',
	ReMatingPercent: 'Remating, pct.',
	ThereOfGiltsPcs: '\u2022 There of gilts, pcs.',
	AlivePerLitterPcs: 'Alive per litter, pcs.',
	DeadPerLitter: 'Dead per litter, pcs.',
	FarrowingPercent: 'Farrowing percent.',
	AvgPregnantDaysPerLitter: 'Avg. pregnant days per litter',
	ThereOfFirstLitterPcs: '\u2022 There of first litter, pcs.',
	FirstLitterPercent: '\u2022 First litter, percent',
	NursingSowPercent: '\u2022 Percent. Nursing sows',
	DeadTillWeaningPercent: 'Dead till weaning, percent',
	KeyNumbers: 'P-report, Key numbers',
	clearPeriods: 'Clear periods',
	WeanedPerWeaningPcs: 'Weaned per weaning, pcs.',
	PReportFor: 'P-report for:',
	invalidDataIfPrivateSlaughter:
		"Can't calculated p-report, because of missing weight registration og slaughtered departure",

	// Production scale
	YearSowPcs: 'Sow per year',
	WeanedPigletsPcs: 'Weaned pigs',
	AvgWeightPerPiglet: 'Avg. weight on weaning',
	PercentDeadSowGilts: 'Percent death sow of Year sow',
	PeriodFeedDaysSowAndGilt: 'Feed days the period sows/gilt',
	PeriodFeedDaysYoungAnimal: 'Feed days the period young female',
	ProductionDays: 'Productive days',
	NonProductiveDays: 'Non-productive days',
	CorrectedDays: 'Corrected days',
	PeriodYearSowPcs: 'Year sow the period',
	ProductionScale: 'Production scale',

	// Feed
	SowFoodFeYearSow: 'Sow feed FU/year sow',
	ThereOfYoungAnimalFood: '\u2022 Thereof young female feed, FU/year sow',
	FePerYearSowPerDay: 'FU per year sow per day',
	FePerYearYoungFemalePerDay: 'FU per young female per day',
	// Reproduction
	WasteDaysPerDay: 'Non-productive days per litter',
	ThereOfAdjustedDays: '\u2022 Thereof corrected days',
	LitterPerYearSowPcs: 'Litter per year sow',
	WeanedPigletsPerYearSowPcs: 'Weaned pigs per year sow',
	ProducedLitters: 'Produced litter',
	Reproduction: 'Reproduction',

	// Status
	SowAndGiltPcs: 'Sows/gilts, pcs',
	SowAndGiltEntrance: '\u2022 Entrances, pcs.',
	SowAndGiltDepartured: '\u2022 Departures, pcs.',
	SowAndGiltFeedDaysPcs: 'Feed days sows/gilts, pcs.',
	SowAndGiltPregnantDays: 'Pregnant days sows/gilts, pcs.',
	YoungFemalePcs: 'Young female, pcs.',
	YoungFemaleEntrance: '\u2022 Entrances, pcs.',
	YoungFemaleEntranceToGilt: '\u2022 Transfered, pcs.',
	YoungFemaleDepartured: '\u2022 Departures, pcs.',
	YoungFemaleFeedDaysPcs: 'Feed days young female, pcs.',
	BoarPcs: 'Boars, pcs.',
	BoarEntrance: '\u2022 Entrances, pcs.',
	BoarDepartured: '\u2022 Departures, pcs.',
	PigletPcs: 'Piglets, pcs.',
	Departured: 'Departures, pcs.',
	// Feed usage
	FeedUsage: 'Feed usage',
	kgTotal: 'Total kg',
	feTotal: 'Total FU',
	fePercent: 'FU percent',
	kgPercent: 'KG percent',
	sowFoodTotal: 'Sow food total',
	youngFemaleFoodTotal: 'Young female food total',
	pigletFoodTotal: 'Piglet food total',
	allInAllFood: 'For all feed usage',

	// Sub results
	SubResult: 'Sub result',
	SubResults: 'Sub results',

	// Dead piglets event
	deadPigletsEvent: 'Dead piglets',
	deadPigsEvent: 'Dead pigs',
	sowCount: 'Sow count',
	totalDeadPiglets: 'Dead piglets',
	avgDeadPerSow: 'Avg. dead per sow',
	deadPigs: 'Dead pigs',
	deadPigletsGreaterThan1: 'Id number is unavailable when dead piglets is greater than 1',
	growthPigGreaterThan1: 'Id number is unavailable when count is greater than 1',

	//Feeding report
	printFeedingReport: 'Print feeding report',
	feedingReport: 'Feeding report',

	// Pregnancy analysis
	maxBatchesIs27: 'Maximum of mating batches to show is 27',

	// Resend Esf list
	resendEsfList: 'Resend data to ESF',
	resend: 'Resend',
	SowEvent: 'Event',
	MoveEvent: 'Move',
	Entrance: 'Entrance',
	RequireSelectionOfPercentagesRed: 'Interval required for when animal should be marked red',
	RequireSelectionOfPercentagesGreen:
		'Interval required for when animal should be marked green, needs to be bigger than red interval',
	FeedingESFReportSetting: 'Feeding report settings',
	feedingESFSetupGreenNameText: 'Animal marked as green when:',
	feedingESFSetupRedNameText: 'Animal marked as red when:',
	dayRationNotEaten: '% daily ration not eaten',
	dayRationEaten: '% daily ration eaten',

	// Pool young female
	salePoolYoungFemale: 'Turnover pool young female',
	poolYoungFemale: 'Pool young female',
	Climatepigs: 'Weaners',
	IdNumberDisableIfGreaterThan1: 'It\'s not possible to indicate Id number when Count is greater than 1',
	// Balance weaned pigs
	sold: 'Sold',
	PigletWeanedEvent: 'Weaned',
	PigletDepartureSoldEvent: 'Sold',
	PigletMovedEvent: 'Weaned',
	WeanedOnStable: ' Weaned on stable',
	SellWeanedPigs: 'Sell weaned pigs',
	DistributeWeanedPigs: 'Distribute weaned pigs',
	BalanceWeanedPigs: 'Balance weaned pigs',
	balanceWeanedPigs: 'Balance weaned pigs',
	ToBeDistributed: 'To be distributed',
	ToDistributed: 'Distributed',
	PigletMovedEventDash: 'Weaned',

	// Growth pigs events dashboard
	numOfPigs: 'No. of pigs',
	entryWeight: 'Entry',
	weightToday: 'Today',
	days: 'No. Days',
	dailyGain: 'Daily gain',
	feedPerPigToday: 'Today',
	feedPerPigCurve: 'Curve',
	feedPerPigAdjust: 'Adjust',
	feedPerPigTotal: 'Feed',
	feedPerPigWeight: 'Weight',
	totalDayX: 'Total day {{area}}',
	deadLastSevenDays: 'Dead/put down',
	treatedLastSevenDays: 'Treated',
	lastSevenDays: 'Last 7 days',
	expectedDeliveryDate: 'Expected delivery at {{area}}kg in week',
	expectedDeliveryAdjust: 'Adjust',
	feedPerPig: 'Feed per pig',
	expectedDelivery: 'Expected delivery',
	lastBalanced: 'Last balanced',
	deadLast7DaysTitle: 'Elaboration Dead/Put down',
	treatedLast7DaysTitle: 'Treated last 7 days',
	enteredDashboard: 'Entered',
	departedDashboard: 'Departed',
	dateForBalance: 'Balance date',
	gpeDashboardMoved: 'Moved',
	identifierToFinisher: 'To finisher',
	identifierToWeaner: 'To weaner',
	identifierToSow: 'To sow',
	identifierToYoungFemale: 'To young female',
	identifierFromFinisher: 'From finisher',
	identifierFromWeaner: 'From weaner',
	identifierFromSow: 'From sow',
	enteredSince: 'Entered since',
	departedSince: 'Departed since',

	// General settings
	GeneralSettings: 'General settings',
	General: 'General',
	Generalt: 'General',
	RegisterAliveFemales: 'Register alive female pigs',
	RegisterAliveMales: 'Register alive male pigs',
	WeanedPigsToLocation: 'Wean pigs to location',
	SetupEvent: 'Event',
	SetupRegistration: 'Registration',
	TreatmentRetentionTime: 'Treatments - Retention time',
	HandleRetentionTime: 'Handle retention time on move',
	IsLettersUsedForAnimal: 'Is letters used for animal number',
	IsLettersUsedForBoar: 'Is letters used for boar number',
	PigDeliveryToPrivateSlaughterhouse: 'Pig delivery to private slaughterhouse',
	SowRegistrations: 'Sow registrations',
	Communication: 'Communication',
	SendDataToDistriwin: 'Share weight regulations with Distriwin',
	EnergyIndicatedBy: 'Energy indicated by',
	FetchDistriwinFeedingData: 'Fetch distriwin feeding data?',
	MinAgeInDaysFirstMating: 'Minimum age for animal at 1. mating',
	MaxDaysAfterBoarDepartureSemen: 'Days a boar is allowed for use after departure',
	ShowFamilyTree: 'Show family tree icon',
	ShowRaceForLitter: 'Show the race of the litter',
	RegisterLitterWeight: 'Register litter weight for born pigs',
	RegisterMumifiedPigs: 'Is mumified pigs registrated',
	MapYoungAnimalsToLocation: 'Map young animals to location',
	ShouldShowDadMomData: 'Should show dad/mom data',
	ShouldShowInitialsForRegistrations: 'Should show initials for registrations',
	RegisterEarNotch: 'Register ear notch',
	CalculateEarNotch: 'Calculate ear notch',
	WeekStartyDay: 'Week start day',
	ShowRaceLine: 'Should race line be specified',
	UsageShownAs: 'Should feed usage percent be shown as',
	ShouldUsageBeAttachedToRecipe: 'Should feed usage be attached to recipe',
	SendEntranceDepartureToDistriwin: 'Send entrance/departure to Distriwin',
	AssignNumbersToBornPiglets: 'Assign number to born piglets',
	SendCycleDaysAsNegative: 'Send cycle days as negative values',
	RegisterIdForTurnoverPoolYoungAnimals: 'Enable ID number registration on turnover pool young animals',
	NotifyCapacity: 'Notify when capacity is exceeded',
	RegisterIdForGpeDeparture: 'Enable ID number registration on departure',
	ShareDataWithSegesInsight:'Share data with SEGES InSight',
	// Gpe regulations
	Regulations: 'Regulations',
	WeightRegulation: 'Weight regulation',
	EditRegulation: 'Edit regulation',

	Updated: 'Updated',
	viewControllers: 'View Controllers',
	ControllerNumber: 'Controller Number',
	// Distriwin Component
	createRelation: 'Create relation',
	VALIDATION_EqualDistriwinAndSkioldEnergy: 'Energy in Distriwin og SKIOLD digital needs to be equal',
	FEkg: 'FU/kg',
	MJkg: 'MJ/kg',
	KCALkg: 'KCAL/kg',
	FEsoFEsvkg: 'FUso/kg',
	FEsvkg: 'FUsv/kg',

	EnergyKg: '{{area}}/kg',

	FETotal: 'Total FU',
	KCALTotal: 'Total KCAL',
	MJTotal: 'Total MJ',
	FEsoFEsvTotal: 'Total FU',

	FEPct: 'FU percent',
	KCALPct: 'KCAL percent',
	MJPct: 'MJ percent',
	FEsoFEsvPct: 'FU percent',

	//Distriwin feed registrations
	Energy: 'Energy',
	CurveRation: ' Kurve ration',

	// Nucleus management
	UserName: 'Username',
	Connected: 'Connected',
	NoConnectionFound: 'No connection found',
	UpdatePassword: 'Update password',
	UpdateLogin: 'Update login',
	NucleusManagement: 'Nucleus Management',
	Provider: 'Provider',
	Connections: 'Connections',
	BreedingNumber: 'Breeding number',
	NumberBatch: 'Number batch',
	Fetch: 'Fetch',
	FetchData: 'Fetch data',
	Send: 'Send',
	SendData: 'Send/Export data to excel file',
	DataSendFrequency: 'How often is data sent',
	DataSendTime: 'Data sent at',
	Current: 'Current',
	FinalNumber: 'Final number',
	CreateBreedingNr: 'Create Breeding nr.',
	CreateNumberBatch: 'Create number batch',
	FirstMatingNr: '1st mating nr',
	SecondMatingNr: '2nd mating nr',
	Letter: 'Letter',
	SetupBreedingNumber: 'Setup breeding number',
	NumberBatches: 'Number batches',
	DataFetching: 'Data fetching',
	Daily: 'Daily',
	Weekly: 'Weekly',
	Monthly: 'Monthly',
	Manual: 'Manual',
	Weekday: 'Weekday',
	IndexAnimals: 'Index on animals in herd',
	KsBoarHerds: 'AI-boars from following herds',
	InvalidHerdsAlert: 'Herds to manage input is invalid, must consist of: xxxx,xxxx format, up to 10 herds',
	NotAllInfoAlert: 'Please fill in all fields',
	NoNucleusManagementLicence: 'This site has no Nucleus Management licence or is CEFN (AU)',
	FileType: 'Filetype',
	NoEmailProvided: 'Please provide at least one email address',
	InvalidEmailProvided: 'The provided email is invalid',
	SendManually: 'Send data',
	CreateConnectionFirstError: 'Please login to Nucleus Management provider first under the "Connections" tab',
	SelfManaged: 'CEFN (AU)',
	ValidateBreedingNumber: 'Invalid breeding number',
	ValidateRace: 'Choose race',
	ValidateGender: 'Choose gender',
	ValidateNumberBatc1Start: 'Set 1st mating nr',
	ValidateNumberBatc2Start: 'Set start 2st mating nr',
	ValidateNumberBatc1End: 'Set 1st mating nr',
	ValidateNumberBatc2End: 'Set end 2st mating nr',
	ValidateCurrentNumberBatch: 'Set current 2. mating nr.',
	ValidatePriority: 'Set priority for rows with same breed',
	KS: 'AI',
	Nat: 'Nat',
	EntranceAIBoar: 'AI',
	EntranceBoar: 'Nat',
	IsAIBoar: 'Is AI boar',
	ValidateNoBreedRace: 'No breed race',
	ValidateNoMaleOrFemale: 'Type alive female male pigs',
	ValidateNoSowFound: 'No sow found',
	ValidateNoBreedingNumber: 'The sow does not have a breeding number',
	ValidateExistingIdNumbers: 'The following ID numbers already exists {{area}}',
	CredentialsAreInvalid: 'Credentials are invalid',
	Archive: 'Archive',
	FileArchive: 'File archive',
	ArchiveSendData: 'Archive data send',
	FileName: 'File name',
	SentDate: 'Sent date',
	Open: 'Open',
	RaceLine: 'Race line',

	// Family tree
	FamilyTree: 'Family tree',
	Family: {
		TheAnimal: 'The animal',
		Mother: 'Mother',
		Father: 'Father',
		MotherGrandmother: 'Grandmother (mother)',
		MotherGrandfather: 'Grandfather (mother)',
		FatherGrandmother: 'Grandmother (father)',
		FatherGrandfather: 'Grandfather (father)',
	},
	// Breed table
	DadBreedRace: 'Dad breed',
	MomBreedRace: 'Mom breed',
	SKIOLD: 'SKIOLD digital',
	DANBRED: 'Danbred',
	DanBred: 'Danbred',
	DANBRED_NUMBER: 'Danbred number',
	DANISH_GENETICS: 'Danish Genetics',
	DanishGenetics: 'Danish Genetics',
	DANISH_GENETICS_ID_NUMMER: 'DanishGenetics letter',
	AddNewBreed: 'Add new breed',
	// Status texts
	InProgress: 'In progress',
	// Daily feed usage
	DailyFeedUsageInt: 'Feed usage {{area}}',
	Day: 'Day',
	FUCurve: 'FU curve',
	FUPigDay: 'FU/pig/day',
	finisherDailyFeedUsage: 'Daily feed usage',
	weanerDailyFeedUsage: 'Daily feed usage',
	NoAdjustmentOnDate: 'No adjustments have been made today',
	Old: 'Old',
	New: 'New',
	Adjustments: 'Adjustments',
	DailyFeedUsageWeaner: 'Overview daily feed usage APP',
	DailyFeedUsageProcessFinisher: 'Overview daily feed usage APP',
	EnergyPrPig: 'Energy pr. pig',

	// Sow Task Worklist
	CalculateTreatment: 'Calculate treatment by',
	SowWeightAtTreatment: 'Sow weight at treatment',
	GiltWeightAtTreatment: 'Gilt weight at treatment',
	MatingPregnant: 'Mating - Pregnant',
	FarrowingNursing: 'Farrowing - Nursing',
	WeaningDry: 'Weaning - TDry',
	SowTaskSettings1: 'Sow tasks 1',
	SowTaskSettings2: 'Sow tasks 2',
	SpecifyMLTreatment: 'Specify ml for chosen rows',

	ChangeFeed: 'Change feed',
	FetchSiteInformation: 'Fetch site information',
};
