import { AnimalKind, AmountType } from "shared/api/api";
import { localized } from "shared/state/i18n/i18n";


export function getPigType(animalKinds: AnimalKind[]) {
    if (animalKinds) {
        if (animalKinds.find(a => a === AnimalKind.Boar || a === AnimalKind.Gilt || a === AnimalKind.Sow)) {
            return localized('SowBoarGlit');
        } else if (animalKinds.find(a => a === AnimalKind.YoungFemale)) {
            return localized('youngFemale');
        } else if(animalKinds.find(ak=> ak  === AnimalKind.Piglet)){
            return localized('Piglet');
        }else if(animalKinds.find(ak=> ak  === AnimalKind.Weaner)){
            return localized(AnimalKind.Weaner);
        }
        else if(animalKinds.find(ak=> ak  === AnimalKind.Finisher)){
            return localized( AnimalKind.Finisher);
        }
    }
    return '';
}
export function getAdjustmentType(amountType: AmountType) {
    if (amountType === AmountType.Adjustment) {
        return localized('AddToDrugStock');
    } else if (amountType === AmountType.Waste) {
        return localized('Waste');
    } else {
        return '';
    }
}
