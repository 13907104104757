import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IAnalysisResultYoungFemaleAgeSetting } from 'shared/api/api';
import { RefType } from 'shared/helpers/ref-type';
import { GetElaborationAnalysisResultYoungFemaleAgeData } from 'shared/state/ducks/analysis/result-young-female-age/operations';
import { SaveAnalysisSetting } from 'shared/state/ducks/analysis/settings/operations';
import { localized } from 'shared/state/i18n/i18n';
import styled from 'styled-components';
import { WebAppState } from 'web/state/store.web';
import { SkioldModalButtonContainer } from 'web/view/components/skiold-components/skiold-modal/skiold-modal-button-container/skiold-modal-button-container';
import { Heading } from 'web/view/components/utils/heading';
import { ViewWeb } from 'web/view/components/utils/web-view';
import AnalysisResultsYoungFemaleAgeModalTable, {
	AnalysisResultsYoungFemaleAgeModalTable as AnalysisResultsYoungFemaleAgeModalTableRef,
} from './analysis-result-young-female-age-modal-table';

const OuterDiv = styled.div`
	margin: 0px 16px;
`;

interface PropsFromParent {
	dateFrom?: Date;
	dateTo?: Date;
	setting: IAnalysisResultYoungFemaleAgeSetting;
	closeModal: () => void;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		siteId: state.profile.active!.siteId,
		profile: state.profile.active!,
		dashboardTableItems:
			state.dashboardOverview.data && state.dashboardOverview.data.dashboardTableData
				? state.dashboardOverview.data.dashboardTableData
				: {},
		loading: state.dashboardOverview.loading,
		matingBatches: state.matingBatches.entities,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
	return {
		getAnalysisElaborationResultYoungFemaleAgeData: (fromDate?: Date, toDate?: Date) =>
			GetElaborationAnalysisResultYoungFemaleAgeData(fromDate, toDate)(dispatch),

		saveAnalysisSettings: setting => SaveAnalysisSetting(setting)(dispatch),
	};
};

export interface State {
	dateFrom?: Date;
	dateTo?: Date;
	modalOpen: boolean;
	selectedModal?: string;
	selectedYoungFemaleRange: string;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

export class AnalysisResultsYoungFemaleAgeModalOverview extends React.PureComponent<Props, State> {
	public analysisResultsPerLitterTableRef: AnalysisResultsYoungFemaleAgeModalTableRef | undefined;
	constructor(props: Props) {
		super(props);
		props.getAnalysisElaborationResultYoungFemaleAgeData(props.dateFrom, props.dateTo);
		this.state = {
			modalOpen: false,
			selectedModal: undefined,
			selectedYoungFemaleRange: '',
		};
	}

	public render() {
		return (
			<ViewWeb>
				<Heading text={localized('analysisElaborationYoungFemaleAge')} />
				<OuterDiv className="analysisModalOverview">
					<ViewWeb className="analysis-top-container">
						<ViewWeb className="end sizing vertical-center">
							<SkioldModalButtonContainer closeModal={this.props.closeModal}></SkioldModalButtonContainer>
						</ViewWeb>
					</ViewWeb>
					<ViewWeb className="viewContainer">
						<AnalysisResultsYoungFemaleAgeModalTable setting={this.props.setting} />
					</ViewWeb>
				</OuterDiv>
			</ViewWeb>
		);
	}
}

export default connect<ReturnType<typeof mapStateToProps>, ReturnType<typeof mapDispatchToProps>, RefType, WebAppState>(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{
		forwardRef: true,
	}
)(AnalysisResultsYoungFemaleAgeModalOverview);
