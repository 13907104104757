import { TableGroupRow } from '@devexpress/dx-react-grid-material-ui';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setOneDecimalsAsNumber } from 'shared/helpers/general-helpers';
import { SetDashboardItems, SetSummaryItems } from 'shared/state/ducks/dashboard-growth-pigs-events/operations';
import { localized } from 'shared/state/i18n/i18n';
import { SkioldTableGroupGrid } from '../../skiold-components/skiold-table/skiold-table-grouped-grid/Skiold-table-grouped-grid';
import {
	SummaryRowItem,
	TableKeyValue,
} from '../../skiold-components/skiold-table/skiold-table-grouped-grid/skiold-table-grouped-grid-helper';
import { setTableItems } from '../dash-growth-pigs-helper';
import { DashboardEntranceDepartureModel } from '../models/dashboard-entrance-departure-model';

interface PropsFromParent {
	data: DashboardEntranceDepartureModel[];
}

const GroupCellContent = rest => {
	return (
		<TableGroupRow.Content {...rest} className="summaryItem">
			{rest.row.value}
		</TableGroupRow.Content>
	);
};

const nameGroupCriteria = value => {
	const groupString =
		localized('identifierToFinisher') +
		localized('identifierToSow') +
		localized('identifierToWeaner') +
		localized('identifierToYoungFemale');
	return {
		value: groupString.includes(value) ? localized('gpeDashboardMoved') : value,
		key: value.substr(0, 2),
	};
};

export const DashboardGpeDepartureTable = ({ data }: PropsFromParent) => {
	const dispatch = useDispatch();
	const summaryItems = [
		{ columnName: 'pigCount', type: 'pigCount' },
		{ columnName: 'avgWeight', type: 'avgWeight' },
	];

	const summaryMessages = {
		avgWeight: 'Gns. vægt',
		pigCount: 'Total antal',
	};

	const weightSummary = (items: DashboardEntranceDepartureModel[]) => {
		let totalWeight = 0;
		let totalPigs = 0;
		items.forEach(item => {
			if (item.pigCount !== undefined && item.totalWeight !== undefined) {
				totalWeight += item.totalWeight;
				totalPigs += item.pigCount;
			}
		});
		return totalWeight && totalPigs ? setOneDecimalsAsNumber(totalWeight / totalPigs) : 0;
	};

	const pigCountSummary = (items: DashboardEntranceDepartureModel[]) => {
		let totalPigs = 0;
		items.forEach(item => {
			if (item.pigCount !== undefined) {
				totalPigs += item.pigCount;
			}
		});
		return totalPigs;
	};

	const generateColumns = () => [
		{
			columnName: 'gpeEventType',
			name: 'gpeEventType',
			width: 140,
			title: localized('departedDashboard'),
			getCellValue: getEventTypeCell,
		},
		{ columnName: 'dateString', name: 'dateString', width: 140, title: localized('date') },
		{
			columnName: 'pigCount',
			name: 'pigCount',
			width: 140,
			title: localized('Count'),
			summaryMethod: pigCountSummary,
		},
		{
			columnName: 'avgWeight',
			name: 'avgWeight',
			width: 140,
			title: localized('avgWeightShort'),
			getCellValue: getAvgWeightCell,
			summaryMethod: weightSummary,
		},
	];

	// Get filtered data from table

	const setDashboardItem = useCallback(
		(summaryRowItems: SummaryRowItem[]) => {
			SetDashboardItems('DashboardGpeDeparture', setTableItems(summaryRowItems))(dispatch);
		},
		[dispatch],
	);

	const setTotalSummary = useCallback(
		(summaryRowItems: TableKeyValue[]) => {
			SetSummaryItems('DashboardGpeDeparture', summaryRowItems)(dispatch);
		},
		[dispatch],
	);

	const getEventTypeCell = (item: DashboardEntranceDepartureModel) =>
		item.gpeEventType && localized(item.gpeEventType);
	const getAvgWeightCell = (item: DashboardEntranceDepartureModel) =>
		item.totalWeight && item.pigCount ? (item.totalWeight / item.pigCount).toFixed(1) : undefined;

	return (
		<SkioldTableGroupGrid
			tableKey={'DashboardGpeDeparture'}
			rowsToRender={10}
			summaryItems={summaryItems}
			groupBy="gpeEventType"
			columns={generateColumns()}
			data={data}
			summaryMessages={summaryMessages}
			totalSummaryChanged={setTotalSummary}
			totalSummrayItems={summaryItems}
			// containerClass="top-border"
			tableItemsChange={setDashboardItem}
			groupCellContent={GroupCellContent}
			groupCritiria={nameGroupCriteria}
		/>
	);
};
