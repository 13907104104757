import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IUserProfile } from 'shared/api/api';
import PrinterGreyIcon from 'shared/assets/src-assets/png/printer_ikon_grey.png';
import { getDateString } from 'shared/helpers/date-helpers';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { SkioldIconSpinner } from '../../utils/skiold-icon-spinner';
import { DashboardElaborationPdf } from '../dash-growth-pigs-helper';

interface PropsFromParent {
	locationString: string;
	penString: string;
	active: IUserProfile | undefined;
	lastBalanced: Date;
}

export const DashboardGpePrint: FC<PropsFromParent> = React.memo(
	({ locationString, penString, active, lastBalanced }) => {
		const { summaryItems, dashboardItems } = useSelector((state: WebAppState) => state.dashboardGrowthPigsOverview);
		const [entranceSummary, setEntranceSummary] = useState<string>('');
		const [departureSummary, setDepartureSummary] = useState<string>('');

		useEffect(() => {
			let entranceString = '';
			summaryItems['DashboardGpeEntrance'] &&
				summaryItems['DashboardGpeEntrance'].forEach(column => {
					if (column.columnKey === 'pigCount') {
						entranceString += `${localized('enteredSince')} ${getDateString(lastBalanced)}: ${
							column.columnValue
						}  `;
					} else {
						entranceString += `  ${localized(column.columnKey)}: ${column.columnValue}`;
					}
				});
			let departedString = '';
			summaryItems['DashboardGpeDeparture'] &&
				summaryItems['DashboardGpeDeparture'].forEach(column => {
					if (column.columnKey === 'pigCount') {
						departedString += `${localized('departedSince')} ${getDateString(lastBalanced)}: ${
							column.columnValue
						}  `;
					} else {
						departedString += `  ${localized(column.columnKey)}: ${column.columnValue}`;
					}
				});

			setEntranceSummary(entranceString);
			setDepartureSummary(departedString);
		}, [summaryItems, lastBalanced]);

		const printDashboardElaboration = async () => {
			if (active) {
				await DashboardElaborationPdf(
					dashboardItems['DashboardGpeEntrance'] ? dashboardItems['DashboardGpeEntrance'] : [],
					dashboardItems['DashboardGpeDeparture'] ? dashboardItems['DashboardGpeDeparture'] : [],
					entranceSummary,
					departureSummary,
					'Growth pig dashboard.pdf',
					active.siteId!,
					Intl.DateTimeFormat().resolvedOptions().timeZone,
					active.language,
					`${locationString} ${penString ? '- ' + penString : ''}`,
				);
			}
		};

		return (
			<SkioldIconSpinner
				containerClassName={'flex-1 align-right'}
				title={'Print'}
				icon={PrinterGreyIcon}
				onPress={printDashboardElaboration}
			/>
		);
	},
);
