import React, { FC, useMemo } from 'react';
import { AnalysisDepartureSowTableData, AnalysisDepartureSowTableItem } from 'shared/api/api';
import { localized } from 'shared/state/i18n/i18n';
import SkioldTableGrid from '../../skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { ViewWeb } from '../../utils/web-view';

export interface PropsFromParent {
	tableData: AnalysisDepartureSowTableData | undefined;
	asPercentage: boolean;
}

export const AnalysisDepartureTypeSowTable: FC<PropsFromParent> = React.memo(({ tableData, asPercentage }) => {
	const data = useMemo(() => {
		if (tableData && tableData.departureType) {
			return Object.keys(tableData.departureType!).map(e => tableData.departureType![e]);
		}
		return [];
	}, [tableData]);
	const columns = useMemo(() => {
		let columns: any[] = [
			{
				name: 'animalCount',
				title: localized('AnalysisDeparture.NumberOfAnimals'),
			},
			{
				name: 'name',
				title: localized('departureType'),
			},
			{
				name: 'litter0',
				title: '0',
				getCellValue: (x: AnalysisDepartureSowTableItem) => (x.litter0 !== 0 ? x.litter0 : ''),
			},
			{
				name: 'litter1',
				title: '1',
				getCellValue: (x: AnalysisDepartureSowTableItem) => (x.litter1 !== 0 ? x.litter1 : ''),
			},
			{
				name: 'litter2',
				title: '2',
				getCellValue: (x: AnalysisDepartureSowTableItem) => (x.litter2 !== 0 ? x.litter2 : ''),
			},
			{
				name: 'litter3',
				title: '3',
				getCellValue: (x: AnalysisDepartureSowTableItem) => (x.litter3 !== 0 ? x.litter3 : ''),
			},
			{
				name: 'litter4',
				title: '4',
				getCellValue: (x: AnalysisDepartureSowTableItem) => (x.litter4 !== 0 ? x.litter4 : ''),
			},
			{
				name: 'litter5',
				title: '5',
				getCellValue: (x: AnalysisDepartureSowTableItem) => (x.litter5 !== 0 ? x.litter5 : ''),
			},
			{
				name: 'litter6',
				title: '6',
				getCellValue: (x: AnalysisDepartureSowTableItem) => (x.litter6 !== 0 ? x.litter6 : ''),
			},
			{
				name: 'litter7',
				title: '7',
				getCellValue: (x: AnalysisDepartureSowTableItem) => (x.litter7 !== 0 ? x.litter7 : ''),
			},
			{
				name: 'litter8',
				title: '8',
				getCellValue: (x: AnalysisDepartureSowTableItem) => (x.litter8 !== 0 ? x.litter8 : ''),
			},
			{
				name: 'litter9Plus',
				title: '9+',
				getCellValue: (x: AnalysisDepartureSowTableItem) => (x.litter9Plus !== 0 ? x.litter9Plus : ''),
			},
			{
				name: 'totalPct',
				title: localized('TotalPct'),
			},
		];
		return columns;
	}, []);

	const columnsExt = useMemo(() => {
		let columnsExt: any[] = [
			{
				columnName: 'animalCount',
				width: 185,
			},
			{
				columnName: 'name',
				width: 150,
			},
			{
				columnName: 'litter0',
				width: 100,
			},
			{
				columnName: 'litter1',
				width: 100,
			},
			{
				columnName: 'litter2',
				width: 100,
			},
			{
				columnName: 'litter3',
				width: 100,
			},
			{
				columnName: 'litter4',
				width: 100,
			},
			{
				columnName: 'litter5',
				width: 100,
			},
			{
				columnName: 'litter6',
				width: 100,
			},
			{
				columnName: 'litter7',
				width: 100,
			},
			{
				columnName: 'litter8',
				width: 100,
			},
			{
				columnName: 'litter9Plus',
				width: 100,
			},
			{
				columnName: 'totalPct',
				width: 100,
			},
		];
		return columnsExt;
	}, []);

	const groupedColumns = useMemo(() => {
		let groupedColumns: any[] = [
			{
				title: asPercentage
					? localized('AnalysisDeparture.PctAnimalLitterNumber')
					: localized('AnalysisDeparture.CountAnimalLitterNumber'),
				children: [
					{ columnName: 'litter0' },
					{ columnName: 'litter1' },
					{ columnName: 'litter2' },
					{ columnName: 'litter3' },
					{ columnName: 'litter4' },
					{ columnName: 'litter5' },
					{ columnName: 'litter6' },
					{ columnName: 'litter7' },
					{ columnName: 'litter8' },
					{ columnName: 'litter9Plus' },
				],
			},
		];
		return groupedColumns;
	}, [asPercentage]);

	return (
		<ViewWeb className="analysis-overview-table upper-border-radius">
			<SkioldTableGrid
				tableKey={'analysisDeparture3'}
				columns={columns}
				ColumnExtensions={columnsExt}
				groupedColumns={groupedColumns}
				data={data}
				sortable={false}
				ignoreSetCount={true}
				filterable={false}
				rowStyling={'td-font-weight-bold'}
				rowStylingIndicator={{ fromBottom: 1, fromTop: 0 }}
			/>
		</ViewWeb>
	);
});
