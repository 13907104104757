import React, { FC, useCallback, useEffect, useState } from 'react';
import { Option } from 'react-dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { AnimalType, GrowthPigsEvent, IGrowthPigEntranceEventDto, LocationType } from 'shared/api/api';
import {
	BalancedWeanedPigsetAmountAccordingToMaxAmount,
	BalanceWeanedPigsGenerateInitialDataFromProps,
	BalanceWeanedPigsHandleOnPenChanged,
	BalanceWeanedPigsHandleSaveDistributeWeanedPigs,
	BalanceWeanedPigsPenIdsToUse,
	balanceWeanedPigsproductionTypeOptions,
	distributeWeanedPigsSetAmount,
	getAvgWeightParentAndTotalBalanceWeanedPigs,
	getCorrectProductionTypeForBalanceWeanedPigs,
} from 'shared/helpers/balance-weaned-pigs-helper/balance-weaned-pigs-helper';
import { generateDataForEntranceDistribute } from 'shared/helpers/growth-pigs-helper/growth-pig-event-function-helper';
import { DistributeTableDataTypes } from 'shared/helpers/growth-pigs-helper/growth-pig-event-helper';
import { CheckIfSectionUsesPens, getLocationStringBySectionId } from 'shared/helpers/location-helper';
import { memoizeHashmapLocation } from 'shared/helpers/memoize-getters/memoize-getters';
import { NaturalSort } from 'shared/helpers/natural-sort';
import { SectionHasDistriwin } from 'shared/state/ducks/unit-to-pen/reducer';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { DistriwinFeedCurvePicker } from 'web/view/components/distriwin-components/distriwin-feed-curve-picker';
import { DistriwinRecipePicker } from 'web/view/components/distriwin-components/distriwin-recipe-picker';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldDatePicker } from 'web/view/components/skiold-components/skiold-date-picker/skiold-date-picker';
import { SkioldDropdown } from 'web/view/components/skiold-components/skiold-dropdown/skiold-dropdown';
import { SkioldFormDropdown } from 'web/view/components/skiold-components/skiold-dropdown/skiold-form-dropdown';
import { SkioldModal } from 'web/view/components/skiold-components/skiold-modal/skiold-modal';
import { SkioldIntegerTextField } from 'web/view/components/skiold-components/skiold-text-field/skiold-integer-text-field';
import { GrowthPigDistributeTable } from 'web/view/components/stem-animal/growth-pig-events/growth-pig-distribute-table';
import { WhiteText } from 'web/view/components/Text/white-text';
import { Heading } from 'web/view/components/utils/heading';
import { ViewWeb } from 'web/view/components/utils/web-view';

interface PropsFromParent {
	isOpen: boolean;
	eventsToUse: GrowthPigsEvent[] | undefined;
	closeModal: () => void;
}

const blankOption = { label: ' ', value: '' };

export const DistributeWeanedPigsModal: FC<PropsFromParent> = React.memo(props => {
	const dispatch = useDispatch();
	const sections = useSelector((state: WebAppState) => state.locations.sections);
	const pens = useSelector((state: WebAppState) => state.locations.pens);
	const profile = useSelector((state: WebAppState) => state.profile.active);
	const hashmapLocations = useSelector((state: WebAppState) =>
		memoizeHashmapLocation(state.locations.buildings, state.locations.sections, state.locations.pens),
	);
	const generalSettings = useSelector((state: WebAppState) => state.generalSettings.entity);
	const totalWeight = useSelector((state: WebAppState) => state.balanceWeanedPigsData.totalAmountAndTotalWeight);
	const currentPigsOnStable = useSelector((state: WebAppState) => state.balanceWeanedPigsData.currentPigsOnStable);
	const data = useSelector((state: WebAppState) => state.balanceWeanedPigsData.entities);
	let {
		selectedFromPenOption,
		selectedToPenOption,
		selectedSectionOption,
		selectedProductionOption,
		selectedAmountToUse,
	}: {
		selectedFromPenOption: Option;
		selectedToPenOption: Option;
		selectedSectionOption: Option;
		selectedProductionOption: Option;
		selectedAmountToUse: number | undefined;
	} = BalanceWeanedPigsGenerateInitialDataFromProps(hashmapLocations, props.eventsToUse);

	const [isInit, setIsInit] = useState<boolean>(!!props.eventsToUse && props.eventsToUse.length > 0);
	const [penOptions, setPenOptions] = useState<Option[]>([]);
	const [sectionOptions, setSectionOptions] = useState<Option[]>([]);
	const { avgWeightParent, total } = getAvgWeightParentAndTotalBalanceWeanedPigs(totalWeight, data);
	const [selectedFromPen, setSelectedFromPen] = useState<Option>(selectedFromPenOption);
	const [selectedToPen, setSelectedToPen] = useState<Option>(selectedToPenOption);
	const [selectedSection, setSelectedSection] = useState<Option>(selectedSectionOption);
	const [selectedProduction, setSelectedProduction] = useState<Option>(selectedProductionOption);
	const [productionOptions, setProductionOptions] = useState<Option[]>([]);
	const [amountToUseForSetTableData, setAmountToUseForSetTableData] = useState<number | undefined>(
		selectedAmountToUse,
	);
	const [amountToUse, setAmountToUse] = useState<number | undefined>(selectedAmountToUse);
	const [tableData, setTableData] = useState<DistributeTableDataTypes[]>([]);
	const [dateToUse, setDateToUse] = useState(
		props.eventsToUse && props.eventsToUse.length > 0 ? props.eventsToUse[0].date! : new Date(),
	);
	const [feedCurve, setFeedCurve] = useState<number | undefined>(undefined);
	const [recipe, setRecipe] = useState<number | undefined>(undefined);

	const resetComponent = useCallback(() => {
		if (props.eventsToUse) {
			props.closeModal();
		}
		setSelectedFromPen(blankOption);
		setSelectedToPen(blankOption);
		setSelectedSection(blankOption);
		setAmountToUse(undefined);
		setAmountToUseForSetTableData(undefined);
		setTableData([]);
	}, [setSelectedFromPen, setSelectedToPen, setSelectedSection, setAmountToUse, setTableData, props]);

	useEffect(() => {
		if (props.eventsToUse && props.eventsToUse.length > 0) {
			return;
		}
		const selectedFromPenCopy = penOptions.length > 0 ? penOptions[0] : blankOption;
		const selectedToPenCopy = penOptions.length > 0 ? penOptions[penOptions.length - 1] : blankOption;
		setSelectedFromPen(selectedFromPenCopy);
		setSelectedToPen(selectedToPenCopy);
		setSelectedSection(selectedSection);
		const mapped = sections
			.filter(
				sec =>
					((selectedProduction.value === AnimalType.Finisher ||
						selectedProduction.value === AnimalType.Weaner) &&
						sec.animalType === selectedProduction.value) ||
					(selectedProduction.value === AnimalType.YoungFemale &&
						sec.animalType === AnimalType.Sow &&
						sec.type === LocationType.YoungFemale) ||
					(selectedProduction.value === AnimalType.FRATS &&
						sec.animalType === AnimalType.FRATS &&
						sec.type &&
						(sec.type === LocationType.ReliefFinisher ||
							sec.type === LocationType.ReliefWeaners ||
							sec.type === LocationType.Finisher ||
							sec.type === LocationType.Weaners)),
			)
			.map(s => {
				const stableSectionLabel = getLocationStringBySectionId(s.id, hashmapLocations);
				return { label: stableSectionLabel ? stableSectionLabel : '', value: s.id! };
			});
		setSectionOptions(mapped);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedProduction, sections, hashmapLocations, penOptions]);

	useEffect(() => {
		const pensToUse = pens
			.filter(pen => pen.sectionId === selectedSection.value)
			.sort((a, b) => NaturalSort(a.name, b.name));

		const mapped = pensToUse.map(p => {
			return { label: p.name!, value: p.id! };
		});
		setPenOptions(mapped);
		let penIds = BalanceWeanedPigsPenIdsToUse(mapped, selectedFromPen, selectedToPen);
		if (!isInit) {
			const selectedFromPenCopy = mapped.length > 0 ? mapped[0] : blankOption;
			const selectedToPenCopy = mapped.length > 0 ? mapped[mapped.length - 1] : blankOption;
			setSelectedFromPen(selectedFromPenCopy);
			setSelectedToPen(selectedToPenCopy);
			penIds = BalanceWeanedPigsPenIdsToUse(mapped, selectedFromPenCopy, selectedToPenCopy);
		}
		setTableData(
			generateDataForEntranceDistribute(
				pensToUse.filter(a => a.id && penIds.includes(a.id)),
				selectedSection.value,
				undefined,
				amountToUseForSetTableData,
				avgWeightParent,
				new Date(),
				profile,
				new Date(),
				(selectedProduction.value as AnimalType) === AnimalType.FRATS
					? getCorrectProductionTypeForBalanceWeanedPigs(selectedSection, hashmapLocations)
					: (selectedProduction.value as AnimalType),
				props.eventsToUse,
			),
		);
	}, [
		selectedSection,
		pens,
		amountToUseForSetTableData,
		avgWeightParent,
		profile,
		selectedProduction.value,
		hashmapLocations,
		props,
		isInit,
	]);

	const saveDistributeWeanedPigs = useCallback(() => {
		BalanceWeanedPigsHandleSaveDistributeWeanedPigs(
			tableData,
			profile,
			dateToUse,
			generalSettings.weanedPenId,
			hashmapLocations,
			avgWeightParent,
			props.eventsToUse,
			dispatch,
			resetComponent,
			feedCurve,
			recipe,
		);
	}, [
		tableData,
		profile,
		dateToUse,
		generalSettings,
		hashmapLocations,
		avgWeightParent,
		props,
		dispatch,
		resetComponent,
		feedCurve,
		recipe,
	]);

	const dateChanged = useCallback(
		(date: Date) => {
			setDateToUse(date);
		},
		[setDateToUse],
	);

	const onPenChange = useCallback(
		(option: Option | undefined, itemFromParent: 'toPen' | 'fromPen') => {
			BalanceWeanedPigsHandleOnPenChanged(
				option,
				itemFromParent,
				penOptions,
				selectedToPen,
				selectedFromPen,
				setTableData,
				pens,
				selectedSection,
				amountToUse,
				avgWeightParent,
				profile,
				selectedProduction,
				setSelectedFromPen,
				setSelectedToPen,
			);
		},
		[
			penOptions,
			selectedToPen,
			selectedFromPen,
			setTableData,
			pens,
			selectedSection,
			amountToUse,
			avgWeightParent,
			profile,
			selectedProduction,
			setSelectedFromPen,
			setSelectedToPen,
		],
	);

	const setProduction = useCallback(
		(option: Option | undefined) => {
			if (option) {
				setSelectedProduction(option);
			}
		},
		[setSelectedProduction],
	);
	const setAmountHeader = useCallback(
		(num?: number) => {
			const numToUse = num;
			BalancedWeanedPigsetAmountAccordingToMaxAmount(
				penOptions,
				selectedFromPen,
				selectedToPen,
				setTableData,
				pens,
				profile,
				selectedSection,
				numToUse,
				avgWeightParent,
				selectedProduction,
				setAmountToUse,
				setAmountToUseForSetTableData,
			);
		},
		[
			penOptions,
			selectedFromPen,
			selectedToPen,
			setTableData,
			pens,
			profile,
			selectedSection,
			avgWeightParent,
			selectedProduction,
			setAmountToUse,
			setAmountToUseForSetTableData,
		],
	);

	const setAmountTable = useCallback(
		(num?: number) => {
			const numToUse = num;
			BalancedWeanedPigsetAmountAccordingToMaxAmount(
				penOptions,
				selectedFromPen,
				selectedToPen,
				setTableData,
				pens,
				profile,
				selectedSection,
				numToUse,
				avgWeightParent,
				selectedProduction,
				setAmountToUse,
			);
		},
		[
			penOptions,
			selectedFromPen,
			selectedToPen,
			setTableData,
			pens,
			profile,
			selectedSection,
			avgWeightParent,
			selectedProduction,
			setAmountToUse,
		],
	);

	const setSection = useCallback(
		(option: Option | undefined) => {
			if (option) {
				setIsInit(false);
				setSelectedSection(option);
			}
		},
		[setSelectedSection],
	);

	useEffect(() => {
		const { options, selectedOption } = balanceWeanedPigsproductionTypeOptions(sections, props.eventsToUse);
		setSelectedProduction(selectedOption);
		setProductionOptions(options);
	}, [sections, setProductionOptions, setSelectedProduction, props.eventsToUse]);

	const onAmountChanged = useCallback(
		(numb: number | undefined, item: IGrowthPigEntranceEventDto) => {
			let dataArrayCopy = [...tableData];
			distributeWeanedPigsSetAmount(dataArrayCopy, item, numb);
			setTableData(dataArrayCopy);
		},
		[tableData, setTableData],
	);

	const onWeightChanged = useCallback(
		(numb: number | undefined, item: IGrowthPigEntranceEventDto) => {
			let dataArrayCopy = [...tableData];
			const indexToModify = dataArrayCopy.findIndex(a => a.toPenId === item.toPenId);
			if (indexToModify >= 0) {
				const dataToModify = { ...dataArrayCopy[indexToModify] };
				dataToModify.avgWeight = numb!;
				dataToModify.totalWeight =
					numb && dataToModify.amount ? numb * dataToModify.amount : dataToModify.totalWeight;
				dataArrayCopy[indexToModify] = dataToModify;
			}
			setTableData(dataArrayCopy);
		},
		[setTableData, tableData],
	);
	const renderHeader = useCallback(
		() =>
			renderHeaderElement(
				dateToUse,
				dateChanged,
				(currentPigsOnStable ? currentPigsOnStable : 0) +
					(props.eventsToUse
						? props.eventsToUse.reduce((num, etu) => num + (etu.amount ? etu.amount : 0), 0)
						: 0),
				amountToUse,
				setAmountHeader,
				setProduction,
				productionOptions,
				selectedProduction,
				setSection,
				sectionOptions,
				selectedSection,
				onPenChange,
				penOptions,
				selectedFromPen,
				selectedToPen,
			),

		[
			dateToUse,
			dateChanged,
			currentPigsOnStable,
			amountToUse,
			setAmountHeader,
			setProduction,
			productionOptions,
			selectedProduction,
			setSection,
			sectionOptions,
			selectedSection,
			onPenChange,
			penOptions,
			selectedFromPen,
			selectedToPen,
			props,
		],
	);

	const onBlur = useCallback(() => {
		let total = 0;
		for (let i of tableData) {
			if (i.amount) {
				total += i.amount;
			}
		}
		setAmountToUse(total);
	}, [tableData, setAmountToUse]);

	const renderTable = () => {
		return (
			<GrowthPigDistributeTable
				data={tableData}
				disablePenColumn={!CheckIfSectionUsesPens(selectedSection.value)}
				onAmounthanged={onAmountChanged}
				onWeightChanged={onWeightChanged}
				onBlur={onBlur}
			/>
		);
	};
	const feedCurveChanged = useCallback((feedCurve: number) => {
		setFeedCurve(feedCurve);
	}, []);

	const recipeChanged = useCallback((recipeNumber: number) => {
		setRecipe(recipeNumber);
	}, []);

	return (
		<SkioldModal overflowNone={true} padding="0" isOpen={props.isOpen} close={props.closeModal}>
			<Heading text={localized('DistributeWeanedPigs')} />
			<ViewWeb className="distribute-weaned-pigs-container">
				<ViewWeb className="flex-row-space-between-100p">
					<ViewWeb className="flex-column">
						{renderHeader()}
						{SectionHasDistriwin(selectedSection.value) &&
							((selectedProduction.value === AnimalType.Finisher &&
								generalSettings.sendEntranceDepartureDistriwinFinisher) ||
								(selectedProduction.value === AnimalType.Weaner &&
									generalSettings.sendEntranceDepartureDistriwinWeaner)) && (
								<ViewWeb className="flexDirectionRow default-gap align-items-center">
									<WhiteText className="pen-text">{localized('feedCurve')}:</WhiteText>
									<DistriwinFeedCurvePicker
										sectionId={selectedSection.value}
										onSelect={feedCurveChanged}
									/>
									<WhiteText className="pen-text">{localized('Recipe')}:</WhiteText>
									<DistriwinRecipePicker onSelect={recipeChanged} />
								</ViewWeb>
							)}
					</ViewWeb>
					<ViewWeb className="button-container">
						<SkioldButton
							className="save-button"
							title={localized('Save')}
							onPress={saveDistributeWeanedPigs}
						/>
						<SkioldButton
							className="save-button"
							theme="grey"
							title={localized('Close')}
							onPress={props.closeModal}
						/>
					</ViewWeb>
				</ViewWeb>

				{renderTable()}
			</ViewWeb>
		</SkioldModal>
	);
});

function renderHeaderElement(
	dateToUse: Date,
	dateChanged: (date: Date) => void,
	total: number | undefined,
	amountToUse: number | undefined,
	setAmount: (num?: number | undefined) => void,
	setProduction: (option: Option | undefined) => void,
	productionOptions: Option[],
	selectedProduction: Option,
	setSection: (option: Option | undefined) => void,
	sectionOptions: Option[],
	selectedSection: Option,
	onPenChange: (option: Option | undefined, itemFromParent: 'toPen' | 'fromPen') => void,
	penOptions: Option[],
	selectedFromPen: Option,
	selectedToPen: Option,
) {
	return (
		<ViewWeb className={'minWidth545'}>
			<ViewWeb className="flexDirectionRowSpaceBetween">
				<WhiteText className="pen-text">{localized('Date') + ':'}</WhiteText>
				<SkioldDatePicker
					selectedDate={dateToUse}
					onDateChanged={dateChanged}
					theme={'light'}
					backwardDateSetEndTime={true}
				></SkioldDatePicker>
			</ViewWeb>
			<ViewWeb className="flexDirectionRowSpaceBetween">
				<WhiteText className="pen-text">{localized('ToBeDistributed') + ':'}</WhiteText>
				<WhiteText className="amountNotDistributedFont">
					{(total ? total : 0) - (amountToUse ? amountToUse : 0)}
				</WhiteText>
			</ViewWeb>
			<ViewWeb className="single-dropdown-container">
				<WhiteText className="pen-text">{localized('ToDistributed')}</WhiteText>
				<SkioldIntegerTextField
					className="amountTextField"
					theme="light"
					onChangeNumber={setAmount}
					value={amountToUse}
				/>
			</ViewWeb>
			<ViewWeb className="single-dropdown-container">
				<WhiteText className="pen-text">{localized('toProductionType')}</WhiteText>
				<SkioldDropdown
					theme="light"
					containerClassName="flex1"
					onValueChanged={setProduction}
					items={productionOptions}
					selectedValue={selectedProduction}
				/>
			</ViewWeb>
			<ViewWeb className="single-dropdown-container">
				<WhiteText className="pen-text">{localized('ToLocation')}</WhiteText>
				<SkioldDropdown
					theme="light"
					containerClassName="flex1"
					onValueChanged={setSection}
					items={sectionOptions}
					selectedValue={selectedSection}
				/>
			</ViewWeb>
			{CheckIfSectionUsesPens(selectedSection.value) && (
				<ViewWeb className="flexDirectionRow">
					<ViewWeb className="flex-direction-row-space-between margin-right-thirty">
						<WhiteText className="pen-text">{localized('FromPen')}</WhiteText>
						<SkioldFormDropdown
							theme="light"
							itemFromParent={'fromPen'}
							onValueChanged={onPenChange}
							items={penOptions}
							selectedValue={selectedFromPen}
						/>
					</ViewWeb>
					<WhiteText className="pen-text">{localized('ToPen')}</WhiteText>
					<SkioldFormDropdown
						theme="light"
						itemFromParent={'toPen'}
						onValueChanged={onPenChange}
						items={penOptions}
						selectedValue={selectedToPen}
					/>
				</ViewWeb>
			)}
		</ViewWeb>
	);
}
