import React from 'react';
import { GrowthPigPReportTableData, IProductionReportSetting } from 'shared/api/api';
import { getDateString, getDaysDifference } from 'shared/helpers/date-helpers';
import { setOneDecimalsAsNumber, setOneDecimalsAsString, setTwoDecimalsAsNumber } from 'shared/helpers/general-helpers';
import { localized } from 'shared/state/i18n/i18n';
import { ViewWeb } from 'web/view/components/utils/web-view';
import SkioldTableGrid from '../../skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';

// tmp dates are used in the datepicker as they shouldnt come into effect before the fetch button is clicked
interface State {}

interface PropsFromParent {
	productionReportData?: GrowthPigPReportTableData[];
	isSpecialPeriod: boolean;
	pReportSetting: IProductionReportSetting;
}

type Props = PropsFromParent;
export class GrowthPigProductionReportTable extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {};
	}

	private readonly inlineHeaders = [
		'growthPigPReportState',
		'growthPigPReportSubResults',
		'growthPigPReportSlaughterHouseData',
	];

	private readonly decimalHelpers = {
		DiversePercentDead: 1,
		FeedPerPigDaily: 2,
		FeedPerKgGrowth: 2,
		WeanerReferenceFeedUsage: 2,
		FinisherReferenceFeedUsage: 2,
		FRATSReferenceFeedUsage: 2,
	};

	private readonly customInlineHeaders = [
		'FinisherActivePcs',
		'WeanerActivePcs',
		'TotalEntryInPeriod',
		'DeadPutDownPcs',
		'SoldTransferedPcs',
		'DelieveredPcs',
		'TotalSlaughteredRegistrations',
	];

	public render() {
		return (
			<div className="report-container">
				<ViewWeb className="p-report-outer-div">
					<SkioldTableGrid
						className="production-report"
						tableKey={
							this.props.isSpecialPeriod
								? 'GrowthPigProductionReportTableSpecial'
								: 'GrowthPigProductionReportTable'
						}
						ColumnExtensions={this.generateColumnsExtensions()}
						columns={this.generateColumns()}
						data={this.props.productionReportData ? this.props.productionReportData : []}
						filterable={false}
						InlineHeaders={this.inlineHeaders}
					/>
				</ViewWeb>
			</div>
		);
	}

	private getNameField = (d: GrowthPigPReportTableData) => {
		let name = d.name ? (
			<div
				className={
					'bold alignLeft ' +
					(d.isHeader || this.customInlineHeaders.find(e => e === d.name) ? 'inlineHeader' : '')
				}
			>
				{localized(d.name)}
			</div>
		) : (
			' '
		);
		return name;
	};

	private getValueIndexField = (index: number, periodIndex: number) => {
		return (productionReportData: GrowthPigPReportTableData) => {
			return (
				<div
					className={
						'alignRight ' +
						(this.customInlineHeaders.find(e => e === productionReportData.name) ? 'bold-font' : '')
					}
				>
					{productionReportData.isHeader &&
					productionReportData.name === 'growthPigPReportState' &&
					this.props.pReportSetting &&
					this.props.pReportSetting.periods &&
					this.props.pReportSetting.periods[periodIndex] &&
					this.props.pReportSetting.periods[periodIndex].toDate
						? getDateString(this.props.pReportSetting.periods[periodIndex].toDate)
						: productionReportData.values &&
						  productionReportData.values.length >= index &&
						  productionReportData.values[index - 1]
						? productionReportData.name && this.decimalHelpers[productionReportData.name] === 1
							? setOneDecimalsAsString(productionReportData.values[index - 1])
							: productionReportData.name && this.decimalHelpers[productionReportData.name] === 2
							? setTwoDecimalsAsNumber(productionReportData.values[index - 1])
							: setOneDecimalsAsNumber(productionReportData.values[index - 1])
						: ''}
				</div>
			);
		};
	};

	public generateColumnsExtensions() {
		if (!this.props.isSpecialPeriod) {
			return [
				{
					columnName: 'name',
					width: 325,
				},
				{
					columnName: 'period1',
					width: 70,
				},
				{
					columnName: 'period2',
					width: 70,
				},
				{
					columnName: 'period3',
					width: 70,
				},
				{
					columnName: 'period4',
					width: 70,
				},
				{
					columnName: 'period5',
					width: 70,
				},
			];
		} else {
			return [
				{
					columnName: 'name',
					width: 325,
				},
				{
					columnName: 'specialPeriod',
					width: 70,
				},
			];
		}
	}

	private generateColumns = () => {
		if (!this.props.isSpecialPeriod) {
			let le = 0;
			if (this.props.pReportSetting && this.props.pReportSetting.periods) {
				le = this.props.pReportSetting.periods.filter(e => e.fromDate).length;
			}

			return [
				{
					name: 'name',
					title: localized('Matings'),
					header: (
						<div className="width100 paddingHorizontalEight">
							<div className="marginLeftAuto">
								<div></div>

								<p className="headerInTable alignRight"> {localized('FromDate')}</p>
								<p className="headerInTable alignRight"> {localized('ToDate')}</p>
								<p className="headerInTable alignRight"> {localized('Days')}</p>
							</div>
						</div>
					),
					getCellValue: this.getNameField,
				},
				{
					name: 'period1',
					header: this.generatePeriodHeader(le >= 5 ? le - 5 : 3),
					getCellValue: this.getValueIndexField(1, le >= 5 ? le - 5 : 3),
				},
				{
					name: 'period2',
					header: this.generatePeriodHeader(le >= 4 ? le - 4 : 3),
					getCellValue: this.getValueIndexField(2, le >= 4 ? le - 4 : 3),
				},
				{
					name: 'period3',
					header: this.generatePeriodHeader(le >= 3 ? le - 3 : 3),
					getCellValue: this.getValueIndexField(3, le >= 3 ? le - 3 : 3),
				},
				{
					name: 'period4',
					header: this.generatePeriodHeader(le >= 2 ? le - 2 : 3),
					getCellValue: this.getValueIndexField(4, le >= 2 ? le - 2 : 3),
				},
				{
					name: 'period5',
					header: this.generatePeriodHeader(4),
					getCellValue: this.getValueIndexField(5, 4),
				},
			];
		} else {
			return [
				{
					name: 'name',
					title: localized('Matings'),
					header: (
						<div className="width100">
							<div className="marginLeftAuto">
								<div></div>

								<p className="headerInTable alignRight"> {localized('FromDate')}</p>
								<p className="headerInTable alignRight"> {localized('ToDate')}</p>
								<p className="headerInTable alignRight"> {localized('Days')}</p>
							</div>
						</div>
					),
					getCellValue: this.getNameField,
				},
				{
					name: 'specialPeriod',
					header: this.generatePeriodHeader(0),
					getCellValue: this.getValueIndexField(1, 0),
				},
			];
		}
	};

	private generatePeriodHeader(number: number) {
		if (!this.props.isSpecialPeriod) {
			const days =
				this.props.pReportSetting.periods &&
				this.props.pReportSetting.periods[number] &&
				getDaysDifference(
					this.props.pReportSetting.periods[number].fromDate,
					this.props.pReportSetting.periods[number].toDate,
				);
			return this.props.pReportSetting.periods && this.props.pReportSetting.periods[number] ? (
				<div className="width100">
					<p className="headerInTable">
						{this.props.pReportSetting.periods[number].fromDate &&
							getDateString(this.props.pReportSetting.periods[number].fromDate)}
					</p>
					<p className="headerInTable">
						{this.props.pReportSetting.periods[number].toDate &&
							getDateString(this.props.pReportSetting.periods[number].toDate)}
					</p>
					<p className="headerInTable"> {days ? days : ''}</p>
				</div>
			) : (
				<div />
			);
		} else {
			const days =
				this.props.pReportSetting.specialFromDate &&
				this.props.pReportSetting.specialToDate &&
				getDaysDifference(this.props.pReportSetting.specialFromDate, this.props.pReportSetting.specialToDate);
			return this.props.pReportSetting.specialFromDate && this.props.pReportSetting.specialToDate ? (
				<div className="width100">
					<p className="headerInTable">{getDateString(this.props.pReportSetting.specialFromDate)}</p>
					<p className="headerInTable">{getDateString(this.props.pReportSetting.specialToDate)}</p>
					<p className="headerInTable"> {days ? days : ''}</p>
				</div>
			) : (
				<div />
			);
		}
	}
}
