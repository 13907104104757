import { Dispatch } from 'redux';
import { IMoveEvent, IStemAnimal } from 'shared/api/api';
import { memoizeGetESFProcessEquipment } from 'shared/helpers/memoize-getters/memoize-getters';
import { SetSowsCount } from 'shared/state/ducks/stem-animals/operations';
import { WebAppState } from 'web/state/store.web';

export const ResendEsfListMapStateToProps = (state: WebAppState) => {
	return {
		moveEvents: state.moveEvents.entities,
		stemAnimals: state.stemAnimals.entities,
		departuredSows: state.stemAnimals.departuredAnimals as IStemAnimal[],
		locations: state.locations,
		siteId: state.profile.active && state.profile.active.siteId,
		unitToPens: state.unitToPenData.data,
		processEquipmentData: memoizeGetESFProcessEquipment(state.processEquipmentData.entities),
	};
};

export const ResendEsfListMapDispatchToProps = (dispatch: Dispatch, props: {}) => {
	return {
		setSowsCount: (count: number) => SetSowsCount(count)(dispatch),
	};
};

interface PropsFromParent {
	dateFrom: Date;
	dateTo: Date;
	onSummaryChanged: (element: JSX.Element) => void;
}

export interface ResendEsfListState {
	commitAll: boolean;
	loading: boolean;
	moveEvents: IMoveEvent[];
	listItems: RetryEsfListItem[];
    selectedItems: RetryEsfListItem[];
    animalsRetriedThisSession: string[];
    calculating: boolean;
    
}

export class RetryEsfListItem  {
    public date?: Date | undefined;
    public stemAnimalId?: string | undefined;
    public animalNumber?: string | undefined;
    public fromBuildingName?: string | undefined;
    public fromSectionName?: string | undefined;
    public fromPenName?: string | undefined;
    public fromPenId?: string | undefined;
    public toBuildingName?: string | undefined;
    public toSectionName?: string | undefined;
    public toPenName?: string | undefined;
    public toPenId?: string | undefined;
    public usesSectionsFrom?: boolean | undefined;
    public usesSectionsTo?: boolean | undefined;
    public usesPensFrom?: boolean | undefined;
    public usesPensTo?: boolean | undefined;
    public transponder?: string | undefined;
    public cycleDays?: number | undefined;
    public retryEsfType?: RetryEsfType | undefined;

}

export enum RetryEsfType {
    Departure = "Departure", 
    Entrance = "Entrance", 
    MoveEvent = "MoveEvent", 
}

export class ResendEsfSummaryItem {
	public sowCount?: string;
}

export type ResendEsfListProps = ReturnType<typeof ResendEsfListMapStateToProps> &
	ReturnType<typeof ResendEsfListMapDispatchToProps> &
	PropsFromParent;
