import { default as ObjectID } from 'bson-objectid';
import { FeedingCategory, IFeedingCategory, IUserProfile } from 'shared/api/api';
import { getAnimalTypesInUse } from 'shared/helpers/general-helpers';
import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import { StoreContainer } from 'shared/state/store-container';
import * as Action from './actions';

export function GetSyncData(userProfile: IUserProfile) {
	let animalTypes = getAnimalTypesInUse(userProfile);
	return AsyncOperationBuilder2(Action.getData, client => client.feedingCategories_GetAll(animalTypes), {});
}

export function SaveFeedingCategory(category: IFeedingCategory) {
	const state = StoreContainer.getState();
	const prevEntity = state.feedingCategory.entities.find(diagCat => diagCat.id === category.id);
	if (!category.id) {
		category.id = new ObjectID().toHexString();
	}

	return AsyncOperationBuilder2(
		Action.saveFeedingCategory,
		client => client.feedingCategories_Post(FeedingCategory.fromJS(category)),
		{ data: FeedingCategory.fromJS(category) },
		{ data: prevEntity },
	);
}
