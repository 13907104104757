import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import * as actions from './actions';
import { DistriwinFeedRegistrationState } from './types';
import { mergeArrays } from 'shared/helpers/reducer-helpers';
import { SyncableInitialState } from 'shared/state/models/syncable';
import { siteChange } from '../profile/actions';

export const initialState: DistriwinFeedRegistrationState = {
	distriwinFeedRegistrations: [],
};

const distriwinFeedRegistrationReducer = (
	state: DistriwinFeedRegistrationState = initialState,
	action: Action,
): DistriwinFeedRegistrationState => {
	if (isType(action, actions.getDistriwinFeedRegistrations.started)) {
		state = { ...state };
	}

	if (isType(action, actions.getDistriwinFeedRegistrations.done)) {
		state = {
			...state,
			distriwinFeedRegistrations: action.payload.result,
		};
	}
	if (isType(action, siteChange.done)) {
		state = initialState;
	}

	return state;
};

export default distriwinFeedRegistrationReducer;
