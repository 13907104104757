import { AnimalKind, Gender, IGeneralSettings } from 'shared/api/api';
import { localized, localizedDynamic } from 'shared/state/i18n/i18n';

export function getAnimalKindString(animalKinds: AnimalKind[] | undefined) {
	if (!animalKinds || !animalKinds.length) {
		return '';
	}

	return animalKinds!.map(kind => localizedDynamic(kind)).join(', ');
}

export const shouldUseLetters = (gender: Gender | undefined, generalSetting: IGeneralSettings | undefined) => {
	if (generalSetting && gender) {
		if (gender === Gender.Female) {
			return generalSetting.useLettersForAnimalNumber;
		}
		return generalSetting.useLettersForBoarNumber;
	}

	return false;
};

export const getAnimalKindOptions = () => {
	return [
		{
			label: localized('YoungFemale'),
			value: AnimalKind.YoungFemale,
		},
		{
			label: localized('Gilt'),
			value: AnimalKind.Gilt,
		},
		{
			label: localized('Sow'),
			value: AnimalKind.Sow,
		},
	];
};
