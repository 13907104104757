export default {
	"SkioldDigital": "SKIOLD digital",
	"Clear": "Limpiar",
	"locale": "in-gb",
	"reset": "Reiniciar",
	"site": "Sitio",
	"enterprise": "Empresa",
	"building": "Edificio",
	"section": "Sección",
	"buildingShort": "Edificio",
	"sectionShort": "Sección",
	"Unit": "Unidad",
	"Save": "Guardar",
	"Show": "Mostrar",
	"Edit": "Editar",
	"Handled": "Tratado",
	"Delete": "Borrar",
	"Cancel": "Cancellar",
	"Count": "Cantidad",
	"Weight": "Peso",
	"AnimalWeight": "Peso de animal",
	"FeedWeight": "Peso del pienso",
	"Result": "Resultados",
	"Saved": "Guardado",
	"Overdue": "Atrasado",
	"Print": "Imprimir",
	"Today": "Hoy",
	"Tomorrow": "Mañana",
	"SaveChanges": "Guardar cambios",
	"ChooseDate": "Elegir fecha",
	"stable": "Establo",
	"pen": "Corral",
	"ERROR": "ERROR",
	"Confirm": "Comfirmar",
	"Close": "Cerrar",
	"avg": "Promedio",
	"Gender": "Género",
	"Done": "Hecho",
	"Waiting": "Esperando",
	"Running": "Corriendo",
	"Paused": "Pausado",
	"Cancelled": "Cancellado",
	"Deleted": "Borrardo",
	"kg": "kg",
	"AreYouSureYouWantToCancel": "Esta seguro que quiere cancelar?",
	"NoProcessesText": "Ningún acceso a este módulo",
	"PleaseWait": "Espere por favor",
	"Saving": "Guardando",
	"Retry": "Re intentar ",
	"Ok": "Ok",
	"TableNoData": "Tabla sin datos",
	"Previous": "Antes",
	"Next": "Próximo",
	"Loading": "Cargando",
	"January": "Enero",
	"February": "Febrero",
	"March": "Marzo",
	"April": "Abril",
	"May": "Mayo",
	"June": "Junio",
	"July": "Julio",
	"August": "Agosto",
	"September": "Septiembre",
	"October": "Octubre",
	"November": "Noviembre ",
	"December": "Diciembre",
	"PunctualDaySuccessor": "Día de sucesor",
	"Animals": "Animales",
	"week": "Semana",
	"from": "Desde",
	"to": "Hasta",
	"ml": "ml",
	"outOf": "fuera de",
	"back": "Atráz",
	"failed": "Fallado",
	"bluetooth": "Bluetooth",
	"WillYouContinue": "Deséa continuar?",
	"PlanAndGoal": "Plan",
	"DefaultWeaningWeight": "Promedio de peso destete",
	"allocateWeight": "Asignar peso",
	"totalWeanedInPeriod": "Total de destetados en el periodo",
	"totalWeightInPeriod": "Peso total en el periodo",
	"InitialsShort": "Inicio",
	"Email": "Corréo electrónico",
	"or": "o",
	"Initials": "Iniciales",
	"PrintGeneralData": "Datos generales",
	"PrintMomDadData": "Datos padre/madre",
	"Denmark": "Dinamarca",
	"Taiwan": "Taiwán, proviencia de China",
	"Australia": "Australia",
	"Sweden": "Suecia",
	"Ecuador": "Ecuador",
	"Norway": "Noruega",
	"France": "Francia",
	"South Africa": "Africa del sur",
	"Sow Module": "Cerdas",
	"Climatepigs Module": "Cebadero",
	"Finisher Module": "Cebo",
	"Settings Module": "Configuración",
	"Status Module": "Estado del módulo",
	"Developer Module": "Desarrollo del módulo",
	"Pig management": "Manego de cerdos",
	"ControllerStatus": "Estado de controladores",
	"App Info Module": "Módulo informativo de la aplicación",
	"Adjust Feed": "Ajúste de pienso",
	"Delivery Module": "Módulo de entrega",
	"Delivery In": "Entrega",
	"Stock Module": "Módulo de existencia",
	"Alarm Module": "Alarmas",
	"Warning Module": "Advertencias",
	"LicencedModules": "Módulos de licencias",
	"Events": "Eventos",
	"addMedicine": "Agregar Medicinas",
	"addMedicineSow": "Agregar Medicinas",
	"addMedicineWeaner": "Agregar Medicinas",
	"addMedicineFinisher": "Agregar Medicinas",
	"registerTreatmentWeaner": "Registro de tratamientos",
	"treatmentListWeaner": "Listado de tratamientos",
	"registerTreatmentFinisher": "Registro de tratamientos",
	"treatmentListFinisher": "Listado de tratamientos",
	"ClimateEvents": "Eventos",
	"weanerPigsEvents": "Eventos",
	"finisherPigsEvents": "Eventos",
	"pregnancyEventLists": "Listado de eventos",
	"weanerPigsEventsList": "Listado de eventos",
	"finisherPigsEventLists": "Listado de eventos",
	"finishersManagement": "Manejo de cebos",
	"sowManagement": "Manejo de cerdas ",
	"weanersManagement": "Lechones destetados",
	"adminPanel": "Administrador",
	"developer": "Desarrollo",
	"order": "Orden",
	"deliveries": "Entregas",
	"status": "Estado",
	"settings": "Configuraciones",
	"treatments": "Tratamientos",
	"alarms": "Alarmas",
	"climate": "Clima",
	"warnings": "Advertencias",
	"stock": "Inventario",
	"plannedOrders": "Entregas planeadas",
	"reOrders": "Volver a pedir",
	"unmanagedOrders": "Pedidos no gestionados",
	"plannedDeliveries": "Pedidos planeados",
	"unmanagedDeliveries": "Pedidos no gestionados",
	"feedingHistory": "Historial del pienso",
	"profile": "Profil",
	"online": "En linea",
	"offline": "Sin linea",
	"activities": "Actividades",
	"tasks": "Tareas",
	"silos": "Silos",
	"sows": "Cerdas",
	"sowLardWidth": "Grasa dorsal",
	"lardScanning": "Escaneado dorsal",
	"pigs": "Cerdos",
	"distriwin": "Distriwin",
	"Alarms by Definition": "Alarmas por definición",
	"Warnings by Definition": "Advertencias por definición",
	"total alarms": "Total de alarmas",
	"total warnings": "Total de advertencias",
	"total active time": "Tiempo total de actividad",
	"average active time": "Tiempo medio activo",
	"Feeding System": "Sistema de alimentación",
	"Milling System": "Sistema de fresado",
	"slaughterRetention": "Retención",
	"SowManagementSetup": "Cerdas",
	"WeanerManagementSetup": "Destetados",
	"FinisherManagementSetup": "Cebadero",
	"userManagementSettings": "Gestión de usuario",
	"AnalysisProcessFinisher": "Análisis",
	"DashboardProcessFinisher": "Panel de índices",
	"AnalysisProcessWeaner": "Análisis",
	"DashboardProcessWeaner": "Panel de índices",
	"addsow": "Agregar cerda",
	"removesow": "Eliminar cerda",
	"movesow": "Mover cerda",
	"registertreatment": "Registro de tratamientos",
	"startadjustfeed": "Ajuste de pienso",
	"startadjustfeedSow": "Ajuste de pienso",
	"startadjustfeedFinisher": "Ajuste de pienso",
	"startadjustfeedWeaner": "Ajuste de pienso",
	"retryAdjustFeedMenuTitle": "Cambio de ajuste de pienso",
	"assigntask": "Designar tarea",
	"treatmentlists": "Lista de tratamientos",
	"departurelist": "Listado de bajas",
	"DepartureWorkListSetting": "Listado de bajas",
	"events.1": "Eventos",
	"createTreatment": "Crear tratamiento",
	"treatmentList": "Lista de tratamiento",
	"AddMedicineApp": "Agregar medicamentos/vacunas",
	"TreatmentAppList": "Crear tratamientos/retratamientos",
	"TreatmentWebList": "Tratamientos-/vaccianción",
	"sowCard": "Ficha de cerdas",
	"foodlists": "Lista de pienso",
	"analysisandreports": "Análisis & informes",
	"deliveryIn": "Entregas",
	"deliveryOut": "Salidas",
	"language": "Idioma",
	"english": "Inglés",
	"swedish": "Sueco",
	"danish": "Danés",
	"change": "Cambiar",
	"name": "Nombre",
	"en": "Inglés",
	"da": "Danés",
	"logout": "Cerrar sesión",
	"Login": "Acceso",
	"CHANGEPASSWORD": "Cambiar la contraseña",
	"EDITPROFILE": "Editar su profil",
	"OVERLAYPIG": "Cebadero",
	"OVERLAYSOW": "Cerdas",
	"OVERLAYFINISHER": "Cebo",
	"OVERLAYWEANER": "Lechones destetado",
	"OVERLAYSTOCK": "Inventario",
	"OVERLAYSETTINGS": "Configuraciones",
	"OVERLAYADMIN": "Administrador",
	"OVERLAYSTABLE": "Establo",
	"OVERLAYFEEDMILLS": "Molinos de piensos",
	"OVERLAYVALVES": "Válvula",
	"OVERLAYNUMBERS": "Números",
	"OVERLAYOUTPUT": "Producción",
	"OVERLAYGROWTH": "Crecimiento",
	"OVERLAYCLIMATE": "Clima",
	"OVERLAYREPORTS": "Informes",
	"return": "Retorno",
	"USER_MANAGEMENT": "Manejo de usuarios",
	"NOACCESSROUTE": "Ningún acceso",
	"ADMIN_DEPARTURE_REASONS": "Razón de bajas",
	"ADMIN_DIAGNOSES": "Diagnósticos",
	"ADMIN_DIAGNOSES_CATEGORIES": "Categorías de diagnósticos",
	"ADMIN_DRUG_TYPES": "Tipos de medicamentos",
	"ADMINROUTE": "Acceso administrativo",
	"ADMIN_MODULE_LICENCES": "Módulos de licencias",
	"ADMIN_FEED_REGISTRATIONS": "Registro de pienso",
	"ADMIN_BREED_TABLE": "Tabla de razas ",
	"SOWANIMALS": "Animales ",
	"SOWEVENTS": "EVENTOS",
	"SowEvents.1": "Eventos",
	"EVENTS.2": "Eventos",
	"SOWTREATMENTS": "Tratamientos",
	"SOWMEDICIN": "Medicamentos/Diagnósticos ",
	"SOWDEPARTUREREASONS": "Tipos de bajas",
	"SOWSETTINGS": "Configuraciones",
	"PIGSETTINGS": "Configuraciones",
	"selectDiagnose": "Seleccionar Diagnóstico/Vacunación",
	"startroute": "Empezar ",
	"adjustfeed": "Ajuste de pienso",
	"retryAdjustFeed": "Falla ajuste de pienso",
	"fetchDataFromDistriwin": "Cargar datos distriwin",
	"FetchDataFromDistriwinConfirmDialog": "Transpaso de datos. Duración 5 á 10 min. Deséa continuar?",
	"Time": "Tiempo",
	"FE_Day_Pig": "Uds. de pienso/día/lechón",
	"FE_Day_Sow": "Uds. de pienso/día/cerda",
	"Avr_Day": "Pmd/dia",
	"CONST DEV": "Pmd/día",
	"DEV": "Variable",
	"DEVIATION": "Variable",
	"DEVIATIONDAYS": "Variable días",
	"DEVPERCENT": "Variable %",
	"DEVIATIONDAYSPERCENT": "Variable %",
	"Sows.1": "Cerdas",
	"Pigs.1": "Lechones",
	"DAYS": "Días",
	"WEEK.1": "Semanas",
	"SECTION.1": "SECCIÓN",
	"FEEDINGCURVE": "Curva",
	"UNNAMEDFEEDINGCURVE": "Sin nombre",
	"NoHistoryAvailable": "Historial no disponible",
	"FeedingCurveAndRecept": "Curva/prescripción",
	"Recipe": "Receta",
	"fullSyncFailedMessage": "Vuelva intentar, o contacte SKIOLD",
	"wentThrough": "pasó por",
	"deleteMarked": "Borrar lo marcado",
	"moveMarked": "Mover lo marcado",
	"SureDeleteMarked": "¿Seguro de que quieres eliminar lo marcado?",
	"sendMarked": "Enviar lo marcado",
	"ConstantDeviation": "Desviación reparada",
	"Deviation.1": "Desviación",
	"DeviationDays.1": "Días de desviación",
	"FeedingCurve.1": "Curva de alimentación",
	"SowCycle": "Días de ciclos",
	"PenDays": "Días de engorde",
	"PigCount": "Conteo de cerdos",
	"pigCount.1": "Conteo de cerdos",
	"PigletCount": "Conteo de lechones",
	"PrevValue": "Viejo",
	"NewValue": "Nuevo",
	"Property": "Tipo",
	"NoAdjustmentsToShow": "Ningún ajuste",
	"SkipFeeding": "Pasar la ración",
	"RECENT_WARNINGS_CAPS": "ADVERTENCIAS",
	"RECENT_ALARMS_CAPS": "ALARMAS",
	"details": "Detalles",
	"DistriWin.1": "DistriWin",
	"FlexMixPro": "FlexMixPro",
	"Recent Alarms": "Alarmas",
	"Recent Warnings": "Advertencias ",
	"Date": "Fecha",
	"retentionDate": "Fecha de retención",
	"Event": "Evento",
	"History": "Historial",
	"addwidget": "AGREGAR DISPOSITIVO",
	"nowidget": "Dispositivo no disponible!",
	"HISTORY.1": "HISTORIAL",
	"HISTORYLast7Days": "HISTORIAL últimos 7 días",
	"ADJUST FEED.1": "AJUSTE DE PIENSO",
	"SELECT SECTION": "SELECCIONAR SELECCIÓN",
	"selectevent": "Seleccionar evento",
	"entrance": "Entradas",
	"entry": "Entrada",
	"departure": "Bajas",
	"select": "Selección",
	"animalNumber": "Núm. de animal",
	"animalNumberShorten": "Núm. de animal",
	"Select Section.1": "Selección de sección",
	"animalNumbers": "Núm. de animal",
	"setEntranceDateToMatingDate": "Ingrese de fecha de entrada a la fecha de cubrición",
	"PEN.1": "CORRAL",
	"VALVE": "VÁLVULA",
	"SOWS.2": "CERDAS",
	"ANIMALS.1": "ANIMALES",
	"FE_DAY": "PIENSO/DÍA",
	"FIXED": "FIJADO",
	"FIXEDPERCENT": "FIJADO %",
	"fixedPercent.1": "FIJADO %",
	"WEIGHT.1": "PESO",
	"entranceType": "Tipo de entrada",
	"entranceTypeBought": "Comprado",
	"entranceEventTypeBought": "Comprado",
	"entranceTypeFromYoungAnimal": "De animales de cría",
	"entranceTypeFromPolt": "De animales de reposición",
	"entranceGender": "Entrada Género",
	"selectEntranceType": "Selección de tipo de entrada",
	"entranceMating": "Cubrición",
	"ChooseIdNumber": "Elegir número de ID",
	"Litters": "Litros",
	"LitterNr": "Nro. de litros",
	"EntrancePrice": "Precio de entrada",
	"CreateAnimal": "Crear animal",
	"PrintSowTable": "Impresión de fichas",
	"PrintList": "Imprimir",
	"searchYoungAnimal": "Buscar animales jóvenes",
	"searchAnimal": "Buscar animales",
	"youngAnimalNotFound": "Animal no encontrado",
	"entranceTypeFromYoungFemale": "De animales de cría",
	"ValidateAnimalNumberLength": "Máximo de 6 caracteres, para validar datos para Danbred",
	"ValidateWrongUsageNumber": "Código de uso animal jóven \"{{area}}\" que no está permitido para Núcleus Management",
	"ValidateWrongIdNumber": "El número de identificación debe tener {{area}} caracteres, para que {{area2}} lo permita",
	"ValidateAge": "El animal tiene que tener {{area}} días de edad, para ser permitido por {{area2}}",
	"ExistingTransponder": "Transponder existentes",
	"NewTransponder": "Nuevos transponders",
	"ChangeTransponderNumber": "Cambio de transponder",
	"choosenLocationIsEmpty": "La ubicación elegida está vacía",
	"chooseStableSection": "Elija el establo - Sección",
	"StableSection": "Establo - Sección",
	"FromStableSection": "Del establo - Sección",
	"MoveToLocation": "Mover a ubicación",
	"FromLocation": "Desde la ubicación ",
	"ToLocation": "Para ubicación",
	"FromPen": "Del corral",
	"ToPen": "Al corral",
	"choosePen": "Elegir corral",
	"SITEANDFARM": "SITIO/GRANJA",
	"BUILDING.1": "EDIFICIO",
	"country": "País",
	"cvRnum": "CVRnr.",
	"chRnum": "CHRnr.",
	"ownerName": "Nombre",
	"ownerAddress": "Dirección",
	"ownerZipCode": "Código de país ",
	"ownerCity": "Ciudad",
	"siteAddress": "Dirección",
	"siteZipCode": "Código de país ",
	"siteName": "Nombre",
	"siteCity": "Ciudad",
	"SiteName.1": "Sitio/Granja",
	"address": "Dirección",
	"postalCode": "Código postal",
	"ZipCode": "Código postal",
	"Owner": "Propietario",
	"city": "Ciudad",
	"chrNum.1": "CHR nro.",
	"createBuilding": "Crear establos",
	"createSite": "Crear sitio",
	"number": "Número",
	"units": "Unidades",
	"amountOfSections": "Cantidad de secciones",
	"startNumber": "Número de inicio",
	"amountOfPens": "Cantidad de corrales",
	"capacity": "Capacidad",
	"addSections": "Agregar secciones",
	"addPens": "Agregar corrales",
	"chooseCountry": "Elegir país",
	"isUsingQRCode": "Utilizando códigos QR ",
	"isUsingSections": "Utilizando secciones",
	"isUsingPens": "Utilizando corrales",
	"letterBehindPen": "Letras detrás del número de corral",
	"NameBehindStartNumber": "Nombre ( al frente de la sección nro.)",
	"longmg": "por kilo",
	"longml": "por litro",
	"chooseLocation": "Elija la ubicación ",
	"Active": "Activo",
	"Inactive": "Inactivo",
	"Valve.1": "Valvula",
	"ValveNr": "Número de valvula",
	"Pen.2": "Corral",
	"OK.1": "OK",
	"Title": "Titulo",
	"Location": "Ubicación",
	"LocationShort": "Ubicación",
	"currentLocation": "Ubicación actual",
	"newLocation": "Nueva localidad",
	"Status.1": "Estado",
	"Description": "Descripción",
	"How to Fix": "Como repararlo",
	"No available curves": "Curvas no disponibles",
	"Key performance indicators": "Indicadores claves de rendimiento",
	"Value": "Válvula",
	"Num": "Número",
	"Type": "Tipo",
	"Add": "Agregar",
	"sort": "Clasificar",
	"EDITSECTION": "EDITAR SECCIÓN",
	"EDITBUILDING": "EDITAR EDIFICIO",
	"locationOverview": "Descripción de ubicación",
	"siteSetup": "Configuración de sitio",
	"siteChrError": "CHR nro. en uso",
	"peqCodeError": "El código de ubicación en uso",
	"removeDeparture": "Eliminar salida",
	"departureTypeSold": "Vendidos",
	"departureTypeDead": "Muertos",
	"departureTypeKilled": "Matadero",
	"departureTypeToBeKilled": "A ser sacrificados",
	"departureTypeShouldDeparture": "Debería salir de baja",
	"departureTypePutDown": "Sacrificados",
	"departureReason": "Razón ",
	"departureType": "Tipo de salida",
	"selectDepartureType": "Tipo de salida",
	"putDown": "Sacrificados",
	"departurePrice": "Precio de salida",
	"selectMarking": "Seleccionar marca",
	"selectReason": "Seleccionar motivo",
	"Days.1": "Días",
	"Quantity": "Cantidad",
	"marking": "Marca",
	"AdepartureTypeKilled": "Matadero",
	"BdepartureTypeDead": "Muertos",
	"CdepartureTypePutDown": "Sacrificados",
	"DdepartureTypeSold": "Vendidos ",
	"animalNr": "Nro. de animal",
	"kind": "Clase",
	"condition": "Condición",
	"entranceDate": "Fecha de entrada",
	"Batch": "Lote",
	"transponder": "Número de chip",
	"Teats": "Tetas",
	"price": "Precio",
	"idNumber": "Número de ID",
	"birthdate": "Fecha de nacimiento",
	"Breed": "Raza",
	"dadNr": "Nro. del padre",
	"dadNumber": "Número del padre",
	"selectDadNumber": "Elija número del padre",
	"dadID": "ID del padre",
	"momNr": "Nro. de la madre",
	"momNumber": "Número de la madre",
	"selectMomNumber": "Seleccionar número de la madre",
	"momID": "ID dela madre",
	"index": "Índice",
	"indexDate": "Fecha de índice",
	"departureDate": "Fecha de baja",
	"age": "Edad",
	"CreateSow": "Crear cerda",
	"CHOOSELIST": "ELECCION DE LISTA",
	"cycleDays": "Dias de ciclo",
	"minCycleDays": "Minimo días de ciclo",
	"maxCycleDays": "Máximo días de ciclo",
	"DryDays": "Días vacíos",
	"moveToMatingWeaningDryDays": "Destetes, días vacíos",
	"moveToMatingWeaningDate": "Destetes, fecha",
	"dadRace": "Raza del padre",
	"momRace": "Raza de la madre",
	"activeSowPdf": "Cerdas activas.pdf",
	"UsageCode": "Código de uso",
	"DadMomPdf": "Padre-Madre-Datos.pdf",
	"activeYoungAnimals": "Animales de cría activos.pdf",
	"activeSows": "Cerdas activas",
	"departuredSows": "Bajas cerdas",
	"boars": "Verraco",
	"youngAnimals": "Animales de cría ",
	"activeBoars": "Verracos activos",
	"departedBoars": "Bajas verracos",
	"departedYoungAnimals": "Bajas animales de cría ",
	"female": "Cerdas",
	"male": "Macho",
	"youngFemale": "Hembras jóvenes",
	"gilt": "Primerizas",
	"boar": "Verraco",
	"Unknown": "Desconocido",
	"YoungFemale.1": "Hembras jóvenes",
	"Gilt.1": "Primerizas",
	"Sow": "Cerdas",
	"Boar.1": "Verraco",
	"Piglet": "Lechones",
	"YoungAnimal": "Animales jóvenes",
	"youngFemales": "Hembras jóvenes",
	"sowBoars": "Cerdas/Verracos",
	"AnimalKinds": "Tipo de animal",
	"chooseImportFile": "Archivo seleccionado",
	"chosenFile": "Archivo seleccionado",
	"import": "Importar",
	"export": "Exportar",
	"startImport": "Iniciar importación",
	"specifyWeight": "Peso específico",
	"ChooseFilePath": "Seleccione ruta de archivo",
	"ExportYoungAnimals": "Exportar animales jóvenes",
	"Marking": {
		"Blue": "Azul",
		"Red": "Rojo",
		"Green": "Verde",
		"Black": "Negro",
		"White": "Blanco",
		"EarmarkRemoved": "Crotal eliminados",
		"EarmarkAdded": "Crotal agregados",
	},

	"DrugTypes": "Tipos de medicamentos",
	"DrugType": "Tipo de medicamento",
	"AddDrugType": "Agregar tipo de medicamentos",
	"AddToDrugType": "Agregar tipo de medicamentos",
	"DrugTypeNotChoosen": "Seleccione tipo de medicamento",
	"createReason": "Crear causa",
	"reasonName": "Nombre de causa",
	"reasonNameDa": "Nombre de causa danés",
	"reasonNameEn": "Nombre de causa inglés",
	"reasonCode": "Código de prioridad",
	"editReason": "Editar causa",
	"reasons": "Causas",
	"reason": "Causa",
	"stemAnimalDeparture": "Bajas",
	"reasonDead": "Bajas",
	"reasonPutDown": "Mortandad",
	"piglets": "Lechones",
	"polts": "Cerdas jóvenes",
	"DIAGNOSE_CATEGORIES": "CATEGORÍAS DE DIAGNÓGTICOS",
	"Diagnose": "Diagnósticos",
	"categories": "Categorías",
	"addCategory": "Agregar categorías",
	"category": "Categoría de diagnóstico",
	"categoryNameEn": "Nombre de categoría (en)",
	"categoryNameDa": "Nombre de categoría (da)",
	"priority": "Prioridad",
	"delete.1": "Borrar",
	"DIAGNOSES": "DIAGNOSTICOS",
	"addDiagnose": "Agregar Diagnóstico",
	"diagnoseNameDa": "Nombre de diagnóstico (da)",
	"diagnoseNameEn": "Nombre de diagnóstico (en)",
	"diagnoseDescriptionDa": "Descripción de diagnóstico (da)",
	"diagnoseDescriptionEn": "Descripción de diagnóstico (en)",
	"diagnoses.1": "Diagnósticos",
	"DeleteDiagnosePrompt": "Deséa eliminar el diagnóstico? Esto no se puede eliminar.",
	"isVaccination": "Es vacuna",
	"diagnoseName": "Diagnósticos",
	"pigTypes": "Tipos de cerdos",
	"pigType": "Tipo de cerdo",
	"diagnoseType": "Tipo de diagnóstico",
	"vaccine": "Vacuna",
	"treatment": "Tratamiento",
	"create": "Crear",
	"selectDiagnoseDescription": "Seleccionar Diagnóstico/Vacunación",
	"selectAnimalKind": "Seleccionar tipo de animal",
	"Analysis": "Análisis",
	"matingsFirstMating": "Fecha de cubrición 1. cubrición",
	"AllFinishedLitters": "•Camadas destetadas, uds.",
	"AllNurseWeanings": "•Camadas de cerdas lactantes, uds.",
	"analysisResultsPerLitter": "Resultados distribuidos por camadas",
	"analysisResultsYoungFemale": "Resutado de hembras jóvenes, edad al parto",
	"analysisElaborationYoungFemaleAge": "Detalles de hembras jóvenes, edad al parto",
	"ChooseAnalysis": "Selección de análisis.",
	"AllWeanings": "Destetados, uds.",
	"AvgNursingSow": "Porcentajes cerdas lactantes",
	"All": "Todos",
	"Some": "Algunos",
	"None": "Ninguno",
	"Weeks": "Semanas",
	"AgePeriod": "Columna de edad",
	"Elaboration": "Detalles",
	"SaveSettings": "Ajustes de guardado ",
	"MatingDate": "Fecha de cubrición",
	"TotalPeriod": "Periodo total",
	"PercentageRematings": "Promedio restante",
	"PercentageDeparture": "Promedio de salida",
	"MatingsInTotal": "Cubrición en total",
	AnalysisResult: {
		"Matings": "Cubrición",
		"TotalMatings": "Cubriciones totales",
		"TotalFirstMatingToLitter": "Primera cubrición, uds.",
		"DistributedMatingPercentage": "Porciento de cubriciones distribuidas",
		"ReturnSows": "Re-cubriciones, uds.",
		"PercentageReturnSows": "Porcientos recubriertas",
		"DepartureNonPregnant": "No preñada, uds.",
		"ThereOfAbortions": "Abortos, uds.",
		"TotalDepartures": "Bajas totales, uds.",
		"DepartureKilledThisLitter": "Vendidos/Matadero, uds.",
		"DepartureDeadThisLitter": "Muertos/Sacrificados, uds.",
		"WeaningToFirstMating": "Destetes hasta 1. cubrición, días",
		"MatingToNonPregnantDays": "Cubrición hasta no preñada, días",
		"MatingToRemating": "Cubrición hasta recubrición, días",
		"MatingToDeparture": "Cubrición hasta bajas, días",
		"Farrowings": "Partos",
		"TotalFarrowings": "Partos, uds.",
		"AvgAlive": "Nacidos vivos, uds.",
		"AvgDead": "Nacidos muertos, uds.",
		"DistributedFarrowingsPercentage": "Distribuidos en partos, %",
		"StillbornToAliveRate": "Nacidos muertos en porcentaje de nacidos vivos",
		"FarrowingRate": "Tasa de partos",
		"NeedFarrowingFirstLitter": "A parir 1era camada, uds.",
		"AvgPregnantDays": "Días de preñes",
		"Weanings": "Destetes",
		"AllWeanings": "Destetes, uds.",
		"AllFinishedLitters": "• Partes de destete de camadas, uds.",
		"AllNurseWeanings": "• Partes de cerdas lactantes uds.",
		"AvgWeanedPerLitter": "Destetados por camadas, uds.",
		"AvgWeanedPerWeaninig": "Destetados por cerdas lactantes, uds.",
		"AvgWeight": "Peso por lechon, kg.",
		"AvgNursingSow": "% Cerdas lactantes",
		"TotalWeanedPiglets": "Lechones totales, uds.",
		"DeadToAliveRate": "Mortandad al destete, %",
		"NeedWeaningFirstLitter": "A ser destetados 1era. camada, uds.",
		"AvgNursingDays": "Días de lactación",
		"PercentageRematings": "Promedio de rebrición",
		"PercentageDepartured": "Promedio de bajas ",
		"AvgDays": "Promedio de días ",
		"Percentage": "Porcentaje",
	},

	"ResultYoungFemaleFirstMating": "Edad de cerdas de reposición",
	"WeaningToMating": "Destetes á cubrición",
	"MatingToNonPregnant": "Cubrición á no preñadas",
	"MatingToMating": "Cubrición á cubrición",
	"MatingTo": "Cubrición á",
	"Departure.1": "Bajas",
	"Abortion": "Aborto",
	"FromTo": "Desde - Hasta",
	"IntervalDays": "Intervalo en días",
	"analysisResultsPregnancy": "Análisis de preñés",
	"AvgDays": "Promedio en días",
	"Percentage": "Porcentaje",
	"MatingToAbortionAndDeparture": "Cubrición á aborto y bajas",
	"analysisDepartureSow": "Tipos de bajas/razón de salidas cerdas ",
	"AnalysisDeparturePiglets": "Razón de bajas lechones",
	"AnalysisDepartureAnimalType": "Razón de bajas {{area}}",
	AnalysisDeparture: {
		"NumberOfAnimals": "Números de animales",
		"PctAnimalLitterNumber": "PORCIENTO DE ANIMALES POR NÚMEROS DE CAMADAS",
		"CountAnimalLitterNumber": "NÚMEROS DE ANIMALES POR NÚMEROS DE CAMADAS",
		"PctAnimalWeek": "PORCIENTOS DE ANIMALES POR SEMANA DEL PERIODO",
		"CountAnimalWeek": "NÚMEROS DE ANIMALES POR SEMANA DEL PERIODO",
	},

	"TotalPct": "Total %",
	"TotalWeek": "Números de animales por semana",
	"TotalLitter": "Números de animales por camadas",
	"TotalBorn": "Números de nacidos vivos",
	"MortalityRate": "Porcentaje de nacidos muertos",
	"year": "año",
	"overTen": "Pasado los 10",
	"sowsGilt": "Cerdas/Cerdas de reposición",
	"goalYear": "Metas",
	"overGoalYear": "Arriva abajo ",
	"averageYear": "Promedio año",
	"DASHBOARD": "Pizzarra",
	"Dashboard.1": "Pizzarra",
	"ageByFold": "Edad por camada",
	"DaysToMatingFrom": "Días de cubrición desde",
	"DaysFromMatingToDeparture": "Días de cubrición á bajas",
	"SoldOrKilled": "Vendidos/Faenados",
	"Sold": "Vendidos",
	"soldOrKilled.1": "Vendidos/Faenados",
	"deadOrPutDown": "Muertos/Sacrificados",
	"DeadOrPutDown.1": "Muertos/Sacrificados",
	"notPregnant": "No preñada",
	"AnimalsToFarrowingTable": "Cubriciónes, animales a parir",
	"TotalMatingsTable": "Cubrición, cubriciones totales",
	"TotalDepartureTable": "Cubriciones, salidas totales",
	"TotalFarrowingsTable": "Partos, partos totales",
	"NeedFarrowingTable": "Partos, partos previstos",
	"NeedWeaningTable": "Destetes, destetes previstos",
	"TotalWeaningsTable": "Destetes: destetes totales",
	"AliveBorn": "Nacidos vivos",
	"weightPerPigDashboard": "Peso por lechon",
	"weanedPerLitterDashboard": "Destetados por camadas",
	"weaningDateDashboard": "Fecha de destetes",
	"achievedYear": "Logrados al año",
	"VALIDATION_ERROR_INTERVALS_MAY_NOT_OVERLAP": "Los intervalos en una sección dada, no pueden superponerse",
	"fromMatingBatch": "Desde el lote de cubrición",
	"toMatingBatch": "Al lote de cubrición",
	"weaningToMating.1": "Destete a cubrición",
	"matingToNotPregnant": "Cubrición a no preñadas",
	"matingToMating.1": "Cubrición a cubrición",
	"matingToAbortion": "Cubrición a aborto",
	"abortionToMating": "Aborto a cubrición",
	"matingToSoldOrKilled": "Cubrición a vendidos/sacrificados",
	"matingTodeadOrPutDown": "Cubrición a muertos/sacrificados",
	"matingsFrom": "Cubrición desde",
	"DashboardSubGoal": "Sub-objetivo de uso/peso de alimento",
	"DashboardExpectedWeight": "Peso de venta deseado ",
	"weekBatchPdf": "Lote de semana {{area}}",
	"ElaborationMatingBatch": "Elaboración lote de cubrición {{area}}",
	"DeadPiglets": "Lechones muertos",
	"PctDeadPiglets": "Porcentaje lechones muertos de nacidos vivos",
	"Dates": "Fechas",
	"rollingYear": "Periodo de 365 días",
	"PerYear": "Por año",
	"matingsPcs": "Porciento de cubriciones",
	"percentageYoungAnimalMatings": "% cubrición de animales jóvenes",
	"percentageReMatings.1": "% recubriciones",
	"daysFromWeaningToFirstMating": "Días desde destetes á primera cubrición",
	"averageWasteDaysPerMatingBatch": "Promedio días perdidos por lote de cubrición ",
	"farrowingsPcs": "Partos, uds.",
	"aliveBornPerFarrowingPcs": "Nacidos vivos por parto, uds.",
	"aliveBornPerFarrowingFirstLegPcs": "Nacidos vivos por parto 1ra. camada, uds.",
	"deadBornPerFarrowingPcs": "Nacidos muertos por parto, uds.",
	"weanedPerFarrowingPcs": "Destetados por partos, uds.",
	"weightPerWeanedPig": "Peso por lechon destetado, kg (1dec.)",
	"stillbornToAliveRate": "Nacidos muertos en porciento del total de nacidos",
	"daysPerMatingBatch": "Días por lotes de cubriciones",
	"deviationPercent": "Desviación por %",
	"dashboardDefinition": "Definición de años Pizzarra:",
	"startAtMatingBatch": "Empezar al comienzo del lote de cubriciones",
	"endAtMatingBatch": "Terminar con el lote de cubiertas",
	"selectTreatmentDefinition": "Diagnóstico",
	"noDrugTypesForAnimalTypeVaccine": "Agregue farmaco de tipo vacuna al stock para este tipo de animal, para crear una nueva definición de vacuna",
	"noDrugTypesForAnimalTypeMedicine": "Agregue farmaco de tipo medicamento al stock para este tipo de animal, para crear una nueva definición de diagnóstico",
	"weightLabel": "Peso",
	"registerTreatment.1": "Resgistro de tratamientos",
	"registerToBeTreated": "A ser tratados",
	"VALIDATION_ERROR_NoDiagnoseSet": "Diagnóstico requerido",
	"VALIDATION_ERROR_CHECKALL_OR_SELECTPEN": "Seleccione corral o seleccione todos a la sección",
	"VALIDATION_ERROR_NoMarkingSet": "Marca requerida ",
	"VALIDATION_ERROR_NoWeightSet": "Peso requerido",
	"AmountOfPiglets": "Cantidad de lechones",
	"AmountOfPigletsShort": "Lechones",
	"DrugAmount": "Cantidad de medicamentos",
	"DrugUseForAnimals": "Medicamento utilizado por animal",
	"DrugAmountShort": "Cantidad",
	"DrugAmountPerPig": "Cantidad de medicamentos por animal",
	"Dosage": "Dosis",
	"EditTreatment": "Editar tratamientos",
	"SelectTreatedMoved": "Cuantos lechomes tratados deben ser transladados?",
	"CantRegisterANursingSow": "No es posible registrar un cerda lactante",
	"SYNC_ERROR": "Algo salió mal, es posible que los datos no se sincronicen. Vuelve a intentarlo volviendo a entrar en esta página",
	"VALIDATION_ERROR_MISSING_AGE_PERIOD": ": Debe definir el período de edad para las cerdas jóvenes",
	"VALIDATION_ERROR_AGE_TO_IS_LESS_THAN_FROM_DATE": ": Desde edad no debe ser menor que la edad",
	"VALIDATION_ERROR_DRUGSTOCK_REASON_REQUIRED": "Debe indicar el motivo de la perdida ",
	"VALIDATION_ERROR_DRUGSTOCK_USE_ADD_INSTEAD": "Utilice agregar en vez de ",
	"VALIDATION_ERROR_DRUGSTOCK_ERROR_EDITING_WASTE_HAS_TO_BE_NEGATIVE": "Perdida es negativa",
	"VALIDATION_ERROR_WEIGHT_REQUIRED_FOR_DEPARTED": "Esta intentando transladar lechones, sin definir su peso",
	"VALIDATION_ERROR_ANIMAL_IS_NOT_PREGNANT_ANYMORE_AND_ANIMAL_IS_ON_PREGNANT_LOCATION": "Debería mudar los animales a una localidad, esta no es una localidad de preñes ",
	"VALIDATION_ERROR_ANIMAL_NEEDS_TO_BE_PREGNANT_TO_MOVE_TO_THIS_LOCATION": "Los animales deberían de estar preñadas, para moverlos a la localidad de preñes",
	"VALIDATION_ERROR_ESF_IS_ENABLED_AND_TRANSPONDER_IS_REQUIRED": "No se há elegido un transponder ",
	"VALIDATION_ERROR_DRUGSTOCK_ERROR_EDITING_ADJUSTMENT_HAS_TO_BE_POSITIVE": "Los ajustes son positivos",
	"VALIDATION_ERROR_DRUGSTOCK_NOT_SELECTED": "Tipo de medicamentos no seleccionados",
	"VALIDATION_ERROR_FEEDINGSTOCK_NOT_SELECTED": "Se requiere tipo de pienso",
	"VALIDATION_ERROR_FEEDINGSTOCK_AMOUNT": "Sin cantidad seleccionada",
	"VALIDATION_ERROR_FEEDINGSTOCK_PRICE": "Sin precio seleccionado",
	"VALIDATION_ERROR_NoFeedingSubjectChoosen": "Debería elegir tipo de pienso",
	"VALIDATION_ERROR_NoFeedingAnimalTypeChoosen": "Debería elegir tipo de animal",
	"VALIDATION_ERROR_FEEDINGSTOCK_FESOW": "Selección unidad de pienso cerdas - 0 es un valor correcto",
	"VALIDATION_ERROR_FEEDINGSTOCK_FEPIG": "Selección unidad de pienso lechones - 0 es un valor correcto",
	"VALIDATION_ERROR_AMOUNT_NOT_SET": "No se há ingresado la cantidad",
	"VALIDATION_ERROR_FEEDINGSTOCK_FOSFOR": "No se há elegido el fósforo",
	"VALIDATION_ERROR_TOTAL_AMOUNT_TO_LOW_TO_CONTAIN_ALREADY_USED_FOOD": "La cantidad debería de haber sido ingresada",
	"VALIDATION_ERROR_TOTAL_AMOUNT_AVAILABLE_WILL_BE_LOWER_THAN_TOTALUSED": "La cantidad total de este tipo de pienso, será menos que el total utilizado",
	"VALIDATION_ERROR_DRUGSTOCK_AMOUNT": "No seleccionó ninguna cantidad",
	"VALIDATION_ERROR_DRUGSTOCK_DATE": "No seleccionó la fecha",
	"VALIDATION_ERROR_TRANSPONDER_ALREADY_IN_USE_BY_SOW": "Número de transponder yá esta utilizado en el nro. de animal",
	"VALIDATION_ERROR_ANIMAL_NUMBER_ALREADY_IN_USE_BY_SOW": "Número de animal yá fué utilizado",
	"VALIDATION_ERROR_AnimalNumberNotSet": "Número de animal no ingresado",
	"VALIDATION_ERROR_AnimalNumberNotFound": "Número de animal no existe",
	"VALIDATION_ERROR_AnimalNumberIsDepartured": "Número de animal yá fué dado de baja",
	"VALIDATION_ERROR_AnimalNumberExists": "Número de animal yá existe",
	"VALIDATION_ERROR_EntranceDateNotSet": "No há ingresado la fecha de entrada",
	"VALIDATION_ERROR_PenIdNotSet": "No há ingresado el corral",
	"VALIDATION_ERROR_STABLE_SECTION_NOT_SET": "Establo - no há ingresado la sección",
	"VALIDATION_ERROR_NEW_PEN_ID_NOT_SET": "No há ingresado el corral",
	"VALIDATION_ERROR_MOVE_STABLE_SECTION_NOT_SET": "Mover al establo - no há ingresado la sección",
	"VALIDATION_ERROR_MOVE_NEW_PEN_ID_NOT_SET": "No há ingresado a que corral mover ",
	"VALIDATION_ERROR_SiteNotSet": "No há ingresaod el sitio",
	"VALIDATION_ERROR_MatingEqualsOneLitter": "La camada debería de ser por lo menos 1 cuando la cubrición fué ingresada",
	"VALIDATION_ERROR_EntranceTypeNotSet": "Tipo de entrada no ingresada",
	"VALIDATION_ERROR_AnimalNotFound": "No se encontro el animal",
	"VALIDATION_ERROR_DepartureTypeNotSet": "Tipo de baja no ingresado",
	"VALIDATION_ERROR_DepartureDateNotSet": "Fecha de baja no ingresado",
	"VALIDATION_ERROR_DATE_NOT_SET": "Fecha no ingresado",
	"VALIDATION_ERROR_LARD_WIDTH_NOT_SET": "Grosor de grasa no ingresado",
	"VALIDATION_ERROR_DepartureBoarIsKs": "El verraco es un KS ",
	"VALIDATION_ERROR_DepartureDateMostAfterCurrentDate": "Fecha de baja debe ser despues de fecha actual",
	"VALIDATION_ERROR_DASHBOARD_ENDMATINGBATCH_NEED_TO_BE_LATER": "Debería ingresar el final del lote de cubrición para que séa despues de la fecha actual",
	"VALIDATION_ERROR_DASHBOARD_NEED_LATER_ENDMATINGBATCH": "Año nuevo requerido para la pizarra - Configuración, Manejo de cerdas, Plan/metas de producción",
	"VALIDATION_ERROR_NameNotSet": "No ingreso el nombre",
	"VALIDATION_ERROR_UnitNotSet": "No ingreso la unidad",
	"VALIDATION_ERROR_TransponderOccupied": "Transponder ocupado",
	"VALIDATION_ERROR_BothNamesRequired": "Ambos nombres son requeridos",
	"VALIDATION_ERROR_CategoryRequired": "Se requiere categorías ",
	"VALIDATION_ERROR_CategoryInUse": "No es posible borrar la categoria yá utilizada en un diagnostico",
	"VALIDATION_ERROR_FieldsRequired": "Todos los campos son requerídos",
	"VALIDATION_ERROR_NoDrugId": "Selecciono un medicamento",
	"VALIDATION_ERROR_AnotherVaccineAlreadyExist": "La vacuna definida yá existe",
	"VALIDATION_ERROR_CannotBeNegative": "Valores no pueden ser negativos",
	"VALIDATION_ERROR_CannotBeNegativeOrZero": "Valores no pueden ser negativos o ceros",
	"VALIDATION_ERROR_COUNTRYID_REQUIRED": "La granja requiere un país",
	"VALIDATION_ERROR_CHRNUM_REQUIRED": "La granja requiere un número de CHR",
	"VALIDATION_ERROR_SITE_NAME_REQUIRED": "La granja requiere un nombre",
	"VALIDATION_ERROR_SITE_ADDRESS_REQUIRED": "La granja requiere una dirección",
	"VALIDATION_ERROR_SITE_ZIPCODE_REQUIRED": "La granja requiere un número un código de postal",
	"VALIDATION_ERROR_SITE_CITY_REQUIRED": "La granja requiere una ciudad",
	"VALIDATION_ERROR_OWNER_NAME_REQUIRED": "El propietario requiere un nombre",
	"VALIDATION_ERROR_OWNER_ADDRESS_REQUIRED": "El porpietario requiere de una dirección",
	"VALIDATION_ERROR_OWNER_ZIPCODE_REQUIRED": "El propietario requiere de un código postal",
	"VALIDATION_ERROR_OWNER_CITY_REQUIRED": "El propietario requiere de una ciudad",
	"VALIDATION_ERROR_CVRNUM_REQUIRED": "El porpietario requiere de un número de CVR",
	"VALIDATION_ERROR_CategoryNotSet": "Categoría no elegida",
	"VALIDATION_ERROR_NoDiagnoseID": "Ningún diagnóstico",
	"VALIDATION_ERROR_NoAnimalKinds": "Tipo de animal no elegido",
	"VALIDATION_ERROR_NoDrugTypeIsChoosed": "Tipo de medicamentos no seleccionados",
	"VALIDATION_ERROR_NoFrequency": "Número de frecuencia no seleccionada",
	"VALIDATION_ERROR_NoNumberOfTreatments": "Números de tratamientos no seleccionados",
	"VALIDATION_ERROR_RefreshError": "Error, cargargando página ",
	"VALIDATION_ERROR_NoRetentionTime": "Tiempo de retención no seleccionado",
	"VALIDATION_ERROR_NoTreatmentType": "Tipo de tratamiento no seleccionado",
	"VALIDATION_ERROR_NoDrugAmountValue": "Cantidad de medicamento no seleccionado",
	"VALIDATION_ERROR_NoPerUnit": "Por unidad no seleccionada",
	"VALIDATION_ERROR_NoUnitType": "Tipo de unidad no seleccionada",
	"VALIDATION_ERROR_BatchNotSet": "Lote no seleccionado",
	"VALIDATION_ERROR_FinishedDateNotSet": "Fecha no seleccionada",
	"VALIDATION_ERROR_MatingDateAndYoungFemaleError": "Fecha de entrada de animal, despues de la fecha de cubrición",
	"VALIDATION_ERROR_InvalidAgeInterval": "Edad de las cerdas jóvenes fuera de los rangos",
	"VALIDATION_ERROR_InvalidPregnancyEvent": "Evento de preñes invalidos",
	"VALIDATION_ERROR_HasNoPreviousEvent": "No tuvo eventos de preñes previos",
	"VALIDATION_ERROR_SowAlreadyPregnant": "La cerda yá está preñada",
	"VALIDATION_ERROR_DateIsBeforePreviousEvent": "Fecha seleccionada es anterior a la fecha de evento actual",
	"VALIDATION_ERROR_InvalidMatingToFarrowingInterval": "{{area}} días entre cubrición y parto son inválidos",
	"VALIDATION_ERROR_RegistrationErrorPregnancyEvent": "Error mientras que se realizaba el registro de evento de preñes ",
	"VALIDATION_WARNING_CHANGING_SECTION_OR_BUILDING_WILL_RESULT_IN_RESET_FOR_CHANGED_BUILDINGS_SECTIONS": "Cambió la relación entre las ubicaciones digitales de Distriwin y SKIOLD, resulta en que las válvulas ya no estén conectadas a una ubicación. Desde la descripción general de la ubicación, debe volver a conectar las válvulas a una ubicación. ¿Guardar los cambios?",
	"VALIDATION_WARNING_SECTION_NOT_BELONGING_TO_SECTION_SELECTED_HAS_NON_SETTED_PENS": "Datos no guardados, debido a que hay secciones que no pertenecen a la sección seleccionada sin válvulas conectadas, ¿Deséa realizar la corrección?",
	"VALIDATION_WARNING_YOU_ARE_CREATING_A_USAGE_THAT_IS_IN_ANOTHER_USAGE_PERIOD": "Está creando un periodo que está en otro periodo",
	"VALIDATION_ERROR_NoAbortionIfAlivePigs": "No disponible al aborto, si el cerdo esta vivo",
	"VALIDATION_ERROR_InvalidFarrowingToWeaningInterval": "Intervalo de la fecha de parto al destete es inválido",
	"VALIDATION_ERROR_SET_WHAT_TO_TREAT": "Elija si todos los animales deben ser tratados o solamente los verracos",
	"VALIDATION_ERROR_InvalidWeaningToMatingInterval": "Intervalo de la fecha de destete a cubrición es inválido",
	"VALIDATION_ERROR_GiltMatingDateError": "Cuando el tipo de animal es reposición, la fecha de cubrición debería de ser antes de al fecha de entrada.",
	"VALIDATION_ERROR_PregnantDaysNotSet": "Días de preñes no seleccionados",
	"VALIDATION_ERROR_NursingDaysNotSet": "Días de lactación no seleccionados",
	"VALIDATION_ERROR_SHOW_ANIMAL_ON_LIST": "No existen valores para mostrar animales en esta lista",
	"VALIDATION_ERROR_StartDateIsNotSet": "Fecha de inicio no seleccionado",
	"VALIDATION_ERROR_WeekCycleIsNotSet": "La semana de ciclo no seleccionado",
	"VALIDATION_ERROR_CycledaysNotSet": "Días de ciclo no seleccionados",
	"VALIDATION_ERROR_MatingPeriodIsNotSet": "Periodo de cubrición no seleccionados",
	"VALIDATION_ERROR_PregnantAndNursingDaysExceeded": "Días de preñes + días de lactación exeden el ciclo de la cerda",
	"VALIDATION_ERROR_NoMatingBatchesGenerated": "No se há generado lotes de cubrición",
	"VALIDATION_ERROR_EndDateNotSet": "No se há generado la fecha final",
	"VALIDATION_ERROR_OverlapsOtherSettingsDates": "Fecha de inicio o final para la configuración, sobreponen otras fechas",
	"VALIDATION_ERROR_DateDoesNotMatchAMatingBatch": "La fecha seleccionada no coinciden con el lote de cubrición. Por favor seleccione el lote de cubrición",
	"VALIDATION_ERROR_RegistrationErrorOnMatingBatch": "Há ocurrido un error mientras ser realizó el registro del lote de cubrición",
	"VALDIATION_ERROR_UnknownTreatmentDefinitionSet": "La definicion del tratamiento es desconocido en el servidor",
	"VALIDATION_ERROR_TreatmentPlannedDateNotSet": "No existe tratamientos en las fechas planeadas",
	"VALIDATION_ERROR_TreatmentSiteIdNotSet": "No existe ID del sitio para el tratamiento",
	"VALIDATION_ERROR_ScanCantBeSameDateAsMated": "No es posible escanear a la cerda, la misma fecha que la cubrición",
	"VALIDATION_ERROR_StartDateIsEarlierThanCurrentDate": "La fecha de inicio debería de ser después de la fecha actual",
	"VALIDATION_ERROR_NewStartDateMustBeEqualToExisting": "La fecha de inicio debería de ser después de la fecha de hoy y igual a la fecha de cubrición calculada",
	"VALIDATION_ERROR_NoneAlive": "Ningún animal activo con ese número",
	"VALIDATION_ERROR_TransponderInUse": "Transponder yá esta en uso",
	"VALIDATION_ERROR_SowDoesNotExist": "La cerda no existe",
	"VALIDATION_ERROR_AnimalNotSet": "El animal no existe",
	"VALIDATION_ERROR_BuildingNumberNotSet": "Números de localidad no configurados",
	"VALIDATION_ERROR_BuildingNameNotSet": "Nombre de localidad no configurados",
	"VALIDATION_ERROR_ProductionFormNotSet": "Forma de producción no configurados",
	"VALIDATION_ERROR_PRODUCTION_TYPE_NOT_SET": "Tipo de producción no configurados",
	"VALIDATION_ERROR_COUNT_NOT_SET": "Conteo no configurado",
	"VALIDATION_ERROR_AVG_WEIGHT_NOT_SET": "Peso promedio no configurados",
	"VALIDATION_ERROR_BuildingNumberAlreadyExists": "Número de localidad yá existe",
	"VALIDATION_ERROR_SectionTypeNotSet": "Tipo de sección no seleccionados",
	"VALIDATION_ERROR_DuplicateSectionNumbers": "No se pueden generar secciones, debido a la duplicación del número de sección",
	"VALIDATION_ERROR_FEEDINGSTOCK_NO_FEEDING_SUBJECT_SELECTED": "Necesita elegir tipo de pienso",
	"VALIDATION_ERROR_DuplicatePenNumbers": "No se pueden generar corrales, debido a la duplicación de números de corrales",
	"VALIDATION_ERROR_RegistrationError": "Há ocurrido un error durante el registro",
	"VALIDATION_ERROR_HasNoSection": "Ninguna sección",
	"VALIDATION_ERROR_HasNoBuilding": "Ninguna localidad",
	"VALIDATION_ERROR_SetupValidationSetup": "Configure los intervalos de validación en la configuración de cerdas",
	"VALIDATION_ERROR_InvalidPregnantPeriod": "Periodo de preñes invalido. El intervalo debería de ser entre100-125 días",
	"VALIDATION_ERROR_invalidPlanPeriod": "Periodo de plan invalido. Es necesario ingresar el plan entre - y el plan + ",
	"VALIDATION_ERROR_InvalidNursingPeriod": "Periodo de lectación inválidos. No puede ser menos de 21 días",
	"VALIDATION_ERROR_LocationNotSet": "La localidad no se há configurado",
	"VALIDATION_ERROR_InvalidFarrowingAlive": "Verracos y cerdas, no equivalen con el total de cerdos activos",
	"VALIDATION_ERROR_IdNumberNotSet": "Número de ID no configurado",
	"VALIDATION_ERROR_WeaningNumAliveIsNotSet": "Lechones destetados no ingresados",
	"VALIDATION_ERROR_MoveDateNotSet": "Fecha de movimiento no ingresados",
	"VALIDATION_ERROR_NewPenIdNotSet": "Corral no ingresado",
	"VALIDATION_ERROR_NewPenSameAsCurrent": "El corral yá fué seleccionado",
	"VALIDATION_ERROR_FarrowingNumAliveNotSet": "Número de vivos no ingresados",
	"VALIDATION_ERROR_BuildingIsNotEmpty": "No se puede borrar,  la localidad no está vacia",
	"VALIDATION_ERROR_SectionIsNotEmpty": "No se puede borrar, la sección no está vacía",
	"VALIDATION_ERROR_MatingBatchOutOfRange": "Lote de cubrición no disponible anteriormente",
	"VALIDATION_ERROR_NotAllowedToDeleteDiagnose": "Diagnóstico en uso",
	"VALIDATION_ERROR_DiagnoseAnimalKindStillInUse": "Diagnóstico del tipo de animal en uso",
	"VALIDATION_ERROR_ColumnTextIsNotSet": "Si el diagnóstico es seleccionado,escriba columna texto",
	"VALIDATION_ERROR_BoarProcentageIsNotSet": "Si el verraco esta seleccionado, escriba verraco %",
	"VALIDATION_ERROR_NursingFromIsEmptyOrNegative": "Lactación desde es requerido, y no puede ser negativo",
	"VALIDATION_ERROR_NursingToIsEmptyOrNegative": "Lactación hasta es requerido, menos que lactación desde",
	"VALIDATION_ERROR_PregnantFromIsEmptyOrNegative": "Preñadas desde es requerido, y no puede ser negativo",
	"VALIDATION_ERROR_PregnantToIsEmptyOrNegative": "Preñadas hasta es requerido, menos que lactación desde ",
	"VALIDATION_ERROR_NursingDaysAreRequiered": "Días de lactación son requeridos",
	"VALIDATION_ERROR_AvailableOnNeedsToBeSet": "Listado disponible en, es necesario configurarlo",
	"VALIDATION_ERROR_ChoosePregnancyEvent": "Elija evento de preñes",
	"VALIDATION_ERROR_IncludeLittersTo": "Hasta los números de camadas son necesario ser configurados",
	"VALIDATION_ERROR_AfterEventDaysGreaterThanZero": "Días despues de eventos deberían de ser mayores que cero",
	"VALIDATION_ERROR_ChooseAtleastOneTreatmentDef": "Elija por lo menos una definición de tratamiento",
	"VALIDATION_ERROR_ChooseAnimalNotSet": "Elegir animal no esta configurado",
	"VALIDATION_ERROR_FEEDINGSTOCK_NO_FEEDING_SUBJECT_NAME": "Nombre de tipo de pienso",
	"VALIDATION_ERROR_AMOUNT_EXCEED_STOCK": "La cantidad excede la cantidad de pienso disponible",
	"VALIDATION_ERROR_NO_CODE_SET": "El código no esta configurado",
	"VALIDATION_ERROR_INVALID_CODE_SET": "Elija otro código",
	"VALIDATION_ERROR_DEFAULT_CODE_SET": "El código estandart no será aceptado",
	"VALIDATION_ERROR_NO_UNITTYPE_SET": "Elija unidades",
	"VALIDATION_ERROR_NO_PROCESS_EQUIPMENT_SET": "Elija procesos de unidades",
	"VALIDATION_ERROR_InsertWithoutTransponder": "No es posible crear un animal en ESF sin un número de transponder",
	"VALIDATION_ERROR_CycleDayIsEmpty": "El día de ciclo no esta configurado",
	"VALIDATION_ERROR_AnimalNumberIsEmpty": "No es posible actualizar animal al ESF sin un número de animal",
	"VALIDATION_ERROR_FeedCurveDoesNotExist": "La curva de pienso seleccionada no existe",
	"VALIDATION_ERROR_LocationIsEmpty": "La localidad no configurada",
	"VALIDATION_ERROR_InvalidAnimalKindOnEsfLocation": "El tipo de animal no puede ser creado en el corral del ESF",
	"VALIDATION_ERROR_FARM_HAS_ESF_BUT_ANIMAL_DOES_NOT_HAVE_TRANSPONDER": "El animal necesita tener un número de transponder, antes de ser transferido a un transponder",
	"VALIDATION_ERROR_FEED_CURVE_NUMBER_IS_NOT_SET": "Curva de pienso es requerida yá que la localidad elegida es una localidad del ESF",
	"VALIDATION_ERROR_ANIMALS_NEED_TRANSPONDER": "Los animales en la lista no cuentan con un número de transponder",
	"VALIDATION_ERROR_INVALID_ESF_DATA": "Los datos de los animales escaneados no estan actualizados",
	"VALIDATION_ERROR_BLE_CHOOSE_LOCATION": "Para utilizar el scanner, elija una localida standart en la aplicación",
	"VALIDATION_ERROR_SOW_INVALID_FOR_WORKLIST": "El animal no es valido en esta lista de trabajo",
	"VALIDATION_ERROR_ENTRACE_SOW_EDIT_LITTER": "Camadas deberían de ser ser 2 o más, por que el animal fué creado como una cerda",
	"VALIDATION_ERROR_MATING_BATCH_1_YEAR": "Es requerido tener un lote de camada al menos 1 año desde la fecha de hoy. Por favor genere más lotes de camadas",
	"VALIDATION_ERROR_MUST_BE_START_MATING_BATCH": "La fecha debe ser la fecha de inicio de un lote de apareamiento posterior al actual",
	"VALIDATION_ERROR_AVG_WEIGHT_POSITIVE": "El peso promedio debería de ser positivo",
	"VALIDATION_ERROR_DEAD_PIGLETS_NOT_SET": "Cantidad no configurada - Debería de ser mayor a 0",
	"VALIDATION_ERROR_MUST_BE_NURSING": "Cerdas deberían de estar en lactantes",
	"VALIDATION_ERROR_NO_FEEDING_ADJUSTMENT": "El tipo de pienso no tiene ningún ajuste antes de la fecha establecida",
	"VALIDATION_ERROR_DEPARTURE_DATE_IS_EARLIER_THAN_LAST_PREGNANCY": "La baja no es válida porque la fecha de salida es anterior al último evento de preñes.",
	"VALIDATION_ERROR_NO_DEPARTURE_ON_NURSING_SOW": "La baja no es válida porque la cerda se encuentra en estado de lactancia",
	"VALIDATION_ERROR_MOVE_EVENT_IS_EARLIER_THAN_LAST_EVENT": "La fecha de translado es anterior a la última fecha de translado",
	"VALIDATION_ERROR_TYPE_NOT_SET": "Tipo no configurado",
	"VALIDATION_ERROR_CODE_ALREADY_USED": "El código yá está en uso",
	"VALIDATION_ERROR_REASON_NOT_SET": "La razón no esta configurada",
	"VALIDATION_ERROR_NO_SOWS_IN_FILE": "Ninguna cerda en el archivo",
	"VALIDATION_ERROR_SOME_SOWS_EXISTS": "Algunas cerdas yá existen",
	"VALIDATION_ERROR_ANIMAL_ID_NUM_EXISTS": "El número de ID yá existe",
	"VALIDATION_ERROR_ANIMAL_IS_NOT_YOUNG_FEMALE": "El animal(s), no es hembra jóven",
	"VALIDATION_ERROR_NO_DEFAULT_WEANING_PEN": "Una localidad para lechones destetados es necesario. Configure en ajustes, Cerdas, General",
	"VALIDATION_ERROR_COUNT_LARGER_THAN_CURRENT_COUNT1": "Solamente",
	"VALIDATION_ERROR_COUNT_LARGER_THAN_CURRENT_COUNT2": "Lechón se encuentra actualmente en la localidad elegida",
	"VALIDATION_ERROR_COUNT_LARGER_THAN_CURRENT_COUNT3": "Existe",
	"VALIDATION_ERROR_COUNT_LARGER_THAN_CURRENT_COUNT4": "Lechones en localidad",
	"VALIDATION_ERROR_COUNT_LARGER_THAN_CURRENT_COUNT5": "Por lo tanto, baja no es posible",
	"VALIDATION_ERROR_FarrowingOutOfInterval": "No es posible asignar números de identificación. El parto no coincide con el intervalo.",
	"VALIDATION_ERROR_DrugHasUsageOrWasteRegistration": "Medicamento en uso o desperdicio registrado",
	"VALIDATION_ERROR_SET_FEED_CURVE": "Configurar curva de alimentación",
	"VALIDATION_ERROR_SET_RECIPE": "Configurar receta",
	"VALIDATION_WARNING_MatingToFarrowingOutOfInterval": "El animal tiene {{area}} días de preñés, que está fuera del intervalo configurado, desea guardar de todos modos?",
	"VALIDATION_ERROR_YOU_ARE_MATING_A_ANIMAL_THAT_IS_NURSING": "Estás cubriendo un animal, que está en lactación, ¿desea guardalo de todos modos?",
	"VALIDATION_WARNING_FarrowingToWeaningOutOfInterval1": "días de destete es",
	"VALIDATION_WARNING_FarrowingToWeaningOutOfInterval2": "Intervalo exedido, ¿desea guardar de todos modos?",
	"VALIDATION_WARNING_WeaningToMatingOutOfInterval": "El período desde el destete hasta la cubrición está fuera del intervalo configurado, ¿desea guardarlo de todos modos?",
	"VALIDATION_WARNING_MatingAgeOutOfInterval": "La edad del animal está fuera del intervalo configurado, ¿desea guardar de todos modos?",
	"VALIDATION_WARNING_ConfirmDeletionMated": "¿Eliminar la cubrición elegida?",
	"VALIDATION_WARNING_ConfirmDeletionFeedingStockAdjustment": "¿Está seguro de que desea eliminar el ajuste de pienso?",
	"VALIDATION_WARNING_ConfirmDeletionFeedingStockUsage": "¿Está seguro de que desea eliminar el uso de pienso?",
	"VALIDATION_WARNING_ConfirmDeletionAdjustments": "¿Eliminar el ajuste del medicamento elegido?",
	"VALIDATION_WARNING_ConfirmDeletionScanning": "¿Eliminar el ecógrafo?",
	"VALIDATION_WARNING_ConfirmDeletionFarrowing": "¿Eliminar parto?",
	"VALIDATION_WARNING_ConfirmDeletionWeaning": "¿Eliminar el destete?",
	"VALIDATION_WARNING_CapacityExceeded1": "Capacidad excedida en el corral:",
	"VALIDATION_WARNING_CapacityExceeded2": "Cantidad actual de cerdos:",
	"VALIDATION_WARNING_CapacityExceeded3": "La capacidad es:",
	"VALIDATION_WARNING_ConfirmWarningMessage": "¿Esta seguro que deséa guardarlo?",
	"VALIDATION_WARNING_ConfirmWarningDataNotSavedMessage": "Hay datos que no se guardan, ¿desea guardar?",
	"VALIDATION_WARNING_ConfirmWarningMessageMoveEvent": "¿Estás seguro de que quieres mover este animal?",
	"VALIDATION_WARNING_ConfirmDeletionBuilding": "¿Está seguro de que desea eliminar esta granja?",
	"VALIDATION_WARNING_ConfirmDeletionSection": "¿Está seguro de que desea eliminar esta sección?",
	"VALIDATION_WARNING_ValveGroupSectionContainsOldData": "La sección seleccionada contiene datos antiguos del grupo de válvulas",
	"VALIDATION_WARNING_ValveGroupsModificationOld": "La modificación en el los grupo(s) de válvulas no se guardó porque estaba desactualizado",
	"VALIDATION_WARNING_ValveGroupIsNotEditable": "Los datos de la válvula son de ayer y no se pueden editar debido a los datos antiguos. A través del sitio web, ajuste los cambios de alimentación, puede descargar los últimos datos de Distriwin",
	"VALIDATION_WARNING_ESF_DATA_IS_NOT_UP_TO_DATE": "Algunos datos no están actualizados con el controlador",
	"VALIDATION_ERROR_CURVE_INVALID": "Es necesario seleccionar la curva para guardar",
	"VALIDATION_ERROR_FIXED_PERCENT_INVALID": "El porciento debe ser 0-100 para guardar",
	"VALIDATION_WARNING_DELETE_MARKED_YOUNG_ANIMALS": "¿Eliminar los animales seleccionados?",
	"VALIDATION_WARNING_EDIT_GROWTH_PIG_EVENT": "Los cambios darán como resultado una cantidad negativa de cerdo, ¿Deséa continuar?",
	"VALIDATION_WARNING_CAPACITY_EXCEEDED": "Limite de capacidad en {{area}}% excedido, deséa guardarlo?",
	"VALIDATION_WARNING_CAPACITY_EXCEEDED_MANY": "Limite de capacidad en {{area}}% excedido en lote {{area21}}, deséa guardarlo?",
	"SlaughterHouse": "Matadero",
	"connectToTican": "Conección a Tican",
	"DanishCrown": "Danish Crown",
	"Tican": "Tican",
	"loginInformation": "Información de acceso",
	"connectionSlaughterHouse": "Comunicación al matadero",
	"LoginInformationDanishCrown": "INFORMACIÓN DE ACCESO DANISH CROWN",
	"UserId": "ID del usuario:",
	"password": "Contraseña:",
	"SupplierNumbers": "Distribuidor:",
	"meatPercentage": "Carne %",
	"slaughterWeight": "Peso de matadero",
	"checkSlaughterDataWithDepartureData": "Control de faéna con el matadero",
	"departureSkioldDigital": "Bajas=Contéo de matadero",
	"departureSlaughterHouse": "Conteo de matadero",
	"difference": "Diferencia",
	"checkSlaughterData": "Chequéo de faéna/datos de mataderos",
	"finisherData": "Datos de matadero",
	"supplier": "Número del distribuidor",
	"chooseLocationForMove": "Elija la localidad para el movimiento",
	"APig": "A-cerdos",
	"Discarded": "Descartados",
	"BesBoar": "Bes. Verraco",
	"DiscardedSow": "Cerdas descartadas",
	"DiscardedBesBoar": "Disc. Bes. Verracos",
	"RemovedMale": "Bajas machos",
	"OtherDelievered": "Otras entregas",
	"ReturnPig": "Lechones de retorno",
	"Barrow": "Carretilla",
	"Basic": "Básico",
	"slaughterNumber": "Número de faéna",
	"noting": "Nota",
	"aliveWeight": "Peso vivo",
	"SavingChangesTheLocationForAllPigsInThisSectionForThisDelieveryForThisDate": "¿Deséa cambiar la localidad para la entrega?",
	"DoYouWantToChangeTheLocationOfThoseItems": "¿Deséa cambiar la loacalidad marcada?",
	"DoYouWantToDeleteSlaughterDatas": "¿Deséa borrar los datos de faéna marcados?",
	"FinisherDataProcessFinisher": "Datos de faéna",
	"Failed_To_Login_Tican": "Error con Tican, por favor vuelva a intentar",
	"Error_SlaughterHouse_required": "Se requiere un matadero",
	"avgPricePrKg": "Kr./kg",
	"IncorrectLoginInformation": "Información de acceso incorrectos",
	"supplementDeductionPrPig": "Suplemento/deducción por cerdo",
	"spec": "Spec",
	"Note": "Nota",
	"priceKgBasis": "KR/KG BASIS",
	"noteText": "Nota texto",
	"priceWithDeduction": "Precio incluido suplementos basicos/deducciónes",
	"PromptSureDelete": "Esta seguro que deséa borrar?",
	"SetupValidationSetup": "Ingrese las configuraciones de validación en sección de configuración",
	"TreatmentDefinitions": "Tratamientos/Definición de vacunas",
	"drug": "Medicamento ",
	"chooseDrugType": "Elija tipo de medicamento",
	"RetentionTime": "Horario de retención",
	"method": "Metodo",
	"severiy": "Gravedad",
	"amountPerUnit": "Cantidad por unidad",
	"duration": "Duración",
	"interval": "Intervalo",
	"amount": "Cantidad",
	"numberTreatments": "Cantidad",
	"per": "por",
	"addTreatDef": "Agregar difinición",
	"choose": "Elegir ",
	"SowBoarGlit": "Cerdas/Verracos/Primerizas",
	"none.1": "Ninguno",
	"CanBeRegistration": "Podría ser el registrado",
	"productionKey": "ESCALA DE PRODUCCIÓN",
	"feedKey": "PIENSO",
	"diverseInformationKey": "INFORMACIÓNES VARIAS",
	"slaughterDataKey": "DATOS DE MATADERO",
	"stateKey": "ESTADO",
	"subResultKey": "SUB RESULTADOS",
	"FeedUsageKey": "CONSUMO DE PIENSO",
	"showFeedUsage": "Consumo de pienso",
	datagrowthPigPReportSlaughterHouseData: {
		"amountBasicPcs": "Básico, uds.",
		"amountOverPcs": "Mayor, uds.",
		"amountUnderPcs": "Menor, uds.",
		"avgSlaughterWeight": "Promedio peso de faena",
		"avgMeatPercentage": "Promedio porcentaje de carne",
		"avgDiscarded": "Porcentaje de desecho",
	},
	datagrowthPigPReportSubResults: {
		"feedDays": "Promedio días de consumo",
		"periodGrowth": "Periodo de crecimiento, kg.",
	},
	datagrowthPigPReportDiverseInformation: {
		"growthPerPigKg": "Crecimiento por cerdo, kg.",
		"avgWeightAtEntry": "Promedio peso de entrada, kg.",
		"avgWeightAtDeparture": "Promedio peso de baja, kg.",
		"feedDaysPerProducedPigs": "Días de alimentación por cerdo producido",
		"diversePercentDead": "Porcentajes de bajas",
	},
	datagrowthPigPReportProduction: {
		"producedPigsPcs": "Cerdos producido, uds.",
		"dailyGrowth": "Crecimiento diario, g",
		"referenceDailyGrowth": "Referencia de crecimiento diario",
	},
	datagrowthPigPReportState: {
		"activePcs": "Activos, uds.",
		"totalEntryInPeriod": "Entradas, uds.",
		"entryFromBought": "Parte de comprados, uds.",
		"entryFromTransfered": "Parte de transferidos, uds.",
		"entryFromWeaning": "Parte de destetados, porcientos",
		"avgWeightAtEntry": "Promedio peso de entrada, porcientos",
		"deadPutDownPcs": "Muertos/Sacrificados, porcientos",
		"avgWeightDeadPutDown": "Promedio, peso de muertos, kg.",
		"soldTransferedPcs": "Vendidos/transferidos, uds.",
		"transferedToYoungFemale": "Parte de hembras jóvenes",
		"avgWeightSoldTransfered": "Promedio peso Vendidos/transferidos, kg.",
		"delieveredPcs": "Entregados, uds.",
		"avgWeightDelievered": "Promedios peso de entrega, kg.",
		"activeWeight": "Promedios peso",
		"totalSlaughteredRegistrations": "Bajas. sacrificados",
		"avgWeightSlaughteredRegistrations": "Peso promedio de matadero. Kg.",
	},
	datagrowthPigPReportFeed: {
		"feedPerPigDaily": "Consumo de pienso por cerdo diario, FUsv",
		"feedPerKgGrowth": "Consumo de pienso por kg. de crecimiento, Fesv",
		"referenceFeedUsage": "Referencia de consumo de pienso por kg, Fesv",
		"feedPerProducedPig": "Pienso por cerdo producido, FEsv",
	},

	"ProducedPigsPcs": "Cerdo producido, uds.",
	"DailyGrowth": "Crecimiento diario, g",
	"ReferenceDailyGrowth": "Referencia crecimiento diario",
	"WeanerReferenceDailyGrowth": "Referencia crecimiento diario 7- 30 kg",
	"FinisherReferenceDailyGrowth": "Referencia crecimiento diario 30 - 115 kg",
	"FRATSReferenceDailyGrowth": "Referencia crecimiento dirario 7-115 kg",
	"GrowthPerPigKg": "Crecimiento por cerdo, kg",
	"AvgWeightAtEntry": "Peso promedio de entrada, kg",
	"AvgWeightAtDeparture": "Peso promedio de baja, kg",
	"FeedDaysPerProducedPigs": "Días de alimentación por cerdo producido",
	"DiversePercentDead": "Porcentaje de bajas",
	"FRATSActivePcs": "Cebos, uds.",
	"WeanerActivePcs": "Destetados, uds.",
	"FinisherActivePcs": "Cebo, uds.",
	"TotalEntryInPeriod": "Entradas, uds.",
	"EntryFromWeaning": "Parte de destados, uds.",
	"EntryFromBought": "Parte de comprados, uds.",
	"EntryFromTransfered": "Parte de transferidos, uds.",
	"DeadPutDownPcs": "Muertos/Sacrificados, uds.",
	"AvgWeightDeadPutDown": "Peso promedio muertos, kg",
	"SoldTransferedPcs": "Vendidos/transferidos, uds.",
	"TransferedToYoungFemale": "Parte de animales jovenes",
	"AvgWeightSoldTransfered": "Peso promedio vendidos/transferidos, kg.",
	"DelieveredPcs": "Entregados, uds.",
	"TotalSlaughteredRegistrations": "Bajas, Á matadero",
	"AvgWeightSlaughteredRegistrations": "Peso promedio sacrificados, kg",
	"AvgWeightDelievered": "Peso promedio entregados, kg",
	"FeedDays": "Periodo de alimentación en días",
	"PeriodGrowth": "Periodo de crecimiento, kg",
	"AmountBasicPcs": "Básico, uds.",
	"AmountOverPcs": "Por encima del básico, uds.",
	"AmountUnderPcs": "Por debajo del básico, uds.",
	"AvgSlaughterWeight": "Peso promedio sacrificados, kg",
	"AvgMeatPercentage": "Promedio de porcetaje de carne",
	"AvgDiscarded": "Promedio de descartados",
	"FeedPerPigDaily": "Pienso diario por cerdo/Uni. Pieso cerdos",
	"FeedPerKgGrowth": "Pienso por kilo de crecimiento/Uni. Pienso cerdos",
	"ReferenceFeedUsage": "Referencia de uso de pienso por kg",
	"WeanerReferenceFeedUsage": "Referencia de uso de pienso por kg 7-30 kg",
	"FinisherReferenceFeedUsage": "Referencia de uso de pienso por kg 30-115 kg",
	"FRATSReferenceFeedUsage": "Referencia de uso de pienso por kg 7-115 kg",
	"FeedPerProducedPig": "Pienso por cerdo producido/ Uni. Pienso cerdos",
	"weanerFoodTotal": "Pienso de destetados en total",
	"finisherFoodTotal": "Pienso de cebadero en total",
	"createDelievery": "Crear número de entrega",
	"ReportsWeaner": "Reportes",
	"ReportsFinisher": "Reportes",
	"reportsWeaner.1": "Reportes",
	"reportsFinisher.1": "Reportes",
	"growthPigPReportProduction": "Alcance de producción",
	"growthPigPReportDiverseInformation": "Informaciones varias",
	"growthPigPReportFeed": "Pienso",
	"growthPigPReportState": "Estado",
	"growthPigPReportSubResults": "Sub resultados",
	"growthPigPReportSlaughterHouseData": "DATOS MATADERÍA",
	"Calculated": "Calculados",
	"Tuned": "Afinada",
	"tuneProductionReportWeaner": "Afinamiento de destetados",
	"tuneProductionReportFinisher": "Afinamiento de cebaderos",
	"tuneProductionReportFRATS": "Afinamiento de cebaderos",
	"weanerPigsTuneProductionLists": "Afinamiento de cerdos",
	"finisherPigsTuneProductionLists": "Afinamiento de cerdos",
	"Tune": "Afinamiento",
	"ActiveWeight": "Peso promedio",
	"entered": "Entrada",
	"internalTransfer": "Transferencias internas",
	"Food": "Pienso",
	"Water": "Agua",
	"Oral": "Oral",
	"Injection": "Injección",
	"OnTheSkin": "En la piel",
	"perKgPig": "Kg cerdo",
	"perKgFood": "Kg pienso",
	"perAnimal": "Animal",
	"once": "Una vez",
	"everyDay": "Cada día",
	"everyOtherDay": "Cada otro día",
	"everyWeek": "Cada semana",
	"event.1": "Evento",
	"Days1": "Días 1",
	"Days2": "Días 2",
	"Age1": "Edad 1",
	"Age2": "Edad 2",
	"Age3": "Edad 3",
	"Farrowing": "Parición",
	"Mating": "Cubrición",
	"Averting": "Destete",
	"Birthdate.1": "Fecha de nacimiento",
	"chooseDrug": "Elija el medicamento",
	"TREATMENT_LOG": "TRATAMIENTOS",
	"Treatments.1": "Tratamientos",
	"Treatment.1": "Tratamiento",
	"FetchTreatmentPlans": "Buscar",
	"ExecutedDate": "Ejecutado en",
	"Reactivate": "Reactivado",
	"FromDate": "Desde fecha",
	"ToDate": "Hasta fecha",
	"User": "Usuario",
	"CountFirstTreatments": "Número de 1. tratamiento",
	"CountRetreatments": "Numero de retratamientos",
	"treatmentDetails": "Detalles de tratamientos",
	"Treated": "Tratado",
	"DeleteTreatmentPlan": "Borrar el plan de tratamiento",
	"DeleteTreatmentPlanPrompt": "¿Realmente deséa borrar el plan de tratamiento? El mismo no puede quedar sin efecto",
	"TreatmentPlanNotFound": "Plan de tratamientos no encontrado",
	"PerAnimal.1": "Por animal",
	"PerPig": "Por cerdo",
	"Total": "Total",
	"OverdueAlertHeader": "Tratamiento atrasado!",
	"OverdueAlertMessage": "Cuando realizó el tratamiento?",
	"OverdueAlertHeaderDepart": "Muerto/sacrificado",
	"OverdueAlertMessageDepart": "Cuando realizó el registro?",
	"PregnancyLists": "Lista de partos",
	"moveEvents": "Movimientos",
	"VALIDATION_ERROR_WEIGHT_NOT_DISTRIBUTED": "El peso no distribuido",
	"VALIDATION_ERROR_TO_MUCH_WEIGHT_DISTRIBUTED": "Demasiado peso distribuido",
	"VALIDATION_ERROR_PIGS_NOT_DISTRIBUTED": "Cerdos no distribuidos",
	"VALIDATION_ERROR_TO_MANY_PIGS_DISTRIBUTED": "Exceso de lechones distribuidos",
	"VALIDATION_ERROR_TO_MANY_PIGS_MOVED": "Exceso lechones transferidos",
	"VALIDATION_ERROR_TO_MANY_PIGS_DEPARTED": "Exceso de bajas de lechones",
	"VALIDATION_ERROR_FROM_LOCATION_NOT_SET": "Desde que localidad, no se há seleccionado",
	"VALIDATION_ERROR_TO_LOCATION_NOT_SET": "Para que localidad, no se há seleccionado",
	"VALIDATION_ERROR_FROM_PRODUCTIONTYPE_NOT_SET": "De que tipo de producción, no se há seleccionado",
	"VALIDATION_ERROR_TO_PRODUCTIONTYPE_NOT_SET": "Para que tipo de producción, no se há seleccionado",
	"VALIDATION_ERROR_NO_PIGS_MOVED": "Ningún lechón se há transladado",
	"VALIDATION_ERROR_NO_PIGS_DEPARTED": "Ninguna baja de lechón",
	"productionType": "Tipo de producción",
	"GrowthPigEntrance": "Comprados",
	"GrowthPigMoveEvent": "Movimientos",
	"VALIDATION_REASON_NOT_SET": "No se há ingresado la razón",
	"GrowthPigEntranceEvent": "Comprados",
	"GrowthPigDepartureEvent": "Vendidos/faénados",
	"GrowthPigPenDepartureEvent": "Sacrificados/muertos",
	"toProductionType": "A producción",
	"fromProductionType": "De producción",
	"DistributePigsInSection": "Distribución de lechones en sección",
	"SpecifyWeight.1": "Peso especifico",
	"AmountPigsNotDistributed": "Cantidad de lechones, no distribuidos",
	"TotalWeightNotDistributed": "Peso, no distribuido",
	"FromWeaner": "De destetados",
	"FromFinisher": "De sebaderos",
	"FromPiglet": "De lechones destetados",
	"FromBuilding": "De granja",
	"FromSection": "De sección",
	"ToSection": "A sección",
	"ToBuilding": "Al establo",
	"PenNumber": "Número de corral",
	"Bought": "Comprados",
	"Moved": "Movidos",
	"Distribute": "Distribuidos ",
	"DistributeAll": "Distribuir todos",
	"MoveAll": "Mover todos",
	"DepartureAll": "Todas las bajas",
	"DistributeWeight": "Distribución de peso",
	"fromBuildingName": "De granja",
	"fromSectionName": "De sección",
	"fromPenName": "De corral",
	"toBuildingName": "Al establo",
	"toSectionName": "A sección",
	"toPenName": "Al corral",
	"GrowthPigEventsList": "Listado de eventos",
	"EventsList": "Lista de eventos",
	"toProctionType": "A producción",
	"fromProctionType": "De producción",
	"YouHaveToSelectLocation": "Tienes que seleccionar una ubicación",
	"appFinishersManagement": "Sebaderos",
	"appSowManagement": "Cerdas",
	"appWeanersManagement": "Destetados",
	"DistributeCount": "Distribución venta",
	"DistributeSlaughter": "Distribución faenados",
	"TotalPigsInPeriod": "Total de lechones en periodo",
	"ShouldDepartureList": "Lista de bajas",
	"ShouldDepartureListWeaner": "Lista de bajas",
	"ShouldDepartureListFinisher": "Lista de bajas",
	"weanerShouldDepartureOverview": "Lista de bajas",
	"MovePigsOnLocation": "Translados de lechones en locación",
	"finisherShouldDepartureOverview": "Lista de bajas",
	"DistributeMoveFrom": "Distribución de movimientos desde ",
	"DistributeTo": "Distribución desde",
	"NegativePigAmountBeforeMove": "Corrales con cantidad negativa, solo se pueden mover {{area}} cerdos. Se debe igualar el conteo con una cantidad positiva. ¿Deséa realizar el movimiento? {{area}}",
	"mating.1": "Cubrición",
	"Matings": "Cubriciones",
	"Scannings": "Ecógrafo ",
	"Farrowings": "Partos",
	"Farrowed": "Paridas",
	"Weanings": "Destetados",
	"Grade": "Grado",
	"NumAlive": "Cantidad de vivos",
	"NumAliveTotal": "Total nacidos vivos",
	"NumDead": "Cantidad muertos",
	"NumDeadTotal": "Total nacidos muertos",
	"TypeAnimalNumber": "Tipo de número de animal",
	"ValidateAgeAtFirstMating": "La edad mínima para la primera cubrición es {{area}} días",
	"ValidateSetAgeAtFirstMating": "Edad especifica para la primera cubrición : Debajo de configuraciones, Cerdas, General, Eventos, Cubrición",
	"ValidateSetMaxDaysAfterBoarDeparture": "Día específico un verraco esta autorizado despues de la baja: Debajo de configuraciones, Cerdas, General, Eventos, Cubriciones",
	"ValidateMaxDaysAfterBoarDeparture": "Un verraco solamente puede ser utilizado un máximo, {{area}} días despues de fecha de baja",
	"ValidateNucleusBoarEntranceDate": "El verraco no fué parte de animal de granja en la fecha elegida",
	"searchBoars": "Buscar verraco",
	"Dry": "Vacía",
	"Preg": "Preñada",
	"Nursing": "Lactante",
	"PregnantAndNursing": "Preñada/Lactante",
	"NursingLong": "Lactante",
	"Gestation": "Preñada",
	"Maternity": "Lactante",
	"True": "Si ",
	"False": "No",
	"Maybe": "Talvéz",
	"scanning": "Ecógrafo",
	"Pregnant": "Preñada",
	"NonPregant": "No preñada",
	"farrowing.1": "Partos",
	"alive": "Vivos",
	"dead": "Muertos",
	"aliveFemale": "Lechones hembras vivos",
	"aliveMale": "Lechones machos vivos",
	"abortion.1": "Aborto",
	"farrowingInProgress": "Parto en preceso",
	"FarrowingDate": "Fecha de parto",
	"LitterWeight": "Peso de camada",
	"Mumified": "Mumificadas",
	"Assign": "Asignado",
	"AssignId": "Número de ID asignado",
	"EarNotch": "Número de crotal",
	"LitterBreed": "Camada de cria",
	"ValidateAssignIdCount": "Los números de ID asignados no corresponden a lechones vivos. Editar los números de ID?",
	"CurrentSecondMatingNumber": "Actual 2do. Número de cubrición",
	"ValidateNucleusAbortion": "No es posible registrar un aborto, fuera de los intervalos de {{area}}-{{area2}} días",
	"ValidateNoAssignedRegistrationsCheck": "No se han realizado marcas en los animales jóvenes que deben ser creados",
	"pcsWithoutDot": "Uds.",
	"pcs": "Uds.",
	"Pcs.1": "Uds.",
	"avgWeight": "Peso promedio / lechón",
	"avgWeightShort": "Peso promedio",
	"avgWeightSlashPig": "Peso / lechón",
	"avgPriceSlashPig": "precio/ lechón",
	"totalWeight": "Peso total",
	"nursingSow": "Cerdas lactantes",
	"avgPrice": "Precio por lechón",
	"totalPrice": "Precio total",
	"Weaning": "Destete",
	"nursSow": "Cerdas lactantes",
	"totalPcs": "Uds. total",
	"avgPcs": "Promedio de unidades",
	"defaultWeight": "Peso por defecto",
	"NoWeaningLocationSet": "No se há ingresado la localidad",
	"matingBatch": "Lote de cubrición ",
	"AmountMatingBatchesPerColumn": "Cantidad de lotes de cubrición por columnas",
	"matingBatchUpToAndIncluding": "Lote de cubrición calculado hasta y incluido",
	"weekdayStart": "Inicio de semana",
	"StartDateForBatch": "Fecha de inicio lote de cubrición",
	"daysForMatingPeriod": "Días en periodo de cubrición",
	"cycleInWeeks": "Ciclos en semanas",
	"matingPeriod": "Periodo de cubrición",
	"FarrowingPeriod": "Periodo de partos",
	"batchNum": "Número de lotes",
	"pregnantDays": "Días de preñes",
	"NursingDays": "Días de lactación",
	"calculate": "Cálculos",
	"addMatingBatch": "Agregar lotes de cubrición",
	"deleteMatingBatch": "Borrar lotes de cubrición",
	"weaningPeriod": "Periodo de destetes",
	"Start": "Inicio ",
	"End": "Final",
	"deleteMatingBatchesFromDateAndForward": "Borrar lotes de cubrición desde esta fecha en adelante",
	"repeat": "Repetir",
	"manuel": "Manual",
	"daysExceeded": "Números de días excedidos",
	"batchPerCycle": "Calidad de lotes - Inicio de lotes 1 por",
	"timesToRepeat": "Tiempos para repetir",
	"setStartDateToNextAvailableBatch": "¿Establecer fecha de inicio para el próximo lote de apareamiento disponible?",
	"matingBatchReport": "Reporte de lote de cubrición",
	"printMatingBatchReport": "Imprimir",
	"actualWeek": "Semana actual",
	"TotalMatings": "Cubriciones, uds.",
	"YoungAnimalMatings": "• Hembras jóvenes, uds.",
	"TotalDepartures": "Bajas de animales de lotes, uds.",
	"DepartureNonPregnant": "• No preñadas, uds.",
	"DepartureKilled": "• Salida á matadero/vendidos, uds.",
	"DepartureDead": "• Parte de muertos/sacrificados, uds.",
	"AvgWasteDays": "Promedio días no productivos",
	"ReturnSows": "• Cerdas repetidas",
	"ThereOfAbortions": "• Abortos",
	"WeaningToFirstMating": "Días desde destete á primera cubrición",
	"AnimalsToFarrowing": "Animales á parir, uds.",
	"TotalFarrowings": "Partos, uds.",
	"NeedFarrowing": "Cerdas que todavía no parierón, uds.",
	"AvgAlive": "Promedio, nacidos vivos, uds.",
	"AvgDead": "Promedio, nacidos muerto uds.",
	"FarrowingRate": "Tasa de parto para el lote, %",
	"AvgPregnantDays": "Promedio días de preñés",
	"AvgPregnantDaysPreviousBatch": "Promedio días de preñes en camadas anteriores",
	"ThereOfFirstParity": "• Parte de 1ra. Parición",
	"AvgLivebornFirstParity": "Promedio nacidos vivos 1er parto, uds.",
	"TotalLiveBorn": "Nacidos vivos, uds.",
	"StillbornToAliveRate.1": "% Nacidos muertos del total de nacidos vivos",
	"TotalWeanings": "Cerdas destetadas, uds.",
	"NursingSows": "• Parte de cerdas lactantes, uds.",
	"NeedWeaning": "Cerdas no destetadas, uds.",
	"AvgAlivePerLitter": "Promedio nacidos vivos por camada, uds.",
	"AvgDeadPerLitter": "Promedio nacidos muertos por camada, uds.",
	"AvgWeanedPerLitter": "Promedio lechones destetados por camada, uds.",
	"AvgWeight.1": "Peso por lechón, kg",
	"DeadTillWeaning": "Lechones muertos",
	"DeadToAliveRate": "Muertos hasta destetes, %",
	"AvgNursingDays": "Promedio días de lactación",
	"NursingSowsCount": "Cerdas lactantes",
	"AvgAliveShort": "Promedio vivos",
	"AvgDeadShort": "Promedio muertos",
	"AvgPriceShort": "Promedio de precio",
	"AvgWeanedPerWeaninig": "Destetados por destetes uds.",
	"TotalWeanedPiglets": "Total de destetados, uds.",
	"Monday": "Lunes",
	"Tuesday": "Martes",
	"Wednesday": "Miercoles",
	"Thursday": "Jueves",
	"Friday": "Viernes",
	"Saturday": "Sabado ",
	"Sunday": "Domingo",
	"FeedingAdjustment": "Ajuste de pienso",
	"FeedingAdjustment_purchase": "Compra de pienso",
	"FeedingUsage": "Utilización de pienso",
	"FeedingStock": "Existencia de pienso",
	"FeedingLog": "Registro de pienso",
	"CreateFood": "Crear pienso",
	"EditFood": "Editar compra de pienso",
	"EditFoodUsage": "Editar consumo de pienso",
	"CreateUsage": "Crear consumo",
	"CreatePurchase": "Compra de pienso",
	"FeedingType": "Tipo de pienso",
	"FeedingSubject": "Concepto de pienso",
	"FeedingSubjectName": "Nombre de concepto de pienso",
	"AmountInKgs": "Cantidad en kg.",
	"FEsoPerKg": "Unida de pienso cerdas /kg",
	"FEsvPerKg": "Unidad de pienso cebos/kg",
	"purchase": "Compras",
	"RawProtein": "Proteina cruda % del producto",
	"FosforGPerKg": "Fósforo g/kg",
	"PricePer100Kgs": "Precio por 100 kg",
	"PeriodFrom": "Periodo desde",
	"PeriodTo": "Periodo hasta",
	"UsageKg": "Utilización kg.",
	"UsageFE": "Utilización unidades de pienso",
	"UsageFEso": "Utilización unidades de pienso cerdas",
	"UsageFEsv": "Utilización unidades de pienso cebo",
	"UsageMoney": "Dinero utilizado",
	"feedingLog.1": "Registro de pienso",
	"FEso": "Unidades de pienso cerdas",
	"FEsv": "Unidades de pienso cebo",
	"KG.1": "KG",
	"NoRelation": "Ninguna relación",
	"DrugStock": "Existencia de medicamento/vacuna ",
	"adjustmentType": "Typo de ajustes",
	"registeredBy": "Registrado por",
	"registeredOn": "Fecha de registro",
	"medicineLog": "Registro de medicamentos",
	"AddToDrugStock": "Agregar",
	"lastRegulation": "Ùltima regulación",
	"Waste": "Desperdicio",
	"Usage": "Utilizado",
	"UpdateInventory": "Inventario",
	"MedicineStockStorage": "Inventarios",
	"UpdateMedicineStock": "Regulación",
	"AddDrug": "Agregar medicamentos a existencia",
	"UpdateDrugStockAmount": "Actualizar cantidad de existencia",
	"currentDrugStockAmount": "Inventario actual",
	"newDrugStockAmount": "Cantidad de existencia nueva",
	"createMedication": "Crear una medicación",
	"Medicine": "Medicamento",
	"Vaccine.1": "Vacuna",
	"SOWCARD.1": "FICHA DE CERDA",
	"location.1": "Localidad",
	"IDnr": "Número de ID ",
	"comment": "Comentario",
	"move": "Mover ",
	"wasteDays": "Días de desperdicio",
	"wasteDaysShort": "Desperdicio",
	"weaningShort": "Destete",
	"pigYearSo": "Lechón/cerda año",
	"pregnantDaysShort": "Preñada",
	"gradeShort": "K",
	"abortionSowCard": "Aborto ficha de cerda",
	"nursingDaysShort": "Días de lactación",
	"aliveShort": "Vivos",
	"kilo": "Kg",
	"pregDays": "Días de preñes",
	"nursDays": "Días de lactación",
	"stillBorn": "Nacidos muertos",
	"lastMoved": "Ùltimo movimiento",
	"departed": "Bajas",
	"BreedingNr": "Nro. de cría",
	"BreedingNumberAndNumberBatch": "Número de cría - número de lote",
	"Boars.1": "Verracos",
	"Relief": "Liberados",
	"ReliefFinisher": "Cebos liberados",
	"ReliefWeaners": "Destetados liberados",
	"Holistic": "Holístico",
	"Weaners": "Destetes",
	"ClimatePigs": "Destetes",
	"Finisher": "Cebos",
	"locationType": "Tipo",
	"Silo": "Silo",
	"PostponeSows": "Cerda posponida",
	"WeanedPiglets": "Destetados ",
	"FRATS": "Cebo",
	"FeedMill": "Molino de pieso",
	"Weaner": "Destetes",
	"animalType": "Tipo de animal",
	"production": "Producción",
	"PigProduction": "Cerdo de producción",
	"GrainMill": "Molinos de granos",
	"Feed": "Pienso",
	"Mills": "Molinos",
	"lowerLimit": "Límite bajo",
	"upperLimit": "Límite alto",
	"plan": "Plan",
	"Sell": "Vender",
	"ToBeMoved": "A ser movidos",
	"validation": "Validación",
	"weaningAnimals": "Animales de destetes",
	"usingQRForSows": "Utilizando códigos QR por cerda",
	"usingQRForLocations": "Utilizando códigos QR po localidad",
	"MatingAge": "Edad de cubrición para animales jóvenes",
	"MatingToFarrowing": "Cubrición á parto",
	"FarrowingToWeaning": "Parto á destete",
	"FarrowingToAverted": "Parto á destete",
	"AvertedToMating": "Desde el destete á 1. cubrición",
	"systemMinimum": "Sistema mínimo",
	"systemMaximum": "Sistema máximo",
	"deviationMinimum": "Plan de desviación - minimo",
	"deviationMaximim": "Plan de desviación - máximo",
	"weanedPigletsMovedToLocation": "Lechones destetados movidos a localidad:",
	"Component": "Componente",
	"Destination": "Destinación",
	"NewDeliveryIn": "Nueva entrega en",
	"NewDeliveryInBulk": "Nueva entrega a granel en",
	"NewDeliveryInBag": "Nueva entrega de bolsas nuevas en",
	"NoComponentsReceived": "Ningún componente avaliable",
	"ChooseComponent": "Elija el componente",
	"ChooseSupplier": "Elija el proveedor",
	"ChooseTruck": "Elija el camión",
	"ChooseSourceSilo": "Elija el silo de recurso",
	"ChooseDestinationSilo": "Elija el silo de destino",
	"ChooseWarehouse": "Elija el almacen",
	"Weighing": "Pesaje",
	"FirstWeighing": "Primer pesaje",
	"FirstWeighingShort": "1er. Peso",
	"SecondWeighing": "Segundo peso",
	"SecondWeighingShort": "2do. peso",
	"TotalReceived": "Total recibidos",
	"StartTransport": "Empezar el transporte",
	"DeliveryInCreating": "El pedido esta creado en FlexMix Pro",
	"DeliveryInUpdating": "El pedido esta actualizado en FlexMix Pro",
	"DeliveryInCreatingSuccess": "La orden se há creado satisfactoriamente",
	"DeliveryInUpdatingSuccess": "La orden se há actualizado satisfactoriamente",
	"DeliveryInCreatingSuccessFmpOnly": "El pedido se creó en FlexMix Pro, pero hubo un problema al guardarlo en Skiold Digital",
	"DeliveryInUpdatingSuccessFmpOnly": "El pedido se actualizó en FlexMix Pro, pero hubo un problema al guardarlo en Skiold Digital",
	"DeliveryInSavingFailed": "Hubo un problema al enviar el pedido a FlexMix Pro",
	"DeliveryInSavingOrderNumber": "El número de orden es",
	"SaveAndSendOrder": "Guardar y enviar orden",
	"CancelDelivery": "Confirme la cancelación del pedido",
	"TotalWeight.1": "Peso total",
	"Order.1": "Orden",
	"ActiveOrders": "Orden activas",
	"OrderNumber": "Número de orden",
	"FilterByOrderNumberOrComponent": "Filtre por número de orden o componente",
	"workLists": "Lista de trabajo",
	"WorkLists.1": "Lista de trabajo",
	"saveWorklist": "¿Realizar acciones seleccionadas en la lista de tabajo antes de salir de la página?",
	"ChooseWorkLists": "Elija lista de trabajo",
	"Synchronize": "Sincronización",
	"Setup": "Configuración",
	"pregnantFromDate": "Preñadas desde fecha",
	"pregnantToDate": "Preñadas hasta la fecha",
	"chooseAnimalToList": "Elija animales de la lista",
	"MoveTo": "Mover a",
	"MoveToPregnancyLocation": "Mover a localidad de preñadas",
	"MoveToFarrowingLocation": "Mover a localidad de lactantes",
	"MoveToMatingLocation": "Mover a loacalidad de cubiertas",
	"FarrowingListSetting": "Lista de partos",
	"WeaningListSetting": "Lista de destetes",
	"MatingListSetting": "Lista de cubriciones",
	"ScanningListSetting": "Lista 1 de ecógrafo",
	"ScanningListSetting2": "Lista 2 de ecógrafo",
	"LardScanningEventListSetting": "Ecógrafo de grasa dorsal lista1",
	"LardScanningEventListSetting2": "Ecógrafo de grasa dorsal lista 2",
	"listAvailableOn": "Listado disponible en",
	"columnTextShownAs": "Columna texto/se muestra como",
	"daysAfterMating": "Días despues de cubrición",
	"PregnacyDays": "Días de preñés",
	"PregnancyDays": "Días de preñés",
	"MatingBatch.1": "Lote de cubrición",
	"Web": "Web",
	"App": "App",
	"SetupActivityList": "Configuración de listado de actividad",
	"ActivityList": "Listado de actividad",
	"activityLists": "Listados de actividad",
	"expectedFarrowing": "Previsión de partos",
	"expectedFarrowingDashboard": "Pizarra de previsión de partos",
	"completed": "Completados",
	"VaccineListSetting": "Vacunación",
	"VaccineListSetting1": "Vacunación 1",
	"VaccineListSetting2": "Vacunación 2",
	"TasksInFarrowingStableSetting": "Tareas en establo de partos",
	"TasksInFarrowingStableSetting2": "Tareas en establo de tareas 2",
	"boarPerLitterPcs": "% Verracos en camada",
	"treatmentVaccination": "Tratamientos / Vacunación",
	"toBetreated": "A ser tratados",
	"allPigs": "Todos los lechones",
	"boarsPigs": "Lechones verracos",
	"daysAfterEvent": "Días despues del evento",
	"weeksAfterEvent": "Semanas despues del evento",
	"weeksAfterMating": "Semanas despues de la cubrición",
	"weeksAfterFarrowing": "Semanas despues del parto",
	"vaccineByLitterNumber": "Vacunación hasta el número de camada",
	"showLocationOnList": "Mostrar localidad en la lista",
	"calcByVaccinationPregnancyType": "La vacunación es calculada por",
	"showAnimalsOnList": "Mostrar animales en la lista (días de ciclo)",
	"expectedFarrowingLong": "Partos previstos",
	"dateForWeaning": "Fecha de destete",
	"dateForMating": "Fecha de cubrición",
	"Blank": "En blanco",
	"chooseFeedCurve": "Elija la curva de pieso para",
	"chooseReader": "Elija el lector",
	"feedCurve": "Curva de pienso",
	"MoveFromPregToPregSetting": "Mover entre localidad de preñes",
	"MoveMatingToMatingSetting": "Mover entre localidad de cubriciones",
	"maxDryDays": "Maximo días vacíos",
	"newSow": "Cerda nueva",
	"MatingBatchReportSetting": "Lote de cubrición",
	"ActiveAnimalsReportSetting": "Animales activos",
	"ActivePoolYoungFemale": "Animal de cría",
	"reports": "Reportes",
	"report": "Reporte",
	"reportAvailableOn": "Reporte disponible en",
	"AnimalNumberOnly": "Número de animal solamente",
	"AnimalNumberPerLocation": "Animal por localidad",
	"howToPresentAnimals": "Como presentar los animales",
	"ShowMatingBatchInLeftSide": "Mostrar lotes de cubrición en el lado correcto",
	"ShowFarrowingDateInRightSide": "Mostrar fecha de parto en el lado correcto",
	"RepeatAnimalNumberAtBottom": "Repita el número de animal en la parte inferior",
	"ShowComment": "Mostrar comentarios",
	"SowBoardReportSetting": "Cerdas / Verracos configuración de reportes",
	"FailedToFetchNewestLocations": "No se pueden recuperar las ubicaciones más nuevas del equipo de preceso",
	"removeProcessEquipment": "Remover los equipos de preceso?",
	"setPensAutomaticValves": "Colocar corrales en valvulas",
	"setPensAutomaticStations": "Colocar corrales en estaciones",
	"processEquipment": "Equipo de proceso",
	"penNr": "Número de corral",
	"Valves": "Valvulas",
	"StationNumbers": "Número de estaciones",
	"locations": "LOCALIZACIONES",
	"relationProcessEquipment": "CREAR RELACIONES ENTRE UBICACIONES EN EQUIPOS DE PROCESO Y SKIOLD digital",
	"Units.1": "Unidades",
	"locationsSkioldDigital": "LOCALIDADES SKIOLD digital",
	"locationsProcessEquipment": "LOCALIDADES EQUIPO DE PROCESO",
	"createProcessEquipment": "Crear equipos de proceso",
	"chooseProcessEquipment": "Elegir equipos de proceso",
	"chooseUnits": "Elegir unidades",
	"relationLocation1": "Crear relación",
	"relationLocation2": "Equípos de preceso/localidades",
	"bindProcessEquipment": "UNIR LA UNIDAD DEL EQUIPO DE PROCESO A LAS UBICACIONES EN SKIOLD digital",
	"unsavedRegisteredData": "Los datos registrados no estan guardados. Guardar los cambios?",
	"processCode": "Código",
	"AllAnimalsEsfListSetting": "Todos los animales de ESF",
	"AWR250StickReader": "AWR 250 lector de chip",
	"AWR300StickReader": "AWR 300 lector de chip",
	"Reader": "Lector",
	"cycleDaysShownNegative": "Los días de ciclos muestran negativos",
	"eatenToday": "Consumo de pienso hoy",
	"curve": "Curva",
	"Stations": "Estaciones",
	"Yesterday": "Ayer",
	"DateAndTime": "Fecha, hora",
	"FE": "Unidad de Pienso",
	"ShortageEsfListSetting": "Lista de escacez ",
	"LastFourVisits": "ULTIMAS CUATRO VISITAS ",
	"Visit0": "Ultima visita",
	"Visit1": "2.Visita",
	"Visit2": "3.Visita",
	"Visit3": "4.Visita",
	"Rest": "Resto",
	"MJEnergy1": "MJ",
	"KCALEnergy1": "KCAL",
	"FEsoFEsvEnergy1": "Unidad de pienso cerdas",
	"FEsoFEsvEnergy2": "Unidad de pienso cebaderos",
	"FEEnergy1": "Unidad de pienso ",
	"FEEnergy2": "Unidad de Pienso",
	"MJEnergy2": "MJ",
	"KCALEnergy2": "KCAL",
	"connectToUnit": "Conecctar a la unidad de bluetooth",
	"disonnect": "Desconectar",
	"startScan": "Empezar ecógrafo",
	"bleSelect": "Seleccionar",
	"bleCancel": "Cancelar",
	"bleOk": "Ok",
	"bleSave": "Guardar",
	"chooseCurve": "Elegir curva",
	"enterFixed": "Introducir reparados %",
	"Eat": "Comer",
	"animalsOnListWhileDaysRationNotUnfed": "Animales, que hán comido menos de la ración del día, está incluido",
	"TodayFE": "Hoy unidad de pienso",
	"model": "Modelo",
	"ID": "ID",
	"bluetoothIsDisabled": "Bluetooth está deshabilitado",
	"YesterdayFE": "Ayer unidad de pienso",
	"Station": "Estación",
	"restTodayFE": "Resto el día de hoy",
	"eatenTodayFE": "Comsumo hoy",
	"curveFE": "Curva",
	"restYesterdayFE": "Resto ayer",
	"eatenYesterdayFE": "Consumo ayer",
	"searchBtUnits": "Busqueda de dispositivos",
	"enableLocation": "Se requiere ubicación para utilizar el servicio de bajo consumo de energía",
	"useGPS": "Habilitar GPS",
	"noCurveChosen": "Ninguna curva elegida",
	"CHOOSEREPORT": "ELEGIR INFORME",
	"pReport": "Informe productivo",
	"period": "Periodo",
	"newPeriod": "Nuevo periodo",
	"specialPeriod": "Periodo especial",
	"ReMatingPercent": "Recubiertas, uds.",
	"ThereOfGiltsPcs": "• Parte de primerizas, uds.",
	"AlivePerLitterPcs": "Vivos por camada, uds.",
	"DeadPerLitter": "Muertos por camada, uds.",
	"FarrowingPercent": "Porciento de partos",
	"AvgPregnantDaysPerLitter": "Promedio días de preñés por camada",
	"ThereOfFirstLitterPcs": "•Parte de primeras camadas, uds.",
	"FirstLitterPercent": "• Primera camada, uds.",
	"NursingSowPercent": "• Porciento, cerdas lactantes",
	"DeadTillWeaningPercent": "Mortandad hasta el destete, porciento",
	"KeyNumbers": "Informe de producción, Número de índices",
	"clearPeriods": "Borrar periodos",
	"WeanedPerWeaningPcs": "Destetados por destetes, uds.",
	"PReportFor": "Informe de producción por:",
	"invalidDataIfPrivateSlaughter": "No se puede calcular el informe de producción debido a la falta de registro de peso y bajas",
	"YearSowPcs": "Cerdas por año",
	"WeanedPigletsPcs": "Lechones destetados",
	"AvgWeightPerPiglet": "Promedio de peso al destete",
	"PercentDeadSowGilts": "Porciento de mortandad de cerdas del total de cerdas anuales",
	"PeriodFeedDaysSowAndGilt": "Periodo de días de consumo de pienso cerdas/primerizas",
	"PeriodFeedDaysYoungAnimal": "Periodo de días de consumo de pienso cerdas jóvenes de reposición",
	"ProductionDays": "Días productivos",
	"NonProductiveDays": "Días no productivos",
	"CorrectedDays": "Corrección de días",
	"PeriodYearSowPcs": "Periodo de cerda al año ",
	"ProductionScale": "Escala de producción",
	"SowFoodFeYearSow": "Consumo de pienso unidad de pienso/cerda al año",
	"ThereOfYoungAnimalFood": "• Parte de cerdas jóvenes pienso, unidad de pienso/cerda al año",
	"FePerYearSowPerDay": "Unidad de pienso de cerda al año diario ",
	"FePerYearYoungFemalePerDay": "Unidad de pienso de primerizas al año diario",
	"WasteDaysPerDay": "Días no productivos por camada",
	"ThereOfAdjustedDays": "• Parte de corrección de días",
	"LitterPerYearSowPcs": "Camadas por cerda año",
	"WeanedPigletsPerYearSowPcs": "Lechones destetados por cerda año",
	"ProducedLitters": "Camadas producidas",
	"Reproduction": "Reproducción",
	"SowAndGiltPcs": "Cerdas/primerizas, uds.",
	"SowAndGiltEntrance": " •  Entradas primerizas, uds.",
	"SowAndGiltDepartured": " •  Bajas, uds.",
	"SowAndGiltFeedDaysPcs": "Días de pienso cerdas/primerizas, uds.",
	"SowAndGiltPregnantDays": "Días de preñes cerdas/primerizas, uds.",
	"YoungFemalePcs": "Cerdas jovenes, uds.",
	"YoungFemaleEntrance": " • Entradas, uds.",
	"YoungFemaleEntranceToGilt": " • Transferidos, uds.",
	"YoungFemaleDepartured": " • Bajas, uds.",
	"YoungFemaleFeedDaysPcs": "Días de pienso cerdas jóvenes, uds.",
	"BoarPcs": "Verracos, uds.",
	"BoarEntrance": " •  Entradas, uds.",
	"BoarDepartured": " •  Bajas, uds.",
	"PigletPcs": "Lechones, uds.",
	"Departured": "Bajas, uds.",
	"FeedUsage": "Consumo de pienso",
	"kgTotal": "Total kg",
	"feTotal": "Total deundidades de pienso",
	"fePercent": "Unidades pienso, porciento",
	"kgPercent": "KG porciento",
	"sowFoodTotal": "Pienso de cerda total",
	"youngFemaleFoodTotal": "Total de pienso cerdas jóvenes",
	"pigletFoodTotal": "Total de pienso lechones",
	"allInAllFood": "Total de consumo de pienso",
	"SubResult": "Sub resultado",
	"SubResults": "Sub resultados",
	"deadPigletsEvent": "Lechones muertos",
	"deadPigsEvent": "Lechon muerto",
	"sowCount": "Recuento de cerdas",
	"totalDeadPiglets": "Mortandad de lechones",
	"avgDeadPerSow": "Promedio de mortandad de cerda",
	"deadPigs": "Lechones muertos",
	"deadPigletsGreaterThan1": "El número de identificación no está disponible cuando los lechones muertos no es mayor que 1",
	"growthPigGreaterThan1": "El número de identificación no está disponible cuando el recuento es mayor que 1",
	"printFeedingReport": "Imprimir ",
	"feedingReport": "Informe de pienso",
	"maxBatchesIs27": "El maximo de lotes de cubrición a mostrar es 27",
	"resendEsfList": "Reenviar ESF",
	"resend": "Reenviar",
	"SowEvent": "Evento",
	"MoveEvent": "Mover",
	"Entrance.1": "Entrada",
	"RequireSelectionOfPercentagesRed": "Intervalo requerido para marcar el animal en rojo",
	"RequireSelectionOfPercentagesGreen": "Intervalo requerido para marcar el animal en verde, debería de ser mayor al intervalo del rojo",
	"FeedingESFReportSetting": "Configuración de informes de pienso",
	"feedingESFSetupGreenNameText": "El animal es marcado en verde cuando: ",
	"feedingESFSetupRedNameText": "El animal es marcado en rojo cuando: ",
	"dayRationNotEaten": "% ración diaria no consumido",
	"dayRationEaten": "% ración diaria consumido",
	"salePoolYoungFemale": "Retorno de animal de cría",
	"poolYoungFemale": "Animal de cría",
	"Climatepigs.1": "Destetados",
	"IdNumberDisableIfGreaterThan1": "No es posible indicar el número de identificación cuando el recuento es mayor que 1",
	"sold.1": "Vendidos",
	"PigletWeanedEvent": "Destetes",
	"PigletDepartureSoldEvent": "Vendidos",
	"PigletMovedEvent": "Destetados",
	"WeanedOnStable": "Destetados en el establo",
	"SellWeanedPigs": "Venta de lechones destetados",
	"DistributeWeanedPigs": "Distribución de lechones destetados",
	"BalanceWeanedPigs": "Balance de lechones destetados",
	"balanceWeanedPigs.1": "Balance de lechones destetados",
	"ToBeDistributed": "A ser distribuidos",
	"ToDistributed": "Distribuidos",
	"PigletMovedEventDash": "Destetados",
	"numOfPigs": "Número de lechones",
	"entryWeight": "Entrada",
	"weightToday": "Hoy",
	"days.2": "Número de días",
	"dailyGain": "Ganancia diaria",
	"feedPerPigToday": "Hoy",
	"feedPerPigCurve": "Curva",
	"feedPerPigAdjust": "Ajuste",
	"feedPerPigTotal": "Pienso",
	"feedPerPigWeight": "Peso",
	"totalDayX": "Días totales {{area}}",
	"deadLastSevenDays": "Muertos/bajas",
	"treatedLastSevenDays": "Tratados",
	"lastSevenDays": "Ùltimos 7 días",
	"expectedDeliveryDate": "Entrega prevista de {{area}} kg. en semana",
	"expectedDeliveryAdjust": "Ajustes",
	"feedPerPig": "Pienso por lechón",
	"expectedDelivery": "Entrega prevista",
	"lastBalanced": "Ùltimo balance",
	"deadLast7DaysTitle": "Elaboración Muertos/bajas",
	"treatedLast7DaysTitle": "Tratados los últimos 7 días",
	"enteredDashboard": "Entradas",
	"departedDashboard": "Bajas",
	"dateForBalance": "Fecha de balance",
	"gpeDashboardMoved": "Movidos",
	"identifierToFinisher": "Á cebaderos",
	"identifierToWeaner": "Á destetados",
	"identifierToSow": "Á cerdas",
	"identifierToYoungFemale": "Á reposición",
	"identifierFromFinisher": "De cebaderos",
	"identifierFromWeaner": "De destetados",
	"identifierFromSow": "De cerdas",
	"enteredSince": "Ingresos desde",
	"departedSince": "Bajas desde",
	"GeneralSettings": "Configuraciones generales",
	"General": "General",
	"Generalt": "General",
	"RegisterAliveFemales": "Registro de hembras vivas",
	"RegisterAliveMales": "Registro de machos vivos",
	"WeanedPigsToLocation": "Lechón destetado a localidad",
	"SetupEvent": "Evento",
	"SetupRegistration": "Registro",
	"TreatmentRetentionTime": "Tratamiento - peiodo de retención",
	"HandleRetentionTime": "Manejo de periodo de retención en movimiento",
	"IsLettersUsedForAnimal": "Se utilizan letras para el número de animal?",
	"IsLettersUsedForBoar": "Se utilizan letras para el número de verraco?",
	"PigDeliveryToPrivateSlaughterhouse": "Entrega de cerdos a mataderos privados",
	"SowRegistrations": "Registro de cerdas",
	"Communication": "Comunincación",
	"SendDataToDistriwin": "Compartir normativa de peso con Distriwin?",
	"EnergyIndicatedBy": "Energia indicada por",
	"FetchDistriwinFeedingData": "Buscar datos de pienso de Distriwin?",
	"MinAgeInDaysFirstMating": "Edad minima por animal a la 1. cubrición",
	"MaxDaysAfterBoarDepartureSemen": "Dias permitidos de utilización de verraco despues de baja",
	"ShowFamilyTree": "Mostrar icono de árbol genealógico",
	"ShowRaceForLitter": "Mostrar la raza de la camada",
	"RegisterLitterWeight": "Registro de peso de camada lechones nacidos",
	"RegisterMumifiedPigs": "¿Registra los lechones momificados?",
	"MapYoungAnimalsToLocation": "Ubicar animales jóvenes en una localidad",
	"ShouldShowDadMomData": "Mostrar datos de padre/madre",
	"ShouldShowInitialsForRegistrations": "Mostrar iniciales para registros",
	"RegisterEarNotch": "Registro de crotales",
	"CalculateEarNotch": "Calculo de números de crotal",
	"WeekStartyDay": "Día de comienzo de semana",
	"ShowRaceLine": "La raza debería de ser especificada",
	"UsageShownAs": "Consumo de pienso utilizado debería mostrarse como",
	"ShouldUsageBeAttachedToRecipe": "Consumo de pienso debe ser adjunto a la receta",
	"SendEntranceDepartureToDistriwin": "Enivar entrada/bajas a Distriwin",
	"AssignNumbersToBornPiglets": "Asignar número a los lechones nacidos",
	"SendCycleDaysAsNegative": "Enviar días de ciclos como valores negativos",
	"RegisterIdForTurnoverPoolYoungAnimals": "Habilitar ID en animales jóvenes del grupo de crías",
	"NotifyCapacity": "Notificar cuando se excede la capacidad",
	"RegisterIdForGpeDeparture": "Habilitar registro de número de identificación a la salida",
	"Regulations": "Regulaciones",
	"WeightRegulation": "Regulación de peso",
	"EditRegulation": "Editar regulación",
	"Updated": "Actualizado",
	"viewControllers": "Visualizar controladores",
	"ControllerNumber": "Número de controladores",
	"createRelation": "Crear relación",
	"VALIDATION_EqualDistriwinAndSkioldEnergy": "Energia en Distriwin y Skiold digital debería de ser iguales",
	"FEkg": "Unidad de pienso/kg",
	"MJkg": "MJ/kg",
	"KCALkg": "Kcal/kg",
	"FEsoFEsvkg": "Unidad de pienso cerda/kg",
	"FEsvkg": "Unidad de pienso cebo/kg",
	"EnergyKg": "{{area}}/kg",
	"FETotal.1": "Total Unidad de pienso",
	"KCALTotal": "Total Kcal",
	"MJTotal": "Total MJ",
	"FEsoFEsvTotal": "Total Unidad de pienso",
	"FEPct": "Porciento de unidad de pienso",
	"KCALPct": "Kcal prociento",
	"MJPct": "MJ porciento",
	"FEsoFEsvPct": "Unidad de pienso porciento",
	"Energy": "Energia ",
	"CurveRation": "Curva de ración",
	"UserName": "Usuario",
	"Connected": "Connectado",
	"NoConnectionFound": "Ninguna connección detectada",
	"UpdatePassword": "Actualizar la contraseña",
	"UpdateLogin": "Actualizar el usuario",
	"NucleusManagement": "Manejo de cría",
	"Provider": "Proveedor",
	"Connections": "Canecciones",
	"BreedingNumber": "Número de cría",
	"NumberBatch": "Número de lote",
	"Fetch": "Buscar",
	"FetchData": "Cargar datos",
	"Send": "Enviar",
	"SendData": "Enviar/Exportar datos a un documento Excel",
	"DataSendFrequency": "Cada cuanto se envia datos",
	"DataSendTime": "Los datos se enviaron el",
	"Current": "Actual",
	"FinalNumber": "Número final",
	"CreateBreedingNr": "Crear número de cría",
	"CreateNumberBatch": "Crear numero de lote",
	"FirstMatingNr": "1er. número de cubrición",
	"SecondMatingNr": "2do. número de cubrición",
	"Letter": "Carta",
	"SetupBreedingNumber": "Configurar número de cría",
	"NumberBatches": "Número de lotes",
	"DataFetching": "Busca de datos",
	"Daily": "Diario",
	"Weekly": "Semanal",
	"Monthly": "Mensual",
	"Manual": "Manual",
	"Weekday": "Día de semana",
	"IndexAnimals": "Index de animales en cría",
	"KsBoarHerds": "Verracos-AI de los siguientes criaderos",
	"InvalidHerdsAlert": "Las granjas para el manejo no son válidos, deberían de contener de: xxxx,xxxx formato, hasta 10 granjas",
	"NotAllInfoAlert": "Por favor complete todos los campos",
	"NoNucleusManagementLicence": "Esta página no cuenta con una licencia de manejo de núcleo o es CEFN (AU)",
	"FileType": "Tipo de archivo",
	"NoEmailProvided": "Por favor asignar por lo menos una dirección de correo electrónico",
	"InvalidEmailProvided": "El email proveido no es válido",
	"SendManually": "Enviar datos",
	"CreateConnectionFirstError": "Primero inicie sesión en el proveedor de Manejo de núcleos en al pestaña \" Connecciones\"",
	"SelfManaged": "CEFN (AU)",
	"ValidateBreedingNumber": "Número de cría inválido",
	"ValidateRace": "Elija una raza",
	"ValidateGender": "Elija un género",
	"ValidateNumberBatc1Start": "Ingrese 1er. número de cubrición",
	"ValidateNumberBatc2Start": "Ingrese comienzo de 2do. número de cubrición",
	"ValidateNumberBatc1End": "Ingrese 1er. número de cubrición",
	"ValidateNumberBatc2End": "Ingrese el final 2do. número de cubrición",
	"ValidateCurrentNumberBatch": "Ingrese 2do. corriente número de cubrición",
	"ValidatePriority": "Establecer prioridad para cerdas con la misma raza",
	"KS": "AI",
	"Nat": "Natural",
	"EntranceAIBoar": "AI",
	"EntranceBoar": "Natural",
	"IsAIBoar": "Es verraco AI",
	"ValidateNoBreedRace": "Ninguna raza de cría",
	"ValidateNoMaleOrFemale": "Tipo de lechones nacidos vivos hembras o machos",
	"ValidateNoSowFound": "Cerda no encontrada",
	"ValidateNoBreedingNumber": "La cerda no cuenta con un número de cría",
	"ValidateExistingIdNumbers": "Los siguientes números de ID yá existen {{area}}",
	"CredentialsAreInvalid": "Las credeciales son inválidas",
	"Archive": "Archivo",
	"FileArchive": "Archivo guradado",
	"ArchiveSendData": "Datos de archivo enviados",
	"FileName": "Nombre de archivo",
	"SentDate": "Fecha enviada",
	"Open": "Abierto",
	"RaceLine": "Linea de raza",
	"FamilyTree": "Arbol geneológico",
	Family: {
		"TheAnimal": "El animal",
		"Mother": "Madre",
		"Father": "Padre",
		"MotherGrandmother": "Abuela (madre)",
		"MotherGrandfather": "Abuelo (madre)",
		"FatherGrandmother": "Abuela (padre)",
		"FatherGrandfather": "Abuelo (padre)",
	},

	"DadBreedRace": "Raza padre",
	"MomBreedRace": "Raza madre",
	"SKIOLD": "SKIOLD digital",
	"DANBRED": "Danbred",
	"DanBred.1": "Danbred",
	"DANBRED_NUMBER": "Número de Danbred",
	"DANISH_GENETICS": "Danish Genetics",
	"DanishGenetics": "Danish Genetics",
	"DANISH_GENETICS_ID_NUMMER": "Cartas de DanishGenetics",
	"AddNewBreed": "Agregar nueva cría",
	"InProgress": "En proceso",
	"DailyFeedUsageInt": "Consumo de pienso {{area}}",
	"Day": "Día",
	"FUCurve": "Unidad de pienso curva",
	"FUPigDay": "Unidad de pienso /día ",
	"finisherDailyFeedUsage": "Consumo de pienso diario",
	"weanerDailyFeedUsage": "Consumo de pienso diario",
	"NoAdjustmentOnDate": "Ningún ajuste se há hecho el día de hoy",
	"Old": "Viejo",
	"New": "Nuevo",
	"Adjustments": "Ajustes",
	"DailyFeedUsageWeaner": "Resumen de consumo de pienso APP",
	"DailyFeedUsageProcessFinisher": "Resumen de consumo de pienso APP",
	"EnergyPrPig": "Energia por cerdo",
	"CalculateTreatment": "Calcular tratamiento por",
	"SowWeightAtTreatment": "Peso de cerda al tratamiento",
	"GiltWeightAtTreatment": "Peso de cerda de reposición al tratamiento",
	"MatingPregnant": "Cubrición - Preñes",
	"FarrowingNursing": "Parto - Lactación",
	"WeaningDry": "Destete - TDry",
	"SowTaskSettings1": "Tareas de cerda 1",
	"SowTaskSettings2": "Tareas de cerda 2",
	"SpecifyMLTreatment": "Especificar ml. por el tratamiento especifico",
	"ChangeFeed": "Cambiar pienso",
	"FetchSiteInformation": "Cargar información del sitio"
};
