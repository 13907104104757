import { Sorting } from '@devexpress/dx-react-grid';
import React from 'react';
import { IPoolYoungFemale } from 'shared/api/api';
import DeleteIcon from 'shared/assets/src-assets/png/delete_icon.png';
import PenIcon from 'shared/assets/src-assets/png/pen_icon.png';
import { getDateString } from 'shared/helpers/date-helpers';
import { exactNumberFilterMethodGrid, rangeFilterMethodGrid } from 'shared/helpers/general-helpers';
import { getBuildingNameByPenId, getPenNameByPenId, getSectionNameByPenId } from 'shared/helpers/location-helper';
import { NaturalSortDates } from 'shared/helpers/natural-sort';
import {
	PoolYoungFemaleTableProps,
	PoolYoungFemaleTableMapStateToProps,
} from 'shared/helpers/pool-young-female-helper/pool-young-female-event-list-helper';
import { getReasonNameById } from 'shared/helpers/reason-helper/reason-helper';
import { localized } from 'shared/state/i18n/i18n';
import 'web/view/components/event-lists/pregnancy-event-lists/sow-pregnancy-event-list-styles.scss';
import 'web/view/components/skiold-components/skiold-table/skiold-double-header-table.scss';
import SkioldTableGrid, {
	SkioldTableGrid as SkioldTableRef,
} from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { SkioldTouchableOpacity } from 'web/view/components/skiold-components/skiold-touchable-opacity';
import { SowListConstants } from 'web/view/components/stem-animal/animal-lists/table-constants';
import { SkioldImage } from 'web/view/components/utils/svg/skiold-image';
import { connect } from 'react-redux';
import { ViewWeb } from '../../utils/web-view';
import { printIdNumber } from 'shared/helpers/stemanimal-helper/stemanimal-helper';

export class PoolYoungFemaleTable extends React.PureComponent<PoolYoungFemaleTableProps> {
	public SkioldTableRef: SkioldTableRef | undefined;

	private defaultSorting = [{ columnName: 'date', direction: 'asc' }] as Sorting[];

	public generateColumnsExtensions = () => {
		let columnExt = [
			{
				columnName: 'edit',
				width: SowListConstants.iconWidth,
				filteringEnabled: false,
				sortingEnabled: false,
				resizingEnabled: false,
			},
			{
				columnName: 'date',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'building',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'section',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'pen',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'total',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'eventType',
				width: SowListConstants.adjustmentType,
			},
			{
				columnName: 'reason',
				width: SowListConstants.diagnoseWidth,
			},
			{
				columnName: 'idNumber',
				width: SowListConstants.diagnoseWidth,
			},
		];
		if (this.props.generalSettings.stemAnimalShowInitials) {
			columnExt.push({
				columnName: 'initial',
				width: SowListConstants.animalNrWidth,
			});
		}
		columnExt.push({
			columnName: 'remove',
			width: SowListConstants.removeIconWidth,
			filteringEnabled: false,
			sortingEnabled: false,
			resizingEnabled: false,
		});
		return columnExt;
	};

	public render() {
		return (
			<SkioldTableGrid
				tableKey={'poolYoungFemaleTable'}
				columns={this.generateColumns()}
				ColumnExtensions={this.generateColumnsExtensions()}
				data={this.props.data ? this.props.data : []}
				ref={this.props.setRef}
				sortHeaderId={this.defaultSorting}
				onFiltersChanged={this.props.onFiltersChanged}
				ignoreSetCount={true}
			/>
		);
	}

	private getDateString = (event: IPoolYoungFemale) => {
		return getDateString(event.registrationDate);
	};

	private getEventType = (event: IPoolYoungFemale) => {
		if (event.eventType) {
			return localized(event.eventType);
		}
		return '';
	};

	private getReason = (event: IPoolYoungFemale) => {
		return getReasonNameById(event.departureReasonId);
	};

	private getEditIcon = (event: IPoolYoungFemale) => {
		return (
			<>
				{event.eventType === 'FromWeaner' || event.eventType === 'FromFinisher' ? (
					<ViewWeb />
				) : (
					<SkioldTouchableOpacity onPress={this.props.openEditModal} itemFromParent={event}>
						<SkioldImage
							width={SowListConstants.iconSVGWidth}
							height={SowListConstants.iconSVGWidth}
							imageData={PenIcon}
						/>
					</SkioldTouchableOpacity>
				)}
			</>
		);
	};

	private getRemoveIcon = (event: IPoolYoungFemale) => {
		return (
			<>
				{event.eventType === 'FromWeaner' || event.eventType === 'FromFinisher' ? (
					<ViewWeb />
				) : (
					<SkioldTouchableOpacity onPress={this.props.removeItem} itemFromParent={event}>
						<SkioldImage
							width={SowListConstants.iconSVGWidth}
							height={SowListConstants.iconSVGWidth}
							imageData={DeleteIcon}
						/>
					</SkioldTouchableOpacity>
				)}
			</>
		);
	};
	private readonly getInitCell = (d: IPoolYoungFemale) => {
		const user = this.props.userProfiles.find(u => u.id === d.createdBy);
		return user?.initials ?? '';
	};

	private getBuildingName = (event: IPoolYoungFemale) => {
		return getBuildingNameByPenId(event.penId, this.props.hashmapLocations);
	};

	private getSectionName = (event: IPoolYoungFemale) => {
		return getSectionNameByPenId(event.penId, this.props.hashmapLocations);
	};

	private getPenName = (event: IPoolYoungFemale) => {
		return getPenNameByPenId(event.penId, this.props.hashmapLocations);
	};

	private getIdNumber = (event: IPoolYoungFemale) => {
		return printIdNumber(event.idNumber);
	};
	private generateColumns = () => {
		let columns = [
			{
				name: 'edit',
				title: ' ',
				sortable: false,
				filterable: false,
				isFixedLeft: true,
				getCellValue: this.getEditIcon,
			},
			{
				name: 'date',
				title: localized('Date'),
				getCellValue: this.getDateString,
				sortFunction: NaturalSortDates,
			},
			{
				name: 'building',
				title: localized('building'),
				getCellValue: this.getBuildingName,
			},
			{
				name: 'section',
				title: localized('section'),
				getCellValue: this.getSectionName,
				filterFunction: exactNumberFilterMethodGrid,
			},
			{
				name: 'pen',
				title: localized('pen'),
				getCellValue: this.getPenName,
			},
			{
				name: 'total',
				title: localized('Count'),
				filterFunction: rangeFilterMethodGrid,
			},

			{
				name: 'eventType',
				title: localized('Type'),
				getCellValue: this.getEventType,
			},
			{
				name: 'reason',
				title: localized('reason'),
				getCellValue: this.getReason,
			},
			{
				name: 'idNumber',
				title: localized('idNumber'),
				getCellValue: this.getIdNumber,
			},
		];
		if (this.props.generalSettings.stemAnimalShowInitials) {
			columns.push({
				name: 'initial',
				title: localized('InitialsShort'),
				getCellValue: this.getInitCell,
			});
		}
		columns.push({
			name: 'remove',
			title: ' ',
			sortable: false,
			filterable: false,
			isFixedRight: true,
			getCellValue: this.getRemoveIcon,
		} as any);
		return columns;
	};
}
export default connect(PoolYoungFemaleTableMapStateToProps, null, null, { forwardRef: true })(
	PoolYoungFemaleTable,
) as any;
