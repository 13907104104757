import React, { FC, useMemo } from 'react';
import { AnalysisDepartureGpeTableData, AnalysisDepartureGpeTableItem } from 'shared/api/api';
import { localized } from 'shared/state/i18n/i18n';
import SkioldTableGrid from '../../skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { ViewWeb } from '../../utils/web-view';

export interface PropsFromParent {
	tableData: AnalysisDepartureGpeTableData | undefined;
	asPercentage: boolean;
	rowStylingIndicator?: { fromTop: number; fromBottom: number };
}

export const AnalysisDepartureTypeGpeTable: FC<PropsFromParent> = React.memo(
	({ tableData, asPercentage, rowStylingIndicator }) => {
		const data = useMemo(() => {
			if (tableData && tableData.departureReason) {
				return Object.keys(tableData.departureReason!).map(e => tableData.departureReason![e]);
			}
			return [];
		}, [tableData]);

		const columns = useMemo(() => {
			let columns: any[] = [
				{
					name: 'animalCount',
					title: localized('AnalysisDeparture.NumberOfAnimals'),
					getCellValue: (item: AnalysisDepartureGpeTableItem) =>
						item.animalCount !== 0 ? item.animalCount : '',
				},
				{
					name: 'name',
					title: localized('SOWDEPARTUREREASONS'),
				},
			];

			if (data && data.length > 0) {
				Object.keys(data[0].weekValue!).forEach(e => {
					columns.push({
						name: e,
						title: e,
						getCellValue: (item: AnalysisDepartureGpeTableItem) =>
							item.weekValue![e] !== 0 ? item.weekValue![e] : '',
					});
				});
			}

			columns.push({
				name: 'totalPct',
				title: localized('TotalPct'),
				getCellValue: (item: AnalysisDepartureGpeTableItem) => (item.totalPct !== 0 ? item.totalPct : ''),
			});
			return columns;
		}, [data]);

		const columnsExt = useMemo(() => {
			let columnsExt: any[] = [
				{
					columnName: 'animalCount',
					width: 185,
				},
				{
					columnName: 'name',
					width: 250,
				},
			];

			if (data && data.length > 0) {
				Object.keys(data[0].weekValue!).forEach(e => {
					columnsExt.push({ columnName: e, width: 100 });
				});
			}

			columnsExt.push({
				columnName: 'totalPct',
				width: 100,
			});

			return columnsExt;
		}, [data]);

		const groupedColumns = useMemo(() => {
			if (data && data.length > 0) {
				let groupedColumns: any[] = [
					{
						title: asPercentage
							? localized('AnalysisDeparture.PctAnimalWeek')
							: localized('AnalysisDeparture.CountAnimalWeek'),
						children: Object.keys(data[0].weekValue!).map(e => {
							return { columnName: e };
						}),
					},
				];
				return groupedColumns;
			}
			return [
				{
					title: asPercentage
						? localized('AnalysisDeparture.PctAnimalWeek')
						: localized('AnalysisDeparture.CountAnimalWeek'),
					children: [],
				},
			];
		}, [data, asPercentage]);

		return (
			<ViewWeb className="analysis-overview-table">
				<SkioldTableGrid
					columns={columns}
					ColumnExtensions={columnsExt}
					groupedColumns={groupedColumns}
					data={data}
					sortable={false}
					ignoreSetCount={true}
					filterable={false}
					rowStyling={'td-font-weight-bold'}
					tableKey='analysisDepartureGpeTable1'
					rowStylingIndicator={rowStylingIndicator}
				/>
			</ViewWeb>
		);
	},
);
