import actionCreatorFactory from 'typescript-fsa';
import * as types from './types';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import { IUserProfile, IDevice, ISiteAuthorizationUpdate, SyncDataUserProfile, IUserProfile2 } from 'shared/api/api';

const actionCreator = actionCreatorFactory(GenActionFactoryName('profile'));

export const activateProfileAsync = actionCreator.async<string, IUserProfile, { code: number; message: string }>(
	types.ACTIVATE,
);

export const activateSiteIdAdmin = actionCreator.async<string, IUserProfile, { code: number; message: string }>(
	types.ACTIVATE_SITE_ADMIN,
);

export const getProfileAsync = actionCreator.async<{}, IUserProfile, { code: number; message: string }>(
	types.GET_PROFILE,
);
export const updateProfileAsync = actionCreator.async<IUserProfile, IUserProfile, { code: number; message: string }>(
	types.UPDATE_PROFILE,
);

export const upsertDeviceAsync = actionCreator.async<IDevice, void, { code: number; message: string }>('ADD_DEVICE');

export const removeDeviceAsync = actionCreator.async<string, void, { code: number; message: string }>('REMOVE_DEVICE');

export const siteChange = actionCreator.async<void, void>(types.SITE_CHANGE);

export const setIsResettingState = actionCreator<boolean>(types.SET_IS_RESETTING_STATE);

export const moduleChanged = actionCreator.async<ISiteAuthorizationUpdate, void>(types.MODULE_CHANGED);
export const getSyncData = actionCreator.async<{ siteId: string; lastSyncDate: Date }, SyncDataUserProfile>(
	types.SYNC_DATA_USERPROFILE,
);
