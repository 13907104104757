
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import { actionCreatorFactory } from 'typescript-fsa';
import * as types from './types';
import { IDashboardSettings, SyncDashboardSettings } from 'shared/api/api';

const actionCreator = actionCreatorFactory(GenActionFactoryName('dashboardSettings'));

export const saveSettings = actionCreator.async<
IDashboardSettings,
    string,
    { code: number; message: string; prevEntity: IDashboardSettings | undefined }
>(types.SAVE_DASHBOARDSETTINGS);
export const getSyncData = actionCreator.async<{ siteId: string; lastSyncDate: Date }, SyncDashboardSettings>(
    types.SYNC_DATA_DASHBOARDSETTINGS
);
