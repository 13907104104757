import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
	handleGrowthpigsDeparturedFromSection,
	handleGrowthpigsWeightFromSection,
} from 'shared/helpers/growth-pigs-helper/growth-pig-event-function-helper';
import { GrowthPigDepartureFromSectionRow } from 'shared/helpers/growth-pigs-helper/growth-pig-event-helper';
import { GrowthPigMoveEventBundleListRow } from 'shared/helpers/growth-pigs-helper/growth-pig-event-list-helper';
import { initEditGpeFromMoveData } from 'shared/helpers/growth-pigs-helper/growth-pig-move-helpers';
import { memoizeHashmapLocation } from 'shared/helpers/memoize-getters/memoize-getters';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldDatePicker } from 'web/view/components/skiold-components/skiold-date-picker/skiold-date-picker';
import { SkioldModal } from 'web/view/components/skiold-components/skiold-modal/skiold-modal';
import { WhiteText } from 'web/view/components/Text/white-text';
import { Heading } from 'web/view/components/utils/heading';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { GrowthPigDepartureFromSectionTable } from '../../growth-pig-events/growth-pig-departure-move-table';
import { GpeMoveToEditModal } from './growth-pig-move-to-edit-modal';

interface PropsFromParent {
	closeModal: (saved?: boolean) => void;
	navigation?: any;
	bundle: GrowthPigMoveEventBundleListRow;
}

export const GpeMoveFromEditModal: FC<PropsFromParent> = React.memo(({ bundle, closeModal }) => {
	// Get data
	const growthPigEvents = useSelector((state: WebAppState) => state.growthPigEvents.growthPigEvents);
	const growthPigsEventsDto = useSelector((state: WebAppState) => state.growthPigEvents.entities);
	const hashmapLocations = useSelector((state: WebAppState) => memoizeHashmapLocation(state.locations.buildings,
		state.locations.sections,
		state.locations.pens));

	// Set State
	const [data, setData] = useState<GrowthPigDepartureFromSectionRow[]>([]);
	const [isDistributeModalOpen, setIsDistributeModalOpen] = useState<boolean>(false);
	const [registrationDate, setRegistrationDate] = useState<Date | undefined>(new Date());

	const openDistributeModal = () => {
		setIsDistributeModalOpen(true);
	};

	const closeDistributeModal = (isSave?: boolean) => {
		setIsDistributeModalOpen(false);
		if (isSave) {
			close();
		}
	};

	// ComponentDidMount
	useEffect(() => {
		const initData = () => {
			const initData = initEditGpeFromMoveData(growthPigEvents, growthPigsEventsDto, bundle, hashmapLocations);
			setData(initData);
			if (initData && initData.length) {
				setRegistrationDate(initData[0].date);
			}
		};
		initData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const close = () => {
		if (closeModal) {
			closeModal();
		}
	};

	const onDateChanged = (date?: Date) => {
		setRegistrationDate(date);
	};

	const onMoveNumberChanged = (number?: number, itemFromParent?: GrowthPigDepartureFromSectionRow) => {
		const datasToModify = [...data];
		handleGrowthpigsDeparturedFromSection(datasToModify, itemFromParent, number);

		setData(datasToModify);
	};

	const onDepartureWeightChanged = (number?: number, itemFromParent?: GrowthPigDepartureFromSectionRow) => {
		const datasToModify = [...data];
		handleGrowthpigsWeightFromSection(datasToModify, itemFromParent, number);
		setData(datasToModify);
	};

	const renderButtons = () => {
		let buttons = (
			<ViewWeb className="button-view-style">
				<SkioldButton theme="grey" title={localized('Close')} onPress={close} />
				<SkioldButton title={localized('Distribute')} onPress={openDistributeModal} />
			</ViewWeb>
		);

		return buttons;
	};

	return (
		<ViewWeb className="growth-pig-distribute">
			<Heading text={localized('MovePigsOnLocation')}></Heading>
			<ViewWeb className="distribute-container">
				<WhiteText className="bold-white-text">
					{localized('FromLocation')}: {bundle.fromLocation}
				</WhiteText>
				<ViewWeb className="flexDirectionRow">
					<WhiteText className="penTextStyle">{localized('Date')}:</WhiteText>
					<SkioldDatePicker
						selectedDate={registrationDate}
						onDateChanged={onDateChanged}
						theme={'light'}
						containerClassName="distribute-datepicker-container"
					/>
				</ViewWeb>
				<GrowthPigDepartureFromSectionTable
					usesPen={true}
					departedFromSectionText={localized('moved')}
					onDepartureFromSectionNumberChanged={onMoveNumberChanged}
					onDepartureFromSectionAvgWeightChanged={onDepartureWeightChanged}
					data={data}
				/>
				<SkioldModal
					shouldCloseOnOverlayClick={true}
					padding="0"
					isOpen={isDistributeModalOpen}
					close={closeDistributeModal}
					justify-content="flex-end"
					max-width="calc(100% - 220px)"
				>
					<GpeMoveToEditModal
						registrationDate={registrationDate}
						fromSectionData={data}
						closeModal={closeDistributeModal}
						bundle={bundle}
					/>
				</SkioldModal>
			</ViewWeb>
			{renderButtons()}
		</ViewWeb>
	);
});
