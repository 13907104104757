import { Syncable } from 'shared/state/models/syncable';
import { ITreatmentPlan } from 'shared/api/api';

export const SAVE_TREATMENT_PLAN = 'SAVE_TREATMENT_PLAN';
export const SAVE_MANY_TREATMENT_PLAN = 'SAVE_MANY_TREATMENT_PLAN';
export const GET_TREATMENT_PLANS_WITH_COMPLETED = 'GET_TREATMENT_PLANS_WITH_COMPLETED';
export const SYNC_DATA_TREATMENT_PLAN = 'SYNC_DATA_TREATMENT_PLAN';
export const REMOVE_TREATMENT_PLANS_BY_ANIMAL = 'REMOVE_TREATMENT_PLANS_BY_ANIMAL';
export const GET_TREATMENT_PLANS_BY_STEMANIMAL_ID = 'GET_TREATMENT_PLANS_BY_STEMANIMAL_ID';

export interface TreatmentPlanState extends Syncable<ITreatmentPlan> {
	treatmentPlansForLog: ITreatmentPlan[];
	fetchingTreatmentPlansForLog: boolean;
}
