
import { FeedingStatusUpdate, SyncEsfFeedCurve, SyncEsfFeedingStatus, EsfStatusAcknowledge } from 'shared/api/api';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import actionCreatorFactory from 'typescript-fsa';
import * as types from './types';

const actionCreator = actionCreatorFactory(GenActionFactoryName('esfStatus'));
export const getSyncEsfFeedingStatusData = actionCreator.async<any, SyncEsfFeedingStatus>(types.GET_EsfFeedingStatusData);
export const saveEsfFeedingStatus = actionCreator.async<FeedingStatusUpdate, void>(types.SAVE_EsfFeedingStatus);
export const getSyncEsfFeedCurveData = actionCreator.async<{siteId: string;  activeSiteId?: string}, SyncEsfFeedCurve>(types.GET_EsfFeedingCurveData);
export const removeEsfStatus = actionCreator.async<string, string>(types.REMOVE_ESF_STATUS);
export const acknowledgeFeedingStatus =  actionCreator.async<EsfStatusAcknowledge, void>(types.ACKNOWLEDGE_FEEDING_STATUS);