import React from 'react';
import { Option } from 'react-dropdown';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
	AfterEventTask,
	AnimalKind,
	IAfterEventTask,
	ITreatmentDefinition,
	IVaccineListSetting,
	IWorkListSetting,
	VaccineListSetting,
	VaccinePregnancyType,
	VaccineSubTask,
	WorkListType,
} from 'shared/api/api';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { SaveWorkListSetting } from 'shared/state/ducks/work-list-settings/operations';
import { localized, localizedDynamic } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { showAlert } from '../../skiold-alert/skiold-alert';
import { SkioldButton } from '../../skiold-components/skiold-button/skiold-button';
import { SkioldCheckbox } from '../../skiold-components/skiold-checkbox/skiold-checkbox';
import { DefaultTypeOption } from '../../skiold-components/skiold-dropdown/skiold-dropdown';
import { SkioldFormDropdown } from '../../skiold-components/skiold-dropdown/skiold-form-dropdown';
import { SkioldFormsWrapper } from '../../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from '../../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { SkioldFormInput } from '../../skiold-components/skiold-input/skiold-form-input';
import { SkioldFormIntegerInput } from '../../skiold-components/skiold-integer-input/skiold-form-integer-input';
import TreatmentDefinitionPicker from '../../treatments/treatment-definition/treatment-definition-picker';
import './vaccine-work-list-setup.scss';

const mapStateToProps = (state: WebAppState) => {
	return {
		vaccineListSettings: state.workListSettings.entities,
		siteId: state.profile.active!.siteId,
		treatmentDefinitions: state.treatmentDefinitions.entities,
		diagnoses: state.diagnose.entities,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
	saveWorkListSetting: (vaccineListSetting: IVaccineListSetting) => SaveWorkListSetting(vaccineListSetting)(dispatch),
});

interface PropsFromParent {
	workListType: WorkListType;
}

interface State {
	vaccineListSetting: IVaccineListSetting;
	selectedVaccinePregnancyType: Option;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

class VaccineListSetup extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		const doesExist = props.vaccineListSettings.find(
			setting => setting.type === props.workListType,
		) as IVaccineListSetting;
		const setting = this.init(doesExist);
		const option = doesExist ? this.getVaccinePregnancyTypeOptionBySetting(doesExist) : DefaultTypeOption;
		this.state = {
			vaccineListSetting: setting,
			selectedVaccinePregnancyType: option,
		};
	}

	public toggleShowOnWeb = () => {
		this.vaccineListSettingChanged('showOnWeb', !this.state.vaccineListSetting.showOnWeb);
	};

	public toggleShowOnApp = () => {
		this.vaccineListSettingChanged('showOnApp', !this.state.vaccineListSetting.showOnApp);
	};

	public includeLitterToChanged = (value: number | undefined) => {
		this.vaccineListSettingChanged('includeLitterTo', value);
	};

	public showOnLocationChanged = () => {
		this.vaccineListSettingChanged('showLocationOnList', !this.state.vaccineListSetting.showLocationOnList);
	};

	public vaccineListSettingChanged = (key: keyof IVaccineListSetting, value: any) => {
		this.setState(prevState => ({
			vaccineListSetting: {
				...prevState.vaccineListSetting,
				[key]: value,
			},
		}));
	};

	public render() {
		return (
			<ViewWeb className="vaccine-work-list-setup">
				<TextWeb className="text-style">{localized('Setup')}</TextWeb>
				<SkioldFormsWrapper formRows={this.getFormRows()} containerClassName="wrapper-container" />
				{this.renderButtons()}
			</ViewWeb>
		);
	}

	private nameChanged = (newName: string) => {
		this.vaccineListSettingChanged('name', newName);
	};
	private init(doesExist: IWorkListSetting | undefined) {
		let settingToInit = doesExist
			? VaccineListSetting.fromJS(doesExist)
			: VaccineListSetting.fromJS({ type: this.props.workListType, vaccineSubTasks: [] });

		for (let i = 1; i < 4; i++) {
			if (settingToInit.vaccineSubTasks && !settingToInit.vaccineSubTasks?.find(e => e.subTaskNumber === i)) {
				settingToInit.vaccineSubTasks.push(
					VaccineSubTask.fromJS({
						subTaskNumber: i,
						afterEventTasks: [
							AfterEventTask.fromJS({ afterEventNumber: 1 } as IAfterEventTask),
							AfterEventTask.fromJS({ afterEventNumber: 2 } as IAfterEventTask),
						],
					}),
				);
			}
		}
		settingToInit.init({
			...settingToInit,
			siteId: this.props.siteId,
		});
		return settingToInit;
	}

	private getFormRows() {
		let formRows = new Array<FormRow>();
		formRows.push(this.getHeader());
		formRows.push(this.getNameRow());
		formRows.push(this.getVaccinePregnancyTypeRow());
		if (this.state.vaccineListSetting && this.state.vaccineListSetting!.vaccineSubTasks) {
			this.state.vaccineListSetting!.vaccineSubTasks!.forEach(subTask => {
				formRows.push(this.getSkioldDropdownRow(subTask));
			});
		}
		if (this.state.selectedVaccinePregnancyType.value !== VaccinePregnancyType.AgeYoungFemale) {
			formRows.push(this.getIncludeLitterNumberRow());
		}
		formRows.push(this.getShowLocationOnList());
		formRows.push(this.getAvailablityRow());
		return formRows;
	}

	private getNameRow(): FormRow {
		return {
			name: localized('name'),
			component: <SkioldFormInput onChangeText={this.nameChanged} text={this.state.vaccineListSetting.name} />,
		};
	}

	private VaccinePregnancyTypeChanged = (option: Option) => {
		const pregnancyType = option.value as VaccinePregnancyType;

		this.setState(prevState => ({
			selectedVaccinePregnancyType: option,
			vaccineListSetting: { ...prevState.vaccineListSetting, vaccinePregnancyType: pregnancyType },
		}));
	};

	private getVaccinePregnancyTypeRow = () => {
		return {
			name: localized('calcByVaccinationPregnancyType'),
			component: (
				<SkioldFormDropdown
					onValueChanged={this.VaccinePregnancyTypeChanged}
					selectedValue={this.state.selectedVaccinePregnancyType}
					items={this.getVaccinePregnancyTypeOptions()}
				/>
			),
		};
	};

	private getShowLocationOnList = () => {
		return {
			name: localized('showLocationOnList'),
			component: (
				<SkioldCheckbox
					onClick={this.showOnLocationChanged}
					isChecked={this.state.vaccineListSetting.showLocationOnList!}
				/>
			),
		};
	};

	private getVaccinePregnancyTypeOptions() {
		return Object.keys(VaccinePregnancyType).map(k => {
			return {
				label: localizedDynamic(VaccinePregnancyType[k as any]),
				value: VaccinePregnancyType[k as any],
			};
		});
	}

	private getVaccinePregnancyTypeOptionBySetting(setting: IVaccineListSetting) {
		return {
			label: localizedDynamic(setting.vaccinePregnancyType!),
			value: setting.vaccinePregnancyType!,
		};
	}

	private getIncludeLitterNumberRow(): FormRow {
		return {
			name: localized('vaccineByLitterNumber'),
			component: (
				<SkioldFormIntegerInput
					onChangeNumber={this.includeLitterToChanged}
					text={this.state.vaccineListSetting.includeLitterTo}
				/>
			),
		};
	}

	private vaccineSubTaskChanged = (key: keyof VaccineSubTask, vaccineNumber: number, value: any) => {
		this.setState(prevState => ({
			vaccineListSetting: {
				...prevState.vaccineListSetting,
				vaccineSubTasks: prevState.vaccineListSetting.vaccineSubTasks!.map(e =>
					e.subTaskNumber === vaccineNumber ? VaccineSubTask.fromJS({ ...e, [key]: value }) : e,
				),
			},
		}));
	};

	private afterEventChanged = (
		key: keyof AfterEventTask,
		afterEventNumber: number,
		vaccineNumber: number,
		value: any,
	) => {
		this.setState(prevState => ({
			vaccineListSetting: {
				...prevState.vaccineListSetting,
				vaccineSubTasks: prevState.vaccineListSetting.vaccineSubTasks!.map(e =>
					e.subTaskNumber !== vaccineNumber
						? e
						: VaccineSubTask.fromJS({
								...e,
								afterEventTasks: e.afterEventTasks!.map(e =>
									e.afterEventNumber === afterEventNumber
										? AfterEventTask.fromJS({ ...e, [key]: value })
										: e,
								),
						  }),
				),
			},
		}));
	};

	private getSkioldDropdownRow(vaccineSubTask: VaccineSubTask): FormRow {
		const filtertedTreatmentDefs = this.props.treatmentDefinitions.filter(
			def => this.props.diagnoses.find(a => a.id === def.diagnoseId && a.isVaccination) !== undefined,
		);
		return {
			name: (
				<ViewWeb className="multiple-row-container">
					<TextWeb className="bold-font-size-sixteen">
						{localized('VaccineListSetting')} {vaccineSubTask.subTaskNumber}
					</TextWeb>
					<TextWeb className="bold-font-size-sixteen">{localized('columnTextShownAs')}</TextWeb>
					{this.state.selectedVaccinePregnancyType.value !== VaccinePregnancyType.AgeYoungFemale ? (
						<>
							<TextWeb className="bold-font-size-sixteen">{localized('weeksAfterEvent')}</TextWeb>
							<TextWeb className="bold-font-size-sixteen">{localized('weeksAfterEvent')}</TextWeb>
						</>
					) : (
						<TextWeb className="bold-font-size-sixteen">{localized('AgeForYoungFemales')}</TextWeb>
					)}
				</ViewWeb>
			),
			component: (
				<ViewWeb className="treatment-picker-container" style={{ zIndex: 5 - vaccineSubTask.subTaskNumber! }}>
					<ViewWeb className="align-picker">
						<TreatmentDefinitionPicker
							onValueChanged={(newText: ITreatmentDefinition | undefined) =>
								this.vaccineSubTaskChanged(
									'vaccineDefinitionId',
									vaccineSubTask.subTaskNumber!,
									newText ? newText.id : undefined,
								)
							}
							treatmentDefinitionId={vaccineSubTask.vaccineDefinitionId}
							pigType={
								this.state.selectedVaccinePregnancyType.value !== VaccinePregnancyType.AgeYoungFemale
									? AnimalKind.Gilt
									: AnimalKind.YoungFemale
							}
							allowVaccines={true}
							allowMedicine={false}
							treatmentDefinitionsFromParent={filtertedTreatmentDefs}
						/>
					</ViewWeb>
					<ViewWeb className="column-text-container">
						<ViewWeb className="vertical-padding">
							<SkioldFormInput
								className="column-text-input"
								onChangeText={newText =>
									this.vaccineSubTaskChanged('columnText', vaccineSubTask.subTaskNumber!, newText)
								}
								text={vaccineSubTask.columnText}
							/>
						</ViewWeb>
					</ViewWeb>
					{this.state.selectedVaccinePregnancyType.value !== VaccinePregnancyType.AgeYoungFemale ? (
						vaccineSubTask.afterEventTasks!.map((afterEvent, index) => (
							<ViewWeb key={index} className="after-event-container">
								<ViewWeb className="after-event-width">
									<SkioldFormIntegerInput
										className="after-event-input"
										itemFromParent={{
											afterEventAfterEventNumber: afterEvent.afterEventNumber,
											subTaskNumber: vaccineSubTask.subTaskNumber,
										}}
										onChangeNumber={this.weeksChanged}
										text={afterEvent.days ? Math.round(afterEvent.days / 7) : undefined}
									/>
								</ViewWeb>

								<ViewWeb className="vac-checkbox-container">
									<SkioldCheckbox
										className="vac-checkbox-inner"
										onClick={() =>
											this.afterEventChanged(
												'treatGilt',
												afterEvent.afterEventNumber!,
												vaccineSubTask.subTaskNumber!,
												!afterEvent.treatGilt,
											)
										}
										isChecked={afterEvent.treatGilt!}
									/>
									<TextWeb>{'Gylte'}</TextWeb>
								</ViewWeb>
								<ViewWeb className="vac-checkbox-container">
									<SkioldCheckbox
										className="vac-checkbox-inner"
										onClick={() =>
											this.afterEventChanged(
												'treatSows',
												afterEvent.afterEventNumber!,
												vaccineSubTask.subTaskNumber!,
												!afterEvent.treatSows,
											)
										}
										isChecked={afterEvent.treatSows!}
									/>
									<TextWeb>{'Søer'}</TextWeb>
								</ViewWeb>
							</ViewWeb>
						))
					) : (
						<ViewWeb className="column-text-container">
							<ViewWeb className="vertical-padding">
								<SkioldFormIntegerInput
									className="column-text-input"
									onChangeNumber={newText =>
										this.vaccineSubTaskChanged(
											'ageYoungFemale',
											vaccineSubTask.subTaskNumber!,
											newText,
										)
									}
									text={vaccineSubTask.ageYoungFemale}
								/>
							</ViewWeb>
						</ViewWeb>
					)}
				</ViewWeb>
			),
			style: {
				height:
					this.state.selectedVaccinePregnancyType.value !== VaccinePregnancyType.AgeYoungFemale ? 170 : 130,
			},
		};
	}

	private weeksChanged = (
		newNumber: number | undefined,
		itemFromParent: { afterEventAfterEventNumber: number; subTaskNumber: number },
	) => {
		this.setState(prevState => ({
			vaccineListSetting: {
				...prevState.vaccineListSetting,
				vaccineSubTasks: prevState.vaccineListSetting.vaccineSubTasks!.map(e =>
					e.subTaskNumber !== itemFromParent.subTaskNumber
						? e
						: VaccineSubTask.fromJS({
								...e,
								afterEventTasks: e.afterEventTasks!.map(e =>
									e.afterEventNumber === itemFromParent.afterEventAfterEventNumber
										? AfterEventTask.fromJS({ ...e, days: newNumber ? newNumber * 7 : undefined! })
										: e,
								),
						  }),
				),
			},
		}));
	};

	private getAvailablityRow(): FormRow {
		return {
			name: localized('listAvailableOn'),
			component: (
				<ViewWeb className="availability-container">
					<SkioldCheckbox
						onClick={this.toggleShowOnApp}
						isChecked={this.state.vaccineListSetting.showOnApp!}
					/>
					<TextWeb>{localized('App')}</TextWeb>
					<SkioldCheckbox
						onClick={this.toggleShowOnWeb}
						isChecked={this.state.vaccineListSetting.showOnWeb!}
					/>
					<TextWeb>{localized('Web')}</TextWeb>
				</ViewWeb>
			),
		};
	}

	private save = async () => {
		if (!this.validate(this.state.vaccineListSetting)) {
			return;
		}

		this.props.saveWorkListSetting(this.state.vaccineListSetting);
	};

	private validate(listSetting: IVaccineListSetting) {
		let hasOneChosenVaccine = false;
		if (!listSetting.siteId) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_SITE_NOT_SET));
			return false;
		}
		if (!listSetting.vaccinePregnancyType) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_CHOOSE_PREGNANCY_EVENT));
			return false;
		}

		if (
			!listSetting.includeLitterTo &&
			this.state.selectedVaccinePregnancyType.value !== VaccinePregnancyType.AgeYoungFemale
		) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_INCLUDE_LITTERS_TO));
			return false;
		}

		for (let subTask of listSetting.vaccineSubTasks!) {
			if (subTask.vaccineDefinitionId) {
				hasOneChosenVaccine = true;
			}
			if (subTask.vaccineDefinitionId && !subTask.columnText) {
				showAlert(localized(ExceptionMessage.VALIDATION_ERROR_COLUMN_TEXT_IS_NOT_SET));
				return false;
			}

			for (let afterEventTask of subTask.afterEventTasks!) {
				if (afterEventTask.days !== undefined && afterEventTask.days < 0) {
					showAlert(localized(ExceptionMessage.VALIDATION_ERROR_AFTER_EVENT_DAYS_GREATER_THAN_ZERO));
					return false;
				}
			}
		}

		if (!listSetting.showOnApp && !listSetting.showOnWeb) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_AVAILABLE_ON_NEED_TO_BE_SET));
			return false;
		}

		if (!hasOneChosenVaccine) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_CHOOSE_ATLEAST_ONE_TREATMENT_DEF));
			return false;
		}

		return true;
	}

	private renderButtons() {
		return (
			<ViewWeb className="view-button-container">
				<SkioldButton title={localized('Save')} onPress={this.save} />
			</ViewWeb>
		);
	}

	private getHeader(): FormRow {
		return {
			header: localized(this.props.workListType!),
		};
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(VaccineListSetup);
