import React from 'react';
import isEqual from 'react-fast-compare';
import { connect } from 'react-redux';
import { AnimalType, ISection, LocationType } from 'shared/api/api';
import { setOneDecimalsAsNumber } from 'shared/helpers/general-helpers';
import {
	onGrowthPigEntranceNumberChanged,
	validateGrowthPigEntranceEvent,
} from 'shared/helpers/growth-pigs-helper/growth-pig-event-function-helper';
import {
	GrowthPigEntranceDateProperties,
	GrowthPigEntranceEventProps,
	GrowthPigEntranceState,
	GrowthPigEventsMapDispatchToProps,
	GrowthPigEventsMapStateToProps,
	GrowthPigNumberProperties,
	UpsertGrowthPigEventAndClose,
	getGrowthPigEntranceResetComponentState,
	getGrowthPigsEntranceEventInitialState,
	saveGrowthPigEntranceEventFromGrowthPigEntranceEvent,
	upsertGrowthPigEntranceEvent,
} from 'shared/helpers/growth-pigs-helper/growth-pig-event-helper';
import { CheckIfSectionUsesPens, FindHiddenPenOnSection } from 'shared/helpers/location-helper';
import { PenHasDistriwin } from 'shared/state/ducks/unit-to-pen/reducer';
import { localized } from 'shared/state/i18n/i18n';
import SkioldFormPenPicker from 'web/view/components/location/location-picker/skiold-form-pen-picker';
import SkioldStableSectionPicker from 'web/view/components/location/location-picker/skiold-stable-section-picker';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldDatePicker } from 'web/view/components/skiold-components/skiold-date-picker/skiold-date-picker';
import { SkioldFormsWrapper } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { SkioldFormIntegerInput } from 'web/view/components/skiold-components/skiold-integer-input/skiold-form-integer-input';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { DistriwinFeedCurvePicker } from '../../distriwin-components/distriwin-feed-curve-picker';
import { DistriwinRecipePicker } from '../../distriwin-components/distriwin-recipe-picker';
import { ShowConfirmAlert, showAlert } from '../../skiold-alert/skiold-alert';
import { SkioldFormDecimalInput } from '../../skiold-components/skiold-decimal-input/skiold-form-decimal-input';
import { SkioldModal } from '../../skiold-components/skiold-modal/skiold-modal';
import GrowthPigProductionTypePicker from '../../skiold-components/skiold-production-type-picker/skiold-production-type-picker';
import GrowthPigDistributeEntrance from './growth-pig-distribute-entrance';

export class GrowthPigEntrance extends React.PureComponent<GrowthPigEntranceEventProps, GrowthPigEntranceState> {
	constructor(props: GrowthPigEntranceEventProps) {
		super(props);
		const growthPigEventToEdit = this.props.growthPigsEvent;
		this.state = getGrowthPigsEntranceEventInitialState(growthPigEventToEdit, props);
	}

	public render() {
		return (
			<ViewWeb className="sow-entrance">
				<SkioldFormsWrapper
					formRows={this.getFormRows()}
					containerClassName="sow-entrance-forms-wrapper-container"
				/>
				{this.state.date &&
					this.state.avgWeight !== undefined &&
					this.state.amount !== undefined &&
					this.state.section &&
					this.state.section.id &&
					this.state.selectedProductionType && (
						<SkioldModal
							shouldCloseOnOverlayClick={true}
							padding="0"
							isOpen={this.state.modalOpen}
							close={this.closeModal}
							justify-content="flex-end"
							max-width="calc(100% - 220px)"
						>
							<GrowthPigDistributeEntrance
								closeModal={this.closeModal}
								date={this.state.date}
								retentionDate={this.state.retentionDate}
								avgWeight={this.state.avgWeight}
								totalWeight={this.state.totalWeight}
								amount={this.state.amount}
								toSectionId={this.state.section.id}
								productionType={this.props.productionType}
							/>
						</SkioldModal>
					)}
				{this.renderButtons()}
			</ViewWeb>
		);
	}

	public componentDidUpdate(prevProps: GrowthPigEntranceEventProps) {
		if (!isEqual(prevProps.productionType, this.props.productionType)) {
			const growthPigEventToEdit = this.props.growthPigsEvent;
			this.setState(getGrowthPigsEntranceEventInitialState(growthPigEventToEdit, this.props));
		}
	}

	private closeModal = (saved?: boolean) => {
		if (saved) {
			this.resetComponent();
		} else {
			this.setState({ modalOpen: false });
		}
	};

	public resetComponent = () => {
		this.setState(getGrowthPigEntranceResetComponentState(this.props, this.state.date));
	};

	public getProductionRow(): FormRow {
		return {
			name: localized('production'),
			component: (
				<GrowthPigProductionTypePicker
					dontSetToFrats={!!(this.state.section && this.state.section.animalType !== AnimalType.FRATS)}
					onProductionTypeChanged={this.OnProductionTypeChanged}
					usedInsideForm={true}
					disableYoungFemale={true}
					productionType={this.state.selectedProductionType}
				/>
			),
		};
	}

	private OnProductionTypeChanged = (productionType: AnimalType) => {
		if (productionType !== this.state.selectedProductionType) {
			this.setState({ selectedProductionType: productionType, section: undefined, penId: undefined });
		} else {
			this.setState({ selectedProductionType: productionType });
		}
	};

	private getFormRows() {
		let formRows = new Array<FormRow>();

		formRows.push(this.getDateRow('date'));
		formRows.push(this.getProductionRow());
		formRows.push(this.getStableSectionPickerRow());
		if (this.state.section && this.state.section.id) {
			if (CheckIfSectionUsesPens(this.state.section.id)) {
				formRows.push(this.getPenPickerRow());
			}
		}
		if (
			this.state.penId &&
			PenHasDistriwin(this.state.penId) &&
			((this.props.productionType === AnimalType.Finisher &&
				this.props.generalSettings.sendEntranceDepartureDistriwinFinisher) ||
				(this.props.productionType === AnimalType.Weaner &&
					this.props.generalSettings.sendEntranceDepartureDistriwinWeaner))
		) {
			formRows.push(this.getCurvePicker());
			formRows.push(this.getRecipePicker());
		}
		formRows.push(this.getAmountRow());
		formRows.push(this.getAvgWeightRow('avgWeight'));
		formRows.push(this.getIntergerRow('totalWeight'));
		formRows.push(this.getDateRow('retentionDate'));
		return formRows;
	}

	private getAvgWeightRow(property: GrowthPigNumberProperties): FormRow {
		let value = setOneDecimalsAsNumber(this.state[property]);
		return {
			name: localized('avgWeight'),
			component: (
				<SkioldFormDecimalInput
					text={
						value === undefined || value <= 0.0 ? undefined : setOneDecimalsAsNumber(this.state[property])
					}
					itemFromParent={property}
					onChangeNumber={this.OnNumberChanged}
				/>
			),
		};
	}

	private OnPropertyChanged = (newText, ItemFromParent: string) => {
		const state = { ...this.state };
		state[ItemFromParent] = newText;
		this.setState({ ...state });
	};

	private OnNumberChanged = (newText?: number, ItemFromParent?: GrowthPigNumberProperties) => {
		let state = { ...this.state };
		onGrowthPigEntranceNumberChanged(ItemFromParent, state, newText);
		this.setState({ ...state });
	};

	private sectionSelected = (selectedSectionId: string) => {
		const section = this.props.hashmapLocations[selectedSectionId] as ISection;

		if (CheckIfSectionUsesPens(selectedSectionId)) {
			let state = { section: section } as GrowthPigEntranceState;

			if (selectedSectionId !== section.id) {
				state = { ...state, penId: undefined };
			}
			this.setState(state);
		} else if (section === undefined) {
			this.setState({ penId: undefined, section: undefined });
		} else {
			let penId = FindHiddenPenOnSection(selectedSectionId, this.state.penId);
			if (penId) {
				this.setState({ penId, section: section });
			}
		}
	};

	private penSelected = (selectedPenId: string) => {
		this.setState({ penId: selectedPenId });
	};

	private getAmountRow = (): FormRow => {
		return {
			name: localized('Count'),
			component: (
				<SkioldFormIntegerInput
					text={this.state.amount}
					itemFromParent={'amount'}
					onChangeNumber={this.OnNumberChanged}
				/>
			),
		};
	};

	private getIntergerRow = (property: GrowthPigNumberProperties): FormRow => {
		return {
			name: localized(property as string),
			component: (
				<SkioldFormDecimalInput
					text={this.state[property] ? setOneDecimalsAsNumber(this.state[property]) : undefined}
					itemFromParent={property}
					onChangeNumber={this.OnNumberChanged}
				/>
			),
		};
	};

	private getDateRow = (property: GrowthPigEntranceDateProperties): FormRow => {
		return {
			name: property === 'retentionDate' ? localized('RetentionTime') : localized('Date'),
			component: (
				<SkioldDatePicker
					onDateChanged={this.OnPropertyChanged}
					itemFromParent={property}
					selectedDate={this.state[property]}
					useMaxDate={property !== 'retentionDate'}
					theme={'dark'}
					color={'grey'}
					backwardDateSetEndTime={true}
				/>
			),
		};
	};

	private getStableSectionPickerRow(): FormRow {
		return {
			name: localized('StableSection'),
			component: (
				<SkioldStableSectionPicker
					usedInsideForm={true}
					onStableSectionSelected={this.sectionSelected}
					sectionId={this.state.section && this.state.section.id}
					locationType={
						this.state.selectedProductionType === AnimalType.FRATS
							? this.props.productionType === AnimalType.Weaner
								? LocationType.Weaners
								: LocationType.Finisher
							: undefined
					}
					AnimalType={this.state.selectedProductionType}
					penId={this.state.penId}
					isPigProduction={true}
				/>
			),
		};
	}

	private getPenPickerRow(): FormRow {
		return {
			name: localized('Pen'),
			component: (
				<SkioldFormPenPicker
					usedInsideForm={true}
					onPenSelected={this.penSelected}
					sectionId={this.state.section && this.state.section.id}
					penId={this.state.penId}
				/>
			),
		};
	}

	private feedCurveChanged = (feedCurve: number) => {
		this.setState({ feedCurve });
	};

	private recipeChanged = (recipeNumber: number) => {
		this.setState({ recipeNumber });
	};

	private getCurvePicker(): FormRow {
		return {
			name: localized('FeedingCurve'),
			component: (
				<DistriwinFeedCurvePicker
					form={true}
					penId={this.state.penId}
					onSelect={this.feedCurveChanged}
					animalType={this.props.productionType}
				/>
			),
		};
	}

	private getRecipePicker(): FormRow {
		return {
			name: localized('Recipe'),
			component: (
				<DistriwinRecipePicker
					form={true}
					penId={this.state.penId}
					onSelect={this.recipeChanged}
					animalType={this.props.productionType}
				/>
			),
		};
	}

	private renderButtons() {
		return (
			<>
				{!this.state.growthPigEvent ? (
					<ViewWeb className="button-view-style">
						<SkioldButton
							disabled={
								!this.state.section ||
								!this.state.section.id ||
								(this.state.penId !== undefined && this.state.penId.length > 0) ||
								!CheckIfSectionUsesPens(this.state.section.id)
							}
							title={localized('Distribute')}
							onPress={this.openModal}
						/>
						<SkioldButton title={localized('Save')} onPress={this.save} />
					</ViewWeb>
				) : (
					<ViewWeb className="button-view-style">
						<SkioldButton title={localized('Save')} onPress={this.upsertEvent} />
						<SkioldButton title={localized('Delete')} onPress={this.deleteEvent} />
						<SkioldButton theme="grey" title={localized('Close')} onPress={this.props.closeEditModal} />
					</ViewWeb>
				)}
			</>
		);
	}

	private deleteEvent = async () => {
		const growthPigEventToEdit = this.props.growthPigsEvent;
		if (growthPigEventToEdit) {
			growthPigEventToEdit.isDeleted = true;
			UpsertGrowthPigEventAndClose(
				growthPigEventToEdit,
				this.props.upsertGrowthPigsEvent,
				this.props.closeEditModal,
			);
		}
	};

	private upsertEvent = async () => {
		if (
			await validateGrowthPigEntranceEvent(
				this.state,
				false,
				showAlert,
				this.props.locations,
				this.props.growthPigsEvents,
				ShowConfirmAlert,
			)
		) {
			await upsertGrowthPigEntranceEvent(this.props, this.state, ShowConfirmAlert);
		}
	};

	private openModal = async () => {
		if (
			await validateGrowthPigEntranceEvent(
				this.state,
				true,
				showAlert,
				this.props.locations,
				this.props.growthPigsEvents,
				ShowConfirmAlert,
			)
		) {
			this.setState({ modalOpen: true });
		}
	};

	private save = async () => {
		if (
			await validateGrowthPigEntranceEvent(
				this.state,
				false,
				showAlert,
				this.props.locations,
				this.props.growthPigsEvents,
				ShowConfirmAlert,
			)
		) {
			saveGrowthPigEntranceEventFromGrowthPigEntranceEvent(this.state, this.props, this.resetComponent);
		}
	};
}

export default connect(GrowthPigEventsMapStateToProps, GrowthPigEventsMapDispatchToProps)(GrowthPigEntrance);
