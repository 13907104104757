import { StoreContainer } from 'shared/state/store-container';
import { IPregnancyEvent, IMatingBatch, PregnancyState } from 'shared/api/api';
import { sortPregnanciesByDate } from './pregnancy-helper/sort-pregnancies';
import moment from 'moment';

export function findBatch(stemAnimalId: string) {
	const state = StoreContainer.getState();
	return findBatch1(state.pregnancyEvents.entities[stemAnimalId], state.matingBatches.entities);
}

export function findBatchByFarrowingId(
	stemAnimalId: string,
	farrowingId: string,
	pregnancies?: {
		[key: string]: IPregnancyEvent[];
	},
) {
	const state = StoreContainer.getState();
	let pregs = state.pregnancyEvents.entities;
	if (pregnancies) {
		pregs = pregnancies;
	}

	const farrowing = pregs[stemAnimalId]?.find(p => p.id === farrowingId);
	const mating = pregs[stemAnimalId]?.find(
		p => p.state === PregnancyState.Mated && p.pregnancyId === farrowing?.pregnancyId,
	);

	if (mating) {
		const matingBatch = state.matingBatches.entities.find(
			x => mating.date && x.matingPeriodStart! <= mating.date && mating.date <= x.matingPeriodEnd!,
		);
		return matingBatch?.batchNumber;
	}
	return undefined;
}

export function findBatch1(pregnancyEvents: IPregnancyEvent[], matingBatches: IMatingBatch[]) {
	if (!pregnancyEvents) {
		return undefined;
	}

	const sorted = sortPregnanciesByDate(pregnancyEvents);
	const mated = sorted.find(x => x.state === PregnancyState.Mated);
	if (mated) {
		return getMatingBatchNumber1(mated.date!, matingBatches);
	}
	return '';
}

export function getMatingBatch(stemAnimalId: string) {
	const state = StoreContainer.getState();
	const pregnancyEvents = state.pregnancyEvents.entities[stemAnimalId];
	const matingBatches = state.matingBatches.entities;
	if (!pregnancyEvents) {
		return undefined;
	}

	const sorted = sortPregnanciesByDate(pregnancyEvents);
	const mated = sorted.find(x => x.state === PregnancyState.Mated);
	if (mated) {
		const matingBatch = matingBatches.find(
			x => mated.date && x.matingPeriodStart! <= mated.date && mated.date <= x.matingPeriodEnd!,
		);
		return matingBatch;
	}
	return undefined;
}

export function getMatingBatchNumber(date: Date) {
	if (!date) {
		return;
	}
	const state = StoreContainer.getState();
	const matingBatch = state.matingBatches.entities.find(
		x => x.matingPeriodStart! <= date && date <= x.matingPeriodEnd!,
	);
	const batchNumber = matingBatch ? matingBatch.batchNumber + '' : '';
	return batchNumber;
}

export function getMatingBatchNumber1(date: Date, matingBatches: IMatingBatch[]) {
	if (!date) {
		return;
	}

	const matingBatch = matingBatches.find(x => x.matingPeriodStart! <= date && date <= x.matingPeriodEnd!);
	const batchNumber = matingBatch ? matingBatch.batchNumber + '' : '';
	return batchNumber;
}

export function getFarrowingPeriod(matingBatch: IMatingBatch | undefined) {
	if (matingBatch) {
		let matingBatchSetting = StoreContainer.getState().matingBatchSetting.entities.find(
			setting =>
				setting.startDate &&
				matingBatch.matingPeriodStart &&
				setting.endDate &&
				matingBatch.matingPeriodEnd &&
				setting.startDate <= matingBatch.matingPeriodStart &&
				setting.endDate >= matingBatch.matingPeriodEnd,
		);
		if (matingBatchSetting && matingBatchSetting.pregnantDay) {
			let farrowingPeriodStart = moment(matingBatch.matingPeriodStart)
				.add(matingBatchSetting.pregnantDay, 'days')
				.toDate();
			let farrowingPeriodEnd = moment(matingBatch.matingPeriodEnd)
				.add(matingBatchSetting.pregnantDay, 'days')
				.toDate();
			return { farrowingPeriodStart, farrowingPeriodEnd };
		}
	}
	return undefined;
}

export function getWeaningPeriod(matingBatch: IMatingBatch | undefined) {
	if (matingBatch) {
		let matingBatchSetting = StoreContainer.getState().matingBatchSetting.entities.find(
			setting =>
				setting.startDate &&
				matingBatch.matingPeriodStart &&
				setting.endDate &&
				matingBatch.matingPeriodEnd &&
				setting.startDate <= matingBatch.matingPeriodStart &&
				setting.endDate >= matingBatch.matingPeriodEnd,
		);
		if (matingBatchSetting && matingBatchSetting.pregnantDay && matingBatchSetting.nursingDays) {
			let weaningPeriodStart = moment(matingBatch.matingPeriodStart)
				.add(matingBatchSetting.pregnantDay + matingBatchSetting.nursingDays, 'days')
				.toDate();
			let weaningPeriodEnd = moment(matingBatch.matingPeriodEnd)
				.add(matingBatchSetting.pregnantDay + matingBatchSetting.nursingDays, 'days')
				.toDate();
			return { weaningPeriodStart, weaningPeriodEnd };
		}
	}
	return undefined;
}
