import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { WebAppState } from "web/state/store.web";
import { ITreatment, ITreatmentPlan, Treatment } from 'shared/api/api';
import { SaveLogTreatment, SaveTreatment } from 'shared/state/ducks/treatment/operations';
import { UpdateTreatmentPlan } from 'shared/state/ducks/treatment-plan/operations';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { SkioldDatePicker } from '../../skiold-components/skiold-date-picker/skiold-date-picker';
import { SkioldButton } from '../../skiold-components/skiold-button/skiold-button';
import { localized } from 'shared/state/i18n/i18n';
import { deepCopy } from 'shared/helpers/general-helpers';
import './edit-treatment.scss';
import { Heading } from '../../utils/heading';
import PageContainer from '../../page-container/page-container';

interface PropsFromParent {
    close: () => void;
    treatment: ITreatment;
    isOpen: boolean;
}

const mapStateToProps = (state: WebAppState) => {
    return {
        treatmentPlans: state.treatmentPlans.treatmentPlansForLog,
        logTreatments: state.treatments.treatmentsForLog,
        treatments: state.treatments.entities
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
    saveTreatment: (treatment: ITreatment) => SaveLogTreatment(treatment)(dispatch),
    saveLogTreatment: (treatment: ITreatment) => SaveTreatment(treatment)(dispatch),
    updateTreatmentPlan: (treatmentPlan: ITreatmentPlan) => UpdateTreatmentPlan(treatmentPlan)(dispatch)
});

interface State {
    treatment: ITreatment;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;
class EditTreatment extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            treatment: this.props.treatment
        };
    }

    public render() {
        return (
            <PageContainer >
            <ViewWeb className='edit-treatment'>
                <Heading text={localized('EditTreatment')} />
                <ViewWeb className='alignCenter'>
                <SkioldDatePicker
                    onDateChanged={(newDate: Date) => {
                        this.setState(prevState => ({
                            treatment: { ...prevState.treatment, executedDate: newDate }
                        }));
                    }}
                    selectedDate={this.state.treatment.executedDate}
                    text={'ExecutedDate'}
                    theme='light'
                />
                </ViewWeb>
                <ViewWeb
                className='buttons-container'
                >
                    <SkioldButton title={localized('Close')} onPress={this.props.close} theme="grey" />
                    <SkioldButton
                        title={localized('DeleteTreatmentPlan')}
                        onPress={this.deletePlan}
                        theme="grey"
                    />

                    <SkioldButton title={localized('Reactivate')} onPress={this.reactivate} theme="grey" />
                    {/* <SkioldButton title={localized('Save')} onPress={() => this.save()} /> */}
                </ViewWeb>
            </ViewWeb>
            </PageContainer>
        );
    }

    private async save() {
        this.props.saveTreatment(this.state.treatment);
        this.props.close();
    }

    private  reactivate = async() => {
        const treatment = Treatment.fromJS(this.state.treatment);
        treatment.executedDate = undefined;

        this.props.saveTreatment(treatment);
        this.props.close();
    }

    private  deletePlan = async() => {
        if (window.confirm(localized('DeleteTreatmentPlanPrompt'))) {
            // Find and delete all the local treatments from that plan (Log and Non-Log)
            const logTreatments = this.props.logTreatments.filter(
                t => t.treatmentPlanId === this.state.treatment.treatmentPlanId
            );

            const otherTreatments = this.props.treatments.filter(
                t => t.treatmentPlanId === this.state.treatment.treatmentPlanId
            );

            // Check that we can actually get the treatmentplan. If we can't, something is
            // probably very wrong
            const treatmentPlan = deepCopy(
                this.props.treatmentPlans.find(tp => tp.id === this.state.treatment.treatmentPlanId)
            );
            if (!treatmentPlan) {
                alert(localized('TreatmentPlanNotFound'));
                return;
            }

            let treatmentsToDelete = logTreatments.concat(otherTreatments);
            treatmentsToDelete = [...new Set(treatmentsToDelete.map(t => t))]; // Remove duplicates

            for (const treatment of treatmentsToDelete) {
                treatment.isDeleted = true;
                if (this.state.treatment.executedDate) {
                    this.props.saveLogTreatment(treatment);
                } else {
                    this.props.saveTreatment(treatment);
                }
            }

            // Delete the actual treatment plan
            treatmentPlan.isDeleted = true;
            this.props.updateTreatmentPlan(treatmentPlan);

            this.props.close();
        }
    }
}

export default (
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(EditTreatment)
);
