import React from 'react';
import './skiold-flatlist.scss';

export interface PropsFromParent {
	data: any[];
	numColumns?: number;
	style?: any;
	keyExtractor?: (item: any, index: number) => void;
	renderItem: (item: any, index?: number) => JSX.Element;
	className?: string;
	contentContainerClassName?: string;
	contentContainerClassStyle?: string;
	horizontal?: boolean;
}

// Component representing a Skiold Digital textfield throughout the app
export class SkioldFlatList extends React.PureComponent<PropsFromParent> {
	public static defaultProps: Partial<PropsFromParent> = {
		className: '',
		contentContainerClassName: '',
		horizontal: false
	};
	// private checkBoxStyle = [styles.checkBoxCommonStyle, this.props.style];
	public render() {
		return (
			<ul
				className={`skiold-flatlist ${this.props.contentContainerClassName} ${
					this.props.horizontal ? 'horizontal' : 'vertical'
				}`}
			>
				{this.props.data.map(this.renderItem)}
			</ul>
		);
	}

	private renderItem = (item: any, index: number) => {
		return <li key={index}>{this.props.renderItem(item, index)}</li>;
	};
}
