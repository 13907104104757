import { Option } from 'react-dropdown';
import { Dispatch } from 'redux';
import {
	IMatingBatch,
	IMoveEvent,
	IMoveMatingToMatingSetting,
	IPregnancyEvent,
	IStemAnimal,
	LocationType,
	PregnancyValidationType,
	SowState,
} from 'shared/api/api';
import { calculateDays } from 'shared/helpers/general-helpers';
import { getPenBySow, getStemAnimalPen, ILocationModel } from 'shared/helpers/location-helper';
import { memoizeLocation, memoizeValidationSettingPlan } from 'shared/helpers/memoize-getters/memoize-getters';
import { getCycleDays1 } from 'shared/helpers/pregnancy-helper/generel-pregnancy-helpers';
import { getActiveSows } from 'shared/helpers/stemanimal-helper/stem-animal-input-helper';
import { getState, getState1 } from 'shared/helpers/stemanimal-helper/stemanimal-helper';
import {
	checkLocation,
	checkMatingBatch,
	checkSectionWorkLists,
	getCycleDaysByPregnancyEvents,
	InsertOldDataSelectedSettings,
	setMovelistTableItemData,
} from 'shared/helpers/work-list-helpers/move-to-helpers/moving-lists-helper';
import { GetLocations } from 'shared/state/ducks/locations/operations';
import * as matingBatchOperation from 'shared/state/ducks/mating-batch/operations';
import * as moveEventOperation from 'shared/state/ducks/move-events/operations';
import { SaveMoveEvent } from 'shared/state/ducks/move-events/operations';
import * as pregnancyOperation from 'shared/state/ducks/pregnancy-events/operations';
import * as stemAnimalOperation from 'shared/state/ducks/stem-animals/operations';
import * as validationSetupOperation from 'shared/state/ducks/validation-setup/operations';
import { GetSyncData } from 'shared/state/ducks/work-list-settings/operations';
import { SharedAppState } from 'shared/state/store.shared';
import { MovingListTable } from './move-to-data-model';

export interface MoveMatingToMatingListState {
	selectedBatch: Option;
	matingBatchesOptions: Option[];
	locationString: string | undefined;
	moveMatingToMatingSetup: IMoveMatingToMatingSetting;
	locations: ILocationModel | undefined;
	selectedHeaderSectionUsesPens: boolean;
	toSectionId?: string;
	toPenId?: string;
	fromSectionId?: string;
	fromPenId?: string;
	fromLocation: ILocationModel | undefined;
	fromSectionUsesPens: boolean;
	moveListData: MovingListTable[];
	moveListInitData: MovingListTable[];
}
export const matingToMatingLocationTypes = [LocationType.Mating, LocationType.YoungFemale];
export const moveMatingToMatingListMapStateToProps = (state: SharedAppState) => {
	return {
		workListSettings: state.workListSettings.entities,
		activeSows: state.stemAnimals.entities,
		pregnancyEvets: state.pregnancyEvents.entities,
		matingBatches: state.matingBatches.entities,
		// feedCurves: state.site.site.feedCurves,
		siteId: state.profile.active!.siteId!,
		moveEvents: state.moveEvents.entities,
		validationSettingPlan: memoizeValidationSettingPlan(
			state.validationSetup.entity,
			PregnancyValidationType.MatingToFarrowing,
		),
		locations: memoizeLocation(
			state.locations.buildings,
			state.locations.sections,
			state.locations.pens,
			state.locations.valves,
		),
	};
};
export const moveMatingToMatingListMapDispatchToProps = (dispatch: Dispatch) => ({
	saveMoveEvent: (moveEvent: IMoveEvent) => SaveMoveEvent(moveEvent)(dispatch),
	getWorkListSettingsBySiteId: () => GetSyncData()(dispatch),
	pregnancyEventGetSyncData: () => pregnancyOperation.GetSyncData()(dispatch),
	stemAnimalGetSyncData: () => stemAnimalOperation.GetSyncData()(dispatch),
	matingBatchGetSyncData: () => matingBatchOperation.GetSyncData()(dispatch),
	validationSetupGetSyncData: () => validationSetupOperation.GetSyncData()(dispatch),
	moveEventGetSyncData: () => moveEventOperation.GetSyncData()(dispatch),
	getLocations: () => GetLocations()(dispatch),
});

export type MoveMatingToMatingListProps = ReturnType<typeof moveMatingToMatingListMapStateToProps> &
	ReturnType<typeof moveMatingToMatingListMapDispatchToProps>;

export function getDataByMatingBatchMoveMatingToMatingList(
	batchId: string | undefined,
	moveEvents: IMoveEvent[],
	locations: ILocationModel,
	matingBatches: IMatingBatch[],
	pregEvents: { [key: string]: IPregnancyEvent[] },
	moveListData: MovingListTable[],
	toSectionId: string | undefined,
	toPenId: string | undefined,
	validateSettingToSubtract: number | undefined,
) {
	let workListData = [] as MovingListTable[];
	let activeSows = getActiveSows();
	const batch = matingBatches.find(matingBatch => matingBatch.id === batchId);
	if (batch) {
		for (let sow of activeSows) {
			let state = getState(sow.id!);
			let animalPregEvents = pregEvents[sow.id!];
			if (!animalPregEvents || state !== SowState.Dry) {
				continue;
			}

			let currentPen = getStemAnimalPen(sow, moveEvents, locations.pens);

			if (!currentPen || !checkLocation(currentPen, locations, matingToMatingLocationTypes)) {
				continue;
			}

			let pregnancyEvents = checkMatingBatch(pregEvents[sow.id!], batch);
			if (pregnancyEvents === undefined || !pregnancyEvents.lastestPregEvent) {
				continue;
			}
			const cycleDays = calculateDays(pregnancyEvents.lastestPregEvent.date!, new Date());
			let movePregWorkListItem = new MovingListTable();
			const negativeCycleDays = validateSettingToSubtract && cycleDays
				? cycleDays - validateSettingToSubtract : 0
			setMovelistTableItemData(
				sow,
				movePregWorkListItem, cycleDays
				, negativeCycleDays
			);
			let { usesPens } = checkSectionWorkLists(toSectionId, toPenId);
			movePregWorkListItem.sectionId = toSectionId;
			movePregWorkListItem.penId = toPenId;
			movePregWorkListItem.usesPens = usesPens;
			movePregWorkListItem.currentPenId = currentPen.id;
			workListData.push(movePregWorkListItem);
		}
		InsertOldDataSelectedSettings(workListData, moveListData);
	}
	return workListData;
}
export function getDataByLocationMoveMatingToMatingList(
	pregEvents: { [key: string]: IPregnancyEvent[] },
	moveListData: MovingListTable[],
	penId: string,
	toSectionId: string | undefined,
	toPenId: string | undefined,
	validateSettingToSubtract: number | undefined,
) {
	let ActiveSows = getActiveSows();
	let data: MovingListTable[] = [];
	for (let sow of ActiveSows) {
		let state = getState(sow.id!);

		if (state !== SowState.Dry) {
			continue;
		}
		let pen = getPenBySow(sow);
		if (pen === undefined || pen.id !== penId) {
			continue;
		}
		let movePregWorkListItem = new MovingListTable();
		const cycleDays = getCycleDaysByPregnancyEvents(pregEvents[sow.id!]);
		const negativeCycleDays = validateSettingToSubtract && cycleDays
			? cycleDays - validateSettingToSubtract : 0
		setMovelistTableItemData(sow, movePregWorkListItem, cycleDays, negativeCycleDays);
		let { usesPens } = checkSectionWorkLists(toSectionId, penId);
		movePregWorkListItem.sectionId = toSectionId;
		movePregWorkListItem.penId = toPenId;
		movePregWorkListItem.usesPens = usesPens;
		movePregWorkListItem.currentPenId = pen.id;
		data.push(movePregWorkListItem);
	}
	InsertOldDataSelectedSettings(data, moveListData);
	return data;
}

export function getMoveMatingToMatingItemByAnimal(
	sow: IStemAnimal,
	pregEvents: IPregnancyEvent[],
	moveEvents: IMoveEvent[],
	locations: ILocationModel,
	validateSettingToSubtract: number | undefined,
) {
	let state = getState1(sow.id!, pregEvents);
	if (state !== SowState.Dry) {
		return undefined;
	}

	let currentPen = getStemAnimalPen(sow, moveEvents, locations.pens);

	if (!currentPen || !checkLocation(currentPen, locations, matingToMatingLocationTypes)) {
		return undefined;
	}

	let cycleDays = getCycleDays1(sow.id!, pregEvents);
	const negativeCycleDays = validateSettingToSubtract && cycleDays
		? cycleDays - validateSettingToSubtract : 0
	let moveToWorkListItem = new MovingListTable();
	setMovelistTableItemData(sow, moveToWorkListItem, cycleDays, negativeCycleDays);
	moveToWorkListItem.currentPenId = currentPen.id;
	return moveToWorkListItem;
}
