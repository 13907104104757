import { Syncable } from "shared/state/models/syncable";
import { IMoveEvent } from "shared/api/api";


export const SAVE_MOVE_EVENT = 'SAVE_MOVE_EVENT';
export const SYNC_DATA_MOVEEVENTS = 'SYNC_DATA_MOVEEVENTS';
export const REMOVE_MOVE_EVENT = 'REMOVE_MOVE_EVENT';
export const GET_DEPARTURED_MOVE_EVENTS = 'GET_DEPARTURED_MOVE_EVENTS';

export interface MoveEventState extends Syncable<IMoveEvent> {
    departuredMoveEvents: IMoveEvent[];
}
