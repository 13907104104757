import React from 'react';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import './skiold-forms-wrapper.scss';

export interface PropsFromParent {
	className?: string;
	children?: string | number;
}

export class SkioldFormTextField extends React.PureComponent<PropsFromParent> {
	public render() {
		return (
			<TextWeb className={'skiold-form-text-field ' + (this.props.className ? this.props.className : '')}>
				{this.props.children}
			</TextWeb>
		);
	}
}
