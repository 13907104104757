import { Dispatch } from 'redux';
import { FarrowingListSetting, WorkListType } from 'shared/api/api';
import * as matingBatchOperation from 'shared/state/ducks/mating-batch/operations';
import * as pregnancyOperation from 'shared/state/ducks/pregnancy-events/operations';
import * as stemAnimalOperation from 'shared/state/ducks/stem-animals/operations';
import { SetSowsCount } from 'shared/state/ducks/stem-animals/operations';
import * as validationSetupOperation from 'shared/state/ducks/validation-setup/operations';
import { GetSyncData } from 'shared/state/ducks/work-list-settings/operations';
import { WebAppState } from 'web/state/store.web';
import { ILocationModel } from 'shared/helpers/location-helper';
import { memoizeHashmapLocation } from 'shared/helpers/memoize-getters/memoize-getters';

export class FarrowingWorkListItem {
	public cycleDays: number | undefined;
	public animalNumber: string | undefined;
	public matingBatch: string | undefined;
	public expectedFarrowing: Date | undefined;
	public stemAnimalId: string | undefined;
	public href: string | undefined;
	public penString: string | undefined;
	public buildingSectionString: string | undefined;
}

interface PropsFromParent {
	editFarrowingWeb: (farrowingWorkListItem: FarrowingWorkListItem) => void;
}

export const FarrowingMapStateToProps = (state: WebAppState) => {
	return {
		workListSetting: state.workListSettings.entities.find(
			setting => setting.type === WorkListType.FarrowingListSetting,
		) as FarrowingListSetting,
		pregnancyEvents: state.pregnancyEvents.entities,
		matingBatches: state.matingBatches.entities,
		validationSetup: state.validationSetup.entity,
		stemAnimals: state.stemAnimals.entities,
		locations: state.locations,
		moveEvents: state.moveEvents.entities,
		locationHashmap: memoizeHashmapLocation(
			state.locations.buildings,
			state.locations.sections,
			state.locations.pens,
		),
	};
};

export const FarrowingMapDispatchToProps = (dispatch: Dispatch, props: {}) => {
	return {
		setSowsCount: (count: number) => SetSowsCount(count)(dispatch),
		getWorkListSettingsBySiteId: () => GetSyncData()(dispatch),
		pregnancyEventGetSyncData: () => pregnancyOperation.GetSyncData()(dispatch),
		stemAnimalGetSyncData: () => stemAnimalOperation.GetSyncData()(dispatch),
		matingBatchGetSyncData: () => matingBatchOperation.GetSyncData()(dispatch),
		validationSetupGetSyncData: () => validationSetupOperation.GetSyncData()(dispatch),
	};
};

export interface FarrowingWorkListState {
	columns: any[];
	columnsExte: any[];
	editModalOpen: boolean;
	loading: boolean;
	headers: any;
	fromSectionId?: string;
	fromPenId?: string;
	fromLocation: ILocationModel | undefined;
	fromSectionUsesPens: boolean;
}

export type FarrowingWorkListProps = ReturnType<typeof FarrowingMapStateToProps> &
	ReturnType<typeof FarrowingMapDispatchToProps> &
	PropsFromParent;
