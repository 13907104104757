import React from 'react';
import { connect } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import {
	IResultPerLitterTableData,
	IResultPerLitterTableItem,
	ResultPerLitterTableItem,
	PeriodDto,
} from 'shared/api/api';
import { RefType } from 'shared/helpers/ref-type';
import { localized } from 'shared/state/i18n/i18n';
import styled from 'styled-components';
import { WebAppState } from 'web/state/store.web';
import SkioldTableGrid, {
	SkioldTableGrid as SkioldTableRef,
} from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { SkioldTouchableOpacity } from '../../skiold-components/skiold-touchable-opacity';
import { GetResultPerLitterPdf } from 'web/web-helpers/pdf-helper/analysis-pdf-helpers/analysis-pdf-helper';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { AnalysisResultPerLitterItemFromParent } from '../analysis-helper';

const OuterDiv = styled.div`
	margin: 16px 0;
`;

interface PropsFromParent {
	openModal: (itemFromParent: AnalysisResultPerLitterItemFromParent) => void;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		resultsPerLitterTableItems: state.resultsPerLitter.data ? state.resultsPerLitter.data : {},
		loading: state.resultsPerLitter.loading,
		profile: state.profile.active!,
	};
};

export interface State {
	loading: boolean;
	modalOpen: boolean;
	modalSelected?: string;
	batchId?: string;
	columns: any[];
	columnExte: any[];
	count?: string;
}

type Props = ReturnType<typeof mapStateToProps> & PropsFromParent;

export class AnalysisResultsPerLitterTable extends React.PureComponent<Props, State> {
	public SkioldTableRef: SkioldTableRef | undefined;
	private KeysWithModals: string[] = ['TotalMatings', 'TotalFarrowings', 'AllWeanings'];
	private columnsIds = {
		name: 'name',
		litter1: 'litter1',
		litter2: 'litter2',
		litter3: 'litter3',
		litter4: 'litter4',
		litter5: 'litter5',
		litter6: 'litter6',
		litter7: 'litter7',
		litter8: 'litter8',
		litter9: 'litter9',
		total: 'total',
	};
	private readonly inlineHeaders = ['Matings', 'Farrowings', 'Weanings'];

	private GetNameField = (d: ResultPerLitterTableItem) =>
		d.name ? (
			<div
				className={
					'textAlignStart' +
					(this.IndentedFields.includes(d.name) ? ' paddingLeftTwenty' : '') +
					(this.inlineHeaders.includes(d.name) ? ' bold' : '')
				}
			>
				{localized('AnalysisResult.' + d.name)}
			</div>
		) : (
			' '
		);

	private readonly filterNameColumn = (key: string) => key !== this.columnsIds.name;

	constructor(props: Props) {
		super(props);
		this.state = {
			columns: this.generateColumns(),
			loading: false,
			modalOpen: false,
			modalSelected: undefined,
			batchId: undefined,
			columnExte: this.generateColumnsExtensions(),
		};
	}

	public generateColumnsExtensions() {
		let columnsExt = [
			{
				columnName: this.columnsIds.name,
				width: 285,
			},
		];

		Object.keys(this.columnsIds)
			.filter(this.filterNameColumn)
			.forEach(key => {
				columnsExt.push({
					columnName: key,
					width: 80,
				});
			});
		return columnsExt;
	}

	public render() {
		return (
			<ViewWeb>
				<OuterDiv>
					{this.props.loading ? (
						<ViewWeb className="spinner-container-active-analysis">
							<ClipLoader color="#f2ac40" size={70} />
						</ViewWeb>
					) : (
						<ViewWeb className="analysis-overview-table">
							{this.props.resultsPerLitterTableItems && (
								<SkioldTableGrid
									columns={this.state.columns}
									ref={this.setRef}
									tableKey={'analysisResultsPerLitterOverview'}
									resizeAbleEnabled={false}
									InlineHeaders={this.inlineHeaders}
									data={this.GenerateData(this.props.resultsPerLitterTableItems)}
									ColumnExtensions={this.state.columnExte}
									sortable={false}
									ignoreSetCount={true}
									filterable={false}
									showPagination={true}
								/>
							)}
						</ViewWeb>
					)}
				</OuterDiv>
			</ViewWeb>
		);
	}

	public printData = (periodDto: PeriodDto) => {
		if (this.SkioldTableRef) {
			let data = this.SkioldTableRef.GetSortedData();
			GetResultPerLitterPdf(
				data,
				'ResultPerLitter.pdf',
				this.props.profile.siteId!,
				Intl.DateTimeFormat().resolvedOptions().timeZone,
				this.props.profile.language,
				periodDto,
			);
		}
	};

	private matingsHeader = { Matings: new ResultPerLitterTableItem({ name: 'Matings' } as IResultPerLitterTableItem) };
	private farrowingHeader = {
		Farrowings: new ResultPerLitterTableItem({ name: 'Farrowings' } as IResultPerLitterTableItem),
	};
	private weaningHeader = {
		Weanings: new ResultPerLitterTableItem({ name: 'Weanings' } as IResultPerLitterTableItem),
	};
	private GenerateData = (data: IResultPerLitterTableData) => {
		let d: { [key: string]: IResultPerLitterTableItem } = {};
		if (data['matings']) {
			d = { ...d, ...this.matingsHeader, ...data['matings'] };
		}
		if (data['farrowings']) {
			d = { ...d, ...this.farrowingHeader, ...data['farrowings'] };
		}
		if (data['weanings']) {
			d = { ...d, ...this.weaningHeader, ...data['weanings'] };
		}
		return Object.keys(d)
			.filter(key => key !== 'Litter' && key !== 'TotalWeanedPiglets')
			.map(key => {
				return new ResultPerLitterTableItem({ ...d[key], name: key });
			});
	};

	private setRef = (m: any) => {
		if (m) {
			this.SkioldTableRef = m;
		}
	};

	private generateColumns() {
		let columns = [
			{
				name: this.columnsIds.name,
				title: <ViewWeb className="textAlignStart paddingLeftEight bold"> {localized('LitterNr')}</ViewWeb>,
				headerClassName: 'justifyContentStart',
				isFixedLeft: true,
				getCellValue: this.GetNameField,
			},
		] as any[];
		Object.keys(this.columnsIds)
			.filter(this.filterNameColumn)
			.forEach((key, index) => {
				const getLitterCellValue = (tableItem: ResultPerLitterTableItem) =>
					this.GetLitterCellValue(tableItem, key);
				const indexToUse = index + 1;
				columns.push({
					name: key,
					title: indexToUse === 10 ? localized('Total') : indexToUse.toString(),
					getCellValue: getLitterCellValue,
				});
			});
		return columns;
	}

	private GetLitterCellValue = (tableItem: ResultPerLitterTableItem, key: string) => {
		if (tableItem && tableItem.name && this.KeysWithModals.includes(tableItem.name) && tableItem[key]) {
			const itemFromParent = { tableItem, key };
			return (
				<SkioldTouchableOpacity itemFromParent={itemFromParent} onPress={this.props.openModal}>
					{tableItem[key] && <ViewWeb className="folder-arrow arrow-folded" />}
					<TextWeb> {tableItem[key]} </TextWeb>
				</SkioldTouchableOpacity>
			);
		} else if (tableItem && tableItem[key] && tableItem[key] !== '') {
			return tableItem[key];
		} else {
			return ' ';
		}
	};

	private IndentedFields = ['AllFinishedLitters', 'AllNurseWeanings'];
}

export default connect<ReturnType<typeof mapStateToProps>, null, RefType, WebAppState>(mapStateToProps, null, null, {
	forwardRef: true,
})(AnalysisResultsPerLitterTable);
