import { IDrugType, DrugAmount, TreatmentType, IDiagnose, IStemAnimal, AnimalKind } from 'shared/api/api';

export class SubtreatmentTableModel {
	public drugType?: IDrugType;
	public drugAmount?: DrugAmount;
	public treatmentType?: TreatmentType;

	/**
	 * Represents each subtreatment
	 */
	constructor(drugType: IDrugType, drugAmount: DrugAmount, treatmentType: TreatmentType) {
		this.drugType = drugType;
		this.drugAmount = drugAmount;
		this.treatmentType = treatmentType;
	}
}

// tslint:disable-next-line:max-classes-per-file
export class TreatmentTableModel {
	public executedDate?: Date;
	public diagnose?: IDiagnose;
	public pigAmount?: number;
	public pigWeight?: number;
	public subtreatmentTableModels: SubtreatmentTableModel[] = [];
	public stemAnimal?: IStemAnimal;
	public penId?: string;
	public animalWeight?: number;
	public feedWeight?: number;
	public treatmentId?: string;
	public treatmentDefinitionId?: string;
	public treatmentPlanId?: string;
	public treatmentNumber?: number;
	public totalTreatmentNumber?: number;
	public pigCount?: number;
	public executedByUser?: string;
	public stable?: string;
	public section?: string;
	public pen?: string;
	public drugUsed?: string;
	public retentionTime?: string;
	public sectionId?: string;
	public severity?: number;
	public isRegistration?: boolean;
}

// tslint:disable-next-line:max-classes-per-file
export class SumOfTreatments {
	public subSumOftreatmentTableModels: SumOfTreatmentsTableModel[] = [];
	public animalKinds?: AnimalKind[];
}

// tslint:disable-next-line:max-classes-per-file
export class SumOfTreatmentsTableModel {
	public diagnose?: IDiagnose;
	public usage?: number[] = [];
	public subtreatmentTableModels: SubtreatmentTableModel[] = [];
	public treatmentDefinitionId?: string;
	public firstTreatment?: number[] = [];
	public retreatments?: number[] = [];
	public isRegistration?: boolean;
}
