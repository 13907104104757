import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import * as actions from './actions';
import { DistriwinFeedComponentState } from './types';
import { mergeArrays } from 'shared/helpers/reducer-helpers';
import { SyncableInitialState } from 'shared/state/models/syncable';
import { setFeedingToComponentRelations } from './actions';
import { siteChange } from '../profile/actions';

export const initialState: DistriwinFeedComponentState = {
	...SyncableInitialState,
	fetchingComponents: false,
};

const distriwinFeedComponentReducer = (
	state: DistriwinFeedComponentState = initialState,
	action: Action,
): DistriwinFeedComponentState => {
	if (isType(action, actions.getSyncData.started)) {
		state = { ...state, syncInProgress: true };
	}

	if (isType(action, actions.getSyncData.done)) {
		state = {
			...state,
			entities: mergeArrays(state.entities, action.payload.result),
		};
	}

	if (isType(action, setFeedingToComponentRelations.started)) {
		state = { ...state };
	}
	if (isType(action, setFeedingToComponentRelations.failed)) {
		state = { ...state };
	}
	if (isType(action, setFeedingToComponentRelations.done)) {
		const result = action.payload.result;
		state = {
			...state,
			entities: state.entities.map(dfc => {
				// Set component id on feeding
				return {
					...dfc,
					feedingId: dfc.id && (result[dfc.id] === 'norelation' ? '' : result[dfc.id]),
					noRelation: !!(dfc.id && result[dfc.id] === 'norelation'),
				};
			}),
		};
	}

	if (isType(action, actions.fetchComponents.started)) {
		state = { ...state, fetchingComponents: true };
	}
	if (isType(action, actions.fetchComponents.failed)) {
		state = { ...state, fetchingComponents: false };
	}
	if (isType(action, actions.fetchComponents.done)) {
		state = { ...state, fetchingComponents: false };
	}

	if (isType(action, siteChange.done)) {
		state = initialState;
	}

	return state;
};

export default distriwinFeedComponentReducer;
