import { IStemAnimal, IReasonDto } from 'shared/api/api';
import { getStemAnimalPen } from 'shared/helpers/location-helper';
import { getActiveSows } from 'shared/helpers/stemanimal-helper/stem-animal-input-helper';
import { DepartureTypes } from 'shared/state/models/departure-types';
import { DepartureListProps, DepartureListState, DepartureListTableItem } from './departure-work-list-item';

import { Option } from 'react-dropdown';
import { localized } from 'shared/state/i18n/i18n';
const DefaultDepartureReasonOption: Option = { label: ' ', value: '' };
export function genereateDepartureListTableData(props: DepartureListProps, state: DepartureListState) {
	let activeSows = getActiveSows();
	let workListData = [] as DepartureListTableItem[];

	for (let sow of activeSows) {
		if (!sow || sow.departureType !== DepartureTypes.departureTypeShouldDeparture) {
			continue;
		}
		let reason = props.reasons.find(a => a.reason && a.reason.id === sow.departureReasonId);
		let workListItem = new DepartureListTableItem();
		workListItem.animalNumber = sow.animalNumber;
		workListItem.stemAnimalId = sow.id;
		workListItem.departureDate = new Date();
		workListItem.price = sow.departurePrice;
		workListItem.departureReason = reason ? reason.reason : undefined;
		workListItem.departureType = 'departureTypeKilled';
		workListItem.selectedDepartureType = {
			label: localized(DepartureTypes.departureTypeKilled),
			value: DepartureTypes.departureTypeKilled,
		};
		if (reason) {
			workListItem.selectedDepartureReason = {
				label: reason.reason!.name![props.profile && props.profile.language ? props.profile.language : 'da'],
				value: reason.reason!.id!,
			};
		}

		setLocationStrings(sow, props, workListItem);

		workListData.push(workListItem);
	}
	return workListData;
}

export function getReasons(tableItem: DepartureListTableItem, props: DepartureListProps): Option[] {
	if (!tableItem.selectedDepartureType.value) {
		return [];
	}

	let reasons: IReasonDto[];

	if (DepartureTypes.IsDeathType(tableItem.selectedDepartureType.value)) {
		reasons = props.reasons.filter(reason => reason.reasonSettings && reason.reasonSettings.stemAnimalDead);
	} else {
		reasons = props.reasons.filter(reason => reason.reasonSettings && reason.reasonSettings.stemAnimalDeparture);
	}

	reasons = sortReasons(reasons);

	let reasonsForSelect: Option[] = reasons.map(reason => {
		return {
			label: reason.reason!.name![props.profile && props.profile.language ? props.profile.language : 'da'],
			value: reason.reason!.id!,
		};
	});
	reasonsForSelect.unshift(DefaultDepartureReasonOption);
	return reasonsForSelect;

	function sortReasons(reasons: IReasonDto[]) {
		reasons = reasons.sort((reasonA, reasonB) => {
			return reasonA!.reason!.priorityCode! > reasonB!.reason!.priorityCode! ? 1 : -1;
		});

		return reasons;
	}
}

export function setLocationStrings(
	sow: IStemAnimal,
	props: DepartureListProps,
	DepartureListItem: DepartureListTableItem,
) {
	const pen = getStemAnimalPen(sow, props.moveEvents, props.locations.pens);
	if (pen) {
		const section = props.locations.sections.find(x => x.id === pen!.sectionId);
		if (section) {
			DepartureListItem.pen = section && section.usePens ? pen!.name : '';
			DepartureListItem.penOrder = section && section.usePens ? pen!.order : 0;
			const building = props.locations.buildings.find(x => x.id === pen!.buildingId);
			if (building) {
				DepartureListItem.location =
					(building.name ? building.name : '') +
					' - ' +
					(building && building.useSections ? section!.name : '');
				DepartureListItem.sectionOrder = building && building.useSections ? section!.order : 0;
			}
		}
	}
}
