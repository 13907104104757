import { Action } from 'redux';
import { IProcessEquipment, ProcessEquipment } from 'shared/api/api';
import { mergeArrays, removeValueFromArray, upsertValueInArray } from 'shared/helpers/reducer-helpers';
import { SyncableInitialState } from 'shared/state/models/syncable';
import { isType } from 'typescript-fsa';
import { siteChange } from '../profile/actions';
import * as actions from './actions';
import { getSyncData, saveProcessEquipment } from './actions';
import { ProcessEquipmentState } from './types';
import { SharedAppState } from 'shared/state/store.shared';
import { getSyncModelData } from '../sync/actions';

export const initialState: ProcessEquipmentState = {
	...SyncableInitialState,
};

const processEquipmentReducer = (
	state: ProcessEquipmentState = initialState,
	action: Action,
): ProcessEquipmentState => {
	if (isType(action, siteChange.done)) {
		state = initialState;
	}

	if (isType(action, getSyncData.started)) {
		state = { ...state, syncInProgress: true };
	}

	if (isType(action, getSyncModelData.done)) {
		if (action.payload.params.siteId === action.payload.params.activeSiteId) {
			if (action.payload.result.processEquipments && action.payload.result.processEquipments.datas!.length) {
				state = {
					...state,
					entities: mergeArrays(state.entities, action.payload.result.processEquipments.datas!),
					lastSyncDate: action.payload.result.processEquipments.syncTime!,
				};
			}

			state = {
				...state,

				syncInProgress: false,
			};
		}
	}

	if (isType(action, getSyncData.done)) {
		if (action.payload.params.siteId === action.payload.params.activeSiteId) {
			if (action.payload.result.datas!.length) {
				state = {
					...state,
					entities: mergeArrays(state.entities, action.payload.result.datas!),
				};
			}

			state = {
				...state,
				lastSyncDate: action.payload.result.syncTime!,
				syncInProgress: false,
			};
		}
	}

	if (isType(action, saveProcessEquipment.started)) {
		// state = { ...state, updates: upsertValueInArray(state.updates, action.payload), saveSyncInProgress: true };
		// state = { ...state, entities: upsertValueInArray(state.entities, action.payload) };
	}

	if (isType(action, saveProcessEquipment.failed)) {
		if (action.payload.error.code === 500) {
			if (action.payload.error.prevEntity) {
				state = { ...state, entities: upsertValueInArray(state.entities, action.payload.error.prevEntity) };
			} else {
				state = { ...state, entities: removeValueFromArray(state.entities, action.payload.params.id!) };
			}
			state = { ...state, updates: removeValueFromArray(state.updates, action.payload.params.id!) };
		}

		state = { ...state, saveSyncInProgress: false };
		return state;
	}

	if (isType(action, saveProcessEquipment.done)) {
		state = { ...state, updates: removeValueFromArray(state.updates, action.payload.result) };
		state = { ...state, saveSyncInProgress: false };
		state = { ...state, entities: upsertValueInArray(state.entities, action.payload.params) };
		return state;
	}

	if (isType(action, siteChange.done)) {
		state = initialState;
	}

	if (isType(action, actions.removeProcessEquipment.started)) {
		return { ...state, entities: removeValueFromArray(state.entities, action.payload) };
	}

	if (isType(action, actions.removeProcessEquipment.done)) {
		return { ...state, updates: removeValueFromArray(state.updates, action.payload.params) };
	}

	//Ensure Date objects are in fact date and not strings - redux-persist serializes dates to string but doesn't deserialize to dates again
	if (action.type === 'persist/REHYDRATE') {
		let a = (action as any) as { payload: SharedAppState; key: string };

		if (a.key === 'root') {
			let entities = new Array<ProcessEquipment>();
			let updates = new Array<ProcessEquipment>();
			let lastSyncDate = SyncableInitialState.lastSyncDate;
			if (a.payload && a.payload.processEquipments && a.payload.processEquipments.entities) {
				entities = a.payload.processEquipments.entities.map((processEquipment: IProcessEquipment) => {
					let newProcessEquipment = ProcessEquipment.fromJS({});
					newProcessEquipment.init(processEquipment);
					return newProcessEquipment;
				});
			}

			if (a.payload && a.payload.processEquipments && a.payload.processEquipments.updates) {
				updates = a.payload.processEquipments.updates.map((processEquipment: IProcessEquipment) => {
					let newProcessEquipment = ProcessEquipment.fromJS({});
					newProcessEquipment.init(processEquipment);
					return newProcessEquipment;
				});
			}

			if (a.payload && a.payload.processEquipments && a.payload.processEquipments.lastSyncDate) {
				lastSyncDate = new Date(a.payload.processEquipments.lastSyncDate);
			}

			state = { ...state, entities, updates, lastSyncDate };
		}
	}

	return state;
};

export default processEquipmentReducer;
