import { SkioldOneClient } from 'shared/api/api';
import { SkioldDigitalApiBaseUrl } from 'shared/constants';
import { AnalysisMatingPregnancy } from './types';
export enum AnalysisModalMode {
	ResultPerLitter,
	YoungFemaleAge,
	MatingPregnancy,
}

export function GetAnalysisResultAnalysisMatingsTable(
	siteId: string,
	value: string,
	mode: AnalysisModalMode,
	dateFrom?: Date,
	dateTo?: Date,
) {
	return new SkioldOneClient(SkioldDigitalApiBaseUrl).analysis_ResultAnalysisMatingsTable(
		siteId,
		dateFrom,
		dateTo,
		value,
		AnalysisModalMode[mode],
	);
}

export function GetElaborationAnalysisMatingPregnancy(siteId: string, dateFrom?: Date, dateTo?: Date) {
	return new SkioldOneClient(SkioldDigitalApiBaseUrl).analysis_GetElaborationAnalysisMatingPregnancy(
		siteId,
		dateFrom,
		dateTo,
	);
}

export function GetAnalysisResultAnalysisFarrowingsTable(
	siteId: string,
	value: string,
	mode: AnalysisModalMode,
	dateFrom?: Date,
	dateTo?: Date,
) {
	return new SkioldOneClient(SkioldDigitalApiBaseUrl).analysis_ResultAnalysisFarrowingsTable(
		siteId,
		dateFrom,
		dateTo,
		value,
		AnalysisModalMode[mode],
	);
}

export function GetAnalysisResultAnalysisWeaningsTable(
	siteId: string,
	value: string,
	mode: AnalysisModalMode,
	dateFrom?: Date,
	dateTo?: Date,
) {
	return new SkioldOneClient(SkioldDigitalApiBaseUrl).analysis_ResultAnalysisWeaningsTable(
		siteId,
		dateFrom,
		dateTo,
		value,
		AnalysisModalMode[mode],
	);
}

export function GetAnalysisMatingPregnancy(siteId: string, fetchDataValue: string, dateFrom?: Date, dateTo?: Date) {
	if (fetchDataValue === AnalysisMatingPregnancy[AnalysisMatingPregnancy.WeaningToMating]) {
		return new SkioldOneClient(SkioldDigitalApiBaseUrl).analysis_ElaborationMatingPregnancyWeaningToMating(
			siteId,
			dateFrom,
			dateTo,
		);
	} else if (fetchDataValue === AnalysisMatingPregnancy[AnalysisMatingPregnancy.MatingToNonPregnant]) {
		return new SkioldOneClient(SkioldDigitalApiBaseUrl).analysis_ElaborationMatingPregnancyMatingToNonPregnant(
			siteId,
			dateFrom,
			dateTo,
		);
	} else if (fetchDataValue === AnalysisMatingPregnancy[AnalysisMatingPregnancy.MatingToMating]) {
		return new SkioldOneClient(SkioldDigitalApiBaseUrl).analysis_ElaborationMatingPregnancyMatingToMating(
			siteId,
			dateFrom,
			dateTo,
		);
	} else if (fetchDataValue === AnalysisMatingPregnancy[AnalysisMatingPregnancy.MatingToAbortionAndDeparture]) {
		return new SkioldOneClient(
			SkioldDigitalApiBaseUrl,
		).analysis_ElaborationMatingPregnancyMatingToAbortionAndDeparture(siteId, dateFrom, dateTo);
	}
	return undefined;
}
