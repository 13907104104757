import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IFarrowing, INucleusManagementBreedingSettings, INumberBatch, IStemAnimal } from 'shared/api/api';
import {
	AssignIdItem,
	AssignIdsResponse,
	generateAssignIdItems,
	generateStemAnimalsByAssignId,
	setNextMatingNumber,
} from 'shared/helpers/nucleus-management-helper/nucleus-management-helper';
import { selectNucleusManagementOrAssignIdAccess } from 'shared/state/ducks/site/reducer';
import { selectStemAnimalById } from 'shared/state/ducks/stem-animals/selectors';
import { localized, localizedInterpolation } from 'shared/state/i18n/i18n';
import { SharedAppState } from 'shared/state/store.shared';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';

import DeleteIcon from 'shared/assets/src-assets/png/delete_icon.png';
import { SkioldTouchableOpacity } from 'web/view/components/skiold-components/skiold-touchable-opacity';
import { SkioldImage } from 'web/view/components/utils/svg/skiold-image';
import { SowListConstants } from 'web/view/components/stem-animal/animal-lists/table-constants';
import { DeleteYoungAnimals } from 'shared/state/ducks/stem-animals/operations';
import { ViewWeb } from 'web/view/components/utils/web-view';
import SkioldTableGrid from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { SkioldCheckbox } from 'web/view/components/skiold-components/skiold-checkbox/skiold-checkbox';
import { SkioldIntegerInput } from 'web/view/components/skiold-components/skiold-integer-input/skiold-integer-input';
import './assign-id-num.scss';
import { WhiteText } from 'web/view/components/Text/white-text';
import { selectIdNumbers } from 'shared/state/ducks/stem-animals/reducer';
import { showAlert, showDefaultAlert } from 'web/view/components/skiold-alert/skiold-alert';
interface Props {
	closeModal: (response: AssignIdsResponse) => void;
	dadId?: string;
	momId?: string;
	farrowing: IFarrowing;
	selectedYoungAnimals: IStemAnimal[] | undefined;
	alreadySavedYoungAnimals: IStemAnimal[] | undefined;
	deleteYoungAnimalCallback: (stemId: string) => void;
}

export const AssignIdNum: FC<Props> = React.memo(
	({
		momId,
		dadId,
		farrowing,
		closeModal,
		selectedYoungAnimals,
		alreadySavedYoungAnimals,
		deleteYoungAnimalCallback,
	}) => {
		const dispatch = useDispatch();
		// Fetch data from redux
		const { breedingSettings } = useSelector((state: SharedAppState) => state.nucleusManagementBreedingSettings);
		const breedTableItems = useSelector((state: SharedAppState) => state.breedTableItems.entities);
		const allIdNumbers = useSelector(selectIdNumbers);
		const generalSetting = useSelector((state: SharedAppState) => state.generalSettings.entity);
		const nucleusLicence = useSelector(selectNucleusManagementOrAssignIdAccess);
		const dad = useSelector((state: SharedAppState) =>
			selectStemAnimalById(
				state.stemAnimals.entities,
				state.stemAnimals.departuredAnimals as IStemAnimal[],
				dadId,
			),
		);
		const mom = useSelector((state: SharedAppState) =>
			selectStemAnimalById(
				state.stemAnimals.entities,
				state.stemAnimals.departuredAnimals as IStemAnimal[],
				momId,
			),
		);

		const [minMatingNumber, setMinMatingNumber] = useState<number | undefined>(0);
		const [breedSettingToUseState, setBreedSettingToUseState] = useState<
			INucleusManagementBreedingSettings | undefined
		>();
		const [numberBatch, setNumberBatch] = useState<INumberBatch | undefined>();
		// Setup data
		useEffect(() => {
			if (mom && dad && breedingSettings) {
				const { rows, breedSettingToUse, numberBatchToUse } = generateAssignIdItems(
					breedingSettings,
					mom,
					dad,
					farrowing,
					breedTableItems,
					nucleusLicence,
					selectedYoungAnimals,
					alreadySavedYoungAnimals,
				);

				setMinMatingNumber(Number(numberBatchToUse?.nextMatingNumber));
				setBreedSettingToUseState(breedSettingToUse);
				setNumberBatch(numberBatchToUse);
				setRows(rows);
			}
		}, [
			breedingSettings,
			mom,
			dad,
			farrowing,
			breedTableItems,
			generalSetting,
			alreadySavedYoungAnimals,
			selectedYoungAnimals,
			nucleusLicence,
		]);
		const deleteYoungAnimal = (item: AssignIdItem) => {
			dispatch(DeleteYoungAnimals([item.id]));
			deleteYoungAnimalCallback(item.id);
		};

		const [rows, setRows] = useState<AssignIdItem[]>();

		const isCheckedClicked = (checked: boolean, item: AssignIdItem) => {
			if (rows) {
				const index = rows?.findIndex(e => e.id === item.id);
				if (index > -1 && !rows[index].alreadySaved) {
					let newRows = [...rows];
					newRows[index].isChecked = checked;
					setRows(newRows);
				}
			}
		};

		const matingNumberChanged = (matingNumber: number | undefined, item: AssignIdItem) => {
			if (rows) {
				const index = rows?.findIndex(e => e.id === item.id);
				if (index > -1) {
					let newRows = [...rows];
					newRows[index].matingNumber2 = matingNumber;
					setRows(newRows);
				}
			}
		};

		const allIsCheckedClicked = useCallback(() => {
			if (rows) {
				const isChecked = !rows.every(e => e.isChecked);

				const newRows = rows.map(
					(row: AssignIdItem) => ({ ...row, isChecked: row.alreadySaved ?? isChecked } as AssignIdItem),
				);
				setRows(newRows);
			}
		}, [rows]);

		const columns = [
			{
				name: 'isChecked',
				title: ' ',
				filter: (item: AssignIdItem) => (
					<ViewWeb className="checkbox-view-filter-style">
						<SkioldCheckbox
							isChecked={rows && rows.every(e => e.isChecked)}
							className="checkbox-style-filter"
							onClick={allIsCheckedClicked}
						/>
					</ViewWeb>
				),
				getCellValue: (item: AssignIdItem) => (
					<ViewWeb>
						<SkioldCheckbox isChecked={item.isChecked} onClick={isCheckedClicked} itemFromParent={item} />
					</ViewWeb>
				),
			},
			{
				name: 'breed',
				title: localized('Breed'),
			},
			{
				name: 'gender',
				title: localized('Gender'),
			},
			{
				name: 'danishGeneticsLetter',
				title: localized('Letter'),
			},
			{
				name: 'breedNumber',
				title: localized('BreedingNr'),
			},
			{
				name: 'matingNumber1',
				title: localized('FirstMatingNr'),
			},
			{
				name: 'matingNumber2',
				title: localized('SecondMatingNr'),
				getCellValue: (item: AssignIdItem) => {
					return (
						<SkioldIntegerInput
							disabled={item.alreadySaved}
							text={item.matingNumber2}
							itemFromParent={item}
							onChangeNumber={matingNumberChanged}
							className="text-align-center"
							maxNumber={99999}
						/>
					);
				},
			},
			{
				name: 'delete',
				title: ' ',
				getCellValue: (item: AssignIdItem) => {
					return item.alreadySaved ? (
						<SkioldTouchableOpacity onPress={deleteYoungAnimal} itemFromParent={item}>
							<SkioldImage
								width={SowListConstants.iconSVGWidth}
								height={SowListConstants.iconSVGWidth}
								imageData={DeleteIcon}
							/>
						</SkioldTouchableOpacity>
					) : (
						<ViewWeb />
					);
				},
			},
		];
		const columnExtensions = [
			{
				columnName: 'isChecked',
				width: 50,
			},
			{
				columnName: 'breed',
				editingEnabled: false,
				width: 100,
			},
			{
				columnName: 'gender',
				editingEnabled: false,
				width: 100,
			},
			{
				columnName: 'danishGeneticsLetter',
				width: 100,
			},
			{
				columnName: 'breedNumber',
				width: 100,
			},
			{
				columnName: 'matingNumber1',
				width: 100,
			},
			{
				columnName: 'matingNumber2',
				width: 100,
			},
			{
				columnName: 'delete',
				width: 40,
				editingEnabled: false,
			},
		];

		const save = useCallback(() => {
			// Don't allow to save if no rows are checked
			if (!rows || !rows.find(assignRow => assignRow.isChecked)) {
				showAlert(localized('ValidateNoAssignedRegistrationsCheck'));
				return;
			}

			if (rows && mom && dad && breedSettingToUseState) {
				const youngAnimals = generateStemAnimalsByAssignId(
					rows,
					mom,
					dad,
					breedSettingToUseState,
					farrowing,
					generalSetting,
				);
				const newIdNumbers = youngAnimals.map(y => y.idNumber);
				const IdNumbersThatExist = allIdNumbers.filter(idnumber => newIdNumbers.includes(idnumber));
				if (IdNumbersThatExist.length > 0) {
					showDefaultAlert(localizedInterpolation('ValidateExistingIdNumbers', IdNumbersThatExist.join(',')));
					return;
				}

				closeModal({
					youngAnimals,
					breedSettingToUse: setNextMatingNumber(
						breedSettingToUseState,
						numberBatch,
						youngAnimals.map(r => r.idNumber),
					),
				} as AssignIdsResponse);
			}
		}, [rows, mom, dad, breedSettingToUseState, farrowing, closeModal, generalSetting, numberBatch, allIdNumbers]);

		if (!rows || rows.length < 1) {
			return null;
		}

		const minChanged = (value: number | undefined) => {
			setMinMatingNumber(value);
		};

		const applyMin = () => {
			if (mom && dad && breedingSettings) {
				const { rows, breedSettingToUse, numberBatchToUse } = generateAssignIdItems(
					breedingSettings,
					mom,
					dad,
					farrowing,
					breedTableItems,
					nucleusLicence,
					selectedYoungAnimals,
					alreadySavedYoungAnimals,
					minMatingNumber,
				);
				setBreedSettingToUseState(breedSettingToUse);
				setNumberBatch(numberBatchToUse);
				setRows(rows);
			}
		};

		return (
			<div className="basic-padding assign-id-num">
				<div className="flex-row-space-between">
					<div className="input-container">
						<WhiteText>{localized('CurrentSecondMatingNumber')}:</WhiteText>
						<SkioldIntegerInput
							text={minMatingNumber}
							onChangeNumber={minChanged}
							className="min-mating-number-input"
							onBlur={applyMin}
							maxNumber={99999}
						/>
					</div>
					<div className="flex-row">
						<SkioldButton onPress={save} title={localized('Assign')} />
						<SkioldButton onPress={closeModal} title={localized('Close')} theme={'grey'} />
					</div>
				</div>

				<div className="basic-padding">
					<SkioldTableGrid
						tableKey={'assign-id2'}
						ignoreSetCount={true}
						ColumnExtensions={columnExtensions}
						columns={columns}
						data={rows}
					/>
				</div>
			</div>
		);
	},
);

AssignIdNum.defaultProps = {
	alreadySavedYoungAnimals: [],
	selectedYoungAnimals: [],
};
