import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { isAuthenticated, isUserAdmin } from 'shared/helpers/authentication-helper.shared';
import { SetIsAdmin, setIsAuth } from 'shared/state/ducks/authentication/operations';
import { WebAppState } from 'web/state/store.web';
import { Spinner } from '../spinner/spinner';

interface PropsFromParent {
	children: React.ReactNode;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		isAuthenticated: state.authentication.isAuthenticated,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
	setIsAuth: (isAuth: boolean | undefined) => setIsAuth(isAuth)(dispatch),
	setIsAdmin: (isAdmin: boolean) => SetIsAdmin(isAdmin)(dispatch),
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

class AuthInitializer extends React.PureComponent<Props> {
	public render() {
		this.init();

		if (this.props.isAuthenticated !== undefined) {
			return this.props.children;
		}

		return <Spinner />;
	}

	private async init() {
		let isAuth = await isAuthenticated();
		this.props.setIsAdmin(await isUserAdmin());
		this.props.setIsAuth(isAuth);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthInitializer);
