import { localized } from '../i18n/i18n';
import { AnimalType } from 'shared/api/api';

export class DepartureTypes {
	public static readonly departureTypeSold = 'departureTypeSold';
	public static readonly departureTypeDead = 'departureTypeDead';
	public static readonly departureTypeKilled = 'departureTypeKilled';
	public static readonly departureTypePutDown = 'departureTypePutDown';
	public static readonly departureTypeToBeKilled = 'departureTypeToBeKilled';
	public static readonly departureTypeShouldDeparture = 'departureTypeShouldDeparture';

	public static IsDeathType(departureType: string) {
		if (
			departureType === DepartureTypes.departureTypeDead ||
			departureType === DepartureTypes.departureTypePutDown
		) {
			return true;
		}

		return false;
	}

	public static IsDepartedType(departureType: string) {
		if (
			departureType === DepartureTypes.departureTypeSold ||
			departureType === DepartureTypes.departureTypeKilled ||
			departureType === DepartureTypes.departureTypeShouldDeparture
		) {
			return true;
		}

		return false;
	}

	public static IsSoldOrSlaughted(departureType: string | undefined) {
		if (
			(departureType && departureType === DepartureTypes.departureTypeSold) ||
			departureType === DepartureTypes.departureTypeKilled
		) {
			return true;
		}

		return false;
	}

	public static GetDepartureTypesOptions = (productionType: AnimalType) => {
		let options = [
			{
				label: localized(DepartureTypes.departureTypePutDown),
				value: DepartureTypes.departureTypePutDown,
			},
			{
				label: localized(DepartureTypes.departureTypeDead),
				value: DepartureTypes.departureTypeDead,
			},
			{
				label: localized(DepartureTypes.departureTypeSold),
				value: DepartureTypes.departureTypeSold,
			},
		];
		if (productionType !== AnimalType.Weaner) {
			options.push({
				label: localized(DepartureTypes.departureTypeKilled),
				value: DepartureTypes.departureTypeKilled,
			});
		}

		return options;
	};
}
