import { DashboardElaborationTableItem, GrowthPigsDashboardDto } from 'shared/api/api';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import { actionCreatorFactory } from 'typescript-fsa';
import { TableKeyValue } from 'web/view/components/skiold-components/skiold-table/skiold-table-grouped-grid/skiold-table-grouped-grid-helper';
import * as types from './types';

const actionCreator = actionCreatorFactory(GenActionFactoryName('dashboardGrowthPigsOverview'));

export const getDashboardGrowthPigsOverviewTable = actionCreator.async<
	{ siteId: string; showSpinner: boolean },
	GrowthPigsDashboardDto[],
	{ code: number; message: string }
>(types.GET_GROWTH_PIGS_DASHBOARD_OVERVIEW_TABLE);

export const setSummaryItems = actionCreator<{ key: string; data: TableKeyValue[] }>(types.SET_SUMMARY_ITEM);
export const setDashboardItems = actionCreator<{ key: string; data: DashboardElaborationTableItem[] }>(
	types.SET_DASHBOARD_ITEMS,
);
