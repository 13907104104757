import ObjectID from 'bson-objectid';
import moment from 'moment';
import { Dispatch } from 'redux';
import {
	GrowthPigAdjustFeedUpdate,
	IGrowthPigAdjustFeedUpdate,
	IValveGroupUpdate,
	RegistrationErrorDto,
	SkioldOneClient,
	ValveGroupUpdate,
} from 'shared/api/api';
import { SkioldDigitalApiBaseUrl } from 'shared/constants';
import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import { StoreContainer } from 'shared/state/store-container';
import * as Action from './actions';
import { FullSyncNumbers } from './types';

export function GetSyncData() {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;
	const lastSyncDate = state.adjustFeed.lastSyncDate;
	if (state.adjustFeed.syncInProgress) {
		return (dispatch: Dispatch<any>) => {
			return Promise.resolve();
		};
	}
	return AsyncOperationBuilder2(Action.getSyncData, client => client.valveGroups_SyncGET(siteId, lastSyncDate), {
		siteId,
		lastSyncDate,
	});
}

export function SetFullSyncSpinner(show: boolean) {
	return async (dispatch: Dispatch<any>) => {
		dispatch(Action.setFullSyncSpinner.done({ result: show }));
	};
}

export function fetchNewestDataFromDistriwin(processEquipmentId: string) {
	return async (dispatch: Dispatch<any>) => {
		dispatch(Action.setFullSyncSpinner.done({ result: true }));
		let promise = new SkioldOneClient(SkioldDigitalApiBaseUrl)
			.valveGroups_DistriwinFullSync(processEquipmentId)
			.then(async keyValues => {
				const total = { ...keyValues };
				let numberOfUpdated = 0;
				let fullSyncNumbers: FullSyncNumbers = {
					fullSyncTotal: Object.keys(total).length,
					fullSyncCurrent: numberOfUpdated,
				};
				dispatch(
					Action.setFullSyncNumbers.done({
						result: { ...fullSyncNumbers, fullSyncCurrent: numberOfUpdated },
					}),
				);

				let spinnerIsActive = true;
				let prevNumberOfUpdated = numberOfUpdated;

				const IntervalFunction = () => {
					if (prevNumberOfUpdated === numberOfUpdated && spinnerIsActive) {
						dispatch(Action.fullSyncFailed.started());
						clearInterval(counter);
					}
				};
				// Checks whether or not there have beens updates the last minute
				let counter = setInterval(IntervalFunction, 60000);

				// run until process is cancelled - Manual cancel , by timer or finished full sync
				while (spinnerIsActive && fullSyncNumbers.fullSyncTotal > numberOfUpdated) {
					const state = StoreContainer.getState();
					spinnerIsActive = state.adjustFeed.showFullSyncSpinner;

					// Fetch data
					if (spinnerIsActive) {
						numberOfUpdated = await new SkioldOneClient(
							SkioldDigitalApiBaseUrl,
						).valveGroups_GetUpdatedValveGroups(total);
						// reset timer if new data was fetched
						if (numberOfUpdated !== prevNumberOfUpdated) {
							prevNumberOfUpdated = numberOfUpdated;
							clearInterval(counter);
							counter = setInterval(IntervalFunction, 60000);
						}
						dispatch(
							Action.setFullSyncNumbers.done({
								result: { ...fullSyncNumbers, fullSyncCurrent: numberOfUpdated },
							}),
						);
						await new Promise(resolve => {
							setTimeout(() => resolve('result'), 5000);
						});
					}
				}

				if (spinnerIsActive) {
					dispatch(Action.setFullSyncSpinner.done({ result: false }));
				}
				clearInterval(counter);
			});

		return promise;
	};
}

export function UpdateValveGroup(upd: IValveGroupUpdate) {
	upd.modifiedOn = new Date();
	return (dispatch: Dispatch<any>) => {
		dispatch(Action.updateValveGroup(upd));
	};
}

export function SaveSyncData() {
	const state = StoreContainer.getState();
	const updates = state.adjustFeed.updates;
	let promises = new Array<Promise<void>>();
	let showTTLError = false;
	return async (dispatch: Dispatch<any>) => {
		if (state.adjustFeed.saveSyncInProgress) {
			return Promise.resolve();
		}
		updates.forEach(update => {
			if (update.modifiedOn && !moment(update.modifiedOn).isSame(moment(new Date()), 'day')) {
				promises.push(CreateRegistrationError(update)(dispatch));
				showTTLError = true;
			} else {
				promises.push(UpdateValveGroupApi(update)(dispatch));
			}
		});

		return await Promise.all(promises);
	};
}

export function UpdateValveGroupApi(upd: IValveGroupUpdate) {
	const state = StoreContainer.getState();
	const prevEntity = state.adjustFeed.previousEntities.find(group => group.id === upd.id);

	if (!upd.id) {
		upd.id = new ObjectID().toHexString();
	}

	return AsyncOperationBuilder2(
		Action.updateValveGroupAPI,
		client => client.valveGroups_UpdateValveGroup(new ValveGroupUpdate(upd)),
		new ValveGroupUpdate(upd),
		prevEntity,
	);
}

function CreateRegistrationError(upd: IValveGroupUpdate) {
	const state = StoreContainer.getState();
	const prevEntity = state.adjustFeed.previousEntities.find(group => group.id === upd.id);

	return async (dispatch: Dispatch<any>) => {
		let registrationError = new RegistrationErrorDto();
		let valveGroup = new ValveGroupUpdate();
		valveGroup.init(upd);
		registrationError.model = valveGroup;
		registrationError.error = 'Data exceeded TTL';

		new SkioldOneClient(SkioldDigitalApiBaseUrl)
			.registrationError_Post(registrationError, valveGroup.constructor.name)
			.then(() => {
				dispatch(
					Action.updateValveGroupAPI.failed({
						params: upd,
						error: { code: 500, message: 'Data exceeded TTL', prevEntity },
					}),
				);
			});
	};
}

export function UpdateValveGroupGrowthPig(siteId: string | undefined, upd: IGrowthPigAdjustFeedUpdate[]) {
	const mapped = upd.map(x => new GrowthPigAdjustFeedUpdate(x));
	return AsyncOperationBuilder2(
		Action.saveGrowthPigAdjustFeed,
		client => client.valveGroups_SaveGrowthPigAdjustFeed(siteId, mapped),
		mapped,
		null,
	);
}
