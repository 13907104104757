
import { ILardScanningEvent, SyncDataLardScanningEvent } from 'shared/api/api';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import actionCreatorFactory from 'typescript-fsa';
import * as types from './types';

const actionCreator = actionCreatorFactory(GenActionFactoryName('lardScanningEvents'));

export const saveLardScanningEvent= actionCreator.async<
    ILardScanningEvent,
    string,
    { code: number; message: string; prevEntity: ILardScanningEvent | undefined }
>(types.SAVE_LARD_EVENT);

export const removeLardScanningEvent = actionCreator.async<string, string>(types.REMOVE_LARD_EVENT);
export const getSyncData = actionCreator.async<{ siteId: string; lastSyncDate: Date; activeSiteId?: string  }, SyncDataLardScanningEvent>(types.SYNC_LARDS_EVENTS);

export const getDeparturedLardEvents = actionCreator.async<{siteId: string; activeSiteId?: string}, ILardScanningEvent[]>(
    types.GET_DEPARTURED_LARD_EVENTS
);