import React from 'react';
import {InputTextarea} from 'primereact/inputtextarea';
import './skiold-text-area.scss';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';

export interface TextPropsFromParent {
	onChangeText?: (newText: string) => void;
	label?: string;
	text?: string;
	style?: any;
	theme?: 'light' | 'dark';
	suffix?: string;
	editable?: boolean;
	className?: string;
    numberOfLines?: number;
    numberOfColumns?:number;
	onBlur?: () => void;
	selectTextOnFocus?: boolean;
	autoFocus?: boolean;
    maxLength?: number;
    id?: string;
}

interface State {
	text?: string;
}

// Component representing a Skiold Digital textfield throughout the app/website
export class SkioldTextArea extends React.PureComponent<TextPropsFromParent, State> {
	public static defaultProps: Partial<TextPropsFromParent> = {
		theme: 'light',
        className: '',
        id:'',
        numberOfColumns: 10,
        numberOfLines: 5
	};

	public static getDerivedStateFromProps(nextProps: TextPropsFromParent) {
		return {
			text: nextProps.text ? nextProps.text!.toString() : ''
		};
	}

	public input: any; //Any because of missing .focus on TextField typings

	constructor(props: TextPropsFromParent) {
		super(props);
		this.state = {
			text: props.text ? props.text : ''
		};
	}

	public focus() {
		if (this.input) {
			this.input.focus();
		}
	}

	public render() {
		return (
            <ViewWeb>
                <TextWeb  className={`${this.props.className}`}>{this.props.label}</TextWeb>
                 <InputTextarea className={`skiold-text-area ${this.props.className}`} onBlur={this.props.onBlur} value={this.state.text} onChange={this.textChanged} rows={this.props.numberOfLines} cols={this.props.numberOfColumns}></InputTextarea>
            </ViewWeb>
           
			// <input
            //     id={this.props.id}
			// 	className={`skiold-input ${this.props.className}`}
			// 	value={this.state.text}
			// 	onChange={this.textChanged}
			// 	maxLength={this.props.maxLength}
			// 	// inputRef={(ref: any) => (this.input = ref)}
			// 	disabled={!this.props.editable}
			// 	// selectTextOnFocus={this.props.selectTextOnFocus}
			// 	autoFocus={this.props.autoFocus}
			// 	type="text"
			// 	onBlur={this.props.onBlur}
			// />
		);
	}

	private textChanged = (event: React.FormEvent<HTMLTextAreaElement> | undefined) => {
		const newText = event ? event.currentTarget.value : '';
        this.setState({ text: newText });
        if(this.props.onChangeText){
            this.props.onChangeText(newText);
        }
	
	};
}
