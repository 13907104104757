import { Sorting } from '@devexpress/dx-react-grid';
import moment from 'moment';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Building, Pen, Section } from 'shared/api/api';
import { getDateString } from 'shared/helpers/date-helpers';
import { getDiagnoseName } from 'shared/helpers/diagnose-helper/diagnose-helper';
import { rangeFilterMethodGrid } from 'shared/helpers/general-helpers';
import {
	memoizeHashmapDiagnose,
	memoizeHashmapDrugTypes,
	memoizeHashmapLocation,
	memoizeHashmapTreatmentDefinitions,
	memoizeHashmapTreatmentPlan,
} from 'shared/helpers/memoize-getters/memoize-getters';
import { NaturalSortDates } from 'shared/helpers/natural-sort';
import { GetTreatmentPlansForLog } from 'shared/state/ducks/treatment-plan/operations';
import { GetCompletedTreatmentsInPeriod } from 'shared/state/ducks/treatment/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { useGpeDashboard } from 'web/view/pages/dashboard-overview-growth-pigs/dashboard-overview-growth-pigs';
import { SkioldButton } from '../skiold-components/skiold-button/skiold-button';
import { SkioldModal } from '../skiold-components/skiold-modal/skiold-modal';
import SkioldTableGrid from '../skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { SowListConstants } from '../stem-animal/animal-lists/table-constants';
import { WhiteText } from '../Text/white-text';
import { getDrugAmountCell } from '../treatments/treatment-log/drug-amount-text';
import { getDrugNameCell } from '../treatments/treatment-log/drug-name-text';
import { TreatmentTableModel } from '../treatments/treatment-plan/treatment-table-model';
import { Heading } from '../utils/heading';
import { ViewWeb } from '../utils/web-view';
import { generateLast7DaysTreated } from './dash-growth-pigs-helper';

interface PropsFromParent {}
const defaultSorting: Sorting[] = [{ columnName: 'executedDate', direction: 'desc' }];
export const TreatedLast7Days: FC<PropsFromParent> = React.memo(() => {
	const dispatch = useDispatch();

	const language = useSelector((state: WebAppState) => state.profile.active && state.profile.active.language);
	const siteId = useSelector((state: WebAppState) => state.profile.active && state.profile.active.siteId);
	const hashmapLocation = useSelector((state: WebAppState) =>
		memoizeHashmapLocation(state.locations.buildings, state.locations.sections, state.locations.pens),
	);
	const treatmentPlans = useSelector((state: WebAppState) =>
		memoizeHashmapTreatmentPlan(state.treatmentPlans.treatmentPlansForLog),
	);
	const treatments = useSelector((state: WebAppState) => state.treatments.treatmentsForLog);
	const currentState = useSelector((state: WebAppState) => state);
	const diagnoses = useSelector((state: WebAppState) => memoizeHashmapDiagnose(state.diagnose.entities));
	const treatmentDefinitions = useSelector((state: WebAppState) =>
		memoizeHashmapTreatmentDefinitions(state.treatmentDefinitions.entities),
	);
	const drugTypes = useSelector((state: WebAppState) => memoizeHashmapDrugTypes(state.drugType.entities));

	const { isTreatedOpen, closeTreatedModal, sectionId, penId, productionType } = useGpeDashboard();

	const toDate = useMemo(() => new Date().endOfDayTime(), []);
	const fromDate = useMemo(() => moment().subtract(6, 'days').toDate().withoutTime(), []);
	const [data, setData] = useState<TreatmentTableModel[]>([] as TreatmentTableModel[]);
	const [locationString, setLocationString] = useState<string>('');
	const [penString, setPenString] = useState<string>('');

	// Component did mount
	useEffect(() => {
		if (siteId) {
			GetTreatmentPlansForLog(siteId, fromDate, toDate)(dispatch);
			GetCompletedTreatmentsInPeriod(siteId, fromDate, toDate)(dispatch);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Generate data on growthPigEvents change
	useEffect(() => {
		setData(
			generateLast7DaysTreated(
				fromDate,
				toDate,
				sectionId,
				penId,
				treatments,
				treatmentPlans,
				treatmentDefinitions,
				diagnoses,
				drugTypes,
				productionType,
			),
		);
	}, [
		treatments,
		diagnoses,
		treatmentDefinitions,
		drugTypes,
		treatmentPlans,
		sectionId,
		penId,
		toDate,
		fromDate,
		productionType,
	]);

	// Set location string
	useEffect(() => {
		if (sectionId) {
			let section = hashmapLocation[sectionId] as Section;
			if (section.buildingId) {
				let building = hashmapLocation[section.buildingId] as Building;
				setLocationString(`${building.name} - ${section.name}`);
			}
		}
	}, [sectionId, hashmapLocation]);

	// Set pen string
	useEffect(() => {
		if (penId) {
			let pen = hashmapLocation[penId] as Pen;
			setPenString(`${localized('pen')}: ${pen.name}`);
		} else {
			setPenString('');
		}
	}, [penId, hashmapLocation]);

	const getDiagnoseCell = (t: TreatmentTableModel) => getDiagnoseName(t.diagnose, language);

	const getTreatmentNumberCell = (t: TreatmentTableModel) =>
		(t.treatmentNumber ? t.treatmentNumber : '')
			.toString()
			.concat('/')
			.concat(t.totalTreatmentNumber ? t.totalTreatmentNumber.toString() : '');

	const getDateCell = (item: TreatmentTableModel) => getDateString(item.executedDate);

	const generateColumns = () => [
		{
			name: 'executedDate',
			title: localized('Date'),
			sortFunction: NaturalSortDates,
			getCellValue: getDateCell,
		},
		{
			name: 'diagnose',
			title: localized('Diagnose'),
			getCellValue: getDiagnoseCell,
		},
		{
			name: 'drugAmount',
			title: localized('DrugAmountShort'),
			getCellValue: getDrugAmountCell,
		},
		{
			name: 'drug',
			title: localized('drug'),
			getCellValue: getDrugNameCell,
		},
		{
			name: 'treatmentNumber',
			title: localized('Treatment'),
			getCellValue: getTreatmentNumberCell,
		},
		{
			filterFunction: rangeFilterMethodGrid,
			name: 'pigAmount',
			title: localized('Count'),
		},
	];

	const generateColumnExtes = [
		{
			columnName: 'executedDate',
			width: SowListConstants.dateWidth,
		},
		{
			columnName: 'diagnose',
			width: 200,
		},
		{
			columnName: 'drugAmount',
			width: 110,
		},
		{
			columnName: 'drug',
			width: 200,
		},
		{
			columnName: 'treatmentNumber',
			width: 120,
		},

		{
			columnName: 'pigAmount',
			width: 70,
		},
	];

	return (
		<SkioldModal
			shouldCloseOnOverlayClick={true}
			padding="0"
			isOpen={isTreatedOpen}
			close={closeTreatedModal}
			justify-content="flex-end"
			max-width="calc(100% - 220px)"
		>
			<Heading text={localized('treatedLast7DaysTitle')} />
			<ViewWeb className="dead-putdown-last-7-days">
				<ViewWeb className="header-container">
					<WhiteText> {locationString}</WhiteText>
					<WhiteText>{penString}</WhiteText>
				</ViewWeb>
				<SkioldTableGrid
					sortHeaderId={defaultSorting}
					tableKey={'TreatedLast7Days'}
					ignoreSetCount={true}
					ColumnExtensions={generateColumnExtes}
					columns={generateColumns()}
					data={data}
				/>
				<SkioldButton className="close-btn" title={localized('Close')} onPress={closeTreatedModal} />
			</ViewWeb>
		</SkioldModal>
	);
});
