import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import * as Action from './actions';
import { SkioldOneClient } from 'shared/api/api';
import { SkioldDigitalApiBaseUrl } from 'shared/constants';

export function GetDashboardOverviewTable(siteId: string, showSpinner: boolean = true) {
	return AsyncOperationBuilder2(
		Action.getDashboardOverviewTable,
		client => client.dashboard_GetDashboardOverviewTable(siteId),
		{ siteId, showSpinner },
	);
}

export function GetDashboardTotalMatingsTable(siteId: string, batchId: string) {
	return new SkioldOneClient(SkioldDigitalApiBaseUrl).dashboard_GetTotalMatingsTable(siteId, batchId);
}
export function GetDashboardDeparturedTotalMatingsTable(siteId: string, batchId: string) {
	return new SkioldOneClient(SkioldDigitalApiBaseUrl).dashboard_GetDeparturedFromBatchTable(siteId, batchId);
}
export function GetDashboardGetAnimalToFarrowingTable(siteId: string, batchId: string) {
	return new SkioldOneClient(SkioldDigitalApiBaseUrl).dashboard_GetAnimalToFarrowingTable(siteId, batchId);
}
export function GetDashboardGetTotalFarrowingTable(siteId: string, batchId: string) {
	return new SkioldOneClient(SkioldDigitalApiBaseUrl).dashboard_GetTotalFarrowingTable(siteId, batchId);
}

export function GetDashboardGetNeedFarrowingTable(siteId: string, batchId: string) {
	return new SkioldOneClient(SkioldDigitalApiBaseUrl).dashboard_GetNeedFarrowingTable(siteId, batchId);
}

export function GetDashboardGetTotalWeaningTable(siteId: string, batchId: string) {
	return new SkioldOneClient(SkioldDigitalApiBaseUrl).dashboard_GetTotalWeaningTable(siteId, batchId);
}

export function GetDashboardGetNeedWeaningTable(siteId: string, batchId: string) {
	return new SkioldOneClient(SkioldDigitalApiBaseUrl).dashboard_GetNeedWeaningTable(siteId, batchId);
}
