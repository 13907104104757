import { Action } from 'redux';
import { IMoveEvent, MoveEvent } from 'shared/api/api';
import {
	mergeArrays,
	mergeArraysReplaceNewer,
	removeMultipleValuesFromArray,
	removeValueFromArray,
	upsertValueInArrayCustomId,
} from 'shared/helpers/reducer-helpers';
import { SyncableInitialState } from 'shared/state/models/syncable';
import { isType } from 'typescript-fsa';
import { siteChange } from '../profile/actions';
import { fetchSingleAnimalInfo, getDeparturedAnimalDataByDate } from '../stem-animals/actions';
import { getSyncModelData } from '../sync/actions';
import * as actions from './actions';
import { getDeparturedMoveEvents, getSyncData, saveMoveEvent } from './actions';
import { MoveEventState } from './types';

export const initialState: MoveEventState = {
	...SyncableInitialState,
	departuredMoveEvents: [],
};

const moveEventReducer = (state: MoveEventState = initialState, action: Action): MoveEventState => {
	if (isType(action, getSyncData.started)) {
		state = { ...state, syncInProgress: true };
	}

	if (isType(action, getSyncModelData.done)) {
		if (action.payload.params.siteId === action.payload.params.activeSiteId) {
			let entities = state.entities;

			if (action.payload.result.moveEvents && action.payload.result.moveEvents.datas!.length) {
				if (
					!action.payload.params.syncParams.moveEvents ||
					action.payload.params.syncParams.moveEvents.getTime() === new Date(-8640000000000000).getTime()
				) {
					entities = mergeArrays(state.entities, action.payload.result.moveEvents.datas!);
				} else {
					entities = mergeArraysReplaceNewer(
						state.entities,
						action.payload.result.moveEvents.datas!,
						(m: IMoveEvent) => m.stemAnimalId!,
						(oldElem: IMoveEvent, newElem: IMoveEvent) => oldElem.moveDate! <= newElem.moveDate!,
					);
				}

				state = { ...state, lastSyncDate: action.payload.result.moveEvents.syncTime! };
			}

			state = {
				...state,

				syncInProgress: false,
				entities,
			};
		} else {
			state = {
				...state,
				syncInProgress: false,
			};
		}
	}

	if (isType(action, getSyncData.done)) {
		if (action.payload.params.siteId === action.payload.params.activeSiteId) {
			let entities = state.entities;

			if (action.payload.result.datas!.length) {
				if (action.payload.params.lastSyncDate.getTime() === new Date(-8640000000000000).getTime()) {
					entities = mergeArrays(state.entities, action.payload.result.datas!);
				} else {
					entities = mergeArraysReplaceNewer(
						state.entities,
						action.payload.result.datas!,
						(m: IMoveEvent) => m.stemAnimalId!,
						(oldElem: IMoveEvent, newElem: IMoveEvent) => oldElem.moveDate! <= newElem.moveDate!,
					);
				}
			}

			state = {
				...state,
				lastSyncDate: action.payload.result.syncTime!,
				syncInProgress: false,
				entities,
			};
		} else {
			state = {
				...state,
				syncInProgress: false,
			};
		}
	}

	if (isType(action, getSyncData.failed)) {
		state = { ...state, syncInProgress: false };
	}

	if (isType(action, saveMoveEvent.started)) {
		if (action.payload) {
			state = {
				...state,
				updates: upsertValueInArrayCustomId(state.updates, action.payload, (m: IMoveEvent) => m.stemAnimalId!),
				saveSyncInProgress: true,
				entities: upsertValueInArrayCustomId(
					state.entities,
					action.payload,
					(m: IMoveEvent) => m.stemAnimalId!,
				),
			};
		}
	}

	if (isType(action, saveMoveEvent.failed)) {
		if (action.payload.error.code === 500 && action.payload.params) {
			if (action.payload.error.prevEntity) {
				state = {
					...state,
					entities: upsertValueInArrayCustomId(
						state.entities,
						action.payload.error.prevEntity,
						(m: IMoveEvent) => m.stemAnimalId!,
					),
				};
			} else {
				state = { ...state, entities: removeValueFromArray(state.entities, action.payload.params.id!) };
			}
			state = { ...state, updates: removeValueFromArray(state.updates, action.payload.params.id!) };
		}

		state = { ...state, saveSyncInProgress: false };
		return state;
	}

	if (isType(action, saveMoveEvent.done)) {
		state = { ...state, updates: removeValueFromArray(state.updates, action.payload.result) };
		state = { ...state, saveSyncInProgress: false };
		return state;
	}

	if (isType(action, siteChange.done)) {
		state = initialState;
	}

	if (isType(action, actions.removeMoveEvent.started)) {
		const idsToRemove = state.entities
			.filter((moveEvent: any) => moveEvent.stemAnimalId === action.payload)
			.map((moveEvent: any) => moveEvent.id!);
		if (idsToRemove && idsToRemove.length > 0) {
			state = {
				...state,
				entities: removeMultipleValuesFromArray(state.entities, idsToRemove),
			};
		}
	}

	if (isType(action, getDeparturedMoveEvents.done)) {
		if (action.payload.params.siteId === action.payload.params.activeSiteId) {
			state = {
				...state,
				departuredMoveEvents: action.payload.result,
			};
		}
	}

	if (isType(action, getDeparturedAnimalDataByDate.done)) {
		if (action.payload.result.departedMoveEvents) {
			state = {
				...state,
				departuredMoveEvents: action.payload.result.departedMoveEvents,
			};
		}
	}

	if (isType(action, fetchSingleAnimalInfo.done)) {
		if (action.payload.result.stemAnimal && action.payload.result.moveEvent) {
			if (
				action.payload.result.stemAnimal.departureDate &&
				action.payload.result.stemAnimal.departureDate <= new Date()
			) {
				state = {
					...state,

					departuredMoveEvents: upsertValueInArrayCustomId(
						state.departuredMoveEvents,
						action.payload.result.moveEvent,
						(m: IMoveEvent) => m.stemAnimalId!,
					),
				};
			} else {
				state = {
					...state,

					entities: upsertValueInArrayCustomId(
						state.entities,
						action.payload.result.moveEvent,
						(m: IMoveEvent) => m.stemAnimalId!,
					),
				};
			}
		}
	}

	//Ensure Date objects are in fact date and not strings - redux-persist serializes dates to string but doesn't deserialize to dates again
	if (action.type === 'persist/REHYDRATE') {
		let a = (action as any) as { payload: MoveEventState; key: string };

		if (a.key === 'moveEvents') {
			let entities = new Array<MoveEvent>();
			let updates = new Array<MoveEvent>();
			let lastSyncDate = SyncableInitialState.lastSyncDate;

			if (a.payload && a.payload && a.payload.entities) {
				entities = a.payload.entities.map((move: IMoveEvent) => {
					let newMove = MoveEvent.fromJS({});
					newMove.init(move);
					return newMove;
				});
			}

			if (a.payload && a.payload && a.payload.updates) {
				updates = a.payload.updates.map((move: IMoveEvent) => {
					let newMove = MoveEvent.fromJS({});
					newMove.init(move);
					return newMove;
				});
			}

			if (a.payload && a.payload && a.payload.lastSyncDate) {
				lastSyncDate = new Date(a.payload.lastSyncDate);
			}

			state = { ...state, entities, updates, lastSyncDate };
		}
	}

	return state;
};

export default moveEventReducer;
