import { Sorting } from '@devexpress/dx-react-grid';
import memoize from 'memoize-one';
import React from 'react';
import { Option } from 'react-dropdown';
import { connect } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { Dispatch } from 'redux';
import {
	DistriwinFeedUnitType,
	Feeding,
	FeedingAmountType,
	FeedingUsage,
	IFeeding,
	IFeedingSubject,
	IFeedingUsage,
} from 'shared/api/api';
import DeleteIcon from 'shared/assets/src-assets/png/delete_icon.png';
import PenIcon from 'shared/assets/src-assets/png/pen_icon.png';
import { getDateString } from 'shared/helpers/date-helpers';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { getFeedUnitType } from 'shared/helpers/feeding/feeding-helper';
import { deepCopy2 } from 'shared/helpers/general-helpers';
import { NaturalSortDates } from 'shared/helpers/natural-sort';
import { GetDistriwinFeedConsumptionDtos } from 'shared/state/ducks/distriwin-feed-consumptions/operations';
import { saveFeeding } from 'shared/state/ducks/feeding/operations';
import { localized, localizedInterpolation } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import PageContainer from 'web/view/components/page-container/page-container';
import { ShowConfirmAlert } from 'web/view/components/skiold-alert/skiold-alert';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldModal } from 'web/view/components/skiold-components/skiold-modal/skiold-modal';
import { SowListConstants } from 'web/view/components/stem-animal/animal-lists/table-constants';
import { Heading } from 'web/view/components/utils/heading';
import { SkioldImage } from 'web/view/components/utils/svg/skiold-image';
import { SkioldDropdown } from '../skiold-components/skiold-dropdown/skiold-dropdown';
import { SkioldFetch } from '../skiold-components/skiold-fetch/skiold-fetch';
import SkioldTableGrid from '../skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { SkioldTouchableOpacity } from '../skiold-components/skiold-touchable-opacity';
import { ViewWeb } from '../utils/web-view';
import AddFood from './add-food';
import CreateFoodUsage from './create-food-usage';
import { FeedingAdjustmentTableModel } from './feeding-table-model';
import FeedingUsageTable from './feeding-usage-table';
import { calculateFeedingUsage, GetFeedingName } from './food-helper';
import './feeding.scss';

interface PropsFromParent {
	closeModal: () => void;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		siteId: state.profile.active!.siteId,
		feedings: state.feeding.feedings,
		userProfile: state.profile.active!,
		feedingSubjects: state.feedingSubject.entities,
		feedingCategory: state.feedingCategory.entities,
		buildings: state.locations.buildings,
		feedUnitType: getFeedUnitType(state),
		pending: state.distriwinFeedConsumptions.pending,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	saveFeeding: (feeding: Feeding) => saveFeeding(feeding)(dispatch),
	getDistriwinFeedConsumptionDtos: (fromDate: Date, toDate: Date, siteId: string) =>
		GetDistriwinFeedConsumptionDtos(fromDate, toDate, siteId)(dispatch),
});

interface State {
	feedingAdjustmentTableModels?: FeedingAdjustmentTableModel[];
	dateFrom?: Date;
	dateTo?: Date;
	editCreateModalOpen: boolean;
	editCreateUsageModalOpen: boolean;
	adjustmentId?: string;
	feedingId?: string;
	selectedType: Option;
	energyUsageModelOpen: boolean;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

export class FeedingLog extends React.PureComponent<Props, State> {
	private feedingAdjustmentTableModels: FeedingAdjustmentTableModel[] = [];
	private generateColumnData = memoize((selectedType: FeedingAmountType) => {
		return this.generateColumns();
	});
	private generateData = memoize((selectedType: FeedingAmountType, feedings) => {
		return this.getFilteredData(selectedType, feedings);
	});

	private logDropdownOptions: Option[] = [
		{ value: FeedingAmountType.FeedingAdjustment, label: localized(FeedingAmountType.FeedingAdjustment+'_purchase') },
		{ value: FeedingAmountType.FeedingUsage, label: localized(FeedingAmountType.FeedingUsage) },
	];

	private readonly getEditCell = (feeding: FeedingAdjustmentTableModel) => (
		<SkioldTouchableOpacity itemFromParent={feeding} onPress={this.editFeedingAdjustment}>
			<SkioldImage
				width={SowListConstants.iconSVGWidth}
				height={SowListConstants.iconSVGWidth}
				imageData={PenIcon}
			/>
		</SkioldTouchableOpacity>
	);

	private readonly getDeleteCell = (feeding: FeedingAdjustmentTableModel) => (
		<SkioldTouchableOpacity itemFromParent={feeding} onPress={this.deleteAdjustment}>
			<SkioldImage
				width={SowListConstants.iconSVGWidth}
				height={SowListConstants.iconSVGWidth}
				imageData={DeleteIcon}
			/>
		</SkioldTouchableOpacity>
	);

	private readonly getDateCell = (adjustment: FeedingAdjustmentTableModel) => getDateString(adjustment.date);

	private readonly getFeedingSubjectCell = (adjustment: FeedingAdjustmentTableModel) => adjustment.FeedingSubject;

	private readonly getFeedingCategoryCell = (adjustment: FeedingAdjustmentTableModel) => adjustment.FeedingCategory;

	private readonly getAmountInKgCell = (adjustment: FeedingAdjustmentTableModel) => adjustment.amount!.toFixed(1);

	private readonly getPriceCell = (adjustment: FeedingAdjustmentTableModel) => adjustment.pricePer100Kg;

	constructor(props: Props) {
		super(props);
		let newDate = new Date();
		let dateFrom = new Date(
			new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), 0, 0, 0).setMonth(
				newDate.getMonth() - 1,
			),
		);
		let dateTo = new Date(
			new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), 23, 59, 59).setMonth(
				newDate.getMonth(),
			),
		);

		this.state = {
			selectedType: {
				value: FeedingAmountType.FeedingAdjustment,
				label: localized(FeedingAmountType.FeedingAdjustment+'_purchase'),
			},
			editCreateModalOpen: false,
			editCreateUsageModalOpen: false,
			adjustmentId: undefined,
			feedingId: undefined,
			feedingAdjustmentTableModels: undefined,
			dateFrom: dateFrom,
			dateTo: dateTo,
			energyUsageModelOpen: false,
		};
	}

	public generateColumnsExtensions() {
		let columnExts: any[] = [
			{
				columnName: 'edit',
				width: SowListConstants.iconWidth,
				filteringEnabled: false,
				sortingEnabled: false,
				resizingEnabled: false,
			},
			{
				columnName: 'delete',
				width: SowListConstants.iconWidth,
				filteringEnabled: false,
				sortingEnabled: false,
				resizingEnabled: false,
			},
			{
				columnName: 'date',
				width: SowListConstants.indexDateWidth,
			},
			{
				columnName: 'feedingSubject',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'feedingCategory',
				width: 160,
			},
			{
				columnName: 'amountInKg',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'Energy1',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'price',
				width: SowListConstants.entranceDateWidth,
			},
		];

		if (this.props.feedUnitType === DistriwinFeedUnitType.FEsoFEsv) {
			columnExts.splice(columnExts.length - 2, 0, {
				columnName: 'Energy2',
				width: SowListConstants.animalNrWidth,
			});
		}
		return columnExts;
	}

	private generateColumns(): any {
		let columns: any[] = [
			{
				name: 'edit',
				title: ' ',
				sortable: false,
				getCellValue: this.getEditCell,
			},
			{
				name: 'delete',
				title: ' ',
				sortable: false,
				getCellValue: this.getDeleteCell,
			},
			{
				name: 'date',
				title: localized('Date'),
				sortFunction: NaturalSortDates,
				getCellValue: this.getDateCell,
			},
			{
				name: 'feedingSubject',
				title: localized('FeedingSubject'),
				getCellValue: this.getFeedingSubjectCell,
			},
			{
				name: 'feedingCategory',
				title: localized('Type'),
				getCellValue: this.getFeedingCategoryCell,
			},
			{
				name: 'amountInKg',
				title: localized('AmountInKgs'),
				getCellValue: this.getAmountInKgCell,
			},
			{
				name: 'Energy1',
				title: localized('FEsoPerKg'),
				getCellValue: this.getEnergy1Cell,
			},
			{
				name: 'price',
				title: localized('PricePer100Kgs'),
				getCellValue: this.getPriceCell,
			},
		];
		if (this.props.feedUnitType === DistriwinFeedUnitType.FEsoFEsv) {
			columns.splice(columns.length - 2, 0, {
				name: 'Energy2',
				title: localizedInterpolation('EnergyKg', this.props.feedUnitType + 'Energy2'),
				getCellValue: this.getEnergy2Cell,
			});
		}

		return columns;
	}

	private getEnergy2Cell = (adjustment: FeedingAdjustmentTableModel) =>
		this.state.selectedType.value === FeedingAmountType.FeedingAdjustment
			? adjustment.energy2PerKg!.toFixed(2)
			: (adjustment.energy2PerKg! / adjustment.amount!).toFixed(2);

	private getEnergy1Cell = (adjustment: FeedingAdjustmentTableModel) => {
		return this.state.selectedType.value === FeedingAmountType.FeedingAdjustment
			? adjustment.energy1PerKg!.toFixed(2)
			: (adjustment.energy1PerKg! / adjustment.amount!).toFixed(2);
	};

	private deleteAdjustment = (feeding: FeedingAdjustmentTableModel) => {
		if (feeding.type === FeedingAmountType.FeedingUsage) {
			this.deleteFeedingUsage(feeding);
		} else {
			this.deleteFeedingAdjustment(feeding);
		}
	};

	private async deleteFeedingAdjustment(feeding: FeedingAdjustmentTableModel) {
		if (this.props.feedings) {
			const feedingToEditIndex = this.props.feedings.findIndex(f => f.id === feeding.feedingId);
			if (feedingToEditIndex >= 0) {
				if (
					!(await ShowConfirmAlert(
						localized(ExceptionMessage.VALIDATION_WARNING_CONFIRM_DELETIOM_FEEDING_STOCK_ADJUSTMENT),
					))
				) {
					return;
				}
				let feedings = deepCopy2(this.props.feedings);
				const feedingToEdit = feedings[feedingToEditIndex];
				const adjustmentIndexToDelete = feedingToEdit.adjustments!.findIndex(
					a => a.id === feeding.AdjustmentId,
				);
				feedingToEdit.adjustments![adjustmentIndexToDelete].isDeleted = true;
				feedingToEdit.adjustments![adjustmentIndexToDelete].modifiedBy = this.props.userProfile.id;

				feedingToEdit.adjustments![adjustmentIndexToDelete].modifiedOn = new Date();
				if (
					feedingToEdit.adjustments &&
					feedingToEdit.adjustments.find(a => a.isDeleted === false && a.type) === undefined
				) {
					feedingToEdit.isDeleted = true;
				}

				this.props.saveFeeding(feedingToEdit as Feeding);
			}
		}
	}
	private deleteFeedingUsage = async (feeding: FeedingAdjustmentTableModel) => {
		if (this.props.feedings) {
			const feedingToEditIndex = this.props.feedings.findIndex(f => f.id === feeding.feedingId);
			if (feedingToEditIndex >= 0) {
				const feedingToEdit = deepCopy2(this.props.feedings[feedingToEditIndex]);
				let adjustmentIndexToDelete = feedingToEdit.adjustments!.findIndex(a => a.id === feeding.AdjustmentId);
				feedingToEdit.adjustments![adjustmentIndexToDelete].isDeleted = true;
				feedingToEdit.adjustments![adjustmentIndexToDelete].modifiedBy = this.props.userProfile.id;
				feedingToEdit.adjustments![adjustmentIndexToDelete].modifiedOn = new Date();
				let allow = await ShowConfirmAlert(
					localized(ExceptionMessage.VALIDATION_WARNING_CONFIRM_DELETIOM_FEEDING_STOCK_USAGE),
				);
				if (!allow) {
					return;
				}
				if (
					feedingToEdit.adjustments &&
					feedingToEdit.adjustments.find(a => a.isDeleted === false && a.type) === undefined
				) {
					feedingToEdit.isDeleted = true;
				}

				this.props.saveFeeding(feedingToEdit as Feeding);
			}
		}
	};
	private editFeedingAdjustment = (feeding: FeedingAdjustmentTableModel) => {
		if ((this.state.selectedType.value as FeedingAmountType) === FeedingAmountType.FeedingAdjustment) {
			this.setState({
				adjustmentId: feeding.AdjustmentId,
				feedingId: feeding.feedingId,
				editCreateModalOpen: true,
			});
		} else {
			this.setState({
				adjustmentId: feeding.AdjustmentId,
				feedingId: feeding.feedingId,
				editCreateUsageModalOpen: true,
			});
		}
	};

	private openEnergyUsageModal = () => {
		this.setState({ energyUsageModelOpen: true });
	};

	private defaultSorting = [{ columnName: 'date', direction: 'asc' }] as Sorting[];
	private fetchData = (dateFrom, dateTo) => {
		let feedings = this.GetFeedingAdjustments(dateFrom, dateTo);
		const feedingAdjustmentTableModels = this.generateData(
			this.state.selectedType.value as FeedingAmountType,
			feedings,
		);
		this.setState({ feedingAdjustmentTableModels, dateFrom, dateTo });
		if (dateFrom && dateTo && this.props.siteId) {
			this.props.getDistriwinFeedConsumptionDtos(dateFrom, dateTo, this.props.siteId);
		}
	};

	private GetFeedingAdjustments(dateFrom: any, dateTo: any) {
		let feedings = [...this.props.feedings];
		for (let index = 0; index < feedings.length; index++) {
			let feeding = { ...feedings[index] };
			feeding.adjustments = [
				...feedings[index].adjustments!.filter(a => {
					return a.date && a.date >= dateFrom && a.date <= dateTo;
				}),
			];
			feedings[index] = feeding;
		}
		return feedings;
	}

	public render() {
		let data = this.generateData(
			this.state.selectedType.value as FeedingAmountType,
			this.GetFeedingAdjustments(this.state.dateFrom, this.state.dateTo),
		);
		return (
			<PageContainer className="log">
				<Heading text={localized('CHOOSELIST')} />
				<ViewWeb className="stock-overview">
					<ViewWeb className="view-container-style">
						<SkioldFetch fetchData={this.fetchData} />
						<ViewWeb className="alignDropdownCenter">
							<SkioldDropdown
								onValueChanged={this.onDropdownValueChanged}
								selectedValue={this.state.selectedType}
								items={this.logDropdownOptions}
								containerClassName="dropdown-style"
							/>
						</ViewWeb>

						<ViewWeb className="button-container">
							{this.state.selectedType.value === FeedingAmountType.FeedingAdjustment && (
								<SkioldButton title={localized('Energy')} onPress={this.openEnergyUsageModal} />
							)}
							<SkioldButton theme="grey" title={localized('Close')} onPress={this.props.closeModal} />
						</ViewWeb>
					</ViewWeb>

					<ViewWeb className="log-outer-view">
						{this.state.selectedType.value === FeedingAmountType.FeedingAdjustment && (
							<SkioldTableGrid
								columns={this.generateColumnData(this.state.selectedType.value as FeedingAmountType)}
								ColumnExtensions={this.generateColumnsExtensions()}
								tableKey={'feedingLog1'}
								data={data}
								ignoreSetCount={true}
								sortHeaderId={this.defaultSorting}
								onFiltersChanged={this.onFiltersChanged}
								containerClassName={'feeding-usage-table-container'}
							/>
						)}
						{this.state.selectedType.value === FeedingAmountType.FeedingUsage && (
							<FeedingUsageTable
								fromDate={this.state.dateFrom}
								toDate={this.state.dateTo}
								deleteCallback={this.deleteAdjustment}
								editCallback={this.editFeedingAdjustment}
							/>
						)}
						{this.props.pending && (
							<ViewWeb className="over-menu-spinner">
								<ClipLoader color="#f2ac40" size={70} />
							</ViewWeb>
						)}
					</ViewWeb>

					<SkioldModal padding="0" isOpen={this.state.editCreateUsageModalOpen} close={this.closeModal}>
						<CreateFoodUsage
							closeModal={this.closeModal}
							adjustmentToEdit={this.state.adjustmentId}
							feedingToEdit={this.state.feedingId}
						/>
					</SkioldModal>
					<SkioldModal padding="0" isOpen={this.state.editCreateModalOpen} close={this.closeModal}>
						<AddFood
							closeModal={this.closeModal}
							feedingToEdit={this.state.feedingId}
							adjustmentToEdit={this.state.adjustmentId}
						/>
					</SkioldModal>
					<SkioldModal padding="0" isOpen={this.state.energyUsageModelOpen} close={this.closeModal}>
						<ViewWeb className="energy-modal">
							<ViewWeb className="modal-wrapper">
								{this.state.selectedType.value === FeedingAmountType.FeedingAdjustment &&
									this.generateSummary(
										this.state.feedingAdjustmentTableModels
											? this.state.feedingAdjustmentTableModels
											: data,
									)}
							</ViewWeb>
							<ViewWeb className="button-container">
								<SkioldButton theme="grey" title={localized('Close')} onPress={this.closeModal} />
							</ViewWeb>
						</ViewWeb>
					</SkioldModal>
				</ViewWeb>
			</PageContainer>
		);
	}

	private onFiltersChanged = (feedingAdjustmentTableModels: FeedingAdjustmentTableModel[]) => {
		this.setState({ feedingAdjustmentTableModels });
	};

	private onDropdownValueChanged = (selectedOption: Option) => {
		let feedings = this.GetFeedingAdjustments(this.state.dateFrom, this.state.dateTo);

		this.setState({
			selectedType: selectedOption,
			feedingAdjustmentTableModels: this.generateData(selectedOption.value as FeedingAmountType, feedings),
		});
	};

	private getFilteredData(selectedType: FeedingAmountType, feedings: IFeeding[]) {
		let feedingAdjustments: FeedingAdjustmentTableModel[] = [];
		feedings.forEach(feeding => {
			let adjustmentKgUsedSoFar = 0;
			feeding
				.adjustments!.filter(a => a.type === selectedType && !a.isDeleted)
				.forEach(adjustment => {
					const adjustmentToUse =
						selectedType === FeedingAmountType.FeedingUsage
							? calculateFeedingUsage(
									feeding.adjustments!,
									adjustment.amount!,
									adjustmentKgUsedSoFar,
									adjustment as FeedingUsage,
							  )
							: adjustment;
					const feedingAdjustmentTableModel = {
						...adjustmentToUse,
						FeedingSubjectId: feeding.feedingSubjectId,
						AdjustmentId: adjustmentToUse.id,
						feedingId: feeding.id,
					} as FeedingAdjustmentTableModel;
					adjustmentKgUsedSoFar = adjustmentKgUsedSoFar + adjustment.amount!;
					const feedingSubject = this.props.feedingSubjects.find(
						subject => subject.id === feeding.feedingSubjectId,
					);

					this.setFeedingSubjectAndFeedingCategory(feedingSubject, feedingAdjustmentTableModel);
					feedingAdjustments.push(feedingAdjustmentTableModel);
				});
		});
		return feedingAdjustments;
	}

	private setFeedingSubjectAndFeedingCategory(
		feedingSubject: IFeedingSubject | undefined,
		feedingAdjustmentTableModel: FeedingAdjustmentTableModel,
	) {
		if (feedingSubject) {
			feedingAdjustmentTableModel.FeedingSubject = GetFeedingName(feedingSubject, this.props.userProfile);
			const feedingCategory = this.props.feedingCategory.find(
				subject => subject.id === feedingSubject!.feedingCategoryId,
			);
			if (feedingCategory) {
				feedingAdjustmentTableModel.FeedingCategory = GetFeedingName(feedingCategory, this.props.userProfile);
			}
		}
	}

	private generateSummaryData(tabelModel: FeedingAdjustmentTableModel) {
		const isAdjustment = this.state.selectedType.value === FeedingAmountType.FeedingAdjustment;
		let indexOfElement = this.feedingAdjustmentTableModels.findIndex(a => a.feedingId === tabelModel.feedingId);
		if (indexOfElement >= 0) {
			this.feedingAdjustmentTableModels[indexOfElement].amount =
				this.feedingAdjustmentTableModels[indexOfElement].amount! + tabelModel.amount!;
			this.feedingAdjustmentTableModels[indexOfElement].energy1PerKg =
				this.feedingAdjustmentTableModels[indexOfElement].energy1PerKg! +
				(isAdjustment ? tabelModel.energy1PerKg! * tabelModel.amount! : tabelModel.energy1PerKg!);
			this.feedingAdjustmentTableModels[indexOfElement].energy2PerKg =
				this.feedingAdjustmentTableModels[indexOfElement].energy2PerKg! +
				(isAdjustment ? tabelModel.energy2PerKg! * tabelModel.amount! : tabelModel.energy2PerKg!);
		} else if (tabelModel.amount !== 0) {
			this.feedingAdjustmentTableModels.push({
				...tabelModel,
				energy1PerKg: isAdjustment ? tabelModel.energy1PerKg! * tabelModel.amount! : tabelModel.energy1PerKg!,
				energy2PerKg: isAdjustment ? tabelModel.energy2PerKg! * tabelModel.amount! : tabelModel.energy2PerKg!,
			} as FeedingAdjustmentTableModel);
		}
	}

	private generateSummary = memoize((allFeedingAdjustmentTableModels: FeedingAdjustmentTableModel[]) => {
		let jsxElements: JSX.Element[] = [this.generateSummaryHeaderRow()];
		this.feedingAdjustmentTableModels.forEach(a => {
			a.amount = 0;
			a.energy1PerKg = 0;
			a.energy2PerKg = 0;
			a.pricePer100Kg = 0;
		});
		allFeedingAdjustmentTableModels.forEach(tableModel => {
			this.generateSummaryData(tableModel);
		});
		this.feedingAdjustmentTableModels.forEach(model => {
			if (model.amount && model.amount > 0) {
				jsxElements.push(this.generateSummaryRow(model));
			}
		});
		return <ViewWeb className={'summaryContainer'}> {jsxElements.length > 1 && jsxElements} </ViewWeb>;
	});

	private closeModal = () => {
		if (this.state.dateFrom && this.state.dateTo) {
			this.fetchData(this.state.dateFrom, this.state.dateTo);
		}
		this.setState({ editCreateModalOpen: false, editCreateUsageModalOpen: false, energyUsageModelOpen: false });
	};

	private generateSummaryHeaderRow(): JSX.Element {
		return (
			<ViewWeb key={'headerRow'} className={'headerSummaryStyle'}>
				<ViewWeb className={'width200 alignLeftText yellow'}>{localized('FeedingSubject')} </ViewWeb>
				<ViewWeb className={'flexOne alignLeftText verticalPaddingFive yellow'}>{localized('KG')} </ViewWeb>
				<ViewWeb className={'flexOne alignLeftText verticalPaddingFive yellow'}>
					{localized(this.props.feedUnitType + 'Energy1')}
				</ViewWeb>
				{this.props.feedUnitType === DistriwinFeedUnitType.FEsoFEsv && (
					<ViewWeb className={'flexOne alignLeftText verticalPaddingFive yellow'}>
						{localized(this.props.feedUnitType + 'Energy2')}
					</ViewWeb>
				)}
			</ViewWeb>
		);
	}

	private generateSummaryRow(model: FeedingAdjustmentTableModel): JSX.Element {
		return (
			<ViewWeb key={model.feedingId} className={'headerSummaryStyle'}>
				<ViewWeb className={'width200 alignLeftText white'}>{model.FeedingSubject} </ViewWeb>
				<ViewWeb className={'flexOne alignLeftText verticalPaddingFive white stockSummaryBorderLeft'}>
					{model.amount!.toFixed(1)}{' '}
				</ViewWeb>
				<ViewWeb className={'flexOne alignLeftText verticalPaddingFive white stockSummaryBorderLeft'}>
					{model.energy1PerKg!.toFixed(2)}
				</ViewWeb>
				{this.props.feedUnitType === DistriwinFeedUnitType.FEsoFEsv && (
					<ViewWeb className={'flexOne alignLeftText verticalPaddingFive white stockSummaryBorderLeft'}>
						{model.energy2PerKg!.toFixed(2)}
					</ViewWeb>
				)}
			</ViewWeb>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(FeedingLog);
