import { IDiagnose } from 'shared/api/api';
import { LanguageType, localized } from 'shared/state/i18n/i18n';
import { StoreContainer } from 'shared/state/store-container';

export function getDiagnoseName(diagnose: IDiagnose | undefined, language: string | undefined, isRegistration?: boolean): string {
	let diagnoseName: string = '';
	if (diagnose && diagnose.name) {
		if (language) {
			diagnoseName = diagnose.name![language as LanguageType];
		}

		if (!diagnoseName) {
			diagnoseName = diagnose.name!['en' as LanguageType];
		}

		if (!diagnoseName) {
			diagnoseName = diagnose.name!['da' as LanguageType];
		}
	}

	return isRegistration? `${diagnoseName} - (${localized('SetupRegistration')})`: diagnoseName;
}

export function getDiagnoseNameByTreatDefinitionId(treatDefinitionId: string | undefined): string {
	if (!treatDefinitionId) {
		return '';
	}
	const state = StoreContainer.getState();
	const treatDef = state.treatmentDefinitions.entities.find(def => def.id === treatDefinitionId);
	const language = state.profile.active!.language;
	if (treatDef) {
		const diagnose = state.diagnose.entities.find(diag => diag.id === treatDef.diagnoseId);
		if (diagnose) {
			return getDiagnoseName(diagnose, language);
		}
	}

	return '';
}
