import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { goToPage } from 'web/state/ducks/navigation/actions';
import { RouteKey } from 'web/state/ducks/navigation/routes';
import './navlogo.scss';

const mapDispatchToProps = (dispatch: Dispatch) => ({
	navigate: (type: RouteKey, param?: string) => () => dispatch(goToPage(type, param)),
});

interface PropsFromParent {}

type NavLogoProps = PropsFromParent & ReturnType<typeof mapDispatchToProps>;

const navBarStyle = { marginLeft: 'auto', marginRight: 'auto' };
const NavLogo = (props: NavLogoProps) => {
	return (
		<div style={navBarStyle} className="skioldDigitalFontStyle">
			SKIOLD digital
		</div>
	);
};

export default connect(null, mapDispatchToProps)(NavLogo);
