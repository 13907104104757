import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import * as actions from './actions';
import { BMPState } from './types';
import { mergeArrays } from 'shared/helpers/reducer-helpers';
import { SyncableInitialState } from 'shared/state/models/syncable';

export const initialState: BMPState = {
	...SyncableInitialState
};

const animalReportSetttingReducer = (
	state: BMPState = initialState,
	action: Action
): BMPState => {
	if (isType(action, actions.getSyncData.started)) {
		state = { ...state, syncInProgress: true };
	}

	if (isType(action, actions.getSyncData.done)) {
		state = {
			...state,
			entities: mergeArrays(state.entities, action.payload.result)
		};
	}

	// //Ensure Date objects are in fact date and not strings - redux-persist serializes dates to string but don't deserialize to dates again
	// if (action.type === 'persist/REHYDRATE') {
	// 	let a = (action as any) as { payload: SharedAppState; key: string };

	// 	if (a.key === 'root') {
	// 		let entities = new Array<AnimalReportSetting>();
	// 		let updates = new Array<AnimalReportSetting>();
	// 		let lastSyncDate = new Date(-8640000000000000);

	// 		if (a.payload && a.payload.animalReportSettings && a.payload.animalReportSettings.entities) {
	// 			entities = [];
	// 			a.payload.animalReportSettings.entities.forEach((dt: IAnimalReportSetting) => {
	// 				let ndt = NewAnimalReportSetting(dt);
	// 				if (ndt) {
	// 					ndt.init(dt);
	// 					entities.push(ndt);
	// 				}
	// 			});
	// 		}

	// 		if (a.payload && a.payload.animalReportSettings && a.payload.animalReportSettings.updates) {
	// 			updates = [];
	// 			a.payload.animalReportSettings.updates.forEach((dt: IAnimalReportSetting) => {
	// 				let ndt = NewAnimalReportSetting(dt);
	// 				if (ndt) {
	// 					ndt.init(dt);
	// 					updates.push(ndt);
	// 				}
	// 			});
	// 		}
	// 		if (a.payload && a.payload.animalReportSettings && a.payload.animalReportSettings.lastSyncDate) {
	// 			lastSyncDate = new Date(a.payload.animalReportSettings.lastSyncDate);
	// 		}

	// 		state = { ...state, entities, updates, lastSyncDate };
	// 	}
	//}

	return state;
};

export default animalReportSetttingReducer;
