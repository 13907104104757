import React from 'react';
import FetchDataIcon from 'shared/assets/src-assets/png/hent_data_new.png';
import './skiold-fetch-icon.scss';
import { SkioldTouchableOpacity } from '../skiold-components/skiold-touchable-opacity';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { SkioldImage } from './svg/skiold-image';
import { ViewWeb } from './web-view';
import { ClipLoader } from 'react-spinners';
import { localized } from 'shared/state/i18n/i18n';
interface SkioldFetchIconProps {
	onPress: () => void;
	showSpinner?: boolean;
	className?: string;
}

export class SkioldFetchIcon extends React.PureComponent<SkioldFetchIconProps> {
	public render() {
		return (
			<SkioldTouchableOpacity
				className={'skiold-fetch-icon ' + (this.props.className ? this.props.className : '')}
				onPress={this.props.onPress}
			>
				<TextWeb className="text-style">{localized('Show')}</TextWeb>
				<SkioldImage width="50" height="50" imageData={FetchDataIcon} />
				{this.props.showSpinner && (
					<ViewWeb className="spinner-container-active">
						<ClipLoader color="#f2ac40" size={35} />
					</ViewWeb>
				)}
			</SkioldTouchableOpacity>
		);
	}
}
