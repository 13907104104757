import AnalysisIcon from 'shared/assets/src-assets/png/analyse_ikon.png';
import AnalysisIconOn from 'shared/assets/src-assets/png/analyse_ikon_orange.png';
import ArbejdsLister from 'shared/assets/src-assets/png/arbejdslister_grey.png';
import {
	default as TreatmentDefinition,
	default as TreatmentDefinitionOn,
} from 'shared/assets/src-assets/png/behandlingsdefinitioner.png';
import { default as TreatmentLog, default as TreatmentLogOn } from 'shared/assets/src-assets/png/behandlingslog.png';
import FeedingStock from 'shared/assets/src-assets/png/FoderLager_Grey.png';
import FeedingStockOn from 'shared/assets/src-assets/png/FoderLager_Yellow.png';
import PregnancyEventsIcon from 'shared/assets/src-assets/png/Hændelser_Grey.png';
import PregnancyEventsIconOn from 'shared/assets/src-assets/png/Hændelser_Yellow.png';
import retryAdjustFeed from 'shared/assets/src-assets/png/juster-foder-menu.png';
import MatingBatchReports from 'shared/assets/src-assets/png/mating_batch_reports.png';
import MatingBatchReportsOn from 'shared/assets/src-assets/png/mating_batch_reports_on.png';
import { default as DrugStock, default as DrugStockOn } from 'shared/assets/src-assets/png/medicin_paa_lager.png';
import Settings from 'shared/assets/src-assets/png/settings.png';
import SettingsOn from 'shared/assets/src-assets/png/settings_on.png';
import Pig from 'shared/assets/src-assets/png/slaughterpigs_grey.png';
import PigOn from 'shared/assets/src-assets/png/slaughterpigs_yellow.png';
import { default as SowCard, default as SowCardOn } from 'shared/assets/src-assets/png/sokort.png';
import Events from 'shared/assets/src-assets/png/sokort_hendelser.png';
import Sow from 'shared/assets/src-assets/png/sow_grey.png';
import SowOn from 'shared/assets/src-assets/png/sow_on.png';
import finisherEventsIcon from 'shared/assets/src-assets/png/finisher_event_web_grey.png';
import finisherEventsIconOn from 'shared/assets/src-assets/png/finisher_event_web_yellow.png';
import weanerEventsIcon from 'shared/assets/src-assets/png/weaner_event_web_grey.png';
import weanerEventsIconOn from 'shared/assets/src-assets/png/weaner_event_web_yellow.png';
import finisherEventsListIcon from 'shared/assets/src-assets/png/finisher_event_list_web_grey.png';
import finisherEventsListIconOn from 'shared/assets/src-assets/png/finisher_event_list_web_yellow.png';
import weanerEventsListIcon from 'shared/assets/src-assets/png/weaner_event_list_web_grey.png';
import weanerEventsListIconOn from 'shared/assets/src-assets/png/weaner_event_list_web_yellow.png';
import weanerIcon from 'shared/assets/src-assets/png/climatepigs_grey.png';
import weanerIconOn from 'shared/assets/src-assets/png/climatepigs_yellow.png';
import slaughterhouseData from 'shared/assets/src-assets/png/slagteri_data_grey.png';
import slaughterhouseDataOn from 'shared/assets/src-assets/png/slagteri_data_yellow.png';
import dashboard from 'shared/assets/src-assets/png/dashboard.png';
import dashboardOn from 'shared/assets/src-assets/png/dashboard_orange.png';
import weanerYellow from 'shared/assets/src-assets/png/weaner_bg_yellow.png';
import weanerGrey from 'shared/assets/src-assets/png/weaner_bg_grey.png';
import finisherYellow from 'shared/assets/src-assets/png/finisher_bg_yellow.png';
import finisherGrey from 'shared/assets/src-assets/png/finisher_bg_grey.png';

import {
	RETRY_ADJUST_FEED_PIG,
	RETRY_ADJUST_FEED_SOW,
	ROUTE_SOW_ANALYSIS,
	ROUTE_ANIMAL_REPORT,
	ROUTE_ANIMALS,
	ROUTE_BALANCE_WEANED_PIG,
	ROUTE_BMP,
	ROUTE_DASHBOARD,
	ROUTE_DIAGNOSE_CATEGORIES,
	ROUTE_DIAGNOSES,
	ROUTE_DRUG_STOCK,
	ROUTE_DRUG_STOCK_FINISHER,
	ROUTE_DRUG_STOCK_WEANER,
	ROUTE_DRUG_TYPES,
	ROUTE_EVENTS,
	ROUTE_FEEDING_STOCK,
	ROUTE_FEEDING_STOCK_FINISHER,
	ROUTE_FEEDING_STOCK_WEANER,
	ROUTE_FINISHER_DATA,
	ROUTE_FINISHER_SETTINGS,
	ROUTE_GROWTH_PIG_DASHBOARD_FINISHER,
	ROUTE_GROWTH_PIG_DASHBOARD_WEANER,
	ROUTE_GROWTH_PIG_EVENT_LISTS_FINISHER,
	ROUTE_GROWTH_PIG_EVENT_LISTS_WEANER,
	ROUTE_GROWTH_PIG_EVENTS_FINISHER,
	ROUTE_GROWTH_PIG_EVENTS_WEANER,
	ROUTE_GROWTH_PIG_SHOULD_DEPART_LIST_FINISHER,
	ROUTE_GROWTH_PIG_SHOULD_DEPART_LIST_WEANER,
	ROUTE_GROWTH_PIGS_REPORTS_FINISHER,
	ROUTE_GROWTH_PIGS_REPORTS_WEANER,
	ROUTE_MODULE_LICENSES,
	ROUTE_PREGNANCY_EVENT_LISTS,
	ROUTE_REASONS,
	ROUTE_REGULATIONS,
	ROUTE_SETTINGS,
	ROUTE_SOW_CARD,
	ROUTE_SOW_SETTINGS,
	ROUTE_TREATMENT_DEFINITIONS,
	ROUTE_TREATMENT_DEFINITIONS_FINISHER,
	ROUTE_TREATMENT_DEFINITIONS_WEANER,
	ROUTE_TREATMENT_LOG,
	ROUTE_TREATMENT_LOG_FINISHER,
	ROUTE_TREATMENT_LOG_WEANER,
	ROUTE_WEANER_SETTINGS,
	ROUTE_WORK_LISTS,
	ROUTE_ADMIN_FEED_REGISTRATIONS,
	ROUTE_ADMIN_BREED_TABLE,
	ROUTE_FINISHER_ANALYSIS,
	ROUTE_WEANER_ANALYSIS,
} from 'web/state/ducks/navigation/types';
import { OverlayMenuItemModel, SubMenuToItems } from './overlay-menu-item';
import { DistriwinFeedComponentState } from 'shared/state/ducks/distriwin-feed-component';
import { ModulesIds } from 'shared/constants';

export const OverlayMenuUpperData: OverlayMenuItemModel[] = [
	{
		id: [ModulesIds.SowModule],
		name: 'OVERLAYSOW',
		icon: Sow,
		iconOn: SowOn,
		to: 'sowManagement',
		type: 'submenu',
	},
	{
		id: [ModulesIds.ClimatePig],
		name: 'OVERLAYWEANER',
		icon: weanerIcon,
		iconOn: weanerIconOn,
		to: 'weanersManagement',
		type: 'submenu',
	},
	{
		id: [ModulesIds.FinisherModule],
		name: 'OVERLAYFINISHER',
		icon: Pig,
		iconOn: PigOn,
		to: 'finishersManagement',
		type: 'submenu',
	},
	{
		id: [ModulesIds.SettingsModule],
		name: 'SOWSETTINGS',
		icon: Settings,
		iconOn: SettingsOn,
		to: ROUTE_SETTINGS,
		type: 'navigation',
	},
];

export const OverlayMenuMiddleData: OverlayMenuItemModel[] = [
	{
		id: ['AdminOnlyMenu'],
		name: 'OVERLAYADMIN',
		icon: Settings,
		iconOn: SettingsOn,
		to: 'adminPanel',
		type: 'submenu',
		adminMenu: true,
	},
];

export const SubMenuContent: SubMenuToItems = {
	sowManagement: [
		[
			{
				id: [
					'6d7562e8-f75a-4a79-83a6-98e535f8b9f7',
					'fdb5f742-d890-463e-912e-89b7a4f9d3eb',
					'ed71ba1f-be95-4416-b53a-668c63303fbd',
					'9247036f-30f6-4e08-aacb-f5a031b92b10',
				],
				name: 'SOWANIMALS',
				icon: Sow,
				iconOn: SowOn,
				to: ROUTE_ANIMALS,
				type: 'navigation',
			},
			{
				id: [
					'6d7562e8-f75a-4a79-83a6-98e535f8b9f7',
					'fdb5f742-d890-463e-912e-89b7a4f9d3eb',
					'ed71ba1f-be95-4416-b53a-668c63303fbd',
					'9247036f-30f6-4e08-aacb-f5a031b92b10',
				],
				name: 'pregnancyEventLists',
				icon: PregnancyEventsIcon,
				iconOn: PregnancyEventsIconOn,
				to: ROUTE_PREGNANCY_EVENT_LISTS,
				type: 'navigation',
			},
			{
				id: [
					'6d7562e8-f75a-4a79-83a6-98e535f8b9f7',
					'fdb5f742-d890-463e-912e-89b7a4f9d3eb',
					'ed71ba1f-be95-4416-b53a-668c63303fbd',
					'9247036f-30f6-4e08-aacb-f5a031b92b10',
				],
				name: 'sowCard',
				icon: SowCard,
				iconOn: SowCardOn,
				to: ROUTE_SOW_CARD,
				type: 'navigation',
			},
			{
				id: [
					'6d7562e8-f75a-4a79-83a6-98e535f8b9f7',
					'fdb5f742-d890-463e-912e-89b7a4f9d3eb',
					'ed71ba1f-be95-4416-b53a-668c63303fbd',
					'9247036f-30f6-4e08-aacb-f5a031b92b10',
				],
				name: 'SowEvents',
				icon: Events,
				iconOn: Events,
				to: ROUTE_EVENTS,
				type: 'navigation',
			},
			{
				id: ['3f1a9536-c69d-49a4-903d-d59f96488ca6'],
				name: 'workLists',
				icon: ArbejdsLister,
				iconOn: ArbejdsLister,
				to: ROUTE_WORK_LISTS,
				type: 'navigation',
			},
			{
				id: ['0ab90680-7adc-4602-9e4c-8f935bf10cee'],
				name: 'balanceWeanedPigs',
				icon: ArbejdsLister,
				iconOn: ArbejdsLister,
				to: ROUTE_BALANCE_WEANED_PIG,
				type: 'navigation',
			},
		],
		[
			{
				id: ['fdb5f742-d890-463e-912e-89b7a4f9d3eb'],
				name: 'SOWTREATMENTS',
				icon: TreatmentLog,
				iconOn: TreatmentLogOn,
				to: ROUTE_TREATMENT_LOG,
				type: 'navigation',
			},
			{
				id: ['6d7562e8-f75a-4a79-83a6-98e535f8b9f7'],
				name: 'reports',
				icon: MatingBatchReports,
				iconOn: MatingBatchReportsOn,
				to: ROUTE_ANIMAL_REPORT,
				type: 'navigation',
			},
			{
				id: ['6d7562e8-f75a-4a79-83a6-98e535f8b9f7'],
				name: 'Analysis',
				icon: AnalysisIcon,
				iconOn: AnalysisIconOn,
				to: ROUTE_SOW_ANALYSIS,
				type: 'navigation',
			},

			{
				id: ['6a5d1856-60ec-40bb-83fc-bf6e3f734a48'],
				name: 'retryAdjustFeedMenuTitle',
				icon: retryAdjustFeed,
				iconOn: retryAdjustFeed,
				to: RETRY_ADJUST_FEED_SOW,
				type: 'navigation',
			},
			{
				id: [
					'6d7562e8-f75a-4a79-83a6-98e535f8b9f7',
					'fdb5f742-d890-463e-912e-89b7a4f9d3eb',
					'ed71ba1f-be95-4416-b53a-668c63303fbd',
					'9247036f-30f6-4e08-aacb-f5a031b92b10',
				],
				name: 'Dashboard',
				icon: dashboard,
				iconOn: dashboardOn,
				to: ROUTE_DASHBOARD,
				type: 'navigation',
			},
		],
		[
			{
				id: ['fdb5f742-d890-463e-912e-89b7a4f9d3eb'],
				name: 'TreatmentDefinitions',
				icon: TreatmentDefinition,
				iconOn: TreatmentDefinitionOn,
				to: ROUTE_TREATMENT_DEFINITIONS,
				type: 'navigation',
			},
			{
				id: ['fdb5f742-d890-463e-912e-89b7a4f9d3eb'],
				name: 'DrugStock',
				icon: DrugStock,
				iconOn: DrugStockOn,
				to: ROUTE_DRUG_STOCK,
				type: 'navigation',
			},
			{
				id: ['db8d096a-708a-4d1a-883f-783c97441327'],
				name: 'FeedingStock',
				icon: FeedingStock,
				iconOn: FeedingStockOn,
				to: ROUTE_FEEDING_STOCK,
				type: 'navigation',
				unread: (distriwinFeedComponents: DistriwinFeedComponentState) =>
					!!distriwinFeedComponents.entities.find(x => !x.feedingId && !x.noRelation),
			},
			{
				id: [ModulesIds.SowModule],
				name: 'SOWSETTINGS',
				icon: Settings,
				iconOn: Settings,
				to: ROUTE_SOW_SETTINGS,
				type: 'navigation',
			},
		],
	],
	adminPanel: [
		[
			{
				id: [ModulesIds.SettingsModule],
				name: 'ADMIN_DEPARTURE_REASONS',
				icon: Settings,
				iconOn: SettingsOn,
				to: ROUTE_REASONS,
				type: 'navigation',
				adminMenu: true,
			},
			{
				id: [ModulesIds.SettingsModule],
				name: 'ADMIN_DIAGNOSES',
				icon: Settings,
				iconOn: SettingsOn,
				to: ROUTE_DIAGNOSES,
				type: 'navigation',
				adminMenu: true,
			},
			{
				id: [ModulesIds.SettingsModule],
				name: 'ADMIN_DIAGNOSES_CATEGORIES',
				icon: Settings,
				iconOn: SettingsOn,
				to: ROUTE_DIAGNOSE_CATEGORIES,
				type: 'navigation',
				adminMenu: true,
			},
			{
				id: [ModulesIds.SettingsModule],
				name: 'BMP',
				icon: Settings,
				iconOn: SettingsOn,
				to: ROUTE_BMP,
				type: 'navigation',
				adminMenu: true,
			},
			{
				id: [ModulesIds.SettingsModule],
				name: 'Regulations',
				icon: Settings,
				iconOn: SettingsOn,
				to: ROUTE_REGULATIONS,
				type: 'navigation',
				adminMenu: true,
			},
		],
		[
			{
				id: [ModulesIds.SettingsModule],
				name: 'ADMIN_DRUG_TYPES',
				icon: Settings,
				iconOn: SettingsOn,
				to: ROUTE_DRUG_TYPES,
				type: 'navigation',
				adminMenu: true,
			},
			{
				id: [ModulesIds.SettingsModule],
				name: 'ADMIN_MODULE_LICENCES',
				icon: Settings,
				iconOn: SettingsOn,
				to: ROUTE_MODULE_LICENSES,
				type: 'navigation',
				adminMenu: true,
			},
			{
				id: [ModulesIds.SettingsModule],
				name: 'ADMIN_FEED_REGISTRATIONS',
				icon: Settings,
				iconOn: SettingsOn,
				to: ROUTE_ADMIN_FEED_REGISTRATIONS,
				type: 'navigation',
				adminMenu: true,
			},
			{
				id: [ModulesIds.SettingsModule],
				name: 'ADMIN_BREED_TABLE',
				icon: Settings,
				iconOn: SettingsOn,
				to: ROUTE_ADMIN_BREED_TABLE,
				type: 'navigation',
				adminMenu: true,
			},
		],
	],

	weanersManagement: [
		[
			{
				id: ['f69fbece-cdc8-4995-9065-81746cc2573f'],
				name: 'EventsList',
				icon: weanerEventsListIcon,
				iconOn: weanerEventsListIconOn,
				to: ROUTE_GROWTH_PIG_EVENT_LISTS_WEANER,
				type: 'navigation',
			},
			{
				id: ['f69fbece-cdc8-4995-9065-81746cc2573f'],
				name: 'Events',
				icon: weanerEventsIcon,
				iconOn: weanerEventsIconOn,
				to: ROUTE_GROWTH_PIG_EVENTS_WEANER,
				type: 'navigation',
			},
			{
				id: ['a2c46ced-6270-4df8-a770-fe32a855c0dd'],
				name: 'ShouldDepartureList',
				icon: weanerGrey,
				iconOn: weanerYellow,
				to: ROUTE_GROWTH_PIG_SHOULD_DEPART_LIST_WEANER,
				type: 'navigation',
			},
		],
		[
			{
				id: ['2b501b81-d7a9-462e-aaad-969ca792f2a4'],
				name: 'SOWTREATMENTS',
				icon: TreatmentLog,
				iconOn: TreatmentLogOn,
				to: ROUTE_TREATMENT_LOG_WEANER,
				type: 'navigation',
			},
			{
				id: ['78afe7e3-3d73-408d-9f8b-cbb76e8e2547'],
				name: 'reportsWeaner',
				icon: MatingBatchReports,
				iconOn: MatingBatchReportsOn,
				to: ROUTE_GROWTH_PIGS_REPORTS_WEANER,
				type: 'navigation',
			},
			{
				id: ['7140df64-fe1c-4ccf-8dc5-8163592f9755'],
				name: 'Analysis',
				icon: AnalysisIcon,
				iconOn: AnalysisIconOn,
				to: ROUTE_WEANER_ANALYSIS,
				type: 'navigation',
			},
			{
				id: ['353d3261-1161-4de5-93f7-5c05ccd4526a'],
				name: 'retryAdjustFeedMenuTitle',
				icon: retryAdjustFeed,
				iconOn: retryAdjustFeed,
				to: RETRY_ADJUST_FEED_PIG,
				type: 'navigation',
			},
			{
				id: ['ef9ed684-0fd2-468b-9612-a26d5bc67890'],
				name: 'Dashboard',
				icon: dashboard,
				iconOn: dashboardOn,
				to: ROUTE_GROWTH_PIG_DASHBOARD_WEANER,
				type: 'navigation',
			},
		],
		[
			{
				id: ['2b501b81-d7a9-462e-aaad-969ca792f2a4'],
				name: 'TreatmentDefinitions',
				icon: TreatmentDefinition,
				iconOn: TreatmentDefinitionOn,
				to: ROUTE_TREATMENT_DEFINITIONS_WEANER,
				type: 'navigation',
			},

			{
				id: ['2b501b81-d7a9-462e-aaad-969ca792f2a4'],
				name: 'DrugStock',
				icon: DrugStock,
				iconOn: DrugStockOn,
				to: ROUTE_DRUG_STOCK_WEANER,
				type: 'navigation',
			},
			{
				id: ['9deec183-5027-4819-8f4c-8e064c7ba378'],
				name: 'FeedingStock',
				icon: FeedingStock,
				iconOn: FeedingStockOn,
				to: ROUTE_FEEDING_STOCK_WEANER,
				type: 'navigation',
				unread: (distriwinFeedComponents: DistriwinFeedComponentState) =>
					!!distriwinFeedComponents.entities.find(x => !x.feedingId && !x.noRelation),
			},
			{
				id: [ModulesIds.ClimatePig],
				name: 'PIGSETTINGS',
				icon: Settings,
				iconOn: Settings,
				to: ROUTE_WEANER_SETTINGS,
				type: 'navigation',
			},
		],
	],
	finishersManagement: [
		[
			{
				id: ['eee63204-68a5-4aa6-b276-0cde8c5a2202'],
				name: 'EventsList',
				icon: finisherEventsListIcon,
				iconOn: finisherEventsListIconOn,
				to: ROUTE_GROWTH_PIG_EVENT_LISTS_FINISHER,
				type: 'navigation',
			},
			{
				id: ['eee63204-68a5-4aa6-b276-0cde8c5a2202'],
				name: 'Events',
				icon: finisherEventsIcon,
				iconOn: finisherEventsIconOn,
				to: ROUTE_GROWTH_PIG_EVENTS_FINISHER,
				type: 'navigation',
			},
			{
				id: ['c316cb06-2685-47ff-8671-28a540273dc1'],
				name: 'finisherData',
				icon: slaughterhouseData,
				iconOn: slaughterhouseDataOn,
				to: ROUTE_FINISHER_DATA,
				type: 'navigation',
			},
			{
				id: ['1ec349a6-bfaa-4fcd-a7a5-ae6ac37fb9e1'],
				name: 'ShouldDepartureList',
				icon: finisherGrey,
				iconOn: finisherYellow,
				to: ROUTE_GROWTH_PIG_SHOULD_DEPART_LIST_FINISHER,
				type: 'navigation',
			},
		],
		[
			{
				id: ['4617bd03-830d-4e6f-9cab-3d348b9c6878'],
				name: 'SOWTREATMENTS',
				icon: TreatmentLog,
				iconOn: TreatmentLogOn,
				to: ROUTE_TREATMENT_LOG_FINISHER,
				type: 'navigation',
			},
			{
				id: ['37fbec62-5e3e-4eb6-96df-1b6ebfc23dad'],
				name: 'reportsFinisher',
				icon: MatingBatchReports,
				iconOn: MatingBatchReportsOn,
				to: ROUTE_GROWTH_PIGS_REPORTS_FINISHER,
				type: 'navigation',
			},
			{
				id: ['aaa08a6c-0aeb-4748-85e7-1b0b6bdbf02d'],
				name: 'Analysis',
				icon: AnalysisIcon,
				iconOn: AnalysisIconOn,
				to: ROUTE_FINISHER_ANALYSIS,
				type: 'navigation',
			},

			{
				id: ['2f827998-1e67-4a04-b907-98d5bb6460d6'],
				name: 'retryAdjustFeedMenuTitle',
				icon: retryAdjustFeed,
				iconOn: retryAdjustFeed,
				to: RETRY_ADJUST_FEED_PIG,
				type: 'navigation',
			},
			{
				id: ['cdd2226d-0d25-4728-ac59-6767c6bc5e8a'],
				name: 'Dashboard',
				icon: dashboard,
				iconOn: dashboardOn,
				to: ROUTE_GROWTH_PIG_DASHBOARD_FINISHER,
				type: 'navigation',
			},
		],
		[
			{
				id: ['4617bd03-830d-4e6f-9cab-3d348b9c6878'],
				name: 'TreatmentDefinitions',
				icon: TreatmentDefinition,
				iconOn: TreatmentDefinitionOn,
				to: ROUTE_TREATMENT_DEFINITIONS_FINISHER,
				type: 'navigation',
			},

			{
				id: ['4617bd03-830d-4e6f-9cab-3d348b9c6878'],
				name: 'DrugStock',
				icon: DrugStock,
				iconOn: DrugStockOn,
				to: ROUTE_DRUG_STOCK_FINISHER,
				type: 'navigation',
			},
			{
				id: ['1fbecc75-d4ec-44a5-a6fb-3a3dcce04bed'],
				name: 'FeedingStock',
				icon: FeedingStock,
				iconOn: FeedingStockOn,
				to: ROUTE_FEEDING_STOCK_FINISHER,
				type: 'navigation',
				unread: (distriwinFeedComponents: DistriwinFeedComponentState) =>
					!!distriwinFeedComponents.entities.find(x => !x.feedingId && !x.noRelation),
			},
			{
				id: [ModulesIds.FinisherModule],
				name: 'PIGSETTINGS',
				icon: Settings,
				iconOn: Settings,
				to: ROUTE_FINISHER_SETTINGS,
				type: 'navigation',
			},
		],
	],
};
