import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import {
	getTableData,
	retryAdjustFeed,
	setRetryCommitStatus,
	updateCommitStatusOnAll,
	deleteRetryAdjustFeedItem
} from './actions';
import { RetryAdjustFeedState } from '.';
import { siteChange } from '../profile/actions';

const initialState: RetryAdjustFeedState = {
	retryAdjustFeedItems: [],
	attemptingToUpdateAll: false,
	commitAll: false
};

const retryAdjustFeedReducer = (state: RetryAdjustFeedState = initialState, action: Action): RetryAdjustFeedState => {
	if (isType(action, getTableData.done)) {
		if (action.payload.params.siteId === action.payload.params.activeSiteId) {
			let data = action.payload.result.sort((a, b) => {
				if (a.buildingOrder && b.buildingOrder && a.buildingOrder > b.buildingOrder) {
					return 1;
				} else if (a.buildingOrder && b.buildingOrder && a.buildingOrder < b.buildingOrder) {
					return -1;
				}
				if (a.sectionOrder && b.sectionOrder && a.sectionOrder > b.sectionOrder) {
					return 1;
				} else if (a.sectionOrder && b.sectionOrder && a.sectionOrder < b.sectionOrder) {
					return -1;
				}
				if (a.penOrder && b.penOrder && a.penOrder > b.penOrder) {
					return 1;
				} else if (a.penOrder && b.penOrder && a.penOrder < b.penOrder) {
					return -1;
				}
				return -1;
			});

			state = { ...state, retryAdjustFeedItems: data };
		}
	}
	if (isType(action, retryAdjustFeed.started)) {
		let tmpRetryAdjustFeedItems = [...state.retryAdjustFeedItems];
		let index = state.retryAdjustFeedItems.findIndex(
			a => a.location === action.payload.location && a.date === action.payload.date
		);
		if (index !== -1) {
			let tmpObject = action.payload;
			tmpObject.showSpinner = true;
			tmpRetryAdjustFeedItems[index] = tmpObject;
		}
		state = { ...state, retryAdjustFeedItems: tmpRetryAdjustFeedItems };
	}

	if (isType(action, retryAdjustFeed.done)) {
		let tmpRetryAdjustFeedItems = [...state.retryAdjustFeedItems];
		let index = state.retryAdjustFeedItems.findIndex(
			a =>
				a.location === action.payload.result.valveFieldUpdateDto.location &&
				a.date === action.payload.result.valveFieldUpdateDto.date
		);
		if (index !== -1) {
			let tmpObject = action.payload.result;

			if (
				tmpObject.valveFieldUpdateDto.ids.length === 0 ||
				tmpObject.valveFieldUpdateDto.ids.length === tmpObject.failedIds.length
			) {
				tmpObject.valveFieldUpdateDto.showSpinner = false;
			}

			tmpRetryAdjustFeedItems[index] = tmpObject.valveFieldUpdateDto;
		}
		let filteredData = tmpRetryAdjustFeedItems.filter(a => a.ids.length !== 0);
		if (action.payload.result) {
			state = {
				...state,
				retryAdjustFeedItems: filteredData
			};
		}
	}

	if (isType(action, retryAdjustFeed.failed)) {
		const updated = { ...action.payload.params, showSpinner: false, shouldBeCommited: false };
		let index = state.retryAdjustFeedItems.findIndex(
			a => a.location === action.payload.params.location && a.date === action.payload.params.date
		);
		let arrayCopy = [...state.retryAdjustFeedItems];
		arrayCopy[index] = updated;
		state = { ...state, retryAdjustFeedItems: arrayCopy, commitAll: false };
	}

	if (isType(action, deleteRetryAdjustFeedItem.started)) {
		let tmpRetryAdjustFeedItems = [...state.retryAdjustFeedItems];
		let index = state.retryAdjustFeedItems.findIndex(
			a => a.location === action.payload.location && a.date === action.payload.date
		);
		if (index !== -1) {
			let tmpObject = action.payload;
			tmpObject.showSpinner = true;
			tmpRetryAdjustFeedItems[index] = tmpObject;
		}
		state = { ...state, retryAdjustFeedItems: tmpRetryAdjustFeedItems };
	}

	if (isType(action, deleteRetryAdjustFeedItem.done)) {
		let tmpRetryAdjustFeedItems = [...state.retryAdjustFeedItems];
		let filteredData = tmpRetryAdjustFeedItems.filter(a => a.ids.length !== 0);
		if (action.payload.result) {
			state = {
				...state,
				retryAdjustFeedItems: filteredData
			};
		}
	}

	if (isType(action, setRetryCommitStatus.done)) {
		let tmpRetryAdjustFeedItems = [...state.retryAdjustFeedItems];
		let index = state.retryAdjustFeedItems.findIndex(
			a => a.location === action.payload.result.location && a.date === action.payload.result.date
		);
		if (index !== -1) {
			let tmpObject = action.payload.result;
			tmpObject.shouldBeCommited = action.payload.result.shouldBeCommited === true ? false : true;
			tmpRetryAdjustFeedItems[index] = tmpObject;
		}
		if (action.payload.result !== undefined) {
			state = {
				...state,
				retryAdjustFeedItems: tmpRetryAdjustFeedItems,
				commitAll: false
			};
		}
	}
	if (isType(action, updateCommitStatusOnAll.done)) {
		let tmpRetryAdjustFeedItems = [...state.retryAdjustFeedItems];
		let commitAll = action.payload.result === true ? false : true;
		tmpRetryAdjustFeedItems = tmpRetryAdjustFeedItems.map(element => {
			return { ...element, shouldBeCommited: commitAll };
		});
		if (action.payload.result !== undefined) {
			state = {
				...state,
				retryAdjustFeedItems: tmpRetryAdjustFeedItems,
				commitAll
			};
		}
	}
	if (isType(action, siteChange.done)) {
		state = initialState;
	}

	return state;
};

export default retryAdjustFeedReducer;
