import React from 'react';
import PrinterGreyIcon from 'shared/assets/src-assets/png/printer_ikon_grey.png';
import { getDateString } from 'shared/helpers/date-helpers';
import { localized } from 'shared/state/i18n/i18n';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { SkioldAnimalCounterModal } from '../../skiold-components/skiold-modal/skiold-animal-counter-modal/skiold-animal-counter-modal';
import { SkioldModalButtonContainer } from '../../skiold-components/skiold-modal/skiold-modal-button-container/skiold-modal-button-container';
import { WhiteText } from '../../Text/white-text';
import { SkioldIconSpinner } from '../../utils/skiold-icon-spinner';
import { AnalysisModalMode } from 'shared/state/ducks/analysis/analysis-modal/operation';
import { StoreContainer } from 'shared/state/store-container';
import { getAgePeriodTitle } from '../analysis-result-young-female-age/analysis-result-young-female-age-helper';
import { AnalysisSettingType, IAnalysisResultYoungFemaleAgeSetting } from 'shared/api/api';

interface PropsFromParent {
	count?: number;
	mode: AnalysisModalMode;
	textForCounter?: string;
	value?: string;
	dateFrom?: Date;
	dateTo?: Date;
	printData: () => void;
	closeModal: () => void;
}

export class AnalysisModalHeader extends React.PureComponent<PropsFromParent> {
	public render() {
		return (
			<ViewWeb className="analysis-modal-result-per-litter-container">
				<SkioldAnimalCounterModal text={this.props.textForCounter} count={this.props.count} />
				<WhiteText className="alignSelfCenter">{this.generateText()}</WhiteText>
				{this.props.dateFrom && this.props.dateTo && (
					<>
						<WhiteText className="alignSelfCenter paddingLeftTen">
							{localized('FromDate') + ': ' + getDateString(this.props.dateFrom)}
						</WhiteText>
						<WhiteText className="alignSelfCenter paddingLeftTwenty">
							{localized('ToDate') + ': ' + getDateString(this.props.dateTo)}
						</WhiteText>
					</>
				)}

				<SkioldModalButtonContainer closeModal={this.props.closeModal}>
					<ViewWeb className="alignItemsCenter">
						<SkioldIconSpinner
							title={localized('PrintList')}
							icon={PrinterGreyIcon}
							onPress={this.props.printData}
						/>
					</ViewWeb>
				</SkioldModalButtonContainer>
			</ViewWeb>
		);
	}

	private generateText() {
		if (this.props.mode === AnalysisModalMode.ResultPerLitter) {
			return localized('Litters') + ': ' + this.GetLitterNumber();
		} else if (this.props.mode === AnalysisModalMode.YoungFemaleAge) {
			let agePeriodString = this.GetAgePeriod();
			if(agePeriodString === ' '){
				return `${localized('TotalPeriod')}: `;
			}else{
				return `${localized('age')}: ${agePeriodString}  ${localized('period')}: `;
			}
		
		}
	}

	private GetAgePeriod() {
		const storeContainer = StoreContainer.store.getState();
		const setting = storeContainer.analysisSettings.analysisSettings.find(
			analysisSetting => analysisSetting.type === AnalysisSettingType.AnalysisResultYoungFemaleAgeSetting
		);
		if (setting && this.props.value) {
			return getAgePeriodTitle(this.props.value, setting as IAnalysisResultYoungFemaleAgeSetting);
		}
		return ' ';
	}

	private GetLitterNumber() {
		if (this.props.value) {
			const litterNumber = Number(this.props.value);
			if (litterNumber < 9) {
				return this.props.value;
			}
			if (litterNumber === 9) {
				return '9+';
			}
			if (litterNumber === 10) {
				return localized('All');
			}
		}
		return '';
	}
}
