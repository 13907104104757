
import { GrowthPigTuneProduction } from 'shared/api/api';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import actionCreatorFactory from 'typescript-fsa';
import * as types from './types';

const actionCreator = actionCreatorFactory(GenActionFactoryName('growthPigTuneProduction'));

export const upsertGrowthPigTuneProduction = actionCreator.async<GrowthPigTuneProduction[], void>(
    types.UPSERT_GROWTH_PIG_TUNE_PRODUCTION
);

export const getGrowthPigTuneProductionBySiteId = actionCreator.async<{ siteId: string;  activeSiteId?: string  }, GrowthPigTuneProduction[]>(
    types.GET_GROWTH_PIG_TUNE_PRODUCTION_BY_SITEID
);
