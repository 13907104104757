
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import actionCreatorFactory from 'typescript-fsa';
import * as types from './types';
import { IDeadPigletsEvent, SyncDataDeadPigletsEvent } from 'shared/api/api';

const actionCreator = actionCreatorFactory(GenActionFactoryName('deadPigletsEvents'));

export const saveDeadPigletsEvent= actionCreator.async<
    IDeadPigletsEvent,
    string,
    { code: number; message: string; prevEntity: IDeadPigletsEvent | undefined }
>(types.SAVE_DEAD_PIGLETS_EVENT);

export const removeDeadPigletsEvent = actionCreator.async<string, string>(types.REMOVE_DEAD_PIGLETS_EVENT);
export const getSyncDataDeadPigletsEvent = actionCreator.async<{ siteId: string; lastSyncDate: Date; activeSiteId?: string  }, SyncDataDeadPigletsEvent>(types.SYNC_DEAD_PIGLETS_EVENT);

export const getDeparturedDeadPigletsEvent = actionCreator.async<{siteId: string; activeSiteId?: string}, IDeadPigletsEvent[]>(
    types.GET_DEPARTURED_DEAD_PIGLETS_EVENT
);

export const getPigletEventsByPeriod = actionCreator.async<{siteId: string; fromDate: Date, toDate: Date}, IDeadPigletsEvent[]>(
    types.GET_PIGLET_EVENTS_BY_PERIOD
);