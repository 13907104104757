import React from 'react';
import { IMatingBatch } from 'shared/api/api';
import PrinterGreyIcon from 'shared/assets/src-assets/png/printer_ikon_grey.png';
import { getDateString } from 'shared/helpers/date-helpers';
import { localized } from 'shared/state/i18n/i18n';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { SkioldAnimalCounterModal } from '../skiold-components/skiold-modal/skiold-animal-counter-modal/skiold-animal-counter-modal';
import { SkioldModalButtonContainer } from '../skiold-components/skiold-modal/skiold-modal-button-container/skiold-modal-button-container';
import { WhiteText } from '../Text/white-text';
import { SkioldIconSpinner } from '../utils/skiold-icon-spinner';

interface PropsFromParent {
	count?: number;
	matingBatch?: IMatingBatch;
	dateFrom?: Date;
	dateTo?: Date;
	printData?: () => void;
	closeModal: () => void;
	hideShowCount?: boolean;
}

export class DashboardModalHeader extends React.PureComponent<PropsFromParent> {
	public render() {
		return (
			<ViewWeb className="print-container">
				{!this.props.hideShowCount &&<SkioldAnimalCounterModal count={this.props.count} />}
				{this.props.matingBatch && (
					<WhiteText className="alignTextCenter">
						{localized('matingBatch') + ': ' + this.props.matingBatch.batchNumber}
					</WhiteText>
				)}
				{this.props.matingBatch && (
					<WhiteText className="alignTextCenter paddingLeftTen">
						{localized('from') +
							': ' +
							getDateString(this.props.matingBatch.matingPeriodStart) +
							' - ' +
							getDateString(this.props.matingBatch.matingPeriodEnd)}
					</WhiteText>
				)}
				{!this.props.matingBatch && this.props.dateFrom && this.props.dateTo && (
					<WhiteText className="alignTextCenter paddingLeftTen">
						{localized('matingsFrom') +
							': ' +
							getDateString(this.props.dateFrom) +
							' - ' +
							getDateString( this.props.dateTo)}
					</WhiteText>
				)}
				<SkioldModalButtonContainer closeModal={this.props.closeModal}>
					<ViewWeb className="alignItemsCenter">
						{this.props.printData &&
						<SkioldIconSpinner
							title={localized('PrintList')}
							icon={PrinterGreyIcon}
							onPress={this.props.printData}
						/>}
					</ViewWeb>
				</SkioldModalButtonContainer>
			</ViewWeb>
		);
	}
}
