import React from 'react';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { WebAppState } from 'web/state/store.web';
import { WorkListType, IMatingListSetting, MatingListSetting, IWorkListSetting } from 'shared/api/api';
import { SaveWorkListSetting } from 'shared/state/ducks/work-list-settings/operations';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { localized } from 'shared/state/i18n/i18n';
import { SkioldFormsWrapper } from '../../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { deepCopy } from 'shared/helpers/general-helpers';
import { FormRow } from '../../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { getSkioldInputRow, getAvailablityRow } from '../work-list-helper';
import { SkioldButton } from '../../skiold-components/skiold-button/skiold-button';
import '../list-setup.scss';

interface PropsFromParent {
	workListType: WorkListType;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		matingListSetting: state.workListSettings.entities.find(wl => wl.type === WorkListType.MatingListSetting),
		siteId: state.profile.active!.siteId
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	saveWorkListSetting: (matingListSetting: IMatingListSetting) => SaveWorkListSetting(matingListSetting)(dispatch)
});

export interface State {
	matingListSetting: IMatingListSetting;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

class MatingListSetup extends React.PureComponent<Props, State> {
	public static getDerivedStateFromProps(nextProps: Props, prevState: State): Partial<State> {
		if (!prevState.matingListSetting.id && nextProps.matingListSetting) {
			return {
				matingListSetting: { ...prevState.matingListSetting, ...nextProps.matingListSetting }
			};
		}
		return {};
	}
	constructor(props: Props) {
		super(props);
		this.state = {
			matingListSetting: MatingListSetting.fromJS({ type: WorkListType.MatingListSetting })
		};
	}

	public toggleShowOnWeb() {
		this.setState(prevState => ({
			matingListSetting: {
				...prevState.matingListSetting,
				showOnWeb: !this.state.matingListSetting.showOnWeb
			}
		}));
	}

	public toggleShowOnApp() {
		this.setState(prevState => ({
			matingListSetting: {
				...prevState.matingListSetting,
				showOnApp: !this.state.matingListSetting.showOnApp
			}
		}));
	}

	public farrowingListSetupChanged(setup: IWorkListSetting) {
		this.setState({
			matingListSetting: setup
		});
	}

	public render() {
		return (
			<ViewWeb className="list-setup">
				<ViewWeb className="view-container">
					<TextWeb className="text-style">{localized('Setup')}</TextWeb>
					<SkioldFormsWrapper formRows={this.getFormRows()} containerClassName="wrapper-container" />

					{this.renderButtons()}
				</ViewWeb>
			</ViewWeb>
		);
	}

	private async save() {
		if (!this.validate(this.state.matingListSetting)) {
			return;
		}

		let farrowingListSetup = deepCopy(this.state.matingListSetting);
		farrowingListSetup.siteId = this.props.siteId;

		this.props.saveWorkListSetting(farrowingListSetup);
	}

	private getFormRows() {
		let formRows = new Array<FormRow>();

		formRows.push(this.getBuildingHeader());
		formRows.push(
			getSkioldInputRow(
				setup => this.farrowingListSetupChanged(setup),
				this.state.matingListSetting,
				'name',
				localized('name')
			)
		);
		formRows.push(this.getStateRow());
		formRows.push(
			getAvailablityRow(
				() => this.toggleShowOnWeb(),
				() => this.toggleShowOnApp(),
				this.state.matingListSetting.showOnWeb!,
				this.state.matingListSetting.showOnApp!
			)
		);

		return formRows;
	}

	private validate(listSetting: IMatingListSetting) {
		return true;
	}

	private getBuildingHeader(): FormRow {
		return {
			header: localized(this.props.workListType)
		};
	}

	private getStateRow(): FormRow {
		return {
			name: localized('condition'),
			component: <TextWeb className="condition-container">{localized('Dry')}</TextWeb>
		};
	}

	private renderButtons() {
		let buttons = (
			<ViewWeb className="view-button-container">
				<SkioldButton title={localized('Save')} onPress={() => this.save()} />
			</ViewWeb>
		);

		return buttons;
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MatingListSetup);
