import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSyncBreedTableItems } from 'shared/state/ducks/breed-table-items/operation';
import { selectFamilyTreeById } from 'shared/state/ducks/stem-animals/selectors';
import { WebAppState } from 'web/state/store.web';
import PageContainer from '../page-container/page-container';
import { ViewWeb } from '../utils/web-view';
import { BreedTable } from './breed-table';

interface PropsFromParent {}

export const BreedTableOverview: FC<PropsFromParent> = React.memo(() => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getSyncBreedTableItems());
	}, [dispatch]);

	return (
		<PageContainer>
			<BreedTable />
		</PageContainer>
	);
});
