import 'chartjs-adapter-moment';
import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IPen } from 'shared/api/api';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { sortByOrder } from '../location/location-overview-components/location-tree-components/location-overview-helper';
import { ViewWeb } from '../utils/web-view';
import '../dashboard-growth-pigs/elaboration/feed-usage-graph/feed-usage-graph.scss';
import { SkioldImage } from '../utils/svg/skiold-image';
import leftArrow from '../../../../shared/assets/src-assets/svg/left_arrow.svg';
import { SkioldTouchableOpacity } from '../skiold-components/skiold-touchable-opacity';
import rightArrow from '../../../../shared/assets/src-assets/svg/right_arrow.svg';

interface PropsFromParent {
	sectionId: string | undefined;
	penId: string | undefined;
	setPenId: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const SowFeedUsagePenSlider: FC<PropsFromParent> = React.memo(({ sectionId, penId, setPenId }) => {
	const pens = useSelector((state: WebAppState) => state.locations.pens);

	const pensInSection = useMemo(() => {
		return sortByOrder(pens.filter(p => p.sectionId === sectionId)) as IPen[];
	}, [pens, sectionId]);

	const index = useMemo(() => {
		if (pensInSection && penId) {
			return pensInSection.findIndex(p => p.id === penId);
		}
		return -1;
	}, [pensInSection, penId]);

	const prevPen = () => {
		if (index > 0 && setPenId) {
			setPenId(pensInSection[index - 1].id);
		} else if (index === 0 && setPenId) {
			setPenId(undefined);
		}
	};

	const nextPen = () => {
		if ((index === -1 && pensInSection.length > 0) || (index < pensInSection.length - 1 && setPenId)) {
			setPenId(pensInSection[index + 1].id);
		}
	};

	return (
		<ViewWeb className="feed-usage-slider">
			<SkioldTouchableOpacity onPress={prevPen}>
				<SkioldImage
					imageData={leftArrow}
					className={`slider-arrow ${penId === undefined ? 'disabled' : ''}`}
				/>
			</SkioldTouchableOpacity>
			<TextWeb className="slider-text">{localized('pen')}: </TextWeb>
			{pensInSection[index]?.name && (
				<TextWeb className="pen-text">{pensInSection[index]?.name ? pensInSection[index]?.name : ''}</TextWeb>
			)}
			<SkioldTouchableOpacity onPress={nextPen}>
				<SkioldImage
					imageData={rightArrow}
					className={`slider-arrow ${index === pensInSection.length - 1 ? 'disabled' : ''}`}
				/>
			</SkioldTouchableOpacity>
		</ViewWeb>
	);
});
