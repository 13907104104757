import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IReason, IReasonDto, Reason, ReasonDto } from 'shared/api/api';
import { deepCopy2 } from 'shared/helpers/general-helpers';
import { SaveReason } from 'shared/state/ducks/reasons/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { showAlert } from '../skiold-alert/skiold-alert';
import { SkioldButton } from '../skiold-components/skiold-button/skiold-button';
import { SkioldIntegerTextField } from '../skiold-components/skiold-text-field/skiold-integer-text-field';
import { SkioldTextField } from '../skiold-components/skiold-text-field/skiold-text-field';
import './reason-modal.scss';
const mapStateToProps = (state: WebAppState) => {
	return {};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	saveReason: (reason: IReasonDto) => SaveReason(reason)(dispatch),
});

interface ReasonModalPropsFromParent {
	reasonDto: IReasonDto;
	close(): void;
}

interface ReasonModalState {
	reasonDto: IReasonDto;
}
type ReasonModalProps = ReturnType<typeof mapStateToProps> &
	ReturnType<typeof mapDispatchToProps> &
	ReasonModalPropsFromParent;
export class ReasonModal extends React.PureComponent<ReasonModalProps, ReasonModalState> {
	constructor(props: ReasonModalProps) {
		super(props);
		let item = new ReasonDto();
		let reason = Reason.fromJS({});
		reason.init({ name: { en: '', da: '' } });
		item.reason = reason;

		this.state = {
			reasonDto:
				props.reasonDto && props.reasonDto.reason && props.reasonDto.reason.name ? props.reasonDto : item,
		};
	}

	public UNSAFE_componentWillReceiveProps() {
		if (this.state.reasonDto !== this.props.reasonDto) {
			this.setState({
				reasonDto: this.props.reasonDto,
			});
		}
	}

	public enNameChanged = (name: string) => {
		const r = deepCopy2(this.state.reasonDto);
		r.reason!.name!['en'] = name;
		this.setState({ reasonDto: r });
	};

	public daNameChanged = (name: string) => {
		const r = deepCopy2(this.state.reasonDto);
		r.reason!.name!['da'] = name;
		this.setState({ reasonDto: r });
	};

	public codeChanged = (code: number | undefined) => {
		const r = deepCopy2(this.state.reasonDto);
		r.reason!.priorityCode = code;

		this.setState({ reasonDto: r });
	};

	public render() {
		return (
			<ViewWeb>
				<ViewWeb className="reason-modal">
					<SkioldTextField
						onChangeText={this.enNameChanged}
						label={localized('reasonNameEn')}
						className={'white-text'}
						value={
							this.state.reasonDto.reason!.name !== undefined
								? this.state.reasonDto.reason!.name['en']
								: ''
						}
					/>
					<SkioldTextField
						onChangeText={this.daNameChanged}
						label={localized('reasonNameDa')}
						className={'white-text'}
						value={
							this.state.reasonDto.reason!.name !== undefined
								? this.state.reasonDto.reason!.name['da']
								: ''
						}
					/>

					<SkioldIntegerTextField
						onChangeNumber={this.codeChanged}
						className={'white-text'}
						label={localized('reasonCode')}
						value={this.state.reasonDto.reason!.priorityCode}
					/>

					<ViewWeb className="button-container">
						<SkioldButton onPress={() => this.props.close()} title={localized('Close')} theme="grey" />
						<SkioldButton
							onPress={() => {
								this.save();
							}}
							title={localized('Save')}
						/>
					</ViewWeb>
				</ViewWeb>
			</ViewWeb>
		);
	}

	private async save() {
		if (!this.validate()) {
			return;
		}
		let item = new ReasonDto();
		item.init(this.state.reasonDto);
		await this.props.saveReason(item);

		this.resetForm();
		this.props.close();
	}

	private resetForm() {
		this.setState({
			reasonDto: new ReasonDto({ reason: Reason.fromJS(({ name: { en: '', da: '' } } as unknown) as IReason) }),
		});
	}

	private validate() {
		if (!this.state.reasonDto.reason!.name) {
			showAlert(localized('VALIDATION_ERROR_FieldsRequired'));
			return false;
		}

		if (this.state.reasonDto.reason!.priorityCode === undefined) {
			showAlert(localized('VALIDATION_ERROR_FieldsRequired'));
			return false;
		}

		return true;
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(ReasonModal);
