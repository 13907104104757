import {
	ISlaughterhouseConnectionDto,
	SkioldOneClient,
	SlaughterhouseConnectionDto,
	SlaughterhouseCompany,
} from 'shared/api/api';
import { SkioldDigitalApiBaseUrl } from 'shared/constants';
import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import * as Action from './actions';
import { Dispatch } from 'redux';

export function GetSlaughterhouseConnectionData(siteId?: string) {
	return AsyncOperationBuilder2(
		Action.getSlaughterHouseConnections,
		client => client.slaughterHouseConnection_GetBySiteId(siteId),
		siteId,
	);
}

export function PostSlaughterhouseConnectionDto(data: ISlaughterhouseConnectionDto) {
	return AsyncOperationBuilder2(
		Action.postSlaughterHouseConnections,
		client => client.slaughterHouseConnection_Post(data as SlaughterhouseConnectionDto),
		data as SlaughterhouseConnectionDto,
	);
}

export function SlaughterHouseConnectionCreateConnection(data: ISlaughterhouseConnectionDto) {
	return AsyncOperationBuilder2(
		Action.postSlaughterHouseConnections,
		client => client.slaughterHouseConnection_CreateConnection(data as SlaughterhouseConnectionDto),
		data as SlaughterhouseConnectionDto,
	);
}

export function GenerateRefreshTokenForSiteId(code: string, siteId: string) {
	return new SkioldOneClient(SkioldDigitalApiBaseUrl).slaughterHouseConnection_GenerateRefreshToken(code, siteId);
}

export function GetDanishCrownAuthState(data: ISlaughterhouseConnectionDto) {
	return new SkioldOneClient(SkioldDigitalApiBaseUrl).slaughterHouseConnection_GetDanishCrownAuthState(
		data.farmNumber,
		data.password,
	);
}

export function SetSlaughterhouseConnection(connection: SlaughterhouseCompany) {
	return async (dispatch: Dispatch<any>) => {
		dispatch(Action.setSlaughterHouseConnection(connection));
	};
}
