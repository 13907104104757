import * as types from './types';
import actionCreatorFactory from 'typescript-fsa';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import { SyncNotifcations } from 'shared/api/api';

const actionCreator = actionCreatorFactory(GenActionFactoryName('notifications'));

export const getAlarms = actionCreator.async<{}, SyncNotifcations, { code: number; message: string }>(types.GET_ALARMS);

export const getWarnings = actionCreator.async<{}, SyncNotifcations, { code: number; message: string }>(
    types.GET_WARNINGS
);

export const acknowledgeAlarm = actionCreator.async<string, void, { code: number; message: string }>(types.ACK_ALARM);

export const acknowledgeWarning = actionCreator.async<string, void, { code: number; message: string }>(types.ACK_WARNING);
