import { Syncable } from "shared/state/models/syncable";
import { IDiagnose, IImage } from "shared/api/api";


export const SAVE_DIAGNOSE = 'SAVE_DIAGNOSE';
export const DELETE_DIAGNOSE = 'DELETE_DIAGNOSE';
export const SYNC_DATA_DIAGNOSES = 'SYNC_DATA_DIAGNOSES';

export const SAVE_IMAGE = 'SAVE_IMAGE';
export const GET_IMAGE = 'GET_IMAGE';

export interface DiagnoseState extends Syncable<IDiagnose> {
    diagnoseImages: IImage[];
    diagoseImageUpdates: IImage[];
    finishedSaving: boolean;
}
