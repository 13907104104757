import React, { FC, useEffect, useState } from 'react';
import { localized, localizedDynamic } from 'shared/state/i18n/i18n';
import { SkioldFormDropdown } from 'web/view/components/skiold-components/skiold-dropdown/skiold-form-dropdown';
import { SkioldFormsWrapper } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { useGeneralSetting } from './general-settings-overview';
import { Option } from 'react-dropdown';
import { DistriwinFeedUnitType, FuOrKg } from 'shared/api/api';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { SkioldCheckbox } from 'web/view/components/skiold-components/skiold-checkbox/skiold-checkbox';
import { TextWeb } from 'web/web-helpers/styled-text-components';

interface Props {}

const getDistriwinFeedUnitTypeOptions = (): Option[] => {
	return Object.keys(DistriwinFeedUnitType).map(e => {
		return { label: localizedDynamic(e), value: DistriwinFeedUnitType[e as any] } as Option;
	});
};

export const DistriwinFeedUnitSetting: FC<Props> = React.memo(() => {
	const { generalSetting, setGeneralSetting } = useGeneralSetting();

	const [options] = useState<Option[]>(getDistriwinFeedUnitTypeOptions);
	const [selectedOption, setSelectedOption] = useState<Option | undefined>({ label: ' ', value: '' } as Option);

	useEffect(() => {
		if (generalSetting.distriwinFeedUnitType) {
			setSelectedOption({
				label: localizedDynamic(generalSetting.distriwinFeedUnitType),
				value: DistriwinFeedUnitType[generalSetting.distriwinFeedUnitType],
			} as Option);
		} else {
			setSelectedOption({
				label: localizedDynamic(DistriwinFeedUnitType.FEsoFEsv),
				value: DistriwinFeedUnitType.FEsoFEsv,
			} as Option);
		}
	}, [generalSetting]);

	const getFormRows = () => {
		let formRows = new Array<FormRow>();

		formRows.push(getWorkListHeader());
		formRows.push(getEntranceTypeRow());
		formRows.push(getShowFuOrKgRow());
		//formRows.push(getUsageAttchedToRecipeRow());
		return formRows;
	};

	const optionChanged = (option: Option) => {
		setGeneralSetting({ ...generalSetting, distriwinFeedUnitType: option.value as DistriwinFeedUnitType });
	};

	const getEntranceTypeRow = (): FormRow => {
		return {
			name: localized('EnergyIndicatedBy'),
			component: (
				<SkioldFormDropdown items={options} selectedValue={selectedOption} onValueChanged={optionChanged} />
			),
		};
	};

	const getWorkListHeader = (): FormRow => {
		return {
			header: localized('Feed'),
		};
	};

	const getShowFuOrKgRow = (): FormRow => {
		return {
			name: localized('UsageShownAs'),
			component: (
				<ViewWeb className="two-checkbox-container">
					<SkioldCheckbox onClick={setFu} isChecked={generalSetting.showFuOrKg=== FuOrKg.Fu} />
					<TextWeb>{localized(FuOrKg.Fu)}</TextWeb>
					<SkioldCheckbox onClick={setKg} isChecked={generalSetting.showFuOrKg === FuOrKg.Kg} />
					<TextWeb>{localized(FuOrKg.Kg)}</TextWeb>
				</ViewWeb>
			),
		};
	};

	const setFu = () => {
		setGeneralSetting({ ...generalSetting, showFuOrKg: FuOrKg.Fu });
	};

	const setKg = () => {
		setGeneralSetting({ ...generalSetting, showFuOrKg: FuOrKg.Kg });
	};

	const getUsageAttchedToRecipeRow = (): FormRow => {
		return {
			name: localized('ShouldUsageBeAttachedToRecipe'),
			component: (
				<ViewWeb className="two-checkbox-container">
					<SkioldCheckbox onClick={setAttachedTrue} isChecked={generalSetting.feedAttactedToRecipe} />
					<TextWeb>{localized('True')}</TextWeb>
					<SkioldCheckbox onClick={setAttachedFalse} isChecked={!generalSetting.feedAttactedToRecipe} />
					<TextWeb>{localized('False')}</TextWeb>
				</ViewWeb>
			),
		};
	};

	const setAttachedTrue = () => {
		setGeneralSetting({ ...generalSetting, feedAttactedToRecipe: true });
	};

	const setAttachedFalse = () => {
		setGeneralSetting({ ...generalSetting, feedAttactedToRecipe: false });
	};

	return <SkioldFormsWrapper formRows={getFormRows()} containerClassName="wrapper-container" />;
});
