import { IAnalysisResultYoungFemaleAgeSetting } from 'shared/api/api';

export function getAgePeriodTitle(key: string, setting?: IAnalysisResultYoungFemaleAgeSetting) {
	if (setting && setting.agePeriods) {
		const period = setting.agePeriods.find(
			period => period.agePeriod && period.agePeriod.toLowerCase() === key.toLowerCase()
		);
		if (period && period.fromAge && period.toAge) {
			return period.fromAge.toString() + ' - ' + period.toAge.toString();
		}
	}
	return ' ';
}
