import React, { FC, useEffect, useState } from 'react';
import { Option } from 'react-dropdown';
import { useSelector } from 'react-redux';
import { DistriwinComponentType, DistriwinFeedUnitType } from 'shared/api/api';
import {
	FeedingComponentToFeedingTableRow,
	generateFeedingRelationTableRows,
} from 'shared/helpers/feeding/create-distriwin-feeding-relation-helper';
import { localized, localizedInterpolation } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { SkioldDropdown } from 'web/view/components/skiold-components/skiold-dropdown/skiold-dropdown';
import SkioldTableGrid from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { SkioldCheckbox } from '../../skiold-components/skiold-checkbox/skiold-checkbox';
import { GetFeedingName } from '../food-helper';
import { useCreateFeedingRelation } from './create-distriwin-feeding-relation';

interface PropsFromParent {}

const DefaultOption: Option = { label: ' ', value: '' };

export const CreateDistriwinFeedingRelationTable: FC<PropsFromParent> = React.memo(() => {
	const { data, setData, processEquipment } = useCreateFeedingRelation();

	const feedingComponents = useSelector((state: WebAppState) => state.distriwinFeedComponents.entities);
	const feedings = useSelector((state: WebAppState) => state.feeding.feedings);
	const feedingSubjects = useSelector((state: WebAppState) => state.feedingSubject.entities);
	const feedingCategories = useSelector((state: WebAppState) => state.feedingCategory.entities);
	const user = useSelector((state: WebAppState) => state.profile.active);
	const generalSetting = useSelector((state: WebAppState) => state.generalSettings.entity);

	const [feedingSubjectOptions, setFeedingSubjectOptions] = useState<Option[]>([]);
	const feedingSubjectCell = (item: FeedingComponentToFeedingTableRow) => {
		const feedingChanged = (option: Option) => {
			let tableData = [...data];
			const index = tableData.findIndex(row => row.componentObjectId === item.componentObjectId);
			if (index >= 0) {
				const feeding = feedings.find(f => f.id === option.value);
				if (feeding) {
					const feedingSubject = feedingSubjects.find(fs => fs.id === feeding.feedingSubjectId);
					if (feedingSubject) {
						const feedingCategory = feedingCategories.find(e => e.id === feedingSubject.feedingCategoryId);
						if (feedingCategory) {
							tableData[index] = {
								...tableData[index],
								feedingId: option.value,
								skioldFeeding: GetFeedingName(feedingSubject, user),
								skioldType: GetFeedingName(feedingCategory, user),
							} as FeedingComponentToFeedingTableRow;
						}
					}
				} else {
					tableData[index] = {
						...tableData[index],
						feedingId: undefined,
						skioldFeeding: undefined,
						skioldType: undefined,
					} as FeedingComponentToFeedingTableRow;
				}
			}
			setData(tableData);
		};

		// Only allow a 1-to-1 relation between distriwin component and SKIOLD digital feeding
		const filteredOptions = feedingSubjectOptions.filter(
			o =>
				item.distriwinType === DistriwinComponentType.Water ||
				o.value === item.feedingId ||
				!data.find(e => e.feedingId === o.value),
		);
		const selectedOption = filteredOptions.find(o => o.value === item.feedingId);
		return (
			<SkioldDropdown
				disabled={item.noRelation}
				usedInTable={true}
				theme={'dark'}
				items={filteredOptions}
				selectedValue={selectedOption ? selectedOption : DefaultOption}
				onValueChanged={feedingChanged}
			/>
		);
	};

	const noRelationCell = (item: FeedingComponentToFeedingTableRow) => {
		const onChange = () => {
			let tableData = [...data];
			const index = tableData.findIndex(row => row.componentObjectId === item.componentObjectId);
			if (index >= 0) {
				const toggleValue = !item.noRelation;
				if (toggleValue) {
					tableData[index] = {
						...tableData[index],
						feedingId: undefined,
						skioldFeeding: undefined,
						skioldType: undefined,
						noRelation: toggleValue,
					} as FeedingComponentToFeedingTableRow;
				} else {
					tableData[index] = {
						...tableData[index],
						noRelation: toggleValue,
					};
				}
			}
			setData(tableData);
		};
		return <SkioldCheckbox isChecked={item.noRelation} onClick={onChange} />;
	};

	const groupedHeaders = [
		{
			title: localized('Distriwin'),
			children: [
				{ columnName: 'distriwinName' },
				{ columnName: 'distriwinType' },
				{ columnName: 'distriwinEnergy1' },
			],
		},
		{
			title: localized('SkioldDigital'),
			children: [{ columnName: 'noRelation' }, { columnName: 'skioldFeeding' }, { columnName: 'skioldType' }],
		},
	];

	const columns = [
		{
			title: localized('name'),
			name: 'distriwinName',
		},
		{
			title: localized('Type'),
			name: 'distriwinType',
		},
		{
			title: localizedInterpolation('EnergyKg', generalSetting.distriwinFeedUnitType + 'Energy1'),
			name: 'distriwinEnergy1',
		},
		{
			title: localized('NoRelation'),
			name: 'noRelation',
			getCellValue: noRelationCell,
		},
		{
			title: localized('FeedingSubject'),
			name: 'skioldFeeding',
			getCellValue: feedingSubjectCell,
		},
		{
			title: localized('Type'),
			name: 'skioldType',
		},
	];
	const columnExtes = [
		{
			width: 150,
			columnName: 'distriwinName',
		},
		{
			width: 150,
			columnName: 'distriwinType',
		},
		{
			width: 85,
			columnName: 'distriwinEnergy1',
		},
		{
			width: 150,
			columnName: 'noRelation',
		},
		{
			width: 300,
			columnName: 'skioldFeeding',
		},
		{
			width: 300,
			columnName: 'skioldType',
		},
	];
	// dynamicly add column and headers, depending on general setting
	if (generalSetting.distriwinFeedUnitType === DistriwinFeedUnitType.FEsoFEsv) {
		columns.splice(3, 0, {
			title: localizedInterpolation('EnergyKg', generalSetting.distriwinFeedUnitType + 'Energy2'),
			name: 'distriwinEnergy2',
		});
		columnExtes.splice(3, 0, {
			width: 85,
			columnName: 'distriwinEnergy2',
		});
		groupedHeaders[0].children.push({ columnName: 'distriwinEnergy2' });
	}

	// set feedingSubjectOptions
	useEffect(() => {
		if (feedings && user) {
			const options = feedings.map(f => {
				const feedingSubject = feedingSubjects.find(fs => fs.id === f.feedingSubjectId);
				return { label: GetFeedingName(feedingSubject, user), value: f.id } as Option;
			});

			options.unshift(DefaultOption);
			setFeedingSubjectOptions(options);
		}
	}, [feedingSubjects, user, feedings]);

	// generate data
	useEffect(() => {
		let data = generateFeedingRelationTableRows(
			feedings,
			feedingSubjects,
			feedingComponents,
			user,
			feedingCategories,
			processEquipment,
		);
		setData(data);
		//eslint-disable-next-line
	}, [feedings, feedingSubjects, feedingComponents, feedingCategories, processEquipment]);

	return (
		<ViewWeb className="flex-row-space-between gpe-should-depart-table-container">
			<SkioldTableGrid
				tableKey={'create-distriwin-feeding-relation'}
				ignoreSetCount={true}
				ColumnExtensions={columnExtes}
				columns={columns}
				data={data}
				groupedColumns={groupedHeaders}
				headerCapitalized={false}
			/>
		</ViewWeb>
	);
});
