import {
	IWorkListSetting,
	WorkListType,
	MoveToPregnancyLocation,
	MoveToFarrowingLocation,
	MoveToMatingLocation,
	FarrowingListSetting,
	MatingListSetting,
	WeaningListSetting,
	TasksInFarrowingStableSetting,
	TasksInFarrowingStableSetting2,
	VaccineListSetting1,
	VaccineListSetting2,
	VaccineListSetting3,
	ScanningListSetting,
	ScanningListSetting2,
	AllAnimalsEsfListSetting,
	ShortageEsfListSetting,
	MoveFromPregToPregSetting,
	MoveMatingToMatingSetting,
	DepartureWorkListSetting,
	LardScanningEventListSetting2,
	LardScanningEventListSetting,
	SowTaskSettings1,
	SowTaskSettings2,
} from 'shared/api/api';

export function NewWorkListSetting(workList: IWorkListSetting) {
	if (workList.type === WorkListType.MoveToPregnancyLocation) {
		return MoveToPregnancyLocation.fromJS(workList);
	}
	if (workList.type === WorkListType.MoveToFarrowingLocation) {
		return MoveToFarrowingLocation.fromJS(workList);
	}
	if (workList.type === WorkListType.MoveToMatingLocation) {
		return MoveToMatingLocation.fromJS(workList);
	}
	if (workList.type === WorkListType.FarrowingListSetting) {
		return FarrowingListSetting.fromJS(workList);
	}
	if (workList.type === WorkListType.MatingListSetting) {
		return MatingListSetting.fromJS(workList);
	}
	if (workList.type === WorkListType.WeaningListSetting) {
		return WeaningListSetting.fromJS(workList);
	}
	if (workList.type === WorkListType.TasksInFarrowingStableSetting) {
		return TasksInFarrowingStableSetting.fromJS(workList);
	}
	if (workList.type === WorkListType.TasksInFarrowingStableSetting2) {
		return TasksInFarrowingStableSetting2.fromJS(workList);
	}
	if (workList.type === WorkListType.VaccineListSetting1) {
		return VaccineListSetting1.fromJS(workList);
	}
	if (workList.type === WorkListType.VaccineListSetting2) {
		return VaccineListSetting2.fromJS(workList);
	}
	if (workList.type === WorkListType.VaccineListSetting3) {
		return VaccineListSetting3.fromJS(workList);
	}
	if (workList.type === WorkListType.ScanningListSetting) {
		return ScanningListSetting.fromJS(workList);
	}
	if (workList.type === WorkListType.ScanningListSetting2) {
		return ScanningListSetting2.fromJS(workList);
	}
	if (workList.type === WorkListType.AllAnimalsEsfListSetting) {
		return AllAnimalsEsfListSetting.fromJS(workList);
	}
	if (workList.type === WorkListType.ShortageEsfListSetting) {
		return ShortageEsfListSetting.fromJS(workList);
	}
	if (workList.type === WorkListType.MoveFromPregToPregSetting) {
		return MoveFromPregToPregSetting.fromJS(workList);
	}
	if (workList.type === WorkListType.MoveMatingToMatingSetting) {
		return MoveMatingToMatingSetting.fromJS(workList);
	}
	if (workList.type === WorkListType.DepartureWorkListSetting) {
		return DepartureWorkListSetting.fromJS(workList);
	}
	if (workList.type === WorkListType.LardScanningEventListSetting) {
		return LardScanningEventListSetting.fromJS(workList);
	}
	if (workList.type === WorkListType.LardScanningEventListSetting2) {
		return LardScanningEventListSetting2.fromJS(workList);
	}
	if (workList.type === WorkListType.SowTaskSettings1) {
		return SowTaskSettings1.fromJS(workList);
	}
	if (workList.type === WorkListType.SowTaskSettings2) {
		return SowTaskSettings2.fromJS(workList);
	}

	console.error('Worklist setting failed to rehydrate');
	return undefined;
}
