import { Dispatch } from 'redux';
import { ITasksInFarrowingBase } from 'shared/api/api';
import { SharedAppState } from 'shared/state/store.shared';


export const TaskListmapStateToProps = (state: SharedAppState) => {
    return {
        workListSettings: state.workListSettings.entities
    };
};

export const TaskListmapDispatchToProps = (dispatch: Dispatch, props: {}) => {
    return {};
};

// export const styles = StyleSheet.create({
//     flexRow: { flexDirection: 'row' },
//     containerView: { alignSelf: 'center', width: '100%', flexDirection: 'column' }
// });

export interface TaskListState {
    editModalOpen: boolean;
    workListSetting: ITasksInFarrowingBase;
}
