import {
	AnimalType,
	FileResponse,
	GrowthPigProductionReportData,
	IGrowthPigPReportTableDatasType,
	ProductionReportByGrowthPigAnimalTypes,
	SkioldOneClient,
} from 'shared/api/api';
import { SkioldDigitalApiBaseUrl } from 'shared/constants';
import { localized } from 'shared/state/i18n/i18n';
import { showAlert } from 'web/view/components/skiold-alert/skiold-alert';
import { savePdfAs } from './general-pdf-helper';

export function GetProductionReportPdf(
	isSpecialPeriod: boolean,
	siteId: string,
	fileName: string,
	timezone: string | undefined,
	lang?: string,
) {
	return new SkioldOneClient(SkioldDigitalApiBaseUrl)
		.productionReport_GetProductionReportPdf(isSpecialPeriod, siteId, timezone, lang)
		.then((fileResponse: FileResponse | null) => {
			savePdfAs(fileResponse, fileName);
		});
}

export function GetGrowthPigProductionReportPdf(
	data: IGrowthPigPReportTableDatasType,
	animalType: AnimalType,
	siteId: string,
	fileName: string,
	title: string,
	timezone: string | undefined,
	lang?: string,
	specialPeriod?: boolean,
) {
	return new SkioldOneClient(SkioldDigitalApiBaseUrl)
		.growthPigsReportPdf_GetProductionReportByGrowthPigAnimalTypes(
			{
				rows: data && data.tableData ? data.tableData : [],
				feedUsages: data && data.feedUsageItems ? data.feedUsageItems : [],
				animalType,
			} as ProductionReportByGrowthPigAnimalTypes,
			siteId,
			lang,
			timezone,
			title,
			specialPeriod,
		)
		.then((fileResponse: FileResponse | null) => {
			savePdfAs(fileResponse, fileName);
		});
}

export function GetProductionReport(isSpecialPeriod: boolean, siteId: string) {
	return new SkioldOneClient(SkioldDigitalApiBaseUrl).productionReport_GetProductionReport(
		isSpecialPeriod,
		siteId,
		Intl.DateTimeFormat().resolvedOptions().timeZone,
	);
}

export async function GetProductionReportGrowthPigs(
	isSpecialPeriod: boolean,
	siteId: string,
	animalType: AnimalType,
	correctAnimalType: AnimalType,
	sectionId?: string,
) {
	const report = await new SkioldOneClient(
		SkioldDigitalApiBaseUrl,
	).growthPigsReport_GetProductionReportByGrowthPigAnimalTypes(
		new GrowthPigProductionReportData({
			isSpecialPeriod,
			siteId,
			animalType,
			timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
			sectionId,
			correctAnimalType,
		}),
	);
	if (report.errorTranslationKey) {
		showAlert(localized(report.errorTranslationKey));
	}
	return report;
}

export const pReportDecimals = {
	AvgWeightPerPiglet: 1,
	AvgWeanedPerLitter: 1,
	ThereOfYoungAnimalFood: 1,
	FePerYearSowPerDay: 1,
	WasteDaysPerDay: 1,
	ThereOfAdjustedDays: 1,
	LitterPerYearSowPcs: 2,
	WeanedPigletsPerYearSowPcs: 1,
	PeriodYearSowPcs: 1,
	ProducedLitters: 2,
};
