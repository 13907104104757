import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { DashboardOverviewState } from '.';
import * as actions from './actions';
import { siteChange } from '../profile/actions';

export const initialState: DashboardOverviewState = {
	loading: false,
	data: undefined,
};

const dashboardOverviewReducer = (
	state: DashboardOverviewState = initialState,
	action: Action
): DashboardOverviewState => {
	if (isType(action, siteChange.done)) {
		state = initialState;
	}
	if (isType(action, actions.getDashboardOverviewTable.started)) {
		
		state = { ...state, loading: action.payload.showSpinner };
	}

	if (isType(action, actions.getDashboardOverviewTable.done)) {
		if (action.payload.result) {
			state = {
				...state,
				data: action.payload.result,
			};
		}
		state = {
			...state,
			loading: false,
		};
	}

	if (isType(action, actions.getDashboardOverviewTable.failed)) {
		state = { ...state, loading: false };
	}

	return state;
};

export default dashboardOverviewReducer;
