import { actionCreatorFactory } from 'typescript-fsa';
import * as types from './types';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import { IGeneralSettings, SyncGeneralSettings } from 'shared/api/api';

const actionCreator = actionCreatorFactory(GenActionFactoryName('generalSettings'));

export const saveDropdownScrollOption = actionCreator<types.DropdownScrollOption>(types.SAVE_DROPDOWN_SCROLL_OPTION);
export const resetDropdownScrollOptions = actionCreator<void>(types.RESET_DROPDOWN_SCROLL_OPTIONS);

export const saveGeneralSettings = actionCreator.async<
    IGeneralSettings,
    string,
    { code: number; message: string; prevEntity: IGeneralSettings | undefined }
>(types.SAVE_GENERALSETTINGS);
export const getSyncData = actionCreator.async<{ siteId: string; lastSyncDate: Date }, SyncGeneralSettings>(
    types.SYNC_DATA_GENERALSETTINGS
);

export const setGlobalDate = actionCreator<Date>(types.SET_GLOBAL_DATE);
