import { AnalysisPregnancyTableItem } from 'shared/api/api';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import actionCreatorFactory from 'typescript-fsa';
import * as types from './types';

const actionCreator = actionCreatorFactory(GenActionFactoryName('analysisPregnancy'));

export const getData = actionCreator.async<
	{ siteId: string; fromMatingBatch?: Date; toMatingBatch?: Date },
	AnalysisPregnancyTableItem
>(types.SYNC_DATA_ANALYSIS_PREGNANCY);
