import { IProcessEquipmentData, ProcessEquipmentData, SkioldOneClient, Equipment } from 'shared/api/api';
import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import * as Action from './actions';
import { StoreContainer } from 'shared/state/store-container';
import { SkioldDigitalApiBaseUrl } from 'shared/constants';

export async function FetchLocations(equipment: Equipment, code: string) {
	if (equipment === Equipment.Distriwin) {
		return await FetchDistriwinLocations(code);
	} else if (equipment === Equipment.ESF) {
		return await FetchEsfLocations(code);
	}
}
export async function FetchDistriwinLocations(code: string) {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;
	return await new SkioldOneClient(SkioldDigitalApiBaseUrl).locationsV2_FetchLocations(siteId, code);
}

export async function FetchEsfLocations(code: string) {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;
	return await new SkioldOneClient(SkioldDigitalApiBaseUrl).esfStation_RequestNewStations(siteId, code);
}
export function GetProcessEquipmentData() {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;
	return AsyncOperationBuilder2(
		Action.getProcessEquipmentData,
		client => client.processEquipmentLocationRelations_GetProcessEquipmentDataBySiteId(siteId),
		siteId,
	);
}
export function SaveProcessEquipmentLocationRelation(item: IProcessEquipmentData, reset: boolean) {
	let processEquipmentData = ProcessEquipmentData.fromJS({});
	processEquipmentData.init(item);
	return AsyncOperationBuilder2(
		Action.saveData,
		client => client.processEquipmentLocationRelations_Upsert(processEquipmentData, reset),
		item,
	);
}

export function SaveProcessEquipmentDataFromUnitToPenMap(item: IProcessEquipmentData) {
	let processEquipmentData = ProcessEquipmentData.fromJS({});
	processEquipmentData.init(item);
	return new SkioldOneClient(SkioldDigitalApiBaseUrl).processEquipmentLocationRelations_Upsert2(processEquipmentData);
}

export function GetProcessEquipmentDataSyncData() {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;
	const lastSyncDate = state.processEquipmentData.lastSyncDate;
	return AsyncOperationBuilder2(
		Action.getSyncData,
		client => client.processEquipmentLocationRelations_SyncProcessEquipmentData(siteId, lastSyncDate),
		{
			siteId,
			lastSyncDate,
		},
	);
}
