import React, { FC, useEffect, useState } from 'react';
import { Option } from 'react-dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { GrowthPigMoveEventDto, IGrowthPigMoveEventDto } from 'shared/api/api';
import {
	HandleGrowthPigDistributeAmountChanged,
	HandleGrowthPigDistributeTotalWeightNotDistributed,
	validateDistributeGrowthPigs,
} from 'shared/helpers/growth-pigs-helper/growth-pig-event-function-helper';
import { GrowthPigDepartureFromSectionRow } from 'shared/helpers/growth-pigs-helper/growth-pig-event-helper';
import { GrowthPigMoveEventBundleListRow } from 'shared/helpers/growth-pigs-helper/growth-pig-event-list-helper';
import {
	HandleMoveEditBeforeSave,
	HandlePenChangeMoveToGpeEdit,
	initEditGpeToMoveData,
} from 'shared/helpers/growth-pigs-helper/growth-pig-move-helpers';
import { getSectionBySectionId, sortByOrder } from 'shared/helpers/location-helper';
import { memoizeHashmapLocation } from 'shared/helpers/memoize-getters/memoize-getters';
import { removeEventsByBundleId, upsertGrowthPigsMoveEvents } from 'shared/state/ducks/growth-pig-events/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { showAlert } from 'web/view/components/skiold-alert/skiold-alert';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import {
	SkioldFormDecimalInput
} from 'web/view/components/skiold-components/skiold-decimal-input/skiold-form-decimal-input';
import {
	SkioldTableGrid
} from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { WhiteText } from 'web/view/components/Text/white-text';
import { Heading } from 'web/view/components/utils/heading';
import { ViewWeb } from 'web/view/components/utils/web-view';
import GrowthPigDistributeForm from '../../growth-pig-events/growth-pig-distribute-form';
import '../../growth-pig-events/growth-pig-distribute.scss';

interface PropsFromParent {
	closeModal: (saved?: boolean) => void;
	navigation?: any;
	bundle: GrowthPigMoveEventBundleListRow;
	fromSectionData: GrowthPigDepartureFromSectionRow[];
	registrationDate: Date | undefined;
}

export const GpeMoveToEditModal: FC<PropsFromParent> = React.memo(
	({ bundle, closeModal, fromSectionData, registrationDate }) => {
		const dispatch = useDispatch();

		// Get data
		const pens = useSelector((state: WebAppState) => state.locations.pens);
		const growthPigEvents = useSelector((state: WebAppState) => state.growthPigEvents.growthPigEvents);
		const hashmapLocations = useSelector((state: WebAppState) =>
			memoizeHashmapLocation(state.locations.buildings, state.locations.sections, state.locations.pens),
		);
		const siteId = useSelector((state: WebAppState) => state.profile.active && state.profile.active.siteId);

		// Set State
		const [fromPenOption, setFromPenOption] = useState<Option>();
		const [toPenOption, setToPenOption] = useState<Option>();
		const [penOptions, setPenOptions] = useState<Option[]>([]);
		const [pensToUse, setPensToUse] = useState<string[]>([]);
		const [amountPigsNotDistributed, setAmountPigsNotDistributed] = useState<number>(0);
		const [totalWeightNotDistributedState, setTotalWeightNotDistributed] = useState<number>(0);
		const [data, setData] = useState<IGrowthPigMoveEventDto[]>([]);
		const [blacklistedPenIds, setBlacklistedPenIds] = useState<string[]>([]);

		// ComponentDidMount
		useEffect(() => {
			const blacklistedPenIds = fromSectionData.map(x => x.penId!);
			// Setup pens options by bundleId
			const options = sortByOrder(
				pens.filter(p => !blacklistedPenIds.includes(p.id!) && p.sectionId === bundle.toSectionId),
			).map((p) => {
				return { label: p.name!, value: p.id! } as Option;
			});

			setPenOptions(options);
			setFromPenOption(options && options[0]);
			setToPenOption(options && options[options.length - 1]);
			setBlacklistedPenIds(blacklistedPenIds);
			initData(options);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		const close = (isSave?: boolean) => {
			if (closeModal) {
				closeModal(isSave);
			}
		};
		const initData = (options: Option[]) => {
			// Init table data, by current Gpe events
			const fromSectionCount = fromSectionData.reduce(
				(a, b) => a + (b.departedFromSection !== undefined ? b.departedFromSection : 0),
				0,
			);
			const newTotalWeight = fromSectionData.reduce(
				(a, b) =>
					a +
					(b.departedFromSection !== undefined && b.newAvgWeight
						? b.departedFromSection * b.newAvgWeight
						: 0),
				0,
			);

			const newAvgWeight = Math.round((newTotalWeight / fromSectionCount) * 10) / 10;
			const initData = initEditGpeToMoveData(
				options,
				growthPigEvents,
				bundle,
				hashmapLocations,
				siteId,
				newAvgWeight,
			);

			const toSectionCount = initData.reduce((a, b) => a + (b.amount !== undefined ? b.amount : 0), 0);
			setAmountPigsNotDistributed(fromSectionCount - toSectionCount);
			setPensToUse(options.map(e => e.value));
			setData(initData);
		};

		const onAmountChanged = (numb: number | undefined, item: IGrowthPigMoveEventDto) => {
			let dataArrayCopy = [...data];
			// Handle change item, and sets amount not Distributed
			let { currentAmountNotDistributed, totalWeightNotDistributed } = HandleGrowthPigDistributeAmountChanged(
				dataArrayCopy,
				item,
				numb,
				amountPigsNotDistributed,
				totalWeightNotDistributedState,
			);
			setData(dataArrayCopy);
			setAmountPigsNotDistributed(currentAmountNotDistributed);
			setTotalWeightNotDistributed(totalWeightNotDistributed);
		};

		const onWeightChanged = (numb: number | undefined, item: IGrowthPigMoveEventDto) => {
			let dataArrayCopy = [...data];
			// Handle change item, and sets amount not Distributed
			let currentTotalWeightNotDistributed = HandleGrowthPigDistributeTotalWeightNotDistributed(
				dataArrayCopy,
				item,
				numb,
				totalWeightNotDistributedState,
			);
			setData(dataArrayCopy);
			setTotalWeightNotDistributed(currentTotalWeightNotDistributed);
		};

		const OnFromPenChanged = (newValue?: Option) => {
			// recalculate distribution, and remove unused pens
			if (newValue) {
				const fromPenToUseIndex = penOptions.findIndex(p => newValue && p.value === newValue.value);
				const toPenToUseIndex = penOptions.findIndex(p => toPenOption && p.value === toPenOption.value);
				const pensToUse = penOptions.slice(fromPenToUseIndex, toPenToUseIndex + 1);
				let copyData = HandlePenChangeMoveToGpeEdit(
					data,
					pensToUse,
					fromSectionData.reduce(
						(a, b) => a + (b.departedFromSection !== undefined ? b.departedFromSection : 0),
						0,
					),
				);
				setPensToUse(pensToUse.map(e => e.value));
				setFromPenOption(newValue);
				setData(copyData);
			}
		};

		const OnToPenChanged = (newValue?: Option) => {
			// recalculate distribution, and remove unused pens
			if (newValue) {
				const fromPenToUseIndex = penOptions.findIndex(p => fromPenOption && p.value === fromPenOption.value);
				const toPenToUseIndex = penOptions.findIndex(p => newValue && p.value === newValue.value);
				const pensToUse = penOptions.slice(fromPenToUseIndex, toPenToUseIndex + 1);
				let copyData = HandlePenChangeMoveToGpeEdit(
					data,
					pensToUse,
					fromSectionData.reduce(
						(a, b) => a + (b.departedFromSection !== undefined ? b.departedFromSection : 0),
						0,
					),
				);
				setPensToUse(pensToUse.map(e => e.value));
				setToPenOption(newValue);
				setData(copyData);
			}
		};

		const getPenNameCell = (item: IGrowthPigMoveEventDto) => item.name;

		const getAmountCell = (item: IGrowthPigMoveEventDto) => (
			<SkioldFormDecimalInput
				text={item.amount ? item.amount : undefined}
				itemFromParent={item}
				onChangeNumber={onAmountChanged}
			/>
		);

		const getWeightCell = (item: IGrowthPigMoveEventDto) => (
			<SkioldFormDecimalInput
				text={item.amount ? item.avgWeight : undefined}
				itemFromParent={item}
				onChangeNumber={onWeightChanged}
			/>
		);

		const generateColumns = () => {
			return [
				{
					name: 'pen',
					title: localized('PenNumber'),
					getCellValue: getPenNameCell,
				},
				{
					name: 'amount',
					sortable: false,
					filterable: false,
					title: localized('Count'),
					getCellValue: getAmountCell,
				},
				{
					name: 'weight',
					sortable: false,
					filterable: false,
					title: localized('avgWeight'),
					getCellValue: getWeightCell,
				},
			] as any[];
		};

		const generateColumnExtes = () => {
			return [
				{
					columnName: 'pen',
					width: 230,
				},
				{
					columnName: 'amount',
					width: 230,
				},
				{
					columnName: 'weight',
					filteringEnabled: false,
					width: 230,
				},
			];
		};

		const save = async () => {
			if (validateDistributeGrowthPigs(totalWeightNotDistributedState, amountPigsNotDistributed, showAlert)) {
				const firstItem = data && data.length > 0 ? data[0] : undefined;
				const firstFromSectionData =
					fromSectionData && fromSectionData.length > 0 ? fromSectionData[0] : undefined;
				if (
					firstItem &&
					firstFromSectionData &&
					firstFromSectionData.productionType &&
					firstItem.date &&
					firstItem.toProductionType
				) {
					// Generete new move events, to handle the distribution among change pens
					const itemsToSave: IGrowthPigMoveEventDto[] = HandleMoveEditBeforeSave(
						data as IGrowthPigMoveEventDto[],
						fromSectionData,
						registrationDate ? registrationDate : firstItem.date,
						siteId,
						firstFromSectionData.productionType,
						firstItem.toProductionType,
						getSectionBySectionId(bundle.toSectionId, hashmapLocations),
					);
					if (itemsToSave.length > 0) {
						// Save new events
						upsertGrowthPigsMoveEvents(itemsToSave as GrowthPigMoveEventDto[])(dispatch);
						if (bundle.bundleId) {
							// Delete old events
							removeEventsByBundleId(bundle.bundleId)(dispatch);
						}
					}
				}

				close(true);
			}
		};

		const renderButtons = () => {
			return (
				<ViewWeb className="button-view-style">
					<SkioldButton theme="grey" title={ localized('Close') } onPress={ close }/>
					<SkioldButton title={ localized('Save') } onPress={ save }/>
				</ViewWeb>
			);
		};

		return (
			<ViewWeb className="growth-pig-distribute">
				<Heading text={localized('DistributePigsInSection')}></Heading>
				<ViewWeb className="distribute-container">
					<WhiteText className="bold-white-text">
						{localized('ToLocation')}: {bundle.toLocation}
					</WhiteText>
					<ViewWeb>
						<GrowthPigDistributeForm
							OnFromPenChanged={OnFromPenChanged}
							OnToPenChanged={OnToPenChanged}
							fromPenId={fromPenOption}
							toSectionId={bundle.toSectionId}
							toPenId={toPenOption}
							disablePenSelect={false}
							amountPigsNotDistributed={amountPigsNotDistributed}
							totalWeightNotDistributed={totalWeightNotDistributedState}
							blacklistedPenIds={blacklistedPenIds}
						/>
					</ViewWeb>
					<SkioldTableGrid
						ignoreSetCount={true}
						filterable={false}
						sortable={false}
						ColumnExtensions={generateColumnExtes()}
						columns={generateColumns()}
						data={data.filter(e => e.toPenId && pensToUse.includes(e.toPenId))}
						containerClassName={'growth-distribution-table-max-height'}
					/>
				</ViewWeb>
				{renderButtons()}
			</ViewWeb>
		);
	},
);
