import Styles from 'native/view/styles';
import React, { FC, useContext, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { AnimalType, IProcessEquipment } from 'shared/api/api';
import { getDateString } from 'shared/helpers/date-helpers';
import { FeedingComponentToFeedingTableRow } from 'shared/helpers/feeding/create-distriwin-feeding-relation-helper';
import { localized } from 'shared/state/i18n/i18n';
import PageContainer from '../../page-container/page-container';
import { Heading } from '../../utils/heading';
import { ViewWeb } from '../../utils/web-view';
import { CreateDistriwinFeedingRelationButtons } from './create-distriwin-feeding-relation-buttons';
import { CreateDistriwinFeedingRelationHeader } from './create-distriwin-feeding-relation-header';
import { CreateDistriwinFeedingRelationTable } from './create-distriwin-feeding-relation-table';

interface PropsFromParent {
	closeModal: () => void;
}

interface CreateDistriwinFeedingRelationContextModel {
	setData: React.Dispatch<React.SetStateAction<FeedingComponentToFeedingTableRow[]>>;
	data: FeedingComponentToFeedingTableRow[];
	processEquipment: IProcessEquipment | undefined;
	closeModal: () => void;
	setProcessEquipment: React.Dispatch<React.SetStateAction<IProcessEquipment | undefined>>;
}

export const CreateDistriwinFeedingRelationContext = React.createContext({} as any);
export const useCreateFeedingRelation = () =>
	useContext<CreateDistriwinFeedingRelationContextModel>(CreateDistriwinFeedingRelationContext);
export const CreateDistriwinFeedingRelation: FC<PropsFromParent> = React.memo(({ closeModal }) => {
	const [data, setData] = useState<FeedingComponentToFeedingTableRow[]>([]);
	const [processEquipment, setProcessEquipment] = useState<IProcessEquipment | undefined>();
	return (
		<PageContainer>
			<Heading allLettersCapitalized={false} text={localized('relationProcessEquipment')} />
			<CreateDistriwinFeedingRelationContext.Provider
				value={{
					data,
					setData,
					closeModal,
					setProcessEquipment,
					processEquipment,
				}}
			>
				<ViewWeb className="create-location-relations">
					<CreateDistriwinFeedingRelationHeader />
					<CreateDistriwinFeedingRelationTable />
					<CreateDistriwinFeedingRelationButtons />
				</ViewWeb>
			</CreateDistriwinFeedingRelationContext.Provider>
		</PageContainer>
	);
});
