import React from 'react';
import { Option } from 'react-dropdown';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { DrugTypeType, IDrugType } from 'shared/api/api';
import { getDrugTypeTypeOptions } from 'shared/helpers/drug-type-helper/drug-type-helper';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { deepCopy } from 'shared/helpers/general-helpers';
import { SaveDrugType } from 'shared/state/ducks/drugTypes/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { showAlert } from 'web/view/components/skiold-alert/skiold-alert';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldDropdown } from 'web/view/components/skiold-components/skiold-dropdown/skiold-dropdown';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { SkioldTextField } from '../../skiold-components/skiold-text-field/skiold-text-field';
import './add-drug-type.scss';
import { Heading } from '../../utils/heading';

interface PropsFromParent {
	close: () => void;
	drugType?: IDrugType;
	isOpen: boolean;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		drugTypes: state.drugType.entities,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	postDrugType: (drugType: IDrugType) => SaveDrugType(drugType)(dispatch),
});

interface State {
	drugType: IDrugType;
	selectedTypeOption: Option;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;
class AddDrugType extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			drugType: this.props.drugType || ({} as IDrugType),
			selectedTypeOption: { label: localized(DrugTypeType.Medicine), value: DrugTypeType.Medicine },
		};
	}

	public render() {
		return (
			<ViewWeb className="add-drug-type">
				<Heading text={localized('DrugType')} />
				<TextWeb className={'white-text'}>{localized('name')}</TextWeb>
				<SkioldTextField
					onChangeText={this.setName}
					label={localized('name')}
					className={'white-text'}
					value={this.state.drugType.name}
				/>
				<SkioldTextField
					onChangeText={this.setUnit}
					label={localized('Unit')}
					className={'white-text'}
					value={this.state.drugType.unitOfMeasurement}
				/>
				<SkioldDropdown
					items={getDrugTypeTypeOptions()}
					usedInTable={true}
					selectedValue={this.state.selectedTypeOption}
					onValueChanged={this.drugTypeTypeChanged}
					theme={'light'}
				/>

				<ViewWeb className="add-drug-type__button-container">
					<SkioldButton title={localized('Save')} onPress={this.save} />

					{this.state.drugType.id && (
						<SkioldButton title={localized('Delete')} onPress={this.delete} theme="grey" />
					)}

					<SkioldButton title={localized('Close')} onPress={this.props.close} theme="grey" />
				</ViewWeb>
			</ViewWeb>
		);
	}

	private drugTypeTypeChanged = (option: Option) => {
		const type = option.value as DrugTypeType;
		this.setState(prevState => ({ drugType: { ...prevState.drugType, type }, selectedTypeOption: option }));
	};

	private setUnit = (unit: string) => {
		this.setState(prevState => ({ drugType: { ...prevState.drugType, unitOfMeasurement: unit } }));
	};

	private setName = (name: string) => {
		this.setState(prevState => ({ drugType: { ...prevState.drugType, name } }));
	};

	private save = async () => {
		if (!this.validate()) {
			return;
		}

		this.props.postDrugType(this.state.drugType);

		this.props.close();
	};

	private delete = async () => {
		if (!window.confirm(localized('PromptSureDelete'))) {
			return;
		}

		const drugType = deepCopy(this.state.drugType);
		drugType.isDeleted = true;

		this.props.postDrugType(drugType);

		this.props.close();
	};

	private validate() {
		if (!this.state.drugType.name) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_NAME_NOT_SET));
			return false;
		}

		if (!this.state.drugType.unitOfMeasurement) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_UNIT_NOT_SET));
			return false;
		}

		return true;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AddDrugType);
