import { i18nTranslationKey, localized } from 'shared/state/i18n/i18n';

export class ExceptionMessage {
	// General
	public static VALIDATION_ERROR_FIELDS_REQUIRED_AREA(
		area: i18nTranslationKey,
		
	) {
		return `${localized(area)} ${localized('VALIDATION_ERROR_FieldsRequired_2')} `;
	}

	public static VALIDATION_ERROR_FIELDS_REQUIRED: i18nTranslationKey = 'VALIDATION_ERROR_FieldsRequired';
	public static VALIDATION_ERROR_CANNOT_BE_NEGATIVE_OR_ZERO: i18nTranslationKey =
		'VALIDATION_ERROR_CannotBeNegativeOrZero';

	public static VALIDATION_ERROR_INTERVALS_MAY_NOT_OVERLAP: i18nTranslationKey =
		'VALIDATION_ERROR_INTERVALS_MAY_NOT_OVERLAP';
	public static VALIDATION_ERROR_COUNTRYID_REQUIRED: i18nTranslationKey = 'VALIDATION_ERROR_COUNTRYID_REQUIRED';
	public static VALIDATION_ERROR_CHRNUM_REQUIRED: i18nTranslationKey = 'VALIDATION_ERROR_CHRNUM_REQUIRED';
	public static VALIDATION_ERROR_SITE_NAME_REQUIRED: i18nTranslationKey = 'VALIDATION_ERROR_SITE_NAME_REQUIRED';
	public static VALIDATION_ERROR_SITE_ADDRESS_REQUIRED: i18nTranslationKey = 'VALIDATION_ERROR_SITE_ADDRESS_REQUIRED';
	public static VALIDATION_ERROR_SITE_ZIPCODE_REQUIRED: i18nTranslationKey = 'VALIDATION_ERROR_SITE_ZIPCODE_REQUIRED';
	public static VALIDATION_ERROR_SITE_CITY_REQUIRED: i18nTranslationKey = 'VALIDATION_ERROR_SITE_CITY_REQUIRED';
	public static VALIDATION_ERROR_OWNER_NAME_REQUIRED: i18nTranslationKey = 'VALIDATION_ERROR_OWNER_NAME_REQUIRED';
	public static VALIDATION_ERROR_OWNER_ADDRESS_REQUIRED: i18nTranslationKey =
		'VALIDATION_ERROR_OWNER_ADDRESS_REQUIRED';
	public static VALIDATION_ERROR_OWNER_ZIPCODE_REQUIRED: i18nTranslationKey =
		'VALIDATION_ERROR_OWNER_ZIPCODE_REQUIRED';
	public static VALIDATION_ERROR_OWNER_CITY_REQUIRED: i18nTranslationKey = 'VALIDATION_ERROR_OWNER_CITY_REQUIRED';
	public static VALIDATION_ERROR_CVRNUM_REQUIRED: i18nTranslationKey = 'VALIDATION_ERROR_CVRNUM_REQUIRED';
	public static VALIDATION_ERROR_CANNOT_BE_NEGATIVE: i18nTranslationKey = 'VALIDATION_ERROR_CannotBeNegative';
	public static VALIDATION_ERROR_REGISTRATION_ERROR: i18nTranslationKey = 'VALIDATION_ERROR_RegistrationError';
	public static VALIDATION_REASON_NOT_SET: i18nTranslationKey = 'VALIDATION_REASON_NOT_SET';
	public static VALIDATION_ERROR_AMOUNT_NOT_SET: i18nTranslationKey = 'VALIDATION_ERROR_AMOUNT_NOT_SET';
	public static VALIDATION_ERROR_COUNT_NOT_SET: i18nTranslationKey = 'VALIDATION_ERROR_COUNT_NOT_SET';
	public static VALIDATION_ERROR_TYPE_NOT_SET: i18nTranslationKey = 'VALIDATION_ERROR_TYPE_NOT_SET';
	public static VALIDATION_ERROR_REASON_NOT_SET: i18nTranslationKey = 'VALIDATION_ERROR_REASON_NOT_SET';
	public static VALIDATION_ERROR_WEIGHT_REQUIRED_FOR_DEPARTED: i18nTranslationKey =
		'VALIDATION_ERROR_WEIGHT_REQUIRED_FOR_DEPARTED';
	// StemAnimal
	public static VALIDATION_ERROR_ANIMAL_NUM_EXISTS: i18nTranslationKey = 'VALIDATION_ERROR_AnimalNumberExists';
	public static VALIDATION_ERROR_TRANSPONDER_IN_USE: i18nTranslationKey = 'VALIDATION_ERROR_TransponderInUse';
	public static VALIDATION_ERROR_NONE_ALIVE: i18nTranslationKey = 'VALIDATION_ERROR_NoneAlive';
	public static VALIDATION_WARNING_CONFIRM_DELETIOM_FEEDING_STOCK_ADJUSTMENT: i18nTranslationKey =
		'VALIDATION_WARNING_ConfirmDeletionFeedingStockAdjustment';
	public static VALIDATION_ERROR_WEIGHT_NOT_DISTRIBUTED: i18nTranslationKey =
		'VALIDATION_ERROR_WEIGHT_NOT_DISTRIBUTED';
	public static VALIDATION_ERROR_TO_MUCH_WEIGHT_DISTRIBUTED: i18nTranslationKey =
		'VALIDATION_ERROR_TO_MUCH_WEIGHT_DISTRIBUTED';
	public static VALIDATION_ERROR_PIGS_NOT_DISTRIBUTED: i18nTranslationKey = 'VALIDATION_ERROR_PIGS_NOT_DISTRIBUTED';
	public static VALIDATION_ERROR_TO_MANY_PIGS_DISTRIBUTED: i18nTranslationKey =
		'VALIDATION_ERROR_TO_MANY_PIGS_DISTRIBUTED';
	public static VALIDATION_ERROR_TO_MANY_PIGS_MOVED: i18nTranslationKey = 'VALIDATION_ERROR_TO_MANY_PIGS_MOVED';
	public static VALIDATION_ERROR_TO_MANY_PIGS_DEPARTED: i18nTranslationKey = 'VALIDATION_ERROR_TO_MANY_PIGS_DEPARTED';
	public static VALIDATION_ERROR_FROM_LOCATION_NOT_SET: i18nTranslationKey = 'VALIDATION_ERROR_FROM_LOCATION_NOT_SET';
	public static VALIDATION_ERROR_TO_LOCATION_NOT_SET: i18nTranslationKey = 'VALIDATION_ERROR_TO_LOCATION_NOT_SET';
	public static VALIDATION_ERROR_FROM_PRODUCTIONTYPE_NOT_SET: i18nTranslationKey =
		'VALIDATION_ERROR_FROM_PRODUCTIONTYPE_NOT_SET';
	public static VALIDATION_ERROR_TO_PRODUCTIONTYPE_NOT_SET: i18nTranslationKey =
		'VALIDATION_ERROR_TO_PRODUCTIONTYPE_NOT_SET';
	public static VALIDATION_ERROR_NO_PIGS_MOVED: i18nTranslationKey = 'VALIDATION_ERROR_NO_PIGS_MOVED';
	public static VALIDATION_ERROR_NO_PIGS_DEPARTED: i18nTranslationKey = 'VALIDATION_ERROR_NO_PIGS_DEPARTED';

	public static VALIDATION_WARNING_YOU_ARE_CREATING_A_USAGE_THAT_IS_IN_ANOTHER_USAGE_PERIOD: i18nTranslationKey =
		'VALIDATION_WARNING_YOU_ARE_CREATING_A_USAGE_THAT_IS_IN_ANOTHER_USAGE_PERIOD';

	public static VALIDATION_ERROR_NoFeedingAnimalTypeChoosen: i18nTranslationKey =
		'VALIDATION_ERROR_NoFeedingAnimalTypeChoosen';
	public static VALIDATION_ERROR_NoFeedingSubjectChoosen: i18nTranslationKey =
		'VALIDATION_ERROR_NoFeedingSubjectChoosen';
	public static VALIDATION_WARNING_CONFIRM_DELETIOM_FEEDING_STOCK_USAGE: i18nTranslationKey =
		'VALIDATION_WARNING_ConfirmDeletionFeedingStockUsage';
	public static VALIDATION_ERROR_TOTAL_AMOUNT_TO_LOW_TO_CONTAIN_ALREADY_USED_FOOD: i18nTranslationKey =
		'VALIDATION_ERROR_TOTAL_AMOUNT_TO_LOW_TO_CONTAIN_ALREADY_USED_FOOD';
	public static VALIDATION_ERROR_TOTAL_AMOUNT_AVAILABLE_WILL_BE_LOWER_THAN_TOTALUSED: i18nTranslationKey =
		'VALIDATION_ERROR_TOTAL_AMOUNT_AVAILABLE_WILL_BE_LOWER_THAN_TOTALUSED';

	public static VALIDATION_ERROR_ANIMAL_NUM_NOT_SET: i18nTranslationKey = 'VALIDATION_ERROR_AnimalNumberNotSet';
	public static VALIDATION_ERROR_ANIMAL_NUM_NOT_FOUND: i18nTranslationKey = 'VALIDATION_ERROR_AnimalNumberNotFound';
	public static VALIDATION_ERROR_CURVE_INVALID: i18nTranslationKey = 'VALIDATION_ERROR_CURVE_INVALID';
	public static VALIDATION_ERROR_FIXED_PERCENT_INVALID: i18nTranslationKey = 'VALIDATION_ERROR_FIXED_PERCENT_INVALID';
	public static VALIDATION_ERROR_TRANSPONDER_ALREADY_IN_USE_BY_SOW: i18nTranslationKey =
		'VALIDATION_ERROR_TRANSPONDER_ALREADY_IN_USE_BY_SOW';

	public static VALIDATION_ERROR_ANIMAL_NUM_IS_DEPARTURED: i18nTranslationKey =
		'VALIDATION_ERROR_AnimalNumberIsDepartured';

	public static VALIDATION_ERROR_ANIMAL_IS_NOT_PREGNANT_ANYMORE_AND_ANIMAL_IS_ON_PREGNANT_LOCATION: i18nTranslationKey =
		'VALIDATION_ERROR_ANIMAL_IS_NOT_PREGNANT_ANYMORE_AND_ANIMAL_IS_ON_PREGNANT_LOCATION';

	public static VALIDATION_ERROR_ANIMAL_NEEDS_TO_BE_PREGNANT_TO_MOVE_TO_THIS_LOCATION: i18nTranslationKey =
		'VALIDATION_ERROR_ANIMAL_NEEDS_TO_BE_PREGNANT_TO_MOVE_TO_THIS_LOCATION';

	public static VALIDATION_ERROR_ESF_IS_ENABLED_AND_TRANSPONDER_IS_REQUIRED: i18nTranslationKey =
		'VALIDATION_ERROR_ESF_IS_ENABLED_AND_TRANSPONDER_IS_REQUIRED';
	public static VALIDATION_ERROR_ENTRANCE_TYPE_NOT_SET: i18nTranslationKey = 'VALIDATION_ERROR_EntranceTypeNotSet';
	public static VALIDATION_ERROR_ENTRANCE_DATE_NOT_SET: i18nTranslationKey = 'VALIDATION_ERROR_EntranceDateNotSet';
	public static VALIDATION_ERROR_DATE_NOT_SET: i18nTranslationKey = 'VALIDATION_ERROR_DATE_NOT_SET';
	public static VALIDATION_ERROR_LARD_WIDTH_NOT_SET: i18nTranslationKey = 'VALIDATION_ERROR_LARD_WIDTH_NOT_SET';
	public static VALIDATION_ERROR_PEN_ID_NOT_SET: i18nTranslationKey = 'VALIDATION_ERROR_PenIdNotSet';
	public static VALIDATION_ERROR_STABLE_SECTION_NOT_SET: i18nTranslationKey =
		'VALIDATION_ERROR_STABLE_SECTION_NOT_SET';
	public static VALIDATION_ERROR_MOVE_STABLE_SECTION_NOT_SET: i18nTranslationKey =
		'VALIDATION_ERROR_MOVE_STABLE_SECTION_NOT_SET';
	public static VALIDATION_ERROR_MOVE_NEW_PEN_ID_NOT_SET: i18nTranslationKey =
		'VALIDATION_ERROR_MOVE_NEW_PEN_ID_NOT_SET';
	public static VALIDATION_ERROR_SITE_NOT_SET: i18nTranslationKey = 'VALIDATION_ERROR_SiteNotSet';
	public static VALIDATION_ERROR_MATING_EQUALS_ONE_LITTER: i18nTranslationKey =
		'VALIDATION_ERROR_MatingEqualsOneLitter';
	public static VALIDATION_ERROR_SOW_DOES_NOT_EXIST: i18nTranslationKey = 'VALIDATION_ERROR_SowDoesNotExist';
	public static VALIDATION_ERROR_ID_NUMBER_NOT_SET: i18nTranslationKey = 'VALIDATION_ERROR_IdNumberNotSet';
	public static VALIDATION_ERROR_ANIMAL_NUMBER_ALREADY_IN_USE_BY_SOW: i18nTranslationKey =
		'VALIDATION_ERROR_ANIMAL_NUMBER_ALREADY_IN_USE_BY_SOW';

	public static VALIDATION_ERROR_ENTRACE_SOW_EDIT_LITTER: i18nTranslationKey =
		'VALIDATION_ERROR_ENTRACE_SOW_EDIT_LITTER';
	public static VALIDATION_WARNING_DELETE_MARKED_YOUNG_ANIMALS: i18nTranslationKey =
		'VALIDATION_WARNING_DELETE_MARKED_YOUNG_ANIMALS';
	public static VALIDATION_ERROR_NO_SOWS_IN_FILE: i18nTranslationKey = 'VALIDATION_ERROR_NO_SOWS_IN_FILE';
	public static VALIDATION_ERROR_SOME_SOWS_EXISTS: i18nTranslationKey = 'VALIDATION_ERROR_SOME_SOWS_EXISTS';
	public static VALIDATION_ERROR_ANIMAL_ID_NUM_EXISTS: i18nTranslationKey = 'VALIDATION_ERROR_ANIMAL_ID_NUM_EXISTS';

	// Pregnancies
	public static VALIDATION_ERROR_REGISTRATION_ERROR_PREG_EVENT: i18nTranslationKey =
		'VALIDATION_ERROR_RegistrationErrorPregnancyEvent';
	public static VALIDATION_ERROR_FINISHED_DATE_NOT_SET: i18nTranslationKey = 'VALIDATION_ERROR_FinishedDateNotSet';
	public static VALIDATION_ERROR_FINISHED_DATE_BEFORE_PRE_EVENT: i18nTranslationKey =
		'VALIDATION_ERROR_DateIsBeforePreviousEvent';

	public static VALIDATION_ERROR_FINISHED_DATE_FINISHED_DATE_NOT_SET: i18nTranslationKey =
		'VALIDATION_ERROR_FinishedDateNotSet';
	public static VALIDATION_ERROR_FINISHED_DATE_MATING_DATE_YOUNG_FEMALE_ERR: i18nTranslationKey =
		'VALIDATION_ERROR_MatingDateAndYoungFemaleError';
	public static VALIDATION_ERROR_FINISHED_DATE_NO_PRE_EVENT: i18nTranslationKey =
		'VALIDATION_ERROR_HasNoPreviousEvent';

	public static VALIDATION_ERROR_INVALID_PREG_EVENT: i18nTranslationKey = 'VALIDATION_ERROR_InvalidPregnancyEvent';
	public static VALIDATION_ERROR_DATE_DOES_NOT_MATCH_MATING_BATCH: i18nTranslationKey =
		'VALIDATION_ERROR_DateDoesNotMatchAMatingBatch';

	public static VALIDATION_ERROR_INVALID_WEANING_TO_MATING_INTERVAL: i18nTranslationKey =
		'VALIDATION_ERROR_InvalidWeaningToMatingInterval';
	public static VALIDATION_ERROR_INVALID_AGE_INTERVAL: i18nTranslationKey = 'VALIDATION_ERROR_InvalidAgeInterval';
	public static VALIDATION_ERROR_GILT_MATING_DATE: i18nTranslationKey = 'VALIDATION_ERROR_GiltMatingDateError';
	public static VALIDATION_ERROR_CYCLEDAYS_NOT_SET: i18nTranslationKey = 'VALIDATION_ERROR_CycledaysNotSet';
	public static VALIDATION_ERROR_PREGNANT_NURSING_DAYS_EXCEEDED: i18nTranslationKey =
		'VALIDATION_ERROR_PregnantAndNursingDaysExceeded';
	public static VALIDATION_ERROR_WEEK_CYCLE_NOT_SET: i18nTranslationKey = 'VALIDATION_ERROR_WeekCycleIsNotSet';
	public static VALIDATION_ERROR_MATING_PERIOD_NOT_SET: i18nTranslationKey = 'VALIDATION_ERROR_MatingPeriodIsNotSet';
	public static VALIDATION_ERROR_START_DATE_NOT_SET: i18nTranslationKey = 'VALIDATION_ERROR_StartDateIsNotSet';
	public static VALIDATION_ERROR_START_DATE_IS_EARLIER_THAN_CURRENT_DATE: i18nTranslationKey =
		'VALIDATION_ERROR_StartDateIsEarlierThanCurrentDate';
	public static VALIDATION_ERROR_NEW_STEART_DATE_MUST_BE_EQUAL_TO_EXISTING: i18nTranslationKey =
		'VALIDATION_ERROR_NewStartDateMustBeEqualToExisting';

	public static VALIDATION_ERROR_NURSING_DAYS_NOT_SET: i18nTranslationKey = 'VALIDATION_ERROR_NursingDaysNotSet';
	public static VALIDATION_ERROR_PREGNANT_DAYS_NOT_SET: i18nTranslationKey = 'VALIDATION_ERROR_PregnantDaysNotSet';
	public static VALIDATION_ERROR_OVERLAP_OTHER_SETTING_DATES: i18nTranslationKey =
		'VALIDATION_ERROR_OverlapsOtherSettingsDates';
	public static VALIDATION_ERROR_END_DATE_NOT_SET: i18nTranslationKey = 'VALIDATION_ERROR_EndDateNotSet';

	public static VALIDATION_ERROR_NO_MATING_BATCHES_GENERATED: i18nTranslationKey =
		'VALIDATION_ERROR_NoMatingBatchesGenerated';

	public static VALIDATION_ERROR_INVALID_MATING_TO_FARROWING_INTERVAL: i18nTranslationKey =
		'VALIDATION_ERROR_InvalidMatingToFarrowingInterval';
	public static VALIDATION_ERROR_INVALID_FARROWING_TO_WEANING_INTERVAL: i18nTranslationKey =
		'VALIDATION_ERROR_InvalidFarrowingToWeaningInterval';
	public static VALIDATION_ERROR_NO_ABORTION_IF_ALIVE_PIGS: i18nTranslationKey =
		'VALIDATION_ERROR_NoAbortionIfAlivePigs';

	public static VALIDATION_ERROR_SOW_ALREADY_PREGNANT: i18nTranslationKey = 'VALIDATION_ERROR_SowAlreadyPregnant';
	public static VALIDATION_ERROR_SCAN_SAME_DAY_AS_MATED: i18nTranslationKey =
		'VALIDATION_ERROR_ScanCantBeSameDateAsMated';
	public static VALIDATION_ERROR_NO_WEIGHT_SET: i18nTranslationKey = 'VALIDATION_ERROR_NoWeightSet';
	public static VALIDATION_ERROR_SETUP_PREGNANCY_VALIDATION: i18nTranslationKey =
		'VALIDATION_ERROR_SetupValidationSetup';
	public static VALIDATION_ERROR_INVALID_FARROWING_ALIVE: i18nTranslationKey =
		'VALIDATION_ERROR_InvalidFarrowingAlive';

	public static VALIDATION_WARNING_CONFIRM_DELETEION_MATED: i18nTranslationKey =
		'VALIDATION_WARNING_ConfirmDeletionMated';

	public static VALIDATION_WARNING_CONFIRM_DELETEION_ADJUSTMENT: i18nTranslationKey =
		'VALIDATION_WARNING_ConfirmDeletionAdjustments';

	public static VALIDATION_WARNING_CONFIRM_DELETEION_SCANNING: i18nTranslationKey =
		'VALIDATION_WARNING_ConfirmDeletionScanning';

	public static VALIDATION_WARNING_CONFIRM_DELETEION_FARROWING: i18nTranslationKey =
		'VALIDATION_WARNING_ConfirmDeletionFarrowing';

	public static VALIDATION_WARNING_CONFIRM_DELETEION_WEANING: i18nTranslationKey =
		'VALIDATION_WARNING_ConfirmDeletionWeaning';
	public static VALIDATION_ERROR_WEANING_NUM_ALIVE_IS_NOT_SET: i18nTranslationKey =
		'VALIDATION_ERROR_WeaningNumAliveIsNotSet';

	public static VALIDATION_ERROR_FARROWING_NUM_ALIVE_NOT_SET: i18nTranslationKey =
		'VALIDATION_ERROR_FarrowingNumAliveNotSet';
	public static VALIDATION_ERROR_AVG_WEIGHT_NOT_SET: i18nTranslationKey = 'VALIDATION_ERROR_AVG_WEIGHT_NOT_SET';
	public static VALIDATION_ERROR_AVG_WEIGHT_POSITIVE: i18nTranslationKey = 'VALIDATION_ERROR_AVG_WEIGHT_POSITIVE';
	public static VALIDATION_ERROR_DEPARTURE_DATE_IS_EARLIER_THAN_LAST_PREGNANCY: i18nTranslationKey =
		'VALIDATION_ERROR_DEPARTURE_DATE_IS_EARLIER_THAN_LAST_PREGNANCY';
	public static VALIDATION_ERROR_NO_DEPARTURE_ON_NURSING_SOW: i18nTranslationKey =
		'VALIDATION_ERROR_NO_DEPARTURE_ON_NURSING_SOW';

	// Feedstock
	public static VALIDATION_ERROR_DRUGSTOCK_NOT_SELECTED: i18nTranslationKey =
		'VALIDATION_ERROR_DRUGSTOCK_NOT_SELECTED';

	public static VALIDATION_ERROR_FEEDINGSTOCK_NO_FEEDING_SUBJECT_SELECTED: i18nTranslationKey =
		'VALIDATION_ERROR_FEEDINGSTOCK_NO_FEEDING_SUBJECT_SELECTED';
	public static VALIDATION_ERROR_FEEDINGSTOCK_NOT_SELECTED: i18nTranslationKey =
		'VALIDATION_ERROR_FEEDINGSTOCK_NOT_SELECTED';
	public static VALIDATION_ERROR_FEEDINGSTOCK_AMOUNT_NOT_SET: i18nTranslationKey =
		'VALIDATION_ERROR_FEEDINGSTOCK_AMOUNT';

	public static VALIDATION_ERROR_FEEDINGSTOCK_PRICE_NOT_SET: i18nTranslationKey =
		'VALIDATION_ERROR_FEEDINGSTOCK_PRICE';

	public static VALIDATION_ERROR_FEEDINGSTOCK_FESOW_NOT_SET: i18nTranslationKey =
		'VALIDATION_ERROR_FEEDINGSTOCK_FESOW';

	public static VALIDATION_ERROR_FEEDINGSTOCK_FEPIG_NOT_SET: i18nTranslationKey =
		'VALIDATION_ERROR_FEEDINGSTOCK_FEPIG';

	public static VALIDATION_ERROR_FEEDINGSTOCK_FOSFOR_NOT_SET: i18nTranslationKey =
		'VALIDATION_ERROR_FEEDINGSTOCK_FOSFOR';

	public static VALIDATION_ERROR_FEEDINGSTOCK_NO_FEEDING_SUBJECT_NAME: i18nTranslationKey =
		'VALIDATION_ERROR_FEEDINGSTOCK_NO_FEEDING_SUBJECT_NAME';

	public static VALIDATION_ERROR_AMOUNT_EXCEED_STOCK: i18nTranslationKey = 'VALIDATION_ERROR_AMOUNT_EXCEED_STOCK';

	public static VALIDATION_ERROR_DRUGSTOCK_REASON_REQUIRED: i18nTranslationKey =
		'VALIDATION_ERROR_DRUGSTOCK_REASON_REQUIRED';
	public static VALIDATION_ERROR_DRUGSTOCK_AMOUNT_NOT_SET: i18nTranslationKey = 'VALIDATION_ERROR_DRUGSTOCK_AMOUNT';
	public static VALIDATION_ERROR_DRUGSTOCK_DATE_NOT_SET: i18nTranslationKey = 'VALIDATION_ERROR_DRUGSTOCK_DATE';
	public static VALIDATION_ERROR_DRUGSTOCK_USE_ADD_INSTEAD: i18nTranslationKey =
		'VALIDATION_ERROR_DRUGSTOCK_USE_ADD_INSTEAD';
	public static VALIDATION_ERROR_DRUGSTOCK_ERROR_EDITING_WASTE_HAS_TO_BE_NEGATIVE: i18nTranslationKey =
		'VALIDATION_ERROR_DRUGSTOCK_ERROR_EDITING_WASTE_HAS_TO_BE_NEGATIVE';
	public static VALIDATION_ERROR_DRUGSTOCK_ERROR_EDITING_ADJUSTMENT_HAS_TO_BE_POSITIVE: i18nTranslationKey =
		'VALIDATION_ERROR_DRUGSTOCK_ERROR_EDITING_ADJUSTMENT_HAS_TO_BE_POSITIVE';
	public static VALIDATION_ERROR_NO_FEEDING_ADJUSTMENT: i18nTranslationKey = 'VALIDATION_ERROR_NO_FEEDING_ADJUSTMENT';
	// DrugType
	public static VALIDATION_ERROR_NAME_NOT_SET: i18nTranslationKey = 'VALIDATION_ERROR_NameNotSet';
	public static VALIDATION_ERROR_UNIT_NOT_SET: i18nTranslationKey = 'VALIDATION_ERROR_UnitNotSet';

	// DiagnoseCategory
	public static VALIDATION_ERROR_BOTH_NAMES_REQ: i18nTranslationKey = 'VALIDATION_ERROR_BothNamesRequired';
	public static VALIDATION_CAT_IN_USE: i18nTranslationKey = 'VALIDATION_ERROR_CategoryInUse';

	// Diagnose
	public static VALIDATION_ERROR_NAME_REQ: i18nTranslationKey = 'VALIDATION_ERROR_NameNotSet';
	public static VALIDATION_ERROR_CATEGORY_REQ: i18nTranslationKey = 'VALIDATION_ERROR_CategoryNotSet';
	public static VALIDATION_ERROR_DIAGNOSE_ANIMALKIND_STILL_IN_USE: i18nTranslationKey =
		'VALIDATION_ERROR_DiagnoseAnimalKindStillInUse';
	public static VALIDATION_ERROR_NOT_ALLOWED_TO_DELETE_DIAGNOSE: i18nTranslationKey =
		'VALIDATION_ERROR_NotAllowedToDeleteDiagnose';
	// Departure
	public static VALIDATION_ERROR_DEPARTURE_DATE_AFTER_CURRENT_DATE: i18nTranslationKey =
		'VALIDATION_ERROR_DepartureDateMostAfterCurrentDate';
	public static VALIDATION_ERROR_DEPARTURE_DATE_NOT_SET: i18nTranslationKey = 'VALIDATION_ERROR_DepartureDateNotSet';
	public static VALIDATION_ERROR_DEPARTURE_TYPE_NOT_SET: i18nTranslationKey = 'VALIDATION_ERROR_DepartureTypeNotSet';
	public static VALIDATION_ERROR_DEPARTURE_BOAR_IS_KS: i18nTranslationKey = 'VALIDATION_ERROR_DepartureBoarIsKs';
	public static VALIDATION_ERROR_ANIMAL_NOT_FOUND: i18nTranslationKey = 'VALIDATION_ERROR_AnimalNotFound';

	//dashboard
	public static VALIDATION_ERROR_DASHBOARD_ENDMATINGBATCH_NEED_TO_BE_LATER: i18nTranslationKey =
		'VALIDATION_ERROR_DASHBOARD_ENDMATINGBATCH_NEED_TO_BE_LATER';
	public static VALIDATION_ERROR_DASHBOARD_NEED_LATER_ENDMATINGBATCH: i18nTranslationKey =
		'VALIDATION_ERROR_DASHBOARD_NEED_LATER_ENDMATINGBATCH';

	//Location
	public static VALIDATION_ERROR_BUILDING_NUMBER_NOT_SET: i18nTranslationKey =
		'VALIDATION_ERROR_BuildingNumberNotSet';
	public static VALIDATION_ERROR_BUILDING_NAME_NOT_SET: i18nTranslationKey = 'VALIDATION_ERROR_BuildingNameNotSet';
	public static VALIDATION_ERROR_PRODUCTION_FORM_NOT_SET: i18nTranslationKey =
		'VALIDATION_ERROR_ProductionFormNotSet';
	public static VALIDATION_ERROR_PRODUCTION_TYPE_NOT_SET: i18nTranslationKey =
		'VALIDATION_ERROR_PRODUCTION_TYPE_NOT_SET';
	public static VALIDATION_ERROR_BUILDING_NUMBER_ALREADY_EXISTS: i18nTranslationKey =
		'VALIDATION_ERROR_BuildingNumberAlreadyExists';
	public static VALIDATION_ERROR_SECTION_TYPE_NOT_SET: i18nTranslationKey = 'VALIDATION_ERROR_SectionTypeNotSet';
	public static VALIDATION_ERROR_DUPLICATE_SECTION_NUMBERS: i18nTranslationKey =
		'VALIDATION_ERROR_DuplicateSectionNumbers';
	public static VALIDATION_ERROR_DUPLICATE_PEN_NUMBERS: i18nTranslationKey = 'VALIDATION_ERROR_DuplicatePenNumbers';
	public static VALIDATION_ERROR_HAS_NO_SECTION: i18nTranslationKey = 'VALIDATION_ERROR_HasNoSection';
	public static VALIDATION_ERROR_HAS_NO_BUILDING: i18nTranslationKey = 'VALIDATION_ERROR_HasNoBuilding';
	public static VALIDATION_ERROR_BUILDING_IS_NOT_EMPTY: i18nTranslationKey = 'VALIDATION_ERROR_BuildingIsNotEmpty';
	public static VALIDATION_ERROR_SECTION_IS_NOT_EMPTY: i18nTranslationKey = 'VALIDATION_ERROR_SectionIsNotEmpty';
	public static VALIDATION_WARNING_CONFIRM_DELETION_BUILDING: i18nTranslationKey =
		'VALIDATION_WARNING_ConfirmDeletionBuilding';
	public static VALIDATION_WARNING_CONFIRM_DELETION_SECTION: i18nTranslationKey =
		'VALIDATION_WARNING_ConfirmDeletionSection';

	// Analysis settings

	public static VALIDATION_ERROR_MISSING_AGE_PERIOD: i18nTranslationKey = 'VALIDATION_ERROR_MISSING_AGE_PERIOD';
	public static VALIDATION_ERROR_AGE_TO_IS_LESS_THAN_FROM_DATE: i18nTranslationKey =
		'VALIDATION_ERROR_AGE_TO_IS_LESS_THAN_FROM_DATE';

	//Validation/Setup
	public static VALIDATION_ERROR_INVALID_PREGNANT_PERIOD: i18nTranslationKey =
		'VALIDATION_ERROR_InvalidPregnantPeriod';
	public static VALIDATION_ERROR_INVALID_NURSING_PERIOD: i18nTranslationKey = 'VALIDATION_ERROR_InvalidNursingPeriod';
	public static VALIDATION_ERROR_LOCATION_NOT_SET: i18nTranslationKey = 'VALIDATION_ERROR_LocationNotSet';
	public static VALIDATION_ERROR_YOU_ARE_MATING_A_ANIMAL_THAT_IS_NURSING: i18nTranslationKey =
		'VALIDATION_ERROR_YOU_ARE_MATING_A_ANIMAL_THAT_IS_NURSING';
	public static VALIDATION_WARNING_MATING_TO_FARROWING_OUT_OF_INTERVAL: i18nTranslationKey =
		'VALIDATION_WARNING_MatingToFarrowingOutOfInterval';
	public static VALIDATION_WARNING_WEANING_TO_MATING_OUT_OF_INTERVAL: i18nTranslationKey =
		'VALIDATION_WARNING_WeaningToMatingOutOfInterval';
	public static VALIDATION_WARNING_MATING_AGE_OUT_OF_INTERVAL: i18nTranslationKey =
		'VALIDATION_WARNING_MatingAgeOutOfInterval';
	public static VALIDATION_ERROR_INVALID_PERIOD: i18nTranslationKey = 'VALIDATION_ERROR_invalidPlanPeriod';

	public static VALIDATION_ERROR_NO_DEFAULT_WEANING_PEN: i18nTranslationKey =
		'VALIDATION_ERROR_NO_DEFAULT_WEANING_PEN';

	//TreatmentDefinition
	public static VALIDATION_ERROR_NoAnimalKinds: i18nTranslationKey = 'VALIDATION_ERROR_NoAnimalKinds';

	public static VALIDATION_ERROR_NoDiagnoseID: i18nTranslationKey = 'VALIDATION_ERROR_NoDiagnoseID';
	public static VALIDATION_ERROR_ANIMAL_IS_NOT_YOUNG_FEMALE: i18nTranslationKey =
		'VALIDATION_ERROR_ANIMAL_IS_NOT_YOUNG_FEMALE';

	//Move
	public static VALIDATION_ERROR_MOVE_DATE_NOT_SET: i18nTranslationKey = 'VALIDATION_ERROR_MoveDateNotSet';
	public static VALIDATION_ERROR_NEW_PEN_ID_NOT_SET: i18nTranslationKey = 'VALIDATION_ERROR_NewPenIdNotSet';
	public static VALIDATION_ERROR_NEW_PEN_SAME_AS_CURRENT: i18nTranslationKey = 'VALIDATION_ERROR_NewPenSameAsCurrent';
	public static VALIDATION_ERROR_FARM_HAS_ESF_BUT_ANIMAL_DOES_NOT_HAVE_TRANSPONDER: i18nTranslationKey =
		'VALIDATION_ERROR_FARM_HAS_ESF_BUT_ANIMAL_DOES_NOT_HAVE_TRANSPONDER';
	public static VALIDATION_ERROR_FEED_CURVE_NUMBER_IS_NOT_SET: i18nTranslationKey =
		'VALIDATION_ERROR_FEED_CURVE_NUMBER_IS_NOT_SET';
	public static VALIDATION_ERROR_MOVE_EVENT_IS_EARLIER_THAN_LAST_EVENT: i18nTranslationKey =
		'VALIDATION_ERROR_MOVE_EVENT_IS_EARLIER_THAN_LAST_EVENT';

	//MatingBatchReport
	public static VALIDATION_ERROR_MATING_BATCH_OUT_OF_RANGE: i18nTranslationKey =
		'VALIDATION_ERROR_MatingBatchOutOfRange';

	//AdjustFeed
	public static VALIDATION_WARNING_VALVE_GROUP_SECTION_OLD_DATA: i18nTranslationKey =
		'VALIDATION_WARNING_ValveGroupSectionContainsOldData';
	public static VALIDATION_WARNING_VALVE_GROUP_OLD_MODIFICATION: i18nTranslationKey =
		'VALIDATION_WARNING_ValveGroupsModificationOld';
	public static VALIDATION_WARNING_VALVE_GROUP_IS_NOT_EDITABLE: i18nTranslationKey =
		'VALIDATION_WARNING_ValveGroupIsNotEditable';
	public static VALIDATION_WARNING_CHANGING_SECTION_OR_BUILDING_WILL_RESULT_IN_RESET_FOR_CHANGED_BUILDINGS_SECTIONS: i18nTranslationKey =
		'VALIDATION_WARNING_CHANGING_SECTION_OR_BUILDING_WILL_RESULT_IN_RESET_FOR_CHANGED_BUILDINGS_SECTIONS';
	public static VALIDATION_WARNING_SECTION_NOT_BELONGING_TO_SECTION_SELECTED_HAS_NON_SETTED_PENS: i18nTranslationKey =
		'VALIDATION_WARNING_SECTION_NOT_BELONGING_TO_SECTION_SELECTED_HAS_NON_SETTED_PENS';
	//Worklists - Tasks in farrowing stable
	public static VALIDATION_ERROR_CHOOSE_ANIMAL_NOT_SET: i18nTranslationKey = 'VALIDATION_ERROR_ChooseAnimalNotSet';
	public static VALIDATION_ERROR_COLUMN_TEXT_IS_NOT_SET: i18nTranslationKey = 'VALIDATION_ERROR_ColumnTextIsNotSet';

	public static VALIDATION_ERROR_BOAR_PROCENTAGE_IS_NOT_SET: i18nTranslationKey =
		'VALIDATION_ERROR_BoarProcentageIsNotSet';
	public static VALIDATION_ERROR_SET_WHAT_TO_TREAT: i18nTranslationKey = 'VALIDATION_ERROR_SET_WHAT_TO_TREAT';
	public static VALIDATION_ERROR_NURSING_FROM_IS_EMPTY_OR_NEGATIVE: i18nTranslationKey =
		'VALIDATION_ERROR_NursingFromIsEmptyOrNegative';
	public static VALIDATION_ERROR_NURSING_TO_IS_EMPTY_OR_NEGATIVE: i18nTranslationKey =
		'VALIDATION_ERROR_NursingToIsEmptyOrNegative';

	public static VALIDATION_ERROR_PREGNANT_FROM_IS_EMPTY_OR_NEGATIVE: i18nTranslationKey =
		'VALIDATION_ERROR_PregnantFromIsEmptyOrNegative';
	public static VALIDATION_ERROR_PREGNANT_TO_IS_EMPTY_OR_NEGATIVE: i18nTranslationKey =
		'VALIDATION_ERROR_PregnantToIsEmptyOrNegative';

	public static VALIDATION_ERROR_SHOW_ANIMAL_ON_LIST: i18nTranslationKey = 'VALIDATION_ERROR_SHOW_ANIMAL_ON_LIST';
	public static VALIDATION_ERROR_NURSING_DAYS_ARE_REQUIERED: i18nTranslationKey =
		'VALIDATION_ERROR_NursingDaysAreRequiered';
	public static VALIDATION_ERROR_AVAILABLE_ON_NEED_TO_BE_SET: i18nTranslationKey =
		'VALIDATION_ERROR_AvailableOnNeedsToBeSet';
	public static VALIDATION_ERROR_CHOOSE_PREGNANCY_EVENT: i18nTranslationKey = 'VALIDATION_ERROR_ChoosePregnancyEvent';
	public static VALIDATION_ERROR_INCLUDE_LITTERS_TO: i18nTranslationKey = 'VALIDATION_ERROR_IncludeLittersTo';
	public static VALIDATION_ERROR_AFTER_EVENT_DAYS_GREATER_THAN_ZERO: i18nTranslationKey =
		'VALIDATION_ERROR_AfterEventDaysGreaterThanZero';

	public static VALIDATION_ERROR_CHOOSE_ATLEAST_ONE_TREATMENT_DEF: i18nTranslationKey =
		'VALIDATION_ERROR_ChooseAtleastOneTreatmentDef';

	public static VALIDATION_WARNING_CAPACITY_EXCEEDED(penCapacity: number, animalCount: number, penName: string) {
		return `${localized('VALIDATION_WARNING_CapacityExceeded1')} ${penName}\n${localized(
			'VALIDATION_WARNING_CapacityExceeded2',
		)} ${animalCount}\n${localized('VALIDATION_WARNING_CapacityExceeded3')} ${penCapacity}\n${localized(
			'VALIDATION_WARNING_ConfirmWarningMessage',
		)}`;
	}

	public static VALIDATION_WARNING_FARROWING_TO_WEANING_OUT_OF_INTERVAL(
		animalNumber: string | undefined,
		weaningdays: number,
	) {
		return `${localized('animalNr')} ${animalNumber} - ${localized(
			'VALIDATION_WARNING_FarrowingToWeaningOutOfInterval1',
		)} ${weaningdays} ${localized('VALIDATION_WARNING_FarrowingToWeaningOutOfInterval2')}`;
	}

	public static VALIDATION_ERROR_COUNT_LARGER_THAN_CURRENT_COUNT(capacity: number) {
		return `${localized('VALIDATION_ERROR_COUNT_LARGER_THAN_CURRENT_COUNT1')} ${capacity} ${localized(
			'VALIDATION_ERROR_COUNT_LARGER_THAN_CURRENT_COUNT2',
		)}`;
	}

	public static VALIDATION_ERROR_COUNT_LARGER_THAN_CURRENT_COUNT_2(
		capacity: number,
		locationString: string,
		departAmount: number,
	) {
		return `${localized('VALIDATION_ERROR_COUNT_LARGER_THAN_CURRENT_COUNT3')} ${capacity} ${localized(
			'VALIDATION_ERROR_COUNT_LARGER_THAN_CURRENT_COUNT4',
		)}: ${locationString}. ${localized('VALIDATION_ERROR_COUNT_LARGER_THAN_CURRENT_COUNT5')}: ${departAmount}`;
	}

	public static VALIDATION_WARNING_CAPACITY_EXCEEDED_WITH_ANIMALNUMBER(
		animalNumber: string,
		penCapacity: number,
		animalCount: number,
		penName: string,
	) {
		return `${localized('animalNumber')} ${animalNumber}\n ${localized(
			'VALIDATION_WARNING_CapacityExceeded1',
		)} ${penName}\n${localized('VALIDATION_WARNING_CapacityExceeded2')} ${animalCount}\n${localized(
			'VALIDATION_WARNING_CapacityExceeded3',
		)} ${penCapacity}\n${localized('VALIDATION_WARNING_ConfirmWarningMessageMoveEvent')}`;
	}

	public static VALIDATION_ERROR_ANIMALS_NEED_TRANSPONDER(animalNumbers: string[]) {
		return `${localized('VALIDATION_ERROR_ANIMALS_NEED_TRANSPONDER')}: ${animalNumbers.join()}`;
	}

	//Process Equipment
	public static VALIDATION_ERROR_NO_CODE_SET: i18nTranslationKey = 'VALIDATION_ERROR_NO_CODE_SET';
	public static VALIDATION_ERROR_INVALID_CODE_SET: i18nTranslationKey = 'VALIDATION_ERROR_INVALID_CODE_SET';
	public static VALIDATION_ERROR_DEFAULT_CODE_SET: i18nTranslationKey = 'VALIDATION_ERROR_DEFAULT_CODE_SET';
	public static VALIDATION_ERROR_NO_UNITTYPE_SET: i18nTranslationKey = 'VALIDATION_ERROR_NO_UNITTYPE_SET';
	public static VALIDATION_ERROR_NO_PROCESS_EQUIPMENT_SET: i18nTranslationKey =
		'VALIDATION_ERROR_NO_PROCESS_EQUIPMENT_SET';
	public static VALIDATION_ERROR_CODE_ALREADY_USED: i18nTranslationKey = 'VALIDATION_ERROR_CODE_ALREADY_USED';

	//ESF
	public static VALIDATION_WARNING_ESF_DATA_IS_NOT_UP_TO_DATE: i18nTranslationKey =
		'VALIDATION_WARNING_ESF_DATA_IS_NOT_UP_TO_DATE';
	public static VALIDATION_ERROR_INVALID_ESF_DATA: i18nTranslationKey = 'VALIDATION_ERROR_INVALID_ESF_DATA';

	//Move worklist
	public static VALIDATION_ERROR_BLE_CHOOSE_LOCATION: i18nTranslationKey = 'VALIDATION_ERROR_BLE_CHOOSE_LOCATION';
	public static VALIDATION_ERROR_SOW_INVALID_FOR_WORKLIST: i18nTranslationKey =
		'VALIDATION_ERROR_SOW_INVALID_FOR_WORKLIST';

	//Sync error
	public static SYNC_ERROR: i18nTranslationKey = 'SYNC_ERROR';

	//Mating Batch Setting
	public static VALIDATION_ERROR_MATING_BATCH_1_YEAR: i18nTranslationKey = 'VALIDATION_ERROR_MATING_BATCH_1_YEAR';
	public static VALIDATION_ERROR_MUST_BE_START_MATING_BATCH: i18nTranslationKey =
		'VALIDATION_ERROR_MUST_BE_START_MATING_BATCH';

	//Dead Piglets
	public static VALIDATION_ERROR_DEAD_PIGLETS_NOT_SET: i18nTranslationKey = 'VALIDATION_ERROR_DEAD_PIGLETS_NOT_SET';
	public static VALIDATION_ERROR_MUST_BE_NURSING: i18nTranslationKey = 'VALIDATION_ERROR_MUST_BE_NURSING';
}
