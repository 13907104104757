import { IUserSiteDTO } from 'shared/api/api';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import actionCreatorFactory from 'typescript-fsa';
import * as types from './types';

const actionCreator = actionCreatorFactory(GenActionFactoryName('userSites'));

export const getUserSitesForCurrentUserAsync = actionCreator.async<
	{},
	IUserSiteDTO[],
	{ code: number; message: string }
>(types.GET_CURRENT_USER_SITES);

export const getUserSitesForUser = actionCreator.async<{ userId: string }, IUserSiteDTO[]>(
	types.GET_USER_SITES_FOR_USER,
);

export const removeUserSiteAccess = actionCreator.async<{ userId: string; siteIds: string[] }, void>(
	types.REMOVE_USER_SITE_ACCESS,
);
