import React from 'react';
import { generateSkioldFetchInitialState, SkioldFetchPropsFromParent } from 'shared/helpers/date-helpers';
import { SkioldFetchIcon } from '../../utils/skiold-fetch-icon';
import { ViewWeb } from '../../utils/web-view';
import { SkioldDatePicker } from '../skiold-date-picker/skiold-date-picker';
import './skiold-fetch.scss';
import { SkioldFetchState } from 'shared/state/ducks/skiold-fetch/types';

export class SkioldFetch extends React.PureComponent<SkioldFetchPropsFromParent, SkioldFetchState> {
	constructor(props: SkioldFetchPropsFromParent) {
		super(props);

		if (props.defaultEmpty) {
			this.state = {
				fromDate: undefined,
				toDate: undefined,
			};
		} else {
			let { tempFromDate, tempToDate } = generateSkioldFetchInitialState(props);

			this.state = {
				fromDate: tempFromDate,
				toDate: tempToDate,
			};
		}
	}

	// if parent controls dates (isControlled), state should be updated by parent
	public componentDidUpdate(prevProps: SkioldFetchPropsFromParent) {
		if (
			this.props.isControlled &&
			((prevProps.fromDate !== this.props.fromDate && this.props.fromDateChange) ||
				(this.props.toDateChange && prevProps.toDate !== this.props.toDate))
		) {
			this.setState({
				fromDate: this.props.fromDate,
				toDate: this.props.toDate,
			});
		}
	}

	private fromDateChanged = (fromDate: Date) => {
		if (this.props.fromDateChange) {
			this.props.fromDateChange(fromDate);
		} else {
			this.setState({ fromDate });
		}
	};

	private callFetchData = () => {
		let fromDate = this.state.fromDate;
		let toDate = this.state.toDate;
		if (fromDate && toDate) {
			this.props.fetchData(fromDate, toDate);
		}
	};
	private toDateChanged = (toDate: Date) => {
		if (this.props.toDateChange) {
			this.props.toDateChange(toDate);
		} else {
			this.setState({ toDate });
		}
	};
	
	public render() {
		return (
			<ViewWeb className="view-style">
				<ViewWeb className="date-picker-container">
					<SkioldDatePicker
						highlightDates={this.props.datesToHighlightLeftDatePicker}
						onDateChanged={this.fromDateChanged}
						selectedDate={this.state.fromDate!}
						maxDate={this.state.toDate}
						text={this.props.fromText ? this.props.fromText : 'FromDate'}
						containerClassName="datepicker-margin"
						includeTimeStamp={false}
					/>
					<SkioldDatePicker
						highlightDates={this.props.datesToHighlightRightDatePicker}
						onDateChanged={this.toDateChanged}
						selectedDate={this.state.toDate!}
						isEndDate={true}
						text={this.props.toText ? this.props.toText : 'ToDate'}
						containerClassName="datepicker-margin"
					/>
					<SkioldFetchIcon showSpinner={this.props.showSpinner} onPress={this.callFetchData} />
				</ViewWeb>
			</ViewWeb>
		);
	}
}
