import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
	Farrowing,
	Gender,
	IFarrowing,
	IMated,
	INucleusManagementBreedingSettings,
	IPregnancyEvent,
	IStemAnimal,
	PregnancyState,
	PregnancyValidationType,
	StemAnimal,
} from 'shared/api/api';
import { getDateString, getDaysDifference } from 'shared/helpers/date-helpers';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { deepCopy, isEmpty, isNullOrUndefined } from 'shared/helpers/general-helpers';
import { getLocationString } from 'shared/helpers/location-helper';
import { findBatch } from 'shared/helpers/matingbatch-helper';
import { memoizeAllAnimals } from 'shared/helpers/memoize-getters/memoize-getters';
import {
	AssignIdsResponse,
	findBreedRace,
	hasAssignIdNumbers,
	hasDanishNucleusFeature,
	hasDanishNucleusFeatureOrAssignId,
	hasNucleusFeatureCEFN,
	validateOpenAssignId,
} from 'shared/helpers/nucleus-management-helper/nucleus-management-helper';
import {
	NucleusValidator,
	validateFarrowingDateChange,
} from 'shared/helpers/nucleus-management-helper/nucleus-validation-interface';
import { checkAssignIdAlive, setFarrowingData } from 'shared/helpers/pregnancy-helper/farrowing-helper';
import {
	getFarrowingWeekByGeneralSetting,
	getFarrowingYear,
	SetupPregnancyByStemAnimal,
	setupPregnancyEventById,
	ValidateAnimalIPregnancyLocationAndEsfIsEnabled,
} from 'shared/helpers/pregnancy-helper/generel-pregnancy-helpers';
import { sortPregnanciesByDate } from 'shared/helpers/pregnancy-helper/sort-pregnancies';
import { mergeArraysHashmap } from 'shared/helpers/reducer-helpers';
import { RefType } from 'shared/helpers/ref-type';
import { getLastMoveEventDate, isMoveDateValid } from 'shared/helpers/stemanimal-helper/stemanimal-helper';
import { upsertBreedingSettings } from 'shared/state/ducks/nucleus-management-breeding-settings/operation';
import {
	DeleteLastPregnancyEvent,
	GetSyncData as PregnancyEventGetSyncData,
	SavePregnancyEvent,
} from 'shared/state/ducks/pregnancy-events/operations';
import { GetProcessEquipmentDataSyncData } from 'shared/state/ducks/process-equipment-data/operations';
import { selectNucleusManagementOrAssignIdAccess } from 'shared/state/ducks/site/reducer';
import {
	GetSyncData as StemAnimalGetSyncData,
	UpsertManyYoungAnimal,
} from 'shared/state/ducks/stem-animals/operations';
import * as UnitsToPenOperations from 'shared/state/ducks/unit-to-pen/operations';
import { GetSyncData as ValidationSetupGetSyncData } from 'shared/state/ducks/validation-setup/operations';
import { localized, localizedDynamic } from 'shared/state/i18n/i18n';
import { DepartureTypes } from 'shared/state/models/departure-types';
import { WebAppState } from 'web/state/store.web';
import { showAlert, ShowConfirmAlert, showDefaultAlert } from 'web/view/components/skiold-alert/skiold-alert';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldCheckbox } from 'web/view/components/skiold-components/skiold-checkbox/skiold-checkbox';
import { SkioldDatePicker } from 'web/view/components/skiold-components/skiold-date-picker/skiold-date-picker';
import { SkioldFormDecimalInput } from 'web/view/components/skiold-components/skiold-decimal-input/skiold-form-decimal-input';
import { SkioldFormTextField } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-form-text';
import { SkioldFormsWrapper } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { SkioldFormInput } from 'web/view/components/skiold-components/skiold-input/skiold-form-input';
import { SkioldFormIntegerInput } from 'web/view/components/skiold-components/skiold-integer-input/skiold-form-integer-input';
import { SkioldModal } from 'web/view/components/skiold-components/skiold-modal/skiold-modal';
import MoveEventBase from 'web/view/components/stem-animal/sow-events/move-event/move-event-base';
import SkioldPregnancyAnimalInput from 'web/view/components/stem-animal/stem-animal-input/skiold-pregnancy-animal-input';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { createAndEditValidation } from 'web/web-helpers/pregnancies/register-validation-helper';
import { validateSowAnimalNumberIsNotDepartured } from 'web/web-helpers/pregnancies/web-prengancy-validation-settings';
import { AssignIdNum } from './assign-id-num';
import './register-farrowing-base.scss';
import ObjectID from 'bson-objectid';

interface PropsFromParent {
	farrowed?: IFarrowing;
	animalNumber?: string;
	pregnancyEventId?: string;
	save?: any;
	remove?: any;
	dateRow?: boolean;
	genderRow?: boolean;
	getPregnancyAnimalNumberRow?: boolean;
	getNonEditablePregnancyAnimalNumberRow?: boolean;
	getLocationRow?: boolean;
	getBatchRow?: boolean;
	getAliveRow?: boolean;
	getDeadRow?: boolean;
	getAbortionRow?: boolean;
	getAliveFemaleRow?: boolean;
	getAliveMaleRow?: boolean;
	getMoveRow?: boolean;
	getAssignIdRow?: boolean;
	stableRow?: boolean;
	penRow?: boolean;
	getBreedRow?: boolean;
	getLastMoveDateRow?: boolean;
	getLitterWeightRow?: boolean;
	getMumifiedRow?: boolean;
	stemAnimalId?: string;
	selectedAnimal?: () => void;
	closeEditModal?: () => void;
	focusAliveNum?: boolean;
	getEarNotchRow?: boolean;
	disableResend?: (disabled: boolean) => void;
	setYoungAnimals?: (youngAnimals: IStemAnimal[]) => void;
	isEdit?: boolean;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		siteId: state.profile.active!.siteId,
		pregnancyEvents: mergeArraysHashmap(
			state.pregnancyEvents.entities,
			state.pregnancyEvents.departuredPregnancies,
		),
		validationSetup: state.validationSetup.entity,
		unitToPens: state.unitToPenData.data,
		processEquipmentData: state.processEquipmentData.entities,
		stemAnimals: memoizeAllAnimals(
			state.stemAnimals.entities,
			state.stemAnimals.departuredAnimals as IStemAnimal[],
		),
		departuredStemAnimals: state.stemAnimals.departuredAnimals,
		moveEvents: state.moveEvents.entities,
		generalSettings: state.generalSettings.entity,
		nucleusManagement: selectNucleusManagementOrAssignIdAccess(state),
		breedTableItems: state.breedTableItems.entities,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	getValidationSetup: () => ValidationSetupGetSyncData()(dispatch),
	stemAnimalGetSyncData: () => StemAnimalGetSyncData()(dispatch),
	pregnancyEventGetSyncData: () => PregnancyEventGetSyncData()(dispatch),
	savePregnancyEvent: (pregnancyEvent: IPregnancyEvent) => SavePregnancyEvent(pregnancyEvent)(dispatch),
	deleteLastPregnancyEvent: (pregEvent: IPregnancyEvent) => DeleteLastPregnancyEvent(pregEvent)(dispatch),
	GetProcessEquipmentDataSyncData: async () => (await GetProcessEquipmentDataSyncData())(dispatch),
	getunitsData: () => UnitsToPenOperations.GetSyncData()(dispatch),
	saveYoungAnimals: (youngAnimals: IStemAnimal[]) => UpsertManyYoungAnimal(youngAnimals)(dispatch),
	upsertBreedingSettings: (nucleusBreedSetting: INucleusManagementBreedingSettings) =>
		dispatch(upsertBreedingSettings(nucleusBreedSetting) as any),
});

export interface FarrowingState {
	sow: IStemAnimal;
	farrowed: IFarrowing;
	animalEvents: IPregnancyEvent[];
	prevEvent: IPregnancyEvent;
	animalNumber: string | undefined;
	matingBatchNumber: string | undefined;
	registerMoveEvent: boolean;
	toggleFocus: boolean;
	lastMoveDate: Date | undefined;
	dadBoar?: IStemAnimal;
	openModal: boolean;
	youngAnimalsToSave: IStemAnimal[] | undefined;
	youngAnimalsAlreadySaved: IStemAnimal[] | undefined; // Used to keep track on what is saved, and what is not

	nucleusSettingToSave: INucleusManagementBreedingSettings | undefined;
	mating?: IMated;
	breedRace?: string;
	currentState?: IFarrowing;
	disableAssign: boolean;
}

export type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;
export class RegisterFarrowingBase extends React.PureComponent<Props, FarrowingState> {
	public moveEventSave: (() => Promise<boolean>) | undefined;
	constructor(props: Props) {
		super(props);

		this.state = {
			sow: StemAnimal.fromJS({}),
			farrowed: this.props.farrowed!,
			animalEvents: [],
			prevEvent: {} as IPregnancyEvent,
			matingBatchNumber: undefined,
			animalNumber: undefined,
			registerMoveEvent: false,
			toggleFocus: false,
			lastMoveDate: undefined,
			openModal: false,
			youngAnimalsToSave: undefined,
			nucleusSettingToSave: undefined,
			youngAnimalsAlreadySaved: undefined,
			disableAssign: false,
		};

		this.animalNumberChanged = this.animalNumberChanged.bind(this);
	}

	public componentDidMount() {
		this.props.getunitsData();
		this.props.GetProcessEquipmentDataSyncData();
		if (this.props.pregnancyEventId && this.props.stemAnimalId) {
			setupPregnancyEventById(
				this.props.pregnancyEvents,
				this.props.pregnancyEventId,
				this.props.stemAnimalId,
				this.animalNumberChanged,
				(animalNumber, pregEvent) => {
					const youngAnimalsAlreadySaved = this.props.stemAnimals.filter(
						stem => pregEvent && stem.fromPregnancyId === pregEvent.pregnancyId,
					);

					this.setState({ animalNumber, youngAnimalsAlreadySaved, currentState: pregEvent as IFarrowing });
				},
			);
		} else if (this.props.stemAnimalId) {
			SetupPregnancyByStemAnimal(
				(sow, animalEvents, prevEvent, newEvent) => {
					this.animalNumberChanged(sow, animalEvents, prevEvent, newEvent);
				},
				this.props.stemAnimals.find(a => a.id === this.props.stemAnimalId),
				this.state.farrowed,
				this.props.pregnancyEvents[this.props.stemAnimalId],
			);
		}
		this.props.save(this.save);
		if (this.props.remove) {
			this.props.remove(this.removeEvent);
		}
	}

	public render() {
		let formRows: FormRow[] = [];
		if (this.props.getPregnancyAnimalNumberRow) {
			formRows.push(this.getPregnancyAnimalNumberRow(this.props.stemAnimalId ? false : true));
		}
		if (this.props.getNonEditablePregnancyAnimalNumberRow) {
			formRows.push(this.getNonEditablePregnancyAnimalNumberRow());
		}
		if (this.props.getLocationRow) {
			formRows.push(this.getLocationRow());
		}
		if (this.props.getLastMoveDateRow) {
			formRows.push(this.getLastMoveDateRow());
		}
		if (this.props.dateRow) {
			formRows.push(this.getDateRow());
		}
		if (this.props.getBatchRow) {
			formRows.push(this.getFarrowingBatchNumberRow());
		}
		if (this.props.getAliveRow) {
			formRows.push(this.getAliveNumberRow());
		}
		if (this.props.getAliveFemaleRow) {
			formRows.push(this.getAliveFemaleRow());
		}
		if (this.props.getAliveMaleRow) {
			formRows.push(this.getAliveMaleRow());
		}
		if (
			this.props.getEarNotchRow &&
			this.props.generalSettings.registerEarNotch &&
			hasNucleusFeatureCEFN(this.props.nucleusManagement)
		) {
			formRows.push(this.getEarNotchRow());
		}
		if (
			this.props.getAssignIdRow &&
			this.state.sow &&
			(this.state.sow.breedingNumber || hasAssignIdNumbers(this.props.nucleusManagement)) &&
			hasDanishNucleusFeatureOrAssignId(this.props.nucleusManagement)
		) {
			formRows.push(this.getAssignIdRow());
		}
		if (this.props.getDeadRow) {
			formRows.push(this.getDeadNumberRow());
		}

		if (
			this.props.getBreedRow &&
			this.props.generalSettings.farrowingShowRaceOnLitter &&
			this.props.nucleusManagement
		) {
			formRows.push(this.getBreedRow());
		}
		if (
			this.props.getLitterWeightRow &&
			this.props.generalSettings.farrowingRegisterLitterWeight &&
			hasNucleusFeatureCEFN(this.props.nucleusManagement)
		) {
			formRows.push(this.getLitterWeightRow());
		}
		if (this.props.getMumifiedRow && this.props.generalSettings.farrowingRegisterMumifiedPigs) {
			formRows.push(this.getMumifiedRow());
		}

		if (this.props.getAbortionRow) {
			formRows.push(this.getAbortionRow());
		}

		if (this.props.getMoveRow) {
			formRows.push(this.getToggleMoveEventRow());
		}
		return (
			<ViewWeb className="register-farrowing-base">
				{this.state.registerMoveEvent ? (
					<MoveEventBase
						date={this.state.farrowed.date}
						stableRow={(formRow: FormRow) => (this.props.stableRow ? formRows.push(formRow) : {})}
						penRow={(formRow: FormRow) => (this.props.penRow ? formRows.push(formRow) : {})}
						feedCurveRow={(formRow: FormRow) => formRows.push(formRow)}
						fixedDeviationRow={(formRow: FormRow) => formRows.push(formRow)}
						stemAnimal={this.state.sow}
						pregnancyEvent={this.state.farrowed}
						update={() => this.forceUpdate()}
						gender={Gender.Female}
						save={(method: () => Promise<boolean>) => (this.moveEventSave = method)}
						useCurrentLocationFromParent={true}
					/>
				) : null}
				<SkioldFormsWrapper formRows={formRows} containerClassName="forms-wrapper-container-style" />
				<SkioldModal
					close={this.closeAssignModal}
					shouldCloseOnOverlayClick={true}
					isOpen={this.state.openModal}
				>
					{this.state.openModal && (
						<AssignIdNum
							closeModal={this.closeAssignModal}
							farrowing={this.state.farrowed}
							momId={this.state.sow.id}
							dadId={this.state.dadBoar?.id}
							selectedYoungAnimals={this.state.youngAnimalsToSave}
							alreadySavedYoungAnimals={this.state.youngAnimalsAlreadySaved}
							deleteYoungAnimalCallback={this.deleteYoungAnimalCallback}
						/>
					)}
				</SkioldModal>
			</ViewWeb>
		);
	}

	public getFarrowing = () => {
		const farrowingToSave = {
			...this.state.farrowed,
			litterBreed: this.state.breedRace,
		} as IFarrowing;

		const generalSettings = this.props.generalSettings;
		if (generalSettings.farrowingShowAliveMales && !generalSettings.farrowingShowAliveFemales) {
			farrowingToSave.numFemaleAlive = (farrowingToSave.numAlive ?? 0) - (farrowingToSave.numMaleAlive ?? 0);
		} else {
			farrowingToSave.numMaleAlive = (farrowingToSave.numAlive ?? 0) - (farrowingToSave.numFemaleAlive ?? 0);
		}
		return farrowingToSave;
	};

	public animalNumberChanged = (
		sow: IStemAnimal,
		animalEvents: IPregnancyEvent[],
		prevEvent: IPregnancyEvent,
		newPregnancyEvent: IPregnancyEvent,
	) => {
		const lastMoveDate = getLastMoveEventDate(sow.id, this.props.moveEvents);
		const mating =
			sow.id &&
			this.props.pregnancyEvents[sow.id] &&
			this.props.pregnancyEvents[sow.id].find(
				p => p.pregnancyId === newPregnancyEvent.pregnancyId && p.state === PregnancyState.Mated,
			);
		const dadBoar = mating && this.props.stemAnimals.find(b => b.id === mating.boarId);
		const breedRace = findBreedRace(this.props.nucleusManagement, this.props.breedTableItems, sow, dadBoar);
		this.setState(
			{
				sow,
				animalEvents: animalEvents || [],
				prevEvent,
				farrowed: newPregnancyEvent as IFarrowing,
				matingBatchNumber: findBatch(sow.id!),
				registerMoveEvent: !isMoveDateValid(lastMoveDate, newPregnancyEvent.date)
					? false
					: this.state.registerMoveEvent,
				lastMoveDate,
				dadBoar,
				youngAnimalsToSave: [],
				mating,
				breedRace,
				disableAssign: breedRace === '?',
			},
			() => {
				if (this.state.sow && this.state.sow.animalNumber) {
					this.setState({ animalNumber: sow.animalNumber });
				}
			},
		);
	};

	public finishedDateChanged = (newDate: Date) => {
		const moveValid = isMoveDateValid(this.state.lastMoveDate, newDate);

		let earNotch = '';
		if (hasNucleusFeatureCEFN(this.props.nucleusManagement) && this.state.farrowed.earNotch) {
			const weekNumer = getFarrowingWeekByGeneralSetting(this.props.generalSettings, newDate);
			const yearLetter = getFarrowingYear(newDate);
			const value = this.state.farrowed.earNotch?.substring(2, this.state.farrowed.earNotch.length - 1);
			earNotch = weekNumer + value + yearLetter;
		}

		this.setState(prevState => ({
			farrowed: { ...prevState.farrowed, date: newDate, earNotch: earNotch },
			registerMoveEvent: !moveValid ? false : this.state.registerMoveEvent,
		}));
	};

	public deadChanged = (dead: number | undefined) => {
		this.setState(prevState => ({
			farrowed: { ...prevState.farrowed, numDead: dead },
		}));
	};

	public numAliveChanged = (alive: number | undefined) => {
		this.setState(prevState => ({
			farrowed: { ...prevState.farrowed, numAlive: alive } as IFarrowing,
		}));
	};

	public aliveFemaleChanged = (femaleAlive: number | undefined) => {
		const numAlive = this.state.farrowed.numAlive;
		if ((numAlive && femaleAlive && femaleAlive > numAlive) || numAlive === 0) {
			femaleAlive = this.state.farrowed.numAlive;
		}
		const maleAlive = numAlive && femaleAlive ? numAlive - femaleAlive : 0;

		this.setState(prevState => ({
			farrowed: { ...prevState.farrowed, numFemaleAlive: femaleAlive, numMaleAlive: maleAlive },
		}));
	};

	public aliveMaleChanged = (maleAlive: number | undefined) => {
		const numAlive = this.state.farrowed.numAlive;
		if ((numAlive && maleAlive && maleAlive > numAlive) || numAlive === 0) {
			maleAlive = numAlive;
		}
		const femaleAlive = numAlive && maleAlive ? numAlive - maleAlive : 0;

		this.setState(prevState => ({
			farrowed: { ...prevState.farrowed, numMaleAlive: maleAlive, numFemaleAlive: femaleAlive },
		}));
	};

	public toggleMoveEvent = () => {
		const toggle = !this.state.registerMoveEvent;
		this.setState({ registerMoveEvent: toggle });
	};

	public handleAbortion = () => {
		const abortion = !deepCopy(this.state.farrowed.abortion);
		if (abortion && this.state.farrowed.numAlive) {
			showAlert(localizedDynamic(ExceptionMessage.VALIDATION_ERROR_NO_ABORTION_IF_ALIVE_PIGS));
			return;
		}
		if (abortion) {
			this.setState(prevState => ({
				farrowed: {
					...prevState.farrowed,
					numFemaleAlive: undefined,
					numMaleAlive: undefined,
					abortion,
					numAlive: 0,
				},
			}));
		} else {
			this.setState(prevState => ({
				farrowed: { ...prevState.farrowed, abortion },
			}));
		}
	};

	public litterWeightChanged = (weight: number | undefined) => {
		this.setState(prevState => ({
			farrowed: { ...prevState.farrowed, litterWeight: weight },
		}));
	};

	public mumifiedChanged = (mumified: number | undefined) => {
		this.setState(prevState => ({
			farrowed: { ...prevState.farrowed, mumified },
		}));
	};
	public earNotchChanged = (earNotch: string | undefined) => {
		this.setState(prevState => ({
			farrowed: { ...prevState.farrowed, earNotch },
		}));
	};
	public openAssignModal = () => {
		if (
			validateOpenAssignId(
				this.state.sow,
				this.state.breedRace,
				this.state.mating,
				this.state.farrowed,
				this.props.validationSetup,
				showDefaultAlert,
				this.props.generalSettings,
			)
		) {
			this.setState({ openModal: true });
		}
	};
	public closeAssignModal = (assignIdsResponse: AssignIdsResponse) => {
		if (assignIdsResponse) {
			this.setState({
				youngAnimalsToSave: assignIdsResponse.youngAnimals,
				nucleusSettingToSave: assignIdsResponse.breedSettingToUse,
			});
			if (this.props.setYoungAnimals && assignIdsResponse.youngAnimals) {
				this.props.setYoungAnimals(assignIdsResponse.youngAnimals);
			}
		}
		this.setState({ openModal: false });
	};

	public save = async () => {
		let message = await this.validate();

		if (message !== '') {
			if (message !== 'ignore') {
				showAlert(localizedDynamic(message));
			}
			return;
		}

		if (
			!this.props.isEdit &&
			!this.state.registerMoveEvent &&
			!ValidateAnimalIPregnancyLocationAndEsfIsEnabled(
				this.state.sow,
				this.state.farrowed,
				this.props.unitToPens,
				this.props.processEquipmentData,
				showAlert,
			)
		) {
			return;
		}

		if (this.state.registerMoveEvent && this.moveEventSave) {
			const valid = await this.moveEventSave();
			if (!valid) {
				return;
			}
		}
		const notch = this.state.farrowed.earNotch;
		const value = notch?.substring(2, notch.length - 1);
		const formatEarnotch =
			notch && value?.length === 1 ? notch.substring(0, 2) + '0' + value + notch.substring(2 + 1) : notch;

		const farrowingToSave = {
			...this.state.farrowed,
			litterBreed: this.state.breedRace,
			earNotch: formatEarnotch,
		} as IFarrowing;

		const generalSettings = this.props.generalSettings;
		if (
			generalSettings.farrowingShowAliveMales &&
			!generalSettings.farrowingShowAliveFemales &&
			!isNullOrUndefined(farrowingToSave.numMaleAlive)
		) {
			farrowingToSave.numFemaleAlive = (farrowingToSave.numAlive ?? 0) - (farrowingToSave.numMaleAlive ?? 0);
		} else if (
			generalSettings.farrowingShowAliveFemales &&
			!generalSettings.farrowingShowAliveMales &&
			!isNullOrUndefined(farrowingToSave.numMaleAlive)
		) {
			farrowingToSave.numMaleAlive = (farrowingToSave.numAlive ?? 0) - (farrowingToSave.numFemaleAlive ?? 0);
		}

		this.props.savePregnancyEvent(farrowingToSave).then(() => {
			if (this.state.youngAnimalsToSave) {
				this.props.saveYoungAnimals(setFarrowingData(farrowingToSave, this.state.youngAnimalsToSave));
			}
			if (this.state.nucleusSettingToSave) {
				this.props.upsertBreedingSettings(this.state.nucleusSettingToSave);
			}
			this.props.pregnancyEventGetSyncData();
			this.resetComponent();
			this.editDone();
		});
	};

	public validate = async () => {
		let animalNumberCheck = validateSowAnimalNumberIsNotDepartured(
			this.state.sow,
			this.props.departuredStemAnimals,
			this.state.animalNumber,
		);
		if (animalNumberCheck !== 'ignore') {
			return animalNumberCheck;
		}

		const basicValidation = createAndEditValidation(
			this.props.validationSetup,
			this.props.generalSettings,
			this.state.farrowed,
			this.state.prevEvent,
			this.state.animalEvents,
			undefined,
			undefined,
			!!this.props.nucleusManagement,
		);
		if ((await basicValidation) !== '') {
			return basicValidation;
		}

		const nucleusValidator = new NucleusValidator(this.props.nucleusManagement, showDefaultAlert);
		if (
			this.state.mating &&
			!nucleusValidator.validateFarrowing(this.state.sow, this.state.mating, this.state.farrowed)
		) {
			return 'ignore';
		}

		return '';
	};

	public removeEvent = async () => {
		let allow = await ShowConfirmAlert(localized(ExceptionMessage.VALIDATION_WARNING_CONFIRM_DELETEION_FARROWING));
		if (!allow) {
			return;
		}
		if (this.state.farrowed.id === sortPregnanciesByDate(this.state.animalEvents)[0].id) {
			let pregEvent = deepCopy(this.state.farrowed);
			pregEvent.isDeleted = true;
			this.props.deleteLastPregnancyEvent(pregEvent);
			this.editDone();
		}
	};

	private getPregnancyAnimalNumberRow = (isEditable: boolean = true): FormRow => {
		return {
			name: localized('animalNumber'),
			component: (
				<SkioldPregnancyAnimalInput
					onChangeAnimalNumber={this.animalNumberChanged}
					text={this.state.animalNumber}
					pregnancyEvent={this.state.farrowed}
					onAnimalNumberChanged={(animalNumber: string | undefined) => this.setState({ animalNumber })}
					toggleFocus={this.state.toggleFocus}
					editable={isEditable ? true : false}
					selectTextOnFocus={isEditable ? true : false}
					className={`${
						this.state.sow && this.state.sow.departureType === DepartureTypes.departureTypeShouldDeparture
							? 'should-departure-list-color-with-margin-change'
							: ''
					}`}
				/>
			),
		};
	};

	private editDone = () => {
		if (this.props.closeEditModal) {
			this.props.closeEditModal();
		}
	};

	private getNonEditablePregnancyAnimalNumberRow = () => {
		return {
			name: localized('animalNumber'),
			component: <SkioldFormTextField>{this.state.animalNumber}</SkioldFormTextField>,
		};
	};

	private getFarrowingBatchNumberRow = (): FormRow => {
		return {
			name: localized('Batch'),
			component: <SkioldFormTextField>{this.state.matingBatchNumber}</SkioldFormTextField>,
		};
	};

	private getLocationRow = (): FormRow => {
		return {
			name: localized('location'),
			component: (
				<SkioldFormTextField>{this.state.sow ? getLocationString(this.state.sow) : ''}</SkioldFormTextField>
			),
		};
	};
	private getBreedRow = (): FormRow => {
		return {
			name: localized('LitterBreed'),
			component: <SkioldFormTextField>{this.state.breedRace}</SkioldFormTextField>,
		};
	};

	private getLastMoveDateRow = (): FormRow => {
		return {
			name: localized('lastMoved'),
			component: <SkioldFormTextField>{getDateString(this.state.lastMoveDate)}</SkioldFormTextField>,
		};
	};

	private getDateRow = (): FormRow => {
		return {
			name: localized('Date'),
			component: (
				<SkioldDatePicker
					onDateChanged={this.finishedDateChanged}
					selectedDate={this.state.farrowed.date!}
					theme={'dark'}
					color={'grey'}
					disabled={
						this.props.isEdit &&
						!!this.props.nucleusManagement &&
						!validateFarrowingDateChange(this.state.farrowed)
					}
				/>
			),
		};
	};

	private getAliveNumberRow = (): FormRow => {
		return {
			name: localized('alive'),
			component: (
				<SkioldFormIntegerInput
					onChangeNumber={newNumber => this.numAliveChanged(newNumber)}
					text={this.state.farrowed.numAlive}
					autoFocus={this.props.focusAliveNum}
				/>
			),
		};
	};

	private getDeadNumberRow = (): FormRow => {
		return {
			name: localized('dead'),
			component: (
				<SkioldFormIntegerInput
					onChangeNumber={newNumber => this.deadChanged(newNumber)}
					text={this.state.farrowed.numDead}
					editable={!(this.props.isEdit && this.props.nucleusManagement)}
				/>
			),
		};
	};

	private getAbortionRow = (): FormRow => {
		return {
			name: localized('abortion'),
			component: (
				<SkioldCheckbox
					onClick={this.handleAbortion}
					containerClassName={'inForm'}
					isChecked={this.state.farrowed.abortion!}
					disabled={this.props.isEdit && !!this.props.nucleusManagement}
				/>
			),
		};
	};

	private getAliveFemaleRow = (): FormRow => {
		return {
			name: localized('aliveFemale'),
			component: (
				<SkioldFormIntegerInput
					onChangeNumber={newNumber => this.aliveFemaleChanged(newNumber)}
					text={this.state.farrowed.numFemaleAlive}
					onBlur={this.checkAssignIdAlive}
				/>
			),
		};
	};

	private checkAssignIdAlive = async () => {
		if (
			await checkAssignIdAlive(
				this.state.youngAnimalsAlreadySaved,
				this.state.youngAnimalsToSave,
				this.state.farrowed.numFemaleAlive,
				this.state.farrowed.numMaleAlive,
				ShowConfirmAlert,
			)
		) {
			this.openAssignModal();
		}
	};

	private getAliveMaleRow = (): FormRow => {
		return {
			name: localized('aliveMale'),
			component: (
				<SkioldFormIntegerInput
					onChangeNumber={newNumber => this.aliveMaleChanged(newNumber)}
					text={this.state.farrowed.numMaleAlive}
					onBlur={this.checkAssignIdAlive}
				/>
			),
		};
	};

	private getToggleMoveEventRow = (): FormRow => {
		return {
			name: localized('move'),
			component: (
				<SkioldCheckbox
					onClick={this.toggleMoveEvent}
					containerClassName={'inForm'}
					isChecked={this.state.registerMoveEvent}
					disabled={
						!isMoveDateValid(this.state.lastMoveDate, this.state.farrowed.date) ||
						(this.props.isEdit && !!this.props.nucleusManagement)
					}
				/>
			),
		};
	};

	private getLitterWeightRow = (): FormRow => {
		return {
			name: localized('LitterWeight'),
			component: (
				<SkioldFormDecimalInput
					onChangeNumber={this.litterWeightChanged}
					text={this.state.farrowed.litterWeight}
					numberOfDecimals={1}
					disabled={this.props.isEdit && !!this.props.nucleusManagement}
				/>
			),
		};
	};

	private getMumifiedRow = (): FormRow => {
		return {
			name: localized('Mumified'),
			component: (
				<SkioldFormIntegerInput
					onChangeNumber={this.mumifiedChanged}
					text={this.state.farrowed.mumified}
					editable={!(this.props.isEdit && !!this.props.nucleusManagement)}
				/>
			),
		};
	};
	private getAssignIdRow = (): FormRow => {
		return {
			name: localized('AssignId'),
			component: (
				<ViewWeb className="flex-row align-items-center">
					<SkioldButton
						onPress={this.openAssignModal}
						title={localized('Assign')}
						disabled={this.disableAssignIdButton()}
					/>
				</ViewWeb>
			),
		};
	};

	public disableAssignIdButton = () => {
		const validationInterval = this.props.validationSetup.validationIntervals!.find(
			x => x.type === PregnancyValidationType.MatingToFarrowing,
		);

		const isDisabled =
			getDaysDifference(this.state.mating?.date, this.state.farrowed.date) < 109 ||
			getDaysDifference(this.state.mating?.date, this.state.farrowed.date) > 125;

		const numDoesNotMatch =
			this.state.currentState?.numAlive !== this.state.farrowed.numAlive ||
			this.state.currentState?.numFemaleAlive !== this.state.farrowed.numFemaleAlive ||
			this.state.currentState?.numMaleAlive !== this.state.farrowed.numMaleAlive;

		if (this.props.disableResend) {
			this.props.disableResend(!this.state.farrowed.abortion && (isDisabled || numDoesNotMatch));
		}

		return isDisabled || this.state.disableAssign;
	};

	private EarNotchCalculator = () => {
		const earNotchCalculatedChanged = (earNotch: string | undefined) => {
			this.setState(prevState => ({
				farrowed: { ...prevState.farrowed, earNotch: weekNumer + earNotch + yearLetter },
			}));
		};
		const weekNumer = getFarrowingWeekByGeneralSetting(this.props.generalSettings, this.state.farrowed.date);

		const yearLetter = getFarrowingYear(this.state.farrowed.date);
		const value = this.state.farrowed.earNotch?.substring(2, this.state.farrowed.earNotch.length - 1);
		return (
			<ViewWeb className="flex-row ear-notch-container">
				<SkioldFormTextField className="small-margin">{`${localized(
					'week',
				)}: ${weekNumer}`}</SkioldFormTextField>
				<SkioldFormInput
					className="ear-notch-input small-margin"
					onChangeText={earNotchCalculatedChanged}
					text={value}
				/>
				<SkioldFormTextField className="small-margin">{`${localized(
					'year',
				)}: ${yearLetter}`}</SkioldFormTextField>
			</ViewWeb>
		);
	};

	private getEarNotchRow = (): FormRow => {
		return {
			name: localized('EarNotch'),
			component: this.props.generalSettings.calculateEarNotch ? (
				this.EarNotchCalculator()
			) : (
				<SkioldFormInput onChangeText={this.earNotchChanged} text={this.state.farrowed.earNotch} />
			),
		};
	};

	private deleteYoungAnimalCallback = (id: string) => {
		if (this.state.youngAnimalsAlreadySaved) {
			this.setState({ youngAnimalsAlreadySaved: this.state.youngAnimalsAlreadySaved.filter(s => s.id !== id) });
		}
	};

	// Called after successfull save to remove one-time values
	private resetComponent = () => {
		this.setState({
			sow: StemAnimal.fromJS({ animalNumber: '' } as IStemAnimal),
			animalEvents: [],
			farrowed: Farrowing.fromJS({
				date: this.state.farrowed.date ? this.state.farrowed.date : new Date(),
				state: PregnancyState.Farrowing,
				id: new ObjectID().toHexString(),
				numAlive: undefined,
				numFemaleAlive: undefined,
				numMaleAlive: undefined,
			}),
			animalNumber: undefined,
			toggleFocus: !this.state.toggleFocus,
			youngAnimalsToSave: [],
		});
	};
}

export default connect<ReturnType<typeof mapStateToProps>, ReturnType<typeof mapDispatchToProps>, RefType, WebAppState>(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{ forwardRef: true },
)(RegisterFarrowingBase);
