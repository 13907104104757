import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Equipment, FeedingStatusUpdate, IProcessEquipmentData, IVisit } from 'shared/api/api';
import { calculateDays, deepCopy2 } from 'shared/helpers/general-helpers';
import { SaveFeedingStatusValidation } from 'shared/helpers/work-list-helpers/esf-list-helpers/general-work-list-esf-helpers';
import {
	getLastFourVisits,
	LastFourVisitEsfWorkListItem,
} from 'shared/helpers/work-list-helpers/esf-list-helpers/shortage-esf-helpers';
import { SaveFeedingStatus } from 'shared/state/ducks/esf-status/operations';
import { localized } from 'shared/state/i18n/i18n';
import { SharedAppState } from 'shared/state/store.shared';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import PageContainer from '../../page-container/page-container';
import SkioldFeedCurvePicker from '../../process-equipment/skiold-feed-curve-picker';
import { showAlert } from '../../skiold-alert/skiold-alert';
import { SkioldButton } from '../../skiold-components/skiold-button/skiold-button';
import { SkioldUnderlineIntergerInput } from '../../skiold-components/skiold-integer-input/skiold-underline-interger-input';
import { Heading } from '../../utils/heading';
import './last-four-visits-esf.scss';
import { VisitEsf } from './visit-esf';

const mapStateToProps = (state: SharedAppState) => {
	return {
		siteId: state.profile.active!.siteId!,
		stemAnimals: state.stemAnimals.entities,
		validationSettings:
			state.validationSetup.entity.validationIntervals &&
			state.validationSetup.entity.validationIntervals &&
			state.validationSetup.entity.validationIntervals[1] !== undefined &&
			state.validationSetup.entity.validationIntervals[1].plan
				? state.validationSetup.entity.validationIntervals[1].plan
				: 116,
		unitToPens: state.unitToPenData.data,
		processEquipmentData: state.processEquipmentData.entities.filter(peq => peq.equipmentType === Equipment.ESF),
		locations: state.locations,
		moveEvents: state.moveEvents.entities,
		esfStatusData: state.esfStatus.data,
		stations: state.station.data,
		esfDailyStatusData: state.esfDailyStatus.entities,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	saveFeedingStatus: (feedingStatusUpdate: FeedingStatusUpdate) => SaveFeedingStatus(feedingStatusUpdate)(dispatch),
});
type LastFourVisitsEsfProps = ReturnType<typeof mapStateToProps> &
	ReturnType<typeof mapDispatchToProps> &
	LastFourVisitsEsfPropsFromParent;

export interface LastFourVisitsEsfPropsFromParent {
	stemAnimalId: string | undefined;
	processEquipmentDataWithSettedPens: IProcessEquipmentData[];
	close: () => void;
}

interface LastFourVisitsState {
	itemCopy: LastFourVisitEsfWorkListItem | undefined;
	item: LastFourVisitEsfWorkListItem | undefined;
	fixedPercentage: string | undefined;
}

export class LastFourVisitsEsf extends React.PureComponent<LastFourVisitsEsfProps, LastFourVisitsState> {
	constructor(props: LastFourVisitsEsfProps) {
		super(props);
		const lastFourVisitsItem = getLastFourVisits(
			this.props.stemAnimals,
			this.props.unitToPens,
			this.props.locations.pens,
			this.props.moveEvents,
			this.props.esfStatusData,
			this.props.stemAnimalId,
			this.props.processEquipmentDataWithSettedPens,
		);
		this.state = {
			item: deepCopy2(lastFourVisitsItem),
			itemCopy: deepCopy2(lastFourVisitsItem),
			fixedPercentage: lastFourVisitsItem ? lastFourVisitsItem.fixedPercentage : undefined,
		};
	}
	private readonly onFixedPercentChanged = (number: number | undefined): void => {
		this.setState({ fixedPercentage: number !== undefined ? number.toString() : '' });
	};

	private readonly OnSelectedCurveChanged = (feedCurveNumber: number | undefined) => {
		let itemDataCopy = { ...this.state.itemCopy } as LastFourVisitEsfWorkListItem;
		itemDataCopy.selectedFeedCurveNumber = feedCurveNumber;
		this.setState({ itemCopy: itemDataCopy });
	};

	public render() {
		return (
			<PageContainer>
				<Heading text={localized('LastFourVisits')} />
				<ViewWeb className={'last-four-visits-top-container'}>
					<ViewWeb className={'items'}>
						<TextWeb>{localized('animalNumber')}:</TextWeb>
						<TextWeb className={'textStyle'}>
							{this.state.itemCopy && this.state.itemCopy.animalNumber
								? this.state.itemCopy.animalNumber
								: ''}
						</TextWeb>
					</ViewWeb>
					<ViewWeb className={'items'}>
						<TextWeb>{localized('curve')}:</TextWeb>

						{this.state.itemCopy && (
							<SkioldFeedCurvePicker
								feedCurveNumber={this.state.itemCopy.selectedFeedCurveNumber}
								penId={this.state.itemCopy.penId}
								onChange={this.OnSelectedCurveChanged}
								disabled={this.state.itemCopy.isDataValid ? false : true}
							/>
							// <SkioldDropdown
							// 	usedInTable={true}
							// 	items={this.props.feedCurvesOptions}
							// 	containerClassName="feed-curve-dropdown"
							// 	selectedValue={this.state.itemCopy.selectedCurve}
							// 	onValueChanged={this.OnSelectedCurveChanged}
							// 	disabled={this.state.itemCopy.isDataValid ? false : true}
							// />
						)}
					</ViewWeb>
					<ViewWeb className={'items'}>
						<TextWeb>{localized('fixedPercent')}:</TextWeb>
						{this.state.itemCopy && (
							<SkioldUnderlineIntergerInput
								text={
									this.state.fixedPercentage && this.state.fixedPercentage.length > 0
										? parseInt(this.state.fixedPercentage.toString())
										: undefined
								}
								containerClassName={'fixed-input-container'}
								className={
									this.state.itemCopy.isDataValid
										? 'textStyle editAbleTextColor'
										: 'textStyle editAbleTextColor disabled'
								}
								onChangeNumber={this.onFixedPercentChanged}
								editable={this.state.itemCopy.isDataValid}
							/>
						)}
					</ViewWeb>
				</ViewWeb>
				<ViewWeb className={'margin-left-and-right'}>
					{this.state.itemCopy && this.state.itemCopy.visits.map(this.renderVisits)}
				</ViewWeb>
				<ViewWeb className="alignViewContentCenter">
					<SkioldButton
						className="modal-close-button"
						title={localized('Close')}
						onPress={this.props.close}
						theme="grey"
					/>
					{this.state.itemCopy && this.state.itemCopy.isDataValid && (
						<SkioldButton
							className="modal-close-button"
							title={localized('Save')}
							onPress={this.save}
							theme="orange"
						/>
					)}
				</ViewWeb>
			</PageContainer>
		);
	}

	private save = () => {
		if (
			this.state.itemCopy &&
			this.state.item &&
			SaveFeedingStatusValidation(
				this.state.itemCopy.selectedFeedCurveNumber,
				this.state.item.selectedFeedCurveNumber,
				this.state.fixedPercentage,
				this.state.itemCopy.fixedPercentage,
				this.state.itemCopy.animalNumber,
				this.state.itemCopy.feedingStatusId,
				this.props.saveFeedingStatus,
				showAlert,
			)
		) {
			this.props.close();
		}
	};
	private renderVisits = (visit: IVisit | undefined, index) => {
		if (
			visit &&
			index < 4 &&
			index >= 0 &&
			visit.date &&
			visit.stationNumber &&
			visit.stationNumber !== '0' &&
			calculateDays(visit.date, new Date()) < 10
		) {
			return <VisitEsf key={index} visit={visit} visitNumber={index.toString()} />;
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(LastFourVisitsEsf);
