import { Gender, IStemAnimal } from 'shared/api/api';
import { StoreContainer } from 'shared/state/store-container';

export function findAnimal(animalNumber: string, gender: Gender): IStemAnimal | undefined {
	const state = StoreContainer.getState();
	let lowerAnimalNumber = animalNumber.toLocaleLowerCase();

	let foundAnimal = state.stemAnimals.entities.find(
		s => s.animalNumber != null && s.animalNumber!.toLocaleLowerCase() === lowerAnimalNumber && s.gender === gender,
	);
	return foundAnimal;
}

export function findActiveAnimal(
	animalNumber: string,
	gender: Gender,
	date: Date = new Date(),
): IStemAnimal | undefined {
	const state = StoreContainer.getState();
	let lowerAnimalNumber = animalNumber.toLocaleLowerCase();
	let foundAnimals = state.stemAnimals.entities.filter(
		animal =>
			animal.animalNumber != null &&
			animal.animalNumber!.toLocaleLowerCase() === lowerAnimalNumber &&
			animal.gender === gender,
	);
	let ActiveAnimal = foundAnimals.find(
		animal => (animal.departureDate != null && animal.departureDate > date) || !animal.departureDate,
	);

	if (ActiveAnimal) {
		return ActiveAnimal;
	}
	return foundAnimals[0];
}

export function findSow(animalNumber: string): IStemAnimal | undefined {
	return findAnimal(animalNumber, Gender.Female);
}

export function findBoar(animalNumber: string): IStemAnimal | undefined {
	return findAnimal(animalNumber, Gender.Male);
}

export function getDepartedYoungAnimals() {
	const state = StoreContainer.getState();

	let youngAnimals = (state.stemAnimals.departuredAnimals as IStemAnimal[]).filter(
		animal => animal.animalNumber == null,
	);
	return youngAnimals;
}

export function anyYoungAnimals() {
	const state = StoreContainer.getState();

	return state.stemAnimals.entities.some(animal => !animal.animalNumber);
}

export function getBoars() {
	const state = StoreContainer.getState();

	let sows = state.stemAnimals.entities
		.concat(state.stemAnimals.departuredAnimals as IStemAnimal[])
		.filter(animal => animal.animalNumber != null && animal.gender === Gender.Male);
	return sows;
}

export function getDepartedBoars() {
	const state = StoreContainer.getState();

	let sows = (state.stemAnimals.departuredAnimals as IStemAnimal[]).filter(
		animal => animal.animalNumber != null && animal.gender === Gender.Male,
	);
	return sows;
}

export function getActiveBoars(date: Date = new Date()) {
	const state = StoreContainer.getState();

	let sows = state.stemAnimals.entities.filter(
		animal =>
			animal.animalNumber != null &&
			animal.gender === Gender.Male &&
			((animal.departureDate != null && animal.departureDate > date) || !animal.departureDate),
	);
	return sows;
}

export function getActiveSows(
	fromDate?: Date,
	toDate?: Date,
	dateProperty?: 'entranceDate' | 'birthDate' | 'breedIndexDate',
) {
	const state = StoreContainer.getState();

	let testActiveSow = (animal: IStemAnimal) => {
		return (
			animal.animalNumber != null &&
			animal.animalNumber !== '' &&
			animal.gender === Gender.Female &&
			(!animal.departureDate || animal.departureDate > new Date())
		);
	};

	let sows: IStemAnimal[] = [];

	if (toDate !== undefined && fromDate !== undefined) {
		if (dateProperty === 'entranceDate') {
			sows = state.stemAnimals.entities.filter(
				animal =>
					testActiveSow(animal) &&
					animal.entranceDate! >= fromDate! &&
					animal.entranceDate! <= toDate! &&
					!animal.departureDate,
			);
		} else if (dateProperty === 'birthDate') {
			sows = state.stemAnimals.entities.filter(
				animal =>
					testActiveSow(animal) &&
					animal.birthDate! >= fromDate! &&
					animal.birthDate! <= toDate! &&
					!animal.departureDate,
			);
		} else if (dateProperty === 'breedIndexDate') {
			sows = state.stemAnimals.entities.filter(
				animal =>
					testActiveSow(animal) &&
					animal.breedIndexDate! >= fromDate! &&
					animal.breedIndexDate! <= toDate! &&
					!animal.departureDate,
			);
		} else {
			sows = state.stemAnimals.entities.filter(animal => testActiveSow(animal));
		}
	} else {
		sows = state.stemAnimals.entities.filter(animal => testActiveSow(animal));
	}

	return sows;
}

export function getDepartedSows() {
	const state = StoreContainer.getState();

	let sows = state.stemAnimals.entities.filter(
		animal => animal.animalNumber != null && animal.gender === Gender.Female && animal.departureDate != null,
	);
	return sows;
}

export function getSows() {
	const state = StoreContainer.getState();

	let sows = state.stemAnimals.entities.filter(
		animal => animal.animalNumber != null && animal.gender === Gender.Female,
	);
	return sows;
}

export function animalExists(animalNumber: string, gender: Gender, stemAnimalId?: string) {
	const state = StoreContainer.getState();
	let lowerAnimalNumber = animalNumber.toLocaleLowerCase();

	let exists =
		state.stemAnimals.entities.findIndex(
			s =>
				s.animalNumber != null &&
				s.animalNumber!.toLocaleLowerCase() === lowerAnimalNumber &&
				s.id !== stemAnimalId &&
				s.gender === gender &&
				s.departureDate == null,
		) > -1;

	return exists;
}

export function isTransponderOccupied(transponder: string, stemAnimalId: string) {
	const state = StoreContainer.getState();

	let transponderOccupied =
		state.stemAnimals.entities
			.filter(
				animal => (animal.departureDate != null && animal.departureDate < new Date()) || !animal.departureDate,
			)
			.findIndex(s => s.transponder === transponder && s.id !== stemAnimalId) > -1;

	return transponderOccupied;
}

export function findAnimalById(stemAnimalId: string | undefined) {
	if (!stemAnimalId) {
		return;
	}
	const state = StoreContainer.getState();
	const allAnimals = state.stemAnimals.entities.concat(state.stemAnimals.departuredAnimals as IStemAnimal[]);

	return allAnimals.find(animal => animal.id === stemAnimalId);
}
