import actionCreatorFactory from 'typescript-fsa';
import * as types from './types';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import { IWorkListSetting, SyncDataWorkListSetting } from 'shared/api/api';

const actionCreator = actionCreatorFactory(GenActionFactoryName('workListSettings'));

export const saveWorkListSetting = actionCreator.async<IWorkListSetting, string>(types.SAVE_WORK_LIST_SETTING);
export const getSyncData = actionCreator.async<
	{ siteId: string; lastSyncDate: Date; activeSiteId?: string },
	SyncDataWorkListSetting
>(types.SYNC_DATA_WORK_LIST_SETTINGS);

export const setSaveFunction = actionCreator<{saveFunction?: (any?: any) => any}>(types.SET_SAVE_FUNCTION);
export const setDeleteFunction = actionCreator<{deleteFunction?: (any?: any) => any}>(types.SET_DELETE_FUNCTION);
export const setPrintFunction = actionCreator<{printFunction?: (any?: any) => any}>(types.SET_PRINT_FUNCTION);
