import React, { FC, useState, useEffect, useCallback } from 'react';
import { Keyboard, KeyboardEvent } from 'react-native';
import { SetKeyboardHeight } from 'native/state/ducks/app-state/operations';
import { useDispatch, useSelector } from 'react-redux';
import { ViewWeb } from '../../utils/web-view';
import { SkioldDatePicker } from '../skiold-date-picker/skiold-date-picker';
import { WebAppState } from 'web/state/store.web';
import { setFromDate, setToDate } from 'shared/state/ducks/skiold-fetch/reducer';
interface PropsFromParent {
	datesToHighlightLeftDatePicker?: Date[] | undefined;
	datesToHighlightRightDatePicker?: Date[] | undefined;
	fromText?: string;
	toText?: string;
}
export const SkioldFetchDatePicker: FC<PropsFromParent> = React.memo(
	({ datesToHighlightLeftDatePicker, datesToHighlightRightDatePicker, fromText, toText }) => {
		const dispatch = useDispatch();
		const { fromDate, toDate } = useSelector((state: WebAppState) => state.skioldFetch);
		const fromDateChanged = useCallback(
			(fromDate: Date) => {
				dispatch(setFromDate(fromDate));
			},
			[dispatch],
		);

		const toDateChanged = useCallback(
			(toDate: Date) => {
				dispatch(setToDate(toDate));
			},
			[dispatch],
		);

		return (
			<>
				<SkioldDatePicker
					highlightDates={datesToHighlightLeftDatePicker}
					onDateChanged={fromDateChanged}
					selectedDate={fromDate!}
					maxDate={toDate}
					text={fromText ? fromText : 'FromDate'}
					containerClassName="datepicker-margin"
					includeTimeStamp={false}
				/>
				<SkioldDatePicker
					highlightDates={datesToHighlightRightDatePicker}
					onDateChanged={toDateChanged}
					selectedDate={toDate!}
					isEndDate={true}
					text={toText ? toText : 'ToDate'}
					containerClassName="datepicker-margin"
				/>
			</>
		);
	},
);
