import React from 'react';

import { connect } from 'react-redux';
import {
	ScanningWorkListProps,
	ScanningWorkListState,
	ScanningWorkListmapStateToProps,
	ScanningWorkListmapDispatchToProps,
} from 'shared/helpers/work-list-helpers/scanning-list-helpers/scanning-work-list-item';
import ScanningListTable from './scanning-work-list-table';
import { IScanningListSettingBase } from '../../../../../shared/api/api';

export class ScanningWorkList extends React.PureComponent<ScanningWorkListProps, ScanningWorkListState> {
	constructor(props: ScanningWorkListProps) {
		super(props);
		this.state = {
			workListSetting: props.workListSettings.find(
				setting => setting.type === props.choosedSetting,
			)! as IScanningListSettingBase,
		};
	}

	public render() {
		return (
			<ScanningListTable
				ref={this.setRef}
				batch={this.props.batch}
				workListSetting={this.state.workListSetting}
				choosedSetting={this.props.choosedSetting}
			/>
		);
	}

	private setRef = (m: any) => (m ? this.props.refCallback(m) : {});
}

export default connect(ScanningWorkListmapStateToProps, ScanningWorkListmapDispatchToProps, null, { forwardRef: true })(
	ScanningWorkList,
) as any;
