import { Action, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { Monitoring } from 'shared/helpers/monitoring-service';
import { isAppFailure } from 'shared/helpers/redux-helpers';

export const aiLoggerMiddleware: () => Middleware = () => {
    return <S>(api: MiddlewareAPI) => (next: Dispatch) => <A extends Action>(action: A): A => {
        if (isAppFailure(action)) {
            Monitoring.logTrace(JSON.stringify(action));
        }

        return next(action);
    };
};
