import { Sorting } from '@devexpress/dx-react-grid';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { dateTimeFormat } from 'shared/helpers/date-helpers';
import { GetSyncData } from 'web/state/ducks/bmp/operations';
import { WebAppState } from 'web/state/store.web';
import PageContainer from 'web/view/components/page-container/page-container';
import { SkioldChart } from 'web/view/components/skiold-components/skiold-chart/skiold-chart';
import { SkioldTableGrid } from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { Heading } from 'web/view/components/utils/heading';
import { ViewWeb } from 'web/view/components/utils/web-view';
import './bmp-overview.scss';

const mapStateToProps = (state: WebAppState) => {
	return {
		bmpDatas: state.BMPDatas.entities,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	syncBmp: () => GetSyncData()(dispatch),
});

const columns: any[] = [
	{
		name: 'date',
		title: 'Dato',
		getCellValue: (data: BMPTableModel) => data.date,
	},
	{
		name: 'maxPigArea',
		title: 'MaxPigArea',
		getCellValue: (data: BMPTableModel) => data.maxPigArea,
	},
	{
		name: 'maxSeqArea',
		title: 'MaxSeqArea',
		getCellValue: (data: BMPTableModel) => data.maxSeqArea,
	},
	{
		name: 'minPigArea',
		title: 'MinPigArea',
		getCellValue: (data: BMPTableModel) => data.minPigArea,
	},
];

const columnExtensions: any[] = [
	{
		columnName: 'date',
		width: 160,
	},
	{
		columnName: 'maxPigArea',
		width: 160,
	},
	{
		columnName: 'maxSeqArea',
		width: 160,
	},
	{
		columnName: 'minPigArea',
		width: 160,
	},
];

const chartDataValueFields = [
	{ key: 'Max pig area', value: 'maxPigArea' },
	{ key: 'Min seq area', value: 'maxSeqArea' },
	{ key: 'Min pig area', value: 'minPigArea' },
];

const defaultSorting = [{ columnName: 'date', direction: 'desc' }] as Sorting[];

export interface State {
	tableData: BMPTableModel[];
	graphData: any[];
}

interface BMPTableModel {
	id: string;
	date: string;
	maxPigArea: number;
	maxSeqArea: number;
	minPigArea: number;
}

interface ChartProps {
	argument: Date;
	maxPigArea: number;
	maxSeqArea: number;
	minPigArea: number;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class BMPOverview extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			tableData: this.generateTableData(),
			graphData: this.generateGraphData(),
		};

		this.props.syncBmp();
	}

	public componentDidUpdate(prevProps: Props, prevState: State) {
		if (prevProps.bmpDatas !== this.props.bmpDatas) {
			this.setState({ tableData: this.generateTableData(), graphData: this.generateGraphData() });
		}
	}

	public render() {
		return (
			<PageContainer className="bmp-overview">
				<Heading text={'BMP'} />
				<ViewWeb className="general-padding"></ViewWeb>
				<ViewWeb className="page-container">
					<ViewWeb className="table-container">
						<SkioldTableGrid
							columns={columns}
							ColumnExtensions={columnExtensions}
							data={this.state.tableData}
							ignoreSetCount={true}
							sortHeaderId={defaultSorting}
						/>
					</ViewWeb>
					<ViewWeb className="bmp-chart-container">
						<SkioldChart
							chartData={this.state.graphData}
							chartDataValueFields={chartDataValueFields}
							title="BMP kamera test"
						/>
					</ViewWeb>
				</ViewWeb>
			</PageContainer>
		);
	}

	private generateGraphData(): ChartProps[] {
		let chartData: ChartProps[] = [];

		let count = 0;
		this.props.bmpDatas.forEach((data, i) => {
			if (data.maxPigArea === undefined || data.maxSeqArea === undefined || data.maxPigArea === undefined) {
				return;
			}

			count++;
			if (count > 100) {
				return;
			}

			chartData.push({
				maxPigArea: data.maxPigArea![0],
				maxSeqArea: data.maxSeqArea![0],
				minPigArea: 0, //data.minPigArea![0],
				argument: data.timestamp!,
			});
		});

		return chartData;
	}

	private generateTableData(): BMPTableModel[] {
		let tableData: BMPTableModel[] = [];
		this.props.bmpDatas.forEach(data => {
			if (data.maxPigArea === undefined || data.maxSeqArea === undefined || data.maxPigArea === undefined) {
				return;
			}

			tableData.push({
				id: data.id!,
				date: moment(data.createdOn).format(dateTimeFormat),
				maxPigArea: data.maxPigArea ? data.maxPigArea![0] : 0,
				maxSeqArea: data.maxSeqArea ? data.maxSeqArea![0] : 0,
				minPigArea: data.minPigArea ? data.minPigArea![0] : 0,
			});
		});

		return tableData;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(BMPOverview);
