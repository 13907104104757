import { Dispatch } from 'redux';
import { ICountry, SkioldOneClient, ApiException } from 'shared/api/api';
import { SkioldDigitalApiBaseUrl } from 'shared/constants';
import * as Action from './actions';

export function GetCountries() {
	return (dispatch: Dispatch<any>) => {
		new SkioldOneClient(SkioldDigitalApiBaseUrl).country_HttpGet().then(
			(countries: ICountry[]) => {
				dispatch(Action.getCountries.done({ params: undefined, result: countries }));
			},
			(reject: ApiException) => {
				dispatch(
					Action.getCountries.failed({
						params: undefined,
						error: { code: reject.status, message: reject.response },
					}),
				);
			},
		);
	};
}
