import { createAsyncThunk } from '@reduxjs/toolkit';
import { AsyncOperationHandler } from '../../../helpers/redux-helpers';
import { FeedUsageParameters, GraphFetchParameters, GraphFetchPenParameters } from './types';

export const getGraphForSection = createAsyncThunk('feedUsage/getGraphForSection', (params: GraphFetchParameters) => {
	const { siteId, sectionId, fromDate, toDate } = params;
	return AsyncOperationHandler(client =>
		client.feedUsage_GetBySectionForWeanerGraph(siteId, sectionId, fromDate, toDate),
	);
});

export const getDataForBuilding = createAsyncThunk('feedUsage/getDataForBuilding', (params: FeedUsageParameters) => {
	const {buildingId, locationTypes} = params;
	return AsyncOperationHandler(client => client.feedUsage_GetByBuildingForApp(buildingId, locationTypes));
});

export const getBySection = createAsyncThunk(
	'feedUsage/getBySection',
	(params: { siteId: string; sectionId: string }) => {
		return AsyncOperationHandler(client => client.feedUsage_GetBySection(params.siteId, params.sectionId));
	},
);

export const getByPenForGraph = createAsyncThunk('feedUsage/getByPenForGraph', (params: GraphFetchPenParameters) => {
	const { siteId, penId, fromDate, toDate } = params;
	return AsyncOperationHandler(client => client.feedUsage_GetByPenForGraph(siteId, penId, fromDate, toDate));
});
