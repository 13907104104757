import {
	FileResponse,
	ISlaughterHouseDataOverviewDto,
	ISlaughterHouseDataRowModalDto,
	ISlaughterSiteDataDto,
	SkioldOneClient,
	SlaughterHouseDataOverviewDto,
	SlaughterHouseDataRowModalDto,
} from 'shared/api/api';
import { SkioldDigitalApiBaseUrl } from 'shared/constants';
import { getDateString } from 'shared/helpers/date-helpers';
import { setTwoDecimalsAsNumber, setTwoDecimalsAsString } from 'shared/helpers/general-helpers';
import { memoizeHashmapLocation } from 'shared/helpers/memoize-getters/memoize-getters';
import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import { GetLocationStringForSlaughterDataSectionId } from 'shared/helpers/slaughter-data-helper/slaughter-data-helper';
import { localized } from 'shared/state/i18n/i18n';
import { StoreContainer } from 'shared/state/store-container';
import { WebAppState } from 'web/state/store.web';
import { savePdfAs } from 'web/web-helpers/pdf-helper/general-pdf-helper';
import * as Action from './actions';

export function GetSyncSlaughterHouseDatas(siteId?: string, fromData?: Date, toDate?: Date) {
	return AsyncOperationBuilder2(
		Action.getSlaughterData,
		client => client.slaughterHouseData_GetSlaughterHouseDataSiteData(siteId, fromData, toDate),
		{ siteId, fromData, toDate },
	);
}

export function DeleteManySlaughterData(data: ISlaughterSiteDataDto[]) {
	const idsToUpdate = data.map(a => a.id!);
	return AsyncOperationBuilder2(
		Action.deleteSlaughterDatas,
		client => client.slaughterHouseData_DeleteManySlaughterData(idsToUpdate),
		idsToUpdate,
	);
}

export function UpdateSlaughterDataLocation(data: ISlaughterSiteDataDto[], buildingId?: string, sectionId?: string) {
	const idsToUpdate = data.map(a => a.id!);
	return AsyncOperationBuilder2(
		Action.postSlaughterDatas,
		client => client.slaughterHouseData_UpdateSlaughterDataLocation(idsToUpdate, buildingId, sectionId),
		data,
	);
}

export function PrintSlaughterDataOverviewTableData(data: ISlaughterSiteDataDto[], fileName: string) {
	const store = StoreContainer.getState() as WebAppState;

	const memorizedLocations = memoizeHashmapLocation(
		store.locations.buildings,
		store.locations.sections,
		store.locations.pens,
	);
	const site = store.site.site;
	const lang = store.profile.active ? store.profile.active.language : undefined;
	const datoToPrint = data.map(
		item =>
			SlaughterHouseDataOverviewDto.fromJS({
				date: getDateString(item.date),
				amount: item.amount ? item.amount.toString() : '',
				slaughterWeight: item.weight ? setTwoDecimalsAsString(item.weight) : '',
				meatPercentage: item.meat ? setTwoDecimalsAsString(item.meat) : '',
				locationString: GetLocationStringForSlaughterDataSectionId(
					memorizedLocations,
					site.siteName ? site.siteName : '',
					item.sectionId,
				),
				price: item.price ? setTwoDecimalsAsString(item.price) : '',
				supplier: item.supplier ? item.supplier : '',
			} as ISlaughterHouseDataOverviewDto),
	);
	return new SkioldOneClient(SkioldDigitalApiBaseUrl)
		.slaughterHouseDataPdf_GetSlaughterHouseDataOverviewPdf(
			datoToPrint,
			site.id,
			Intl.DateTimeFormat().resolvedOptions().timeZone,
			lang,
		)
		.then((fileResponse: FileResponse | null) => {
			savePdfAs(fileResponse, fileName);
		})
		.catch(() => {
			return;
		});
}

export function PrintSlaughterDataOverviewDataRowTableData(data: ISlaughterSiteDataDto[], fileName: string) {
	const store = StoreContainer.getState() as WebAppState;
	const memorizedLocations = memoizeHashmapLocation(
		store.locations.buildings,
		store.locations.sections,
		store.locations.pens,
	);
	const site = store.site.site;
	const lang = store.profile.active ? store.profile.active.language : undefined;
	const supplierText =
		data.length > 0 && data[0].supplier
			? ' - ' +
			  localized('location') +
			  ': ' +
			  GetLocationStringForSlaughterDataSectionId(
					memorizedLocations,
					site.siteName ? site.siteName : '',
					data[0].sectionId,
			  ) +
			  ' - ' +
			  localized('supplier') +
			  ' ' +
			  data[0].supplier.toString()
			: '';
	const datoToPrint = data.map(
		item =>
			SlaughterHouseDataRowModalDto.fromJS({
				date: getDateString(item.date),
				priceIncludingAdditionsDeductions: item.amount ? item.amount.toString() : '',
				slaughterWeight: item.weight ? setTwoDecimalsAsString(item.weight) : '',
				meatPercentage: item.meat ? setTwoDecimalsAsString(item.meat) : '',
				gender: item.gender ? localized(item.gender) : '',
				noting: item.noting ? localized(item.noting) : '',
				type: item.type ? localized(item.type) : '',
				deductionWeight: item.weightToDeduction ? item.weightToDeduction.toString() : '',
				deductionMeatPercentage: item.meatPercentageToDeduction
					? item.meatPercentageToDeduction.toString()
					: '',
				deductionDisease: item.diseaseToDeduction ? item.diseaseToDeduction.toString() : '',
				deductionSpec: item.specToDeduction ? item.specToDeduction.toString() : '',
				listingKg: item.listingKg ? item.listingKg.toString() : '',
				price: item.price ? setTwoDecimalsAsNumber(item.price)!.toString() : '',
				pricePrPig: item.price ? setTwoDecimalsAsString(item.pricePrPig) : '',
				aliveWeight: item.aliveWeight ? setTwoDecimalsAsNumber(item.aliveWeight)!.toString() : '',
				slaughterNumber: item.slaughterNumber ? item.slaughterNumber : '',
				remarks: item.remarks
					? item.remarks.map(
							e =>
								`${
									localized('DieseaseCodes.' + e) === 'DieseaseCodes.' + e
										? e
											? e
											: ''
										: localized('DieseaseCodes.' + e)
								}`,
					  )
					: [],
			} as ISlaughterHouseDataRowModalDto),
	);
	return new SkioldOneClient(SkioldDigitalApiBaseUrl)
		.slaughterHouseDataPdf_GetSlaughterHouseDataRowModalPdf(
			datoToPrint,
			site.id,
			Intl.DateTimeFormat().resolvedOptions().timeZone,
			supplierText,
			lang,
		)
		.then((fileResponse: FileResponse | null) => {
			savePdfAs(fileResponse, fileName);
		})
		.catch(() => {
			return;
		});
}
