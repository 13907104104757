import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { WebAppState } from 'web/state/store.web';
import { SaveConnectionStatus } from 'shared/state/ducks/offline/operations';
import TriggerSync from './trigger-sync';

const mapStateToProps = (state: WebAppState) => {
	return {
		isConnected: state.offline.isConnected
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	saveConnectionStatus: (isConnected: boolean) => SaveConnectionStatus(isConnected)(dispatch)
});

interface State {
	isConnected?: boolean;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class OfflineNotice extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		this.props.saveConnectionStatus(true);
	}

	public componentDidMount() {
		window.addEventListener('online', this.handleOnline);
		window.addEventListener('offline', this.handleOffline);
	}

	public componentWillUnmount() {
		window.removeEventListener('online', this.handleOnline);
		window.removeEventListener('offline', this.handleOffline);
	}

	public render() {
		return <TriggerSync />;
	}

	private handleConnectivityChange = (isConnected: boolean) => {
		this.props.saveConnectionStatus(isConnected);
	}

	private handleOnline = () => {
		this.handleConnectivityChange(true);
	}

	private handleOffline = () => {
		this.handleConnectivityChange(false);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(OfflineNotice);
