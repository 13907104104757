import React, { FC } from 'react';
import { DayOfWeek, LocationType } from 'shared/api/api';
import {
	FindHiddenPenOnSection,
	getFilteredLocationsByTypesAndNoPens,
	getStableSectionOptionByPenId,
} from 'shared/helpers/location-helper';
import { getStableSectionOptions } from 'shared/helpers/location-helpers/stable-section-helper';
import { localized } from 'shared/state/i18n/i18n';
import { SkioldCheckbox } from 'web/view/components/skiold-components/skiold-checkbox/skiold-checkbox';
import { SkioldFormDropdown } from 'web/view/components/skiold-components/skiold-dropdown/skiold-form-dropdown';
import { SkioldFormsWrapper } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { useGeneralSetting } from './general-settings-overview';
import { Option } from 'react-dropdown';
import { SkioldDropdown } from 'web/view/components/skiold-components/skiold-dropdown/skiold-dropdown';
import { dayOfWeekOptions } from '../nucleus-management/settings/nucleus-management-helper';
import {
	hasNucleusFeatureCEFN,
	showNucleusFeatureCEFN,
} from 'shared/helpers/nucleus-management-helper/nucleus-management-helper';

interface Props {}

export const FarrowingSetting: FC<Props> = React.memo(() => {
	const { generalSetting, setGeneralSetting, nucleusManagement } = useGeneralSetting();
	const getFormRows = () => {
		let formRows = new Array<FormRow>();

		formRows.push(getWorkListHeader());
		formRows.push(ShowAliveFemales());
		formRows.push(ShowAliveMales());
		if (nucleusManagement) {
			formRows.push(ShowRaceForLitter());
		}

		if (hasNucleusFeatureCEFN(nucleusManagement)) {
			formRows.push(ShowRegisterLitterWeight());
			formRows.push(getEarNotchRow());
		}

		formRows.push(ShowRegisterMumifiedPigs());
		if (nucleusManagement) {
			formRows.push(getStableSectionPickerRow());
		}

		return formRows;
	};
	const getWorkListHeader = (): FormRow => {
		return {
			header: localized('Farrowings'),
		};
	};

	const ShowAliveFemales = (): FormRow => {
		return {
			name: localized('RegisterAliveFemales'),
			component: (
				<ViewWeb className="two-checkbox-container">
					<SkioldCheckbox
						onClick={SetShowAliveFemalesTrue}
						isChecked={generalSetting.farrowingShowAliveFemales}
					/>
					<TextWeb>{localized('True')}</TextWeb>
					<SkioldCheckbox
						onClick={SetShowAliveFemalesFalse}
						isChecked={!generalSetting.farrowingShowAliveFemales}
					/>
					<TextWeb>{localized('False')}</TextWeb>
				</ViewWeb>
			),
		};
	};

	const ShowAliveMales = (): FormRow => {
		return {
			name: localized('RegisterAliveMales'),
			component: (
				<ViewWeb className="two-checkbox-container">
					<SkioldCheckbox
						onClick={SetShowAliveMalesTrue}
						isChecked={generalSetting.farrowingShowAliveMales}
					/>
					<TextWeb>{localized('True')}</TextWeb>
					<SkioldCheckbox
						onClick={SetShowAliveMalesFalse}
						isChecked={!generalSetting.farrowingShowAliveMales}
					/>
					<TextWeb>{localized('False')}</TextWeb>
				</ViewWeb>
			),
		};
	};

	const ShowRaceForLitter = (): FormRow => {
		return {
			name: localized('ShowRaceForLitter'),
			component: (
				<ViewWeb className="two-checkbox-container">
					<SkioldCheckbox
						onClick={SetShowRaceForLitterTrue}
						isChecked={generalSetting.farrowingShowRaceOnLitter}
					/>
					<TextWeb>{localized('True')}</TextWeb>
					<SkioldCheckbox
						onClick={SetShowRaceForLitterFalse}
						isChecked={!generalSetting.farrowingShowRaceOnLitter}
					/>
					<TextWeb>{localized('False')}</TextWeb>
				</ViewWeb>
			),
		};
	};

	const ShowRegisterLitterWeight = (): FormRow => {
		return {
			name: localized('RegisterLitterWeight'),
			component: (
				<ViewWeb className="two-checkbox-container">
					<SkioldCheckbox
						onClick={SetRegisterLitterWeightTrue}
						isChecked={generalSetting.farrowingRegisterLitterWeight}
					/>
					<TextWeb>{localized('True')}</TextWeb>
					<SkioldCheckbox
						onClick={SetRegisterLitterWeightFalse}
						isChecked={!generalSetting.farrowingRegisterLitterWeight}
					/>
					<TextWeb>{localized('False')}</TextWeb>
				</ViewWeb>
			),
		};
	};

	const ShowRegisterMumifiedPigs = (): FormRow => {
		return {
			name: localized('RegisterMumifiedPigs'),
			component: (
				<ViewWeb className="two-checkbox-container">
					<SkioldCheckbox
						onClick={SetRegisterMumifiedPigsTrue}
						isChecked={generalSetting.farrowingRegisterMumifiedPigs}
					/>
					<TextWeb>{localized('True')}</TextWeb>
					<SkioldCheckbox
						onClick={SetRegisterMumifiedPigsFalse}
						isChecked={!generalSetting.farrowingRegisterMumifiedPigs}
					/>
					<TextWeb>{localized('False')}</TextWeb>
				</ViewWeb>
			),
		};
	};

	const getStableSectionPickerRow = (): FormRow => {
		const { buildings, sections } = getFilteredLocationsByTypesAndNoPens([LocationType.YoungAnimal]);
		const locationOption = getStableSectionOptionByPenId(generalSetting.farrowingYoungAnimalsPenId);
		return {
			name: localized('MapYoungAnimalsToLocation'),
			component: (
				<>
					<SkioldFormDropdown
						items={getStableSectionOptions(buildings, sections)}
						onValueChanged={onStableSectionChanged}
						selectedValue={locationOption}
					/>
				</>
			),
		};
	};

	const getEarNotchRow = (): FormRow => {
		const dayOption = dayOfWeekOptions.find(day => day.value === generalSetting.startWeekDay);
		return {
			name: (
				<ViewWeb className="multiple-row-container">
					<TextWeb className="bold-font-size-sixteen">{localized('RegisterEarNotch')}:</TextWeb>
					<TextWeb className="bold-font-size-sixteen">{localized('CalculateEarNotch')}</TextWeb>
					{generalSetting.calculateEarNotch && (
						<TextWeb className="bold-font-size-sixteen">{localized('WeekStartyDay')}</TextWeb>
					)}
				</ViewWeb>
			),
			component: (
				<ViewWeb className="multiple-row-container">
					<ViewWeb className="two-checkbox-container flexOne">
						<SkioldCheckbox onClick={SetRegisterEarNotchTrue} isChecked={generalSetting.registerEarNotch} />
						<TextWeb>{localized('True')}</TextWeb>
						<SkioldCheckbox
							onClick={SetRegisterEarNotchFalse}
							isChecked={!generalSetting.registerEarNotch}
						/>
						<TextWeb>{localized('False')}</TextWeb>
					</ViewWeb>
					<ViewWeb className="two-checkbox-container flexOne">
						<SkioldCheckbox
							onClick={SetCalculateEarNotchTrue}
							isChecked={generalSetting.calculateEarNotch}
						/>
						<TextWeb>{localized('True')}</TextWeb>
						<SkioldCheckbox
							onClick={SetCalculateEarNotchFalse}
							isChecked={!generalSetting.calculateEarNotch}
						/>
						<TextWeb>{localized('False')}</TextWeb>
					</ViewWeb>
					{generalSetting.calculateEarNotch && (
						<ViewWeb className="align-picker flexOne">
							<SkioldDropdown
								items={dayOfWeekOptions}
								selectedValue={dayOption}
								onValueChanged={dayOfWeekChanged}
								theme={'dark'}
							/>
						</ViewWeb>
					)}
				</ViewWeb>
			),
			style: { height: generalSetting.calculateEarNotch ? 150 : 100 },
		};
	};

	const onStableSectionChanged = (selectedSection: Option) => {
		let penId = FindHiddenPenOnSection(selectedSection.value);
		if (penId) {
			setGeneralSetting({
				...generalSetting,
				farrowingYoungAnimalsPenId: penId,
				farrowingYoungAnimalsSectionId: selectedSection.value,
			});
		}
	};

	const SetRegisterEarNotchTrue = () => {
		setGeneralSetting({ ...generalSetting, registerEarNotch: true });
	};

	const SetRegisterEarNotchFalse = () => {
		setGeneralSetting({ ...generalSetting, registerEarNotch: false });
	};

	const SetCalculateEarNotchTrue = () => {
		setGeneralSetting({ ...generalSetting, calculateEarNotch: true });
	};

	const SetCalculateEarNotchFalse = () => {
		setGeneralSetting({ ...generalSetting, calculateEarNotch: false });
	};

	const dayOfWeekChanged = (dayOfTheWeek: Option) => {
		setGeneralSetting({ ...generalSetting, startWeekDay: DayOfWeek[dayOfTheWeek.value] });
	};

	const SetShowAliveFemalesTrue = () => {
		setGeneralSetting({ ...generalSetting, farrowingShowAliveFemales: true });
	};

	const SetShowAliveFemalesFalse = () => {
		setGeneralSetting({ ...generalSetting, farrowingShowAliveFemales: false });
	};

	const SetShowAliveMalesTrue = () => {
		setGeneralSetting({ ...generalSetting, farrowingShowAliveMales: true });
	};

	const SetShowAliveMalesFalse = () => {
		setGeneralSetting({ ...generalSetting, farrowingShowAliveMales: false });
	};

	const SetShowRaceForLitterTrue = () => {
		setGeneralSetting({ ...generalSetting, farrowingShowRaceOnLitter: true });
	};

	const SetShowRaceForLitterFalse = () => {
		setGeneralSetting({ ...generalSetting, farrowingShowRaceOnLitter: false });
	};

	const SetRegisterLitterWeightTrue = () => {
		setGeneralSetting({ ...generalSetting, farrowingRegisterLitterWeight: true });
	};

	const SetRegisterLitterWeightFalse = () => {
		setGeneralSetting({ ...generalSetting, farrowingRegisterLitterWeight: false });
	};

	const SetRegisterMumifiedPigsTrue = () => {
		setGeneralSetting({ ...generalSetting, farrowingRegisterMumifiedPigs: true });
	};

	const SetRegisterMumifiedPigsFalse = () => {
		setGeneralSetting({ ...generalSetting, farrowingRegisterMumifiedPigs: false });
	};

	return <SkioldFormsWrapper formRows={getFormRows()} containerClassName="wrapper-container" />;
});
