import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { localized, localizedInterpolation } from 'shared/state/i18n/i18n';
import PageContainer from '../page-container/page-container';
import { Heading } from '../utils/heading';
import { WebAppState } from 'web/state/store.web';
import { SowListConstants } from '../stem-animal/animal-lists/table-constants';
import { FeedingAdjustmentTableModel } from './feeding-table-model';
import { getDateString, isSameDate } from 'shared/helpers/date-helpers';
import { NaturalSortDates } from 'shared/helpers/natural-sort';
import SkioldTableGrid from '../skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { ViewWeb } from '../utils/web-view';
import { Sorting } from '@devexpress/dx-react-grid';
import { generatePenFeedingConsumptions } from './food-helper';
import { getLocationStringBySectionId } from 'shared/helpers/location-helper';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { memoizeHashmapLocation } from 'shared/helpers/memoize-getters/memoize-getters';
import { SkioldButton } from '../skiold-components/skiold-button/skiold-button';
import './feeding.scss';
import { selectFeedingUnitType } from 'shared/helpers/feeding/feeding-helper';
import { DistriwinFeedUnitType } from '../../../../shared/api/api';

interface PropsFromParent {
	sectionId?: string;
	date?: Date;
	closeModal?: () => void;
}

export const DistriwinPenFeedingTable: FC<PropsFromParent> = React.memo(({ sectionId, closeModal, date }) => {
	const consumptions = useSelector((state: WebAppState) => state.distriwinFeedConsumptions.dtoEntities);
	const language = useSelector((state: WebAppState) => state.profile.active && state.profile.active.language);
	const hashmapLocations = useSelector((state: WebAppState) =>
		memoizeHashmapLocation(state.locations.buildings, state.locations.sections, state.locations.pens),
	);
	const feedinUnitType = useSelector(selectFeedingUnitType);
	const consumptionsBySection = useMemo(() => {
		return generatePenFeedingConsumptions(
			consumptions.filter(c => c.sectionId === sectionId && isSameDate(c.periodTo, date)),
			language,
		);
	}, [consumptions, language, sectionId, date]);
	const getColunmExts = () => {
		let columnExts: any[] = [
			{
				columnName: 'periodFrom',
				width: SowListConstants.indexDateWidth,
			},
			{
				columnName: 'penName',
				width: SowListConstants.indexDateWidth,
			},
			{
				columnName: 'feedingSubject',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'feedingCategory',
				width: 160,
			},
			{
				columnName: 'recipe',
				width: 160,
			},
			{
				columnName: 'amountInKg',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'Energy1',
				width: SowListConstants.animalNrWidth,
			},
		];
		if (feedinUnitType === DistriwinFeedUnitType.FEsoFEsv) {
			columnExts.push({
				columnName: 'Energy2',
				width: SowListConstants.animalNrWidth,
			});
		}
		return columnExts;
	};

	const getPeriodFromCell = (adjustment: FeedingAdjustmentTableModel) => getDateString(adjustment.periodFrom);

	const getAmountInKgCell = (adjustment: FeedingAdjustmentTableModel) => adjustment.amount!.toFixed(2);

	const getFeedingCategoryCell = (adjustment: FeedingAdjustmentTableModel) => adjustment.FeedingCategory;

	const getFeedingSubjectCell = (adjustment: FeedingAdjustmentTableModel) => adjustment.FeedingSubject;
	const getEnergy1PerKgCell = (adjustment: FeedingAdjustmentTableModel) =>
		(adjustment.energy1PerKg! / adjustment.amount!).toFixed(2);

	const getEnergy2PerKgCell = (adjustment: FeedingAdjustmentTableModel) =>
		(adjustment.energy2PerKg! / adjustment.amount!).toFixed(2);

	const getColumns = () => {
		let columns: any[] = [
			{
				name: 'periodFrom',
				sortFunction: NaturalSortDates,
				title: localized('PeriodFrom'),
				getCellValue: getPeriodFromCell,
			},
			{
				name: 'penName',
				title: localized('Pen'),
			},
			{
				name: 'feedingSubject',
				title: localized('FeedingSubject'),
				getCellValue: getFeedingSubjectCell,
			},
			{
				name: 'feedingCategory',
				title: localized('Type'),
				getCellValue: getFeedingCategoryCell,
			},
			{
				name: 'recipe',
				title: localized('Recipe'),
			},
			{
				name: 'amountInKg',
				title: localized('AmountInKgs'),
				getCellValue: getAmountInKgCell,
			},
			{
				name: 'Energy1',
				title: localizedInterpolation('EnergyKg', feedinUnitType + 'Energy1'),
				getCellValue: getEnergy1PerKgCell,
			},
		];
		if (feedinUnitType === DistriwinFeedUnitType.FEsoFEsv) {
			columns.push({
				name: 'Energy2',
				title: localizedInterpolation('EnergyKg', feedinUnitType + 'Energy2'),
				getCellValue: getEnergy2PerKgCell,
			});
		}
		return columns;
	};

	const closeModalFunc = () => {
		closeModal && closeModal();
	};

	const defaultSorting = [{ columnName: 'periodFrom', direction: 'asc' }] as Sorting[];
	return (
		<PageContainer className="distriwin-feeding-pen-container">
			<Heading text={localized('FeedingUsage')} />
			<ViewWeb className="flex-row-space-between align-items-center default-modal-margin">
				<TextWeb className="skiold-text-label">{`${localized('Location')} - ${getLocationStringBySectionId(
					sectionId,
					hashmapLocations,
				)}`}</TextWeb>
				<SkioldButton theme="grey" title={localized('Close')} onPress={closeModalFunc} />
			</ViewWeb>

			<ViewWeb className="default-modal-margin">
				<SkioldTableGrid
					columns={getColumns()}
					tableKey={'DistriwinPenFeedingTable'}
					ColumnExtensions={getColunmExts()}
					data={consumptionsBySection}
					ignoreSetCount={true}
					sortHeaderId={defaultSorting}
				/>
			</ViewWeb>
		</PageContainer>
	);
});
