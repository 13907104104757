/* eslint-disable no-loss-of-precision */
import moment from 'moment';
import { AnimalType, GrowthPigEventType } from 'shared/api/api';
import { calculateDays, setOneDecimalsAsNumber, setTwoDecimalsAsNumber } from '../general-helpers';

export function GetTotalWeightByEntrance(
	eventData: Date,
	currentTotalWeight: number,
	amount: number,
	animalType: AnimalType,
	toDate: Date,
	isSameProduction: boolean | undefined,
) {
	if (calculateDays(eventData, toDate) !== 0) {
		let ageInDays = GetAgeInDays(eventData, animalType, toDate, currentTotalWeight, amount);
		let totalWeight = GetTotalWeight(animalType, currentTotalWeight, amount, ageInDays);
		const newWeight = calculateWeight(eventData, currentTotalWeight, amount, animalType, toDate, isSameProduction);
		return newWeight;
	}

	return currentTotalWeight;
}

export function GetAgeInDays(
	eventData: Date,
	animalType: AnimalType,
	toDate: Date,
	currentTotalWeight: number,
	amount: number,
) {
	// The entrance day is not counted as a FeedDay
	const entranceDate = eventData;

	const diffDays = calculateDays(entranceDate, toDate);
	let initialAvgWeight = currentTotalWeight / amount;
	let initialAge = 0;
	if (animalType) {
		initialAge = CalcDays(initialAvgWeight, animalType);
	}

	let ageInDays = initialAge + diffDays;
	return Math.ceil(ageInDays);
}

export function GetTotalWeight(animalType: AnimalType, currentTotalWeight: number, amount: number, ageInDays: number) {
	if (currentTotalWeight > 0 && amount > 0 && animalType) {
		let avgWeight = CalcWeight(ageInDays, animalType);

		return avgWeight * amount;
	}

	return 0;
}
export function CalcWeight(days: number, pigType: AnimalType) {
	let weight = 0;
	let tuples = pigType === AnimalType.Weaner ? [...WeanerWeightTuple] : [...FinisherWeightTuple];
	if (days < 0) {
		weight = 0;
	} else if (days <= tuples[tuples.length - 1].days) {
		// TODO already spent days and handle days <= 14 and weight > 9.6
		weight = CalcWeightPiglets(days, pigType);
	} else if (pigType === AnimalType.Weaner) {
		weight = CalcWeightClimatePigs(days);
	} else if (pigType === AnimalType.Finisher) {
		weight = CalcWeightSlaughterPigs(days);
	}

	if (weight < 0) {
		weight = 0;
	}

	return weight;
}

export function CalcWeightClimatePigs(days: number) {
	let x6 = -1.052125022347357 * Math.pow(10, -12) * Math.pow(days, 6);
	let x5 = 9.5794285818886781 * Math.pow(10, -10) * Math.pow(days, 5);
	let x4 = -2.7711401589045702 * Math.pow(10, -7) * Math.pow(days, 4);
	let x3 = 1.0173187369650515 * Math.pow(10, -5) * Math.pow(days, 3);
	let x2 = 6.2441998995195931 * Math.pow(10, -3) * Math.pow(days, 2);
	let x1 = 1.6546772148725564 * Math.pow(10, -1) * days;
	let startVal = 6.3688189855110808;

	let weight = x6 + x5 + x4 + x3 + x2 + x1 + startVal;

	return weight;
}

export function CalcWeightSlaughterPigs(days: number) {
	let x6 = -1.2955625485307641 * Math.pow(10, -12) * Math.pow(days, 6);
	let x5 = 1.1197354532837012 * Math.pow(10, -9) * Math.pow(days, 5);
	let x4 = -2.9511762921410562 * Math.pow(10, -7) * Math.pow(days, 4);
	let x3 = 2.2618965086784623 * Math.pow(10, -6) * Math.pow(days, 3);
	let x2 = 7.9345203839221862 * Math.pow(10, -3) * Math.pow(days, 2);
	let x1 = 1.5191806628616167 * Math.pow(10, -1) * days;
	let startVal = 6.4536578541229179;

	let weight = x6 + x5 + x4 + x3 + x2 + x1 + startVal;

	return weight;
}

export function CalcDays(weight: number, pigType: AnimalType) {
	let days = 0;
	let tuples = pigType === AnimalType.Weaner ? [...WeanerWeightTuple] : [...FinisherWeightTuple];
	if (weight < 0) {
		days = 0;
	} else if (weight <= tuples[tuples.length - 1].weight) {
		days = CalcDaysPiglets(weight, pigType);
	} else if (pigType === AnimalType.Weaner) {
		days = CalcDaysClimatePigs(weight);
	} else if (pigType === AnimalType.Finisher) {
		days = CalcDaysSlaughterPigs(weight);
	}

	if (days < 0) {
		days = 0;
	}
	return days;
}

export const FinisherWeightTuple = [
	{ weight: 7, days: 0 },
	{ weight: 6.9, days: 1 },
	{ weight: 6.9, days: 2 },
	{ weight: 7, days: 3 },
	{ weight: 7.1, days: 4 },
	{ weight: 7.3, days: 5 },
	{ weight: 7.4, days: 6 },
	{ weight: 7.7, days: 7 },
	{ weight: 7.9, days: 8 },
	{ weight: 8.2, days: 9 },
	{ weight: 8.5, days: 10 },
	{ weight: 8.9, days: 11 },
	{ weight: 9.2, days: 12 },
	{ weight: 9.6, days: 13 },
	{ weight: 10, days: 14 },
];
export const WeanerWeightTuple = [
	{ weight: 7, days: 0 },
	{ weight: 6.9, days: 1 },
	{ weight: 6.9, days: 2 },
	{ weight: 7, days: 3 },
	{ weight: 7.1, days: 4 },
	{ weight: 7.2, days: 5 },
	{ weight: 7.4, days: 6 },
	{ weight: 7.6, days: 7 },
	{ weight: 7.8, days: 8 },
	{ weight: 8.1, days: 9 },
	{ weight: 8.4, days: 10 },
	{ weight: 8.7, days: 11 },
	{ weight: 9, days: 12 },
	{ weight: 9.4, days: 13 },
	{ weight: 9.8, days: 14 },
];

export function CalcDaysPiglets(weight: number, pigType: AnimalType) {
	let tuples = pigType === AnimalType.Weaner ? [...WeanerWeightTuple] : [...FinisherWeightTuple];
	tuples.sort((a, b) => {
		return Math.abs(a.weight - weight) - Math.abs(b.weight - weight);
	});
	return tuples[0].days;
}

export function CalcWeightPiglets(days: number, pigType: AnimalType) {
	let tuples = pigType === AnimalType.Weaner ? [...WeanerWeightTuple] : [...FinisherWeightTuple];
	tuples.sort((a, b) => {
		return Math.abs(a.days - days) - Math.abs(b.days - days);
	});
	return tuples[0].weight;
}

export function CalcDaysClimatePigs(weight: number) {
	let x6 = -1.2385592681873386 * Math.pow(10, -11) * Math.pow(weight, 6);
	let x5 = 1.1813221342610739 * Math.pow(10, -8) * Math.pow(weight, 5);
	let x4 = -3.9942292495317177 * Math.pow(10, -6) * Math.pow(weight, 4);
	let x3 = 6.6088904412144284 * Math.pow(10, -4) * Math.pow(weight, 3);
	let x2 = -5.7129215323704748 * Math.pow(10, -2) * Math.pow(weight, 2);
	let x1 = 3.427654698659139 * weight;
	let startVal = -15.854376838484351;

	let days = x6 + x5 + x4 + x3 + x2 + x1 + startVal;

	return days;
}

export function CalcDaysSlaughterPigs(weight: number) {
	let x6 = -2.6941040073154208 * Math.pow(10, -12) * Math.pow(weight, 6);
	let x5 = 5.7563211327408742 * Math.pow(10, -9) * Math.pow(weight, 5);
	let x4 = -2.547824465538661 * Math.pow(10, -6) * Math.pow(weight, 4);
	let x3 = 4.9340613542558801 * Math.pow(10, -4) * Math.pow(weight, 3);
	let x2 = -4.7280297763366529 * Math.pow(10, -2) * Math.pow(weight, 2);
	let x1 = 3.0755566723649395 * weight;
	let startVal = -13.808763286862421;

	let days = x6 + x5 + x4 + x3 + x2 + x1 + startVal;

	return days;
}

// Growth percent at entrance
const day0 = -20 / 100;
const day1 = -5 / 100;
const day2 = 20 / 100;
const day3 = 35 / 100;
const day4 = 48 / 100;
const day5 = 57 / 100;
const day6 = 65 / 100;
const day7 = 72 / 100;
const day8 = 80 / 100;
const day9 = 86 / 100;
const day10 = 91 / 100;
const day11 = 95 / 100;
const day12 = 98 / 100;
const day13 = 100 / 100;

// If entrance weight is less then 7.1 - use all of the growth percent
const minWeight = 7.1;

// IF entrance weight is between max and min, start Growth percent at day6
const maxWeight = 8.5;

// These values are straight from Skiold
const dailyGrowthWeaner = 461;
const growthRateWeaner = -0.0015 + dailyGrowthWeaner / 33600;

const dailyGrowthFinisher = 1026;
const growthRateFinisher = dailyGrowthFinisher / 78400;
const const1 = 5.44;

export const calculateWeight = (
	eventData: Date,
	currentTotalWeight: number,
	amount: number,
	animalType: AnimalType,
	toDate: Date,
	isSameProduction: boolean | undefined,
) => {
	const insertDate = moment(eventData);
	const currentDate = moment(toDate);
	const diffDays = currentDate.diff(insertDate, 'days');

	let prevWeight = currentTotalWeight / amount;
	let startWeight = currentTotalWeight / amount;

	let growthRate = animalType === AnimalType.Weaner ? growthRateWeaner : growthRateFinisher;
	for (let i = 0; i < diffDays; i++) {
		if (!isSameProduction && minWeight >= startWeight) {
			prevWeight = Math.exp(
				const1 - (const1 - Math.log(prevWeight)) * Math.exp(-growthRate * getGrowthPercent(i)),
			);
		} else if (!isSameProduction && minWeight <= startWeight && maxWeight >= startWeight) {
			prevWeight = Math.exp(
				const1 - (const1 - Math.log(prevWeight)) * Math.exp(-growthRate * getGrowthPercent(i + 5)),
			);
		} else {
			prevWeight = Math.exp(const1 - (const1 - Math.log(prevWeight)) * Math.exp(-growthRate));
		}
	}

	return prevWeight * amount;
};

export const getGrowthPercent = days => {
	switch (days) {
		case 0:
			return day0;
		case 1:
			return day1;
		case 2:
			return day2;
		case 3:
			return day3;
		case 4:
			return day4;
		case 5:
			return day5;
		case 6:
			return day6;
		case 7:
			return day7;
		case 8:
			return day8;
		case 9:
			return day9;
		case 10:
			return day10;
		case 11:
			return day11;
		case 12:
			return day12;
		case 13:
			return day13;
		default:
			return 1;
	}
};
