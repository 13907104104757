import { Action } from 'redux';
import * as actions from './actions';
import { SiteState } from './types';
import { isType } from 'typescript-fsa';
import { SharedAppState } from 'shared/state/store.shared';
import { ISite, Site } from 'shared/api/api';
import { AssignIdAccess, LicenceIds } from 'shared/constants';
import { WebAppState } from 'web/state/store.web';
import { NativeAppState } from 'native/state/store.native';

export const initialSiteState: SiteState = {
	site: {} as ISite,
	isLoading: false,
};

export default function reducer(state: SiteState = initialSiteState, action: Action): SiteState {
	if (isType(action, actions.getSiteAsync.started)) {
		return { ...state, isLoading: true };
	}

	if (isType(action, actions.getSiteAsync.done)) {
		return { ...state, site: action.payload.result, isLoading: false };
	}

	if (isType(action, actions.saveSiteAsync.done)) {
		if (!action.payload.params.isDeleted) {
			state = { ...state, site: action.payload.params };
		}
		return state;
	}

	//Ensure Date objects are in fact date and not strings - redux-persist serializes dates to string but doesn't deserialize to dates again
	if (action.type === 'persist/REHYDRATE') {
		let a = (action as any) as { payload: SharedAppState; key: string };

		if (a.key === 'root') {
			if (a.payload && a.payload.site && a.payload.site.site) {
				let ndt = Site.fromJS({});
				ndt.init(a.payload.site.site);
				state = { ...state, site: ndt };
			}
		}
	}

	return state;
}

export const selectActiveSite = (state: SharedAppState | NativeAppState | WebAppState) => state.site.site;
export const hasDanishGenetics = (state: SharedAppState | NativeAppState | WebAppState) =>
	!!state.site.site.activeLicences?.find(l => l === LicenceIds.NucleusManagementDanishGenetics);

export const selectNucleusManagement = (state: SharedAppState | NativeAppState | WebAppState) => {
	if (state.site.site.activeLicences) {
		const nucleusLicecnce = state.site.site.activeLicences.find(
			l =>
				l === LicenceIds.NucleusManagementDanBred ||
				l === LicenceIds.NucleusManagementDanishGenetics ||
				l === LicenceIds.NucleusManagementSelfManaged,
		);
		return nucleusLicecnce
	}
	return undefined;
};

export const selectNucleusManagementOrAssignIdAccess = (state: SharedAppState | NativeAppState | WebAppState) => {
	if (state.site.site.activeLicences) {
		const nucleusLicecnce = state.site.site.activeLicences.find(
			l =>
				l === LicenceIds.NucleusManagementDanBred ||
				l === LicenceIds.NucleusManagementDanishGenetics ||
				l === LicenceIds.NucleusManagementSelfManaged,
		);
		return nucleusLicecnce ? nucleusLicecnce : state.generalSettings.entity.assignNumbersToBornPiglets ? AssignIdAccess : undefined
	}
	return undefined;
};


export const hasNucleusManagement = (state: SharedAppState | NativeAppState | WebAppState) => {
	if (state.site.site.activeLicences) {
		return !!state.site.site.activeLicences.find(
			l =>
				l === LicenceIds.NucleusManagementDanBred ||
				l === LicenceIds.NucleusManagementDanishGenetics ||
				l === LicenceIds.NucleusManagementSelfManaged,
		);
	}
	return false;
};
