import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import { StoreContainer } from 'shared/state/store-container';
import { WebAppState } from 'web/state/store.web';
import * as Action from './actions';

export function GetDistriwinFeedingComponentSyncData(siteId: string) {
	const state = StoreContainer.getState() as WebAppState;
	const lastSyncDate = state.distriwinFeedComponents.lastSyncDate;

	return AsyncOperationBuilder2(Action.getSyncData, client => client.distriwinFeedComponent_GetBySiteId(siteId), {
		siteId,
	});
}

export function SetFeedingToComponentRelation(relations: { [key: string]: string }) {
	return AsyncOperationBuilder2(
		Action.setFeedingToComponentRelations,
		client => client.distriwinFeedComponent_SetFeedingToComponentRelation(relations),
		relations,
	);
}

export function FetchingComponents(siteId: string, code: string) {
	return AsyncOperationBuilder2(
		Action.fetchComponents,
		client => client.distriwinFeedComponent_FetchComponents(siteId, code),
		{
			siteId,
			code,
		},
	);
}
