import React, { FC } from 'react';
import { ViewWeb } from '../../utils/web-view';
interface PropsFromParent {
	children?: React.ReactNode;
}
export const SkioldFetchContainer: FC<PropsFromParent> = React.memo(({ children }) => {
	return (
		<ViewWeb className="view-style">
			<ViewWeb className="date-picker-container">{children}</ViewWeb>
		</ViewWeb>
	);
});
