import { Dispatch } from 'redux';
import { AnimalType, SkioldOneClient, ValveFieldUpdateDto, ValveFieldUpdateStatus } from 'shared/api/api';
import { SkioldDigitalApiBaseUrl } from 'shared/constants';
import { NaturalSortDates } from 'shared/helpers/natural-sort';
import { StoreContainer } from 'shared/state/store-container';
import * as Action from './actions';
import {
	acknowledgeSucceededFields,
	findDataToBeDeleted,
	findLastestEventDate,
	getLastestUpdate,
	HandleTableData,
	pollValveUpdate,
	removeSucceededFields,
} from './retry-adjust-feed-operation-helper';
import { RetryAdjustFeedItemApp } from './types';
import { getSiteId } from 'shared/helpers/reducer-helpers';
import { ExtractLocationCode } from 'shared/helpers/process-equipment-helper/process-equipment-helper';

export function GetRetryAdjustFeedTableData(pigType: AnimalType[]) {
	let retryAdjustFeed: RetryAdjustFeedItemApp[] = [];
	let retryAdjustFeedItemsToBeDeletedItems: string[] = [];
	let listOfUniqueLocations: Array<string | undefined> = [];
	const state = StoreContainer.getState();

	return async (dispatch: Dispatch<any>) => {
		if (state.profile.active!.siteId) {
			let allData = await new SkioldOneClient(SkioldDigitalApiBaseUrl).valveUpdates_GetValveFieldUpdates(
				state.profile.active!.siteId ? state.profile.active!.siteId : '',
			);

			allData.forEach((v: ValveFieldUpdateDto) => {
				if (pigType.includes(v.animalType!)) {
					if (!listOfUniqueLocations.includes(v.location)) {
						listOfUniqueLocations.push(v.location);
					}
				}
			});

			listOfUniqueLocations.forEach((loc: string | undefined) => {
				let filteredAllData = allData
					.filter(a => a.location === loc)
					.sort((a, b) => NaturalSortDates(a.firstAttemptedOn, b.firstAttemptedOn));
				let FixedModulations = filteredAllData.filter(a => a.fieldName === 'FixedModulation');
				let PenDays = filteredAllData.filter(a => a.fieldName === 'PenDays');
				let PigCount = filteredAllData.filter(a => a.fieldName === 'PigCount');
				let PigletCount = filteredAllData.filter(a => a.fieldName === 'PigletCount');
				let SowCycle = filteredAllData.filter(a => a.fieldName === 'SowCycle');
				let Weight = filteredAllData.filter(a => a.fieldName === 'Weight');
				let VariableModulation = filteredAllData.filter(a => a.fieldName === 'VariableModulation');
				let VariableModulationDays = filteredAllData.filter(a => a.fieldName === 'VariableModulationDays');
				let feedingCurve = filteredAllData.filter(a => a.fieldName === 'FeedingCurve');

				if (FixedModulations !== undefined && FixedModulations.length >= 1) {
					HandleTableData(
						FixedModulations,
						'fixedValue',
						retryAdjustFeedItemsToBeDeletedItems,
						retryAdjustFeed,
					);
				}
				if (PenDays !== undefined && PenDays.length >= 1) {
					HandleTableData(PenDays, 'penDays', retryAdjustFeedItemsToBeDeletedItems, retryAdjustFeed);
				}

				// Pig and piglet count use the same table column
				if (PigCount !== undefined && PigCount.length >= 1) {
					HandleTableData(PigCount, 'pigCount', retryAdjustFeedItemsToBeDeletedItems, retryAdjustFeed);
				}
				if (PigletCount !== undefined && PigletCount.length >= 1) {
					HandleTableData(PigletCount, 'pigCount', retryAdjustFeedItemsToBeDeletedItems, retryAdjustFeed);
				}
				if (Weight !== undefined && Weight.length >= 1) {
					HandleTableData(Weight, 'weight', retryAdjustFeedItemsToBeDeletedItems, retryAdjustFeed);
				}
				if (SowCycle !== undefined && SowCycle.length >= 1) {
					HandleTableData(SowCycle, 'sowCycle', retryAdjustFeedItemsToBeDeletedItems, retryAdjustFeed);
				}
				if (VariableModulation !== undefined && VariableModulation.length >= 1) {
					HandleTableData(
						VariableModulation,
						'variableModulation',
						retryAdjustFeedItemsToBeDeletedItems,
						retryAdjustFeed,
					);
				}
				if (VariableModulationDays !== undefined && VariableModulationDays.length >= 1) {
					HandleTableData(
						VariableModulationDays,
						'variableModulationDays',
						retryAdjustFeedItemsToBeDeletedItems,
						retryAdjustFeed,
					);
				}
				if (feedingCurve !== undefined && feedingCurve.length >= 1) {
					const firstAttemptedOnDate = findLastestEventDate(feedingCurve);
					let dataToBeDeleted = findDataToBeDeleted(feedingCurve, firstAttemptedOnDate);
					dataToBeDeleted.forEach(toBeDeleted => {
						retryAdjustFeedItemsToBeDeletedItems.push(toBeDeleted.id!);
					});
					let { index, d } = getLastestUpdate(feedingCurve, firstAttemptedOnDate, retryAdjustFeed);
					if (index !== -1) {
						const processEquipment = state.processEquipments.entities.find(
							peq => peq.code === ExtractLocationCode(loc),
						);

						const feedingCurve =
							processEquipment &&
							processEquipment.feedCurves &&
							processEquipment.feedCurves.find(
								a =>
									// tslint:disable-next-line: radix
									a.curveId === parseInt(d!.value!) &&
									a.type ===
										(pigType.find(animalType => animalType === AnimalType.Sow) !== undefined
											? 'sow'
											: 'pig'),
							);

						retryAdjustFeed[index].feedingCurve = feedingCurve ? feedingCurve.name : '';

						if (retryAdjustFeed[index].date! < d!.firstAttemptedOn!) {
							retryAdjustFeed[index].date = d!.firstAttemptedOn!;
						}
						if (!retryAdjustFeed[index].ids.includes(d!.id!)) {
							retryAdjustFeed[index].ids.push(d!.id!);
						}
					} else {
						const processEquipment = state.processEquipments.entities.find(
							peq => peq.code === ExtractLocationCode(loc),
						);

						let feedCurve =
							processEquipment &&
							processEquipment.feedCurves &&
							processEquipment.feedCurves.find(
								a =>
									// tslint:disable-next-line: radix
									a.curveId === parseInt(d!.value!) &&
									pigType
										.map(type => type.toLocaleLowerCase())
										.includes(a!.type!.toLocaleLowerCase()),
							);

						let data = {
							location: d!.location!,
							building: d!.building,
							section: d!.section,
							pens: d!.pens,
							buildingOrder: d!.buildingOrder,
							sectionOrder: d!.sectionOrder,
							date: d!.firstAttemptedOn,
							penOrder: d!.penOrder,
							valve: d!.valve,
							status: false,
							shouldBeCommited: false,
							showSpinner: false,
							feedingCurve: feedCurve !== undefined ? feedCurve.name : '',
							ids: [d!.id!],
						} as RetryAdjustFeedItemApp;

						retryAdjustFeed.push(data);
					}
				}
			});

			dispatch(
				Action.getTableData.done({
					params: {
						siteId: getSiteId(state.profile.active),
						activeSiteId: getSiteId(StoreContainer.getState().profile.active),
					},
					result: retryAdjustFeed,
				}),
			);

			if (retryAdjustFeedItemsToBeDeletedItems && retryAdjustFeedItemsToBeDeletedItems.length >= 1) {
				await new SkioldOneClient(SkioldDigitalApiBaseUrl).valveUpdates_AcknowledgeValveFieldUpdates(
					retryAdjustFeedItemsToBeDeletedItems,
				);
			}
		}
	};
}

export function RetryAdjustFeedUpdates(retryAdjustFeedItem: RetryAdjustFeedItemApp) {
	return async (dispatch: Dispatch<any>) => {
		dispatch(Action.retryAdjustFeed.started(retryAdjustFeedItem));

		return new SkioldOneClient(SkioldDigitalApiBaseUrl)
			.valveUpdates_RetryValveFieldUpdates(retryAdjustFeedItem.ids)
			.then(async valveFieldUpdate => {
				let failedIds: string[] = [];
				await valveFieldUpdate.forEach(async u => {
					let res = await pollValveUpdate(u.id!, 0);
					if (res.status === ValveFieldUpdateStatus.Failed || res.status === ValveFieldUpdateStatus.Created) {
						failedIds.push(res.id!);
					}
					if (res.status === ValveFieldUpdateStatus.Succeeded) {
						retryAdjustFeedItem = removeSucceededFields([res], retryAdjustFeedItem);
						await acknowledgeSucceededFields([res]);
					}

					dispatch(
						Action.retryAdjustFeed.done({
							params: retryAdjustFeedItem,
							result: { valveFieldUpdateDto: retryAdjustFeedItem, failedIds },
						}),
					);
				});
				return valveFieldUpdate;
			})
			.catch(error => {
				dispatch(Action.retryAdjustFeed.failed({ params: retryAdjustFeedItem, error }));
			});
	};
}

export function DeleteRetryAdjustFeedUpdates(retryAdjustFeedItem: RetryAdjustFeedItemApp) {
	return async (dispatch: Dispatch<any>) => {
		dispatch(Action.retryAdjustFeed.started(retryAdjustFeedItem));
		new SkioldOneClient(SkioldDigitalApiBaseUrl)
			.valveUpdates_AcknowledgeValveFieldUpdates(retryAdjustFeedItem.ids)
			.then(async valveFieldUpdate => {
				retryAdjustFeedItem.ids = [];
				dispatch(
					Action.retryAdjustFeed.done({
						params: retryAdjustFeedItem,
						result: { valveFieldUpdateDto: retryAdjustFeedItem, failedIds: [] },
					}),
				);
				return valveFieldUpdate;
			});
	};
}

export function UpdateCommitStatusOnAll(commitAll: boolean) {
	return (dispatch: Dispatch<any>) => {
		dispatch(Action.updateCommitStatusOnAll.done({ params: undefined, result: commitAll }));
	};
}

export function SetRetryAdjustFeedCommitStatus(retryAdjustFeedItem: RetryAdjustFeedItemApp) {
	return (dispatch: Dispatch<any>) => {
		dispatch(Action.setRetryCommitStatus.done({ params: undefined, result: retryAdjustFeedItem }));
	};
}
