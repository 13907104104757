import React from 'react';
import { Option } from 'react-dropdown';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import * as validationOperation from 'shared/state/ducks/validation-setup/operations';
import * as genrealSettingsOperation from 'shared/state/ducks/general-settings/operations';
import { localized, localizedDynamic } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import PageContainer from 'web/view/components/page-container/page-container';
import RegisterFarrowing from 'web/view/components/pregnancies/pregnancy-events/register-farrowing/register-farrowing';
import RegisterMating from 'web/view/components/pregnancies/pregnancy-events/register-mating';
import RegisterScanning from 'web/view/components/pregnancies/pregnancy-events/register-scanning';
import RegisterWeaning from 'web/view/components/pregnancies/pregnancy-events/register-weaning';
import { SkioldDropdown } from 'web/view/components/skiold-components/skiold-dropdown/skiold-dropdown';
import RegisterMoveEvent from 'web/view/components/stem-animal/sow-events/move-event/register-move-event';
import RegisterDeadPigletsEvent from 'web/view/components/stem-animal/sow-events/register-dead-piglets-event/register-dead-piglets-event';
import RegisterDeparture from 'web/view/components/stem-animal/sow-events/register-sow-departure/register-sow-departure';
import RegisterSowLardScanningEvent from 'web/view/components/stem-animal/sow-events/register-sow-lard-scanning-event/register-sow-lard-scanning-event';
import RegisterPoolYoungFemale from 'web/view/components/stem-animal/pool-young-female/register-pool-young-female';
import SowEntrance from 'web/view/components/stem-animal/sow-events/sow-entrance/sow-entrance';
import { Heading } from 'web/view/components/utils/heading';
import { ViewWeb } from 'web/view/components/utils/web-view';
import './sow-events.scss';
import { getSyncBreedTableItems } from 'shared/state/ducks/breed-table-items/operation';
import { getBreedingSettings } from 'shared/state/ducks/nucleus-management-breeding-settings/operation';
import { GetSyncData as ProfileGetSyncData } from 'shared/state/ducks/profile/operations';
interface PropsFromParent {
	closeEditModal?: () => void;
	shouldCloseOnSave?: boolean;
	stemAnimalId?: string;
	theme?: 'light' | 'dark';
	setEventOption?: Option;
	pregnancyEventId?: string;
}

const mapStateToProps = (state: WebAppState, props: any) => {
	return {
		navigation: state.navigation,
		pregnancyEventIdNavigation: state.navigation.query && state.navigation.query.pregnancy,
		stemAnimalIdNavigation: state.navigation.query && state.navigation.query.stemAnimalId,
		setEventOptionNavigation: state.navigation.query && state.navigation.query.selected,
		siteId: state.profile.active!.siteId,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	validationGetSyncData: () => validationOperation.GetSyncData()(dispatch),
	generalSettingsGetSyncData: () => genrealSettingsOperation.GetSyncData()(dispatch),
	getSyncBreedTableItems: () => dispatch(getSyncBreedTableItems() as any),
	getBreedingSettings: (siteId: string) => dispatch(getBreedingSettings(siteId) as any),
	getProfiles: () => ProfileGetSyncData()(dispatch),
});

export interface State {
	events: Option[];
	selected: Option | undefined;
	pregnancyEventIdState: string | undefined;
	stemAnimalId: string | undefined;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;
class SowEvents extends React.PureComponent<Props, State> {
	private DefaultEventOption: Option = { label: localized('entry'), value: 'entry' };
	constructor(props: Props) {
		super(props);

		this.state = {
			selected: this.props.setEventOption
				? this.props.setEventOption
				: this.props.setEventOptionNavigation
				? {
						label: localizedDynamic(this.props.setEventOptionNavigation),
						value: this.props.setEventOptionNavigation,
				  }
				: this.DefaultEventOption,
			events: [
				{ label: localized('entry'), value: 'entry' },
				{ label: localized('departure'), value: 'departure' },
				{ label: localized('mating'), value: 'mating' },
				{ label: localized('scanning'), value: 'scanning' },
				{ label: localized('farrowing'), value: 'farrowing' },
				{ label: localized('Weaning'), value: 'weaning' },
				{ label: localized('move'), value: 'move' },
				{ label: localized('lardScanning'), value: 'scanningLardEvent' },
				{ label: localized('deadPigletsEvent'), value: 'deadPigletsEvent' },
				{ label: localized('salePoolYoungFemale'), value: 'salePoolYoungFemale' },
			],
			pregnancyEventIdState: this.props.pregnancyEventIdNavigation || this.props.pregnancyEventId,
			stemAnimalId: this.props.stemAnimalIdNavigation || this.props.stemAnimalId,
		};
		this.props.validationGetSyncData();
		this.props.getProfiles();
		this.props.generalSettingsGetSyncData();
		this.props.getSyncBreedTableItems();
		this.props.siteId && this.props.getBreedingSettings(this.props.siteId);
	}

	public render() {
		return (
			<PageContainer>
				<Heading text={localized('SOWEVENTS')} />
				<ViewWeb className="sow-events">
					<ViewWeb className="sow-event-content-container">
						<ViewWeb className="sow-event-dropdown-container">
							<SkioldDropdown
								onValueChanged={(selected: Option) => {
									this.setState({ selected });
								}}
								disabled={!!this.props.closeEditModal}
								selectedValue={this.state.selected}
								items={this.state.events}
								containerClassName="dropdown-component-style"
							/>
						</ViewWeb>
						{this.state.selected && this.state.selected.value === 'entry' && (
							<ViewWeb>
								{this.props.closeEditModal ? (
									<SowEntrance
										shouldCloseOnSave={this.props.shouldCloseOnSave}
										stemAnimalId={this.state.stemAnimalId}
										closeEditModal={this.closeModal}
									/>
								) : (
									<SowEntrance />
								)}
							</ViewWeb>
						)}
						{this.state.selected && this.state.selected.value === 'departure' && (
							<ViewWeb>
								{this.props.closeEditModal ? (
									<RegisterDeparture
										closeEditModal={this.closeModal}
										stemAnimalId={this.state.stemAnimalId}
									/>
								) : (
									<RegisterDeparture />
								)}
							</ViewWeb>
						)}
						{this.state.selected && this.state.selected.value === 'mating' && (
							<ViewWeb>
								{this.props.closeEditModal ? (
									<RegisterMating
										closeEditModal={this.closeModal}
										pregnancyEventId={this.state.pregnancyEventIdState}
										stemAnimalId={this.state.stemAnimalId}
									/>
								) : this.props.pregnancyEventIdNavigation ? (
									<RegisterMating
										pregnancyEventId={this.state.pregnancyEventIdState}
										stemAnimalId={this.state.stemAnimalId}
									/>
								) : (
									<RegisterMating />
								)}
							</ViewWeb>
						)}
						{this.state.selected && this.state.selected.value === 'scanning' && (
							<ViewWeb>
								{this.props.closeEditModal ? (
									<RegisterScanning
										closeEditModal={this.closeModal}
										pregnancyEventId={this.state.pregnancyEventIdState}
										stemAnimalId={this.state.stemAnimalId}
									/>
								) : this.props.pregnancyEventIdNavigation ? (
									<RegisterScanning
										pregnancyEventId={this.state.pregnancyEventIdState}
										stemAnimalId={this.state.stemAnimalId}
									/>
								) : (
									<RegisterScanning />
								)}
							</ViewWeb>
						)}
						{this.state.selected && this.state.selected.value === 'farrowing' && (
							<ViewWeb>
								{this.props.closeEditModal ? (
									<RegisterFarrowing
										closeEditModal={this.closeModal}
										pregnancyEventId={this.state.pregnancyEventIdState}
										stemAnimalId={this.state.stemAnimalId}
									/>
								) : this.props.pregnancyEventIdNavigation ? (
									<RegisterFarrowing
										pregnancyEventId={this.state.pregnancyEventIdState}
										stemAnimalId={this.state.stemAnimalId}
									/>
								) : (
									<RegisterFarrowing />
								)}
							</ViewWeb>
						)}
						{this.state.selected && this.state.selected.value === 'weaning' && (
							<ViewWeb>
								{this.props.closeEditModal ? (
									<RegisterWeaning
										closeEditModal={this.closeModal}
										pregnancyEventId={this.state.pregnancyEventIdState}
										stemAnimalId={this.state.stemAnimalId}
									/>
								) : this.props.pregnancyEventIdNavigation ? (
									<RegisterWeaning
										pregnancyEventId={this.state.pregnancyEventIdState}
										stemAnimalId={this.state.stemAnimalId}
									/>
								) : (
									<RegisterWeaning />
								)}
							</ViewWeb>
						)}
						{this.state.selected && this.state.selected.value === 'scanningLardEvent' && (
							<RegisterSowLardScanningEvent />
						)}
						{this.state.selected && this.state.selected.value === 'deadPigletsEvent' && (
							<RegisterDeadPigletsEvent />
						)}
						{this.state.selected &&
							this.state.selected.value === 'salePoolYoungFemale' &&
							(this.props.closeEditModal ? (
								<RegisterPoolYoungFemale
									closeEditModal={this.closeModal}
									poolYoungFemaleId={this.state.stemAnimalId}
								/>
							) : (
								<RegisterPoolYoungFemale />
							))}

						{this.state.selected && this.state.selected.value === 'move' && (
							<ViewWeb>
								{this.props.closeEditModal ? (
									<RegisterMoveEvent
										closeEditModal={this.closeModal}
										pregnancyEventId={this.state.pregnancyEventIdState}
									/>
								) : (
									<RegisterMoveEvent />
								)}
							</ViewWeb>
						)}
					</ViewWeb>
				</ViewWeb>
			</PageContainer>
		);
	}
	private closeModal = () => {
		this.props.closeEditModal!();
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SowEvents);
