import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { AnimalType, DashboardElaborationTableItem } from 'shared/api/api';
import { getDateString } from 'shared/helpers/date-helpers';
import { GetDashboardGrowthPigsOverviewTable } from 'shared/state/ducks/dashboard-growth-pigs-events/operations';
import { getGrowthPigsEventsByDatesDashboard } from 'shared/state/ducks/growth-pig-events/operations';
import { getGrowthPigTuneProductionBySiteId } from 'shared/state/ducks/growth-pig-tune-production/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { DashboardOverviewGrowthPigsTable } from 'web/view/components/dashboard-growth-pigs/dashboard-growth-pigs-table';
import { DeadPutDownLast7Days } from 'web/view/components/dashboard-growth-pigs/dead-put-down-last-7-days';
import { DashboardGpeElaborationOverview } from 'web/view/components/dashboard-growth-pigs/elaboration/dashboard-gpe-elaboration-overview';
import { TreatedLast7Days } from 'web/view/components/dashboard-growth-pigs/treated-last-7-days';
import { SummaryRowItem } from 'web/view/components/skiold-components/skiold-table/skiold-table-grouped-grid/skiold-table-grouped-grid-helper';
import { Heading } from 'web/view/components/utils/heading';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { GetDashBoardSettings } from '../../../../shared/state/ducks/dashboard-settings/operations';
import { resetGraph } from '../../../../shared/state/ducks/feed-usage/reducer';
import { FeedUsageGraph } from '../../components/dashboard-growth-pigs/elaboration/feed-usage-graph/feed-usage-graph';
import './dashboard-overview-growth-pigs.scss';
import { DashboardPigCountAndWeight } from './dashboard-pig-count-and-weight';

interface DashboardContext {
	closeTreatedModal: () => void;
	openTreatedModal: () => void;
	openDeadModal: () => void;
	closeDeadModal: () => void;
	openEntranceDepartureModal: () => void;
	closeEntranceDepartureModal: () => void;
	sectionId: string;
	penId: string;
	setSectionId: (sectionId: string | undefined) => void;
	setPenId: (penId: string | undefined) => void;
	productionType: AnimalType;
	isDeadOpen: boolean;
	isTreatedOpen: boolean;
	isEntranceDepartureOpen: boolean;
	isGraphModalOpen: boolean;
	openGraphModal: () => void;
	closeGraphModal: () => void;
}

export const GrowthPigsDashboardContext = React.createContext({} as any);
export const useGpeDashboard = () => useContext<DashboardContext>(GrowthPigsDashboardContext);

const interval = 1000 * 60 * 5; // 5min;

export const DashboardOverviewGrowthPigs: FC = React.memo(() => {
	const dispatch = useDispatch();

	// Get setup data
	const navigationQuery = useSelector((state: WebAppState) => state.navigation);
	const loading = useSelector((state: WebAppState) => state.dashboardGrowthPigsOverview.loading);
	const { growthPigTuneProductions } = useSelector((state: WebAppState) => state.growthPigTuneProduction);
	const siteId = useSelector((state: WebAppState) => state.profile.active && state.profile.active.siteId);
	const intervalRef = useRef<any>(null);
	// Set states
	const [lastBalanced, setLastBalanced] = useState<Date>();
	const [sectionId, setSectionId] = useState<string>('');
	const [penId, setPenId] = useState<string>('');
	const [isDeadOpen, setIsDeadOpen] = useState<boolean>(false);
	const [isTreatedOpen, setIsTreatedOpen] = useState<boolean>(false);
	const [isEntranceDepartureOpen, setIsEntranceDepartureOpen] = useState<boolean>(false);
	const [isGraphModalOpen, setIsGraphModalOpen] = useState(false);
	const [productionType, setProductionType] = useState(
		navigationQuery.query ? (navigationQuery.query['type'] as AnimalType) : AnimalType.FRATS,
	);

	useEffect(() => {
		setProductionType(navigationQuery.query ? (navigationQuery.query['type'] as AnimalType) : AnimalType.FRATS);
	}, [navigationQuery]);

	// Refresh dashboard every 5 mintues
	useEffect(() => {
		if (siteId) {
			dispatch(GetDashboardGrowthPigsOverviewTable(productionType, siteId));
			getGrowthPigTuneProductionBySiteId()(dispatch);
		}
		const refreshDashboard = () => {
			if (intervalRef && intervalRef.current) {
				clearInterval(intervalRef.current);
			}
			intervalRef.current = setInterval(() => {
				if (siteId) {
					dispatch(GetDashboardGrowthPigsOverviewTable(productionType, siteId, false));
					getGrowthPigTuneProductionBySiteId()(dispatch);
				}
			}, interval);
		};
		refreshDashboard();
		return () => {
			if (intervalRef && intervalRef.current) {
				clearInterval(intervalRef.current);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, siteId, productionType]);

	// Set Header data
	useEffect(() => {
		if (siteId) {
			dispatch(GetDashBoardSettings(siteId));
		}
		if (growthPigTuneProductions && growthPigTuneProductions.length > 0) {
			let filtered = growthPigTuneProductions.filter(e => e.animalType === productionType);
			let lastestDate =
				filtered && filtered.length > 0
					? filtered.reduce((a, b) => {
							return new Date(a.date!) > new Date(b.date!) ? a : b;
					  })
					: undefined;
			setLastBalanced(lastestDate && lastestDate.date);
		}
		// eslint-disable-next-line
	}, [productionType, growthPigTuneProductions]);

	useEffect(() => {
		setProductionType(navigationQuery.query ? (navigationQuery.query['type'] as AnimalType) : AnimalType.FRATS);
	}, [navigationQuery.query]);

	useEffect(() => {
		if (lastBalanced) {
			getGrowthPigsEventsByDatesDashboard(lastBalanced, new Date())(dispatch);
		}
	}, [lastBalanced, dispatch]);

	const closeDeadModal = () => {
		setIsDeadOpen(false);
	};

	const openDeadModal = () => {
		setIsDeadOpen(true);
	};

	const closeTreatedModal = () => {
		setIsTreatedOpen(false);
	};

	const openTreatedModal = () => {
		setIsTreatedOpen(true);
	};

	const closeEntranceDepartureModal = () => {
		setIsEntranceDepartureOpen(false);
	};

	const openEntranceDepartureModal = () => {
		setIsEntranceDepartureOpen(true);
	};

	const openGraphModal = () => {
		setIsGraphModalOpen(true);
	};

	const closeGraphModal = useCallback(() => {
		setIsGraphModalOpen(false);
		dispatch(resetGraph());
	}, [dispatch]);

	const setTableItems = (summaryRowItems: SummaryRowItem[]) => {
		let items: DashboardElaborationTableItem[] = [];

		for (let item of summaryRowItems) {
			let summaryRow = { gpeEventType: item.key } as DashboardElaborationTableItem;
			summaryRow.isSummaryRow = true;
			summaryRow.isExpanded = item.isExpanded;
			for (let sumVal of item.summaryValues) {
				summaryRow[sumVal.columnKey] = sumVal.columnValue;
			}
			items.push(summaryRow);
			items = items.concat(item.groupedValues);
		}
		return items;
	};

	return (
		<GrowthPigsDashboardContext.Provider
			value={{
				closeTreatedModal,
				openTreatedModal,
				openDeadModal,
				closeDeadModal,
				sectionId,
				penId,
				setSectionId,
				setPenId,
				productionType,
				isDeadOpen,
				isTreatedOpen,
				closeEntranceDepartureModal,
				openEntranceDepartureModal,
				isEntranceDepartureOpen,
				openGraphModal,
				closeGraphModal,
				isGraphModalOpen,
			}}
		>
			<ViewWeb className="dashboard-overview-growth-pigs">
				<Heading text={`${localized('dashboard')} ${localized(productionType)}`}></Heading>
				<ViewWeb className="dashboard-header-container">
					<DashboardPigCountAndWeight />
					<TextWeb className="header-label">
						{localized('lastBalanced')}: {getDateString(lastBalanced)}
					</TextWeb>
				</ViewWeb>
				<ViewWeb className="borderRadius15 dashboard-container">
					{loading ? (
						<ViewWeb className="spinner-container-active-dashboard">
							<ClipLoader color="#f2ac40" size={70} />
						</ViewWeb>
					) : (
						<DashboardOverviewGrowthPigsTable productionType={productionType} />
					)}
				</ViewWeb>
				<DeadPutDownLast7Days />
				<TreatedLast7Days />
				<DashboardGpeElaborationOverview />
				<FeedUsageGraph />
			</ViewWeb>
		</GrowthPigsDashboardContext.Provider>
	);
});
