import React, { FC, useEffect, useState } from 'react';
import { Option } from 'react-dropdown';
import { useSelector } from 'react-redux';
import { ISupplierDetailDto } from 'shared/api/api';
import { getBuildingIdBySectionId } from 'shared/helpers/location-helper';
import { memoizeHashmapLocation } from 'shared/helpers/memoize-getters/memoize-getters';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { SlaughterDataDropDown } from 'web/view/components/skiold-components/finisher-data-dropdown/finisher-data-dropdown';
import { SkioldDatePicker } from 'web/view/components/skiold-components/skiold-date-picker/skiold-date-picker';
import { SkioldFormInput } from 'web/view/components/skiold-components/skiold-input/skiold-form-input';
import { SkioldTableGrid } from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';

interface PropsFromParent {
	onDataChanged: (data: ISupplierDetailDto[]) => void;
}

export const DelieverySlaughterHouse: FC<PropsFromParent> = React.memo(props => {
	const memorizedLoca = useSelector((state: WebAppState) => memoizeHashmapLocation(state.locations.buildings,
		state.locations.sections,
		state.locations.pens));
	const currentSuppliers = useSelector((state: WebAppState) => state.SlaughterHouseSupplierDetails.supplierDetails);
	const slaughterhouseConnections = useSelector(
		(state: WebAppState) => state.slaughterhouseConnections.slaughterhouseConnections,
	);
	const slaughterhouseCompany = useSelector(
		(state: WebAppState) => state.slaughterhouseConnections.selectedConnection,
	);
	const [dataState, setDataState] = useState([
		...currentSuppliers.filter(sd => sd.slaughterHouseConnectionId === slaughterhouseCompany),
	]);

	useEffect(() => {
		const currentActiveSlaughterHouse = slaughterhouseConnections.find(
			shc => shc.slaughterhouse === slaughterhouseCompany,
		);
		setDataState([
			...currentSuppliers.filter(
				sd =>
					sd.slaughterhouseCompany === slaughterhouseCompany ||
					(currentActiveSlaughterHouse && currentActiveSlaughterHouse.id === sd.slaughterHouseConnectionId),
			),
		]);
	}, [currentSuppliers, slaughterhouseCompany, slaughterhouseConnections]);

	return (
		<ViewWeb className="width420">
			<ViewWeb className="DelieveryHeader">
				<TextWeb className="DelieveryHeaderText">{localized('SupplierNumbers')}</TextWeb>
			</ViewWeb>
			<ViewWeb className="tableContainer">
				<SkioldTableGrid
					className="delieverySlaughterHouseSettingsTable"
					columns={generateColumns(dataState, setDataState, memorizedLoca, props.onDataChanged)}
					ignoreSetCount={true}
					sortable={false}
					filterable={false}
					ColumnExtensions={generateColumnsExtensions()}
					data={dataState}
				/>
			</ViewWeb>
		</ViewWeb>
	);
});

function generateColumnsExtensions() {
	return [
		{
			columnName: 'number',
			width: 100,
		},
		{
			columnName: 'location',
			filteringEnabled: false,
			width: 200,
		},
		{
			columnName: 'fromDate',
			width: 120,
		},
	];
}

const generateColumns = (
	dataState: ISupplierDetailDto[],
	setDataState: React.Dispatch<React.SetStateAction<ISupplierDetailDto[]>>,
	locationsMemo: { [key: string]: any },
	onDataChanged: (data: ISupplierDetailDto[]) => void,
) => {
	const columns = [
		{
			name: 'number',
			sortable: false,
			filterable: false,
			title: <TextWeb className={'DelieveryHeaderText'}>{localized('number')}</TextWeb>,
			getCellValue: getDelieveryNumberCell(dataState, setDataState, onDataChanged),
		},
		{
			name: 'location',
			title: <TextWeb className={'DelieveryHeaderText'}>{localized('location')}</TextWeb>,
			shouldOverflow: true,
			getCellValue: getLocationCell(dataState, setDataState, locationsMemo, onDataChanged),
		},
		{
			name: 'fromDate',
			title: <TextWeb className={'DelieveryHeaderText'}> {localized('FromDate')}</TextWeb>,
			getCellValue: getFromDateCell(dataState, setDataState, onDataChanged),
		},
	];
	return columns;
};

function getFromDateCell(
	dataState: ISupplierDetailDto[],
	setDataState: React.Dispatch<React.SetStateAction<ISupplierDetailDto[]>>,
	onDataChanged: (data: ISupplierDetailDto[]) => void,
) {
	return (sd: ISupplierDetailDto) => (
		<SkioldDatePicker
			theme="dark"
			color={'black'}
			selectedDate={sd.fromDate!}
			itemFromParent={sd.id}
			onDateChanged={(date, id?: string) => {
				const copyOfState = [...dataState];
				const index = copyOfState.findIndex(a => a.id === id);
				if (index >= 0) {
					copyOfState[index].fromDate = date;
				}
				setDataState(copyOfState);
				onDataChanged(copyOfState);
			}}
		/>
	);
}

function getLocationCell(
	dataState: ISupplierDetailDto[],
	setDataState: React.Dispatch<React.SetStateAction<ISupplierDetailDto[]>>,
	locationsMemo: { [key: string]: any },
	onDataChanged: (data: ISupplierDetailDto[]) => void,
) {
	return (sd: ISupplierDetailDto) => (
		<SlaughterDataDropDown
			isDarkTheme={true}
			sectionId={sd.sectionId}
			itemFromParent={sd.id}
			onValueChanged={(option: Option, id?: string) => {
				const copyOfState = [...dataState];
				const index = copyOfState.findIndex(a => a.id === id);

				if (index >= 0) {
					copyOfState[index].buildingId =
						option.value === '' ? undefined : getBuildingIdBySectionId(option.value, locationsMemo);
					copyOfState[index].sectionId = option.value === '' ? undefined : option.value;
				}
				setDataState(copyOfState);
				onDataChanged(copyOfState);
			}}
		/>
	);
}

function getDelieveryNumberCell(
	dataState: ISupplierDetailDto[],
	setDataState: React.Dispatch<React.SetStateAction<ISupplierDetailDto[]>>,
	onDataChanged: (data: ISupplierDetailDto[]) => void,
) {
	return (sd: ISupplierDetailDto) => (
		<SkioldFormInput
			text={sd.supplierNumber}
			itemFromParent={sd.id}
			onChangeText={(supplierNumber, id?: string) => {
				const copyOfState = [...dataState];
				const index = copyOfState.findIndex(a => a.id === id);
				if (index >= 0) {
					copyOfState[index].supplierNumber = supplierNumber;
				}
				setDataState(copyOfState);
				onDataChanged(copyOfState);
			}}
		/>
	);
}
