import { Action } from 'redux';
import { SyncableInitialState } from 'shared/state/models/syncable';
import { PenRetentionTimeState } from './types';

export const initialState: PenRetentionTimeState = {
	...SyncableInitialState,
};

const penRetentionTimeReducer = (
	state: PenRetentionTimeState = initialState,
	action: Action,
): PenRetentionTimeState => {
	return state;
};

export default penRetentionTimeReducer;
