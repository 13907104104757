import { Action } from 'redux';
import { upsertValueInArray } from 'shared/helpers/reducer-helpers';
import { isType } from 'typescript-fsa';
import * as actions from './actions';
import { GeneralSettingsState } from '.';
import { saveDropdownScrollOption, resetDropdownScrollOptions } from './actions';
import { SyncableSingleInitialState } from 'shared/state/models/syncable';
import { siteChange } from '../profile/actions';
import { GeneralSettings, IGeneralSettings } from 'shared/api/api';
import { SharedAppState } from 'shared/state/store.shared';
import { getSyncModelData } from '../sync/actions';
import { getSlaughterData } from '../slaughter-data/actions';
import { GetEventTypesGrowthPigEventsByDates } from '../growth-pig-events/actions';

export const initialState: GeneralSettingsState = {
	...SyncableSingleInitialState,
	dropdownScrollOptions: [],
	globalDate: new Date(),
	showSpinner: {},
};

const generalSettingsReducer = (state: GeneralSettingsState = initialState, action: Action): GeneralSettingsState => {
	if (isType(action, saveDropdownScrollOption)) {
		state = {
			...state,
			dropdownScrollOptions: upsertValueInArray(state.dropdownScrollOptions, action.payload),
		};
	}
	if (isType(action, resetDropdownScrollOptions)) {
		state = { ...state, dropdownScrollOptions: [] };
	}

	if (isType(action, actions.getSyncData.started)) {
		state = { ...state, syncInProgress: true };
	}

	if (isType(action, actions.getSyncData.done)) {
		if (action.payload.result.datas) {
			state = {
				...state,
				entity: { ...state.entity, ...action.payload.result.datas },
			};
		}

		state = {
			...state,
			lastSyncDate: action.payload.result.syncTime!,
			syncInProgress: false,
		};
	}

	if (isType(action, getSyncModelData.done)) {
		if (action.payload.result.generalSettings && action.payload.result.generalSettings.datas) {
			state = {
				...state,
				entity: { ...state.entity, ...action.payload.result.generalSettings.datas },
				lastSyncDate: action.payload.result.generalSettings.syncTime!,
			};
		}

		state = {
			...state,

			syncInProgress: false,
		};
	}

	if (isType(action, actions.getSyncData.failed)) {
		state = { ...state, syncInProgress: false };
	}

	if (isType(action, actions.saveGeneralSettings.started)) {
		state = { ...state, update: action.payload };
	}

	if (isType(action, actions.saveGeneralSettings.failed)) {
		if (action.payload.error.code === 500) {
			if (action.payload.error.prevEntity) {
				state = { ...state, entity: action.payload.error.prevEntity };
			} else {
				state = { ...state, entity: {} as IGeneralSettings };
			}
			state = { ...state, update: {} as IGeneralSettings };
		}

		return state;
	}

	if (isType(action, actions.saveGeneralSettings.done)) {
		if (!action.payload.params.isDeleted) {
			state = { ...state, entity: action.payload.params };
		}

		state = { ...state, update: {} as IGeneralSettings };

		return state;
	}

	if (isType(action, siteChange.done)) {
		state = initialState;
		state.dropdownScrollOptions = [];
	}

	if (isType(action, actions.setGlobalDate)) {
		state = { ...state, globalDate: action.payload };
	}

	if (isType(action, getSlaughterData.started)) {
		state = setSpinnerStarted('getSlaughterData', state);
	}

	if (isType(action, getSlaughterData.done)) {
		state = setSpinnerDone('getSlaughterData', state);
	}

	if (isType(action, GetEventTypesGrowthPigEventsByDates.started)) {
		state = setSpinnerStarted('GetEventTypesGrowthPigEventsByDates', state);
	}

	if (isType(action, GetEventTypesGrowthPigEventsByDates.done)) {
		state = setSpinnerDone('GetEventTypesGrowthPigEventsByDates', state);
	}

	//Ensure Date objects are in fact date and not strings - redux-persist serializes dates to string but doesn't deserialize to dates again
	if (action.type === 'persist/REHYDRATE') {
		let a = (action as any) as { payload: SharedAppState; key: string };
		if (a.key === 'root') {
			let entity = GeneralSettings.fromJS({});
			let update = GeneralSettings.fromJS({});
			let lastSyncDate = new Date(-8640000000000000);

			if (a.payload && a.payload.generalSettings && a.payload.generalSettings.entity) {
				let ndt = GeneralSettings.fromJS({});
				ndt.init(a.payload.generalSettings.entity);
				entity = ndt;
			}

			if (a.payload && a.payload.generalSettings && a.payload.generalSettings.update) {
				let ndt = GeneralSettings.fromJS({});
				ndt.init(a.payload.generalSettings.update);
				update = ndt;
			}

			if (a.payload && a.payload.generalSettings && a.payload.generalSettings.lastSyncDate) {
				lastSyncDate = new Date(a.payload.generalSettings.lastSyncDate);
			}

			state = { ...state, entity, update, lastSyncDate };
		}
	}

	return state;
};

const setSpinnerStarted = (action: string, state: GeneralSettingsState) => {
	const spinner = { ...state.showSpinner };
	spinner[action] = true;
	return { ...state, showSpinner: spinner };
};

const setSpinnerDone = (action: string, state: GeneralSettingsState) => {
	const spinner = { ...state.showSpinner };
	spinner[action] = false;
	return { ...state, showSpinner: spinner };
};

export default generalSettingsReducer;
