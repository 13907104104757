import { default as ObjectID } from 'bson-objectid';
import { Dispatch } from 'redux';
import {
	IFeedingStatusUpdate,
	ImportFileData,
	IStemAnimal,
	SkioldOneClient,
	StemAnimal,
	SyncDataStemAnimal,
} from 'shared/api/api';
import { SkioldDigitalApiBaseUrl } from 'shared/constants';
import { isNullOrUndefined } from 'shared/helpers/general-helpers';
import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import { StoreContainer } from 'shared/state/store-container';
import { RemoveDailyStatus } from '../esf-daily-status/operations';
import { RemoveEsfStatus } from '../esf-status/operations';
import { RemoveLardEvent } from '../lardScanningEvents/operations';
import { RemoveMoveEvent } from '../move-events/operations';
import { RemovePregnanciesByAnimal, SavePregnancyEventWithSow } from '../pregnancy-events/operations';
import { RemoveTreatmentPlansByAnimal } from '../treatment-plan/operations';
import * as Action from './actions';
import { CombinedEntranceAndMated } from './types';

export function RegisterDeparture(animal: IStemAnimal, departureType: string, departureDate: Date) {
	animal.departureType = departureType;
	animal.departureDate = departureDate;
	return SaveSow(animal);
}

export function UpdateYoungAnimal(animal: IStemAnimal) {
	if (!animal) {
		return async (dispatch: Dispatch<any>) => {
			return Promise.resolve();
		};
	}
	const state = StoreContainer.getState();
	const prevEntity = state.stemAnimals.entities.find(stem => stem.id === animal.id);

	if (!animal.id) {
		animal.id = new ObjectID().toHexString();
	}

	return AsyncOperationBuilder2(
		Action.updateYoungAnimal,
		client => client.stemAnimal_UpdateYoungAnimal(StemAnimal.fromJS(animal)),
		StemAnimal.fromJS(animal),
		prevEntity,
	);
}

export function SetRetentionTimeOnStemAnimals(retentionTimes: { [key: string]: Date | undefined }) {
	const state = StoreContainer.getState();

	const animalsToUpdate = state.stemAnimals.entities
		.filter(a => a.id && retentionTimes[a.id] && (!a.retentionTime || retentionTimes[a.id]! > a.retentionTime))
		.map(a => StemAnimal.fromJS({ ...a, retentionTime: retentionTimes[a.id!] }));

	return async (dispatch: Dispatch<any>) => {
		const promises = animalsToUpdate.map(a => SaveSow(a)(dispatch));

		return await Promise.all(promises);
	};
}

export function SaveSow(animal: IStemAnimal, feedCurve?: string, fixedPercentage?: number, hasIdSwap?: boolean) {
	const state = StoreContainer.getState();
	const prevEntity = state.stemAnimals.entities.find(stem => stem.id === animal.id);

	if (!animal.id) {
		animal.id = new ObjectID().toHexString();
	}

	return AsyncOperationBuilder2(
		Action.saveSow,
		client => client.stemAnimal_Post(StemAnimal.fromJS(animal), feedCurve, fixedPercentage, hasIdSwap),
		StemAnimal.fromJS(animal),
		prevEntity,
		undefined,
		(result: string, d: Dispatch<any>) => {
			if (
				result === animal.id &&
				(animal.isDeleted || (animal.departureDate && animal.departureDate < new Date()))
			) {
				RemoveMoveEvent(animal.id!)(d);
				RemovePregnanciesByAnimal(animal.id!)(d);
				RemoveEsfStatus(animal.id!)(d);
				RemoveDailyStatus(animal.id!)(d);
				RemoveLardEvent(animal.id!)(d);
			}
		},
	);
}

export function UploadImportData(
	base64String: ImportFileData,
	errorCallback: (errorCode: number, message: string) => void,
) {
	return AsyncOperationBuilder2(
		Action.uploadImportData,
		client => client.stemAnimal_ImportYoungAnimals(base64String),
		base64String,
		undefined,
		undefined,
		undefined,
		undefined,
		errorCallback,
	);
}

export function GetDeparturedAnimals() {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;

	return AsyncOperationBuilder2(
		Action.getDeparturedSows,
		client => client.stemAnimal_GetDeparturedAnimalsBySiteId(siteId),
		siteId,
	);
}

export function GetSyncData() {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;
	const lastSyncDate = state.stemAnimals.lastSyncDate;
	if (state.stemAnimals.syncInProgress) {
		return (dispatch: Dispatch<any>) => {
			return Promise.resolve();
		};
	}

	return AsyncOperationBuilder2(
		Action.getSyncData,
		client => client.stemAnimal_Sync(siteId, lastSyncDate),
		{
			siteId,
			lastSyncDate,
		},
		undefined,
		undefined,
		(result: SyncDataStemAnimal, dispatch: Dispatch<any>) => {
			if (
				result &&
				result.datas &&
				result.datas.length &&
				lastSyncDate.getTime() !== new Date(-8640000000000000).getTime()
			) {
				const animalsToRemove = result.datas!.filter(
					a =>
						a.isDeleted ||
						(a.departureDate &&
							a.departureDate < new Date() &&
							a.departureType !== 'departureTypeShouldDeparture'),
				);
				animalsToRemove.forEach(animalToRemove => {
					RemoveMoveEvent(animalToRemove.id!)(dispatch);
					RemovePregnanciesByAnimal(animalToRemove.id!)(dispatch);
					RemoveTreatmentPlansByAnimal(animalToRemove.id!)(dispatch);
					RemoveEsfStatus(animalToRemove.id!)(dispatch);
					RemoveDailyStatus(animalToRemove.id!)(dispatch);
					RemoveLardEvent(animalToRemove.id!)(dispatch);
				});
			}
		},
	);
}

export function SaveSyncData() {
	const state = StoreContainer.getState();
	const updates = state.stemAnimals.updates;
	let promises = new Array<Promise<void>>();

	return async (dispatch: Dispatch<any>) => {
		if (state.stemAnimals.saveSyncInProgress) {
			return Promise.resolve();
		}
		updates.forEach(update => {
			let entranceFeedingStatus = state.stemAnimals.entranceEsfFeedingStatus.find(
				feeding => feeding.animalNumber === update.animalNumber,
			);
			let feedCurve =
				entranceFeedingStatus && !isNullOrUndefined(entranceFeedingStatus.feedCurveNumber)
					? entranceFeedingStatus.feedCurveNumber!.toString()
					: undefined;
			let fixedDeviation = entranceFeedingStatus && entranceFeedingStatus.fixedDeviation;
			promises.push(SaveSow(update, feedCurve, fixedDeviation)(dispatch));
		});

		return await Promise.all(promises);
	};
}

export function SaveSyncDataYoungAnimal() {
	const state = StoreContainer.getState();
	const updates = state.stemAnimals.updatesYoungAninal;

	return async (dispatch: Dispatch<any>) => {
		if (!updates || updates.length <= 0 || state.stemAnimals.saveSyncYoungAnimal) {
			return Promise.resolve();
		}
		return await UpsertManyYoungAnimal(updates)(dispatch);
	};
}

export function SetSowsCount(count: number) {
	return (dispatch: Dispatch<any>) => {
		dispatch(Action.setSowsCount(count));
	};
}

export function SetCheckedCount(count: number, reset?: boolean) {
	const store = StoreContainer.getState();
	const currentCount = reset ? count : store.stemAnimals.checkedCount + count;

	return (dispatch: Dispatch<any>) => {
		dispatch(Action.setCheckedCount(currentCount));
	};
}

export function SaveEntranceFeedingStatus(feedingStatus: IFeedingStatusUpdate) {
	return (dispatch: Dispatch<any>) => {
		dispatch(Action.saveEntranceFeedingStatus(feedingStatus));
	};
}

export function SaveSowWithMated(
	animalAndMated: CombinedEntranceAndMated,
	feedCurve?: string,
	fixedPercentage?: number,
) {
	return async (dispatch: Dispatch<any>) => {
		if (!animalAndMated.stemAnimal!.id) {
			animalAndMated.stemAnimal!.id = new ObjectID().toHexString();
		}
		if (!animalAndMated.mated!.id) {
			animalAndMated.mated!.id = new ObjectID().toHexString();
		}
		if (!animalAndMated.mated!.pregnancyId) {
			animalAndMated.mated!.pregnancyId = new ObjectID().toHexString();
		}
		if (!animalAndMated.mated!.siteId) {
			animalAndMated.mated!.siteId = animalAndMated.stemAnimal!.siteId;
		}
		if (!animalAndMated.mated!.stemAnimalId) {
			animalAndMated.mated!.stemAnimalId = animalAndMated.stemAnimal!.id;
		}

		let promiseToAwait = SaveSow(animalAndMated.stemAnimal!, feedCurve, fixedPercentage)(dispatch);
		SavePregnancyEventWithSow(animalAndMated.mated!, promiseToAwait)(dispatch);
	};
}

export function FetchSingleAnimalInfo(stemAnimalId: string) {
	return AsyncOperationBuilder2(
		Action.fetchSingleAnimalInfo,
		client => client.stemAnimal_FetchSingleAnimalInfo(stemAnimalId),
		stemAnimalId,
	);
}

export function DeleteYoungAnimals(ids: string[]) {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;
	return AsyncOperationBuilder2(
		Action.deleteYoungAnimals,
		client => client.stemAnimal_DeleteYoungAnimalsByIds(ids, siteId),
		ids,
	);
}

export function UpsertManyYoungAnimal(animals: IStemAnimal[]) {
	const state = StoreContainer.getState();

	const prevEntities = state.stemAnimals.entities.filter(stem => animals.find(ya => ya.id === stem.id));

	animals.forEach(animal => {
		if (!animal.id) {
			animal.id = new ObjectID().toHexString();
		}
	});

	return AsyncOperationBuilder2(
		Action.upsertManyYoungAnimals,
		client => client.stemAnimal_UpsertManyYoungAnimals(animals as StemAnimal[]),
		animals as StemAnimal[],
		prevEntities,
	);
}

export function ResendStemAnimal(stemAnimal: IStemAnimal) {
	return new SkioldOneClient(SkioldDigitalApiBaseUrl)
		.nucleusManagementCommunication_ResendNucleusStemAnimal(stemAnimal as StemAnimal)
		.catch(() => {
			return;
		});
}

export function GetAllIdNumbers() {
	return AsyncOperationBuilder2(Action.GetAllIdNumbers, client => client.stemAnimal_GetAllIdNumbers(), undefined);
}

export function GetDeparturedAnimalDataByDate(siteId: string, fromDate: Date, toDate: Date) {
	return AsyncOperationBuilder2(
		Action.getDeparturedAnimalDataByDate,
		client => client.stemAnimal_GetDeparturedAnimalDataByDate(siteId, fromDate, toDate),
		{ siteId, fromDate, toDate },
	);
}

export function GetYoungFemalesByDate( date: Date) {

	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;
	return AsyncOperationBuilder2(
		Action.getYoungFemalesByDate,
		client => client.stemAnimal_GetActiveYoungReportData(siteId, date),
		{ siteId, date },
	);
}
