import { createSlice } from '@reduxjs/toolkit';
import { SharedAppState } from '../../store.shared';
import { getByPenForGraph, getBySection, getDataForBuilding, getGraphForSection } from './operation';
import { FeedUsageState } from './types';

const initialState: FeedUsageState = {
	feedUsageWeanerGraph: undefined,
	pending: false,
	sectionFeedUsage: [],
	penFeedUsage: [],
};

const feedUsageReducer = createSlice({
	name: 'feedUsage',
	initialState,
	reducers: {
		resetGraph: state => initialState,
		resetPenUsageData: state => {
			state.penFeedUsage = [];
		},
	},
	extraReducers: builder => {
		builder.addCase(getGraphForSection.fulfilled, (state, action) => {
			state.feedUsageWeanerGraph = action.payload;
			state.pending = false;
		});

		builder.addCase(getGraphForSection.pending, state => {
			state.pending = true;
		});

		builder.addCase(getGraphForSection.rejected, state => {
			state.pending = false;
		});

		builder.addCase(getByPenForGraph.fulfilled, (state, action) => {
			state.feedUsageWeanerGraph = action.payload;
			state.pending = false;
		});

		builder.addCase(getByPenForGraph.pending, state => {
			state.pending = true;
		});

		builder.addCase(getByPenForGraph.rejected, state => {
			state.pending = false;
		});

		builder.addCase(getDataForBuilding.fulfilled, (state, action) => {
			state.sectionFeedUsage = action.payload;
			state.pending = false;
		});

		builder.addCase(getBySection.fulfilled, (state, action) => {
			state.penFeedUsage = action.payload;
			state.pending = false;
		});

		builder.addCase(getBySection.pending, state => {
			state.pending = true;
		});

		builder.addCase(getBySection.rejected, state => {
			state.pending = false;
		});
	},
});

export const selectFeedUsagePending = (state: SharedAppState) => state.feedUsage.pending;
export const selectFeedUsageWeanerGraph = (state: SharedAppState) => state.feedUsage.feedUsageWeanerGraph;

export const { resetGraph, resetPenUsageData } = feedUsageReducer.actions;

export default feedUsageReducer.reducer;
