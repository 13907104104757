import React from 'react';
import { SkioldInput } from '../skiold-input/skiold-input';
import { IntegerChanged } from 'shared/helpers/number-helper';
import { isNullOrUndefined } from 'shared/helpers/general-helpers';

export interface PropsFromParent {
	onChangeNumber: (newText?: number, itemFromParent?: any) => void;
	itemFromParent?: any;
	label?: string;
	text?: number;
	theme?: 'light' | 'dark';
	unit?: string;
	disabled?: boolean;
	style?: any;
	className?: string;
	id?: string;
	stateless?: boolean;
	onBlur?: (text?: number, itemFromParent?: any) => void;
	selectTextOnFocus?: boolean;
	autoFocus?: boolean;
	maxNumber?: number;
}

interface State {
	text: number | undefined;
}

export class SkioldIntegerInput extends React.PureComponent<PropsFromParent, State> {
	public static defaultProps: Partial<PropsFromParent> = {
		id: '',
		stateless: true,
	};
	public input: any | null | undefined;
	constructor(props: PropsFromParent) {
		super(props);
		this.state = {
			text: this.props.text,
		};
	}
	public render() {
		return (
			<SkioldInput
				id={this.props.id}
				style={this.props.style}
				onChangeText={this.onTextChanged}
				label={this.props.label}
				text={this.renderText()}
				theme={this.props.theme}
				suffix={this.props.unit}
				editable={!this.props.disabled}
				className={this.props.className}
				onBlur={this.onBlurCalledTriggered}
				selectTextOnFocus={this.props.selectTextOnFocus}
				autoFocus={this.props.autoFocus}
				ref={ref => {
					this.input = ref;
				}}
			/>
		);
	}

	private onBlurCalledTriggered = (e: any) => {
		if (this.props.onBlur) {
			if (this.props.stateless) {
				IntegerChanged(e.target.value, valueToSet => {
					this.props.onBlur!(valueToSet, this.props.itemFromParent);
				});
			} else {
				this.props.onBlur(this.state.text, this.props.itemFromParent);
			}
		}
	};

	private renderText = () => {
		if (this.props.stateless) {
			return isNullOrUndefined(this.props.text) ? '' : this.props.text + '';
		} else {
			return isNullOrUndefined(this.state.text) ? '' : this.state.text + '';
		}
	};

	private onTextChanged = (newText: string) => {
		IntegerChanged(newText, valueToSet => {
			if (!this.props.maxNumber || !valueToSet || valueToSet <= this.props.maxNumber) {
				if (this.props.stateless) {
					this.props.onChangeNumber(valueToSet, this.props.itemFromParent);
				} else {
					this.setState({ text: valueToSet });
				}
			}
		});
	};
	public focus() {
		if (this.input) {
			this.input.focus();
		}
	}
}
