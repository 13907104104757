import React from 'react';
import { SkioldDecimalInput } from './skiold-decimal-input';

export interface PropsFromParent {
	onChangeNumber: (newText: number | undefined) => void;
	text?: number;
	style?: any;
	editable?: boolean;
    onBlur?: () => void;
	className?: string; 
    
}


export class SkioldBoldDecimalInput extends React.PureComponent<PropsFromParent> {
    public static defaultProps: Partial<PropsFromParent> = {
        className: '',
	};
	
	

	public render() {
		return (
			<SkioldDecimalInput theme={'light'} text={this.props.text} onChangeNumber={this.onTextChanged}  className={`skiold-bold-integer-input ${this.props.className}`}/>
		);
	}

	private onTextChanged= (newText: number | undefined) => {
		this.props.onChangeNumber(newText);
	}
}
