import {
	AnimalToFarrowingTableItem,
	FileResponse,
	IAnimalToFarrowingTableItem,
	INeedWeaningTableItem,
	ITotalFarrowingTableItem,
	ITotalMatingTableItem,
	ITotalWeaningTableItem,
	NeedWeaningTableItem,
	SkioldOneClient,
	TotalFarrowingTableItem,
	TotalMatingTableItem,
	TotalWeaningTableItem,
} from 'shared/api/api';
import { SkioldDigitalApiBaseUrl } from 'shared/constants';
import { getAnimalNumberColor } from 'shared/helpers/treatment-helper/treatment-helper';
import { savePdfAs } from '../general-pdf-helper';

export function GetTotalMatingTableDashboardPdf(
	items: ITotalMatingTableItem[],
	fileName: string,
	siteId: string,
	timeZoneId?: string,
	lang?: string,
	isDeparture?: boolean,
	title?: string,
) {
	HandleCellColorStyle(items);
	return new SkioldOneClient(SkioldDigitalApiBaseUrl)
		.dashboardPdf_GetTotalMatingTableItemPdf(
			items as TotalMatingTableItem[],
			siteId,
			timeZoneId,
			isDeparture,
			title,
			lang,
		)
		.then((fileResponse: FileResponse | null) => {
			savePdfAs(fileResponse, fileName);
		})
		.catch(() => {
			return;
		});
}

export function GetAnimalToFarrowingTableDashboardPdf(
	items: IAnimalToFarrowingTableItem[],
	fileName: string,
	siteId: string,
	timeZoneId?: string,
	lang?: string,
	needFarrowing?: boolean,
) {
	HandleCellColorStyle(items);
	return new SkioldOneClient(SkioldDigitalApiBaseUrl)
		.dashboardPdf_GetAnimalToFarrowingTableItemPdf(
			items as AnimalToFarrowingTableItem[],
			siteId,
			timeZoneId,
			needFarrowing,
			lang,
		)
		.then((fileResponse: FileResponse | null) => {
			savePdfAs(fileResponse, fileName);
		})
		.catch(() => {
			return;
		});
}

export function GetTotalFarrowingTableDashboardPdf(
	items: ITotalFarrowingTableItem[],
	fileName: string,
	siteId: string,
	timeZoneId?: string,
	lang?: string,
) {
	HandleCellColorStyle(items);
	return new SkioldOneClient(SkioldDigitalApiBaseUrl)
		.dashboardPdf_GetTotalFarrowingTableItemPdf(items as TotalFarrowingTableItem[], siteId, timeZoneId, lang)
		.then((fileResponse: FileResponse | null) => {
			savePdfAs(fileResponse, fileName);
		})
		.catch(() => {
			return;
		});
}

export function GetNeedWeaningTableDashboardPdf(
	items: INeedWeaningTableItem[],
	fileName: string,
	siteId: string,
	timeZoneId?: string,
	lang?: string,
) {
	HandleCellColorStyle(items);
	return new SkioldOneClient(SkioldDigitalApiBaseUrl)
		.dashboardPdf_GetNeedWeaningTableItemPdf(items as NeedWeaningTableItem[], siteId, timeZoneId, lang)
		.then((fileResponse: FileResponse | null) => {
			savePdfAs(fileResponse, fileName);
		})
		.catch(() => {
			return;
		});
}

export function GetTotalWeaningTableDashboardPdf(
	items: ITotalWeaningTableItem[],
	fileName: string,
	siteId: string,
	timeZoneId?: string,
	lang?: string,
) {
	HandleCellColorStyle(items);
	return new SkioldOneClient(SkioldDigitalApiBaseUrl)
		.dashboardPdf_GetTotalWeaningTableItemPdf(items as TotalWeaningTableItem[], siteId, timeZoneId, lang)
		.then((fileResponse: FileResponse | null) => {
			savePdfAs(fileResponse, fileName);
		})
		.catch(() => {
			return;
		});
}

export function GetMatingBatchWeekPdf(
	items: ITotalMatingTableItem[],
	fileName: string,
	siteId: string,
	timeZoneId?: string,
	lang?: string,
	title?: string,
) {
	HandleCellColorStyle(items);
	return new SkioldOneClient(SkioldDigitalApiBaseUrl)
		.dashboardPdf_GetMatingBatchWeekPdf(items as TotalMatingTableItem[], siteId, timeZoneId, title, lang)
		.then((fileResponse: FileResponse | null) => {
			savePdfAs(fileResponse, fileName);
		})
		.catch(() => {
			return;
		});
}

function HandleCellColorStyle(
	items: Array<
		| ITotalMatingTableItem
		| IAnimalToFarrowingTableItem
		| ITotalFarrowingTableItem
		| INeedWeaningTableItem
		| ITotalWeaningTableItem
	>,
) {
	items.forEach(item => {
		if (item.sowId) {
			item.cellColorStyle = getAnimalNumberColor(item.sowId);
		}
	});
}
