import { RetryAdjustFeedTableItem, SkioldOneClient, FileResponse } from 'shared/api/api';
import { SkioldDigitalApiBaseUrl } from 'shared/constants';
import { savePdfAs } from './general-pdf-helper';

export async function PrintRetryAdjustFeed(data: any[], siteId: string, lang: string, isSow: boolean) {
	let retryAdjustFeedItems: RetryAdjustFeedTableItem[] = [];
	data.forEach(a => {
		let item = new RetryAdjustFeedTableItem();
		item.init(a._original);
		retryAdjustFeedItems.push(item);
	});
	return new SkioldOneClient(SkioldDigitalApiBaseUrl)
		.valveUpdateReport_Post(
			retryAdjustFeedItems,
			siteId,
			Intl.DateTimeFormat().resolvedOptions().timeZone,
			lang,
			isSow,
		)
		.then((fileResponse: FileResponse | null) => {
			savePdfAs(fileResponse, 'AdjustFeedChanges.pdf');
		})
		.catch(() => {
			return;
		});
}
