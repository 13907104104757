import {
	ResultAnalysisFarrowingsTableItem,
	ResultAnalysisMatingsTableItem,
	ResultAnalysisWeaningsTableItem,
	ResultPerLitterTableItem,
	TotalWeaningTableItem,
} from 'shared/api/api';
import { SkioldModal } from '../skiold-components/skiold-modal/skiold-modal';
import SowCard from 'web/view/pages/sow-card/sow-card';
import React from 'react';
import { Sorting } from '@devexpress/dx-react-grid';

import sokort_icon from 'shared/assets/src-assets/png/sokort_icon.png';
import { WebAppState } from 'web/state/store.web';
import { SkioldTouchableOpacity } from '../skiold-components/skiold-touchable-opacity';
import { SowListConstants } from '../stem-animal/animal-lists/table-constants';
import { SkioldImage } from '../utils/svg/skiold-image';
import { AnalysisModalMode } from 'shared/state/ducks/analysis/analysis-modal/operation';

type AnalysisTableItem =
	| ResultAnalysisMatingsTableItem
	| ResultAnalysisFarrowingsTableItem
	| ResultAnalysisWeaningsTableItem;
export type AnalysisResultPerLitterItemFromParent = { tableItem: ResultPerLitterTableItem; key: string };

export interface AnalysisModalPropsFromParent {
	fetchDataValue: string; // litter age batch etc. etc.
	mode: AnalysisModalMode;
	header?: string;
	dateFrom?: Date;
	dateTo?: Date;
	closeModal: () => void;
}

export const AnalysisModalMapStateToProps = (state: WebAppState) => {
	return {
		siteId: state.profile.active!.siteId!,
	};
};

export interface AnalysisResultPerLitterModalTableState {
	sowCardModal: boolean;
	stemAnimalId: string;
}

export const initialAnalysisResultPerLitterModalsTableStates = { sowCardModal: false, stemAnimalId: '' };

export const getAnalysisAnimalNumberCell = (item: AnalysisTableItem) => item.animalNumber;

export const getAnalysisLitterNumberCell = (item: AnalysisTableItem) => item.litterNumber;

export const getNumberForAnalysisTable = (num: number | undefined) => {
	return num && num > 0 ? num : undefined;
};

export function getSkioldSowCardModal(isOpen: boolean, closeModal: () => void, stemAnimalId?: string): React.ReactNode {
	return (
		<SkioldModal
			shouldCloseOnOverlayClick={true}
			padding="0"
			isOpen={isOpen}
			close={closeModal}
			justify-content="flex-end"
			max-width="calc(100% - 220px)"
		>
			<SowCard stemAnimalIdFromParent={stemAnimalId} close={closeModal} />
		</SkioldModal>
	);
}
export const getShowSowCardCell = (item: AnalysisTableItem, showSowCard: () => void) => (
	<SkioldTouchableOpacity itemFromParent={item.sowId} onPress={showSowCard}>
		<SkioldImage
			width={SowListConstants.iconSVGWidth}
			height={SowListConstants.iconSVGWidth}
			imageData={sokort_icon}
		/>
	</SkioldTouchableOpacity>
);
export const defaultSortingResultPerLitterModals: Sorting[] = [{ columnName: 'animalNr', direction: 'desc' }];

export const nursingDaysSummary = (items: ResultAnalysisWeaningsTableItem[] | TotalWeaningTableItem[]) => {
	let totalDays = 0;
	let count = 0;

	let groupedWeanings = items.groupBy('pregnancyId');
	groupedWeanings.forEach(weanings => {
		let finishedWeaning = weanings.find(wea => !wea.isNursingSow);
		if (finishedWeaning && finishedWeaning.nursingDays !== undefined) {
			totalDays += finishedWeaning.nursingDays;
			count += 1;
		} else {
			let totalNursingsDays = weanings
				.map(wea => wea.nursingDays)
				.reduce((total, days) => {
					let d1 = days ? days : 0;
					let d2 = total ? total : 0;
					return d2 + d1;
				}, 0);

			totalDays += totalNursingsDays ? totalNursingsDays : 0;
			count += 1;
		}
	});
	return totalDays && count ? Math.round((totalDays / count) * 10) / 10 : undefined;
};
