import {
	Averted,
	PregnancyState,
	SowState,
	WeaningListTypes,
	IStemAnimal,
	IPregnancyEvent,
	IAverted,
} from 'shared/api/api';
import { calculateDays } from 'shared/helpers/general-helpers';
import { getActiveSows } from 'shared/helpers/stemanimal-helper/stem-animal-input-helper';
import { getState } from 'shared/helpers/stemanimal-helper/stemanimal-helper';
import {
	WeaningWorkListItem,
	WeaningWorkListItemData,
	WeaningWorkListProps,
	WeaningWorkListState,
} from '../../../../../shared/helpers/work-list-helpers/weaning-list-helpers/weaning-work-list-item';
import { getPenBySow } from 'shared/helpers/location-helper';

function InsertOldData(workListDataOldDataCopy: WeaningWorkListItemData[], workListData: WeaningWorkListItemData[]) {
	workListDataOldDataCopy.forEach(oldData => {
		let oldDataIndex = workListData.findIndex(
			a => a.listItemData.stemAnimalId === oldData.listItemData.stemAnimalId,
		);
		if (oldDataIndex !== -1) {
			if (
				workListData[oldDataIndex].listItemData.animalEvents.length === oldData.listItemData.animalEvents.length
			) {
				workListData[oldDataIndex].workListData = oldData.workListData;
			}
		}
	});
}

function GenerateWeaningWorklistData(
	workListData: WeaningWorkListItemData[],
	prevState: WeaningWorkListState | undefined,
) {
	let worklistDatacopy = [...workListData];
	for (let index = 0; index < worklistDatacopy.length; index++) {
		let elementCopy = { ...worklistDatacopy[index] };
		if (elementCopy.workListData === undefined) {
			elementCopy.workListData = {
				Weaning: {
					state: PregnancyState.Averted,
					pregnancyId: elementCopy.listItemData.pregnancyId,
					stemAnimalId: elementCopy.listItemData.stemAnimalId,
					isNursingSow: false,
					date: new Date(),
					siteId: elementCopy.listItemData.siteId,
					totalWeight: prevState && prevState.defaultWeight ? prevState.defaultWeight : undefined,
				} as IAverted,
				stemAnimalId: elementCopy.listItemData.stemAnimalId,
				isChecked: false,
				animalEvents: elementCopy.listItemData.animalEvents,
				prevEvent: elementCopy.listItemData.prevEvent,
			};
			worklistDatacopy[index] = elementCopy;
		}
	}
	return worklistDatacopy;
}

export function genereateWorklistData(props: WeaningWorkListProps, prevState: WeaningWorkListState | undefined) {
	let workListData: WeaningWorkListItemData[] = [];
	let workListDataOldDataCopy: WeaningWorkListItemData[] = [];
	if (prevState) {
		workListDataOldDataCopy = [...prevState.workListData];
	}
	let ActiveSows = getActiveSows();
	for (let sow of ActiveSows) {
		let sowState = getState(sow.id!);

		if (sowState !== SowState.Nursing && sowState !== SowState.PregnantAndNursing) {
			continue;
		}
		const filteredPregnancyEvents = props.pregnancyEvents[sow.id!].filter(
			event =>
				event.state === PregnancyState.Farrowing ||
				(event.state === PregnancyState.Averted && (event as Averted).isNursingSow === true),
		);
		const eventDate = new Date(
			Math.max.apply(
				null,
				filteredPregnancyEvents
					.filter(event => event.state === PregnancyState.Farrowing)
					.map(e => {
						return new Date(e.date!).getTime();
					}),
			),
		);

		const eventByLastestFarrowing = filteredPregnancyEvents.find(
			a =>
				new Date(a.date!).toString() ===
				new Date(
					Math.max.apply(
						null,
						filteredPregnancyEvents
							.filter(event => event.state === PregnancyState.Farrowing)
							.map(e => {
								return new Date(e.date!).getTime();
							}),
					),
				).toString(),
		);
		let lastestEvent: IPregnancyEvent | undefined;
		if (filteredPregnancyEvents !== undefined) {
			lastestEvent = filteredPregnancyEvents.find(
				a =>
					new Date(a.date!).toString() ===
					new Date(
						Math.max.apply(
							null,
							filteredPregnancyEvents.map(e => {
								return new Date(e.date!).getTime();
							}),
						),
					).toString(),
			)!;
		}
		const cycleDays = calculateDays(eventDate, new Date());
		if (
			cycleDays !== undefined &&
			eventByLastestFarrowing !== undefined &&
			((props.workListSetting &&
				props.workListSetting.selectedType === WeaningListTypes.PregnancyDays &&
				props.workListSetting.cycleDays &&
				cycleDays >= props.workListSetting.cycleDays) ||
				(props.workListSetting &&
					props.workListSetting.selectedType === WeaningListTypes.Location &&
					CheckSowLocation(sow, prevState)))
		) {
			let weaningWorkListItem = new WeaningWorkListItem();
			weaningWorkListItem.prevEvent = eventByLastestFarrowing;
			weaningWorkListItem.animalEvents = props.pregnancyEvents[sow.id!];
			weaningWorkListItem.animalNumber = sow.animalNumber;
			weaningWorkListItem.stemAnimalId = sow.id;
			weaningWorkListItem.pregnancyId = eventByLastestFarrowing.pregnancyId;
			weaningWorkListItem.siteId = props.siteId;
			weaningWorkListItem.href = sow.id;
			weaningWorkListItem.cycleDays = cycleDays;
			weaningWorkListItem.isNursingSow =
				lastestEvent && lastestEvent.state === PregnancyState.Averted && (lastestEvent as Averted).isNursingSow
					? true
					: false;
			workListData.push({ listItemData: weaningWorkListItem, workListData: undefined });
		}
	}
	workListData = GenerateWeaningWorklistData(workListData, prevState);
	InsertOldData(workListDataOldDataCopy, workListData);
	return workListData;

	function CheckSowLocation(stemanimal: IStemAnimal, state: WeaningWorkListState | undefined) {
		let pen = getPenBySow(stemanimal);
		if (
			state &&
			pen &&
			((!state.fromPenId && state.fromSectionId && pen.sectionId === state.fromSectionId) ||
				(state.fromPenId && state.fromSectionId && pen.id === state.fromPenId))
		) {
			return true;
		}
		return false;
	}
}
