import { Action } from 'redux';
import { EsfDailyStatus, IEsfDailyStatus } from 'shared/api/api';
import { getHourDifference } from 'shared/helpers/date-helpers';
import {
	mergeArrays,
	removeMultipleValuesFromArray,
	removeValueFromArray,
	upsertValueInArray,
} from 'shared/helpers/reducer-helpers';
import { SyncableInitialState } from 'shared/state/models/syncable';
import { SharedAppState } from 'shared/state/store.shared';
import { isType } from 'typescript-fsa';
import { siteChange } from '../profile/actions';
import { getSyncModelData } from '../sync/actions';
import { getSyncEsfDailyStatusData, removeDailyStatus, saveEsfDailyStatus } from './actions';
import { EsfDailyStatusDataState } from './types';

export const initialState: EsfDailyStatusDataState = {
	...SyncableInitialState,
};

const esfDailyStatusDataReducer = (
	state: EsfDailyStatusDataState = initialState,
	action: Action,
): EsfDailyStatusDataState => {
	if (isType(action, siteChange.done)) {
		state = initialState;
	}

	if (isType(action, getSyncModelData.done)) {
		if (action.payload.params.siteId === action.payload.params.activeSiteId) {
			let today = new Date();

			let dataToSave: IEsfDailyStatus[] = [];
			if (action.payload.result.esfDailyStatus && action.payload.result.esfDailyStatus.datas) {
				dataToSave = action.payload.result.esfDailyStatus.datas.filter(
					dailyStatus => getHourDifference(dailyStatus.date, today) < 48,
				);
			}
			let entitiesStored = state.entities.filter(dailyStatus => getHourDifference(dailyStatus.date, today) < 48);

			if (
				action.payload.result.esfDailyStatus &&
				action.payload.result.esfDailyStatus.datas &&
				action.payload.result.esfDailyStatus.datas.length > 0
			) {
				state = {
					...state,
					entities: mergeArrays(entitiesStored, dataToSave),
					lastSyncDate: action.payload.result.esfDailyStatus.syncTime!,
					syncInProgress: false,
				};
			}
		} else {
			state = {
				...state,
				syncInProgress: false,
			};
		}
	}

	if (isType(action, getSyncEsfDailyStatusData.done)) {
		if (action.payload.params.siteId === action.payload.params.activeSiteId) {
			let today = new Date();

			let dataToSave: IEsfDailyStatus[] = [];
			if (action.payload.result.datas) {
				dataToSave = action.payload.result.datas.filter(
					dailyStatus => getHourDifference(dailyStatus.date, today) < 48,
				);
			}
			let entitiesStored = state.entities.filter(dailyStatus => getHourDifference(dailyStatus.date, today) < 48);

			if (action.payload.result.datas && action.payload.result.datas.length > 0) {
				state = {
					...state,
					entities: mergeArrays(entitiesStored, dataToSave),
					lastSyncDate: action.payload.result.syncTime!,
					syncInProgress: false,
				};
			}
		} else {
			state = {
				...state,
				syncInProgress: false,
			};
		}
	}

	if (isType(action, saveEsfDailyStatus.started)) {
		if (action.payload) {
			const itemUpdate = action.payload;
			let item = { ...state.entities.find(d => d.id === itemUpdate.id) } as IEsfDailyStatus;
			item.acknowledged = itemUpdate.acknowledged;
			state = {
				...state,
				updates: upsertValueInArray(state.updates, action.payload),
				entities: upsertValueInArray(state.entities, item),
				saveSyncInProgress: true,
			};
		}
	}

	if (isType(action, saveEsfDailyStatus.failed)) {
		state = { ...state, saveSyncInProgress: false };

		return state;
	}

	if (isType(action, saveEsfDailyStatus.done)) {
		if (action.payload.params) {
			state = {
				...state,
				updates: removeValueFromArray(state.updates, action.payload.params.id!),
				saveSyncInProgress: false,
			};
		}
	}

	if (isType(action, removeDailyStatus.started)) {
		const idsToRemove = state.entities
			.filter(dailyStatus => dailyStatus.stemAnimalId === action.payload)
			.map(dailyStatus => dailyStatus.id!);
		if (idsToRemove && idsToRemove.length > 0) {
			state = {
				...state,
				entities: removeMultipleValuesFromArray(state.entities, idsToRemove),
			};
		}
	}

	if (action.type === 'persist/REHYDRATE') {
		let a = (action as any) as { payload: SharedAppState; key: string };

		if (a.key === 'root') {
			let entities = new Array<IEsfDailyStatus>();
			let lastSyncDate = new Date(-8640000000000000);

			if (a.payload && a.payload.esfDailyStatus && a.payload.esfDailyStatus.entities) {
				entities = a.payload.esfDailyStatus.entities.map((dt: IEsfDailyStatus) => {
					let ndt = EsfDailyStatus.fromJS({});
					ndt.init(dt);
					return ndt;
				});
			}

			if (a.payload && a.payload.esfDailyStatus && a.payload.esfDailyStatus.lastSyncDate) {
				lastSyncDate = new Date(a.payload.esfDailyStatus.lastSyncDate);
			}
			state = { ...state, entities, lastSyncDate };
		}
	}

	return state;
};

export default esfDailyStatusDataReducer;
