import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
	AnalysisResultPregnancyDayPeriod,
	AnalysisResultPregnancySetting,
	AnalysisSettingType,
	IAnalysisResultPregnancyDayPeriod,
	IAnalysisResultPregnancySetting,
} from 'shared/api/api';
import PrinterGreyIcon from 'shared/assets/src-assets/png/printer_ikon_grey.png';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { RefType } from 'shared/helpers/ref-type';
import { AnalysisModalMode } from 'shared/state/ducks/analysis/analysis-modal/operation';
import { AnalysisMatingPregnancy } from 'shared/state/ducks/analysis/analysis-modal/types';
import { GetAnalysisPregnancyData } from 'shared/state/ducks/analysis/analysis-pregnancy/operations';
import { SaveAnalysisSetting } from 'shared/state/ducks/analysis/settings/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { ViewWeb } from 'web/view/components/utils/web-view';
import TotalMatingsDashboardTable, {
	TotalMatingsModalMode,
} from '../../dashboard/total-matings-dashboard-table/total-matings-dashboard-table';
import { SkioldButton } from '../../skiold-components/skiold-button/skiold-button';
import { SkioldFetch } from '../../skiold-components/skiold-fetch/skiold-fetch';
import { SkioldModal } from '../../skiold-components/skiold-modal/skiold-modal';
import { WhiteText } from '../../Text/white-text';
import { SkioldIconSpinner } from '../../utils/skiold-icon-spinner';
import AnalysisMatingPregnancyOverview from '../analysis-modals/analysis-mating-pregnancy/analysis-mating-pregnancy-overview';
import AnalysisResultsPregnancyTable, {
	AnalysisResultsPregnancyTable as AnalysisResultsPregnancyTableRef,
} from './analysis-results-pregnancy-table';
import './analysis-results-pregnancy.scss';
import { generatePeriodDto } from 'web/web-helpers/pdf-helper/analysis-pdf-helpers/analysis-pdf-helper';
import { isNullOrUndefined } from 'shared/helpers/general-helpers';
import { showAlert, ShowCustomAlert, ShowOkAlert } from '../../skiold-alert/skiold-alert';
interface PropsFromParent {
	topRight?: (topRight: JSX.Element) => void;
	topLeft?: (topLeft: JSX.Element) => void;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		siteId: state.profile.active!.siteId,
		profile: state.profile.active!,
		loading: state.dashboardOverview.loading,
		setting: state.analysisSettings.analysisSettings.find(
			analysisSetting => analysisSetting.type === AnalysisSettingType.AnalysisResultPregnancySetting,
		),
		matingCount: state.analysisPregnancy.data ? state.analysisPregnancy.data.matings : undefined,
		matingBatches: state.matingBatches.entities,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
	return {
		getAnalysisPregnancyData: (fromDate?: Date, toDate?: Date) =>
			GetAnalysisPregnancyData(fromDate, toDate)(dispatch),
		saveAnalysisSettings: setting => SaveAnalysisSetting(setting)(dispatch),
	};
};

export interface State {
	modalOpen: boolean;
	selectedModal?: string;
	setting: IAnalysisResultPregnancySetting;
	dateFrom?: Date;
	dateTo?: Date;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

export class AnalysisResultsPregnancyOverview extends React.PureComponent<Props, State> {
	private analysisResultsPregnancyTableRef: AnalysisResultsPregnancyTableRef | undefined;

	private onSettingChanged = (setting: IAnalysisResultPregnancySetting) => {
		this.setState({ setting: setting });
	};

	private readonly openTotalMatings = () => {
		this.setState({
			modalOpen: true,
			selectedModal: AnalysisMatingPregnancy[AnalysisMatingPregnancy.TotalMatings],
		});
	};

	private initSetting(): IAnalysisResultPregnancySetting {
		const setting = AnalysisResultPregnancySetting.fromJS({});
		setting.name = AnalysisSettingType.AnalysisResultPregnancySetting;
		setting.siteId = this.props.profile.siteId;
		setting.type = AnalysisSettingType.AnalysisResultPregnancySetting;
		setting.weaningToMating = this.getArrayOfCertainNumberOfObjects(new AnalysisResultPregnancyDayPeriod(), 4);
		setting.matingToMating = this.getArrayOfCertainNumberOfObjects(new AnalysisResultPregnancyDayPeriod(), 4);
		setting.matingToNonPregnant = this.getArrayOfCertainNumberOfObjects(new AnalysisResultPregnancyDayPeriod(), 5);
		return setting;
	}

	private getArrayOfCertainNumberOfObjects(
		analysisResultPregnancyDayPeriod: IAnalysisResultPregnancyDayPeriod,
		length: number,
	) {
		const objectArray: IAnalysisResultPregnancyDayPeriod[] = [];
		for (let index = 0; index < length; index++) {
			objectArray.push({ ...analysisResultPregnancyDayPeriod });
		}
		return objectArray as AnalysisResultPregnancyDayPeriod[];
	}

	constructor(props: Props) {
		super(props);
		this.state = {
			modalOpen: false,
			selectedModal: undefined,
			setting: props.setting ? props.setting : this.initSetting(),
		};
	}

	public componentDidUpdate(prevProps: Props) {
		// Typical usage (don't forget to compare props):
		if (this.props.setting !== prevProps.setting) {
			this.setState({ setting: this.props.setting as IAnalysisResultPregnancySetting });
		}
		if (
			this.props.topLeft &&
			(prevProps.matingCount !== this.props.matingCount ||
				this.props.matingBatches.length !== prevProps.matingBatches.length)
		) {
			this.props.topLeft(this.renderTopLeft());
		}
	}

	public componentDidMount() {
		if (this.props.topRight && this.props.topLeft) {
			this.props.topRight(this.renderTopRight());
			this.props.topLeft(this.renderTopLeft());
		}
	}

	public render() {
		return (
			<ViewWeb>
				<AnalysisResultsPregnancyTable
					setting={this.state.setting}
					ref={this.setRef as any}
					openModal={this.OpenModal}
					onSettingChanged={this.onSettingChanged}
					saveSetting={this.saveSettings}
				/>
				{this.state.modalOpen && this.state.selectedModal && (
					<SkioldModal
						shouldCloseOnOverlayClick={true}
						padding="0"
						isOpen={this.state.modalOpen}
						close={this.closeModal}
						justify-content="flex-end"
						max-width="calc(100% - 150px)"
					>
						{this.state.selectedModal !== 'TotalMatings' && (
							<AnalysisMatingPregnancyOverview
								header={'analysisResultsPregnancy'}
								dateFrom={this.state.dateFrom}
								dateTo={this.state.dateTo}
								fetchDataValue={this.state.selectedModal}
								mode={AnalysisModalMode.MatingPregnancy}
								closeModal={this.closeModal}
							/>
						)}

						{this.state.selectedModal === 'TotalMatings' && (
							<TotalMatingsDashboardTable
								mode={TotalMatingsModalMode.MatingPregnancy}
								closeModal={this.closeModal}
								dateFrom={this.state.dateFrom}
								dateTo={this.state.dateTo}
							/>
						)}
					</SkioldModal>
				)}
			</ViewWeb>
		);
	}

	private setRef = (ref?: AnalysisResultsPregnancyTableRef) => (this.analysisResultsPregnancyTableRef = ref);

	private closeModal = () => {
		this.setState({ modalOpen: false, selectedModal: undefined });
	};

	private OpenModal = (modalToOpen: AnalysisMatingPregnancy) => {
		this.setState({
			modalOpen: true,
			selectedModal: AnalysisMatingPregnancy[modalToOpen],
		});
	};

	public renderTopLeft = () => {
		let datesToHighlightLeftDatePicker = this.props.matingBatches.map(batch => batch.matingPeriodStart!);
		let datesToHighlightRightDatePicker = this.props.matingBatches.map(batch => batch.matingPeriodEnd!);
		return (
			<ViewWeb className="flexDirectionRow">
				<SkioldFetch
					defaultEmpty={true}
					datesToHighlightLeftDatePicker={datesToHighlightLeftDatePicker}
					datesToHighlightRightDatePicker={datesToHighlightRightDatePicker}
					toDate={this.state.dateTo}
					fromDate={this.state.dateFrom}
					fetchData={this.fetchData}
					fromText={localized('fromMatingBatch')}
					toText={localized('toMatingBatch')}
				/>
				{this.props.matingCount !== undefined && (
					<>
						<WhiteText className="alignSelfCenter marginRightFive">{localized('Matings') + ': '}</WhiteText>
						<WhiteText className="alignSelfCenter">{this.props.matingCount}</WhiteText>
					</>
				)}
			</ViewWeb>
		);
	};

	public renderTopRight = () => {
		return (
			<ViewWeb className="flexDirectionRow alignItemsCenter">
				<SkioldButton
					className="alignButtonCenter"
					title={localized('Elaboration')}
					onPress={this.openTotalMatings}
				/>
				<SkioldIconSpinner title={localized('PrintList')} icon={PrinterGreyIcon} onPress={this.printData} />
			</ViewWeb>
		);
	};

	private saveSettings = (setting: IAnalysisResultPregnancySetting) => {
		this.setState({ setting: setting });
		let shouldSave = true;
		if (setting.matingToMating && setting.matingToNonPregnant && setting.weaningToMating) {
			for (let index = 0; index < 5; index++) {
				if (setting.matingToMating.length > index) {
					shouldSave = this.checkSetting(setting.matingToMating, index, shouldSave);
				}
				if (setting.matingToNonPregnant.length > index) {
					shouldSave = this.checkSetting(setting.matingToNonPregnant, index, shouldSave);
				}
				if (setting.weaningToMating.length > index) {
					shouldSave = this.checkSetting(setting.weaningToMating, index, shouldSave);
				}
			}
		}

		if (shouldSave) {
			this.props.saveAnalysisSettings(setting);
		} else {
			ShowOkAlert(localized(ExceptionMessage.VALIDATION_ERROR_INTERVALS_MAY_NOT_OVERLAP));
		}
	};

	public fetchData = (dateFrom?: Date, dateTo?: Date) => {
		if (dateFrom && dateTo) {
			this.props.getAnalysisPregnancyData(dateFrom, dateTo);
			this.setState({ dateFrom, dateTo });
		}
	};

	private printData = () => {
		if (this.analysisResultsPregnancyTableRef) {
			this.analysisResultsPregnancyTableRef.printData(generatePeriodDto(this.state.dateFrom, this.state.dateTo));
		}
	};

	private checkSetting(period: AnalysisResultPregnancyDayPeriod[], index: number, shouldSave: boolean) {
		const agePeriodToCheck = period[index];
		if (agePeriodToCheck && !isNullOrUndefined(agePeriodToCheck.from) && !isNullOrUndefined(agePeriodToCheck.to)) {
			for (let indexToCheck = 0; indexToCheck < period.length; indexToCheck++) {
				if (indexToCheck !== index) {
					const valuesToCheckAgainist = period[indexToCheck];
					if (
						(!isNullOrUndefined(valuesToCheckAgainist.from) &&
							!isNullOrUndefined(valuesToCheckAgainist.to) &&
							agePeriodToCheck.from! >= valuesToCheckAgainist.from! &&
							agePeriodToCheck.from! <= valuesToCheckAgainist.to! &&
							(agePeriodToCheck.to! >= valuesToCheckAgainist.from! ||
								(agePeriodToCheck.to! <= valuesToCheckAgainist.from! &&
									agePeriodToCheck.to! <= valuesToCheckAgainist.to!))) ||
						agePeriodToCheck.from! > agePeriodToCheck.to!
					) {
						shouldSave = false;
					}
				}
			}
		}
		return shouldSave;
	}
}

export default connect<ReturnType<typeof mapStateToProps>, ReturnType<typeof mapDispatchToProps>, RefType, WebAppState>(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{
		forwardRef: true,
	},
)(AnalysisResultsPregnancyOverview);
