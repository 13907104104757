import React, { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ISlaughterSiteDataDto, SlaughterDataType } from 'shared/api/api';
import { getDateString } from 'shared/helpers/date-helpers';
import { setTwoDecimalsAsNumber } from 'shared/helpers/general-helpers';
import {
	generateSummaryData,
	generateSummaryDataForOverviewTableModal,
	slaughterDataForCalculation,
} from 'shared/helpers/slaughter-data-helper/slaughter-data-helper';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';

interface PropsFromParent {
	items: ISlaughterSiteDataDto[];
}

export const SlaughterSummaryComponent: FC<PropsFromParent> = React.memo(({ items }) => {
	const slaughterData = useSelector((state: WebAppState) => state.SlaughterDatas.slaughteDatas);

	const { aPigs, sows } = useMemo(() => {
		let aPigs: ISlaughterSiteDataDto[] = [];
		let sows: ISlaughterSiteDataDto[] = [];
		slaughterData.forEach(slaughter => {
			slaughterDataForCalculation(slaughter) ? aPigs.push(slaughter) : sows.push(slaughter);
		});

		return {
			aPigs: generateSummaryData(
				aPigs.filter(sd =>
					items.find(
						item =>
							sd.supplier === item.supplier &&
							getDateString(sd.date) === getDateString(item.date) &&
							sd.sectionId === item.sectionId &&
							sd.buildingId === item.buildingId,
					),
				),
			),
			sows: generateSummaryData(
				sows.filter(sd =>
					items.find(
						item =>
							sd.supplier === item.supplier &&
							getDateString(sd.date) === getDateString(item.date) &&
							sd.sectionId === item.sectionId &&
							sd.buildingId === item.buildingId,
					),
				),
			),
		};
	}, [slaughterData, items]);

	return (
		<>
			<div className="summaryTextStyle">{localized('APig')}</div>
			<div className="summary-container">
				<div className="flexDirectionRowWithWidth">
					<div className="summaryTextStyle"> {localized('Count')}: </div>
					<div className="summaryTextStyle"> {aPigs.amount} </div>
				</div>
				<div className="flexDirectionRowWithWidth">
					<div className="summaryTextStyle"> {localized('meatPercentage')}: </div>
					<div className="summaryTextStyle"> {setTwoDecimalsAsNumber(aPigs.meat)} </div>
				</div>
				<div className="flexDirectionRowWithWidth">
					<div className="summaryTextStyle"> {localized('avgPrice')}: </div>
					<div className="summaryTextStyle"> {setTwoDecimalsAsNumber(aPigs.price)} </div>
				</div>
				<div className="flexDirectionRowWithWidth">
					<div className="summaryTextStyle"> {localized('avgWeight')}: </div>
					<div className="summaryTextStyle"> {setTwoDecimalsAsNumber(aPigs.weight)} </div>
				</div>
			</div>
			<div className="summaryTextStyle">{localized('sowBoars')}</div>
			<div className="summary-container">
				<div className="flexDirectionRowWithWidth">
					<div className="summaryTextStyle"> {localized('Count')}: </div>
					<div className="summaryTextStyle"> {sows.amount} </div>
				</div>
				<div className="flexDirectionRowWithWidth">
					<div className="summaryTextStyle"> {localized('meatPercentage')}: </div>
					<div className="summaryTextStyle"> {setTwoDecimalsAsNumber(sows.meat)} </div>
				</div>
				<div className="flexDirectionRowWithWidth">
					<div className="summaryTextStyle"> {localized('avgPrice')}: </div>
					<div className="summaryTextStyle"> {setTwoDecimalsAsNumber(sows.price)} </div>
				</div>
				<div className="flexDirectionRowWithWidth">
					<div className="summaryTextStyle"> {localized('avgWeight')}: </div>
					<div className="summaryTextStyle"> {setTwoDecimalsAsNumber(sows.weight)} </div>
				</div>
			</div>
		</>
	);
});
