import {connectRoutes} from 'redux-first-router';
import { Routes } from 'web/state/ducks/navigation/routes';
import queryString from 'query-string'

const {
  reducer,
  middleware,
  enhancer
} = connectRoutes(Routes, {location:"navigation",title: "navigation", querySerializer: queryString})

// @HACK Exporting when assigning the variables above works when the application compiles to run,
// however Jest chokes on the reducer when running our snapshot tests.
export default  {
  reducer,
  middleware,
  enhancer
}