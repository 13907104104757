import React, { FC, useCallback, useEffect, useState } from 'react';
import { Option } from 'react-dropdown';
import { useSelector } from 'react-redux';
import { IPen } from 'shared/api/api';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { GrowthPigDepartureFromSectionRow } from 'shared/helpers/growth-pigs-helper/growth-pig-event-helper';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { sortByOrder } from '../../location/location-overview-components/location-tree-components/location-overview-helper';
import { showAlert } from '../../skiold-alert/skiold-alert';
import { SkioldButton } from '../../skiold-components/skiold-button/skiold-button';
import { SkioldFormDropdown } from '../../skiold-components/skiold-dropdown/skiold-form-dropdown';
import { SkioldFormsWrapper } from '../../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from '../../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { SkioldFormIntegerInput } from '../../skiold-components/skiold-integer-input/skiold-form-integer-input';
import { Heading } from '../../utils/heading';
import { ViewWeb } from '../../utils/web-view';

interface PropsFromParent {
	sectionId: string;
	penRowData: GrowthPigDepartureFromSectionRow[];
	setCountCallback: (keyValue: { [key: string]: number }, fromPen?: Option, toPen?: Option, amount?: number) => void;
	onClose?: () => void;
	headerTranslation: string;
	fromPen?: Option;
	toPen?: Option;
	amount?: number;
}

export const GrowthPigDistributeCountSold: FC<PropsFromParent> = React.memo(
	({ sectionId, setCountCallback, onClose, penRowData, headerTranslation, amount, fromPen, toPen }) => {
		// Get data
		const pens = useSelector((state: WebAppState) => state.locations.pens);
		const [penOptions, setPenOptions] = useState<Option[]>([]);
		const [count, setCount] = useState<number | undefined>(0);
		const [fromPenOption, setFromPenOption] = useState<Option | undefined>();
		const [toPenOption, setToPenOption] = useState<Option | undefined>();

		// ComponentDidMount
		useEffect(() => {
			const options = sortByOrder(pens.filter(p => p.sectionId === sectionId)).map(p => {
				return { label: (p as IPen).name!, value: (p as IPen).id! } as Option;
			});

			const fromOption = options.find(e => e.value === fromPen?.value);
			const toOption = options.find(e => e.value === toPen?.value);
			setPenOptions(options);
			setFromPenOption(fromOption ? fromOption : options && options[0]);
			setToPenOption(toOption ? toOption : options && options[options.length - 1]);
			setCount(fromOption && toOption ? amount: 0)
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		const close = () => {
			if (onClose) {
				onClose();
			}
		};

		// Map count correctly to each pen, and notify parent component
		const mapCountToPens = () => {
			const fromPenToUseIndex = penOptions.findIndex(p => fromPenOption && p.value === fromPenOption.value);
			const toPenToUseIndex = penOptions.findIndex(p => toPenOption && p.value === toPenOption.value);
			const pensToUse = penOptions.slice(fromPenToUseIndex, toPenToUseIndex + 1);
			let totalCount = 0;

			const pensWithMaxValue = pensToUse
				.map(e => {
					const penInfo = penRowData.find(penRow => penRow.penId === e.value);
					totalCount += penInfo && penInfo.amount !== undefined && penInfo.amount > 0 ? penInfo.amount : 0;
					return {
						penId: e.value,
						penMaxCount: penInfo && penInfo.amount !== undefined && penInfo.amount > 0 ? penInfo.amount : 0,
					};
				})
				.sort((a, b) => a.penMaxCount - b.penMaxCount);

			// Don't allow to distribute more pigs, than is currently on the location
			if (count && totalCount < count) {
				showAlert(ExceptionMessage.VALIDATION_ERROR_COUNT_LARGER_THAN_CURRENT_COUNT(totalCount));
				return;
			}

			let pensKeyValue = {};
			let tmpTotal = count ? count : 0;
			pensWithMaxValue.forEach((p, index) => {
				// Set count on each pen
				let penCount = Math.ceil(tmpTotal / (pensToUse.length - index));
				if (p && p.penMaxCount !== undefined && p.penMaxCount < penCount) {
					penCount = p.penMaxCount;
				}
				pensKeyValue[p.penId] = penCount;
				tmpTotal -= penCount;
			});
			setCountCallback(pensKeyValue, fromPenOption, toPenOption, count);
			close();
		};

		const getFormRows = () => {
			let formRows = new Array<FormRow>();

			formRows.push(countRow());
			formRows.push(fromPenRow());
			formRows.push(toPenRow());

			return formRows;
		};

		const fromPenRow = () => {
			return {
				name: localized('from'),
				component: (
					<SkioldFormDropdown
						onValueChanged={handleFromPen}
						items={penOptions}
						selectedValue={fromPenOption}
					/>
				),
			};
		};

		const toPenRow = () => {
			return {
				name: localized('to'),
				component: (
					<SkioldFormDropdown onValueChanged={handleToPen} items={penOptions} selectedValue={toPenOption} />
				),
			};
		};

		const countRow = () => {
			return {
				name: localized('Count'),
				component: <SkioldFormIntegerInput onChangeNumber={handleSetCount} text={count} />,
			};
		};

		const renderButtons = () => {
			return (
				<ViewWeb className="button-view-style">
					<SkioldButton theme="grey" title={localized('Close')} onPress={close} />
					<SkioldButton title={localized('Distribute')} onPress={mapCountToPens} />
				</ViewWeb>
			);
		};

		const handleSetCount = useCallback(
			(c: number | undefined) => {
				setCount(c);
			},
			[setCount],
		);

		const handleFromPen = useCallback(
			(from: Option) => {
				setFromPenOption(from);
			},
			[setFromPenOption],
		);

		const handleToPen = useCallback(
			(to: Option) => {
				setToPenOption(to);
			},
			[setToPenOption],
		);

		return (
			<ViewWeb className="growth-pig-distribute">
				<Heading text={localized(headerTranslation)} />
				<ViewWeb className="marginLeftTen marginRightTen">
					<SkioldFormsWrapper formRows={getFormRows()} containerClassName="forms-wrapper-container" />
				</ViewWeb>
				{renderButtons()}
			</ViewWeb>
		);
	},
);
