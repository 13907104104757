import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { saveMatingBatchSetting, getSyncData } from './actions';
import { MatingBatchSettingState } from './types';
import { SharedAppState } from 'shared/state/store.shared';
import { SyncableInitialState } from 'shared/state/models/syncable';
import { IMatingBatchSetting, MatingBatchSetting } from 'shared/api/api';
import { mergeArrays, upsertValueInArray, removeValueFromArray } from 'shared/helpers/reducer-helpers';
import { siteChange } from '../profile/actions';
import { getSyncModelData } from '../sync/actions';

export const initialState: MatingBatchSettingState = {
	...SyncableInitialState,
	currentMatingBatchSetting: {} as IMatingBatchSetting,
};

const matingBatchSettingReducer = (
	state: MatingBatchSettingState = initialState,
	action: Action,
): MatingBatchSettingState => {
	if (isType(action, getSyncData.started)) {
		state = { ...state, syncInProgress: true };
	}

	if (isType(action, getSyncData.done)) {
		if (action.payload.params.siteId === action.payload.params.activeSiteId) {
			if (action.payload.result.datas!.length) {
				const current = action.payload.result.datas!.sort((a: IMatingBatchSetting, b: IMatingBatchSetting) => {
					return b.endDate!.getTime() - a.endDate!.getTime();
				})[0];
				state = {
					...state,
					entities: mergeArrays(state.entities, action.payload.result.datas!),
					currentMatingBatchSetting: current,
				};
			}

			state = {
				...state,
				lastSyncDate: action.payload.result.syncTime!,
				syncInProgress: false,
			};
		} else {
			state = {
				...state,
				syncInProgress: false,
			};
		}
	}

	if (isType(action, getSyncModelData.done)) {
		if (action.payload.params.siteId === action.payload.params.activeSiteId) {
			if (action.payload.result.matingBatchSettings && action.payload.result.matingBatchSettings.datas!.length) {
				const current = action.payload.result.matingBatchSettings.datas!.sort(
					(a: IMatingBatchSetting, b: IMatingBatchSetting) => {
						return b.endDate!.getTime() - a.endDate!.getTime();
					},
				)[0];
				state = {
					...state,
					entities: mergeArrays(state.entities, action.payload.result.matingBatchSettings.datas!),
					currentMatingBatchSetting: current,
					lastSyncDate: action.payload.result.matingBatchSettings.syncTime!,
				};
			}

			state = {
				...state,

				syncInProgress: false,
			};
		} else {
			state = {
				...state,
				syncInProgress: false,
			};
		}
	}

	if (isType(action, saveMatingBatchSetting.started)) {
		if (action.payload) {
			state = {
				...state,
				updates: upsertValueInArray(state.updates, action.payload),
				currentMatingBatchSetting: action.payload,
				entities: upsertValueInArray(state.entities, action.payload),
			};
		}
	}

	if (isType(action, saveMatingBatchSetting.failed)) {
		if (action.payload.error.code === 500) {
			if (action.payload.error.prevEntity) {
				state = {
					...state,
					entities: upsertValueInArray(state.entities, action.payload.error.prevEntity),
					currentMatingBatchSetting: action.payload.error.prevEntity,
				};
			} else {
				state = { ...state, entities: removeValueFromArray(state.updates, action.payload.params.id!) };
			}
			state = { ...state, updates: removeValueFromArray(state.updates, action.payload.params.id!) };
		}

		return state;
	}

	if (isType(action, saveMatingBatchSetting.done)) {
		state = { ...state, updates: removeValueFromArray(state.updates, action.payload.params.id!) };
		return state;
	}

	if (isType(action, siteChange.done)) {
		state = initialState;
	}

	//Ensure Date objects are in fact date and not strings - redux-persist serializes dates to string but doesn't deserialize to dates again
	if (action.type === 'persist/REHYDRATE') {
		let a = (action as any) as { payload: SharedAppState; key: string };

		if (a.key === 'root') {
			let entities = new Array<MatingBatchSetting>();
			let updates = new Array<MatingBatchSetting>();
			let lastSyncDate = new Date(-8640000000000000);

			if (a.payload && a.payload.matingBatchSetting && a.payload.matingBatchSetting.entities) {
				entities = a.payload.matingBatchSetting.entities.map((dt: IMatingBatchSetting) => {
					let ndt = MatingBatchSetting.fromJS(dt);
					ndt.init(dt);
					return ndt;
				});
			}

			if (a.payload && a.payload.matingBatchSetting && a.payload.matingBatchSetting.updates) {
				updates = a.payload.matingBatchSetting.updates.map((dt: IMatingBatchSetting) => {
					let ndt = MatingBatchSetting.fromJS(dt);
					ndt.init(dt);
					return ndt;
				});
			}

			if (a.payload && a.payload.matingBatchSetting && a.payload.matingBatchSetting.lastSyncDate) {
				lastSyncDate = new Date(a.payload.matingBatchSetting.lastSyncDate);
			}

			const sorted =
				entities && entities.length > 0
					? entities.sort(
							(matingBatchSettingA: IMatingBatchSetting, matingBatchSettingB: IMatingBatchSetting) => {
								return matingBatchSettingB.endDate!.getTime() - matingBatchSettingA.endDate!.getTime();
							},
					  )
					: [];
			const current = sorted.length > 0 ? sorted[0] : ({} as IMatingBatchSetting);

			state = {
				...state,
				entities,
				updates,
				lastSyncDate,
				currentMatingBatchSetting: current,
			};
		}
	}

	return state;
};

export default matingBatchSettingReducer;
