import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import * as actions from './actions';
import { siteChange } from '../profile/actions';
import { DashboardGrowthPigsOverviewState } from './types';

export const initialState: DashboardGrowthPigsOverviewState = {
	loading: false,
	data: undefined,
	summaryItems: {},
	dashboardItems: {},
};

const dashboardGrowthPigsOverviewReducer = (
	state: DashboardGrowthPigsOverviewState = initialState,
	action: Action,
): DashboardGrowthPigsOverviewState => {
	if (isType(action, siteChange.done)) {
		state = initialState;
	}
	if (isType(action, actions.getDashboardGrowthPigsOverviewTable.started)) {
		state = { ...state, loading: action.payload.showSpinner };
	}

	if (isType(action, actions.getDashboardGrowthPigsOverviewTable.done)) {
		if (action.payload.result) {
			state = {
				...state,
				data: action.payload.result,
			};
		}

		state = {
			...state,
			loading: false,
		};
	}

	if (isType(action, actions.getDashboardGrowthPigsOverviewTable.failed)) {
		state = { ...state, loading: false };
	}

	if (isType(action, actions.setSummaryItems)) {
		let item = { ...state.summaryItems };
		item[action.payload.key] = action.payload.data;

		return {
			...state,
			summaryItems: item,
		};
	}

	if (isType(action, actions.setDashboardItems)) {
		let item = { ...state.dashboardItems };
		item[action.payload.key] = action.payload.data;

		return {
			...state,
			dashboardItems: item,
		};
	}

	return state;
};

export default dashboardGrowthPigsOverviewReducer;
