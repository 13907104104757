import { SkioldOneClient, FileResponse, FeedingReportItem, FeedingReportTable } from 'shared/api/api';
import { SkioldDigitalApiBaseUrl } from 'shared/constants';
import { savePdfAs } from './general-pdf-helper';
import { getAnimalNumberColor } from 'shared/helpers/treatment-helper/treatment-helper';

export function GetFeedingReport(date: Date, siteId: string, buildingId: string = '', sectionIds: string[] = []) {
	return new SkioldOneClient(SkioldDigitalApiBaseUrl).feedingReport_GetFeedingReport(
		date,
		buildingId,
		sectionIds,
		siteId,
		Intl.DateTimeFormat().resolvedOptions().timeZone,
	);
}

export function GetFeedingReportPdf(
	items: FeedingReportItem[],
	header: FeedingReportItem,
	siteId: string,
	fileName: string,
	lang?: string,
) {
	let feedingReportListItems: FeedingReportItem[] = [];
	items.forEach(reportItem => {
		let tableItem = new FeedingReportItem(reportItem);
		tableItem.cellColorStyle = getAnimalNumberColor(reportItem.stemAnimalId!);
		feedingReportListItems.push(tableItem);
	});
	const report = new FeedingReportTable({ header, tableItems: feedingReportListItems });
	return new SkioldOneClient(SkioldDigitalApiBaseUrl)
		.feedingReport_GetFeedingReportPdf(report, siteId, Intl.DateTimeFormat().resolvedOptions().timeZone, lang)
		.then((fileResponse: FileResponse | null) => {
			savePdfAs(fileResponse, fileName);
		})
		.catch(() => {
			return;
		});
}
