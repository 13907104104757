import { default as ObjectID } from 'bson-objectid';
import { Dispatch } from 'redux';
import { IProcessEquipment, ProcessEquipment } from 'shared/api/api';
import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import { StoreContainer } from 'shared/state/store-container';
import * as Action from './actions';

export function SaveProcessEquipment(
	processEquipment: IProcessEquipment,
	onSucess?: () => void,
	errorAlert?: () => void,
) {
	const state = StoreContainer.getState();
	const prevEntity = state.processEquipments.entities.find(pEquipment => pEquipment.id === processEquipment.id);

	if (!processEquipment.id) {
		processEquipment.id = new ObjectID().toHexString();
	}

	return AsyncOperationBuilder2(
		Action.saveProcessEquipment,
		client => client.processEquipment_Upsert(ProcessEquipment.fromJS(processEquipment)),
		ProcessEquipment.fromJS(processEquipment),
		prevEntity,
		undefined,
		() => {
			if (onSucess) {
				onSucess();
			}
		},
		0,
		(errorCode: number, message: string) => {
			if (errorAlert) {
				errorAlert();
			}
		},
	);
}

export function GetSyncData() {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;
	const lastSyncDate = state.processEquipments.lastSyncDate;
	if (state.processEquipments.syncInProgress) {
		return (dispatch: Dispatch<any>) => {
			return Promise.resolve();
		};
	}
	return AsyncOperationBuilder2(Action.getSyncData, client => client.processEquipment_Sync(siteId, lastSyncDate), {
		siteId,
		lastSyncDate,
	});
}

export function SaveSyncData() {
	const state = StoreContainer.getState();
	const updates = state.processEquipments.updates;
	let promises = new Array<Promise<void>>();
	return (dispatch: Dispatch<any>) => {
		if (state.processEquipments.saveSyncInProgress) {
			return Promise.resolve();
		}
		updates.forEach(update => {
			promises.push(SaveProcessEquipment(update)(dispatch));
		});

		return Promise.all(promises);
	};
}

export function RemoveProcessEquipment(id: string) {
	return AsyncOperationBuilder2(Action.removeProcessEquipment, client => client.processEquipment_Delete(id), id);
}
