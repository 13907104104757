import React from 'react';
import { TreatmentTableModel } from '../treatment-plan/treatment-table-model';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { localized } from 'shared/state/i18n/i18n';
import { TextWeb } from 'web/web-helpers/styled-text-components';

interface PropsFromParent {
    treatment: TreatmentTableModel;
}

export class DrugMethodText extends React.PureComponent<PropsFromParent, {}> {
    public render() {
        return this.getTreatmentText(this.props.treatment);
    }

    private getTreatmentText(treatmentTableModel: TreatmentTableModel) {
        if (!treatmentTableModel || !treatmentTableModel.subtreatmentTableModels|| treatmentTableModel.isRegistration) {
            return <ViewWeb />;
        }

        const textElements = treatmentTableModel.subtreatmentTableModels.map((sub, index) => {
            const typeString = localized(sub.treatmentType!);

            return <TextWeb key={index}>{typeString}</TextWeb>;
        });

        return textElements;
    }
}
