import { ProductionForm, LocationType, AnimalType } from "shared/api/api";


export class LocationTableModel {
    public buildingNumber: number | undefined;
    public buildingName: string | undefined;
    public useUnits: boolean = false;
    public unsettedUnitToPens: boolean = false;
    public productionForm: ProductionForm | undefined;
    public sectionName: string | undefined;
    public sectionType: LocationType | undefined;
    public penNumber: string | undefined;
    public productionType: AnimalType | undefined;
    public capacity: number | undefined;
    public units: string | undefined;
    public order: number | undefined;
    public useSections: boolean | undefined;
    public usePens: boolean | undefined;
    public isBuilding: boolean | undefined;
    public isSection: boolean | undefined;
    public isPen: boolean | undefined;
    public buildingId: string | undefined;
    public sectionId: string | undefined;
    public penId: string | undefined;
    public showChildren: boolean | undefined;
    public name: string | undefined;
}
