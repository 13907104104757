import { Dispatch } from 'redux';
import { DropdownScrollOption } from '.';
import * as Action from './actions';
import { IGeneralSettings, GeneralSettings } from 'shared/api/api';
import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import ObjectID from 'bson-objectid';
import { StoreContainer } from 'shared/state/store-container';

export function SaveDropdownScrollOption(scrollOptions: DropdownScrollOption) {
	return (dispatch: Dispatch<any>) => {
		dispatch(Action.saveDropdownScrollOption(scrollOptions));
	};
}

export function resetDropdownScrollOptions() {
	return (dispatch: Dispatch<any>) => {
		dispatch(Action.resetDropdownScrollOptions());
	};
}

export function SetGlobalDate(date: Date) {
	return (dispatch: Dispatch<any>) => {
		dispatch(Action.setGlobalDate(date));
	};
}

export function SaveGeneralSettings(generalSettings: IGeneralSettings) {
	let copy = { ...generalSettings };
	if (!copy.id) {
		copy.id = new ObjectID().toHexString();
	}
	if (!copy.siteId) {
		const state = StoreContainer.getState();
		copy.siteId = state.profile.active!.siteId;
	}
	let settings = GeneralSettings.fromJS(copy);
	return AsyncOperationBuilder2(
		Action.saveGeneralSettings,
		client => client.generalSettings_Upsert(settings),
		settings,
	);
}

export function GetSyncData() {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;
	const lastSyncDate = state.generalSettings.lastSyncDate;

	return AsyncOperationBuilder2(Action.getSyncData, client => client.generalSettings_Sync(siteId, lastSyncDate), {
		siteId,
		lastSyncDate,
	});
}
