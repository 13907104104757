export const SAVE_CONNECTION_STATUS = 'SAVE_CONNECTION_STATUS';
export const SAVE_TRIGGER_SYNC = 'SAVE_TRIGGER_SYNC';
export const SYNC_STARTED = 'SYNC_STARTED';
export const SYNC_FINISHED = 'SYNC_FINISHED';

export interface ConnectionState {
    isConnected: boolean;
    isTriggered: boolean;
    isSyncing: boolean;
}
