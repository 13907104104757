import { default as ObjectID } from 'bson-objectid';
import { Dispatch } from 'redux';
import {
	ActiveAnimalsReportSetting,
	AnimalReportType,
	FeedingESFReportSetting,
	IActiveAnimalsReportSetting,
	IAnimalReportSetting,
	IFeedingESFReportSetting,
	ISowBoardReportSetting,
	MatingBatchReportSetting,
	SowBoardReportSetting,
} from 'shared/api/api';
import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import { StoreContainer } from 'shared/state/store-container';
import * as Action from './actions';

export function GetSyncData() {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;
	const lastSyncDate = state.animalReportSettings.lastSyncDate;

	if (state.animalReportSettings.syncInProgress) {
		return (dispatch: Dispatch<any>) => {
			return Promise.resolve();
		};
	}

	return AsyncOperationBuilder2(Action.getSyncData, client => client.animalReportSetting_Sync(siteId, lastSyncDate), {
		siteId,
		lastSyncDate,
	});
}

export function SaveAnimalReportSetting(animalReportSetting: IAnimalReportSetting) {
	const state = StoreContainer.getState();
	const prevEntity = state.animalReportSettings.entities.find(
		animalReport => animalReport.id === animalReportSetting.id,
	);
	if (!animalReportSetting.id) {
		animalReportSetting.id = new ObjectID().toHexString();
	}

	return AsyncOperationBuilder2(
		Action.saveAnimalReportSetting,
		client =>
			client.animalReportSetting_Upsert(
				animalReportSetting.type === AnimalReportType.ActiveAnimalsReportSetting
					? ActiveAnimalsReportSetting.fromJS(animalReportSetting)
					: animalReportSetting.type === AnimalReportType.MatingBatchReportSetting
					? MatingBatchReportSetting.fromJS(animalReportSetting)
					: animalReportSetting.type === AnimalReportType.SowBoardReportSetting
					? SowBoardReportSetting.fromJS(animalReportSetting)
					: animalReportSetting.type === AnimalReportType.FeedingESFReportSetting
					? FeedingESFReportSetting.fromJS(animalReportSetting)
					: MatingBatchReportSetting.fromJS(animalReportSetting),
			),
		animalReportSetting,
		prevEntity,
	);
}

export function SaveSyncData() {
	const state = StoreContainer.getState();
	const updates = state.animalReportSettings.updates;
	let promises = new Array<Promise<void>>();

	return async (dispatch: Dispatch<any>) => {
		if (state.animalReportSettings.saveSyncInProgress) {
			return Promise.resolve();
		}
		updates.forEach(update => {
			promises.push(SaveAnimalReportSetting(update)(dispatch));
		});

		return await Promise.all(promises);
	};
}
