import { BuildingsState } from './types';
import { createReducer } from '@reduxjs/toolkit';
import { getBuildings } from './operations';
import { NativeAppState } from 'native/state/store.native';

const initialState: BuildingsState = {
	buildings: [],
	pending: false,
};

const buildingsReducer = createReducer(initialState, builder => {
	builder.addCase(getBuildings.fulfilled, (state, action) => {
		state.buildings = action.payload ?? [];
		state.pending = false;
	});

	builder.addCase(getBuildings.pending, state => {
		state.pending = true;
	});

	builder.addCase(getBuildings.rejected, state => {
		state.pending = false;
	});
});

export default buildingsReducer;

export const selectBuildings = (state: NativeAppState) => state.buildings.buildings;
