
import actionCreatorFactory from 'typescript-fsa';
import * as types from './types';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import { IProcessEquipment, SyncDataProcessEquipment } from 'shared/api/api';

const actionCreator = actionCreatorFactory(GenActionFactoryName('processEquipments'));

export const saveProcessEquipment = actionCreator.async<
IProcessEquipment,
    string,
    { code: number; message: string; prevEntity: IProcessEquipment | undefined }
>(types.SAVE_PROCESS_EQUIPMENT);

export const getSyncData = actionCreator.async<{ siteId: string; lastSyncDate: Date; activeSiteId?: string  }, SyncDataProcessEquipment>(
    types.SYNC_DATA_PROCESS_EQUIPMENT
);

export const removeProcessEquipment = actionCreator.async<string, void>(types.REMOVE_PROCESS_EQUIPMENT);

