import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { getDateString } from 'shared/helpers/date-helpers';
import { RefType } from 'shared/helpers/ref-type';
import { GetDashboardTableModels } from 'shared/state/ducks/mating-batch-report/operations';
import { MatingBatchReportModalMode } from 'shared/state/ducks/mating-batch-report/types';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import DashBoardOverviewTable from 'web/view/components/dashboard/dashboard-overview-table';
import { SkioldModalButtonContainer } from 'web/view/components/skiold-components/skiold-modal/skiold-modal-button-container/skiold-modal-button-container';
import { WhiteText } from 'web/view/components/Text/white-text';
import { Heading } from 'web/view/components/utils/heading';
import { ViewWeb } from 'web/view/components/utils/web-view';
import './mating-batch-report-modal.scss';

interface PropsFromParent {
	date?: Date;
	closeModal: () => void;
	mode?: MatingBatchReportModalMode;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		siteId: state.profile.active!.siteId,
		profile: state.profile.active!,
		loading: state.matingBatchReports.loadingMatingBatchReportDashboardTableModels,
		tableModels: state.matingBatchReports.matingBatchReportDashboardTableModels,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
	return {
		getDashboardTableModels: (siteId: string, date?: Date, mode?: MatingBatchReportModalMode) =>
			GetDashboardTableModels(siteId, date, mode)(dispatch),
	};
};

export interface State {}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

export class MatingBatchReportModal extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		if (props.siteId) {
			this.props.getDashboardTableModels(props.siteId, props.date, props.mode);
		}
	}

	public render() {
		return (
			<ViewWeb className="matingBatchReportModal">
				<Heading text={localized('matingBatchReport') + this.generateTitle()} />
				<ViewWeb className="header-modal-container">
					<WhiteText className="lineHeight40">
						{localized('Date') + ': ' + getDateString(this.props.date)}
					</WhiteText>

					<SkioldModalButtonContainer closeModal={this.props.closeModal} />
				</ViewWeb>

				<ViewWeb className="marginTen dashboardOverview">
					<DashBoardOverviewTable
						dashboardTableItems={this.props.tableModels}
						loading={this.props.loading}
						showGoalOverUnderYear={false}
						dontShowYearColumns={true}
					/>
				</ViewWeb>
			</ViewWeb>
		);
	}

	private generateTitle = () => {
		switch (this.props.mode) {
			case MatingBatchReportModalMode.matings:
				return localized('Matings');
			case MatingBatchReportModalMode.farrowings:
				return localized('Farrowings');
			case MatingBatchReportModalMode.weanings:
				return localized('Weanings');
			default:
				return ' ';
		}
	};
}

export default connect<ReturnType<typeof mapStateToProps>, ReturnType<typeof mapDispatchToProps>, RefType, WebAppState>(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{
		forwardRef: true,
	},
)(MatingBatchReportModal);
