import {
	AnalysisResultYoungFemaleAgeSetting,
	IAnalysisResultYoungFemaleAgeSetting,
	AnalysisResultYoungFemaleAgePeriod,
	AnalysisResultYoungFemaleAge,
	AnalysisSettingType,
	IAnalysisResultYoungFemaleAgePeriod,
} from 'shared/api/api';
import ObjectID from 'bson-objectid';

export function initAnalysisResultYoungFemaleAgeSetting(
	setting: IAnalysisResultYoungFemaleAgeSetting | undefined,
	siteId?: string,
) {
	if (setting && setting.id) {
		return setting;
	} else {
		let setting = AnalysisResultYoungFemaleAgeSetting.fromJS({
			name: AnalysisSettingType.AnalysisResultYoungFemaleAgeSetting,
			agePeriods: [] as AnalysisResultYoungFemaleAgePeriod[],
			id: new ObjectID().toHexString(),
			type: AnalysisSettingType.AnalysisResultYoungFemaleAgeSetting,
			siteId: siteId,
		} as IAnalysisResultYoungFemaleAgeSetting);
		Object.keys(AnalysisResultYoungFemaleAge).forEach(key => {
			setting.agePeriods!.push(
				new AnalysisResultYoungFemaleAgePeriod({
					fromAge: undefined,
					toAge: undefined,
					agePeriod: AnalysisResultYoungFemaleAge[key],
				} as IAnalysisResultYoungFemaleAgePeriod),
			);
		});
		return setting;
	}
}
