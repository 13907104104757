import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { ConnectionState } from './types';
import { saveConnectionStatus, saveTriggerSync, syncStarted, syncFinished } from './actions';

export const initialState: ConnectionState = {
	isConnected: false,
	isTriggered: false,
	isSyncing: false,
};

const offlineReducer = (state: ConnectionState = initialState, action: Action): ConnectionState => {
	if (isType(action, saveConnectionStatus.done)) {
		const result = { ...state, isConnected: action.payload.result };
		return result;
	}

	if (isType(action, saveTriggerSync.done)) {
		const result = { ...state, isTriggered: action.payload.result };
		return result;
	}

	if (isType(action, syncStarted)) {
		const result = { ...state, isSyncing: true };
		return result;
	}

	if (isType(action, syncFinished)) {
		const result = { ...state, isSyncing: false };
		return result;
	}
	if (action.type === 'USER_LOGOUT') {
		state = { ...state, isConnected: true };
	}

	return state;
};

export default offlineReducer;
