import { ChangeSet, Column, EditingState, TableColumnWidthInfo } from '@devexpress/dx-react-grid';
import {
	Grid,
	Table,
	TableColumnResizing,
	TableEditColumn,
	TableEditRow,
	TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import { Button } from '@mui/material';
import Paper from '@mui/material/Paper';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from 'shared/assets/src-assets/png/delete_icon.png';
import PenIcon from 'shared/assets/src-assets/png/pen_icon.png';
import { validateTableResizing } from 'shared/helpers/general-helpers';
import { setSize } from 'shared/state/ducks/table-settings/operations';
import { TableColumnWidthInfoGeneric } from 'shared/state/ducks/table-settings/types';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { SowListConstants } from 'web/view/components/stem-animal/animal-lists/table-constants';
import { SkioldImage } from 'web/view/components/utils/svg/skiold-image';
import './simple-skiold-table.scss';
const getRowId = row => row.id;

export interface ColumnExtension extends TableColumnWidthInfo, EditingState.ColumnExtension {}

export interface ColumnExtended extends Column {
	filter?: any;
}

interface PropsFromParent {
	data: any[];
	columns: ReadonlyArray<ColumnExtended>;
	getRowId: (row: any) => any;
	addItem: () => any;
	validate?: (edited, rows, isEdit?) => boolean;
	save?: (edited) => void;
	onDelete?: (key) => void;
	columnExtensions: ColumnExtension[];
	tableKey?: string;
}

const EditCell = ({ error, props }) => {
	const { children } = props;
	return (
		<TableEditColumn.Cell {...props}>
			{React.Children.map(children, child =>
				child?.props.id === 'commit' ? React.cloneElement(child, { disabled: error }) : child,
			)}
		</TableEditColumn.Cell>
	);
};

export const SimpleSkioldTable: FC<PropsFromParent> = React.memo(
	({ data, columns, getRowId, addItem, validate, save, onDelete, columnExtensions, tableKey }) => {
		const dispatch = useDispatch();
		const tableSettings = useSelector((state: WebAppState) => state.tableSettings.columnWidthsHashMap);
		useEffect(() => {
			setRows(data);
		}, [data]);
		const [rows, setRows] = useState<any[]>([]);
		const [addedRows, setAddedRows] = useState([]);
		const [error, setError] = useState<boolean>(false);
		const commitChanges = (item: ChangeSet) => {
			const { added, changed, deleted } = item;
			let changedRows;
			if (added) {
				changedRows = [...rows, ...added];
				if (save) {
					save(added[0]);
				}
			}
			if (changed) {
				changedRows = rows.map(row => (changed[getRowId(row)] ? { ...row, ...changed[getRowId(row)] } : row));
				if (save) {
					const key = Object.keys(changed)[0];
					const row = rows.find(e => getRowId(e) === key);
					if (row) {
						save({ ...row, ...changed[getRowId(row)] });
					}
				}
			}
			if (deleted) {
				const deletedSet = new Set(deleted);
				changedRows = rows.filter(row => !deletedSet.has(getRowId(row)));
				if (onDelete) {
					onDelete(deleted[0]);
				}
			}

			setRows(changedRows);
		};
		const changeAddedRows = value => {
			const initialized = value.map(row => (Object.keys(row).length ? row : addItem()));

			setAddedRows(initialized);
		};

		const onEdited = edited => {
			if (validate) {
				setError(validate(edited, rows.concat(addedRows), addedRows.length === 0));
			}
		};

		const editColumnMessages = {
			addCommand: localized('AddNewBreed'),
			editCommand: localized('Edit'),
			deleteCommand: localized('Delete'),
			commitCommand: localized('Save'),
			cancelCommand: localized('Cancel'),
		};

		const Command = ({ id, onExecute, text }) => {
			switch (id) {
				case 'edit':
					return (
						<Button onClick={onExecute}>
							<SkioldImage
								width={SowListConstants.iconSVGWidth}
								height={SowListConstants.iconSVGWidth}
								imageData={PenIcon}
							/>
						</Button>
					);
				case 'delete':
					return (
						<Button onClick={onExecute}>
							<SkioldImage
								width={SowListConstants.iconSVGWidth}
								height={SowListConstants.iconSVGWidth}
								imageData={DeleteIcon}
							/>
						</Button>
					);

				default:
					return <Button onClick={onExecute}>{text}</Button>;
			}
		};
		const changeColumnWidths = (columnWidths: TableColumnWidthInfo[]) => {
			if (tableKey) {
				dispatch(setSize(tableKey, columnWidths as Array<TableColumnWidthInfoGeneric<string>>));
			}
		};

		const setStyle = () => {
			const columnExtension =
				tableKey && tableSettings[tableKey]
					? validateTableResizing(tableSettings[tableKey], columnExtensions as TableColumnWidthInfo[])
					: (columnExtensions as TableColumnWidthInfo[]);
			let width = 270;
			columnExtension.forEach(element => {
				width += +element.width;
			});

			return { width };
		};

		return (
			<div style={setStyle()}>
				<Paper className={'simple-skiold-table'}>
					<Grid rows={rows} columns={columns} getRowId={getRowId}>
						<EditingState
							addedRows={addedRows}
							onAddedRowsChange={changeAddedRows}
							onCommitChanges={commitChanges}
							onRowChangesChange={onEdited}
							columnExtensions={columnExtensions as EditingState.ColumnExtension[]}
						/>
						<Table />
						<TableColumnResizing
							minColumnWidth={SowListConstants.iconWidth}
							onColumnWidthsChange={changeColumnWidths}
							columnWidths={
								tableKey && tableSettings[tableKey]
									? validateTableResizing(
											tableSettings[tableKey],
											columnExtensions as TableColumnWidthInfo[],
									  )
									: (columnExtensions as TableColumnWidthInfo[])
							}
						/>
						<TableHeaderRow />
						<TableEditRow />
						<TableEditColumn
							commandComponent={Command}
							cellComponent={props => <EditCell props={props} error={error} />}
							showEditCommand={true}
							width={250}
							showDeleteCommand={true}
							showAddCommand={!addedRows.length}
							messages={editColumnMessages}
						/>
					</Grid>
				</Paper>
			</div>
		);
	},
);
