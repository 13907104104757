
import { IReasonDto, SyncDataReason } from 'shared/api/api';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import actionCreatorFactory from 'typescript-fsa';
import * as types from './types';

const actionCreator = actionCreatorFactory(GenActionFactoryName('reasons'));

export const saveReason = actionCreator.async<
IReasonDto,
    string,
    { code: number; message: string; prevEntity: IReasonDto | undefined }
>(types.SAVE_REASON);
export const saveReasonSettings = actionCreator.async<
IReasonDto,
    string,
    { code: number; message: string; prevEntity: IReasonDto | undefined }
>(types.SAVE_REASON_SETTINGS);
export const getSyncData = actionCreator.async<{ siteId: string; lastSyncDate: Date; activeSiteId?: string  }, SyncDataReason>(
    types.SYNC_DATA_REASON
);
