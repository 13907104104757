import memoize from 'memoize-one';
import React from 'react';
import { Option } from 'react-dropdown';
import isEqual from 'react-fast-compare';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
	AnimalType,
	IGrowthPigPReportTableDatasType,
	IProductionReportItem,
	IProductionReportSetting,
	ISection,
	LocationType,
	PReportPeriod,
} from 'shared/api/api';
import PrinterGreyIcon from 'shared/assets/src-assets/png/printer_ikon_grey.png';
import { getDateString, getEndOfDate, getStartOfDate, getStartOfDay } from 'shared/helpers/date-helpers';
import { deepCopy } from 'shared/helpers/general-helpers';
import { getLocationStringBySectionId } from 'shared/helpers/location-helper';
import { memoizeHashmapLocation } from 'shared/helpers/memoize-getters/memoize-getters';
import {
	GetSyncData as GetProductionReportSeting,
	SaveProductionReportSetting,
} from 'shared/state/ducks/production-report-settings/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { GrowthPigProductionReportTable } from 'web/view/components/growth-pigs/growth-pigs-reports/growth-pig-production-report';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldDropdown } from 'web/view/components/skiold-components/skiold-dropdown/skiold-dropdown';
import { SkioldModal } from 'web/view/components/skiold-components/skiold-modal/skiold-modal';
import { SkioldTableGrid as SkioldTableRef } from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { WhiteText } from 'web/view/components/Text/white-text';
import { SkioldFetchIcon } from 'web/view/components/utils/skiold-fetch-icon';
import { SkioldIconSpinner } from 'web/view/components/utils/skiold-icon-spinner';
import { ViewWeb } from 'web/view/components/utils/web-view';
import {
	GetGrowthPigProductionReportPdf,
	GetProductionReport,
	GetProductionReportGrowthPigs,
	GetProductionReportPdf,
} from 'web/web-helpers/pdf-helper/production-report-helper';
import { PReportFeedUsage } from './p-report-feed-usage-table';
import { PeriodTableItem, PReportPeriodTable } from './p-report-period-table';
import { PReportSpecialPeriod } from './p-report-special-period';
import { ProductionReportTable } from './production-report-table';
import { initProductionReportSetting } from './production-report.-helper';
import './production-report.scss';

const mapStateToProps = (state: WebAppState, props: PropsFromParent) => {
	return {
		productionSetting: state.productionReportSetting.entities,
		profile: state.profile.active!,
		locationsSections: state.locations.sections,
		locationsMemoized: memoizeHashmapLocation(
			state.locations.buildings,
			state.locations.sections,
			state.locations.pens,
		),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
	return {
		saveProductionReportSetting: (report: IProductionReportSetting) =>
			SaveProductionReportSetting(report)(dispatch),
		getProductionReportSetting: () => GetProductionReportSeting()(dispatch),
	};
};
export interface State {
	productionType: AnimalType;
	pReportSetting: IProductionReportSetting;
	pReportSettingCopy: IProductionReportSetting;
	reportData?: IProductionReportItem | IGrowthPigPReportTableDatasType;
	selectedSectionOption?: Option;
	specialPeriodIsOpen: boolean;
	loading: boolean;
}

export interface PropsFromParent {
	animalType: AnimalType;
	topRight?: (topRight: JSX.Element) => void;
	topLeft?: (topLeft: JSX.Element) => void;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;
export class ProductionReport extends React.PureComponent<Props, State> {
	public SkioldTableRef: SkioldTableRef | undefined;

	public static getDerivedStateFromProps(nextProps: Props, prevState: State): Partial<State> {
		const productionSetting = nextProps.productionSetting.find(prs => prs.animaltype === prevState.productionType);
		if (
			!productionSetting ||
			((!productionSetting.periods || !productionSetting.periods.find(period => period.fromDate)) &&
				nextProps.animalType === AnimalType.Sow)
		) {
			return { reportData: undefined };
		}
		if (!isEqual(prevState.pReportSetting.id, productionSetting.id)) {
			return { pReportSetting: productionSetting };
		}
		if (
			isEqual(prevState.pReportSetting.id, productionSetting.id) &&
			!isEqual(prevState.pReportSettingCopy.periods, productionSetting.periods)
		) {
			return { pReportSettingCopy: productionSetting };
		}
		return {};
	}

	public componentDidUpdate(prevProps: Props, prevState: State) {
		if (prevProps.animalType !== this.props.animalType) {
			const sectionsToUse = this.getSectionsToUse();
			this.setState({
				selectedSectionOption:
					this.props.animalType === AnimalType.Sow
						? undefined
						: sectionsToUse.find(
								sec => sec.animalType === AnimalType.Weaner || sec.animalType === AnimalType.Finisher,
						  )
						? {
								label: localized(
									this.props.animalType === AnimalType.Weaner ? 'Weaners' : this.props.animalType,
								),
								value: this.props.animalType,
						  }
						: { label: localized(this.props.animalType), value: this.props.animalType },
			});
		}
		if (this.props.animalType !== AnimalType.Sow && !isEqual(this.props.animalType, prevProps.animalType)) {
			const sectionsToUse = this.getSectionsToUse();
			const selectedSectionOption = sectionsToUse.find(
				sec => sec.animalType === AnimalType.Weaner || sec.animalType === AnimalType.Finisher,
			)
				? {
						label: localized(
							this.props.animalType === AnimalType.Weaner ? 'Weaners' : this.props.animalType,
						),
						value: this.props.animalType,
				  }
				: { label: localized(this.props.animalType), value: this.props.animalType };

			const pReportSetting = initProductionReportSetting(
				this.props.productionSetting.find(
					prs => prs.animaltype === (selectedSectionOption.value as AnimalType),
				),
				this.props.profile.siteId!,
				selectedSectionOption.value as AnimalType,
			);
			this.setState({
				loading: true,
				selectedSectionOption,
				productionType: selectedSectionOption.value as AnimalType,
				pReportSetting: pReportSetting,
				pReportSettingCopy: pReportSetting,
			});

			GetProductionReportGrowthPigs(
				false,
				this.props.profile.siteId!,
				this.props.animalType,
				selectedSectionOption.value as AnimalType,
			).then(report => {
				this.setState({
					loading: false,
					reportData: report,
				});
			});
		}
		if (
			this.props.topLeft &&
			this.props.animalType !== AnimalType.Sow &&
			(!isEqual(this.state.loading, prevState.loading) ||
				!isEqual(this.state.selectedSectionOption, prevState.selectedSectionOption))
		) {
			this.props.topLeft(this.renderTopLeft());
		}
	}

	constructor(props: Props) {
		super(props);
		const setting = initProductionReportSetting(
			props.productionSetting.find(prs => prs.animaltype === props.animalType),
			props.profile.siteId!,
			props.animalType,
		);
		const sectionsToUse = this.getSectionsToUse();
		this.state = {
			productionType: props.animalType,
			pReportSetting: setting,
			pReportSettingCopy: setting,
			reportData: undefined,
			specialPeriodIsOpen: false,
			loading: false,
			selectedSectionOption:
				this.props.animalType === AnimalType.Sow
					? undefined
					: sectionsToUse.find(
							sec => sec.animalType === AnimalType.Weaner || sec.animalType === AnimalType.Finisher,
					  )
					? {
							label: localized(
								this.props.animalType === AnimalType.Weaner ? 'Weaners' : this.props.animalType,
							),
							value: this.props.animalType,
					  }
					: { label: localized(this.props.animalType), value: this.props.animalType },
		};
	}

	private getSectionsToUse() {
		return this.props.locationsSections.filter(
			sec =>
				(sec.animalType === AnimalType.Weaner && this.props.animalType === AnimalType.Weaner) ||
				(sec.animalType === AnimalType.Finisher && this.props.animalType === AnimalType.Finisher) ||
				(sec.animalType === AnimalType.FRATS &&
					(((sec.type === LocationType.Finisher || sec.type === LocationType.ReliefFinisher) &&
						this.props.animalType === AnimalType.Finisher) ||
						((sec.type === LocationType.Weaners || sec.type === LocationType.ReliefWeaners) &&
							this.props.animalType === AnimalType.Weaner))),
		);
	}

	public componentDidMount() {
		if (this.props.topRight) {
			this.props.topRight(this.renderTopRight());
		}
		if (this.props.topLeft) {
			this.props.topLeft(this.renderTopLeft());
		}

		if (
			this.props.productionSetting &&
			this.props.productionSetting.find(prs => prs.animaltype === this.props.animalType)
		) {
			this.fetchData();
		}
	}

	public render() {
		return (
			<ViewWeb className="production-report">
				<this.renderPeriodTable />
				{this.props.animalType === AnimalType.Sow ? (
					<this.renderProductionReportTable />
				) : (
					<this.renderGrowthPigProductionReportTable />
				)}
				<ViewWeb className="feed-usage-table-container">
					<this.renderFeedUsageTable />
				</ViewWeb>
				<this.renderSpecialPeriodModal />
			</ViewWeb>
		);
	}

	private openModal = () => {
		this.setState({ specialPeriodIsOpen: true });
	};

	private closeModal = () => {
		this.setState({ specialPeriodIsOpen: false });
	};

	public renderSpecialPeriodModal = () => {
		const sectionToUse = this.state.selectedSectionOption
			? (this.props.locationsMemoized[this.state.selectedSectionOption.value] as ISection)
			: undefined;
		return (
			<SkioldModal padding="0" isOpen={this.state.specialPeriodIsOpen} close={this.closeModal}>
				<PReportSpecialPeriod
					animalType={this.state.productionType}
					profile={this.props.profile}
					onDataChanged={this.specialPeriodChanged}
					pReportSetting={this.state.pReportSetting}
					closeModal={this.closeModal}
					selectedSectionLabel={this.state.selectedSectionOption && this.state.selectedSectionOption.label}
					selectedSectionId={sectionToUse && sectionToUse.id}
				/>
			</SkioldModal>
		);
	};

	public renderTopRight = () => {
		return (
			<ViewWeb className="production-top-container">
				<SkioldButton title={localized('clearPeriods')} onPress={this.clearPeriods} />
				<SkioldButton title={localized('specialPeriod')} onPress={this.openModal} />
				<SkioldIconSpinner title={'Print'} icon={PrinterGreyIcon} onPress={this.printPdf} />;
			</ViewWeb>
		);
	};

	public renderTopLeft = () => {
		return (
			<ViewWeb className="production-top-container">
				<SkioldButton title={localized('newPeriod')} onPress={this.newPeriod} />
				<SkioldFetchIcon onPress={this.fetchData} showSpinner={this.state.loading} />
				{this.props.animalType !== AnimalType.Sow && (
					<ViewWeb className="p-report-location-picker">
						<WhiteText>{localized('PReportFor')}</WhiteText>
						<SkioldDropdown
							theme={'light'}
							className="select-report-type"
							items={this.generateSectionOptions(
								this.props.locationsSections,
								this.props.animalType,
								this.props.locationsMemoized,
							)}
							onValueChanged={this.onProductionTypeChanged}
							selectedValue={this.state.selectedSectionOption}
						/>
					</ViewWeb>
				)}
			</ViewWeb>
		);
	};
	private generateSectionOptions = memoize((sections: ISection[], animalType: AnimalType, locationsMemoized) => {
		if (this.props.animalType === AnimalType.Sow) {
			return undefined;
		}
		const sectionOptions: Option[] = [];
		const sectionsToUse = sections.filter(
			sec =>
				(sec.animalType === AnimalType.Weaner && this.props.animalType === AnimalType.Weaner) ||
				(sec.animalType === AnimalType.Finisher && this.props.animalType === AnimalType.Finisher) ||
				(sec.animalType === AnimalType.FRATS &&
					(((sec.type === LocationType.Finisher || sec.type === LocationType.ReliefFinisher) &&
						this.props.animalType === AnimalType.Finisher) ||
						((sec.type === LocationType.Weaners || sec.type === LocationType.ReliefWeaners) &&
							this.props.animalType === AnimalType.Weaner))),
		);
		const sectionFrats = sectionsToUse.find(sec => sec.animalType === AnimalType.FRATS);
		const sectionWeaner = sectionsToUse.find(sec => sec.animalType === AnimalType.Weaner);

		const sectionFinsiher = sectionsToUse.find(sec => sec.animalType === AnimalType.Finisher);
		if (sectionFrats !== undefined) {
			sectionOptions.push({ label: localized(AnimalType.FRATS), value: AnimalType.FRATS });
		}
		if (sectionWeaner !== undefined) {
			sectionOptions.push({ label: localized('Weaners'), value: AnimalType.Weaner });
		}
		if (sectionFinsiher !== undefined) {
			sectionOptions.push({ label: localized(AnimalType.Finisher), value: AnimalType.Finisher });
		}

		sectionsToUse.forEach(sec => {
			const buildingSectionName = getLocationStringBySectionId(sec.id, this.props.locationsMemoized);
			if (buildingSectionName && sec.animalType !== AnimalType.FRATS) {
				sectionOptions.push({ label: buildingSectionName!, value: sec.id! });
			}
		});

		return sectionOptions;
	});

	private onProductionTypeChanged = async (section: Option, itemFromParent?: any) => {
		const sectionToUse = this.props.locationsMemoized[section.value] as ISection;
		if (sectionToUse && sectionToUse.animalType) {
			const pReportSetting = initProductionReportSetting(
				this.props.productionSetting.find(prs => prs.animaltype === sectionToUse.animalType),
				this.props.profile.siteId!,
				sectionToUse.animalType,
			);
			this.setState({
				loading: true,
				selectedSectionOption: section,
				productionType: sectionToUse.animalType,
				pReportSetting: pReportSetting,
				pReportSettingCopy: pReportSetting,
			});
			let report = await GetProductionReportGrowthPigs(
				false,
				this.props.profile.siteId!,
				sectionToUse.animalType,
				sectionToUse.animalType,
				section.value,
			);
			this.setState({
				loading: false,
				reportData: report,
			});
		} else {
			let animalTypeToUse = this.props.animalType;
			if (
				section.value === AnimalType.Weaner ||
				section.value === AnimalType.Finisher ||
				section.value === AnimalType.FRATS
			) {
				animalTypeToUse = section.value as AnimalType;
			}

			const pReportSetting = initProductionReportSetting(
				this.props.productionSetting.find(prs => prs.animaltype === this.props.animalType),
				this.props.profile.siteId!,
				animalTypeToUse,
			);
			this.setState({
				loading: true,
				selectedSectionOption: section,
				productionType: this.props.animalType,
				pReportSetting: pReportSetting,
				pReportSettingCopy: pReportSetting,
			});
			let report = await GetProductionReportGrowthPigs(
				false,
				this.props.profile.siteId!,
				this.props.animalType,
				animalTypeToUse,
			);
			this.setState({
				loading: false,
				reportData: report,
			});
		}
	};

	// render period settings table
	public renderPeriodTable = () => {
		return <PReportPeriodTable pReportSetting={this.state.pReportSetting} onDataChanged={this.dateChanged} />;
	};

	// render the p-report table
	public renderProductionReportTable = () => {
		return (
			<ProductionReportTable
				pReportSetting={this.state.pReportSetting}
				isSpecialPeriod={false}
				productionReportData={this.state.reportData as IProductionReportItem}
			/>
		);
	};

	public renderGrowthPigProductionReportTable = () => {
		return (
			<GrowthPigProductionReportTable
				pReportSetting={this.state.pReportSettingCopy}
				isSpecialPeriod={false}
				productionReportData={
					this.state.reportData && (this.state.reportData as IGrowthPigPReportTableDatasType).tableData
				}
			/>
		);
	};

	public renderFeedUsageTable = () => {
		const totalPeriod = this.state.pReportSetting.periods;

		if (this.props.animalType !== AnimalType.Sow && totalPeriod) {
			const data =
				this.state.reportData && (this.state.reportData as IGrowthPigPReportTableDatasType).feedUsageItems;

			const minDates = totalPeriod.filter(a => a.fromDate !== undefined).map(p => p.fromDate!);

			const maxDates = totalPeriod.filter(a => a.toDate !== undefined).map(p => p.toDate!);
			if (minDates.length > 0 && maxDates.length > 0) {
				const minDate = minDates.reduce((a, b) => (a > b ? a : b));
				const maxDate = maxDates.reduce((a, b) => (a > b ? a : b));
				return <PReportFeedUsage feedUsageData={data} fromDate={minDate} toDate={maxDate} />;
			} else {
				return <ViewWeb></ViewWeb>;
			}
		} else {
			const data = this.state.reportData && (this.state.reportData as IProductionReportItem);
			return this.state.pReportSetting.keyNumbers &&
				this.state.pReportSetting.keyNumbers['FeedUsage'] &&
				this.state.pReportSetting.keyNumbers['FeedUsage'] === true ? (
				<PReportFeedUsage feedUsageData={data && data.feedUsageItems} mappedDates={data && data.mappedDates} />
			) : (
				<ViewWeb></ViewWeb>
			);
		}
	};

	public printPdf = async () => {
		if (this.props.animalType === AnimalType.Sow) {
			await GetProductionReportPdf(
				false,
				this.props.profile.siteId!,
				`${localized('pReport')} ${getDateString(new Date())}.pdf`,
				Intl.DateTimeFormat().resolvedOptions().timeZone,
				this.props.profile.language,
			);
		} else {
			await GetGrowthPigProductionReportPdf(
				this.state.reportData as IGrowthPigPReportTableDatasType,
				this.state.productionType as AnimalType,
				this.props.profile.siteId!,
				`${localized('pReport')} ${getDateString(new Date())}.pdf`,
				this.state.selectedSectionOption ? this.state.selectedSectionOption.label : '',
				Intl.DateTimeFormat().resolvedOptions().timeZone,
				this.props.profile.language,
				false,
			);
		}
	};

	// push periods one column from left to right
	private newPeriod = () => {
		if (this.state.pReportSetting.periods![0].fromDate && this.state.pReportSetting.periods![0].toDate) {
			const pReportSettingCopy = deepCopy(this.state.pReportSetting);
			this.setState(prevState => {
				const periods = [...prevState.pReportSetting.periods!];
				periods.sort((a, b) => (a.periodNumber! > b.periodNumber! ? 1 : -1));
				let newFromDate = new Date(periods[0].toDate ? periods[0].toDate : new Date());
				newFromDate.setDate(newFromDate.getDate() + 1);
				newFromDate = getStartOfDay(newFromDate);
				for (let i = 1; i <= 4; i++) {
					if (periods[i - 1].periodNumber === 4) {
						periods[i - 1] = new PReportPeriod({
							fromDate: newFromDate,
							toDate: undefined,
							periodNumber: 1,
						});
					} else {
						periods[i - 1].periodNumber = i + 1;
					}
				}
				periods.sort((a, b) => (a.periodNumber! > b.periodNumber! ? 1 : -1));
				pReportSettingCopy.periods = periods;
				return { pReportSetting: { ...prevState.pReportSetting, periods } };
			});
		}
	};

	// Set to-/from date on period
	private dateChanged = (date: Date, item: PeriodTableItem) => {
		const pReportSettingCopy = deepCopy(this.state.pReportSetting);
		this.setState(prevState => {
			const periods = [...prevState.pReportSetting.periods!];
			periods.sort((a, b) => (a.periodNumber! > b.periodNumber! ? 1 : -1));
			if (item.isFromDate) {
				periods[item.periodNumber - 1].fromDate = getStartOfDate(date);
			} else {
				periods[item.periodNumber - 1].toDate = getEndOfDate(date);
			}
			periods.sort((a, b) => (a.periodNumber! > b.periodNumber! ? 1 : -1));
			pReportSettingCopy.periods = periods;
			this.props.saveProductionReportSetting(pReportSettingCopy);
			return { pReportSetting: { ...prevState.pReportSetting, periods } };
		});
	};

	// Set special period
	private specialPeriodChanged = (date: Date, item: PeriodTableItem) => {
		let pReportSettingCopy = deepCopy(this.state.pReportSetting);
		if (item.isFromDate) {
			pReportSettingCopy.specialFromDate = date;
		} else {
			pReportSettingCopy.specialToDate = date;
		}

		this.props.saveProductionReportSetting(pReportSettingCopy);
		this.setState({ pReportSetting: pReportSettingCopy });
	};

	// fetch data for web view
	private fetchData = async () => {
		this.setState(
			{
				loading: true,
			},
			() => {
				if (this.props.topLeft) {
					this.props.topLeft(this.renderTopLeft());
				}
			},
		);
		const sectionToUse = this.state.selectedSectionOption
			? (this.props.locationsMemoized[this.state.selectedSectionOption.value] as ISection)
			: undefined;
		try {
			let report =
				this.props.animalType !== AnimalType.Sow
					? sectionToUse && this.state.selectedSectionOption && this.state.productionType
						? await GetProductionReportGrowthPigs(
								false,
								this.props.profile.siteId!,
								this.state.productionType,
								this.state.productionType,
								this.state.selectedSectionOption.value,
						  )
						: await GetProductionReportGrowthPigs(
								false,
								this.props.profile.siteId!,
								this.state.productionType as AnimalType,
								this.state.selectedSectionOption
									? (this.state.selectedSectionOption.value as AnimalType)
									: AnimalType.Unknown,
						  )
					: await GetProductionReport(false, this.props.profile.siteId!);

			this.setState({ reportData: report, loading: false }, () => {
				if (this.props.topLeft) {
					this.props.topLeft(this.renderTopLeft());
				}
			});
		} catch (e) {
			this.setState({ loading: false });
		}
	};

	private clearPeriods = () => {
		let periods: PReportPeriod[] = [];
		for (let i = 1; i <= 4; i++) {
			periods!.push(
				new PReportPeriod({
					fromDate: undefined,
					toDate: undefined,
					periodNumber: i,
				}),
			);
		}
		this.setState(prevState => {
			const clearPeriodsSetting = { ...prevState.pReportSetting, periods };
			this.props.saveProductionReportSetting(clearPeriodsSetting);
			return { pReportSetting: clearPeriodsSetting };
		});
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductionReport);
