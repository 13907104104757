import React from 'react';
import { Option } from 'react-dropdown';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
	ILardScanningEventListSettingBase,
	IWorkListSetting,
	LardScanningEventTypes,
	ScanningListSetting,
	SowState,
	WorkListType,
} from 'shared/api/api';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { deepCopy } from 'shared/helpers/general-helpers';
import { SaveWorkListSetting } from 'shared/state/ducks/work-list-settings/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { showAlert } from '../../skiold-alert/skiold-alert';
import { SkioldButton } from '../../skiold-components/skiold-button/skiold-button';
import { SkioldFormDropdown } from '../../skiold-components/skiold-dropdown/skiold-form-dropdown';
import { SkioldFormsWrapper } from '../../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from '../../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { SkioldFormInput } from '../../skiold-components/skiold-input/skiold-form-input';
import { SkioldFormIntegerInput } from '../../skiold-components/skiold-integer-input/skiold-form-integer-input';
import { getAvailablityRow } from '../work-list-helper';
import './lard-scanning-event-list-setup.scss';

interface PropsFromParent {
	workListType: WorkListType;
}

const mapStateToProps = (state: WebAppState, props: PropsFromParent) => {
	return {
		listSetting: state.workListSettings.entities.find(wl => wl.type === props.workListType),
		siteId: state.profile.active!.siteId,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	saveWorkListSetting: (scanningListSetting: IWorkListSetting) => SaveWorkListSetting(scanningListSetting)(dispatch),
});

export interface State {
	listSetting: ILardScanningEventListSettingBase;
	selectedOption: Option;
	options: Option[];
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

class LardScanningEventListSetup extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			listSetting: (this.props.listSetting
				? this.props.listSetting
				: this.init()) as ILardScanningEventListSettingBase,
			options: [
				{
					label: localized(SowState.Pregnant),
					value: SowState.Pregnant,
				},
				{
					label: localized(SowState.Nursing),
					value: SowState.Nursing,
				},
				{
					label: localized(SowState.Dry),
					value: SowState.Dry,
				},
			],
			selectedOption: { label: '', value: '' },
		};
	}

	public componentDidMount() {
		let initSetting = {};
		if (this.state.listSetting.sowState !== undefined) {
			initSetting['selectedOption'] = {
				label: localized(this.state.listSetting.sowState),
				value: this.state.listSetting.sowState,
			};
			this.setState(initSetting);
		}
	}

	public toggleShowOnWeb = () => {
		this.setState(prevState => ({
			listSetting: {
				...prevState.listSetting,
				showOnWeb: !this.state.listSetting.showOnWeb,
			},
		}));
	};

	public toggleShowOnApp = () => {
		this.setState(prevState => ({
			listSetting: {
				...prevState.listSetting,
				showOnApp: !this.state.listSetting.showOnApp,
			},
		}));
	};

	public render() {
		return (
			<ViewWeb className="scanning-list-setup">
				<ViewWeb className="view-container">
					<TextWeb className="text-style">{localized('Setup')}</TextWeb>
					<SkioldFormsWrapper formRows={this.getFormRows()} containerClassName="wrapper-container" />

					{this.renderButtons()}
				</ViewWeb>
			</ViewWeb>
		);
	}

	private init() {
		return ScanningListSetting.fromJS({
			type: this.props.workListType,
			siteId: this.props.siteId,
		});
	}

	private save = async () => {
		if (!this.validate(this.state.listSetting)) {
			return;
		}
		this.props.saveWorkListSetting(this.state.listSetting);
	};

	private getFormRows() {
		let formRows = new Array<FormRow>();

		formRows.push(this.getWorkListHeader());
		formRows.push(this.getWorkListNameRow());
		formRows.push(this.getConditionRow());
		if (this.state.selectedOption.value !== SowState.Dry) {
			formRows.push(this.getWeeksAfterEventRow());
		}

		formRows.push(
			getAvailablityRow(
				this.toggleShowOnWeb,
				this.toggleShowOnApp,
				this.state.listSetting.showOnWeb!,
				this.state.listSetting.showOnApp!,
			),
		);

		return formRows;
	}

	private onEventTypeChanged = (option: Option) => {
		let listSetting = deepCopy(this.state.listSetting);
		listSetting.sowState = option.value as SowState;
		this.setState({
			listSetting,
			selectedOption: option,
		});
	};

	private getConditionRow(): FormRow {
		return {
			name: localized('condition'),
			component: (
				<SkioldFormDropdown
					items={this.state.options}
					selectedValue={this.state.selectedOption}
					onValueChanged={this.onEventTypeChanged}
				/>
			),
		};
	}

	private onWeeksAfterEventChanged = (weeksAfterEvent: number | undefined) => {
		this.valueInSetupChanged(weeksAfterEvent, 'weeksAfterEvent');
	};

	private onSetupNameChange = (newName: string) => {
		this.valueInSetupChanged(newName, 'name');
	};

	private valueInSetupChanged(value: any, key: keyof ILardScanningEventListSettingBase) {
		this.setState(prevState => ({
			listSetting: {
				...prevState.listSetting,
				[key]: value,
			},
		}));
	}

	private getWorkListNameRow(): FormRow {
		return {
			name: localized('name'),
			component: <SkioldFormInput onChangeText={this.onSetupNameChange} text={this.state.listSetting.name} />,
		};
	}

	private validate(listSetting: ILardScanningEventListSettingBase) {
		return true;
	}

	private getWorkListHeader(): FormRow {
		return {
			header: localized(this.props.workListType),
		};
	}

	private getWeeksAfterEventRow(): FormRow {
		return {
			name:
				this.state.selectedOption.value === SowState.Pregnant
					? localized('weeksAfterMating')
					: localized('weeksAfterFarrowing'),
			component: (
				<SkioldFormIntegerInput
					onChangeNumber={this.onWeeksAfterEventChanged}
					className="weeks-after-event-input"
					text={this.state.listSetting.weeksAfterEvent}
				/>
			),
		};
	}

	private renderButtons() {
		return (
			<ViewWeb className="view-button-container">
				<SkioldButton title={localized('Save')} onPress={this.save} />
			</ViewWeb>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(LardScanningEventListSetup);
