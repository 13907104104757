import { ISite, Site } from 'shared/api/api';
import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import * as Action from './actions';

export const GetSite = (id: string) => {
	return AsyncOperationBuilder2(Action.getSiteAsync, cl => cl.sites_Get(id), id);
};

export const GetByProfile = (profileId: string) => {
	return AsyncOperationBuilder2(Action.getSiteAsync, cl => cl.sites_GetByProfile(profileId), profileId);
};

export const GetByProfileAsync = (profileId: string) => {
	return AsyncOperationBuilder2(Action.getSiteAsync, client => client.sites_GetByProfile(profileId), profileId);
};

export const SaveSiteAsync = (site: ISite, onSucess?: () => void, errorAlert?: () => void) => {
	let siteToSave = Site.fromJS({});
	siteToSave.init(site);
	return AsyncOperationBuilder2(
		Action.saveSiteAsync,
		client => client.sites_Upsert(siteToSave),
		siteToSave,
		undefined,
		undefined,
		() => {
			if (onSucess) {
				onSucess();
			}
		},
		0,
		(errorCode: number, message: string) => {
			if (errorAlert) {
				errorAlert();
			}
		},
	);
};
