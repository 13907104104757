import { TreatmentState } from './types';
import { Action } from 'redux';
import * as actions from './actions';
import { createTreatmentList, getTreatmentsForLog, saveManyTreatments, saveTreatment } from './actions';
import { isType } from 'typescript-fsa';
import { SyncableInitialState } from 'shared/state/models/syncable';
import {
	mergeArrays,
	removeMultipleValuesFromArray,
	removeValueFromArray,
	upsertValueInArray,
} from 'shared/helpers/reducer-helpers';
import { siteChange } from '../profile/actions';
import { ITreatment, Treatment } from 'shared/api/api';
import { removeRedundantDataByArray } from 'shared/helpers/general-helpers';
import { getSyncModelData } from '../sync/actions';

export const initialState: TreatmentState = {
	...SyncableInitialState,
	treatmentsForLog: [],
	fetchingTreatmentsForLog: false,
};

const treatmentReducer = (state: TreatmentState = initialState, action: Action): TreatmentState => {
	if (isType(action, actions.getSyncData.started)) {
		state = { ...state, syncInProgress: true };
	}

	if (isType(action, getSyncModelData.done)) {
		if (action.payload.result.treatments && action.payload.params.siteId === action.payload.params.activeSiteId) {
			if (action.payload.result.treatments.datas!.length) {
				state = {
					...state,
					entities: removeRedundantDataByArray(
						mergeArrays(state.entities, action.payload.result.treatments.datas!),
					) as ITreatment[],
				};
			}

			state = {
				...state,
				lastSyncDate: action.payload.result.treatments.syncTime!,
				syncInProgress: false,
			};
		} else {
			state = {
				...state,
				syncInProgress: false,
			};
		}
	}

	if (isType(action, actions.getSyncData.done)) {
		if (action.payload.result && action.payload.params.siteId === action.payload.params.activeSiteId) {
			if (action.payload.result.datas!.length) {
				state = {
					...state,
					entities: removeRedundantDataByArray(
						mergeArrays(state.entities, action.payload.result.datas!),
					) as ITreatment[],
				};
			}

			state = {
				...state,
				lastSyncDate: action.payload.result.syncTime!,
				syncInProgress: false,
			};
		} else {
			state = {
				...state,
				syncInProgress: false,
			};
		}
	}

	if (isType(action, actions.getSyncData.failed)) {
		state = { ...state, syncInProgress: false };
	}

	if (isType(action, saveTreatment.started)) {
		if (action.payload && action.payload.isDeleted) {
			state = {
				...state,
				updates: upsertValueInArray(state.updates, action.payload),
				entities: removeValueFromArray(state.entities, action.payload.id!),
				treatmentsForLog: removeValueFromArray(state.treatmentsForLog, action.payload.id!),
			};
		} else if (action.payload) {
			state = {
				...state,
				updates: upsertValueInArray(state.updates, action.payload),
				entities: upsertValueInArray(state.entities, action.payload),
			};
		}
		state = { ...state, saveSyncInProgress: true };
		return state;
	}

	if (isType(action, saveTreatment.failed)) {
		if (action.payload.error.code === 500 && action.payload.params) {
			if (action.payload.error.prevEntity) {
				state = { ...state, entities: upsertValueInArray(state.entities, action.payload.error.prevEntity) };
			} else {
				state = { ...state, entities: removeValueFromArray(state.entities, action.payload.params.id!) };
			}
			//state = { ...state, updates: removeValueFromArray(state.updates, action.payload.params.id!) };
		}
		state = { ...state, saveSyncInProgress: false };
		return state;
	}

	if (isType(action, saveTreatment.done)) {
		return {
			...state,
			updates: removeValueFromArray(state.updates, action.payload.result),
			saveSyncInProgress: false,
		};
	}

	if (isType(action, createTreatmentList.started)) {
		state = {
			...state,
			updates: mergeArrays(state.updates, action.payload),
			entities: mergeArrays(state.entities, action.payload),
		};

		return state;
	}

	if (isType(action, saveManyTreatments.started)) {
		state = {
			...state,
			updates: mergeArrays(state.updates, action.payload.treatments),
			entities: mergeArrays(state.entities, action.payload.treatments),
		};

		return state;
	}

	// No real error handling on failed, since the we catch exceptions for single treatments, and return ids only for succesfull treatments
	// Error handling is done in done action
	if (isType(action, saveManyTreatments.failed)) {
		if (action.payload.error.code === 500) {
		}

		return state;
	}

	if (isType(action, saveManyTreatments.done)) {
		const treatmentIds = action.payload.params.treatments.map(t => t.id!);
		let difference = treatmentIds.filter(x => !action.payload.result.includes(x));
		const prevEntities = action.payload.params.prevEntities.filter(t => difference.includes(t.id!));
		state = {
			...state,
			updates: removeMultipleValuesFromArray(state.updates, action.payload.result),
			entities: mergeArrays(state.entities, prevEntities),
		};

		return state;
	}

	if (isType(action, createTreatmentList.failed)) {
		if (action.payload.error.code === 500) {
			const ids = action.payload.params.map(treatment => treatment.id!);
			state = {
				...state,
				entities: removeMultipleValuesFromArray(state.entities, ids),
				// updates: removeMultipleValuesFromArray(state.updates, ids),
			};
		}

		return state;
	}

	if (isType(action, createTreatmentList.done)) {
		state = {
			...state,
			updates: removeMultipleValuesFromArray(state.updates, action.payload.result),
		};

		return state;
	}

	if (isType(action, getTreatmentsForLog.started)) {
		state = { ...state, fetchingTreatmentsForLog: true };
		return state;
	}

	if (isType(action, getTreatmentsForLog.done)) {
		if (action.payload.params.siteId === action.payload.params.activeSiteId) {
			state = {
				...state,
				treatmentsForLog: mergeArrays(state.treatmentsForLog, action.payload.result),
				fetchingTreatmentsForLog: false,
			};
			return state;
		}
	}

	if (isType(action, getTreatmentsForLog.failed)) {
		state = { ...state, fetchingTreatmentsForLog: false };
		return state;
	}

	if (isType(action, actions.saveLogTreatment.started)) {
		if (action.payload.executedDate) {
			state = {
				...state,
				updates: upsertValueInArray(state.updates, action.payload),
				// Date is still set, it has just been changed - update in place
				treatmentsForLog: upsertValueInArray(state.treatmentsForLog, action.payload),
			};
		} else {
			state = {
				...state,
				updates: upsertValueInArray(state.updates, action.payload),
				// This is a reactivation, remove from the log list
				treatmentsForLog: removeValueFromArray(state.treatmentsForLog, action.payload.id!),
				// and add it to the non-log list
				entities: upsertValueInArray(state.entities, action.payload),
			};
		}
		state = { ...state, saveSyncInProgress: true };
		return state;
	}

	if (isType(action, actions.saveLogTreatment.failed)) {
		if (action.payload.error.code === 500) {
			if (action.payload.error.prevEntity) {
				state = {
					...state,
					entities: upsertValueInArray(state.treatmentsForLog, action.payload.error.prevEntity),
				};
				if (!action.payload.params.executedDate) {
					state = { ...state, entities: removeValueFromArray(state.entities, action.payload.params.id!) };
				}
			} else {
				state = { ...state, entities: removeValueFromArray(state.entities, action.payload.params.id!) };
				state = { ...state, entities: removeValueFromArray(state.treatmentsForLog, action.payload.params.id!) };
			}
			// state = { ...state, updates: removeValueFromArray(state.updates, action.payload.params.id!) };
		}
		state = { ...state, saveSyncInProgress: false };
		return state;
	}

	if (isType(action, actions.saveLogTreatment.done)) {
		if (!action.payload.params.isDeleted) {
			state = { ...state, treatmentsForLog: upsertValueInArray(state.treatmentsForLog, action.payload.params) };
		}

		return {
			...state,
			updates: removeValueFromArray(state.updates, action.payload.result),
			saveSyncInProgress: false,
		};
	}

	if (isType(action, siteChange.done)) {
		state = initialState;
	}

	if (isType(action, actions.removeTreatmentsByAnimal)) {
		const idsToRemove = state.entities
			.filter((treatment: any) => treatment.treatmentPlanId === action.payload)
			.map((treatment: any) => treatment.id!);
		if (idsToRemove && idsToRemove.length > 0) {
			state = {
				...state,
				entities: removeMultipleValuesFromArray(state.entities, idsToRemove),
			};
		}
	}

	if (isType(action, actions.getTreatmentsByStemAnimalId.done)) {
		if (action.payload.result.length) {
			state = {
				...state,
				treatmentsForLog: mergeArrays(state.treatmentsForLog, action.payload.result),
				entities: mergeArrays(state.entities, action.payload.result),
			};
		}

		state = {
			...state,
			syncInProgress: false,
		};
	}

	//Ensure Date objects are in fact date and not strings - redux-persist serializes dates to string but doesn't deserialize to dates again
	if (action.type === 'persist/REHYDRATE') {
		let a = (action as any) as { payload: TreatmentState; key: string };

		if (a.key === 'treatments') {
			let entities = new Array<ITreatment>();
			let updates = new Array<ITreatment>();
			let lastSyncDate = new Date(-8640000000000000);

			if (a.payload && a.payload.entities) {
				entities = a.payload.entities.map((t: ITreatment) => {
					let nt = Treatment.fromJS({});
					nt.init(t);
					return nt;
				});
			}

			if (a.payload && a.payload.updates) {
				updates = a.payload.updates.map((t: ITreatment) => {
					let nt = Treatment.fromJS({});
					nt.init(t);
					return nt;
				});
			}

			if (a.payload && a.payload.lastSyncDate) {
				lastSyncDate = new Date(a.payload.lastSyncDate);
			}

			state = { ...state, entities, updates, lastSyncDate };
		}
	}

	return state;
};

export default treatmentReducer;
