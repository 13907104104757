
import actionCreatorFactory from 'typescript-fsa';
import * as types from './types';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import { IProductionReportSetting, SyncDataProductionReportSetting } from 'shared/api/api';

const actionCreator = actionCreatorFactory(GenActionFactoryName('productionReportSetting'));

export const saveProductionReportSetting = actionCreator.async<
IProductionReportSetting,
    string,
    { code: number; message: string; prevEntity: IProductionReportSetting | undefined }
>(types.SAVE_PRODUCTION_REPORT_SETTING);
export const getSyncData = actionCreator.async<{ siteId: string; lastSyncDate: Date }, SyncDataProductionReportSetting>(
    types.SYNC_DATA_PRODUCTION_REPORT_SETTING
);
