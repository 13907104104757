import { Sorting } from '@devexpress/dx-react-grid';
import ObjectID from 'bson-objectid';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
	Equipment,
	IMoveFromPregToPregSetting,
	IMoveMatingToMatingSetting,
	IMoveToFarrowingLocation,
	IMoveToMatingLocation,
	IMoveToPregnancyLocation,
	ISection,
	IStemAnimal,
	MoveToLocationTypes,
} from 'shared/api/api';
import { copyFlatObjectArray, rangeFilterMethodGrid } from 'shared/helpers/general-helpers';
import { ILocationModel, LocationModel } from 'shared/helpers/location-helper';
import { memoizeActiveSows } from 'shared/helpers/memoize-getters/memoize-getters';
import { ExtractLocationCode } from 'shared/helpers/process-equipment-helper/process-equipment-helper';
import { RefType } from 'shared/helpers/ref-type';
import { getCurveItemByAnimalKind } from 'shared/helpers/work-list-helpers/general-work-list-helper';
import { PenHasSameBaseLocationCode } from 'shared/helpers/work-list-helpers/move-to-helpers/move-from-preg-to-preg-list-helper';
import { MovingListTable } from 'shared/helpers/work-list-helpers/move-to-helpers/move-to-data-model';
import {
	checkSectionWorkLists,
	getMoveListItemByAnimal,
	workListUseFeedCurve,
} from 'shared/helpers/work-list-helpers/move-to-helpers/moving-lists-helper';
import { SetCheckedCount, SetSowsCount } from 'shared/state/ducks/stem-animals/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { ViewWeb } from 'web/view/components/utils/web-view';
import SkioldFormPenPicker from '../../location/location-picker/skiold-form-pen-picker';
import SkioldStableSectionPicker from '../../location/location-picker/skiold-stable-section-picker';
import SkioldFeedCurvePicker from '../../process-equipment/skiold-feed-curve-picker';
import { SkioldCheckbox } from '../../skiold-components/skiold-checkbox/skiold-checkbox';
import { SkioldFormInput } from '../../skiold-components/skiold-input/skiold-form-input';
import { SkioldFormIntegerInput } from '../../skiold-components/skiold-integer-input/skiold-form-integer-input';
import SkioldTableGrid from '../../skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { SowListConstants } from '../../stem-animal/animal-lists/table-constants';
import '../list-setup.scss';
import { getAnimalNumberText, getStemAnimalNumberColor } from '../work-list-helper';
import './move-list-table.scss';

const mapStateToProps = (state: WebAppState) => {
	return {
		esfExists: state.processEquipments.entities.find(pq => pq.equipment === Equipment.ESF) !== undefined,
		locations: state.locations,
		moveEvents: state.moveEvents.entities,
		activeSows: memoizeActiveSows(state.stemAnimals.entities),
		pregnancyEvets: state.pregnancyEvents.entities,
		unitToPens: state.unitToPenData.data,
		processEquipmentDatas: state.processEquipmentData.entities,
		esfFeedingStatus: state.esfStatus.data,
		stations: state.station.data,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	SetCheckedCount: (count: number, reset?: boolean) => SetCheckedCount(count, reset)(dispatch),
	setSowsCount: (count: number) => SetSowsCount(count)(dispatch),
});

export interface MoveToListTableState {
	toSectionId?: string;
	toPenId?: string;
	selection: React.ReactText[];
	commitAll: boolean;
	isBlankOption: boolean;
}

interface PropsFromParent {
	data: MovingListTable[];
	movingListSetup:
		| IMoveToPregnancyLocation
		| IMoveToMatingLocation
		| IMoveToFarrowingLocation
		| IMoveFromPregToPregSetting
		| IMoveMatingToMatingSetting;
	filteredLocations?: ILocationModel;
	saveWorkListDataTemporary: (movingListData: MovingListTable[]) => void;
	showPen: boolean;
	validateSettingToSubtract?: number;
	sectionId?: string;
	penId?: string;
	usesPens?: boolean;
	tableKey?: string;
}

type MoveToListTableProps = ReturnType<typeof mapStateToProps> &
	ReturnType<typeof mapDispatchToProps> &
	PropsFromParent;
export class MoveToListTable extends React.PureComponent<MoveToListTableProps, MoveToListTableState> {
	public static defaultProps: Partial<MoveToListTableProps> = {
		validateSettingToSubtract: 0,
	};
	public SkioldTableRef: any;

	private defaultSorting = [{ columnName: 'cycleDays', direction: 'desc' }] as Sorting[];
	constructor(props: MoveToListTableProps) {
		super(props);
		this.state = {
			toSectionId: undefined,
			toPenId: undefined,
			selection: [],
			commitAll: false,
			isBlankOption: props.movingListSetup.selectedType === MoveToLocationTypes.Blank,
		};
	}

	public componentDidUpdate(prevProps: MoveToListTableProps) {
		const stemLength = this.props.data.filter(e => e.stemAnimalId).length;
		if (this.state.isBlankOption && prevProps.data.filter(e => e.stemAnimalId).length !== stemLength) {
			this.props.setSowsCount(stemLength);
		}
	}

	public setSectionOnTableItem(item: MovingListTable, section: ISection) {
		let workListData = copyFlatObjectArray(this.props.data);
		let indexOfItemToEdit = workListData.findIndex(a => a.stemAnimalId === item.stemAnimalId);

		if (section && indexOfItemToEdit !== -1) {
			workListData[indexOfItemToEdit].sectionId = section.id;
			let { penId, usesPens } = checkSectionWorkLists(section.id, this.state.toPenId);
			workListData[indexOfItemToEdit].usesPens = usesPens;
			workListData[indexOfItemToEdit].penId = penId;
			const defaultFeedCurves = getCurveItemByAnimalKind(this.props.movingListSetup, item.stemAnimalId);

			const utp = this.props.unitToPens.find(p => p.penId === penId);
			const processEquipmentData = this.props.processEquipmentDatas.find(
				peq =>
					defaultFeedCurves &&
					utp &&
					peq.id === utp.processEquipmentDataId &&
					defaultFeedCurves.locationCode === ExtractLocationCode(peq.code),
			);
			workListData[indexOfItemToEdit].fromPenIsEsfLocation = PenHasSameBaseLocationCode(item.currentPenId, penId);

			// If Default curve and toPen is ESF1, and from pen is ESF2 or Non ESF
			if (processEquipmentData && defaultFeedCurves && !workListData[indexOfItemToEdit].fromPenIsEsfLocation) {
				workListData[indexOfItemToEdit].selectedCurveNumber = defaultFeedCurves.feedCurveNumber;
			} else if (
				// If Default curve and toPen is ESF1, and from pen is ESF1
				// If Default curve is ESF1 and toPen is ESF2, and from pen is ESF2
				workListData[indexOfItemToEdit].fromPenIsEsfLocation
			) {
				workListData[indexOfItemToEdit].selectedCurveNumber =
					workListData[indexOfItemToEdit].currentSelectedCurveNumber;
				workListData[indexOfItemToEdit].fixedDeviation = workListData[indexOfItemToEdit].currentFixed;
			} else {
				// If Default curve is ESF1 and toPen is ESF2, and from pen is ESF1
				workListData[indexOfItemToEdit].selectedCurveNumber = 1;
			}
			if (!usesPens) {
				workListData[indexOfItemToEdit].penId = penId;
				workListData[indexOfItemToEdit].isMoveLocationEsf = this.props.unitToPens.find(
					utp => utp.penId === penId,
				)
					? true
					: false;
			}
			this.props.saveWorkListDataTemporary(workListData);
		} else if (!section && indexOfItemToEdit !== -1) {
			workListData[indexOfItemToEdit].sectionId = '';
			workListData[indexOfItemToEdit].usesPens = false;
			workListData[indexOfItemToEdit].penId = '';
			this.props.saveWorkListDataTemporary(workListData);
		}
	}

	public setPenOnTableItem(item: MovingListTable, penId: string) {
		let indexOfItemToEdit = this.props.data.findIndex(a => a.stemAnimalId === item.stemAnimalId);
		if (indexOfItemToEdit !== -1) {
			const workListData = copyFlatObjectArray(this.props.data);
			const defaultFeedCurves = getCurveItemByAnimalKind(this.props.movingListSetup, item.stemAnimalId);

			const utp = this.props.unitToPens.find(p => p.penId === penId);
			const processEquipmentData = this.props.processEquipmentDatas.find(
				peq =>
					defaultFeedCurves &&
					utp &&
					peq.id === utp.processEquipmentDataId &&
					defaultFeedCurves.locationCode === ExtractLocationCode(peq.code),
			);
			workListData[indexOfItemToEdit].fromPenIsEsfLocation = PenHasSameBaseLocationCode(item.currentPenId, penId);
			if (workListData[indexOfItemToEdit].penId !== penId) {
				workListData[indexOfItemToEdit].penId = penId;
				// If Default curve and toPen is ESF1, and from pen is ESF2 or Non ESF
				if (
					processEquipmentData &&
					defaultFeedCurves &&
					!workListData[indexOfItemToEdit].fromPenIsEsfLocation
				) {
					workListData[indexOfItemToEdit].selectedCurveNumber = defaultFeedCurves.feedCurveNumber;
				} else if (
					// If Default curve and toPen is ESF1, and from pen is ESF1
					// If Default curve is ESF1 and toPen is ESF2, and from pen is ESF2
					workListData[indexOfItemToEdit].fromPenIsEsfLocation
				) {
					workListData[indexOfItemToEdit].selectedCurveNumber =
						workListData[indexOfItemToEdit].currentSelectedCurveNumber;
					workListData[indexOfItemToEdit].fixedDeviation = workListData[indexOfItemToEdit].currentFixed;
				} else {
					// If Default curve is ESF1 and toPen is ESF2, and from pen is ESF1
					workListData[indexOfItemToEdit].selectedCurveNumber = 1;
				}
				workListData[indexOfItemToEdit].isMoveLocationEsf = this.props.unitToPens.find(
					utp => utp.penId === penId,
				)
					? true
					: false;
				this.props.saveWorkListDataTemporary(workListData);
			}
		}
	}

	public render() {
		const columnExte = this.generateColumnsExtensions();
		const columns = this.generateColumns();
		return (
			<ViewWeb className="align-items-center">
				<SkioldTableGrid
					columns={columns}
					className={'work-list-table move-list-table'}
					ColumnExtensions={columnExte}
					tableKey={this.props.tableKey + columnExte.length.toString()}
					data={this.props.data}
					ref={this.setTableRef}
					sortable={!this.state.isBlankOption}
					sortHeaderId={this.state.isBlankOption ? undefined : this.defaultSorting}
					ignoreSetCount={this.state.isBlankOption}
				/>
			</ViewWeb>
		);
	}

	public generateCommitAllCheckBox = () => {
		return (
			<ViewWeb className="checkbox-view-filter-style">
				<SkioldCheckbox
					isChecked={this.state.commitAll}
					className="checkbox-style-filter"
					onClick={this.commitAllCheckBoxClicked}
				/>
			</ViewWeb>
		);
	};

	public generateColumnsExtensions() {
		let columnExtensions = [
			{
				columnName: 'cycleDays',
				width: SowListConstants.cycleDaysWidth,
			},
			{
				columnName: 'animalNumber',
				wordWrapEnabled: true,
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'sectionId',
				filteringEnabled: false,
				width: SowListConstants.animalNrWidth,
			},
		];
		if (this.props.showPen) {
			columnExtensions.push({
				columnName: 'penId',
				filteringEnabled: false,
				width: SowListConstants.animalNrWidth,
			});
		}
		if (this.props.esfExists && this.props.movingListSetup && workListUseFeedCurve(this.props.movingListSetup)) {
			columnExtensions.push({
				columnName: 'feedCurve',
				filteringEnabled: true,
				width: SowListConstants.treatmentNumberWidth,
			});
			columnExtensions.push({
				columnName: 'fixedDeviation',
				filteringEnabled: true,
				width: SowListConstants.treatmentNumberWidth,
			});
		}

		columnExtensions.push({
			columnName: 'completed',
			width: SowListConstants.entranceDateWidth,
		});

		return columnExtensions;
	}

	// Handle move lists differently depending on various setups
	private handleItemFound = (
		currentTableItem: MovingListTable,
		newtableItem: MovingListTable,
		sow: IStemAnimal,
	): MovingListTable => {
		let item = {
			...newtableItem,
			identifier: currentTableItem.identifier,
			penId: this.props.penId,
			sectionId: this.props.sectionId,
			usesPens: this.props.usesPens,
			isMoveLocationEsf: currentTableItem.isMoveLocationEsf,
		};
		if (workListUseFeedCurve(this.props.movingListSetup)) {
			// Handle moves between 2 ESF controllers
			let newUtp =
				item.penId && this.props.unitToPens && this.props.unitToPens.find(utp => utp.penId === item.penId);
			const currentUtp =
				item.currentPenId &&
				this.props.unitToPens &&
				this.props.unitToPens.find(utp => item.currentPenId && utp.penId === item.currentPenId);

			const currentStation = this.props.stations.find(
				s => currentUtp && s.unitGroupId === currentUtp.unitGroupId,
			);
			const newStation = this.props.stations.find(s => newUtp && s.unitGroupId === newUtp.unitGroupId);
			item.fromPenIsEsfLocation =
				currentStation &&
				newStation &&
				ExtractLocationCode(currentStation.code) === ExtractLocationCode(newStation.code)
					? true
					: false;

			let defaultFeedCurves = (this.props.movingListSetup as IMoveToPregnancyLocation).defaultFeedCurves;

			let curve =
				defaultFeedCurves &&
				defaultFeedCurves.find(
					d =>
						newStation &&
						d.animalKind === newtableItem.animalKind &&
						d.locationCode === ExtractLocationCode(newStation.code),
				);

			item.selectedCurveNumber = item.fromPenIsEsfLocation
				? item.currentSelectedCurveNumber
				: curve
				? curve.feedCurveNumber
				: 1;
			item.fixedDeviation = item.fromPenIsEsfLocation ? item.currentFixed : 100;
		}
		return item as MovingListTable;
	};

	private animalInput = (tableItem: MovingListTable) => {
		const animalInputChanged = (animalNumber: string) => {
			let sortedData = [...this.props.data] as MovingListTable[];
			let animalFound = this.props.activeSows.find(
				moveListItem =>
					moveListItem.animalNumber === animalNumber &&
					sortedData.find(tableData => tableData.animalNumber === animalNumber) === undefined,
			);
			let index = sortedData.findIndex(item => item.identifier === tableItem.identifier);
			// This validate if animal could be on the list
			let item =
				animalFound && animalFound.id
					? getMoveListItemByAnimal(
							animalFound,
							this.props.pregnancyEvets[animalFound.id],
							this.props.moveEvents.filter(move => animalFound && move.stemAnimalId === animalFound.id),
							new LocationModel(this.props.locations),
							this.props.movingListSetup.type,
							this.props.unitToPens,
							this.props.esfFeedingStatus,
							this.props.penId,
							this.props.validateSettingToSubtract,
					  )
					: undefined;
			if (animalFound && animalFound.id && item) {
				sortedData[index] = this.handleItemFound(tableItem, item, animalFound);
				// If animal is inserted, add new line to the list
				if (sortedData[sortedData.length - 1].animalNumber) {
					let newUtp =
						this.props.penId &&
						this.props.unitToPens &&
						this.props.unitToPens.find(utp => utp.penId === this.props.penId);
					sortedData.push(
						new MovingListTable({
							identifier: new ObjectID().toHexString(),
							sectionId: this.props.sectionId,
							penId: this.props.penId,
							usesPens: this.props.usesPens,
							isMoveLocationEsf: newUtp ? true : false,
						} as any),
					);
				}
			} else {
				sortedData[index] = new MovingListTable({
					identifier: tableItem.identifier,
					animalNumber: animalNumber,
					sectionId: this.props.sectionId,
					penId: this.props.penId,
					usesPens: this.props.usesPens,
					isMoveLocationEsf: tableItem.isMoveLocationEsf,
				} as any);
			}
			this.props.saveWorkListDataTemporary(sortedData);
		};

		// Remove item onBlur if input is empty
		const onBlur = () => {
			let sortedData = [...this.props.data] as MovingListTable[];
			let index = sortedData.findIndex(item => item.identifier === tableItem.identifier);
			if (tableItem.animalNumber !== '0' && !tableItem.animalNumber && index !== this.props.data.length - 1) {
				sortedData.splice(index, 1);
				this.props.saveWorkListDataTemporary(sortedData);
			}
		};

		return <SkioldFormInput onChangeText={animalInputChanged} text={tableItem.animalNumber} onBlur={onBlur} />;
	};

	// If isBlankOption = true, we dont want to sort,
	// because the last item always is empty, and therefore will ruin the blank list
	// If it is necessary a custom sortFunc is acquired
	private generateColumns() {
		let columns: any[] = [
			{
				name: 'cycleDays',
				title: localized('Days'),
				width: SowListConstants.cycleDaysWidth,
				filterFunction: rangeFilterMethodGrid,
				sortable: !this.state.isBlankOption,
				getCellValue: (d: MovingListTable) =>
					d.cycleDays && this.props.movingListSetup && this.props.movingListSetup.negativeCycleDays
						? d.negativeCycleDays
						: d.cycleDays,
			},
			{
				name: 'animalNumber',
				title: localized('animalNumber'),
				width: SowListConstants.animalNrWidth,
				sortable: !this.state.isBlankOption,
				className: getStemAnimalNumberColor,
				getCellValue: this.state.isBlankOption ? this.animalInput : getAnimalNumberText,
			},
		];

		if (this.props.esfExists && this.props.movingListSetup && workListUseFeedCurve(this.props.movingListSetup)) {
			columns.push({
				name: 'feedCurve',
				title: localized('feedCurve'),
				sortable: !this.state.isBlankOption,
				getCellValue: this.FeedCurvePicker,
			});
			columns.push({
				name: 'fixedDeviation',
				title: localized('fixedPercent'),
				sortable: !this.state.isBlankOption,
				getCellValue: this.FixedDeviationInput,
			});
		}

		columns.push({
			name: 'sectionId',
			title: localized('location'),
			sortable: !this.state.isBlankOption,
			getCellValue: (d: MovingListTable) => (
				<SkioldStableSectionPicker
					theme={'dark'}
					onStableSectionSelected={(selectedSectionId: string) => {
						if (
							this.props.filteredLocations &&
							selectedSectionId !== d.sectionId &&
							(d.sectionId || selectedSectionId)
						) {
							this.setSectionOnTableItem(
								d,
								this.props.filteredLocations.sections.find(
									section => section.id === selectedSectionId,
								)!,
							);
						}
					}}
					usedInsideTable={true}
					filteredLocations={this.props.filteredLocations}
					sectionId={d.sectionId}
					isPigProduction={true}
					dropdownIdentifier={'move-list-section-picker'}
				/>
			),
		});

		if (this.props.showPen) {
			columns.push({
				name: 'penId',
				sortable: !this.state.isBlankOption,
				width: SowListConstants.animalNrWidth,
				title: localized('pen'),
				getCellValue: (d: MovingListTable) => (
					<ViewWeb>
						{d.usesPens && (
							<SkioldFormPenPicker
								theme={'dark'}
								key={d.stemAnimalId + 'pen'}
								usedInsideTable={true}
								onPenSelected={(selectedPenId: string) => this.setPenOnTableItem(d, selectedPenId)}
								filteredLocations={this.props.filteredLocations}
								filteredPens={this.props.filteredLocations ? this.props.filteredLocations.pens : []}
								sectionId={d.sectionId}
								penId={d.penId ? d.penId : undefined}
								dropdownIdentifier={'move-list-pen-picker'}
							/>
						)}
					</ViewWeb>
				),
			});
		}
		columns.push({
			name: 'completed',
			title: localized('completed'),
			headerClassName: 'merged-header',
			width: SowListConstants.treatmentNumberWidth,
			sortable: false,
			filter: this.generateCommitAllCheckBox,
			getCellValue: (d: MovingListTable) => (
				<SkioldCheckbox
					isChecked={d.isChecked}
					onClick={async () => {
						let workListData = [...this.props.data];
						let dataIndex = workListData.findIndex(a => a.stemAnimalId === d.stemAnimalId);
						workListData[dataIndex].isChecked = workListData[dataIndex].isChecked ? false : true;
						this.props.saveWorkListDataTemporary(workListData);
						this.props.SetCheckedCount(workListData[dataIndex].isChecked ? 1 : -1);
						this.setState({ commitAll: false });
					}}
				/>
			),
		});

		return columns;
	}
	private FixedDeviationInput = (tableItem: MovingListTable) => {
		const fixedDeviationChanged = (value: number | undefined) => {
			let dataCopy = copyFlatObjectArray(this.props.data) as MovingListTable[];
			let dataIndex = dataCopy.findIndex(dataItem => dataItem.stemAnimalId === tableItem.stemAnimalId);
			let workListDataCopy = { ...dataCopy[dataIndex] } as MovingListTable;
			workListDataCopy.fixedDeviation = value;
			workListDataCopy.isChecked = false;
			dataCopy[dataIndex] = workListDataCopy;
			this.props.saveWorkListDataTemporary(dataCopy);
		};
		return tableItem.isMoveLocationEsf ? (
			<SkioldFormIntegerInput
				className={'move-list--table-input'}
				onChangeNumber={fixedDeviationChanged}
				text={tableItem.fixedDeviation}
			/>
		) : (
			<ViewWeb />
		);
	};
	private FeedCurvePicker = (tableItem: MovingListTable) => {
		const curveChanged = (feedCurveNumber: number | undefined) => {
			let dataCopy = copyFlatObjectArray(this.props.data) as MovingListTable[];
			let dataIndex = dataCopy.findIndex(dataItem => dataItem.stemAnimalId === tableItem.stemAnimalId);
			let workListDataCopy = { ...dataCopy[dataIndex] } as MovingListTable;
			workListDataCopy.selectedCurveNumber = feedCurveNumber;
			workListDataCopy.isChecked = false;
			dataCopy[dataIndex] = workListDataCopy;
			this.props.saveWorkListDataTemporary(dataCopy);
		};
		return tableItem.isMoveLocationEsf ? (
			<SkioldFeedCurvePicker
				usedInsideTable={true}
				feedCurveNumber={tableItem.selectedCurveNumber}
				penId={tableItem.penId}
				onChange={curveChanged}
				dropdownIdentifier={'move-list-curve-picker'}
				theme={'dark'}
				className={'inTable'}
				containerClassName="grade-dropdown"
			/>
		) : (
			<ViewWeb />
		);
	};

	private setTableRef = (m: any) => {
		if (m) {
			this.SkioldTableRef = m;
		}
	};
	private commitAllCheckBoxClicked = () => {
		let data = [...this.props.data];
		let dataInTable = [];
		let commitAll = !this.state.commitAll;
		if (this.SkioldTableRef) {
			dataInTable = this.SkioldTableRef.GetSortedData();
		}
		if (this.SkioldTableRef && data.length !== dataInTable.length) {
			dataInTable.forEach((element: MovingListTable) => {
				let indexToUpdate = data.findIndex(a => element.stemAnimalId === a.stemAnimalId);
				if (indexToUpdate !== -1) {
					data[indexToUpdate].isChecked = commitAll;
				}
			});
		} else {
			for (const tableItem of data) {
				tableItem.isChecked = commitAll;
			}
		}
		this.props.SetCheckedCount(
			data.reduce((a, b) => a + (b && b.isChecked ? 1 : 0), 0),
			true,
		);
		this.props.saveWorkListDataTemporary(data);
		this.setState({ commitAll });
	};
}

export default connect<ReturnType<typeof mapStateToProps>, ReturnType<typeof mapDispatchToProps>, RefType, WebAppState>(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{ forwardRef: true },
)(MoveToListTable);
