import { Action } from 'redux';
import { DistriwinOption } from 'shared/api/api';
import { SyncableSingleInitialState } from 'shared/state/models/syncable';
import { SharedAppState } from 'shared/state/store.shared';
import { isType } from 'typescript-fsa';
import { siteChange } from '../profile/actions';
import { getSyncModelData } from '../sync/actions';
import * as actions from './actions';
import { DistriwinOptionState } from './types';

export const initialState: DistriwinOptionState = {
	...SyncableSingleInitialState,
};

const distriwinOptionReducer = (state: DistriwinOptionState = initialState, action: Action): DistriwinOptionState => {
	if (isType(action, actions.getSyncData.started)) {
		state = { ...state, syncInProgress: true };
	}

	if (isType(action, getSyncModelData.done)) {
		if (action.payload.result.distriwinOption && action.payload.result.distriwinOption.data) {
			state = {
				...state,
				entity: { ...state.entity, ...action.payload.result.distriwinOption.data },
				lastSyncDate: action.payload.result.distriwinOption.syncTime!,
			};
		}

		state = {
			...state,

			syncInProgress: false,
		};
	}

	if (isType(action, actions.getSyncData.done)) {
		if (action.payload.result.data) {
			state = {
				...state,
				entity: { ...state.entity, ...action.payload.result.data },
			};
		}

		state = {
			...state,
			lastSyncDate: action.payload.result.syncTime!,
			syncInProgress: false,
		};
	}

	if (isType(action, actions.getSyncData.failed)) {
		state = { ...state, syncInProgress: false };
	}

	if (isType(action, siteChange.done)) {
		state = initialState;
	}

	//Ensure Date objects are in fact date and not strings - redux-persist serializes dates to string but doesn't deserialize to dates again
	if (action.type === 'persist/REHYDRATE') {
		let a = (action as any) as { payload: SharedAppState; key: string };
		if (a.key === 'root') {
			let entity = DistriwinOption.fromJS({});
			let update = DistriwinOption.fromJS({});
			let lastSyncDate = new Date(-8640000000000000);

			if (a.payload && a.payload.distriwinOption && a.payload.generalSettings.entity) {
				let ndt = DistriwinOption.fromJS({});
				ndt.init(a.payload.distriwinOption.entity);
				entity = ndt;
			}

			if (a.payload && a.payload.distriwinOption && a.payload.distriwinOption.update) {
				let ndt = DistriwinOption.fromJS({});
				ndt.init(a.payload.distriwinOption.update);
				update = ndt;
			}

			if (a.payload && a.payload.distriwinOption && a.payload.distriwinOption.lastSyncDate) {
				lastSyncDate = new Date(a.payload.distriwinOption.lastSyncDate);
			}

			state = { ...state, entity, update, lastSyncDate };
		}
	}

	return state;
};

export default distriwinOptionReducer;
