import {
	FileResponse,
	IResultAnalysisFarrowingsTableItem,
	IResultAnalysisMatingsTableItem,
	ResultAnalysisFarrowingsTableItem,
	ResultAnalysisMatingsTableItem,
	SkioldOneClient,
	IResultAnalysisWeaningsTableItem,
	ResultAnalysisWeaningsTableItem,
	IResultPerLitterTableItem,
	ResultPerLitterTableItem,
	IResultYoungFemaleAgeTableItem,
	ResultYoungFemaleAgeTableItem,
	AnalysisResultYoungFemaleAgePeriod,
	IAnalysisPregnancyTableData,
	AnalysisPregnancyTableData,
	AnalysisMatingPregnancyTableData,
	IAnalysisMatingPregnancyTableData,
	AnalysisMatingPregnancyTotalMatingTableItem,
	PeriodDto,
	ResultAnalysisFarrowingsData,
	ResultAnalysisMatingsData,
	AnalysisMatingPregnancyTotalMatingData,
	ResultAnalysisWeaningsData,
	ResultPerLitterData,
	ResultYoungFemaleAgeData,
	AnalysisPregnancyData,
	AnalysisMatingPregnancyData,
	IAnalysisDepartureSowTableData,
	AnalysisDepartureSowTableData,
	AnalysisDepartureGpeTableData,
	IAnalysisDepartureGpeTableData,
} from 'shared/api/api';
import { SkioldDigitalApiBaseUrl } from 'shared/constants';
import { getAnimalNumberColor } from 'shared/helpers/treatment-helper/treatment-helper';
import { savePdfAs } from '../general-pdf-helper';
import { getDateString } from 'shared/helpers/date-helpers';

export const generatePeriodDto = (dateFrom?: Date, dateTo?: Date) =>
	new PeriodDto({ dateFrom: getDateString(dateFrom), dateTo: getDateString(dateTo) });

export function GetAnalysisFarrowingsPdf(
	items: IResultAnalysisFarrowingsTableItem[],
	fileName: string,
	siteId: string,
	timeZoneId?: string,
	lang?: string,
	periodDto?: PeriodDto,
) {
	HandleCellColorStyle(items);
	return new SkioldOneClient(SkioldDigitalApiBaseUrl)
		.analysisPdf_GetResultPerLitterFarrowingsItemPdf(
			{
				tableModels: items as ResultAnalysisFarrowingsTableItem[],
				period: periodDto,
			} as ResultAnalysisFarrowingsData,
			siteId,
			timeZoneId,
			lang,
		)
		.then((fileResponse: FileResponse | null) => {
			savePdfAs(fileResponse, fileName);
		})
		.catch(() => {
			return;
		});
}

export function GetAnalysisMatingsPdf(
	items: IResultAnalysisMatingsTableItem[],
	fileName: string,
	siteId: string,
	timeZoneId?: string,
	lang?: string,
	periodDto?: PeriodDto,
) {
	HandleCellColorStyle(items);
	return new SkioldOneClient(SkioldDigitalApiBaseUrl)
		.analysisPdf_GetTotalMatingTableItemPdf(
			{ tableModels: items as ResultAnalysisMatingsTableItem[], period: periodDto } as ResultAnalysisMatingsData,
			siteId,
			timeZoneId,
			lang,
		)
		.then((fileResponse: FileResponse | null) => {
			savePdfAs(fileResponse, fileName);
		})
		.catch(() => {
			return;
		});
}

export function GetAnalysisMatingsForTotalMatingPregnancyPdf(
	items: IResultAnalysisMatingsTableItem[],
	fileName: string,
	siteId: string,
	timeZoneId?: string,
	lang?: string,
	periodDto?: PeriodDto,
) {
	HandleCellColorStyle(items);
	return new SkioldOneClient(SkioldDigitalApiBaseUrl)
		.analysisPdf_GetTotalMatingTableItemPdf2(
			{
				tableModels: items as AnalysisMatingPregnancyTotalMatingTableItem[],
				period: periodDto,
			} as AnalysisMatingPregnancyTotalMatingData,
			siteId,
			timeZoneId,
			lang,
		)
		.then((fileResponse: FileResponse | null) => {
			savePdfAs(fileResponse, fileName);
		})
		.catch(() => {
			return;
		});
}

export function GetAnalysisWeaningsPdf(
	items: IResultAnalysisWeaningsTableItem[],
	fileName: string,
	siteId: string,
	timeZoneId?: string,
	lang?: string,
	periodDto?: PeriodDto,
) {
	HandleCellColorStyle(items);
	return new SkioldOneClient(SkioldDigitalApiBaseUrl)
		.analysisPdf_GetResultPerLitterWeaningsItemPdf(
			{
				tableModels: items as ResultAnalysisWeaningsTableItem[],
				period: periodDto,
			} as ResultAnalysisWeaningsData,
			siteId,
			timeZoneId,
			lang,
		)
		.then((fileResponse: FileResponse | null) => {
			savePdfAs(fileResponse, fileName);
		})
		.catch(() => {
			return;
		});
}

export function GetResultPerLitterPdf(
	items: IResultPerLitterTableItem[],
	fileName: string,
	siteId: string,
	timeZoneId?: string,
	lang?: string,
	periodDto?: PeriodDto,
) {
	HandleCellColorStyle(items);
	return new SkioldOneClient(SkioldDigitalApiBaseUrl)
		.analysisPdf_GetResultsPerLitterPdf(
			{ tableModels: items as ResultPerLitterTableItem[], period: periodDto } as ResultPerLitterData,
			siteId,
			timeZoneId,
			lang,
		)
		.then((fileResponse: FileResponse | null) => {
			savePdfAs(fileResponse, fileName);
		})
		.catch(() => {
			return;
		});
}

export function GetResultYoungFemaleAgePdf(
	items: IResultYoungFemaleAgeTableItem[],
	fileName: string,
	siteId: string,
	timeZoneId?: string,
	lang?: string,
	agePeriods?: AnalysisResultYoungFemaleAgePeriod[],
	periodDto?: PeriodDto,
) {
	HandleCellColorStyle(items);
	return new SkioldOneClient(SkioldDigitalApiBaseUrl)
		.analysisPdf_GetResultsYoungAnimalAgePdf(
			{
				tableModels: items.filter(e => e.agePeriod1 !== undefined) as ResultYoungFemaleAgeTableItem[],
				period: periodDto,
			} as ResultYoungFemaleAgeData,
			siteId,
			timeZoneId,
			agePeriods,
			lang,
		)
		.then((fileResponse: FileResponse | null) => {
			savePdfAs(fileResponse, fileName);
		})
		.catch(() => {
			return;
		});
}

export function GetAnalysisMatingPregnancyPdf(
	items: IAnalysisPregnancyTableData[],
	fileName: string,
	siteId: string,
	timeZoneId?: string,
	lang?: string,
	periodDto?: PeriodDto,
) {
	return new SkioldOneClient(SkioldDigitalApiBaseUrl)
		.analysisPdf_GetAnalysisResultPregnancyPdf(
			{ tableModels: items as AnalysisPregnancyTableData[], period: periodDto } as AnalysisPregnancyData,
			siteId,
			timeZoneId,
			lang,
		)
		.then((fileResponse: FileResponse | null) => {
			savePdfAs(fileResponse, fileName);
		})
		.catch(() => {
			return;
		});
}

export function GetAnalysisMatingPregnancyElaborationPdf(
	items: IAnalysisMatingPregnancyTableData[],
	fileName: string,
	siteId: string,
	dataMode: string,
	timeZoneId?: string,
	lang?: string,
	periodDto?: PeriodDto,
) {
	return new SkioldOneClient(SkioldDigitalApiBaseUrl)
		.analysisPdf_GetAnalysisMatingPregnancyTableDataPdf(
			{
				tableModels: items as AnalysisMatingPregnancyTableData[],
				period: periodDto,
			} as AnalysisMatingPregnancyData,
			siteId,
			timeZoneId,
			dataMode,
			lang,
		)
		.then((fileResponse: FileResponse | null) => {
			savePdfAs(fileResponse, fileName);
		})
		.catch(() => {
			return;
		});
}

type AnalysisTableItemPdf = IResultAnalysisFarrowingsTableItem | IResultAnalysisMatingsTableItem;

function HandleCellColorStyle(items: AnalysisTableItemPdf[]) {
	items.forEach(item => {
		if (item.sowId) {
			item.cellColorStyle = getAnimalNumberColor(item.sowId);
		}
	});
}

export function GetAnalysisDepartureSowPdf(
	data: IAnalysisDepartureSowTableData,
	fileName: string,
	siteId: string,
	asPercentage: boolean,
	timeZoneId?: string,
	lang?: string,
) {
	return new SkioldOneClient(SkioldDigitalApiBaseUrl)
		.analysisPdf_GetAnalysisDepartureSowPdf(
			data as AnalysisDepartureSowTableData,
			siteId,
			timeZoneId,
			asPercentage,
			lang,
		)
		.then((fileResponse: FileResponse | null) => {
			savePdfAs(fileResponse, fileName);
		})
		.catch(() => {
			return;
		});
}

export function GetAnalysisDepartureGpePdf(
	data: IAnalysisDepartureGpeTableData,
	fileName: string,
	siteId: string,
	asPercentage: boolean,
	timeZoneId?: string,
	lang?: string,
) {
	return new SkioldOneClient(SkioldDigitalApiBaseUrl)
		.analysisPdf_GetAnalysisDepartureGpePdf(
			data as AnalysisDepartureGpeTableData,
			siteId,
			timeZoneId,
			asPercentage,
			lang,
		)
		.then((fileResponse: FileResponse | null) => {
			savePdfAs(fileResponse, fileName);
		})
		.catch(() => {
			return;
		});
}
