import React, { FC } from 'react';
import { Option } from 'react-dropdown';
import { LocationType } from 'shared/api/api';
import {
	FindHiddenPenOnSection,
	getFilteredLocationsByTypesAndNoPens,
	getStableSectionOptionByPenId,
} from 'shared/helpers/location-helper';
import { getStableSectionOptions } from 'shared/helpers/location-helpers/stable-section-helper';
import { localized } from 'shared/state/i18n/i18n';
import { SkioldFormDropdown } from 'web/view/components/skiold-components/skiold-dropdown/skiold-form-dropdown';
import { SkioldFormsWrapper } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { useGeneralSetting } from './general-settings-overview';

interface Props {}

export const WeaningSetting: FC<Props> = React.memo(() => {
	const { generalSetting, setGeneralSetting } = useGeneralSetting();
	const getFormRows = () => {
		let formRows = new Array<FormRow>();

		formRows.push(getWorkListHeader());
		formRows.push(getStableSectionPickerRow());
		return formRows;
	};

	const getWorkListHeader = (): FormRow => {
		return {
			header: localized('Weanings'),
		};
	};

	const getStableSectionPickerRow = (): FormRow => {
		const { buildings, sections } = getFilteredLocationsByTypesAndNoPens([LocationType.WeanedPiglets]);
		const locationOption = getStableSectionOptionByPenId(generalSetting.weanedPenId);
		return {
			name: localized('WeanedPigsToLocation'),
			component: (
				<>
					<SkioldFormDropdown
						items={getStableSectionOptions(buildings, sections)}
						onValueChanged={onStableSectionChanged}
						selectedValue={locationOption}
					/>
				</>
			),
		};
	};

	const onStableSectionChanged = (selectedSection: Option) => {
		let penId = FindHiddenPenOnSection(selectedSection.value);
		if (penId) {
			setGeneralSetting({ ...generalSetting, weanedPenId: penId, weanedSectionId: selectedSection.value });
		}
	};

	return <SkioldFormsWrapper formRows={getFormRows()} containerClassName="wrapper-container" />;
});
