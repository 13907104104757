import * as React from 'react';
import { i18nTranslationKey, localized } from 'shared/state/i18n/i18n';
import { SkioldImage } from '../../utils/svg/skiold-image';
import './menu-item.scss';
import { SkioldTouchableOpacity } from '../../skiold-components/skiold-touchable-opacity';

interface OverlayMenuItemProps {
	svgXmlDataOn?: any;
	svgXmlDataOff: any;
	name: i18nTranslationKey;
	textColor?: string;
	textColorOn?: string;
	style?: any;
	itemFromParent?: any;
	onClick: (itemFromParent?: any) => void;
	className?: string;
	showAlert?: boolean;
}

export interface OverlayMenuItemState {
	svgXmlData: any;
	textStyle?: React.CSSProperties;
}

export class OverlayMenuItem extends React.PureComponent<OverlayMenuItemProps, OverlayMenuItemState> {
	constructor(props: OverlayMenuItemProps) {
		super(props);

		this.mouseOut = this.mouseOut.bind(this);
		this.mouseOver = this.mouseOver.bind(this);
	}

	public UNSAFE_componentWillMount() {
		this.setState({
			svgXmlData: this.props.svgXmlDataOff,
			textStyle: { color: this.props.textColor || 'white' },
		});
	}

	public mouseOut = () => {
		this.setState({
			svgXmlData: this.props.svgXmlDataOff,
			textStyle: { color: this.props.textColor || 'white' },
		});
	};

	public mouseOver = () => {
		this.setState({
			svgXmlData: this.props.svgXmlDataOn || this.props.svgXmlDataOff,
			textStyle: { color: this.props.textColorOn || this.props.textColor || 'white' },
		});
	};

	public render() {
		const webOnlyProps = {
			onMouseEnter: this.mouseOver,
			onMouseLeave: this.mouseOut,
			alt: this.props.name ? localized(this.props.name) : '',
		};

		return (
			<div
				className={this.props.className + ' menu-item'}
				onMouseEnter={this.mouseOver}
				onMouseLeave={this.mouseOut}
			>
				<SkioldTouchableOpacity itemFromParent={this.props.itemFromParent} onPress={this.props.onClick}>
					{this.props.showAlert && <div className="redicon" />}
					<SkioldImage
						width={100}
						height={50}
						imageData={this.state.svgXmlData}
						mode={'contain'}
						className="image-style"
						{...webOnlyProps}
					/>
					<span className="menu-name-label" style={this.state.textStyle}>
						{this.props.name ? localized(this.props.name) : ''}
					</span>
				</SkioldTouchableOpacity>
			</div>
		);
	}
}
