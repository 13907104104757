import React from 'react';
import { connect } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import {
	AnalysisResultYoungFemaleAge,
	ElaborationResultYoungFemaleAgeTableItem,
	IAnalysisResultYoungFemaleAgeSetting,
	IElaborationResultYoungFemaleAgeTableData,
	IElaborationResultYoungFemaleAgeTableItem,
	IResultPerLitterTableItem,
	ResultPerLitterTableItem,
} from 'shared/api/api';
import { RefType } from 'shared/helpers/ref-type';
import { localized } from 'shared/state/i18n/i18n';
import styled from 'styled-components';
import { WebAppState } from 'web/state/store.web';
import SkioldTableGrid, {
	SkioldTableGrid as SkioldTableRef,
} from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { GetResultPerLitterPdf } from 'web/web-helpers/pdf-helper/analysis-pdf-helpers/analysis-pdf-helper';
import { getAgePeriodTitle } from '../analysis-result-young-female-age-helper';

const OuterDiv = styled.div`
	margin: 16px 0;
`;

interface PropsFromParent {
	setting?: IAnalysisResultYoungFemaleAgeSetting;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		items: state.resultYoungFemaleAge.elaborationdata ? state.resultYoungFemaleAge.elaborationdata : {},
		loading: state.resultYoungFemaleAge.loadingElaboration,
		profile: state.profile.active!,
	};
};

export interface State {
	loading: boolean;
	modalOpen: boolean;
	modalSelected?: string;
	batchId?: string;
	count?: string;
}

type Props = ReturnType<typeof mapStateToProps> & PropsFromParent;

export class AnalysisResultsYoungFemaleAgeModalTable extends React.PureComponent<Props, State> {
	public SkioldTableRef: SkioldTableRef | undefined;
	private KeysWithModals: string[] = ['TotalMatings', 'TotalFarrowings', 'AllWeanings'];
	private columnsIds = {
		name: 'name',
		agePeriod1: AnalysisResultYoungFemaleAge.AgePeriod1,
		agePeriod2: AnalysisResultYoungFemaleAge.AgePeriod2,
		agePeriod3: AnalysisResultYoungFemaleAge.AgePeriod3,
		agePeriod4: AnalysisResultYoungFemaleAge.AgePeriod4,
		agePeriod5: AnalysisResultYoungFemaleAge.AgePeriod5,
		total: 'total',
	};
	private readonly inlineHeaders = ['Matings', 'Farrowings', 'Weanings'];

	private GetNameField = (d: ResultPerLitterTableItem) =>
		d.name ? (
			<div
				className={
					'textAlignStart' +
					(this.IndentedFields.includes(d.name) ? ' paddingLeftTwenty' : '') +
					(this.inlineHeaders.includes(d.name) ? ' bold' : '')
				}
			>
				{localized('AnalysisResult.' + d.name)}
			</div>
		) : (
			' '
		);

	constructor(props: Props) {
		super(props);
		this.state = {
			loading: false,
			modalOpen: false,
			modalSelected: undefined,
			batchId: undefined,
		};
	}

	public generateColumnsExtensions(headers: { [key: string]: { [key: string]: number[] } } | undefined) {
		let columnsExt = [
			{
				columnName: this.columnsIds.name,
				width: 400,
			},
		];

		if (headers) {
			Object.keys(headers).forEach(agePeriod => {
				Object.keys(headers[agePeriod]).forEach(age => {
					columnsExt = columnsExt.concat(
						headers[agePeriod][age].map(litter => {
							return { columnName: agePeriod + age + litter.toString(), width: 100 };
						}),
					);
				});
			});
		}
		columnsExt.push({ columnName: 'Total', width: 100 });

		return columnsExt;
	}

	public render() {
		return (
			<ViewWeb>
				<OuterDiv>
					{this.props.loading ? (
						<ViewWeb className="spinner-container-active-analysis">
							<ClipLoader color="#f2ac40" size={70} />
						</ViewWeb>
					) : (
						<ViewWeb className="analysis-overview-table analysis-overview-modal-table">
							{this.props.items && (
								<SkioldTableGrid
									columns={this.generateColumns(this.props.items.headers)}
									groupedColumns={this.generateGroupedColumns(this.props.items.headers)}
									ref={this.setRef}
									resizeAbleEnabled={false}
									InlineHeaders={this.inlineHeaders}
									data={this.GenerateData(this.props.items)}
									ColumnExtensions={this.generateColumnsExtensions(this.props.items.headers)}
									tableKey="analysisYoungFemaleAgeOverviewModalTable"
									sortable={false}
									ignoreSetCount={true}
									filterable={false}
									showPagination={true}
									IsInModal={true}
									lineHeightValue={20}
								/>
							)}
						</ViewWeb>
					)}
				</OuterDiv>
			</ViewWeb>
		);
	}
	private generateGroupedColumns(headers: { [key: string]: { [key: string]: number[] } } | undefined) {
		if (headers && this.props.setting) {
			return Object.keys(headers).map(agePeriod => {
				return {
					title: getAgePeriodTitle(agePeriod, this.props.setting),
					children: Object.keys(headers[agePeriod]).map(age => {
						return {
							title: age,
							children: headers[agePeriod][age].map(litter => {
								return { columnName: agePeriod + age + litter.toString() };
							}),
						};
					}),
				};
			});
		} else {
			return [];
		}
	}

	public printData = () => {
		if (this.SkioldTableRef) {
			let data = this.SkioldTableRef.GetSortedData();
			GetResultPerLitterPdf(
				data,
				'ResultPerLitter.pdf',
				this.props.profile.siteId!,
				Intl.DateTimeFormat().resolvedOptions().timeZone,
				this.props.profile.language,
			);
		}
	};
	private matingsHeader = { Matings: new ResultPerLitterTableItem({ name: 'Matings' } as IResultPerLitterTableItem) };
	private farrowingHeader = {
		Farrowings: new ResultPerLitterTableItem({ name: 'Farrowings' } as IResultPerLitterTableItem),
	};
	private weaningHeader = {
		Weanings: new ResultPerLitterTableItem({ name: 'Weanings' } as IResultPerLitterTableItem),
	};
	private GenerateData = (data: IElaborationResultYoungFemaleAgeTableData) => {
		let d: { [key: string]: IElaborationResultYoungFemaleAgeTableItem } = {};
		if (data['matings']) {
			d = { ...d, ...this.matingsHeader, ...data['matings'] };
		}
		if (data['farrowings']) {
			d = { ...d, ...this.farrowingHeader, ...data['farrowings'] };
		}
		if (data['weanings']) {
			d = { ...d, ...this.weaningHeader, ...data['weanings'] };
		}
		return Object.keys(d)
			.filter(key => key !== 'Litter' && key !== 'TotalWeanedPiglets' && key !== 'Age')
			.map(key => {
				return new ElaborationResultYoungFemaleAgeTableItem({ ...d[key], name: key });
			});
	};

	private setRef = (m: any) => {
		if (m) {
			this.SkioldTableRef = m;
		}
	};

	private generateColumns(headers: { [key: string]: { [key: string]: number[] } } | undefined) {
		let columns: any[] = [];
		if (headers) {
			Object.keys(headers).forEach(agePeriod => {
				Object.keys(headers[agePeriod]).forEach(age => {
					columns = columns.concat(
						headers[agePeriod][age].map(litter => {
							const getLitterCellValue = (tableItem: ElaborationResultYoungFemaleAgeTableItem) =>
								this.GetLitterCellValue(tableItem, agePeriod, age, litter.toString());
							return {
								name: agePeriod + age + litter.toString(),
								title: litter.toString(),
								getCellValue: getLitterCellValue,
							};
						}),
					);
				});
			});
			columns.unshift({
				name: this.columnsIds.name,
				title:
					columns.length > 0 ? (
						<div className={'flexDirectionRow positionMatingHeader width100 all-capitalized'}>
							<div className="paddingTopFiveAndPaddingLeftEight">
								{localized('ResultYoungFemaleFirstMating')}
							</div>
							<div className="headerWithRows positionMatingRightHeader positionCorrectly">
								<div>{localized('AgePeriod')}:</div>
								<div>{localized('Weeks')}:</div>
								<div>{localized('LitterNr')}:</div>
							</div>
						</div>
					) : (
						localized('ResultYoungFemaleFirstMating')
					),
				isFixedLeft: true,
				getCellValue: this.GetNameField,
			});
			columns.push({
				name: 'Total',
				title:
					columns.length > 1 ? (
						<div className={'totalColumnHeader'}>{localized('Total')}</div>
					) : (
						localized('Total')
					),
				getCellValue: (tableItem: ElaborationResultYoungFemaleAgeTableItem) =>
					tableItem.total ? tableItem.total : ' ',
			});
		}
		return columns;
	}
	private GetLitterCellValue = (
		tableItem: ElaborationResultYoungFemaleAgeTableItem,
		agePeriod: string,
		age: string,
		litter: string,
	) => {
		return tableItem[agePeriod] && tableItem[agePeriod][age] && tableItem[agePeriod][age][litter]
			? Math.round(tableItem[agePeriod][age][litter] * 10) / 10
			: '';
	};

	private IndentedFields = ['AllFinishedLitters', 'AllNurseWeanings'];
}

export default connect<ReturnType<typeof mapStateToProps>, null, RefType, WebAppState>(mapStateToProps, null, null, {
	forwardRef: true,
})(AnalysisResultsYoungFemaleAgeModalTable);
