import { Syncable } from 'shared/state/models/syncable';
import { IStemAnimal, IFeedingStatusUpdate, Mated, Gender, IYoungFemaleReportData } from 'shared/api/api';

export const SYNC = 'STEM_ANIMAL_SYNC';
export const SAVE_STEM_ANIMAL = 'SAVE_STEM_ANIMAL';
export const UPDATE_STEM_ANIMAL = 'UPDATE_STEM_ANIMAL';
export const HANDLE_STEM_ANIMAL_DEPARTURE = 'HANDLE_STEM_ANIMAL_DEPARTURE';
export const UPLOAD_IMPORT_DATA = 'UPLOAD_IMPORT_DATA';
export const SAVE_STEM_ANIMAL_NO_UPDATE = 'SAVE_STEM_ANIMAL_NO_UPDATE';
export const SAVE_BOAR_NO_ON_FARM = 'SAVE_BOAR_NO_ON_FARM';
export const SYNC_DATA_STEMANIMALS = 'SYNC_DATA_STEMANIMALS';
export const SET_SOWS_COUNT = 'SET_SOWS_COUNT';
export const SET_CHECKED_COUNT = 'SET_CHECKED_COUNT';
export const GET_DEPARTURED_ANIMALS = 'GET_DEPARTURED_ANIMALS';
export const SAVE_ENTRANCE_FEEDING_STATUS = 'SAVE_ENTRANCE_FEEDING_STATUS';
export const REMOVE_ENTRANCE_FEEDING_STATUS = 'REMOVE_ENTRANCE_FEEDING_STATUS';
export const FETCH_SINGLE_ANIMAL_INFO = 'FETCH_SINGLE_ANIMAL_INFO';
export const DELETE_YOUNG_ANIMALS = 'DELETE_YOUNG_ANIMALS';
export const UPDATE_YOUNG_ANIMAL = 'UPDATE_YOUNG_ANIMAL';
export const UPSERT_MANY_YOUNG_ANIMAL = 'UPSERT_MANY_YOUNG_ANIMAL';
export const GET_ALL_ID_NUMBERS = 'GET_ALL_ID_NUMBERS';
export const GET_DEPARTED_ANIMAL_DATA = 'GET_DEPARTED_ANIMAL_DATA';
export const GET_YOUNG_FEMALES_BY_DATE = 'GET_YOUNG_FEMALES_BY_DATE';

// tslint:disable-next-line: interface-name
export interface IStemAnimalDepartured {
	animalNumber: string;
	departureDate: Date;
	race?: string;
	id?: string;
	gender?: Gender;
	idNumber?: string;
}

export interface StemAnimalState extends Syncable<IStemAnimal> {
	sowsCount: number;
	checkedCount: number;
	departuredAnimals: Array<IStemAnimal | IStemAnimalDepartured>;
	// Since FeedingStatus is generated on ESF, we have to use this for offline purposes
	entranceEsfFeedingStatus: IFeedingStatusUpdate[];
	updatesYoungAninal: IStemAnimal[];
	youngFemaleReportData: IYoungFemaleReportData | undefined;
	saveSyncYoungAnimal: boolean;
	allIdNumbers: string;
}

export interface CombinedEntranceAndMated {
	stemAnimal: IStemAnimal;
	mated: Mated;
}
