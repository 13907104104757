import React from 'react';
import { Option } from 'react-dropdown';
import { connect } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { Dispatch } from 'redux';
import { Equipment, IProcessEquipment } from 'shared/api/api';
import { FetchLocations, GetProcessEquipmentData } from 'shared/state/ducks/process-equipment-data/operations';
import * as UnitsToPenOperations from 'shared/state/ducks/unit-to-pen/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import PageContainer from '../page-container/page-container';
import { showAlert, ShowConfirmAlert } from '../skiold-alert/skiold-alert';
import { SkioldButton } from '../skiold-components/skiold-button/skiold-button';
import { SkioldFormDropdown } from '../skiold-components/skiold-dropdown/skiold-form-dropdown';
import { WhiteText } from '../Text/white-text';
import { Heading } from '../utils/heading';
import { SkioldFetchIcon } from '../utils/skiold-fetch-icon';
import { ViewWeb } from '../utils/web-view';
import './create-location-relations.scss';
import './create-process-equipment.scss';
import LocationRelationTable from './location-relation-table';

const mapStateToProps = (state: WebAppState) => {
	return {
		siteId: state.profile.active ? state.profile.active.siteId : undefined,
		processEquipments: state.processEquipments.entities,
		buildings: state.locations.buildings,
		sections: state.locations.sections,
		processEquipmentData: state.processEquipmentData.entities,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	getSyncData: () => GetProcessEquipmentData()(dispatch),
	fetchLocations: async (equipment: Equipment, code: string) => await FetchLocations(equipment, code),
	getunitsData: () => UnitsToPenOperations.GetSyncData()(dispatch),
});

interface PropsFromParent {
	closeModal?: () => void;
}

type CreateLocationRelationProps = ReturnType<typeof mapStateToProps> &
	ReturnType<typeof mapDispatchToProps> &
	PropsFromParent;

interface CreateLocationRelationtState {
	processEquipmentOptions: Option[];
	loading: boolean;
	selectedOption: Option;
	selectedProcessEquipment: IProcessEquipment | undefined;
	showSpinner: boolean;
}

export class CreateLocationRelation extends React.PureComponent<
	CreateLocationRelationProps,
	CreateLocationRelationtState
> {
	constructor(props: CreateLocationRelationProps) {
		super(props);
		let processEquipmentOptions = this.props.processEquipments.map(processEquipment => ({
			value: processEquipment.id!,
			label: processEquipment.name
				? processEquipment.name + ''
				: processEquipment.equipment
				? processEquipment.equipment!.toString()
				: '',
		}));
		processEquipmentOptions.unshift({ value: '', label: ' ' });

		this.state = {
			processEquipmentOptions,
			showSpinner: false,
			loading: false,
			selectedOption: { value: '', label: ' ' },
			selectedProcessEquipment: undefined,
		};
	}

	public render() {
		return (
			<PageContainer>
				<Heading allLettersCapitalized={false} text={localized('relationProcessEquipment')} />
				<ViewWeb className="create-location-relations">
					<ViewWeb className="uppper-container">
						<WhiteText>{localized('chooseProcessEquipment')}</WhiteText>
						<SkioldFormDropdown
							items={this.state.processEquipmentOptions}
							selectedValue={this.state.selectedOption}
							onValueChanged={this.equipmentChanged}
							theme="light"
						/>
						{this.state.selectedOption.value !== '' && (
							<SkioldFetchIcon
								className={'margin-left'}
								showSpinner={this.state.loading}
								onPress={this.showData}
							/>
						)}
					</ViewWeb>
					<LocationRelationTable
						loading={this.state.loading}
						buildings={this.props.buildings}
						save={this.save}
						sections={this.props.sections}
						data={this.props.processEquipmentData}
						processEquipment={this.state.selectedProcessEquipment}
						checkForUnsavedChanges={this.mapCheckForChangesFunction}
					/>
					{this.renderButtons()}
				</ViewWeb>
			</PageContainer>
		);
	}

	private showData = async () => {
		const selectedProcessEquipment = this.props.processEquipments.find(
			processEquipment => this.state.selectedOption.value === processEquipment.id,
		);
		this.setState({
			selectedProcessEquipment,
			loading: true,
		});
		if (selectedProcessEquipment && selectedProcessEquipment.equipment && selectedProcessEquipment.code) {
			let sucess = await this.props.fetchLocations(
				selectedProcessEquipment.equipment,
				selectedProcessEquipment.code,
			);

			if (!sucess) {
				showAlert(localized('FailedToFetchNewestLocations'));
			}
			let ArrayOfPromises = Array<Promise<void>>();
			ArrayOfPromises.push(this.props.getSyncData());
			ArrayOfPromises.push(this.props.getunitsData());
			await Promise.all(ArrayOfPromises);
		}

		this.setState({ loading: false });
	};
	private equipmentChanged = (equipmentOption: Option) => {
		this.setState({
			selectedOption: equipmentOption,
			selectedProcessEquipment: undefined, // Doing this, makes sure that the table doesn't show wrong controller data
		});
	};

	private renderButtons() {
		let buttons = (
			<ViewWeb className="buttons-container">
				{this.props.closeModal && (
					<SkioldButton title={localized('Close')} onPress={this.closeModal} theme="grey" />
				)}
				<SkioldButton title={localized('Save')} onPress={this.saveLocations} />
				{this.state.showSpinner && (
					<ViewWeb className="is-saving-spinner">
						<ClipLoader color="#f2ac40" size={35} />
					</ViewWeb>
				)}
			</ViewWeb>
		);

		return buttons;
	}
	private saveLocations = async (calledFromClose: boolean = false) => {
		this.setState({ showSpinner: true });
		await this.LocationSave!();
		this.setState({ showSpinner: false });
		if (!calledFromClose) {
			this.closeModal(true);
		}
	};
	public LocationSave: (() => void) | undefined;
	public checkForUnsavedChanges: (() => boolean) | undefined;
	private save = (method: () => void) => (this.LocationSave = method);
	private mapCheckForChangesFunction = (method: () => boolean) => (this.checkForUnsavedChanges = method);
	private closeModal = async (calledFromSave: boolean = false) => {
		if (
			!calledFromSave &&
			this.checkForUnsavedChanges &&
			this.checkForUnsavedChanges() &&
			(await ShowConfirmAlert(localized('unsavedRegisteredData')))
		) {
			await this.saveLocations(true);
		}

		if (this.props.closeModal) {
			this.props.closeModal();
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateLocationRelation);
