import i18n from 'i18next';
import da from 'date-fns/locale/da';
import en from 'date-fns/locale/en-GB';
import fr from 'date-fns/locale/fr';
import es from 'date-fns/locale/es';
import vi from 'date-fns/locale/vi';
import { registerLocale } from 'react-datepicker';
import moment from 'moment';
import 'moment/locale/da';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/vi';
import englishTranslation from './english-translations';
import danishTranslation from './danish-translations';
import frenchTranslation from './french-translations';
import vietnamTranslation from './vietnam-translations';
import spanishTranslation from './spanish-translations';
export const Languages = {
	en: 'English',
	da: 'Dansk',
	fr: 'Français',
	vi: 'Tiếng Việt',
	es: 'Español',
};

export type LanguageType = keyof typeof Languages;

type LanguageResource = { [K in keyof typeof Languages]: { translation: { [T in string] } } };

const i18nResources: LanguageResource = {
	en: {
		translation: englishTranslation,
	},
	da: {
		translation: danishTranslation,
	},
	fr: {
		translation: frenchTranslation,
	},
	vi: {
		translation: vietnamTranslation,
	},
	es: {
		translation: spanishTranslation,
	},
};

export type i18nTranslationKey = keyof typeof i18nResources.en.translation;

i18n.init({
	compatibilityJSON: 'v3',
	fallbackLng: 'en',
	resources: i18nResources,
	// have a common namespace used around the full app
	ns: ['translation'],
	defaultNS: 'translation',
	debug: false,
	// cache: {
	//   enabled: true
	// },
	interpolation: {
		escapeValue: false, // not needed for react as it does escape per default to prevent xss!
	},
});

// type-safe translation lookup
export const localized = (key: i18nTranslationKey): string => {
	return i18n.t(key);
};

export const localizedDynamic = (key: i18nTranslationKey | string): string => {
	return i18n.t(key);
};

export const localizedInterpolation = (key: i18nTranslationKey, keysToReplace: any): string => {
	const area = { area: localized(keysToReplace) };
	return i18n.t(key, area);
};

export const localizedInterpolationMultiple = (key: i18nTranslationKey, keysToReplace: any): string => {
	//@ts-ignore
	return i18n.t(key, keysToReplace);
};

export const getTranslation = (language: keyof typeof Languages) => i18n.getFixedT(language);

export const changeLanguage = (language: keyof typeof Languages) => {
	i18n.changeLanguage(language);
	moment.locale(language);
};

export const registerLanguages = () => {
	registerLocale('da-dk', da);
	registerLocale('en-gb', en);
	registerLocale('fr-fr', fr);
	registerLocale('es-es', es);
	registerLocale('vi', vi);
};

export const getLanguage = () => i18n.language;

export default i18n;
