import { NucleusManagementHerdSettingsState } from './types';
import { createReducer } from '@reduxjs/toolkit';
import { getBreedingSettings, upsertBreedingSettings, upsertManyBreedingSettings } from './operation';
import { getSyncModelData } from '../sync/actions';
import { NumberBatch } from 'shared/api/api';
import { SyncableInitialState } from 'shared/state/models/syncable';
import { mergeArrays } from 'shared/helpers/reducer-helpers';
import { SharedAppState } from 'shared/state/store.shared';
import { INucleusManagementBreedingSettings, NucleusManagementBreedingSettings } from 'shared/api/api';
import { siteChange } from '../profile/actions';

const initialState: NucleusManagementHerdSettingsState = {
	...SyncableInitialState,
	breedingSettings: [],
	loading: false,
};

const nucleusManagementBreedingSettingsReducer = createReducer(initialState, builder => {
	builder.addCase(getBreedingSettings.fulfilled, (state, action) => {
		if (action.payload) {
			state.breedingSettings = action.payload;
		}

		state.loading = false;
	});

	builder.addCase(getBreedingSettings.pending, state => {
		state.loading = true;
	});

	builder.addCase(getBreedingSettings.rejected, state => {
		state.loading = false;
	});

	builder.addCase(upsertBreedingSettings.pending, (state, action) => {
		const index = state.breedingSettings.findIndex(s => s.id === action.meta.arg.id);

		if (index < 0) {
			state.breedingSettings.push(action.meta.arg);
		} else {
			state.breedingSettings[index] = action.meta.arg;
		}
	});

	builder.addCase(upsertBreedingSettings.fulfilled, (state, action) => {
		const result = action.payload;
		if (result === undefined) {
			return;
		}
		const index = state.breedingSettings.findIndex(s => s.id === result.id);

		if (index < 0) {
			state.breedingSettings.push(result);
		} else {
			state.breedingSettings[index] = result;
		}
	});

	builder.addCase(upsertManyBreedingSettings.fulfilled, (state, action) => {
		const result = action.payload;
		if (result === undefined) {
			return;
		}

		state.breedingSettings = result;
	});

	builder.addCase(getSyncModelData.done, (state, action) => {
		if (action.payload.params.siteId === action.payload.params.activeSiteId) {
			if (
				action.payload.result.nucleusManagementBreedingSettings &&
				action.payload.result.nucleusManagementBreedingSettings.datas!.length
			) {
				state.entities = mergeArrays(
					state.entities,
					action.payload.result.nucleusManagementBreedingSettings.datas!,
				);
				state.lastSyncDate = action.payload.result.nucleusManagementBreedingSettings.syncTime!;
			}
		}
		state.syncInProgress = false;
	});

	builder.addCase(siteChange.done, (state, action) => {
		state.entities = [];
		state.updates = [];
		state.lastSyncDate = new Date(-8640000000000000);
		state.syncInProgress = false;
		state.saveSyncInProgress = false;
	});

	builder.addCase('persist/REHYDRATE', (state, action) => {
		let a = ((action as any) as { payload: SharedAppState; key: string }) as any;

		if (a.key === 'root') {
			let entities = new Array<NucleusManagementBreedingSettings>();
			let updates = new Array<NucleusManagementBreedingSettings>();
			let breedingSettings = new Array<NucleusManagementBreedingSettings>();
			let lastSyncDate = new Date(-8640000000000000);

			if (
				a.payload &&
				a.payload.nucleusManagementBreedingSettings &&
				a.payload.nucleusManagementBreedingSettings.entities
			) {
				entities = a.payload.nucleusManagementBreedingSettings.entities.map(
					(dt: INucleusManagementBreedingSettings) => {
						let ndt = NucleusManagementBreedingSettings.fromJS({});
						ndt.init(dt);
						return ndt;
					},
				);
			}

			if (
				a.payload &&
				a.payload.nucleusManagementBreedingSettings &&
				a.payload.nucleusManagementBreedingSettings.breedingSettings
			) {
				breedingSettings = a.payload.nucleusManagementBreedingSettings.breedingSettings.map(
					(dt: INucleusManagementBreedingSettings) => {
						let ndt = NucleusManagementBreedingSettings.fromJS({});
						ndt.init(dt);
						return ndt;
					},
				);
			}

			if (
				a.payload &&
				a.payload.nucleusManagementBreedingSettings &&
				a.payload.nucleusManagementBreedingSettings.updates
			) {
				updates = a.payload.nucleusManagementBreedingSettings.updates.map(
					(dt: INucleusManagementBreedingSettings) => {
						let ndt = NucleusManagementBreedingSettings.fromJS({});
						ndt.init(dt);
						return ndt;
					},
				);
			}
			if (
				a.payload &&
				a.payload.nucleusManagementBreedingSettings &&
				a.payload.nucleusManagementBreedingSettings.lastSyncDate
			) {
				lastSyncDate = new Date(a.payload.nucleusManagementBreedingSettings.lastSyncDate);
			}

			state.entities = entities;
			state.updates = updates;
			state.lastSyncDate = lastSyncDate;
			state.breedingSettings = breedingSettings;
		}
	});
});

export default nucleusManagementBreedingSettingsReducer;
