import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnimalType, GrowthPigTuneProduction } from 'shared/api/api';
import { getDateString } from 'shared/helpers/date-helpers';
import { getGrowthPigsEventsBySiteId } from 'shared/state/ducks/growth-pig-events/operations';
import { getGrowthPigTuneProductionBySiteId } from 'shared/state/ducks/growth-pig-tune-production/operations';
import { WebAppState } from 'web/state/store.web';
import { SkioldDatePicker } from 'web/view/components/skiold-components/skiold-date-picker/skiold-date-picker';
import { SkioldFetchIcon } from 'web/view/components/utils/skiold-fetch-icon';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { GrowthPigTuneProductionReportTable } from './growth-pig-tune-production-report-table';
export interface PropsFromParent {
	animalType: AnimalType;
	topRight?: (topRight: JSX.Element) => void;
	topLeft?: (topLeft: JSX.Element) => void;
}

export const GrowthPigTuneProductionReportOverview: FC<PropsFromParent> = React.memo(props => {
	const [date, setDate] = useState(new Date());
	const dispatch = useDispatch();

	getGrowthPigsEventsBySiteId()(dispatch);
	getGrowthPigTuneProductionBySiteId()(dispatch);

	useEffect(() => {
		const changeDate = (newDate: Date) => {
			getGrowthPigsEventsBySiteId()(dispatch);
			getGrowthPigTuneProductionBySiteId()(dispatch);
			setDate(newDate);
		};
		if (props.topLeft) {
			props.topLeft(
				<GrowthPigTuneProductionReportDatePicker animalType={props.animalType} changeDateParent={changeDate} />,
			);
		}
	}, [props, setDate, dispatch]);
	return (
		<div className="report-container">
			<ViewWeb className="p-report-outer-div">
				<GrowthPigTuneProductionReportTable animalType={props.animalType} date={date} />
			</ViewWeb>
		</div>
	);
});

interface DatePickerProps {
	animalType: AnimalType;
	changeDateParent: (date: Date) => void;
}

export const GrowthPigTuneProductionReportDatePicker: FC<DatePickerProps> = React.memo(props => {
	const gpetp = useSelector((state: WebAppState) => state.growthPigTuneProduction.growthPigTuneProductions);
	const [date, setDate] = useState(new Date());

	const [highLightedDateList, setHighlightedDates] = useState([] as Date[]);
	const changeDate = (newDate: Date) => {
		setDate(newDate);
	};
	const fetchReport = () => {
		props.changeDateParent(date);
	};
	useEffect(() => {
		const dates: Date[] = handleHighLightedDates(gpetp, props.animalType);
		setHighlightedDates(dates);
	}, [props.animalType, gpetp]);
	return (
		<div className="top-left-container">
			<div className="date-picker-margin">
				<SkioldDatePicker
					onDateChanged={changeDate}
					highlightDates={highLightedDateList}
					selectedDate={date}
					text="Date"
					includeTimeStamp={false}
				/>
			</div>

			<SkioldFetchIcon onPress={fetchReport} />
		</div>
	);
});
function handleHighLightedDates(gpetp: GrowthPigTuneProduction[], animalType: AnimalType) {
	const dates: Date[] = [];
	gpetp
		.filter(gpetp => gpetp.animalType === animalType)
		.forEach(gpetp => {
			if (gpetp.date && dates.find(d => getDateString(d) === getDateString(gpetp.date)) === undefined) {
				dates.push(gpetp.date);
			}
		});
	return dates;
}
