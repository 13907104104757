import React from 'react';
import { AnimalType } from 'shared/api/api';
import allocateWeight from 'shared/assets/src-assets/png/allocate_wieght_light_grey.png';
import PrinterGreyIcon from 'shared/assets/src-assets/png/printer_ikon_grey.png';
import { numberWithCommas, setOneDecimalsAsNumber } from 'shared/helpers/general-helpers';
import {
	GrowthPigDepartureEventBundleListRow,
	GrowthPigMoveEventBundleListRow,
	GrowthPigSummaryBundleRowItem,
} from 'shared/helpers/growth-pigs-helper/growth-pig-event-list-helper';
import { localized } from 'shared/state/i18n/i18n';
import { DepartureTypes } from 'shared/state/models/departure-types';
import { SkioldIconSpinner } from '../../utils/skiold-icon-spinner';

export const renderTopRightGrowthPigEventLists = (
	data: GrowthPigSummaryBundleRowItem[],
	printData: () => void,
	amountText: string,
) => {
	if (data.length > 0) {
		const totalAmount = data.map(a => a.pcs).reduce((a, b) => (a ? a : 0) + (b ? b : 0), 0);
		const totalWeight = data
			.map(a => (a.pcs ? a.pcs : 0) * (a.avgWeight ? a.avgWeight : 0))
			.reduce((a, b) => (a ? a : 0) + (b ? b : 0), 0);
		const avgWeightTotal = totalWeight / (totalAmount ? totalAmount : 1);

		return (
			<div className="flexDirectionRow">
				<div className="alignSelfCenter">
					<div className="flexDirectionRow">
						<div className="flexDirectionRowWithWidth borderRight">
							<div className="summaryTextStyle"> {localized(amountText)} </div>
						</div>
						<div className="flexDirectionRowWithWidth borderRight">
							<div className="summaryTextStyle"> {localized('totalWeight')} </div>
						</div>
						<div className="flexDirectionRowWithWidth borderRight">
							<div className="summaryTextStyle"> {localized('avgWeight')} </div>
						</div>
					</div>
					<div className="flexDirectionRow">
						<div className="flexDirectionRowWithWidth borderRight">
							<div className="summaryTextStyle"> {numberWithCommas(totalAmount)} </div>
						</div>
						<div className="flexDirectionRowWithWidth borderRight">
							<div className="summaryTextStyle">
								{numberWithCommas(setOneDecimalsAsNumber(totalWeight))}
							</div>
						</div>
						<div className="flexDirectionRowWithWidth borderRight">
							<div className="summaryTextStyle"> {setOneDecimalsAsNumber(avgWeightTotal)} </div>
						</div>
					</div>
				</div>
				<SkioldIconSpinner
					containerClassName="growth-pig-print-icon-container"
					title={localized('PrintList')}
					icon={PrinterGreyIcon}
					onPress={printData}
				/>
			</div>
		);
	}
	return (
		<SkioldIconSpinner
			containerClassName="growth-pig-print-icon-container"
			title={localized('PrintList')}
			icon={PrinterGreyIcon}
			onPress={printData}
		/>
	);
};

export const renderTopRightGrowthPigMovedEventLists = (
	data: GrowthPigMoveEventBundleListRow[],
	printData: () => void,
	animalType: AnimalType,
) => {
	if (data.length > 0) {
		// Separate all events depending on toProduction
		const entranced = data.filter(e => e.toProductionType === animalType && e.fromProductionType !== animalType);
		const departured = data.filter(e => e.toProductionType !== animalType);
		const internalTransfered = data.filter(
			e => e.toProductionType === animalType && e.fromProductionType === animalType,
		);

		const totalAmountDeparted = departured.map(a => a.pcs).reduce((a, b) => (a ? a : 0) + (b ? b : 0), 0);
		const totalAmountEntered = entranced.map(a => a.pcs).reduce((a, b) => (a ? a : 0) + (b ? b : 0), 0);
		const totalAmountInternal = internalTransfered
			.map(a => a.pcs)
			.reduce((a, b) => (a ? a : 0) + (b ? b : 0), 0);

		const totalWeightDeparted = departured
			.map(a => (a.pcs ? a.pcs : 0) * (a.avgWeight ? a.avgWeight : 0))
			.reduce((a, b) => (a ? a : 0) + (b ? b : 0), 0);
		const avgWeightTotalDeparted = totalWeightDeparted / (totalAmountDeparted ? totalAmountDeparted : 1);

		const totalWeightEntered = entranced
			.map(a => (a.pcs ? a.pcs : 0) * (a.avgWeight ? a.avgWeight : 0))
			.reduce((a, b) => (a ? a : 0) + (b ? b : 0), 0);
		const avgWeightTotalEntered = totalWeightEntered / (totalAmountEntered ? totalAmountEntered : 1);

		const totalWeightInternal = internalTransfered
			.map(a => (a.pcs ? a.pcs : 0) * (a.avgWeight ? a.avgWeight : 0))
			.reduce((a, b) => (a ? a : 0) + (b ? b : 0), 0);
		const avgWeightTotalInternal = totalWeightInternal / (totalAmountInternal ? totalAmountInternal : 1);

		return (
			<div className="flexDirectionRow">
				<div className="alignSelfCenter">
					<div className="flexDirectionRow">
						<div className="move-summary-container borderRight">
							<div className="summaryTextStyle"> {localized('Type')} </div>
							<div className="summaryTextStyle"> {localized('entered')} </div>
							<div className="summaryTextStyle"> {localized('departed')} </div>
							<div className="summaryTextStyle"> {localized('internalTransfer')} </div>
						</div>
						<div className="move-summary-container borderRight">
							<div className="summaryTextStyle"> {localized('Pcs')} </div>
							<div className="summaryTextStyle">
								{totalAmountEntered ? numberWithCommas(totalAmountEntered) : '\xa0'}{' '}
							</div>
							<div className="summaryTextStyle">
								{totalAmountDeparted ? numberWithCommas(totalAmountDeparted) : '\xa0'}
							</div>
							<div className="summaryTextStyle">
								{totalAmountInternal ? numberWithCommas(totalAmountInternal) : '\xa0'}
							</div>
						</div>
						<div className="move-summary-container borderRight">
							<div className="summaryTextStyle"> {localized('totalWeight')} </div>
							<div className="summaryTextStyle">
								{totalWeightEntered
									? numberWithCommas(setOneDecimalsAsNumber(totalWeightEntered))
									: '\xa0'}
							</div>
							<div className="summaryTextStyle">
								{totalWeightDeparted
									? numberWithCommas(setOneDecimalsAsNumber(totalWeightDeparted))
									: '\xa0'}
							</div>
							<div className="summaryTextStyle">
								{totalWeightInternal
									? numberWithCommas(setOneDecimalsAsNumber(totalWeightInternal))
									: '\xa0'}
							</div>
						</div>
						<div className="move-summary-container borderRight">
							<div className="summaryTextStyle"> {localized('avgWeight')} </div>
							<div className="summaryTextStyle">
								{avgWeightTotalEntered ? setOneDecimalsAsNumber(avgWeightTotalEntered) : '\xa0'}
							</div>
							<div className="summaryTextStyle">
								{avgWeightTotalDeparted ? setOneDecimalsAsNumber(avgWeightTotalDeparted) : '\xa0'}
							</div>
							<div className="summaryTextStyle">
								{avgWeightTotalInternal ? setOneDecimalsAsNumber(avgWeightTotalInternal) : '\xa0'}
							</div>
						</div>
					</div>
				</div>
				<SkioldIconSpinner
					containerClassName="growth-pig-print-icon-container"
					title={localized('PrintList')}
					icon={PrinterGreyIcon}
					onPress={printData}
				/>
			</div>
		);
	}
	return (
		<SkioldIconSpinner
			containerClassName="growth-pig-print-icon-container"
			title={localized('PrintList')}
			icon={PrinterGreyIcon}
			onPress={printData}
		/>
	);
};

export interface GrowthPigDepartureEventSummaryRow {
	type: string;
	totalAmount: number;
	totalWeight: number;
	avgWeightTotal: number;
}

export const renderTopRightGrowthPigDepartureEventLists = (
	data: GrowthPigDepartureEventBundleListRow[],
	printData: () => void,
	amountText: string,
	openModal: () => void,
	usePrivateSlaughterhouse?: boolean,
) => {
	const summaryRow: GrowthPigDepartureEventSummaryRow[] = [];
	if (data.length > 0) {
		Object.keys(DepartureTypes).forEach(key => {
			const totalAmount = data
				.filter(d => d.departuredType === key)
				.map(a => a.pcs)
				.reduce((a, b) => (a ? a : 0) + (b ? b : 0), 0);
			const totalWeight = data
				.filter(
					d =>
						d.departuredType === key &&
						(key !== DepartureTypes.departureTypeKilled || usePrivateSlaughterhouse),
				)
				.map(a => (a.pcs ? a.pcs : 0) * (a.avgWeight ? a.avgWeight : 0))
				.reduce((a, b) => (a ? a : 0) + (b ? b : 0), 0);

			const avgWeightTotal = totalWeight / (totalAmount ? totalAmount : 1);
			if (totalAmount !== undefined && totalAmount > 0) {
				summaryRow.push({ type: key, totalAmount, totalWeight, avgWeightTotal });
			}
		});

		return (
			<div className="flexDirectionRow">
				<div className="alignSelfCenter">
					<div className="flexDirectionRow">
						<div className="move-summary-container borderRight">
							<div className="summaryTextStyle"> {localized('departureType')}: </div>
							{summaryRow.map((a, i) => (
								<div key={i} className="summaryTextStyle">
									{localized(a.type)}
								</div>
							))}
						</div>
						<div className="move-summary-container borderRight">
							<div className="summaryTextStyle"> {localized(amountText)}: </div>

							{summaryRow.map((a, i) => (
								<div key={i} className="summaryTextStyle">
									{numberWithCommas(a.totalAmount)}
								</div>
							))}
						</div>
						<div className="move-summary-container borderRight">
							<div className="summaryTextStyle"> {localized('totalWeight')}: </div>
							{summaryRow.map((a, i) => (
								<div key={i} className="summaryTextStyle">
									{a.totalWeight ? numberWithCommas(setOneDecimalsAsNumber(a.totalWeight)) : '\xa0'}
								</div>
							))}
						</div>
						<div className="move-summary-container borderRight">
							<div className="summaryTextStyle"> {localized('avgWeight')}: </div>
							{summaryRow.map((a, i) => (
								<div key={i} className="summaryTextStyle">
									{a.avgWeightTotal ? setOneDecimalsAsNumber(a.avgWeightTotal) : '\xa0'}
								</div>
							))}
						</div>
						<div className="move-summary-container borderRight left-right-padding">
							<SkioldIconSpinner title={'DistributeWeight'} icon={allocateWeight} onPress={openModal} />
						</div>
					</div>
				</div>

				<SkioldIconSpinner
					containerClassName="growth-pig-print-icon-container"
					title={localized('PrintList')}
					icon={PrinterGreyIcon}
					onPress={printData}
				/>
			</div>
		);
	}
	return (
		<SkioldIconSpinner
			containerClassName="growth-pig-print-icon-container"
			title={localized('PrintList')}
			icon={PrinterGreyIcon}
			onPress={printData}
		/>
	);
};
