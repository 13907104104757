import { Sorting } from '@devexpress/dx-react-grid';
import { FarrowingWorkListItem } from 'native/view/components/work-list/farrowing-work-list/farrowing-work-list-item';
import React from 'react';
import { Option } from 'react-dropdown';
import { Equipment, IWorkListSetting, WorkListType } from 'shared/api/api';
import { findRetentionTime } from 'shared/helpers/treatment-helper/treatment-helper';
import { DepartureListTableItem } from 'shared/helpers/work-list-helpers/departure-list-helpers/departure-work-list-item';
import { AllAnimalsEsfWorkListItem } from 'shared/helpers/work-list-helpers/esf-list-helpers/all-animals-esf-helpers';
import { ShortageEsfWorkListItem } from 'shared/helpers/work-list-helpers/esf-list-helpers/shortage-esf-helpers';
import { MovingListTable } from 'shared/helpers/work-list-helpers/move-to-helpers/move-to-data-model';
import { ScanningListTableItem } from 'shared/helpers/work-list-helpers/scanning-list-helpers/scanning-work-list-item';
import { TasksInFarrowingStableItem } from 'shared/helpers/work-list-helpers/tasks-in-farrowing-stable-helper/tasks-in-farrowing-stable-item';
import { VaccineWorkListItem } from 'shared/helpers/work-list-helpers/vaccine-list-helpers/vaccine-worklist-items';
import { sortWorklistByPriority } from 'shared/helpers/work-list-helpers/work-list-priority';
import { localized, localizedDynamic } from 'shared/state/i18n/i18n';
import { StoreContainer } from 'shared/state/store-container';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { getWebAnimalColorStyleTable } from 'web/web-helpers/general-web-helpers';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { SkioldCheckbox } from '../skiold-components/skiold-checkbox/skiold-checkbox';
import { FormRow } from '../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { SkioldFormInput } from '../skiold-components/skiold-input/skiold-form-input';
import './work-list-helper.scss';
export function getWorkListNameRow(setState: (name: string) => void, initName: string): FormRow {
	return {
		name: localized('name'),
		component: <SkioldFormInput onChangeText={newText => setState(newText)} text={initName} />,
	};
}

export const getStemAnimalNumberColor = (
	sow:
		| MovingListTable
		| VaccineWorkListItem
		| TasksInFarrowingStableItem
		| ShortageEsfWorkListItem
		| ScanningListTableItem
		| DepartureListTableItem
		| AllAnimalsEsfWorkListItem
		| FarrowingWorkListItem,
) => {
	return sow && sow ? getWebAnimalColorStyleTable(sow.stemAnimalId) : '';
};

export const getAnimalNumberText = (
	tableItem:
		| MovingListTable
		| VaccineWorkListItem
		| TasksInFarrowingStableItem
		| ShortageEsfWorkListItem
		| ScanningListTableItem
		| DepartureListTableItem
		| AllAnimalsEsfWorkListItem
		| FarrowingWorkListItem,
) => tableItem.animalNumber;

export function generateActivityListOptions(): Option[] {
	const state = StoreContainer.getState();
	const usesEsf = state.processEquipments.entities.find(eq => eq.equipment === Equipment.ESF && !eq.isDeleted);
	let mappedWorklistSettings = state.workListSettings.entities
		.filter(
			a =>
				a.showOnWeb &&
				!(
					usesEsf === undefined &&
					(a.type === WorkListType.AllAnimalsEsfListSetting || a.type === WorkListType.ShortageEsfListSetting)
				),
		)
		.map((setting: IWorkListSetting) => {
			return {
				label: setting.name ? setting.name : localizedDynamic(setting.type!),
				value: setting.type!.toString(),
			};
		});
	return mappedWorklistSettings.sort((settingA: Option, settingB: Option) => {
		return sortWorklistByPriority(settingA.value, settingB.value);
	});
}

export function getAvailablityRow(
	toggleWeb: () => void,
	toggleApp: () => void,
	showOnWeb: boolean,
	showOnApp: boolean,
	title: string = 'listAvailableOn',
): FormRow {
	return {
		name: localizedDynamic(title),
		component: (
			<ViewWeb className="work-list-helper-availability-row">
				<SkioldCheckbox
					onClick={() => {
						toggleApp();
					}}
					isChecked={showOnApp!}
				/>
				<TextWeb>{localized('App')}</TextWeb>
				<SkioldCheckbox
					onClick={() => {
						toggleWeb();
					}}
					isChecked={showOnWeb!}
				/>
				<TextWeb>{localized('Web')}</TextWeb>
			</ViewWeb>
		),
	};
}

export const WorkListHasRetentionTimeHelper = (
	item:
		| MovingListTable
		| AllAnimalsEsfWorkListItem
		| FarrowingWorkListItem
		| ShortageEsfWorkListItem
		| TasksInFarrowingStableItem
		| VaccineWorkListItem,
) => {
	return item.stemAnimalId && findRetentionTime(item.stemAnimalId) !== undefined ? 'retention-time-list-color' : '';
};

export function getSkioldInputRow<K extends keyof T, T extends Partial<Record<K, string>>>(
	callback: (genericObject: T) => void,
	genericObject: T,
	key: K,
	translation: string,
): FormRow {
	return {
		name: translation,
		component: (
			<SkioldFormInput
				onChangeText={newText => {
					let obj = { ...genericObject };
					obj[key] = newText as any;
					callback(obj);
				}}
				text={genericObject[key]}
			/>
		),
	};
}

export const defaultSortingWorklists = [{ columnName: 'cycleDays', direction: 'desc' }] as Sorting[];

export function getWorkListBuildSecString(showBuilding: boolean, buildingString: string, sectionString: string) {
	return `${showBuilding ? buildingString : !sectionString ? buildingString : ''}${
		showBuilding && sectionString ? '/' : ''
	}${sectionString} `;
}
