import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveSite } from '../../../../../../shared/state/ducks/site/reducer';
import {
	getCEFNFrequencyOptions,
	getDayOfWeekOptions,
	getFrequencyOptions,
	getProvider,
	shouldDisableTime,
	timePickerFTextStyle,
} from '../nucleus-management-helper';
import { WebAppState } from '../../../../../state/store.web';
import { Option } from 'react-dropdown';
import {
	createNucleusManagementConnection,
	getConnections,
	sendNucleusEventsManually,
	updateNucleusManagementSendSettings,
} from '../../../../../../shared/state/ducks/nucleus-management-connections/operation';
import {
	DayOfWeek,
	NucleusManagementConnectionCreationDTO,
	NucleusManagementFrequency,
	NucleusManagementProvider,
	NucleusManagementSendSetup,
} from '../../../../../../shared/api/api';
import { localized } from '../../../../../../shared/state/i18n/i18n';
import moment from 'moment';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ViewWeb } from '../../../../components/utils/web-view';
import './nucleus-management-fetch-setup.scss';
import { TextWeb } from '../../../../../web-helpers/styled-text-components';
import { SkioldFormsWrapper } from '../../../../components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { SkioldButton } from '../../../../components/skiold-components/skiold-button/skiold-button';
import { FormRow } from '../../../../components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { TextField } from '@mui/material';
import { SkioldFormInput } from '../../../../components/skiold-components/skiold-input/skiold-form-input';
import { validateEmailAddress } from '../../../../../../shared/helpers/general-helpers';
import { SkioldFormDropdown } from 'web/view/components/skiold-components/skiold-dropdown/skiold-form-dropdown';

export const NucleusManagementSendSetupForm: FC = React.memo(() => {
	const dispatch = useDispatch();

	const activeSite = useSelector(selectActiveSite);
	const provider = useMemo(() => getProvider(activeSite.activeLicences), [activeSite]);
	const managementConnection = useSelector((state: WebAppState) =>
		state.nucleusManagementConnections.connections.find(c => c.provider === provider),
	);

	const [activeSendFrequencyOption, setActiveSendFrequencyOption] = useState<Option>();
	const [activeDayOfWeekOption, setActiveDayOfWeekOption] = useState<Option>();
	const [timeOfDay, setTimeOfDay] = useState<Date | null>(new Date('01 Jan 1970 12:00:00'));
	const [emailAddress1, setEmailAddress1] = useState('');
	const [emailAddress2, setEmailAddress2] = useState('');

	const [dayOfWeekOptions] = useState<Option[]>(getDayOfWeekOptions());
	const [frequencyOptions] = useState<Option[]>(
		provider === NucleusManagementProvider.SelfManaged ? getCEFNFrequencyOptions() : getFrequencyOptions(),
	);

	useEffect(() => {
		if (provider === NucleusManagementProvider.SelfManaged && managementConnection === undefined) {
			dispatch(
				createNucleusManagementConnection(
					new NucleusManagementConnectionCreationDTO({
						siteId: activeSite.id,
						provider: NucleusManagementProvider.SelfManaged,
						password: '',
						username: '',
					}),
				),
			);
		}
	}, [activeSite, managementConnection, provider, dispatch]);

	useEffect(() => {
		if (activeSite.id === undefined) {
			return;
		}

		dispatch(getConnections(activeSite.id));
	}, [activeSite, dispatch]);

	useEffect(() => {
		const sendSetup = managementConnection?.sendSetup;
		if (sendSetup) {
			setActiveSendFrequencyOption(frequencyOptions.find(o => o.value === sendSetup.frequency));
			setActiveDayOfWeekOption(dayOfWeekOptions.find(o => o.value === sendSetup.dayOfWeek));
			setTimeOfDay(moment(sendSetup.timeOfDay ?? '', 'HH:mm:ss').toDate());
			setEmailAddress1(sendSetup.emailAddress1 ?? '');
			setEmailAddress2(sendSetup.emailAddress2 ?? '');
		}
		// eslint-disable-next-line
	}, [managementConnection]);

	const sendFrequencyChanged = useCallback(
		(newValue: Option) => {
			const option = frequencyOptions.find(o => o.value === newValue.value);
			setActiveSendFrequencyOption(option);
		},
		[frequencyOptions],
	);

	const dayOfWeekChanged = useCallback(
		(newValue: Option) => {
			const option = dayOfWeekOptions.find(o => o.value === newValue.value);
			setActiveDayOfWeekOption(option);
		},
		[dayOfWeekOptions],
	);

	const handleEmail1Changed = useCallback((newText: string) => {
		setEmailAddress1(newText);
	}, []);

	const handleEmail2Changed = useCallback((newText: string) => {
		setEmailAddress2(newText);
	}, []);

	const saveSendSettings = useCallback(() => {
		if (
			(activeDayOfWeekOption?.value === undefined &&
				(activeSendFrequencyOption?.value === NucleusManagementFrequency.Weekly ||
					activeSendFrequencyOption?.value === NucleusManagementFrequency.Monthly)) ||
			activeSendFrequencyOption?.value === undefined ||
			(timeOfDay === null && activeSendFrequencyOption?.value === NucleusManagementFrequency.Manual) ||
			managementConnection === undefined
		) {
			alert(localized('NotAllInfoAlert'));
			return;
		}

		if (provider === NucleusManagementProvider.SelfManaged && !validateEmails(emailAddress1, emailAddress2)) {
			return false;
		}

		managementConnection.sendSetup = NucleusManagementSendSetup.fromJS({
			frequency: NucleusManagementFrequency[activeSendFrequencyOption.value],
			dayOfWeek: activeDayOfWeekOption?.value === undefined ? undefined : DayOfWeek[activeDayOfWeekOption.value],
			timeOfDay: timeOfDay ?? new Date(),
			emailAddress1,
			emailAddress2,
		});

		dispatch(updateNucleusManagementSendSettings(managementConnection));
	}, [
		activeDayOfWeekOption,
		activeSendFrequencyOption,
		timeOfDay,
		emailAddress1,
		emailAddress2,
		managementConnection,
		provider,
		dispatch,
	]);

	const handleSendNucleusEventsManually = useCallback(() => {
		if (managementConnection === undefined) {
			return;
		}

		dispatch(sendNucleusEventsManually(managementConnection));
	}, [dispatch, managementConnection]);

	const getFormRows = useCallback(() => {
		const formRows: FormRow[] = [
			{ header: localized('SendData') },
			{
				name: `${localized('Provider')} - ${localized('FileType')}`,
				component: <TextWeb>{provider === 'SelfManaged' ? 'Excel' : provider}</TextWeb>,
			},
			{
				name: localized('DataSendFrequency'),
				component: (
					<SkioldFormDropdown
						items={frequencyOptions}
						selectedValue={activeSendFrequencyOption}
						onValueChanged={sendFrequencyChanged}
					/>
				),
			},
		];
		if (
			activeSendFrequencyOption?.value === NucleusManagementFrequency.Weekly ||
			activeSendFrequencyOption?.value === NucleusManagementFrequency.Monthly
		) {
			formRows.push({
				name: localized('Weekday'),
				component: (
					<SkioldFormDropdown
						items={dayOfWeekOptions}
						selectedValue={activeDayOfWeekOption}
						onValueChanged={dayOfWeekChanged}
					/>
				),
			});
		}
		if (activeSendFrequencyOption?.value !== NucleusManagementFrequency.Manual) {
			formRows.push({
				name: localized('DataSendTime'),
				component: (
					<TimePicker
						value={timeOfDay}
						ampm={false}
						onChange={newValue => setTimeOfDay(newValue)}
						renderInput={params => <TextField {...params} variant={'filled'} sx={timePickerFTextStyle} />}
						shouldDisableTime={shouldDisableTime}
					/>
				),
			});
		}
		if (provider === 'SelfManaged') {
			formRows.push(
				{
					name: localized('Email') + ' 1',
					component: <SkioldFormInput text={emailAddress1} onChangeText={handleEmail1Changed} />,
				},
				{
					name: localized('Email') + ' 2',
					component: <SkioldFormInput text={emailAddress2} onChangeText={handleEmail2Changed} />,
				},
			);
		}

		return formRows;
	}, [
		provider,
		emailAddress1,
		emailAddress2,
		activeSendFrequencyOption,
		activeDayOfWeekOption,
		dayOfWeekChanged,
		handleEmail1Changed,
		handleEmail2Changed,
		sendFrequencyChanged,
		timeOfDay,
		dayOfWeekOptions,
		frequencyOptions,
	]);

	if (provider === 'NoNucleusManagementLicence') {
		return (
			<ViewWeb className={'fetch-settings-container'}>
				<TextWeb className={'title'}>{localized('NoNucleusManagementLicence')}</TextWeb>
			</ViewWeb>
		);
	}

	if (managementConnection === undefined) {
		return (
			<ViewWeb className={'fetch-settings-container'}>
				<TextWeb className={'title'}>{localized('CreateConnectionFirstError')}</TextWeb>
			</ViewWeb>
		);
	}

	return (
		<LocalizationProvider dateAdapter={AdapterMoment}>
			<ViewWeb className={'fetch-settings-container'}>
				<TextWeb className={'title'}>{localized('Setup')}</TextWeb>
				<SkioldFormsWrapper formRows={getFormRows()} containerClassName={'form-container'} />
				<SkioldButton title={localized('Save')} onPress={saveSendSettings} className={'button-container'} />
				{managementConnection?.sendSetup?.frequency === NucleusManagementFrequency.Manual &&
					activeSendFrequencyOption?.value === NucleusManagementFrequency.Manual && (
						<SkioldButton
							title={localized('SendManually')}
							onPress={handleSendNucleusEventsManually}
							className={'button-container'}
						/>
					)}
			</ViewWeb>
		</LocalizationProvider>
	);
});

const validateEmails = (email1: string, email2: string) => {
	if (email1 === '' && email2 === '') {
		alert(localized('NoEmailProvided'));
		return false;
	}
	if ((email1 !== '' && !validateEmailAddress(email1)) || (email2 !== '' && !validateEmailAddress(email2))) {
		alert(localized('InvalidEmailProvided'));
		return false;
	}
	return true;
};
