import { createAsyncThunk } from '@reduxjs/toolkit';
import { AsyncOperationHandler } from '../../../helpers/redux-helpers';

export const getNucleusManagementArchiveFiles = createAsyncThunk(
	'nucleusManagementArchive/getNucleusManagementArchiveFiles',
	(siteId: string) => {
		return AsyncOperationHandler(client => client.nucleusManagementArchiveFile_GetBySiteId(siteId));
	},
);

export const resendNucleusManagementArchiveFiles = createAsyncThunk(
	'nucleusManagementArchive/resendNucleusManagementFiles',
	(archiveFileIds: string[]) => {
		return AsyncOperationHandler(client => client.nucleusManagementArchiveFile_ResendFiles(archiveFileIds));
	},
);
