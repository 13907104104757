import { Syncable } from "shared/state/models/syncable";
import { IProcessEquipment } from "shared/api/api";


export const SAVE_PROCESS_EQUIPMENT = 'SAVE_PROCESS_EQUIPMENT';
export const SYNC_DATA_PROCESS_EQUIPMENT = 'SYNC_DATA_PROCESS_EQUIPMENT';
export const REMOVE_PROCESS_EQUIPMENT = 'REMOVE_PROCESS_EQUIPMENT';

export interface ProcessEquipmentState extends Syncable<IProcessEquipment> {
}
