import React, { FC } from 'react';
import { localized } from 'shared/state/i18n/i18n';
import { SkioldCheckbox } from 'web/view/components/skiold-components/skiold-checkbox/skiold-checkbox';
import { SkioldFormsWrapper } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { useGeneralSetting } from './general-settings-overview';

interface Props {}

export const FinisherRegistrationSetting: FC<Props> = React.memo(({ ...props }) => {
	const { generalSetting, setGeneralSetting } = useGeneralSetting();
	const getFormRows = () => {
		let formRows = new Array<FormRow>();

		formRows.push(getWorkListHeader());
		formRows.push(getUsePrivateSlaughterhouse());
		formRows.push(getRegisterIdForGpeDeparture());
		return formRows;
	};
	const getWorkListHeader = (): FormRow => {
		return {
			header: localized('SetupRegistration'),
		};
	};

	const getUsePrivateSlaughterhouse = (): FormRow => {
		return {
			name: localized('PigDeliveryToPrivateSlaughterhouse'),
			component: (
				<ViewWeb className="two-checkbox-container">
					<SkioldCheckbox
						onClick={SetUsePrivateSlaughterHouseTrue}
						isChecked={generalSetting.usePrivateSlaughterhouse}
					/>
					<TextWeb>{localized('True')}</TextWeb>
					<SkioldCheckbox
						onClick={SetUsePrivateSlaughterHouseFalse}
						isChecked={!generalSetting.usePrivateSlaughterhouse}
					/>
					<TextWeb>{localized('False')}</TextWeb>
				</ViewWeb>
			),
		};
	};

	const getRegisterIdForGpeDeparture = (): FormRow => {
		return {
			name: localized('RegisterIdForGpeDeparture'),
			component: (
				<ViewWeb className="two-checkbox-container">
					<SkioldCheckbox
						onClick={SetRegisterIdForGpeDepartureTrue}
						isChecked={generalSetting.registerIdForGpeDeparture}
					/>
					<TextWeb>{localized('True')}</TextWeb>
					<SkioldCheckbox
						onClick={SetRegisterIdForGpeDepartureFalse}
						isChecked={!generalSetting.registerIdForGpeDeparture}
					/>
					<TextWeb>{localized('False')}</TextWeb>
				</ViewWeb>
			),
		};
	};

	const SetRegisterIdForGpeDepartureTrue = () => {
		setGeneralSetting({ ...generalSetting, registerIdForGpeDeparture: true });
	};

	const SetRegisterIdForGpeDepartureFalse = () => {
		setGeneralSetting({ ...generalSetting, registerIdForGpeDeparture: false });
	};

	const SetUsePrivateSlaughterHouseTrue = () => {
		setGeneralSetting({ ...generalSetting, usePrivateSlaughterhouse: true });
	};

	const SetUsePrivateSlaughterHouseFalse = () => {
		setGeneralSetting({ ...generalSetting, usePrivateSlaughterhouse: false });
	};

	return <SkioldFormsWrapper formRows={getFormRows()} containerClassName="wrapper-container" />;
});
