import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AnimalType, ImportFileData, ISection, LocationType } from 'shared/api/api';
import {
	CheckIfSectionUsesPens,
	FindHiddenPenObjectOnSection,
	getFilteredLocationsByTypesAndAnimalType,
	sortPensByName,
	usesPens,
} from 'shared/helpers/location-helper';
import { memoizeLocation } from 'shared/helpers/memoize-getters/memoize-getters';
import { UploadImportData } from 'shared/state/ducks/stem-animals/operations';
import { localized, localizedDynamic } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import PageContainer from 'web/view/components/page-container/page-container';
import { showAlert } from 'web/view/components/skiold-alert/skiold-alert';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldFormDropdown } from 'web/view/components/skiold-components/skiold-dropdown/skiold-form-dropdown';
import { Heading } from 'web/view/components/utils/heading';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import SkioldStableSectionPicker from '../../../location/location-picker/skiold-stable-section-picker';
import './import-data.scss';
import { Option } from 'react-dropdown';
import { generateOptionsForGrowthPigEventDistribute } from 'shared/helpers/growth-pigs-helper/growth-pig-event-function-helper';
import { SkioldDecimalInput } from 'web/view/components/skiold-components/skiold-decimal-input/skiold-decimal-input';
import { ConvertLocationTypeToAnimalType } from 'shared/helpers/general-helpers';
import { SkioldDropdown } from 'web/view/components/skiold-components/skiold-dropdown/skiold-dropdown';
import { memoizeBreedNumberOptions } from 'shared/helpers/nucleus-management-helper/nucleus-management-helper';
import { getBreedingSettings } from 'shared/state/ducks/nucleus-management-breeding-settings/operation';
import { selectNucleusManagementOrAssignIdAccess } from 'shared/state/ducks/site/reducer';

const mapStateToProps = (state: WebAppState) => {
	return {
		siteId: state.profile.active!.siteId,
		locations: memoizeLocation(
			state.locations.buildings,
			state.locations.sections,
			state.locations.pens,
			state.locations.valves,
		),
		nucleusManagement: selectNucleusManagementOrAssignIdAccess(state),
		breedNumberOptions: memoizeBreedNumberOptions(state.nucleusManagementBreedingSettings.breedingSettings),
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	uploadImportData: (fileData: ImportFileData, errorCallback: (errorCode: number, message: string) => void) =>
		UploadImportData(fileData, errorCallback)(dispatch),
	getBreedingSettings: (siteId: string) => dispatch(getBreedingSettings(siteId) as any),
});

export interface ImportDataState {
	importFile: File;
	usesPens: boolean;
	toSectionId: string;
	toPenId?: Option;
	fromPenId?: Option;
	fromOptions: Option[];
	toOptions: Option[];
	totalWeight?: number;
	avgWeight?: number;
	section?: ISection;
	breedNumberOption: Option | undefined;
}

interface PropsFromParent {
	closeModal?: () => void;
	theme?: 'light' | 'dark';
}

const animalTypes = [AnimalType.Sow, AnimalType.Weaner, AnimalType.Finisher];
const locationTypes = [LocationType.YoungFemale, LocationType.Weaners, LocationType.Finisher];

type ImportDataProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;
class ImportData extends React.PureComponent<ImportDataProps, ImportDataState> {
	public static defaultProps: Partial<ImportDataProps> = {
		theme: 'light',
	};

	private loading = false;
	constructor(props: ImportDataProps) {
		super(props);

		this.state = {
			importFile: new File([''], ''),
			usesPens: false,
			toSectionId: '',
			toPenId: undefined,
			fromPenId: undefined,
			toOptions: [],
			fromOptions: [],
			breedNumberOption: undefined,
		};
	}

	public handleFile(files: FileList) {
		if (files[0] !== undefined) {
			const file = files[0];
			this.loading = true;

			this.setState({ importFile: file }, () => {
				this.loading = false;
			});
		}
	}

	public componentDidMount() {
		this.props.siteId && this.props.getBreedingSettings(this.props.siteId);
	}

	public componentDidUpdate(prevProps: ImportDataProps, prevState: ImportDataState) {
		if (this.props.breedNumberOptions && this.props.breedNumberOptions.length > 0 && !prevState.breedNumberOption) {
			this.setState({ breedNumberOption: this.props.breedNumberOptions[0] });
		}
	}

	public render() {
		return (
			<PageContainer>
				<ViewWeb className="import-data">
					<Heading text={localized('import')} />
					<ViewWeb>
						<div className="import-container">
							<div className="flex-row upper-col">
								<div className="flex-column">
									<label className="choose-file-labe-input">
										{localized('chooseImportFile')}
										<input
											className="file-input-style"
											type="file"
											accept=".txt, .csv"
											name="file"
											onChange={this.importFileChanged}
										/>
									</label>
									<label className="label-file-chosen">
										{localized('chosenFile')}: {this.state.importFile.name}
									</label>
									{this.renderBreednumber()}
								</div>
								<div>
									<div className="flex-row">
										<TextWeb className="location-label">{localized('chooseLocation')}:</TextWeb>
										<SkioldStableSectionPicker
											filteredLocations={getFilteredLocationsByTypesAndAnimalType(
												this.props.locations,
												locationTypes,
												animalTypes,
											)}
											onStableSectionSelected={this.onStableSectionChanged}
											containerClassName="picker-width"
										/>
									</div>
									{usesPens(this.state.toSectionId) && (
										<>
											<div className="flex-row">
												<TextWeb className="location-label">{localized('FromPen')}:</TextWeb>
												<SkioldFormDropdown
													theme="light"
													onValueChanged={this.fromPenChanged}
													items={this.state.fromOptions}
													selectedValue={this.state.fromPenId}
												/>
											</div>
											<div className="flex-row">
												<TextWeb className="location-label">{localized('ToPen')}:</TextWeb>
												<SkioldFormDropdown
													theme="light"
													onValueChanged={this.toPenChanged}
													items={this.state.toOptions}
													selectedValue={this.state.toPenId}
												/>
											</div>
										</>
									)}
									{this.state.section &&
										(ConvertLocationTypeToAnimalType(this.state.section.type) ===
											AnimalType.Weaner ||
											ConvertLocationTypeToAnimalType(this.state.section.type) ===
												AnimalType.Finisher) && (
											<div className="flex-row">
												<TextWeb className="location-label">{localized('Weight')}:</TextWeb>
												<TextWeb className="location-label">{localized('avg')}:</TextWeb>
												<ViewWeb className={'underline'}>
													<SkioldDecimalInput
														className="default-input-text"
														onChangeNumber={this.avgWeightChanged}
														text={this.state.avgWeight}
													/>
												</ViewWeb>
												<TextWeb className="location-label">{localized('Total')}:</TextWeb>
												<ViewWeb className={'underline'}>
													<SkioldDecimalInput
														className="default-input-text"
														onChangeNumber={this.totalWeightChanged}
														text={this.state.totalWeight}
													/>
												</ViewWeb>
											</div>
										)}
								</div>
							</div>
						</div>
						{this.renderButtons()}
					</ViewWeb>
				</ViewWeb>
			</PageContainer>
		);
	}

	private renderBreednumber = () => {
		if (this.props.nucleusManagement && this.props.breedNumberOptions && this.state.breedNumberOption) {
			return (
				<div className="flex-row align-items-center">
					<label className="label-file-chosen">{localized('BreedingNumber')}:</label>
					{this.props.breedNumberOptions.length > 1 ? (
						<SkioldDropdown
							items={this.props.breedNumberOptions}
							selectedValue={this.state.breedNumberOption}
							onValueChanged={this.setBreedNumberOption}
						/>
					) : (
						<label className="label-file-chosen">{this.state.breedNumberOption.label}</label>
					)}
				</div>
			);
		}

		return null;
	};

	private setBreedNumberOption = (breedNumberOption: Option) => {
		this.setState({ breedNumberOption });
	};

	private avgWeightChanged = (avgWeight: number | undefined) => {
		this.setState({ avgWeight, totalWeight: undefined });
	};
	private totalWeightChanged = (totalWeight: number | undefined) => {
		this.setState({ totalWeight, avgWeight: undefined });
	};

	private toPenChanged = (toPenId: Option) => {
		const pensInSection = this.props.locations.pens
			.filter(p => p.sectionId === this.state.toSectionId)
			.sort(sortPensByName);
		const index = pensInSection.findIndex(p => toPenId && p.id === toPenId.value);
		let state = { toPenId };
		if (index >= 0) {
			const indexFrom = pensInSection.findIndex(p => this.state.fromPenId && p.id === this.state.fromPenId.value);
			const options = generateOptionsForGrowthPigEventDistribute(
				pensInSection,
				pensInSection[indexFrom >= 0 ? indexFrom : 0],
				pensInSection[index],
			);
			state = { ...state, ...options };
		}
		this.setState(state);
	};

	private fromPenChanged = (fromPenId: Option) => {
		const pensInSection = this.props.locations.pens
			.filter(p => p.sectionId === this.state.toSectionId)
			.sort(sortPensByName);
		const index = pensInSection.findIndex(p => fromPenId && p.id === fromPenId.value);
		let state = { fromPenId };
		if (index >= 0) {
			const indexTo = pensInSection.findIndex(p => this.state.toPenId && p.id === this.state.toPenId.value);
			const options = generateOptionsForGrowthPigEventDistribute(
				pensInSection,
				pensInSection[index],
				pensInSection[indexTo],
			);
			state = { ...state, ...options };
		}
		this.setState(state);
	};

	private onStableSectionChanged = (sectionId: string) => {
		const section = this.props.locations.sections.find(p => p.id === sectionId);
		if (section) {
			if (CheckIfSectionUsesPens(sectionId)) {
				let penOptions: { fromOptions: Option[]; toOptions: Option[] } = { fromOptions: [], toOptions: [] };
				const pensInSection = this.props.locations.pens
					.filter(p => p.sectionId === sectionId)
					.sort(sortPensByName);
				if (pensInSection.length > 0) {
					penOptions = generateOptionsForGrowthPigEventDistribute(
						pensInSection,
						pensInSection[0],
						pensInSection[pensInSection.length - 1],
					);
				}
				this.setState({
					...penOptions,
					fromPenId: { label: pensInSection[0].name!, value: pensInSection[0].id! } as Option,
					toPenId: {
						label: pensInSection[pensInSection.length - 1].name!,
						value: pensInSection[pensInSection.length - 1].id!,
					} as Option,
					toSectionId: sectionId,
					section,
				});
			} else {
				let pen = FindHiddenPenObjectOnSection(sectionId);
				if (pen) {
					this.setState({
						toPenId: {
							label: pen.name,
							value: pen.id,
						} as Option,
						toSectionId: sectionId,
						fromPenId: undefined,
						section,
					});
				} else {
					this.setState({ toSectionId: sectionId });
				}
			}
		}
	};

	private importFileChanged = val => {
		this.handleFile(val.target.files!);
		val.target.files = null;
	};
	private renderButtons() {
		return (
			<ViewWeb className="render-buttons-container">
				{this.props.closeModal && (
					<SkioldButton title={localized('Close')} onPress={this.props.closeModal} theme="grey" />
				)}
				<SkioldButton title={localized('startImport')} onPress={this.import} />
			</ViewWeb>
		);
	}

	private import = async () => {
		if (this.state.importFile.name !== '' && !this.loading) {
			if (this.state.toSectionId.length === 0 || !this.state.section) {
				await showAlert(localized('YouHaveToSelectLocation'));
			} else if (
				(ConvertLocationTypeToAnimalType(this.state.section.type) === AnimalType.Weaner ||
					ConvertLocationTypeToAnimalType(this.state.section.type) === AnimalType.Finisher) &&
				!this.state.avgWeight &&
				!this.state.totalWeight
			) {
				await showAlert(localized('specifyWeight'));
			} else {
				let fileBase64 = '';
				const reader = new FileReader();

				// Find pens to use, in between from- and to- pen
				const pensInSection = this.props.locations.pens
					.filter(p => p.id && p.sectionId === this.state.toSectionId)
					.sort(sortPensByName);

				const toPenToUseIndex = pensInSection.findIndex(
					p => this.state.toPenId && p.id === this.state.toPenId.value,
				);
				const fromPenToUseIndex = pensInSection.findIndex(
					p => this.state.fromPenId && p.id === this.state.fromPenId.value,
				);
				const pensToUse = pensInSection.slice(fromPenToUseIndex, toPenToUseIndex + 1).map(e => e.id!);

				reader.readAsDataURL(this.state.importFile);
				const cb = (base64String: string) => {
					const data = ImportFileData.fromJS({});
					data.siteId = this.props.siteId;
					data.base64String = base64String;
					data.penIds = pensToUse;
					data.animalType = this.state.section ? this.state.section.animalType! : undefined!;
					data.avgWeight = this.state.avgWeight;
					data.totalWeight = this.state.totalWeight;
					data.locationType = this.state.section ? this.state.section.type : undefined!;
					data.breedingNumber = this.state.breedNumberOption && this.state.breedNumberOption.label;
					this.props.uploadImportData(data, this.errorCallback);
					this.setState({ importFile: new File([''], '') });
					this.props.closeModal!();
				};
				reader.onload = () => {
					fileBase64 = '' + reader.result;
					cb(fileBase64);
				};
				reader.onerror = error => {
					console.log('Error: ', error);
				};
			}
		}
	};

	private errorCallback = (errorCode: number, message: string) => {
		if (errorCode === 500 && message.includes('VALIDATION_ERROR')) {
			console.log('err', message);
			showAlert(localizedDynamic(message.toString()));
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportData);
