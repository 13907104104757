import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import { StoreContainer } from 'shared/state/store-container';
import * as Action from './actions';

export function GetAnalysisResultYoungFemaleAgeData(fromDate?: Date, toDate?: Date) {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;

	return AsyncOperationBuilder2(
		Action.getData,
		client => client.analysis_ResultYoungFemaleAge(siteId, fromDate, toDate),
		{
			siteId,
			fromDate,
			toDate,
		},
	);
}

export function GetElaborationAnalysisResultYoungFemaleAgeData(fromDate?: Date, toDate?: Date) {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;

	return AsyncOperationBuilder2(
		Action.getElaborationData,
		client => client.analysis_ElaborationResultYoungFemaleAge(siteId, fromDate, toDate),
		{
			siteId,
			fromDate,
			toDate,
		},
	);
}
