import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnimalType, GpeDashboardSettingItem, IDashboardSettings, LocationType } from 'shared/api/api';
import { deepCopy2 } from 'shared/helpers/general-helpers';
import { SaveDashboardSettings } from 'shared/state/ducks/dashboard-settings/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import PReportGrowthPigSetup from 'web/view/components/growth-pigs/growth-pig-report-setup/growth-pig-p-report-setup';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldTouchableOpacity } from 'web/view/components/skiold-components/skiold-touchable-opacity';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { GpeDashboardSettings } from './gpe-dashboard-settings';

interface Props {
	animalType: AnimalType;
}

export const AnimalReportOverviewSetting: FC<Props> = React.memo(props => {
	const dispatch = useDispatch();
	const gpeDashboardItems = useSelector(
		(state: WebAppState) => state.dashboardSettings.entity.gpeDashboardSettingItems,
	);
	const dashboardSetting = useSelector((state: WebAppState) => state.dashboardSettings.entity);
	const buildings = useSelector((state: WebAppState) => state.locations.buildings);

	const [gpeDashboardItemsState, setGpeDashboardItemsState] = useState<GpeDashboardSettingItem[]>([]);

	useEffect(() => {
		let items: GpeDashboardSettingItem[] = [];
		if (gpeDashboardItems) {
			items = items.concat(gpeDashboardItems);
		}
		if (!items.find(e => e.animalType === AnimalType.Weaner)) {
			items.push({
				predictedWeightAtSale: 30,
				subGoal: 28,
				animalType: AnimalType.Weaner,
			} as GpeDashboardSettingItem);
		}

		if (!items.find(e => e.animalType === AnimalType.Finisher)) {
			items.push({
				predictedWeightAtSale: 115,
				subGoal: 28,
				animalType: AnimalType.Finisher,
			} as GpeDashboardSettingItem);
		}

		// TODO: use buildings after Agro
		// buildings.forEach(building => {
		// 	const found = items.find(i => i.buildingId === building.id);
		// 	if (!found) {
		// 		items.push({
		// 			buildingId: building.id,
		// 			predictedWeightAtSale: 28,
		// 			subGoal: 28,
		// 		} as GpeDashboardSettingItem);
		// 	}
		// });

		setGpeDashboardItemsState(items);
	}, [buildings, gpeDashboardItems]);

	const [reportOptions] = useState<string[]>(['Dashboard', 'KeyNumbers']);

	const [reportOption, setReportOption] = useState<string>(reportOptions[0]);
	const renderOption = (option: string) => {
		const onPress = () => {
			setReportOption(option);
		};

		return (
			<ViewWeb key={option} className="animal-report-type-picker">
				<SkioldTouchableOpacity onPress={onPress}>
					<ViewWeb className="animal-report-type-picker">
						<TextWeb className="item-text-style">{localized(option)}</TextWeb>
					</ViewWeb>
				</SkioldTouchableOpacity>
			</ViewWeb>
		);
	};
	const renderButtons = () => {
		return (
			<ViewWeb className="view-button-container">
				<SkioldButton title={localized('Save')} onPress={saveDashboardSetting} />
			</ViewWeb>
		);
	};

	const saveDashboardSetting = () => {
		let newSetting = deepCopy2(dashboardSetting) as IDashboardSettings;
		newSetting.gpeDashboardSettingItems = gpeDashboardItemsState;
		dispatch(SaveDashboardSettings(newSetting));
	};

	const itemChanged = (item: GpeDashboardSettingItem) => {
		setGpeDashboardItemsState(
			gpeDashboardItemsState.map(i =>
				i.animalType === item.animalType
					? ({ ...item } as GpeDashboardSettingItem)
					: ({ ...i } as GpeDashboardSettingItem),
			),
		);
	};

	const renderItem = (item: GpeDashboardSettingItem) => {
		if (props.animalType === AnimalType.Finisher && item.animalType === AnimalType.Finisher) {
			return (
				<GpeDashboardSettings key={item.animalType} item={item} buildings={buildings} onChange={itemChanged} />
			);
		} else if (props.animalType === AnimalType.Weaner && item.animalType === AnimalType.Weaner) {
			return (
				<GpeDashboardSettings key={item.animalType} item={item} buildings={buildings} onChange={itemChanged} />
			);
		}
		return null;
	};

	return (
		<ViewWeb className="general-settings-overview">
			<ViewWeb className="sub-container">
				<TextWeb className="header-text-style">{localized('reports')}</TextWeb>
				{reportOptions.map(type => renderOption(type))}
			</ViewWeb>
			<ViewWeb className="setup-container">
				<ViewWeb className="settings-width-600">
					<TextWeb className="text-style">{localized('Setup')}</TextWeb>
					{reportOption === 'Dashboard' && <>{gpeDashboardItemsState.map(renderItem)}</>}
					{reportOption === 'KeyNumbers' && (
						<>
							<PReportGrowthPigSetup animalType={props.animalType} />
						</>
					)}

					{renderButtons()}
				</ViewWeb>
			</ViewWeb>
		</ViewWeb>
	);
});
