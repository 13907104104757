import { Action } from 'redux';
import { IReasonDto, ReasonDto } from 'shared/api/api';
import {
	mergeArraysNoUnique,
	removeValueFromArrayNoUnique,
	upsertValueInArrayNoUnique,
} from 'shared/helpers/reducer-helpers';
import { SyncableInitialState } from 'shared/state/models/syncable';
import { SharedAppState } from 'shared/state/store.shared';
import { isType } from 'typescript-fsa';
import { siteChange } from '../profile/actions';
import { getSyncModelData } from '../sync/actions';
import { getSyncData, saveReason, saveReasonSettings } from './actions';
import { ResasonsState } from './types';

export const initialState: ResasonsState = {
	...SyncableInitialState,
};

const reasonsReducer = (state: ResasonsState = initialState, action: Action): ResasonsState => {
	if (isType(action, getSyncData.started)) {
		state = { ...state, syncInProgress: true };
	}

    if (isType(action, getSyncData.done)) {
		if (action.payload.result && action.payload.result.datas!.length) {
			state = {
				...state,
				entities: mergeArraysNoUnique(
					state.entities,
					action.payload.result.datas!,
					(t: IReasonDto) => t.reason!.id!,
					(t: IReasonDto) => t.reason!.isDeleted!,
				),
                lastSyncDate: action.payload.result.syncTime!,
			};
		}

		state = {
			...state,
		
			syncInProgress: false,
		};
	}

	if (isType(action, getSyncModelData.done)) {
		if (action.payload.result.reasons && action.payload.result.reasons.datas!.length) {
			state = {
				...state,
				entities: mergeArraysNoUnique(
					state.entities,
					action.payload.result.reasons.datas!,
					(t: IReasonDto) => t.reason!.id!,
					(t: IReasonDto) => t.reason!.isDeleted!,
				),
                lastSyncDate: action.payload.result.reasons.syncTime!,
			};
		}

		state = {
			...state,
		
			syncInProgress: false,
		};
	}

	if (isType(action, getSyncData.failed)) {
		state = { ...state, syncInProgress: false };
	}

	if (isType(action, saveReason.started)) {
		state = {
			...state,
			updates: upsertValueInArrayNoUnique(state.updates, action.payload, (t: IReasonDto) => t.reason!.id!),
			entities: upsertValueInArrayNoUnique(state.entities, action.payload, (t: IReasonDto) => t.reason!.id!),
		};
	}

	if (isType(action, saveReason.failed)) {
		if (action.payload.error.code === 500) {
			if (action.payload.error.prevEntity) {
				state = {
					...state,
					entities: upsertValueInArrayNoUnique(
						state.entities,
						action.payload.error.prevEntity,
						(t: IReasonDto) => t.reason!.id!,
					),
				};
			} else {
				state = {
					...state,
					entities: removeValueFromArrayNoUnique(
						state.entities,
						action.payload.params.reason!.id!,
						(t: IReasonDto) => t.reason!.id!,
					),
				};
			}
			state = {
				...state,
				updates: removeValueFromArrayNoUnique(
					state.updates,
					action.payload.params.reason!.id!,
					(t: IReasonDto) => t.reason!.id!,
				),
			};
		}

		return state;
	}

	if (isType(action, saveReason.done)) {
		state = {
			...state,
			entities: upsertValueInArrayNoUnique(
				state.entities,
				action.payload.params,
				(t: IReasonDto) => t.reason!.id!,
			),
			updates: removeValueFromArrayNoUnique(
				state.updates,
				action.payload.params.reason!.id!,
				(t: IReasonDto) => t.reason!.id!,
			),
		};

		return state;
	}

	if (isType(action, saveReasonSettings.started)) {
		state = {
			...state,
			updates: upsertValueInArrayNoUnique(state.updates, action.payload, (t: IReasonDto) => t.reason!.id!),
			entities: upsertValueInArrayNoUnique(state.entities, action.payload, (t: IReasonDto) => t.reason!.id!),
		};
	}

	if (isType(action, saveReasonSettings.failed)) {
		if (action.payload.error.code === 500) {
			if (action.payload.error.prevEntity) {
				state = {
					...state,
					entities: upsertValueInArrayNoUnique(
						state.entities,
						action.payload.error.prevEntity,
						(t: IReasonDto) => t.reason!.id!,
					),
				};
			} else {
				state = {
					...state,
					entities: removeValueFromArrayNoUnique(
						state.entities,
						action.payload.params.reason!.id!,
						(t: IReasonDto) => t.reason!.id!,
					),
				};
			}
			state = {
				...state,
				updates: removeValueFromArrayNoUnique(
					state.updates,
					action.payload.params.reason!.id!,
					(t: IReasonDto) => t.reason!.id!,
				),
			};
		}

		return state;
	}

	if (isType(action, saveReasonSettings.done)) {
		state = {
			...state,
			entities: upsertValueInArrayNoUnique(
				state.entities,
				action.payload.params,
				(t: IReasonDto) => t.reason!.id!,
			),
			updates: removeValueFromArrayNoUnique(
				state.updates,
				action.payload.params.reason!.id!,
				(t: IReasonDto) => t.reason!.id!,
			),
		};

		return state;
	}

	if (isType(action, siteChange.done)) {
		state = initialState;
	}

	//Ensure Date objects are in fact date and not strings - redux-persist serializes dates to string but doesn't deserialize to dates again
	if (action.type === 'persist/REHYDRATE') {
		let a = (action as any) as { payload: SharedAppState; key: string };

		if (a.key === 'root') {
			let entities = new Array<ReasonDto>();
			let updates = new Array<ReasonDto>();
			let lastSyncDate = new Date(-8640000000000000);

			if (a.payload && a.payload.reasons && a.payload.reasons.entities) {
				entities = a.payload.reasons.entities.map((dt: IReasonDto) => {
					let ndt = new ReasonDto();
					ndt.init(dt);
					return ndt;
				});
			}

			if (a.payload && a.payload.reasons && a.payload.reasons.updates) {
				updates = a.payload.reasons.updates.map((dt: IReasonDto) => {
					let ndt = new ReasonDto();
					ndt.init(dt);
					return ndt;
				});
			}

			if (a.payload && a.payload.reasons && a.payload.reasons.lastSyncDate) {
				lastSyncDate = new Date(a.payload.reasons.lastSyncDate);
			}

			state = { ...state, entities, updates, lastSyncDate };
		}
	}

	return state;
};

export default reasonsReducer;
