import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import SkioldIcon from 'shared/assets/src-assets/png/skiold_S.png';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { goToPage } from 'web/state/ducks/navigation/actions';
import { ROUTE_SIGN_IN_REDIRECT } from 'web/state/ducks/navigation/types';
import { WebAppState } from 'web/state/store.web';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldImage } from 'web/view/components/utils/svg/skiold-image';
import './signin-page.scss';

const mapStateToProps = (state: WebAppState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	navigateToSigninRedirect: () => dispatch(goToPage(ROUTE_SIGN_IN_REDIRECT))
});

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

class SignInPage extends React.PureComponent<Props, {}> {

	public render() {
		return (
			<ViewWeb className="signin-container">
				<SkioldImage width={200} height={200} imageData={SkioldIcon} />
				<SkioldButton title="Login" theme="orange" onPress={this.signIn} />
			</ViewWeb>
		);
	}

	private signIn = () => {
		this.props.navigateToSigninRedirect();
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SignInPage);
