import * as types from './types';
import actionCreatorFactory from 'typescript-fsa';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import { IValveGroupHistory2 } from 'shared/api/api';

const actionCreator = actionCreatorFactory(GenActionFactoryName('history'));

export const fetchHistory = actionCreator.async<
	{ zoneId: string; siteId: string; activeSiteId?: string },
	IValveGroupHistory2[],
	{ code: number; message: string }
>(types.FETCH_HISTORY);

export const fetchLatestHistory = actionCreator.async<
	{ zoneId: string; siteId: string; activeSiteId?: string },
	IValveGroupHistory2,
	{ code: number; message: string }
>(types.FETCH_LATEST_HISTORY);
