import { Sorting } from '@devexpress/dx-react-grid';
import memoize from 'memoize-one';
import React from 'react';
import { Option } from 'react-dropdown';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
	DistriwinFeedUnitType,
	Feeding,
	FeedingAmountType,
	IDistriwinFeedConsumptionDto,
	IFeeding,
} from 'shared/api/api';
import DeleteIcon from 'shared/assets/src-assets/png/delete_icon.png';
import PenIcon from 'shared/assets/src-assets/png/pen_icon.png';
import { getDateString } from 'shared/helpers/date-helpers';
import { getFeedUnitType } from 'shared/helpers/feeding/feeding-helper';
import { exactNumberFilterMethodGrid } from 'shared/helpers/general-helpers';
import { getBuildingBySectionId, getSectionBySectionId, NaturalSortBuildSection } from 'shared/helpers/location-helper';
import { memoizeHashmapLocation } from 'shared/helpers/memoize-getters/memoize-getters';
import { NaturalSortDates } from 'shared/helpers/natural-sort';
import { saveFeeding } from 'shared/state/ducks/feeding/operations';
import { localized, localizedDynamic, localizedInterpolation } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { SowListConstants } from 'web/view/components/stem-animal/animal-lists/table-constants';
import { SkioldImage } from 'web/view/components/utils/svg/skiold-image';
import { SkioldModal } from '../skiold-components/skiold-modal/skiold-modal';
import SkioldTableGrid from '../skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { SkioldTouchableOpacity } from '../skiold-components/skiold-touchable-opacity';
import { ViewWeb } from '../utils/web-view';
import { DistriwinPenFeedingTable } from './distriwin-feedusage-pen';
import { FeedingAdjustmentTableModel } from './feeding-table-model';
import { generateFeedingAdjustmentTableModelByConsumptions, getFeedingUsagesByFeeding } from './food-helper';
import { Building, Pen, Section } from 'shared/state/ducks/locations';
import './feeding.scss';

interface PropsFromParent {
	fromDate: Date | undefined;
	toDate: Date | undefined;
	deleteCallback: (feeding: FeedingAdjustmentTableModel) => void;
	editCallback: (feeding: FeedingAdjustmentTableModel) => void;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		siteId: state.profile.active!.siteId,
		feedings: state.feeding.feedings,
		userProfile: state.profile.active!,
		feedingSubjects: state.feedingSubject.entities,
		feedingCategory: state.feedingCategory.entities,
		buildings: state.locations.buildings,
		consumptionDtos: state.distriwinFeedConsumptions.dtoEntities,
		locations: memoizeHashmapLocation(state.locations.buildings, state.locations.sections, state.locations.pens),
		feedUnitType: getFeedUnitType(state),
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	saveFeeding: (feeding: Feeding) => saveFeeding(feeding)(dispatch),
});

interface State {
	feedingAdjustmentTableModels?: FeedingAdjustmentTableModel[];
	dateFrom?: Date;
	dateTo?: Date;
	feedings?: IFeeding[];
	editCreateModalOpen: boolean;
	editCreateUsageModalOpen: boolean;
	adjustmentId?: string;
	feedingId?: string;
	selectedType: Option;
	modalSectionId?: { sectionId: string; date?: Date };
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

export class FeedingUsageTable extends React.PureComponent<Props, State> {
	private generateColumnData = memoize((selectedType: FeedingAmountType) => {
		return this.generateColumns();
	});

	private generateData = memoize(
		(
			selectedType: FeedingAmountType,
			feedings,
			consumptionDtos: IDistriwinFeedConsumptionDto[],
			locations: {
				[key: string]: Building | Section | Pen;
			},
			fromDate?: Date,
			toDate?: Date,
		) => {
			return this.getFilteredData(selectedType, feedings, consumptionDtos, locations);
		},
	);

	private readonly getPriceCell = (adjustment: FeedingAdjustmentTableModel) => adjustment.pricePer100Kg;

	private readonly getAmountInKgCell = (adjustment: FeedingAdjustmentTableModel) => adjustment.amount!.toFixed(2);

	private readonly getFeedingCategoryCell = (adjustment: FeedingAdjustmentTableModel) => adjustment.FeedingCategory;

	private readonly getFeedingSubjectCell = (adjustment: FeedingAdjustmentTableModel) => adjustment.FeedingSubject;

	private readonly getAnimalTypeCell = (adjustment: FeedingAdjustmentTableModel) =>
		adjustment.animalType && localized(adjustment.animalType);

	private readonly getProductionTypeCell = (adjustment: FeedingAdjustmentTableModel) => {
		if (adjustment.productionType) {
			return localized(adjustment.productionType);
		}
		return undefined;
	};

	private readonly getBuildingCell = (adjustment: FeedingAdjustmentTableModel) => {
		if (adjustment.sectionId) {
			const building = getBuildingBySectionId(adjustment.sectionId, this.props.locations);
			return building && building.name;
		}
		return undefined;
	};

	private OpenModal = (feeding: FeedingAdjustmentTableModel) => {
		if (feeding.sectionId) {
			this.setState({
				modalSectionId: { sectionId: feeding.sectionId, date: feeding.periodTo },
			});
		}
	};

	private closeModal = () => {
		this.setState({
			modalSectionId: undefined,
		});
	};

	private readonly getSectionCell = (adjustment: FeedingAdjustmentTableModel) => {
		if (adjustment.sectionId) {
			const section = getSectionBySectionId(adjustment.sectionId, this.props.locations);
			return adjustment.isDistriwinData ? (
				<SkioldTouchableOpacity itemFromParent={adjustment} onPress={this.OpenModal}>
					{<ViewWeb className="folder-arrow arrow-folded" />}
					{adjustment.sectionName}
				</SkioldTouchableOpacity>
			) : (
				adjustment.sectionName
			);
		}
		return '';
	};

	private readonly getPeriodToCell = (adjustment: FeedingAdjustmentTableModel) => getDateString(adjustment.periodTo);

	private readonly getPeriodFromCell = (adjustment: FeedingAdjustmentTableModel) =>
		getDateString(adjustment.periodFrom);

	private readonly deleteFeeding = (feeding: FeedingAdjustmentTableModel) => {
		this.deleteFeedingUsage(feeding);
	};

	private readonly getDeleteCell = (feeding: FeedingAdjustmentTableModel) =>
		!feeding.isDistriwinData && (
			<SkioldTouchableOpacity itemFromParent={feeding} onPress={this.deleteFeeding}>
				<SkioldImage
					width={SowListConstants.iconSVGWidth}
					height={SowListConstants.iconSVGWidth}
					imageData={DeleteIcon}
				/>
			</SkioldTouchableOpacity>
		);

	private readonly editFeedingUsage = (feeding: FeedingAdjustmentTableModel) => {
		this.editFeedingAdjustment(feeding);
	};

	private readonly filterSection = (value: any, filterValue: any) => {
		let v = value && value.props ? value.props.itemFromParent.sectionName : value;

		return exactNumberFilterMethodGrid(v, filterValue);
	};
	private readonly sortSection = (data1: any, data2: any) => {
		let d1 = data1 && data1.props ? data1.props.itemFromParent : data1;
		let d2 = data2 && data2.props ? data2.props.itemFromParent : data2;

		return NaturalSortBuildSection(d1, d2);
	};

	private readonly getEditCell = (feeding: FeedingAdjustmentTableModel) =>
		!feeding.isDistriwinData && (
			<SkioldTouchableOpacity itemFromParent={feeding} onPress={this.editFeedingUsage}>
				<SkioldImage
					width={SowListConstants.iconSVGWidth}
					height={SowListConstants.iconSVGWidth}
					imageData={PenIcon}
				/>
			</SkioldTouchableOpacity>
		);

	constructor(props: Props) {
		super(props);
		this.state = {
			selectedType: {
				value: FeedingAmountType.FeedingAdjustment,
				label: localized(FeedingAmountType.FeedingAdjustment+'_purchase'),
			},
			editCreateModalOpen: false,
			editCreateUsageModalOpen: false,
			adjustmentId: undefined,
			feedingId: undefined,
			feedingAdjustmentTableModels: undefined,
			feedings: undefined,
			modalSectionId: undefined,
		};
	}

	public generateColumnsExtensions() {
		let columnExts: any[] = [
			{
				columnName: 'edit',
				width: SowListConstants.iconWidth,
				filteringEnabled: false,
				sortingEnabled: false,
				resizingEnabled: false,
			},
			{
				columnName: 'delete',
				width: SowListConstants.iconWidth,
				filteringEnabled: false,
				sortingEnabled: false,
				resizingEnabled: false,
			},
			{
				columnName: 'periodFrom',
				width: SowListConstants.indexDateWidth,
			},
			{
				columnName: 'periodTo',
				width: SowListConstants.indexDateWidth,
			},
			{
				columnName: 'animalType',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'productionType',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'building',
				width: SowListConstants.buildingWidth,
			},
			{
				columnName: 'section',
				width: SowListConstants.sectionWidth,
			},
			{
				columnName: 'feedingSubject',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'feedingCategory',
				width: 160,
			},
			{
				columnName: 'amountInKg',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'Energy1',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'price',
				width: SowListConstants.entranceDateWidth,
			},
		];
		if (this.props.feedUnitType === DistriwinFeedUnitType.FEsoFEsv) {
			columnExts.splice(columnExts.length - 1, 0, {
				columnName: 'Energy2',
				width: SowListConstants.animalNrWidth,
			});
		}
		return columnExts;
	}

	private generateColumns(): any {
		let columns: any[] = [
			{
				name: 'edit',
				title: ' ',
				sortable: false,
				getCellValue: this.getEditCell,
			},
			{
				name: 'delete',
				title: ' ',
				sortable: false,
				getCellValue: this.getDeleteCell,
			},
			{
				name: 'periodFrom',
				sortFunction: NaturalSortDates,
				title: localized('PeriodFrom'),
				getCellValue: this.getPeriodFromCell,
			},
			{
				name: 'periodTo',
				sortFunction: NaturalSortDates,
				title: localized('PeriodTo'),
				getCellValue: this.getPeriodToCell,
			},
			{
				name: 'animalType',
				title: localized('pigType'),
				getCellValue: this.getAnimalTypeCell,
			},
			{
				name: 'productionType',
				title: localized('production'),
				getCellValue: this.getProductionTypeCell,
			},
			{
				name: 'building',
				title: localized('building'),
				getCellValue: this.getBuildingCell,
			},
			{
				name: 'section',
				title: localized('section'),
				getCellValue: this.getSectionCell,
				filterFunction: this.filterSection,
				sortFunction: this.sortSection,
			},
			{
				name: 'feedingSubject',
				title: localized('FeedingSubject'),
				getCellValue: this.getFeedingSubjectCell,
			},
			{
				name: 'feedingCategory',
				title: localized('Type'),
				getCellValue: this.getFeedingCategoryCell,
			},
			{
				name: 'amountInKg',
				title: localized('AmountInKgs'),
				getCellValue: this.getAmountInKgCell,
			},
			{
				name: 'Energy1',
				title: localizedInterpolation('EnergyKg', this.props.feedUnitType + 'Energy1'),
				getCellValue: this.getEnergy1PerKgCell,
			},

			{
				name: 'price',
				title: localized('PricePer100Kgs'),
				getCellValue: this.getPriceCell,
			},
		];
		if (this.props.feedUnitType === DistriwinFeedUnitType.FEsoFEsv) {
			columns.splice(columns.length - 1, 0, {
				name: 'Energy2',
				title: localizedInterpolation('EnergyKg', this.props.feedUnitType + 'Energy2'),
				getCellValue: this.getEnergy2PerKgCell,
			});
		}
		return columns;
	}

	private getEnergy1PerKgCell = (adjustment: FeedingAdjustmentTableModel) =>
		this.state.selectedType.value === FeedingAmountType.FeedingAdjustment
			? adjustment.energy1PerKg!.toFixed(2)
			: (adjustment.energy1PerKg! / adjustment.amount!).toFixed(2);

	private getEnergy2PerKgCell = (adjustment: FeedingAdjustmentTableModel) =>
		this.state.selectedType.value === FeedingAmountType.FeedingAdjustment
			? adjustment.energy2PerKg!.toFixed(2)
			: (adjustment.energy2PerKg! / adjustment.amount!).toFixed(2);

	private getBuildingName = (usage: FeedingAdjustmentTableModel) => {
		const building = this.props.buildings.find(building => building.id === usage.buildingId);
		if (building) {
			return building.name;
		}
		return localizedDynamic(usage.defaultBuildingName ? usage.defaultBuildingName : '');
	};

	private async deleteFeedingUsage(feeding: FeedingAdjustmentTableModel) {
		this.props.deleteCallback(feeding);
	}

	private editFeedingAdjustment(feeding: FeedingAdjustmentTableModel) {
		this.props.editCallback(feeding);
	}

	private defaultSorting = [{ columnName: 'date', direction: 'asc' }] as Sorting[];

	public render() {
		let data = this.generateData(
			this.state.selectedType.value as FeedingAmountType,
			this.props.feedings,
			this.props.consumptionDtos,
			this.props.locations,
			this.props.fromDate,
			this.props.toDate,
		);
		return (
			<ViewWeb className="marginHorizontalTen">
				<SkioldTableGrid
					columns={this.generateColumnData(this.state.selectedType.value as FeedingAmountType)}
					tableKey={'FeedingUsageTableLog2'}
					ColumnExtensions={this.generateColumnsExtensions()}
					data={data}
					ignoreSetCount={true}
					sortHeaderId={this.defaultSorting}
					containerClassName={'feeding-usage-table-container'}
				/>
				<SkioldModal
					padding="0"
					isOpen={!!this.state.modalSectionId}
					close={this.closeModal}
					shouldCloseOnOverlayClick={true}
				>
					{this.state.modalSectionId && (
						<DistriwinPenFeedingTable
							closeModal={this.closeModal}
							sectionId={this.state.modalSectionId.sectionId}
							date={this.state.modalSectionId.date}
						/>
					)}
				</SkioldModal>
			</ViewWeb>
		);
	}
	private getFilteredData(
		selectedType: FeedingAmountType,
		feedings: IFeeding[],
		consumptionDtos: IDistriwinFeedConsumptionDto[],
		locations: {
			[key: string]: Building | Section | Pen;
		},
	) {
		let feedingAdjustments: FeedingAdjustmentTableModel[] = [];

		feedings.forEach(feeding => {
			feedingAdjustments = feedingAdjustments.concat(
				getFeedingUsagesByFeeding(
					feeding,
					this.props.feedingSubjects,
					this.props.feedingCategory,
					this.props.userProfile,
					locations,
				),
			);
		});
		feedingAdjustments = feedingAdjustments.concat(
			generateFeedingAdjustmentTableModelByConsumptions(
				consumptionDtos,
				this.props.userProfile.language,
				locations,
			),
		);

		return feedingAdjustments.filter(
			usage => usage.periodTo! <= this.props.toDate! && usage.periodTo! >= this.props.fromDate!,
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(FeedingUsageTable);
