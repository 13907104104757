import { Sorting } from '@devexpress/dx-react-grid';
import memoize from 'memoize-one';
import React from 'react';
import isEqual from 'react-fast-compare';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
	AllAnimalsEsfListSetting,
	FeedingStatusUpdate,
	IEsfFeedingStatus,
	IMoveEvent,
	IProcessEquipmentData,
	IStation,
	IStemAnimal,
	IUnitToPen,
	PregnancyValidationType,
	WorkListType,
} from 'shared/api/api';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { deepCopy2, rangeFilterMethodGrid } from 'shared/helpers/general-helpers';
import { memoizeValidationSettingPlan } from 'shared/helpers/memoize-getters/memoize-getters';
import { NaturalSort } from 'shared/helpers/natural-sort';
import { RefType } from 'shared/helpers/ref-type';
import {
	AllAnimalsEsfWorkListItem,
	AllAnimalsEsfWorkListPropsFromParent,
	getAllAniamlsData,
} from 'shared/helpers/work-list-helpers/esf-list-helpers/all-animals-esf-helpers';
import { SaveFeedingStatusValidation } from 'shared/helpers/work-list-helpers/esf-list-helpers/general-work-list-esf-helpers';
import { SaveFeedingStatus } from 'shared/state/ducks/esf-status/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import SkioldFeedCurvePicker from '../../process-equipment/skiold-feed-curve-picker';
import { showAlert, ShowConfirmAlert } from '../../skiold-alert/skiold-alert';
import { SkioldCheckbox } from '../../skiold-components/skiold-checkbox/skiold-checkbox';
import { SkioldFormIntegerInput } from '../../skiold-components/skiold-integer-input/skiold-form-integer-input';
import SkioldTableGrid from '../../skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { SowListConstants } from '../../stem-animal/animal-lists/table-constants';
import '../list-setup.scss';
import { getAnimalNumberText, getStemAnimalNumberColor } from '../work-list-helper';
import './all-animals-esf-list-table.scss';

const mapStateToProps = (state: WebAppState) => {
	return {
		stemAnimals: state.stemAnimals.entities,
		unitToPens: state.unitToPenData.data,
		moveEvents: state.moveEvents.entities,
		esfStatusData: state.esfStatus.data,
		stations: state.station.data,
		siteId: state.profile.active ? state.profile.active.siteId : undefined,
		workListSettings: state.workListSettings.entities.find(
			setup => setup.type === WorkListType.AllAnimalsEsfListSetting,
		)! as AllAnimalsEsfListSetting,
		validationSettings: memoizeValidationSettingPlan(
			state.validationSetup.entity,
			PregnancyValidationType.MatingToFarrowing,
		),
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	saveFeedingStatus: (feedingStatusUpdate: FeedingStatusUpdate) => SaveFeedingStatus(feedingStatusUpdate)(dispatch),
});
type WorkListTableProps = ReturnType<typeof mapStateToProps> &
	ReturnType<typeof mapDispatchToProps> &
	AllAnimalsEsfWorkListPropsFromParent;

interface WorkListTableState {
	columnExte: any[];
	loading: boolean;
	columns: any[];
	commitAll: boolean;
	workListData: AllAnimalsEsfWorkListItem[];
	workListDataCopy: AllAnimalsEsfWorkListItem[];
}
class AllAnimalsEsfListTable extends React.PureComponent<WorkListTableProps, WorkListTableState> {
	public SkioldTableRef: any;

	private generateDataWithSetState = memoize(
		(
			stemAnimals: IStemAnimal[],
			unitToPens: IUnitToPen[],
			penId: string | undefined,
			selectedSectionId: string | undefined,
			moveEvents: IMoveEvent[],
			esfFeedingStatus: IEsfFeedingStatus[],
			esfStations: IStation[],
			esfLocationsWithSettedPens: IProcessEquipmentData[],
			siteId: string | undefined,
			workListSettings: AllAnimalsEsfListSetting,
			validationSettings: number,
		) => {
			const allAnimalListData = getAllAniamlsData(
				stemAnimals,
				penId ? unitToPens.filter(utp => utp.penId === penId) : unitToPens,
				selectedSectionId,
				moveEvents,
				esfFeedingStatus,
				esfStations,
				esfLocationsWithSettedPens,
				siteId,
				workListSettings,
				validationSettings,
			);

			this.setState({
				workListData: allAnimalListData.data,
				workListDataCopy: allAnimalListData.data,
			});
		},
	);

	private defaultSorting = [{ columnName: 'cycleDays', direction: 'desc' }] as Sorting[];
	constructor(props: WorkListTableProps) {
		super(props);
		this.state = {
			columns: this.generateColumns(),
			columnExte: this.generateColumnsExtensions(),
			commitAll: false,
			loading: false,
			workListData: [],
			workListDataCopy: [],
		};
	}

	public componentDidMount() {
		this.generateDataWithSetState(
			this.props.stemAnimals,
			this.props.unitToPens,
			this.props.penId,
			this.props.sectionId,
			this.props.moveEvents,
			this.props.esfStatusData,
			this.props.stations,
			this.props.esfLocationsWithSettedPens,
			this.props.siteId,
			this.props.workListSettings,
			this.props.validationSettings,
		);
	}

	public checkForInvalidData = () => {
		if (this.state.workListData.some(allAnimalData => !allAnimalData.isDataValid)) {
			showAlert(localized(ExceptionMessage.VALIDATION_WARNING_ESF_DATA_IS_NOT_UP_TO_DATE));
		}
	};

	public componentDidUpdate(prevProps: WorkListTableProps) {
		this.generateDataWithSetState(
			this.props.stemAnimals,
			this.props.unitToPens,
			this.props.penId,
			this.props.sectionId,
			this.props.moveEvents,
			this.props.esfStatusData,
			this.props.stations,
			this.props.esfLocationsWithSettedPens,
			this.props.siteId,
			this.props.workListSettings,
			this.props.validationSettings,
		);
	}

	public async ResetWorkList() {
		if (this.state.workListDataCopy) {
			const dataCopy = deepCopy2(this.state.workListDataCopy) as AllAnimalsEsfWorkListItem[];
			dataCopy.forEach(item => {
				item.isChecked = false;
			});
			this.setState({ workListData: dataCopy, commitAll: false });
		}
	}

	public async CheckSavePregnancyEventListsStatus() {
		if (this.SkioldTableRef) {
			let data = this.SkioldTableRef.GetSortedData() as AllAnimalsEsfWorkListItem[];
			let dataCopy = [...this.state.workListDataCopy];
			if (data) {
				let saveItems = data.find(item => {
					if (item.isChecked) {
						let oldItemIndex = dataCopy.findIndex(dataItem => dataItem.stemAnimalId === item.stemAnimalId);
						if (
							dataCopy[oldItemIndex] &&
							(!isEqual(dataCopy[oldItemIndex].fixedPercentage, item.fixedPercentage) ||
								!isEqual(dataCopy[oldItemIndex].selectedCurveNumber, item.selectedCurveNumber))
						) {
							return true;
						}
					}
					return false;
				});
				if (saveItems && (await ShowConfirmAlert(localized('saveWorklist')))) {
					await this.SaveWorklist();
				}
			}
		}
		return true;
	}

	public async SaveWorklist() {
		let ArrayOfPromises = Array<Promise<void>>();
		if (this.SkioldTableRef) {
			let data = this.SkioldTableRef.GetSortedData() as AllAnimalsEsfWorkListItem[];
			let dataCopy = [...this.state.workListDataCopy];
			if (data) {
				data.forEach(item => {
					if (item.isChecked) {
						let oldItemIndex = dataCopy.findIndex(dataItem => dataItem.stemAnimalId === item.stemAnimalId);
						let oldItem = dataCopy[oldItemIndex];
						if (
							SaveFeedingStatusValidation(
								item.selectedCurveNumber,
								oldItem && oldItem.selectedCurveNumber,
								item.fixedPercentage ? item.fixedPercentage.toString() : undefined,
								oldItem && oldItem.fixedPercentage ? oldItem.fixedPercentage.toString() : undefined,
								item.animalNumber,
								item.esfFeedingStatusId,
								this.props.saveFeedingStatus,
								showAlert,
							)
						) {
							dataCopy[oldItemIndex] = item;
						}
					}
				});
			}
			await Promise.all(ArrayOfPromises);
			this.setState({ workListDataCopy: dataCopy });
		}
		return false;
	}

	public render() {
		return (
			<ViewWeb className="align-items-center">
				<SkioldTableGrid
					tableKey={'allAnimalsEsfListTable'}
					columns={this.state.columns}
					className={'work-list-table all-animals-esf-list-table'}
					ColumnExtensions={this.state.columnExte}
					data={this.state.workListData}
					ref={this.setTableRef}
					sortHeaderId={this.defaultSorting}
				/>
			</ViewWeb>
		);
	}

	public generateCommitAllCheckBox = () => {
		return (
			<ViewWeb className="checkbox-view-filter-style">
				<SkioldCheckbox
					isChecked={this.state.commitAll}
					className="checkbox-style-filter"
					onClick={this.commitAllCheckBoxClicked}
				/>
			</ViewWeb>
		);
	};

	public generateColumnsExtensions() {
		let columnExtensions = [
			{
				columnName: 'cycleDays',
				width: 100,
			},
			{
				columnName: 'animalNumber',
				wordWrapEnabled: true,
				width: 100,
			},
			{
				columnName: 'feDay',
				width: 100,
			},
			{
				columnName: 'eatenToday',
				width: 150,
			},
			{
				columnName: 'curve',
				filteringEnabled: false,
				width: 150,
			},
			{
				columnName: 'percentage',
				width: 100,
			},
			{
				columnName: 'commitAll',
				filteringEnabled: false,
				width: 100,
			},
		];
		return columnExtensions;
	}

	private generateColumns() {
		let columns: any[] = [
			{
				name: 'cycleDays',
				title: localized('Days'),
				width: SowListConstants.cycleDaysWidth,
				filterFunction: rangeFilterMethodGrid,
				getCellValue: (tableItem: AllAnimalsEsfWorkListItem) => tableItem.cycleDays,
			},
			{
				name: 'animalNumber',
				title: localized('animalNumber'),
				width: SowListConstants.animalNrWidth,
				className: getStemAnimalNumberColor,
				getCellValue: getAnimalNumberText,
			},
			{
				name: 'feDay',
				title: localized('FE_DAY'),
				getCellValue: (tableItem: AllAnimalsEsfWorkListItem) => tableItem.feDay,
			},
			{
				name: 'eatenToday',
				title: localized('eatenToday'),
				getCellValue: (tableItem: AllAnimalsEsfWorkListItem) => tableItem.eatenToday,
			},
			{
				name: 'curve',
				title: localized('curve'),
				getCellValue: this.generateFeedingCurveDropdownField,
				sortFunction: this.NaturalSortDropdown,
			},
			{
				name: 'percentage',
				title: localized('FIXEDPERCENT'),
				getCellValue: this.generateFixedPercentageField,
				sortFunction: this.NaturalSortInputField,
			},
			{
				name: 'commitAll',
				title: localized('completed'),
				filter: this.generateCommitAllCheckBox,
				getCellValue: this.generateCommitCheckBox,
				sortable: false,
			},
		];
		return columns;
	}

	private NaturalSortInputField(data1: any, data2: any) {
		return NaturalSort(data1.props.text, data2.props.text);
	}

	private NaturalSortDropdown(data1: any, data2: any) {
		return NaturalSort(data1.props.feedCurveNumber, data2.props.feedCurveNumber);
	}

	private generateFeedingCurveDropdownField = (tableItem: AllAnimalsEsfWorkListItem) => {
		const onChange = (feedCurveNumber: number | undefined) => {
			let dataCopy = [...this.state.workListData] as AllAnimalsEsfWorkListItem[];
			let dataIndex = dataCopy.findIndex(dataItem => dataItem.stemAnimalId === tableItem.stemAnimalId);
			let workListDataCopy = { ...dataCopy[dataIndex] } as AllAnimalsEsfWorkListItem;
			workListDataCopy.selectedCurveNumber = feedCurveNumber;
			workListDataCopy.isChecked = false;
			dataCopy[dataIndex] = workListDataCopy;

			if (this.state.commitAll) {
				this.setState({ workListData: dataCopy, commitAll: false });
			} else {
				this.setState({ workListData: dataCopy });
			}
		};

		return (
			<SkioldFeedCurvePicker
				usedInsideTable={true}
				feedCurveNumber={tableItem.selectedCurveNumber}
				penId={tableItem.penId}
				onChange={onChange}
				disabled={!tableItem.isDataValid}
				theme={'dark'}
				className={'inTable'}
				dropdownIdentifier={'all-animals-esf-curve'}
			/>
		);
	};

	private generateFixedPercentageField = (tableItem: AllAnimalsEsfWorkListItem) => {
		return (
			<SkioldFormIntegerInput
				text={tableItem.fixedPercentage}
				editable={tableItem.isDataValid}
				className="boar-input"
				onChangeNumber={newFixedPercentage => {
					let dataCopy = [...this.state.workListData] as AllAnimalsEsfWorkListItem[];
					let dataIndex = dataCopy.findIndex(dataItem => dataItem.stemAnimalId === tableItem.stemAnimalId);
					let workListDataCopy = { ...dataCopy[dataIndex] } as AllAnimalsEsfWorkListItem;
					workListDataCopy.fixedPercentage = newFixedPercentage;
					workListDataCopy.isChecked = false;
					dataCopy[dataIndex] = workListDataCopy;
					if (this.state.commitAll) {
						this.setState({ workListData: dataCopy, commitAll: false });
					} else {
						this.setState({ workListData: dataCopy });
					}
				}}
			/>
		);
	};

	private generateCommitCheckBox = (tableItem: AllAnimalsEsfWorkListItem) => {
		if (tableItem.isDataValid) {
			return (
				<SkioldCheckbox
					isChecked={tableItem.isChecked}
					className="checkbox-style"
					onClick={() => {
						this.commitedClicked(tableItem);
					}}
				/>
			);
		} else {
			return (
				<ViewWeb className="red-box">
					<TextWeb>X</TextWeb>
				</ViewWeb>
			);
		}
	};

	private commitedClicked = (tableItem: AllAnimalsEsfWorkListItem) => {
		let dataCopy = [...this.state.workListData] as AllAnimalsEsfWorkListItem[];
		let dataIndex = dataCopy.findIndex(dataItem => dataItem.stemAnimalId === tableItem.stemAnimalId);
		let workListDataCopy = { ...dataCopy[dataIndex] } as AllAnimalsEsfWorkListItem;
		workListDataCopy.isChecked = !tableItem.isChecked;
		dataCopy[dataIndex] = workListDataCopy;
		if (this.state.commitAll) {
			this.setState({ workListData: dataCopy, commitAll: false });
		} else {
			this.setState({ workListData: dataCopy });
		}
	};

	private setTableRef = (m: any) => {
		if (m) {
			this.SkioldTableRef = m;
		}
	};
	private commitAllCheckBoxClicked = () => {
		const isChecked = !this.state.commitAll;
		let dataCopy = [...this.state.workListData] as AllAnimalsEsfWorkListItem[];
		dataCopy.forEach(dataItem => {
			if (dataItem.isDataValid) {
				dataItem.isChecked = isChecked;
			}
		});
		this.setState({ workListData: dataCopy, commitAll: isChecked });
	};
}

export default connect<ReturnType<typeof mapStateToProps>, ReturnType<typeof mapDispatchToProps>, RefType, WebAppState>(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{ forwardRef: true },
)(AllAnimalsEsfListTable);
