import { ISlaughterhouseConnectionDto, SlaughterhouseCompany } from 'shared/api/api';

export const SAVE_SLAUGHTERHOUSECONNECTION = 'SAVE_SLAUGHTERHOUSECONNECTION';
export const PUT_SLAUGHTERHOUSECONNECTION = 'PUT_SLAUGHTERHOUSECONNECTION';
export const DELETE_SLAUGHTERHOUSECONNECTION = 'DELETE_SLAUGHTERHOUSECONNECTION';
export const SYNC_DATA_SLAUGHTERHOUSECONNECTIONS = 'SYNC_DATA_SLAUGHTERHOUSECONNECTIONS';
export const SET_SELECTED_SLAUGHTERHOUSECONNECTION = 'SET_SELECTED_SLAUGHTERHOUSECONNECTION';

export interface SlaughterhouseConnectionState {
	slaughterhouseConnections: ISlaughterhouseConnectionDto[];
	selectedConnection: SlaughterhouseCompany | undefined;
}
