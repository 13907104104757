
import actionCreatorFactory from 'typescript-fsa';
import * as types from './types';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import { IWorkListToTreatmentPlan, SyncDataWorkListToTreatmentPlan } from 'shared/api/api';

const actionCreator = actionCreatorFactory(GenActionFactoryName('workListToTreatmentPlans'));

export const saveWorkListToTreatmenPlan = actionCreator.async<
  IWorkListToTreatmentPlan,
    string,
    { code: number; message: string; prevEntity: IWorkListToTreatmentPlan | undefined }
>(types.SAVE_WORK_LIST_TO_TREATMENT_PLAN);
export const getSyncData = actionCreator.async<{ siteId: string; lastSyncDate: Date; activeSiteId?: string  }, SyncDataWorkListToTreatmentPlan>(
    types.SYNC_WORK_LIST_TO_TREATMENT_PLAN
);
