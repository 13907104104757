import { Option } from 'react-dropdown';
import {
	IMatingBatch,
	IMoveEvent,
	IMoveToFarrowingLocation,
	IMoveToMatingLocation,
	IMoveToPregnancyLocation,
	IPen,
	IPregnancyEvent,
	IStemAnimal,
	IWorkListSetting,
	LocationType,
	MoveToLocationTypes,
	PregnancyState,
	Scanned,
	ScanResult,
	WorkListType,
} from 'shared/api/api';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { calculateDays, copyFlatObjectArray, isNullOrUndefined } from 'shared/helpers/general-helpers';
import {
	CheckIfSectionUsesPens,
	FindHiddenPenOnSection,
	getLocationStringByPenId,
	getStemAnimalPenId,
	ILocationModel,
} from 'shared/helpers/location-helper';
import { sortPregnanciesByDate } from 'shared/helpers/pregnancy-helper/sort-pregnancies';
import { findRetentionTime } from 'shared/helpers/treatment-helper/treatment-helper';
import { MovingListTable } from 'shared/helpers/work-list-helpers/move-to-helpers/move-to-data-model';
import { localized } from 'shared/state/i18n/i18n';
import { StoreContainer } from 'shared/state/store-container';
import { showAlert } from '../../skiold-alert/skiold-alert';
import { workListUseFeedCurve } from 'shared/helpers/work-list-helpers/move-to-helpers/moving-lists-helper';

export function GetMoveToOptions() {
	return [
		{ label: localized(MoveToLocationTypes.Location), value: MoveToLocationTypes.Location },
		{
			label: localized(MoveToLocationTypes.MatingBatch),
			value: MoveToLocationTypes.MatingBatch,
		},
		{
			label: localized(MoveToLocationTypes.PregnacyDays),
			value: MoveToLocationTypes.PregnacyDays,
		},
	];
}
export function getCycleDaysByPregnancyEvents(filteredPregnancyEvents: IPregnancyEvent[]) {
	if (filteredPregnancyEvents !== undefined) {
		const eventDate = getEventDate(filteredPregnancyEvents);
		let cycleDays = calculateDays(eventDate, new Date());
		if (cycleDays) {
			return cycleDays;
		}
	}
	return undefined;
}

export function movingListsCheckCycleDaysPregnancyDays(
	moveToSetup: IMoveToFarrowingLocation | IMoveToMatingLocation | IMoveToPregnancyLocation,
	eventDate: Date,
) {
	let cycleDays = calculateDays(eventDate, new Date());
	if (
		moveToSetup.cycleDaysFrom !== undefined &&
		moveToSetup.cycleDaysTo !== undefined &&
		cycleDays <= moveToSetup.cycleDaysTo &&
		cycleDays >= moveToSetup.cycleDaysFrom
	) {
		return cycleDays;
	}
	return undefined;
}

export function checkLocation(locationOfSow: IPen | undefined, locations: ILocationModel, locationType: LocationType) {
	if (locationOfSow !== undefined) {
		if (
			locations.sections.find(
				section => section.id === locationOfSow!.sectionId && section.type === locationType,
			) !== undefined
		) {
			return true;
		}
	}
	return false;
}

export function checkMatingBatch(pregnancyEvents: IPregnancyEvent[], matingBatch: IMatingBatch) {
	let filteredPregnancyEvents = sortPregnanciesByDate(pregnancyEvents);
	const eventDate = getEventDate(filteredPregnancyEvents);
	let lastestPregEvent = filteredPregnancyEvents.find(preg => preg.date!.toString() === eventDate.toString());
	if (
		lastestPregEvent &&
		lastestPregEvent.state === PregnancyState.Scanned &&
		(lastestPregEvent as Scanned).result === ScanResult.True
	) {
		lastestPregEvent = filteredPregnancyEvents.find(preg => preg.state === PregnancyState.Mated);
	}
	if (
		!lastestPregEvent ||
		lastestPregEvent.date! < matingBatch.matingPeriodStart! ||
		lastestPregEvent.date! > matingBatch.matingPeriodEnd!
	) {
		return undefined;
	}
	return { filteredPregnancyEvents, lastestPregEvent };
}

export function setMovelistTableItemData(
	sow: IStemAnimal,
	moveToWorkListItem: MovingListTable,
	cycleDays: number | undefined,
) {
	moveToWorkListItem.stemAnimalId = sow.id;
	moveToWorkListItem.animalNumber = sow.animalNumber;
	moveToWorkListItem.cycleDays = cycleDays;
	moveToWorkListItem.hasRetentionTime = !!findRetentionTime(sow.id!);
	moveToWorkListItem.sectionId = undefined;
	moveToWorkListItem.penId = undefined;
}

export function getEventDate(filteredPregnancyEvents: IPregnancyEvent[]) {
	return new Date(
		Math.max.apply(
			null,
			filteredPregnancyEvents.map(e => {
				return new Date(e.date!).getTime();
			}),
		),
	);
}

export function InsertOldDataSelectedSettings(data: MovingListTable[], moveListData: MovingListTable[]) {
	if (moveListData.length > 0) {
		moveListData.forEach(moveListTableItem => {
			let dataIndex = data.findIndex(
				moveListTable => moveListTable.stemAnimalId === moveListTableItem.stemAnimalId,
			);
			if (dataIndex !== -1) {
				data[dataIndex] = moveListTableItem;
			}
		});
	}
}

export function setMovingListSectionInStore(
	section: string | undefined,
	moveToSetup: IMoveToMatingLocation | IMoveToFarrowingLocation | IMoveToPregnancyLocation,
	moveListData: MovingListTable[],
	penId: string | undefined,
	usesPens: boolean | undefined,
	saveWorkListDataTemporary: (movingListData: MovingListTable[], headerPenId: string | undefined) => Promise<void>,
) {
	if (moveToSetup && section !== undefined) {
		moveListData.forEach(moveListItem => {
			moveListItem.sectionId = section;
			if (!usesPens) {
				moveListItem.penId = penId;
				moveListItem.usesPens = false;
			} else {
				moveListItem.usesPens = true;
			}
		});
		saveWorkListDataTemporary(moveListData, penId);
	}
}

export function setMovingListPenInStore(
	pen: string | undefined,
	moveToSetup: IMoveToMatingLocation | IMoveToFarrowingLocation | IMoveToPregnancyLocation,
	moveListData: MovingListTable[],
	moveListSelectedPen: string | undefined,
	saveWorkListDataTemporary: (movingListData: MovingListTable[], headerPenId: string | undefined) => Promise<void>,
) {
	if (moveToSetup && pen !== undefined) {
		for (let index = 0; index < moveListData.length; index++) {
			let moveListTableItem = moveListData[index];
			moveListTableItem.penId = pen;
			moveListData[index] = { ...moveListTableItem };
		}
		if (moveListSelectedPen !== pen) {
			saveWorkListDataTemporary(moveListData, pen);
		}
	}
}

export function GenerateInitialData(
	moveToSetup: IMoveToPregnancyLocation | IMoveToFarrowingLocation,
	locations: ILocationModel,
	locationType: LocationType,
	penId: string | undefined,
	selectedBatch: Option,
	generateData: (
		moveToSetup: IMoveToPregnancyLocation | IMoveToFarrowingLocation,
		batchId: string,
	) => MovingListTable[],
	saveWorkListDataTemporary: (movingListData: MovingListTable[], headerPenId: string | undefined) => Promise<void>,
) {
	let filteredLocations = { ...locations };
	let locationString: string | undefined;
	if (moveToSetup) {
		saveWorkListDataTemporary(generateData(moveToSetup, selectedBatch.value), penId);
		filteredLocations.sections = [...filteredLocations.sections.filter(section => section.type === locationType)];
		if (moveToSetup.selectedType === MoveToLocationTypes.Location) {
			locationString = getLocationStringByPenId(moveToSetup.penId);
		}
	}
	return { filteredLocations, locationString };
}

export function ResetMoveWorkListDataInStore(
	allData: MovingListTable[],
	saveWorkListDataTemporary: (movingListData: MovingListTable[], headerPenId: string | undefined) => Promise<void>,
) {
	let resetData = allData.map(movingListTableItem => {
		let moveListTable = { ...movingListTableItem };
		moveListTable.buildingId = undefined;
		moveListTable.sectionId = undefined;
		moveListTable.penId = undefined;
		moveListTable.isChecked = false;
		return moveListTable;
	});
	saveWorkListDataTemporary(resetData, undefined);
}

export function getLocationsForMoveToList(locations: ILocationModel, locationType: LocationType) {
	let filteredLocations = locations;
	filteredLocations.sections = [...filteredLocations.sections.filter(section => section.type === locationType)];
	return filteredLocations;
}

export function getLocationsForMoveToSetup(locations: ILocationModel, locationType: LocationType) {
	let filteredLocations = locations;
	filteredLocations.sections = [...filteredLocations.sections.filter(section => section.type === locationType)];
	return filteredLocations;
}

export async function saveMovingListData(
	allData: MovingListTable[],
	siteId: string | undefined,
	penId: string | undefined,
	saveMoveEvent: (moveEvent: IMoveEvent) => Promise<void>,
	saveWorkListDataTemporary: (movingListData: MovingListTable[]) => void,
	esfExist?: boolean,
	worklistSetup?: IWorkListSetting,
) {
	let data: MovingListTable[] = [];
	let listOfFuncPromises: Array<() => Promise<void>> = [];
	let dataIsValid = true;
	let animalsWithoutTransponder: string[] = [];
	let allDataCopy = copyFlatObjectArray(allData);
	for (let MovingListItem of allDataCopy) {
		if (MovingListItem.isChecked) {
			if (!MovingListItem.penId || MovingListItem.penId.length <= 0) {
				showAlert(localized(ExceptionMessage.VALIDATION_ERROR_LOCATION_NOT_SET));
				dataIsValid = false;
				return dataIsValid;
			}

			if (
				esfExist &&
				MovingListItem.isMoveLocationEsf &&
				worklistSetup &&
				workListUseFeedCurve(worklistSetup) &&
				isNullOrUndefined(MovingListItem.selectedCurveNumber)
			) {
				showAlert(localized(ExceptionMessage.VALIDATION_ERROR_FEED_CURVE_NUMBER_IS_NOT_SET));
				dataIsValid = false;
				return dataIsValid;
			}
			if (!MovingListItem.stemAnimalId) {
				showAlert(localized(ExceptionMessage.VALIDATION_ERROR_ANIMAL_NOT_FOUND));
				dataIsValid = false;
				return dataIsValid;
			}
			if (
				esfExist &&
				worklistSetup &&
				workListUseFeedCurve(worklistSetup) &&
				!MovingListItem.transponder &&
				MovingListItem.animalNumber
			) {
				animalsWithoutTransponder.push(MovingListItem.animalNumber);
				dataIsValid = false;
			} else {
				if (MovingListItem.penId !== MovingListItem.currentPenId) {
					listOfFuncPromises.push(() =>
						saveMoveEvent({
							moveDate: new Date(),
							stemAnimalId: MovingListItem.stemAnimalId,
							penId: MovingListItem.penId,
							siteId,
							feedCurveNumber: MovingListItem.isMoveLocationEsf
								? MovingListItem.selectedCurveNumber
								: undefined,
							fixedDeviation: MovingListItem.isMoveLocationEsf
								? MovingListItem.fixedDeviation
								: undefined,
						} as IMoveEvent),
					);
				}
			}
		} else {
			data.push(MovingListItem);
		}
	}
	if (!dataIsValid && animalsWithoutTransponder.length > 0) {
		showAlert(ExceptionMessage.VALIDATION_ERROR_ANIMALS_NEED_TRANSPONDER(animalsWithoutTransponder));
		return dataIsValid;
	}
	let listOfPromises: Array<Promise<void>> = [];
	for (let func of listOfFuncPromises) {
		listOfPromises.push(func());
	}
	await Promise.all(listOfPromises);
	saveWorkListDataTemporary(data);
	return dataIsValid;
}

export function getAvailablePensForSow(sow: IStemAnimal) {
	const state = StoreContainer.getState();
	let pensAvailableForSow: IPen[] = [];
	let pen = getStemAnimalPenId(sow, state.moveEvents.entities);
	if (pen) {
		let pens = state.locations.pens.filter(p => p.id !== pen);

		pens.forEach(pen => {
			pensAvailableForSow.push(pen);
		});
	}
	return pensAvailableForSow;
}

export function generateMatingBatchSetup(
	workListSettings: IWorkListSetting[],
	matingBatches: IMatingBatch[],
	workListType: WorkListType,
) {
	let moveToSetup = workListSettings.find(setup => setup.type === workListType)! as
		| IMoveToPregnancyLocation
		| IMoveToFarrowingLocation;
	let selectedOption: Option = { label: ' ', value: ' ' };
	let matingBatchesOptions: Option[] = [];
	if (moveToSetup && moveToSetup.selectedType === MoveToLocationTypes.MatingBatch) {
		const sortedBatches = matingBatches.sort((a, b) => {
			return b.matingPeriodStart!.getTime() - a.matingPeriodStart!.getTime();
		});

		let dateToCalculateWith = new Date();
		dateToCalculateWith.setDate(dateToCalculateWith.getDate() - moveToSetup.batchCycleDays!);
		let batchIndex = sortedBatches.findIndex(batch => batch.matingPeriodStart! < dateToCalculateWith);
		if (batchIndex !== -1) {
			if (batchIndex !== 0) {
				matingBatchesOptions.push({
					label: sortedBatches[batchIndex - 1].batchNumber!.toString(),
					value: sortedBatches[batchIndex - 1].id!,
				});
			}
			selectedOption = {
				label: sortedBatches[batchIndex].batchNumber!.toString(),
				value: sortedBatches[batchIndex].id!,
			};
			matingBatchesOptions.push(selectedOption);
			if (batchIndex + 1 !== sortedBatches.length) {
				matingBatchesOptions.push({
					label: sortedBatches[batchIndex + 1].batchNumber!.toString(),
					value: sortedBatches[batchIndex + 1].id!,
				});
			}
			return { selectedOption, matingBatchesOptions, moveToSetup };
		}
	}
	return { selectedOption, matingBatchesOptions, moveToSetup };
}

export function ResetDataOnMoveListSetups(movetoPregSetup: IMoveToFarrowingLocation | IMoveToPregnancyLocation) {
	switch (movetoPregSetup.selectedType) {
		case MoveToLocationTypes.Location:
			movetoPregSetup.batchCycleDays = undefined;
			movetoPregSetup.cycleDaysFrom = undefined;
			movetoPregSetup.cycleDaysTo = undefined;
			break;
		case MoveToLocationTypes.MatingBatch:
			movetoPregSetup.cycleDaysFrom = undefined;
			movetoPregSetup.cycleDaysTo = undefined;
			movetoPregSetup.buildingId = undefined;
			movetoPregSetup.sectionId = undefined;
			movetoPregSetup.penId = undefined;
			break;
		case MoveToLocationTypes.PregnacyDays:
			movetoPregSetup.batchCycleDays = undefined;
			movetoPregSetup.buildingId = undefined;
			movetoPregSetup.sectionId = undefined;
			movetoPregSetup.penId = undefined;
			break;
	}
}

export function checkSectionWorkLists(section: string | undefined, pen: string | undefined) {
	let penId = pen;
	let usesPens = true;
	if (!CheckIfSectionUsesPens(section!)) {
		penId = FindHiddenPenOnSection(section!, '');
		usesPens = false;
	}
	return { penId, usesPens };
}
