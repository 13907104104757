import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { MatingBatchReportsState } from './types';
import { getMatingBatchReportsBySiteAndDate, getDashboardTableModels } from './actions';
import { siteChange } from '../profile/actions';
import { MatingBatchReports } from 'shared/api/api';

export const initialState: MatingBatchReportsState = {
	matingBatchReports: MatingBatchReports.fromJS({}),
	matingBatchReportDashboardTableModels: {},
	loadingMatingBatchReportDashboardTableModels: false,
};

const matingBatchReportsReducer = (
	state: MatingBatchReportsState = initialState,
	action: Action,
): MatingBatchReportsState => {
	if (isType(action, getMatingBatchReportsBySiteAndDate.done)) {
		if (action.payload.params.siteId === action.payload.params.activeSiteId) {
			const result = {
				...state,
				matingBatchReports: action.payload.result,
				loadingMatingBatchReportDashboardTableModels: false,
			};
			return result;
		}
	}

	if (isType(action, getMatingBatchReportsBySiteAndDate.started)) {
		return { ...state, loadingMatingBatchReportDashboardTableModels: true };
	}

	if (isType(action, getMatingBatchReportsBySiteAndDate.failed)) {
		return { ...state, loadingMatingBatchReportDashboardTableModels: false };
	}

	if (isType(action, getDashboardTableModels.started)) {
		const result = { ...state, loadingMatingBatchReportDashboardTableModels: true };
		return result;
	}
	if (isType(action, getDashboardTableModels.failed)) {
		const result = { ...state, loadingMatingBatchReportDashboardTableModels: false };
		return result;
	}

	if (isType(action, getDashboardTableModels.done)) {
		const result = {
			...state,
			matingBatchReportDashboardTableModels: action.payload.result,
			loadingMatingBatchReportDashboardTableModels: false,
		};
		return result;
	}

	if (isType(action, siteChange.done)) {
		state = initialState;
	}

	return state;
};

export default matingBatchReportsReducer;
