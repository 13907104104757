import actionCreatorFactory from 'typescript-fsa';
import * as types from './types';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import { IDistriwinFeedComponent } from 'shared/api/api';

const actionCreator = actionCreatorFactory(GenActionFactoryName('distriwinFeedComponents'));

export const getSyncData = actionCreator.async<{ siteId: string }, IDistriwinFeedComponent[]>(
	types.SYNC_DISTRIWIN_FEED_COMPONENTS,
);

export const setFeedingToComponentRelations = actionCreator.async<{ [key: string]: string }, { [key: string]: string }>(
	types.SET_FEEDING_TO_COMPONENT_RELATIONS,
);

export const fetchComponents = actionCreator.async<{ siteId: string; code: string }, boolean>(
	types.FETCH_COMPONENTS,
);
