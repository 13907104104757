import memoize from 'memoize-one';
import { Option } from 'react-dropdown';
import { IUserProfile } from 'shared/api/api';

export const memoizeInitialOptions = memoize((profiles: IUserProfile[], currentUser: IUserProfile | undefined) =>
	profiles
		.filter(p => (!p.admin || (currentUser && currentUser.id === p.id)) && p.initials)
		.map(profile => {
			return { value: profile.id, label: profile.initials ? profile.initials : ' ' } as Option;
		}),
);

export const memoizeDefaultInitialOption = memoize((profile: IUserProfile | undefined) =>
	profile && profile.initials
		? ({ label: defaultInitials(profile), value: profile.id } as Option)
		: ({ label: ' ', value: '' } as Option),
);

export const defaultInitials = (profile: IUserProfile | undefined) => {
	return profile ? (profile.initials ? profile.initials : ' ') : '';
};
