import React from 'react';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import './work-list-settings.scss';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { WebAppState } from 'web/state/store.web';
import { GetSyncData as WorkListSettingGetSyncData } from 'shared/state/ducks/work-list-settings/operations';
import { WorkListType, Equipment } from 'shared/api/api';
import { localized } from 'shared/state/i18n/i18n';
import { SkioldTouchableOpacity } from 'web/view/components/skiold-components/skiold-touchable-opacity';
import MoveToPregnantSetup from 'web/view/components/work-lists/move-to/move-to-pregnant-setup';
import MoveToFarrowingSetup from 'web/view/components/work-lists/move-to/move-to-farrowing-setup';
import MoveToMatingSetup from 'web/view/components/work-lists/move-to/move-to-mating-setup';
import FarrowingListSetup from 'web/view/components/work-lists/farrowing-work-list/farrowing-list-setup';
import WeaningListSetup from 'web/view/components/work-lists/weaning-work-list/weaning-list-setup';
import MatingListSetup from 'web/view/components/work-lists/mating-work-list/mating-list-setup';
import VaccineListSetup from 'web/view/components/work-lists/vaccine-work-list/vaccine-work-list-setup';
import ScanningListSetup from 'web/view/components/work-lists/scanning-work-list/scanning-list-setup';
import ShortageEsfListSetup from 'web/view/components/work-lists/shortage-esf-work-list/shortage-esf-list-setup';
import AllAnimalsEsfListSetup from 'web/view/components/work-lists/all-animals-esf-work-list/all-animals-esf-list-setup';
import TasksInFarrowingStableSetup from 'web/view/components/work-lists/tasks-in-farrowing-stable-work-list/tasks-in-farrowing-stable-setup';
import styled from 'styled-components';
import { sortWorklistByPriority } from 'shared/helpers/work-list-helpers/work-list-priority';
import { GetProcessEquipmentDataSyncData } from 'shared/state/ducks/process-equipment-data/operations';
import MoveFromPregToPregSetup from 'web/view/components/work-lists/move-to/move-list-preg-to-preg-setup';
import MoveMatingToMatingSetup from 'web/view/components/work-lists/move-to/move-mating-to-mating-setup';
import DepartureWorkListSetup from 'web/view/components/work-lists/departure-work-list/departure-work-list-setup';
import LardScanningEventListSetup from 'web/view/components/work-lists/lard-scanning-event-work-list/lard-scanning-event-list-setup';
import { SowTasksWorklistSetup } from 'web/view/components/work-lists/sow-tasks-work-list/sow-tasks-worklist-setup';
const WhiteCheckMark = styled.div`
	color: white;
`;

const mapStateToProps = (state: WebAppState) => {
	return {
		siteId: state.profile.active!.siteId,
		WorkListSettings: state.workListSettings.entities,
		processEquipments: state.processEquipments.entities,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	getWorkListSettingsBySiteId: () => WorkListSettingGetSyncData()(dispatch),
	getProcessEquipmentSyncData: () => GetProcessEquipmentDataSyncData()(dispatch),
});

export interface WorkListSettingsState {
	workListType: WorkListType;
	workListTypes: string[];
}

type WorkListSettingsProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
class WorkListSettings extends React.PureComponent<WorkListSettingsProps, WorkListSettingsState> {
	constructor(props: WorkListSettingsProps) {
		super(props);
		this.state = {
			workListType: WorkListType.MatingListSetting,
			workListTypes: this.getWorkListTypes(),
		};
	}

	public async componentDidMount() {
		if (this.props.siteId) {
			this.props.getWorkListSettingsBySiteId();
			await this.props.getProcessEquipmentSyncData();
		}
	}

	public render() {
		return (
			<ViewWeb className="work-list-settings">
				<ViewWeb className="options-container">
					<TextWeb className="header-text">{localized('workLists')}</TextWeb>
					{this.state.workListTypes.sort(sortWorklistByPriority).map(type => (
						<ViewWeb key={type} className="display-row-flex">
							<SkioldTouchableOpacity
								onPress={() => this.setState({ workListType: type as WorkListType })}
							>
								<ViewWeb className="display-row-flex">
									<TextWeb className="item-text">{localized(type as WorkListType)}</TextWeb>
									{this.props.WorkListSettings.find(a => a.type === type) && (
										<WhiteCheckMark className="pi pi-check"></WhiteCheckMark>
									)}
								</ViewWeb>
							</SkioldTouchableOpacity>
						</ViewWeb>
					))}
				</ViewWeb>

				<ViewWeb className="setup-render-container">
					{this.state.workListType === WorkListType.MoveToPregnancyLocation && (
						<MoveToPregnantSetup workListType={this.state.workListType} />
					)}
					{this.state.workListType === WorkListType.MoveToFarrowingLocation && (
						<MoveToFarrowingSetup workListType={this.state.workListType} />
					)}
					{this.state.workListType === WorkListType.MoveToMatingLocation && (
						<MoveToMatingSetup workListType={this.state.workListType} />
					)}
					{this.state.workListType === WorkListType.FarrowingListSetting && (
						<FarrowingListSetup workListType={this.state.workListType} />
					)}
					{this.state.workListType === WorkListType.WeaningListSetting && (
						<WeaningListSetup workListType={this.state.workListType} />
					)}
					{this.state.workListType === WorkListType.MatingListSetting && (
						<MatingListSetup workListType={this.state.workListType} />
					)}
					{this.state.workListType === WorkListType.VaccineListSetting1 && (
						<VaccineListSetup workListType={this.state.workListType} />
					)}
					{this.state.workListType === WorkListType.VaccineListSetting2 && (
						<VaccineListSetup workListType={this.state.workListType} />
					)}
					{this.state.workListType === WorkListType.VaccineListSetting3 && (
						<VaccineListSetup workListType={this.state.workListType} />
					)}
					{this.state.workListType === WorkListType.ScanningListSetting && (
						<ScanningListSetup workListType={this.state.workListType} />
					)}
					{this.state.workListType === WorkListType.AllAnimalsEsfListSetting && (
						<AllAnimalsEsfListSetup workListType={this.state.workListType} />
					)}
					{this.state.workListType === WorkListType.ShortageEsfListSetting && (
						<ShortageEsfListSetup workListType={this.state.workListType} />
					)}
					{this.state.workListType === WorkListType.TasksInFarrowingStableSetting && (
						<TasksInFarrowingStableSetup choosedSetting={WorkListType.TasksInFarrowingStableSetting} />
					)}
					{this.state.workListType === WorkListType.ScanningListSetting2 && (
						<ScanningListSetup workListType={this.state.workListType} />
					)}

					{this.state.workListType === WorkListType.TasksInFarrowingStableSetting2 && (
						<TasksInFarrowingStableSetup choosedSetting={WorkListType.TasksInFarrowingStableSetting2} />
					)}
					{this.state.workListType === WorkListType.MoveFromPregToPregSetting && (
						<MoveFromPregToPregSetup workListType={this.state.workListType} />
					)}
					{this.state.workListType === WorkListType.MoveMatingToMatingSetting && (
						<MoveMatingToMatingSetup workListType={this.state.workListType} />
					)}
					{this.state.workListType === WorkListType.DepartureWorkListSetting && (
						<DepartureWorkListSetup workListType={this.state.workListType} />
					)}
					{this.state.workListType === WorkListType.LardScanningEventListSetting && (
						<LardScanningEventListSetup workListType={this.state.workListType} />
					)}
					{this.state.workListType === WorkListType.LardScanningEventListSetting2 && (
						<LardScanningEventListSetup workListType={this.state.workListType} />
					)}
					{this.state.workListType === WorkListType.SowTaskSettings1 && (
						<SowTasksWorklistSetup workListType={this.state.workListType} />
					)}
					{this.state.workListType === WorkListType.SowTaskSettings2 && (
						<SowTasksWorklistSetup workListType={this.state.workListType} />
					)}
				</ViewWeb>
			</ViewWeb>
		);
	}

	private getWorkListTypes() {
		let usesESF = this.props.processEquipments.find(a => a.equipment === Equipment.ESF);
		return Object.keys(WorkListType)
			.filter(w => !(w.includes('Esf') && usesESF === undefined))
			.map(k => WorkListType[k as any]);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkListSettings);
