import React, { FC, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnimalKind, AnimalType, GrowthPigShouldDepartureTableItem } from 'shared/api/api';
import { AddDaysToDate } from 'shared/helpers/date-helpers';
import {
	getGrowthPigsEventsByDates,
	getGrowthPigsEventsBySiteId,
} from 'shared/state/ducks/growth-pig-events/operations';
import { WebAppState } from 'web/state/store.web';
import { Spinner } from 'web/view/components/spinner/spinner';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { GrowthPigShouldDepartureHeader } from './gpe-should-departure-header';
import { GrowthPigShouldDepartureTable } from './gpe-should-departure-table';
import { SkioldTableGrid as SkioldTableRef } from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
interface PropsFromParent {}

interface GrowthPigShouldDepartureContext {
	tableItems: GrowthPigShouldDepartureTableItem[];
	setTableItems: React.Dispatch<React.SetStateAction<GrowthPigShouldDepartureTableItem[]>>;
	productionType: AnimalType;
	setProductionType: React.Dispatch<React.SetStateAction<AnimalType>>;
	animalKind: AnimalKind;
	setAnimalKind: React.Dispatch<React.SetStateAction<AnimalKind>>;
	registrationDate: Date;
	setRegistrationDate: React.Dispatch<React.SetStateAction<Date>>;
	setSkioldTableRef: React.Dispatch<React.SetStateAction<SkioldTableRef | undefined>>;
	skioldTableRef: SkioldTableRef;
}

export const GrowthPigsShouldDepartureContext = React.createContext({} as any);
export const useGpeShouldDeparture = () =>
	useContext<GrowthPigShouldDepartureContext>(GrowthPigsShouldDepartureContext);

export const GrowthPigShouldDepartureOverview: FC<PropsFromParent> = React.memo(({ ...props }) => {
	const dispatch = useDispatch();

	// Handle animalType and animalKind by navigation query
	const navigationQuery = useSelector((state: WebAppState) => state.navigation);
	const syncInProgress = useSelector((state: WebAppState) => state.growthPigEvents.syncInProgress);
	const [tableItems, setTableItems] = useState<GrowthPigShouldDepartureTableItem[]>([]);
	const [skioldTableRef, setSkioldTableRef] = useState<SkioldTableRef | undefined>(undefined);
	const [registrationDate, setRegistrationDate] = useState<Date>(new Date());

	const [productionType, setProductionType] = useState<AnimalType>();
	const [animalKind, setAnimalKind] = useState<AnimalKind>();

	useEffect(() => {
		// Get relevant gpes
		getGrowthPigsEventsByDates(AddDaysToDate(new Date(), -365), new Date())(dispatch);
		getGrowthPigsEventsBySiteId()(dispatch);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setAnimalKind(navigationQuery.query ? (navigationQuery.query['type'] as AnimalKind) : AnimalKind.Weaner);
		setProductionType(navigationQuery.query ? (navigationQuery.query['type'] as AnimalType) : AnimalType.Weaner);
	}, [navigationQuery]);

	return (
		<GrowthPigsShouldDepartureContext.Provider
			value={{
				tableItems,
				setTableItems,
				productionType,
				setProductionType,
				animalKind,
				setAnimalKind,
				setRegistrationDate,
				registrationDate,
				setSkioldTableRef,
				skioldTableRef,
			}}
		>
			{productionType && animalKind ? (
				<ViewWeb className="growth-pig-should-departure-overview">
					<GrowthPigShouldDepartureHeader />
					<GrowthPigShouldDepartureTable />
				</ViewWeb>
			) : (
				<Spinner />
			)}
		</GrowthPigsShouldDepartureContext.Provider>
	);
});
