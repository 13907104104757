import memoize from 'memoize-one';
import React from 'react';
import { connect } from 'react-redux';
import { IMoveToMatingLocation, LocationType, MoveToLocationTypes, WorkListType } from 'shared/api/api';
import { RefType } from 'shared/helpers/ref-type';
import { checkSaveWorkListStatus } from 'shared/helpers/work-list-helpers/general-work-list-helper';
import {
	getDataByPregnancyDaysMoveToMatingList,
	getDataByWeaningDateMoveToMatingList,
	moveToMatingListMapDispatchToProps,
	moveToMatingListMapStateToProps,
	MoveToMatingListProps,
	MoveToMatingListState,
} from 'shared/helpers/work-list-helpers/move-to-helpers/move-to-mating-list-helper';
import {
	checkSectionWorkLists,
	getLocationsForMoveToList,
	ResetMoveWorkListDataInStore,
	setMovingListPenInStore,
	setMovingListSectionInStore,
	shouldShowPen,
} from 'shared/helpers/work-list-helpers/move-to-helpers/moving-lists-helper';
import { SharedAppState } from 'shared/state/store.shared';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { ShowConfirmAlert } from '../../skiold-alert/skiold-alert';
import MoveToListTableConnect from './move-list-table';
import { getRows } from './move-lists-getrows';
import '../list-setup.scss';
import { saveMovingListData } from './moving-lists-setup-helper';
import { MovingListTable } from 'shared/helpers/work-list-helpers/move-to-helpers/move-to-data-model';
import { LocationModel } from 'shared/helpers/location-helper';

export class MoveToMatingListTable extends React.PureComponent<MoveToMatingListProps, MoveToMatingListState> {
	public SkioldTableRef: any;

	private generateData = memoize((moveToSetup, date) => this.getData(date));
	private generateDataWithSetState = memoize((moveToSetup, date, props) => {
		this.saveMovingListTableData(this.getData(date));
	});

	private generateFormRows = memoize(
		(moveToSetup, toPenId, toSectionId, locations, selectedHeaderSectionUsesPens, selectedDate) =>
			getRows(
				this.state.moveToSetup,
				[],
				{ label: ' ', value: ' ' },
				this.state.locationString,
				toPenId,
				this.state.toSectionId,
				this.state.locations,
				selectedHeaderSectionUsesPens,
				this.state.selectedDate,
				undefined,
				this.dateChanged,
				this.selectedSectionChanged,
				this.selectedPenChanged,
			),
	);
	constructor(props: MoveToMatingListProps) {
		super(props);
		let moveSetup = props.workListSettings.find(
			setup => setup.type === WorkListType.MoveToMatingLocation,
		)! as IMoveToMatingLocation;

		this.state = {
			moveToSetup: moveSetup,
			toSectionId: undefined,
			toPenId: undefined,
			locationString: undefined,
			locations: undefined,
			selectedHeaderSectionUsesPens: false,
			selectedDate: new Date(),
			moveListData: [],
			moveListInitData: [],
		};
	}

	public componentDidUpdate() {
		this.generateDataWithSetState(this.state.moveToSetup, this.state.selectedDate, this.props);
	}

	public componentDidMount() {
		if (this.state.moveToSetup) {
			let tableData = this.generateData(this.state.moveToSetup, this.state.selectedDate);

			this.setState({
				locations: getLocationsForMoveToList(new LocationModel(this.props.locations), LocationType.Mating),
				moveListData: tableData,
				moveListInitData: tableData,
			});
		}

		this.props.getWorkListSettingsBySiteId();
		this.props.matingBatchGetSyncData();
		this.props.pregnancyEventGetSyncData();
		this.props.stemAnimalGetSyncData();
		this.props.moveEventGetSyncData();
		this.props.getLocations();
	}

	public async ResetWorkList() {
		let resetData = ResetMoveWorkListDataInStore(this.state.moveListData);
		this.setState({
			toSectionId: undefined,
			toPenId: undefined,
			moveListData: resetData,
			moveListInitData: resetData,
			selectedHeaderSectionUsesPens: false,
		});
	}

	public async CheckSavePregnancyEventListsStatus() {
		if (await checkSaveWorkListStatus(this.state.moveListData, ShowConfirmAlert)) {
			return await this.SaveWorklist();
		}
		return true;
	}

	public async SaveWorklist() {
		const wasValid = await saveMovingListData(
			this.state.moveListData,
			this.props.siteId,
			this.state.toPenId,
			this.props.saveMoveEvent,
			this.saveMovingListTableData,
		);
		return wasValid;
	}

	public dateChanged = (date: Date) => {
		this.generateDataWithSetState(this.state.moveToSetup, date, this.props);
		this.setState({ selectedDate: date });
	};

	public saveMovingListTableData = (moveListData: MovingListTable[]) => {
		this.setState({
			moveListData: moveListData,
			moveListInitData: moveListData,
		});
	};

	public saveMovingListTableDataTemporary = (moveListData: MovingListTable[]) => {
		this.setState({
			moveListData: moveListData,
		});
	};

	public setSection(section?: string) {
		if (section) {
			let { penId, usesPens } = checkSectionWorkLists(section, this.state.toPenId);
			setMovingListSectionInStore(
				section,
				this.state.moveToSetup,
				this.state.moveListData,
				penId,
				usesPens,
				this.saveMovingListTableDataTemporary,
			);
			this.setState({ toSectionId: section, toPenId: penId, selectedHeaderSectionUsesPens: usesPens });
		} else {
			setMovingListSectionInStore(
				section,
				this.state.moveToSetup,
				this.state.moveListData,
				undefined,
				false,
				this.saveMovingListTableDataTemporary,
			);
			this.setState({ toSectionId: undefined, toPenId: undefined, selectedHeaderSectionUsesPens: false });
		}
	}

	public setPen(pen?: string) {
		if (pen !== '') {
			setMovingListPenInStore(
				pen,
				this.state.moveToSetup,
				this.state.moveListData,
				this.state.toPenId,
				this.saveMovingListTableDataTemporary,
			);
			this.setState({ toPenId: pen });
		}
	}

	public render() {
		return (
			<ViewWeb className="work-list">
				<ViewWeb className="view-container">
					<ViewWeb className="z-index-1000">
						{this.generateFormRows(
							this.state.moveToSetup,
							this.state.toPenId,
							this.state.toSectionId,
							this.state.locations,
							this.state.selectedHeaderSectionUsesPens,
							this.state.selectedDate,
						)}
					</ViewWeb>
					<MoveToListTableConnect
						movingListSetup={this.state.moveToSetup}
						filteredLocations={this.state.locations}
						tableKey={'moveToMatingWorklist'}
						data={this.state.moveListData}
						saveWorkListDataTemporary={this.saveMovingListTableDataTemporary}
						ref={this.setRef}
						showPen={shouldShowPen(this.state.locations)}
					/>
				</ViewWeb>
			</ViewWeb>
		);
	}
	private setRef = (m: any) => (m ? (this.SkioldTableRef = m) : {});
	private selectedSectionChanged = (selectedSectionId: string) => this.setSection(selectedSectionId);
	private selectedPenChanged = (selectedPenId: string) => this.setPen(selectedPenId);

	private getData(selectedDate: Date): MovingListTable[] {
		switch (this.state.moveToSetup.selectedType) {
			case MoveToLocationTypes.PregnacyDays:
				return getDataByPregnancyDaysMoveToMatingList(
					this.props.moveEvents,
					new LocationModel(this.props.locations),
					this.props.pregnancyEvets,
					this.state.moveListData,
					this.state.moveToSetup,
					this.state.toSectionId,
					this.state.toPenId,
					this.props.validationSettingPlan,
				);
			case MoveToLocationTypes.Date:
				return getDataByWeaningDateMoveToMatingList(
					this.props.moveEvents,
					new LocationModel(this.props.locations),
					this.props.pregnancyEvets,
					this.state.moveListData,
					selectedDate,
					this.state.toSectionId,
					this.state.toPenId,
					this.props.validationSettingPlan,
				);

			default:
				return [];
		}
	}
}

export default connect<
	ReturnType<typeof moveToMatingListMapStateToProps>,
	ReturnType<typeof moveToMatingListMapDispatchToProps>,
	RefType,
	SharedAppState
>(moveToMatingListMapStateToProps, moveToMatingListMapDispatchToProps, null, { forwardRef: true })(
	MoveToMatingListTable,
);
