import { AnimalType, GrowthPigEventType, GrowthPigsEvent } from 'shared/api/api';
import { localized } from 'shared/state/i18n/i18n';
import { getDateString } from '../date-helpers';
import { getOrderById } from '../location-helper';

export const generateRegulationTableRows = (hashmapLocations: any, data: GrowthPigsEvent[], animalType: AnimalType) => {
	let rows: RegulationTableRow[] = [];

	data.forEach(item => {
		if (
			item.growthPigEventType === GrowthPigEventType.RegulationWeight &&
			item.toProductionType === animalType
		) {
			rows.push({
				toBuildingName:
					item.toBuildingId &&
					hashmapLocations[item.toBuildingId] &&
					hashmapLocations[item.toBuildingId].name,
				toSectionName:
					item.toSectionId && hashmapLocations[item.toSectionId] && hashmapLocations[item.toSectionId].name,
				toPenName: item.toPenId && hashmapLocations[item.toPenId] && hashmapLocations[item.toPenId].name,
				date: item.date && getDateString(item.date),
				amount: item.amount,
				avgWeight: item.totalWeight, // Weight regulations use weight as avg
				productionType: item.toProductionType && localized(item.toProductionType),
				bundleId: item.bundleIdentifier,
				growthPigId: item.id,
				realDate: item.date,
				penOrder: getOrderById(item.toPenId, hashmapLocations),
				sectionOrder: getOrderById(item.toSectionId, hashmapLocations),
				buildingOrder: getOrderById(item.toBuildingId, hashmapLocations),
			} as RegulationTableRow);
		}
	});

	return rows;
};

export interface RegulationTableRow {
	toBuildingName?: string;
	toSectionName?: string;
	toPenName?: string;
	date?: string;
	amount?: number;
	avgWeight: number;
	productionType?: AnimalType;
	correctProductionType?: AnimalType;
	bundleId?: string;
	growthPigId?: string;
	realDate?: Date;
	penOrder?: number | undefined;
	sectionOrder?: number | undefined;
	buildingOrder?: number | undefined;
}
