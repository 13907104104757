import React from 'react';
import { connect } from 'react-redux';
import { AnalysisMatingPregnancyTableData, ResultAnalysisFarrowingsTableItem, PeriodDto } from 'shared/api/api';
import { RefType } from 'shared/helpers/ref-type';
import { AnalysisModalMode } from 'shared/state/ducks/analysis/analysis-modal/operation';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import SkioldTableGrid, {
	SkioldTableGrid as SkioldTableRef,
} from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { GroupedHeader } from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid-grouped-header';
import { SowListConstants } from 'web/view/components/stem-animal/animal-lists/table-constants';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { GetAnalysisMatingPregnancyElaborationPdf } from 'web/web-helpers/pdf-helper/analysis-pdf-helpers/analysis-pdf-helper';
import { defaultSortingResultPerLitterModals } from '../../analysis-helper';
import { AnalysisMatingPregnancy } from 'shared/state/ducks/analysis/analysis-modal/types';

interface PropsFromParent {
	mode: AnalysisModalMode;
	dataMode: string;
	data: ResultAnalysisFarrowingsTableItem[];
}

const mapStateToProps = (state: WebAppState) => {
	return {
		profile: state.profile.active!,
	};
};

type Props = ReturnType<typeof mapStateToProps> & PropsFromParent;

export interface State {
	columns: any[];
	columnExte: any[];
	groupedColumns: GroupedHeader[];
}
export class AnalysisResultsMatingsPregnancyTable extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			columnExte: this.generateColumnExtenstions(),
			columns: this.generateColumns(),
			groupedColumns: this.GenerateGroupedColumns(),
		};
	}

	public render() {
		return (
			<ViewWeb className="analysis-modal-table ">
				<SkioldTableGrid
					sortHeaderId={defaultSortingResultPerLitterModals}
					data={this.props.data}
					ref={this.setTableRef}
					groupedColumns={this.state.groupedColumns}
					filterable={false}
					sortable={false}
					columns={this.state.columns}
					ColumnExtensions={this.state.columnExte}
					tableKey={'analysisFarrowingResultPerLitterTable'}
				/>
			</ViewWeb>
		);
	}

	public printData = async (periodDto: PeriodDto) => {
		if (this.SkioldTableRef) {
			let data = this.SkioldTableRef.GetSortedData();
			GetAnalysisMatingPregnancyElaborationPdf(
				data,
				this.props.dataMode + '.pdf',
				this.props.profile.siteId!,
				this.props.dataMode,
				Intl.DateTimeFormat().resolvedOptions().timeZone,
				this.props.profile.language,
				periodDto
			);
		}
	};

	public SkioldTableRef: SkioldTableRef | undefined;
	private GenerateGroupedColumns = () => {
		const groupedColumns: GroupedHeader[] = [
			{
				title: this.props.dataMode !== AnalysisMatingPregnancy[AnalysisMatingPregnancy.MatingToAbortionAndDeparture] ?  <ViewWeb className="paddingLeftFive">{localized('IntervalDays')}</ViewWeb> : undefined,
				children: [{ columnName: 'name' }],
				className: 'textAlignStart',
			},
			{
				title: localized('LitterNr'),
				children: this.GenerateGroupedColumnNames(),
			},
		];
		return groupedColumns;
	};

	private GenerateGroupedColumnNames() {
		const groupedColumn: any[] = [];
		for (let index = 0; index < 9; index++) {
			groupedColumn.push({
				columnName: 'litter' + index,
			});
		}
		return groupedColumn;
	}

	private setTableRef = (m: any) => (m ? (this.SkioldTableRef = m) : {});

	private generateColumnExtenstions = () => {
		let columnExte = [
			{
				columnName: 'name',
				width: SowListConstants.reasonWidth,
			},
		];

		for (let index = 0; index < 9; index++) {
			columnExte.push({
				columnName: 'litter' + index,
				width: SowListConstants.dateWidth,
			});
		}
		columnExte = columnExte.concat([
			{
				columnName: 'matings',
				width: SowListConstants.dateWidth,
			},
			{
				columnName: 'Percentage',
				width: SowListConstants.dateWidth,
			}
		]);
		return columnExte;
	};

	private generateColumns = () => {
		let column: any[] = [
			{
				name: 'name',
				title:
					this.props.dataMode !==
					AnalysisMatingPregnancy[AnalysisMatingPregnancy.MatingToAbortionAndDeparture] ? (
						<div className={'paddingLeftFive textAlignStart'}>{localized('FromTo')}</div>
					) : (
						' '
					),
				getCellValue: (data: AnalysisMatingPregnancyTableData) =>
					data.name ? <div className={data.boldName? 'bold textAlignStart': 'textAlignStart'}>{localized(data.name)}</div> : ' ',
			},
		];

		for (let index = 0; index < 9; index++) {
			column.push({
				name: 'litter' + index,
				title: index === 8 ? '9 over' : (index + 1).toString(),
				getCellValue: (data: AnalysisMatingPregnancyTableData) =>
					data.litters && data.litters[index] ? data.litters[index].toString() : ' ',
			});
		}
		column = column.concat([
			{
				name: 'matings',
				title: localized('Matings'),
				getCellValue: (data: AnalysisMatingPregnancyTableData) =>
					data.matings ? data.matings.toString() : ' ',
			},
			{
				name: 'Percentage',
				title: localized('Percentage'),
				getCellValue: (data: AnalysisMatingPregnancyTableData) =>
					data.percentage ? data.percentage.toString() : ' ',
			}
		]);
		return column;
	};
}

export default connect<ReturnType<typeof mapStateToProps>, null, RefType, WebAppState>(mapStateToProps, null, null, {
	forwardRef: true,
})(AnalysisResultsMatingsPregnancyTable);
