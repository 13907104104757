import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import * as Action from './actions';
import { StoreContainer } from 'shared/state/store-container';

export const LoadProfileModules = () => {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId! ?? ' ';
	return AsyncOperationBuilder2(Action.loadSiteModules, cl => cl.sites_GetModules(siteId), {});
};

export const LoadAllLicences = () => {
	return AsyncOperationBuilder2(Action.loadAll, client => client.licences_GetAll(), {});
};
