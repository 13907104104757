import actionCreatorFactory from 'typescript-fsa';
import * as types from './types';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import { IPregnancyEvent, SyncDataPregnancyV2 } from 'shared/api/api';

const actionCreator = actionCreatorFactory(GenActionFactoryName('pregnancyEvents'));

export const savePregnancyEvent = actionCreator.async<
	IPregnancyEvent,
	string,
	{ code: number; message: string; prevEntity: IPregnancyEvent | undefined }
>(types.SAVE_PREGNANCY_EVENT);

export const savePregnancyEvents = actionCreator.async<
	{ newPregEvents: IPregnancyEvent[]; prevPregEventHashmap: { [key: string]: IPregnancyEvent } },
	string[],
	{ code: number; message: string }
>(types.SAVE_LIST_OF_PREGNANCY_EVENTS);

export const savePregnancyEventNoUpdate = actionCreator.async<IPregnancyEvent, string>(
	types.SAVE_PREGNANCY_EVENT_NO_UPDATE
);

export const saveWeaningEvents = actionCreator.async<
	IPregnancyEvent[],
	void,
	{ code: number; message: string; prevEntity: IPregnancyEvent[] }
>(types.SAVE_PREGNANCY_EVENTS);

export const deleteLastPregnancyEvent = actionCreator.async<IPregnancyEvent, void>(types.DELETE_LAST_PREGNANCY_EVENT);
export const deleteLastPregnancyEvents = actionCreator.async<IPregnancyEvent[], void>(
	types.DELETE_LAST_PREGNANCY_EVENTS
);
export const getSyncData = actionCreator.async<
	{ siteId: string; lastSyncDate: Date; activeSiteId?: string },
	SyncDataPregnancyV2
>(types.SYNC_DATA_PREGNANCYEVENTS);
export const resetState = actionCreator(types.RESET_PREGNANCY_STATE);

export const removePregnanciesByAnimal = actionCreator<string>(types.REMOVE_PREGNANCIES_BY_ANIMAL);

export const getDeparturedPregnancies = actionCreator.async<string, { [key: string]: IPregnancyEvent[] }>(
	types.GET_DEPARTURED_PREGNANCIES
);

export const saveDeparturedPregnancyEvent = actionCreator.async<
	IPregnancyEvent,
	string,
	{ code: number; message: string; prevEntity: IPregnancyEvent | undefined }
>(types.SAVE_DEPARTURED_PREGNANCY_EVENT);
