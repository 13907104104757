import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import {
	Chart,
	ArgumentAxis,
	ValueAxis,
	LineSeries,
	Legend,
	Title,
	Tooltip,
} from '@devexpress/dx-react-chart-material-ui';
import { localized } from 'shared/state/i18n/i18n';
import './skiold-chart.scss';
import { EventTracker, ArgumentScale } from '@devexpress/dx-react-chart';
import { scaleTime } from 'd3-scale';

const titleStyle = { margin: 'auto' };
const TitleText = props => <Title.Text {...props} style={titleStyle} />;
interface ChartDataValueFieldsData {
	key: string;
	value: string;
}

interface SkioldChartPropsFromParent {
	title: string;
	chartData: any[];
	chartDataValueFields: ChartDataValueFieldsData[];
}

const generateLineSeries = (value: ChartDataValueFieldsData): JSX.Element => (
	<LineSeries key={value.value} name={localized(value.key)} valueField={value.value} argumentField="argument" />
);

export const SkioldChart = (props: SkioldChartPropsFromParent) => {
	return (
		<Paper>
			<Chart data={props.chartData}>
				<ArgumentScale factory={scaleTime as any} />
				<ArgumentAxis />
				<ValueAxis />

				{props.chartDataValueFields.length > 0 && props.chartDataValueFields.map(generateLineSeries)}

				<Title text={props.title} textComponent={TitleText} />
				<Legend />
				<EventTracker />
				<Tooltip />
			</Chart>
		</Paper>
	);
};
