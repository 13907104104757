import actionCreatorFactory from 'typescript-fsa';
import * as types from './types';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import {
	IStemAnimal,
	ImportFileData,
	SyncDataStemAnimal,
	StemAnimal,
	IFeedingStatusUpdate,
	SingleStemAnimalInfo,
	DepartedAnimalData,
	YoungFemaleReportData,
	IYoungFemaleReportData,
} from 'shared/api/api';

const actionCreator = actionCreatorFactory(GenActionFactoryName('stemAnimals'));

export const saveSow = actionCreator.async<
	IStemAnimal,
	string,
	{ code: number; message: string; prevEntity: IStemAnimal | undefined }
>(types.SAVE_STEM_ANIMAL);

export const uploadImportData = actionCreator.async<ImportFileData, IStemAnimal[]>(types.UPLOAD_IMPORT_DATA);

export const saveStemAnimalNoUpdate = actionCreator.async<IStemAnimal, string>(types.SAVE_STEM_ANIMAL_NO_UPDATE);

export const getSyncData = actionCreator.async<
	{ siteId: string; lastSyncDate: Date; activeSiteId?: string },
	SyncDataStemAnimal
>(types.SYNC_DATA_STEMANIMALS);

export const setSowsCount = actionCreator<number>(types.SET_SOWS_COUNT);
export const setCheckedCount = actionCreator<number>(types.SET_CHECKED_COUNT);

export const getDeparturedSows = actionCreator.async<string, StemAnimal[]>(types.GET_DEPARTURED_ANIMALS);

export const saveEntranceFeedingStatus = actionCreator<IFeedingStatusUpdate>(types.SAVE_ENTRANCE_FEEDING_STATUS);

export const fetchSingleAnimalInfo = actionCreator.async<string, SingleStemAnimalInfo>(types.FETCH_SINGLE_ANIMAL_INFO);

export const deleteYoungAnimals = actionCreator.async<string[], void>(types.DELETE_YOUNG_ANIMALS);
export const updateYoungAnimal = actionCreator.async<
	StemAnimal,
	string,
	{ code: number; message: string; prevEntity: IStemAnimal | undefined }
>(types.UPDATE_YOUNG_ANIMAL);

export const upsertManyYoungAnimals = actionCreator.async<
	StemAnimal[],
	StemAnimal[],
	{ code: number; message: string; prevEntities: IStemAnimal[] | undefined }
>(types.UPSERT_MANY_YOUNG_ANIMAL);

export const GetAllIdNumbers = actionCreator.async<undefined, string>(types.GET_ALL_ID_NUMBERS);
export const getDeparturedAnimalDataByDate = actionCreator.async<
	{ siteId: string; fromDate: Date; toDate: Date },
	DepartedAnimalData
>(types.GET_DEPARTED_ANIMAL_DATA);

export const getYoungFemalesByDate = actionCreator.async<{ siteId: string;  date: Date },IYoungFemaleReportData>(types.GET_YOUNG_FEMALES_BY_DATE);
