import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IStemAnimal } from 'shared/api/api';
import { mergeArrays } from 'shared/helpers/reducer-helpers';
import { generateTreatmentData } from 'shared/helpers/sow-card-helper/sow-card-helper';
import { SowCardTreatmentModel } from 'shared/helpers/sow-card-helper/sow-card-table-model';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { SkioldButton } from '../../skiold-components/skiold-button/skiold-button';
import SkioldTableGrid from '../../skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { Heading } from '../../utils/heading';
import { SowListConstants } from '../animal-lists/table-constants';
import './sow-card-modals.scss';

interface PropsFromParent {
	sow: IStemAnimal | undefined;
	closeModal?: () => void;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		location: state.locations,
		treatments: mergeArrays(state.treatments.entities, state.treatments.treatmentsForLog),
		treatmentPlans: mergeArrays(state.treatmentPlans.entities, state.treatmentPlans.treatmentPlansForLog),
		treatmentDefinitions: state.treatmentDefinitions.entities,
		diagnoses: state.diagnose.entities,
		siteId: state.profile.active!.siteId,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
	return {};
};

export interface SowCardState {}

type SowCardProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;
class SowCardTreatment extends React.PureComponent<SowCardProps, SowCardState> {
	constructor(props: SowCardProps) {
		super(props);

		this.state = {};
	}

	public render() {
		return (
			<ViewWeb>
				<Heading text={localized('Treatments')} />
				<ViewWeb className="sow-card-modals">
					{this.renderTable()}

					{this.props.closeModal && (
						<SkioldButton
							className="btn-container"
							title={localized('Close')}
							onPress={this.props.closeModal}
							theme="grey"
						/>
					)}
				</ViewWeb>
			</ViewWeb>
		);
	}

	public renderTable() {
		return (
			<SkioldTableGrid
				data={generateTreatmentData(this.props.sow)}
				columns={this.generateColumns}
				ColumnExtensions={this.getColumnExtenstions}
				filterable={false}
				tableKey={'sowCardTreatments'}
			/>
		);
	}

	private getColumnExtenstions = [
		{
			columnName: 'litter',
			width: SowListConstants.dateWidth,
		},
		{
			columnName: 'date',
			width: SowListConstants.dateWidth,
		},
		{
			columnName: 'diagnose',
			width: SowListConstants.treatDefinitionDrugWidth,
		},
	];

	private generateColumns = [
		{
			name: 'litter',
			title: localized('Litters'),
			sortable: false,
			filterable: false,
			getCellValue: (treatModel: SowCardTreatmentModel) => <TextWeb>{treatModel.litter}</TextWeb>,
		},
		{
			name: 'date',
			title: localized('Date'),
			sortable: false,
			filterable: false,
			getCellValue: (treatModel: SowCardTreatmentModel) => <TextWeb>{treatModel.treatmentDate}</TextWeb>,
		},
		{
			name: 'diagnose',
			title: localized('Diagnose'),
			sortable: false,
			filterable: false,
			getCellValue: (treatModel: SowCardTreatmentModel) => <TextWeb>{treatModel.diagnoseName}</TextWeb>,
		},
	];
}

export default connect(mapStateToProps, mapDispatchToProps)(SowCardTreatment);
