import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RefType } from 'shared/helpers/ref-type';
import { GetSyncData as StemAnimalGetSyncData } from 'shared/state/ducks/stem-animals/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';

const mapStateToProps = (state: WebAppState) => {
	return {
		sows: state.stemAnimals.entities,
		pregs: state.pregnancyEvents.entities
	};
};
const mapDispatchToProps = (dispatch: Dispatch) => {
	return {
		stemAnimalGetSyncData: () => StemAnimalGetSyncData()(dispatch),
	};
};

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
export class DashBoardOverviewSowCounter extends React.PureComponent<Props> {
	public render() {
		return (
			<ViewWeb className="flexDirectionRow">
					<TextWeb className="yellowText bold paddingRightFive all-capitalized">{localized('sowsGilt')}:</TextWeb>
					<TextWeb className="yellowText bold paddingRightFive">{Object.keys(this.props.pregs).length}</TextWeb>
		
			</ViewWeb>
		);
	}
}

export default connect<ReturnType<typeof mapStateToProps>, ReturnType<typeof mapDispatchToProps>, RefType, WebAppState>(mapStateToProps, mapDispatchToProps, null, {
	forwardRef: true,
})(DashBoardOverviewSowCounter);
