import { Option } from 'react-dropdown';
import { Dispatch } from 'redux';
import { IMated, IPregnancyEvent, IStemAnimal, MatingListSetting, WorkListType } from 'shared/api/api';
import { resetDropdownScrollOptions } from 'shared/state/ducks/general-settings/operations';
import * as matingBatchOperation from 'shared/state/ducks/mating-batch/operations';
import * as pregnancyOperation from 'shared/state/ducks/pregnancy-events/operations';
import { DeleteLastPregnancyEvent, SavePregnancyEvent } from 'shared/state/ducks/pregnancy-events/operations';
import {
	defaultInitials,
	memoizeDefaultInitialOption,
	memoizeInitialOptions,
} from 'shared/state/ducks/profile/selectors';
import { selectNucleusManagementOrAssignIdAccess } from 'shared/state/ducks/site/reducer';
import * as stemAnimalOperation from 'shared/state/ducks/stem-animals/operations';
import { SaveSow, SetCheckedCount, SetSowsCount } from 'shared/state/ducks/stem-animals/operations';
import * as validationSetupOperation from 'shared/state/ducks/validation-setup/operations';
import { GetSyncData } from 'shared/state/ducks/work-list-settings/operations';
import { SharedAppState } from 'shared/state/store.shared';

export interface MatingWorkListItem {
	//public index: number | undefined;
	stemAnimalId: string | undefined;
	animalNumber: string | undefined;
	sow: IStemAnimal | undefined;
	siteId: string | undefined;
	cycleDays: number | undefined;
	animalEvents: IPregnancyEvent[];
	prevEvent: IPregnancyEvent | undefined;
	href: string | undefined;
	boarAnimal: IStemAnimal | undefined;


}

export interface MatingWorkListMatingData {
	stemAnimalId: string | undefined;
	isChecked: boolean;
	boar: string | undefined;
	grade: Option;
	mating: IMated;
	initial: Option;
	selectedSemenType: Option;
	order?: number | undefined;

}

export interface MatingWorkListItemData {
	workListData?: MatingWorkListMatingData;
	listItemData: MatingWorkListItem;
}

export const MatingWorkListMapStateToProps = (state: SharedAppState) => {
	return {
		workListSetting: state.workListSettings.entities.find(
			setting => setting.type === WorkListType.MatingListSetting,
		) as MatingListSetting,
		pregnancyEvents: state.pregnancyEvents.entities,
		matingBatches: state.matingBatches.entities,
		validationSetup: state.validationSetup.entity,
		stemAnimals: state.stemAnimals.entities,
		siteId: state.profile.active!.siteId,
		generalSettings: state.generalSettings.entity,
		initials: memoizeInitialOptions(state.profile.entities, state.profile.active),
		defaultInitial: memoizeDefaultInitialOption(state.profile.active),
		nucleusManagement: selectNucleusManagementOrAssignIdAccess(state),
	};
};

export const MatingWorkListMapDispatchToProps = (dispatch: Dispatch, props: {}) => {
	return {
		setSowsCount: (count: number) => SetSowsCount(count)(dispatch),
		SetCheckedCount: (count: number, reset?: boolean) => SetCheckedCount(count, reset)(dispatch),
		savePregnancyEvent: (pregnancyEvent: IPregnancyEvent) => SavePregnancyEvent(pregnancyEvent)(dispatch),
		deleteLastPregnancyEvent: (pregEvent: IPregnancyEvent) => DeleteLastPregnancyEvent(pregEvent)(dispatch),
		saveStemAnimal: (boar: IStemAnimal) => SaveSow(boar)(dispatch),
		resetDropdownScrollOptions: () => resetDropdownScrollOptions()(dispatch),
		getWorkListSettingsBySiteId: () => GetSyncData()(dispatch),
		pregnancyEventGetSyncData: () => pregnancyOperation.GetSyncData()(dispatch),
		stemAnimalGetSyncData: () => stemAnimalOperation.GetSyncData()(dispatch),
		matingBatchGetSyncData: () => matingBatchOperation.GetSyncData()(dispatch),
		validationSetupGetSyncData: () => validationSetupOperation.GetSyncData()(dispatch),
		savePregnancyEvents: (pregEvents: IPregnancyEvent[]) =>
			pregnancyOperation.SavePregnancyEvents(pregEvents)(dispatch),
	};
};

export interface WeaningPropsFromParent {
	defaultDate: Date;
}

export interface MatingWorkListState {
	columns: any[];
	commitAll: boolean;
	workListData: MatingWorkListItemData[];
	loading: boolean;
	columnExte: any[];
	maxDryDays?: number;
	initialAll: Option;
	modalVisible: boolean;
	rowStemAnimalId?: string;
	semenTypeOptions: Option[];
	matingDate?: Date;
}

export type MatingWorkListProps = ReturnType<typeof MatingWorkListMapStateToProps> &
	ReturnType<typeof MatingWorkListMapDispatchToProps> &
	WeaningPropsFromParent;
