import { FileResponse, GrowthPigShouldDepartureTableItem, SkioldOneClient } from 'shared/api/api';
import { SkioldDigitalApiBaseUrl } from 'shared/constants';
import { savePdfAs } from 'web/web-helpers/pdf-helper/general-pdf-helper';

export function GetByGrowthPigEntranceEventsPdfByDates(
	ids: string[],
	fileName: string,
	siteId: string,
	productionType: string,
	timeZoneId?: string,
	lang?: string,
) {
	return new SkioldOneClient(SkioldDigitalApiBaseUrl)
		.growthPigsEventPdf_GetByGrowthPigEntranceEventsPdfByDates(ids, siteId, timeZoneId, productionType, lang)
		.then((fileResponse: FileResponse | null) => {
			savePdfAs(fileResponse, fileName);
		})
		.catch(() => {
			return;
		});
}

export function GetByGrowthPigDepartureEventsPdfByDates(
	ids: string[],
	fileName: string,
	siteId: string,
	productionType: string,
	timeZoneId?: string,
	lang?: string,
) {
	return new SkioldOneClient(SkioldDigitalApiBaseUrl)
		.growthPigsEventPdf_GetGrowthPigDepartureEventsPdfByDates(ids, siteId, timeZoneId, productionType, lang)
		.then((fileResponse: FileResponse | null) => {
			savePdfAs(fileResponse, fileName);
		})
		.catch(() => {
			return;
		});
}

export function GetByGrowthPigMoveEventsPdfByDates(
	ids: string[],
	fileName: string,
	siteId: string,
	productionType: string,
	timeZoneId?: string,
	lang?: string,
) {
	return new SkioldOneClient(SkioldDigitalApiBaseUrl)
		.growthPigsEventPdf_GetByGrowthPigMoveEventsPdfByDates(ids, siteId, timeZoneId, productionType, lang)
		.then((fileResponse: FileResponse | null) => {
			savePdfAs(fileResponse, fileName);
		})
		.catch(() => {
			return;
		});
}

export function GetGrowthShouldDepartureList(
	ids: GrowthPigShouldDepartureTableItem[],
	fileName: string,
	siteId: string,
	productionType: string,
	timeZoneId?: string,
	lang?: string,
) {
	return new SkioldOneClient(SkioldDigitalApiBaseUrl)
		.growthPigsEventPdf_GrowthShouldDepartureList(ids, siteId, timeZoneId, productionType, lang)
		.then((fileResponse: FileResponse | null) => {
			savePdfAs(fileResponse, fileName);
		})
		.catch(() => {
			return;
		});
}
