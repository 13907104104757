import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import { localized } from 'shared/state/i18n/i18n';
import './skiold-alert.scss';

export async function ShowConfirmAlert(message: string): Promise<boolean> {
	return showForWeb(message);
}

export function showAlert(errorMessage: string) {
	alert(errorMessage);
}

export async function ShowOkAlert(errorMessage: string) {
	return new Promise<boolean>((resolve, reject) => {
		confirmAlert({
			title: 'SKIOLD Digital',
			message: errorMessage,
			buttons: [{ label: localized('Ok'), onClick: () => resolve(true) }],
		});
	});
}

export async function ShowCustomAlert(message: string): Promise<boolean> {
	return showForWeb(message);
}

async function showForWeb(message: string): Promise<boolean> {
	return new Promise<boolean>((resolve, reject) => {
		confirmAlert({
			title: 'SKIOLD Digital',
			message: message,
			buttons: [
				{ label: localized('True'), onClick: () => resolve(true) },
				{
					label: localized('False'),
					onClick: () => resolve(false),
				},
			],
		});
	});
}
export interface CustomAlertOption<T> {
	label: string;
	value: T;
}

export async function showCustomKeyValueAlert<T>(message: string, options: Array<CustomAlertOption<T>>): Promise<T> {
	return new Promise<T>((resolve, reject) => {
		let alertButtons: Array<{
			label: string;
			onClick: () => void;
		}> = [];
		options.forEach(o => {
			alertButtons.push({
				label: o.label ? o.label : localized('False'),
				onClick: () => resolve(o.value),
			});
		});
		confirmAlert({
			title: 'SKIOLD Digital',
			message: message,
			buttons: alertButtons,
		});
	});
}

export function showDefaultAlert(message: string): Promise<void> {
	return Promise.resolve(
		confirmAlert({
			title: 'SKIOLD Digital',
			message: message,
			buttons: [{ label: localized('Ok'), onClick: () => {} }],
		}),
	);
}
