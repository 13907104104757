import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GrowthPigsEvent } from 'shared/api/api';
import { RegulationTableRow } from 'shared/helpers/growth-pigs-helper/growth-pig-weight-regulation-helper';
import { upsertGrowthPigsEvent } from 'shared/state/ducks/growth-pig-events/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldDatePicker } from 'web/view/components/skiold-components/skiold-date-picker/skiold-date-picker';
import { SkioldFormDecimalInput } from 'web/view/components/skiold-components/skiold-decimal-input/skiold-form-decimal-input';
import { SkioldFormTextField } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-form-text';
import { SkioldFormsWrapper } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { SkioldFormIntegerInput } from 'web/view/components/skiold-components/skiold-integer-input/skiold-form-integer-input';
import { Heading } from 'web/view/components/utils/heading';
import { ViewWeb } from 'web/view/components/utils/web-view';

interface PropsFromParent {
	regulationRow?: RegulationTableRow;
	closeModal?: () => void;
}

interface RegulationEdit {}

export const EditGrowthPigEventRegulation: FC<PropsFromParent> = React.memo(({ regulationRow, closeModal }) => {
	const dispatch = useDispatch();

	const regulation = useSelector((state: WebAppState) =>
		state.growthPigEvents.growthPigEvents.find(e => regulationRow && e.id === regulationRow.growthPigId),
	);

	const [stateRegulation, setStateRegulation] = useState<RegulationTableRow | undefined>(undefined);

	useEffect(() => {
		if (regulationRow) {
			setStateRegulation({ ...regulationRow } as RegulationTableRow);
		}
	}, [regulationRow]);

	const getBuildingRow = () => {
		return {
			name: localized('building'),
			component: <SkioldFormTextField>{stateRegulation && stateRegulation.toBuildingName}</SkioldFormTextField>,
		};
	};

	const getSectionRow = () => {
		return {
			name: localized('section'),
			component: <SkioldFormTextField>{stateRegulation && stateRegulation.toSectionName}</SkioldFormTextField>,
		};
	};

	const getPenRow = () => {
		return {
			name: localized('pen'),
			component: <SkioldFormTextField>{stateRegulation && stateRegulation.toPenName}</SkioldFormTextField>,
		};
	};

	const dateChanged = (date: Date) => {
		setStateRegulation({ ...stateRegulation, realDate: date } as RegulationTableRow);
	};

	const getDateRow = () => {
		return {
			name: localized('Date'),
			component: (
				<SkioldDatePicker
					disabled={regulation && regulation.isWeightRegulation}
					onDateChanged={dateChanged}
					selectedDate={stateRegulation && stateRegulation.realDate}
					theme={'dark'}
					color={'grey'}
				/>
			),
		};
	};

	const amountChanged = (amount: number | undefined) => {
		setStateRegulation({ ...stateRegulation, amount } as RegulationTableRow);
	};

	const getAmountRow = () => {
		return {
			name: localized('Amount'),
			component: (
				<SkioldFormIntegerInput
					editable={!regulation || !regulation.isWeightRegulation}
					text={stateRegulation && stateRegulation.amount}
					onChangeNumber={amountChanged}
				/>
			),
		};
	};

	const avgWeightChanged = (avgWeight: number | undefined) => {
		setStateRegulation({ ...stateRegulation, avgWeight } as RegulationTableRow);
	};

	const getAvgWeightRow = () => {
		return {
			name: localized('avgWeight'),
			component: (
				<SkioldFormDecimalInput
					text={stateRegulation && stateRegulation.avgWeight}
					onChangeNumber={avgWeightChanged}
				/>
			),
		};
	};

	const getFormRows = () => {
		let formRows = new Array<FormRow>();

		formRows.push(getBuildingRow());
		formRows.push(getSectionRow());
		formRows.push(getPenRow());
		formRows.push(getDateRow());
		formRows.push(getAmountRow());
		formRows.push(getAvgWeightRow());

		return formRows;
	};

	const save = () => {
		if (closeModal && stateRegulation) {
			const totalWeight =
				stateRegulation.amount && stateRegulation.avgWeight
					? stateRegulation.amount * stateRegulation.avgWeight
					: 0;
			const gpeToEdit = {
				...regulation,
				date: stateRegulation.realDate,
				amount: stateRegulation.amount,
				totalWeight,
			} as GrowthPigsEvent;
			upsertGrowthPigsEvent(gpeToEdit)(dispatch);
			closeModal();
		}
	};

	return (
		<>
			<Heading text={localized('EditRegulation')} />
			<ViewWeb className="default-modal-margin">
				<SkioldFormsWrapper
					formRows={getFormRows()}
					containerClassName="sow-entrance-forms-wrapper-container"
				/>
				<ViewWeb className="button-view-style">
					<SkioldButton title={localized('Save')} onPress={save} />
				</ViewWeb>
			</ViewWeb>
		</>
	);
});
