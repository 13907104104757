import { Dispatch } from 'redux';
import { IPregnancyEvent, Reason, IStemAnimal } from 'shared/api/api';
import { SavePregnancyEvent } from 'shared/state/ducks/pregnancy-events/operations';
import { SetSowsCount, SaveSow, SetCheckedCount } from 'shared/state/ducks/stem-animals/operations';
import { DepartureTypes } from 'shared/state/models/departure-types';
import { SharedAppState } from 'shared/state/store.shared';
import { Option } from 'react-dropdown';
import { UpdateTreatmentPlansForDepartedSow } from 'shared/state/ducks/treatment-plan/operations';
import { memoizeLocation } from 'shared/helpers/memoize-getters/memoize-getters';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';

export const DepartureListMapStateToProps = (state: SharedAppState) => {
	return {
		pregnancyEvents: state.pregnancyEvents.entities,
		stemAnimals: state.stemAnimals.entities,
		treatmentPlans: state.treatmentPlans.entities,
		moveEvents: state.moveEvents.entities,
		locations: memoizeLocation(
			state.locations.buildings,
			state.locations.sections,
			state.locations.pens,
			state.locations.valves,
		),
		reasons: state.reasons.entities,
		profile: state.profile.active,
	};
};

export const DepartureListMapDispatchToProps = (dispatch: Dispatch, props: {}) => {
	return {
		setSowsCount: (count: number) => SetSowsCount(count)(dispatch),
		SetCheckedCount: (count: number, reset?: boolean) => SetCheckedCount(count, reset)(dispatch),
		savePregnancyEvent: (pregnancyEvent: IPregnancyEvent) => SavePregnancyEvent(pregnancyEvent)(dispatch),
		handleDeparture: (sow: IStemAnimal) => SaveSow(sow)(dispatch),
		updateTreatmentPlansForDepartedSow: (stemAnimalId: string, isSowActive: boolean) =>
			UpdateTreatmentPlansForDepartedSow(stemAnimalId, isSowActive)(dispatch),
	};
};

export interface DepartureListState {
	columns: any[];
	columnExte: any[];
	loading: boolean;
	workListData: DepartureListTableItem[];
	modalOpen: boolean;
	stemAnimalIdToEdit: string;
	sowCardModal?: boolean;
	commitAll: boolean;
	animalTypeOption: Option;
	date: Date;
	departureType: Option;
}

export class DepartureListTableItem {
	public stemAnimalId: string | undefined;
	public animalNumber: string | undefined;
	public butcherQuarantine: Date | undefined;
	public location: string | undefined;
	public pen: string | undefined;
	public departureDate: Date | undefined;
	public departureType: DepartureTypes | undefined;
	public selectedDepartureType: Option = { label: '', value: '' };
	public selectedDepartureReason: Option = { label: '', value: '' };
	public departureReason: Reason | undefined;
	public price: number | undefined;
	public isChecked: boolean | undefined;
	public hasRetentionTime: boolean | undefined;
	public penOrder: number | undefined;
	public sectionOrder: number | undefined;
	public order?: number | undefined;
}

export type DepartureListProps = ReturnType<typeof DepartureListMapStateToProps> &
	ReturnType<typeof DepartureListMapDispatchToProps> & { navigation?: any };
