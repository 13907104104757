export interface Syncable<T> {
	entities: T[];
	updates: T[];
	lastSyncDate: Date;
	syncInProgress: boolean;
	saveSyncInProgress: boolean;
}

export interface SyncableWithPrev<T> {
	entities: T[];
	updates: Array<UpdateItem<T>>;
	lastSyncDate: Date;
	syncInProgress: boolean;
	saveSyncInProgress: boolean;
}

export interface UpdateItem<T> {
	update: T;
	prevEntity: T;
}

export interface SyncableHashmap<T> {
	entities: { [key: string]: T[] };
	updates: T[];
	lastSyncDate: Date;
	syncInProgress: boolean;
	saveSyncInProgress: boolean;
}

export interface SyncableSingle<T> {
	entity: T;
	update: T;
	lastSyncDate: Date;
	syncInProgress: boolean;
	saveSyncInProgress: boolean;
}

export const SyncableInitialState: Syncable<any> = {
	entities: [],
	updates: [],
	lastSyncDate: new Date(-8640000000000000),
	syncInProgress: false,
	saveSyncInProgress: false,
};

export const SyncableHashmapInitialState: SyncableHashmap<any> = {
	entities: {},
	updates: [],
	lastSyncDate: new Date(-8640000000000000),
	syncInProgress: false,
	saveSyncInProgress: false,
};

export const SyncableSingleInitialState: SyncableSingle<any> = {
	entity: {},
	update: {},
	lastSyncDate: new Date(-8640000000000000),
	syncInProgress: false,
	saveSyncInProgress: false,
};

export interface SyncableSingleHashmap<T> {
	entities: { [key: string]: T };
	updates: T[];
	lastSyncDate: Date;
	syncInProgress: boolean;
	saveSyncInProgress: boolean;
}

export const SyncableSingleHashmapInitialState: SyncableSingleHashmap<any> = {
	entities: {},
	updates: [],
	lastSyncDate: new Date(-8640000000000000),
	syncInProgress: false,
	saveSyncInProgress: false,
};

