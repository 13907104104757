import ObjectID from 'bson-objectid';
import { Option } from 'react-dropdown';
import {
	AnimalType,
	GrowthPigMoveEventDto,
	GrowthPigsEvent,
	IGrowthPigEventDto,
	IGrowthPigMoveEventDto,
	IGrowthPigShouldDepartureTableItem,
	IPen,
	ISection,
	LocationType,
} from 'shared/api/api';
import {
	getCorrectProductionTypeBySectionId,
	GrowthPigDepartureFromSectionRowExtend,
	GrowthPigMoveEventDtoExtend,
} from 'shared/helpers/growth-pigs-helper/growth-pig-event-function-helper';
import { GrowthPigMoveEventBundleListRow } from 'shared/helpers/growth-pigs-helper/growth-pig-event-list-helper';
import { deepCopy2 } from '../general-helpers';
import { NaturalSort, NaturalSortDates } from '../natural-sort';
import { GrowthPigDepartureFromSectionRow } from './growth-pig-event-helper';

export const initEditGpeFromMoveData = (
	growthPigEvents: GrowthPigsEvent[],
	growthPigsEventsDto: IGrowthPigEventDto[],
	bundle: GrowthPigMoveEventBundleListRow,
	hashmapLocations: {
		[key: string]: any;
	},
) => {
	let bundled = growthPigEvents.filter(e => e.bundleIdentifier === bundle.bundleId);

	let data: GrowthPigDepartureFromSectionRow[] = [];

	const gpeToUse = growthPigsEventsDto.find(gpe => bundle && gpe.sectionId === bundle.fromSectionId);
	if (gpeToUse !== undefined) {
		bundled
			.sort((a, b) => {
				if (a.amount && a.amount < 0) {
					return NaturalSort(a.amount, b.amount);
				}
				if (a.amount && b.amount && a.amount > 0 && b.amount > 0) {
					return NaturalSortDates(a.date, b.date);
				}
				return NaturalSort(a.amount, b.amount);
			})
			.groupBy('fromPenId') // Groupd the moveevents by fromPen, to map the current amount departed and weight
			.forEach(gpes => {
				if (gpes && gpes.length > 0) {
					const date = gpes[0].date;
					const fromPenId = gpes[0].fromPenId;
					const fromProductionType = gpes[0].fromProductionType;
					const penToUse = fromPenId && (hashmapLocations[fromPenId] as IPen);

					if (penToUse) {
						let totalWeight = 0;
						//let totalOldWeight = 0;
						let totalCount = 0;
						gpes.forEach(element => {
							totalWeight += element.totalWeight ? element.totalWeight : 0;
							//totalOldWeight += element.oldTotalWeight ? element.oldTotalWeight : 0;
							totalCount += element.amount ? element.amount : 0;
						});
						data.push({
							penName: penToUse.name,
							penOrder: penToUse.order,
							sectionId: penToUse.sectionId,
							buildingId: penToUse.buildingId,
							penId: fromPenId,
							date: date,
							oldAvgWeight: 0,
							newAvgWeight: totalWeight / totalCount,
							departureWeight: 0,
							totalWeightCurve: totalWeight,
							departedFromSection: totalCount,
							totalDepartureWeight: totalWeight,
							productionType: fromProductionType,
						} as GrowthPigDepartureFromSectionRow);
					}
				}
			});
	}

	data = data.sort((a, b) => NaturalSort(a.penOrder, b.penOrder));
	return data;
};

export const initEditGpeToMoveData = (
	options: Option[],
	growthPigEvents: GrowthPigsEvent[],
	bundle: GrowthPigMoveEventBundleListRow,
	hashmapLocations: {
		[key: string]: any;
	},
	siteId?: string,
	newAvgWeight?: number,
) => {
	//let bundled = growthPigEvents.filter(e => e.bundleIdentifier === bundle.bundleId);
	let initData = [] as GrowthPigMoveEventDto[];

	// Because of a misunderstanding, we get correct production by sectionId
	const correctProduction = getCorrectProductionTypeBySectionId(bundle.toSectionId);
	//
	let bundled = growthPigEvents.filter(e => e.bundleIdentifier === bundle.bundleId);
	const groupedByToPen = bundled.dictionaryBy('toPenId');
	// Setup gpes to edit, with correct values, by pen Options - Empty pens will show aswell
	options.forEach(o => {
		// let gpeToUse = bundled.filter(gpe => gpe.toPenId === o.value);
		// if (!gpeToUse) {
		let pen = hashmapLocations[o.value] as IPen;
		initData.push(
			GrowthPigMoveEventDto.fromJS({
				name: o.label,
				siteId: siteId,
				toBuildingId: pen.buildingId,
				toSectionId: pen.sectionId,
				toPenId: pen.id,
				correctToProductionType: correctProduction,
				toProductionType: bundle.toProductionType!,
				date: bundle.date!,
				amount: groupedByToPen[pen.id!]
					? groupedByToPen[pen.id!].reduce((acc, item) => acc + item.amount!, 0)
					: 0,
				avgWeight: newAvgWeight!,
				totalWeight: 0,
				bundleIdentifier: bundle.bundleId,
			} as IGrowthPigMoveEventDto),
		);
		// } else {
		// 	let pen = hashmapLocations[o.value] as IPen;

		// 	let totalWeight = 0;
		// 	let totalCount = 0;
		// 	gpeToUse.forEach(gpe => {
		// 		totalCount += gpe.amount ? gpe.amount : 0;
		// 		totalWeight += gpe.totalWeight ? gpe.totalWeight : 0;
		// 	});

		// 	initData.push(
		// 		GrowthPigMoveEventDto.fromJS({
		// 			id: gpeToUse.map(e => e.id).join(','),
		// 			name: o.label,
		// 			siteId: siteId,
		// 			toBuildingId: pen.buildingId,
		// 			toSectionId: pen.sectionId,
		// 			correctToProductionType: correctProduction,
		// 			toProductionType: bundle.toProductionType,
		// 			date: bundle.date,
		// 			toPenId: pen.id,
		// 			amount: totalCount,
		// 			avgWeight: totalCount ? Math.round((totalWeight / totalCount) * 10) / 10 : newAvgWeight,
		// 			totalWeight: totalWeight,
		// 			bundleIdentifier: bundle.bundleId,
		// 		}),
		// 	);
		// }
	});

	return initData;
};

export const HandlePenChangeMoveToGpeEdit = (
	data: IGrowthPigMoveEventDto[],
	pensToUse: Option[],
	totalAmount: number,
) => {
	let tmpTotal = totalAmount ? totalAmount : 0;
	const copyData = [...data];

	// Skip handles pens which need to be unset - This is to handle correct distribution
	let skipped = 0;
	copyData.forEach((e, index) => {
		const row = pensToUse.find(penOption => penOption.value === e.toPenId);
		if (row) {
			let penCount = Math.ceil(tmpTotal / (pensToUse.length - index + skipped));

			copyData[index] = { ...e, amount: penCount } as GrowthPigMoveEventDto;
			tmpTotal -= penCount;
		} else {
			copyData[index] = { ...e, amount: 0 } as GrowthPigMoveEventDto;
			skipped++;
		}
	});

	return copyData;
};

export function HandleMoveEditBeforeSave(
	currentData: GrowthPigMoveEventDtoExtend[],
	dataFromParent: GrowthPigDepartureFromSectionRowExtend[],
	date: Date,
	siteId: string | undefined,
	fromProductionType: AnimalType,
	toProductionType: AnimalType,
	toSection?: ISection,
) {
	const dataToSave: IGrowthPigMoveEventDto[] = [];

	// Sort away if from location don't depart any
	const dataFromParentCopy = deepCopy2(dataFromParent.filter(e => e.departedFromSection));
	const currentDataCopy = deepCopy2(currentData) as GrowthPigMoveEventDtoExtend[];
	const currentDate = new Date();
	let used = 0;

	// Get total sum to depart
	const sum = dataFromParentCopy.reduce(
		(sum, current) => sum + (current.departedFromSection !== undefined ? current.departedFromSection : 0),
		0,
	);
	while (used < sum) {
		for (let index = 0; index < currentDataCopy.length; ) {
			const data = currentDataCopy[index];
			const indexParent = dataFromParentCopy.findIndex(
				a =>
					!a.currentUsedAmount ||
					(a.currentUsedAmount && a.departedFromSection && a.currentUsedAmount < a.departedFromSection),
			);

			if (data.amount && indexParent >= 0) {
				const dataParent = dataFromParentCopy[indexParent];
				let amountToAdd: number =
					dataParent.departedFromSection &&
					(dataParent.currentUsedAmount
						? dataParent.departedFromSection! - dataParent.currentUsedAmount
						: dataParent.departedFromSection) >= data.amount
						? data.amount
						: dataParent.currentUsedAmount
						? dataParent.departedFromSection! - dataParent.currentUsedAmount
						: dataParent.departedFromSection!;
				if (
					data.currentUsedAmount &&
					data.amount &&
					(data.currentUsedAmount ? data.currentUsedAmount + amountToAdd : amountToAdd) > data.amount
				) {
					amountToAdd =
						amountToAdd -
						((data.currentUsedAmount ? data.currentUsedAmount + amountToAdd : amountToAdd) - data.amount!);
				}

				currentDataCopy[index].currentUsedAmount = data.currentUsedAmount
					? data.currentUsedAmount + amountToAdd
					: amountToAdd;
				dataFromParentCopy[indexParent].currentUsedAmount = dataFromParentCopy[indexParent].currentUsedAmount
					? dataFromParentCopy[indexParent].currentUsedAmount! + amountToAdd
					: amountToAdd;

				used += amountToAdd;

				// Because of a misunderstanding, we get correct production by sectionId
				const correctFromProduction = getCorrectProductionTypeBySectionId(dataParent.sectionId);
				const correctToProduction = getCorrectProductionTypeBySectionId(data.toSectionId);

				dataToSave.push(
					GrowthPigMoveEventDto.fromJS({
						id: new ObjectID().toHexString(),
						siteId: siteId,
						fromBuildingId: dataParent.buildingId,
						fromSectionId: dataParent.sectionId,
						fromPenId: dataParent.penId,
						fromProductionType: fromProductionType,
						correctFromProductionType: correctFromProduction,
						toProductionType:
							toSection &&
							(toSection.type === LocationType.ReliefFinisher || toSection.type === LocationType.Finisher)
								? AnimalType.Finisher
								: toProductionType,
						toBuildingId: data.toBuildingId,
						toSectionId: data.toSectionId,
						correctToProductionType: correctToProduction,
						toPenId: data.toPenId,
						amount: amountToAdd,
						avgWeight: dataParent.oldAvgWeight,
						totalWeight: data.avgWeight ? data.avgWeight * amountToAdd : 0,
						oldTotalWeight: amountToAdd * dataParent.newAvgWeight,
						date: date,
						modifiedOn: currentDate,
						createdOn: currentDate,
					} as IGrowthPigMoveEventDto),
				);
			}
			if (
				currentDataCopy[index].currentUsedAmount! >= currentDataCopy[index].amount! ||
				!currentDataCopy[index].amount
			) {
				index++;
			}
		}
	}
	return dataToSave;
}
