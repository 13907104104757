import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { WebAppState } from 'web/state/store.web';
import { Colors } from 'shared/assets/root-assets/colors';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { localized } from 'shared/state/i18n/i18n';
import styled from 'styled-components';
import { SkioldTouchableOpacity } from 'web/view/components/skiold-components/skiold-touchable-opacity';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import LocationOverview from '../location-overview/location-overview';
import './embed-site.scss';

const options = {
	locationOverview: 'locationOverview',
	processEquipment: 'processEquipment'
};

const LinkText = styled.p`
	color: ${Colors.white};
	font-size: 16;
	margin-right: 16px;

	&:hover {
		color: ${Colors.yellow};
	}
`;

const mapStateToProps = (state: WebAppState) => {
	return {};
};
const mapDispatchToProps = (dispatch: Dispatch) => ({});

interface SiteState {
	selected: keyof typeof options;
}

type SiteProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class Site extends React.PureComponent<SiteProps, SiteState> {
	constructor(props: SiteProps) {
		super(props);

		this.state = {
			selected: 'locationOverview'
		};
	}

	public render() {
		return (
			<ViewWeb className="embed-site">
				<TextWeb className="site-setup-label">{localized('siteSetup')}</TextWeb>
				<ViewWeb>
					<ViewWeb className="location-link-container">
						<SkioldTouchableOpacity onPress={this.locationChanged}>
							<LinkText>{localized('locationOverview')}</LinkText>
						</SkioldTouchableOpacity>
						<SkioldTouchableOpacity onPress={this.processEquipmentChanged}>
							<LinkText>{localized('processEquipment')}</LinkText>
						</SkioldTouchableOpacity>
					</ViewWeb>
					<LocationOverview selected={this.state.selected} />
				</ViewWeb>
			</ViewWeb>
		);
	}
	private locationChanged = () => {
		this.setState({ selected: 'locationOverview' });
	};

	private processEquipmentChanged = () => {
		this.setState({ selected: 'processEquipment' });
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Site);
