import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IMatingBatch } from 'shared/api/api';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { GetSyncData } from 'shared/state/ducks/dashboard-settings/operations';
import { GetDashboardOverviewTable } from 'shared/state/ducks/dashboard/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import DashBoardOverviewLitterCounter from 'web/view/components/dashboard/dashboard-overview-litter-counter';
import DashBoardOverviewSowCounter from 'web/view/components/dashboard/dashboard-overview-sow-counter';
import PageContainer from 'web/view/components/page-container/page-container';
import { showAlert } from 'web/view/components/skiold-alert/skiold-alert';
import { Heading } from 'web/view/components/utils/heading';
import { ViewWeb } from 'web/view/components/utils/web-view';
import DashBoardOverviewTable from '../../components/dashboard/dashboard-overview-table';
import './dashboard-overview.scss';
import { isEmpty } from 'shared/helpers/general-helpers';

const mapStateToProps = (state: WebAppState) => {
	return {
		profile: state.profile.active!,
		loading: state.dashboardOverview.loading,
		dashboardSettings: state.dashboardSettings.entity,
		matingBatches: state.matingBatches.entities,
		showDashboard:
			state.matingBatchSetting.currentMatingBatchSetting &&
			!isEmpty(state.matingBatchSetting.currentMatingBatchSetting) &&
			state.profile &&
			state.profile.active &&
			state.profile.active.processes &&
			state.profile.active.processes.find(p => p === '6d7562e8-f75a-4a79-83a6-98e535f8b9f7'),
		dashboardTableItems:
			state.dashboardOverview.data && state.dashboardOverview.data.dashboardTableData
				? state.dashboardOverview.data.dashboardTableData
				: {},
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
	return {
		GetDashboardOverviewTable: (siteId: string, showSpinner?: boolean) =>
			GetDashboardOverviewTable(siteId, showSpinner)(dispatch),
		GetDashboardSettings: () => GetSyncData()(dispatch),
	};
};

export interface State {
	endMatingBatch?: IMatingBatch;
	showGoalOverUnderYear: boolean;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export class DashboardOverview extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		let showGoalOverUnderYear = false;
		this.props.GetDashboardSettings();

		const endMatingBatch = props.matingBatches.find(mb => mb.id === this.props.dashboardSettings.endMatingBatchId);
		if (
			props.dashboardSettings &&
			!this.props.dashboardSettings.rollingYear &&
			endMatingBatch &&
			endMatingBatch.matingPeriodStart &&
			endMatingBatch.matingPeriodStart < new Date()
		) {
			showGoalOverUnderYear = false;
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_DASHBOARD_NEED_LATER_ENDMATINGBATCH));
		} else if (props.dashboardSettings && props.dashboardSettings.siteId) {
			showGoalOverUnderYear = true;
		}
		this.state = {
			endMatingBatch,
			showGoalOverUnderYear,
		};
	}

	public componentDidMount() {
		if (this.props.profile.siteId && this.props.showDashboard) {
			this.props.GetDashboardOverviewTable(this.props.profile.siteId);
			this.refreshDashboard();
		}
	}

	public componentDidUpdate(prevProps: Props) {
		if (
			this.props.showDashboard &&
			this.props.profile.siteId &&
			this.props.showDashboard !== prevProps.showDashboard
		) {
			this.props.GetDashboardOverviewTable(this.props.profile.siteId);
			this.refreshDashboard();
		}
	}

	private interval = 1000 * 60 * 15; // 15min;
	private intervalRef: any;
	private refreshDashboard = () => {
		if (this.intervalRef) {
			clearInterval(this.intervalRef);
		}
		this.intervalRef = setInterval(() => {
			if (this.props.profile.siteId && this.props.showDashboard) {
				this.props.GetDashboardOverviewTable(this.props.profile.siteId, false);
			}
		}, this.interval);
	};

	public componentWillUnmount() {
		if (this.intervalRef) {
			clearInterval(this.intervalRef);
		}
	}

	public static getDerivedStateFromProps(nextProps: Props, prevState: State) {
		if (nextProps.dashboardSettings && !nextProps.dashboardSettings.rollingYear && !prevState.endMatingBatch) {
			const endMatingBatch = nextProps.matingBatches.find(
				mb => mb.id === nextProps.dashboardSettings.endMatingBatchId,
			);
			if (
				nextProps.dashboardSettings &&
				!nextProps.dashboardSettings.rollingYear &&
				endMatingBatch &&
				endMatingBatch.matingPeriodStart &&
				endMatingBatch.matingPeriodStart < new Date()
			) {
				showAlert(localized(ExceptionMessage.VALIDATION_ERROR_DASHBOARD_NEED_LATER_ENDMATINGBATCH));
				return { endMatingBatch, showGoalOverUnderYear: false };
			} else if (nextProps.dashboardSettings && nextProps.dashboardSettings.siteId) {
				return { showGoalOverUnderYear: true };
			}
		} else if (nextProps.dashboardSettings && nextProps.dashboardSettings.rollingYear) {
			return { showGoalOverUnderYear: true };
		}

		return {};
	}

	public render() {
		return (
			<PageContainer className="dashboardOverview animals">
				<Heading text={localized(this.props.showDashboard ? 'DASHBOARD' : '')} />
				{this.props.profile.siteId && this.props.showDashboard && (
					<ViewWeb className="viewContainer">
						<ViewWeb className="flexDirectionRow flex-space-between">
							<DashBoardOverviewSowCounter />
							<DashBoardOverviewLitterCounter />
						</ViewWeb>
						<DashBoardOverviewTable
							dashboardTableItems={this.props.dashboardTableItems}
							loading={this.props.loading}
							showGoalOverUnderYear={this.state.showGoalOverUnderYear}
						/>
					</ViewWeb>
				)}
			</PageContainer>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardOverview);
