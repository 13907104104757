import { ISite } from "shared/api/api";


export const LOAD = 'LOAD';
export const SAVE_SITE = 'SAVE_SITE';

export interface SiteState {
    site: ISite;
    isLoading?: boolean;
}
