import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Option } from 'react-dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { AnimalType } from 'shared/api/api';
import { GetSyncData } from 'shared/state/ducks/process-equipments/operations';
import { WebAppState } from 'web/state/store.web';
import { SkioldFormDropdown } from '../skiold-components/skiold-dropdown/skiold-form-dropdown';
import { SkioldDropdown } from '../skiold-components/skiold-dropdown/skiold-dropdown';

interface PropsFromParent {
	animalType?: AnimalType;
	penId?: string;
	sectionId?: string;
	onSelect?: (recipeNumber: number) => void;
	form?: boolean;
}
export const DistriwinFeedCurvePicker: FC<PropsFromParent> = React.memo(
	({ onSelect, animalType, penId, sectionId, form }) => {
		const [selected, setSelected] = useState<Option | undefined>(undefined);
		const utp = useSelector((state: WebAppState) => state.unitToPenData.data.find(utp => utp.penId === penId));
		const ped = useSelector((state: WebAppState) =>
			state.processEquipmentData.entities.find(ped => ped.sectionId === sectionId),
		);

		const pe = useSelector((state: WebAppState) =>
			state.processEquipments.entities.find(pe => pe.code === ped?.code?.split(':')[0]),
		);
		const valveGroup = useSelector((state: WebAppState) =>
			state.adjustFeed.valveGroups.find(vg => vg.id === utp?.unitGroupId),
		);
		const dispatch = useDispatch();
		const feedCurveOptions = useMemo(() => {
			if (valveGroup) {
				return valveGroup?.availableFeedingCurves
					?.filter(z => z.type === 'pig')
					?.map(
						x =>
							({
								value: x.curveId.toString(),
								label: x.name,
							} as Option),
					);
			} else if (pe) {
				return pe?.feedCurves
					?.filter(z => z.type === 'pig')
					?.map(
						x =>
							({
								value: x.curveId.toString(),
								label: x.name,
							} as Option),
					);
			}
			return [];
		}, [valveGroup, pe]);
		useEffect(() => {
			dispatch(GetSyncData());
		}, []);

		// Set recipe on init
		useEffect(() => {
			if (valveGroup && feedCurveOptions) {
				const item = feedCurveOptions.find(r => r.value === valveGroup.feedingCurve?.toString());
				if (item) {
					setSelected(item);
					if (onSelect) {
						onSelect(+item.value);
					}
				}
			}
		}, [valveGroup, feedCurveOptions]);

		const onSelectLocal = useCallback(
			(newValue: Option, itemFromParent?: any) => {
				setSelected(newValue);
				if (onSelect) {
					onSelect(+newValue.value);
				}
			},
			[setSelected],
		);

		return form ? (
			<SkioldFormDropdown
				items={feedCurveOptions}
				theme={'dark'}
				containerClassName="grade-dropdown"
				selectedValue={selected}
				onValueChanged={onSelectLocal}
			/>
		) : (
			<SkioldDropdown
				items={feedCurveOptions}
				theme={'light'}
				containerClassName="basic-dropdown"
				selectedValue={selected}
				onValueChanged={onSelectLocal}
			/>
		);
	},
);
