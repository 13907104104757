import { CSSProperties } from 'react';

export interface FormWrapperPropsFromParent {
    formRows: FormRow[];
    containerStyle?: CSSProperties ;
    containerClassName?: string;
    roundCorners?: boolean;
}

export interface FormWrapperItemPropsFromParent {
    formRow: FormRow;
    formRowLength: number;
    formRowIndex: number;
    roundCorners?: boolean;
}

export interface FormRow {
    name?: string | JSX.Element;
    component?: JSX.Element;
    header?: string;
    doubleHeader?: DoubleHeader;
    style?: any;
    className?: string;
}

export class DoubleHeader {
    public left: string;
    public right?: string;

    constructor(left: string, right?: string) {
        this.left = left;
        this.right = right;
    }
}
