import { createAsyncThunk } from '@reduxjs/toolkit';
import { AsyncOperationHandler } from '../../../helpers/redux-helpers';
import { INucleusManagementBreedingSettings, NucleusManagementBreedingSettings } from '../../../api/api';

export const getBreedingSettings = createAsyncThunk(
	'nucleusManagementBreedingSettings/getBreedingSettings',
	(siteId: string) => {
		return AsyncOperationHandler(client => client.nucleusManagementBreedingSettings_Get(siteId));
	},
);

export const upsertBreedingSettings = createAsyncThunk(
	'nucleusManagementBreedingSettings/upsertBreedingSettings',
	(herdSettings: INucleusManagementBreedingSettings) => {
		return AsyncOperationHandler(client =>
			client.nucleusManagementBreedingSettings_Post(herdSettings as NucleusManagementBreedingSettings),
		);
	},
);

export const upsertManyBreedingSettings = createAsyncThunk(
	'nucleusManagementBreedingSettings/upsertManyBreedingSettings',
	(breedingSettings: INucleusManagementBreedingSettings[]) => {
		return AsyncOperationHandler(client =>
			client.nucleusManagementBreedingSettings_UpsertMany(
				breedingSettings as NucleusManagementBreedingSettings[],
			),
		);
	},
);
