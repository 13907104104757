import React from 'react';
import ObjectID from 'bson-objectid';
import memoize from 'memoize-one';
import { Option } from 'react-dropdown';
import {
	AnimalType,
	BalancePigEventTotalWeightBalancePigEvents,
	BalanceWeanedPigEvent,
	GrowthPigEventType,
	GrowthPigMoveEventDto,
	GrowthPigsEvent,
	IBuilding,
	IGrowthPigEntranceEventDto,
	IGrowthPigMoveEventDto,
	IPen,
	ISection,
	ITotalWeight,
	IUserProfile,
	LocationType,
	TotalWeight,
} from 'shared/api/api';
import { upsertBalanceWeanedPigsData } from 'shared/state/ducks/balance-weaned-pigs/operations';
import {
	upsertGrowthPigsEvent,
	upsertGrowthPigsPigletMoveEventsAndUpdateEvents,
} from 'shared/state/ducks/growth-pig-events/operations';
import { localized } from 'shared/state/i18n/i18n';
import { getDateString } from '../date-helpers';
import {
	generateDataForEntranceDistribute,
	generateGrowthPigEntranceEventDto,
} from '../growth-pigs-helper/growth-pig-event-function-helper';
import { DistributeTableDataTypes } from '../growth-pigs-helper/growth-pig-event-helper';
import {
	formatLocationString,
	getBuildingByPenId,
	getLocationStringBySectionId,
	getSectionByPenId,
} from '../location-helper';
import { NaturalSort } from '../natural-sort';

const blankOption = { label: ' ', value: '' };
export const BalanceWeanedPigsOverviewTableWidths = {
	dateWidth: 120,
	amountWidth: 100,
	editWidth: 40,
	typeWidth: 200,
};

export const validateSellWeanedPigs = (date?: Date, amount?: number, siteId?: string) => {
	if (!date) {
		return false;
	}

	if (amount === undefined) {
		return false;
	}

	if (!siteId) {
		return false;
	}
	return true;
};

export function getCorrectProductionTypeForBalanceWeanedPigs(
	selectedSection: Option,
	hashmapLocations: { [key: string]: any },
): AnimalType | undefined {
	if (selectedSection && selectedSection.value.length > 0) {
		const sectionInUse = hashmapLocations[selectedSection.value] as ISection;
		if (
			sectionInUse &&
			(sectionInUse.type === LocationType.ReliefWeaners || sectionInUse.type === LocationType.Weaners)
		) {
			return AnimalType.Weaner;
		} else {
			return AnimalType.Finisher;
		}
	}
	return AnimalType.FRATS;
}

export const balanceWeanedPigsproductionTypeOptions = (
	sections: ISection[],
	eventsToUse: GrowthPigsEvent[] | undefined,
) => {
	const options: Option[] = [];
	const weanerSection = sections.find(s => s.animalType === AnimalType.Weaner);
	const finisherSection = sections.find(s => s.animalType === AnimalType.Finisher);
	const fratsSection = sections.find(
		s =>
			s.animalType === AnimalType.FRATS &&
			(s.type === LocationType.Weaners ||
				s.type === LocationType.ReliefWeaners ||
				s.type === LocationType.Finisher ||
				s.type === LocationType.ReliefFinisher),
	);
	if (weanerSection) {
		options.push({ value: AnimalType.Weaner, label: localized(AnimalType.Weaner) });
	}
	if (finisherSection) {
		options.push({ value: AnimalType.Finisher, label: localized(AnimalType.Finisher) });
	}
	if (fratsSection) {
		options.push({ value: AnimalType.FRATS, label: localized(AnimalType.FRATS) });
	}

	options.push({ value: AnimalType.YoungFemale, label: localized(AnimalType.YoungFemale) });
	let firstEvent = eventsToUse && eventsToUse.length > 0 ? eventsToUse[0] : undefined;
	let optionToUse = options.find(op => firstEvent && op.value === firstEvent.toProductionType);
	return { options, selectedOption: optionToUse ? optionToUse : options[0] };
};

export function getAvgWeightParentAndTotalBalanceWeanedPigs(
	totalWeight: ITotalWeight | undefined,
	data: BalanceWeanedPigEvent[],
) {
	const total =
		totalWeight && totalWeight.total !== undefined
			? totalWeight.total +
			  data.reduce(
					(a, b) =>
						a +
						(b.growthPigEventType === GrowthPigEventType.PigletWeanedEvent
							? b.totalAmount
								? b.totalAmount
								: 0
							: b.totalAmount
							? -b.totalAmount
							: 0),
					0,
			  )
			: 0;

	const avgWeightParent =
		totalWeight && totalWeight.total && totalWeight.weight ? totalWeight.weight / totalWeight.total : 0;

	return { avgWeightParent, total };
}

export function distributeWeanedPigsSetAmount(
	dataArrayCopy: DistributeTableDataTypes[],
	item: IGrowthPigEntranceEventDto,
	numb: number | undefined,
) {
	const indexToModify = dataArrayCopy.findIndex(a => a.toPenId === item.toPenId);
	if (indexToModify >= 0) {
		const dataToModify = { ...dataArrayCopy[indexToModify] };

		dataToModify.totalWeight = (dataToModify.avgWeight ?? 0) * (numb ?? 0);
		dataToModify.amount = numb ?? 0;

		dataArrayCopy[indexToModify] = dataToModify;
	}
}

export function weanedPigSoldEvent(
	amount: number | undefined,
	date: Date | undefined,
	siteId: string | undefined,
	growthPigEvent: GrowthPigsEvent | undefined,
	avgWeightParent: number,
	dispatch,
	resetComponent: () => void,
) {
	const eventId = growthPigEvent && growthPigEvent.id ? growthPigEvent.id : new ObjectID().toHexString();
	const sellWeanedPigs = {
		amount: amount,
		date: date,
		siteId: siteId,
		id: eventId,
		growthPigEventType: GrowthPigEventType.PigletDepartureSoldEvent,
	} as GrowthPigsEvent;
	const balanceEvents = BalancePigEventTotalWeightBalancePigEvents.fromJS({
		balanceWeanedPigEvents: [],
		totalWeightToCalculateWith: TotalWeight.fromJS({ total: 0, weight: 0 }),
	});
	if (
		balanceEvents.balanceWeanedPigEvents &&
		balanceEvents.totalWeightToCalculateWith &&
		balanceEvents.totalWeightToCalculateWith.weight !== undefined &&
		balanceEvents.totalWeightToCalculateWith.total !== undefined
	) {
		balanceEvents.balanceWeanedPigEvents.push({
			eventId: eventId,
			date: sellWeanedPigs.date,
			growthPigEventType: GrowthPigEventType.PigletDepartureSoldEvent,
			totalAmount: sellWeanedPigs.amount,
			totalWeight: (sellWeanedPigs.amount ? sellWeanedPigs.amount : 0) * avgWeightParent,
		} as BalanceWeanedPigEvent);
		balanceEvents.totalWeightToCalculateWith.total += sellWeanedPigs.amount ? sellWeanedPigs.amount : 0;
		balanceEvents.totalWeightToCalculateWith.weight +=
			(sellWeanedPigs.amount ? sellWeanedPigs.amount : 0) * avgWeightParent;
	}

	// Handle currnet pigs on stable
	let oldAmount = growthPigEvent && growthPigEvent.amount !== undefined ? growthPigEvent.amount : 0;
	let newAmount = sellWeanedPigs.amount !== undefined ? sellWeanedPigs.amount : 0;
	dispatch(upsertGrowthPigsEvent(sellWeanedPigs));
	dispatch(upsertBalanceWeanedPigsData(balanceEvents, [], oldAmount - newAmount));
	resetComponent();
}

export interface BalanceWeanedPigEventOverviewTableData {
	eventIds: string[];
	date?: Date | undefined;
	sectionId?: string;
	totalAmount?: number | undefined;
	eventName?: string;
	totalWeight?: number | undefined;
	growthPigEventType?: GrowthPigEventType;
}

export const generateBalancedWeanedOverviewTableData = (
	data: BalanceWeanedPigEvent[],
	gpeMemo: { [key: string]: GrowthPigsEvent },
	locMemo: { [key: string]: IPen | ISection | IBuilding },
) => {
	const tableData: BalanceWeanedPigEventOverviewTableData[] = [];

	data.forEach(item => {
		if (item.eventId) {
			const tableItem = gpeMemo[item.eventId];
			if (tableItem && tableItem.toPenId) {
				const buildingToUse = getBuildingByPenId(tableItem.toPenId, locMemo);
				const sectionToUse = getSectionByPenId(tableItem.toPenId, locMemo);
				const locationString = formatLocationString(buildingToUse, sectionToUse);
				const alreadyInTableIndex = tableData.findIndex(
					td =>
						sectionToUse &&
						sectionToUse.id &&
						td.sectionId === sectionToUse.id &&
						getDateString(tableItem.date) === getDateString(td.date),
				);
				if (alreadyInTableIndex >= 0) {
					tableData[alreadyInTableIndex].eventIds.push(item.eventId);
					tableData[alreadyInTableIndex].totalAmount =
						(tableData[alreadyInTableIndex].totalAmount ? tableData[alreadyInTableIndex].totalAmount! : 0) +
						(item.totalAmount ? item.totalAmount! : 0);
					tableData[alreadyInTableIndex].totalWeight =
						(tableData[alreadyInTableIndex].totalWeight ? tableData[alreadyInTableIndex].totalWeight! : 0) +
						(item.totalWeight ? item.totalWeight! : 0);
				} else {
					tableData.push({
						eventIds: [item.eventId],
						sectionId: sectionToUse && sectionToUse.id,
						date: item.date,
						totalAmount: item.totalAmount,
						totalWeight: item.totalWeight,
						eventName: locationString,
						growthPigEventType: item.growthPigEventType,
					});
				}
			} else {
				tableData.push({
					eventIds: [item.eventId],
					date: item.date,
					totalAmount: item.totalAmount,
					totalWeight: item.totalWeight,
					eventName: item.growthPigEventType ? localized(item.growthPigEventType) : '',
					growthPigEventType: item.growthPigEventType,
				});
			}
		} else {
			tableData.push({
				eventIds: [],
				date: item.date,
				totalAmount: item.totalAmount,
				totalWeight: item.totalWeight,
				eventName: item.growthPigEventType ? localized(item.growthPigEventType) : '',
				growthPigEventType: item.growthPigEventType,
			});
		}
	});
	return tableData;
};

export const BalanceWeanedPigsGenerateInitialDataFromProps = memoize(
	(hashmapLocations: { [key: string]: any }, eventsToUse?: GrowthPigsEvent[]) => {
		let selectedProductionOption: Option = blankOption;
		let selectedSectionOption: Option = blankOption;
		let selectedAmountToUse: number | undefined;
		let selectedFromPenOption: Option = blankOption;
		let selectedToPenOption: Option = blankOption;
		let fromPenOrder: number | undefined;
		let toPenOrder: number | undefined;
		if (eventsToUse) {
			for (let index = 0; index < eventsToUse.length; index++) {
				const event = eventsToUse[index];
				if (selectedProductionOption === blankOption && event.toProductionType) {
					selectedProductionOption = {
						label: localized(event.toProductionType),
						value: event.toProductionType,
					};
				}

				if (selectedSectionOption === blankOption && event.toSectionId && hashmapLocations && event.toPenId) {
					const sectionToUse = hashmapLocations[event.toSectionId] as ISection;

					const locationString = getLocationStringBySectionId(sectionToUse.id, hashmapLocations);
					if (sectionToUse && sectionToUse.name && sectionToUse.id && locationString) {
						selectedSectionOption = { label: locationString, value: sectionToUse.id };
					}
				}
				if (event.toPenId) {
					const penToUse = hashmapLocations[event.toPenId] as IPen;
					if (
						penToUse &&
						penToUse.name &&
						penToUse.id &&
						(fromPenOrder === undefined || (penToUse.order !== undefined && fromPenOrder > penToUse.order))
					) {
						fromPenOrder = penToUse.order;
						selectedFromPenOption = { label: penToUse.name, value: penToUse.id };
					}
					if (
						penToUse &&
						penToUse.name &&
						penToUse.id &&
						(toPenOrder === undefined || (penToUse.order !== undefined && toPenOrder < penToUse.order))
					) {
						toPenOrder = penToUse.order;
						selectedToPenOption = { label: penToUse.name, value: penToUse.id };
					}
				}

				selectedAmountToUse =
					(selectedAmountToUse ? selectedAmountToUse : 0) + (event.amount ? event.amount : 0);
			}
		}

		return {
			selectedFromPenOption,
			selectedToPenOption,
			selectedSectionOption,
			selectedProductionOption,
			selectedAmountToUse,
		};
	},
);

export function BalanceWeanedPigsHandleSaveDistributeWeanedPigs(
	tableData: DistributeTableDataTypes[],
	profile: IUserProfile | undefined,
	dateToUse: Date,
	weanedPenId: string | undefined,
	hashmapLocations: { [key: string]: any },
	avgWeightParent: number,
	eventsParent: GrowthPigsEvent[] | undefined,
	dispatch,
	resetComponent: () => void,
	feedCurve?: number,
	recipeNumber?: number,
) {
	const itemsToSave: IGrowthPigEntranceEventDto[] = generateGrowthPigEntranceEventDto(
		tableData,
		dateToUse,
		profile && profile.siteId,
	);
	const gpeMoveEvents: IGrowthPigMoveEventDto[] = [];
	const balanceEvents = BalancePigEventTotalWeightBalancePigEvents.fromJS({
		balanceWeanedPigEvents: [],
		totalWeightToCalculateWith: TotalWeight.fromJS({ total: 0, weight: 0 }),
	});
	if (itemsToSave.length > 0) {
		itemsToSave.forEach(element => {
			const building = getBuildingByPenId(weanedPenId, hashmapLocations);
			const section = getSectionByPenId(weanedPenId, hashmapLocations);

			gpeMoveEvents.push(
				GrowthPigMoveEventDto.fromJS({
					...element,
					fromBuildingId: building && building.id,
					fromSectionId: section && section.id,
					fromPenId: weanedPenId,
					fromProductionType: AnimalType.Piglet,
					correctFromProductionType: AnimalType.Piglet,
					feedCurve: feedCurve,
					recipe: recipeNumber,
				} as IGrowthPigMoveEventDto),
			);
			if (
				balanceEvents.balanceWeanedPigEvents &&
				balanceEvents.totalWeightToCalculateWith &&
				balanceEvents.totalWeightToCalculateWith.weight !== undefined &&
				balanceEvents.totalWeightToCalculateWith.total !== undefined
			) {
				balanceEvents.balanceWeanedPigEvents.push({
					eventId: element.id,
					date: element.date,
					growthPigEventType: GrowthPigEventType.PigletMovedEvent,
					totalAmount: element.amount,
					totalWeight: (element.amount ? element.amount : 0) * avgWeightParent,
				} as BalanceWeanedPigEvent);
				balanceEvents.totalWeightToCalculateWith.total += element.amount ? element.amount : 0;
				balanceEvents.totalWeightToCalculateWith.weight +=
					(element.amount ? element.amount : 0) * avgWeightParent;
			}
		});

		dispatch(
			upsertGrowthPigsPigletMoveEventsAndUpdateEvents(
				gpeMoveEvents as GrowthPigMoveEventDto[],
				(eventsParent
					? eventsParent.map(ev =>
							GrowthPigsEvent.fromJS({
								...ev,
								isDeleted: true,
								growthPigEventType: GrowthPigEventType.PigletMovedEvent,
							}),
					  )
					: []) as GrowthPigsEvent[],
			),
		);

		// Handle current pigs on stable statee
		let newDepartEvents = gpeMoveEvents.map(e => e.amount!).reduce((acc, curr) => curr + acc); // To subtract
		let oldDepartEvents = eventsParent && eventsParent.map(e => e.amount!).reduce((acc, curr) => curr + acc); // To Add

		dispatch(
			upsertBalanceWeanedPigsData(
				balanceEvents,
				eventsParent ? eventsParent.map(gpe => gpe.id!) : undefined,
				(oldDepartEvents ? oldDepartEvents : 0) - newDepartEvents,
			),
		);
		resetComponent();
	}
}

export function BalanceWeanedPigsHandleOnPenChanged(
	option: Option | undefined,
	itemFromParent: string,
	penOptions: Option[],
	selectedToPen: Option,
	selectedFromPen: Option,
	setTableData: React.Dispatch<React.SetStateAction<DistributeTableDataTypes[]>>,
	pens: IPen[],
	selectedSection: Option,
	amountToUse: number | undefined,
	avgWeightParent: number,
	profile: IUserProfile | undefined,
	selectedProduction: Option,
	setSelectedFromPen: React.Dispatch<React.SetStateAction<Option>>,
	setSelectedToPen: React.Dispatch<React.SetStateAction<Option>>,
) {
	if (option) {
		let penIds: string[] =
			itemFromParent === 'fromPen'
				? BalanceWeanedPigsPenIdsToUse(penOptions, option, selectedToPen)
				: BalanceWeanedPigsPenIdsToUse(penOptions, selectedFromPen, option);

		setTableData(
			generateDataForEntranceDistribute(
				pens.filter(a => a.id && penIds.includes(a.id)).sort((a, b) => NaturalSort(a.name, b.name)),
				selectedSection.value,
				undefined,
				amountToUse ? amountToUse : 0,
				avgWeightParent,
				new Date(),
				profile,
				new Date(),
				selectedProduction.value as AnimalType,
			),
		);
		if (itemFromParent === 'fromPen') {
			setSelectedFromPen(option);
		} else {
			setSelectedToPen(option);
		}
	}
}

export function BalanceWeanedPigsPenIdsToUse(penOptions: Option[], selectedFromPen: Option, selectedToPen: Option) {
	const pensFrom = penOptions.findIndex(a => a.value === selectedFromPen.value);
	const pensTo = penOptions.findIndex(a => a.value === selectedToPen.value);
	let penIds: string[] = [];
	if (pensFrom <= pensTo) {
		penIds = penOptions.slice(pensFrom, pensTo + 1).map(a => a.value);
	}
	return penIds;
}

export function BalancedWeanedPigsetAmountAccordingToMaxAmount(
	penOptions: Option[],
	selectedFromPen: Option,
	selectedToPen: Option,
	setTableData: React.Dispatch<React.SetStateAction<DistributeTableDataTypes[]>>,
	pens: IPen[],
	profile: IUserProfile | undefined,
	selectedSection: Option,
	num: number | undefined,
	avgWeightParent: number,
	selectedProduction: Option,
	setAmountToUse: React.Dispatch<React.SetStateAction<number | undefined>>,
	setAmountToUseForSetTableData?: React.Dispatch<React.SetStateAction<number | undefined>>,
) {
	let penIds: string[] = BalanceWeanedPigsPenIdsToUse(penOptions, selectedFromPen, selectedToPen);

	setTableData(
		generateDataForEntranceDistribute(
			pens.filter(a => a.id && penIds.includes(a.id)),
			selectedSection.value,
			undefined,
			num,
			avgWeightParent,
			new Date(),
			profile,
			new Date(),
			selectedProduction.value as AnimalType,
		),
	);
	setAmountToUse(num);
	if (setAmountToUseForSetTableData) {
		setAmountToUseForSetTableData(num);
	}
}
