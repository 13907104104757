import { IPoolYoungFemale } from "shared/api/api";
import { Syncable } from "shared/state/models/syncable";


export const UPSERT_POOL_YOUNG_FEMALE = 'UPSERT_POOL_YOUNG_FEMALE';
export const DELETE_POOL_YOUNG_FEMALE = "DELETE_POOL_YOUNG_FEMALE";
export const SYNC_POOL_YOUNG_FEMALE = 'SYNC_POOL_YOUNG_FEMALE';
export const UPSERT_MANY_POOL_YOUNG_FEMALE = 'UPSERT_MANY_POOL_YOUNG_FEMALE'
export const DELETE_MANY_POOL_YOUNG_FEMALE = 'DELETE_MANY_POOL_YOUNG_FEMALE'

// tslint:disable-next-line: interface-name

export interface PoolYoungFemaleState extends Syncable<IPoolYoungFemale> {
}

