import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { HistoryState } from '.';
import { fetchHistory, fetchLatestHistory } from './actions';
import { siteChange } from '../profile/actions';
import { mergeArraysNoUnique, upsertValueInArrayCustomId } from 'shared/helpers/reducer-helpers';

export const initialHistoryState: HistoryState = {
	histories: [],
	isLoading: false,
	latestHistories: [],
};

const locationsReducer = (state: HistoryState = initialHistoryState, action: Action): HistoryState => {
	if (isType(action, fetchHistory.started)) {
		return { ...state, isLoading: true, histories: [] };
	}
	if (isType(action, fetchHistory.done)) {
		if (action.payload.params.siteId === action.payload.params.activeSiteId) {
			return { ...state, isLoading: false, histories: action.payload.result };
		} else {
			state = {
				...state,
				isLoading: false,
			};
		}
	}

	if (isType(action, fetchLatestHistory.done)) {
		if (action.payload.params.siteId === action.payload.params.activeSiteId) {
			return {
				...state,
				isLoading: false,
				latestHistories: upsertValueInArrayCustomId(
					state.latestHistories,
					action.payload.result,
					e => e?.valveGroupId!,
				),
			};
		} else {
			state = {
				...state,
				isLoading: false,
			};
		}
	}
	if (isType(action, fetchHistory.failed)) {
		return { ...state, isLoading: false, histories: [] };
	}
	if (isType(action, siteChange.done)) {
		state = initialHistoryState;
	}

	return state;
};

export default locationsReducer;
