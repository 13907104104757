import ObjectID from 'bson-objectid';
import {
	AnimalType,
	GrowthProductionReportSettingDiverseInformationKey,
	GrowthProductionReportSettingFeedKey,
	GrowthProductionReportSettingProductionKey,
	GrowthProductionReportSettingSlaughterDataKey,
	GrowthProductionReportSettingStateKey,
	GrowthProductionReportSettingSubResultKey,
	IProductionReportSetting,
	PReportPeriod,
	ProductionReportSetting,
} from 'shared/api/api';

export function initProductionReportSetting(
	productionSetting: IProductionReportSetting | undefined,
	siteId: string,
	animalType: AnimalType,
) {
	if (productionSetting && productionSetting.id) {
		if (productionSetting.periods === undefined) {
			const setting = { ...productionSetting } as ProductionReportSetting;
			setting.periods = [];
			generateInitialPeriods(setting);
			return setting;
		} else {
			return productionSetting;
		}
	} else {
		let setting = ProductionReportSetting.fromJS({
			animaltype: animalType!,
			periods: [] as PReportPeriod[],
			id: new ObjectID().toHexString(),
			siteId: siteId,
		} as IProductionReportSetting);

		generateInitialPeriods(setting);

		if (animalType === AnimalType.Sow) {
			setting.keyNumbers = pReportKeyValue;
		} else {
			setting.showFeedUsage = true;
			setting.growthPigPReportProduction = GrowthProductionReportSettingProductionKey.fromJS({});
			setting.growthPigPReportFeed = GrowthProductionReportSettingFeedKey.fromJS({});
			setting.growthPigPReportDiverseInformation = GrowthProductionReportSettingDiverseInformationKey.fromJS({});
			setting.growthPigPReportSlaughterHouseData = GrowthProductionReportSettingSlaughterDataKey.fromJS({});
			setting.growthPigPReportState = GrowthProductionReportSettingStateKey.fromJS({});
			setting.growthPigPReportSubResults = GrowthProductionReportSettingSubResultKey.fromJS({});
			pReportGrowthPigEventValues.forEach(key => {
				if (setting[key]) {
					const keys = Object.keys(setting[key]);
					keys.forEach(valueKey => {
						setting[key][valueKey] = true;
					});
				}
			});
		}
		return setting;
	}
}

const pReportGrowthPigEventValues = [
	'growthPigPReportProduction',
	'growthPigPReportFeed',
	'growthPigPReportDiverseInformation',
	'growthPigPReportSlaughterHouseData',
	'growthPigPReportState',
	'growthPigPReportSubResults',
];

const pReportKeyValue = {
	TotalMatings: true,
	YoungAnimalMatings: true,
	ReMatingPercent: true,
	WeaningToFirstMating: true,
	AvgWasteDays: true,
	TotalFarrowings: true,
	ThereOfGiltsPcs: true,
	AlivePerLitterPcs: true,
	DeadPerLitter: true,
	FarrowingPercent: true,
	AvgPregnantDaysPerLitter: true,
	TotalWeanings: true,
	ThereOfFirstLitterPcs: true,
	FirstLitterPercent: true,
	NursingSows: true,
	NursingSowPercent: true,
	AvgAlivePerLitter: true,
	AvgDeadPerLitter: true,
	AvgWeanedPerLitter: true,
	AvgWeight: true,
	DeadTillWeaningPercent: true,
	AvgNursingDays: true,
	YearSowPcs: true,
	WeanedPigletsPcs: true,
	AvgWeightPerPiglet: true,
	PercentDeadSowGilts: true,
	WeanedPerWeaningPcs: true,
	FePerYearYoungFemalePerDay: true,
	PeriodFeedDaysYoungAnimal: true,
	SowFoodFeYearSow: true,
	ThereOfYoungAnimalFood: true,
	FePerYearSowPerDay: true,
	WasteDaysPerDay: true,
	ThereOfAdjustedDays: true,
	LitterPerYearSowPcs: true,
	WeanedPigletsPerYearSowPcs: true,
	FeedUsage: true,
	Status: true,
	SubResult: true,
};
function generateInitialPeriods(setting: ProductionReportSetting) {
	for (let i = 1; i <= (!setting.animaltype || setting.animaltype === AnimalType.Sow ? 4 : 5); i++) {
		setting.periods!.push(
			new PReportPeriod({
				fromDate: undefined,
				toDate: undefined,
				periodNumber: i,
			}),
		);
	}
}
