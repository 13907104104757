import { Syncable } from "shared/state/models/syncable";
import { IWorkListSetting } from "shared/api/api";


export const GET_WORK_LIST_SETTINGS_BY_SITE = 'GET_WORK_LIST_SETTINGS_BY_SITE';
export const SAVE_WORK_LIST_SETTING = 'SAVE_WORK_LIST_SETTING';
export const SYNC_DATA_WORK_LIST_SETTINGS = 'SYNC_DATA_WORK_LIST_SETTINGS';

export const SET_SAVE_FUNCTION = 'SET_SAVE_FUNCTION';
export const SET_DELETE_FUNCTION = 'SET_DELETE_FUNCTION';
export const SET_PRINT_FUNCTION = 'SET_PRINT_FUNCTION';

export interface WorkListSettingState extends Syncable<IWorkListSetting> {
    saveFunction?: (any?: any) => any
    deleteFunction?: (any?: any) => any
    printFunction?: (any?: any) => any
}
