import { createAsyncThunk } from '@reduxjs/toolkit';
import ObjectID from 'bson-objectid';
import { BreedTableItem, DadMomBreed, IBreedTableItem } from 'shared/api/api';
import { removeRedundantDataByArray } from 'shared/helpers/general-helpers';
import { StoreContainer } from 'shared/state/store-container';
import { AsyncOperationHandler } from '../../../helpers/redux-helpers';

export const getSyncBreedTableItems = createAsyncThunk('breedTableItems/getSyncBreedTableItems', () => {
	const state = StoreContainer.getState();
	const lastSyncDate = state.breedTableItems.lastSyncDate;

	return AsyncOperationHandler(client => client.breedTableItem_Sync(lastSyncDate));
});

export const upsertManyBreedTableItems = createAsyncThunk(
	'breedTableItems/upsertManyBreedTableItems',
	(breedTableItems: IBreedTableItem[]) => {
		breedTableItems.forEach(item => {
			if (!item.id) {
				item.id = new ObjectID().toHexString();
			}
		});
		const removedUndefined = removeRedundantDataByArray(breedTableItems);
		return AsyncOperationHandler(client => client.breedTableItem_UpsertMany(removedUndefined as BreedTableItem[]));
	},
);

export const deleteByDadAndMom = createAsyncThunk('breedTableItems/deleteByDadAndMom', (item: DadMomBreed) => {
	return AsyncOperationHandler(client => client.breedTableItem_DeleteByDadAndMom(item));
});

export const getUniqueDadBreeds = createAsyncThunk('breedTableItems/getUniqueDadBreeds', () => {
	return AsyncOperationHandler(client => client.breedTableItem_GetUniqueDadBreeds());
});

export const getDanBredBreeds = createAsyncThunk('breedTableItems/getDanBredBreeds', () => {
	return AsyncOperationHandler(client => client.breedTableItem_GetDanBredBreeds());
});

export const getSkioldBreeds = createAsyncThunk('breedTableItems/getSkioldBreeds', () => {
	return AsyncOperationHandler(client => client.breedTableItem_GetSkioldBreeds());
});
