import { useSnackbar } from 'notistack';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Option } from 'react-dropdown';
import { useDispatch, useSelector } from 'react-redux';
import {
	AnimalKind,
	ISowTaskSettings,
	ITreatmentDefinition,
	SowTaskSettings,
	SowTaskState,
	SowTreatmentTask,
	WorkListType,
} from 'shared/api/api';
import { validateSowTaskSetup } from 'shared/helpers/work-list-helpers/sow-task-wl-helper/sow-task-wl-helper';
import { SaveWorkListSetting } from 'shared/state/ducks/work-list-settings/operations';
import { localized, localizedDynamic } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { ShowOkAlert } from '../../skiold-alert/skiold-alert';
import { SkioldButton } from '../../skiold-components/skiold-button/skiold-button';
import { SkioldCheckbox } from '../../skiold-components/skiold-checkbox/skiold-checkbox';
import { SkioldFormDecimalInput } from '../../skiold-components/skiold-decimal-input/skiold-form-decimal-input';
import { DefaultTypeOption } from '../../skiold-components/skiold-dropdown/skiold-dropdown';
import { SkioldFormDropdown } from '../../skiold-components/skiold-dropdown/skiold-form-dropdown';
import { SkioldFormsWrapper } from '../../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from '../../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { SkioldFormInput } from '../../skiold-components/skiold-input/skiold-form-input';
import { SkioldFormIntegerInput } from '../../skiold-components/skiold-integer-input/skiold-form-integer-input';
import TreatmentDefinitionPicker from '../../treatments/treatment-definition/treatment-definition-picker';
import { ViewWeb } from '../../utils/web-view';
import './sow-task-worklist-setup.scss';
interface Props {
	workListType: WorkListType.SowTaskSettings1 | WorkListType.SowTaskSettings2;
}

const getSowTaskStateOptions = () => {
	return Object.keys(SowTaskState).map(k => {
		return {
			label: localizedDynamic(SowTaskState[k as any]),
			value: SowTaskState[k as any],
		};
	});
};

const getSowTaskStateOption = (setting: ISowTaskSettings) => {
	return {
		label: localizedDynamic(setting.sowState!),
		value: setting.sowState!,
	};
};

export const SowTasksWorklistSetup: FC<Props> = React.memo(props => {
	// Setup
	const dispatch = useDispatch();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const sowTasks = useSelector((state: WebAppState) =>
		state.workListSettings.entities.find(set => set.type === props.workListType),
	);
	const treatmentDefinitions = useSelector((state: WebAppState) => state.treatmentDefinitions.entities);
	const siteId = useSelector((state: WebAppState) => state.profile.active && state.profile.active.siteId);

	// State
	const [stateSowTask, setStateSowTask] = useState<ISowTaskSettings>(
		SowTaskSettings.fromJS({
			type: props.workListType,
			siteId: siteId,
			sowTreatmentTasks: [
				new SowTreatmentTask({
					subTaskNumber: 1,
				}),
				new SowTreatmentTask({
					subTaskNumber: 2,
				}),
				new SowTreatmentTask({
					subTaskNumber: 3,
				}),
			],
		}),
	);
	const [sowTaskState, setSowTaskState] = useState<Option>(DefaultTypeOption);

	useEffect(() => {
		if (sowTasks) {
			setStateSowTask(sowTasks as ISowTaskSettings);
			const option = sowTasks ? getSowTaskStateOption(sowTasks as ISowTaskSettings) : DefaultTypeOption;
			setSowTaskState(option);
		}
	}, [sowTasks]);

	const getFormRows = () => {
		let formRows = new Array<FormRow>();

		formRows.push(getHeader());
		formRows.push(getNameRow());
		formRows.push(getSowTaskStateRow());
		formRows.push(IntervalDaysRow());
		formRows.push(SowWeightAtTreatmentRow());
		formRows.push(GiltWeightAtTreatmentRow());
		stateSowTask.sowTreatmentTasks?.forEach(subTask => {
			formRows.push(getSkioldDropdownRow(subTask));
		});

		formRows.push(getAvailablityRow());

		return formRows;
	};

	const getHeader = (): FormRow => {
		return {
			header: localized(stateSowTask.type!),
		};
	};

	const toggleShowOnWeb = useCallback(() => {
		setStateSowTask({ ...stateSowTask, showOnWeb: !stateSowTask.showOnWeb });
	}, [stateSowTask]);

	const toggleShowOnApp = useCallback(() => {
		setStateSowTask({ ...stateSowTask, showOnApp: !stateSowTask.showOnApp });
	}, [stateSowTask]);

	const getAvailablityRow = (): FormRow => {
		return {
			name: localized('listAvailableOn'),
			component: (
				<ViewWeb className="availability-container">
					<SkioldCheckbox onClick={toggleShowOnApp} isChecked={stateSowTask.showOnApp} />
					<TextWeb>{localized('App')}</TextWeb>
					<SkioldCheckbox onClick={toggleShowOnWeb} isChecked={stateSowTask.showOnWeb} />
					<TextWeb>{localized('Web')}</TextWeb>
				</ViewWeb>
			),
		};
	};

	const nameChanged = useCallback(
		(newName: string) => {
			setStateSowTask({ ...stateSowTask, name: newName });
		},
		[stateSowTask],
	);

	const getNameRow = (): FormRow => {
		return {
			name: localized('name'),
			component: <SkioldFormInput onChangeText={nameChanged} text={stateSowTask.name} />,
		};
	};

	const SowStateOptionChanged = useCallback(
		(option: Option) => {
			const sowState = option.value as SowTaskState;
			setSowTaskState(option);
			setStateSowTask({ ...stateSowTask, sowState: sowState });
		},
		[stateSowTask],
	);

	const getSowTaskStateRow = () => {
		return {
			name: localized('CalculateTreatment'),
			component: (
				<SkioldFormDropdown
					onValueChanged={SowStateOptionChanged}
					selectedValue={sowTaskState}
					items={getSowTaskStateOptions()}
				/>
			),
		};
	};

	const SowWeightAtTreatmentChanged = useCallback(
		(weight: number | undefined) => {
			setStateSowTask({ ...stateSowTask, sowWeight: weight ?? 0 });
		},
		[stateSowTask],
	);

	const SowWeightAtTreatmentRow = (): FormRow => {
		return {
			name: localized('SowWeightAtTreatment'),
			component: (
				<SkioldFormDecimalInput onChangeNumber={SowWeightAtTreatmentChanged} text={stateSowTask.sowWeight} />
			),
		};
	};

	const GiltWeightAtTreatmentChanged = useCallback(
		(weight: number | undefined) => {
			setStateSowTask({ ...stateSowTask, giltWeight: weight ?? 0 });
		},
		[stateSowTask],
	);

	const GiltWeightAtTreatmentRow = (): FormRow => {
		return {
			name: localized('GiltWeightAtTreatment'),
			component: (
				<SkioldFormDecimalInput onChangeNumber={GiltWeightAtTreatmentChanged} text={stateSowTask.giltWeight} />
			),
		};
	};

	const startIntervalChanged = useCallback(
		(days: number | undefined) => {
			setStateSowTask({ ...stateSowTask, startIntervalDays: days ?? 0 });
		},
		[stateSowTask],
	);

	const endIntervalChanged = useCallback(
		(days: number | undefined) => {
			setStateSowTask({ ...stateSowTask, endIntervalDays: days ?? 0 });
		},
		[stateSowTask],
	);

	const IntervalDaysRow = (): FormRow => {
		return {
			name: localized('daysAfterEvent'),
			component: (
				<ViewWeb className="from-to-container">
					<ViewWeb className="flex-one-row form-padding">
						<TextWeb>{localized('from')}</TextWeb>
						<SkioldFormIntegerInput
							className="days-input"
							onChangeNumber={startIntervalChanged}
							text={stateSowTask.startIntervalDays}
						/>
					</ViewWeb>
					<ViewWeb className="flex-one-row">
						<TextWeb>{localized('to')}</TextWeb>
						<SkioldFormIntegerInput
							className="days-input"
							onChangeNumber={endIntervalChanged}
							text={stateSowTask.endIntervalDays}
						/>
					</ViewWeb>
				</ViewWeb>
			),
		};
	};

	const getSkioldDropdownRow = (sowTreatmentTask: SowTreatmentTask): FormRow => {
		const onTreatmentChange = (treatmentDefinition: ITreatmentDefinition | undefined) => {
			setStateSowTask({
				...stateSowTask,
				sowTreatmentTasks: stateSowTask.sowTreatmentTasks?.map(e =>
					e.subTaskNumber === sowTreatmentTask.subTaskNumber
						? new SowTreatmentTask({ ...e, treatmentDefinitionId: treatmentDefinition?.id })
						: e,
				),
			});
		};

		const columnTextChange = newText =>
			setStateSowTask({
				...stateSowTask,
				sowTreatmentTasks: stateSowTask.sowTreatmentTasks?.map(e =>
					e.subTaskNumber === sowTreatmentTask.subTaskNumber
						? new SowTreatmentTask({ ...e, columnText: newText })
						: e,
				),
			});

		return {
			name: (
				<ViewWeb className="multiple-row-container">
					<TextWeb className="bold-font-size-sixteen">
						{localized('Treatment')} {sowTreatmentTask.subTaskNumber}
					</TextWeb>
					<TextWeb className="bold-font-size-sixteen">{localized('columnTextShownAs')}</TextWeb>
				</ViewWeb>
			),
			component: (
				<ViewWeb className="treatment-picker-container">
					<TreatmentDefinitionPicker
						onValueChanged={onTreatmentChange}
						treatmentDefinitionId={sowTreatmentTask.treatmentDefinitionId}
						pigType={AnimalKind.Sow}
						allowMultipleSubTreatments={false}
						treatmentDefinitionsFromParent={treatmentDefinitions}
					/>

					<ViewWeb className="column-text-container">
						<SkioldFormInput
							className="column-text-input"
							onChangeText={columnTextChange}
							text={sowTreatmentTask.columnText}
						/>
					</ViewWeb>
				</ViewWeb>
			),
			style: { height: 80 },
		};
	};

	const save = useCallback(() => {
		if (validateSowTaskSetup(stateSowTask, ShowOkAlert)) {
			let settingToInit = SowTaskSettings.fromJS({});
			settingToInit.init(stateSowTask);
			dispatch(SaveWorkListSetting(settingToInit));
		}
	}, [stateSowTask, dispatch]);
	return (
		<ViewWeb className="vaccine-work-list-setup">
			<TextWeb className="text-style">{localized('Setup')}</TextWeb>
			<SkioldFormsWrapper formRows={getFormRows()} containerClassName="wrapper-container" />
			<ViewWeb className="view-button-container">
				<SkioldButton title={localized('Save')} onPress={save} />
			</ViewWeb>
		</ViewWeb>
	);
});
