import React from 'react';
import { connect } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import {
	AnalysisResultYoungFemaleAge,
	IAnalysisResultYoungFemaleAgeSetting,
	IResultYoungFemaleAgeTableData,
	IResultYoungFemaleAgeTableItem,
	ResultYoungFemaleAgeTableItem,
	PeriodDto,
} from 'shared/api/api';
import { RefType } from 'shared/helpers/ref-type';
import { localized } from 'shared/state/i18n/i18n';
import styled from 'styled-components';
import { WebAppState } from 'web/state/store.web';
import SkioldTableGrid, {
	SkioldTableGrid as SkioldTableRef,
} from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { GetResultYoungFemaleAgePdf } from 'web/web-helpers/pdf-helper/analysis-pdf-helpers/analysis-pdf-helper';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { SkioldTouchableOpacity } from '../../skiold-components/skiold-touchable-opacity';
import { AnalysisResultPerLitterItemFromParent } from '../analysis-helper';
import { getAgePeriodTitle } from './analysis-result-young-female-age-helper';

const OuterDiv = styled.div`
	margin: 16px 0;
`;

interface PropsFromParent {
	setting?: IAnalysisResultYoungFemaleAgeSetting;
	openModal: (itemFromParent: AnalysisResultPerLitterItemFromParent) => void;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		items: state.resultYoungFemaleAge.data ? state.resultYoungFemaleAge.data : {},
		loading: state.resultYoungFemaleAge.loading,
		profile: state.profile.active!,
	};
};

export interface State {
	loading: boolean;
	modalOpen: boolean;
	modalSelected?: string;
	batchId?: string;
	columnExte: any[];
	count?: string;
}

type Props = ReturnType<typeof mapStateToProps> & PropsFromParent;

export class AnalysisResultsYoungFemaleAgeTable extends React.PureComponent<Props, State> {
	private SkioldTableRef: SkioldTableRef | undefined;
	private KeysWithModals: string[] = ['TotalMatings', 'TotalFarrowings', 'AllWeanings'];
	private columnsIds = {
		name: 'name',
		agePeriod1: AnalysisResultYoungFemaleAge.AgePeriod1,
		agePeriod2: AnalysisResultYoungFemaleAge.AgePeriod2,
		agePeriod3: AnalysisResultYoungFemaleAge.AgePeriod3,
		agePeriod4: AnalysisResultYoungFemaleAge.AgePeriod4,
		agePeriod5: AnalysisResultYoungFemaleAge.AgePeriod5,
		total: 'total',
	};
	private readonly inlineHeaders: string[] = ['Matings', 'Farrowings', 'Weanings'];

	private GetNameField = (d: ResultYoungFemaleAgeTableItem) =>
		d.name ? (
			<div
				className={
					'textAlignStart' +
					(this.IndentedFields.includes(d.name) ? ' paddingLeftTwenty' : '') +
					(this.inlineHeaders.includes(d.name) ? ' bold' : '')
				}
			>
				{localized('AnalysisResult.' + d.name)}
			</div>
		) : (
			' '
		);

	private readonly filterNameColumn = (key: string) => key !== this.columnsIds.name;

	constructor(props: Props) {
		super(props);
		this.state = {
			loading: false,
			modalOpen: false,
			modalSelected: undefined,
			batchId: undefined,
			columnExte: this.generateColumnsExtensions(),
		};
	}

	public generateColumnsExtensions() {
		let columnsExt = [
			{
				columnName: this.columnsIds.name,
				width: 550,
			},
		];

		Object.keys(this.columnsIds)
			.filter(this.filterNameColumn)
			.forEach(key => {
				columnsExt.push({
					columnName: key,
					width: 100,
				});
			});
		return columnsExt;
	}

	public render() {
		return (
			<ViewWeb>
				<OuterDiv>
					{this.props.loading ? (
						<ViewWeb className="spinner-container-active-analysis">
							<ClipLoader color="#f2ac40" size={70} />
						</ViewWeb>
					) : (
						<ViewWeb className="analysis-overview-table">
							{this.props.items && (
								<SkioldTableGrid
									columns={this.generateColumns()}
									ref={this.setRef}
									tableKey={'analysisResultYoungFemaleAgeTableOverview'}
									resizeAbleEnabled={false}
									InlineHeaders={this.inlineHeaders}
									data={this.GenerateData(this.props.items)}
									ColumnExtensions={this.state.columnExte}
									sortable={false}
									ignoreSetCount={true}
									filterable={false}
									showPagination={true}
								/>
							)}
						</ViewWeb>
					)}
				</OuterDiv>
			</ViewWeb>
		);
	}

	public printData = (periodDto: PeriodDto) => {
		if (this.SkioldTableRef && this.props.setting) {
			let data = this.SkioldTableRef.GetSortedData();
			GetResultYoungFemaleAgePdf(
				data,
				'ResultYoungFemaleAge.pdf',
				this.props.profile.siteId!,
				Intl.DateTimeFormat().resolvedOptions().timeZone,
				this.props.profile.language,
				this.props.setting.agePeriods,
				periodDto,
			);
		}
	};
	private matingsHeader = {
		Matings: new ResultYoungFemaleAgeTableItem({ name: 'Matings' } as IResultYoungFemaleAgeTableItem),
	};
	private farrowingHeader = {
		Farrowings: new ResultYoungFemaleAgeTableItem({ name: 'Farrowings' } as IResultYoungFemaleAgeTableItem),
	};
	private weaningHeader = {
		Weanings: new ResultYoungFemaleAgeTableItem({ name: 'Weanings' } as IResultYoungFemaleAgeTableItem),
	};
	private GenerateData = (data: IResultYoungFemaleAgeTableData) => {
		let tableItems: { [key: string]: IResultYoungFemaleAgeTableItem } = {};
		if (data['matings']) {
			tableItems = { ...tableItems, ...this.matingsHeader, ...data['matings'] };
		}
		if (data['farrowings']) {
			tableItems = { ...tableItems, ...this.farrowingHeader, ...data['farrowings'] };
		}
		if (data['weanings']) {
			tableItems = { ...tableItems, ...this.weaningHeader, ...data['weanings'] };
		}
		return Object.keys(tableItems)
			.filter(key => key !== 'Litter' && key !== 'TotalWeanedPiglets')
			.map(key => {
				return new ResultYoungFemaleAgeTableItem({ ...tableItems[key], name: key });
			});
	};

	private setRef = (m: any) => {
		if (m) {
			this.SkioldTableRef = m;
		}
	};

	private generateColumns() {
		let columns: any[] = [
			{
				name: this.columnsIds.name,
				title: (
					<div className={'flexDirectionRow positionMatingHeader width100 all-capitalized'}>
						<div className="paddingTopFiveAndPaddingLeftEight">
							{localized('ResultYoungFemaleFirstMating')}
						</div>
						<div className="headerWithRows positionMatingRightHeader positionCorrectly">
							<div>{localized('Weeks')}:</div>
						</div>
					</div>
				),
				isFixedLeft: true,
				getCellValue: this.GetNameField,
			},
		];
		Object.keys(this.columnsIds)
			.filter(this.filterNameColumn)
			.forEach(key => {
				const getAgePeriodCellValue = (tableItem: ResultYoungFemaleAgeTableItem) =>
					this.GetAgePeriodCellValue(tableItem, key);
				columns.push({
					name: key,
					title:
						key === this.columnsIds.total
							? localized('Total')
							: getAgePeriodTitle(this.columnsIds[key].toString(), this.props.setting),
					getCellValue: getAgePeriodCellValue,
				});
			});
		return columns;
	}

	private GetAgePeriodCellValue = (tableItem: ResultYoungFemaleAgeTableItem, key: string) => {
		if (tableItem && tableItem.name && this.KeysWithModals.includes(tableItem.name) && tableItem[key]) {
			const itemFromParent = { tableItem, key };
			return (
				<SkioldTouchableOpacity itemFromParent={itemFromParent} onPress={this.props.openModal}>
					{tableItem[key] && <ViewWeb className="folder-arrow arrow-folded" />}
					<TextWeb> {tableItem[key]} </TextWeb>
				</SkioldTouchableOpacity>
			);
		} else if (tableItem && tableItem[key] && tableItem[key] !== '') {
			return tableItem[key];
		} else {
			return ' ';
		}
	};

	private IndentedFields = ['AllFinishedLitters', 'AllNurseWeanings'];
}

export default connect<ReturnType<typeof mapStateToProps>, null, RefType, WebAppState>(mapStateToProps, null, null, {
	forwardRef: true,
})(AnalysisResultsYoungFemaleAgeTable);
