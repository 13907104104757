import ObjectID from 'bson-objectid';
import React from 'react';
import { Option } from 'react-dropdown';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AmountType, AnimalKind, Drug, DrugTypeType, IAdjustment, IDrug, IDrugType } from 'shared/api/api';
import { getDrugTypeTypeOptions } from 'shared/helpers/drug-type-helper/drug-type-helper';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { getTreatmentAnimalKindsOptions } from 'shared/helpers/general-helpers';
import { saveDrug } from 'shared/state/ducks/drug/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import PageContainer from 'web/view/components/page-container/page-container';
import { showAlert } from 'web/view/components/skiold-alert/skiold-alert';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldFormDatePicker } from 'web/view/components/skiold-components/skiold-date-picker/skiold-form-datepicker';
import { SkioldFormDropdown } from 'web/view/components/skiold-components/skiold-dropdown/skiold-form-dropdown';
import { SkioldFormsWrapper } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { SkioldFormIntegerInput } from 'web/view/components/skiold-components/skiold-integer-input/skiold-form-integer-input';
import { Heading } from 'web/view/components/utils/heading';
import { ViewWeb } from 'web/view/components/utils/web-view';
import 'web/view/styles/stock-styles/add-item.scss';
import isEqual from 'react-fast-compare';

interface PropsFromParent {
	animalKinds: AnimalKind[];
	closeModal: () => void;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		siteId: state.profile.active!.siteId,
		profileId: state.profile.active!.id,
		profile: state.profile.active!,
		drugTypes: state.drugType.entities,
		drugs: state.drugs.drugs.filter(d => !d.isDeleted),
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	saveDrug: (drug: IDrug, adjustment: IAdjustment) => saveDrug(drug, adjustment)(dispatch),
});

interface State {
	amount?: number;
	price?: number;
	selectedDrugTypeOption?: Option;
	selectedDate?: Date;
	selectedTypeOption?: Option;
	animalKinds: AnimalKind[];
	selectedAnimalKind: Option;
	drugTypes: IDrugType[];
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

class AddDrug extends React.PureComponent<Props, State> {
	private findSelectedAnimalKind(animalKinds: AnimalKind[]) {
		if (
			animalKinds.find(kind => kind === AnimalKind.Sow || kind === AnimalKind.Boar || kind === AnimalKind.Gilt) !=
			null
		) {
			return {
				animalKinds: [AnimalKind.Boar, AnimalKind.Sow, AnimalKind.Gilt],
				selectedAnimalKind: { label: localized('SowBoarGlit'), value: AnimalKind.Sow },
			};
		} else if (animalKinds.find(kind => kind === AnimalKind.YoungFemale) != null) {
			return {
				animalKinds: [AnimalKind.YoungFemale],
				selectedAnimalKind: { label: localized('youngFemale'), value: AnimalKind.YoungFemale },
			};
		} else if (animalKinds.find(kind => kind === AnimalKind.Piglet) != null) {
			return {
				animalKinds: [AnimalKind.Piglet],
				selectedAnimalKind: { label: localized('piglets'), value: AnimalKind.Piglet },
			};
		} else if (animalKinds.find(kind => kind === AnimalKind.Weaner) != null) {
			return {
				animalKinds: [AnimalKind.Weaner],
				selectedAnimalKind: { label: localized(AnimalKind.Weaner), value: AnimalKind.Weaner },
			};
		} else if (animalKinds.find(kind => kind === AnimalKind.Finisher) != null) {
			return {
				animalKinds: [AnimalKind.Finisher],
				selectedAnimalKind: { label: localized(AnimalKind.Finisher), value: AnimalKind.Finisher },
			};
		}
	}
	constructor(props: Props) {
		super(props);

		this.state = {
			amount: undefined,
			price: undefined,
			selectedAnimalKind: { label: ' ', value: '' },
			animalKinds: [],
			selectedDrugTypeOption: { label: '', value: '' },
			drugTypes: [],
			selectedDate: new Date(),

			selectedTypeOption: { label: localized(DrugTypeType.Medicine), value: DrugTypeType.Medicine },
		};
	}

	public componentDidUpdate(prevProps: Props) {
		if (!isEqual(this.props.drugTypes, prevProps.drugTypes) || !isEqual(this.props.drugs, prevProps.drugs)) {
			this.setState({
				drugTypes: this.getDrugTypesForAnimalType(
					this.state.animalKinds,
					this.props.drugTypes,
					this.props.drugs,
				),
			});
		}
	}

	private getDrugTypesForAnimalType(animalKinds: AnimalKind[], drugsTypes: IDrugType[], drugs: IDrug[]) {
		let drugsFiltered = this.getFilteredData(drugs, animalKinds);
		let drugTypes = [...drugsTypes];
		drugsFiltered.forEach(drug => (drugTypes = drugTypes.filter(drugType => drugType.id !== drug.drugTypeId)));
		return drugTypes;
	}

	private getFilteredData(drugs: IDrug[], animalKinds: AnimalKind[]) {
		let filtered = drugs.filter(a => a.animalKinds!.some(r => animalKinds.indexOf(r) >= 0));

		return filtered;
	}

	public render() {
		return (
			<PageContainer>
				<Heading text={localized('createMedication')} />
				<ViewWeb className="add-item">
					<SkioldFormsWrapper formRows={this.getFormRows()} />
					<ViewWeb className="add-item-button-container">
						<SkioldButton theme="grey" title={localized('Close')} onPress={this.props.closeModal} />
						<SkioldButton title={localized('Save')} onPress={this.save} />
					</ViewWeb>
				</ViewWeb>
			</PageContainer>
		);
	}

	public selectAnimalKindRow(): FormRow {
		return {
			name: localized('selectAnimalKind'),
			component: (
				<SkioldFormDropdown
					items={getTreatmentAnimalKindsOptions(this.props.profile)}
					onValueChanged={animalKind => this.animalKindChanged(animalKind!)}
					selectedValue={this.state.selectedAnimalKind}
				/>
			),
		};
	}

	private animalKindChanged = (animalKind: Option) => {
		const copyOfState = { ...this.state };
		copyOfState.selectedAnimalKind = animalKind;
		if (
			animalKind.value === AnimalKind.Sow ||
			animalKind.value === AnimalKind.Boar ||
			animalKind.value === AnimalKind.Gilt
		) {
			copyOfState.animalKinds = [AnimalKind.Boar, AnimalKind.Sow, AnimalKind.Gilt];
		} else if (animalKind.value === AnimalKind.YoungFemale) {
			copyOfState.animalKinds = [AnimalKind.YoungFemale];
		} else if (animalKind.value === AnimalKind.Piglet) {
			copyOfState.animalKinds = [AnimalKind.Piglet];
		} else if (animalKind.value === AnimalKind.Weaner) {
			copyOfState.animalKinds = [AnimalKind.Weaner];
		} else if (animalKind.value === AnimalKind.Finisher) {
			copyOfState.animalKinds = [AnimalKind.Finisher];
		}
		copyOfState.drugTypes = this.getDrugTypesForAnimalType(
			copyOfState.animalKinds,
			this.props.drugTypes,
			this.props.drugs,
		);
		this.setState(copyOfState);
	};
	private save = async () => {
		if (!this.validate()) {
			return;
		}
		let drug = Drug.fromJS({});
		drug.drugTypeId = this.state.selectedDrugTypeOption!.value!;
		drug.siteId = this.props.siteId;
		drug.startDate = this.state.selectedDate!;
		drug.lastAdjusted = this.state.selectedDate!;
		drug.animalKinds = this.state.animalKinds;

		let adjustment = {
			type: AmountType.Adjustment,
			amount: this.state.amount!,
			date: this.state.selectedDate!,
			createdBy: this.props.profileId,
			modifiedBy: this.props.profileId,
			price: this.state.price,
			registeredById: this.props.profileId,
			id: new ObjectID().toHexString(),
		} as IAdjustment;

		await this.props.saveDrug(drug, adjustment);
		this.setState({ amount: undefined, price: undefined, selectedDrugTypeOption: { label: '', value: '' } });
	};

	private validate() {
		if (this.state.selectedDrugTypeOption && this.state.selectedDrugTypeOption.value === '') {
			showAlert(localized('DrugTypeNotChoosen'));
			return false;
		}
		if (!this.state.selectedDrugTypeOption) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_DRUGSTOCK_NOT_SELECTED));
			return false;
		}
		if (!this.state.amount) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_DRUGSTOCK_AMOUNT_NOT_SET));
			return false;
		}
		if (!this.state.selectedDate) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_DRUGSTOCK_DATE_NOT_SET));
			return false;
		}
		return true;
	}

	private getFormRows() {
		let formRows = new Array<FormRow>();
		formRows.push(this.getDrugTypeTypeRow());
		formRows.push(this.selectAnimalKindRow());
		formRows.push(this.getDrugTypeRow());
		formRows.push(this.getAmountRow());
		formRows.push(this.getPriceRow());
		formRows.push(this.getDatePickerRow());

		return formRows;
	}

	private getAmountRow(): FormRow {
		return {
			name: localized('amount'),
			component: <SkioldFormIntegerInput text={this.state.amount} onChangeNumber={this.amountChanged} />,
		};
	}
	private getPriceRow(): FormRow {
		return {
			name: localized('price'),
			component: <SkioldFormIntegerInput text={this.state.price} onChangeNumber={this.priceChanged} />,
		};
	}

	private priceChanged = (price?: number) => {
		this.setState({
			price,
		});
	};

	private amountChanged = (amount?: number) => {
		this.setState({
			amount,
		});
	};

	private readonly onDateChanged = (newDate: Date) => {
		this.setState({ selectedDate: newDate });
	};

	private getDatePickerRow(): FormRow {
		return {
			name: localized('Date'),
			component: (
				<SkioldFormDatePicker
					theme={'dark'}
					color={'grey'}
					selectedDate={this.state.selectedDate}
					onDateChanged={this.onDateChanged}
				/>
			),
		};
	}

	private readonly onDrugTypeChanged = (drugTypeOption: Option) => {
		this.setState({ selectedDrugTypeOption: drugTypeOption });
	};

	private getDrugTypeRow(): FormRow {
		return {
			name: localized('drug'),
			component: (
				<SkioldFormDropdown
					items={this.getDrugTypeOptions()}
					selectedValue={this.state.selectedDrugTypeOption}
					onValueChanged={this.onDrugTypeChanged}
				/>
			),
		};
	}

	private getDrugTypeTypeRow(): FormRow {
		return {
			name: localized('Type'),
			component: (
				<SkioldFormDropdown
					items={getDrugTypeTypeOptions()}
					selectedValue={this.state.selectedTypeOption}
					onValueChanged={this.drugTypeTypeChanged}
				/>
			),
		};
	}

	private drugTypeTypeChanged = (option: Option) => {
		this.setState({ selectedTypeOption: option });
	};

	private getDrugTypeOptions() {
		if (!this.state.selectedTypeOption) {
			return [];
		}
		const type = this.state.selectedTypeOption.value as DrugTypeType;
		return this.state.drugTypes
			.filter(drugType => drugType.type === type)
			.sort((a, b) => (a.name! > b.name! ? 1 : -1))
			.map<Option>((dt: IDrugType) => {
				return {
					label: dt.name!,
					value: dt.id!,
				};
			});
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AddDrug);
