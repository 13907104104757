import React from 'react';
import { Option } from 'react-dropdown';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AnimalType, IBuilding, IPen, ISection, LocationType, Pen, ProductionForm, Section } from 'shared/api/api';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { deepCopy } from 'shared/helpers/general-helpers';
import { generateAnimalType, generateLocationType } from 'shared/helpers/location-helper';
import { upsertValueInArray } from 'shared/helpers/reducer-helpers';
import { UpsertPen, UpsertSection } from 'shared/state/ducks/locations/operations';
import { localized, localizedDynamic } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import PageContainer from 'web/view/components/page-container/page-container';
import { showAlert } from 'web/view/components/skiold-alert/skiold-alert';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldFormDropdown } from 'web/view/components/skiold-components/skiold-dropdown/skiold-form-dropdown';
import { SkioldFormsWrapper } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import {
	DoubleHeader,
	FormRow,
} from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { SkioldFormInput } from 'web/view/components/skiold-components/skiold-input/skiold-form-input';
import { SkioldFormIntegerInput } from 'web/view/components/skiold-components/skiold-integer-input/skiold-form-integer-input';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { sortByOrder } from '../location-tree-components/location-overview-helper';
import './edit-section.scss';

interface PropsFromParent {
	building: IBuilding;
	section: ISection;
	closeEditModal?: () => void;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		sections: state.locations.sections,
		pens: state.locations.pens,
		siteId: state.profile.active!.siteId,
		moveEvents: state.moveEvents.entities,
		stemAnimals: state.stemAnimals.entities, //for render purposes
	};
};
const DefaultEntranceTypeOption: Option = { label: ' ', value: '' };

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	updateSection: (section: ISection) => UpsertSection(section)(dispatch),
	updatePen: (pen: IPen) => UpsertPen(pen)(dispatch),
});

interface EditSectionState {
	sectionToEdit: ISection;
	animalTypes: Option[];
	selectedAnimalType: Option;
	locationTypes: Option[];
	selectedLocationType: Option;
	pens: IPen[];
	pensToUpdate: IPen[];
	fakePen: IPen;
}

export type EditSectionProps = ReturnType<typeof mapStateToProps> &
	ReturnType<typeof mapDispatchToProps> &
	PropsFromParent;
export class EditSection extends React.PureComponent<EditSectionProps, EditSectionState> {
	constructor(props: EditSectionProps) {
		super(props);
		this.state = {
			sectionToEdit: props.section,
			animalTypes: generateAnimalType(props.building.productionForm),
			selectedAnimalType: props.section
				? { value: props.section.animalType!, label: localized(props.section.animalType!) }
				: DefaultEntranceTypeOption,
			locationTypes: [],
			selectedLocationType: props.section
				? { value: props.section.type!, label: localized(props.section.type!) }
				: DefaultEntranceTypeOption,
			pens: [],
			pensToUpdate: [],
			fakePen: Pen.fromJS({}),
		};
	}

	public componentDidMount() {
		if (this.state.selectedAnimalType.value !== '') {
			this.setAnimalType({
				value: this.state.selectedAnimalType.value!,
				label: localizedDynamic(this.state.selectedAnimalType.value!),
			});
		}

		if (this.props.section) {
			const pens = sortByOrder(this.props.pens.filter(pen => pen.sectionId === this.props.section.id));
			this.setState({ pens: pens as IPen[] });
			if (!this.props.section.usePens) {
				let fakePen = this.props.pens.find(pen => pen.sectionId === this.props.section.id);
				if (!fakePen) {
					fakePen = Pen.fromJS({});
				}
				this.setState({ fakePen });
			}
		}
	}

	public sectionNameChanged(sectionName: string) {
		this.setState(prevState => ({
			sectionToEdit: {
				...prevState.sectionToEdit,
				name: sectionName,
			},
		}));
	}

	public setAnimalType(animalType: Option) {
		const locationTypes = generateLocationType(this.props.building.productionForm, animalType.value);
		this.setState({ selectedAnimalType: animalType, locationTypes });
	}

	public setLocationType(locationType: Option) {
		this.setState({ selectedLocationType: locationType });
	}

	public async penCapacityChanged(penCapacity: number | undefined, penId: string) {
		const pens = deepCopy(this.state.pens);
		const index = pens.findIndex(pen => pen.id === penId);
		pens[index].capacity = penCapacity!;

		this.setState({
			pens,
			pensToUpdate: upsertValueInArray(this.state.pensToUpdate, pens[index]),
		});
	}

	public penNameChanged(penName: string, penId: string) {
		const pens = deepCopy(this.state.pens);
		const index = pens.findIndex(pen => pen.id === penId);
		pens[index].name = penName;

		this.setState({
			pens,
			pensToUpdate: upsertValueInArray(this.state.pensToUpdate, pens[index]),
		});
	}

	public fakePenCapacityChanged(capacity: number | undefined) {
		this.setState(prevState => ({ fakePen: { ...prevState.fakePen!, capacity: capacity! } }));
	}

	public render() {
		return (
			<PageContainer>
				<ViewWeb className="edit-section">
					<ViewWeb className="forms-container">
						<ViewWeb className="z-index-container">
							<SkioldFormsWrapper formRows={this.getFormRows()} containerClassName="wrapper-container" />
						</ViewWeb>

						{this.renderButtons()}
					</ViewWeb>
				</ViewWeb>
			</PageContainer>
		);
	}

	public async save() {
		const editedSection = deepCopy(this.state.sectionToEdit);
		editedSection.animalType = this.state.selectedAnimalType.value as AnimalType;
		editedSection.type = this.state.selectedLocationType.value as LocationType;
		if (!(await this.validateSection(editedSection)) || !(await this.validatePens())) {
			return;
		}
		if (!editedSection.usePens) {
			this.props.updatePen(this.state.fakePen!);
		}
		this.state.pensToUpdate.forEach(pen => {
			this.props.updatePen(pen);
		});
		this.props.updateSection(editedSection);

		this.resetComponent();
	}

	public async validatePens() {
		for (let pen of this.state.pensToUpdate) {
			if (pen.capacity! < 0) {
				showAlert(localized(ExceptionMessage.VALIDATION_ERROR_FIELDS_REQUIRED));
				return false;
			}

			const penFound = this.state.pens.find(penToFind => penToFind.name === pen.name && penToFind.id !== pen.id);
			if (penFound) {
				if (penFound.id !== pen.id) {
					showAlert(localized(ExceptionMessage.VALIDATION_ERROR_DUPLICATE_PEN_NUMBERS));
					return false;
				}
			}
			// We dont want to check if pen capacity is exceeded atm.

			// const animalCount = getAnimalCountByPenIds([pen.id!]);
			// if (pen.capacity !== undefined && animalCount > pen.capacity) {
			// 	if (
			// 		!(await ShowConfirmAlert(
			// 			ExceptionMessage.VALIDATION_WARNING_CAPACITY_EXCEEDED(pen.capacity, animalCount, pen.name!)
			// 		))
			// 	) {
			// 		return;
			// 	}
			// }
		}

		return true;
	}

	public async validateSection(section: ISection) {
		if (!section.name) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_FIELDS_REQUIRED));
			return false;
		}

		if (!section.animalType) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_FIELDS_REQUIRED));
			return false;
		}

		if (!this.state.sectionToEdit.usePens) {
			if (!this.state.fakePen!.capacity) {
				showAlert(localized(ExceptionMessage.VALIDATION_ERROR_FIELDS_REQUIRED));
				return false;
			}

			if (this.state.fakePen!.capacity! < 1) {
				showAlert(localized(ExceptionMessage.VALIDATION_ERROR_CANNOT_BE_NEGATIVE_OR_ZERO));
				return false;
			}
		}

		if (!section.type) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_SECTION_TYPE_NOT_SET));
			return false;
		}

		const sectionFound = this.props.sections.find(
			sec => sec.buildingId === section.buildingId && sec.name === section.name,
		);
		if (sectionFound) {
			if (sectionFound.id !== section.id) {
				showAlert(localized(ExceptionMessage.VALIDATION_ERROR_DUPLICATE_SECTION_NUMBERS));
				return false;
			}
		}
		return true;
	}

	private getFormRows() {
		let formRows = new Array<FormRow>();
		formRows.push(this.getSectionHeader());
		formRows.push(this.getSectionNameRow());
		if (this.props.building.productionForm === ProductionForm.PigProduction) {
			formRows.push(this.getAnimalTypeRow());
		}
		formRows.push(this.getLocationTypeRow());
		if (this.state.sectionToEdit.usePens) {
			formRows.push(this.getPenHeader());
			if (this.state.pens.length > 0) {
				this.state.pens.forEach(pen => {
					formRows.push(this.getPenRows(pen));
				});
			}
		} else {
			formRows.push(this.getCapacityRow());
		}

		return formRows;
	}

	private getSectionHeader(): FormRow {
		return {
			header: localized('SECTION'),
		};
	}

	private getSectionNameRow(): FormRow {
		return {
			name: localized('name'),
			component: (
				<SkioldFormInput
					onChangeText={newName => this.sectionNameChanged(newName)}
					text={this.state.sectionToEdit.name}
				/>
			),
		};
	}

	private getAnimalTypeRow(): FormRow {
		return {
			name: localized('production'),
			component: (
				<SkioldFormDropdown
					items={this.state.animalTypes}
					selectedValue={this.state.selectedAnimalType}
					onValueChanged={(animalTypeOption: Option) => this.setAnimalType(animalTypeOption)}
				/>
			),
		};
	}
	private getLocationTypeRow(): FormRow {
		return {
			name: localized('locationType'),
			component: (
				<SkioldFormDropdown
					items={this.state.locationTypes}
					selectedValue={this.state.selectedLocationType}
					onValueChanged={(locationTypeOption: Option) => this.setLocationType(locationTypeOption)}
				/>
			),
		};
	}

	private getPenHeader() {
		return {
			doubleHeader: new DoubleHeader(localized('pen'), localized('capacity')),
		};
	}

	private getPenRows(pen: IPen): FormRow {
		return {
			name: (
				<div className="left-align">
					<SkioldFormInput
						onChangeText={newName => this.penNameChanged(newName, pen.id!)}
						text={pen.name}
						style={{ textAlign: 'left' }}
					/>
				</div>
			),
			component: (
				<SkioldFormIntegerInput
					onChangeNumber={newNumber => this.penCapacityChanged(newNumber, pen.id!)}
					text={pen.capacity}
				/>
			),
		};
	}

	private getCapacityRow(): FormRow {
		return {
			name: localized('capacity'),
			component: (
				<SkioldFormIntegerInput
					onChangeNumber={newNumber => this.fakePenCapacityChanged(newNumber)}
					text={this.state.fakePen!.capacity}
				/>
			),
		};
	}

	private renderButtons() {
		let buttons = (
			<ViewWeb className="button-container">
				{this.props.closeEditModal && (
					<SkioldButton
						title={localized('Close')}
						onPress={() => this.props.closeEditModal!()}
						theme="grey"
					/>
				)}

				<SkioldButton title={localized('Save')} onPress={() => this.save()} />
			</ViewWeb>
		);

		return buttons;
	}

	// Called after successfull save to remove one-time values
	private async resetComponent() {
		this.setState({
			sectionToEdit: Section.fromJS({}),
			selectedAnimalType: DefaultEntranceTypeOption,
			selectedLocationType: DefaultEntranceTypeOption,
		});
		if (this.props.closeEditModal) {
			this.props.closeEditModal();
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(EditSection);
