import React from 'react';
import { Option } from 'react-dropdown';
import { Gender } from 'shared/api/api';
import { localized } from 'shared/state/i18n/i18n';
import { SkioldFormDropdown } from '../../skiold-components/skiold-dropdown/skiold-form-dropdown';

interface PropsFromParent {
    onGenderChanged: (gender: Gender) => void;
    gender?: Gender;
}

interface State {
    genderOptions: Option[];
}

export class GenderPicker extends React.PureComponent<PropsFromParent, State> {
    private FemaleGenderOption = { value: Gender.Female, label: localized('female') };
    private MaleGenderOption = { value: Gender.Male, label: localized('male') };
    private DefaultGenderOption = this.FemaleGenderOption;
    constructor(props: PropsFromParent) {
        super(props);
        this.state = {
            genderOptions: [this.FemaleGenderOption, this.MaleGenderOption]
        };
    }

    public render() {
        return (
            <SkioldFormDropdown
                items={this.state.genderOptions}
                selectedValue={this.findSelectedGenderOption()}
                onValueChanged={(gender: Option) => this.genderChanged(gender)}
            />
        );
    }

    private findSelectedGenderOption(): Option {
        if (!this.props.gender) {
            return this.DefaultGenderOption;
        }

        if (this.props.gender === Gender.Female) {
            return this.FemaleGenderOption;
        } else {
            return this.MaleGenderOption;
        }
    }

    private async genderChanged(option: Option) {
        this.props.onGenderChanged(option.value as Gender);
    }
}
