
import { ResultYoungFemaleAgeTableData, ElaborationResultYoungFemaleAgeTableData } from 'shared/api/api';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import actionCreatorFactory from 'typescript-fsa';
import * as types from './types';

const actionCreator = actionCreatorFactory(GenActionFactoryName('resultYoungFemaleAge'));

export const getData = actionCreator.async<{ siteId: string; fromDate?: Date,  toDate?: Date }, ResultYoungFemaleAgeTableData>(
    types.SYNC_DATA_RESULT_YOUNG_ANIMAL_AGE_DATA
);

export const getElaborationData = actionCreator.async<{ siteId: string; fromDate?: Date,  toDate?: Date }, ElaborationResultYoungFemaleAgeTableData>(
    types.SYNC_DATA_ELABORATION_RESULT_YOUNG_ANIMAL_AGE_DATA
);
