import ObjectID from 'bson-objectid';
import { IMated, IPregnancyEvent, IPReportPeriod, PregnancyState, SowState } from 'shared/api/api';
import { calculateDays } from 'shared/helpers/general-helpers';
import { getActiveSows } from 'shared/helpers/stemanimal-helper/stem-animal-input-helper';
import { getState } from 'shared/helpers/stemanimal-helper/stemanimal-helper';
import {
	MatingWorkListItem,
	MatingWorkListItemData,
	MatingWorkListProps,
	MatingWorkListState,
} from './mating-work-list-item';
import { Option } from 'react-dropdown';
function InsertOldData(workListDataOldDataCopy: MatingWorkListItemData[], workListData: MatingWorkListItemData[]) {
	workListDataOldDataCopy.forEach(oldData => {
		let oldDataIndex = workListData.findIndex(
			a => a.listItemData.stemAnimalId === oldData.listItemData.stemAnimalId,
		);
		if (oldDataIndex !== -1) {
			workListData[oldDataIndex] = { ...workListData[oldDataIndex], workListData: oldData.workListData };
		}
	});
}

export function genereateListData(props: MatingWorkListProps, prevState: MatingWorkListState | undefined) {
	let workListData: MatingWorkListItemData[] = [];
	let workListDataOldDataCopy: MatingWorkListItemData[] = [];
	if (prevState) {
		workListDataOldDataCopy = [...prevState.workListData];
	}
	let ActiveSows = getActiveSows();
	for (let sow of ActiveSows) {
		if (getState(sow.id!) !== SowState.Dry) {
			continue;
		}
		const filteredPregnancyEvents = props.pregnancyEvents[sow.id!];

		// filter away young females, which to young to be mated
		if (sow.birthDate && (!filteredPregnancyEvents || !filteredPregnancyEvents.length)) {
			const ageInWeeks = calculateDays(sow.birthDate, new Date()) / 7;
			if (ageInWeeks < props.generalSettings.minAgeInDaysAtFirstMating) {
				continue;
			}
		}

		let matingWorkListItem = {} as MatingWorkListItem;
		let lastestEvent = {};
		if (filteredPregnancyEvents !== undefined) {
			const eventDate = new Date(
				Math.max.apply(
					null,
					filteredPregnancyEvents.map(e => {
						return new Date(e.date!).getTime();
					}),
				),
			);
			matingWorkListItem.cycleDays = calculateDays(eventDate, new Date());
			if (
				prevState &&
				prevState.maxDryDays !== undefined &&
				matingWorkListItem.cycleDays > prevState.maxDryDays
			) {
				continue;
			}
		}

		if (filteredPregnancyEvents !== undefined) {
			lastestEvent = filteredPregnancyEvents.find(
				a =>
					new Date(a.date!).toString() ===
					new Date(
						Math.max.apply(
							null,
							filteredPregnancyEvents.map(e => {
								return new Date(e.date!).getTime();
							}),
						),
					).toString(),
			)!;
		}

		matingWorkListItem.animalNumber = sow.animalNumber;
		matingWorkListItem.prevEvent = lastestEvent as IPregnancyEvent;
		matingWorkListItem.animalEvents = props.pregnancyEvents[sow.id!];
		matingWorkListItem.stemAnimalId = sow.id;
		matingWorkListItem.siteId = props.siteId;
		matingWorkListItem.href = sow.id;
		matingWorkListItem.sow = sow;
		workListData.push({ listItemData: matingWorkListItem, workListData: undefined });
	}

	workListData = generateMatingWorklistData(workListData, prevState ? prevState.initialAll : props.defaultInitial);
	InsertOldData(workListDataOldDataCopy, workListData);
	return workListData;
}

function generateMatingWorklistData(workListData: MatingWorkListItemData[], defaultInitial: Option) {
	let worklistDatacopy = [...workListData];
	for (let index = 0; index < worklistDatacopy.length; index++) {
		let elementCopy = { ...worklistDatacopy[index] };

		if (elementCopy.workListData === undefined) {
			elementCopy.workListData = {
				mating: {
					state: PregnancyState.Mated,
					pregnancyId: new ObjectID().toHexString(),
					stemAnimalId: elementCopy.listItemData.stemAnimalId,
					date: new Date(),
					siteId: elementCopy.listItemData.siteId,
					initials: defaultInitial.value,
				} as IMated,
				grade: { value: '', label: ' ' },
				stemAnimalId: elementCopy.listItemData.stemAnimalId,
				isChecked: false,
				boar: undefined,
				initial: defaultInitial,
				selectedSemenType: { label: ' ', value: '' },
			};
			worklistDatacopy[index] = elementCopy;
		}
	}
	return worklistDatacopy;
}
