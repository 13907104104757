import React from 'react';
import { localized } from 'shared/state/i18n/i18n';
import { WhiteText } from '../../../Text/white-text';
import { ViewWeb } from '../../../utils/web-view';
import SkioldTableItemCounter from '../../skiold-table/skiold-table-item-counter';
import './skiold-animal-counter-modal.scss';

export interface PropsFromParent {
	text?: string;
	count?: number;
}
export class SkioldAnimalCounterModal extends React.PureComponent<PropsFromParent> {
	public render() {
		return (
			<ViewWeb className="skioldAnimalCounterModal">
				<WhiteText className="alignTextCenter">
					{(this.props.text ? this.props.text : localized('Animals')) + ':'}
				</WhiteText>
				{this.props.count !== undefined ? (
					<WhiteText className="alignTextCenter paddingLeftFive">{this.props.count}</WhiteText>
				) : (
					<SkioldTableItemCounter className=" paddingLeftTen alignTextCenter" />
				)}
			</ViewWeb>
		);
	}
}
