import { Averted, Farrowing, IPregnancyEvent, PregnancyState, Scanned, ScanResult } from 'shared/api/api';
import { SowState } from 'shared/state/models/sow-state';
import { sortPregnanciesByDate } from './sort-pregnancies';

export function getPreviousEvent(events: IPregnancyEvent[], newEvent: IPregnancyEvent) {
	if (events !== undefined && events.length > 0) {
		let sorted = sortPregnanciesByDate(events).filter(p => p.date < newEvent.date);
		let lastestEvent = sorted[0];

		//Returns the correct previous event for the new event
		if (newEvent.state === PregnancyState.Mated) {
			return lastestEvent;
		}
		if (newEvent.state === PregnancyState.Scanned) {
			const prev = sorted.find(x => x.state === PregnancyState.Scanned || x.state === PregnancyState.Mated);
			return prev ?? ({} as IPregnancyEvent);
		}
		if (newEvent.state === PregnancyState.FarrowingInProgress) {
			const prev = sorted.find(x => x.state === PregnancyState.Scanned || x.state === PregnancyState.Mated);
			return prev ?? ({} as IPregnancyEvent);
		}
		if (newEvent.state === PregnancyState.Farrowing) {
			const prev = sorted.find(
				x =>
					x.state === PregnancyState.Mated ||
					x.state === PregnancyState.Scanned ||
					x.state === PregnancyState.FarrowingInProgress,
			);
			return prev ?? ({} as IPregnancyEvent);
		}
		if (newEvent.state === PregnancyState.Averted) {
			const prev = sorted.find(x => x.state === PregnancyState.Farrowing || x.state === PregnancyState.Averted);
			return prev ?? ({} as IPregnancyEvent);
		}
		return lastestEvent;
	}
	return {} as IPregnancyEvent;
}

export function getSowState(pregnancyEvents: IPregnancyEvent[]): SowState {
	let currentState = SowState.Dry;

	if (pregnancyEvents !== undefined && pregnancyEvents.length > 0) {
		let sorted = sortPregnanciesByDate(pregnancyEvents);
		const lastestEvent = sorted[0];

		//Get lastest event from previous pregnancy
		let lastestPreviousPregEvent = sorted.find(x => x.pregnancyId !== lastestEvent.pregnancyId);

		//If Lastest event == Mated or == Scanned
		if (lastestEvent.state === PregnancyState.Mated || lastestEvent.state === PregnancyState.Scanned) {
			currentState = getSowStateMatedAndScanned(lastestEvent, lastestPreviousPregEvent, sorted);
		}
		//If Lastest event == FarrowingInProgress
		if (lastestEvent.state === PregnancyState.FarrowingInProgress) {
			currentState = getSowStateByFarrowingInProgress(lastestEvent);
		}

		//If Lastest event == Farrowing
		if (lastestEvent.state === PregnancyState.Farrowing) {
			currentState = getSowStateByFarrowing(lastestEvent);
		}

		//If Lastest event == Weaning
		if (lastestEvent.state === PregnancyState.Averted) {
			currentState = getSowStateByWeaning(lastestEvent, lastestPreviousPregEvent);
		}
	}
	return currentState;
}

export function getSowStateByNextPregnancyEvent(
	pregnancyEvents: IPregnancyEvent[],
	nextPregnancyEvent: IPregnancyEvent,
): SowState {
	let currentState = SowState.Dry;

	if (pregnancyEvents !== undefined && pregnancyEvents.length > 0) {
		let sorted = sortPregnanciesByDate(pregnancyEvents);
		const lastestEvent = sorted[0];

		//Get lastest event from previous pregnancy
		let lastestPreviousPregEvent = sorted.find(x => x.pregnancyId !== lastestEvent.pregnancyId);

		//If Lastest event == Mated or == Scanned
		if (lastestEvent.state === PregnancyState.Mated || lastestEvent.state === PregnancyState.Scanned) {
			currentState = getSowStateMatedAndScanned(lastestEvent, lastestPreviousPregEvent, sorted);
		}
		//If Lastest event == FarrowingInProgress
		if (lastestEvent.state === PregnancyState.FarrowingInProgress) {
			currentState = getSowStateByFarrowingInProgress(lastestEvent);
		}

		//If Lastest event == Farrowing
		if (lastestEvent.state === PregnancyState.Farrowing) {
			currentState = getSowStateByFarrowing(lastestEvent);
		}

		//If Lastest event == Weaning
		if (lastestEvent.state === PregnancyState.Averted) {
			currentState = getSowStateByWeaning(lastestEvent, lastestPreviousPregEvent);
		}
	}
	if (GetIfSowIsPregnantAfterNextEvent()) {
		return SowState.Pregnant;
	} else if (GetIfSowIsDryAfterNextEvent()) {
		return SowState.Dry;
	} else if (GetIfSowIsNursingAfterNextEvent()) {
		return getSowStateByFarrowing(nextPregnancyEvent);
	} else if (GetIfSowIsPregnantAndNursingAfterNextEvent()) {
		return SowState.PregnantAndNursing;
	}
	return currentState;

	function GetIfSowIsPregnantAndNursingAfterNextEvent() {
		return (
			(currentState === SowState.Nursing && nextPregnancyEvent.state === PregnancyState.Mated) ||
			(currentState === SowState.PregnantAndNursing &&
				nextPregnancyEvent.state === PregnancyState.Averted &&
				(nextPregnancyEvent as Averted).isNursingSow)
		);
	}

	function GetIfSowIsNursingAfterNextEvent() {
		return currentState === SowState.Pregnant && nextPregnancyEvent.state === PregnancyState.Farrowing;
	}

	function GetIfSowIsDryAfterNextEvent() {
		return (
			(currentState === SowState.Nursing &&
				nextPregnancyEvent.state === PregnancyState.Averted &&
				!(nextPregnancyEvent as Averted).isNursingSow) ||
			(currentState === SowState.Pregnant &&
				nextPregnancyEvent.state === PregnancyState.Scanned &&
				(nextPregnancyEvent as Scanned).result === ScanResult.False)
		);
	}

	function GetIfSowIsPregnantAfterNextEvent() {
		return (
			(currentState === SowState.Dry && nextPregnancyEvent.state === PregnancyState.Mated) ||
			(currentState === SowState.PregnantAndNursing &&
				nextPregnancyEvent.state === PregnancyState.Averted &&
				!(nextPregnancyEvent as Averted).isNursingSow) ||
			(currentState === SowState.Pregnant &&
				nextPregnancyEvent.state === PregnancyState.Scanned &&
				(nextPregnancyEvent as Scanned).result !== ScanResult.False)
		);
	}
}

function getSowStateMatedAndScanned(
	lastestEvent: IPregnancyEvent,
	lastestPreviousPregEvent: IPregnancyEvent | undefined,
	sortedPregEventList: IPregnancyEvent[],
) {
	let scanResult = lastestEvent.state === PregnancyState.Scanned ? Scanned.fromJS(lastestEvent).result : undefined;
	//If ScanResult is False, it means that we are only interested in previous Farrowing or Weaning. Case: M -> F -> A(nursning) -> M -> S(False) -> M -> S(False)
	if (scanResult === ScanResult.False) {
		lastestPreviousPregEvent = sortedPregEventList.find(
			x => x.state === PregnancyState.Farrowing || x.state === PregnancyState.Averted,
		);
	}
	let currentState = SowState.Pregnant;
	if (lastestPreviousPregEvent !== undefined) {
		let isAbortion =
			lastestPreviousPregEvent.state === PregnancyState.Farrowing
				? Farrowing.fromJS(lastestPreviousPregEvent).abortion
				: undefined;
		let isNursing =
			lastestPreviousPregEvent.state === PregnancyState.Averted
				? Averted.fromJS(lastestPreviousPregEvent).isNursingSow
				: undefined;
		if (isAbortion !== undefined && !isAbortion) {
			currentState = SowState.PregnantAndNursing;
			if (scanResult === ScanResult.False) {
				currentState = SowState.Nursing;
			}
		}
		if (isNursing !== undefined && isNursing) {
			currentState = SowState.PregnantAndNursing;
			if (scanResult === ScanResult.False) {
				currentState = SowState.Nursing;
			}
		}
		if (scanResult === ScanResult.False && isNursing === undefined && isAbortion === true) {
			currentState = SowState.Dry;
		}
		if (scanResult === ScanResult.False && isAbortion === undefined && isNursing === false) {
			currentState = SowState.Dry;
		}
	} else {
		if (scanResult === ScanResult.False) {
			currentState = SowState.Dry;
		}
	}
	return currentState;
}

function getSowStateByFarrowingInProgress(lastestEvent: IPregnancyEvent) {
	return SowState.Pregnant;
}

function getSowStateByFarrowing(lastestEvent: IPregnancyEvent) {
	const farrowing = Farrowing.fromJS(lastestEvent);

	if (farrowing.abortion) {
		return SowState.Dry;
	} else {
		return SowState.Nursing;
	}
}

function getSowStateByWeaning(lastestEvent: IPregnancyEvent, lastestPreviousPregEvent: IPregnancyEvent | undefined) {
	let currentState = SowState.Dry;
	let weaning = Averted.fromJS(lastestEvent);
	if (weaning.isNursingSow) {
		currentState = SowState.Nursing;
		if (lastestPreviousPregEvent) {
			if (lastestPreviousPregEvent.state === PregnancyState.Mated) {
				currentState = SowState.PregnantAndNursing;
			}

			if (lastestPreviousPregEvent.state === PregnancyState.Scanned) {
				const scan = Scanned.fromJS(lastestPreviousPregEvent);
				if (scan.result !== ScanResult.False) {
					currentState = SowState.PregnantAndNursing;
				}
			}
		}
	} else if (!weaning.isNursingSow) {
		if (lastestPreviousPregEvent !== undefined && lastestPreviousPregEvent.state !== PregnancyState.Averted) {
			if (lastestPreviousPregEvent.state === PregnancyState.Mated) {
				currentState = SowState.Pregnant;
			}

			if (lastestPreviousPregEvent.state === PregnancyState.Scanned) {
				const scan = Scanned.fromJS(lastestPreviousPregEvent);
				if (scan.result !== ScanResult.False) {
					currentState = SowState.Pregnant;
				}
			}
		}
	}
	return currentState;
}
