import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { WebAppState } from 'web/state/store.web';
import './skiold-table-item-counter.scss';
import { RefType } from 'shared/helpers/ref-type';

export interface PropsFromParent {
	className?: any;
	useChecked?: boolean;
	animalCountParent?: number;
	checkedCountParent?: number;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		animalCount: state.stemAnimals.sowsCount,
		checkedCount: state.stemAnimals.checkedCount,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
	return {};
};

type Props = ReturnType<typeof mapStateToProps> & PropsFromParent;
export class SkioldTableItemCounter extends React.PureComponent<Props> {
	public render() {
		return (
			<div className={`skiold-table-item-counter ${this.props.className}`}>
				{(this.props.animalCountParent ? this.props.animalCountParent : this.props.animalCount) +
					(this.props.useChecked
						? '/' +
						  (this.props.checkedCountParent ? this.props.checkedCountParent : this.props.checkedCount)
						: '')}
			</div>
		);
	}
}

export default connect<ReturnType<typeof mapStateToProps>, ReturnType<typeof mapDispatchToProps>, RefType, WebAppState>(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{ forwardRef: true },
)(SkioldTableItemCounter);
