import { SyncableSingle } from "shared/state/models/syncable";
import { IGeneralSettings } from "shared/api/api";



export const SAVE_DROPDOWN_SCROLL_OPTION = 'SAVE_DROPDOWN_OPTION'; 
export const SYNC_DATA_GENERALSETTINGS = 'SYNC_DATA_GENERALSETTINGS';
export const SAVE_GENERALSETTINGS = 'SAVE_GENERALSETTINGS';
export const RESET_DROPDOWN_SCROLL_OPTIONS = 'RESET_DROPDOWN_SCROLL_OPTIONS'; 
export const SET_GLOBAL_DATE = 'SET_GLOBAL_DATE'; 

export interface GeneralSettingsState extends SyncableSingle<IGeneralSettings>  {
    dropdownScrollOptions: DropdownScrollOption[];
    globalDate: Date;
    showSpinner: SpinnerState


}

export interface SpinnerState{
    [key: string]: boolean;
}

export interface DropdownScrollOption{
    id: string;
    index?: number;
    value?: string;
}
