import { Dispatch } from 'redux';
import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import { StoreContainer } from 'shared/state/store-container';
import { WebAppState } from 'web/state/store.web';
import * as Action from './actions';

export function GetDistriwinFeedRegistrations(siteId: string | undefined, fromDate: Date, toDate: Date) {
	if (!siteId) {
		return (dispatch: Dispatch<any>) => {
			return Promise.resolve();
		};
	}
	return AsyncOperationBuilder2(
		Action.getDistriwinFeedRegistrations,
		client => client.distriwinFeedRegistration_GetBySiteId(siteId, fromDate, toDate),
		{
			siteId,
		},
	);
}
