export default {
	SkioldDigital: 'SKIOLD Digital',
	locale: 'vi',
	reset: 'Đặt lại',
	site: 'Trang trại',
	enterprise: 'Doanh nghiệp',
	building: 'Chuồng nuôi',
	section: 'Phòng nuôi',
	buildingShort: 'Nhà',
	sectionShort: 'Phòng',
	Unit: 'Đơn vị',
	Save: 'Lưu',
	Show: 'Hiện',
	Edit: 'Chỉnh',
	Handled: 'Đã giao',
	Delete: 'Xóa',
	Cancel: 'Thôi',
	Count: 'Đếm',
	Weight: 'Cân nặng',
	AnimalWeight: 'T.Lượng lợn',
	FeedWeight: 'T.Lượng cám',
	Result: 'Kết quả',
	Saved: 'Đã lưu',
	Overdue: 'Quá hạn',
	Print: 'In ra',
	Today: 'Hôm nay',
	Tomorrow: 'Ngày mai',
	SaveChanges: 'Lưu thay đổi',
	ChooseDate: 'Chọn ngày',
	stable: 'Nhà heo',
	pen: 'Ô chuồng',
	ERROR: 'LỖI',
	Confirm: 'Xác nhận',
	Close: 'Đóng',
	avg: 'TB.',
	Gender: 'Giới tính',
	Done: 'Hoàn thành',
	Waiting: 'Đang đợi',
	Running: 'Đang chạy',
	Paused: 'Tạm dừng',
	Cancelled: 'Đã hủy bỏ',
	Deleted: 'Đã xóa',
	kg: 'kg',
	AreYouSureYouWantToCancel: 'Bạn thực sự muốn bỏ qua?',
	NoProcessesText: 'Bạn chưa được cấp quyền truy cập vào chức năng này…',
	PleaseWait: 'Xin vui lòng đợi',
	Saving: 'Đang lưu',
	Retry: 'Thử lại',
	Ok: 'OK',
	TableNoData: 'Bảng không có dữ liệu',
	Previous: 'Lùi',
	Next: 'Tiếp',
	Loading: 'Đang tải',
	January: 'Tháng 01',
	February: 'Tháng 02',
	March: 'Tháng 03',
	April: 'Tháng 04',
	May: 'Tháng 05',
	June: 'Tháng 06',
	July: 'Tháng 07',
	August: 'Tháng 08',
	September: 'Tháng 09',
	October: 'Tháng 10',
	November: 'Tháng 11',
	December: 'Tháng 12',
	Animals: 'Động vật',
	week: 'Tuần',
	from: 'Từ',
	to: 'Đến',
	ml: 'ml',
	outOf: 'quá',
	back: 'Trở lại',
	failed: 'Thất bại',
	bluetooth: 'Bluetooth',
	WillYouContinue: 'Bạn tiếp tục chứ?',
	PlanAndGoal: 'Kế hoạch/Mục tiêu',
	DefaultWeaningWeight: 'T.L Cai sữa TB',
	allocateWeight: 'Tổng cân nặng',
	totalWeanedInPeriod: 'Tổng C.sữa theo lô',
	totalWeightInPeriod: 'Tổng cân nặng theo lô',
	Denmark: 'Đan Mạch',
	Taiwan: 'Đài Loan, Tỉnh thuộc Trung Quốc',
	Australia: 'Nước Úc',
	Sweden: 'Thụy Điển',
	Ecuador: 'Ê-cua-do',
	Norway: 'Na-uy',
	France: 'Pháp',
	'South Africa': 'Nam-mỹ',
	'Sow Module': 'Lợn Nái',
	'Climatepigs Module': 'Cai Sữa',
	'Finisher Module': 'Lợn thịt',
	'Settings Module': 'Cài đặt',
	'Status Module': 'Mô-đun trạng thái',
	'Developer Module': 'Mô-đun lập trình',
	'App Info Module': 'Mô-đun t.tin App',
	'Adjust Feed': 'Chỉnh cám',
	'Delivery Module': 'Mô-đun xuất',
	'Delivery In': 'Xuất vào',
	'Stock Module': 'Mô-đun tồn',
	'Alarm Module': 'Báo động',
	'Warning Module': 'Cảnh báo',
	Events: 'Sự kiện',
	addMedicine: 'Thêm thuốc',
	addMedicineSow: 'Thuốc cho nái',
	addMedicineWeaner: 'Thuốc cai sữa',
	addMedicineFinisher: 'Thuốc lợn thịt',
	registerTreatmentWeaner: 'Lên lịch đ.trị CS',
	treatmentListWeaner: 'Danh sách đ.trị CS',
	registerTreatmentFinisher: 'Lên lịch đ.trị thịt',
	treatmentListFinisher: 'Danh sách đ.trị thịt',
	ClimateEvents: 'Sự kiện',
	weanerPigsEvents: 'Sự kiện',
	finisherPigsEvents: 'Sự kiện',
	pregnancyEventLists: 'D.sách sự kiện',
	weanerPigsEventsList: 'D.sách sự kiện',
	finisherPigsEventLists: 'D.sách sự kiện',
	finishersManagement: 'Q.lý lợn thịt',
	sowManagement: 'Q.lý lợn nái',
	weanersManagement: 'Q.lý cai sữa',
	adminPanel: 'Quản trị viên',
	developer: 'Nhà lập trình',
	order: 'Đặt hàng',
	deliveries: 'Giao hàng',
	status: 'Trạng thái',
	settings: 'Cài đặt',
	treatments: 'Điều trị',
	alarms: 'Báo động gần nhất',
	climate: 'Khí hậu',
	warnings: 'Cảnh báo gần nhất',
	stock: 'Kho',
	plannedOrders: 'Đơn hàng theo lịch',
	reOrders: 'Đặt hàng lại',
	unmanagedOrders: 'Đơn hàng lẻ',
	plannedDeliveries: 'Giao hàng theo lịch',
	unmanagedDeliveries: 'Giao hàng lẻ',
	feedingHistory: 'Lịch sử cho ăn',
	profile: 'Lý lịch',
	online: 'Trực tuyến',
	offline: 'Ngoại tuyến',
	activities: 'Hoạt động',
	tasks: 'Công việc',
	silos: 'Silo',
	sows: 'Nái',
	sowLardWidth: 'Độ dài mỡ',
	lardScanning: 'Scan mợ',
	pigs: 'Lợn',
	'Alarms by Definition': 'Chuông theo Cài đặt',
	'Warnings by Definition': 'Cảnh báo theo Cài đặt',
	'total alarms': 'Tổng báo động',
	'total warnings': 'Tổng cảnh báo',
	'total active time': 'Tổng số lần báo',
	'average active time': 'Thời gian báo T.B',
	'Feeding System': 'Hệ thống cho ăn',
	'Milling System': 'Hệ thống chứa',
	slaughterRetention: 'Duy trì',
	SowManagementSetup: 'Nái',
	WeanerManagementSetup: 'Cai sữa',
	FinisherManagementSetup: 'Lợn thịt',
	userManagementSettings: 'Q.lý người dùng',
	addsow: 'Thêm nái',
	removesow: 'Loại nái',
	movesow: 'Chuyển nái',
	registertreatment: 'Lên lịch Đ.trị',
	startadjustfeed: 'Chỉnh cám',
	startadjustfeedSow: 'Chỉnh cám',
	startadjustfeedFinisher: 'Chỉnh cám',
	startadjustfeedWeaner: 'Chỉnh cám',
	retryAdjustFeedMenuTitle: 'Thay đổi chỉnh cám',
	assigntask: 'Phân công',
	treatmentlists: 'D.sách Đ.trị',
	departurelist: 'Danh sách loại',
	DepartureWorkListSetting: 'Danh sách loại',
	events: 'Sự kiện',
	createTreatment: 'Tạo D.sách Đ.trị',
	treatmentList: 'D.sách Đ.trị',
	AddMedicineApp: 'Thêm thuốc/vaccine',
	TreatmentAppList: 'Tạo lệnh Đtrị/ tái Đ.trị',
	TreatmentWebList: 'Định nghĩa Đ.trị/ vaccine',
	sowCard: 'Thẻ nái',
	foodlists: 'D.sách cám',
	analysisandreports: 'Phân tích & B.cáo',
	deliveryIn: 'Xuất RA',
	deliveryOut: 'Nhập VÀO',
	language: 'Ngôn ngữ',
	english: 'Tiếng Anh',
	swedish: 'Thụy Điển',
	danish: 'Đan Mạch',
	change: 'Thay đổi',
	name: 'Tên',
	en: 'Tiếng Anh',
	da: 'Đan Mạch',
	logout: 'Đăng xuất',
	Login: 'Đăng nhập',
	CHANGEPASSWORD: 'Đổi mật khẩu',
	EDITPROFILE: 'Hiệu chỉnh',
	OVERLAYPIG: 'Cai sữa và Thịt',
	OVERLAYSOW: 'Nái',
	OVERLAYFINISHER: 'Heo thịt',
	OVERLAYWEANER: 'Cai sữa',
	OVERLAYSTOCK: 'Kho',
	OVERLAYSETTINGS: 'Cài đặt',
	OVERLAYADMIN: 'Quản trị viên',
	OVERLAYSTABLE: 'Chuồng nuôi',
	OVERLAYFEEDMILLS: 'Nhà máy cám',
	OVERLAYVALVES: 'Van xả',
	OVERLAYNUMBERS: 'Con số',
	OVERLAYOUTPUT: 'Đầu ra',
	OVERLAYGROWTH: 'Tăng trưởng',
	OVERLAYCLIMATE: 'Khí hậu',
	OVERLAYREPORTS: 'Báo cáo',
	return: 'Trờ về',
	USER_MANAGEMENT: 'Q.lý người dùng',
	NOACCESSROUTE: 'Không truy cập theo hướng này',
	ADMIN_DEPARTURE_REASONS: 'Lý do xuất',
	ADMIN_DIAGNOSES: 'Chẩn đoán',
	ADMIN_DIAGNOSES_CATEGORIES: 'Loại chẩn đóan',
	ADMIN_DRUG_TYPES: 'Loại thuốc',
	ADMINROUTE: 'Đây là hướng của quản trị viên',
	SOWANIMALS: 'động vật',
	SOWEVENTS: 'SỰ KIỆN',
	SowEvents: 'Sự kiện',
	EVENTS: 'Sự kiện',
	SOWTREATMENTS: 'Điều trị',
	SOWMEDICIN: 'Thuốc/ Chẩn đoán',
	SOWDEPARTUREREASONS: 'Kiểu xuất',
	SOWSETTINGS: 'Cài đặt',
	PIGSETTINGS: 'Cài đặt',
	selectDiagnose: 'Chọn chẩn đoán/ Tiêu chủng',
	startroute: 'Bắt đầu lộ trình',
	adjustfeed: 'Chỉnh cám',
	retryAdjustFeed: 'Chỉnh cám thất bại',
	fetchDataFromDistriwin: 'Lấy dữ liệu từ Distriwin',
	FetchDataFromDistriwinConfirmDialog:
		'Bạn sắp vào quá trình lấy dữ liệu gần nhất từ các van ăn trong Distriwin. Quá trình cập nhật này sẽ mất 5-10 phút. Bạn có muốn bắt đầu?',
	Time: 'Thời gian',
	FE_Day_Pig: 'FU/ngày/lợn',
	FE_Day_Sow: 'FU/ngày/nái',
	Avr_Day: 'Avr./ngày',
	'CONST DEV': 'CỐ ĐỊNH',
	DEV: 'ĐỔI',
	DEVIATION: 'THAY ĐỔI',
	DEVIATIONDAYS: 'ĐỔI THEO NGÀY',
	DEVPERCENT: '% ĐỔI',
	DEVIATIONDAYSPERCENT: '% ĐỔI - NGÀY',
	Sows: 'Nái',
	Pigs: 'Lợn',
	DAYS: 'NGÀY',
	WEEK: 'Tuần',
	SECTION: 'PHÒNG',
	FEEDINGCURVE: 'Đường cong',
	UNNAMEDFEEDINGCURVE: 'Không tên',
	NoHistoryAvailable: 'Không có lịch sử thay đổi',
	FeedingCurveAndRecept: 'Đường cong/ Kho',
	fullSyncFailedMessage: 'Thử lại hoặc liên hệ SKIOLD',
	wentThrough: 'Tham quan',
	deleteMarked: 'Đánh dấu sẽ xóa',
	moveMarked: 'Đánh dấu sẽ chuyển',
	SureDeleteMarked: 'Bạn thực sự muốn xóa các mục đã đánh dấu?',
	sendMarked: 'Đánh dấu sẽ gửi',
	ConstantDeviation: 'Độ lệch cố định',
	Deviation: 'Độ lêch',
	DeviationDays: 'Ngày lệch',
	FeedingCurve: 'Đường cho ăn',
	SowCycle: 'Chu kỳ ngày',
	PenDays: 'Ngày trong chuồng',
	PigCount: 'S.lượng lợn',
	pigCount: 'S.lượng lợn',
	PigletCount: 'S.lượng lợn con',
	PrevValue: 'Cũ',
	NewValue: 'Mới',
	Property: 'Kiểu',
	RECENT_WARNINGS_CAPS: 'CẢNH BÁO GẦN NHẤT',
	RECENT_ALARMS_CAPS: 'CHUÔNG BÁO GẦN NHẤT',
	details: 'Chi tiết',
	DistriWin: 'DistriWin',
	FlexMixPro: 'FlexMixPro',
	'Recent Alarms': 'Chuông báo gần nhất',
	'Recent Warnings': 'Cảnh báo gần nhất',
	Date: 'Ngày',
	retentionDate: 'Ngày duy trì',
	Event: 'Sự kiện',
	History: 'Lịch sử',
	addwidget: 'Thêm WIDGET',
	nowidget: 'Bạn không còn widget nào cả!',
	HISTORY: 'LỊCH SỬ',
	'ADJUST FEED': 'CHỈNH CÁM',
	'SELECT SECTION': 'CHỌN PHÒNG',
	selectevent: 'Chọn sự kiện',
	entrance: 'Vào',
	entry: 'Nhập vào',
	departure: 'Xuất',
	select: 'Chọn',
	animalNumber: 'Mã động vật',
	animalNumberShorten: 'Mã lợn',
	'Select Section': 'Chọn phòng',
	animalNumbers: 'Mã số động vật',
	setEntranceDateToMatingDate: 'Đặt ngày nhập vào làm ngày phối',
	PEN: 'Ô CHUỒNG',
	VALVE: 'VAN XẢ',
	SOWS: 'NÁI',
	ANIMALS: 'Động vật',
	FE_DAY: 'FU/NGÀY',
	FIXED: 'CỐ ĐỊNH',
	FIXEDPERCENT: '% CỐ ĐỊNH',
	fixedPercent: '% CỐ ĐỊNH',
	WEIGHT: 'CÂN NẶNG',
	entranceType: 'Kiểu vào',
	entranceTypeBought: 'Được mua',
	entranceEventTypeBought: 'Được mua',
	entranceTypeFromYoungAnimal: 'Từ lợn hậu bị',
	entranceTypeFromPolt: 'Từ polts',
	entranceGender: 'Giới tính',
	selectEntranceType: 'Chọn kiều vào',
	entranceMating: 'Phối',
	ChooseIdNumber: 'Chọn Số ID',
	Litters: 'Lứa số',
	LitterNr: 'Số lứa',
	EntrancePrice: 'Giá mua vào',
	CreateAnimal: 'Thêm lợn',
	PrintSowTable: 'In D.sách nái',
	PrintList: 'In D.sách',
	searchYoungAnimal: 'Tìm lợn hậu bị',
	youngAnimalNotFound: 'Không tìm thấy lợn hậu bị',
	entranceTypeFromYoungFemale: 'Từ lợn hậu bị',
	choosenLocationIsEmpty: 'Vị trí đã chọn không có dữ liệu',
	chooseStableSection: 'Chọn Nhà heo - Phòng heo',
	StableSection: 'Nhà - Phòng',
	FromStableSection: 'Từ Nhà - Phòng',
	MoveToLocation: 'Chuyển đến chỗ',
	FromLocation: 'Từ chỗ',
	ToLocation: 'Đến chỗ',
	FromPen: 'Từ ô chuồng',
	ToPen: 'Đến ô chuồng',
	choosePen: 'Chọn ô chuồng',
	SITEANDFARM: 'KHU/TRẠI',
	BUILDING: 'NHÀ HEO',
	country: 'Quốc gia',
	cvRnum: 'M.số thuế',
	chRnum: 'Mã trại',
	ownerName: 'Tên',
	ownerAddress: 'Địa chỉ',
	ownerZipCode: 'Mã bưu cục',
	ownerCity: 'T.phố',
	siteAddress: 'Địa chỉ',
	siteZipCode: 'Mã bưu cục',
	siteName: 'Tên',
	siteCity: 'T.phố',
	SiteName: 'Khu/Trại',
	address: 'Địa chỉ',
	postalCode: 'Mã bưu cục',
	ZipCode: 'Mã bưu cục',
	Owner: 'Chủ trại',
	city: 'T.phố',
	chrNum: 'Mã trại',
	createBuilding: 'Tạo nhà',
	createSite: 'Tạo trại',
	number: 'Số T.tự',
	units: 'Đơn vị',
	amountOfSections: 'S.lượng phòng',
	startNumber: 'Bắt đầu từ',
	amountOfPens: 'Số ô chuồng',
	capacity: 'Số lượng lợn',
	addSections: 'Thêm phòng',
	addPens: 'Thêm ô chuồng',
	chooseCountry: 'Chọn quốc gia',
	isUsingQRCode: 'Dùng QR code',
	isUsingSections: 'Dùng phòng',
	isUsingPens: 'Dùng ô chuồng',
	letterBehindPen: 'Ký tự sau số ô',
	NameBehindStartNumber: 'Tên (trước số phòng)',
	longmg: 'mỗi kg',
	longml: 'mỗi lứa',
	chooseLocation: 'Chọn vị trí',
	Active: 'Hoạt động',
	Inactive: 'Ngừng',
	Valve: 'Van xả',
	ValveNr: 'Số van xả',
	Pen: 'Ô chuồng',
	OK: 'OK',
	Title: 'Tiêu đề',
	Location: 'Vị trí',
	LocationShort: 'V.trí',
	currentLocation: 'V.trí hiện tại',
	newLocation: 'V.trí mới',
	Status: 'Trạng thái',
	Description: 'Mô tả',
	'How to Fix': 'Cách để sửa chữa',
	'No available curves': 'Không có đường cong phù hợp',
	'Key performance indicators': 'Các chỉ số hiệu suất',
	Value: 'G.trị',
	Num: 'Số',
	Type: 'Kiểu',
	Add: 'Thêm',
	sort: 'Lọc',
	EDITSECTION: 'CHỈNH PHÒNG',
	EDITBUILDING: 'CHỈNH NHÀ',
	locationOverview: 'Tổng quát khu trại',
	siteSetup: 'Thiết lập trại',
	siteChrError: 'Mã trại này đã được dùng',
	peqCodeError: 'Mã vị trí đã được dùng bởi trang trại khác',
	removeDeparture: 'Di chuyển',
	departureTypeSold: 'Bán',
	departureTypeDead: 'Chết',
	departureTypeKilled: 'Làm thịt',
	departureTypeToBeKilled: 'Bị cắn chết',
	departureTypeShouldDeparture: 'Nên loại',
	departureTypePutDown: 'Đã chết',
	departureReason: 'Lý do',
	departureType: 'Kiểu xuất',
	selectDepartureType: 'Kiểu xuất',
	putDown: 'Đã chết',
	departurePrice: 'Giá xuất',
	selectMarking: 'Chọn dấu hiệu',
	selectReason: 'Chọn lý do',
	Days: 'Ngày',
	Quantity: 'Số lượng',
	marking: 'Dấu hiệu',
	animalNr: 'Mã lợn',
	kind: 'Kiểu',
	condition: 'Điều kiện',
	entranceDate: 'Ngày nhập trại',
	Batch: 'Lô phối',
	transponder: 'Bộ phát đáp',
	Teats: 'Số vú',
	price: 'Giá',
	idNumber: 'Mã ID',
	birthdate: 'Ngày sinh',
	Breed: 'Giống',
	dadNr: 'Mã cha',
	dadID: 'ID cha',
	momNr: 'Mã mẹ',
	momID: 'ID mẹ',
	index: 'Chỉ số',
	indexDate: 'Ngày chấm',
	departureDate: 'Ngày loại',
	age: 'Tuổi',
	CreateSow: 'Tạo nái',
	CHOOSELIST: 'D.SÁCH CHỌN',
	cycleDays: 'Số s.suất',
	minCycleDays: 'Số ngày Min.',
	maxCycleDays: 'Số ngày Max.',
	DryDays: 'Ngày n.khô',
	moveToMatingWeaningDryDays: 'Cai sữa, nái khô',
	moveToMatingWeaningDate: 'Cai sữa, ngày',
	activeSowPdf: 'Nái sản xuất.pdf',
	activeSows: 'Nái sản xuất',
	departuredSows: 'Nái loại',
	boars: 'Lợn nọc',
	youngAnimals: 'Lợn choai',
	female: 'Nái',
	male: 'Nọc',
	youngFemale: 'Hậu bị cái',
	gilt: 'H.bị c.phối',
	boar: 'Nọc',
	Unknown: 'Chưa rõ',
	YoungFemale: 'Hậu bị cái',
	Gilt: 'H.bị c.phối',
	Sow: 'Nái',
	Boar: 'Nọc',
	Piglet: 'Lợn con',
	YoungAnimal: 'Lợn choai',
	youngFemales: 'Hậu bị cái',
	sowBoars: 'Nái/Nọc',
	AnimalKinds: 'Loại động vật',
	chooseImportFile: 'Chọn file',
	chosenFile: 'Chọn file',
	import: 'Nhập vào',
	startImport: 'Bắt đầu nhập',
	specifyWeight: 'Nhập t.lượng',
	Marking: {
		Blue: 'Xanh',
		Red: 'Đỏ',
		Green: 'Xanh lá',
		Black: 'Đen',
		White: 'Trắng',
		EarmarkRemoved: 'Đã tháo chíp',
		EarmarkAdded: 'Đã gắn chíp',
	},
	DrugTypes: 'Loại thuốc',
	DrugType: 'Loại thuốc',
	AddDrugType: 'Thêm loại thuốc',
	AddToDrugType: 'Thêm loại thuốc',
	DrugTypeNotChoosen: 'Chọn loại thuốc',
	createReason: 'Lý do tạo',
	reasonName: 'Tên lý do',
	reasonNameDa: 'Tên lý do DK',
	reasonNameEn: 'Tên lý do EN',
	reasonCode: 'Mã ưu tiên',
	editReason: 'Lý do sửa',
	reasons: 'Các lý do',
	reason: 'Lý do',
	stemAnimalDeparture: 'Xuất',
	reasonDead: 'Chết',
	piglets: 'Lợn con',
	polts: 'In',
	DIAGNOSE_CATEGORIES: 'PHÂN LOẠI CHẨN ĐOÁN',
	Diagnose: 'Chẩn đoán',
	categories: 'Thể loại',
	addCategory: 'Thêm loại',
	category: 'Phân loại chẩn đoán',
	categoryNameEn: 'Tên thể loại (en)',
	categoryNameDa: 'Tên thể loại (da)',
	priority: 'Ưu tiên',
	delete: 'Xóa',
	DIAGNOSES: 'CHẨN ĐOÁN',
	addDiagnose: 'Thêm chẩn đoán',
	diagnoseNameDa: 'Tên chẩn đoán (da)',
	diagnoseNameEn: 'Tên chẩn đoán (en)',
	diagnoseDescriptionDa: 'Mô tả chẩn đoán (da)',
	diagnoseDescriptionEn: 'Mô tả chẩn đoán (en)',
	diagnoses: 'Các chẩn đóan',
	DeleteDiagnosePrompt: 'Ban thật sự muốn xóa chẩn đoán? Việc này không thể khôi phục.',
	isVaccination: 'Đã tiêm chủng',
	diagnoseName: 'Chẩn đoán',
	pigTypes: 'Các loại lợn',
	pigType: 'Loại lợn',
	diagnoseType: 'Loại chẩn đóan',
	vaccine: 'Vắc-xin',
	treatment: 'Điều trị',
	create: 'Khởi tạo',
	selectDiagnoseDescription: 'Chọn chẩn đoán/tiêm chủng',
	selectAnimalKind: 'Chọn kiểu động vật',
	Analysis: 'Phân tích',
	matingsFirstMating: 'Phối lần 1 ngày',
	AllFinishedLitters: '• Nái sau cai sữa, pcs.',
	AllNurseWeanings: '• Nái đang nuôi con, pcs.',
	analysisResultsPerLitter: 'Kết quả mỗi lứa',
	analysisResultsYoungFemale: 'Kết quả tuổi hậu bị lúc phối',
	analysisElaborationYoungFemaleAge: 'Chi tiết tuổi hậu bị lúc phối',
	ChooseAnalysis: 'Chọn phân tích',
	AllWeanings: 'Cai sữa, pcs',
	AvgNursingSow: 'Tỷ lệ nái nuôi con',
	All: 'T.bộ',
	Some: 'Một vài',
	None: 'Trống',
	Weeks: 'Tuần',
	AgePeriod: 'Cột tuổi',
	Elaboration: 'Chi tiết',
	SaveSettings: 'Lưu cài đặt',
	MatingDate: 'Ngày phối',
	TotalPeriod: 'Tổng thời gian',
	PercentageRematings: 'T.bình phối lại',
	PercentageDeparture: 'T.bình xuất',
	MatingsInTotal: 'Tổng số phối',
	AnalysisResult: {
		Matings: 'Phối',
		TotalMatings: 'Tổng số phối',
		TotalFirstMatingToLitter: '1.  Phối, pcs.',
		DistributedMatingPercentage: 'Phối phân tán pct.',
		ReturnSows: 'Phối lại, pcs.',
		PercentageReturnSows: 'Pct. Phối lại',
		DepartureNonPregnant: 'Không mang thai, pcs.',
		ThereOfAbortions: 'Sẩy thai, pcs.',
		TotalDepartures: 'Tổng xuất, pcs.',
		DepartureKilledThisLitter: 'Bán/Làm thịt, pcs.',
		DepartureDeadThisLitter: 'Chết/bị chết, pcs.',
		WeaningToFirstMating: 'Cai sữa đến phối L1, ngày',
		MatingToNonPregnantDays: 'Phối đến không mang thai, ngày',
		MatingToRemating: 'Phối đến khi phối lại, ngày',
		MatingToDeparture: 'Phối đến lúc loại, ngày',
		Farrowings: 'Lợn đẻ',
		TotalFarrowings: 'Lợn đẻ, pcs',
		AvgAlive: 'Còn sống, pcs',
		AvgDead: 'Chết lưu, pcs',
		DistributedFarrowingsPercentage: 'Phân tán lợn đẻ, pct',
		StillbornToAliveRate: 'Chết lưu theo pct. Trong tổng sinh',
		FarrowingRate: 'Tỷ lệ đẻ',
		NeedFarrowingFirstLitter: 'Cần đẻ 1 lứa, pcs.',
		AvgPregnantDays: 'Số ngày mang thai',
		Weanings: 'Cai sữa',
		AllWeanings: 'Cai sữa, pcs.',
		AllFinishedLitters: '• Cai sữa theo lứa, pcs.',
		AllNurseWeanings: '• Nái nuôi con, pcs.',
		AvgWeanedPerLitter: 'Cai sữa theo lứa, pcs.',
		AvgWeanedPerWeaninig: 'Cai sữa theo cai sữa, pcs.',
		AvgWeight: 'T.Lượng mỗi con, kg.',
		AvgNursingSow: 'Tỷ lệ ho nuôi con',
		TotalWeanedPiglets: 'Tổng lợn con, pcs.',
		DeadToAliveRate: 'Chết đến khi cai sữa, pct',
		NeedWeaningFirstLitter: 'Cần cai sữa 1 lứa, pcs.',
		AvgNursingDays: 'Số ngày nuôi con',
		PercentageRematings: 'Tỷ lệ phối lại',
		PercentageDepartured: 'Tỷ lệ xuất',
		AvgDays: 'Số ngày trong bình',
		Percentage: 'Pct.',
	},
	ResultYoungFemaleFirstMating: 'Tuồi hậu bị cái',
	WeaningToMating: 'Cai sữa đến khi phối',
	MatingToNonPregnant: 'Phối đến khi khong mang thai',
	MatingToMating: 'Phối đến phối',
	MatingTo: 'Phối đến',
	Departure: 'Loại',
	Abortion: 'Sẩy thai',
	FromTo: 'Từ - đến',
	IntervalDays: 'Khoảng t.gian theo ngày',
	analysisResultsPregnancy: 'Phân tích mang thai',
	AvgDays: 'Số ngày T.bình',
	Percentage: 'Pct.',
	MatingToAbortionAndDeparture: 'Mang thai đến sẩy và loại',
	year: 'năm',
	overTen: 'Trên 10',
	sowsGilt: 'Nái/H.bị',
	goalYear: 'Mục tiêu',
	overGoalYear: 'Trên dưới',
	averageYear: 'T.bình năm',
	DASHBOARD: 'Bảng tổng kết',
	Dashboard:"Bảng tổng kết",
	ageByFold: 'Tuổi theo lứa',
	DaysToMatingFrom: 'Số ngày lúc phối đến',
	DaysFromMatingToDeparture: 'Số ngày lúc phối đến loại',
	SoldOrKilled: 'Bán/Làm thịt',
	Sold: 'Bán',
	soldOrKilled: 'Bán/Làm thịt',
	deadOrPutDown: 'Chết/bị chết',
	DeadOrPutDown: 'Chết/bị chết',
	notPregnant: 'Không mang thai',
	AnimalsToFarrowingTable: 'Lợn phối, Các Lợn đến đẻ',
	TotalMatingsTable: 'Lợn phối, tổng số phối',
	TotalDepartureTable: 'Lợn phối, tổng số loại',
	TotalFarrowingsTable: 'Lợn đẻ, tổng số đẻ',
	NeedFarrowingTable: 'Lợn đẻ, nên đẻ',
	NeedWeaningTable: 'Cai sữa, nên cai sữa',
	TotalWeaningsTable: 'Cai sữa, tổng cai sữa',
	AliveBorn: 'Số còn sống',
	weightPerPigDashboard: 'T.Lượng mỗi lợn',
	weanedPerLitterDashboard: 'Cai sữa mỗi lứa',
	weaningDateDashboard: 'Ngày cai sữa',
	achievedYear: 'Đã đạt',
	VALIDATION_ERROR_INTERVALS_MAY_NOT_OVERLAP: 'Khoảng t.gian trong một phòng, không trùng lặp',
	fromMatingBatch: 'Từ lô phối',
	toMatingBatch: 'Đến lô phối',
	weaningToMating: 'Cai sữa đến phối',
	matingToNotPregnant: 'Phối đến không mang thai',
	matingToMating: 'Phối đến phối',
	matingToAbortion: 'Phối đến sẩy thai',
	abortionToMating: 'Sẩy thai đến phối',
	matingToSoldOrKilled: 'Phối đến khi bán/làm thịt',
	matingTodeadOrPutDown: 'Phối đến khi chết/bị chết',
	matingsFrom: 'Phối từ',
	Dates: 'Ngày tháng',
	rollingYear: 'Suốt 365 ngày',
	PerYear: 'Mỗi năm',
	matingsPcs: 'Phối pcs.',
	percentageYoungAnimalMatings: '% phối hậu bị cái',
	percentageReMatings: '% phối lại',
	daysFromWeaningToFirstMating: 'Số ngày từ khi cai sữa đến phối lần đầu',
	averageWasteDaysPerMatingBatch: 'T.bình không sản xuất mỗi lô',
	farrowingsPcs: 'Lợn đẻ pcs.',
	aliveBornPerFarrowingPcs: 'Lợn con sống mỗi nái đẻ, pcs.',
	aliveBornPerFarrowingFirstLegPcs: 'Lợn con sống mỗi nái đẻ 1. lứa, pcs.',
	deadBornPerFarrowingPcs: 'Lợn con chết mỗi nái đẻ, pcs.',
	weanedPerFarrowingPcs: 'Lợn cai sữa mỗi nái đẻ, pcs.',
	weightPerWeanedPig: 'Cân nặng mỗi cai sữa, kg (1dec.)',
	stillbornToAliveRate: 'Chết lưu theo pct. Trong tổng sinh',
	daysPerMatingBatch: 'Số ngày mỗi lô phối',
	deviationPercent: 'Độ lệch theo %',
	dashboardDefinition: 'Năm quy định trong Bảng tổng kết',
	startAtMatingBatch: 'Bắt đầu tại lô phối',
	endAtMatingBatch: 'Kết thúc tại lô phối',
	selectTreatmentDefinition: 'Chẩn đoán',
	noDrugTypesForAnimalTypeVaccine:
		'Bạn cần thêm một loại thuốc trong kiểu Vắc-xin vào kho cho loại lợn này, để tạo một tiêm chủng mới',
	noDrugTypesForAnimalTypeMedicine:
		'Bạn cần thêm một loại thuốc trong kiểu Vắc-xin vào kho cho loại lợn này, để tạo một chẩn đoán mới',
	weightLabel: 'T.Lượng',
	registerTreatment: 'Đăng ký điều trị',
	registerToBeTreated: 'Được điều trị',
	VALIDATION_ERROR_NoDiagnoseSet: 'Yêu cầu chẩn đoán',
	VALIDATION_ERROR_CHECKALL_OR_SELECTPEN: 'Chọn ô chuồng hoăc cả phòng',
	VALIDATION_ERROR_NoMarkingSet: 'Yêu cầu đánh dấu',
	VALIDATION_ERROR_NoWeightSet: 'Yêu cầu cân',
	AmountOfPiglets: 'Số lợn con',
	AmountOfPigletsShort: 'Lợn con',
	DrugAmount: 'Lượng thuốc',
	DrugUseForAnimals: 'Thuốc dùng cho động vật',
	DrugAmountShort: 'Lượng',
	DrugAmountPerPig: 'Lượng thuốc mỗi lợn',
	Dosage: 'Liều dùng',
	EditTreatment: 'Điều chỉnh liệu trình',
	SelectTreatedMoved: 'Bao nhiêu lợn điều trị sẽ được chuyển?',
	SYNC_ERROR: 'Có lỗi xảy ra, có thể dữ liệu chưa đồng bộ. Vui lòng truy cập lại trang này',
	VALIDATION_ERROR_MISSING_AGE_PERIOD: ': Bạn nên xác định tuần tuổi cho hậu bị cái',
	VALIDATION_ERROR_AGE_TO_IS_LESS_THAN_FROM_DATE: ': Tuổi đến không được nhỏ hơn tuổi vào',
	VALIDATION_ERROR_DRUGSTOCK_REASON_REQUIRED: 'Bạn cần nêu rõ lý do vì sao trống',
	VALIDATION_ERROR_DRUGSTOCK_USE_ADD_INSTEAD: 'Dùng add',
	VALIDATION_ERROR_DRUGSTOCK_ERROR_EDITING_WASTE_HAS_TO_BE_NEGATIVE: 'Ngày trống âm',
	VALIDATION_ERROR_WEIGHT_REQUIRED_FOR_DEPARTED: 'Bạn đang chuyển lợn mà chưa xác định cân nặng của chúng',
	VALIDATION_ERROR_ANIMAL_IS_NOT_PREGNANT_ANYMORE_AND_ANIMAL_IS_ON_PREGNANT_LOCATION:
		'Bạn phải chuyển lợn đến ô chuồng mà ở đó không nuôi lợn mang thai',
	VALIDATION_ERROR_ANIMAL_NEEDS_TO_BE_PREGNANT_TO_MOVE_TO_THIS_LOCATION:
		'Lợn cần phải mang thai trước khi chuyển vào ô dưỡng thai',
	VALIDATION_ERROR_ESF_IS_ENABLED_AND_TRANSPONDER_IS_REQUIRED: 'Chưa chọn bộ phát đáp',
	VALIDATION_ERROR_DRUGSTOCK_ERROR_EDITING_ADJUSTMENT_HAS_TO_BE_POSITIVE: 'Điều chỉnh tích cực',
	VALIDATION_ERROR_DRUGSTOCK_NOT_SELECTED: 'Chưa chọn loại thuốc',
	VALIDATION_ERROR_FEEDINGSTOCK_NOT_SELECTED: 'Yêu cầu chủ đề ăn',
	VALIDATION_ERROR_FEEDINGSTOCK_AMOUNT: 'Chưa nhập lượng',
	VALIDATION_ERROR_FEEDINGSTOCK_PRICE: 'Chưa chọn giá',
	VALIDATION_ERROR_NoFeedingSubjectChoosen: 'Bạn cần chọn chủ đề ăn',
	VALIDATION_ERROR_NoFeedingAnimalTypeChoosen: 'Bạn cần chọn loại động vật',
	VALIDATION_ERROR_FEEDINGSTOCK_FESOW: 'FU nái cần được chọn - 0 được chấp nhận',
	VALIDATION_ERROR_FEEDINGSTOCK_FEPIG: 'FU lợn cần được chọn - 0 được chấp nhận',
	VALIDATION_ERROR_AMOUNT_NOT_SET: 'Chưa đặt lượng',
	VALIDATION_ERROR_FEEDINGSTOCK_FOSFOR: 'Chưa chọn Phốt pho',
	VALIDATION_ERROR_TOTAL_AMOUNT_TO_LOW_TO_CONTAIN_ALREADY_USED_FOOD: 'Lượng của bạn cần tối thiểu:',
	VALIDATION_ERROR_TOTAL_AMOUNT_AVAILABLE_WILL_BE_LOWER_THAN_TOTALUSED:
		'Tổng lượng trong chủ đề ăn, sẽ thấp hơn tổng lượng được dùng',
	VALIDATION_ERROR_DRUGSTOCK_AMOUNT: 'Chưa chọn lượng',
	VALIDATION_ERROR_DRUGSTOCK_DATE: 'Chưa chọn ngày',
	VALIDATION_ERROR_TRANSPONDER_ALREADY_IN_USE_BY_SOW: 'Thẻ chíp đang được dùng cho nái khác',
	VALIDATION_ERROR_ANIMAL_NUMBER_ALREADY_IN_USE_BY_SOW: 'Mã lợn đã được dùng',
	VALIDATION_ERROR_AnimalNumberNotSet: 'Mã lợn chưa được nhập',
	VALIDATION_ERROR_AnimalNumberNotFound: 'Mã lợn không tồn tại',
	VALIDATION_ERROR_AnimalNumberIsDepartured: 'Mã lợn đã xuất',
	VALIDATION_ERROR_AnimalNumberExists: 'Mã lợn đã tồn tại',
	VALIDATION_ERROR_EntranceDateNotSet: 'Ngày vào trại chưa nhập',
	VALIDATION_ERROR_PenIdNotSet: 'On chuồng chưa nhập',
	VALIDATION_ERROR_STABLE_SECTION_NOT_SET: 'Chuồng - phòng chưa nhập',
	VALIDATION_ERROR_NEW_PEN_ID_NOT_SET: 'Ô chuồng chưa nhập',
	VALIDATION_ERROR_MOVE_STABLE_SECTION_NOT_SET: 'Chuyền chuồng - phòng chưa nhập',
	VALIDATION_ERROR_MOVE_NEW_PEN_ID_NOT_SET: 'Chuyển chuồng chưa tạo',
	VALIDATION_ERROR_SiteNotSet: 'Trại chưa nhập',
	VALIDATION_ERROR_MatingEqualsOneLitter: 'Lứa phải là 1 khi bắt đầu phối',
	VALIDATION_ERROR_EntranceTypeNotSet: 'Kiểu vào trại chưa nhập',
	VALIDATION_ERROR_AnimalNotFound: 'Không tìm thấy động vật',
	VALIDATION_ERROR_DepartureTypeNotSet: 'Chưa nhập kiểu xuất',
	VALIDATION_ERROR_DepartureDateNotSet: 'Chưa nhập ngày xuất',
	VALIDATION_ERROR_DATE_NOT_SET: 'Nhưa nhập ngày',
	VALIDATION_ERROR_LARD_WIDTH_NOT_SET: 'Chưa nhập độ rộng mỡ',
	VALIDATION_ERROR_DepartureBoarIsKs: 'Nọc này là Nọc KS',
	VALIDATION_ERROR_DepartureDateMostAfterCurrentDate: 'Ngày xuất nên là sau ngày hiện tại',
	VALIDATION_ERROR_DASHBOARD_ENDMATINGBATCH_NEED_TO_BE_LATER: 'Bạn nên chọn ngày kết thúc phối sau ngày hiện tại',
	VALIDATION_ERROR_DASHBOARD_NEED_LATER_ENDMATINGBATCH:
		'Yêu cầu năm mới cho bảng thổng kết - cài đặt, Quản lý nái, Kế hoạch/mục tiêu cho sản xuất',
	VALIDATION_ERROR_NameNotSet: 'Chưa đặt tên',
	VALIDATION_ERROR_UnitNotSet: 'Chưa đặt đơn vị',
	VALIDATION_ERROR_TransponderOccupied: 'Thẻ chíp đã dùng',
	VALIDATION_ERROR_BothNamesRequired: 'Yêu cầu các tên',
	VALIDATION_ERROR_CategoryRequired: 'Yêu cầu thể loại',
	VALIDATION_ERROR_CategoryInUse: 'Không thể xóa thể loại khi đang dùng cho chẩn đoán',
	VALIDATION_ERROR_FieldsRequired: 'Yêu cầu tất cả mục',
	VALIDATION_ERROR_NoDrugId: 'Chọn thuốc',
	VALIDATION_ERROR_AnotherVaccineAlreadyExist: 'Đã tồn tại loại Vắc-xin này',
	VALIDATION_ERROR_CannotBeNegative: 'Giá trị không được âm',
	VALIDATION_ERROR_CannotBeNegativeOrZero: 'Giá trị không được âm hoặc bằng 0',
	VALIDATION_ERROR_COUNTRYID_REQUIRED: 'Yêu cầu quốc gia đặt trại',
	VALIDATION_ERROR_CHRNUM_REQUIRED: 'Yêu cầu mã trại',
	VALIDATION_ERROR_SITE_NAME_REQUIRED: 'Yêu cầu tên trại',
	VALIDATION_ERROR_SITE_ADDRESS_REQUIRED: 'Yêu cầu địa chỉ trại',
	VALIDATION_ERROR_SITE_ZIPCODE_REQUIRED: 'Yêu cầu mã bưu chính',
	VALIDATION_ERROR_SITE_CITY_REQUIRED: 'Yêu cầu thành phố',
	VALIDATION_ERROR_OWNER_NAME_REQUIRED: 'Yêu cầu tên chủ trại',
	VALIDATION_ERROR_OWNER_ADDRESS_REQUIRED: 'Yêu cầu địa chỉ chủ',
	VALIDATION_ERROR_OWNER_ZIPCODE_REQUIRED: 'Yêu cầu mã bưu chính',
	VALIDATION_ERROR_OWNER_CITY_REQUIRED: 'Yêu cầu thành phố',
	VALIDATION_ERROR_CVRNUM_REQUIRED: 'Yêu cầu mã doanh nghiệp',
	VALIDATION_ERROR_CategoryNotSet: 'Chưa chọn phân loại',
	VALIDATION_ERROR_NoDiagnoseID: 'Chưa chẩn đoán',
	VALIDATION_ERROR_NoAnimalKinds: 'Chưa chọn kiểu động vật',
	VALIDATION_ERROR_NoDrugTypeIsChoosed: 'Chưa chọn thuốc',
	VALIDATION_ERROR_NoFrequency: 'Chưa chọn tầng suất',
	VALIDATION_ERROR_NoNumberOfTreatments: 'Chưa chọn số lần điều trị',
	VALIDATION_ERROR_RefreshError: 'Lỗi, tải lại trang',
	VALIDATION_ERROR_NoRetentionTime: 'Chưa nhập ngày lưu lại',
	VALIDATION_ERROR_NoTreatmentType: 'Chưa nhập kiểu điều trị',
	VALIDATION_ERROR_NoDrugAmountValue: 'Chưa chọn lượng thuốc',
	VALIDATION_ERROR_NoPerUnit: 'Chưa chọn theo đơn vị',
	VALIDATION_ERROR_NoUnitType: 'Chưa chọn kiểu đơn vị',
	VALIDATION_ERROR_BatchNotSet: 'Chưa chọn lô',
	VALIDATION_ERROR_FinishedDateNotSet: 'Chưa chọn ngày',
	VALIDATION_ERROR_MatingDateAndYoungFemaleError: 'Ngày vào lợn, là sau ngày phối',
	VALIDATION_ERROR_InvalidAgeInterval: 'Tuổi hậu bị nái ngoài vùng',
	VALIDATION_ERROR_InvalidPregnancyEvent: 'Chưa đúng sự kiện mang thai',
	VALIDATION_ERROR_HasNoPreviousEvent: 'Không có sự kiện mang thai nào',
	VALIDATION_ERROR_SowAlreadyPregnant: 'Nái đang mang thai',
	VALIDATION_ERROR_DateIsBeforePreviousEvent: 'Ngày nhập bị sớm hơn sự kiện trước đó',
	VALIDATION_ERROR_InvalidMatingToFarrowingInterval: 'Khoảng thời gian từ phối đến đẻ chưa đúng',
	VALIDATION_ERROR_RegistrationErrorPregnancyEvent: 'Một lỗi xảy ra trong quá trình khởi tạo sự kiện mang thai',
	VALIDATION_WARNING_CHANGING_SECTION_OR_BUILDING_WILL_RESULT_IN_RESET_FOR_CHANGED_BUILDINGS_SECTIONS:
		'Bạn đã thay đổi mối quan hệ giữa các vị trí kỹ thuật số của Distriwin và SKIOLD Digital, điều này dẫn đến các van không còn được kết nối với một vị trí nữa. Từ mục tổng quan về vị trí, bạn phải kết nối lại các van với một vị trí. Lưu các thay đổi?',
	VALIDATION_WARNING_SECTION_NOT_BELONGING_TO_SECTION_SELECTED_HAS_NON_SETTED_PENS:
		'Không lưu được dữ liệu, do có những đoạn không thuộc đoạn đã chọn mà không có van kết nối, bạn sửa lỗi này nhé?',
	VALIDATION_WARNING_YOU_ARE_CREATING_A_USAGE_THAT_IS_IN_ANOTHER_USAGE_PERIOD:
		'Bạn đang tạo một khoảng thời gian, tức là trong một khoảng thời gian khác:',
	VALIDATION_ERROR_NoAbortionIfAlivePigs: 'Không thể bỏ, nếu lợn còn sống',
	VALIDATION_ERROR_InvalidFarrowingToWeaningInterval: 'Khoảng thời gian từ đẻ đến cai sữa không hợp lệ',
	VALIDATION_ERROR_SET_WHAT_TO_TREAT:
		'Bạn phải chọn xem tất cả những con lợn phải được xử lý hay chỉ những con lợn đực',
	VALIDATION_ERROR_InvalidWeaningToMatingInterval: 'Khoảng thời gian từ cai sữa đến phối không hợp lệ',
	VALIDATION_ERROR_GiltMatingDateError: 'Khi loại động vật là hậu bị, ngày giao phối cần sớm hơn ngày nhập',
	VALIDATION_ERROR_PregnantDaysNotSet: 'Ngày mang thai không được thiết lập',
	VALIDATION_ERROR_NursingDaysNotSet: 'Ngày nuôi con không được đặt',
	VALIDATION_ERROR_SHOW_ANIMAL_ON_LIST: 'Không có giá trị nào cho động vật hiển thị trong danh sách',
	VALIDATION_ERROR_StartDateIsNotSet: 'Ngày bắt đầu chưa được đặt',
	VALIDATION_ERROR_WeekCycleIsNotSet: 'Chu kỳ tuần không được đặt',
	VALIDATION_ERROR_CycledaysNotSet: 'Chu kỳ ngày không được đặt',
	VALIDATION_ERROR_MatingPeriodIsNotSet: 'Thời gian giao phối không được thiết lập',
	VALIDATION_ERROR_PregnantAndNursingDaysExceeded:
		'Số ngày mang thai + ngày nuôi con vượt quá số tuần chu kỳ của nái',
	VALIDATION_ERROR_NoMatingBatchesGenerated: 'Không có lô giao phối được tạo ra',
	VALIDATION_ERROR_EndDateNotSet: 'Không có ngày kết thúc được tạo',
	VALIDATION_ERROR_OverlapsOtherSettingsDates:
		'Ngày bắt đầu hoặc ngày kết thúc cho cài đặt, trùng lặp với cài đặt khác',
	VALIDATION_ERROR_DateDoesNotMatchAMatingBatch:
		'Ngày được chọn không phù hợp với một lô giao phối. Vui lòng thiết lập các lô giao phối  ',
	VALIDATION_ERROR_RegistrationErrorOnMatingBatch: 'Đã xảy ra lỗi khi đăng ký lô giao phối',
	VALDIATION_ERROR_UnknownTreatmentDefinitionSet: 'Định nghĩa điều trị không được biết bởi máy chủ',
	VALIDATION_ERROR_TreatmentPlannedDateNotSet: 'Điều trị bị thiếu ngày theo kế hoạch',
	VALIDATION_ERROR_TreatmentSiteIdNotSet: 'Điều trị bị thiếu id trang trại',
	VALIDATION_ERROR_ScanCantBeSameDateAsMated: 'Không thể scan lợn nái, cùng ngày với giao phối',
	VALIDATION_ERROR_StartDateIsEarlierThanCurrentDate: 'Ngày bắt đầu cần muộn hơn ngày hiện tại',
	VALIDATION_ERROR_NewStartDateMustBeEqualToExisting:
		'Ngày bắt đầu phải muộn hơn ngày hôm nay và bằng ngày bắt đầu giao phối được tính toán',
	VALIDATION_ERROR_NoneAlive: 'Không có động vật sống nào có số đó',
	VALIDATION_ERROR_TransponderInUse: 'Transponder đã được sử dụng',
	VALIDATION_ERROR_SowDoesNotExist: 'Lợn nái không tồn tại',
	VALIDATION_ERROR_AnimalNotSet: 'Động vật không được thiết lập',
	VALIDATION_ERROR_BuildingNumberNotSet: 'Số tòa nhà không được đặt',
	VALIDATION_ERROR_BuildingNameNotSet: 'Tên tòa nhà chưa được đặt',
	VALIDATION_ERROR_ProductionFormNotSet: 'Hình thức sản xuất không được thiết lập',
	VALIDATION_ERROR_PRODUCTION_TYPE_NOT_SET: 'Loại hình sản xuất không được thiết lập',
	VALIDATION_ERROR_COUNT_NOT_SET: 'Đếm không được đặt',
	VALIDATION_ERROR_AVG_WEIGHT_NOT_SET: 'T.Lượng T.bình không được đặt',
	VALIDATION_ERROR_BuildingNumberAlreadyExists: 'Số tòa nhà đã tồn tại',
	VALIDATION_ERROR_SectionTypeNotSet: 'Kiểu phòng không được đặt',
	VALIDATION_ERROR_DuplicateSectionNumbers: 'Không thể tạo các phần do trùng lặp số phòng',
	VALIDATION_ERROR_FEEDINGSTOCK_NO_FEEDING_SUBJECT_SELECTED: 'Bạn cần chọn đối tượng cho ăn',
	VALIDATION_ERROR_DuplicatePenNumbers: 'Không thể tạo ô chuồng, vì trùng lặp số ô',
	VALIDATION_ERROR_RegistrationError: 'Đã xảy ra lỗi khi đăng ký',
	VALIDATION_ERROR_HasNoSection: 'Không có phòng',
	VALIDATION_ERROR_HasNoBuilding: 'Không có tòa nhà',
	VALIDATION_ERROR_SetupValidationSetup: 'Vui lòng thiết lập khoảng thời gian xác thực trong cài đặt lợn nái',
	VALIDATION_ERROR_InvalidPregnantPeriod: 'Thời kỳ mang thai không hợp lệ. Khoảng thời gian cần từ 100 - 125 ngày',
	VALIDATION_ERROR_invalidPlanPeriod:
		'Thời gian không hợp lệ. Khoảng thời gian cần phải nằm giữa kế hoạch - và kế hoạch +',
	VALIDATION_ERROR_InvalidNursingPeriod: 'Thời gian nuôi con không hợp lệ. Không được ít hơn 21 ngày',
	VALIDATION_ERROR_LocationNotSet: 'Vị trí chưa được đặt',
	VALIDATION_ERROR_InvalidFarrowingAlive: 'Lợn đực giống còn sống, không phù hợp với tổng số lợn sống',
	VALIDATION_ERROR_IdNumberNotSet: 'Số ID chưa được đặt.',
	VALIDATION_ERROR_WeaningNumAliveIsNotSet: 'Lợn cai sữa không được thiết lập',
	VALIDATION_ERROR_MoveDateNotSet: 'Ngày di chuyển không được đặt',
	VALIDATION_ERROR_NewPenIdNotSet: 'Ô chuồng chưa được đặt',
	VALIDATION_ERROR_NewPenSameAsCurrent: 'Ô chuồng được chọn là cây bút hiện tại',
	VALIDATION_ERROR_FarrowingNumAliveNotSet: 'Số lượng sống không được thiết lập',
	VALIDATION_ERROR_BuildingIsNotEmpty: 'Không thể xóa, vì tòa nhà có lợn',
	VALIDATION_ERROR_SectionIsNotEmpty: 'Không thể xóa, vì phòng có lợn',
	VALIDATION_ERROR_MatingBatchOutOfRange: 'Các lô giao phối không đạt được xa như vậy trong quá khứ',
	VALIDATION_ERROR_NotAllowedToDeleteDiagnose: 'Chẩn đoán vẫn còn sử dụng trong một trang trại',
	VALIDATION_ERROR_DiagnoseAnimalKindStillInUse: 'Chẩn đoán loại động vật vẫn còn được sử dụng trong trang trại',
	VALIDATION_ERROR_ColumnTextIsNotSet: 'Nếu chẩn đoán được chọn, hãy viết chữ vào cột',
	VALIDATION_ERROR_BoarProcentageIsNotSet: 'Nếu lợn nọc được chọn, hãy viết % nọc',
	VALIDATION_ERROR_NursingFromIsEmptyOrNegative: '"Nuôi con từ" bắt buộc, và không thể là số âm',
	VALIDATION_ERROR_NursingToIsEmptyOrNegative: '"Nuôi con đến" bắt buộc, và không thể nhỏ hơn "Nuôi con từ"',
	VALIDATION_ERROR_PregnantFromIsEmptyOrNegative: '"Mang thai từ" là bắt buộc, và không thể số âm',
	VALIDATION_ERROR_PregnantToIsEmptyOrNegative: '"Mang thai đế" là bắt buộc, và không thể nhỏ hơn "Mang thai từ"',
	VALIDATION_ERROR_NursingDaysAreRequiered: 'Ngày nuôi con là yêu cầu bắt buộc',
	VALIDATION_ERROR_AvailableOnNeedsToBeSet: 'Danh sách có sẵn trên cần được thiết lập',
	VALIDATION_ERROR_ChoosePregnancyEvent: 'Chọn sự kiện mang thai',
	VALIDATION_ERROR_IncludeLittersTo: 'Lên đến số lứa cần phải được thiết lập',
	VALIDATION_ERROR_AfterEventDaysGreaterThanZero: 'Ngày sau sự kiện cần phải lớn hơn Không',
	VALIDATION_ERROR_ChooseAtleastOneTreatmentDef: 'Chọn ít nhất một định nghĩa điều trị',
	VALIDATION_ERROR_ChooseAnimalNotSet: 'Chọn động vật không được thiết lập',
	VALIDATION_ERROR_FEEDINGSTOCK_NO_FEEDING_SUBJECT_NAME: 'Nhập tên cám',
	VALIDATION_ERROR_AMOUNT_EXCEED_STOCK: 'Số lượng vượt quá lượng thức ăn hiện tại',
	VALIDATION_ERROR_NO_CODE_SET: 'Mã chưa được đặt',
	VALIDATION_ERROR_INVALID_CODE_SET: 'Chọn mã khác',
	VALIDATION_ERROR_DEFAULT_CODE_SET: 'Mã mặc định sẽ không được chấp nhận',
	VALIDATION_ERROR_NO_UNITTYPE_SET: 'Chọn đơn vị',
	VALIDATION_ERROR_NO_PROCESS_EQUIPMENT_SET: 'Chọn thiết bị xử lý',
	VALIDATION_ERROR_InsertWithoutTransponder: 'Không thể tạo động vật trong ESF nếu không có bộ phát đáp',
	VALIDATION_ERROR_CycleDayIsEmpty: 'Ngày chu kỳ không được đặt',
	VALIDATION_ERROR_AnimalNumberIsEmpty: 'Không thể cập nhật động vật trong ESF mà không có số động vật',
	VALIDATION_ERROR_FeedCurveDoesNotExist: 'Kế hoạch cho ăn đã chọn không tồn tại',
	VALIDATION_ERROR_LocationIsEmpty: 'Vị trí chưa được đặt',
	VALIDATION_ERROR_InvalidAnimalKindOnEsfLocation: 'Không thể tạo loại động vật đã chọn trên ô chuồng ESF',
	VALIDATION_ERROR_FARM_HAS_ESF_BUT_ANIMAL_DOES_NOT_HAVE_TRANSPONDER:
		'động vật cần phải có một bộ phát đáp, trước khi con vật có thể được di chuyển đến một vị trí có bộ phát đáp',
	VALIDATION_ERROR_FEED_CURVE_NUMBER_IS_NOT_SET: 'Kế hoạch cho ăn được yêu cầu vì vị trí đã chọn là vị trí ESF',
	VALIDATION_ERROR_ANIMALS_NEED_TRANSPONDER: 'Động vật được liệt kê không có bộ phát đáp nr.',
	VALIDATION_ERROR_INVALID_ESF_DATA: 'Dữ liệu về động vật được quét đã lỗi thời',
	VALIDATION_ERROR_BLE_CHOOSE_LOCATION: 'Để sử dụng máy quét, hãy chọn vị trí mặc định trên ứng dụng.',
	VALIDATION_ERROR_SOW_INVALID_FOR_WORKLIST: 'Động vật không hợp lệ cho danh sách công việc này',
	VALIDATION_ERROR_ENTRACE_SOW_EDIT_LITTER:
		'Lứa đẻ nên từ 2 hoặc cao hơn, bởi vì con vật được tạo ra như một con lợn nái',
	VALIDATION_ERROR_MATING_BATCH_1_YEAR:
		'Yêu cầu có một lô giao phối ít nhất 1 năm kể từ ngày hôm nay . Vui lòng tạo thêm các lô giao phối',
	VALIDATION_ERROR_MUST_BE_START_MATING_BATCH: 'Ngày phải là ngày bắt đầu của một đợt giao phối muộn hơn hiện tại',
	VALIDATION_ERROR_AVG_WEIGHT_POSITIVE: 'T.Lượng T.B phải là số dương',
	VALIDATION_ERROR_DEAD_PIGLETS_NOT_SET: 'Số tiền chưa đặt - Phải lớn hơn 0',
	VALIDATION_ERROR_MUST_BE_NURSING: 'Lợn nái cần được cho con bú',
	VALIDATION_ERROR_NO_FEEDING_ADJUSTMENT: 'Loại thức ăn không có bất kỳ điều chỉnh nào trước ngày đã đặt',
	VALIDATION_ERROR_DEPARTURE_DATE_IS_EARLIER_THAN_LAST_PREGNANCY:
		'Xuất không hợp lệ, vì ngày khởi hành sớm hơn sự kiện mang thai cuối cùng đó.',
	VALIDATION_ERROR_NO_DEPARTURE_ON_NURSING_SOW:
		'Xuất là không hợp lệ, bởi vì lợn nái đang ở trong tình trạng nuôi con',
	VALIDATION_ERROR_MOVE_EVENT_IS_EARLIER_THAN_LAST_EVENT: 'Ngày di chuyển sớm hơn ngày di chuyển cuối cùng\n',
	VALIDATION_ERROR_TYPE_NOT_SET: 'Thể loại không được đặt',
	VALIDATION_ERROR_CODE_ALREADY_USED: 'Mã đã được sử dụng',
	VALIDATION_ERROR_REASON_NOT_SET: 'Lý do không được thiết lập',
	VALIDATION_ERROR_NO_SOWS_IN_FILE: 'Không có lợn nái trong tệp',
	VALIDATION_ERROR_SOME_SOWS_EXISTS: 'Một số lợn nái đã tồn tại',
	VALIDATION_ERROR_ANIMAL_ID_NUM_EXISTS: 'Số ID đã tồn tại',
	VALIDATION_ERROR_ANIMAL_IS_NOT_YOUNG_FEMALE: 'Các con vật, không phải là hậu bị cái',
	VALIDATION_ERROR_NO_DEFAULT_WEANING_PEN:
		'Một vị trí cho lợn cai sữa là cần thiết. Thiết lập trong cài đặt, lợn Nái, Tổng quan (General)',
	VALIDATION_ERROR_COUNT_LARGER_THAN_CURRENT_COUNT1: 'Chỉ',
	VALIDATION_ERROR_COUNT_LARGER_THAN_CURRENT_COUNT2: 'Lợn hiện đang ở các vị trí đã chọn',
	VALIDATION_ERROR_COUNT_LARGER_THAN_CURRENT_COUNT3: 'Có',
	VALIDATION_ERROR_COUNT_LARGER_THAN_CURRENT_COUNT4: 'lợn trong vị trí',
	VALIDATION_ERROR_COUNT_LARGER_THAN_CURRENT_COUNT5: 'Do đó không thể loại',
	VALIDATION_WARNING_MatingToFarrowingOutOfInterval:
		'Thời kỳ mang thai cho lợn nái, đã hết khoảng thời gian được cấu hình, bạn có muốn lưu lại không?',
	VALIDATION_ERROR_YOU_ARE_MATING_A_ANIMAL_THAT_IS_NURSING:
		'Bạn đang phối giống một lợn, mà chúng đang nuôi con, bạn vẫn muốn lưu?',
	VALIDATION_WARNING_FarrowingToWeaningOutOfInterval1: 'ngày cai sữa là',
	VALIDATION_WARNING_FarrowingToWeaningOutOfInterval2: 'và vượt quá khoảng thời gian, bạn có muốn lưu lại không?',
	VALIDATION_WARNING_WeaningToMatingOutOfInterval:
		'Khoảng thời gian từ Cai sữa đến giao phối nằm ngoài khoảng thời gian được cài đặt, bạn có muốn lưu lại không?',
	VALIDATION_WARNING_MatingAgeOutOfInterval:
		'Tuổi của con vật nằm ngoài khoảng thời gian được cài đặt, bạn có muốn lưu lại không?',
	VALIDATION_WARNING_ConfirmDeletionMated: 'Xóa đã giao phối?',
	VALIDATION_WARNING_ConfirmDeletionFeedingStockAdjustment: 'Bạn có chắc chắn, bạn muốn xóa điều chỉnh cho ăn?',
	VALIDATION_WARNING_ConfirmDeletionFeedingStockUsage: 'Bạn có chắc chắn, bạn muốn xóa việc sử dụng thức ăn?',
	VALIDATION_WARNING_ConfirmDeletionAdjustments: 'Xóa điều chỉnh thuốc đã chọn?',
	VALIDATION_WARNING_ConfirmDeletionScanning: 'Xóa Scaning đã chọn?',
	VALIDATION_WARNING_ConfirmDeletionFarrowing: 'Xóa lợn đẻ đã chọn?',
	VALIDATION_WARNING_ConfirmDeletionWeaning: 'Xóa lợn đẻ đã chọn?',
	VALIDATION_WARNING_CapacityExceeded1: 'Công suất vượt quá trên ô chuồng:',
	VALIDATION_WARNING_CapacityExceeded2: 'Số lượng lợn hiện tại:',
	VALIDATION_WARNING_CapacityExceeded3: 'Công suất là',
	VALIDATION_WARNING_ConfirmWarningMessage: 'Bạn có chắc chắn muốn lưu lại?',
	VALIDATION_WARNING_ConfirmWarningDataNotSavedMessage: 'Có dữ liệu, không được lưu, chúng có nên được lưu không?',
	VALIDATION_WARNING_ConfirmWarningMessageMoveEvent:
		'Bạn có chắc chắn muốn di chuyển con vật này đến vị trí mới không?',
	VALIDATION_WARNING_ConfirmDeletionBuilding: 'Bạn có chắc chắn muốn xóa tòa nhà này không?',
	VALIDATION_WARNING_ConfirmDeletionSection: 'Bạn có chắc chắn muốn xóa phòng này không?',
	VALIDATION_WARNING_ValveGroupSectionContainsOldData: 'Phòng đã chọn chứa dữ liệu nhóm van cũ',
	VALIDATION_WARNING_ValveGroupsModificationOld: 'Sửa đổi trên các nhóm van không lưu lại được vì nó đã lỗi thời',
	VALIDATION_WARNING_ValveGroupIsNotEditable:
		'Dữ liệu của Van là từ ngày hôm qua và không thể chỉnh sửa được vì dữ liệu cũ. Thông qua trang web, điều chỉnh các thay đổi nguồn thức ăn, bạn có thể tải xuống dữ liệu mới nhất từ Distriwin',
	VALIDATION_WARNING_ESF_DATA_IS_NOT_UP_TO_DATE: 'Một số dữ liệu không được cập nhật với bộ điều khiển',
	VALIDATION_ERROR_CURVE_INVALID: 'Đường cong cần chọn để lưu',
	VALIDATION_ERROR_FIXED_PERCENT_INVALID: 'Cố định % cần phải là 0-100 để lưu lại',
	VALIDATION_WARNING_DELETE_MARKED_YOUNG_ANIMALS: 'Xóa lợn hậu bị đã đánh dấu?',
	VALIDATION_WARNING_EDIT_GROWTH_PIG_EVENT:
		'Những thay đổi sẽ dẫn đến số lượng tiêu cực của lợn, bạn có muốn tiếp tục không?',
	SlaughterHouse: 'Lò giết mổ',
	connectToTican: 'Kết nối với Tican',
	DanishCrown: 'Danish Crown',
	Tican: 'Tican',
	loginInformation: 'Thông tin đăng nhập',
	connectionSlaughterHouse: 'Giao tiếp lò giết mổ',
	LoginInformationDanishCrown: 'THÔNG TIN ĐĂNG NHẬP DANISH CROWN',
	UserId: 'ID người dùng:',
	password: 'Mật khẩu:',
	SupplierNumbers: 'Nhà cung cấp',
	meatPercentage: '% Thịt',
	slaughterWeight: 'T.Lượng giết mổ',
	checkSlaughterDataWithDepartureData: 'Kiểm tra đã giết mổ với dữ liệu đưa giết mổ',
	departureSkioldDigital: 'Xuất = Số lượng giết giết mổ',
	departureSlaughterHouse: 'Đếm dữ liệu giết mổ',
	difference: 'Sự khác nhau',
	checkSlaughterData: 'Kiểm tra dữ liệu đã giết mổ/đưa giết mổ',
	finisherData: 'Dữ liệu đưa giết mổ',
	supplier: 'Nhà cung cấp.',
	chooseLocationForMove: 'Chọn vị trí để chuyển',
	APig: 'Một lợn',
	Discarded: 'Loại bỏ',
	BesBoar: 'Lợn lòi nọc',
	DiscardedSow: 'Lợn nái bỏ đi',
	DiscardedBesBoar: 'Lợn lòi nọc bỏ đi',
	RemovedMale: 'Đực đã loại bỏ',
	OtherDelievered: 'Xuất kiểu khác',
	ReturnPig: 'Lợn trở về',
	Barrow: 'Đực thiến',
	Basic: 'Căn bản',
	slaughterNumber: 'Mã lò giết mổ',
	noting: 'Ghi chú',
	aliveWeight: 'T.Lượng hơi',
	SavingChangesTheLocationForAllPigsInThisSectionForThisDelieveryForThisDate:
		'Bạn có muốn thay đổi vị trí cho việc xuất không?',
	DoYouWantToChangeTheLocationOfThoseItems: 'Bạn có muốn thay đổi vị trí các mục được đánh dấu không?',
	DoYouWantToDeleteSlaughterDatas: 'Bạn có muốn xóa những dữ liệu giết mổ được đánh dấu không?',
	FinisherDataProcessFinisher: 'Dữ liệu giết giết mổ',
	Failed_To_Login_Tican: 'Thất bại với tican, vui lòng thử lại',
	Error_SlaughterHouse_required: 'Yêu cầu lò giết mổ',
	avgPricePrKg: 'Kr./kg',
	IncorrectLoginInformation: 'Thông tin đăng nhập không chính xác',
	supplementDeductionPrPig: 'Bổ sung / khấu trừ cho mỗi con lợn',
	spec: 'Spec',
	Note: 'Ghi chú',
	priceKgBasis: 'KR / KG CĂN BẢN',
	noteText: 'Văn bản ghi chú',
	priceWithDeduction: 'Giá bao gồm các khoản bổ sung / khấu trừ cơ sở',
	PromptSureDelete: 'Bạn có chắc chắn muốn xóa?',
	SetupValidationSetup: 'Vui lòng nhập cài đặt xác thực trên trang cài đặt',
	TreatmentDefinitions: 'Định nghĩa điều trị / vắc-xin',
	drug: 'Thuốc',
	chooseDrugType: 'Chọn loại thuốc',
	RetentionTime: 'Thời gian lưu giữ',
	method: 'Phương pháp',
	amountPerUnit: 'Số tiền trên mỗi đơn vị',
	duration: 'Trường độ',
	interval: 'Khoảng t.gian',
	amount: 'Lượng',
	numberTreatments: 'Lượng',
	per: 'cho',
	addTreatDef: 'Thêm định nghĩa',
	choose: 'Chọn',
	SowBoarGlit: 'Nái/ Nọc / Hậu bị',
	none: 'Rỗng',
	productionKey: 'QUY MÔ SẢN XUẤT',
	feedKey: 'CÁM',
	diverseInformationKey: 'THÔNG TIN ĐA DẠNG',
	slaughterDataKey: 'DỮ LIỆU GIẾT MỔ',
	stateKey: 'TÌNH TRẠNG',
	subResultKey: 'KẾT QUẢ PHỤ',
	FeedUsageKey: 'SỬ DỤNG THỨC ĂN',
	showFeedUsage: 'Sử dụng cám',
	datagrowthPigPReportSlaughterHouseData: {
		amountBasicPcs: 'Cơ bản, pcs',
		amountOverPcs: 'Quá, pcs',
		amountUnderPcs: 'Dưới, pcs',
		avgSlaughterWeight: 'T.Lượng giết mổ T.B',
		avgMeatPercentage: 'Tỷ lệ phần trăm thịt T.B',
		avgDiscarded: 'Pct. bị loại bỏ',
	},
	datagrowthPigPReportSubResults: {
		feedDays: 'Thời gian ngày cho ăn',
		periodGrowth: 'Tăng trưởng thời kỳ, kg',
	},
	datagrowthPigPReportDiverseInformation: {
		growthPerPigKg: 'Tăng trưởng mỗi con lợn, kg',
		avgWeightAtEntry: 'T.Lượng T.B khi nhập cảnh, kg',
		avgWeightAtDeparture: 'T.Lượng T.B xuất, kg',
		feedDaysPerProducedPigs: 'Ngày cho ăn mỗi lợn sản xuất',
		diversePercentDead: 'Tỷ lệ phần trăm tử vong',
	},
	datagrowthPigPReportProduction: {
		producedPigsPcs: 'Lợn đã s.xuất, pcs.',
		dailyGrowth: 'Tăng trưởng hàng ngày, g',
		referenceDailyGrowth: 'Tham khảo tăng trưởng hàng ngày',
	},
	datagrowthPigPReportState: {
		activePcs: 'Hoạt động, pcs.',
		totalEntryInPeriod: 'Nhập, pcs.',
		entryFromBought: 'Trong đó, mua, pcs',
		entryFromTransfered: 'Trong đó, chuyển, pcs',
		entryFromWeaning: 'Trong đó, cai sữa, pcs',
		avgWeightAtEntry: 'T.Lượng T.B khi nhập',
		deadPutDownPcs: 'Chết / Bị giết, pcs.',
		avgWeightDeadPutDown: 'T.B T.Lượng chết, kg',
		soldTransferedPcs: 'Đã bán / chuyển nhượng, pcs.',
		transferedToYoungFemale: 'Trong đó, h.bị cái, pcs',
		avgWeightSoldTransfered: 'T.lượng TB đã bán / chuyển nhượng, kg.',
		delieveredPcs: 'Đã xuất, pcs',
		avgWeightDelievered: 'Tbình t.lượng xuất, kg.',
		activeWeight: 'T.Lượng T.B',
		totalSlaughteredRegistrations: 'Đã xuất, đã giết mổ',
		avgWeightSlaughteredRegistrations: 'T.lượng t.bình giết mổ, kg',
	},
	datagrowthPigPReportFeed: {
		feedPerPigDaily: 'Thức ăn cho mỗi lợn hàng ngày, FUsv',
		feedPerKgGrowth: 'Thức ăn cho mỗi kg tăng trưởng, Fusv',
		referenceFeedUsage: 'Thức ăn tham khảo sử dụng mỗi kg, FEsv',
		feedPerProducedPig: 'Thức ăn cho mỗi lợn sản xuất, FEsv',
	},
	ProducedPigsPcs: 'Lợn đã s.xuất, pcs.',
	DailyGrowth: 'Tăng trưởng hàng ngày, g',
	ReferenceDailyGrowth: 'Tham khảo tăng trưởng hàng ngày',
	WeanerReferenceDailyGrowth: 'Tham khảo tăng trưởng hàng ngày 7-30 kg',
	FinisherReferenceDailyGrowth: 'Tham khảo tăng trưởng hàng ngày 30-115 kg',
	FRATSReferenceDailyGrowth: 'Tham khảo tăng trưởng hàng ngày 7-115 kg',
	GrowthPerPigKg: 'Tăng trưởng mỗi lợn, kg',
	AvgWeightAtEntry: 'T.Lượng T.B khi vào, kg',
	AvgWeightAtDeparture: 'T.Lượng T.B khi xuất, kg',
	FeedDaysPerProducedPigs: 'Ngày cho ăn mỗi lợn s.xuất',
	DiversePercentDead: 'Tỷ lệ phần trăm tử vong',
	FRATSActivePcs: 'FRATS, pcs.',
	WeanerActivePcs: 'Cai sữa, pcs.',
	FinisherActivePcs: 'Lợn thịt, pcs.',
	TotalEntryInPeriod: 'Nhập, pcs.',
	EntryFromWeaning: 'Trong đó, C.sữa, pcs',
	EntryFromBought: 'Trong đó, mua, pcs',
	EntryFromTransfered: 'Trong đó, chuyển, pcs',
	DeadPutDownPcs: 'Chết / Bị giết, chiếc.',
	AvgWeightDeadPutDown: 'T.B T.Lượng chết, kg',
	SoldTransferedPcs: 'Đã bán / chuyển, pcs.',
	TransferedToYoungFemale: 'Trong đó vào H.bị cái',
	AvgWeightSoldTransfered: 'T.Lượng T.B đã bán / chuyển, kg.',
	DelieveredPcs: 'Đã xuất, pcs.',
	TotalSlaughteredRegistrations: 'Đã xuất, đã giết mổ',
	AvgWeightSlaughteredRegistrations: 'T.Lượng T.B giết mổ, kg',
	AvgWeightDelievered: 'T.Lượng T.B đã xuất, kg',
	FeedDays: 'Khoảng t.gian cho ăn',
	PeriodGrowth: 'Khoảng t.gian tăng trưởng',
	AmountBasicPcs: 'Căn bản, pcs.',
	AmountOverPcs: 'Trên C.bản, pcs.',
	AmountUnderPcs: 'Dưới C.bản, pcs.',
	AvgSlaughterWeight: 'T.Lượng T.B đã xuất, kg',
	AvgMeatPercentage: 'T.Lệ % thịt',
	AvgDiscarded: 'T.B loại bỏ',
	FeedPerPigDaily: 'Thức ăn cho mỗi lợn hàng ngày, FUsv',
	FeedPerKgGrowth: 'Thức ăn cho mỗi kg tăng trưởng, Fusv',
	ReferenceFeedUsage: 'Tham khảo lượng cám s.dụng mỗi kg',
	WeanerReferenceFeedUsage: 'Tham khảo lượng cám 7-30 kg',
	FinisherReferenceFeedUsage: 'Tham khảo lượng cám 30-115 kg',
	FRATSReferenceFeedUsage: 'Tham khảo lượng cám 7-115 kg',
	FeedPerProducedPig: 'Thức ăn cho mỗi lợn s.xuất, FUsv',
	weanerFoodTotal: 'Thức ăn cai sữa ăn tổng cộng',
	finisherFoodTotal: 'Thức ăn lợn thịt ăn tổng cộng',
	createDelievery: 'Tạo mã xuất',
	ReportsWeaner: 'Báo cáo',
	ReportsFinisher: 'Báo cáo',
	reportsWeaner: 'Báo cáo',
	reportsFinisher: 'Báo cáo',
	growthPigPReportProduction: 'Phạm vị sản xuất',
	growthPigPReportDiverseInformation: 'Thông tin đa dạng',
	growthPigPReportFeed: 'Thức ăn',
	growthPigPReportState: 'Tình trạng',
	growthPigPReportSubResults: 'Kết quả phụ',
	growthPigPReportSlaughterHouseData: 'DỮ LIỆU GIẾT MỔ',
	Calculated: 'Đã tính',
	Tuned: 'Đã điều chỉnh',
	tuneProductionReportWeaner: 'Điều chỉnh cai sữa',
	tuneProductionReportFinisher: 'Điều chỉnh lợn thịt',
	tuneProductionReportFRATS: 'Điều chỉnh FRATS',
	weanerPigsTuneProductionLists: 'Điều chỉnh lợn',
	finisherPigsTuneProductionLists: 'Điều chỉnh lợn',
	Tune: 'Điều chỉnh',
	ActiveWeight: 'T.lượng Avg.',
	entered: 'Nhập vào',
	internalTransfer: 'Chuyển nội bộ',
	Food: 'Thức ăn',
	Water: 'Nước uống',
	Oral: 'Uống',
	Injection: 'Tiêm',
	OnTheSkin: 'Dưới da',
	perKgPig: 'Kg lợn',
	perKgFood: 'Kg T.ăn',
	perAnimal: 'Vật nuôi',
	once: 'Một lần',
	everyDay: 'Mỗi ngày',
	everyOtherDay: 'Cách ngày',
	everyWeek: 'Mỗi tuần',
	event: 'Sự kiện',
	Days1: 'Ngày 1',
	Days2: 'Ngày 2',
	Age1: 'Tuồi 1',
	Age2: 'Tuổi 2',
	Age3: 'Tuổi 3',
	Farrowing: 'Lợn đẻ',
	Mating: 'Lợn phối',
	Averting: 'Cai sữa',
	Birthdate: 'Sinh nhật',
	chooseDrug: 'Chọn thuốc',
	TREATMENT_LOG: 'ĐIỀU TRỊ',
	Treatments: 'Các điều trị',
	Treatment: 'Điều trị',
	FetchTreatmentPlans: 'Mẹo',
	ExecutedDate: 'Thực hiện ngày',
	Reactivate: 'Kích hoạt lại',
	FromDate: 'Từ ngày',
	ToDate: 'Đến ngày',
	User: 'Người dùng',
	CountFirstTreatments: 'S.lượng 1. lần trị',
	CountRetreatments: 'S.lượng Đ.trị lại',
	treatmentDetails: 'Chi tiết Đ.trị',
	Treated: 'Đã trị',
	DeleteTreatmentPlan: 'Xóa K.hoạch Đ.trị',
	DeleteTreatmentPlanPrompt: 'Thật sự xóa toàn bộ kế hoạch điều trị? Quá trình này không thể khôi phục.',
	TreatmentPlanNotFound: 'Không tìm thấy K.hoạch Đ.trị',
	PerAnimal: 'Mỗi vật nuôi',
	PerPig: 'Mỗi lợn',
	Total: 'Tổng',
	OverdueAlertHeader: 'Điều trị quá hạn!',
	OverdueAlertMessage: 'Bạn đã thực hiện điều trị khi nào?',
	OverdueAlertHeaderDepart: 'Chết/ Tiêu hủy',
	OverdueAlertMessageDepart: 'Bạn đã thực hiện đăng ký khi nào?',
	PregnancyLists: 'Danh sách mang thai',
	moveEvents: 'Di chuyển',
	VALIDATION_ERROR_WEIGHT_NOT_DISTRIBUTED: 'T.lượng chưa được phân bổ',
	VALIDATION_ERROR_TO_MUCH_WEIGHT_DISTRIBUTED: 'Quá nhiều T.lượng phân bổ',
	VALIDATION_ERROR_PIGS_NOT_DISTRIBUTED: 'Lợn chưa được phân bổ',
	VALIDATION_ERROR_TO_MANY_PIGS_DISTRIBUTED: 'Quá nhiều lợn được phân bổ',
	VALIDATION_ERROR_TO_MANY_PIGS_MOVED: 'Quá nhiều lợn được chuyển',
	VALIDATION_ERROR_TO_MANY_PIGS_DEPARTED: 'Quá nhiều lợn xuất',
	VALIDATION_ERROR_FROM_LOCATION_NOT_SET: 'Chưa nhập vị trí hiện tại',
	VALIDATION_ERROR_TO_LOCATION_NOT_SET: 'Chưa nhập vị trí đến',
	VALIDATION_ERROR_FROM_PRODUCTIONTYPE_NOT_SET: 'Vị trí S.xuất hiện tại chưa nhập',
	VALIDATION_ERROR_TO_PRODUCTIONTYPE_NOT_SET: 'Vị trí S.xuất mới chưa nhập',
	VALIDATION_ERROR_NO_PIGS_MOVED: 'Không lợn nào được chuyển',
	VALIDATION_ERROR_NO_PIGS_DEPARTED: 'Không lợn nào được xuất',
	productionType: 'Hình thức sản xuất',
	GrowthPigEntrance: 'Được mua',
	GrowthPigMoveEvent: 'Chuyển',
	VALIDATION_REASON_NOT_SET: 'Chưa nêu lý do',
	GrowthPigEntranceEvent: 'Được mua',
	GrowthPigDepartureEvent: 'Bán/Giết thịt',
	GrowthPigPenDepartureEvent: 'Bị chết/Chết',
	toProductionType: 'Đến nơi sản xuất',
	fromProductionType: 'Từ nơi sản xuất',
	DistributePigsInSection: 'Phân phối lợn trong phòng',
	SpecifyWeight: 'Nhập trọng lượng',
	AmountPigsNotDistributed: 'Số lượng lợn, không được phân phối',
	TotalWeightNotDistributed: 'Trọng lượng, không được phân phối',
	FromWeaner: 'Từ cai sữa',
	FromFinisher: 'Từ lợn thịt',
	FromPiglet: 'Từ lúc heo cai sữa',
	FromBuilding: 'Từ nhà heo',
	FromSection: 'Từ phòng heo',
	ToSection: 'Đến phòng heo',
	ToBuilding: 'Đến nhà heo',
	PenNumber: 'Ô chuồng số',
	Bought: 'Được mua',
	Moved: 'Được chuyển',
	Distribute: 'Phân phối',
	DistributeAll: 'Phân phối toàn bộ',
	MoveAll: 'Chuyển toàn bộ',
	DepartureAll: 'Xuất toàn bộ',
	DistributeWeight: 'Phân bố trọng lượng',
	fromBuildingName: 'Từ nhà heo',
	fromSectionName: 'Từ phòng heo',
	fromPenName: 'Từ ô chuồng',
	toBuildingName: 'Đến nhà heo',
	toSectionName: 'Đến phòng heo',
	toPenName: 'Đến ô chuồng',
	GrowthPigEventsList: 'Danh sách các sự kiện',
	EventsList: 'Danh sách sự kiện',
	toProctionType: 'Đến nơi sản xuất',
	fromProctionType: 'Từ nơi sản xuất',
	YouHaveToSelectLocation: 'Bạn phải chọn một vị trí, mà vật nuôi thuộc về',
	appFinishersManagement: 'Lợn thịt',
	appSowManagement: 'Lợn nái',
	appWeanersManagement: 'Lợn sữa',
	DistributeCount: 'Phân bố bán',
	DistributeSlaughter: 'Phân bố lò mổ',
	TotalPigsInPeriod: 'Tổng lợn trong kỳ',
	ShouldDepartureList: 'Danh sách xuất',
	ShouldDepartureListWeaner: 'Danh sách xuất',
	ShouldDepartureListFinisher: 'Danh sách xuất',
	weanerShouldDepartureOverview: 'Danh sách xuất',
	MovePigsOnLocation: 'Chuyển lợn vào vị trí',
	finisherShouldDepartureOverview: 'Danh sách xuất',
	DistributeMoveFrom: 'Phân phối chuyển từ',
	DistributeTo: 'Phân bổ vào',
	mating: 'Phối',
	Matings: 'Lợn phối',
	Scannings: 'Quét scan',
	Farrowings: 'Lợn đẻ',
	Farrowed: 'Đã đẻ',
	Weanings: 'Lợn cai sữa',
	Grade: 'Loại',
	NumAlive: 'Lượng còn sống',
	NumAliveTotal: 'Tổng lượng sống',
	NumDead: 'Lượng chết',
	NumDeadTotal: 'Tổng lượng chết',
	Dry: 'Nái khô',
	Preg: 'Mang thai',
	Nursing: 'Nuôi con',
	PregnantAndNursing: 'Mang thai/Nuôi con',
	NursingLong: 'Nuôi con',
	Gestation: 'Mang thai',
	Maternity: 'Nuôi con',
	True: 'Yes',
	False: 'No',
	Maybe: '?',
	scanning: 'Quét scan',
	Pregnant: 'Có thai',
	NonPregant: 'Không mang thai',
	farrowing: 'Lợn đẻ',
	alive: 'Còn sống',
	dead: 'Chết',
	aliveFemale: 'Lợn cái còn sống',
	aliveMale: 'Lợn đực còn sống',
	abortion: 'Sẩy thai',
	farrowingInProgress: 'Đang đẻ',
	FarrowingDate: 'Ngày đẻ',
	pcsWithoutDot: 'pcs',
	pcs: 'pcs',
	Pcs: 'Pcs',
	avgWeight: 'T.lượng Avg./Lợn',
	avgWeightShort: 'T.lượng Avg.',
	avgWeightSlashPig: 'T.lượng/lợn',
	avgPriceSlashPig: 'Giá/lợn',
	totalWeight: 'Tổng trọng lượng',
	nursingSow: 'Lợn nuôi con',
	avgPrice: 'Giá mỗi lợn',
	totalPrice: 'Tổng giá',
	Weaning: 'Cai sữa',
	nursSow: 'N',
	totalPcs: 'Tổng pcs',
	avgPcs: 'Avg. pcs',
	defaultWeight: 'T.lượng chuẩn',
	NoWeaningLocationSet: 'Chưa xác định vị trí cai sữa',
	matingBatch: 'Lô phối',
	AmountMatingBatchesPerColumn: 'Lượng lô phối mỗi cột',
	matingBatchUpToAndIncluding: 'Lô phối được tính lên đến và bao gồm',
	weekdayStart: 'Ngày bắt đầu trong tuần',
	StartDateForBatch: 'Ngày bắt đầu lô phối',
	daysForMatingPeriod: 'Ngày trong chu kỳ phối',
	cycleInWeeks: 'Chu kỳ theo tuần',
	matingPeriod: 'Chu ký phối',
	FarrowingPeriod: 'Chu kỳ đẻ',
	batchNum: 'Số lô',
	pregnantDays: 'Ngày mang thai',
	NursingDays: 'Ngày nuôi con',
	calculate: 'Tính toán',
	addMatingBatch: 'Thêm lô phối',
	deleteMatingBatch: 'Xóa lô phối',
	weaningPeriod: 'Chu ký cai sữa',
	Start: 'Bắt đầu',
	End: 'Kết thúc',
	deleteMatingBatchesFromDateAndForward: 'Xóa lô phối kể từ ngày này trở đi',
	repeat: 'Lặp lại',
	manuel: 'Thủ công',
	daysExceeded: 'Số ngày vượt quá',
	batchPerCycle: 'Số lứa - bắt đầu lứa 1 mỗi',
	timesToRepeat: 'Số lần lặp',
	setStartDateToNextAvailableBatch: 'Đặt ngày bắt đầu cho lô phối tiếp theo?',
	matingBatchReport: 'Báo cáo lô phối',
	printMatingBatchReport: 'In báo cáo lô phối',
	actualWeek: 'Tuần hiện tại',
	TotalMatings: 'Phối, pcs',
	YoungAnimalMatings: '• Trong đó hậu bị cái, pcs.',
	TotalDepartures: 'Vật nuôi xuất từ lô phối, pcs.',
	DepartureNonPregnant: '• Trong đó không mang thai, pcs.',
	DepartureKilled: '• Trong đó đã mổ/bán, pcs.',
	DepartureDead: '• Trong đó đã chết/bị giết, pcs.',
	AvgWasteDays: 'Không sản xuất Avg.',
	ReturnSows: '• Trong đó lê giống lại',
	ThereOfAbortions: '• Trong đó sẩy thai',
	WeaningToFirstMating: 'Ngày từ cai sữa đến phối lần đầu',
	AnimalsToFarrowing: 'Vật nuôi để đẻ, pcs',
	TotalFarrowings: 'Heo đẻ, pcs.',
	NeedFarrowing: 'Những heo chưa đẻ, pcs.',
	AvgAlive: 'T.bình sinh ra sống, pcs',
	AvgDead: 'T.bình chết lưu, pcs',
	FarrowingRate: 'Tỷ lệ đẻ theo lô, pct.',
	AvgPregnantDays: 'Số ngày mang thai T.bình',
	AvgPregnantDaysPreviousBatch: 'Số ngày mang thai T.bình lứa trước',
	ThereOfFirstParity: '• Trong đó lứa 1',
	AvgLivebornFirstParity: 'Sinh ra sống T.bình lứa 1, pcs',
	TotalLiveBorn: 'Tổng sinh ra sống, pcs',
	StillbornToAliveRate: 'Chết lưu theo pct. Trong tổng sinh',
	TotalWeanings: 'Lợn nái đã cai sữa, pcs',
	NursingSows: '• Trong đó lơn nuôi con, pcs.',
	NeedWeaning: 'Những nái chưa cai sữa, pcs',
	AvgAlivePerLitter: 'Sinh ra sống T.bình trên lứa, pcs',
	AvgDeadPerLitter: 'Sinh chết lưu T.bình trên lứa, pcs',
	AvgWeanedPerLitter: 'Cai sữa T.bình trên lứa, pcs',
	AvgWeight: 'T.lượng mỗi lợn, kg',
	DeadTillWeaning: 'Lợn con chết',
	DeadToAliveRate: 'Chết đến khi cai sữa, pct',
	AvgNursingDays: 'Nuôi con trung bình',
	NursingSowsCount: 'Nái nuôi con',
	AvgAliveShort: 'T.bình sống',
	AvgDeadShort: 'Trung bình chết',
	AvgPriceShort: 'Giá trung bình',
	AvgWeanedPerWeaninig: 'Cai sữa mỗi lượt, pcs',
	TotalWeanedPiglets: 'Tổng cai sữa',
	Monday: 'Thứ 2',
	Tuesday: 'Thứ 3',
	Wednesday: 'Thứ 4',
	Thursday: 'Thứ 5',
	Friday: 'Thứ 6',
	Saturday: 'Thứ 7',
	Sunday: 'Chù Nhật',
	FeedingAdjustment: 'Chỉnh cám',
	FeedingUsage: 'Lượng cám dùng',
	FeedingStock: 'Lượng cám tồn',
	FeedingLog: 'Nhật ký dùng cám',
	CreateFood: 'Tạo cám',
	EditFood: 'Hiệu chỉnh cám mua',
	EditFoodUsage: 'Chỉnh sửa cám dùng',
	CreateUsage: 'Tạo cách dùng',
	CreatePurchase: 'Mua cám',
	FeedingType: 'Loại cám',
	FeedingSubject: 'Kế hoạch cho ăn',
	FeedingSubjectName: 'Tên kế hoạch cho ăn',
	AmountInKgs: 'Lượng tính kg',
	FEsoPerKg: 'FUsow/kg',
	FEsvPerKg: 'FUsv/kg',
	purchase: 'Mua hàng',
	RawProtein: 'Protein thô % của sản phẩm',
	FosforGPerKg: 'Phốt pho g/kg',
	PricePer100Kgs: 'Giá mỗi 100 kg',
	PeriodFrom: 'Khoảng từ',
	PeriodTo: 'Đến khoảng',
	UsageKg: 'Sử dụng kg',
	UsageFE: 'Sử dụng FU',
	UsageFEso: 'Sử dụng Fusow',
	UsageFEsv: 'Sử dụng Fusv',
	UsageMoney: 'Tiền sử dụng',
	feedingLog: 'Nhật ký cho ăn',
	FEso: 'FUso',
	FEsv: 'FUsv',
	KG: 'KG',
	DrugStock: 'Kho thuốc/văcxin',
	adjustmentType: 'Kiểu điều chỉnh',
	registeredBy: 'Đăng ký bởi',
	registeredOn: 'Ngày đăng ký',
	medicineLog: 'Nhật ký thuốc',
	AddToDrugStock: 'Thêm',
	lastRegulation: 'Thức hiện lần cuối',
	Waste: 'Hao phí',
	Usage: 'Sử dụng',
	UpdateInventory: 'Kho chứa',
	MedicineStockStorage: 'Tồn kho',
	UpdateMedicineStock: 'Quy định',
	AddDrug: 'Thêm thuốc vào kho',
	UpdateDrugStockAmount: 'Cập nhật tồn kho',
	currentDrugStockAmount: 'Tồn kho hiện tại',
	newDrugStockAmount: 'Lưu kho mới',
	createMedication: 'Tạo thuốc',
	Medicine: 'Thuốc',
	Vaccine: 'Vắc-xin',
	SOWCARD: 'THẺ NÁI',
	location: 'Vị trí',
	IDnr: 'Mã ID',
	comment: 'Ghi chú',
	move: 'Chuyển',
	wasteDays: 'Ngày phí',
	wasteDaysShort: 'Lãng phí',
	weaningShort: 'Cai sữa',
	pigYearSo: 'Lợn/nái năm',
	pregnantDaysShort: 'M.thai',
	gradeShort: 'K',
	abortionSowCard: 'A',
	nursingDaysShort: 'Nuôi',
	aliveShort: 'Sống',
	kilo: 'Kg',
	pregDays: 'Ngày M.thai',
	nursDays: 'Ngày nuôi',
	stillBorn: 'Chết lưu',
	lastMoved: 'Lần cuối ở',
	departed: 'Đã xuất',
	Boars: 'Lợn nọc',
	Relief: 'Cứu chữa',
	ReliefFinisher: 'Chữa lơn thịt',
	ReliefWeaners: 'Chữa cai sữa',
	Holistic: 'Toàn diện',
	Weaners: 'Cai sữa',
	ClimatePigs: 'Cai sữa',
	Finisher: 'Lợn thịt',
	locationType: 'Kiểu',
	Silo: 'Silo',
	PostponeSows: 'Nài hoãn',
	WeanedPiglets: 'Cai sữa',
	FRATS: 'FRATS',
	FeedMill: 'Nhà máy cám',
	Weaner: 'Cai sữa',
	animalType: 'Loại vật nuôi',
	production: 'Sản xuất',
	PigProduction: 'Chăn nuôi lợn',
	GrainMill: 'Nghiền ngũ cốc',
	Feed: 'Cám',
	Mills: 'Nhà máy',
	lowerLimit: 'Giới hạn dưới',
	upperLimit: 'Giới hạn trên',
	plan: 'Kế hoạch',
	Sell: 'Bán',
	ToBeMoved: 'Được chuyển đi',
	validation: 'Xác nhận',
	weaningAnimals: 'Lợn cai sữa',
	usingQRForSows: 'Dùng QR code cho nái',
	usingQRForLocations: 'Dùng QR code cho vị trí',
	MatingAge: 'Tuổi hậu bị lúc phối',
	MatingToFarrowing: 'Phối đến đẻ',
	FarrowingToWeaning: 'Đẻ đến cai sữa',
	FarrowingToAverted: 'Đẻ đến cai sữa',
	AvertedToMating: 'Cai sữa đến phối lần 1',
	systemMinimum: 'Hệ thống tối thiểu',
	systemMaximum: 'Hệ thống tối đa',
	deviationMinimum: 'Độ lệch -  tối thiểu',
	deviationMaximim: 'Độ lệch - tối đa',
	weanedPigletsMovedToLocation: 'Lợn cai sữa được chuyển đến:',
	Component: 'Thành phần',
	Destination: 'Điểm đến',
	NewDeliveryIn: 'Giao hàng mới vào',
	NewDeliveryInBulk: 'Giao xe cám vào',
	NewDeliveryInBag: 'Giao cám bao vào',
	NoComponentsReceived: 'Không có sẵn thành phần nào',
	ChooseComponent: 'Chọn thành phần',
	ChooseSupplier: 'Chọn nhà cung cấp',
	ChooseTruck: 'Chọn xe',
	ChooseSourceSilo: 'Chọn Silo nguồn',
	ChooseDestinationSilo: 'Chọn Silo đến',
	ChooseWarehouse: 'Chọn nhà kho',
	Weighing: 'Cân nặng',
	FirstWeighing: 'Cân lần 1',
	FirstWeighingShort: 'Lần 1',
	SecondWeighing: 'Cân lần 2',
	SecondWeighingShort: 'Lần 2',
	TotalReceived: 'Tổng nhận',
	StartTransport: 'Bắt đầu vận chuyển',
	DeliveryInCreating: 'Đơn hàng đang được khởi tạo tại FlexMix Pro',
	DeliveryInUpdating: 'Đơn hàng này đang được cập nhật tại FlexMix Pro',
	DeliveryInCreatingSuccess: 'Đơn hàng này vừa được khởi tạo thành công',
	DeliveryInUpdatingSuccess: 'Đơn hàng này vừa được cập nhật thành công',
	DeliveryInCreatingSuccessFmpOnly:
		'Đơn hàng này đã khởi tạo trên Flexmix Pro, nhưng có sự cố về lưu trữ lên Skiold Digital',
	DeliveryInUpdatingSuccessFmpOnly:
		'Đơn hàng này đã cập nhật trên Flexmix Pro, nhưng có sự cố về lưu trữ lên Skiold Digital',
	DeliveryInSavingFailed: 'Đã có vấn đề khi gửi đơn hàng đến FlexMix Pro',
	DeliveryInSavingOrderNumber: 'Mã đơn hàng là',
	SaveAndSendOrder: 'Lưu và gửi đơn hàng',
	CancelDelivery: 'Xác nhận hủy giao hàng',
	TotalWeight: 'Tổng trọng lượng',
	Order: 'Đơn hàng',
	ActiveOrders: 'Đơn hàng đang hoạt động',
	OrderNumber: 'Mã đơn hàng',
	FilterByOrderNumberOrComponent: 'Lọc theo mã đơn hoặc thành phần',
	workLists: 'Các công việc',
	WorkLists: 'Các công việc',
	saveWorklist: 'Thực thi các hoạt động đã chọn trong danh sách công việc rồi mới thoát?',
	ChooseWorkLists: 'Chọn danh sách công việc',
	Synchronize: 'Đồng bộ',
	Setup: 'Cài đặt',
	pregnantFromDate: 'Mang thai từ ngày',
	pregnantToDate: 'Mang thai đến ngày',
	chooseAnimalToList: 'Chọn những vật nuôi vào danh sách',
	MoveTo: 'Chuyển đến',
	MoveToPregnancyLocation: 'Chuyển đến chuồng mang thai',
	MoveToFarrowingLocation: 'Chuyển đến chuồng đẻ',
	MoveToMatingLocation: 'Chuyền đến chuồng phối',
	FarrowingListSetting: 'Danh sách lợn đẻ',
	WeaningListSetting: 'Danh sách cai sữa',
	MatingListSetting: 'Danh sách lợn phối',
	ScanningListSetting: 'Quét Scan 1',
	ScanningListSetting2: 'Quét scan 2',
	LardScanningEventListSetting: 'Đo mỡ lưng 1',
	LardScanningEventListSetting2: 'Đo mỡ lưng 2',
	listAvailableOn: 'Danh sách có trên',
	columnTextShownAs: 'Cột chữ/được xem',
	daysAfterMating: 'Số ngày sau phối',
	PregnacyDays: 'Số ngày mang thai',
	PregnancyDays: 'Số ngày mang thai',
	MatingBatch: 'Lô phối',
	Web: 'Web',
	App: 'App',
	SetupActivityList: 'Cài D.sách hoạt động',
	ActivityList: 'D.sách hoạt động',
	activityLists: 'D.sách hoạt động',
	expectedFarrowing: 'S.L đẻ dự kiến',
	expectedFarrowingDashboard: 'S.L đẻ dự kiến',
	completed: 'Hoàn thành',
	VaccineListSetting: 'Tiêm chủng',
	VaccineListSetting1: 'Tiêm chùng 1',
	VaccineListSetting2: 'Tiêm chủng 2',
	VaccineListSetting3: 'Tiêm chủng 3',
	TasksInFarrowingStableSetting: 'Công việc chuồng đẻ',
	TasksInFarrowingStableSetting2: 'Công việc chuồng đẻ 2',
	boarPerLitterPcs: '% nọc trên lứa',
	treatmentVaccination: 'Điều trị/Tiêm chủng',
	toBetreated: 'Cần điều trị',
	allPigs: 'Tất cả lợn',
	boarsPigs: 'Lợn nọc',
	daysAfterEvent: 'Số ngày sau S.kiện',
	weeksAfterEvent: 'Số tuần sau S.kiện',
	weeksAfterMating: 'Số tuần sau phối',
	vaccineByLitterNumber: 'Tiêm chủng đến lứa thứ',
	showLocationOnList: 'Xem danh sách vị trí',
	calcByVaccinationPregnancyType: 'Tiêm chủng được tính toán theo',
	showAnimalsOnList: 'Xem vật nuôi theo danh sách (chu kỳ ngày)',
	expectedFarrowingLong: 'Đẻ kỳ vọng',
	dateForWeaning: 'Ngày cai sữa',
	dateForMating: 'Ngày phối',
	Blank: 'Trống',
	chooseFeedCurve: 'Chọn đường ăn cho',
	chooseReader: 'Chọn đầu đọc',
	feedCurve: 'Đường ăn',
	MoveFromPregToPregSetting: 'Di chuyển giữa các chuồng mang thai',
	MoveMatingToMatingSetting: 'Di chuyển giữa các chuồng phối',
	maxDryDays: 'Số ngày khô Max.',
	newSow: 'Nái mới',
	MatingBatchReportSetting: 'Lô phối',
	ActiveAnimalsReportSetting: 'Vật nuôi hoạt động',
	ActivePoolYoungFemale: 'Vốn vật nuôi',
	reports: 'Các báo cáo',
	report: 'Báo cáo',
	reportAvailableOn: 'Báo cáo sẵn có vào',
	AnimalNumberOnly: 'Chỉ mỗi mã vật nuôi',
	AnimalNumberPerLocation: 'Vật nuôi theo vị trí',
	howToPresentAnimals: 'Làm cách nào để hiện các vật nuôi này',
	ShowMatingBatchInLeftSide: 'Hiển thị lô phối bên phải',
	ShowFarrowingDateInRightSide: 'Xem ngày đẻ bên phải',
	RepeatAnimalNumberAtBottom: 'Lặp lại mã lợn cuối trang',
	ShowComment: 'Xem ghi chú',
	SowBoardReportSetting: 'Báo cáo nái',
	FailedToFetchNewestLocations: 'Gặp lỗi khi lấy vị trí thiết bị mới nhất',
	removeProcessEquipment: 'Gỡ bỏ thiết bị?',
	setPensAutomaticValves: 'Đặt các ô chuồng cho các van',
	setPensAutomaticStations: 'Đặt ô chuồng cho các trạm',
	processEquipment: 'Thiết bị thực thi',
	penNr: 'Mã ô chuồng',
	Valves: 'Các van',
	StationNumbers: 'Mã trạm',
	locations: 'VỊ TRÍ',
	relationProcessEquipment: 'TẠO MỐI QUAN HỆ GIỮA THIẾT BỊ VÀ SKIOLD DIGITAL',
	Units: 'Vị trí',
	locationsSkioldDigital: 'VỊ TRÍ SKIOLD Digital',
	locationsProcessEquipment: 'VỊ TRÍ THIẾT BỊ',
	createProcessEquipment: 'Tạo thiết bị',
	chooseProcessEquipment: 'Chọn thiết bị',
	chooseUnits: 'Chọn đơn vị',
	relationLocation1: 'Tạo mối quan hệ',
	relationLocation2: 'truy cập thiết bị/vị trí',
	bindProcessEquipment: 'RÀNG BUỘC THIẾT BỊ VÀO VỊ TRÍ TRÊN SKIOLD Digital',
	unsavedRegisteredData: 'Thông tin đã đăng ký không được lưu. Lưu các thay đổi?',
	processCode: 'Mã',
	AllAnimalsEsfListSetting: 'Tất cả heo ESF',
	AWR250StickReader: 'Thanh đọc AWR 250',
	AWR300StickReader: 'Thanh đọc AWR 300',
	Reader: 'Đầu đọc',
	cycleDaysShownNegative: 'Chu kỳ ngày hiển thị lùi',
	eatenToday: 'Đã ăn hôm nay',
	curve: 'Biểu đồ',
	Stations: 'Trạm',
	Yesterday: 'Hôm qua',
	DateAndTime: 'Ngày, giờ',
	FE: 'FU',
	ShortageEsfListSetting: 'Danh sách hụt',
	LastFourVisits: 'BỐN LẦN GẦN NHẤT',
	Visit0: 'Lần gần nhất',
	Visit1: 'Lần 2',
	Visit2: 'Lần 3',
	Visit3: 'Lần 4',
	Rest: 'Còn lại',
	MJEnergy1: 'MJ',
	KCALEnergy1: 'KCAL',
	FEsoFEsvEnergy1: 'FUso',
	FEsoFEsvEnergy2: 'FUsv',
	FEEnergy1: 'FU',
	FEEnergy2: 'FU',
	MJEnergy2: 'MJ',
	KCALEnergy2: 'KCAL',
	connectToUnit: 'Kết nối bluetooth',
	disonnect: 'Ngắt kết nối',
	startScan: 'Bắt đầu quét',
	bleSelect: 'Chọn',
	bleCancel: 'Hủy',
	bleOk: 'Ok',
	bleSave: 'Lưu',
	chooseCurve: 'Chọn biểu đồ',
	enterFixed: 'Nhập % cố định',
	Eat: 'Ăn',
	animalsOnListWhileDaysRationNotUnfed: 'Vật nuôi, mà chúng ăn ít hơn khẩu phần hằng ngày, bao gồm',
	TodayFE: 'FU hôm nay',
	model: 'Mẫu',
	ID: 'ID',
	bluetoothIsDisabled: 'Bluetooth chưa mở',
	YesterdayFE: 'FU hôm qua',
	Station: 'Trạm',
	restTodayFE: 'Còn hôm nay',
	eatenTodayFE: 'Đã ăn hôm nay',
	curveFE: 'Biều đồ',
	restYesterdayFE: 'Còn hôm qua',
	eatenYesterdayFE: 'Đã ăn hôm qua',
	searchBtUnits: 'Tìm thiết bị',
	enableLocation: 'Vị trí là bắt buộc để sử dụng Bluetooth Năng Lượng thấp',
	useGPS: 'Mở GPS',
	noCurveChosen: 'Chưa chọn biều đồ',
	CHOOSEREPORT: 'CHỌN BÁO CÁO',
	pReport: 'Báo cáo P',
	period: 'Chu kỳ',
	newPeriod: 'Chù kỳ mới',
	specialPeriod: 'Chu kỳ đặc biệt',
	ReMatingPercent: 'Phối lại, pct.',
	ThereOfGiltsPcs: '• Trong đó hậu bị, pcs.',
	AlivePerLitterPcs: 'Còn sống mổi lứa, pcs',
	DeadPerLitter: 'Chết mỗi lứa, pcs',
	FarrowingPercent: 'Tỷ lệ đẻ',
	AvgPregnantDaysPerLitter: 'T.bình số ngày mang thai mỗi lứa',
	ThereOfFirstLitterPcs: '• Trong đó lứa đầu, pcs.',
	FirstLitterPercent: '• Lứa đầu, p.trăm',
	NursingSowPercent: '• P.trăm. Nái nuôi con',
	DeadTillWeaningPercent: 'Chết đến khi cai sữa, p.trăm',
	KeyNumbers: 'báo cáo P, Số Key',
	clearPeriods: 'Xóa chu kỳ',
	WeanedPerWeaningPcs: 'Cai sữa mỗi đợt cai, pcs',
	PReportFor: 'Báo cáo P cho:',
	invalidDataIfPrivateSlaughter: 'Không thể lập báo cáo P, bời vì chưa nhập trọng lượng lợn xuất bán',
	YearSowPcs: 'Nái mỗi năm',
	WeanedPigletsPcs: 'Lợn cai sữa',
	AvgWeightPerPiglet: 'T.lượng T.bình cai sữa',
	PercentDeadSowGilts: 'P.trăm nái chết trên nái năm',
	PeriodFeedDaysSowAndGilt: 'Ngày cho ăn trong kỳ nái/hậu bị',
	PeriodFeedDaysYoungAnimal: 'Ngày cho ăn trong kỳ hậu bị cái',
	ProductionDays: 'Ngày sản xuất',
	NonProductiveDays: 'Ngày không sản xuất',
	CorrectedDays: 'Ngày hiệu đính',
	PeriodYearSowPcs: 'Chu kỳ nái năm',
	ProductionScale: 'Quy mô sản xuất',
	SowFoodFeYearSow: 'Cám nái FU/nái năm',
	ThereOfYoungAnimalFood: '• Trong đó cám hậu bị cái, FU/nái năm',
	FePerYearSowPerDay: 'FU nái năm mỗi ngày',
	FePerYearYoungFemalePerDay: 'FU mỗi hậu bị cái mổi ngày',
	WasteDaysPerDay: 'Số ngày không sản xuất mỗi lứa',
	ThereOfAdjustedDays: '• Trong đó số hiệu chỉnh là',
	LitterPerYearSowPcs: 'Lứa mổi nái năm',
	WeanedPigletsPerYearSowPcs: 'Số cai sữa nái năm',
	ProducedLitters: 'Lứa sản xuất',
	Reproduction: 'Tái sản xuất',
	SowAndGiltPcs: 'Nái/hậu bị, pcs',
	SowAndGiltEntrance: '• Nhập trại, pcs.',
	SowAndGiltDepartured: '• Xuất đi, pcs.',
	SowAndGiltFeedDaysPcs: 'Số ngày cho ăn nái/hậu bị, pcs',
	SowAndGiltPregnantDays: 'Số ngày mang thai nái/hậu bị, pcs',
	YoungFemalePcs: 'Hậu bị cái, pcs',
	YoungFemaleEntrance: '• Nhập trại, pcs.',
	YoungFemaleEntranceToGilt: '• Chuyển vào, pcs.',
	YoungFemaleDepartured: '• Xuất đi, pcs.',
	YoungFemaleFeedDaysPcs: 'Số ngày cho ăn hậu bị cái, pcs.',
	BoarPcs: 'Nọc, pcs.',
	BoarEntrance: '• Nhập trại, pcs.',
	BoarDepartured: '• Xuất đi, pcs.',
	PigletPcs: 'Lợn con, pcs',
	Departured: 'Xuất đi, pcs.',
	FeedUsage: 'Cám tiêu thụ',
	kgTotal: 'Tổng kg',
	feTotal: 'Tổng FU',
	fePercent: 'Phần trăm FU',
	kgPercent: 'Phần trăm KG',
	sowFoodTotal: 'Tổng thức ăn nái',
	youngFemaleFoodTotal: 'Tổng thức ăn hậu bị cái',
	pigletFoodTotal: 'Tồng thức ăn lợn con',
	allInAllFood: 'Cho tất cả thức ăn dùng',
	SubResult: 'Kết quả phụ',
	SubResults: 'Các kết quả phụ',
	deadPigletsEvent: 'Lợn con chết',
	sowCount: 'S.lượng nái',
	totalDeadPiglets: 'Lợn con chết',
	avgDeadPerSow: 'T.bình chết mỗi nái',
	deadPigs: 'Lợn chết',
	printFeedingReport: 'In báo cáo lượng ăn',
	feedingReport: 'Báo cáo lượng ăn',
	maxBatchesIs27: 'Số lứa tối đa thể hiện là 27',
	resendEsfList: 'Trạm ESF Gensend',
	resend: 'Gửi lại',
	SowEvent: 'Sự kiện',
	MoveEvent: 'Di chuyển',
	Entrance: 'Nhập vào',
	RequireSelectionOfPercentagesRed: 'Khoảng thởi gian yêu cầu khi nào vật nuôi được sơn đỏ',
	RequireSelectionOfPercentagesGreen:
		'Khoảng thởi gian yêu cầu khi nào vật nuôi được xịt xanh, cần lớn hơn khoảng xịt sơn đỏ',
	FeedingESFReportSetting: 'Cài đặt báo cáo lượng ăn',
	feedingESFSetupGreenNameText: 'Vật nuôi được sơn xanh khi:',
	feedingESFSetupRedNameText: 'Vật nuôi được sơn xanh khi:',
	dayRationNotEaten: '% khẩu phần bỏ ăn',
	dayRationEaten: '% khẩu phần đã ăn',
	salePoolYoungFemale: 'Vốn thu hậu bị cái',
	poolYoungFemale: 'Vốn hậu bị cái',
	Climatepigs: 'Cai sữa',
	sold: 'Đã bán',
	PigletWeanedEvent: 'Đã cai sữa',
	PigletDepartureSoldEvent: 'Đã bán',
	PigletMovedEvent: 'Đã cai sữa',
	WeanedOnStable: 'Cai sữa trên chuồng',
	SellWeanedPigs: 'Bán lợn cai sữa',
	DistributeWeanedPigs: 'Phân phối lợn cai sữa',
	BalanceWeanedPigs: 'Cân đối lợn cai sữa',
	balanceWeanedPigs: 'Cân đối lợn cai sữa',
	ToBeDistributed: 'Được phân phối',
	ToDistributed: 'Đã phân phối',
	PigletMovedEventDash: 'Đã cai sữa',
	numOfPigs: 'S.lượng lợn',
	entryWeight: 'Nạp vào',
	weightToday: 'Hôm nay',
	days: 'Số ngày',
	dailyGain: 'Tăng trọng ngày',
	feedPerPigToday: 'Hôm nay',
	feedPerPigCurve: 'Biểu đồ',
	feedPerPigAdjust: 'Điều chỉnh',
	deadLastSevenDays: 'Chết/tiêu hủy',
	treatedLastSevenDays: 'Đã trị',
	lastSevenDays: '7 ngày gần nhất',
	expectedDeliveryDate: 'Tuần giao kỳ vọng',
	expectedDeliveryAdjust: 'Hiệu chỉnh',
	feedPerPig: 'Cám mỗi lợn',
	expectedDelivery: 'Kỳ vọng giao hàng',
	lastBalanced: 'Cân đối gần nhất',
	deadLast7DaysTitle: 'Bổ sung chết/ tiêu hùy',
	treatedLast7DaysTitle: 'Đã điều trị 7 ngày qua',
	enteredDashboard: 'Đã nhập vào',
	departedDashboard: 'Đã xuất',
	dateForBalance: 'Ngày cân đối',
	gpeDashboardMoved: 'Đã chuyển',
	identifierToFinisher: 'Đến lợn thịt',
	identifierToWeaner: 'Đến cai sữa',
	identifierToSow: 'Đến nái',
	identifierFromFinisher: 'Từ lợn thịt',
	identifierFromWeaner: 'Từ cai sữa',
	identifierFromSow: 'Từ nái',
	enteredSince: 'Nhập vào từ',
	departedSince: 'Xuất từ',
	GeneralSettings: 'Cài đặt chung',
	General: 'Tổng quan',
	Generalt: 'Tổng quan',
	RegisterAliveFemales: 'Đăng ký lợn cái còn sống',
	RegisterAliveMales: 'Đăng ký lợn đực sống',
	WeanedPigsToLocation: 'Lợn cai sữa đến vị trí',
	SetupEvent: 'Sự kiện',
	SetupRegistration: 'Đăng ký',
	TreatmentRetentionTime: 'Điều trị - Thời gian lưu',
	HandleRetentionTime: 'Xử lý thời gian lưu khi chuyển',
	IsLettersUsedForAnimal: 'Các chữ cái được dùng cho số vật nuôi',
	PigDeliveryToPrivateSlaughterhouse: 'Giao lợn đến lò mổ tư nhân',
	SowRegistrations: 'Đăng ký nái',
	Communication: 'Giao tiếp',
	SendDataToDistriwin: 'Chia sẻ quy định trọng lượng với Distrawin',
	EnergyIndicatedBy: 'Năng lượng được xác định bởi',
	Regulations: 'Quy định',
	WeightRegulation: 'Quy định trọng lượng',
	EditRegulation: 'Sửa quy định',
	createRelation: 'Tạo quan hệ',
	VALIDATION_EqualDistriwinAndSkioldEnergy: 'Năng lượng trên Distriwin vói SKIOLD digital cần phải khớp nhau',
	FEkg: 'FU/kg',
	MJkg: 'MJ/kg',
	KCALkg: 'KCAL/kg',
	FEsoFEsvkg: 'FUso/kg',
	FEsvkg: 'FUsv/kg',
	EnergyKg: '{{area}}/kg',
	FETotal: 'Tổng FU',
	KCALTotal: 'Tổng KCAL',
	MJTotal: 'Tổng MJ',
	FEsoFEsvTotal: 'Tổng FU',
	FEPct: 'Phần trăm FU',
	KCALPct: 'Phần trăm KCAL',
	MJPct: 'Phần trăm MJ',
	FEsoFEsvPct: 'Phần trăm FU',
};
