import { PayloadAction, createReducer, createSlice } from '@reduxjs/toolkit';
import { SkioldFetchState } from './types';

const initialState: SkioldFetchState = {};

// const skioldFetchReducer = createReducer(initialState, builder => {});

// export default skioldFetchReducer;

export const skioldFetchSlice = createSlice({
	name: 'skioldFetch',
	initialState,
	reducers: {
		setFromDate: (state, action: PayloadAction<Date>) => {
			state.fromDate = action.payload;
		},
		setToDate: (state, action: PayloadAction<Date>) => {
			state.toDate = action.payload;
		},
		setBreed: (state, action: PayloadAction<string>) => {
			state.selectedBreed = action.payload;
		},
	},
});

// eslint-disable-next-line
export const { setFromDate, setToDate,setBreed } = skioldFetchSlice.actions;

export default skioldFetchSlice.reducer;
