import { default as ObjectID } from 'bson-objectid';
import { Dispatch } from 'redux';
import { DrugType, IDrugType } from 'shared/api/api';
import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import { StoreContainer } from 'shared/state/store-container';
import * as Action from './actions';

export function SaveDrugType(drugType: IDrugType) {
	const state = StoreContainer.getState();
	const prevEntity = state.drugType.entities.find(dt => dt.id === drugType.id);
	if (!drugType.id) {
		drugType.id = new ObjectID().toHexString();
	}
	return AsyncOperationBuilder2(
		Action.saveDrugType,
		client => client.drugType_Post(DrugType.fromJS(drugType)),
		DrugType.fromJS(drugType),
		prevEntity,
	);
}

export function GetSyncData() {
	const state = StoreContainer.getState();
	const lastSyncDate = state.drugType.lastSyncDate;

	if (state.drugType.syncInProgress) {
		return (dispatch: Dispatch<any>) => {
			return Promise.resolve();
		};
	}
	return AsyncOperationBuilder2(Action.getSyncData, client => client.drugType_Sync(lastSyncDate), {
		lastSyncDate,
	});
}
