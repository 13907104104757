import React, { FC } from 'react';
import { Chart, ChartData, ChartDataset, ChartOptions, ChartType, CommonElementOptions } from 'chart.js';
import { Chart as PrimeChart } from 'primereact/chart';

type ChartPointDataType = number | string | Date;

export interface ChartPoint {
	x?: ChartPointDataType;
	y?: ChartPointDataType;
	r?: number;
	t?: ChartPointDataType;
}

type LineColor = CommonElementOptions['backgroundColor'];
type AreaColor = LineColor;

interface Props {
	lineColor?: LineColor;
	lineAreaColor?: AreaColor;
	datasetLabel?: string;
	datasetScaleId?: string;
	options?: ChartOptions;
	height?: string;
	width?: string;
	data: number[] | ChartPoint[];
	extraDataSets?: ChartDataset<ChartType, number[] | ChartPoint[]>[];
	chartRef?: React.LegacyRef<PrimeChart>;
}

export const Graph: FC<Props> = React.memo(props => {
	const options = getChartOptions(props.options);

	const data: ChartData<ChartType, number[] | ChartPoint[]> = {
		datasets: [
			{
				label: props.datasetLabel,
				data: props.data,
				backgroundColor: props.lineAreaColor,
				borderColor: props.lineColor,
				pointBackgroundColor: props.lineColor,
				fill: false,
				yAxisID: props.datasetScaleId,
			},
		],
	};

	if (props.extraDataSets) {
		for (const dataset of props.extraDataSets) {
			data.datasets.push({ ...dataset });
		}
	}

	return (
		<PrimeChart
			type={'line'}
			data={data}
			height={props.height ?? '250px'}
			width={props.width ?? ''}
			options={options}
			ref={props.chartRef}
			id={'thisChart'}
		/>
	);
});

const mergeDefaultAndCustomOptions = (customOptions?: ChartOptions) => {
	let mergedOptions = getDefaultOptionsCopy();

	for (const key in customOptions) {
		if (customOptions.hasOwnProperty(key)) {
			if (mergedOptions[key]) {
				mergedOptions[key] = Object.assign(mergedOptions[key], customOptions[key]);
			} else {
				mergedOptions[key] = customOptions[key];
			}
		}
	}

	return mergedOptions;
};

const getChartOptions = (customOptions?: ChartOptions) => {
	return mergeDefaultAndCustomOptions(customOptions);
};

const getDefaultOptionsCopy = () => {
	// Source: https://www.chartjs.org/docs/latest/axes/cartesian/linear.html#tick-configuration-options
	const chartOptions: ChartOptions = {
		responsive: true,
		maintainAspectRatio: false,
		layout: {
			// Avoid last point being cut off by the card. The bigger the point radius, the bigger the padding
			padding: {
				right: 10,
			},
		},
		animation: {
			duration: 0,
		},
		plugins: {
			legend: {
				display: false,
			},
		},
		scales: {
			x: {
				title: {
					display: true,

					text: 'title',
				},
				grid: {
					display: false,
				},
			},
		},
	};

	return chartOptions;
};
