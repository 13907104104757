import React, { FC, useCallback, useEffect } from 'react';
import { ViewWeb } from '../../components/utils/web-view';
import { useDispatch, useSelector } from 'react-redux';
import { WebAppState } from '../../../state/store.web';
import PageContainer from '../../components/page-container/page-container';
import './module-licences.scss';
import { GetSite, SaveSiteAsync } from '../../../../shared/state/ducks/site/operations';
import { localized } from '../../../../shared/state/i18n/i18n';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { SkioldCheckbox } from '../../components/skiold-components/skiold-checkbox/skiold-checkbox';
import { ILicence, LicenceCategory } from '../../../../shared/api/api';
import { LoadAllLicences, LoadProfileModules } from '../../../../shared/state/ducks/licences/operations';
import { Heading } from '../../components/utils/heading';
import { nucleusManagementLicences } from '../nucleus-management/settings/nucleus-management-helper';
import { deepCopy2 } from 'shared/helpers/general-helpers';
import { FetchSiteInformation } from 'shared/helpers/site-helper';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';

export const ModuleLicences: FC = React.memo(() => {
	const dispatch = useDispatch();

	const siteId = useSelector((state: WebAppState) => state.profile.active!.siteId);
	const licences = useSelector((state: WebAppState) => state.licences.licences);
	const activeSite = useSelector((state: WebAppState) => state.site.site);

	useEffect(() => {
		if (siteId === undefined) {
			return;
		}

		dispatch(GetSite(siteId));
		dispatch(LoadAllLicences());
	}, [siteId, dispatch]);

	useEffect(() => {
		if (activeSite === undefined) {
			return;
		}

		// Update the users available modules
		dispatch(LoadProfileModules());
	}, [activeSite, dispatch]);

	const isChecked = useCallback(
		(licence: ILicence) => {
			return activeSite.activeLicences?.includes(licence.id as string);
		},
		[activeSite],
	);

	const fetchSiteInfo = useCallback(FetchSiteInformation, []);

	const handleCheck = useCallback(
		(checked: boolean, licenceId: string) => {
			let copyActiveSite = deepCopy2(activeSite);
			// Clear NucleusManagement licences if one is checked
			if (nucleusManagementLicences.includes(licenceId)) {
				copyActiveSite.activeLicences = copyActiveSite.activeLicences?.filter(
					l => !nucleusManagementLicences.includes(l),
				);
			}
			if (checked) {
				copyActiveSite.activeLicences?.push(licenceId);
			} else {
				const index = copyActiveSite.activeLicences?.findIndex(m => m === licenceId);
				if (index !== -1) {
					copyActiveSite.activeLicences?.splice(index as number, 1);
				}
			}
			dispatch(SaveSiteAsync(copyActiveSite));
		},
		[activeSite, dispatch],
	);

	if (siteId === undefined) {
		return (
			<PageContainer>
				<ViewWeb className={'error-container'}>
					<ViewWeb className={'error-text'}>
						<h1>ERROR:</h1>
						The user profile is not associated with a site.
					</ViewWeb>
				</ViewWeb>
			</PageContainer>
		);
	}
	if (activeSite === undefined) {
		return <ViewWeb />;
	}
	return (
		<PageContainer>
			<Heading text={localized('ADMIN_MODULE_LICENCES')} />
			<ViewWeb className="module-licences-container">
				<SkioldButton className='basic-margin' title={localized('FetchSiteInformation')} onPress={fetchSiteInfo} />
				{(Object.keys(LicenceCategory) as Array<keyof typeof LicenceCategory>).map((key, index) => (
					<ViewWeb key={key}>
						<ViewWeb
							key={module.id}
							className={`row-item-outer-container ${
								index % 2 === 0 ? 'white-background' : 'light-grey-background'
							}`}
						>
							<TextWeb className={'category-header'}>{localized(key)}</TextWeb>
							{licences.map(licence => {
								if (licence.licenceCategory === key) {
									return (
										<ViewWeb key={licence.id} className={'row-item-inner-container'}>
											<TextWeb className={'row-item first'}>
												- {localized(licence.name as string)}
											</TextWeb>
											<ViewWeb className={'row-checkbox-container'}>
												<SkioldCheckbox
													isChecked={isChecked(licence)}
													onClick={checked => handleCheck(checked, licence.id as string)}
												/>
											</ViewWeb>
										</ViewWeb>
									);
								}
							})}
						</ViewWeb>
					</ViewWeb>
				))}
			</ViewWeb>
		</PageContainer>
	);
});
