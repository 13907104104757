import React from 'react';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { GetSyncData as TreatmentDefinitionGetSyncData } from 'shared/state/ducks/treatment-definitions/operations';
import { GetSyncData as TreatmentPlanGetSyncData } from 'shared/state/ducks/treatment-plan/operations';
import { GetSyncData as TreatmentGetSyncData } from 'shared/state/ducks/treatment/operations';
import { WebAppState } from "web/state/store.web";
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { findRetentionTime } from 'shared/helpers/treatment-helper/treatment-helper';

export interface PropsFromParent {
    stemAnimalId: string;
}

const mapStateToProps = (state: WebAppState) => {
    return {
        treatments: state.treatments.entities,
        treatmentPlans: state.treatmentPlans.entities,
        treatmentDefinitions: state.treatmentDefinitions.entities,
        siteId: state.profile.active!.siteId
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
    treatmentDefinitionGetSyncData: () => TreatmentDefinitionGetSyncData()(dispatch),
    treatmentPlanGetSyncData: () => TreatmentPlanGetSyncData()(dispatch),
    treatmentGetSyncData: () => TreatmentGetSyncData()(dispatch)
});

interface State {}
type GetRetentionTimeProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> &
    PropsFromParent;
class GetRetentionTime extends React.PureComponent<GetRetentionTimeProps, State> {
    constructor(props: GetRetentionTimeProps) {
        super(props);

        this.state = {};
    }

    public componentDidMount() {
        this.props.treatmentDefinitionGetSyncData();
        this.props.treatmentGetSyncData();
        this.props.treatmentPlanGetSyncData();
    }

    public render() {
        return <TextWeb>{findRetentionTime(this.props.stemAnimalId)}</TextWeb>;
    }
}

export default (
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(GetRetentionTime)
);
