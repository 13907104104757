import React from 'react';
import isEqual from 'react-fast-compare';
import { connect } from 'react-redux';
import { AnimalType, GrowthPigsEvent, ISection, LocationType } from 'shared/api/api';
import { setTwoDecimalsAsNumber } from 'shared/helpers/general-helpers';
import {
	generateGrowthPigEventDataForSectionId,
	generateLocationOptionsForGrowthPigEvents,
	handleGrowthpigsDeparturedFromSection,
	handleGrowthpigsRestFromSection,
	handleGrowthpigsWeightFromSection,
	validateGrowthPigsMoveEvent,
} from 'shared/helpers/growth-pigs-helper/growth-pig-event-function-helper';
import {
	getGrowthPigMoveEventInitialState,
	getMaxNumberForAmountForGrowthPigEvent,
	getMoveAllTreatedEnumOptions,
	GrowthPigDepartureFromSectionRow,
	GrowthPigEventsMapDispatchToProps,
	GrowthPigMoveProps,
	GrowthPigMovePropsMapStateToProps,
	GrowthPigMoveState,
	MoveAllTreatedEnum,
	UpsertGrowthPigEventAndClose,
} from 'shared/helpers/growth-pigs-helper/growth-pig-event-helper';
import { FindHiddenPenOnSection, getBuildingBySectionId } from 'shared/helpers/location-helper';
import { localized, localizedInterpolation } from 'shared/state/i18n/i18n';
import SkioldFormPenPicker from 'web/view/components/location/location-picker/skiold-form-pen-picker';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldFormsWrapper } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { ViewWeb } from 'web/view/components/utils/web-view';
import SkioldStableSectionPicker from '../../location/location-picker/skiold-stable-section-picker';
import { showAlert, ShowConfirmAlert, showCustomKeyValueAlert } from '../../skiold-alert/skiold-alert';
import { SkioldDatePicker } from '../../skiold-components/skiold-date-picker/skiold-date-picker';
import { SkioldFormDecimalInput } from '../../skiold-components/skiold-decimal-input/skiold-form-decimal-input';
import { SkioldFormIntegerInput } from '../../skiold-components/skiold-integer-input/skiold-form-integer-input';
import { SkioldModal } from '../../skiold-components/skiold-modal/skiold-modal';
import GrowthPigProductionTypePicker from '../../skiold-components/skiold-production-type-picker/skiold-production-type-picker';
import { WhiteText } from '../../Text/white-text';
import { GpeDepartureCount } from './growth-pig-departure-count';
import { GrowthPigDepartureFromSectionTable } from './growth-pig-departure-move-table';
import GrowthPigDistributeMoveEvent from './growth-pig-distribute-move-event';
import './growth-pig-distribute.scss';
import { GrowthPigDistributeCountSold } from './growth-pig-distribute-count-sold';
import { Option } from 'react-dropdown';

export class GrowthPigMoveEventComponent extends React.PureComponent<GrowthPigMoveProps, GrowthPigMoveState> {
	constructor(props: GrowthPigMoveProps) {
		super(props);
		const growthPigEventToEdit = this.props.growthPigsEvent;
		this.state = getGrowthPigMoveEventInitialState(
			growthPigEventToEdit,
			props,
			this.props.growthPigsEvent && this.props.growthPigsEvent.fromProductionType
				? this.props.growthPigsEvent.fromProductionType
				: this.props.productionType,
			new Date(),
		);
	}

	public componentDidUpdate(prevProps: GrowthPigMoveProps) {
		if (!isEqual(prevProps.growthPigsEvents, this.props.growthPigsEvents) && this.state.fromProductionType) {
			this.setState({
				filteredLocation: generateLocationOptionsForGrowthPigEvents(
					this.props.growthPigsEvents,
					this.props.locations,
					this.props.productionType,
				),
			});
		}
		if (!isEqual(prevProps.productionType, this.props.productionType)) {
			const growthPigEventToEdit = this.props.growthPigsEvent;
			this.setState(
				getGrowthPigMoveEventInitialState(
					growthPigEventToEdit,
					this.props,
					this.props.growthPigsEvent && this.props.growthPigsEvent.fromProductionType
						? this.props.growthPigsEvent.fromProductionType
						: this.props.productionType,
					this.state.date,
					this.state,
				),
			);
		}

		// Set gpe departure count
		if (this.state.data) {
			let total = 0;
			let departured = 0;
			this.state.data.forEach(element => {
				total += element.amount ? element.amount : 0;
				departured += element.departedFromSection ? element.departedFromSection : 0;
			});
			this.props.setGpeDepartureCount({ totalCount: total, departureCount: departured });
		}
	}

	public render() {
		return (
			<ViewWeb className="growth-pig-distribute">
				<ViewWeb className="distribute-container">
					{this.state.growthPigEvent ? (
						<SkioldFormsWrapper
							formRows={this.getFormRows()}
							containerClassName="forms-wrapper-container"
						/>
					) : (
						this.generateRegisterHeader()
					)}
					{!this.state.growthPigEvent && (
						<GrowthPigDepartureFromSectionTable
							usesPen={this.state.fromSection && this.state.fromSection.usePens}
							departedFromSectionText={localized('moved')}
							onDepartureFromSectionNumberChanged={this.onMoveNumberChanged}
							onDepartureFromSectionAvgWeightChanged={this.onDepartureWeightChanged}
							onRestFromSectionNumberChanged={this.onRestNumberChanged}
							data={this.state.data}
							handleRetentionTime={this.props.handleRetentionTime}
						/>
					)}
				</ViewWeb>
				{this.state.date &&
					this.state.toSection &&
					this.state.toSection.id &&
					this.state.fromSection &&
					this.state.fromSection.id &&
					this.state.toProductionType &&
					this.state.fromProductionType && (
						<SkioldModal
							shouldCloseOnOverlayClick={true}
							padding="0"
							isOpen={this.state.modalOpen}
							close={this.closeModal}
							justify-content="flex-end"
							max-width="calc(100% - 220px)"
						>
							<GrowthPigDistributeMoveEvent
								closeModal={this.closeModal}
								fromSectionId={this.state.fromSection.id}
								date={this.state.date}
								data={this.state.dataToModalForward}
								toSectionId={this.state.toSection.id}
								fromProductionType={
									this.state.fromProductionType === AnimalType.FRATS
										? this.props.productionType
										: this.state.fromProductionType
								}
								toProductionType={
									this.state.toProductionType === AnimalType.FRATS
										? this.props.productionType
										: this.state.toProductionType
								}
							/>
						</SkioldModal>
					)}
				{this.state.data &&
					this.state.fromSection &&
					this.state.fromSection.id &&
					this.state.fromProductionType && (
						<SkioldModal
							overflowNone={true}
							shouldCloseOnOverlayClick={true}
							padding="0"
							isOpen={this.state.distributeModalOpen}
							close={this.closeDistributeModal}
							justify-content="flex-end"
							max-width="calc(100% - 220px)"
						>
							<GrowthPigDistributeCountSold
								toPen={this.state.distribiteToPen}
								fromPen={this.state.distributeFromPen}
								amount={this.state.distributeCount}
								penRowData={this.state.data}
								setCountCallback={this.setCountOnPens}
								sectionId={this.state.fromSection.id}
								onClose={this.closeDistributeModal}
								headerTranslation={'DistributeMove'}
							/>
						</SkioldModal>
					)}
			</ViewWeb>
		);
	}
	private openDistributeModal = () => {
		this.setState({ distributeModalOpen: true });
	};

	private closeDistributeModal = () => {
		this.setState({ distributeModalOpen: false });
	};

	private setCountOnPens = (
		keyValue: { [key: string]: number },
		fromPen?: Option,
		toPen?: Option,
		amount?: number,
	) => {
		let tmpData = [...this.state.data];
		tmpData.forEach((item, i) => {
			if (item.penId) {
				const value = keyValue[item.penId];

				if (value !== undefined) {
					tmpData[i] = { ...item, departedFromSection: value, rest: item.amount && item.amount - value };
				} else {
					tmpData[i] = { ...item, departedFromSection: undefined, rest: item.amount && item.amount };
				}
			}
		});
		this.setState({ data: tmpData, distribiteToPen: toPen, distributeCount: amount, distributeFromPen: fromPen });
	};

	private closeModal = (saved?: boolean) => {
		if (saved) {
			this.setState(
				getGrowthPigMoveEventInitialState(
					this.props.growthPigsEvent,
					this.props,
					this.state.fromProductionType,
					new Date(),
					this.state,
				),
			);
		} else {
			this.setState({ modalOpen: false });
		}
	};

	private generateRegisterHeader(): React.ReactNode {
		const locationType =
			this.state.toProductionType === AnimalType.FRATS
				? this.props.productionType === AnimalType.Weaner
					? LocationType.Weaners
					: LocationType.Finisher
				: undefined;
		return (
			<ViewWeb className="marginBottomTen">
				<ViewWeb className="flexDirectionRow align-items-center">
					<ViewWeb className="flexDirectionRow width275justifyContentSpaceBetween">
						<WhiteText> {localized('Date')}</WhiteText>
						<SkioldDatePicker
							onDateChanged={this.OnDateChanged}
							selectedDate={this.state.date}
							theme={'light'}
							color={'lightGrey'}
							backwardDateSetEndTime={true}
						/>
						<GpeDepartureCount />
					</ViewWeb>
					{this.renderButtons()}
				</ViewWeb>
				<ViewWeb className="flexDirectionRow marginTopTwenty">
					<WhiteText className="alignSelfCenterWidth110"> {localized('fromProductionType')} </WhiteText>
					<GrowthPigProductionTypePicker
						dontSetToFrats={
							!!(this.state.fromSection && this.state.fromSection.animalType !== AnimalType.FRATS)
						}
						theme="light"
						onProductionTypeChanged={this.onFromProductionTypeChanged}
						disableYoungFemale={true}
						usedInsideForm={true}
						productionTypeLimitation={undefined}
						disabled={false}
						productionType={this.state.fromProductionType}
					/>
					<WhiteText className="alignSelfCenterWidth110 marginLeftTwenty">
						{' '}
						{localized('FromLocation')}{' '}
					</WhiteText>
					<SkioldStableSectionPicker
						usedInsideForm={true}
						locationType={locationType}
						containerClassName="minWidth300"
						theme="light"
						AnimalType={this.state.fromProductionType}
						disabled={false}
						sectionId={this.state.fromSection ? this.state.fromSection.id : undefined}
						filteredLocations={this.state.filteredLocation}
						onStableSectionSelected={this.onStableSectionChanged}
					/>
				</ViewWeb>
				<ViewWeb className="flexDirectionRow marginTopTwenty">
					<WhiteText className="alignSelfCenterWidth110"> {localized('toProductionType')} </WhiteText>
					<GrowthPigProductionTypePicker
						theme="light"
						onProductionTypeChanged={this.onToProductionTypeChanged}
						disableYoungFemale={false}
						usedInsideForm={true}
						productionTypeLimitation={this.state.fromProductionType}
						allowSetToDefaultFinisher={true}
						disabled={false}
						productionType={this.state.toProductionType}
					/>

					<WhiteText className="alignSelfCenterWidth110 marginLeftTwenty">
						{localized('ToLocation')}
					</WhiteText>
					<SkioldStableSectionPicker
						sectionId={this.state.toSection && this.state.toSection.id}
						theme="light"
						containerClassName="minWidth300"
						filteredLocations={this.getFilteredToLocations()}
						allowYoungFemale={true}
						usedInsideForm={true}
						locationType={locationType}
						AnimalType={this.state.toProductionType}
						onStableSectionSelected={this.onStableToSectionChanged}
						isPigProduction={true}
					/>
				</ViewWeb>
			</ViewWeb>
		);
	}

	private getPenPickerRow(name: string, disabled?: boolean, sectionId?: string, penId?: string): FormRow {
		return {
			name: name,
			component: (
				<SkioldFormPenPicker
					usedInsideForm={true}
					disabled={disabled}
					onPenSelected={disabled ? this.onFromPenChanged : this.onToPenChanged}
					sectionId={sectionId}
					penId={penId}
				/>
			),
		};
	}

	private onFromPenChanged = () => {};

	private onToPenChanged = (penId?: string) => {
		if (this.state.growthPigEvent) {
			const gpe = { ...this.state.growthPigEvent } as GrowthPigsEvent;
			gpe.toPenId = penId;
			this.setState({ growthPigEvent: gpe, toPenId: penId });
		}
	};

	private onMoveNumberChanged = (number?: number, itemFromParent?: GrowthPigDepartureFromSectionRow) => {
		if (itemFromParent && itemFromParent.editable) {
			const datasToModify = [...this.state.data];
			handleGrowthpigsDeparturedFromSection(datasToModify, itemFromParent, number);

			this.setState({ data: datasToModify });
		}
	};

	private onRestNumberChanged = (number?: number, itemFromParent?: GrowthPigDepartureFromSectionRow) => {
		if (itemFromParent && itemFromParent.editable) {
			const datasToModify = [...this.state.data];
			handleGrowthpigsRestFromSection(datasToModify, itemFromParent, number);

			this.setState({ data: datasToModify });
		}
	};

	private onDepartureWeightChanged = (number?: number, itemFromParent?: GrowthPigDepartureFromSectionRow) => {
		const datasToModify = [...this.state.data];
		handleGrowthpigsWeightFromSection(datasToModify, itemFromParent, number);
		this.setState({ data: datasToModify });
	};

	private onFromProductionTypeChanged = (productionType: AnimalType) => {
		const filteredLocations = generateLocationOptionsForGrowthPigEvents(
			this.props.growthPigsEvents,
			this.props.locations,
			this.props.productionType,
		);
		if (this.state.fromSection && this.state.fromSection.id) {
			const data = generateGrowthPigEventDataForSectionId(
				this.state.fromSection,
				this.props.growthPigsEvents,
				this.props.locations,
				this.props.productionType,
				productionType,
				this.state.date ? this.state.date : new Date(),
			);
			if (data.length === 0) {
				this.setState({
					fromProductionType: productionType,
					filteredLocation: filteredLocations,
					fromSection: undefined,
					data,
				});
			} else {
				if (productionType !== this.state.fromProductionType) {
					this.setState({
						fromProductionType: productionType,
						filteredLocation: filteredLocations,
						data,
						fromSection: undefined,
					});
				} else {
					this.setState({ fromProductionType: productionType, filteredLocation: filteredLocations, data });
				}
			}
		} else {
			this.setState({ fromProductionType: productionType, filteredLocation: filteredLocations });
		}
	};

	private getFromLocationRow = (): FormRow => {
		return {
			name: localized('FromLocation'),
			component: (
				<SkioldStableSectionPicker
					usedInsideForm={true}
					locationType={
						this.state.fromProductionType === AnimalType.FRATS
							? this.props.productionType === AnimalType.Weaner
								? LocationType.Weaners
								: LocationType.Finisher
							: undefined
					}
					AnimalType={this.state.fromProductionType}
					disabled={!!this.state.growthPigEvent}
					sectionId={this.state.fromSection ? this.state.fromSection.id : undefined}
					filteredLocations={this.state.filteredLocation}
					onStableSectionSelected={this.onStableSectionChanged}
				/>
			),
		};
	};

	private getToLocationRow = (): FormRow => {
		let filteredLocations = this.getFilteredToLocations();

		return {
			name: localized('ToLocation'),
			component: (
				<SkioldStableSectionPicker
					sectionId={this.state.toSection && this.state.toSection.id}
					filteredLocations={filteredLocations}
					allowYoungFemale={true}
					usedInsideForm={true}
					locationType={
						this.state.toProductionType === AnimalType.FRATS
							? this.props.productionType === AnimalType.Weaner ||
							  this.state.fromProductionType === AnimalType.Piglet
								? LocationType.Weaners
								: LocationType.Finisher
							: undefined
					}
					AnimalType={this.state.toProductionType}
					onStableSectionSelected={this.onStableToSectionFormChanged}
					isPigProduction={true}
				/>
			),
		};
	};

	private getFilteredToLocations() {
		let filteredLocations = { ...this.props.locations };
		if (this.state.fromSection) {
			filteredLocations.sections = filteredLocations.sections.filter(s => s.id !== this.state.fromSection);
		}
		return filteredLocations;
	}

	public getProductionRow(isTo: boolean): FormRow {
		return {
			name: isTo ? localized('toProductionType') : localized('fromProductionType'),
			component: (
				<GrowthPigProductionTypePicker
					dontSetToFrats={
						isTo
							? !!(this.state.toSection && this.state.toSection.animalType !== AnimalType.FRATS)
							: !!(this.state.fromSection && this.state.fromSection.animalType !== AnimalType.FRATS)
					}
					onProductionTypeChanged={isTo ? this.onToProductionTypeChanged : this.onFromProductionTypeChanged}
					disableYoungFemale={!isTo}
					usedInsideForm={true}
					productionTypeLimitation={isTo ? this.state.fromProductionType : undefined}
					disabled={!!(!isTo && this.state.growthPigEvent)}
					productionType={isTo ? this.state.toProductionType : this.state.fromProductionType}
				/>
			),
		};
	}

	private getIntergerRow = (): FormRow => {
		let sum = getMaxNumberForAmountForGrowthPigEvent(
			this.state.data,
			this.props.growthPigsEvent!.fromPenId,
			this.props.growthPigsEvent,
		);
		return {
			name: localized('Count'),
			component: (
				<SkioldFormIntegerInput
					text={this.state.growthPigEvent ? this.state.growthPigEvent.amount : 0}
					maxNumber={sum}
					itemFromParent={'amount'}
					onChangeNumber={this.onGrowthPigEventAmountChanged}
				/>
			),
		};
	};

	private getAvgWeightRow = (): FormRow => {
		return {
			name: localized('AvgWeight'),
			component: (
				<SkioldFormDecimalInput
					text={
						this.state.growthPigEvent &&
						this.state.growthPigEvent.amount &&
						this.state.growthPigEvent.totalWeight
							? setTwoDecimalsAsNumber(
									this.state.growthPigEvent.totalWeight / this.state.growthPigEvent.amount,
							  )
							: 0
					}
					onChangeNumber={this.onGrowthPigEventAvgWeightChanged}
				/>
			),
		};
	};

	private onToProductionTypeChanged = (productionType: AnimalType) => {
		if (
			productionType !== this.state.toProductionType &&
			this.state.toSection &&
			this.state.toSection.animalType !== productionType
		) {
			this.setState({ toProductionType: productionType, toPenId: undefined, toSection: undefined });
		} else {
			this.setState({ toProductionType: productionType });
		}
	};

	private onStableSectionChanged = (sectionId: string) => {
		const section = this.props.hashmapLocations[sectionId] as ISection;
		const data = generateGrowthPigEventDataForSectionId(
			section,
			this.props.growthPigsEvents,
			this.props.locations,
			this.props.productionType,
			this.state.fromProductionType,
			this.state.date ? this.state.date : new Date(),
		);
		this.setState({ fromSection: section, data });
	};

	private onStableToSectionChanged = (sectionId: string) => {
		const section = this.props.hashmapLocations[sectionId] as ISection;

		this.setState({ toSection: section });
	};

	private onStableToSectionFormChanged = (sectionId: string) => {
		const section = this.props.hashmapLocations[sectionId] as ISection;
		if (section && !section.usePens) {
			let penId = FindHiddenPenOnSection(sectionId, this.state.toPenId);
			if (penId) {
				this.setState({ toPenId: penId, toSection: section });
			}
		}
		this.setState({ toSection: section });
	};

	private getDateRow = (): FormRow => {
		return {
			name: localized('Date'),
			component: (
				<SkioldDatePicker
					onDateChanged={this.OnDateChanged}
					selectedDate={this.state.date}
					theme={'dark'}
					color={'grey'}
					backwardDateSetEndTime={true}
				/>
			),
		};
	};

	private OnDateChanged = (date: Date) => {
		const state = { ...this.state };
		if (this.state.fromSection) {
			const data = generateGrowthPigEventDataForSectionId(
				this.state.fromSection,
				this.props.growthPigsEvents,
				this.props.locations,
				this.props.productionType,
				this.state.fromProductionType,
				date,
			);
			this.setState({ ...state, data, date: date });
			const itemsWithDeparture = data.filter(e => e.departedFromSection);
			itemsWithDeparture.forEach(itemWithDepartures => {
				handleGrowthpigsDeparturedFromSection(
					state.data,
					itemWithDepartures,
					itemWithDepartures.departedFromSection,
				);
			});
		} else {
			this.setState({ ...state, date: date });
		}
	};

	private getFormRows() {
		let formRows = new Array<FormRow>();
		formRows.push(this.getDateRow());
		formRows.push(this.getProductionRow(false));
		formRows.push(this.getFromLocationRow());
		if (this.state.growthPigEvent && this.state.fromSection && this.state.fromSection.usePens) {
			formRows.push(
				this.getPenPickerRow(
					localized('FromPen'),
					true,
					this.state.fromSection && this.state.fromSection.id,
					this.state.growthPigEvent.fromPenId,
				),
			);
		}

		formRows.push(this.getProductionRow(true));
		formRows.push(this.getToLocationRow());
		if (this.state.growthPigEvent && this.state.toSection && this.state.toSection.usePens) {
			formRows.push(
				this.getPenPickerRow(
					localized('ToPen'),
					false,
					this.state.toSection && this.state.toSection.id,
					this.state.growthPigEvent.toPenId,
				),
			);
		}
		if (this.props.growthPigsEvent) {
			formRows.push(this.getIntergerRow());
			formRows.push(this.getAvgWeightRow());
		}
		return formRows;
	}

	private onGrowthPigEventAvgWeightChanged = (num?: number) => {
		const gpe = { ...this.state.growthPigEvent } as GrowthPigsEvent;
		gpe.totalWeight = num && gpe.amount ? gpe.amount * num : 0;
		this.setState({ growthPigEvent: gpe as GrowthPigsEvent });
	};

	private onGrowthPigEventAmountChanged = (num?: number) => {
		const gpe = { ...this.state.growthPigEvent } as GrowthPigsEvent;
		const growthPigEventToEdit = this.props.growthPigsEvent;
		const oldTotalWeightPerPig =
			growthPigEventToEdit && growthPigEventToEdit.totalWeight && growthPigEventToEdit.amount
				? growthPigEventToEdit.totalWeight / growthPigEventToEdit.amount
				: 0;

		const oldTotalWeight = num ? oldTotalWeightPerPig * num : 0;
		gpe.amount = num!;
		gpe.totalWeight = oldTotalWeight;
		this.setState({ growthPigEvent: gpe as GrowthPigsEvent });
	};

	private renderButtons() {
		return (
			<>
				{!this.state.growthPigEvent ? (
					<ViewWeb className="button-view-style">
						<SkioldButton title={localized('MoveAll')} onPress={this.DistributeAll} />
						<SkioldButton
							disabled={!this.state.fromSection}
							title={localized('DistributeMoveFrom')}
							onPress={this.openDistributeModal}
						/>
						<SkioldButton title={localized('DistributeTo')} onPress={this.openModal} />
					</ViewWeb>
				) : (
					<ViewWeb className="button-view-style">
						<SkioldButton title={localized('Save')} onPress={this.upsertEvent} />
						<SkioldButton title={localized('Delete')} onPress={this.deleteEvent} />
						<SkioldButton theme="grey" title={localized('Close')} onPress={this.props.closeEditModal} />
					</ViewWeb>
				)}
			</>
		);
	}

	private upsertEvent = async () => {
		if (
			this.state.growthPigEvent &&
			this.props.closeEditModal &&
			validateGrowthPigsMoveEvent(this.state, showAlert, true, false)
		) {
			const copyOfEvent = { ...this.state.growthPigEvent } as GrowthPigsEvent;
			copyOfEvent.date = this.state.date;
			copyOfEvent.toProductionType = this.state.toProductionType;
			copyOfEvent.toPenId = this.state.toPenId;
			copyOfEvent.toSectionId = this.state.toSection && this.state.toSection.id;
			copyOfEvent.poolYoungFemaleId =
				this.state.toProductionType === AnimalType.YoungFemale ? copyOfEvent.poolYoungFemaleId : undefined;
			copyOfEvent.correctToProductionType = this.state.toSection
				? this.state.toSection.animalType!
				: AnimalType.Unknown;
			const hiddenPen =
				this.state.toSection &&
				this.state.toSection.id &&
				!this.state.toSection.usePens &&
				FindHiddenPenOnSection(this.state.toSection.id, this.state.toPenId);
			copyOfEvent.toPenId = hiddenPen ? hiddenPen : copyOfEvent.toPenId;
			copyOfEvent.toProductionType =
				this.state.toSection &&
				((this.state.toSection.type && this.state.toSection.animalType === AnimalType.FRATS) ||
					this.state.toSection.animalType === AnimalType.Piglet)
					? this.state.toSection.type!.includes('Weaner')
						? AnimalType.Weaner
						: this.state.toSection.type!.includes('Finisher')
						? AnimalType.Finisher
						: AnimalType.YoungFemale
					: this.state.toSection && this.state.toSection.animalType
					? this.state.toProductionType
					: this.state.toProductionType;
			if (copyOfEvent.toSectionId) {
				const building = getBuildingBySectionId(copyOfEvent.toSectionId, this.props.hashmapLocations);
				copyOfEvent.toBuildingId = building && building.id;
			}

			UpsertGrowthPigEventAndClose(copyOfEvent, this.props.upsertGrowthPigsEvent, this.props.closeEditModal);
		}
	};

	private deleteEvent = async () => {
		const growthPigEventToEdit = this.props.growthPigsEvent;
		if (growthPigEventToEdit && this.props.closeEditModal) {
			growthPigEventToEdit.isDeleted = true;
			UpsertGrowthPigEventAndClose(
				growthPigEventToEdit,
				this.props.upsertGrowthPigsEvent,
				this.props.closeEditModal,
			);
		}
	};

	private DistributeAll = () => {
		const data = this.state.data.map(d => {
			if (d.editable) {
				return { ...d, rest: 0, departedFromSection: d.amount, departureWeight: d.totalWeight };
			}
			return { ...d };
		});
		this.setState({ data });
	};

	private allowNegativeAlert = async () => {
		let total = 0;
		let departured = 0;
		this.state.data.forEach(element => {
			total += element.amount ? element.amount : 0;
			departured += element.departedFromSection ? element.departedFromSection : 0;
		});
		if (total < departured) {
			return await ShowConfirmAlert(localizedInterpolation('NegativePigAmountBeforeMove', total));
		}
		return true;
	};

	private openModal = async () => {
		if (validateGrowthPigsMoveEvent(this.state, showAlert) && (await this.allowNegativeAlert())) {
			const data = this.state.data;

			// Sets a moveAllTreated bool, to determine how retention time should be handled
			let dataWithTreatmentOptions: GrowthPigDepartureFromSectionRow[] = [];
			if (this.props.handleRetentionTime) {
				for (let e of data) {
					if (e.retentionDate && e.retentionDate > new Date()) {
						if (e.rest) {
							const moveAllTreated = await showCustomKeyValueAlert<number>(
								`${localized('pen')} ${e.penName}: ${localized('SelectTreatedMoved')}`,
								getMoveAllTreatedEnumOptions(),
							);

							dataWithTreatmentOptions.push({ ...e, moveAllTreated: moveAllTreated });
						}
						if (!e.rest) {
							dataWithTreatmentOptions.push({ ...e, moveAllTreated: MoveAllTreatedEnum.All });
						}
					} else {
						dataWithTreatmentOptions.push(e);
					}
				}
			} else {
				dataWithTreatmentOptions = data;
			}

			this.setState({ modalOpen: true, dataToModalForward: dataWithTreatmentOptions });
		}
	};
}

export default connect(
	GrowthPigMovePropsMapStateToProps,
	GrowthPigEventsMapDispatchToProps,
)(GrowthPigMoveEventComponent);
