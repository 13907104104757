import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { getUserSitesForCurrentUserAsync, getUserSitesForUser, removeUserSiteAccess } from './actions';
import { UserSiteState } from './types';

export const initialProfileState: UserSiteState = {
	userSites: [],
	loading: false,
};

export default function reducer(state: UserSiteState = initialProfileState, action: Action): UserSiteState {
	if (isType(action, getUserSitesForCurrentUserAsync.started)) {
		return { ...state, loading: true };
	}
	if (isType(action, getUserSitesForCurrentUserAsync.failed)) {
		return { ...state, loading: false };
	}
	if (isType(action, getUserSitesForCurrentUserAsync.done)) {
		return { ...state, userSites: action.payload.result, loading: false };
	}

	if (action.type === 'USER_LOGOUT') {
		return initialProfileState;
	}

	if (isType(action, getUserSitesForUser.done)) {
		return { ...state, userSites: action.payload.result, loading: false };
	}
	if (isType(action, getUserSitesForUser.started)) {
		return { ...state, loading: true };
	}
	if (isType(action, getUserSitesForUser.failed)) {
		return { ...state, loading: false };
	}

	return state;
}
