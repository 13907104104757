import {
	AccessTokenKey,
	AccessTokenExpireKey,
	RefreshTokenKey,
	RefreshTokenExpireKey,
	B2C_CLIENT_ID,
	SkioldDigitalResetPasswordPolicy,
	B2C_API_SCOPE,
	JwtAdminExtension,
} from 'shared/constants';
import { getAuthStorageEngine } from './auth-storage-helper';
import { decode } from 'base-64';
import { StoreContainer } from 'shared/state/store-container';
import { Monitoring } from './monitoring-service';

export const ONE_DAY_SECONDS = 60 * 60 * 24;
export const EXPIRE_BUFFER_SEC = 10;

export async function isTokenValid(tokenType: 'accessToken' | 'refreshToken') {
	let tokenKey: string;
	let tokenExpireKey: string;

	if (tokenType === 'accessToken') {
		tokenKey = AccessTokenKey;
		tokenExpireKey = AccessTokenExpireKey;
	} else {
		tokenKey = RefreshTokenKey;
		tokenExpireKey = RefreshTokenExpireKey;
	}

	let token = await getAuthStorageEngine().getItem<string>(tokenKey);
	if (token) {
		let expiresAt = await getAuthStorageEngine().getItem<string>(tokenExpireKey);
		if (expiresAt && tokenType === 'accessToken') {
			let dateToHandle = new Date();

			let isValid = new Date(expiresAt) > new Date(dateToHandle.setTime(dateToHandle.getTime() + 5 * 60 * 1000));
			return isValid;
		} else {
			let isValid = new Date(expiresAt) > new Date();

			return isValid;
		}
	}

	return false;
}

export async function isAuthenticated() {
	return isTokenValid('accessToken');
}

export function getTokenExpireTime(expiresInSeconds: number): Date {
	const tokenExpiration = new Date();
	tokenExpiration.setSeconds(tokenExpiration.getSeconds() + expiresInSeconds - EXPIRE_BUFFER_SEC);

	return tokenExpiration;
}

export function getChangePasswordConfig(redirectUrl: string) {
	const authConfiguration = {
		scope: `openid ${B2C_API_SCOPE}`,
		redirect_uri: redirectUrl,
		client_id: B2C_CLIENT_ID,
		prompt: 'login',
		response_type: 'id_token',
		p: SkioldDigitalResetPasswordPolicy,
	};

	return authConfiguration;
}

export function TraceUser(msg: string) {
	let profile = StoreContainer.getState().profile;
	if (profile && profile.active) {
		let userLoginOutMiddleware = StoreContainer.getState().profile.active!.adUserID;
		Monitoring.logTrace(msg, {
			userLoginOutMiddleware: `${userLoginOutMiddleware} - ${new Date().toISOString()}`,
		});
	}
}

// Extract custom jwt extension to check if admin
export async function isUserAdmin(token?: string) {
	if (!token) {
		token = await getAuthStorageEngine().getItem<string>(AccessTokenKey);
	}

	if (token) {
		const tokenObject = parseJwt(token);
		if (tokenObject && tokenObject[JwtAdminExtension] && tokenObject[JwtAdminExtension].toLowerCase() === 'admin') {
			return true;
		}
	}
	return false;
}

function parseJwt(token) {
	let base64Url = token.split('.')[1];
	let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	let jsonPayload = decodeURIComponent(
		decode(base64)
			.split('')
			.map(c => {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join(''),
	);

	return JSON.parse(jsonPayload);
}
