import { default as ObjectID } from 'bson-objectid';
import { Dispatch } from 'redux';
import {
	AllAnimalsEsfListSetting,
	LardScanningEventListSetting,
	LardScanningEventListSetting2,
	FarrowingListSetting,
	IWorkListSetting,
	MatingListSetting,
	MoveFromPregToPregSetting,
	MoveMatingToMatingSetting,
	MoveToFarrowingLocation,
	MoveToMatingLocation,
	MoveToPregnancyLocation,
	ScanningListSetting,
	ScanningListSetting2,
	ShortageEsfListSetting,
	TasksInFarrowingStableSetting,
	TasksInFarrowingStableSetting2,
	VaccineListSetting1,
	VaccineListSetting2,
	VaccineListSetting3,
	WeaningListSetting,
	WorkListSetting,
	WorkListType,
	DepartureWorkListSetting,
	SowTaskSettings1,
	SowTaskSettings2,
} from 'shared/api/api';
import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import { StoreContainer } from 'shared/state/store-container';
import * as Action from './actions';

export function GetSyncData() {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;
	const lastSyncDate = state.workListSettings.lastSyncDate;

	if (state.workListSettings.syncInProgress) {
		return (dispatch: Dispatch<any>) => {
			return Promise.resolve();
		};
	}

	return AsyncOperationBuilder2(Action.getSyncData, client => client.workListSetting_Sync(siteId, lastSyncDate), {
		siteId,
		lastSyncDate,
	});
}

export function SaveWorkListSetting(workListSetting: IWorkListSetting) {
	const state = StoreContainer.getState();
	const prevEntity = state.workListSettings.entities.find(wls => wls.id === workListSetting.id);
	if (!workListSetting.id) {
		workListSetting.id = new ObjectID().toHexString();
	}

	return AsyncOperationBuilder2(
		Action.saveWorkListSetting,
		client => client.workListSetting_Upsert(getCorrectWorklistType(workListSetting)),
		workListSetting,
		prevEntity,
	);
}

function getCorrectWorklistType(workListSetting: IWorkListSetting) {
	switch (workListSetting.type) {
		case WorkListType.MatingListSetting:
			return MatingListSetting.fromJS(workListSetting);
		case WorkListType.FarrowingListSetting:
			return FarrowingListSetting.fromJS(workListSetting);
		case WorkListType.WeaningListSetting:
			return WeaningListSetting.fromJS(workListSetting);
		case WorkListType.ScanningListSetting:
			return ScanningListSetting.fromJS(workListSetting);
		case WorkListType.ScanningListSetting2:
			return ScanningListSetting2.fromJS(workListSetting);
		case WorkListType.TasksInFarrowingStableSetting:
			return TasksInFarrowingStableSetting.fromJS(workListSetting);
		case WorkListType.TasksInFarrowingStableSetting2:
			return TasksInFarrowingStableSetting2.fromJS(workListSetting);
		case WorkListType.MoveToMatingLocation:
			return MoveToMatingLocation.fromJS(workListSetting);
		case WorkListType.MoveToFarrowingLocation:
			return MoveToFarrowingLocation.fromJS(workListSetting);
		case WorkListType.MoveToPregnancyLocation:
			return MoveToPregnancyLocation.fromJS(workListSetting);
		case WorkListType.VaccineListSetting1:
			return VaccineListSetting1.fromJS(workListSetting);
		case WorkListType.VaccineListSetting2:
			return VaccineListSetting2.fromJS(workListSetting);
		case WorkListType.VaccineListSetting3:
			return VaccineListSetting3.fromJS(workListSetting);
		case WorkListType.AllAnimalsEsfListSetting:
			return AllAnimalsEsfListSetting.fromJS(workListSetting);
		case WorkListType.ShortageEsfListSetting:
			return ShortageEsfListSetting.fromJS(workListSetting);
		case WorkListType.MoveFromPregToPregSetting:
			return MoveFromPregToPregSetting.fromJS(workListSetting);
		case WorkListType.MoveMatingToMatingSetting:
			return MoveMatingToMatingSetting.fromJS(workListSetting);
		case WorkListType.DepartureWorkListSetting:
			return DepartureWorkListSetting.fromJS(workListSetting);
		case WorkListType.LardScanningEventListSetting:
			return LardScanningEventListSetting.fromJS(workListSetting);
		case WorkListType.LardScanningEventListSetting2:
			return LardScanningEventListSetting2.fromJS(workListSetting);
		case WorkListType.SowTaskSettings1:
			return SowTaskSettings1.fromJS(workListSetting);
		case WorkListType.SowTaskSettings2:
			return SowTaskSettings2.fromJS(workListSetting);
		default:
			return WorkListSetting.fromJS(workListSetting);
	}
}

export function SaveSyncData() {
	const state = StoreContainer.getState();
	const updates = state.workListSettings.updates;
	let promises = new Array<Promise<void>>();

	return async (dispatch: Dispatch<any>) => {
		if (state.workListSettings.saveSyncInProgress) {
			return Promise.resolve();
		}
		updates.forEach(update => {
			promises.push(SaveWorkListSetting(update)(dispatch));
		});

		return await Promise.all(promises);
	};
}

export function SetSaveFunction(saveFunction?: (any?: any) => any) {
	return async (dispatch: Dispatch<any>) => {
		dispatch(Action.setSaveFunction({ saveFunction }));
	};
}

export function SetDeleteFunction(deleteFunction?: (any?: any) => any) {
	return async (dispatch: Dispatch<any>) => {
		dispatch(Action.setDeleteFunction({ deleteFunction }));
	};
}

export function SetPrintFunction(printFunction?: (any?: any) => any) {
	return async (dispatch: Dispatch<any>) => {
		dispatch(Action.setPrintFunction({ printFunction }));
	};
}
