import { truncateSync } from 'fs';
import {
	CellColorStyle,
	Gender,
	IFarrowing,
	IGeneralSettings,
	IMated,
	IPregnancyEvent,
	IStemAnimal,
	NucleusManagementProvider,
	PregnancyState,
} from 'shared/api/api';
import { AssignIdAccess, LicenceIds } from 'shared/constants';
import { localized, localizedInterpolation, localizedInterpolationMultiple } from 'shared/state/i18n/i18n';
import { EntranceTypes } from 'shared/state/models/entrance-types';
import { StoreContainer } from 'shared/state/store-container';
import { getDaysDifference, isDateSameOrBefore, isSameDate } from '../date-helpers';

export const danbredIdNumberLength = 11;
export const DgIdNumberLengthUpper = 12;
export const DgIdNumberLengthLower = 11;
export const abortionLower = 40;
export const abortionUpper = 130;
export interface NucleusManagementValidator {
	//Entrance
	validateEntranceStemAnimalAge: (stemAnimal: IStemAnimal) => boolean;
	validateAnimalNumberLength: (stemAnimal: IStemAnimal) => boolean;
	validateIdNumber: (stemAnimal: IStemAnimal) => boolean;

	//Mating
	validateAgeAtMating: (stemAnimal: IStemAnimal, mating: IMated, generalSetting: IGeneralSettings) => boolean;
	validateBoarAtMating: (
		boar: IStemAnimal | undefined,
		sow: IStemAnimal,
		mating: IMated,
		generalSetting: IGeneralSettings,
	) => boolean;

	//Farrowing
	validateAbortion: (sow: IStemAnimal, mating: IMated, farrowing: IFarrowing) => boolean;
	showAlert: (msg: string) => void;
}

export class NucleusValidator {
	public validator: BaseNucleusManagementValidator;
	constructor(licence: string | undefined, showAlert: (msg: string) => void) {
		switch (licence) {
			case LicenceIds.NucleusManagementDanBred:
				this.validator = new DanBredValidator(showAlert);
				break;
			case LicenceIds.NucleusManagementDanishGenetics:
				this.validator = new DanishGeneticsValidator(showAlert);
				break;
			case LicenceIds.NucleusManagementSelfManaged:
				this.validator = new CEFNValidator(showAlert);
				break;
			case AssignIdAccess:
				this.validator = new BypassValidator(showAlert);
				break;
			default:
				this.validator = new BaseNucleusManagementValidator(showAlert);
		}
	}

	public validateEntrance(stemAnimal: IStemAnimal) {
		return (
			this.validator.validateIdNumber(stemAnimal) &&
			this.validator.validateAnimalNumberLength(stemAnimal) &&
			this.validator.validateEntranceStemAnimalAge(stemAnimal)
		);
	}

	public validateMating(
		boar: IStemAnimal | undefined,
		sow: IStemAnimal,
		mating: IMated,
		generalSetting: IGeneralSettings,
	) {
		return (
			this.validator.validateAgeAtMating(sow, mating, generalSetting) &&
			this.validator.validateBoarAtMating(boar, sow, mating, generalSetting)
		);
	}

	public validateFarrowing(sow: IStemAnimal, mating: IMated, farrowing: IFarrowing) {
		return this.validator.validateAbortion(sow, mating, farrowing);
	}
}

export class BaseNucleusManagementValidator implements NucleusManagementValidator {
	public stemAnimal: IStemAnimal | undefined;
	public generalSetting: IGeneralSettings | undefined;
	public showAlert: (msg: string) => void;

	constructor(showAlert: (msg: string) => void) {
		this.showAlert = showAlert;
	}

	public validateAnimalNumberLength(stemAnimal: IStemAnimal) {
		return true;
	}
	public validateIdNumber(stemAnimal: IStemAnimal) {
		return true;
	}
	public validateAgeAtMating(stemAnimal: IStemAnimal, mating: IMated, generalSetting: IGeneralSettings) {
		let isValid = true;
		if (stemAnimal.breedingNumber) {
			if (!generalSetting.minAgeInDaysAtFirstMating) {
				isValid = false;
				this.showAlert(localized('ValidateSetAgeAtFirstMating'));
			} else {
				isValid =
					getDaysDifference(mating.date, stemAnimal.birthDate) >= generalSetting.minAgeInDaysAtFirstMating;
				if (!isValid) {
					this.showAlert(
						localizedInterpolation('ValidateAgeAtFirstMating', generalSetting.minAgeInDaysAtFirstMating),
					);
				}
			}
		}
		return isValid;
	}
	public validateBoarAtMating(
		boar: IStemAnimal | undefined,
		sow: IStemAnimal,
		mating: IMated,
		generalSetting: IGeneralSettings,
	) {
		let isValid = true;
		if (!sow.breedingNumber) {
			return isValid;
		}
		if (!boar) {
			return isValid;
		}
		if (boar.departureDate) {
			if (!generalSetting.maxDaysAfterBoarDepartureSemen) {
				isValid = false;
				this.showAlert(localized('ValidateSetMaxDaysAfterBoarDeparture'));
			} else {
				isValid =
					mating.date !== undefined &&
					mating.date >= boar.departureDate &&
					getDaysDifference(mating.date, boar.departureDate) <= generalSetting.maxDaysAfterBoarDepartureSemen;
				if (!isValid) {
					this.showAlert(
						localizedInterpolation(
							'ValidateMaxDaysAfterBoarDeparture',
							generalSetting.maxDaysAfterBoarDepartureSemen,
						),
					);
				}
			}
		} else {
			isValid = !!(
				boar.entranceDate &&
				mating.date !== undefined &&
				isDateSameOrBefore(boar.entranceDate, mating.date)
			);
			if (!isValid) {
				this.showAlert(
					localizedInterpolation(
						'ValidateNucleusBoarEntranceDate',
						generalSetting.maxDaysAfterBoarDepartureSemen,
					),
				);
			}
		}

		return isValid;
	}
	public validateAbortion(sow: IStemAnimal, mating: IMated, farrowing: IFarrowing) {
		if (sow.breedingNumber) {
			const days = getDaysDifference(mating.date, farrowing.date);
			if (days < abortionLower || days > abortionUpper) {
				this.showAlert(
					localizedInterpolationMultiple('ValidateNucleusAbortion', {
						area: abortionLower,
						area2: abortionUpper,
					}),
				);
				return false;
			}
		}
		return true;
	}
	public validateEntranceStemAnimalAge(stemAnimal: IStemAnimal) {
		return true;
	}
}

const allowedUsageCode = (usageCode: string | undefined) => {
	return usageCode !== '400' && usageCode !== '200' && usageCode !== '100';
};
export class DanBredValidator extends BaseNucleusManagementValidator {
	public validateAnimalNumberLength(stemAnimal: IStemAnimal) {
		if (stemAnimal.breedingNumber && stemAnimal.animalNumber) {
			const isValid = stemAnimal.animalNumber.length <= 6;
			if (!isValid) {
				this.showAlert(localized('ValidateAnimalNumberLength'));
			}
			return isValid;
		}
		return true;
	}
	public validateIdNumber(stemAnimal: IStemAnimal) {
		if (stemAnimal.breedingNumber) {
			let isValid = true;
			if (allowedUsageCode(stemAnimal.usageCode) && stemAnimal.entranceType === EntranceTypes.FromYoungAnimal) {
				this.showAlert(localizedInterpolation('ValidateWrongUsageNumber', stemAnimal.usageCode));
				isValid = false;
			} else {
				const idNumberTrimmed = !stemAnimal.idNumber ? '' : stemAnimal.idNumber.replace(/-/g, '');
				if (idNumberTrimmed.length !== danbredIdNumberLength) {
					this.showAlert(
						localizedInterpolationMultiple('ValidateWrongIdNumber', {
							area: danbredIdNumberLength,
							area2: NucleusManagementProvider.DanBred,
						}),
					);
					isValid = false;
				}
			}

			return isValid;
		}
		return true;
	}
	public validateAgeAtMating(stemAnimal: IStemAnimal, mating: IMated, generalSetting: IGeneralSettings) {
		return super.validateAgeAtMating(stemAnimal, mating, generalSetting);
	}
	public validateBoarAtMating(
		boar: IStemAnimal | undefined,
		sow: IStemAnimal,
		mating: IMated,
		generalSetting: IGeneralSettings,
	) {
		return super.validateBoarAtMating(boar, sow, mating, generalSetting);
	}
	public validateAbortion(sow: IStemAnimal, mating: IMated, farrowing: IFarrowing) {
		return super.validateAbortion(sow, mating, farrowing);
	}
	public validateEntranceStemAnimalAge(stemAnimal: IStemAnimal) {
		return super.validateEntranceStemAnimalAge(stemAnimal);
	}
}

export class DanishGeneticsValidator extends BaseNucleusManagementValidator {
	public validateAnimalNumberLength(stemAnimal: IStemAnimal) {
		return true;
	}
	public validateIdNumber(stemAnimal: IStemAnimal) {
		if (stemAnimal.breedingNumber) {
			let isValid = true;
			if (allowedUsageCode(stemAnimal.usageCode) && stemAnimal.entranceType === EntranceTypes.FromYoungAnimal) {
				this.showAlert(localizedInterpolation('ValidateWrongUsageNumber', stemAnimal.usageCode));
				isValid = false;
			} else {
				const idNumberTrimmed = !stemAnimal.idNumber ? '' : stemAnimal.idNumber.replace(/-/g, '');
				if (idNumberTrimmed.length < DgIdNumberLengthLower || idNumberTrimmed.length > DgIdNumberLengthUpper) {
					this.showAlert(
						localizedInterpolationMultiple('ValidateWrongIdNumber', {
							area: localizedInterpolationMultiple('or', {
								area: DgIdNumberLengthLower,
								area2: DgIdNumberLengthUpper,
							}),
							area2: localized(NucleusManagementProvider.DanishGenetics),
						}),
					);
					isValid = false;
				}
			}

			return isValid;
		}
		return true;
	}
	public validateAgeAtMating(stemAnimal: IStemAnimal, mating: IMated, generalSetting: IGeneralSettings) {
		return super.validateAgeAtMating(stemAnimal, mating, generalSetting);
	}
	public validateBoarAtMating(
		boar: IStemAnimal | undefined,
		sow: IStemAnimal,
		mating: IMated,
		generalSetting: IGeneralSettings,
	) {
		return true;
	}
	public validateAbortion(sow: IStemAnimal, mating: IMated, farrowing: IFarrowing) {
		return super.validateAbortion(sow, mating, farrowing);
	}
	public validateEntranceStemAnimalAge(stemAnimal: IStemAnimal) {
		if (stemAnimal.breedingNumber) {
			const ageMinimum = stemAnimal.gender === Gender.Male ? 120 : 30;
			const isValid = getDaysDifference(stemAnimal.entranceDate, stemAnimal.birthDate) >= ageMinimum;
			if (!isValid) {
				this.showAlert(
					localizedInterpolationMultiple('ValidateAge', {
						area: ageMinimum,
						area2: localized(NucleusManagementProvider.DanishGenetics),
					}),
				);
			}

			return isValid;
		}
		return true;
	}
}

export class CEFNValidator extends BaseNucleusManagementValidator {
	public validateAnimalNumberLength(stemAnimal: IStemAnimal) {
		return true;
	}
	public validateIdNumber(stemAnimal: IStemAnimal) {
		return true;
	}
	public validateAgeAtMating(stemAnimal: IStemAnimal, mating: IMated, generalSetting: IGeneralSettings) {
		return true;
	}
	public validateBoarAtMating(
		boar: IStemAnimal | undefined,
		sow: IStemAnimal,
		mating: IMated,
		generalSetting: IGeneralSettings,
	) {
		return true;
	}
	public validateAbortion(sow: IStemAnimal, mating: IMated, farrowing: IFarrowing) {
		return super.validateAbortion(sow, mating, farrowing);
	}
	public validateEntranceStemAnimalAge(stemAnimal: IStemAnimal) {
		return true;
	}
}

export class BypassValidator extends BaseNucleusManagementValidator {
	public validateAnimalNumberLength(stemAnimal: IStemAnimal) {
		return true;
	}
	public validateIdNumber(stemAnimal: IStemAnimal) {
		return true;
	}
	public validateAgeAtMating(stemAnimal: IStemAnimal, mating: IMated, generalSetting: IGeneralSettings) {
		return true;
	}
	public validateBoarAtMating(
		boar: IStemAnimal | undefined,
		sow: IStemAnimal,
		mating: IMated,
		generalSetting: IGeneralSettings,
	) {
		return true;
	}
	public validateAbortion(sow: IStemAnimal, mating: IMated, farrowing: IFarrowing) {
		return super.validateAbortion(sow, mating, farrowing);
	}
	public validateEntranceStemAnimalAge(stemAnimal: IStemAnimal) {
		return true;
	}
}

export const resendMatedValidation = (currentState?: IMated, newState?: IMated) => {
	if (!currentState || !newState) {
		return false;
	}

	if (!isSameDate(currentState.date, newState.date)) {
		return true;
	}
	if (currentState.boarId !== newState.boarId) {
		return true;
	}
	return false;
};

export const validateFarrowingDateChange = (farrowing: IFarrowing) => {
	if (farrowing.id && farrowing.stemAnimalId) {
		const state = StoreContainer.getState();

		const latestFarrowing = (state.pregnancyEvents.departuredPregnancies[farrowing.stemAnimalId] || []).concat(state.pregnancyEvents.entities[farrowing.stemAnimalId] || [])
			.filter(preg => preg.state === PregnancyState.Farrowing)
			.sort((a: IPregnancyEvent, b: IPregnancyEvent) => {
				return b.date!.getTime() - a.date!.getTime();
			})[0];

		if (latestFarrowing && latestFarrowing.id !== farrowing.id) {
			return false;
		}

		if (state.stemAnimals.entities.find(youngAnimal => youngAnimal.fromPregnancyId === farrowing.pregnancyId)) {
			return false;
		}
	}

	return true;
};
