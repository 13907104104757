import * as React from 'react';
import { connect } from 'react-redux';
import { WebAppState } from 'web/state/store.web';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import './menu-header.scss';

const mapStateToProps = (state: WebAppState) => ({ signedIn: true });

interface OverlayMenuHeaderProps {
	text: string;
	className?: string;
}

const OverlayMenuHeader = (props: OverlayMenuHeaderProps & ReturnType<typeof mapStateToProps>) => {
	return <TextWeb className={"menu-header " +( props.className?  props.className:'')} >{props.text}</TextWeb>;
};

export default connect(mapStateToProps)(OverlayMenuHeader);
