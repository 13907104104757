import * as types from 'shared/state/ducks/authorizations/types';
import actionCreatorFactory from 'typescript-fsa';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import { IUserProfile2, SiteAuthorizations } from 'shared/api/api';

const actionCreator = actionCreatorFactory(GenActionFactoryName('authorization'));

export const load = actionCreator.async<{}, SiteAuthorizations, { code: number; message: string }>(types.LOAD);

export const updateInitials = actionCreator.async<{ id: string; initials: string }, IUserProfile2>('UPDATE_INITIALS');
