import {
	AnimalKind,
	Equipment,
	Farrowing,
	Gender,
	IMoveEvent,
	IPen,
	IPregnancyEvent,
	IProcessEquipmentData,
	IStemAnimal,
	IUnitToPen,
	LocationType,
	PregnancyState,
	SowState,
} from 'shared/api/api';
import { localized } from 'shared/state/i18n/i18n';
import { StoreContainer } from 'shared/state/store-container';
import { ExceptionMessage } from '../exception-message';
import { getStemAnimalPenId } from '../location-helper';
import { sortPregnanciesByDate } from '../pregnancy-helper/sort-pregnancies';
import { getSowState, getSowStateByNextPregnancyEvent } from '../pregnancy-helper/sow-state-helper';
import { mergeArraysHashmap } from '../reducer-helpers';
import { animalExists, animalIdnumberExist, isTransponderOccupied } from './stem-animal-input-helper';
import { IStemAnimalDepartured } from 'shared/state/ducks/stem-animals';
import { Option } from 'react-dropdown';

// For now, this is the max valid transponder number
export const MAX_TRANSPONDER_NUMBER = 2147483646;

export function getState(stemAnimalId: string) {
	const state = StoreContainer.getState();
	return getState1(stemAnimalId, state.pregnancyEvents.entities[stemAnimalId]);
}

export function getState1(stemAnimalId: string, pregnancyEvents: IPregnancyEvent[]) {
	return getSowState(pregnancyEvents);
}

export function getStateByDate(stemAnimalId: string, date: Date | undefined) {
	if (date) {
		const state = StoreContainer.getState();
		if (state.pregnancyEvents.entities[stemAnimalId]) {
			return getSowState(
				state.pregnancyEvents.entities[stemAnimalId].filter(preg => preg.date && preg.date <= date),
			);
		}
	}
	return SowState.Dry;
}

// function findSow(animalNumber: string): IStemAnimal | undefined {
// 	return findActiveAnimal(animalNumber, Gender.Female);
// }

// function getMatingBatchNumber(date: Date) {
// 	if (!date) {
// 		return;
// 	}
// 	let dateCopy = new Date(date);
// 	const state = StoreContainer.getState();
// 	//Makes sure time of the day isn't a factor
// 	dateCopy.setHours(0, 0, 0, 0);
// 	const matingBatch = state.matingBatches.entities.find(
// 		x => x.matingPeriodStart! <= dateCopy && dateCopy <= x.matingPeriodEnd!
// 	);
// 	const batchNumber = matingBatch ? matingBatch.batchNumber + '' : '';
// 	return batchNumber;
// }

export function getActiveAnimalsAtDate(date: Date) {
	const entranceDateComparer = new Date(date);

	const departureDateComparer = new Date(date);
	const state = StoreContainer.getState();
	const allAnimals = state.stemAnimals.entities.concat(state.stemAnimals.departuredAnimals as IStemAnimal[]);
	const activeAnimals = allAnimals.filter(
		animal =>
			animal.entranceDate &&
			animal.animalNumber &&
			animal.entranceDate.withoutTime() <= entranceDateComparer.withoutTime() &&
			(!animal.departureDate || animal.departureDate.withoutTime() > departureDateComparer.withoutTime()),
	);
	return activeAnimals;
}

export async function ValidateStemAnimalEntrance(
	stemAnimal: IStemAnimal,
	sectionId: string | undefined,
	gender: Gender,
	esfExists: boolean,
	pens: IPen[],
	showAlert: (message: string) => void,
	confirmAlert: (
		message: string,
		acceptText?: string,
		rejectText?: string,
		customHeader?: string,
	) => Promise<boolean>,
	isEdit?: boolean,
	idNumSwitch?: boolean,
) {
	if (!stemAnimal.animalNumber) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_ANIMAL_NUM_NOT_SET));
		return { validated: false, showYoungAnimalModal: false };
	}

	if (!stemAnimal.entranceType) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_ENTRANCE_TYPE_NOT_SET));
		return { validated: false, showYoungAnimalModal: false };
	}

	if (!stemAnimal.entranceDate) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_ENTRANCE_DATE_NOT_SET));
		return { validated: false, showYoungAnimalModal: false };
	}
	if (!sectionId && !isEdit && stemAnimal.entranceKind !== AnimalKind.AIBoar) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_STABLE_SECTION_NOT_SET));
		return { validated: false, showYoungAnimalModal: false };
	}

	if (!stemAnimal.entrancePenId && !isEdit && stemAnimal.entranceKind !== AnimalKind.AIBoar) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_PEN_ID_NOT_SET));
		return { validated: false, showYoungAnimalModal: false };
	}

	if (!stemAnimal.siteId) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_SITE_NOT_SET));
		return { validated: false, showYoungAnimalModal: false };
	}

	if (!idNumSwitch && animalExists(stemAnimal.animalNumber, gender, stemAnimal.id)) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_ANIMAL_NUM_EXISTS));
		return { validated: false, showYoungAnimalModal: false };
	}

	if (!idNumSwitch && animalIdnumberExist(stemAnimal.idNumber, stemAnimal.id)) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_ANIMAL_ID_NUM_EXISTS));
		return { validated: false, showYoungAnimalModal: false };
	}

	if (stemAnimal.entranceType === 'entranceTypeFromYoungAnimal' && !stemAnimal.idNumber) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_ID_NUMBER_NOT_SET));
		return { validated: false, showYoungAnimalModal: true };
	}
	if (
		stemAnimal.gender === Gender.Female &&
		stemAnimal.transponder &&
		isTransponderOccupied(stemAnimal.transponder, stemAnimal.id!)
	) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_TRANSPONDER_IN_USE));
		return { validated: false, showYoungAnimalModal: false };
	}

	if (stemAnimal.gender === Gender.Female && esfExists && !stemAnimal.transponder) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_ESF_IS_ENABLED_AND_TRANSPONDER_IS_REQUIRED));
		return { validated: false, showYoungAnimalModal: false };
	}

	return { validated: true, showYoungAnimalModal: false };
}

export async function ValidateMoveEvent(
	sow: IStemAnimal | undefined,
	moveEvent: IMoveEvent,
	sectionId: string | undefined,
	esfExists: boolean,
	pens: IPen[],
	moveEvents: IMoveEvent[],
	unitToPens: IUnitToPen[],
	processEquipmentData: IProcessEquipmentData[],
	pregnancyEvent: IPregnancyEvent | undefined,
	showAlert: (message: string) => void,
	confirmAlert: (
		message: string,
		acceptText?: string,
		rejectText?: string,
		customHeader?: string,
	) => Promise<boolean>,
) {
	if (!moveEvent.stemAnimalId) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_ANIMAL_NOT_FOUND));
		return false;
	}

	if (!moveEvent.moveDate) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_MOVE_DATE_NOT_SET));
		return false;
	}

	if (!sectionId) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_STABLE_SECTION_NOT_SET));
		return false;
	}
	if (!moveEvent.penId) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_NEW_PEN_ID_NOT_SET));
		return false;
	}

	if (sow && moveEvent.penId === getStemAnimalPenId(sow, moveEvents)) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_NEW_PEN_SAME_AS_CURRENT));
		return false;
	}

	const lastMoveDate = getLastMoveEventDate(moveEvent.stemAnimalId, moveEvents);
	if (!isMoveDateValid(lastMoveDate, moveEvent.moveDate)) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_MOVE_EVENT_IS_EARLIER_THAN_LAST_EVENT));
		return false;
	}

	let pen = pens.find(p => p.id === moveEvent.penId);
	if (
		esfExists &&
		sow &&
		pen &&
		unitToPens.find(
			utp =>
				pen &&
				utp.penId === pen.id &&
				processEquipmentData.find(
					peq => peq.id === utp.processEquipmentDataId && peq.equipmentType === Equipment.ESF,
				) !== undefined,
		)
	) {
		if (!sow.transponder) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_FARM_HAS_ESF_BUT_ANIMAL_DOES_NOT_HAVE_TRANSPONDER));
			return false;
		}

		const state = StoreContainer.store.getState();

		let section = state.locations.sections.find(
			s => pen && s.id === pen.sectionId && s.type === LocationType.Pregnant,
		);
		if (section !== undefined && sow.id) {
			if (
				!(
					pregnancyEvent &&
					(pregnancyEvent.state === PregnancyState.Mated || pregnancyEvent.state === PregnancyState.Averted)
				)
			) {
				if (
					(pregnancyEvent &&
						getSowStateByNextPregnancyEvent(state.pregnancyEvents.entities[sow.id], pregnancyEvent) !==
						SowState.Pregnant) ||
					(getSowState(state.pregnancyEvents.entities[sow.id]) !== SowState.Pregnant &&
						pregnancyEvent === undefined)
				) {
					if (state.pregnancyEvents.entities[sow.id]) {
						showAlert(
							localized(
								ExceptionMessage.VALIDATION_ERROR_ANIMAL_NEEDS_TO_BE_PREGNANT_TO_MOVE_TO_THIS_LOCATION,
							),
						);
						return false;
					}



					if (!(await confirmAlert(localized("VALIDATION_WARNING_MOVE_YOUNG_FEMALE_TO_PREGNANT"), localized("Yes"), localized("No")))) {
						return false;
					}



				}
			}
			if (
				pregnancyEvent &&
				getSowStateByNextPregnancyEvent(state.pregnancyEvents.entities[sow.id], pregnancyEvent) !==
				SowState.Pregnant
			) {
				showAlert(
					localized(
						ExceptionMessage.VALIDATION_ERROR_ANIMAL_IS_NOT_PREGNANT_ANYMORE_AND_ANIMAL_IS_ON_PREGNANT_LOCATION,
					),
				);
				return false;
			}
		}
		if (moveEvent.feedCurveNumber === undefined || moveEvent.feedCurveNumber === null) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_FEED_CURVE_NUMBER_IS_NOT_SET));
			return false;
		}
	}

	return true;
}

export function calculateAnimalKind(stemAnimal: IStemAnimal) {
	const state = StoreContainer.getState();
	return calculateAnimalKind1(stemAnimal, state.pregnancyEvents.entities[stemAnimal.id!]);
}

export function calculateAnimalKindByDate(stemAnimal: IStemAnimal, date: Date) {
	const state = StoreContainer.getState();
	const pregsByDate = state.pregnancyEvents.entities[stemAnimal.id!]
		? state.pregnancyEvents.entities[stemAnimal.id!].filter(e => e.date && e.date <= date)
		: [];
	return calculateAnimalKind1(stemAnimal, pregsByDate);
}

export function getBoarType(stemAnimal: IStemAnimal) {
	if (stemAnimal.gender === Gender.Male) {
		if (stemAnimal.entranceKind === AnimalKind.AIBoar || stemAnimal.entranceKind === AnimalKind.Boar) {
			return stemAnimal.entranceKind;
		}
	}
	return '';
}

export function calculateAnimalKindById(stemAnimalId: string) {
	const state = StoreContainer.getState();
	const sow = state.stemAnimals.entities.find(e => e.id === stemAnimalId);
	if (sow) {
		return calculateAnimalKind1(sow, state.pregnancyEvents.entities[sow.id!]);
	} else {
		return AnimalKind.Unknown;
	}
}

export function calculateAnimalKind1(stemAnimal: IStemAnimal, pregnancyEvents: IPregnancyEvent[]) {
	if (stemAnimal.gender === Gender.Male) {
		return AnimalKind.Boar;
	}
	if (stemAnimal.gender === Gender.Female) {
		if (!pregnancyEvents) {
			return stemAnimal.entranceKind;
		} else {
			if (stemAnimal.entranceKind === AnimalKind.Sow) {
				return AnimalKind.Sow;
			}
			const litter = calculateLitter1(stemAnimal, new Date(8640000000000000), pregnancyEvents);
			const farrowing = pregnancyEvents.filter(x => x.state === PregnancyState.Farrowing).length;

			const mated = pregnancyEvents.filter(x => x.state === PregnancyState.Mated).length;

			if (mated === 0) {
				return stemAnimal.entranceKind;
			}
			if ((litter === 1 || litter === 0) && mated > 0 && farrowing === 0) {
				return AnimalKind.Gilt;
			}
			if (litter >= 1) {
				return AnimalKind.Sow;
			}
		}
	}

	return AnimalKind.Unknown;
}

export function calculateLitter(sow: IStemAnimal, date: Date) {
	const state = StoreContainer.getState();
	// const entranceLitter = sow.entranceLitterNumber ? sow.entranceLitterNumber : 0;
	const pregnancyEvents = mergeArraysHashmap<IPregnancyEvent>(
		state.pregnancyEvents.entities,
		state.pregnancyEvents.departuredPregnancies,
	)[sow.id!];
	if (!pregnancyEvents || pregnancyEvents.length === 0) {
		return 0;
	}

	let lastMating = [...pregnancyEvents]
		.filter(
			preg => preg.state === PregnancyState.Mated && preg.date && preg.date.withoutTime() <= date.withoutTime(),
		)
		.sort((a, b) => a.date!.getTime() - b.date!.getTime())
		.reverse()[0];

	return calculateLitterByPregnancy(sow, lastMating, pregnancyEvents);
}

export function calculateLitterByPregnancy(
	sow: IStemAnimal,
	pregnancy: IPregnancyEvent,
	pregnancyEvents: IPregnancyEvent[],
) {
	// const entranceLitter = sow.entranceLitterNumber ? sow.entranceLitterNumber : 0;
	// const mapPregnancyIds = (pregnancyEvent: IPregnancyEvent) => pregnancyEvent.pregnancyId;
	const sorted = sortPregnanciesByDate(pregnancyEvents);
	if (sow && pregnancy) {
		const prevFarrowings = sorted.filter(
			f =>
				f.date &&
				pregnancy.date &&
				f.pregnancyId !== pregnancy.pregnancyId &&
				f.date.withoutTime() < pregnancy.date.withoutTime() &&
				f.state === PregnancyState.Farrowing &&
				!(f as Farrowing).abortion,
		);
		const matingsbeforeEntrance = sorted.filter(
			f =>
				f.date &&
				sow.entranceDate &&
				f.date.withoutTime() < sow.entranceDate.withoutTime() &&
				f.state === PregnancyState.Mated,
		);
		const matingsbeforeOrOnEntrance = sorted.filter(
			f =>
				f.date &&
				sow.entranceDate &&
				f.date.withoutTime() <= sow.entranceDate.withoutTime() &&
				f.state === PregnancyState.Mated,
		);
		let handleEntranceLitterInsufficiency2 = 0;
		if (sow.entranceLitterNumber === 0 && matingsbeforeEntrance.length > 0) {
			handleEntranceLitterInsufficiency2 = 1;
		}

		if (
			sow.entranceLitterNumber &&
			sow.entranceLitterNumber >= 1 &&
			(matingsbeforeOrOnEntrance.length <= 0 || sow.entranceKind === AnimalKind.YoungFemale)
		) {
			handleEntranceLitterInsufficiency2 -= 1;
		}
		return (
			sow.entranceLitterNumber! +
			prevFarrowings.length +
			1 -
			matingsbeforeEntrance.length +
			handleEntranceLitterInsufficiency2
		);
	}
	return 0;
}

export function calculateLitter1(
	sow: IStemAnimal | IStemAnimalDepartured,
	date: Date,
	pregnancyEvents: IPregnancyEvent[],
) {
	if (!pregnancyEvents || pregnancyEvents.length === 0) {
		return 0;
	}

	let lastMating = [...pregnancyEvents]
		.filter(
			preg => preg.state === PregnancyState.Mated && preg.date && preg.date.withoutTime() <= date.withoutTime(),
		)
		.sort((a, b) => a.date!.getTime() - b.date!.getTime())
		.reverse()[0];
	return calculateLitterByPregnancy(sow as IStemAnimal, lastMating, pregnancyEvents);
}

export function getLastMoveEvent(stemAnimalId: string | undefined, moveEvents: IMoveEvent[]) {
	const lastMoveMoveEvent = moveEvents.find(moveEvent => moveEvent.stemAnimalId === stemAnimalId);
	return lastMoveMoveEvent;
}

export function getLastMoveEventDate(stemAnimalId: string | undefined, moveEvents: IMoveEvent[]) {
	const moveEvent = getLastMoveEvent(stemAnimalId, moveEvents);
	return moveEvent && moveEvent.moveDate;
}

export function isMoveDateValid(lastMoveDate: Date | undefined, moveDate: Date | undefined) {
	if (!lastMoveDate || !moveDate) {
		return true;
	}

	return lastMoveDate.withoutTime().getTime() <= moveDate.withoutTime().getTime();
}

// TODO: Handle other lenghts of idnumber - currently matches api
export function printIdNumber(idNumber: string | undefined | null) {
	if (idNumber === undefined || idNumber === null) {
		return '';
	}
	const trimmed = idNumber.replace(/-/gm, '');

	if (trimmed.length === 11) {
		return trimmed.slice(0, 4) + '-' + trimmed.slice(4, 6) + '-' + trimmed.slice(6);
	}

	return idNumber;
}

export const searchIdNumber = (stemAnimals: IStemAnimal[], searchString: string) => {
	return stemAnimals.filter(stem => {
		if (stem.idNumber) {
			const onlyNumeric = stem.idNumber
				.substring(stem.idNumber.length - 5, stem.idNumber.length)
				.replace(/\D/g, '');

			return (
				stem.idNumber
					.substring(
						stem.idNumber.length - onlyNumeric.length,
						stem.idNumber.length + searchString.length - onlyNumeric.length,
					)
					.indexOf(searchString) > -1
			);
		}
		return false;
	});
}

export function idNumberFilter(value: any, filterValue: any) {
	const onlyNumeric = value
		.substring(value.length - 5, value.length)
		.replace(/\D/g, '');
	return (
		value
			.substring(
				value.length - onlyNumeric.length,
				value.length + filterValue.value.length - onlyNumeric.length,
			)
			.indexOf(filterValue.value) > -1
	);
}

export const getBoarTypeOptions = (): Option[] => {
	return [
		{ value: AnimalKind.Boar, label: AnimalKind.Boar },
		{ value: AnimalKind.AIBoar, label: AnimalKind.AIBoar },
	];
};

export const sortBoars = (boars: IStemAnimal[]) => {
	const boarsCopy = [...boars];

	// Function to split animalNumber into numeric and alphabetic parts
	const splitLettersAndNumbers = (animalNumber: string) => {
		const match = animalNumber.match(/^([a-zA-Z]*)(\d*)$/);
		return match ? [match[1], parseInt(match[2])] : [animalNumber, null];
	}

	// Sort boars by animalnumber, by number first if it can cast to int, then by letter
	return boarsCopy.sort((a, b) => {

		const aNumberParse = parseInt(a.animalNumber!);
		const bNumberParse = parseInt(b.animalNumber!);
		if (aNumberParse && bNumberParse) {
			return aNumberParse - bNumberParse;
		}

		const [aLetters, aNumber] = splitLettersAndNumbers(a.animalNumber!);
		const [bLetters, bNumber] = splitLettersAndNumbers(b.animalNumber!);


		if (!aLetters && bLetters) {
			return -1;
		}
		if (aLetters && !bLetters) {
			return 1;
		}

		// Check aNumber for isNaN and null
		if ((aNumber === null || aNumber?.toString() === "NaN") && (bNumber !== null && bNumber?.toString() !== "NaN")) {
			return 1;
		}

		if ((bNumber === null || bNumber?.toString() === "NaN") && (aNumber !== null && aNumber?.toString() !== "NaN")) {
			return -1;
		}
		if (aLetters !== bLetters) {
			return (aLetters as string).localeCompare(bLetters as string);
		}
		if (aLetters === bLetters && aNumber !== null && bNumber !== null) {
			return (aNumber as number) - (bNumber as number);
		}

		return a.animalNumber!.localeCompare(b.animalNumber!);
	});
}