
import { IFeedingCategory } from "shared/api/api";

export const SAVE_FEEDINGCATEGORY = 'SAVE_FEEDINGCATEGORY';
export const DELETE_FEEDINGCATEGORY = 'DELETE_FEEDINGCATEGORY';
export const FETCH_DATA_FEEDINGCATEGORIES = 'FETCH_DATA_FEEDINGCATEGORIES';

export interface FeedingCategoryState {
    entities: IFeedingCategory[];
}
