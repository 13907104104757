
import { ISlaughterSiteDataDto } from 'shared/api/api';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import actionCreatorFactory from 'typescript-fsa';
import * as types from './types';

const actionCreator = actionCreatorFactory(GenActionFactoryName('SlaughterDatas'));

export const getSlaughterData = actionCreator.async<{siteId?:string, fromData?: Date, toDate?: Date}, ISlaughterSiteDataDto[], { code: number; message: string }>(
    types.SYNC_DATA_SLAUGHTER_DATA
);

export const postSlaughterDatas = actionCreator.async<ISlaughterSiteDataDto[],  void | undefined>(
    types.SAVE_SLAUGHTER_DATA
);

export const putSupplierDetails = actionCreator.async<ISlaughterSiteDataDto, ISlaughterSiteDataDto, { code: number; message: string }>(
    types.PUT_SLAUGHTER_DATA
);

export const deleteSlaughterDatas = actionCreator.async<string[], void | undefined, { code: number; message: string }>(
    types.DELETE_SLAUGHTER_DATA
);
