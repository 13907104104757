import React from 'react';
import { connect } from 'react-redux';
import { AnimalType } from 'shared/api/api';
import { Colors } from 'shared/assets/root-assets/colors';
import { localized } from 'shared/state/i18n/i18n';
import styled from 'styled-components';
import { WebAppState } from 'web/state/store.web';
import ReasonSetting from 'web/view/pages/reason-settings/reason-settings';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { SkioldTouchableOpacity } from '../skiold-components/skiold-touchable-opacity';
import { ViewWeb } from '../utils/web-view';
import { ConnectionSlaughterHouseOverview } from './finisher/connection-slaughter-house/connection-slaughter-house-overview';
import isEqual from 'react-fast-compare';
import PReportGrowthPigSetup from '../growth-pigs/growth-pig-report-setup/growth-pig-p-report-setup';
import { GeneralSettingsOverview } from 'web/view/pages/general-settings-overview/general-settings-overview';
import { AnimalReportOverviewSetting } from 'web/view/pages/animal-report-settings/animal-report-overview-setting';

const options = {
	reasons: 'reasons',
	connectionSlaughterHouse: 'connectionSlaughterHouse',
	validation: 'validation',
	batchProduction: 'batchProduction',
	PlanAndGoal: 'PlanAndGoal',
	WorkLists: 'WorkLists',
	reports: 'reports',
	General: 'General',
};

interface State {
	selected: keyof typeof options;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		profile: state.profile.active!,
	};
};

interface PropsFromParent {
	AnimalType: AnimalType.Finisher | AnimalType.Weaner;
}

type Props = ReturnType<typeof mapStateToProps> & PropsFromParent;

const LinkText = styled.p`
	color: ${Colors.white};
	font-size: 16;
	margin-right: 16px;

	&:hover {
		color: ${Colors.yellow};
	}
`;

export class GrowthPigManagementSetup extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			selected: 'reasons',
		};
	}

	public componentDidUpdate(prevProps: Props) {
		if (!isEqual(prevProps.AnimalType, this.props.AnimalType)) {
			this.setState({ selected: 'reasons' });
		}
	}

	private onPropertyChanged = (itemFromParent: keyof typeof options) => {
		this.setState({ selected: itemFromParent });
	};

	public render() {
		return (
			<ViewWeb className="sow-management-setup">
				<TextWeb className="setup-label">
					{this.props.AnimalType === AnimalType.Finisher
						? localized('FinisherManagementSetup')
						: localized('WeanerManagementSetup')}
				</TextWeb>
				<ViewWeb>
					<ViewWeb className="selectable-container">
						<SkioldTouchableOpacity itemFromParent={options.reasons} onPress={this.onPropertyChanged}>
							<LinkText>{localized('reasons')}</LinkText>
						</SkioldTouchableOpacity>
						{this.props.AnimalType === AnimalType.Finisher && (
							<SkioldTouchableOpacity
								itemFromParent={options.connectionSlaughterHouse}
								onPress={this.onPropertyChanged}
							>
								<LinkText>{localized(options.connectionSlaughterHouse)}</LinkText>
							</SkioldTouchableOpacity>
						)}
						<SkioldTouchableOpacity itemFromParent={options.reports} onPress={this.onPropertyChanged}>
							<LinkText>{localized(options.reports)}</LinkText>
						</SkioldTouchableOpacity>
						<SkioldTouchableOpacity itemFromParent="General" onPress={this.onPropertyChanged}>
							<LinkText>{localized('General')}</LinkText>
						</SkioldTouchableOpacity>
					</ViewWeb>
					{this.state.selected === 'reasons' && <ReasonSetting animalType={this.props.AnimalType} />}
					{this.state.selected === options.connectionSlaughterHouse && <ConnectionSlaughterHouseOverview />}
					{this.state.selected === options.reports && (
						<AnimalReportOverviewSetting animalType={this.props.AnimalType} />
					)}
					{this.state.selected === 'General' && (
						<GeneralSettingsOverview animalType={this.props.AnimalType} />
					)}
				</ViewWeb>
			</ViewWeb>
		);
	}
}
export default connect(mapStateToProps, {})(GrowthPigManagementSetup);
