import React, { FC, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BreedTableColumnEnum, BreedTableItem, DadMomBreed, IBreedTableItem } from 'shared/api/api';
import { splitStringAtIndex } from 'shared/helpers/general-helpers';
import {
	generateDanbred,
	generateDanishGenetics,
	generateDanishGeneticsIdLetter,
} from 'shared/helpers/pregnancy-helper/animal-breed-helper';
import { deleteByDadAndMom, upsertManyBreedTableItems } from 'shared/state/ducks/breed-table-items/operation';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { SkioldUnderlineInput } from '../skiold-components/skiold-input/skiold-underline-input';
import {
	ColumnExtension,
	SimpleSkioldTable,
} from '../skiold-components/skiold-table/simpel-skiold-table/simple-skiold-table';
import { WhiteText } from '../Text/white-text';
import { ViewWeb } from '../utils/web-view';
import { BreedTableModel } from './breed-helper';
import './breed-table.scss';

interface PropsFromParent {}

const getRowId = (item: BreedTableModel) => (item.dadBreedRace ?? '') +"/"+ (item.momBreedRace ?? '');

export const BreedTable: FC<PropsFromParent> = React.memo(() => {
	const [momBreedRace, setMomBreedRace] = useState<string>('');
	const [dadBreedRace, setDadBreedRace] = useState<string>('');
	const dispatch = useDispatch();
	const listItems = useSelector((state: WebAppState) => state.breedTableItems.entities);

	const data = useMemo(() => {
		const grouped = listItems.groupByMultipleProps(item => [item.dadBreedRace, item.momBreedRace]);
		return grouped.map(group => {
			let firstItem = group[0];
			let item = {
				dadBreedRace: firstItem.dadBreedRace,
				momBreedRace: firstItem.momBreedRace,
			} as BreedTableModel;
			group.forEach(i => {
				if (i.breedTableColumnEnum === BreedTableColumnEnum.SKIOLD) {
					item.skiold = i.breedRace;
				}
				if (i.breedTableColumnEnum === BreedTableColumnEnum.DANBRED) {
					item.danbred = i.breedRace;
				}
				if (i.breedTableColumnEnum === BreedTableColumnEnum.DANBRED_NUMBER) {
					item.danbredNumber = i.breedRace;
				}
				if (i.breedTableColumnEnum === BreedTableColumnEnum.DANISH_GENETICS) {
					item.danishgenetics = i.breedRace;
				}
				if (i.breedTableColumnEnum === BreedTableColumnEnum.DANISH_GENETICS_ID_NUMMER) {
					item.danishgeneticsId = i.breedRace;
				}
			});
			return item;
		});
	}, [listItems]);

	const [columns] = useState([
		{ name: 'dadBreedRace', title: localized('DadBreedRace') },
		{ name: 'momBreedRace', title: localized('MomBreedRace') },
		{ name: 'skiold', title: localized(BreedTableColumnEnum.SKIOLD) },
		{ name: 'danbred', title: localized(BreedTableColumnEnum.DANBRED) },
		{ name: 'danbredNumber', title: localized(BreedTableColumnEnum.DANBRED_NUMBER) },
		{ name: 'danishgenetics', title: localized(BreedTableColumnEnum.DANISH_GENETICS) },
		{ name: 'danishgeneticsId', title: localized(BreedTableColumnEnum.DANISH_GENETICS_ID_NUMMER) },
	]);

	const [columnExtensions] = useState<ColumnExtension[]>([
		{ columnName: 'dadBreedRace', width: 100, editingEnabled: false },
		{ columnName: 'momBreedRace', width: 100, editingEnabled: false },
		{ columnName: 'skiold', width: 100 },
		{ columnName: 'danbred', width: 100 },
		{ columnName: 'danbredNumber', width: 100 },
		{ columnName: 'danishgenetics', width: 100 },
		{ columnName: 'danishgeneticsId', width: 100 },
	]);

	const addItem = () => {
		const dg = generateDanishGenetics(dadBreedRace, momBreedRace);
		return {
			dadBreedRace,
			momBreedRace,
			skiold: generateDanbred(dadBreedRace, momBreedRace),
			danbred: generateDanbred(dadBreedRace, momBreedRace),
			danishgenetics: dg,
			danishgeneticsId: generateDanishGeneticsIdLetter(dg),
		} as BreedTableModel;
	};

	const save = (newData: BreedTableModel) => {
		const breeds = listItems.filter(
			b => b.dadBreedRace === newData.dadBreedRace && b.momBreedRace === newData.momBreedRace,
		);
		dispatch(upsertManyBreedTableItems(handleNewBreeds(breeds, newData)));
	};

	const onDelete = (key: string) => {
		if (key) {
			const [dad, mom] = key.split("/")
			dispatch(deleteByDadAndMom({ mom: mom, dad: dad } as DadMomBreed));
		}
	};

	const handleNewBreeds = (breeds: IBreedTableItem[], newData: BreedTableModel) => {
		const keyValue = breeds?.keyValuePairSingle('breedTableColumnEnum');

		const skiold = {
			...keyValue[BreedTableColumnEnum.SKIOLD],
			breedRace: newData.skiold,
			dadBreedRace: newData.dadBreedRace,
			momBreedRace: newData.momBreedRace,
			breedTableColumnEnum: BreedTableColumnEnum.SKIOLD,
		} as BreedTableItem;
		const danbred = {
			...keyValue[BreedTableColumnEnum.DANBRED],
			breedRace: newData.danbred,
			dadBreedRace: newData.dadBreedRace,
			momBreedRace: newData.momBreedRace,
			breedTableColumnEnum: BreedTableColumnEnum.DANBRED,
		} as BreedTableItem;

		const danbredNumber = {
			...keyValue[BreedTableColumnEnum.DANBRED_NUMBER],
			breedRace: newData.danbredNumber,
			dadBreedRace: newData.dadBreedRace,
			momBreedRace: newData.momBreedRace,
			breedTableColumnEnum: BreedTableColumnEnum.DANBRED_NUMBER,
		} as BreedTableItem;
		const danishgenetics = {
			...keyValue[BreedTableColumnEnum.DANISH_GENETICS],
			breedRace: newData.danishgenetics ?? newData.danishgenetics,
			dadBreedRace: newData.dadBreedRace,
			momBreedRace: newData.momBreedRace,
			breedTableColumnEnum: BreedTableColumnEnum.DANISH_GENETICS,
		} as BreedTableItem;
		const danishgeneticsId = {
			...keyValue[BreedTableColumnEnum.DANISH_GENETICS_ID_NUMMER],
			breedRace: newData.danishgeneticsId,
			dadBreedRace: newData.dadBreedRace,
			momBreedRace: newData.momBreedRace,
			breedTableColumnEnum: BreedTableColumnEnum.DANISH_GENETICS_ID_NUMMER,
		} as BreedTableItem;
		return [skiold, danbred, danbredNumber, danishgenetics, danishgeneticsId];
	};

	return (
		<ViewWeb>
			<ViewWeb className="flex-row breed-input-container">
				<ViewWeb className="flex-row">
					<WhiteText>{localized('DadBreedRace')}</WhiteText>
					<SkioldUnderlineInput className="breed-input" text={dadBreedRace} onChangeText={setDadBreedRace} />
				</ViewWeb>
				<ViewWeb className="flex-row ">
					<WhiteText>{localized('MomBreedRace')}</WhiteText>
					<SkioldUnderlineInput className="breed-input" text={momBreedRace} onChangeText={setMomBreedRace} />
				</ViewWeb>
			</ViewWeb>

			<SimpleSkioldTable
				data={data}
				getRowId={getRowId}
				columns={columns}
				columnExtensions={columnExtensions}
				addItem={addItem}
				save={save}
				onDelete={onDelete}
				tableKey={'breed-table1'}
			/>
		</ViewWeb>
	);
});
