import React, { FC, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PoolYoungFemaleShouldDepartureTableItem } from 'shared/api/api';
import * as PoolYoungFemaleOperations from 'shared/state/ducks/pool-young-female/operations';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { PoolYoungFemaleShouldDepartureHeader } from './pyf-should-departure-header';
import { PoolYoungFemaleShouldDepartureTable } from './pyf-should-departure-table';
import './pyf-should-departure.scss';

interface PropsFromParent {}

interface PoolYoungFemaleShouldDepartureContextModel {
	tableItems: PoolYoungFemaleShouldDepartureTableItem[];
	setTableItems: React.Dispatch<React.SetStateAction<PoolYoungFemaleShouldDepartureTableItem[]>>;
	printData: PoolYoungFemaleShouldDepartureTableItem[];
	setPrintData: React.Dispatch<React.SetStateAction<PoolYoungFemaleShouldDepartureTableItem[]>>;
	registrationDate: Date;
	setRegistrationDate: React.Dispatch<React.SetStateAction<Date>>;
}

export const PoolYoungFemaleShouldDepartureContext = React.createContext({} as any);
export const usePyfShouldDeparture = () =>
	useContext<PoolYoungFemaleShouldDepartureContextModel>(PoolYoungFemaleShouldDepartureContext);

export const PoolYoungFemaleShouldDepartureOverview: FC<PropsFromParent> = React.memo(({ ...props }) => {
	const dispatch = useDispatch();

	// Get relevant pyfs
	useEffect(() => {
		PoolYoungFemaleOperations.GetSyncData()(dispatch);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [registrationDate, setRegistrationDate] = useState<Date>(new Date());
	const [printData, setPrintData] = useState<PoolYoungFemaleShouldDepartureTableItem[]>([]);
	const [tableItems, setTableItems] = useState<PoolYoungFemaleShouldDepartureTableItem[]>([]);

	return (
		<PoolYoungFemaleShouldDepartureContext.Provider
			value={{ tableItems, setTableItems, printData, setPrintData, setRegistrationDate, registrationDate }}
		>
			<ViewWeb className="pyf-should-departure-overview">
				<PoolYoungFemaleShouldDepartureHeader />
				<PoolYoungFemaleShouldDepartureTable />
			</ViewWeb>
		</PoolYoungFemaleShouldDepartureContext.Provider>
	);
});
