export class DefaultValidationValues {
    public static DEFAULT_PREGNANT_PERIOD = 116;
    public static DEFAULT_NURSING_PERIOD = 28;

    //Mating age
    public static MatingAge_LOWER_LIMIT = '';
    public static MatingAge_UPPER_LIMIT = '';

    //Mating To Farrowing
    public static MatingToFarrowing_LOWER_LIMIT = 90;
    public static MatingToFarrowing_VALIDATION_LOWER_LIMIT = 100;
    public static MatingToFarrowing_UPPER_LIMIT = 125;

    //Farrowing To Weaning
    public static FarrowingToWeaning_LOWER_LIMIT = 0;
    public static FarrowingToWeaning_UPPER_LIMIT = '';

    //Weaning To Mating
    public static WeaningToMating_LOWER_LIMIT = '';
    public static WeaningToMating_UPPER_LIMIT = '';
}
