import React from 'react';
import { connect } from 'react-redux';
import { IPoolYoungFemale } from 'shared/api/api';
import {
	PoolYoungFemaleEventListMapDispatchToProps,
	PoolYoungFemaleEventListMapStateToProps,
	PoolYoungFemaleEventListProps,
	PoolYoungFemaleEventListState,
	PoolYoungFemaleSummaryItem,
	PrintPoolYoungFemalePdf,
} from 'shared/helpers/pool-young-female-helper/pool-young-female-event-list-helper';
import { localized } from 'shared/state/i18n/i18n';
import 'web/view/components/event-lists/pregnancy-event-lists/sow-pregnancy-event-list-styles.scss';
import 'web/view/components/skiold-components/skiold-table/skiold-double-header-table.scss';
import { SkioldTableGrid as SkioldTableRef } from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { SkioldModal } from '../../skiold-components/skiold-modal/skiold-modal';
import RegisterPoolYoungFemale from '../../stem-animal/pool-young-female/register-pool-young-female';
import PoolYoungFemaleTable from './pool-young-female-table';
import PrinterGreyIcon from 'shared/assets/src-assets/png/printer_ikon_grey.png';
import { SkioldIconSpinner } from '../../utils/skiold-icon-spinner';
import { EntranceTypes } from 'shared/state/models/entrance-types';
import { ViewWeb } from '../../utils/web-view';
import { DepartureTypes } from 'shared/state/models/departure-types';
import memoize from 'memoize-one';
import { PoolYoungFemaleEventTypes } from 'shared/helpers/pool-young-female-helper/pool-young-female-helper';
import SowEvents from 'web/view/pages/sow-events/sow-events';

const option = { label: localized('salePoolYoungFemale'), value: 'salePoolYoungFemale' };
export class PoolYoungFemaleEventList extends React.PureComponent<
	PoolYoungFemaleEventListProps,
	PoolYoungFemaleEventListState
> {
	public SkioldTableRef: SkioldTableRef | undefined;

	constructor(props: PoolYoungFemaleEventListProps) {
		super(props);
		this.state = {
			editModalIsOpen: false,
			editId: undefined,
			summaryItem: new PoolYoungFemaleSummaryItem(),
		};
		this.generateSummary([]);
		props.poolYoungTableGetSyncDate();
	}

	public componentDidUpdate() {}

	private handleData = memoize((poolYoungAnimals: IPoolYoungFemale[]) => {
		let filterAwayToStemAnimal = poolYoungAnimals.filter(
			pyf => pyf.eventType !== PoolYoungFemaleEventTypes.ToStemAnimal,
		);
		this.generateSummary(filterAwayToStemAnimal);
		return filterAwayToStemAnimal;
	});

	public render() {
		return (
			<div className="align-center">
				<PoolYoungFemaleTable
					setRef={this.setTableRef}
					data={this.handleData(this.props.poolYoungFemaleEvents)}
					removeItem={this.removeItem}
					openEditModal={this.openEditModal}
					onFiltersChanged={this.onFiltersChanged}
				/>
				<SkioldModal
					shouldCloseOnOverlayClick={true}
					overflowNone={true}
					padding="0"
					isOpen={this.state.editModalIsOpen}
					close={this.closeModal}
					justify-content="flex-end"
					max-width="calc(100% - 220px)"
				>
					<SowEvents
						stemAnimalId={this.state.editId}
						closeEditModal={this.closeModal}
						theme="dark"
						setEventOption={option}
					/>

				
				</SkioldModal>
			</div>
		);
	}

	private setTableRef = (m: any) => (m ? (this.SkioldTableRef = m) : {});

	private closeModal = () => {
		this.setState({ editId: undefined, editModalIsOpen: false });
	};

	private openEditModal = (event: IPoolYoungFemale) => {
		this.setState({ editId: event.id, editModalIsOpen: true });
	};

	private removeItem = (event: IPoolYoungFemale) => {
		this.props.removePoolYoungFemale(event);
	};

	private reduceFunctionForSummary = (sum: number, current: IPoolYoungFemale) =>
		sum + (current.total !== undefined ? current.total : 0);

	private generateSummary = (items: IPoolYoungFemale[]) => {
		let summaryItem: PoolYoungFemaleSummaryItem = {};
		let groupedCount = items.dictionaryBy('eventType');

		summaryItem.countBought = groupedCount[EntranceTypes.PoolEventTypeBought]
			? groupedCount[EntranceTypes.PoolEventTypeBought].reduce(this.reduceFunctionForSummary, 0).toString()
			: '';
		summaryItem.countDead = groupedCount[DepartureTypes.departureTypeSold]
			? groupedCount[DepartureTypes.departureTypeSold].reduce(this.reduceFunctionForSummary, 0).toString()
			: '';
		summaryItem.countPutDown = groupedCount[DepartureTypes.departureTypeDead]
			? groupedCount[DepartureTypes.departureTypeDead].reduce(this.reduceFunctionForSummary, 0).toString()
			: '';
		summaryItem.countSold = groupedCount[DepartureTypes.departureTypePutDown]
			? groupedCount[DepartureTypes.departureTypePutDown].reduce(this.reduceFunctionForSummary, 0).toString()
			: '';
		summaryItem.countFromFinisher = groupedCount['FromFinisher']
			? groupedCount['FromFinisher'].reduce(this.reduceFunctionForSummary, 0).toString()
			: '';
		summaryItem.countFromWeaner = groupedCount['FromWeaner']
			? groupedCount['FromWeaner'].reduce(this.reduceFunctionForSummary, 0).toString()
			: '';

		this.props.onSummaryChanged(
			<div className="pool-young-female-summary">
				<div className="summary-container">
					<div className="flexDirectionRowWithWidth">
						<div className="summaryTextStyle"> {localized(EntranceTypes.PoolEventTypeBought)}: </div>
						<div className="summaryTextStyle"> {summaryItem.countBought} </div>
					</div>
					<div className="flexDirectionRowWithWidth">
						<div className="summaryTextStyle"> {localized('FromFinisher')}: </div>
						<div className="summaryTextStyle"> {summaryItem.countFromFinisher} </div>
					</div>
					<div className="flexDirectionRowWithWidth">
						<div className="summaryTextStyle"> {localized('FromWeaner')}: </div>
						<div className="summaryTextStyle"> {summaryItem.countFromWeaner} </div>
					</div>
				</div>

				<ViewWeb className="borderLine" />
				<div className="summary-container">
					<div className="flexDirectionRowWithWidth">
						<div className="summaryTextStyle"> {localized(DepartureTypes.departureTypeSold)}: </div>
						<div className="summaryTextStyle"> {summaryItem.countDead} </div>
					</div>
					<div className="flexDirectionRowWithWidth">
						<div className="summaryTextStyle"> {localized(DepartureTypes.departureTypeDead)}: </div>
						<div className="summaryTextStyle"> {summaryItem.countPutDown} </div>
					</div>
					<div className="flexDirectionRowWithWidth">
						<div className="summaryTextStyle"> {localized(DepartureTypes.departureTypePutDown)}: </div>
						<div className="summaryTextStyle"> {summaryItem.countSold} </div>
					</div>
				</div>
				<ViewWeb className="borderLine" />
				<SkioldIconSpinner title={localized('PrintList')} icon={PrinterGreyIcon} onPress={this.printData} />
			</div>,
		);
	};

	private onFiltersChanged = (events: IPoolYoungFemale[]) => {
		this.generateSummary(events);
	};

	private printData = async () => {
		if (this.props.profile) {
			await PrintPoolYoungFemalePdf(
				this.SkioldTableRef
					? (this.SkioldTableRef.GetSortedData() as IPoolYoungFemale[]).map(row => row.id!)
					: [],
				'PoolYoungFemaleEvents.pdf',
				this.props.profile.siteId!,
				Intl.DateTimeFormat().resolvedOptions().timeZone,
				this.props.profile.language,
			);
		}
	};
}

export default connect(PoolYoungFemaleEventListMapStateToProps, PoolYoungFemaleEventListMapDispatchToProps, null, {
	forwardRef: true,
})(PoolYoungFemaleEventList) as any;
