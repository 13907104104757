
import { ResultPerLitterTableData } from 'shared/api/api';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import actionCreatorFactory from 'typescript-fsa';
import * as types from './types';

const actionCreator = actionCreatorFactory(GenActionFactoryName('resultsPerLitter'));

export const geData = actionCreator.async<{ siteId: string; fromDate?: Date,  toDate?: Date }, ResultPerLitterTableData>(
    types.SYNC_DATA_RESULT_PER_LITTER_DATA
);