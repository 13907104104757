import * as React from 'react';
import { signIn } from 'web/web-helpers/authentication-helper-web';
import { Spinner } from '../../components/spinner/spinner';

export class SignInRedirectPage extends React.PureComponent<{}, {}> {
	constructor(props: any) {
		super(props);
		this.state = {};

		this.signIn();
	}

	public render() {
		return <Spinner />;
	}

	private signIn() {
		signIn();
	}
}
