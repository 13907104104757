import React, { useMemo, FC } from 'react';
import { useSelector } from 'react-redux';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';

interface PropsFromParent {}

export const DashboardPigCountAndWeight: FC<PropsFromParent> = React.memo((props) => {
	const dataToUse = useSelector((state: WebAppState) => state.dashboardGrowthPigsOverview.data);

	const { pigCount, pigWeigth } = useMemo(() => {
		let pigCount = 0;
		let pigWeigthTotal = 0;
		if (dataToUse) {
			dataToUse.forEach(gpe => {
				pigCount += gpe.numOfPig ? gpe.numOfPig : 0;

				pigWeigthTotal += gpe.feedCurveWeight && gpe.numOfPig ? gpe.feedCurveWeight* gpe.numOfPig : 0;
			});
		}
		let pigWeigth = pigCount && pigWeigthTotal ? (pigWeigthTotal / pigCount).toFixed(1) : 0;
		return { pigCount, pigWeigth };
	}, [dataToUse]);
  
	return (
		<ViewWeb className="flexDirectionRow dashboard-pig-count-and-weight">
			<ViewWeb className="flexDirectionRow pig-count-and-weight-label">
				<TextWeb>{localized('PigCount')}:</TextWeb>
				<TextWeb>{pigCount}</TextWeb>
			</ViewWeb>
			<ViewWeb className="flexDirectionRow pig-count-and-weight-label">
				<TextWeb>{localized('avgWeightShort')}:</TextWeb>
				<TextWeb>{pigWeigth}</TextWeb>
			</ViewWeb>
		</ViewWeb>
	);
});
