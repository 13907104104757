
import actionCreatorFactory from 'typescript-fsa';
import * as types from './types';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import { IValidationSetup, SyncDataValidationSetup } from 'shared/api/api';

const actionCreator = actionCreatorFactory(GenActionFactoryName('validationSetup'));

export const saveValidationSetup = actionCreator.async<
    IValidationSetup,
    string,
    { code: number; message: string; prevEntity: IValidationSetup | undefined }
>(types.SAVE_VALIDATION_SETUP);
export const getSyncData = actionCreator.async<{ siteId: string; lastSyncDate: Date; activeSiteId?: string  }, SyncDataValidationSetup>(
    types.SYNC_DATA_VALIDATIONSETUP
);
