import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import {
	activateProfileAsync,
	getProfileAsync,
	moduleChanged,
	updateProfileAsync,
	siteChange,
	getSyncData,
	activateSiteIdAdmin,
	setIsResettingState,
} from './actions';
import { ProfileState } from './types';
import { mergeArrays } from 'shared/helpers/reducer-helpers';
import { SharedAppState } from 'shared/state/store.shared';
import { UserProfile, IUserProfile } from 'shared/api/api';
import { SyncableInitialState } from 'shared/state/models/syncable';

export const initialProfileState: ProfileState = {
	...SyncableInitialState,
	active: { name: '', id: '', language: 'en' } as IUserProfile,
	isResettingState: false,
};

export default function reducer(state: ProfileState = initialProfileState, action: Action): ProfileState {
	// When getting and changing profile
	if (
		isType(action, getProfileAsync.done) ||
		isType(action, activateProfileAsync.done) ||
		isType(action, updateProfileAsync.done)
	) {
		if (state.active && state.active.siteId !== action.payload.result.siteId) {
			return { ...state, active: action.payload.result, isResettingState: true };
		} else {
			return { ...state, active: action.payload.result };
		}
	}

	if (isType(action, activateSiteIdAdmin.started) || isType(action, activateSiteIdAdmin.started)) {
		return { ...state };
	}

	if (isType(action, activateSiteIdAdmin.failed) || isType(action, activateSiteIdAdmin.failed)) {
		return { ...state };
	}

	if (isType(action, activateSiteIdAdmin.done) || isType(action, activateSiteIdAdmin.done)) {
		return { ...state, active: action.payload.result };
	}

	if (isType(action, updateProfileAsync.started)) {
		return { ...state };
	}

	if (isType(action, siteChange.done)) {
		state = { ...state, ...SyncableInitialState, isResettingState: true };
	}

	if (isType(action, setIsResettingState)) {
		state = { ...state, isResettingState: action.payload };
	}

	if (isType(action, getSyncData.started)) {
		state = { ...state, syncInProgress: true };
	}

	if (isType(action, getSyncData.done)) {
		if (action.payload.result.datas && action.payload.result.datas.length) {
			state = {
				...state,
				entities: (action.payload.result.datas as unknown[]) as IUserProfile[],
			};
		}

		state = {
			...state,
			lastSyncDate: action.payload.result.syncTime!,
			syncInProgress: false,
		};
	}

	if (isType(action, getSyncData.failed)) {
		state = { ...state, syncInProgress: false };
	}

	if (isType(action, moduleChanged.started)) {
		if (action.payload.userId === state.active!.adUserID) {
			if (action.payload.authorized) {
				return {
					...state,
					active: {
						...state.active!,
						modules: [...state.active!.modules!, action.payload.moduleId!],
						processes: [...state.active!.processes!, ...action.payload.processIds!],
					},
				};
			} else {
				const modules = state.active!.modules!.filter(item => {
					return item !== action.payload.moduleId; // return all the items not matching the action.id
				});
				const processes = state.active!.processes!.filter(
					item => action.payload.processIds && !action.payload.processIds.includes(item),
					// return all the items not matching the action.id
				);
				return {
					...state,
					active: { ...state.active!, modules, processes },
				};
			}
		}
		return state;
	}

	if (action.type === 'USER_LOGOUT') {
		return (state = initialProfileState);
	}

	//Ensure Date objects are in fact date and not strings - redux-persist serializes dates to string but doesn't deserialize to dates again
	if (action.type === 'persist/REHYDRATE') {
		let a = ((action as any) as { payload: SharedAppState; key: string }) as any;

		if (a.key === 'root' && a.payload) {
			let entities = new Array<UserProfile>();
			let updates = new Array<UserProfile>();
			let lastSyncDate = new Date(-8640000000000000);
			let active: IUserProfile | undefined;

			if (a.payload && a.payload.profile && a.payload.profile.entities) {
				entities = a.payload.profile.entities.map((dt: IUserProfile) => {
					let ndt = UserProfile.fromJS({});
					ndt.init(dt);
					return ndt;
				});
			}

			if (a.payload && a.payload.profile && a.payload.profile.updates) {
				updates = a.payload.profile.updates.map((dt: IUserProfile) => {
					let ndt = UserProfile.fromJS({});
					ndt.init(dt);
					return ndt;
				});
			}

			if (a.payload && a.payload.profile && a.payload.profile.lastSyncDate) {
				lastSyncDate = new Date(a.payload.profile.lastSyncDate);
			}

			if (a.payload && a.payload.profile && a.payload.profile.active) {
				active = a.payload.profile.active;
			} else {
				active = initialProfileState.active;
			}

			state = { ...state, entities, updates, lastSyncDate, active };
		}
	}

	return state;
}
