import React from 'react';
import { Option } from 'react-dropdown';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RefType } from 'shared/helpers/ref-type';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { ViewWeb } from 'web/view/components/utils/web-view';
import RegisterFarrowingWorkList from '../../pregnancies/pregnancy-events/register-farrowing/register-farrowing-work-list';
import { SkioldModal } from '../../skiold-components/skiold-modal/skiold-modal';
import { FarrowingWorkListItem } from './farrowing-work-list-item';
import FarrowingWorkListTable, {
	FarrowingWorkListTable as FarrowingWorkListTableRef,
} from './farrowing-work-list-table';
import './farrowing-work-list.scss';
import { SetUseCurrentLocation } from 'shared/state/ducks/locations/operations';

const mapStateToProps = (state: WebAppState) => {
	return {};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => {
	return {
		setUseCurrentLocation: (useLocation: boolean) => SetUseCurrentLocation(useLocation)(dispatch),
	};
};

export interface State {
	selectedFarrowingWorkListItem: FarrowingWorkListItem | undefined;
	editModalOpen: boolean;
	eventOption: Option;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
export class FarrowingWorkList extends React.PureComponent<Props, State> {
	public tableRef: FarrowingWorkListTableRef | undefined;
	constructor(props: Props) {
		super(props);
		this.state = {
			selectedFarrowingWorkListItem: undefined,
			editModalOpen: false,
			eventOption: { label: '', value: '' },
		};
	}

	public componentDidMount() {
		this.props.setUseCurrentLocation(true);
	}

	public componentWillUnmount() {
		this.props.setUseCurrentLocation(false);
	}

	public render() {
		return (
			<ViewWeb className="farrowing-work-list">
				<ViewWeb className="container-view">
					<FarrowingWorkListTable ref={this.setRef} editFarrowingWeb={this.editFarrowing} />
				</ViewWeb>
				<SkioldModal padding="0" isOpen={this.state.editModalOpen} close={this.closeModal}>
					<RegisterFarrowingWorkList
						closeEditModal={this.closeModal}
						animalId={
							this.state.selectedFarrowingWorkListItem
								? this.state.selectedFarrowingWorkListItem.stemAnimalId
								: ''
						}
					/>
				</SkioldModal>
			</ViewWeb>
		);
	}

	private setRef = (s: any) => {
		if (s) {
			this.tableRef = s;
		}
	};

	public GetSortedData() {
		if (this.tableRef && this.tableRef.SkioldTableRef) {
			return this.tableRef.SkioldTableRef.GetSortedData();
		}
		return [];
	}

	private closeModal = () => {
		this.setState({ editModalOpen: false });
	};

	private editFarrowing = (farrowingWorkListItem: FarrowingWorkListItem) => {
		this.setState({
			selectedFarrowingWorkListItem: farrowingWorkListItem,
			editModalOpen: true,
			eventOption: { label: localized('farrowing'), value: 'farrowing' },
		});
	};
}

export default connect<ReturnType<typeof mapStateToProps>, ReturnType<typeof mapDispatchToProps>, RefType, WebAppState>(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{ forwardRef: true },
)(FarrowingWorkList) as any;
