import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnimalType, GrowthPigEventType, GrowthPigsEvent } from 'shared/api/api';
import { getDateString } from 'shared/helpers/date-helpers';
import { memoizeHashmapAllLocation } from 'shared/helpers/memoize-getters/memoize-getters';
import {
	GetGrowthPigEventRegulations,
	removeGrowthPigsEventByIds,
} from 'shared/state/ducks/growth-pig-events/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import SkioldTableGrid from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { SkioldTouchableOpacity } from 'web/view/components/skiold-components/skiold-touchable-opacity';
import { SowListConstants } from 'web/view/components/stem-animal/animal-lists/table-constants';
import { SkioldImage } from 'web/view/components/utils/svg/skiold-image';
import { ViewWeb } from 'web/view/components/utils/web-view';
import DeleteIcon from 'shared/assets/src-assets/png/delete_icon.png';
import PenIcon from 'shared/assets/src-assets/png/pen_icon.png';
import { SkioldModal } from 'web/view/components/skiold-components/skiold-modal/skiold-modal';
import { EditGrowthPigEventRegulation } from 'web/view/pages/growth-pig-regulations/edit-growth-pig-event-regulation';
import {
	generateRegulationTableRows,
	RegulationTableRow,
} from 'shared/helpers/growth-pigs-helper/growth-pig-weight-regulation-helper';
import { Sorting } from '@devexpress/dx-react-grid';
import { NaturalSortDates } from 'shared/helpers/natural-sort';
import { NaturalSortBuildSection, NaturalSortPenOrder, NaturalSortSection } from 'shared/helpers/location-helper';

interface PropsFromParent {
	topLeft?: (topLeft: JSX.Element) => void;
	topRight?: (topLeft: JSX.Element) => void;
	dateFrom?: Date;
	dateTo?: Date;
	productionType: AnimalType;
}

const defaultSort: Sorting[] = [
	{ columnName: 'date', direction: 'desc' },
	{ columnName: 'toPenName', direction: 'asc' },
];

export const GrowthPigWeightRegulations: FC<PropsFromParent> = React.memo(props => {
	const dispatch = useDispatch();

	// Get data
	const { growthPigEvents } = useSelector((state: WebAppState) => state.growthPigEvents);
	const locations = useSelector((state: WebAppState) =>
		memoizeHashmapAllLocation(state.locations.buildings, state.locations.sections, state.locations.pens),
	);
	const { sendDataToDistriwinFinisher, sendDataToDistriwinWeaner } = useSelector(
		(state: WebAppState) => state.generalSettings.entity,
	);

	const [data, setData] = useState<RegulationTableRow[]>([]);
	const [selectedGrowthPigId, setSelectedGrowthPigId] = useState<RegulationTableRow | undefined>(undefined);
	const [columns, setColumns] = useState([]);
	const [columnsExt, setColumnsExt] = useState([]);

	useEffect(() => {
		fetchRegulations(props.dateFrom, props.dateTo);
		if (props.topRight) {
			props.topRight(<></>);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getEditCell = useCallback((event: RegulationTableRow) => {
		return (
			<SkioldTouchableOpacity onPress={openModal} itemFromParent={event}>
				<SkioldImage
					width={SowListConstants.iconSVGWidth}
					height={SowListConstants.iconSVGWidth}
					imageData={PenIcon}
				/>
			</SkioldTouchableOpacity>
		);
	}, []);

	const getRemoveCell = useCallback((event: RegulationTableRow) => {
		return (
			<SkioldTouchableOpacity onPress={deleteRegulation} itemFromParent={event}>
				<SkioldImage
					width={SowListConstants.iconSVGWidth}
					height={SowListConstants.iconSVGWidth}
					imageData={DeleteIcon}
				/>
			</SkioldTouchableOpacity>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const generateColumns = [
			{
				name: 'toBuildingName',
				title: localized('building'),
				sortFunction: NaturalSortBuildSection,
			},
			{
				name: 'toSectionName',
				title: localized('section'),
				sortFunction: NaturalSortSection,
			},
			{
				name: 'toPenName',
				title: localized('pen'),
				sortFunction: NaturalSortPenOrder,
			},
			{
				name: 'date',
				title: localized('Date'),
				sortFunction: NaturalSortDates,
			},
			{
				name: 'amount',
				title: localized('Pcs'),
			},
			{
				name: 'avgWeight',
				title: localized('avgWeight'),
			},
			{
				name: 'productionType',
				title: localized('productionType'),
			},
		] as any;

		const generateColumnExtes = [
			{
				columnName: 'toBuildingName',
				width: SowListConstants.dateWidth,
			},
			{
				columnName: 'toSectionName',
				width: SowListConstants.countWidth,
			},
			{
				columnName: 'toPenName',
				width: SowListConstants.weightWidth,
			},
			{
				columnName: 'date',
				width: SowListConstants.avgWeightWidth,
			},
			{
				columnName: 'amount',
				width: SowListConstants.avgWeightWidth,
			},
			{
				columnName: 'avgWeight',
				width: SowListConstants.avgWeightWidth,
			},
			{
				columnName: 'productionType',
				width: SowListConstants.locationWidth,
			},
		] as any;
		let editable =
			props.productionType === AnimalType.Finisher ? !sendDataToDistriwinFinisher : !sendDataToDistriwinWeaner;
		if (editable) {
			generateColumns.unshift({
				name: 'edit',
				title: ' ',
				sortable: false,
				filterable: false,
				isFixedLeft: true,
				getCellValue: getEditCell,
			});
			generateColumns.push({
				name: 'remove',
				title: ' ',
				sortable: false,
				filterable: false,
				isFixedLeft: true,
				getCellValue: getRemoveCell,
			});
			generateColumnExtes.unshift({
				columnName: 'edit',
				width: SowListConstants.iconWidth,
				filteringEnabled: false,
				sortingEnabled: false,
				resizingEnabled: false,
			});
			generateColumnExtes.push({
				columnName: 'remove',
				width: SowListConstants.iconWidth,
				filteringEnabled: false,
				sortingEnabled: false,
				resizingEnabled: false,
			});
		}
		setColumns(generateColumns);
		setColumnsExt(generateColumnExtes);
	}, [props.productionType, sendDataToDistriwinFinisher, sendDataToDistriwinWeaner, getEditCell, getRemoveCell]);

	useEffect(() => {
		const rowData = generateRegulationTableRows(locations, growthPigEvents, props.productionType);
		setData(rowData);
	}, [growthPigEvents, locations, props.productionType]);

	const fetchRegulations = (fromDate, toDate) => {
		GetGrowthPigEventRegulations(fromDate, toDate)(dispatch);
	};

	const openModal = (event: RegulationTableRow) => {
		setSelectedGrowthPigId(event);
	};

	const closeModal = () => {
		setSelectedGrowthPigId(undefined);
	};

	const deleteRegulation = (event: RegulationTableRow) => {
		if (event.growthPigId) {
			removeGrowthPigsEventByIds([event.growthPigId])(dispatch);

			const rowData = data.filter(e => e.growthPigId !== event.growthPigId);
			setData(rowData);
		}
	};

	return (
		<ViewWeb className="align-items-center">
			<SkioldTableGrid
				ignoreSetCount={true}
				ColumnExtensions={columnsExt}
				columns={columns}
				data={data}
				sortHeaderId={defaultSort}
			/>
			<SkioldModal
				shouldCloseOnOverlayClick={true}
				padding="0"
				isOpen={selectedGrowthPigId ? true : false}
				close={closeModal}
				justify-content="flex-end"
				max-width="calc(100% - 220px)"
			>
				<EditGrowthPigEventRegulation closeModal={closeModal} regulationRow={selectedGrowthPigId} />
			</SkioldModal>
		</ViewWeb>
	);
});
