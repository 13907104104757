import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AnalysisSettingType, IAnalysisResultYoungFemaleAgeSetting } from 'shared/api/api';
import PrinterGreyIcon from 'shared/assets/src-assets/png/printer_ikon_grey.png';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { deepCopy } from 'shared/helpers/general-helpers';
import { RefType } from 'shared/helpers/ref-type';
import { AnalysisModalMode } from 'shared/state/ducks/analysis/analysis-modal/operation';
import { GetAnalysisResultYoungFemaleAgeData } from 'shared/state/ducks/analysis/result-young-female-age/operations';
import { SaveAnalysisSetting } from 'shared/state/ducks/analysis/settings/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { SkioldButton } from '../../skiold-components/skiold-button/skiold-button';
import { SkioldModal } from '../../skiold-components/skiold-modal/skiold-modal';
import { SkioldIconSpinner } from '../../utils/skiold-icon-spinner';
import { AnalysisResultPerLitterItemFromParent } from '../analysis-helper';
import AnalysisResultsAnalysisFarrowingsOverview from '../analysis-modals/analysis-results-analysis-farrowings/analysis-results-analysis-farrowings-overview';
import AnalysisResultsAnalysisMatingsOverview from '../analysis-modals/analysis-results-analysis-matings/analysis-results-analysis-matings-overview';
import AnalysisResultsAnalysisWeaningsOverview from '../analysis-modals/analysis-results-analysis-weanings/analysis-results-analysis-weanings-overview';
import { AnalysisResultYoungFemaleAgeDefinitionsTable } from './analysis-result-young-female-age-definitions/analysis-result-young-female-age-definitions-table';
import { initAnalysisResultYoungFemaleAgeSetting } from './analysis-result-young-female-age-definitions/analysis-result-young-female-age-definitions-table-helper';
import AnalysisResultsYoungFemaleAgeModalOverview from './analysis-result-young-female-age-modal-overview/analysis-result-young-female-age-modal-overview';
import AnalysisResultsYoungFemaleAgeTable, {
	AnalysisResultsYoungFemaleAgeTable as AnalysisResultsYoungFemaleAgeTableRef,
} from './analysis-result-young-female-age-table';
import { generatePeriodDto } from 'web/web-helpers/pdf-helper/analysis-pdf-helpers/analysis-pdf-helper';

interface PropsFromParent {
	topRight?: (topRight: JSX.Element) => void;
	dateFrom?: Date;
	dateTo?: Date;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		siteId: state.profile.active!.siteId,
		profile: state.profile.active!,
		dashboardTableItems:
			state.dashboardOverview.data && state.dashboardOverview.data.dashboardTableData
				? state.dashboardOverview.data.dashboardTableData
				: {},
		loading: state.dashboardOverview.loading,
		setting: state.analysisSettings.analysisSettings.find(
			analysisSetting => analysisSetting.type === AnalysisSettingType.AnalysisResultYoungFemaleAgeSetting,
		),
		matingBatches: state.matingBatches.entities,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
	return {
		getAnalysisResultYoungFemaleAgeData: (fromDate?: Date, toDate?: Date) =>
			GetAnalysisResultYoungFemaleAgeData(fromDate, toDate)(dispatch),
		saveAnalysisSettings: setting => SaveAnalysisSetting(setting)(dispatch),
	};
};

export interface State {
	settings: IAnalysisResultYoungFemaleAgeSetting;
	modalOpen: boolean;
	selectedModal?: string;
	selectedYoungFemaleRange: string;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

export class AnalysisResultsYoungFemaleOverview extends React.PureComponent<Props, State> {
	public analysisResultsPerLitterTableRef: AnalysisResultsYoungFemaleAgeTableRef | undefined;
	constructor(props: Props) {
		super(props);

		this.state = {
			modalOpen: false,
			selectedModal: undefined,
			selectedYoungFemaleRange: '',
			settings: initAnalysisResultYoungFemaleAgeSetting(props.setting, props.siteId),
		};
	}

	public componentDidMount() {
		if (this.props.topRight) {
			this.fetchData(this.props.dateFrom, this.props.dateTo);
			this.props.topRight(this.renderTopRight());
		}
	}

	public render() {
		return (
			<ViewWeb>
				<AnalysisResultYoungFemaleAgeDefinitionsTable
					onSettingChanged={this.settingChanged}
					setting={this.state.settings}
				/>
				<AnalysisResultsYoungFemaleAgeTable
					setting={this.props.setting}
					ref={this.setRef as any}
					openModal={this.OpenModal}
				/>
				{
					<SkioldModal
						shouldCloseOnOverlayClick={true}
						padding="0"
						isOpen={this.state.modalOpen}
						close={this.closeModal}
						justify-content="flex-end"
						maxWidth="calc(100% - 200px)"
					>
						{this.state.selectedModal === 'AnalysisResultsYoungFemaleAgeModalOverview' && (
							<AnalysisResultsYoungFemaleAgeModalOverview
								closeModal={this.closeModal}
								dateFrom={this.props.dateFrom}
								dateTo={this.props.dateTo}
								setting={this.props.setting as IAnalysisResultYoungFemaleAgeSetting}
							/>
						)}
						{this.state.selectedModal === 'TotalMatings' && (
							<AnalysisResultsAnalysisMatingsOverview
								fetchDataValue={this.state.selectedYoungFemaleRange}
								dateFrom={this.props.dateFrom}
								dateTo={this.props.dateTo}
								closeModal={this.closeModal}
								mode={AnalysisModalMode.YoungFemaleAge}
							/>
						)}
						{this.state.selectedModal === 'TotalFarrowings' && (
							<AnalysisResultsAnalysisFarrowingsOverview
								fetchDataValue={this.state.selectedYoungFemaleRange}
								dateFrom={this.props.dateFrom}
								dateTo={this.props.dateTo}
								closeModal={this.closeModal}
								mode={AnalysisModalMode.YoungFemaleAge}
							/>
						)}
						{this.state.selectedModal === 'AllWeanings' && (
							<AnalysisResultsAnalysisWeaningsOverview
								fetchDataValue={this.state.selectedYoungFemaleRange}
								dateFrom={this.props.dateFrom}
								dateTo={this.props.dateTo}
								closeModal={this.closeModal}
								mode={AnalysisModalMode.YoungFemaleAge}
							/>
						)}
					</SkioldModal>
				}
			</ViewWeb>
		);
	}

	private settingChanged = (setting?: IAnalysisResultYoungFemaleAgeSetting) => {
		if (setting) {
			this.setState({ settings: setting }, this.saveSettings);
		}
	};

	// push periods one column from left to right
	private saveSettings = () => {
		if (this.state.settings.agePeriods![0].fromAge && this.state.settings.agePeriods![0].toAge) {
			const settingCopy = deepCopy(this.state.settings);
			this.setState(prevState => {
				const periods = [...prevState.settings.agePeriods!];
				let valid = true;
				for (let index = 0; index < periods.length; index++) {
					if (
						index !== 0 &&
						periods[index - 1].fromAge &&
						periods[index - 1].toAge &&
						periods[index].fromAge &&
						periods[index].toAge
					) {
						const previousAgePeriodToAge = periods[index - 1].toAge;
						const AgePeriodFromAge = periods[index].fromAge;
						if (
							!previousAgePeriodToAge ||
							!AgePeriodFromAge ||
							previousAgePeriodToAge >= AgePeriodFromAge
						) {
							valid = false;
							alert(
								this.getAgePeriod(index) +
									localized(ExceptionMessage.VALIDATION_ERROR_MISSING_AGE_PERIOD),
							);
						}
					} else if (
						(!periods[index].fromAge && periods[index].toAge) ||
						(!periods[index].toAge && periods[index].fromAge)
					) {
						valid = false;
					}

					if (periods[index].toAge && periods[index].fromAge! > periods[index].toAge!) {
						valid = false;
						alert(
							this.getAgePeriod(index) +
								localized(ExceptionMessage.VALIDATION_ERROR_AGE_TO_IS_LESS_THAN_FROM_DATE),
						);
					}
				}

				settingCopy.agePeriods = periods;
				if (valid) {
					this.props.saveAnalysisSettings(settingCopy);
				}

				return { settings: settingCopy };
			});
		}
	};

	private getAgePeriod(index: number) {
		return localized('AgePeriod') + ' ' + (index + 1);
	}

	private setRef = (ref?: AnalysisResultsYoungFemaleAgeTableRef) => (this.analysisResultsPerLitterTableRef = ref);

	private closeModal = () => {
		this.setState({ modalOpen: false, selectedModal: undefined, selectedYoungFemaleRange: '' });
	};

	private OpenModal = (itemFromParent: AnalysisResultPerLitterItemFromParent) => {
		this.setState({
			modalOpen: true,
			selectedModal: itemFromParent.tableItem.name,
			selectedYoungFemaleRange: itemFromParent.key,
		});
	};

	public renderTopRight = () => {
		return (
			<ViewWeb className="flexDirectionRow">
				<SkioldButton
					className="alignButtonCenter"
					title={localized('Elaboration')}
					onPress={this.OpenAnalysisResultsYoungFemaleAgeModalOverview}
				/>
				<ViewWeb className="alignItemsCenter">
					<SkioldIconSpinner title={localized('PrintList')} icon={PrinterGreyIcon} onPress={this.printData} />
				</ViewWeb>
			</ViewWeb>
		);
	};

	private OpenAnalysisResultsYoungFemaleAgeModalOverview = () => {
		this.setState({ modalOpen: true, selectedModal: 'AnalysisResultsYoungFemaleAgeModalOverview' });
	};

	public fetchData = (dateFrom?: Date, dateTo?: Date) => {
		if (dateFrom && dateTo) {
			this.props.getAnalysisResultYoungFemaleAgeData(dateFrom, dateTo);
		}
	};

	private printData = () => {
		if (this.analysisResultsPerLitterTableRef) {
			this.analysisResultsPerLitterTableRef.printData(generatePeriodDto(this.props.dateFrom, this.props.dateTo));
		}
	};
}

export default connect<ReturnType<typeof mapStateToProps>, ReturnType<typeof mapDispatchToProps>, RefType, WebAppState>(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{
		forwardRef: true,
	},
)(AnalysisResultsYoungFemaleOverview);
