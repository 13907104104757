import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Building, Pen, Section } from 'shared/api/api';
import { getDateString } from 'shared/helpers/date-helpers';
import { rangeFilterMethodGrid, setOneDecimalsAsNumber } from 'shared/helpers/general-helpers';
import { GrowthPigDepartureEventListRow } from 'shared/helpers/growth-pigs-helper/growth-pig-event-list-helper';
import { memoizeHashmapLocation } from 'shared/helpers/memoize-getters/memoize-getters';
import { NaturalSortDates } from 'shared/helpers/natural-sort';
import { getGrowthPigsDepartureEventsByDates } from 'shared/state/ducks/growth-pig-events/operations';
import { localized } from 'shared/state/i18n/i18n';
import { DepartureTypes } from 'shared/state/models/departure-types';
import { WebAppState } from 'web/state/store.web';
import { useGpeDashboard } from 'web/view/pages/dashboard-overview-growth-pigs/dashboard-overview-growth-pigs';
import { SkioldButton } from '../skiold-components/skiold-button/skiold-button';
import { SkioldModal } from '../skiold-components/skiold-modal/skiold-modal';
import SkioldTableGrid from '../skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { SowListConstants } from '../stem-animal/animal-lists/table-constants';
import { WhiteText } from '../Text/white-text';
import { Heading } from '../utils/heading';
import { ViewWeb } from '../utils/web-view';
import { generateLast7DaysDeadPutDown } from './dash-growth-pigs-helper';

interface PropsFromParent {}

export const DeadPutDownLast7Days: FC<PropsFromParent> = React.memo(() => {
	const dispatch = useDispatch();

	// Get data
	const { growthPigEvents } = useSelector((state: WebAppState) => state.growthPigEvents);
	const reasons = useSelector((state: WebAppState) => state.reasons.entities);
	const language = useSelector((state: WebAppState) => state.profile.active && state.profile.active.language);
	const hashmapLocation = useSelector((state: WebAppState) => memoizeHashmapLocation(state.locations.buildings,
		state.locations.sections,
		state.locations.pens));

	const { isDeadOpen, closeDeadModal, sectionId, penId } = useGpeDashboard();

	const [data, setData] = useState<GrowthPigDepartureEventListRow[]>([] as GrowthPigDepartureEventListRow[]);
	const [locationString, setLocationString] = useState<string>('');
	const [penString, setPenString] = useState<string>('');
	const [toDate] = useState(new Date().endOfDayTime());
	const [fromDate] = useState(moment().subtract(6, 'days').toDate().withoutTime());
	// Component did mount
	useEffect(() => {
		if (isDeadOpen) {
			getGrowthPigsDepartureEventsByDates(fromDate, toDate)(dispatch);
		}
	}, [isDeadOpen, dispatch, fromDate, toDate]);

	// Generate data on growthPigEvents change
	useEffect(() => {
		if (isDeadOpen) {
			setData(
				generateLast7DaysDeadPutDown(fromDate, toDate, sectionId, penId, growthPigEvents, reasons, language),
			);
		}
	}, [growthPigEvents, sectionId, penId, fromDate, toDate, reasons, language, isDeadOpen]);

	// Set location string
	useEffect(() => {
		if (sectionId) {
			let section = hashmapLocation[sectionId] as Section;
			if (section.buildingId) {
				let building = hashmapLocation[section.buildingId] as Building;
				setLocationString(`${building.name} - ${section.name}`);
			}
		}
	}, [sectionId, hashmapLocation]);

	// Set pen string
	useEffect(() => {
		if (penId) {
			let pen = hashmapLocation[penId] as Pen;
			setPenString(`${localized('pen')}: ${pen.name}`);
		} else {
			setPenString('');
		}
	}, [penId, hashmapLocation]);

	return (
		<SkioldModal
			shouldCloseOnOverlayClick={true}
			padding="0"
			isOpen={isDeadOpen}
			close={closeDeadModal}
			justify-content="flex-end"
			max-width="calc(100% - 220px)"
		>
			<Heading text={localized('deadLast7DaysTitle')} />
			<ViewWeb className="dead-putdown-last-7-days">
				<ViewWeb className="header-container">
					<WhiteText> {locationString}</WhiteText>
					<WhiteText>{penString}</WhiteText>
				</ViewWeb>
				<SkioldTableGrid
					tableKey={'DeadPutDownLast7Days'}
					ignoreSetCount={true}
					ColumnExtensions={generateColumnExtes}
					columns={generateColumns()}
					data={data}
				/>
				<SkioldButton className="close-btn" title={localized('Close')} onPress={closeDeadModal} />
			</ViewWeb>
		</SkioldModal>
	);
});

// Setup table data
const getAmountCell = (item: GrowthPigDepartureEventListRow) => item.pcs;
const getDepartureCell = (item: GrowthPigDepartureEventListRow) =>
	item.departuredType && localized(item.departuredType);
const getTotalWeightCell = (item: GrowthPigDepartureEventListRow) =>
	item.departuredType === DepartureTypes.departureTypeKilled ? '' : setOneDecimalsAsNumber(item.departedWeight);
const getAvgWeightCell = (item: GrowthPigDepartureEventListRow) =>
	item.departuredType === DepartureTypes.departureTypeKilled ? '' : setOneDecimalsAsNumber(item.avgWeight);
const getDateCell = (item: GrowthPigDepartureEventListRow) => getDateString(item.date);
const getReasonCell = (item: GrowthPigDepartureEventListRow) => item.reason;

const generateColumns = () => [
	{
		name: 'date',
		title: localized('Date'),
		getCellValue: getDateCell,
		sortFunction: NaturalSortDates,
	},
	{
		filterFunction: rangeFilterMethodGrid,
		name: 'amount',
		title: localized('Count'),
		getCellValue: getAmountCell,
	},
	{
		filterFunction: rangeFilterMethodGrid,
		name: 'weight',
		title: localized('Weight'),
		getCellValue: getTotalWeightCell,
	},
	{
		name: 'avgWeight',
		filterFunction: rangeFilterMethodGrid,
		title: <ViewWeb className="column-word-wrap">{localized('avgWeight')}</ViewWeb>,
		getCellValue: getAvgWeightCell,
	},
	{ name: 'departure', title: localized('departure'), getCellValue: getDepartureCell },
	{ name: 'departureReason', title: localized('reason'), getCellValue: getReasonCell },
];

const generateColumnExtes = [
	{
		columnName: 'date',
		width: SowListConstants.dateWidth,
	},
	{
		columnName: 'amount',
		width: SowListConstants.countWidth,
	},
	{
		columnName: 'weight',
		width: SowListConstants.weightWidth,
	},
	{
		columnName: 'avgWeight',
		width: SowListConstants.avgWeightWidth,
	},
	{
		columnName: 'departure',
		width: SowListConstants.avgWeightWidth,
	},
	{
		columnName: 'departureReason',
		width: SowListConstants.locationWidth,
	},
];
