import ObjectID from 'bson-objectid';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IMatingBatch, IMatingBatchSetting, MatingBatch } from 'shared/api/api';
import { getDateString } from 'shared/helpers/date-helpers';
import { calculateDays } from 'shared/helpers/general-helpers';
import { RefType } from 'shared/helpers/ref-type';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { showAlert } from 'web/view/components/skiold-alert/skiold-alert';
import { SkioldIntegerInput } from 'web/view/components/skiold-components/skiold-integer-input/skiold-integer-input';
import SkioldTableGrid from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { GroupedHeader } from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid-grouped-header';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { repeatManuel } from './mating-batch-setting-helper';
import './mating-batch-setting.scss';

const mapStateToProps = (state: WebAppState) => {
	return {
		siteId: state.profile.active!.siteId,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({});

interface PropsFromParent {
	matingBatchSetting: IMatingBatchSetting;
}

interface State {
	matingBatches: IMatingBatch[];
	endDate?: Date;
	invalidId: string | undefined;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;
class ManuelMatingBatchSettings extends React.PureComponent<Props, State> {
	public SkioldTableRef: any;

	constructor(props: Props) {
		super(props);

		this.state = {
			matingBatches: [],
			endDate: undefined,
			invalidId: undefined,
		};
	}

	public getManuelMatingBatches = () => {
		return this.state.matingBatches;
	};

	public render() {
		return (
			<ViewWeb className="manuel-list-container">
				<SkioldTableGrid
					columns={this.generateColumns()}
					data={this.state.matingBatches}
					ColumnExtensions={this.generateColumnsExtensions()}
					groupedColumns={this.groupedColumns()}
					filterable={false}
					sortable={false}
					className="no-border-radius"
				/>
			</ViewWeb>
		);
	}

	public repeatMatingBatches = (timesToRepeat?: number) => {
		if (this.state.matingBatches.find(batch => batch.matingPeriodStart === undefined)) {
			return;
		} else {
			if (timesToRepeat) {
				let repeatBatches = repeatManuel(
					timesToRepeat,
					this.props.matingBatchSetting.weekCycle,
					this.props.matingBatchSetting.matingPeriod,
					this.state.matingBatches,
					this.props.siteId,
				);
				this.setState({
					matingBatches: [...this.state.matingBatches, ...repeatBatches],
					endDate: repeatBatches[repeatBatches.length - 1].matingPeriodEnd,
				});
			}
		}
	};

	public initManuelMatingBatches = () => {
		let matingBatches: IMatingBatch[] = [];
		const daysToAdd = this.props.matingBatchSetting.weekCycle! * this.props.matingBatchSetting.matingPeriod!;
		const trueEndDate = moment(this.props.matingBatchSetting.startDate)
			.add(daysToAdd, 'day')
			.subtract(1, 'second')
			.toDate();

		matingBatches.push(
			MatingBatch.fromJS({
				siteId: this.props.siteId,
				id: new ObjectID().toHexString(),
				batchNumber: 1,
				matingPeriodStart: this.props.matingBatchSetting.startDate,
				matingPeriodEnd: trueEndDate,
				farrowingPeriodStart: this.props.matingBatchSetting.pregnantDay
					? this.setStart(this.props.matingBatchSetting.pregnantDay, this.props.matingBatchSetting.startDate)
					: undefined!,
				farrowingPeriodEnd: this.props.matingBatchSetting.pregnantDay
					? this.setEndByStart(
							daysToAdd + this.props.matingBatchSetting.pregnantDay,
							this.props.matingBatchSetting.startDate,
					  )
					: undefined!,
			} as IMatingBatch),
		);
		this.setState({ matingBatches, endDate: trueEndDate });
	};

	private groupedColumns = () => {
		const groupedHeader: GroupedHeader[] = [
			{
				title: localized('matingPeriod'),
				children: [{ columnName: 'matingPeriodStart' }, { columnName: 'matingPeriodEnd' }],
			},
			{
				title: localized('FarrowingPeriod'),
				children: [{ columnName: 'farrowingPeriodStart' }, { columnName: 'farrowingPeriodEnd' }],
			},
			{
				title: localized('weaningPeriod'),
				children: [{ columnName: 'weaningPeriodStart' }, { columnName: 'weaningPeriodEnd' }],
			},
		];
		return groupedHeader;
	};

	public generateColumnsExtensions() {
		return [
			{
				width: 100,
				columnName: 'batchNumber',
			},
			{
				width: 60,
				columnName: 'days',
			},
			{
				width: 85,
				columnName: 'matingPeriodStart',
			},
			{
				width: 85,
				columnName: 'matingPeriodEnd',
			},
			{
				width: 85,
				columnName: 'farrowingPeriodStart',
			},
			{
				width: 85,
				columnName: 'farrowingPeriodEnd',
			},
			{
				width: 85,
				columnName: 'weaningPeriodStart',
			},
			{
				width: 85,
				columnName: 'weaningPeriodEnd',
			},
		];
	}

	private matingBatchDaysComponent = (batch: MatingBatch) => {
		return (
			<SkioldIntegerInput
				key={batch.id}
				stateless={false}
				onBlur={days => {
					if (days) {
						let batchesCopy = [...this.state.matingBatches];

						// const batchIndex = this.state.matingBatches.findIndex(b => b.id === batch.id);
						// if (batchIndex !== this.state.matingBatches.length - 1) {
						// }

						// batchesCopy[batchIndex] = this.generateMatingBatchByDays(batchesCopy[batchIndex], days);
						// batchesCopy.push(this.setupNextBatch(batchesCopy[batchIndex]));
						const aa = this.propagateMatingBatches(batchesCopy, batch, days);
						if (aa[aa.length - 1].matingPeriodStart! > aa[aa.length - 1].matingPeriodEnd!) {
							showAlert(localized('daysExceeded'));
							this.setState({ invalidId: batch.id });
						} else {
							this.setState({ matingBatches: aa, invalidId: undefined });
						}
					}
				}}
				text={
					batch.matingPeriodStart && batch.matingPeriodEnd
						? calculateDays(batch.matingPeriodStart, batch.matingPeriodEnd) + 1
						: undefined
				}
				className={`text-input-style ${this.state.invalidId === batch.id && 'red-text'}`}
			/>
		);
	};

	private propagateMatingBatches = (batches: IMatingBatch[], currentBatch: IMatingBatch, days: number) => {
		//Insert the changed batch
		const batchIndex = batches.findIndex(b => b.id === currentBatch.id);
		batches[batchIndex] = this.generateMatingBatchByDateAndDays(batches[batchIndex], days);

		//If handled batch, is the last one, insert a new with remaining days
		if (batchIndex === batches.length - 1) {
			if (batches[batchIndex].matingPeriodEnd!.getTime() !== this.state.endDate!.getTime()) {
				batches.push(this.setupLastBatch(batches[batchIndex]));
			}
		} else {
			//Propagate changes, to ensure all dates are legit
			//Skip the first one, since it is the one edited first in this function
			for (let i = batchIndex + 1; i < batches.length; i++) {
				if (i !== batches.length - 1) {
					let days = calculateDays(batches[i].matingPeriodStart!, batches[i].matingPeriodEnd) + 1;
					batches[i] = this.generateMatingBatchByDateAndDays(
						batches[i],
						days,
						batches[i - 1].matingPeriodEnd,
					);
				} else {
					batches[i] = this.setupLastBatch(batches[i - 1]);
				}
			}
		}

		return batches;
	};

	private setupLastBatch = (batch: IMatingBatch) => {
		let newBatch = MatingBatch.fromJS({});
		newBatch.batchNumber = batch.batchNumber! + 1;

		newBatch.siteId = this.props.siteId;
		newBatch.id = new ObjectID().toHexString();

		newBatch.matingPeriodStart = this.setStartByEnd(batch.matingPeriodEnd);
		newBatch.matingPeriodEnd = this.state.endDate!;

		let days = calculateDays(newBatch.matingPeriodStart!, newBatch.matingPeriodEnd) + 1;
		newBatch.farrowingPeriodStart = this.setStart(
			this.props.matingBatchSetting.pregnantDay,
			newBatch.matingPeriodStart,
		);
		newBatch.farrowingPeriodEnd = this.setEndByStart(days, newBatch.farrowingPeriodStart);

		return newBatch;
	};

	private generateMatingBatchByDateAndDays = (batch: IMatingBatch, days: number, prevEndDate?: Date) => {
		let batchToEdit = { ...batch };
		if (prevEndDate) {
			batchToEdit.matingPeriodStart = this.setStartByEnd(prevEndDate);
		}

		batchToEdit.matingPeriodEnd = this.setEndByStart(days, batchToEdit.matingPeriodStart);
		batchToEdit.farrowingPeriodStart = this.setStart(
			this.props.matingBatchSetting.pregnantDay,
			batchToEdit.matingPeriodStart,
		);
		batchToEdit.farrowingPeriodEnd = this.setEndByStart(days, batchToEdit.farrowingPeriodStart);
		return batchToEdit;
	};

	private setStartByEnd = (date?: Date) => {
		return moment(date).add(1, 'second').toDate();
	};

	private setStart = (days?: number, date?: Date) => {
		return moment(date).add(days, 'days').toDate();
	};

	//private setEnd = (days?: number, date?: Date) => {};

	private setEndByStart = (days?: number, date?: Date) => {
		return moment(date).add(days, 'days').subtract(1, 'second').toDate();
	};

	private matingBatchNumberComponent = (batch: MatingBatch) => {
		const numberChanged = (batchNumber: number | undefined) => {
			const batchIndex = this.state.matingBatches.findIndex(b => b.id === batch.id);
			let batchesCopy = [...this.state.matingBatches];
			batchesCopy[batchIndex] = { ...batchesCopy[batchIndex], batchNumber: batchNumber! };

			this.setState({ matingBatches: batchesCopy });
		};

		return (
			<SkioldIntegerInput text={batch.batchNumber} onChangeNumber={numberChanged} className="text-input-style" />
		);
	};

	private generateColumns() {
		return [
			{
				title: localized('batchNum'),
				name: 'batchNumber',
				getCellValue: this.matingBatchNumberComponent,
				isFixedLeft: true, // Is temp, because resize only is available if one column is fixed atm.
			},
			{
				title: localized('DAYS'),
				name: 'days',
				getCellValue: this.matingBatchDaysComponent,
			},
			{
				title: localized('Start'),
				getCellValue: (d: MatingBatch) => getDateString(d.matingPeriodStart),
				name: 'matingPeriodStart',
			},
			{
				title: localized('End'),
				name: 'matingPeriodEnd',
				getCellValue: (d: MatingBatch) => getDateString(d.matingPeriodEnd),
			},
			{
				title: localized('Start'),
				name: 'farrowingPeriodStart',
				getCellValue: (d: MatingBatch) => getDateString(d.farrowingPeriodStart),
			},
			{
				title: localized('End'),
				name: 'farrowingPeriodEnd',
				getCellValue: (d: MatingBatch) => getDateString(d.farrowingPeriodEnd),
			},
			{
				title: localized('Start'),
				name: 'weaningPeriodStart',
				getCellValue: (d: MatingBatch) =>
					d.farrowingPeriodStart &&
					getDateString(
						moment(d.farrowingPeriodStart).add(this.props.matingBatchSetting.nursingDays, 'days').toDate(),
					),
			},
			{
				title: localized('End'),
				name: 'weaningPeriodEnd',
				getCellValue: (d: MatingBatch) =>
					d.farrowingPeriodEnd &&
					getDateString(
						moment(d.farrowingPeriodEnd).add(this.props.matingBatchSetting.nursingDays, 'days').toDate(),
					),
			},
		];
	}
}

export default connect<ReturnType<typeof mapStateToProps>, ReturnType<typeof mapDispatchToProps>, RefType, WebAppState>(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{ forwardRef: true },
)(ManuelMatingBatchSettings);
