import ObjectID from 'bson-objectid';
import memoize from 'memoize-one';
import moment from 'moment';
import { Option } from 'react-dropdown';
import {
	AnimalType,
	GrowthPigDepartureEventDto,
	GrowthPigDepartureFromPenEventDto,
	GrowthPigEntranceEventDto,
	GrowthPigEventDistribution,
	GrowthPigEventType,
	GrowthPigMoveEventDto,
	GrowthPigsEvent,
	GrowthPigTuneProduction,
	IGeneralSettings,
	IGrowthPigDepartureEventDto,
	IGrowthPigEntranceEventDto,
	IGrowthPigEventDistribution,
	IGrowthPigEventDto,
	IGrowthPigMoveEventDto,
	IGrowthPigsEvent,
	IGrowthPigTuneProduction,
	IPen,
	IReasonDto,
	ISection,
	IStemAnimal,
	IUserProfile,
	LocationType,
} from 'shared/api/api';
import { LocationsState } from 'shared/state/ducks/locations';
import { localized, localizedInterpolation, localizedInterpolationMultiple } from 'shared/state/i18n/i18n';
import { StoreContainer } from 'shared/state/store-container';
import { areDatesEqual, areDateTimesEqual, isDateSameOrBefore } from '../date-helpers';
import { ExceptionMessage } from '../exception-message';
import { deepCopy, deepCopy2 } from '../general-helpers';
import { CheckIfSectionUsesPens, getPenRetentionTime, NaturalSortPens, sortPensByName } from '../location-helper';
import { NaturalSort, NaturalSortDates, NaturalSortDatesOnly } from '../natural-sort';
import {
	DefaultGrowthTypeDepartureTypeOption,
	DistributeTableDataTypes,
	GrowthPigDepartureFromSectionRow,
	GrowthPigDeparturePenState,
	GrowthPigDepartureProps,
	GrowthPigDepartureState,
	GrowthPigDistributeFormProps,
	GrowthPigDistributeMoveEventProps,
	GrowthPigDistributeState,
	GrowthPigEntranceDistributeProps,
	GrowthPigEntranceState,
	GrowthPigMoveEventBaseEventProps,
	GrowthPigMoveEventBasePropsFromParent,
	GrowthPigMoveEventBaseState,
	GrowthPigMoveState,
	GrowthPigProductionTypeProps,
	MoveAllTreatedEnum,
} from './growth-pig-event-helper';
import { GetTotalWeightByEntrance } from './growth-pig-weight-curve-helper';
import { Monitoring } from '../monitoring-service';
import { PieChartTwoTone } from '@material-ui/icons';
import { DepartureTypes } from 'shared/state/models/departure-types';

export function generateDataForMoveEventDistribute(pensToUse: IPen[], events: GrowthPigDepartureFromSectionRow[]) {
	// Filter away gpe's without any departedFromSection
	// If pen has negativ pcs, nothing can be moved, and therefore it is sorted away
	const filteredData = events.filter(e => e.departedFromSection && e.departedFromSection > 0);
	const data: IGrowthPigMoveEventDto[] = [];
	let used = 0;
	const sum = filteredData.reduce(
		(sum, current) => sum + (current.departedFromSection !== undefined ? current.departedFromSection : 0),
		0,
	);
	const sumWeight = filteredData.reduce(
		(sumWeight, current) =>
			sumWeight +
			(current.newAvgWeight !== undefined && current.departedFromSection && current.departedFromSection > 0
				? current.newAvgWeight * current.departedFromSection
				: 0),
		0,
	);

	const sumTotal = filteredData.reduce(
		(sum, current) =>
			sum + (current.departedFromSection && current.departedFromSection > 0 ? current.departedFromSection : 0),
		0,
	);

	const avgWeight = Math.round((sumWeight / sumTotal) * 10) / 10;
	let eventUsedNumber = 0;
	let eventIndex = 0;
	while (used < sum) {
		for (let index = 0; index < pensToUse.length; index++) {
			const pen = pensToUse[index];
			if (used === sum) {
				continue;
			}
			const ev = filteredData[eventIndex];
			if (ev.departedFromSection !== undefined && ev.oldAvgWeight !== undefined) {
				const indexOfData = data.findIndex(a => a.toPenId === pen.id);
				if (ev.departedFromSection && indexOfData < 0) {
					data.push({
						name: pen.name,
						toBuildingId: pen.buildingId,
						toSectionId: pen.sectionId,
						toPenId: pen.id,
						amount: 1,
						avgWeight: avgWeight,
						totalWeight: avgWeight ? avgWeight : 0,
						oldTotalWeight: ev.oldAvgWeight,
					} as IGrowthPigMoveEventDto);
				} else if (ev.departedFromSection && indexOfData >= 0) {
					data[indexOfData].amount = (data[indexOfData].amount ? data[indexOfData].amount! : 0) + 1;
					data[indexOfData].totalWeight! += avgWeight ? avgWeight : 0;
					data[indexOfData].oldTotalWeight! += ev.oldAvgWeight;
				}
				eventUsedNumber += 1;
				used += 1;
				if (ev.departedFromSection === eventUsedNumber) {
					eventIndex += 1;
					eventUsedNumber = 0;
				}
			}
		}
	}
	while (pensToUse.length > data.length) {
		pensToUse.forEach(pen => {
			if (data.find(d => d.toPenId === pen.id) === undefined) {
				data.push({
					name: pen.name,
					toBuildingId: pen.buildingId,
					toSectionId: pen.sectionId,
					toPenId: pen.id,
					amount: 0,
					avgWeight: avgWeight,
					totalWeight: 0,
				} as IGrowthPigMoveEventDto);
			}
		});
	}
	return data;
}

export function getMoveEventDtosForBase(
	state: GrowthPigMoveEventBaseState,
	props: GrowthPigMoveEventBaseEventProps,
	amountMoved?: number,
	avgWeight?: number,
) {
	const moveEventDtos: IGrowthPigMoveEventDto[] = [];
	const totalAmountMoved = amountMoved ? amountMoved : props.amountMoved;
	const avgWeightProps = avgWeight ? avgWeight : props.avgWeight;
	if (props.distribution && props.amountMoved) {
		const currentDate = new Date();

		const ev = props.distribution;
		if (totalAmountMoved && ev.penId && state.penId && avgWeightProps) {
			const fromPen = props.locations[ev.penId] as IPen;
			const toPen = props.locations[state.penId] as IPen;
			moveEventDtos.push(
				GenerateMoveEventDto(fromPen, toPen, state, props, ev, currentDate, totalAmountMoved, avgWeightProps),
			);
		}
	}
	return moveEventDtos as GrowthPigMoveEventDto[];
}

export const getGrowthPigEventDistributionsForSectionIdAndPenId = (
	growthPigEvents: IGrowthPigEventDto[],
	sectionId?: string,
	penId?: string,
	productionType?: AnimalType,
) => {
	const gpe = growthPigEvents.find(gpe => gpe.sectionId === sectionId && productionType === gpe.productionType);
	if (gpe && gpe.growthPigEventDistributions) {
		return gpe.growthPigEventDistributions.filter(gped => gped.penId === penId || !penId);
	}
	return undefined;
};

export const getGrowthPigEventDistributionForSectionIdAndPenId = (
	growthPigEvents: IGrowthPigEventDto[],
	sectionId?: string,
	penId?: string,
	productionType?: AnimalType,
) => {
	const gpe = growthPigEvents.find(gpe => gpe.sectionId === sectionId && productionType === gpe.productionType);
	if (gpe && gpe.growthPigEventDistributions) {
		return gpe.growthPigEventDistributions.find(gped => gped.penId === penId);
	}
	return undefined;
};

function GenerateMoveEventDto(
	fromPen: IPen,
	toPen: IPen,
	state: GrowthPigMoveEventBaseState,
	props: GrowthPigMoveEventBaseEventProps,
	growthPigDistribution: IGrowthPigEventDistribution,
	dateCreated: Date,
	amount: number,
	avgWeight: number,
) {
	// Because of a misunderstanding, we get correct production by sectionId
	const correctFromProduction = getCorrectProductionTypeBySectionId(fromPen.sectionId);
	const correctToProduction = getCorrectProductionTypeBySectionId(toPen.sectionId);
	return {
		siteId: props.profile.siteId,
		fromBuildingId: fromPen.buildingId,
		fromSectionId: fromPen.sectionId,
		fromPenId: fromPen.id,
		fromProductionType: growthPigDistribution.productionType,
		toProductionType: state.toProductionType,
		correctFromProductionType: correctFromProduction,
		correctToProductionType: correctToProduction,
		toBuildingId: toPen.buildingId,
		toSectionId: toPen.sectionId,
		toPenId: toPen.id,
		amount: amount,
		avgWeight: avgWeight,
		totalWeight: amount && avgWeight ? amount * avgWeight : 0,
		oldTotalWeight:
			amount *
			(growthPigDistribution.totalWeight && growthPigDistribution.amount
				? growthPigDistribution.totalWeight / growthPigDistribution.amount
				: 0),
		date: props.date,
		modifiedOn: dateCreated,
		createdOn: dateCreated,
		id: new ObjectID().toHexString(),
	} as GrowthPigMoveEventDto;
}

export const validateGrowthPigMoveEventBase = (
	moveEventState: GrowthPigMoveEventBaseState,
	growthPigMoveEventBasePropsFromParent: GrowthPigMoveEventBasePropsFromParent,
	showAlert: (exceptionMessage: string) => void,
) => {
	if (!growthPigMoveEventBasePropsFromParent.date) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_MOVE_DATE_NOT_SET));
		return false;
	}
	if (!growthPigMoveEventBasePropsFromParent.amountMoved) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_NO_PIGS_MOVED));
		return false;
	}
	if (!growthPigMoveEventBasePropsFromParent.productionType) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_TO_PRODUCTIONTYPE_NOT_SET));
		return false;
	}
	if (!moveEventState.sectionId) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_MOVE_STABLE_SECTION_NOT_SET));
		return false;
	}
	if (!moveEventState.penId) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_MOVE_NEW_PEN_ID_NOT_SET));
		return false;
	}
	return true;
};

export const generateLocationOptionsForGrowthPigEventsProductionType = memoize(
	(
		events: IGrowthPigEventDto[],
		productionType: AnimalType,
		locationsState: LocationsState,
		allowFratsLocationType?: boolean,
	) => {
		switch (productionType) {
			case AnimalType.FRATS:
				return generateLocationOptionsForGrowthPigEventsFRATS(events, productionType, locationsState);
			case AnimalType.Weaner:
				return generateLocationOptionsForGrowthPigEventsWeaner(
					events,
					productionType,
					locationsState,
					allowFratsLocationType,
				);
			case AnimalType.Finisher:
				return generateLocationOptionsForGrowthPigEventsFinisher(
					events,
					productionType,
					locationsState,
					allowFratsLocationType,
				);
			case AnimalType.YoungFemale:
				return generateLocationOptionsForGrowthPigEventsYoungFemale(events, productionType, locationsState);
			default:
				return locationsState;
		}
	},
);

export const generateLocationOptionsForGrowthPigEventsFRATS = memoize(
	(events: IGrowthPigEventDto[], productionType: AnimalType, locationsState: LocationsState) => {
		return HandleLocationsToProductionType(locationsState, events, productionType);
	},
);
export const generateLocationOptionsForGrowthPigEventsWeaner = memoize(
	(
		events: IGrowthPigEventDto[],
		productionType: AnimalType,
		locationsState: LocationsState,
		allowFratsLocationType?: boolean,
	) => {
		return HandleLocationsToProductionType(locationsState, events, productionType);
	},
);
export const generateLocationOptionsForGrowthPigEventsFinisher = memoize(
	(
		events: IGrowthPigEventDto[],
		productionType: AnimalType,
		locationsState: LocationsState,
		allowFratsLocationType?: boolean,
	) => {
		return HandleLocationsToProductionType(locationsState, events, productionType);
	},
);
export const generateLocationOptionsForGrowthPigEventsYoungFemale = memoize(
	(events: IGrowthPigEventDto[], productionType: AnimalType, locationsState: LocationsState) => {
		return HandleLocationsToProductionType(locationsState, events, productionType);
	},
);

function HandleLocationsToProductionType(
	locationsState: LocationsState,
	events: IGrowthPigEventDto[],
	productionType: AnimalType,
	allowFratsLocationType?: boolean,
) {
	const locations = { ...locationsState };
	locations.sections = locations.sections.filter(
		s =>
			(productionType === AnimalType.YoungFemale &&
				s.animalType === AnimalType.Sow &&
				s.type === LocationType.YoungFemale) ||
			events.find(gpe => gpe.sectionId === s.id && gpe.productionType === productionType) !== undefined,
	);

	locations.pens = locations.pens.filter(
		pen =>
			(productionType === AnimalType.YoungFemale && locations.sections.find(s => s.id === pen.sectionId)) ||
			events.find(gpe => gpe.growthPigEventDistributions && gpe.productionType === productionType) !== undefined,
	);
	return locations;
}

export function generateGrowthPigEntranceEventDto(
	data: DistributeTableDataTypes[],
	date: Date,
	siteId?: string,
	feedCurve?: number,
	recipeNumber?: number,
) {
	const itemsToSave: IGrowthPigEntranceEventDto[] = [];
	const currentDate = new Date(Date.now());
	data.forEach(item => {
		if (item.amount) {
			item.siteId = siteId;
			item.date = date;
			item.totalWeight = item.avgWeight ? item.avgWeight * item.amount : item.totalWeight;
			item.modifiedOn = currentDate;
			item.createdOn = currentDate;

			let dto = GrowthPigEntranceEventDto.fromJS({ ...item });
			dto.feedCurve = feedCurve;
			dto.recipe = recipeNumber;
			itemsToSave.push(dto);
		}
	});
	return itemsToSave;
}

export function HandleGrowthPigDistributeTotalWeightNotDistributed(
	dataArrayCopy: DistributeTableDataTypes[],
	item: IGrowthPigEntranceEventDto,
	numb: number | undefined,
	totalWeightNotDistributed: number,
) {
	const itemToModifyIndex = dataArrayCopy.findIndex(dac => dac.toPenId === item.toPenId);
	const itemToModify = { ...dataArrayCopy[itemToModifyIndex] };
	let currentTotalWeightNotDistributed = totalWeightNotDistributed;
	if (itemToModify.totalWeight && numb && itemToModify.totalWeight > numb) {
		currentTotalWeightNotDistributed = currentTotalWeightNotDistributed + (itemToModify.totalWeight - numb);
	} else if (itemToModify.totalWeight && numb && itemToModify.totalWeight < numb) {
		currentTotalWeightNotDistributed = currentTotalWeightNotDistributed - (numb - itemToModify.totalWeight);
	} else if (numb && !itemToModify.totalWeight) {
		currentTotalWeightNotDistributed = currentTotalWeightNotDistributed - numb;
	} else if (!numb && itemToModify.totalWeight !== undefined) {
		currentTotalWeightNotDistributed = currentTotalWeightNotDistributed + itemToModify.totalWeight;
	}
	itemToModify.avgWeight = numb!;
	dataArrayCopy[itemToModifyIndex] = itemToModify;
	return currentTotalWeightNotDistributed;
}

export function HandleGrowthPigDistributeAmountChanged(
	dataArrayCopy: DistributeTableDataTypes[],
	item: IGrowthPigEntranceEventDto,
	numb: number | undefined,
	amountPigsNotDistributed: number,
	weightNotDistributed: number,
) {
	const itemToModifyIndex = dataArrayCopy.findIndex(dac => dac.toPenId === item.toPenId);
	const itemToModify = { ...dataArrayCopy[itemToModifyIndex] };
	let currentAmountNotDistributed = amountPigsNotDistributed;
	let totalWeightNotDistributed = weightNotDistributed;
	if (itemToModify.amount && numb && itemToModify.amount > numb) {
		currentAmountNotDistributed = currentAmountNotDistributed + (itemToModify.amount - numb);
	} else if (itemToModify.amount && numb && itemToModify.amount < numb) {
		currentAmountNotDistributed = currentAmountNotDistributed - (numb - itemToModify.amount);
	} else if (numb && !itemToModify.amount) {
		currentAmountNotDistributed = currentAmountNotDistributed - numb;
	} else if (!numb && itemToModify.amount !== undefined) {
		currentAmountNotDistributed = currentAmountNotDistributed + itemToModify.amount;
		if (itemToModify.totalWeight) {
			totalWeightNotDistributed += itemToModify.totalWeight;
			itemToModify.totalWeight = 0;
		}
	}
	itemToModify.amount = numb!;
	dataArrayCopy[itemToModifyIndex] = itemToModify;
	return { currentAmountNotDistributed, totalWeightNotDistributed };
}

export function HandleGrowthPigDistributeAmountChangedFromPen(
	dataArrayCopy: IGrowthPigDepartureEventDto[],
	item: IGrowthPigDepartureEventDto,
	numb: number | undefined,
	amountPigsNotDistributed: number,
	weightNotDistributed: number,
) {
	const itemToModifyIndex = dataArrayCopy.findIndex(dac => dac.fromPenId === item.fromPenId);
	const itemToModify = { ...dataArrayCopy[itemToModifyIndex] };
	let currentAmountNotDistributed = amountPigsNotDistributed;
	let totalWeightNotDistributed = weightNotDistributed;
	if (itemToModify.amount && numb && itemToModify.amount > numb) {
		currentAmountNotDistributed = currentAmountNotDistributed + (itemToModify.amount - numb);
	} else if (itemToModify.amount && numb && itemToModify.amount < numb) {
		currentAmountNotDistributed = currentAmountNotDistributed - (numb - itemToModify.amount);
	} else if (numb && !itemToModify.amount) {
		currentAmountNotDistributed = currentAmountNotDistributed - numb;
	} else if (!numb && itemToModify.amount !== undefined) {
		currentAmountNotDistributed = currentAmountNotDistributed + itemToModify.amount;
		if (itemToModify.totalWeight) {
			totalWeightNotDistributed += itemToModify.totalWeight;
			itemToModify.totalWeight = 0;
		}
	}
	itemToModify.amount = numb!;
	dataArrayCopy[itemToModifyIndex] = itemToModify;
	return { currentAmountNotDistributed, totalWeightNotDistributed };
}

export interface GrowthPigMoveEventDtoExtend extends IGrowthPigMoveEventDto {
	used?: boolean;
	currentUsedAmount?: number;
}

export interface GrowthPigDepartureFromSectionRowExtend extends GrowthPigDepartureFromSectionRow {
	currentUsedAmount?: number;
}

export function HandleWeightMoved(
	currentData: GrowthPigMoveEventDtoExtend[],
	dataFromParent: GrowthPigDepartureFromSectionRowExtend[],
	date: Date,
	siteId: string | undefined,
	fromProductionType: AnimalType,
	toProductionType: AnimalType,
	toSection?: ISection,
	retentionDate?: Date,
	feedCurve?: number,
	recipeNumber?: number,
) {
	const dataToSave: IGrowthPigMoveEventDto[] = [];
	const dataFromParentCopy = deepCopy2(dataFromParent) as GrowthPigDepartureFromSectionRowExtend[];
	const currentDataCopy = deepCopy2(currentData) as GrowthPigMoveEventDtoExtend[];
	const currentDate = new Date();
	let used = 0;
	const sum = dataFromParentCopy.reduce(
		(sum, current) => sum + (current.departedFromSection !== undefined ? current.departedFromSection : 0),
		0,
	);
	while (used < sum) {
		for (let index = 0; index < currentDataCopy.length; ) {
			const data = currentDataCopy[index];
			const indexParent = dataFromParentCopy.findIndex(
				a =>
					a.departedFromSection !== undefined &&
					a.departedFromSection > 0 &&
					(!a.currentUsedAmount ||
						(a.currentUsedAmount && a.departedFromSection && a.currentUsedAmount < a.departedFromSection)),
			);

			if (data.amount && indexParent >= 0) {
				const dataParent = dataFromParentCopy[indexParent];
				let amountToAdd: number =
					dataParent.departedFromSection &&
					(dataParent.currentUsedAmount
						? dataParent.departedFromSection! - dataParent.currentUsedAmount
						: dataParent.departedFromSection) >= data.amount
						? data.amount
						: dataParent.currentUsedAmount
						? dataParent.departedFromSection! - dataParent.currentUsedAmount
						: dataParent.departedFromSection!;
				if (
					data.currentUsedAmount &&
					data.amount &&
					(data.currentUsedAmount ? data.currentUsedAmount + amountToAdd : amountToAdd) > data.amount
				) {
					amountToAdd =
						amountToAdd -
						((data.currentUsedAmount ? data.currentUsedAmount + amountToAdd : amountToAdd) - data.amount!);
				}

				currentDataCopy[index].currentUsedAmount = data.currentUsedAmount
					? data.currentUsedAmount + amountToAdd
					: amountToAdd;
				dataFromParentCopy[indexParent].currentUsedAmount = dataFromParentCopy[indexParent].currentUsedAmount
					? dataFromParentCopy[indexParent].currentUsedAmount! + amountToAdd
					: amountToAdd;

				used += amountToAdd;

				// Because of a misunderstanding, we get correct production by sectionId
				const correctFromProduction = getCorrectProductionTypeBySectionId(dataParent.sectionId);
				const correctToProduction = getCorrectProductionTypeBySectionId(data.toSectionId);

				dataToSave.push(
					GrowthPigMoveEventDto.fromJS({
						id: new ObjectID().toHexString(),
						siteId: siteId,
						fromBuildingId: dataParent.buildingId,
						fromSectionId: dataParent.sectionId,
						fromPenId: dataParent.penId,
						fromProductionType: fromProductionType,
						correctFromProductionType: correctFromProduction,
						toProductionType:
							toSection &&
							(toSection.type === LocationType.ReliefFinisher || toSection.type === LocationType.Finisher)
								? AnimalType.Finisher
								: toProductionType,
						toBuildingId: data.toBuildingId,
						toSectionId: data.toSectionId,
						correctToProductionType: correctToProduction,
						toPenId: data.toPenId,
						amount: amountToAdd,
						avgWeight: dataParent.oldAvgWeight,
						totalWeight: data.avgWeight ? data.avgWeight * amountToAdd : 0,
						oldTotalWeight: amountToAdd * dataParent.oldAvgWeight,
						date: date,
						modifiedOn: currentDate,
						createdOn: currentDate,
						retentionDate: data.retentionDate,
						bundleIdentifier: dataParent.bundleIdentifier,
						feedCurve,
						recipe: recipeNumber,
					} as IGrowthPigMoveEventDto),
				);
			}
			if (
				currentDataCopy[index].currentUsedAmount! >= currentDataCopy[index].amount! ||
				!currentDataCopy[index].amount
			) {
				index++;
			}
		}
	}
	return dataToSave;
}

export function generateOptionsForGrowthPigEventDistribute(pensToUse: IPen[], fromPen: IPen, toPen: IPen) {
	const fromOptions: Option[] = [];
	const toOptions: Option[] = [];
	const fromPenToUseIndex = pensToUse.findIndex(p => p.id === fromPen.id);
	const toPenToUseIndex = pensToUse.findIndex(p => p.id === toPen.id);
	const fromPens = pensToUse.slice(0, toPenToUseIndex + 1);
	const toPens = pensToUse.slice(fromPenToUseIndex, pensToUse.length);
	fromPens.forEach(pen => {
		fromOptions.push({ label: pen.name!, value: pen.id! });
	});
	toPens.forEach(pen => {
		toOptions.push({ label: pen.name!, value: pen.id! });
	});
	return { fromOptions, toOptions };
}

export function GetNewPenOptions(prevProps: GrowthPigDistributeFormProps, currentProps: GrowthPigDistributeFormProps) {
	const pensInSection = currentProps.locations.pens
		.filter(p => p.sectionId === currentProps.toSectionId)
		.sort(sortPensByName);
	if (prevProps.fromPenId !== currentProps.fromPenId) {
		const index = pensInSection.findIndex(p => currentProps.fromPenId && p.id === currentProps.fromPenId.value);
		if (index >= 0) {
			const indexTo = pensInSection.findIndex(p => currentProps.toPenId && p.id === currentProps.toPenId.value);
			return generateOptionsForGrowthPigEventDistribute(
				pensInSection,
				pensInSection[index],
				pensInSection[indexTo],
			);
		}
	}
	if (prevProps.toPenId !== currentProps.toPenId) {
		const index = pensInSection.length - 1;
		if (index >= 0) {
			const indexFrom = pensInSection.findIndex(
				p => currentProps.fromPenId && p.id === currentProps.fromPenId.value,
			);
			return generateOptionsForGrowthPigEventDistribute(
				pensInSection,
				pensInSection[indexFrom >= 0 ? indexFrom : 0],
				pensInSection[index],
			);
		}
	}
}

export const getTotalWeightByPenId = (
	sectionId: string | undefined,
	penId: string | undefined,
	dateTo: Date,
	initSubtract: number,
	getAvgWeight?: boolean,
) => {
	const state = StoreContainer.getState();
	const section = state.growthPigEvents.entities.find(e => e.sectionId === sectionId);
	if (section && section.growthPigEventDistributions) {
		const gpesToUse = section.growthPigEventDistributions.filter(e => e.penId === penId);
		const date = moment(dateTo, 'DD/MM/YYYY').endOf('day').toDate();

		// Find most recent regulation for each pen
		// This is to determine which data we need to work on
		let regulations = gpesToUse
			.filter(e => e.growthPigEventType === GrowthPigEventType.Regulation && e.date && e.date < date)
			.groupBy('penId');

		let recentRegulations = {};
		regulations.forEach(reg => {
			let mostRecentDate = new Date(
				Math.max.apply(
					null,
					reg.map(e => {
						return new Date(e.date!).getTime();
					}),
				),
			);
			let mostRecentObject = reg.filter(e => {
				if (e.date) {
					let d = new Date(e.date);
					return d.getTime() === mostRecentDate.getTime();
				}
				return false;
			})[0];

			recentRegulations[mostRecentObject.penId!] = mostRecentObject.date;
		});
		let sorted = gpesToUse.sort((a, b) => {
			if (a.amount && a.amount < 0) {
				return NaturalSort(a.amount, b.amount);
			}

			if (
				a.amount !== undefined &&
				b.amount !== undefined &&
				a.amount >= 0 &&
				b.amount >= 0 &&
				!areDatesEqual(a.date, b.date)
			) {
				return NaturalSortDatesOnly(a.date, b.date);
			} else if (
				a.amount !== undefined &&
				b.amount !== undefined &&
				a.amount >= 0 &&
				b.amount >= 0 &&
				areDatesEqual(a.date, b.date)
			) {
				return NaturalSortDates(a.createdOn, b.createdOn);
			} else if (areDateTimesEqual(a.date, b.date)) {
				return NaturalSort(a.amount, b.amount);
			}

			return NaturalSort(a.amount, b.amount);
		});
		let totalCount: number | undefined = 0;
		let totalWeight: number | undefined = 0;
		let totalWeightCurve: number | undefined = 0;
		sorted.forEach(gped => {
			if (date && gped.date && isDateSameOrBefore(gped.date, date)) {
				if (
					gped.date &&
					gped.penId &&
					recentRegulations[gped.penId] &&
					((gped.date <= recentRegulations[gped.penId] &&
						gped.growthPigEventType !== GrowthPigEventType.Regulation) ||
						(gped.date < recentRegulations[gped.penId] &&
							gped.growthPigEventType === GrowthPigEventType.Regulation))
				) {
					return;
				}

				const currentAmount = totalCount;
				totalCount = (totalCount ? totalCount : 0) + (gped.amount ? gped.amount : 0);

				// If  editable (amount > 0) til weight, else set to 0
				// initSubtract, handle with new weight calculation, i see the potential new weight - Used for regulations
				if (totalCount - initSubtract > 0) {
					//TODO: distribute into smaller methods
					// Handles weight regulations, by overwriting current weight by current amount
					if (gped.growthPigEventType === GrowthPigEventType.RegulationWeight) {
						totalWeight = gped.totalWeight ? totalCount * gped.totalWeight : 0;

						totalWeightCurve =
							gped.date && gped.productionType && date
								? totalCount && totalCount! > 0
									? GetTotalWeightByEntrance(
											gped.date,
											currentAmount && currentAmount < 0
												? (Math.abs(totalWeight) / Math.abs(totalCount)) * totalCount!
												: Math.abs(totalWeight),
											currentAmount && currentAmount < 0 ? totalCount! : Math.abs(totalCount),
											gped.productionType,
											date,
											gped.isSameProduction,
									  )
									: 0
								: gped.totalWeightCurve
								? gped.totalWeightCurve
								: 0;
					} else {
						totalWeight = (totalWeight ? totalWeight! : 0) + (gped.totalWeight ? gped.totalWeight : 0);

						totalWeightCurve =
							(totalWeightCurve ? totalWeightCurve! : 0) +
							(gped.date && gped.totalWeight && gped.productionType && gped.amount && date
								? totalCount && totalCount > 0
									? GetTotalWeightByEntrance(
											gped.date,
											currentAmount !== undefined && currentAmount - initSubtract < 0
												? (Math.abs(gped.totalWeight) / Math.abs(gped.amount)) *
														(totalCount - initSubtract)
												: Math.abs(gped.totalWeight),
											currentAmount !== undefined && currentAmount - initSubtract < 0
												? totalCount - initSubtract
												: Math.abs(gped.amount),
											gped.productionType,
											date,
											gped.isSameProduction,
									  )
									: 0
								: gped.totalWeightCurve
								? gped.totalWeightCurve
								: 0);
					}
				} else {
					totalWeight = 0;
					totalWeightCurve = 0;
				}
			}
		});
		if (getAvgWeight) {
			return totalWeightCurve && totalCount ? Math.round((totalWeightCurve / totalCount) * 10) / 10 : 0;
		}
		return totalWeightCurve;
	}

	return 0;
};

export const generateGrowthPigEventDataForSectionId = (
	section: ISection,
	growthPigsEvents: IGrowthPigEventDto[],
	locationState: LocationsState,
	parentProductionType: AnimalType,
	productionType?: AnimalType,
	dateTo?: Date,
) => {
	let data: GrowthPigDepartureFromSectionRow[] = [];

	const gpeToUse = growthPigsEvents.find(
		gpe =>
			section &&
			gpe.sectionId === section.id &&
			(section.animalType === productionType &&
			productionType &&
			section.type &&
			section.type.toString().includes(parentProductionType)
				? true
				: gpe.productionType === productionType),
	);
	if (gpeToUse !== undefined && gpeToUse.growthPigEventDistributions) {
		const date = moment(dateTo, 'DD/MM/YYYY').endOf('day').toDate();

		// Find most recent regulation for each pen
		// This is to determine which data we need to work on
		let recentRegulations = findMostRecentRegulationsForPens(gpeToUse, date);

		let sorted = gpeToUse.growthPigEventDistributions.sort((a, b) => {
			if (a.amount && a.amount < 0) {
				return NaturalSort(a.amount, b.amount);
			}

			if (
				a.amount !== undefined &&
				b.amount !== undefined &&
				a.amount >= 0 &&
				b.amount >= 0 &&
				!areDatesEqual(a.date, b.date)
			) {
				return NaturalSortDatesOnly(a.date, b.date);
			} else if (
				a.amount !== undefined &&
				b.amount !== undefined &&
				a.amount >= 0 &&
				b.amount >= 0 &&
				areDatesEqual(a.date, b.date)
			) {
				return NaturalSortDates(a.createdOn, b.createdOn);
			} else if (areDateTimesEqual(a.date, b.date)) {
				return NaturalSort(a.amount, b.amount);
			}
			return NaturalSort(a.amount, b.amount);
		});
		sorted.forEach(gped => {
			if (date && gped.date && isDateSameOrBefore(gped.date, date)) {
				if (
					gped.date &&
					gped.penId &&
					recentRegulations[gped.penId] &&
					((gped.date <= recentRegulations[gped.penId] &&
						gped.growthPigEventType !== GrowthPigEventType.Regulation) ||
						(gped.date < recentRegulations[gped.penId] &&
							gped.growthPigEventType === GrowthPigEventType.Regulation))
				) {
					return;
				}
				const penToUse = locationState.pens.find(p => p.id === gped.penId);
				const dataIndex = data.findIndex(d => d.penId === gped.penId);

				if (penToUse !== undefined && dataIndex < 0) {
					data.push({
						penName: penToUse.name,
						penOrder: penToUse.order,
						sectionId: penToUse.sectionId,
						buildingId: penToUse.buildingId,
						penId: gped.penId,
						amount: gped.amount,
						totalWeight: gped.totalWeight,
						totalWeightCurve:
							gped.date && gped.totalWeight && gped.amount && gped.productionType && date
								? gped.amount > 0
									? GetTotalWeightByEntrance(
											gped.date,
											Math.abs(gped.totalWeight),
											Math.abs(gped.amount),
											gped.productionType,
											date,
											gped.isSameProduction,
									  )
									: 0
								: gped.totalWeightCurve
								? gped.totalWeightCurve
								: 0,
						oldAvgWeight: 0,
						newAvgWeight: 0,
						rest: gped.amount,
						editable: !!(gped.amount && gped.amount > 0),
						hasQuarantine: gped.hasQuarantine,
						retentionDate: getPenRetentionTime(gped.penId),
						departureWeight: 0,
					} as GrowthPigDepartureFromSectionRow);
				} else if (dataIndex >= 0) {
					const currentAmount = deepCopy(data[dataIndex].amount);
					data[dataIndex].rest =
						(data[dataIndex].rest ? data[dataIndex].rest! : 0) + (gped.amount ? gped.amount : 0);
					data[dataIndex].editable = !!(data[dataIndex].rest && data[dataIndex].rest! > 0);
					data[dataIndex].amount =
						(data[dataIndex].amount ? data[dataIndex].amount! : 0) + (gped.amount ? gped.amount : 0);
					data[dataIndex].hasQuarantine = gped.hasQuarantine;

					// If  editable (amount > 0) til weight, else set to 0
					if (data[dataIndex].editable) {
						//TODO: distribute into smaller methods
						// Handles weight regulations, by overwriting current weight by current amount
						if (gped.growthPigEventType === GrowthPigEventType.RegulationWeight) {
							let amount = data[dataIndex].amount ? data[dataIndex].amount! : 0;
							let totalWeight = gped.totalWeight ? amount * gped.totalWeight : 0;
							data[dataIndex].totalWeight = totalWeight;
							data[dataIndex].totalWeightCurve =
								gped.date && gped.productionType && date
									? data[dataIndex].amount && data[dataIndex].amount! > 0
										? GetTotalWeightByEntrance(
												gped.date,
												currentAmount && currentAmount < 0
													? (Math.abs(totalWeight) / Math.abs(amount)) *
															data[dataIndex].amount!
													: Math.abs(totalWeight),
												currentAmount && currentAmount < 0
													? data[dataIndex].amount!
													: Math.abs(amount),
												gped.productionType,
												date,
												gped.isSameProduction,
										  )
										: 0
									: gped.totalWeightCurve
									? gped.totalWeightCurve
									: 0;
						} else {
							data[dataIndex].totalWeight =
								(data[dataIndex].totalWeight ? data[dataIndex].totalWeight! : 0) +
								(gped.totalWeight ? gped.totalWeight : 0);

							data[dataIndex].retentionDate = getPenRetentionTime(gped.penId);
							data[dataIndex].totalWeightCurve =
								(data[dataIndex].totalWeightCurve ? data[dataIndex].totalWeightCurve! : 0) +
								(gped.date && gped.totalWeight && gped.productionType && gped.amount && date
									? data[dataIndex].amount && data[dataIndex].amount! > 0
										? GetTotalWeightByEntrance(
												gped.date,
												currentAmount && currentAmount < 0
													? (Math.abs(gped.totalWeight) / Math.abs(gped.amount)) *
															data[dataIndex].amount!
													: Math.abs(gped.totalWeight),
												currentAmount && currentAmount < 0
													? data[dataIndex].amount!
													: Math.abs(gped.amount),
												gped.productionType,
												date,
												gped.isSameProduction,
										  )
										: 0
									: gped.totalWeightCurve
									? gped.totalWeightCurve
									: 0);
						}
					} else {
						data[dataIndex].totalWeight = 0;
						data[dataIndex].totalWeightCurve = 0;
					}
				}
			}
		});
	}
	data.forEach(dataToModify => {
		dataToModify.oldAvgWeight =
			(dataToModify.totalWeightCurve && dataToModify.totalWeightCurve > 0 ? dataToModify.totalWeightCurve : 0) /
			(dataToModify.amount ? dataToModify.amount : 1);
		dataToModify.newAvgWeight =
			(dataToModify.totalWeightCurve && dataToModify.totalWeightCurve > 0 ? dataToModify.totalWeightCurve : 0) /
			(dataToModify.amount ? dataToModify.amount : 1);
	});
	data = data.filter(e => e.amount).sort((a, b) => NaturalSort(a.penOrder, b.penOrder));
	return data;
};

export const validateGrowthPigEntranceEvent = async (
	state: GrowthPigEntranceState,
	allowSectionsWithoutSettingPen: boolean,
	showAlert: (errorMessage: string) => void,
	locations: LocationsState,
	growthPigEvents: IGrowthPigEventDto[],
	showConfirmAlert: (errorMessage: string) => Promise<boolean>,
) => {
	if (!state.date) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_DATE_NOT_SET));
		return false;
	}
	if (!state.selectedProductionType) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_PRODUCTION_TYPE_NOT_SET));
		return false;
	}
	if (!state.section) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_STABLE_SECTION_NOT_SET));
		return false;
	}
	if (
		!allowSectionsWithoutSettingPen &&
		state.section &&
		state.section.id &&
		CheckIfSectionUsesPens(state.section.id) &&
		!state.penId
	) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_PEN_ID_NOT_SET));
		return false;
	}
	if (!state.amount) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_COUNT_NOT_SET));
		return false;
	}
	if (!state.avgWeight) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_AVG_WEIGHT_NOT_SET));
		return false;
	}

	// Notify on capacity
	if (state.selectedProductionType === AnimalType.Finisher && state.generalSettings?.notifyCapacityWeaner) {
		const currentPigs = getTotalPigsOnPens(
			growthPigEvents,
			state.selectedProductionType,
			state.section.id,
			state.penId,
			state.date,
		);
		const capacity = locations.pens.find(p => p.id === state.penId)?.capacity;
		if (!capacity) {
			return true;
		}
		const calculatedPct = ((currentPigs[state.penId!] + state.amount) / capacity) * 100;
		100;

		if (state.generalSettings?.notifyCapacityWeaner < calculatedPct) {
			return showConfirmAlert(
				localizedInterpolation(
					'VALIDATION_WARNING_CAPACITY_EXCEEDED',
					state.generalSettings?.notifyCapacityWeaner,
				),
			);
		}
	}
	if (state.selectedProductionType === AnimalType.Finisher && state.generalSettings?.notifyCapacityFinisher) {
		const currentPigs = getTotalPigsOnPens(
			growthPigEvents,
			state.selectedProductionType,
			state.section.id,
			state.penId,
			state.date,
		);
		const capacity = locations.pens.find(p => p.id === state.penId)?.capacity;
		if (!capacity) {
			return true;
		}
		const calculatedPct = ((currentPigs[state.penId!] + state.amount) / capacity) * 100;
		100;

		if (state.generalSettings?.notifyCapacityFinisher < calculatedPct) {
			return showConfirmAlert(
				localizedInterpolation(
					'VALIDATION_WARNING_CAPACITY_EXCEEDED',
					state.generalSettings?.notifyCapacityFinisher,
				),
			);
		}
	}

	return true;
};

export const validateGrowthPigsMoveEvent = (
	growthPigMoveState: GrowthPigMoveState,
	showAlert: (errorMessage: string) => void,
	skipDataCheck: boolean = false,
	allowSectionsWithoutSettingPen: boolean = true,
) => {
	if (!growthPigMoveState.date) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_MOVE_DATE_NOT_SET));
		return false;
	}
	if (!growthPigMoveState.fromSection) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_FROM_LOCATION_NOT_SET));
		return false;
	}
	if (!growthPigMoveState.toSection) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_TO_LOCATION_NOT_SET));
		return false;
	}
	if (!growthPigMoveState.fromProductionType) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_FROM_PRODUCTIONTYPE_NOT_SET));
		return false;
	}
	if (!growthPigMoveState.toProductionType) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_TO_PRODUCTIONTYPE_NOT_SET));
		return false;
	}

	if (
		!allowSectionsWithoutSettingPen &&
		growthPigMoveState.toSection &&
		growthPigMoveState.toSection.id &&
		CheckIfSectionUsesPens(growthPigMoveState.toSection.id) &&
		!growthPigMoveState.toPenId
	) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_PEN_ID_NOT_SET));
		return false;
	}

	if (
		!skipDataCheck &&
		(growthPigMoveState.data.length <= 0 ||
			growthPigMoveState.data.reduce(
				(sum, current) => sum + (current.departedFromSection !== undefined ? current.departedFromSection : 0),
				0,
			) <= 0)
	) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_NO_PIGS_MOVED));
		return false;
	}
	if (
		!skipDataCheck &&
		growthPigMoveState.data.find(
			gpm => gpm.amount && gpm.amount > 0 && gpm.editable && (!gpm.newAvgWeight || gpm.newAvgWeight <= 0),
		)
	) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_WEIGHT_REQUIRED_FOR_DEPARTED));
		return false;
	}

	if (
		!skipDataCheck &&
		growthPigMoveState.data.find(d => d.editable && d.rest !== undefined && d.rest < 0) !== undefined
	) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_TO_MANY_PIGS_MOVED));
		return false;
	}
	return true;
};

export const validateGrowthPigsDepartureEvent = (
	growthPigMoveState: GrowthPigDepartureState,
	showAlert: (errorMessage: string) => void,
) => {
	if (!growthPigMoveState.date) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_DEPARTURE_DATE_NOT_SET));
		return false;
	}
	if (!growthPigMoveState.fromSection) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_FROM_LOCATION_NOT_SET));
		return false;
	}
	if (!growthPigMoveState.fromProductionType) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_FROM_PRODUCTIONTYPE_NOT_SET));
		return false;
	}
	if (!growthPigMoveState.departureTypeOption.value) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_DEPARTURE_TYPE_NOT_SET));
		return false;
	}
	if (
		growthPigMoveState.data.length <= 0 ||
		growthPigMoveState.data.reduce(
			(sum, current) => sum + (current.departedFromSection !== undefined ? current.departedFromSection : 0),
			0,
		) <= 0
	) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_NO_PIGS_DEPARTED));
		return false;
	}
	if (growthPigMoveState.data.find(d => d.editable && d.rest !== undefined && d.rest < 0) !== undefined) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_TO_MANY_PIGS_DEPARTED));
		return false;
	}
	return true;
};

export const validateGrowthPenPigsDepartureEvent = (
	growthPigMoveState: GrowthPigDeparturePenState,
	showAlert: (errorMessage: string) => void,
	youngAnimal?: IStemAnimal | undefined,
) => {
	if (!growthPigMoveState.date) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_DEPARTURE_DATE_NOT_SET));
		return false;
	}
	if (!growthPigMoveState.fromSection) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_FROM_LOCATION_NOT_SET));
		return false;
	}
	if (!growthPigMoveState.fromPenId) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_PEN_ID_NOT_SET));
		return false;
	}
	if (!growthPigMoveState.fromProductionType) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_FROM_PRODUCTIONTYPE_NOT_SET));
		return false;
	}
	if (!growthPigMoveState.departureTypeOption.value) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_DEPARTURE_TYPE_NOT_SET));
		return false;
	}
	if (!growthPigMoveState.amount) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_NO_PIGS_DEPARTED));
		return false;
	}
	if (!growthPigMoveState.avgWeight) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_AVG_WEIGHT_NOT_SET));
		return false;
	}
	if (youngAnimal && growthPigMoveState.amount > 1) {
		showAlert(localized('deadPigletsGreaterThan1'));
		return false;
	}
	return true;
};

export function generateLocationOptionsForGrowthPigEvents(
	events: IGrowthPigEventDto[],
	locationsState: LocationsState,
	productionType: AnimalType,
) {
	const locations = { ...locationsState };
	locations.sections = locations.sections.filter(
		s => events.find(gpe => gpe.sectionId === s.id && gpe.productionType === productionType) !== undefined,
	);

	locations.pens = locations.pens.filter(
		pen =>
			events.find(gpe => gpe.growthPigEventDistributions && gpe.productionType === productionType) !== undefined,
	);
	return locations;
}

export function getLocationsWithGrowthPigs(
	events: IGrowthPigEventDto[],
	locationsState: LocationsState,
	productionType: AnimalType,
) {
	const locations = { ...locationsState };
	locations.sections = locations.sections.filter(
		s => events.find(gpe => gpe.sectionId === s.id && gpe.productionType === productionType) !== undefined,
	);

	locations.pens = locations.pens.filter(
		pen =>
			events.find(gpe => gpe.growthPigEventDistributions && gpe.productionType === productionType) !== undefined,
	);
	return locations;
}

export const checkIfGrowthPigEventDistributeWeightShouldBeDisabled = memoize(
	(data: GrowthPigDepartureFromSectionRow[]) => {
		const totalAmount = data
			.map(d => (d.departedFromSection ? d.departedFromSection : 0))
			.reduce((a, b) => a + b, 0);
		if (totalAmount <= 0) {
			return true;
		} else {
			return false;
		}
	},
);

export function handleGrowthpigsDeparturedFromSection(
	datasToModify: GrowthPigDepartureFromSectionRow[],
	itemFromParent: GrowthPigDepartureFromSectionRow | undefined,
	number: number | undefined,
) {
	const dataIndexToModify = datasToModify.findIndex(dtm => itemFromParent && dtm.penId === itemFromParent.penId);
	const dataToModify = datasToModify[dataIndexToModify];
	if (dataToModify) {
		dataToModify.departedFromSection = number;
		// if (dataToModify.totalDepartureWeight && dataToModify.departedFromSection) {
		// 	dataToModify.newAvgWeight = dataToModify.totalDepartureWeight / dataToModify.departedFromSection;
		// }
		dataToModify.departureWeight =
			(number ? number : 0) * (dataToModify.newAvgWeight ? dataToModify.newAvgWeight : 0);
		dataToModify.rest =
			(itemFromParent && itemFromParent.amount ? itemFromParent.amount : 0) - (number ? number : 0);
		datasToModify[dataIndexToModify] = { ...dataToModify };
	}
}

export function handleGrowthpigsRestFromSection(
	datasToModify: GrowthPigDepartureFromSectionRow[],
	itemFromParent: GrowthPigDepartureFromSectionRow | undefined,
	number: number | undefined,
) {
	const dataIndexToModify = datasToModify.findIndex(dtm => itemFromParent && dtm.penId === itemFromParent.penId);
	const dataToModify = datasToModify[dataIndexToModify];
	dataToModify.rest = number;
	dataToModify.departedFromSection =
		(itemFromParent && itemFromParent.amount ? itemFromParent.amount : 0) - (number ? number : 0);
	// if (dataToModify.totalDepartureWeight && dataToModify.departedFromSection) {
	// 	dataToModify.newAvgWeight = dataToModify.departedFromSection / dataToModify.departedFromSection;
	// }
	dataToModify.departureWeight = (number ? number : 0) * (dataToModify.newAvgWeight ? dataToModify.newAvgWeight : 0);

	datasToModify[dataIndexToModify] = { ...dataToModify };
}

export function handleGrowthpigsWeightFromSection(
	datasToModify: GrowthPigDepartureFromSectionRow[],
	itemFromParent: GrowthPigDepartureFromSectionRow | undefined,
	number: number | undefined,
) {
	const dataIndexToModify = datasToModify.findIndex(dtm => itemFromParent && dtm.penId === itemFromParent.penId);
	const dataToModify = datasToModify[dataIndexToModify];
	dataToModify.newAvgWeight = number;
	if (dataToModify.departedFromSection) {
		dataToModify.totalDepartureWeight = (number ? number : 0) * dataToModify.departedFromSection;
	}

	dataToModify.departureWeight = dataToModify.departedFromSection ? dataToModify.departedFromSection : 0;
	datasToModify[dataIndexToModify] = { ...dataToModify };
}

export function onGrowthPigEntranceNumberChanged(
	ItemFromParent: string | undefined,
	state: GrowthPigEntranceState,
	newText: number | undefined,
) {
	if (ItemFromParent === 'avgWeight') {
		state[ItemFromParent] = newText;
		state.totalWeight = newText !== undefined && state.amount !== undefined ? state.amount * newText : undefined;
	} else if (ItemFromParent === 'totalWeight') {
		state.avgWeight = newText !== undefined && state.amount ? newText / state.amount : undefined;
		state[ItemFromParent] = newText;
	} else if (ItemFromParent === 'amount') {
		if (state.avgWeight && state.totalWeight === (state.amount ? state.amount : 0) * state.avgWeight) {
			state.totalWeight =
				newText !== undefined && state.avgWeight !== undefined ? newText * state.avgWeight : state.totalWeight;
		}

		state[ItemFromParent] = newText;
	}
}

export function getReasonsGrowthPigEvents(
	departureTypeOption: Option,
	propsReasons: IReasonDto[],
	profile: IUserProfile,
	production: AnimalType,
): Option[] {
	if (!departureTypeOption.value) {
		return [];
	}

	let reasons: IReasonDto[] = [];

	// Filter reason settings, depending on AnimalType and DepartureType Options
	reasons = propsReasons.filter(
		reason =>
			reason.reasonSettings &&
			((production === AnimalType.Weaner &&
				((reason.reasonSettings.weanerDead && departureTypeOption.value === DepartureTypes.departureTypeDead) ||(reason.reasonSettings.weanerSold && departureTypeOption.value === DepartureTypes.departureTypeSold)||
					(reason.reasonSettings.weanerPutDown &&
						departureTypeOption.value === DepartureTypes.departureTypePutDown))) ||
				(production === AnimalType.Finisher &&
					((reason.reasonSettings.finisherDead &&
						departureTypeOption.value === DepartureTypes.departureTypeDead) ||
						(reason.reasonSettings.finisherSold && departureTypeOption.value === DepartureTypes.departureTypeSold) ||
						(reason.reasonSettings.finisherSlaughtered && departureTypeOption.value === DepartureTypes.departureTypeKilled) ||
						(reason.reasonSettings.finisherPutDown &&
							departureTypeOption.value === DepartureTypes.departureTypePutDown)))),
	);
	reasons = reasons.sort((reasonA, reasonB) => {
		return reasonA!.reason!.priorityCode! > reasonB!.reason!.priorityCode! ? 1 : -1;
	});
	let reasonsForSelect: Option[] = reasons.map(reason => {
		return {
			label: reason.reason!.name![profile && profile.language ? profile.language : 'da'],
			value: reason.reason!.id!,
		};
	});
	reasonsForSelect.unshift(DefaultGrowthTypeDepartureTypeOption);
	return reasonsForSelect;
}

export function handleGrowthPigPenDepartureEvent(props: GrowthPigDepartureProps, state: GrowthPigDeparturePenState) {
	const pen = props.locations.pens.find(p => p.id === state.fromPenId);
	const lisToSave: GrowthPigDepartureFromPenEventDto[] = [];
	if (pen && state.departureReasonIdOption && props.profile && state.amount && state.avgWeight) {
		// Because of a misunderstanding, we get correct production by sectionId
		const correctProduction = getCorrectProductionTypeBySectionId(pen.sectionId);
		const dataToSave = GrowthPigDepartureFromPenEventDto.fromJS({});
		dataToSave.id = new ObjectID().toHexString();
		dataToSave.amount = state.amount;
		dataToSave.avgWeight = state.avgWeight;
		dataToSave.date = state.date!;
		dataToSave.siteId = props.profile.siteId;
		dataToSave.fromBuildingId = pen.buildingId;
		dataToSave.fromSectionId = pen.sectionId;
		dataToSave.fromPenId = pen.id;
		dataToSave.correctFromProductionType = correctProduction;
		dataToSave.createdBy = props.profile.id;
		dataToSave.modifiedBy = props.profile.id;
		dataToSave.departureReasonId = state.departureReasonIdOption.value;
		dataToSave.departureType = state.departureTypeOption.value;
		dataToSave.totalWeight = state.amount * state.avgWeight;
		dataToSave.fromProductionType = props.productionType;
		dataToSave.animalIdNumber = state.youngAnimal?.idNumber;
		const growthPigEvent = props.growthPigsEvents.find(
			a => state.fromSection && a.sectionId === state.fromSection.id,
		);
		if (growthPigEvent && growthPigEvent.growthPigEventDistributions) {
			const growthPigPenEvent = growthPigEvent.growthPigEventDistributions.find(a => a.penId === state.fromPenId);
			if (
				growthPigPenEvent &&
				growthPigPenEvent.totalWeight !== undefined &&
				growthPigPenEvent.amount !== undefined
			) {
				dataToSave.oldTotalWeight =
					(growthPigPenEvent.totalWeight / growthPigPenEvent.amount) * dataToSave.amount;
			}
		}
		lisToSave.push(dataToSave);
	}
	return lisToSave;
}

export function GenerateProductionTypeOptions(props: GrowthPigProductionTypeProps) {
	const options: Option[] = [];
	const productionTypeToUse = props.productionTypeLimitation ? props.productionTypeLimitation : props.productionType;
	if (
		(!props.productionTypeLimitation ||
			props.productionTypeLimitation === AnimalType.FRATS ||
			props.productionTypeLimitation === AnimalType.Piglet) &&
		props.locations!.sections.find(
			s =>
				s.animalType === AnimalType.FRATS &&
				s.type &&
				(s.type.toString().includes(productionTypeToUse) ||
					(props.productionTypeLimitation === AnimalType.Piglet &&
						(s.type === LocationType.ReliefFinisher ||
							s.type === LocationType.Finisher ||
							s.type === LocationType.ReliefWeaners ||
							s.type === LocationType.Weaners))),
		)
	) {
		options.push({ label: localized(AnimalType.FRATS), value: AnimalType.FRATS });
	}
	if (props.locations!.sections.find(s => s.animalType === productionTypeToUse)) {
		options.push({ label: localized(productionTypeToUse), value: productionTypeToUse });
	}
	if (
		props.productionTypeLimitation === AnimalType.Piglet &&
		props.locations!.sections.find(s => s.animalType === AnimalType.Weaner)
	) {
		options.push({ label: localized(AnimalType.Weaner), value: AnimalType.Weaner });
	}

	if (
		!props.disableYoungFemale &&
		(props.productionTypeLimitation === AnimalType.Weaner ||
			props.productionTypeLimitation === AnimalType.Piglet) &&
		props.locations!.sections.find(s => s.animalType === AnimalType.Finisher)
	) {
		options.push({ label: localized(AnimalType.Finisher), value: AnimalType.Finisher });
	}

	if (!props.disableYoungFemale) {
		options.push({ label: localized(AnimalType.YoungFemale), value: AnimalType.YoungFemale });
	}

	return options;
}

export function validateSendDataToDistriwinData(
	generalSettings: IGeneralSettings,
	animalType: AnimalType,
	showAlert: (errorMessage: string) => void,
	feedCurve?: number,
	recipeNumber?: number,
) {
	if (
		(animalType === AnimalType.Finisher && generalSettings.sendEntranceDepartureDistriwinFinisher) ||
		(animalType === AnimalType.Weaner && generalSettings.sendEntranceDepartureDistriwinWeaner)
	) {
		if (!feedCurve) {
			showAlert(localized('VALIDATION_ERROR_SET_FEED_CURVE'));
			return false;
		}
		if (!recipeNumber) {
			showAlert(localized('VALIDATION_ERROR_SET_RECIPE'));
			return false;
		}
		return true;
	}
	return true;
}

export function validateDistributeGrowthPigs(
	totalWeightNotDistributed: number,
	amountPigsNotDistributed: number,
	showAlert: (errorMessage: string) => void,
) {
	if ((Math.round(totalWeightNotDistributed*10)/10) > 0) {
		// disabled since may not be required
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_WEIGHT_NOT_DISTRIBUTED));
		return false;
	} else if ((Math.round(totalWeightNotDistributed*10)/10) < 0) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_TO_MUCH_WEIGHT_DISTRIBUTED));
		return false;
	} else if (amountPigsNotDistributed > 0) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_PIGS_NOT_DISTRIBUTED));
		return false;
	} else if (amountPigsNotDistributed < 0) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_TO_MANY_PIGS_DISTRIBUTED));
		return false;
	}
	return true;
}

export function generateDataForEntranceDistribute(
	pens: IPen[],
	sectionId?: string,
	penId?: string,
	totalAmount?: number,
	avgWeight?: number,
	retentionDate?: Date,
	profile?: IUserProfile,
	date?: Date,
	productionType?: AnimalType,
	eventsToUse?: GrowthPigsEvent[],
	feedCurve?: number,
	recipe?: number,
) {
	const pensToUse = pens
		.filter(p => p.sectionId === sectionId && ((penId && p.id === penId) || !penId))
		.sort((a, b) => NaturalSort(a.name, b.name));
	const itemsToSave: IGrowthPigEntranceEventDto[] = [];

	// Because of a misunderstanding, we get correct production by sectionId
	const correctProduction = getCorrectProductionTypeBySectionId(sectionId);

	if (pensToUse.length > 0 && totalAmount !== undefined && avgWeight && profile) {
		const data: IGrowthPigEntranceEventDto[] = [];
		let used = 0;

		const amount = totalAmount / pensToUse.length;
		const amountAlreadyDistributed = eventsToUse && eventsToUse.reduce((a, b) => a + (b.amount ? b.amount : 0), 0);
		if (
			eventsToUse &&
			amountAlreadyDistributed &&
			totalAmount >= amountAlreadyDistributed &&
			eventsToUse.find(gpe => sectionId && gpe.toSectionId && gpe.toSectionId === sectionId)
		) {
			eventsToUse.forEach(element => {
				const intvalue = element.amount ? Math.floor(element.amount) : 0;
				let amountToAdd = intvalue;
				if (amount !== 0 && intvalue !== undefined) {
					const amountToCheck = intvalue ? intvalue : 1;

					if (used + amountToCheck <= totalAmount) {
						used += amountToCheck;
						amountToAdd = amountToCheck;
					}
				}
				const pen = pensToUse.find(p => p.id === element.toPenId);
				const indexToModify = data.findIndex(gped => pen && gped.toPenId === pen.id);
				if (pen && indexToModify === -1) {
					data.push(
						GrowthPigEntranceEventDto.fromJS({
							id: new ObjectID().toHexString(),
							name: pen.name,
							siteId: profile.siteId,
							toBuildingId: pen.buildingId,
							toSectionId: pen.sectionId,
							correctToProductionType: correctProduction,
							retentionDate: retentionDate,
							toProductionType: productionType!,
							date: date!,
							toPenId: pen.id,
							amount: amountToAdd ? amountToAdd : undefined!,
							avgWeight: avgWeight,
							totalWeight: amountToAdd ? avgWeight * amountToAdd : undefined!,
							feedCurve,
							recipe,
						} as IGrowthPigEntranceEventDto),
					);
				} else {
					data[indexToModify].amount =
						(data[indexToModify].amount ? data[indexToModify].amount! : 0) +
						(amountToAdd ? amountToAdd : 0);
					data[indexToModify].totalWeight =
						(data[indexToModify].totalWeight ? data[indexToModify].totalWeight! : 0) +
						(amountToAdd ? avgWeight * amountToAdd : 0);
				}
			});
		}

		pensToUse.forEach(pen => {
			if (data.find(cd => cd.toPenId === pen.id) === undefined) {
				const intvalue = Math.floor(amount);
				let amountToAdd = intvalue;
				if (amount !== 0 && intvalue !== undefined) {
					const amountToCheck = intvalue ? intvalue : 1;
					if (used + amountToCheck <= totalAmount) {
						used += amountToCheck;
						amountToAdd = amountToCheck;
					}
				}
				data.push(
					GrowthPigEntranceEventDto.fromJS({
						id: new ObjectID().toHexString(),
						name: pen.name,
						siteId: profile.siteId,
						toBuildingId: pen.buildingId,
						toSectionId: pen.sectionId,
						retentionDate: retentionDate,
						toProductionType: productionType!,
						correctToProductionType: correctProduction,
						date: date!,
						toPenId: pen.id,
						amount: amountToAdd ? amountToAdd : undefined!,
						avgWeight: avgWeight,
						totalWeight: amountToAdd ? avgWeight * amountToAdd : undefined!,
						feedCurve,
						recipe,
					} as IGrowthPigEntranceEventDto),
				);
			}
		});

		const valueToUse = (totalAmount - used) / pensToUse.length;
		data.forEach(item => {
			const intvalue = Math.floor(valueToUse);
			let amountToAdd = intvalue;
			if (valueToUse !== 0 && intvalue !== undefined) {
				const amountToCheck = intvalue ? intvalue : 1;
				if (used + amountToCheck <= totalAmount) {
					used += amountToCheck;
					amountToAdd = amountToCheck;
				}
			}
			item.amount = item.amount && amountToAdd ? item.amount + amountToAdd : item.amount;
			item.avgWeight = avgWeight;
			item.totalWeight = item.amount ? avgWeight * item.amount : undefined!;
		});
		data.forEach(item => {
			itemsToSave.push(item);
		});
	}
	return itemsToSave.sort((a, b) => NaturalSort(a.name, b.name));
}

export function getGrowthPigDepartureEventDtos(state: GrowthPigDepartureState, props: GrowthPigDepartureProps) {
	const listToSave: GrowthPigDepartureEventDto[] = [];
	// Calculate if Section is emptied
	const totalPigsLeftInSection = state.data.reduce((acc, item) => acc + (item.rest ? item.rest : 0), 0);

	state.data.forEach(d => {
		if (d.departedFromSection && d.departedFromSection > 0) {
			// Because of a misunderstanding, we get correct production by sectionId
			const correctFromProduction = getCorrectProductionTypeBySectionId(d.sectionId);
			const item = GrowthPigDepartureEventDto.fromJS({});
			item.id = new ObjectID().toHexString();
			item.amount = d.departedFromSection;
			item.avgWeight = d.oldAvgWeight;
			item.siteId = props.profile!.siteId;
			item.date = state.date!;
			item.oldTotalWeight = (item.amount ? item.amount : 0) * (d.oldAvgWeight ? d.oldAvgWeight : 0);
			item.fromProductionType = props.productionType;
			item.fromBuildingId = d.buildingId;
			item.fromPenId = d.penId;
			item.fromSectionId = d.sectionId;
			item.correctFromProductionType = correctFromProduction;
			item.departureType = state.departureTypeOption.value;
			item.totalWeight = (item.amount ? item.amount : 0) * (d.newAvgWeight ? d.newAvgWeight : 0);
			item.name = d.penName;
			item.bundleIdentifier = d.bundleIdentifier;
			listToSave.push(item);
		}
		if (totalPigsLeftInSection <= 0) {
			Monitoring.logTrace(`Empty pen for regulation`, {
				penId: d.penId ? d.penId : '',
				rest: d.rest ? d.rest.toString() : '',
				departedFromSection: d.departedFromSection ? d.departedFromSection.toString() : '',
				timestamp: state.date ? state.date.toString() : 'no Date',
			});
			const correctToProduction = getCorrectProductionTypeBySectionId(d.sectionId);
			const item = GrowthPigsEvent.fromJS({});
			item.id = new ObjectID().toHexString();
			item.amount = 0;
			item.siteId = props.profile!.siteId;
			item.date = state.date!;
			item.oldTotalWeight = (item.amount ? item.amount : 0) * (d.oldAvgWeight ? d.oldAvgWeight : 0);
			item.toProductionType = props.productionType;
			item.toBuildingId = d.buildingId;
			item.toPenId = d.penId;
			item.toSectionId = d.sectionId;
			item.correctToProductionType = correctToProduction;
			item.totalWeight = 0;
			item.growthPigEventType = GrowthPigEventType.Regulation;
			item.bundleIdentifier = d.bundleIdentifier;
			listToSave.push(item);
		}
	});
	return listToSave;
}

export function getGrowthPigTuneProductionIfPenEmpty(state: GrowthPigDepartureState, props: GrowthPigDepartureProps) {
	const listToSave: GrowthPigTuneProduction[] = [];
	const totalPigsLeftInSection = state.data.reduce((acc, item) => acc + (item.rest ? item.rest : 0), 0);
	state.data.forEach(d => {
		if (totalPigsLeftInSection <= 0) {
			const dataToSave = GrowthPigTuneProduction.fromJS({
				id: new ObjectID().toHexString(),
				date: state.date!,
				buildingId: d.buildingId,
				sectionId: d.sectionId,
				animalType: props.productionType,
				penId: d.penId,
				amount: 0,
				weight: 0,
				siteId: props.profile!.siteId,
			} as IGrowthPigTuneProduction);
			listToSave.push(dataToSave);
		}
	});
	return listToSave;
}

export function getSinglePenGrowthPigTuneProductionIfPenEmpty(
	state: GrowthPigDepartureState,
	props: GrowthPigDepartureProps,
	departureAmount?: number,
) {
	const listToSave: GrowthPigTuneProduction[] = [];

	state.data.forEach(d => {
		if (departureAmount && departureAmount > 0 && d.amount === departureAmount) {
			const dataToSave = GrowthPigTuneProduction.fromJS({
				id: new ObjectID().toHexString(),
				date: state.date!,
				buildingId: d.buildingId,
				sectionId: d.sectionId,
				animalType: props.productionType,
				penId: d.penId,
				amount: 0,
				weight: 0,
				siteId: props.profile!.siteId,
			} as IGrowthPigTuneProduction);
			listToSave.push(dataToSave);
		}
	});
	return listToSave;
}

export const HandleFromAndToDataChangedEntrance = (
	props: GrowthPigEntranceDistributeProps,
	state: GrowthPigDistributeState,
	fromPenId?: Option,
	toPenId?: Option,
) => {
	const fromPenIndex = state.pensInSection.findIndex(pen => fromPenId && pen.id === fromPenId.value);
	const toPenIndex = state.pensInSection.findIndex(pen => toPenId && pen.id === toPenId.value);
	const pensInSection = state.pensInSection.slice(fromPenIndex, toPenIndex + 1);
	return generateDataForEntranceDistribute(
		pensInSection,
		props.toSectionId,
		undefined,
		props.amount,
		props.avgWeight,
		props.retentionDate,
		props.profile,
		props.date,
		props.productionType,
	);
};

export const HandleFromAndToDataChangedMoveEvent = (
	props: GrowthPigDistributeMoveEventProps,
	state: GrowthPigDistributeState,
	fromPenId?: Option,
	toPenId?: Option,
) => {
	const fromPenIndex = state.pensInSection.findIndex(pen => fromPenId && pen.id === fromPenId.value);
	const toPenIndex = state.pensInSection.findIndex(pen => toPenId && pen.id === toPenId.value);
	const pensInSection = state.pensInSection.slice(fromPenIndex, toPenIndex + 1);
	return generateDataForMoveEventDistribute(pensInSection, props.data);
};

export function getGrowthPigDistributeMoveEventDefaultState(
	props: GrowthPigDistributeMoveEventProps,
): GrowthPigDistributeState {
	let data: IGrowthPigMoveEventDto[] = [];
	let fromPenId: Option | undefined;
	let toPenId: Option | undefined;

	const filteredDate = props.data.filter(
		e =>
			e.departedFromSection &&
			e.departedFromSection > 0 &&
			e.retentionDate &&
			(e.moveAllTreated === MoveAllTreatedEnum.All || e.moveAllTreated === MoveAllTreatedEnum.Some),
	);
	const latestRetentionDate =
		filteredDate && filteredDate.length > 0
			? new Date(
					Math.max.apply(
						null,
						filteredDate.map(e => {
							return new Date(e.retentionDate!).getTime();
						}),
					),
			  )
			: undefined;
	const blacklistedPenIds = props.data
		.filter(x => x.departedFromSection !== undefined && x.departedFromSection > 0)
		.map(x => x.penId!);
	const pensInSection = props.locations.pens
		.filter(p => !blacklistedPenIds.includes(p.id!) && p.sectionId === props.toSectionId)
		.sort(sortPensByName);
	const section = props.locations.sections.find(p => p.id === props.toSectionId);
	if (pensInSection.length > 0) {
		fromPenId = { label: pensInSection[0].name!, value: pensInSection[0].id! };
		toPenId = {
			label: pensInSection[pensInSection.length - 1].name!,
			value: pensInSection[pensInSection.length - 1].id!,
		};
		data = generateDataForMoveEventDistribute(pensInSection, props.data);
	}
	// get totalWeight and total amount from data
	const totalAmount = data.reduce((acc, item) => acc + (item.amount ? item.amount : 0), 0);
	const totalWeight = data.reduce((acc, item) => acc + (item.totalWeight ? item.totalWeight : 0), 0);

	return {
		latestRetentionDate,
		data,
		fromPenId,
		toPenId,
		pensInSection,
		amountPigsNotDistributed: 0,
		totalWeightNotDistributed: 0,
		disablePenColumn: !(section && section.usePens),
		blacklistedPenIds,
		totalWeight,
		amount: totalAmount,
	};
}

export const getCorrectProductionTypeBySectionId = (sectionId: string | undefined) => {
	const state = StoreContainer.getState();

	let section = state.locations.sections.find(sec => sec.id === sectionId);
	return section?.animalType ?? AnimalType.Unknown;
};

export const getTunePigProductionDataOnly = (
	section: ISection,
	growthPigsEvents: IGrowthPigEventDto[],
	locationState: LocationsState,
	parentProductionType: AnimalType,
	productionType?: AnimalType,
	dateTo?: Date,
) => {
	let data: GrowthPigDepartureFromSectionRow[] = [];

	const gpeToUse = growthPigsEvents.find(
		gpe =>
			section &&
			gpe.sectionId === section.id &&
			(section.animalType === productionType &&
			productionType &&
			section.type &&
			section.type.toString().includes(parentProductionType)
				? true
				: gpe.productionType === productionType),
	);
	if (gpeToUse !== undefined && gpeToUse.growthPigEventDistributions) {
		const date = moment(dateTo, 'DD/MM/YYYY').endOf('day').toDate();

		// Find most recent regulation for each pen
		// This is to determine which data we need to work on
		let regulations = gpeToUse.growthPigEventDistributions
			.filter(e => e.growthPigEventType === GrowthPigEventType.Regulation && e.date && e.date < date)
			.groupBy('penId');

		let recentRegulations = {};
		regulations.forEach(reg => {
			let mostRecentDate = new Date(
				Math.max.apply(
					null,
					reg.map(e => {
						return new Date(e.date!).getTime();
					}),
				),
			);
			let mostRecentObject = reg.filter(e => {
				if (e.date) {
					let d = new Date(e.date);
					return d.getTime() === mostRecentDate.getTime();
				}
				return false;
			})[0];

			recentRegulations[mostRecentObject.penId!] = mostRecentObject.date;
		});

		gpeToUse.growthPigEventDistributions
			.sort((a, b) => {
				if (a.amount && a.amount < 0) {
					return NaturalSort(a.amount, b.amount);
				}

				if (
					a.amount !== undefined &&
					b.amount !== undefined &&
					a.amount >= 0 &&
					b.amount >= 0 &&
					!areDatesEqual(a.date, b.date)
				) {
					return NaturalSortDatesOnly(a.date, b.date);
				} else if (
					a.amount !== undefined &&
					b.amount !== undefined &&
					a.amount >= 0 &&
					b.amount >= 0 &&
					areDatesEqual(a.date, b.date)
				) {
					return NaturalSortDates(a.createdOn, b.createdOn);
				} else if (areDateTimesEqual(a.date, b.date)) {
					return NaturalSort(a.amount, b.amount);
				}

				return NaturalSort(a.amount, b.amount);
			})
			.forEach(gped => {
				if (date && gped.date && isDateSameOrBefore(gped.date, date)) {
					if (
						gped.date &&
						gped.penId &&
						recentRegulations[gped.penId] &&
						((gped.date <= recentRegulations[gped.penId] &&
							gped.growthPigEventType !== GrowthPigEventType.Regulation) ||
							(gped.date < recentRegulations[gped.penId] &&
								gped.growthPigEventType === GrowthPigEventType.Regulation))
					) {
						return;
					}

					const penToUse = locationState.pens.find(p => p.id === gped.penId);
					const dataIndex = data.findIndex(d => d.penId === gped.penId);
					if (penToUse !== undefined && dataIndex < 0) {
						data.push({
							penName: penToUse.name,
							penOrder: penToUse.order,
							sectionId: penToUse.sectionId,
							buildingId: penToUse.buildingId,
							penId: gped.penId,
							amount: gped.amount,
							totalWeight: gped.totalWeight,
							totalWeightCurve:
								gped.date && gped.totalWeight && gped.amount && gped.productionType && date
									? gped.amount > 0
										? GetTotalWeightByEntrance(
												gped.date,
												Math.abs(gped.totalWeight),
												Math.abs(gped.amount),
												gped.productionType,
												date,
												gped.isSameProduction,
										  )
										: 0
									: gped.totalWeightCurve
									? gped.totalWeightCurve
									: 0,
							oldAvgWeight: 0,
							newAvgWeight: 0,
							rest: gped.amount,
							hasQuarantine: gped.hasQuarantine,
							departureWeight: 0,
						} as GrowthPigDepartureFromSectionRow);
					} else if (dataIndex >= 0) {
						const currentAmount = deepCopy(data[dataIndex].amount);
						data[dataIndex].rest =
							(data[dataIndex].rest ? data[dataIndex].rest! : 0) + (gped.amount ? gped.amount : 0);
						data[dataIndex].amount =
							(data[dataIndex].amount !== undefined ? data[dataIndex].amount! : 0) +
							(gped.amount !== undefined ? gped.amount : 0);
						data[dataIndex].hasQuarantine = gped.hasQuarantine;

						// If  editable (amount > 0) til weight, else set to 0
						if (data[dataIndex].rest && data[dataIndex].rest! > 0) {
							//TODO: distribute into smaller methods
							// Handles weight regulations, by overwriting current weight by current amount
							if (gped.growthPigEventType === GrowthPigEventType.RegulationWeight) {
								let amount = data[dataIndex].amount ? data[dataIndex].amount! : 0;
								let totalWeight = gped.totalWeight ? amount * gped.totalWeight : 0;
								data[dataIndex].totalWeight = totalWeight;
								data[dataIndex].totalWeightCurve =
									gped.date && gped.productionType && date
										? data[dataIndex].amount && data[dataIndex].amount! > 0
											? GetTotalWeightByEntrance(
													gped.date,
													currentAmount && currentAmount < 0
														? (Math.abs(totalWeight) / Math.abs(amount)) *
																data[dataIndex].amount!
														: Math.abs(totalWeight),
													currentAmount && currentAmount < 0
														? data[dataIndex].amount!
														: Math.abs(amount),
													gped.productionType,
													date,
													gped.isSameProduction,
											  )
											: 0
										: gped.totalWeightCurve
										? gped.totalWeightCurve
										: 0;
							} else {
								data[dataIndex].totalWeight =
									(data[dataIndex].totalWeight ? data[dataIndex].totalWeight! : 0) +
									(gped.totalWeight ? gped.totalWeight : 0);
								data[dataIndex].totalWeightCurve =
									(data[dataIndex].totalWeightCurve ? data[dataIndex].totalWeightCurve! : 0) +
									(gped.date && gped.totalWeight && gped.productionType && gped.amount && date
										? data[dataIndex].amount && data[dataIndex].amount! > 0
											? GetTotalWeightByEntrance(
													gped.date,
													currentAmount !== undefined && currentAmount < 0
														? (Math.abs(gped.totalWeight) / Math.abs(gped.amount)) *
																data[dataIndex].amount!
														: Math.abs(gped.totalWeight),
													currentAmount !== undefined && currentAmount < 0
														? data[dataIndex].amount!
														: Math.abs(gped.amount),
													gped.productionType,
													date,
													gped.isSameProduction,
											  )
											: 0
										: gped.totalWeightCurve
										? gped.totalWeightCurve
										: 0);
							}
						} else {
							data[dataIndex].totalWeight = 0;
							data[dataIndex].totalWeightCurve = 0;
						}
					}
				}
			});
	}
	data.forEach(dataToModify => {
		dataToModify.oldAvgWeight =
			(dataToModify.totalWeightCurve && dataToModify.totalWeightCurve > 0 ? dataToModify.totalWeightCurve : 0) /
			(dataToModify.amount ? dataToModify.amount : 1);
		dataToModify.newAvgWeight =
			(dataToModify.totalWeightCurve && dataToModify.totalWeightCurve > 0 ? dataToModify.totalWeightCurve : 0) /
			(dataToModify.amount ? dataToModify.amount : 1);
	});
	data = data.sort((a, b) => NaturalSort(a.penOrder, b.penOrder));
	return data;
};

export const convertGpeToGrowthPigEventDistribution = (gpe: GrowthPigMoveEventDto, isFrom: boolean) => {
	if (isFrom) {
		return {
			date: gpe.date,
			penId: gpe.fromPenId,
			growthPigEventId: gpe.id,
			amount: gpe.amount ? -gpe.amount : 0,
			totalWeight: gpe.totalWeight ? -gpe.totalWeight : 0,
			productionType: gpe.fromProductionType,
			growthPigEventType: gpe.growthPigEventType,
			totalWeightCurve: gpe.totalWeight ? -gpe.totalWeight : 0,

			isSameProduction: gpe.fromProductionType === gpe.toProductionType,
		} as GrowthPigEventDistribution;
	} else {
		return {
			date: gpe.date,
			penId: gpe.toPenId,
			growthPigEventId: gpe.id,
			amount: gpe.amount,
			totalWeight: gpe.totalWeight,
			productionType: gpe.fromProductionType,
			growthPigEventType: gpe.growthPigEventType,
			totalWeightCurve: gpe.totalWeight,

			isSameProduction: gpe.fromProductionType === gpe.toProductionType,
		} as GrowthPigEventDistribution;
	}
};

export const findMostRecentRegulationsForPens = (gpeToUse: IGrowthPigEventDto | undefined, date: Date) => {
	let recentRegulations = {};
	// Find most recent regulation for each pen
	// This is to determine which data we need to work on
	if (gpeToUse && gpeToUse.growthPigEventDistributions) {
		let regulations = gpeToUse.growthPigEventDistributions
			.filter(e => e.growthPigEventType === GrowthPigEventType.Regulation && e.date && e.date < date)
			.groupBy('penId');

		regulations.forEach(reg => {
			let mostRecentDate = new Date(
				Math.max.apply(
					null,
					reg.map(e => {
						return new Date(e.date!).getTime();
					}),
				),
			);
			let mostRecentObject = reg.filter(e => {
				if (e.date) {
					let d = new Date(e.date);
					return d.getTime() === mostRecentDate.getTime();
				}
				return false;
			})[0];

			recentRegulations[mostRecentObject.penId!] = mostRecentObject.date;
		});
	}

	return recentRegulations;
};

export const findMostRecentRegulationWeightsForPens = (gpeToUse: IGrowthPigEventDto | undefined, date: Date) => {
	let recentRegulations = {};
	// Find most recent regulation for each pen
	// This is to determine which data we need to work on
	if (gpeToUse && gpeToUse.growthPigEventDistributions) {
		let regulations = gpeToUse.growthPigEventDistributions
			.filter(e => e.growthPigEventType === GrowthPigEventType.RegulationWeight && e.date && e.date < date)
			.groupBy('penId');

		regulations.forEach(reg => {
			let mostRecentDate = new Date(
				Math.max.apply(
					null,
					reg.map(e => {
						return new Date(e.date!).getTime();
					}),
				),
			);
			let mostRecentObject = reg.filter(e => {
				if (e.date) {
					let d = new Date(e.date);
					return d.getTime() === mostRecentDate.getTime();
				}
				return false;
			})[0];

			recentRegulations[mostRecentObject.penId!] = mostRecentObject.date;
		});
	}

	return recentRegulations;
};

export const getTotalPigsOnPens = (
	gpes: IGrowthPigEventDto[],
	animalKind?: AnimalType,
	sectionId?: string,
	penId?: string,
	dateAtCalculation?: Date,
) => {
	let date = dateAtCalculation ? dateAtCalculation.endOfDayTime() : new Date();
	const sectionGpe = gpes.find(gpe => gpe.sectionId === sectionId && animalKind === gpe.productionType);
	let recentRegulations = findMostRecentRegulationsForPens(sectionGpe, date);
	let penIdAmount = {};

	if (sectionGpe && sectionGpe.growthPigEventDistributions) {
		let sorted = sectionGpe.growthPigEventDistributions.sort((a, b) => {
			if (a.amount && a.amount < 0) {
				return NaturalSort(a.amount, b.amount);
			}

			if (
				a.amount !== undefined &&
				b.amount !== undefined &&
				a.amount >= 0 &&
				b.amount >= 0 &&
				!areDatesEqual(a.date, b.date)
			) {
				return NaturalSortDatesOnly(a.date, b.date);
			} else if (
				a.amount !== undefined &&
				b.amount !== undefined &&
				a.amount >= 0 &&
				b.amount >= 0 &&
				areDatesEqual(a.date, b.date)
			) {
				return NaturalSortDates(a.createdOn, b.createdOn);
			} else if (areDateTimesEqual(a.date, b.date)) {
				return NaturalSort(a.amount, b.amount);
			}

			return NaturalSort(a.amount, b.amount);
		});

		sorted.forEach(gped => {
			if (date && gped.date && isDateSameOrBefore(gped.date, date)) {
				if (
					gped.date &&
					gped.penId &&
					recentRegulations[gped.penId] &&
					((gped.date <= recentRegulations[gped.penId] &&
						gped.growthPigEventType !== GrowthPigEventType.Regulation) ||
						(gped.date < recentRegulations[gped.penId] &&
							gped.growthPigEventType === GrowthPigEventType.Regulation))
				) {
					return;
				}
				if (gped.penId) {
					if (penIdAmount[gped.penId]) {
						penIdAmount[gped.penId] += gped.amount;
					} else {
						penIdAmount[gped.penId] = gped.amount;
					}
				}
			}
		});
	}
	return penIdAmount;
};

export const ValidateGpeCapacity = (
	sectionId: string,
	notifyCapacity,
	date: Date,
	addedPigs: { [key: string]: number },
	showConfirmAlert: (errorMessage: string) => Promise<boolean>,
) => {
	const state = StoreContainer.getState();
	const section = state.locations.sections.find(s => s.id === sectionId);
	if (!section || !notifyCapacity) {
		return true;
	}
	const currentPigs = getTotalPigsOnPens(state.growthPigEvents.entities, section.animalType, section.id, '', date);
	const pens = state.locations.pens.filter(p => p.sectionId === sectionId).sort(NaturalSortPens);
	let penNames: (string | undefined)[] = [];
	pens.forEach(p => {
		if (!p.id || !addedPigs[p.id]) {
			return;
		}
		const calculatedPct = ((currentPigs[p.id] + addedPigs[p.id]) / p.capacity) * 100;

		if (notifyCapacity < calculatedPct) {
			penNames.push(p.name);
		}
	});

	if (penNames.length === 0) {
		return true;
	}
	return showConfirmAlert(
		localizedInterpolationMultiple('VALIDATION_WARNING_CAPACITY_EXCEEDED_MANY', {
			area: notifyCapacity,
			area2: penNames.join(', '),
		}),
	);
};
