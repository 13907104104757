import { default as ObjectID } from 'bson-objectid';
import React from 'react';
import { Option } from 'react-dropdown';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
	DrugAmount,
	DrugTypeType,
	TreatmentFrequency,
	IDiagnose,
	IDrugType,
	IImage,
	ITreatmentDefinition,
	SubTreatment,
	TreatmentType,
	TreatmentUnit,
	ISubTreatment,
} from 'shared/api/api';
import opret_medicin from 'shared/assets/src-assets/png/opret_medicin.png';
import DeleteIcon from 'shared/assets/src-assets/png/delete_icon.png';
import { getAnimalKindString } from 'shared/helpers/animal-kinds-helpers';
import { getDiagnoseName } from 'shared/helpers/diagnose-helper/diagnose-helper';
import { deepCopy, isNullOrUndefined } from 'shared/helpers/general-helpers';
import { IntegerChanged } from 'shared/helpers/number-helper';
import { GetDiagnoseImage } from 'shared/state/ducks/diagnoses/operations';
import { GetDrugs } from 'shared/state/ducks/drug/operations';
import { GetSyncData as DrugTypeGetSyncData } from 'shared/state/ducks/drugTypes/operations';
import { GetSyncData as MarkingsGetSyncData } from 'shared/state/ducks/markings/operations';
import { SaveTreatmentDefinition } from 'shared/state/ducks/treatment-definitions/operations';
import { LanguageType, localized } from 'shared/state/i18n/i18n';
import styled from 'styled-components';
import { WebAppState } from 'web/state/store.web';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import MarkingPicker from '../../marking-picker/marking-picker';
import PageContainer from '../../page-container/page-container';
import { showAlert } from '../../skiold-alert/skiold-alert';
import { SkioldButton } from '../../skiold-components/skiold-button/skiold-button';
import { SkioldDecimalInput } from '../../skiold-components/skiold-decimal-input/skiold-decimal-input';
import { SkioldDropdown } from '../../skiold-components/skiold-dropdown/skiold-dropdown';
import { SkioldInput } from '../../skiold-components/skiold-input/skiold-input';
import { SkioldUnderlineIntergerInput } from '../../skiold-components/skiold-integer-input/skiold-underline-interger-input';
import GroupedHeaderSkioldTable from '../../skiold-components/skiold-table/skiold-grouped-header-table';
import { Row } from '../../skiold-components/skiold-table/skiold-table';
import { SkioldTextArea } from '../../skiold-components/skiold-text-area/skiold-text-area';
import { SkioldTouchableOpacity } from '../../skiold-components/skiold-touchable-opacity';
import { SowListConstants } from '../../stem-animal/animal-lists/table-constants';
import { WhiteText } from '../../Text/white-text';
import { Heading } from '../../utils/heading';
import { SkioldImage } from '../../utils/svg/skiold-image';
import './add-treatment-definitions.scss';
import { SkioldCheckbox } from '../../skiold-components/skiold-checkbox/skiold-checkbox';

interface PropsFromParent {
	close: () => void;
	treatmentDefinition?: ITreatmentDefinition;
	diagnose: IDiagnose;
	isOpen?: boolean;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		drugTypes: state.drugType.entities,
		siteId: state.profile.active!.siteId,
		profile: state.profile.active!,
		diagnoseImages: state.diagnose.diagnoseImages,
		markings: state.markings.entities,
		drugs: state.drugs.drugs.filter(d => !d.isDeleted),
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	getDrugTypes: () => DrugTypeGetSyncData()(dispatch),
	saveTreatmentDefinition: (treatmentDefinition: ITreatmentDefinition, isVaccination: boolean) =>
		SaveTreatmentDefinition(treatmentDefinition, isVaccination)(dispatch),
	getDrugs: (siteId: string, startDate: Date, endDate: Date) => GetDrugs(siteId, startDate, endDate)(dispatch),
	getImage: (id: string) => GetDiagnoseImage(id)(dispatch),
	loadMarkings: () => MarkingsGetSyncData()(dispatch),
});
const defaultOption = { value: ' ', label: ' ' };
interface State {
	prevTreatmentDefinition: ITreatmentDefinition;
	treatmentDefinition: ITreatmentDefinition;
	treatmentTypes: Option[];
	unitTypes: Option[];
	intervals: Option[];
	drugOptions: Option[];
	diagnoseImage?: IImage;
	intervalFequency: Option;
	//To be able to do this: items={this.state[type]} in renderEnumPicker
	[key: string]: any;
}

const WrapperColumnWeb = styled.div`
	flex: 1;
	padding: 15px;
	display: flex;
	flex-direction: column;
`;
const TextAlignLeft = styled.div`
	width: 150px;
	input {
		text-align: left;
	}
`;
const WrapperRowBaseline = styled.div`
	flex: 1;
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
	z-index: 10;
`;

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;
class AddTreatmentDefinition extends React.PureComponent<Props, State> {
	private defaultIntervalFequency: Option = {
		label: ' ',
		value: ' ',
	};

	constructor(props: Props) {
		super(props);
		this.state = {
			prevTreatmentDefinition: this.props.treatmentDefinition || ({} as ITreatmentDefinition),
			treatmentDefinition: this.props.treatmentDefinition || ({} as ITreatmentDefinition),
			treatmentTypes: this.generateTreatTypes(),
			unitTypes: this.generateUnitTypes(),
			intervals: this.generateIntervals(),
			intervalFequency: this.defaultIntervalFequency,
			drugOptions: [],
		};
		if (this.props.siteId) {
			let tempFromDate = new Date();
			tempFromDate.setMonth(new Date().getMonth() - 1);
			let tempToDate = new Date();
			tempToDate.setMonth(new Date().getMonth() + 1);
			this.props.getDrugTypes();
			this.props.loadMarkings();
		}
	}

	public async componentDidMount() {
		this.getDiagnoseImage();
		this.setInitInterval();
		const drugTypeOptions = new Array<Option>();
		let drugTypesForAnimal = this.getDrugTypesForAnimalType();
		if (drugTypesForAnimal.length === 0) {
			if (this.props.diagnose.isVaccination) {
				showAlert(localized('noDrugTypesForAnimalTypeVaccine'));
			} else {
				showAlert(localized('noDrugTypesForAnimalTypeMedicine'));
			}
		}
		drugTypeOptions.push(defaultOption);
		drugTypesForAnimal.forEach(type => {
			drugTypeOptions.push({ value: type!.id!.toString(), label: type.name!.toString() });
		});
		this.setState({ drugOptions: drugTypeOptions });
	}

	public setInitInterval() {
		switch (this.state.treatmentDefinition.frequency) {
			case TreatmentFrequency.Once: {
				this.setState({ intervalFequency: { value: localized('once'), label: localized('once') } });
				break;
			}
			case TreatmentFrequency.EveryDay: {
				this.setState({ intervalFequency: { value: localized('everyDay'), label: localized('everyDay') } });
				break;
			}
			case TreatmentFrequency.EveryOtherDay: {
				this.setState({
					intervalFequency: { value: localized('everyOtherDay'), label: localized('everyOtherDay') },
				});
				break;
			}
			case TreatmentFrequency.EveryWeek: {
				this.setState({ intervalFequency: { value: localized('everyWeek'), label: localized('everyWeek') } });
				break;
			}
			default: {
				this.setState({ intervalFequency: { value: ' ', label: ' ' } });
				break;
			}
		}
	}
	public render() {
		const columns = this.generateColumns();
		let headerText =
			getDiagnoseName(this.props.diagnose, this.props.profile.language) +
			' - ' +
			getAnimalKindString(this.state.treatmentDefinition.animalKinds);

		return (
			<PageContainer>
				<div className="add-treatment-definition">
					<Heading text={headerText} />
					{!this.props.diagnose.isVaccination && (
						<div className="treatment-column-container">
							{this.renderColumn1()}
							{this.renderColumn2()}
							{this.generateSubTreatment()}
						</div>
					)}

					<div style={{ marginTop: this.props.diagnose.isVaccination ? 20 : 0 }}>
						<GroupedHeaderSkioldTable
							columns={columns}
							data={this.state.treatmentDefinition!.subTreatments!}
							sortHeaderId={'Code'}
							filterable={false}
							className={'treatment-table usesOverflow'}
							sortable={false}
							style={{ marginTop: 20, marginBottom: 20 }}
						/>
					</div>
					<div className="diagnose-description-container">
						{this.renderDiagnosePicture()}
						{this.renderDiagnosePictureDescription()}
					</div>
					<div className="add-treatment-buttons-container">
						<SkioldButton title={localized('Save')} onPress={this.save} />
						<SkioldButton title={localized('Close')} onPress={() => this.props.close()} theme="grey" />
					</div>
				</div>
			</PageContainer>
		);
	}

	private getDrugTypesForAnimalType() {
		let drugs = this.getFilteredData();
		let drugTypes = this.props.drugTypes;
		const drugTypeType = this.props.diagnose.isVaccination ? DrugTypeType.Vaccine : DrugTypeType.Medicine;

		if (drugs.length > 0) {
			let drugTypesToBeDisplayed: IDrugType[] = [];

			drugs.forEach(drug => {
				let drugType = drugTypes.find(
					drugType => drugType.type === drugTypeType && drugType.id === drug.drugTypeId,
				);

				if (drugType) {
					drugTypesToBeDisplayed.push(drugType!);
				}
			});

			return drugTypesToBeDisplayed;
		} else {
			return [];
		}
	}

	private getFilteredData() {
		if (this.state.treatmentDefinition.animalKinds !== undefined) {
			return this.props.drugs.filter(a =>
				a.animalKinds!.some(ak => this.state.treatmentDefinition.animalKinds!.includes(ak)),
			);
		} else {
			return [];
		}
	}

	private renderColumn1() {
		return (
			<WrapperColumnWeb>
				<WrapperRowBaseline className="baseline-index">
					<TextWeb className="add-treatment-label">{localized('interval')}: </TextWeb>
					{this.renderIntervals()}
				</WrapperRowBaseline>

				{this.state.treatmentDefinition.frequency !== undefined &&
					this.state.treatmentDefinition.frequency !== TreatmentFrequency.Once && (
						<WrapperRowBaseline>
							<TextWeb className="add-treatment-label">{localized('numberTreatments')}: </TextWeb>

							<TextAlignLeft>
								<SkioldUnderlineIntergerInput
									className="add-treatment-value"
									onChangeNumber={text => {
										if (text !== undefined) {
											this.setNumberOfTreatments(text.toString());
										}
									}}
									text={
										this.state.treatmentDefinition.numberOfTreatments !== undefined
											? this.state.treatmentDefinition.numberOfTreatments
											: undefined
									}
								/>
							</TextAlignLeft>
						</WrapperRowBaseline>
					)}
			</WrapperColumnWeb>
		);
	}

	private renderIntervals() {
		return (
			<SkioldDropdown
				containerClassName="interval-container-dropdown"
				items={this.state.intervals}
				onValueChanged={(option: Option) => {
					this.setInterval(option);
				}}
				selectedValue={this.state.intervalFequency}
			/>
		);
	}

	private setCanBeRegistration = (checked: boolean) => {
		this.setState(prevState => ({
			treatmentDefinition: { ...prevState.treatmentDefinition, canBeATreatment: checked },
		}));
	};

	private renderColumn2() {
		return (
			<WrapperColumnWeb className="add-treatment-column2">
				<WrapperRowBaseline className="baseline-index">
					<ViewWeb>
						<TextWeb className="add-treatment-label">{localized('marking')}: </TextWeb>
					</ViewWeb>
					<MarkingPicker
						theme="light"
						onMarkingChanged={(m: string | undefined) => this.onMarkingChanged(m)}
						marking={this.state.treatmentDefinition.marking}
						placeholderText={false}
					/>
					<TextWeb className="add-treatment-label" />
				</WrapperRowBaseline>
				<WrapperRowBaseline className="baseline-index">
					<ViewWeb>
						<TextWeb className="add-treatment-label">{localized('CanBeRegistration')}: </TextWeb>
					</ViewWeb>
					<SkioldCheckbox
						isChecked={this.state.treatmentDefinition.canBeATreatment}
						onClick={this.setCanBeRegistration}
					/>
					<TextWeb className="add-treatment-label" />
				</WrapperRowBaseline>
			</WrapperColumnWeb>
		);
	}

	private renderDiagnosePicture() {
		return (
			<div>
				{this.state.diagnoseImage && (
					<WrapperColumnWeb className="diagnose-picture-container">
						<SkioldImage width={250} height={250} imageData={this.state.diagnoseImage!.base64Data} />
					</WrapperColumnWeb>
				)}
			</div>
		);
	}
	private renderDiagnosePictureDescription() {
		return (
			<WrapperColumnWeb
				style={{
					zIndex: 10,
				}}
			>
				<SkioldTextArea
					className="add-treatment-label"
					numberOfLines={13}
					editable={false}
					text={this.props.diagnose.description![this.props.profile.language as LanguageType]}
				/>
			</WrapperColumnWeb>
		);
	}

	private onMarkingChanged(markingText?: string) {
		this.setState(prevState => ({
			treatmentDefinition: { ...prevState.treatmentDefinition, marking: markingText },
		}));
	}

	private async getDiagnoseImage() {
		if (!this.props.diagnose || !this.props.diagnose.imageId) {
			return;
		}
		let image = this.findImageInProps();

		if (!image) {
			await this.props.getImage(this.props.diagnose.imageId);
			image = this.findImageInProps();
		}

		this.setState({ diagnoseImage: image });
	}

	private findImageInProps() {
		return this.props.diagnoseImages.find(img => img && img.id === this.props.diagnose.imageId);
	}

	private generateSubTreatment() {
		if (
			this.state.treatmentDefinition!.subTreatments === undefined ||
			this.state.treatmentDefinition!.subTreatments!.length < 1
		) {
			this.state.treatmentDefinition!.subTreatments = [];
			this.state.treatmentDefinition!.subTreatments!.push(
				new SubTreatment({ id: new ObjectID().toHexString(), drugAmount: new DrugAmount() } as ISubTreatment),
			);
		}
		if (this.state.treatmentDefinition!.subTreatments!.length <= 2) {
			return (
				<div className="generate-sub-treatment-container">
					<ViewWeb style={{ alignItems: 'center' }}>
						<SkioldTouchableOpacity onPress={this.addSubTreatment}>
							<SkioldImage width="60" height="60" imageData={opret_medicin} />
						</SkioldTouchableOpacity>
						<WhiteText>{localized('AddToDrugType')}</WhiteText>
					</ViewWeb>
				</div>
			);
		} else {
			return <ViewWeb />;
		}
	}

	private renderDrugPicker(subTreatment: SubTreatment) {
		const selectedDrugType = this.state.drugOptions.find(option => subTreatment.drugTypeId === option.value);
		return (
			<SkioldDropdown
				items={this.state.drugOptions}
				onValueChanged={(value: Option) => {
					this.setDrugType(value, subTreatment);
				}}
				usedInTable={true}
				theme={'dark'}
				selectedValue={selectedDrugType ? selectedDrugType : defaultOption}
			/>
		);
	}

	private renderEnumPicker(
		type: 'treatmentTypes' | 'unitTypes',
		initVal: TreatmentType | TreatmentUnit | undefined,
		st?: SubTreatment,
	) {
		const selectedVal =
			initVal !== undefined
				? this.state[type].find(
						enumType =>
							enumType.value ===
							(type === 'treatmentTypes' ? st!.treatmentType : st!.drugAmount!.unitType),
				  )
				: { value: ' ', label: ' ' };
		return (
			<SkioldDropdown
				items={this.state[type]}
				usedInTable={true}
				onValueChanged={(option: Option) => {
					if (type === 'treatmentTypes' && st !== undefined) {
						this.setTreatType(option.value);
					}
					if (type === 'unitTypes' && st !== undefined) {
						this.setUnitType(option.value);
					}
				}}
				theme={'dark'}
				selectedValue={selectedVal}
			/>
		);
	}

	private addSubTreatment = () => {
		const subTreatmentsCopy = deepCopy(this.state.treatmentDefinition!.subTreatments!);

		let newSubTreatment = new SubTreatment({
			id: new ObjectID().toHexString(),
			drugAmount: new DrugAmount(),
		} as ISubTreatment);

		if (subTreatmentsCopy && subTreatmentsCopy.length) {
			newSubTreatment.treatmentType = subTreatmentsCopy[0].treatmentType;
			newSubTreatment.drugAmount!.unitType = subTreatmentsCopy[0].drugAmount!.unitType;
		}

		subTreatmentsCopy.push(newSubTreatment);

		this.setState(prevState => ({
			...prevState,
			treatmentDefinition: {
				...prevState.treatmentDefinition,
				subTreatments: subTreatmentsCopy,
			},
		}));
	};

	private deleteSubTreatment(subTreatment: SubTreatment) {
		const subTreatmentsCopy = deepCopy(this.state.treatmentDefinition!.subTreatments!);

		let index = subTreatmentsCopy.findIndex(st => st.id === subTreatment.id);
		subTreatmentsCopy.splice(index, 1);

		this.setState(prevState => ({
			...prevState,
			treatmentDefinition: {
				id: undefined,
				...prevState.treatmentDefinition,
				subTreatments: subTreatmentsCopy,
			},
		}));
		this.setHasChanged();
	}

	private generateColumns() {
		const isVaccination = this.props.diagnose.isVaccination;
		const columns: any[] = [
			//Col 1
			{
				Header: localized('drug'),
				columns: [
					{
						width: SowListConstants.treatDefinitionDrugWidth,
						id: 'drug',
						accessor: (subTreatment: SubTreatment) => {
							return this.renderDrugPicker(subTreatment);
						},
					},
				],
			},
		];
		// col 2
		if (!isVaccination) {
			columns.push({
				Header: localized('RetentionTime'),
				columns: [
					{
						id: 'butcherQuarantine',
						width: SowListConstants.RetentionTimeWidth,
						accessor: (subTreatment: SubTreatment) => (
							<SkioldInput
								className="cell-input"
								onChangeText={text => {
									this.setRetentionTime(text, subTreatment);
								}}
								text={
									subTreatment.butcherQuarantine !== undefined
										? subTreatment.butcherQuarantine!.toString()
										: ''
								}
							/>
						),
					},
				],
			});
		}
		//Col 3
		columns.push({
			Header: localized('method'),
			columns: [
				{
					id: 'treatmentType',
					width: 125,
					accessor: (subTreatment: SubTreatment) => {
						return isVaccination ? (
							<TextWeb>{localized(subTreatment.treatmentType!)}</TextWeb>
						) : (
							this.renderEnumPicker('treatmentTypes', subTreatment.treatmentType, subTreatment)
						);
					},
				},
			],
		});

		//Col 4

		let amountPerUnit = {
			id: 'amountPerUnit',
			Header: localized('amountPerUnit'),

			columns: [
				{
					id: 'amount',
					width: SowListConstants.batchWidth,
					accessor: (subTreatment: SubTreatment) => (
						<SkioldDecimalInput
							className="cell-input"
							onChangeNumber={text => {
								this.setDrugAmount(text, subTreatment);
							}}
							text={subTreatment.drugAmount!.amount}
						/>
					),
				},
				{
					id: 'unit',
					width: SowListConstants.batchWidth,
					accessor: (st: SubTreatment) => {
						const type = this.props.drugTypes.find(d => d.id === st.drugTypeId);
						if (type) {
							return (
								<TextWeb>
									{type.unitOfMeasurement} {localized('per')}
								</TextWeb>
							);
						}
						return;
					},
				},
			],
		};
		if (!isVaccination) {
			amountPerUnit.columns.push({
				id: 'perUnit',
				width: SowListConstants.batchWidth,
				accessor: (subTreatment: SubTreatment) => (
					<SkioldInput
						className="cell-input"
						onChangeText={text => {
							this.setPerUnit(text, subTreatment);
						}}
						text={
							subTreatment.drugAmount!.perUnit !== undefined
								? subTreatment.drugAmount!.perUnit!.toString()
								: ''
						}
						// editable={subTreatment.drugAmount!.unitType === TreatmentUnit.PerAnimal}
						selectTextOnFocus={subTreatment.drugAmount!.unitType !== TreatmentUnit.PerAnimal}
					/>
				),
			});
		}
		amountPerUnit.columns.push({
			id: 'unitType',
			width: 120,
			accessor: (subTreatment: SubTreatment) => {
				return isVaccination ? (
					<TextWeb>{localized('Animals')}</TextWeb>
				) : (
					this.renderEnumPicker('unitTypes', subTreatment.drugAmount!.unitType, subTreatment)
				);
			},
		});
		columns.push(amountPerUnit);
		if (!this.props.diagnose.isVaccination) {
			//Col 6
			columns.push({
				width: SowListConstants.iconWidth,
				id: 'delete',
				Cell: (row: Row<SubTreatment>) => (
					<SkioldTouchableOpacity onPress={() => this.deleteSubTreatment(row.original)}>
						<SkioldImage
							width={SowListConstants.iconSVGWidth}
							height={SowListConstants.iconSVGWidth}
							imageData={DeleteIcon}
						/>
					</SkioldTouchableOpacity>
				),
			});
		}
		return columns;
	}

	private setInterval(interval: Option) {
		this.setState({ intervalFequency: interval });
		const enumVals: TreatmentFrequency[] = Object.values(TreatmentFrequency);
		let val = enumVals.filter(x => x === interval.value)[0];
		switch (interval.value) {
			case localized('once'): {
				val = enumVals.filter(x => x === TreatmentFrequency.Once)[0];
				break;
			}
			case localized('everyDay'): {
				val = enumVals.filter(x => x === TreatmentFrequency.EveryDay)[0];
				break;
			}
			case localized('everyOtherDay'): {
				val = enumVals.filter(x => x === TreatmentFrequency.EveryOtherDay)[0];
				break;
			}
			case localized('everyWeek'): {
				val = enumVals.filter(x => x === TreatmentFrequency.EveryWeek)[0];
				break;
			}
			default: {
				break;
			}
		}
		this.setState(prevState => ({
			treatmentDefinition: {
				...prevState.treatmentDefinition,
				frequency: val,
			},
		}));
		this.setHasChanged();
		if (val === TreatmentFrequency.Once || interval.value === localized('choose')) {
			this.setNumberOfTreatments('');
		}
	}

	private setNumberOfTreatments(text: string) {
		IntegerChanged(text, valueToSet => {
			this.setState(prevState => ({
				treatmentDefinition: {
					...prevState.treatmentDefinition,
					numberOfTreatments: valueToSet!,
				},
			}));
		});
		this.setHasChanged();
	}

	private setTreatType(treatmentType: string) {
		const enumVals: TreatmentType[] = Object.values(TreatmentType);
		const val = enumVals.find(x => x === treatmentType);
		let subTreatments = [...this.state.treatmentDefinition.subTreatments!];
		subTreatments.map(st => (st.treatmentType = val!));

		this.setState(prevState => ({
			...prevState,
			treatmentDefinition: {
				...prevState.treatmentDefinition,
				subTreatments,
			},
		}));
		this.setHasChanged();
	}

	private setUnitType(unitType: string) {
		const enumVals: TreatmentUnit[] = Object.values(TreatmentUnit);
		const val = enumVals.find(x => x === unitType);

		let subTreatments = [...this.state.treatmentDefinition.subTreatments!];
		subTreatments.forEach(st => {
			st.drugAmount!.unitType = val!;
			st.drugAmount!.perUnit = val === 'PerAnimal' ? 1 : st.drugAmount!.perUnit;
		});

		this.setState(prevState => ({
			...prevState,
			treatmentDefinition: {
				...prevState.treatmentDefinition,
				subTreatments,
			},
		}));
		this.setHasChanged();
	}

	private setRetentionTime(text: string, subTreatment: SubTreatment) {
		IntegerChanged(text, valueToSet => {
			const subTreatmentsCopy = deepCopy(this.state.treatmentDefinition.subTreatments);
			const index = subTreatmentsCopy!.findIndex(sub => sub.id === subTreatment.id);
			subTreatmentsCopy![index].butcherQuarantine = valueToSet!;
			this.setState(prevState => ({
				treatmentDefinition: { ...prevState.treatmentDefinition, subTreatments: subTreatmentsCopy },
			}));
		});
		this.setHasChanged();
	}

	private setDrugAmount(text: any, subTreatment: SubTreatment) {
		const subTreatmentCopy = deepCopy(subTreatment);
		subTreatmentCopy.drugAmount!.amount = text;
		this.setState(prevState => ({
			...prevState,
			treatmentDefinition: {
				...prevState.treatmentDefinition,
				subTreatments: prevState!.treatmentDefinition!.subTreatments!.map(t =>
					t.id === subTreatmentCopy.id ? subTreatmentCopy : t,
				),
			},
		}));
		this.setHasChanged();
	}
	private setHasChanged() {
		this.setState(prevState => ({
			...prevState,
			treatmentDefinition: {
				...prevState.treatmentDefinition,
				id: undefined,
				isActive: true,
			},
			prevTreatmentDefinition: {
				...prevState.prevTreatmentDefinition,
				isActive: false,
			},
		}));
	}

	private setPerUnit(text: string, subTreatment: SubTreatment) {
		IntegerChanged(text, valueToSet => {
			const subTreatmentCopy = deepCopy(subTreatment);
			subTreatmentCopy.drugAmount!.perUnit = valueToSet!;

			this.setState(
				prevState => ({
					...prevState,
					treatmentDefinition: {
						...prevState.treatmentDefinition,
						subTreatments: prevState!.treatmentDefinition!.subTreatments!.map(t =>
							t.id === subTreatmentCopy.id ? subTreatmentCopy : t,
						),
					},
				}),
				this.setHasChanged,
			);
		});
	}

	private setDrugType(text: Option, st: SubTreatment) {
		const subTreatmentCopy = deepCopy(st);
		subTreatmentCopy.drugTypeId = text.value;
		this.setState(
			prevState => ({
				...prevState,
				treatmentDefinition: {
					...prevState.treatmentDefinition,
					subTreatments: prevState!.treatmentDefinition!.subTreatments!.map(t => {
						return t.id === subTreatmentCopy.id ? subTreatmentCopy : t;
					}),
				},
			}),
			this.setHasChanged,
		);
	}

	private save = async () => {
		const treatDefCopy = deepCopy(this.state.treatmentDefinition);
		treatDefCopy.siteId = this.props.siteId;
		if (!this.validate(treatDefCopy)) {
			return;
		}
		if (treatDefCopy.frequency === TreatmentFrequency.Once) {
			treatDefCopy.numberOfTreatments = 1;
		}
		if (
			this.state.prevTreatmentDefinition.id !== undefined &&
			this.state.prevTreatmentDefinition.id !== treatDefCopy.id
		) {
			this.props.saveTreatmentDefinition(this.state.prevTreatmentDefinition, this.props.diagnose.isVaccination!);
		}
		this.props.saveTreatmentDefinition(treatDefCopy, this.props.diagnose.isVaccination!);
		this.props.close();
	};

	private validate(treatmentDefinition: ITreatmentDefinition) {
		//let valid = true;
		if (treatmentDefinition.frequency === undefined) {
			showAlert(localized('VALIDATION_ERROR_NoFrequency'));
			return false;
		}
		if (!treatmentDefinition.diagnoseId) {
			showAlert(localized('VALIDATION_ERROR_NoDiagnoseID'));
			return false;
		}
		if (!treatmentDefinition.animalKinds || !treatmentDefinition.animalKinds.length) {
			showAlert(localized('VALIDATION_ERROR_NoAnimalKinds'));
			return false;
		}
		if (!treatmentDefinition.numberOfTreatments || treatmentDefinition.numberOfTreatments < 1) {
			if (treatmentDefinition.frequency !== TreatmentFrequency.Once) {
				showAlert(localized('VALIDATION_ERROR_NoNumberOfTreatments'));
				return false;
			}
		}

		for (let e of treatmentDefinition.subTreatments!) {
			if (!e.id) {
				showAlert(localized('VALIDATION_ERROR_RefreshError'));
				return false;
			}
			if (!e.drugTypeId) {
				showAlert(localized('VALIDATION_ERROR_NoDrugTypeIsChoosed'));
				return false;
			}
			if (e.treatmentType === undefined) {
				showAlert(localized('VALIDATION_ERROR_NoTreatmentType'));
				return false;
			}
			if (!e.drugAmount) {
				showAlert(localized('VALIDATION_ERROR_RefreshError'));
				return false;
			}
			if (!e.drugAmount || !e.drugAmount.amount || e.drugAmount.amount < 0) {
				showAlert(localized('VALIDATION_ERROR_NoDrugAmountValue'));
				return false;
			}
			if (!e.drugAmount || !e.drugAmount.perUnit || e.drugAmount.perUnit < 1) {
				showAlert(localized('VALIDATION_ERROR_NoPerUnit'));
				return false;
			}
			if (!e.drugAmount || e.drugAmount.unitType === undefined) {
				showAlert(localized('VALIDATION_ERROR_NoUnitType'));
				return false;
			}
			if (isNullOrUndefined(e.butcherQuarantine) && !this.props.diagnose.isVaccination) {
				showAlert(localized('VALIDATION_ERROR_NoRetentionTime'));
				return false;
			}
		}

		return true;
	}

	private generateTreatTypes(): Option[] {
		return [
			{ value: ' ', label: ' ' },
			{ value: TreatmentType.Food, label: localized('Food') },
			{ value: TreatmentType.Water, label: localized('Water') },
			{ value: TreatmentType.Oral, label: localized('Oral') },
			{ value: TreatmentType.Injection, label: localized('Injection') },
			{ value: TreatmentType.OnTheSkin, label: localized('OnTheSkin') },
		];
	}

	private generateUnitTypes(): Option[] {
		return [
			{ value: ' ', label: ' ' },
			{ value: TreatmentUnit.PerKgPig, label: localized('perKgPig') },
			{ value: TreatmentUnit.PerKgFood, label: localized('perKgFood') },
			{ value: TreatmentUnit.PerAnimal, label: localized('perAnimal') },
		];
	}

	private generateIntervals(): Option[] {
		return [
			{ value: ' ', label: ' ' },
			{ value: localized('once'), label: localized('once') },
			{ value: localized('everyDay'), label: localized('everyDay') },
			{ value: localized('everyOtherDay'), label: localized('everyOtherDay') },
			{ value: localized('everyWeek'), label: localized('everyWeek') },
		];
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AddTreatmentDefinition);
