import React, { FC, useCallback, useEffect, useState } from 'react';
import { SkioldModal } from '../skiold-components/skiold-modal/skiold-modal';
import { Heading } from '../utils/heading';
import { localized } from '../../../../shared/state/i18n/i18n';
import { ViewWeb } from '../utils/web-view';
import { ChartPoint, Graph } from '../graph/graph';
import { ChartDataset, ChartOptions, ChartType } from 'chart.js';
import { Colors } from '../../../../shared/assets/root-assets/colors';
import moment from 'moment/moment';
import { useDispatch, useSelector } from 'react-redux';
import { IAdjustmentDTO, LocationType } from '../../../../shared/api/api';
import {
	resetGraph,
	selectFeedUsagePending,
	selectFeedUsageWeanerGraph,
} from '../../../../shared/state/ducks/feed-usage/reducer';
import { getByPenForGraph, getGraphForSection } from '../../../../shared/state/ducks/feed-usage/operation';
import { WebAppState } from '../../../state/store.web';
import { AdjustmentsModal } from '../dashboard-growth-pigs/elaboration/feed-usage-graph/adjustments-modal';
import { SkioldDatePicker } from '../skiold-components/skiold-date-picker/skiold-date-picker';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { Option } from 'react-dropdown';
import { memoizeHashmapLocation } from '../../../../shared/helpers/memoize-getters/memoize-getters';
import {
	getLocationPlaceholder,
	getStableSectionOptionByLocationTypes,
} from '../../../../shared/helpers/location-helper';
import { SkioldDropdown } from '../skiold-components/skiold-dropdown/skiold-dropdown';
import { SowFeedUsagePenSlider } from './sow-feed-usage-slider';
import { SkioldButton } from '../skiold-components/skiold-button/skiold-button';
import '../dashboard-growth-pigs/elaboration/feed-usage-graph/feed-usage-graph.scss';
import { Spinner } from 'web/view/components/spinner/spinner';

interface Props {
	isOpen: boolean;
	close: () => void;
	locationTypes?: LocationType[];
}

export const SowFeedUsageGraph: FC<Props> = React.memo(props => {
	const hashmapLocations = useSelector((state: WebAppState) =>
		memoizeHashmapLocation(state.locations.buildings, state.locations.sections, state.locations.pens),
	);

	const dispatch = useDispatch();
	const graphDto = useSelector(selectFeedUsageWeanerGraph);
	const pending = useSelector(selectFeedUsagePending);
	const siteId = useSelector((state: WebAppState) => state.site.site.id);

	const [data, setData] = useState<ChartPoint[]>([]);
	const [dataSets, setDataSets] = useState<ChartDataset<ChartType, number[] | ChartPoint[]>[]>([]);
	const [fromDate, setFromDate] = useState(moment().subtract(30, 'days'));
	const [toDate, setToDate] = useState(moment());
	const [adjustmentsOnDate, setAdjustmentsOnDate] = useState<IAdjustmentDTO[] | undefined>([]);
	const [adjustmentDate, setAdjustmentDate] = useState(moment());
	const [adjustmentModalOpen, setAdjustmentModalOpen] = useState(false);
	const [selectedSectionOption, setSelectedSectionOption] = useState<Option>(getLocationPlaceholder());
	const [stableSectionOptions, setStableSectionOptions] = useState<Option[]>([]);
	const [penId, setPenId] = useState<string | undefined>('');

	// Generate Section options
	useEffect(() => {
		setStableSectionOptions(getStableSectionOptionByLocationTypes(hashmapLocations, props.locationTypes));
	}, [hashmapLocations, props.locationTypes]);

	useEffect(() => {
		if (!props.isOpen) {
			setSelectedSectionOption(getLocationPlaceholder());
			dispatch(resetGraph());
			setPenId(undefined);
		}
	}, [props.isOpen, dispatch]);

	useEffect(() => {
		if (pending || siteId === undefined || !props.isOpen || selectedSectionOption.value === '') {
			return;
		}
		if (penId) {
			dispatch(
				getByPenForGraph({
					siteId: siteId,
					penId: penId,
					fromDate: fromDate.toDate(),
					toDate: toDate.toDate(),
				}),
			);
		} else {
			dispatch(
				getGraphForSection({
					siteId: siteId,
					sectionId: selectedSectionOption.value,
					fromDate: fromDate.toDate(),
					toDate: toDate.toDate(),
				}),
			);
		}
		// eslint-disable-next-line
	}, [fromDate, toDate, siteId, props.isOpen, selectedSectionOption, penId]);

	useEffect(() => {
		const extraDataSets: ChartDataset<ChartType, number[] | ChartPoint[]>[] = [];
		const feedCurve = mapToGraphLine(graphDto?.feedUnitsCurve ?? {});
		const penDays = mapToGraphLine(graphDto?.penDays ?? {});
		const feedUnitsPerDay = mapToGraphLine(graphDto?.feedUnitsPerDay ?? {});

		extraDataSets.push({
			data: penDays,
			label: localized('SowCycle'),
			backgroundColor: Colors.controllersBlue,
			borderColor: Colors.controllersBlue,
			pointBackgroundColor: Colors.controllersBlue,
			yAxisID: 'PenDays',
			fill: false,
			pointRadius: 0,
		});

		extraDataSets.push({
			data: feedCurve,
			label: localized('feedCurve'),
			backgroundColor: Colors.yellow,
			borderColor: Colors.yellow,
			pointBackgroundColor: Colors.yellow,
			yAxisID: 'y',
			fill: false,
			pointRadius: 0,
		});

		setDataSets(extraDataSets);
		setData(feedUnitsPerDay);
	}, [graphDto]);

	const closeAdjustmentModal = useCallback(() => {
		setAdjustmentModalOpen(false);
	}, []);

	const handleFromDateChanged = useCallback((newDate: Date) => {
		setFromDate(moment(newDate));
	}, []);

	const handleToDateChanged = useCallback((newDate: Date) => {
		setToDate(moment(newDate));
	}, []);

	const stableSectionChange = useCallback((option: Option) => {
		setSelectedSectionOption(option);
		setPenId(undefined);
	}, []);

	const getGraphOptions = useCallback((): ChartOptions => {
		return {
			onClick: (e, items) => {
				const curveItem = items.find(i => i.datasetIndex === 0);
				if (curveItem === undefined || graphDto?.adjustments === undefined) {
					return;
				}
				const dataPointDate = data[curveItem.index].x;
				setAdjustmentsOnDate(graphDto?.adjustments[dataPointDate as string]);
				setAdjustmentModalOpen(true);
				setAdjustmentDate(moment(dataPointDate));
			},
			plugins: {
				legend: {
					display: true,
					labels: {
						color: Colors.lighterGrey,
						usePointStyle: true,
						padding: 20,
					},
					position: 'bottom',
					align: 'start',
				},
				tooltip: {
					callbacks: {
						label: tooltipItem => {
							const label = tooltipItem.dataset.label;
							const value = tooltipItem.parsed.y.toFixed(2);
							if (tooltipItem.datasetIndex === 1) {
								return `${label}: ${value} ${localized('Days')}`;
							} else {
								return `${label}: ${value} ${localized('FE')}`;
							}
						},
					},
				},
			},

			scales: {
				x: {
					title: {
						color: Colors.lighterGrey,
						display: true,
						text: localized('Date'),
					},
					type: 'timeseries',
					ticks: {
						color: Colors.lighterGrey,
						display: true,
						autoSkip: false,
						minRotation: 45,
						maxRotation: 75,
					},
					display: true,
					time: {
						minUnit: 'day',
						displayFormats: {
							day: 'DD/MM',
							color: Colors.lighterGrey,
						},
						tooltipFormat: 'DD/MM/YYYY',
					},
					grid: {
						color: Colors.lighterGrey,
						borderDash: [2, 2],
					},
				},
				y: {
					suggestedMin: 0,
					title: {
						color: Colors.lighterGrey,
						display: false,
						text: localized('FE'),
						align: 'end',
					},
					grid: {
						display: true,
						color: Colors.lighterGrey,
						borderDash: [2, 2],
					},
					ticks: {
						color: Colors.lighterGrey,
						display: true,
						callback: tickValue => `${parseFloat(tickValue as string).toFixed(1)} ${localized('FE')}`,
						maxTicksLimit: 10,
					},
				},
				PenDays: {
					suggestedMin: 0,
					title: {
						color: Colors.lighterGrey,
						display: false,
						text: localized('Days'),
					},
					grid: {
						display: false,
						color: Colors.lighterGrey,
						borderDash: [2, 2],
					},
					position: 'right',
					ticks: {
						color: Colors.lighterGrey,
						display: true,
						callback: tickValue => `${parseFloat(tickValue as string).toFixed(0)} ${localized('Days')}`,
					},
				},
			},
			elements: {
				line: {
					tension: 0,
					borderWidth: 2,
				},
				point: {
					radius: ctx => {
						if (graphDto?.adjustments && ctx.raw) {
							return graphDto?.adjustments[(ctx.raw as ChartPoint).x as string] === undefined ? 0 : 3;
						}
						return 0;
					},
				},
			},
		};
	}, [data, graphDto]);

	return (
		<SkioldModal
			shouldCloseOnOverlayClick={true}
			overflowNone={true}
			isOpen={props.isOpen}
			close={props.close}
			className={'feed-usage-graph-container'}
			backgroundImage={'dark-background-graphic.jpg'}
		>
			<Heading text={localized('FeedUsage')} />
			<ViewWeb className={'header-buttons'}>
				<ViewWeb className={'block-container'}>
					<TextWeb>{localized('FromDate')}: </TextWeb>
					<SkioldDatePicker
						onDateChanged={handleFromDateChanged}
						selectedDate={fromDate.toDate()}
						maxDate={new Date()}
						textFieldClassName={'date-display'}
					/>
					<TextWeb>{localized('ToDate')}: </TextWeb>
					<SkioldDatePicker
						onDateChanged={handleToDateChanged}
						selectedDate={toDate.toDate()}
						maxDate={new Date()}
						textFieldClassName={'date-display'}
					/>
				</ViewWeb>
				<ViewWeb className={'block-container margin-right-auto'}>
					<TextWeb>
						{localized('stable')} - {localized('section')}:{' '}
					</TextWeb>
					<SkioldDropdown
						items={stableSectionOptions}
						selectedValue={selectedSectionOption}
						onValueChanged={stableSectionChange}
						menuClassname={'width-max-content'}
					/>
					<SowFeedUsagePenSlider setPenId={setPenId} sectionId={selectedSectionOption?.value} penId={penId} />
				</ViewWeb>
				<ViewWeb className={'spacer-view'} />
			</ViewWeb>
			<ViewWeb>
				{pending && <Spinner />}
				{props.isOpen && (
					<Graph
						data={data}
						extraDataSets={dataSets}
						lineColor={Colors.lightGreen}
						lineAreaColor={Colors.lightGreen}
						height={'50vh'}
						width={'100%'}
						datasetLabel={`${localized('FE')}/${localized('Day')}`}
						datasetScaleId={'y'}
						options={getGraphOptions()}
					/>
				)}
			</ViewWeb>
			<ViewWeb className={'button-view-style'}>
				<SkioldButton title={localized('Close')} onPress={props.close} theme={'grey'} />
			</ViewWeb>
			<AdjustmentsModal
				adjustmentModalOpen={adjustmentModalOpen}
				closeAdjustmentModal={closeAdjustmentModal}
				adjustments={adjustmentsOnDate}
				date={adjustmentDate}
			/>
		</SkioldModal>
	);
});

function mapToGraphLine(dict: { [key: string]: number }) {
	return Object.entries(dict).map(([key, value]) => ({ x: key, y: value })) || [];
}
