import ObjectID from 'bson-objectid';
import React from 'react';
import { Option } from 'react-dropdown';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
	AnimalKind,
	AnimalType,
	Equipment,
	FeedingStatusUpdate,
	Gender,
	IFeedingStatusUpdate,
	IMated,
	IPregnancyEvent,
	IStemAnimal,
	Mated,
	PregnancyState,
	StemAnimal,
} from 'shared/api/api';
import ForwardArrowGrey from 'shared/assets/src-assets/png/forward_arrow_bottom_grey.png';
import { getAnimalKindOptions, shouldUseLetters } from 'shared/helpers/animal-kinds-helpers';
import { getDateString, isDateAfter, isDateSameOrAfter, isDateSameOrBefore } from 'shared/helpers/date-helpers';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { deepCopy, isNullOrUndefined } from 'shared/helpers/general-helpers';
import { CheckIfSectionUsesPens, FindHiddenPenOnSection } from 'shared/helpers/location-helper';
import {
	handleDanishGeneticsIdNumber,
	hasDanishNucleusFeatureLicence,
	hasNucleusFeatureCEFN,
	memoizeBreedNumberOptions,
	memoizeBreedTableItemsOptions,
} from 'shared/helpers/nucleus-management-helper/nucleus-management-helper';
import { NucleusValidator } from 'shared/helpers/nucleus-management-helper/nucleus-validation-interface';
import { sortPregnanciesByDateReversed } from 'shared/helpers/pregnancy-helper/sort-pregnancies';
import {
	anyYoungAnimals,
	findAnimal,
	findAnimalById,
	getBoars,
	getSows,
	getYoungAnimals,
	getYoungMales,
} from 'shared/helpers/stemanimal-helper/stem-animal-input-helper';
import {
	MAX_TRANSPONDER_NUMBER,
	ValidateStemAnimalEntrance,
	getBoarTypeOptions,
} from 'shared/helpers/stemanimal-helper/stemanimal-helper';
import { GetLocations } from 'shared/state/ducks/locations/operations';
import { selectNucleusManagementOrAssignIdAccess } from 'shared/state/ducks/site/reducer';
import { CombinedEntranceAndMated } from 'shared/state/ducks/stem-animals';
import {
	ResendStemAnimal,
	SaveEntranceFeedingStatus,
	SaveSow,
	SaveSowWithMated,
	GetSyncData as StemAnimalGetSyncData,
	UpdateYoungAnimal,
} from 'shared/state/ducks/stem-animals/operations';
import { localized, localizedDynamic } from 'shared/state/i18n/i18n';
import { EntranceTypes } from 'shared/state/models/entrance-types';
import { WebAppState } from 'web/state/store.web';
import SkioldFormPenPicker from 'web/view/components/location/location-picker/skiold-form-pen-picker';
import SkioldStableSectionPicker from 'web/view/components/location/location-picker/skiold-stable-section-picker';
import RegisterMatingComponent, {
	RegisterMatingComponent as RegisterMatingRef,
} from 'web/view/components/pregnancies/pregnancy-events/register-mating-components';
import SkioldFeedCurvePicker from 'web/view/components/process-equipment/skiold-feed-curve-picker';
import { ShowConfirmAlert, showAlert } from 'web/view/components/skiold-alert/skiold-alert';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldCheckbox } from 'web/view/components/skiold-components/skiold-checkbox/skiold-checkbox';
import { SkioldDatePicker } from 'web/view/components/skiold-components/skiold-date-picker/skiold-date-picker';
import { SkioldFormDropdown } from 'web/view/components/skiold-components/skiold-dropdown/skiold-form-dropdown';
import { SkioldFormTextField } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-form-text';
import { SkioldFormsWrapper } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { SkioldFormInput } from 'web/view/components/skiold-components/skiold-input/skiold-form-input';
import { SkioldFormIntegerInput } from 'web/view/components/skiold-components/skiold-integer-input/skiold-form-integer-input';
import { SkioldModal } from 'web/view/components/skiold-components/skiold-modal/skiold-modal';
import { SkioldTouchableOpacity } from 'web/view/components/skiold-components/skiold-touchable-opacity';
import { Scroller } from 'web/view/components/utils/scroller';
import { SkioldImage } from 'web/view/components/utils/svg/skiold-image';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { createAndEditValidation } from 'web/web-helpers/pregnancies/register-validation-helper';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { GenderPicker } from '../../gender-picker/gender-picker';
import SearchYoungAnimals from '../search-animals/search-young-animals';
import './sow-entrance.scss';
import { stemAnimals } from 'shared/state/store.shared';

const mapStateToProps = (state: WebAppState) => {
	const keyvalue = state.breedTableItems.entities.keyValuePairSingle('id');
	return {
		siteId: state.profile.active!.siteId,
		validationSetup: state.validationSetup.entity,
		locations: state.locations,
		moveEvents: state.moveEvents.entities,
		pens: state.locations.pens,
		unitToPens: state.unitToPenData.data,
		esfExists: state.processEquipments.entities.find(pq => pq.equipment === Equipment.ESF) !== undefined,
		pregnancies: state.pregnancyEvents.entities,
		processEquipmentData: state.processEquipmentData.entities,
		generalSettings: state.generalSettings.entity,
		showInitials: state.generalSettings.entity.stemAnimalShowInitials,
		defaultInitial: state.profile.active?.id,
		breedNumberOptions: [
			{
				value: '',
				label: ' ',
			} as Option,
		].concat(memoizeBreedNumberOptions(state.nucleusManagementBreedingSettings.breedingSettings)),
		breedTableItemOptions: memoizeBreedTableItemsOptions(keyvalue, state.licences.nucleusManagementCompany),
		nucleusManagement: selectNucleusManagementOrAssignIdAccess(state),
		breedTableItems: state.breedTableItems.entities,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	saveSow: (sow: IStemAnimal, feedCurve?: string, fixedPercentage?: number, hasIdSwap?: boolean) =>
		SaveSow(sow, feedCurve, fixedPercentage, hasIdSwap)(dispatch),
	saveSowWithMated: (combined: CombinedEntranceAndMated, feedCurve?: string, fixedPercentage?: number) =>
		SaveSowWithMated(combined, feedCurve, fixedPercentage)(dispatch),
	stemAnimalGetSyncData: () => StemAnimalGetSyncData()(dispatch),
	GetLocations: () => GetLocations()(dispatch),
	saveEntranceFeedingStatus: (entranceFeedingStatus: IFeedingStatusUpdate) =>
		SaveEntranceFeedingStatus(entranceFeedingStatus)(dispatch),
	updateYoungAnimal: (youngAnimal: IStemAnimal) => UpdateYoungAnimal(youngAnimal)(dispatch),
});

interface PropsFromParent {
	stemAnimalId?: string;
	shouldCloseOnSave?: boolean;
	closeEditModal?: () => void;
	navigateBack?: () => void;
}

interface SowEntranceState {
	stemAnimal: IStemAnimal;
	entranceEvents: Option[];
	isMating: boolean;
	kind: string;
	modalIsOpen: boolean;
	sectionId?: string;
	penId?: string;
	isEdit: boolean;
	entranceType: Option;
	gender: Gender;
	mated: IMated;
	dateSelected: Date;
	boarNumber?: string;
	boarId?: string;
	transponderNumber: number | undefined;
	canEditLitter: boolean;
	feedCurveNumber?: number;
	fixedPercentage: number | undefined;
	sectionHasStation: boolean;
	breed: string | undefined;
	birthdate: Date | undefined;
	youngAnimalToEdit?: IStemAnimal; // if a animal use search change of id number, it is neccessary to switch idnum, since Object IDs is bound to stemanimal
	breedNumberOption: Option;
	breedTableItemOption: Option;
	idNumberKind: 'Animal';
	isEditEntranceKind: Option;
	boarTypeOptions: Option[];
	selectedBoarType: Option;
}

type SowEntranceProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;
class SowEntrance extends React.PureComponent<SowEntranceProps, SowEntranceState> {
	private DefaultEntranceTypeOption: Option = {
		label: localized(EntranceTypes.Bought),
		value: EntranceTypes.Bought,
	};
	private registerMatingRef: RegisterMatingRef | undefined;
	private scrollerRef: Scroller | null | undefined;
	private animalNumInputRef: SkioldFormInput | null | undefined;

	constructor(props: SowEntranceProps) {
		super(props);
		this.state = {
			mated: Mated.fromJS({ date: new Date(), state: PregnancyState.Mated, initials: props.defaultInitial }),
			stemAnimal: {
				gender: Gender.Female,
				entranceDate: new Date(),
				breedIndexDate: new Date(),
				entranceLitterNumber: 0,
				siteId: this.props.siteId,
				entranceKind: AnimalKind.YoungFemale,
			} as IStemAnimal,
			dateSelected: new Date(),
			entranceEvents: this.generateEntranceType(),
			isMating: false,
			kind: localized('youngFemale'),
			modalIsOpen: false,
			sectionId: undefined,
			penId: undefined,
			boarNumber: undefined,
			boarId: undefined,
			isEdit: false,
			entranceType: this.DefaultEntranceTypeOption,
			gender: Gender.Female,
			transponderNumber: undefined,
			canEditLitter: true,
			feedCurveNumber: undefined,
			fixedPercentage: undefined,
			sectionHasStation: false,
			breed: undefined,
			birthdate: undefined,
			breedNumberOption: { label: ' ', value: '' },
			breedTableItemOption: { label: ' ', value: '' },
			isEditEntranceKind: { label: ' ', value: '' },
			idNumberKind: 'Animal',
			boarTypeOptions: getBoarTypeOptions(),
			selectedBoarType: { label: ' ', value: '' } as Option,
		};
		this.props.stemAnimalGetSyncData();
		this.props.GetLocations();
		this.setRef = this.setRef.bind(this);
	}

	public setRef(s: any) {
		if (s) {
			this.registerMatingRef = s;
		}
	}

	public boarNumberChanged = (boarNumber: string, boarId?: string) => {
		if (boarNumber.length > 0 && boarId !== undefined) {
			this.setState({ boarNumber: boarNumber, boarId: boarId });
		} else {
			this.setState({ boarNumber: boarNumber, boarId: undefined });
		}
	};

	public returnMatedCallback = async (mated: IMated, confirmAlert: boolean) => {
		if (getDateString(mated.date) !== getDateString(this.state.mated.date) && confirmAlert) {
			this.setState({ mated: { ...mated } });
			if (await ShowConfirmAlert(localized('setEntranceDateToMatingDate') + getDateString(mated.date) + '?')) {
				this.setState(prevState => ({
					dateSelected: new Date(mated.date!),
					stemAnimal: { ...prevState.stemAnimal, entranceDate: new Date(mated.date!) },
				}));
			}
		} else {
			this.setState({ mated: { ...mated } });
		}
	};

	public componentDidMount() {
		if (this.props.stemAnimalId) {
			this.findSowById(this.props.stemAnimalId);
		}
	}

	public sectionSelected = (selectedSectionId: string) => {
		const sectionHasStation = this.props.processEquipmentData.some(
			peq =>
				selectedSectionId &&
				peq.equipmentType === Equipment.ESF &&
				peq.sectionId === selectedSectionId &&
				peq.unitCodes &&
				peq.unitCodes.length > 0,
		);

		if (CheckIfSectionUsesPens(selectedSectionId)) {
			if (this.props.pens.findIndex(p => p.sectionId === selectedSectionId && p.id === this.state.penId) <= -1) {
				this.setState({ penId: '', sectionId: selectedSectionId, sectionHasStation });
			} else {
				this.setState({ sectionId: selectedSectionId, sectionHasStation });
			}
		} else {
			let penId = FindHiddenPenOnSection(selectedSectionId, this.state.penId);
			if (penId) {
				this.setState({ penId, sectionId: selectedSectionId, sectionHasStation });
			} else {
				this.setState({ sectionId: selectedSectionId, sectionHasStation });
			}
		}
	};

	public penSelected = (selectedPenId: string) => {
		this.setState({ penId: selectedPenId });
	};

	public animalNumberChanged = (text: string) => {
		this.setState(prevState => ({ stemAnimal: { ...prevState.stemAnimal, animalNumber: text } }));
	};

	public idNumberChanged = (text: string) => {
		this.setState(prevState => ({ stemAnimal: { ...prevState.stemAnimal, idNumber: text } }));
	};

	public idNumberOnBlur = () => {
		const dgLetter = handleDanishGeneticsIdNumber(
			this.props.breedTableItems,
			this.state.stemAnimal,
			this.state.breed,
			this.props.nucleusManagement,
		);
		if (dgLetter) {
			this.setState(prevState => ({
				stemAnimal: { ...prevState.stemAnimal, idNumber: dgLetter + prevState.stemAnimal.idNumber },
			}));
		}
	};

	public entranceLitterNumberTextChanged = (litterNumber: number | undefined) => {
		const isMating = !!(litterNumber && litterNumber > 0);
		if (!this.state.isMating && isMating && this.state.stemAnimal && this.state.stemAnimal.entranceDate) {
			if (this.registerMatingRef) {
				this.registerMatingRef.entranceDateChanged(new Date(this.state.stemAnimal.entranceDate));
			}
		}
		this.setState(
			prevState => ({
				stemAnimal: { ...prevState.stemAnimal, entranceLitterNumber: litterNumber || 0 },
				isMating,
			}),
			() => this.calculateKind(),
		);
	};
	public transponderTextChanged = (transponderNumber: number | undefined) => {
		if (!transponderNumber || transponderNumber <= MAX_TRANSPONDER_NUMBER) {
			this.setState({ transponderNumber: transponderNumber });
		}
	};

	public curveChanged = (feedCurveNumber: number | undefined) => {
		this.setState({ feedCurveNumber: feedCurveNumber });
	};

	public fixedPercentageChanged = (fixedPercentage: number | undefined) => {
		this.setState({ fixedPercentage });
	};

	public setEntranceType = (entranceType: Option) => {
		this.setState({ entranceType });
	};

	public setBreedNumberOption = (breedNumberOption: Option) => {
		this.setState(prevState => ({
			breedNumberOption,
			stemAnimal: { ...prevState.stemAnimal, breedingNumber: breedNumberOption.value },
		}));
	};

	public setAnimalKind = (animalKindOption: Option) => {
		this.setState(prevState => ({
			isEditEntranceKind: animalKindOption,
			stemAnimal: { ...prevState.stemAnimal, entranceKind: animalKindOption.value as AnimalKind },
		}));
	};

	public setBreedTableItemOption = (breedTableItemOption: Option) => {
		this.setState({ breedTableItemOption, breed: breedTableItemOption.label }, () => {
			this.idNumberOnBlur();
		});
	};

	public breedTextChanged = (breed: string | undefined) => {
		this.setState({ breed }, () => {
			this.idNumberOnBlur();
		});
	};

	public birthdayChanged = (birthdate: Date) => {
		this.setState(
			prevState => ({ birthdate, stemAnimal: { ...prevState.stemAnimal, birthDate: birthdate } }),
			() =>
				this.registerMatingRef &&
				this.registerMatingRef.animalNumberChanged(
					this.state.stemAnimal,
					[],
					{} as IPregnancyEvent,
					this.state.mated,
				),
		);
	};

	public render() {
		return this.renderContent();
	}
	public renderContent() {
		return (
			<ViewWeb className="sow-entrance">
				{this.renderButtons()}
				<RegisterMatingComponent
					boarNumber={this.state.boarNumber}
					boarNumberChanged={this.boarNumberChanged}
					returnMatedCallback={this.returnMatedCallback}
					ref={this.setRef}
				/>
				<SkioldFormsWrapper
					formRows={this.getFormRows()}
					containerClassName="sow-entrance-forms-wrapper-container"
				/>

				{this.renderModal()}
			</ViewWeb>
		);
	}

	public onEntranceDateChanged = (newDate: Date) => {
		if (this.registerMatingRef) {
			this.registerMatingRef.entranceDateChanged(new Date(newDate));
		}
		this.setState(
			prevState => ({
				dateSelected: new Date(newDate),
				birthdate:
					prevState.birthdate && newDate < prevState.birthdate ? new Date(newDate) : prevState.birthdate,
				stemAnimal: {
					...prevState.stemAnimal,
					entranceDate: new Date(newDate),
					breedIndexDate: new Date(newDate),
				},
			}),
			this.handleEditDateAfterMating,
		);
	};

	// Handle entrance type and litter, depending on YoungFemale/Gilt, and the first pregnancy
	private handleEditDateAfterMating() {
		if (this.state.stemAnimal.id && this.state.isEdit) {
			let pregs = this.props.pregnancies[this.state.stemAnimal.id];

			if (pregs && pregs.length > 0) {
				let pregMating = sortPregnanciesByDateReversed(pregs)[0];

				// If animal was youngfemale -> Depending on pregDate and entrance date, change litter
				if (
					isDateSameOrBefore(pregMating.date, this.state.stemAnimal.entranceDate!) &&
					this.state.stemAnimal.entranceKind === AnimalKind.YoungFemale
				) {
					this.entranceLitterNumberTextChanged(1);
				}
				// If animal was gilt -> Depending on pregDate and entrance date, change litter
				else if (
					isDateAfter(pregMating.date, this.state.stemAnimal.entranceDate) &&
					this.state.stemAnimal.entranceKind === AnimalKind.Gilt
				) {
					this.entranceLitterNumberTextChanged(0);
				}
			}
		}
	}

	private genderChanged = (gender: Gender) => {
		this.setState({ gender }, () => this.calculateKind());
	};

	private openIdNumberModal = () => {
		this.setState({ modalIsOpen: true, idNumberKind: 'Animal' });
	};

	private getFormRows() {
		let formRows = new Array<FormRow>();

		formRows.push(this.getDateRow());
		formRows.push(this.getEntranceTypeRow());
		formRows.push(this.getGenderRow());
		if (this.state.gender === Gender.Male) {
			formRows.push(this.getAIBoarCheckbox());
		}
		if (this.props.nucleusManagement) {
			formRows.push(this.getBreedNumberDropdown());
		}

		formRows.push(this.getAnimalNumberRow());
		formRows.push(this.getBreedTableItemDropdown());
		if (this.props.nucleusManagement && this.props.generalSettings.showRaceLine) {
			formRows.push(this.getRaceLineRow());
		}

		formRows.push(this.getBirthdateRow());
		formRows.push(this.getIdNumberRow());
		if (this.state.gender === Gender.Male) {
			formRows.push(this.getIndexRow());
			formRows.push(this.getIndexDateRow());
		}
		if (this.props.generalSettings.registerEarNotch || hasNucleusFeatureCEFN(this.props.nucleusManagement)) {
			formRows.push(this.getEarNotchRow());
		}
		if (hasNucleusFeatureCEFN(this.props.nucleusManagement)) {
			formRows.push(this.getDadNumberRow());
			formRows.push(this.getMomNumberRow());
		}

		if (this.state.stemAnimal.entranceKind !== AnimalKind.AIBoar) {
			formRows.push(this.getStableSectionPickerRow());
		}

		if (this.state.sectionId) {
			if (CheckIfSectionUsesPens(this.state.sectionId)) {
				formRows.push(this.getPenPickerRow());
			}
		}
		if (this.state.stemAnimal.entranceKind !== AnimalKind.AIBoar) {
			formRows.push(this.getTransponderRow());
			const sectionHasStation = this.props.processEquipmentData.find(
				peq =>
					this.state.sectionId &&
					peq.equipmentType === Equipment.ESF &&
					peq.sectionId === this.state.sectionId &&
					peq.unitCodes &&
					peq.unitCodes.length > 0,
			);
			if (sectionHasStation && !this.state.isEdit) {
				formRows.push(this.getFeedCurvePickerRow());
				formRows.push(this.getFixedPercentageRow());
			}
		}

		if (this.state.gender === Gender.Female) {
			formRows.push(this.getLittersRow());
			formRows.push(this.getKindRow());

			// Don't show Mating rows, if editing
			if (!this.state.isEdit) {
				formRows.push(this.getMatingRow());
				if (this.state.isMating && this.registerMatingRef) {
					formRows.push(this.getMatingDate());
					formRows.push(this.getMatingBatchNumber());
					formRows.push(this.getMatingGrade());
					formRows.push(this.getMatingBoarNumber());
					formRows.push(this.getSemenType());
					if (this.props.showInitials) {
						formRows.push(this.getInitials());
					}
				}
			}
		}

		return formRows;
	}

	private getFixedPercentageRow = (): FormRow => {
		return {
			name: localized('fixedPercent'),
			component: (
				<SkioldFormIntegerInput
					text={this.state.fixedPercentage}
					className="boar-input"
					onChangeNumber={this.fixedPercentageChanged}
				/>
			),
		};
	};

	private getFeedCurvePickerRow(): FormRow {
		return {
			name: localized('feedCurve'),
			component: (
				<SkioldFeedCurvePicker
					usedInsideForm={true}
					feedCurveNumber={this.state.feedCurveNumber}
					penId={this.state.penId}
					onChange={this.curveChanged}
				/>
			),
		};
	}

	private getDateRow(): FormRow {
		return {
			name: localized('Date'),
			component: (
				<SkioldDatePicker
					onDateChanged={this.onEntranceDateChanged}
					minimumDate={this.state.stemAnimal.youngAnimalEntranceDate}
					selectedDate={this.state.stemAnimal.entranceDate}
					theme={'dark'}
					color={'grey'}
				/>
			),
		};
	}
	private getEntranceTypeRow(): FormRow {
		return {
			name: localized('entranceType'),
			component: (
				<SkioldFormDropdown
					items={this.state.entranceEvents}
					selectedValue={this.state.entranceType}
					onValueChanged={this.setEntranceType}
				/>
			),
		};
	}

	private getGenderRow(): FormRow {
		return {
			name: localized('entranceGender'),
			component: <GenderPicker gender={this.state.gender} onGenderChanged={this.genderChanged} />,
		};
	}

	private getAIBoarCheckbox(): FormRow {
		return {
			name: localized('IsAIBoar'),
			component: (
				<SkioldCheckbox
					containerClassName={'inForm'}
					onClick={this.toggleAIBoar}
					isChecked={this.state.stemAnimal.entranceKind === AnimalKind.AIBoar}
				/>
			),
		};
	}

	private toggleAIBoar = () => {
		if (this.state.stemAnimal.entranceKind !== AnimalKind.AIBoar) {
			this.setState(prevState => ({ stemAnimal: { ...prevState.stemAnimal, entranceKind: AnimalKind.AIBoar } }));
		} else {
			this.calculateKind();
		}
	};

	private getBreedNumberDropdown(): FormRow {
		return {
			name: localized('BreedingNr'),
			component: (
				<SkioldFormDropdown
					items={this.props.breedNumberOptions}
					selectedValue={this.state.breedNumberOption}
					onValueChanged={this.setBreedNumberOption}
				/>
			),
		};
	}

	private getBreedTableItemDropdown(): FormRow {
		return {
			name: localized('Breed'),
			component: (
				<ViewWeb className="position-absolute flex-row align-items-center">
					<SkioldFormInput
						editable={this.state.entranceType.value !== EntranceTypes.FromYoungAnimal} // Disable, because breed should be given
						onChangeText={this.breedTextChanged}
						text={this.state.breed}
						className="z-index-10"
					/>
					<SkioldFormDropdown
						items={this.props.breedTableItemOptions}
						selectedValue={this.state.breedTableItemOption}
						onValueChanged={this.setBreedTableItemOption}
						disabled={this.state.entranceType.value === EntranceTypes.FromYoungAnimal} // Disable, because breed should be given
						containerClassName="boar-dropdown"
						hideResult={true}
					/>
				</ViewWeb>
			),
		};
	}

	private getAnimalNumberRow(): FormRow {
		return {
			name: localized('animalNr'),
			component: (
				<SkioldFormInput
					autoFocus={true}
					ref={this.setAnimalNumInputRef}
					onChangeText={this.animalNumberChanged}
					text={this.state.stemAnimal.animalNumber}
					useAsInterger={!shouldUseLetters(this.state.gender, this.props.generalSettings)}
					maxLength={this.props.esfExists ? 6 : undefined}
				/>
			),
		};
	}

	private getIdNumberRow(): FormRow {
		if (this.state.entranceType.value === 'entranceTypeFromYoungAnimal') {
			return {
				name: localized('idNumber'),
				component: (
					<SkioldTouchableOpacity className="margin-right-eight" onPress={this.openIdNumberModal}>
						<ViewWeb className="display-flex-direction-row">
							<TextWeb className="padding-right-five-font-size-sixteen">
								{this.state.stemAnimal.idNumber || localized('ChooseIdNumber')}
							</TextWeb>
							<ViewWeb className="picker-young-animals-view-style">
								<SkioldImage
									width={18}
									height={18}
									imageData={ForwardArrowGrey}
									className="image-picker-view-style"
								/>
							</ViewWeb>
						</ViewWeb>
					</SkioldTouchableOpacity>
				),
			};
		} else {
			return {
				name: localized('idNumber'),
				component: (
					<SkioldFormInput
						onChangeText={this.idNumberChanged}
						text={this.state.stemAnimal.idNumber}
						onBlur={this.idNumberOnBlur}
					/>
				),
			};
		}
	}

	private getEarNotchRow(): FormRow {
		return {
			name: localized('EarNotch'),
			component: <SkioldFormInput text={this.state.stemAnimal.earNotch} onChangeText={this.earNotchChanged} />,
		};
	}

	private earNotchChanged = (earNotch: string) => {
		this.setState(prevState => ({ stemAnimal: { ...prevState.stemAnimal, earNotch } }));
	};

	private getDadNumberRow(): FormRow {
		return {
			name: localized('dadNumber'),
			component: (
				<SkioldFormInput text={this.state.stemAnimal.dadAnimalNumber} onChangeText={this.dadNumberChanged} />
			),
		};
	}

	private dadNumberChanged = (text: string) => {
		const dad = findAnimal(text, Gender.Male);
		if (dad) {
			this.setDad(dad);
		} else {
			this.setState(prevState => ({ stemAnimal: { ...prevState.stemAnimal, dadAnimalNumber: text } }));
		}
	};

	private setDad = (animal: IStemAnimal | undefined) => {
		if (animal === undefined) {
			return;
		}

		this.setState(prevState => ({
			stemAnimal: {
				...prevState.stemAnimal,
				dadIdNumber: animal.idNumber,
				dadRace: animal.race,
				dadMongoId: animal.id,
				dadAnimalNumber: animal.animalNumber,
				dadBreedLetter: animal.breedLetter,
			},
		}));
	};

	private getMomNumberRow(): FormRow {
		return {
			name: localized('momNumber'),
			component: (
				<SkioldFormInput text={this.state.stemAnimal.momAnimalNumber} onChangeText={this.momNumberChanged} />
			),
		};
	}

	private momNumberChanged = (text: string) => {
		const mom = findAnimal(text, Gender.Female);
		if (mom) {
			this.setMom(mom);
		} else {
			this.setState(prevState => ({ stemAnimal: { ...prevState.stemAnimal, momAnimalNumber: text } }));
		}
	};

	private breedRaceLineChanged = (raceLine: string) => {
		this.setState(prevState => ({ stemAnimal: { ...prevState.stemAnimal, raceLine: raceLine } }));
	};

	private setMom = (animal: IStemAnimal | undefined) => {
		if (animal === undefined) {
			return;
		}

		this.setState(prevState => ({
			stemAnimal: {
				...prevState.stemAnimal,
				momIdNumber: animal.idNumber,
				momRace: animal.race,
				momMongoId: animal.id,
				momAnimalNumber: animal.animalNumber,
				momBreedLetter: animal.breedLetter,
			},
		}));
	};

	private getStableSectionPickerRow(): FormRow {
		return {
			name: localized('StableSection'),
			component: (
				<SkioldStableSectionPicker
					usedInsideForm={true}
					AnimalType={AnimalType.Sow}
					onStableSectionSelected={this.sectionSelected}
					sectionId={this.state.sectionId}
					penId={this.state.penId}
					isPigProduction={true}
				/>
			),
		};
	}
	private getPenPickerRow(): FormRow {
		return {
			name: localized('Pen'),
			component: (
				<SkioldFormPenPicker
					usedInsideForm={true}
					onPenSelected={this.penSelected}
					sectionId={this.state.sectionId}
					penId={this.state.penId}
				/>
			),
		};
	}

	private getTransponderRow(): FormRow {
		return {
			name: localized('transponder'),
			component: (
				<SkioldFormIntegerInput
					onChangeNumber={this.transponderTextChanged}
					text={this.state.transponderNumber}
				/>
			),
		};
	}

	private getLittersRow(): FormRow {
		return {
			name: localized('Litters'),
			component: (
				<SkioldFormIntegerInput
					onChangeNumber={this.entranceLitterNumberTextChanged}
					text={this.state.stemAnimal.entranceLitterNumber}
					editable={this.state.canEditLitter}
				/>
			),
		};
	}

	private getMatingRow(): FormRow {
		return {
			name: localized('entranceMating'),
			component: (
				<SkioldCheckbox
					containerClassName={'inForm'}
					onClick={this.toggleIsMating}
					isChecked={this.state.isMating}
				/>
			),
		};
	}

	private getBreedRow(): FormRow {
		return {
			name: localized('Breed'),
			component: (
				<SkioldFormInput
					editable={this.state.entranceType.value !== EntranceTypes.FromYoungAnimal} // Disable, because breed should be given
					onChangeText={this.breedTextChanged}
					text={this.state.breed}
				/>
			),
		};
	}

	private getRaceLineRow(): FormRow {
		return {
			name: localized('RaceLine'),
			component: (
				<SkioldFormInput onChangeText={this.breedRaceLineChanged} text={this.state.stemAnimal.raceLine} />
			),
		};
	}

	private getBirthdateRow(): FormRow {
		return {
			name: localized('birthdate'),
			component: (
				<SkioldDatePicker
					onDateChanged={this.birthdayChanged}
					selectedDate={this.state.birthdate}
					theme={'dark'}
					color={'grey'}
					maxDate={this.state.stemAnimal.entranceDate}
				/>
			),
		};
	}

	private getMatingDate(): FormRow {
		return this.registerMatingRef!.getDateRow(this.state.stemAnimal.entranceDate);
	}

	private getMatingBatchNumber(): FormRow {
		return this.registerMatingRef!.getBatchNumberRow();
	}

	private getMatingGrade(): FormRow {
		return this.registerMatingRef!.getGradePickerRow();
	}

	private getMatingBoarNumber(): FormRow {
		if (this.props.nucleusManagement) {
			return this.registerMatingRef!.getBoarInputRowNucleus();
		}
		return this.registerMatingRef!.getBoarInputRow();
	}

	private getSemenType(): FormRow {
		return this.registerMatingRef!.getSemenTypeRow();
	}

	private getInitials(): FormRow {
		return this.registerMatingRef!.getInitialsRow();
	}

	private toggleIsMating = () => {
		const toggle = !this.state.isMating;
		let litter = 0;
		if (toggle) {
			litter = 1;
		}
		if (!this.state.isMating && toggle && this.state.stemAnimal && this.state.stemAnimal.entranceDate) {
			if (this.registerMatingRef) {
				this.registerMatingRef.entranceDateChanged(new Date(this.state.stemAnimal.entranceDate));
				this.registerMatingRef.animalNumberChanged(
					this.state.stemAnimal,
					[],
					{} as IPregnancyEvent,
					this.state.mated,
				);
			}
		}
		this.setState(
			prevState => ({ stemAnimal: { ...prevState.stemAnimal, entranceLitterNumber: litter }, isMating: toggle }),
			() => this.calculateKind(),
		);
	};

	private getKindRow(): FormRow {
		return {
			name: localized('kind'),
			component: this.state.isEdit ? (
				<SkioldFormDropdown
					usedInTable={true}
					items={getAnimalKindOptions()}
					selectedValue={this.state.isEditEntranceKind}
					onValueChanged={this.setAnimalKind}
				/>
			) : (
				<SkioldFormTextField>{this.state.kind}</SkioldFormTextField>
			),
		};
	}
	private onBoarTypeChange = (option: Option | undefined) => {
		if (option) {
			this.setState(prevState => ({
				stemAnimal: { ...prevState.stemAnimal, entranceKind: option.value as AnimalKind },
				selectedBoarType: option,
			}));
		}
	};

	private getBoarTypeRow(): FormRow {
		return {
			name: localized('Type'),
			component: (
				<SkioldFormDropdown
					items={this.state.boarTypeOptions}
					selectedValue={this.state.selectedBoarType}
					onValueChanged={this.onBoarTypeChange}
				/>
			),
		};
	}

	private onIndexChange = (index: number | undefined) => {
		this.setState(prevState => ({
			stemAnimal: { ...prevState.stemAnimal, breedIndex: index },
		}));
	};

	private getIndexRow(): FormRow {
		return {
			name: localized('index'),
			component: (
				<SkioldFormIntegerInput onChangeNumber={this.onIndexChange} text={this.state.stemAnimal.breedIndex} />
			),
		};
	}

	private onIndexDateChange = (indexDate: Date) => {
		this.setState(prevState => ({
			stemAnimal: { ...prevState.stemAnimal, breedIndexDate: indexDate },
		}));
	};

	private getIndexDateRow(): FormRow {
		return {
			name: localized('indexDate'),
			component: (
				<SkioldDatePicker
					onDateChanged={this.onIndexDateChange}
					selectedDate={this.state.stemAnimal.breedIndexDate}
					theme={'dark'}
					color={'grey'}
				/>
			),
		};
	}

	private renderButtons() {
		return (
			<ViewWeb
				className={
					this.props.stemAnimalId ? 'button-view-style-upper-corner-edit' : 'button-view-style-upper-corner'
				}
			>
				{this.props.closeEditModal && (
					<SkioldButton title={localized('Close')} onPress={this.editDone} theme="grey" />
				)}
				{this.props.stemAnimalId && hasDanishNucleusFeatureLicence(this.props.nucleusManagement) && (
					<SkioldButton title={localized('resend')} onPress={this.resendEntrance} theme="grey" />
				)}

				<SkioldButton title={localized('Save')} onPress={this.save} />
			</ViewWeb>
		);
	}

	private editDone = () => {
		if (this.props.closeEditModal) {
			this.props.closeEditModal();
		}
	};

	private resendEntrance = () => {
		ResendStemAnimal(this.state.stemAnimal);
	};

	private renderModal() {
		let youngAnimals: IStemAnimal[] = [];
		let parents: IStemAnimal[] = [];
		if (this.state.idNumberKind === 'Animal') {
			if (this.state.entranceType.value === EntranceTypes.FromYoungAnimal && this.state.gender === Gender.Male) {
				youngAnimals = getYoungMales();
			} else {
				youngAnimals = getYoungAnimals();
			}
		} else if (this.state.idNumberKind === 'Dad') {
			parents = getBoars();
		} else if (this.state.idNumberKind === 'Mom') {
			parents = getSows();
		}

		return (
			<SkioldModal isOpen={this.state.modalIsOpen} close={() => this.setState({ modalIsOpen: false })}>
				{this.state.idNumberKind === 'Animal' ? (
					<SearchYoungAnimals close={this.setYoungAnimal} youngAnimals={youngAnimals} />
				) : null}
			</SkioldModal>
		);
	}

	private setYoungAnimal = (youngAnimal: IStemAnimal | undefined) => {
		if (youngAnimal) {
			let breedTableItemOption = this.props.breedTableItemOptions.find(
				event => event.label === youngAnimal!.race,
			);
			if (!breedTableItemOption) {
				breedTableItemOption = { label: ' ', value: '' };
			}
			const newYoungAnimal = StemAnimal.fromJS({});
			const newStemAnimal = StemAnimal.fromJS({});
			if (this.state.isEdit && youngAnimal && youngAnimal.idNumber) {
				//Manually transfer each prop, not related to nucleus management
				const oldanimalNumber = this.state.stemAnimal.animalNumber;
				const newanimalNumber = youngAnimal.animalNumber;

				const oldentrancePenId = this.state.stemAnimal.entrancePenId;
				const newentrancePenId = youngAnimal.entrancePenId;

				const oldtransponder = this.state.stemAnimal.transponder;
				const newtransponder = youngAnimal.transponder;

				const oldentranceLitterNumber = this.state.stemAnimal.entranceLitterNumber;
				const newentranceLitterNumber = youngAnimal.entranceLitterNumber;

				const oldentranceKind = this.state.stemAnimal.entranceKind;
				const newentranceKind = youngAnimal.entranceKind;

				const oldteats = this.state.stemAnimal.teats;
				const newteats = youngAnimal.teats;

				const oldcomments = this.state.stemAnimal.comments;
				const newcomments = youngAnimal.comments;

				const oldentranceDate = this.state.stemAnimal.entranceDate;
				const newentranceDate = youngAnimal.entranceDate;

				const oldId = this.state.stemAnimal.id;
				const newId = youngAnimal.id;

				const oldBreedNumber = this.state.stemAnimal.breedingNumber;
				const newBreedNumber = youngAnimal.breedingNumber;

				newYoungAnimal.init(this.state.stemAnimal);
				newStemAnimal.init(youngAnimal);

				this.setState({
					stemAnimal: {
						...newStemAnimal,
						animalNumber: oldanimalNumber,
						entrancePenId: oldentrancePenId,
						transponder: oldtransponder,
						entranceLitterNumber: oldentranceLitterNumber,
						entranceKind: oldentranceKind,
						teats: oldteats,
						comments: oldcomments,
						entranceDate: oldentranceDate,
						id: oldId,
						breedingNumber: oldBreedNumber,
					},
					youngAnimalToEdit: {
						...newYoungAnimal,
						animalNumber: newanimalNumber,
						entrancePenId: newentrancePenId,
						transponder: newtransponder,
						entranceLitterNumber: newentranceLitterNumber,
						entranceKind: newentranceKind,
						teats: newteats,
						comments: newcomments,
						entranceDate: newentranceDate,
						id: newId,
						isDeleted: !this.state.stemAnimal.idNumber,
						breedingNumber: newBreedNumber,
					},
					birthdate: newStemAnimal.birthDate,
					breed: newStemAnimal.race,
					breedTableItemOption: breedTableItemOption,
				});
			} else {
				newYoungAnimal.init(youngAnimal);
				newYoungAnimal.animalNumber = this.state.stemAnimal.animalNumber;
				newYoungAnimal.entranceType = this.state.stemAnimal.entranceType;
				newYoungAnimal.entranceDate = this.state.dateSelected;
				this.setState(
					{
						modalIsOpen: false,
						stemAnimal: newYoungAnimal,
						birthdate: newYoungAnimal.birthDate,
						breed: newYoungAnimal.race,
						breedTableItemOption: breedTableItemOption,
						breedNumberOption:
							this.props.breedNumberOptions.find(o => o.value === youngAnimal.breedingNumber) ??
							this.props.breedNumberOptions[0],
					},
					() => {
						this.calculateKind();
					},
				);
			}
		}

		this.setState({
			modalIsOpen: false,
		});
	};

	private setAnimalNumInputRef = (ref: SkioldFormInput) => {
		this.animalNumInputRef = ref;
	};

	private save = async () => {
		// If editing a sow (at entrance) dont allow entrance litter to be below 2
		if (
			this.state.isEdit &&
			this.state.canEditLitter &&
			this.state.stemAnimal.entranceLitterNumber !== undefined &&
			this.state.stemAnimal.entranceLitterNumber <= 1
		) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_ENTRACE_SOW_EDIT_LITTER));
			return;
		}
		const sow = { ...this.state.stemAnimal };
		sow.entrancePenId = this.state.penId;
		sow.entranceType = this.state.entranceType.value;
		sow.breedingNumber = this.state.breedNumberOption && this.state.breedNumberOption.value;
		sow.gender = this.state.gender;
		sow.transponder = this.state.transponderNumber ? this.state.transponderNumber.toString() : undefined;
		sow.race = this.state.breed;
		sow.birthDate = this.state.birthdate;
		if (sow.id === undefined || sow.id === '') {
			sow.id = new ObjectID().toHexString();
		}

		// Handle litter if pregDate is Same or After entrance date
		if (
			!this.state.isEdit &&
			this.state.isMating &&
			sow.entranceLitterNumber &&
			sow.entranceLitterNumber > 0 &&
			isDateSameOrAfter(this.state.mated.date, this.state.stemAnimal.entranceDate)
		) {
			sow.entranceLitterNumber = sow.entranceLitterNumber - 1;
		}

		// Editing: Handle litter if pregDate is Same or After entrance date
		if (sow.entranceLitterNumber && sow.entranceLitterNumber > 0 && this.state.isEdit) {
			let pregs = this.props.pregnancies[sow.id];

			if (pregs && pregs.length > 0) {
				let pregMating = sortPregnanciesByDateReversed(pregs)[0];
				if (isDateSameOrAfter(pregMating.date, this.state.stemAnimal.entranceDate!)) {
					sow.entranceLitterNumber = sow.entranceLitterNumber - 1;
				}
			}
		}

		let validateStemAnimalEntrance = await ValidateStemAnimalEntrance(
			sow,
			this.state.sectionId,
			this.state.gender,
			this.props.esfExists,
			this.props.pens,
			showAlert,
			ShowConfirmAlert,
			this.state.isEdit,
			!!this.state.youngAnimalToEdit,
		);
		if (!validateStemAnimalEntrance.validated) {
			if (validateStemAnimalEntrance.showYoungAnimalModal) {
				this.setState({ modalIsOpen: true });
			}
			return;
		}
		const nucleusValidator = new NucleusValidator(this.props.nucleusManagement, showAlert);
		if (!nucleusValidator.validateEntrance(sow)) {
			return;
		}

		// Save FeedStatusUpdate separately to handle offline registration
		if (
			this.state.sectionHasStation &&
			(this.state.feedCurveNumber !== undefined || !isNullOrUndefined(this.state.fixedPercentage))
		) {
			this.props.saveEntranceFeedingStatus(
				new FeedingStatusUpdate({
					animalNumber: sow.animalNumber,
					feedCurveNumber: this.state.feedCurveNumber,
					fixedDeviation: this.state.fixedPercentage,
					id: sow.id,
				}),
			);
		}

		if (!this.state.isEdit && this.state.isMating) {
			if (!(await this.handleMated(sow))) {
				return;
			}
		} else {
			const feedCurveNumber =
				this.state.feedCurveNumber !== undefined ? this.state.feedCurveNumber.toString() : undefined;
			const fixedPercentage = this.state.sectionHasStation ? this.state.fixedPercentage : undefined;
			if (this.state.isEdit && this.state.youngAnimalToEdit) {
				this.props.updateYoungAnimal(this.state.youngAnimalToEdit);
			}
			await this.props.saveSow(sow, feedCurveNumber, fixedPercentage, !!this.state.youngAnimalToEdit);
		}

		if (this.props.shouldCloseOnSave) {
			this.editDone();
		}

		this.resetComponent();
	};

	private async handleMated(sow: IStemAnimal): Promise<boolean> {
		const mated = { ...this.state.mated };
		mated.id = new ObjectID().toHexString();
		mated.pregnancyId = new ObjectID().toHexString();
		mated.siteId = sow.siteId;
		mated.stemAnimalId = sow.id;

		const matingErrorMessage = await createAndEditValidation(
			this.props.validationSetup,
			this.props.generalSettings,
			mated,
			undefined,
			[],
			sow,
			this.registerMatingRef!.state.matingBatchNumber,
		);

		if (matingErrorMessage !== '') {
			if (matingErrorMessage !== 'ignore') {
				showAlert(localizedDynamic(matingErrorMessage));
			}
			return false;
		}

		if (!this.registerMatingRef?.validateNucleus()) {
			return false;
		}

		//Create boar if it doesn't exist
		if (this.registerMatingRef!.state.boarNumber && !this.state.boarId) {
			const newBoarId = new ObjectID().toHexString();
			this.setState({ boarId: newBoarId });
			const newBoar = StemAnimal.fromJS({
				animalNumber: this.registerMatingRef!.state.boarNumber,
				id: newBoarId,
				siteId: mated.siteId,
				gender: Gender.Male,
				entranceKind: AnimalKind.Boar,
			} as IStemAnimal);
			mated.boarId = newBoarId;
			this.props.saveSow(newBoar);
		} else {
			mated.boarId = this.state.boarId;
		}
		const combined = {
			stemAnimal: StemAnimal.fromJS(sow),
			mated: Mated.fromJS(mated),
		} as CombinedEntranceAndMated;

		const feedCurveNumber =
			this.state.feedCurveNumber !== undefined ? this.state.feedCurveNumber.toString() : undefined;
		const fixedPercentage = this.state.sectionHasStation ? this.state.fixedPercentage : undefined;
		await this.props.saveSowWithMated(combined, feedCurveNumber, fixedPercentage);

		return true;
	}

	// Called after successful save to remove one-time values
	private async resetComponent() {
		if (this.state.isEdit && this.props.closeEditModal) {
			this.props.closeEditModal();
			return;
		}

		const newSow = deepCopy(this.state.stemAnimal);
		newSow.animalNumber = '';
		newSow.id = '';
		newSow.idNumber = '';
		newSow.transponder = '';
		newSow.race = '';
		newSow.createdBy = undefined;
		newSow.createdOn = undefined;
		newSow.entranceLitterNumber = 0;
		newSow.entranceKind = newSow.entranceKind === AnimalKind.Boar ? newSow.entranceKind : AnimalKind.YoungFemale;
		if (this.state.entranceType.value === 'entranceTypeFromYoungAnimal') {
			newSow.birthDate = undefined;
			newSow.race = undefined;
		}

		this.setState({
			stemAnimal: newSow,
			isMating: false,
			transponderNumber: undefined,
			birthdate:
				this.state.entranceType.value === 'entranceTypeFromYoungAnimal' ? undefined : this.state.birthdate,
			breed: this.state.entranceType.value === 'entranceTypeFromYoungAnimal' ? undefined : this.state.breed,
			kind: localized('youngFemale'),

			youngAnimalToEdit: undefined,
		});

		if (this.scrollerRef) {
			await this.scrollerRef.scrollToTop();
		}

		this.focusAnimalNumber();
	}

	private focusAnimalNumber = () => {
		if (this.animalNumInputRef) {
			this.animalNumInputRef.focus();
		}
	};

	private calculateKind() {
		if (this.state.gender === Gender.Male) {
			this.setState({ kind: localized('male') });
			this.setState(prevState => ({ stemAnimal: { ...prevState.stemAnimal, entranceKind: AnimalKind.Boar } }));
			return;
		}

		if (!this.state.stemAnimal.entranceLitterNumber || this.state.stemAnimal.entranceLitterNumber === 0) {
			this.setState({ kind: localized('youngFemale') });
			this.setState(prevState => ({
				stemAnimal: {
					...prevState.stemAnimal,
					entranceKind: this.state.isEdit ? this.state.stemAnimal.entranceKind : AnimalKind.YoungFemale,
				},
			}));
			return;
		}
		if (this.state.stemAnimal.entranceLitterNumber === 1) {
			this.setState({ kind: localized('gilt') });
			this.setState(prevState => ({ stemAnimal: { ...prevState.stemAnimal, entranceKind: AnimalKind.Gilt } }));
			return;
		}
		if (this.state.stemAnimal.entranceLitterNumber >= 1) {
			this.setState({ kind: localized('Sow') });
			this.setState(prevState => ({ stemAnimal: { ...prevState.stemAnimal, entranceKind: AnimalKind.Sow } }));
			return;
		}
	}
	private findSowById(id: string) {
		let foundAnimal = { ...findAnimalById(id) } as IStemAnimal;

		if (foundAnimal) {
			let pregs = this.props.pregnancies[id];
			// Handle entrance litter, depending on entrance date and preg date
			if (pregs && pregs.length > 0 && foundAnimal.entranceKind !== AnimalKind.YoungFemale) {
				let pregMating = sortPregnanciesByDateReversed(pregs)[0];
				if (isDateSameOrAfter(pregMating.date, foundAnimal.entranceDate)) {
					foundAnimal.entranceLitterNumber = foundAnimal!.entranceLitterNumber! + 1;
				}
			}

			let entranceType = this.state.entranceEvents.find(event => event.value === foundAnimal!.entranceType);
			let breedNumberOption = this.props.breedNumberOptions.find(
				event => event.value === foundAnimal!.breedingNumber,
			);
			let isEditEntranceKind = getAnimalKindOptions().find(event => event.value === foundAnimal!.entranceKind);
			let breedTableItemOption = this.props.breedTableItemOptions.find(
				event => event.label === foundAnimal!.race,
			);
			let stateToSet = {
				stemAnimal: foundAnimal!,
				penId: foundAnimal.entrancePenId!,
				isEdit: true,
				transponderNumber: foundAnimal.transponder && parseInt(foundAnimal.transponder),
				kind: foundAnimal.entranceKind,
				canEditLitter: foundAnimal.entranceKind === AnimalKind.Sow,
				gender: foundAnimal.gender,
				breed: foundAnimal.race,
				birthdate: foundAnimal.birthDate,
				breedNumberOption,
				breedTableItemOption,
				isEditEntranceKind,
			} as SowEntranceState;
			if (entranceType) {
				stateToSet = { ...stateToSet, entranceType };
			}

			this.setState(stateToSet, this.calculateKind);
		}
	}

	private generateEntranceType() {
		let entranceTypes = [
			this.DefaultEntranceTypeOption,
			{
				label: localized(EntranceTypes.FromYoungFemale),
				value: EntranceTypes.FromYoungFemale,
			},
		];
		if (anyYoungAnimals()) {
			entranceTypes.push({
				label: localized('entranceTypeFromYoungAnimal'),
				value: 'entranceTypeFromYoungAnimal',
			});
		}

		return entranceTypes;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SowEntrance);
