import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GrowthPigEventType, GrowthPigsEvent } from 'shared/api/api';
import { selectSiteId } from 'shared/state/ducks/site/selectors';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import PageContainer from 'web/view/components/page-container/page-container';
import { Heading } from 'web/view/components/utils/heading';
import { SkioldFetch } from 'web/view/components/skiold-components/skiold-fetch/skiold-fetch';
import { memoizeHashmapAllLocation } from 'shared/helpers/memoize-getters/memoize-getters';
import { SowListConstants } from 'web/view/components/stem-animal/animal-lists/table-constants';
import SkioldTableGrid from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { getDateString } from 'shared/helpers/date-helpers';
import { ViewWeb } from 'web/view/components/utils/web-view';
import moment from 'moment';
import { SkioldTouchableOpacity } from 'web/view/components/skiold-components/skiold-touchable-opacity';
import { SkioldImage } from 'web/view/components/utils/svg/skiold-image';
import DeleteIcon from 'shared/assets/src-assets/png/delete_icon.png';
import PenIcon from 'shared/assets/src-assets/png/pen_icon.png';
import {
	GetGrowthPigEventRegulations,
	removeGrowthPigsEventByIds,
} from 'shared/state/ducks/growth-pig-events/operations';
import { SkioldModal } from 'web/view/components/skiold-components/skiold-modal/skiold-modal';
import { EditGrowthPigEventRegulation } from './edit-growth-pig-event-regulation';
import { RegulationTableRow } from 'shared/helpers/growth-pigs-helper/growth-pig-weight-regulation-helper';

interface PropsFromParent {}

const generateTableData = (hashmapLocations: any, data: GrowthPigsEvent[]) => {
	let rows: RegulationTableRow[] = [];

	data.forEach(item => {
		if (item.growthPigEventType === GrowthPigEventType.Regulation) {
			rows.push({
				toBuildingName:
					item.toBuildingId &&
					hashmapLocations[item.toBuildingId] &&
					hashmapLocations[item.toBuildingId].name,
				toSectionName:
					item.toSectionId && hashmapLocations[item.toSectionId] && hashmapLocations[item.toSectionId].name,
				toPenName: item.toPenId && hashmapLocations[item.toPenId] && hashmapLocations[item.toPenId].name,
				date: item.date && getDateString(item.date),
				amount: item.amount,
				avgWeight: item.amount && item.totalWeight ? Math.round((item.totalWeight / item.amount) * 10) / 10 : 0,
				productionType: item.toProductionType && localized(item.toProductionType),
				bundleId: item.bundleIdentifier,
				growthPigId: item.id,
				realDate: item.date,
			} as RegulationTableRow);
		}
	});

	return rows;
};

export const GrowthPigRegulationsOverview: FC<PropsFromParent> = React.memo(() => {
	const dispatch = useDispatch();

	// Get data
	const { growthPigEvents } = useSelector((state: WebAppState) => state.growthPigEvents);
	const locations = useSelector((state: WebAppState) =>
		memoizeHashmapAllLocation(state.locations.buildings, state.locations.sections, state.locations.pens),
	);
	const initFromDate = moment().subtract(7, 'days').toDate().withoutTime();
	const initToDate = new Date().endOfDayTime();
	const siteId = useSelector(selectSiteId);
	const [data, setData] = useState<RegulationTableRow[]>([]);
	const [selectedGrowthPigId, setSelectedGrowthPigId] = useState<RegulationTableRow | undefined>(undefined);

	useEffect(() => {
		fetchRegulations(initFromDate, initToDate);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const rowData = generateTableData(locations, growthPigEvents);
		setData(rowData);
	}, [growthPigEvents, locations]);

	const fetchRegulations = (fromDate, toDate) => {
		GetGrowthPigEventRegulations(fromDate, toDate)(dispatch);
	};

	const openModal = (event: RegulationTableRow) => {
		setSelectedGrowthPigId(event);
	};

	const closeModal = () => {
		setSelectedGrowthPigId(undefined);
	};

	const getEditCell = (event: RegulationTableRow) => {
		return (
			<SkioldTouchableOpacity onPress={openModal} itemFromParent={event}>
				<SkioldImage
					width={SowListConstants.iconSVGWidth}
					height={SowListConstants.iconSVGWidth}
					imageData={PenIcon}
				/>
			</SkioldTouchableOpacity>
		);
	};

	const deleteRegulation = (event: RegulationTableRow) => {
		if (event.growthPigId) {
			removeGrowthPigsEventByIds([event.growthPigId])(dispatch);

			const rowData = data.filter(e => e.growthPigId !== event.growthPigId);
			setData(rowData);
		}
	};

	const getRemoveCell = (event: RegulationTableRow) => {
		return (
			<SkioldTouchableOpacity onPress={deleteRegulation} itemFromParent={event}>
				<SkioldImage
					width={SowListConstants.iconSVGWidth}
					height={SowListConstants.iconSVGWidth}
					imageData={DeleteIcon}
				/>
			</SkioldTouchableOpacity>
		);
	};

	const generateColumns = [
		{
			name: 'edit',
			title: ' ',
			sortable: false,
			filterable: false,
			isFixedLeft: true,
			getCellValue: getEditCell,
		},
		{
			name: 'toBuildingName',
			title: localized('building'),
		},
		{
			name: 'toSectionName',
			title: localized('section'),
		},
		{
			name: 'toPenName',
			title: localized('pen'),
		},
		{
			name: 'date',
			title: localized('Date'),
		},
		{
			name: 'amount',
			title: localized('amount'),
		},
		{
			name: 'avgWeight',
			title: localized('avgWeight'),
		},
		{
			name: 'productionType',
			title: localized('productionType'),
		},
		{
			name: 'remove',
			title: ' ',
			sortable: false,
			filterable: false,
			isFixedLeft: true,
			getCellValue: getRemoveCell,
		},
	];

	const generateColumnExtes = [
		{
			columnName: 'edit',
			width: SowListConstants.iconWidth,
		},
		{
			columnName: 'toBuildingName',
			width: SowListConstants.dateWidth,
		},
		{
			columnName: 'toSectionName',
			width: SowListConstants.countWidth,
		},
		{
			columnName: 'toPenName',
			width: SowListConstants.weightWidth,
		},
		{
			columnName: 'date',
			width: SowListConstants.avgWeightWidth,
		},
		{
			columnName: 'amount',
			width: SowListConstants.avgWeightWidth,
		},
		{
			columnName: 'avgWeight',
			width: SowListConstants.locationWidth,
		},
		{
			columnName: 'productionType',
			width: SowListConstants.locationWidth,
		},
		{
			columnName: 'remove',
			width: SowListConstants.iconWidth,
		},
	];

	return (
		<PageContainer className="dashboardOverview animals">
			<Heading text={localized('Regulations')} />
			<SkioldFetch
				fromDate={initFromDate}
				toDate={initToDate}
				fetchData={fetchRegulations}
				isControlled={false}
			/>

			<ViewWeb className="align-items-center">
				<SkioldTableGrid
					ignoreSetCount={true}
					ColumnExtensions={generateColumnExtes}
					columns={generateColumns}
					data={data}
					tableKey={'GrowthPigRegulationsTable'}
				/>
			</ViewWeb>
			<SkioldModal
				shouldCloseOnOverlayClick={true}
				padding="0"
				isOpen={selectedGrowthPigId ? true : false}
				close={closeModal}
				justify-content="flex-end"
				max-width="calc(100% - 220px)"
			>
				<EditGrowthPigEventRegulation closeModal={closeModal} regulationRow={selectedGrowthPigId} />
			</SkioldModal>
		</PageContainer>
	);
});
