import { Action } from 'redux';
import { FeedingStatusUpdate, Gender, IFeedingStatusUpdate, IStemAnimal, StemAnimal } from 'shared/api/api';
import {
	mergeArrays,
	mergeArraysNoUnique,
	removeMultipleValuesFromArray,
	removeValueFromArray,
	upsertMultipleValuesInArray,
	upsertValueInArray,
} from 'shared/helpers/reducer-helpers';
import { SyncableInitialState } from 'shared/state/models/syncable';
import { siteChange } from '../profile/actions';
import { isType } from 'typescript-fsa';
import * as actions from './actions';
import { getDeparturedSows, getSyncData, saveEntranceFeedingStatus, saveSow, saveStemAnimalNoUpdate } from './actions';
import { IStemAnimalDepartured, StemAnimalState } from './types';
import { DepartureTypes } from 'shared/state/models/departure-types';
import { Monitoring } from 'shared/helpers/monitoring-service';
import { NativeAppState } from 'native/state/store.native';
import { SharedAppState } from 'shared/state/store.shared';
import { WebAppState } from 'web/state/store.web';
import { memoizeIdNumbers } from 'shared/helpers/memoize-getters/memoize-getters';

export const initialStemAnimalState: StemAnimalState = {
	...SyncableInitialState,
	sowsCount: 0,
	checkedCount: 0,
	departuredAnimals: [],
	entranceEsfFeedingStatus: [],
	updatesYoungAninal: [],
	saveSyncYoungAnimal: false,
	allIdNumbers: '',
	youngFemaleReportData: undefined,
};

export const propertiesToIgnore = (prop: string) => {
	if (prop === 'modifiedBy') {
		return true;
	}
	if (prop === 'modifiedOn') {
		return true;
	}

	return false;
};

const sowReducer = (state: StemAnimalState = initialStemAnimalState, action: Action): StemAnimalState => {
	if (isType(action, getSyncData.started)) {
		state = { ...state, syncInProgress: true };
	}

	if (isType(action, getSyncData.done)) {
		if (action.payload.params.siteId === '5f8453b80b833f242c2e65f8') {
			Monitoring.logTrace(
				`Number of stemAnimal fetched: ${action.payload.result && action.payload.result.datas ? action.payload.result.datas.length : 0
				}`,
				{
					activeSiteId: action.payload.params.activeSiteId ? action.payload.params.activeSiteId : '',
					siteId: action.payload.params.siteId ? action.payload.params.siteId : '',
				},
			);
			Monitoring.logTrace(`Number of stemAnimal fetched: ${state.entities ? state.entities.length : 0}`, {
				activeSiteId: action.payload.params.activeSiteId ? action.payload.params.activeSiteId : '',
				siteId: action.payload.params.siteId ? action.payload.params.siteId : '',
			});
		}
		if (action.payload.params.siteId === action.payload.params.activeSiteId) {
			if (action.payload.result.datas!.length) {
				let data = mergeArrays(state.entities, action.payload.result.datas!);
				let departuredSows: Array<IStemAnimal | IStemAnimalDepartured> = [];
				let animalNumbersUsed: string[] = [];
				let inactiveAnimals = data.filter(
					a =>
						!a.isDeleted &&
						a.departureDate &&
						a.departureType !== DepartureTypes.departureTypeShouldDeparture,
				);
				let activeAnimals = data.filter(
					a =>
						!a.isDeleted &&
						(!a.departureDate ||
							a.departureType === DepartureTypes.departureTypeShouldDeparture ||
							a.departureDate > new Date()),
				);

				activeAnimals.forEach(stem => {
					animalNumbersUsed.push(stem.animalNumber!);
				});
				inactiveAnimals.forEach(inactiveStemAnimal => {
					if (!animalNumbersUsed.includes(inactiveStemAnimal.animalNumber!)) {
						if (inactiveStemAnimal.gender === Gender.Male) {
							departuredSows.push({
								isDeleted: inactiveStemAnimal.isDeleted,
								departureDate: inactiveStemAnimal.departureDate,
								animalNumber: inactiveStemAnimal.animalNumber,
								race: inactiveStemAnimal.race,
								id: inactiveStemAnimal.id,
								gender: inactiveStemAnimal.gender,
								idNumber: inactiveStemAnimal.idNumber,
							} as IStemAnimalDepartured);
						} else {
							departuredSows.push({
								isDeleted: inactiveStemAnimal.isDeleted,
								departureDate: inactiveStemAnimal.departureDate,
								animalNumber: inactiveStemAnimal.animalNumber,
							} as IStemAnimalDepartured);
						}

						animalNumbersUsed.push(inactiveStemAnimal.animalNumber!);
					}
				});
				const mergeDepartured = mergeArraysNoUnique(
					state.departuredAnimals,
					departuredSows,
					(t: IStemAnimal | IStemAnimalDepartured) => t.animalNumber!,
					() => false,
				);
				state = {
					...state,
					entities: activeAnimals.map(e =>
						Object.entries(e).reduce((a, [k, v]) => {
							return propertiesToIgnore(k) || v == null ? a : ((a[k] = v), a);
						}, {} as IStemAnimal),
					),
					departuredAnimals: mergeDepartured.map(e => {
						if (e.gender === Gender.Male) {
							return {
								animalNumber: e.animalNumber,
								departureDate: e.departureDate,
								race: e.race,
								id: e.id,
								gender: e.gender,
								idNumber: e.idNumber,
							} as IStemAnimalDepartured;
						}
						return {
							animalNumber: e.animalNumber,
							departureDate: e.departureDate,
						} as IStemAnimalDepartured;
					}),
				};
			}
			state = {
				...state,
				lastSyncDate: action.payload.result.syncTime!,
				syncInProgress: false,
			};
		} else {
			state = {
				...state,
				syncInProgress: false,
			};
		}
	}

	if (isType(action, getDeparturedSows.done)) {
		state = {
			...state,
			departuredAnimals: action.payload.result,
		};
	}

	if (isType(action, actions.getDeparturedAnimalDataByDate.done)) {
		if (action.payload.result.departedStemAnimals) {
			state = {
				...state,
				departuredAnimals: action.payload.result.departedStemAnimals,
			};
		}
	}

	if (isType(action, getSyncData.failed)) {
		state = { ...state, syncInProgress: false };
	}

	if (isType(action, saveSow.started)) {
		if (
			action.payload.departureDate &&
			action.payload.departureType !== DepartureTypes.departureTypeShouldDeparture &&
			action.payload.departureDate < new Date()
		) {
			state = {
				...state,
				updates: upsertValueInArray(state.updates, action.payload),
				entities: removeValueFromArray(state.entities, action.payload.id!),
				departuredAnimals: upsertValueInArray(state.departuredAnimals as IStemAnimal[], action.payload),
				saveSyncInProgress: true,
			};
		} else {
			state = {
				...state,
				updates: upsertValueInArray(state.updates, action.payload),
				entities: upsertValueInArray(state.entities, action.payload),
				saveSyncInProgress: true,
			};
			const animalToRestore = state.departuredAnimals.find(a => (a as IStemAnimal).id === action.payload.id);
			if (animalToRestore !== undefined) {
				state.departuredAnimals = state.departuredAnimals.filter(
					a => (a as IStemAnimal).id !== action.payload.id,
				);
			}
		}

		return state;
	}

	if (isType(action, saveSow.failed)) {
		if (action.payload.error.code === 500) {
			if (action.payload.error.prevEntity) {
				state = { ...state, entities: upsertValueInArray(state.entities, action.payload.error.prevEntity) };
			} else {
				state = { ...state, entities: removeValueFromArray(state.entities, action.payload.params.id!) };
			}
			state = {
				...state,
				updates: removeValueFromArray(state.updates, action.payload.params.id!),
				entranceEsfFeedingStatus: removeValueFromArray(
					state.entranceEsfFeedingStatus,
					action.payload.params.id!,
				),
			};
		}
		state = { ...state, saveSyncInProgress: false };

		return state;
	}

	if (isType(action, saveSow.done)) {
		state = {
			...state,
			updates: removeValueFromArray(state.updates, action.payload.result),
			saveSyncInProgress: false,
			entranceEsfFeedingStatus: removeValueFromArray(state.entranceEsfFeedingStatus, action.payload.params.id!),
		};

		return state;
	}

	if (isType(action, actions.updateYoungAnimal.started)) {
		if (
			action.payload.departureDate &&
			action.payload.departureType !== DepartureTypes.departureTypeShouldDeparture &&
			action.payload.departureDate < new Date()
		) {
			state = {
				...state,
				updatesYoungAninal: upsertValueInArray(state.updates, action.payload),
				entities: removeValueFromArray(state.entities, action.payload.id!),
				departuredAnimals: upsertValueInArray(state.departuredAnimals as IStemAnimal[], action.payload),
				saveSyncInProgress: true,
			};
		} else {
			state = {
				...state,
				saveSyncInProgress: true,
				entities: upsertValueInArray(state.entities, action.payload),
			};
		}

		return state;
	}
	if (isType(action, actions.updateYoungAnimal.failed)) {
		if (action.payload.error.code === 500) {
			if (action.payload.error.prevEntity) {
				state = { ...state, entities: upsertValueInArray(state.entities, action.payload.error.prevEntity) };
			} else {
				state = { ...state, entities: removeValueFromArray(state.entities, action.payload.params.id!) };
			}
			state = {
				...state,
				updatesYoungAninal: removeValueFromArray(state.updates, action.payload.params.id!),
			};
		}
		state = { ...state, saveSyncInProgress: false };

		return state;
	}

	if (isType(action, actions.updateYoungAnimal.done)) {
		state = {
			...state,
			saveSyncInProgress: false,
			updatesYoungAninal: removeValueFromArray(state.updates, action.payload.params.id!),
		};

		return state;
	}

	if (isType(action, actions.uploadImportData.done)) {
		state = { ...state, entities: mergeArrays(state.entities, action.payload.result) };
	}

	if (isType(action, saveStemAnimalNoUpdate.started)) {
		state = { ...state, entities: upsertValueInArray(state.entities, action.payload) };

		return state;
	}

	if (isType(action, siteChange.done)) {
		state = initialStemAnimalState;
	}

	if (isType(action, actions.setSowsCount)) {
		state = { ...state, sowsCount: action.payload };
	}

	if (isType(action, actions.setCheckedCount)) {
		state = { ...state, checkedCount: action.payload };
	}

	if (isType(action, saveEntranceFeedingStatus)) {
		state = {
			...state,
			entranceEsfFeedingStatus: upsertValueInArray(state.entranceEsfFeedingStatus, action.payload),
		};
	}

	if (isType(action, actions.fetchSingleAnimalInfo.done)) {
		if (action.payload.result.stemAnimal) {
			if (
				action.payload.result.stemAnimal.departureDate &&
				action.payload.result.stemAnimal.departureDate <= new Date()
			) {
				state = {
					...state,

					departuredAnimals: upsertValueInArray(
						state.departuredAnimals as IStemAnimal[],
						action.payload.result.stemAnimal,
					),
				};
			} else {
				state = {
					...state,

					entities: upsertValueInArray(state.entities, action.payload.result.stemAnimal),
				};
			}
		}
	}

	if (isType(action, actions.deleteYoungAnimals.done)) {
		state = {
			...state,
			entities: removeMultipleValuesFromArray(state.entities, action.payload.params),
		};
	}

	if (isType(action, actions.upsertManyYoungAnimals.started)) {
		state = {
			...state,
			entities: upsertMultipleValuesInArray(state.entities, action.payload),
			updatesYoungAninal: upsertMultipleValuesInArray(state.updatesYoungAninal, action.payload),
		};

		return state;
	}
	if (isType(action, actions.upsertManyYoungAnimals.failed)) {
		state = { ...state, saveSyncInProgress: true };
		if (action.payload.error.code === 500) {
			// Removes those entities, which failed, and didn't exist prior to the request
			let entitiesToRemove = action.payload.params.filter(
				ya =>
					action.payload.error.prevEntities && action.payload.error.prevEntities.find(pe => pe.id === ya.id),
			);
			if (action.payload.error.prevEntities) {
				state = {
					...state,
					entities: upsertMultipleValuesInArray(state.entities, action.payload.error.prevEntities),
				};
			} else {
				let ids = entitiesToRemove.map(ya => ya.id!);
				state = {
					...state,
					entities: removeMultipleValuesFromArray(state.entities, ids),
				};
			}
			state = {
				...state,
				updatesYoungAninal: removeMultipleValuesFromArray(
					state.updatesYoungAninal,
					action.payload.params.map(ya => ya.id!),
				),
			};
		}
		return state;
	}

	if (isType(action, actions.upsertManyYoungAnimals.done)) {
		const currentDate = new Date();
		const active = action.payload.result.filter(s => !s.departureDate);
		const departed = action.payload.result.filter(s => s.departureDate && s.departureDate <= currentDate);
		let entities = upsertMultipleValuesInArray(state.entities, active);
		entities = removeMultipleValuesFromArray(
			state.entities,
			departed.map(ya => ya.id!),
		);

		state = {
			...state,
			updatesYoungAninal: removeMultipleValuesFromArray(
				state.updatesYoungAninal,
				action.payload.result.map(ya => ya.id!),
			),
			entities: entities,
			departuredAnimals: upsertMultipleValuesInArray(state.departuredAnimals, departed),
		};

		return state;
	}

	if (isType(action, actions.GetAllIdNumbers.done)) {
		state = {
			...state,
			allIdNumbers: action.payload.result,
		};

		return state;
	}

	if (isType(action, actions.getYoungFemalesByDate.done)) {

		state = {
			...state,
			youngFemaleReportData: action.payload.result,
		};
		return state;
	}

	//Ensure Date objects are in fact date and not strings - redux-persist serializes dates to string but doesn't deserialize to dates again
	if (action.type === 'persist/REHYDRATE') {
		let a = (action as any) as { payload: StemAnimalState; key: string };

		if (a.key === 'stemAnimals') {
			let entities = new Array<StemAnimal>();
			let departuredSows: Array<IStemAnimal | IStemAnimalDepartured> = [];
			let updates = new Array<StemAnimal>();
			let updatesYoungAninal = new Array<StemAnimal>();
			let entranceEsfFeedingStatus = new Array<IFeedingStatusUpdate>();
			let lastSyncDate = new Date(-8640000000000000);

			if (a.payload && a.payload && a.payload.entities) {
				entities = a.payload.entities.map((dt: IStemAnimal) => {
					let ndt = StemAnimal.fromJS({});
					ndt.init(dt);
					return ndt;
				});
			}

			if (a.payload && a.payload && a.payload.updates) {
				updates = a.payload.updates.map((dt: IStemAnimal) => {
					let ndt = StemAnimal.fromJS({});
					ndt.init(dt);
					return ndt;
				});
			}
			if (a.payload && a.payload && a.payload.updatesYoungAninal) {
				updatesYoungAninal = a.payload.updatesYoungAninal.map((dt: IStemAnimal) => {
					let ndt = StemAnimal.fromJS({});
					ndt.init(dt);
					return ndt;
				});
			}

			if (a.payload && a.payload && a.payload.entranceEsfFeedingStatus) {
				entranceEsfFeedingStatus = a.payload.entranceEsfFeedingStatus.map((dt: IFeedingStatusUpdate) => {
					let ndt = new FeedingStatusUpdate();
					ndt.init(dt);
					return ndt;
				});
			}

			if (a.payload && a.payload && a.payload.lastSyncDate) {
				lastSyncDate = new Date(a.payload.lastSyncDate);
			}

			if (a.payload && a.payload.departuredAnimals && a.payload.lastSyncDate) {
				a.payload.departuredAnimals.forEach(departuredAnimal => {
					if (departuredAnimal.gender === Gender.Male) {
						departuredSows.push({
							animalNumber: departuredAnimal.animalNumber,
							departureDate: departuredAnimal.departureDate
								? new Date(departuredAnimal.departureDate)
								: undefined,
							race: departuredAnimal.race,
							id: departuredAnimal.id,
							gender: departuredAnimal.gender,
							idNumber: departuredAnimal.idNumber,
						} as IStemAnimalDepartured);
					} else {
						departuredSows.push({
							animalNumber: departuredAnimal.animalNumber,
							departureDate: departuredAnimal.departureDate
								? new Date(departuredAnimal.departureDate)
								: undefined,
						} as IStemAnimalDepartured);
					}
				});
			}

			state = {
				...state,
				entities,
				updates,
				lastSyncDate,
				departuredAnimals: departuredSows,
				entranceEsfFeedingStatus,
				updatesYoungAninal,
			};
		}
	}

	return state;
};

export default sowReducer;

export const selectIdNumbers = (state: SharedAppState | NativeAppState | WebAppState) =>
	memoizeIdNumbers(state.stemAnimals.entities);
