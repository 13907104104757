import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
	INucleusManagementBreedingSettings,
	INumberBatch,
	NucleusManagementProvider,
	NumberBatch,
} from '../../../../../../shared/api/api';
import { ViewWeb } from '../../../../components/utils/web-view';
import './nucleus-management-breeding-settings.scss';
import { SkioldInput } from '../../../../components/skiold-components/skiold-input/skiold-input';
import { SkioldIntegerInput } from '../../../../components/skiold-components/skiold-integer-input/skiold-integer-input';
import { SkioldCheckbox } from '../../../../components/skiold-components/skiold-checkbox/skiold-checkbox';
import { useSelector } from 'react-redux';
import { WebAppState } from '../../../../../state/store.web';
import { Option } from 'react-dropdown';
import { SkioldDropdown } from '../../../../components/skiold-components/skiold-dropdown/skiold-dropdown';
import { SkioldTouchableOpacity } from '../../../../components/skiold-components/skiold-touchable-opacity';
import { SkioldImage } from '../../../../components/utils/svg/skiold-image';
import DeleteIcon from 'shared/assets/src-assets/png/delete_icon.png';
import { getProvider } from '../nucleus-management-helper';
import { getCombinations } from 'shared/helpers/nucleus-management-helper/nucleus-management-helper';

interface Props {
	numberBatch: INumberBatch;
	setBreedingSettings: (event: React.SetStateAction<INucleusManagementBreedingSettings[]>) => void;
	parentIndex: number;
	index: number;
	provider: ReturnType<typeof getProvider>;
}

const inputRestrictions = {
	purchaseNumber: 2,
	startMatingNumber: 5,
	nextMatingNumber: 5,
	endMatingNumber: 5,
};

export const NumberBatchSetupRow: FC<Props> = React.memo(props => {
	const tableBreeds = useSelector((state: WebAppState) => state.breedTableItems.tableBreeds);

	const breedOptions = useMemo(() => {
		if (props.provider === NucleusManagementProvider.DanishGenetics) {
			const combinations = getCombinations(tableBreeds);
			return combinations.map(option => ({ label: option, value: option } as Option));
		} else if (props.provider === NucleusManagementProvider.DanBred) {
			return tableBreeds
				.slice()
				.sort()
				.map(option => ({ label: option, value: option } as Option));
		} else {
			return tableBreeds
				.slice()
				.sort()
				.map(option => ({ label: option, value: option } as Option));
		}
	}, [tableBreeds, props.provider]);

	const [selectedOption, setSelectedOption] = useState<Option | undefined>(
		breedOptions.find(option => option.value === props.numberBatch.race),
	);

	useEffect(() => {
		setSelectedOption(breedOptions.find(option => option.value === props.numberBatch.race));
	}, [props.numberBatch.race, breedOptions]);

	const handleValueChange = useCallback(
		(fieldName: keyof NumberBatch) => (text: string | boolean | number | undefined) => {
			props.setBreedingSettings(current =>
				current.map((breedingSetting, index) => {
					if (index === props.parentIndex) {
						// Restrict input length by fieldName
						if (
							inputRestrictions[fieldName] &&
							text &&
							text.toString().length > inputRestrictions[fieldName]
						) {
							return breedingSetting;
						}
						const localSetting = { ...breedingSetting };
						localSetting.numberBatches![props.index] = {
							...localSetting.numberBatches![props.index],
							[fieldName]: text,
						} as NumberBatch;
						return localSetting;
					}

					return breedingSetting;
				}),
			);
		},
		[props],
	);

	const handleDropdown = useCallback(
		(newValue: Option) => {
			setSelectedOption(newValue);
			props.setBreedingSettings(current =>
				current.map((breedingSetting, index) => {
					if (index === props.parentIndex) {
						const localSetting = { ...breedingSetting };
						localSetting.numberBatches![props.index] = {
							...localSetting.numberBatches![props.index],
							race: newValue.value,
						} as NumberBatch;
						return localSetting;
					}

					return breedingSetting;
				}),
			);
		},
		[props],
	);

	const deleteNumberBatch = useCallback(() => {
		props.setBreedingSettings(current =>
			current.map((breedingSetting, index) => {
				if (index === props.parentIndex) {
					const localSetting = { ...breedingSetting };
					localSetting.numberBatches?.splice(props.index, 1);
					return localSetting;
				}
				return breedingSetting;
			}),
		);
	}, [props]);

	return (
		<ViewWeb className={'flex-row'}>
			<ViewWeb
				className={`number-batch-row flex-row ${
					props.index % 2 === 0 ? 'gray-background' : 'white-background'
				}`}
			>
				<ViewWeb className={'border-right-dottet wide-cell '}>
					<SkioldDropdown
						items={breedOptions}
						onValueChanged={handleDropdown}
						selectedValue={selectedOption}
						theme={'dark'}
						menuClassname={'dropdown-menu width-auto'}
					/>
				</ViewWeb>
				<ViewWeb className={'border-right-dottet slim-cell'}>
					<SkioldCheckbox
						isChecked={props.numberBatch.boarAssignable}
						onClick={handleValueChange('boarAssignable')}
					/>
				</ViewWeb>
				<ViewWeb className={'border-right-dottet slim-cell'}>
					<SkioldCheckbox
						isChecked={props.numberBatch.sowAssignable}
						onClick={handleValueChange('sowAssignable')}
					/>
				</ViewWeb>
				<ViewWeb className={'border-right-dottet wide-cell'}>
					<SkioldInput
						text={props.numberBatch.purchaseNumber}
						onChangeText={handleValueChange('purchaseNumber')}
					/>
				</ViewWeb>
				<ViewWeb className={'border-right-dottet wide-cell'}>
					<SkioldInput
						text={props.numberBatch.startMatingNumber}
						onChangeText={handleValueChange('startMatingNumber')}
					/>
				</ViewWeb>
				<ViewWeb className={'border-right-dottet wide-cell'}>
					<SkioldInput
						text={props.numberBatch.nextMatingNumber}
						onChangeText={handleValueChange('nextMatingNumber')}
					/>
				</ViewWeb>
				<ViewWeb className={'border-right-dottet wide-cell'}>
					<SkioldInput
						text={props.numberBatch.purchaseNumber}
						onChangeText={handleValueChange('purchaseNumber')}
					/>
				</ViewWeb>
				<ViewWeb className={'border-right-dottet wide-cell'}>
					<SkioldInput
						text={props.numberBatch.endMatingNumber}
						onChangeText={handleValueChange('endMatingNumber')}
					/>
				</ViewWeb>
				<ViewWeb className={'border-right-dottet slim-cell'}>
					<SkioldIntegerInput
						text={props.numberBatch.priority}
						onChangeNumber={handleValueChange('priority')}
					/>
				</ViewWeb>
				<ViewWeb className={'slim-cell'}>
					<SkioldTouchableOpacity onPress={deleteNumberBatch}>
						<SkioldImage imageData={DeleteIcon} width={20} height={20} />
					</SkioldTouchableOpacity>
				</ViewWeb>
			</ViewWeb>
		</ViewWeb>
	);
});
