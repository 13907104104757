import React from 'react';
import { localized } from 'shared/state/i18n/i18n';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldUnderlineIntergerInput } from 'web/view/components/skiold-components/skiold-integer-input/skiold-underline-interger-input';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import './mating-batch-setting.scss';

interface PropsFromParent {
	closeModal: () => void;
	repeat: (timesToRepeat?: number) => void;
}

interface State {
	timesToRepeat: number | undefined;
}

type Props = PropsFromParent;

export class TimesToRepeat extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			timesToRepeat: undefined
		};
	}

	public render() {
		return (
			<ViewWeb className="repeat-container">
				<ViewWeb className="row-container">
					<TextWeb className="white-text">{localized('timesToRepeat')}</TextWeb>
					<SkioldUnderlineIntergerInput
						onChangeNumber={this.repeatChanged}
						text={this.state.timesToRepeat}
						className="text-input-style"
					/>
				</ViewWeb>
				<ViewWeb className="row-container">
					<SkioldButton title={localized('Ok')} onPress={this.repeatMatingBatches} />
					<SkioldButton title={localized('Close')} onPress={this.props.closeModal}  theme="grey" />
				</ViewWeb>
			</ViewWeb>
		);
	}
	private repeatMatingBatches = () => {
		this.props.repeat(this.state.timesToRepeat);
	};

	private repeatChanged = (repeat?: number) => {
		this.setState({ timesToRepeat: repeat });
	};
}
