import { ISupplierDetailDto } from "shared/api/api";


export const SAVE_SUPPLIER_DETAILS = 'SAVE_SUPPLIER_DETAILS';
export const PUT_SUPPLIER_DETAILS = 'PUT_SUPPLIER_DETAILS';
export const ADD_SUPPLIER_DETAILS = 'ADD_SUPPLIER_DETAILS';
export const DELETE_SUPPLIER_DETAILS = 'DELETE_SUPPLIER_DETAILS';
export const SYNC_DATA_SUPPLIER_DETAILSS = 'SYNC_DATA_SUPPLIER_DETAILSS';

export interface SupplierDetailsState {
    supplierDetails: ISupplierDetailDto[];
}