import { IRetryAdjustFeedTableItem } from "shared/api/api";


export const GET_DATA = 'GET_DATA';
export const SET_COMMIT_STATUS = 'SET_COMMIT_STATUS';
export const SAVE_RETRY = 'SAVE_RETRY';
export const DELETE_RETRY = 'DELETE_RETRY';

export const SET_COMMIT_STATUS_ON_ALL = 'SET_COMMIT_STATUS_ON_ALL';

export interface RetryAdjustFeedState {
    retryAdjustFeedItems: RetryAdjustFeedItemApp[];
    attemptingToUpdateAll: boolean;
    commitAll: boolean;
}

export interface RetryAdjustFeedItemApp extends IRetryAdjustFeedTableItem {
    ids: string[];
    buildingOrder: number | undefined;
    sectionOrder: number | undefined;
    penOrder: number | undefined;
    shouldBeCommited: boolean;
    showSpinner: boolean;
    status: boolean;
}
