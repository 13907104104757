import { IDeadPigletsEvent } from 'shared/api/api';
import { Syncable } from 'shared/state/models/syncable';

export const SAVE_DEAD_PIGLETS_EVENT = 'SAVE_DEAD_PIGLETS_EVENT';
export const REMOVE_DEAD_PIGLETS_EVENT = 'REMOVE_DEAD_PIGLETS_EVENT';
export const SYNC_DEAD_PIGLETS_EVENT = 'SYNC_DEAD_PIGLETS_EVENT';
export const GET_DEPARTURED_DEAD_PIGLETS_EVENT = 'GET_DEPARTURED_DEAD_PIGLETS_EVENT';
export const GET_PIGLET_EVENTS_BY_PERIOD = 'GET_PIGLET_EVENTS_BY_PERIOD';

// tslint:disable-next-line: interface-name

export interface DeadPigletsEventEventState extends Syncable<IDeadPigletsEvent> {
	departuredDeadPigletEvents: IDeadPigletsEvent[];
}
