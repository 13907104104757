import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import actionCreatorFactory from 'typescript-fsa';
import * as types from './types';
import { IPoolYoungFemale, SyncPoolYoungFemale } from 'shared/api/api';

const actionCreator = actionCreatorFactory(GenActionFactoryName('poolYoungFemales'));

export const upsertPoolYoungFemale = actionCreator.async<
	IPoolYoungFemale,
	string,
	{ code: number; message: string; prevEntity: IPoolYoungFemale | undefined }
>(types.UPSERT_POOL_YOUNG_FEMALE);

export const deletePoolYoungFemale = actionCreator.async<string, string>(types.DELETE_POOL_YOUNG_FEMALE);

export const getSyncPoolYoungFemale = actionCreator.async<
	{ siteId: string; lastSyncDate: Date; activeSiteId?: string },
	SyncPoolYoungFemale
>(types.SYNC_POOL_YOUNG_FEMALE);

export const upsertManyPoolYoungFemale = actionCreator.async<
	IPoolYoungFemale[],
	string,
	{ code: number; message: string; prevEntity: IPoolYoungFemale[] | undefined }
>(types.UPSERT_MANY_POOL_YOUNG_FEMALE);

export const deleteManyPoolYoungFemale = actionCreator.async<
	IPoolYoungFemale[],
	string,
	{ code: number; message: string; prevEntity: IPoolYoungFemale[] | undefined }
>(types.DELETE_MANY_POOL_YOUNG_FEMALE);

