import { Sorting } from '@devexpress/dx-react-grid';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	BalanceWeanedPigEvent,
	GrowthPigEventType,
	BalancePigEventTotalWeightBalancePigEvents,
	IBalancePigEventTotalWeightBalancePigEvents,
} from 'shared/api/api';
import PenIcon from 'shared/assets/src-assets/png/pen_icon.png';
import {
	BalanceWeanedPigEventOverviewTableData,
	BalanceWeanedPigsOverviewTableWidths,
	generateBalancedWeanedOverviewTableData,
} from 'shared/helpers/balance-weaned-pigs-helper/balance-weaned-pigs-helper';
import { getDateString } from 'shared/helpers/date-helpers';
import { memoizeHashmapGrowthPigEvents, memoizeHashmapLocation } from 'shared/helpers/memoize-getters/memoize-getters';
import { NaturalSortDates } from 'shared/helpers/natural-sort';
import { localized } from 'shared/state/i18n/i18n';
import { SharedAppState } from 'shared/state/store.shared';
import SkioldTableGrid from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { SkioldTouchableOpacity } from 'web/view/components/skiold-components/skiold-touchable-opacity';
import { SowListConstants } from 'web/view/components/stem-animal/animal-lists/table-constants';
import { SkioldImage } from 'web/view/components/utils/svg/skiold-image';
import DeleteIcon from 'shared/assets/src-assets/png/delete_icon.png';
import { removeGrowthPigsEventByIds } from 'shared/state/ducks/growth-pig-events/operations';
import { upsertBalanceWeanedPigsData } from 'shared/state/ducks/balance-weaned-pigs/operations';

interface PropsFromParent {
	data: BalanceWeanedPigEvent[];
	onFiltersChanged?: (currentData: any[]) => void;
	openEditModal: (event: BalanceWeanedPigEventOverviewTableData) => void;
}

const defaultSort: Sorting[] = [{ columnName: 'date', direction: 'desc' }];

export const BalanceWeanedPigTable: FC<PropsFromParent> = React.memo(props => {
	const dispatch = useDispatch();
	const gpesMemoized = memoizeHashmapGrowthPigEvents(
		useSelector((state: SharedAppState) => state.growthPigEvents.growthPigEvents),
	);

	const { buildings, sections, pens } = useSelector((state: SharedAppState) => state.locations);
	const locationsMemoized = memoizeHashmapLocation(buildings, sections, pens);
	const generateColumnsExtensions = useCallback(() => {
		return [
			{
				columnName: 'edit',
				filteringEnabled: false,
				width: BalanceWeanedPigsOverviewTableWidths.editWidth,
			},
			{
				columnName: 'Event',
				width: BalanceWeanedPigsOverviewTableWidths.typeWidth,
			},
			{
				columnName: 'date',
				width: BalanceWeanedPigsOverviewTableWidths.dateWidth,
			},
			{
				columnName: 'totalAmount',
				width: BalanceWeanedPigsOverviewTableWidths.amountWidth,
			},
			{
				columnName: 'remove',
				filteringEnabled: false,
				width: BalanceWeanedPigsOverviewTableWidths.editWidth,
			},
		];
	}, []);

	const getDateCell = (balancePigEvent: BalanceWeanedPigEventOverviewTableData) =>
		getDateString(balancePigEvent.date);
	const getEventTypeCell = (balancePigEvent: BalanceWeanedPigEventOverviewTableData) =>
		balancePigEvent && balancePigEvent.eventName;
	const getTotalAmountCell = (balancePigEvent: BalanceWeanedPigEventOverviewTableData) => balancePigEvent.totalAmount;

	const generateColumns = () => {
		const columns = [
			{
				name: 'edit',
				title: ' ',
				sortable: false,
				filterable: false,
				isFixedLeft: true,
				rowSelectedDisabledForColumn: true,
				getCellValue: generateEditCell(),
			},
			{
				name: 'Event',
				sortable: false,
				filterable: false,
				title: localized('Events'),
				getCellValue: getEventTypeCell,
			},
			{
				name: 'date',
				title: localized('Date'),
				sortFunction: NaturalSortDates,
				getCellValue: getDateCell,
			},
			{
				name: 'totalAmount',
				title: localized('Count'),
				getCellValue: getTotalAmountCell,
			},
			{
				name: 'remove',
				title: ' ',
				sortable: false,
				filterable: false,
				rowSelectedDisabledForColumn: true,
				getCellValue: getRemoveCell,
			},
		];
		return columns;
	};
	const generateEditCell = () => {
		return (item: BalanceWeanedPigEventOverviewTableData) =>
			item.eventName !== localized(GrowthPigEventType.PigletWeanedEvent) ? (
				<SkioldTouchableOpacity itemFromParent={item} onPress={props.openEditModal}>
					<SkioldImage
						width={SowListConstants.iconSVGWidth}
						height={SowListConstants.iconSVGWidth}
						imageData={PenIcon}
					/>
				</SkioldTouchableOpacity>
			) : undefined;
	};

	const getRemoveCell = (item: BalanceWeanedPigEventOverviewTableData) => {
		return item.eventName !== localized(GrowthPigEventType.PigletWeanedEvent) ? (
			<SkioldTouchableOpacity onPress={removeDistributed} itemFromParent={item}>
				<SkioldImage
					width={SowListConstants.iconSVGWidth}
					height={SowListConstants.iconSVGWidth}
					imageData={DeleteIcon}
				/>
			</SkioldTouchableOpacity>
		) : undefined;
	};

	const removeDistributed = (item: BalanceWeanedPigEventOverviewTableData) => {
		removeGrowthPigsEventByIds(item.eventIds)(dispatch);

		upsertBalanceWeanedPigsData(
			new BalancePigEventTotalWeightBalancePigEvents({
				balanceWeanedPigEvents: [] as BalanceWeanedPigEvent[],
			} as IBalancePigEventTotalWeightBalancePigEvents),
			item.eventIds,
			item.totalAmount,
			item.totalWeight,
		)(dispatch);
	};

	const [tableData, setTableData] = useState(
		generateBalancedWeanedOverviewTableData(props.data, gpesMemoized, locationsMemoized),
	);

	useEffect(() => {
		setTableData(generateBalancedWeanedOverviewTableData(props.data, gpesMemoized, locationsMemoized));
	}, [props, gpesMemoized, locationsMemoized]);

	return (
		<SkioldTableGrid
			onFiltersChanged={props.onFiltersChanged}
			triggerFilterChangedOnDataNotEqual={true}
			columns={generateColumns()}
			ignoreSetCount={true}
			tableKey={'balanceWeanedPigTable'}
			ColumnExtensions={generateColumnsExtensions()}
			data={tableData}
			sortHeaderId={defaultSort}
		/>
	);
});
