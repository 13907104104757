import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import PenIcon from 'shared/assets/src-assets/png/pen_icon.png';
import { GetCountries } from 'shared/state/ducks/country/operations';
import { localized, localizedDynamic } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { SkioldModal } from '../../skiold-components/skiold-modal/skiold-modal';
import { SkioldTouchableOpacity } from '../../skiold-components/skiold-touchable-opacity';
import { SkioldImage } from '../../utils/svg/skiold-image';
import EditSite from './location-create-components/edit-site';
import './site-component.scss';


const mapStateToProps = (state: WebAppState) => {
	return {
		site: state.site.site,
		countries: state.countries.countries
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
	return {
		getCountries: () => GetCountries()(dispatch)
	};
};

interface SiteComponentState {
	modalEditIsOpen: boolean;
}

type SiteComponentProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
class SiteComponent extends React.PureComponent<SiteComponentProps, SiteComponentState> {
	constructor(props: SiteComponentProps) {
		super(props);

		this.state = {
			modalEditIsOpen: false
		};
	}

	public componentDidMount() {
		this.props.getCountries();
	}

	private readonly openEditSite = () => this.setState({ modalEditIsOpen: true });

	private readonly editPenExtraStyle = { paddingLeft: 10, marginTop: -5 };

	public render() {
		return (
			<ViewWeb className="site-component">
				<ViewWeb className="wrapper-column">
					<ViewWeb className="wrapper-row">
						<TextWeb className="label-text">{localized('Owner')}: </TextWeb>
						<TextWeb className="value-text">
							{ this.props.site &&this.props.site.ownerName}
						</TextWeb>
					</ViewWeb>
					<ViewWeb className="wrapper-row">
						<TextWeb className="label-text">{localized('address')}: </TextWeb>
						<TextWeb className="value-text">
							{ this.props.site && this.props.site.ownerAddress}
						</TextWeb>
					</ViewWeb>
					<ViewWeb className="wrapper-row">
						<TextWeb className="label-text">{localized('ZipCode')}: </TextWeb>
						<TextWeb className="value-text">{this.props.site &&  this.props.site.ownerZipCode !== 0 && this.props.site.ownerZipCode}</TextWeb>
					</ViewWeb>
					<ViewWeb className="wrapper-row">
						<TextWeb className="label-text">{localized('city')}: </TextWeb>
						<TextWeb className="value-text">{this.props.site && this.props.site.ownerCity}</TextWeb>
					</ViewWeb>
					<ViewWeb className="wrapper-row">
						<TextWeb className="label-text">{localized('CVRnr')}: </TextWeb>
						<TextWeb className="value-text">{this.props.site &&  this.props.site.cvRnum !== 0 && this.props.site.cvRnum}</TextWeb>
					</ViewWeb>
				</ViewWeb>
				<ViewWeb className="wrapper-column left-border">
					<ViewWeb className="wrapper-row">
						<TextWeb className="label-text">{localized('CHRnr')}: </TextWeb>
						<TextWeb className="value-text">{this.props.site &&this.props.site.chRnum}</TextWeb>
					</ViewWeb>
					<ViewWeb className="wrapper-row">
						<TextWeb className="label-text">{localized('address')}: </TextWeb>
						<TextWeb className="value-text">{this.props.site &&this.props.site.siteAddress}</TextWeb>
					</ViewWeb>
					<ViewWeb className="wrapper-row">
						<TextWeb className="label-text">{localized('ZipCode')}: </TextWeb>
						<TextWeb className="value-text">{this.props.site &&this.props.site.siteZipCode}</TextWeb>
					</ViewWeb>
					<ViewWeb className="wrapper-row">
						<TextWeb className="label-text">{localized('city')}: </TextWeb>
						<TextWeb className="value-text">{this.props.site &&this.props.site.siteCity}</TextWeb>
					</ViewWeb>
					<ViewWeb className="wrapper-row">
						<TextWeb className="label-text">{localized('siteName')}: </TextWeb>
						<TextWeb className="value-text">{this.props.site &&this.props.site.siteName}</TextWeb>
					</ViewWeb>
				</ViewWeb>
				<ViewWeb className="left-border-padding wrapper-row">
					<SkioldTouchableOpacity
						className="flexdirection"
						onPress={this.openEditSite}
					>
						<TextWeb className="label-text">{localized('country')}: </TextWeb>
						<TextWeb className="value-text">
							{ this.props.site && localizedDynamic(this.getCountry(this.props.site.countryId!))}
						</TextWeb>
						<SkioldImage
							extraStyle={this.editPenExtraStyle}
							width="30"
							height="30"
							imageData={PenIcon}
						/>
					</SkioldTouchableOpacity>
				</ViewWeb>

				<SkioldModal padding="0" close={ this.closeModal} isOpen={this.state.modalEditIsOpen}>
					<EditSite close={this.closeModal} site={this.props.site} isOpen={this.state.modalEditIsOpen} />
				</SkioldModal>
			</ViewWeb>
		);
	}

	private closeModal = () => {
		this.setState({ modalEditIsOpen: false });
	};

	private getCountry(countryId: string) {
		const country = this.props.countries.find(x => x.id === countryId);
		if (!country) {
			return '';
		}
		return country!.name!;
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SiteComponent);
