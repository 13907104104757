import React, { CSSProperties } from 'react';
import { Option } from 'react-dropdown';
import { IMoveToMatingLocation, IMoveToPregnancyLocation, MoveToLocationTypes, WorkListType } from 'shared/api/api';
import { localized } from 'shared/state/i18n/i18n';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import SkioldFormPenPicker from '../../location/location-picker/skiold-form-pen-picker';
import SkioldStableSectionPicker from '../../location/location-picker/skiold-stable-section-picker';
import { SkioldDatePicker } from '../../skiold-components/skiold-date-picker/skiold-date-picker';
import { SkioldFormDropdown } from '../../skiold-components/skiold-dropdown/skiold-form-dropdown';
import SkioldTableItemCounter from '../../skiold-components/skiold-table/skiold-table-item-counter';
import { ILocationModel } from 'shared/helpers/location-helper';

export function getRows(
	moveToSetup: IMoveToPregnancyLocation | IMoveToPregnancyLocation | IMoveToMatingLocation,
	matingBatchesOptions: Option[],
	selectedBatch: Option,
	locationString: string | undefined,
	moveListSelectedPen: string | undefined,
	sectionId: string | undefined,
	locations: ILocationModel | undefined,
	selectedHeaderSectionUsesPens: boolean,
	selectedDate: Date | undefined,
	selectedBatchChanged: ((option: Option) => void) | undefined,
	selectedDateChanged: ((date: Date) => void) | undefined,
	selectedSectionChanged: (selectedSectionId: string) => void,
	selectedPenChanged: (selectedPenId: string) => void,
	fromLocation?: ILocationModel | undefined,
	fromSelectedSectionChanged?: (selectedSectionId: string) => void,
	fromSelectedPenChanged?: (selectedPenId: string) => void,
	fromPenId?: string | undefined,
	fromSectionId?: string | undefined,
	fromSectionUsesPens?: boolean,
) {
	return (
		<ViewWeb className="getrows">
			<ViewWeb className="flex-direction-row">
				<ViewWeb className="flexOne">
					<div className="tableCounterContainer">
						<div className="tableCounterLabel">{localized('Animals')}</div>
						<SkioldTableItemCounter useChecked={true} className="tableCounterValue" />
					</div>
				</ViewWeb>
				{moveToSetup.selectedType === MoveToLocationTypes.PregnacyDays ? (
					<ViewWeb className="flex-direction-row flexFour">
						<TextWeb className="text label-padding">
							{moveToSetup.type === WorkListType.MoveToMatingLocation
								? localized('moveToMatingWeaningDryDays')
								: localized('pregnantDays')}
						</TextWeb>
						<ViewWeb className="buttons-no-right-border">
							<TextWeb className="orange-color-viewstyle">
								{moveToSetup.cycleDaysFrom}
							</TextWeb>

							<TextWeb className="preg-days-connector"> - </TextWeb>
							<TextWeb className="orange-color-viewstyle">
								{moveToSetup.cycleDaysTo}
							</TextWeb> 
						</ViewWeb>
					</ViewWeb>
				) : moveToSetup.selectedType === MoveToLocationTypes.MatingBatch ? (
					<ViewWeb className="flex-direction-row flexFour">
						<TextWeb className="text label-padding">{localized('matingBatch')} </TextWeb>
						<ViewWeb className="buttons-no-right-border">
							<SkioldFormDropdown
								theme={'light'}
								items={matingBatchesOptions}
								selectedValue={selectedBatch}
								onValueChanged={selectedBatchChanged!}
							/>
						</ViewWeb>
					</ViewWeb>
				) : moveToSetup.selectedType === MoveToLocationTypes.Location &&
				  fromSelectedSectionChanged &&
				  fromSelectedPenChanged &&
				  fromLocation ? (
					<ViewWeb className="flex-direction-row flexFour">
						<TextWeb className="text flexOne">{localized('FromLocation')}</TextWeb>
						<ViewWeb className="flexFour flex-direction-row">
							<ViewWeb className="buttons-no-right-border">
								<TextWeb className="stable-section-text">{localized('StableSection')}:</TextWeb>
								<SkioldStableSectionPicker
									onStableSectionSelected={fromSelectedSectionChanged}
									sectionId={fromSectionId}
									filteredLocations={fromLocation}
									isPigProduction={true}
									containerClassName="picker-width"
								/>
							</ViewWeb>

							{fromSectionUsesPens === true && (
								<ViewWeb className="pen-picker-container">
									<ViewWeb className="buttons-no-right-border">
										<TextWeb className="pen-text">{localized('Pen')}:</TextWeb>
										<SkioldFormPenPicker
											onPenSelected={fromSelectedPenChanged}
											filteredLocations={fromLocation}
											sectionId={fromSectionId}
											penId={fromPenId}
											theme="light"
											containerClassName="picker-width"
											usedInsideForm={false}
										/>
									</ViewWeb>
								</ViewWeb>
							)}
						</ViewWeb>
					</ViewWeb>
				) : moveToSetup.selectedType === MoveToLocationTypes.Date ? (
					<ViewWeb className="flex-direction-row flexFour">
						<TextWeb className="text label-padding">{localized('moveToMatingWeaningDate')}</TextWeb>
						<ViewWeb className="buttons-no-right-border">
							<SkioldDatePicker
								onDateChanged={selectedDateChanged!}
								selectedDate={selectedDate}
								includeTimeStamp={false}
								theme={'light'}
							/>
						</ViewWeb>
					</ViewWeb>
				) : (
					<ViewWeb />
				)}
			</ViewWeb>

			{/* {this.state.feedCurves.length > 0 ? (
                <ViewWeb style={{ flex-direction: row; }}>
                    <TextWeb style={styles.StableSectionStyle}>{localized('FEEDINGCURVE')}: </TextWeb>
                    <SkioldFormDropdown
                        theme={'light'}
                        items={this.state.feedCurvesOptions}
                        selectedValue={this.state.selectedFeedCurve}
                        onValueChanged={(option: Option) =>
                            this.setState(prevState => ({
                                selectedFeedCurve: option
                            }))
                        }
                    />
                </ViewWeb>
            ) : (
                <ViewWeb />
            )} */}
			<ViewWeb className="stable-picker-container">
				{moveToSetup.selectedType === MoveToLocationTypes.Location ? (
					<ViewWeb className="flexOne" />
				) : (
					<TextWeb className="text flexOne">{localized('MoveToLocation')} </TextWeb>
				)}
				<ViewWeb className="flexFour flex-direction-row ">
					{moveToSetup.selectedType === MoveToLocationTypes.Location && (
						<TextWeb className="text flexOne">{localized('ToLocation')}</TextWeb>
					)}

					<ViewWeb className="flexFour flex-direction-row ">
						<ViewWeb className="buttons-no-right-border">
							<TextWeb className="stable-section-text">{localized('StableSection')}:</TextWeb>
							<SkioldStableSectionPicker
								onStableSectionSelected={selectedSectionChanged}
								sectionId={sectionId}
								filteredLocations={locations}
								isPigProduction={true}
								containerClassName="picker-width"
							/>
						</ViewWeb>

						{selectedHeaderSectionUsesPens === true && (
							<ViewWeb className="pen-picker-container">
								<ViewWeb className="buttons-no-right-border">
									<TextWeb className="pen-text">{localized('Pen')}:</TextWeb>
									<SkioldFormPenPicker
										onPenSelected={selectedPenChanged}
										filteredLocations={locations}
										sectionId={sectionId}
										penId={moveListSelectedPen}
										theme="light"
										containerClassName="picker-width"
										usedInsideForm={false}
									/>
								</ViewWeb>
							</ViewWeb>
						)}
					</ViewWeb>
				</ViewWeb>
			</ViewWeb>
		</ViewWeb>
	);
}
