import React from 'react';
import { connect } from 'react-redux';
import { SyncLoader } from 'react-spinners';
import ReactTable, { FilterValue } from 'react-table';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import 'react-table/react-table.css';
import { Dispatch } from 'redux';
import { Colors } from 'shared/assets/root-assets/colors';
import { Monitoring } from 'shared/helpers/monitoring-service';
import { NaturalSort } from 'shared/helpers/natural-sort';
import { localized } from 'shared/state/i18n/i18n';
import styled from 'styled-components';
import 'web/view/components/skiold-components/skiold-table/skiold-double-header-table.scss';
import './react-table-override.scss';
import { isNullOrUndefined } from 'shared/helpers/general-helpers';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

export interface PropsFromParent {
	data: any[];
	columns: any[];
	defaultDesc?: boolean;
	sortHeaderId?: string;
	style?: any;
	filterable?: boolean;
	className?: string;
	sortable?: boolean;
	showNoDataText?: boolean;
	getTrProps?: any;
	rowCount?: number;
	loading?: boolean;
	showPagination?: boolean;
	handleFilteredDataChanged?: () => void;
}

const OuterDiv = styled.div`
	border-radius: 50px;
`;

export interface Column {
	id: string;
}

export interface Row<T> {
	original: T;
	index: number;
	column: Column;
}

export interface State {}
const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({});

type SkioldTableProps = ReturnType<typeof mapDispatchToProps> & PropsFromParent;
export class SkioldTable extends React.PureComponent<SkioldTableProps, State> {
	public static defaultProps: Partial<SkioldTableProps> = {
		filterable: true,
		sortHeaderId: undefined,
		className: '',
		sortable: true,
		showNoDataText: false,
		showPagination: false,
		defaultDesc: false,
	};
	private reactTableRef: any;

	constructor(props: SkioldTableProps) {
		super(props);
		this.reactTableRef = React.createRef();
	}
	public GetSortedData() {
		if (!isNullOrUndefined(this.reactTableRef)) {
			let sortedData = this.reactTableRef.getResolvedState().sortedData;
			return sortedData;
		}
	}

	public render() {
		return (
			<OuterDiv>
				<ReactTableFixedColumns
					className={'-striped skiold-table ' + this.props.className}
					data={this.props.data}
					columns={this.props.columns}
					showPagination={this.props.showPagination}
					minRows={0}
					innerRef={this.setInnerTableRef}
					filterable={this.props.filterable}
					onFilteredChange={this.onFilterChanged}
					//defaultPageSize={20}
					defaultPageSize={this.props.showPagination ? 20 : 1000000000}
					defaultSorted={
						this.props.sortHeaderId
							? [
									{
										id: this.props.sortHeaderId,
										desc: this.props.defaultDesc,
									},
							  ]
							: undefined
					}
					defaultFilterMethod={this.getDefaultFilterMethod}
					defaultSortMethod={NaturalSort}
					style={this.props.style}
					sortable={this.props.sortable}
					getNoDataProps={this.getNoDataProps}
					getTrProps={this.props.getTrProps}
					getTdProps={this.getTdProps}
					nextText={localized('Next')}
					previousText={localized('Previous')}
					loadingText={localized('Loading')}
					loading={this.props.loading}
					LoadingComponent={TableLoadingComponent}
					//manual
					//onFetchData={this.props.fetchData}
					//pages={this.props.rowCount ? Math.ceil(this.props.rowCount / 20) : 1}
				/>
			</OuterDiv>
		);
	}

	public setInnerTableRef = (r: any) => {
		this.reactTableRef = r;
	};

	public onFilterChanged = () => {
		if (this.props.handleFilteredDataChanged) {
			this.props.handleFilteredDataChanged();
		}
	};

	public getDefaultFilterMethod = (filter: FilterValue, row: any) => {
		try {
			//let escaped = (filter.value as string).replace('*', '.');
			let regEx = new RegExp(filter.value.toLowerCase());
			let rowValue = row[filter.id];
			if (!isNullOrUndefined(rowValue)) {
				return regEx.test(String(rowValue).toLowerCase());
			}
			return false;
		} catch (error) {
			Monitoring.logException(error as Error);
			return true;
		}
	};

	public getNoDataProps = () => {
		if (!this.props.showNoDataText) {
			return { style: { display: 'none' } };
		}
		return {};
	};

	public getTdProps = () => {
		return {
			style: {
				//background: 'transparent'
			},
		};
	};
}
export default connect(null, mapDispatchToProps)(SkioldTable);

interface LoadingProps {
	loading: boolean;
}

// tslint:disable-next-line:max-classes-per-file
class TableLoadingComponent extends React.PureComponent<LoadingProps> {
	public render() {
		return this.props.loading ? (
			<div className="table-loading-component">
				<SyncLoader color={Colors.yellow} size={20} />
			</div>
		) : null;
	}
}
