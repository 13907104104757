import { Action } from 'redux';
import { mergeArrays } from 'shared/helpers/reducer-helpers';
import { isType } from 'typescript-fsa';
import { getSyncData, saveData, saveDataFromUnitToPen, getProcessEquipmentData } from './actions';
import { ProcessEquipmentDataState } from './types';
import { SyncableInitialState } from 'shared/state/models/syncable';
import { IProcessEquipmentData, ProcessEquipmentData } from 'shared/api/api';
import { siteChange } from '../profile/actions';
import { SharedAppState } from 'shared/state/store.shared';
import { getSyncModelData } from '../sync/actions';

export const initialProcessEquipmentDataState: ProcessEquipmentDataState = {
	...SyncableInitialState,
};

const processEquipmentDataReducer = (
	state: ProcessEquipmentDataState = initialProcessEquipmentDataState,
	action: Action,
): ProcessEquipmentDataState => {
	if (isType(action, siteChange.done)) {
		state = initialProcessEquipmentDataState;
	}

	if (isType(action, saveDataFromUnitToPen.done)) {
	}

	if (isType(action, getSyncData.started)) {
		state = { ...state, syncInProgress: true };
	}

	if (isType(action, getSyncData.done)) {
		if (action.payload.params.siteId === action.payload.params.activeSiteId) {
			if (action.payload.result.datas!.length) {
				state = {
					...state,
					entities: mergeArrays(state.entities, action.payload.result.datas!),
				};
			}

			state = {
				...state,
				lastSyncDate: action.payload.result.syncTime!,
				syncInProgress: false,
			};
		} else {
			state = {
				...state,
				syncInProgress: false,
			};
		}
	}

	if (isType(action, getSyncModelData.done)) {
		if (action.payload.params.siteId === action.payload.params.activeSiteId) {
			if (
				action.payload.result.processEquipmentData &&
				action.payload.result.processEquipmentData.datas!.length
			) {
				state = {
					...state,
					entities: mergeArrays(state.entities, action.payload.result.processEquipmentData.datas!),
					lastSyncDate: action.payload.result.processEquipmentData.syncTime!,
				};
			}

			state = {
				...state,

				syncInProgress: false,
			};
		} else {
			state = {
				...state,
				syncInProgress: false,
			};
		}
	}

	if (isType(action, getSyncData.failed)) {
		state = { ...state, syncInProgress: false };
	}

	if (isType(action, getProcessEquipmentData.done)) {
		if (action.payload.result) {
			state = {
				...state,
				entities: action.payload.result,
			};
		}
	}
	if (isType(action, saveData.done)) {
		if (action.payload.result) {
			state = {
				...state,
				entities: mergeArrays(state.entities, [action.payload.params]),
			};
		}
	}

	if (action.type === 'persist/REHYDRATE') {
		let a = (action as any) as { payload: SharedAppState; key: string };
		if (a.key === 'root') {
			let entities = new Array<IProcessEquipmentData>();
			let updates = new Array<IProcessEquipmentData>();
			let lastSyncDate = new Date(-8640000000000000);
			if (a.payload && a.payload.processEquipmentData && a.payload.processEquipmentData.entities) {
				entities = a.payload.processEquipmentData.entities.map((t: IProcessEquipmentData) => {
					let nt = ProcessEquipmentData.fromJS({});
					nt.init(t);
					return nt;
				});
			}

			if (a.payload && a.payload.processEquipmentData && a.payload.processEquipmentData.updates) {
				updates = a.payload.processEquipmentData.updates.map((t: IProcessEquipmentData) => {
					let nt = ProcessEquipmentData.fromJS({});
					nt.init(t);
					return nt;
				});
			}

			if (a.payload && a.payload.processEquipmentData && a.payload.processEquipmentData.lastSyncDate) {
				lastSyncDate = new Date(a.payload.processEquipmentData.lastSyncDate);
			}

			state = { ...state, entities, updates, lastSyncDate };
		}
	}
	return state;
};

export default processEquipmentDataReducer;
