import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import { StoreContainer } from 'shared/state/store-container';
import * as Action from './actions';

export function GetAnalysisResultPerLitterData(fromDate?: Date, toDate?: Date, breed?: string) {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;

	return AsyncOperationBuilder2(Action.geData, client => client.analysis_ResultPerLitter(siteId, fromDate, toDate, breed), {
		siteId,
		fromDate,
		toDate,
	});
}
