import actionCreatorFactory from 'typescript-fsa';
import * as types from './types';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import { IDistriwinFeedConsumptionDto } from 'shared/api/api';

const actionCreator = actionCreatorFactory(GenActionFactoryName('distriwinFeedConsumptions'));

export const getDistriwinFeedConsumptionDtos = actionCreator.async<
	{ fromDate: Date; toDate: Date; siteId: string },
	IDistriwinFeedConsumptionDto[]
>(types.GET_DISTRIWIN_FEED_CONSUMPTION_DTOS);
