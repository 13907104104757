import { actionCreatorFactory } from 'typescript-fsa';

import * as types from './types';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import { ITreatment, SyncDataTreatment } from 'shared/api/api';

const actionCreator = actionCreatorFactory(GenActionFactoryName('treatments'));

export const saveTreatment = actionCreator.async<
	ITreatment,
	string,
	{ code: number; message: string; prevEntity: ITreatment | undefined }
>(types.SAVE_TREATMENT);
export const createTreatmentList = actionCreator.async<ITreatment[], string[], { code: number; message: string }>(
	types.CREATE_TREATMENTS,
);
export const getTreatmentsForLog = actionCreator.async<{ siteId: string; activeSiteId?: string }, ITreatment[]>(
	types.GET_TREATMENTS_LOG,
);
export const saveLogTreatment = actionCreator.async<
	ITreatment,
	string,
	{ code: number; message: string; prevEntity: ITreatment | undefined }
>(types.SAVE_LOG_TREATMENT);
export const getSyncData = actionCreator.async<
	{ siteId: string; lastSyncDate: Date; activeSiteId?: string },
	SyncDataTreatment
>(types.SYNC_DATA_TREATMENT);
export const removeTreatmentsByAnimal = actionCreator<string>(types.REMOVE_TREATMENTS_BY_ANIMAL);
export const getTreatmentsByStemAnimalId = actionCreator.async<
	{ stemAnimalId: string; siteId: string; activeSiteId?: string },
	ITreatment[]
>(types.GET_TREATMENTS_BY_STEMANIMAL_ID);

export const saveManyTreatments = actionCreator.async<
	{ treatments: ITreatment[]; prevEntities: ITreatment[] },
	string[],
	{ code: number; message: string }
>(types.SAVE_MANY_TREATMENTS);
