import { AuthorizationNotifier, RedirectRequestHandler } from '@openid/appauth';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { PreAuthRedirectLocationKey } from 'shared/constants';
import { getAuthStorageEngine } from 'shared/helpers/auth-storage-helper';
import { Monitoring } from 'shared/helpers/monitoring-service';
import { goToPage } from 'web/state/ducks/navigation/actions';
import { RouteKey } from 'web/state/ducks/navigation/routes';
import { ROUTE_CALLBACK, ROUTE_HOME, ROUTE_SIGN_IN, ROUTE_SIGN_IN_REDIRECT, ROUTE_SIGN_OUT_REDIRECT } from 'web/state/ducks/navigation/types';
import { WebAppState } from 'web/state/store.web';
import { getAccessToken } from 'web/web-helpers/authentication-helper-web';
import { Spinner } from '../../components/spinner/spinner';

const mapStateToProps = (state: WebAppState) => {
	return {};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	navigateToSignin: () => dispatch(goToPage(ROUTE_SIGN_IN)),
	navigateToHome: () => dispatch(goToPage(ROUTE_HOME)),
	navigateTo: (route: RouteKey) => dispatch(goToPage(route))
});

type Props = ReturnType<typeof mapDispatchToProps>;

const notAllowedRoutes = [ROUTE_SIGN_IN, ROUTE_SIGN_IN_REDIRECT, ROUTE_SIGN_OUT_REDIRECT, ROUTE_CALLBACK];

class Callback extends React.PureComponent<Props> {
	private notifier: AuthorizationNotifier;
	private authorizationHandler: RedirectRequestHandler;

	constructor(props: Props) {
		super(props);

		this.notifier = new AuthorizationNotifier();
		this.authorizationHandler = new RedirectRequestHandler();

		this.init();
	}

	public render() {
		return <Spinner />;
	}

	private init() {
		this.authorizationHandler.setAuthorizationNotifier(this.notifier);

		// Set a listener to listen for authorization responses
		this.notifier.setAuthorizationListener(async (request, response, error) => {
			if (response) {
				try {
					await getAccessToken();

					let routeKey = (await getAuthStorageEngine().getItem(PreAuthRedirectLocationKey)) as RouteKey | '' | null;
					if (
						routeKey !== undefined &&
						routeKey !== null &&
						routeKey !== '' &&
						notAllowedRoutes.indexOf(routeKey) === -1
					) {
						this.props.navigateTo(routeKey as RouteKey);
					} else {
						this.props.navigateToHome();
					}

					await getAuthStorageEngine().removeItem(PreAuthRedirectLocationKey);
				} catch (error) {
					Monitoring.logException(error as any);
					this.props.navigateToSignin();
				}
			}
		});

		this.checkForAuthorizationResponse();
	}

	private async checkForAuthorizationResponse() {
		try {
			// Read info from fragment in url and save in localStorage
			await this.authorizationHandler.completeAuthorizationRequestIfPossible();
		} catch (error) {
			Monitoring.logException(error as any);
			this.props.navigateToSignin();
		}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Callback);
