import { AnimalKind } from 'shared/api/api';

export class MovingListTable implements IMovingListTable {
	public isChecked: boolean = false;
	public usesPens: boolean = false;
	public isMoveLocationEsf: boolean = false;
	public animalNumber: string | undefined;
	public stemAnimalId: string | undefined;
	public sectionId: string | undefined;
	public penId: string | undefined;
	public buildingId: string | undefined;
	public cycleDays: number | undefined;
	public negativeCycleDays: number | undefined;
	public ESF: string | undefined;
	public hasRetentionTime: boolean | undefined;
	public fixedDeviation: number | undefined = 100;
	public selectedCurveNumber: number | undefined;
	public transponder: string | undefined;
	public currentPenId: string | undefined; // Is used on Move Preg to preg and Move Mating to Mating
	public identifier: string | undefined;
	public savedFromScanner: boolean = false;
	public animalKind: AnimalKind | undefined;
	public fromPenIsEsfLocation: boolean = false;
	public currentSelectedCurveNumber: number | undefined;
	public currentFixed: number | undefined;
	public order: number | undefined;

	constructor(data?: MovingListTable) {
		if (data) {
			for (let property in data) {
				if (data.hasOwnProperty(property)) {
					this[property as any] = (data as any)[property];
				}
			}
		}
	}
}

// tslint:disable-next-line: interface-name
export interface IMovingListTable {
	isChecked: boolean;
	usesPens: boolean;
	animalNumber: string | undefined;
	stemAnimalId: string | undefined;
	sectionId: string | undefined;
	penId: string | undefined;
	buildingId: string | undefined;
	cycleDays: number | undefined;
	ESF: string | undefined;
	hasRetentionTime: boolean | undefined;
	selectedCurveNumber: number | undefined;
	transponder: string | undefined;
	identifier: string | undefined;
	animalKind: AnimalKind | undefined;
}

export interface MovingListData {
	movingListType: 'MoveToMatingList' | 'MoveToPregnancyList' | 'MoveToFarrowingList';
	movingListData: MovingListTable[];
	headerPenId: string | undefined;
}

export interface MovingWorkListsState {
	MoveToMatingTable: MovingListTable[];
	MoveToPregnancyTable: MovingListTable[];
	MoveToFarrowingTable: MovingListTable[];
	moveToMatingSelectedPen: string | undefined;
	moveToPregnancySelectedPen: string | undefined;
	moveToFarrowingSelectedPen: string | undefined;
}
