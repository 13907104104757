import { SyncNotifcations } from 'shared/api/api';

export const GET_ALARMS = 'GET_ALARMS';

export const GET_WARNINGS = 'GET_WARNINGS';

export const ACK_ALARM = 'ACK_ALARM';

export const ACK_WARNING = 'ACK_WARNING';

export interface NotificationState {
	alarms: SyncNotifcations;
	warnings: SyncNotifcations;
	distriwinTasks: number[];
	isSyncingAlarms: boolean;
	isSyncingWarnings: boolean;
}
