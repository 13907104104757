import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IMatingBatch } from 'shared/api/api';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { DeleteMatingBatchesByDate } from 'shared/state/ducks/mating-batch/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import PageContainer from 'web/view/components/page-container/page-container';
import { showAlert } from 'web/view/components/skiold-alert/skiold-alert';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldDatePicker } from 'web/view/components/skiold-components/skiold-date-picker/skiold-date-picker';
import { Heading } from 'web/view/components/utils/heading';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import './mating-batch-setting.scss';

interface PropsFromParent {
	matingBatches: IMatingBatch[];
	closeModal: () => void;
}

interface State {
	deletionDate: Date;
	validDates: Date[];
}

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	deleteMatingBatchesByDate: (siteId: string, deletionDate: Date) =>
		DeleteMatingBatchesByDate(siteId, deletionDate)(dispatch)
});

const mapStateToProps = (state: WebAppState) => {
	return {
		siteId: state.profile.active!.siteId
	};
};

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

class DeleteMatingBatchComponent extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		let currentDate  =new Date().withoutTime();
		let validDates = props.matingBatches.filter(batch =>  batch.matingPeriodStart && batch.matingPeriodStart > currentDate ).map(batch => batch.matingPeriodStart!)
		this.state = {
			deletionDate: validDates.length > 0 ? validDates[0]: new Date(),
			validDates: validDates
		};
	}

	public render() {
		const now = new Date();
		const matingBatch = this.props.matingBatches.find(
			batch => batch.matingPeriodStart! <= now && batch.matingPeriodEnd! >= now
		);
		return (
			<PageContainer className="delete-mating-batch">
				<Heading text={localized('deleteMatingBatch')} />
				<ViewWeb className="date-picker-container">
					<TextWeb className="date-picker-text">
						{localized('deleteMatingBatchesFromDateAndForward')}
					</TextWeb>
					<SkioldDatePicker
						selectedDate={this.state.deletionDate}
						onDateChanged={this.deletionDateChanged}
						highlightDates={this.state.validDates}
						maxDate={this.state.validDates[this.state.validDates.length - 1]}
						minimumDate={matingBatch && matingBatch.matingPeriodEnd}
						includeTimeStamp={false}
					/>
				</ViewWeb>
				<this.renderButtons />
			</PageContainer>
		);
	}

	private deletionDateChanged = (dateToDelete: Date) => {
		this.setState({ deletionDate: dateToDelete });
	};

	private deleteMatingBatch = () => {
		if (!this.validate()) {
			return;
		}
		if (this.props.siteId) {
			this.props.deleteMatingBatchesByDate(this.props.siteId, this.state.deletionDate);
		}

		this.props.closeModal();
	};

	private validate = () => {
		if (!this.props.siteId) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_SITE_NOT_SET));
			return false;
		}
		if (!this.state.validDates.find(validDate => this.state.deletionDate.getTime() === validDate.getTime())) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_MUST_BE_START_MATING_BATCH));
			return false;
		}
		return true;
	};

	private renderButtons = () => {
		return (
			<ViewWeb className="button-view-style">
				<SkioldButton title={localized('Cancel')} onPress={this.props.closeModal} theme="grey" />
				<SkioldButton title={localized('Delete')} onPress={this.deleteMatingBatch} />
			</ViewWeb>
		);
	};
}

export const DeleteMatingBatch = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
	DeleteMatingBatchComponent
);
