import { Dispatch } from 'redux';
import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import * as Action from './actions';

export const GetUserSitesForCurrentUserAsync = () => {
	return (dispatch: Dispatch<any>) => {
		return AsyncOperationBuilder2(
			Action.getUserSitesForCurrentUserAsync,
			cl => cl.userSite_GetUserSitesForUser(),
			{},
		)(dispatch);
	};
};

export const GetUserSitesForUser = (userId: string) => {
	return AsyncOperationBuilder2(Action.getUserSitesForUser, client => client.userSite_GetUserSitesForUser2(userId), {
		userId,
	});
};

export const RemoveUserSiteAccess = (userId: string, siteIds: string[]) => {
	return AsyncOperationBuilder2(
		Action.removeUserSiteAccess,
		client => client.userSite_RemoveUserSiteAccess(userId, siteIds),
		{ userId, siteIds },
	);
};
