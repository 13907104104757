import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import * as actions from './actions';
import { AnimalReportSettingState } from '.';
import { SyncableInitialState } from 'shared/state/models/syncable';
import { AnimalReportType, AnimalReportSetting, IAnimalReportSetting } from 'shared/api/api';
import { mergeArrays, upsertValueInArray, removeValueFromArray } from 'shared/helpers/reducer-helpers';
import { siteChange } from '../profile/actions';
import { SharedAppState } from 'shared/state/store.shared';
import { NewAnimalReportSetting } from 'shared/helpers/animal-report/animal-report-setting-helper';

export const initialState: AnimalReportSettingState = {
	...SyncableInitialState
};

const animalReportSetttingReducer = (
	state: AnimalReportSettingState = initialState,
	action: Action
): AnimalReportSettingState => {
	if (isType(action, actions.getSyncData.started)) {
		state = { ...state, syncInProgress: true };
	}

	if (isType(action, actions.getSyncData.done)) {
		if (action.payload.params.siteId === action.payload.params.activeSiteId) {
			if (action.payload.result.datas!.length) {
				let unorderedWorkListSettings = action.payload.result.datas!;
				let activeAnimalsReportSetting = unorderedWorkListSettings.find(
					a => a.type === AnimalReportType.ActiveAnimalsReportSetting
				);
				let matingBatchReportSetting = unorderedWorkListSettings.find(
					a => a.type === AnimalReportType.MatingBatchReportSetting
				);
				let sowBoardReportSetting = unorderedWorkListSettings.find(
					a => a.type === AnimalReportType.SowBoardReportSetting
				);
				let esfFeedingReportSetting = unorderedWorkListSettings.find(
					a => a.type === AnimalReportType.FeedingESFReportSetting
				);

				let reportSettings: AnimalReportSetting[] = [];

				if (matingBatchReportSetting !== undefined) {
					reportSettings.push(matingBatchReportSetting);
				}

				if (activeAnimalsReportSetting !== undefined) {
					reportSettings.push(activeAnimalsReportSetting);
				}
				if(esfFeedingReportSetting !== undefined){
					reportSettings.push(esfFeedingReportSetting);
				}
				if (sowBoardReportSetting !== undefined) {
					reportSettings.push(sowBoardReportSetting);
				}

				state = {
					...state,
					entities: mergeArrays(state.entities, reportSettings)
				};
			}
			state = {
				...state,
				lastSyncDate: action.payload.result.syncTime!,
				syncInProgress: false
			};
		} else {
			state = {
				...state,
				syncInProgress: false
			};
		}
	}
	if (isType(action, actions.getSyncData.failed)) {
		state = { ...state, syncInProgress: false };
	}

	if (isType(action, actions.saveAnimalReportSetting.started)) {
		if (action.payload) {
			state = {
				...state,
				updates: upsertValueInArray(state.updates, action.payload),
				entities: upsertValueInArray(state.entities, action.payload)
			};
		}
	}

	if (isType(action, actions.saveAnimalReportSetting.failed)) {
		return state;
	}

	if (isType(action, actions.saveAnimalReportSetting.done)) {
		state = { ...state, updates: removeValueFromArray(state.updates, action.payload.result) };
		return state;
	}

	if (isType(action, siteChange.done)) {
		state = initialState;
	}

	//Ensure Date objects are in fact date and not strings - redux-persist serializes dates to string but don't deserialize to dates again
	if (action.type === 'persist/REHYDRATE') {
		let a = (action as any) as { payload: SharedAppState; key: string };

		if (a.key === 'root') {
			let entities = new Array<AnimalReportSetting>();
			let updates = new Array<AnimalReportSetting>();
			let lastSyncDate = new Date(-8640000000000000);

			if (a.payload && a.payload.animalReportSettings && a.payload.animalReportSettings.entities) {
				entities = [];
				a.payload.animalReportSettings.entities.forEach((dt: IAnimalReportSetting) => {
					let ndt = NewAnimalReportSetting(dt);
					if (ndt) {
						ndt.init(dt);
						entities.push(ndt);
					}
				});
			}

			if (a.payload && a.payload.animalReportSettings && a.payload.animalReportSettings.updates) {
				updates = [];
				a.payload.animalReportSettings.updates.forEach((dt: IAnimalReportSetting) => {
					let ndt = NewAnimalReportSetting(dt);
					if (ndt) {
						ndt.init(dt);
						updates.push(ndt);
					}
				});
			}
			if (a.payload && a.payload.animalReportSettings && a.payload.animalReportSettings.lastSyncDate) {
				lastSyncDate = new Date(a.payload.animalReportSettings.lastSyncDate);
			}

			state = { ...state, entities, updates, lastSyncDate };
		}
	}

	return state;
};

export default animalReportSetttingReducer;
