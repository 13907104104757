import { Option } from 'react-dropdown';
import { Dispatch } from 'redux';
import { AnimalType, IPen, IPoolYoungFemale, IStemAnimal, PoolYoungFemaleShouldDepartureTableItem } from 'shared/api/api';
import { UpsertPoolYoungFemale } from 'shared/state/ducks/pool-young-female/operations';
import { localized, localizedDynamic } from 'shared/state/i18n/i18n';
import { DepartureTypes } from 'shared/state/models/departure-types';
import { SharedAppState } from 'shared/state/store.shared';
import { ExceptionMessage } from '../exception-message';
import { memoizeHashmapLocation, memoizeLocation } from '../memoize-getters/memoize-getters';
import { getReasonNameById } from '../reason-helper/reason-helper';
import { mapTreatmentsRetentionDate } from '../treatment-helper/treatment-helper';
import { getLocationStringByPenIdHashmap, getPenNameByPenId } from '../location-helper';
import { UpdateYoungAnimal } from 'shared/state/ducks/stem-animals/operations';

export const PoolYoungFemaleEventTypes = {
	EntranceEventTypeBought: 'entranceEventTypeBought',
	DepartureTypeSold: DepartureTypes.departureTypeSold,
	DepartureTypeDead: DepartureTypes.departureTypeDead,
	DepartureTypeKilled: DepartureTypes.departureTypeKilled,
	DepartureTypePutDown: DepartureTypes.departureTypePutDown,
	DepartureTypeShouldDeparture: DepartureTypes.departureTypeShouldDeparture,
	ToStemAnimal: 'ToStemAnimal',
	FromFinisher: 'FromFinisher',
	FromWeaner: 'FromWeaner',
};

export const poolYoungFemaleMapStateToProps = (state: SharedAppState, props: PropsFromParent) => {
	const propsFromParent = {
		...(props.navigation && props.navigation.state && props.navigation.state.params),
	} as PropsFromParent;
	return {
		generalSettings: state.generalSettings.entity,
		locations: memoizeLocation(
			state.locations.buildings,
			state.locations.sections,
			state.locations.pens,
			state.locations.valves,
		),
		active: state.profile.active!,
		reasons: state.reasons.entities,
		poolYoungFemales: state.poolYoungFemales.entities,
		language: state.profile.active && state.profile.active.language ? state.profile.active.language : 'en',
		hashmapLocations: memoizeHashmapLocation(
			state.locations.buildings,
			state.locations.sections,
			state.locations.pens,
		),
		...propsFromParent,
	};
};

export const poolYoungFemaleMapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	savePoolYoungFemale: (poolYoungFemale: IPoolYoungFemale) => UpsertPoolYoungFemale(poolYoungFemale)(dispatch),
	updateYoungAnimal: (sow: IStemAnimal) => UpdateYoungAnimal(sow)(dispatch),
});

export interface PropsFromParent {
	closeEditModal?: () => void;
	updateFunction?: (poolYoungFemale?: IPoolYoungFemale) => void;
	poolYoungFemaleId?: string;
	navigation?: any;
	poolYoungFemale?: IPoolYoungFemale;
}

export interface RegisterPoolYoungFemaleState {
	date: Date | undefined;
	sectionId: string | undefined;
	penId: string | undefined;
	totalAmount: number | undefined;
	eventType: Option;
	reasonOption: Option;
	reasonOptions: Option[] | undefined;
	eventTypes: Option[];
	editModel: IPoolYoungFemale | undefined;
	youngAnimal: IStemAnimal | undefined;
	modalIsOpen: boolean;
}

export const DefaultEntranceTypeOption = () => {
	return {
		label: localizedDynamic('entranceEventTypeBought'),
		value: 'entranceEventTypeBought',
	};
};

export function validatePoolYoungFemale(
	state: RegisterPoolYoungFemaleState,
	showAlert: (errorMessage: string) => void,
) {
	if (!state.date) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_DATE_NOT_SET));
		return false;
	}
	if (!state.sectionId) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_STABLE_SECTION_NOT_SET));
		return false;
	}
	if (!state.penId) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_PEN_ID_NOT_SET));
		return false;
	}
	if (!state.totalAmount) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_COUNT_NOT_SET));
		return false;
	}
	if (!state.eventType.value) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_TYPE_NOT_SET));
		return false;
	}

	return true;
}

export type RegisterPoolYoungFemaleProps = ReturnType<typeof poolYoungFemaleMapStateToProps> &
	ReturnType<typeof poolYoungFemaleMapDispatchToProps> &
	PropsFromParent;

export const generatePyfShouldDepartureTableData = (
	pyfs: IPoolYoungFemale[],
	hashMapLocations: {
		[key: string]: any;
	},
	sectionSelected?: string,
) => {
	const tableItems: PoolYoungFemaleShouldDepartureTableItem[] = [];
	const shouldDepart = pyfs.filter(
		pyf =>
			pyf.eventType === DepartureTypes.departureTypeShouldDeparture &&
			(sectionSelected === undefined ||
				(pyf.penId && sectionSelected === (hashMapLocations[pyf.penId] as IPen).sectionId)),
	);

	const rentionDates = mapTreatmentsRetentionDate(AnimalType.YoungFemale);

	shouldDepart.forEach(pyf => {
		let reasonString = getReasonNameById(pyf.departureReasonId);
		tableItems.push({
			poolYoungFemaleId: pyf.id,
			locationString: getLocationStringByPenIdHashmap(pyf.penId, hashMapLocations),
			penString: getPenNameByPenId(pyf.penId, hashMapLocations),
			registrationDate: new Date(),
			pigCount: pyf.total,
			departureType: !reasonString ? DepartureTypes.departureTypeKilled : DepartureTypes.departureTypePutDown,
			reasonString,
			checked: false,
			reasonId: pyf.departureReasonId,
			retentionDate: pyf.penId && rentionDates[pyf.penId],
		} as PoolYoungFemaleShouldDepartureTableItem);
	});

	return tableItems;
};

export const setPyfShouldDeparture = (
	pyfs: IPoolYoungFemale[],
	tableItems: PoolYoungFemaleShouldDepartureTableItem[],
	registrationDate: Date,
) => {
	const checkItems = tableItems.filter(e => e.checked);
	let dataToSave = [] as IPoolYoungFemale[];

	checkItems.forEach(item => {
		const pyf = pyfs.find(g => g.id === item.poolYoungFemaleId);
		if (pyf) {
			dataToSave.push({
				...pyf,
				total: item.pigCount,
				registrationDate: registrationDate,
				departureReasonId: item.reasonId,
				eventType: item.departureType,
			});
		}
	});

	return dataToSave;
};
