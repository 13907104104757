import React from 'react';
import isEqual from 'react-fast-compare';
import { connect } from 'react-redux';
import { GrowthPigsEvent } from 'shared/api/api';
import {
	CheckIfMoveGrowthPigEventIsBetweenTwoDates,
	defaultGrowthPigEventListDates,
	GenerateGrowthPigMoveEventBundleRowItems,
	GrowthPigEventListMapStateToProps,
	GrowthPigEventListMoveMapDispatchToProps,
	GrowthPigMoveEventBundleListRow,
	GrowthPigMoveEventListProps,
	GrowthPigMoveEventListState,
	GrowthPigSummaryBundleRowItem,
} from 'shared/helpers/growth-pigs-helper/growth-pig-event-list-helper';
import { GetByGrowthPigMoveEventsPdfByDates } from 'shared/helpers/growth-pigs-helper/growth-pig-event-pdf-helper';
import { SkioldFetch } from 'web/view/components/skiold-components/skiold-fetch/skiold-fetch';
import { SkioldModal } from 'web/view/components/skiold-components/skiold-modal/skiold-modal';
import { SkioldTableGrid as SkioldTableRef } from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { ViewWeb } from 'web/view/components/utils/web-view';
import GrowthPigEvents from 'web/view/pages/growth-pigs-events/growth-pig-event';
import { renderTopRightGrowthPigMovedEventLists } from '../growth-pig-event-list-web-helper';
import { GrowthPigMoveEventTable } from './growth-pig-move-event-table';
import { GpeMoveFromEditModal } from './growth-pig-move-from-edit-modal';

export class GrowthPigMoveEventList extends React.PureComponent<
	GrowthPigMoveEventListProps,
	GrowthPigMoveEventListState
> {
	private skioldTableRef?: SkioldTableRef = undefined;

	constructor(props: GrowthPigMoveEventListProps) {
		super(props);
		const bundleData = GenerateGrowthPigMoveEventBundleRowItems(
			this.props.growthPigsEvents.filter(this.filterToProductionType),
			this.props.locations,
			this.props.gped,
		);
		this.state = {
			animalType: this.props.productionType,
			bundleData,
			...defaultGrowthPigEventListDates(),
		};
	}

	private filterToProductionType = (gpe: GrowthPigsEvent) =>
		gpe.toProductionType === this.props.productionType || gpe.fromProductionType === this.props.productionType;

	public componentDidUpdate(prevProps: GrowthPigMoveEventListProps, prevState: GrowthPigMoveEventListState) {
		if (
			!isEqual(prevProps.growthPigsEvents, this.props.growthPigsEvents) ||
			!isEqual(prevProps.productionType, this.props.productionType)
		) {
			const updatedData = GenerateGrowthPigMoveEventBundleRowItems(
				this.props.growthPigsEvents.filter(
					CheckIfMoveGrowthPigEventIsBetweenTwoDates(this.props, this.props.productionType),
				),
				this.props.locations,
				this.props.gped,
			);

			this.setState({
				bundleData: updatedData,
			});
		}
		if (!isEqual(prevState.bundleData, this.state.bundleData)) {
			if (this.props.topRight && this.state.bundleData) {
				this.props.topRight(
					renderTopRightGrowthPigMovedEventLists(
						this.state.bundleData,
						this.printData,
						this.state.animalType,
					),
				);
			}
		}
	}

	public render() {
		return (
			<ViewWeb>
				<GrowthPigMoveEventTable
					animalType={this.props.productionType}
					onFilterChanged={this.onFilterChanged}
					setRef={this.setRef}
					openEditModal={this.openEditModal}
					removeItem={this.props.removeGrowthPigsEvent}
					bundleData={this.state.bundleData}
				/>
				<SkioldModal
					shouldCloseOnOverlayClick={true}
					padding="0"
					isOpen={!!this.state.bundleEvent}
					close={this.closeModal}
					justify-content="flex-end"
					max-width="calc(100% - 220px)"
				>
					{this.state.bundleEvent && this.state.bundleEvent.bundleId ? (
						<GpeMoveFromEditModal closeModal={this.closeModal} bundle={this.state.bundleEvent} />
					) : (
						<GrowthPigEvents closeEditModal={this.closeModal} event={this.state.event} />
					)}
				</SkioldModal>
			</ViewWeb>
		);
	}

	private closeModal = () => {
		this.setState({ bundleEvent: undefined, event: undefined });
	};

	private onFilterChanged = () => {
		const data = this.skioldTableRef
			? (this.skioldTableRef.GetSortedData() as GrowthPigMoveEventBundleListRow[])
			: this.state.bundleData
			? this.state.bundleData
			: [];
		if (this.props.topRight) {
			this.props.topRight(renderTopRightGrowthPigMovedEventLists(data, this.printData, this.state.animalType));
		}
	};

	private openEditModal = (event: GrowthPigSummaryBundleRowItem) => {
		let gpeEvent;

		if (event && !event.bundleId && event.gpeIds.length === 1) {
			gpeEvent = this.props.growthPigsEvents.find(gpe => gpe.id === event.gpeIds[0]);
		}

		this.setState({
			bundleEvent: this.state.bundleData && this.state.bundleData.find(gpe => gpe.bundleId === event.bundleId),
			event: gpeEvent,
		});
	};

	private setRef = ref => {
		this.skioldTableRef = ref;
	};

	public componentDidMount() {
		if (this.props.topRight && this.props.topLeft && this.state.bundleData) {
			this.props.topRight(
				renderTopRightGrowthPigMovedEventLists(this.state.bundleData, this.printData, this.state.animalType),
			);
			this.props.topLeft(this.renderTopLeft());
		}
	}

	public renderTopLeft = () => {
		return <SkioldFetch toDate={this.state.dateTo} fromDate={this.state.dateFrom} fetchData={this.fetchData} />;
	};

	private printData = async () => {
		if (this.props.profile) {
			await GetByGrowthPigMoveEventsPdfByDates(
				this.skioldTableRef
					? (this.skioldTableRef.GetSortedData() as GrowthPigMoveEventBundleListRow[])
							.map(row => row.gpeIds)
							.reduce((totalIds, ids) => (totalIds ? totalIds.concat(ids) : ids))
					: [],
				'GrowthPigDepartureEvents.pdf',
				this.props.profile.siteId!,
				this.props.productionType,
				Intl.DateTimeFormat().resolvedOptions().timeZone,
				this.props.profile.language,
			);
		}
	};
	private fetchData = (dateFrom: Date, dateTo: Date) => {
		this.setState({ dateFrom: dateFrom, dateTo: dateTo });
		this.props.getGrowthPigsMoveEventsByDates(dateFrom, dateTo);
	};
}

export default connect(
	GrowthPigEventListMapStateToProps,
	GrowthPigEventListMoveMapDispatchToProps,
)(GrowthPigMoveEventList);
