import { Action } from 'redux';
import * as actions from './actions';
import { LocationsState } from './types';
import { AsyncActionCreators, isType } from 'typescript-fsa';
import { SyncableSingleInitialState } from 'shared/state/models/syncable';
import { siteChange } from '../profile/actions';
import {
	mergeArrays,
	removeMultipleValuesFromArray,
	removeValueFromArray,
	upsertValueInArray,
} from 'shared/helpers/reducer-helpers';
import { Building, IBuilding, IPen, ISection, IValve, Pen, Section, Valve } from 'shared/api/api';
import { SharedAppState } from 'shared/state/store.shared';
import { getSyncModelData } from '../sync/actions';

export const initialLocationState: LocationsState = {
	...SyncableSingleInitialState,
	buildings: [],
	sections: [],
	pens: [],
	valves: [],
	updateBuildings: [],
	updateSections: [],
	updatePens: [],
	updateValves: [],
	currentLocation: { locationString: '', penId: '', sectionId: '' },
	useCurrentLocation: false,
};

const locationsReducer = (state: LocationsState = initialLocationState, action: Action): LocationsState => {
	if (isType(action, siteChange.done)) {
		state = initialLocationState;
	}

	if (isType(action, actions.load.started)) {
		state = { ...state, syncInProgress: true };
	}

	if (isType(action, getSyncModelData.done)) {
		if (action.payload.params.siteId === action.payload.params.activeSiteId) {
			if (action.payload.result.locations && action.payload.result.locations.data) {
				if (
					action.payload.result.locations.data.buildings &&
					action.payload.result.locations.data.buildings.length > 0
				) {
					state = {
						...state,
						buildings: mergeArrays(state.buildings!, action.payload.result.locations.data!.buildings!),
					};
				}
				if (
					action.payload.result.locations.data.sections &&
					action.payload.result.locations.data.sections.length > 0
				) {
					state = {
						...state,
						sections: mergeArrays(state.sections!, action.payload.result.locations.data!.sections!),
					};
				}
				if (action.payload.result.locations.data.pens && action.payload.result.locations.data.pens.length > 0) {
					state = {
						...state,
						pens: mergeArrays(state.pens!, action.payload.result.locations.data!.pens!),
					};
				}
				if (
					action.payload.result.locations.data.valves &&
					action.payload.result.locations.data.valves.length > 0
				) {
					state = {
						...state,
						valves: mergeArrays(state.valves!, action.payload.result.locations.data!.valves!),
					};
				}

				state = {
					...state,
					lastSyncDate: action.payload.result.locations.syncTime!,
					syncInProgress: false,
				};
			}
		} else {
			state = {
				...state,
				syncInProgress: false,
			};
		}
	}

	if (isType(action, actions.load.done)) {
		if (action.payload.params.siteId === action.payload.params.activeSiteId) {
			if (action.payload.result.data) {
				if (action.payload.result.data.buildings && action.payload.result.data.buildings.length > 0) {
					state = {
						...state,
						buildings: mergeArrays(state.buildings!, action.payload.result.data!.buildings!),
					};
				}
				if (action.payload.result.data.sections && action.payload.result.data.sections.length > 0) {
					state = {
						...state,
						sections: mergeArrays(state.sections!, action.payload.result.data!.sections!),
					};
				}
				if (action.payload.result.data.pens && action.payload.result.data.pens.length > 0) {
					state = {
						...state,
						pens: mergeArrays(state.pens!, action.payload.result.data!.pens!),
					};
				}
				if (action.payload.result.data.valves && action.payload.result.data.valves.length > 0) {
					state = {
						...state,
						valves: mergeArrays(state.valves!, action.payload.result.data!.valves!),
					};
				}

				state = {
					...state,
					lastSyncDate: action.payload.result.syncTime!,
					syncInProgress: false,
				};
			} else {
				state = {
					...state,
					syncInProgress: false,
				};
			}
		} else {
			state = {
				...state,
				syncInProgress: false,
			};
		}
	}

	if (isType(action, actions.load.failed)) {
		state = { ...state, syncInProgress: false };
	}

	// Handle all upserts in location arrays
	const buildingsHandle = handleBuilding(action, actions.upsertBuilding, state.buildings!);
	if (buildingsHandle) {
		state = { ...state, buildings: buildingsHandle };
	}

	const sectionsHandle = handleBuilding(action, actions.upsertSection, state.sections!);
	if (sectionsHandle) {
		state = { ...state, sections: sectionsHandle };
	}

	const pensHandle = handleBuilding(action, actions.upsertPen, state.pens!);
	if (pensHandle) {
		state = { ...state, pens: pensHandle };
	}

	const valvesHandle = handleBuilding(action, actions.upsertValve, state.valves!);
	if (valvesHandle) {
		state = { ...state, valves: valvesHandle };
	}

	// Handle all update arrays
	const buildingsUpdates = handleUpdates(action, actions.upsertBuilding, state.buildings!);
	if (buildingsUpdates) {
		state = { ...state, updateBuildings: buildingsUpdates };
	}

	const sectionsUpdates = handleUpdates(action, actions.upsertSection, state.sections!);
	if (sectionsUpdates) {
		state = { ...state, updateSections: sectionsUpdates };
	}

	const pensUpdates = handleUpdates(action, actions.upsertPen, state.pens!);
	if (pensUpdates) {
		state = { ...state, updatePens: pensUpdates };
	}

	const valvesUpdates = handleUpdates(action, actions.upsertValve, state.valves!);
	if (valvesUpdates) {
		state = { ...state, updateValves: valvesUpdates };
	}

	if (isType(action, actions.removeBuilding.done)) {
		return { ...state, buildings: removeFromList(action.payload.params, state.buildings!, state) };
	} else if (isType(action, actions.removeSection.done)) {
		return { ...state, sections: removeFromList(action.payload.params, state.sections!, state) };
	} else if (isType(action, actions.removePen.done)) {
		return { ...state, pens: removeFromList(action.payload.params, state.pens!, state) };
	} else if (isType(action, actions.removeValve.done)) {
		return { ...state, valves: removeFromList(action.payload.params, state.valves!, state) };
	}
	if (isType(action, actions.setCurrentLocation.done)) {
		// Don't set current location if not enabled
		if (state.useCurrentLocation) {
			const currentLocation = action.payload.result;
			const result = { ...state, currentLocation };
			return result;
		}
	}

	if (isType(action, actions.setUseCurrentLocation)) {
		const result = { ...state, useCurrentLocation: action.payload };
		return result;
	}

	if (isType(action, actions.setRetentionTimeOnPens.started)) {
		if (action.payload && action.payload.length > 0) {
			return {
				...state,
				pens: mergeArrays(state.pens, action.payload),
				updatePens: mergeArrays(state.updatePens, action.payload),
			};
		}
	}

	if (isType(action, actions.setRetentionTimeOnPens.done)) {
		if (action.payload.params && action.payload.params.length > 0) {
			return {
				...state,
				updatePens: removeMultipleValuesFromArray(
					state.updatePens,
					action.payload.params.map(p => p.id!),
				),
			};
		}
	}

	//Ensure Date objects are in fact date and not strings - redux-persist serializes dates to string but doesn't deserialize to dates again
	if (action.type === 'persist/REHYDRATE') {
		let a = (action as any) as { payload: SharedAppState; key: string };

		if (a.key === 'root') {
			let buildings = new Array<Building>();
			let sections = new Array<Section>();
			let pens = new Array<Pen>();
			let updateBuildings = new Array<Building>();
			let updateSections = new Array<Section>();
			let updatePens = new Array<Pen>();
			let lastSyncDate = new Date(-8640000000000000);
			let valves = new Array<Valve>();

			if (a.payload && a.payload.locations && a.payload.locations.buildings) {
				buildings = a.payload.locations.buildings.map((dt: IBuilding) => {
					let ndt = Building.fromJS({});
					ndt.init(dt);
					return ndt;
				});
			}

			if (a.payload && a.payload.locations && a.payload.locations.sections) {
				sections = a.payload.locations.sections.map((dt: ISection) => {
					let ndt = Section.fromJS({});
					ndt.init(dt);
					return ndt;
				});
			}

			if (a.payload && a.payload.locations && a.payload.locations.pens) {
				pens = a.payload.locations.pens.map((dt: IPen) => {
					let ndt = Pen.fromJS({});
					ndt.init(dt);
					return ndt;
				});
			}

			if (a.payload && a.payload.locations && a.payload.locations.valves) {
				valves = a.payload.locations.valves.map((dt: IValve) => {
					let ndt = new Valve();
					ndt.init(dt);
					return ndt;
				});
			}

			if (a.payload && a.payload.locations && a.payload.locations.updateBuildings) {
				updateBuildings = a.payload.locations.updateBuildings.map((dt: IBuilding) => {
					let ndt = Building.fromJS({});
					ndt.init(dt);
					return ndt;
				});
			}

			if (a.payload && a.payload.locations && a.payload.locations.updateSections) {
				updateSections = a.payload.locations.updateSections.map((dt: ISection) => {
					let ndt = Section.fromJS({});
					ndt.init(dt);
					return ndt;
				});
			}

			if (a.payload && a.payload.locations && a.payload.locations.updatePens) {
				updatePens = a.payload.locations.updatePens.map((dt: IPen) => {
					let ndt = Pen.fromJS({});
					ndt.init(dt);
					return ndt;
				});
			}

			if (a.payload && a.payload.locations && a.payload.locations.lastSyncDate) {
				lastSyncDate = new Date(a.payload.locations.lastSyncDate);
			}

			state = {
				...state,
				buildings,
				sections,
				pens,
				updateBuildings,
				updateSections,
				updatePens,
				lastSyncDate,
				valves,
			};
		}
	}

	return state;
};

function handleBuilding<
	P extends { id?: string; isLoading?: boolean },
	R extends string,
	E extends { code: number; message: string; prevEntity: P }
>(action: Action, asyncAct: AsyncActionCreators<P, R, E>, arr: P[]) {
	if (isType(action, asyncAct.started)) {
		if (action.payload.id) {
			action.payload.isLoading = true;
			const buildings = upsertValueInArray(arr, action.payload);
			return buildings;
		} else {
			return mergeArrays(arr, [action.payload]);
		}
	} else if (isType(action, asyncAct.done)) {
		action.payload.params!.id = action.payload.result!;
		const index = arr.findIndex(x => x.id === action.payload.result!);
		const buildings = [...arr];
		buildings[index] = { ...(buildings[index] as any), isLoading: false };
		return buildings;
	} else if (isType(action, asyncAct.failed)) {
		const buildings = [...arr];
		if (action.payload.error) {
			if (action.payload.error.prevEntity) {
				return upsertValueInArray(buildings, action.payload.error.prevEntity);
			} else {
				return removeValueFromArray(buildings, action.payload.params!.id!);
			}
		}
		return buildings;
	}
	return null;
}

function handleUpdates<
	P extends { id?: string; isLoading?: boolean },
	R extends string,
	E extends { code: number; message: string; prevEntity: P }
>(action: Action, asyncAct: AsyncActionCreators<P, R, E>, arr: P[]) {
	if (isType(action, asyncAct.started)) {
		const updates = upsertValueInArray(arr, action.payload);
		return updates;
	} else if (isType(action, asyncAct.done)) {
		const updates = removeValueFromArray(arr, action.payload.result!);
		return updates;
	} else if (isType(action, asyncAct.failed)) {
		if (action.payload.error) {
			const updates = removeValueFromArray(arr, action.payload.params!.id!);
			return updates;
		}
	}
	return null;
}

function removeFromList<T extends { id?: string }>(id: string, arr: T[], state: LocationsState): T[] {
	const index = arr.findIndex(x => x.id === id);
	if (index === -1) {
		return arr;
	}
	const arra = [...arr];
	arra.splice(index, 1);
	return arra;
}

export default locationsReducer;
