import { LocationType, AnimalType } from "shared/api/api";


export class SectionFormData {
    public nameBehindStartNumber: string | undefined;
    public startNumber: number | undefined;
    public numOfSections: number | undefined;
    public UsePens: boolean = true;
    public selectedLocationType: LocationType | undefined;
    public selectedAnimalType: AnimalType | undefined;
}
