import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { getDateString } from 'shared/helpers/date-helpers';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { WhiteText } from '../../Text/white-text';
import { ViewWeb } from '../../utils/web-view';

interface PropsFromParent {
	lastBalanced: Date;
}

export const TotalSummaryEntrance: FC<PropsFromParent> = React.memo(({ lastBalanced }) => {
	const { summaryItems } = useSelector((state: WebAppState) => state.dashboardGrowthPigsOverview);

	const getSummaryString = () => {
		let entranceString = '';
		summaryItems['DashboardGpeEntrance'] &&
			summaryItems['DashboardGpeEntrance'].forEach(column => {
				if (column.columnKey === 'pigCount') {
					entranceString += `${localized('enteredSince')} ${getDateString(lastBalanced)}: ${
						column.columnValue
					}\u00A0\u00A0\u00A0\u00A0`;
				} else {
					entranceString += `${localized(column.columnKey)}: ${column.columnValue}`;
				}
			});
		return entranceString;
	};
	return (
		<ViewWeb className="totalSummary">
			<WhiteText>{getSummaryString()}</WhiteText>
		</ViewWeb>
	);
});
