import { IEsfFeedCurve, IProcessEquipmentData } from 'shared/api/api';
import { Option } from 'react-dropdown';
import { ExtractLocationCode } from './process-equipment-helper/process-equipment-helper';
import { StoreContainer } from 'shared/state/store-container';

export function generateFeedCurveOptions(availableCurves: IEsfFeedCurve[]) {
	let options: Option[] = [];

	options = availableCurves.map(curve => {
		return {
			label: curve.feedCurveName
				? `${curve.feedCurveNumber} - ${curve.feedCurveName}`
				: curve.feedCurveNumber !== undefined && curve.feedCurveNumber !== null
				? curve.feedCurveNumber.toString()
				: '',
			value:
				curve.feedCurveNumber !== undefined && curve.feedCurveNumber !== null
					? curve.feedCurveNumber.toString()
					: '',
		} as Option;
	});

	options.unshift({ label: ' ', value: '' });

	return options;
}

export function generateFeedCurveOptionsByLocation(
	availableCurves: IEsfFeedCurve[],
	peq: IProcessEquipmentData | undefined,
) {
	let options: Option[] = [];

	if (peq) {
		options = availableCurves
			.filter(
				curve =>
					curve.feedCurveName && curve.locationCode && curve.locationCode === ExtractLocationCode(peq.code),
			)
			.map(curve => {
				return {
					label: curve.feedCurveName
						? `${curve.feedCurveNumber} - ${curve.feedCurveName}`
						: curve.feedCurveNumber !== undefined && curve.feedCurveNumber !== null
						? curve.feedCurveNumber.toString()
						: '',
					value:
						curve.feedCurveNumber !== undefined && curve.feedCurveNumber !== null
							? curve.locationCode + '-' + curve.feedCurveNumber.toString()
							: '',
				} as Option;
			});
	}

	options.unshift({ label: ' ', value: '' });

	return options;
}

export function generateFeedCurveOptionsWithLocationCode(availableCurves: IEsfFeedCurve[]) {
	let options: Option[] = [];

	options = availableCurves.map(curve => {
		return {
			label: curve.feedCurveName
				? `${curve.feedCurveNumber} - ${curve.feedCurveName}`
				: curve.feedCurveNumber !== undefined && curve.feedCurveNumber !== null
				? curve.feedCurveNumber.toString()
				: '',
			value:
				curve.feedCurveNumber !== undefined && curve.feedCurveNumber !== null
					? curve.locationCode + '-' + curve.feedCurveNumber.toString()
					: '',
		} as Option;
	});

	options.unshift({ label: ' ', value: '' });

	return options;
}

export const splitFeedCurveOption = (option: Option) => {
	if (option) {
		let splitValue = option.value.split('-');
		return splitValue.length > 1 ? splitValue[1] : undefined;
	}
	return undefined;
};

export const getFeedCurvesByPenId = (penId: string | undefined) => {
	if (penId) {
		const state = StoreContainer.getState();
		const utp = state.unitToPenData.data.find(p => p.penId === penId);
		const processEquipmentData = state.processEquipmentData.entities.find(
			peq => utp && peq.id === utp.processEquipmentDataId,
		);
		if (processEquipmentData) {
			return state.esfStatus.availableCurves.filter(
				curve =>
					curve.feedCurveName &&
					curve.locationCode &&
					curve.locationCode === ExtractLocationCode(processEquipmentData.code),
			);
		}
	}

	return [];
};
