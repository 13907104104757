import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { ViewWeb } from '../../../../components/utils/web-view';
import { localized } from '../../../../../../shared/state/i18n/i18n';
import './nucleus-management-fetch-setup.scss';
import { SkioldFormsWrapper } from '../../../../components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from '../../../../components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import {
	getDayOfWeekOptions,
	getFrequencyOptions,
	getProvider,
	shouldDisableTime,
	timePickerFTextStyle,
} from '../nucleus-management-helper';
import { useDispatch, useSelector } from 'react-redux';
import { WebAppState } from '../../../../../state/store.web';
import { Option } from 'react-dropdown';
import {
	DayOfWeek,
	NucleusManagementFetchSetup,
	NucleusManagementFrequency,
	NucleusManagementProvider,
} from '../../../../../../shared/api/api';
import { SkioldButton } from '../../../../components/skiold-components/skiold-button/skiold-button';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import { SkioldCheckbox } from '../../../../components/skiold-components/skiold-checkbox/skiold-checkbox';
import { SkioldFormInput } from '../../../../components/skiold-components/skiold-input/skiold-form-input';
import {
	getConnections,
	updateNucleusManagementFetchSettings,
} from '../../../../../../shared/state/ducks/nucleus-management-connections/operation';
import moment from 'moment';
import { selectActiveSite } from '../../../../../../shared/state/ducks/site/reducer';
import { SkioldFormDropdown } from 'web/view/components/skiold-components/skiold-dropdown/skiold-form-dropdown';

export const NucleusManagementFetchSetupForm: FC = React.memo(() => {
	const dispatch = useDispatch();

	const activeSite = useSelector(selectActiveSite);
	const provider = useMemo(() => getProvider(activeSite.activeLicences), [activeSite]);
	const managementConnection = useSelector((state: WebAppState) =>
		state.nucleusManagementConnections.connections.find(c => c.provider === provider),
	);

	const [activeFetchFrequencyOption, setActiveFetchFrequencyOption] = useState<Option>();
	const [activeDayOfWeekOption, setActiveDayOfWeekOption] = useState<Option>();
	const [timeOfDay, setTimeOfDay] = useState<Date | null>(null);
	const [indexAnimals, setIndexAnimals] = useState(false);
	const [manageHerds, setManageHerds] = useState('');

	useEffect(() => {
		if (activeSite.id === undefined) {
			return;
		}

		dispatch(getConnections(activeSite.id));
	}, [activeSite, dispatch]);

	const [dayOfWeekOptions] = useState<Option[]>(getDayOfWeekOptions());
	const [frequencyOptions] = useState<Option[]>(getFrequencyOptions().filter(o => o.value !== 'Manual'));

	useEffect(() => {
		const fetchSetup = managementConnection?.fetchSetup;
		if (fetchSetup) {
			setActiveFetchFrequencyOption(frequencyOptions.find(o => o.value === fetchSetup.frequency));
			setActiveDayOfWeekOption(dayOfWeekOptions.find(o => o.value === fetchSetup.dayOfWeek));
			setTimeOfDay(moment(fetchSetup.timeOfDay ?? '', 'HH:mm:ss').toDate());
			setIndexAnimals(fetchSetup.updateIndexes ?? false);
			setManageHerds(fetchSetup.herdsToInclude ?? '');
		}
	}, [managementConnection, dayOfWeekOptions, frequencyOptions]);

	const dataFetchOptionsChanged = useCallback(
		(newValue: Option) => {
			const option = frequencyOptions.find(option => option.value === newValue.value);
			setActiveFetchFrequencyOption(option);
		},
		[frequencyOptions],
	);

	const dayOfWeekChanged = useCallback(
		(newValue: Option) => {
			const option = dayOfWeekOptions.find(option => option.value === newValue.value);
			setActiveDayOfWeekOption(option);
		},
		[dayOfWeekOptions],
	);

	const handleCheckbox = useCallback(() => {
		setIndexAnimals(!indexAnimals);
	}, [indexAnimals]);

	const handleManageHerdsChanged = useCallback((newText: string) => {
		setManageHerds(newText);
	}, []);

	const saveFetchSettings = useCallback(() => {
		if (
			(activeDayOfWeekOption?.value === undefined &&
				(activeFetchFrequencyOption?.value === NucleusManagementFrequency.Weekly ||
					activeFetchFrequencyOption?.value === NucleusManagementFrequency.Monthly)) ||
			activeFetchFrequencyOption?.value === undefined ||
			timeOfDay === null ||
			managementConnection === undefined ||
			manageHerds === ''
		) {
			alert(localized('NotAllInfoAlert'));
			return;
		}

		// This regex checks for up to 10 comma seperated blocks of 1-4 chars
		if (!manageHerds.match('^[^,]{1,4}(?:,[^,]{1,4}){0,9}$')) {
			alert(localized('InvalidHerdsAlert'));
			return;
		}

		managementConnection.fetchSetup = NucleusManagementFetchSetup.fromJS({
			frequency: NucleusManagementFrequency[activeFetchFrequencyOption.value],
			dayOfWeek: activeDayOfWeekOption?.value === undefined ? undefined : DayOfWeek[activeDayOfWeekOption.value],
			timeOfDay: timeOfDay,
			herdsToInclude: manageHerds,
			updateIndexes: indexAnimals,
		});

		dispatch(updateNucleusManagementFetchSettings(managementConnection));
	}, [
		activeDayOfWeekOption,
		activeFetchFrequencyOption,
		manageHerds,
		timeOfDay,
		indexAnimals,
		managementConnection,
		dispatch,
	]);

	const getFormRows = useCallback(() => {
		const formRows: FormRow[] = [
			{ header: localized('FetchData') },
			{
				name: localized('Provider'),
				component: <TextWeb>{provider}</TextWeb>,
			},
			{
				name: localized('DataFetching'),
				component: (
					<SkioldFormDropdown
						items={frequencyOptions}
						selectedValue={activeFetchFrequencyOption}
						onValueChanged={dataFetchOptionsChanged}
					/>
				),
			},
		];
		if (activeFetchFrequencyOption?.value !== NucleusManagementFrequency.Daily) {
			formRows.push({
				name: localized('Weekday'),
				component: (
					<SkioldFormDropdown
						items={dayOfWeekOptions}
						selectedValue={activeDayOfWeekOption}
						onValueChanged={dayOfWeekChanged}
					/>
				),
			});
		}
		formRows.push(
			{
				name: localized('Time'),
				component: (
					<TimePicker
						value={timeOfDay}
						ampm={false}
						onChange={newValue => setTimeOfDay(newValue)}
						renderInput={params => <TextField {...params} variant={'filled'} sx={timePickerFTextStyle} />}
						shouldDisableTime={shouldDisableTime}
					/>
				),
			},
			{
				name: localized('IndexAnimals'),
				component: (
					<ViewWeb className={'checkbox-container'}>
						<SkioldCheckbox isChecked={indexAnimals} onClick={handleCheckbox} />
					</ViewWeb>
				),
			},
			{
				name: localized('KsBoarHerds'),
				component: <SkioldFormInput text={manageHerds} onChangeText={handleManageHerdsChanged} />,
			},
		);

		return formRows;
	}, [
		activeDayOfWeekOption,
		activeFetchFrequencyOption,
		dataFetchOptionsChanged,
		dayOfWeekChanged,
		handleCheckbox,
		handleManageHerdsChanged,
		indexAnimals,
		manageHerds,
		provider,
		timeOfDay,
		dayOfWeekOptions,
		frequencyOptions,
	]);

	if (provider === NucleusManagementProvider.SelfManaged || provider === 'NoNucleusManagementLicence') {
		return (
			<ViewWeb className={'fetch-settings-container'}>
				<TextWeb className={'title'}>{localized('NoNucleusManagementLicence')}</TextWeb>
			</ViewWeb>
		);
	}

	if (managementConnection === undefined) {
		return (
			<ViewWeb className={'fetch-settings-container'}>
				<TextWeb className={'title'}>{localized('CreateConnectionFirstError')}</TextWeb>
			</ViewWeb>
		);
	}

	return (
		<LocalizationProvider dateAdapter={AdapterMoment}>
			<ViewWeb className={'fetch-settings-container'}>
				<TextWeb className={'title'}>{localized('Setup')}</TextWeb>
				<SkioldFormsWrapper formRows={getFormRows()} containerClassName={'form-container'} />
				<SkioldButton title={localized('Save')} onPress={saveFetchSettings} className={'button-container'} />
			</ViewWeb>
		</LocalizationProvider>
	);
});
