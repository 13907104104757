import memoize from 'memoize-one';
import React from 'react';
import { connect } from 'react-redux';
import { ILardScanningEvent, IPregnancyEvent, IStemAnimal } from 'shared/api/api';
import DeleteIcon from 'shared/assets/src-assets/png/delete_icon.png';
import {
	GenerateSowCardLardWidthsData,
	SowCardLardScanningEventListTableItem,
	sowCardLardWidthsMapDispatchToProps,
	sowCardLardWidthsMapStateToProps,
	SowCardLardWidthsProps,
	SowCardLardWidthsState
} from 'shared/helpers/sow-card-helper/sow-card-lard-events-helper';
import { localized } from 'shared/state/i18n/i18n';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import SkioldTableGrid from '../../skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { SkioldTouchableOpacity } from '../../skiold-components/skiold-touchable-opacity';
import { SkioldImage } from '../../utils/svg/skiold-image';
import { SowListConstants } from '../animal-lists/table-constants';
import './sow-card-modals.scss';
import { Heading } from '../../utils/heading';
import { getDateString } from 'shared/helpers/date-helpers';
import { SkioldButton } from '../../skiold-components/skiold-button/skiold-button';

class SowCardLardEvents extends React.PureComponent<SowCardLardWidthsProps, SowCardLardWidthsState> {
	constructor(props: SowCardLardWidthsProps) {
		super(props);

		this.state = {};
	}

	public render() {
		return (
			<ViewWeb>
				<Heading text={localized('sowLardWidth')} />
				<ViewWeb className="sow-card-modals">
					{this.renderTable()}
					{this.props.closeModal && (
						<SkioldButton
							className="btn-container"
							title={localized('Close')}
							onPress={this.props.closeModal}
							theme="grey"
						/>
					)}
				</ViewWeb>
				
			</ViewWeb>
		);
	}

	private generateData = memoize(
		(
			sow: IStemAnimal | undefined,
			lardScanningEvents: ILardScanningEvent[],
			pregnancyEvents: { [key: string]: IPregnancyEvent[] }
		) => {
			return GenerateSowCardLardWidthsData(sow, lardScanningEvents, pregnancyEvents);
		}
	);

	public renderTable() {
		return (
			<SkioldTableGrid
				data={this.generateData(this.props.sow, this.props.lardScanningEvents, this.props.pregnancyEvents)}
				columns={this.generateColumns}
				ColumnExtensions={this.getColumnExtenstions}
				filterable={false}
				tableKey={'sowCardLardWidths'}
			/>
		);
	}

	private getColumnExtenstions = [
		{
			columnName: 'animalNumber',
			width: SowListConstants.dateWidth
		},
		{
			columnName: 'litter',
			width: SowListConstants.dateWidth
		},
		{
			columnName: 'date',
			width: SowListConstants.dateWidth
		},
		{
			columnName: 'lardWidth',
			width: SowListConstants.dateWidth
		},
		{
			columnName: 'lardWeight',
			width: SowListConstants.dateWidth
		},
		{
			columnName: 'delete',
			width: SowListConstants.iconWidth
		}
	];

	private generateColumns = [
		{
			name: 'animalNumber',
			title: localized('animalNumber'),
			sortable: false,
			filterable: false,
			getCellValue: (item: SowCardLardScanningEventListTableItem) => <TextWeb>{item.animalNumber}</TextWeb>
		},
		{
			name: 'litter',
			title: localized('Litters'),
			sortable: false,
			filterable: false,
			getCellValue: (item: SowCardLardScanningEventListTableItem) => <TextWeb>{item.litter}</TextWeb>
		},
		{
			name: 'date',
			title: localized('Date'),
			sortable: false,
			filterable: false,
			getCellValue: (item: SowCardLardScanningEventListTableItem) => <TextWeb>{getDateString(item.date)}</TextWeb>
		},
		{
			name: 'lardWidth',
			title: localized('sowLardWidth'),
			sortable: false,
			filterable: false,
			getCellValue: (item: SowCardLardScanningEventListTableItem) => <TextWeb>{item.LardWidth}</TextWeb>
		},
		{
			name: 'lardWeight',
			title: localized('Weight'),
			sortable: false,
			filterable: false,
			getCellValue: (item: SowCardLardScanningEventListTableItem) => <TextWeb>{item.weight}</TextWeb>
		},
		{
			name: 'delete',
			title: ' ',
			sortable: false,
			filterable: false,
			getCellValue: (item: SowCardLardScanningEventListTableItem) => (
				<SkioldTouchableOpacity
					onPress={() => {
						this.deleteLardScanningEvent(item.id!);
					}}
				>
					<SkioldImage
						width={SowListConstants.iconSVGWidth}
						height={SowListConstants.iconSVGWidth}
						imageData={DeleteIcon}
					/>
				</SkioldTouchableOpacity>
			)
		}
	];
	private deleteLardScanningEvent = (lardId: string) => {
		let lardEvent = this.props.lardScanningEvents.find(lse => lse.id === lardId);
		if (lardEvent) {
			lardEvent.isDeleted = true;
			this.props.saveLardScanningEvent(lardEvent);
		}
	};
}

export default connect(sowCardLardWidthsMapStateToProps, sowCardLardWidthsMapDispatchToProps)(SowCardLardEvents);
