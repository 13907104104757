import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { WebAppState } from 'web/state/store.web';

interface PropsFromParent {
	children: React.ReactNode;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		isAuthenticated: state.authentication.isAuthenticated,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => ({});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

class AuthorizedContent extends React.PureComponent<Props> {
	public render() {
		if (this.props.isAuthenticated === true) {
			return this.props.children;
		}

		return null;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthorizedContent);
