import React, { FC } from 'react';
import { Option } from 'react-dropdown';
import { useSelector } from 'react-redux';
import { getBuildingBySectionId, getSectionBySectionId } from 'shared/helpers/location-helper';
import { memoizeHashmapLocation } from 'shared/helpers/memoize-getters/memoize-getters';
import { generateLocationOptionsForSlaughterHouse } from 'shared/helpers/slaughter-data-helper/slaughter-data-helper';
import { WebAppState } from 'web/state/store.web';
import { SkioldDropdown } from '../skiold-dropdown/skiold-dropdown';

interface SlaughterDataDropDownPropsFromParen {
	sectionId?: string;
	onValueChanged: (option: Option) => void;
	isDarkTheme?: boolean;
	itemFromParent?: any;
}

export const SlaughterDataDropDown: FC<SlaughterDataDropDownPropsFromParen> = React.memo((props) => {
	const state = useSelector((state: WebAppState) => {
		return {
			locations: state.locations,
			memorizedLoca: memoizeHashmapLocation(state.locations.buildings,
				state.locations.sections,
				state.locations.pens),
			site: state.site.site,
		};
	});
	return (
		<SkioldDropdown
			usedInTable={true}
			items={generateLocationOptionsForSlaughterHouse(state.locations, state.memorizedLoca, state.site)}
			theme={props.isDarkTheme ? 'dark' : 'light'}
			selectedValue={{
				label: props.sectionId
					? getBuildingBySectionId(props.sectionId, state.memorizedLoca)!.name +
					  ' - ' +
					  getSectionBySectionId(props.sectionId, state.memorizedLoca)!.name
					: state.site.siteName!,
				value: props.sectionId ? props.sectionId : '',
			}}
			onValueChanged={props.onValueChanged}
			itemFromParent={props.itemFromParent}
		/>
	);
});
