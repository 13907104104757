import ObjectID from 'bson-objectid';
import React from 'react';
import { Option } from 'react-dropdown';
import isEqual from 'react-fast-compare';
import { connect } from 'react-redux';
import {
	AnimalKind,
	Gender,
	MatingGrade,
	StemAnimal,
	IPregnancyEvent,
	PregnancyState,
	Scanned,
	ScanResult,
	IStemAnimal,
	SemenType,
} from 'shared/api/api';
import { deepCopy, exactStartsWithMethodGrid, rangeFilterMethodGrid } from 'shared/helpers/general-helpers';
import { NaturalSort } from 'shared/helpers/natural-sort';
import { GetGradeOptions, getSemenTypeOptions } from 'shared/helpers/pregnancy-helper/generel-pregnancy-helpers';
import { RefType } from 'shared/helpers/ref-type';
import { checkSavePregnancyEventListsStatus } from 'shared/helpers/work-list-helpers/general-work-list-helper';
import { genereateListData } from 'shared/helpers/work-list-helpers/mating-list-helpers/mating-list-helper';
import {
	MatingWorkListItemData,
	MatingWorkListMapDispatchToProps,
	MatingWorkListMapStateToProps,
	MatingWorkListProps,
	MatingWorkListState,
} from 'shared/helpers/work-list-helpers/mating-list-helpers/mating-work-list-item';
import { localized, localizedDynamic } from 'shared/state/i18n/i18n';
import { SharedAppState } from 'shared/state/store.shared';
import 'web/view/components/skiold-components/skiold-table/skiold-double-header-table.scss';
import SkioldTableGrid, {
	SkioldTableGrid as SkioldTableRef,
} from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { getWebAnimalColorStyleTable } from 'web/web-helpers/general-web-helpers';
import { createAndEditValidation } from 'web/web-helpers/pregnancies/register-validation-helper';
import { showAlert, ShowConfirmAlert } from '../../skiold-alert/skiold-alert';
import { SkioldCheckbox } from '../../skiold-components/skiold-checkbox/skiold-checkbox';
import { SkioldDatePicker } from '../../skiold-components/skiold-date-picker/skiold-date-picker';
import { SkioldDropdown } from '../../skiold-components/skiold-dropdown/skiold-dropdown';
import { SkioldFormInput } from '../../skiold-components/skiold-input/skiold-form-input';
import { GroupedHeader } from '../../skiold-components/skiold-table/skiold-table-grid/skiold-table-grid-grouped-header';
import exportFunctions from '../../stem-animal/stem-animal-input/stem-animal-test-helper';
import '../list-setup.scss';
import { defaultSortingWorklists } from '../work-list-helper';
import { SowListConstants } from '../../stem-animal/animal-lists/table-constants';
import './mating-work-list.scss';
import { findBoar } from 'shared/helpers/stemanimal-helper/stem-animal-input-helper';
import { SkioldIntegerInput } from '../../skiold-components/skiold-integer-input/skiold-integer-input';
import { WhiteText } from '../../Text/white-text';
import { SkioldTouchableOpacity } from '../../skiold-components/skiold-touchable-opacity';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { SkioldModal } from '../../skiold-components/skiold-modal/skiold-modal';
import { SearchBoars } from '../../pregnancies/pregnancy-events/register-farrowing/search-boars';

export class MatingWorkListTable extends React.Component<MatingWorkListProps, MatingWorkListState> {
	public static getDerivedStateFromProps(nextProps: MatingWorkListProps, prevState: MatingWorkListState) {
		let data = genereateListData(nextProps, prevState).sort((a, b) =>
			NaturalSort(b.listItemData.cycleDays, a.listItemData.cycleDays),
		);
		if (!isEqual(data.length, prevState.workListData.length)) {
			nextProps.setSowsCount(data.length);
		}
		if (!isEqual(data, prevState.workListData)) {
			return {
				workListData: data,
			};
		}
		return {};
	}
	public SkioldTableRef: SkioldTableRef | undefined;
	private boarsAdded: StemAnimal[] = [];

	private groupedColumns: GroupedHeader[] = [
		{
			title: localized('Mating'),
			children: [
				{ columnName: 'matedDate' },
				{ columnName: 'boar' },
				{ columnName: 'boarType' },
				{ columnName: 'k' },
			],
		},
	];
	constructor(props: MatingWorkListProps) {
		super(props);
		this.state = {
			columns: this.generateColumns(),
			columnExte: this.generateColumnsExtensions(),
			loading: false,
			commitAll: false,
			workListData: genereateListData(props, this.state).sort((a, b) =>
				NaturalSort(b.listItemData.cycleDays, a.listItemData.cycleDays),
			),
			initialAll: props.defaultInitial,
			modalVisible: false,
			semenTypeOptions: getSemenTypeOptions(),
		};
	}

	public componentDidUpdate(prevProps) {
		// Typical usage (don't forget to compare props):
		if (this.props.defaultDate !== prevProps.defaultDate) {
			let workListDataCopy = deepCopy(this.state.workListData);
			workListDataCopy.forEach(item => {
				item.workListData!.mating.date = this.props.defaultDate;
			});
			this.setState({ workListData: workListDataCopy });
		}
	}

	public ResetWorkList() {
		let data = genereateListData(this.props, undefined);
		this.setState({ workListData: data });
	}

	public generateCommitAllCheckBox = () => {
		return (
			<ViewWeb className="checkbox-view-filter-style">
				<SkioldCheckbox
					isChecked={this.state.commitAll}
					className="checkbox-style-filter"
					onClick={this.commitAllCheckBoxClicked}
				/>
			</ViewWeb>
		);
	};

	public generateColumnsExtensions = () => {
		let colExt = [
			{
				columnName: 'cycleDays',
				width: SowListConstants.cycleDaysWidth,
			},
			{
				columnName: 'animalNumber',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'matedDate',
				filteringEnabled: false,
				width: SowListConstants.entranceDateWidth,
			},
			{
				columnName: 'boar',
				filteringEnabled: false,
				width: SowListConstants.entranceDateWidth,
			},
			{
				columnName: 'boarType',
				filteringEnabled: false,
				width: 80,
			},
			{
				columnName: 'k',
				filteringEnabled: false,
				width: 80,
			},
			{
				columnName: 'completed',
				width: SowListConstants.entranceDateWidth,
			},
		];

		if (this.props.generalSettings.stemAnimalShowInitials) {
			colExt.splice(colExt.length - 1, 0, {
				columnName: 'initials',
				width: SowListConstants.entranceDateWidth,
			});
		}

		return colExt;
	};

	public componentDidMount() {
		if (this.SkioldTableRef) {
			this.props.setSowsCount(this.SkioldTableRef.GetSortedData().length);
		}

		this.props.getWorkListSettingsBySiteId();
		this.props.matingBatchGetSyncData();
		this.props.pregnancyEventGetSyncData();
		this.props.stemAnimalGetSyncData();
		this.props.validationSetupGetSyncData();
	}

	public shouldComponentUpdate(nextProps: MatingWorkListProps, nextState: MatingWorkListState) {
		const stemAnimalsEqual = isEqual(this.props.stemAnimals, nextProps.stemAnimals);
		const workListSettingEqual = isEqual(this.props.workListSetting, nextProps.workListSetting);
		const pregnancyEventsEqual = isEqual(this.props.pregnancyEvents, nextProps.pregnancyEvents);
		const matingBatchesEqual = isEqual(this.props.matingBatches, nextProps.matingBatches);
		const validationSetupEqual = isEqual(this.props.validationSetup, nextProps.validationSetup);
		const workListDataEqual = isEqual(this.state.workListData, nextState.workListData);

		if (
			!stemAnimalsEqual ||
			!workListSettingEqual ||
			!pregnancyEventsEqual ||
			!matingBatchesEqual ||
			!validationSetupEqual ||
			!workListDataEqual ||
			this.props.defaultDate !== nextProps.defaultDate ||
			this.state.maxDryDays !== nextState.maxDryDays ||
			this.state.initialAll.value !== nextState.initialAll.value ||
			this.state.modalVisible !== nextState.modalVisible
		) {
			return true;
		}

		return false;
	}

	public SaveWorklist = async () => {
		let pregnancyEventsToSave: IPregnancyEvent[] = [];
		for (let index = 0; index < this.state.workListData.length; index++) {
			const mating = this.state.workListData[index];
			if (mating.workListData && mating.workListData.isChecked) {
				const matingToSave = await this.saveMating(mating);
				if (matingToSave) {
					pregnancyEventsToSave.push(matingToSave);
				}
			}
		}
		await this.props.savePregnancyEvents(pregnancyEventsToSave);
	};

	public render() {
		return (
			<ViewWeb>
				<ViewWeb className="max-dry-days-container">
					<ViewWeb className="mating-work-list-init-container">
						<WhiteText>{localized('maxDryDays')}</WhiteText>
						<ViewWeb className={'underline'}>
							<SkioldIntegerInput
								className="default-input-text"
								onChangeNumber={this.setDryDays}
								text={this.state.maxDryDays}
							/>
						</ViewWeb>
					</ViewWeb>
					{this.props.generalSettings.stemAnimalShowInitials && (
						<ViewWeb className="mating-work-list-init-container">
							<WhiteText>{localized('InitialsShort')}:</WhiteText>
							<SkioldDropdown
								items={this.props.initials}
								selectedValue={this.state.initialAll}
								onValueChanged={this.setInitialsOnAll}
							/>
						</ViewWeb>
					)}
				</ViewWeb>
				<SkioldTableGrid
					tableKey={'matingWorkListTable'}
					columns={this.state.columns}
					className={'work-list-table mating-work-list'}
					ColumnExtensions={this.state.columnExte}
					data={this.state.workListData}
					ref={this.setTableRef}
					sortHeaderId={defaultSortingWorklists}
					groupedColumns={this.groupedColumns}
				/>
				<SkioldModal isOpen={this.state.modalVisible} close={this.closeModal}>
					<SearchBoars
						close={this.closeModal}
						boarSelected={this.boarSelected}
						clear={this.boarCleared}
						matingDate={this.state.matingDate}
					/>
				</SkioldModal>
			</ViewWeb>
		);
	}

	private setDryDays = maxDryDays => {
		this.setState({ maxDryDays });
	};

	// Find and change all initials
	private setInitialsOnAll = (initialOption: Option) => {
		let dataArrayCopy = [...this.state.workListData];
		let newDataArray: MatingWorkListItemData[] = [];

		dataArrayCopy.forEach(item => {
			let newItem = { ...item };
			if (newItem.workListData) {
				newItem.workListData = { ...newItem.workListData, initial: initialOption };
				newItem.workListData.mating = { ...newItem.workListData.mating, initials: initialOption.value };
				newDataArray.push(newItem);
			}
		});

		this.setState({ initialAll: initialOption, workListData: newDataArray });
	};

	public async CheckSavePregnancyEventListsStatus() {
		if (await checkSavePregnancyEventListsStatus(this.state.workListData, ShowConfirmAlert)) {
			await this.SaveWorklist();
		}
		return true;
	}

	private getStemAnimalNumberColor = (sow: MatingWorkListItemData) => {
		return sow.listItemData && sow.listItemData.stemAnimalId
			? getWebAnimalColorStyleTable(sow.listItemData.stemAnimalId)
			: '';
	};

	// If mating is a remating, indicate with at large X
	private getAnimalNumberText = (tableItem: MatingWorkListItemData) =>
		tableItem.listItemData.prevEvent &&
		tableItem.listItemData.prevEvent.state === PregnancyState.Scanned &&
		(tableItem.listItemData.prevEvent as Scanned).result === ScanResult.False ? (
			<ViewWeb className="flex-row-space-evenly">
				<ViewWeb>{tableItem.listItemData.sow!.animalNumber}</ViewWeb>
				<ViewWeb className="bold-label-text-filter-red">X</ViewWeb>
			</ViewWeb>
		) : (
			tableItem.listItemData.sow!.animalNumber
		);

	private setTableRef = (m: any) => (m ? (this.SkioldTableRef = m) : {});
	private readonly generateCycleDaysCell = (d: MatingWorkListItemData) =>
		d.listItemData.cycleDays ? d.listItemData.cycleDays.toString() : undefined;

	private closeModal = () => {
		this.setState({ modalVisible: false, rowStemAnimalId: undefined, matingDate: undefined });
	};

	private boarSelected = (boar: IStemAnimal) => {
		if (boar.animalNumber && this.state.rowStemAnimalId) {
			// Set Semen type according to boar kind
			const semenType = boar.entranceKind === AnimalKind.Boar ? SemenType.Nat : SemenType.KS;
			let dataArrayCopy = [...this.state.workListData];
			let index = dataArrayCopy.findIndex(b => b.listItemData.stemAnimalId === this.state.rowStemAnimalId);
			let dataCopy = { ...dataArrayCopy[index] };
			let workListData = { ...dataArrayCopy[index].workListData! };
			let listData = { ...dataArrayCopy[index].listItemData! };
			let mating = { ...workListData.mating! };
			mating.semenType = semenType;
			workListData.mating = mating;
			listData.boarAnimal = boar;
			workListData.boar = boar.animalNumber;
			workListData.selectedSemenType = { value: semenType, label: semenType };
			dataCopy.workListData = workListData;
			dataCopy.listItemData = listData;
			dataArrayCopy[index] = dataCopy;
			this.setState({ workListData: dataArrayCopy, modalVisible: false });
		}
	};

	private boarCleared = () => {
		if (this.state.rowStemAnimalId) {
			let dataArrayCopy = [...this.state.workListData];
			let index = dataArrayCopy.findIndex(b => b.listItemData.stemAnimalId === this.state.rowStemAnimalId);
			let dataCopy = { ...dataArrayCopy[index] };
			let workListData = { ...dataArrayCopy[index].workListData! };
			let listData = { ...dataArrayCopy[index].listItemData! };
			listData.boarAnimal = undefined;
			workListData.boar = undefined;
			dataCopy.workListData = workListData;
			dataCopy.listItemData = listData;
			dataArrayCopy[index] = dataCopy;
			this.setState({ workListData: dataArrayCopy, modalVisible: false });
		}
	};
	private getBoarInput = (card: MatingWorkListItemData) => {
		if (this.props.nucleusManagement) {
			const openModal = () => {
				this.setState({
					modalVisible: true,
					rowStemAnimalId: card.listItemData.stemAnimalId,
					matingDate: card.workListData?.mating.date,
				});
			};
			return (
				<SkioldTouchableOpacity onPress={openModal}>
					<TextWeb>{card.workListData?.boar ?? localized('choose')}</TextWeb>
				</SkioldTouchableOpacity>
			);
		} else {
			return (
				<SkioldFormInput
					text={card.workListData!.boar}
					className="boar-input"
					onChangeText={a => {
						let dataArrayCopy = [...this.state.workListData];
						let index = dataArrayCopy.findIndex(
							b => b.listItemData.stemAnimalId === card.listItemData.stemAnimalId,
						);
						let dataCopy = { ...dataArrayCopy[index] };
						let workListData = { ...dataArrayCopy[index].workListData! };
						workListData.boar = a;
						dataCopy.workListData = workListData;
						dataArrayCopy[index] = dataCopy;
						this.setState({ workListData: dataArrayCopy });
					}}
				/>
			);
		}
	};

	private animalNumberFilter = (value: any, filterValue: any, row: MatingWorkListItemData) => {
		return (
			row.listItemData.animalNumber &&
			String(localized(row.listItemData.animalNumber).toLocaleLowerCase()).startsWith(
				filterValue.value.toLocaleLowerCase(),
			)
		);
	};

	private generateColumns() {
		const columns = [
			{
				name: 'cycleDays',
				title: localized('Days'),
				headerClassName: 'merged-header',
				pathToValue: 'listItemData',
				filterFunction: rangeFilterMethodGrid,
				getCellValue: this.generateCycleDaysCell,
			},
			{
				name: 'animalNumber',
				headerClassName: 'merged-header',
				title: localized('animalNumber'),
				className: this.getStemAnimalNumberColor,
				pathToValue: 'listItemData',
				filterFunction: this.animalNumberFilter,
				getCellValue: this.getAnimalNumberText,
			},
			{
				name: 'matedDate',
				title: localized('Date'),
				shouldOverflow: true,
				sortable: false,
				filterable: false,
				getCellValue: this.dateColumn,
			},
			{
				name: 'boar',
				sortable: false,
				filterable: false,
				title: localized('boar'),
				getCellValue: this.getBoarInput,
			},
			{
				name: 'boarType',
				sortable: false,
				filterable: false,
				title: 'Type',
				getCellValue: (card: MatingWorkListItemData) => (
					<SkioldDropdown
						usedInTable={true}
						items={this.state.semenTypeOptions}
						theme={'dark'}
						className={'inTable'}
						containerClassName="grade-dropdown"
						selectedValue={card.workListData!.selectedSemenType}
						onValueChanged={this.semenTypeChanged}
						dropdownIdentifier={'mating-worklist-grade'}
						itemFromParent={card}
					/>
				),
			},
			{
				name: 'k',
				title: 'K',
				shouldOverflow: true,
				sortable: false,
				filterable: false,
				getCellValue: (card: MatingWorkListItemData) => (
					<SkioldDropdown
						usedInTable={true}
						items={GetGradeOptions}
						theme={'dark'}
						className={'inTable'}
						containerClassName="grade-dropdown"
						selectedValue={card.workListData!.grade}
						onValueChanged={(gradeOption: Option) => {
							this.gradeChanged(gradeOption, card);
						}}
						dropdownIdentifier={'mating-worklist-grade'}
					/>
				),
			},
			{
				name: 'completed',
				title: localized('completed'),
				headerClassName: 'merged-header',
				sortable: false,
				filter: this.generateCommitAllCheckBox,
				getCellValue: (d: MatingWorkListItemData) => (
					<ViewWeb className="checkbox-view-style">
						<SkioldCheckbox
							isChecked={d.workListData!.isChecked}
							className="checkbox-style"
							onClick={async () => {
								await this.commitedClicked(d);
							}}
						/>
					</ViewWeb>
				),
			},
		];

		if (this.props.generalSettings.stemAnimalShowInitials) {
			columns.splice(columns.length - 1, 0, {
				name: 'initials',
				title: localized('InitialsShort'),
				sortable: false,
				filterable: false,
				getCellValue: (card: MatingWorkListItemData) => (
					<SkioldDropdown
						usedInTable={true}
						items={this.props.initials}
						theme={'dark'}
						className={'inTable'}
						containerClassName="grade-dropdown"
						selectedValue={card.workListData!.initial}
						onValueChanged={this.initChanged}
						dropdownIdentifier={'mating-worklist-grade'}
						itemFromParent={card}
					/>
				),
			});
		}
		return columns;
	}

	private dateColumn = (card: MatingWorkListItemData) => (
		<SkioldDatePicker
			onDateChanged={newDate => {
				let dataArrayCopy = [...this.state.workListData];
				let index = dataArrayCopy.findIndex(
					b => b.listItemData.stemAnimalId === card.listItemData.stemAnimalId,
				);
				let dataCopy = { ...dataArrayCopy[index] };
				let workListData = { ...dataArrayCopy[index].workListData! };
				let mating = { ...workListData.mating! };
				mating.date = newDate;
				workListData.mating = mating;
				dataCopy.workListData = workListData;
				dataArrayCopy[index] = dataCopy;
				this.setState({ workListData: dataArrayCopy });
			}}
			containerClassName="datepicker-ctyle"
			selectedDate={card.workListData!.mating.date}
			theme={'dark'}
			color={'grey'}
			maxDate={new Date(Date.now())}
		/>
	);

	private gradeChanged(gradeOption: Option, matingWorkListItemData: MatingWorkListItemData) {
		let dataArrayCopy = [...this.state.workListData];
		let index = dataArrayCopy.findIndex(
			b => b.listItemData.stemAnimalId === matingWorkListItemData.listItemData.stemAnimalId,
		);
		let dataCopy = { ...dataArrayCopy[index] };
		let workListData = { ...dataArrayCopy[index].workListData! };
		let mating = { ...workListData.mating! };
		mating.grade = gradeOption.value as MatingGrade;
		workListData.mating = mating;
		workListData.grade = gradeOption;
		dataCopy.workListData = workListData;
		dataArrayCopy[index] = dataCopy;
		this.setState({ workListData: dataArrayCopy });
	}

	// Find and change initials
	private initChanged = (initOption: Option, matingWorkListItemData: MatingWorkListItemData) => {
		let dataArrayCopy = [...this.state.workListData];
		let index = dataArrayCopy.findIndex(
			b => b.listItemData.stemAnimalId === matingWorkListItemData.listItemData.stemAnimalId,
		);
		let dataCopy = { ...dataArrayCopy[index] };
		let workListData = { ...dataArrayCopy[index].workListData! };
		let mating = { ...workListData.mating! };
		mating.initials = initOption.value as MatingGrade;
		workListData.mating = mating;
		workListData.initial = initOption;
		dataCopy.workListData = workListData;
		dataArrayCopy[index] = dataCopy;
		this.setState({ workListData: dataArrayCopy });
	};

	// change semenType
	private semenTypeChanged = (semenType: Option, matingWorkListItemData: MatingWorkListItemData) => {
		let dataArrayCopy = [...this.state.workListData];
		let index = dataArrayCopy.findIndex(
			b => b.listItemData.stemAnimalId === matingWorkListItemData.listItemData.stemAnimalId,
		);
		let dataCopy = { ...dataArrayCopy[index] };
		let workListData = { ...dataArrayCopy[index].workListData! };
		let mating = { ...workListData.mating! };
		mating.semenType = semenType.value as SemenType;
		workListData.mating = mating;
		workListData.selectedSemenType = semenType;
		dataCopy.workListData = workListData;
		dataArrayCopy[index] = dataCopy;
		this.setState({ workListData: dataArrayCopy });
	};

	private commitedClicked = async (d: MatingWorkListItemData) => {
		if (d.workListData!.isChecked !== true) {
			const errorMessage = await createAndEditValidation(
				this.props.validationSetup,
				this.props.generalSettings,
				d.workListData!.mating,
				d.listItemData.prevEvent,
				d.listItemData.animalEvents,
				d.listItemData.sow,
				exportFunctions.getMatingBatchNumber(d.workListData!.mating.date!),
			);
			if (errorMessage !== '') {
				if (errorMessage !== 'ignore') {
					await showAlert(localizedDynamic(errorMessage));
				}
				return;
			}
		}
		let dataCopy = [...this.state.workListData];
		let dataIndex = dataCopy.findIndex(a => a.workListData!.stemAnimalId === d.workListData!.stemAnimalId);
		let workListDataCopy = { ...dataCopy[dataIndex] };
		let workListDataToChange = { ...workListDataCopy.workListData! };
		workListDataToChange.isChecked = d.workListData!.isChecked === true ? false : true;
		this.props.SetCheckedCount(workListDataToChange.isChecked ? 1 : -1);
		workListDataCopy.workListData = workListDataToChange;
		dataCopy[dataIndex] = workListDataCopy;
		if (this.state.commitAll === true) {
			this.setState({ workListData: dataCopy, commitAll: false });
		} else {
			this.setState({ workListData: dataCopy });
		}
	};

	private commitAllCheckBoxClicked = async () => {
		let commitAll = this.state.commitAll === true ? false : true;
		let dataCopy = [...this.state.workListData];
		let data = this.SkioldTableRef!.GetSortedData();

		for (let element of data) {
			if (commitAll === true) {
				const errorMessage = await createAndEditValidation(
					this.props.validationSetup,
					this.props.generalSettings,
					element.workListData.mating,
					element.listItemData.prevEvent,
					element.listItemData.animalEvents,
					element.listItemData.sow,
					exportFunctions.getMatingBatchNumber(element.workListData.mating.date!),
				);
				if (errorMessage !== '') {
					continue;
				}
			}
			let dataIndex = dataCopy.findIndex(
				a => a.workListData!.stemAnimalId === element.workListData!.stemAnimalId,
			);
			let worklistData = Object.assign({}, dataCopy[dataIndex]);
			let worklistDataToChange = Object.assign({}, worklistData.workListData!);
			worklistDataToChange.isChecked = commitAll;
			worklistData.workListData = worklistDataToChange;
			dataCopy[dataIndex] = worklistData;
		}
		this.props.SetCheckedCount(
			dataCopy.reduce((a, b) => a + (b.workListData && b.workListData.isChecked ? 1 : 0), 0),
			true,
		);
		this.setState({ workListData: dataCopy, commitAll });
	};

	private async saveMating(matingWorkListItem: MatingWorkListItemData) {
		if (matingWorkListItem.workListData!.isChecked === true) {
			if (matingWorkListItem.workListData && matingWorkListItem.workListData!.boar) {
				let boar = findBoar(matingWorkListItem.workListData.boar, this.props.stemAnimals);
				let addedBoar = this.boarsAdded.find(
					a =>
						matingWorkListItem.workListData &&
						a.animalNumber &&
						matingWorkListItem.workListData.boar &&
						a.animalNumber.toLocaleLowerCase() === matingWorkListItem.workListData.boar.toLocaleLowerCase(),
				);

				if (boar === undefined && addedBoar === undefined) {
					const newBoar = StemAnimal.fromJS({
						animalNumber: matingWorkListItem.workListData!.boar!.toLowerCase(),
						id: new ObjectID().toHexString(),
						siteId: matingWorkListItem.workListData!.mating.siteId,
						gender: Gender.Male,
						entranceKind: AnimalKind.Boar,
					} as IStemAnimal);
					matingWorkListItem.workListData!.mating.boarId = newBoar.id;
					this.boarsAdded.push(newBoar);
					this.props.saveStemAnimal(newBoar);
				} else {
					matingWorkListItem.workListData.mating.boarId =
						boar !== undefined ? boar.id : addedBoar !== undefined ? addedBoar.id : undefined;
				}
			}
			if (matingWorkListItem.workListData!.mating.id === undefined) {
				matingWorkListItem.workListData!.mating.id = new ObjectID().toHexString();
				return matingWorkListItem.workListData!.mating;
			}
		}
	}
}

export default connect<
	ReturnType<typeof MatingWorkListMapStateToProps>,
	ReturnType<typeof MatingWorkListMapDispatchToProps>,
	RefType,
	SharedAppState
>(MatingWorkListMapStateToProps, MatingWorkListMapDispatchToProps, null, { forwardRef: true })(MatingWorkListTable);
