import React from 'react';
import './skiold-touchable-opacity.scss';
import { ViewWeb } from 'web/view/components/utils/web-view';

interface PropsFromParent {
	className?: any;
	containerClassName?: string;
	onPress: (itemFromParent?: any) => void;
	itemFromParent?: any;
    disabled?: boolean;
    style?: any;
	children?: React.ReactNode;
}

interface State {
	touched: boolean;
}

export class SkioldTouchableOpacity extends React.PureComponent<PropsFromParent, State> {
	public static defaultProps: Partial<PropsFromParent> = {
		className: '',
		containerClassName:'',
		disabled: false
	};
	private timeout: any
	constructor(props: PropsFromParent) {
		super(props);
		this.state = {
			touched: false
		};
	}
	public render() {
		const { touched } = this.state;
		const { disabled } = this.props;
		const className = touched && !disabled ? 'btn touched' : 'btn';
		const pointerEvent = disabled ? 'disabled' : 'clickable';
		return (
			<ViewWeb className={("skiold-touchable-opacity "+ this.props.containerClassName)}>
				<ViewWeb
					className={this.props.className + ' ' + className + ' ' +pointerEvent}
					onMouseDown={this.toggleTouched}
                    onMouseUp={this.handleMouseUp}
                    style={this.props.style}
				>
					{this.props.children}
				</ViewWeb>
			</ViewWeb>
		);
	}

	public componentWillUnmount(){
		clearTimeout(this.timeout)
	}

	private toggleTouched = () => {
		this.setState(prevState => ({
			touched: !prevState.touched
		}));
	};
	private handleMouseUp = () => {		
		this.props.onPress(this.props.itemFromParent);
		// Handle smooth animation when clicking without holding
		this.timeout = setTimeout(() => {
			this.setState({ touched: false });
		}, 150);
	};
}
