export function DecimalNumberChanged(newValue: string, setState: (valueToSet: number | undefined) => void) {
	newValue = newValue.replace(',', '.');

	const newValueNumeric = parseFloat(newValue);

	if (newValue !== '' && typeof newValueNumeric === 'number' && !isNaN(newValueNumeric)) {
		if (newValue[newValue.length - 1] !== '.') {
			setState(newValueNumeric);
		}
		return;
	}

	setState(undefined);
}

export function IntegerChanged(newValue: string, setState: (valueToSet: number | undefined) => void) {
	const newValueNumeric = +newValue;

	if (newValue !== '' && typeof newValueNumeric === 'number' && !isNaN(newValueNumeric)) {
		setState(newValueNumeric);
		return;
	}

	setState(undefined);
}

export const roundNumber = (number: number | undefined, decimals: number) => {
	if (!number) {
		return undefined;
	}
	return Math.round((number + Number.EPSILON) * Math.pow(10, decimals)) / Math.pow(10, decimals);
};
