import { Dispatch } from 'redux';
import { ISupplierDetailDto, SupplierDetailDto } from 'shared/api/api';
import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import * as Action from './actions';

export function GetSyncSupplierDetailsData(siteId?: string) {
	return AsyncOperationBuilder2(
		Action.getSupplierDetails,
		client => client.supplierDetail_GetBySiteId(siteId),
		siteId,
	);
}

export function PostSupplierDetails(data: ISupplierDetailDto) {
	return AsyncOperationBuilder2(
		Action.postSupplierDetails,
		client => client.supplierDetail_Post(data as SupplierDetailDto),
		data as SupplierDetailDto,
	);
}

export function AddSupplier(data: ISupplierDetailDto) {
	return (dispatch: Dispatch<any>) => {
		dispatch(Action.AddSupplier(data));
	};
}
