import { default as ObjectID } from 'bson-objectid';
import { Dispatch } from 'redux';
import {
	AnalysisResultYoungFemaleAgeSetting,
	AnalysisSetting,
	AnalysisSettingType,
	IAnalysisSetting,
	AnalysisResultPregnancySetting,
} from 'shared/api/api';
import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import { StoreContainer } from 'shared/state/store-container';
import * as Action from './actions';

export function GetAnalysisSettingsBySiteId() {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;

	if (state.analysisSettings.loading) {
		return (dispatch: Dispatch<any>) => {
			return Promise.resolve();
		};
	}

	return AsyncOperationBuilder2(Action.getBySiteId, client => client.analysisSettings_GetbySiteId(siteId), {
		siteId,
	});
}

export function SaveAnalysisSetting(setting: IAnalysisSetting) {
	const state = StoreContainer.getState();
	const prevEntity = state.analysisSettings.analysisSettings.find(wls => wls.id === setting.id);
	if (!setting.id) {
		setting.id = new ObjectID().toHexString();
	}

	return AsyncOperationBuilder2(
		Action.saveAnalysisSettings,
		client => client.analysisSettings_Upsert(getCorrectAnalysisType(setting)),
		setting,
		prevEntity,
	);
}

function getCorrectAnalysisType(setting: IAnalysisSetting) {
	switch (setting.type) {
		case AnalysisSettingType.AnalysisResultYoungFemaleAgeSetting:
			return AnalysisResultYoungFemaleAgeSetting.fromJS(setting);

		case AnalysisSettingType.AnalysisResultPregnancySetting:
			return AnalysisResultPregnancySetting.fromJS(setting);

		default:
			return AnalysisSetting.fromJS(setting);
	}
}
