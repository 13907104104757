import { ScanResult } from "shared/api/api";
import { localized } from "shared/state/i18n/i18n";


export function GenerateScanResults() {
    return [
        { value: ScanResult.True, label: localized(ScanResult.True) },
        { value: ScanResult.False, label: localized(ScanResult.False) },
        { value: ScanResult.Maybe, label: localized(ScanResult.Maybe) }
    ];
}
