import { Option } from 'react-dropdown';
import { Dispatch } from 'redux';
import {
	Equipment,
	IMatingBatch,
	IMoveEvent,
	IMoveFromPregToPregSetting,
	IPregnancyEvent,
	IStemAnimal,
	IUnitToPen,
	LocationType,
	PregnancyValidationType,
	SowState,
	IEsfFeedingStatus,
	IStation,
	IWorkListSetting,
	IMoveToPregnancyLocation,
	AnimalKind,
} from 'shared/api/api';
import { calculateDays } from 'shared/helpers/general-helpers';
import { getPenBySow, getStemAnimalPen, ILocationModel } from 'shared/helpers/location-helper';
import { memoizeLocation, memoizeValidationSettingPlan } from 'shared/helpers/memoize-getters/memoize-getters';
import { getCycleDays1 } from 'shared/helpers/pregnancy-helper/generel-pregnancy-helpers';
import { getActiveSows } from 'shared/helpers/stemanimal-helper/stem-animal-input-helper';
import { calculateAnimalKind, getState, getState1 } from 'shared/helpers/stemanimal-helper/stemanimal-helper';
import {
	checkLocation,
	checkMatingBatch,
	checkSectionWorkLists,
	getCycleDaysByPregnancyEvents,
	InsertOldDataSelectedSettings,
	setMovelistTableItemData,
	workListUseFeedCurve
} from 'shared/helpers/work-list-helpers/move-to-helpers/moving-lists-helper';
import * as EsfStatusOperations from 'shared/state/ducks/esf-status/operations';
import { GetLocations } from 'shared/state/ducks/locations/operations';
import * as matingBatchOperation from 'shared/state/ducks/mating-batch/operations';
import * as moveEventOperation from 'shared/state/ducks/move-events/operations';
import { SaveMoveEvent } from 'shared/state/ducks/move-events/operations';
import * as pregnancyOperation from 'shared/state/ducks/pregnancy-events/operations';
import * as processEquipmentOperation from 'shared/state/ducks/process-equipments/operations';
import * as stemAnimalOperation from 'shared/state/ducks/stem-animals/operations';
import * as UnitsToPenOperations from 'shared/state/ducks/unit-to-pen/operations';
import * as validationSetupOperation from 'shared/state/ducks/validation-setup/operations';
import { GetSyncData } from 'shared/state/ducks/work-list-settings/operations';
import { SharedAppState } from 'shared/state/store.shared';
import { MovingListTable } from './move-to-data-model';
import { ExtractLocationCode } from 'shared/helpers/process-equipment-helper/process-equipment-helper';
import { StoreContainer } from 'shared/state/store-container';

export interface MoveFromPregToPregListState {
	selectedBatch: Option;
	matingBatchesOptions: Option[];
	locationString: string | undefined;
	moveFromPregToPregSetup: IMoveFromPregToPregSetting;
	locations: ILocationModel | undefined;
	selectedHeaderSectionUsesPens: boolean;
	toSectionId?: string;
	toPenId?: string;
	fromSectionId?: string;
	fromPenId?: string;
	fromLocation: ILocationModel | undefined;
	fromSectionUsesPens: boolean;
	moveListData: MovingListTable[];
	moveListInitData: MovingListTable[];
}

export const moveFromPregToPregListMapStateToProps = (state: SharedAppState) => {
	return {
		workListSettings: state.workListSettings.entities,
		activeSows: state.stemAnimals.entities,
		pregnancyEvets: state.pregnancyEvents.entities,
		matingBatches: state.matingBatches.entities,
		// feedCurves: state.site.site.feedCurves,
		siteId: state.profile.active!.siteId!,
		moveEvents: state.moveEvents.entities,
		locations: memoizeLocation(
			state.locations.buildings,
			state.locations.sections,
			state.locations.pens,
			state.locations.valves,
		),
		validationSettingPlan: memoizeValidationSettingPlan(
			state.validationSetup.entity,
			PregnancyValidationType.MatingToFarrowing,
		),
		esfExists: state.processEquipments.entities.find(pq => pq.equipment === Equipment.ESF) !== undefined,
		unitToPens: state.unitToPenData.data,
		esfFeedingStatus: state.esfStatus.data,
		stations: state.station.data,
	};
};

export const moveFromPregToPregListMapDispatchToProps = (dispatch: Dispatch) => ({
	saveMoveEvent: (moveEvent: IMoveEvent) => SaveMoveEvent(moveEvent)(dispatch),
	getWorkListSettingsBySiteId: () => GetSyncData()(dispatch),
	pregnancyEventGetSyncData: () => pregnancyOperation.GetSyncData()(dispatch),
	stemAnimalGetSyncData: () => stemAnimalOperation.GetSyncData()(dispatch),
	matingBatchGetSyncData: () => matingBatchOperation.GetSyncData()(dispatch),
	validationSetupGetSyncData: () => validationSetupOperation.GetSyncData()(dispatch),
	moveEventGetSyncData: () => moveEventOperation.GetSyncData()(dispatch),
	getLocations: () => GetLocations()(dispatch),
	getFeedingCurves: () => EsfStatusOperations.GetEsfFeedCurveSyncData()(dispatch),
	getunitsData: () => UnitsToPenOperations.GetSyncData()(dispatch),
	getProcessEquipmentSyncData: () => processEquipmentOperation.GetSyncData()(dispatch),
	getFeedingStatus: () => EsfStatusOperations.GetEsfFeedingStatusSyncData()(dispatch),
});
export type MoveFromPregToPregListProps = ReturnType<typeof moveFromPregToPregListMapStateToProps> &
	ReturnType<typeof moveFromPregToPregListMapDispatchToProps>;

export function getDataByMatingBatchMoveFromPregToPregList(
	batchId: string | undefined,
	moveEvents: IMoveEvent[],
	locations: ILocationModel,
	matingBatches: IMatingBatch[],
	pregEvents: { [key: string]: IPregnancyEvent[] },
	moveListData: MovingListTable[],
	moveFromPregToPregSetup: IMoveFromPregToPregSetting,
	toSectionId: string | undefined,
	toPenId: string | undefined,
	unitToPens: IUnitToPen[] | undefined,
	esfFeedingStatus: IEsfFeedingStatus[],
	stations: IStation[],
	validateSettingToSubtract: number | undefined,
) {
	let workListData = [] as MovingListTable[];
	let activeSows = getActiveSows();
	const batch = matingBatches.find(matingBatch => matingBatch.id === batchId);
	if (batch) {
		let newUtp = toPenId && unitToPens && unitToPens.find(utp => utp.penId === toPenId);

		for (let sow of activeSows) {
			let state = getState(sow.id!);
			let animalPregEvents = pregEvents[sow.id!];

			if (!animalPregEvents || state !== SowState.Pregnant) {
				continue;
			}

			let currentPen = getStemAnimalPen(sow, moveEvents, locations.pens);

			if (!currentPen || !checkLocation(currentPen, locations, LocationType.Pregnant)) {
				continue;
			}

			let pregnancyEvents = checkMatingBatch(pregEvents[sow.id!], batch);
			if (pregnancyEvents === undefined || !pregnancyEvents.lastestPregEvent) {
				continue;
			}
			const cycleDays = calculateDays(pregnancyEvents.lastestPregEvent.date!, new Date());
			const negativeCycleDays = validateSettingToSubtract && cycleDays
				? cycleDays - validateSettingToSubtract : 0
			let movePregWorkListItem = new MovingListTable();
			setMovelistTableItemData(
				sow,
				movePregWorkListItem,
				cycleDays, negativeCycleDays
			);

			const currentUtp =
				currentPen.id && unitToPens && unitToPens.find(utp => currentPen && utp.penId === currentPen.id);

			const currentStation = stations.find(s => currentUtp && s.unitGroupId === currentUtp.unitGroupId);
			const newStation = stations.find(s => newUtp && s.unitGroupId === newUtp.unitGroupId);
			let fromPenIsEsfLocation =
				currentStation &&
					newStation &&
					ExtractLocationCode(currentStation.code) === ExtractLocationCode(newStation.code)
					? true
					: false;
			let feeddingStatus = esfFeedingStatus.find(ef => ef.stemAnimalId === sow.id);

			movePregWorkListItem.currentPenId = currentPen.id;
			let { usesPens } = checkSectionWorkLists(toSectionId, toPenId);
			movePregWorkListItem.sectionId = toSectionId;
			movePregWorkListItem.penId = toPenId;
			movePregWorkListItem.usesPens = usesPens;
			movePregWorkListItem.animalKind = calculateAnimalKind(sow);
			movePregWorkListItem.isMoveLocationEsf = newUtp ? true : false;
			movePregWorkListItem.fromPenIsEsfLocation = fromPenIsEsfLocation;
			movePregWorkListItem.currentSelectedCurveNumber = feeddingStatus
				? feeddingStatus.feedCurveNumber
				: undefined;
			movePregWorkListItem.currentFixed = feeddingStatus ? feeddingStatus.fixedDeviation : undefined;
			workListData.push(movePregWorkListItem);
		}
		InsertOldDataSelectedSettings(workListData, moveListData);
	}
	return workListData;
}

export function getDataByLocationMoveFromPregToPregList(
	pregEvents: { [key: string]: IPregnancyEvent[] },
	moveListData: MovingListTable[],
	penId: string,
	toSectionId: string | undefined,
	toPenId: string | undefined,
	moveFromPregToPregSetup: IMoveFromPregToPregSetting,
	unitToPens: IUnitToPen[] | undefined,
	esfFeedingStatus: IEsfFeedingStatus[],
	stations: IStation[],
	validateSettingToSubtract: number | undefined,

) {
	let ActiveSows = getActiveSows();
	let data: MovingListTable[] = [];

	let newUtp = toPenId && unitToPens && unitToPens.find(utp => utp.penId === toPenId);
	const currentUtp = penId && unitToPens && unitToPens.find(utp => penId && utp.penId === penId);
	const currentStation = stations.find(s => currentUtp && s.unitGroupId === currentUtp.unitGroupId);
	const newStation = stations.find(s => newUtp && s.unitGroupId === newUtp.unitGroupId);

	let fromPenIsEsfLocation =
		currentStation &&
			newStation &&
			ExtractLocationCode(currentStation.code) === ExtractLocationCode(newStation.code)
			? true
			: false;

	for (let sow of ActiveSows) {
		let feeddingStatus = esfFeedingStatus.find(ef => ef.stemAnimalId === sow.id);
		let state = getState(sow.id!);
		let animalPregEvents = pregEvents[sow.id!];
		if (!animalPregEvents || state !== SowState.Pregnant) {
			continue;
		}
		let pen = getPenBySow(sow);
		if (pen === undefined || pen.id !== penId) {
			continue;
		}

		const cycleDays = getCycleDaysByPregnancyEvents(pregEvents[sow.id!]);
		const negativeCycleDays = validateSettingToSubtract && cycleDays
			? cycleDays - validateSettingToSubtract : 0

		let movePregWorkListItem = new MovingListTable();
		setMovelistTableItemData(sow, movePregWorkListItem, cycleDays, negativeCycleDays);
		let { usesPens } = checkSectionWorkLists(toSectionId, penId);
		movePregWorkListItem.sectionId = toSectionId;
		movePregWorkListItem.penId = toPenId;
		movePregWorkListItem.usesPens = usesPens;
		movePregWorkListItem.currentPenId = pen.id;
		movePregWorkListItem.isMoveLocationEsf = newUtp ? true : false;
		movePregWorkListItem.animalKind = calculateAnimalKind(sow);
		movePregWorkListItem.fromPenIsEsfLocation = fromPenIsEsfLocation;
		movePregWorkListItem.currentSelectedCurveNumber = feeddingStatus ? feeddingStatus.feedCurveNumber : undefined;
		movePregWorkListItem.currentFixed = feeddingStatus ? feeddingStatus.fixedDeviation : undefined;

		data.push(movePregWorkListItem);
	}
	InsertOldDataSelectedSettings(data, moveListData);
	return data;
}

export function getMoveFromPregToPregItemByAnimal(
	sow: IStemAnimal,
	pregEvents: IPregnancyEvent[],
	moveEvents: IMoveEvent[],
	locations: ILocationModel,
	unitToPens: IUnitToPen[] | undefined,
	esfFeedingStatus: IEsfFeedingStatus[] | undefined,
	toPenId: string | undefined,
	validateSettingToSubtract: number | undefined,
) {
	let state = getState1(sow.id!, pregEvents);
	if (!pregEvents || state !== SowState.Pregnant) {
		return undefined;
	}

	let currentPen = getStemAnimalPen(sow, moveEvents, locations.pens);

	if (!currentPen || !checkLocation(currentPen, locations, LocationType.Pregnant)) {
		return undefined;
	}

	let fromPenIsEsfLocation = PenHasSameBaseLocationCode(currentPen.id, toPenId);

	let feeddingStatus = esfFeedingStatus && esfFeedingStatus.find(ef => ef.stemAnimalId === sow.id);

	let cycleDays = getCycleDays1(sow.id!, pregEvents);

	const negativeCycleDays = validateSettingToSubtract && cycleDays
		? cycleDays - validateSettingToSubtract : 0
	let moveToWorkListItem = new MovingListTable();
	setMovelistTableItemData(sow, moveToWorkListItem, cycleDays, negativeCycleDays);
	moveToWorkListItem.currentPenId = currentPen.id;
	moveToWorkListItem.penId = toPenId;
	moveToWorkListItem.fromPenIsEsfLocation = fromPenIsEsfLocation;
	moveToWorkListItem.currentSelectedCurveNumber = feeddingStatus ? feeddingStatus.feedCurveNumber : undefined;
	moveToWorkListItem.currentFixed =
		fromPenIsEsfLocation && feeddingStatus ? feeddingStatus.fixedDeviation : undefined;
	return moveToWorkListItem;
}

export const PenHasSameBaseLocationCode = (penId1: string | undefined, pendId2: string | undefined) => {
	const state = StoreContainer.getState();
	const currentUtp =
		penId1 && state.unitToPenData.data && state.unitToPenData.data.find(utp => penId1 && utp.penId === penId1);

	const newUtp =
		pendId2 && state.unitToPenData.data && state.unitToPenData.data.find(utp => pendId2 && utp.penId === pendId2);

	const currentStation = state.station.data.find(s => currentUtp && s.unitGroupId === currentUtp.unitGroupId);
	const newStation = state.station.data.find(s => newUtp && s.unitGroupId === newUtp.unitGroupId);

	return currentStation &&
		newStation &&
		ExtractLocationCode(currentStation.code) === ExtractLocationCode(newStation.code)
		? true
		: false;
};

export const GetDefaultCurveByPenIdAnimalkind = (
	penId1: string | undefined,
	animalKind: AnimalKind | undefined,
	worklist: IWorkListSetting,
) => {
	if (!workListUseFeedCurve(worklist) || animalKind === undefined) {
		return undefined;
	}
	const state = StoreContainer.getState();

	const newUtp =
		penId1 && state.unitToPenData.data && state.unitToPenData.data.find(utp => penId1 && utp.penId === penId1);

	const newStation = state.station.data.find(s => newUtp && s.unitGroupId === newUtp.unitGroupId);
	let defaultFeedCurves = (worklist as IMoveToPregnancyLocation).defaultFeedCurves;

	return (
		defaultFeedCurves &&
		defaultFeedCurves.find(
			d => newStation && d.animalKind === animalKind && d.locationCode === ExtractLocationCode(newStation.code),
		)
	);
};
