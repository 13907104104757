import memoize from 'memoize-one';
import React from 'react';
import isEqual from 'react-fast-compare';
import { connect } from 'react-redux';
import { FarrowingListTypes, LocationType } from 'shared/api/api';
import { getDateString } from 'shared/helpers/date-helpers';
import { exactStartsWithMethodGrid, rangeFilterMethodGrid } from 'shared/helpers/general-helpers';
import { LocationModel } from 'shared/helpers/location-helper';
import { NaturalSortDates } from 'shared/helpers/natural-sort';
import { checkSectionWorkLists } from 'shared/helpers/work-list-helpers/move-to-helpers/moving-lists-helper';
import { localized } from 'shared/state/i18n/i18n';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import SkioldTableGrid, {
	SkioldTableGrid as SkioldTableRef,
} from '../../skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { SowListConstants } from '../../stem-animal/animal-lists/table-constants';
import { ViewWeb } from '../../utils/web-view';
import { defaultSortingWorklists, getAnimalNumberText, getStemAnimalNumberColor } from '../work-list-helper';
import { genereateWorklistData } from './farrowing-list-helper';
import {
	FarrowingMapDispatchToProps,
	FarrowingMapStateToProps,
	FarrowingWorkListItem,
	FarrowingWorkListProps,
	FarrowingWorkListState,
} from './farrowing-work-list-item';
import './farrowing-work-list-table.scss';
import SkioldFormPenPicker from '../../location/location-picker/skiold-form-pen-picker';
import SkioldStableSectionPicker from '../../location/location-picker/skiold-stable-section-picker';
import { isDistinctPen } from 'shared/helpers/work-list-helpers/vaccine-list-helpers/vaccine-work-list-helpers';

export class FarrowingWorkListTable extends React.Component<FarrowingWorkListProps, FarrowingWorkListState> {
	public SkioldTableRef: SkioldTableRef | undefined;

	//Only recalculate data when one of the inputs change
	private generateData = memoize((animals, pregnancies, matingBatches, worklistSetting, fromSectionId, fromPenId) =>
		genereateWorklistData(this.props, fromSectionId, fromPenId),
	);
	constructor(props: FarrowingWorkListProps) {
		super(props);
		let locations = new LocationModel(this.props.locations);
		locations.sections = this.props.locations.sections.filter(a => a.type === LocationType.Farrowing);
		this.state = {
			loading: false,
			columns: this.generateColumns(),
			columnsExte: this.generateColumnsExtensions(),
			editModalOpen: false,
			headers: undefined,
			fromSectionId: undefined,
			fromPenId: undefined,
			fromLocation: locations,
			fromSectionUsesPens: false,
		};
	}

	public generateColumnsExtensions() {
		let columnExtensions = [
			{
				columnName: 'cycleDays',
				width: SowListConstants.cycleDaysWidth,
			},
			{
				columnName: 'animalNumber',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'matingBatch',
				filteringEnabled: false,
				width: SowListConstants.batchWidth,
			},
			{
				columnName: 'expectedFarrowing',
				filteringEnabled: false,
				width: 200,
			},
		];
		if (this.props.workListSetting.usesLocation) {
			columnExtensions.push({ columnName: 'buildSection', width: 150 });
			columnExtensions.push({ columnName: 'penString', width: 80 });
		}

		return columnExtensions;
	}

	public componentDidMount() {
		if (this.SkioldTableRef) {
			this.props.setSowsCount(this.SkioldTableRef.GetSortedData().length);
		}
		this.props.getWorkListSettingsBySiteId();
		this.props.matingBatchGetSyncData();
		this.props.pregnancyEventGetSyncData();
		this.props.stemAnimalGetSyncData();
		this.props.validationSetupGetSyncData();
	}

	public componentDidUpdate() {
		if (this.SkioldTableRef) {
			this.props.setSowsCount(this.SkioldTableRef.GetSortedData().length);
		}
	}

	public shouldComponentUpdate(nextProps: FarrowingWorkListProps, nextState: FarrowingWorkListState) {
		const stemAnimalsEqual = isEqual(this.props.stemAnimals, nextProps.stemAnimals);
		const workListSettingEqual = isEqual(this.props.workListSetting, nextProps.workListSetting);
		const pregnancyEventsEqual = isEqual(this.props.pregnancyEvents, nextProps.pregnancyEvents);
		const matingBatchesEqual = isEqual(this.props.matingBatches, nextProps.matingBatches);
		const validationSetupEqual = isEqual(this.props.validationSetup, nextProps.validationSetup);
		const columnsEqual = isEqual(this.state.columns, nextState.columns);
		const pensEqual = isEqual(this.state.fromPenId, nextState.fromPenId);
		const sectionsEqual = isEqual(this.state.fromSectionId, nextState.fromSectionId);

		if (
			!stemAnimalsEqual ||
			!workListSettingEqual ||
			!pregnancyEventsEqual ||
			!matingBatchesEqual ||
			!validationSetupEqual ||
			!columnsEqual ||
			!pensEqual ||
			!sectionsEqual
		) {
			return true;
		}

		return false;
	}

	public render() {
		let data = this.generateData(
			this.props.stemAnimals,
			this.props.pregnancyEvents,
			this.props.matingBatches,
			this.props.workListSetting,
			this.state.fromSectionId,
			this.state.fromPenId,
		);

		return (
			<ViewWeb className="farrowing-work-list-table">
				{this.props.workListSetting.selectedType === FarrowingListTypes.Location && (
					<ViewWeb className="flex-direction-row flex-one margin-left-20">
						<ViewWeb className="flex-direction-row flex-one">
							<ViewWeb className="section-picker-container">
								<TextWeb className="stable-section-text">{localized('StableSection')}:</TextWeb>
								<SkioldStableSectionPicker
									onStableSectionSelected={this.fromSelectedSectionChanged}
									sectionId={this.state.fromSectionId}
									filteredLocations={this.state.fromLocation}
									isPigProduction={true}
									containerClassName="picker-width"
								/>
							</ViewWeb>
							{this.state.fromSectionUsesPens === true && (
								<ViewWeb className="pen-picker-container">
									<TextWeb className="pen-text">{localized('Pen')}:</TextWeb>
									<SkioldFormPenPicker
										onPenSelected={this.fromSelectedPenChanged}
										filteredLocations={this.state.fromLocation}
										sectionId={this.state.fromSectionId}
										penId={this.state.fromPenId}
										theme="light"
										containerClassName="picker-width"
										usedInsideForm={false}
									/>
								</ViewWeb>
							)}
						</ViewWeb>
					</ViewWeb>
				)}

				<SkioldTableGrid
					tableKey={'farrowingWorkListTable' + this.props.workListSetting.usesLocation}
					columns={this.state.columns}
					ColumnExtensions={this.state.columnsExte}
					data={data}
					ref={this.setRef}
					sortHeaderId={defaultSortingWorklists}
					onClickedRow={this.rowClicked}
					showPagination={true}
				/>
			</ViewWeb>
		);
	}

	private rowClicked = (rowData: FarrowingWorkListItem) => {
		this.props.editFarrowingWeb(rowData);
	};

	private animalNumberFilter = (value: any, filterValue: any, row: FarrowingWorkListItem) => {
		return (
			row.animalNumber &&
			String(localized(row.animalNumber).toLocaleLowerCase()).startsWith(filterValue.value.toLocaleLowerCase())
		);
	};

	private generateColumns() {
		const columns: any = [
			{
				name: 'cycleDays',
				title: localized('Days'),
				filterFunction: rangeFilterMethodGrid,
				getCellValue: (d: FarrowingWorkListItem) => d.cycleDays,
			},
			{
				name: 'animalNumber',
				title: localized('animalNumber'),
				filterFunction: this.animalNumberFilter,
				className: getStemAnimalNumberColor,
				getCellValue: getAnimalNumberText,
			},
			{
				name: 'matingBatch',
				title: localized('Batch'),
				filterFunction: exactStartsWithMethodGrid,
				getCellValue: (d: FarrowingWorkListItem) => d.matingBatch,
			},
			{
				name: 'expectedFarrowing',
				title: localized('expectedFarrowingLong'),
				getCellValue: (d: FarrowingWorkListItem) => getDateString(d.expectedFarrowing),
				sortFunction: NaturalSortDates,
			},
		];

		if (this.props.workListSetting.usesLocation) {
			columns.push({
				name: 'buildSection',
				title: localized('section'),
				headerClassName: 'merged-header',
				filterFunction: exactStartsWithMethodGrid,
				getCellValue: (tableItem: FarrowingWorkListItem) => tableItem.buildingSectionString,
			});

			columns.push({
				name: 'penString',
				title: localized('pen'),
				headerClassName: 'merged-header',
				filterFunction: exactStartsWithMethodGrid,
			});
		}

		return columns;
	}

	private fromSelectedSectionChanged = (selectedSectionId: string) => {
		let { penId, usesPens } = checkSectionWorkLists(selectedSectionId, this.state.fromPenId);
		if (this.state.fromSectionId !== selectedSectionId) {
			this.setState({
				fromSectionId: selectedSectionId,
				fromPenId: undefined,
				fromSectionUsesPens: usesPens,
			});
		} else {
			this.setState({
				fromSectionId: selectedSectionId,
				fromPenId: penId,
				fromSectionUsesPens: usesPens,
			});
		}
	};
	private fromSelectedPenChanged = (selectedPenId: string) => {
		if (this.state.fromPenId !== selectedPenId) {
			// const workListData = genereateWorklistData(this.props,this.state.fromSectionId, selectedPenId );
			this.setState({
				fromPenId: selectedPenId,
			});
		} else {
			this.setState({
				fromPenId: selectedPenId,
			});
		}
	};

	private setRef = (m: any) => (m ? (this.SkioldTableRef = m) : {});
}

export default connect(FarrowingMapStateToProps, FarrowingMapDispatchToProps, null, { forwardRef: true })(
	FarrowingWorkListTable,
) as any;
