import React from 'react';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { treatmentTableModelType } from './treatment-log-helper';
export const getDrugNameCell = (t: treatmentTableModelType) => {
	return (
		<ViewWeb className="alignContentBaseline">
			<DrugNameText treatment={t} />
		</ViewWeb>
	);
};

interface PropsFromParent {
	treatment: treatmentTableModelType;
}

export class DrugNameText extends React.PureComponent<PropsFromParent, {}> {
	public render() {
		return this.getTreatmentText(this.props.treatment);
	}

	private getTreatmentText(treatmentTableModel: treatmentTableModelType) {
		if (!treatmentTableModel || !treatmentTableModel.subtreatmentTableModels || treatmentTableModel.isRegistration) {
			return <ViewWeb />;
		}

		const textElements = treatmentTableModel.subtreatmentTableModels.map((sub, index) => {
			const drugString = `${sub.drugType!.name}`;

			return (
				<TextWeb className="drug-name-text" key={index}>
					{drugString}
				</TextWeb>
			);
		});

		return textElements;
	}
}
