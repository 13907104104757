import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
	AnimalReportType,
	FarrowingReport,
	IMatingBatch,
	IMatingBatchReportSetting,
	MatingBatchReportSetting,
	MatingReport,
	WeaningReport,
} from 'shared/api/api';
import PrinterGreyIcon from 'shared/assets/src-assets/png/printer_ikon_grey.png';
import { getDateString } from 'shared/helpers/date-helpers';
import { NaturalSortDates } from 'shared/helpers/natural-sort';
import { SaveAnimalReportSetting } from 'shared/state/ducks/animal-report-settings/operations';
import { GetMatingBatchReportBySiteAndDate } from 'shared/state/ducks/mating-batch-report/operations';
import { MatingBatchReportModalMode } from 'shared/state/ducks/mating-batch-report/types';
import { GetSyncData as MatingBatchSettingGetSyncData } from 'shared/state/ducks/mating-batch-setting/operations';
import { GetSyncData as MatingBatchGetSyncData } from 'shared/state/ducks/mating-batch/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { SkioldDatePicker } from 'web/view/components/skiold-components/skiold-date-picker/skiold-date-picker';
import { SkioldIntegerInput } from 'web/view/components/skiold-components/skiold-integer-input/skiold-integer-input';
import { SkioldModal } from 'web/view/components/skiold-components/skiold-modal/skiold-modal';
import { SkioldTable } from 'web/view/components/skiold-components/skiold-table/skiold-table';
import { SkioldTouchableOpacity } from 'web/view/components/skiold-components/skiold-touchable-opacity';
import { SkioldFetchIcon } from 'web/view/components/utils/skiold-fetch-icon';
import { SkioldIconSpinner } from 'web/view/components/utils/skiold-icon-spinner';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { GetMatingBatchReportPdf } from 'web/web-helpers/pdf-helper/mating-batch-report-helper';
import MatingBatchReportModal from './mating-batch-report-modal';
import './mating-batch-report.scss';

const mapStateToProps = (state: WebAppState) => {
	return {
		profile: state.profile.active!,
		MatingBatchReports: state.matingBatchReports.matingBatchReports,
		matingBatches: state.matingBatches.entities.sort((mbA, mbB) =>
			NaturalSortDates(mbA.matingPeriodStart, mbB.matingPeriodStart),
		),
		matingBatchSetting: state.matingBatchSetting.currentMatingBatchSetting,
		loading: state.matingBatchReports.loadingMatingBatchReportDashboardTableModels,
		matingBatchReportSetting: state.animalReportSettings.entities.find(
			animalReport => animalReport.type === AnimalReportType.MatingBatchReportSetting,
		),
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	getMatingBatches: () => MatingBatchGetSyncData()(dispatch),
	getMatingBatchSetting: () => MatingBatchSettingGetSyncData()(dispatch),
	getMatingBatchReportsBySiteAndDate: (date: Date, siteId: string) =>
		GetMatingBatchReportBySiteAndDate(date, siteId)(dispatch),
	saveMatingBatchReportSetting: (setting: IMatingBatchReportSetting) => SaveAnimalReportSetting(setting)(dispatch),
});

// tmp dates are used in the datepicker as they shouldn't come into effect before the fetch button is clicked
interface State {
	tmpDate: Date;
	modalOpen: boolean;
	modalMode?: MatingBatchReportModalMode;
	isDownloadingMatingBatchReport: boolean;
	matingBatchReportSetting?: IMatingBatchReportSetting;
}

interface MatingBatchReportsData {
	description: JSX.Element;
	columns: JSX.Element[];
}

interface PropsFromParent {
	topLeft?: (topLeft: JSX.Element) => void;
	topRight?: (topRight: JSX.Element) => void;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;
class MatingBatchReport extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		const tmpDate = new Date();
		const initMatingBatchReportSetting = MatingBatchReportSetting.fromJS({
			batchesPerColumn: [1, 1, 1, 1, 1],
			type: AnimalReportType.MatingBatchReportSetting,
			siteId: props.profile.siteId,
		} as IMatingBatchReportSetting);
		this.state = {
			tmpDate,
			isDownloadingMatingBatchReport: false,
			matingBatchReportSetting: props.matingBatchReportSetting
				? props.matingBatchReportSetting
				: initMatingBatchReportSetting,
			modalOpen: false,
			modalMode: undefined,
		};

		this.props.getMatingBatches();
		this.props.getMatingBatchSetting();
	}

	public componentDidUpdate(prevProps: Props) {
		if (prevProps.matingBatchReportSetting !== this.props.matingBatchReportSetting) {
			return { matingBatchReportSetting: this.props.matingBatchReportSetting };
		}
		if (this.props.loading !== prevProps.loading && this.props.topLeft) {
			this.props.topLeft(this.renderTopLeft(this.props.loading));
		}
		return {};
	}

	public componentDidMount() {
		this.fetchMatingReport();
		if (this.props.topLeft) {
			this.props.topLeft(this.renderTopLeft(this.props.loading));
		}
		if (this.props.topRight) {
			this.props.topRight(this.renderTopRight());
		}
	}

	public render() {
		const columns = this.generateColumns();
		return (
			<div className="report-container">
				<ViewWeb className="batch-report-outer-div">
					<SkioldTable className="mating-batch-report" columns={columns} data={this.generateData()} />
				</ViewWeb>
				{this.state.modalOpen && (
					<SkioldModal
						shouldCloseOnOverlayClick={true}
						padding="0"
						isOpen={this.state.modalOpen}
						close={this.closeModal}
						className=" matingBatchReportSkioldModal"
						justify-content="flex-end"
						max-width="calc(100% - 150px)"
					>
						<MatingBatchReportModal
							closeModal={this.closeModal}
							mode={this.state.modalMode}
							date={this.state.tmpDate}
						/>
					</SkioldModal>
				)}
			</div>
		);
	}

	private closeModal = () => this.setState({ modalOpen: false, modalMode: undefined });

	public setDate = (newDate: Date) => {
		this.setState({ tmpDate: newDate }, () => {
			if (this.props.topLeft) {
				this.props.topLeft(this.renderTopLeft(this.props.loading));
			}
		});
	};

	public renderTopLeft = (loading: boolean) => {
		return (
			<div className="top-left-container">
				<div className="date-picker-margin">
					<SkioldDatePicker
						onDateChanged={this.setDate}
						selectedDate={this.state.tmpDate}
						text="Date"
						includeTimeStamp={false}
					/>
				</div>

				<SkioldFetchIcon onPress={this.fetchMatingReport} showSpinner={loading} />
			</div>
		);
	};
	public renderTopRight = () => {
		return <SkioldIconSpinner title={'printMatingBatchReport'} icon={PrinterGreyIcon} onPress={this.download} />;
	};

	public renderEmptyParagraphs(length: number) {
		const Paragraphs: JSX.Element[] = [];
		for (let i = 0; i < length; i++) {
			Paragraphs.push(<p key={i} />);
		}
		return Paragraphs;
	}

	private readonly GenerateDescriptionHeader = (descriptionHeader: string, mode: MatingBatchReportModalMode) => (
		<SkioldTouchableOpacity
			itemFromParent={mode}
			onPress={this.pressDescriptionHeader}
			className="matingBatchReportDescriptionContainer"
		>
			<div className="matingBatchReportDescriptionHeader">
				<div className="folder-arrow arrow-folded" />
				<p className="lineHeight20 marginLeft10"> {descriptionHeader}</p>
			</div>
			<div className="batchfromToContainer">
				<p className="height20 lineHeight20 textAlignRight"> {localized('Batch')}</p>
				<p className="height20 lineHeight20 textAlignRight"> {localized('FromDate')}</p>
				<p className="height20 lineHeight20 textAlignRight"> {localized('ToDate')}</p>
			</div>
		</SkioldTouchableOpacity>
	);

	private readonly pressDescriptionHeader = (itemFromParent: MatingBatchReportModalMode) => {
		this.setState({ modalMode: itemFromParent, modalOpen: true });
	};

	public generateData(): MatingBatchReportsData[] {
		let matingBatchReportsData = new Array<MatingBatchReportsData>(7);
		matingBatchReportsData[0] = {
			description: (
				<div className="AmountMatingBatchesPerColumns"> {localized('AmountMatingBatchesPerColumn')}</div>
			),
			columns: this.generateAmountMatingBatchesPerColumns(),
		};
		matingBatchReportsData[1] = {
			description: this.GenerateDescriptionHeader(localized('Matings'), MatingBatchReportModalMode.matings),
			columns: new Array<JSX.Element>(5),
		};
		matingBatchReportsData[2] = {
			description: this.getMatingBatchReportMatingDescription(),
			columns: new Array<JSX.Element>(5),
		};
		matingBatchReportsData[3] = {
			description: this.GenerateDescriptionHeader(localized('Farrowings'), MatingBatchReportModalMode.farrowings),
			columns: new Array<JSX.Element>(5),
		};
		matingBatchReportsData[4] = {
			description: this.getMatingBatchReportFarrowingDescription(),
			columns: new Array<JSX.Element>(5),
		};
		matingBatchReportsData[5] = {
			description: this.GenerateDescriptionHeader(localized('Weanings'), MatingBatchReportModalMode.weanings),
			columns: new Array<JSX.Element>(5),
		};
		matingBatchReportsData[6] = {
			description: this.getMatingBatchReportWeaningDescription(),
			columns: new Array<JSX.Element>(5),
		};
		matingBatchReportsData = this.generateMatingBatchReportsData(matingBatchReportsData);
		return matingBatchReportsData;
	}
	private generateAmountMatingBatchesPerColumns() {
		return this.state.matingBatchReportSetting && this.state.matingBatchReportSetting.batchesPerColumn
			? this.state.matingBatchReportSetting.batchesPerColumn.map((a, index) => {
					return (
						<SkioldIntegerInput
							itemFromParent={index}
							text={a}
							onChangeNumber={this.onChangeMatingBatchNumber}
							onBlur={this.onBlurMatingBatchNumber}
							className="AmountMatingBatchesPerColumns"
							key={'batch' + index}
						/>
					);
			  })
			: this.initMatingBatchPerColumn();
	}

	private initMatingBatchPerColumn = () => {
		const items: JSX.Element[] = [];
		for (let index = 0; index < 5; index++) {
			items.push(
				<SkioldIntegerInput
					itemFromParent={index}
					text={0}
					onChangeNumber={this.onChangeMatingBatchNumber}
					onBlur={this.onBlurMatingBatchNumber}
					className="AmountMatingBatchesPerColumns"
					key={'batch' + index}
				/>,
			);
		}
		return items;
	};

	private onChangeMatingBatchNumber = (number?: number, itemFromParent?: number) => {
		if (itemFromParent !== undefined) {
			let matingBatchSettingCopy = { ...this.state.matingBatchReportSetting } as IMatingBatchReportSetting;
			let batchesPerColumn = matingBatchSettingCopy.batchesPerColumn
				? [...matingBatchSettingCopy.batchesPerColumn]
				: undefined;
			if (batchesPerColumn) {
				batchesPerColumn[itemFromParent] = number!;
			} else {
				matingBatchSettingCopy = MatingBatchReportSetting.fromJS({
					type: AnimalReportType.MatingBatchReportSetting,
					siteId: this.props.profile.siteId,
				} as IMatingBatchReportSetting);
				batchesPerColumn = new Array<number>(5);
				for (let index = 0; index < batchesPerColumn.length; index++) {
					batchesPerColumn[index] = 0;
				}
				batchesPerColumn[itemFromParent] = number!;
			}
			matingBatchSettingCopy.batchesPerColumn = batchesPerColumn;
			this.setState({ matingBatchReportSetting: matingBatchSettingCopy });
		}
	};

	private onBlurMatingBatchNumber = (number?: number, itemFromParent?: number) => {
		if (number !== undefined && itemFromParent !== undefined && this.state.matingBatchReportSetting) {
			let matingBatchSettings = { ...this.state.matingBatchReportSetting } as IMatingBatchReportSetting;
			if (matingBatchSettings.batchesPerColumn) {
				matingBatchSettings.batchesPerColumn = matingBatchSettings.batchesPerColumn.map(bpc => (bpc ? bpc : 1));
			}
			this.props.saveMatingBatchReportSetting(matingBatchSettings);
			this.setState({ matingBatchReportSetting: matingBatchSettings });
		}
	};

	public generateMatingBatchReportsData(matingBatchReportsData: MatingBatchReportsData[]) {
		let matingReports: MatingReport[] | undefined;
		let farrowingReports: FarrowingReport[] | undefined;
		let weaningReports: WeaningReport[] | undefined;
		if (
			this.props.MatingBatchReports &&
			this.props.MatingBatchReports.matingReport &&
			this.props.MatingBatchReports.farrowingReport &&
			this.props.MatingBatchReports.weaningReport
		) {
			matingReports = this.props.MatingBatchReports.matingReport!;
			farrowingReports = this.props.MatingBatchReports.farrowingReport!;
			weaningReports = this.props.MatingBatchReports.weaningReport!;
		}

		for (let index = 0; index < 5; index++) {
			this.renderMatingColumn(matingReports, index, matingBatchReportsData);
			this.renderFarrowingColumn(farrowingReports, index, matingBatchReportsData);
			this.renderWeaningColumn(weaningReports, index, matingBatchReportsData);
		}

		return matingBatchReportsData;
	}

	private renderWeaningColumn(
		weaningReports: WeaningReport[] | undefined,
		index: number,
		matingBatchReportsData: MatingBatchReportsData[],
	) {
		if (weaningReports && weaningReports[index]) {
			let weaningReport = weaningReports[index];
			matingBatchReportsData[5].columns[index] = (
				<div>
					<p>{this.GenerateMatingBatchNumbers(weaningReport.matingBatch)}</p>
					<p>
						{weaningReport.matingBatch && weaningReport.matingBatch.matingPeriodStart
							? getDateString(weaningReport.matingBatch!.matingPeriodStart)
							: ' '}
					</p>
					<p>
						{weaningReport.matingBatch && weaningReport.matingBatch.matingPeriodEnd
							? getDateString(weaningReport.matingBatch.matingPeriodEnd)
							: ' '}
					</p>
				</div>
			);
			matingBatchReportsData[6].columns[index] = (
				<div className="valueRows">
					<p> {weaningReport.totalWeanings} </p>
					<p> {weaningReport.nursingSows} </p>
					<p> {weaningReport.needWeaning} </p>
					<p> {weaningReport.avgAlivePerLitter && weaningReport.avgAlivePerLitter.toFixed(1)} </p>
					<p> {weaningReport.avgDeadPerLitter && weaningReport.avgDeadPerLitter.toFixed(1)} </p>
					<p> {weaningReport.avgWeanedPerLitter && weaningReport.avgWeanedPerLitter.toFixed(1)} </p>
					<p> {weaningReport.avgWeanedPerWeaninig && weaningReport.avgWeanedPerWeaninig.toFixed(1)} </p>
					<p> {weaningReport.avgWeight && weaningReport.avgWeight.toFixed(1)} </p>
					<p> {weaningReport.totalWeanedPiglets} </p>
					<p> {weaningReport.deadToAliveRate && weaningReport.deadToAliveRate.toFixed(1)} </p>
					<p> {weaningReport.avgNursingDays && weaningReport.avgNursingDays.toFixed(1)} </p>
				</div>
			);
		} else {
			matingBatchReportsData[5].columns[index] = <div />;
			matingBatchReportsData[6].columns[index] = (
				<div className="valueRows">{this.renderEmptyParagraphs(11)}</div>
			);
		}
	}

	private renderFarrowingColumn(
		farrowingReports: FarrowingReport[] | undefined,
		index: number,
		matingBatchReportsData: MatingBatchReportsData[],
	) {
		if (farrowingReports && farrowingReports[index]) {
			let farrowingReport = farrowingReports[index];
			matingBatchReportsData[3].columns[index] = (
				<div>
					<p>{this.GenerateMatingBatchNumbers(farrowingReport.matingBatch)}</p>
					<p>
						{farrowingReport.matingBatch && farrowingReport.matingBatch.matingPeriodStart
							? getDateString(farrowingReport.matingBatch.matingPeriodStart)
							: ' '}
					</p>
					<p>
						{farrowingReport.matingBatch && farrowingReport.matingBatch.matingPeriodEnd
							? getDateString(farrowingReport.matingBatch!.matingPeriodEnd)
							: ' '}
					</p>
				</div>
			);
			matingBatchReportsData[4].columns[index] = (
				<div className="valueRows">
					<p> {farrowingReport.totalFarrowings} </p>
					<p> {farrowingReport.thereOfFirstParity} </p>
					<p> {farrowingReport.needFarrowing} </p>
					<p> {farrowingReport.totalLiveBorn} </p>
					<p> {farrowingReport.avgAlive && farrowingReport.avgAlive.toFixed(1)} </p>
					<p>
						{' '}
						{farrowingReport.avgLivebornFirstParity &&
							farrowingReport.avgLivebornFirstParity.toFixed(1)}{' '}
					</p>
					<p> {farrowingReport.avgDead && farrowingReport.avgDead.toFixed(1)} </p>
					<p> {farrowingReport.stillbornToAliveRate && farrowingReport.stillbornToAliveRate.toFixed(1)} </p>
					<p> {farrowingReport.farrowingRate && farrowingReport.farrowingRate.toFixed(1)} </p>
					<p> {farrowingReport.avgPregnantDays && farrowingReport.avgPregnantDays.toFixed(1)} </p>
				</div>
			);
		} else {
			matingBatchReportsData[3].columns[index] = <div />;
			matingBatchReportsData[4].columns[index] = (
				<div className="valueRows">{this.renderEmptyParagraphs(10)}</div>
			);
		}
	}

	private GenerateMatingBatchNumbers(matingBatch?: IMatingBatch) {
		// Adds a &nbsp
		let matingBatches = '\u00A0';
		if (matingBatch) {
			let matingBatchesInUse = this.props.matingBatches.filter(
				batch =>
					batch.matingPeriodStart &&
					matingBatch.matingPeriodEnd &&
					batch.matingPeriodEnd &&
					matingBatch.matingPeriodStart &&
					batch.matingPeriodStart >= matingBatch.matingPeriodStart &&
					batch.matingPeriodEnd <= matingBatch.matingPeriodEnd,
			);
			if (matingBatchesInUse.length === 1 && matingBatchesInUse[0].batchNumber) {
				matingBatches = matingBatchesInUse[0].batchNumber.toString();
			}

			// Might be used later, if Mette change her mind
			// let firstNumber: number | undefined;
			// let previousNumber: number | undefined;
			// matingBatchesInUse.forEach((batch, index) => {
			// 	if (batch.batchNumber !== undefined) {
			// 		if (index === 0) {
			// 			matingBatches += batch.batchNumber.toString();
			// 			previousNumber = batch.batchNumber;
			// 			firstNumber = batch.batchNumber;
			// 		} else {
			// 			if (previousNumber === batch.batchNumber - 1) {
			// 				previousNumber = batch.batchNumber;
			// 				if (index === matingBatchesInUse.length - 1) {
			// 					matingBatches += ' - ' + (previousNumber ? previousNumber.toString() : '');
			// 				}
			// 			} else {
			// 				if (firstNumber !== previousNumber) {
			// 					matingBatches += ' - ' + (previousNumber ? previousNumber.toString() : '');
			// 				}
			// 				previousNumber = batch.batchNumber;
			// 				firstNumber = batch.batchNumber;
			// 				matingBatches += ', ' + batch.batchNumber.toString();
			// 			}
			// 		}
			// 	}
			// });
		}

		return matingBatches;
	}

	private renderMatingColumn(
		matingReports: MatingReport[] | undefined,
		index: number,
		matingBatchReportsData: MatingBatchReportsData[],
	) {
		if (matingReports && matingReports[index]) {
			let matingReport = matingReports[index];
			matingBatchReportsData[1].columns[index] = (
				<div>
					<p>{this.GenerateMatingBatchNumbers(matingReport.matingBatch)}</p>
					<p>
						{matingReport.matingBatch && matingReport.matingBatch.matingPeriodEnd
							? getDateString(matingReport.matingBatch!.matingPeriodStart)
							: ' '}
					</p>
					<p>
						{matingReport.matingBatch && matingReport.matingBatch.matingPeriodEnd
							? getDateString(matingReport.matingBatch!.matingPeriodEnd)
							: ' '}
					</p>
				</div>
			);

			matingBatchReportsData[2].columns[index] = (
				<div className="valueRows">
					<p> {matingReport.totalMatings} </p>
					<p> {matingReport.youngAnimalMatings} </p>
					<p> {matingReport.returnSows} </p>
					<p> {matingReport.totalDepartures} </p>
					<p> {matingReport.departureNonPregnant} </p>
					<p> {matingReport.thereOfAbortions} </p>
					<p> {matingReport.departureKilled} </p>
					<p> {matingReport.departureDead} </p>
					<p> {matingReport.weaningToFirstMating && matingReport.weaningToFirstMating.toFixed(1)} </p>
					<p> {matingReport.avgWasteDays && matingReport.avgWasteDays.toFixed(1)} </p>
					<p> {matingReport.animalsToFarrowing} </p>
				</div>
			);
		} else {
			matingBatchReportsData[1].columns[index] = <div />;
			matingBatchReportsData[2].columns[index] = (
				<div className="valueRows">{this.renderEmptyParagraphs(11)}</div>
			);
		}
	}

	private fetchMatingReport = () => {
		if (
			this.state.matingBatchReportSetting &&
			this.state.matingBatchReportSetting.batchesPerColumn &&
			this.state.matingBatchReportSetting.batchesPerColumn.reduce((a, b) => a + b) > 27
		) {
			alert(localized('maxBatchesIs27'));
			return;
		}
		this.props.getMatingBatchReportsBySiteAndDate(this.state.tmpDate, this.props.profile.siteId!);
	};

	private getMatingBatchReportMatingDescription() {
		return (
			<div className="valueRows">
				<p> {localized('TotalMatings')} </p>
				<p> {localized('YoungAnimalMatings')} </p>
				<p> {localized('ReturnSows')} </p>
				<p> {localized('TotalDepartures')} </p>
				<p> {localized('DepartureNonPregnant')} </p>
				<p> {localized('ThereOfAbortions')} </p>
				<p> {localized('DepartureKilled')} </p>
				<p> {localized('DepartureDead')} </p>
				<p> {localized('WeaningToFirstMating')} </p>
				<p> {localized('AvgWasteDays')} </p>
				<p> {localized('AnimalsToFarrowing')} </p>
			</div>
		);
	}

	private getMatingBatchReportFarrowingDescription() {
		return (
			<div className="valueRows">
				<p> {localized('TotalFarrowings')} </p>
				<p> {localized('ThereOfFirstParity')} </p>
				<p> {localized('NeedFarrowing')} </p>
				<p> {localized('TotalLiveBorn')} </p>
				<p> {localized('AvgAlive')} </p>
				<p> {localized('AvgLivebornFirstParity')} </p>
				<p> {localized('AvgDead')} </p>
				<p> {localized('StillbornToAliveRate')} </p>
				<p> {localized('FarrowingRate')} </p>
				<p> {localized('AvgPregnantDays')} </p>
			</div>
		);
	}

	private getMatingBatchReportWeaningDescription() {
		return (
			<div className="valueRows">
				<p> {localized('TotalWeanings')} </p>
				<p> {localized('NursingSows')} </p>
				<p> {localized('NeedWeaning')} </p>
				<p> {localized('AvgAlivePerLitter')} </p>
				<p> {localized('AvgDeadPerLitter')} </p>
				<p> {localized('AvgWeanedPerLitter')} </p>
				<p> {localized('AvgWeanedPerWeaninig')} </p>
				<p> {localized('AvgWeight')} </p>
				<p> {localized('TotalWeanedPiglets')} </p>
				<p> {localized('DeadToAliveRate')} </p>
				<p> {localized('AvgNursingDays')} </p>
			</div>
		);
	}

	private generateColumns() {
		return [
			{
				id: 'Matings',
				filterable: false,
				sortable: false,
				width: 270,
				accessor: (matingBatchReportData: MatingBatchReportsData) => matingBatchReportData.description,
			},
			{
				id: 'batch1',
				filterable: false,
				sortable: false,
				accessor: (matingBatchReportData: MatingBatchReportsData) => matingBatchReportData.columns![0],
			},
			{
				id: 'batch2',
				filterable: false,
				sortable: false,
				accessor: (matingBatchReportData: MatingBatchReportsData) => matingBatchReportData.columns![1],
			},
			{
				id: 'batch3',
				filterable: false,
				sortable: false,
				accessor: (matingBatchReportData: MatingBatchReportsData) => matingBatchReportData.columns![2],
			},
			{
				id: 'batch4',
				filterable: false,
				sortable: false,
				accessor: (matingBatchReportData: MatingBatchReportsData) => matingBatchReportData.columns![3],
			},
			{
				id: 'batch5',
				filterable: false,
				sortable: false,
				accessor: (matingBatchReportData: MatingBatchReportsData) => matingBatchReportData.columns![4],
			},
		];
	}

	private download = async () => {
		this.setState({ isDownloadingMatingBatchReport: true });
		await GetMatingBatchReportPdf(
			this.state.tmpDate,
			this.props.profile.siteId!,
			'MatingBatchReports ' + getDateString(this.state.tmpDate) + '.pdf',
			Intl.DateTimeFormat().resolvedOptions().timeZone,
			this.props.profile.language,
		);
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(MatingBatchReport);
