import ObjectID from 'bson-objectid';
import memoize from 'memoize-one';
import moment from 'moment';
import React from 'react';
import { Option } from 'react-dropdown';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
	IMatingBatch,
	IMatingBatchSetting,
	MatingBatch,
	MatingBatchSetting,
	PregnancyValidationType,
} from 'shared/api/api';
import { areDatesEqual, getDateString } from 'shared/helpers/date-helpers';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { calculateDays, deepCopy, isEmpty } from 'shared/helpers/general-helpers';
import {
	GetSyncData as SyncMatingBatchSetting,
	SaveMatingBatchSetting,
} from 'shared/state/ducks/mating-batch-setting/operations';
import { GetSyncData as SyncMatingBatch, SaveMatingBatches } from 'shared/state/ducks/mating-batch/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { showAlert } from 'web/view/components/skiold-alert/skiold-alert';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldDatePicker } from 'web/view/components/skiold-components/skiold-date-picker/skiold-date-picker';
import { SkioldFormDropdown } from 'web/view/components/skiold-components/skiold-dropdown/skiold-form-dropdown';
import { SkioldFormsWrapper } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { SkioldFormIntegerInput } from 'web/view/components/skiold-components/skiold-integer-input/skiold-form-integer-input';
import { SkioldIntegerInput } from 'web/view/components/skiold-components/skiold-integer-input/skiold-integer-input';
import { SkioldModal } from 'web/view/components/skiold-components/skiold-modal/skiold-modal';
import SkioldTableGrid from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { GroupedHeader } from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid-grouped-header';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { DefaultValidationValues } from '../validation-setup/default-validation-values';
import { DeleteMatingBatch } from './delete-mating-batch';
import ManuelMatingBatchSettings from './manuel-mating-batch-setting';
import { findMinimumDate, repeatManuel } from './mating-batch-setting-helper';
import './mating-batch-setting.scss';
import { TimesToRepeat } from './repeat-modal';

const mapStateToProps = (state: WebAppState) => {
	return {
		siteId: state.profile.active!.siteId,
		matingBatches: state.matingBatches.entities,
		matingBatchSetting: state.matingBatchSetting.currentMatingBatchSetting,
		validationSetup: state.validationSetup.entity,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	SaveMatingBatches: (batches: IMatingBatch[]) => SaveMatingBatches(batches)(dispatch),
	syncMatingBatches: () => SyncMatingBatch()(dispatch),
	syncMatingBatchSetting: () => SyncMatingBatchSetting()(dispatch),
	SaveMatingBatchSetting: (matingBatchSetting: IMatingBatchSetting) =>
		SaveMatingBatchSetting(matingBatchSetting)(dispatch),
});

interface State {
	matingBatches: IMatingBatch[];
	matingBatchSetting: IMatingBatchSetting;
	matingPeriods: Option[];
	weekCycles: Option[];
	newMatingBatches: IMatingBatch[];
	deleteModalOpen: boolean;
	showManuel: boolean;
	isRepeatModalOpen: boolean;
	timesToRepeat: number | undefined;
	changesDateOnce: boolean;
	batchNumberRotationDate?: Date;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
class MatingBatchSettings extends React.PureComponent<Props, State> {
	private resetMatingBatch = memoize((matingBatches: IMatingBatch[]) => {
		this.setState(
			{
				matingBatches: this.props.matingBatches.concat().sort((a: IMatingBatch, b: IMatingBatch) => {
					return a.matingPeriodStart!.getTime() - b.matingPeriodStart!.getTime();
				}),
			},
			this.scrollToCurrentMatingBatch,
		);
	});
	public SkioldTableRef: any;
	private setTableRef = (m: any) => {
		if (m) {
			this.SkioldTableRef = m;
		}
	};

	public DefaultmatingPeriodOption: Option = {
		label: '7',
		value: '7',
	};
	public DefaultweekCycleOption: Option = {
		label: '21',
		value: '21',
	};
	constructor(props: Props) {
		super(props);
		let weekCycles = this.generateAllWeekCycles();
		let sortedBatches = props.matingBatches.concat().sort((a: IMatingBatch, b: IMatingBatch) => {
			return a.matingPeriodStart!.getTime() - b.matingPeriodStart!.getTime();
		});
		this.state = {
			matingBatches: sortedBatches || [],
			matingBatchSetting:
				props.matingBatchSetting &&
				!isEmpty(props.matingBatchSetting) &&
				props.matingBatchSetting!.id !== undefined
					? this.handleExistingMatingBatchSetting(props.matingBatchSetting!)
					: this.createSetting(false),
			matingPeriods: this.generateMatingPeriod(),
			weekCycles,
			newMatingBatches: [],
			deleteModalOpen: false,
			showManuel: false,
			isRepeatModalOpen: false,
			timesToRepeat: undefined,
			changesDateOnce: false,
			batchNumberRotationDate: findMinimumDate(sortedBatches),
		};

		this.setWrapperRef = this.setWrapperRef.bind(this);
	}

	private matingBatchSettingRef: any;
	private sowManagementLinks: any;
	private settingsPageRef: any;

	// Verify if user try to leave mating batch setting, without a mating batch 1 year forward
	public handleClickOutside = event => {
		if (
			// Allow clicks on specific items (datepicker and Dropdown menu)
			!event.target.classList.value.includes('react-datepicker') &&
			!event.target.classList.value.includes('Dropdown') &&
			!this.findAncestor(event.target, 'ReactModalPortal') &&
			((this.settingsPageRef && !this.settingsPageRef.contains(event.target)) ||
				(this.sowManagementLinks &&
					this.sowManagementLinks !== event.target &&
					this.sowManagementLinks.contains(event.target))) &&
			((this.state.matingBatches && this.state.matingBatches.length === 0) ||
				calculateDays(
					new Date(),
					this.state.matingBatches[this.state.matingBatches.length - 1].matingPeriodStart,
				) < 365)
		) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_MATING_BATCH_1_YEAR));
		}
	};

	private findAncestor(el: any, cls: any) {
		// tslint:disable-next-line: no-conditional-assignment
		while ((el = el.parentElement) && !el.classList.contains(cls)) {}
		return el;
	}

	// Sets div elements for mating batch settings, and its outer parent.
	public setWrapperRef(node) {
		if (node) {
			this.settingsPageRef = node.parentElement.parentElement.parentElement;
			this.matingBatchSettingRef = node;
			this.sowManagementLinks = node.parentElement.firstChild;
		}
	}

	public async componentDidUpdate() {
		this.resetMatingBatch(this.props.matingBatches);
	}
	public componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	public async componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
		await this.props.syncMatingBatches();
		await this.props.syncMatingBatchSetting();

		this.setState(
			{
				matingBatches: this.props.matingBatches.concat().sort((a: IMatingBatch, b: IMatingBatch) => {
					return a.matingPeriodStart!.getTime() - b.matingPeriodStart!.getTime();
				}),
			},
			this.scrollToCurrentMatingBatch,
		);
	}

	// Scrolls to current mating batch
	public scrollToCurrentMatingBatch = () => {
		const now = new Date();
		const currentMatingBatchIndex = this.state.matingBatches.findIndex(
			batch => batch.matingPeriodStart! <= now && batch.matingPeriodEnd! >= now,
		);
		if (this.SkioldTableRef && this.SkioldTableRef.virtualTableRef) {
			this.SkioldTableRef.virtualTableRef.scrollToRow(currentMatingBatchIndex);
		}
	};
	private getCycleDefault = (periodValue: number = 7, isManuel: boolean = false) => {
		if (isManuel) {
			return 21;
		}
		if (periodValue === 7) {
			return 21;
		}
		if (periodValue === 14) {
			return 11;
		}
		if (periodValue === 21) {
			return 7;
		}
		return 21;
	};

	public periodChanged = (period: Option) => {
		const periodNumeric = +period.value;
		const weekCycle = this.getCycleDefault(periodNumeric, this.state.matingBatchSetting.isManuel);

		this.setState(
			prevState => ({
				matingBatchSetting: {
					...prevState.matingBatchSetting,
					matingPeriod: periodNumeric,
					weekCycle: weekCycle,
					batchNumberRotation: weekCycle,
				},
			}),
			this.changeStartDate,
		);
	};

	public weekCycleChanged = (weeks: Option) => {
		const weeksNumeric = +weeks.value;
		this.setState(
			prevState => ({
				matingBatchSetting: {
					...prevState.matingBatchSetting,
					weekCycle: weeksNumeric,
					batchNumberRotation: weeksNumeric,
				},
			}),
			this.changeStartDate,
		);
	};

	public startDateChanged = (newDate: Date) => {
		const weekday = this.getWeekDay(newDate.getDay());
		this.setState(prevState => ({
			matingBatchSetting: { ...prevState.matingBatchSetting, startDate: newDate, weekday },
			batchNumberRotationDate: newDate,
		}));
	};

	public rotationDateChanges = (newDate: Date) => {
		this.setState({
			batchNumberRotationDate: newDate,
		});
	};

	public setPregnantDays = (days: number | undefined) => {
		this.setState(
			prevState => ({
				matingBatchSetting: { ...prevState.matingBatchSetting, pregnantDay: days! },
			}),
			this.changeStartDate,
		);
	};

	public setNursingDays = (days: number | undefined) => {
		this.setState(
			prevState => ({
				matingBatchSetting: { ...prevState.matingBatchSetting, nursingDays: days! },
			}),
			this.changeStartDate,
		);
	};

	public setMatingBatchNumberRotation = (matingBatchNumber: number | undefined) => {
		this.setState(prevState => ({
			matingBatchSetting: {
				...prevState.matingBatchSetting,
				batchNumberRotation: matingBatchNumber!,
			},
		}));
	};

	private setBatchNumbers = () => {
		let batchesCopy = [...this.state.matingBatches];
		let changedBatches: IMatingBatch[] = [];
		let matingBatchNumber = this.state.matingBatchSetting.batchNumberRotation;
		let rotationDate = this.state.batchNumberRotationDate;
		if (matingBatchNumber && rotationDate) {
			let batch = batchesCopy.find(x => rotationDate && x.matingPeriodEnd && x.matingPeriodEnd >= rotationDate);
			if (batch) {
				const batchIndex = batchesCopy.findIndex(b => batch && b.id === batch.id);

				let batchNumberCoutner = 1;
				for (let i = batchIndex; i < batchesCopy.length; i++) {
					if (batchNumberCoutner > matingBatchNumber) {
						batchNumberCoutner = 1;
					}
					batchesCopy[i] = { ...batchesCopy[i], batchNumber: batchNumberCoutner };
					changedBatches.push(batchesCopy[i]);
					batchNumberCoutner++;
				}
			}
			let currentNewBatches = this.state.newMatingBatches.filter(
				batch => rotationDate && batch.matingPeriodEnd && batch.matingPeriodEnd < rotationDate,
			);
			this.setState({ matingBatches: batchesCopy, newMatingBatches: currentNewBatches.concat(changedBatches) });
		}
	};

	public setManuelOption = () => {
		let isManuelState = this.state.matingBatchSetting.isManuel;
		let isManuelProd = this.props.matingBatchSetting.isManuel;
		let matingBatchSetting =
			this.props.matingBatchSetting &&
			!isEmpty(this.props.matingBatchSetting) &&
			this.props.matingBatchSetting!.id !== undefined &&
			isManuelProd !== isManuelState
				? this.handleExistingMatingBatchSetting({ ...this.props.matingBatchSetting!, isManuel: !isManuelState })
				: this.createSetting(!isManuelState);

		this.setState({
			matingBatchSetting,
			showManuel: false,
		});
	};

	public showManuelTable = () => {
		let validDates = this.props.matingBatches
			.filter(batch => batch.matingPeriodStart && batch.matingPeriodStart >= new Date().withoutTime())
			.map(batch => batch.matingPeriodStart!);
		if (
			validDates.length > 0 &&
			this.props.matingBatches.length > 0 &&
			!validDates.find(validDate => areDatesEqual(validDate, this.state.matingBatchSetting.startDate))
		) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_NEW_STEART_DATE_MUST_BE_EQUAL_TO_EXISTING));
			return;
		}
		this.setState({ showManuel: true }, () => {
			if (this.manuelMatingSettingRef) {
				this.manuelMatingSettingRef.initManuelMatingBatches();
			}
		});
	};

	public render() {
		return (
			<div ref={this.setWrapperRef} className="mating-batch-setting">
				<ViewWeb className="firstColumn">
					<ViewWeb className="contentContainer">
						<SkioldFormsWrapper
							formRows={this.getFormRows()}
							containerClassName="forms-wrapper-container"
						/>
						{this.state.matingBatchSetting.isManuel && (
							<ViewWeb className="ok-btn-container">
								<SkioldButton title={localized('Ok')} onPress={this.showManuelTable} />
							</ViewWeb>
						)}
					</ViewWeb>

					<ViewWeb className="wrapper1">
						<SkioldButton title={localized('manuel')} onPress={this.setManuelOption} />
						{this.state.matingBatchSetting.isManuel ? (
							<SkioldButton
								title={localized('repeat')}
								onPress={this.openRepeatDiaglog}
								disabled={!this.props.matingBatchSetting.isManuel && !this.state.showManuel}
							/>
						) : (
							<SkioldButton title={localized('calculate')} onPress={this.calculateMatingBatches} />
						)}
						<SkioldButton
							title={localized('Save')}
							onPress={this.state.showManuel ? this.saveManuelMatingBatches : this.save}
						/>
						<SkioldButton title={localized('Delete')} onPress={this.openModal} />
					</ViewWeb>
				</ViewWeb>
				<ViewWeb className="secondColumn">
					{this.state.showManuel && this.state.matingBatchSetting.isManuel ? (
						<ManuelMatingBatchSettings
							ref={this.setManuelMatingSettingRef}
							matingBatchSetting={this.state.matingBatchSetting}
						/>
					) : (
						<SkioldTableGrid
							columns={this.generateColumns()}
							data={this.state.matingBatches}
							ColumnExtensions={this.generateColumnsExtensions()}
							groupedColumns={this.groupedColumns()}
							filterable={false}
							sortable={false}
							tableKey="mating-batch-settings-table"
							ref={this.setTableRef}
							className="no-border-radius"
						/>
					)}
				</ViewWeb>
				<SkioldModal padding="0" isOpen={this.state.deleteModalOpen} close={this.closeModal} minWidth={'400px'}>
					<DeleteMatingBatch matingBatches={this.state.matingBatches} closeModal={this.closeModal} />
				</SkioldModal>
				<SkioldModal
					className="repeat-container"
					minWidth={'200px'}
					padding="0"
					isOpen={this.state.isRepeatModalOpen}
					close={this.closeModal}
				>
					<TimesToRepeat closeModal={this.closeModal} repeat={this.repeatMatingBatches} />
				</SkioldModal>
			</div>
		);
	}

	public openRepeatDiaglog = () => {
		this.setState({ isRepeatModalOpen: true });
	};

	private groupedColumns = memoize(() => {
		const groupedHeader: GroupedHeader[] = [
			{
				title: localized('matingPeriod'),
				children: [{ columnName: 'matingPeriodStart' }, { columnName: 'matingPeriodEnd' }],
			},
			{
				title: localized('FarrowingPeriod'),
				children: [{ columnName: 'farrowingPeriodStart' }, { columnName: 'farrowingPeriodEnd' }],
			},
			{
				title: localized('weaningPeriod'),
				children: [{ columnName: 'weaningPeriodStart' }, { columnName: 'weaningPeriodEnd' }],
			},
		];
		return groupedHeader;
	});

	public generateColumnsExtensions = memoize(() => {
		return [
			{
				width: 100,
				columnName: 'batchNumber',
			},
			{
				width: 60,
				columnName: 'days',
			},
			{
				width: 85,
				columnName: 'matingPeriodStart',
			},
			{
				width: 85,
				columnName: 'matingPeriodEnd',
			},
			{
				width: 85,
				columnName: 'farrowingPeriodStart',
			},
			{
				width: 85,
				columnName: 'farrowingPeriodEnd',
			},
			{
				width: 85,
				columnName: 'weaningPeriodStart',
			},
			{
				width: 85,
				columnName: 'weaningPeriodEnd',
			},
		];
	});

	private generateColumns = memoize(() => {
		return [
			{
				title: localized('batchNum'),
				name: 'batchNumber',
			},
			{
				title: localized('DAYS'),
				name: 'days',
				getCellValue: (e: MatingBatch) =>
					e.matingPeriodStart &&
					e.matingPeriodEnd &&
					calculateDays(e.matingPeriodStart, e.matingPeriodEnd) + 1, // adding 1 because the first day itself count as 1
			},
			{
				title: localized('Start'),
				getCellValue: (d: MatingBatch) => getDateString(d.matingPeriodStart),
				name: 'matingPeriodStart',
			},
			{
				title: localized('End'),
				name: 'matingPeriodEnd',
				getCellValue: (d: MatingBatch) => getDateString(d.matingPeriodEnd),
			},
			{
				title: localized('Start'),
				name: 'farrowingPeriodStart',
				getCellValue: (d: MatingBatch) => getDateString(d.farrowingPeriodStart),
			},
			{
				title: localized('End'),
				name: 'farrowingPeriodEnd',
				getCellValue: (d: MatingBatch) => getDateString(d.farrowingPeriodEnd),
			},
			{
				title: localized('Start'),
				name: 'weaningPeriodStart',
				getCellValue: (d: MatingBatch) =>
					getDateString(
						moment(d.farrowingPeriodStart).add(this.state.matingBatchSetting.nursingDays, 'days').toDate(),
					),
			},
			{
				title: localized('End'),
				name: 'weaningPeriodEnd',
				getCellValue: (d: MatingBatch) =>
					getDateString(
						moment(d.farrowingPeriodEnd).add(this.state.matingBatchSetting.nursingDays, 'days').toDate(),
					),
			},
		];
	});

	private openModal = () => {
		this.setState({ deleteModalOpen: true });
	};

	private closeModal = () => {
		this.setState({ deleteModalOpen: false, isRepeatModalOpen: false });
	};

	private manuelMatingSettingRef: any;
	private setManuelMatingSettingRef = (ref: any) => {
		this.manuelMatingSettingRef = ref;
	};

	private getFormRows() {
		let formRows = new Array<FormRow>();

		formRows.push(this.getMatingBatch());
		formRows.push(this.getStartDateForBatch());
		formRows.push(this.getStartWeekDay());

		if (!this.state.matingBatchSetting.isManuel) {
			formRows.push(this.getDaysForMatingPeriod());
		}

		formRows.push(this.getCycleInWeeks());
		if (!this.state.matingBatchSetting.isManuel) {
			formRows.push(this.getMatingBatchNumberRotation());
		}

		formRows.push(this.getPregnantDays());
		formRows.push(this.getNursingDays());

		return formRows;
	}

	private getMatingBatch(): FormRow {
		return {
			name: localized('matingBatchUpToAndIncluding'),
			component: (
				<TextWeb className="start-end-date-label">
					{getDateString(this.state.matingBatchSetting.endDate)}
				</TextWeb>
			),
		};
	}

	private getStartWeekDay(): FormRow {
		return {
			name: localized('weekdayStart'),
			component: (
				<TextWeb className="start-end-date-label">{localized(this.state.matingBatchSetting.weekday!)}</TextWeb>
			),
		};
	}

	private getStartDateForBatch(): FormRow {
		const now = new Date();
		let sortedPropsMatingBatches = this.props.matingBatches.concat().sort((a: IMatingBatch, b: IMatingBatch) => {
			return a.matingPeriodStart!.getTime() - b.matingPeriodStart!.getTime();
		});
		let datesToHighlight = sortedPropsMatingBatches
			.filter(batch => batch.matingPeriodStart! >= now)
			.map(batch => batch.matingPeriodStart!);

		let minimumDate = findMinimumDate(sortedPropsMatingBatches, datesToHighlight);

		return {
			name: localized('StartDateForBatch'),
			component: (
				<SkioldDatePicker
					onDateChanged={this.startDateChanged}
					selectedDate={this.state.matingBatchSetting.startDate!}
					theme={'dark'}
					color={'grey'}
					includeTimeStamp={false}
					highlightDates={datesToHighlight}
					maxDate={new Date(8640000000000000)}
					minimumDate={minimumDate}
					openToDate={
						this.state.matingBatchSetting.startDate! < minimumDate
							? minimumDate
							: this.state.matingBatchSetting.startDate!
					}
				/>
			),
		};
	}

	private getDaysForMatingPeriod(): FormRow {
		return {
			name: localized('daysForMatingPeriod'),
			component: (
				<SkioldFormDropdown
					items={this.state.matingPeriods}
					onValueChanged={this.periodChanged}
					selectedValue={this.getOptionValue(this.state.matingBatchSetting.matingPeriod)}
				/>
			),
		};
	}

	private getCycleInWeeks(): FormRow {
		return {
			name: localized('cycleInWeeks'),
			component: (
				<SkioldFormDropdown
					items={this.generateWeekCycles(this.state.matingBatchSetting.matingPeriod!)}
					onValueChanged={this.weekCycleChanged}
					selectedValue={this.getOptionValue(this.state.matingBatchSetting.weekCycle)}
				/>
			),
		};
	}

	private getPregnantDays(): FormRow {
		return {
			name: localized('pregnantDays'),
			component: (
				<SkioldFormIntegerInput
					onChangeNumber={this.setPregnantDays}
					text={this.state.matingBatchSetting.pregnantDay}
				/>
			),
		};
	}

	private getNursingDays(): FormRow {
		return {
			name: localized('NursingDays'),
			component: (
				<SkioldFormIntegerInput
					onChangeNumber={this.setNursingDays}
					text={this.state.matingBatchSetting.nursingDays}
				/>
			),
		};
	}

	private getMatingBatchNumberRotation(): FormRow {
		const now = new Date();
		let sortedPropsMatingBatches = this.props.matingBatches.concat().sort((a: IMatingBatch, b: IMatingBatch) => {
			return a.matingPeriodStart!.getTime() - b.matingPeriodStart!.getTime();
		});
		let datesToHighlight = sortedPropsMatingBatches
			.filter(batch => batch.matingPeriodStart! >= now)
			.map(batch => batch.matingPeriodStart!);

		let minimumDate = findMinimumDate(sortedPropsMatingBatches, datesToHighlight);

		return {
			name: localized('batchPerCycle'),
			component: (
				<ViewWeb className="batch-rotation-row">
					<SkioldIntegerInput
						text={this.state.matingBatchSetting.batchNumberRotation}
						onChangeNumber={this.setMatingBatchNumberRotation}
						className="batch-rotation-input"
					/>
					<SkioldDatePicker
						onDateChanged={this.rotationDateChanges}
						selectedDate={this.state.batchNumberRotationDate!}
						theme={'dark'}
						color={'grey'}
						includeTimeStamp={false}
						highlightDates={datesToHighlight}
						maxDate={new Date(8640000000000000)}
						minimumDate={minimumDate}
						openToDate={
							this.state.matingBatchSetting.startDate! < minimumDate
								? minimumDate
								: this.state.matingBatchSetting.startDate!
						}
					/>
					<SkioldButton
						className="batch-rotation-btn"
						title={localized('Ok')}
						onPress={this.setBatchNumbers}
					/>
				</ViewWeb>
			),
		};
	}

	private saveManuelMatingBatches = () => {
		if (this.manuelMatingSettingRef) {
			let batches = this.manuelMatingSettingRef.getManuelMatingBatches();
			let batchesToKeep = this.state.matingBatches.filter(
				batch => batch.matingPeriodStart! < this.state.matingBatchSetting.startDate!,
			);
			let newArray = batchesToKeep.concat(batches);
			this.setState(
				prevState => ({
					matingBatchSetting: {
						...prevState.matingBatchSetting,
						endDate: newArray[newArray.length - 1].matingPeriodEnd,
					},
					matingBatches: newArray,
					newMatingBatches: batches,
				}),
				this.save,
			);
		}
	};

	private calculateMatingBatches = () => {
		if (!this.validateCalculation()) {
			return;
		}

		let newBatches = [] as IMatingBatch[];
		let batches = this.getUnChangeableBatches();

		//Start date
		let startDate = this.getStartDate();
		let currentDate = this.getCurrentDate();
		//Calculated end date (2 years)
		const endDate = new Date(
			currentDate.getFullYear() + 2,
			currentDate.getMonth(),
			currentDate.getDate(),
			23,
			59,
			59,
			0,
		);

		let batchNum = batches && batches.length > 0 ? batches[0].batchNumber! + 1 : 1;
		for (
			let date = new Date(startDate);
			date < endDate;
			date.setDate(date.getDate() + this.state.matingBatchSetting.matingPeriod!)
		) {
			let rotateBatchNumberAt = this.state.matingBatchSetting.batchNumberRotation
				? this.state.matingBatchSetting.batchNumberRotation
				: this.state.matingBatchSetting.weekCycle!;
			if (rotateBatchNumberAt < batchNum) {
				batchNum = 1;
			}

			let newBatch = this.generateBatch(date, endDate);

			if (newBatch !== undefined) {
				newBatch.batchNumber = batchNum;
				newBatches.push(newBatch);
				batchNum++;
			}
		}
		//Merge new batches with unchanged batches
		let newArray = batches.concat(newBatches);
		const endDateReal = newBatches[newBatches.length - 1].matingPeriodEnd; //Set correct end date
		this.setState(prevState => ({
			matingBatchSetting: { ...prevState.matingBatchSetting, endDate: endDateReal, siteId: this.props.siteId },
			matingBatches: newArray.concat().sort((a: IMatingBatch, b: IMatingBatch) => {
				return a.matingPeriodStart!.getTime() - b.matingPeriodStart!.getTime();
			}),
			newMatingBatches: newBatches,
		}));
	};

	private generateBatch(date: Date, endDate: Date) {
		let matingStart = new Date(date);
		matingStart.setDate(matingStart.getDate());
		let matingEnd = new Date(date);
		matingEnd.setDate(matingEnd.getDate() + this.state.matingBatchSetting.matingPeriod! - 1);
		matingEnd.setHours(23, 59, 59, 0);

		//Makes sure that mating end date is before calculated end date
		if (matingEnd < endDate) {
			let farrowingStart = new Date(date);
			farrowingStart.setDate(farrowingStart.getDate() + this.state.matingBatchSetting.pregnantDay!);
			let farrowingEnd = new Date(date);
			farrowingEnd.setDate(
				farrowingEnd.getDate() +
					this.state.matingBatchSetting.matingPeriod! +
					this.state.matingBatchSetting.pregnantDay! -
					1,
			);
			farrowingEnd.setHours(23, 59, 59, 0);
			return MatingBatch.fromJS({
				matingPeriodStart: matingStart,
				matingPeriodEnd: matingEnd,
				farrowingPeriodStart: farrowingStart,
				farrowingPeriodEnd: farrowingEnd,
				siteId: this.props.siteId,
				id: new ObjectID().toHexString(), //Id is set here to make upsert easier
			} as IMatingBatch);
		}
		return undefined;
	}

	private getStartDate() {
		let currentDate = this.getCurrentDate();
		let startDate = new Date(this.state.matingBatchSetting.startDate!);
		if (this.isIdentical() && this.state.matingBatches.length > 0 && startDate < currentDate) {
			let nextBatch = this.state.matingBatches.find(x => x.matingPeriodEnd! >= currentDate);
			if (nextBatch) {
				startDate = moment(nextBatch.matingPeriodEnd!).add(1, 'second').toDate();
			}
		}
		return startDate;
	}

	private getUnChangeableBatches() {
		let currentDate = this.getCurrentDate();
		const dateToFilter =
			currentDate > this.state.matingBatchSetting.startDate!
				? currentDate
				: this.state.matingBatchSetting.startDate!;

		//Sort out every mating batch, before current date or the set Start date
		return this.props.matingBatches
			.filter(batch => batch.matingPeriodStart! <= dateToFilter)
			.sort((a: IMatingBatch, b: IMatingBatch) => {
				return b.matingPeriodStart!.getTime() - a.matingPeriodStart!.getTime();
			});
	}

	private save = () => {
		if (!this.validateSave()) {
			return;
		}
		this.props.SaveMatingBatches(this.state.newMatingBatches);
		let newSetting = deepCopy(this.state.matingBatchSetting);
		if (!this.isIdentical() && this.props.matingBatchSetting.id) {
			let oldSetting = deepCopy(this.props.matingBatchSetting);
			let newEndDate = new Date(this.state.matingBatchSetting.startDate!);
			newEndDate.setSeconds(newEndDate.getSeconds() - 1);
			oldSetting.endDate = newEndDate;
			this.props.SaveMatingBatchSetting(oldSetting);
			newSetting.id = undefined;
			newSetting.batchNumberRotation = this.state.matingBatchSetting.batchNumberRotation;
		}
		this.props.SaveMatingBatchSetting(newSetting);
		this.resetComponent();
	};

	private validateSave() {
		if (!this.validateCalculation()) {
			return false;
		}
		if (this.state.matingBatches.length < 1) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_NO_MATING_BATCHES_GENERATED));
			return false;
		}
		if (!this.state.matingBatchSetting.endDate) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_END_DATE_NOT_SET));
			return false;
		}
		return true;
	}

	private validateCalculation() {
		const doesDateExist = this.props.matingBatches.find(
			x => x.matingPeriodStart!.getTime() === this.state.matingBatchSetting.startDate!.getTime(),
		);
		const doesLaterDateExist = this.props.matingBatches.find(
			x => x.matingPeriodStart!.getTime() > this.state.matingBatchSetting.startDate!.getTime(),
		);
		if (
			!this.isIdentical() &&
			this.props.matingBatches.length > 0 &&
			this.state.matingBatchSetting.startDate! < this.getCurrentDate() &&
			doesLaterDateExist
		) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_NEW_STEART_DATE_MUST_BE_EQUAL_TO_EXISTING));
			return false;
		}

		if (!this.isIdentical() && this.props.matingBatches.length > 0 && !doesDateExist && doesLaterDateExist) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_NEW_STEART_DATE_MUST_BE_EQUAL_TO_EXISTING));
			return false;
		}
		if (!this.state.matingBatchSetting.pregnantDay) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_PREGNANT_DAYS_NOT_SET));
			return false;
		}
		if (!this.state.matingBatchSetting.nursingDays) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_NURSING_DAYS_NOT_SET));
			return false;
		}
		if (!this.state.matingBatchSetting.startDate) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_START_DATE_NOT_SET));
			return false;
		}
		if (!this.state.matingBatchSetting.matingPeriod) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_MATING_PERIOD_NOT_SET));
			return false;
		}
		if (!this.state.matingBatchSetting.weekCycle) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_WEEK_CYCLE_NOT_SET));
			return false;
		}
		if (
			this.state.matingBatchSetting.nursingDays + this.state.matingBatchSetting.pregnantDay >
			this.state.matingBatchSetting.weekCycle * this.state.matingBatchSetting.matingPeriod
		) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_PREGNANT_NURSING_DAYS_EXCEEDED));
			return false;
		}
		return true;
	}

	private resetComponent() {
		this.setState({ showManuel: false });
		alert(localized('Saved'));
	}

	private generateMatingPeriod() {
		let matingPeriods: Option[] = [
			{
				label: '7',
				value: '7',
			},
			{
				label: '14',
				value: '14',
			},
			{
				label: '21',
				value: '21',
			},
		];
		return matingPeriods;
	}

	private generateAllWeekCycles = () => {
		let weekCycles: Option[] = [];
		weekCycles.push({ label: '21', value: '21' }, { value: '22', label: '22' });
		weekCycles.push({ label: '11', value: '11' });
		weekCycles.push({ label: '7', value: '7' });
		return weekCycles;
	};

	private generateWeekCycles = (period: number, showManuel: boolean = false) => {
		let weekCycles: Option[] = [];
		if (showManuel) {
			weekCycles.push({ label: '21', value: '21' }, { value: '22', label: '22' });
			return weekCycles;
		}
		if (period === 7) {
			weekCycles.push({ label: '21', value: '21' }, { value: '22', label: '22' });
		}
		if (period === 14) {
			weekCycles.push({ label: '11', value: '11' });
		}
		if (period === 21) {
			weekCycles.push({ label: '7', value: '7' });
		}

		return weekCycles;
	};

	private getWeekDay(day: number) {
		let weekday = new Array(7);
		weekday[0] = 'Sunday';
		weekday[1] = 'Monday';
		weekday[2] = 'Tuesday';
		weekday[3] = 'Wednesday';
		weekday[4] = 'Thursday';
		weekday[5] = 'Friday';
		weekday[6] = 'Saturday';

		return weekday[day];
	}

	private isIdentical() {
		// sort by property Key, to compare values
		let matingState: any[] = [];
		let matingProps: any[] = [];

		for (let vehicle in this.state.matingBatchSetting) {
			if (vehicle) {
				matingState.push([vehicle, this.state.matingBatchSetting[vehicle]]);
			}
		}

		for (let vehicle in this.props.matingBatchSetting) {
			if (vehicle) {
				matingProps.push([vehicle, this.props.matingBatchSetting[vehicle]]);
			}
		}

		//re-insert to an object
		let objState = {};
		matingState.forEach(item => {
			objState[item[0]] = item[1];
		});
		//re-insert to an object
		let objProps = {};
		matingProps.forEach(item => {
			objProps[item[0]] = item[1];
		});

		const identical =
			JSON.stringify(objProps, this.ignoreEndDate).toLowerCase() ===
			JSON.stringify(objState, this.ignoreEndDate).toLowerCase();
		return identical;
	}

	private ignoreEndDate(key: any, value: any) {
		if (key === 'endDate') {
			return undefined;
		} else if (key === 'createdBy') {
			return undefined;
		} else if (key === 'createdOn') {
			return undefined;
		} else if (key === 'modifiedBy') {
			return undefined;
		} else if (key === 'modifiedOn') {
			return undefined;
		} else if (key === 'isDeleted') {
			return undefined;
		} else if (key === 'isManuel') {
			return undefined;
		} else if (key === 'batchNumberRotation') {
			return undefined;
		} else {
			return value;
		}
	}

	private getCurrentDate() {
		let currentDate = new Date();
		currentDate.setHours(0, 0, 0, 0);
		return currentDate;
	}

	// Talk to Mette about this
	private changeStartDate = async () => {
		// if (
		// 	this.props.matingBatchSetting &&
		// 	this.props.matingBatchSetting.id &&
		// 	!this.state.changesDateOnce &&
		// 	this.state.matingBatchSetting.startDate &&
		// 	moment()
		// 		.subtract(365, 'days')
		// 		.toDate() > this.state.matingBatchSetting.startDate
		// ) {
		// 	let state = {};
		// 	let confirmed =  await ShowConfirmAlert(localized('setStartDateToNextAvailableBatch'));
		// 	if (confirmed) {
		// 		let validDates = this.props.matingBatches
		// 			.filter(batch => batch.matingPeriodStart && batch.matingPeriodStart >= new Date().withoutTime())
		// 			.map(batch => batch.matingPeriodStart!);
		// 		if (validDates.length > 0) {
		// 			const weekday = this.getWeekDay(validDates[0].getDay());
		// 			this.setState(prevState => ({
		// 				matingBatchSetting: { ...prevState.matingBatchSetting, startDate: validDates[0], weekday },
		// 				changesDateOnce: true
		// 			}));
		// 		}
		// 	}else{
		// 		this.setState({changesDateOnce: true });
		// 	}
		// }
	};

	private handleExistingMatingBatchSetting = (matingBatchSetting: IMatingBatchSetting) => {
		let matingBatchSettingCopy = { ...matingBatchSetting };
		if (!matingBatchSettingCopy.batchNumberRotation && matingBatchSettingCopy.weekCycle) {
			matingBatchSettingCopy.batchNumberRotation = matingBatchSettingCopy.weekCycle;
		}
		return matingBatchSettingCopy;
	};

	private createSetting(isManuel: boolean) {
		let minimumDate = findMinimumDate(
			this.props.matingBatches.concat().sort((a: IMatingBatch, b: IMatingBatch) => {
				return a.matingPeriodStart!.getTime() - b.matingPeriodStart!.getTime();
			}),
		);
		if (!minimumDate) {
			minimumDate = new Date(new Date().setHours(0, 0, 0, 0));
		}
		if (isManuel) {
			return MatingBatchSetting.fromJS({
				startDate: minimumDate,
				batchNumberRotation: 21,
				matingPeriod: 7,
				weekCycle: 21,
				id: new ObjectID().toHexString(),
				pregnantDay:
					this.props.validationSetup !== null && this.props.validationSetup.validationIntervals
						? this.props.validationSetup.validationIntervals!.find(
								x => x.type === PregnancyValidationType.MatingToFarrowing,
						  )!.plan
						: DefaultValidationValues.DEFAULT_PREGNANT_PERIOD,
				nursingDays:
					this.props.validationSetup !== null && this.props.validationSetup.validationIntervals
						? this.props.validationSetup.validationIntervals!.find(
								x => x.type === PregnancyValidationType.FarrowingToAverted,
						  )!.plan
						: DefaultValidationValues.DEFAULT_NURSING_PERIOD,
				endDate: this.props.matingBatchSetting.endDate,
				weekday: this.getWeekDay(new Date().getDay()),
				siteId: this.props.siteId,
				isManuel: true,
			} as IMatingBatchSetting);
		}

		return MatingBatchSetting.fromJS({
			startDate: minimumDate,
			batchNumberRotation: 21,
			matingPeriod: 7,
			weekCycle: 21,
			id: new ObjectID().toHexString(),
			pregnantDay:
				this.props.validationSetup !== null && this.props.validationSetup.validationIntervals
					? this.props.validationSetup.validationIntervals!.find(
							x => x.type === PregnancyValidationType.MatingToFarrowing,
					  )!.plan
					: DefaultValidationValues.DEFAULT_PREGNANT_PERIOD,
			nursingDays:
				this.props.validationSetup !== null && this.props.validationSetup.validationIntervals
					? this.props.validationSetup.validationIntervals!.find(
							x => x.type === PregnancyValidationType.FarrowingToAverted,
					  )!.plan
					: DefaultValidationValues.DEFAULT_NURSING_PERIOD,
			endDate: this.props.matingBatchSetting.endDate,
			weekday: this.getWeekDay(new Date().getDay()),
			siteId: this.props.siteId,
			isManuel: false,
		} as IMatingBatchSetting);
	}

	// ManuelSetting Functions
	private repeatMatingBatches = (timesToRepeat?: number) => {
		if (this.manuelMatingSettingRef) {
			this.manuelMatingSettingRef.repeatMatingBatches(timesToRepeat);
		} else {
			//Only allow this is latest saved Mating batch setting was manuel setup
			if (timesToRepeat && this.props.matingBatchSetting.isManuel) {
				let sorted = this.state.matingBatches.concat().sort((a: IMatingBatch, b: IMatingBatch) => {
					return b.matingPeriodStart!.getTime() - a.matingPeriodStart!.getTime();
				});
				let lastBatch = sorted[0];
				let second = sorted.find(
					batch => batch.id !== lastBatch.id && batch.batchNumber === lastBatch.batchNumber,
				);
				let batchesToUse = sorted.filter(e => !second || e.matingPeriodStart! > second.matingPeriodEnd!);

				let repeatBatches = repeatManuel(
					timesToRepeat,
					this.props.matingBatchSetting.weekCycle,
					this.props.matingBatchSetting.matingPeriod,
					batchesToUse,
					this.props.siteId,
				);
				let newArray = this.state.matingBatches
					.concat(repeatBatches)
					.sort((a: IMatingBatch, b: IMatingBatch) => {
						return a.matingPeriodStart!.getTime() - b.matingPeriodStart!.getTime();
					});
				this.setState(prevState => ({
					matingBatchSetting: {
						...prevState.matingBatchSetting,
						endDate: newArray[newArray.length - 1].matingPeriodEnd,
					},
					matingBatches: newArray,
					newMatingBatches: this.state.newMatingBatches.concat(repeatBatches),
				}));
			}
		}
		this.closeModal();
	};

	private getOptionValue = (valueNumber: number | undefined) => {
		let valueString = valueNumber !== undefined ? valueNumber.toString() : '';
		return { label: valueString, value: valueString };
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(MatingBatchSettings);
