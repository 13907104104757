import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import * as actions from './actions';
import { saveValidationSetup } from './actions';
import { ValidationSetupsState } from './types';
import { siteChange } from '../profile/actions';
import { SharedAppState } from 'shared/state/store.shared';
import { SyncableSingleInitialState } from 'shared/state/models/syncable';
import { IValidationSetup, ValidationSetup } from 'shared/api/api';
import { getSyncModelData } from '../sync/actions';

export const initialState: ValidationSetupsState = {
	...SyncableSingleInitialState,
};

const validationSetupReducer = (state: ValidationSetupsState = initialState, action: Action): ValidationSetupsState => {
	if (isType(action, actions.getSyncData.started)) {
		state = { ...state, syncInProgress: true };
	}

	if (isType(action, actions.getSyncData.done)) {
		if (action.payload.result && action.payload.params.siteId === action.payload.params.activeSiteId) {
			if (action.payload.result.data) {
				state = {
					...state,
					entity: { ...state.entity, ...action.payload.result.data },
					lastSyncDate: action.payload.result.syncTime!,
				};
			}

			state = {
				...state,

				syncInProgress: false,
			};
		} else {
			state = {
				...state,
				syncInProgress: false,
			};
		}
	}

	if (isType(action, getSyncModelData.done)) {
		if (
			action.payload.result.validationSetup &&
			action.payload.params.siteId === action.payload.params.activeSiteId
		) {
			if (action.payload.result.validationSetup.data) {
				state = {
					...state,
					entity: { ...state.entity, ...action.payload.result.validationSetup.data },
					lastSyncDate: action.payload.result.validationSetup.syncTime!,
				};
			}

			state = {
				...state,

				syncInProgress: false,
			};
		} else {
			state = {
				...state,
				syncInProgress: false,
			};
		}
	}

	if (isType(action, actions.getSyncData.failed)) {
		state = { ...state, syncInProgress: false };
	}

	if (isType(action, saveValidationSetup.started)) {
		state = { ...state, update: action.payload };
		state = { ...state, entity: action.payload };
	}

	if (isType(action, saveValidationSetup.failed)) {
		if (action.payload.error.code === 500) {
			if (action.payload.error.prevEntity) {
				state = { ...state, entity: action.payload.error.prevEntity };
			} else {
				state = { ...state, entity: {} as IValidationSetup };
			}
			state = { ...state, update: {} as IValidationSetup };
		}

		return state;
	}

	if (isType(action, saveValidationSetup.done)) {
		if (!action.payload.params.isDeleted) {
			state = { ...state, entity: action.payload.params };
		}

		state = { ...state, update: {} as IValidationSetup };

		return state;
	}

	if (isType(action, siteChange.done)) {
		state = initialState;
	}

	//Ensure Date objects are in fact date and not strings - redux-persist serializes dates to string but doesn't deserialize to dates again
	if (action.type === 'persist/REHYDRATE') {
		let a = (action as any) as { payload: SharedAppState; key: string };
		if (a.key === 'root') {
			let entity = ValidationSetup.fromJS({});
			let update = ValidationSetup.fromJS({});
			let lastSyncDate = new Date(-8640000000000000);

			if (a.payload && a.payload.validationSetup && a.payload.validationSetup.entity) {
				let ndt = ValidationSetup.fromJS({});
				ndt.init(a.payload.validationSetup.entity);
				entity = ndt;
			}

			if (a.payload && a.payload.validationSetup && a.payload.validationSetup.update) {
				let ndt = ValidationSetup.fromJS({});
				ndt.init(a.payload.validationSetup.update);
				update = ndt;
			}

			if (a.payload && a.payload.validationSetup && a.payload.validationSetup.lastSyncDate) {
				lastSyncDate = new Date(a.payload.validationSetup.lastSyncDate);
			}

			state = { ...state, entity, update, lastSyncDate };
		}
	}

	return state;
};

export default validationSetupReducer;
