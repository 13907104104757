import { IStemAnimal, ILardScanningEvent, IPregnancyEvent } from 'shared/api/api';

import { SaveLardScanningEvent } from 'shared/state/ducks/lardScanningEvents/operations';

import { calculateLitter1 } from '../stemanimal-helper/stemanimal-helper';
import { SharedAppState } from 'shared/state/store.shared';
import { Dispatch } from 'redux';
import { memoizeAllLardEvents, memoizeAllPregnancyEvents } from '../memoize-getters/memoize-getters';

export interface SowCardLardWidthsPropsFromParent {
	sow: IStemAnimal | undefined;
	closeModal?: () => void;
}

export const sowCardLardWidthsMapStateToProps = (state: SharedAppState) => {
	return {
		siteId: state.profile.active!.siteId,
		lardScanningEvents: memoizeAllLardEvents(
			state.lardScanningEvents.entities,
			state.lardScanningEvents.departuredLardEvents,
		),
		pregnancyEvents: memoizeAllPregnancyEvents(
			state.pregnancyEvents.entities,
			state.pregnancyEvents.departuredPregnancies,
		),
	};
};

export const sowCardLardWidthsMapDispatchToProps = (dispatch: Dispatch) => {
	return {
		saveLardScanningEvent: (lard: ILardScanningEvent) => SaveLardScanningEvent(lard)(dispatch),
	};
};

export interface SowCardLardWidthsState {}

export class SowCardLardScanningEventListTableItem {
	public id: string | undefined;
	public date: Date | undefined;
	public LardWidth: string | undefined;
	public litter: number | undefined;
	public weight: number | undefined;
	public animalNumber: string | undefined;
}

export type SowCardLardWidthsProps = ReturnType<typeof sowCardLardWidthsMapStateToProps> &
	ReturnType<typeof sowCardLardWidthsMapDispatchToProps> &
	SowCardLardWidthsPropsFromParent;

export function GenerateSowCardLardWidthsData(
	sow: IStemAnimal | undefined,
	lardScanningEvents: ILardScanningEvent[],
	pregnancyEvents: { [key: string]: IPregnancyEvent[] },
) {
	let tableItems: SowCardLardScanningEventListTableItem[] = [];

	if (sow) {
		lardScanningEvents
			.filter(a => a.stemAnimalId === sow.id)
			.forEach(e => {
				tableItems.push({
					LardWidth: e.width ? e.width.toString() : ' ',
					id: e.id,
					litter: calculateLitter1(sow, e.date!, pregnancyEvents[e.stemAnimalId!]),
					date: e.date,
					animalNumber: sow.animalNumber,
					weight: e.weight,
				});
			});
	}
	return tableItems;
}
