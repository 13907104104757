import { default as ObjectID } from 'bson-objectid';
import { Dispatch } from 'redux';
import { IWorkListToTreatmentPlan, WorkListToTreatmentPlan } from 'shared/api/api';
import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import { StoreContainer } from 'shared/state/store-container';
import * as Action from './actions';

export function GetSyncData() {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;
	const lastSyncDate = state.workListToTreatmentPlans.lastSyncDate;

	if (state.workListToTreatmentPlans.syncInProgress) {
		return (dispatch: Dispatch<any>) => {
			return Promise.resolve();
		};
	}

	return AsyncOperationBuilder2(
		Action.getSyncData,
		client => client.workListToTreatmentPlan_Sync(siteId, lastSyncDate),
		{
			siteId,
			lastSyncDate,
		},
	);
}

export function SaveWorkListToTreatmentPlan(worklistToTreatmentPlan: IWorkListToTreatmentPlan) {
	if (!worklistToTreatmentPlan.id) {
		worklistToTreatmentPlan.id = new ObjectID().toHexString();
	}
	return AsyncOperationBuilder2(
		Action.saveWorkListToTreatmenPlan,
		client => client.workListToTreatmentPlan_Upsert(WorkListToTreatmentPlan.fromJS(worklistToTreatmentPlan)),
		worklistToTreatmentPlan,
	);
}

export function SaveSyncData() {
	const state = StoreContainer.getState();
	const updates = state.workListToTreatmentPlans.updates;
	let promises = new Array<Promise<void>>();

	return async (dispatch: Dispatch<any>) => {
		if (state.workListToTreatmentPlans.saveSyncInProgress) {
			return Promise.resolve();
		}
		updates.forEach(update => {
			promises.push(SaveWorkListToTreatmentPlan(update)(dispatch));
		});

		return await Promise.all(promises);
	};
}
