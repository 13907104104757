import React from 'react';
import { ViewWeb } from 'web/view/components/utils/web-view';
import './skiold-input.scss';
import { SkioldInput } from './skiold-input';

export interface TextPropsFromParent {
	onChangeText?: (newText: string) => void;
	text?: string;
	style?: any;
	editable?: boolean;
	multiLine?: boolean;
	numOfLines?: number;
	onBlur?: () => void;
	selectTextOnFocus?: boolean;
	containerStyle?: any;
	autoFocus?: boolean;
	containerClassName?: string;
	className?: string;
}

interface State {}

// Component representing a Skiold Digital textfield throughout the app/website
export class SkioldUnderlineInput extends React.PureComponent<TextPropsFromParent, State> {
	public static defaultProps: Partial<TextPropsFromParent> = {
		selectTextOnFocus: false,
		autoFocus: false,
		containerClassName: '',
		className: '',
		editable: true
	};
	public input: any; //Any because of missing .focus on TextField typings

	constructor(props: TextPropsFromParent) {
		super(props);

		this.state = {};
	}

	public textChanged = (newText: string) => {
		if (this.props.onChangeText) {
			this.props.onChangeText(newText);
		}
	}

	public onBlur() {
		if (this.props.onBlur) {
			this.props.onBlur();
		}
	}

	public focus() {
		if (this.input) {
			this.input.focus();
		}
	}

	public render() {
		const isEditableClassName =
			this.props.editable === undefined || this.props.editable === true ? '' : 'is-editable';
		return (
			<ViewWeb className={`skiold-underline-input ${this.props.containerClassName} ${isEditableClassName}`}>
				<SkioldInput
				onChangeText={this.textChanged}
				text={this.props.text || ''}
				className={this.props.className}
				editable={this.props.editable}
				// ref={ref => (this.input = ref)}
				onBlur={() => this.onBlur()}
				selectTextOnFocus={this.props.selectTextOnFocus}
				autoFocus={this.props.autoFocus}
				/>
			</ViewWeb>
		);
	}
}
