
import { IProcessEquipmentData, SyncProcessEquipmentData } from 'shared/api/api';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import actionCreatorFactory from 'typescript-fsa';
import * as types from './types';

const actionCreator = actionCreatorFactory(GenActionFactoryName('processEquipmentData'));
export const getProcessEquipmentData = actionCreator.async<any, IProcessEquipmentData[]>(types.GET_ProcessEquipmentData);
export const saveData = actionCreator.async<any, string>(types.SAVE_ProcessEquipmentData);

export const saveDataFromUnitToPen = actionCreator.async<IProcessEquipmentData, string>( types.SAVE_ProcessEquipmentDataFromUnitToPen)

export const getSyncData = actionCreator.async<{ siteId: string; lastSyncDate: Date; activeSiteId?: string  }, SyncProcessEquipmentData>(
    types.SYNC_DATA_PROCESS_EQUIPMENT_DATA
);