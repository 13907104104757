import React from 'react';
import { Option } from 'react-dropdown';
import isEqual from 'react-fast-compare';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { DashboardSettingItem, DashboardSettings, IDashboardSettings, IMatingBatch, MatingBatch } from 'shared/api/api';
import { getDateString } from 'shared/helpers/date-helpers';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { GetSyncData, SaveDashboardSettings } from 'shared/state/ducks/dashboard-settings/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { showAlert } from 'web/view/components/skiold-alert/skiold-alert';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldCheckbox } from 'web/view/components/skiold-components/skiold-checkbox/skiold-checkbox';
import { SkioldFormDecimalInput } from 'web/view/components/skiold-components/skiold-decimal-input/skiold-form-decimal-input';
import { SkioldDropdown } from 'web/view/components/skiold-components/skiold-dropdown/skiold-dropdown';
import { SkioldFormDropdown } from 'web/view/components/skiold-components/skiold-dropdown/skiold-form-dropdown';
import { SkioldFormIntegerInput } from 'web/view/components/skiold-components/skiold-integer-input/skiold-form-integer-input';
import SkioldTableGrid, {
	SkioldTableGridFooterRow,
} from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { GroupedHeader } from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid-grouped-header';
import { SowListConstants } from 'web/view/components/stem-animal/animal-lists/table-constants';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import './dashboard-settings.scss';

const mapStateToProps = (state: WebAppState) => {
	return {
		siteId: state.profile.active!.siteId,
		settings: state.dashboardSettings.entity,
		matingBatches: state.matingBatches.entities.sort((a: IMatingBatch, b: IMatingBatch) => {
			return b.matingPeriodStart!.getTime() - a.matingPeriodStart!.getTime();
		}),
		matingBatchSetting: state.matingBatchSetting.currentMatingBatchSetting,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	SaveDashboardSettings: (settings: IDashboardSettings) => SaveDashboardSettings(settings)(dispatch),
	syncDashboardSettings: () => GetSyncData()(dispatch),
});

export interface State {
	settings: IDashboardSettings;
	matingBatchOptionsEnd: Option[];
	matingBatchOptionsStart: Option[];
	selectedStartMatingBatchOption?: Option;
	selectedEndMatingBatchOption?: Option;
}

interface DashboardSettingTableItem {
	key: string;
	name: string;
	data: DashboardSettingItem;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const keysUseDecimal = [
	'averageWasteDaysPerMatingBatch',
	'aliveBornPerFarrowingFirstLegPcs',
	'aliveBornPerFarrowingPcs',
	'deadBornPerFarrowingPcs',
	'weightPerWeanedPig',
	'weanedPerFarrowingPcs',
];

const stillbornToAliveRateSettingKey = 'stillbornToAliveRate';

export class DashboardSettingsPage extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		const today = new Date();
		let { setting, matingBatchOptionsEnd, selectedEndMatingBatchOption, selectedStartMatingBatchOption } =
			this.SetInitialData(props);
		if (props.settings && props.settings.startMatingBatchId && props.settings.endMatingBatchId) {
			const selectedData = DashboardSettingsPage.GetSelectedMatingBatchesFromProps(props);
			if (selectedData !== undefined) {
				setting = selectedData.settings;
				matingBatchOptionsEnd = selectedData.matingBatchOptionsEnd;
				selectedStartMatingBatchOption = selectedData.selectedStartMatingBatchOption;
				selectedEndMatingBatchOption = selectedData.selectedEndMatingBatchOption;
			}
		}
		this.state = {
			settings: setting,
			matingBatchOptionsEnd,
			matingBatchOptionsStart: props.matingBatches
				.filter(a => a.matingPeriodStart && a.matingPeriodStart < today)
				.map(mb => DashboardSettingsPage.SetMatingBatchOption(mb)),

			selectedStartMatingBatchOption: selectedStartMatingBatchOption,
			selectedEndMatingBatchOption: selectedEndMatingBatchOption,
		};
		this.props.syncDashboardSettings();
	}

	private groupedColumns: GroupedHeader[] = [
		{
			title: localized('deviationPercent'),
			children: [{ columnName: 'postiveDeviation' }, { columnName: 'negativeDeviation' }],
		},
	];

	private SetInitialData(props: Props) {
		const dateToUse = new Date();
		const endDateToUse = new Date();
		endDateToUse.setDate(dateToUse.getDate() + 365);
		const startMatingBatch = this.getSelectedMatingBatchOptionForDate(dateToUse);
		const endMatingBatch = this.getSelectedMatingBatchOptionForDate(endDateToUse);
		const matingBatchOptionsEnd = this.GetMatingBatchOptions(
			endMatingBatch,
			props.matingBatches
				.filter(a => a.matingPeriodStart && a.matingPeriodStart < endDateToUse)
				.map(mb => DashboardSettingsPage.SetMatingBatchOption(mb)),
		);
		const selectedStartMatingBatchOption = startMatingBatch
			? DashboardSettingsPage.SetMatingBatchOption(startMatingBatch)
			: undefined;
		const selectedEndMatingBatchOption = endMatingBatch
			? DashboardSettingsPage.SetMatingBatchOption(endMatingBatch)
			: undefined;
		const setting =
			this.props.settings && this.props.settings.siteId
				? this.props.settings
				: this.init(startMatingBatch as MatingBatch, endMatingBatch as MatingBatch);
		return { setting, matingBatchOptionsEnd, selectedStartMatingBatchOption, selectedEndMatingBatchOption };
	}

	public static getDerivedStateFromProps(nextProps: Props, prevState: State) {
		if (!isEqual(prevState.settings.modifiedOn, nextProps.settings.modifiedOn)) {
			if (nextProps.settings.startMatingBatchId && nextProps.settings.endMatingBatchId) {
				const selectedData = DashboardSettingsPage.GetSelectedMatingBatchesFromProps(nextProps);
				if (selectedData) {
					return selectedData;
				}
			}
			return {
				settings: nextProps.settings,
			};
		}
		return {};
	}

	private static GetSelectedMatingBatchesFromProps(props) {
		const startMatingBatch = props.matingBatches.find(mb => mb.id === props.settings.startMatingBatchId);
		const endMatingBatchIndex = props.matingBatches.findIndex(mb => mb.id === props.settings.endMatingBatchId);
		let matingBatchOptionsEnd = props.matingBatchOptionsEnd;
		const endMatingBatch = props.matingBatches[endMatingBatchIndex];
		const endMatingBatchUpperLimitIndex =
			props.matingBatches.length - 1 >= endMatingBatchIndex + 4
				? endMatingBatchIndex + 4
				: props.matingBatches.length - 1;
		const endMatingBatchLowerLimitIndex = endMatingBatchIndex - 4;
		const endMatingBatchUpperLimit = props.matingBatches[endMatingBatchUpperLimitIndex];
		const endMatingBatchLowerLimit = props.matingBatches[endMatingBatchLowerLimitIndex];
		if (endMatingBatchUpperLimit && endMatingBatchLowerLimit) {
			matingBatchOptionsEnd = props.matingBatches
				.filter(
					a =>
						a.matingPeriodStart &&
						endMatingBatchLowerLimit.matingPeriodStart &&
						endMatingBatchUpperLimit.matingPeriodStart &&
						a.matingPeriodStart >= endMatingBatchUpperLimit.matingPeriodStart &&
						a.matingPeriodStart <= endMatingBatchLowerLimit.matingPeriodStart,
				)
				.map(mb => DashboardSettingsPage.SetMatingBatchOption(mb));
		}

		if (startMatingBatch && endMatingBatch) {
			return {
				settings: props.settings,
				selectedStartMatingBatchOption: DashboardSettingsPage.SetMatingBatchOption(startMatingBatch),
				selectedEndMatingBatchOption: DashboardSettingsPage.SetMatingBatchOption(endMatingBatch),
				matingBatchOptionsEnd,
			};
		}
	}

	private static SetMatingBatchOption = (matingBatch: IMatingBatch) => {
		return {
			value: matingBatch.id,
			label:
				localized('Batch') +
				' ' +
				matingBatch.batchNumber +
				': ' +
				localized('from') +
				' ' +
				getDateString(matingBatch.matingPeriodStart) +
				' til ' +
				getDateString(matingBatch.matingPeriodEnd),
		} as Option;
	};

	private getSelectedMatingBatchOptionForDate = (dateToUse: Date) => {
		return this.props.matingBatches.find(
			a =>
				a.matingPeriodStart &&
				a.matingPeriodStart <= dateToUse &&
				a.matingPeriodEnd &&
				a.matingPeriodEnd >= dateToUse,
		);
	};

	public render() {
		const formRows = this.getFormRows();
		return (
			<ViewWeb className="plan-and-goal-settings">
				<ViewWeb className="view-container">
					<SkioldTableGrid
						className={'fit-content'}
						data={this.generateData(this.state.settings)}
						columns={this.generateColumns}
						footerRows={formRows}
						groupedColumns={this.groupedColumns}
						ColumnExtensions={this.getColumnExtenstions}
						tableKey={'dashboardSettingsTable'}
						filterable={false}
						sortable={false}
					/>
					{this.renderButtons()}
				</ViewWeb>
			</ViewWeb>
		);
	}

	//Only used to this segment
	private dashboardSettingKeys = [
		'matingsPcs',
		'percentageYoungAnimalMatings',
		'percentageReMatings',
		'daysFromWeaningToFirstMating',
		'averageWasteDaysPerMatingBatch',
		'farrowingsPcs',
		'aliveBornPerFarrowingPcs',
		'aliveBornPerFarrowingFirstLegPcs',
		'deadBornPerFarrowingPcs',
		'weanedPerFarrowingPcs',
		'weightPerWeanedPig',
		'stillbornToAliveRate',
	];

	private generateData(dashboardSetting: IDashboardSettings) {
		const data: DashboardSettingTableItem[] = [];
		this.dashboardSettingKeys.forEach(keyName => {
			if (dashboardSetting[keyName]) {
				data.push({
					key: keyName,
					name: localized(keyName),
					data: dashboardSetting[keyName] as DashboardSettingItem,
				});
			}
		});
		return data;
	}

	private onPerYearChanged = (num, item: DashboardSettingTableItem) => {
		const copyOfData = { ...item.data };
		copyOfData.pcs = num;
		this.valueChanged(copyOfData, item.key);
	};

	private onPostiveDeviationChanged = (num, item: DashboardSettingTableItem) => {
		const copyOfData = { ...item.data };
		copyOfData.plusDeviation = num;
		this.valueChanged(copyOfData, item.key);
	};

	private onNegativeDeviationChanged = (num, item: DashboardSettingTableItem) => {
		const copyOfData = { ...item.data };
		copyOfData.negativeDeviation = num;
		this.valueChanged(copyOfData, item.key);
	};

	private getPerYearCell = (item: DashboardSettingTableItem) => {
		if (keysUseDecimal.includes(item.key)) {
			return (
				<SkioldFormDecimalInput
					text={item.data.pcs}
					itemFromParent={item}
					onChangeNumber={this.onPerYearChanged}
				/>
			);
		}
		return (
			<SkioldFormIntegerInput
				editable={item.key !== stillbornToAliveRateSettingKey}
				text={item.data.pcs}
				itemFromParent={item}
				onChangeNumber={this.onPerYearChanged}
			/>
		);
	};

	private getPostiveDeviationCell = (item: DashboardSettingTableItem) => (
		<SkioldFormIntegerInput
			text={item.data.plusDeviation}
			itemFromParent={item}
			onChangeNumber={this.onPostiveDeviationChanged}
		/>
	);

	private getNegativeDeviationCell = (item: DashboardSettingTableItem) => (
		<SkioldFormIntegerInput
			text={item.data.negativeDeviation}
			itemFromParent={item}
			onChangeNumber={this.onNegativeDeviationChanged}
		/>
	);

	private generateColumns = [
		{
			name: 'name',
			title: localized('PlanAndGoal'),
			getCellValue: (item: DashboardSettingTableItem) => (
				<ViewWeb className="alignContentBaseline bold">{item.name}</ViewWeb>
			),
		},
		{
			name: 'perYear',
			span: 3,
			title: localized('PerYear'),
			getCellValue: this.getPerYearCell,
		},
		{
			name: 'postiveDeviation',
			title: <div className="bold font-size-twenty">+</div>,
			getCellValue: this.getPostiveDeviationCell,
			className: 'bold',
		},
		{
			name: 'negativeDeviation',
			title: <div className="bold font-size-twenty">-</div>,
			getCellValue: this.getNegativeDeviationCell,
		},
	];

	private getColumnExtenstions = [
		{
			columnName: 'name',
			filteringEnabled: false,
			width: SowListConstants.dashboardSettingNameWidth,
		},
		{
			columnName: 'perYear',
			width: SowListConstants.iDNumberWidth,
		},
		{
			columnName: 'postiveDeviation',
			width: SowListConstants.iDNumberWidth,
		},
		{
			columnName: 'negativeDeviation',
			width: SowListConstants.iDNumberWidth,
		},
	];

	private init(startMatingBatch: MatingBatch, endMatingBatch: MatingBatch) {
		return DashboardSettings.fromJS({
			siteId: this.props.siteId,
			startMatingBatchId: startMatingBatch && startMatingBatch.id,
			endMatingBatchId: endMatingBatch && endMatingBatch.id,
			matingsPcs: new DashboardSettingItem(),
			percentageYoungAnimalMatings: new DashboardSettingItem(),
			percentageReMatings: new DashboardSettingItem(),
			daysFromWeaningToFirstMating: new DashboardSettingItem(),
			averageWasteDaysPerMatingBatch: new DashboardSettingItem(),
			farrowingsPcs: new DashboardSettingItem(),
			aliveBornPerFarrowingPcs: new DashboardSettingItem(),
			aliveBornPerFarrowingFirstLegPcs: new DashboardSettingItem(),
			deadBornPerFarrowingPcs: new DashboardSettingItem(),
			weanedPerFarrowingPcs: new DashboardSettingItem(),
			weightPerWeanedPig: new DashboardSettingItem(),
			stillbornToAliveRate: new DashboardSettingItem(),
		});
	}

	private save = async () => {
		let settingsCopy = { ...this.state.settings };
		settingsCopy.siteId = settingsCopy.siteId ? settingsCopy.siteId : this.props.siteId;
		if (!this.validate(settingsCopy)) {
			return;
		}
		this.props.SaveDashboardSettings(settingsCopy);
	};

	private getFormRows() {
		let formRows = new Array<any>();
		formRows.push(this.getDaysInMatingBatch());
		formRows.push(this.getRollingYearRow());
		return formRows;
	}

	private getDaysInMatingBatch = () => {
		let footerRow = new SkioldTableGridFooterRow();
		footerRow.items = [
			<TextWeb className={'bold'} key={'daysPerMatingBatch'}>
				{localized('daysPerMatingBatch')}
			</TextWeb>,
			<TextWeb key={'matingPeriod'} className="skiold-table-input width100">
				{this.props.matingBatchSetting.matingPeriod}{' '}
			</TextWeb>,
		];

		return footerRow;
	};

	private getRollingYearRow = () => {
		let footerRow = new SkioldTableGridFooterRow();
		footerRow.items = [
			<ViewWeb className={'bold'} key={'rollingYearFirstColumn'}>
				<TextWeb className={'bold description-line flex-center'}>{localized('dashboardDefinition')}</TextWeb>
				{!this.state.settings.rollingYear && (
					<TextWeb className={'bold start-batch-line flex-center'}>{localized('startAtMatingBatch')}</TextWeb>
				)}
				{!this.state.settings.rollingYear && (
					<TextWeb className={'bold end-batch-line flex-center'}>{localized('endAtMatingBatch')}</TextWeb>
				)}
				<TextWeb className={'bold rolling-year-line flex-center'}>{localized('rollingYear')}</TextWeb>
			</ViewWeb>,
			<ViewWeb key={'rollingYearSecondColumn'}>
				<TextWeb className={' flex-center description-line'}> </TextWeb>
				{!this.state.settings.rollingYear && (
					<SkioldDropdown
						items={this.state.matingBatchOptionsStart}
						usedInTable={true}
						theme="dark"
						containerClassName="start-batch-line flex-center justify-content-end "
						className="flex-center"
						selectedValue={this.state.selectedStartMatingBatchOption}
						onValueChanged={this.onStartMatingBatchChanged}
					/>
				)}
				{!this.state.settings.rollingYear && (
					<SkioldFormDropdown
						theme="dark"
						usedInTable={true}
						containerClassName="end-batch-line flex-center justify-content-end"
						className="flex-center"
						items={this.state.matingBatchOptionsEnd}
						selectedValue={this.state.selectedEndMatingBatchOption}
						onValueChanged={this.onEndMatingBatchChanged}
					/>
				)}

				<SkioldCheckbox
					key={'rollingYear'}
					onClick={this.toggleRollingYear}
					containerClassName={'checkBoxinTable rolling-year-line flex-center'}
					isChecked={this.state.settings.rollingYear}
				/>
			</ViewWeb>,
		];
		return footerRow;
	};

	private toggleRollingYear = () => {
		this.setState(prevState => ({
			settings: {
				...prevState.settings,
				rollingYear: !prevState.settings.rollingYear,
			},
		}));
	};

	private onEndMatingBatchChanged = (matingBatch: Option) => {
		const selectedMatingBatchIndex = this.props.matingBatches.findIndex(mb => mb.id === matingBatch.value);
		const selectedMatingBatch = this.props.matingBatches[selectedMatingBatchIndex];
		if (selectedMatingBatch && selectedMatingBatch.matingPeriodStart) {
			this.setState(prevState => ({
				settings: {
					...prevState.settings,
					endMatingBatchId: (selectedMatingBatch as MatingBatch).id,
				},
				selectedEndMatingBatchOption: matingBatch,
			}));
		} else if (selectedMatingBatch) {
			this.setState(prevState => ({
				settings: {
					...prevState.settings,
					endMatingBatchId: (selectedMatingBatch as MatingBatch).id,
				},
				selectedEndMatingBatchOption: matingBatch,
			}));
		}
	};

	private onStartMatingBatchChanged = (matingBatch: Option) => {
		const selectedMatingBatch = this.props.matingBatches.find(mb => mb.id === matingBatch.value);
		const selectedMatingBatchIndex = this.props.matingBatches.findIndex(mb => mb.id === matingBatch.value);
		const previouslySelectedMatingBatchIndex = this.getPreviouslySelectedIndexForMatingBatchStartDate();
		if (
			selectedMatingBatch &&
			selectedMatingBatch.matingPeriodStart &&
			!(
				previouslySelectedMatingBatchIndex > selectedMatingBatchIndex - 4 &&
				previouslySelectedMatingBatchIndex < selectedMatingBatchIndex + 4
			)
		) {
			const dateToUse = new Date(
				selectedMatingBatch.matingPeriodStart.getFullYear(),
				selectedMatingBatch.matingPeriodStart.getMonth(),
				selectedMatingBatch.matingPeriodStart.getDate() + 365,
			);
			const EndMatingBatchSelected = this.getSelectedMatingBatchOptionForDate(dateToUse);
			let matingBatchOptionsEnd = this.state.matingBatchOptionsEnd;
			matingBatchOptionsEnd = this.GetMatingBatchOptions(EndMatingBatchSelected, matingBatchOptionsEnd);

			this.setState(prevState => ({
				settings: {
					...prevState.settings,
					startMatingBatchId: (selectedMatingBatch as MatingBatch).id,
					endMatingBatchId: (EndMatingBatchSelected as MatingBatch).id,
				},
				matingBatchOptionsEnd,
				selectedStartMatingBatchOption: matingBatch,
				selectedEndMatingBatchOption: EndMatingBatchSelected
					? matingBatchOptionsEnd.find(mbo => mbo.value === EndMatingBatchSelected.id)
					: matingBatch,
			}));
		} else if (selectedMatingBatch) {
			this.setState(prevState => ({
				settings: {
					...prevState.settings,
					startMatingBatchId: (selectedMatingBatch as MatingBatch).id,
				},
				selectedStartMatingBatchOption: matingBatch,
			}));
		}
	};

	private GetMatingBatchOptions(EndMatingBatchSelected: IMatingBatch | undefined, matingBatchOptionsEnd: Option[]) {
		if (EndMatingBatchSelected) {
			const endMatingBatchIndex = this.props.matingBatches.findIndex(mb => mb.id === EndMatingBatchSelected.id);
			const endMatingBatchUpperLimitIndex =
				this.props.matingBatches.length - 1 >= endMatingBatchIndex + 4
					? endMatingBatchIndex + 4
					: this.props.matingBatches.length - 1;
			const endMatingBatchLowerLimitIndex = endMatingBatchIndex - 4;
			const endMatingBatchUpperLimit = this.props.matingBatches[endMatingBatchUpperLimitIndex];
			const endMatingBatchLowerLimit = this.props.matingBatches[endMatingBatchLowerLimitIndex];
			if (endMatingBatchUpperLimit && endMatingBatchLowerLimit) {
				matingBatchOptionsEnd = this.props.matingBatches
					.filter(
						a =>
							a.matingPeriodStart &&
							endMatingBatchLowerLimit.matingPeriodStart &&
							endMatingBatchUpperLimit.matingPeriodStart &&
							a.matingPeriodStart >= endMatingBatchUpperLimit.matingPeriodStart &&
							a.matingPeriodStart <= endMatingBatchLowerLimit.matingPeriodStart,
					)
					.map(mb => DashboardSettingsPage.SetMatingBatchOption(mb));
			}
		}
		return matingBatchOptionsEnd;
	}

	private getPreviouslySelectedIndexForMatingBatchStartDate() {
		let previouslySelectedMatingBatchIndex = 0;
		const previouslySelectedEndMatingBatch = this.props.matingBatches.find(
			mb => this.state.selectedEndMatingBatchOption && mb.id === this.state.selectedEndMatingBatchOption.value,
		);
		if (previouslySelectedEndMatingBatch && previouslySelectedEndMatingBatch.matingPeriodStart) {
			const dateToUse = new Date(
				previouslySelectedEndMatingBatch.matingPeriodStart.getFullYear() - 1,
				previouslySelectedEndMatingBatch.matingPeriodStart.getMonth(),
				previouslySelectedEndMatingBatch.matingPeriodStart.getDate(),
			);
			previouslySelectedMatingBatchIndex = this.props.matingBatches.findIndex(
				mb => mb.matingPeriodEnd && mb.matingPeriodEnd <= dateToUse,
			);
		}
		return previouslySelectedMatingBatchIndex;
	}

	private valueChanged(value: any, key: string) {
		//Calcuate the value for the stillbornToAliveRate
		if (key === 'aliveBornPerFarrowingPcs' || key === 'deadBornPerFarrowingPcs') {
			const aliveBornPerFarrowingPcs =
				key === 'aliveBornPerFarrowingPcs' ? value : this.state.settings.aliveBornPerFarrowingPcs;
			const deadBornPerFarrowingPcs =
				key === 'deadBornPerFarrowingPcs' ? value : this.state.settings.deadBornPerFarrowingPcs;

			if (!isNaN(aliveBornPerFarrowingPcs.pcs) &&aliveBornPerFarrowingPcs.pcs !== 0 && !isNaN(deadBornPerFarrowingPcs.pcs) &&deadBornPerFarrowingPcs.pcs !== 0) {
				const stillbornToAliveRateValue =
					Math.round(((deadBornPerFarrowingPcs.pcs / aliveBornPerFarrowingPcs.pcs)* 100)*10)/10 ;
				let stillbornToAliveRate = { ...this.state.settings.stillbornToAliveRate } as DashboardSettingItem;
				stillbornToAliveRate.pcs = stillbornToAliveRateValue;
				this.setState(prevState => ({
					settings: {
						...prevState.settings,
						[key]: value,
						[stillbornToAliveRateSettingKey]: stillbornToAliveRate,
					},
				}));
				return;
			}
		}

		this.setState(prevState => ({
			settings: {
				...prevState.settings,
				[key]: value,
			},
		}));
	}

	private validate(listSetting: IDashboardSettings) {
		const dateNow = new Date();

		if (!listSetting.rollingYear) {
			const endMatingBatch = this.props.matingBatches.find(mb => mb.id === listSetting.endMatingBatchId);
			if (
				listSetting.startMatingBatchId &&
				endMatingBatch &&
				endMatingBatch.matingPeriodStart &&
				endMatingBatch.matingPeriodStart < dateNow
			) {
				showAlert(localized(ExceptionMessage.VALIDATION_ERROR_DASHBOARD_ENDMATINGBATCH_NEED_TO_BE_LATER));
				return false;
			}
		}
		return true;
	}

	private renderButtons() {
		return (
			<ViewWeb className="view-button-container">
				<SkioldButton title={localized('Save')} onPress={this.save} />
			</ViewWeb>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardSettingsPage);
