import React from 'react';
import { Option } from 'react-dropdown';
import { connect } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { Dispatch } from 'redux';
import { Equipment, IBuilding, IProcessEquipment, ISection } from 'shared/api/api';
import AutoPenNumber from 'shared/assets/src-assets/png/PenNumber_Grey_06.png';
import { FetchLocations, GetProcessEquipmentData } from 'shared/state/ducks/process-equipment-data/operations';
import { GetUnits } from 'shared/state/ducks/process-units/operations';
import * as UnitsToPenOperations from 'shared/state/ducks/unit-to-pen/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import PageContainer from '../page-container/page-container';
import { showAlert } from '../skiold-alert/skiold-alert';
import { SkioldButton } from '../skiold-components/skiold-button/skiold-button';
import { SkioldFormDropdown } from '../skiold-components/skiold-dropdown/skiold-form-dropdown';
import { SkioldTouchableOpacity } from '../skiold-components/skiold-touchable-opacity';
import { WhiteText } from '../Text/white-text';
import { Heading } from '../utils/heading';
import { SkioldFetchIcon } from '../utils/skiold-fetch-icon';
import { SkioldImage } from '../utils/svg/skiold-image';
import { ViewWeb } from '../utils/web-view';
import './create-location-relations.scss';
import './create-process-equipment.scss';
import { GetUnitOption, GetUnitTypes } from './LocationRelation-helper';
import UnitsOnProcessEquipmentTable, { SaveCloseDontClose } from './units-on-process-equipment-table';
import { ExtractLocationCode } from 'shared/helpers/process-equipment-helper/process-equipment-helper';

const mapStateToProps = (state: WebAppState) => {
	return {
		siteId: state.profile.active ? state.profile.active.siteId : undefined,
		processEquipments: state.processEquipments.entities,
		buildings: state.locations.buildings,
		sections: state.locations.sections,
		processEquipmentData: state.processEquipmentData.entities,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	getSyncData: async () => (await GetProcessEquipmentData())(dispatch),
	getunitsData: () => UnitsToPenOperations.GetSyncData()(dispatch),
	fetchLocations: async (equipment: Equipment, code: string) => await FetchLocations(equipment, code),
	getunits: () => GetUnits()(dispatch),
});

interface PropsFromParent {
	Building?: IBuilding;
	Section?: ISection;
	closeModal?: () => void;
}

type UnitsOnProcessEquipmentProps = ReturnType<typeof mapStateToProps> &
	ReturnType<typeof mapDispatchToProps> &
	PropsFromParent;

interface UnitsOnProcessEquipmenttState {
	processEquipmentOptions: Option[];
	equipmentOptions: Option[];
	selectedEquipmentOption: Option;
	selectedOption: Option;
	selectedProcessEquipment: IProcessEquipment | undefined;
	loading: boolean;
	automaticSetPens: boolean;
	showSpinner: boolean;
}

export class UnitsOnProcessEquipment extends React.PureComponent<
	UnitsOnProcessEquipmentProps,
	UnitsOnProcessEquipmenttState
> {
	constructor(props: UnitsOnProcessEquipmentProps) {
		super(props);

		let processEquipmentOptions = this.props.processEquipments
			.filter(
				pe =>
					props.processEquipmentData.find(
						peq =>
							(ExtractLocationCode(peq.code) === pe.code &&
								peq.equipmentType === pe.equipment &&
								props.Building &&
								peq.buildingId === props.Building.id) ||
							(ExtractLocationCode(peq.code) === pe.code &&
								peq.equipmentType === pe.equipment &&
								props.Section &&
								peq.sectionId === props.Section.id),
					) !== undefined,
			)
			.map(processEquipment => ({
				value: processEquipment.id!,
				label: processEquipment.name
					? processEquipment.name + ''
					: processEquipment.equipment
					? processEquipment.equipment!.toString()
					: '',
			}));

		processEquipmentOptions.unshift({ value: '', label: ' ' });

		this.state = {
			processEquipmentOptions,
			selectedEquipmentOption: { value: '', label: ' ' },
			equipmentOptions: GetUnitTypes(undefined, true),
			selectedOption: { value: '', label: ' ' },
			selectedProcessEquipment: undefined,
			loading: false,
			showSpinner: false,
			automaticSetPens: false,
		};
	}

	public render() {
		return (
			<PageContainer>
				<Heading allLettersCapitalized={false} text={localized('bindProcessEquipment')} />
				<ViewWeb className="create-location-relations">
					<ViewWeb className="uppper-container">
						<WhiteText>{localized('chooseProcessEquipment')}</WhiteText>
						<SkioldFormDropdown
							containerClassName={'min-width-120'}
							items={this.state.processEquipmentOptions}
							selectedValue={this.state.selectedOption}
							onValueChanged={this.equipmentChanged}
							theme="light"
						/>
						<WhiteText className={'margin-left'}>{localized('chooseUnits')}</WhiteText>
						<SkioldFormDropdown
							containerClassName={'min-width-120'}
							items={this.state.equipmentOptions}
							selectedValue={this.state.selectedEquipmentOption}
							onValueChanged={this.unitChanged}
							theme="light"
						/>
						{this.state.selectedOption.value !== '' && this.state.selectedEquipmentOption.value !== '' && (
							<SkioldFetchIcon
								className={'margin-left'}
								showSpinner={this.state.loading}
								onPress={this.showData}
							/>
						)}

						{this.state.selectedProcessEquipment !== undefined && this.state.loading !== true && (
							<ViewWeb className={'flex-one'}>
								<SkioldTouchableOpacity
									className={'fit-content-flex-end'}
									onPress={this.setAutomaticPens}
								>
									<SkioldImage
										className={'align-center'}
										width="50"
										height="50"
										imageData={AutoPenNumber}
									/>
									<TextWeb className="text-style">
										{this.state.selectedProcessEquipment.equipment === Equipment.Distriwin
											? localized('setPensAutomaticValves')
											: localized('setPensAutomaticStations')}
									</TextWeb>
								</SkioldTouchableOpacity>
							</ViewWeb>
						)}
					</ViewWeb>

					<UnitsOnProcessEquipmentTable
						automaticSetPens={this.state.automaticSetPens}
						setAutomaticPensDone={this.setAutomaticPensDone}
						building={this.props.Building}
						section={this.props.Section}
						loading={this.state.loading}
						save={this.save}
						processEquipmentData={this.filterProcessEquipment()}
						processEquipment={this.state.selectedProcessEquipment}
						checkForUnsavedChanges={this.mapCheckForChangesFunction}
					/>
					{this.renderButtons()}
				</ViewWeb>
			</PageContainer>
		);
	}

	private filterProcessEquipment = () => {
		return this.props.processEquipmentData.filter(
			peq =>
				(this.state.selectedProcessEquipment &&
					this.state.selectedProcessEquipment.code === ExtractLocationCode(peq.code) &&
					this.props.Building &&
					peq.buildingId === this.props.Building.id) ||
				(this.state.selectedProcessEquipment &&
					this.state.selectedProcessEquipment.code === ExtractLocationCode(peq.code) &&
					this.props.Section &&
					this.props.Section.id === peq.sectionId),
		);
	};

	private setAutomaticPensDone = () => {
		this.setState({ automaticSetPens: false });
	};
	private unitChanged = () => {
		/// if more units is added handle it here
	};

	private showData = async () => {
		const selectedProcessEquipment = this.props.processEquipments.find(
			processEquipment => this.state.selectedOption.value === processEquipment.id,
		);
		this.setState({
			selectedProcessEquipment,
			loading: true,
			automaticSetPens: false,
		});
		if (selectedProcessEquipment && selectedProcessEquipment.equipment && selectedProcessEquipment.code) {
			let sucess = await this.props.fetchLocations(
				selectedProcessEquipment.equipment,
				selectedProcessEquipment.code,
			);
			if (!sucess) {
				showAlert(localized('FailedToFetchNewestLocations'));
			}
			let ArrayOfPromises = Array<Promise<void>>();
			ArrayOfPromises.push(this.props.getSyncData());
			ArrayOfPromises.push(this.props.getunitsData());
			ArrayOfPromises.push(this.props.getunits());
			await Promise.all(ArrayOfPromises);
		}
		this.setState({ loading: false });
	};
	private equipmentChanged = (equipmentOption: Option) => {
		const selectedProcessEquipment = this.props.processEquipments.find(
			processEquipment => equipmentOption.value === processEquipment.id,
		);

		this.setState({
			selectedOption: equipmentOption,
			equipmentOptions: GetUnitTypes(selectedProcessEquipment && selectedProcessEquipment.equipment, true),
			selectedEquipmentOption: GetUnitOption(selectedProcessEquipment && selectedProcessEquipment.equipment),
		});
	};

	private renderButtons() {
		let buttons = (
			<ViewWeb className="buttons-container">
				{this.props.closeModal && (
					<SkioldButton title={localized('Close')} onPress={this.closeModal} theme="grey" />
				)}
				<SkioldButton title={localized('Save')} onPress={this.saveLocations} />
				{this.state.showSpinner && (
					<ViewWeb className="is-saving-spinner">
						<ClipLoader color="#f2ac40" size={35} />
					</ViewWeb>
				)}
			</ViewWeb>
		);

		return buttons;
	}
	private saveLocations = async (calledFromSave: boolean = true) => {
		this.setState({ showSpinner: true });
		let shouldClose = await this.LocationSave!(calledFromSave);
		this.setState({ showSpinner: false });
		if (shouldClose && this.props.closeModal) {
			this.props.closeModal();
		}
	};
	private setAutomaticPens = () => this.setState({ automaticSetPens: true });
	public LocationSave: ((calledFromSave: boolean) => Promise<boolean>) | undefined;
	public checkForUnsavedChanges: (() => Promise<SaveCloseDontClose>) | undefined;
	private mapCheckForChangesFunction = (method: () => Promise<SaveCloseDontClose>) =>
		(this.checkForUnsavedChanges = method);
	private save = (method: (calledFromSave: boolean) => Promise<boolean>) => (this.LocationSave = method);
	private closeModal = async (calledFromSave: boolean = false) => {
		if (this.checkForUnsavedChanges) {
			const saveCloseDontClose = await this.checkForUnsavedChanges();
			if (saveCloseDontClose === SaveCloseDontClose.saveAndClose && !calledFromSave) {
				await this.saveLocations(calledFromSave);
			}
			if (
				this.props.closeModal &&
				(saveCloseDontClose === SaveCloseDontClose.saveAndClose ||
					saveCloseDontClose === SaveCloseDontClose.close)
			) {
				this.props.closeModal();
			}
		} else if (this.props.closeModal) {
			this.props.closeModal();
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(UnitsOnProcessEquipment);
