import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetPoolYoungFemaleShouldDepartureList } from 'shared/helpers/pool-young-female-helper/pool-young-female-event-list-helper';
import { setPyfShouldDeparture } from 'shared/helpers/pool-young-female-helper/pool-young-female-helper';
import { DeleteManyPoolYoungFemale, UpsertManyPoolYoungFemale } from 'shared/state/ducks/pool-young-female/operations';
import { SetCheckedCount, SetSowsCount } from 'shared/state/ducks/stem-animals/operations';
import { SetDeleteFunction, SetPrintFunction, SetSaveFunction } from 'shared/state/ducks/work-list-settings/operations';
import { WebAppState } from 'web/state/store.web';
import { SkioldDatePicker } from '../../skiold-components/skiold-date-picker/skiold-date-picker';
import { usePyfShouldDeparture } from './pyf-should-departure-overview';
interface PropsFromParent {}

export const PoolYoungFemaleShouldDepartureHeader: FC<PropsFromParent> = React.memo(({ ...props }) => {
	const dispatch = useDispatch();
	const language = useSelector((state: WebAppState) => state.profile.active && state.profile.active.language);
	const siteId = useSelector((state: WebAppState) => state.profile.active && state.profile.active.siteId);

	const { tableItems, printData, setRegistrationDate, registrationDate } = usePyfShouldDeparture();
	const [isCheckedCount, setIsCheckedCount] = useState<string>('0/0');

	useEffect(() => {
		let checked = 0;
		let total = 0;
		tableItems.forEach(item => {
			if (item.pigCount) {
				if (item.checked) {
					checked += item.pigCount;
				}
				total += item.pigCount;
			}
		});
		setIsCheckedCount(`${total}/${checked}`);
		SetSowsCount(total)(dispatch);
		SetCheckedCount(checked, true)(dispatch);
	}, [tableItems, dispatch]);

	const pyfs = useSelector((state: WebAppState) => state.poolYoungFemales.entities);

	useEffect(() => {
		const deleteShouldDeparture = () => {
			const dataToSave = setPyfShouldDeparture(pyfs, tableItems, registrationDate);
			if (dataToSave && dataToSave.length > 0) {
				DeleteManyPoolYoungFemale(dataToSave)(dispatch);
			}
		};
		SetDeleteFunction(deleteShouldDeparture)(dispatch);

		return () => {
			SetDeleteFunction(undefined)(dispatch);
		};
	}, [tableItems, pyfs, dispatch, registrationDate]);

	useEffect(() => {
		const updateShouldDeparture = () => {
			const dataToSave = setPyfShouldDeparture(pyfs, tableItems, registrationDate);
			if (dataToSave && dataToSave.length > 0) {
				UpsertManyPoolYoungFemale(dataToSave)(dispatch);
			}
		};

		SetSaveFunction(updateShouldDeparture)(dispatch);

		return () => {
			SetSaveFunction(undefined)(dispatch);
		};
	}, [tableItems, pyfs, registrationDate, dispatch]);

	useEffect(() => {
		const printShouldDeparture = async () => {
			if (siteId) {
				await GetPoolYoungFemaleShouldDepartureList(
					printData,
					'PoolYoungFemaleShouldDepartureEvents.pdf',
					siteId,

					Intl.DateTimeFormat().resolvedOptions().timeZone,
					language,
				);
			}
		};
		SetPrintFunction(printShouldDeparture)(dispatch);

		return () => {
			SetPrintFunction(undefined)(dispatch);
		};
	}, [printData, dispatch, language, siteId]);

	const dateChanged = (date: Date) => {
		setRegistrationDate(date);
	};

	return <SkioldDatePicker selectedDate={registrationDate} maxDate={new Date()} onDateChanged={dateChanged} />;
});
