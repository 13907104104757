import { createAsyncThunk } from '@reduxjs/toolkit';
import { AsyncOperationHandler } from '../../../helpers/redux-helpers';
import { TaskAlertIdentifiers } from './types';

export const getControllerTasks = createAsyncThunk('feedComputers/getControllerTasks', (siteId: string) => {
	return AsyncOperationHandler(client => client.distriwinTask_GetBySiteId(siteId));
});

export const getControllerTaskAlerts = createAsyncThunk(
	'feedComputers/getControllerTaskAlerts',
	(identifiers: TaskAlertIdentifiers) => {
		const { location, controllerNumber, taskNumber } = identifiers;
		return AsyncOperationHandler(client => client.distriwinTaskAlert_Get(location, controllerNumber, taskNumber));
	},
);
