import { Sorting } from '@devexpress/dx-react-grid';
import memoize from 'memoize-one';
import React from 'react';
import isEqual from 'react-fast-compare';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
	EsfDailyStatus,
	EsfFeedingStatus,
	EsfStatusAcknowledge,
	IEsfDailyStatus,
	IEsfFeedingStatus,
	IMoveEvent,
	IProcessEquipmentData,
	IStation,
	IStemAnimal,
	IUnitToPen,
	PregnancyValidationType,
	ShortageEsfListSetting,
	WorkListType,
} from 'shared/api/api';
import { areDatesEqual } from 'shared/helpers/date-helpers';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { deepCopy2, rangeFilterMethodGrid } from 'shared/helpers/general-helpers';
import {
	memoizeGetESFProcessEquipment,
	memoizeValidationSettingPlan,
} from 'shared/helpers/memoize-getters/memoize-getters';
import { RefType } from 'shared/helpers/ref-type';
import {
	getShortageEsfData,
	ShortageEsfWorkListItem,
	ShortageEsfWorkListPropsFromParent,
} from 'shared/helpers/work-list-helpers/esf-list-helpers/shortage-esf-helpers';
import { SaveEsfDailyStatus } from 'shared/state/ducks/esf-daily-status/operations';
import { AcknowledgeFeedingStatus } from 'shared/state/ducks/esf-status/operations';
import { SetCheckedCount } from 'shared/state/ducks/stem-animals/operations';
import { localized } from 'shared/state/i18n/i18n';
import { SharedAppState } from 'shared/state/store.shared';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { showAlert, ShowConfirmAlert } from '../../skiold-alert/skiold-alert';
import { SkioldCheckbox } from '../../skiold-components/skiold-checkbox/skiold-checkbox';
import SkioldTableGrid from '../../skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { GroupedHeader } from '../../skiold-components/skiold-table/skiold-table-grid/skiold-table-grid-grouped-header';
import { SowListConstants } from '../../stem-animal/animal-lists/table-constants';
import '../list-setup.scss';
import { getStemAnimalNumberColor } from '../work-list-helper';
import './shortage-esf-list-table.scss';
import { Spinner } from '../../spinner/spinner';

const mapStateToProps = (state: SharedAppState) => {
	return {
		siteId: state.profile.active!.siteId!,
		workListSettings: state.workListSettings.entities.find(
			setup => setup.type === WorkListType.ShortageEsfListSetting,
		)! as ShortageEsfListSetting,
		stemAnimals: state.stemAnimals.entities,
		validationSettings: memoizeValidationSettingPlan(
			state.validationSetup.entity,
			PregnancyValidationType.MatingToFarrowing,
		),
		unitToPens: state.unitToPenData.data,
		processEquipmentData: memoizeGetESFProcessEquipment(state.processEquipmentData.entities),
		locations: state.locations,
		moveEvents: state.moveEvents.entities,
		esfStatusData: state.esfStatus.data,
		stations: state.station.data,
		esfDailyStatusData: state.esfDailyStatus.entities,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	saveDailyStatus: (dailyStatus: EsfDailyStatus) => SaveEsfDailyStatus(dailyStatus)(dispatch),
	SetCheckedCount: (count: number, reset?: boolean) => SetCheckedCount(count, reset)(dispatch),
	acknowledgeFeedStatusDate: (acknowledge: EsfStatusAcknowledge) => AcknowledgeFeedingStatus(acknowledge)(dispatch),
});

type WorkListTableProps = ReturnType<typeof mapStateToProps> &
	ReturnType<typeof mapDispatchToProps> &
	ShortageEsfWorkListPropsFromParent;

interface WorkListTableState {
	columnExte: any[];
	loading: boolean;
	columns: any[];
	commitAll: boolean;
	workListData: any;
	workListDataCopy: any;
	showErrorMsg: boolean;
	showSpinner: boolean;
}

class ShortageEsfListTable extends React.PureComponent<WorkListTableProps, WorkListTableState> {
	public static defaultProps: Partial<WorkListTableProps> = {};
	public SkioldTableRef: any;

	private generateDataWithSetState = memoize(
		(
			stemAnimals: IStemAnimal[],
			unitToPens: IUnitToPen[],
			penId: string | undefined,
			selectedSectionId: string | undefined,
			moveEvents: IMoveEvent[],
			esfFeedingStatus: IEsfFeedingStatus[],
			esfDailyStatusData: IEsfDailyStatus[],
			esfStation: IStation[],
			esfLocationsWithSettedPens: IProcessEquipmentData[],
			siteId: string,
			workListSettings: ShortageEsfListSetting,
			validationSettings: number,
		) => {
			const shortageListData = getShortageEsfData(
				stemAnimals,
				penId ? unitToPens.filter(utp => utp.penId === penId) : unitToPens,
				selectedSectionId,
				moveEvents,
				esfFeedingStatus,
				esfDailyStatusData,
				esfStation,
				esfLocationsWithSettedPens,
				siteId,
				workListSettings,
				validationSettings,
			);

			this.setState(
				{
					workListData: shortageListData.data,
					workListDataCopy: shortageListData.data,
					showSpinner: false,
				},
				this.checkForInvalidData,
			);
		},
	);
	private handleSpinner = memoize(
		(
			stemAnimals: IStemAnimal[],
			unitToPens: IUnitToPen[],
			penId: string | undefined,
			selectedSectionId: string | undefined,
			moveEvents: IMoveEvent[],
			esfFeedingStatus: IEsfFeedingStatus[],
			esfDailyStatusData: IEsfDailyStatus[],
			esfStation: IStation[],
			esfLocationsWithSettedPens: IProcessEquipmentData[],
			siteId: string,
			workListSettings: ShortageEsfListSetting,
			validationSettings: number,
		) => {
			this.setState({
				showSpinner: true,
			});
		},
	);

	private defaultSorting = [{ columnName: 'cycleDays', direction: 'desc' }] as Sorting[];
	constructor(props: WorkListTableProps) {
		super(props);
		this.state = {
			columns: this.generateColumns(),
			columnExte: this.generateColumnsExtensions(),
			commitAll: false,
			loading: false,
			workListData: [],
			workListDataCopy: [],
			showErrorMsg: true,
			showSpinner: true,
		};
	}

	public componentDidUpdate(prevProps: WorkListTableProps) {
		this.handleSpinner(
			this.props.stemAnimals,
			this.props.unitToPens,
			this.props.penId,
			this.props.sectionId,
			this.props.moveEvents,
			this.props.esfStatusData,
			this.props.esfDailyStatusData,
			this.props.stations,
			this.props.esfLocationsWithSettedPens,
			this.props.siteId,
			this.props.workListSettings,
			this.props.validationSettings,
		);
		setTimeout(() => {
			this.generateDataWithSetState(
				this.props.stemAnimals,
				this.props.unitToPens,
				this.props.penId,
				this.props.sectionId,
				this.props.moveEvents,
				this.props.esfStatusData,
				this.props.esfDailyStatusData,
				this.props.stations,
				this.props.esfLocationsWithSettedPens,
				this.props.siteId,
				this.props.workListSettings,
				this.props.validationSettings,
			);
		}, 100);
	}

	public componentDidMount() {
		// this.generateDataWithSetState(
		// 	this.props.stemAnimals,
		// 	this.props.unitToPens,
		// 	this.props.penId,
		// 	this.props.sectionId,
		// 	this.props.moveEvents,
		// 	this.props.esfStatusData,
		// 	this.props.esfDailyStatusData,
		// 	this.props.stations,
		// 	this.props.esfLocationsWithSettedPens,
		// 	this.props.siteId,
		// 	this.props.workListSettings,
		// 	this.props.validationSettings,
		// );
	}

	public checkForInvalidData = () => {
		if (this.state.showErrorMsg && this.state.workListData.some(shortageData => !shortageData.isDataValid)) {
			showAlert(localized(ExceptionMessage.VALIDATION_WARNING_ESF_DATA_IS_NOT_UP_TO_DATE));
			this.setState({ showErrorMsg: false });
		}
	};

	public ResetWorkList() {
		if (this.state.workListDataCopy) {
			const dataCopy = deepCopy2(this.state.workListDataCopy) as ShortageEsfWorkListItem[];
			dataCopy.forEach(item => {
				item.isChecked = false;
			});
			this.setState({ workListData: dataCopy, commitAll: false });
		}
	}

	public async SaveWorklist() {
		let ArrayOfPromises = Array<Promise<void>>();
		if (this.SkioldTableRef) {
			let data = this.SkioldTableRef.GetSortedData() as ShortageEsfWorkListItem[];
			let dataCopy = [...this.state.workListDataCopy];
			if (data) {
				data.forEach(ShortageEsfWorkListItem => {
					if (ShortageEsfWorkListItem.isChecked) {
						let oldItemIndex = dataCopy.findIndex(
							ShortageEsfWorkListItemFromCopy =>
								ShortageEsfWorkListItemFromCopy.stemAnimalId === ShortageEsfWorkListItem.stemAnimalId,
						);
						let oldItem = { ...dataCopy[oldItemIndex] };
						if (ShortageEsfWorkListItem.esfDailyStatusToday) {
							let dailyStatusToday = EsfFeedingStatus.fromJS({});
							dailyStatusToday.init({ ...ShortageEsfWorkListItem.esfDailyStatusToday });

							let todaysDate = new Date();
							let acknowledgeUpdate = new EsfStatusAcknowledge({
								id: dailyStatusToday.id,
								acknowledgeDate: todaysDate,
							});

							dailyStatusToday.acknowledgeDate = todaysDate;
							if (
								dailyStatusToday &&
								oldItem &&
								oldItem.esfDailyStatusToday &&
								!areDatesEqual(
									oldItem.esfDailyStatusToday.acknowledgeDate,
									dailyStatusToday.acknowledgeDate,
								)
							) {
								dataCopy[oldItemIndex].esfDailyStatusToday = dailyStatusToday;
								ArrayOfPromises.push(this.props.acknowledgeFeedStatusDate(acknowledgeUpdate));
							}
						}
						if (ShortageEsfWorkListItem.esfDailyStatusYesterday) {
							let dailyStatusYesterday = EsfDailyStatus.fromJS({});
							dailyStatusYesterday.init({ ...ShortageEsfWorkListItem.esfDailyStatusYesterday });
							dailyStatusYesterday.acknowledged = true;
							if (
								dailyStatusYesterday &&
								!isEqual(dataCopy[oldItemIndex].esfDailyStatusYesterday, dailyStatusYesterday)
							) {
								dataCopy[oldItemIndex].esfDailyStatusYesterday = dailyStatusYesterday;
								ArrayOfPromises.push(this.props.saveDailyStatus(dailyStatusYesterday));
							}
						}
					}
				});
			}
			await Promise.all(ArrayOfPromises);
			this.setState({ workListDataCopy: dataCopy });
		}

		return true;
	}

	public async CheckSavePregnancyEventListsStatus() {
		if (this.SkioldTableRef) {
			let data = this.SkioldTableRef.GetSortedData() as ShortageEsfWorkListItem[];
			let dataCopy = [...this.state.workListDataCopy];
			if (data) {
				let saveItems = data.find(ShortageEsfWorkListItem => {
					if (ShortageEsfWorkListItem.isChecked) {
						let oldItemIndex = dataCopy.findIndex(
							ShortageEsfWorkListItemFromCopy =>
								ShortageEsfWorkListItemFromCopy.stemAnimalId === ShortageEsfWorkListItem.stemAnimalId,
						);
						let oldItem = { ...dataCopy[oldItemIndex] };
						if (ShortageEsfWorkListItem.esfDailyStatusToday) {
							let todaysDate = new Date();
							if (
								oldItem &&
								oldItem.esfDailyStatusToday &&
								!areDatesEqual(oldItem.esfDailyStatusToday.acknowledgeDate, todaysDate)
							) {
								return true;
							}
						}
						if (ShortageEsfWorkListItem.esfDailyStatusYesterday) {
							let dailyStatusYesterday = EsfDailyStatus.fromJS({});
							dailyStatusYesterday.init({ ...ShortageEsfWorkListItem.esfDailyStatusYesterday });
							dailyStatusYesterday.acknowledged = true;
							if (
								dailyStatusYesterday &&
								!isEqual(dataCopy[oldItemIndex].esfDailyStatusYesterday, dailyStatusYesterday)
							) {
								return true;
							}
						}
					}
					return false;
				});

				if (saveItems && (await ShowConfirmAlert(localized('saveWorklist')))) {
					await this.SaveWorklist();
				}
			}
		}
		return true;
	}

	public render() {
		return (
			<ViewWeb className="align-items-center">
				{this.state.showSpinner ? (
					<Spinner></Spinner>
				) : (
					<SkioldTableGrid
						tableKey={'shortageEsfWorkListTable'}
						columns={this.state.columns}
						ColumnExtensions={this.state.columnExte}
						data={this.state.workListData}
						ref={this.setTableRef}
						className={'work-list-table shortage-esf-list-table'}
						sortHeaderId={this.defaultSorting}
						onClickedRow={this.props.rowSelected}
						groupedColumns={this.groupedColumns}
					/>
				)}
			</ViewWeb>
		);
	}

	private groupedColumns: GroupedHeader[] = [
		{
			title: localized('TodayFE'),
			children: [{ columnName: 'restToday' }, { columnName: 'eatenToday' }, { columnName: 'curveToday' }],
		},
		{
			title: localized('YesterdayFE'),
			children: [
				{ columnName: 'restYesterday' },
				{ columnName: 'eatenYesterday' },
				{ columnName: 'curveYesterday' },
			],
		},
	];

	public generateCommitAllCheckBox = () => {
		return (
			<ViewWeb className="checkbox-view-filter-style">
				<SkioldCheckbox
					isChecked={this.state.commitAll}
					className="checkbox-style-filter"
					onClick={this.commitAllCheckBoxClicked}
				/>
			</ViewWeb>
		);
	};

	public generateColumnsExtensions() {
		let columnExtensions = [
			{
				columnName: 'cycleDays',
				width: 100,
			},
			{
				columnName: 'animalNumber',
				wordWrapEnabled: true,
				width: 100,
			},
			{
				columnName: 'currentLocation',
				width: 200,
			},
			{
				columnName: 'restToday',
				width: 100,
			},
			{
				columnName: 'eatenToday',
				width: 100,
			},
			{
				columnName: 'curveToday',
				width: 100,
			},
			{
				columnName: 'restYesterday',
				width: 100,
			},
			{
				columnName: 'eatenYesterday',
				width: 100,
			},
			{
				columnName: 'curveYesterday',
				width: 100,
			},
			{
				columnName: 'commitAll',
				filteringEnabled: false,
				width: 100,
			},
		];
		return columnExtensions;
	}

	private getAnimalNumberText = (tableItem: ShortageEsfWorkListItem) => tableItem.animalNumber;

	private generateColumns() {
		let columns: any[] = [
			{
				name: 'cycleDays',
				title: localized('Days'),
				width: SowListConstants.cycleDaysWidth,
				filterFunction: rangeFilterMethodGrid,
			},
			{
				name: 'animalNumber',
				title: localized('animalNumber'),
				className: getStemAnimalNumberColor,
				width: SowListConstants.animalNrWidth,
				getCellValue: this.getAnimalNumberText,
			},
			{
				name: 'currentLocation',
				title: localized('location'),
			},
			{
				name: 'restToday',
				title: localized('Rest'),
			},
			{
				name: 'eatenToday',
				title: localized('Eat'),
			},
			{
				name: 'curveToday',
				title: localized('curve'),
			},
			{
				name: 'restYesterday',
				title: localized('Rest'),
			},
			{
				name: 'eatenYesterday',
				title: localized('Eat'),
			},
			{
				name: 'curveYesterday',
				title: localized('curve'),
			},
			{
				name: 'commitAll',
				title: localized('completed'),
				rowSelectedDisabledForColumn: true,
				filter: this.generateCommitAllCheckBox,
				getCellValue: this.generateCommitCheckBox,

				sortable: false,
			},
		];
		return columns;
	}

	private generateCommitCheckBox = (shortageEsfWorkListItem: ShortageEsfWorkListItem) => {
		if (shortageEsfWorkListItem.isDataValid) {
			return (
				<SkioldCheckbox
					isChecked={shortageEsfWorkListItem.isChecked}
					className="checkbox-style"
					onClick={async () => {
						await this.commitedClicked(shortageEsfWorkListItem);
					}}
				/>
			);
		} else {
			return (
				<ViewWeb className="red-box">
					<TextWeb>X</TextWeb>
				</ViewWeb>
			);
		}
	};

	private commitedClicked = async (shortageEsfWorkListItem: ShortageEsfWorkListItem) => {
		let dataCopy = [...this.state.workListData] as ShortageEsfWorkListItem[];
		let dataIndex = dataCopy.findIndex(
			ShortageEsfWorkListItemCopy =>
				ShortageEsfWorkListItemCopy.stemAnimalId === shortageEsfWorkListItem.stemAnimalId,
		);
		let workListDataCopy = { ...dataCopy[dataIndex] } as ShortageEsfWorkListItem;
		workListDataCopy.isChecked = shortageEsfWorkListItem.isChecked ? false : true;
		dataCopy[dataIndex] = workListDataCopy;
		if (this.state.commitAll === true) {
			this.setState({ workListData: dataCopy, commitAll: false });
		} else {
			this.setState({ workListData: dataCopy });
		}
		this.props.SetCheckedCount(dataCopy[dataIndex].isChecked ? 1 : -1);
	};

	private setTableRef = (m: any) => {
		if (m) {
			this.SkioldTableRef = m;
		}
	};
	private commitAllCheckBoxClicked = () => {
		const isChecked = this.state.commitAll ? false : true;
		let dataCopy = [...this.state.workListData] as ShortageEsfWorkListItem[];
		dataCopy.forEach(shortageEsfWorkListItem => {
			if (shortageEsfWorkListItem.isDataValid) {
				shortageEsfWorkListItem.isChecked = isChecked;
			}
		});
		this.props.SetCheckedCount(
			dataCopy.reduce((a, b) => a + (b && b.isChecked ? 1 : 0), 0),
			true,
		);
		this.setState({ workListData: dataCopy, commitAll: isChecked });
	};
}

export default connect<
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	RefType,
	SharedAppState
>(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ShortageEsfListTable);
