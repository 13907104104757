import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import { StoreContainer } from 'shared/state/store-container';
import { WebAppState } from 'web/state/store.web';
import * as Action from './actions';

export function GetDistriwinFeedConsumptionDtos(fromDate: Date, toDate: Date, siteId: string) {
	return AsyncOperationBuilder2(
		Action.getDistriwinFeedConsumptionDtos,
		client => client.distriwinFeedConsumption_GetUsageByDate(fromDate, toDate, siteId),
		{
			fromDate,
			toDate,
			siteId,
		},
	);
}
