import memoize from 'memoize-one';
import moment from 'moment';
import {
	IMatingBatch,
	IMoveEvent,
	IPregnancyEvent,
	IScanningListSettingBase,
	IStemAnimal,
	MatingBatch,
	PregnancyState,
	SowState,
} from 'shared/api/api';
import { calculateDays } from 'shared/helpers/general-helpers';
import { getStemAnimalPen } from 'shared/helpers/location-helper';
import { getMatingBatch } from 'shared/helpers/matingbatch-helper';
import { NaturalSort } from 'shared/helpers/natural-sort';
import { sortPregnanciesByDate } from 'shared/helpers/pregnancy-helper/sort-pregnancies';
import { getActiveSows } from 'shared/helpers/stemanimal-helper/stem-animal-input-helper';
import { calculateLitter1, getState } from 'shared/helpers/stemanimal-helper/stemanimal-helper';
import { LocationsState } from 'shared/state/ducks/locations';
import { ScanningListTableItem } from './scanning-work-list-item';
import { FarrowingWorkListItem } from 'web/view/components/work-lists/farrowing-work-list/farrowing-work-list-item';

export const generateDataScanningWorkListMemoized = memoize(
	(animals, pregnancies, matingBatches, worklistSetting, locations, moveEvents) => {
		let data = genereateScanningListTableData(pregnancies, matingBatches, worklistSetting, locations, moveEvents);
		return {
			data: data.workListData.sort((a: ScanningListTableItem, b: ScanningListTableItem) =>
				NaturalSort(b.cycleDays, a.cycleDays),
			),
			matingBatchNumbers: data.batchNumbersInUse,
		};
	},
);

export function genereateScanningListTableData(
	pregnancyEvents: { [key: string]: IPregnancyEvent[] },
	matingBatches: IMatingBatch[],
	workListSetting: IScanningListSettingBase,
	locations: LocationsState,
	moveEvents: IMoveEvent[],
) {
	let activeSows = getActiveSows();
	const batchNumbersInUse: string[] = [];
	let workListData = [] as ScanningListTableItem[];
	let batchesToUse = getBatchesToUseReal(matingBatches, workListSetting);

	for (let sow of activeSows) {
		let sta = getState(sow.id!);

		if (!sow || (sta !== SowState.Pregnant && sta !== SowState.PregnantAndNursing)) {
			continue;
		}

		let sowPregnancies = pregnancyEvents[sow.id!];
		const filteredPregnancyEvents = sortPregnanciesByDate(
			sowPregnancies.filter(event => event.state === PregnancyState.Mated),
		);
		let mated = filteredPregnancyEvents[0];
		let scanned;
		let matedDate = mated.date;
		if (!matedDate) {
			continue;
		}

		const filteredScanningPregnancyEvents = sortPregnanciesByDate(
			sowPregnancies.filter(
				event => event.state === PregnancyState.Scanned && mated.pregnancyId === event.pregnancyId,
			),
		);

		let cycleDays = calculateDays(matedDate!, new Date());
		const weeks = getWorkListWeekDay(matedDate!, workListSetting.daysAfterMating!);
		const batch = getMatingBatch(sow.id!);

		if (filteredScanningPregnancyEvents.length > 0) {
			scanned = filteredScanningPregnancyEvents[0];
		}

		if (!(workListSetting.daysAfterMating && batchesToUse.matingBatchesToUse.find(b => batch && b.id === batch.id))) {
			continue;
		}
		// Ensure that a scanning in the same week doesn't occur
		if (scanned) {
			let batchStartWeek: string[] = [];
			batchesToUse.matingBatchesToUse.forEach(b => {
				if (batch && batch.id === b.id) {
					batchStartWeek.push(
						getWorkListWeekDay(b.matingPeriodStart!, workListSetting.daysAfterMating!).toString(),
						getWorkListWeekDay(b.matingPeriodEnd!, workListSetting.daysAfterMating!).toString(),
					);
				}
			});
			let scannedWeek = getWorkListWeekDay(scanned.date, 0).toString();

			if (batchStartWeek.includes(scannedWeek)) {
				continue;
			}
		}

		let workListItem = new ScanningListTableItem();
		workListItem.batchNumber = batch && batch.batchNumber ? batch.batchNumber.toString() : '';
		workListItem.animalNumber = sow.animalNumber;
		workListItem.stemAnimalId = sow.id;
		workListItem.pregnancyId = mated.pregnancyId;
		workListItem.cycleDays = cycleDays;
		workListItem.id = scanned && scanned.id;
		workListItem.createdOn = scanned && scanned.createdOn;
		workListItem.weeks = weeks ? weeks.toString() : '';
		if (!batchNumbersInUse.includes(workListItem.batchNumber)) {
			batchNumbersInUse.push(workListItem.batchNumber);
		}

		if (workListSetting.usesLocation) {
			setLocationStrings(sow, locations, moveEvents, workListItem);
		}

		workListItem.litter = calculateLitter1(sow, new Date(8640000000000000), pregnancyEvents[sow.id!]);
		workListData.push(workListItem);
	}
	return { workListData, batchNumbersInUse };
}

export function getBatchesToUse(matingBatches: IMatingBatch[], workListSetting: IScanningListSettingBase) {
	return matingBatches.filter(
		a =>
			(a.matingPeriodStart &&
				workListSetting.daysAfterMating &&
				getWorkListWeekDay(new Date(), -workListSetting.daysAfterMating) ===
				getWorkListWeekDay(a.matingPeriodStart, 0)) ||
			(a.matingPeriodEnd &&
				workListSetting.daysAfterMating &&
				getWorkListWeekDay(new Date(), -workListSetting.daysAfterMating) ===
				getWorkListWeekDay(a.matingPeriodEnd, 0)),
	);
}

export function getBatchesToUseReal(matingBatches: IMatingBatch[], workListSetting: IScanningListSettingBase): { matingBatchesToUse: IMatingBatch[], currentBatch: IMatingBatch } {
	let matingBatchesToUse: IMatingBatch[] = [];
	let currentBatch: IMatingBatch = new MatingBatch();
	if (!workListSetting || workListSetting.daysAfterMating === undefined) {
		return { matingBatchesToUse, currentBatch };
	}

	let matingBatchesSorted = matingBatches.slice().sort((a, b) => ( a.matingPeriodStart!.getTime() - b.matingPeriodStart!.getTime() ));
	// Add prev and next mating batch
	const newDay = new Date().withoutTime();
	newDay.setDate(newDay.getDate() - workListSetting.daysAfterMating);


	let batchesToUse = matingBatchesSorted.findIndex(
		a =>
			a.matingPeriodStart && a.matingPeriodEnd &&
			a.matingPeriodStart <= newDay &&
			a.matingPeriodEnd >= newDay
	);

	if (batchesToUse >= 0) {
		matingBatchesSorted[batchesToUse - 1] && matingBatchesToUse.push(matingBatchesSorted[batchesToUse - 1]);
		matingBatchesSorted[batchesToUse] && matingBatchesToUse.push(matingBatchesSorted[batchesToUse]);
		matingBatchesSorted[batchesToUse + 1] && matingBatchesToUse.push(matingBatchesSorted[batchesToUse + 1]);
		currentBatch = matingBatchesSorted[batchesToUse];
	}
	return { matingBatchesToUse, currentBatch };
}

function getWorkListWeekDay(eventDate: Date, afterEventDays: number) {
	/// vi skal lige have den her ud
	const newDay = new Date(eventDate.withoutTime());
	newDay.setDate(newDay.getDate() + afterEventDays);
	return moment(newDay).isoWeek();
}

export function setLocationStrings(
	sow: IStemAnimal,
	locations: LocationsState,
	moveEvents: IMoveEvent[],
	scanningListItem: ScanningListTableItem,
) {
	const pen = getStemAnimalPen(sow, moveEvents, locations.pens);
	if (pen) {
		const section = locations.sections.find(x => x.id === pen!.sectionId);
		if (section) {
			scanningListItem.penString = section && section.usePens ? pen!.name : '';
			const building = locations.buildings.find(x => x.id === pen!.buildingId);
			if (building) {
				scanningListItem.sectionString = building && building.useSections ? section!.name : '';
				scanningListItem.buildingString = building.name;
			}
		}
	}
}
