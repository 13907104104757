export const SET_IS_AUTH_ACTION = 'SET_IS_AUTH_ACTION';
export const SET_SHOULD_REFRESH_ACTION = 'SET_SHOULD_REFRESH_ACTION';
export const SET_PERFORMING_REFRESH_ACTION = 'SET_PERFORMING_REFRESH_ACTION';
export const SET_IS_ADMIN = 'SET_IS_ADMIN';

export interface AuthenticationState {
	isAuthenticated: boolean | undefined;
	shouldRefresh: boolean;
	performingRefresh: boolean;
	isAdmin: boolean;
}
