import memoize from 'memoize-one';
import {
	ISite,
	AnimalType,
	LocationType,
	ISlaughterSiteDataDto,
	SlaughterSiteDataDto,
	SlaughterDataType,
} from 'shared/api/api';
import { LocationsState } from 'shared/state/ducks/locations';
import { getBuildingBySectionId, getSectionBySectionId } from '../location-helper';

import { Option } from 'react-dropdown';
import { deepCopy2 } from '../general-helpers';
import { NaturalSortDates } from '../natural-sort';

export interface SlaughterSiteDataDtoExtend extends ISlaughterSiteDataDto {
	isChecked: boolean;
}

export const generateLocationOptionsForSlaughterHouse = memoize(
	(locationState: LocationsState, locationsMemo: { [key: string]: any }, site: ISite) => {
		const options: Option[] = [];
		if (site && site.id && site.siteName) {
			options.push({ label: site.siteName, value: '' });
		}
		const sectionsFilteredToFinisher = locationState.sections.filter(
			s =>
				s.animalType === AnimalType.Finisher ||
				(s.animalType === AnimalType.FRATS &&
					(s.type === LocationType.ReliefFinisher || s.type === LocationType.Finisher)),
		);

		sectionsFilteredToFinisher.forEach(section => {
			if (section.name && section.id) {
				const building = getBuildingBySectionId(section.id, locationsMemo);
				if (building && building.name) {
					options.push({ label: building.name + ' - ' + section.name, value: section.id });
				}
			}
		});
		return options;
	},
);

export function getLocationStringForSlaughterData(memorizedLocations: { [key: string]: any }, siteName: string) {
	return (sd: SlaughterSiteDataDtoExtend | ISlaughterSiteDataDto) =>
		GetLocationStringForSlaughterDataSectionId(memorizedLocations, siteName, sd.sectionId);
}

export function GetLocationStringForSlaughterDataSectionId(
	memorizedLocations: { [key: string]: any },
	siteName: string,
	sectionId?: string,
) {
	if (sectionId) {
		const building = getBuildingBySectionId(sectionId, memorizedLocations);
		const section = getSectionBySectionId(sectionId, memorizedLocations);
		return (
			(building && building.name ? building.name : '') +
			(building && building.useSections && section && section.name ? ' - ' + section.name : '')
		);
	} else {
		return siteName;
	}
}

export const generateDataForOverviewTable = memoize((data: ISlaughterSiteDataDto[]) => {
	const groupedBySupplier = deepCopy2(data)
		.sort((a, b) => NaturalSortDates(a.date, b.date))
		.groupBy('supplier');
	const tableData = new Array<ISlaughterSiteDataDto>();
	groupedBySupplier.forEach(dataBySupplier => {
		const dataGroupedByLocation = dataBySupplier.groupBy('sectionId');
		dataGroupedByLocation.forEach(dataBySupplierByLocation => {
			const dataGroupedByDelieveryDate = dataBySupplierByLocation.groupBy('date');
			dataGroupedByDelieveryDate.forEach(dataBySupplierByLocationByDate => {
				let initial: boolean = true;

				// DC doesn't give meatPct in some cases - Subtract those who dont in meat calculations
				let subtractPigForMeatCalc = 0;
				dataBySupplierByLocationByDate.forEach(itemToAdd => {
					if (initial) {
						itemToAdd.amount = dataBySupplierByLocationByDate.length;
						tableData.push(itemToAdd);
						initial = false;
						if (!itemToAdd.meat) {
							subtractPigForMeatCalc++;
						}
					} else {
						const currentData = tableData[tableData.length - 1];
						currentData.price = (currentData.price ?? 0) + (itemToAdd.price ?? 0);
						currentData.meat = (currentData.meat ?? 0) + (itemToAdd.meat ?? 0);
						if (!itemToAdd.meat) {
							subtractPigForMeatCalc++;
						}
						currentData.weight = (currentData.weight ?? 0) + (itemToAdd.weight ?? 0);
						tableData[tableData.length - 1] = currentData;
					}
				});
				if (dataBySupplierByLocationByDate.length > 0) {
					const currentDataToModify = tableData[tableData.length - 1];
					currentDataToModify.price =
						(currentDataToModify.price ?? 0) / dataBySupplierByLocationByDate.length;
					currentDataToModify.meat =
						(currentDataToModify.meat ?? 0) /
						(dataBySupplierByLocationByDate.length - subtractPigForMeatCalc);
					currentDataToModify.weight = currentDataToModify.weight ?? 0;
					tableData[tableData.length - 1] = currentDataToModify;
				}
			});
		});
	});
	return tableData;
});

export function generateSummaryDataForOverviewTableModal(data: SlaughterSiteDataDtoExtend[]) {
	const tableData = SlaughterSiteDataDto.fromJS({});
	data.forEach(data => {
		tableData.weight = (tableData.weight ?? 0) + (data.weight ?? 0);
		tableData.price = (tableData.price ?? 0) + (data.price ?? 0);
		tableData.meat = (tableData.meat ?? 0) + (data.meat ?? 0);
	});
	tableData.weight = (tableData.weight ?? 0) / data.length;
	tableData.price = (tableData.price ?? 0) / data.length;
	tableData.meat = (tableData.meat ?? 0) / data.filter(d => d.meat).length;
	return tableData;
}

export function generateSummaryData(data: ISlaughterSiteDataDto[]) {
	const tableData = SlaughterSiteDataDto.fromJS({});
	let meatPctCount = 0;
	data.forEach(data => {
		tableData.weight = (tableData.weight ? tableData.weight : 0) + (data.weight ? data.weight : 0);
		tableData.price = (tableData.price ? tableData.price : 0) + (data.price ? data.price : 0);
		tableData.meat = (tableData.meat ? tableData.meat : 0) + (data.meat ? data.meat : 0);
		if (data.meat) {
			++meatPctCount;
		}
	});
	tableData.amount = data.length;
	tableData.weight = (tableData.weight ? tableData.weight : 0) / data.length;
	tableData.price = (tableData.price ? tableData.price : 0) / data.length;
	tableData.meat = (tableData.meat ? tableData.meat : 0) / (meatPctCount ? meatPctCount : 1);
	return tableData;
}

export function generateSummaryDataForOverviewTable(data: ISlaughterSiteDataDto[]) {
	const tableData = SlaughterSiteDataDto.fromJS({});
	data.forEach(data => {
		tableData.amount = (tableData.amount ?? 0) + (data.amount ?? 0);
		tableData.weight = (tableData.weight ?? 0) + (data.weight ?? 0);
		tableData.price = (tableData.price ?? 0) + (data.price ?? 0) * (data.amount ?? 0);
		tableData.meat = (tableData.meat ?? 0) + (data.meat ?? 0) * (data.amount ?? 0);
	});
	tableData.weight = (tableData.weight ?? 0) / (tableData.amount ?? 1);
	tableData.price = (tableData.price ?? 0) / (tableData.amount ?? 1);
	tableData.meat = (tableData.meat ?? 0) / (tableData.amount ?? 1);
	return tableData;
}

export const SlaughterDataTableWidths = {
	DateWidth: 95,
	countWidth: 65,
	typeWidth: 80,
	slaughterWeightWidth: 100,
	meatPercentageWidth: 65,
	genderWidth: 80,
	locationWidth: 150,
	priceWidth: 65,
	delieveryNumberWidth: 125,
	slaughterNumberWidth: 95,
	aliveWeightWidth: 130,
	notingWidth: 85,
};

export const slaughterDataForCalculation = (slaughter: ISlaughterSiteDataDto) => {
	return (
		slaughter.type === SlaughterDataType.APig ||
		slaughter.type === SlaughterDataType.Discarded ||
		slaughter.type === SlaughterDataType.OtherDelievered ||
		slaughter.type === SlaughterDataType.ReturnPig
	);
};
