import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ITotalFarrowingTableItem, TotalFarrowingTableItem } from 'shared/api/api';
import sokort_icon from 'shared/assets/src-assets/png/sokort_icon.png';
import { getDateString } from 'shared/helpers/date-helpers';
import { NaturalSortDates } from 'shared/helpers/natural-sort';
import { GetDashboardGetTotalFarrowingTable } from 'shared/state/ducks/dashboard/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import SkioldTableGrid, {
	SkioldTableGrid as SkioldTableRef,
} from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { ViewWeb } from 'web/view/components/utils/web-view';
import SowCard from 'web/view/pages/sow-card/sow-card';
import { GetTotalFarrowingTableDashboardPdf } from 'web/web-helpers/pdf-helper/dashboard-pdf-helper.ts/dashboard-pdf-helper';
import { SkioldModal } from '../../skiold-components/skiold-modal/skiold-modal';
import { SkioldTouchableOpacity } from '../../skiold-components/skiold-touchable-opacity';
import { SowListConstants } from '../../stem-animal/animal-lists/table-constants';
import { Heading } from '../../utils/heading';
import { SkioldImage } from '../../utils/svg/skiold-image';
import { defaultSortingDashboardWeaningModals, getWebAnimalColorStyleDashboard } from '../dashboard-helper';
import { DashboardModalHeader } from '../dashboard-modal-header';
import { ClipLoader } from 'react-spinners';

interface PropsFromParent {
	batchId?: string;
	closeModal: () => void;
}

interface State {
	sowCardModal: boolean;
	stemAnimalId: string;
	data: ITotalFarrowingTableItem[];
	loading: boolean;
}

const mapStateToProps = (state: WebAppState, props: PropsFromParent) => {
	return {
		matingBatch: state.matingBatches.entities.find(mb => mb.id === props.batchId),
		profile: state.profile.active,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
	return {
		GetDashboardGetTotalFarrowingTable: (siteId: string, batchId: string) =>
			GetDashboardGetTotalFarrowingTable(siteId, batchId),
	};
};

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

export class TotalFarrowingsDashboardTable extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		if (this.props.profile && this.props.profile.siteId && this.props.batchId) {
			this.props.GetDashboardGetTotalFarrowingTable(this.props.profile.siteId, this.props.batchId);
		}
		this.state = { sowCardModal: false, stemAnimalId: '', data: [], loading: true };
	}

	public componentDidMount = () => {
		if (this.props.profile && this.props.profile.siteId && this.props.batchId) {
			this.props
				.GetDashboardGetTotalFarrowingTable(this.props.profile.siteId, this.props.batchId)
				.then(d => this.setState({ data: d, loading: false }));
		}
	};
	public render() {
		return (
			<ViewWeb>
				<Heading text={localized('TotalFarrowingsTable')} />
				<DashboardModalHeader
					closeModal={this.props.closeModal}
					matingBatch={this.props.matingBatch}
					printData={this.printData}
				/>

				{this.state.loading ? (
					<ViewWeb className="spinner-container-active-dashboard">
						<ClipLoader color="#f2ac40" size={70} />
					</ViewWeb>
				) : (
					<ViewWeb className="dashboard-modal-table">
						<SkioldTableGrid
							sortHeaderId={defaultSortingDashboardWeaningModals}
							data={this.state.data}
							columns={this.generateColumns}
							ref={this.setTableRef}
							ColumnExtensions={this.getColumnExtenstions}
							usesSummaryRow={true}
							tableKey={'TotalFarrowingDashboardTable'}
						/>

						<SkioldModal
							shouldCloseOnOverlayClick={true}
							padding="0"
							isOpen={this.state.sowCardModal}
							close={this.closeModal}
							justify-content="flex-end"
							max-width="calc(100% - 220px)"
						>
							<SowCard stemAnimalIdFromParent={this.state.stemAnimalId} close={this.closeModal} />
						</SkioldModal>
					</ViewWeb>
				)}
			</ViewWeb>
		);
	}
	private printData = async () => {
		if (this.SkioldTableRef && this.props.profile && this.props.profile.siteId) {
			let data = this.SkioldTableRef.GetSortedData();
			GetTotalFarrowingTableDashboardPdf(
				data,
				'TotalFarrowings.pdf',
				this.props.profile.siteId,
				Intl.DateTimeFormat().resolvedOptions().timeZone,
				this.props.profile.language,
			);
		}
	};
	public SkioldTableRef: SkioldTableRef | undefined;
	private setTableRef = (m: any) => (m ? (this.SkioldTableRef = m) : {});
	private getColumnExtenstions = [
		{
			columnName: 'sowCard',
			width: SowListConstants.iconWidth,
			filteringEnabled: false,
		},
		{
			columnName: 'nursingDays',
			width: SowListConstants.pregnantDaysDashboardWidth,
			usesSummary: true,
		},
		{
			columnName: 'animalNr',
			width: SowListConstants.dateWidth,
		},
		{ columnName: 'breedRace', width: SowListConstants.animalNrWidth },
		{
			columnName: 'litter',
			width: SowListConstants.iDNumberWidth,
		},
		{
			columnName: 'pregnantDays',
			width: SowListConstants.pregnantDaysDashboardWidth,
			usesSummary: true,
		},
		{
			columnName: 'farrowingDate',
			width: SowListConstants.iDNumberWidth,
		},
		{
			columnName: 'aliveBorn',
			width: SowListConstants.iDNumberWidth,
			allowZero: true,
			usesSummary: true,
		},
		{
			columnName: 'stillborn',
			width: SowListConstants.iDNumberWidth,
			allowZero: true,
			usesSummary: true,
		},
	];
	private showSowCard = (stemAnimalId?: string) => {
		if (stemAnimalId) {
			this.setState({ sowCardModal: true, stemAnimalId: stemAnimalId });
		}
	};

	private closeModal = () => {
		this.setState({ stemAnimalId: '', sowCardModal: false });
	};

	private generateColumns = [
		{
			name: 'sowCard',
			title: ' ',
			Footer: localized('avg'),
			sortable: false,
			filterable: false,
			getCellValue: (item: TotalFarrowingTableItem) => (
				<SkioldTouchableOpacity itemFromParent={item.sowId} onPress={this.showSowCard}>
					<SkioldImage
						width={SowListConstants.iconSVGWidth}
						height={SowListConstants.iconSVGWidth}
						imageData={sokort_icon}
					/>
				</SkioldTouchableOpacity>
			),
		},
		{
			name: 'nursingDays',
			title: localized('NursingDays'),
			getCellValue: (item: TotalFarrowingTableItem) => item.nursingDays,
		},
		{
			name: 'animalNr',
			title: localized('animalNr'),
			className: getWebAnimalColorStyleDashboard,
			getCellValue: (item: TotalFarrowingTableItem) => item.animalNumber,
		},
		{
			name: 'breedRace',
			title: localized('Race'),
		},
		{
			name: 'litter',
			title: localized('Litters'),
			getCellValue: (item: TotalFarrowingTableItem) => item.litterNumber,
		},
		{
			name: 'pregnantDays',
			title: localized('PregnancyDays'),
			getCellValue: (item: TotalFarrowingTableItem) => item.pregnancyDays,
		},
		{
			name: 'farrowingDate',
			title: localized('FarrowingDate'),
			sortFunction: NaturalSortDates,
			getCellValue: (item: TotalFarrowingTableItem) => getDateString(item.farrowingDate),
		},
		{
			name: 'aliveBorn',
			title: localized('AliveBorn'),
			getCellValue: (item: TotalFarrowingTableItem) => item.aliveBorn,
		},
		{
			name: 'stillborn',
			title: localized('stillborn'),
			getCellValue: (item: TotalFarrowingTableItem) => item.stillborn,
		},
	];
}

export default connect(mapStateToProps, mapDispatchToProps)(TotalFarrowingsDashboardTable);
