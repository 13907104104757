import { actionCreatorFactory } from 'typescript-fsa';
import * as types from './types';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import { DrugWithAdjustment, IDrug, IDrugWithAdjustment } from 'shared/api/api';

const actionCreator = actionCreatorFactory(GenActionFactoryName('drugs'));

export const saveDrug = actionCreator.async< types.DrugUpdate, string>(types.SAVE_DRUG);
export const deleteDrug = actionCreator.async<DrugWithAdjustment, string>(types.DELETE_DRUG);
export const getDrugs = actionCreator.async<{siteId: string;  activeSiteId?: string}, IDrug[]>(types.GET_DRUGS);
export const getDrugsForMedicineLog = actionCreator.async<{siteId: string;  activeSiteId?: string}, IDrugWithAdjustment[]>(
    types.GET_DRUGS_FOR_MEDICINE_LOG
);

export const removeDrug = actionCreator.async<string, void>(types.REMOVE_DRUG);
