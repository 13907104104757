import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IStemAnimal } from 'shared/api/api';
import { Colors } from 'shared/assets/root-assets/colors';
import { sortBoars } from 'shared/helpers/stemanimal-helper/stemanimal-helper';
import {
	selectActiveBoarsWithIdNumberByState,
	selectAllBoarsByDepartureState,
} from 'shared/state/ducks/stem-animals/selectors';
import { localized } from 'shared/state/i18n/i18n';
import { SharedAppState } from 'shared/state/store.shared';
import { WebAppState } from 'web/state/store.web';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldTextField } from 'web/view/components/skiold-components/skiold-text-field/skiold-text-field';
import { WhiteText } from 'web/view/components/Text/white-text';
import { Heading } from 'web/view/components/utils/heading';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';

interface PropsFromParent {
	boarSelected: (boar: IStemAnimal) => void;
	clear: () => void;
	close: () => void;
	matingDate?: Date;
}
export const SearchBoars: FC<PropsFromParent> = React.memo(props => {
	const boars = useSelector((state: SharedAppState) => selectAllBoarsByDepartureState(state, props.matingDate));
	const { useLettersForBoarNumber } = useSelector((state: WebAppState) => state.generalSettings.entity);
	const [filteredBoars, setFilteredBoars] = useState<IStemAnimal[]>([]);
	const [searchText, setSearchText] = useState<string>('');

	useEffect(() => {
		setFilteredBoars(sortBoars(boars));
	}, [boars]);

	useEffect(() => {
		setFilteredBoars(sortBoars(boars.filter(ya => !searchText || (ya.animalNumber && ya.animalNumber.includes(searchText)))));
	}, [searchText, boars]);

	return (
		<ViewWeb className="search-young-animals">
			<Heading text={localized('boars')}></Heading>
			<ViewWeb className="young-animals-scroll">
				<ViewWeb className="flexDirectionRowSpaceBetween">
					<WhiteText className="searchYoungAnimalText">{localized('searchBoars')}</WhiteText>
					<ViewWeb className="flexDirectionRowSpaceBetween">
						<SkioldButton title={localized('Close')} onPress={props.close} theme="grey" />
						<SkioldButton title={localized('Clear')} onPress={props.clear} theme="grey" />
					</ViewWeb>
				</ViewWeb>

				<SkioldTextField
					onChangeText={setSearchText}
					value={searchText}
					className={'light'}
					theme={'light'}
					label={localized('TypeAnimalNumber')}
					useAsInterger={!useLettersForBoarNumber}
					autoFocus={true}
				/>
				{filteredBoars.map(boar => {
					return (
						<SkioldButton
							className="get-young-animals-button"
							key={boar.id}
							title={`${boar.animalNumber} ${boar.race !== undefined ? ` - ${boar.race}` : ''}`}
							itemFromParent={boar}
							onPress={props.boarSelected}
						/>
					);
				})}

				{filteredBoars.length === 0 && <TextWeb>{localized('youngAnimalNotFound')}</TextWeb>}
			</ViewWeb>
		</ViewWeb>
	);
});
