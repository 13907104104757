import { createReducer } from '@reduxjs/toolkit';
import { BreedTableItem, IBreedTableItem } from 'shared/api/api';
import {
	mergeArrays,
	removeMultipleValuesFromArray,
	upsertMultipleValuesInArray,
} from 'shared/helpers/reducer-helpers';
import { SyncableInitialState } from 'shared/state/models/syncable';
import { SharedAppState } from 'shared/state/store.shared';
import { siteChange } from '../profile/actions';
import { getSyncModelData } from '../sync/actions';
import {
	deleteByDadAndMom,
	getDanBredBreeds,
	getSkioldBreeds,
	getSyncBreedTableItems,
	getUniqueDadBreeds,
	upsertManyBreedTableItems,
} from './operation';
import { BreedTableItemState } from './types';

export const initialState: BreedTableItemState = {
	...SyncableInitialState,
	tableBreeds: [],
};

const breedTableItemsReducer = createReducer(initialState, builder => {
	builder.addCase(getSyncBreedTableItems.fulfilled, (state, action) => {
		if (action.payload?.datas) {
			state.entities = action.payload?.datas;
		}
		if (action && action.payload && action.payload.syncTime) {
			state.lastSyncDate = action.payload.syncTime;
		}
		state.syncInProgress = false;
	});

	builder.addCase(getSyncBreedTableItems.pending, (state, action) => {
		state.syncInProgress = true;
	});

	builder.addCase(getSyncBreedTableItems.rejected, (state, action) => {
		state.syncInProgress = false;
	});
	builder.addCase(deleteByDadAndMom.fulfilled, (state, action) => {
		if (action.payload) {
			state.entities = removeMultipleValuesFromArray(state.entities, action.payload);
		}
	});

	builder.addCase(upsertManyBreedTableItems.fulfilled, (state, action) => {
		if (action.payload) {
			state.entities = upsertMultipleValuesInArray(state.entities, action.payload);
		}
	});

	builder.addCase(getUniqueDadBreeds.fulfilled, (state, action) => {
		if (action.payload) {
			state.tableBreeds = action.payload;
		}
	});

	builder.addCase(getDanBredBreeds.fulfilled, (state, action) => {
		if (action.payload) {
			state.tableBreeds = action.payload;
		}
	});
	builder.addCase(getSkioldBreeds.fulfilled, (state, action) => {
		if (action.payload) {
			state.tableBreeds = action.payload;
		}
	});

	builder.addCase(getSyncModelData.done, (state, action) => {
		if (action.payload.params.siteId === action.payload.params.activeSiteId) {
			if (action.payload.result.breedTableItems && action.payload.result.breedTableItems.datas!.length) {
				state.entities = mergeArrays(state.entities, action.payload.result.breedTableItems.datas!);
				state.lastSyncDate = action.payload.result.breedTableItems.syncTime!;
			}
		}
		state.syncInProgress = false;
	});

	builder.addCase(siteChange.done, (state, action) => {
		state.entities = [];
		state.updates = [];
		state.lastSyncDate = new Date(-8640000000000000);
		state.syncInProgress = false;
		state.saveSyncInProgress = false;
	});

	builder.addCase('persist/REHYDRATE', (state, action) => {
		let a = ((action as any) as { payload: SharedAppState; key: string }) as any;

		if (a.key === 'root') {
			let entities = new Array<BreedTableItem>();
			let updates = new Array<BreedTableItem>();
			let lastSyncDate = new Date(-8640000000000000);

			if (a.payload && a.payload.breedTableItems && a.payload.breedTableItems.entities) {
				entities = a.payload.breedTableItems.entities.map((dt: IBreedTableItem) => {
					let ndt = BreedTableItem.fromJS({});
					ndt.init(dt);
					return ndt;
				});
			}

			if (a.payload && a.payload.breedTableItems && a.payload.breedTableItems.updates) {
				updates = a.payload.breedTableItems.updates.map((dt: IBreedTableItem) => {
					let ndt = BreedTableItem.fromJS({});
					ndt.init(dt);
					return ndt;
				});
			}

			if (a.payload && a.payload.breedTableItems && a.payload.breedTableItems.lastSyncDate) {
				lastSyncDate = new Date(a.payload.breedTableItems.lastSyncDate);
			}
			state.entities = entities;
			state.updates = updates;
			state.lastSyncDate = lastSyncDate;
		}
	});
});

export default breedTableItemsReducer;
