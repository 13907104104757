
import { EsfDailyStatus, SyncEsfDailyStatus } from 'shared/api/api';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import actionCreatorFactory from 'typescript-fsa';
import * as types from './types';

const actionCreator = actionCreatorFactory(GenActionFactoryName('esfDailyStatus'));
export const getSyncEsfDailyStatusData = actionCreator.async<{ siteId: string; activeSiteId?: string}, SyncEsfDailyStatus>(types.GET_EsfDailyStatusData);
export const saveEsfDailyStatus = actionCreator.async< EsfDailyStatus, string>(types.SAVE_EsfDailyStatusData);
export const removeDailyStatus = actionCreator.async<string, string>(types.REMOVE_DAILY_STATUS);
