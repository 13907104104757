
import actionCreatorFactory from 'typescript-fsa';
import * as types from './types';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import { ITreatmentDefinition, SyncDataTreatmentDefinition } from 'shared/api/api';

const actionCreator = actionCreatorFactory(GenActionFactoryName('treatmentDefinitions'));

export const saveTreatmentDefinition = actionCreator.async<
   ITreatmentDefinition,
    string,
    { code: number; message: string; prevEntity: ITreatmentDefinition | undefined }
>(types.SAVE_TREATMENT_DEFINITION);
export const getSyncData = actionCreator.async<{ siteId: string; lastSyncDate: Date; activeSiteId?: string  }, SyncDataTreatmentDefinition>(
    types.SYNC_DATA_TREATMENT_DEFINITION
);
