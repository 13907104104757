import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from "web/state/store.web";
import PageContainer from 'web/view/components/page-container/page-container';
import { Heading } from 'web/view/components/utils/heading';
import { ViewWeb } from 'web/view/components/utils/web-view';
import TreatmentDefinitions from '../treatment-definitions/treatment-definitions';
import './medicine-diagnoses.scss';


const mapStateToProps = (state: WebAppState) => {
    return {};
};
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {};
};

interface State {
    events: Array<{ value?: string; label: string }>;
    selected: string;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
class MedicineDiagnoses extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            selected: 'TreatmentDefinitions',
            events: [
                { label: localized('select'), value: undefined },
                { label: localized('TreatmentDefinitions'), value: 'TreatmentDefinitions' },
                { label: localized('DrugStock'), value: 'DrugStock' }
            ]
        };
    }

    public render() {
        return (
            <PageContainer className='medicine-diagnoses'>
                <Heading text={localized('SOWMEDICIN')} />
                <ViewWeb
                    className='inner-container'
                >
                    <ViewWeb>
                        {/* <SkioldDropdown
                            onValueChanged={(text: string) => {
                                this.setState({ selected: text });
                            }}
                            selectedValue={this.state.selected}
                            items={this.state.events}
                        /> */}
                    </ViewWeb>

                    {this.state.selected === 'TreatmentDefinitions' && <TreatmentDefinitions /> }
                    {/* {this.state.selected === 'DrugStock' && <ViewWeb /> : <ViewWeb />} */}
                </ViewWeb>
            </PageContainer>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)((MedicineDiagnoses));
