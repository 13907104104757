import React from 'react';
import { Option } from 'react-dropdown';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Equipment, IProcessEquipment, ISite, ProcessEquipment, ProcessUnit } from 'shared/api/api';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { ValidateSite } from 'shared/helpers/site-helper';
import {
	GetSyncData as ProcessEquipmentGetSyncData,
	SaveProcessEquipment,
} from 'shared/state/ducks/process-equipments/operations';
import { SaveSiteAsync } from 'shared/state/ducks/site/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import PageContainer from '../page-container/page-container';
import { showAlert } from '../skiold-alert/skiold-alert';
import { SkioldButton } from '../skiold-components/skiold-button/skiold-button';
import { SkioldFormDropdown } from '../skiold-components/skiold-dropdown/skiold-form-dropdown';
import { SkioldFormTextField } from '../skiold-components/skiold-forms-wrapper/skiold-form-text';
import { SkioldFormsWrapper } from '../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from '../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { SkioldFormInput } from '../skiold-components/skiold-input/skiold-form-input';
import { Heading } from '../utils/heading';
import { ViewWeb } from '../utils/web-view';
import './create-process-equipment.scss';
import { GetUnitTypes } from './LocationRelation-helper';

const mapStateToProps = (state: WebAppState) => {
	return {
		siteId: state.profile.active ? state.profile.active.siteId : undefined,
		processEquipments: state.processEquipments.entities,
		site: state.site.site,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	saveProcessEquipment: (processEquipment: IProcessEquipment, onSucess: () => void, errorAlert: () => void) =>
		SaveProcessEquipment(processEquipment, onSucess, errorAlert)(dispatch),
	getSyncData: () => ProcessEquipmentGetSyncData()(dispatch),
	saveSite: (site: ISite, onSucess: () => void, errorAlert: () => void) =>
		SaveSiteAsync(site, onSucess, errorAlert)(dispatch),
});

//This is the code that is set default in distriwin and esf - and we wont accept that because of potential clashes between farms
const defaultCode = '208000000000001';

interface PropsFromParent {
	closeModal?: () => void;
	processEquipmentId?: string;
}

interface ProcessEquipmentState {
	processEquipmentTypes: Option[];
	selectedProcessEquipment: Option;
	unitTypes: Option[];
	selectedUnit: Option;
	processEquipment: IProcessEquipment;
}

type ProcessEquipmentProps = ReturnType<typeof mapStateToProps> &
	ReturnType<typeof mapDispatchToProps> &
	PropsFromParent;
export class CreateProcessEquipment extends React.PureComponent<ProcessEquipmentProps, ProcessEquipmentState> {
	constructor(props: ProcessEquipmentProps) {
		super(props);

		const pe = props.processEquipments.find(pe => pe.id === props.processEquipmentId)!;
		const equipmentTypes = this.getProcessEquipmentTypes();
		const units = GetUnitTypes(pe ? pe.equipment : undefined);

		this.state = {
			processEquipmentTypes: equipmentTypes,
			selectedProcessEquipment: pe
				? equipmentTypes.find(type => type.value === pe.equipment)!
				: { value: '', label: '' },
			processEquipment: pe ? pe : ProcessEquipment.fromJS({ siteId: props.siteId } as IProcessEquipment),
			unitTypes: units,
			selectedUnit: pe ? units.find(type => type.value === pe.unit)! : { value: '', label: '' },
		};
	}

	public render() {
		return (
			<PageContainer>
				<Heading text={localized('createProcessEquipment')} />
				<ViewWeb className="create-process-equipment">
					<ViewWeb>
						<SkioldFormsWrapper formRows={this.getFormRows()} containerClassName="wrapper-container" />
					</ViewWeb>

					{this.renderButtons()}
				</ViewWeb>
			</PageContainer>
		);
	}

	private getFormRows() {
		let formRows = new Array<FormRow>();
		formRows.push(this.getNameRow());
		formRows.push(this.getEquipmentRow());
		formRows.push(this.getUnitsRow());
		formRows.push(this.getCodeRow());
		return formRows;
	}

	private getNameRow(): FormRow {
		return {
			name: localized('name'),
			component: <SkioldFormInput onChangeText={this.nameChange} text={this.state.processEquipment.name} />,
		};
	}

	private getEquipmentRow(): FormRow {
		return {
			name: localized('processEquipment'),
			component: this.props.processEquipmentId ? (
				<SkioldFormTextField>{this.state.selectedProcessEquipment.value}</SkioldFormTextField>
			) : (
				<SkioldFormDropdown
					items={this.state.processEquipmentTypes}
					selectedValue={this.state.selectedProcessEquipment}
					onValueChanged={this.equipmentChanged}
				/>
			),
		};
	}

	private getUnitsRow(): FormRow {
		return {
			name: localized('units'),
			component: (
				<SkioldFormDropdown
					items={this.state.unitTypes}
					selectedValue={this.state.selectedUnit}
					onValueChanged={this.unitChanged}
				/>
			),
		};
	}

	private getCodeRow(): FormRow {
		return {
			name: localized('processCode'),
			component: this.props.processEquipmentId ? (
				<SkioldFormTextField>{this.state.processEquipment.code}</SkioldFormTextField>
			) : (
				<SkioldFormInput onChangeText={this.codeChanged} text={this.state.processEquipment.code!} />
			),
		};
	}

	private codeChanged = (newCode: string) => {
		let value = newCode.replace(/[^0-9]/g, '');
		this.setState(prevState => ({
			processEquipment: { ...prevState.processEquipment, code: value.trim() },
		}));
	};

	private nameChange = (newName: string) => {
		this.setState(prevState => ({
			processEquipment: { ...prevState.processEquipment, name: newName },
		}));
	};

	private equipmentChanged = (equipmentOption: Option) => {
		const equipment = equipmentOption.value as Equipment;
		this.setState(prevState => ({
			processEquipment: { ...prevState.processEquipment, equipment },
			selectedProcessEquipment: equipmentOption,
			unitTypes: GetUnitTypes(equipment),
			selectedUnit: { value: '', label: '' },
		}));
	};

	private unitChanged = (unitOption: Option) => {
		this.setState(prevState => ({
			processEquipment: { ...prevState.processEquipment, unit: unitOption.value as ProcessUnit },
			selectedUnit: unitOption,
		}));
	};

	private renderButtons() {
		return (
			<ViewWeb className="buttons-container">
				{this.props.closeModal && (
					<SkioldButton title={localized('Close')} onPress={this.closeModal} theme="grey" />
				)}
				<SkioldButton title={localized('Save')} onPress={this.save} />
			</ViewWeb>
		);
	}

	private save = async () => {
		if (!this.validate()) {
			return;
		}

		if (this.state.processEquipment.code) {
			await this.props.saveProcessEquipment(
				this.state.processEquipment,
				() => {
					this.closeModal();
				},
				() => {
					showAlert(localized('peqCodeError'));
				},
			);
		} else {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_INVALID_CODE_SET));
		}
	};

	private validate = () => {
		if (!ValidateSite(this.props.site, showAlert)) {
			return false;
		}
		if (this.state.processEquipment.code === defaultCode) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_DEFAULT_CODE_SET));
			return false;
		}
		if (!this.state.processEquipment.code) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_NO_CODE_SET));
			return false;
		}
		if (!this.state.processEquipment.equipment) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_NO_PROCESS_EQUIPMENT_SET));
			return false;
		}
		if (!this.state.processEquipment.unit) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_NO_UNITTYPE_SET));
			return false;
		}
		if (
			this.props.processEquipments.find(
				peq => peq.id !== this.state.processEquipment.id && peq.code === this.state.processEquipment.code,
			)
		) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_CODE_ALREADY_USED));
			return false;
		}
		return true;
	};

	private closeModal = () => {
		if (this.props.closeModal) {
			this.props.closeModal();
		}
	};

	private getProcessEquipmentTypes() {
		const types = Object.keys(Equipment).map(k => {
			const type = Equipment[k as any];
			return { value: type, label: type };
		});
		if (this.props.processEquipmentId) {
			return types as Option[];
		}

		return types as Option[];
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateProcessEquipment);
