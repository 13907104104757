import * as React from "react";
import { ClipLoader } from 'react-spinners';
import { Colors } from 'shared/assets/root-assets/colors';
import './spinner.scss';

export class Spinner extends React.PureComponent<{}, {}> {
  public render() {
    return (
        <div className="spinner-container">
            <ClipLoader color={Colors.yellow} size={100} />
        </div>
    )
  }
}
