import React from 'react';
import { AnimalType, IMatingBatch, IProductionReportSetting } from 'shared/api/api';
import { getFirstMatingBatch } from 'shared/helpers/general-helpers';
import { localized } from 'shared/state/i18n/i18n';
import { SkioldFormDatePicker } from 'web/view/components/skiold-components/skiold-date-picker/skiold-form-datepicker';
import SkioldTableGrid from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import './p-report-period-table.scss';

interface PropsFromParent {
	pReportSetting: IProductionReportSetting;
	onDataChanged: (date: Date, item: PeriodTableItem) => void;
}

interface State {
	columns: any[];
	pReportSetting: IProductionReportSetting;
	earliestBatch: IMatingBatch | undefined;
	columnExt: any[];
}

export class PReportPeriodTable extends React.PureComponent<PropsFromParent, State> {
	public static defaultProps: Partial<PropsFromParent> = {};

	constructor(props: PropsFromParent) {
		super(props);
		this.state = {
			columns: this.generateColumns(),
			pReportSetting: props.pReportSetting,
			earliestBatch: getFirstMatingBatch(),
			columnExt: this.generateColumnExt()
		};
	}

	public render() {
		const data = this.generateData(this.props.pReportSetting);
		return (
			<ViewWeb className="p-report-period-table">
				<SkioldTableGrid
					ColumnExtensions={this.state.columnExt}
					columns={this.state.columns}
					data={data}
					sortable={false}
					filterable={false}
				/>
			</ViewWeb>
		);
	}

	// generate date with from dates in first row, and to dates in second
	private generateData = (pReportSetting: IProductionReportSetting) => {
		const dataItems: PeriodTableRow[] = [];
		let fromItems: PeriodTableItem[] = [];
		let toItems: PeriodTableItem[] = [];
		if (pReportSetting && pReportSetting.periods) {
			const periods = pReportSetting.periods.map(period => {
				const from = new PeriodTableItem(localized('FromDate'), period.fromDate, period.periodNumber, true);
				const to = new PeriodTableItem(localized('ToDate'), period.toDate, period.periodNumber, false);
				return { from, to };
			});
			fromItems = periods.map(period => period.from);
			toItems = periods.map(period => period.to);
		}

		while (fromItems.length < 4) {
			fromItems.push(new PeriodTableItem(localized('FromDate'), undefined, fromItems.length + 1, true));
			toItems.push(new PeriodTableItem(localized('ToDate'), undefined, toItems.length + 1, false));
		}
		fromItems.sort((a, b) => (a.periodNumber > b.periodNumber ? 1 : -1));
		toItems.sort((a, b) => (a.periodNumber > b.periodNumber ? 1 : -1));
		dataItems.push(new PeriodTableRow(fromItems));
		dataItems.push(new PeriodTableRow(toItems));

		return dataItems;
	};

	private generateColumnExt() {
		const columnExt: any = [
			{
				name: 'period1',
				width: 192.5
			},
			{
				name: 'period2',
				width: 192.5
			},
			{
				name: 'period3',
				width: 192.5
			},
			{
				name: 'period4',
				width: 192.5
			}
		];

		return columnExt;
	}

	private generateColumns() {
		const columns: any = [
			{
				name: 'period1', //sow team
				title: `${localized('period')} 1`,
				sortable: false,
				filterable: false,
				getCellValue: (period: PeriodTableRow) => this.generateDatePicker(period.items![0])
			},
			{
				name: 'period2', //sow team
				title: `${localized('period')} 2`,
				sortable: false,
				filterable: false,
				getCellValue: (period: PeriodTableRow) => this.generateDatePicker(period.items![1])
			},
			{
				name: 'period3', //sow team
				title: `${localized('period')} 3`,
				sortable: false,
				filterable: false,
				getCellValue: (period: PeriodTableRow) => this.generateDatePicker(period.items![2])
			},
			{
				name: 'period4', //sow team
				title: `${localized('period')} 4`,
				sortable: false,
				filterable: false,
				getCellValue: (period: PeriodTableRow) => this.generateDatePicker(period.items![3])
			}
		];

		return columns;
	}

	private generateDatePicker = (item: PeriodTableItem) => {
		return (
			<ViewWeb className="period-item-container">
				<TextWeb>{item.name}</TextWeb>
				<SkioldFormDatePicker
					onDateChanged={this.props.onDataChanged}
					selectedDate={item.date}
					itemFromParent={item}
					containerClassName="marginRightTen"
					includeTimeStamp={false}
					disabled={this.disableDatePicker(item)}
					theme={'dark'}
					color={'grey'}
					maxDate={new Date(8640000000000000)}
					minDate={this.setMinimumDate(item)}
					isEndDate={item.isFromDate ? false : true}
					showIcon={item.periodNumber === 1}
				/>
			</ViewWeb>
		);
	};

	// set min date from to date
	private setMinimumDate = (item: PeriodTableItem) => {
		if(this.props.pReportSetting.animaltype === AnimalType.Sow){
			let minimumDate = new Date(
				this.state.earliestBatch ? this.state.earliestBatch.matingPeriodStart! : -8640000000000000
			);
			if (this.props.pReportSetting.periods && !item.isFromDate && this.props.pReportSetting.periods[0].fromDate) {
				minimumDate = new Date(this.props.pReportSetting.periods[0].fromDate);
			}
			return minimumDate;
		}else{
			return new Date(-8640000000000000)
		}
		
	};

	// disable picker if not first period
	private disableDatePicker(item: PeriodTableItem) {
		if (this.props.pReportSetting.animaltype === AnimalType.Sow && !this.state.earliestBatch) {
			return true;
		}

		if (item.periodNumber !== 1) {
			return true;
		}
		if (!this.props.pReportSetting.periods) {
			return true;
		}

		if (item.isFromDate && this.props.pReportSetting.periods && this.props.pReportSetting.periods[1].fromDate) {
			return true;
		}
		return false;
	}
}

export class PeriodTableRow {
	public items: PeriodTableItem[] | undefined;
	constructor(items) {
		this.items = items;
	}
}

export class PeriodTableItem {
	public name: string;
	public date: Date;
	public periodNumber: number;
	public isFromDate: boolean;

	constructor(name, date, periodNumber, isFromDate) {
		this.name = name;
		this.date = date;
		this.periodNumber = periodNumber;
		this.isFromDate = isFromDate;
	}
}
