import ObjectID from 'bson-objectid';
import React from 'react';
import { Option } from 'react-dropdown';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ISite, IUserProfile, Site } from 'shared/api/api';
import { deepCopy } from 'shared/helpers/general-helpers';
import { UpdateProfile } from 'shared/state/ducks/profile/operations';
import { SaveSiteAsync } from 'shared/state/ducks/site/operations';
import { localized, localizedDynamic } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import PageContainer from 'web/view/components/page-container/page-container';
import { showAlert } from 'web/view/components/skiold-alert/skiold-alert';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldFormDropdown } from 'web/view/components/skiold-components/skiold-dropdown/skiold-form-dropdown';
import { SkioldFormsWrapper } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { SkioldFormInput } from 'web/view/components/skiold-components/skiold-input/skiold-form-input';
import { SkioldFormIntegerInput } from 'web/view/components/skiold-components/skiold-integer-input/skiold-form-integer-input';
import { ViewWeb } from 'web/view/components/utils/web-view';
import './edit-site.scss';
import { ValidateSite } from 'shared/helpers/site-helper';

interface PropsFromParent {
	close: () => void;
	site: ISite;
	isOpen: boolean;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		countries: state.countries.countries,
		profile: state.profile.active!,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	saveSite: (site: ISite, onSucess: () => void, errorAlert: () => void) =>
		SaveSiteAsync(site, onSucess, errorAlert)(dispatch),
	udpateProfile: (profile: IUserProfile) => UpdateProfile(profile)(dispatch),
});

interface EditFarmState {
	site: ISite;
	selectedCountry: Option;
	contries: Option[];
}

type EditSiteProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;
class EditSite extends React.PureComponent<EditSiteProps, EditFarmState> {
	constructor(props: EditSiteProps) {
		super(props);
		this.state = {
			site: props.site || Site.fromJS({}),
			selectedCountry:
				props.site && props.site.countryId
					? {
							value: props.site.countryId,
							label: localizedDynamic(this.getCountry(props.site.countryId)),
					  }
					: { label: localized('chooseCountry'), value: '' },
			contries: [{ label: localized('chooseCountry'), value: '' }].concat(
				this.props.countries.map(country => {
					return {
						label: country.name ? localizedDynamic(country.name) : '',
						value: country.id ? country.id : '',
					};
				}),
			),
		};
	}

	public countryChanged = (country: Option) => {
		this.setState({ selectedCountry: country });
	};

	public render() {
		return (
			<PageContainer>
				<ViewWeb className="edit-site">
					<ViewWeb className="forms-container">
						<ViewWeb className="z-index-container">
							<SkioldFormsWrapper formRows={this.getFormRows()} containerClassName="wrapper-container" />
						</ViewWeb>

						{this.renderButtons()}
					</ViewWeb>
				</ViewWeb>
			</PageContainer>
		);
	}

	private save() {
		const siteToEdit = deepCopy(this.state.site);
		siteToEdit.countryId = this.state.selectedCountry.value;

		if (!ValidateSite(siteToEdit, showAlert)) {
			return;
		}

		if (!siteToEdit.id) {
			siteToEdit.id = new ObjectID().toHexString();
			const profileToEdit = deepCopy(this.props.profile);
			profileToEdit.siteId = siteToEdit.id;
			this.props.udpateProfile(profileToEdit);
		}

		this.props.saveSite(
			siteToEdit,
			() => {
				this.props.close();
			},
			() => {
				showAlert(localized('siteChrError'));
			},
		);
	}

	private getFormRows() {
		let formRows = new Array<FormRow>();
		formRows.push(this.getOwnerHeader());
		formRows.push(this.GenerateFormInputRow('ownerName'));
		formRows.push(this.GenerateFormInputRow('ownerAddress'));
		formRows.push(this.getIntergerInputRow('ownerZipCode'));
		formRows.push(this.GenerateFormInputRow('ownerCity'));
		formRows.push(this.getIntergerInputRow('cvRnum'));

		formRows.push(this.getSiteHeader());
		formRows.push(this.getIntergerInputRow('chRnum'));
		formRows.push(this.GenerateFormInputRow('siteAddress'));
		formRows.push(this.getIntergerInputRow('siteZipCode'));
		formRows.push(this.GenerateFormInputRow('siteCity'));
		formRows.push(this.GenerateFormInputRow('siteName'));
		formRows.push(this.getSiteCountryRow());
		return formRows;
	}
	private getIntergerInputRow = (propertyName: keyof ISite) => {
		return {
			name: localized(propertyName),
			component: (
				<SkioldFormIntegerInput
					itemFromParent={propertyName}
					onChangeNumber={this.OnPropertyChanged}
					text={this.state.site[propertyName] as number}
				/>
			),
		};
	};

	private GenerateFormInputRow = (propertyName: keyof ISite) => {
		return {
			name: localizedDynamic(propertyName),
			component: (
				<SkioldFormInput
					itemFromParent={propertyName}
					onChangeText={this.OnPropertyChanged}
					text={this.state.site[propertyName] ? (this.state.site[propertyName] as string) : ''}
				/>
			),
		};
	};

	private OnPropertyChanged = (newText, ItemFromParent: string) => {
		const site = { ...this.state.site };
		site[ItemFromParent] = newText;
		this.setState({ site: site });
	};

	private getOwnerHeader(): FormRow {
		return {
			header: localized('Owner'),
		};
	}

	private getSiteHeader(): FormRow {
		return {
			header: localized('SITEANDFARM'),
		};
	}

	private getSiteCountryRow(): FormRow {
		return {
			name: localized('country'),
			component: (
				<SkioldFormDropdown
					items={this.state.contries}
					selectedValue={this.state.selectedCountry}
					onValueChanged={this.countryChanged}
				/>
			),
		};
	}

	private getCountry(countryId: string) {
		const country = this.props.countries.find(x => x.id === countryId);
		if (!country) {
			return '';
		}
		return country!.name!;
	}

	private renderButtons() {
		let buttons = (
			<ViewWeb className="button-container">
				{this.props.close ? (
					<SkioldButton title={localized('Close')} onPress={() => this.props.close()} theme="grey" />
				) : (
					<ViewWeb />
				)}

				<SkioldButton title={localized('Save')} onPress={() => this.save()} />
			</ViewWeb>
		);

		return buttons;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(EditSite);
