import React from 'react';
import { Option } from 'react-dropdown';
import { IGrowthPigEntranceEventDto } from 'shared/api/api';
import { getDateString } from 'shared/helpers/date-helpers';
import { GrowthPigDistributeTablePropsFromParent } from 'shared/helpers/growth-pigs-helper/growth-pig-event-helper';
import { localized } from 'shared/state/i18n/i18n';
import { SkioldFormDecimalInput } from '../../skiold-components/skiold-decimal-input/skiold-form-decimal-input';
import { SkioldFormDropdown } from '../../skiold-components/skiold-dropdown/skiold-form-dropdown';
import SkioldTableGrid from '../../skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import './growth-pig-distribute.scss';
import { setOneDecimalsAsNumber } from 'shared/helpers/general-helpers';

export class GrowthPigDistributeTable extends React.PureComponent<GrowthPigDistributeTablePropsFromParent> {
	public render() {
		return (
			<SkioldTableGrid
				ignoreSetCount={true}
				filterable={false}
				sortable={false}
				ColumnExtensions={this.generateColumnExtes()}
				columns={this.generateColumns()}
				data={this.props.data}
				containerClassName={'growth-distribution-table-max-height'}
			/>
		);
	}

	private getPenNameCell = (item: IGrowthPigEntranceEventDto) => item.name;

	private getAmountCell = (item: IGrowthPigEntranceEventDto) => (
		<SkioldFormDecimalInput
			onBlur={this.props.onBlur}
			text={item.amount ? item.amount : undefined}
			itemFromParent={item}
			onChangeNumber={this.onAmounthanged}
		/>
	);

	private onAmounthanged = (number?: number | undefined, itemFromParent?: IGrowthPigEntranceEventDto) => {
		if (itemFromParent) {
			let copy = { ...itemFromParent };
			copy.amount = number!;
			copy.totalWeight = number && copy.avgWeight ? number * copy.avgWeight : copy.totalWeight;
			this.props.onAmounthanged(number, copy);
		}
	};

	private getWeightCell = (item: IGrowthPigEntranceEventDto) => (
		<SkioldFormDecimalInput
			onBlur={this.props.onBlur}
			// text={item.amount ? item.avgWeight : undefined}
			text={
				item.avgWeight === undefined || item.avgWeight <= 0.0
					? undefined
					: setOneDecimalsAsNumber(item.avgWeight)
			}
			itemFromParent={item}
			onChangeNumber={this.onWeightChanged}
		/>
	);

	private getTotalWeightCell = (item: IGrowthPigEntranceEventDto) => (
		<SkioldFormDecimalInput
			onBlur={this.props.onBlur}
			// text={item.amount ? item.totalWeight : undefined}
			text={item.totalWeight ? setOneDecimalsAsNumber(item.totalWeight) : undefined}
			itemFromParent={item}
			onChangeNumber={this.onTotalWeightChanged}
		/>
	);
	private onWeightChanged = (number?: number | undefined, itemFromParent?: IGrowthPigEntranceEventDto) => {
		if (itemFromParent) {
			let copy = { ...itemFromParent };
			copy.avgWeight = number!;
			copy.totalWeight = number && copy.amount ? number * copy.amount : copy.totalWeight;
			this.props.onWeightChanged(number, copy);
		}
	};

	private onTotalWeightChanged = (number?: number | undefined, itemFromParent?: IGrowthPigEntranceEventDto) => {
		if (itemFromParent) {
			let copy = { ...itemFromParent };
			copy.totalWeight = number!;
			copy.avgWeight = number && copy.amount ? number / copy.amount : copy.totalWeight;
			this.props.onWeightChanged(number, copy);
		}
	};

	private getRetentionDateCell = (item: IGrowthPigEntranceEventDto) => {
		const onLatestChange = (option: Option | undefined) => {
			if (option && this.props.onLatestRetentionDateChange) {
				this.props.onLatestRetentionDateChange(new Date(option.value), item);
			}
		};

		return (
			<SkioldFormDropdown
				usedInTable={true}
				items={
					[
						{
							label: getDateString(this.props.latestRetentionDate),
							value: this.props.latestRetentionDate,
						},
					] as any[]
				}
				selectedValue={{ label: getDateString(item.retentionDate), value: item.retentionDate } as any}
				onValueChanged={onLatestChange}
				itemFromParent={item}
			/>
		);
	};

	private generateColumns = () => {
		const columns = [
			{
				name: 'amount',
				sortable: false,
				filterable: false,
				title: localized('Count'),
				getCellValue: this.getAmountCell,
			},
			{
				name: 'weight',
				sortable: false,
				filterable: false,
				title: localized('avgWeightSlashPig'),
				getCellValue: this.getWeightCell,
			},
			{
				name: 'totalWeight',
				sortable: false,
				filterable: false,
				title: localized('totalWeight'),
				getCellValue: this.getTotalWeightCell,
			},
		] as any[];
		if (!this.props.disablePenColumn) {
			columns.unshift({
				name: 'pen',
				title: localized('PenNumber'),
				getCellValue: this.getPenNameCell,
			});
		}
		if (this.props.latestRetentionDate && this.props.handleRetentionTime) {
			columns.push({
				name: 'retentionDate',
				sortable: false,
				filterable: false,
				title: localized('slaughterRetention'),
				getCellValue: this.getRetentionDateCell,
			});
		}
		return columns;
	};

	private generateColumnExtes = () => {
		const columnsExte = [
			{
				columnName: 'amount',
				width: 230,
			},
			{
				columnName: 'weight',
				filteringEnabled: false,
				width: 230,
			},
			{
				columnName: 'totalWeight',
				filteringEnabled: false,
				width: 230,
			},
		];

		if (this.props.latestRetentionDate && this.props.handleRetentionTime) {
			columnsExte.push({
				columnName: 'retentionDate',
				filteringEnabled: false,
				width: 230,
			});
		}
		if (!this.props.disablePenColumn) {
			columnsExte.unshift({
				columnName: 'pen',
				width: 230,
			});
		}
		return columnsExte;
	};
}
