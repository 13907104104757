import { IStemAnimal } from 'shared/api/api';
import {
	memoizeActiveBoars,
	memoizeActiveBoarsWithIdNumber,
	memoizeActiveBoarsWithIdNumberOption,
	memoizeAllBoars,
	memoizeFamilyTree,
	memoizeStemAnimal,
} from 'shared/helpers/memoize-getters/memoize-getters';
import { SharedAppState } from 'shared/state/store.shared';
import { Option } from 'react-dropdown';
export const selectActiveBoars = (state: SharedAppState) =>
	memoizeActiveBoars(state.stemAnimals.entities) as IStemAnimal[];
export const selectActiveBoarsWithIdNumberByState = (state: SharedAppState) =>
	memoizeActiveBoarsWithIdNumber(state.stemAnimals.entities) as IStemAnimal[];

export const selectActiveBoarsWithIdNumberByAnimals = (stemAnimals: IStemAnimal[]) =>
	memoizeActiveBoarsWithIdNumber(stemAnimals) as IStemAnimal[];

export const selectActiveBoarsWithIdNumberOptions = (stemAnimals: IStemAnimal[]) =>
	memoizeActiveBoarsWithIdNumberOption(stemAnimals) as Option[];

export const selectAllBoarsByDepartureState = (state: SharedAppState, matingDate?: Date) =>
	memoizeAllBoars(
		state.stemAnimals.entities,
		state.stemAnimals.departuredAnimals as IStemAnimal[],
		state.generalSettings.entity.maxDaysAfterBoarDepartureSemen,
		matingDate,
	) as IStemAnimal[];

export const selectStemAnimalById = (
	stemAnimals: IStemAnimal[],
	departed: IStemAnimal[],
	stemAnimalId: string | undefined,
) => memoizeStemAnimal(stemAnimals, departed, stemAnimalId);

export const selectFamilyTreeById = (stemAnimals: IStemAnimal[], departed: IStemAnimal[], stemAnimalId: string) =>
	memoizeFamilyTree(stemAnimals, departed, stemAnimalId);


