import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import { StoreContainer } from 'shared/state/store-container';
import * as Action from './actions';

export function GetUnits() {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;

	return AsyncOperationBuilder2(Action.getUnits, client => client.valves_GetbySiteId(siteId), {
		siteId,
	});
}

export function RemoveProcessEquipment(id: string) {
	return AsyncOperationBuilder2(Action.removeProcessEquipment, client => client.processEquipment_Delete(id), id);
}
