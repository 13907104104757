import { default as ObjectID } from 'bson-objectid';
import { Dispatch } from 'redux';
import { IValidationSetup, ValidationSetup } from 'shared/api/api';
import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import { StoreContainer } from 'shared/state/store-container';
import * as Action from './actions';

export function SaveValidationSetup(validation: IValidationSetup) {
	if (!validation.id) {
		validation.id = new ObjectID().toHexString();
	}
	let setup = ValidationSetup.fromJS(validation);
	return AsyncOperationBuilder2(Action.saveValidationSetup, client => client.validationSetup_Upsert(setup), setup);
}

export function GetSyncData() {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;
	const lastSyncDate = state.validationSetup.lastSyncDate;

	if (state.validationSetup.syncInProgress) {
		return (dispatch: Dispatch<any>) => {
			return Promise.resolve();
		};
	}
	return AsyncOperationBuilder2(Action.getSyncData, client => client.validationSetup_Sync(siteId, lastSyncDate), {
		siteId,
		lastSyncDate,
	});
}
