import { Action } from 'redux';
import {
	GrowthPigAdjustFeedUpdate,
	GrowthPigMoveEventDto,
	IValveGroup,
	IValveGroupUpdate,
	ValveGroup,
	ValveGroupUpdate,
} from 'shared/api/api';
import { deepCopy } from 'shared/helpers/general-helpers';
import { mergeNoUnique, removeValueFromArray, upsertValueInArray } from 'shared/helpers/reducer-helpers';
import { isType } from 'typescript-fsa';
import { siteChange } from '../profile/actions';
import { getSyncModelData } from '../sync/actions';
import {
	fullSyncFailed,
	getSyncData,
	setFullSyncNumbers,
	setFullSyncSpinner,
	updateValveGroup,
	updateValveGroupAPI,
} from './actions';
import { AdjustFeedState, LocationWithType } from './types';
import {
	upsertDepartureEvent,
	upsertDepartureFromPenEvent,
	upsertEntranceEvent,
	upsertGrowthPigsPigletMoveEventsAndUpdateEvents,
	upsertMoveEvent,
} from '../growth-pig-events/actions';
import moment from 'moment';

export const initialAdjustFeedState: AdjustFeedState = {
	locationStrings: [],
	valveGroups: [],
	updates: [],
	lastSync: 0,
	isLoading: false,
	errorMessage: undefined,
	failed: false,
	lastSyncDate: new Date(-8640000000000000),
	syncInProgress: false,
	saveSyncInProgress: false,
	previousEntities: [],
	showFullSyncSpinner: false,
	fullSyncNumbers: { fullSyncCurrent: 0, fullSyncTotal: 0 },
	fullSyncFailed: false,
	growthPigAdjustFeedUpdate: [],
};

const adjustFeedReducer = (state: any = initialAdjustFeedState, action: Action): AdjustFeedState => {
	if (isType(action, getSyncData.started)) {
		state = { ...state, syncInProgress: true, isLoading: true, failed: false, errorMessage: undefined };
	}

	if (isType(action, getSyncModelData.done)) {
		if (
			action.payload.result.valveGroups &&
			action.payload.result.valveGroups.datas &&
			action.payload.params.siteId === action.payload.params.activeSiteId
		) {
			if (action.payload.result.valveGroups.datas!.length) {
				const mergedValveGroups = action.payload.result.valveGroups.datas;
				const locationStrings: LocationWithType[] = [];
				mergedValveGroups.forEach(vg => {
					if (
						vg.locationString &&
						locationStrings.findIndex(l => l.locationString === vg.locationString) < 0
					) {
						locationStrings.push({
							locationString: vg.locationString,
							animalType: vg.animalType,
							order: vg.order,
						});
					}
				});
				state = {
					...state,
					valveGroups: mergedValveGroups,
					locationStrings,
				};
			}
			state = {
				...state,
				lastSyncDate: action.payload.result.valveGroups.syncTime!,
				syncInProgress: false,
				lastSync: Date.now(),
				isLoading: false,
			};
		} else {
			state = {
				...state,
				syncInProgress: false,
				isLoading: false,
			};
		}
	}

	if (isType(action, getSyncData.done)) {
		if (action.payload.result.datas && action.payload.params.siteId === action.payload.params.activeSiteId) {
			if (action.payload.result.datas!.length) {
				const mergedValveGroups = action.payload.result.datas;
				const locationStrings: LocationWithType[] = [];
				mergedValveGroups.forEach(vg => {
					if (
						vg.locationString &&
						locationStrings.findIndex(l => l.locationString === vg.locationString) < 0
					) {
						locationStrings.push({
							locationString: vg.locationString,
							animalType: vg.animalType,
							order: vg.order,
						});
					}
				});
				state = {
					...state,
					valveGroups: mergedValveGroups,
					locationStrings,
				};
			}
			state = {
				...state,
				lastSyncDate: action.payload.result.syncTime!,
				syncInProgress: false,
				lastSync: Date.now(),
				isLoading: false,
			};
		} else {
			state = {
				...state,
				syncInProgress: false,
				isLoading: false,
			};
		}
	}

	if (isType(action, getSyncData.failed)) {
		state = { ...state, syncInProgress: false, isLoading: false };
	}

	if (isType(action, updateValveGroupAPI.started)) {
		let valveGroupUpdate = action.payload;
		const vgIndex = state.valveGroups.findIndex((x: any) => x.id === valveGroupUpdate.id);
		const valveGroups = [...state.valveGroups];
		const prevEntity = { ...valveGroups[vgIndex] };
		const newEntity = { ...valveGroups[vgIndex] };

		newEntity.constantDeviation = getSetValue(
			valveGroupUpdate.constantDeviation,
			valveGroups[vgIndex].constantDeviation,
		);
		newEntity.deviation = getSetValue(valveGroupUpdate.deviation, valveGroups[vgIndex].deviation);
		newEntity.deviationDays = getSetValue(valveGroupUpdate.deviationDays, valveGroups[vgIndex].deviationDays);
		newEntity.feedingCurve = getSetValue(valveGroupUpdate.feedingCurve, valveGroups[vgIndex].feedingCurve);
		newEntity.sowCycle = getSetValue(valveGroupUpdate.sowCycle, valveGroups[vgIndex].sowCycle);
		newEntity.pigCount = getSetValue(valveGroupUpdate.pigCount, valveGroups[vgIndex].pigCount);
		newEntity.pigletCount = getSetValue(valveGroupUpdate.pigletCount, valveGroups[vgIndex].pigletCount);
		newEntity.weight = getSetValue(valveGroupUpdate.weight, valveGroups[vgIndex].weight);
		newEntity.penDays = getSetValue(valveGroupUpdate.penDays, valveGroups[vgIndex].penDays);
		valveGroups[vgIndex] = newEntity;

		const oldUpdate = state.updates.find((x: any) => x.id === valveGroupUpdate.id);
		valveGroupUpdate = mergeValveGroupUpdates(valveGroupUpdate, oldUpdate);

		const uIndex = state.updates.findIndex((x: any) => x.id === valveGroupUpdate.id);
		let updates: IValveGroupUpdate[];
		if (uIndex < 0) {
			updates = state.updates.concat([valveGroupUpdate]);
		} else {
			updates = [...state.updates];
			updates[uIndex] = valveGroupUpdate;
		}

		state = {
			...state,
			updates,
			valveGroups,
			previousEntities: upsertValueInArray(state.previousEntities, prevEntity),
			saveSyncInProgress: true,
		};
	}

	if (isType(action, updateValveGroupAPI.failed)) {
		if (action.payload.error.code === 500) {
			if (action.payload.error.prevEntity) {
				state = {
					...state,
					valveGroups: upsertValueInArray(state.valveGroups, action.payload.error.prevEntity),
				};
			} else {
				state = { ...state, valveGroups: removeValueFromArray(state.valveGroups, action.payload.params.id!) };
			}
			state = { ...state, updates: removeValueFromArray(state.updates, action.payload.params.id!) };
			state = {
				...state,
				previousEntities: removeValueFromArray(state.previousEntities, action.payload.params.id!),
			};
		}
		state = { ...state, saveSyncInProgress: false };
	}

	if (isType(action, updateValveGroupAPI.done)) {
		state = {
			...state,
			updates: removeValueFromArray(state.updates, action.payload.result),
			previousEntities: removeValueFromArray(state.previousEntities, action.payload.result),
			saveSyncInProgress: false,
		};
	}

	if (isType(action, siteChange.done)) {
		state = initialAdjustFeedState;
	}

	// if (isType(action, upsertEntranceEvent.started)) {
	// 	const update = action.payload.map(
	// 		x => ({ feedingCurve: x.feedCurve, recipeNumber: x.recipe, penId: x.toPenId } as GrowthPigAdjustFeedUpdate),
	// 	);
	// 	state = {
	// 		...state,
	// 		growthPigAdjustFeedUpdate: mergeArraysNoUniqueGpe(
	// 			state.growthPigAdjustFeedUpdate,
	// 			update,
	// 			(t: GrowthPigAdjustFeedUpdate) => t.penId!,
	// 		),
	// 	};
	// }
	// if (isType(action, upsertGrowthPigsPigletMoveEventsAndUpdateEvents.started)) {
	// 	if (action.payload.growthPigEntranceEventDto) {
	// 		const update = action.payload.growthPigEntranceEventDto.map(
	// 			x =>
	// 				({
	// 					feedingCurve: x.feedCurve,
	// 					recipeNumber: x.recipe,
	// 					penId: x.toPenId,
	// 				} as GrowthPigAdjustFeedUpdate),
	// 		);
	// 		state = {
	// 			...state,
	// 			growthPigAdjustFeedUpdate: mergeArraysNoUniqueGpe(
	// 				state.growthPigAdjustFeedUpdate,
	// 				update,
	// 				(t: GrowthPigAdjustFeedUpdate) => t.penId!,
	// 			),
	// 		};
	// 	}
	// }
	// if (isType(action, upsertMoveEvent.started)) {
	// 	const toPen = action.payload.sort(sortToPen);
	// 	const fromPen = action.payload.sort(sortFromPen);

	// 	let update = toPen.map(
	// 		x =>
	// 			({
	// 				feedingCurve: x.feedCurve,
	// 				recipeNumber: x.recipe,
	// 				penId: x.toPenId,
	// 			} as GrowthPigAdjustFeedUpdate),
	// 	);
	// 	update = update.concat(
	// 		fromPen.map(
	// 			x =>
	// 				({
	// 					feedingCurve: x.feedCurve,
	// 					recipeNumber: x.recipe,
	// 					penId: x.fromPenId,
	// 				} as GrowthPigAdjustFeedUpdate),
	// 		),
	// 	);
	// 	state = {
	// 		...state,
	// 		growthPigAdjustFeedUpdate: mergeArraysNoUniqueGpe(
	// 			state.growthPigAdjustFeedUpdate,
	// 			update,
	// 			(t: GrowthPigAdjustFeedUpdate) => t.penId!,
	// 		),
	// 	};
	// }
	// if (isType(action, upsertDepartureEvent.started)) {
	// 	if (action.payload) {
	// 		const update = action.payload.map(
	// 			x =>
	// 				({
	// 					feedingCurve: undefined,
	// 					recipeNumber: undefined,
	// 					penId: x.fromPenId,
	// 				} as GrowthPigAdjustFeedUpdate),
	// 		);
	// 		state = {
	// 			...state,
	// 			growthPigAdjustFeedUpdate: mergeArraysNoUniqueGpe(
	// 				state.growthPigAdjustFeedUpdate,
	// 				update,
	// 				(t: GrowthPigAdjustFeedUpdate) => t.penId!,
	// 			),
	// 		};
	// 	}
	// }

	// if (isType(action, upsertDepartureFromPenEvent.started)) {
	// 	if (action.payload) {
	// 		const update = action.payload.map(
	// 			x =>
	// 				({
	// 					feedingCurve: undefined,
	// 					recipeNumber: undefined,
	// 					penId: x.fromPenId,
	// 				} as GrowthPigAdjustFeedUpdate),
	// 		);
	// 		state = {
	// 			...state,
	// 			growthPigAdjustFeedUpdate: mergeArraysNoUniqueGpe(
	// 				state.growthPigAdjustFeedUpdate,
	// 				update,
	// 				(t: GrowthPigAdjustFeedUpdate) => t.penId!,
	// 			),
	// 		};
	// 	}
	// }

	if (isType(action, updateValveGroup)) {
		const upd = action.payload;
		const vgIndex = state.valveGroups.findIndex((x: any) => x.id === upd.id);
		const valveGroups = [...state.valveGroups];
		const prevEntity = deepCopy(valveGroups[vgIndex]);

		valveGroups[vgIndex].constantDeviation = upd.constantDeviation;
		valveGroups[vgIndex].deviation = upd.deviation;
		valveGroups[vgIndex].deviationDays = upd.deviationDays;
		valveGroups[vgIndex].feedingCurve = upd.feedingCurve;
		valveGroups[vgIndex].sowCycle = upd.sowCycle;
		valveGroups[vgIndex].pigCount = upd.pigCount;
		valveGroups[vgIndex].pigletCount = upd.pigletCount;
		valveGroups[vgIndex].weight = upd.weight;
		valveGroups[vgIndex].penDays = upd.penDays;
		const uIndex = state.updates.findIndex((x: any) => x.id === upd.id);
		let updates: IValveGroupUpdate[];

		if (uIndex < 0) {
			updates = state.updates.concat([upd]);
		} else {
			updates = [...state.updates];
			updates[uIndex] = upd;
		}
		state = {
			...state,
			updates,
			valveGroups,
			previousEntities: upsertValueInArray(state.previousEntities, prevEntity),
		};
	}

	//Ensure Date objects are in fact date and not strings - redux-persist serializes dates to string but doesn't deserialize to dates again
	if (action.type === 'persist/REHYDRATE') {
		let a = (action as any) as { payload: AdjustFeedState; key: string };

		if (a.key === 'adjustfeed') {
			let updates = new Array<ValveGroupUpdate>();
			let previousEntities = new Array<ValveGroup>();
			let lastSyncDate = new Date(-8640000000000000);
			let valveGroups = new Array<ValveGroup>();
			if (a.payload && a.payload && a.payload.valveGroups) {
				valveGroups = a.payload.valveGroups.map((valveGroup: IValveGroup) => {
					let newValveGroup = new ValveGroup();
					newValveGroup.init(valveGroup);
					return newValveGroup;
				});
			}

			if (a.payload && a.payload && a.payload.updates) {
				updates = a.payload.updates.map((valveGroupUpdate: IValveGroupUpdate) => {
					let newValveGroupUpdate = new ValveGroupUpdate();
					newValveGroupUpdate.init(valveGroupUpdate);
					return newValveGroupUpdate;
				});
			}

			if (a.payload && a.payload && a.payload.previousEntities) {
				previousEntities = a.payload.previousEntities.map((valveGroup: IValveGroup) => {
					let newValveGroup = new ValveGroup();
					newValveGroup.init(valveGroup);
					return newValveGroup;
				});
			}

			if (a.payload && a.payload && a.payload.lastSyncDate) {
				lastSyncDate = new Date(a.payload.lastSyncDate);
			}

			state = { ...state, valveGroups, updates, lastSyncDate, previousEntities };
		}
	}

	if (isType(action, setFullSyncSpinner.done)) {
		state = {
			...state,
			showFullSyncSpinner: action.payload.result,
			fullSyncFailed: false,
		};
	}

	if (isType(action, setFullSyncNumbers.done)) {
		state = {
			...state,
			fullSyncNumbers: { ...action.payload.result },
		};
	}

	if (isType(action, fullSyncFailed.started)) {
		state = {
			...state,
			fullSyncFailed: true,
			showFullSyncSpinner: false,
		};
	}

	return state;
};

function mergeValveGroupUpdates(
	newValveGroupUpdate: IValveGroupUpdate,
	oldValveGroupUpdate: IValveGroupUpdate | undefined,
): IValveGroupUpdate {
	if (!oldValveGroupUpdate) {
		return newValveGroupUpdate;
	}

	oldValveGroupUpdate = { ...oldValveGroupUpdate };

	oldValveGroupUpdate.constantDeviation = getSetValue(
		oldValveGroupUpdate.constantDeviation,
		newValveGroupUpdate.constantDeviation,
	);
	oldValveGroupUpdate.deviation = getSetValue(oldValveGroupUpdate.deviation, newValveGroupUpdate.deviation);
	oldValveGroupUpdate.deviationDays = getSetValue(
		oldValveGroupUpdate.deviationDays,
		newValveGroupUpdate.deviationDays,
	);
	oldValveGroupUpdate.feedingCurve = getSetValue(oldValveGroupUpdate.feedingCurve, newValveGroupUpdate.feedingCurve);
	oldValveGroupUpdate.sowCycle = getSetValue(oldValveGroupUpdate.sowCycle, newValveGroupUpdate.sowCycle);
	oldValveGroupUpdate.pigCount = getSetValue(oldValveGroupUpdate.pigCount, newValveGroupUpdate.pigCount);
	oldValveGroupUpdate.pigletCount = getSetValue(oldValveGroupUpdate.pigletCount, newValveGroupUpdate.pigletCount);
	oldValveGroupUpdate.weight = getSetValue(oldValveGroupUpdate.weight, newValveGroupUpdate.weight);
	oldValveGroupUpdate.penDays = getSetValue(oldValveGroupUpdate.penDays, newValveGroupUpdate.penDays);

	return oldValveGroupUpdate;
}

function getSetValue(newVal: any, oldVal: any) {
	if (newVal !== undefined && newVal !== null) {
		return newVal;
	}

	return oldVal;
}

export function sortToPen(ob1: GrowthPigMoveEventDto, ob2: GrowthPigMoveEventDto) {
	if (ob1.toPenId! > ob2.toPenId!) {
		return 1;
	} else if (ob1.toPenId! < ob2.toPenId!) {
		return -1;
	}

	// Else go to the 2nd item
	if (
		moment(ob1.date.withoutTime(), 'DD.MM.YY') > moment(ob2.date.withoutTime(), 'DD.MM.YY') ||
		(ob1.date && !ob2.date)
	) {
		return 1;
	} else {
		return -1;
	}
}

export function sortFromPen(ob1: GrowthPigMoveEventDto, ob2: GrowthPigMoveEventDto) {
	if (ob1.fromPenId! > ob2.fromPenId!) {
		return 1;
	} else if (ob1.fromPenId! < ob2.fromPenId!) {
		return -1;
	}

	// Else go to the 2nd item
	if (
		moment(ob1.date.withoutTime(), 'DD.MM.YY') > moment(ob2.date.withoutTime(), 'DD.MM.YY') ||
		(ob1.date && !ob2.date)
	) {
		return 1;
	} else {
		return -1;
	}
}

export function mergeArraysNoUniqueGpe(
	oldArray: GrowthPigAdjustFeedUpdate[],
	updatedArray: GrowthPigAdjustFeedUpdate[],
	idFn: (t: GrowthPigAdjustFeedUpdate) => string,
) {
	if (!oldArray || oldArray.length === 0) {
		return updatedArray;
	}

	let clonedOld = [...oldArray];
	updatedArray.forEach(updated => {
		const index = clonedOld.findIndex(old => idFn(old) === idFn(updated));
		const handlesUpdated =
			index > -1
				? ({
						...updated,
						feedingCurve: updated.feedingCurve ?? clonedOld[index].feedingCurve,
						recipeNumber: updated.recipeNumber ?? clonedOld[index].recipeNumber,
				  } as GrowthPigAdjustFeedUpdate)
				: updated;
		mergeNoUnique<GrowthPigAdjustFeedUpdate>(index, clonedOld, handlesUpdated);
	});

	return clonedOld;
}

export default adjustFeedReducer;
