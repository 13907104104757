import { IReasonDto } from "shared/api/api";
import { Syncable } from "shared/state/models/syncable";


export const SAVE_REASON = 'SAVE_REASON';

export const SAVE_REASON_SETTINGS = 'SAVE_REASON_SETTINGS';
export const SYNC_DATA_REASON = 'SYNC_DATA_REASON';

export interface ResasonsState extends Syncable<IReasonDto> {}
