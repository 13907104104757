import React, { FC, useState, useEffect, useCallback, useMemo } from 'react';
import { Keyboard, KeyboardEvent } from 'react-native';
import { SetKeyboardHeight } from 'native/state/ducks/app-state/operations';
import { useDispatch, useSelector } from 'react-redux';
import { ViewWeb } from '../../utils/web-view';
import { SkioldDatePicker } from '../skiold-date-picker/skiold-date-picker';
import { WebAppState } from 'web/state/store.web';
import { SkioldFetchIcon } from '../../utils/skiold-fetch-icon';
import { SkioldDropdown } from '../skiold-dropdown/skiold-dropdown';
import { getProvider } from 'web/view/pages/nucleus-management/settings/nucleus-management-helper';
import { NucleusManagementProvider } from 'shared/api/api';
import { getCombinations } from 'shared/helpers/nucleus-management-helper/nucleus-management-helper';
import { Option } from 'react-dropdown';
import { setBreed } from 'shared/state/ducks/skiold-fetch/reducer';
import { getDanBredBreeds, getSkioldBreeds, getUniqueDadBreeds } from 'shared/state/ducks/breed-table-items/operation';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { localized } from 'shared/state/i18n/i18n';
interface PropsFromParent {}
export const SkioldFetchBreedDropdown: FC<PropsFromParent> = React.memo(({}) => {
	const dispatch = useDispatch();
	const [option, setOption] = useState<Option>({ label: '', value: '' });
	const activeSite = useSelector((state: WebAppState) => state.site.site);
	const provider = useMemo(() => getProvider(activeSite.activeLicences), [activeSite]);
	const tableBreeds = useSelector((state: WebAppState) => state.breedTableItems.tableBreeds);
	const animals = useSelector((state: WebAppState) => state.stemAnimals.entities);
	useEffect(() => {
		if (provider === NucleusManagementProvider.DanishGenetics) {
			dispatch(getUniqueDadBreeds());
			return;
		}
		if (provider === NucleusManagementProvider.DanBred) {
			dispatch(getDanBredBreeds());
			return;
		}
		dispatch(getSkioldBreeds());
	}, [dispatch, provider]);

	const breedOptions = useMemo(() => {
		let options: Option[] = [];
		const breedSet = new Set<string>();
		animals.forEach(animal => animal.race && breedSet.add(animal.race));
		breedSet.forEach(item => {
			options.push({ label: item, value: item } as Option);
		});
		options.unshift({ label: ' ', value: '' });
		return options;
	}, [tableBreeds, provider, animals]);

	const breedChanged = useCallback((option: Option) => {
		setOption(option);
		dispatch(setBreed(option.value));
	}, [dispatch]);

	return (
		<ViewWeb className="flex-row">
			<TextWeb className="race-text">{localized('Race')}:</TextWeb>
			<SkioldDropdown
				items={breedOptions}
				onValueChanged={breedChanged}
				selectedValue={option}
				theme={'light'}
				menuClassname={'skiold-fetch-breed-menu-dropdown'}
				className={'skiold-fetch-breed-dropdown'}
				containerClassName="skiold-fetch-breed-dropdown-container"
			/>
		</ViewWeb>
	);
});
