export class Colors {
	public static yellow = '#f2ac40';
	public static darkGrey = '#364142';
	public static grey = '#435152';
	public static lightGrey = '#6f7a7a';
	public static dottedBorderColor: '#838380';
	public static borderGrey = '#9faaaa';
	public static lighterGrey = '#ccc';
	public static lightestGrey = '#ddd';
	public static white = '#fff';
	public static black = '#000';
	public static filterRed = '#d95b1e';
	public static debug = '#f00';
	public static red = 'rgb(255, 91, 91)';
	public static darkRed = '#BF0000';
	public static greenColor = '#008000';
	public static lightGreen = '#54BF0C';
	public static controllersGray = '#838E8E';
	public static controllersRed = '#EC4100';
	public static controllersBlue = '#008EEC';
}
