import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { siteChange } from '../profile/actions';
import { getSyncModelData } from './actions';
import { SyncState } from './types';

export const initialState: SyncState = {
	IsSynching: false,
	LastSync: new Date(-8640000000000000),
};

const syncReducer = (state: SyncState = initialState, action: Action): SyncState => {
	if (isType(action, siteChange.done)) {
		state = initialState;
	}

	if (isType(action, getSyncModelData.done)) {
		if (action.payload.result.locations && action.payload.result.locations.syncTime) {
			state = { ...state, LastSync: action.payload.result.locations!.syncTime };
		}
	}

	return state;
};

export default syncReducer;
