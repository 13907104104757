import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
	IBuilding,
	IDiagnose,
	IDrugType,
	IPen,
	ISection,
	IStemAnimal,
	ISubTreatment,
	ITreatment,
	ITreatmentDefinition,
	ITreatmentPlan,
	TreatmentUnit
} from 'shared/api/api';
import { getDateString, isOverdue } from 'shared/helpers/date-helpers';
import { getDiagnoseName } from 'shared/helpers/diagnose-helper/diagnose-helper';
import { getStemAnimalPen } from 'shared/helpers/location-helper';
import { findAnimalById } from 'shared/helpers/stemanimal-helper/stem-animal-input-helper';
import { calculateDrugAmountPerPig, calculateTotalDrugAmount } from 'shared/helpers/treatment-helper/treatment-helper';
import { GetSyncData as DrugTypeGetSyncData } from 'shared/state/ducks/drugTypes/operations';
import { GetLocations } from 'shared/state/ducks/locations/operations';
import { GetSyncData as GetMoveEventSyncData } from 'shared/state/ducks/move-events/operations';
import { RegisterDeparture } from 'shared/state/ducks/stem-animals/operations';
import { GetSyncData as TreatmentDefinitionGetSyncData } from 'shared/state/ducks/treatment-definitions/operations';
import { UpdateTreatmentPlansForDepartedSow } from 'shared/state/ducks/treatment-plan/operations';
import { GetSyncData as GetTreatmentsGetSyncData, SaveTreatment } from 'shared/state/ducks/treatment/operations';
import { localized, localizedDynamic } from 'shared/state/i18n/i18n';
import { DepartureTypes } from 'shared/state/models/departure-types';
import { WebAppState } from 'web/state/store.web';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import PageContainer from '../../page-container/page-container';
import { ShowCustomAlert } from '../../skiold-alert/skiold-alert';
import { SkioldButton } from '../../skiold-components/skiold-button/skiold-button';
import { SkioldFormsWrapper } from '../../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from '../../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { SkioldFormIntegerInput } from '../../skiold-components/skiold-integer-input/skiold-form-integer-input';
import { Heading } from '../../utils/heading';
import './treatment-details.scss';

export interface PropsFromState {
	// tslint:disable-next-line:no-any
	navigation: any;
}

const mapStateToProps = (state: WebAppState, props: PropsFromState) => {
	return {
		siteId: state.profile.active!.siteId,
		buildings: state.locations.buildings,
		sections: state.locations.sections,
		pens: state.locations.pens,
		drugTypes: state.drugType.entities,
		treatmentDefinitions: state.treatmentDefinitions.entities,
		diagnoses: state.diagnose.entities,
		treatmentId: props.navigation.getParam('treatmentId'),
		treatmentPlans: state.treatmentPlans.entities,
		treatments: state.treatments.entities,
		profile: state.profile.active,
		moveEvents: state.moveEvents.entities,
		stemAnimals: state.stemAnimals.entities //for render purposes
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	getDrugTypes: () => DrugTypeGetSyncData()(dispatch),
	treatmentDefinitionGetSyncData: () => TreatmentDefinitionGetSyncData()(dispatch),
	getLocationsAsync: () => GetLocations()(dispatch),
	// navigateBack: () => dispatch({ type: Navigation.NavigationActions.BACK }),
	saveTreatment: (treatment: ITreatment) => SaveTreatment(treatment)(dispatch),
	updateTreatmentPlansForDepartedSow: (stemAnimalId: string, isSowActive: boolean) =>
		UpdateTreatmentPlansForDepartedSow(stemAnimalId, isSowActive)(dispatch),
	getTreatments: () => GetTreatmentsGetSyncData()(dispatch),
	getMoveEventSyncData: () => GetMoveEventSyncData()(dispatch),
	registerDeparture: (animal: IStemAnimal, departureType: string, departureDate: Date) =>
		RegisterDeparture(animal, departureType, departureDate)(dispatch)
});

interface State {
	stemAnimal: IStemAnimal;
	treatmentPlan: ITreatmentPlan;
	treatment: ITreatment;
	treatmentDefinition: ITreatmentDefinition;
	pen: IPen;
	section: ISection;
	building: IBuilding;
	diagnose: IDiagnose;
	subTreatmentsAndDrugTypes: ISubTreatmentAndDrugType[];
}
type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromState;

class TreatmentDetails extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		const detailsData = this.getContent();

		if (detailsData) {
			const treatment = detailsData.treatment;
			const treatmentPlan = detailsData.treatmentPlan;
			const treatmentDefinition = detailsData.treatmentDefinition;
			const stemAnimal = detailsData.stemAnimal;
			const pen = detailsData.pen;
			const section = detailsData.section;
			const building = detailsData.building;
			const diagnose = detailsData.diagnose;
			const subTreatmentsAndDrugTypes = detailsData.subTreatmentsAndDrugTypes;

			this.state = {
				treatment,
				treatmentPlan,
				stemAnimal,
				treatmentDefinition,
				pen,
				section,
				building,
				diagnose,
				subTreatmentsAndDrugTypes
			};
		}
	}

	public async componentDidMount() {
		this.props.getLocationsAsync();
		this.props.treatmentDefinitionGetSyncData();
		this.props.getDrugTypes();
		this.props.getTreatments();
		this.props.getMoveEventSyncData();
	}

	public render() {
		let formRows = this.getFormRows();
		return (
			<PageContainer>
				<Heading text={localized('treatmentDetails')} />
				<ViewWeb className="treatment-details">
					<ViewWeb className="scroller">
						<SkioldFormsWrapper formRows={formRows} containerClassName="treatment-details-container" />
						{this.state.treatment.pigletAmount! === 0
							? this.renderButtonsForSingleAnimalTreatment()
							: this.renderButtonsForMultipleAnimalsTreatment()}
					</ViewWeb>
				</ViewWeb>
			</PageContainer>
		);
	}

	public async animalDied() {
		let animalDiedBeforeToday: boolean = false;
		if (isOverdue(this.state.treatment.plannedDate!)) {
			animalDiedBeforeToday = await ShowCustomAlert(localized('OverdueAlertMessage'));
		}
		await this.props.registerDeparture(
			this.state.stemAnimal,
			DepartureTypes.departureTypeDead,
			animalDiedBeforeToday ? this.state.treatment.plannedDate! : new Date()
		);
		this.props.updateTreatmentPlansForDepartedSow(this.state.stemAnimal.id!, false);
		// this.props.navigateBack();
	}

	public async animalPutDown() {
		let animalDiedBeforeToday: boolean = false;
		if (isOverdue(this.state.treatment.plannedDate!)) {
			animalDiedBeforeToday = await ShowCustomAlert(localized('OverdueAlertMessage'));
		}

		await this.props.registerDeparture(
			this.state.stemAnimal,
			DepartureTypes.departureTypePutDown,
			animalDiedBeforeToday ? this.state.treatment.plannedDate! : new Date()
		);
		this.props.updateTreatmentPlansForDepartedSow(this.state.stemAnimal.id!, false);
		// this.props.navigateBack();
	}

	// Saves this and future, possibly modified, treatments.
	public async treated() {
		this.props.saveTreatment(this.state.treatment);
		this.props.treatments
			.filter(
				t =>
					t.treatmentPlanId === this.state.treatment.treatmentPlanId &&
					t.treatmentNumber! > this.state.treatment.treatmentNumber! &&
					t.plannedDate &&
					t.plannedDate > this.state.treatment.plannedDate!
			)
			.forEach(t => {
					this.props.saveTreatment(t);
			});
		// this.props.navigateBack();
	}

	private renderButtonsForSingleAnimalTreatment() {
		return (
			<ViewWeb className="treatment-details-buttons-container">
				{this.getTreatedButton()}
				<SkioldButton title={localized('departureTypeDead')} onPress={async () => await this.animalDied()} />
				<SkioldButton title={localized('putDown')} onPress={async () => await this.animalPutDown()} />
			</ViewWeb>
		);
	}

	private renderButtonsForMultipleAnimalsTreatment() {
		return <ViewWeb className="treatment-details-buttons-container">{this.getTreatedButton()}</ViewWeb>;
	}

	private getTreatedButton() {
		return (
			<SkioldButton
				title={localized('Treated')}
				onPress={async () => {
					await this.setExecutedMetadataAndSaveTreatment();
				}}
			/>
		);
	}

	private async setExecutedMetadataAndSaveTreatment() {
		let treatmentDoneInTime: boolean = false;

		if (isOverdue(this.state.treatment.plannedDate!)) {
			treatmentDoneInTime = await ShowCustomAlert(localized('OverdueAlertMessage'));
		}

		this.setState(
			prevState => ({
				treatment: {
					...prevState.treatment,
					executedByProfileId: this.props.profile!.id!,
					executedDate: treatmentDoneInTime ? this.state.treatment.plannedDate! : new Date()
				}
			}),
			async () => await this.treated()
		);
	}

	private getFormRows(): FormRow[] {
		let formRows = new Array<FormRow>();
		formRows.push(this.getAnimalNumberRow());
		formRows.push(this.getPigletAmountRow());
		formRows.push(this.getTreatmentDateRow());
		formRows.push(this.getLocationRow());
		formRows.push(this.getPenRow());
		formRows.push(this.getDiagnoseRow());
		formRows.push(...this.getSubtreatmentRows());
		if (
			this.state.treatmentDefinition.subTreatments!.find(
				td => td.drugAmount!.unitType === TreatmentUnit.PerKgPig
			) != null
		) {
			formRows.push(this.getAnimalWeightRow());
		}
		if (
			this.state.treatmentDefinition.subTreatments!.find(
				td => td.drugAmount!.unitType === TreatmentUnit.PerKgFood
			) != null
		) {
			formRows.push(this.getFeedWeightRow());
		}
		if (this.state.treatmentPlan && this.state.treatmentPlan.marking) {
			formRows.push(this.getMarkingRow());
		}
		return formRows;
	}

	private getAnimalNumberRow(): FormRow {
		return {
			name: localized('animalNr'),
			component: <TextWeb>{this.state.stemAnimal.animalNumber!}</TextWeb>
		};
	}

	private getPigletAmountRow(): FormRow {
		return {
			name: localized('AmountOfPiglets'),
			component:
				this.state.treatment.pigletAmount! === 0 ? (
					<TextWeb>{this.state.treatment.pigletAmount!}</TextWeb>
				) : (
					<SkioldFormIntegerInput
						text={this.state.treatment.pigletAmount!}
						onChangeNumber={newAmount => this.updateFutureTreatmentValues(newAmount!, 'pigletAmount')}
					/>
				)
		};
	}

	private getTreatmentDateRow(): FormRow {
		return {
			name: localized('Date'),
			component: <TextWeb>{getDateString(this.state.treatment.plannedDate!)}</TextWeb>
		};
	}

	private getLocationRow(): FormRow {
		return {
			name: localized('StableSection'),
			component: <TextWeb>{`${this.state.building.name} - ${this.state.section.name}`}</TextWeb>
		};
	}

	private getPenRow(): FormRow {
		return {
			name: localized('Pen'),
			component: <TextWeb>{this.state.pen.name}</TextWeb>
		};
	}

	private getDiagnoseRow(): FormRow {
		return {
			name: localized('Diagnose'),
			component: <TextWeb>{getDiagnoseName(this.state.diagnose, this.props.profile!.language)}</TextWeb>
		};
	}

	private getAnimalWeightRow(): FormRow {
		return {
			name: localized('AnimalWeight'),
			component: (
				<SkioldFormIntegerInput
					text={this.state.treatment.animalWeight}
					onChangeNumber={newWeight => this.updateFutureTreatmentValues(newWeight!, 'animalWeight')}
				/>
			)
		};
	}

	private getFeedWeightRow(): FormRow {
		return {
			name: localized('FeedWeight'),
			component: (
				<SkioldFormIntegerInput
					text={this.state.treatment.feedWeight}
					onChangeNumber={newWeight => this.updateFutureTreatmentValues(newWeight!, 'feedWeight')}
				/>
			)
		};
	}

	private getMarkingRow(): FormRow {
		return {
			name: localized('marking'),
			component: <TextWeb>{localizedDynamic(this.state.treatmentPlan.marking!)}</TextWeb>
		};
	}

	private getSubtreatmentRows(): FormRow[] {
		let rows: FormRow[] = [];
		let drugNumber: number = 1;
		this.state.subTreatmentsAndDrugTypes.forEach(st => {
			rows.push(
				{
					name: `${localized('drug')} ${drugNumber}`,
					component: <TextWeb>{st.drugType.name}</TextWeb>
				},
				{
					name: localized('method'),
					component: <TextWeb>{localized(st.subTreatment.treatmentType!)}</TextWeb>
				},
				{
					name: localized('DrugAmount'),
					component: (
						<ViewWeb className="sub-treatment-container">
							<ViewWeb className="row-container">
								<TextWeb>{localized('PerAnimal')}:</TextWeb>
								<TextWeb>
									{`${calculateDrugAmountPerPig(
										st.subTreatment.drugAmount!,
										this.state.treatment.animalWeight!,
										this.state.treatment.feedWeight!
									)} ${st.drugType.unitOfMeasurement}`}
								</TextWeb>
							</ViewWeb>
							<ViewWeb className="row-container">
								<TextWeb>{localized('Total')}</TextWeb>
								<TextWeb>
									{`${calculateTotalDrugAmount(
										st.subTreatment.drugAmount!,
										this.state.treatment.pigletAmount!,
										this.state.treatment.animalWeight!,
										this.state.treatment.feedWeight!
									)} ${st.drugType.unitOfMeasurement}`}
								</TextWeb>
							</ViewWeb>
						</ViewWeb>
					)
				}
			);
			drugNumber++;
		});
		return rows;
	}

	private getContent(): ITreatmentDetailsData | undefined {
		const treatment = this.props.treatments.find(t => t.id === this.props.treatmentId)!;
		if (!treatment) {
			return;
		}

		const treatmentPlan = this.props.treatmentPlans.find(tp => tp.id === treatment.treatmentPlanId)!;
		if (!treatmentPlan) {
			return;
		}

		const stemAnimal = findAnimalById(treatmentPlan.stemAnimalId);
		if (!stemAnimal) {
			return;
		}

		const pen = getStemAnimalPen(stemAnimal, this.props.moveEvents, this.props.pens);
		if (!pen) {
			return;
		}

		const section = this.props.sections.find(s => s.id === pen!.sectionId)!;
		if (!section) {
			return;
		}

		const building = this.props.buildings.find(b => b.id === section!.buildingId)!;
		if (!building) {
			return;
		}

		const treatmentDefinition = this.props.treatmentDefinitions.find(
			td => td.id === treatmentPlan.treatmentDefinitionId
		)!;
		if (!treatmentDefinition) {
			return;
		}

		const subTreatmentsAndDrugTypes = new Array<ISubTreatmentAndDrugType>();
		treatmentDefinition!.subTreatments!.forEach(subTreatment => {
			const drugType = this.props.drugTypes.find(dt => dt.id === subTreatment.drugTypeId)!;
			if (drugType) {
				subTreatmentsAndDrugTypes.push({ drugType, subTreatment });
			}
		});

		const diagnose = this.props.diagnoses.find(d => d.id === treatmentDefinition.diagnoseId)!;
		if (!diagnose) {
			return;
		}

		const data: ITreatmentDetailsData = {
			treatment,
			treatmentPlan,
			stemAnimal,
			pen,
			section,
			building,
			treatmentDefinition,
			subTreatmentsAndDrugTypes,
			diagnose
		};

		return data;
	}

	private updateFutureTreatmentValues(
		newValue: number | undefined,
		field: 'animalWeight' | 'feedWeight' | 'pigletAmount'
	) {
		if (field === 'pigletAmount') {
			if (!newValue || newValue < 1) {
				newValue = 1;
			}
			this.setState(prevState => ({
				treatment: { ...prevState.treatment, pigletAmount: newValue }
			}));
		} else if (field === 'animalWeight') {
			this.setState(prevState => ({
				treatment: { ...prevState.treatment, animalWeight: newValue }
			}));
		} else if (field === 'feedWeight') {
			this.setState(prevState => ({
				treatment: { ...prevState.treatment, feedWeight: newValue }
			}));
		}
		this.props.treatments
			.filter(
				t =>
					t.treatmentPlanId === this.state.treatment.treatmentPlanId &&
					t.treatmentNumber! > this.state.treatment.treatmentNumber! &&
					t.plannedDate! > this.state.treatment.plannedDate!
			)
			.forEach(t => {
				if (field === 'pigletAmount') {
					t.pigletAmount = newValue!;
				} else if (field === 'animalWeight') {
					t.animalWeight = newValue!;
				} else if (field === 'feedWeight') {
					t.feedWeight = newValue!;
				}
			});
	}
}

// tslint:disable-next-line:interface-name
export interface ITreatmentDetailsData {
	treatment: ITreatment;
	treatmentPlan: ITreatmentPlan;
	stemAnimal: IStemAnimal;
	pen: IPen;
	section: ISection;
	building: IBuilding;
	treatmentDefinition: ITreatmentDefinition;
	subTreatmentsAndDrugTypes: ISubTreatmentAndDrugType[];
	diagnose: IDiagnose;
}

// tslint:disable-next-line:interface-name
export interface ISubTreatmentAndDrugType {
	subTreatment: ISubTreatment;
	drugType: IDrugType;
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(TreatmentDetails);
