import { Action } from 'redux';
import {
	EsfFeedCurve,
	EsfFeedingStatus,
	IEsfFeedCurve,
	IEsfFeedingStatus,
	IEsfStatusAcknowledge,
	EsfStatusAcknowledge,
} from 'shared/api/api';
import {
	mergeArrays,
	removeValueFromArray,
	upsertValueInArray,
	removeMultipleValuesFromArray,
} from 'shared/helpers/reducer-helpers';
import { SharedAppState } from 'shared/state/store.shared';
import { isType } from 'typescript-fsa';
import { siteChange } from '../profile/actions';
import { getSyncModelData } from '../sync/actions';
import {
	getSyncEsfFeedCurveData,
	getSyncEsfFeedingStatusData,
	saveEsfFeedingStatus,
	removeEsfStatus,
	acknowledgeFeedingStatus,
} from './actions';
import { EsfFeedingStatusDataState } from './types';

export const initialState: EsfFeedingStatusDataState = {
	data: [],
	updatesEsfFeedingStatus: [],
	availableCurves: [],
	dataLastSyncDate: new Date(-8640000000000000),
	curveLastSyncDate: new Date(-8640000000000000),
	saveSyncInProgress: false,
	acknowledgeUpdates: [],
};

const esfStatusDataReducer = (
	state: EsfFeedingStatusDataState = initialState,
	action: Action,
): EsfFeedingStatusDataState => {
	if (isType(action, getSyncEsfFeedingStatusData.done)) {
		if (action.payload.result && action.payload.params.siteId === action.payload.params.activeSiteId) {
			if (action.payload.result.datas && action.payload.result.datas.length > 0) {
				state = {
					...state,
					data: mergeArrays(state.data, action.payload.result.datas!),
					dataLastSyncDate: action.payload.result.syncTime,
				};
			}
		}
	}

	if (isType(action, getSyncModelData.done)) {
		if (action.payload.result && action.payload.params.siteId === action.payload.params.activeSiteId) {
			if (
				action.payload.result.esfFeedingStatus &&
				action.payload.result.esfFeedingStatus.datas &&
				action.payload.result.esfFeedingStatus.datas.length > 0
			) {
				state = {
					...state,
					data: mergeArrays(state.data, action.payload.result.esfFeedingStatus.datas!),
					dataLastSyncDate: action.payload.result.esfFeedingStatus.syncTime,
				};
			}
		}
	}

	if (isType(action, saveEsfFeedingStatus.started)) {
		if (action.payload) {
			const itemUpdate = action.payload;
			let item = { ...state.data.find(d => d.id === itemUpdate.id) } as IEsfFeedingStatus;

			item.fixedDeviation = itemUpdate.fixedDeviation!;
			item.feedCurveNumber = itemUpdate.feedCurveNumber!;
			state = {
				...state,
				updatesEsfFeedingStatus: upsertValueInArray(state.updatesEsfFeedingStatus, action.payload),
				data: upsertValueInArray(state.data, item),
				saveSyncInProgress: true,
			};
		}
	}

	if (isType(action, removeEsfStatus.started)) {
		const idsToRemove = state.data
			.filter(esfStatus => esfStatus.stemAnimalId === action.payload)
			.map(esfStatus => esfStatus.id!);
		if (idsToRemove && idsToRemove.length > 0) {
			state = {
				...state,
				data: removeMultipleValuesFromArray(state.data, idsToRemove),
			};
		}
	}

	if (isType(action, saveEsfFeedingStatus.failed)) {
		state = { ...state, saveSyncInProgress: false };

		return state;
	}

	if (isType(action, saveEsfFeedingStatus.done)) {
		if (action.payload.params) {
			state = {
				...state,
				updatesEsfFeedingStatus: removeValueFromArray(state.updatesEsfFeedingStatus, action.payload.params.id!),
				saveSyncInProgress: false,
			};
		}
	}

	if (isType(action, getSyncEsfFeedCurveData.done)) {
		if (action.payload.result && action.payload.params.siteId === action.payload.params.activeSiteId) {
			if (action.payload.result.datas && action.payload.result.datas.length > 0) {
				state = {
					...state,
					availableCurves: mergeArrays(state.availableCurves, action.payload.result.datas!),
					curveLastSyncDate: action.payload.result.syncTime,
				};
			}
		}
	}

	if (isType(action, getSyncModelData.done)) {
		if (action.payload.result && action.payload.params.siteId === action.payload.params.activeSiteId) {
			if (
				action.payload.result.esfFeedCurves &&
				action.payload.result.esfFeedCurves.datas &&
				action.payload.result.esfFeedCurves.datas.length > 0
			) {
				state = {
					...state,
					availableCurves: mergeArrays(state.availableCurves, action.payload.result.esfFeedCurves.datas!),
					curveLastSyncDate: action.payload.result.esfFeedCurves.syncTime,
				};
			}
		}
	}

	if (isType(action, acknowledgeFeedingStatus.started)) {
		if (action.payload) {
			const itemUpdate = action.payload;
			let item = { ...state.data.find(d => d.id === itemUpdate.id) } as IEsfFeedingStatus;

			item.acknowledgeDate = itemUpdate.acknowledgeDate;
			state = {
				...state,
				acknowledgeUpdates: upsertValueInArray(state.acknowledgeUpdates, action.payload),
				data: upsertValueInArray(state.data, item),
				saveSyncInProgress: true,
			};
		}
	}

	if (isType(action, acknowledgeFeedingStatus.done)) {
		if (action.payload.params) {
			state = {
				...state,
				acknowledgeUpdates: removeValueFromArray(state.acknowledgeUpdates, action.payload.params.id!),
				saveSyncInProgress: false,
			};
		}
	}

	if (isType(action, siteChange.done)) {
		state = initialState;
	}

	if (action.type === 'persist/REHYDRATE') {
		let a = (action as any) as { payload: SharedAppState; key: string };

		if (a.key === 'root') {
			let entities = new Array<IEsfFeedingStatus>();
			let updates = new Array<IEsfFeedingStatus>();
			let acknowledgeUpdates = new Array<IEsfStatusAcknowledge>();
			let availableCurves = new Array<IEsfFeedCurve>();
			let dataLastSyncDate = new Date(-8640000000000000);
			let curveLastSyncDate = new Date(-8640000000000000);

			if (a.payload && a.payload.esfStatus && a.payload.esfStatus.data) {
				entities = a.payload.esfStatus.data.map((dt: IEsfFeedingStatus) => {
					let ndt = EsfFeedingStatus.fromJS({});
					ndt.init(dt);
					return ndt;
				});
			}

			if (a.payload && a.payload.esfStatus && a.payload.esfStatus.updatesEsfFeedingStatus) {
				updates = a.payload.esfStatus.updatesEsfFeedingStatus.map((dt) => {
					let ndt = EsfFeedingStatus.fromJS({});
					ndt.init(dt);
					return ndt;
				});
			}

			if (a.payload && a.payload.esfStatus && a.payload.esfStatus.acknowledgeUpdates) {
				acknowledgeUpdates = a.payload.esfStatus.acknowledgeUpdates.map((dt: IEsfStatusAcknowledge) => {
					let ndt = new EsfStatusAcknowledge();
					ndt.init(dt);
					return ndt;
				});
			}
			if (a.payload && a.payload.esfStatus && a.payload.esfStatus.availableCurves) {
				availableCurves = a.payload.esfStatus.availableCurves.map((dt: IEsfFeedCurve) => {
					let ndt = EsfFeedCurve.fromJS({});
					ndt.init(dt);
					return ndt;
				});
			}
			if (a.payload && a.payload.esfStatus && a.payload.esfStatus.dataLastSyncDate) {
				dataLastSyncDate = new Date(a.payload.esfStatus.dataLastSyncDate);
			}
			if (a.payload && a.payload.esfStatus && a.payload.esfStatus.curveLastSyncDate) {
				curveLastSyncDate = new Date(a.payload.esfStatus.curveLastSyncDate);
			}

			state = {
				...state,
				data: entities,
				updatesEsfFeedingStatus: updates,
				dataLastSyncDate: dataLastSyncDate,
				curveLastSyncDate,
				availableCurves,
				acknowledgeUpdates,
			};
		}
	}

	return state;
};

export default esfStatusDataReducer;
