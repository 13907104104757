import { default as ObjectID } from 'bson-objectid';
import { Dispatch } from 'redux';
import { ITreatmentDefinition, TreatmentDefinition } from 'shared/api/api';
import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import { StoreContainer } from 'shared/state/store-container';
import * as Action from './actions';

export function SaveTreatmentDefinition(treatDef: ITreatmentDefinition, isVaccination: boolean) {
	const state = StoreContainer.getState();
	const prevEntity = state.treatmentDefinitions.entities.find(td => td.id === treatDef.id);
	if (treatDef.id === undefined) {
		treatDef.id = new ObjectID().toHexString();
	}
	let treatDefinition = TreatmentDefinition.fromJS({});
	treatDefinition.init(treatDef);

	return AsyncOperationBuilder2(
		Action.saveTreatmentDefinition,
		client => client.treatmentDefinition_Post(treatDefinition, isVaccination),
		treatDefinition,
		prevEntity,
	);
}

export function GetSyncData() {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;
	const lastSyncDate = state.treatmentDefinitions.lastSyncDate;

	if (state.treatmentDefinitions.syncInProgress) {
		return (dispatch: Dispatch<any>) => {
			return Promise.resolve();
		};
	}
	return AsyncOperationBuilder2(Action.getSyncData, client => client.treatmentDefinition_Sync(siteId, lastSyncDate), {
		siteId,
		lastSyncDate,
	});
}

export function SaveSyncData() {
	const state = StoreContainer.getState();
	const updates = state.treatmentDefinitions.updates;
	const diagnoses = state.diagnose.entities;
	let promises = new Array<Promise<void>>();

	return (dispatch: Dispatch<any>) => {
		if (state.treatmentDefinitions.saveSyncInProgress) {
			return Promise.resolve();
		}
		updates.forEach(update => {
			const diagnose = diagnoses.find(a => a.id === update.diagnoseId);
			promises.push(
				SaveTreatmentDefinition(
					update,
					diagnose && diagnose.isVaccination ? diagnose.isVaccination : false,
				)(dispatch),
			);
		});

		return Promise.all(promises);
	};
}
