import { FileResponse, ISite, SkioldOneClient } from 'shared/api/api';
import { localized } from 'shared/state/i18n/i18n';
import { ExceptionMessage } from './exception-message';
import { SkioldDigitalApiBaseUrl } from 'shared/constants';
import { saveCsvAs } from 'web/web-helpers/pdf-helper/general-pdf-helper';

export function ValidateSite(site: ISite, showAlert: (errorMessage: string) => void) {
	if (!site.countryId) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_COUNTRYID_REQUIRED));
		return false;
	}
	if (!site.chRnum) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_CHRNUM_REQUIRED));
		return false;
	}
	if (!site.siteName) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_SITE_NAME_REQUIRED));
		return false;
	}
	if (!site.siteAddress) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_SITE_ADDRESS_REQUIRED));
		return false;
	}
	if (!site.siteZipCode) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_SITE_ZIPCODE_REQUIRED));
		return false;
	}
	if (!site.siteCity) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_SITE_CITY_REQUIRED));
		return false;
	}
	if (!site.ownerName) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_OWNER_NAME_REQUIRED));
		return false;
	}
	if (!site.ownerAddress) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_OWNER_ADDRESS_REQUIRED));
		return false;
	}
	if (!site.ownerZipCode) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_OWNER_ZIPCODE_REQUIRED));
		return false;
	}
	if (!site.ownerCity) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_OWNER_CITY_REQUIRED));
		return false;
	}
	if (!site.cvRnum) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_CVRNUM_REQUIRED));
		return false;
	}
	return true;
}

export function FetchSiteInformation(
) {
	return new SkioldOneClient(SkioldDigitalApiBaseUrl)
		.sites_FetchSkioldDigitalSitesInformation(

	)
		.then((fileResponse: FileResponse | null) => {
			saveCsvAs(fileResponse, fileResponse?.fileName ?? 'SkioldDigitalSitesInformation.csv');
		})
		.catch(() => {
			return;
		});
}