import { BreedTableColumnEnum, ILicence, IModule } from 'shared/api/api';

export const LOAD_SITE_MODULES = 'LOAD_SITE_MODULES';
export const LOAD_ALL = 'LOAD_ALL';

export interface Module extends IModule {
	isLoading?: boolean;
}

export interface LicenceState {
	siteModules: IModule[];
	licences: ILicence[];
	isLoading: boolean;
	nucleusManagementCompany?: BreedTableColumnEnum;
}
