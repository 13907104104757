import React from 'react';
import { Option } from 'react-dropdown';
import { connect } from 'react-redux';
import { AnimalType, IGrowthPigEntranceEventDto } from 'shared/api/api';
import {
	HandleFromAndToDataChangedEntrance,
	ValidateGpeCapacity,
	validateDistributeGrowthPigs,
} from 'shared/helpers/growth-pigs-helper/growth-pig-event-function-helper';
import {
	DistributeTableDataTypes,
	GrowthPigDistributeState,
	GrowthPigEntranceDistributeProps,
	GrowthPigEntranceDistributePropsMapStateToProps,
	GrowthPigEventsMapDispatchToProps,
	generateGrowthPigDistributeEntranceInitialState,
	saveGrowthPigEntranceEventFromGrowthPigDistribute,
} from 'shared/helpers/growth-pigs-helper/growth-pig-event-helper';
import { localized } from 'shared/state/i18n/i18n';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { ShowConfirmAlert, showAlert } from '../../skiold-alert/skiold-alert';
import { Heading } from '../../utils/heading';
import GrowthPigDistributeForm from './growth-pig-distribute-form';
import { GrowthPigDistributeTable } from './growth-pig-distribute-table';
import './growth-pig-distribute.scss';

export class GrowthPigDistributeEntrance extends React.PureComponent<
	GrowthPigEntranceDistributeProps,
	GrowthPigDistributeState
> {
	constructor(props: GrowthPigEntranceDistributeProps) {
		super(props);
		this.state = generateGrowthPigDistributeEntranceInitialState(props);
	}

	public render() {
		return (
			<ViewWeb className="growth-pig-distribute">
				<Heading text={localized('DistributePigsInSection')}></Heading>
				<ViewWeb className="distribute-container">
					<ViewWeb className="flex-row-space-between-100p">
						<GrowthPigDistributeForm
							OnFromPenChanged={this.OnFromPenChanged}
							OnToPenChanged={this.OnToPenChanged}
							fromPenId={this.state.fromPenId}
							toSectionId={this.props.toSectionId}
							toPenId={this.state.toPenId}
							disablePenSelect={this.state.disablePenColumn}
							amountPigsNotDistributed={this.state.amountPigsNotDistributed}
							totalWeightNotDistributed={this.state.totalWeightNotDistributed}
							feedCurveChanged={this.feedCurveChanged}
							recipeChanged={this.recipeChanged}
							productionType={this.props.productionType}
						/>
						{this.renderButtons()}
					</ViewWeb>
					<GrowthPigDistributeTable
						data={this.state.data}
						disablePenColumn={this.state.disablePenColumn}
						onAmounthanged={this.onAmounthanged}
						onWeightChanged={this.onWeightChanged}
						onTotalWeightChanged={this.onTotalWeightChanged}
					/>
				</ViewWeb>
			</ViewWeb>
		);
	}

	private feedCurveChanged = (feedCurve: number | undefined) => {
		this.setState({ feedCurve });
	};

	private recipeChanged = (recipeNumber: number | undefined) => {
		this.setState({ recipeNumber });
	};

	private onAmounthanged = (numb: number | undefined, item: IGrowthPigEntranceEventDto) => {
		let dataArrayCopy = [...this.state.data];
		const itemToModifyIndex = dataArrayCopy.findIndex(dac => dac.toPenId === item.toPenId);
		dataArrayCopy[itemToModifyIndex] = item;
		const totalWeightNotDistributed = dataArrayCopy.reduce((a: number, b: DistributeTableDataTypes) => {
			return a + (b.amount ?? 0) * (b.avgWeight ?? 0);
		}, 0);
		const totalAmount = dataArrayCopy.reduce((a: number, b: DistributeTableDataTypes) => {
			return a + (b.amount ?? 0);
		}, 0);

		this.setState({
			data: dataArrayCopy,
			amountPigsNotDistributed: (this.props.amount ?? 0) - totalAmount,
			totalWeightNotDistributed: (this.props.totalWeight ?? 0) - totalWeightNotDistributed,
		});
	};

	private onWeightChanged = (numb: number | undefined, item: IGrowthPigEntranceEventDto) => {
		let dataArrayCopy = [...this.state.data];
		// let currentTotalWeightNotDistributed = HandleGrowthPigDistributeTotalWeightNotDistributed(
		// 	dataArrayCopy,
		// 	item,
		// 	numb,
		// 	this.state.totalWeightNotDistributed,
		// );

		const itemToModifyIndex = dataArrayCopy.findIndex(dac => dac.toPenId === item.toPenId);
		dataArrayCopy[itemToModifyIndex] = item;

		const totalWeightNotDistributed = dataArrayCopy.reduce((a: number, b: DistributeTableDataTypes) => {
			return a + (b.amount ?? 0) * (b.avgWeight ?? 0);
		}, 0);
		this.setState({
			data: dataArrayCopy,
			totalWeightNotDistributed: (this.props.totalWeight ?? 0) - totalWeightNotDistributed,
		});
	};

	private onTotalWeightChanged = (numb: number | undefined, item: IGrowthPigEntranceEventDto) => {
		let dataArrayCopy = [...this.state.data];
		const itemToModifyIndex = dataArrayCopy.findIndex(dac => dac.toPenId === item.toPenId);
		dataArrayCopy[itemToModifyIndex] = item;

		const totalWeightNotDistributed = dataArrayCopy.reduce((a: number, b: DistributeTableDataTypes) => {
			return a + (b.amount ?? 0) * (b.avgWeight ?? 0);
		}, 0);
		this.setState({
			data: dataArrayCopy,
			totalWeightNotDistributed: (this.props.totalWeight ?? 0) - totalWeightNotDistributed,
		});
	};

	private readonly OnFromPenChanged = (newValue?: Option) => {
		if (newValue) {
			const data = HandleFromAndToDataChangedEntrance(this.props, this.state, newValue, this.state.toPenId);
			this.setState({ fromPenId: newValue, data });
		}
	};

	private readonly OnToPenChanged = (newValue?: Option) => {
		if (newValue) {
			const data = HandleFromAndToDataChangedEntrance(this.props, this.state, this.state.fromPenId, newValue);
			this.setState({ toPenId: newValue, data });
		}
	};

	private renderButtons() {
		let buttons = (
			<ViewWeb className="button-view-style align-self-end">
				<SkioldButton theme="grey" title={localized('Close')} onPress={this.props.closeModal} />
				<SkioldButton title={localized('Save')} onPress={this.save} />
			</ViewWeb>
		);

		return buttons;
	}

	private save = async () => {
		if (
			validateDistributeGrowthPigs(
				this.state.totalWeightNotDistributed,
				this.state.amountPigsNotDistributed,
				showAlert,
			)
		) {
			const distributed = this.state.data.keyValuePairSingle2('toPenId', 'amount');
			const notifyCapacity =
				this.props.productionType === AnimalType.Finisher
					? this.props.generalSettings.notifyCapacityFinisher
					: this.props.generalSettings.notifyCapacityWeaner;

			if (
				await ValidateGpeCapacity(
					this.props.toSectionId,
					notifyCapacity,
					this.props.date,
					distributed,
					ShowConfirmAlert,
				)
			) {
				saveGrowthPigEntranceEventFromGrowthPigDistribute(this.state, this.props, this.props.closeModal);
			}
		}
	};
}

export default connect(
	GrowthPigEntranceDistributePropsMapStateToProps,
	GrowthPigEventsMapDispatchToProps,
)(GrowthPigDistributeEntrance);
