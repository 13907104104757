export const ROUTE_HOME = 'route/ROUTE_HOME';
export const RETRY_ADJUST_FEED_SOW = 'route/RETRY_ADJUST_FEED_SOW';
export const RETRY_ADJUST_FEED_PIG = 'route/RETRY_ADJUST_FEED_PIG';
export const ROUTE_EVENTS = 'route/ROUTE_EVENTS';
export const ROUTE_SOW = 'route/ROUTE_SOW';
export const ROUTE_FEEDING_STOCK_WEANER = 'route/ROUTE_FEEDING_STOCK_WEANER';
export const ROUTE_FEEDING_STOCK_FINISHER = 'route/ROUTE_FEEDING_STOCK_FINISHER';
export const ROUTE_SOW_CARD = 'route/ROUTE_SOW';
export const ROUTE_WORK_LIST_EVENT = 'route/ROUTE_WORK_LIST_EVENT';
export const ROUTE_REGISTER_TREATMENT = 'route/ROUTE_REGISTER_TREATMENT';
export const ROUTE_TREATMENT_LIST = 'route/ROUTE_TREATMENT_LIST';
export const ROUTE_TREATMENT_DETAILS = 'route/ROUTE_TREATMENT_DETAILS';
export const ROUTE_DRUG_TYPES = 'route/ROUTE_DRUG_TYPES';
export const ROUTE_DRUG_STOCK = 'route/ROUTE_DRUG_STOCK';
export const ROUTE_DRUG_STOCK_FINISHER = 'route/ROUTE_DRUG_STOCK_FINISHER';
export const ROUTE_DRUG_STOCK_WEANER = 'route/ROUTE_DRUG_STOCK_WEANER';
export const ROUTE_FEEDING_STOCK = 'route/ROUTE_FEEDING_STOCK';
export const ROUTE_PREGNANCY_EVENT_LISTS = 'route/ROUTE_PREGNANCY_EVENT_LISTS';
export const ROUTE_GROWTH_PIG_DASHBOARD_WEANER = 'route/ROUTE_GROWTH_PIG_DASHBOARD_WEANER';
export const ROUTE_GROWTH_PIG_DASHBOARD_FINISHER = 'route/ROUTE_GROWTH_PIG_DASHBOARD_FINISHER';
export const ROUTE_GROWTH_PIG_EVENT_LISTS_WEANER = 'route/ROUTE_GROWTH_PIG_EVENT_LISTS_WEANER';
export const ROUTE_GROWTH_PIG_EVENTS_WEANER = 'route/ROUTE_GROWTH_PIG_EVENTS_WEANER';
export const ROUTE_GROWTH_PIG_EVENT_LISTS_FINISHER = 'route/ROUTE_GROWTH_PIG_EVENT_LISTS_FINISHER';
export const ROUTE_GROWTH_PIG_EVENTS_FINISHER = 'route/ROUTE_GROWTH_PIG_EVENTS_FINISHER';
export const ROUTE_DIAGNOSE_CATEGORIES = 'route/ROUTE_DIAGNOSE_CATEGORIES';
export const ROUTE_DIAGNOSES = 'route/ROUTE_DIAGNOSES';
export const ROUTE_TREATMENT_LOG = 'route/ROUTE_TREATMENT_LOG';
export const ROUTE_TREATMENT_LOG_FINISHER = 'route/ROUTE_TREATMENT_LOG_FINISHER';
export const ROUTE_TREATMENT_LOG_WEANER = 'route/ROUTE_TREATMENT_LOG_WEANER';
export const ROUTE_SETTINGS = 'route/ROUTE_SETTINGS';
export const ROUTE_WORK_LISTS = 'route/ROUTE_WORK_LISTS';
export const ROUTE_ANIMAL_REPORT = 'route/ROUTE_ANIMAL_REPORT';
export const ROUTE_SOW_ANALYSIS = 'route/ROUTE_SOW_ANALYSIS';
export const ROUTE_WEANER_ANALYSIS = 'route/ROUTE_WEANER_ANALYSIS';
export const ROUTE_FINISHER_ANALYSIS = 'route/ROUTE_FINISHER_ANALYSIS';
export const ROUTE_ANIMALS = 'route/ROUTE_ANIMALS';
export const ROUTE_DASHBOARD = 'route/ROUTE_DASHBOARD';
export const ROUTE_REASONS = 'route/ROUTE_REASONS';
export const ROUTE_REASONS_SETTINGS = 'route/ROUTE_REASONS_SETTINGS';
export const ROUTE_TREATMENT_DEFINITIONS = 'route/ROUTE_TREATMENT_DEFINITIONS';
export const ROUTE_TREATMENT_DEFINITIONS_FINISHER = 'route/ROUTE_TREATMENT_DEFINITIONS_FINISHER';
export const ROUTE_TREATMENT_DEFINITIONS_WEANER = 'route/ROUTE_TREATMENT_DEFINITIONS_WEANER';
export const ROUTE_SOW_SETTINGS = 'route/ROUTE_SOW_SETTINGS';
export const ROUTE_WEANER_SETTINGS = 'route/ROUTE_WEANER_SETTINGS';
export const ROUTE_FINISHER_SETTINGS = 'route/ROUTE_FINISHER_SETTINGS';
export const ROUTE_MEDICINE_DIAGNOSES = 'route/ROUTE_MEDICINE_DIAGNOSES';
export const ROUTE_WORK_LIST_SETTINGS = 'route/ROUTE_WORK_LIST_SETTINGS';
export const ROUTE_LANGUAGE = 'route/ROUTE_LANGUAGE';
export const ROUTE_SIGN_IN = 'route/ROUTE_SIGN_IN';
export const ROUTE_SIGN_IN_REDIRECT = 'route/ROUTE_SIGN_IN_REDIRECT';
export const ROUTE_SIGN_OUT_REDIRECT = 'route/ROUTE_SIGN_OUT_REDIRECT';
export const ROUTE_CALLBACK = 'route/ROUTE_SIGN_OUT_REDIRECT';
export const ROUTE_CHANGE_PASSWORD = 'route/ROUTE_CHANGE_PASSWORD';
export const ROUTE_PROFILE = 'route/ROUTE_PROFILE';
export const ROUTE_STATUS = 'route/ROUTE_STATUS';
export const ROUTE_MODULE_SETTINGS = 'route/ROUTE_MODULE_SETTINGS';
export const ROUTE_FINISHER_DATA = 'route/ROUTE_FINISHER_DATA';
export const ROUTE_TICAN_CALLBACK = 'route/ROUTE_TICAN_CALLBACK';
export const ROUTE_GROWTH_PIGS_REPORTS_FINISHER = 'route/ROUTE_GROWTH_PIGS_REPORTS_FINISHER';
export const ROUTE_BALANCE_WEANED_PIG = 'route/ROUTE_BALANCE_WEANED_PIG';
export const ROUTE_GROWTH_PIGS_REPORTS_WEANER = 'route/ROUTE_GROWTH_PIGS_REPORTS_WEANER';
export const ROUTE_BMP = 'route/BMP';
export const ROUTE_GROWTH_PIG_SHOULD_DEPART_LIST_WEANER = 'route/ROUTE_GROWTH_PIG_SHOULD_DEPART_LIST_WEANER';
export const ROUTE_GROWTH_PIG_SHOULD_DEPART_LIST_FINISHER = 'route/ROUTE_GROWTH_PIG_SHOULD_DEPART_LIST_FINISHER';
export const ROUTE_REGULATIONS = 'route/ROUTE_REGULATIONS';
export const ROUTE_MODULE_LICENSES = 'route/ROUTE_MODULE_LICENCES';
export const ROUTE_ADMIN_BREED_TABLE = 'route/ROUTE_ADMIN_BREED_TABLE';
export const ROUTE_ADMIN_FEED_REGISTRATIONS = 'route/ROUTE_ADMIN_FEED_REGISTRATIONS';

export interface AppRoute {
	title?: string;
	path: string;
	showInNavBar?: boolean;
	params?: any;
}
