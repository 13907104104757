import memoize from 'memoize-one';
import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ISlaughterSiteDataDto, SlaughterSiteDataDto } from 'shared/api/api';
import DeleteIcon from 'shared/assets/src-assets/png/delete_icon_grey.png';
import MoveIcon from 'shared/assets/src-assets/png/flyt_lightgrey_01.png';
import PrinterGreyIcon from 'shared/assets/src-assets/png/printer_ikon_grey.png';
import { setTwoDecimalsAsNumber } from 'shared/helpers/general-helpers';
import {
	generateSummaryDataForOverviewTableModal,
	SlaughterSiteDataDtoExtend,
} from 'shared/helpers/slaughter-data-helper/slaughter-data-helper';
import {
	DeleteManySlaughterData,
	PrintSlaughterDataOverviewDataRowTableData,
} from 'shared/state/ducks/slaughter-data/operations';
import { localized } from 'shared/state/i18n/i18n';
import { SkioldTableGrid as SkioldTableRef } from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { ShowConfirmAlert } from '../../skiold-alert/skiold-alert';
import { SkioldButton } from '../../skiold-components/skiold-button/skiold-button';
import { SkioldModal } from '../../skiold-components/skiold-modal/skiold-modal';
import { Heading } from '../../utils/heading';
import { SkioldIconSpinner } from '../../utils/skiold-icon-spinner';
import { EditSlaughterDataLocation } from '../edit-slaughter-pig-data/edit-slaughter-data-location';
import '../slaughter-pigs-overview.scss';
import { SlaughterPigsDataTableModal } from './Slaughter-pigs-data-table-modal';

interface PropsFromParent {
	item: ISlaughterSiteDataDto;
	closeModal: () => void;
}

const printSlaughterPigsDataRowModal = async (tableRef: SkioldTableRef | undefined) => {
	if (tableRef) {
		PrintSlaughterDataOverviewDataRowTableData(tableRef.GetSortedData(), 'SlaughterHouseDataOverview.pdf');
	}
};
export const SlaughterDataRowModal: FC<PropsFromParent> = React.memo((props) => {
	const dispatch = useDispatch();
	const [dataState, dataStateChanged] = useState([] as SlaughterSiteDataDtoExtend[]);
	const onDataChanged = (data: SlaughterSiteDataDtoExtend[]) => {
		dataStateChanged(data);
	};

	const [modalState, setModalState] = useState<boolean>(false);

	const onDelete = () => {
		ShowConfirmAlert(localized('DoYouWantToDeleteSlaughterDatas')).then(shouldSave => {
			if (shouldSave && tableRef[0]) {
				const dataToSave = tableRef[0]
					.GetSortedData()
					.filter(ssdde => ssdde.isChecked)
					.map(
						ssdde =>
							SlaughterSiteDataDto.fromJS({
								...ssdde,
							}),
					) as ISlaughterSiteDataDto[];
				DeleteManySlaughterData(dataToSave)(dispatch);
			}
		});
	};

	const onCloseModal = () => {
		setModalState(false);
	};

	const openModal = () => {
		if (tableRef && tableRef[0] && tableRef[0].GetSortedData().find(ssdde => ssdde.isChecked)) {
			setModalState(true);
		}
	};

	const tableRef = useState<SkioldTableRef | undefined>(undefined);
	const setRef = (ref: SkioldTableRef | undefined) => {
		tableRef[1](ref);
	};
	return (
		<ViewWeb className="slaughterPigsDataOverview">
			<Heading text={localized('finisherData')}></Heading>
			<ViewWeb className="flexDirectionRow justifyContentSpaceBetween marginBottom10">
				<ViewWeb className="flexDirectionRow alignItemsCenter"></ViewWeb>
				<ViewWeb className="flexDirectionRow">
					<SkioldButton
						className="smallButton"
						theme="grey"
						title={localized('Close')}
						onPress={props.closeModal}
					/>
					{generateDataForOverviewTable(dataState)}
					<SkioldIconSpinner
						containerClassName="borderRightDotted"
						title={localized('moveMarked')}
						icon={MoveIcon}
						onPress={openModal}
					/>
					<SkioldIconSpinner
						containerClassName="borderRightDotted"
						title={localized('deleteMarked')}
						icon={DeleteIcon}
						onPress={onDelete}
					/>
					<SkioldIconSpinner
						itemFromParent={tableRef[0]}
						title={localized('PrintList')}
						icon={PrinterGreyIcon}
						onPress={printSlaughterPigsDataRowModal}
					/>
				</ViewWeb>
			</ViewWeb>
			<SlaughterPigsDataTableModal setRef={setRef} item={props.item} onDataChanged={onDataChanged} />
			<SkioldModal shouldCloseOnOverlayClick={true} isOpen={modalState} close={onCloseModal}>
				<EditSlaughterDataLocation
					item={
						tableRef && tableRef[0] ? tableRef[0].GetSortedData().find(ssdde => ssdde.isChecked) : undefined
					}
					checkedSlaughterData={
						tableRef && tableRef[0] ? tableRef[0].GetSortedData().filter(ssdde => ssdde.isChecked) : []
					}
					closeModal={onCloseModal}
				/>
			</SkioldModal>
		</ViewWeb>
	);
});

const generateDataForOverviewTable = memoize((data: SlaughterSiteDataDtoExtend[]) => {
	const tableData = generateSummaryDataForOverviewTableModal(data);
	return (
		<div className="summary-container">
			<div className="flexDirectionRowWithWidth">
				<div className="summaryTextStyle"> {localized('Count')}: </div>
				<div className="summaryTextStyle"> {data.length} </div>
			</div>
			<div className="flexDirectionRowWithWidth">
				<div className="summaryTextStyle"> {localized('meatPercentage')}: </div>
				<div className="summaryTextStyle"> {setTwoDecimalsAsNumber(tableData.meat)} </div>
			</div>
			<div className="flexDirectionRowWithWidth">
				<div className="summaryTextStyle"> {localized('avgPrice')}: </div>
				<div className="summaryTextStyle"> {setTwoDecimalsAsNumber(tableData.price)} </div>
			</div>
			<div className="flexDirectionRowWithWidth">
				<div className="summaryTextStyle"> {localized('avgWeight')}: </div>
				<div className="summaryTextStyle"> {setTwoDecimalsAsNumber(tableData.weight)} </div>
			</div>
		</div>
	);
});
