import React from 'react';
import { Option } from 'react-dropdown';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
	AnimalKind,
	IMated,
	IPregnancyEvent,
	IStemAnimal,
	Mated,
	MatingGrade,
	PregnancyState,
	SemenType,
	StemAnimal,
} from 'shared/api/api';
import { GetGradeOptions, getSemenTypeOptions } from 'shared/helpers/pregnancy-helper/generel-pregnancy-helpers';
import { mergeArraysHashmap } from 'shared/helpers/reducer-helpers';
import { findBoar } from 'shared/helpers/stemanimal-helper/stem-animal-input-helper';
import { GetSyncData as MatingBatchesGetSyncData } from 'shared/state/ducks/mating-batch/operations';
import {
	GetSyncData as PregnancyEventGetSyncData,
	SavePregnancyEvent,
} from 'shared/state/ducks/pregnancy-events/operations';
import { GetProcessEquipmentDataSyncData } from 'shared/state/ducks/process-equipment-data/operations';
import { GetSyncData as StemAnimalGetSyncData } from 'shared/state/ducks/stem-animals/operations';
import * as UnitsToPenOperations from 'shared/state/ducks/unit-to-pen/operations';
import { GetSyncData as ValidationSetupGetSyncData } from 'shared/state/ducks/validation-setup/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { createAndEditValidation } from 'web/web-helpers/pregnancies/register-validation-helper';
import { validateSowAnimalNumberIsNotDepartured } from 'web/web-helpers/pregnancies/web-prengancy-validation-settings';
import { SkioldDatePicker } from '../../skiold-components/skiold-date-picker/skiold-date-picker';
import { SkioldFormDropdown } from '../../skiold-components/skiold-dropdown/skiold-form-dropdown';
import { SkioldFormTextField } from '../../skiold-components/skiold-forms-wrapper/skiold-form-text';
import { FormRow } from '../../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { SkioldFormInput } from '../../skiold-components/skiold-input/skiold-form-input';
import SkioldPregnancyAnimalInput from '../../stem-animal/stem-animal-input/skiold-pregnancy-animal-input';
import exportFunctions from '../../stem-animal/stem-animal-input/stem-animal-test-helper';
import { Scroller } from '../../utils/scroller';
import './register-mating-components.scss';
import { RefType } from 'shared/helpers/ref-type';
import { DepartureTypes } from 'shared/state/models/departure-types';
import { GetSyncData as MoveEventGetSyncData } from 'shared/state/ducks/move-events/operations';
import {
	defaultInitials,
	memoizeDefaultInitialOption,
	memoizeInitialOptions,
} from 'shared/state/ducks/profile/selectors';
import * as ReactSelect from 'react-select';
import { selectActiveBoarsWithIdNumberOptions } from 'shared/state/ducks/stem-animals/selectors';
import { SingleValue } from 'react-select';
import BackArrowGrey from 'shared/assets/src-assets/png/back_arrow_bottom_grey.png';
import { SkioldImage } from '../../utils/svg/skiold-image';
import Control from 'react-select/dist/declarations/src/components/Control';
import { Colors } from 'shared/assets/root-assets/colors';
import { NucleusValidator } from 'shared/helpers/nucleus-management-helper/nucleus-validation-interface';
import { showAlert } from '../../skiold-alert/skiold-alert';
import { selectNucleusManagementOrAssignIdAccess } from 'shared/state/ducks/site/reducer';
import { SkioldModal } from '../../skiold-components/skiold-modal/skiold-modal';
import { SearchBoars } from './register-farrowing/search-boars';
import { SkioldDropdown } from '../../skiold-components/skiold-dropdown/skiold-dropdown';
import { SkioldTouchableOpacity } from '../../skiold-components/skiold-touchable-opacity';
interface PropsFromParent {
	returnMatedCallback: (mated: IMated, confirmAlert: boolean) => void;
	boarNumberChanged?: (string: string, boarId?: string) => void;
	boarNumber?: string;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		siteId: state.profile.active!.siteId,
		pregnancyEvents: mergeArraysHashmap(
			state.pregnancyEvents.entities,
			state.pregnancyEvents.departuredPregnancies,
		),
		matingBatches: state.matingBatches.entities,
		validationSetup: state.validationSetup.entity,
		departuredStemAnimals: state.stemAnimals.departuredAnimals,
		generalSettings: state.generalSettings.entity,
		initials: memoizeInitialOptions(state.profile.entities, state.profile.active),
		defaultInitial: memoizeDefaultInitialOption(state.profile.active),
		activeBoars: selectActiveBoarsWithIdNumberOptions(state.stemAnimals.entities),
		nucleusManagement: selectNucleusManagementOrAssignIdAccess(state),
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	stemAnimalGetSyncData: () => StemAnimalGetSyncData()(dispatch),
	pregnancyEventGetSyncData: () => PregnancyEventGetSyncData()(dispatch),
	savePregnancyEvent: (pregnancyEvent: IPregnancyEvent) => SavePregnancyEvent(pregnancyEvent)(dispatch),
	matingBatchesGetSyncData: () => MatingBatchesGetSyncData()(dispatch),
	getValidationSetup: () => ValidationSetupGetSyncData()(dispatch),
	GetProcessEquipmentDataSyncData: async () => (await GetProcessEquipmentDataSyncData())(dispatch),
	getunitsData: () => UnitsToPenOperations.GetSyncData()(dispatch),
	moveEventGetSyncData: () => MoveEventGetSyncData()(dispatch),
});

const DefaultGradeOption = { value: '', label: ' ' };

interface MatingComponentState {
	sow: IStemAnimal;
	grades: Option[];
	selectedGrade: Option;
	boarNumber: string;
	animalEvents: IPregnancyEvent[];
	prevEvent: IPregnancyEvent;
	matingBatchNumber: string | undefined;
	animalNumber: string | undefined;
	mated: IMated;
	toggleFocus: boolean;
	selectedInitial: Option;
	semenTypeOptions: Option[];
	selectedSemenType: Option;
	selectedBoar: Option;
	boar?: IStemAnimal;
	modalVisible: boolean;
}

export type MatingComponentProps = ReturnType<typeof mapStateToProps> &
	ReturnType<typeof mapDispatchToProps> &
	PropsFromParent;
export class RegisterMatingComponent extends React.PureComponent<MatingComponentProps, MatingComponentState> {
	private scrollerRef: Scroller | null | undefined;
	constructor(props: MatingComponentProps) {
		super(props);
		this.state = {
			sow: StemAnimal.fromJS({}),
			grades: GetGradeOptions,
			selectedGrade: DefaultGradeOption,
			boarNumber: '',
			animalEvents: [],
			prevEvent: {} as IPregnancyEvent,
			matingBatchNumber: exportFunctions.getMatingBatchNumber(new Date()),
			animalNumber: undefined,
			mated: Mated.fromJS({
				date: new Date(),
				state: PregnancyState.Mated,
				initials: props.defaultInitial.value,
			}),
			toggleFocus: true,
			selectedInitial: props.defaultInitial,
			semenTypeOptions: getSemenTypeOptions(),
			selectedSemenType: { label: ' ', value: '' } as Option,
			selectedBoar: { label: ' ', value: '' } as Option,
			modalVisible: false,
		};
		//When binding directly to OnChange method, this warning appears: "Binds are forbidden in JSX attributes due to their rendering performance impact"
		//Following should help.
		this.animalNumberChanged = this.animalNumberChanged.bind(this);
	}

	public componentDidMount() {
		this.props.stemAnimalGetSyncData();
		this.props.pregnancyEventGetSyncData();
		this.props.matingBatchesGetSyncData();
		this.props.getValidationSetup();
		this.props.getunitsData();
		this.props.GetProcessEquipmentDataSyncData();
		this.props.moveEventGetSyncData();
	}

	public animalNumberChanged(
		sow: IStemAnimal,
		animalEvents: IPregnancyEvent[],
		prevEvent: IPregnancyEvent,
		newPregnancyEvent: IPregnancyEvent,
	) {
		this.setState(
			{
				sow,
				animalEvents,
				prevEvent,
				mated: newPregnancyEvent as IMated,
				matingBatchNumber: exportFunctions.getMatingBatchNumber(newPregnancyEvent.date!),
			},
			() => this.props.returnMatedCallback(this.state.mated, false),
		);
	}

	public finishedDateChanged(newDate: Date) {
		this.setState(
			prevState => ({
				mated: { ...prevState.mated, date: newDate },
				matingBatchNumber: exportFunctions.getMatingBatchNumber(newDate),
			}),
			() => this.props.returnMatedCallback(this.state.mated, true),
		);
	}

	public gradeChanged(gradeOption: Option) {
		this.setState(
			prevState => ({
				mated: { ...prevState.mated, grade: gradeOption.value as MatingGrade },
				selectedGrade: gradeOption,
			}),
			() => this.props.returnMatedCallback(this.state.mated, false),
		);
	}

	public setBoar = (boarString: string) => {
		const boarExist = findBoar(boarString);
		if (boarExist) {
			const mating = { ...this.state.mated, boarId: boarExist.id } as Mated;
			this.setState(
				prevSate => ({ mated: mating, boarNumber: boarString, modalVisible: false }),
				() => {
					this.setBoarState(boarString, mating);
				},
			);
		} else {
			const mating = { ...this.state.mated, boarId: undefined } as Mated;
			this.setState(
				prevSate => ({ mated: mating, boarNumber: boarString, modalVisible: false }),
				() => {
					this.setBoarState(boarString, mating);
				},
			);
		}
	};

	private setBoarState(boarString: string, mated: Mated) {
		if (this.props.boarNumberChanged) {
			this.props.boarNumberChanged(boarString, mated.boarId);
			this.props.returnMatedCallback(mated, false);
		} else {
			this.props.returnMatedCallback(mated, false);
		}
	}

	private boarCleared = () => {
		this.setBoar('');
	};

	private closeModal = () => {
		this.setState({ modalVisible: false });
	};

	public render() {
		return (
			<SkioldModal isOpen={this.state.modalVisible} close={this.closeModal}>
				<SearchBoars
					close={this.closeModal}
					boarSelected={this.boarSelected}
					clear={this.boarCleared}
					matingDate={this.state.mated.date}
				/>
			</SkioldModal>
		);
	}

	public async validate() {
		if (!this.validateNucleus()) {
			return 'ignore';
		}

		let animalNumberCheck = validateSowAnimalNumberIsNotDepartured(
			this.state.sow,
			this.props.departuredStemAnimals,
			this.state.animalNumber,
		);
		if (animalNumberCheck !== 'ignore') {
			return animalNumberCheck;
		}

		const basicValidation = createAndEditValidation(
			this.props.validationSetup,
			this.props.generalSettings,
			this.state.mated,
			this.state.prevEvent,
			this.state.animalEvents,
			this.state.sow,
			this.state.matingBatchNumber,
		);

		if ((await basicValidation) !== '') {
			return basicValidation;
		}

		return '';
	}

	public validateNucleus = (shouldShowAlert: boolean = true) => {
		const nucleusValidator = new NucleusValidator(
			this.props.nucleusManagement,
			shouldShowAlert ? showAlert : () => {},
		);
		return nucleusValidator.validateMating(
			this.state.boar,
			this.state.sow,
			this.state.mated,
			this.props.generalSettings,
		);
	};

	// Called after successfull save to remove one-time values
	public async resetComponent() {
		this.setState({
			sow: StemAnimal.fromJS({ animalNumber: '' } as IStemAnimal),
			animalEvents: [],
			mated: Mated.fromJS({
				date: this.state.mated.date ? this.state.mated.date : new Date(),
				state: PregnancyState.Mated,
				initials: this.state.mated.initials,
				semenType: this.state.mated.semenType,
			}),
			animalNumber: '',
			toggleFocus: !this.state.toggleFocus,
		});

		if (this.scrollerRef) {
			await this.scrollerRef.scrollToTop();
		}
	}

	public getPregnancyAnimalNumberRow(isEditable: boolean = true): FormRow {
		return {
			name: localized('animalNumber'),
			component: (
				<SkioldPregnancyAnimalInput
					onChangeAnimalNumber={this.animalNumberChanged}
					text={this.state.animalNumber}
					pregnancyEvent={this.state.mated}
					onAnimalNumberChanged={(animalNumber: string | undefined) =>
						this.setState({ animalNumber }, () => this.props.returnMatedCallback(this.state.mated, false))
					}
					editable={isEditable}
					selectTextOnFocus={isEditable}
					toggleFocus={this.state.toggleFocus}
					className={`${
						this.state.sow && this.state.sow.departureType === DepartureTypes.departureTypeShouldDeparture
							? 'should-departure-list-color-with-margin-change'
							: ''
					}`}
				/>
			),
		};
	}

	public getDateRow(entranceDate?: Date): FormRow {
		return {
			name: localized('Date'),
			component: (
				<SkioldDatePicker
					onDateChanged={newDate => {
						this.finishedDateChanged(newDate);
					}}
					selectedDate={this.state.mated.date}
					theme={'dark'}
					color={'grey'}
					maxDate={entranceDate}
				/>
			),
		};
	}
	public entranceDateChanged(newDate: Date) {
		const mating = { ...this.state.mated, date: newDate } as Mated;
		this.setState(prevState => ({
			mated: mating,
			matingBatchNumber: exportFunctions.getMatingBatchNumber(newDate),
		}));
		setTimeout(() => this.props.returnMatedCallback(mating, false), 100);
	}
	public getBatchNumberRow(): FormRow {
		return {
			name: localized('batchNum'),
			component: <SkioldFormTextField>{this.state.matingBatchNumber}</SkioldFormTextField>,
		};
	}
	private boarSelected = (boar: IStemAnimal) => {
		if (boar.animalNumber) {
			// Set Semen type according to boar kind
			const semenType = boar.entranceKind === AnimalKind.Boar ? SemenType.Nat : SemenType.KS;
			const mating = { ...this.state.mated, boarId: boar.id, semenType } as Mated;
			this.setState(
				prevSate => ({
					mated: mating,
					boarNumber: boar.animalNumber!,
					selectedSemenType: { value: semenType, label: semenType },
					boar,
					modalVisible: false,
				}),
				() => this.setBoarState(boar.animalNumber!, mating),
			);
		}
	};

	public CustomOptionOrSingleValue = props => {
		const {
			innerProps,
			innerRef,
			isDisabled,
			isFocused,
			isSelected,
			cx,
			getStyles,
			data: { label, value },
			type,
			className,
		} = props;
		const optionOrSingleValue = type === 'option' ? 'option' : 'singleValue';
		return (
			<div
				style={getStyles(optionOrSingleValue, props)}
				className={cx(
					{
						option: true,
						'option--is-disabled': isDisabled,
						'option--is-focused': isFocused,
						'option--is-selected': isSelected,
					},
					className,
				)}
				ref={innerRef}
				{...innerProps}
			>
				{label}
			</div>
		);
	};

	public getIcon = () => {
		return <SkioldImage className="image-container" imageData={BackArrowGrey} width="26" height="18" />;
	};

	public styles = {
		control: css => ({
			...css,
			backgroundColor: 'transparent',
			border: 'none',
			boxShadow: 'none',
		}),
		indicatorSeparator: css => ({ ...css, backgroundColor: 'transparent' }),
		menu: css => ({ ...css, backgroundColor: Colors.grey }),
		option: css => ({
			...css,
			backgroundColor: Colors.grey,
			color: Colors.white,
		}),
		indicatorsContainer: css => ({ ...css, transform: 'rotate(270deg)' }),
		valueContainer: css => ({ ...css, padding: 0 }),
	};

	public openModal = () => {
		this.setState({ modalVisible: true });
	};

	public getBoarInputRowNucleus(): FormRow {
		const label = this.state.boarNumber || localized('TypeAnimalNumber');
		return {
			name: localized('boar'),
			component: (
				<>
					<SkioldFormInput
						placeholder={localized('TypeAnimalNumber')}
						onChangeText={this.setBoar}
						text={this.state.boarNumber}
					/>
					<SkioldTouchableOpacity onPress={this.openModal}>
						<SkioldImage className="arrow-image-container" imageData={BackArrowGrey} width="11" height="18" />
					</SkioldTouchableOpacity>
				</>
			),
		};
	}

	public getBoarInputRow(): FormRow {
		return {
			name: localized('boar'),
			component: <SkioldFormInput onChangeText={this.setBoar} text={this.state.boarNumber} />,
		};
	}

	public getGradePickerRow(): FormRow {
		return {
			name: localized('Grade'),
			component: (
				<SkioldFormDropdown
					items={this.state.grades}
					selectedValue={this.state.selectedGrade}
					onValueChanged={(gradeOption: Option) => {
						this.gradeChanged(gradeOption);
					}}
				/>
			),
		};
	}

	private onInitialChanged = (option: Option | undefined) => {
		if (option) {
			this.setState(
				prevState => ({
					mated: { ...prevState.mated, initials: option.value },
					selectedInitial: option,
				}),
				() => this.props.returnMatedCallback(this.state.mated, false),
			);
		}
	};

	public getInitialsRow(): FormRow {
		return {
			name: localized('Init'),
			component: (
				<SkioldFormDropdown
					items={this.props.initials}
					selectedValue={this.state.selectedInitial}
					onValueChanged={this.onInitialChanged}
				/>
			),
		};
	}

	private onSemenTypeChange = (option: Option | undefined) => {
		if (option) {
			this.setState(
				prevState => ({
					mated: { ...prevState.mated, semenType: option.value as SemenType },
					selectedSemenType: option,
				}),
				() => this.props.returnMatedCallback(this.state.mated, false),
			);
		}
	};

	public getSemenTypeRow(): FormRow {
		return {
			name: localized('Type'),
			component: (
				<SkioldFormDropdown
					items={this.state.semenTypeOptions}
					selectedValue={this.state.selectedSemenType}
					onValueChanged={this.onSemenTypeChange}
				/>
			),
		};
	}
}

export default connect<ReturnType<typeof mapStateToProps>, ReturnType<typeof mapDispatchToProps>, RefType, WebAppState>(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{ forwardRef: true },
)(RegisterMatingComponent);
