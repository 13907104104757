import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Farrowing, IFarrowing, IPregnancyEvent, PregnancyState } from 'shared/api/api';
import { ViewWeb } from 'web/view/components/utils/web-view';
import {
	DeleteLastPregnancyEvent,
	GetSyncData as PregnancyEventGetSyncData,
	SavePregnancyEvent,
} from 'shared/state/ducks/pregnancy-events/operations';
import { GetSyncData as StemAnimalGetSyncData } from 'shared/state/ducks/stem-animals/operations';
import { GetSyncData as ValidationSetupGetSyncData } from 'shared/state/ducks/validation-setup/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import PageContainer from 'web/view/components/page-container/page-container';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldModal } from 'web/view/components/skiold-components/skiold-modal/skiold-modal';
import { Heading } from 'web/view/components/utils/heading';
import SowCard from 'web/view/pages/sow-card/sow-card';
import RegisterFarrowingBase from './register-farrowing-base';
import './register-farrowing-work-list.scss';

interface PropsFromParent {
	animalId?: string;
	closeEditModal?: () => void;
}

const mapStateToProps = (state: WebAppState, props: any) => {
	return {
		siteId: state.profile.active!.siteId,
		pregnancyEvents: state.pregnancyEvents.entities,
		validationSetup: state.validationSetup.entity,
		animalIdNative: props.navigation ? props.navigation.getParam('animalId') : undefined,
		generalSetting: state.generalSettings.entity,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	getValidationSetup: () => ValidationSetupGetSyncData()(dispatch),
	stemAnimalGetSyncData: () => StemAnimalGetSyncData()(dispatch),
	pregnancyEventGetSyncData: () => PregnancyEventGetSyncData()(dispatch),
	savePregnancyEvent: (pregnancyEvent: IPregnancyEvent) => SavePregnancyEvent(pregnancyEvent)(dispatch),
	deleteLastPregnancyEvent: (pregEvent: IPregnancyEvent) => DeleteLastPregnancyEvent(pregEvent)(dispatch),
});

interface FarrowingState {
	farrowed: IFarrowing;
	sowCardModal: boolean;
}

export type FarrowingProps = ReturnType<typeof mapStateToProps> &
	ReturnType<typeof mapDispatchToProps> &
	PropsFromParent;
class RegisterFarrowingWorkList extends React.PureComponent<FarrowingProps, FarrowingState> {
	constructor(props: FarrowingProps) {
		super(props);
		this.state = {
			sowCardModal: false,
			farrowed: Farrowing.fromJS({
				date: new Date(),
				state: PregnancyState.Farrowing,
			}),
		};
	}

	public farrowingEventSave = () => {};

	public componentDidMount() {
		this.props.stemAnimalGetSyncData();
		this.props.pregnancyEventGetSyncData();
		this.props.getValidationSetup();
	}

	public render() {
		return (
			<PageContainer>
				<Heading text={localized('Farrowing')} />
				<ViewWeb className="register-farrowing-work-list">
					{this.renderContent()}
					<SkioldModal
						shouldCloseOnOverlayClick={true}
						isOpen={this.state.sowCardModal}
						close={() => this.closeModal()}
						justifyContent="center"
						maxWidth="calc(100% - 220px)"
					>
						<SowCard stemAnimalIdFromParent={this.props.animalId} close={() => this.closeModal()} />
					</SkioldModal>
				</ViewWeb>
			</PageContainer>
		);
	}

	public renderContent() {
		return (
			<ViewWeb>
				<ViewWeb>
					<ViewWeb className="buttons-outer-container">{this.renderButtons()}</ViewWeb>
					<RegisterFarrowingBase
						dateRow={true}
						genderRow={true}
						getNonEditablePregnancyAnimalNumberRow={true}
						getLocationRow={true}
						getBatchRow={true}
						getAliveRow={true}
						getDeadRow={true}
						getAbortionRow={true}
						getAliveFemaleRow={this.props.generalSetting.farrowingShowAliveFemales}
						getAliveMaleRow={this.props.generalSetting.farrowingShowAliveMales}
						getMoveRow={true}
						stableRow={true}
						penRow={true}
						save={(method: () => void) => (this.farrowingEventSave = method)}
						closeEditModal={this.props.closeEditModal ? () => this.props.closeEditModal!() : undefined}
						isEdit={false}
						farrowed={this.state.farrowed}
						focusAliveNum={true}
						stemAnimalId={
							this.props.animalIdNative !== undefined ? this.props.animalIdNative : this.props.animalId
						}
						getBreedRow={true}
						getAssignIdRow={true}
						getLitterWeightRow={true}
						getMumifiedRow={true}
						getLastMoveDateRow={true}
						getEarNotchRow={true}
					/>
				</ViewWeb>
			</ViewWeb>
		);
	}

	public async save() {
		await this.farrowingEventSave();
	}

	public showSowCard() {
		this.setState({ sowCardModal: true });
	}

	private renderButtons() {
		return (
			<ViewWeb className="buttons-inner-container">
				{this.props.closeEditModal ? (
					<SkioldButton
						title={localized('Close')}
						onPress={() => this.props.closeEditModal!()}
						theme="grey"
					/>
				) : (
					<ViewWeb />
				)}
				<SkioldButton title={localized('sowCard')} onPress={() => this.showSowCard()} theme="grey" />
				<SkioldButton title={localized('Save')} onPress={() => this.save()} />
			</ViewWeb>
		);
	}
	private closeModal() {
		this.setState({ sowCardModal: false });
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterFarrowingWorkList);
