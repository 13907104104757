import React, { FC, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ISowTaskSettings, SowTreatmentTask, TreatmentUnit } from 'shared/api/api';
import { memoizeActiveSows } from 'shared/helpers/memoize-getters/memoize-getters';
import {
	SowTaskListItem,
	generateSowTaskData,
	generateTreatmentPlans,
	getTreatmentColumnName,
	handleSowTaskWeightChange,
	showTreatmentColumn,
	validateSowTaskWorklist,
} from 'shared/helpers/work-list-helpers/sow-task-wl-helper/sow-task-wl-helper';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { SimpelEditSkioldTable } from 'web/view/components/skiold-components/skiold-table/simpel-skiold-table/simpel-edit-skiold-table';
import SkioldTableGrid from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { SowListConstants } from 'web/view/components/stem-animal/animal-lists/table-constants';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { SkioldButton } from '../../skiold-components/skiold-button/skiold-button';
import { saveManyTreatmentPlans } from 'shared/state/ducks/treatment-plan/operations';
import { ShowOkAlert } from '../../skiold-alert/skiold-alert';
import { SetCheckedCount, SetSowsCount } from 'shared/state/ducks/stem-animals/operations';

interface PropsFromParent {
	setting: ISowTaskSettings;
	ref?: any;
}

export const SowTaskWorkListOverview: FC<PropsFromParent> = React.memo(
	forwardRef(({ setting }, ref) => {
		const dispatch = useDispatch();
		const [data, setData] = useState<SowTaskListItem[]>([]);
		// Setup
		const sows = useSelector((state: WebAppState) => memoizeActiveSows(state.stemAnimals.entities));
		const pregnancies = useSelector((state: WebAppState) => state.pregnancyEvents.entities);
		const treatmentPlans = useSelector((state: WebAppState) => state.treatmentPlans.entities);
		const treatmentDefinitions = useSelector((state: WebAppState) => state.treatmentDefinitions.entities);
		const treatments = useSelector((state: WebAppState) => state.treatments.entities);
		const siteId = useSelector((state: WebAppState) => state.profile.active && state.profile.active.siteId);
		const profileId = useSelector((state: WebAppState) => state.profile.active?.id);
		const setDataCallback = useCallback(
			(changedData: SowTaskListItem[]) => {
				dispatch(SetCheckedCount(changedData.filter(i => i.isChecked).length, true));
				setData(changedData);
			},
			[dispatch],
		);
		const reset = useCallback(() => {
			const generatedData = generateSowTaskData(
				setting,
				pregnancies,
				sows,
				treatmentPlans,
				treatments,
				treatmentDefinitions,
			);
			setDataCallback(generatedData);
			dispatch(SetSowsCount(generatedData.length));
		}, [setting, pregnancies, sows, treatmentPlans, treatments, setDataCallback, dispatch, treatmentDefinitions]);

		const save = useCallback(() => {
			if (siteId && profileId && validateSowTaskWorklist(data, ShowOkAlert)) {
				const dataToSave = generateTreatmentPlans(data, treatmentDefinitions, profileId, siteId);
				dispatch(saveManyTreatmentPlans(dataToSave.treatmentPlans, dataToSave.treatments));
			}
		}, [siteId, profileId, data, treatmentDefinitions, dispatch]);
		useImperativeHandle(
			ref,
			() => ({
				save() {
					save();
				},
				reset() {
					reset();
				},
			}),
			[save, reset],
		);

		const generateColumns = useMemo(() => {
			return [
				{
					name: 'days',
					title: localized('days'),
				},
				{
					name: 'animalNumber',
					title: localized('animalNumber'),
				},
				{
					name: 'weight',
					title: localized('Weight'),
				},
				{
					name: 'ml1',
					title: localized('ml'),
					hide: showTreatmentColumn(setting, 1),
				},
				{
					name: 'ml2',
					title: localized('ml'),
					hide: showTreatmentColumn(setting, 2),
				},
				{
					name: 'ml3',
					title: localized('ml'),
					hide: showTreatmentColumn(setting, 3),
				},
			].filter(e => !e.hide);
		}, [setting]);

		const generateColumnExtes = useMemo(() => {
			return [
				{
					columnName: 'days',
					width: SowListConstants.dateWidth,
					editingEnabled: false,
				},
				{
					columnName: 'animalNumber',
					width: SowListConstants.dateWidth,
					editingEnabled: false,
				},
				{
					columnName: 'weight',
					width: SowListConstants.dateWidth,
					createRowChange: handleSowTaskWeightChange,
				},
				{
					columnName: 'ml1',
					width: 150,
					editingEnabled: false,
					hide: showTreatmentColumn(setting, 1),
				},
				{
					columnName: 'ml2',
					width: 150,
					editingEnabled: false,
					hide: showTreatmentColumn(setting, 2),
				},
				{
					columnName: 'ml3',
					width: 150,
					editingEnabled: false,
					hide: showTreatmentColumn(setting, 3),
				},
			].filter(e => !e.hide);
		}, [setting]);

		const columnBands = useMemo(() => {
			return [
				{ title: getTreatmentColumnName(setting, 1), children: [{ columnName: 'ml1' }] },
				{ title: getTreatmentColumnName(setting, 2), children: [{ columnName: 'ml2' }] },
				{ title: getTreatmentColumnName(setting, 3), children: [{ columnName: 'ml3' }] },
			];
		}, [setting]);

		useEffect(() => {
			const generatedData = generateSowTaskData(
				setting,
				pregnancies,
				sows,
				treatmentPlans,
				treatments,
				treatmentDefinitions,
			);
			setDataCallback(generatedData);
			dispatch(SetSowsCount(generatedData.length));
		}, [setting, pregnancies, sows, treatmentPlans, treatments, setDataCallback, dispatch, treatmentDefinitions]);

		return (
			<ViewWeb>
				{data.length > 0 && (
					<SimpelEditSkioldTable
						tableKey="SowTaskTable1"
						getRowId={row => row.id}
						columns={generateColumns}
						columnExtensions={generateColumnExtes}
						rows={data}
						columnBands={columnBands}
						setRows={setDataCallback}
					></SimpelEditSkioldTable>
				)}
			</ViewWeb>
		);
	}),
);
