import React from "react";
import { IntegerChanged } from "shared/helpers/number-helper";
import { SkioldInput } from "../skiold-input/skiold-input";
import './skiold-integer-input';

export interface PropsFromParent {
    onChangeNumber: (newText: number | undefined, itemFromParent?: any) => void;
    itemFromParent?: any
    text?: number | undefined;
    IsOnlyPositive?: boolean | undefined;
    style?: any;
    editable?: boolean;
    onBlur?: () => void;
    className?: string;
}

interface State {
    text: string | undefined;
    parentValue: number | undefined;
}

export class SkioldBoldIntegerInput extends React.PureComponent<PropsFromParent, State> {
    public static getDerivedStateFromProps(nextProps: PropsFromParent, prevState: State) {
        return {
            parentValue: nextProps.text,
            text: nextProps.text != null ? nextProps.text.toString() : ''
        };
    }
    constructor(props: PropsFromParent) {
        super(props);
        this.state = {
            text: this.props.text != null ? this.props.text.toString() : '',
            parentValue: this.props.text
        };
    }

    public render() {
        return (
            <SkioldInput theme={'light'} text={this.state.text} onChangeText={this.onTextChanged} className={`skiold-bold-integer-input ${this.props.className}`}/>
            // <TextInput
            //     onChangeText={newText => this.onTextChanged(newText)}
            //     keyboardType={
            //         Platform.OS === 'ios' && this.props.IsOnlyPositive !== true ? 'numbers-and-punctuation' : 'numeric'
            //     }
            //     editable={this.props.editable === undefined ? true : this.props.editable}
            //     value={this.state.text}
            //     style={this.textInputStyle}
            //     onBlur={this.props.onBlur}
            //     //underlineColorAndroid="transparent"
            //     selectTextOnFocus={true}
            //     {...props}
            // />
        );
    }

    private onTextChanged = (newText: string) => {
        let text = newText.replace(/(?!^)-|(?!^)\./g, ''); // ensures at most one -
        text = text.replace(/,| |\.[0-9]/g, ''); // replaces any spaces, dots and comma with empty string

        this.setState({ text });

        if (!isNaN(parseInt(text, 10)) || text === '') {
            IntegerChanged(text, valueToSet => {
                this.props.onChangeNumber(valueToSet, this.props.itemFromParent);
            });
        }
    }
}
