import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { FarrowingListSetting, FarrowingListTypes, IFarrowingListSetting, WorkListType } from 'shared/api/api';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { deepCopy } from 'shared/helpers/general-helpers';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { SaveWorkListSetting } from 'shared/state/ducks/work-list-settings/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { SkioldButton } from '../../skiold-components/skiold-button/skiold-button';
import { SkioldFormsWrapper } from '../../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from '../../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { SkioldFormIntegerInput } from '../../skiold-components/skiold-integer-input/skiold-form-integer-input';
import { getAvailablityRow, getSkioldInputRow } from '../work-list-helper';
import '../list-setup.scss';
import { Option } from 'react-dropdown';
import { SkioldFormDropdown } from '../../skiold-components/skiold-dropdown/skiold-form-dropdown';
import { SkioldCheckbox } from '../../skiold-components/skiold-checkbox/skiold-checkbox';

interface PropsFromParent {
	workListType: WorkListType;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		farrowingListSetting: state.workListSettings.entities.find(wl => wl.type === WorkListType.FarrowingListSetting),
		siteId: state.profile.active!.siteId,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	saveWorkListSetting: (farrowingListSetting: IFarrowingListSetting) =>
		SaveWorkListSetting(farrowingListSetting)(dispatch),
});

export interface State {
	farrowingListSetting: IFarrowingListSetting;
	options: Option[];
	selectedOption: Option;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

class FarrowingListSetup extends React.PureComponent<Props, State> {
	public static getDerivedStateFromProps(nextProps: Props, prevState: State): Partial<State> {
		if (!prevState.farrowingListSetting.id && nextProps.farrowingListSetting) {
			return {
				farrowingListSetting: { ...prevState.farrowingListSetting, ...nextProps.farrowingListSetting },
			};
		}
		return {};
	}

	constructor(props: Props) {
		super(props);
		this.state = {
			options: [
				{ label: localized(FarrowingListTypes.Location), value: FarrowingListTypes.Location },

				{
					label: localized(FarrowingListTypes.PregnancyDays),
					value: FarrowingListTypes.PregnancyDays,
				},
			],
			selectedOption: { label: '', value: '' },
			farrowingListSetting: FarrowingListSetting.fromJS({ type: WorkListType.FarrowingListSetting }),
		};
	}

	public componentDidMount() {
		let initSetting = {};
		if (this.state.farrowingListSetting.selectedType !== undefined) {
			initSetting['selectedOption'] = {
				label: localized(this.state.farrowingListSetting.selectedType),
				value: this.state.farrowingListSetting.selectedType,
			};
		}
		this.setState(initSetting);
	}

	public toggleShowOnWeb() {
		this.setState(prevState => ({
			farrowingListSetting: {
				...prevState.farrowingListSetting,
				showOnWeb: !this.state.farrowingListSetting.showOnWeb,
			},
		}));
	}

	public toggleShowOnApp() {
		this.setState(prevState => ({
			farrowingListSetting: {
				...prevState.farrowingListSetting,
				showOnApp: !this.state.farrowingListSetting.showOnApp,
			},
		}));
	}

	public farrowingListSetupChanged(setup: IFarrowingListSetting) {
		this.setState({
			farrowingListSetting: setup,
		});
	}

	public cycleDaysChanged(cycleDays: number | undefined) {
		this.setState(prevState => ({
			farrowingListSetting: { ...prevState.farrowingListSetting, cycleDays } as IFarrowingListSetting,
		}));
	}

	private animalToListChanged = (option: Option) => {
		this.setState(prevState => ({
			farrowingListSetting: {
				...prevState.farrowingListSetting,
				selectedType: option.value as FarrowingListTypes,
			},
			selectedOption: option,
		}));
	};

	public getUseLocationRow(): FormRow {
		return {
			name: localized('location'),
			component: (
				<SkioldCheckbox
					onClick={this.toggleUseLocation}
					isChecked={this.state.farrowingListSetting.usesLocation ? true : false}
				/>
			),
		};
	}
	public toggleUseLocation = () => {
		let farrowingListSetting = { ...this.state.farrowingListSetting };
		farrowingListSetting.usesLocation = farrowingListSetting.usesLocation ? false : true;
		this.setState({ farrowingListSetting });
	};

	public render() {
		return (
			<ViewWeb className="list-setup">
				<ViewWeb className="view-container">
					<TextWeb className="text-style">{localized('Setup')}</TextWeb>
					<SkioldFormsWrapper formRows={this.getFormRows()} containerClassName="wrapper-container" />
					{this.renderButtons()}
				</ViewWeb>
			</ViewWeb>
		);
	}

	private async save() {
		if (!this.validate(this.state.farrowingListSetting)) {
			return;
		}

		let farrowingListSetup = deepCopy(this.state.farrowingListSetting);
		farrowingListSetup.siteId = this.props.siteId;

		this.props.saveWorkListSetting(farrowingListSetup);
	}

	private getFormRows() {
		let formRows = new Array<FormRow>();

		formRows.push(this.getBuildingHeader());
		formRows.push(
			getSkioldInputRow(
				setup => this.farrowingListSetupChanged(setup),
				this.state.farrowingListSetting,
				'name',
				localized('name'),
			),
		);
		formRows.push(this.getAnimalToListRow());
		if (this.state.farrowingListSetting.selectedType === FarrowingListTypes.PregnancyDays) {
			formRows.push(this.getCycleDaysRow());
		}
		formRows.push(this.getUseLocationRow());
		formRows.push(
			getAvailablityRow(
				() => this.toggleShowOnWeb(),
				() => this.toggleShowOnApp(),
				this.state.farrowingListSetting.showOnWeb!,
				this.state.farrowingListSetting.showOnApp!,
			),
		);

		return formRows;
	}

	private validate(listSetting: IFarrowingListSetting) {
		if (!listSetting.cycleDays || (listSetting.cycleDays && listSetting.cycleDays < 0)) {
			this.showErrorMessage(localized(ExceptionMessage.VALIDATION_ERROR_CYCLEDAYS_NOT_SET));
			return false;
		}

		return true;
	}

	private showErrorMessage(errorMessage: string) {
		alert(errorMessage);
	}

	private getBuildingHeader(): FormRow {
		return {
			header: localized(this.props.workListType),
		};
	}

	private getCycleDaysRow(): FormRow {
		return {
			name: localized('cycleDays'),
			component: (
				<SkioldFormIntegerInput
					onChangeNumber={newText => this.cycleDaysChanged(newText)}
					text={this.state.farrowingListSetting.cycleDays}
				/>
			),
		};
	}

	private getAnimalToListRow(): FormRow {
		return {
			name: localized('chooseAnimalToList'),
			component: (
				<SkioldFormDropdown
					items={this.state.options}
					selectedValue={this.state.selectedOption}
					onValueChanged={this.animalToListChanged}
				/>
			),
		};
	}

	private renderButtons() {
		return (
			<ViewWeb className="view-button-container">
				<SkioldButton title={localized('Save')} onPress={() => this.save()} />
			</ViewWeb>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(FarrowingListSetup);
