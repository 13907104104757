import memoize from 'memoize-one';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IProcessEquipment, IProcessEquipmentData, ProcessUnit } from 'shared/api/api';
import { NaturalSort } from 'shared/helpers/natural-sort';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import PageContainer from '../page-container/page-container';
import { SkioldButton } from '../skiold-components/skiold-button/skiold-button';
import SkioldTableGrid, {
	SkioldTableGrid as SkioldTableRef,
} from '../skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { Heading } from '../utils/heading';
import { ViewWeb } from '../utils/web-view';
import './unit-table.scss';

const mapStateToProps = (state: WebAppState) => {
	return {
		processEquipmentData: state.processEquipmentData.entities,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({});

export class UnitTableItem {
	public unit: string | undefined;
	public key: string | undefined;
}
interface PropsFromParent {
	processEquipment: IProcessEquipment | undefined;
	closeModal?: () => void;
}
type UnitsProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

interface UnitsState {
	columns: any[];
	columnExtension: any[];
	units: UnitTableItem[];
}

export class UnitsTable extends React.PureComponent<UnitsProps, UnitsState> {
	public SkioldTableRef: SkioldTableRef | undefined;
	private generateData = memoize((processEquipmentData, processEquipment) =>
		UnitsTable.getData(processEquipmentData, processEquipment),
	);
	constructor(props: UnitsProps) {
		super(props);

		this.state = {
			columns: this.generateColumns(),
			columnExtension: this.generateColumnsExtensions(),
			units: this.generateData(this.props.processEquipmentData, this.props.processEquipment),
		};
	}

	public static getDerivedStateFromProps(nextProps: UnitsProps, prevState: UnitsState): Partial<UnitsState> {
		let dataFromProps = UnitsTable.getData(nextProps.processEquipmentData, nextProps.processEquipment);
		return {
			units: dataFromProps,
		};
	}

	public componentDidUpdate(prevProps: UnitsProps) {
		if (prevProps.processEquipment !== this.props.processEquipment) {
			this.setState({
				units: this.generateData(this.props.processEquipmentData, this.props.processEquipment),
			});
		}
	}
	private static getData(dataFromProps: IProcessEquipmentData[], processEquipment: IProcessEquipment | undefined) {
		let data: UnitTableItem[] = [];
		if (dataFromProps && processEquipment) {
			dataFromProps.forEach(item => {
				if (item.isFromImport && item.equipmentType === processEquipment.equipment && item.unitCodes) {
					item.unitCodes.forEach(valveData => {
						if (valveData.unit) {
							data.push({ key: valveData.unitGroupId, unit: valveData.unit });
						}
					});
				}
			});
		}
		return data.sort((a, b) => NaturalSort(a.unit, b.unit));
	}

	public render() {
		return (
			<PageContainer className="unit-table-page-container-style">
				<Heading text={localized('Units')} />
				<ViewWeb className={'margin-top'}>
					<SkioldTableGrid
						tableKey={'unitListTable'}
						columns={this.generateColumns()}
						ref={this.setRef}
						data={this.state.units}
						ColumnExtensions={this.state.columnExtension}
						showPagination={true}
					/>
				</ViewWeb>
				<ViewWeb className="buttons-container">
					<SkioldButton title={localized('Close')} onPress={this.closeModal} theme="grey" />
				</ViewWeb>
			</PageContainer>
		);
	}

	private closeModal = () => {
		if (this.props.closeModal) {
			this.props.closeModal();
		}
	};

	public setRef = (s: any) => {
		if (s) {
			this.SkioldTableRef = s;
		}
	};

	public generateColumnsExtensions() {
		const columnExtensions: any = [];

		return columnExtensions;
	}

	public generateColumns = () => {
		let columns: any = [
			{
				name: 'unit',
				title:
					this.props.processEquipment && this.props.processEquipment.unit
						? this.props.processEquipment.unit === ProcessUnit.Valve
							? localized('Valves')
							: localized('Stations')
						: localized('Unit'),
				sortable: false,
				getCellValue: (tableData: UnitTableItem) => tableData.unit,
			},
		];
		return columns;
	};
}
export default connect(mapStateToProps, mapDispatchToProps)(UnitsTable);
