import { Sorting } from '@devexpress/dx-react-grid';
import memoize from 'memoize-one';
import React from 'react';
import { connect } from 'react-redux';
import TreatmentIcon from 'shared/assets/src-assets/png/behandling_grey.png';
import { exactStartsWithMethodGrid, rangeFilterMethodGrid } from 'shared/helpers/general-helpers';
import { NaturalSort } from 'shared/helpers/natural-sort';
import { checkSaveWorkListStatus } from 'shared/helpers/work-list-helpers/general-work-list-helper';
import { genereateTasksFarrowingTableData } from 'shared/helpers/work-list-helpers/tasks-in-farrowing-stable-helper/tasks-in-farrowing-stable-helper';
import {
	FarrowingTaskTreatmentColumn,
	TasksInFarrowingStableItem,
	TasksInFarrowingStableMapDispatchToProps,
	TasksInFarrowingStableMapStateToProps,
	TasksInFarrowingStableProps,
	TasksInFarrowingStableState,
} from 'shared/helpers/work-list-helpers/tasks-in-farrowing-stable-helper/tasks-in-farrowing-stable-item';
import { localized } from 'shared/state/i18n/i18n';
import SkioldTableGrid, {
	SkioldTableGrid as SkioldTableRef,
} from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { ShowConfirmAlert } from '../../skiold-alert/skiold-alert';
import { SkioldCheckbox } from '../../skiold-components/skiold-checkbox/skiold-checkbox';
import { SkioldIntegerInput } from '../../skiold-components/skiold-integer-input/skiold-integer-input';
import { SkioldModal } from '../../skiold-components/skiold-modal/skiold-modal';
import { GroupedHeader } from '../../skiold-components/skiold-table/skiold-table-grid/skiold-table-grid-grouped-header';
import { SkioldTouchableOpacity } from '../../skiold-components/skiold-touchable-opacity';
import { TasksInFarrowingStableListConstants } from '../../stem-animal/animal-lists/table-constants';
import CreateTreatmentPlan from '../../treatments/treatment-plan/create-treatment-plan';
import { SkioldImage } from '../../utils/svg/skiold-image';
import { getAnimalNumberText, getStemAnimalNumberColor } from '../work-list-helper';
import './tasks-in-farrowing-stable-table.scss';
import '../list-setup.scss';

export class TasksInFarrowingStableTable extends React.PureComponent<
	TasksInFarrowingStableProps,
	TasksInFarrowingStableState
> {
	public static defaultProps: Partial<TasksInFarrowingStableProps> = {};
	public SkioldTableRef: SkioldTableRef | undefined;

	//Only recalculate data when one of the inputs change
	private generateData = memoize((animals, pregnancies, treatmentPlans, worklistSetting) =>
		genereateTasksFarrowingTableData(this.props, this.state),
	);
	private defaultSorting: Sorting[] = [{ columnName: 'cycleDays', direction: 'desc' }];
	constructor(props: TasksInFarrowingStableProps) {
		super(props);
		this.state = {
			columns: this.generateColumns(),
			loading: false,
			workListData: this.generateData(
				this.props.stemAnimals,
				this.props.pregnancyEvents,
				this.props.treatmentPlans,
				this.props.workListSetting,
			).sort((a, b) => NaturalSort(b.cycleDays, a.cycleDays)),
			columnExte: this.generateColumnsExtensions(),
			createTreatmentModal: false,
			stemAnimalIdToEdit: '',
			commitAll: false,
		};
	}

	public ResetWorkList() {
		let data = this.generateData(
			this.props.stemAnimals,
			this.props.pregnancyEvents,
			this.props.treatmentPlans,
			this.props.workListSetting,
		);
		const columns = this.generateColumns();
		this.setState({ workListData: data, columns });
	}
	public componentDidMount() {
		if (this.SkioldTableRef) {
			this.props.setSowsCount(this.SkioldTableRef.GetSortedData().length);
		}
		this.props.pregnancyEventGetSyncData();
		this.props.stemAnimalGetSyncData();
		this.props.diagnoseGetSyncData();
		this.props.treatmentDefinitionGetSyncData();
		this.props.treatmentGetSyncData();
		this.props.treatmentPlanGetSyncData();
		this.props.workListToTreatmentPlanGetSyncData();
	}
	public componentDidUpdate() {
		if (this.SkioldTableRef) {
			this.props.setSowsCount(this.SkioldTableRef.GetSortedData().length);
		}
	}

	public getWorkListSetting() {
		const { workListSetting } = this.props;
		return workListSetting;
	}

	public render() {
		return (
			<ViewWeb className="tasks-in-farrowing-stable-table">
				<SkioldTableGrid
					tableKey={
						(this.props.workListSetting.type
							? this.props.workListSetting.type.toString()
							: 'tasksInFarrowingStableWorkListTable') + this.state.columnExte.length.toString()
					}
					columns={this.state.columns}
					className={'work-list-table'}
					ref={this.setRef}
					data={this.state.workListData}
					ColumnExtensions={this.state.columnExte}
					sortHeaderId={this.defaultSorting}
					showPagination={true}
					groupedColumns={this.groupedColumns()}
				/>
				<SkioldModal padding="0" isOpen={this.state.createTreatmentModal} close={this.closeModal}>
					<CreateTreatmentPlan
						close={this.closeModal}
						stemAnimalIdFromParent={this.state.stemAnimalIdToEdit}
					/>
				</SkioldModal>
			</ViewWeb>
		);
	}

	public async CheckSavePregnancyEventListsStatus() {
		if (await checkSaveWorkListStatus(this.state.workListData, ShowConfirmAlert)) {
			await this.SaveWorklist();
		}
		return true;
	}

	public setRef = (s: any) => {
		if (s) {
			this.SkioldTableRef = s;
			this.forceUpdate();
		}
	};

	public generateColumnsExtensions() {
		const { workListSetting } = this.props;
		const columnExtensions: any = [
			{
				columnName: 'cycleDays',
				width: TasksInFarrowingStableListConstants.cycleDaysWidth,
			},
			{
				columnName: 'animalNumber',
				width: TasksInFarrowingStableListConstants.animalNrWidth,
			},
		];
		let numberOfSubTasks = 0;
		workListSetting.farrowingTaskTreatments!.forEach(subTask => {
			if (subTask.treatmentDefinitionId) {
				numberOfSubTasks = numberOfSubTasks + 1;
				columnExtensions.push({
					columnName: 'pcs' + subTask.subTaskNumber,
					width: TasksInFarrowingStableListConstants.pcsWidth,
					filteringEnabled: false,
				});
				columnExtensions.push({
					columnName: 'amount' + subTask.subTaskNumber,
					width: TasksInFarrowingStableListConstants.amountWidth,
					filteringEnabled: false,
				});
			}
		});

		columnExtensions.push({ columnName: 'completed', width: TasksInFarrowingStableListConstants.completedWidth });
		columnExtensions.push({
			columnName: 'createTreatment',
			width:
				numberOfSubTasks === 1
					? TasksInFarrowingStableListConstants.createTreatmentWidth + 100
					: TasksInFarrowingStableListConstants.createTreatmentWidth,
			filteringEnabled: false,
		});

		return columnExtensions;
	}

	public generateCommitAllCheckBox = () => {
		return (
			<ViewWeb className="checkbox-view-filter-style">
				<SkioldCheckbox isChecked={this.state.commitAll} className="checkbox-style" onClick={this.commitAll} />
			</ViewWeb>
		);
	};

	public commitAll = () => {
		const commitAll = !this.state.commitAll;
		this.setState(prevState => {
			const workListData = [...prevState.workListData];
			for (let index = 0; index < workListData.length; index++) {
				workListData[index] = { ...workListData[index], isChecked: commitAll };
			}
			this.props.SetCheckedCount(
				workListData.reduce((a, b) => a + (b && b.isChecked ? 1 : 0), 0),
				true,
			);
			return { workListData, commitAll };
		});
	};

	public SaveWorklist = () => {
		const isCheckedItems = this.state.workListData.filter(item => item.isChecked);
		const animalIds = isCheckedItems.map(item => item.stemAnimalId);
		this.props.saveMultipleTreatmentPlansByWorkList(this.props.workListSetting, isCheckedItems);
		const items = this.state.workListData.filter(data => !animalIds.includes(data.stemAnimalId));
		this.setState({ workListData: items });
	};

	private groupedColumns = () => {
		const groupedHeader: GroupedHeader[] = [];
		const { workListSetting } = this.props;
		if (workListSetting && workListSetting.farrowingTaskTreatments) {
			workListSetting.farrowingTaskTreatments!.forEach(subTask => {
				if (subTask.treatmentDefinitionId) {
					groupedHeader.push({
						title: subTask.columnText!,
						children: [
							{ columnName: 'pcs' + subTask.subTaskNumber },
							{ columnName: 'amount' + subTask.subTaskNumber },
						],
					});
				}
			});
		}
		return groupedHeader;
	};

	private getValueByKey(
		key: keyof FarrowingTaskTreatmentColumn,
		item: TasksInFarrowingStableItem,
		taskNumber: number,
	) {
		const column = item.farrowingTaskTreatments!.find(col => col.subTaskNumber === taskNumber);
		if (column && !column.used) {
			if (key === 'pcs') {
				return (
					<SkioldIntegerInput
						className="alignCenter"
						text={column[key]}
						onChangeNumber={a => {
							let data = [...this.state.workListData];
							const itemIndex = data.findIndex(a => a.stemAnimalId === item.stemAnimalId);
							if (itemIndex >= 0) {
								let farrowingTaskTreatments = [...data[itemIndex].farrowingTaskTreatments!];
								const farrowingTaskTreatmentIndex = farrowingTaskTreatments.findIndex(
									taskTreatment => taskTreatment.subTaskNumber === taskNumber,
								);
								let itemToModify = { ...farrowingTaskTreatments[farrowingTaskTreatmentIndex] };
								itemToModify.pcs = a;
								farrowingTaskTreatments[farrowingTaskTreatmentIndex] = itemToModify;
								data[itemIndex].farrowingTaskTreatments = farrowingTaskTreatments;
								this.setState({ workListData: data });
							}
						}}
					/>
				);
			}
			return column[key];
		}
		return '';
	}

	private animalNumberFilter = (value: any, filterValue: any, row: TasksInFarrowingStableItem) => {
		return (
			row.animalNumber &&
			String(localized(row.animalNumber).toLocaleLowerCase()).startsWith(filterValue.value.toLocaleLowerCase())
		);
	};

	private generateColumns() {
		const { workListSetting } = this.props;
		const columns: any = [
			{
				name: 'cycleDays',
				title: localized('Days'),
				headerClassName: 'merged-header',
				filterFunction: rangeFilterMethodGrid,
				getCellValue: (tableItem: TasksInFarrowingStableItem) =>
					tableItem.cycleDays && !isNaN(tableItem.cycleDays) ? tableItem.cycleDays : '',
			},
			{
				name: 'animalNumber',
				headerClassName: 'merged-header',
				title: localized('animalNumber'),
				filterFunction: this.animalNumberFilter,
				className: getStemAnimalNumberColor,
				getCellValue: getAnimalNumberText,
			},
		];

		workListSetting.farrowingTaskTreatments!.forEach(subTask => {
			if (subTask.treatmentDefinitionId) {
				columns.push({
					name: 'pcs' + subTask.subTaskNumber,
					title: localized('pcs'),
					sortable: false,
					filterable: false,
					getCellValue: (tableItem: TasksInFarrowingStableItem) =>
						this.getValueByKey('pcs', tableItem, subTask.subTaskNumber!),
				});
				columns.push({
					name: 'amount' + subTask.subTaskNumber,
					title: 'ml',
					sortable: false,
					filterable: false,
					getCellValue: (tableItem: TasksInFarrowingStableItem) =>
						this.getValueByKey('amount', tableItem, subTask.subTaskNumber!),
				});
			}
		});

		columns.push({
			name: 'completed',
			title: localized('completed'),
			headerClassName: 'merged-header',
			sortable: false,
			filter: this.generateCommitAllCheckBox,
			getCellValue: (tableItem: TasksInFarrowingStableItem) => (
				<ViewWeb className="Checkbox-view-style">
					<SkioldCheckbox
						isChecked={tableItem.isChecked!}
						className="checkbox-style"
						onClick={() => {
							this.commitedClicked(tableItem);
						}}
					/>
				</ViewWeb>
			),
		});

		columns.push({
			name: 'createTreatment',
			title: ' ',
			sortable: false,
			filterable: false,
			getCellValue: (row: TasksInFarrowingStableItem) => (
				<SkioldTouchableOpacity onPress={() => this.createTreatmentPlan(row.stemAnimalId!)}>
					<SkioldImage
						width={TasksInFarrowingStableListConstants.iconSVGWidth}
						height={TasksInFarrowingStableListConstants.iconSVGWidth}
						imageData={TreatmentIcon}
					/>
				</SkioldTouchableOpacity>
			),
		});

		return columns;
	}

	private createTreatmentPlan = (stemAnimalId: string) => {
		this.setState({ createTreatmentModal: true, stemAnimalIdToEdit: stemAnimalId });
	};

	private closeModal = () => {
		this.setState({ createTreatmentModal: false, stemAnimalIdToEdit: '' });
	};

	private commitedClicked = (tableItem: TasksInFarrowingStableItem) => {
		const dataIndex = this.state.workListData.findIndex(a => a.stemAnimalId === tableItem.stemAnimalId);

		this.setState(prevState => {
			const workListData = [...prevState.workListData];
			workListData[dataIndex] = { ...workListData[dataIndex], isChecked: !tableItem.isChecked };
			this.props.SetCheckedCount(workListData[dataIndex].isChecked ? 1 : -1);
			return { workListData, commitAll: false };
		});
	};
}

export default connect(TasksInFarrowingStableMapStateToProps, TasksInFarrowingStableMapDispatchToProps, null, {
	forwardRef: true,
})(TasksInFarrowingStableTable) as any;
