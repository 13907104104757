import {
	ITasksInFarrowingBase,
	ITasksInFarrowingStableSetting,
	TasksInFarrowingStableSetting,
	TasksInFarrowingStableSetting2,
	WorkListType,
} from 'shared/api/api';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { localized } from 'shared/state/i18n/i18n';
import { showAlert } from '../../skiold-alert/skiold-alert';

export function validateTasksInFarrowing(listSetting: ITasksInFarrowingBase) {
	let hasOneChosenTreatmentDef = false;
	if (!listSetting.siteId) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_SITE_NOT_SET));
		return false;
	}

	const setting = listSetting;
	if (setting.nursingDaysFrom === undefined || setting.nursingDaysFrom < 0) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_NURSING_FROM_IS_EMPTY_OR_NEGATIVE));
		return false;
	}
	if (setting.nursingDaysTo === undefined || setting.nursingDaysTo < setting.nursingDaysFrom) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_NURSING_TO_IS_EMPTY_OR_NEGATIVE));
		return false;
	}


	for (let subTask of listSetting.farrowingTaskTreatments!) {
		if (subTask.treatmentDefinitionId) {
			hasOneChosenTreatmentDef = true;
		}
		if (subTask.treatmentDefinitionId && subTask.treatAll === undefined) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_SET_WHAT_TO_TREAT));
			return false;
		}
		if (subTask.treatAll === false) {
			if (!listSetting.boarPcs || (listSetting.boarPcs && listSetting.boarPcs === 0)) {
				showAlert(localized(ExceptionMessage.VALIDATION_ERROR_BOAR_PROCENTAGE_IS_NOT_SET));
				return false;
			}
		}
		if (subTask.treatmentDefinitionId && !subTask.columnText) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_COLUMN_TEXT_IS_NOT_SET));
			return false;
		}
	}
	if (!hasOneChosenTreatmentDef) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_CHOOSE_ATLEAST_ONE_TREATMENT_DEF));
		return false;
	}

	if (!listSetting.showOnApp && !listSetting.showOnWeb) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_AVAILABLE_ON_NEED_TO_BE_SET));
		return false;
	}

	return true;
}
