import isEqual from 'react-fast-compare';
import { Equipment, FeedingStatusUpdate, IProcessEquipmentData, IUnitToPen } from 'shared/api/api';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { deepCopy2 } from 'shared/helpers/general-helpers';
import { ILocationModel } from 'shared/helpers/location-helper';
import { localized } from 'shared/state/i18n/i18n';
import { AllAnimalsEsfListProps } from './all-animals-esf-helpers';
import { ShortageEsfListProps } from './shortage-esf-helpers';
export function GetFilteredLocations(
	props: ShortageEsfListProps | AllAnimalsEsfListProps,
	generateEsfLocationsWithSettedPensData: (
		ProcessEquipmentData: IProcessEquipmentData[],
		unitToPens: IUnitToPen[],
	) => IProcessEquipmentData[],
) {
	let esfLocationsWithSettedPens = generateEsfLocationsWithSettedPensData(
		props.processEquipmentData,
		props.unitToPens,
	);

	// Flat out all unitGroup ids
	let unitGroupIds = esfLocationsWithSettedPens
		.map(peq => peq.unitCodes && peq.unitCodes.map(uc => uc.unitGroupId))
		.flat();

	let locations = deepCopy2(props.locations) as ILocationModel;

	// find buildings which is paired up with a ESF location
	locations.buildings = locations.buildings.filter(
		b =>
			props.unitToPens.find(
				esfLoc =>
					unitGroupIds.includes(esfLoc.unitGroupId) &&
					locations.pens.find(pen => pen.id === esfLoc.penId && pen.buildingId === b.id),
			) !== undefined,
	);

	// find sections which is paired up with a ESF location
	locations.sections = locations.sections.filter(
		s =>
			props.unitToPens.find(
				esfLoc =>
					unitGroupIds.includes(esfLoc.unitGroupId) &&
					locations.pens.find(pen => pen.id === esfLoc.penId && pen.sectionId === s.id),
			) !== undefined,
	);

	// find pens which is paired up with a ESF location
	locations.pens = locations.pens.filter(
		p =>
			props.unitToPens.find(esfLoc => unitGroupIds.includes(esfLoc.unitGroupId) && p.id === esfLoc.penId) !==
			undefined,
	);
	return { locations: locations, esfLocationsWithSettedPens: esfLocationsWithSettedPens };
}

export function GetEsfLocationsWithSettedPensData(
	processEquipmentData: IProcessEquipmentData[],
	unitToPens: IUnitToPen[],
) {
	let data: IProcessEquipmentData[] = [];
	processEquipmentData.forEach(peq => {
		const exists = unitToPens.find(
			utp => peq.id === utp.processEquipmentDataId && peq.equipmentType === Equipment.ESF,
		);
		if (exists) {
			data.push(peq);
		}
	});
	return data;
}

export function SaveFeedingStatusValidation(
	selectedFeedCurveNumber: number | undefined,
	previousSelectedFeedCurveNumber: number | undefined,
	fixedPercentage: string | undefined,
	previousFixedPercentage: string | undefined,
	animalNumber: string | undefined,
	feedingStatusId: string | undefined,
	saveFeedingStatus: (feedingStatusUpdate: FeedingStatusUpdate) => Promise<void>,
	showAlert: (errorMessage: string) => void,
) {
	let item = new FeedingStatusUpdate();
	if (
		!isEqual(selectedFeedCurveNumber, previousSelectedFeedCurveNumber) ||
		!isEqual(fixedPercentage, previousFixedPercentage)
	) {
		if (selectedFeedCurveNumber === undefined) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_CURVE_INVALID));
		} else if (fixedPercentage && fixedPercentage.length <= 0) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_FIXED_PERCENT_INVALID));
		} else {
			item.animalNumber = animalNumber;

			item.feedCurveNumber = selectedFeedCurveNumber;

			if (fixedPercentage) {
				item.fixedDeviation = parseInt(fixedPercentage);
			}
			item.id = feedingStatusId;
			saveFeedingStatus(item);
			return true;
		}
	}
	return false;
}
