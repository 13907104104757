
import actionCreatorFactory from 'typescript-fsa';
import * as types from './types';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import { IDrugType, SyncDataDrugType } from 'shared/api/api';

const actionCreator = actionCreatorFactory(GenActionFactoryName('drugType'));

export const saveDrugType = actionCreator.async<
    IDrugType,
    string,
    { code: number; message: string; prevEntity: IDrugType | undefined }
>(types.SAVE_DRUGTYPE);
export const getSyncData = actionCreator.async<{ lastSyncDate: Date }, SyncDataDrugType>(types.SYNC_DATA_DRUGTYPES);
