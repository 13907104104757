import React from 'react';
import { SkioldInput, TextPropsFromParent } from './skiold-input';
import './skiold-input.scss';

export class SkioldFormInput extends React.PureComponent<TextPropsFromParent> {
	public static defaultProps: Partial<TextPropsFromParent> = {
		editable: true,
		selectTextOnFocus: true,
		autoFocus: false,
		className: '',
		useAsInterger: false,
	};

	public input: any | null | undefined;

	public render() {
		return (
			<SkioldInput
				{...this.props}
				ref={ref => {
					this.input = ref;
				}}
				onChangeText={this.onTextChanged}
				className={`skiold-form-input ${this.props.className}`}
				
			/>
		);
	}

	private regexToUse = /[^\d]/g;

	private onTextChanged = (newText: string) => {
		if (this.props.useAsInterger) {
			let textstring = newText.replace(this.regexToUse, '');
			this.props.onChangeText(textstring, this.props.itemFromParent);
		} else {
			this.props.onChangeText(newText, this.props.itemFromParent);
		}
	};

	public focus() {
		if (this.input) {
			this.input.focus();
		}
	}
}
