
import { IFeedingCategory } from 'shared/api/api';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import actionCreatorFactory from 'typescript-fsa';
import * as types from './types';

const actionCreator = actionCreatorFactory(GenActionFactoryName('feedingCategory'));

export const saveFeedingCategory = actionCreator.async<
    {data?:IFeedingCategory},
    string,
    { code: number; message: string; prevEntity: IFeedingCategory | undefined }
>(types.SAVE_FEEDINGCATEGORY);
export const getData = actionCreator.async<{}, IFeedingCategory[]>(
    types.FETCH_DATA_FEEDINGCATEGORIES
);
