import { actionCreatorFactory } from 'typescript-fsa';
import * as types from './types';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import { SyncDistriwinOption } from 'shared/api/api';

const actionCreator = actionCreatorFactory(GenActionFactoryName('distriwinOption'));

export const getSyncData = actionCreator.async<{ siteId: string; lastSyncDate: Date }, SyncDistriwinOption>(
    types.SYNC_DATA_DISTRIWIN_OPTION
);
