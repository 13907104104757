import { Sorting } from '@devexpress/dx-react-grid';
import React, { FC, useEffect, useState } from 'react';
import { Option } from 'react-dropdown';
import { useSelector } from 'react-redux';
import { GrowthPigShouldDepartureTableItem } from 'shared/api/api';
import { getDateString } from 'shared/helpers/date-helpers';
import { getTotalWeightByPenId } from 'shared/helpers/growth-pigs-helper/growth-pig-event-function-helper';
import { generateShouldDepartureTableData } from 'shared/helpers/growth-pigs-helper/growth-pig-should-departure-helper.';
import { NaturalSortBuildSection, NaturalSortPenOrder } from 'shared/helpers/location-helper';
import { memoizeHashmapLocation } from 'shared/helpers/memoize-getters/memoize-getters';
import { DefaultReasonOption, getReasonOptions } from 'shared/helpers/reason-helper/reason-helper';
import { localized } from 'shared/state/i18n/i18n';
import { DepartureTypes } from 'shared/state/models/departure-types';
import { WebAppState } from 'web/state/store.web';
import { SkioldCheckbox } from 'web/view/components/skiold-components/skiold-checkbox/skiold-checkbox';
import { SkioldDatePicker } from 'web/view/components/skiold-components/skiold-date-picker/skiold-date-picker';
import { SkioldDecimalInput } from 'web/view/components/skiold-components/skiold-decimal-input/skiold-decimal-input';
import { SkioldDropdown } from 'web/view/components/skiold-components/skiold-dropdown/skiold-dropdown';
import { SkioldIntegerInput } from 'web/view/components/skiold-components/skiold-integer-input/skiold-integer-input';
import SkioldTableGrid, {
	SkioldTableGrid as SkioldTableRef,
} from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { useGpeShouldDeparture } from './growth-pig-should-departure-overview';
interface PropsFromParent {}

const retentionCell = (item: GrowthPigShouldDepartureTableItem) => (
	<TextWeb className="red-text-label">{getDateString(item.retentionDate)}</TextWeb>
);

export const defaultSortingLocation = [{ columnName: 'locationString', direction: 'asc' }] as Sorting[];

export const GrowthPigShouldDepartureTable: FC<PropsFromParent> = React.memo(() => {
	const reasons = useSelector((state: WebAppState) => state.reasons.entities);
	const { growthPigEvents, entities } = useSelector((state: WebAppState) => state.growthPigEvents);
	const hashMapLocations = useSelector((state: WebAppState) =>
		memoizeHashmapLocation(state.locations.buildings, state.locations.sections, state.locations.pens),
	);
	const { tableItems, setTableItems, productionType, animalKind, setSkioldTableRef } = useGpeShouldDeparture();
	const [departureTypeOptions, setDepartureOptions] = useState<Option[]>(
		DepartureTypes.GetDepartureTypesOptions(productionType),
	);

	useEffect(() => {
		setDepartureOptions(DepartureTypes.GetDepartureTypesOptions(productionType));
	}, [productionType]);

	// Genereate table items
	useEffect(() => {
		const tableData = generateShouldDepartureTableData(growthPigEvents, hashMapLocations, productionType);
		setTableItems(tableData);
	}, [growthPigEvents, hashMapLocations, productionType, entities, setTableItems]);

	const departureTypeCell = (item: GrowthPigShouldDepartureTableItem) => {
		// Setup departure types. If type is sold, clear reason
		const departureTypeOption = departureTypeOptions.find(op => op.value === item.departureType);
		const departureTypeChanged = (option: Option) => {
			let tableData = [...tableItems];
			const index = tableData.findIndex(gpe => gpe.gpeId === item.gpeId);
			if (index >= 0) {
				const itemChanged = GrowthPigShouldDepartureTableItem.fromJS({
					...tableData[index],
					departureType: option.value,
					reasonId: option.value === DepartureTypes.departureTypeSold ? '' : tableData[index].reasonId,
				});
				tableData[index] = itemChanged;
				setTableItems(tableData);
			}
		};
		return (
			<SkioldDropdown
				usedInTable={true}
				theme={'dark'}
				items={departureTypeOptions}
				selectedValue={departureTypeOption}
				onValueChanged={departureTypeChanged}
			/>
		);
	};

	const pigCountCell = (item: GrowthPigShouldDepartureTableItem) => {
		const pigCountChanged = (newPigCount: number | undefined) => {
			let tableData = [...tableItems];
			const index = tableData.findIndex(gpe => gpe.gpeId === item.gpeId);
			if (index >= 0) {
				const itemChanged = GrowthPigShouldDepartureTableItem.fromJS({
					...tableData[index],
					pigCount: newPigCount,
				});
				tableData[index] = itemChanged;
				setTableItems(tableData);
			}
		};
		return (
			<SkioldIntegerInput className="text-align-right" onChangeNumber={pigCountChanged} text={item.pigCount} />
		);
	};

	const avgWeightCell = (item: GrowthPigShouldDepartureTableItem) => {
		const avWeightChanged = (newAvgWeight: number | undefined) => {
			let tableData = [...tableItems];
			const index = tableData.findIndex(gpe => gpe.gpeId === item.gpeId);
			if (index >= 0) {
				const itemChanged = GrowthPigShouldDepartureTableItem.fromJS({
					...tableData[index],
					avgWeight: newAvgWeight,
				});
				tableData[index] = itemChanged;
				setTableItems(tableData);
			}
		};
		return (
			<SkioldDecimalInput className="text-align-right" onChangeNumber={avWeightChanged} text={item.avgWeight} />
		);
	};

	const reasonCell = (item: GrowthPigShouldDepartureTableItem) => {
		const departureReasonChanged = (option: Option) => {
			let tableData = [...tableItems];
			const index = tableData.findIndex(gpe => gpe.gpeId === item.gpeId);
			if (index >= 0) {
				const itemChanged = GrowthPigShouldDepartureTableItem.fromJS({
					...tableData[index],
					reasonId: option.value,
					reasonString: option.label,
				});
				tableData[index] = itemChanged;
				setTableItems(tableData);
			}
		};

		const options = getReasonOptions(reasons, animalKind, item.departureType!);
		const selectedOption = options.find(o => o.value === item.reasonId);
		return (
			<SkioldDropdown
				usedInTable={true}
				theme={'dark'}
				items={options}
				selectedValue={selectedOption ? selectedOption : DefaultReasonOption}
				onValueChanged={departureReasonChanged}
			/>
		);
	};

	const checkedCell = (item: GrowthPigShouldDepartureTableItem) => {
		const onCheckChanged = (value: boolean) => {
			let tableData = [...tableItems];
			const index = tableData.findIndex(gpe => gpe.gpeId === item.gpeId);
			if (index >= 0) {
				const itemChanged = GrowthPigShouldDepartureTableItem.fromJS({ ...tableData[index], checked: value });
				tableData[index] = itemChanged;
				setTableItems(tableData);
			}
		};
		return <SkioldCheckbox className="text-align-right" isChecked={item.checked} onClick={onCheckChanged} />;
	};

	const checkAllCell = () => {
		const isCommitAll = tableItems.length <= 0 || tableItems.find(item => !item.checked) ? false : true;
		const onCheckChanged = (value: boolean) => {
			// Set all items to the opposite of isCommitAll
			let tableData = tableItems.map(item => {
				return GrowthPigShouldDepartureTableItem.fromJS({ ...item, checked: !isCommitAll });
			});
			setTableItems(tableData);
		};
		return (
			<ViewWeb className="checkbox-view-filter-style">
				<SkioldCheckbox isChecked={isCommitAll} onClick={onCheckChanged} />
			</ViewWeb>
		);
	};

	const dateCell = (item: GrowthPigShouldDepartureTableItem) => {
		return (
			<SkioldDatePicker
				onDateChanged={dateChanged}
				itemFromParent={item}
				selectedDate={item.registrationDate}
				theme={'dark'}
				color={'grey'}
				containerClassName={'justify-content-center'}
			/>
		);
	};

	const dateChanged = (date: Date, item: GrowthPigShouldDepartureTableItem) => {
		let tableData = [...tableItems];
		const index = tableData.findIndex(gpe => gpe.gpeId === item.gpeId);
		if (index >= 0) {
			// If date change, avg weight should too
			const avgWeight = getTotalWeightByPenId(item.sectionId, item.penId, date, 0, true);
			const itemChanged = GrowthPigShouldDepartureTableItem.fromJS({
				...tableData[index],
				registrationDate: date,
				avgWeight,
			});
			tableData[index] = itemChanged;

			setTableItems(tableData);
		}
	};

	// Custom filters, due to editable cells
	const customPigCountFilter = (value: any, filterValue: any, row: GrowthPigShouldDepartureTableItem) => {
		return row.pigCount && String(row.pigCount).startsWith(filterValue.value);
	};
	const avgWegihtFilter = (value: any, filterValue: any, row: GrowthPigShouldDepartureTableItem) => {
		return row.avgWeight && String(row.avgWeight).startsWith(filterValue.value);
	};
	const departureTypeFilter = (value: any, filterValue: any, row: GrowthPigShouldDepartureTableItem) => {
		return (
			row.departureType &&
			String(localized(row.departureType).toLocaleLowerCase()).startsWith(filterValue.value.toLocaleLowerCase())
		);
	};
	const reasonFilter = (value: any, filterValue: any, row: GrowthPigShouldDepartureTableItem) => {
		return (
			row.reasonString &&
			String(row.reasonString.toLocaleLowerCase()).startsWith(filterValue.value.toLocaleLowerCase())
		);
	};

	const generateColumns = () => [
		{
			title: localized('Date'),
			name: 'date',
			getCellValue: dateCell,
		},
		{
			title: localized('location'),
			name: 'locationString',
			sortFunction: NaturalSortBuildSection,
			useHiddenSort: true,
		},
		{
			title: localized('pen'),
			name: 'penString',
			sortFunction: NaturalSortPenOrder,
			useHiddenSort: true,
		},
		{
			title: localized('pigCount'),
			name: 'pigCount',
			getCellValue: pigCountCell,
			sortable: false,
			filterFunction: customPigCountFilter,
		},
		{
			title: localized('avgWeight'),
			name: 'avgWeight',
			getCellValue: avgWeightCell,
			sortable: false,

			filterFunction: avgWegihtFilter,
		},
		{
			title: localized('departureType'),
			name: 'departureType',
			getCellValue: departureTypeCell,
			sortable: false,
			filterFunction: departureTypeFilter,
		},
		{
			title: localized('reason'),
			name: 'reasonString',
			getCellValue: reasonCell,
			sortable: false,
			filterFunction: reasonFilter,
		},
		{
			title: localized('slaughterRetention'),
			name: 'retentionDate',
			getCellValue: retentionCell,
		},
		{
			title: localized('completed'),
			name: 'checked',
			getCellValue: checkedCell,
			sortable: false,
			filter: checkAllCell,
		},
	];

	const generateColumnExtes = [
		{
			width: 150,
			columnName: 'date',
		},
		{
			width: 150,
			columnName: 'locationString',
		},
		{
			width: 85,
			columnName: 'penString',
		},
		{
			width: 85,
			columnName: 'pigCount',
		},
		{
			width: 85,
			columnName: 'avgWeight',
		},
		{
			width: 150,
			columnName: 'departureType',
		},
		{
			width: 150,
			columnName: 'reasonString',
		},
		{
			width: 150,
			columnName: 'retentionDate',
		},
		{
			width: 85,
			columnName: 'checked',
		},
	];

	return (
		<ViewWeb className="flex-row-space-between gpe-should-depart-table-container">
			<SkioldTableGrid
				tableKey={'gpe-should-depart1'}
				ignoreSetCount={true}
				ColumnExtensions={generateColumnExtes}
				columns={generateColumns()}
				data={tableItems}
				ref={setSkioldTableRef as any}
				sortHeaderId={defaultSortingLocation}
			/>
		</ViewWeb>
	);
});
