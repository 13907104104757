import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AnimalReportType, FeedingESFReportSetting, IFeedingESFReportSetting } from 'shared/api/api';
import { SaveAnimalReportSetting } from 'shared/state/ducks/animal-report-settings/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { showAlert } from 'web/view/components/skiold-alert/skiold-alert';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldFormsWrapper } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { SkioldFormIntegerInput } from 'web/view/components/skiold-components/skiold-integer-input/skiold-form-integer-input';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';

const mapStateToProps = (state: WebAppState) => {
	return {
		reportSettings: state.animalReportSettings.entities,
		siteId: state.profile.active!.siteId,
		lang: state.profile.active!.language,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	SaveAnimalReportSetting: (report: IFeedingESFReportSetting) => SaveAnimalReportSetting(report)(dispatch),
});

interface State {
	setting: IFeedingESFReportSetting;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export class FeedingESFReportSettingSetup extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		const doesExist = props.reportSettings.find(set => set.type === AnimalReportType.FeedingESFReportSetting);
		const setting = doesExist
			? (doesExist as IFeedingESFReportSetting)
			: FeedingESFReportSetting.fromJS({ siteId: props.siteId, type: AnimalReportType.FeedingESFReportSetting });
		this.state = {
			setting,
		};
	}

	public render() {
		return (
			<ViewWeb className="tasks-in-farrowing-stable-setup">
				<ViewWeb className="view-container">
					<TextWeb className="text-style">{localized('Setup')}</TextWeb>
					<SkioldFormsWrapper formRows={this.getFormRows()} containerClassName="wrapper-container" />
					{this.renderButtons()}
				</ViewWeb>
			</ViewWeb>
		);
	}

	private getFormRows() {
		let formRows = new Array<FormRow>();

		formRows.push(this.getHeader());
		formRows.push(
			this.getNursingDaysFromToRow(
				'feedingESFSetupRedNameText',
				'redFromPercentage',
				'redToPercentage',
				'dayRationEaten',
			),
		);
		formRows.push(
			this.getNursingDaysFromToRow(
				'feedingESFSetupGreenNameText',
				'greenFromPercentage',
				'greenToPercentage',
				'dayRationEaten',
			),
		);

		return formRows;
	}

	private getNursingDaysFromToRow(
		name: 'feedingESFSetupGreenNameText' | 'feedingESFSetupRedNameText',
		from: 'redFromPercentage' | 'greenFromPercentage',
		to: 'redToPercentage' | 'greenToPercentage',
		unit: 'dayRationNotEaten' | 'dayRationEaten',
	): FormRow {
		return {
			name: localized(name),
			component: (
				<ViewWeb className="nursing-from-to-container">
					{this.generateFormIntergerInput(from)}
					<TextWeb>{'-'}</TextWeb>
					{this.generateFormIntergerInput(to)}
					<TextWeb className="marginLeftTen">{localized(unit)}</TextWeb>
				</ViewWeb>
			),
		};
	}

	private generateFormIntergerInput = (
		itemFromParent: 'redFromPercentage' | 'redToPercentage' | 'greenFromPercentage' | 'greenToPercentage',
	) => (
		<SkioldFormIntegerInput
			className="width60 marginRightZero"
			itemFromParent={itemFromParent}
			onChangeNumber={this.propertyChanged}
			text={this.state.setting[itemFromParent]}
		/>
	);
	private propertyChanged = (
		number: number | undefined,
		itemFromParent: 'redFromPercentage' | 'redToPercentage' | 'greenFromPercentage' | 'greenToPercentage',
	) => {
		const setting = { ...this.state.setting };

		setting[itemFromParent] = number!;
		if (
			itemFromParent === 'redToPercentage' &&
			(setting.greenFromPercentage === undefined || setting.greenFromPercentage <= setting[itemFromParent]!)
		) {
			setting.greenFromPercentage = setting[itemFromParent] ? setting[itemFromParent]! + 1 : undefined!;
		} else if (itemFromParent === 'greenFromPercentage') {
			if (setting.redToPercentage === undefined) {
				setting.redToPercentage = setting[itemFromParent] ? setting[itemFromParent]! - 1 : undefined!;
			}
		}
		this.setState({ setting });
	};

	private save = async () => {
		if (
			this.state.setting.greenFromPercentage !== undefined &&
			this.state.setting.greenToPercentage !== undefined &&
			this.state.setting.redFromPercentage !== undefined &&
			this.state.setting.redToPercentage !== undefined &&
			this.state.setting.greenFromPercentage > this.state.setting.redToPercentage &&
			this.state.setting.greenToPercentage >= this.state.setting.redFromPercentage &&
			this.state.setting.greenToPercentage >= this.state.setting.greenFromPercentage &&
			this.state.setting.redToPercentage >= this.state.setting.redFromPercentage
		) {
			this.props.SaveAnimalReportSetting(this.state.setting);
		} else {
			if (
				this.state.setting.redFromPercentage === undefined ||
				this.state.setting.redToPercentage === undefined ||
				this.state.setting.redToPercentage < this.state.setting.redFromPercentage
			) {
				showAlert(localized('RequireSelectionOfPercentagesRed'));
			} else {
				showAlert(localized('RequireSelectionOfPercentagesGreen'));
			}
		}
	};

	private renderButtons() {
		return (
			<ViewWeb className="view-button-container">
				<SkioldButton title={localized('Save')} onPress={this.save} />
			</ViewWeb>
		);
	}

	private getHeader(): FormRow {
		return {
			header: localized(AnimalReportType.FeedingESFReportSetting),
		};
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(FeedingESFReportSettingSetup);
