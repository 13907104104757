import React from 'react';
import { Option } from 'react-dropdown';
import { connect } from 'react-redux';
import {
	generateFeedCurveOptionsByLocation,
	splitFeedCurveOption,
} from 'shared/helpers/feed-curve-helper';
import { WebAppState } from 'web/state/store.web';
import { SkioldFormDropdown } from '../skiold-components/skiold-dropdown/skiold-form-dropdown';
import { SkioldDropdown } from '../skiold-components/skiold-dropdown/skiold-dropdown';

interface PropsFromParent {
	usedInsideForm?: boolean;
	usedInsideTable?: boolean;
	penId?: string;
	feedCurveNumber?: number;
	onChange?: (feedCurveNumber: number | undefined) => void;
	disabled?: boolean;
	showAsLabel?: boolean;
	menuClassname?: string;
	dropdownIdentifier?: string;
	containerClassName?: string;
	className?: string;
	style?: any;
	theme?: 'light' | 'dark';
}

const mapStateToProps = (state: WebAppState, props: PropsFromParent) => {
	const utp = state.unitToPenData.data.find(p => p.penId === props.penId);
	const processEquipmentData = state.processEquipmentData.entities.find(
		peq => utp && peq.id === utp.processEquipmentDataId,
	);

	return {
		processEquipmentData,
		feedCurveOptions: generateFeedCurveOptionsByLocation(state.esfStatus.availableCurves, processEquipmentData),
	};
};

const DefaultFeedCurveOption: Option = { label: ' ', value: '' };

interface State {
	selectedFeedCurveOption: Option;
}

type Props = ReturnType<typeof mapStateToProps> & PropsFromParent;
export class SkioldFeedCurvePicker extends React.PureComponent<Props, State> {
	public static defaultProps: Partial<Props> = {};

	public static getDerivedStateFromProps(nextProps: Props, prevState: State) {
      
		const option = nextProps.feedCurveOptions.find(
			o => nextProps.feedCurveNumber && splitFeedCurveOption(o) === nextProps.feedCurveNumber.toString(),
		);
		if (option !== undefined) {
			return { selectedFeedCurveOption: option };
		} else {
			return { selectedFeedCurveOption: DefaultFeedCurveOption };
		}
	}

	constructor(props: Props) {
		super(props);

		this.state = {
			selectedFeedCurveOption: DefaultFeedCurveOption,
		};
	}

	public componentDidMount() {
		if (this.props.feedCurveNumber !== undefined) {
			const option = this.props.feedCurveOptions.find(
				c => this.props.feedCurveNumber && c.value === this.props.feedCurveNumber.toString(),
			);
			this.setState({
				selectedFeedCurveOption: option ? option : DefaultFeedCurveOption,
			});
		}
	}

	public render() {
		if (this.props.showAsLabel && this.props.disabled) {
		}
		if (this.props.usedInsideForm) {
			return this.renderSkioldFormPenPicker();
		} else {
			return this.renderSkioldPenPicker();
		}
	}

	private renderSkioldPenPicker() {
		return (
			<SkioldDropdown
				usedInTable={true}
				items={this.props.feedCurveOptions}
				containerClassName={this.props.containerClassName}
				style={this.props.style}
				menuClassname={this.props.menuClassname}
				theme={this.props.theme}
				dropdownIdentifier={this.props.dropdownIdentifier}
				selectedValue={this.state.selectedFeedCurveOption}
				onValueChanged={this.curveChanged}
				disabled={this.props.disabled}
				className={this.props.className}
			/>
		);
	}

	private renderSkioldFormPenPicker() {
		return (
			<SkioldFormDropdown
				items={this.props.feedCurveOptions}
				theme={'dark'}
				containerClassName="grade-dropdown"
				selectedValue={this.state.selectedFeedCurveOption}
				onValueChanged={this.curveChanged}
			/>
		);
	}

	public curveChanged = (curveOption: Option) => {
		this.setState({ selectedFeedCurveOption: curveOption });
		let value = splitFeedCurveOption(curveOption);
		let intValue;
		if (value) {
			intValue = value ? parseInt(value) : undefined;
		}

		if (this.props.onChange) {
			this.props.onChange(intValue);
		}
	};
}

export default connect(mapStateToProps, {})(SkioldFeedCurvePicker);
