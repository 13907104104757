import { IDistriwinFeedComponent } from 'shared/api/api';
import { Syncable } from 'shared/state/models/syncable';

export const SYNC_DISTRIWIN_FEED_COMPONENTS = 'SYNC_DISTRIWIN_FEED_COMPONENTS';
export const SET_FEEDING_TO_COMPONENT_RELATIONS = 'SET_FEEDING_TO_COMPONENT_RELATIONS';
export const FETCH_COMPONENTS = 'FETCH_COMPONENTS';

export interface DistriwinFeedComponentState extends Syncable<IDistriwinFeedComponent> {
	fetchingComponents: boolean;
}
