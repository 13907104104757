import React from 'react';
import { Option } from 'react-dropdown';
import { connect } from 'react-redux';
import {
	generateOptionsForGrowthPigEventDistribute,
	GetNewPenOptions,
} from 'shared/helpers/growth-pigs-helper/growth-pig-event-function-helper';
import {
	GrowthPigDistributeFormProps,
	GrowthPigDistributeFormState,
	GrowthPigEventsMapStateToProps,
} from 'shared/helpers/growth-pigs-helper/growth-pig-event-helper';
import { localized } from 'shared/state/i18n/i18n';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { SkioldFormDropdown } from '../../skiold-components/skiold-dropdown/skiold-form-dropdown';
import { WhiteText } from '../../Text/white-text';
import './growth-pig-distribute.scss';
import { DistriwinFeedCurvePicker } from '../../distriwin-components/distriwin-feed-curve-picker';
import { DistriwinRecipePicker } from '../../distriwin-components/distriwin-recipe-picker';
import { AnimalType } from 'shared/api/api';
import { SectionHasDistriwin } from 'shared/state/ducks/unit-to-pen/reducer';
import { sortPensByName } from 'shared/helpers/location-helper';

export class GrowthPigDistributeForm extends React.PureComponent<
	GrowthPigDistributeFormProps,
	GrowthPigDistributeFormState
> {
	constructor(props: GrowthPigDistributeFormProps) {
		super(props);
		let penOptions: { fromOptions: Option[]; toOptions: Option[] } = { fromOptions: [], toOptions: [] };
		const pensInSection = props.locations.pens
			.filter(p => p.sectionId === this.props.toSectionId)
			.sort(sortPensByName);

		if (pensInSection.length > 0) {
			penOptions = generateOptionsForGrowthPigEventDistribute(
				pensInSection,
				pensInSection[0],
				pensInSection[pensInSection.length - 1],
			);
		}
		const fromOptions = penOptions.fromOptions.filter(
			x => !props.blacklistedPenIds || !props.blacklistedPenIds.includes(x.value),
		);
		const toOptions = penOptions.toOptions.filter(
			x => !props.blacklistedPenIds || !props.blacklistedPenIds.includes(x.value),
		);
		this.state = {
			fromOptions,
			toOptions,
		};
	}

	public componentDidUpdate(prevProps: GrowthPigDistributeFormProps) {
		const dataToSet = GetNewPenOptions(prevProps, this.props);
		if (dataToSet !== undefined) {
			const fromOptions = dataToSet.fromOptions.filter(
				x => !this.props.blacklistedPenIds || !this.props.blacklistedPenIds.includes(x.value),
			);
			const toOptions = dataToSet.toOptions.filter(
				x => !this.props.blacklistedPenIds || !this.props.blacklistedPenIds.includes(x.value),
			);
			this.setState({ fromOptions, toOptions });
		}
	}

	public render() {
		return (
			<ViewWeb>
				{!this.props.hideAmountPigsNotDistributed && (
					<ViewWeb className="flexDirectionRow">
						<WhiteText className="penTextStyle">{localized('NotDistributed') + ':'}</WhiteText>
						<WhiteText className="marginLeftTen">
							{`${this.props.amountPigsNotDistributed} ${localized('pigs')} ${
								Math.round(this.props.totalWeightNotDistributed * 10) / 10
							} ${localized('kg')}`}
						</WhiteText>
					</ViewWeb>
				)}
				{SectionHasDistriwin(this.props.toSectionId) &&
					((this.props.productionType === AnimalType.Finisher &&
						this.props.generalSettings.sendEntranceDepartureDistriwinFinisher) ||
						(this.props.productionType === AnimalType.Weaner &&
							this.props.generalSettings.sendEntranceDepartureDistriwinWeaner)) && (
						<ViewWeb className="flexDirectionRow default-gap">
							<WhiteText className="penTextStyle">{localized('feedCurve')}:</WhiteText>
							<DistriwinFeedCurvePicker
								sectionId={this.props.toSectionId}
								onSelect={this.props.feedCurveChanged}
							/>
							<WhiteText className="penTextStyle">{localized('Recipe')}:</WhiteText>
							<DistriwinRecipePicker onSelect={this.props.recipeChanged} />
						</ViewWeb>
					)}

				<ViewWeb className="flexDirectionRow">
					<ViewWeb className="flexDirectionRowSpaceBetween marginRightThirty">
						<WhiteText className="penTextStyle">{localized('FromPen')}</WhiteText>
						<SkioldFormDropdown
							theme="light"
							onValueChanged={this.props.OnFromPenChanged}
							items={this.state.fromOptions}
							selectedValue={this.props.fromPenId}
						/>
					</ViewWeb>
					<WhiteText className="penTextStyle">{localized('ToPen')}</WhiteText>
					<SkioldFormDropdown
						theme="light"
						onValueChanged={this.props.OnToPenChanged}
						items={this.state.toOptions}
						selectedValue={this.props.toPenId}
					/>
				</ViewWeb>
			</ViewWeb>
		);
	}
}

export default connect(GrowthPigEventsMapStateToProps)(GrowthPigDistributeForm);
