import { IMatingBatchReports, DashboardTableModel } from "shared/api/api";


export const GET_MATING_BATCH_REPORTS_BY_SITE = 'GET_MATING_BATCHES_BY_SITE';
export const GET_DASHBOARD_TABLE_MODELS_FOR_MATING_BATCH_REPORTS = "GET_DASHBOARD_TABLE_MODELS_FOR_MATING_BATCH_REPORTS";
export const SAVE_MATING_BATCH_REPORT = 'SAVE_MATING_BATCHES';

export interface MatingBatchReportsState {
    matingBatchReports: IMatingBatchReports;
    matingBatchReportDashboardTableModels: { [key: string] : DashboardTableModel[]; }
    loadingMatingBatchReportDashboardTableModels: boolean;
}

export enum MatingBatchReportModalMode {
	matings,
	farrowings,
	weanings,
}