import { Dispatch } from 'redux';
import React from 'react';
import { connect } from 'react-redux';

import PenIcon from 'shared/assets/src-assets/png/pen_icon.png';
import { WebAppState } from 'web/state/store.web';
import { GetSyncData as DiagnoseCategoriesGetSyncData } from 'shared/state/ducks/diagnoseCategories/operations';
import { IDiagnoseCategory } from 'shared/api/api';
import PageContainer from 'web/view/components/page-container/page-container';
import { Heading } from 'web/view/components/utils/heading';
import { localized } from 'shared/state/i18n/i18n';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { SkioldTable, Row } from 'web/view/components/skiold-components/skiold-table/skiold-table';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldModal } from 'web/view/components/skiold-components/skiold-modal/skiold-modal';
import './diagnose-categories.scss';
import AddDiagnoseCategory from 'web/view/components/diagnose/add-diagnose-category';
import { SkioldTouchableOpacity } from 'web/view/components/skiold-components/skiold-touchable-opacity';
import { SkioldImage } from 'web/view/components/utils/svg/skiold-image';
const mapStateToProps = (state: WebAppState) => {
	return {
		categories: state.diagnoseCategory.entities,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	getDiagnoseCategories: () => DiagnoseCategoriesGetSyncData()(dispatch),
});

interface State {
	modalIsOpen: boolean;
	categoryToEdit: IDiagnoseCategory | undefined;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
class DiagnoseCategories extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			modalIsOpen: false,
			categoryToEdit: undefined,
		};

		this.props.getDiagnoseCategories();
	}

	public render() {
		return (
			<PageContainer className="diagnose-categories">
				<Heading text={localized('DIAGNOSE_CATEGORIES')} />

				<ViewWeb className="categories-table-container">
					<SkioldTable
						columns={this.generateColumns()}
						data={this.props.categories}
						sortHeaderId={'priority'}
					/>
				</ViewWeb>

				<ViewWeb className="add-category-button">
					<SkioldButton title={localized('addCategory')} onPress={() => this.addCategory()} />
				</ViewWeb>

				<SkioldModal close={this.closeModal} isOpen={this.state.modalIsOpen}>
					<AddDiagnoseCategory
						close={this.closeModal}
						isOpen={this.state.modalIsOpen}
						diagnoseCategory={this.state.categoryToEdit}
					/>
				</SkioldModal>
			</PageContainer>
		);
	}

	private closeModal() {
		this.setState({ modalIsOpen: false, categoryToEdit: undefined });
	}

	private addCategory() {
		this.setState({ modalIsOpen: true });
	}

	private edit(category: IDiagnoseCategory) {
		this.setState({ modalIsOpen: true, categoryToEdit: category });
	}

	private generateColumns() {
		return [
			{
				id: 'priority',
				Header: localized('priority'),
				accessor: (d: IDiagnoseCategory) => d.priority,
			},
			{
				id: 'nameDa',
				Header: localized('categoryNameDa'),
				accessor: (d: IDiagnoseCategory) => this.getName('da', d),
			},
			{
				id: 'nameEn',
				Header: localized('categoryNameEn'),
				accessor: (d: IDiagnoseCategory) => this.getName('en', d),
			},
			{
				id: 'edit',
				Header: '',
				Cell: (row: Row<IDiagnoseCategory>) => (
					<SkioldTouchableOpacity onPress={() => this.edit(row.original)}>
						<SkioldImage width="30" height="30" imageData={PenIcon} />
					</SkioldTouchableOpacity>
				),
			},
		];
	}

	private getName(countryCode: string, category: IDiagnoseCategory | undefined): string {
		if (!category || !category.name) {
			return '';
		}

		if (category.name[countryCode]) {
			return category.name[countryCode];
		}

		return '';
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DiagnoseCategories);
