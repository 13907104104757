import memoize from 'memoize-one';
import {
	Equipment,
	Gender,
	GrowthPigsEvent,
	IBuilding,
	IDeadPigletsEvent,
	IDiagnose,
	IDrugType,
	ILardScanningEvent,
	IMoveEvent,
	IPen,
	IPoolYoungFemale,
	IPregnancyEvent,
	IProcessEquipment,
	IProcessEquipmentData,
	ISection,
	IStemAnimal,
	ITreatment,
	ITreatmentDefinition,
	ITreatmentPlan,
	IUserProfile,
	IValidationSetup,
	PregnancyValidationType,
} from 'shared/api/api';
import { generateFeedCurveOptions, generateFeedCurveOptionsByLocation } from '../feed-curve-helper';
import { mergeArrays, mergeArraysHashmap } from '../reducer-helpers';
import { getValidationSettingPlan } from '../work-list-helpers/move-to-helpers/moving-lists-helper';
import { Building, LocationsState, Pen, Section, Valve } from 'shared/state/ducks/locations';
import { TAutocompleteDropdownItem } from 'react-native-autocomplete-dropdown';
import { Option } from 'react-dropdown';
import { FamilyTree } from 'web/view/components/family-tree/family-tree-helper';
import { AddDaysToDate, isDateSameOrAfter, minJSDate, SubtractDays } from '../date-helpers';
import { AllLocationType } from '../../../web/view/components/location/location-overview-components/location-tree-components/location-overview-helper';
import { IStemAnimalDepartured } from 'shared/state/ducks/stem-animals';

export const memoizeFeedCurveOptions = memoize(availableCurves => generateFeedCurveOptions(availableCurves));
export const memoizeFeedCurveOptionsByLocation = memoize((availableCurves, peq) =>
	generateFeedCurveOptionsByLocation(availableCurves, peq),
);

export const memoizeValidationSettingPlan = memoize(
	(validationSetup: IValidationSetup, validationType: PregnancyValidationType) =>
		getValidationSettingPlan(validationSetup, validationType),
);

export const memoizeGetESFProcessEquipment = memoize((processEquipmentData: IProcessEquipmentData[]) =>
	processEquipmentData.filter(peq => peq.equipmentType === Equipment.ESF),
);

export const memoizeGetDistriwinProcessEquipment = memoize((processEquipment: IProcessEquipment[]) =>
	processEquipment.filter(peq => peq.equipment === Equipment.Distriwin),
);

export const memoizeGetDistriwinProcessEquipmentData = memoize((processEquipmentData: IProcessEquipmentData[]) =>
	processEquipmentData.filter(peq => peq.equipmentType === Equipment.Distriwin),
);

export const memoizeActiveSows = memoize((activeSows: IStemAnimal[]) =>
	activeSows.filter(animal => animal.gender === Gender.Female),
);

export const memoizeAllLardEvents = memoize(
	(lardEvents: ILardScanningEvent[], departuredLardEvents: ILardScanningEvent[]) =>
		mergeArrays(lardEvents, departuredLardEvents),
);

export const memoizeAllPregnancyEvents = memoize(
	(
		pregnacyEvents: { [key: string]: IPregnancyEvent[] },
		departuredPregnancyEvents: { [key: string]: IPregnancyEvent[] },
	) => mergeArraysHashmap(pregnacyEvents, departuredPregnancyEvents),
);

export const memoizeAllSows = memoize(
	(activeStemAnimals: IStemAnimal[], departuredStemAnimals: (IStemAnimal | IStemAnimalDepartured)[]) =>
		mergeArrays(
			activeStemAnimals.filter(animal => animal.gender === Gender.Female),
			departuredStemAnimals.filter(animal => animal.gender === Gender.Female),
		),
);

export const memoizeAllAnimals = memoize((activeStemAnimals: IStemAnimal[], departuredStemAnimals: IStemAnimal[]) =>
	mergeArrays(activeStemAnimals, departuredStemAnimals),
);

export const memoizeIdNumbers = memoize((activeStemAnimals: IStemAnimal[]) =>
	activeStemAnimals.map(animal => (!animal.idNumber ? '' : animal.idNumber.replace(/-/g, ''))),
);

// Filter by boar entrance and departuredate
export const memoizeAllBoars = memoize(
	(
		activeStemAnimals: IStemAnimal[],
		departuredStemAnimals: IStemAnimal[],
		maxDaysAfterBoarDepartureSemen: number | undefined,
		matingDate?: Date,
	) => {
		if (matingDate) {
			const allowedDate = maxDaysAfterBoarDepartureSemen
				? SubtractDays(matingDate, maxDaysAfterBoarDepartureSemen)
				: minJSDate;
			return mergeArrays(
				activeStemAnimals.filter(
					animal =>
						animal.entranceDate &&
						animal.gender === Gender.Male &&
						animal.animalNumber &&
						isDateSameOrAfter(matingDate, animal.entranceDate),
				),
				departuredStemAnimals.filter(
					animal =>
						animal.entranceDate &&
						animal.gender === Gender.Male &&
						animal.animalNumber &&
						animal.departureDate &&
						animal.departureDate > allowedDate,
				),
			);
		}
		return activeStemAnimals.filter(
			animal =>
				animal.entranceDate &&
				animal.gender === Gender.Male &&
				animal.animalNumber);
	},
);

export const memoizeActiveBoars = memoize((activeStemAnimals: IStemAnimal[]) =>
	activeStemAnimals.filter(animal => animal.gender === Gender.Male),
);
export const memoizeActiveBoarsWithIdNumber = memoize((activeStemAnimals: IStemAnimal[]) =>
	activeStemAnimals.filter(animal => animal.gender === Gender.Male && animal.animalNumber),
);

export const memoizeActiveBoarsWithIdNumberOption = memoize((activeStemAnimals: IStemAnimal[]): Option[] =>
	activeStemAnimals
		.filter(animal => animal.gender === Gender.Male && animal.animalNumber)
		.map(b => {
			return { label: b.animalNumber, value: b.id, objectData: b.entranceKind } as Option;
		}),
);

export const memoizeActiveBoarsOptions = memoize((activeStemAnimals: IStemAnimal[]) =>
	activeStemAnimals
		.filter(animal => animal.gender === Gender.Male)
		.map(b => ({ id: b.id, title: b.idNumber } as TAutocompleteDropdownItem)),
);

export const memoizeAllDeadPigletEvents = memoize(
	(
		activeDeadPigletsEvent: IDeadPigletsEvent[],
		departuredDeadPigletsEvent: IDeadPigletsEvent[],
		dateFrom: Date,
		dateTo: Date,
	) =>
		mergeArrays(
			activeDeadPigletsEvent.filter(
				event =>
					event.registrationDate && event.registrationDate >= dateFrom && event.registrationDate <= dateTo,
			),
			departuredDeadPigletsEvent.filter(
				event =>
					event.registrationDate && event.registrationDate >= dateFrom && event.registrationDate <= dateTo,
			),
		),
);

export const memoizeAllPoolYoungFemaleEvents = memoize(
	(poolYoungFemaleEvents: IPoolYoungFemale[], dateFrom: Date, dateTo: Date) =>
		poolYoungFemaleEvents.filter(
			event => event.registrationDate && event.registrationDate >= dateFrom && event.registrationDate <= dateTo,
		),
);

export const memoizePoolYoungFemaleEventById = memoize(
	(poolYoungFemaleEvents: IPoolYoungFemale[], id: undefined | string) =>
		poolYoungFemaleEvents.find(event => (event.id = id)),
);

export const memoizeAllMoveEvents = memoize((activeMoveEvents: IMoveEvent[], departuredMoveEvents: IMoveEvent[]) =>
	mergeArrays(activeMoveEvents, departuredMoveEvents),
);

export const memoizeHashmapLocation = memoize((buildings: Building[], sections: Section[], pens: Pen[]) => {
	return [...buildings, ...sections, ...pens].keyValuePairSingle('id');
});

export const memoizeHashmapGrowthPigEvents = memoize((gpes: GrowthPigsEvent[]) => {
	return gpes.keyValuePairSingle('id');
});

export const memoizeHashmapTreatmentPlan = memoize((treatmentPlans: ITreatmentPlan[]) => {
	return treatmentPlans.keyValuePairSingle('id');
});

export const memoizeHashmapTreatmentDefinitions = memoize((treatmentDefinitions: ITreatmentDefinition[]) => {
	return treatmentDefinitions.keyValuePairSingle('id');
});

export const memoizeHashmapDiagnose = memoize((diagnoses: IDiagnose[]) => {
	return diagnoses.keyValuePairSingle('id');
});

export const memoizeHashmapDrugTypes = memoize((drugTypes: IDrugType[]) => {
	return drugTypes.keyValuePairSingle('id');
});

export const memoizeHashmapStemAnimal = memoize(
	(activeStemAnimals: IStemAnimal[], departuredStemAnimals: IStemAnimal[]) =>
		activeStemAnimals.concat(departuredStemAnimals).keyValuePairSingle('id'),
);

export const memoizeHashmapActiveStemAnimal = memoize((activeStemAnimals: IStemAnimal[]) =>
	activeStemAnimals.keyValuePairSingle('id'),
);

export const memoizeHashmapProfile = memoize((profiles: IUserProfile[]) => profiles.keyValuePairSingle('id'));

export const memoizeHashmapAllLocation = memoize((buildings: Building[], sections: Section[], pens: Pen[]) => {
	return ([] as AllLocationType[]).concat(buildings).concat(sections).concat(pens).keyValuePairSingle('id');
});

export const memoizeHashmapAllMoveEventsToStemAnimalId = memoize((moveEvents: IMoveEvent[]) => {
	return moveEvents.keyValuePairArray('stemAnimalId');
});

export const memoizeHashmapTreatmentsToTreatmentPlanId = memoize((treatments: ITreatment[]) => {
	return treatments.keyValuePairArray('treatmentPlanId');
});

export const memoizeHashmapTreatmentPlanToStemAnimalId = memoize((treatmentPlans: ITreatmentPlan[]) => {
	return treatmentPlans.keyValuePairArray('stemAnimalId');
});

export const memoizeLocation = memoize((buildings: Building[], sections: Section[], pens: Pen[], valves: Valve[]) => {
	const locState = { buildings, sections, pens, valves };
	return locState as LocationsState;
});

export const memoizeTreatmentAndLog = memoize((treatments: ITreatment[], logs: ITreatment[]) =>
	mergeArrays(treatments, logs),
);

export const memoizeTreatmentPlanAndLog = memoize((plans: ITreatmentPlan[], logs: ITreatmentPlan[]) =>
	mergeArrays(plans, logs),
);

export const memoizeStemAnimal = memoize(
	(activeAnimal: IStemAnimal[], departedAnimals: IStemAnimal[], stemAnimalId: string | undefined) => {
		let stemAnimal: IStemAnimal | undefined;
		stemAnimal = activeAnimal.find(stem => stem.id === stemAnimalId);
		if (!stemAnimal) {
			stemAnimal = departedAnimals.find(stem => stem.id === stemAnimalId);
		}
		return stemAnimal;
	},
);

export const memoizeFamilyTree = memoize(
	(activeAnimal: IStemAnimal[], departedAnimals: IStemAnimal[], stemAnimalId: string) => {
		let animalDictionary = activeAnimal.concat(departedAnimals).keyValuePairSingle('id');
		let familyTree: FamilyTree = {};
		familyTree.current = animalDictionary[stemAnimalId];
		//Handle parents
		if (familyTree.current) {
			familyTree.mom = familyTree.current.momMongoId
				? animalDictionary[familyTree.current.momMongoId]
				: undefined;
			familyTree.dad = familyTree.current.dadMongoId
				? animalDictionary[familyTree.current.dadMongoId]
				: undefined;

			//Handle mom parents
			if (familyTree.mom) {
				familyTree.momGrandmother = familyTree.mom.momMongoId
					? animalDictionary[familyTree.mom.momMongoId]
					: undefined;
				familyTree.momGrandfather = familyTree.mom.dadMongoId
					? animalDictionary[familyTree.mom.dadMongoId]
					: undefined;
			}

			//Handle dad parents
			if (familyTree.dad) {
				familyTree.dadGrandmother = familyTree.dad.momMongoId
					? animalDictionary[familyTree.dad.momMongoId]
					: undefined;
				familyTree.dadGrandfather = familyTree.dad.dadMongoId
					? animalDictionary[familyTree.dad.dadMongoId]
					: undefined;
			}
		}
		return familyTree;
	},
);