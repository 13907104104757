import { IBuilding, ISection, IValve, IPen, ILocations } from 'shared/api/api';
import { SyncableSingle } from 'shared/state/models/syncable';

export const LOAD = 'LOAD';

export const UPSERT_BUILDING = 'UPSERT_BUILDING';
export const UPSERT_SECTION = 'UPSERT_SECTION';
export const UPSERT_PEN = 'UPSERT_PEN';
export const UPSERT_VALVE = 'UPSERT_VALVE';
export const SET_RETENTION_TIME_ON_PENS = 'SET_RETENTION_TIME_ON_PENS';

export const REMOVE_BUILDING = 'REMOVE_BUILDING';
export const REMOVE_SECTION = 'REMOVE_SECTION';
export const REMOVE_PEN = 'REMOVE_PEN';
export const REMOVE_VALVE = 'REMOVE_VALVE';

export const SET_CURRENT_LOCATION = 'SET_CURRENT_LOCATION';
export const SET_USE_CURRENT_LOCATION = 'SET_USE_CURRENT_LOCATION';

export interface Building extends IBuilding {
	isLoading?: boolean;
}

export interface Section extends ISection {
	isLoading?: boolean;
}

export interface Valve extends IValve {
	isLoading?: boolean;
}

export interface Pen extends IPen {
	isLoading?: boolean;
}

export interface CurrentLocation {
	locationString?: string;
	penId?: string;
	sectionId?: string;
}

export interface LocationsState extends SyncableSingle<ILocations> {
	buildings: Building[];
	sections: Section[];
	pens: Pen[];
	valves: Valve[];

	updateBuildings: Building[];
	updateSections: Section[];
	updatePens: Pen[];
	updateValves: Valve[];
	currentLocation: CurrentLocation;
	useCurrentLocation: boolean;
}
