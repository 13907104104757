import React from 'react';
import { Option } from 'react-dropdown';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AnimalReportType, AnimalType, Equipment } from 'shared/api/api';
import { generateAnimalReportOptions } from 'shared/helpers/animal-report/animal-report-setting-helper';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { GetSyncData as AnimalReportSettingGetSyncData } from 'shared/state/ducks/animal-report-settings/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import PageContainer from 'web/view/components/page-container/page-container';
import { SkioldDropdown } from 'web/view/components/skiold-components/skiold-dropdown/skiold-dropdown';
import ActiveAnimalReportList from 'web/view/components/stem-animal/animal-report-list/active-animals/active-animals-report-list';
import MatingBatchReport from 'web/view/components/stem-animal/animal-report-list/mating-batch-report/mating-batch-report';
import { Heading } from 'web/view/components/utils/heading';
import './animal-report-picker.scss';
import ProductionReport from 'web/view/components/stem-animal/animal-report-list/production-report/production-report';
import FeedingReportOverview from 'web/view/components/stem-animal/animal-report-list/feeding-report/feeding-report-overview';

const mapStateToProps = (state: WebAppState) => {
	return {
		siteId: state.profile.active!.siteId,
		animalReportSettings: state.animalReportSettings.entities,
		usesEsf: state.processEquipments.entities.find(a => a.equipment === Equipment.ESF),
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	getAnimalReportSettingsBySiteId: (siteId: string) => AnimalReportSettingGetSyncData()(dispatch),
});

export interface State {
	selected: Option;
	reportOptions: Option[];
	renderTopLeft: JSX.Element;
	renderTopRight: JSX.Element;
	renderTopMiddle?: JSX.Element;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class AnimalReportPicker extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			selected: { value: ' ', label: ' ' },
			renderTopRight: <ViewWeb />,
			renderTopLeft: <ViewWeb />,
			renderTopMiddle: <ViewWeb />,
			reportOptions: [],
		};
	}

	public componentDidMount() {
		if (this.props.siteId) {
			const options = this.generateOptionData();
			const matingBatch = options.find(option => option.value === AnimalReportType.MatingBatchReportSetting)!;
			this.setState({ reportOptions: options, selected: matingBatch });
			this.props.getAnimalReportSettingsBySiteId(this.props.siteId);
		}
	}

	public render() {
		return (
			<PageContainer className="animal-report-picker">
				<Heading text={localized('CHOOSEREPORT')} />
				<ViewWeb className="general-padding">
					{/* Header row */}
					<ViewWeb className="animal-report-top-container">
						{/* Left header column */}
						<ViewWeb className="start sizing vertical-center">{this.state.renderTopLeft}</ViewWeb>
						{/* Center header column */}
						<ViewWeb className="align-center">
							<SkioldDropdown
								onValueChanged={this.selectedReportChanged}
								selectedValue={this.state.selected}
								items={this.generateOptionData()}
								containerClassName="select-report-type sizing"
							/>
							{this.state.renderTopMiddle}
						</ViewWeb>

						{/* Right header column */}
						<ViewWeb className="end sizing vertical-center">{this.state.renderTopRight}</ViewWeb>
					</ViewWeb>

					{/* Table row */}
					{this.state.selected &&
						this.state.selected.value === AnimalReportType.ActiveAnimalsReportSetting && (
							<ActiveAnimalReportList
								topMiddle={this.setTopMiddle}
								topLeft={this.setTopLeft}
								topRight={this.setTopRight}
							/>
						)}
					{this.state.selected && this.state.selected.value === AnimalReportType.MatingBatchReportSetting && (
						<MatingBatchReport topLeft={this.setTopLeft} topRight={this.setTopRight} />
					)}
					{this.state.selected && this.state.selected.value === 'pReport' && (
						<ProductionReport
							animalType={AnimalType.Sow}
							topLeft={this.setTopLeft}
							topRight={this.setTopRight}
						/>
					)}
					{this.state.selected && this.state.selected.value === 'feedingReport' && (
						<FeedingReportOverview topLeft={this.setTopLeft} topRight={this.setTopRight} />
					)}
				</ViewWeb>
			</PageContainer>
		);
	}

	private setTopLeft = (res: JSX.Element) => {
		this.setState({ renderTopLeft: res });
	};

	private setTopRight = (res: JSX.Element) => {
		this.setState({ renderTopRight: res });
	};

	private setTopMiddle = (res: JSX.Element) => {
		this.setState({ renderTopMiddle: res });
	};

	private generateOptionData = () =>
		generateAnimalReportOptions(this.props.animalReportSettings, true, this.props.usesEsf);

	private selectedReportChanged = (option: Option) => {
		if (option.value !== this.state.selected.value) {
			this.setState({
				selected: option,
				renderTopRight: <ViewWeb />,
				renderTopLeft: <ViewWeb />,
				renderTopMiddle: undefined,
			});
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(AnimalReportPicker);
