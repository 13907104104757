import { Sorting } from '@devexpress/dx-react-grid';
import memoize from 'memoize-one';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Gender } from 'shared/api/api';
import PenIcon from 'shared/assets/src-assets/png/pen_icon.png';
import sokort_icon from 'shared/assets/src-assets/png/sokort_icon.png';
import { getDateString } from 'shared/helpers/date-helpers';
import {
	exactFilterMethodGrid,
	exactNumberFilterMethodGrid,
	rangeFilterMethodGrid,
} from 'shared/helpers/general-helpers';
import { getCompleteLocationBySow, LocationModel } from 'shared/helpers/location-helper';
import { findBatch } from 'shared/helpers/matingbatch-helper';
import { NaturalSortDates } from 'shared/helpers/natural-sort';
import { hasNucleusFeatureCEFN } from 'shared/helpers/nucleus-management-helper/nucleus-management-helper';
import { getCycleDays } from 'shared/helpers/pregnancy-helper/generel-pregnancy-helpers';
import { RefType } from 'shared/helpers/ref-type';
import { calculateAnimalKind, getState, idNumberFilter, printIdNumber } from 'shared/helpers/stemanimal-helper/stemanimal-helper';
import { selectNucleusManagementOrAssignIdAccess } from 'shared/state/ducks/site/reducer';
import { localized, localizedDynamic } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { SkioldModal } from 'web/view/components/skiold-components/skiold-modal/skiold-modal';
import SkioldTableGrid, {
	SkioldTableGrid as SkioldTableRef,
} from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { SkioldTouchableOpacity } from 'web/view/components/skiold-components/skiold-touchable-opacity';
import { SkioldImage } from 'web/view/components/utils/svg/skiold-image';
import { ViewWeb } from 'web/view/components/utils/web-view';
import SowCard from 'web/view/pages/sow-card/sow-card';
import SowEvents from 'web/view/pages/sow-events/sow-events';
import { getWebAnimalColorStyleTable } from 'web/web-helpers/general-web-helpers';
import { SowListConstants } from '../table-constants';
import { ActiveSow } from './active-sows-model';
import './active-sows.scss';

interface PropsFromParent {
	fromDate: Date | undefined;
	toDate: Date | undefined;
	dateFilterProperty: string;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		sows: state.stemAnimals.entities,
		pregnancyEvents: state.pregnancyEvents.entities,
		locations: state.locations,
		moveEvents: state.moveEvents.entities,
		generalSettings: state.generalSettings.entity,
		nucleusManagement: selectNucleusManagementOrAssignIdAccess(state),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
	return {};
};

export interface State {
	events: Array<{ value: string }>;
	selected: string;
	editModalOpen: boolean;
	stemAnimalIdToEdit: string;
	sowCardModal: boolean;
	loading: boolean;
	columns: any[];
	columnExte: any[];
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

export class ActiveSows extends React.PureComponent<Props, State> {
	public SkioldTableRef: SkioldTableRef | undefined;
	private generateData = memoize((sows, pregnancyEvents, moveEvents) => this.getActiveSows());
	private defaultSorting: Sorting[] = [{ columnName: 'animalNumber', direction: 'asc' }];

	constructor(props: Props) {
		super(props);
		this.state = {
			events: [],
			selected: '',
			editModalOpen: false,
			stemAnimalIdToEdit: '',
			sowCardModal: false,
			loading: false,
			columns: this.generateColumns(),
			columnExte: this.generateColumnsExtensions(),
		};
	}

	public render() {
		return (
			<ViewWeb className="active-sows">
				<ViewWeb className="outer-div-margin">
					<SkioldTableGrid
						columns={this.state.columns}
						tableKey={'activeSows'}
						ref={this.setRef}
						data={this.getFilteredData(
							this.generateData(this.props.sows, this.props.pregnancyEvents, this.props.moveEvents),
						)}
						ColumnExtensions={this.state.columnExte}
						sortHeaderId={this.defaultSorting}
						showPagination={true}
					/>
				</ViewWeb>

				<SkioldModal padding="0" isOpen={this.state.editModalOpen} close={this.closeModal}>
					<SowEvents
						stemAnimalId={this.state.stemAnimalIdToEdit}
						closeEditModal={this.closeModal}
						theme="dark"
					/>
				</SkioldModal>
				<SkioldModal
					shouldCloseOnOverlayClick={true}
					padding="0"
					isOpen={this.state.sowCardModal}
					close={this.closeModal}
					justify-content="flex-end"
					max-width="calc(100% - 220px)"
				>
					<SowCard stemAnimalIdFromParent={this.state.stemAnimalIdToEdit} close={this.closeModal} />
				</SkioldModal>
			</ViewWeb>
		);
	}

	public GetSortedData() {
		if (this.SkioldTableRef !== undefined) {
			return this.SkioldTableRef.currentData;
		}
		return [];
	}

	private setRef = (m: any) => {
		if (m) {
			this.SkioldTableRef = m;
		}
	};

	private getActiveSows() {
		let location = new LocationModel(this.props.locations);
		return this.props.sows
			.filter(sow => sow.gender === Gender.Female && sow.entranceDate && sow.animalNumber)
			.map(sow => {
				const { building, section, pen } = getCompleteLocationBySow(sow, location, this.props.moveEvents);

				let activeSow: ActiveSow = {
					id: sow.id!,
					animalNumber: sow.animalNumber,
					batch: findBatch(sow.id!),
					birthDate: sow.birthDate,
					breedIndex: sow.breedIndex,
					breedIndexDate: sow.breedIndexDate,
					condition: getState(sow.id!),
					cycleDays: getCycleDays(sow.id!),
					dadAnimalNumber: sow.dadAnimalNumber,
					dadIdNumber: sow.dadIdNumber,
					entranceDate: sow.entranceDate,
					entranceType: localizedDynamic(sow.entranceType!),
					idNumber: sow.idNumber,
					kind: calculateAnimalKind(sow),
					momAnimalNumber: sow.momAnimalNumber,
					momIdNumber: sow.momIdNumber,
					race: sow.race,
					teats: sow.teats,
					transponder: sow.transponder,
					penName: pen && section && section.usePens ? pen.name : '',
					buildingName: building ? building.name : '',
					sectionName: section && building && building.useSections ? section.name : '',
					momRace: sow.momRace,
					dadRace: sow.dadRace,
					breedingNumber: sow.breedingNumber ? sow.breedingNumber.toString() : '',
					earNotch: sow.earNotch,
					raceLine: sow.raceLine,
				};
				return activeSow;
			});
	}

	private getFilteredData(data: ActiveSow[]) {
		if (!this.props.dateFilterProperty || !this.props.fromDate || !this.props.toDate) {
			return data;
		}

		return data.filter(sow => {
			const date = sow[this.props.dateFilterProperty as keyof ActiveSow];
			return date && date >= this.props.fromDate! && date <= this.props.toDate!;
		});
	}

	private edit(stemAnimalId: string) {
		this.setState({ editModalOpen: true, stemAnimalIdToEdit: stemAnimalId });
	}

	private showSowCard(stemAnimalId: string) {
		this.setState({ sowCardModal: true, stemAnimalIdToEdit: stemAnimalId });
	}

	private closeModal = () => {
		this.setState({ editModalOpen: false, stemAnimalIdToEdit: '', sowCardModal: false });
	};

	private getStemAnimalNumberColor = (sow: ActiveSow) => {
		return sow && sow ? getWebAnimalColorStyleTable(sow.id) : '';
	};

	private generateColumns() {
		let columns = [] as any;
		columns.push({
			name: 'sowCard',
			title: ' ',
			sortable: false,
			filterable: false,
			isFixedLeft: true,
			getCellValue: (row: ActiveSow) => (
				<SkioldTouchableOpacity
					onPress={() => {
						this.showSowCard(row.id!);
					}}
				>
					<SkioldImage
						width={SowListConstants.iconSVGWidth}
						height={SowListConstants.iconSVGWidth}
						imageData={sokort_icon}
					/>
				</SkioldTouchableOpacity>
			),
		});
		columns.push({
			name: 'edit',
			title: ' ',
			sortable: false,
			filterable: false,
			isFixedLeft: true,
			getCellValue: (row: ActiveSow) => (
				<SkioldTouchableOpacity onPress={() => this.edit(row.id!)}>
					<SkioldImage
						width={SowListConstants.iconSVGWidth}
						height={SowListConstants.iconSVGWidth}
						imageData={PenIcon}
					/>
				</SkioldTouchableOpacity>
			),
		});
		columns.push({
			name: 'animalNumber',
			title: localized('animalNr'),
			className: this.getStemAnimalNumberColor,
			isFixedLeft: true,
			filterFunction: exactFilterMethodGrid,
			getCellValue: (d: ActiveSow) => d.animalNumber,
		});
		columns.push({
			name: 'kind',
			title: localized('kind'),
			getCellValue: (d: ActiveSow) => localizedDynamic(d.kind!),
		});
		columns.push({
			name: 'condition',
			title: localized('condition'),
			width: SowListConstants.conditionWidth,
			getCellValue: (d: ActiveSow) => localizedDynamic(d.condition!),
		});
		columns.push({
			name: 'cycleDays',
			title: localized('cycleDays'),
			width: SowListConstants.cycleDaysWidth,
			getCellValue: (d: ActiveSow) => d.cycleDays,
			filterFunction: rangeFilterMethodGrid,
		});
		columns.push({
			name: 'buildingName',
			title: localized('building'),
			width: SowListConstants.entranceDateWidth,
		});
		columns.push({
			name: 'sectionName',
			title: localized('section'),
			width: SowListConstants.entranceDateWidth,
			filterFunction: exactNumberFilterMethodGrid,
		});
		columns.push({
			name: 'penName',
			title: localized('pen'),
			width: SowListConstants.entranceDateWidth,
		});
		columns.push({
			name: 'entranceDate',
			title: localized('entranceDate'),
			width: SowListConstants.entranceDateWidth,
			getCellValue: (d: ActiveSow) => getDateString(d.entranceDate),
			sortFunction: NaturalSortDates,
		});
		columns.push({
			name: 'entranceType',
			title: localized('entranceType'),
			getCellValue: (d: ActiveSow) => d.entranceType,
		});
		columns.push({
			name: 'batch', //sow team
			title: localized('Batch'),
			filterFunction: exactFilterMethodGrid,
			getCellValue: (d: ActiveSow) => d.batch,
		});
		columns.push({
			name: 'transponder',
			title: localized('transponder'),
			getCellValue: (d: ActiveSow) => d.transponder,
		});
		columns.push({
			name: 'teats',
			title: localized('Teats'),
			getCellValue: (d: ActiveSow) => d.teats,
		});
		columns.push({
			name: 'idNumber',
			title: localized('idNumber'),
			getCellValue: (d: ActiveSow) => printIdNumber(d.idNumber),
			filterFunction: idNumberFilter,
		});
		columns.push({
			name: 'birthDate',
			title: localized('birthdate'),
			getCellValue: (d: ActiveSow) => getDateString(d.birthDate),
			sortFunction: NaturalSortDates,
		});
		columns.push({
			name: 'race',
			title: localized('Breed'),
			getCellValue: (d: ActiveSow) => d.race,
		});
		if (this.props.generalSettings.showRaceLine) {
			columns.push({
				name: 'raceLine',
				title: localized('RaceLine'),
			});
		}
		columns.push({
			name: 'breedIndex',
			title: localized('index'),
			getCellValue: (d: ActiveSow) => d.breedIndex,
		});
		columns.push({
			name: 'breedIndexDate',
			title: localized('indexDate'),
			getCellValue: (d: ActiveSow) => getDateString(d.breedIndexDate),
			sortFunction: NaturalSortDates,
		});

		if (this.props.nucleusManagement) {
			columns.push({
				name: 'breedingNumber',
				title: localized('BreedingNr'),
				getCellValue: (d: ActiveSow) => d.breedingNumber,
			});
		}
		if (hasNucleusFeatureCEFN(this.props.nucleusManagement)) {
			columns.push({
				name: 'earNotch',
				title: localized('EarNotch'),
				getCellValue: (d: ActiveSow) => d.earNotch,
			});
		}

		if (this.props.generalSettings.stemAnimalShowMomDadData) {
			columns.push(
				{
					name: 'dadAnimalNumber',
					title: localized('dadNr'),
					getCellValue: (d: ActiveSow) => d.dadAnimalNumber,
				},
				{
					name: 'dadRace',
					title: localized('dadRace'),
					getCellValue: (d: ActiveSow) => d.dadRace,
				},
				{
					name: 'dadIdNumber',
					title: localized('dadID'),
					getCellValue: (d: ActiveSow) => printIdNumber(d.dadIdNumber),
				},

				{
					name: 'momAnimalNumber',
					title: localized('momNr'),
					getCellValue: (d: ActiveSow) => d.momAnimalNumber,
				},
				{
					name: 'momRace',
					title: localized('momRace'),
					getCellValue: (d: ActiveSow) => d.momRace,
				},
				{
					name: 'momIdNumber',
					title: localized('momID'),
					getCellValue: (d: ActiveSow) => printIdNumber(d.momIdNumber),
				},
			);
		}

		return columns;
	}

	private generateColumnsExtensions() {
		let columnExt = [] as any;
		columnExt.push({
			columnName: 'sowCard',
			filteringEnabled: false,
			sortingEnabled: false,
			resizingEnabled: false,
			width: SowListConstants.iconWidth,
		});
		columnExt.push({
			columnName: 'edit',
			sortingEnabled: false,
			filteringEnabled: false,
			resizingEnabled: false,
			width: SowListConstants.iconWidth,
		});
		columnExt.push({
			columnName: 'animalNumber',
			width: SowListConstants.animalNrWidth,
		});
		columnExt.push({
			columnName: 'kind',
			width: SowListConstants.kindWidth,
		});
		columnExt.push({
			columnName: 'condition',
			width: SowListConstants.conditionWidth,
		});
		columnExt.push({
			columnName: 'cycleDays',
			width: SowListConstants.cycleDaysWidth,
		});
		columnExt.push({
			columnName: 'buildingName',
			width: SowListConstants.cycleDaysWidth,
		});
		columnExt.push({
			columnName: 'sectionName',
			width: SowListConstants.cycleDaysWidth,
		});
		columnExt.push({
			columnName: 'penName',
			width: SowListConstants.cycleDaysWidth,
		});
		columnExt.push({
			columnName: 'entranceDate',
			width: SowListConstants.entranceDateWidth,
		});
		columnExt.push({
			columnName: 'entranceType',
			width: SowListConstants.entranceTypeWidth,
		});
		columnExt.push({
			columnName: 'batch',
			width: SowListConstants.batchWidth,
		});
		columnExt.push({
			columnName: 'transponder',
			width: SowListConstants.transponderWidth,
		});
		columnExt.push({
			columnName: 'teats',
			width: SowListConstants.teatsWidth,
		});
		columnExt.push({
			columnName: 'idNumber',
			width: SowListConstants.iDNumberWidth,
		});
		columnExt.push({
			columnName: 'birthDate',
			width: SowListConstants.birthdayWidth,
		});
		columnExt.push({
			columnName: 'race',
			width: SowListConstants.raceWidth,
		});
		if (this.props.generalSettings.showRaceLine) {
			columnExt.push({
				columnName: 'raceLine',
				width: 100,
			});
		}
		columnExt.push({
			columnName: 'breedIndex',
			width: SowListConstants.indexWidth,
		});
		columnExt.push({
			columnName: 'breedIndexDate',
			width: SowListConstants.indexDateWidth,
		});

		if (this.props.nucleusManagement) {
			columnExt.push({
				columnName: 'breedingNumber',
				width: SowListConstants.breedingNumber,
			});
		}
		if (hasNucleusFeatureCEFN(this.props.nucleusManagement)) {
			columnExt.push({
				columnName: 'earNotch',
				width: SowListConstants.breedingNumber,
			});
		}

		if (this.props.generalSettings.stemAnimalShowMomDadData) {
			columnExt.push(
				{
					columnName: 'dadAnimalNumber',
					width: SowListConstants.dadNrWidth,
				},
				{
					columnName: 'dadRace',
					width: 100,
				},
				{
					columnName: 'dadIdNumber',
					width: SowListConstants.dadIdWidth,
				},

				{
					columnName: 'momAnimalNumber',
					width: SowListConstants.momNrWidth,
				},
				{
					columnName: 'momRace',
					width: 100,
				},
				{
					columnName: 'momIdNumber',
					width: SowListConstants.momIdWidth,
				},
			);
		}
		return columnExt;
	}
}

export default connect<ReturnType<typeof mapStateToProps>, ReturnType<typeof mapDispatchToProps>, RefType, WebAppState>(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{ forwardRef: true },
)(ActiveSows);
