import React from 'react';
import isEqual from 'react-fast-compare';
import { connect } from 'react-redux';
import { GrowthPigsEvent } from 'shared/api/api';
import {
	CheckIfGrowthPigEventIsBetweenTwoDates,
	defaultGrowthPigEventListDates,
	GenerateGrowthPigEntranceEventBundleRowItems,
	GrowthPigEntranceEventBundleListRow,
	GrowthPigEntranceEventListProps,
	GrowthPigEntranceEventListState,
	GrowthPigEventListEntranceMapDispatchToProps,
	GrowthPigEventListMapStateToProps,
	GrowthPigSummaryBundleRowItem,
} from 'shared/helpers/growth-pigs-helper/growth-pig-event-list-helper';
import { GetByGrowthPigEntranceEventsPdfByDates } from 'shared/helpers/growth-pigs-helper/growth-pig-event-pdf-helper';
import { SkioldFetch } from 'web/view/components/skiold-components/skiold-fetch/skiold-fetch';
import { SkioldModal } from 'web/view/components/skiold-components/skiold-modal/skiold-modal';
import { SkioldTableGrid as SkioldTableRef } from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { ViewWeb } from 'web/view/components/utils/web-view';
import GrowthPigEvents from 'web/view/pages/growth-pigs-events/growth-pig-event';
import { renderTopRightGrowthPigEventLists } from '../growth-pig-event-list-web-helper';
import { GpeEntranceEditModal } from './growth-pig-entrance-edit-modal';
import { GrowthPigEntranceEventTable } from './growth-pig-entrance-event-table';

export class GrowthPigEntranceEventList extends React.PureComponent<
	GrowthPigEntranceEventListProps,
	GrowthPigEntranceEventListState
> {
	private skioldTableRef?: SkioldTableRef = undefined;

	constructor(props: GrowthPigEntranceEventListProps) {
		super(props);
		const bundleData = GenerateGrowthPigEntranceEventBundleRowItems(
			this.props.growthPigsEvents.filter(this.filterToProductionType),
			this.props.locations,
			this.props.gped,
		);
		this.state = {
			bundleData,
			...defaultGrowthPigEventListDates(),
		};
	}

	public componentDidUpdate(prevProps: GrowthPigEntranceEventListProps, prevState: GrowthPigEntranceEventListState) {
		if (
			!isEqual(prevProps.growthPigsEvents, this.props.growthPigsEvents) ||
			// !isEqual(prevState.dateFrom, this.state.dateFrom) ||
			// !isEqual(prevState.dateTo, this.state.dateTo) ||
			!isEqual(prevProps.productionType, this.props.productionType)
		) {
			const updatedData = GenerateGrowthPigEntranceEventBundleRowItems(
				this.props.growthPigsEvents.filter(
					CheckIfGrowthPigEventIsBetweenTwoDates(this.props, this.props.productionType),
				),
				this.props.locations,
				this.props.gped,
			);
			this.setState({
				bundleData: updatedData,
			});
		}

		if (!isEqual(prevState.bundleData, this.state.bundleData)) {
			if (this.props.topRight && this.state.bundleData) {
				this.props.topRight(renderTopRightGrowthPigEventLists(this.state.bundleData, this.printData, 'Bought'));
			}
		}
	}

	private filterToProductionType = (gpe: GrowthPigsEvent) => gpe.toProductionType === this.props.productionType;

	public render() {
		return (
			<ViewWeb className="sow-entrance">
				<GrowthPigEntranceEventTable
					openBundleEditModal={this.openEditModal}
					removeItem={this.props.removeGrowthPigsEvent}
					onFilterChanged={this.onFilterChanged}
					setRef={this.setRef}
					bundleData={this.state.bundleData}
				/>
				<SkioldModal
					shouldCloseOnOverlayClick={true}
					padding="0"
					isOpen={!!this.state.bundleEvent}
					close={this.closeModal}
					justify-content="flex-end"
					max-width="calc(100% - 220px)"
				>
					{this.state.bundleEvent &&
					this.state.bundleEvent.bundleId &&
					!this.state.bundleEvent.singleEvent ? (
						<GpeEntranceEditModal closeModal={this.closeModal} bundle={this.state.bundleEvent} />
					) : this.state.bundleEvent && this.state.bundleEvent.singleEvent ? (
						<GrowthPigEvents closeEditModal={this.closeModal} event={this.state.bundleEvent.singleEvent} />
					) : null}
				</SkioldModal>
			</ViewWeb>
		);
	}

	private openEditModal = (event: GrowthPigSummaryBundleRowItem) => {
		let gpeEvent;

		if (event && !event.bundleId && event.gpeIds.length === 1) {
			gpeEvent = this.props.growthPigsEvents.find(gpe => gpe.id === event.gpeIds[0]);
		}
		this.setState({
			bundleEvent: this.state.bundleData && this.state.bundleData.find(gpe => gpe.bundleId === event.bundleId),
			event: gpeEvent,
		});
	};

	private closeModal = () => {
		this.setState({ bundleEvent: undefined, event: undefined });
	};

	private setRef = ref => {
		this.skioldTableRef = ref;
	};

	public componentDidMount() {
		if (this.props.topRight && this.props.topLeft && this.state.bundleData) {
			this.props.topRight(renderTopRightGrowthPigEventLists(this.state.bundleData, this.printData, 'Bought'));
			this.props.topLeft(this.renderTopLeft());
		}
	}

	private onFilterChanged = () => {
		const data = this.skioldTableRef
			? (this.skioldTableRef.GetSortedData() as GrowthPigEntranceEventBundleListRow[])
			: this.state.bundleData
			? this.state.bundleData
			: [];
		if (this.props.topRight) {
			this.props.topRight(renderTopRightGrowthPigEventLists(data, this.printData, 'Bought'));
		}
	};

	public renderTopLeft = () => {
		return <SkioldFetch toDate={this.state.dateTo} fromDate={this.state.dateFrom} fetchData={this.fetchData} />;
	};

	private printData = async () => {
		if (this.props.profile) {
			await GetByGrowthPigEntranceEventsPdfByDates(
				this.skioldTableRef
					? (this.skioldTableRef.GetSortedData() as GrowthPigEntranceEventBundleListRow[])
							.map(row => row.gpeIds)
							.reduce((totalIds, ids) => (totalIds ? totalIds.concat(ids) : ids))
					: [],
				'GrowthPigEntranceEvents.pdf',
				this.props.profile.siteId!,
				this.props.productionType,
				Intl.DateTimeFormat().resolvedOptions().timeZone,
				this.props.profile.language,
			);
		}
	};

	private fetchData = (dateFrom: Date, dateTo: Date) => {
		this.setState({ dateFrom: dateFrom, dateTo: dateTo });
		this.props.getGrowthPigsEntranceEventsByDates(dateFrom, dateTo);
	};
}

export default connect(
	GrowthPigEventListMapStateToProps,
	GrowthPigEventListEntranceMapDispatchToProps,
)(GrowthPigEntranceEventList);
