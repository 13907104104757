import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import * as Action from './actions';
import { StoreContainer } from 'shared/state/store-container';
import { Dispatch } from 'redux';
import ObjectID from 'bson-objectid';
import { IDeadPigletsEvent, DeadPigletsEvent } from 'shared/api/api';
export function GetSyncData() {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;
	const lastSyncDate = state.deadPigletsEvents.lastSyncDate;

	if (state.deadPigletsEvents.syncInProgress) {
		return (dispatch: Dispatch<any>) => {
			return Promise.resolve();
		};
	}
	return AsyncOperationBuilder2(
		Action.getSyncDataDeadPigletsEvent,
		client => client.deadPigletsEvent_Sync(siteId, lastSyncDate),
		{
			siteId,
			lastSyncDate,
		},
	);
}
export function SaveDeadPigletsEvent(deadPigletsEvent: IDeadPigletsEvent) {
	const state = StoreContainer.getState();
	const prevEntity = state.deadPigletsEvents.entities.find(deadPiglet => deadPiglet.id === deadPigletsEvent.id);

	if (!deadPigletsEvent.id) {
		deadPigletsEvent.id = new ObjectID().toHexString();
	}

	//

	return AsyncOperationBuilder2(
		Action.saveDeadPigletsEvent,
		client => client.deadPigletsEvent_Post(DeadPigletsEvent.fromJS(deadPigletsEvent)),
		DeadPigletsEvent.fromJS(deadPigletsEvent),
		prevEntity,
	);
}

export function RemoveDeadPigletsEvent(id: string) {
	return (dispatch: Dispatch<any>) => {
		dispatch(Action.removeDeadPigletsEvent.started(id));
	};
}

export function GetDeparturedDeadPigletsEvent() {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;

	return AsyncOperationBuilder2(
		Action.getDeparturedDeadPigletsEvent,
		client => client.deadPigletsEvent_GetDeparturedDeadPigletsEvent(siteId),
		{ siteId },
	);
}

export function GetPigletEventsByPeriod(fromDate: Date, toDate: Date) {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;

	return AsyncOperationBuilder2(
		Action.getPigletEventsByPeriod,
		client => client.deadPigletsEvent_GetDeadPigletsByPeriod(siteId, fromDate, toDate),
		{ siteId, fromDate, toDate },
	);
}

export function SaveSyncData() {
	const state = StoreContainer.getState();
	const updates = state.deadPigletsEvents.updates;
	let promises = new Array<Promise<void>>();
	return (dispatch: Dispatch<any>) => {
		if (state.deadPigletsEvents.saveSyncInProgress) {
			return Promise.resolve();
		}
		updates.forEach(update => {
			promises.push(SaveDeadPigletsEvent(update)(dispatch));
		});

		return Promise.all(promises);
	};
}
