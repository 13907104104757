import { FeedComputersState } from './types';
import { createReducer } from '@reduxjs/toolkit';
import { getControllerTaskAlerts, getControllerTasks } from './operation';

export const initialState: FeedComputersState = {
	Tasks: [],
	Alerts: [],
};

const feedComputersReducer = createReducer(initialState, builder => {
	builder.addCase(getControllerTasks.fulfilled, (state, action) => {
		state.Tasks = action.payload ?? [];
	});

	builder.addCase(getControllerTaskAlerts.fulfilled, (state, action) => {
		const alert = action.payload;
		if (alert === undefined || alert === null) {
			return;
		}
		const index = state.Alerts.findIndex(a => a.id === alert.id);

		if (index < 0) {
			state.Alerts.push(alert);
		} else {
			state.Alerts[index] = alert;
		}
	});
});

export default feedComputersReducer;
