import React, { FC, useCallback } from 'react';
import { SkioldModal } from '../../../skiold-components/skiold-modal/skiold-modal';
import { IAdjustmentDTO } from '../../../../../../shared/api/api';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { localized, localizedDynamic } from '../../../../../../shared/state/i18n/i18n';
import { SkioldTable } from '../../../skiold-components/skiold-table/skiold-table';
import moment from 'moment';
import { ViewWeb } from '../../../utils/web-view';
import { SkioldButton } from '../../../skiold-components/skiold-button/skiold-button';

interface Props {
	adjustmentModalOpen: boolean;
	closeAdjustmentModal: () => void;
	adjustments: IAdjustmentDTO[] | undefined;
	date: ReturnType<typeof moment>;
}

export const AdjustmentsModal: FC<Props> = React.memo(props => {
	const generateColumns = useCallback(() => {
		return [
			{
				id: 'penName',
				Header: localized('pen'),
				accessor: (a: any) => a.penName,
			},
			{
				id: 'adjustmentType',
				Header: localized('Type'),
				accessor: (a: any) => localizedDynamic(a.update.property),
				width: 150,
			},
			{
				id: 'oldValue',
				Header: localized('Old'),
				accessor: a => a.update.prevValue,
			},
			{
				id: 'newValue',
				Header: localized('New'),
				accessor: a => a.update.newValue,
			},
		];
	}, []);

	const renderContent = useCallback(() => {
		if (props.adjustments === undefined) {
			return <TextWeb className={'error-text'}>{localized('NoAdjustmentOnDate')}</TextWeb>;
		}
		return (
			<SkioldTable
				columns={generateColumns()}
				data={props.adjustments
					?.map(a =>
						a.updates?.map(u => {
							return { penName: a.penName, update: u };
						}),
					)
					.flat(1)}
				sortHeaderId={'penName'}
				filterable={false}
			/>
		);
	}, [props.adjustments, generateColumns]);

	return (
		<SkioldModal
			shouldCloseOnOverlayClick={true}
			isOpen={props.adjustmentModalOpen}
			close={props.closeAdjustmentModal}
			className={'adjustment-modal'}
			maxWidth={'500px'}
		>
			<ViewWeb className={'adjustment-header'}>
				<TextWeb className={'error-text'}>
					{localized('Adjustments')}: {props.date.format('DD/MM/YY')}
				</TextWeb>
				<ViewWeb className={'button-view-style'}>
					<SkioldButton title={localized('Close')} onPress={props.closeAdjustmentModal} theme={'grey'} />
				</ViewWeb>
			</ViewWeb>
			{renderContent()}
		</SkioldModal>
	);
});
