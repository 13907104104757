import { Action, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { Actions } from '../ducks/profile';
import { LanguageType } from '../i18n/i18n';
import { changeLanguage } from '../i18n/i18n';
import { isType } from 'typescript-fsa';

export const persistTranslationMiddleware: () => Middleware = () => {
    return <S>(api: MiddlewareAPI) => (next: Dispatch) => <A extends Action>(action: A): A => {
        if (action.type === 'persist/REHYDRATE') {
            // tslint:disable-next-line:no-any
            const state = (action as any).payload;
            if (state && state.profile && state.profile.active && state.profile.active.language) {
                changeLanguage(state.profile.active!.language);
            }
        }
        if (isType(action, Actions.getProfileAsync.done)) {
            let active = action.payload.result
            if (active && active.language) {
                changeLanguage(active.language! as LanguageType);
            }
        }
        return next(action);
    };
};
