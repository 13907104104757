import memoize from 'memoize-one';
import { AnimalKind, AnimalType, IGrowthPigEventDto, ITreatmentPlan } from 'shared/api/api';
import { setOneDecimalsAsNumber } from 'shared/helpers/general-helpers';
import { getTotalPigsOnPens } from 'shared/helpers/growth-pigs-helper/growth-pig-event-function-helper';
import { GetTotalWeightByEntrance } from 'shared/helpers/growth-pigs-helper/growth-pig-weight-curve-helper';

export function GetProductionType(animalKind?: AnimalKind) {
	switch (animalKind) {
		case AnimalKind.Sow:
			return AnimalType.Sow;
		case AnimalKind.Weaner:
			return AnimalType.Weaner;
		case AnimalKind.Finisher:
			return AnimalType.Finisher;
		default:
			return AnimalType.FRATS;
	}
}

export const getMaxNumberGrowthPigsForAmountRow = memoize(
	(gpes: IGrowthPigEventDto[], animalKind?: AnimalType, sectionId?: string, penId?: string) => {
		const penIdAmount = getTotalPigsOnPens(gpes, animalKind, sectionId, penId);
		let total = 0;
		if (penId) {
			total = penIdAmount[penId] ? penIdAmount[penId] : 0;
		} else {
			Object.keys(penIdAmount).forEach(id => {
				total += penIdAmount[id];
			});
		}
		return total;
	},
);

export const getTreatmentPlanWithSetAvgWeight = (
	treatmentPlanState: ITreatmentPlan,
	gpedtos: IGrowthPigEventDto[],
	animalKind?: AnimalKind,
	selectedSectionId?: string,
	penId?: string,
	dateTo?: Date,
) => {
	const avgWeight = getAvgWeightGrowthPigs(gpedtos, animalKind, selectedSectionId, penId, dateTo);
	const treatmentPlan = { ...treatmentPlanState };
	treatmentPlan.avgWeight = avgWeight;
	return treatmentPlan;
};

export const getAvgWeightGrowthPigs = memoize(
	(gpes: IGrowthPigEventDto[], animalKind?: AnimalKind, sectionId?: string, penId?: string, dateTo?: Date) => {
		const sectionGpe = gpes.find(
			gpe => gpe.sectionId === sectionId && GetProductionType(animalKind) === gpe.productionType,
		);
		let avgWeight = 0;
		if (sectionGpe && sectionGpe.growthPigEventDistributions) {
			if (penId) {
				const gpedToUse = sectionGpe.growthPigEventDistributions.filter(
					gpe => gpe.penId === penId && gpe.date && gpe.date <= (dateTo ? dateTo : new Date()),
				);
				const currentAmountOnPen = gpedToUse.reduce(
					(sum, current) => sum + (current.amount !== undefined ? current.amount : 0),
					0,
				);
				const currentWeightOnPen = gpedToUse.reduce(
					(sum, current) =>
						sum +
						(current.totalWeightCurve !== undefined && current.amount && current.amount < 0
							? current.totalWeightCurve
							: current.date && current.totalWeight && current.amount && current.amount > 0
							? GetTotalWeightByEntrance(
									current.date,
									Math.abs(current.totalWeight),
									Math.abs(current.amount),
									GetProductionType(animalKind),
									dateTo ? dateTo : new Date(),
									current.isSameProduction,
							  )
							: 0),
					0,
				);
				if (gpedToUse && currentWeightOnPen && currentAmountOnPen) {
					let avgWeightTwoDecimals = setOneDecimalsAsNumber(currentWeightOnPen / currentAmountOnPen);
					if (avgWeightTwoDecimals) {
						return avgWeightTwoDecimals;
					}
				}
			} else {
				const gpedToUse = sectionGpe.growthPigEventDistributions.filter(
					gpe => gpe.date && gpe.date <= (dateTo ? dateTo : new Date()),
				);
				const totalWeight = gpedToUse.reduce(
					(sum, current) =>
						sum +
						(current.totalWeightCurve !== undefined && current.amount && current.amount < 0
							? current.totalWeightCurve
							: current.date && current.totalWeight && current.amount && current.amount > 0
							? GetTotalWeightByEntrance(
									current.date,
									Math.abs(current.totalWeight),
									Math.abs(current.amount),
									GetProductionType(animalKind),
									dateTo ? dateTo : new Date(),
									current.isSameProduction,
							  )
							: 0),
					0,
				);
				const totalAmount = gpedToUse.reduce(
					(sum, current) => sum + (current.amount !== undefined ? current.amount : 0),
					0,
				);
				if (totalAmount) {
					let avgWeightTwoDecimals = setOneDecimalsAsNumber(totalWeight / totalAmount);
					if (avgWeightTwoDecimals) {
						return avgWeightTwoDecimals;
					}
				}
			}
		}
		return avgWeight;
	},
);
