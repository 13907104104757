import React, { FC } from 'react';
import { localized } from 'shared/state/i18n/i18n';
import { SkioldCheckbox } from 'web/view/components/skiold-components/skiold-checkbox/skiold-checkbox';
import { SkioldFormsWrapper } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { useGeneralSetting } from './general-settings-overview';

interface Props {}

export const UseLettersForAnimalSetting: FC<Props> = React.memo(({ ...props }) => {
	const { generalSetting, setGeneralSetting, nucleusManagement } = useGeneralSetting();

	const getFormRows = () => {
		let formRows = new Array<FormRow>();

		formRows.push(getWorkListHeader());
		formRows.push(getUseLettersForAnimalNumber());
		formRows.push(getUseLettersForBoarNumber());
		formRows.push(getRegisterIdForTurnoverPoolYoungAnimals());
		formRows.push(getShowRaceLine());
		formRows.push(getShouldShowDadMomData());
		if (nucleusManagement) {
			formRows.push(getShowIconForFamilyTree());
		}

		formRows.push(getShouldShowInitialsForRegistrations());
		formRows.push(AssignNumbersToBornPiglets());
		return formRows;
	};
	const getWorkListHeader = (): FormRow => {
		return {
			header: localized('Generalt'),
		};
	};

	const getUseLettersForAnimalNumber = (): FormRow => {	
		return {
			name: localized('IsLettersUsedForAnimal'),
			component: (
				<ViewWeb className="two-checkbox-container">
					<SkioldCheckbox onClick={SetUseLettersTrue} isChecked={generalSetting.useLettersForAnimalNumber} />
					<TextWeb>{localized('True')}</TextWeb>
					<SkioldCheckbox
						onClick={SetUseLettersFalse}
						isChecked={!generalSetting.useLettersForAnimalNumber}
					/>
					<TextWeb>{localized('False')}</TextWeb>
				</ViewWeb>
			),
		};
	};

	const getUseLettersForBoarNumber = (): FormRow => {
		return {
			name: localized('IsLettersUsedForBoar'),
			component: (
				<ViewWeb className="two-checkbox-container">
					<SkioldCheckbox
						onClick={SetUseBoarLettersTrue}
						isChecked={generalSetting.useLettersForBoarNumber}
					/>
					<TextWeb>{localized('True')}</TextWeb>
					<SkioldCheckbox
						onClick={SetUseBoarLettersFalse}
						isChecked={!generalSetting.useLettersForBoarNumber}
					/>
					<TextWeb>{localized('False')}</TextWeb>
				</ViewWeb>
			),
		};
	};

	const getShouldShowDadMomData = (): FormRow => {
		return {
			name: localized('ShouldShowDadMomData'),
			component: (
				<ViewWeb className="two-checkbox-container">
					<SkioldCheckbox
						onClick={SetShouldShowDadMomDataTrue}
						isChecked={generalSetting.stemAnimalShowMomDadData}
					/>
					<TextWeb>{localized('True')}</TextWeb>
					<SkioldCheckbox
						onClick={SetShouldShowDadMomDataFalse}
						isChecked={!generalSetting.stemAnimalShowMomDadData}
					/>
					<TextWeb>{localized('False')}</TextWeb>
				</ViewWeb>
			),
		};
	};

	const getShowIconForFamilyTree = (): FormRow => {
		return {
			name: localized('ShowFamilyTree'),
			component: (
				<ViewWeb className="two-checkbox-container">
					<SkioldCheckbox
						onClick={SetShowFamilyTreeTrue}
						isChecked={generalSetting.stemAnimalShowFamilyTree}
					/>
					<TextWeb>{localized('True')}</TextWeb>
					<SkioldCheckbox
						onClick={SetShowFamilyTreeFalse}
						isChecked={!generalSetting.stemAnimalShowFamilyTree}
					/>
					<TextWeb>{localized('False')}</TextWeb>
				</ViewWeb>
			),
		};
	};

	const getShouldShowInitialsForRegistrations = (): FormRow => {
		return {
			name: localized('ShouldShowInitialsForRegistrations'),
			component: (
				<ViewWeb className="two-checkbox-container">
					<SkioldCheckbox
						onClick={SetShouldShowInitialsForRegistrationsTrue}
						isChecked={generalSetting.stemAnimalShowInitials}
					/>
					<TextWeb>{localized('True')}</TextWeb>
					<SkioldCheckbox
						onClick={SetShouldShowInitialsForRegistrationsFalse}
						isChecked={!generalSetting.stemAnimalShowInitials}
					/>
					<TextWeb>{localized('False')}</TextWeb>
				</ViewWeb>
			),
		};
	};

	const getShowRaceLine = (): FormRow => {
		return {
			name: localized('ShowRaceLine'),
			component: (
				<ViewWeb className="two-checkbox-container">
					<SkioldCheckbox onClick={SetShowRaceLineTrue} isChecked={generalSetting.showRaceLine} />
					<TextWeb>{localized('True')}</TextWeb>
					<SkioldCheckbox onClick={SetShowRaceLineFalse} isChecked={!generalSetting.showRaceLine} />
					<TextWeb>{localized('False')}</TextWeb>
				</ViewWeb>
			),
		};
	};

	const AssignNumbersToBornPiglets = (): FormRow => {
		return {
			name: localized('AssignNumbersToBornPiglets'),
			component: (
				<ViewWeb className="two-checkbox-container">
					<SkioldCheckbox
						onClick={SetAssignNumbersToBornPigletsTrue}
						isChecked={generalSetting.assignNumbersToBornPiglets}
					/>
					<TextWeb>{localized('True')}</TextWeb>
					<SkioldCheckbox
						onClick={SetAssignNumbersToBornPigletsFalse}
						isChecked={!generalSetting.assignNumbersToBornPiglets}
					/>
					<TextWeb>{localized('False')}</TextWeb>
				</ViewWeb>
			),
		};
	};

	const getRegisterIdForTurnoverPoolYoungAnimals = (): FormRow => {
		return {
			name: localized('RegisterIdForTurnoverPoolYoungAnimals'),
			component: (
				<ViewWeb className="two-checkbox-container">
					<SkioldCheckbox
						onClick={SetRegisterIdForTurnoverPoolYoungAnimalsTrue}
						isChecked={generalSetting.registerIdForTurnoverPoolYoungAnimals}
					/>
					<TextWeb>{localized('True')}</TextWeb>
					<SkioldCheckbox
						onClick={SetRegisterIdForTurnoverPoolYoungAnimalsFalse}
						isChecked={!generalSetting.registerIdForTurnoverPoolYoungAnimals}
					/>
					<TextWeb>{localized('False')}</TextWeb>
				</ViewWeb>
			),
		};
	};

	const SetShowRaceLineTrue = () => {
		setGeneralSetting({ ...generalSetting, showRaceLine: true });
	};

	const SetShowRaceLineFalse = () => {
		setGeneralSetting({ ...generalSetting, showRaceLine: false });
	};

	const SetShouldShowDadMomDataTrue = () => {
		setGeneralSetting({ ...generalSetting, stemAnimalShowMomDadData: true });
	};

	const SetShouldShowDadMomDataFalse = () => {
		setGeneralSetting({ ...generalSetting, stemAnimalShowMomDadData: false });
	};

	const SetUseLettersTrue = () => {
		setGeneralSetting({ ...generalSetting, useLettersForAnimalNumber: true });
	};

	const SetUseLettersFalse = () => {
		setGeneralSetting({ ...generalSetting, useLettersForAnimalNumber: false });
	};

	const SetShowFamilyTreeTrue = () => {
		setGeneralSetting({ ...generalSetting, stemAnimalShowFamilyTree: true });
	};

	const SetShowFamilyTreeFalse = () => {
		setGeneralSetting({ ...generalSetting, stemAnimalShowFamilyTree: false });
	};

	const SetShouldShowInitialsForRegistrationsTrue = () => {
		setGeneralSetting({ ...generalSetting, stemAnimalShowInitials: true });
	};

	const SetShouldShowInitialsForRegistrationsFalse = () => {
		setGeneralSetting({ ...generalSetting, stemAnimalShowInitials: false });
	};

	const SetUseBoarLettersTrue = () => {
		setGeneralSetting({ ...generalSetting, useLettersForBoarNumber: true });
	};

	const SetUseBoarLettersFalse = () => {
		setGeneralSetting({ ...generalSetting, useLettersForBoarNumber: false });
	};

	const SetAssignNumbersToBornPigletsTrue = () => {
		setGeneralSetting({ ...generalSetting, assignNumbersToBornPiglets: true });
	};

	const SetAssignNumbersToBornPigletsFalse = () => {
		setGeneralSetting({ ...generalSetting, assignNumbersToBornPiglets: false });
	};

	const SetRegisterIdForTurnoverPoolYoungAnimalsTrue = () => {
		setGeneralSetting({ ...generalSetting, registerIdForTurnoverPoolYoungAnimals: true });
	};

	const SetRegisterIdForTurnoverPoolYoungAnimalsFalse = () => {
		setGeneralSetting({ ...generalSetting, registerIdForTurnoverPoolYoungAnimals: false });
	};

	return <SkioldFormsWrapper formRows={getFormRows()} containerClassName="wrapper-container" />;
});
