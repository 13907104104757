import ObjectID from 'bson-objectid';
import { Dispatch } from 'redux';
import { IProductionReportSetting, ProductionReportSetting } from 'shared/api/api';
import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import { StoreContainer } from 'shared/state/store-container';
import * as Action from './actions';

export function SaveProductionReportSetting(productionReportSetting: IProductionReportSetting) {
	if (!productionReportSetting.id) {
		productionReportSetting.id = new ObjectID().toHexString();
	}
	return AsyncOperationBuilder2(
		Action.saveProductionReportSetting,
		client => client.productionReportSetting_Upsert(productionReportSetting as ProductionReportSetting),
		productionReportSetting as ProductionReportSetting,
	);
}

export function GetSyncData() {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;
	const lastSyncDate = state.productionReportSetting.lastSyncDate;

	if (state.productionReportSetting.syncInProgress) {
		return (dispatch: Dispatch<any>) => {
			return Promise.resolve();
		};
	}
	return AsyncOperationBuilder2(
		Action.getSyncData,
		client => client.productionReportSetting_SyncData(siteId, lastSyncDate),
		{
			siteId,
			lastSyncDate,
		},
	);
}
