import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ResultAnalysisWeaningsTableItem } from 'shared/api/api';
import { localized } from 'shared/state/i18n/i18n';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { Heading } from '../../../utils/heading';
import { AnalysisModalMapStateToProps, AnalysisModalPropsFromParent } from '../../analysis-helper';
import { AnalysisModalHeader } from '../analysis-modal-header';
import AnalysisResultsAnalysisWeaningsTable, {
	AnalysisResultsAnalysisWeaningsTable as AnalysisResultsAnalysisWeaningsTableRef,
} from './analysis-results-analysis-weanings-table';
import { onlyUnique } from 'shared/helpers/general-helpers';
import { GetAnalysisResultAnalysisWeaningsTable } from 'shared/state/ducks/analysis/analysis-modal/operation';
import { generatePeriodDto } from 'web/web-helpers/pdf-helper/analysis-pdf-helpers/analysis-pdf-helper';
import { Colors } from 'shared/assets/root-assets/colors';
import { ClipLoader } from 'react-spinners';

interface State {
	loading: boolean;
	count: number;
	data: ResultAnalysisWeaningsTableItem[];
}

const mapDispatchToProps = (dispatch: Dispatch, props: AnalysisModalPropsFromParent) => {
	return {
		GetAnalysisResultAnalysisWeaningsTable: async (siteId: string) =>
			await GetAnalysisResultAnalysisWeaningsTable(
				siteId,
				props.fetchDataValue,
				props.mode,
				props.dateFrom,
				props.dateTo,
			),
	};
};

type Props = ReturnType<typeof AnalysisModalMapStateToProps> &
	ReturnType<typeof mapDispatchToProps> &
	AnalysisModalPropsFromParent;

export class AnalysisResultsPerLitterWeaningsOverview extends React.PureComponent<Props, State> {
	public analysisResultsAnalysisWeaningsTableRef: AnalysisResultsAnalysisWeaningsTableRef | undefined;
	constructor(props: Props) {
		super(props);
		this.state = { loading: true, data: [], count: 0 };
	}

	public componentDidMount() {
		this.props
			.GetAnalysisResultAnalysisWeaningsTable(this.props.siteId)
			.then(tableData => this.setState({ data: tableData, loading: false, count: this.getCount(tableData) }));
	}

	public render() {
		return (
			<ViewWeb>
				<Heading text={localized('Weanings')} />
				<AnalysisModalHeader
					mode={this.props.mode}
					closeModal={this.props.closeModal}
					count={this.state.count}
					value={this.props.fetchDataValue}
					dateFrom={this.props.dateFrom}
					dateTo={this.props.dateTo}
					printData={this.printData}
				/>
				{this.state.loading ? (
					<ViewWeb className="align-items-center basic-padding">
						<ClipLoader color={Colors.yellow} size={100} />
					</ViewWeb>
				) : (
					<AnalysisResultsAnalysisWeaningsTable
						mode={this.props.mode}
						onFilterChange={this.setCount}
						data={this.state.data}
						ref={this.setRef as any}
					/>
				)}
			</ViewWeb>
		);
	}

	private setCount = (data: ResultAnalysisWeaningsTableItem[]) => {
		this.setState({ count: this.getCount(data) });
	};

	private getCount = (data: ResultAnalysisWeaningsTableItem[]) => {
		return data.map(tableItem => tableItem.pregnancyId).filter(onlyUnique).length;
	};

	private setRef = (ref?: AnalysisResultsAnalysisWeaningsTableRef) =>
		(this.analysisResultsAnalysisWeaningsTableRef = ref);

	private printData = async () => {
		if (this.analysisResultsAnalysisWeaningsTableRef) {
			this.analysisResultsAnalysisWeaningsTableRef.printData(
				generatePeriodDto(this.props.dateFrom, this.props.dateTo),
			);
		}
	};
}

export default connect(AnalysisModalMapStateToProps, mapDispatchToProps)(AnalysisResultsPerLitterWeaningsOverview);
