import React, { FC, useState, useEffect } from 'react';
import { Option } from 'react-dropdown';
import { SlaughterhouseCompany } from 'shared/api/api';
import PenIcon from 'shared/assets/src-assets/png/pen_icon.png';
import { localized } from 'shared/state/i18n/i18n';
import { SkioldFormDropdown } from 'web/view/components/skiold-components/skiold-dropdown/skiold-form-dropdown';
import { SkioldFormsWrapper } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { SkioldTouchableOpacity } from 'web/view/components/skiold-components/skiold-touchable-opacity';
import { SowListConstants } from 'web/view/components/stem-animal/animal-lists/table-constants';
import { SkioldImage } from 'web/view/components/utils/svg/skiold-image';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { useSelector, useDispatch } from 'react-redux';
import { WebAppState } from 'web/state/store.web';
import { SetSlaughterhouseConnection } from 'shared/state/ducks/connection-slaughter-house/operations';
interface PropsFromParent {
	onPenIconPressed: (slaughterhouseCompany: SlaughterhouseCompany) => void;
}

export const ConnectionSlaughterHouse: FC<PropsFromParent> = React.memo((props) => {
	const dispatch = useDispatch();
	const [optionState, setOptionState] = useState({ label: '', value: '' } as Option);
	const onPenIconPressed = () => {
		props.onPenIconPressed(optionState.value as SlaughterhouseCompany);
	};

	const { slaughterhouseConnections } = useSelector((state: WebAppState) => state.slaughterhouseConnections);

	// Set slaughterhouse connection if setup
	useEffect(() => {
		if (slaughterhouseConnections.length > 0 && optionState.value === ''  ) {
		
			if (slaughterhouseConnections[0].slaughterhouse !== undefined) {
				SetSlaughterhouseConnection(slaughterhouseConnections[0].slaughterhouse)(dispatch);
				setOptionState({
					label: localized(slaughterhouseConnections[0].slaughterhouse),
					value: slaughterhouseConnections[0].slaughterhouse,
				} as Option);
			}
		}
	}, [slaughterhouseConnections, optionState, dispatch]);

	const GetFormRows = () => {
		let formRows = new Array<FormRow>();
		formRows.push(getSlaughterHouseHeader());
		formRows.push(getSlaughterHouseRow());
		return formRows;
	};

	function getSlaughterHouseRow() {
		const updateValue = (op: Option) => {
			SetSlaughterhouseConnection(op.value as SlaughterhouseCompany)(dispatch);
			setOptionState(op);
		};
		return {
			name: <TextWeb className="minWidth100 bold"> {localized('SlaughterHouse')}</TextWeb>,
			component: (
				<SkioldFormDropdown items={options()} selectedValue={optionState} onValueChanged={updateValue} />
			),
		};
	}

	function getSlaughterHouseHeader(): FormRow {
		return {
			header: localized('SlaughterHouse'),
		};
	}

	return (
		<>
			<SkioldFormsWrapper formRows={GetFormRows()} />
			<ViewWeb className="flexDirectionRow connectionStringContainerStyle">
				{optionState.value !== '' && (
					<>
						<TextWeb>
							{optionState.value === SlaughterhouseCompany.Tican
								? localized('connectToTican')
								: localized('loginInformation')}{' '}
						</TextWeb>
						<ViewWeb className="marginRightTen">
							<SkioldTouchableOpacity onPress={onPenIconPressed}>
								<SkioldImage
									width={SowListConstants.iconSVGWidth}
									height={SowListConstants.iconSVGWidth}
									imageData={PenIcon}
								/>
							</SkioldTouchableOpacity>
						</ViewWeb>
					</>
				)}
			</ViewWeb>
		</>
	);
});

const options = () => [
	{ value: SlaughterhouseCompany.DanishCrown, label: localized(SlaughterhouseCompany.DanishCrown) },
	{ value: SlaughterhouseCompany.Tican, label: localized(SlaughterhouseCompany.Tican) },
];
