import {
	GrowthPigDepartureEventDto,
	GrowthPigEntranceEventDto,
	GrowthPigMoveEventDto,
	GrowthPigsEvent,
	IGrowthPigEventDto,
	GrowthPigDepartureFromPenEventDto,
} from 'shared/api/api';
import { Syncable } from 'shared/state/models/syncable';

// tslint:disable-next-line: interface-name
export interface GpeDepartureCount {
	totalCount: number;
	departureCount: number;
}

export const UPSERT_GROWTHPIG_ENTRANCE = 'UPSERT_GROWTHPIG_ENTRANCE';
export const UPSERT_GROWTHPIG_MOVEEVENT = 'UPSERT_GROWTHPIG_MOVEEVENT';
export const UPSERT_GROWTHPIG_DEPARTURE = 'UPSERT_GROWTHPIG_DEPARTURE';
export const UPSERT_GROWTHPIGSEVENTS = 'UPSERT_GROWTHPIGSEVENTS';
export const UPSERT_GROWTHPIG_FROM_PEN_DEPARTURE = 'UPSERT_GROWTHPIG_FROM_PEN_DEPARTURE';
export const GET_GROWTHPIGEVENTS_BY_SITEID = 'GET_GROWTHPIGEVENTS_BY_SITEID';
export const GET_GROWTHPIGEVENTS_BY_DATES = 'GET_GROWTHPIGEVENTS_BY_DATES';
export const GET_GROWTHPIGEVENTS_BY_DATES_DASHBOARD = 'GET_GROWTHPIGEVENTS_BY_DATES_DASHBOARD';
export const GET_EVENTTYPE_GROWTHPIGEVENTS_BY_DATES = 'GET_EVENTTYPE_GROWTHPIGEVENTS_BY_DATES';
export const GET_GROWTHPIGEVENTS_REGULATIONS_BY_DATES = 'GET_GROWTHPIGEVENTS_REGULATIONS_BY_DATES';
export const SAVE_INITIAL_GROWTH_PIG_EVENTS = 'SAVE_INITIAL_GROWTH_PIG_EVENTS';
export const UPSERT_GROWTH_PIG_PIGLET_MOVE_EVENTS_AND_UPDATE_EVENTS =
	'UPSERT_GROWTH_PIG_PIGLET_MOVE_EVENTS_AND_UPDATE_EVENTS';
export const SET_GPE_DEPARTURE_COUNT = 'SET_GPE_DEPARTURE_COUNT';

export type GrowthPigsEventUpdatesType =
	| GrowthPigEntranceEventDto
	| GrowthPigMoveEventDto
	| GrowthPigDepartureEventDto
	| GrowthPigDepartureFromPenEventDto
	| GrowthPigsEvent;

export interface GrowthPigEventState extends Syncable<IGrowthPigEventDto> {
	growthPigEvents: GrowthPigsEvent[];
	growthPigEventsUpdates: GrowthPigsEventUpdatesType[];

	// Used to set Total pigs/should departure on registration
	gpeDepartureCount: GpeDepartureCount;
	dailyLastSync: Date;
}
