import { Dispatch } from 'redux';
import { EsfDailyStatus } from 'shared/api/api';
import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import { StoreContainer } from 'shared/state/store-container';
import * as Action from './actions';

export function GetEsfDailySyncData() {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;
	const lastSyncDate = state.esfDailyStatus.lastSyncDate;
	let yesterday = new Date().withoutTime();
	yesterday.setDate(yesterday.getDate() - 1);
	return AsyncOperationBuilder2(
		Action.getSyncEsfDailyStatusData,
		client => client.esfDailyStatus_Sync(siteId, lastSyncDate, yesterday),
		{ siteId },
	);
}

export function SaveEsfDailyStatus(esfDailyStatus: EsfDailyStatus) {
	esfDailyStatus.eventTime= new Date();
	return AsyncOperationBuilder2(
		Action.saveEsfDailyStatus,
		client => client.esfDailyStatus_UpsertEsfDailyStatus(esfDailyStatus),
		esfDailyStatus,
	);
}

export function SaveEsfDailyStatusSyncData() {
	const state = StoreContainer.getState();
	const updates = state.esfDailyStatus.updates;
	let promises = new Array<Promise<void>>();
	return async (dispatch: Dispatch<any>) => {
		if (state.esfDailyStatus.saveSyncInProgress) {
			return Promise.resolve();
		}

		updates.forEach(update => {
			promises.push(SaveEsfDailyStatus(EsfDailyStatus.fromJS(update))(dispatch));
		});

		return await Promise.all(promises);
	};
}

export function RemoveDailyStatus(id: string) {
	return (dispatch: Dispatch<any>) => {
		dispatch(Action.removeDailyStatus.started(id));
	};
}
