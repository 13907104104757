import { IAnimalNumberOnlyItem, AnimalNumberOnlyItem, SkioldOneClient, FileResponse } from 'shared/api/api';
import { savePdfAs } from 'web/web-helpers/pdf-helper/general-pdf-helper';
import { SkioldDigitalApiBaseUrl } from 'shared/constants';

export function GetAnimalNumberOnlyPdf(
	items: IAnimalNumberOnlyItem[],
	animalCount: number,
	poolYoungFemaleCount: number,
	fileName: string,
	siteId: string,
	timeZoneId?: string,
	lang?: string,
) {
	let animalNumberOnlyItems: AnimalNumberOnlyItem[] = [];
	items.forEach(element => {
		let d = new AnimalNumberOnlyItem();
		d.animalNumberRow = element.animalNumberRow;
		animalNumberOnlyItems.push(d);
	});
	return new SkioldOneClient(SkioldDigitalApiBaseUrl)
		.activeAnimalReport_GenerateAnimalNumberOnlyReportPdf(
			animalNumberOnlyItems,
			animalCount,
			poolYoungFemaleCount,
			siteId,
			timeZoneId,
			lang,
		)
		.then((fileResponse: FileResponse | null) => {
			savePdfAs(fileResponse, fileName);
		})
		.catch(() => {
			return;
		});
}
