import {ISlaughterSiteDataDto } from "shared/api/api";


export const SAVE_SLAUGHTER_DATA = 'SAVE_SLAUGHTER_DATA';
export const PUT_SLAUGHTER_DATA = 'PUT_SLAUGHTER_DATA';
export const DELETE_SLAUGHTER_DATA = 'DELETE_SLAUGHTER_DATA';
export const SYNC_DATA_SLAUGHTER_DATA = 'SYNC_DATA_SLAUGHTER_DATA';

export interface SlaughteDataState {
    slaughteDatas: ISlaughterSiteDataDto[];
}
