import React from 'react';
import { Option } from 'react-dropdown';
import isEqual from 'react-fast-compare';
import { connect } from 'react-redux';
import {
	AnimalType,
	GrowthPigDepartureEventDto,
	GrowthPigEventType,
	GrowthPigsEvent,
	ISection,
	LocationType,
} from 'shared/api/api';
import { setOneDecimalsAsNumber } from 'shared/helpers/general-helpers';
import {
	generateGrowthPigEventDataForSectionId,
	generateLocationOptionsForGrowthPigEvents,
	getGrowthPigDepartureEventDtos,
	getGrowthPigTuneProductionIfPenEmpty,
	handleGrowthpigsDeparturedFromSection,
	handleGrowthpigsRestFromSection,
	handleGrowthpigsWeightFromSection,
	validateGrowthPigsDepartureEvent,
} from 'shared/helpers/growth-pigs-helper/growth-pig-event-function-helper';
import {
	GenerateIniitialStateGrowthPigDepartureEvent,
	getMaxNumberForAmountForGrowthPigEvent,
	GrowthPigDepartureFromSectionRow,
	GrowthPigDepartureProps,
	GrowthPigDeparturePropsMapStateToProps,
	GrowthPigDepartureState,
	GrowthPigEventsMapDispatchToProps,
	GrowthTypeDepartureTypesFromSection,
	upsertGrowthPigDepartureEvent,
	UpsertGrowthPigEventAndClose,
} from 'shared/helpers/growth-pigs-helper/growth-pig-event-helper';
import { CheckIfSectionUsesPens } from 'shared/helpers/location-helper';
import { setGpeBundleIdentifierArray } from 'shared/state/ducks/growth-pig-events/operations';
import { localized } from 'shared/state/i18n/i18n';
import { DepartureTypes } from 'shared/state/models/departure-types';
import SkioldFormPenPicker from 'web/view/components/location/location-picker/skiold-form-pen-picker';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldFormsWrapper } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { ViewWeb } from 'web/view/components/utils/web-view';
import SkioldStableSectionPicker from '../../location/location-picker/skiold-stable-section-picker';
import { showAlert, ShowConfirmAlert } from '../../skiold-alert/skiold-alert';
import { SkioldDatePicker } from '../../skiold-components/skiold-date-picker/skiold-date-picker';
import { SkioldFormDecimalInput } from '../../skiold-components/skiold-decimal-input/skiold-form-decimal-input';
import { SkioldFormDropdown } from '../../skiold-components/skiold-dropdown/skiold-form-dropdown';
import { SkioldFormIntegerInput } from '../../skiold-components/skiold-integer-input/skiold-form-integer-input';
import { SkioldModal } from '../../skiold-components/skiold-modal/skiold-modal';
import GrowthPigProductionTypePicker from '../../skiold-components/skiold-production-type-picker/skiold-production-type-picker';
import { WhiteText } from '../../Text/white-text';
import { GpeDepartureCount } from './growth-pig-departure-count';
import { GrowthPigDepartureFromSectionTable } from './growth-pig-departure-move-table';
import { GrowthPigDistributeCountSold } from './growth-pig-distribute-count-sold';
import './growth-pig-distribute.scss';

export class GrowthPigDepartureEventComponent extends React.PureComponent<
	GrowthPigDepartureProps,
	GrowthPigDepartureState
> {
	constructor(props: GrowthPigDepartureProps) {
		super(props);
		const growthPigEventToEdit = this.props.growthPigsEvent;
		this.state = GenerateIniitialStateGrowthPigDepartureEvent(growthPigEventToEdit, props);
	}

	public componentDidUpdate(prevProps: GrowthPigDepartureProps) {
		if (!isEqual(prevProps.growthPigsEvents, this.props.growthPigsEvents) && this.state.fromProductionType) {
			this.setState({
				filteredLocation: generateLocationOptionsForGrowthPigEvents(
					this.props.growthPigsEvents,
					this.props.locations,
					this.props.productionType,
				),
			});
		}
		if (!isEqual(prevProps.productionType, this.props.productionType)) {
			const growthPigEventToEdit = this.props.growthPigsEvent;
			this.setState(GenerateIniitialStateGrowthPigDepartureEvent(growthPigEventToEdit, this.props));
		}

		// Set gpe departure count
		if (this.state.data) {
			let total = 0;
			let departured = 0;
			this.state.data.forEach(element => {
				total += element.amount ? element.amount : 0;
				departured += element.departedFromSection ? element.departedFromSection : 0;
			});
			this.props.setGpeDepartureCount({ totalCount: total, departureCount: departured });
		}
	}

	public render() {
		return (
			<ViewWeb className="growth-pig-distribute">
				<ViewWeb className="distribute-container">
					{this.state.growthPigEvent ? (
						<SkioldFormsWrapper
							formRows={this.getFormRows()}
							containerClassName="forms-wrapper-container"
						/>
					) : (
						this.generateRegisterHeader()
					)}

					{!this.state.growthPigEvent && (
						<GrowthPigDepartureFromSectionTable
							departedFromSectionText={localized('departed')}
							departureType={this.state.departureTypeOption.value}
							isDeparture={true}
							onDepartureFromSectionNumberChanged={this.onDepartureNumberChanged}
							onDepartureFromSectionAvgWeightChanged={this.onDepartureWeightChanged}
							onRestFromSectionNumberChanged={this.onRestNumberChanged}
							data={this.state.data}
							usesPen={this.state.fromSection && this.state.fromSection.usePens}
							usePrivateSlaughterhouse={this.props.usePrivateSlaughterhouse}
						/>
					)}
				</ViewWeb>
				{this.state.data &&
					this.state.fromSection &&
					this.state.fromSection.id &&
					this.state.fromProductionType && (
						<SkioldModal
							overflowNone={true}
							shouldCloseOnOverlayClick={true}
							padding="0"
							isOpen={this.state.modalOpen}
							close={this.closeModal}
							justify-content="flex-end"
							max-width="calc(100% - 220px)"
						>
							<GrowthPigDistributeCountSold
								toPen={this.state.distribiteToPen}
								fromPen={this.state.distributeFromPen}
								amount={this.state.distributeCount}
								penRowData={this.state.data}
								setCountCallback={this.setCountOnPens}
								sectionId={this.state.fromSection.id}
								onClose={this.closeModal}
								headerTranslation={
									this.state.departureTypeOption.value === DepartureTypes.departureTypeKilled
										? 'DistributeSlaughter'
										: 'DistributeCount'
								}
							/>
						</SkioldModal>
					)}
				{this.renderButtons()}
			</ViewWeb>
		);
	}

	private setCountOnPens = (
		keyValue: { [key: string]: number },
		fromPen?: Option,
		toPen?: Option,
		amount?: number,
	) => {
		let tmpData = [...this.state.data];
		tmpData.forEach((item, i) => {
			if (item.penId) {
				const value = keyValue[item.penId];

				if (value !== undefined) {
					tmpData[i] = { ...item, departedFromSection: value, rest: item.amount && item.amount - value };
				}else{
					tmpData[i] = { ...item, departedFromSection: undefined, rest: item.amount && item.amount };
				}
			}
		});
		this.setState({ data: tmpData, distribiteToPen: toPen, distributeCount: amount, distributeFromPen: fromPen });
	};

	private OnDateChanged = (date: Date) => {
		if (this.state.fromSection) {
			let data = generateGrowthPigEventDataForSectionId(
				this.state.fromSection,
				this.props.growthPigsEvents,
				this.props.locations,
				this.props.productionType,
				this.state.fromProductionType,
				date,
			);

			const itemsWithDeparture = data.filter(e => e.departedFromSection);
			itemsWithDeparture.forEach(itemWithDepartures => {
				handleGrowthpigsDeparturedFromSection(data, itemWithDepartures, itemWithDepartures.departedFromSection);
			});
			this.setState({ date, data });
		} else {
			this.setState({ date });
		}
	};

	private generateRegisterHeader(): React.ReactNode {
		return (
			<ViewWeb className="marginBottomTen">
				<ViewWeb className="flexDirectionRow">
					<ViewWeb className="flexDirectionRow width275justifyContentSpaceBetween">
						<WhiteText className="alignSelfCenter"> {localized('Date')}</WhiteText>
						<SkioldDatePicker
							onDateChanged={this.OnDateChanged}
							itemFromParent={'date'}
							selectedDate={this.state['date']}
							theme={'light'}
							color={'lightGrey'}
							backwardDateSetEndTime={true}
						/>
						<GpeDepartureCount />
					</ViewWeb>
					<WhiteText className="alignSelfCenterWidth110 marginLeftThirtyOne">
						{localized('departureType')}{' '}
					</WhiteText>

					<SkioldFormDropdown
						theme="light"
						containerClassName="minWidth300"
						items={GrowthTypeDepartureTypesFromSection(this.props.productionType)}
						selectedValue={this.state.departureTypeOption}
						onValueChanged={this.departureTypeChanged}
					/>
				</ViewWeb>
				<ViewWeb className="flexDirectionRow marginTopTwenty">
					<WhiteText className="alignSelfCenterWidth110 "> {localized('fromProductionType')} </WhiteText>
					<GrowthPigProductionTypePicker
						dontSetToFrats={
							!!(this.state.fromSection && this.state.fromSection.animalType !== AnimalType.FRATS)
						}
						theme="light"
						disableYoungFemale={true}
						onProductionTypeChanged={this.onFromProductionTypeChanged}
						usedInsideForm={true}
						disabled={!!this.state.growthPigEvent}
						productionType={
							this.state.fromProductionType ? this.state.fromProductionType : this.props.productionType
						}
					/>

					<WhiteText className="alignSelfCenterWidth110 marginLeftTwenty">
						{' '}
						{localized('FromLocation')}{' '}
					</WhiteText>
					<SkioldStableSectionPicker
						theme="light"
						usedInsideForm={true}
						locationType={
							this.state.fromProductionType === AnimalType.FRATS
								? this.props.productionType === AnimalType.Weaner
									? LocationType.Weaners
									: LocationType.Finisher
								: undefined
						}
						containerClassName="minWidth300"
						AnimalType={this.state.fromProductionType}
						disabled={!!this.state.growthPigEvent}
						sectionId={this.state.fromSection && this.state.fromSection.id}
						filteredLocations={this.state.filteredLocation}
						onStableSectionSelected={this.onStableSectionChanged}
					/>
				</ViewWeb>
			</ViewWeb>
		);
	}

	private onTotalWeightChanged = (totalWeight?: number) => {
		if (totalWeight) {
			const data = [...this.state.data];
			const totalAmount = data
				.map(d => (d.departedFromSection ? d.departedFromSection : 0))
				.reduce((a, b) => a + b);
			data.forEach(d => {
				if (d.departedFromSection && d.departedFromSection > 0) {
					const avgWeight = totalWeight / totalAmount;
					d.newAvgWeight = avgWeight;
					d.totalDepartureWeight = d.departedFromSection * avgWeight;
				}
			});
			this.setState({ data, modalOpen: false });
		}
	};

	private resetComponent = () => {
		this.setState(GenerateIniitialStateGrowthPigDepartureEvent(this.props.growthPigsEvent, this.props, this.state));
	};

	private closeModal = () => {
		this.setState({ modalOpen: false });
	};

	private onDepartureNumberChanged = (number?: number, itemFromParent?: GrowthPigDepartureFromSectionRow) => {
		if (itemFromParent && itemFromParent.editable) {
			const datasToModify = [...this.state.data];
			handleGrowthpigsDeparturedFromSection(datasToModify, itemFromParent, number);
			this.setState({ data: datasToModify });
		}
	};

	private onRestNumberChanged = (number?: number, itemFromParent?: GrowthPigDepartureFromSectionRow) => {
		if (itemFromParent && itemFromParent.editable) {
			const datasToModify = [...this.state.data];
			handleGrowthpigsRestFromSection(datasToModify, itemFromParent, number);
			this.setState({ data: datasToModify });
		}
	};

	private onDepartureWeightChanged = (number?: number, itemFromParent?: GrowthPigDepartureFromSectionRow) => {
		const datasToModify = [...this.state.data];
		handleGrowthpigsWeightFromSection(datasToModify, itemFromParent, number);
		this.setState({ data: datasToModify });
	};

	private getFromLocationRow = (): FormRow => {
		return {
			name: localized('FromLocation'),
			component: (
				<SkioldStableSectionPicker
					usedInsideForm={true}
					locationType={
						this.state.fromProductionType === AnimalType.FRATS
							? this.props.productionType === AnimalType.Weaner
								? LocationType.Weaners
								: LocationType.Finisher
							: undefined
					}
					AnimalType={this.state.fromProductionType}
					disabled={this.state.growthPigEvent ? true : false}
					sectionId={this.state.fromSection && this.state.fromSection.id}
					filteredLocations={this.state.filteredLocation}
					onStableSectionSelected={this.onStableSectionChanged}
				/>
			),
		};
	};

	public getProductionRow(): FormRow {
		return {
			name: localized('fromProductionType'),
			component: (
				<GrowthPigProductionTypePicker
					dontSetToFrats={
						!!(this.state.fromSection && this.state.fromSection.animalType !== AnimalType.FRATS)
					}
					disableYoungFemale={true}
					onProductionTypeChanged={this.onFromProductionTypeChanged}
					usedInsideForm={true}
					disabled={!!this.state.growthPigEvent}
					productionType={
						this.state.fromProductionType ? this.state.fromProductionType : this.props.productionType
					}
				/>
			),
		};
	}

	private getIntergerRow = (): FormRow => {
		let sum = getMaxNumberForAmountForGrowthPigEvent(
			this.state.data,
			this.state.fromPenId,
			this.props.growthPigsEvent,
		);

		return {
			name: localized('Count'),
			component: (
				<SkioldFormIntegerInput
					text={this.state.growthPigEvent ? this.state.growthPigEvent.amount : 0}
					maxNumber={sum}
					itemFromParent={'amount'}
					onChangeNumber={this.onGrowthPigEventAmountChanged}
				/>
			),
		};
	};

	private getAvgWeightRow = (): FormRow => {
		return {
			name: localized('AvgWeight'),
			component: (
				<SkioldFormDecimalInput
					text={
						this.state.growthPigEvent &&
						this.state.growthPigEvent.amount &&
						this.state.growthPigEvent.totalWeight
							? setOneDecimalsAsNumber(
									this.state.growthPigEvent.totalWeight / this.state.growthPigEvent.amount,
							  )
							: 0
					}
					onChangeNumber={this.onGrowthPigEventAvgWeightChanged}
				/>
			),
		};
	};

	private getDepartureTypeRow(): FormRow {
		return {
			name: localized('departureType'),
			component: (
				<SkioldFormDropdown
					items={GrowthTypeDepartureTypesFromSection(this.props.productionType)}
					selectedValue={this.state.departureTypeOption}
					onValueChanged={this.departureTypeChanged}
				/>
			),
		};
	}

	private departureTypeChanged = (option: Option) => {
		this.setState({ departureTypeOption: option });
	};

	private onGrowthPigEventAvgWeightChanged = (num?: number) => {
		const gpe = { ...this.state.growthPigEvent } as GrowthPigsEvent;
		gpe.totalWeight = num && gpe.amount ? gpe.amount * num : 0;
		this.setState({ growthPigEvent: gpe as GrowthPigsEvent });
	};

	private onGrowthPigEventAmountChanged = (num?: number) => {
		const gpe = { ...this.state.growthPigEvent } as GrowthPigsEvent;
		const growthPigEventToEdit = this.props.growthPigsEvent;
		const oldTotalWeightPerPig =
			growthPigEventToEdit && growthPigEventToEdit.oldTotalWeight && growthPigEventToEdit.amount
				? growthPigEventToEdit.oldTotalWeight / growthPigEventToEdit.amount
				: 0;
		const oldTotalWeight = num ? oldTotalWeightPerPig * num : 0;
		gpe.amount = num!;
		gpe.oldTotalWeight = oldTotalWeight;
		this.setState({ growthPigEvent: gpe as GrowthPigsEvent });
	};

	private onFromProductionTypeChanged = (productionType: AnimalType) => {
		const filteredLocations = generateLocationOptionsForGrowthPigEvents(
			this.props.growthPigsEvents,
			this.props.locations,
			this.props.productionType,
		);

		if (this.state.fromSection && this.state.fromSection.id) {
			const data = generateGrowthPigEventDataForSectionId(
				this.state.fromSection,
				this.props.growthPigsEvents,
				this.props.locations,
				this.props.productionType,
				productionType,
				this.state.date ? this.state.date : new Date(),
			);
			if (data.length === 0) {
				this.setState({
					fromProductionType: productionType,
					filteredLocation: filteredLocations,
					fromSection: undefined,
					data,
				});
			} else {
				if (productionType !== this.state.fromProductionType) {
					this.setState({
						fromProductionType: productionType,
						filteredLocation: filteredLocations,
						data,
						fromSection: undefined,
					});
				} else {
					this.setState({ fromProductionType: productionType, filteredLocation: filteredLocations, data });
				}
			}
		} else {
			this.setState({ fromProductionType: productionType, filteredLocation: filteredLocations });
		}
	};

	private onStableSectionChanged = (sectionId: string) => {
		const section = this.props.hashmapLocations[sectionId] as ISection;
		const data = generateGrowthPigEventDataForSectionId(
			section,
			this.props.growthPigsEvents,
			this.props.locations,
			this.props.productionType,
			this.state.fromProductionType,
			this.state.date ? this.state.date : new Date(),
		);

		this.setState({ fromSection: section, data });
	};

	private getDateRow = (property: 'date') => {
		return {
			name: localized('Date'),
			component: (
				<SkioldDatePicker
					onDateChanged={this.OnPropertyChanged}
					itemFromParent={property}
					selectedDate={this.state[property]}
					theme={'dark'}
					color={'grey'}
					backwardDateSetEndTime={true}
				/>
			),
		};
	};

	private OnPropertyChanged = (newText, ItemFromParent: string) => {
		const state = { ...this.state };
		state[ItemFromParent] = newText;
		this.setState({ ...state });
	};

	private getFormRows() {
		let formRows = new Array<FormRow>();
		formRows.push(this.getDateRow('date'));
		formRows.push(this.getProductionRow());
		formRows.push(this.getFromLocationRow());
		if (this.state.growthPigEvent && this.state.fromSection && this.state.fromSection.id) {
			if (CheckIfSectionUsesPens(this.state.fromSection.id)) {
				formRows.push(this.getPenPickerRow());
			}
		}
		formRows.push(this.getDepartureTypeRow());
		if (this.props.growthPigsEvent) {
			formRows.push(this.getIntergerRow());
			formRows.push(this.getAvgWeightRow());
		}
		return formRows;
	}

	private getPenPickerRow(): FormRow {
		return {
			name: localized('Pen'),
			component: (
				<SkioldFormPenPicker
					usedInsideForm={true}
					disabled={!!this.state.growthPigEvent}
					onPenSelected={this.penSelected}
					sectionId={this.state.fromSection && this.state.fromSection.id}
					penId={this.state.fromPenId}
				/>
			),
		};
	}

	private penSelected = (selectedPenId: string) => {
		this.setState({ fromPenId: selectedPenId });
	};

	private DistributeWeight = () => {
		this.setState({ modalOpen: true });
	};

	private renderButtons() {
		return (
			<>
				{!this.state.growthPigEvent ? (
					<ViewWeb className="button-view-style justify-content-space-between">
						<ViewWeb className="flexDirectionRow">
							<SkioldButton title={localized('DepartureAll')} onPress={this.departureAll} />
							<SkioldButton
								disabled={!this.state.fromSection}
								title={localized(
									this.state.departureTypeOption.value === DepartureTypes.departureTypeKilled
										? 'DistributeSlaughter'
										: 'DistributeCount',
								)}
								onPress={this.DistributeWeight}
							/>
						</ViewWeb>
						<SkioldButton title={localized('Save')} onPress={this.save} />
					</ViewWeb>
				) : (
					<ViewWeb className="button-view-style">
						<SkioldButton title={localized('Save')} onPress={this.upsertEvent} />
						<SkioldButton title={localized('Delete')} onPress={this.deleteEvent} />
						<SkioldButton theme="grey" title={localized('Close')} onPress={this.props.closeEditModal} />
					</ViewWeb>
				)}
			</>
		);
	}

	private upsertEvent = async () => {
		if (this.state.growthPigEvent && this.state.departureTypeOption) {
			await upsertGrowthPigDepartureEvent(this.props, this.state, ShowConfirmAlert);
		}
	};

	private deleteEvent = async () => {
		const growthPigEventToEdit = this.props.growthPigsEvent;
		if (growthPigEventToEdit) {
			growthPigEventToEdit.isDeleted = true;
			UpsertGrowthPigEventAndClose(
				growthPigEventToEdit,
				this.props.upsertGrowthPigsEvent,
				this.props.closeEditModal,
			);
		}
	};

	private departureAll = () => {
		const data = this.state.data.map(d => {
			if (d.editable) {
				return { ...d, rest: 0, departedFromSection: d.amount, departureWeight: d.totalWeight };
			}
			return { ...d };
		});

		this.setState({ data });
	};

	private save = async () => {
		if (validateGrowthPigsDepartureEvent(this.state, showAlert) && this.props.profile) {
			const listToSave: GrowthPigDepartureEventDto[] = getGrowthPigDepartureEventDtos(this.state, this.props);
			if (listToSave.length > 0) {
				let dataWithBundleId = setGpeBundleIdentifierArray(listToSave);
				const d = dataWithBundleId.filter(gpe => gpe.growthPigEventType !== GrowthPigEventType.Regulation);
				this.props.upsertGrowthPigsDepartureEvents(d);

				dataWithBundleId.forEach(gpe => {
					if (gpe.growthPigEventType === GrowthPigEventType.Regulation) {
						this.props.upsertGrowthPigsEvent(gpe);
					}
				});

				// If a location is emptied, save tunepigproduction
				const gpeTuneEmptyPens = getGrowthPigTuneProductionIfPenEmpty(this.state, this.props);
				if (gpeTuneEmptyPens && gpeTuneEmptyPens.length > 0) {
					this.props.upsertMultipleGrowthPigTuneProduction(gpeTuneEmptyPens);
				}
				this.resetComponent();
			}
		}
	};
}

export default connect(
	GrowthPigDeparturePropsMapStateToProps,
	GrowthPigEventsMapDispatchToProps,
)(GrowthPigDepartureEventComponent);
