import { Sorting } from '@devexpress/dx-react-grid';
import memoize from 'memoize-one';
import React from 'react';
import { connect } from 'react-redux';
import { IAverted, IPregnancyEvent, IStemAnimal, PregnancyState } from 'shared/api/api';
import allocateWeight from 'shared/assets/src-assets/png/allocate_wieght_light_grey.png';
import sokort_icon from 'shared/assets/src-assets/png/sokort_icon.png';
import { exactStartsWithMethodGrid } from 'shared/helpers/general-helpers';
import { NaturalSortDates } from 'shared/helpers/natural-sort';
import { calculateLitterByPregnancy } from 'shared/helpers/stemanimal-helper/stemanimal-helper';
import { localized } from 'shared/state/i18n/i18n';
import 'web/view/components/event-lists/pregnancy-event-lists/sow-pregnancy-event-list-styles.scss';
import { SkioldModal } from 'web/view/components/skiold-components/skiold-modal/skiold-modal';
import 'web/view/components/skiold-components/skiold-table/skiold-double-header-table.scss';
import SkioldTableGrid, {
	SkioldTableGrid as SkioldTableRef,
} from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { SkioldTouchableOpacity } from 'web/view/components/skiold-components/skiold-touchable-opacity';
import { SowListConstants } from 'web/view/components/stem-animal/animal-lists/table-constants';
import { getActiveSows } from 'web/view/components/stem-animal/stem-animal-input/stem-animal-input-helper';
import { WhiteText } from 'web/view/components/Text/white-text';
import { SkioldImage } from 'web/view/components/utils/svg/skiold-image';
import { ViewWeb } from 'web/view/components/utils/web-view';
import SowCard from 'web/view/pages/sow-card/sow-card';
import { getWebAnimalColorStyleTable } from 'web/web-helpers/general-web-helpers';
import {
	getEventListAnimalNumberCell,
	getEventListLitterCell,
	getEventListPregnancyDateCell,
	PregnancyEventListMapDispatchToProps,
	PregnancyEventListMapStateToProps,
	PregnancyEventListProps,
	PregnancyEventListState,
	WeaningEventListItem,
	WeaningSummaryItem,
} from '../sow-pregnancy-event-list-helper';
import AllocateWeaningWeight from './allocate-weight';
import './weaning-event-list-table.scss';

export class WeaningEventListTable extends React.PureComponent<PregnancyEventListProps, PregnancyEventListState> {
	public SkioldTableRef: SkioldTableRef | undefined;

	private generateData = memoize((dateFrom, dateTo, departuredSows, departuredPregnancies, pregnancyEvents) =>
		this.genereateListData(),
	);

	private defaultSorting = [{ columnName: 'animalNumber', direction: 'asc' }] as Sorting[];
	private readonly showSowCard = (d: WeaningEventListItem) => {
		this.setState({ sowCardModal: true, stemAnimalId: d.stemAnimalId! });
	};

	private readonly getNumAliveCell = (d: WeaningEventListItem) =>
		d.pregnancyEvent ? d.pregnancyEvent.numAlive : ' ';

	private readonly getAvgWeightCell = (d: WeaningEventListItem) => d.avgWeight;

	private readonly getNursingSowCell = (d: WeaningEventListItem) => d.nursingSow;

	private readonly getSowCardIconCell = (d: WeaningEventListItem) => (
		<SkioldTouchableOpacity itemFromParent={d} onPress={this.showSowCard}>
			<SkioldImage
				width={SowListConstants.iconSVGWidth}
				height={SowListConstants.iconSVGWidth}
				imageData={sokort_icon}
			/>
		</SkioldTouchableOpacity>
	);

	private readonly getInitCell = (d: WeaningEventListItem) => {
		if (d.pregnancyEvent) {
			const user = this.props.userProfiles.find(u => u.id === d.pregnancyEvent?.createdBy);
			return user?.initials ?? '';
		}
		return ' ';
	};

	constructor(props: PregnancyEventListProps) {
		super(props);
		this.state = {
			columns: this.generateColumns(),
			columnExte: this.generateColumnsExtensions(),
			loading: false,
			commitAll: false,
			stemAnimalId: '',
			sowCardModal: false,
			eventSpecificModal: false,
		};
	}

	public genereateListData() {
		let activeSows = getActiveSows();
		let { pregnancyEvents } = this.props;
		let listItems: WeaningEventListItem[] = [];
		this.insertDataIntoListItems(activeSows, listItems, pregnancyEvents);
		if (this.props.departuredSows && this.props.departuredSows.length > 0) {
			this.insertDataIntoListItems(this.props.departuredSows, listItems, this.props.departuredPregnancies);
		}
		this.generateSummary(listItems);
		return listItems;
	}

	public generateColumnsExtensions() {
		let columnExt = [
			{
				columnName: 'sowCard',
				width: SowListConstants.iconWidth,
				filteringEnabled: false,
				sortingEnabled: false,
				resizingEnabled: false,
			},
			{
				columnName: 'animalNumber',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'breedRace',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'litter',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'date',
				width: SowListConstants.entranceDateWidth,
			},
			{
				columnName: 'numAlive',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'avgWeight',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'nursingSow',
				width: SowListConstants.animalNrWidth,
			},
		];

		if (this.props.generalSettings.stemAnimalShowInitials) {
			columnExt.push({
				columnName: 'initial',
				width: SowListConstants.animalNrWidth,
			});
		}
		return columnExt;
	}

	public componentDidMount() {
		if (this.SkioldTableRef) {
			this.props.setSowsCount(this.SkioldTableRef.GetSortedData().length);
		}
	}

	public render() {
		let items = this.generateData(
			this.props.dateFrom,
			this.props.dateTo,
			this.props.departuredSows,
			this.props.departuredPregnancies,
			this.props.pregnancyEvents,
		);
		return (
			<div className={'WeaningEventListViewMaxWidth'}>
				<SkioldTableGrid
					tableKey={'weaningEventListTable'}
					columns={this.state.columns}
					ColumnExtensions={this.state.columnExte}
					data={items}
					ref={this.setTableRef}
					onFiltersChanged={this.onFiltersChanged}
					sortHeaderId={this.defaultSorting}
				/>

				<SkioldModal
					shouldCloseOnOverlayClick={true}
					padding="0"
					isOpen={this.state.sowCardModal}
					close={this.closeModal}
					justify-content="flex-end"
					max-width="calc(100% - 220px)"
				>
					<SowCard stemAnimalIdFromParent={this.state.stemAnimalId} close={this.closeModal} />
				</SkioldModal>
				<SkioldModal
					shouldCloseOnOverlayClick={true}
					padding="0"
					isOpen={this.state.eventSpecificModal}
					close={this.closeEventSpecificModal}
					justify-content="flex-end"
					max-width="calc(100% - 220px)"
				>
					<AllocateWeaningWeight
						WeaningEventListItems={this.state.filteredEvents ? this.state.filteredEvents : items}
						close={this.closeEventSpecificModal}
					/>
				</SkioldModal>
			</div>
		);
	}

	private insertDataIntoListItems(
		stemAnimals: IStemAnimal[],
		listItems: WeaningEventListItem[],
		pregnancyEvents: { [key: string]: IPregnancyEvent[] },
	) {
		stemAnimals.forEach(sow => {
			let sowPregnancyEvents = pregnancyEvents[sow.id!];
			if (sowPregnancyEvents && sowPregnancyEvents.length > 0) {
				let weanedPregnancyEvents = sowPregnancyEvents.filter(a => a.state === PregnancyState.Averted);
				weanedPregnancyEvents.forEach(pregnancyEvent => {
					if (
						this.props.dateFrom === undefined ||
						this.props.dateTo === undefined ||
						(pregnancyEvent.date! >= this.props.dateFrom && pregnancyEvent.date! <= this.props.dateTo)
					) {
						let item = new WeaningEventListItem();
						item.stemAnimalId = sow.id;
						item.litterNumber = calculateLitterByPregnancy(sow, pregnancyEvent, sowPregnancyEvents);
						item.animalNumber = sow.animalNumber;
						item.pregnancyEvent = pregnancyEvent as IAverted;
						if (
							(pregnancyEvent as IAverted).numAlive &&
							(pregnancyEvent as IAverted).totalWeight &&
							(pregnancyEvent as IAverted).numAlive !== 0
						) {
							item.avgWeight = parseFloat(
								(
									(pregnancyEvent as IAverted).totalWeight! / (pregnancyEvent as IAverted).numAlive
								).toFixed(1),
							);
						}

						item.nursingSow = localized((pregnancyEvent as IAverted).isNursingSow ? 'True' : 'False');
						listItems.push(item);
					}
				});
			}
		});
	}
	private generateSummary(pregnancyEvents: WeaningEventListItem[]) {
		let summaryItem: WeaningSummaryItem = new WeaningSummaryItem();
		pregnancyEvents.forEach(event => {
			if (event.pregnancyEvent!.isNursingSow) {
				summaryItem.nursingSowTotal++;
			}
			if (event.pregnancyEvent!.numAlive) {
				summaryItem.numAliveTotal = summaryItem.numAliveTotal + event.pregnancyEvent!.numAlive!;
			}

			if (event.pregnancyEvent!.totalWeight) {
				summaryItem.weightTotal = summaryItem.weightTotal + event.pregnancyEvent!.totalWeight!;
			}
			if (summaryItem.numAliveTotal !== 0) {
				summaryItem.avgNumAlive = summaryItem.numAliveTotal / pregnancyEvents.length;
			}
			if (summaryItem.numAliveTotal !== 0 && summaryItem.weightTotal !== 0) {
				summaryItem.avgWeight = summaryItem.weightTotal / summaryItem.numAliveTotal;
			}
		});

		summaryItem.sowCount = pregnancyEvents.length.toString();
		this.setState({ filteredEvents: pregnancyEvents });
		this.props.onSummaryChanged(
			<ViewWeb className={'marginTopTen flexDirectionRow'}>
				<ViewWeb className="large-icon-container">
					<SkioldTouchableOpacity onPress={this.OpenEventSpecificModal}>
						<SkioldImage width="60" height="60" imageData={allocateWeight} />
					</SkioldTouchableOpacity>

					<WhiteText className="white-text-container">{localized('allocateWeight')}</WhiteText>
				</ViewWeb>

				<ViewWeb>
					<ViewWeb className="flexDirectionRow">
						<ViewWeb className="flexDirectionRowWithWidth borderRight">
							<ViewWeb className="summaryTextStyle"> {localized('Weanings')}: </ViewWeb>
							<ViewWeb className="summaryTextStyle"> {summaryItem.sowCount} </ViewWeb>
						</ViewWeb>
					</ViewWeb>
					<ViewWeb className="flexDirectionRow">
						<ViewWeb className="flexDirectionRowWithWidth borderRight">
							<ViewWeb className="summaryTextStyle"> {localized('totalPcs')}: </ViewWeb>
							<ViewWeb className="summaryTextStyle"> {summaryItem.numAliveTotal} </ViewWeb>
						</ViewWeb>
						<ViewWeb className="flexDirectionRowWithWidth">
							<ViewWeb className="summaryTextStyle"> {localized('avgPcs')} </ViewWeb>
							<ViewWeb className="summaryTextStyle"> {summaryItem.avgNumAlive.toFixed(1)} </ViewWeb>
						</ViewWeb>
					</ViewWeb>
					<ViewWeb className="flexDirectionRow">
						<ViewWeb className="flexDirectionRowWithWidth borderRight">
							<ViewWeb className="summaryTextStyle"> {localized('totalWeight')}: </ViewWeb>
							<ViewWeb className="summaryTextStyle"> {summaryItem.weightTotal.toFixed(1)} </ViewWeb>
						</ViewWeb>
						<ViewWeb className="flexDirectionRowWithWidth">
							<ViewWeb className="summaryTextStyle"> {localized('avgWeight')}: </ViewWeb>
							<ViewWeb className="summaryTextStyle"> {summaryItem.avgWeight.toFixed(1)} </ViewWeb>
						</ViewWeb>
					</ViewWeb>
				</ViewWeb>
			</ViewWeb>,
		);
	}

	private OpenEventSpecificModal = () => {
		this.setState({ eventSpecificModal: true });
	};

	private onFiltersChanged = (events: WeaningEventListItem[]) => {
		this.generateSummary(events);
	};

	private closeModal = () => {
		this.setState({ stemAnimalId: '', sowCardModal: false, eventSpecificModal: false });
	};

	private closeEventSpecificModal = async () => {
		this.setState({ eventSpecificModal: false });
	};

	private setTableRef = (m: any) => (m ? (this.SkioldTableRef = m) : {});

	private getStemAnimalNumberColor = (tableItem: WeaningEventListItem) => {
		return tableItem && tableItem.stemAnimalId ? getWebAnimalColorStyleTable(tableItem.stemAnimalId) : '';
	};

	private generateColumns() {
		const columns = [
			{
				name: 'sowCard',
				title: ' ',
				headerClassName: 'merged-header',
				sortable: false,
				getCellValue: this.getSowCardIconCell,
			},
			{
				name: 'animalNumber',
				headerClassName: 'merged-header',
				className: this.getStemAnimalNumberColor,
				title: localized('animalNumber'),
				filterFunction: exactStartsWithMethodGrid,
				getCellValue: getEventListAnimalNumberCell,
			},
			{
				name: 'breedRace',
				title: localized('Race'),
			},
			{
				name: 'litter',
				title: localized('LitterNr'),
				getCellValue: getEventListLitterCell,
			},
			{
				name: 'date',
				title: localized('Date'),
				sortFunction: NaturalSortDates,
				getCellValue: getEventListPregnancyDateCell,
			},
			{
				name: 'numAlive',
				pathToValue: 'pregnancyEvent',
				title: localized('pcsWithoutDot'),
				getCellValue: this.getNumAliveCell,
			},
			{
				name: 'avgWeight',
				title: localized('avgWeightSlashPig'),
				getCellValue: this.getAvgWeightCell,
			},
			{
				name: 'nursingSow',
				title: localized('nursingSow'),
				getCellValue: this.getNursingSowCell,
			},
		];
		if (this.props.generalSettings.stemAnimalShowInitials) {
			columns.push({
				name: 'initial',
				title: localized('InitialsShort'),
				getCellValue: this.getInitCell,
			});
		}

		return columns;
	}
}

export default connect(PregnancyEventListMapStateToProps, PregnancyEventListMapDispatchToProps, null, {
	forwardRef: true,
})(WeaningEventListTable) as any;
