import ObjectID from 'bson-objectid';
import { Dispatch } from 'redux';
import {
	Building,
	IBuilding,
	IPen,
	ISection,
	IValve,
	Pen,
	Section,
	SkioldOneClient,
	ApiException,
	SyncDataLocations,
} from 'shared/api/api';
import { SkioldDigitalApiBaseUrl } from 'shared/constants';
import { getSiteId } from 'shared/helpers/reducer-helpers';
import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import { LocationCreateType } from 'shared/state/models/locations/location-create-type';
import { PenFormData } from 'shared/state/models/locations/pen-form-data';
import { SectionFormData } from 'shared/state/models/locations/section-form-data';
import { StoreContainer } from 'shared/state/store-container';
import * as Action from './actions';
import { CurrentLocation } from './types';

export function GetLocations() {
	let state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;
	const lastSyncDate = state.locations.lastSyncDate;
	if (state.locations.syncInProgress) {
		return (dispatch: Dispatch<any>) => {
			return Promise.resolve();
		};
	}
	return (dispatch: Dispatch<any>) => {
		if (!state.offline.isConnected || !siteId) {
			return Promise.resolve();
		}

		dispatch(Action.load.started({ siteId, lastSyncDate }));

		return new SkioldOneClient(SkioldDigitalApiBaseUrl).locationsV2_Sync(siteId, lastSyncDate).then(
			(locations: SyncDataLocations) => {
				state = StoreContainer.getState();
				dispatch(
					Action.load.done({
						params: { siteId, lastSyncDate, activeSiteId: getSiteId(state.profile.active) },
						result: locations,
					}),
				);
			},
			(reject: ApiException) => {
				dispatch(
					Action.load.failed({
						params: { siteId, lastSyncDate },
						error: { code: reject.status, message: reject.response },
					}),
				);
			},
		);
	};
}

export const UpsertBuilding = (building: IBuilding) => {
	const state = StoreContainer.getState();
	const prevEntity = state.locations.buildings.find(b => b.id === building.id);

	return AsyncOperationBuilder2(
		Action.upsertBuilding,
		cl => cl.buildings_Upsert2(Building.fromJS(building)),
		Building.fromJS(building),
		prevEntity,
	);
};

export const UpsertSection = (section: ISection) => {
	const state = StoreContainer.getState();
	const prevEntity = state.locations.sections.find(sec => sec.id === section.id);

	return AsyncOperationBuilder2(
		Action.upsertSection,
		cl => cl.sectionsV2_Upsert2(Section.fromJS(section)),
		Section.fromJS(section),
		prevEntity,
	);
};

export const UpsertPen = (pen: IPen) => {
	const state = StoreContainer.getState();
	const prevEntity = state.locations.pens.find(p => p.id === pen.id);

	return AsyncOperationBuilder2(
		Action.upsertPen,
		cl => cl.pens_Upsert2(Pen.fromJS(pen)),
		Pen.fromJS(pen),
		prevEntity,
	);
};

export const SetRetentionTimeOnPens = (
	pens: { [key: string]: Date | undefined },
	forceClearRetentionDate?: boolean,
) => {
	const state = StoreContainer.getState();

	// Filter away any location, which retention time is blank or before todays date
	const pensToUpdate = state.locations.pens
		.filter(
			p =>
				(p.id && forceClearRetentionDate && Object.keys(pens).includes(p.id)) ||
				(p.id && pens[p.id] && (!p.retentionTime || pens[p.id]! > p.retentionTime)),
		)
		.map(p => {
			return Pen.fromJS({ ...p, retentionTime: pens[p.id!] });
		});

	return AsyncOperationBuilder2(
		Action.setRetentionTimeOnPens,
		cl => cl.pens_UpsertMany(pensToUpdate),
		pensToUpdate,
		pensToUpdate,
	);
};

export function SaveSyncPenData() {
	const state = StoreContainer.getState();
	const updates = state.locations.updatePens as Pen[];
	return AsyncOperationBuilder2(Action.setRetentionTimeOnPens, cl => cl.pens_UpsertMany(updates), updates, updates);
}

export const UpsertValve = (valve: IValve) => {
	const state = StoreContainer.getState();
	const prevEntity = state.locations.valves.find(v => v.id === valve.id);

	return AsyncOperationBuilder2(Action.upsertValve, cl => cl.valves_Upsert(valve as any), valve as any, prevEntity);
};

export const RemoveBuilding = (building: IBuilding) => {
	return AsyncOperationBuilder2(Action.removeBuilding, cl => cl.buildings_Delete(building.id!), building.id!);
};

export const RemoveSection = (section: ISection) => {
	return AsyncOperationBuilder2(Action.removeSection, cl => cl.sectionsV2_Delete(section.id!), section.id!);
};

export const RemovePen = (pen: IPen) => {
	return AsyncOperationBuilder2(Action.removePen, cl => cl.pens_Delete(pen.id!), pen.id!);
};

export const RemoveValve = (valve: IValve) => {
	return AsyncOperationBuilder2(Action.removeValve, cl => cl.valves_Delete(valve.id!), valve.id!);
};

//Remember last picked location
export const SetCurrentLocation = (currentLocation: CurrentLocation) => {
	return (dispatch: Dispatch<any>) => {
		dispatch(Action.setCurrentLocation.done({ params: currentLocation, result: currentLocation }));
	};
};

// Set if setups should use remembered location
export const SetUseCurrentLocation = (useCurrentLocation: boolean) => {
	return (dispatch: Dispatch<any>) => {
		dispatch(Action.setUseCurrentLocation(useCurrentLocation));
	};
};

export function SaveLocation(
	createType: LocationCreateType,
	penFormData: PenFormData,
	building: IBuilding,
	sectionFormData?: SectionFormData,
	section?: ISection,
) {
	return (dispatch: Dispatch<any>) => {
		const state = StoreContainer.getState();
		if (!penFormData || !building) {
			return;
		}
		if (!building.id) {
			building.id = new ObjectID().toHexString();
		}

		let sections: ISection[] = [];
		if (createType === LocationCreateType.AddPen && section) {
			sections.push(section);
		} else if (createType !== LocationCreateType.AddPen && sectionFormData) {
			sections = generateSections(building, sectionFormData);
		} else {
			return;
		}

		if (createType === LocationCreateType.AddSection && building && !building.useSections) {
			const currentSection = state.locations.sections.find(section => section.buildingId === building.id);
			if (currentSection) {
				sections[0].id = currentSection.id;
				const edittedBuilding = { ...building, useSections: true };
				UpsertBuilding(edittedBuilding)(dispatch);
			} else {
				return;
			}
		}

		const pens = generatePens(sections, penFormData);
		if (createType === LocationCreateType.AddPen && section && !section.usePens) {
			const currentPen = state.locations.pens.find(pen => pen.sectionId === section.id);
			if (currentPen) {
				pens[0].id = currentPen.id;
				const edittedSection = { ...section, usePens: true };
				UpsertSection(edittedSection)(dispatch);
			} else {
				return;
			}
		}

		if (createType === LocationCreateType.AddSection && building && !building.useSections) {
			const currentSection = state.locations.sections.find(section => section.buildingId === building.id);
			if (currentSection) {
				const currentPen = state.locations.pens.find(pen => pen.sectionId === currentSection.id);
				if (currentPen) {
					pens[0].id = currentPen.id;
				} else {
					return;
				}
			} else {
				return;
			}
		}

		if (createType === LocationCreateType.CreateBuilding) {
			UpsertBuilding(building)(dispatch);
		}
		if (createType !== LocationCreateType.AddPen) {
			sections.forEach(sec => UpsertSection(sec)(dispatch));
		}

		pens.forEach(pen => UpsertPen(pen)(dispatch));
	};

	function generateSections(b: IBuilding, sectionForm: SectionFormData) {
		let startNum = sectionForm.startNumber!;
		let sections: Section[] = [];
		for (let i = 0; i < sectionForm.numOfSections!; i++) {
			sections.push(
				Section.fromJS({
					buildingId: b.id,
					type: sectionForm.selectedLocationType!,
					name:
						(sectionForm.nameBehindStartNumber ? sectionForm.nameBehindStartNumber + ' ' : '') +
						startNum.toString(),
					order: startNum,
					usePens: sectionForm.UsePens,
					id: new ObjectID().toHexString(),
					siteId: b.siteId,
					animalType: sectionForm.selectedAnimalType,
				} as ISection),
			);
			++startNum;
		}
		return sections;
	}

	function generatePens(sections: ISection[], penForm: PenFormData) {
		let pens: Pen[] = [];
		for (let sec of sections) {
			let startNum = penForm.startNumber!;
			for (let i = 0; i < penForm.numOfPens!; i++) {
				pens.push(
					Pen.fromJS({
						sectionId: sec.id,
						capacity: penForm.capacity!,
						order: startNum,
						id: new ObjectID().toHexString(),
						siteId: sec.siteId,
						buildingId: sec.buildingId,
						name: startNum + (penForm.letter ? penForm.letter : ''),
					} as IPen),
				);
				++startNum;
			}
		}
		return pens;
	}
}
