import memoize from 'memoize-one';
import React from 'react';
import IsEqual from 'react-fast-compare';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
	IValidationInterval,
	IValidationSetup,
	PregnancyValidationType,
	ValidationInterval,
	ValidationSetup,
	WeaningCode,
} from 'shared/api/api';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { deepCopy } from 'shared/helpers/general-helpers';
import { IntegerChanged } from 'shared/helpers/number-helper';
import * as validationOperation from 'shared/state/ducks/validation-setup/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { showAlert } from 'web/view/components/skiold-alert/skiold-alert';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldInput } from 'web/view/components/skiold-components/skiold-input/skiold-input';
import { SkioldTable } from 'web/view/components/skiold-components/skiold-table/skiold-table';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { DefaultValidationValues } from './default-validation-values';
import './validation-setup.scss';

interface PropsFromParent {}

const mapStateToProps = (state: WebAppState) => {
	return {
		validationSetup: state.validationSetup.entity,
		siteId: state.profile.active!.siteId,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	getValidationSetup: () => validationOperation.GetSyncData()(dispatch),
	saveValidationSetup: (validation: IValidationSetup) =>
		validationOperation.SaveValidationSetup(validation)(dispatch),
});

interface State {
	validationSetup: IValidationSetup;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;
class ValidationSetupSetting extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			validationSetup: ValidationSetup.fromJS({
				validationIntervals: this.init(),
				afterAvertion: WeaningCode.Sold,
				qrCodeForSows: false,
			} as IValidationSetup),
		};
	}

	private validationSetupFromState: IValidationSetup | undefined;

	private generateData = memoize((validationSetupsFromStore, validationSetup) => {
		if (!this.validationSetupFromState && validationSetupsFromStore) {
			this.validationSetupFromState = deepCopy(validationSetupsFromStore);
			if (!this.validationSetupFromState!.validationIntervals) {
				this.validationSetupFromState!.validationIntervals = this.init();
			}
			this.setState({ validationSetup: { ...this.state.validationSetup, ...this.validationSetupFromState } });
			return { ...this.state.validationSetup, ...this.validationSetupFromState }.validationIntervals;
		}
		return { ...this.state.validationSetup }.validationIntervals;
	});

	public async componentDidMount() {
		await this.props.getValidationSetup();
	}

	public async componentDidUpdate(prevProps: Props) {
		if (!IsEqual(prevProps.validationSetup, this.props.validationSetup)) {
			this.setState({ validationSetup: this.props.validationSetup });
		}
	}

	public minusChanged(text: string, validationInterval: IValidationInterval) {
		IntegerChanged(text, valueToSet => {
			const intervalCopy = deepCopy(this.state.validationSetup.validationIntervals);
			const index = intervalCopy!.findIndex(interval => interval.type === validationInterval.type);
			intervalCopy![index].minus = valueToSet!;
			let validationCopy = { ...this.state.validationSetup };
			validationCopy.validationIntervals = intervalCopy;
			this.setState({
				validationSetup: validationCopy,
			});
		});
	}

	public planChanged(text: string, validationInterval: IValidationInterval) {
		IntegerChanged(text, valueToSet => {
			const intervalCopy = deepCopy(this.state.validationSetup.validationIntervals);
			let validationCopy = { ...this.state.validationSetup };
			const index = intervalCopy!.findIndex(interval => interval.type === validationInterval.type);
			intervalCopy![index].plan = valueToSet!;
			validationCopy.validationIntervals = intervalCopy;

			this.setState({
				validationSetup: validationCopy,
			});
		});
	}

	public plusChanged(text: string, validationInterval: IValidationInterval) {
		IntegerChanged(text, valueToSet => {
			const intervalCopy = deepCopy(this.state.validationSetup.validationIntervals);
			let validationCopy = { ...this.state.validationSetup };
			const index = intervalCopy!.findIndex(interval => interval.type === validationInterval.type);
			intervalCopy![index].plus = valueToSet!;
			validationCopy.validationIntervals = intervalCopy;
			this.setState({
				validationSetup: validationCopy,
			});
		});
	}

	public render() {
		return (
			<ViewWeb className="validation-setup">
				<SkioldTable
					columns={this.generateColumns()}
					data={this.generateData(this.props.validationSetup, this.state.validationSetup)}
					filterable={false}
					className={'treatment-table'}
					sortable={false}
				/>
				<ViewWeb className="row-wrapper"></ViewWeb>
				<ViewWeb className="save-container">
					<SkioldButton title={localized('Save')} onPress={this.save} />
				</ViewWeb>
			</ViewWeb>
		);
	}

	private generateColumns() {
		return [
			{
				id: 'type',
				Header: 'Type',
				accessor: (s: IValidationInterval) => (s.type !== undefined ? localized(s.type) : ''),
			},
			{
				id: 'lower',
				Header: localized('systemMinimum'),
				accessor: (s: IValidationInterval) => {
					return <TextWeb>{this.getLowerLimit(s.type!)}</TextWeb>;
				},
			},
			{
				id: 'minus',
				Header: localized('deviationMinimum'),
				accessor: (s: IValidationInterval) => {
					return (
						<SkioldInput
							onChangeText={newNumber => this.minusChanged(newNumber, s)}
							text={!s.minus ? '' : s.minus.toString()}
							className={'alignTextCenter'}
						/>
					);
				},
			},
			{
				id: 'plan',
				Header: localized('plan'),
				accessor: (s: IValidationInterval) => {
					return (
						<SkioldInput
							onChangeText={newNumber => this.planChanged(newNumber, s)}
							text={!s.plan ? '' : s.plan.toString()}
							className={'alignTextCenter'}
						/>
					);
				},
			},
			{
				id: 'plus',
				Header: localized('deviationMaximim'),
				accessor: (s: IValidationInterval) => {
					return (
						<SkioldInput
							onChangeText={newNumber => this.plusChanged(newNumber, s)}
							text={!s.plus ? '' : s.plus.toString()}
							className={'alignTextCenter'}
						/>
					);
				},
			},
			{
				id: 'upper',
				Header: localized('systemMaximum'),
				accessor: (s: IValidationInterval) => {
					return <TextWeb>{this.getUpperLimit(s.type!)}</TextWeb>;
				},
			},
		];
	}

	private getLowerLimit(type: PregnancyValidationType) {
		return (DefaultValidationValues as any)[type + '_LOWER_LIMIT'];
	}

	private getUpperLimit(type: PregnancyValidationType) {
		return (DefaultValidationValues as any)[type + '_UPPER_LIMIT'];
	}

	private init() {
		return [
			new ValidationInterval({
				type: PregnancyValidationType.MatingAge,
			} as IValidationInterval),
			new ValidationInterval({
				type: PregnancyValidationType.MatingToFarrowing,
			} as IValidationInterval),
			new ValidationInterval({
				type: PregnancyValidationType.FarrowingToAverted,
			} as IValidationInterval),
			new ValidationInterval({
				type: PregnancyValidationType.AvertedToMating,
			} as IValidationInterval),
		];
	}

	private save = async () => {
		if (!this.validate()) {
			return;
		}
		const setupCopy = deepCopy(this.state.validationSetup);
		setupCopy.siteId = this.props.siteId;
		this.props.saveValidationSetup(setupCopy);
		alert(localized('Saved'));
	};

	private validate() {
		for (let validationInterval of this.state.validationSetup.validationIntervals!) {
			let isMatingAge = validationInterval.type === PregnancyValidationType.MatingAge;
			if (!validationInterval.plan && !isMatingAge) {
				showAlert(localized(ExceptionMessage.VALIDATION_ERROR_FIELDS_REQUIRED));
				return false;
			} else if (!validationInterval.plan && isMatingAge) {
				continue;
			}

			if (
				!(
					validationInterval.plan &&
					validationInterval.plan >= validationInterval.minus! &&
					validationInterval.plan <= validationInterval.plus!
				)
			) {
				showAlert(localized(ExceptionMessage.VALIDATION_ERROR_INVALID_PERIOD));
				return false;
			}
			if (!validationInterval.minus) {
				showAlert(localized(ExceptionMessage.VALIDATION_ERROR_FIELDS_REQUIRED));
				return false;
			}
			if (!validationInterval.plus) {
				showAlert(localized(ExceptionMessage.VALIDATION_ERROR_FIELDS_REQUIRED));
				return false;
			}

			if (
				validationInterval.type === PregnancyValidationType.MatingToFarrowing &&
				!(
					validationInterval.minus! >= DefaultValidationValues.MatingToFarrowing_VALIDATION_LOWER_LIMIT &&
					validationInterval.plus! <= DefaultValidationValues.MatingToFarrowing_UPPER_LIMIT
				)
			) {
				showAlert(localized(ExceptionMessage.VALIDATION_ERROR_INVALID_PREGNANT_PERIOD));
				return false;
			}

			if (
				validationInterval.type === PregnancyValidationType.FarrowingToAverted &&
				validationInterval.plan < 21
			) {
				showAlert(localized(ExceptionMessage.VALIDATION_ERROR_INVALID_NURSING_PERIOD));
				return false;
			}
		}

		return true;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ValidationSetupSetting);
