import { Equipment, IUnitToPen, ProcessUnit, UnitData } from 'shared/api/api';
import { Option } from 'react-dropdown';
import { localized } from 'shared/state/i18n/i18n';
export class LocationRelationTableItem {
	public id?: string;
	public ProcessEquipmentType: Equipment | undefined;
	public buildingId: string | undefined;
	public sectionId: string | undefined;
	public sectionCode: string | undefined;
	public buildingCodeToShow: string | undefined;
	public actualBuildingCode: string | undefined;
}

export class UnitsOnProcessEquipmentItem {
	public id?: string;
	public isFromImport?: boolean;
	public unitNumber?: string;
	public unitToPen?: IUnitToPen[];
	public locationCode?: string;
	public buildingCode?: string;
	public sectionCode?: string;
	public processEquipmentUnitCode?: UnitData;
	public buildingId: string | undefined;
	public sectionId: string | undefined;
	public usesPens: boolean | undefined;
}

const DefaultEntranceTypeOption: Option = {
	label: ' ',
	value: '',
};

export function GetUnitTypes(equipment?: Equipment, defaultOption: boolean = true) {
	let types = defaultOption ? [DefaultEntranceTypeOption] : [];
	if (equipment === Equipment.Distriwin) {
		types.push({ value: ProcessUnit.Valve, label: localized('Valves') });
	}
	if (equipment === Equipment.ESF) {
		types.push({ value: ProcessUnit.Station, label: localized('Stations') });
	}
	return types;
}

export function GetUnitOption(equipment?: Equipment) {
	switch (equipment) {
		case Equipment.Distriwin:
			return { value: ProcessUnit.Valve, label: localized('Valves') };
		case Equipment.ESF:
			return { value: ProcessUnit.Station, label: localized('Stations') };
		default:
			return { label: ' ', value: '' };
	}
}
