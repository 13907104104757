
import actionCreatorFactory from 'typescript-fsa';
import * as types from './types';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';

const actionCreator = actionCreatorFactory(GenActionFactoryName('retryAdjustFeed'));

export const getTableData = actionCreator.async<{siteId: string, activeSiteId?: string}, types.RetryAdjustFeedItemApp[]>(types.GET_DATA);

export const retryAdjustFeed = actionCreator.async<
    types.RetryAdjustFeedItemApp,
    { valveFieldUpdateDto: types.RetryAdjustFeedItemApp; failedIds: string[] }
>(types.SAVE_RETRY);

export const deleteRetryAdjustFeedItem = actionCreator.async<
    types.RetryAdjustFeedItemApp,
    types.RetryAdjustFeedItemApp
>(types.DELETE_RETRY);
export const updateCommitStatusOnAll = actionCreator.async<undefined, boolean>(types.SET_COMMIT_STATUS_ON_ALL);

export const setRetryCommitStatus = actionCreator.async<undefined, types.RetryAdjustFeedItemApp>(
    types.SET_COMMIT_STATUS
);
