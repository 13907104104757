import React from 'react';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Option } from 'react-dropdown';
import { WebAppState } from 'web/state/store.web';
import { IBuilding, LocationType, AnimalType, ProductionForm } from 'shared/api/api';
import { SectionFormData } from 'shared/state/models/locations/section-form-data';
import { PenFormData } from 'shared/state/models/locations/pen-form-data';
import { generateAnimalType, generatePenFormData, generateLocationType } from 'shared/helpers/location-helper';
import { deepCopy } from 'shared/helpers/general-helpers';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { SkioldFormsWrapper } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { showAlert } from 'web/view/components/skiold-alert/skiold-alert';
import { localized } from 'shared/state/i18n/i18n';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { FormRow } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { SkioldFormIntegerInput } from 'web/view/components/skiold-components/skiold-integer-input/skiold-form-integer-input';
import { SkioldCheckbox } from 'web/view/components/skiold-components/skiold-checkbox/skiold-checkbox';
import { SkioldFormDropdown } from 'web/view/components/skiold-components/skiold-dropdown/skiold-form-dropdown';
import './add-section.scss';
import { LocationCreateType } from 'shared/state/models/locations/location-create-type';
import { SkioldFormInput } from 'web/view/components/skiold-components/skiold-input/skiold-form-input';
import { AnyRecord } from 'dns';

interface PropsFromParent {
	building: IBuilding;
	UsesPens: boolean;
	locationCreateType: LocationCreateType;
	penFormChanged: (PenFormData: PenFormData | undefined) => void;
	usingPensChanged: (penChanged: boolean) => void;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		sections: state.locations.sections,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({});

interface AddSectionState {
	sectionForm: SectionFormData;
	animalTypes: Option[];
	locationTypes: Option[];
	selectedLocationType: Option;
	selectedAnimalType: Option;
	penForm: PenFormData | undefined;
}

const DefaultTypeOption: Option = { label: ' ', value: '' };

type AddSectionProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;
export class AddSection extends React.PureComponent<AddSectionProps, AddSectionState> {
	constructor(props: AddSectionProps) {
		super(props);
		this.state = {
			sectionForm: new SectionFormData(),
			animalTypes: generateAnimalType(props.building.productionForm),
			selectedAnimalType: DefaultTypeOption,
			locationTypes: [],
			selectedLocationType: DefaultTypeOption,
			penForm: undefined,
		};
	}
	public amountOfSectionsChanged = (numOfSections: number | undefined) => {
		this.setState(prevState => ({ sectionForm: { ...prevState.sectionForm, numOfSections } }));
	};

	public startNumberChange = (startNumber: number | undefined) => {
		this.setState(prevState => ({ sectionForm: { ...prevState.sectionForm, startNumber } }));
	};

	public setLocationType = (locationType: Option) => {
		this.setState(prevState => ({
			sectionForm: { ...prevState.sectionForm, selectedLocationType: locationType.value as LocationType },
			selectedLocationType: locationType,
		}));
	};

	public toggleIsUsingPens = () => {
		const toggle = deepCopy(this.state.sectionForm.UsePens);
		this.setState(prevState => ({
			sectionForm: { ...prevState.sectionForm, UsePens: !toggle },
			penForm: toggle ? generatePenFormData() : undefined,
		}));
		this.props.usingPensChanged(toggle ? false : true);
		this.props.penFormChanged(toggle ? generatePenFormData() : undefined);
	};

	public setAnimalType = (animalType: Option) => {
		const locationTypes = generateLocationType(this.props.building.productionForm, animalType.value);
		this.setState(prevState => ({
			sectionForm: { ...prevState.sectionForm, selectedAnimalType: animalType.value as AnimalType },
			selectedAnimalType: animalType,
			locationTypes,
		}));
	};

	public render() {
		const animalType = generateAnimalType(this.props.building.productionForm);
		return (
			<ViewWeb className="add-section">
				<ViewWeb
					className={`form-container ${
						this.props.building.useSections ||
						this.props.locationCreateType === LocationCreateType.AddSection
							? 'pointer-event-auto'
							: 'pointer-event-none'
					}`}
				>
					<SkioldFormsWrapper roundCorners={false} formRows={this.getFormRows(animalType)} />
				</ViewWeb>
			</ViewWeb>
		);
	}

	public save = () => {
		if (!this.validate()) {
			return undefined;
		}
		return this.state.sectionForm;
	};

	public validate() {
		if (!this.props.building.useSections && this.props.locationCreateType !== LocationCreateType.AddSection) {
			return true;
		}

		if (!this.state.sectionForm.UsePens) {
			if (!this.state.penForm!.capacity) {
				showAlert(localized(ExceptionMessage.VALIDATION_ERROR_FIELDS_REQUIRED));
				return false;
			}

			if (this.state.penForm!.capacity! < 1) {
				showAlert(localized(ExceptionMessage.VALIDATION_ERROR_CANNOT_BE_NEGATIVE_OR_ZERO));
				return false;
			}
		}

		if (!this.state.sectionForm.numOfSections) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_FIELDS_REQUIRED));
			return false;
		}

		if (this.state.sectionForm.numOfSections < 1) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_CANNOT_BE_NEGATIVE_OR_ZERO));
			return false;
		}

		if (!this.state.sectionForm.startNumber) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_FIELDS_REQUIRED));
			return false;
		}

		if (this.state.sectionForm.startNumber < 1) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_CANNOT_BE_NEGATIVE_OR_ZERO));
			return false;
		}
		if (!this.state.sectionForm.selectedLocationType) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_SECTION_TYPE_NOT_SET));
			return false;
		}

		if (this.props.building) {
			if (this.hasDuplicateSectionNames(this.props.building.id!)) {
				showAlert(localized(ExceptionMessage.VALIDATION_ERROR_DUPLICATE_SECTION_NUMBERS));
				return false;
			}
		}

		if (this.state.sectionForm.UsePens) {
			if (this.state.penForm && this.state.penForm.capacity && this.state.penForm.capacity! < 1) {
				showAlert(localized(ExceptionMessage.VALIDATION_ERROR_CANNOT_BE_NEGATIVE_OR_ZERO));
				return false;
			}
		}
		return true;
	}

	public capacityChanged = (capacity: number | undefined) => {
		this.setState(prevState => ({ penForm: { ...prevState.penForm!, capacity } }));
		let penFormData = this.state.penForm!;
		penFormData.capacity = capacity;
		this.props.penFormChanged(penFormData);
	};

	private getFormRows(animalType: Option[]) {
		let formRows = new Array<FormRow>();
		formRows.push(this.getBuildingHeader());

		formRows.push(this.getAmountSectionsRow());
		formRows.push(this.getStartNumberRow());
		formRows.push(this.getNameBehindStartNumber());

		formRows.push(this.getIsUsingPensRow());
		if (this.props.building.productionForm === ProductionForm.PigProduction) {
			formRows.push(this.getAnimalTypeRow(animalType));
		}
		formRows.push(this.getLocationTypeRow());

		if (this.state.penForm) {
			formRows.push(this.getCapacityRow());
		}

		return formRows;
	}

	private getBuildingHeader(): FormRow {
		return {
			header: localized('SECTION'),
		};
	}

	private getAmountSectionsRow(): FormRow {
		return {
			name: localized('amountOfSections'),
			component: (
				<SkioldFormIntegerInput
					onChangeNumber={this.amountOfSectionsChanged}
					text={this.state.sectionForm.numOfSections}
				/>
			),
		};
	}

	private getNameBehindStartNumber(): FormRow {
		return {
			name: localized('NameBehindStartNumber'),
			component: (
				<SkioldFormInput
					onChangeText={this.onPropertyChangedOnForm}
					text={this.state.sectionForm.nameBehindStartNumber}
				/>
			),
		};
	}

	private onPropertyChangedOnForm = (nameBehindStartNumber: string) => {
		this.setState(prevState => ({ sectionForm: { ...prevState.sectionForm, nameBehindStartNumber } }));
	};

	private getIsUsingPensRow(): FormRow {
		return {
			name: localized('isUsingPens'),
			component: (
				<SkioldCheckbox
					onClick={this.toggleIsUsingPens}
					isChecked={this.props.UsesPens ? this.state.sectionForm.UsePens! : false}
				/>
			),
		};
	}

	private getStartNumberRow(): FormRow {
		return {
			name: localized('startNumber'),
			component: (
				<SkioldFormIntegerInput
					onChangeNumber={this.startNumberChange}
					text={this.state.sectionForm.startNumber}
				/>
			),
		};
	}

	private getCapacityRow(): FormRow {
		return {
			name: localized('capacity'),
			component: (
				<SkioldFormIntegerInput onChangeNumber={this.capacityChanged} text={this.state.penForm!.capacity} />
			),
		};
	}

	private getAnimalTypeRow(animalTypes: Option[]): FormRow {
		return {
			name: localized('production'),
			component: (
				<SkioldFormDropdown
					items={animalTypes}
					selectedValue={this.state.selectedAnimalType}
					onValueChanged={this.setAnimalType}
				/>
			),
		};
	}
	private getLocationTypeRow(): FormRow {
		return {
			name: localized('locationType'),
			component: (
				<SkioldFormDropdown
					items={generateLocationType(
						this.props.building.productionForm,
						this.state.selectedAnimalType.value,
					)}
					selectedValue={this.state.selectedLocationType}
					onValueChanged={(locationTypeOption: Option) => this.setLocationType(locationTypeOption)}
				/>
			),
		};
	}

	private hasDuplicateSectionNames(buildingId: string) {
		const currentSectionNames = this.props.sections
			.filter(sec => sec.buildingId === buildingId)
			.map(sec => sec.name);
		const newSectionNames = [] as string[];
		//generate new section names
		for (
			let i = this.state.sectionForm.startNumber!;
			i < this.state.sectionForm.numOfSections! + this.state.sectionForm.startNumber!;
			i++
		) {
			newSectionNames.push(
				(this.state.sectionForm.nameBehindStartNumber
					? this.state.sectionForm.nameBehindStartNumber + ' '
					: '') + i.toString(),
			);
		}
		let duplicates = currentSectionNames.filter(secName => newSectionNames.includes(secName!));
		if (duplicates.length > 0) {
			return true;
		} else {
			return false;
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(AddSection) as any;
