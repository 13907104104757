import { Action } from 'redux';
import { SiteAuthorizations, UserProfile2 } from 'shared/api/api';
import * as actions from 'shared/state/ducks/authorizations/actions';
import { AuthorizationsState } from 'shared/state/ducks/authorizations/types';
import { isType } from 'typescript-fsa';
import { removeUserSiteAccess } from '../user-sites/actions';

export const initialModuleState: AuthorizationsState = {
	auth: new SiteAuthorizations(),
};

export default function authorizationReducer(
	state: AuthorizationsState = initialModuleState,
	action: Action,
): AuthorizationsState {
	if (isType(action, actions.load.done)) {
		return {
			...state,
			auth: action.payload.result,
		};
	}
	if (isType(action, actions.updateInitials.done) && state.auth.userProfiles !== undefined) {
		const result = action.payload.result;
		const index = state.auth.userProfiles.findIndex(p => p.id === result.id);

		if (index > -1) {
			state.auth.userProfiles[index] = result as UserProfile2;
			return state;
		}
	}

	return state;
}
