import React from 'react';
import { Option } from 'react-dropdown';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
	Gender,
	IPregnancyEvent,
	IScanned,
	IStemAnimal,
	PregnancyState,
	Scanned,
	ScanResult,
	StemAnimal,
} from 'shared/api/api';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { deepCopy } from 'shared/helpers/general-helpers';
import { getLocationString } from 'shared/helpers/location-helper';
import {
	setupPregnancyEventById,
	ValidateAnimalIPregnancyLocationAndEsfIsEnabled,
} from 'shared/helpers/pregnancy-helper/generel-pregnancy-helpers';
import { sortPregnanciesByDate } from 'shared/helpers/pregnancy-helper/sort-pregnancies';
import { mergeArraysHashmap } from 'shared/helpers/reducer-helpers';
import { ViewWeb } from 'web/view/components/utils/web-view';
import {
	DeleteLastPregnancyEvent,
	GetSyncData as PregnancyEventGetSyncData,
	SavePregnancyEvent,
} from 'shared/state/ducks/pregnancy-events/operations';
import { GetSyncData as StemAnimalGetSyncData } from 'shared/state/ducks/stem-animals/operations';
import { GetSyncData as ValidationSetupGetSyncData } from 'shared/state/ducks/validation-setup/operations';
import { localized, localizedDynamic } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import MoveEventBase from 'web/view/components/stem-animal/sow-events/move-event/move-event-base';
import { createAndEditValidation } from 'web/web-helpers/pregnancies/register-validation-helper';
import { validateSowAnimalNumberIsNotDepartured } from 'web/web-helpers/pregnancies/web-prengancy-validation-settings';
import { showAlert, ShowConfirmAlert } from '../../skiold-alert/skiold-alert';
import { SkioldButton } from '../../skiold-components/skiold-button/skiold-button';
import { SkioldCheckbox } from '../../skiold-components/skiold-checkbox/skiold-checkbox';
import { SkioldDatePicker } from '../../skiold-components/skiold-date-picker/skiold-date-picker';
import { SkioldFormDropdown } from '../../skiold-components/skiold-dropdown/skiold-form-dropdown';
import { SkioldFormTextField } from '../../skiold-components/skiold-forms-wrapper/skiold-form-text';
import { SkioldFormsWrapper } from '../../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from '../../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import SkioldPregnancyAnimalInput from '../../stem-animal/stem-animal-input/skiold-pregnancy-animal-input';
import { Scroller } from '../../utils/scroller';
import { GenerateScanResults } from './register-events-helper';
import './register-scanning.scss';
import * as UnitsToPenOperations from 'shared/state/ducks/unit-to-pen/operations';
import { GetProcessEquipmentDataSyncData } from 'shared/state/ducks/process-equipment-data/operations';
import { DepartureTypes } from 'shared/state/models/departure-types';
import { getLastMoveEventDate, isMoveDateValid } from 'shared/helpers/stemanimal-helper/stemanimal-helper';
import { getDateString } from 'shared/helpers/date-helpers';

interface PropsFromParent {
	pregnancyEventId?: string;
	stemAnimalId?: string;
	closeEditModal?: () => void;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		siteId: state.profile.active!.siteId,
		pregnancyEvents: mergeArraysHashmap(
			state.pregnancyEvents.entities,
			state.pregnancyEvents.departuredPregnancies,
		),
		unitToPens: state.unitToPenData.data,
		processEquipmentData: state.processEquipmentData.entities,
		validationSetup: state.validationSetup.entity,
		departuredStemAnimals: state.stemAnimals.departuredAnimals,
		moveEvents: state.moveEvents.entities,
		generalSettings: state.generalSettings.entity,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	getValidationSetup: () => ValidationSetupGetSyncData()(dispatch),
	stemAnimalGetSyncData: () => StemAnimalGetSyncData()(dispatch),
	pregnancyEventGetSyncData: () => PregnancyEventGetSyncData()(dispatch),
	savePregnancyEvent: (pregnancyEvent: IPregnancyEvent) => SavePregnancyEvent(pregnancyEvent)(dispatch),
	deleteLastPregnancyEvent: (pregEvent: IPregnancyEvent) => DeleteLastPregnancyEvent(pregEvent)(dispatch),
	GetProcessEquipmentDataSyncData: async () => (await GetProcessEquipmentDataSyncData())(dispatch),
	getunitsData: () => UnitsToPenOperations.GetSyncData()(dispatch),
});

interface ScanningState {
	sow: IStemAnimal;
	scanned: IScanned;
	scanResults: Option[];
	animalEvents: IPregnancyEvent[];
	prevEvent: IPregnancyEvent;
	selectedResult: Option;
	animalNumber: string | undefined;
	registerMoveEvent: boolean;
	toggleFocus: boolean;
	lastMoveDate: Date | undefined;
}

export type ScanningProps = ReturnType<typeof mapStateToProps> &
	ReturnType<typeof mapDispatchToProps> &
	PropsFromParent;
export class RegisterScanning extends React.PureComponent<ScanningProps, ScanningState> {
	public moveEventSave: (() => Promise<boolean>) | undefined;

	private scrollerRef: Scroller | null | undefined;
	private DefaultResultOption = { value: ScanResult.True, label: localized(ScanResult.True) };
	constructor(props: ScanningProps) {
		super(props);
		this.state = {
			sow: StemAnimal.fromJS({}),
			scanned: Scanned.fromJS({
				date: new Date(),
				state: PregnancyState.Scanned,
				result: ScanResult.True,
			}),
			scanResults: GenerateScanResults(),
			animalEvents: [],
			prevEvent: {} as IPregnancyEvent,
			selectedResult: this.DefaultResultOption,
			animalNumber: undefined,
			registerMoveEvent: false,
			toggleFocus: true,
			lastMoveDate: undefined,
		};

		this.animalNumberChanged = this.animalNumberChanged.bind(this);
	}

	public componentDidMount() {
		this.props.stemAnimalGetSyncData();
		this.props.getValidationSetup();
		this.props.pregnancyEventGetSyncData();
		this.props.getunitsData();
		this.props.GetProcessEquipmentDataSyncData();

		if (this.props.pregnancyEventId) {
			setupPregnancyEventById(
				this.props.pregnancyEvents,
				this.props.pregnancyEventId,
				this.props.stemAnimalId!,
				this.animalNumberChanged,
				(animalNumber, pregEvent) => {
					this.setState({
						animalNumber,
						selectedResult: {
							label: localized((pregEvent as Scanned).result!),
							value: (pregEvent as Scanned).result!,
						},
					});
				},
			);
		}
	}

	public animalNumberChanged(
		sow: IStemAnimal,
		animalEvents: IPregnancyEvent[],
		prevEvent: IPregnancyEvent,
		newPregnancyEvent: IPregnancyEvent,
	) {
		const lastMoveDate = getLastMoveEventDate(sow.id, this.props.moveEvents);
		let state = {
			sow,
			animalEvents: animalEvents || [],
			prevEvent,
			scanned: newPregnancyEvent as IScanned,
			registerMoveEvent: !isMoveDateValid(lastMoveDate, newPregnancyEvent.date)
				? false
				: this.state.registerMoveEvent,
			lastMoveDate,
		};
		if (prevEvent.state === PregnancyState.Scanned) {
			this.setState({
				...state,
				scanned: { ...newPregnancyEvent, id: prevEvent.id, createdOn: prevEvent.createdOn } as IScanned,
			});
		} else {
			this.setState(state);
		}
	}

	public finishedDateChanged = (newDate: Date) => {
		const moveValid = isMoveDateValid(this.state.lastMoveDate, newDate);
		this.setState(prevState => ({
			scanned: { ...prevState.scanned, date: newDate },
			registerMoveEvent: !moveValid ? false : this.state.registerMoveEvent,
		}));
	};
	public scanResultChanged = (resultOption: Option) => {
		this.setState(prevState => ({
			scanned: { ...prevState.scanned, result: resultOption.value as ScanResult },
			selectedResult: resultOption,
		}));
	};

	public toggleMoveEvent = () => {
		const toggle = !this.state.registerMoveEvent;
		this.setState({ registerMoveEvent: toggle });
	};

	public render() {
		return <ViewWeb className="register-scanning">{this.renderContent()}</ViewWeb>;
	}

	public renderContent() {
		let formRows = this.getFormRows();

		return (
			<ViewWeb>
				<ViewWeb className="move-event-container-zindex">
					{this.renderButtons()}
					{this.state.registerMoveEvent && (
						<MoveEventBase
							date={this.state.scanned.date}
							stableRow={(formRow: FormRow) => formRows.push(formRow)}
							penRow={(formRow: FormRow) => formRows.push(formRow)}
							feedCurveRow={(formRow: FormRow) => formRows.push(formRow)}
							fixedDeviationRow={(formRow: FormRow) => formRows.push(formRow)}
							stemAnimal={this.state.sow}
							pregnancyEvent={this.state.scanned}
							update={this.forceUpdateView}
							gender={Gender.Female}
							save={this.saveMoveEvent}
						/>
					)}

					<SkioldFormsWrapper formRows={formRows} containerClassName="forms-wrapper-style" />
				</ViewWeb>
			</ViewWeb>
		);
	}

	public save = async () => {
		let errorMessage = await this.validate();
		if (errorMessage !== '') {
			showAlert(localizedDynamic(errorMessage));
			return;
		}
		if (
			!this.state.registerMoveEvent &&
			// isMoveDateValid(this.state.lastMoveDate,this.state.scanned.date)&&
			!ValidateAnimalIPregnancyLocationAndEsfIsEnabled(
				this.state.sow,
				this.state.scanned,
				this.props.unitToPens,
				this.props.processEquipmentData,
				showAlert,
			)
		) {
			return;
		}

		if (this.state.registerMoveEvent && this.moveEventSave) {
			const valid = await this.moveEventSave();
			if (!valid) {
				return;
			}
		}

		this.props.savePregnancyEvent(this.state.scanned);
		this.resetComponent();
		this.editDone();
	};

	public removeEvent = async () => {
		let allow = await ShowConfirmAlert(localized(ExceptionMessage.VALIDATION_WARNING_CONFIRM_DELETEION_SCANNING));
		if (!allow) {
			return;
		}
		if (this.state.scanned.id === sortPregnanciesByDate(this.state.animalEvents)[0].id) {
			let pregEvent = deepCopy(this.state.scanned);
			pregEvent.isDeleted = true;
			this.props.deleteLastPregnancyEvent(pregEvent);
			this.editDone();
		}
	};

	public async validate() {
		let animalNumberCheck = validateSowAnimalNumberIsNotDepartured(
			this.state.sow,
			this.props.departuredStemAnimals,
			this.state.animalNumber,
		);
		if (animalNumberCheck !== 'ignore') {
			return animalNumberCheck;
		}
		const basicValidation = createAndEditValidation(
			this.props.validationSetup,
			this.props.generalSettings,
			this.state.scanned,
			this.state.prevEvent,
			this.state.animalEvents,
		);
		if ((await basicValidation) !== '') {
			return basicValidation;
		}

		return '';
	}

	private saveMoveEvent = (method: () => Promise<boolean>) => (this.moveEventSave = method);
	private closeEditModal = () => this.props.closeEditModal!();

	private forceUpdateView = () => this.forceUpdate();
	private onAnimalNumberChanged = (animalNumber: string | undefined) => this.setState({ animalNumber });

	private editDone() {
		if (this.props.closeEditModal) {
			this.props.closeEditModal();
		}
	}

	private getFormRows() {
		let formRows = new Array<FormRow>();

		formRows.push(this.getPregnancyAnimalNumberRow());
		formRows.push(this.getLocationRow());
		formRows.push(this.getLastMoveDateRow());
		formRows.push(this.getDateRow());
		formRows.push(this.getResultPickerRow());
		formRows.push(this.getToggleMoveEventRow());

		return formRows;
	}

	private getPregnancyAnimalNumberRow(): FormRow {
		return {
			name: localized('animalNumber'),
			component: (
				<SkioldPregnancyAnimalInput
					onChangeAnimalNumber={this.animalNumberChanged}
					text={this.state.animalNumber}
					pregnancyEvent={this.state.scanned}
					onAnimalNumberChanged={this.onAnimalNumberChanged}
					editable={!this.props.pregnancyEventId}
					selectTextOnFocus={!this.props.pregnancyEventId}
					toggleFocus={this.state.toggleFocus}
					className={`${
						this.state.sow && this.state.sow.departureType === DepartureTypes.departureTypeShouldDeparture
							? 'should-departure-list-color-with-margin-change'
							: ''
					}`}
				/>
			),
		};
	}

	private getLocationRow(): FormRow {
		return {
			name: localized('location'),
			component: (
				<SkioldFormTextField>{this.state.sow ? getLocationString(this.state.sow) : ''}</SkioldFormTextField>
			),
		};
	}

	private getLastMoveDateRow(): FormRow {
		return {
			name: localized('lastMoved'),
			component: <SkioldFormTextField>{getDateString(this.state.lastMoveDate)}</SkioldFormTextField>,
		};
	}

	private getDateRow(): FormRow {
		return {
			name: localized('Date'),
			component: (
				<SkioldDatePicker
					onDateChanged={this.finishedDateChanged}
					selectedDate={this.state.scanned.date!}
					theme={'dark'}
					color={'grey'}
				/>
			),
		};
	}

	private getResultPickerRow(): FormRow {
		return {
			name: localized('Pregnant'),
			component: (
				<SkioldFormDropdown
					items={this.state.scanResults}
					selectedValue={this.state.selectedResult}
					onValueChanged={this.scanResultChanged}
				/>
			),
		};
	}

	private getToggleMoveEventRow(): FormRow {
		return {
			name: localized('move'),
			component: (
				<SkioldCheckbox
					containerClassName={'inForm'}
					disabled={!isMoveDateValid(this.state.lastMoveDate, this.state.scanned.date)}
					onClick={this.toggleMoveEvent}
					isChecked={this.state.registerMoveEvent}
				/>
			),
		};
	}

	private renderButtons() {
		return (
			<ViewWeb className="button-view-style-upper-corner">
				{this.props.closeEditModal && (
					<SkioldButton title={localized('Close')} onPress={this.closeEditModal} theme="grey" />
				)}
				{this.state.animalEvents.length > 0 &&
					this.props.pregnancyEventId === sortPregnanciesByDate(this.state.animalEvents)[0].id && (
						<SkioldButton title={localized('Delete')} onPress={this.removeEvent} theme="grey" />
					)}

				<SkioldButton title={localized('Save')} onPress={this.save} />
			</ViewWeb>
		);
	}

	// Called after successfull save to remove one-time values
	private async resetComponent() {
		this.setState({
			sow: StemAnimal.fromJS({ animalNumber: '' } as IStemAnimal),
			animalEvents: [],
			scanned: Scanned.fromJS({
				date: this.state.scanned.date ? this.state.scanned.date : new Date(),
				state: PregnancyState.Scanned,
				result: this.state.selectedResult.value,
			}),
			animalNumber: undefined,
			toggleFocus: !this.state.toggleFocus,
		});

		if (this.scrollerRef) {
			await this.scrollerRef.scrollToTop();
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterScanning);
