import {
	IPregnancyEvent,
	PregnancyState,
	Mated,
	Scanned,
	FarrowingInProgress,
	Farrowing,
	Averted,
	PregnancyEvent,
	IMated,
	IScanned,
} from 'shared/api/api';

export function NewPregnancyEventClass(pregEvent: IPregnancyEvent) {
	if (pregEvent.state === PregnancyState.Mated) {
		return Mated.fromJS(pregEvent);
	}
	if (pregEvent.state === PregnancyState.Scanned) {
		return Scanned.fromJS(pregEvent);
	}
	if (pregEvent.state === PregnancyState.FarrowingInProgress) {
		return FarrowingInProgress.fromJS(pregEvent);
	}
	if (pregEvent.state === PregnancyState.Farrowing) {
		return Farrowing.fromJS(pregEvent);
	}
	if (pregEvent.state === PregnancyState.Averted) {
		return Averted.fromJS(pregEvent);
	}
	return pregEvent as PregnancyEvent;
}
