import { CellColorStyle } from 'shared/api/api';
import { getAnimalNumberColor } from 'shared/helpers/treatment-helper/treatment-helper';

export const getWebAnimalColorStyleTable = (stemAnimalId: string | undefined) => {
	if (stemAnimalId) {
		const animalColor = getAnimalNumberColor(stemAnimalId);
		switch (animalColor) {
			case CellColorStyle.RedGreen:
				return 'retention-time-and-should-departure-list-color';
			case CellColorStyle.Red:
				return 'retention-time-list-color';
			case CellColorStyle.Green:
				return 'should-departure-list-color';
		}
	}
	return '';
};

export const getWebAnimalColorStyleTableCellColorStyle = (animalColor: CellColorStyle) => {
		switch (animalColor) {
			case CellColorStyle.RedGreen:
				return 'retention-time-and-should-departure-list-color';
			case CellColorStyle.Red:
				return 'esf-feeding-red-color';
			case CellColorStyle.Green:
				return 'esf-feeding-green-color';
		}
		return "";
};



export const getWebAnimalColorRetentionStyleTable = (stemAnimalId: string | undefined) => {
	if (stemAnimalId) {
		const animalColor = getAnimalNumberColor(stemAnimalId);
		switch (animalColor) {
			case CellColorStyle.RedGreen:
				return 'retention-time-list-color';
			case CellColorStyle.Red:
				return 'retention-time-list-color';
		}
	}
	return '';
};
