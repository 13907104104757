import {
	FileResponse,
	IDeadPigletsEvent,
	IPregnancyEvent,
	IPregnancyTableModel,
	IStemAnimal,
	ITreatmentTableModel,
	IValidationSetup,
	PregnancyTableModel,
	SkioldOneClient,
	SowBoardTableModel,
	TreatmentModel,
	TreatmentTableModel,
} from 'shared/api/api';
import { SkioldDigitalApiBaseUrl } from 'shared/constants';
import { getLocationString } from 'shared/helpers/location-helper';
import { findBatch } from 'shared/helpers/matingbatch-helper';
import { sortPregnanciesByDate } from 'shared/helpers/pregnancy-helper/sort-pregnancies';
import {
	calculateSowCardAvg,
	calculateSowCardWeightAvg,
	generateSowCardData,
	generateTreatmentData,
	getPigYearSow,
} from 'shared/helpers/sow-card-helper/sow-card-helper';
import { findRetentionTime } from 'shared/helpers/treatment-helper/treatment-helper';
import { savePdfAs } from './general-pdf-helper';

export function GetSowBoardPdf(
	sowboardTableData: SowBoardTableModel[],
	fileName: string,
	lang?: string,
	siteId?: string,
) {
	return new SkioldOneClient(SkioldDigitalApiBaseUrl)
		.generateSowBoardPdf_Get(sowboardTableData, siteId, lang)
		.then((fileResponse: FileResponse | null) => {
			savePdfAs(fileResponse, fileName);
		})
		.catch(() => {
			return;
		});
}

export function GenerateDataForSowBoardPdf(
	sow: IStemAnimal,
	sowcardforanimals: SowBoardTableModel[],
	pregEvents: { [key: string]: IPregnancyEvent[] },
	validationSetup: IValidationSetup,
	deadPigletsEvents: IDeadPigletsEvent[],
) {
	if (sow && sow.id) {
		let sowboardTableModel = SowBoardTableModel.fromJS({
			pregnancyTableModel: [],
			treatmentTableModel: [],
			animalNumber: sow.animalNumber,
			comment: sow.comments,
			race: sow.race,
			teats: sow.teats,
			location: getLocationString(sow),
			idNr: sow.idNumber,
			butcherQuarantine: findRetentionTime(sow.id!),
			batch: findBatch(sow.id!),
			index: sow.breedIndex,
		});
		const animalPregnancies = pregEvents[sow.id];
		const sortedPregnancies = sortPregnanciesByDate(animalPregnancies);
		const sowDatas = generateSowCardData(sow, sortedPregnancies, validationSetup, deadPigletsEvents);
		if (sowDatas.length > 0) {
			const lastRow = sowDatas[sowDatas.length - 1];
			if (!lastRow.hasFarrowing && lastRow.farrowingDate) {
				const date = lastRow.farrowingDate.split('.');
				sowboardTableModel.farrowingDateShort = date[0] + '.' + date[1];
			}
		}
		if (sowDatas.length > 0) {
			let nursDays = calculateSowCardAvg('nursingDays', sowDatas);
			let avg = PregnancyTableModel.fromJS({
				litter: 'Avg.',
				pregnancyDays: calculateSowCardAvg('pregnancyDays', sowDatas),
				numAlive: calculateSowCardAvg('numAlive', sowDatas),
				numDead: calculateSowCardAvg('numDead', sowDatas),
				nursingDays: isNaN(nursDays) ? '' : nursDays.toString(),
				pcs: calculateSowCardAvg('pcs', sowDatas),
				weight: calculateSowCardWeightAvg('weight', sowDatas),
				wasteDays: calculateSowCardAvg('wasteDays', sowDatas),
				pigSow: getPigYearSow(sow, animalPregnancies, sowDatas),
			} as IPregnancyTableModel);
			sowDatas.push(avg);
			sowboardTableModel.pregnancyTableModel = sowDatas;
		}

		let treatmentsforAnimal = generateTreatmentData(sow);
		let treatment = new TreatmentTableModel();
		for (let index = 0; index < treatmentsforAnimal.length; index++) {
			const element = treatmentsforAnimal[index];
			if (index % 2 === 0) {
				treatment = TreatmentTableModel.fromJS({
					firstTreatment: TreatmentModel.fromJS({
						litter: element.litter!.toString(),
						diagnose: element.diagnoseName,
						dateFirstTreatment: element.treatmentDate,
					}),
				} as ITreatmentTableModel);
				if (index + 1 === treatmentsforAnimal.length) {
					sowboardTableModel.treatmentTableModel!.push(treatment as TreatmentTableModel);
				}
			} else {
				treatment.secondTreatment = TreatmentModel.fromJS({
					litter: element.litter!.toString(),
					diagnose: element.diagnoseName,
					dateFirstTreatment: element.treatmentDate,
				});
				sowboardTableModel.treatmentTableModel!.push(treatment as TreatmentTableModel);
			}
		}
		sowcardforanimals.push(sowboardTableModel);
	}
}
