import memoize from 'memoize-one';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ISlaughterSiteDataDto } from 'shared/api/api';
import PrinterGreyIcon from 'shared/assets/src-assets/png/printer_ikon_grey.png';
import SlaughterDataCompareGreyIcon from 'shared/assets/src-assets/png/slaughterDatasCompare.png';
import { AddDaysToDate } from 'shared/helpers/date-helpers';
import { setTwoDecimalsAsNumber } from 'shared/helpers/general-helpers';
import { generateSummaryDataForOverviewTable } from 'shared/helpers/slaughter-data-helper/slaughter-data-helper';
import { showSpinnerByActionTypes } from 'shared/state/ducks/general-settings/selectors';
import {
	GetSyncSlaughterHouseDatas,
	PrintSlaughterDataOverviewTableData,
} from 'shared/state/ducks/slaughter-data/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { SkioldTableGrid as SkioldTableRef } from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { SkioldFetch } from '../skiold-components/skiold-fetch/skiold-fetch';
import { SkioldModal } from '../skiold-components/skiold-modal/skiold-modal';
import { Heading } from '../utils/heading';
import { SkioldIconSpinner } from '../utils/skiold-icon-spinner';
import { CheckSlaughterDataWithDepartureData } from './check-slaughter-data-with-departure-data/check-slaughter-data-with-departure-data';
import { EditSlaughterDataLocation } from './edit-slaughter-pig-data/edit-slaughter-data-location';
import { SlaughterPigsDataTable } from './slaughter-pigs-data-table';
import './slaughter-pigs-overview.scss';
import { SlaughterDataRowModal } from './slaughter-pigs-row-modal/slaughter-pigs-data-row-modal';
import { SlaughterSummaryComponent } from './slaughter-summary-component';
const spinnerActionsTypes = ['getSlaughterData'];
export const SlaughterPigsDataOverview: FC = React.memo(() => {
	const dispatch = useDispatch();
	const [dates, setDates] = useState({
		fromDate: AddDaysToDate(new Date(), -30),
		toDate: new Date(),
		tmpFromDate: AddDaysToDate(new Date(), -30),
		tmpToDate: new Date(),
	});
	const showSpinner = useSelector(showSpinnerByActionTypes(spinnerActionsTypes));

	const [SummaryState, summaryStateChanged] = useState<ISlaughterSiteDataDto[]>([]);
	const [modalState, modalStateChanged] = useState<{
		modalOpen: boolean;
		item: ISlaughterSiteDataDto | undefined;
		width: '400px' | '1100px' | '1200px';
		mode: 'rowClicked' | 'editClicked' | 'checkSlaughterDataWithDepartureData' | undefined;
	}>({ item: undefined, mode: undefined, modalOpen: false, width: '400px' });
	const site = useSelector((state: WebAppState) => state.site.site);
	const onFromDateChanged = (date: Date) => {
		setDates({ ...dates, tmpFromDate: date });
	};
	const onToDateChanged = (date: Date) => {
		setDates({ ...dates, tmpToDate: date });
	};
	const fetchData = (fromDate: Date, toDate: Date) => {
		setDates({ ...dates, fromDate, toDate });
		GetSyncSlaughterHouseDatas(site.id, fromDate, toDate)(dispatch);
	};
	useEffect(() => {
		GetSyncSlaughterHouseDatas(site.id, dates.fromDate, dates.toDate)(dispatch);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, site]);
	const onDataChanged = (data: ISlaughterSiteDataDto[]) => {
		summaryStateChanged(data);
	};
	const onEditClicked = (item: ISlaughterSiteDataDto) => {
		modalStateChanged({ item, mode: 'editClicked', modalOpen: true, width: '400px' });
	};
	const onRowClicked = (item: ISlaughterSiteDataDto) => {
		modalStateChanged({ item, mode: 'rowClicked', modalOpen: true, width: '1200px' });
	};
	const onCheckSlaughterDataWithDepartureDataClicked = () => {
		modalStateChanged({
			item: undefined,
			mode: 'checkSlaughterDataWithDepartureData',
			modalOpen: true,
			width: '1100px',
		});
	};
	const onCloseModal = () => {
		modalStateChanged({ item: undefined, mode: undefined, modalOpen: false, width: '400px' });
	};
	const tableRef = useState<SkioldTableRef | undefined>(undefined);
	const setRef = (ref: SkioldTableRef | undefined) => {
		tableRef[1](ref);
	};

	return (
		<ViewWeb className="slaughterPigsDataOverview">
			<Heading text={localized('finisherData')}></Heading>
			<ViewWeb className="flexDirectionRow justifyContentSpaceBetween">
				<SkioldFetch
					isControlled={true}
					fetchData={fetchData}
					toDateChange={onToDateChanged}
					fromDateChange={onFromDateChanged}
					fromDate={dates.tmpFromDate}
					toDate={dates.tmpToDate}
					showSpinner={showSpinner}
				/>
				<ViewWeb className="flexDirectionRow">
					<SlaughterSummaryComponent items={SummaryState} />
					<SkioldIconSpinner
						containerClassName="check-data-icon-button"
						title={localized('checkSlaughterData')}
						icon={SlaughterDataCompareGreyIcon}
						onPress={onCheckSlaughterDataWithDepartureDataClicked}
					/>
					<SkioldIconSpinner
						itemFromParent={tableRef[0]}
						title={localized('PrintList')}
						icon={PrinterGreyIcon}
						onPress={GetSlaughterPigsOverviewData()}
					/>
				</ViewWeb>
			</ViewWeb>
			<ViewWeb className="table-container">
				<SlaughterPigsDataTable
					setRef={setRef}
					onRowClicked={onRowClicked}
					onEdit={onEditClicked}
					onDataChanged={onDataChanged}
				/>
			</ViewWeb>
			<SkioldModal
				minWidth={modalState.width}
				shouldCloseOnOverlayClick={true}
				isOpen={modalState.modalOpen}
				close={onCloseModal}
			>
				{modalState.mode === 'editClicked' && (
					<EditSlaughterDataLocation item={modalState.item!} closeModal={onCloseModal} />
				)}
				{modalState.mode === 'rowClicked' && (
					<SlaughterDataRowModal item={modalState.item!} closeModal={onCloseModal} />
				)}
				{modalState.mode === 'checkSlaughterDataWithDepartureData' && (
					<CheckSlaughterDataWithDepartureData
						closeModal={onCloseModal}
						dateFrom={dates.tmpFromDate}
						dateTo={dates.tmpToDate}
					/>
				)}
			</SkioldModal>
		</ViewWeb>
	);
});

export const generateDataForOverviewTable = memoize((data: ISlaughterSiteDataDto[]) => {
	const tableData = generateSummaryDataForOverviewTable(data);
	return (
		<div className="summary-container">
			<div className="flexDirectionRowWithWidth">
				<div className="summaryTextStyle"> {localized('Count')}: </div>
				<div className="summaryTextStyle"> {tableData.amount} </div>
			</div>
			<div className="flexDirectionRowWithWidth">
				<div className="summaryTextStyle"> {localized('meatPercentage')}: </div>
				<div className="summaryTextStyle"> {setTwoDecimalsAsNumber(tableData.meat)} </div>
			</div>
			<div className="flexDirectionRowWithWidth">
				<div className="summaryTextStyle"> {localized('avgPrice')}: </div>
				<div className="summaryTextStyle"> {setTwoDecimalsAsNumber(tableData.price)} </div>
			</div>
			<div className="flexDirectionRowWithWidth">
				<div className="summaryTextStyle"> {localized('avgWeight')}: </div>
				<div className="summaryTextStyle"> {setTwoDecimalsAsNumber(tableData.weight)} </div>
			</div>
		</div>
	);
});

function GetSlaughterPigsOverviewData(): ((itemFromParent?: any) => void) | undefined {
	return async (tableRef: SkioldTableRef | undefined) => {
		if (tableRef) {
			PrintSlaughterDataOverviewTableData(tableRef.GetSortedData(), 'SlaughterHouseDataOverview.pdf');
		}
	};
}
