import {
	DistriwinComponentType,
	IDistriwinFeedComponent,
	IFeeding,
	IFeedingCategory,
	IFeedingSubject,
	IProcessEquipment,
	IUserProfile,
} from 'shared/api/api';
import { GetFeedingName } from 'web/view/components/feeding/food-helper';

export class FeedingComponentToFeedingTableRow {
	// Component props
	public distriwinName?: string;
	public distriwinType?: DistriwinComponentType;
	public distriwinEnergy1?: number;
	public distriwinEnergy2?: number;
	public componentObjectId?: string;

	// Feeding subject props
	public skioldFeeding?: string;
	public skioldType?: string;
	public feedingId?: string;
	public noRelation?: boolean;
}

export const generateFeedingRelationTableRows = (
	feedings: IFeeding[],
	feedingSubjects: IFeedingSubject[],
	feedingComponents: IDistriwinFeedComponent[],
	user: IUserProfile | undefined,
	feedingCategories: IFeedingCategory[],
	processEquipment: IProcessEquipment | undefined,
) => {
	let data: FeedingComponentToFeedingTableRow[] = [];
	const keyValueFeeding = feedings.keyValuePairSingle('id');
	feedingComponents.forEach(component => {
		if (component.id && processEquipment && processEquipment.code === component.locationCode) {
			let element = {
				distriwinName: component.name,
				distriwinType: component.type,
				distriwinEnergy1: component.energy1,
				distriwinEnergy2: component.energy2,
				componentObjectId: component.id,
				noRelation: component.noRelation,
			} as FeedingComponentToFeedingTableRow;
			const feeding = component.feedingId ? keyValueFeeding[component.feedingId] : undefined;
			if (feeding) {
				const feedingSubject = feedingSubjects.find(e => e.id === feeding.feedingSubjectId);
				if (feedingSubject) {
					const feedingCategory = feedingCategories.find(e => e.id === feedingSubject.feedingCategoryId);
					if (feedingCategory) {
						element.skioldFeeding = GetFeedingName(feedingSubject, user);
						element.skioldType = GetFeedingName(feedingCategory, user);
						element.feedingId = feeding.id;
					}
				}
			}

			data.push(element);
		}
	});
	return data;
};
