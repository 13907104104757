import memoize from 'memoize-one';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IStemAnimal } from 'shared/api/api';
import { getDateString } from 'shared/helpers/date-helpers';
import { calculateAgeInWeeks, exactNumberFilterMethodGrid } from 'shared/helpers/general-helpers';
import { getBuildingNameByPenId, getPenNameByPenId, getSectionNameByPenId } from 'shared/helpers/location-helper';
import { memoizeHashmapLocation } from 'shared/helpers/memoize-getters/memoize-getters';
import { NaturalSortDates } from 'shared/helpers/natural-sort';
import { RefType } from 'shared/helpers/ref-type';
import { selectNucleusManagementOrAssignIdAccess } from 'shared/state/ducks/site/reducer';
import { DeleteYoungAnimals } from 'shared/state/ducks/stem-animals/operations';
import { localized, localizedDynamic } from 'shared/state/i18n/i18n';
import styled from 'styled-components';
import { WebAppState } from 'web/state/store.web';
import { SkioldCheckbox } from 'web/view/components/skiold-components/skiold-checkbox/skiold-checkbox';
import SkioldTableGrid, {
	SkioldTableGrid as SkioldTableRef,
} from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { SowListConstants } from '../table-constants';
import './young-animals.scss';
import { idNumberFilter, printIdNumber } from '../../../../../../shared/helpers/stemanimal-helper/stemanimal-helper';

const OuterDiv = styled.div`
	margin: 16px 0;
`;

interface PropsFromParent {
	fromDate: Date | undefined;
	toDate: Date | undefined;
	dateFilterProperty: string;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		siteId: state.profile.active!.siteId,
		profile: state.profile.active!,
		stemAnimals: state.stemAnimals.entities, //for render purposes,
		hashmapLocations: memoizeHashmapLocation(
			state.locations.buildings,
			state.locations.sections,
			state.locations.pens,
		),
		generalSettings: state.generalSettings.entity,
		nucleusManagement: selectNucleusManagementOrAssignIdAccess(state),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
	return {
		deleteYoungAnimals: (ids: string[]) => DeleteYoungAnimals(ids)(dispatch),
	};
};

export interface State {
	loading: boolean;
	columns: any[];
	columnExte: any[];
	checked: string[];
	youngAnimals: IStemAnimal[];
	commitAll: boolean;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;
export class YoungAnimals extends React.PureComponent<Props, State> {
	private generateData = memoize(
		(
			dateFilterProperty: string,
			fromDate: Date | undefined,
			toDate: Date | undefined,
			stemAnimals: IStemAnimal[],
		) => {
			let youngAnimalDateProperty =
				dateFilterProperty === 'entranceDate' ? 'youngAnimalEntranceDate' : dateFilterProperty;
			this.getFilteredData(youngAnimalDateProperty, fromDate, toDate, stemAnimals);
		},
	);

	public SkioldTableRef: SkioldTableRef | undefined;
	constructor(props: Props) {
		super(props);

		this.state = {
			columns: this.generateColumns(),
			loading: false,
			columnExte: this.generateColumnsExtensions(),
			checked: [],
			youngAnimals: [],
			commitAll: false,
		};
	}

	public componentDidMount() {
		this.generateData(
			this.props.dateFilterProperty,
			this.props.fromDate,
			this.props.toDate,
			this.props.stemAnimals,
		);
	}

	public componentDidUpdate(prevProps: Props, prevState: State) {
		if (prevState.checked.length !== this.state.checked.length) {
			this.setState({ youngAnimals: [...this.state.youngAnimals] });
		} else {
			this.generateData(
				this.props.dateFilterProperty,
				this.props.fromDate,
				this.props.toDate,
				this.props.stemAnimals,
			);
		}
	}

	public generateColumnsExtensions() {
		let columnExt = [] as any;
		columnExt.push({
			columnName: 'idNumber',
			width: SowListConstants.iDNumberWidth,
		});
		columnExt.push({
			columnName: 'age',
			width: SowListConstants.ageWidth,
		});
		columnExt.push({
			columnName: 'gender',
			width: 100,
		});
		columnExt.push({
			columnName: 'youngAnimalEntranceDate',
			width: SowListConstants.entranceDateWidth,
		});
		columnExt.push({
			columnName: 'entranceType',
			width: SowListConstants.entranceTypeWidth,
		});
		columnExt.push({
			columnName: 'building',
			width: SowListConstants.animalNrWidth,
		});
		columnExt.push({
			columnName: 'section',
			width: SowListConstants.animalNrWidth,
		});
		columnExt.push({
			columnName: 'pen',
			width: SowListConstants.animalNrWidth,
		});
		columnExt.push({
			columnName: 'transponder',
			width: SowListConstants.transponderWidth,
		});
		columnExt.push({
			columnName: 'birthDate',
			width: SowListConstants.birthdayWidth,
		});
		columnExt.push({
			columnName: 'race',
			width: SowListConstants.raceWidth,
		});
		if (this.props.nucleusManagement && this.props.generalSettings.showRaceLine) {
			columnExt.push({
				columnName: 'raceLine',
				width: 100,
			});
		}
		columnExt.push({
			columnName: 'index',
			width: SowListConstants.indexWidth,
		});
		columnExt.push({
			columnName: 'indexDate',
			width: SowListConstants.indexDateWidth,
		});

		if (this.props.nucleusManagement) {
			columnExt.push({
				columnName: 'breedingNumber',
				width: SowListConstants.breedingNumber,
			});
			columnExt.push({
				columnName: 'usageCode',
				width: SowListConstants.breedingNumber,
			});
		}

		if (this.props.generalSettings.stemAnimalShowMomDadData) {
			columnExt.push(
				{
					columnName: 'dadAnimalNumber',
					width: SowListConstants.dadNrWidth,
				},
				{
					columnName: 'dadRace',
					width: 100,
				},
				{
					columnName: 'dadIdNumber',
					width: SowListConstants.dadIdWidth,
				},

				{
					columnName: 'momAnimalNumber',
					width: SowListConstants.momNrWidth,
				},
				{
					columnName: 'momRace',
					width: 100,
				},
				{
					columnName: 'momIdNumber',
					width: SowListConstants.momIdWidth,
				},
			);
		}
		columnExt.push({
			columnName: 'checked',
			filteringEnabled: false,
			sortingEnabled: false,
			resizingEnabled: false,
			width: SowListConstants.indexWidth,
		});
		return columnExt;
	}
	public GetSortedData() {
		if (this.SkioldTableRef !== undefined) {
			return this.SkioldTableRef.currentData;
		}
		return [];
	}

	public render() {
		return (
			<ViewWeb className="young-animals">
				<OuterDiv>
					<SkioldTableGrid
						tableKey={'youngAnimals'}
						columns={this.state.columns}
						ref={this.setRef}
						data={this.state.youngAnimals}
						ColumnExtensions={this.state.columnExte}
						sortHeaderId={[{ columnName: 'idNumber', direction: 'asc' }]}
						showPagination={true}
					/>
				</OuterDiv>
			</ViewWeb>
		);
	}

	private setRef = (m: any) => {
		if (m) {
			this.SkioldTableRef = m;
		}
	};

	private getFilteredData = (
		dateFilterProperty: string,
		fromDate: Date | undefined,
		toDate: Date | undefined,
		stemAnimals: IStemAnimal[],
	) => {
		let youngAnimals = stemAnimals.filter(animal => animal.animalNumber == null);
		if (!dateFilterProperty || !fromDate || !toDate) {
			this.setState({ youngAnimals: youngAnimals });
			return;
		}
		youngAnimals = youngAnimals.filter(youngAnimal => {
			const date = youngAnimal[dateFilterProperty as keyof IStemAnimal];
			let res = date && date > fromDate! && date < toDate!;
			return res;
		});
		this.setState({ youngAnimals });
	};

	private generateColumns() {
		const columns = [] as any;
		columns.push({
			name: 'idNumber',
			title: localized('idNumber'),
			isFixedLeft: true,
			getCellValue: (d: IStemAnimal) => printIdNumber(d.idNumber),
			filterFunction: idNumberFilter,
		});
		columns.push({
			name: 'age',
			title: localized('age'),
			getCellValue: (d: IStemAnimal) => (d.birthDate ? calculateAgeInWeeks(d.birthDate) : ''),
		});
		columns.push({
			name: 'gender',
			title: localized('Gender'),
			getCellValue: (d: IStemAnimal) => d.gender && localizedDynamic(d.gender),
		});
		columns.push({
			name: 'youngAnimalEntranceDate',
			title: localized('entranceDate'),
			getCellValue: (d: IStemAnimal) => getDateString(d.youngAnimalEntranceDate),
			sortFunction: NaturalSortDates,
		});
		columns.push({
			name: 'entranceType',
			title: localized('entranceType'),
			getCellValue: (d: IStemAnimal) => localizedDynamic(d.entranceType!),
		});
		columns.push({
			name: 'building',
			title: localized('building'),
			getCellValue: this.getBuildingName,
		});
		columns.push({
			name: 'section',
			title: localized('section'),
			getCellValue: this.getSectionName,
			filterFunction: exactNumberFilterMethodGrid,
		});
		columns.push({
			name: 'pen',
			title: localized('pen'),
			getCellValue: this.getPenName,
		});
		columns.push({
			name: 'transponder',
			title: localized('transponder'),
			getCellValue: (d: IStemAnimal) => d.transponder,
		});
		columns.push({
			name: 'birthDate',
			title: localized('birthdate'),
			getCellValue: (d: IStemAnimal) => getDateString(d.birthDate),
			sortFunction: NaturalSortDates,
		});
		columns.push({
			name: 'race',
			title: localized('Breed'),
			getCellValue: (d: IStemAnimal) => d.race,
		});
		if (this.props.generalSettings.showRaceLine) {
			columns.push({
				name: 'raceLine',
				title: localized('RaceLine'),
			});
		}
		columns.push({
			name: 'index',
			title: localized('index'),
			getCellValue: (d: IStemAnimal) => d.breedIndex,
		});
		columns.push({
			name: 'indexDate',
			title: localized('indexDate'),
			getCellValue: (d: IStemAnimal) => getDateString(d.breedIndexDate),
			sortFunction: NaturalSortDates,
		});

		if (this.props.nucleusManagement) {
			columns.push({
				name: 'breedingNumber',
				title: localized('BreedingNr'),
				getCellValue: (d: IStemAnimal) => d.breedingNumber,
			});
			columns.push({
				name: 'usageCode',
				title: localized('UsageCode'),
				getCellValue: (d: IStemAnimal) => d.usageCode,
			});
		}
		if (this.props.generalSettings.stemAnimalShowMomDadData) {
			columns.push(
				{
					name: 'dadAnimalNumber',
					title: localized('dadNr'),
					getCellValue: (d: IStemAnimal) => d.dadAnimalNumber,
				},
				{
					name: 'dadRace',
					title: localized('dadRace'),
					getCellValue: (d: IStemAnimal) => d.dadRace,
				},
				{
					name: 'dadIdNumber',
					title: localized('dadID'),
					getCellValue: (d: IStemAnimal) => printIdNumber(d.dadIdNumber),
				},

				{
					name: 'momAnimalNumber',
					title: localized('momNr'),
					getCellValue: (d: IStemAnimal) => d.momAnimalNumber,
				},
				{
					name: 'momRace',
					title: localized('momRace'),
					getCellValue: (d: IStemAnimal) => d.momRace,
				},
				{
					name: 'momIdNumber',
					title: localized('momID'),
					getCellValue: (d: IStemAnimal) => printIdNumber(d.momIdNumber),
				},
			);
		}
		columns.push({
			name: 'checked',
			title: ' ',
			getCellValue: this.getCheckedCell,
			sortable: false,
			filter: this.generateCommitAllCheckBox,
			isFixedRight: true,
		});
		return columns;
	}

	public generateCommitAllCheckBox = () => {
		return (
			<ViewWeb className="checkbox-view-filter-style">
				<SkioldCheckbox
					isChecked={this.state.commitAll}
					className="checkbox-style-filter"
					onClick={this.commitAllCheckBoxClicked}
				/>
			</ViewWeb>
		);
	};

	private commitAllCheckBoxClicked = () => {
		const isChecked = !this.state.commitAll;
		let checkedData: string[] = [];
		if (this.SkioldTableRef && isChecked) {
			const data = this.SkioldTableRef.GetSortedData() as IStemAnimal[];

			if (data) {
				checkedData = data.map(a => a.id!);
			}
		}
		this.setState({ checked: checkedData, commitAll: isChecked });
	};

	private getBuildingName = (event: IStemAnimal) => {
		return getBuildingNameByPenId(event.entrancePenId, this.props.hashmapLocations);
	};

	private getSectionName = (event: IStemAnimal) => {
		return getSectionNameByPenId(event.entrancePenId, this.props.hashmapLocations);
	};

	private getPenName = (event: IStemAnimal) => {
		return getPenNameByPenId(event.entrancePenId, this.props.hashmapLocations);
	};

	private getCheckedCell = (d: IStemAnimal) => {
		return <SkioldCheckbox isChecked={this.isChecked(d.id!)} onClick={this.toggle} itemFromParent={d.id} />;
	};

	private toggle = async (checked: boolean, id: string) => {
		if (checked) {
			this.setState({ checked: [...this.state.checked, id!] });
		} else {
			this.setState({ checked: this.state.checked.filter(item => item !== id) });
		}
	};

	private isChecked = (id: string) => {
		return this.state.checked.includes(id);
	};

	public deleteMarked = () => {
		this.props.deleteYoungAnimals(this.state.checked);
	};

	public getMarked = () => {
		return this.state.youngAnimals.filter(ya => this.state.checked.includes(ya.id!));
	};
}

export default connect<ReturnType<typeof mapStateToProps>, ReturnType<typeof mapDispatchToProps>, RefType, WebAppState>(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{ forwardRef: true },
)(YoungAnimals);
