import Styles from 'native/view/styles';
import React, { FC, useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { AnimalType, Equipment, IProcessEquipment } from 'shared/api/api';
import { getDateString } from 'shared/helpers/date-helpers';
import { localized } from 'shared/state/i18n/i18n';
import PageContainer from '../../page-container/page-container';
import { SkioldFormDropdown } from '../../skiold-components/skiold-dropdown/skiold-form-dropdown';
import { WhiteText } from '../../Text/white-text';
import { Heading } from '../../utils/heading';
import { ViewWeb } from '../../utils/web-view';
import { Option } from 'react-dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { WebAppState } from 'web/state/store.web';
import { SkioldFetchIcon } from '../../utils/skiold-fetch-icon';
import {
	GetDistriwinFeedingComponentSyncData,
	SetFeedingToComponentRelation,
} from 'shared/state/ducks/distriwin-feed-component/operations';
import { useCreateFeedingRelation } from './create-distriwin-feeding-relation';
import { SkioldButton } from '../../skiold-components/skiold-button/skiold-button';
import { ClipLoader } from 'react-spinners';

interface PropsFromParent {}

export const CreateDistriwinFeedingRelationButtons: FC<PropsFromParent> = React.memo(() => {
	const dispatch = useDispatch();
	const { data, closeModal } = useCreateFeedingRelation();

	const saveFeedingRelation = () => {
		const keyValue = {};
		data.forEach(e => {
			if (e.componentObjectId) {
				keyValue[e.componentObjectId] = e.feedingId ? e.feedingId : e.noRelation ? 'norelation' : '';
			}
		});
		SetFeedingToComponentRelation(keyValue)(dispatch);
		closeModal();
	};

	return (
		<ViewWeb className="buttons-container">
			<SkioldButton title={localized('Close')} onPress={closeModal} theme="grey" />

			<SkioldButton title={localized('Save')} onPress={saveFeedingRelation} />
			{/* {this.state.showSpinner && (
				<ViewWeb className="is-saving-spinner">
					<ClipLoader color="#f2ac40" size={35} />
				</ViewWeb>
			)} */}
		</ViewWeb>
	);
});
