
import actionCreatorFactory from 'typescript-fsa';
import * as types from './types';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import { IAnimalReportSetting, SyncDataAnimalReportSetting } from 'shared/api/api';

const actionCreator = actionCreatorFactory(GenActionFactoryName('animalReportSettings'));

export const getAnimalReportSettingsBySiteId = actionCreator.async<
{siteId: string;  activeSiteId?: string},
    IAnimalReportSetting[],
    { code: number; message: string }
>(types.GET_ANIMAL_REPORT_SETTINGS_BY_SITE);

export const saveAnimalReportSetting = actionCreator.async<IAnimalReportSetting, string>(
    types.SAVE_ANIMAL_REPORT_SETTING
);
export const getSyncData = actionCreator.async<{ siteId: string; lastSyncDate: Date,  activeSiteId?: string }, SyncDataAnimalReportSetting>(
    types.SYNC_DATA_ANIMAL_REPORT_SETTINGS
);
