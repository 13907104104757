import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import * as actions from './actions';
import { WorkListSettingState } from './types';
import { siteChange } from '../profile/actions';
import { SharedAppState } from 'shared/state/store.shared';
import { SyncableInitialState } from 'shared/state/models/syncable';
import { WorkListType, WorkListSetting, IWorkListSetting } from 'shared/api/api';
import { mergeArrays, upsertValueInArray, removeValueFromArray } from 'shared/helpers/reducer-helpers';
import { NewWorkListSetting } from 'shared/helpers/new-work-list-setting-helper';
import { getSyncModelData } from '../sync/actions';

export const initialState: WorkListSettingState = {
	...SyncableInitialState,
};

const worklistSetttingReducer = (state: WorkListSettingState = initialState, action: Action): WorkListSettingState => {
	if (isType(action, actions.getSyncData.started)) {
		state = { ...state, syncInProgress: true };
	}

	if (isType(action, getSyncModelData.done)) {
		if (action.payload.params.siteId === action.payload.params.activeSiteId) {
			if (action.payload.result.workListSettings && action.payload.result.workListSettings.datas!.length) {
				let unorderedWorkListSettings = action.payload.result.workListSettings.datas!;
				let matingWorkListSetting = unorderedWorkListSettings.find(
					a => a.type === WorkListType.MatingListSetting,
				);
				let farrowingWorkListSetting = unorderedWorkListSettings.find(
					a => a.type === WorkListType.FarrowingListSetting,
				);
				let weaningWorkListSetting = unorderedWorkListSettings.find(
					a => a.type === WorkListType.WeaningListSetting,
				);
				let scanningWorkListSetting = unorderedWorkListSettings.find(
					a => a.type === WorkListType.ScanningListSetting,
				);
				let scanningWorkListSetting2 = unorderedWorkListSettings.find(
					a => a.type === WorkListType.ScanningListSetting2,
				);

				let vaccineListSetting1 = unorderedWorkListSettings.find(
					a => a.type === WorkListType.VaccineListSetting1,
				);
				let vaccineListSetting2 = unorderedWorkListSettings.find(
					a => a.type === WorkListType.VaccineListSetting2,
				);
				let vaccineListSetting3 = unorderedWorkListSettings.find(
					a => a.type === WorkListType.VaccineListSetting3,
				);
				let allAnimalsEsfListSetting = unorderedWorkListSettings.find(
					a => a.type === WorkListType.AllAnimalsEsfListSetting,
				);
				let ShortageEsfListSetting = unorderedWorkListSettings.find(
					a => a.type === WorkListType.ShortageEsfListSetting,
				);
				let tasksInFarrowingStableSetting = unorderedWorkListSettings.find(
					a => a.type === WorkListType.TasksInFarrowingStableSetting,
				);

				let tasksInFarrowingStableSetting2 = unorderedWorkListSettings.find(
					a => a.type === WorkListType.TasksInFarrowingStableSetting2,
				);
				let moveToPregnancyWorkListSetting = unorderedWorkListSettings.find(
					a => a.type === WorkListType.MoveToPregnancyLocation,
				);
				let moveToFarrowingWorkListSetting = unorderedWorkListSettings.find(
					a => a.type === WorkListType.MoveToFarrowingLocation,
				);
				let moveToMatingWorkListSetting = unorderedWorkListSettings.find(
					a => a.type === WorkListType.MoveToMatingLocation,
				);

				let moveFromPregToPregSetting = unorderedWorkListSettings.find(
					a => a.type === WorkListType.MoveFromPregToPregSetting,
				);

				let moveMatingToMatingSetting = unorderedWorkListSettings.find(
					a => a.type === WorkListType.MoveMatingToMatingSetting,
				);

				let departureWorkListSetting = unorderedWorkListSettings.find(
					a => a.type === WorkListType.DepartureWorkListSetting,
				);
				let LardScanningEventListSetting = unorderedWorkListSettings.find(
					a => a.type === WorkListType.LardScanningEventListSetting,
				);
				let LardScanningEventListSetting2 = unorderedWorkListSettings.find(
					a => a.type === WorkListType.LardScanningEventListSetting2,
				);
				let sowTaskSettings1 = unorderedWorkListSettings.find(a => a.type === WorkListType.SowTaskSettings1);
				let sowTaskSettings2 = unorderedWorkListSettings.find(a => a.type === WorkListType.SowTaskSettings2);
				let workListSettings: WorkListSetting[] = [];

				if (matingWorkListSetting !== undefined) {
					workListSettings.push(matingWorkListSetting);
				}

				if (farrowingWorkListSetting !== undefined) {
					workListSettings.push(farrowingWorkListSetting);
				}
				if (weaningWorkListSetting !== undefined) {
					workListSettings.push(weaningWorkListSetting);
				}

				if (tasksInFarrowingStableSetting !== undefined) {
					workListSettings.push(tasksInFarrowingStableSetting);
				}

				if (tasksInFarrowingStableSetting2 !== undefined) {
					workListSettings.push(tasksInFarrowingStableSetting2);
				}
				if (vaccineListSetting1 !== undefined) {
					workListSettings.push(vaccineListSetting1);
				}
				if (vaccineListSetting2 !== undefined) {
					workListSettings.push(vaccineListSetting2);
				}
				if (vaccineListSetting3 !== undefined) {
					workListSettings.push(vaccineListSetting3);
				}
				if (scanningWorkListSetting !== undefined) {
					workListSettings.push(scanningWorkListSetting);
				}
				if (moveToPregnancyWorkListSetting !== undefined) {
					workListSettings.push(moveToPregnancyWorkListSetting);
				}
				if (moveToFarrowingWorkListSetting !== undefined) {
					workListSettings.push(moveToFarrowingWorkListSetting);
				}
				if (scanningWorkListSetting2 !== undefined) {
					workListSettings.push(scanningWorkListSetting2);
				}
				if (moveToMatingWorkListSetting !== undefined) {
					workListSettings.push(moveToMatingWorkListSetting);
				}
				if (ShortageEsfListSetting) {
					workListSettings.push(ShortageEsfListSetting);
				}
				if (allAnimalsEsfListSetting) {
					workListSettings.push(allAnimalsEsfListSetting);
				}
				if (moveFromPregToPregSetting) {
					workListSettings.push(moveFromPregToPregSetting);
				}
				if (moveMatingToMatingSetting) {
					workListSettings.push(moveMatingToMatingSetting);
				}
				if (departureWorkListSetting) {
					workListSettings.push(departureWorkListSetting);
				}

				if (LardScanningEventListSetting) {
					workListSettings.push(LardScanningEventListSetting);
				}
				if (LardScanningEventListSetting2) {
					workListSettings.push(LardScanningEventListSetting2);
				}
				if (sowTaskSettings1 !== undefined) {
					workListSettings.push(sowTaskSettings1);
				}
				if (sowTaskSettings2 !== undefined) {
					workListSettings.push(sowTaskSettings2);
				}
				state = {
					...state,
					entities: mergeArrays(state.entities, workListSettings),
					lastSyncDate: action.payload.result.workListSettings.syncTime!,
				};
			}
			state = {
				...state,

				syncInProgress: false,
			};
			return state;
		} else {
			state = {
				...state,
				syncInProgress: false,
			};
		}
	}

	if (isType(action, actions.getSyncData.done)) {
		if (action.payload.params.siteId === action.payload.params.activeSiteId) {
			if (action.payload.result.datas!.length) {
				let unorderedWorkListSettings = action.payload.result.datas!;
				let matingWorkListSetting = unorderedWorkListSettings.find(
					a => a.type === WorkListType.MatingListSetting,
				);
				let farrowingWorkListSetting = unorderedWorkListSettings.find(
					a => a.type === WorkListType.FarrowingListSetting,
				);
				let weaningWorkListSetting = unorderedWorkListSettings.find(
					a => a.type === WorkListType.WeaningListSetting,
				);
				let scanningWorkListSetting = unorderedWorkListSettings.find(
					a => a.type === WorkListType.ScanningListSetting,
				);
				let scanningWorkListSetting2 = unorderedWorkListSettings.find(
					a => a.type === WorkListType.ScanningListSetting2,
				);

				let vaccineListSetting1 = unorderedWorkListSettings.find(
					a => a.type === WorkListType.VaccineListSetting1,
				);
				let vaccineListSetting2 = unorderedWorkListSettings.find(
					a => a.type === WorkListType.VaccineListSetting2,
				);
				let vaccineListSetting3 = unorderedWorkListSettings.find(
					a => a.type === WorkListType.VaccineListSetting3,
				);
				let allAnimalsEsfListSetting = unorderedWorkListSettings.find(
					a => a.type === WorkListType.AllAnimalsEsfListSetting,
				);
				let ShortageEsfListSetting = unorderedWorkListSettings.find(
					a => a.type === WorkListType.ShortageEsfListSetting,
				);
				let tasksInFarrowingStableSetting = unorderedWorkListSettings.find(
					a => a.type === WorkListType.TasksInFarrowingStableSetting,
				);

				let tasksInFarrowingStableSetting2 = unorderedWorkListSettings.find(
					a => a.type === WorkListType.TasksInFarrowingStableSetting2,
				);
				let moveToPregnancyWorkListSetting = unorderedWorkListSettings.find(
					a => a.type === WorkListType.MoveToPregnancyLocation,
				);
				let moveToFarrowingWorkListSetting = unorderedWorkListSettings.find(
					a => a.type === WorkListType.MoveToFarrowingLocation,
				);
				let moveToMatingWorkListSetting = unorderedWorkListSettings.find(
					a => a.type === WorkListType.MoveToMatingLocation,
				);

				let moveFromPregToPregSetting = unorderedWorkListSettings.find(
					a => a.type === WorkListType.MoveFromPregToPregSetting,
				);

				let moveMatingToMatingSetting = unorderedWorkListSettings.find(
					a => a.type === WorkListType.MoveMatingToMatingSetting,
				);

				let departureWorkListSetting = unorderedWorkListSettings.find(
					a => a.type === WorkListType.DepartureWorkListSetting,
				);
				let LardScanningEventListSetting = unorderedWorkListSettings.find(
					a => a.type === WorkListType.LardScanningEventListSetting,
				);
				let LardScanningEventListSetting2 = unorderedWorkListSettings.find(
					a => a.type === WorkListType.LardScanningEventListSetting2,
				);
				let sowTaskSettings1 = unorderedWorkListSettings.find(a => a.type === WorkListType.SowTaskSettings1);
				let sowTaskSettings2 = unorderedWorkListSettings.find(a => a.type === WorkListType.SowTaskSettings2);

				let workListSettings: WorkListSetting[] = [];

				if (matingWorkListSetting !== undefined) {
					workListSettings.push(matingWorkListSetting);
				}

				if (farrowingWorkListSetting !== undefined) {
					workListSettings.push(farrowingWorkListSetting);
				}
				if (weaningWorkListSetting !== undefined) {
					workListSettings.push(weaningWorkListSetting);
				}

				if (tasksInFarrowingStableSetting !== undefined) {
					workListSettings.push(tasksInFarrowingStableSetting);
				}

				if (tasksInFarrowingStableSetting2 !== undefined) {
					workListSettings.push(tasksInFarrowingStableSetting2);
				}
				if (vaccineListSetting1 !== undefined) {
					workListSettings.push(vaccineListSetting1);
				}
				if (vaccineListSetting2 !== undefined) {
					workListSettings.push(vaccineListSetting2);
				}
				if (vaccineListSetting3 !== undefined) {
					workListSettings.push(vaccineListSetting3);
				}
				if (scanningWorkListSetting !== undefined) {
					workListSettings.push(scanningWorkListSetting);
				}
				if (moveToPregnancyWorkListSetting !== undefined) {
					workListSettings.push(moveToPregnancyWorkListSetting);
				}
				if (moveToFarrowingWorkListSetting !== undefined) {
					workListSettings.push(moveToFarrowingWorkListSetting);
				}
				if (scanningWorkListSetting2 !== undefined) {
					workListSettings.push(scanningWorkListSetting2);
				}
				if (moveToMatingWorkListSetting !== undefined) {
					workListSettings.push(moveToMatingWorkListSetting);
				}
				if (ShortageEsfListSetting) {
					workListSettings.push(ShortageEsfListSetting);
				}
				if (allAnimalsEsfListSetting) {
					workListSettings.push(allAnimalsEsfListSetting);
				}
				if (moveFromPregToPregSetting) {
					workListSettings.push(moveFromPregToPregSetting);
				}
				if (moveMatingToMatingSetting) {
					workListSettings.push(moveMatingToMatingSetting);
				}
				if (departureWorkListSetting) {
					workListSettings.push(departureWorkListSetting);
				}

				if (LardScanningEventListSetting) {
					workListSettings.push(LardScanningEventListSetting);
				}
				if (LardScanningEventListSetting2) {
					workListSettings.push(LardScanningEventListSetting2);
				}
				if (sowTaskSettings1 !== undefined) {
					workListSettings.push(sowTaskSettings1);
				}
				if (sowTaskSettings2 !== undefined) {
					workListSettings.push(sowTaskSettings2);
				}

				state = {
					...state,
					entities: mergeArrays(state.entities, workListSettings),
				};
			}
			state = {
				...state,
				lastSyncDate: action.payload.result.syncTime!,
				syncInProgress: false,
			};
			return state;
		} else {
			state = {
				...state,
				syncInProgress: false,
			};
		}
	}

	if (isType(action, actions.getSyncData.failed)) {
		state = { ...state, syncInProgress: false };
	}

	if (isType(action, actions.saveWorkListSetting.started)) {
		if (action.payload) {
			state = {
				...state,
				updates: upsertValueInArray(state.updates, action.payload),
				entities: upsertValueInArray(state.entities, action.payload),
			};
		}
	}

	if (isType(action, actions.saveWorkListSetting.failed)) {
		state = {
			...state,
			updates: removeValueFromArray(state.updates, action.payload.params.id!),
			entities: removeValueFromArray(state.entities, action.payload.params.id!),
		};
		return state;
	}

	if (isType(action, actions.saveWorkListSetting.done)) {
		state = { ...state, updates: removeValueFromArray(state.updates, action.payload.result) };
		return state;
	}

	if (isType(action, siteChange.done)) {
		state = initialState;
	}

	//Ensure Date objects are in fact date and not strings - redux-persist serializes dates to string but don't deserialize to dates again
	if (action.type === 'persist/REHYDRATE') {
		let a = (action as any) as { payload: SharedAppState; key: string };

		if (a.key === 'root') {
			let entities = new Array<WorkListSetting>();
			let updates = new Array<WorkListSetting>();
			let lastSyncDate = new Date(-8640000000000000);

			if (a.payload && a.payload.workListSettings.entities) {
				entities = [];
				a.payload.workListSettings.entities.forEach((dt: IWorkListSetting) => {
					let ndt = NewWorkListSetting(dt);
					if (ndt) {
						ndt.init(dt);
						entities.push(ndt);
					}
				});
			}

			if (a.payload && a.payload.workListSettings && a.payload.workListSettings.updates) {
				updates = [];
				a.payload.workListSettings.updates.forEach((dt: IWorkListSetting) => {
					let ndt = NewWorkListSetting(dt);
					if (ndt) {
						ndt.init(dt);
						updates.push(ndt);
					}
				});
			}
			if (a.payload && a.payload.workListSettings && a.payload.workListSettings.lastSyncDate) {
				lastSyncDate = new Date(a.payload.workListSettings.lastSyncDate);
			}

			state = { ...state, entities, updates, lastSyncDate };
		}
	}

	if (isType(action, actions.setSaveFunction)) {
		state = { ...state, saveFunction: action.payload.saveFunction };
	}

	if (isType(action, actions.setDeleteFunction)) {
		state = { ...state, deleteFunction: action.payload.deleteFunction };
	}

	if (isType(action, actions.setPrintFunction)) {
		state = { ...state, printFunction: action.payload.printFunction };
	}

	return state;
};

export default worklistSetttingReducer;
