import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/nova/theme.css';
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { default as i18nProvider, registerLanguages } from 'shared/state/i18n/i18n';
import { RouteSwitcher } from 'web/state/ducks/navigation/switcher-navigation';
import { default as getStore } from 'web/state/store.web';
import { ViewWeb } from 'web/view/components/utils/web-view';
import './app.web.scss';
import { Routes } from './state/ducks/navigation/routes';
import AuthInitializer from './view/components/authorizations/auth-initializer';
import AuthorizedContent from './view/components/authorizations/authorized-content';
import ReauthorizeComponent from './view/components/authorizations/reauthorize';
import NavBar from './view/components/navbar/navbar';
import OfflineNotice from './view/components/sync/offline-notice';
import { SnackbarProvider } from 'notistack';
class App extends React.PureComponent {
	constructor(props: any) {
		super(props);
		registerLanguages();
	}

	public render() {
		return (
			<Provider store={getStore().store}>
				<I18nextProvider i18n={i18nProvider}>
					<PersistGate loading={null} persistor={getStore().persistor}>
						<OfflineNotice />
						<AuthInitializer>
							<SnackbarProvider maxSnack={4}>
								<AuthorizedContent>
									<ViewWeb>
										<NavBar routes={Routes} />
									</ViewWeb>
									<ReauthorizeComponent />
								</AuthorizedContent>
								<RouteSwitcher />
							</SnackbarProvider>
						</AuthInitializer>
					</PersistGate>
				</I18nextProvider>
			</Provider>
		);
	}
}

export default App;
