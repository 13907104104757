import { ThreeDRotationSharp } from '@material-ui/icons';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AnimalReportType, ISowBoardReportSetting, SowBoardReportSetting } from 'shared/api/api';
import { SaveAnimalReportSetting } from 'shared/state/ducks/animal-report-settings/operations';
import { localized, localizedDynamic } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldCheckbox } from 'web/view/components/skiold-components/skiold-checkbox/skiold-checkbox';
import { SkioldFormsWrapper } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';

interface PropsFromParent {}

const mapStateToProps = (state: WebAppState) => {
	return {
		sowBoardReportSetting: state.animalReportSettings.entities.find(
			animalReport => animalReport.type === AnimalReportType.SowBoardReportSetting,
		),
		siteId: state.profile.active!.siteId,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	saveActiveAnimalReportSetting: (activeAnimalReportSetting: ISowBoardReportSetting) =>
		SaveAnimalReportSetting(activeAnimalReportSetting)(dispatch),
});

export interface State {
	sowBoardReportSetting: ISowBoardReportSetting;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

export class SowBoardSettingSetup extends React.PureComponent<Props, State> {
	public static getDerivedStateFromProps(nextProps: Props, prevState: State): Partial<State> {
		if (
			nextProps.sowBoardReportSetting &&
			!nextProps.sowBoardReportSetting.siteId &&
			nextProps.sowBoardReportSetting.siteId
		) {
			return {
				sowBoardReportSetting: {
					...prevState.sowBoardReportSetting,
					...nextProps.sowBoardReportSetting,
				},
			};
		}
		return {};
	}
	constructor(props: Props) {
		super(props);
		this.state = {
			sowBoardReportSetting: this.props.sowBoardReportSetting
				? (this.props.sowBoardReportSetting as ISowBoardReportSetting)
				: SowBoardReportSetting.fromJS({
						type: AnimalReportType.SowBoardReportSetting,
						siteId: props.siteId,
						showMatingBatchInRightSide: true,
				  } as ISowBoardReportSetting),
		};
	}

	private ToggleSetting = (setting?: boolean) => !setting;

	public toggleShowMatingBatchInLeftSide = () => {
		const sowBoardReportSetting = { ...this.state.sowBoardReportSetting };
		sowBoardReportSetting.showFarrowingDateInRightSide = false;
		sowBoardReportSetting.showMatingBatchInRightSide = this.ToggleSetting(
			this.state.sowBoardReportSetting.showMatingBatchInRightSide,
		);

		this.setState({ sowBoardReportSetting });
	};

	public toggleShowFarrowingDateInRightSide = () => {
		const sowBoardReportSetting = { ...this.state.sowBoardReportSetting };

		sowBoardReportSetting.showMatingBatchInRightSide = false;
		sowBoardReportSetting.showFarrowingDateInRightSide = this.ToggleSetting(
			this.state.sowBoardReportSetting.showFarrowingDateInRightSide,
		);
		this.setState({ sowBoardReportSetting });
	};

	public toggleShowRepeatAnimalNumberAtBottom = () => {
		const sowBoardReportSetting = { ...this.state.sowBoardReportSetting };
		sowBoardReportSetting.repeatAnimalNumberAtBottom = this.ToggleSetting(
			this.state.sowBoardReportSetting.repeatAnimalNumberAtBottom,
		);
		this.setState({ sowBoardReportSetting });
	};

	public toggleShowComment = () => {
		const sowBoardReportSetting = { ...this.state.sowBoardReportSetting };
		sowBoardReportSetting.showComment = this.ToggleSetting(this.state.sowBoardReportSetting.showComment);
		this.setState({ sowBoardReportSetting });
	};

	public activeAnimalReportSetupChanged(setup: ISowBoardReportSetting) {
		this.setState({
			sowBoardReportSetting: setup,
		});
	}
	public render() {
		return (
			<ViewWeb>
				<ViewWeb className="active-animals-setup">
					<TextWeb className="setup-text-label">{localized('Setup')}</TextWeb>
					<SkioldFormsWrapper
						formRows={this.getFormRows()}
						containerClassName="setup-forms-wrappoer-container"
					/>

					{this.renderButtons()}
				</ViewWeb>
			</ViewWeb>
		);
	}

	private save = async () => {
		this.props.saveActiveAnimalReportSetting(this.state.sowBoardReportSetting);
	};

	private getFormRows() {
		let formRows = new Array<FormRow>();
		formRows.push(this.getBuildingHeader());
		formRows.push({
			name: localizedDynamic('ShowMatingBatchInLeftSide'),
			component: (
				<SkioldCheckbox
					onClick={this.toggleShowMatingBatchInLeftSide}
					isChecked={this.state.sowBoardReportSetting.showMatingBatchInRightSide!}
				/>
			),
		});
		formRows.push({
			name: localizedDynamic('ShowFarrowingDateInRightSide'),
			component: (
				<SkioldCheckbox
					onClick={this.toggleShowFarrowingDateInRightSide}
					isChecked={this.state.sowBoardReportSetting.showFarrowingDateInRightSide!}
				/>
			),
		});
		formRows.push({
			name: localizedDynamic('RepeatAnimalNumberAtBottom'),
			component: (
				<SkioldCheckbox
					onClick={this.toggleShowRepeatAnimalNumberAtBottom}
					isChecked={this.state.sowBoardReportSetting.repeatAnimalNumberAtBottom!}
				/>
			),
		});
		formRows.push({
			name: localizedDynamic('ShowComment'),
			component: (
				<SkioldCheckbox
					onClick={this.toggleShowComment}
					isChecked={this.state.sowBoardReportSetting.showComment!}
				/>
			),
		});
		return formRows;
	}

	private getBuildingHeader(): FormRow {
		return {
			header: localized(AnimalReportType.SowBoardReportSetting),
		};
	}

	private renderButtons() {
		return (
			<ViewWeb className="setup-button-container">
				<SkioldButton title={localized('Save')} onPress={this.save} />
			</ViewWeb>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SowBoardSettingSetup);
