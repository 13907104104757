import React from 'react';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import styled from 'styled-components';
import PenIcon from 'shared/assets/src-assets/png/pen_icon.png';
import { Option } from 'react-dropdown';

import { WebAppState } from 'web/state/store.web';
import { GetDeparturedAnimals } from 'shared/state/ducks/stem-animals/operations';
import { GetDeparturedMoveEvents } from 'shared/state/ducks/move-events/operations';
import { AnimalKind, IStemAnimal } from 'shared/api/api';
import { localized, localizedDynamic } from 'shared/state/i18n/i18n';
import { SowListConstants } from '../table-constants';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { SkioldModal } from 'web/view/components/skiold-components/skiold-modal/skiold-modal';
import { getDepartedBoars } from '../../stem-animal-input/stem-animal-input-helper';
import { SkioldImage } from 'web/view/components/utils/svg/skiold-image';
import { getDateString } from 'shared/helpers/date-helpers';
import { NaturalSortDates } from 'shared/helpers/natural-sort';
import SowEvents from 'web/view/pages/sow-events/sow-events';
import SkioldTableGrid, {
	SkioldTableGrid as SkioldTableRef,
} from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import './boars.scss';
import { SkioldTouchableOpacity } from 'web/view/components/skiold-components/skiold-touchable-opacity';
import { Sorting } from '@devexpress/dx-react-grid';
import { calculateAnimalKind, getBoarType, idNumberFilter, printIdNumber } from 'shared/helpers/stemanimal-helper/stemanimal-helper';
import { selectNucleusManagementOrAssignIdAccess } from 'shared/state/ducks/site/reducer';
import { hasNucleusFeatureCEFN } from 'shared/helpers/nucleus-management-helper/nucleus-management-helper';

const OuterDiv = styled.div`
	margin: 16px 0;
`;

interface PropsFromParent {
	fromDate: Date | undefined;
	toDate: Date | undefined;
	dateFilterProperty: string;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		siteId: state.profile.active!.siteId,
		profile: state.profile.active!,
		stemAnimals: state.stemAnimals.entities, //for render purposes
		departuredStemAnimals: state.stemAnimals.departuredAnimals, //for render purposes
		generalSettings: state.generalSettings.entity,
		nucleusManagement: selectNucleusManagementOrAssignIdAccess(state),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
	return {
		getDeparturedAnimals: () => GetDeparturedAnimals()(dispatch),
		getDeparturedMoveEvents: () => GetDeparturedMoveEvents()(dispatch),
	};
};

export interface State {
	events: Array<{ value: string }>;
	selected: string;
	editModalOpen: boolean;
	stemAnimalToEdit: IStemAnimal;
	loading: boolean;
	columns: any[];
	columnExte: any[];

	EventOption: Option;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;
export class DepartedBoars extends React.PureComponent<Props, State> {
	public SkioldTableRef: SkioldTableRef | undefined;
	constructor(props: Props) {
		super(props);

		this.state = {
			events: [],
			selected: '',
			editModalOpen: false,
			stemAnimalToEdit: {} as IStemAnimal,
			columns: this.generateColumns(),
			loading: false,
			columnExte: this.generateColumnsExtensions(),
			EventOption: { label: localized('departure'), value: 'departure' },
		};
	}

	public componentDidMount() {
		this.props.getDeparturedAnimals();
		this.props.getDeparturedMoveEvents();
	}

	public generateColumnsExtensions() {
		let columnExt = [] as any;
		columnExt.push({
			columnName: 'edit',
			sortingEnabled: false,
			filteringEnabled: false,
			resizingEnabled: false,
			width: SowListConstants.iconWidth,
		});
		columnExt.push({
			columnName: 'animalNumber',
			width: SowListConstants.animalNrWidth,
		});
		columnExt.push({
			columnName: 'kind',
			width: SowListConstants.kindWidth,
		});
		columnExt.push({
			columnName: 'entranceDate',
			width: SowListConstants.entranceDateWidth,
		});
		columnExt.push({
			columnName: 'entranceType',
			width: SowListConstants.entranceTypeWidth,
		});
		columnExt.push({
			columnName: 'transponder',
			width: SowListConstants.transponderWidth,
		});
		columnExt.push({
			columnName: 'departureDate',
			width: SowListConstants.departureDateWidth,
		});
		columnExt.push({
			columnName: 'departure',
			width: SowListConstants.departureWidth,
		});
		columnExt.push({
			columnName: 'idNumber',
			width: SowListConstants.iDNumberWidth,
		});
		columnExt.push({
			columnName: 'birthdate',
			width: SowListConstants.birthdayWidth,
		});
		columnExt.push({
			columnName: 'race',
			width: SowListConstants.raceWidth,
		});
		if (this.props.nucleusManagement && this.props.generalSettings.showRaceLine) {
			columnExt.push({
				columnName: 'raceLine',
				width: 100,
			});
		}
		columnExt.push({
			columnName: 'index',
			width: SowListConstants.indexWidth,
		});
		columnExt.push({
			columnName: 'indexDate',
			width: SowListConstants.indexDateWidth,
		});

		if (this.props.nucleusManagement) {
			columnExt.push({
				columnName: 'breedingNumber',
				width: SowListConstants.breedingNumber,
			});
		}
		if (hasNucleusFeatureCEFN(this.props.nucleusManagement)) {
			columnExt.push({
				columnName: 'earNotch',
				width: SowListConstants.breedingNumber,
			});
		}
		if (this.props.generalSettings.stemAnimalShowMomDadData) {
			columnExt.push(
				{
					columnName: 'dadAnimalNumber',
					width: SowListConstants.dadNrWidth,
				},
				{
					columnName: 'dadRace',
					width: 100,
				},
				{
					columnName: 'dadIdNumber',
					width: SowListConstants.dadIdWidth,
				},

				{
					columnName: 'momAnimalNumber',
					width: SowListConstants.momNrWidth,
				},
				{
					columnName: 'momRace',
					width: 100,
				},
				{
					columnName: 'momIdNumber',
					width: SowListConstants.momIdWidth,
				},
			);
		}
		return columnExt;
	}

	public render() {
		return (
			<ViewWeb className="boars">
				<OuterDiv>
					<SkioldTableGrid
						tableKey={'departedboars'}
						columns={this.state.columns}
						ref={this.setRef}
						data={this.getFilteredData()}
						ColumnExtensions={this.state.columnExte}
						sortHeaderId={this.defaultSorting}
						showPagination={true}
					/>
				</OuterDiv>
				<SkioldModal padding="0" isOpen={this.state.editModalOpen} close={this.closeModal}>
					<SowEvents
						stemAnimalId={this.state.stemAnimalToEdit.id}
						closeEditModal={this.closeModal}
						theme="dark"
						setEventOption={this.state.EventOption}
					/>
				</SkioldModal>
			</ViewWeb>
		);
	}

	private setRef = (m: any) => {
		if (m) {
			this.SkioldTableRef = m;
		}
	};
	private defaultSorting: Sorting[] = [{ columnName: 'animalNumber', direction: 'asc' }];
	private getFilteredData() {
		let boars = getDepartedBoars();

		boars = boars.filter(boar => boar.entranceDate); // Filter out KS boars

		if (!this.props.dateFilterProperty || !this.props.fromDate || !this.props.toDate) {
			return boars;
		}

		boars = boars.filter(boar => {
			const date = boar[this.props.dateFilterProperty as keyof IStemAnimal];
			return date && date > this.props.fromDate! && date < this.props.toDate!;
		});

		return boars;
	}

	private edit(stemAnimal: IStemAnimal) {
		this.setState({ editModalOpen: true, stemAnimalToEdit: stemAnimal });
	}

	private closeModal = () => {
		this.setState({ editModalOpen: false, stemAnimalToEdit: {} as IStemAnimal });
	};

	private generateColumns() {
		const columns = [] as any;
		columns.push({
			name: 'edit',
			title: ' ',
			sortable: false,
			filterable: false,
			isFixedLeft: true,
			width: SowListConstants.iconWidth,
			getCellValue: (row: IStemAnimal) =>
				row.entranceKind === AnimalKind.AIBoar ? null : (
					<SkioldTouchableOpacity onPress={() => this.edit(row)}>
						<SkioldImage
							width={SowListConstants.iconSVGWidth}
							height={SowListConstants.iconSVGWidth}
							imageData={PenIcon}
						/>
					</SkioldTouchableOpacity>
				),
		});
		columns.push({
			name: 'animalNumber',
			title: localized('animalNr'),
			isFixedLeft: true,
			getCellValue: (d: IStemAnimal) => d.animalNumber,
		});
		columns.push({
			name: 'kind',
			title: localized('kind'),
			getCellValue: (d: IStemAnimal) =>
				getBoarType(d) ? localizedDynamic('Entrance' + getBoarType(d)) : localized('Boar'),
		});
		columns.push({
			name: 'entranceDate',
			title: localized('entranceDate'),
			getCellValue: (d: IStemAnimal) => getDateString(d.entranceDate),
			sortFunction: NaturalSortDates,
		});
		columns.push({
			name: 'entranceType',
			title: localized('entranceType'),
			getCellValue: (d: IStemAnimal) => localizedDynamic(d.entranceType!),
		});
		columns.push({
			name: 'transponder',
			title: localized('transponder'),
			getCellValue: (d: IStemAnimal) => localizedDynamic(d.transponder!),
		});
		columns.push({
			name: 'departureDate',
			title: localized('departureDate'),
			getCellValue: (d: IStemAnimal) => getDateString(d.departureDate),
			sortFunction: NaturalSortDates,
		});
		columns.push({
			name: 'departure',
			title: localized('departure'),
			getCellValue: (d: IStemAnimal) => localizedDynamic(d.departureType!),
		});
		columns.push({
			name: 'idNumber',
			title: localized('idNumber'),
			getCellValue: (d: IStemAnimal) => printIdNumber(d.idNumber),
			filterFunction: idNumberFilter,
		});
		columns.push({
			name: 'birthdate',
			title: localized('birthdate'),
			getCellValue: (d: IStemAnimal) => getDateString(d.birthDate),
			sortFunction: NaturalSortDates,
		});
		columns.push({
			name: 'race',
			title: localized('Breed'),
			getCellValue: (d: IStemAnimal) => d.race,
		});
		if (this.props.nucleusManagement && this.props.generalSettings.showRaceLine) {
			columns.push({
				name: 'raceLine',
				title: localized('RaceLine'),
			});
		}
		columns.push({
			name: 'index',
			title: localized('index'),
			getCellValue: (d: IStemAnimal) => d.breedIndex,
		});
		columns.push({
			name: 'indexDate',
			title: localized('indexDate'),
			getCellValue: (d: IStemAnimal) => getDateString(d.breedIndexDate),
			sortFunction: NaturalSortDates,
		});

		if (this.props.nucleusManagement) {
			columns.push({
				name: 'breedingNumber',
				title: localized('BreedingNr'),
				getCellValue: (d: IStemAnimal) => d.breedingNumber,
			});
		}

		if (hasNucleusFeatureCEFN(this.props.nucleusManagement)) {
			columns.push({
				name: 'earNotch',
				title: localized('EarNotch'),
				getCellValue: (d: IStemAnimal) => d.earNotch,
			});
		}

		if (this.props.generalSettings.stemAnimalShowMomDadData) {
			columns.push(
				{
					name: 'dadAnimalNumber',
					title: localized('dadNr'),
					getCellValue: (d: IStemAnimal) => d.dadAnimalNumber,
				},
				{
					name: 'dadRace',
					title: localized('dadRace'),
					getCellValue: (d: IStemAnimal) => d.dadRace,
				},
				{
					name: 'dadIdNumber',
					title: localized('dadID'),
					getCellValue: (d: IStemAnimal) => printIdNumber(d.dadIdNumber),
				},

				{
					name: 'momAnimalNumber',
					title: localized('momNr'),
					getCellValue: (d: IStemAnimal) => d.momAnimalNumber,
				},
				{
					name: 'momRace',
					title: localized('momRace'),
					getCellValue: (d: IStemAnimal) => d.momRace,
				},
				{
					name: 'momIdNumber',
					title: localized('momID'),
					getCellValue: (d: IStemAnimal) => printIdNumber(d.momIdNumber),
				},
			);
		}

		return columns;
	}
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(DepartedBoars) as any;
