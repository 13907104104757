import {
	AuthorizationRequest,
	AuthorizationServiceConfiguration,
	AuthorizationServiceConfigurationJson,
	RedirectRequestHandler,
} from '@openid/appauth';
import qs from 'qs';
import {
	AccessTokenExpireKey,
	AccessTokenKey,
	AuthPostSignInRedirectUri,
	AuthPostSignOutRedirectUri,
	AuthReauthorizeRedirectUri,
	B2C_API_SCOPE,
	B2C_AUTHORIZATION_ENDPOINT,
	B2C_CLIENT_ID,
	B2C_TOKEN_DISCOVERY_ENDPOINT,
	SkioldDigitalSigninPolicy,
} from 'shared/constants';
import { getAuthStorageEngine } from 'shared/helpers/auth-storage-helper';
import { getChangePasswordConfig, getTokenExpireTime, isUserAdmin } from 'shared/helpers/authentication-helper.shared';
import { Monitoring } from 'shared/helpers/monitoring-service';
import { SetIsAdmin, setIsAuth } from 'shared/state/ducks/authentication/operations';
import { SiteChange } from 'shared/state/ducks/profile/operations';
import { StoreContainer } from 'shared/state/store-container';

export interface LoginHash {
	access_token: string;
	expires_in: string;
	token_type: string;
}

export async function getAuthConfiguration(): Promise<AuthorizationServiceConfiguration | undefined> {
	try {
		let response = await fetch(B2C_TOKEN_DISCOVERY_ENDPOINT);
		let resJSON = await response.text();
		let configJson = JSON.parse(resJSON) as AuthorizationServiceConfigurationJson;
		let config = new AuthorizationServiceConfiguration(configJson);
		return config;
	} catch (error) {
		handleAuthError(error as any);
	}
}

async function getAuthConfigurationWPolicy(): Promise<AuthorizationServiceConfiguration | undefined> {
	try {
		let response = await fetch(B2C_TOKEN_DISCOVERY_ENDPOINT);
		let resJSON = await response.text();
		let configJson = JSON.parse(resJSON) as AuthorizationServiceConfigurationJson;
		let config = new AuthorizationServiceConfiguration(configJson);
		return config;
	} catch (error) {
		handleAuthError(error as any);
	}
}

export const reauthConfiguration = {
	scope: `openid ${B2C_API_SCOPE}`,
	redirect_uri: AuthReauthorizeRedirectUri,
	client_id: B2C_CLIENT_ID,
	prompt: 'none',
	response_mode: 'fragment',
	response_type: AuthorizationRequest.RESPONSE_TYPE_TOKEN,
};

export async function changePassword() {
	const authConfiguration = getChangePasswordConfig(AuthPostSignOutRedirectUri);
	const query = qs.stringify(authConfiguration);

	const url = `${B2C_AUTHORIZATION_ENDPOINT}?${query}`;
	window.location.href = url;
}

export async function signIn() {
	let configuration = await getAuthConfiguration();

	if (configuration) {
		// uses a redirect flow
		let authorizationHandler = new RedirectRequestHandler();

		// create a request
		let request = new AuthorizationRequest({
			client_id: B2C_CLIENT_ID,
			redirect_uri: AuthPostSignInRedirectUri,
			scope: `openid ${B2C_API_SCOPE}`,
			response_type: AuthorizationRequest.RESPONSE_TYPE_TOKEN,
			state: undefined,
			extras: { response_mode: 'fragment', p: SkioldDigitalSigninPolicy, prompt: 'login' },
		});

		try {
			// make the authorization request
			authorizationHandler.performAuthorizationRequest(configuration, request);
		} catch (error) {
			handleAuthError(error as any);
		}
	}
}

export async function signOutAndClearData() {
	await signOut();
}

export async function signOut(retry: boolean = true) {
	StoreContainer.dispatchAction({ type: 'USER_LOGOUT' });
	SiteChange()(StoreContainer.store.dispatch);

	let configuration = await getAuthConfigurationWPolicy();

	if (configuration) {
		const storage = getAuthStorageEngine();
		await Promise.all([storage.removeItem(AccessTokenKey), storage.removeItem(AccessTokenExpireKey)]);

		let params = qs.stringify({ post_logout_redirect_uri: AuthPostSignOutRedirectUri });
		window.location.href = `${configuration.endSessionEndpoint}?${params}`;
	}
}

export async function getAccessToken() {
	let success: boolean = await extractAndSaveAccessToken(window);

	if (success) {
		setIsAuth(true)(StoreContainer.store.dispatch);
	} else {
		Monitoring.logTrace('Not able to get access token');
	}
}

export async function extractAndSaveAccessToken(windowObj: any) {
	try {
		const hash: LoginHash = qs.parse((windowObj.location.hash as string).substring(1)) as LoginHash;

		if (hash && hash.access_token && hash.expires_in) {
			const storage = getAuthStorageEngine();
			await Promise.all([
				storage.setItem(AccessTokenKey, hash.access_token),
				storage.setItem(AccessTokenExpireKey, getTokenExpireTime(+hash.expires_in).toISOString()),
			]);
			SetIsAdmin(await isUserAdmin(hash.access_token))(StoreContainer.store.dispatch);

			return true;
		}

		return false;
	} catch {
		Monitoring.logTrace(`Extract Token failed. Caused by iframe location: ${windowObj.location}`);
		return false;
	}
}

function handleAuthError(error: Error) {
	Monitoring.logException(error);

	throw error;
}
