import { Syncable } from "shared/state/models/syncable";
import { IMatingBatchSetting } from "shared/api/api";


export const SAVE_MATING_BATCH_SETTING = 'SAVE_MATING_BATCH_SETTING';
export const SYNC_DATA_MATINGBATCHSETTING = 'SYNC_DATA_MATINGBATCHSETTING';

export interface MatingBatchSettingState extends Syncable<IMatingBatchSetting> {
    currentMatingBatchSetting: IMatingBatchSetting;
}
