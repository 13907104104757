import actionCreatorFactory from 'typescript-fsa';
import * as types from './types';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import { ISyncParams, SyncModel } from 'shared/api/api';

const actionCreator = actionCreatorFactory(GenActionFactoryName('sync'));

export const getSyncModelData = actionCreator.async<
	{ siteId?: string; syncParams: ISyncParams; activeSiteId?: string },
	SyncModel
>(types.GET_SYNC_DATA);
