import React from 'react';
import './skiold-text-field.scss';
import { SkioldIntegerInput } from '../skiold-integer-input/skiold-integer-input';
interface TextPropsFromParent {
	onChangeNumber: (newText: number | undefined) => void;
	label: string;
	value?: number;
	style?: any;
	theme?: 'light' | 'dark';
	suffix?: string;
	disabled?: boolean;
	className?: string;
}

// Component representing a Skiold Digital textfield throughout the app/website
export class SkioldIntegerTextField extends React.PureComponent<TextPropsFromParent> {
	public static defaultProps: Partial<TextPropsFromParent> = {
		theme: 'light'
	};

	public input: any; //Any because of missing .focus on TextField typings
	public componentDidMount() {
		if (this.props.theme === 'light') {
		} else if (this.props.theme === 'dark') {
			//Use default colors
		}
	}

	// public textChanged(event: React.FormEvent<HTMLInputElement>) {
	// 	this.props.onChangeText(event.currentTarget.value);
	// }

	public focus() {
		if (this.input) {
			this.input.focus();
		}
	}

	public render() {
		return (
			<span className={`skiold-text-field p-float-label `}>
				<SkioldIntegerInput
					id="float-input"
					text={this.props.value}
					onChangeNumber={this.props.onChangeNumber}
					className={
						(this.props.className ? this.props.className + ' ' : '') +
						(this.props.value || this.props.value === 0 ? 'p-filled' : '')
					}
					disabled={this.props.disabled}
					theme={this.props.theme}
				/>
				<label htmlFor="float-input">{this.props.label}</label>
			</span>
		);
	}
}
