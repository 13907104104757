import React from 'react';
import { SkioldFormsWrapperItem } from './skiold-forms-wrapper-item';
import { FormWrapperPropsFromParent } from './skiold-forms-wrapper-types';
import './skiold-forms-wrapper.scss';


export class SkioldFormsWrapper extends React.PureComponent<FormWrapperPropsFromParent> {
    public static defaultProps: Partial<FormWrapperPropsFromParent> = {
        containerStyle: {},
        roundCorners: true,
        containerClassName: '',
    };
    public render() {
        return (
            <div className={`skiold-forms-wrapper ${this.props.containerClassName}`}>
                {this.props.formRows.map((formRow, index) => (
                    <SkioldFormsWrapperItem
                        key={index}
                        formRow={formRow}
                        formRowIndex={index}
                        formRowLength={this.props.formRows.length}
                        roundCorners={this.props.roundCorners}
                    />
                ))}
            </div>
        );
    }
}
