import React, { FC, useState, useEffect, useCallback } from 'react';
import { Keyboard, KeyboardEvent } from 'react-native';
import { SetKeyboardHeight } from 'native/state/ducks/app-state/operations';
import { useDispatch, useSelector } from 'react-redux';
import { ViewWeb } from '../../utils/web-view';
import { SkioldDatePicker } from '../skiold-date-picker/skiold-date-picker';
import { WebAppState } from 'web/state/store.web';
import { SkioldFetchIcon } from '../../utils/skiold-fetch-icon';
import { SkioldFetchState } from 'shared/state/ducks/skiold-fetch/types';
interface PropsFromParent {
	fetchRequest?: (params: SkioldFetchState) => void;
	showSpinner?: boolean;
}
export const SkioldFetchButton: FC<PropsFromParent> = React.memo(({ fetchRequest, showSpinner }) => {
	const fetchParams = useSelector((state: WebAppState) => state.skioldFetch);
	const callFetchData = useCallback(() => {
		if (fetchRequest && fetchParams) {
			fetchRequest(fetchParams);
		}
	}, [fetchParams, fetchRequest]);

	return <SkioldFetchIcon showSpinner={showSpinner} onPress={callFetchData} />;
});
