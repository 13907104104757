
import actionCreatorFactory from 'typescript-fsa';
import * as types from './types';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import { IDiagnose, SyncDataDiagnose, IImage } from 'shared/api/api';

const actionCreator = actionCreatorFactory(GenActionFactoryName('diagnose'));

export const saveDiagnose = actionCreator.async<
    IDiagnose,
    IDiagnose,
    { code: number; message: string; prevEntity: IDiagnose | undefined }
>(types.SAVE_DIAGNOSE);
export const deleteDiagnose = actionCreator.async<string, undefined>(types.DELETE_DIAGNOSE);
export const getSyncData = actionCreator.async<{ lastSyncDate: Date; }, SyncDataDiagnose>(types.SYNC_DATA_DIAGNOSES);

export const saveImage = actionCreator.async<IImage, string>(types.SAVE_IMAGE);
export const getImage = actionCreator.async<string, IImage>(types.GET_IMAGE);
