import { GrowthPigTuneProduction } from "shared/api/api";

export const UPSERT_GROWTH_PIG_TUNE_PRODUCTION = 'UPSERT_GROWTH_PIG_TUNE_PRODUCTION';
export const GET_GROWTH_PIG_TUNE_PRODUCTION_BY_SITEID = 'GET_GROWTH_PIG_TUNE_PRODUCTION_BY_SITEID';


export interface GrowthPigTuneProductionState {
    growthPigTuneProductions: GrowthPigTuneProduction[];
    updates: GrowthPigTuneProduction[];
    isLoading: boolean;
}
