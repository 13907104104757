import React from 'react';
import { AnimalType, DistriwinFeedUnitType, FuOrKg, IFeedUsagePerAnimal, IProductionMappedDate } from 'shared/api/api';
import { getDateString } from 'shared/helpers/date-helpers';
import { getFeedUnitTypeFromStore, getFuOrKg } from 'shared/helpers/feeding/feeding-helper';
import { localized, localizedDynamic, localizedInterpolation } from 'shared/state/i18n/i18n';
import SkioldTableGrid from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { GroupedHeader } from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid-grouped-header';
import { ViewWeb } from 'web/view/components/utils/web-view';
import './p-report-feed-usage-table.scss';
interface Props {
	feedUsageData?: IFeedUsagePerAnimal[];
	mappedDates?: IProductionMappedDate[];
	fromDate?: Date;
	toDate?: Date;
}

interface State {
	feedUnitType: DistriwinFeedUnitType;
	fuOrKg: FuOrKg;
}

export class PReportFeedUsage extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			feedUnitType: getFeedUnitTypeFromStore(),
			fuOrKg: getFuOrKg(),
		};
	}

	public render() {
		return (
			<SkioldTableGrid
				columns={this.generateColumns()}
				data={this.props.feedUsageData ? this.props.feedUsageData : []}
				tableKey={'PReportFeedUsageKey1'}
				ColumnExtensions={this.generateColumnsExtensions()}
				showPagination={true}
				groupedColumns={this.generateGroupedHeaders()}
				sortable={false}
				filterable={false}
			/>
		);
	}

	public generateColumns = () => {
		const columns: any = [
			{
				name: 'feedSubject',
				title: <div className="feed-subject-align-left">{localized('FeedingSubject')}</div>,
				headerClassName: 'merged-header',
				getCellValue: (d: IFeedUsagePerAnimal) => (
					<ViewWeb>

						{d.feedSubjects &&
							d.feedSubjects!.map((e, index) => (
								<div
									className={`feed-usage-table-row ${
										d.isTotal && d.animalType === AnimalType.Unknown ? 'animal-kind-text' : ''
									}`}
									key={index}
								>
									{e.name && d.isTotal
										? localizedDynamic(e.name)
										: e.feedingSubject &&
										  e.feedingSubject.name &&
										  e.feedingSubject.name.hasOwnProperty('da')
										? e.isDistriwinFeed
											? e.feedingSubject.name['da'] + ' (Distriwin)'
											: e.feedingSubject.name['da']
										: e.feedingSubject && e.feedingSubject.name
										? e.isDistriwinFeed
											? e.feedingSubject.name[0] + ' (Distriwin)'
											: e.feedingSubject.name[0]
										: '\xa0'}
								</div>
							))}
					</ViewWeb>
				),
			},
			{
				name: 'Energy1PerKg',
				title: localizedInterpolation('EnergyKg', this.state.feedUnitType + 'Energy1'),
				headerClassName: 'merged-header',
				getCellValue: (d: IFeedUsagePerAnimal) => (
					<ViewWeb>
						<div>{!d.isTotal && d.animalType && '\xa0'}</div>
						{d.feedSubjects &&
							d.feedSubjects!.map((e, index) => (
								<div className="feed-usage-table-row align-right" key={index}>
									{e.energy1PerKg && e.energy1PerKg.toFixed ? e.energy1PerKg.toFixed(2) : '\xa0'}
								</div>
							))}
					</ViewWeb>
				),
			},
			{
				name: 'Energy2PerKg',
				title: localizedInterpolation('EnergyKg', this.state.feedUnitType + 'Energy2'),
				headerClassName: 'merged-header',
				getCellValue: (d: IFeedUsagePerAnimal) => (
					<ViewWeb>
						<div>{!d.isTotal && d.animalType && '\xa0'}</div>
						{d.feedSubjects &&
							d.feedSubjects!.map((e, index) => (
								<div className="feed-usage-table-row align-right" key={index}>
									{e.energy2PerKg && e.energy2PerKg.toFixed ? e.energy2PerKg.toFixed(2) : '\xa0'}
								</div>
							))}
					</ViewWeb>
				),
			},
			{
				name: 'FosforGPerKg',
				title: localized('FosforGPerKg'),
				headerClassName: 'merged-header',
				getCellValue: (d: IFeedUsagePerAnimal) => (
					<ViewWeb>
						<div>{!d.isTotal && d.animalType && '\xa0'}</div>

						{d.feedSubjects &&
							d.feedSubjects!.map((e, index) => (
								<div className="feed-usage-table-row align-right" key={index}>
									{e.fosforPerKg && e.fosforPerKg.toFixed ? e.fosforPerKg.toFixed(2) : '\xa0'}
								</div>
							))}
					</ViewWeb>
				),
			},
			{
				name: 'RawProtein',
				title: localized('RawProtein'),
				headerClassName: 'merged-header',
				getCellValue: (d: IFeedUsagePerAnimal) => (
					<ViewWeb>
						<div>{!d.isTotal && d.animalType && '\xa0'}</div>

						{d.feedSubjects &&
							d.feedSubjects!.map((e, index) => (
								<div className="feed-usage-table-row align-right" key={index}>
									{e.rawProtein && e.rawProtein.toFixed ? e.rawProtein.toFixed(2) : '\xa0'}
								</div>
							))}
					</ViewWeb>
				),
			},
			{
				name: 'kgTotal',
				title: localized('kgTotal'),
				headerClassName: 'merged-header',
				getCellValue: (d: IFeedUsagePerAnimal) => (
					<ViewWeb>
						<div>{!d.isTotal && d.animalType && '\xa0'}</div>
						{d.feedSubjects &&
							d.feedSubjects!.map((e, index) => (
								<div className="feed-usage-table-row align-right" key={index}>
									{e.totalKg && e.totalKg.toFixed ? e.totalKg.toFixed(1) : '\xa0'}
								</div>
							))}
					</ViewWeb>
				),
			},
			{
				name: 'energyTotal',
				title: localized(this.state.feedUnitType + 'Total'),
				headerClassName: 'merged-header',
				getCellValue: (d: IFeedUsagePerAnimal) => (
					<ViewWeb>
						<div>{!d.isTotal && d.animalType && '\xa0'}</div>
						{d.feedSubjects &&
							d.feedSubjects!.map((e, index) => (
								<div className="feed-usage-table-row align-right" key={index}>
									{e.totalFE && e.totalFE.toFixed ? e.totalFE.toFixed(1) : '\xa0'}
								</div>
							))}
					</ViewWeb>
				),
			},
			{
				name: 'energyPercent',
				title: localized(this.state.fuOrKg === FuOrKg.Fu ? this.state.feedUnitType + 'Pct' : 'kgPercent'),
				headerClassName: 'merged-header',
				getCellValue: (d: IFeedUsagePerAnimal) => (
					<ViewWeb>
						<div>{!d.isTotal && d.animalType && '\xa0'}</div>
						{d.feedSubjects &&
							d.feedSubjects!.map((e, index) => (
								<div className="feed-usage-table-row align-right" key={index}>
									{this.state.fuOrKg === FuOrKg.Fu
										? e.fePercent && e.fePercent.toFixed
											? e.fePercent.toFixed(1)
											: '\xa0'
										: e.kgPercent && e.kgPercent.toFixed
										? e.kgPercent.toFixed(1)
										: '\xa0'}
								</div>
							))}
					</ViewWeb>
				),
			},
		];
		return columns;
	};

	public generateColumnsExtensions = () => {
		const columnExtensions: any = [
			{
				columnName: 'feedSubject',
				width: 165,
			},
			{
				columnName: 'Energy1PerKg',
				width: 80,
			},
			{
				columnName: 'Energy2PerKg',
				width: 80,
			},
			{
				columnName: 'FosforGPerKg',
				width: 97.5,
			},
			{
				columnName: 'RawProtein',
				width: 97.5,
			},
			{
				columnName: 'kgTotal',
				width: 95,
			},
			{
				columnName: 'energyTotal',
				width: 95,
			},
			{
				columnName: 'energyPercent',
				width: 100,
			},
		];
		return columnExtensions;
	};

	public generateGroupedHeaders = () => {
		//Get the newest period, that isn't the total period
		let mappedDate =
			this.props.mappedDates &&
			this.props.mappedDates.length > 0 &&
			this.props.mappedDates.find(mapped => mapped.fromDate && mapped.toDate);
		const groupedHeader: GroupedHeader[] = [
			{
				title: (
					<div>
						<div>{localized('FeedUsage')}</div>
						<div>
							{mappedDate
								? `${mappedDate.fromDate} - ${mappedDate.toDate}`
								: this.props.fromDate && this.props.toDate
								? `${getDateString(this.props.fromDate)} - ${getDateString(this.props.toDate)}`
								: ''}
						</div>
					</div>
				),
				children: [
					{
						columnName: 'feedSubject',
					},
					{
						columnName: 'Energy1PerKg',
					},
					{
						columnName: 'Energy2PerKg',
					},
					{
						columnName: 'FosforGPerKg',
					},
					{
						columnName: 'RawProtein',
					},
					{
						columnName: 'kgTotal',
					},
					{
						columnName: 'energyTotal',
					},
					{
						columnName: 'energyPercent',
					},
				],
			},
		];
		return groupedHeader;
	};
}

// export default (
//     connect(
//         ScanningWorkListmapStateToProps,
//         ScanningWorkListmapDispatchToProps,
//         null,
//         { forwardRef: true }
//     )(ScanningWorkList) as any
// );
