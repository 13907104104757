import memoize from 'memoize-one';
import React from 'react';
import { connect } from 'react-redux';
import { IProcessEquipmentData } from 'shared/api/api';
import { RefType } from 'shared/helpers/ref-type';
import {
	GetEsfLocationsWithSettedPensData,
	GetFilteredLocations,
} from 'shared/helpers/work-list-helpers/esf-list-helpers/general-work-list-esf-helpers';
import { getStationsInUse } from 'shared/helpers/work-list-helpers/esf-list-helpers/generel-esf-list-helper';
import {
	ShortageEsfListMapDispatchToProps,
	ShortageEsfListMapStateToProps,
	ShortageEsfListProps,
	ShortageEsfListState,
	ShortageEsfWorkListItem,
} from 'shared/helpers/work-list-helpers/esf-list-helpers/shortage-esf-helpers';
import { checkSectionWorkLists } from 'shared/helpers/work-list-helpers/move-to-helpers/moving-lists-helper';
import { localized } from 'shared/state/i18n/i18n';
import { SharedAppState } from 'shared/state/store.shared';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import SkioldFormPenPicker from '../../location/location-picker/skiold-form-pen-picker';
import SkioldStableSectionPicker from '../../location/location-picker/skiold-stable-section-picker';
import { SkioldModal } from '../../skiold-components/skiold-modal/skiold-modal';
import '../list-setup.scss';
import LastFourVisitsEsf from './last-four-visits-esf';
import ShortageEsfListTable from './shortage-esf-list-table';

export class ShortageEsfList extends React.PureComponent<ShortageEsfListProps, ShortageEsfListState> {
	public SkioldTableRef: any;
	private esfLocationsWithSettedPens: IProcessEquipmentData[] = [];

	private generateEsfLocationsWithSettedPensData = memoize((processEquipmentData, unitToPens) =>
		GetEsfLocationsWithSettedPensData(processEquipmentData, unitToPens),
	);
	constructor(props: ShortageEsfListProps) {
		super(props);

		this.state = {
			usesPens: false,
			stations: [],
			sectionId: '',
			penId: '',
			filteredLocations: undefined,
		};
	}

	public componentDidMount() {

		this.props.getFeedingStatus();
		this.props.getDailyStatus();
		
		const filteredLocs = GetFilteredLocations(this.props, this.generateEsfLocationsWithSettedPensData);
		this.esfLocationsWithSettedPens = filteredLocs.esfLocationsWithSettedPens;
		let stations = getStationsInUse(
			this.props.unitToPens,
			'',
			this.props.stations,
			this.esfLocationsWithSettedPens,
			true,
		);
		this.setState({ stations, filteredLocations: filteredLocs.locations });
	}

	public async ResetWorkList() {
		if (this.SkioldTableRef) {
			this.SkioldTableRef.ResetWorkList();
		}
	}

	public async CheckSavePregnancyEventListsStatus() {
		if (this.SkioldTableRef && this.SkioldTableRef.CheckSavePregnancyEventListsStatus) {
			return await this.SkioldTableRef.CheckSavePregnancyEventListsStatus();
		}
		return true;
	}

	public async SaveWorklist() {
		if (this.SkioldTableRef) {
			this.SkioldTableRef.SaveWorklist();
		}

		return true;
	}

	public render() {
		return (
			<ViewWeb className="work-list flex-direction-row">
				<ViewWeb className="view-container-no-width">
					<ViewWeb className="z-index-1000">
						<ViewWeb className="getrows">
							<ViewWeb className="flex-direction-row">
								<ViewWeb className="flexFour flex-direction-row ">
									<ViewWeb className="buttons-no-right-border">
										<TextWeb className="stable-section-text">
											{localized('chooseLocation')}:
										</TextWeb>
										<SkioldStableSectionPicker
											onStableSectionSelected={this.onSectionChanged}
											sectionId={this.state.sectionId}
											filteredLocations={this.state.filteredLocations}
											isPigProduction={true}
											containerClassName="picker-width"
										/>
										{this.state.usesPens && (
											<ViewWeb className="buttons-no-right-border">
												<TextWeb className="pen-text">{localized('Pen')}:</TextWeb>
												<SkioldFormPenPicker
													onPenSelected={this.onPenChanged}
													filteredLocations={this.state.filteredLocations}
													sectionId={this.state.sectionId}
													penId={undefined}
													theme="light"
													containerClassName="picker-width"
													usedInsideForm={false}
												/>
											</ViewWeb>
										)}
									</ViewWeb>
								</ViewWeb>
							</ViewWeb>
							<ViewWeb className="flex-direction-row">
								<ViewWeb className="flexFour flex-direction-row ">
									<ViewWeb className="buttons-no-right-border">
										<TextWeb className="stable-section-text">{localized('Stations')}:</TextWeb>
										<TextWeb className="stations-label-text">{this.state.stations}</TextWeb>
									</ViewWeb>
								</ViewWeb>
							</ViewWeb>
						</ViewWeb>
					</ViewWeb>
					<ShortageEsfListTable
						sectionId={this.state.sectionId}
						penId={this.state.penId}
						ref={this.setRef}
						rowSelected={this.onRowSelected}
						esfLocationsWithSettedPens={this.esfLocationsWithSettedPens}
					/>
				</ViewWeb>
				<SkioldModal padding={'0'} isOpen={this.state.isModalOpen} close={this.closeLastFourVisitsModal}>
					<LastFourVisitsEsf
						processEquipmentDataWithSettedPens={this.esfLocationsWithSettedPens}
						close={this.closeLastFourVisitsModal}
						stemAnimalId={this.state.selectedItem ? this.state.selectedItem.stemAnimalId : undefined}
					/>
				</SkioldModal>
			</ViewWeb>
		);
	}

	private closeLastFourVisitsModal = () => this.setState({ selectedItem: undefined, isModalOpen: false });

	private onPenChanged = penId => {
		if (penId !== this.state.penId) {
			this.setState({
				penId: penId,
			});
		}
	};

	private onSectionChanged = sectionId => {
		if (sectionId !== this.state.sectionId) {
			let PenIdAndUsesPens = checkSectionWorkLists(sectionId, this.state.penId); // replace by a more saying name
			let stations = getStationsInUse(
				this.props.unitToPens,
				sectionId,
				this.props.stations,
				this.esfLocationsWithSettedPens,
				true,
			);
			this.setState({
				sectionId: sectionId,
				penId: undefined,
				usesPens: PenIdAndUsesPens.usesPens,
				stations,
			});
		}
	};

	private onRowSelected = (shortageEsfWorkListItem: ShortageEsfWorkListItem | undefined) => {
		this.setState({ selectedItem: shortageEsfWorkListItem, isModalOpen: true });
	};

	private setRef = (m: any) => (m ? (this.SkioldTableRef = m) : {});
}

export default connect<
	ReturnType<typeof ShortageEsfListMapStateToProps>,
	ReturnType<typeof ShortageEsfListMapDispatchToProps>,
	RefType,
	SharedAppState
>(ShortageEsfListMapStateToProps, ShortageEsfListMapDispatchToProps, null, { forwardRef: true })(ShortageEsfList);
