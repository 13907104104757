import React, { FC, useEffect, useState } from 'react';
import { Option } from 'react-dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { Equipment } from 'shared/api/api';
import { usePrevious } from 'shared/helpers/redux-helpers';
import {
	FetchingComponents,
	GetDistriwinFeedingComponentSyncData,
} from 'shared/state/ducks/distriwin-feed-component/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { SkioldFormDropdown } from '../../skiold-components/skiold-dropdown/skiold-form-dropdown';
import { WhiteText } from '../../Text/white-text';
import { SkioldFetchIcon } from '../../utils/skiold-fetch-icon';
import { ViewWeb } from '../../utils/web-view';
import { useCreateFeedingRelation } from './create-distriwin-feeding-relation';

interface PropsFromParent {}

export const CreateDistriwinFeedingRelationHeader: FC<PropsFromParent> = React.memo(props => {
	const dispatch = useDispatch();
	const [processEquipmentOptions, setProcessEquipmentOptions] = useState<Option[]>([]);
	const [selectedOption, setSelectedOption] = useState<Option>({ value: '', label: ' ' });
	const { processEquipment, setProcessEquipment } = useCreateFeedingRelation();
	const processEquipments = useSelector((state: WebAppState) => state.processEquipments.entities);
	const fetchingComponents = useSelector((state: WebAppState) => state.distriwinFeedComponents.fetchingComponents);
	const prevFetchingComponents = usePrevious(fetchingComponents);

	useEffect(() => {
		if (
			!fetchingComponents &&
			prevFetchingComponents &&
			processEquipment &&
			processEquipment.siteId &&
			processEquipment.code
		) {
			GetDistriwinFeedingComponentSyncData(processEquipment.siteId)(dispatch);
		}
	}, [prevFetchingComponents, fetchingComponents, processEquipment, dispatch]);

	// Find process equipment when selection has changed
	useEffect(() => {
		if (selectedOption && selectedOption.value) {
			const peq = processEquipments.find(p => p.id === selectedOption.value);
			setProcessEquipment(peq);
		}
		//eslint-disable-next-line
	}, [selectedOption]);

	// Setup options
	useEffect(() => {
		let options = processEquipments
			.filter(e => e.equipment === Equipment.Distriwin)
			.map(processEquipment => ({
				value: processEquipment.id!,
				label: processEquipment.name
					? processEquipment.name + ''
					: processEquipment.equipment
					? processEquipment.equipment!.toString()
					: '',
			}));
		options.unshift({ value: '', label: ' ' });
		setProcessEquipmentOptions(options);
	}, [processEquipments]);

	const fetchData = () => {
		if (processEquipment && processEquipment.siteId && processEquipment.code) {
			FetchingComponents(processEquipment.siteId, processEquipment.code)(dispatch);
		}
	};

	const equipmentChanged = (option: Option) => {
		setSelectedOption(option);
	};

	return (
		<ViewWeb className="uppper-container">
			<WhiteText>{localized('chooseProcessEquipment')}</WhiteText>
			<SkioldFormDropdown
				items={processEquipmentOptions}
				selectedValue={selectedOption}
				onValueChanged={equipmentChanged}
				theme="light"
			/>
			{selectedOption.value !== '' && (
				<SkioldFetchIcon className={'margin-left'} showSpinner={fetchingComponents} onPress={fetchData} />
			)}
		</ViewWeb>
	);
});
