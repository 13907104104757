import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { ResultYoungFemaleAgeState } from '.';
import { siteChange } from '../../profile/actions';
import * as actions from './actions';
import { clearAnalysisData } from '../actions';

export const initialState: ResultYoungFemaleAgeState = {
	data: undefined,
	elaborationdata: undefined,
	loading: false,
	loadingElaboration: false,
};

const resultYoungFemaleAgeReducer = (
	state: ResultYoungFemaleAgeState = initialState,
	action: Action
): ResultYoungFemaleAgeState => {
	if (isType(action, actions.getData.started)) {
		state = { ...state, loading: true };
	}

	if (isType(action, actions.getData.done)) {
		state = {
			...state,
			data: action.payload.result,
			loading: false,
		};
	}
	if (isType(action, actions.getData.failed)) {
		state = { ...state, loading: false };
	}

	if (isType(action, actions.getElaborationData.started)) {
		state = { ...state, loadingElaboration: true };
	}

	if (isType(action, actions.getElaborationData.done)) {
		state = {
			...state,
			elaborationdata: action.payload.result,
			loadingElaboration: false,
		};
	}
	if (isType(action, actions.getElaborationData.failed)) {
		state = { ...state, loadingElaboration: false };
	}

	if (isType(action, clearAnalysisData.done) ) {
		state = initialState;
	}

	if (isType(action, siteChange.done)) {
		state = initialState;
	}
	return state;
};

export default resultYoungFemaleAgeReducer;
