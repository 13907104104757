import {
	IValveGroup,
	IValveGroupUpdate,
	ValveGroupsSyncStatus,
	AnimalType,
	GrowthPigAdjustFeedUpdate,
} from 'shared/api/api';

export const SYNC = 'SYNC';
export const UPDATE_VALVE_GROUP = 'UPDATE_VALVE_GROUP';
export const UPDATE_VALVE_GROUP_API = 'UPDATE_VALVE_GROUP_API';
export const SYNC_DATA_VALVE_GROUPS = 'SYNC_DATA_VALVE_GROUPS';
export const SHOW_FULL_SYNC_SPINNER = 'SHOW_FULL_SYNC_SPINNER';
export const SET_FULL_SYNC_NUMBER = 'SET_FULL_SYNC_NUMBER';
export const FULL_SYNC_FAILED = 'FULL_SYNC_FAILED';
export const SAVE_GROWTH_PIG_ADJUST_FEED = 'SAVE_GROWTH_PIG_ADJUST_FEED';

export interface AdjustFeedState {
	locationStrings: LocationWithType[];
	valveGroups: IValveGroup[]; // should be serialized to the route
	updates: IValveGroupUpdate[];
	previousEntities: IValveGroup[];
	lastSync: number;
	lastSyncDate: Date;
	isLoading: boolean;
	syncInProgress: boolean;
	failed: boolean;
	errorMessage: ValveGroupsSyncStatus | undefined;
	saveSyncInProgress: boolean;
	showFullSyncSpinner: boolean;
	fullSyncNumbers: FullSyncNumbers;
	fullSyncFailed: boolean;
	growthPigAdjustFeedUpdate: GrowthPigAdjustFeedUpdate[];
}

export interface FullSyncNumbers {
	fullSyncTotal: number;
	fullSyncCurrent: number;
}

export class LocationWithType {
	public locationString: string | undefined;
	public animalType: AnimalType | undefined;
	public order: number | undefined;
}
