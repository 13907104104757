import { Unique } from 'shared/state/models/unique';
import { IUserProfile } from 'shared/api/api';
import { propertiesToIgnore } from 'shared/state/ducks/stem-animals/reducer';

export function mergeArraysHashmap<T extends Unique>(
	oldHashmap: { [key: string]: T[] },
	updatedHashmap: { [key: string]: T[] },
) {
	if (!oldHashmap || Object.keys(oldHashmap).length === 0) {
		let cleanHashmap = {};
		Object.keys(updatedHashmap).forEach(updatedKey => {
			cleanHashmap[updatedKey] = updatedHashmap[updatedKey]
				.map(ua =>
					Object.entries(ua).reduce((a, [k, v]) => {
						return propertiesToIgnore(k) || v == null ? a : ((a[k] = v), a);
					}, {} as T),
				)
				.filter(ua => !ua.isDeleted);
		});
		return cleanHashmap;
	}

	let clonedOldHashmap = { ...oldHashmap };

	Object.keys(updatedHashmap).forEach(updatedKey => {
		let oldArray = clonedOldHashmap[updatedKey];
		if (oldArray === undefined) {
			oldArray = [];
		}
		let clonedOldArray = [...oldArray];

		let updatedArray = updatedHashmap[updatedKey];

		if (oldArray) {
			updatedArray.forEach(u => {
				let index = clonedOldArray.findIndex(o => o.id === u.id);
				const cleanObject = Object.entries(u).reduce((a, [k, v]) => {
					return propertiesToIgnore(k) || v == null ? a : ((a[k] = v), a);
				}, {} as T);
				merge(index, clonedOldArray, cleanObject);
			});

			clonedOldHashmap[updatedKey] = clonedOldArray;
		} else {
			clonedOldHashmap[updatedKey] = updatedArray
				.map(ua =>
					Object.entries(ua).reduce((a, [k, v]) => {
						return propertiesToIgnore(k) || v == null ? a : ((a[k] = v), a);
					}, {} as T),
				)
				.filter(ua => !ua.isDeleted);
		}
	});

	return clonedOldHashmap;
}

export function mergeArrays<T extends Unique>(oldArray: T[], updatedArray: T[], keepDeleted: boolean = false) {
	if (!updatedArray || updatedArray.length === 0) {
		return oldArray;
	}

	if (!oldArray || oldArray.length === 0) {
		return updatedArray.filter(i => !i.isDeleted);
	}

	let clonedOld = [...oldArray];
	updatedArray.forEach(updated => {
		const index = clonedOld.findIndex(old => old.id === updated.id);
		merge<T>(index, clonedOld, updated, keepDeleted);
	});

	return clonedOld.filter(elem => keepDeleted || !elem.isDeleted);
}

export function mergeArraysReplaceNewer<T extends Unique>(
	oldArray: T[],
	updatedArray: T[],
	idFn: (t: T) => string,
	newerValidationFn: (oldElem: T, newElem: T) => boolean,
) {
	if (!oldArray || oldArray.length === 0) {
		return updatedArray;
	}

	let clonedOld = [...oldArray];

	updatedArray.forEach(updated => {
		const index = clonedOld.findIndex(old => idFn(old) === idFn(updated));

		if (index > -1) {
			//Check if we should replace
			if (updated.isDeleted === true) {
				//Remove if it has been deleted - todo: get the newest from the server when we make a delete functionality
				clonedOld.splice(index, 1);
			} else {
				//Replace existing if newer else ignore
				const old = clonedOld[index];
				if (newerValidationFn(old, updated)) {
					merge(index, clonedOld, updated);
				}
			}
		} else {
			//Just add it - its the first for this animal
			merge(index, clonedOld, updated);
		}
	});

	return clonedOld.filter(elem => !elem.isDeleted);
}

export function mergeArraysNoUnique<T>(
	oldArray: T[],
	updatedArray: T[],
	idFn: (t: T) => string,
	deletedFn: (t: T) => boolean,
) {
	if (!oldArray || oldArray.length === 0) {
		return updatedArray;
	}

	let clonedOld = [...oldArray];
	updatedArray.forEach(updated => {
		const index = clonedOld.findIndex(old => idFn(old) === idFn(updated));
		mergeNoUnique<T>(index, clonedOld, updated);
	});

	return clonedOld.filter(elem => !deletedFn(elem));
}

function merge<T extends Unique>(index: number, clonedOld: T[], updated: T, keepDeleted: boolean = false) {
	if (index > -1) {
		if (updated.isDeleted && !keepDeleted) {
			clonedOld.splice(index, 1);
		} else {
			clonedOld[index] = updated;
		}
	} else {
		if (!updated.isDeleted || keepDeleted) {
			clonedOld.push(updated);
		}
	}
}

export function mergeNoUnique<T>(index: number, clonedOld: T[], updated: T) {
	if (index > -1) {
		clonedOld[index] = updated;
	} else { 
		clonedOld.push(updated);
	}
}

export function updateValueInArray<T extends Unique>(array: T[], value: T) {
	return array.map((item, index) => {
		if (item.id !== value.id) {
			// This isn't the item we care about - keep it as-is
			return item;
		}

		// Hack to fix: Spread types may only be created from object types - https://github.com/Microsoft/TypeScript/issues/22687
		let i = item as any;
		let v = value as any;

		// Otherwise, this is the one we want - return an updated value
		return {
			...i,
			...v,
		};
	});
}

export function addValueToArray<T>(array: T[], value: T) {
	const arr = [...array, value];
	return arr;
}

export function upsertValueInArray<T extends Unique>(old: T[], updated: T) {
	const index = old.findIndex(o => o.id === updated.id);
	return upsert(old, updated, index);
}

export function upsertValueInArrayCustomId<T extends Unique>(old: T[], updated: T, idFn: (t: T) => string) {
	const index = old.findIndex(o => idFn(o) === idFn(updated));
	return upsert(old, updated, index);
}

export function upsertValueInHashmap<T extends Unique>(oldHashmap: { [key: string]: T[] }, updated: T, key: string) {
	let clonedOldHashmap = { ...oldHashmap };

	let oldArray = clonedOldHashmap[key];
	if (!oldArray) {
		oldArray = [];
		clonedOldHashmap[key] = oldArray;
	}
	let clonedArray = [...oldArray];

	const index = clonedArray.findIndex(o => o.id === updated.id);
	let updatedArray = upsert(clonedArray, updated, index);

	clonedOldHashmap[key] = updatedArray;
	return clonedOldHashmap;
}

export function upsertValueInArrayNoUnique<T>(old: T[], updated: T, idFn: (t: T) => string) {
	const index = old.findIndex(o => idFn(o) === idFn(updated));
	return upsert(old, updated, index);
}

export function upsertMultipleValuesInArray<T extends Unique>(old: T[], updates: T[]) {
	updates.forEach(update => {
		const index = old.findIndex(o => o.id === update.id);
		old = upsert(old, update, index);
	});
	return old;
}

function upsert<T>(old: T[], updated: T, index: number) {
	//Need to be a clone, since redux have problems when mutating the state
	let clonedOld = [...old];
	if (index > -1) {
		clonedOld[index] = updated;
	} else {
		clonedOld.push(updated);
	}

	return clonedOld;
}

export function removeValueFromArray<T extends Unique>(arr: T[], id: string): T[] {
	const index = arr.findIndex(x => x.id === id);
	return remove(arr, index);
}

export function removeKeyFromHashmap<T extends Unique>(
	hashmap: { [key: string]: T[] },
	key: string,
): { [key: string]: T[] } {
	let clonedHashmap = { ...hashmap };
	delete clonedHashmap[key];
	return clonedHashmap;
}

export function removeValueFromHashmap<T extends Unique>(
	hashmap: { [key: string]: T[] },
	id: string,
	key: string,
): { [key: string]: T[] } {
	let clonedHashmap = { ...hashmap };
	let clonedArray = [...clonedHashmap[key]];

	const index = clonedArray.findIndex(x => x.id === id);
	clonedArray = remove(clonedArray, index);
	clonedHashmap[key] = clonedArray;

	return clonedHashmap;
}

export function removeMultipleValuesFromArray<T extends Unique>(arr: T[], ids: string[]): T[] {
	ids.forEach(id => {
		const index = arr.findIndex(x => x.id === id);
		arr = remove(arr, index);
	});
	return arr;
}

export function removeMultipleValuesFromArrayNoUnique<T>(arr: T[], ids: string[], idFn: (t: T) => string): T[] {
	ids.forEach(id => {
		const index = arr.findIndex(x => idFn(x) === id);
		arr = remove(arr, index);
	});
	return arr;
}

export function removeValueFromArrayNoUnique<T>(arr: T[], id: string, idFn: (t: T) => string): T[] {
	const index = arr.findIndex(x => idFn(x) === id);
	return remove(arr, index);
}

export function remove<T>(arr: T[], index: number) {
	if (index === -1) {
		return arr;
	}
	const arra = [...arr];
	arra.splice(index, 1);

	return arra;
}

export function getSiteId(activeProfile: IUserProfile | undefined) {
	return activeProfile ? activeProfile.siteId! : '';
}

export function removeKeyFromSingleHashmap<T extends Unique>(
	hashmap: { [key: string]: T },
	key: string,
): { [key: string]: T } {
	let clonedHashmap = { ...hashmap };
	delete clonedHashmap[key];
	return clonedHashmap;
}

export function removeMultipleKeysFromSingleHashmap<T extends Unique>(
	hashmap: { [key: string]: T },
	keys: string[],
): { [key: string]: T } {
	let clonedHashmap = { ...hashmap };

	keys.forEach(key => {
		delete clonedHashmap[key];
	});

	return clonedHashmap;
}

export function upsertMultipleKeysFromSingleHashmap<T extends Unique>(
	hashmap: { [key: string]: T },
	items: T[],
): { [key: string]: T } {
	let clonedHashmap = { ...hashmap };

	items.forEach(item => {
		if (item.id) {
			clonedHashmap[item.id] = item;
		}
	});

	return clonedHashmap;
}

