import { Dispatch } from 'redux';
import {
	AllAnimalsEsfListSetting,
	Averted,
	Equipment,
	FeedingStatusUpdate,
	IEsfFeedingStatus,
	IMoveEvent,
	IProcessEquipmentData,
	IStation,
	IStemAnimal,
	IUnitToPen,
	PregnancyState,
	Scanned,
	ScanResult,
	WorkListType,
} from 'shared/api/api';
import { areDatesEqual } from 'shared/helpers/date-helpers';
import { calculateDays, setTwoDecimalsAsString } from 'shared/helpers/general-helpers';
import { ILocationModel } from 'shared/helpers/location-helper';
import { memoizeGetESFProcessEquipment, memoizeLocation } from 'shared/helpers/memoize-getters/memoize-getters';
import { sortPregnanciesByDate } from 'shared/helpers/pregnancy-helper/sort-pregnancies';
import * as EsfStatusOperations from 'shared/state/ducks/esf-status/operations';
import { SaveFeedingStatus } from 'shared/state/ducks/esf-status/operations';
import { GetLocations } from 'shared/state/ducks/locations/operations';
import * as moveEventOperation from 'shared/state/ducks/move-events/operations';
import { GetProcessEquipmentDataSyncData } from 'shared/state/ducks/process-equipment-data/operations';
import * as StationOperations from 'shared/state/ducks/station/operations';
import * as stemAnimalOperation from 'shared/state/ducks/stem-animals/operations';
import * as UnitsToPenOperations from 'shared/state/ducks/unit-to-pen/operations';
import * as validationSetupOperation from 'shared/state/ducks/validation-setup/operations';
import { GetSyncData } from 'shared/state/ducks/work-list-settings/operations';
import { StoreContainer } from 'shared/state/store-container';
import { SharedAppState } from 'shared/state/store.shared';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';

export class AllAnimalsEsfWorkListItem {
	public stemAnimalId: string | undefined;
	public cycleDays: number | undefined;
	public animalNumber: string | undefined;
	public feDay: string | undefined;
	public eatenToday: string | undefined;
	public esfFeedingStatusId: string | undefined;
	public selectedCurveNumber: number | undefined;
	public fixedPercentage: number | undefined;
	public isChecked: boolean = false;
	public siteId: string | undefined;
	public isDataValid: boolean = true;
	public penId: string | undefined;
	public order: number | undefined;
	public isBlank?: boolean | undefined;
}

export interface AllAnimalsEsfWorkListPropsFromParent {
	sectionId: string | undefined;
	penId: string | undefined;
	esfLocationsWithSettedPens: IProcessEquipmentData[];
	navigation?: any;
}

export interface AllAnimalsEsfListState {
	sectionId?: string;
	penId?: string;
	usesPens: boolean;
	stations: string[];
	filteredLocations: ILocationModel;
}

export const allAnimalsEsfListMapStateToProps = (state: SharedAppState) => {
	return {
		workListSettings: state.workListSettings.entities.find(
			setup => setup.type === WorkListType.AllAnimalsEsfListSetting,
		)! as AllAnimalsEsfListSetting,
		siteId: state.profile.active ? state.profile.active.siteId : undefined,
		stemAnimals: state.stemAnimals.entities,
		validationSettings:
			state.validationSetup.entity.validationIntervals &&
			state.validationSetup.entity.validationIntervals &&
			state.validationSetup.entity.validationIntervals[1] !== undefined &&
			state.validationSetup.entity.validationIntervals[1].plan
				? state.validationSetup.entity.validationIntervals[1].plan
				: 116,
		unitToPens: state.unitToPenData.data,
		processEquipmentData: memoizeGetESFProcessEquipment(state.processEquipmentData.entities),
		locations: memoizeLocation(
			state.locations.buildings,
			state.locations.sections,
			state.locations.pens,
			state.locations.valves,
		),
		moveEvents: state.moveEvents.entities,
		esfStatusData: state.esfStatus.data,
		stations: state.station.data,
	};
};

export const allAnimalsEsfListMapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	saveFeedingStatus: (feedingStatusUpdate: FeedingStatusUpdate) => SaveFeedingStatus(feedingStatusUpdate)(dispatch),
	getWorkListSettingsBySiteId: () => GetSyncData()(dispatch),
	stemAnimalGetSyncData: () => stemAnimalOperation.GetSyncData()(dispatch),
	validationSetupGetSyncData: () => validationSetupOperation.GetSyncData()(dispatch),
	getunitsData: () => UnitsToPenOperations.GetSyncData()(dispatch),
	GetStationSyncData: () => StationOperations.GetStationSyncData()(dispatch),
	getFeedingCurves: () => EsfStatusOperations.GetEsfFeedCurveSyncData()(dispatch),
	getFeedingStatus: () => EsfStatusOperations.GetEsfFeedingStatusSyncData()(dispatch),
	GetProcessEquipmentDataSyncData: () => GetProcessEquipmentDataSyncData()(dispatch),
	moveEventGetSyncData: () => moveEventOperation.GetSyncData()(dispatch),
	getLocations: () => GetLocations()(dispatch),
});

export type AllAnimalsEsfListProps = ReturnType<typeof allAnimalsEsfListMapStateToProps> &
	ReturnType<typeof allAnimalsEsfListMapDispatchToProps> & { navigation?: any };

function getAnimalsOnLocation(penIds: string[], moveEvents: IMoveEvent[], stemAnimals: IStemAnimal[]) {
	let stemEntrancePen = stemAnimals
		.filter(
			stem =>
				moveEvents.findIndex(e => e.stemAnimalId === stem.id) === -1 &&
				stem.entrancePenId &&
				penIds.includes(stem.entrancePenId),
		)
		.map(m => m.id);
	let movedToLocation = moveEvents
		.filter(moveEvent => moveEvent.penId && penIds.includes(moveEvent.penId))
		.map(m => m.stemAnimalId);

	return stemEntrancePen.concat(movedToLocation);
}

export function getAllAniamlsData(
	stemAnimals: IStemAnimal[],
	unitToPens: IUnitToPen[],
	selectedSectionId: string | undefined,
	moveEvents: IMoveEvent[],
	esfFeedingStatus: IEsfFeedingStatus[],
	esfStations: IStation[],
	esfLocationsWithSettedPens: IProcessEquipmentData[],
	siteId: string | undefined,
	workListSettings: AllAnimalsEsfListSetting,
	validationSettings: number,
) {
	if (!selectedSectionId) {
		return { data: [] };
	}
	let data: AllAnimalsEsfWorkListItem[] = [];
	const esfLocations = selectedSectionId
		? esfLocationsWithSettedPens
				.filter(esfLoc => esfLoc.sectionId === selectedSectionId && esfLoc.id !== undefined)
				.map(esfLocWithSettedPens => esfLocWithSettedPens.id)
		: esfLocationsWithSettedPens.map(esfLocWithSettedPens => esfLocWithSettedPens.id);

	let utps = unitToPens.filter(utp => esfLocations.includes(utp.processEquipmentDataId));

	let animalIds = getAnimalsOnLocation(
		utps.map(utp => utp.penId!),
		moveEvents,
		stemAnimals,
	);

	const animals = stemAnimals.filter(stem => animalIds.includes(stem.id));

	for (let animalIndex = 0; animalIndex < animals.length; animalIndex++) {
		if (data.find(d => d.stemAnimalId === animals[animalIndex].id) === undefined) {
			let esfStatusItem = esfFeedingStatus.find(esfStatus => esfStatus.stemAnimalId === animals[animalIndex].id);
			if (esfStatusItem) {
				let isDataValid = areDatesEqual(new Date(), esfStatusItem.modifiedOn);
				data.push({
					stemAnimalId: animals[animalIndex].id,
					cycleDays: getCycleDays(animals[animalIndex].id!, workListSettings, validationSettings),
					animalNumber: animals[animalIndex].animalNumber,
					feDay: setTwoDecimalsAsString(esfStatusItem.feedCurveAmount),
					fixedPercentage: esfStatusItem.fixedDeviation,
					eatenToday: isDataValid ? setTwoDecimalsAsString(esfStatusItem.feedToday) : undefined,
					selectedCurveNumber: esfStatusItem.feedCurveNumber,
					isChecked: false,
					siteId: siteId,
					esfFeedingStatusId: esfStatusItem.id,
					isDataValid: isDataValid,
					penId: utps.length > 0 ? utps[0].penId : undefined,
					order: undefined,
				});
			}
		}
	}

	return { data: data };
}

export function getCycleDays(
	stemAnimalId: string,
	workListSettings: AllAnimalsEsfListSetting,
	validationSettings: number,
) {
	const state = StoreContainer.getState();
	const pregnancyEvents = state.pregnancyEvents.entities[stemAnimalId];
	if (pregnancyEvents === undefined) {
		return undefined;
	}

	const filteredPregnancyEvents = pregnancyEvents.filter(event => {
		if (event.stemAnimalId === stemAnimalId) {
			if (event.state === PregnancyState.Scanned) {
				const scanned = event as Scanned;
				if (scanned.result !== ScanResult.False) {
					return false;
				}
			}
			if (event.state === PregnancyState.Averted) {
				const scanned = event as Averted;
				if (scanned.isNursingSow) {
					return false;
				}
			}
			return true;
		}
		return false;
	});

	const lastestPregnancyEvent = sortPregnanciesByDate(filteredPregnancyEvents)[0];
	let cycleDays = lastestPregnancyEvent ? calculateDays(lastestPregnancyEvent.date!, new Date()) : 0;
	if (lastestPregnancyEvent && lastestPregnancyEvent.state === PregnancyState.Mated) {
		cycleDays = cycleDays - (workListSettings.negativeCycleDays ? validationSettings : 0);
	}
	return cycleDays ? cycleDays : 0;
}
