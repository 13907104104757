import React from 'react';
import { Option } from 'react-dropdown';
import isEqual from 'react-fast-compare';
import { connect } from 'react-redux';
import { AnimalType, GrowthPigDepartureFromPenEventDto, ISection, IStemAnimal, LocationType } from 'shared/api/api';
import { setOneDecimalsAsNumber } from 'shared/helpers/general-helpers';
import {
	generateGrowthPigEventDataForSectionId,
	generateLocationOptionsForGrowthPigEvents,
	getReasonsGrowthPigEvents,
	getSinglePenGrowthPigTuneProductionIfPenEmpty,
	handleGrowthPigPenDepartureEvent,
	validateGrowthPenPigsDepartureEvent,
} from 'shared/helpers/growth-pigs-helper/growth-pig-event-function-helper';
import {
	DefaultGrowthTypeDepartureTypeOption,
	getGrowthPigPenDepartureInitialState,
	getMaxNumberForAmountForGrowthPigEvent,
	GrowthPigDeparturePenState,
	GrowthPigDepartureProps,
	GrowthPigEventsMapDispatchToProps,
	GrowthPigEventsMapStateToProps,
	GrowthPigNumberProperties,
	GrowthTypeDepartureTypesFromPen,
	upsertGrowthPigDeparturePenEvent,
	UpsertGrowthPigEventAndClose,
} from 'shared/helpers/growth-pigs-helper/growth-pig-event-helper';
import { CheckIfSectionUsesPens, FindHiddenPenOnSection } from 'shared/helpers/location-helper';
import { localized } from 'shared/state/i18n/i18n';
import SkioldFormPenPicker from 'web/view/components/location/location-picker/skiold-form-pen-picker';
import SkioldStableSectionPicker from 'web/view/components/location/location-picker/skiold-stable-section-picker';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldDatePicker } from 'web/view/components/skiold-components/skiold-date-picker/skiold-date-picker';
import { SkioldFormsWrapper } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { SkioldFormIntegerInput } from 'web/view/components/skiold-components/skiold-integer-input/skiold-form-integer-input';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { showAlert, ShowConfirmAlert } from '../../skiold-alert/skiold-alert';
import { SkioldFormDecimalInput } from '../../skiold-components/skiold-decimal-input/skiold-form-decimal-input';
import { SkioldFormDropdown } from '../../skiold-components/skiold-dropdown/skiold-form-dropdown';
import GrowthPigProductionTypePicker from '../../skiold-components/skiold-production-type-picker/skiold-production-type-picker';
import { SkioldTouchableOpacity } from '../../skiold-components/skiold-touchable-opacity';
import { SkioldImage } from '../../utils/svg/skiold-image';
import ForwardArrowGrey from 'shared/assets/src-assets/png/forward_arrow_bottom_grey.png';
import { SkioldModal } from '../../skiold-components/skiold-modal/skiold-modal';
import SearchYoungAnimals from '../sow-events/search-animals/search-young-animals';
import { getYoungAnimals } from 'shared/helpers/stemanimal-helper/stem-animal-input-helper';
import { DepartureTypes } from 'shared/state/models/departure-types';
import { YoungAnimalDepartures } from 'shared/helpers/growth-pigs-helper/growth-pig-event-list-helper';



export class YoungAnimalIdNumberDeparture extends React.PureComponent<
	GrowthPigDepartureProps,
	GrowthPigDeparturePenState
> {
	constructor(props: GrowthPigDepartureProps) {
		super(props);
		const growthPigEventToEdit = this.props.growthPigsEvent;
		this.state = getGrowthPigPenDepartureInitialState(growthPigEventToEdit, props);
	}

	public componentDidMount() {
		this.props.getDeparturedAnimals();
	}

	public componentDidUpdate(prevProps: GrowthPigDepartureProps) {
		if (!isEqual(prevProps.growthPigsEvents, this.props.growthPigsEvents)) {
			this.setState({
				filteredLocation: generateLocationOptionsForGrowthPigEvents(
					this.props.growthPigsEvents,
					this.props.locations,
					this.props.productionType,
				),
			});
		}
		if (!isEqual(prevProps.productionType, this.props.productionType)) {
			const growthPigEventToEdit = this.props.growthPigsEvent;
			this.setState(getGrowthPigPenDepartureInitialState(growthPigEventToEdit, this.props));
		}
	}

	public render() {
		return (
			<ViewWeb className="sow-entrance">
				<SkioldFormsWrapper
					formRows={this.getFormRows()}
					containerClassName="sow-entrance-forms-wrapper-container"
				/>
				{this.renderButtons()}
				{this.renderModal()}
			</ViewWeb>
		);
	}

	public resetComponent() {
		this.setState({ amount: undefined, avgWeight: undefined, youngAnimal: undefined }, () => {
			this.penSelected(this.state.fromPenId!);
		});
	}

	public getProductionRow(): FormRow {
		return {
			name: localized('fromProductionType'),
			component: (
				<GrowthPigProductionTypePicker
					disableYoungFemale={true}
					dontSetToFrats={
						!!(this.state.fromSection && this.state.fromSection.animalType !== AnimalType.FRATS)
					}
					onProductionTypeChanged={
						this.props.growthPigsEvent
							? this.dontChangeFromProductionTypeChanged
							: this.onFromProductionTypeChanged
					}
					usedInsideForm={true}
					disabled={!!this.state.growthPigEvent}
					productionType={
						this.state.fromProductionType ? this.state.fromProductionType : this.props.productionType
					}
				/>
			),
		};
	}

	private dontChangeFromProductionTypeChanged = () => {};

	private onFromProductionTypeChanged = (productionType: AnimalType) => {
		const filteredLocations = generateLocationOptionsForGrowthPigEvents(
			this.props.growthPigsEvents,
			this.props.locations,
			this.props.productionType,
		);

		if (this.state.fromSection && this.state.fromSection.id) {
			const section = this.props.hashmapLocations[this.state.fromSection.id] as ISection;
			const data = generateGrowthPigEventDataForSectionId(
				section,
				this.props.growthPigsEvents,
				this.props.locations,
				this.props.productionType,
				productionType,
				this.state.date ? this.state.date : new Date(),
			);

			if (data.length === 0) {
				this.setState({
					fromProductionType: productionType,
					filteredLocation: filteredLocations,
					fromSection: undefined,
					data,
				});
			} else {
				this.setState({ fromProductionType: productionType, filteredLocation: filteredLocations, data });
			}
		} else {
			this.setState({ fromProductionType: productionType, filteredLocation: filteredLocations });
		}
	};

	private getFormRows() {
		let formRows = new Array<FormRow>();

		formRows.push(this.getDateRow('date'));
		formRows.push(this.getProductionRow());
		formRows.push(this.getStableSectionPickerRow());
		if (this.state.fromSection && this.state.fromSection.id) {
			if (CheckIfSectionUsesPens(this.state.fromSection.id)) {
				formRows.push(this.getPenPickerRow());
			}
		}

		formRows.push(this.getAvgWeightRow('avgWeight'));
		if (this.props.nucleusManagement || this.props.generalSettings.registerIdForTurnoverPoolYoungAnimals) {
			formRows.push(this.getIdNumberRow());
		}
		formRows.push(this.getDepartureTypeRow());
		formRows.push(this.getReasonsRow());
		return formRows;
	}

	private getReasonsRow(): FormRow {
		return {
			name: localized('reasons'),
			component: (
				<SkioldFormDropdown
					items={getReasonsGrowthPigEvents(
						this.state.departureTypeOption,
						this.props.reasons,
						this.props.profile!,
						this.props.productionType,
					)}
					itemFromParent={'departureReasonIdOption'}
					selectedValue={this.state.departureReasonIdOption}
					onValueChanged={this.OnPropertyChanged}
				/>
			),
		};
	}

	private getDepartureTypeRow(): FormRow {
		return {
			name: localized('departureType'),
			component: (
				<SkioldFormDropdown
					items={YoungAnimalDepartures(this.props.productionType)}
					selectedValue={this.state.departureTypeOption}
					onValueChanged={this.departureTypeChanged}
				/>
			),
		};
	}

	private departureTypeChanged = (option: Option) => {
		this.setState({ departureTypeOption: option });
	};

	private OnPropertyChanged = (newText, ItemFromParent: string) => {
		const state = { ...this.state };
		state[ItemFromParent] = newText;
		this.setState({ ...state });
	};

	private onStableSectionChanged = (selectedSectionId: string) => {
		if (this.state.growthPigEvent) {
			return;
		}

		const section = this.props.hashmapLocations[selectedSectionId] as ISection;
		const data = generateGrowthPigEventDataForSectionId(
			section,
			this.props.growthPigsEvents,
			this.props.locations,
			this.props.productionType,
			this.state.fromProductionType,
			this.state.date ? this.state.date : new Date(),
		);

		if (CheckIfSectionUsesPens(selectedSectionId)) {
			const totalWeightCurve = data.reduce(
				(sum, current) => sum + (current.totalWeightCurve !== undefined ? current.totalWeightCurve : 0),
				0,
			);
			const amount = data.reduce((sum, current) => sum + (current.amount !== undefined ? current.amount : 0), 0);
			this.setState({
				fromPenId: '',
				fromSection: section,
				data,
				avgWeight: totalWeightCurve && amount ? totalWeightCurve / amount : this.state.avgWeight,
			});
			if (amount <= 0) {
				showAlert(localized('choosenLocationIsEmpty'));
			}
		} else if (section === undefined) {
			this.setState({ fromPenId: undefined, fromSection: undefined });
		} else {
			let penId = FindHiddenPenOnSection(selectedSectionId, this.state.fromPenId);
			const totalWeightCurve = data.reduce(
				(sum, current) => sum + (current.totalWeightCurve !== undefined ? current.totalWeightCurve : 0),
				0,
			);
			const amount = data.reduce((sum, current) => sum + (current.amount !== undefined ? current.amount : 0), 0);
			if (penId) {
				this.setState({
					fromPenId: penId,
					fromSection: section,
					data,
					avgWeight: totalWeightCurve && amount ? totalWeightCurve / amount : this.state.avgWeight,
				});
			}
			if (amount <= 0) {
				showAlert(localized('choosenLocationIsEmpty'));
			}
		}
	};

	private penSelected = (selectedPenId: string) => {
		if (this.state.growthPigEvent) {
			return;
		}
		const data = generateGrowthPigEventDataForSectionId(
			this.state.fromSection!,
			this.props.growthPigsEvents,
			this.props.locations,
			this.props.productionType,
			this.state.fromProductionType,
			this.state.date ? this.state.date : new Date(),
		).filter(a => a.penId === selectedPenId || selectedPenId.length === 0);
		const totalWeightCurve = data.reduce(
			(sum, current) => sum + (current.totalWeightCurve !== undefined ? current.totalWeightCurve : 0),
			0,
		);

		const amount = data.reduce((sum, current) => sum + (current.amount !== undefined ? current.amount : 0), 0);

		this.setState({
			fromPenId: selectedPenId.length === 0 ? undefined : selectedPenId,
			avgWeight: totalWeightCurve && amount ? totalWeightCurve / amount : this.state.avgWeight,
			amount: this.state.youngAnimal ? 1 : undefined,
			data,
		});
		if (amount <= 0) {
			showAlert(localized('choosenLocationIsEmpty'));
		}
	};

	private getAvgWeightRow = (property: GrowthPigNumberProperties): FormRow => {
		return {
			name: localized(property as string),
			component: (
				<SkioldFormDecimalInput
					text={this.state[property] ? setOneDecimalsAsNumber(this.state[property]) : undefined}
					itemFromParent={property}
					onChangeNumber={this.OnPropertyChanged}
				/>
			),
		};
	};

	private OnDateChanged = (date: Date) => {
		const state = { ...this.state };
		state.date = date;
		if (this.state.fromSection) {
			state.data = generateGrowthPigEventDataForSectionId(
				this.state.fromSection,
				this.props.growthPigsEvents,
				this.props.locations,
				this.props.productionType,
				this.state.fromProductionType,
				date,
			);
		}
		if (this.state.fromPenId && this.state.fromPenId.length !== 0) {
			const filteredData = state.data.filter(a => a.penId === this.state.fromPenId);
			const totalWeightCurve = filteredData.reduce(
				(sum, current) => sum + (current.totalWeightCurve !== undefined ? current.totalWeightCurve : 0),
				0,
			);
			const amount = filteredData.reduce(
				(sum, current) => sum + (current.amount !== undefined ? current.amount : 0),
				0,
			);
			state.avgWeight = amount !== 0 ? totalWeightCurve / amount : 0;
		} else {
			const totalWeightCurve = state.data.reduce(
				(sum, current) => sum + (current.totalWeightCurve !== undefined ? current.totalWeightCurve : 0),
				0,
			);
			const amount = state.data.reduce(
				(sum, current) => sum + (current.amount !== undefined ? current.amount : 0),
				0,
			);
			state.avgWeight = amount !== 0 ? totalWeightCurve / amount : 0;
		}
		this.setState({ ...state });
	};

	private close = () => {
		if (this.props.closeEditModal) {
			this.props.closeEditModal();
		}
	};

	private openModal = () => {
		if (!this.state.amount || this.state.amount <= 1) {
			this.setState({ chooseIdModalIsOpen: true });
		} else {
			showAlert(localized('growthPigGreaterThan1'));
		}
	};
	private closeModal = () => this.setState({ chooseIdModalIsOpen: false });
	private setYoungAnimal = (youngAnimal: IStemAnimal | undefined) => {
		this.setState({
			chooseIdModalIsOpen: false,
			youngAnimal,
			amount: youngAnimal ? 1 : undefined,
		});
	};

	private getDateRow = (property: 'date'): FormRow => {
		return {
			name: localized('Date'),
			component: (
				<SkioldDatePicker
					onDateChanged={this.OnDateChanged}
					itemFromParent={property}
					selectedDate={this.state[property]}
					theme={'dark'}
					color={'grey'}
					backwardDateSetEndTime={true}
				/>
			),
		};
	};

	private getStableSectionPickerRow(): FormRow {
		return {
			name: localized('StableSection'),
			component: (
				<SkioldStableSectionPicker
					usedInsideForm={true}
					disabled={!!this.state.growthPigEvent}
					sectionId={this.state.fromSection && this.state.fromSection.id}
					locationType={
						this.state.fromProductionType === AnimalType.FRATS
							? this.props.productionType === AnimalType.Weaner
								? LocationType.Weaners
								: LocationType.Finisher
							: undefined
					}
					filteredLocations={this.state.filteredLocation}
					AnimalType={this.state.fromProductionType}
					onStableSectionSelected={this.onStableSectionChanged}
				/>
			),
		};
	}
	private getPenPickerRow(): FormRow {
		return {
			name: localized('Pen'),
			component: (
				<SkioldFormPenPicker
					usedInsideForm={true}
					disabled={!!this.state.growthPigEvent}
					onPenSelected={this.penSelected}
					sectionId={this.state.fromSection && this.state.fromSection.id}
					filteredLocations={this.props.growthPigsEvent ? undefined : this.state.filteredLocation}
					penId={this.state.fromPenId}
					resetIfUndefined={true}
				/>
			),
		};
	}

	private renderButtons() {
		return (
			<>
				{!this.state.growthPigEvent ? (
					<ViewWeb className="button-view-style">
						<SkioldButton title={localized('Save')} onPress={this.save} />
					</ViewWeb>
				) : (
					<ViewWeb className="button-view-style">
						<SkioldButton title={localized('Save')} onPress={this.upsertEvent} />
						<SkioldButton theme="grey" title={localized('Close')} onPress={this.close} />
						<SkioldButton theme="grey" title={localized('Delete')} onPress={this.deleteEvent} />
					</ViewWeb>
				)}
			</>
		);
	}

	private getIdNumberRow(): FormRow {
		return {
			name: localized('idNumber'),
			component: (
				<ViewWeb className="margin-right-eight cursor-pointer" onClick={this.openModal}>
					<ViewWeb className="display-flex-direction-row">
						<TextWeb className="padding-right-five-font-size-sixteen">
							{this.state.youngAnimal?.idNumber || localized('ChooseIdNumber')}
						</TextWeb>
						<ViewWeb className="picker-young-animals-view-style">
							<SkioldImage
								width={18}
								height={18}
								imageData={ForwardArrowGrey}
								className="image-picker-view-style"
							/>
						</ViewWeb>
					</ViewWeb>
				</ViewWeb>
			),
		};
	}

	private renderModal() {
		return (
			<SkioldModal isOpen={this.state.chooseIdModalIsOpen} close={this.closeModal}>
				<SearchYoungAnimals close={this.setYoungAnimal} youngAnimals={getYoungAnimals()} />
			</SkioldModal>
		);
	}

	private upsertEvent = async () => {
		if (this.state.growthPigEvent) {
			await upsertGrowthPigDeparturePenEvent(this.props, this.state, ShowConfirmAlert);
		}
	};

	private deleteEvent = async () => {
		const growthPigEventToEdit = this.props.growthPigsEvent;
		if (growthPigEventToEdit) {
			growthPigEventToEdit.isDeleted = true;
			if (growthPigEventToEdit.animalIdNumber !== undefined) {
				const stemAnimal = this.props.departedAnimals.find(
					animal =>
						(animal as IStemAnimal).siteId === growthPigEventToEdit.siteId &&
						(animal as IStemAnimal).idNumber === growthPigEventToEdit.animalIdNumber,
				) as IStemAnimal;

				if (stemAnimal) {
					stemAnimal.departureDate = undefined;
					stemAnimal.departureReasonId = undefined;
					stemAnimal.departureType = undefined;
					await this.props.updateYoungAnimal(stemAnimal);
				}
			}

			UpsertGrowthPigEventAndClose(
				growthPigEventToEdit,
				this.props.upsertGrowthPigsEvent,
				this.props.closeEditModal,
			);
		}
	};

	private save = async () => {
		if (validateGrowthPenPigsDepartureEvent(this.state, showAlert, this.state.youngAnimal)) {
			const listToSave: GrowthPigDepartureFromPenEventDto[] = handleGrowthPigPenDepartureEvent(
				this.props,
				this.state,
			);
			if (listToSave.length > 0) {
				this.props.upsertGrowthPigsDepartureFromPenEvents(listToSave);
				// If a location is emptied, save tunepigproduction
				const gpeTuneEmptyPens = getSinglePenGrowthPigTuneProductionIfPenEmpty(
					this.state,
					this.props,
					this.state.amount,
				);
				if (gpeTuneEmptyPens && gpeTuneEmptyPens.length > 0) {
					this.props.upsertMultipleGrowthPigTuneProduction(gpeTuneEmptyPens);
				}
			}
			if (this.state.youngAnimal) {
				let saveYoungAnimal = { ...this.state.youngAnimal };
				saveYoungAnimal.departureReasonId = this.state.departureReasonIdOption?.value;
				saveYoungAnimal.departureDate = this.state.date;
				saveYoungAnimal.departureType = this.state.departureTypeOption.value;
				this.props.updateYoungAnimal(saveYoungAnimal);
			}
			this.resetComponent();
		}
	};
}

export default connect(GrowthPigEventsMapStateToProps, GrowthPigEventsMapDispatchToProps)(YoungAnimalIdNumberDeparture);
