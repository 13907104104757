import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { Dispatch } from 'redux';
import { ITasksInFarrowingBase, WorkListType } from 'shared/api/api';
import * as diagnoseOperation from 'shared/state/ducks/diagnoses/operations';
import * as pregnancyOperation from 'shared/state/ducks/pregnancy-events/operations';
import * as stemAnimalOperation from 'shared/state/ducks/stem-animals/operations';
import { SetCheckedCount, SetSowsCount } from 'shared/state/ducks/stem-animals/operations';
import * as treatmentDefinitionOperation from 'shared/state/ducks/treatment-definitions/operations';
import * as treatmentPlanOperation from 'shared/state/ducks/treatment-plan/operations';
import { SaveMultipleTreatmentPlansByWorkList } from 'shared/state/ducks/treatment-plan/operations';
import * as treatmentOperation from 'shared/state/ducks/treatment/operations';
import * as workListToTreatmentPlanOperation from 'shared/state/ducks/work-list-to-treatment-plan/operations';
import { SharedAppState } from 'shared/state/store.shared';

export class TasksInFarrowingStableItem {
	public cycleDays: number | undefined;
	public key: string | undefined;
	public animalNumber: string | undefined;

	public isChecked: boolean | undefined;
	public farrowingTaskTreatments: FarrowingTaskTreatmentColumn[] | undefined;
	public stemAnimalId: string | undefined;
	public hasRetentionTime: boolean | undefined;
	public order?: number | undefined;
}

// tslint:disable-next-line: max-classes-per-file
export class FarrowingTaskTreatmentColumn {
	public pcs: number | undefined;
	public amount: number | undefined;
	public treatmentDefinitionId: string | undefined;
	public subTaskNumber: number | undefined;
	public used?: boolean;
}

interface PropsFromParent {
	choosedSetting: WorkListType.TasksInFarrowingStableSetting | WorkListType.TasksInFarrowingStableSetting2;
	workListSetting: ITasksInFarrowingBase;
	navigation: NativeStackNavigationProp<{}>;
}

export const TasksInFarrowingStableMapStateToProps = (state: SharedAppState) => {
	return {
		pregnancyEvents: state.pregnancyEvents.entities,
		stemAnimals: state.stemAnimals.entities,
		treatmentDefinitions: state.treatmentDefinitions.entities,
		diagnoses: state.diagnose.entities,
		treatmentPlans: state.treatmentPlans.entities,
		treatments: state.treatments.entities,
		worklistToTreatmentPlans: state.workListToTreatmentPlans.entities,
	};
};

export const TasksInFarrowingStableMapDispatchToProps = (dispatch: Dispatch, props: {}) => {
	return {
		setSowsCount: (count: number) => SetSowsCount(count)(dispatch),
		SetCheckedCount: (count: number, reset?: boolean) => SetCheckedCount(count, reset)(dispatch),
		saveMultipleTreatmentPlansByWorkList: (setting: ITasksInFarrowingBase, items: TasksInFarrowingStableItem[]) =>
			SaveMultipleTreatmentPlansByWorkList(setting, items)(dispatch),
		pregnancyEventGetSyncData: () => pregnancyOperation.GetSyncData()(dispatch),
		stemAnimalGetSyncData: () => stemAnimalOperation.GetSyncData()(dispatch),
		treatmentGetSyncData: () => treatmentOperation.GetSyncData()(dispatch),
		treatmentPlanGetSyncData: () => treatmentPlanOperation.GetSyncData()(dispatch),
		treatmentDefinitionGetSyncData: () => treatmentDefinitionOperation.GetSyncData()(dispatch),
		workListToTreatmentPlanGetSyncData: () => workListToTreatmentPlanOperation.GetSyncData()(dispatch),
		diagnoseGetSyncData: () => diagnoseOperation.GetSyncData()(dispatch),
	};
};

export interface TasksInFarrowingStableState {
	columns: any[];
	columnExte: any[];
	loading: boolean;
	workListData: TasksInFarrowingStableItem[];
	createTreatmentModal: boolean;
	stemAnimalIdToEdit: string;
	commitAll: boolean;
}

export type TasksInFarrowingStableProps = ReturnType<typeof TasksInFarrowingStableMapStateToProps> &
	ReturnType<typeof TasksInFarrowingStableMapDispatchToProps> &
	PropsFromParent;
