import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AnalysisDepartureSowTableData, SkioldOneClient } from 'shared/api/api';
import PrinterGreyIcon from 'shared/assets/src-assets/png/printer_ikon_grey.png';
import { SkioldDigitalApiBaseUrl } from 'shared/constants';
import { getReasonNameById } from 'shared/helpers/reason-helper/reason-helper';
import { RefType } from 'shared/helpers/ref-type';
import { GetAnalysisResultPerLitterData } from 'shared/state/ducks/analysis/results-per-litter/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { GetAnalysisDepartureSowPdf } from 'web/web-helpers/pdf-helper/analysis-pdf-helpers/analysis-pdf-helper';
import { SkioldIconSpinner } from '../../utils/skiold-icon-spinner';
import { AnalysisDepartureTypeSowTable } from './analysis-departure-type-sow-table';
import { AnalysisDepartureReasonSowTable } from './analysis-departure-reason-sow-table';
import { ClipLoader } from 'react-spinners';
import { SkioldCheckbox } from '../../skiold-components/skiold-checkbox/skiold-checkbox';
import { WhiteText } from '../../Text/white-text';
import { SkioldFetch } from '../../skiold-components/skiold-fetch/skiold-fetch';
import { DepartureTypes } from 'shared/state/models/departure-types';

interface PropsFromParent {
	topRight?: (topRight: JSX.Element) => void;
	topLeft?: (topLeft: JSX.Element) => void;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		siteId: state.profile.active!.siteId,
		profile: state.profile.active!,
		loading: state.dashboardOverview.loading,
		dashboardSetting: state.dashboardSettings.entity,
		matingBatches: state.matingBatches.entities,
		language: state.profile.active && state.profile.active.language ? state.profile.active.language : 'en',
		reasons: state.reasons.entities,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
	return {
		getAnalysisResultPerLitterData: (fromDate?: Date, toDate?: Date) =>
			GetAnalysisResultPerLitterData(fromDate, toDate)(dispatch),
	};
};

export interface State {
	tableData: AnalysisDepartureSowTableData | undefined;
	fetching: boolean;
	asPercentage: boolean;
	dateFrom?: Date;
	dateTo?: Date;
	slaughtered: boolean;
	dead: boolean;
	putDown: boolean;
	sold: boolean;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

export class AnalysisDepartureOverview extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			tableData: undefined,
			fetching: false,
			asPercentage: false,
			slaughtered: true,
			dead: true,
			putDown: true,
			sold: true,
		};
	}

	public componentDidMount() {
		if (this.props.topRight) {
			this.props.topRight(this.renderTopRight());
		}
		if (this.props.topLeft) {
			this.fetchData(this.state.dateFrom, this.state.dateTo);
			this.props.topLeft(this.renderTopLeft());
		}
	}

	public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
		if (this.props.topLeft) {
			this.props.topLeft(this.renderTopLeft());
		}
	}

	public render() {
		return (
			<ViewWeb>
				{!this.state.fetching ? (
					<>
						<AnalysisDepartureTypeSowTable
							tableData={this.state.tableData}
							asPercentage={this.state.asPercentage}
						/>
						<AnalysisDepartureReasonSowTable
							tableData={this.state.tableData}
							asPercentage={this.state.asPercentage}
						/>
					</>
				) : (
					<ViewWeb className="spinner-container-active-analysis">
						<ClipLoader color="#f2ac40" size={70} />
					</ViewWeb>
				)}
			</ViewWeb>
		);
	}

	public renderTopLeft = () => {
		return (
			<ViewWeb className="vertical-center flex-row width-100-percent">
				<SkioldFetch
					defaultEmpty={true}
					toDate={this.state.dateTo}
					fromDate={this.state.dateFrom}
					fetchData={this.fetchData}
					toDateChange={this.setToDate}
					fromDateChange={this.setfromDate}
					isControlled={true}
				/>
				<ViewWeb className="align-center checkbox-wrapper">
					<ViewWeb className="flex-row">
						<WhiteText>{localized('Count')}</WhiteText>
						<SkioldCheckbox isChecked={!this.state.asPercentage} onClick={this.setPercentageFalse} />
						<WhiteText>{localized('Percentage')}</WhiteText>
						<SkioldCheckbox
							className="checkbox-margin"
							isChecked={this.state.asPercentage}
							onClick={this.setPercentageTrue}
						/>
					</ViewWeb>
					<ViewWeb className="flex-row margin-top-15">
						<ViewWeb className="flex-row checkbox-margin">
							<WhiteText>{localized('departureTypeKilled')}:</WhiteText>
							<SkioldCheckbox
								className="checkbox-margin"
								isChecked={this.state.slaughtered}
								onClick={this.setSlaughtered}
							/>
						</ViewWeb>
						<ViewWeb className="flex-row checkbox-margin">
							<WhiteText>{localized('departureTypeDead')}:</WhiteText>
							<SkioldCheckbox isChecked={this.state.dead} onClick={this.setDead} />
						</ViewWeb>
						<ViewWeb className="flex-row checkbox-margin">
							<WhiteText>{localized('departureTypePutDown')}:</WhiteText>
							<SkioldCheckbox isChecked={this.state.putDown} onClick={this.setPutDown} />
						</ViewWeb>
						<ViewWeb className="flex-row checkbox-margin">
							<WhiteText>{localized('departureTypeSold')}:</WhiteText>
							<SkioldCheckbox isChecked={this.state.sold} onClick={this.setSold} />
						</ViewWeb>
					</ViewWeb>
				</ViewWeb>
			</ViewWeb>
		);
	};

	public renderTopRight = () => {
		return (
			<ViewWeb className="flex-row alignItemsCenter">
				<SkioldIconSpinner title={localized('PrintList')} icon={PrinterGreyIcon} onPress={this.printData} />
			</ViewWeb>
		);
	};

	public fetchData = (dateFrom?: Date, dateTo?: Date) => {
		if (dateFrom && dateTo) {
			let allowDepartureTypes: string[] = [];
			if (this.state.slaughtered) {
				allowDepartureTypes.push(DepartureTypes.departureTypeKilled);
			}
			if (this.state.dead) {
				allowDepartureTypes.push(DepartureTypes.departureTypeDead);
			}
			if (this.state.putDown) {
				allowDepartureTypes.push(DepartureTypes.departureTypePutDown);
			}
			if (this.state.sold) {
				allowDepartureTypes.push(DepartureTypes.departureTypeSold);
			}
			this.setState({ fetching: true });
			new SkioldOneClient(SkioldDigitalApiBaseUrl)
				.analysis_AnalysisDepartureSow(
					this.props.siteId,
					this.state.asPercentage,
					dateFrom,
					dateTo,
					allowDepartureTypes.join(','),
				)
				.then((tableData: AnalysisDepartureSowTableData) => {
					if (tableData) {
						if (tableData.departureReason) {
							const reasonKeys = Object.keys(tableData.departureReason);
							reasonKeys.forEach(key => {
								tableData.departureReason![key].name = getReasonNameById(
									tableData.departureReason![key].name,
									this.props.language,
									this.props.reasons,
								);
							});
						}
						if (tableData.departureType) {
							const typeKeys = Object.keys(tableData.departureType);
							typeKeys.forEach(key => {
								tableData.departureType![key].name = localized(tableData.departureType![key].name!);
							});
						}
					}
					this.setState({ tableData, fetching: false });
				})
				.catch(() => {
					this.setState({ fetching: false });
					return;
				});
		}
	};

	private setPercentageFalse = () => {
		this.setState({ asPercentage: false });
	};

	private setPercentageTrue = () => {
		this.setState({ asPercentage: true });
	};

	private setToDate = (toDate: Date) => {
		this.setState({ dateTo: toDate });
	};

	private setfromDate = (fromDate: Date) => {
		this.setState({ dateFrom: fromDate });
	};

	private setSlaughtered = () => {
		this.setState({ slaughtered: !this.state.slaughtered });
	};

	private setDead = () => {
		this.setState({ dead: !this.state.dead });
	};

	private setPutDown = () => {
		this.setState({ putDown: !this.state.putDown });
	};
	private setSold = () => {
		this.setState({ sold: !this.state.sold });
	};

	private printData = () => {
		if (this.state.tableData) {
			GetAnalysisDepartureSowPdf(
				this.state.tableData,
				localized('analysisDepartureSow') + '.pdf',
				this.props.profile.siteId!,
				this.state.asPercentage,
				Intl.DateTimeFormat().resolvedOptions().timeZone,
				this.props.profile.language,
			);
		}
	};
}

export default connect<ReturnType<typeof mapStateToProps>, ReturnType<typeof mapDispatchToProps>, RefType, WebAppState>(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{
		forwardRef: true,
	},
)(AnalysisDepartureOverview);
