import React, { FC, useCallback, useState } from 'react';
import { ViewWeb } from '../utils/web-view';
import {
	NucleusManagementConnectionCreationDTO,
	NucleusManagementConnectionUpdateDTO,
	NucleusManagementProvider,
} from '../../../../shared/api/api';
import { SkioldButton } from '../skiold-components/skiold-button/skiold-button';
import { localized } from '../../../../shared/state/i18n/i18n';
import './login-settings-nucleus-management-connection.scss';
import { FormRow } from '../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { SkioldFormsWrapper } from '../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { SkioldFormInput } from '../skiold-components/skiold-input/skiold-form-input';
import { useDispatch, useSelector } from 'react-redux';
import { WebAppState } from '../../../state/store.web';
import {
	createNucleusManagementConnection,
	updateNucleusManagementConnection,
} from '../../../../shared/state/ducks/nucleus-management-connections/operation';

interface Props {
	provider: NucleusManagementProvider;
	closeModal: () => void;
}

export const LoginSettingsNucleusManagementConnection: FC<Props> = React.memo(({ provider, closeModal }) => {
	const dispatch = useDispatch();
	const activeConnection = useSelector((state: WebAppState) =>
		state.nucleusManagementConnections.connections.find(c => c.provider === provider),
	);
	const siteId = useSelector((state: WebAppState) => state.site.site.id);

	const [usernameLocal, setUsernameLocal] = useState(activeConnection?.username ?? '');
	const [passwordLocal, setPasswordLocal] = useState(activeConnection?.password ? 'placeholderDefaultPass' : '');

	const handleUsernameChange = useCallback((nextText: string) => {
		setUsernameLocal(nextText);
	}, []);

	const handlePasswordChange = useCallback((nextText: string) => {
		setPasswordLocal(nextText);
	}, []);

	const loginFormRows: FormRow[] = [
		{ header: localized('loginInformation') },
		{
			name: localized('UserName'),
			component: <SkioldFormInput text={usernameLocal} onChangeText={handleUsernameChange} />,
		},
		{
			name: localized('password'),
			component: <SkioldFormInput text={passwordLocal} onChangeText={handlePasswordChange} type={'password'} />,
		},
	];

	const createConnection = useCallback(() => {
		if (siteId === undefined) {
			return;
		}

		const creationDTO = new NucleusManagementConnectionCreationDTO({
			siteId: siteId,
			username: usernameLocal,
			password: passwordLocal,
			provider,
		});
		dispatch(createNucleusManagementConnection(creationDTO));
		closeModal();
	}, [siteId, usernameLocal, passwordLocal, provider, dispatch, closeModal]);

	const updateLogin = useCallback(() => {
		if (activeConnection?.id === undefined) {
			return;
		}

		const updateDTO = new NucleusManagementConnectionUpdateDTO({
			connectionId: activeConnection.id,
			username: usernameLocal,
			password: passwordLocal,
		});
		dispatch(updateNucleusManagementConnection(updateDTO));
		closeModal();
	}, [usernameLocal, passwordLocal, dispatch, activeConnection, closeModal]);

	return (
		<ViewWeb className={'login-settings-container'}>
			<SkioldFormsWrapper formRows={loginFormRows} />
			<ViewWeb className="button-view-style">
				<SkioldButton theme="grey" title={localized('Close')} onPress={closeModal} />
				{activeConnection === undefined ? (
					<SkioldButton title={localized('Login')} onPress={createConnection} />
				) : (
					<SkioldButton title={localized('UpdateLogin')} onPress={updateLogin} />
				)}
			</ViewWeb>
		</ViewWeb>
	);
});
