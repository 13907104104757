import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import './nucleus-management-breeding-settings.scss';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { useDispatch, useSelector } from 'react-redux';
import { BreedingNumberInput } from './breeding-number-input';
import { WebAppState } from '../../../../../state/store.web';
import { ViewWeb } from '../../../../components/utils/web-view';
import { localized } from '../../../../../../shared/state/i18n/i18n';
import {
	INucleusManagementBreedingSettings,
	NucleusManagementBreedingSettings,
	NucleusManagementProvider,
} from '../../../../../../shared/api/api';
import {
	getBreedingSettings,
	upsertManyBreedingSettings,
} from '../../../../../../shared/state/ducks/nucleus-management-breeding-settings/operation';
import { SkioldTouchableOpacity } from '../../../../components/skiold-components/skiold-touchable-opacity';
import { SkioldButton } from '../../../../components/skiold-components/skiold-button/skiold-button';
import { getProvider } from '../nucleus-management-helper';
import { getDanBredBreeds, getSkioldBreeds, getUniqueDadBreeds } from '../../../../../../shared/state/ducks/breed-table-items/operation';
import { checkIfDuplicateExists } from 'shared/helpers/general-helpers';
import { showDefaultAlert } from 'web/view/components/skiold-alert/skiold-alert';

export const NucleusManagementBreedingSettingsSetup: FC = React.memo(() => {
	const dispatch = useDispatch();
	const activeSite = useSelector((state: WebAppState) => state.site.site);
	const breedingSettings = useSelector(
		(state: WebAppState) => state.nucleusManagementBreedingSettings.breedingSettings,
	);
	const provider = useMemo(() => getProvider(activeSite.activeLicences), [activeSite]);

	const [breedingSettingsLocal, setBreedingSettingsLocal] =
		useState<INucleusManagementBreedingSettings[]>(breedingSettings);

	useEffect(() => {
		setBreedingSettingsLocal(breedingSettings);
	}, [breedingSettings]);

	useEffect(() => {
		if (activeSite?.id === undefined) {
			return;
		}

		dispatch(getBreedingSettings(activeSite.id));
	}, [activeSite, dispatch]);

	useEffect(() => {
		if (provider === NucleusManagementProvider.DanishGenetics) {
			dispatch(getUniqueDadBreeds());
			return;
		}
		if (provider === NucleusManagementProvider.DanBred) {
			dispatch(getDanBredBreeds());
			return;
		}
		dispatch(getSkioldBreeds());
	}, [dispatch, provider]);

	const validate = useCallback(() => {
		let isValid = true;
		if (breedingSettingsLocal) {
			breedingSettingsLocal.every(setting => {
				if (!setting.breedingNumber) {
					showDefaultAlert(localized('ValidateBreedingNumber'));
					isValid = false;
				} else if (setting.breedingNumber.length < 3 || setting.breedingNumber.length > 4) {
					showDefaultAlert(localized('ValidateBreedingNumber'));
					isValid = false;
				} else if (setting.numberBatches) {
					let raceList: string[] = [];
					setting.numberBatches.every(nb => {
						if (nb.race) {
							raceList.push(nb.race);
						}
						if (!nb.race) {
							showDefaultAlert(localized('ValidateRace'));
							isValid = false;
						} else if (!nb.boarAssignable && !nb.sowAssignable) {
							showDefaultAlert(localized('ValidateGender'));
							isValid = false;
						} else if (!nb.startMatingNumber) {
							showDefaultAlert(localized('ValidateNumberBatc2Start'));
							isValid = false;
						} else if (!nb.endMatingNumber) {
							showDefaultAlert(localized('ValidateNumberBatc2End'));
							isValid = false;
						} else if (!nb.nextMatingNumber) {
							showDefaultAlert(localized('ValidateCurrentNumberBatch'));
							isValid = false;
						} else if (!nb.purchaseNumber) {
							showDefaultAlert(localized('ValidateNumberBatc1Start'));
							isValid = false;
						}
						if (checkIfDuplicateExists(raceList)) {
							if (!nb.priority) {
								showDefaultAlert(localized('ValidatePriority'));
								isValid = false;
							}
						}
					});
				}

				return isValid;
			});
		}
		return isValid;
	}, [breedingSettingsLocal]);

	const addBreedingNumber = useCallback(() => {
		const newSetting = NucleusManagementBreedingSettings.fromJS({});
		newSetting.siteId = activeSite.id;
		setBreedingSettingsLocal(prevState => [...prevState, newSetting]);
	}, [activeSite]);

	const saveBreedingSettings = useCallback(() => {
		const s = validate();
		if (!s) {
			return;
		}

		dispatch(upsertManyBreedingSettings(breedingSettingsLocal));
	}, [breedingSettingsLocal, dispatch, validate]);

	return (
		<ViewWeb className={'breeding-settings-container'}>
			{breedingSettingsLocal.length !== 0 && (
				<ViewWeb className={'button-container align-self-start'}>
					<SkioldButton title={localized('Save')} onPress={saveBreedingSettings} />
				</ViewWeb>
			)}
			<ViewWeb className={'title-top-row'}>
				<TextWeb className='font-22'>{localized('SetupBreedingNumber')}</TextWeb>
				<SkioldButton onPress={addBreedingNumber} title={localized('CreateBreedingNr')}/>
				
			</ViewWeb>
			{breedingSettingsLocal.map((breedingSetting, index) => (
				<BreedingNumberInput
					key={index}
					BreedingSettings={breedingSetting}
					index={index}
					setBreedingSettings={setBreedingSettingsLocal}
				/>
			))}
		</ViewWeb>
	);
});
