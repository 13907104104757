import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AnimalType, IReasonDto, ReasonSettings } from 'shared/api/api';
import { ModulesIds } from 'shared/constants';
import { deepCopy } from 'shared/helpers/general-helpers';
import { GetSyncData as ReasonsGetSyncData, SaveReasonSettings } from 'shared/state/ducks/reasons/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { SkioldCheckbox } from 'web/view/components/skiold-components/skiold-checkbox/skiold-checkbox';
import SkioldTableGrid from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { GroupedHeader } from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid-grouped-header';
import { ViewWeb } from 'web/view/components/utils/web-view';
import './reason-settings.scss';

const mapStateToProps = (state: WebAppState) => {
	return {
		reasons: state.reasons.entities,
		siteId: state.profile.active!.siteId,
		profile: state.profile.active,
		navigation: state.navigation,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
	return {
		getReasons: () => ReasonsGetSyncData()(dispatch),
		saveReasonSetting: (reason: IReasonDto) => SaveReasonSettings(reason)(dispatch),
	};
};

export interface ReasonSettingsState {
	reasons: IReasonDto[];
}

interface TableColumnsType {
	Header: JSX.Element;
	columns: ColumnType[];
}

interface ColumnType {
	id: string;
	Header?: string;
	show?: boolean;
	filterable?: boolean;
	accessor: (d: IReasonDto) => JSX.Element | string | 0;
}

interface PropsFromParent {
	animalType: AnimalType;
}

type ReasonSettingsProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;
export class ReasonSetting extends React.PureComponent<ReasonSettingsProps, ReasonSettingsState> {
	constructor(props: ReasonSettingsProps) {
		super(props);

		this.state = {
			reasons: [],
		};

		this.props.getReasons();
	}

	public UNSAFE_componentWillReceiveProps(nextProps: ReasonSettingsProps) {
		if (nextProps !== this.props) {
			this.setState({ reasons: nextProps.reasons });
		}
	}
	public render() {
		const columns = this.generateColumns();
		this.generateMissingReasonSettings();
		const sorted = this.props.reasons.sort((a, b) => {
			return a.reason!.priorityCode! - b.reason!.priorityCode!;
		});
		return (
			<ViewWeb className="reason-settings">
				<SkioldTableGrid
					columns={columns}
					ColumnExtensions={this.generateColumnsExtensions()}
					tableKey={'reasonSettings' + this.props.navigation.query!.type}
					className={'work-list-table'}
					groupedColumns={this.groupedColumns()}
					sortable={false}
					data={sorted}
				/>
				{/* <SkioldTable
					columns={columns}
					data={this.props.reasons}
					sortHeaderId={'code'}
					className="reason-setting-table"
				/> */}
			</ViewWeb>
		);
	}

	private groupedColumns = (): GroupedHeader[] => {
		return [
			{
				title: '',
				children: [{ columnName: 'reasonName' }],
			},
			{
				title: localized('piglets'),
				children: [{ columnName: 'pigletDead' }, { columnName: 'pigletPutDown' }],
			},
			{
				title: `${localized('Sows')}-${localized('boars')}-${localized('polts')}`,
				children: [{ columnName: 'stemAnimalDeparture' }, { columnName: 'stemAnimalDead' }],
			},
			{
				title: localized('Weaners'),
				children: [{ columnName: 'weanerDead' }, { columnName: 'weanerPutDown' }, { columnName: 'weanerSold' }],
			},
			{
				title: localized('Finisher'),
				children: [
					{ columnName: 'finisherDead' },
					{ columnName: 'finisherPutDown' },
					{ columnName: 'finisherSold' },
					{ columnName: 'finisherSlaughtered' },
				],
			},
		];
	};

	public generateColumnsExtensions() {
		let columnExtensions: any = [
			{
				columnName: 'reasonName',
				width: 200,
			},
		];

		if (this.props.animalType === AnimalType.Sow) {
			columnExtensions = columnExtensions.concat([
				{
					columnName: 'pigletDead',
					width: 200,
					filteringEnabled: false,
				},
				{
					columnName: 'pigletPutDown',
					width: 200,
					filteringEnabled: false,
				},
				{
					columnName: 'stemAnimalDeparture',
					width: 200,
					filteringEnabled: false,
				},
				{
					columnName: 'stemAnimalDead',
					width: 200,
					filteringEnabled: false,
				},
			]);
		} else {
			if (this.props.profile && this.props.profile.modules) {
				if (this.props.profile.modules.find(p => p === ModulesIds.ClimatePig)) {
					columnExtensions.push({
						columnName: 'weanerDead',
						width: 200,
						filteringEnabled: false,
					});
					columnExtensions.push({
						columnName: 'weanerPutDown',
						width: 200,
						filteringEnabled: false,
					});
					columnExtensions.push({
						columnName: 'weanerSold',
						width: 200,
						filteringEnabled: false,
					});
				}
				if (this.props.profile.modules.find(p => p === ModulesIds.FinisherModule)) {
					columnExtensions.push({
						columnName: 'finisherDead',
						width: 200,
						filteringEnabled: false,
					});
					columnExtensions.push({
						columnName: 'finisherPutDown',
						width: 200,
						filteringEnabled: false,
					});
					columnExtensions.push({
						columnName: 'finisherSold',
						width: 200,
						filteringEnabled: false,
					});
					columnExtensions.push({
						columnName: 'finisherSlaughtered',
						width: 200,
						filteringEnabled: false,
					});
				}
			}
		}

		return columnExtensions;
	}

	private readonly getCodeCell = (d: IReasonDto) => (d.reason !== undefined ? d.reason.priorityCode!.toString() : 0);
	private readonly getNameCell = (d: IReasonDto) =>
		d.reason !== undefined && d.reason.name
			? d.reason.name[this.props.profile && this.props.profile.language ? this.props.profile.language : 'da']
			: '';

	private generateColumns() {
		let generateColumns: any[] = [
			{
				name: 'reasonName',
				title: localized('reason'),
				getCellValue: this.getNameCell,
			},
		];

		if (this.props.animalType === AnimalType.Sow) {
			generateColumns = generateColumns.concat(this.generateStemAnimalColumn());
			generateColumns = generateColumns.concat(this.generatePigletColumn());
		} else {
			if (this.props.profile && this.props.profile.modules) {
				if (this.props.profile.modules.find(p => p === ModulesIds.ClimatePig)) {
					generateColumns = generateColumns.concat(this.generateWeanerReasonColumn());
				}
				if (this.props.profile.modules.find(p => p === ModulesIds.FinisherModule)) {
					generateColumns = generateColumns.concat(this.generateFinisherReasonColumn());
				}
			}
		}
		return generateColumns;
	}

	private getCheckBoxCell = (
		type:
			| 'pigletPutDown'
			| 'pigletDead'
			| 'stemAnimalDead'
			| 'stemAnimalDeparture'
			| 'finisherDead'
			| 'weanerDead'
			| 'finisherPutDown'
			| 'weanerPutDown'
			| 'weanerSold'
			| 'finisherSold'
			| 'finisherSlaughtered'
	) => {
		return (d: IReasonDto) => {
			return (
				<SkioldCheckbox
					title=""
					itemFromParent={{ reasonDto: d, type: type, props: this.props }}
					isChecked={d.reasonSettings![type]}
					onClick={this.handleCheckbox}
				/>
			);
		};
	};

	private generatePigletColumn() {
		return [
			{
				name: 'pigletDead',
				title: localized('reasonDead'),
				getCellValue: this.getCheckBoxCell('pigletDead'),
			},
			{
				name: 'pigletPutDown',
				title: localized('reasonPutDown'),
				getCellValue: this.getCheckBoxCell('pigletPutDown'),
			},
		];
	}

	private generateStemAnimalColumn() {
		return [
			{
				name: 'stemAnimalDeparture',
				title: localized('stemAnimalDeparture'),
				getCellValue: this.getCheckBoxCell('stemAnimalDeparture'),
			},
			{
				name: 'stemAnimalDead',
				title: localized('reasonDead'),
				getCellValue: this.getCheckBoxCell('stemAnimalDead'),
			},
		];
	}

	private generateFinisherReasonColumn() {
		return [
			{
				name: 'finisherDead',
				title: localized('reasonDead'),
				getCellValue: this.getCheckBoxCell('finisherDead'),
			},
			{
				name: 'finisherPutDown',
				title: localized('reasonPutDown'),
				getCellValue: this.getCheckBoxCell('finisherPutDown'),
			},
			{
				name: 'finisherSold',
				title: localized('reasonSold'),
				getCellValue: this.getCheckBoxCell('finisherSold'),
			},
			{
				name: 'finisherSlaughtered',
				title: localized('reasonSlaughtered'),
				getCellValue: this.getCheckBoxCell('finisherSlaughtered'),
			},
		];
	}

	private generateWeanerReasonColumn() {
		return [
			{
				name: 'weanerDead',
				title: localized('reasonDead'),
				getCellValue: this.getCheckBoxCell('weanerDead'),
			},
			{
				name: 'weanerPutDown',
				title: localized('reasonPutDown'),
				getCellValue: this.getCheckBoxCell('weanerPutDown'),
			},
			{
				name: 'weanerSold',
				title: localized('reasonSold'),
				getCellValue: this.getCheckBoxCell('weanerSold'),
			},
		];
	}

	private async handleCheckbox(
		value: boolean,
		itemFromParent: {
			reasonDto: IReasonDto;
			type:
				| 'pigletDead'
				| 'pigletPutDown'
				| 'stemAnimalDead'
				| 'stemAnimalDeparture'
				| 'finisherDead'
				| 'finisherPutdown'
				| 'weanerPutdown'
				| 'weanerDead'
				| 'weanerSold'
				| 'finisherSold'
				| 'finisherSlaughtered';
			props: ReasonSettingsProps;
		},
	) {
		
		const reasonDtoCopy = deepCopy(itemFromParent.reasonDto);
		reasonDtoCopy.reasonSettings!.siteId = itemFromParent.props.siteId;
		reasonDtoCopy.reasonSettings![itemFromParent.type] = !reasonDtoCopy!.reasonSettings![itemFromParent.type];

		// Update Store and db
		await itemFromParent.props.saveReasonSetting(reasonDtoCopy);

		// Update local state
		// this.setState(prevState => ({
		//     reasons: prevState.reasons.map(
		//         r => (r.reason!.id === reasonDto.reason!.id ? { ...r, reasonSettings: reasonDto.reasonSettings } : r)
		//     )
		// }));
	}

	private generateMissingReasonSettings() {
		this.props.reasons.forEach(element => {
			if (!element.reasonSettings) {
				element.reasonSettings = ReasonSettings.fromJS({});
			}
		});
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ReasonSetting);
