import moment from 'moment';
import { StoreContainer } from 'shared/state/store-container';
import { TableColumnWidthInfoGeneric } from 'shared/state/ducks/table-settings/types';
import { AnimalKind, AnimalType, IUserProfile, LocationType } from 'shared/api/api';
import isEqual from 'react-fast-compare';
import { Option } from 'react-dropdown';
import { localized } from 'shared/state/i18n/i18n';


export const DefaultDropdownOption: Option = { label: ' ', value: '' };
export function calculateAgeInWeeks(birthDate: Date, endDate?: Date) {
	let currentDate = new Date();
	if (endDate) {
		currentDate = endDate;
	}
	let oneDay = 24 * 60 * 60 * 1000;
	birthDate = new Date(birthDate);
	let days = Math.floor((currentDate.getTime() - new Date(birthDate).getTime()) / oneDay);
	if (days < 0) {
		return '-';
	}

	let weeks = Math.floor(days / 7);
	let restDays = days % 7;

	let dayOfBirth = getDay(birthDate);

	if (dayOfBirth + restDays > 6) {
		weeks++;
	} else if (getDay(currentDate) < dayOfBirth) {
		weeks++;
	}

	return weeks;
}

export function isOdd(num) {
	return num % 2 !== 0;
}
export function rangeFilterMethod() {
	return (filter: any, row: any) => {
		let filterValue = filter.value as string;
		if (
			String(row[filter.id]) !== undefined &&
			filterValue.includes('-') &&
			filterValue.indexOf('-') === filterValue.lastIndexOf('-')
		) {
			const raw = filterValue.split('-');
			let valueOne = Number(raw[0]);
			let valueTwo = Number(raw[1]);
			if (!isNaN(valueOne) && !isNaN(valueTwo) && raw[0] !== '' && raw[1] !== '') {
				const rowValue = Number(row[filter.id]);
				return rowValue >= valueOne && rowValue <= valueTwo;
			}
		}
		return String(row[filter.id]) === filter.value;
	};
}

export function rangeFilterMethodGrid(value: any, filterValue: any) {
	if (
		value !== undefined &&
		filterValue &&
		filterValue.value.includes('-') &&
		filterValue.value.indexOf('-') === filterValue.value.lastIndexOf('-')
	) {
		const raw = filterValue.value.split('-');
		let valueOne = Number(raw[0]);
		let valueTwo = Number(raw[1]);
		if (!isNaN(valueOne) && !isNaN(valueTwo) && raw[0] !== '' && raw[1] !== '') {
			const rowValue = Number(value);
			return rowValue >= valueOne && rowValue <= valueTwo;
		}
	}
	return String(value).startsWith(filterValue.value);
}

export function commaDotFilterMethod(value: any, filterValue: any) {
	const textstring = filterValue.value.toString().replace(/,/g, '.'); // replaces , with .
	return value ? value.toString().toLowerCase().includes(textstring.toLowerCase()) : false;
}

export function exactFilterMethodGrid(value: any, filterValue: any) {
	return value === filterValue.value;
}

export function exactNumberFilterMethodGrid(value: any, filterValue: any) {
	return (
		value.replace(/[^0-9]+/, '').toLowerCase() === filterValue.value.toLowerCase() ||
		value.toLowerCase().replace(/\s/g, '') === filterValue.value.toLowerCase().replace(/\s/g, '')
	);
}

export function exactStartsWithMethodGrid(value: string, filterValue: any) {
	return String(value).startsWith(filterValue.value);
}

export function exactFilterMethod() {
	return (filter: any, row: any) => String(row[filter.id]) === filter.value;
}

function getDay(date: Date): number {
	let day = date.getDay();

	day--;

	if (day === -1) {
		day = 6;
	}

	return day;
}

export function calculateDays(dateStart: Date, dateEnd?: Date) {
	let currentDate = new Date();
	if (dateEnd) {
		currentDate = dateEnd;
	}
	let a = moment(dateStart, 'DD/MM/YYYY').startOf('day');
	let b = moment(currentDate, 'DD/MM/YYYY').startOf('day');
	return b.diff(a, 'days') < 0 ? 0 : b.diff(a, 'days');
}

export function calculateDaysAbs(dateStart: Date, dateEnd?: Date) {
	let currentDate = new Date();
	if (dateEnd) {
		currentDate = dateEnd;
	}
	let a = moment(dateStart, 'DD/MM/YYYY').startOf('day');
	let b = moment(currentDate, 'DD/MM/YYYY').startOf('day');
	return Math.abs(b.diff(a, 'days')) < 0 ? 0 : b.diff(a, 'days');
}

export function isEmpty(obj: any) {
	for (let key in obj) {
		if (obj.hasOwnProperty(key)) {
			return false;
		}
	}
	return true;
}

export function deepCopy<T>(obj: T): T {
	const clone = deepCopy2(obj);
	return clone as T;
}

export function deepCopy2(obj: any) {
	let copy: any;

	// Handle the 3 simple types, and null or undefined
	if (null == obj || 'object' !== typeof obj) {
		return obj;
	}

	// Handle Date
	if (obj instanceof Date) {
		copy = new Date();
		copy.setTime(obj.getTime());
		return copy;
	}

	// Handle Array
	if (obj instanceof Array) {
		copy = [];
		for (let i = 0, len = obj.length; i < len; i++) {
			copy[i] = deepCopy2(obj[i]);
		}
		return copy;
	}

	// Handle Object
	if (obj instanceof Object) {
		copy = {};
		for (let attr in obj) {
			if (obj.hasOwnProperty(attr)) {
				copy[attr] = deepCopy2(obj[attr]);
			}
		}
		return copy;
	}

	throw new Error("Unable to copy obj! Its type isn't supported.");
}

export function getTreatmentAnimalKindsOptions(userProfile: IUserProfile, animalTypes?: AnimalType[]): Option[] {
	let options: Option[] = [{ label: ' ', value: '' }];
	const animalTypesInUse = getTreatmentAnimalKindsInUse(userProfile);
	animalTypesInUse.forEach(ak => {
		if (ak === AnimalKind.Sow) {
			options.push({ label: localized('SowBoarGlit'), value: AnimalKind.Sow });
		} else {
			options.push({ label: localized(ak), value: ak });
		}
	});

	if (animalTypes !== undefined) {
		options = options.filter(op => op && animalTypes.find(at => at === op.value) !== undefined);
	}

	return options;
}

export function getTreatmentAnimalKindsInUse(userProfile: IUserProfile) {
	let animalTypes: any[] = [];
	if (userProfile.processes) {
		if (
			userProfile.processes.includes('fdb5f742-d890-463e-912e-89b7a4f9d3eb') ||
			userProfile.processes.includes('ed71ba1f-be95-4416-b53a-668c63303fbd')
		) {
			animalTypes.push(AnimalKind.Sow);
			animalTypes.push(AnimalKind.YoungFemale);
			animalTypes.push(AnimalKind.Piglet);
		}
		if (
			userProfile.processes.includes('2b501b81-d7a9-462e-aaad-969ca792f2a4') ||
			userProfile.processes.includes('8602cfe5-8b4e-4dcb-a7ef-960ed4bfa29b')
		) {
			animalTypes.push(AnimalKind.Weaner);
		}
		if (
			userProfile.processes.includes('4617bd03-830d-4e6f-9cab-3d348b9c6878') ||
			userProfile.processes.includes('99c4ccf9-a8da-4432-bb03-6ff168378855')
		) {
			animalTypes.push(AnimalKind.Finisher);
		}
	}
	return animalTypes;
}

export function getAnimalTypesInUse(userProfile: IUserProfile) {
	let animalTypes: any[] = [];
	if (userProfile.processes) {
		if (userProfile.processes.includes('db8d096a-708a-4d1a-883f-783c97441327')) {
			animalTypes.push(AnimalType.Sow);
			animalTypes.push(AnimalType.YoungFemale);
			animalTypes.push(AnimalType.Piglet);
		}
		if (userProfile.processes.includes('9deec183-5027-4819-8f4c-8e064c7ba378')) {
			animalTypes.push(AnimalType.Weaner);
		}
		if (userProfile.processes.includes('1fbecc75-d4ec-44a5-a6fb-3a3dcce04bed')) {
			animalTypes.push(AnimalType.Finisher);
		}
	}
	return animalTypes;
}

export function onlyUniqueFilter(value: any, index: number, self: any) {
	return self.indexOf(value) === index;
}

export function getFirstMatingBatch() {
	const state = StoreContainer.getState();
	const matingBatches = state.matingBatches.entities;

	if (matingBatches && matingBatches.length > 0) {
		matingBatches.sort((a, b) => b.matingPeriodStart!.valueOf() - a.matingPeriodStart!.valueOf());
		return matingBatches[matingBatches.length - 1];
	}
	return undefined;
}

export function isStringArraysEqual(array1: Array<string | undefined>, array2: Array<string | undefined>) {
	return (
		array1.length === array2.length &&
		array1.sort().every((value, index) => {
			return value === array2.sort()[index];
		})
	);
}

export function setTwoDecimalsAsNumber(numberToConvert: number | undefined) {
	if (numberToConvert) {
		return Math.round(numberToConvert * 1e2) / 1e2;
	}
	return undefined;
}

export function setOneDecimalsAsNumber(numberToConvert: number | undefined) {
	if (numberToConvert && !isNaN(numberToConvert)) {
		const valueToConvert = numberToConvert.toFixed(1).match(/^-?\d+(?:\.\d{0,1})?/);
		let with1Decimals = valueToConvert ? valueToConvert[0] : undefined;
		return Number(with1Decimals);
	}
	return undefined;
}

// If we want to display 2 decimals on ui, ex. 2.30, it need to be a string to display the '0'
export function setTwoDecimalsAsString(numberToConvert: number | undefined) {
	if (numberToConvert !== undefined) {
		return numberToConvert.toFixed(2);
	}
	return undefined;
}

// If we want to display 1 decimals on ui, ex. 2.0, it need to be a string to display the '0'
export function setOneDecimalsAsString(numberToConvert: number | undefined) {
	if (numberToConvert !== undefined) {
		return numberToConvert.toFixed(1);
	}
	return undefined;
}

export function setDefaultTwoDecimal(stringNumber: string | undefined) {
	if (stringNumber === undefined) {
		return '0.00';
	}
	return stringNumber;
}

export function onlyUnique(value, index, self) {
	return self.indexOf(value) === index;
}

export function copyFlatObjectArray(objectToCopy: any[]) {
	return objectToCopy.map(obj => ({ ...obj }));
}

export function isNullOrUndefined(value: any) {
	return value === null || value === undefined;
}

export function isNullOrUndefinedOrIsNaN(value: any) {
	return value === null || value === undefined || isNaN(value);
}

export function validateTableResizing(tableSettingData: Array<TableColumnWidthInfoGeneric<string>>, columnExt?: any[]) {
	let settingsCopy = deepCopy(tableSettingData);
	for (let item of settingsCopy) {
		const newValueNumeric = +item.width;

		if (item.width !== '' && !isNaN(newValueNumeric)) {
		} else {
			item.width = 100;
		}
	}
	if (columnExt) {
		let diff = columnExt!.filter(x => !settingsCopy.find(y => y.columnName === x.columnName));

		if (diff && diff.length > 0) {
			settingsCopy = settingsCopy.concat(diff as Array<TableColumnWidthInfoGeneric<string>>);
		}
	}

	return settingsCopy;
}

export const arraysEqual = (a1, a2) => a1.length === a2.length && a1.every((o, idx) => isEqual(o, a2[idx]));

export const removeRedundantDataByObject = (object: any) => {
	return Object.entries(object).reduce((a, [k, v]) => {
		return propertiesToIgnore(k) || v == null ? a : ((a[k] = v), a);
	}, {});
};

export const removeRedundantDataByArray = (array: any[]) => {
	return array.map(object =>
		Object.entries(object).reduce((a, [k, v]) => {
			return propertiesToIgnore(k) || v == null ? a : ((a[k] = v), a);
		}, {}),
	);
};

export const propertiesToIgnore = (prop: string) => {
	if (prop === 'modifiedBy') {
		return true;
	}
	if (prop === 'modifiedOn') {
		return true;
	}

	return false;
};

export const numberWithCommas = x => {
	if (x && !isNaN(x)) {
		return x.toLocaleString('en-US');
	}
};

export const ConvertLocationTypeToAnimalType = (type: LocationType | undefined) => {
	if (type === LocationType.Weaners || type === LocationType.ReliefWeaners) {
		return AnimalType.Weaner;
	}

	if (type === LocationType.Finisher || type === LocationType.ReliefFinisher) {
		return AnimalType.Finisher;
	}
	return AnimalType.Sow;
};

export function splitStringAtIndex(str, index) {
	return [str.slice(0, index), str.slice(index)];
}

export function validateEmailAddress(email: string) {
	return email.match('^[\\w\\-\\.]+@([\\w\\-]+\\.)+[\\w\\-]{2,4}$');
}

export function pad(width: number, string: string | undefined, padding: string) {
	if (string) {
		return width <= string.length ? string : pad(width, padding + string, padding);
	}
}

export function containsAnyLetters(str) {
	return /[a-zA-Z]/.test(str);
}


export function checkIfDuplicateExists(arr) {
    return new Set(arr).size !== arr.length
}

export function makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}


export function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        let k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
}