import { FarrowingWorkListItem, FarrowingWorkListProps } from './farrowing-work-list-item';
import { getActiveSows } from 'shared/helpers/stemanimal-helper/stem-animal-input-helper';
import { getState } from 'shared/helpers/stemanimal-helper/stemanimal-helper';
import {
	FarrowingListTypes,
	IMoveEvent,
	IStemAnimal,
	MoveEvent,
	PregnancyState,
	PregnancyValidationType,
	SowState,
} from 'shared/api/api';
import { calculateDays } from 'shared/helpers/general-helpers';
import { getPenBySow, getStemAnimalPenId } from 'shared/helpers/location-helper';
import { NaturalSort } from 'shared/helpers/natural-sort';

export function genereateWorklistData(
	props: FarrowingWorkListProps,
	fromSection: string | undefined,
	fromPen: string | undefined,
) {
	let workListData = [] as FarrowingWorkListItem[];
	for (let sow of getActiveSows()) {
		let sowState = getState(sow.id!);
		if (sowState !== SowState.Pregnant && sowState !== SowState.PregnantAndNursing) {
			continue;
		}
		const filteredPregnancyEvents = props.pregnancyEvents[sow.id!].filter(
			event => event.state === PregnancyState.Mated,
		);
		const matedDate = new Date(
			Math.max.apply(
				null,
				filteredPregnancyEvents.map(e => {
					return new Date(e.date!).getTime();
				}),
			),
		);
		const cycleDays = calculateDays(matedDate, new Date());
		if (
			(cycleDays !== undefined &&
				props.workListSetting &&
				props.workListSetting.cycleDays &&
				props.workListSetting.selectedType === FarrowingListTypes.PregnancyDays &&
				cycleDays >= props.workListSetting.cycleDays) ||
			(props.workListSetting &&
				fromSection &&
				props.workListSetting.selectedType === FarrowingListTypes.Location &&
				CheckSowLocation(sow, fromSection, fromPen))
		) {
			let farrowingWorkListItem = new FarrowingWorkListItem();
			farrowingWorkListItem.animalNumber = sow.animalNumber;
			farrowingWorkListItem.stemAnimalId = sow.id;
			farrowingWorkListItem.href = sow.id;
			farrowingWorkListItem.cycleDays = cycleDays;
			farrowingWorkListItem.matingBatch = getMatingBatchNumber(matedDate, props);
			farrowingWorkListItem.expectedFarrowing = getExpectedFarrowing(matedDate, props);
			setLocationStrings(sow, props.locationHashmap, props.moveEvents, farrowingWorkListItem);
			workListData.push(farrowingWorkListItem);
		}
	}
	return workListData.sort((a, b) => NaturalSort(b.cycleDays, a.cycleDays));
}

function getMatingBatchNumber(date: Date, props: FarrowingWorkListProps) {
	if (!date) {
		return;
	}
	const matingBatch = props.matingBatches.find(x => x.matingPeriodStart! <= date && date <= x.matingPeriodEnd!);
	const batchNumber = matingBatch ? matingBatch.batchNumber + '' : '';
	return batchNumber;
}

function getExpectedFarrowing(date: Date, props: FarrowingWorkListProps) {
	let matedToFarrowingPlan = 116;
	if (props.validationSetup && props.validationSetup.validationIntervals) {
		const matingToFarrowing = props.validationSetup.validationIntervals!.find(
			setup => setup.type === PregnancyValidationType.MatingToFarrowing,
		);
		matedToFarrowingPlan =
			matingToFarrowing && matingToFarrowing.plan ? matingToFarrowing.plan : matedToFarrowingPlan;
	}
	return new Date(date.setDate(date.getDate() + matedToFarrowingPlan));
}

function CheckSowLocation(stemanimal: IStemAnimal, fromSectionId: string | undefined, fromPenId: string | undefined) {
	let pen = getPenBySow(stemanimal);

	if (
		pen &&
		((!fromPenId && fromSectionId && pen.sectionId === fromSectionId) ||
			(fromPenId && fromSectionId && pen.id === fromPenId))
	) {
		return true;
	}
	return false;
}

export function setLocationStrings(
	sow: IStemAnimal,
	memorizedLocations: {
		[key: string]: any;
	},
	moveEvents: IMoveEvent[],
	farrowingListItem: FarrowingWorkListItem,
) {
	const penId = getStemAnimalPenId(sow, moveEvents);
	if (!penId) {
		return;
	}
	const pen = memorizedLocations[penId];
	if (pen) {
		const section = memorizedLocations[pen.sectionId];
		if (section) {
			farrowingListItem.penString = section && section.usePens ? pen!.name : '';
			const building = memorizedLocations[section.buildingId];
			if (building) {
				farrowingListItem.buildingSectionString =
					building && building.useSections ? `${building.name} - ${section!.name}` : building.name;
			}
		}
	}
}
