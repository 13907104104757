/* eslint-disable */
export {};
declare global {
	export interface Array<T> {
		groupBy: (prop: any) => T[][];
	}

	export interface Array<T> {
		dictionaryBy: (prop: any) => { [key: string]: T[] };
	}

	export interface Array<T> {
		keyValuePairBy: (prop: any) => { [key: string]: T[] };
	}
	export interface Array<T> {
		keyValuePairSingle: (prop: any) => { [key: string]: T };
	}

	export interface Array<T> {
		keyValuePairSingle2: (prop: any, prop2?: any) => { [key: string]: any };
	}

	export interface Array<T> {
		keyValuePairArray: (prop: any) => { [key: string]: T[] };
	}
	export interface Date {
		withoutTime: () => Date;
	}

	export interface Date {
		endOfDayTime: () => Date;
	}
	export interface Array<T> {
		groupByMultipleProps: (f: (item: T) => any[]) => T[][];
	}
}

// tslint:disable-next-line: typedef
Array.prototype.groupBy = function (prop) {
	let reduced = this.reduce((groups, item) => {
		const val = prop.split('.').reduce((a: any, b: any) => a[b], item);
		groups[val] = groups[val] || [];
		groups[val].push(item);
		return groups;
	}, {});

	let finalArray = [] as any[];
	Object.keys(reduced).forEach(arr => {
		finalArray.push(reduced[arr]);
	});

	return finalArray;
};
Array.prototype.groupByMultipleProps = function (f) {
	let groups = {};
	this.forEach(o => {
		let group = JSON.stringify(f(o));
		groups[group] = groups[group] || [];
		groups[group].push(o);
	});
	return Object.keys(groups).map(group => {
		return groups[group];
	});
};

// tslint:disable-next-line: typedef
Array.prototype.keyValuePairSingle = function (prop) {
	let reduced = this.reduce((groups, item) => {
		const val = prop.split('.').reduce((a: any, b: any) => a[b], item);

		groups[val] = item;
		return groups;
	}, {});
	return reduced;
};

// tslint:disable-next-line: typedef
Array.prototype.keyValuePairSingle2 = function (prop, prop2) {
	let reduced = this.reduce((groups, item) => {
		const val = prop.split('.').reduce((a: any, b: any) => a[b], item);

		groups[val] = prop2 ? item[prop2] : item;
		return groups;
	}, {});
	return reduced;
};

// tslint:disable-next-line: typedef
Array.prototype.keyValuePairArray = function (prop) {
	let reduced = this.reduce((groups, item) => {
		const val = prop.split('.').reduce((a: any, b: any) => a[b], item);

		if (groups[val] === undefined) {
			groups[val] = [item];
		} else {
			groups[val].push(item);
		}

		return groups;
	}, {});

	return reduced;
};

// tslint:disable-next-line: typedef
Array.prototype.dictionaryBy = function (prop) {
	let reduced = this.reduce((groups, item) => {
		const val = prop.split('.').reduce((a: any, b: any) => a[b], item);
		groups[val] = groups[val] || [];
		groups[val].push(item);
		return groups;
	}, {});

	return reduced;
};

// tslint:disable-next-line: typedef
Array.prototype.keyValuePairBy = function (prop) {
	let reduced = this.reduce((groups, item) => {
		const val = prop.split('.').reduce((a: any, b: any) => a[b], item);
		if (groups[val] !== undefined) {
			throw new Error("Key isn't unique");
		}
		groups[val] = item;
		return groups;
	}, {});

	return reduced;
};

Date.prototype.withoutTime = function () {
	let d = new Date(this);
	d.setHours(0, 0, 0, 0);
	return d;
};

Date.prototype.endOfDayTime = function () {
	let d = new Date(this);
	d.setHours(23, 59, 0, 0);
	return d;
};
