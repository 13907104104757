import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import { StoreContainer } from 'shared/state/store-container';
import * as Action from './actions';

export function GetAnalysisPregnancyData(fromMatingBatch?: Date, toMatingBatch?: Date) {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;

	return AsyncOperationBuilder2(
		Action.getData,
		client => client.analysis_AnalysisPregnancyTableData(siteId, fromMatingBatch, toMatingBatch),
		{
			siteId,
			fromMatingBatch,
			toMatingBatch,
		},
	);
}
