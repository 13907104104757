
import i18next from 'i18next';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IUserProfile, UserProfile } from 'shared/api/api';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { UpdateProfile } from 'shared/state/ducks/profile/operations';
import { Languages, LanguageType } from 'shared/state/i18n/i18n';
import { WebAppState } from "web/state/store.web";
import './language-list.scss';

const mapStateToProps = (state: WebAppState) => {
    return {
        profile: state.profile.active!
    };
};
const mapDispatchToProps = (dispatch: Dispatch) => ({
    updateProfile: (profile: IUserProfile) => UpdateProfile(profile)(dispatch)
});

type LanguageListProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class LanguageList extends React.PureComponent<LanguageListProps> {
    constructor(props: LanguageListProps) {
        super(props);
        this.changeLanguage = this.changeLanguage.bind(this);
        this.createList = this.createList.bind(this);
    }
    
    public changeLanguage(langId: LanguageType) {
        i18next.changeLanguage(langId);
        const p = UserProfile.fromJS(this.props.profile);
        p.language = langId;
        this.props.updateProfile(p);
    }

    public createList() {
        const checkboxList: JSX.Element[] = [];
        for (let i = 0; i < Object.keys(Languages).length; i++) {
            checkboxList.push(
                <ViewWeb key={i}>
                    {/* <ListItem
                        css={Styles.languageListitem}
                        key={i}
                        title={Languages[item]}
                        onPressItem={() => this.changeLanguage(item)}
                    />
                    {item === this.props.profile.language ? (
                        <TextWeb className='list-item-label'>v</TextWeb>
                    ) : null} */}
                </ViewWeb>
            );
        }
        return checkboxList;
    }

    public render() {
        return (
            <ViewWeb className='language-list'>
                <ViewWeb className='scroller'>
                    <ViewWeb>
                        {/* <SkioldFlatList data={this.createList()} numColumns={1} renderItem={({ item }) => item} /> */}
                    </ViewWeb>
                </ViewWeb>
            </ViewWeb>
        );
    }
}
export default (
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(LanguageList)
);
