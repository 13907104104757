import { Gender, IStemAnimal } from 'shared/api/api';

export const calculateBreed = (dad: IStemAnimal, mom: IStemAnimal) => {
	if (validateParents(dad, mom)) {
		return false;
	}

	if (dad.race) {
		return generateDanbred(dad.race, mom.race);
	}
};

export const generateDanbred = (dadBreedRace: string | undefined, momBreedRace: string | undefined) => {
	if (!dadBreedRace) {
		return '?'
	}
	const dadInitials = dadBreedRace[0];
	if (!momBreedRace) {
		return dadInitials + '?';
	}

	const split = momBreedRace.split('');
	if (split[0] === split[1]) {
		return dadInitials + split[0];
	} else if (split[0] !== split[1]) {
		return dadInitials + momBreedRace.substring(0, 2);
	}
};

export const generateDanishGenetics = (dadBreedRace: string| undefined, momBreedRace: string | undefined) => {
	if (!dadBreedRace) {
		return '-'
	}
	const dadInitials = dadBreedRace[0];
	if (!momBreedRace) {
		return dadInitials + '-';
	}
	let momInitials = '';
	const split = momBreedRace.split('');

	if (split[0] === split[1]) {
		momInitials = split[0];
	} else if (!momBreedRace || split[0] !== split[1]) {
		momInitials = '-';
	}

	return dadInitials + momInitials;
};

export const generateDanishGeneticsIdLetter = (race: string) => {
	const split = race.split('');
	let letter = '';
	if (race.includes('-')) {
		letter = 'Z';
	} else if (split[0] === split[1]) {
		letter = split[0];
	} else if (split[0] !== split[1]) {
		letter = 'X';
	}
	return letter;
};

export const validateParents = (dad: IStemAnimal, mom: IStemAnimal) => {
	if (dad.gender !== Gender.Male && mom.gender !== Gender.Female) {
		return false;
	}
	if (!isValidDadBreedRace(dad.race) || !isValidMomBreedRace(mom.race)) {
		return false;
	}
	return true;
};

export const isValidDadBreedRace = (race: string | undefined) => {
	if (!race || race.length !== 2) {
		return false;
	}
	const split = race.split('');
	return split[0] === split[1];
};

export const isValidMomBreedRace = (race: string | undefined) => {
	return !race || race.length !== 2;
};
