import { Dispatch } from 'redux';
import { GrowthPigTuneProduction } from 'shared/api/api';
import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import { StoreContainer } from 'shared/state/store-container';
import * as Action from './actions';

export const getGrowthPigTuneProductionBySiteId = () => {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;
	return AsyncOperationBuilder2(
		Action.getGrowthPigTuneProductionBySiteId,
		cl => cl.growthPigTuneProduction_GetBySiteId(siteId),
		{
			siteId,
		},
		undefined,
		undefined,
		undefined,
		1,
	);
};

export const upsertGrowthPigTuneProduction = (tuneProductions: GrowthPigTuneProduction[]) => {
	return AsyncOperationBuilder2(
		Action.upsertGrowthPigTuneProduction,
		cl => cl.growthPigTuneProduction_Upsert(tuneProductions),
		tuneProductions,
	);
};

export function SaveGrowthPigTuneProductionSyncData() {
	const state = StoreContainer.getState();
	const updates = state.growthPigTuneProduction.updates;

	return async (dispatch: Dispatch<any>) => {
		if (updates && updates.length > 0) {
			return dispatch(upsertGrowthPigTuneProduction(updates));
		}
	};
}
