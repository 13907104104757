import { Option } from 'react-dropdown';
import { Dispatch } from 'redux';
import { IDeadPigletsEvent, IStemAnimal } from 'shared/api/api';
import { SaveDeadPigletsEvent } from 'shared/state/ducks/dead-piglets-event/operations';
import { selectNucleusManagementOrAssignIdAccess } from 'shared/state/ducks/site/reducer';
import { UpdateYoungAnimal } from 'shared/state/ducks/stem-animals/operations';
import { localized } from 'shared/state/i18n/i18n';
import { SowState } from 'shared/state/models/sow-state';
import { SharedAppState } from 'shared/state/store.shared';
import { ExceptionMessage } from '../exception-message';
import { getStateByDate } from './stemanimal-helper';

export const deadPigletsEventMapStateToProps = (state: SharedAppState) => {
	return {
		active: state.profile.active!,
		reasons: state.reasons.entities,
		pregnancies: state.pregnancyEvents.entities,
		nucleusManagement: selectNucleusManagementOrAssignIdAccess(state),
		generalSettings: state.generalSettings.entity
	};
};

export const deadPigletsEventMapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	saveDeadPigletsEvent: (deadPigletsEvent: IDeadPigletsEvent) => SaveDeadPigletsEvent(deadPigletsEvent)(dispatch),
	youngAnimalDeparture: (sow: IStemAnimal) => UpdateYoungAnimal(sow)(dispatch),
});

export interface RegisterDeadPigletsEventState {
	sow?: IStemAnimal;
	deadPigletsEvent: IDeadPigletsEvent;
	animalNumber: string | undefined;
	toggleFocus: boolean;
	reasonOption: Option;
	modalIsOpen: boolean;
	youngAnimal: IStemAnimal | undefined;
	departureTypeOption: Option;

}

export function validateDeadPigletsEvent(
	deadPigletsEvent: IDeadPigletsEvent,
	stemAnimalId: string | undefined,
	showAlert: (errorMessage: string) => void,
	youngAnimal: IStemAnimal | undefined,
) {
	if (!stemAnimalId || stemAnimalId.length === 0) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_ANIMAL_NOT_FOUND));
		return false;
	}
	let sowState = getStateByDate(stemAnimalId, deadPigletsEvent.registrationDate);
	if (sowState !== SowState.Nursing && sowState !== SowState.PregnantAndNursing) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_MUST_BE_NURSING));
		return false;
	}
	if (!deadPigletsEvent.registrationDate) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_DATE_NOT_SET));
		return false;
	}
	if (!deadPigletsEvent.deadPiglets) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_DEAD_PIGLETS_NOT_SET));
		return false;
	}
	if (youngAnimal && deadPigletsEvent.deadPiglets > 1) {
		showAlert(localized('deadPigletsGreaterThan1'));
		return false;
	}

	return true;
}

export type RegisterDeadPigletsEventProps = ReturnType<typeof deadPigletsEventMapStateToProps> &
	ReturnType<typeof deadPigletsEventMapDispatchToProps>;
