import { actionCreatorFactory } from 'typescript-fsa';

import * as types from './types';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import { ITreatmentPlan, SyncDataTreatmentPlan } from 'shared/api/api';

const actionCreator = actionCreatorFactory(GenActionFactoryName('treatmentPlans'));

export const saveTreatmentPlan = actionCreator.async<
	ITreatmentPlan,
	string,
	{ code: number; message: string; prevEntity: ITreatmentPlan | undefined }
>(types.SAVE_TREATMENT_PLAN);

// To be used when getting for log
export const getTreatmentPlansWithCompleted = actionCreator.async<{ siteId: string }, ITreatmentPlan[]>(
	types.GET_TREATMENT_PLANS_WITH_COMPLETED,
);
export const getSyncData = actionCreator.async<
	{ siteId: string; lastSyncDate: Date; activeSiteId?: string },
	SyncDataTreatmentPlan
>(types.SYNC_DATA_TREATMENT_PLAN);

export const removeTreatmentPlansByAnimal = actionCreator<string>(types.REMOVE_TREATMENT_PLANS_BY_ANIMAL);

export const getTreatmentPlansByStemAnimalId = actionCreator.async<string, ITreatmentPlan[]>(
	types.GET_TREATMENT_PLANS_BY_STEMANIMAL_ID,
);

export const saveManyTreatmentPlans = actionCreator.async<
	ITreatmentPlan[],
	string[],
	{ code: number; message: string; prevEntity: ITreatmentPlan[] | undefined }
>(types.SAVE_MANY_TREATMENT_PLAN);
