import ObjectID from 'bson-objectid';
import memoize from 'memoize-one';
import { Option } from 'react-dropdown';
import {
	AnimalKind,
	BreedTableColumnEnum,
	Gender,
	IBreedTableItem,
	IFarrowing,
	IGeneralSettings,
	ILicence,
	IMated,
	INucleusManagementBreedingSettings,
	INumberBatch,
	IStemAnimal,
	IValidationSetup,
	NumberBatch,
	PregnancyValidationType,
	StemAnimal,
} from 'shared/api/api';
import { AssignIdAccess, LicenceIds } from 'shared/constants';
import { localized, localizedInterpolation } from 'shared/state/i18n/i18n';
import { ExceptionMessage } from '../exception-message';
import { calculateDays, deepCopy2, isNullOrUndefined, pad } from '../general-helpers';
import { DgIdNumberLengthLower, DgIdNumberLengthUpper } from './nucleus-validation-interface';
import { generateDanbred, generateDanishGenetics } from '../pregnancy-helper/animal-breed-helper';
export interface AssignIdItem {
	breed: string;
	gender: Gender;
	danishGeneticsLetter: string;
	breedNumber: string;
	matingNumber1: string;
	matingNumber2: number | undefined; // number for increament purpose,
	id: string;
	isChecked?: boolean;
	alreadySaved?: boolean;
}
const regexFirstCharLetter = new RegExp(/^[a-zA-Z][a-zA-Z0-9.,$;]+$/g);

const splitIdNumber = (idNumber: string | undefined) => {
	let herd = '';
	let matingNumber1 = '';
	let dgLetter = '';
	let matingNumber2 = '';
	if (idNumber) {
		let idNumberTrimmed = idNumber.replace(/-/g, '');
		if (regexFirstCharLetter.test(idNumberTrimmed)) {
			dgLetter = idNumberTrimmed.charAt(0);
			idNumberTrimmed = idNumberTrimmed.slice(1);
		}
		matingNumber2 = idNumberTrimmed.substring(idNumberTrimmed.length - 5);
		idNumberTrimmed = idNumberTrimmed.slice(0, -5);
		matingNumber1 = idNumberTrimmed.substring(idNumberTrimmed.length - 2);
		idNumberTrimmed = idNumberTrimmed.slice(0, -2);
		herd = idNumberTrimmed;
	}

	return { herd, matingNumber1, matingNumber2, dgLetter };
};

const mapYoungAnimalsToAssignIdItems = (youngAnimals: IStemAnimal[] | undefined, alreadySaved: boolean) => {
	if (youngAnimals && youngAnimals.length > 0) {
		return youngAnimals.map(y => {
			const { herd, matingNumber1, matingNumber2, dgLetter } = splitIdNumber(y.idNumber);
			return {
				breed: y.race,
				gender: y.gender,
				danishGeneticsLetter: dgLetter,
				breedNumber: herd,
				matingNumber1: matingNumber1,
				matingNumber2: Number(matingNumber2),
				id: y.id,
				alreadySaved: alreadySaved,
				isChecked: true,
			} as AssignIdItem;
		});
	}
	return [];
};

export const findNumberBatchToUse = (
	breedSettingToUse: INucleusManagementBreedingSettings,
	nucleusLicence: string | undefined,
	listItems: IBreedTableItem[],
	mom: IStemAnimal,
	dad: IStemAnimal,
) => {
	const momBreedRace = mom.race;
	const dadBreedRace = dad.race;
	const breedTableColumnEnum = getNucleusManagementCompanyByLicence(nucleusLicence);
	const breedItem = listItems.find(
		i =>
			i.dadBreedRace === dadBreedRace &&
			i.momBreedRace === momBreedRace &&
			breedTableColumnEnum === i.breedTableColumnEnum,
	);
	const breedRaceToFindNumberBatch =
		nucleusLicence === LicenceIds.NucleusManagementDanBred
			? breedItem?.breedRace
			: LicenceIds.NucleusManagementDanishGenetics
				? dadBreedRace
				: '';
	if (breedRaceToFindNumberBatch) {
		const numberBatch = breedSettingToUse.numberBatches
			?.filter(nb => nb.race?.includes(breedRaceToFindNumberBatch))
			.sort((a, b) => (a.priority! > b.priority! ? 1 : b.priority! > a.priority! ? -1 : 0))[0];
		return numberBatch;
	}
};

export const generateAssignIdItems = (
	breedSettings: INucleusManagementBreedingSettings[],
	mom: IStemAnimal,
	dad: IStemAnimal,
	farrowing: IFarrowing,
	listItems: IBreedTableItem[],
	nucleusLicence: string | undefined,
	selectedYoungAnimals?: IStemAnimal[],
	alreadySavedYoungAnimals?: IStemAnimal[],
	forcedMinMatingNumber?: number,
) => {
	let assignedIdNumbers: AssignIdItem[] = mapYoungAnimalsToAssignIdItems(alreadySavedYoungAnimals, true);
	assignedIdNumbers = assignedIdNumbers.concat(mapYoungAnimalsToAssignIdItems(selectedYoungAnimals, false));

	const momBreedRace = mom.race;
	const dadBreedRace = dad.race;
	let breedItem: IBreedTableItem | undefined;
	let breedSettingToUse: INucleusManagementBreedingSettings | undefined;
	let numberBatchToUse: INumberBatch | undefined;

	if (breedSettings && momBreedRace && dadBreedRace) {
		// Find correct breedTableItem and Number batch to use
		const breedTableColumnEnum = getNucleusManagementCompanyByLicence(nucleusLicence)
		breedItem = listItems.find(
			i =>
				i.dadBreedRace === dadBreedRace &&
				i.momBreedRace === momBreedRace &&
				breedTableColumnEnum === i.breedTableColumnEnum,
		);
		const breedRaceToFindNumberBatch =
			nucleusLicence === LicenceIds.NucleusManagementDanBred || nucleusLicence === AssignIdAccess
				? breedItem?.breedRace
				: LicenceIds.NucleusManagementDanishGenetics
					? dadBreedRace
					: '';

		if (breedItem && breedRaceToFindNumberBatch) {
			const { breedSetting, numberBatch } = findBreedingSettingAndNumberBatchToUse(
				breedSettings,
				breedRaceToFindNumberBatch,
				farrowing.numAlive,
			);
			if (numberBatch && breedSetting) {
				breedSettingToUse = breedSetting;
				numberBatchToUse = numberBatch;
				// Use number of pigs, according to numberBatch setting
				const gender = numberBatch.boarAssignable ? Gender.Male : Gender.Female;
				const numberOfPigs = numberBatch.boarAssignable
					? farrowing.numMaleAlive
					: numberBatch.sowAssignable
						? farrowing.numFemaleAlive
						: 0;
				const nextMatingNumber = forcedMinMatingNumber ?? Number(numberBatch.nextMatingNumber);
				if (numberOfPigs && !isNaN(nextMatingNumber)) {
					const startIndex = assignedIdNumbers ? assignedIdNumbers.length : 0;
					for (let i = startIndex; i < numberOfPigs; i++) {
						assignedIdNumbers.push({
							breed: breedItem.breedRace,
							gender,
							danishGeneticsLetter: getDanishGeneticsLetter(
								listItems,
								breedItem.breedRace,
								nucleusLicence,
							),
							breedNumber: breedSetting.breedingNumber,
							matingNumber1: numberBatch.purchaseNumber,
							matingNumber2: nextMatingNumber + i - startIndex,
							id: new ObjectID().toHexString(),
						} as AssignIdItem);
					}
				}
			}
		}
	}
	return {
		rows: assignedIdNumbers.sort((a, b) => (a.matingNumber2! >= b.matingNumber2! ? 1 : -1)),
		breedSettingToUse,
		numberBatchToUse,
	};
};

export const generateStemAnimalsByAssignId = (
	dataToAssign: AssignIdItem[],
	mom: IStemAnimal,
	dad: IStemAnimal,
	breedSettingToUse: INucleusManagementBreedingSettings,
	farrowing: IFarrowing,
	generalSetting: IGeneralSettings,
) => {
	let newStemAnimals: IStemAnimal[] = [];
	dataToAssign.forEach(data => {
		if (data.isChecked && !data.alreadySaved) {
			let stem = StemAnimal.fromJS({});
			stem.id = data.id;
			stem.entranceKind = AnimalKind.YoungAnimal;
			stem.siteId = mom.siteId;
			stem.gender = data.gender;
			stem.idNumber = `${data.danishGeneticsLetter}${data.breedNumber}${data.matingNumber1}${pad(
				5,
				data.matingNumber2?.toString(),
				'0',
			)}`;
			stem.birthDate = farrowing.date;
			stem.youngAnimalEntranceDate = farrowing.date;
			stem.race = data.breed;
			stem.momAnimalNumber = mom.animalNumber;
			stem.momIdNumber = mom.idNumber;
			stem.momRace = mom.race;
			stem.momMongoId = mom.id;
			stem.dadAnimalNumber = dad.animalNumber;
			stem.dadIdNumber = dad.idNumber;
			stem.dadRace = dad.race;
			stem.dadMongoId = dad.id;
			stem.breedingNumber = mom.breedingNumber;
			stem.entrancePenId = generalSetting.farrowingYoungAnimalsPenId;
			stem.fromPregnancyId = farrowing.pregnancyId;
			stem.siteId = mom.siteId;
			newStemAnimals.push(stem);
		}
	});
	return newStemAnimals;
};

export const setNextMatingNumber = (
	breedSettingToUse: INucleusManagementBreedingSettings,
	numberBatch: INumberBatch | undefined,
	ids: Array<string | undefined>,
) => {
	let copyBreedSetting = deepCopy2(breedSettingToUse) as INucleusManagementBreedingSettings;

	if (numberBatch) {
		let nextMatingNumber = Number(numberBatch.nextMatingNumber);
		// Split idnumber, and use the last string, to compare with next mating number.
		ids.forEach(id => {
			if (id) {
				const { herd, matingNumber1, matingNumber2 } = splitIdNumber(id);
				if (matingNumber2) {
					// Find and set the next mating number

					const matingNumber = Number(matingNumber2);
					if (matingNumber >= nextMatingNumber) {
						nextMatingNumber = matingNumber + 1;
					}
				}
			}
		});
		numberBatch.nextMatingNumber = nextMatingNumber.toString();
		const index = breedSettingToUse.numberBatches?.findIndex(nb => nb.id === numberBatch.id);
		if (copyBreedSetting && copyBreedSetting.numberBatches && index !== undefined && index > -1) {
			copyBreedSetting.numberBatches[index] = {
				...numberBatch,
				nextMatingNumber: nextMatingNumber.toString(),
			} as NumberBatch;
		}
	}
	return copyBreedSetting;
};

export interface AssignIdsResponse {
	youngAnimals?: IStemAnimal[];
	breedSettingToUse?: INucleusManagementBreedingSettings;
}

export const memoizeBreedNumberOptions = memoize(
	(nucleusManagementBreedingSettings: INucleusManagementBreedingSettings[]) => {
		let options: Option[] = [];
		return options.concat(
			nucleusManagementBreedingSettings.map(
				ns => ({ value: ns.breedingNumber, label: ns.breedingNumber } as Option),
			),
		);
	},
);

export const memoizeBreedTableItemsOptions = memoize(
	(breedTableItems: { [key: string]: IBreedTableItem }, nucleusManagementCompany?: BreedTableColumnEnum) => {
		let options: Option[] = [];
		for (let key of Object.keys(breedTableItems)) {
			breedTableItems[key].breedRace &&
				breedTableItems[key].breedTableColumnEnum === nucleusManagementCompany &&
				options.push({ value: breedTableItems[key].id, label: breedTableItems[key].breedRace } as Option);
		}
		return options;
	},
);

export const getNucleusManagementCompanyByLicence = (licence: string | undefined) => {

	switch (licence) {
		case LicenceIds.NucleusManagementDanBred:
			return BreedTableColumnEnum.DANBRED;
		case LicenceIds.NucleusManagementDanishGenetics:
			return BreedTableColumnEnum.DANISH_GENETICS;
		case LicenceIds.NucleusManagementSelfManaged:
			return BreedTableColumnEnum.SKIOLD;
		default:
			return BreedTableColumnEnum.SKIOLD;
	}
};

export const getNucleusManagementCompanyByLicences = (licences: string[] | undefined) => {
	if (licences) {
		const licence = licences.find(
			l =>
				l === LicenceIds.NucleusManagementDanBred ||
				l === LicenceIds.NucleusManagementDanishGenetics ||
				l === LicenceIds.NucleusManagementSelfManaged,
		);
		switch (licence) {
			case LicenceIds.NucleusManagementDanBred:
				return BreedTableColumnEnum.DANBRED;
			case LicenceIds.NucleusManagementDanishGenetics:
				return BreedTableColumnEnum.DANISH_GENETICS;
			case LicenceIds.NucleusManagementSelfManaged:
				return BreedTableColumnEnum.SKIOLD;
			default:
				return BreedTableColumnEnum.SKIOLD;
		}
	}
	return undefined;
};

export const showNucleusFeatureCEFN = (licences: ILicence[]): boolean => {
	if (licences) {
		return !!licences.find(l => l.id === LicenceIds.NucleusManagementSelfManaged);
	}
	return false;
};

export const showNucleusFeatureDanBred = (licences: ILicence[]): boolean => {
	if (licences) {
		return !!licences.find(l => l.id === LicenceIds.NucleusManagementDanBred);
	}
	return false;
};

export const showNucleusFeatureDanishGenetics = (licences: ILicence[]): boolean => {
	if (licences) {
		return !!licences.find(l => l.id === LicenceIds.NucleusManagementDanBred);
	}
	return false;
};

export const hasNucleusFeatureCEFN = (licence: string | undefined): boolean => {
	return licence === LicenceIds.NucleusManagementSelfManaged;
};

export const hasNucleusFeatureDanBred = (licence: string | undefined): boolean => {
	return licence === LicenceIds.NucleusManagementDanBred;
};

export const hasNucleusFeatureDanishGenetics = (licence: string | undefined): boolean => {
	return licence === LicenceIds.NucleusManagementDanishGenetics;
};

export const hasAssignIdNumbers = (licence: string | undefined): boolean => {
	return licence === AssignIdAccess;
};

export const hasDanishNucleusFeatureLicence = (licence: string | undefined): boolean => {
	return hasNucleusFeatureDanishGenetics(licence) || hasNucleusFeatureDanBred(licence) || hasNucleusFeatureCEFN(licence);
};

export const hasDanishNucleusFeature = (licence: string | undefined): boolean => {
	return hasNucleusFeatureDanishGenetics(licence) || hasNucleusFeatureDanBred(licence) || hasAssignIdNumbers(licence);
};

export const hasDanishNucleusFeatureOrAssignId = (licence: string | undefined): boolean => {
	return hasNucleusFeatureDanishGenetics(licence) || hasNucleusFeatureDanBred(licence) || hasAssignIdNumbers(licence);
};

export const getDanishGeneticsLetter = (
	breedTableItems: IBreedTableItem[],
	breedRace: string | undefined,
	licence: string | undefined,
) => {
	const item = breedTableItems.find(
		b => b.breedTableColumnEnum === BreedTableColumnEnum.DANISH_GENETICS && b.breedRace === breedRace,
	);
	if (hasNucleusFeatureDanishGenetics(licence) && item) {
		return breedTableItems.find(
			b =>
				b.breedTableColumnEnum === BreedTableColumnEnum.DANISH_GENETICS_ID_NUMMER &&
				item.momBreedRace === b.momBreedRace &&
				item.dadBreedRace === b.dadBreedRace,
		)?.breedRace;
	}
	return '';
};

export const handleDanishGeneticsIdNumber = (
	breedTableItems: IBreedTableItem[],
	stemAnimal: IStemAnimal,
	breed: string | undefined,
	licence: string | undefined,
) => {
	if (licence === LicenceIds.NucleusManagementDanishGenetics && stemAnimal && stemAnimal.idNumber) {
		// Trim idnumber
		const idNumberTrimmed = !stemAnimal.idNumber ? '' : stemAnimal.idNumber.replace(/-/g, '');
		if (
			idNumberTrimmed.length >= DgIdNumberLengthLower - 1 &&
			idNumberTrimmed.length <= DgIdNumberLengthUpper - 1
		) {
			// Check if the first char is a letter
			if (!regexFirstCharLetter.test(stemAnimal.idNumber)) {
				return getDanishGeneticsLetter(breedTableItems, breed, licence);
			}
		}
	}
	return '';
};

class NumberBatchWithSettingsId extends NumberBatch {
	public settingsId: string = '';
}

export const findBreedingSettingAndNumberBatchToUse = (
	breedSettings: INucleusManagementBreedingSettings[],
	breedRaceToFindNumberBatch: string | undefined,
	neededNumberBatches: number | undefined,
) => {
	if (breedRaceToFindNumberBatch) {
		let numberBatches: NumberBatchWithSettingsId[] = [];
		breedSettings.forEach(setting => {
			numberBatches = numberBatches.concat(
				setting.numberBatches?.map(
					nb => ({ ...nb, settingsId: setting.id } as NumberBatchWithSettingsId),
				) as NumberBatchWithSettingsId[],
			);
		});
		const numberBatch = numberBatches
			?.filter(
				nb =>
					neededNumberBatches &&
					nb.race?.includes(breedRaceToFindNumberBatch) &&
					Number(nb.endMatingNumber) - Number(nb.nextMatingNumber) > neededNumberBatches,
			)
			.sort((a, b) => (a.priority! > b.priority! ? 1 : b.priority! > a.priority! ? -1 : 0))[0];
		return { breedSetting: breedSettings.find(bs => numberBatch && bs.id === numberBatch.settingsId), numberBatch };
	}
	return { breedSetting: undefined, numberBatch: undefined };
};

export const findBreedRace = (
	nucleusLicence: string | undefined,
	listItems: IBreedTableItem[],
	mom: IStemAnimal | undefined,
	dad: IStemAnimal | undefined,
) => {
	if (nucleusLicence && listItems && mom && dad) {
		const momBreedRace = mom.race;
		const dadBreedRace = dad.race;
		const breedTableColumnEnum = getNucleusManagementCompanyByLicence(nucleusLicence);

		const breedItem = listItems.find(
			i =>
				i.dadBreedRace === dadBreedRace &&
				i.momBreedRace === momBreedRace &&
				breedTableColumnEnum === i.breedTableColumnEnum,
		);
		if (!breedItem) {
			switch (breedTableColumnEnum) {
				case BreedTableColumnEnum.SKIOLD:
					return generateDanbred(dad.race, mom.race)
				case BreedTableColumnEnum.DANBRED:
					return generateDanbred(dad.race, mom.race)
				case BreedTableColumnEnum.DANISH_GENETICS:
					return generateDanishGenetics(dad.race, mom.race)
			}
		}
		return breedItem ? breedItem.breedRace : '';
	}
	return '';
};

export const validateOpenAssignId = (
	sow: IStemAnimal | undefined,
	breedRace: string | undefined,
	mating: IMated | undefined,
	farrowing: IFarrowing,
	validationSetup: IValidationSetup,
	showAlert: (msg: string) => void,
	generalSettings: IGeneralSettings,
) => {
	if (!sow) {
		showAlert(localized('ValidateNoSowFound'));
		return;
	}
	if (!sow.breedingNumber && !generalSettings.assignNumbersToBornPiglets) {
		showAlert(localized('ValidateNoBreedingNumber'));
		return;
	}
	if (
		(generalSettings.farrowingShowAliveMales && isNullOrUndefined(farrowing.numMaleAlive)) ||
		(generalSettings.farrowingShowAliveFemales && isNullOrUndefined(farrowing.numFemaleAlive))
	) {
		showAlert(localized('ValidateNoMaleOrFemale'));
		return false;
	}
	if (!mating) {
		showAlert(localized('VALIDATION_ERROR_InvalidPregnancyEvent'));
		return;
	}
	if (!validateMatingToFarrowingCheck(mating.date, farrowing.date, validationSetup, showAlert)) {
		return false;
	}
	if (!breedRace) {
		showAlert(localized('ValidateNoBreedRace'));
		return false;
	}

	return true;
};

export const validateMatingToFarrowingCheck = (
	startDate: Date | undefined,
	endDate: Date | undefined,
	validationSetup: IValidationSetup,
	showAlert: (msg: string) => void,
) => {
	const validationInterval = validationSetup.validationIntervals!.find(
		x => x.type === PregnancyValidationType.MatingToFarrowing,
	);
	if (validationInterval && startDate && endDate) {
		const matingToFarrowingDays = calculateDays(startDate, endDate);
		if (
			(validationInterval.minus &&
				matingToFarrowingDays < validationInterval.minus &&
				validationInterval.minus !== 0) ||
			(validationInterval.plus &&
				matingToFarrowingDays > validationInterval.plus! &&
				validationInterval.plus !== 0)
		) {
			showAlert(localized('VALIDATION_ERROR_FarrowingOutOfInterval'));
			return false;
		}
	}

	return true;
};

export const getCombinations = (items: string[]) => {
	let result: string[][] = [];
	let temp: string[] = [];
	const length = Math.pow(2, items.length);

	for (let i = 0; i < length; i++) {
		temp = [];
		for (let j = 0; j < items.length; j++) {
			if (i & Math.pow(2, j)) {
				temp.push(items[j]);
			}
		}
		if (temp.length > 0) {
			result.push(temp);
		}
	}

	result.sort((a, b) => a.length - b.length);
	return result.map(combination => combination.join(','));
};
