import { IBuilding, IPen, IProcessEquipmentData, ISection } from 'shared/api/api';
import { LocationTableModel } from './location-table-model';

export function generateTableData(
	buildings: IBuilding[],
	sections: ISection[],
	pens: IPen[],
	processEquipmentData: IProcessEquipmentData[],
) {
	let locationList = [] as LocationTableModel[];
	Array.prototype.push.apply(locationList, applyBuildingData(buildings, sections, processEquipmentData));

	Array.prototype.push.apply(locationList, applySectionData(sections, processEquipmentData));
	Array.prototype.push.apply(locationList, applyPenData(pens));
	return locationList;
}

function applyBuildingData(
	buildings: IBuilding[],
	sections: ISection[],
	processEquipmentData: IProcessEquipmentData[],
) {
	let buildingList = [] as LocationTableModel[];
	buildings.forEach(building => {
		let sec = sections.find(s => s.buildingId === building.id);
		let locationModel = new LocationTableModel();
		const peqs = processEquipmentData.filter(
			peq =>
				peq.buildingId === building.id &&
				peq.sectionId !== undefined &&
				peq.unitCodes &&
				peq.unitCodes.length > 0 &&
				peq.isFromImport,
		);
		CheckIfThereIsAnyUnsettedPensInBuilding(peqs, locationModel);
		locationModel.useUnits = peqs.length > 0 ? true : false;
		locationModel.buildingId = building.id;
		locationModel.buildingNumber = building.buildingNumber;
		locationModel.buildingName = building.name;
		locationModel.name = building.name;
		locationModel.order = building.order;
		locationModel.useSections = building.useSections;
		locationModel.productionForm = building.productionForm;
		locationModel.usePens = sec ? sec.usePens : false;
		locationModel.isBuilding = true;
		locationModel.showChildren = false;
		buildingList.push(locationModel);
	});

	return sortByOrder(buildingList);

	function CheckIfThereIsAnyUnsettedPensInBuilding(peqs: IProcessEquipmentData[], locationModel: LocationTableModel) {
		peqs.forEach(peq => {
			if (peq.unitCodes) {
				const peqsNotFromImport = processEquipmentData.filter(
					peqNotFromImport => peq.code === peqNotFromImport.code && !peqNotFromImport.isFromImport,
				);
				let peqUnitCodeCount = peq.unitCodes.length;
				let peqNotFromImportUnitCodeCount = 0;
				peqsNotFromImport.forEach(peqNotFromImport => {
					if (peqNotFromImport.unitCodes) {
						peqNotFromImportUnitCodeCount =
							peqNotFromImportUnitCodeCount + peqNotFromImport.unitCodes.length;
					}
				});
				if (peqUnitCodeCount !== peqNotFromImportUnitCodeCount) {
					locationModel.unsettedUnitToPens = true;
				}
			}
		});
	}
}
function applySectionData(sections: ISection[], processEquipmentData: IProcessEquipmentData[]) {
	let sectionList = [] as LocationTableModel[];
	sections.forEach(section => {
		let locationModel = new LocationTableModel();
		const peqs = processEquipmentData.filter(
			peq =>
				peq.sectionId === section.id &&
				peq.sectionId !== undefined &&
				peq.unitCodes &&
				peq.unitCodes.length > 0 &&
				peq.isFromImport,
		);
		CheckIfThereIsAnyUnsettedPensInSection(peqs, locationModel);
		locationModel.useUnits =
			processEquipmentData.filter(
				peq =>
					peq.sectionId === section.id &&
					peq.sectionId !== undefined &&
					peq.unitCodes &&
					peq.unitCodes.length > 0,
			).length > 0
				? true
				: false;
		locationModel.buildingId = section.buildingId;
		locationModel.sectionId = section.id;
		locationModel.sectionName = section.name;
		locationModel.name = section.name;
		locationModel.usePens = section.usePens;
		locationModel.order = section.order;
		locationModel.productionType = section.animalType;
		locationModel.sectionType = section.type;
		locationModel.isSection = true;
		locationModel.showChildren = false;
		sectionList.push(locationModel);
	});
	return sortByOrder(sectionList);

	function CheckIfThereIsAnyUnsettedPensInSection(peqs: IProcessEquipmentData[], locationModel: LocationTableModel) {
		peqs.forEach(peq => {
			if (peq.unitCodes) {
				const peqsNotFromImport = processEquipmentData.filter(
					peqNotFromImport => peq.code === peqNotFromImport.code && !peqNotFromImport.isFromImport,
				);
				let peqUnitCodeCount = peq.unitCodes.length;
				let peqNotFromImportUnitCodeCount = 0;
				peqsNotFromImport.forEach(peqNotFromImport => {
					if (peqNotFromImport.unitCodes) {
						peqNotFromImportUnitCodeCount =
							peqNotFromImportUnitCodeCount + peqNotFromImport.unitCodes.length;
					}
				});
				if (peqUnitCodeCount !== peqNotFromImportUnitCodeCount) {
					locationModel.unsettedUnitToPens = true;
				}
			}
		});
	}
}
function applyPenData(pens: IPen[]) {
	let penList = [] as LocationTableModel[];
	pens.forEach(pen => {
		let locationModel = new LocationTableModel();
		locationModel.penId = pen.id;
		locationModel.buildingId = pen.buildingId;
		locationModel.sectionId = pen.sectionId;
		locationModel.penNumber = `${pen.name}`;
		locationModel.name = `${pen.name}`;
		locationModel.order = pen.order;
		locationModel.capacity = pen.capacity;
		locationModel.isPen = true;
		penList.push(locationModel);
	});
	return sortByOrder(penList);
}

export function calculateCapacity(pens: IPen[], id: string, sections?: ISection[]) {
	let totalCapacity = 0;
	if (sections) {
		const filteredSections = sections.filter(section => section.buildingId === id).map(sec => sec.id);
		totalCapacity = pens
			.filter(pen => filteredSections.includes(pen.sectionId))
			.reduce((acc, pen) => acc + pen.capacity!, 0);
	} else {
		totalCapacity = pens.filter(pen => pen.sectionId === id).reduce((acc, pen) => acc + pen.capacity!, 0);
	}

	return totalCapacity;
}

export type AllLocationType = IBuilding | ISection | IPen | LocationTableModel;

export function sortByOrder(locationToSort: AllLocationType[]) {
	return locationToSort.slice().sort((a, b) => {
		if (a.order! > b.order!) {
			return 1;
		} else if (b.order! > a.order!) {
			return -1;
		} else {
			if (a.name && b.name) {
				return a.name.localeCompare(b.name);
			} else if (a.name) {
				return -1; // a comes first if b.name is undefined
			} else if (b.name) {
				return 1; // b comes first if a.name is undefined
			} else {
				return 0; // both names are undefined, they are equal
			}
		}
	});
}
