import { Action } from 'redux';
import { WidgetsState } from './';
import { getDashboard, upsertDashboard } from './actions';
import { isType } from 'typescript-fsa';
import { siteChange } from '../profile/actions';

export const initialWidgetsState: WidgetsState = {
    dashboard: null
};

export default function reducer(state: WidgetsState = initialWidgetsState, action: Action): WidgetsState {
    if (isType(action, upsertDashboard.started)) {
        return { ...state, dashboard: action.payload };
    }
    if (isType(action, upsertDashboard.done)) {
        action.payload.params.id = action.payload.result;
        // return { ...state, dashboard: action.payload.params };
        return state;
    }
    if (isType(action, getDashboard.started)) {
        return { ...state, dashboard: null };
    }
    if (isType(action, getDashboard.done)) {
        return { ...state, dashboard: action.payload.result };
    }
    if (isType(action, siteChange.done)) {
		state = initialWidgetsState;
	}
    return state;
}
