import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
	Equipment,
	FeedingReportItem,
	IFeedingReportItem,
	AnimalReportType,
	FeedingESFReportSetting,
	ISection,
} from 'shared/api/api';
import PrinterGreyIcon from 'shared/assets/src-assets/png/printer_ikon_grey.png';
import { getDateString } from 'shared/helpers/date-helpers';
import { ILocationModel, sortByOrder } from 'shared/helpers/location-helper';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import SkioldStableSectionPicker from 'web/view/components/location/location-picker/skiold-stable-section-picker';
import { SkioldDatePicker } from 'web/view/components/skiold-components/skiold-date-picker/skiold-date-picker';
import SkioldTableItemCounter from 'web/view/components/skiold-components/skiold-table/skiold-table-item-counter';
import { SkioldFetchIcon } from 'web/view/components/utils/skiold-fetch-icon';
import { SkioldIconSpinner } from 'web/view/components/utils/skiold-icon-spinner';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { GetFeedingReport, GetFeedingReportPdf } from 'web/web-helpers/pdf-helper/feeding-report-helper';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import './feeding-report-overview.scss';
import { TableFeedingReport } from './feeding-report-table';
import { WhiteText } from 'web/view/components/Text/white-text';
import { LocationsState } from 'shared/state/ducks/locations/types';

const mapStateToProps = (state: WebAppState) => {
	return {
		profile: state.profile.active!,
		locations: state.locations,
		unitToPens: state.unitToPenData.data,
		processEquipmentData: state.processEquipmentData.entities.filter(peq => peq.equipmentType === Equipment.ESF),
		feedingReportSetting: state.animalReportSettings.entities.find(
			report => report.type === AnimalReportType.FeedingESFReportSetting,
		) as FeedingESFReportSetting,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => {
	return {};
};

export interface State {
	date: Date;
	isDownloadingReport: boolean;
	reportData: IFeedingReportItem[] | undefined;
	headers: FeedingReportItem | undefined;
	buildingId: string | undefined;
	sectionId: string | undefined;
	locations: ILocationModel | undefined;
	fromSectionId: string | undefined;
	toSectionId: string | undefined;
	toLocations: ILocationModel | undefined;
}

export interface PropsFromParent {
	topLeft?: (topLeft: JSX.Element) => void;
	topRight?: (topRight: JSX.Element) => void;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;
export class FeedingReportOverview extends React.PureComponent<Props, State> {
	public WorkListTableRef: any;

	constructor(props: Props) {
		super(props);
		let date = new Date();

		// Set hours, because date chosen is end date
		date.setDate(date.getDate());
		date.setHours(23, 59, 59, 0);
		this.state = {
			date: date,
			isDownloadingReport: false,
			reportData: [],
			headers: undefined,
			buildingId: undefined,
			sectionId: undefined,
			locations: undefined,
			toSectionId: undefined,
			fromSectionId: undefined,
			toLocations: undefined,
		};
	}

	public componentDidMount() {
		this.fetchData();

		if (this.props.topLeft) {
			this.props.topLeft(this.renderTopLeft());
		}
		if (this.props.topRight) {
			this.props.topRight(this.renderTopRight());
		}
		this.setEsfLocationOptions();
	}

	public render() {
		return (
			<ViewWeb className="feeding-report-overview">
				<TableFeedingReport
					ref={this.setRefWithWrappedInstance}
					headers={this.state.headers}
					feedingReportData={this.state.reportData}
				/>
			</ViewWeb>
		);
	}

	public setRefWithWrappedInstance = (m: any) => {
		if (m) {
			this.WorkListTableRef = m;
		}
	};

	public setDate = (newDate: Date) => {
		this.setState({ date: newDate }, () => {
			if (this.props.topLeft) {
				this.props.topLeft(this.renderTopLeft());
			}
		});
	};

	public setSectionId = sectionId => {
		this.setState({ sectionId }, () => this.fetchData());
	};

	public setToSectionId = toSectionId => {
		this.setState({ toSectionId });
	};

	public setFromSectionId = fromSectionId => {
		let filteredLocations = { ...this.props.locations };
		let locations = {} as LocationsState;
		const toSection = filteredLocations.sections.find(sec => sec.id === this.state.toSectionId);
		let fromSection = filteredLocations.sections.find(sec => sec.id === fromSectionId);

		locations.buildings = filteredLocations.buildings.filter(b => fromSection && b.id === fromSection.buildingId);

		locations.sections = filteredLocations.sections.filter(
			s =>
				fromSection &&
				fromSection.buildingId === s.buildingId &&
				this.props.unitToPens.find(esfLoc =>
					filteredLocations.pens.find(pen => pen.id === esfLoc.penId && pen.sectionId === s.id),
				) !== undefined,
		);
		locations.pens = filteredLocations.pens.filter(
			p =>
				fromSection &&
				fromSection.buildingId === p.buildingId &&
				this.props.unitToPens.find(esfLoc => p.id === esfLoc.penId) !== undefined,
		);
		let stateToUpdate = { fromSectionId, toLocations: locations };

		if (toSection && fromSection && toSection.buildingId !== fromSection.buildingId) {
			stateToUpdate['toSectionId'] = '';
		}
		this.setState(stateToUpdate, () => {
			if (this.props.topLeft) {
				this.props.topLeft(this.renderTopLeft());
			}
		});
	};

	public renderTopLeft = () => {
		return (
			<div className="top-left-container">
				<div className="date-picker-margin">
					<SkioldDatePicker
						onDateChanged={this.setDate}
						selectedDate={this.state.date}
						text="Date"
						includeTimeStamp={false}
						isEndDate={true}
						containerClassName={'feed-report-date-animaltext-container'}
					/>
					<div className="flexDirectionRow feed-report-date-animaltext-container">
						<TextWeb className="feeding-report-label">{localized('Animals')}: </TextWeb>
						<SkioldTableItemCounter />
					</div>
				</div>
				<ViewWeb>
					<ViewWeb className="feeding-report-location-container">
						<TextWeb className="feeding-report-location-label">{localized('FromLocation')}</TextWeb>
						<SkioldStableSectionPicker
							onStableSectionSelected={this.setFromSectionId}
							sectionId={this.state.fromSectionId}
							filteredLocations={this.state.locations}
							isPigProduction={true}
							containerClassName="stable-section-picker"
						/>
					</ViewWeb>
					<ViewWeb className="feeding-report-location-container">
						<TextWeb className="feeding-report-location-label">{localized('ToLocation')}</TextWeb>
						<SkioldStableSectionPicker
							onStableSectionSelected={this.setToSectionId}
							sectionId={this.state.toSectionId}
							filteredLocations={this.state.toLocations}
							isPigProduction={true}
							containerClassName="stable-section-picker"
						/>
					</ViewWeb>
				</ViewWeb>
				<SkioldFetchIcon
					className="spinner-margin"
					onPress={this.fetchData}
					showSpinner={this.state.isDownloadingReport}
				/>
			</div>
		);
	};

	public renderTopRight = () => {
		const { feedingReportSetting } = this.props;
		return (
			<div className="feeding-report-right-container">
				<ViewWeb className="setting-percentage-container">
					<ViewWeb className="text-label">
						<WhiteText>{localized('dayRationEaten')}:</WhiteText>
						<WhiteText>{localized('dayRationEaten')}:</WhiteText>
					</ViewWeb>
					<ViewWeb className="text-label">
						<WhiteText className="esf-feeding-red-color">
							{feedingReportSetting && feedingReportSetting.redFromPercentage}
						</WhiteText>
						<WhiteText className="esf-feeding-green-color">
							{feedingReportSetting && feedingReportSetting.greenFromPercentage}
						</WhiteText>
					</ViewWeb>
					<ViewWeb>
						<WhiteText>-</WhiteText>
						<WhiteText>-</WhiteText>
					</ViewWeb>
					<ViewWeb className="text-label">
						<WhiteText className="esf-feeding-red-color">
							{feedingReportSetting && feedingReportSetting.redToPercentage}
						</WhiteText>
						<WhiteText className="esf-feeding-green-color">
							{feedingReportSetting && feedingReportSetting.greenToPercentage}
						</WhiteText>
					</ViewWeb>
				</ViewWeb>

				<SkioldIconSpinner title={'printFeedingReport'} icon={PrinterGreyIcon} onPress={this.download} />
			</div>
		);
	};

	private download = async () => {
		let title = localized('FeedingReport');
		if (this.WorkListTableRef && this.WorkListTableRef!.SkioldTableRef) {
			let data = this.WorkListTableRef!.SkioldTableRef!.GetSortedData();
			if (this.state.headers && data.length) {
				await GetFeedingReportPdf(
					this.WorkListTableRef!.SkioldTableRef!.GetSortedData(),
					this.state.headers,
					this.props.profile.siteId!,
					title + ' ' + getDateString(this.state.date) + '.pdf',
					this.props.profile.language,
				);
			}
		}
	};

	// fetch data for web view
	private fetchData = async () => {
		this.setState(
			{
				isDownloadingReport: true,
			},
			() => {
				if (this.props.topLeft) {
					this.props.topLeft(this.renderTopLeft());
				}
			},
		);

		let sectionIds = this.getSectionIdsBetweenTwoSectionIds(
			this.state.fromSectionId,
			this.state.toSectionId,
			this.state.toLocations && this.state.toLocations.sections,
		);

		let report = await GetFeedingReport(
			this.state.date,
			this.props.profile.siteId!,
			this.state.buildingId,
			sectionIds,
		);
		let headers = report.header;
		this.setState({ reportData: report.tableItems, isDownloadingReport: false, headers }, () => {
			if (this.props.topLeft) {
				this.props.topLeft(this.renderTopLeft());
			}
		});
	};

	private getSectionIdsBetweenTwoSectionIds = (
		fromSectionId: string | undefined,
		toSectionId: string | undefined,
		sections: ISection[] | undefined,
	) => {
		if (!fromSectionId || !toSectionId || !sections) {
			return [];
		}
		const sortedSections = sortByOrder(sections);

		let fromIndex = sortedSections.findIndex(s => s.id === fromSectionId);
		let toIndex = sortedSections.findIndex(s => s.id === toSectionId);

		let sectionsToShow = sortedSections.slice(fromIndex, toIndex + 1);
		return sectionsToShow.map(s => s.id!);
	};

	private setEsfLocationOptions = () => {
		let filteredLocations = { ...this.props.locations };
		filteredLocations.buildings = filteredLocations.buildings.filter(
			b =>
				this.props.unitToPens.find(esfLoc =>
					filteredLocations.pens.find(pen => pen.id === esfLoc.penId && pen.buildingId === b.id),
				) !== undefined,
		);
		filteredLocations.sections = filteredLocations.sections.filter(
			s =>
				this.props.unitToPens.find(esfLoc =>
					filteredLocations.pens.find(pen => pen.id === esfLoc.penId && pen.sectionId === s.id),
				) !== undefined,
		);
		filteredLocations.pens = filteredLocations.pens.filter(
			p => this.props.unitToPens.find(esfLoc => p.id === esfLoc.penId) !== undefined,
		);
		this.setState({ locations: filteredLocations });
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(FeedingReportOverview);
