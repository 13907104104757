import React from 'react';
import { Option } from 'react-dropdown';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IPen } from 'shared/api/api';
import { ILocationModel, sortPensByName } from 'shared/helpers/location-helper';
import { SetCurrentLocation } from 'shared/state/ducks/locations/operations';
import { CurrentLocation } from 'shared/state/ducks/locations/types';
import { WebAppState } from 'web/state/store.web';
import { SkioldDropdown } from '../../skiold-components/skiold-dropdown/skiold-dropdown';
import { SkioldFormDropdown } from '../../skiold-components/skiold-dropdown/skiold-form-dropdown';
import './skiold-form-pen-picker.scss';

interface PropsFromParent {
	onPenSelected: (penId: string) => void;
	containerClassName?: string;
	filteredLocations?: ILocationModel;
	filteredPens?: IPen[];
	sectionId: string | undefined;
	penId?: string | undefined;
	usedInsideForm?: boolean;
	usedInsideTable?: boolean;
	theme?: 'light' | 'dark';
	menuClassname?: string;
	dropdownIdentifier?: string;
	disabled?: boolean;
	useCurrentLocationFromParent?: boolean;
	resetIfUndefined?: boolean;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		locations: state.locations,
		useCurrentLocation: state.locations.useCurrentLocation,
		currentLocation: state.locations.currentLocation,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	setCurrentLocation: (currentLocation: CurrentLocation) => SetCurrentLocation(currentLocation)(dispatch),
});

const DefaultPenOption: Option = { label: ' ', value: '' };

interface State {
	selectedPen: Option;
	penId: string;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;
class SkioldFormPenPicker extends React.PureComponent<Props, State> {
	public static defaultProps: Partial<Props> = {};
	constructor(props: Props) {
		super(props);

		this.state = {
			selectedPen: DefaultPenOption,
			penId: '',
		};
	}

	public componentDidMount() {
		if (
			this.props.useCurrentLocationFromParent &&
			this.props.useCurrentLocation &&
			this.props.currentLocation &&
			this.props.currentLocation.penId
		) {
			this.setLocationByPenId(this.props.currentLocation.penId);
		} else if (this.props.penId) {
			this.setLocationByPenId(this.props.penId);
		}
	}

	public UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (nextProps.penId !== this.state.penId) {
			this.setLocationByPenId(nextProps.penId);
		}
	}

	public render() {
		if (this.props.usedInsideForm) {
			return this.renderSkioldFormPenPicker();
		} else {
			return this.renderSkioldPenPicker();
		}
	}

	private renderSkioldPenPicker() {
		return (
			<SkioldDropdown
				usedInTable={this.props.usedInsideTable}
				containerClassName={this.props.containerClassName}
				disabled={this.props.disabled}
				theme={this.props.theme ? this.props.theme : 'dark'}
				items={this.getPens()}
				onValueChanged={this.penSelected}
				selectedValue={this.state.selectedPen}
				menuClassname={this.props.menuClassname}
				dropdownIdentifier={this.props.dropdownIdentifier}
			/>
		);
	}

	private renderSkioldFormPenPicker() {
		return (
			<SkioldFormDropdown
				items={this.getPens()}
				disabled={this.props.disabled}
				theme={'dark'}
				onValueChanged={this.penSelected}
				selectedValue={this.state.selectedPen}
				dropdownIdentifier={this.props.dropdownIdentifier}
			/>
		);
	}

	private penSelected = (penOption: Option) => {
		if (penOption !== this.state.selectedPen) {
			if (penOption && penOption.value) {
				this.props.setCurrentLocation({ ...this.props.currentLocation, penId: penOption.value });
			}

			this.setState({ selectedPen: penOption });
			this.props.onPenSelected(penOption.value);
		}
	};

	private resetSelectedPen() {
		this.penSelected(DefaultPenOption);
	}

	private getPens() {
		let locationState = this.props.filteredLocations ? this.props.filteredLocations : this.props.locations;
		if (!this.props.sectionId || !locationState.pens) {
			this.resetSelectedPen();
			return [];
		}
		let pens = this.props.filteredPens
			? this.props.filteredPens.filter(p => p.sectionId === this.props.sectionId)
			: locationState.pens.filter(p => p.sectionId === this.props.sectionId);
		let penOptions: Option[] = [DefaultPenOption];

		penOptions = penOptions.concat(
			this.sortPens(pens).map(pen => {
				return { label: pen.name ? pen.name : '', value: pen.id! };
			}),
		);

		if (penOptions.findIndex(option => option.value === this.state.selectedPen.value) === -1) {
			this.resetSelectedPen();
		}

		return penOptions;
	}

	private sortPens(pens: IPen[]) {
		pens.sort(sortPensByName);

		return pens;
	}

	private getOption(pen: IPen): Option {
		return { label: pen.name ? pen.name : '', value: pen.id! };
	}

	private setLocationByPenId(id: string | undefined) {
		this.setState({ penId: id ? id : '' });
		const pen = this.props.locations.pens.find(s => s.id === id);
		if (!pen) {
			if (this.props.resetIfUndefined) {
				this.resetSelectedPen();
			}
			return;
		}
		const penOption = this.getOption(pen);
		if (penOption !== this.state.selectedPen) {
			if (penOption && penOption.value) {
				this.props.setCurrentLocation({ ...this.props.currentLocation, penId: penOption.value });
			}

			this.setState({ selectedPen: penOption });
		}
		// this.penSelected(this.getOption(pen));
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SkioldFormPenPicker);
