import { IEsfFeedingStatus, IEsfFeedCurve,IFeedingStatusUpdate, IEsfStatusAcknowledge } from 'shared/api/api';

export const GET_EsfFeedingStatusData = 'GET_EsfFeedingStatusData';
export const GET_EsfDailyStatusData = 'GET_EsfDailyStatusData';
export const SAVE_EsfFeedingStatus = 'SAVE_EsfFeedingStatus';
export const GET_EsfFeedingCurveData = 'GET_EsfFeedingCurveData';
export const GET_StationData = 'GET_StationData';
export const REMOVE_ESF_STATUS = 'REMOVE_ESF_STATUS';
export const ACKNOWLEDGE_FEEDING_STATUS = 'ACKNOWLEDGE_FEEDING_STATUS';

export interface EsfFeedingStatusDataState {
    data: IEsfFeedingStatus[];
    updatesEsfFeedingStatus: IFeedingStatusUpdate[];
    availableCurves: IEsfFeedCurve[],
    dataLastSyncDate?: Date;
    curveLastSyncDate?: Date;
    saveSyncInProgress: boolean;
    acknowledgeUpdates: IEsfStatusAcknowledge[];
}
