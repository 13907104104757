import React from 'react';
import { Option } from 'react-dropdown';
import { AnimalType } from 'shared/api/api';
import { GenerateProductionTypeOptions } from 'shared/helpers/growth-pigs-helper/growth-pig-event-function-helper';
import {
	GrowthPigProductionTypePickerState,
	GrowthPigProductionTypeProps,
	GrowthPigProductionTypePropsFromParent,
	growthPigProductionTypMapStateToProps,
} from 'shared/helpers/growth-pigs-helper/growth-pig-event-helper';
import { localized } from 'shared/state/i18n/i18n';
import { SkioldFormDropdown } from 'web/view/components/skiold-components/skiold-dropdown/skiold-form-dropdown';
import { SkioldDropdown } from '../skiold-dropdown/skiold-dropdown';
import isEqual from 'react-fast-compare';
import { connect } from 'react-redux';

export class GrowthPigProductionTypePicker extends React.PureComponent<
	GrowthPigProductionTypeProps,
	GrowthPigProductionTypePickerState
> {
	constructor(props: GrowthPigProductionTypeProps) {
		super(props);
		const productionTypeOptions = GenerateProductionTypeOptions(props);
		const selectedProductionType = { label: localized(props.productionType), value: props.productionType };
		this.state = {
			selectedProductionType,
			productionTypeOptions,
		};
		this.setSelectedProductionType(this.state);
		if (!props.dontSetToFrats && selectedProductionType.value === AnimalType.FRATS) {
			props.onProductionTypeChanged(AnimalType.FRATS as AnimalType, this.props.itemFromParent);
		}
	}

	public componentDidUpdate(prevProps: GrowthPigProductionTypePropsFromParent) {
		if (!isEqual(prevProps.productionType, this.props.productionType)) {
			if (this.state.productionTypeOptions.find(pto => pto.value === this.props.productionType) === undefined) {
				const stateToSet = { ...this.state };
				stateToSet.productionTypeOptions = GenerateProductionTypeOptions(this.props);
				this.setSelectedProductionType(stateToSet);
				this.setState(stateToSet);
			} else {
				this.setState({
					selectedProductionType: {
						label: localized(this.props.productionType),
						value: this.props.productionType,
					},
				});
			}
		}

		if (
			!isEqual(prevProps.productionTypeLimitation, this.props.productionTypeLimitation) &&
			this.props.productionTypeLimitation
		) {
			const options = GenerateProductionTypeOptions(this.props);
			let selectedProductionType = { ...this.state.selectedProductionType };
			if (options.find(op => selectedProductionType.value === op.value) === undefined) {
				selectedProductionType = {
					label: localized(this.props.productionTypeLimitation),
					value: this.props.productionTypeLimitation,
				};
				this.props.onProductionTypeChanged(this.props.productionTypeLimitation, this.props.itemFromParent);
			}
			this.setState({
				productionTypeOptions: GenerateProductionTypeOptions(this.props),
				selectedProductionType,
			});
		}
	}

	private setSelectedProductionType(stateToSet: { selectedProductionType: Option; productionTypeOptions: Option[] }) {
		if (
			stateToSet.productionTypeOptions.find(pto => pto.value === AnimalType.FRATS) !== undefined &&
			!this.props.dontSetToFrats
		) {
			stateToSet.selectedProductionType = { label: localized(AnimalType.FRATS), value: AnimalType.FRATS };
		} else if (
			stateToSet.productionTypeOptions.find(pto => pto.value === AnimalType.Finisher) &&
			this.props.allowSetToDefaultFinisher
		) {
			stateToSet.selectedProductionType = { label: localized(AnimalType.Finisher), value: AnimalType.Finisher };
		} else {
			stateToSet.selectedProductionType = {
				label: localized(this.props.productionType),
				value: this.props.productionType,
			};
		}
		if (!this.props.dontSetToFrats && stateToSet.selectedProductionType.value === AnimalType.FRATS) {
			this.props.onProductionTypeChanged(AnimalType.FRATS as AnimalType, this.props.itemFromParent);
		}
	}

	public render() {
		return (
			<>
				{this.props.usedInsideForm ? (
					<SkioldFormDropdown
						theme={this.props.theme === 'light' ? 'light' : 'dark'}
						disabled={this.props.disabled}
						items={this.state.productionTypeOptions}
						onValueChanged={this.OnProductionTypeChanged}
						selectedValue={this.state.selectedProductionType}
					/>
				) : (
					<SkioldDropdown
						theme={this.props.theme === 'light'? 'light' : 'dark'}
						disabled={this.props.disabled}
						items={this.state.productionTypeOptions}
						onValueChanged={this.OnProductionTypeChanged}
						selectedValue={this.state.selectedProductionType}
					/>
				)}
			</>
		);
	}

	private OnProductionTypeChanged = (productionType: Option) => {
		this.setState({ selectedProductionType: productionType });
		this.props.onProductionTypeChanged(productionType.value as AnimalType, this.props.itemFromParent);
	};
}
export default connect(growthPigProductionTypMapStateToProps, {})(GrowthPigProductionTypePicker);
