import React, { FC, useCallback, useEffect, useState } from 'react';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import './nucleus-management-connections-settings.scss';
import PenIcon from 'shared/assets/src-assets/png/pen_icon.png';
import DeleteIcon from 'shared/assets/src-assets/png/delete_icon.png';
import { useDispatch, useSelector } from 'react-redux';
import { LoginSettingsNucleusManagementConnection } from '../../../../components/nucleus-management/login-settings-nucleus-management-connection';
import { ViewWeb } from '../../../../components/utils/web-view';
import { WebAppState } from '../../../../../state/store.web';
import { localized } from '../../../../../../shared/state/i18n/i18n';
import { NucleusManagementProvider } from '../../../../../../shared/api/api';
import {
	deleteNucleusManagementConnection,
	getConnections,
} from '../../../../../../shared/state/ducks/nucleus-management-connections/operation';
import { SkioldTouchableOpacity } from '../../../../components/skiold-components/skiold-touchable-opacity';
import { SkioldImage } from '../../../../components/utils/svg/skiold-image';
import { SkioldModal } from '../../../../components/skiold-components/skiold-modal/skiold-modal';
import { getProvider } from '../nucleus-management-helper';

export const NucleusManagementConnectionsSettings: FC = React.memo(() => {
	const dispatch = useDispatch();
	const activeSite = useSelector((state: WebAppState) => state.site.site);

	const [modalOpen, setModalOpen] = useState(false);
	const [provider, setProvider] = useState<ReturnType<typeof getProvider>>(NucleusManagementProvider.DanBred);
	const connection = useSelector((state: WebAppState) =>
		state.nucleusManagementConnections.connections.find(c => c.provider === provider),
	);

	useEffect(() => {
		if (activeSite === undefined || activeSite.id === undefined) {
			return;
		}

		dispatch(getConnections(activeSite.id));
		setProvider(getProvider(activeSite.activeLicences));
	}, [activeSite, dispatch]);

	const startEdit = useCallback((provider: NucleusManagementProvider) => {
		setProvider(provider);
		setModalOpen(true);
	}, []);

	const closeModal = useCallback(() => {
		setModalOpen(false);
	}, []);

	const deleteConnection = useCallback(() => {
		if (connection?.id === undefined) {
			return;
		}

		dispatch(deleteNucleusManagementConnection(connection.id));
	}, [connection, dispatch]);

	if (provider === 'NoNucleusManagementLicence' || provider === NucleusManagementProvider.SelfManaged) {
		return (
			<ViewWeb className={'nucleus-management-container'}>
				<TextWeb className={'not-relevant-info'}>{localized('NoNucleusManagementLicence')}</TextWeb>
			</ViewWeb>
		);
	}

	return (
		<ViewWeb className={'nucleus-management-container'}>
			<ViewWeb className={'connection-information-container'}>
				<TextWeb className={'connection-information-header'}>{localized('Provider')}</TextWeb>
				<ViewWeb>
					<ViewWeb className={'management-provider-row'} key={provider}>
						<TextWeb className={'management-provider-name'}>{localized(provider)}</TextWeb>
						{connection ? (
							<TextWeb className={'connection-status'}>{localized('Connected')}</TextWeb>
						) : (
							<TextWeb className={'connection-status'}>{localized('NoConnectionFound')}</TextWeb>
						)}
						<ViewWeb className={'edit-connection'}>
							<SkioldTouchableOpacity onPress={startEdit} itemFromParent={provider}>
								<SkioldImage width={25} height={25} imageData={PenIcon} />
							</SkioldTouchableOpacity>
							<SkioldTouchableOpacity onPress={deleteConnection}>
								<SkioldImage width={25} height={25} imageData={DeleteIcon} />
							</SkioldTouchableOpacity>
						</ViewWeb>
					</ViewWeb>
				</ViewWeb>
			</ViewWeb>
			<SkioldModal isOpen={modalOpen} shouldCloseOnOverlayClick={true} close={closeModal} minWidth={'400px'}>
				<LoginSettingsNucleusManagementConnection closeModal={closeModal} provider={provider} />
			</SkioldModal>
		</ViewWeb>
	);
});
