import ObjectID from 'bson-objectid';
import { findSow, findAnimalById } from '../stemanimal-helper/stem-animal-input-helper';
import { IStemAnimal, IPregnancyEvent, PregnancyState } from 'shared/api/api';
import { getPreviousEvent } from './sow-state-helper';

//Helper that find sets the necessary state for pregnancy event registration
export function PregnancyAnimalChanged(
	setState: (
		sow: IStemAnimal,
		animalEvents: IPregnancyEvent[],
		prevEvent: IPregnancyEvent,
		newPregnancyEvent: IPregnancyEvent,
	) => void,
	animalNumber: string,
	pregnancyEvent: IPregnancyEvent,
	pregnancyEvents: { [key: string]: IPregnancyEvent[] },
	stemAnimalId?: string,
) {
	let sow = findSow(animalNumber);
	if (stemAnimalId) {
		sow = findAnimalById(stemAnimalId);
	}

	if (sow) {
		let localSowCopy = { ...sow };
		const animalEvents = pregnancyEvents[sow.id!];
		const prevEvent = getPreviousEvent(animalEvents, pregnancyEvent);

		pregnancyEvent.stemAnimalId = sow.id;
		pregnancyEvent.siteId = sow.siteId;

		if (
			prevEvent &&
			pregnancyEvent.state !== PregnancyState.Mated &&
			(!pregnancyEvent.pregnancyId || pregnancyEvent.pregnancyId !== prevEvent.pregnancyId)
		) {
			pregnancyEvent.pregnancyId = prevEvent.pregnancyId;
		} else {
			if (!pregnancyEvent.pregnancyId) {
				pregnancyEvent.pregnancyId = new ObjectID().toHexString();
			}
		}
		setState(localSowCopy, animalEvents, prevEvent, pregnancyEvent);
	} else {
		pregnancyEvent.stemAnimalId = undefined;
		pregnancyEvent.siteId = undefined;
		pregnancyEvent.pregnancyId = undefined;
		setState({} as IStemAnimal, [], {} as IPregnancyEvent, pregnancyEvent);
	}
	return;
}
