import { Sorting } from '@devexpress/dx-react-grid';
import memoize from 'memoize-one';
import React from 'react';
import { connect } from 'react-redux';
import { IMated, IPregnancyEvent, IStemAnimal, PregnancyState } from 'shared/api/api';
import sokort_icon from 'shared/assets/src-assets/png/sokort_icon.png';
import { exactStartsWithMethodGrid } from 'shared/helpers/general-helpers';
import { NaturalSortDates } from 'shared/helpers/natural-sort';
import { RefType } from 'shared/helpers/ref-type';
import { findAnimalById, getActiveSows } from 'shared/helpers/stemanimal-helper/stem-animal-input-helper';
import { calculateLitterByPregnancy } from 'shared/helpers/stemanimal-helper/stemanimal-helper';
import { upsertBalanceWeanedPigsData } from 'shared/state/ducks/balance-weaned-pigs/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import 'web/view/components/event-lists/pregnancy-event-lists/sow-pregnancy-event-list-styles.scss';
import { SkioldModal } from 'web/view/components/skiold-components/skiold-modal/skiold-modal';
import 'web/view/components/skiold-components/skiold-table/skiold-double-header-table.scss';
import SkioldTableGrid, {
	SkioldTableGrid as SkioldTableRef,
} from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { SkioldTouchableOpacity } from 'web/view/components/skiold-components/skiold-touchable-opacity';
import { SowListConstants } from 'web/view/components/stem-animal/animal-lists/table-constants';
import { SkioldImage } from 'web/view/components/utils/svg/skiold-image';
import SowCard from 'web/view/pages/sow-card/sow-card';
import { getWebAnimalColorStyleTable } from 'web/web-helpers/general-web-helpers';
import {
	getEventListAnimalNumberCell,
	getEventListLitterCell,
	getEventListPregnancyDateCell,
	MatingEventListItem,
	MatingSummaryItem,
	PregnancyEventListMapDispatchToProps,
	PregnancyEventListMapStateToProps,
	PregnancyEventListProps,
	PregnancyEventListState,
} from '../sow-pregnancy-event-list-helper';

export class MatingEventListTable extends React.PureComponent<PregnancyEventListProps, PregnancyEventListState> {
	public SkioldTableRef: SkioldTableRef | undefined;

	private generateData = memoize((dateFrom, dateTo, departuredSows, pregnancies, departuredPregnancies) =>
		this.genereateListData(),
	);

	private defaultSorting = [{ columnName: 'animalNumber', direction: 'asc' }] as Sorting[];

	private readonly showSowCard = (d: MatingEventListItem) => {
		this.setState({ sowCardModal: true, stemAnimalId: d.stemAnimalId! });
	};

	private readonly getSowCardIconCell = (d: MatingEventListItem) => (
		<SkioldTouchableOpacity itemFromParent={d} onPress={this.showSowCard}>
			<SkioldImage
				width={SowListConstants.iconSVGWidth}
				height={SowListConstants.iconSVGWidth}
				imageData={sokort_icon}
			/>
		</SkioldTouchableOpacity>
	);

	private readonly getBoarCell = (d: MatingEventListItem) => d.boar;

	private readonly getInitCell = (d: MatingEventListItem) => {
		if (d.pregnancyEvent) {
			const userId = d.pregnancyEvent.initials ? d.pregnancyEvent.initials : d.pregnancyEvent.createdBy;
			const user = this.props.userProfiles.find(u => u.id === userId);
			return user ?.initials ?? '';
		}
		return ' ';
	};

	private readonly getSemenTypeCell = (d: MatingEventListItem) => {
		if (d.pregnancyEvent && d.pregnancyEvent.semenType) {
			return localized(d.pregnancyEvent.semenType);
		}
		return ' ';
	};

	private readonly getGradeCell = (d: MatingEventListItem) => {
		if (d.pregnancyEvent) {
			if (d.pregnancyEvent.grade && d.pregnancyEvent.grade.toString() !== '0') {
				return d.pregnancyEvent.grade;
			}
		}
		return ' ';
	};

	constructor(props: PregnancyEventListProps) {
		super(props);

		this.state = {
			columns: this.generateColumns(),
			columnExte: this.generateColumnsExtensions(),
			loading: false,
			commitAll: false,
			stemAnimalId: '',
			sowCardModal: false,
		};
	}

	public genereateListData() {
		let activeSows = getActiveSows();

		let listItems: MatingEventListItem[] = [];
		this.insertDataIntoListItems(activeSows, listItems, this.props.pregnancyEvents);

		if (this.props.departuredSows && this.props.departuredSows.length > 0) {
			this.insertDataIntoListItems(this.props.departuredSows, listItems, this.props.departuredPregnancies);
		}

		this.generateSummary(listItems);

		return listItems;
	}

	public generateColumnsExtensions() {
		let columnExt = [
			{
				columnName: 'sowCard',
				width: SowListConstants.iconWidth,
				filteringEnabled: false,
				sortingEnabled: false,
				resizingEnabled: false,
			},
			{
				columnName: 'animalNumber',
				width: SowListConstants.animalNrWidth,
			},			{
				columnName: 'breedRace',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'litter',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'matedDate',
				width: SowListConstants.entranceDateWidth,
			},
			{
				columnName: 'boar',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'k',
				width: SowListConstants.animalNrWidth,
			},
		];
		if (this.props.nucleusManagement) {
			columnExt.push({
				columnName: 'Type',
				width: SowListConstants.animalNrWidth,
			});
		}
		if (this.props.generalSettings.stemAnimalShowInitials) {
			columnExt.push({
				columnName: 'initial',
				width: SowListConstants.animalNrWidth,
			});
		}
		return columnExt;
	}

	public componentDidMount() {
		if (this.SkioldTableRef) {
			this.props.setSowsCount(this.SkioldTableRef.GetSortedData().length);
		}
	}

	public render() {
		return (
			<div className="alignTableCenterDefault">
				<SkioldTableGrid
					tableKey={'matingEventListTable'}
					columns={this.state.columns}
					ColumnExtensions={this.state.columnExte}
					data={this.generateData(
						this.props.dateFrom,
						this.props.dateTo,
						this.props.departuredSows,
						this.props.pregnancyEvents,
						this.props.departuredPregnancies,
					)}
					onFiltersChanged={this.onFiltersChanged}
					ref={this.setTableRef}
					sortHeaderId={this.defaultSorting}
				/>

				<SkioldModal
					shouldCloseOnOverlayClick={true}
					padding="0"
					isOpen={this.state.sowCardModal}
					close={this.closeModal}
					justify-content="flex-end"
					max-width="calc(100% - 220px)"
				>
					<SowCard stemAnimalIdFromParent={this.state.stemAnimalId} close={this.closeModal} />
				</SkioldModal>
			</div>
		);
	}

	private insertDataIntoListItems(
		stemAnimals: IStemAnimal[],
		listItems: MatingEventListItem[],
		pregnancyEvents: { [key: string]: IPregnancyEvent[] },
	) {
		stemAnimals.forEach(sow => {
			let sowPregnancyEvents = pregnancyEvents[sow.id!];
			if (sowPregnancyEvents && sowPregnancyEvents.length > 0) {
				let matedPregnancyEvents = sowPregnancyEvents.filter(a => a.state === PregnancyState.Mated);
				matedPregnancyEvents.forEach((pregnancyEvent: any) => {
					if (
						this.props.dateFrom === undefined ||
						this.props.dateTo === undefined ||
						(pregnancyEvent.date! >= this.props.dateFrom && pregnancyEvent.date! <= this.props.dateTo)
					) {
						let item = new MatingEventListItem();
						item.stemAnimalId = sow.id;
						item.litterNumber = calculateLitterByPregnancy(sow, pregnancyEvent, sowPregnancyEvents);
						const boar = findAnimalById(pregnancyEvent.boarId);
						item.boar = boar ? boar.animalNumber : '';
						item.animalNumber = sow.animalNumber;
						item.breedRace = sow.race
						item.pregnancyEvent = pregnancyEvent;
						listItems.push(item);
					}
				});
			}
		});
	}

	private onFiltersChanged = (events: MatingEventListItem[]) => {
		this.generateSummary(events);
	};

	private closeModal = () => {
		this.setState({ stemAnimalId: '', sowCardModal: false });
	};

	private generateSummary(pregnancyEvents: MatingEventListItem[]) {
		let summaryItem: MatingSummaryItem = {};
		summaryItem.sowCount = pregnancyEvents.length.toString();

		this.props.onSummaryChanged(
			<div className="flexDirectionRowWithWidth">
				<div className="summaryTextStyle"> {localized('Matings')}: </div>
				<div className="summaryTextStyle"> {summaryItem.sowCount} </div>
			</div>,
		);
	}

	private setTableRef = (m: any) => (m ? (this.SkioldTableRef = m) : {});

	private getStemAnimalNumberColor = (sow: MatingEventListItem) => {
		return sow ? getWebAnimalColorStyleTable(sow.stemAnimalId) : '';
	};

	private generateColumns() {
		const columns = [
			{
				name: 'sowCard',
				title: ' ',
				headerClassName: 'merged-header',
				sortable: false,
				getCellValue: this.getSowCardIconCell,
			},
			{
				name: 'animalNumber',
				headerClassName: 'merged-header',
				title: localized('animalNumber'),
				className: this.getStemAnimalNumberColor,
				filterFunction: exactStartsWithMethodGrid,
				getCellValue: getEventListAnimalNumberCell,
			},			{
				name: 'breedRace',
				title: localized('Race'),
			},
			{
				name: 'litter',
				title: localized('LitterNr'),
				getCellValue: getEventListLitterCell,
			},
			{
				name: 'matedDate',
				title: localized('Date'),
				pathToValue: 'pregnancyEvent',
				sortFunction: NaturalSortDates,
				getCellValue: getEventListPregnancyDateCell,
			},
			{
				name: 'boar',
				title: localized('boar'),
				getCellValue: this.getBoarCell,
			},
			{
				name: 'k',
				title: localized('Grade'),
				pathToValue: 'pregnancyEvent',
				getCellValue: this.getGradeCell,
			},
		];
		if (this.props.nucleusManagement) {
			columns.push({
				name: 'Type',
				title: localized('Type'),
				getCellValue: this.getSemenTypeCell,
			});
		}
		if (this.props.generalSettings.stemAnimalShowInitials) {
			columns.push({
				name: 'initial',
				title: localized('InitialsShort'),
				getCellValue: this.getInitCell,
			});
		}
		return columns;
	}
}

export default connect<
	ReturnType<typeof PregnancyEventListMapStateToProps>,
	ReturnType<typeof PregnancyEventListMapDispatchToProps>,
	RefType,
	WebAppState
>(PregnancyEventListMapStateToProps, PregnancyEventListMapDispatchToProps, null, { forwardRef: true })(
	MatingEventListTable,
);
