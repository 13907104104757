import React from 'react';
import { connect } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import {
	AnalysisPregnancyTableData,
	AnalysisResultPregnancyDayPeriod,
	IAnalysisResultPregnancySetting,
	ResultPerLitterTableItem,
	PeriodDto,
} from 'shared/api/api';
import { isNullOrUndefined } from 'shared/helpers/general-helpers';
import { RefType } from 'shared/helpers/ref-type';
import { AnalysisMatingPregnancy } from 'shared/state/ducks/analysis/analysis-modal/types';
import { localized } from 'shared/state/i18n/i18n';
import styled from 'styled-components';
import { WebAppState } from 'web/state/store.web';
import SkioldTableGrid, {
	SkioldTableGrid as SkioldTableRef,
} from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { GetAnalysisMatingPregnancyPdf } from 'web/web-helpers/pdf-helper/analysis-pdf-helpers/analysis-pdf-helper';
import { SkioldRangeSelector } from '../../skiold-components/skiold-range-selector/skiold-range-selector';
import { GroupedHeader } from '../../skiold-components/skiold-table/skiold-table-grid/skiold-table-grid-grouped-header';
const OuterDiv = styled.div`
	margin: 16px 0;
`;

interface PropsFromParent {
	openModal: (modalToOpen: AnalysisMatingPregnancy) => void;

	setting: IAnalysisResultPregnancySetting;
	onSettingChanged: (setting: IAnalysisResultPregnancySetting) => void;
	saveSetting: (setting: IAnalysisResultPregnancySetting) => void;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		data: state.analysisPregnancy.data ? state.analysisPregnancy.data.tableData : undefined,
		loading: state.resultsPerLitter.loading,
		profile: state.profile.active!,
	};
};
enum AnalysisPregnancySettingsKeysToModify {
	'weaningToMating',
	'matingToNonPregnant',
	'matingToMating',
}

export interface State {
	loading: boolean;
	modalOpen: boolean;
	modalSelected?: string;
	batchId?: string;
	count?: string;
}

type Props = ReturnType<typeof mapStateToProps> & PropsFromParent;

export class AnalysisResultsPregnancyTable extends React.PureComponent<Props, State> {
	public SkioldTableRef: SkioldTableRef | undefined;

	private GetNameField = (d: ResultPerLitterTableItem) =>
		d.name ? <div className={'textAlignStart'}>{localized('AnalysisResult.' + d.name)}</div> : ' ';

	private readonly mapMatingToMatingChildren: (
		value: AnalysisResultPregnancyDayPeriod,
		index: number,
		array: AnalysisResultPregnancyDayPeriod[],
	) => { columnName: string } = (wtm, index) => {
		return { columnName: 'matingToMating' + index };
	};

	private readonly mapMatingToNonPregnantChildren: (
		value: AnalysisResultPregnancyDayPeriod,
		index: number,
		array: AnalysisResultPregnancyDayPeriod[],
	) => { columnName: string } = (wtm, index) => {
		return { columnName: 'matingToNonPregnant' + index };
	};

	private readonly mapWeaningToMatingChildren: (
		value: AnalysisResultPregnancyDayPeriod,
		index: number,
		array: AnalysisResultPregnancyDayPeriod[],
	) => { columnName: string } = (wtm, index) => {
		return { columnName: 'weaningToMating' + index };
	};

	private readonly openWeaningToMatingModal = () => this.props.openModal(AnalysisMatingPregnancy.WeaningToMating);

	private readonly openMatingToMatingModal = () => this.props.openModal(AnalysisMatingPregnancy.MatingToMating);

	private readonly openMatingToNonPregnantModal = () =>
		this.props.openModal(AnalysisMatingPregnancy.MatingToNonPregnant);

	private readonly openMatingToDepartureAndAbortionModal = () =>
		this.props.openModal(AnalysisMatingPregnancy.MatingToAbortionAndDeparture);

	constructor(props: Props) {
		super(props);
		this.state = {
			loading: false,
			modalOpen: false,
			modalSelected: undefined,
			batchId: undefined,
		};
	}

	public generateColumnsExtensions() {
		let columnsExt = [
			{
				columnName: 'FromTo',
				width: 285,
			},
		];

		if (this.props.setting.weaningToMating) {
			columnsExt = columnsExt.concat(
				this.props.setting.weaningToMating.map((obj, index) => {
					return {
						columnName: 'weaningToMating' + index,
						width: 100,
					};
				}),
			);
		}
		if (this.props.setting.matingToNonPregnant) {
			columnsExt = columnsExt.concat(
				this.props.setting.matingToNonPregnant.map((obj, index) => {
					return {
						columnName: 'matingToNonPregnant' + index,
						width: 100,
					};
				}),
			);
		}

		if (this.props.setting.matingToMating) {
			columnsExt = columnsExt.concat(
				this.props.setting.matingToMating.map((obj, index) => {
					return {
						columnName: 'matingToMating' + index,
						width: 100,
					};
				}),
			);
		}

		columnsExt = columnsExt.concat([
			{
				columnName: 'Abortion',
				width: 150,
			},
			{ columnName: 'Departure', width: 150 },
		]);

		return columnsExt;
	}

	public render() {
		return (
			<ViewWeb>
				<OuterDiv>
					{this.props.loading ? (
						<ViewWeb className="spinner-container-active-analysis">
							<ClipLoader color="#f2ac40" size={70} />
						</ViewWeb>
					) : (
						<ViewWeb className="analysis-overview-table analysis-results-pregnancy-table">
							<SkioldTableGrid
								columns={this.generateColumns()}
								ref={this.setRef}
								tableKey={'analysisResultsPregnancyTableOverview'}
								data={this.props.data ? this.props.data : []}
								groupedColumns={this.groupedColumns(this.props.setting)}
								ColumnExtensions={this.generateColumnsExtensions()}
								sortable={false}
								ignoreSetCount={true}
								filterable={false}
								showPagination={true}
							/>
						</ViewWeb>
					)}
				</OuterDiv>
			</ViewWeb>
		);
	}
	private groupedColumns(setting: IAnalysisResultPregnancySetting): GroupedHeader[] {
		const groupedColumns: GroupedHeader[] = [
			{
				title: <ViewWeb className="paddingLeftTen">{localized('IntervalDays')}</ViewWeb>,
				children: [{ columnName: 'FromTo' }],
				className: 'textAlignStart',
			},
		];
		if (setting.weaningToMating) {
			groupedColumns.push({
				onClick: this.openWeaningToMatingModal,
				title: localized('WeaningToMating'),
				headerContent: <ViewWeb className="folder-arrow arrow-folded" />,
				children: setting.weaningToMating.map(this.mapWeaningToMatingChildren),
			});
		}
		if (setting.matingToNonPregnant) {
			groupedColumns.push({
				onClick: this.openMatingToNonPregnantModal,
				title: localized('MatingToNonPregnant'),
				headerContent: <ViewWeb className="folder-arrow arrow-folded" />,
				children: setting.matingToNonPregnant.map(this.mapMatingToNonPregnantChildren),
			});
		}
		if (setting.matingToMating) {
			groupedColumns.push({
				onClick: this.openMatingToMatingModal, // TODO handle open elaboration
				title: localized('MatingToMating'),
				headerContent: <ViewWeb className="folder-arrow arrow-folded" />,
				children: setting.matingToMating.map(this.mapMatingToMatingChildren),
			});
		}
		groupedColumns.push({
			title: localized('MatingTo'),
			onClick: this.openMatingToDepartureAndAbortionModal,
			headerContent: <ViewWeb className="folder-arrow arrow-folded" />,
			children: [{ columnName: 'Abortion' }, { columnName: 'Departure' }],
		});

		return groupedColumns;
	}

	public printData = (periodDto: PeriodDto) => {
		if (this.SkioldTableRef) {
			let data = this.SkioldTableRef.GetSortedData();
			GetAnalysisMatingPregnancyPdf(
				data,
				'AnalysisMatingPregnancy.pdf',
				this.props.profile.siteId!,
				Intl.DateTimeFormat().resolvedOptions().timeZone,
				this.props.profile.language,
				periodDto,
			);
		}
	};

	private setRef = (m: any) => {
		if (m) {
			this.SkioldTableRef = m;
		}
	};

	private generateColumns() {
		let columns: any[] = [
			{
				name: 'FromTo',
				title: <ViewWeb className="textAlignStart paddingLeftEight bold"> {localized('FromTo')}</ViewWeb>,
				headerClassName: 'justifyContentStart',
				isFixedLeft: true,
				getCellValue: this.GetNameField,
			},
		];

		if (this.props.setting.weaningToMating) {
			columns = columns.concat(
				this.props.setting.weaningToMating.map((obj, index) => {
					return {
						name: 'weaningToMating' + index,
						title: SkioldRangeSelector(
							obj.from,
							obj.to,
							this.onFromWeaningToMatingChanged,
							this.onToWeaningToMatingChanged,
							this.onBlurWeaningToMating,
							index,
							'whiteText',
						),
						headerClassName: 'justifyContentStart',
						getCellValue: this.GetWeaningToMatingValue(index),
					};
				}),
			);
		}
		if (this.props.setting.matingToNonPregnant) {
			columns = columns.concat(
				this.props.setting.matingToNonPregnant.map((obj, index) => {
					return {
						name: 'matingToNonPregnant' + index,
						title: SkioldRangeSelector(
							obj.from,
							obj.to,
							this.onFromMatingToNonPregnantChanged,
							this.onToMatingToNonPregnantChanged,
							this.onBlurMatingToNonPregnant,
							index,
							'whiteText',
						),
						headerClassName: 'justifyContentStart',
						getCellValue: this.GetMatingToNonPregnantValue(index),
					};
				}),
			);
		}

		if (this.props.setting.matingToMating) {
			columns = columns.concat(
				this.props.setting.matingToMating.map((obj, index) => {
					return {
						name: 'matingToMating' + index,
						title: SkioldRangeSelector(
							obj.from,
							obj.to,
							this.onFromMatingToMatingChanged,
							this.onToMatingToMatingChanged,
							this.onBlurMatingToMating,
							index,
							'whiteText',
						),
						headerClassName: 'justifyContentStart',
						getCellValue: this.GetMatingToMatingValue(index),
					};
				}),
			);
		}

		columns = columns.concat([
			{
				name: 'Abortion',
				title: <ViewWeb> {localized('Abortion')}</ViewWeb>,
				getCellValue: this.GetAbortionValue,
			},
			{
				name: 'Departure',
				title: <ViewWeb> {localized('Departure')}</ViewWeb>,
				getCellValue: this.GetDepartureValue,
			},
		]);
		return columns;
	}
	private onBlurWeaningToMating = (day?: number, index?: number) => {
		this.onBlurPeriodEvent(AnalysisPregnancySettingsKeysToModify.weaningToMating, day, index);
	};

	private onBlurMatingToNonPregnant = (day?: number, index?: number) => {
		this.onBlurPeriodEvent(AnalysisPregnancySettingsKeysToModify.matingToNonPregnant, day, index);
	};
	private onBlurMatingToMating = (day?: number, index?: number) => {
		this.onBlurPeriodEvent(AnalysisPregnancySettingsKeysToModify.matingToMating, day, index);
	};

	private onBlurPeriodEvent = (keyToModify: AnalysisPregnancySettingsKeysToModify, day?: number, index?: number) => {
		if (index !== undefined) {
			const copyOfSetting = { ...this.props.setting };
			if (copyOfSetting[AnalysisPregnancySettingsKeysToModify[keyToModify]]) {
				const copyOfAnalysisSettingDayArrayToModify = [
					...copyOfSetting[AnalysisPregnancySettingsKeysToModify[keyToModify]],
				] as AnalysisResultPregnancyDayPeriod[];
				if (copyOfAnalysisSettingDayArrayToModify[index].to) {
					if (
						index + 1 <= copyOfAnalysisSettingDayArrayToModify.length - 1 &&
						isNullOrUndefined(copyOfAnalysisSettingDayArrayToModify[index + 1].from) &&
						!isNullOrUndefined(day)
					) {
						copyOfAnalysisSettingDayArrayToModify[index + 1].from = day! + 1;
					}
				}

				copyOfSetting[
					AnalysisPregnancySettingsKeysToModify[keyToModify]
				] = copyOfAnalysisSettingDayArrayToModify;
				this.props.saveSetting(copyOfSetting);
			}
		}
	};

	private GetWeaningToMatingValue = index => {
		return (d: AnalysisPregnancyTableData) =>
			d.weaningToMating && d.weaningToMating[index] ? d.weaningToMating[index] : ' ';
	};

	private GetMatingToNonPregnantValue = index => {
		return (d: AnalysisPregnancyTableData) =>
			d.matingToNonPregnant && d.matingToNonPregnant[index] ? d.matingToNonPregnant[index] : ' ';
	};

	private GetMatingToMatingValue = index => {
		return (d: AnalysisPregnancyTableData) =>
			d.matingToMating && d.matingToMating[index] ? d.matingToMating[index] : ' ';
	};
	private GetAbortionValue = (d: AnalysisPregnancyTableData) => (d.abortion ? d.abortion : ' ');

	private GetDepartureValue = (d: AnalysisPregnancyTableData) => (d.departure ? d.departure : ' ');

	private onFromWeaningToMatingChanged = (day?: number, index?: number) => {
		this.onPeriodChanged(AnalysisPregnancySettingsKeysToModify.weaningToMating, day, index, true);
	};

	private onToWeaningToMatingChanged = (day?: number, index?: number) => {
		this.onPeriodChanged(AnalysisPregnancySettingsKeysToModify.weaningToMating, day, index, false);
	};

	private onFromMatingToNonPregnantChanged = (day?: number, index?: number) => {
		this.onPeriodChanged(AnalysisPregnancySettingsKeysToModify.matingToNonPregnant, day, index, true);
	};

	private onToMatingToNonPregnantChanged = (day?: number, index?: number) => {
		this.onPeriodChanged(AnalysisPregnancySettingsKeysToModify.matingToNonPregnant, day, index, false);
	};

	private onFromMatingToMatingChanged = (day?: number, index?: number) => {
		this.onPeriodChanged(AnalysisPregnancySettingsKeysToModify.matingToMating, day, index, true);
	};

	private onToMatingToMatingChanged = (day?: number, index?: number) => {
		this.onPeriodChanged(AnalysisPregnancySettingsKeysToModify.matingToMating, day, index, false);
	};

	private onPeriodChanged = (
		keyToModify: AnalysisPregnancySettingsKeysToModify,
		day?: number,
		index?: number,
		isFrom?: boolean,
	) => {
		if (index !== undefined) {
			const copyOfSetting = { ...this.props.setting };
			if (copyOfSetting[AnalysisPregnancySettingsKeysToModify[keyToModify]]) {
				const copyOfAnalysisSettingDayArrayToModify = [
					...copyOfSetting[AnalysisPregnancySettingsKeysToModify[keyToModify]],
				] as AnalysisResultPregnancyDayPeriod[];
				if (isFrom) {
					copyOfAnalysisSettingDayArrayToModify[index].from = day;
				} else {
					copyOfAnalysisSettingDayArrayToModify[index].to = day;
				}
				copyOfSetting[
					AnalysisPregnancySettingsKeysToModify[keyToModify]
				] = copyOfAnalysisSettingDayArrayToModify;
				this.props.onSettingChanged(copyOfSetting);
			}
		}
	};
}

export default connect<ReturnType<typeof mapStateToProps>, null, RefType, WebAppState>(mapStateToProps, null, null, {
	forwardRef: true,
})(AnalysisResultsPregnancyTable);
