import React from 'react';
import isEqual from 'react-fast-compare';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AnimalKind, AnimalNumberOnlyItem, IStemAnimal } from 'shared/api/api';
import PrinterGreyIcon from 'shared/assets/src-assets/png/printer_ikon_grey.png';
import { NaturalSort } from 'shared/helpers/natural-sort';
import {
	calculateAnimalKind1,
	calculateAnimalKindByDate,
	getActiveAnimalsAtDate,
} from 'shared/helpers/stemanimal-helper/stemanimal-helper';
import { GetYoungFemalesByDate, SetSowsCount } from 'shared/state/ducks/stem-animals/operations';
import { localized } from 'shared/state/i18n/i18n';
import { DepartureTypes } from 'shared/state/models/departure-types';
import { WebAppState } from 'web/state/store.web';
import { SkioldDatePicker } from 'web/view/components/skiold-components/skiold-date-picker/skiold-date-picker';
import SkioldTableGrid, {
	SkioldTableGrid as SkioldTableRef,
} from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import SkioldTableItemCounter from 'web/view/components/skiold-components/skiold-table/skiold-table-item-counter';
import { SkioldFetchIcon } from 'web/view/components/utils/skiold-fetch-icon';
import { SkioldIconSpinner } from 'web/view/components/utils/skiold-icon-spinner';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { GetAnimalNumberOnlyPdf } from '../active-animals-helper';
import './animal-number-only-table.scss';
import { PoolYoungFemaleEventTypes } from 'shared/helpers/pool-young-female-helper/pool-young-female-helper';
import { mergeArraysHashmap } from 'shared/helpers/reducer-helpers';

const mapStateToProps = (state: WebAppState) => {
	return {
		siteId: state.profile.active!.siteId,
		profile: state.profile.active!,
		pregnancyEvents: mergeArraysHashmap(
			state.pregnancyEvents.entities,
			state.pregnancyEvents.departuredPregnancies,
		),
		poolYoungFemale: state.poolYoungFemales.entities,
		youngFemales: state.stemAnimals.youngFemaleReportData?.animalNumbers,
		poolToSubtract: state.stemAnimals.youngFemaleReportData?.departedFromPool,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => {
	return {
		setSowsCount: (count: number) => SetSowsCount(count)(dispatch),
		getYoungFemalesByDate: (date: Date) => GetYoungFemalesByDate(date)(dispatch),
	};
};

export interface State {
	columns: any[];
	columnExte: any[];
	animalReportData: AnimalNumberOnlyItem[];
	date: Date;
	animalCount: number;
	mounted: boolean;
}

export interface PropsFromParent {
	animalKinds: AnimalKind[];
	selectedAnimalKindsText: string;
	topRight?: (topRight: JSX.Element) => void;
	topLeft?: (topLeft: JSX.Element) => void;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;
class AnimalNumberOnlyTable extends React.PureComponent<Props, State> {
	public SkioldTableRef: SkioldTableRef | undefined;
	private readonly getAnimalNumber = (d: AnimalNumberOnlyItem) => (
		<ViewWeb className="column-cell-container">
			{d.animalNumberRow ? (
				d.animalNumberRow.map((an, index) => (
					<TextWeb key={index} className="cell-value-container">
						{an}
					</TextWeb>
				))
			) : (
				<ViewWeb />
			)}
		</ViewWeb>
	);

	constructor(props: Props) {
		super(props);
		this.state = {
			columns: this.generateColumns(),
			columnExte: this.generateColumnsExtensions(),
			animalReportData: [],
			date: new Date(),
			animalCount: 0,
			mounted: false,
		};
	}

	public componentDidMount() {
		const { items, count } = this.generateTableData(this.state.date);
		this.props.getYoungFemalesByDate(this.state.date);
		this.setState({ animalReportData: items, animalCount: count, mounted: true });
		if (this.props.topRight) {
			this.props.topRight(this.renderTopRight());
		}
		if (this.props.topLeft) {
			this.props.topLeft(this.renderTopLeft());
		}
		this.props.setSowsCount(count);
	}

	public componentDidUpdate(prevProps: Props) {
		if (
			!this.props.animalKinds.includes(AnimalKind.YoungFemale) &&
			!isEqual(this.props.animalKinds, prevProps.animalKinds)
		) {
			const { items, count } = this.generateTableData(this.state.date);
			this.setState({ animalReportData: items, animalCount: count, mounted: true });
			if (this.props.topRight) {
				this.props.topRight(this.renderTopRight());
			}
			if (this.props.topLeft) {
				this.props.topLeft(this.renderTopLeft());
			}
			this.props.setSowsCount(count);
		}

		if (
			this.props.animalKinds.includes(AnimalKind.YoungFemale) &&
			(!isEqual(this.props.youngFemales, prevProps.youngFemales) ||
				!prevProps.animalKinds.includes(AnimalKind.YoungFemale))
		) {
			if (!this.props.youngFemales) {
				return;
			}
			const sortedAnimalNumbers = this.props.youngFemales.sort((a, b) => NaturalSort(a, b));
			const items: AnimalNumberOnlyItem[] = [];
			let item = this.newAnimalRowItem();
			for (let i = 0; i < sortedAnimalNumbers.length; i++) {
				if (i % 10 === 0) {
					if (item.animalNumberRow && item.animalNumberRow.length !== 0) {
						items.push(item);
					}
					item = this.newAnimalRowItem();
				}
				if (item.animalNumberRow) {
					item.animalNumberRow.push(sortedAnimalNumbers[i]!);
				}

				if (item.animalNumberRow && item.animalNumberRow.length > 0 && i === sortedAnimalNumbers.length - 1) {
					items.push(item);
				}
			}
			this.setState({ animalReportData: items, animalCount: sortedAnimalNumbers.length });
			if (this.props.topRight) {
				this.props.topRight(this.renderTopRight());
			}
			if (this.props.topLeft) {
				this.props.topLeft(this.renderTopLeft(sortedAnimalNumbers.length));
			}
		}
	}
	public render() {
		return (
			<ViewWeb className="animal-number-only-table">
				{this.state.mounted && (
					<SkioldTableGrid
						columns={this.state.columns}
						ColumnExtensions={this.state.columnExte}
						data={this.state.animalReportData}
						showPagination={false}
						filterable={false}
						sortable={false}
						ignoreSetCount={true}
					/>
				)}
			</ViewWeb>
		);
	}

	public renderTopRight = () => {
		return <SkioldIconSpinner title={'Print'} icon={PrinterGreyIcon} onPress={this.printPdf} />;
	};

	public renderTopLeft = (count?: number) => {
		return (
			<div className="top-left-container">
				<div className="date-picker-margin">
					<SkioldDatePicker
						onDateChanged={this.setDate}
						selectedDate={this.state.date}
						text="Date"
						includeTimeStamp={false}
					/>
				</div>
				<SkioldFetchIcon onPress={this.fetchReport} />
				<TextWeb className="top-left-label">{localized('Animals')}: </TextWeb>
				{this.props.animalKinds.includes(AnimalKind.YoungFemale) ? (
					<TextWeb className="top-left-label">{count} </TextWeb>
				) : (
					<SkioldTableItemCounter />
				)}
				{this.props.animalKinds.includes(AnimalKind.YoungFemale) && (
					<>
						<TextWeb className="top-left-label">{localized('ActivePoolYoungFemale')}: </TextWeb>
						<div className={'skiold-table-item-counter top-left-label'}>
							{this.getBoughtPoolYoungFemale() - this.getDeparturedPoolYoungFemale()}
						</div>
						;
					</>
				)}
			</div>
		);
	};

	public printPdf = async () => {
		await GetAnimalNumberOnlyPdf(
			this.state.animalReportData,
			this.state.animalCount,
			this.props.animalKinds.includes(AnimalKind.YoungFemale)
				? this.getBoughtPoolYoungFemale() - this.getDeparturedPoolYoungFemale()
				: 0,
			this.props.selectedAnimalKindsText + 'AnimalNumbers.pdf',
			this.props.profile.siteId!,
			Intl.DateTimeFormat().resolvedOptions().timeZone,
			this.props.profile.language,
		);
	};

	public setDate = (newDate: Date) => {
		this.setState(
			{
				date: newDate,
			},
			() => {
				if (this.props.topLeft) {
					this.props.topLeft(this.renderTopLeft(this.state.animalCount));
				}
			},
		);
	};

	private getBoughtPoolYoungFemale() {
		return (
			this.props.poolYoungFemale
				.filter(
					pyf =>
						(pyf.eventType === PoolYoungFemaleEventTypes.FromWeaner ||
							pyf.eventType === PoolYoungFemaleEventTypes.EntranceEventTypeBought ||
							pyf.eventType === PoolYoungFemaleEventTypes.FromFinisher) &&
						pyf.registrationDate &&
						pyf.registrationDate.withoutTime() <= this.state.date.withoutTime(),
				)
				.map(pyf => (pyf.total ? pyf.total : 0))
				.reduce((numA, numB) => numA + numB, 0) - (this.props.poolToSubtract ?? 0)
		);
	}

	private getDeparturedPoolYoungFemale() {
		return this.props.poolYoungFemale
			.filter(
				pyf =>
					(pyf.eventType === DepartureTypes.departureTypeSold ||
						pyf.eventType === DepartureTypes.departureTypeDead ||
						pyf.eventType === DepartureTypes.departureTypeKilled ||
						pyf.eventType === DepartureTypes.departureTypePutDown ||
						pyf.eventType === PoolYoungFemaleEventTypes.ToStemAnimal) &&
					pyf.registrationDate &&
					pyf.registrationDate <= this.state.date,
			)
			.map(pyf => (pyf.total ? pyf.total : 0))
			.reduce((numA, numB) => numA + numB, 0);
	}

	public generateTableData(date: Date): { items: AnimalNumberOnlyItem[]; count: number } {
		const items: AnimalNumberOnlyItem[] = [];

		const activeAnimals = getActiveAnimalsAtDate(date);

		if (!activeAnimals) {
			return { items: [], count: 0 };
		}

		const sortedAnimalNumbers = activeAnimals
			.filter(stemAnimal => stemAnimal.id && this.checkAnimalKind(stemAnimal))
			.map(activeAnimal => activeAnimal.animalNumber)
			.sort((a, b) => NaturalSort(a, b));
		let item = this.newAnimalRowItem();
		for (let i = 0; i < sortedAnimalNumbers.length; i++) {
			if (i % 10 === 0) {
				if (item.animalNumberRow && item.animalNumberRow.length !== 0) {
					items.push(item);
				}
				item = this.newAnimalRowItem();
			}
			if (item.animalNumberRow) {
				item.animalNumberRow.push(sortedAnimalNumbers[i]!);
			}

			if (item.animalNumberRow && item.animalNumberRow.length > 0 && i === sortedAnimalNumbers.length - 1) {
				items.push(item);
			}
		}
		return { items, count: sortedAnimalNumbers.length };
	}

	private checkAnimalKind(stemAnimal: IStemAnimal) {
		if (stemAnimal.id) {
			const animalKind = calculateAnimalKindByDate(stemAnimal, this.state.date);
			if (this.props.animalKinds.find(ak => ak === animalKind) !== undefined) {
				return true;
			}
		}
		return false;
	}

	private fetchReport = () => {
		if (this.props.animalKinds.includes(AnimalKind.YoungFemale)) {
			this.props.getYoungFemalesByDate(this.state.date);
		} else {
			const { items, count } = this.generateTableData(this.state.date);
			this.setState({ animalReportData: items, animalCount: count });
			this.props.setSowsCount(count);
		}
	};

	private newAnimalRowItem() {
		let item: AnimalNumberOnlyItem = new AnimalNumberOnlyItem();
		item.animalNumberRow = [];
		return item;
	}

	private generateColumnsExtensions() {
		const columns = [
			{
				columnName: 'animalNumbers',
				filteringEnabled: false,
				sortingEnabled: false,
			},
		];

		return columns;
	}

	private generateColumns() {
		const columns = [
			{
				name: 'animalNumbers',
				title: localized('animalNumbers'),
				sortable: false,
				filterable: false,
				getCellValue: this.getAnimalNumber,
			},
		];

		return columns;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AnimalNumberOnlyTable);
