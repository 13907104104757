import actionCreatorFactory from 'typescript-fsa';
import * as types from './types';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import { BalancePigEventTotalWeightBalancePigEvents } from 'shared/api/api';

const actionCreator = actionCreatorFactory(GenActionFactoryName('balanceWeanedPigsData'));

export const getBalanceWeanedPigsData = actionCreator.async<
	string | undefined,
	BalancePigEventTotalWeightBalancePigEvents,
	{ code: number; message: string }
>(types.SYNC_DATA_BALANCE_WEANED_PIGS);

export const UpsertBalanceWeanedPigsData = actionCreator.async<
	UpsertBalancePigEventTotalWeightBalancePigEvent,
	UpsertBalancePigEventTotalWeightBalancePigEvent,
	{ code: number; message: string }
>(types.UPSERT_BALANCE_WEANED_PIGS);

export interface UpsertBalancePigEventTotalWeightBalancePigEvent {
	event: BalancePigEventTotalWeightBalancePigEvents;
	idsToRemove?: string[];
	totalAmount?: number;
	totalWeight?: number;
}
