import React, { FC, useCallback, useMemo } from 'react';
import { ViewWeb } from '../../../../components/utils/web-view';
import { TextWeb } from '../../../../../web-helpers/styled-text-components';
import { localized, localizedDynamic } from '../../../../../../shared/state/i18n/i18n';
import { SkioldInput } from '../../../../components/skiold-components/skiold-input/skiold-input';
import './nucleus-management-breeding-settings.scss';
import { INucleusManagementBreedingSettings, NumberBatch } from '../../../../../../shared/api/api';
import { useSelector } from 'react-redux';
import { WebAppState } from '../../../../../state/store.web';
import { NumberBatchSetupHeader } from './number-batch-setup-header';
import { SkioldTouchableOpacity } from '../../../../components/skiold-components/skiold-touchable-opacity';
import { NumberBatchSetupRow } from './number-batch-setup-row';
import { SkioldFormsWrapper } from '../../../../components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from '../../../../components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { getProvider } from '../nucleus-management-helper';
import ObjectID from 'bson-objectid';
import { selectNucleusManagementOrAssignIdAccess } from '../../../../../../shared/state/ducks/site/reducer';
import { hasNucleusFeatureCEFN } from '../../../../../../shared/helpers/nucleus-management-helper/nucleus-management-helper';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';

interface Props {
	BreedingSettings: INucleusManagementBreedingSettings;
	setBreedingSettings: (event: React.SetStateAction<INucleusManagementBreedingSettings[]>) => void;
	index: number;
}

export const BreedingNumberInput: FC<Props> = React.memo(props => {
	const activeSite = useSelector((state: WebAppState) => state.site.site);

	const provider = useMemo(() => getProvider(activeSite.activeLicences), [activeSite]);
	const nucleusManagement = useSelector(selectNucleusManagementOrAssignIdAccess);

	const handleBreedingNumberChange = useCallback(
		(text: string) => {
			props.setBreedingSettings(current =>
				current.map((breedingSetting, index) => {
					if (index === props.index) {
						return { ...breedingSetting, breedingNumber: text };
					}

					return breedingSetting;
				}),
			);
		},
		[props],
	);

	const addNumberBatch = useCallback(() => {
		const newBatch = new NumberBatch();
		newBatch.id = new ObjectID().toHexString();
		props.setBreedingSettings(current =>
			current.map((breedingSetting, index) => {
				if (index === props.index) {
					return { ...breedingSetting, numberBatches: [...(breedingSetting.numberBatches ?? []), newBatch] };
				}

				return breedingSetting;
			}),
		);
	}, [props]);

	const formRows = useMemo(() => {
		let rows: FormRow[] = [
			{ header: localized('BreedingNumber') },
			{
				name: localized('number'),
				component: (
					<SkioldInput
						text={props.BreedingSettings.breedingNumber}
						onChangeText={handleBreedingNumberChange}
						className={'text-align-right'}
						maxLength={4}
						useAsInterger={true}
					/>
				),
			},
		];
		if (provider !== 'NoNucleusManagementLicence') {
			rows.push({
				name: localized('Provider'),
				component: <TextWeb>{localizedDynamic(provider)}</TextWeb>,
			});
		}
		return rows;
	}, [provider,props.BreedingSettings.breedingNumber]);

	return (
		<>
			<SkioldFormsWrapper formRows={formRows} containerClassName={'breeding-number-container'} />
			{!hasNucleusFeatureCEFN(nucleusManagement) && (
				<ViewWeb className={'number-batch-container'}>
					<ViewWeb className={'title-top-row'}>
						<TextWeb className='font-22'>{localized('NumberBatches')}</TextWeb>
						<SkioldButton onPress={addNumberBatch} title={localized('CreateNumberBatch')} />
					</ViewWeb>
					<NumberBatchSetupHeader />
					{props.BreedingSettings.numberBatches?.map((numberBatch, index) => (
						<NumberBatchSetupRow
							numberBatch={numberBatch}
							setBreedingSettings={props.setBreedingSettings}
							parentIndex={props.index}
							index={index}
							key={index}
							provider={provider}
						/>
					))}
				</ViewWeb>
			)}
		</>
	);
});
