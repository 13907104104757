import React, { FC, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectFamilyTreeById } from 'shared/state/ducks/stem-animals/selectors';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import PageContainer from '../page-container/page-container';
import { SkioldButton } from '../skiold-components/skiold-button/skiold-button';
import { ViewWeb } from '../utils/web-view';
import { FamilyTreePigComponent } from './family-tree-pig-component';
import { IStemAnimal } from '../../../../shared/api/api';

interface PropsFromParent {
	stemAnimalId: string;
	closeModal: () => void;
}

export const FamilyTreeOverview: FC<PropsFromParent> = React.memo(({ stemAnimalId, closeModal }) => {
	const {
		current,
		dad,
		mom,
		momGrandmother,
		momGrandfather,
		dadGrandmother,
		dadGrandfather,
	} = useSelector((state: WebAppState) =>
		selectFamilyTreeById(
			state.stemAnimals.entities,
			state.stemAnimals.departuredAnimals as IStemAnimal[],
			stemAnimalId,
		),
	);
	return (
		<PageContainer className="family-page-container">
			<ViewWeb className="family-tree-overview">
				<FamilyTreePigComponent title={localized('Family.TheAnimal')} stemAnimal={current} />
				<FamilyTreePigComponent title={localized('Family.Father')} stemAnimal={dad} />
				<FamilyTreePigComponent title={localized('Family.Mother')} stemAnimal={mom} />
				<FamilyTreePigComponent title={localized('Family.FatherGrandfather')} stemAnimal={dadGrandfather} />
				<FamilyTreePigComponent title={localized('Family.FatherGrandmother')} stemAnimal={dadGrandmother} />
				<FamilyTreePigComponent title={localized('Family.MotherGrandfather')} stemAnimal={momGrandfather} />
				<FamilyTreePigComponent title={localized('Family.MotherGrandmother')} stemAnimal={momGrandmother} />

				<SkioldButton title={localized('Close')} className="close-button" onPress={closeModal} />
			</ViewWeb>
		</PageContainer>
	);
});
