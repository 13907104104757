import { IBuilding } from "shared/api/api";
import { ExceptionMessage } from "shared/helpers/exception-message";
import { localized } from "shared/state/i18n/i18n";
import { PenFormData } from "shared/state/models/locations/pen-form-data";
import { SectionFormData } from "shared/state/models/locations/section-form-data";
import { showAlert } from "web/view/components/skiold-alert/skiold-alert";

//Used in edit and create
export function validateBuilding(
    building: IBuilding,
    buildings: IBuilding[],
    sectionFormData: SectionFormData | undefined,
    PenData: PenFormData | undefined
) {
    if (!building.siteId) {
        showAlert(localized(ExceptionMessage.VALIDATION_ERROR_SITE_NOT_SET));
        return false;
    }

    if (!building.buildingNumber) {
        showAlert(localized(ExceptionMessage.VALIDATION_ERROR_BUILDING_NUMBER_NOT_SET));
        return false;
    }

    if (!building.name) {
        showAlert(localized(ExceptionMessage.VALIDATION_ERROR_BUILDING_NAME_NOT_SET));
        return false;
    }

    if (!building.productionForm) {
        showAlert(localized(ExceptionMessage.VALIDATION_ERROR_PRODUCTION_FORM_NOT_SET));
        return false;
    }
    const buildingFound = buildings.find(b => b.buildingNumber === building.buildingNumber);
    if (buildingFound) {
        if (buildingFound.id !== building.id) {
            showAlert(localized(ExceptionMessage.VALIDATION_ERROR_BUILDING_NUMBER_ALREADY_EXISTS));
            return false;
        }
    }

    if (sectionFormData && PenFormData) {
        if (!sectionFormData.selectedLocationType) {
            showAlert(localized(ExceptionMessage.VALIDATION_ERROR_SECTION_TYPE_NOT_SET));
            return false;
        }
        if (!PenData!.capacity || (PenData!.capacity && PenData!.capacity! < 1)) {
            showAlert(localized(ExceptionMessage.VALIDATION_ERROR_CANNOT_BE_NEGATIVE_OR_ZERO));
            return false;
        }
    }

    return true;
}