import React, { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { DeadPigletsByWeek, SkioldOneClient } from 'shared/api/api';
import { SkioldDigitalApiBaseUrl } from 'shared/constants';
import { WebAppState } from 'web/state/store.web';
import SkioldTableGrid from '../../skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { localized } from 'shared/state/i18n/i18n';
import { ViewWeb } from '../../utils/web-view';
import { Heading } from '../../utils/heading';
import { ClipLoader } from 'react-spinners';
import { DashboardModalHeader } from '../dashboard-modal-header';
interface PropsFromParent {
	batchId?: string;
	closeModal: () => void;
}

export const DeadPigletsDasbboardTable: FC<PropsFromParent> = React.memo(({ batchId, closeModal }) => {
	const siteId = useSelector((state: WebAppState) => state.profile.active && state.profile.active.siteId);
	const matingBatch = useSelector((state: WebAppState) => state.matingBatches.entities.find(mb => mb.id === batchId));
	const [tableItems, setTableItems] = useState<DeadPigletsByWeek[]>([]);

	const [pending, setPending] = useState<boolean>(false);
	useEffect(() => {
		setPending(true);
		new SkioldOneClient(SkioldDigitalApiBaseUrl)
			.dashboard_GetDeadPigletsByWeeks(siteId, batchId)
			.then((tableData: DeadPigletsByWeek[]) => {
				setTableItems(tableData);
				setPending(false);
			})
			.catch(() => {
				setPending(false);
				return;
			});
	}, [siteId, batchId]);

	const columns = useMemo(() => {
		return [
			{
				name: 'week',
				title: localized('week'),
			},
			{
				name: 'deadPiglets',
				title: localized('Count'),
			},
		];
	}, []);

	const columnsExt = useMemo(() => {
		return [
			{
				columnName: 'week',
				width: 100,
			},
			{
				columnName: 'deadPiglets',
				width: 100,
			},
		];
	}, []);

	return (
		<ViewWeb>
			<Heading text={localized('DeadPiglets')} />
			<DashboardModalHeader closeModal={closeModal} matingBatch={matingBatch} hideShowCount={true} />

			{pending ? (
				<ViewWeb className="spinner-container-active-dashboard">
					<ClipLoader color="#f2ac40" size={70} />
				</ViewWeb>
			) : (
				<ViewWeb className="dashboard-modal-table-no-margin">
					<SkioldTableGrid
						tableKey="dead-piglets-dashboard-table"
						data={tableItems}
						sortable={false}
						filterable={false}
						columns={columns}
						ColumnExtensions={columnsExt}
						ignoreSetCount={true}
					/>
				</ViewWeb>
			)}
		</ViewWeb>
	);
});
