import { default as ObjectID } from 'bson-objectid';
import { FeedingSubject, IFeedingSubject } from 'shared/api/api';
import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import { StoreContainer } from 'shared/state/store-container';
import * as Action from './actions';

export function GetSyncData(siteId: string) {
	return AsyncOperationBuilder2(Action.getData, client => client.feedingSubject_Get(siteId), { siteId });
}

export function SaveFeedingSubject(subject: IFeedingSubject) {
	const state = StoreContainer.getState();
	const prevEntity = state.feedingSubject.entities.find(subj => subj.id === subject.id);
	if (!subject.id) {
		subject.id = new ObjectID().toHexString();
	}

	return AsyncOperationBuilder2(
		Action.saveFeedingSubject,
		client => client.feedingSubject_Post(FeedingSubject.fromJS(subject)),
		FeedingSubject.fromJS(subject),
		prevEntity,
	);
}
