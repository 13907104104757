import ObjectID from 'bson-objectid';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	ISlaughterhouseConnectionDto,
	ISupplierDetailDto,
	SlaughterhouseCompany,
	SlaughterhouseConnectionDto,
} from 'shared/api/api';
import createDelieveryIcon from 'shared/assets/src-assets/png/create_delievery_grey.png';
import { TicanAuthUrl, TicanClientId, TicanRedirectUri } from 'shared/constants';
import {
	GetSlaughterhouseConnectionData,
	SlaughterHouseConnectionCreateConnection,
} from 'shared/state/ducks/connection-slaughter-house/operations';
import {
	AddSupplier,
	GetSyncSupplierDetailsData,
	PostSupplierDetails,
} from 'shared/state/ducks/supplier-details-slaughter-house/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldModal } from 'web/view/components/skiold-components/skiold-modal/skiold-modal';
import { SkioldIconSpinner } from 'web/view/components/utils/skiold-icon-spinner';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { ConnectionSlaughterHouse } from './connection-slaughter-house';
import './connection-slaughter-house-overview.scss';
import { DelieverySlaughterHouse } from './delievery-slaughter-house';
import { LoginInformationSlaughterHouse } from './login-information-slaughter-house';
import { makeid } from 'shared/helpers/general-helpers';

export const ConnectionSlaughterHouseOverview: FC = React.memo(() => {
	const dispatch = useDispatch();
	const siteId = useSelector((state: WebAppState) => state.site.site.id);

	useEffect(() => {
		GetSlaughterhouseConnectionData(siteId)(dispatch);
		GetSyncSupplierDetailsData(siteId)(dispatch);
	}, [dispatch, siteId]);

	const slaughterhouseCompany = useSelector(
		(state: WebAppState) => state.slaughterhouseConnections.selectedConnection,
	);
	const slaughterhouseConnections = useSelector(
		(state: WebAppState) => state.slaughterhouseConnections.slaughterhouseConnections,
	);
	const profile = useSelector((state: WebAppState) => state.profile.active);

	const [dataState, setDataState] = useState(new Array<ISupplierDetailDto>());
	const [modalOpen, openModal] = useState(false);

	const slaughterhousePenPressed = (slaughterhouseCompany: string) => {
		if (slaughterhouseCompany === SlaughterhouseCompany.Tican) {
			redirectToTican();
		} else {
			openModal(true);
		}
	};

	const closeModal = () => openModal(false);
	const onDataChanged = (data: ISupplierDetailDto[]) => setDataState(data);
	const onSupplierDetailsSave = useCallback(
		(data: ISupplierDetailDto[]) => {
			const copyOfSlaughterHouseConnections = [...slaughterhouseConnections];
			data.forEach(sdts => {
				let connection = copyOfSlaughterHouseConnections.find(
					shc => shc.slaughterhouse === slaughterhouseCompany,
				);
				if (connection) {
					sdts.slaughterHouseConnectionId = connection.id;
				} else {
					connection = SlaughterhouseConnectionDto.fromJS({
						id: new ObjectID().toHexString(),
						siteId: sdts.siteId,
						createdBy: sdts.createdBy,
						modifiedBy: sdts.modifiedBy,
						slaughterhouse: slaughterhouseCompany!,
						modifiedOn: new Date(),
						createdOn: new Date(),
					} as ISlaughterhouseConnectionDto);
					SlaughterHouseConnectionCreateConnection(connection)(dispatch);
					copyOfSlaughterHouseConnections.push(connection);
				}

				sdts.slaughterhouseCompany = slaughterhouseCompany;
				sdts.slaughterHouseConnectionId = connection && connection.id;
				PostSupplierDetails(sdts)(dispatch);
			});
		},
		[slaughterhouseConnections, slaughterhouseCompany, dispatch],
	);
	const createDelieveryNumber = useCallback(() => {
		if (slaughterhouseCompany) {
			dispatch(
				AddSupplier({
					fromDate: new Date(),
					siteId: profile?.siteId,
					createdBy: profile?.id,
					createdOn: new Date(),
					modifiedBy: profile?.id,
					modifiedOn: new Date(),
					id: new ObjectID().toHexString(),
					slaughterhouseCompany: slaughterhouseCompany as SlaughterhouseCompany,
				} as ISupplierDetailDto),
			);
		} else {
			alert(localized('Error_SlaughterHouse_required'));
		}
	}, [slaughterhouseCompany, profile, dispatch]);
	return (
		<ViewWeb>
			<div className="createButtonContainer">
				<SkioldIconSpinner
					containerClassName="print-special-period"
					title={localized('createDelievery')}
					icon={createDelieveryIcon}
					onPress={createDelieveryNumber}
				/>
			</div>

			<div className="connection-slaughter-house-setting">
				<ViewWeb className="firstColumn">
					<ConnectionSlaughterHouse onPenIconPressed={slaughterhousePenPressed} />
				</ViewWeb>
				<ViewWeb className="secondColumn">
					<DelieverySlaughterHouse onDataChanged={onDataChanged} />
				</ViewWeb>
				<SkioldModal minWidth="400px" shouldCloseOnOverlayClick={true} isOpen={modalOpen} close={closeModal}>
					<LoginInformationSlaughterHouse
						closeModal={closeModal}
						slaughterHouse={slaughterhouseCompany as SlaughterhouseCompany}
					/>
				</SkioldModal>
			</div>
			<div className="buttonContainer">
				<SkioldButton
					theme="orange"
					itemFromParent={dataState}
					onPress={onSupplierDetailsSave}
					className="saveButton"
					title={localized('Save')}
				></SkioldButton>
			</div>
		</ViewWeb>
	);
});
function redirectToTican() {
	window.open(
		TicanAuthUrl +
			'?client_id=' +
			TicanClientId +
			'&redirect_uri=' +
			TicanRedirectUri +
			'&response_type=code' +
			'&scope=offline_access tican.default' +
			'&state=' +
			makeid(10) +
			'&code_challenge=M9azmNqWx70ALvGtTBpPp_GN4duyChi6BMQxNdNyeU4' +
			'&code_challenge_method=S256',
	);
}
