import memoize from 'memoize-one';
import React from 'react';
import { connect } from 'react-redux';
import { exactStartsWithMethodGrid, rangeFilterMethodGrid } from 'shared/helpers/general-helpers';
import { NaturalSort } from 'shared/helpers/natural-sort';
import { RefType } from 'shared/helpers/ref-type';
import { checkSaveWorkListStatus } from 'shared/helpers/work-list-helpers/general-work-list-helper';
import {
	generateMatingBatchSetupLardScanningEvent,
	genereateLardScanningEventListTableData,
} from 'shared/helpers/work-list-helpers/lard-scanning-event-list-helpers/lard-scanning-event-work-list-helper';
import {
	LardScanningEventListMapDispatchToProps,
	LardScanningEventListMapStateToProps,
	LardScanningEventListProps,
	LardScanningEventListState,
	LardScanningEventListTableItem,
} from 'shared/helpers/work-list-helpers/lard-scanning-event-list-helpers/lard-scanning-event-work-list-item';
import { localized } from 'shared/state/i18n/i18n';
import { SharedAppState } from 'shared/state/store.shared';
import SkioldTableGrid, {
	SkioldTableGrid as SkioldTableRef,
} from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { defaultSortingWorklists } from 'web/view/components/work-lists/work-list-helper';
import { getWebAnimalColorStyleTable } from 'web/web-helpers/general-web-helpers';
import { ShowConfirmAlert, showAlert } from '../../skiold-alert/skiold-alert';
import { SkioldCheckbox } from '../../skiold-components/skiold-checkbox/skiold-checkbox';
import { SkioldDatePicker } from '../../skiold-components/skiold-date-picker/skiold-date-picker';
import { SkioldBoldDecimalInput } from '../../skiold-components/skiold-decimal-input/skiold-bold-decimal-input';
import '../list-setup.scss';
import './lard-scanning-event-work-list-table.scss';
import { validateSowLardScanningEvent } from 'shared/helpers/stemanimal-helper/register-lard-event-helper';
import { ILardScanningEvent } from 'shared/api/api';

export class LardScanningEventListTable extends React.PureComponent<
	LardScanningEventListProps,
	LardScanningEventListState
> {
	public SkioldTableRef: SkioldTableRef | undefined;
	private generateDataWithSetState = memoize(
		(animals, pregnancies, workListSetting, selectedBatch, lardScannings, batch) => {
			let data = genereateLardScanningEventListTableData(
				this.props,
				this.state,
			).sort((a: LardScanningEventListTableItem, b: LardScanningEventListTableItem) =>
				NaturalSort(b.cycleDays, a.cycleDays),
			);

			this.setState({
				workListData: data,
				columns: this.generateColumns(),
				columnExte: this.generateColumnsExtensions,
			});
		},
	);

	constructor(props: LardScanningEventListProps) {
		super(props);
		const getMatingBatches = generateMatingBatchSetupLardScanningEvent(
			this.props.workListSetting,
			this.props.matingBatches,
		);
		this.state = {
			columns: [],
			loading: false,
			workListData: [],
			columnExte: [],
			createTreatmentModal: false,
			stemAnimalIdToEdit: '',
			commitAll: false,
			matingBatchOptions: getMatingBatches.matingBatchesOptions,
			SelectedMatingBatch: getMatingBatches.selectedOption,
		};
	}

	public componentDidMount() {
		this.generateDataWithSetState(
			this.props.stemAnimals,
			this.props.pregnancyEvents,
			this.props.workListSetting,
			this.state.SelectedMatingBatch,
			this.props.lardScanningEvents,
			this.props.batch,
		);

		this.props.pregnancyEventGetSyncData();
		this.props.stemAnimalGetSyncData();
		this.props.moveEventGetSyncData();
		this.props.getLocations();
		this.props.getWorkListSettingsBySiteId();
		this.props.lardScanningsGetSyncData();
	}

	public componentDidUpdate() {
		this.generateDataWithSetState(
			this.props.stemAnimals,
			this.props.pregnancyEvents,
			this.props.workListSetting,
			this.state.SelectedMatingBatch,
			this.props.lardScanningEvents,
			this.props.batch,
		);
	}

	public ResetWorkList() {
		this.generateDataWithSetState(
			this.props.stemAnimals,
			this.props.pregnancyEvents,
			this.props.workListSetting,
			this.state.SelectedMatingBatch,
			this.props.lardScanningEvents,
			this.props.batch,
		);
	}

	public getWorkListSetting() {
		const { workListSetting } = this.props;
		return workListSetting;
	}
	public render() {
		return (
			<ViewWeb className={'lard-scanning-event-work-list-table align-items-center'}>
				<SkioldTableGrid
					tableKey={'lardScanningEventWorkistTable'}
					columns={this.state.columns}
					className={'work-list-table'}
					ref={this.setRef}
					data={this.state.workListData}
					ColumnExtensions={this.state.columnExte}
					sortHeaderId={defaultSortingWorklists}
					showPagination={true}
				/>
			</ViewWeb>
		);
	}

	public async CheckSavePregnancyEventListsStatus() {
		if (await checkSaveWorkListStatus(this.state.workListData, ShowConfirmAlert)) {
			await this.SaveWorklist();
		}
		return true;
	}
	public setRef = (s: any) => {
		if (s) {
			this.SkioldTableRef = s;
			this.forceUpdate();
		}
	};

	public generateColumnsExtensions = [
		{
			columnName: 'cycleDays',
			width: 60,
		},
		{
			columnName: 'litter',
			width: 80,
		},
		{
			columnName: 'animalNumber',
			width: 80,
		},
		{
			columnName: 'batchNumber',
			width: 80,
		},
		{
			columnName: 'date',
			width: 80,
		},
		{
			columnName: 'lardWidth',
			width: 100,
			filteringEnabled: false,
		},
		{ columnName: 'completed', width: 120 },
	];

	public generateCommitAllCheckBox = () => {
		return (
			<ViewWeb className="checkbox-view-filter-style">
				<SkioldCheckbox isChecked={this.state.commitAll} className="checkbox-style" onClick={this.commitAll} />
			</ViewWeb>
		);
	};

	public commitAll = () => {
		const commitAll = !this.state.commitAll;
		this.setState(prevState => {
			const workListData = [...prevState.workListData];
			for (const [i] of workListData.entries()) {
				workListData[i] = { ...workListData[i], isChecked: commitAll };
			}
			this.props.SetCheckedCount(
				workListData.reduce((a, b) => a + (b && b.isChecked ? 1 : 0), 0),
				true,
			);
			return { workListData, commitAll };
		});
	};

	public SaveWorklist = async () => {
		const isCheckedItems = this.state.workListData.filter(item => item.isChecked);
		const animalIds = isCheckedItems.map(item => item.stemAnimalId);
		let listOfPromises: Array<Promise<void>> = [];
		isCheckedItems.forEach(item => {
			const lardScanningEvent = {
				date: item.date!,
				stemAnimalId: item.stemAnimalId,
				width: item.lardWidth,
				siteId: this.props.siteId,
			} as ILardScanningEvent;
			if (validateSowLardScanningEvent(lardScanningEvent, item.stemAnimalId, showAlert)) {
				listOfPromises.push(this.props.saveLardScanningEvent(lardScanningEvent));
			}
		});
		const items = this.state.workListData.filter(data => !animalIds.includes(data.stemAnimalId));
		await Promise.all(listOfPromises);
		this.setState({ workListData: items });
	};

	private getStemAnimalNumberColor = (sow: LardScanningEventListTableItem) => {
		return sow && sow ? getWebAnimalColorStyleTable(sow.stemAnimalId) : '';
	};
	private animalNumberFilter = (value: any, filterValue: any, row: LardScanningEventListTableItem) => {
		return (
			row.animalNumber &&
			String(localized(row.animalNumber).toLocaleLowerCase()).startsWith(filterValue.value.toLocaleLowerCase())
		);
	};

	private generateColumns() {
		const columns: any = [
			{
				name: 'cycleDays',
				title: localized('Days'),
				headerClassName: 'merged-header',
				filterFunction: rangeFilterMethodGrid,
				getCellValue: (tableItem: LardScanningEventListTableItem) =>
					tableItem.cycleDays && !isNaN(tableItem.cycleDays) ? tableItem.cycleDays : '',
			},
			{
				name: 'litter',
				headerClassName: 'merged-header',
				title: localized('Litters'),
				filterFunction: exactStartsWithMethodGrid,
				getCellValue: this.getLitterNumber,
			},
			{
				name: 'animalNumber',
				headerClassName: 'merged-header',
				title: localized('animalNumber'),
				filterFunction: this.animalNumberFilter,
				className: this.getStemAnimalNumberColor,
				getCellValue: this.getAnimalNumber,
			},
			{
				name: 'batchNumber',
				headerClassName: 'merged-header',
				title: localized('Batch'),
				filterFunction: exactStartsWithMethodGrid,
			},
			{
				name: 'date',
				title: localized('Date'),
				headerClassName: 'merged-header',
				getCellValue: this.getDateColumn,
			},
			{
				name: 'lardWidth',
				title: localized('sowLardWidth'),
				sortable: false,
				filterable: false,
				getCellValue: (tableItem: LardScanningEventListTableItem) => (
					<SkioldBoldDecimalInput
						text={tableItem.lardWidth}
						onChangeNumber={number => {
							let dataArrayCopy = [...this.state.workListData];
							let index = dataArrayCopy.findIndex(b => b.stemAnimalId === tableItem.stemAnimalId);
							let dataCopy = { ...dataArrayCopy[index] };
							dataCopy.lardWidth = number;
							dataArrayCopy[index] = dataCopy;
							this.setState({ workListData: dataArrayCopy });
						}}
						className="text-input-style"
					/>
				),
			},
			{
				name: 'completed',
				title: localized('completed'),
				headerClassName: 'merged-header',
				sortable: false,
				filter: this.generateCommitAllCheckBox,
				getCellValue: (tableItem: LardScanningEventListTableItem) => (
					<ViewWeb className="Checkbox-view-style">
						<SkioldCheckbox
							isChecked={tableItem.isChecked!}
							className="checkbox-style"
							onClick={() => {
								this.commitedClicked(tableItem);
							}}
						/>
					</ViewWeb>
				),
			},
		];
		return columns;
	}
	private getLitterNumber = (tableItem: LardScanningEventListTableItem) => tableItem.litter;
	private getDateColumn = (tableItem: LardScanningEventListTableItem) => (
		<SkioldDatePicker
			onDateChanged={newDate => {
				let dataArrayCopy = [...this.state.workListData];
				let index = dataArrayCopy.findIndex(b => b.stemAnimalId === tableItem.stemAnimalId);
				let dataCopy = { ...dataArrayCopy[index] };
				dataCopy.date = newDate;
				dataArrayCopy[index] = dataCopy;
				this.setState({ workListData: dataArrayCopy });
			}}
			containerClassName="datepicker-ctyle"
			selectedDate={tableItem.date}
			theme={'dark'}
			color={'grey'}
			maxDate={new Date(Date.now())}
		/>
	);
	private getAnimalNumber = (tableItem: LardScanningEventListTableItem) => tableItem.animalNumber;

	private commitedClicked = (tableItem: LardScanningEventListTableItem) => {
		const dataIndex = this.state.workListData.findIndex(a => a.stemAnimalId === tableItem.stemAnimalId);

		this.setState(prevState => {
			const workListData = [...prevState.workListData];
			workListData[dataIndex] = { ...workListData[dataIndex], isChecked: !tableItem.isChecked };
			this.props.SetCheckedCount(workListData[dataIndex].isChecked ? 1 : -1);
			return { workListData, commitAll: false };
		});
	};
}

export default connect<
	ReturnType<typeof LardScanningEventListMapStateToProps>,
	ReturnType<typeof LardScanningEventListMapDispatchToProps>,
	RefType,
	SharedAppState
>(LardScanningEventListMapStateToProps, LardScanningEventListMapDispatchToProps, null, { forwardRef: true })(
	LardScanningEventListTable,
);
