import React from 'react';
import { SkioldFormInput } from '../skiold-input/skiold-form-input';
import { DecimalNumberChanged } from 'shared/helpers/number-helper';
import { isNullOrUndefined } from 'shared/helpers/general-helpers';

export interface PropsFromParent {
	onChangeNumber: (newText: number | undefined, itemFromParent?: any) => void;
	text?: number | null;
	maxLength?: number;
	className?: string;
	itemFromParent?: any;
	isOnlyPositive?: boolean;
	onBlur?: () => void;
	numberOfDecimals?: number;
	disabled?: boolean;
}

interface State {
	text: string | undefined;
}

export class SkioldFormDecimalInput extends React.PureComponent<PropsFromParent, State> {
	public static defaultProps: Partial<PropsFromParent> = {
		numberOfDecimals: 1,
	};
	public static getDerivedStateFromProps(nextProps: PropsFromParent, prevState: State) {
		let splittedText = prevState.text ? prevState.text.toString().split('.') : undefined;
		if (
			prevState &&
			splittedText &&
			prevState.text !== undefined &&
			prevState.text[prevState.text.length - 1] !== '.' &&
			((splittedText[0] && !splittedText[1]) || (splittedText[1] && !splittedText[1].endsWith('0'))) &&
			nextProps.text !== undefined
		) {
			let num = nextProps.text;
			if (nextProps.numberOfDecimals) {
				num =
					Math.floor(nextProps.text! * Math.pow(10, nextProps.numberOfDecimals)) /
					Math.pow(10, nextProps.numberOfDecimals);
			}
			return {
				...prevState,
				text: num !== undefined && num !== null ? num.toString() : undefined,
			};
		} else if (nextProps.text === undefined && prevState.text !== '-') {
			return {
				...prevState,
				text: undefined,
			};
		} else if (Number(nextProps.text).toString() !== 'NaN' && prevState.text === undefined) {
			return {
				...prevState,
				text: nextProps.text,
			};
		}

		return prevState;
	}
	constructor(props: PropsFromParent) {
		super(props);
		this.state = {
			text: !isNullOrUndefined(this.props.text) ? this.props.text!.toString() : undefined,
		};
	}

	public render() {
		return (
			<SkioldFormInput
				className={this.props.className}
				onChangeText={this.onTextChanged}
				text={this.state.text}
				maxLength={this.props.maxLength}
				onBlur={this.props.onBlur}
				editable={!this.props.disabled}
			/>
		);
	}

	private onTextChanged = (newText: string) => {
		let textstring = newText
			.replace(/(?!^)-/g, '') // removes any - that is not first character in string
			.replace(/,/g, '.') // replaces , with .
			.replace(/\..*/, c => '.' + c.replace(/\./g, () => '')) // ensures there is at most 1 . in string
			.replace(/ /g, ''); // replaces any spaces with nothing

		if (this.props.numberOfDecimals !== undefined) {
			const reg = new RegExp('^(\\d*)\\.?(\\d){0,' + this.props.numberOfDecimals + '}$');
			const matchDecimal = textstring.match(reg);
			if (!matchDecimal) {
				return;
			}
		}
		let rgx = /^-?[0-9]*\.?[0-9]*$/;
		if (this.props.isOnlyPositive) {
			textstring = textstring.replace(/[^0-9]/g, '');
		}
		if (textstring.match(rgx)) {
			this.setState({
				text: textstring,
			});
			DecimalNumberChanged(textstring, valueToSet => {
				this.props.onChangeNumber(valueToSet, this.props.itemFromParent);
			});
		}
	};
}
