import React from 'react';
import { ViewWeb } from '../../utils/web-view';
import { SkioldIntegerInput } from '../skiold-integer-input/skiold-integer-input';
import './skiold-range-selector.scss';

export const SkioldRangeSelector = (
	lowerNumber: number | undefined,
	higherNumber: number | undefined,
	lowerNumberChanged: (number?: number, itemFromParent?: any) => void,
	higherNumberChanged: (number?: number, itemFromParent?: any) => void,
	onBlur:	(text?: number, itemFromParent?: any) => void,
	itemFromParent: any,
	textStyle?: string
) => {
	return (
		<ViewWeb className="skiold-range-selector flexDirectionRow">
			<SkioldIntegerInput
				text={lowerNumber}
				className={'textAlignCenter ' + (textStyle ? textStyle : '')}
				onBlur={onBlur}
				itemFromParent={itemFromParent}
				onChangeNumber={lowerNumberChanged}
			/>
			<ViewWeb> - </ViewWeb>
			<SkioldIntegerInput
				text={higherNumber}
				onBlur={onBlur}
				className={'textAlignCenter ' + (textStyle ? textStyle : '')}
				itemFromParent={itemFromParent}
				onChangeNumber={higherNumberChanged}
			/>
		</ViewWeb>
	);
};
