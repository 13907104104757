import React, { FC, useCallback, useEffect, useState } from 'react';
import PageContainer from '../page-container/page-container';
import { Heading } from '../utils/heading';
import { useDispatch, useSelector } from 'react-redux';
import { WebAppState } from '../../../state/store.web';
import { localized } from '../../../../shared/state/i18n/i18n';
import { ViewWeb } from '../utils/web-view';
import './edit-user.scss';
import { SkioldButton } from '../skiold-components/skiold-button/skiold-button';
import { SkioldFormsWrapper } from '../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from '../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { SkioldFormTextField } from '../skiold-components/skiold-forms-wrapper/skiold-form-text';
import { SkioldFormInput } from '../skiold-components/skiold-input/skiold-form-input';
import { LoadSiteAuthorizations, UpdateUserInitials } from '../../../../shared/state/ducks/authorizations/operations';
import { GetUserSitesForUser, RemoveUserSiteAccess } from '../../../../shared/state/ducks/user-sites/operations';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { SkioldCheckbox } from '../skiold-components/skiold-checkbox/skiold-checkbox';

interface PropsFromParent {
	closeModal: (userId?: string) => void;
	userId: string;
}

export const EditUser: FC<PropsFromParent> = React.memo(({ closeModal, userId }) => {
	const dispatch = useDispatch();
	const userProfile = useSelector((state: WebAppState) =>
		state.authorization.auth.userProfiles?.find(u => u.id === userId),
	);
	const userSites = useSelector((state: WebAppState) => state.userSites.userSites);
	const activeSiteId = useSelector((state: WebAppState) => state.site.site.id);

	const [initialsLocal, setInitialsLocal] = useState(userProfile?.initials);
	const [sitesToRemove, setSitesToRemove] = useState<string[]>([]);

	useEffect(() => {
		if (userId === undefined) {
			return;
		}
		dispatch(GetUserSitesForUser(userId));
	}, [userId, dispatch]);

	const onInitialsChange = useCallback((text: string) => {
		if (text.length > 3) {
			return;
		}
		setInitialsLocal(text);
	}, []);

	const save = useCallback(() => {
		if (initialsLocal !== undefined && initialsLocal !== userProfile?.initials) {
			dispatch(UpdateUserInitials(userId, initialsLocal));
		}
		if (sitesToRemove.length !== 0) {
			dispatch(RemoveUserSiteAccess(userId, sitesToRemove));
		}
		closeModal(
			sitesToRemove.length !== 0 && sitesToRemove.includes(activeSiteId!) ? userProfile?.adUserID : undefined,
		);
	}, [closeModal, userId, initialsLocal, sitesToRemove, dispatch, userProfile, activeSiteId]);

	const handleCheck = useCallback(
		siteId => () => {
			// If unselect add to delete list
			setSitesToRemove(prevState => {
				const index = prevState.findIndex(s => s === siteId);
				if (index > -1) {
					const temp = [...prevState];
					temp.splice(index, 1);
					return temp;
				} else {
					return [...prevState, siteId];
				}
			});
		},
		[],
	);

	const formRows: FormRow[] = [
		{ name: localized('Email'), component: <SkioldFormTextField>{userProfile?.email}</SkioldFormTextField> },
		{
			name: localized('Initials'),
			component: <SkioldFormInput text={initialsLocal} onChangeText={onInitialsChange} />,
		},
	];

	return (
		<PageContainer>
			<Heading allLettersCapitalized={false} text={`${localized('Edit')} ${userProfile?.name}`} />
			<ViewWeb className={'edit-user-container'}>
				<SkioldFormsWrapper formRows={formRows} />
				<ViewWeb className={'site-toggle-container'}>
					<TextWeb className={'sites-header'}>{localized('Sites')}:</TextWeb>
					{userSites.map(site => (
						<ViewWeb className={'site-checkbox-row'} key={site.siteId}>
							<TextWeb>{site.siteName}</TextWeb>
							<ViewWeb className={'site-checkbox-container'}>
								<SkioldCheckbox
									isChecked={!sitesToRemove.includes(site.siteId as string)}
									onClick={handleCheck(site.siteId)}
								/>
							</ViewWeb>
						</ViewWeb>
					))}
				</ViewWeb>
				<ViewWeb className={'button-view-style'}>
					<SkioldButton title={localized('Close')} onPress={closeModal} theme={'grey'} />
					<SkioldButton title={localized('Save')} onPress={save} />
				</ViewWeb>
			</ViewWeb>
		</PageContainer>
	);
});
