import { Action } from 'redux';
import { BreedTableColumnEnum, ILicence, IModule, Licence, Module } from 'shared/api/api';
import { getNucleusManagementCompanyByLicence, getNucleusManagementCompanyByLicences } from 'shared/helpers/nucleus-management-helper/nucleus-management-helper';
import { SharedAppState } from 'shared/state/store.shared';
import { isType } from 'typescript-fsa';
import { getSiteAsync, saveSiteAsync } from '../site/actions';
import * as actions from './actions';
import { LicenceState } from './types';

export const initialModuleState: LicenceState = {
	siteModules: [],
	licences: [],
	isLoading: false,
	nucleusManagementCompany: undefined,
};

export default function licencesReducer(state: LicenceState = initialModuleState, action: Action): LicenceState {
	// Load site modules
	if (isType(action, actions.loadSiteModules.started)) {
		return { ...state, isLoading: true };
	}
	if (isType(action, actions.loadSiteModules.done)) {
		return {
			...state,
			siteModules: action.payload.result,
			isLoading: false,
		};
	}

	if (isType(action, getSiteAsync.done)) {
		return {
			...state,
			nucleusManagementCompany: getNucleusManagementCompanyByLicences(action.payload.result.activeLicences),
		};
	}

	if (isType(action, saveSiteAsync.done)) {
		return {
			...state,
			nucleusManagementCompany: getNucleusManagementCompanyByLicences(action.payload.params.activeLicences),
		};
	}

	if (isType(action, actions.loadSiteModules.failed)) {
		return { ...state, isLoading: false };
	}

	// Load all modules
	if (isType(action, actions.loadAll.started)) {
		return { ...state, isLoading: true };
	}
	if (isType(action, actions.loadAll.done)) {
		return { ...state, licences: action.payload.result, isLoading: false };
	}
	if (isType(action, actions.loadAll.failed)) {
		return { ...state, isLoading: false };
	}

	//Ensure Date objects are in fact date and not strings - redux-persist serializes dates to string but doesn't deserialize to dates again
	if (action.type === 'persist/REHYDRATE') {
		let a = (action as any) as { payload: SharedAppState; key: string };

		if (a.key === 'root') {
			let siteModules = new Array<IModule>();
			let licences = new Array<ILicence>();
			let nucleusManagementCompany: BreedTableColumnEnum | undefined;
			if (a.payload && a.payload.licences && a.payload.licences.siteModules) {
				siteModules = a.payload.licences.siteModules.map((dt: IModule) => {
					let ndt = Module.fromJS({});
					ndt.init(dt);
					return ndt;
				});
			}
			if (a.payload && a.payload.licences && a.payload.licences.licences) {
				licences = a.payload.licences.licences.map((dt: IModule) => {
					let ndt = Licence.fromJS({});
					ndt.init(dt);
					return ndt;
				});
			}

			if (a.payload && a.payload.licences && a.payload.licences.nucleusManagementCompany) {
				nucleusManagementCompany = a.payload.licences.nucleusManagementCompany;
			}

			state = { ...state, siteModules, licences, nucleusManagementCompany };
		}
	}

	return state;
}
