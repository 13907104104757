
import { DashboardOverviewData } from 'shared/api/api';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import { actionCreatorFactory } from 'typescript-fsa';
import * as types from './types';

const actionCreator = actionCreatorFactory(GenActionFactoryName('dashboardOverview'));

export const getDashboardOverviewTable = actionCreator.async<{siteId:string, showSpinner: boolean}, DashboardOverviewData, { code: number; message: string }>(
    types.GET_DASHBOARD_OVERVIEW_TABLE
);