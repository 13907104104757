import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AnimalKind, Gender, IStemAnimal, ISubTreatment, ITreatmentDefinition, ITreatmentPlan, TreatmentDefinition, TreatmentPlan } from 'shared/api/api';
import { calculateDrugAmountPerPig } from 'shared/helpers/treatment-helper/treatment-helper';
import * as diagnoseCategoryOperation from 'shared/state/ducks/diagnoseCategories/operations';
import * as diagnoseOperation from 'shared/state/ducks/diagnoses/operations';
import { GetSyncData as DrugTypeGetSyncData } from 'shared/state/ducks/drugTypes/operations';
import * as locationOperation from 'shared/state/ducks/locations/operations';
import * as stemAnimalOperation from 'shared/state/ducks/stem-animals/operations';
import * as treatmentDefinitionOperation from 'shared/state/ducks/treatment-definitions/operations';
import { SaveTreatmentPlan } from 'shared/state/ducks/treatment-plan/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from "web/state/store.web";
import { ViewWeb } from 'web/view/components/utils/web-view';
import MarkingPicker from '../../marking-picker/marking-picker';
import PageContainer from '../../page-container/page-container';
import { showAlert } from '../../skiold-alert/skiold-alert';
import { SkioldButton } from '../../skiold-components/skiold-button/skiold-button';
import { SkioldCheckbox } from '../../skiold-components/skiold-checkbox/skiold-checkbox';
import { SkioldDatePicker } from '../../skiold-components/skiold-date-picker/skiold-date-picker';
import { SkioldFormDecimalInput } from '../../skiold-components/skiold-decimal-input/skiold-form-decimal-input';
import { SkioldFormTextField } from '../../skiold-components/skiold-forms-wrapper/skiold-form-text';
import { SkioldFormsWrapper } from '../../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from '../../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { SkioldFormIntegerInput } from '../../skiold-components/skiold-integer-input/skiold-form-integer-input';
import { GenderPicker } from '../../stem-animal/gender-picker/gender-picker';
import MoveEventBase from '../../stem-animal/sow-events/move-event/move-event-base';
import StemAnimalInput from '../../stem-animal/stem-animal-input/stem-animal-input';
import { Heading } from '../../utils/heading';
import TreatmentDefinitionPicker from '../treatment-definition/treatment-definition-picker';
import './create-treatment-plan.scss';
import { calculateAnimalKind } from 'shared/helpers/stemanimal-helper/stemanimal-helper';
import { DepartureTypes } from 'shared/state/models/departure-types';


const mapStateToProps = (state: WebAppState, props: any) => {
	return {
		siteId: state.profile.active!.siteId,
		markings: state.markings.entities,
		stemAnimals: state.stemAnimals.entities,
		drugTypes: state.drugType.entities,
		profile: state.profile.active!
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	saveTreatmentPlan: (
		treatmentPlan: ITreatmentPlan,
		treatmentDefinition: ITreatmentDefinition,
		startDate: Date,
		isTreated: boolean,
		profileId: string,
		animalWeight: number,
		feedWeight: number,
		pigletCount: number
	) =>
		SaveTreatmentPlan(
			treatmentPlan,
			treatmentDefinition,
			startDate,
			isTreated,
			profileId,
			animalWeight,
			feedWeight,
			0,
			pigletCount
		)(dispatch),
	getDrugTypes: () => DrugTypeGetSyncData()(dispatch),
	treatmentDefinitionGetSyncData: () => treatmentDefinitionOperation.GetSyncData()(dispatch),
	stemAnimalGetSyncData: () => stemAnimalOperation.GetSyncData()(dispatch),
	diagnoseCategoryGetSyncData: () => diagnoseCategoryOperation.GetSyncData()(dispatch),
	diagnoseGetSyncData: () => diagnoseOperation.GetSyncData()(dispatch),
	locationGetSyncData: () => locationOperation.GetLocations()(dispatch)
});

interface State {
	treatmentPlan: ITreatmentPlan;
	startDate: Date;
	animal?: IStemAnimal;
	animalNumber?: string;
	selectedTreatmentDefinition?: TreatmentDefinition;
	pigletAmount: number;
	animalKind: AnimalKind;
	showAnimalWeight: boolean;
	showFeedWeight: boolean;
	feedWeight: number | undefined;
	animalWeight: number | undefined;
	registerMoveEvent: boolean;
	toggleFocus: boolean;
}

interface PropsFromParent {
	stemAnimalIdFromParent?: string;
	close?: () => void;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

class CreateTreatmentPlan extends React.PureComponent<Props, State> {
	public static defaultProps: Partial<Props> = {};
	public moveEventSave: (() => Promise<boolean>) | undefined;
	constructor(props: Props) {
		super(props);

		this.state = {
			startDate: new Date(),
			treatmentPlan: this.initTreatmentPlan(),
			animal: undefined,
			selectedTreatmentDefinition: undefined,
			animalNumber: undefined,
			pigletAmount: 0,
			animalKind: AnimalKind.Sow,
			showAnimalWeight: false,
			showFeedWeight: false,
			animalWeight: 0,
			feedWeight: 0,
			registerMoveEvent: false,
			toggleFocus: false
		};

		this.props.getDrugTypes();
		this.props.diagnoseCategoryGetSyncData();
		this.props.diagnoseGetSyncData();
		this.props.locationGetSyncData();
		this.props.stemAnimalGetSyncData();
	}

	public UNSAFE_componentWillMount() {
		if (this.props.stemAnimalIdFromParent) {
			const stemAnimalFromParent = this.props.stemAnimalIdFromParent
				? this.props.stemAnimals.find(a => a.id === this.props.stemAnimalIdFromParent)
				: undefined;
			if (stemAnimalFromParent !== undefined) {
				this.animalChanged(stemAnimalFromParent);
				this.animalNumberChanged(stemAnimalFromParent.animalNumber!);
			}
		}
	}

	public render() {
		let formRows = this.getFormRows();
		return (
			<PageContainer>
				<Heading text={localized('createTreatment')} />

				<ViewWeb className="create-treatment-plan">
					{this.state.registerMoveEvent && (
						<MoveEventBase
							date={this.state.startDate}
							stableRow={(formRow: FormRow) => formRows.push(formRow)}
							penRow={(formRow: FormRow) => formRows.push(formRow)}
							feedCurveRow={(formRow: FormRow) => formRows.push(formRow)}
							fixedDeviationRow={(formRow: FormRow) => formRows.push(formRow)}
							stemAnimal={this.state.animal}
							gender={this.state.treatmentPlan.gender}
							update={this.forceUpdateView}
							save={this.setSaveMethod}
						/>
					)}

					<SkioldFormsWrapper formRows={formRows} containerClassName="wrapper-container" />
					<ViewWeb className="buttons-container">
						<SkioldButton title={localized('registerTreatment')} onPress={this.setTreated} />
						<SkioldButton title={localized('registerToBeTreated')} onPress={this.setNotTreated} />
						{this.props.close && (
							<SkioldButton title={localized('Close')} onPress={ this.props.close} theme="grey" />
						)}
					</ViewWeb>
		
				</ViewWeb>

			</PageContainer>
		);
	}

	private setTreated = () => this.save(true)
	private setNotTreated = () => this.save(false)

	private setSaveMethod = (method: () => Promise<boolean>) => (this.moveEventSave = method)

	private forceUpdateView = () => this.setState({})

	public toggleMoveEvent() {
		const toggle = !this.state.registerMoveEvent;
		this.setState({ registerMoveEvent: toggle });
	}

	private getFormRows() {
		let formRows = new Array<FormRow>();

		formRows.push(this.getDateRow());
		formRows.push(this.getGenderRow());
		formRows.push(this.getStemAnimalRow());

		if (this.state.treatmentPlan.gender === Gender.Female) {
			formRows.push(this.getPigletAmountRow());
		}

		formRows.push(this.getTreatmentDefinitionPickerRow());

		if (this.state.showAnimalWeight) {
			formRows.push(this.getAnimalWeightRow());
		}

		if (this.state.showFeedWeight) {
			formRows.push(this.getFeedWeightRow());
		}

		if (this.state.selectedTreatmentDefinition && this.state.selectedTreatmentDefinition.subTreatments) {
			this.state.selectedTreatmentDefinition.subTreatments.forEach((subTreat, index) => {
				formRows.push(this.getDrugRow(subTreat, index));
				formRows.push(this.getDrugAmountRow(subTreat, index));
			});
		}

		formRows.push(this.getMarkingRow());
		formRows.push(this.getToggleMoveEventRow());

		return formRows;
	}

	private getDateRow(): FormRow {
		return {
			name: localized('Date'),
			component: (
				<SkioldDatePicker
					onDateChanged={newDate =>
						this.setState({
							startDate: newDate
						})
					}
					selectedDate={this.state.startDate}
					theme="dark"
					color="grey"
				/>
			)
		};
	}

	private getGenderRow(): FormRow {
		return {
			name: localized('Gender'),
			component: (
				<GenderPicker
					gender={this.state.treatmentPlan.gender}
					onGenderChanged={(gender: Gender) => this.genderChanged(gender)}
				/>
			)
		};
	}

	private getStemAnimalRow(): FormRow {
		return {
			name: localized('animalNr'),
			component: (
				<ViewWeb>
					<StemAnimalInput
						onAnimalFound={(newAnimal: IStemAnimal | undefined) => this.animalChanged(newAnimal)}
						onAnimalNumberChanged={(animalNumber: string | undefined) =>
							this.animalNumberChanged(animalNumber)
						}
						animalNumber={this.state.animalNumber}
						inputType={'form'}
						gender={this.state.treatmentPlan.gender}
						toggleFocus={this.state.toggleFocus}
						className={`${
							this.state.animal &&
							this.state.animal.departureType === DepartureTypes.departureTypeShouldDeparture
								? 'should-departure-list-color-with-margin-change'
								: ''
						}`}
					/>
				</ViewWeb>
			)
		};
	}

	private getPigletAmountRow(): FormRow {
		return {
			name: localized('AmountOfPiglets'),
			component: (
				<SkioldFormIntegerInput
					onChangeNumber={newNumber => this.pigletAmountChanged(newNumber)}
					text={this.state.pigletAmount}
				/>
			)
		};
	}

	private getTreatmentDefinitionPickerRow(): FormRow {
		return {
			name: localized('selectTreatmentDefinition'),
			component: (
				<TreatmentDefinitionPicker
					onValueChanged={(newValue: ITreatmentDefinition | undefined) =>
						this.treatmentDefinitionChanged(newValue)
					}
					treatmentDefinition={this.state.selectedTreatmentDefinition}
					pigType={this.state.animalKind}
					allowVaccines={false}
				/>
			)
		};
	}

	private getAnimalWeightRow(): FormRow {
		return {
			name: localized('AnimalWeight'),
			component: (
				<SkioldFormDecimalInput
					onChangeNumber={(newText: number | undefined) => this.animalWeightChanged(newText)}
					text={this.state.animalWeight && this.state.animalWeight > 0 ? this.state.animalWeight : null}
				/>
			)
		};
	}

	private getFeedWeightRow(): FormRow {
		return {
			name: localized('FeedWeight'),
			component: (
				<SkioldFormDecimalInput
					onChangeNumber={(newText: number | undefined) => this.feedWeightChanged(newText)}
					text={this.state.feedWeight && this.state.feedWeight > 0 ? this.state.feedWeight : null}
				/>
			)
		};
	}

	private getDrugRow(subTreatment: ISubTreatment, index: number): FormRow {
		return {
			name: localized('drug') + ' ' + (index + 1),
			component: this.getDrugNameComponent(subTreatment)
		};
	}

	private getDrugAmountRow(subTreatment: ISubTreatment, index: number): FormRow {
		return {
			name: localized('DrugAmountPerPig'),
			component: this.getDrugAmountComponent(subTreatment)
		};
	}

	private getMarkingRow(): FormRow {
		return {
			name: localized('marking'),
			component: (
				<MarkingPicker
					marking={this.state.treatmentPlan.marking}
					onMarkingChanged={(m: string | undefined) => this.onMarkingChanged(m)}
				/>
			)
		};
	}

	private getToggleMoveEventRow(): FormRow {
		return {
			name: localized('move'),
			component: (
				<SkioldCheckbox
					onClick={() => {
						this.toggleMoveEvent();
					}}
					isChecked={this.state.registerMoveEvent}
				/>
			)
		};
	}

	private getDrugNameComponent(subTreatment: ISubTreatment) {
		let drug = this.props.drugTypes.find(drugType => drugType.id === subTreatment.drugTypeId);
		return <SkioldFormTextField>{drug ? drug.name : ''}</SkioldFormTextField>;
	}

	private getDrugAmountComponent(subTreatment: ISubTreatment) {
		let amount = calculateDrugAmountPerPig(
			subTreatment.drugAmount!,
			this.state.animalWeight!,
			this.state.feedWeight!
		);

		const drugType = this.props.drugTypes.find(dt => dt.id === subTreatment.drugTypeId);

		if (drugType != null) {
			return <SkioldFormTextField>{`${amount} ${drugType.unitOfMeasurement}`}</SkioldFormTextField>;
		}
		return <SkioldFormTextField>{`${amount}`}</SkioldFormTextField>;
	}

	private genderChanged(gender: Gender) {
		this.setState(prevState => ({ treatmentPlan: { ...prevState.treatmentPlan, gender } }));

		this.pigletAmountChanged(0);
	}

	private setAnimalKind() {
		let prevAnimalKind = this.state.animalKind;

		let newAnimalKind: AnimalKind;
		if (this.state.treatmentPlan.gender === Gender.Male) {
			newAnimalKind = AnimalKind.Boar;
		} else if (this.state.pigletAmount === 0) {
			if (this.state.animal) {
				newAnimalKind = calculateAnimalKind(this.state.animal);
			} else {
				newAnimalKind = AnimalKind.Sow;
			}
		} else {
			newAnimalKind = AnimalKind.Piglet;
		}

		this.setState({ animalKind: newAnimalKind });

		//Boar and Sow shares treatmentDefinitions

		if (
			prevAnimalKind !== newAnimalKind &&
			!(
				prevAnimalKind.includes(AnimalKind.Boar) &&
				prevAnimalKind.includes(AnimalKind.Sow) &&
				prevAnimalKind.includes(AnimalKind.Gilt)
			)
		) {
			this.treatmentDefinitionChanged(undefined);
		}
	}

	private async save(isTreated: boolean) {
		if (await this.validate()) {
			if (this.state.registerMoveEvent && this.moveEventSave) {
				const valid = await this.moveEventSave();
				if (!valid) {
					return;
				}
			}

			this.props.saveTreatmentPlan(
				this.state.treatmentPlan,
				this.state.selectedTreatmentDefinition!,
				this.state.startDate,
				isTreated,
				this.props.profile.id!,
				this.state.animalWeight!,
				this.state.feedWeight!,
				this.state.pigletAmount
			);
			if (this.props.close) {
				this.props.close();
			} else {
				this.resetView();
			}
		}
	}

	private async validate() {
		if (!this.state.treatmentPlan.stemAnimalId) {
			showAlert(localized('VALIDATION_ERROR_AnimalNotSet'));
			return false;
		}

		if (!this.state.treatmentPlan.treatmentDefinitionId) {
			showAlert(localized('VALIDATION_ERROR_NoDiagnoseSet'));
			return false;
		}

		if (!this.state.selectedTreatmentDefinition) {
			showAlert(localized('VALIDATION_ERROR_NoDiagnoseSet'));
			return false;
		}

		if (this.state.showAnimalWeight && (!this.state.animalWeight || this.state.animalWeight <= 0)) {
			showAlert(localized('VALIDATION_ERROR_NoWeightSet'));
			return false;
		}

		//Everything is fine
		return true;
	}

	private initTreatmentPlan(): TreatmentPlan {
		let treatmentPlan = TreatmentPlan.fromJS({});
		treatmentPlan.siteId = this.props.siteId;
		treatmentPlan.gender = Gender.Female;

		treatmentPlan.isSowActive = true;
		if (this.state) {
			treatmentPlan.treatmentDefinitionId = this.state.selectedTreatmentDefinition
				? this.state.selectedTreatmentDefinition.id
				: '';
			treatmentPlan.marking = this.state.treatmentPlan.marking;
		}

		return treatmentPlan;
	}

	private resetView() {
		this.setState({
			treatmentPlan: this.initTreatmentPlan(),
			animal: undefined,
			animalNumber: undefined,
			pigletAmount: 0,
			feedWeight: 0,
			toggleFocus: !this.state.toggleFocus
		});
	}

	private pigletAmountChanged(newAmount: number | undefined) {
		this.setState({ pigletAmount: newAmount || 0 }, () => this.setAnimalKind());
	}

	private animalWeightChanged(weight: number | undefined): void {
		this.setState({ animalWeight: weight });
	}

	private feedWeightChanged(weight: number | undefined): void {
		this.setState({ feedWeight: weight });
	}

	private onMarkingChanged(marking: string | undefined): void {
		this.setState(prevState => ({
			...prevState,
			treatmentPlan: { ...prevState.treatmentPlan, marking }
		}));
	}

	private animalChanged(newAnimal?: IStemAnimal): void {
		let id = newAnimal ? newAnimal.id : '';
		if (newAnimal) {
			this.setState(prevState => ({
				...prevState,
				treatmentPlan: { ...prevState.treatmentPlan, stemAnimalId: id },
				animal: newAnimal,
				animalKind: calculateAnimalKind(newAnimal),
				pigletAmount: 0
			}));
		} else {
			this.setState(prevState => ({
				...prevState,
				treatmentPlan: { ...prevState.treatmentPlan, stemAnimalId: '' },
				animal: newAnimal,
				animalKind: AnimalKind.Sow,
				pigletAmount: 0
			}));
		}
	}

	private animalNumberChanged(animalNumber: string | undefined) {
		this.setState({ animalNumber });
	}

	private treatmentDefinitionChanged(treatmentDef: ITreatmentDefinition | undefined): void {
		this.shouldShowWeight(treatmentDef);
		this.setState(prevState => ({
			...prevState,
			treatmentPlan: {
				...prevState.treatmentPlan,
				treatmentDefinitionId: treatmentDef ? treatmentDef.id : undefined,
				marking: treatmentDef ? treatmentDef.marking : ''
			},
			selectedTreatmentDefinition: treatmentDef as TreatmentDefinition
		}));
	}

	private shouldShowWeight(treatmentDef: ITreatmentDefinition | undefined) {
		if (!treatmentDef) {
			return;
		}

		let animalWeightRelevantTypeFound = false;
		let feedWeightRelevantTypeFound = false;

		treatmentDef!.subTreatments!.forEach(subTreat => {
			if (subTreat.drugAmount!.unitType === 'PerKgPig') {
				animalWeightRelevantTypeFound = true;
			}
			if (subTreat.drugAmount!.unitType === 'PerKgFood') {
				feedWeightRelevantTypeFound = true;
			}
		});

		if (!animalWeightRelevantTypeFound) {
			//Reset weight if weight shouldn't be used
			this.setState({ animalWeight: 0 });
		}
		if (!feedWeightRelevantTypeFound) {
			//Reset weight if weight shouldn't be used
			this.setState({ feedWeight: 0 });
		}

		this.setState({ showAnimalWeight: animalWeightRelevantTypeFound, showFeedWeight: feedWeightRelevantTypeFound });
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CreateTreatmentPlan);
