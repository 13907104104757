import React, { FC, useState, useEffect } from 'react';
import { IStemAnimal } from 'shared/api/api';
import { localized } from 'shared/state/i18n/i18n';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import PageContainer from '../page-container/page-container';
import { ViewWeb } from '../utils/web-view';
import './family-tree.scss';
interface PropsFromParent {
	stemAnimal?: IStemAnimal;
	title?: string;
}
export const FamilyTreePigComponent: FC<PropsFromParent> = React.memo(({ stemAnimal, title }) => {
	return (
		<ViewWeb className="family-tree-container">
			<ViewWeb className="title">
				<TextWeb>{title}</TextWeb>
			</ViewWeb>
			<ViewWeb className="label-value-container">
				<LabelValue
					label={localized('animalNumber')}
					value={stemAnimal ? stemAnimal.animalNumber : localized('Unknown')}
				/>
				<LabelValue
					label={localized('Breed')}
					value={stemAnimal && stemAnimal.race ? stemAnimal.race : localized('Unknown')}
				/>
				<LabelValue
					label={localized('IDnr')}
					value={stemAnimal && stemAnimal.idNumber ? stemAnimal.idNumber : localized('Unknown')}
				/>
			</ViewWeb>
		</ViewWeb>
	);
});

const LabelValue = (props: { label: string; value?: string }) => {
	return (
		<ViewWeb className="label-value">
			<TextWeb className="label">{props.label}</TextWeb>
			<TextWeb className="value">{props.value}</TextWeb>
		</ViewWeb>
	);
};
