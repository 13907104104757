import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { siteChange } from '../profile/actions';
import * as actions from './actions';
import { SlaughterhouseConnectionState } from './types';

export const initialState: SlaughterhouseConnectionState = {
    slaughterhouseConnections: [],
    selectedConnection: undefined
};

const slaughterhouseConnectionReducer = (state: SlaughterhouseConnectionState = initialState, action: Action): SlaughterhouseConnectionState => {

    if (isType(action, actions.getSlaughterHouseConnections.done)) {
        const result = { ...state, slaughterhouseConnections: action.payload.result };
        return result;
    }
    if(isType(action, actions.postSlaughterHouseConnections.done)){
        const result = action.payload.result
        const connectionsCopy = [...state.slaughterhouseConnections]
        const index = connectionsCopy.findIndex(c=> c.id === result.id);
        if(index>= 0){
            connectionsCopy[index] =result;

        }else{
            connectionsCopy.push(result)
        }
        return {...state, slaughterhouseConnections: [...connectionsCopy]}
    }
    if (isType(action, siteChange.done)) {
		state = initialState;
	}

    if (isType(action, actions.setSlaughterHouseConnection)) {
        state = {...state, selectedConnection: action.payload}
	}

    return state;
};

export default slaughterhouseConnectionReducer;
