import { Action } from 'redux';
import { SharedAppState } from 'shared/state/store.shared';
import { isType } from 'typescript-fsa';
import { setIsAuthAction, setShouldRefreshAction, setIsAdmin, setPerformingRefreshAction } from './actions';
import { AuthenticationState } from './types';

export const initialState: AuthenticationState = {
	isAuthenticated: undefined,
	shouldRefresh: false,
	performingRefresh: false,
	isAdmin: false,
};

const authenticationReducer = (state: AuthenticationState = initialState, action: Action): AuthenticationState => {
	if (isType(action, setIsAuthAction)) {
		return {
			...state,
			isAuthenticated: action.payload,
		};
	}
	if (isType(action, setShouldRefreshAction)) {
		return {
			...state,
			shouldRefresh: action.payload,
		};
	}
	if (isType(action, setPerformingRefreshAction)) {
		return {
			...state,
			performingRefresh: action.payload,
		};
	}

	if (isType(action, setIsAdmin)) {
		return {
			...state,
			isAdmin: action.payload,
		};
	}

	if (action.type === 'USER_LOGOUT') {
		state = initialState;
	}

	//Ensure Date objects are in fact date and not strings - redux-persist serializes dates to string but doesn't deserialize to dates again
	if (action.type === 'persist/REHYDRATE') {
		let a = (action as any) as { payload: SharedAppState; key: string };

		if (a.key === 'root') {
			if (a.payload && a.payload.authentication) {
				state = { ...state, isAuthenticated: a.payload.authentication.isAuthenticated };
			}
		}
	}

	return state;
};

export default authenticationReducer;
