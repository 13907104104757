import { Option } from 'react-dropdown';
import {
	ActiveAnimalsReportSetting,
	AnimalReportType,
	FeedingESFReportSetting,
	IActiveAnimalsReportSetting,
	IAnimalReportSetting,
	ISowBoardReportSetting,
	MatingBatchReportSetting,
	SowBoardReportSetting,
} from 'shared/api/api';
import { localizedDynamic } from 'shared/state/i18n/i18n';

export function NewAnimalReportSetting(workList: IAnimalReportSetting) {
	if (workList.type === AnimalReportType.ActiveAnimalsReportSetting) {
		return ActiveAnimalsReportSetting.fromJS(workList);
	}
	if (workList.type === AnimalReportType.MatingBatchReportSetting) {
		return MatingBatchReportSetting.fromJS(workList);
	}
	if (workList.type === AnimalReportType.SowBoardReportSetting) {
		return SowBoardReportSetting.fromJS(workList);
	}
	if (workList.type === AnimalReportType.FeedingESFReportSetting) {
		return FeedingESFReportSetting.fromJS(workList);
	}
	console.error('Animal report setting failed to rehydrate');
	return undefined;
}

export function generateAnimalReportOptions(
	animalReportSettings: IAnimalReportSetting[],
	isWeb: boolean,
	usesEsf,
): Option[] {
	let animalReportOptions = [
		{
			label: localizedDynamic(AnimalReportType.MatingBatchReportSetting),
			value: AnimalReportType.MatingBatchReportSetting.toString(),
		},
		{
			label: localizedDynamic('pReport'),
			value: 'pReport',
		},
	];
	if (usesEsf) {
		animalReportOptions.push({
			label: localizedDynamic('feedingReport'),
			value: 'feedingReport',
		});
	}

	// TEMP until further impl
	if (!animalReportSettings.find(setting => setting.type === AnimalReportType.ActiveAnimalsReportSetting)) {
		animalReportOptions.push({
			label: localizedDynamic(AnimalReportType.ActiveAnimalsReportSetting),
			value: AnimalReportType.ActiveAnimalsReportSetting.toString(),
		});
	} else {
		animalReportOptions = animalReportOptions.concat(
			animalReportSettings
				.filter(a => (a.showOnApp && !isWeb) || (a.showOnWeb && isWeb))
				.map((setting: any) => {
					return {
						label: setting.name ? setting.name : localizedDynamic(setting.type!),
						value: setting.type!.toString(),
					};
				}),
		);
	}

	return animalReportOptions;
}
