import moment from 'moment';
import { Option } from 'react-dropdown';
export const dateFormat = 'DD.MM.YY';

export const dateFormatDash = 'DD-MM-YY';

export const dateTimeFormat = 'DD.MM.YY - HH:mm';
export const localeDateFormat = 'DD. MMMM';

export function isTodayOrOverdueOrTomorrow(date: Date) {
	return moment(date).isSameOrBefore(moment(), 'day') || isTomorrow(date);
}

export function isToday(date: Date) {
	return moment(date).isSame(moment(), 'day');
}

export function isTomorrow(date: Date) {
	return moment(date).isSame(moment().add(1, 'days'), 'day');
}

export function isSameDate(date?: Date, date2?: Date) {
	if (date && date2) {
		return moment(date).isSame(date2, 'day');
	}
	return false;
}

export function isBetween(fromDate?: moment.MomentInput,betweenDate?: moment.MomentInput, toDate?: moment.MomentInput) {
	if (fromDate && betweenDate && toDate) {
		return moment(betweenDate).isBetween(fromDate, toDate, undefined, '[]');
	}
	return false;
}

export function isDateSameOrAfter(date1: Date | undefined, date2: Date | undefined) {
	return moment(date1).isSameOrAfter(date2, 'day');
}

export function isDateSameOrBefore(date1: Date | undefined, date2: Date | undefined) {
	return moment(date1).isSameOrBefore(date2, 'day');
}

export function isDateAfter(date1: Date | undefined, date2: Date | undefined) {
	return moment(date1).isAfter(date2, 'day');
}

export function isOverdue(date: Date) {
	return moment(date).isBefore(moment(), 'day');
}

export function getDateString(date: Date | undefined): string {
	if (date) {
		return moment(date).format(dateFormat);
	}

	return '';
}

export function getDateStringNoMinDate(date: Date | undefined): string {
	if (date && date.getTime() !== new Date(-62135599820000).getTime()) {
		return moment(date).format(dateFormat);
	}

	return '';
}

export function getLocaleDateString(date: Date | undefined): string {
	if (date) {
		return moment(date).format(localeDateFormat);
	}

	return '';
}
export function getTimeDateString(date: Date | undefined): string {
	if (date) {
		return moment(date).format(dateTimeFormat);
	}

	return '';
}
export function formatDate(date: Date | undefined, format: string): string {
	if (date) {
		return moment(date).format(format);
	}

	return '';
}

export function setCorrectTimeStamp(
	newDate: Date | undefined,
	includeTimeStamp: boolean,
	isEndDate: boolean,
	backwardDateSetEndTime?: boolean,
): Date | undefined {
	if (newDate) {
		if (backwardDateSetEndTime && isOverdue(newDate)) {
			return new Date(new Date(newDate).setHours(23, 59, 59, 0));
		} else if (isEndDate) {
			return new Date(new Date(newDate).setHours(23, 59, 59, 0));
		} else if (includeTimeStamp) {
			const currentTime = new Date();
			return new Date(
				new Date(newDate).setHours(currentTime.getHours(), currentTime.getMinutes(), currentTime.getSeconds()),
			);
		} else {
			return new Date(new Date(newDate).setHours(0, 0, 0, 0));
		}
	}
	return undefined;
}

export function areDatesEqual(date1: Date | undefined, date2: Date | undefined) {
	if (date1 && date2) {
		return moment(date1).isSame(date2, 'day');
	}
	return false;
}

export function areDateTimesEqual(date1: Date | undefined, date2: Date | undefined) {
	if (date1 && date2) {
		return moment(date1).isSame(date2);
	}
	return false;
}

export function getDaysDifference(date1: Date | undefined, date2: Date | undefined) {
	let duration = moment.duration(moment(date1).diff(moment(date2)));
	return Math.abs(Math.round(duration.asDays()));
}

export function getHourDifference(date1: Date | undefined, date2: Date | undefined) {
	let duration = moment.duration(moment(date1).diff(moment(date2)));
	return Math.abs(duration.asHours());
}

export function AddDaysToDate(date: Date, daysToAdd: number) {
	const dateCopy = new Date(date);
	dateCopy.setDate(dateCopy.getDate() + daysToAdd);
	return dateCopy;
}

export function SubtractDays(date: Date, days: number) {
	return moment(date).subtract(days, 'days').toDate();
}

export interface SkioldFetchPropsFromParent {
	fetchData: (fromDate: Date, toDate: Date) => void;
	defaultEmpty?: boolean;
	fromDate?: Date;
	fromText?: string;
	toDate?: Date;
	toText?: string;
	showSpinner?: boolean;
	datesToHighlightLeftDatePicker?: Date[];
	datesToHighlightRightDatePicker?: Date[];
	isControlled?: boolean;
	fromDateChange?: (fromDate: Date) => void;
	toDateChange?: (toDate: Date) => void;
}

export function generateSkioldFetchInitialState(props: SkioldFetchPropsFromParent) {
	let tempFromDate = new Date();
	if (props.fromDate) {
		tempFromDate = new Date(props.fromDate);
	} else {
		tempFromDate.setMonth(new Date().getMonth() - 1);
	}
	let tempToDate = new Date();
	if (props.toDate) {
		tempToDate = new Date(props.toDate);
	} else {
		tempToDate.setMonth(new Date().getMonth());
	}
	return { tempFromDate, tempToDate };
}

export const getStartOfDay = (date: Date) => moment(date, 'DD/MM/YYYY').startOf('day').toDate();
export const getStartOfDate = (date: Date) => moment(date, 'DD/MM/YYYY').startOf('day').toDate();
export const getEndOfDate = (date: Date) => moment(date, 'DD/MM/YYYY').endOf('day').toDate();

const minApiData = new Date('0001-01-01T00:00:00Z');
export const minJSDate = new Date(-8640000000000000);
export function getMinApiDateIfSmaller(date: Date | undefined) {
	return date && minApiData.getTime() < date.getTime() ? date : minApiData;
}

export const getPrevPresentAndNextWeek = () => {
	const date = new Date();
	const prevWeek = moment(date).subtract(1, 'week');
	const presentWeek = moment(date);
	const nextWeek = moment(date).add(1, 'week');
	return [{ label: prevWeek.isoWeek().toString(), value: prevWeek.toDate().toDateString() },
	{ label: presentWeek.isoWeek().toString(), value: presentWeek.toDate().toDateString() },
	{ label: nextWeek.isoWeek().toString(), value: nextWeek.toDate().toDateString() }] as Option[];
}

export const getCurrentWeekOption = () => {
	const date = new Date();
	const presentWeek = moment(date);
	return { label: presentWeek.isoWeek().toString(), value: presentWeek.toDate().toDateString() } as Option;
}
