import { Dispatch } from 'redux';
import React from 'react';
import { connect } from 'react-redux';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import 'web/view/components/skiold-components/skiold-table/react-table-override.scss';
import ReactTable, { FilterValue } from 'react-table';
import { WebAppState } from 'web/state/store.web';
const ReactTableFixedColumns = withFixedColumns(ReactTable);
interface PropsFromParent {
	data: any[];
	columns: any;
	sortHeaderId?: string;
	style?: any;
	filterable?: boolean;
	className?: string;
	sortable?: boolean;
	showNoDataText?: boolean;
}

const mapStateToProps = (state: WebAppState) => {
	return {};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;
export class GroupedHeaderSkioldTable extends React.PureComponent<Props> {
	constructor(props: Props) {
		super(props);
		this.state = {};
	}

	public render() {
		return (
			<ReactTableFixedColumns
				columns={this.props.columns}
				data={this.props.data}
				filterable={this.props.filterable}
				sortable={this.props.sortable}
				className={'-striped skiold-table grouped-header-table ' + this.props.className}
				showPagination={false}
				minRows={0}
				defaultPageSize={1000000000}
				defaultFilterMethod={this.defaultFilterMethod}
			/>
		);
	}

	private defaultFilterMethod = (filter: FilterValue, row: any) => {
		let escaped = (filter.value as string).replace('*', '.');

		let regEx = new RegExp(escaped);
		let rowValue = row[filter.id];
		let res = regEx.test(rowValue);

		return res;
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupedHeaderSkioldTable);
