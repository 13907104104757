import * as Action from './actions';
import { StoreContainer } from 'shared/state/store-container';
import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';

export const GetAlarms = () => {
	const state = StoreContainer.getState();
	const lastSyncDate = state.notifications.alarms.syncTime;
	return AsyncOperationBuilder2(
		Action.getAlarms,
		client => client.alarms_Sync(lastSyncDate ? new Date(lastSyncDate) : undefined),
		{},
	);
};

export const GetWarnings = () => {
	const state = StoreContainer.getState();
	const lastSyncDate = state.notifications.warnings.syncTime;
	return AsyncOperationBuilder2(
		Action.getWarnings,
		client => client.warnings_Sync(lastSyncDate ? new Date(lastSyncDate) : undefined),
		{},
	);
};

export function AcknowledgeAlarm(instanceID: string) {
	return AsyncOperationBuilder2(Action.acknowledgeAlarm, cl => cl.alarms_Post(instanceID), instanceID);
}

export function AcknowledgeWarning(instanceID: string) {
	return AsyncOperationBuilder2(Action.acknowledgeWarning, cl => cl.warnings_Post(instanceID), instanceID);
}
