import React from 'react';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Option } from 'react-dropdown';
import './create-building.scss';
import { IBuilding, Building, ProductionForm, AnimalType, LocationType } from 'shared/api/api';
import { SectionFormData } from 'shared/state/models/locations/section-form-data';
import { PenFormData } from 'shared/state/models/locations/pen-form-data';
import { generateAnimalType, generateLocationType, generatePenFormData } from 'shared/helpers/location-helper';
import { deepCopy } from 'shared/helpers/general-helpers';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { SkioldFormsWrapper } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { localized } from 'shared/state/i18n/i18n';
import { SkioldFormIntegerInput } from 'web/view/components/skiold-components/skiold-integer-input/skiold-form-integer-input';
import { SkioldFormInput } from 'web/view/components/skiold-components/skiold-input/skiold-form-input';
import { SkioldFormDropdown } from 'web/view/components/skiold-components/skiold-dropdown/skiold-form-dropdown';
import { SkioldCheckbox } from 'web/view/components/skiold-components/skiold-checkbox/skiold-checkbox';
import { WebAppState } from 'web/state/store.web';
import { validateBuilding } from 'web/web-helpers/web-location-helper';

interface PropsFromParent {
	buildingStateChanged: (building: IBuilding) => void;
	sectionFormChanged: (SectionFormData: SectionFormData | undefined) => void;
	penFormChanged: (PenFormData: PenFormData | undefined) => void;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		siteId: state.profile.active!.siteId,
		buildings: state.locations.buildings,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({});
const DefaultTypeOption: Option = { label: ' ', value: '' };

interface CreateBuildingState {
	productionForms: Option[];
	building: IBuilding;
	selectedProductionForm: Option;
	animalTypes: Option[];
	locationTypes: Option[];
	selectedLocationType: Option;
	selectedAnimalType: Option;
	sectionForm: SectionFormData | undefined;
	penForm: PenFormData | undefined;
}

type CreateBuildingProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;
export class CreateBuilding extends React.PureComponent<CreateBuildingProps, CreateBuildingState> {
	constructor(props: CreateBuildingProps) {
		super(props);
		this.state = {
			productionForms: this.generateProductionForms(),
			building: Building.fromJS({ siteId: props.siteId, useSections: true } as IBuilding),
			sectionForm: undefined,
			penForm: undefined,
			selectedProductionForm: DefaultTypeOption,
			animalTypes: generateAnimalType(undefined),
			selectedAnimalType: DefaultTypeOption,
			locationTypes: [],
			selectedLocationType: DefaultTypeOption,
		};
	}

	public buildingNumberChanged(buildingNumber: number | undefined) {
		this.setState(prevState => ({
			building: { ...prevState.building, buildingNumber: buildingNumber!, order: buildingNumber! },
		}));
	}

	public buildingNameChanged(name: string) {
		this.setState(prevState => ({ building: { ...prevState.building, name } }));
	}

	public setProductionForm(productionForm: Option) {
		this.setState(prevState => ({
			building: { ...prevState.building, productionForm: productionForm.value as ProductionForm },
			selectedProductionForm: productionForm,
		}));
		this.props.buildingStateChanged({
			productionForm: productionForm.value as ProductionForm,
			useSections: this.state.building.useSections,
		} as IBuilding);
	}

	public capacityChanged(capacity: number | undefined) {
		this.setState(prevState => ({ penForm: { ...prevState.penForm!, capacity } }));
		let penFormData = this.state.penForm!;
		penFormData.capacity = capacity;
		this.props.penFormChanged(penFormData);
	}

	public setAnimalType(animalType: Option) {
		const locationTypes = generateLocationType(this.state.building.productionForm, animalType.value);
		this.setState(prevState => ({
			sectionForm: { ...prevState.sectionForm!, selectedAnimalType: animalType.value as AnimalType },
			selectedAnimalType: animalType,
			locationTypes,
		}));
		let sectionFormData = this.state.sectionForm!;
		sectionFormData.selectedAnimalType = animalType.value as AnimalType;
		this.props.sectionFormChanged(sectionFormData);
	}
	public setLocationType(locationType: Option) {
		this.setState(prevState => ({
			sectionForm: { ...prevState.sectionForm!, selectedLocationType: locationType.value as LocationType },
			selectedLocationType: locationType,
		}));
		let sectionFormData = this.state.sectionForm!;
		sectionFormData.selectedLocationType = locationType.value as LocationType;
		this.props.sectionFormChanged(sectionFormData);
	}

	public toggleIsUsingQRCode() {
		const toggle = deepCopy(this.state.building.isUsingQRCode);
		this.setState(prevState => ({ building: { ...prevState.building, isUsingQRCode: !toggle } }));
	}
	public toggleIsUsingSections() {
		const toggle = deepCopy(this.state.building.useSections);

		this.setState(prevState => ({
			building: { ...prevState.building, useSections: !toggle },
			sectionForm: toggle ? this.GenerateSectionFormData() : undefined,
			penForm: toggle ? generatePenFormData() : undefined,
			selectedAnimalType: DefaultTypeOption,
			selectedLocationType: DefaultTypeOption,
		}));
		this.props.buildingStateChanged({
			productionForm: this.state.building.productionForm,
			useSections: !toggle,
		} as IBuilding);
		this.props.penFormChanged(toggle ? generatePenFormData() : undefined);
		this.props.sectionFormChanged(toggle ? this.GenerateSectionFormData() : undefined);
	}

	public GenerateSectionFormData(): SectionFormData {
		return {
			startNumber: 0,
			numOfSections: 1,
			UsePens: false,
			selectedAnimalType: undefined,
			selectedLocationType: undefined,
			nameBehindStartNumber: undefined,
		};
	}

	public render() {
		return (
			<ViewWeb className="create-building">
				<ViewWeb className="form-container">
					<SkioldFormsWrapper roundCorners={false} formRows={this.getFormRows()} />
				</ViewWeb>
			</ViewWeb>
		);
	}

	public save() {
		if (!this.validate()) {
			return undefined;
		}

		return this.state.building;
	}
	public validate() {
		return validateBuilding(this.state.building, this.props.buildings, this.state.sectionForm, this.state.penForm);
	}

	private getFormRows() {
		const animalType = generateAnimalType(this.state.building.productionForm);
		let formRows = new Array<FormRow>();
		formRows.push(this.getBuildingHeader());
		formRows.push(this.getBuildingNumberRow());
		formRows.push(this.getBuildingNameRow());
		formRows.push(this.getProductionRow());
		if (!this.state.building.useSections) {
			if (this.state.building.productionForm === ProductionForm.PigProduction) {
				formRows.push(this.getAnimalTypeRow(animalType));
			}
			formRows.push(this.getLocationTypeRow());
			formRows.push(this.getCapacityRow());
		}
		formRows.push(this.getIsUsingSectionsRow());
		//formRows.push(this.getIsUsingQRCodeRow());

		return formRows;
	}

	private getBuildingHeader(): FormRow {
		return {
			header: localized('BUILDING'),
		};
	}

	private getBuildingNumberRow(): FormRow {
		return {
			name: localized('number'),
			component: (
				<SkioldFormIntegerInput
					onChangeNumber={newNumber => this.buildingNumberChanged(newNumber)}
					text={this.state.building.buildingNumber}
				/>
			),
		};
	}
	private getCapacityRow(): FormRow {
		return {
			name: localized('capacity'),
			component: (
				<SkioldFormIntegerInput
					onChangeNumber={newNumber => this.capacityChanged(newNumber)}
					text={this.state.penForm!.capacity}
				/>
			),
		};
	}

	private getBuildingNameRow(): FormRow {
		return {
			name: localized('name'),
			component: (
				<SkioldFormInput
					onChangeText={newText => this.buildingNameChanged(newText)}
					text={this.state.building.name ? this.state.building.name + '' : ''}
				/>
			),
		};
	}

	private getProductionRow(): FormRow {
		return {
			name: localized('production'),
			component: (
				<SkioldFormDropdown
					items={this.state.productionForms}
					selectedValue={this.state.selectedProductionForm}
					onValueChanged={(productionOption: Option) => this.setProductionForm(productionOption)}
				/>
			),
		};
	}

	private getLocationTypeRow(): FormRow {
		return {
			name: localized('locationType'),
			component: (
				<SkioldFormDropdown
					items={generateLocationType(
						this.state.building.productionForm,
						this.state.selectedAnimalType.value,
					)}
					selectedValue={this.state.selectedLocationType}
					onValueChanged={(locationTypeOption: Option) => this.setLocationType(locationTypeOption)}
				/>
			),
		};
	}

	private getAnimalTypeRow(animalTypes: Option[]): FormRow {
		return {
			name: localized('animalType'),
			component: (
				<SkioldFormDropdown
					items={animalTypes}
					selectedValue={this.state.selectedAnimalType}
					onValueChanged={(animalTypeOption: Option) => this.setAnimalType(animalTypeOption)}
				/>
			),
		};
	}

	private getIsUsingSectionsRow(): FormRow {
		return {
			name: localized('isUsingSections'),
			component: (
				<SkioldCheckbox
					onClick={() => {
						this.toggleIsUsingSections();
					}}
					isChecked={this.state.building.useSections!}
				/>
			),
		};
	}
	private generateProductionForms() {
		return [
			{ value: '', label: localized('choose') },
			{ value: ProductionForm.PigProduction, label: localized('PigProduction') },
			{ value: ProductionForm.Mills, label: localized('Mills') },
		];
	}
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(CreateBuilding) as any;
