import { Dispatch } from 'redux';
import { AnimalType, DashboardElaborationTableItem } from 'shared/api/api';
import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import { TableKeyValue } from 'web/view/components/skiold-components/skiold-table/skiold-table-grouped-grid/skiold-table-grouped-grid-helper';
import * as Action from './actions';

export function GetDashboardGrowthPigsOverviewTable(
	animalType: AnimalType,
	siteId: string,
	showSpinner: boolean = true,
) {
	return AsyncOperationBuilder2(
		Action.getDashboardGrowthPigsOverviewTable,
		client => client.growthPigsDashboardEvent_GenerateDashboardTableData(animalType, siteId),
		{ siteId, showSpinner },
	);
}

export function SetSummaryItems(key: string, summaryItems: TableKeyValue[]) {
	return async (dispatch: Dispatch<any>) => {
		dispatch(Action.setSummaryItems({ key, data: summaryItems }));
	};
}

export function SetDashboardItems(key: string, summaryItems: DashboardElaborationTableItem[]) {
	return async (dispatch: Dispatch<any>) => {
		dispatch(Action.setDashboardItems({ key, data: summaryItems }));
	};
}
