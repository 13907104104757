
import * as types from './types';
import actionCreatorFactory from 'typescript-fsa';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import { ISite } from 'shared/api/api';

const actionCreator = actionCreatorFactory(GenActionFactoryName('site'));

export const getSiteAsync = actionCreator.async<{}, ISite, { code: number; message: string }>(types.LOAD);
export const saveSiteAsync = actionCreator.async<ISite, string, { code: number; message: string }>(types.SAVE_SITE);
