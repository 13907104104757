import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AnimalReportType, Equipment } from 'shared/api/api';
import { GetSyncData as AnimalReportSettingGetSyncData } from 'shared/state/ducks/animal-report-settings/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { SkioldTouchableOpacity } from 'web/view/components/skiold-components/skiold-touchable-opacity';
import ActiveAnimalsSetup from 'web/view/components/stem-animal/animal-report-list/active-animals/active-animals-setup';
import FeedingESFReportSettingSetup from 'web/view/components/stem-animal/animal-report-list/feeding-report/feeding-report-setup';
import PReportSetup from 'web/view/components/stem-animal/animal-report-list/production-report/p-report-key-setup';
import SowBoardSettingSetup from 'web/view/components/stem-animal/animal-report-list/sow-board/sow-board-settings';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import './animal-report-settings.scss';

const mapStateToProps = (state: WebAppState) => {
	return {
		siteId: state.profile.active!.siteId,
		animalReportSettings: state.animalReportSettings.entities,
		modules: state.licences.siteModules,
		profile: state.profile.active!,
		usesEsf: state.processEquipments.entities.find(a => a.equipment === Equipment.ESF),
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	getAnimalReportSettingsBySiteId: (siteId: string) => AnimalReportSettingGetSyncData()(dispatch),
});

export interface AnimalReportSettingsState {
	animalReportType: string;
	animalReportTypes: string[];
}

type AnimalReportSettingsProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class AnimalReportSettingsPage extends React.PureComponent<AnimalReportSettingsProps, AnimalReportSettingsState> {
	constructor(props: AnimalReportSettingsProps) {
		super(props);
		this.state = {
			animalReportType: AnimalReportType.ActiveAnimalsReportSetting,
			animalReportTypes: this.getAnimalReportTypes(),
		};
	}

	public componentDidMount() {
		if (this.props.siteId) {
			this.props.getAnimalReportSettingsBySiteId(this.props.siteId);
		}
	}
	private piCheckStyle = { color: '#fff' };
	public render() {
		return (
			<ViewWeb className="animal-report-settings">
				<ViewWeb className="sub-container">
					<TextWeb className="header-text-style">{localized('reports')}</TextWeb>
					{this.state.animalReportTypes.map(type => {
						if (type !== AnimalReportType.MatingBatchReportSetting) {
							return (
								<ViewWeb key={type} className="animal-report-type-picker">
									<SkioldTouchableOpacity
										// underlayColor="transparent"
										onPress={() => this.setState({ animalReportType: type as AnimalReportType })}
									>
										<ViewWeb className="animal-report-type-picker">
											<TextWeb className="item-text-style">
												{localized(type as AnimalReportType)}
											</TextWeb>
											{this.props.animalReportSettings.find(a => a.type === type) && (
												<i className="pi pi-check" style={this.piCheckStyle}></i>
											)}
										</ViewWeb>
									</SkioldTouchableOpacity>
								</ViewWeb>
							);
						} else {
							return <ViewWeb key={type} />;
						}
					})}
				</ViewWeb>

				<ViewWeb className="setup-container">
					{this.state.animalReportType === AnimalReportType.ActiveAnimalsReportSetting && (
						<ActiveAnimalsSetup />
					)}
					{this.state.animalReportType === 'KeyNumbers' && <PReportSetup />}
					{this.state.animalReportType === AnimalReportType.MatingBatchReportSetting && <ViewWeb />}
					{this.state.animalReportType === AnimalReportType.SowBoardReportSetting && <SowBoardSettingSetup />}
					{this.state.animalReportType === AnimalReportType.FeedingESFReportSetting && (
						<FeedingESFReportSettingSetup />
					)}
				</ViewWeb>
			</ViewWeb>
		);
	}

	private getAnimalReportTypes() {
		let types = Object.keys(AnimalReportType).map(k => AnimalReportType[k as any]);
		if (this.props.profile && this.props.profile.processes) {
			const keyNumberProcess = this.props.profile.processes.find(
				process => process === 'a38a7b3c-7204-4683-9988-a46bc4784e36',
			);
			if (keyNumberProcess) {
				types.push('KeyNumbers');
			}
		}

		if (!this.props.usesEsf) {
			types = types.filter(a => a !== AnimalReportType.FeedingESFReportSetting);
		}

		return types;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AnimalReportSettingsPage);
