import { Dispatch } from 'redux';
import React from 'react';
import { connect } from 'react-redux';

import PenIcon from 'shared/assets/src-assets/png/pen_icon.png';
import { WebAppState } from "web/state/store.web";
import './diagnoses.scss';
import { IDiagnose, AnimalKind, IDiagnoseCategory } from 'shared/api/api';
import { SaveDiagnose, GetSyncData as DiagnoseGetSyncData } from 'shared/state/ducks/diagnoses/operations';
import PageContainer from 'web/view/components/page-container/page-container';
import { Heading } from 'web/view/components/utils/heading';
import { localized } from 'shared/state/i18n/i18n';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { SkioldTable, Row } from 'web/view/components/skiold-components/skiold-table/skiold-table';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldModal } from 'web/view/components/skiold-components/skiold-modal/skiold-modal';
import { SowListConstants } from 'web/view/components/stem-animal/animal-lists/table-constants';
import { SkioldTouchableOpacity } from 'web/view/components/skiold-components/skiold-touchable-opacity';
import { SkioldImage } from 'web/view/components/utils/svg/skiold-image';
import { deepCopy } from 'shared/helpers/general-helpers';
import { showAlert } from 'web/view/components/skiold-alert/skiold-alert';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { GetSyncData as DiagnoseCategoriesGetSyncData } from 'shared/state/ducks/diagnoseCategories/operations';
import AddDiagnose from 'web/view/components/diagnose/add-diagnose';
import { SkioldCheckbox } from 'web/view/components/skiold-components/skiold-checkbox/skiold-checkbox';

const mapStateToProps = (state: WebAppState) => {
	return {
		categories: state.diagnoseCategory.entities,
		diagnoses: state.diagnose.entities,
		language: state.profile.active!.language,
		finishedSaving: state.diagnose.finishedSaving
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	getDiagnoseCategories: () => DiagnoseCategoriesGetSyncData()(dispatch),
	getDiagnoses: () => DiagnoseGetSyncData()(dispatch),
	postDiagnose: (diagnose: IDiagnose) => SaveDiagnose(diagnose)(dispatch)
});

interface ToggleAnimalKind{
	diagnose: IDiagnose;
	animalKind: AnimalKind;
}

interface State {
	modalIsOpen: boolean;
	diagnoseToEdit: IDiagnose | undefined;
	diagnoses?: IDiagnose[];
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
class Diagnoses extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			modalIsOpen: false,
			diagnoseToEdit: undefined
		};

		this.props.getDiagnoseCategories();
		this.props.getDiagnoses();
	}

	public render() {
		return (
			<PageContainer className="diagnoses-page">
				<Heading text={localized('DIAGNOSES')} />

				<ViewWeb className="diagnose-table-container">
					<SkioldTable
						columns={this.generateColumns()}
						data={this.props.diagnoses.filter(a=> !a.isDeleted)}
						sortHeaderId={'priority'}
						className="diagnoses-table"
					/>
				</ViewWeb>

				<ViewWeb className="add-diagnose-button">
					<SkioldButton title={localized('addDiagnose')} onPress={() => this.addDiagnose()} />
				</ViewWeb>

				<SkioldModal close={ this.closeModal} isOpen={this.state.modalIsOpen}>
					<AddDiagnose
						close={this.closeModal}
						isOpen={this.state.modalIsOpen}
						diagnose={this.state.diagnoseToEdit}
						diagnoseCategories={this.props.categories}
					/>
				</SkioldModal>
			</PageContainer>
		);
	}

	private closeModal = () => {
		this.setState({ modalIsOpen: false, diagnoseToEdit: undefined });
	}

	private addDiagnose() {
		this.setState({ modalIsOpen: true });
	}

	private edit(diagnose: IDiagnose) {
		this.setState({ modalIsOpen: true, diagnoseToEdit: diagnose });
	}

	private generateColumns() {
		if (!this.props.language) {
			return [];
		}

		return [
			{
				id: 'name',
				Header: localized('name'),
				accessor: (d: IDiagnoseCategory) => this.getName(this.props.language!, d)
			},
			{
				id: 'category',
				Header: localized('category'),
				accessor: (d: IDiagnose) => this.getCategoryNameById(d.diagnoseCategoryId!)
			},
			{
				id: 'hasSowBoar',
				Header: localized('SowBoarGlit'),
				accessor: (d: IDiagnose) => {
					return (
						<SkioldCheckbox
						title=""
						itemFromParent={{animalKind: AnimalKind.Sow, diagnose: d}}
						isChecked={this.hasAnimalKind(AnimalKind.Sow, d)}
						onClick={this.onAnimalKindChanged}
						/>
					);
				}
			},
			{
				id: 'hasPiglet',
				Header: localized('piglets'),
				accessor: (d: IDiagnose) => {
					return (
						<SkioldCheckbox
							title=""
							itemFromParent={{animalKind: AnimalKind.Piglet, diagnose: d}}
							isChecked={this.hasAnimalKind(AnimalKind.Piglet, d)}
							onClick={this.onAnimalKindChanged}
						/>
					);
				}
			},
			{
				id: 'hasYoungFemale',
				Header: localized('youngFemales'),
				accessor: (d: IDiagnose) => {
					return (
						<SkioldCheckbox
							title=""
							itemFromParent={{animalKind: AnimalKind.YoungFemale, diagnose: d}}
							isChecked={this.hasAnimalKind(AnimalKind.YoungFemale, d)}
							onClick={this.onAnimalKindChanged}
						/>
					);
				}

			},
			{
				id: 'hasFinisher',
				Header: localized('Finisher'),
				accessor: (d: IDiagnose) => {
					return (
						<SkioldCheckbox
							title=""
							itemFromParent={{animalKind: AnimalKind.Finisher, diagnose: d}}
							isChecked={this.hasAnimalKind(AnimalKind.Finisher, d)}
							onClick={this.onAnimalKindChanged}
						/>
					);
				}

			},
			{
				id: 'hasWeaner',
				Header: localized('Weaner'),
				accessor: (d: IDiagnose) => {

					return (
						<SkioldCheckbox
							title=""
							itemFromParent={{animalKind: AnimalKind.Weaner, diagnose: d}}
							isChecked={this.hasAnimalKind(AnimalKind.Weaner, d)}
							onClick={this.onAnimalKindChanged}
						/>
					);
				}

			},
			{
				id: 'edit',
				Header: '',
				width: SowListConstants.iconWidth,
				Cell: (row: Row<IDiagnose>) => (
					<SkioldTouchableOpacity onPress={() => this.edit(row.original)}>
						<SkioldImage
							width={SowListConstants.iconSVGWidth}
							height={SowListConstants.iconSVGWidth}
							imageData={PenIcon}
						/>
					</SkioldTouchableOpacity>
				)
			}
		];
	}

	private onAnimalKindChanged = (bool, toggleAnimalKind: ToggleAnimalKind) => {
			this.toggleAnimalKinds([toggleAnimalKind.animalKind], toggleAnimalKind.diagnose);

	}

	private async toggleAnimalKinds(animalKinds: AnimalKind[], diagnose: IDiagnose) {
		const diag = deepCopy(diagnose); // Make a copy of the list
		const list = diag.animalKinds!;
		for (const kind of animalKinds) {
			const index = list.findIndex(ak => ak === kind);
			if (index > -1) {
				list.splice(index, 1);
			} else {
				list.push(kind);
			}
		}
		diag.animalKinds = list;

		await this.props.postDiagnose(diag);
		if (!this.props.finishedSaving) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_DIAGNOSE_ANIMALKIND_STILL_IN_USE));
		}
	}

	private hasAnimalKind(animalKind: AnimalKind, diagnose: IDiagnose) {
		if (!diagnose.animalKinds) {
			return false;
		}

		if (diagnose.animalKinds!.findIndex(kind => kind === animalKind) > -1) {
			return true;
		}

		return false;
	}

	private getCategoryNameById(categoryId: string) {
		const category = this.props.categories.find(c => c.id === categoryId);
		if (category) {
			return category.name![this.props.language!];
		} else {
			return '';
		}
	}

	private getName(countryCode: string, category: IDiagnoseCategory | undefined): string {
		if (!category || !category.name) {
			return '';
		}

		if (category.name[countryCode]) {
			return category.name[countryCode];
		}

		return '';
	}
}

export default (
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Diagnoses)
);
