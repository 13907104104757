import { Action } from 'redux';
import { SharedAppState } from 'shared/state/store.shared';
import { isType } from 'typescript-fsa';
import * as actions from './actions';
import { TableSettingsState } from './types';
import { validateTableResizing } from 'shared/helpers/general-helpers';
export const initialState: TableSettingsState = {
	columnWidthsHashMap: {}
};

export default function tableSettingsReducer(
	state: TableSettingsState = initialState,
	action: Action
): TableSettingsState {

	if (isType(action, actions.setColumnSizeAction)) {
		let item = { ...state.columnWidthsHashMap };
		let validatedData = validateTableResizing(action.payload.data)
		item[action.payload.key] = validatedData;
		
		return {
			...state,
			columnWidthsHashMap: item
		};
	}

	if (action.type === 'persist/REHYDRATE') {
		let a = (action as any) as { payload: SharedAppState; key: string };
		if (a.key === 'root' &&  a.payload &&  a.payload.tableSettings) {
			state = { ...state, columnWidthsHashMap: a.payload.tableSettings.columnWidthsHashMap };
		}
	}

	return state;
}
