import React from 'react';

import { connect } from 'react-redux';
import TasksInFarrowingStableTable, {
    TasksInFarrowingStableTable as TasksInFarrowingStableTableRef
} from 'web/view/components/work-lists/tasks-in-farrowing-stable-work-list/tasks-in-farrowing-stable-table';
import { ITasksInFarrowingBase, WorkListType } from 'shared/api/api';
import { TaskListmapStateToProps, TaskListmapDispatchToProps, TaskListState } from 'shared/helpers/work-list-helpers/tasks-in-farrowing-stable-helper/task-list-item';
import { ViewWeb } from 'web/view/components/utils/web-view';
import './tasks-in-farrowing-stable-list.scss';

interface TaskListPropsFromParent {
    choosedSetting: WorkListType.TasksInFarrowingStableSetting | WorkListType.TasksInFarrowingStableSetting2;
    refCallback: (ref: TasksInFarrowingStableTableRef) => void;
}
type Props = ReturnType<typeof TaskListmapStateToProps> &
    ReturnType<typeof TaskListmapDispatchToProps> &
    TaskListPropsFromParent;

export class TasksInFarrowingStableList extends React.PureComponent<Props, TaskListState> {
    public static defaultProps: Partial<Props> = {
    };
    constructor(props: Props) {
        super(props);
        this.state = {
            editModalOpen: false,
            workListSetting:
                (props.choosedSetting === WorkListType.TasksInFarrowingStableSetting
                    ? props.workListSettings.find(
                          setting => setting.type === WorkListType.TasksInFarrowingStableSetting
                      )!
                    : props.workListSettings.find(
                          setting => setting.type === WorkListType.TasksInFarrowingStableSetting2
                      )!) as ITasksInFarrowingBase
        };
    }
    private setRef = (m: any) => (m ? this.props.refCallback(m) : {})
    public render() {
        return (
            <ViewWeb className='tasks-in-farrowing-stable-list'>
                <ViewWeb className='container-view'>
                    <TasksInFarrowingStableTable
                        ref={this.setRef}
                        workListSetting={this.state.workListSetting}
                        choosedSetting={this.props.choosedSetting}
                    />
                </ViewWeb>
            </ViewWeb>
        );
    }
}

export default (
    connect(
        TaskListmapStateToProps,
        TaskListmapDispatchToProps,
        null,
        { forwardRef: true }
    )(TasksInFarrowingStableList) as any
);
