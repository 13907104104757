import React from 'react';
import { Option } from 'react-dropdown';
import { connect } from 'react-redux';
import {
	DefaultStableSectionOption,
	getStableSections,
	setLocation,
	skioldStableSectionPickerMapDispatchToProps,
	skioldStableSectionPickerMapStateToProps,
	skioldStableSectionPickerProps,
	SkioldStableSectionPickerState,
} from 'shared/helpers/location-helpers/stable-section-helper';
import { SkioldDropdown } from '../../skiold-components/skiold-dropdown/skiold-dropdown';
import { SkioldFormDropdown } from '../../skiold-components/skiold-dropdown/skiold-form-dropdown';

class SkioldStableSectionPicker extends React.PureComponent<
	skioldStableSectionPickerProps,
	SkioldStableSectionPickerState
> {
	public static defaultProps: Partial<skioldStableSectionPickerProps> = {
		isPigProduction: false,
	};
	public set: any;
	constructor(props: skioldStableSectionPickerProps) {
		super(props);

		this.state = {
			selectedSection: DefaultStableSectionOption,
			penId: undefined,
			sectionId: undefined,
		};
	}

	public async componentDidMount() {
		if (
			this.props.useCurrentLocationFromParent &&
			this.props.useCurrentLocation &&
			this.props.currentLocation &&
			this.props.currentLocation.sectionId
		) {
			await setLocation(
				this.props.currentLocation.penId,
				this.props.currentLocation.sectionId,
				this.props,
				this.state,
				this,
				this.stableSectionSelected,
			);
		} else {
			await setLocation(
				this.props.penId,
				this.props.sectionId,
				this.props,
				this.state,
				this,
				this.stableSectionSelectedWithoutCallback,
			);
		}
	}

	public async UNSAFE_componentWillReceiveProps(nextProps: skioldStableSectionPickerProps) {
		if ((nextProps.penId !== this.state.penId && nextProps.penId) || nextProps.sectionId !== this.state.sectionId) {
			await setLocation(
				nextProps.penId,
				nextProps.sectionId,
				this.props,
				this.state,
				this,
				this.stableSectionSelectedWithoutCallback,
			);
		}

		if (nextProps.sectionId === undefined && this.props.sectionId && this.props.filteredLocations) {
			this.setState({ selectedSection: DefaultStableSectionOption });
		}
	}

	public render() {
		if (this.props.usedInsideForm) {
			return this.renderSkioldFormStableSectionPicker();
		} else {
			return this.renderSkioldStableSectionPicker();
		}
	}

	private renderSkioldStableSectionPicker() {
		return (
			<SkioldDropdown
				containerClassName={this.props.containerClassName}
				usedInTable={this.props.usedInsideTable}
				theme={this.props.theme}
				disabled={this.props.disabled}
				items={getStableSections(this.props)}
				onValueChanged={this.stableSectionSelected}
				selectedValue={this.state.selectedSection}
				dropdownIdentifier={this.props.dropdownIdentifier}
			/>
		);
	}

	private renderSkioldFormStableSectionPicker() {
		return (
			<SkioldFormDropdown
				containerClassName={this.props.containerClassName}
				theme={this.props.theme}
				items={getStableSections(this.props)}
				disabled={this.props.disabled}
				onValueChanged={this.stableSectionSelected}
				selectedValue={this.state.selectedSection}
				dropdownIdentifier={this.props.dropdownIdentifier}
			/>
		);
	}

	private stableSectionSelectedWithoutCallback = (stableSectionOption: Option) => {
		this.setState({ selectedSection: stableSectionOption });
		if (stableSectionOption && stableSectionOption.value) {
			this.props.setCurrentLocation({ ...this.props.currentLocation, sectionId: stableSectionOption.value });
		}
	};

	private stableSectionSelected = (stableSectionOption: Option) => {
		this.setState({ selectedSection: stableSectionOption });
		if (stableSectionOption && stableSectionOption.value) {
			this.props.setCurrentLocation({ ...this.props.currentLocation, sectionId: stableSectionOption.value });
		}

		this.props.onStableSectionSelected(stableSectionOption.value, this.props.itemFromParent);
	};
}

export default connect(
	skioldStableSectionPickerMapStateToProps,
	skioldStableSectionPickerMapDispatchToProps,
)(SkioldStableSectionPicker);
