import React, { FC, useEffect, useState } from 'react';
import { Option } from 'react-dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { GrowthPigEntranceEventDto, IGrowthPigEntranceEventDto } from 'shared/api/api';
import { setOneDecimalsAsNumber } from 'shared/helpers/general-helpers';
import {
	handleEntranceEditBeforeSave,
	HandlePenChangeEntranceGpeEdit,
	initEditGpeEntranceData,
} from 'shared/helpers/growth-pigs-helper/growth-pig-entrance-helpers';
import {
	HandleGrowthPigDistributeAmountChanged,
	validateDistributeGrowthPigs,
} from 'shared/helpers/growth-pigs-helper/growth-pig-event-function-helper';
import { GrowthPigEntranceEventBundleListRow } from 'shared/helpers/growth-pigs-helper/growth-pig-event-list-helper';
import { sortByOrder } from 'shared/helpers/location-helper';
import { memoizeHashmapLocation } from 'shared/helpers/memoize-getters/memoize-getters';
import { upsertGrowthPigsEntranceEvents } from 'shared/state/ducks/growth-pig-events/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { showAlert } from 'web/view/components/skiold-alert/skiold-alert';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldDatePicker } from 'web/view/components/skiold-components/skiold-date-picker/skiold-date-picker';
import { SkioldFormDecimalInput } from 'web/view/components/skiold-components/skiold-decimal-input/skiold-form-decimal-input';
import { SkioldTableGrid } from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { WhiteText } from 'web/view/components/Text/white-text';
import { Heading } from 'web/view/components/utils/heading';
import { ViewWeb } from 'web/view/components/utils/web-view';
import GrowthPigDistributeForm from '../../growth-pig-events/growth-pig-distribute-form';
import '../../growth-pig-events/growth-pig-distribute.scss';

interface PropsFromParent {
	closeModal: (saved?: boolean) => void;
	navigation?: any;
	bundle: GrowthPigEntranceEventBundleListRow;
}

export const GpeEntranceEditModal: FC<PropsFromParent> = React.memo(({ bundle, closeModal }) => {
	const dispatch = useDispatch();

	// Get data
	const pens = useSelector((state: WebAppState) => state.locations.pens);
	const growthPigEvents = useSelector((state: WebAppState) => state.growthPigEvents.growthPigEvents);
	const hashmapLocations = useSelector((state: WebAppState) =>
		memoizeHashmapLocation(state.locations.buildings, state.locations.sections, state.locations.pens),
	);
	const siteId = useSelector((state: WebAppState) => state.profile.active && state.profile.active.siteId);

	// Set State
	const [fromPenOption, setFromPenOption] = useState<Option>();
	const [toPenOption, setToPenOption] = useState<Option>();
	const [penOptions, setPenOptions] = useState<Option[]>([]);
	const [pensToUse, setPensToUse] = useState<string[]>([]);
	const [amountPigsNotDistributed, setAmountPigsNotDistributed] = useState<number>(0);
	const [totalWeightNotDistributedState, setTotalWeightNotDistributed] = useState<number>(0);
	const [data, setData] = useState<GrowthPigEntranceEventDto[]>([]);
	const [registrationDate, setRegistrationDate] = useState<Date | undefined>(new Date());

	// ComponentDidMount
	useEffect(() => {
		// Setup pens options by bundleId
		const options = sortByOrder(pens.filter(p => p.sectionId === bundle.sectionId)).map(p => {
			return { label: p.name!, value: p.id! } as Option;
		});
		setPenOptions(options);
		setFromPenOption(options && options[0]);
		setToPenOption(options && options[options.length - 1]);
		initData(options);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const close = () => {
		if (closeModal) {
			closeModal();
		}
	};
	const initData = (options: Option[]) => {
		// Init table data, by current Gpe events
		const initData = initEditGpeEntranceData(options, growthPigEvents, bundle, hashmapLocations, siteId);
		setPensToUse(options.map(e => e.value));
		setData(initData);
		if (initData && initData.length) {
			setRegistrationDate(initData[0].date);
		}
	};

	const onAmounthanged = (numb: number | undefined, item: IGrowthPigEntranceEventDto) => {
		let dataArrayCopy = [...data];

		// Handle change item, and sets amount not Distributed
		let { currentAmountNotDistributed, totalWeightNotDistributed } = HandleGrowthPigDistributeAmountChanged(
			dataArrayCopy,
			item,
			numb,
			amountPigsNotDistributed,
			totalWeightNotDistributedState,
		);
		setData(dataArrayCopy);
		setAmountPigsNotDistributed(currentAmountNotDistributed);
		setTotalWeightNotDistributed(totalWeightNotDistributed);
	};

	const OnFromPenChanged = (newValue?: Option) => {
		// recalculate distribution, and remove unused pens
		if (newValue) {
			const fromPenToUseIndex = penOptions.findIndex(p => newValue && p.value === newValue.value);
			const toPenToUseIndex = penOptions.findIndex(p => toPenOption && p.value === toPenOption.value);
			const pensToUse = penOptions.slice(fromPenToUseIndex, toPenToUseIndex + 1);

			let copyData = HandlePenChangeEntranceGpeEdit(data, pensToUse, bundle);

			setPensToUse(pensToUse.map(e => e.value));
			setFromPenOption(newValue);
			setData(copyData);
		}
	};

	const OnToPenChanged = (newValue?: Option) => {
		// recalculate distribution, and remove unused pens
		if (newValue) {
			const fromPenToUseIndex = penOptions.findIndex(p => fromPenOption && p.value === fromPenOption.value);
			const toPenToUseIndex = penOptions.findIndex(p => newValue && p.value === newValue.value);
			const pensToUse = penOptions.slice(fromPenToUseIndex, toPenToUseIndex + 1);

			let copyData = HandlePenChangeEntranceGpeEdit(data, pensToUse, bundle);

			setPensToUse(pensToUse.map(e => e.value));
			setToPenOption(newValue);
			setData(copyData);
		}
	};

	const onDateChanged = (date?: Date) => {
		setRegistrationDate(date);
	};

	const getPenNameCell = (item: IGrowthPigEntranceEventDto) => item.name;

	const getAmountCell = (item: IGrowthPigEntranceEventDto) => (
		<SkioldFormDecimalInput text={item.amount} itemFromParent={item} onChangeNumber={onAmounthanged} />
	);

	const getWeightCell = (item: IGrowthPigEntranceEventDto) => setOneDecimalsAsNumber(item.avgWeight);

	const generateColumns = () => {
		return [
			{
				name: 'pen',
				title: localized('PenNumber'),
				getCellValue: getPenNameCell,
			},
			{
				name: 'amount',
				sortable: false,
				filterable: false,
				title: localized('Count'),
				getCellValue: getAmountCell,
			},
			{
				name: 'weight',
				sortable: false,
				filterable: false,
				title: localized('avgWeight'),
				getCellValue: getWeightCell,
			},
		] as any[];
	};

	const generateColumnExtes = () => {
		return [
			{
				columnName: 'pen',
				width: 230,
			},
			{
				columnName: 'amount',
				width: 230,
			},
			{
				columnName: 'weight',
				filteringEnabled: false,
				width: 230,
			},
		];
	};

	const save = async () => {
		if (validateDistributeGrowthPigs(totalWeightNotDistributedState, amountPigsNotDistributed, showAlert)) {
			const dataToSave = handleEntranceEditBeforeSave(data, pensToUse, bundle, registrationDate);
			upsertGrowthPigsEntranceEvents(dataToSave)(dispatch);
			close();
		}
	};

	const renderButtons = () => {
		return (
			<ViewWeb className="button-view-style">
				<SkioldButton theme="grey" title={localized('Close')} onPress={closeModal} />
				<SkioldButton title={localized('Save')} onPress={save} />
			</ViewWeb>
		);
	};

	return (
		<ViewWeb className="growth-pig-distribute">
			<Heading text={localized('DistributePigsInSection')}></Heading>
			<ViewWeb className="distribute-container">
				<WhiteText className="bold-white-text">
					{localized('ToLocation')}: {bundle.locationString}
				</WhiteText>
				<ViewWeb>
					<GrowthPigDistributeForm
						OnFromPenChanged={OnFromPenChanged}
						OnToPenChanged={OnToPenChanged}
						fromPenId={fromPenOption}
						toSectionId={bundle.sectionId}
						toPenId={toPenOption}
						disablePenSelect={false}
						amountPigsNotDistributed={amountPigsNotDistributed}
						totalWeightNotDistributed={totalWeightNotDistributedState}
					/>
					<ViewWeb className="flexDirectionRow">
						<WhiteText className="penTextStyle">{localized('Date')}:</WhiteText>
						<SkioldDatePicker
							selectedDate={registrationDate}
							onDateChanged={onDateChanged}
							theme={'light'}
							containerClassName="distribute-datepicker-container"
						/>
					</ViewWeb>
				</ViewWeb>
				<SkioldTableGrid
					ignoreSetCount={true}
					filterable={false}
					sortable={false}
					ColumnExtensions={generateColumnExtes()}
					columns={generateColumns()}
					data={data.filter(e => e.toPenId && pensToUse.includes(e.toPenId))}
					containerClassName={'growth-distribution-table-max-height'}
				/>
			</ViewWeb>
			{renderButtons()}
		</ViewWeb>
	);
});
