import React from 'react';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
	FarrowingTaskTreatment,
	IFarrowingTaskTreatment,
	ITasksInFarrowingBase,
	ITasksInFarrowingStableSetting,
	ITasksInFarrowingStableSetting2,
	TasksInFarrowingStableSetting,
	TasksInFarrowingStableSetting2,
	WorkListType,
} from 'shared/api/api';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { getTreatmentDefinitionIdsBySetting } from 'shared/helpers/work-list-helpers/tasks-in-farrowing-stable-helper/tasks-in-farrowing-stable-helper';
import { SaveWorkListSetting } from 'shared/state/ducks/work-list-settings/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { SkioldButton } from '../../skiold-components/skiold-button/skiold-button';
import { SkioldCheckbox } from '../../skiold-components/skiold-checkbox/skiold-checkbox';
import { SkioldFormDecimalInput } from '../../skiold-components/skiold-decimal-input/skiold-form-decimal-input';
import { SkioldFormsWrapper } from '../../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from '../../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { SkioldFormInput } from '../../skiold-components/skiold-input/skiold-form-input';
import { SkioldFormIntegerInput } from '../../skiold-components/skiold-integer-input/skiold-form-integer-input';
import './tasks-in-farrowing-stable-setup.scss';
import { TasksInFarrowingSubTask } from './tasks-in-farrowing-stable-sub-tasks';
import { validateTasksInFarrowing } from './tasks-in-farrowing-stable-helper';

const mapStateToProps = (state: WebAppState) => {
	return {
		tasksInFarrowingStableSettings: state.workListSettings.entities,
		siteId: state.profile.active!.siteId,
		diagnoses: state.diagnose.entities,
		treatmentDefinitions: state.treatmentDefinitions.entities,
		lang: state.profile.active!.language,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	saveWorkListSetting: (tasksInFarrowingStableSetting: ITasksInFarrowingBase) =>
		SaveWorkListSetting(tasksInFarrowingStableSetting)(dispatch),
});

interface PropsFromParent {
	choosedSetting: WorkListType.TasksInFarrowingStableSetting | WorkListType.TasksInFarrowingStableSetting2;
}

interface State {
	tasksInFarrowingStableSetting: ITasksInFarrowingBase;
	treatmentDefsToFilter: string[];
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

class TasksInFarrowingStableSetup extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		const doesExist = props.tasksInFarrowingStableSettings.find(set => set.type === props.choosedSetting);
		const setting = doesExist ? (doesExist as ITasksInFarrowingBase) : this.init();
		this.state = {
			tasksInFarrowingStableSetting: setting,
			treatmentDefsToFilter: [],
		};
	}

	public componentDidMount() {
		this.setTreatmentDefBlacklist();
	}

	public toggleShowOnWeb = () => {
		this.setState(prevState => ({
			tasksInFarrowingStableSetting: {
				...prevState.tasksInFarrowingStableSetting,
				showOnWeb: !this.state.tasksInFarrowingStableSetting.showOnWeb,
			},
		}));
	};

	public toggleShowOnApp = () => {
		this.setState(prevState => ({
			tasksInFarrowingStableSetting: {
				...prevState.tasksInFarrowingStableSetting,
				showOnApp: !this.state.tasksInFarrowingStableSetting.showOnApp,
			},
		}));
	};

	public tasksInFarrowingStableSettingBase = (key: keyof ITasksInFarrowingBase, value: any) => {
		this.setState(prevState => ({
			tasksInFarrowingStableSetting: {
				...prevState.tasksInFarrowingStableSetting,
				[key]: value,
			},
		}));
	};

	public tasksInFarrowingStableSettingChanged = (key: keyof ITasksInFarrowingBase, value: any) => {
		this.setState(prevState => ({
			tasksInFarrowingStableSetting: {
				...prevState.tasksInFarrowingStableSetting,
				[key]: value,
			},
		}));
	};

	public render() {
		return (
			<ViewWeb className="tasks-in-farrowing-stable-setup">
				<ViewWeb className="view-container">
					<TextWeb className="text-style">{localized('Setup')}</TextWeb>
					<SkioldFormsWrapper formRows={this.getFormRows()} containerClassName="wrapper-container" />
					{this.renderButtons()}
				</ViewWeb>
			</ViewWeb>
		);
	}

	public farrowingTaskTreatment = (
		subTask: IFarrowingTaskTreatment,
		keySubTask: keyof FarrowingTaskTreatment,
		value: any,
	) => {
		if (keySubTask === 'treatAll' && subTask[keySubTask] === value) {
			value = undefined;
		}

		this.setState(
			prevState => ({
				tasksInFarrowingStableSetting: {
					...prevState.tasksInFarrowingStableSetting,
					farrowingTaskTreatments: prevState.tasksInFarrowingStableSetting.farrowingTaskTreatments!.map(e =>
						e.subTaskNumber === subTask.subTaskNumber
							? new FarrowingTaskTreatment({ ...e, [keySubTask]: value })
							: e,
					),
				},
			}),
			() => {
				if (keySubTask === 'treatmentDefinitionId') {
					this.setTreatmentDefBlacklist();
				}
			},
		);
	};

	private init() {
		let settingToInit: ITasksInFarrowingBase;
		if (this.props.choosedSetting === WorkListType.TasksInFarrowingStableSetting) {
			settingToInit = TasksInFarrowingStableSetting.fromJS({});

			settingToInit.type = WorkListType.TasksInFarrowingStableSetting;
			settingToInit.farrowingTaskTreatments = [
				new FarrowingTaskTreatment({ subTaskNumber: 1 }),
				new FarrowingTaskTreatment({ subTaskNumber: 2 }),
				new FarrowingTaskTreatment({ subTaskNumber: 3 }),
				new FarrowingTaskTreatment({ subTaskNumber: 4 }),
			];
			settingToInit.siteId = this.props.siteId;
		} else {
			settingToInit = TasksInFarrowingStableSetting2.fromJS({});
			settingToInit.type = WorkListType.TasksInFarrowingStableSetting2;
			settingToInit.farrowingTaskTreatments = [
				new FarrowingTaskTreatment({ subTaskNumber: 1 }),
				new FarrowingTaskTreatment({ subTaskNumber: 2 }),
				new FarrowingTaskTreatment({ subTaskNumber: 3 }),
				new FarrowingTaskTreatment({ subTaskNumber: 4 }),
			];
			settingToInit.siteId = this.props.siteId;
		}
		return settingToInit;
	}

	private getFormRows() {
		let formRows = new Array<FormRow>();

		formRows.push(this.getHeader());
		formRows.push(this.getNameRow());
		formRows.push(this.getSowStateRow());
		formRows.push(this.getNursingDaysFromToRow());

		formRows.push(this.getBoarPcsRow());
		this.state.tasksInFarrowingStableSetting!.farrowingTaskTreatments!.forEach(subTask => {
			formRows.push(this.getCombinedRow(subTask));
		});

		formRows.push(this.getAvailablityRow());

		return formRows;
	}

	private getAvailablityRow(): FormRow {
		return {
			name: localized('listAvailableOn'),
			component: (
				<ViewWeb className="availability-container">
					<SkioldCheckbox
						onClick={this.toggleShowOnApp}
						isChecked={this.state.tasksInFarrowingStableSetting.showOnApp!}
					/>
					<TextWeb>{localized('App')}</TextWeb>
					<SkioldCheckbox
						onClick={this.toggleShowOnWeb}
						isChecked={this.state.tasksInFarrowingStableSetting.showOnWeb!}
					/>
					<TextWeb>{localized('Web')}</TextWeb>
				</ViewWeb>
			),
		};
	}

	private boarPcsChange = (newPercentage: number | undefined) => {
		this.tasksInFarrowingStableSettingBase('boarPcs', newPercentage);
	};

	private getBoarPcsRow(): FormRow {
		return {
			name: localized('boarPerLitterPcs'),
			component: (
				<SkioldFormDecimalInput
					onChangeNumber={this.boarPcsChange}
					text={this.state.tasksInFarrowingStableSetting.boarPcs}
					maxLength={3}
				/>
			),
		};
	}

	private nursingFromDateChanged = (newNumber: number | undefined) => {
		this.tasksInFarrowingStableSettingChanged('nursingDaysFrom', newNumber);
	};

	private nursingToDateChanged = (newNumber: number | undefined) => {
		this.tasksInFarrowingStableSettingChanged('nursingDaysTo', newNumber);
	};

	private getNursingDaysFromToRow(): FormRow {
		return {
			name: localized('NursingDays'),
			component: (
				<ViewWeb className="nursing-from-to-container">
					<ViewWeb className="flex-one-row form-padding">
						<TextWeb>{localized('from')}</TextWeb>
						<SkioldFormIntegerInput
							className="days-input"
							onChangeNumber={this.nursingFromDateChanged}
							text={
								(this.state.tasksInFarrowingStableSetting as TasksInFarrowingStableSetting)
									.nursingDaysFrom
							}
						/>
					</ViewWeb>
					<ViewWeb className="flex-one-row">
						<TextWeb>{localized('to')}</TextWeb>
						<SkioldFormIntegerInput
							className="days-input"
							onChangeNumber={this.nursingToDateChanged}
							text={
								(this.state.tasksInFarrowingStableSetting as TasksInFarrowingStableSetting)
									.nursingDaysTo
							}
						/>
					</ViewWeb>
				</ViewWeb>
			),
		};
	}

	private nameChanged = (newName: string) => {
		this.tasksInFarrowingStableSettingBase('name', newName);
	};

	private getNameRow(): FormRow {
		return {
			name: localized('name'),
			component: (
				<SkioldFormInput onChangeText={this.nameChanged} text={this.state.tasksInFarrowingStableSetting.name} />
			),
		};
	}

	private getSowStateRow(): FormRow {
		return {
			name: localized('condition'),
			component: <TextWeb className="condition-container">{localized('NursingLong')}</TextWeb>,
		};
	}

	private getCombinedRow(subTask: IFarrowingTaskTreatment): FormRow {
		return {
			name: (
				<ViewWeb className="multiple-row-container">
					<TextWeb className="bold-font-size-sixteen">
						{localized('treatmentVaccination')} {subTask.subTaskNumber}
					</TextWeb>
					<TextWeb className="bold-font-size-sixteen">{localized('columnTextShownAs')}</TextWeb>
					<TextWeb className="bold-font-size-sixteen">{localized('toBetreated')}</TextWeb>
				</ViewWeb>
			),
			component: (
				<TasksInFarrowingSubTask
					subTask={subTask}
					callback={this.farrowingTaskTreatment}
					treatmentDefinitionBlacklist={this.state.treatmentDefsToFilter}
					treatmentDefinitions={this.props.treatmentDefinitions}
					style={{ zIndex: 5 - subTask.subTaskNumber! }}
				/>
			),
			style: { height: 140 },
		};
	}

	private save = async () => {
		if (!validateTasksInFarrowing(this.state.tasksInFarrowingStableSetting as ITasksInFarrowingStableSetting)) {
			return;
		}

		this.props.saveWorkListSetting(this.state.tasksInFarrowingStableSetting);
	};

	private renderButtons() {
		return (
			<ViewWeb className="view-button-container">
				<SkioldButton title={localized('Save')} onPress={this.save} />
			</ViewWeb>
		);
	}

	private getHeader(): FormRow {
		return {
			header: localized(this.state.tasksInFarrowingStableSetting.type!),
		};
	}

	private setTreatmentDefBlacklist() {
		const idsToFilter = getTreatmentDefinitionIdsBySetting(this.state.tasksInFarrowingStableSetting);
		this.setState({ treatmentDefsToFilter: idsToFilter });
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TasksInFarrowingStableSetup);
