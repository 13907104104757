
import { IFeedingSubject } from 'shared/api/api';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import actionCreatorFactory from 'typescript-fsa';
import * as types from './types';

const actionCreator = actionCreatorFactory(GenActionFactoryName('feedingSubject'));

export const saveFeedingSubject = actionCreator.async<IFeedingSubject, string, { code: number; message: string; prevEntity: IFeedingSubject | undefined }>(types.SAVE_FEEDINGSUBJECT);
export const getData = actionCreator.async<{}, IFeedingSubject[]>(
    types.FETCH_DATA_FEEDINGSUBJECTS
);
 