import React, { FC } from 'react';
import { localized } from 'shared/state/i18n/i18n';
import { SkioldFormsWrapper } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { SkioldFormIntegerInput } from 'web/view/components/skiold-components/skiold-integer-input/skiold-form-integer-input';
import { useGeneralSetting } from './general-settings-overview';

interface Props {}

export const MatingSettings: FC<Props> = React.memo(() => {
	const { generalSetting, setGeneralSetting } = useGeneralSetting();
	const getFormRows = () => {
		let formRows = new Array<FormRow>();

		formRows.push(getWorkListHeader());
		formRows.push(ShowMinAgeInDaysFirstMating());
		formRows.push(ShowMaxDaysAfterBoarDepartureSemen());

		return formRows;
	};
	const getWorkListHeader = (): FormRow => {
		return {
			header: localized('Matings'),
		};
	};

	const ShowMinAgeInDaysFirstMating = (): FormRow => {
		return {
			name: localized('MinAgeInDaysFirstMating'),
			component: (
				<SkioldFormIntegerInput
					onChangeNumber={SetMinAgeInDaysFirstMating}
					text={generalSetting.minAgeInDaysAtFirstMating}
				/>
			),
		};
	};

	const ShowMaxDaysAfterBoarDepartureSemen = (): FormRow => {
		return {
			name: localized('MaxDaysAfterBoarDepartureSemen'),
			component: (
				<SkioldFormIntegerInput
					onChangeNumber={SetMaxDaysAfterBoarDepartureSemen}
					text={generalSetting.maxDaysAfterBoarDepartureSemen}
				/>
			),
		};
	};

	const SetMinAgeInDaysFirstMating = (value: number | undefined) => {
		setGeneralSetting({ ...generalSetting, minAgeInDaysAtFirstMating: value! });
	};

	const SetMaxDaysAfterBoarDepartureSemen = (value: number | undefined) => {
		setGeneralSetting({ ...generalSetting, maxDaysAfterBoarDepartureSemen: value! });
	};

	return <SkioldFormsWrapper formRows={getFormRows()} containerClassName="wrapper-container" />;
});
