import React from 'react';
import { SkioldImage } from './svg/skiold-image';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import gul_topbar from 'shared/assets/src-assets/png/gul_topbar@4x.png';
import './heading.scss';

export interface HeadingProps {
	text: string;
	allLettersCapitalized?: boolean;
	renderHtml: JSX.Element;
}

export class Heading extends React.PureComponent<HeadingProps> {
	public static defaultProps: Partial<HeadingProps> = {
		allLettersCapitalized: true,
	};

	private topBarHeight = 35;

	public render() {
		return (
			<ViewWeb className="heading">
				{this.renderSvg()}
				{this.props.renderHtml ? (
					this.props.renderHtml
				) : (
					<TextWeb className="heading-text-style">
						{this.props.text
							? this.props.allLettersCapitalized
								? this.props.text.toUpperCase()
								: this.props.text
							: ''}
					</TextWeb>
				)}
			</ViewWeb>
		);
	}
	private renderSvg() {
		return (
			<SkioldImage
				width={window.innerWidth}
				height={this.topBarHeight}
				mode="stretch"
				imageData={gul_topbar}
				className="image-container"
			/>
		);
	}
}
