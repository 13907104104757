
import { IFeedingSubject } from "shared/api/api";


export const SAVE_FEEDINGSUBJECT = 'SAVE_FEEDINGSUBJECT';
export const DELETE_FEEDINGSUBJECT = 'DELETE_FEEDINGSUBJECT';
export const FETCH_DATA_FEEDINGSUBJECTS = 'FETCH_DATA_FEEDINGSUBJECTS';

export interface FeedingSubjectState {
    entities: IFeedingSubject[];

}
