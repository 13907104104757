import React, { FC, useEffect, useState } from 'react';
import { Option } from 'react-dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { getGrowthPigsDepartureEventsByDates } from 'shared/state/ducks/growth-pig-events/operations';
import { GetSyncSlaughterHouseDatas } from 'shared/state/ducks/slaughter-data/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { SlaughterDataDropDown } from '../../skiold-components/finisher-data-dropdown/finisher-data-dropdown';
import { SkioldButton } from '../../skiold-components/skiold-button/skiold-button';
import { SkioldFetch } from '../../skiold-components/skiold-fetch/skiold-fetch';
import { Heading } from '../../utils/heading';
import { CheckSlaughterDataWithDepartureDataTable } from './check-slaughter-data-with-departure-data-table';
import '../slaughter-pigs-overview.scss';
import { showSpinnerByActionTypes } from 'shared/state/ducks/general-settings/selectors';
import { SYNC_DATA_SLAUGHTER_DATA } from 'shared/state/ducks/slaughter-data';
import { GET_EVENTTYPE_GROWTHPIGEVENTS_BY_DATES } from 'shared/state/ducks/growth-pig-events';

interface PropsFromParent {
	dateFrom: Date;
	dateTo: Date;
	closeModal: () => void;
}
const spinnerActionsTypes = ['GetEventTypesGrowthPigEventsByDates', 'getSlaughterData'];
export const CheckSlaughterDataWithDepartureData: FC<PropsFromParent> = React.memo(props => {
	const sectionId = useState<string | undefined>(undefined);
	const showSpinner = useSelector(showSpinnerByActionTypes(spinnerActionsTypes));

	const dispatch = useDispatch();
	const [dates, setDates] = useState({
		fromDate: props.dateFrom,
		toDate: props.dateTo,
		tmpFromDate: props.dateFrom,
		tmpToDate: props.dateTo,
	});

	const site = useSelector((state: WebAppState) => state.site.site);
	const onFromDateChanged = (date: Date) => {
		setDates({ ...dates, tmpFromDate: date });
	};
	const onToDateChanged = (date: Date) => {
		setDates({ ...dates, tmpToDate: date });
	};
	const fetchData = (fromDate: Date, toDate: Date) => {
		setDates({ ...dates, fromDate, toDate });
		GetSyncSlaughterHouseDatas(site.id, fromDate, toDate)(dispatch);
		getGrowthPigsDepartureEventsByDates(fromDate, toDate)(dispatch);
	};

	useEffect(() => {
		GetSyncSlaughterHouseDatas(site.id, dates.fromDate, dates.toDate)(dispatch);
		getGrowthPigsDepartureEventsByDates(dates.fromDate, dates.toDate)(dispatch);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, site]);
	const sectionChanged = (option: Option) => {
		sectionId[1](option.value);
	};
	return (
		<ViewWeb className="slaughterPigsDataOverview">
			<Heading text={localized('checkSlaughterDataWithDepartureData')}></Heading>
			<ViewWeb className="flexDirectionRow justifyContentSpaceBetween">
				<SkioldFetch
					isControlled={true}
					fetchData={fetchData}
					toDateChange={onToDateChanged}
					fromDateChange={onFromDateChanged}
					fromDate={dates.tmpFromDate}
					toDate={dates.tmpToDate}
					showSpinner={showSpinner}
				/>
				<ViewWeb className="flexDirectionRow alignItemsCenter">
					<TextWeb className="text flexOne">{localized('Location') + ': '} </TextWeb>
					<SlaughterDataDropDown sectionId={sectionId[0]} onValueChanged={sectionChanged} />
				</ViewWeb>
				<ViewWeb className="flexDirectionRow">
					<SkioldButton
						className="smallButton"
						theme="grey"
						title={localized('Close')}
						onPress={props.closeModal}
					/>
				</ViewWeb>
			</ViewWeb>
			<CheckSlaughterDataWithDepartureDataTable
				sectionId={sectionId[0]}
				dateFrom={dates.fromDate}
				dateTo={dates.toDate}
			/>
		</ViewWeb>
	);
});
