import memoizeOne from 'memoize-one';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BalanceWeanedPigEvent, GrowthPigEventType, GrowthPigsEvent } from 'shared/api/api';
import DepartureAnimal from 'shared/assets/src-assets/png/afgang_lightgrey_01.png';
import MoveAnimal from 'shared/assets/src-assets/png/flyt_lightgrey_01.png';
import { BalanceWeanedPigEventOverviewTableData } from 'shared/helpers/balance-weaned-pigs-helper/balance-weaned-pigs-helper';
import { AddDaysToDate } from 'shared/helpers/date-helpers';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { getBalanceWeanedPigsData } from 'shared/state/ducks/balance-weaned-pigs/operations';
import { getGrowthPigsEventsByDates } from 'shared/state/ducks/growth-pig-events/operations';
import { GetLocations } from 'shared/state/ducks/locations/operations';
import * as validationOperation from 'shared/state/ducks/validation-setup/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { showAlert } from 'web/view/components/skiold-alert/skiold-alert';
import { SkioldFetch } from 'web/view/components/skiold-components/skiold-fetch/skiold-fetch';
import { SkioldTouchableOpacity } from 'web/view/components/skiold-components/skiold-touchable-opacity';
import { WhiteText } from 'web/view/components/Text/white-text';
import { Heading } from 'web/view/components/utils/heading';
import { SkioldImage } from 'web/view/components/utils/svg/skiold-image';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { BalanceWeanedPigTable } from './balance-weaned-pig-table';
import './balance-weaned-pigs-page.scss';
import { RegisterBalanceWeanedPigDepartModal } from './depart-weaned-pigs';
import { DistributeWeanedPigsModal } from './distribute-weaned-pigs';

export const BalanceWeanedPigsOverview: FC = React.memo(() => {
	const dispatch = useDispatch();
	const [fromDate, setFromDate] = useState<Date>(AddDaysToDate(new Date().withoutTime(), -7));
	const [toDate, setToDate] = useState<Date>(new Date().endOfDayTime());
	const [tableItemsCopy, setTableItemsCopy] = useState<BalanceWeanedPigEvent[]>([]);
	const [showDepartModal, setDepartModal] = useState<boolean>(false);
	const [showDistributeModal, setShowDistributeModal] = useState<boolean>(false);
	const weanedPenId = useSelector((state: WebAppState) => state.generalSettings.entity.weanedPenId);

	const siteId = useSelector((state: WebAppState) => state.profile.active && state.profile.active.siteId);
	const gpes = useSelector((state: WebAppState) => state.growthPigEvents.growthPigEvents);
	const data = useSelector((state: WebAppState) => state.balanceWeanedPigsData.entities);
	const isFetchingData = useSelector((state: WebAppState) => state.balanceWeanedPigsData.isFetchingData);

	const currentPigsOnStable = useSelector((state: WebAppState) => state.balanceWeanedPigsData.currentPigsOnStable);

	const dataMemo = useMemo(() => data.filter(e => e.date! >= fromDate && e.date! <= toDate), [
		data,
		fromDate,
		toDate,
	]);

	useEffect(() => {
		const initialFromDate = AddDaysToDate(new Date().withoutTime(), -7);
		const initialToDate = new Date().endOfDayTime();
		GetLocations()(dispatch);
		validationOperation.GetSyncData()(dispatch);
		getGrowthPigsEventsByDates(initialFromDate, initialToDate)(dispatch);
		getBalanceWeanedPigsData(siteId, initialFromDate, initialToDate)(dispatch);
	}, [dispatch, siteId]);

	const fetch = useCallback(
		(fetchFromDate: Date, fetchToDate: Date) => {
			if (fetchFromDate && fetchToDate && siteId) {
				getGrowthPigsEventsByDates(fetchFromDate, fetchToDate)(dispatch);
				getBalanceWeanedPigsData(siteId, fetchFromDate, fetchToDate)(dispatch);
			}
		},
		[dispatch, siteId],
	);

	const renderButtons = () => {
		return (
			<ViewWeb className="balanace-animals-button-container">
				{renderSum(tableItemsCopy)}
				<ViewWeb className="horizontal-padding borderRight">
					<SkioldTouchableOpacity onPress={openDistributeModal}>
						<SkioldImage width="60" height="60" imageData={MoveAnimal} />
					</SkioldTouchableOpacity>
					<WhiteText>{localized('Distribute')}</WhiteText>
				</ViewWeb>
				<ViewWeb className="horizontal-padding">
					<SkioldTouchableOpacity onPress={openDepartModal}>
						<SkioldImage width="60" height="60" imageData={DepartureAnimal} />
					</SkioldTouchableOpacity>
					<WhiteText>{localized('sold')}</WhiteText>
				</ViewWeb>
			</ViewWeb>
		);
	};

	const closeEditEventModal = () => {
		setEventToUse(undefined);

		//dispatch(getGrowthPigsEventsByDates(fromDate, toDate));
		// dispatch(getBalanceWeanedPigsData(siteId, fromDate, toDate));
		//fetch(fromDate, toDate);
	};

	const closeDepartModal = () => {
		setDepartModal(false);
		//	fetch(fromDate, toDate);
	};

	const openDepartModal = () => {
		if (weanedPenId) {
			setDepartModal(true);
		} else {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_NO_DEFAULT_WEANING_PEN));
		}
	};

	const openDistributeModal = () => {
		if (weanedPenId) {
			setShowDistributeModal(true);
		} else {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_NO_DEFAULT_WEANING_PEN));
		}
	};

	const [eventToUse, setEventToUse] = useState<GrowthPigsEvent | undefined>(undefined);
	const [eventsToUse, setEventsToUse] = useState<GrowthPigsEvent[] | undefined>(undefined);
	const openEditModal = useCallback(
		(event: BalanceWeanedPigEventOverviewTableData) => {
			if (event.growthPigEventType === GrowthPigEventType.PigletDepartureSoldEvent && event.eventIds.length > 0) {
				const eventId = event.eventIds[0];
				const gpe = gpes.find(gpe => gpe.id === eventId);
				setEventToUse(gpe);
			} else if (event.growthPigEventType === GrowthPigEventType.PigletMovedEvent && event.eventIds.length > 0) {
				const events = gpes.filter(gpe => gpe.id && event.eventIds.includes(gpe.id));
				setEventsToUse(events);
			}
		},
		[setEventToUse, setEventsToUse, gpes],
	);

	const onFilterChanged = useCallback(
		(data: BalanceWeanedPigEvent[]) => {
			setTableItemsCopy(data);
		},
		[setTableItemsCopy],
	);
	const departModalOpen =
		showDepartModal ||
		(eventToUse !== undefined && eventToUse.growthPigEventType === GrowthPigEventType.PigletDepartureSoldEvent);
	const closeDistributeModal = useCallback(() => {
		setShowDistributeModal(false);
		setEventsToUse(undefined);
	}, [setShowDistributeModal, setEventsToUse]);

	return (
		<ViewWeb className="balance-weaned-pigs-overview">
			<Heading text={localized('BalanceWeanedPigs')}></Heading>
			<ViewWeb className="header-container">
				<ViewWeb className="left-header">
					<SkioldFetch
						isControlled={true}
						fetchData={fetch}
						toDateChange={setToDate}
						fromDateChange={setFromDate}
						fromDate={fromDate}
						toDate={toDate}
						showSpinner={isFetchingData}
					/>
				</ViewWeb>
				{currentPigsOnStable !== undefined && (
					<WhiteText className="weanedOnStable-container">
						{localized('WeanedOnStable') + ': '}
						{currentPigsOnStable}
					</WhiteText>
				)}
				{renderButtons()}
			</ViewWeb>
			<ViewWeb className="table-container">
				<BalanceWeanedPigTable
					onFiltersChanged={onFilterChanged}
					data={dataMemo}
					openEditModal={openEditModal}
				/>
			</ViewWeb>

			{departModalOpen && (
				<RegisterBalanceWeanedPigDepartModal
					growthPigEvent={eventToUse}
					isOpen={departModalOpen}
					closeModal={
						eventToUse !== undefined &&
						eventToUse.growthPigEventType === GrowthPigEventType.PigletDepartureSoldEvent
							? closeEditEventModal
							: closeDepartModal
					}
				/>
			)}
			{(showDistributeModal || eventsToUse) && (
				<DistributeWeanedPigsModal
					eventsToUse={eventsToUse}
					isOpen={showDistributeModal || eventsToUse !== undefined}
					closeModal={closeDistributeModal}
				/>
			)}
		</ViewWeb>
	);
});

const renderSum = memoizeOne((data: BalanceWeanedPigEvent[]) => {
	const sumWeaned = data
		.filter(gpe => gpe.growthPigEventType === GrowthPigEventType.PigletWeanedEvent)
		.reduce((a, gpe) => a + (gpe.totalAmount ? gpe.totalAmount : 0), 0);
	const sumMoved = data
		.filter(a => a.growthPigEventType === GrowthPigEventType.PigletMovedEvent)
		.reduce((a, gpe) => a + (gpe.totalAmount ? gpe.totalAmount : 0), 0);
	const sumSold = data
		.filter(a => a.growthPigEventType === GrowthPigEventType.PigletDepartureSoldEvent)
		.reduce((a, gpe) => a + (gpe.totalAmount ? gpe.totalAmount : 0), 0);
	return (
		<ViewWeb className="flexDirectionRow150 borderRight">
			<ViewWeb className="flexDirectionRowMarginRightTen">
				<WhiteText>{localized('PigletWeanedEvent')}:</WhiteText>
				<WhiteText>{sumWeaned}</WhiteText>
			</ViewWeb>
			<ViewWeb className="flexDirectionRowMarginRightTen">
				<WhiteText>{localized('ToDistributed')}:</WhiteText>
				<WhiteText>{sumMoved}</WhiteText>
			</ViewWeb>
			<ViewWeb className="flexDirectionRowMarginRightTen">
				<WhiteText>{localized('sold')}:</WhiteText>
				<WhiteText>{sumSold}</WhiteText>
			</ViewWeb>
			<ViewWeb className="flexDirectionRowMarginRightTen">
				<WhiteText>{localized('difference')}:</WhiteText>
				<WhiteText>{sumWeaned - sumSold - sumMoved}</WhiteText>
			</ViewWeb>
		</ViewWeb>
	);
});
