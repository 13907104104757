import React from 'react';
import { SkioldInput } from '../skiold-input/skiold-input';
import './skiold-text-field.scss';

interface TextPropsFromParent {
	onChangeText: (newText: string) => void;
	label: string;
	value?: string;
	className?: string;
	style?: any;
	theme?: 'light' | 'dark';
	suffix?: string;
	disabled?: boolean;
	multiline?: boolean;
	useAsInterger?: boolean;
	autoFocus?: boolean;
}

// Component representing a Skiold Digital textfield throughout the app/website
export class SkioldTextField extends React.PureComponent<TextPropsFromParent> {
	public static defaultProps: Partial<TextPropsFromParent> = {
		theme: 'light',
	};

	public input: any; //Any because of missing .focus on TextField typings
	public componentDidMount() {
		if (this.props.theme === 'light') {
		} else if (this.props.theme === 'dark') {
			//Use default colors
		}
	}

	public focus() {
		if (this.input) {
			this.input.focus();
		}
	}
	private setRef = ref => {
		this.input = ref;
	};

	public render() {
		return (
			<span className={`skiold-text-field p-float-label `}>
				<SkioldInput
					autoFocus={true}
					ref={this.setRef}
					id="float-input"
					style={this.props.style}
					text={this.props.value}
					onChangeText={this.textChanged}
					className={
						(this.props.className ? this.props.className + ' ' : '') + (this.props.value ? 'p-filled' : '')
					}
					editable={!this.props.disabled}
					theme={this.props.theme}
				/>
				<label htmlFor="float-input">{this.props.label}</label>
			</span>
		);
	}
	private regexToUse = /[^\d]/g;
	private textChanged = (newText: string) => {
		if (this.props.useAsInterger) {
			let textstring = newText.replace(this.regexToUse, '');
			this.props.onChangeText(textstring);
		} else {
			this.props.onChangeText(newText);
		}
	};
}
