import { Gender, IFarrowing, IStemAnimal } from 'shared/api/api';
import { localized } from 'shared/state/i18n/i18n';

export const setFarrowingData = (farrowing: IFarrowing, youngAnimalsToSave: IStemAnimal[] | undefined) => {
	return youngAnimalsToSave
		? youngAnimalsToSave?.map(
				y =>
					({
						...y,
						birthDate: farrowing.date,
						youngAnimalEntranceDate: farrowing.date,
						fromPregnancyId: farrowing.pregnancyId,
						earNotch: farrowing.earNotch,
					} as IStemAnimal),
		  )
		: [];
};

export const checkAssignIdAlive = async (
	alreadySave: IStemAnimal[] | undefined,
	toSave: IStemAnimal[] | undefined,
	numFemaleAlive: number | undefined,
	numMaleAlive: number | undefined,
	showAlert: (msg: string) => Promise<boolean>,
) => {
	if (alreadySave) {
		let femaleList = alreadySave.filter(e => e.gender === Gender.Female);
		if (toSave) {
			femaleList = femaleList.concat(toSave.filter(e => e.gender === Gender.Female));
		}
		if (femaleList.length > 0 && numFemaleAlive !== femaleList.length) {
			return await showAlert(localized('ValidateAssignIdCount'));
		}
		let maleList = alreadySave.filter(e => e.gender === Gender.Male);
		if (toSave) {
			maleList = maleList.concat(toSave.filter(e => e.gender === Gender.Male));
		}
		if (maleList.length > 0 && numMaleAlive !== maleList.length) {
			return await showAlert(localized('ValidateAssignIdCount'));
		}
	}
};
