import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IModuleAuth, SiteAuthorizationUpdate } from 'shared/api/api';
import { LoadSiteAuthorizations, UpdateSiteAuthorization } from 'shared/state/ducks/authorizations/operations';
import { ModuleChanged } from 'shared/state/ducks/profile/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import PageContainer from 'web/view/components/page-container/page-container';
import { SkioldCheckbox } from 'web/view/components/skiold-components/skiold-checkbox/skiold-checkbox';
import { SkioldTouchableOpacity } from 'web/view/components/skiold-components/skiold-touchable-opacity';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import './auth-mapper.scss';
import { SkioldImage } from '../../components/utils/svg/skiold-image';
import PenIcon from 'shared/assets/src-assets/png/pen_icon.png';
import { SkioldModal } from '../../components/skiold-components/skiold-modal/skiold-modal';
import { EditUser } from 'web/view/components/edit-user/edit-user';
import { nucleusManagementLicences } from '../nucleus-management/settings/nucleus-management-helper';
import { ModulesIds } from 'shared/constants';

const mapStateToProps = (state: WebAppState) => {
	return {
		moduleAuths: state.authorization.auth!.moduleAuthorizations!,
		userProfiles: state.authorization.auth.userProfiles!,
		siteId: state.profile.active!.siteId!,
		activeSite: state.site.site,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
	loadAutorizations: (site: string) => LoadSiteAuthorizations(site)(dispatch),
	moduleChanged: (update: SiteAuthorizationUpdate) => ModuleChanged(update)(dispatch),
	updateAuthorization: (siteId: string, update: SiteAuthorizationUpdate) =>
		UpdateSiteAuthorization(siteId, update)(dispatch),
});

interface State {
	editModalOpen: boolean;
	userIdToEdit: string;
}

type AuthMapperProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class AuthMapper extends React.PureComponent<AuthMapperProps, State> {
	constructor(props: AuthMapperProps) {
		super(props);
		this.state = {
			editModalOpen: false,
			userIdToEdit: '',
		};
	}

	public componentDidMount() {
		if (this.props.siteId) {
			this.props.loadAutorizations(this.props.siteId);
		}
	}

	public render() {
		if (!this.props.siteId) {
			return (
				<PageContainer>
					<ViewWeb className="error-container">
						<TextWeb className="error-text">
							<h1>ERROR:</h1>
							The user profile is not associated with a site.
						</TextWeb>
					</ViewWeb>
				</PageContainer>
			);
		}

		if (!this.props.moduleAuths) {
			return <ViewWeb />;
		}

		return (
			<ViewWeb>
				<ViewWeb className="auth-mapper-container items">
					<ViewWeb className="table-header-container">
						<ViewWeb className="row-container">
							<ViewWeb className="row-profile-name-container first"></ViewWeb>
							{this.props.userProfiles.map((profile, index) => (
								<ViewWeb className="row-profile-name-container" key={index}>
									<TextWeb>{profile.name ? profile.name : profile.adUserID}</TextWeb>
									{profile.id && (
										<SkioldTouchableOpacity onPress={this.startEdit(profile.id)}>
											<SkioldImage width={25} height={25} imageData={PenIcon} />
										</SkioldTouchableOpacity>
									)}
								</ViewWeb>
							))}
						</ViewWeb>
					</ViewWeb>

					{this.props.moduleAuths
						.filter(m => m.module?.id !== ModulesIds.NucleusManagement)
						.map((item, index) => (
							<ViewWeb
								key={index}
								className={`row-item-outer-container ${
									index % 2 === 0 ? 'white-background' : 'light-grey-background'
								}`}
							>
								<ViewWeb className="row-item-inner-container">
									<TextWeb className="row-item first paddingLeftFive">
										{localized(item.module!.name as string)}
									</TextWeb>
									{item.authorizations!.map((auth, userIndex) => (
										<ViewWeb className="row-checkbox-container" key={userIndex}>
											<TextWeb>
												<SkioldCheckbox
													isChecked={auth}
													onClick={checked =>
														this.updateModuleAuth(
															checked,
															this.props.userProfiles[userIndex].adUserID!,
															item,
														)
													}
												/>
											</TextWeb>
										</ViewWeb>
									))}
								</ViewWeb>
								{item.processAuthorizations!.map((processAuth, processindex) => {
									if (
										!this.props.activeSite.activeLicences?.includes(processAuth.process?.licenceId!)
									) {
										return null;
									}

									return (
										<ViewWeb key={processindex} className="row-item-inner-container">
											<TextWeb className="row-item first paddingLeftTen">
												- {localized(processAuth.process!.name as any)}
											</TextWeb>
											{processAuth.authorizations!.map((procAuth, userIndex) => (
												<ViewWeb className="row-checkbox-container" key={userIndex}>
													<TextWeb>
														<SkioldCheckbox
															isChecked={procAuth}
															onClick={checked =>
																this.updateProcessAuth(
																	checked,
																	this.props.userProfiles[userIndex].adUserID!,
																	processAuth.process!.id!,
																)
															}
														/>
													</TextWeb>
												</ViewWeb>
											))}
										</ViewWeb>
									);
								})}
							</ViewWeb>
						))}
				</ViewWeb>
				<SkioldModal isOpen={this.state.editModalOpen} close={this.closeModal}>
					<EditUser closeModal={this.closeModal} userId={this.state.userIdToEdit} />
				</SkioldModal>
			</ViewWeb>
		);
	}

	private updateModuleAuth(checked: boolean, userId: string, item: IModuleAuth) {
		const update = new SiteAuthorizationUpdate({
			userId,
			processIds: item.processAuthorizations!.map(a => a.process!.id!),
			moduleId: item.module!.id,
			authorized: checked,
		});
		this.props.updateAuthorization(this.props.siteId!, update);
		this.props.moduleChanged(update);
	}

	private updateProcessAuth(checked: boolean, userId: string, processId: string) {
		const update = new SiteAuthorizationUpdate({
			userId,
			processIds: [processId],
			authorized: checked,
		});
		this.props.updateAuthorization(this.props.siteId!, update);
		this.props.moduleChanged(update);
	}

	private startEdit = (userId: string) => () => {
		this.setState({ editModalOpen: true, userIdToEdit: userId });
	};

	private closeModal = (userId?: string) => {
		this.setState({ editModalOpen: false, userIdToEdit: '' });
		if (userId) {
			const update = new SiteAuthorizationUpdate({
				userId,
				processIds: [],
				authorized: true,
			});
			this.props.updateAuthorization(this.props.siteId, update);
			this.props.moduleChanged(update);
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthMapper);
