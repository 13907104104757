import React from 'react';
import { AnalysisResultYoungFemaleAge, IAnalysisResultYoungFemaleAgeSetting } from 'shared/api/api';
import { localized } from 'shared/state/i18n/i18n';
import { SkioldIntegerInput } from 'web/view/components/skiold-components/skiold-integer-input/skiold-integer-input';
import SkioldTableGrid from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { deepCopy } from 'shared/helpers/general-helpers';

interface PropsFromParent {
	setting: IAnalysisResultYoungFemaleAgeSetting;
	onSettingChanged: (setting?: IAnalysisResultYoungFemaleAgeSetting) => void;
}

interface State {
	columns: any[];
	setting: IAnalysisResultYoungFemaleAgeSetting;
	columnExt: any[];
}

export class AnalysisResultYoungFemaleAgeDefinitionsTable extends React.PureComponent<PropsFromParent, State> {
	public static defaultProps: Partial<PropsFromParent> = {};

	constructor(props: PropsFromParent) {
		super(props);
		this.state = {
			columns: this.generateColumns(),
			setting: props.setting,
			columnExt: this.generateColumnExt(),
		};
	}

	public render() {
		const data = this.generateData(this.state.setting);

		return (
			<ViewWeb className="p-report-period-table">
				<SkioldTableGrid
					ColumnExtensions={this.state.columnExt}
					columns={this.state.columns}
					data={data}
					sortable={false}
					filterable={false}
				/>
			</ViewWeb>
		);
	}

	private generateData = (setting: IAnalysisResultYoungFemaleAgeSetting) => {
		const dataItems: AnalysisResultYoungFemaleAgeDefinitionsTableRow[] = [];
		let fromItems: AnalysisResultYoungFemaleAgeDefinitionsTableItem[] = [];
		let toItems: AnalysisResultYoungFemaleAgeDefinitionsTableItem[] = [];
		if (setting && setting.agePeriods) {
			const periods = setting.agePeriods.map(period => {
				const from = new AnalysisResultYoungFemaleAgeDefinitionsTableItem(
					localized('from'),
					period.fromAge,
					true,
					period.agePeriod
				);
				const to = new AnalysisResultYoungFemaleAgeDefinitionsTableItem(
					localized('to'),
					period.toAge,
					false,
					period.agePeriod
				);
				return { from, to };
			});
			fromItems = periods.map(period => period.from);
			toItems = periods.map(period => period.to);
		}

		while (fromItems.length < 4) {
			fromItems.push(
				new AnalysisResultYoungFemaleAgeDefinitionsTableItem(
					localized('from'),
					undefined,
					true,
					AnalysisResultYoungFemaleAge[AnalysisResultYoungFemaleAge[fromItems.length - 1]]
				)
			);
			toItems.push(
				new AnalysisResultYoungFemaleAgeDefinitionsTableItem(
					localized('to'),
					undefined,
					false,
					AnalysisResultYoungFemaleAge[AnalysisResultYoungFemaleAge[fromItems.length - 1]]
				)
			);
		}
		dataItems.push(new AnalysisResultYoungFemaleAgeDefinitionsTableRow(fromItems));
		dataItems.push(new AnalysisResultYoungFemaleAgeDefinitionsTableRow(toItems));

		return dataItems;
	};

	private generateColumnExt() {
		const columnExt = Object.keys(AnalysisResultYoungFemaleAge).map(key => {
			return {
				columnName: key,
				width: 150,
			};
		});

		return columnExt;
	}

	private generateColumns() {
		const columns = Object.keys(AnalysisResultYoungFemaleAge).map((key, index) => {
			const getCellValue = (row: AnalysisResultYoungFemaleAgeDefinitionsTableRow) =>
				this.generateNumberPicker(row.items![index]);
			return {
				name: key, //sow team
				title: `${localized('AgePeriod')} ` + (index + 1),
				sortable: false,
				filterable: false,
				getCellValue: getCellValue,
			};
		});

		return columns;
	}

	private dataChanged = (number?: number, item?: AnalysisResultYoungFemaleAgeDefinitionsTableItem) => {
		if (item) {
			const settings = deepCopy(this.state.setting);
			this.setState(prevState => {
				const periods = [...prevState.setting.agePeriods!];
				const periodsIndex = periods.findIndex(p => p.agePeriod === item.ageField);
				if (item.isFrom) {
					periods[periodsIndex].fromAge = number;
				} else {
					periods[periodsIndex].toAge = number;
				}
				settings.agePeriods = periods;
				return { ...prevState, settings: settings };
			});
		}
	};

	private generateNumberPicker = (item: AnalysisResultYoungFemaleAgeDefinitionsTableItem) => {
		return (
			<ViewWeb className="period-item-container">
				<TextWeb className="period-item-text">{item.name}</TextWeb>
				<SkioldIntegerInput
					className="period-item-number"
					text={item.age}
					itemFromParent={item}
					onBlur={this.OnNumberBlur}
					onChangeNumber={this.dataChanged}
				/>
			</ViewWeb>
		);
	};
	private OnNumberBlur = (number?: number, item?: AnalysisResultYoungFemaleAgeDefinitionsTableItem) => {
		if ( item) {
			const settings = deepCopy(this.state.setting);
			const periods = [...settings.agePeriods!];
			const periodsIndex = periods.findIndex(p => p.agePeriod === item.ageField);
			if (item.isFrom) {
				if (periodsIndex - 1 !== -1 && periods[periodsIndex].fromAge !== undefined) {
					periods[periodsIndex - 1].toAge = periods[periodsIndex].fromAge! - 1;
				}
			} else {
				if (periodsIndex + 1 < periods.length && periods[periodsIndex].toAge !== undefined) {
					periods[periodsIndex + 1].fromAge = periods[periodsIndex].toAge! + 1;
				}
			}
			settings.agePeriods = periods;
			this.setState({ setting: settings });
			this.props.onSettingChanged(settings);
		}
	};
}

export class AnalysisResultYoungFemaleAgeDefinitionsTableRow {
	public items: AnalysisResultYoungFemaleAgeDefinitionsTableItem[] | undefined;
	constructor(items) {
		this.items = items;
	}
}

export class AnalysisResultYoungFemaleAgeDefinitionsTableItem {
	public name: string;
	public age: number;
	public ageField?: AnalysisResultYoungFemaleAge;
	public isFrom: boolean;

	constructor(name, age, isFrom, ageField?: AnalysisResultYoungFemaleAge) {
		this.name = name;
		this.age = age;
		this.ageField = ageField;
		this.isFrom = isFrom;
	}
}
