import { IFeeding } from "shared/api/api";
export const SAVE_FEEDING = 'SAVE_FEEDING';
export const DELETE_FEEDING = 'DELETE_FEEDING';
export const GET_FEEDINGS = 'GET_FEEDINGS';
export const GET_FEEDINGS_FOR_FEEDINGS_LOG = 'GET_FEEDINGS_FOR_FEEDINGS_LOG';

export interface FeedingState {
    feedings: IFeeding[];
    feedingSpinner: boolean; 
}

