import { SowListConstants } from "../../stem-animal/animal-lists/table-constants";

export class LocationTableConstants {
    public static editWidth = 77;

    public static sectionAddWidth = 82;
    public static penAddWidth = 60;

    public static buildingNumWidth = 62;
    public static buildingNameWidth = 140;
    public static productionWidth = 130;

    public static sectionNumWidth = 80;
    public static sectionTypeWidth = 160;

    public static penWidth = 57;
    public static capacityWidth = 90;
    public static unitsWidth = 83;

    public static buildingOrderWidth = 80;
    public static sectionOrderWidth = 72;
    public static penOrderWidth = 45;

    public static deleteWidth = 68;

    public static totalWidth =
        LocationTableConstants.editWidth +
        LocationTableConstants.sectionAddWidth +
        LocationTableConstants.penAddWidth +
        LocationTableConstants.buildingNumWidth +
        LocationTableConstants.buildingNameWidth +
        LocationTableConstants.productionWidth +
        LocationTableConstants.sectionNumWidth +
        LocationTableConstants.sectionTypeWidth +
        LocationTableConstants.penWidth +
        LocationTableConstants.capacityWidth +
        LocationTableConstants.unitsWidth +
        LocationTableConstants.buildingOrderWidth +
        LocationTableConstants.sectionOrderWidth +
        LocationTableConstants.penOrderWidth +
        SowListConstants.iconWidth +
        20;
}
