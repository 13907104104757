import React, { FC, useEffect, useState } from 'react';
import { localized, localizedDynamic } from 'shared/state/i18n/i18n';
import { SkioldFormDropdown } from 'web/view/components/skiold-components/skiold-dropdown/skiold-form-dropdown';
import { SkioldFormsWrapper } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';

import { Option } from 'react-dropdown';
import { DistriwinFeedUnitType, Equipment, IDashboardSettingItem, IGpeDashboardSettingItem } from 'shared/api/api';
import { SkioldFormIntegerInput } from 'web/view/components/skiold-components/skiold-integer-input/skiold-form-integer-input';
import { SkioldFormTextField } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-form-text';
import { Building, Section, Pen } from 'shared/state/ducks/locations';
import { useSelector } from 'react-redux';
import { SharedAppState } from 'shared/state/store.shared';

interface Props {
	item: IGpeDashboardSettingItem;
	onChange: (item) => void;
	buildings: Building[];
}

export const GpeDashboardSettings: FC<Props> = React.memo(({ item, onChange, buildings }) => {
	const hasDistriwin = useSelector((state: SharedAppState) =>
		state.processEquipments.entities.find(e => e.equipment === Equipment.Distriwin),
	);
	const getFormRows = () => {
		let formRows = new Array<FormRow>();

		formRows.push(getWorkListHeader());
		// TODO: Add after Agro
		// formRows.push(getLocationRow());
		if (hasDistriwin) {
			formRows.push(getSubGoalRow());
		}

		formRows.push(getPredictionRow());
		return formRows;
	};

	const onChangeSubGoal = (value: number | undefined) => {
		onChange({ ...item, subGoal: value });
	};

	const getSubGoalRow = (): FormRow => {
		return {
			name: localized('DashboardSubGoal'),
			component: <SkioldFormIntegerInput text={item.subGoal} onChangeNumber={onChangeSubGoal} />,
		};
	};
	const onChangePrediction = (value: number | undefined) => {
		onChange({ ...item, predictedWeightAtSale: value });
	};

	const getPredictionRow = (): FormRow => {
		return {
			name: localized('DashboardExpectedWeight'),
			component: <SkioldFormIntegerInput text={item.predictedWeightAtSale} onChangeNumber={onChangePrediction} />,
		};
	};

	const getLocationRow = (): FormRow => {
		const building = buildings.find(b => b.id === item.buildingId);
		return {
			name: localized('DashboardExpectedWeight'),
			component: <SkioldFormTextField>{building?.name}</SkioldFormTextField>,
		};
	};

	const getWorkListHeader = (): FormRow => {
		return {
			header: localized('Dashboard'),
		};
	};

	return <SkioldFormsWrapper formRows={getFormRows()} containerClassName="wrapper-container" />;
});
