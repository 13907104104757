import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IAverted, IPregnancyEvent } from 'shared/api/api';
import * as GeneralSettingsOperations from 'shared/state/ducks/general-settings/operations';
import { SaveWeaningEvents } from 'shared/state/ducks/pregnancy-events/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import PageContainer from 'web/view/components/page-container/page-container';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldFormDecimalInput } from 'web/view/components/skiold-components/skiold-decimal-input/skiold-form-decimal-input';
import { SkioldFormTextField } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-form-text';
import { SkioldFormsWrapper } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { Heading } from 'web/view/components/utils/heading';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { WeaningEventListItem } from '../sow-pregnancy-event-list-helper';
import { ShowConfirmAlert } from 'web/view/components/skiold-alert/skiold-alert';
import { RefType } from 'shared/helpers/ref-type';
import { deepCopy2 } from 'shared/helpers/general-helpers';
interface PropsFromParent {
	close?: () => void;
	WeaningEventListItems: WeaningEventListItem[];
}

const mapAppStateToProps = (state: WebAppState) => {
	return {
		generalSettings: state.generalSettings.entity
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	getGeneralSettings: () => GeneralSettingsOperations.GetSyncData()(dispatch),
	saveWeaningEvents: (events: IPregnancyEvent[], previousPregnancies: IPregnancyEvent[]) =>
		SaveWeaningEvents(events, previousPregnancies)(dispatch)
});

interface State {
	totalWeaned: number;
	avgWeight?: number;
	totalWeight?: number;
	pregnancyEvents: Array<IAverted | undefined>;
}

type Props = ReturnType<typeof mapAppStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

export class AllocateWeaningWeight extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		const totalWeaned = props.WeaningEventListItems.reduce((sum, listItem) => {
			if (listItem.pregnancyEvent && listItem.pregnancyEvent.numAlive) {
				return sum + listItem.pregnancyEvent.numAlive;
			} else {
				return sum;
			}
		}, 0);
		const totalWeight = props.WeaningEventListItems.reduce((sum, listItem) => {
			if (listItem.pregnancyEvent && listItem.pregnancyEvent.numAlive && listItem.pregnancyEvent.totalWeight) {
				return sum + listItem.pregnancyEvent.totalWeight;
			} else {
				return sum;
			}
		}, 0);

		this.state = {
			totalWeaned: totalWeaned,
			avgWeight: totalWeaned ? totalWeight / totalWeaned : 0,
			totalWeight: totalWeight,
			pregnancyEvents: props.WeaningEventListItems.map(a => a.pregnancyEvent)
		};
		this.InitialAvgWeight = totalWeaned ? totalWeight / totalWeaned : 0;
	}

	private InitialAvgWeight: number;
	public getFormRows(): any {
		let formRows = new Array<FormRow>();
		formRows.push(this.getTotalWeanedInPeriodRow());
		formRows.push(this.getAvgWeightRow());
		formRows.push(this.getTotalWeightRow());
		return formRows;
	}

	public async componentDidMount() {
		this.props.getGeneralSettings();
	}

	public render() {
		return (
			<PageContainer>
				<ViewWeb className="show-diagnose-select">
					<ViewWeb>
						<Heading text={localized('allocateWeight')} />
						<SkioldFormsWrapper
							formRows={this.getFormRows()}
							containerClassName="forms-wrapper-container"
						/>
					</ViewWeb>

					<ViewWeb className="diagnose-select-buttons-container">
						<SkioldButton title={localized('Close')} onPress={this.CheckIfDataNotSaved} theme="grey" />
						<SkioldButton title={localized('calculate')} onPress={this.calculate} />
						<SkioldButton title={localized('Save')} onPress={this.save} />
					</ViewWeb>
				</ViewWeb>
			</PageContainer>
		);
	}

	private calculate = () => {
		this.setState({
			avgWeight:
				this.state.totalWeaned && this.state.totalWeight ? this.state.totalWeight / this.state.totalWeaned : 0
		});
	};

	private save = async () => {
		let pregnancyEventsToSave: IAverted[] = [];
		const previousPregnancies = deepCopy2(this.state.pregnancyEvents);
		this.state.pregnancyEvents.forEach(pe => {
			if (pe && this.state.avgWeight !== undefined) {
				pe.totalWeight = (pe.numAlive !== undefined ? pe.numAlive : 0) * this.state.avgWeight;
				pregnancyEventsToSave.push(pe);
			}
		});
		await this.props.saveWeaningEvents(pregnancyEventsToSave, previousPregnancies);
		if (this.props.close) {
			this.props.close();
		}
	};

	public CheckIfDataNotSaved = async () => {
		if (this.InitialAvgWeight !== this.state.avgWeight) {
			if (await ShowConfirmAlert(localized('VALIDATION_WARNING_ConfirmWarningDataNotSavedMessage'))) {
				await this.save();
			} else {
				if (this.props.close) {
					this.props.close();
				}
			}
		}else{
			if (this.props.close) {
				this.props.close();
			}
		}
	};

	private getTotalWeanedInPeriodRow(): FormRow {
		return {
			name: localized('totalWeanedInPeriod'),
			component: <SkioldFormTextField>{this.state.totalWeaned}</SkioldFormTextField>
		};
	}

	private getAvgWeightRow(): FormRow {
		return {
			name: localized('avgWeight'),
			component: (
				<SkioldFormTextField>
					{this.state.avgWeight ? Math.round(this.state.avgWeight * 10) / 10 : ''}
				</SkioldFormTextField>
			)
		};
	}

	public totalWeightChanged = (newNumber: number | undefined) => {
		this.setState({ totalWeight: newNumber });
	};
	private getTotalWeightRow(): FormRow {
		return {
			name: localized('totalWeightInPeriod'),
			component: (
				<SkioldFormDecimalInput
					onChangeNumber={this.totalWeightChanged}
					text={this.state.totalWeight ? Math.round(this.state.totalWeight * 10) / 10 : 0}
				/>
			)
		};
	}

	private showErrorMessage(errorMessage: string) {
		alert(errorMessage);
	}
}
export default connect<
	ReturnType<typeof mapAppStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	RefType,
	WebAppState
>(mapAppStateToProps, mapDispatchToProps, null, { forwardRef: true })(AllocateWeaningWeight);
