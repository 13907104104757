import React from 'react';
import DepartureListTable from './departure-work-list-table';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';

export interface DepartureWorkListPropsFromParent {
	refCallback: (ref: any) => void;
}
export class DepartureWorkList extends React.PureComponent<DepartureWorkListPropsFromParent> {
	public render() {
		return <DepartureListTable ref={this.setRef} />;
	}

	private setRef = (m: any) => (m ? this.props.refCallback(m) : {});
}
