import { Option } from 'react-dropdown';
import { Dispatch } from 'redux';
import {
	IMatingBatch,
	IMoveEvent,
	IMoveToFarrowingLocation,
	IPregnancyEvent,
	IStemAnimal,
	LocationType,
	PregnancyValidationType,
	SowState,
} from 'shared/api/api';
import { calculateDays } from 'shared/helpers/general-helpers';
import { getPenBySow, getStemAnimalPen, ILocationModel } from 'shared/helpers/location-helper';
import { memoizeActiveSows, memoizeLocation, memoizeValidationSettingPlan } from 'shared/helpers/memoize-getters/memoize-getters';
import { getCycleDays1 } from 'shared/helpers/pregnancy-helper/generel-pregnancy-helpers';
import { getActiveSows } from 'shared/helpers/stemanimal-helper/stem-animal-input-helper';
import { getState, getState1 } from 'shared/helpers/stemanimal-helper/stemanimal-helper';
import {
	checkLocation,
	checkMatingBatch,
	checkSectionWorkLists,
	getCycleDaysByPregnancyEvents,
	InsertOldDataSelectedSettings,
	setMovelistTableItemData,
	movingListsCheckCycleDaysPregnancyDaysByPregnancyEvents,
} from 'shared/helpers/work-list-helpers/move-to-helpers/moving-lists-helper';
import { GetLocations } from 'shared/state/ducks/locations/operations';
import * as matingBatchOperation from 'shared/state/ducks/mating-batch/operations';
import * as moveEventOperation from 'shared/state/ducks/move-events/operations';
import { SaveMoveEvent } from 'shared/state/ducks/move-events/operations';
import * as pregnancyOperation from 'shared/state/ducks/pregnancy-events/operations';
import * as stemAnimalOperation from 'shared/state/ducks/stem-animals/operations';
import * as validationSetupOperation from 'shared/state/ducks/validation-setup/operations';
import { GetSyncData } from 'shared/state/ducks/work-list-settings/operations';
import { SharedAppState } from 'shared/state/store.shared';
import { MovingListTable } from './move-to-data-model';

export interface MoveToFarrowingListState {
	selectedBatch: Option;
	matingBatchesOptions: Option[];
	locationString: string | undefined;
	moveToSetup: IMoveToFarrowingLocation;
	locations: ILocationModel | undefined;
	selectedHeaderSectionUsesPens: boolean;
	toSectionId?: string;
	toPenId?: string;
	fromSectionId?: string;
	fromPenId?: string;
	fromLocation: ILocationModel | undefined;
	fromSectionUsesPens: boolean;
	moveListData: MovingListTable[];
	moveListInitData: MovingListTable[];
}

export const moveToFarrowingListMapStateToProps = (state: SharedAppState) => {
	return {
		workListSettings: state.workListSettings.entities,
		activeSows: memoizeActiveSows(state.stemAnimals.entities),
		pregnancyEvets: state.pregnancyEvents.entities,
		matingBatches: state.matingBatches.entities,
		// feedCurves: state.site.site.feedCurves,
		siteId: state.profile.active!.siteId!,
		moveEvents: state.moveEvents.entities,
		locations: memoizeLocation(
			state.locations.buildings,
			state.locations.sections,
			state.locations.pens,
			state.locations.valves,
		),
		validationSettingPlan: memoizeValidationSettingPlan(
			state.validationSetup.entity,
			PregnancyValidationType.MatingToFarrowing,
		),
	};
};

export const moveToFarrowingListMapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	saveMoveEvent: (moveEvent: IMoveEvent) => SaveMoveEvent(moveEvent)(dispatch),
	getWorkListSettingsBySiteId: () => GetSyncData()(dispatch),
	pregnancyEventGetSyncData: () => pregnancyOperation.GetSyncData()(dispatch),
	stemAnimalGetSyncData: () => stemAnimalOperation.GetSyncData()(dispatch),
	matingBatchGetSyncData: () => matingBatchOperation.GetSyncData()(dispatch),
	validationSetupGetSyncData: () => validationSetupOperation.GetSyncData()(dispatch),
	moveEventGetSyncData: () => moveEventOperation.GetSyncData()(dispatch),
	getLocations: () => GetLocations()(dispatch),
});
export type MoveToFarrowingListProps = ReturnType<typeof moveToFarrowingListMapStateToProps> &
	ReturnType<typeof moveToFarrowingListMapDispatchToProps>;

export function getDataByMatingBatchMoveToFarrowingList(
	batchId: string | undefined,
	moveEvents: IMoveEvent[],
	locations: ILocationModel,
	matingBatches: IMatingBatch[],
	pregEvents: { [key: string]: IPregnancyEvent[] },
	moveListData: MovingListTable[],
	toSectionId: string | undefined,
	toPenId: string | undefined,
	validateSettingToSubtract: number | undefined,
) {
	let workListData = [] as MovingListTable[];
	let activeSows = getActiveSows();
	const batch = matingBatches.find(matingBatch => matingBatch.id === batchId);
	if (batch) {
		for (let sow of activeSows) {
			let state = getState(sow.id!);

			if (
				!sow ||
				(state !== SowState.Pregnant && state !== SowState.PregnantAndNursing) ||
				checkLocation(getStemAnimalPen(sow, moveEvents, locations.pens), locations, LocationType.Farrowing)
			) {
				continue;
			}
			let pregnancyEvents = checkMatingBatch(pregEvents[sow.id!], batch);
			if (pregnancyEvents === undefined || !pregnancyEvents.lastestPregEvent) {
				continue;
			}
			let moveToWorkListItem = new MovingListTable();
			const cycleDays = calculateDays(pregnancyEvents.lastestPregEvent.date!, new Date());
			const negativeCycleDays = validateSettingToSubtract && cycleDays
				? cycleDays - validateSettingToSubtract : 0
			setMovelistTableItemData(
				sow,
				moveToWorkListItem,
				cycleDays, negativeCycleDays
			);
			let { usesPens } = checkSectionWorkLists(toSectionId, toPenId);
			moveToWorkListItem.sectionId = toSectionId;
			moveToWorkListItem.penId = toPenId;
			moveToWorkListItem.usesPens = usesPens;
			workListData.push(moveToWorkListItem);
		}
	}
	InsertOldDataSelectedSettings(workListData, moveListData);
	return workListData;
}

export function getDataByLocationMoveToFarrowingList(
	pregEvents: { [key: string]: IPregnancyEvent[] },
	moveListData: MovingListTable[],
	penId: string,
	toSectionId: string | undefined,
	toPenId: string | undefined,
	validateSettingToSubtract: number | undefined,
) {
	let ActiveSows = getActiveSows();
	let data: MovingListTable[] = [];

	for (let sow of ActiveSows) {
		let state = getState(sow.id!);
		let pen = getPenBySow(sow);
		if (
			(state !== SowState.Pregnant && state !== SowState.PregnantAndNursing) ||
			pen === undefined ||
			pen.id !== penId
		) {
			continue;
		}
		let moveToWorkListItem = new MovingListTable();
		const cycleDays =getCycleDaysByPregnancyEvents(pregEvents[sow.id!]);
		const negativeCycleDays = validateSettingToSubtract && cycleDays
			? cycleDays - validateSettingToSubtract : 0
		setMovelistTableItemData(sow, moveToWorkListItem,cycleDays,negativeCycleDays );
		let { usesPens } = checkSectionWorkLists(toSectionId, penId);
		moveToWorkListItem.sectionId = toSectionId;
		moveToWorkListItem.penId = toPenId;
		moveToWorkListItem.usesPens = usesPens;
		data.push(moveToWorkListItem);
	}
	InsertOldDataSelectedSettings(data, moveListData);
	return data;
}

export function getDataByPregnancyDaysMoveToFarrowingList(
	moveEvents: IMoveEvent[],
	locations: ILocationModel,
	pregEvents: { [key: string]: IPregnancyEvent[] },
	moveListData: MovingListTable[],
	moveToSetup: IMoveToFarrowingLocation,
	toSectionId: string | undefined,
	toPenId: string | undefined,
	validateSettingToSubtract: number | undefined,
) {
	let ActiveSows = getActiveSows();
	let data: MovingListTable[] = [];
	for (let sow of ActiveSows) {
		if (!sow.id) {
			continue;
		}
		let state = getState(sow.id!);

		if (
			(state !== SowState.Pregnant && state !== SowState.PregnantAndNursing) ||
			checkLocation(getStemAnimalPen(sow, moveEvents, locations.pens), locations, LocationType.Farrowing)
		) {
			continue;
		}

		let cycleDays = movingListsCheckCycleDaysPregnancyDaysByPregnancyEvents(moveToSetup, pregEvents[sow.id]);
		if (cycleDays === undefined) {
			continue;
		}
		let moveToWorkListItem = new MovingListTable();
		const negativeCycleDays = validateSettingToSubtract && cycleDays
			? cycleDays - validateSettingToSubtract : 0
		setMovelistTableItemData(sow, moveToWorkListItem, cycleDays,negativeCycleDays);
		let { usesPens } = checkSectionWorkLists(toSectionId, toPenId);
		moveToWorkListItem.sectionId = toSectionId;
		moveToWorkListItem.penId = toPenId;
		moveToWorkListItem.usesPens = usesPens;
		data.push(moveToWorkListItem);
	}
	InsertOldDataSelectedSettings(data, moveListData);
	return data;
}

export function getMoveToFarrowingItemByAnimal(
	sow: IStemAnimal,
	pregEvents: IPregnancyEvent[],
	moveEvents: IMoveEvent[],
	locations: ILocationModel,
	validateSettingToSubtract: number | undefined,
) {
	let state = getState1(sow.id!, pregEvents);
	if (
		(state !== SowState.Pregnant && state !== SowState.PregnantAndNursing) ||
		checkLocation(getStemAnimalPen(sow, moveEvents, locations.pens), locations, LocationType.Farrowing)
	) {
		return undefined;
	}
	let cycleDays = getCycleDays1(sow.id!, pregEvents);
	const negativeCycleDays = validateSettingToSubtract && cycleDays
			? cycleDays - validateSettingToSubtract : 0
	let moveToWorkListItem = new MovingListTable();
	setMovelistTableItemData(sow, moveToWorkListItem, cycleDays,negativeCycleDays);
	return moveToWorkListItem;
}
