import { RetryAdjustFeedItemApp } from '.';
import { CellColorStyle, SkioldOneClient, ValveFieldUpdateDto, ValveFieldUpdateStatus } from 'shared/api/api';
import { SkioldDigitalApiBaseUrl } from 'shared/constants';
import { getDateString } from 'shared/helpers/date-helpers';

export async function pollValveUpdate(id: string, attempt: number): Promise<ValveFieldUpdateDto> {
	let valveUpdateStatus = await new SkioldOneClient(SkioldDigitalApiBaseUrl).valveUpdates_GetValveFieldUpdate(id);
	if (
		(valveUpdateStatus.status === ValveFieldUpdateStatus.RetryInProgress ||
			valveUpdateStatus.status === ValveFieldUpdateStatus.Created) &&
		attempt <= 24
	) {
		let attempts = attempt + 1;
		await new Promise(resolve => {
			setTimeout(() => resolve('result'), 5000);
		});
		return pollValveUpdate(id, attempts);
	} else {
		return valveUpdateStatus;
	}
}

export function HandleTableData(
	data: ValveFieldUpdateDto[],
	fieldValue:
		| 'pigCount'
		| 'sowCycle'
		| 'penDays'
		| 'fixedValue'
		| 'variableModulation'
		| 'variableModulationDays'
		| 'weight'
		| 'pigletCount',
	retryAdjustFeedItemsToBeDeletedItems: string[],
	retryAdjustFeed: RetryAdjustFeedItemApp[],
) {
	const firstAttemptedOnDate = findLastestEventDate(data);
	let dataToBeDeleted = findDataToBeDeleted(data, firstAttemptedOnDate);
	dataToBeDeleted.forEach(toBeDeleted => {
		retryAdjustFeedItemsToBeDeletedItems.push(toBeDeleted.id!);
	});
	let { index, d } = getLastestUpdate(data, firstAttemptedOnDate, retryAdjustFeed);
	if (index !== -1) {
		if (fieldValue !== 'sowCycle') {
			retryAdjustFeed[index][fieldValue] = d!.value;
		} else {
			retryAdjustFeed[index][fieldValue] = convertSowCycle(d!.value);
		}
		if (retryAdjustFeed[index].date! < d!.firstAttemptedOn!) {
			retryAdjustFeed[index].date = d!.firstAttemptedOn!;
		}
		if (!retryAdjustFeed[index].ids.includes(d!.id!)) {
			retryAdjustFeed[index].ids.push(d!.id!);
		}

		return retryAdjustFeed;
	} else {
		let retryItemData: RetryAdjustFeedItemApp = {
			location: d!.location!,
			building: d!.building,
			section: d!.section,
			pens: d!.pens,
			buildingOrder: d!.buildingOrder,
			sectionOrder: d!.sectionOrder,
			penOrder: d!.penOrder,
			valve: d!.valve,
			date: d!.firstAttemptedOn,
			status: false,
			shouldBeCommited: false,
			showSpinner: false,
			[fieldValue]: d!.value,
			ids: [d!.id!],
			cellColorStyle: undefined!,
		};
		return retryAdjustFeed.push(retryItemData);
	}
}

export function convertSowCycle(sowCycle: any) {
	switch (sowCycle) {
		case '1':
			return 'Weaning';
		case '2':
			return 'Gestation';
		case '3':
			return 'Maternity';
		default:
			return '';
	}
}

export function getLastestUpdate(
	valveFieldUpdateDto: ValveFieldUpdateDto[],
	firstAttemptedOnDate: Date,
	retryAdjustFeed: RetryAdjustFeedItemApp[],
) {
	let d = valveFieldUpdateDto.find(k => k.firstAttemptedOn!.toString() === firstAttemptedOnDate.toString());
	let index = retryAdjustFeed.findIndex(
		raf => d!.location === raf.location && getDateString(d!.firstAttemptedOn) === getDateString(raf.date),
	);
	return { index, d };
}

export function findDataToBeDeleted(valveFieldUpdateDto: ValveFieldUpdateDto[], firstAttemptedOnDate: Date) {
	return valveFieldUpdateDto.filter(k => k.firstAttemptedOn!.toString() !== firstAttemptedOnDate.toString());
}

export function findLastestEventDate(valveFieldUpdateDto: ValveFieldUpdateDto[]) {
	return new Date(
		Math.max.apply(
			null,
			valveFieldUpdateDto.map(e => {
				return new Date(e.firstAttemptedOn!).getTime();
			}),
		),
	);
}

export async function acknowledgeSucceededFields(valveFieldUpdateDto: ValveFieldUpdateDto[]) {
	let idsToAcknowledge: string[] = [];
	valveFieldUpdateDto.forEach(a => {
		idsToAcknowledge.push(a.id!);
	});
	await new SkioldOneClient(SkioldDigitalApiBaseUrl).valveUpdates_AcknowledgeValveFieldUpdates(idsToAcknowledge);
}

export function removeSucceededFields(
	valveFieldUpdateDto: ValveFieldUpdateDto[],
	reAdjustFeedItem: RetryAdjustFeedItemApp,
) {
	valveFieldUpdateDto.forEach(element => {
		switch (element.fieldName) {
			case 'FixedModulation':
				reAdjustFeedItem.fixedValue = undefined;
				break;
			case 'PenDays':
				reAdjustFeedItem.penDays = undefined;
				break;
			case 'PigCount':
				reAdjustFeedItem.pigCount = undefined;
				break;
			case 'SowCycle':
				reAdjustFeedItem.sowCycle = undefined;
				break;
			case 'VariableModulation':
				reAdjustFeedItem.variableModulation = undefined;
				break;
			case 'VariableModulationDays':
				reAdjustFeedItem.variableModulationDays = undefined;
				break;
			case 'FeedingCurve':
				reAdjustFeedItem.feedingCurve = undefined;
				break;
			default:
				break;
		}
		reAdjustFeedItem.ids = reAdjustFeedItem.ids.filter(a => a !== element.id);
	});
	return reAdjustFeedItem;
}
