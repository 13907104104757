import React from 'react';
import { Option } from 'react-dropdown';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
	IMoveToMatingLocation,
	IWorkListSetting,
	MoveToLocationTypes,
	MoveToMatingLocation,
	WorkListType,
} from 'shared/api/api';
import { deepCopy } from 'shared/helpers/general-helpers';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { GetLocations } from 'shared/state/ducks/locations/operations';
import { SaveWorkListSetting } from 'shared/state/ducks/work-list-settings/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { SkioldButton } from '../../skiold-components/skiold-button/skiold-button';
import { SkioldFormDropdown } from '../../skiold-components/skiold-dropdown/skiold-form-dropdown';
import { SkioldFormsWrapper } from '../../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from '../../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { SkioldFormIntegerInput } from '../../skiold-components/skiold-integer-input/skiold-form-integer-input';
import { getAvailablityRow, getSkioldInputRow } from '../work-list-helper';
import '../list-setup.scss';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { showAlert } from '../../skiold-alert/skiold-alert';

interface PropsFromParent {
	workListType: WorkListType;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		workListSettings: state.workListSettings.entities,
		siteId: state.profile.active!.siteId,
		//   feedCurves: state.site.site.feedCurves,
		locations: state.locations,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	saveWorkListSetting: (movetoPregnancySetup: IMoveToMatingLocation) =>
		SaveWorkListSetting(movetoPregnancySetup)(dispatch),
	getLocations: () => GetLocations()(dispatch),
});

export interface MoveToMatingSetupState {
	movetoPregSetup: IMoveToMatingLocation;
	selectedOption: Option;
	options: Option[];
	// selectedFeedCurve: Option;
	// feedCurves: Option[];
}

type MoveToMatingSetupProps = ReturnType<typeof mapStateToProps> &
	ReturnType<typeof mapDispatchToProps> &
	PropsFromParent;
class MoveToMatingSetup extends React.PureComponent<MoveToMatingSetupProps, MoveToMatingSetupState> {
	constructor(props: MoveToMatingSetupProps) {
		super(props);
		// let feedCurves: Option[] = [];
		// if (this.props.feedCurves) {
		//     feedCurves = this.props.feedCurves.filter(a => a.type === 'sow').map(a => {
		//         return { value: a.curveId!.toString(), label: a.name! };
		//     });
		// }
		this.state = {
			movetoPregSetup: this.getWorkListSetting(props.workListType),
			options: [
				{
					label: localized('moveToMatingWeaningDryDays'),
					value: MoveToLocationTypes.PregnacyDays,
				},
				{
					label: localized('moveToMatingWeaningDate'),
					value: MoveToLocationTypes.Date,
				},
			],
			selectedOption: { label: '', value: '' },
		};
		this.props.getLocations();
	}

	public componentDidMount() {
		if (this.state.movetoPregSetup.selectedType !== undefined) {
			this.setState({
				selectedOption: {
					label: localized(
						this.state.movetoPregSetup.selectedType === MoveToLocationTypes.Date
							? 'moveToMatingWeaningDate'
							: 'moveToMatingWeaningDryDays',
					),
					value: this.state.movetoPregSetup.selectedType,
				},
			});
		}
	}

	public toggleShowOnWeb = () => {
		this.setState(prevState => ({
			movetoPregSetup: { ...prevState.movetoPregSetup, showOnWeb: !this.state.movetoPregSetup.showOnWeb },
		}));
	};

	public toggleShowOnApp = () => {
		this.setState(prevState => ({
			movetoPregSetup: { ...prevState.movetoPregSetup, showOnApp: !this.state.movetoPregSetup.showOnApp },
		}));
	};

	public moveToPregnatSetupChanged(setup: IMoveToMatingLocation) {
		this.setState({
			movetoPregSetup: setup,
		});
	}

	public render() {
		return (
			<ViewWeb className="list-setup">
				<ViewWeb className="view-container">
					<TextWeb className="setup-header">{localized('Setup')}</TextWeb>
					<SkioldFormsWrapper formRows={this.getFormRows()} containerClassName="wrapper-container" />
					{this.renderButtons()}
				</ViewWeb>
			</ViewWeb>
		);
	}

	public ResetDataOnMoveListSetups(movetoPregSetup: IMoveToMatingLocation) {
		switch (movetoPregSetup.selectedType) {
			case MoveToLocationTypes.Date:
				movetoPregSetup.cycleDaysFrom = undefined;
				movetoPregSetup.cycleDaysTo = undefined;
				break;
		}
	}

	private save() {
		if (!this.validate(this.state.movetoPregSetup)) {
			return;
		}

		let movetoPregSetup = deepCopy(this.state.movetoPregSetup);
		movetoPregSetup.selectedType = this.state.selectedOption.value as MoveToLocationTypes;
		this.ResetDataOnMoveListSetups(movetoPregSetup);
		movetoPregSetup.siteId = this.props.siteId;
		this.setState({ movetoPregSetup });
		this.props.saveWorkListSetting(movetoPregSetup);
	}

	private validate(listSetting: IMoveToMatingLocation) {
		if (!listSetting.selectedType) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_CHOOSE_ANIMAL_NOT_SET));
			return false;
		}

		if (listSetting.selectedType === MoveToLocationTypes.PregnacyDays) {
			if (
				listSetting.cycleDaysFrom === undefined ||
				listSetting.cycleDaysFrom === null ||
				listSetting.cycleDaysFrom < 0
			) {
				showAlert(localized(ExceptionMessage.VALIDATION_ERROR_NURSING_FROM_IS_EMPTY_OR_NEGATIVE));
				return false;
			}
			if (
				listSetting.cycleDaysTo === undefined ||
				listSetting.cycleDaysTo === null ||
				listSetting.cycleDaysTo < listSetting.cycleDaysFrom
			) {
				showAlert(localized(ExceptionMessage.VALIDATION_ERROR_NURSING_TO_IS_EMPTY_OR_NEGATIVE));
				return false;
			}
		}

		return true;
	}

	private getFormRows() {
		let formRows = new Array<FormRow>();

		formRows.push(this.getBuildingHeader());

		formRows.push(
			getSkioldInputRow(
				setup => this.moveToPregnatSetupChanged(setup),
				this.state.movetoPregSetup,
				'name',
				localized('name'),
			),
		);

		formRows.push(this.getAnimalToListRow());
		if (this.state.selectedOption.value === MoveToLocationTypes.PregnacyDays) {
			formRows.push({
				name: localized('DryDays'),
				component: (
					<ViewWeb className="nursing-from-to-container">
						<ViewWeb className="flex-one-row form-padding">
							<TextWeb>{localized('from')}</TextWeb>
							<SkioldFormIntegerInput
								className="days-input"
								onChangeNumber={this.cycleDaysFromDateChanged}
								text={this.state.movetoPregSetup.cycleDaysFrom}
							/>
						</ViewWeb>
						<ViewWeb className="flex-one-row">
							<TextWeb>{localized('to')}</TextWeb>
							<SkioldFormIntegerInput
								className="days-input"
								onChangeNumber={this.cycleDaysToDateChanged}
								text={this.state.movetoPregSetup.cycleDaysTo}
							/>
						</ViewWeb>
					</ViewWeb>
				),
			});
		}

		formRows.push(
			getAvailablityRow(
				this.toggleShowOnWeb,
				this.toggleShowOnApp,
				this.state.movetoPregSetup.showOnWeb!,
				this.state.movetoPregSetup.showOnApp!,
			),
		);
		return formRows;
	}
	private cycleDaysFromDateChanged = (newNumber: number | undefined) =>
		this.valueInMoveToPregnantSetupChanged(newNumber, 'cycleDaysFrom');
	private cycleDaysToDateChanged = (newNumber: number | undefined) =>
		this.valueInMoveToPregnantSetupChanged(newNumber, 'cycleDaysTo');

	private valueInMoveToPregnantSetupChanged(value: any, key: keyof IMoveToMatingLocation) {
		this.setState(prevState => ({
			movetoPregSetup: {
				...prevState.movetoPregSetup,
				[key]: value,
			},
		}));
	}

	private getBuildingHeader(): FormRow {
		return {
			header: localized(this.props.workListType),
		};
	}

	private getAnimalToListRow(): FormRow {
		return {
			name: localized('chooseAnimalToList'),
			component: (
				<SkioldFormDropdown
					items={this.state.options}
					selectedValue={this.state.selectedOption}
					onValueChanged={(option: Option) => {
						let movetoPregSetup = deepCopy(this.state.movetoPregSetup);
						movetoPregSetup.selectedType = option.value as MoveToLocationTypes;
						this.setState({
							movetoPregSetup,
							selectedOption: option,
						});
					}}
				/>
			),
		};
	}

	private getWorkListSetting(type: WorkListType) {
		let workListSetting = this.props.workListSettings.find(
			setting => setting.type === type,
		) as MoveToMatingLocation;
		if (!workListSetting) {
			workListSetting = MoveToMatingLocation.fromJS({ type });
		}
		return workListSetting;
	}

	private renderButtons() {
		let buttons = (
			<ViewWeb className="buttons-container">
				<SkioldButton title={localized('Save')} onPress={() => this.save()} />
			</ViewWeb>
		);

		return buttons;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MoveToMatingSetup);
