import * as React from 'react';
import 'web/view/styles/burger.scss';

interface BurgerMenuButtonProps {
    onPress: () => void;
    open: boolean;
}

export class BurgerMenuButton extends React.PureComponent<BurgerMenuButtonProps> {
    public render() {
        return (
            <div onClick={this.props.onPress} className={this.props.open ? 'burger-menu open' : 'burger-menu'}>
                <div className="bar1" key="b1" />
                <div className="bar2" key="b2" />
                <div className="bar3" key="b3" />
            </div>
        );
    }
}
