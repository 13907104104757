import moment from 'moment/moment';
import {
	AnimalKind,
	AnimalType,
	CellColorStyle,
	DrugAmount,
	ITreatment,
	ITreatmentDefinition,
	ITreatmentPlan,
	TreatmentFrequency,
	TreatmentUnit,
} from 'shared/api/api';
import { DepartureTypes } from 'shared/state/models/departure-types';
import { StoreContainer } from 'shared/state/store-container';
import { AddDaysToDate, getDateString } from '../date-helpers';
import { memoizeHashmapTreatmentDefinitions, memoizeHashmapTreatmentPlan } from '../memoize-getters/memoize-getters';

export function calculateTotalTreatmentAmount(
	drugAmount: DrugAmount,
	pigCount: number,
	animalWeight: number,
	feedWeight: number,
) {
	// If pigcount == 0, then it is sow and then it is always 0
	if (pigCount < 1) {
		pigCount = 1;
	}
	let amount = 0;

	// Check if values used are valid, to ensure no NaN is returned
	if (drugAmount!.unitType === TreatmentUnit.PerAnimal) {
		amount = drugAmount!.amount! * pigCount;
	} else if (drugAmount!.unitType === TreatmentUnit.PerKgFood && feedWeight && pigCount) {
		amount = (drugAmount!.amount! / drugAmount!.perUnit!) * feedWeight * pigCount;
	} else if (drugAmount!.unitType === TreatmentUnit.PerKgPig && drugAmount.perUnit && drugAmount.amount && animalWeight && pigCount) {
		amount = (drugAmount.amount / drugAmount.perUnit) * animalWeight * pigCount;
	}

	return amount;
}

export const getAnimalTypesForTreatments = (animalType: AnimalType) => {
	if (animalType === AnimalType.Sow) {
		return [AnimalType.Sow, AnimalType.YoungFemale, AnimalType.Piglet];
	} else {
		return [animalType];
	}
};

export function calculateTotalDrugAmount(
	drugAmount: DrugAmount,
	pigCount: number,
	animalWeight: number,
	feedWeight: number,
): string {
	return calculateTotalTreatmentAmount(drugAmount, pigCount, animalWeight, feedWeight).toFixed(1);
}
export function calculateDrugAmountPerPig(
	drugAmount: DrugAmount,
	animalWeight: number,
	feedWeight: number,
	pigCount?: number,
): string {
	return calculateTotalTreatmentAmount(drugAmount, pigCount ? pigCount : 1, animalWeight, feedWeight).toFixed(1);
}

export function findRetentionTime(stemAnimalId: string) {
	const state = StoreContainer.getState();
	const treatPlans = state.treatmentPlans.entities.filter(plan => plan.stemAnimalId === stemAnimalId && !plan.isRegistration);
	let latestDate: Date | undefined;
	treatPlans.forEach(plan => {
		// Dont show retention time for piglets
		const treatmentDefinition = state.treatmentDefinitions.entities.find(
			definition =>
				definition.id === plan.treatmentDefinitionId &&
				definition.animalKinds &&
				(definition.animalKinds.includes(AnimalKind.Boar) ||
					definition.animalKinds.includes(AnimalKind.Sow) ||
					definition.animalKinds.includes(AnimalKind.Gilt)),
		);
		if (!treatmentDefinition) {
			return;
		}
		//Find subTreatment with the highest butcher quantine
		const retentionTime = getHighestButcherQuantine(treatmentDefinition);

		const treatments = state.treatments.entities.filter(
			treatment => treatment.treatmentPlanId === plan!.id! && treatment.executedDate,
		);
		//Finds the lastest executed treatment
		const latestTreatment = getLastestTreatment(treatments);
		//Calculate retentionTime
		if (retentionTime && latestTreatment) {
			const date = new Date(latestTreatment.executedDate!);
			const dateWithQuantine = new Date(date.setDate(date.getDate() + retentionTime));
			//Set if later than previous
			if (!latestDate || dateWithQuantine > latestDate) {
				latestDate = dateWithQuantine;
			}
		}
	});
	return latestDate && latestDate > new Date() ? getDateString(latestDate) : undefined;
}

export function findRetentionTimeGrowthPigHashmaps(
	treatmentDate: Date,
	treatmentPlan: ITreatmentPlan | undefined,
	treatmentDefinitions: { [key: string]: ITreatmentDefinition },
) {
	const date = findRetentionTimeGrowthPigHashmapsAsDate(treatmentDate, treatmentPlan, treatmentDefinitions);
	return date && date > new Date() ? getDateString(date) : undefined;
}

export function findRetentionTimeGrowthPigHashmapsAsDate(
	treatmentDate: Date,
	treatmentPlan: ITreatmentPlan | undefined,
	treatmentDefinitions: { [key: string]: ITreatmentDefinition },
) {
	let treatmentDateCopy = new Date(treatmentDate);
	let latestDate: Date | undefined;

	const treatmentDefinition =
		treatmentPlan &&
		treatmentDefinitions &&
		treatmentPlan.treatmentDefinitionId &&
		treatmentDefinitions[treatmentPlan.treatmentDefinitionId];
	if (treatmentDefinition) {
		const retentionTime = getHighestButcherQuantine(treatmentDefinition);
		if (retentionTime && retentionTime > 0 && treatmentPlan && !treatmentPlan.isRegistration) {
			latestDate = new Date(treatmentDateCopy.setDate(treatmentDateCopy.getDate() + retentionTime));
		}
	}
	return latestDate && latestDate > new Date() ? latestDate : undefined;
}

export function findMultiPenRetentionTimeByTreatment(treatments: ITreatment[], treatmentPlan?: ITreatmentPlan) {
	let penRetentionTime: { [key: string]: Date } = {};

	// Create hashmap for defs
	const state = StoreContainer.getState();
	const treatmentDefinitions = memoizeHashmapTreatmentDefinitions(state.treatmentDefinitions.entities);
	treatments.forEach(treatment => {
		if (treatment.executedDate && treatment.penId && !treatment.isRegistration) {
			const treatmentPlanToUse = treatmentPlan
				? treatmentPlan
				: state.treatmentPlans.entities.find(plan => plan.id === treatment.treatmentPlanId);
			let latestDate: Date | undefined;

			if (treatmentPlanToUse) {
				// Find retention time by treatmentplan and definition
				latestDate = findRetentionTimeGrowthPigHashmapsAsDate(
					treatment.executedDate,
					treatmentPlanToUse,
					treatmentDefinitions,
				);
			}
			const date = latestDate && latestDate > new Date() ? latestDate : undefined;
			if (date) {
				penRetentionTime[treatment.penId] = date;
			}
		}
	});

	return penRetentionTime;
}

export function findPenRetentionTimeByTreatment(treatment: ITreatment, treatmentPlan: ITreatmentPlan) {
	let penRetentionTime: { [key: string]: Date } = {};
	if (treatment.executedDate && treatment.penId) {
		const state = StoreContainer.getState();
		const treatmentPlanToUse = treatmentPlan
			? treatmentPlan
			: state.treatmentPlans.entities.find(plan => plan.id === treatment.treatmentPlanId);
		let latestDate: Date | undefined;
		if (treatmentPlanToUse) {
			const treatmentDefinition = state.treatmentDefinitions.entities.find(
				def => def.id === treatmentPlanToUse.treatmentDefinitionId,
			);
			let treatmentDateCopy = new Date(treatment.executedDate);

			if (treatmentDefinition) {
				const retentionTime = getHighestButcherQuantine(treatmentDefinition);
				if (retentionTime && retentionTime > 0) {
					latestDate = new Date(treatmentDateCopy.setDate(treatmentDateCopy.getDate() + retentionTime));
				}
			}
		}
		const date = latestDate && latestDate > new Date() ? latestDate : undefined;
		if (date) {
			penRetentionTime[treatment.penId] = date;
		}
	}
	return penRetentionTime;
}

export function findStemAnimalRetentionTimeByTreatment(treatment: ITreatment, treatmentPlan: ITreatmentPlan) {
	let retentionTimes: { [key: string]: Date } = {};

	if (treatmentPlan.stemAnimalId === undefined || treatment.executedDate === undefined) {
		return retentionTimes;
	}
	const state = StoreContainer.getState();
	const treatmentDefinition = state.treatmentDefinitions.entities.find(
		definition =>
			definition.id === treatmentPlan.treatmentDefinitionId &&
			definition.animalKinds &&
			(definition.animalKinds.includes(AnimalKind.Boar) ||
				definition.animalKinds.includes(AnimalKind.Sow) ||
				definition.animalKinds.includes(AnimalKind.Gilt)),
	);

	if (!treatmentDefinition) {
		return retentionTimes;
	}
	const retentionTime = getHighestButcherQuantine(treatmentDefinition);
	const newRetentionTime = moment(treatment.executedDate).add(retentionTime, 'days');
	if (newRetentionTime > moment()) {
		retentionTimes[treatmentPlan.stemAnimalId] = newRetentionTime.toDate();
	}
	return retentionTimes;
}

export function findMultipleStemAnimalRetentionTimesByTreatments(
	treatments: ITreatment[],
	treatmentPlan?: ITreatmentPlan,
) {
	let retentionTimes: { [key: string]: Date } = {};
	const state = StoreContainer.getState();

	treatments.forEach(treatment => {
		const treatmentPlanToUse =
			treatmentPlan ?? state.treatmentPlans.entities.find(plan => plan.id === treatment.treatmentPlanId);
		if (
			treatmentPlanToUse &&
			treatmentPlanToUse.stemAnimalId !== undefined &&
			!treatment.isRegistration &&
			!treatmentPlanToUse.isRegistration
		) {
			const treatmentDefinition = state.treatmentDefinitions.entities.find(
				definition =>
					definition.id === treatmentPlanToUse.treatmentDefinitionId &&
					definition.animalKinds &&
					(definition.animalKinds.includes(AnimalKind.Boar) ||
						definition.animalKinds.includes(AnimalKind.Sow) ||
						definition.animalKinds.includes(AnimalKind.Gilt)),
			);

			if (treatmentDefinition && treatment.executedDate) {
				const retentionTime = getHighestButcherQuantine(treatmentDefinition);
				const newRetentionTime = moment(treatment.executedDate).add(retentionTime, 'days');

				if (newRetentionTime > moment()) {
					retentionTimes[treatmentPlanToUse.stemAnimalId] = newRetentionTime.toDate();
				}
			}
		}
	});

	return retentionTimes;
}

export function findRetentionTimeHashmaps(
	stemAnimalId: string,
	treatmentDefinitions: { [key: string]: ITreatmentDefinition },
	treatmentPlansMappedToStemAnimalId: { [key: string]: ITreatmentPlan[] },
	treatmentsMappedToTreatmentPlanId: { [key: string]: ITreatment[] },
	selectedAnimalType: AnimalType,
) {
	let latestDate: Date | undefined;
	if (treatmentPlansMappedToStemAnimalId && treatmentPlansMappedToStemAnimalId[stemAnimalId]) {
		treatmentPlansMappedToStemAnimalId[stemAnimalId].forEach(plan => {
			if (plan.treatmentDefinitionId && !plan.isRegistration) {
				const treatmentDefinition = treatmentDefinitions[plan.treatmentDefinitionId];

				// Dont show retention time for piglets
				if (
					!treatmentDefinition ||
					(treatmentDefinition.animalKinds &&
						!treatmentDefinition.animalKinds.find(ak => ak.toString() === selectedAnimalType))
				) {
					return;
				}
				//Find subTreatment with the highest butcher quantine
				const retentionTime = getHighestButcherQuantine(treatmentDefinition);

				if (plan.id) {
					const treatments = treatmentsMappedToTreatmentPlanId[plan.id];

					//Finds the lastest executed treatment
					const latestTreatment = getLastestTreatment(treatments);
					//Calculate retentionTime
					if (retentionTime && latestTreatment) {
						const date = new Date(latestTreatment.executedDate!);
						const dateWithQuantine = AddDaysToDate(date, retentionTime);
						//Set if later than previous
						if (!latestDate || dateWithQuantine > latestDate) {
							latestDate = dateWithQuantine;
						}
					}
				}
			}
		});
	}
	return latestDate && latestDate > new Date() ? getDateString(latestDate) : undefined;
}

export enum animalNumberColorEnum {
	gradient = 'gradient',
	red = 'red',
	green = 'green',
	normal = 'normal',
}
export function getAnimalNumberColor(stemAnimalId: string) {
	const retentionDate = findRetentionTime(stemAnimalId);
	const departureTypeIsShouldDeparture = checkSowDepartureType(stemAnimalId);
	if (retentionDate && departureTypeIsShouldDeparture) {
		return CellColorStyle.RedGreen;
	} else if (retentionDate) {
		return CellColorStyle.Red;
	} else if (departureTypeIsShouldDeparture) {
		return CellColorStyle.Green;
	} else {
		return CellColorStyle.Normal;
	}
}

export function checkSowDepartureType(stemAnimalId: string) {
	const state = StoreContainer.getState();
	const stemAnimal = state.stemAnimals.entities.find(a => a.id === stemAnimalId);
	return stemAnimal && stemAnimal.departureType === DepartureTypes.departureTypeShouldDeparture ? true : false;
}

export function getHighestButcherQuantine(treatmentDefinition: ITreatmentDefinition) {
	return treatmentDefinition
		? treatmentDefinition!.subTreatments!.reduce((currentButcherQ, nextButcherQ, index) =>
				nextButcherQ.butcherQuarantine! > currentButcherQ.butcherQuarantine! && index
					? nextButcherQ
					: currentButcherQ,
		  ).butcherQuarantine
		: undefined;
}

function getLastestTreatment(treatments: ITreatment[]) {
	return treatments && treatments.length > 0
		? treatments.reduce((currentTreatment, nextTreatment, index) =>
				nextTreatment.executedDate! > currentTreatment.executedDate! && index
					? nextTreatment
					: currentTreatment,
		  )
		: undefined;
}

export const mapTreatmentsRetentionDate = (animalType: AnimalType) => {
	const state = StoreContainer.getState();
	const treatmentDefinitionHashmap = memoizeHashmapTreatmentDefinitions(state.treatmentDefinitions.entities);
	const treatmentPlanHashmap = memoizeHashmapTreatmentPlan(state.treatmentPlans.entities);
	const penIdRentionDate: { [key: string]: Date } = {};
	state.treatments.entities.forEach(treatment => {
		if (
			animalType === treatment.animalType &&
			treatment.penId &&
			treatment.executedDate &&
			treatment.treatmentPlanId
		) {
			const plan = treatmentPlanHashmap[treatment.treatmentPlanId];
			if (plan && plan.treatmentDefinitionId) {
				const def = treatmentDefinitionHashmap[plan.treatmentDefinitionId];
				if (def) {
					const butcherDays = getHighestButcherQuantine(def);
					if (butcherDays) {
						const retentionDate = AddDaysToDate(treatment.executedDate, butcherDays);
						if (!penIdRentionDate[treatment.penId]) {
							penIdRentionDate[treatment.penId] = retentionDate;
						} else {
						}
						penIdRentionDate[treatment.penId] =
							penIdRentionDate[treatment.penId] > retentionDate
								? penIdRentionDate[treatment.penId]
								: retentionDate;
					}
				}
			}
		}
	});

	return penIdRentionDate;
};

export const mapTreatmentsToAnimal = (treatmentPlans: ITreatmentPlan[], treatments: ITreatment[]) => {
	const mapped = {};
	const treatmentKeyValuePair = treatments.keyValuePairArray('treatmentPlanId');
	treatmentPlans.forEach(tp => {
		if (!tp.stemAnimalId || !tp.treatmentDefinitionId || !tp.id) {
			return;
		} else if (!mapped[tp.stemAnimalId]) {
			mapped[tp.stemAnimalId] = {};
			mapped[tp.stemAnimalId][tp.treatmentDefinitionId] = treatmentKeyValuePair[tp.id];
		} else if (!mapped[tp.stemAnimalId][tp.treatmentDefinitionId]) {
			mapped[tp.stemAnimalId][tp.treatmentDefinitionId] = treatmentKeyValuePair[tp.id];
		} else {
			mapped[tp.stemAnimalId][tp.treatmentDefinitionId] = mapped[tp.stemAnimalId][
				tp.treatmentDefinitionId
			].concat(treatmentKeyValuePair[tp.id]);
		}
	});

	return mapped;
};

export function getInterval(freq?: TreatmentFrequency): number {
	if (freq === TreatmentFrequency.EveryDay) {
		return 1;
	} else if (freq === TreatmentFrequency.EveryOtherDay) {
		return 2;
	} else if (freq === TreatmentFrequency.EveryWeek) {
		return 7;
	}

	return 0;
}
