import { Action, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { signOut } from 'web/web-helpers/authentication-helper-web';
import { isAppFailure } from 'shared/helpers/redux-helpers';
import { Monitoring } from 'shared/helpers/monitoring-service';
import { AccessTokenExpireKey } from 'shared/constants';

export const authorizationMiddleware: () => Middleware = () => {
    return <S>(api: MiddlewareAPI) => (next: Dispatch) => <A extends Action>(action: A): A => {
        if (isAppFailure(action)) {
            let anyAction = action as any;
            if (anyAction && anyAction.error && anyAction.payload && anyAction.payload.error && anyAction.payload.error.code === 401) {
                let tokenExpire = localStorage.getItem(AccessTokenExpireKey);

                Monitoring.logTrace('Unauthorized from API - signing out', {
                    tokenExpire: tokenExpire!,
                    timeNow: new Date().toISOString()
                });

                signOut();
            }
        }

        return next(action);
    };
};
