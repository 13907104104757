import React from 'react';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import './register-move-event.scss';
import { WebAppState } from 'web/state/store.web';
import { IMoveEvent, Gender } from 'shared/api/api';
import { SaveMoveEvent, GetSyncData as MoveEventGetSyncData } from 'shared/state/ducks/move-events/operations';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { FormRow } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { SkioldFormsWrapper } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { localized } from 'shared/state/i18n/i18n';
import * as UnitsToPenOperations from 'shared/state/ducks/unit-to-pen/operations';
import { GetProcessEquipmentDataSyncData } from 'shared/state/ducks/process-equipment-data/operations';
import MoveEventBase from './move-event-base';

const mapStateToProps = (state: WebAppState) => {
	return {
		siteId: state.profile.active!.siteId,
		moveEvents: state.moveEvents.entities,
		moveEventUpdates: state.moveEvents.updates,
		pens: state.locations.pens,
		stemAnimals: state.stemAnimals.entities,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	saveMoveEvent: (moveEvent: IMoveEvent) => SaveMoveEvent(moveEvent)(dispatch),
	getSyncData: () => MoveEventGetSyncData()(dispatch),
	GetProcessEquipmentDataSyncData: async () => (await GetProcessEquipmentDataSyncData())(dispatch),
	getunitsData: () => UnitsToPenOperations.GetSyncData()(dispatch),
});

interface PropsFromParent {
	pregnancyEventId?: string;
	closeEditModal?: () => void;
}

interface MoveEventState {}

type MoveEventProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;
class RegisterMoveEvent extends React.PureComponent<MoveEventProps, MoveEventState> {
	public moveEventSave: (() => Promise<boolean>) | undefined;
	constructor(props: MoveEventProps) {
		super(props);
		this.state = {};
	}

	public componentDidMount() {
		this.props.getSyncData();
		this.props.getunitsData();
		this.props.GetProcessEquipmentDataSyncData();
	}

	public render() {
		return <ViewWeb className="register-move-event">{this.renderContent()}</ViewWeb>;
	}

	public renderContent() {
		const formRows = this.getFormRows();
		const pushFormRow = (formRow: FormRow) => formRows.push(formRow);
		return (
			<ViewWeb>
				{this.renderButtons()}
				<ViewWeb className="move-event-z-index">
					<MoveEventBase
						dateRow={pushFormRow}
						animalNumberRow={pushFormRow}
						genderRow={pushFormRow}
						locationRow={pushFormRow}
						lastMoveDateRow={pushFormRow}
						stableRow={pushFormRow}
						penRow={pushFormRow}
						feedCurveRow={pushFormRow}
						fixedDeviationRow={pushFormRow}
						update={this.update}
						gender={Gender.Female}
						save={this.SetSaveMethod}
					/>
					<SkioldFormsWrapper formRows={formRows} containerClassName="move-event-forms-wrapper" />
				</ViewWeb>
			</ViewWeb>
		);
	}
	private update = () => this.forceUpdate();
	private SetSaveMethod = (method: () => Promise<boolean>) => (this.moveEventSave = method);

	private getFormRows() {
		let formRows = new Array<FormRow>();
		return formRows;
	}

	private renderButtons() {
		let buttons = (
			<ViewWeb className="button-view-style-upper-corner">
				<SkioldButton title={localized('Save')} onPress={() => this.save()} />
			</ViewWeb>
		);

		return buttons;
	}

	private async save() {
		if (this.moveEventSave) {
			const valid = await this.moveEventSave();
			if (!valid) {
				return;
			}
			if (this.props.closeEditModal) {
				this.props.closeEditModal();
			}
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterMoveEvent);
