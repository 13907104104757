import { BalanceWeanedPigEvent, ITotalWeight} from "shared/api/api";

export const SYNC_DATA_BALANCE_WEANED_PIGS = 'SYNC_DATA_BALANCE_WEANED_PIGS';
export const UPSERT_BALANCE_WEANED_PIGS = 'UPSERT_BALANCE_WEANED_PIGS';

export interface BalanceWeanedPigsState {
    entities: BalanceWeanedPigEvent[];
    totalAmountAndTotalWeight?: ITotalWeight;
    currentPigsOnStable?: number,
    isFetchingData: boolean;
}
