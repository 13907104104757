import moment from 'moment';
import { Option } from 'react-dropdown';
import {
	ILardScanningEvent,
	ILardScanningEventListSettingBase,
	IMatingBatch,
	IPregnancyEvent,
	IStemAnimal,
	LardScanningEventTypes,
	MatingBatch,
	PregnancyState,
	Scanned,
	ScanResult,
	SowState,
} from 'shared/api/api';
import { calculateDays } from 'shared/helpers/general-helpers';
import { getCycleDays1 } from 'shared/helpers/pregnancy-helper/generel-pregnancy-helpers';
import { sortPregnanciesByDate } from 'shared/helpers/pregnancy-helper/sort-pregnancies';
import { getActiveSows } from 'shared/helpers/stemanimal-helper/stem-animal-input-helper';
import { calculateLitter1, getState, getState1 } from 'shared/helpers/stemanimal-helper/stemanimal-helper';
import { getEventDate } from '../move-to-helpers/moving-lists-helper';
import { getStateByPregnancies } from '../sow-task-wl-helper/sow-task-wl-helper';
import {
	LardScanningEventListProps,
	LardScanningEventListState,
	LardScanningEventListTableItem,
} from './lard-scanning-event-work-list-item';

export function genereateLardScanningEventListTableData(
	props: LardScanningEventListProps,
	state: LardScanningEventListState,
) {
	let activeSows = getActiveSows();
	const batchNumbersInUse: string[] = [];
	let workListData = [] as LardScanningEventListTableItem[];
	let batchesToUse = getBatchesToUseLardScanning(props.matingBatches, props.workListSetting);
	for (let sow of activeSows) {
		let sorted = sortPregnanciesByDate(props.pregnancyEvents[sow.id!]);
		const sowState = getState1(sow.id!, sorted);
		if (invalidateState(sowState, props.workListSetting)) {
			continue;
		}
		let maxDate: Date | undefined;
		let minDate: Date | undefined;
		let matingBatch: IMatingBatch | undefined;
		if (sowState === SowState.Pregnant) {
			const mated = sorted.find(x => x.state === PregnancyState.Mated);
			if (!mated) {
				continue;
			}

			matingBatch = props.matingBatches.find(
				x => mated.date && x.matingPeriodStart! <= mated.date && mated.date <= x.matingPeriodEnd!,
			);
			maxDate = new Date(
				Math.max.apply(
					null,
					batchesToUse.matingBatchesToUse.flatMap(e => e.matingPeriodEnd!.getTime()),
				),
			);
			minDate = new Date(
				Math.min.apply(
					null,
					batchesToUse.matingBatchesToUse.flatMap(e => e.matingPeriodStart!.getTime()),
				),
			);
		} else if (sowState === SowState.Nursing || sowState === SowState.PregnantAndNursing) {
			const farrowing = sorted.find(x => x.state === PregnancyState.Farrowing);
			if (farrowing?.date) {
				sorted = sorted.filter(x => x.date && x.date <= farrowing?.date!);
			}

			if (!farrowing) {
				continue;
			}

			const mated = sorted.find(x => x.state === PregnancyState.Mated);
			if (!mated) {
				continue;
			}
			matingBatch = props.matingBatches.find(
				x => mated.date && x.matingPeriodStart! <= mated.date && mated.date <= x.matingPeriodEnd!,
			);
			maxDate = new Date(
				Math.max.apply(
					null,
					batchesToUse.matingBatchesToUse.flatMap(e => e.farrowingPeriodEnd!.getTime()),
				),
			);
			minDate = new Date(
				Math.min.apply(
					null,
					batchesToUse.matingBatchesToUse.flatMap(e => e.farrowingPeriodStart!.getTime()),
				),
			);
		}

		if (!minDate || !maxDate || !props.workListSetting.weeksAfterEvent) {
			continue;
		}

		minDate.setDate(minDate.getDate() + props.workListSetting.weeksAfterEvent * 7);
		maxDate.setDate(maxDate.getDate() + props.workListSetting.weeksAfterEvent * 7);

		if (
			sowState !== SowState.Dry &&
			!(props.workListSetting.weeksAfterEvent && batchesToUse.matingBatchesToUse.find(b => matingBatch && b.id === matingBatch.id))
		) {
			continue;
		}

		if (sowState !== SowState.Dry) {
			const larsScanning = props.lardScanningEvents.find(
				e =>
					minDate && maxDate && e.date && e.date >= minDate && e.date <= maxDate && sow.id === e.stemAnimalId,
			);
			if (larsScanning) {
				continue;
			}
		} else if (sowState === SowState.Dry) {
			const latestEvent = sorted.length ? sorted[0] : undefined;
			const larsScanning = props.lardScanningEvents.find(
				e => latestEvent && latestEvent.date && e.date && e.date >= latestEvent.date,
			);
			if (larsScanning) {
				continue;
			}
		}

		let workListItem = new LardScanningEventListTableItem();
		workListItem.animalNumber = sow.animalNumber;
		workListItem.stemAnimalId = sow.id;
		workListItem.batchNumber = matingBatch?.batchNumber?.toString() ?? '';
		workListItem.cycleDays = getCycleDays1(sow.id!, sorted);
		workListItem.date = new Date();
		workListItem.litter = calculateLitter1(sow, new Date(8640000000000000), props.pregnancyEvents[sow.id!]);
		workListData.push(workListItem);
	}
	return workListData.filter(a => a.batchNumber?.toString() === props.batch.value);
}

const invalidateState = (state: SowState, setting: ILardScanningEventListSettingBase) => {
	if (state === SowState.Dry && setting.sowState === SowState.Dry) {
		return false;
	}
	if (
		(state === SowState.Nursing ||
			state === SowState.PregnantAndNursing) && setting.sowState === SowState.Nursing
	) {
		return false;
	}
	if (state === SowState.Pregnant && setting.sowState === SowState.Pregnant) {
		return false;
	}
	return true;
};

function GetCycleDaysForMating(props: LardScanningEventListProps, sow: IStemAnimal) {
	const sowState = getState(sow.id!);

	if (sowState !== SowState.Pregnant && sowState !== SowState.PregnantAndNursing) {
		return undefined;
	}
	const sowPregnancies = props.pregnancyEvents[sow.id!];
	const sortedPregnancyEvents = sortPregnanciesByDate(sowPregnancies.filter(a => a.state === PregnancyState.Mated));
	const eventDate = sortedPregnancyEvents[0].date;
	if (!eventDate) {
		return undefined;
	}
	const cycleDays = calculateDays(eventDate!, new Date());
	if (
		props.workListSetting.daysAfterEvent &&
		props.workListSetting.daysAfterEvent <= cycleDays &&
		((props.workListSetting.cycleDaysFrom !== 0 && !cycleDays) ||
			(cycleDays &&
				(cycleDays < props.workListSetting.cycleDaysFrom! || cycleDays > props.workListSetting.cycleDaysTo!)))
	) {
		return undefined;
	}

	if (
		hasLardScanningForPeriod(
			eventDate,
			props.workListSetting.cycleDaysFrom,
			props.workListSetting.cycleDaysTo,
			props.lardScanningEvents,
			sow.id,
		)
	) {
		return undefined;
	}

	return cycleDays;
}
function GetCycleDaysForFarrowing(props: LardScanningEventListProps, sow: IStemAnimal) {
	const sowState = getState(sow.id!);
	if (sowState !== SowState.Nursing) {
		return undefined;
	}
	const sowPregnancies = props.pregnancyEvents[sow.id!];
	const sortedPregnancyEvents = sortPregnanciesByDate(
		sowPregnancies.filter(a => a.state === PregnancyState.Farrowing),
	);
	const eventDate = sortedPregnancyEvents[0].date;
	if (!eventDate) {
		return undefined;
	}
	const cycleDays = calculateDays(eventDate!, new Date());
	if (
		props.workListSetting.daysAfterEvent &&
		props.workListSetting.daysAfterEvent <= cycleDays &&
		((props.workListSetting.cycleDaysFrom !== 0 && !cycleDays) ||
			(cycleDays &&
				(cycleDays < props.workListSetting.cycleDaysFrom! || cycleDays > props.workListSetting.cycleDaysTo!)))
	) {
		return undefined;
	}

	if (
		hasLardScanningForPeriod(
			eventDate,
			props.workListSetting.cycleDaysFrom,
			props.workListSetting.cycleDaysTo,
			props.lardScanningEvents,
			sow.id,
		)
	) {
		return undefined;
	}

	return cycleDays;
}

function GetCycleDaysForMatingBatch(
	props: LardScanningEventListProps,
	state: LardScanningEventListState,
	sow: IStemAnimal,
) {
	const matingBatch = props.matingBatches.find(a => a.id === state.SelectedMatingBatch.value);
	if (!matingBatch) {
		return undefined;
	}
	const sowPregnancies = props.pregnancyEvents[sow.id!];
	let filteredPregnancyEvents = sortPregnanciesByDate(sowPregnancies);
	const eventDate = getEventDate(filteredPregnancyEvents);
	let lastestPregEvent = filteredPregnancyEvents.find(preg => preg.date!.toString() === eventDate.toString());
	if (
		lastestPregEvent &&
		lastestPregEvent.state === PregnancyState.Scanned &&
		(lastestPregEvent as Scanned).result === ScanResult.True
	) {
		lastestPregEvent = filteredPregnancyEvents.find(preg => preg.state === PregnancyState.Mated);
	}
	if (
		!lastestPregEvent ||
		!lastestPregEvent.date ||
		lastestPregEvent.date! < matingBatch.matingPeriodStart! ||
		lastestPregEvent.date! > matingBatch.matingPeriodEnd!
	) {
		return undefined;
	}
	return calculateDays(lastestPregEvent.date, new Date());
}

export function generateMatingBatchSetupLardScanningEvent(
	workListSettings: ILardScanningEventListSettingBase,
	matingBatches: IMatingBatch[],
) {
	let selectedOption: Option = { label: ' ', value: ' ' };
	let matingBatchesOptions: Option[] = [];
	if (workListSettings && workListSettings.selectedType === LardScanningEventTypes.MatingBatch) {
		const sortedBatches = matingBatches.sort((a, b) => {
			return b.matingPeriodStart!.getTime() - a.matingPeriodStart!.getTime();
		});

		let dateToCalculateWith = new Date();
		dateToCalculateWith.setDate(dateToCalculateWith.getDate() - workListSettings.batchCycleDays!);
		let batchIndex = sortedBatches.findIndex(batch => batch.matingPeriodStart! < dateToCalculateWith);
		if (batchIndex !== -1) {
			if (batchIndex !== 0) {
				matingBatchesOptions.push({
					label: sortedBatches[batchIndex - 1].batchNumber!.toString(),
					value: sortedBatches[batchIndex - 1].id!,
				});
			}
			selectedOption = {
				label: sortedBatches[batchIndex].batchNumber!.toString(),
				value: sortedBatches[batchIndex].id!,
			};
			matingBatchesOptions.push(selectedOption);
			if (batchIndex + 1 !== sortedBatches.length) {
				matingBatchesOptions.push({
					label: sortedBatches[batchIndex + 1].batchNumber!.toString(),
					value: sortedBatches[batchIndex + 1].id!,
				});
			}
			return { selectedOption, matingBatchesOptions };
		}
	}
	return { selectedOption, matingBatchesOptions };
}

function hasLardScanningForPeriod(
	eventDate: Date,
	cycleDaysFrom: number | undefined,
	cycleDaysTo: number | undefined,
	stemLards: ILardScanningEvent[],
	stemAnimalId: string | undefined,
) {
	let fromDate = moment(eventDate).add(cycleDaysFrom, 'days').toDate();
	let toDate = moment(eventDate).add(cycleDaysTo, 'days').toDate();

	let lards = stemLards.some(
		lard => lard.stemAnimalId === stemAnimalId && lard.date && lard.date >= fromDate && lard.date <= toDate,
	);
	if (lards) {
		return true;
	}

	return false;
}

export function getBatchesToUseLardScanning(
	matingBatches: IMatingBatch[],
	workListSetting: ILardScanningEventListSettingBase,
): { matingBatchesToUse: IMatingBatch[], currentBatch: IMatingBatch } {
	let matingBatchesToUse: IMatingBatch[] = [];
	let currentBatch: IMatingBatch = new MatingBatch();
	if (!workListSetting || workListSetting.weeksAfterEvent === undefined) {
		return { matingBatchesToUse, currentBatch };
	}
	let matingBatchesSorted = matingBatches.slice().sort((a, b) => (a.matingPeriodStart!.getTime() - b.matingPeriodStart!.getTime()));
	// Add prev and next mating batch
	const newDay = new Date().withoutTime();
	newDay.setDate(newDay.getDate() - workListSetting.weeksAfterEvent * 7);

	let startDate = moment(newDay).startOf('isoWeek').toDate();
	let endDate = moment(newDay).endOf('isoWeek').toDate();
	let indexBatch = -1;
	if (workListSetting.sowState === SowState.Pregnant) {
		indexBatch = matingBatchesSorted.findIndex(
			a =>
				a.matingPeriodStart && a.matingPeriodEnd &&
				a.matingPeriodStart <= newDay &&
				a.matingPeriodEnd >= newDay
		);
	} else {
		indexBatch = matingBatchesSorted.findIndex(
			a =>
				a.farrowingPeriodStart && a.farrowingPeriodEnd &&
				a.farrowingPeriodStart <= newDay &&
				a.farrowingPeriodEnd >= newDay
		);
	}

	if (indexBatch >= 0) {
		matingBatchesSorted[indexBatch - 1] && matingBatchesToUse.push(matingBatchesSorted[indexBatch - 1]);
		matingBatchesSorted[indexBatch] && matingBatchesToUse.push(matingBatchesSorted[indexBatch]);
		matingBatchesSorted[indexBatch + 1] && matingBatchesToUse.push(matingBatchesSorted[indexBatch + 1]);
		currentBatch = matingBatchesSorted[indexBatch];
	}
	return { matingBatchesToUse, currentBatch };
}
