import memoize from 'memoize-one';
import React from 'react';
import { Option } from 'react-dropdown';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AnimalType, ITreatment, ITreatmentPlan, TreatmentUnit } from 'shared/api/api';
import { getDiagnoseName } from 'shared/helpers/diagnose-helper/diagnose-helper';
import { getTreatmentAnimalKindsOptions } from 'shared/helpers/general-helpers';
import { calculateTotalTreatmentAmount } from 'shared/helpers/treatment-helper/treatment-helper';
import { GetTreatmentPlansForLog } from 'shared/state/ducks/treatment-plan/operations';
import { GetCompletedTreatmentsInPeriod } from 'shared/state/ducks/treatment/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import PageContainer from 'web/view/components/page-container/page-container';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldDropdown } from 'web/view/components/skiold-components/skiold-dropdown/skiold-dropdown';
import { SkioldFetch } from 'web/view/components/skiold-components/skiold-fetch/skiold-fetch';
import SkioldTableGrid from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { SowListConstants } from 'web/view/components/stem-animal/animal-lists/table-constants';
import { getDrugAmountCell } from 'web/view/components/treatments/treatment-log/drug-amount-text';
import { getDrugNameCell } from 'web/view/components/treatments/treatment-log/drug-name-text';
import {
	SumOfTreatments,
	SumOfTreatmentsTableModel,
	TreatmentTableModel,
} from 'web/view/components/treatments/treatment-plan/treatment-table-model';
import { Heading } from 'web/view/components/utils/heading';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import './treatment-log.scss';

const mapStateToProps = (state: WebAppState) => {
	return {
		treatmentPlans: state.treatmentPlans.treatmentPlansForLog,
		fetchingTreatmentPlans: state.treatmentPlans.fetchingTreatmentPlansForLog,
		fetchingTreatments: state.treatments.fetchingTreatmentsForLog,
		language: state.profile.active!.language,
		treatments: state.treatments.treatmentsForLog,
		diagnoses: state.diagnose.entities,
		siteId: state.profile.active!.siteId,
		treatmentDefinitions: state.treatmentDefinitions.entities,
		profile: state.profile.active!,
		animalTypes:
			state.navigation.query && state.navigation.query.type ? state.navigation.query.type : AnimalType.Sow,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	getTreatmentPlans: (siteId: string, startInterval: Date, endInterval: Date) =>
		GetTreatmentPlansForLog(siteId, startInterval, endInterval)(dispatch),
	getTreatments: (siteId: string, startInterval: Date, endInterval: Date) =>
		GetCompletedTreatmentsInPeriod(siteId, startInterval, endInterval)(dispatch),
});

interface PropsFromParent {
	tableModels: TreatmentTableModel[];
	startDate: Date;
	endDate: Date;
	generateTableModelsForSummary: (
		treatments: ITreatment[],
		treatmentPlans: ITreatmentPlan[],
		startDate: Date,
		endDate: Date,
	) => TreatmentTableModel[];
	close: () => void;
	selectedOption: Option;
}
interface State {
	startDate: Date;
	endDate: Date;
	selectedAnimalKind: Option;
	animalKindOptions: Option[];
	sumOfTreatmentsColumns: any[];
	sumOfTreatmentsColumnExte: any[];
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;
export class TreatmentLogAnimalTypes extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		const options = getTreatmentAnimalKindsOptions(this.props.profile, [this.props.animalTypes] as AnimalType[]);
		this.state = {
			endDate: props.endDate,
			selectedAnimalKind: props.selectedOption,
			animalKindOptions: options,
			startDate: props.startDate,
			sumOfTreatmentsColumns: this.generateSumOfTreatmentsColumns(),
			sumOfTreatmentsColumnExte: this.generateSumOfTreatmentsColumnsExtensions(),
		};
	}

	private generateData = memoize((treatments, treatmentplans, startDate, endDate) => {
		if (treatments.length > 0 && treatmentplans.length > 0) {
			const treatmentsToUse = treatments.filter(
				t => t.executedDate && t.executedDate >= startDate && t.executedDate <= endDate,
			);
			return this.props.generateTableModelsForSummary(
				treatmentsToUse,
				treatmentplans.filter(tp => treatmentsToUse.find(t => t.treatmentPlanId === tp.id) !== undefined),
				startDate,
				endDate,
			);
		}
		return [];
	});

	public render() {
		let tableData = this.props.tableModels;
		if (this.state.startDate !== this.props.startDate || this.state.endDate !== this.props.endDate) {
			tableData = this.generateData(
				this.props.treatments,
				this.props.treatmentPlans,
				this.state.startDate,
				this.state.endDate,
			);
		}
		return (
			<PageContainer className="treatment-log">
				<Heading text={localized('DrugUseForAnimals')} />
				<ViewWeb className="animalKindDropdown">
					<SkioldDropdown
						onValueChanged={this.animalKindChanged}
						selectedValue={this.state.selectedAnimalKind}
						items={this.state.animalKindOptions}
						containerClassName="drugType-dropdown"
						disabled={true}
					/>
				</ViewWeb>
				<ViewWeb className="ViewStyle paddingLeftTen verticalPaddingTen">
					<SkioldFetch
						showSpinner={this.props.fetchingTreatmentPlans || this.props.fetchingTreatments}
						fromDate={this.state.startDate}
						toDate={this.state.endDate}
						fetchData={this.fetchTreatmentPlans}
					/>
					<ViewWeb className="button-container marginBottomTen">
						<SkioldButton theme="grey" title={localized('Close')} onPress={this.props.close} />
					</ViewWeb>
				</ViewWeb>
				<ViewWeb className="viewContainerStyle">{this.generateSumOfTreatmentsTable(tableData)}</ViewWeb>
			</PageContainer>
		);
	}

	private animalKindChanged = (option: Option) => {
		this.setState({ selectedAnimalKind: option });
	};

	private fetchTreatmentPlans = (fromDate: Date, toDate: Date) => {
		this.props.getTreatmentPlans(this.props.siteId!, fromDate, toDate);
		this.props.getTreatments(this.props.siteId!, fromDate, toDate);
		this.setState({ endDate: toDate, startDate: fromDate });
	};

	public generateSumOfTreatmentsTable(tableModels: TreatmentTableModel[]) {
		const SumOfTreatmentsTableModels = this.getSumOfTreatments(tableModels);
		const tableItem = SumOfTreatmentsTableModels.find(
			tableModel =>
				tableModel.animalKinds &&
				tableModel.animalKinds.find(ak => ak.toString() === this.state.selectedAnimalKind.value) !== undefined,
		);

		return (
			<ViewWeb className="maxWidthAndMarginBottomSixTeen">
				<SkioldTableGrid
					tableKey={'sumTreatmentLogListTable'}
					columns={this.state.sumOfTreatmentsColumns}
					ColumnExtensions={this.state.sumOfTreatmentsColumnExte}
					data={tableItem ? tableItem.subSumOftreatmentTableModels : []}
				/>
			</ViewWeb>
		);
	}

	public getSumOfTreatments(treatmentTableModels: TreatmentTableModel[]): SumOfTreatments[] {
		let SumOfTreatmentsTableModels: SumOfTreatments[] = [];
		treatmentTableModels.forEach(treatmentTableModel => {
			if (treatmentTableModel.treatmentDefinitionId) {
				let td = this.props.treatmentDefinitions.find(
					td => td.id === treatmentTableModel.treatmentDefinitionId,
				);
				let SumOfTreatmentsTableModel = SumOfTreatmentsTableModels.find(
					a => JSON.stringify(a.animalKinds) === JSON.stringify(td!.animalKinds),
				);
				if (SumOfTreatmentsTableModel) {
					let SumOfTreatmentsModel = SumOfTreatmentsTableModel.subSumOftreatmentTableModels.find(
						subtreatment =>
							subtreatment.diagnose === treatmentTableModel.diagnose &&
							treatmentTableModel.treatmentDefinitionId === subtreatment.treatmentDefinitionId,
					);
					if (SumOfTreatmentsModel) {
						this.addDrugAmountToDiagnose(treatmentTableModel, SumOfTreatmentsModel);
					} else {
						let subTreatmentModel = this.getTreatmentData(treatmentTableModel);
						SumOfTreatmentsTableModel.subSumOftreatmentTableModels.push(subTreatmentModel);
					}
				} else {
					let subTreatmentModel = this.getTreatmentData(treatmentTableModel);

					let SumOfTreatments = {
						animalKinds: td!.animalKinds,
						diagnose: treatmentTableModel.diagnose,
						subSumOftreatmentTableModels: [subTreatmentModel],
					};
					SumOfTreatmentsTableModels.push(SumOfTreatments);
				}
			}
		});
		return SumOfTreatmentsTableModels;
	}

	public generateSumOfTreatmentsColumnsExtensions() {
		return [
			{
				columnName: 'diagnose',
				width: SowListConstants.treatmetLogDiagnoseWidth,
			},
			{
				columnName: 'drug',
				filteringEnabled: false,
				width: SowListConstants.treatDefinitionDrugWidth,
			},
			{
				columnName: 'drugAmount',
				filteringEnabled: false,
				width: SowListConstants.transponderWidth,
			},
			{
				columnName: 'firstTreatments',
				filteringEnabled: false,
				width: SowListConstants.transponderWidth,
			},
			{
				columnName: 'retreatments',
				filteringEnabled: false,
				width: SowListConstants.transponderWidth,
			},
		];
	}

	private addDrugAmountToDiagnose(
		treatmentTableModel: TreatmentTableModel,
		SumOfTreatmentsModel: SumOfTreatmentsTableModel,
	) {
		SumOfTreatmentsModel.isRegistration = treatmentTableModel.isRegistration;
		//pigWeight is the total amount, therefore, we have to divide for calculateTotalTreatmentAmount
		for (let index = 0; index < treatmentTableModel.subtreatmentTableModels.length; index++) {
			const sub = treatmentTableModel.subtreatmentTableModels[index];

			SumOfTreatmentsModel!.usage![index] +=
				calculateTotalTreatmentAmount(
					sub.drugAmount!,
					treatmentTableModel.pigAmount ? treatmentTableModel.pigAmount : treatmentTableModel.pigCount || 1,
					treatmentTableModel.pigWeight ? treatmentTableModel.pigWeight : treatmentTableModel.animalWeight!,
					treatmentTableModel.feedWeight!,
				) /
				(treatmentTableModel.pigAmount && sub.drugAmount!.unitType !== TreatmentUnit.PerAnimal
					? treatmentTableModel.pigAmount
					: 1);
			if (treatmentTableModel.treatmentNumber === 1) {
				SumOfTreatmentsModel!.firstTreatment![index] += treatmentTableModel.pigAmount
					? treatmentTableModel.pigAmount
					: treatmentTableModel.pigCount || 1;
			}
			if (treatmentTableModel.treatmentNumber && treatmentTableModel.treatmentNumber > 1) {
				SumOfTreatmentsModel!.retreatments![index] += treatmentTableModel.pigAmount
					? treatmentTableModel.pigAmount
					: treatmentTableModel.pigCount || 1;
			}
		}
	}

	private getTreatmentData(treatmentTableModel: TreatmentTableModel) {
		let usageArray: number[] = [];
		let firstTreatment: number[] = [0];
		let retreatments: number[] = [0];

		let numberTreated = treatmentTableModel.pigAmount
			? treatmentTableModel.pigAmount
			: treatmentTableModel.pigCount || 1;
		treatmentTableModel.subtreatmentTableModels.forEach(sub => {
			usageArray.push(
				calculateTotalTreatmentAmount(
					sub.drugAmount!,
					numberTreated,
					treatmentTableModel.pigWeight ? treatmentTableModel.pigWeight : treatmentTableModel.animalWeight!,
					treatmentTableModel.feedWeight!,
				) /
					(treatmentTableModel.pigAmount && sub.drugAmount!.unitType !== TreatmentUnit.PerAnimal
						? treatmentTableModel.pigAmount
						: 1),
			);
		});

		if (treatmentTableModel.treatmentNumber === 1) {
			firstTreatment[0] = numberTreated;
		}
		if (treatmentTableModel.treatmentNumber && treatmentTableModel.treatmentNumber > 1) {
			retreatments[0] = numberTreated;
		}
		return {
			diagnose: treatmentTableModel.diagnose!,
			usage: usageArray,
			subtreatmentTableModels: treatmentTableModel.subtreatmentTableModels,
			treatmentDefinitionId: treatmentTableModel.treatmentDefinitionId,
			firstTreatment,
			retreatments,
		};
	}

	private getSumOfFirstTreatments(treatmentTableModel: SumOfTreatmentsTableModel) {
		if (!treatmentTableModel || !treatmentTableModel.subtreatmentTableModels) {
			return <ViewWeb />;
		}
		return <TextWeb>{treatmentTableModel.firstTreatment![0]}</TextWeb>;
	}

	private getSumOfRetreatments(treatmentTableModel: SumOfTreatmentsTableModel) {
		if (!treatmentTableModel || !treatmentTableModel.subtreatmentTableModels) {
			return <ViewWeb />;
		}
		return <TextWeb>{treatmentTableModel.retreatments![0]}</TextWeb>;
	}

	private readonly getDiagnoseCell = (t: SumOfTreatmentsTableModel) =>
		getDiagnoseName(t.diagnose, this.props.language);

	private generateSumOfTreatmentsColumns() {
		if (!this.props.language) {
			// We need the language to display the diagnose
			return [];
		}
		return [
			{
				name: 'diagnose',
				className: 'left-align-content',
				title: localized('Diagnose'),
				getCellValue: this.getDiagnoseCell,
			},
			{
				name: 'drug',
				title: localized('drug'),
				sortable: false,
				filterable: false,
				className: 'left-align-content',
				getCellValue: getDrugNameCell,
			},
			{
				name: 'drugAmount',
				title: localized('DrugAmountShort'),
				sortable: false,
				width: SowListConstants.transponderWidth,
				getCellValue: getDrugAmountCell,
			},
			{
				name: 'firstTreatments',
				title: localized('CountFirstTreatments'),
				sortable: false,
				width: 200,
				getCellValue: this.getSumOfFirstTreatments,
			},
			{
				name: 'retreatments',
				title: localized('CountRetreatments'),
				sortable: false,
				width: 200,
				getCellValue: this.getSumOfRetreatments,
			},
		];
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TreatmentLogAnimalTypes);
