import { Dispatch } from 'redux';
import {
	Averted,
	EsfDailyStatus,
	EsfStatusAcknowledge,
	IEsfDailyStatus,
	IEsfFeedingStatus,
	IMoveEvent,
	IPen,
	IProcessEquipmentData,
	IShortageEsfListSetting,
	IStation,
	IStemAnimal,
	IUnitToPen,
	IVisit,
	PregnancyState,
	PregnancyValidationType,
	Scanned,
	ScanResult,
	ShortageEsfListSetting,
	WorkListType,
} from 'shared/api/api';
import { areDatesEqual, getHourDifference } from 'shared/helpers/date-helpers';
import { calculateDays, setTwoDecimalsAsString } from 'shared/helpers/general-helpers';
import { getStableSectionStringByPenId, ILocationModel } from 'shared/helpers/location-helper';
import {
	memoizeGetESFProcessEquipment,
	memoizeLocation,
	memoizeValidationSettingPlan,
} from 'shared/helpers/memoize-getters/memoize-getters';
import { NaturalSortDates } from 'shared/helpers/natural-sort';
import { sortPregnanciesByDate } from 'shared/helpers/pregnancy-helper/sort-pregnancies';
import * as EsfDailyStatusOperations from 'shared/state/ducks/esf-daily-status/operations';
import { SaveEsfDailyStatus } from 'shared/state/ducks/esf-daily-status/operations';
import * as EsfStatusOperations from 'shared/state/ducks/esf-status/operations';
import { AcknowledgeFeedingStatus } from 'shared/state/ducks/esf-status/operations';
import { GetLocations } from 'shared/state/ducks/locations/operations';
import * as moveEventOperation from 'shared/state/ducks/move-events/operations';
import * as pregnancyOperation from 'shared/state/ducks/pregnancy-events/operations';
import { GetProcessEquipmentDataSyncData } from 'shared/state/ducks/process-equipment-data/operations';
import * as StationOperations from 'shared/state/ducks/station/operations';
import * as UnitsToPenOperations from 'shared/state/ducks/unit-to-pen/operations';
import * as validationSetupOperation from 'shared/state/ducks/validation-setup/operations';
import { GetSyncData } from 'shared/state/ducks/work-list-settings/operations';
import { StoreContainer } from 'shared/state/store-container';
import { SharedAppState } from 'shared/state/store.shared';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';

export class ShortageEsfWorkListItem {
	public stemAnimalId: string | undefined;
	public cycleDays: number | undefined;
	public animalNumber: string | undefined;
	public esfDailyStatusToday: IEsfFeedingStatus | undefined;
	public restToday: string | undefined;
	public esfDailyStatusYesterday: IEsfDailyStatus | undefined;
	public restYesterday: string | undefined;
	public isChecked: boolean = false;
	public siteId: string | undefined;
	public eatenToday: string | undefined;
	public curveToday: string | undefined;
	public eatenYesterday: string | undefined;
	public curveYesterday: string | undefined;
	public isDataValid: boolean = true;
	public feedingStatusId: string | undefined;
	public currentLocation: string | undefined;
	public order: number | undefined;
}

export class LastFourVisitEsfWorkListItem {
	public stemAnimalId: string | undefined;
	public animalNumber: string | undefined;
	public selectedFeedCurveNumber: number | undefined;
	public fixedPercentage: string | undefined;
	public visits: Array<IVisit | undefined> = [];
	public isDataValid: boolean = true;
	public feedingStatusId: string | undefined;
	public penId: string | undefined;
}

export interface ShortageEsfWorkListPropsFromParent {
	sectionId: string | undefined;
	penId: string | undefined;
	esfLocationsWithSettedPens: IProcessEquipmentData[];
	rowSelected: (item: ShortageEsfWorkListItem) => void;
	navigation: NativeStackNavigationProp<{}>;
}

export interface ShortageEsfListState {
	sectionId?: string;
	selectedItem?: ShortageEsfWorkListItem;
	usesPens: boolean;
	isModalOpen?: boolean;
	penId?: string;
	stations: string[];
	filteredLocations?: ILocationModel;
}

export const ShortageEsfListMapStateToProps = (state: SharedAppState) => {
	return {
		workListSettings: state.workListSettings.entities.find(
			setup => setup.type === WorkListType.ShortageEsfListSetting,
		)! as ShortageEsfListSetting,
		siteId: state.profile.active!.siteId!,
		stemAnimals: state.stemAnimals.entities,
		validationSettingPlan: memoizeValidationSettingPlan(
			state.validationSetup.entity,
			PregnancyValidationType.MatingToFarrowing,
		),
		unitToPens: state.unitToPenData.data,
		processEquipmentData: memoizeGetESFProcessEquipment(state.processEquipmentData.entities),
		locations: memoizeLocation(
			state.locations.buildings,
			state.locations.sections,
			state.locations.pens,
			state.locations.valves,
		),
		moveEvents: state.moveEvents.entities,
		esfStatusData: state.esfStatus.data,
		stations: state.station.data,
		esfDailyStatusData: state.esfDailyStatus.entities,
	};
};

export const ShortageEsfListMapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	saveDailyStatus: (dailyStatus: EsfDailyStatus) => SaveEsfDailyStatus(dailyStatus)(dispatch),
	acknowledgeFeedStatusDate: (acknowledge: EsfStatusAcknowledge) => AcknowledgeFeedingStatus(acknowledge)(dispatch),
	getWorkListSettingsBySiteId: () => GetSyncData()(dispatch),
	pregnancyEventGetSyncData: () => pregnancyOperation.GetSyncData()(dispatch),
	validationSetupGetSyncData: () => validationSetupOperation.GetSyncData()(dispatch),
	moveEventGetSyncData: () => moveEventOperation.GetSyncData()(dispatch),
	getLocations: () => GetLocations()(dispatch),
	getFeedingCurves: () => EsfStatusOperations.GetEsfFeedCurveSyncData()(dispatch),
	getFeedingStatus: () => EsfStatusOperations.GetEsfFeedingStatusSyncData()(dispatch),
	getDailyStatus: () => EsfDailyStatusOperations.GetEsfDailySyncData()(dispatch),
	GetProcessEquipmentDataSyncData: () => GetProcessEquipmentDataSyncData()(dispatch),
	getunitsData: () => UnitsToPenOperations.GetSyncData()(dispatch),
	GetStationSyncData: () => StationOperations.GetStationSyncData()(dispatch),
});

export type ShortageEsfListProps = ReturnType<typeof ShortageEsfListMapStateToProps> &
	ReturnType<typeof ShortageEsfListMapDispatchToProps> & { navigation?: any };

function validateFeedStatus(esfStatusItem: IEsfFeedingStatus, workListSettings: IShortageEsfListSetting) {
	return !!(
		esfStatusItem &&
		!areDatesEqual(new Date(), esfStatusItem.acknowledgeDate) &&
		!(!esfStatusItem.visit1 || !esfStatusItem.visit1.date) &&
		workListSettings.feedRationPercentUnfed &&
		workListSettings.today &&
		esfStatusItem.feedToday !== undefined &&
		esfStatusItem.feedCurveAmount &&
		(esfStatusItem.feedToday / esfStatusItem.feedCurveAmount) * 100 < workListSettings.feedRationPercentUnfed
	);
}

function validateDailyStatus(yesterdayStatus: IEsfDailyStatus | undefined, workListSettings: IShortageEsfListSetting) {
	let calculated = yesterdayStatus
		? yesterdayStatus.totalEnergyDosed && yesterdayStatus.totalEnergyDosed > 0.01
			? yesterdayStatus.calculatedFeedToday
			: yesterdayStatus.feedToday
		: 0;

	if (
		yesterdayStatus &&
		!yesterdayStatus.acknowledged &&
		workListSettings.feedRationPercentUnfed &&
		yesterdayStatus &&
		workListSettings.yesterday &&
		calculated !== undefined &&
		yesterdayStatus.feedCurveAmount &&
		(calculated / yesterdayStatus.feedCurveAmount) * 100 < workListSettings.feedRationPercentUnfed
	) {
		return true;
	}

	return false;
}

function getAnimalsOnLocation(penIds: string[], moveEvents: IMoveEvent[], stemAnimals: IStemAnimal[]) {
	let stemEntrancePen = stemAnimals
		.filter(
			stem =>
				moveEvents.findIndex(e => e.stemAnimalId === stem.id) === -1 &&
				stem.entrancePenId &&
				penIds.includes(stem.entrancePenId),
		)
		.map(m => m.id);
	let movedToLocation = moveEvents
		.filter(moveEvent => moveEvent.penId && penIds.includes(moveEvent.penId))
		.map(m => m.stemAnimalId);

	return stemEntrancePen.concat(movedToLocation);
}

export function getShortageEsfData(
	stemAnimals: IStemAnimal[],
	unitToPens: IUnitToPen[],
	selectedSectionId: string | undefined,
	moveEvents: IMoveEvent[],
	esfFeedingStatus: IEsfFeedingStatus[],
	esfFeedingDailyStatus: IEsfDailyStatus[],
	esfStations: IStation[],
	esfLocationsWithSettedPens: IProcessEquipmentData[],
	siteId: string,
	workListSettings: IShortageEsfListSetting,
	validationSettings: number,
) {

	esfFeedingDailyStatus = esfFeedingDailyStatus.sort(
		(esfDailyStatusA, esfDailyStatusB) => esfDailyStatusB.date.getTime() - esfDailyStatusA.date.getTime(),
	);

	let data: ShortageEsfWorkListItem[] = [];
	const esfLocations = selectedSectionId
		? esfLocationsWithSettedPens
				.filter(esfLoc => esfLoc.sectionId === selectedSectionId && esfLoc.id !== undefined)
				.map(esfLocWithSettedPens => esfLocWithSettedPens.id)
		: esfLocationsWithSettedPens.map(esfLocWithSettedPens => esfLocWithSettedPens.id);

	let utps = unitToPens.filter(utp => esfLocations.includes(utp.processEquipmentDataId));

	let animalIds = getAnimalsOnLocation(
		utps.map(utp => utp.penId!),
		moveEvents,
		stemAnimals,
	);

	const animals = stemAnimals.filter(stem => animalIds.includes(stem.id));
	let today = new Date();
	const moved = moveEvents.dictionaryBy('stemAnimalId');
	const daily = esfFeedingDailyStatus.dictionaryBy('stemAnimalId');

	esfFeedingStatus.forEach((esfStatusItem, index) => {
		if (!(!esfStatusItem.visit1 || !esfStatusItem.visit1.date)) {
		let animalId = esfStatusItem.stemAnimalId;
		let animal = animals.find(a => a.id === animalId);

			if (!animal || !animal.id ) {
				return;
			}
	
			let moveEvents = moved[animal.id];

			let moveEvent = moveEvents ? moveEvents[0] : undefined;
			const penId = moveEvent ? moveEvent.penId : animal.entrancePenId;

			if (!utps.some(utp => utp.penId === penId)) {
				return;
			}
			const dailyStem = daily[animal.id] ? daily[animal.id] : [];
			let yesterdayStatus = dailyStem.find(
				esfDailyStatus =>
					esfDailyStatus.stemAnimalId === animalId &&
					esfDailyStatus.date &&
					getHourDifference(esfDailyStatus.date, today) < 48,
			);

			if (
				(validateFeedStatus(esfStatusItem, workListSettings) ||
					validateDailyStatus(yesterdayStatus, workListSettings))
			) {
				let item: ShortageEsfWorkListItem = setShortageItem(
					esfStatusItem,
					animal,
					siteId,
					yesterdayStatus,
					workListSettings,
					validationSettings,
					penId,
				);
				//Only show Todays data, if it's legit
				validateShortageItem(item, esfStatusItem);
				data.push(item);
			}
		}
	});
	return {
		data: data,
	};
}

function validateShortageItem(item: ShortageEsfWorkListItem, esfStatusItem: IEsfFeedingStatus) {
	if (areDatesEqual(new Date(), esfStatusItem.modifiedOn)) {
		item.restToday =
			esfStatusItem.feedCurveAmount && esfStatusItem.feedToday !== undefined
				? setTwoDecimalsAsString(esfStatusItem.feedCurveAmount - esfStatusItem.feedToday)
				: setTwoDecimalsAsString(esfStatusItem.feedCurveAmount);

		item.esfDailyStatusToday = esfStatusItem;
		item.eatenToday = setTwoDecimalsAsString(esfStatusItem && esfStatusItem.feedToday);
	} else {
		item.isDataValid = false;
	}
}

function setShortageItem(
	esfStatusItem: IEsfFeedingStatus,
	animal: IStemAnimal,
	siteId: string,
	yesterdayStatus: IEsfDailyStatus | undefined,
	workListSettings: IShortageEsfListSetting,
	validationSettings: number,
	penId: string | undefined,
) {
	let calculated = yesterdayStatus
		? yesterdayStatus.totalEnergyDosed && yesterdayStatus.totalEnergyDosed > 0.01
			? yesterdayStatus.calculatedFeedToday
			: yesterdayStatus.feedToday
		: 0;

	return {
		stemAnimalId: animal.id,
		feedingStatusId: esfStatusItem.id,
		cycleDays: getCycleDays(animal.id!, workListSettings, validationSettings),
		animalNumber: animal.animalNumber,
		restToday: undefined,
		esfDailyStatusToday: undefined,
		restYesterday: yesterdayStatus
			? yesterdayStatus.feedCurveAmount && calculated
				? setTwoDecimalsAsString(yesterdayStatus.feedCurveAmount - calculated)
				: setTwoDecimalsAsString(yesterdayStatus.feedCurveAmount)
			: undefined,
		esfDailyStatusYesterday: yesterdayStatus,
		isChecked: false,
		siteId: siteId,
		eatenToday: undefined,
		curveToday: setTwoDecimalsAsString(esfStatusItem && esfStatusItem.feedCurveAmount),
		eatenYesterday: setTwoDecimalsAsString(yesterdayStatus && calculated),
		curveYesterday: setTwoDecimalsAsString(yesterdayStatus && yesterdayStatus.feedCurveAmount),
		isDataValid: true,
		currentLocation: getStableSectionStringByPenId(penId),
	} as ShortageEsfWorkListItem;
}

export function getLastFourVisits(
	stemAnimals: IStemAnimal[],
	unitToPens: IUnitToPen[],
	pens: IPen[],
	moveEvents: IMoveEvent[],
	esfFeedingStatus: IEsfFeedingStatus[],
	stemAnimalId: string | undefined,
	esfLocationsWithSettedPens: IProcessEquipmentData[],
) {
	let item: LastFourVisitEsfWorkListItem | undefined;

	const animal = stemAnimals.find(stem => stemAnimalId && stem.id === stemAnimalId);

	let esfStatusItem = esfFeedingStatus.find(esf => esf.stemAnimalId === stemAnimalId);
	if (animal && esfStatusItem) {
		let moveEvent = moveEvents.find(moveEvent => animal && moveEvent.stemAnimalId === animal.id);
		const penId = moveEvent ? moveEvent.penId : animal.entrancePenId;
		const pen = pens.find(p => p.id === penId);
		const esfLocations = pen
			? esfLocationsWithSettedPens
					.filter(esfLoc => esfLoc.sectionId === pen.sectionId && esfLoc.id !== undefined)
					.map(esfLocWithSettedPens => esfLocWithSettedPens.id)
			: esfLocationsWithSettedPens.map(esfLocWithSettedPens => esfLocWithSettedPens.id);

		let utps = unitToPens.filter(utp => esfLocations.includes(utp.processEquipmentDataId));
		if (utps.some(utp => utp.penId === penId)) {
			item = {
				feedingStatusId: esfStatusItem.id,
				stemAnimalId: stemAnimalId,
				animalNumber: animal.animalNumber,
				fixedPercentage: setTwoDecimalsAsString(esfStatusItem.fixedDeviation),
				selectedFeedCurveNumber: esfStatusItem.feedCurveNumber,
				visits: [],
				isDataValid: true,
				penId: penId,
			};
			//Only show Todays data, if it's legit
			if (!areDatesEqual(new Date(), esfStatusItem.modifiedOn)) {
				item.isDataValid = false;
			}

			checkWhereToInsertVisitInArray(item.visits, esfStatusItem.visit4);
			checkWhereToInsertVisitInArray(item.visits, esfStatusItem.visit3);
			checkWhereToInsertVisitInArray(item.visits, esfStatusItem.visit2);
			checkWhereToInsertVisitInArray(item.visits, esfStatusItem.visit1);
		}
	}

	return item;
}

function checkWhereToInsertVisitInArray(visits: Array<IVisit | undefined>, visit: IVisit | undefined) {
	if (visit && visit.date && visit.stationNumber) {
		visits.unshift(visit);
	} else {
		visits.push(visit);
	}
}

export function getCycleDays(
	stemAnimalId: string,
	workListSettings: IShortageEsfListSetting,
	validationSettings: number,
) {
	const state = StoreContainer.getState();
	const pregnancyEvents = state.pregnancyEvents.entities[stemAnimalId];
	if (pregnancyEvents === undefined) {
		return undefined;
	}

	const filteredPregnancyEvents = pregnancyEvents.filter(pregEvent => {
		if (pregEvent.stemAnimalId === stemAnimalId) {
			if (pregEvent.state === PregnancyState.Scanned) {
				const scanned = pregEvent as Scanned;
				if (scanned.result !== ScanResult.False) {
					return false;
				}
			}
			if (pregEvent.state === PregnancyState.Averted) {
				const scanned = pregEvent as Averted;
				if (scanned.isNursingSow) {
					return false;
				}
			}
			return true;
		}
		return false;
	});

	const lastestPregnancyEvent = sortPregnanciesByDate(filteredPregnancyEvents)[0];
	let cycleDays = lastestPregnancyEvent ? calculateDays(lastestPregnancyEvent.date!, new Date()) : 0;
	if (lastestPregnancyEvent && lastestPregnancyEvent.state === PregnancyState.Mated) {
		cycleDays = cycleDays - (workListSettings.negativeCycleDays ? validationSettings : 0);
	}
	return cycleDays ? cycleDays : 0;
}
