import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { DiagnoseCategoryState } from './types';
import { SyncableInitialState } from 'shared/state/models/syncable';
import { getSyncData } from './actions';
import { mergeArrays, upsertValueInArray, removeValueFromArray } from 'shared/helpers/reducer-helpers';
import { saveDiagnoseCategory } from './actions';
import { IDiagnoseCategory, DiagnoseCategory } from 'shared/api/api';
import { SharedAppState } from 'shared/state/store.shared';
import { siteChange } from '../profile/actions';
import { getSyncModelData } from '../sync/actions';

export const initialDiagnoseCategoryState: DiagnoseCategoryState = {
	...SyncableInitialState,
};

const diagnoseCategoriesReducer = (
	state: DiagnoseCategoryState = initialDiagnoseCategoryState,
	action: Action,
): DiagnoseCategoryState => {
	if (isType(action, getSyncData.started)) {
		state = { ...state, syncInProgress: true };
	}

	if (isType(action, getSyncModelData.done)) {
		if (action.payload.result.diagnoseCategories && action.payload.result.diagnoseCategories.datas!.length) {
			state = {
				...state,
				entities: mergeArrays(state.entities, action.payload.result.diagnoseCategories.datas!),
				lastSyncDate: action.payload.result.diagnoseCategories.syncTime!,
			};
		}

		state = {
			...state,

			syncInProgress: false,
		};
	}

	if (isType(action, getSyncData.done)) {
		if (action.payload.result && action.payload.result.datas!.length) {
			state = {
				...state,
				entities: mergeArrays(state.entities, action.payload.result.datas!),
				lastSyncDate: action.payload.result.syncTime!,
			};
		}

		state = {
			...state,

			syncInProgress: false,
		};
	}

	if (isType(action, getSyncData.failed)) {
		state = { ...state, syncInProgress: false };
	}

	if (isType(action, saveDiagnoseCategory.started)) {
		state = { ...state, updates: upsertValueInArray(state.updates, action.payload) };

		if (action.payload.isDeleted) {
			state = { ...state, entities: removeValueFromArray(state.entities, action.payload.id!) };
		} else {
			state = { ...state, entities: upsertValueInArray(state.entities, action.payload) };
		}
	}

	if (isType(action, saveDiagnoseCategory.failed)) {
		if (action.payload.error.code === 500) {
			if (action.payload.error.prevEntity) {
				state = { ...state, entities: upsertValueInArray(state.entities, action.payload.error.prevEntity) };
			} else {
				state = { ...state, entities: removeValueFromArray(state.entities, action.payload.params.id!) };
			}
			state = { ...state, updates: removeValueFromArray(state.updates, action.payload.params.id!) };
		}

		return state;
	}

	if (isType(action, saveDiagnoseCategory.done)) {
		action.payload.params.id = action.payload.result;

		if (!action.payload.params.isDeleted) {
			state = { ...state, entities: upsertValueInArray(state.entities, action.payload.params) };
		}

		state = { ...state, updates: removeValueFromArray(state.updates, action.payload.result) };

		return state;
	}

	if (isType(action, siteChange.done)) {
		state = initialDiagnoseCategoryState;
	}

	//Ensure Date objects are in fact date and not strings - redux-persist serializes dates to string but dont' deserialize to dates again
	if (action.type === 'persist/REHYDRATE') {
		let a = ((action as any) as { payload: SharedAppState; key: string }) as any;

		if (a.key === 'root') {
			let entities = new Array<DiagnoseCategory>();
			let updates = new Array<DiagnoseCategory>();
			let lastSyncDate = new Date(-8640000000000000);

			if (a.payload && a.payload.diagnoseCategory && a.payload.diagnoseCategory.entities) {
				entities = a.payload.diagnoseCategory.entities.map((dt: IDiagnoseCategory) => {
					let ndt = DiagnoseCategory.fromJS({});
					ndt.init(dt);
					return ndt;
				});
			}

			if (a.payload && a.payload.diagnoseCategory && a.payload.diagnoseCategory.updates) {
				updates = a.payload.diagnoseCategory.updates.map((dt: IDiagnoseCategory) => {
					let ndt = DiagnoseCategory.fromJS({});
					ndt.init(dt);
					return ndt;
				});
			}

			if (a.payload && a.payload.diagnoseCategory && a.payload.diagnoseCategory.lastSyncDate) {
				lastSyncDate = new Date(a.payload.diagnoseCategory.lastSyncDate);
			}

			state = { ...state, entities, updates, lastSyncDate };
		}
	}

	return state;
};

export default diagnoseCategoriesReducer;
