import {
	ROUTE_ANIMALS,
	ROUTE_ANIMAL_REPORT,
	ROUTE_CHANGE_PASSWORD,
	ROUTE_DIAGNOSES,
	ROUTE_DIAGNOSE_CATEGORIES,
	ROUTE_DRUG_STOCK,
	ROUTE_DRUG_TYPES,
	ROUTE_EVENTS,
	ROUTE_HOME,
	ROUTE_LANGUAGE,
	ROUTE_MEDICINE_DIAGNOSES,
	ROUTE_MODULE_SETTINGS,
	ROUTE_PREGNANCY_EVENT_LISTS,
	ROUTE_PROFILE,
	ROUTE_REASONS,
	ROUTE_REASONS_SETTINGS,
	ROUTE_REGISTER_TREATMENT,
	ROUTE_SETTINGS,
	ROUTE_SIGN_IN,
	ROUTE_SIGN_OUT_REDIRECT,
	ROUTE_CALLBACK,
	ROUTE_SOW,
	ROUTE_SOW_CARD,
	ROUTE_SOW_SETTINGS,
	ROUTE_STATUS,
	ROUTE_TREATMENT_DEFINITIONS,
	ROUTE_TREATMENT_DETAILS,
	ROUTE_TREATMENT_LIST,
	ROUTE_TREATMENT_LOG,
	ROUTE_WORK_LISTS,
	ROUTE_WORK_LIST_EVENT,
	ROUTE_WORK_LIST_SETTINGS,
	RETRY_ADJUST_FEED_SOW,
	RETRY_ADJUST_FEED_PIG,
	ROUTE_FEEDING_STOCK,
	ROUTE_SIGN_IN_REDIRECT,
	ROUTE_DASHBOARD,
	ROUTE_SOW_ANALYSIS,
	ROUTE_GROWTH_PIG_EVENT_LISTS_FINISHER,
	ROUTE_DRUG_STOCK_FINISHER,
	ROUTE_DRUG_STOCK_WEANER,
	ROUTE_GROWTH_PIG_EVENTS_FINISHER,
	ROUTE_GROWTH_PIG_EVENT_LISTS_WEANER,
	ROUTE_GROWTH_PIG_EVENTS_WEANER,
	ROUTE_TREATMENT_DEFINITIONS_FINISHER,
	ROUTE_TREATMENT_DEFINITIONS_WEANER,
	ROUTE_TREATMENT_LOG_FINISHER,
	ROUTE_TREATMENT_LOG_WEANER,
	ROUTE_FEEDING_STOCK_WEANER,
	ROUTE_FEEDING_STOCK_FINISHER,
	ROUTE_GROWTH_PIG_DASHBOARD_WEANER,
	ROUTE_GROWTH_PIG_DASHBOARD_FINISHER,
	ROUTE_WEANER_SETTINGS,
	ROUTE_FINISHER_SETTINGS,
	ROUTE_FINISHER_DATA,
	ROUTE_TICAN_CALLBACK,
	ROUTE_GROWTH_PIGS_REPORTS_FINISHER,
	ROUTE_BALANCE_WEANED_PIG,
	ROUTE_GROWTH_PIGS_REPORTS_WEANER,
	ROUTE_BMP,
	ROUTE_GROWTH_PIG_SHOULD_DEPART_LIST_WEANER,
	ROUTE_GROWTH_PIG_SHOULD_DEPART_LIST_FINISHER,
	ROUTE_REGULATIONS,
	ROUTE_MODULE_LICENSES,
	ROUTE_ADMIN_FEED_REGISTRATIONS,
	ROUTE_ADMIN_BREED_TABLE,
	ROUTE_WEANER_ANALYSIS,
	ROUTE_FINISHER_ANALYSIS,
} from 'web/state/ducks/navigation/types';
import { AnimalKind, AnimalType } from 'shared/api/api';

interface RouteType {
	path: string;
	title?: string;
	global?: boolean;
	admin?: boolean;
	params?: any;
	accessAdmin?: boolean;
}

// Pages built with ReactDOM and no Native counterpart must be appended to this map,
// otherwise they'll break the native build. Additionally, there must still be a shim 'component.native.tsx'
export const Routes = {
	[ROUTE_MODULE_SETTINGS]: {
		path: '/app/settings/modules',
		title: 'Modules',
		accessAdmin: true,
	} as RouteType,
	[ROUTE_ANIMALS]: {
		path: '/app/sow/animals',
		title: 'Sow',
	} as RouteType,
	[ROUTE_REASONS]: {
		path: '/app/reasons',
		title: 'Reasons',
		admin: true,
	} as RouteType,
	[ROUTE_FINISHER_DATA]: {
		path: '/app/finisherdata',
		title: 'finisherdata',
	} as RouteType,
	[ROUTE_TICAN_CALLBACK]: {
		path: '/app/settings/ticancallback',
		title: 'Tican callback',
	} as RouteType,
	[ROUTE_REASONS_SETTINGS]: {
		path: '/app/reasonsettings',
		title: 'Reasons Settings',
		admin: true,
	} as RouteType,
	[ROUTE_TREATMENT_DEFINITIONS]: {
		path: '/app/treatmentdefinitions',
		title: 'Treatment Definitions',
		params: { type: AnimalType.Sow },
	} as RouteType,
	[ROUTE_SOW_SETTINGS]: {
		path: '/app/settings',
		title: 'Sow Settings',
		params: { selected: 'sow' },
	} as RouteType,
	[ROUTE_MEDICINE_DIAGNOSES]: {
		path: '/app/medicine-diagnoses',
		title: 'Medicine / Diagnoses',
	} as RouteType,
	[ROUTE_DASHBOARD]: {
		global: true,
		path: '/app/dashboard',
		title: 'Sow dashboard',
	} as RouteType,
	[ROUTE_WORK_LIST_SETTINGS]: {
		path: '/app/work-list-setting',
		title: 'Work list setting',
	} as RouteType,
	[ROUTE_HOME]: {
		path: '/app/home',
		global: true,
		title: 'Home',
	} as RouteType,
	[ROUTE_SOW]: {
		path: '/app/sow',
		title: 'Sow',
	} as RouteType,
	[ROUTE_WORK_LIST_EVENT]: {
		path: '/app/sow/work-list/farrowing',
		title: 'Farrowing',
	} as RouteType,
	[RETRY_ADJUST_FEED_SOW]: {
		path: '/app/adjust-feed/retry',
		title: 'retryAdjustFeed',
		params: { type: [AnimalType.Sow] },
	} as RouteType,
	[RETRY_ADJUST_FEED_PIG]: {
		path: '/app/adjust-feed/retry',
		title: 'retryAdjustFeed',
		params: { type: [AnimalType.Weaner, AnimalType.Finisher, AnimalType.FRATS] },
	} as RouteType,
	[ROUTE_REGISTER_TREATMENT]: {
		path: '/app/create-treatment-plan',
		title: 'Create Treatment',
	} as RouteType,
	[ROUTE_TREATMENT_LIST]: {
		path: '/app/sow/treatment-list',
		title: 'Treatment List',
	} as RouteType,
	[ROUTE_TREATMENT_DETAILS]: {
		path: '/app/sow/treatment-list/building/details',
		title: 'Treatment Details',
	} as RouteType,
	[ROUTE_SOW_CARD]: {
		path: '/app/sow-card',
		title: 'Sow Card',
	} as RouteType,
	[ROUTE_EVENTS]: {
		path: '/app/sow/events',
		title: 'Events',
	} as RouteType,
	[ROUTE_BALANCE_WEANED_PIG]: {
		path: '/app/sow/balanceWeanedPigs',
		title: 'balanceWeanedPigs',
	} as RouteType,
	[ROUTE_PROFILE]: {
		path: '/app/profile',
		title: 'Profile',
		global: true,
	} as RouteType,
	[ROUTE_LANGUAGE]: {
		path: '/app/profile/language',
		global: true,
	} as RouteType,
	[ROUTE_STATUS]: {
		path: '/app/status',
		title: 'Status',
		params: { dashboard: 'Status' },
	} as RouteType,
	[ROUTE_DRUG_TYPES]: {
		path: '/app/drugtypes',
		title: 'DrugTypes',
		admin: true,
	} as RouteType,
	[ROUTE_DRUG_STOCK]: {
		path: '/app/drugStock',
		title: 'Drug Stock',
		params: { type: AnimalKind.Sow },
	} as RouteType,
	[ROUTE_FEEDING_STOCK]: {
		path: '/app/feedingStock',
		title: 'Feeding Stock',
		params: { type: [AnimalType.Sow] },
	} as RouteType,
	[ROUTE_FEEDING_STOCK_FINISHER]: {
		path: '/app/finisher/feedingStock',
		title: 'Feeding Stock',
		params: { type: AnimalType.Finisher },
	} as RouteType,
	[ROUTE_FEEDING_STOCK_WEANER]: {
		path: '/app/weaner/feedingStock',
		title: 'Feeding Stock',
		params: { type: AnimalType.Weaner },
	} as RouteType,
	[ROUTE_DRUG_STOCK_FINISHER]: {
		path: '/app/finisher/drugStock',
		title: 'Drug Stock',
		params: { type: AnimalKind.Finisher },
	} as RouteType,
	[ROUTE_DRUG_STOCK_WEANER]: {
		path: '/app/weaner/drugStock',
		title: 'Drug Stock',
		params: { type: AnimalKind.Weaner },
	} as RouteType,
	[ROUTE_TREATMENT_LOG_FINISHER]: {
		path: '/app/pig/treatmentLog',
		title: 'Treatment Log',
		params: { type: AnimalType.Finisher },
	} as RouteType,
	[ROUTE_TREATMENT_LOG_WEANER]: {
		path: '/app/pig/treatmentLog',
		title: 'Treatment Log',
		params: { type: AnimalType.Weaner },
	} as RouteType,
	[ROUTE_TREATMENT_DEFINITIONS_FINISHER]: {
		path: '/app/finisher/treatmentdefinitions',
		title: 'Treatment Definitions',
		params: { type: AnimalType.Finisher },
	} as RouteType,
	[ROUTE_TREATMENT_DEFINITIONS_WEANER]: {
		path: '/app/weaner/treatmentdefinitions',
		title: 'Treatment Definitions',
		params: { type: AnimalType.Weaner },
	} as RouteType,
	[ROUTE_PREGNANCY_EVENT_LISTS]: {
		path: '/app/pregnancyEventLists',
		title: 'pregnancyEventLists',
	} as RouteType,
	[ROUTE_GROWTH_PIG_EVENT_LISTS_FINISHER]: {
		path: '/app/finisher/eventList',
		title: 'growthPigEventList',
		params: { type: AnimalType.Finisher },
	} as RouteType,
	[ROUTE_GROWTH_PIG_EVENTS_FINISHER]: {
		path: '/app/finisher/events',
		title: 'growthPigEvents',
		params: { type: AnimalType.Finisher },
	} as RouteType,
	[ROUTE_GROWTH_PIG_EVENT_LISTS_WEANER]: {
		path: '/app/weaner/eventList',
		title: 'growthPigEventList',
		params: { type: AnimalType.Weaner },
	} as RouteType,
	[ROUTE_GROWTH_PIG_EVENTS_WEANER]: {
		path: '/app/weaner/events',
		title: 'growthPigEvents',
		params: { type: AnimalType.Weaner },
	} as RouteType,
	[ROUTE_GROWTH_PIG_DASHBOARD_WEANER]: {
		path: '/app/weaner/dashboard',
		title: 'weanerDashboard',
		params: { type: AnimalType.Weaner },
	} as RouteType,
	[ROUTE_GROWTH_PIG_DASHBOARD_FINISHER]: {
		path: '/app/finisher/dashboard',
		title: 'finisherDashboard',
		params: { type: AnimalType.Finisher },
	} as RouteType,
	[ROUTE_DIAGNOSE_CATEGORIES]: {
		path: '/app/diagnoseCategories',
		title: 'Diagnose categories',
		admin: true,
	} as RouteType,
	[ROUTE_DIAGNOSES]: {
		path: '/app/diagnoses',
		title: 'Diagnoses',
		admin: true,
	} as RouteType,
	[ROUTE_TREATMENT_LOG]: {
		path: '/app/treatmentLog',
		title: 'Treatment Log',
		params: { type: AnimalType.Sow },
	} as RouteType,
	[ROUTE_SETTINGS]: {
		path: '/app/settings',
		title: 'Settings',
		accessAdmin: true,
		params: { type: AnimalType.Sow },
	} as RouteType,
	[ROUTE_CHANGE_PASSWORD]: {
		path: '/app/changepassword',
		global: true,
		title: 'Change Password',
	} as RouteType,
	[ROUTE_SIGN_IN]: {
		path: '/app/signin',
		global: true,
		title: 'Sign In',
	} as RouteType,
	[ROUTE_SIGN_IN_REDIRECT]: {
		path: '/app/signinRedirect',
		global: true,
		title: 'Sign In',
	} as RouteType,
	[ROUTE_SIGN_OUT_REDIRECT]: {
		path: '/app/signoutRedirect',
		global: true,
		title: 'Sign Out',
	} as RouteType,
	[ROUTE_CALLBACK]: {
		path: '/app/callback',
		global: true,
		title: 'Callback',
	} as RouteType,
	[ROUTE_WORK_LISTS]: {
		path: '/app/work-list',
		title: 'Work list',
	} as RouteType,
	[ROUTE_ANIMAL_REPORT]: {
		path: '/app/animal-report',
		title: 'Animal report',
	} as RouteType,
	[ROUTE_SOW_ANALYSIS]: {
		path: '/app/sow-analysis',
		title: 'Sow analysis',
		params: { type: AnimalType.Sow },
	} as RouteType,
	[ROUTE_WEANER_ANALYSIS]: {
		path: '/app/weaner-analysis',
		title: 'Weaner analysis',
		params: { type: AnimalType.Weaner },
	},
	[ROUTE_FINISHER_ANALYSIS]: {
		path: '/app/finisher-analysis',
		title: 'Finisher analysis',
		params: { type: AnimalType.Finisher },
	},
	[ROUTE_WEANER_SETTINGS]: {
		path: '/app/settings',
		title: 'Settings',
		accessAdmin: true,
		params: { type: AnimalType.Weaner },
	} as RouteType,
	[ROUTE_FINISHER_SETTINGS]: {
		path: '/app/settings',
		title: 'Settings',
		accessAdmin: true,
		params: { type: AnimalType.Finisher },
	} as RouteType,
	[ROUTE_GROWTH_PIGS_REPORTS_FINISHER]: {
		path: '/app/finisher/reports',
		title: 'Reports',
		params: { type: AnimalType.Finisher },
	} as RouteType,
	[ROUTE_GROWTH_PIGS_REPORTS_WEANER]: {
		path: '/app/weaner/reports',
		title: 'Reports',
		params: { type: AnimalType.Weaner },
	} as RouteType,
	[ROUTE_BMP]: {
		path: '/app/bmp',
		title: 'BMP',
		accessAdmin: true,
	} as RouteType,
	[ROUTE_GROWTH_PIG_SHOULD_DEPART_LIST_WEANER]: {
		path: '/app/weaner/should-depart-list',
		title: 'Departure List',
		params: { type: AnimalType.Weaner },
	} as RouteType,
	[ROUTE_GROWTH_PIG_SHOULD_DEPART_LIST_FINISHER]: {
		path: '/app/finisher/should-depart-list',
		title: 'Departure List',
		params: { type: AnimalType.Finisher },
	} as RouteType,
	[ROUTE_REGULATIONS]: {
		path: '/app/regulations',
		title: 'Regulations',
		admin: true,
	},
	[ROUTE_ADMIN_FEED_REGISTRATIONS]: {
		path: '/app/feedregistrations',
		title: 'Feed registrations',
		admin: true,
	} as RouteType,
	[ROUTE_ADMIN_BREED_TABLE]: {
		path: '/app/breed-table',
		title: 'Breed table',
		admin: true,
	} as RouteType,
	[ROUTE_MODULE_LICENSES]: {
		path: '/app/licences',
		title: 'Module licences',
		admin: true,
	},
};

export type RouteKey = keyof typeof Routes;
