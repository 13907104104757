import memoize from 'memoize-one';
import React from 'react';
import { Option } from 'react-dropdown';
import isEqual from 'react-fast-compare';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Gender, IPregnancyTableModel, IStemAnimal } from 'shared/api/api';
import dead_piglets_icon from 'shared/assets/src-assets/png/dead_piglets.png';
import family_tree from 'shared/assets/src-assets/png/family_tree.svg';
import lard_list_icon from 'shared/assets/src-assets/png/lard_list_grey.png';
import medicin_list_icon from 'shared/assets/src-assets/png/medicin_list_grey.png';
import { getDateString } from 'shared/helpers/date-helpers';
import { getLocationString } from 'shared/helpers/location-helper';
import { findBatch } from 'shared/helpers/matingbatch-helper';
import { memoizeAllMoveEvents, memoizeAllPregnancyEvents } from 'shared/helpers/memoize-getters/memoize-getters';
import { sortPregnanciesByDate } from 'shared/helpers/pregnancy-helper/sort-pregnancies';
import {
	calculateSowCardAvg,
	calculateSowCardWeightAvg,
	generateSowCardData,
	getPigYearSow,
	isEventEditable,
} from 'shared/helpers/sow-card-helper/sow-card-helper';
import { ISowCardComplete } from 'shared/helpers/sow-card-helper/sow-card-table-model';
import { findAnimalById } from 'shared/helpers/stemanimal-helper/stem-animal-input-helper';
import { GetSyncData as GetDeadPigletsEventsSyncData } from 'shared/state/ducks/dead-piglets-event/operations';
import { GetSyncData as DiagnoseGetSyncData } from 'shared/state/ducks/diagnoses/operations';
import { GetSyncData as LardScanningEventsGetSyncData } from 'shared/state/ducks/lardScanningEvents/operations';
import { GetLocations } from 'shared/state/ducks/locations/operations';
import { GetSyncData as GetMoveEventSyncData } from 'shared/state/ducks/move-events/operations';
import { GetSyncData as PregnancyEventGetSyncData } from 'shared/state/ducks/pregnancy-events/operations';
import { selectNucleusManagementOrAssignIdAccess } from 'shared/state/ducks/site/reducer';
import {
	FetchSingleAnimalInfo,
	GetSyncData as StemAnimalGetSyncData,
	SaveSow,
} from 'shared/state/ducks/stem-animals/operations';
import { GetSyncData as TreatmentDefinitionGetSyncData } from 'shared/state/ducks/treatment-definitions/operations';
import {
	GetSyncData as TreatmentPlanGetSyncData,
	GetTreatmentPlansByStemAnimalId,
} from 'shared/state/ducks/treatment-plan/operations';
import { GetSyncData as TreatmentGetSyncData, GetTreatmentsByAnimalId } from 'shared/state/ducks/treatment/operations';
import { localized, localizedDynamic } from 'shared/state/i18n/i18n';
import { DepartureTypes } from 'shared/state/models/departure-types';
import { WebAppState } from 'web/state/store.web';
import { FamilyTreeOverview } from 'web/view/components/family-tree/family-tree-overview';
import PageContainer from 'web/view/components/page-container/page-container';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldCheckbox } from 'web/view/components/skiold-components/skiold-checkbox/skiold-checkbox';
import { SkioldUnderlineIntergerInput } from 'web/view/components/skiold-components/skiold-integer-input/skiold-underline-interger-input';
import { SkioldModal } from 'web/view/components/skiold-components/skiold-modal/skiold-modal';
import 'web/view/components/skiold-components/skiold-table/skiold-double-header-table.scss';
import SkioldTableGrid from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { SkioldTextArea } from 'web/view/components/skiold-components/skiold-text-area/skiold-text-area';
import { SkioldTouchableOpacity } from 'web/view/components/skiold-components/skiold-touchable-opacity';
import { SowListConstants } from 'web/view/components/stem-animal/animal-lists/table-constants';
import GetRetentionTime from 'web/view/components/stem-animal/sow-card/get-retention-time';
import SowCardDeadPigletsEvents from 'web/view/components/stem-animal/sow-card/sow-card-dead-piglet';
import SowCardLardEvents from 'web/view/components/stem-animal/sow-card/sow-card-lard-events';
import SowCardTreatment from 'web/view/components/stem-animal/sow-card/sow-card-treatments';
import StemAnimalInput from 'web/view/components/stem-animal/stem-animal-input/stem-animal-input';
import { WhiteText } from 'web/view/components/Text/white-text';
import { Heading } from 'web/view/components/utils/heading';
import { SkioldImage } from 'web/view/components/utils/svg/skiold-image';
import { ViewWeb } from 'web/view/components/utils/web-view';
import 'web/view/pages/sow-card/sow-card-integer-input-override.scss';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import SowEvents from '../sow-events/sow-events';
import './sow-card.scss';

interface PropsFromParent {
	stemAnimalIdFromParent?: string;
	close?: () => void;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		siteId: state.profile.active!.siteId,
		profile: state.profile.active!,
		pregnancyEvents: memoizeAllPregnancyEvents(
			state.pregnancyEvents.entities,
			state.pregnancyEvents.departuredPregnancies,
		),
		location: state.locations,
		stemAnimalId: state.navigation.query && state.navigation.query.stemanimal,
		validationSetup: state.validationSetup.entity,
		moveEvents: memoizeAllMoveEvents(state.moveEvents.entities, state.moveEvents.departuredMoveEvents),
		stemAnimals: state.stemAnimals.entities, //for render purposes,
		matingbatches: state.matingBatches.entities, //for render purposes
		deadPiglets: state.deadPigletsEvents.entities,
		nucleusManagement: selectNucleusManagementOrAssignIdAccess(state),
		generalSettings: state.generalSettings.entity,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
	return {
		loadBuildings: () => GetLocations()(dispatch),
		pregnancyEventGetSyncData: () => PregnancyEventGetSyncData()(dispatch),
		updateSow: (sow: IStemAnimal) => SaveSow(sow)(dispatch),
		getDiagnoses: () => DiagnoseGetSyncData()(dispatch),
		getTreatmentPlans: () => TreatmentPlanGetSyncData()(dispatch),
		getTreatments: () => TreatmentGetSyncData()(dispatch),
		treatmentDefinitionGetSyncData: () => TreatmentDefinitionGetSyncData()(dispatch),
		getMoveEventSyncData: () => GetMoveEventSyncData()(dispatch),
		getStemAnimalSyncData: () => StemAnimalGetSyncData()(dispatch),
		getTreatmentsByStemAnimalId: (stemAnimalId: string) => GetTreatmentsByAnimalId(stemAnimalId)(dispatch),
		getTreatmentPlansByStemAnimalId: (stemAnimalId: string) =>
			GetTreatmentPlansByStemAnimalId(stemAnimalId)(dispatch),
		getLardScanningEventsSyncData: () => LardScanningEventsGetSyncData()(dispatch),
		getDeadPigletsEventsSyncData: () => GetDeadPigletsEventsSyncData()(dispatch),
		fetchSingleAnimalInfo: (stemAnimalId: string) => FetchSingleAnimalInfo(stemAnimalId)(dispatch),
	};
};

export interface State {
	sow: IStemAnimal | undefined;
	animalNumber: string | undefined;
	editModalOpen: boolean;
	treatmentsModalOpen: boolean;
	familyTreeModalOpen: boolean;
	lardWidthsModalOpen: boolean;
	deadPigletsModalOpen: boolean;
	pregnancyIdToEdit: string;
	eventOption: Option;
	columns: any[];
	columnsExte: any[];
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;
class SowCard extends React.PureComponent<Props, State> {
	private generateColumnExte() {
		return [
			{
				columnName: 'litter',
				width: SowListConstants.cycleDaysWidth,
			},
			{
				columnName: 'matedDate',
				width: SowListConstants.dateWidth,
			},
			{
				columnName: 'boar',
				filteringEnabled: false,
				width: SowListConstants.boarWidth,
			},
			{
				columnName: 'k',
				filteringEnabled: false,
				width: SowListConstants.iconWidth,
			},
			{
				columnName: 'pregnant',
				filteringEnabled: false,
				width: SowListConstants.pregnantWidth,
			},
			{
				columnName: 'pregnantDays',
				filteringEnabled: false,
				width: SowListConstants.pregnantDaysWidth,
				usesSummary: true,
			},
			{
				columnName: 'farrowingDate',
				filteringEnabled: false,
				width: SowListConstants.dateWidth,
			},
			{
				columnName: 'alive',
				filteringEnabled: false,
				width: SowListConstants.aliveWidth,
				usesSummary: true,
			},
			{
				columnName: 'stillBorn',
				filteringEnabled: false,
				width: SowListConstants.deadWidth,
				usesSummary: true,
			},
			{
				columnName: 'abortion',
				filteringEnabled: false,
				width: SowListConstants.iconWidth,
			},
			{
				columnName: 'totalDead',
				filteringEnabled: false,
				width: SowListConstants.deadWidth,
				usesSummary: true,
			},
			{
				columnName: 'NursingDays',
				filteringEnabled: false,
				width: SowListConstants.nursingDaysWidth,
				usesSummary: true,
			},
			{
				columnName: 'WeaningDate',
				filteringEnabled: false,
				width: SowListConstants.dateWidth,
			},
			{
				columnName: 'pcs',
				filteringEnabled: false,
				width: SowListConstants.iconWidth,
				usesSummary: true,
			},
			{
				columnName: 'kg',
				filteringEnabled: false,
				width: SowListConstants.iconWidth,
				usesSummary: true,
			},
			{
				columnName: 'isNursingSow',
				filteringEnabled: false,
				width: SowListConstants.iconWidth,
			},
			{
				columnName: 'wasteDaysHeader',
				filteringEnabled: false,
				width: SowListConstants.wasteDaysWidth,
				usesSummary: true,
			},
			{
				columnName: 'pigYearSow',
				filteringEnabled: false,
				width: SowListConstants.pigYearSowWidth,
				usesSummary: true,
			},
		];
	}
	private generateData = memoize((animals, pregnancies, sow, deadPiglets) => this.getSowData(this.state.sow));
	private data: IPregnancyTableModel[] = [];

	constructor(props: Props) {
		super(props);

		this.state = {
			sow: undefined,
			animalNumber: undefined,
			editModalOpen: false,
			lardWidthsModalOpen: false,
			treatmentsModalOpen: false,
			familyTreeModalOpen: false,
			pregnancyIdToEdit: '',
			eventOption: { label: '', value: '' },
			columns: [],
			columnsExte: this.generateColumnExte(),
			deadPigletsModalOpen: false,
		};
	}

	public componentDidMount() {
		const stemAnimalId =
			this.props.stemAnimalIdFromParent !== undefined
				? this.props.stemAnimalIdFromParent
				: this.props.stemAnimalId;
		if (stemAnimalId) {
			this.props.fetchSingleAnimalInfo(stemAnimalId).then(() => {
				this.setAnimal(stemAnimalId);
			});
		}

		this.setAnimal(stemAnimalId);
	}

	public componentDidUpdate(prevProps: Props, prevState: State) {
		if (this.state.sow && !isEqual(prevProps.stemAnimals, this.props.stemAnimals) && this.state.sow.id) {
			this.setAnimal(this.state.sow.id);
		}
		if (this.state.sow?.id && this.state.sow?.id !== prevState.sow?.id) {
			this.props.fetchSingleAnimalInfo(this.state.sow.id).then(() => {
				this.setAnimal(this.state.sow?.id);
			});
		}
	}

	public setAnimal(stemAnimalId: string | undefined) {
		if (stemAnimalId) {
			const sow = findAnimalById(stemAnimalId);
			if (sow) {
				this.props.getTreatmentPlansByStemAnimalId(sow.id!);
				this.props.getTreatmentsByStemAnimalId(sow.id!);
				this.setState({ animalNumber: sow.animalNumber });
				this.animalChanged(sow);
			}
		}
	}

	public animalChanged = (sow: IStemAnimal | undefined) => {
		this.setState({ sow });
	};

	private groupedColumns = [
		{
			title: localized('entranceMating'),
			children: [{ columnName: 'matedDate' }, { columnName: 'boar' }, { columnName: 'k' }],
		},
		{
			title: localized('farrowing'),
			children: [
				{ columnName: 'farrowingDate' },
				{ columnName: 'alive' },
				{ columnName: 'stillBorn' },
				{ columnName: 'abortion' },
				{ columnName: 'totalDead' },
			],
		},
		{
			title: localized('Weaning'),
			children: [
				{ columnName: 'WeaningDate' },
				{ columnName: 'pcs' },
				{ columnName: 'kg' },
				{ columnName: 'isNursingSow' },
			],
		},
	];

	public render() {
		this.data = this.generateData(
			this.props.stemAnimals,
			this.props.pregnancyEvents,
			this.state.sow,
			this.props.deadPiglets,
		);
		return (
			<PageContainer className="sow-card">
				<Heading text={localized('SOWCARD')} />

				<ViewWeb className="treatment-lard-container">
					{this.props.close && (
						<ViewWeb className="vertical-center">
							<SkioldButton title={localized('Close')} onPress={this.props.close} theme="grey" />
						</ViewWeb>
					)}
					<ViewWeb className="alignItemsCenter">
						<SkioldTouchableOpacity onPress={this.openTreatmentsModal}>
							<SkioldImage
								width={SowListConstants.penWidth}
								height={SowListConstants.penWidth}
								imageData={medicin_list_icon}
							/>
						</SkioldTouchableOpacity>
						<WhiteText>{localized('treatments')}</WhiteText>
					</ViewWeb>
					{this.props.generalSettings.stemAnimalShowFamilyTree && this.props.nucleusManagement && (
						<ViewWeb className="alignItemsCenter">
							<SkioldTouchableOpacity onPress={this.openFamilyTreeModal}>
								<SkioldImage
									width={SowListConstants.penWidth}
									height={SowListConstants.penWidth}
									imageData={family_tree}
								/>
							</SkioldTouchableOpacity>
							<WhiteText>{localized('FamilyTree')}</WhiteText>
						</ViewWeb>
					)}

					<ViewWeb className="alignItemsCenter left-padding">
						<SkioldTouchableOpacity onPress={this.openLardWidthsModal}>
							<SkioldImage
								width={SowListConstants.penWidth}
								height={SowListConstants.penWidth}
								imageData={lard_list_icon}
							/>
						</SkioldTouchableOpacity>
						<WhiteText>{localized('sowLardWidth')}</WhiteText>
					</ViewWeb>
					<ViewWeb className="alignItemsCenter left-padding">
						<SkioldTouchableOpacity onPress={this.openDeadPigletsModal}>
							<SkioldImage
								width={SowListConstants.penWidth}
								height={SowListConstants.penWidth}
								imageData={dead_piglets_icon}
							/>
						</SkioldTouchableOpacity>
						<WhiteText>{localized('deadPigletsEvent')}</WhiteText>
					</ViewWeb>
				</ViewWeb>

				<ViewWeb className="columnsContainerViewStyle">
					{this.renderColumn1(this.state)}
					{this.renderColumn2(this.state)}
					{this.renderColumn3(this.state)}
				</ViewWeb>
				<ViewWeb className="paddingFifteen">
					<SkioldTableGrid
						tableKey={'sowCard'}
						groupedColumns={this.groupedColumns}
						columns={this.generateColumns()}
						ColumnExtensions={this.state.columnsExte}
						filterable={false}
						data={this.data}
						usesSummaryRow={true}
						ignoreSetCount={true}
					/>
				</ViewWeb>
				<SkioldModal padding="0" isOpen={this.state.editModalOpen} close={this.closeEditModal}>
					{this.state.sow && this.state.sow.id && (
						<SowEvents
							stemAnimalId={this.state.sow!.id!}
							pregnancyEventId={this.state.pregnancyIdToEdit}
							closeEditModal={this.closeEditModal}
							theme="dark"
							setEventOption={this.state.eventOption}
						/>
					)}
				</SkioldModal>
				<SkioldModal
					shouldCloseOnOverlayClick={true}
					padding="0"
					isOpen={this.state.treatmentsModalOpen}
					close={this.closeTreatmentModal}
				>
					{this.state.sow && this.state.sow.id && (
						<SowCardTreatment sow={this.state.sow} closeModal={this.closeTreatmentModal} />
					)}
				</SkioldModal>
				<SkioldModal
					shouldCloseOnOverlayClick={true}
					padding="0"
					isOpen={this.state.familyTreeModalOpen}
					close={this.closeFamilyTreeModal}
				>
					{this.state.sow && this.state.sow.id && (
						<FamilyTreeOverview closeModal={this.closeFamilyTreeModal} stemAnimalId={this.state.sow.id} />
					)}
				</SkioldModal>
				<SkioldModal
					shouldCloseOnOverlayClick={true}
					padding="0"
					isOpen={this.state.lardWidthsModalOpen}
					close={this.closeLardWidthsModal}
				>
					{this.state.sow && this.state.sow.id && (
						<SowCardLardEvents sow={this.state.sow} closeModal={this.closeLardWidthsModal} />
					)}
				</SkioldModal>
				<SkioldModal
					shouldCloseOnOverlayClick={true}
					padding="0"
					isOpen={this.state.deadPigletsModalOpen}
					close={this.closeDeadPigletsModal}
				>
					{this.state.sow && this.state.sow.id && (
						<SowCardDeadPigletsEvents
							stemAnimalId={this.state.sow.id}
							closeModal={this.closeDeadPigletsModal}
						/>
					)}
				</SkioldModal>
			</PageContainer>
		);
	}

	private renderColumn1 = state => (
		<ViewWeb className="wrapperColumnWeb">
			<ViewWeb className="wrapperRowBaseline">
				<TextWeb className="label">{localized('animalNumber')}: </TextWeb>
				<ViewWeb className="displayFlexThree">
					<StemAnimalInput
						onAnimalFound={this.animalChanged}
						onAnimalNumberChanged={(animalNumber: string | undefined) =>
							this.setState({ animalNumber: animalNumber || undefined })
						}
						autoFocus={!this.props.stemAnimalIdFromParent}
						containerClassName="widthSeventyFivePercent"
						animalNumber={this.state.animalNumber}
						className={`value stem-animal-input ${
							this.state.sow &&
							this.state.sow.departureType === DepartureTypes.departureTypeShouldDeparture
								? 'should-departure-list-color'
								: ''
						}`}
						inputType={'underline'}
						gender={Gender.Female}
					/>
				</ViewWeb>

				<TextWeb className="label">{localized('Batch')}: </TextWeb>
				<TextWeb className="value">
					{this.state.sow && this.state.sow.id ? findBatch(this.state.sow.id) : ''}
				</TextWeb>
			</ViewWeb>
			<ViewWeb className="wrapperRowBaseline">
				<TextWeb className="label">{localized('entrance')}: </TextWeb>
				<TextWeb className="value">{this.state.sow ? getDateString(this.state.sow.entranceDate) : ''}</TextWeb>
				<TextWeb className="rowCell value">
					{this.state.sow ? localizedDynamic(this.state.sow.entranceType!) : ''}
				</TextWeb>
				<TextWeb className="flexOne" />
			</ViewWeb>
			<ViewWeb className="wrapperRowBaseline">
				<TextWeb className="label">{localized('departure')}: </TextWeb>
				<TextWeb className="value">
					{this.state.sow && this.state.sow.departureDate ? getDateString(this.state.sow.departureDate) : ''}
				</TextWeb>
				<TextWeb className="rowCell value">
					{this.state.sow && this.state.sow.departureType
						? localizedDynamic(this.state.sow.departureType!)
						: ''}
				</TextWeb>
				<TextWeb className="flexOne" />
			</ViewWeb>
		</ViewWeb>
	);

	private readonly onTeatsChanged = teats =>
		this.setState(prevState => ({ sow: { ...prevState.sow, teats } as IStemAnimal }));

	private renderColumn2 = state => (
		<ViewWeb className="sowCardBorderCommonStyle wrapperColumnWebLarge">
			<ViewWeb className="wrapperRowBaseline">
				<ViewWeb className="wrapperRowBaseline">
					<TextWeb className="label">{localized('location')}: </TextWeb>
					<TextWeb className="value flexSeven">{getLocationString(this.state.sow)}</TextWeb>
				</ViewWeb>
				{this.props.nucleusManagement && (
					<ViewWeb className="wrapperRowBaseline">
						<TextWeb className="label flex-none">{localized('index')}: </TextWeb>
						<TextWeb className="value flexSeven">{this.state.sow?.breedIndex}</TextWeb>
					</ViewWeb>
				)}
			</ViewWeb>
			<ViewWeb className="wrapperRowBaseline flexTwo">
				<ViewWeb className="flexColumn flexOne height100">
					<ViewWeb className="wrapperRowBaseline flexOne">
						<TextWeb className="label">{localized('Breed')}: </TextWeb>
						<TextWeb className="value">{this.state.sow ? this.state.sow.race : ''}</TextWeb>
					</ViewWeb>
					<ViewWeb className="wrapperRowBaseline flexOne">
						<TextWeb className="label">{localized('Teats')}: </TextWeb>
						<ViewWeb className="displayFlexThree">
							<SkioldUnderlineIntergerInput
								text={this.state.sow ? this.state.sow.teats : undefined}
								className="value teats-input"
								onChangeNumber={this.onTeatsChanged}
								onBlur={this.onBlurEvent}
								containerClassName="teats-input-container"
							/>
						</ViewWeb>
					</ViewWeb>
				</ViewWeb>
				{this.props.nucleusManagement && (
					<ViewWeb className="flexColumn flexOne height100">
						<ViewWeb className="wrapperRowBaseline ">
							<TextWeb className="id-number label">{localized('BreedingNr')}: </TextWeb>
							<TextWeb className="id-number-text value">
								{this.state.sow ? this.state.sow.breedingNumber : ''}
							</TextWeb>
						</ViewWeb>
						<ViewWeb className="flexOne" />
					</ViewWeb>
				)}
				<ViewWeb className="flexColumn flexTwo height100">
					<ViewWeb className="wrapperRowBaseline">
						<TextWeb className="id-number label flex-none">{localized('IDnr')}: </TextWeb>
						<TextWeb className="id-number-text value">
							{this.state.sow ? this.state.sow.idNumber : ''}
						</TextWeb>
					</ViewWeb>
					<ViewWeb className="wrapperRowBaseline">
						<TextWeb className="id-number label flex-none">{localized('RetentionTime')}: </TextWeb>
						<TextWeb className="retention-time value">
							<GetRetentionTime stemAnimalId={this.state.sow ? this.state.sow.id! : ''} />
						</TextWeb>
					</ViewWeb>
				</ViewWeb>
			</ViewWeb>
		</ViewWeb>
	);

	private renderColumn3 = state => (
		<ViewWeb className="wrapperColumnLarge sowCardCommonPaddingWeb sowCardBorderCommonStyle">
			<TextWeb className="rowCell label">{localized('comment')}</TextWeb>
			<SkioldTextArea
				numberOfLines={4}
				onChangeText={this.commentChanged}
				text={this.state.sow && this.state.sow.comments ? this.state.sow.comments : ''}
				className="yellowTextStyle"
				onBlur={this.onBlurEvent}
			/>
		</ViewWeb>
	);

	private commentChanged = (text: string) => {
		this.setState(prevState => ({ sow: { ...prevState.sow, comments: text } as IStemAnimal }));
	};

	private openLardWidthsModal = () => {
		if (this.state.sow && this.state.sow.id) {
			this.setState({ lardWidthsModalOpen: true });
		}
	};

	private openTreatmentsModal = () => {
		if (this.state.sow && this.state.sow.id) {
			this.setState({ treatmentsModalOpen: true });
		}
	};

	private openFamilyTreeModal = () => {
		if (this.state.sow && this.state.sow.id) {
			this.setState({ familyTreeModalOpen: true });
		}
	};

	private openDeadPigletsModal = () => {
		if (this.state.sow && this.state.sow.id) {
			this.setState({ deadPigletsModalOpen: true });
		}
	};

	private onBlurEvent = () => {
		if (this.state.sow && this.state.sow.id) {
			this.props.updateSow(this.state.sow);
		}
	};
	private generateColumns() {
		return [
			{
				name: 'litter',
				title: localized('Litters'),
				headerClassName: 'merged-header',
				getCellValue: (card: ISowCardComplete) => <TextWeb>{card.litter}</TextWeb>,
				Footer: localized('avg'),
			},
			{
				name: 'matedDate',
				title: localized('Date'),
				getCellValue: (card: ISowCardComplete) => (
					<SkioldTouchableOpacity
						disabled={
							this.state.sow && this.state.sow.id
								? !isEventEditable(this.props.pregnancyEvents[this.state.sow!.id!], card.matedId!)
								: true
						}
						onPress={() => this.editMating(card.matedId!)}
					>
						<TextWeb>{card.matingDate}</TextWeb>
					</SkioldTouchableOpacity>
				),
			},
			{
				name: 'boar',
				title: localized('boar'),
				getCellValue: (card: ISowCardComplete) => <TextWeb>{card.boar}</TextWeb>,
			},
			{
				name: 'k',
				title: 'K',
				getCellValue: (card: ISowCardComplete) => <TextWeb>{card.k}</TextWeb>,
			},
			{
				name: 'pregnant',
				title: localized('Preg'),
				headerClassName: 'merged-header',
				getCellValue: (card: ISowCardComplete) => (
					<SkioldTouchableOpacity
						disabled={
							this.state.sow && this.state.sow.id
								? !isEventEditable(this.props.pregnancyEvents[this.state.sow!.id!], card.scannedId!)
								: true
						}
						onPress={() => this.editScanning(card.scannedId!)}
					>
						<TextWeb>{card.pregnant}</TextWeb>
					</SkioldTouchableOpacity>
				),
			},
			{
				name: 'pregnantDays',
				title: localized('pregDays'),
				headerClassName: 'merged-header',
				getCellValue: (card: ISowCardComplete) => (
					<TextWeb className={`${card.hasFarrowing ? '' : 'green-text'}`}>{card.pregnancyDays}</TextWeb>
				),
				Footer: <TextWeb>{this.getAvg('pregnancyDays')}</TextWeb>,
			},
			{
				name: 'farrowingDate',
				title: localized('Date'),
				getCellValue: (card: ISowCardComplete) => (
					<SkioldTouchableOpacity
						disabled={
							this.state.sow && this.state.sow.id
								? !isEventEditable(
										this.props.pregnancyEvents[this.state.sow!.id!],
										card.farrowingId!,
										this.props.nucleusManagement,
								  ) // TODO kerne: add if using kerne
								: true
						}
						onPress={() => this.editFarrowing(card.farrowingId!)}
					>
						<TextWeb className={`${card.hasFarrowing ? '' : 'green-text'}`}>{card.farrowingDate}</TextWeb>
					</SkioldTouchableOpacity>
				),
			},
			{
				name: 'alive',
				title: localized('alive'),
				getCellValue: (card: ISowCardComplete) => <TextWeb>{card.numAlive}</TextWeb>,
				Footer: <TextWeb>{this.getAvg('numAlive')}</TextWeb>,
			},
			{
				name: 'stillBorn',
				title: localized('stillBorn'),
				getCellValue: (card: ISowCardComplete) => <TextWeb>{card.numDead}</TextWeb>,
				Footer: <TextWeb>{this.getAvg('numDead')}</TextWeb>,
			},
			{
				name: 'abortion',
				title: localized('abortionSowCard'),
				getCellValue: (card: ISowCardComplete) =>
					card.abortion ? (
						<ViewWeb className="abortion-checkbox-container">
							<SkioldCheckbox
								isChecked={true}
								onClick={() => {}}
								className="abortion-checkbox-container"
							/>
						</ViewWeb>
					) : (
						<ViewWeb />
					),
			},
			{
				name: 'totalDead',
				title: localized('dead'),
				getCellValue: (card: ISowCardComplete) => <TextWeb>{card.totalDeadPiglets}</TextWeb>,
				Footer: <TextWeb>{this.getAvg('totalDeadPiglets')}</TextWeb>,
			},
			{
				name: 'NursingDays',
				title: localized('nursDays'),
				headerClassName: 'merged-header',
				getCellValue: (card: ISowCardComplete) => <TextWeb>{card.nursingDays}</TextWeb>,
				Footer: <TextWeb>{this.getAvg('nursingDays')}</TextWeb>,
			},
			{
				name: 'WeaningDate',
				title: localized('Date'),
				getCellValue: (card: ISowCardComplete) => (
					<SkioldTouchableOpacity
						disabled={
							this.state.sow && this.state.sow.id
								? !isEventEditable(this.props.pregnancyEvents[this.state.sow!.id!], card.weaningId!)
								: true
						}
						onPress={() => this.editWeaning(card.weaningId!)}
					>
						<TextWeb>{card.weaningDate}</TextWeb>
					</SkioldTouchableOpacity>
				),
			},
			{
				name: 'pcs',
				title: localized('pcs'),
				getCellValue: (card: ISowCardComplete) => <TextWeb>{card.pcs}</TextWeb>,
				Footer: <TextWeb>{this.getAvg('pcs')}</TextWeb>,
			},
			{
				name: 'kg',
				title: 'kg',
				getCellValue: (card: ISowCardComplete) => <TextWeb>{card.weight}</TextWeb>,
				Footer: <TextWeb>{this.getWeightAvg('weight')}</TextWeb>,
			},
			{
				name: 'isNursingSow',
				title: localized('nursSow'),
				getCellValue: (card: ISowCardComplete) =>
					card.isNursingSow ? (
						<ViewWeb className="abortion-checkbox-container">
							<SkioldCheckbox isChecked={true} onClick={() => {}} className="abortion-checkbox" />
						</ViewWeb>
					) : (
						<ViewWeb />
					),
			},
			{
				title: localized('wasteDays'),
				headerClassName: 'merged-header',
				name: 'wasteDaysHeader',
				getCellValue: (card: ISowCardComplete) => <TextWeb>{card.wasteDays}</TextWeb>,
				Footer: <TextWeb>{this.getAvg('wasteDays')}</TextWeb>,
			},
			{
				name: 'pigYearSow',
				title: <TextWeb className="pig-year-header">{localized('pigYearSo')}</TextWeb>,
				headerClassName: 'merged-header',
				getCellValue: (card: ISowCardComplete) => <TextWeb>{card.pigSow}</TextWeb>,
				Footer: (
					<TextWeb>
						{this.state &&
							this.state.sow &&
							this.state.sow.id &&
							getPigYearSow(this.state.sow!, this.props.pregnancyEvents[this.state.sow!.id!], this.data)}
					</TextWeb>
				),
			},
		];
	}

	private editFarrowing(pregnancyEventId: string) {
		this.setState({
			editModalOpen: true,
			pregnancyIdToEdit: pregnancyEventId,
			eventOption: { label: localized('farrowing'), value: 'farrowing' },
		});
	}

	private editMating(pregnancyEventId: string) {
		this.setState({
			editModalOpen: true,
			pregnancyIdToEdit: pregnancyEventId,
			eventOption: { label: localized('mating'), value: 'mating' },
		});
	}

	private editWeaning(pregnancyEventId: string) {
		this.setState({
			editModalOpen: true,
			pregnancyIdToEdit: pregnancyEventId,
			eventOption: { label: localized('Weaning'), value: 'weaning' },
		});
	}

	private editScanning(pregnancyEventId: string) {
		this.setState({
			editModalOpen: true,
			pregnancyIdToEdit: pregnancyEventId,
			eventOption: { label: localized('scanning'), value: 'scanning' },
		});
	}

	private closeEditModal = () => {
		this.setState({ editModalOpen: false, pregnancyIdToEdit: '' }, () => this.getSowData(this.state.sow));
	};
	private closeTreatmentModal = () => {
		this.setState({ treatmentsModalOpen: false });
	};
	private closeFamilyTreeModal = () => {
		this.setState({ familyTreeModalOpen: false });
	};
	private closeLardWidthsModal = () => {
		this.setState({ lardWidthsModalOpen: false });
	};

	private closeDeadPigletsModal = () => {
		this.setState({ deadPigletsModalOpen: false });
	};
	private getAvg(key: keyof IPregnancyTableModel) {
		const avg = calculateSowCardAvg(key, this.data);
		return Number.isFinite(avg) ? avg : undefined;
	}

	private getSowData(sow: IStemAnimal | undefined) {
		if (sow && sow.id) {
			const animalPregnancies = this.props.pregnancyEvents[sow.id];
			const sortedPregnancies = sortPregnanciesByDate(animalPregnancies);
			return generateSowCardData(sow, sortedPregnancies, this.props.validationSetup, this.props.deadPiglets);
		} else {
			return [];
		}
	}

	private getWeightAvg(key: keyof IPregnancyTableModel) {
		const avg = calculateSowCardWeightAvg(key, this.data);
		return avg || undefined;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SowCard);
