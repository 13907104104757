import { IResultYoungFemaleAgeTableData, IElaborationResultYoungFemaleAgeTableData } from "shared/api/api";

export const SYNC_DATA_RESULT_YOUNG_ANIMAL_AGE_DATA = 'SYNC_DATA_RESULT_YOUNG_ANIMAL_AGE_DATA';

export const SYNC_DATA_ELABORATION_RESULT_YOUNG_ANIMAL_AGE_DATA = 'SYNC_DATA_ELABORATION_RESULT_YOUNG_ANIMAL_AGE_DATA';
export interface ResultYoungFemaleAgeState  {
    data?: IResultYoungFemaleAgeTableData;
    elaborationdata?: IElaborationResultYoungFemaleAgeTableData;
    loading: boolean;
    loadingElaboration: boolean;
}
