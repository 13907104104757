import { Dashboard, IDashboard } from 'shared/api/api';
import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import * as Action from './actions';

export function UpsertDashboard(dashboard: IDashboard) {
	if (!dashboard.version) {
		dashboard.version = 0;
	}
	dashboard.version++;
	return AsyncOperationBuilder2(
		Action.upsertDashboard,
		cl => cl.dashboard_Post(new Dashboard(dashboard)),
		new Dashboard(dashboard),
	);
}

export function GetDashboard(name: string) {
	return AsyncOperationBuilder2(Action.getDashboard, cl => cl.dashboard_Get(name), name);
}
