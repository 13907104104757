import React from 'react';
import { Checkbox, CheckboxChangeParams } from 'primereact/checkbox';
import './skiold-checkbox.scss';

export interface CheckboxPropsFromParent {
	onClick: (value: boolean, itemFromParent?: any) => void;
	itemFromParent?: any;
	isChecked: boolean | undefined;
	style?: any;
	title?: string;
	className?: string;
	containerClassName?: string;
	disabled?: boolean;
}

// Component representing a Skiold Digital textfield throughout the app
export class SkioldCheckbox extends React.PureComponent<CheckboxPropsFromParent> {
	public static defaultProps: Partial<CheckboxPropsFromParent> = {
		className: '',
		containerClassName: '',
		disabled: false,
		isChecked: false,
	};
	// private checkBoxStyle = [styles.checkBoxCommonStyle, this.props.style];
	public render() {
		return (
			<div style={this.props.style} className={`${this.props.containerClassName} skiold-checkbox`}>
				<Checkbox
					disabled={this.props.disabled}
					className={`${this.props.className} `}
					checked={this.props.isChecked}
					onChange={this.onClicked}
				/>
			</div>
		);
	}

	public onClicked = (event: CheckboxChangeParams) => {
		this.props.onClick(event.checked, this.props.itemFromParent);
	};
}
