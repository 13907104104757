import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Building, Pen, Section } from 'shared/api/api';
import { getDateString } from 'shared/helpers/date-helpers';
import { memoizeHashmapLocation } from 'shared/helpers/memoize-getters/memoize-getters';
import { getGrowthPigsEventsByDates } from 'shared/state/ducks/growth-pig-events/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { useGpeDashboard } from 'web/view/pages/dashboard-overview-growth-pigs/dashboard-overview-growth-pigs';
import { SkioldButton } from '../../skiold-components/skiold-button/skiold-button';
import { SkioldModal } from '../../skiold-components/skiold-modal/skiold-modal';
import { TableKeyValue } from '../../skiold-components/skiold-table/skiold-table-grouped-grid/skiold-table-grouped-grid-helper';
import { WhiteText } from '../../Text/white-text';
import { Heading } from '../../utils/heading';
import { ViewWeb } from '../../utils/web-view';
import { getDashboardDepartureModel, getDashboardEntranceModel } from '../dash-growth-pigs-helper';
import { DashboardGpeDepartureTable } from './dashboard-gpe-departure-table';
import { DashboardGpeEntranceTable } from './dashboard-gpe-entrance-table';
import { DashboardGpePrint } from './dashboard-gpe-print';
import { TotalSummaryDeparture } from './total-summary-departure';
import { TotalSummaryEntrance } from './total-summary-entrance';
interface PropsFromParent {}

export const DashboardGpeElaborationOverview: FC<PropsFromParent> = React.memo(() => {
	const dispatch = useDispatch();

	// Get data
	const { growthPigEvents } = useSelector((state: WebAppState) => state.growthPigEvents);
	const { active } = useSelector((state: WebAppState) => state.profile);
	const { growthPigTuneProductions } = useSelector((state: WebAppState) => state.growthPigTuneProduction);
	const {
		sectionId,
		penId,
		productionType,
		closeEntranceDepartureModal,
		isEntranceDepartureOpen,
	} = useGpeDashboard();

	const hashmapLocation = useSelector((state: WebAppState) =>
		memoizeHashmapLocation(state.locations.buildings, state.locations.sections, state.locations.pens),
	);
	const [locationString, setLocationString] = useState<string>('');
	const [penString, setPenString] = useState<string>('');

	const lastBalanced = useMemo(() => {
		// Set Header data
		if (growthPigTuneProductions && growthPigTuneProductions.length > 0) {
			let fitlered = growthPigTuneProductions.filter(
				tune =>
					productionType === tune.animalType &&
					((tune.sectionId === sectionId && tune.penId === penId) ||
						(tune.sectionId === sectionId && !penId)),
			);
			let lastestDate =
				fitlered && fitlered.length > 0
					? fitlered.reduce((a, b) => {
							return new Date(a.date!) > new Date(b.date!) ? a : b;
					  })
					: undefined;
			return lastestDate && lastestDate.date ? lastestDate.date : new Date();
		}
		return new Date();
	}, [productionType, growthPigTuneProductions, sectionId, penId]);

	useEffect(() => {
		if (lastBalanced && isEntranceDepartureOpen) {
			getGrowthPigsEventsByDates(lastBalanced, new Date())(dispatch);
		}
	}, [lastBalanced, isEntranceDepartureOpen, dispatch]);

	// Generate data on growthPigEvents change
	const entranceData = useMemo(() => {
		return getDashboardEntranceModel(lastBalanced, sectionId, penId, growthPigEvents);
	}, [lastBalanced, sectionId, penId, growthPigEvents]);

	// Generate data on growthPigEvents change
	const departureData = useMemo(() => {
		return getDashboardDepartureModel(lastBalanced, sectionId, penId, growthPigEvents);
	}, [lastBalanced, sectionId, penId, growthPigEvents]);

	// Set location string
	useEffect(() => {
		if (sectionId) {
			let section = hashmapLocation[sectionId] as Section;
			if (section.buildingId) {
				let building = hashmapLocation[section.buildingId] as Building;
				setLocationString(`${building.name} - ${section.name}`);
			}
		}
	}, [sectionId, hashmapLocation]);

	// Set pen string
	useEffect(() => {
		if (penId) {
			let pen = hashmapLocation[penId] as Pen;
			setPenString(`${localized('pen')}: ${pen.name}`);
		} else {
			setPenString('');
		}
	}, [penId, hashmapLocation]);

	return (
		<SkioldModal
			shouldCloseOnOverlayClick={true}
			padding="0"
			overflowNone={true}
			isOpen={isEntranceDepartureOpen}
			close={closeEntranceDepartureModal}
			justify-content="flex-end"
			max-width="calc(100% - 220px)"
			className="dashboard-elaboration-modal"
		>
			<Heading text={localized('Elaboration')} />
			<ViewWeb className="dashboard-elaboration-container">
				<ViewWeb className="flex-row elaboration-container">
					<WhiteText className="flex-1 align-left">
						{localized('dateForBalance')}: {getDateString(lastBalanced)}
					</WhiteText>
					<ViewWeb className="location-text flex-1">
						<WhiteText> {locationString}</WhiteText>
						<WhiteText>{penString}</WhiteText>
					</ViewWeb>
					<DashboardGpePrint
						locationString={locationString}
						penString={penString}
						active={active}
						lastBalanced={lastBalanced}
					/>
				</ViewWeb>
				<ViewWeb className="dashboard-elaboration-table-container">
					<ViewWeb>
						<TotalSummaryEntrance lastBalanced={lastBalanced} />
						<DashboardGpeEntranceTable data={entranceData} />
					</ViewWeb>
					<ViewWeb>
						<TotalSummaryDeparture lastBalanced={lastBalanced} />
						<DashboardGpeDepartureTable data={departureData} />
					</ViewWeb>
				</ViewWeb>
				<SkioldButton className="close-btn" title={localized('Close')} onPress={closeEntranceDepartureModal} />
			</ViewWeb>
		</SkioldModal>
	);
});
