import { Dispatch } from 'redux';
import { IVaccineListSetting } from 'shared/api/api';
import * as diagnoseOperation from 'shared/state/ducks/diagnoses/operations';
import { GetLocations } from 'shared/state/ducks/locations/operations';
import * as moveEventOperation from 'shared/state/ducks/move-events/operations';
import * as pregnancyOperation from 'shared/state/ducks/pregnancy-events/operations';
import * as stemAnimalOperation from 'shared/state/ducks/stem-animals/operations';
import { SetCheckedCount, SetSowsCount } from 'shared/state/ducks/stem-animals/operations';
import * as treatmentDefinitionOperation from 'shared/state/ducks/treatment-definitions/operations';
import * as treatmentPlanOperation from 'shared/state/ducks/treatment-plan/operations';
import { SaveMultipleVaccinesByWorkList } from 'shared/state/ducks/treatment-plan/operations';
import * as treatmentOperation from 'shared/state/ducks/treatment/operations';
import * as workListToTreatmentPlanOperation from 'shared/state/ducks/work-list-to-treatment-plan/operations';
import { SharedAppState } from 'shared/state/store.shared';
import { Option } from 'react-dropdown';
import { memoizeLocation } from 'shared/helpers/memoize-getters/memoize-getters';

export class VaccineWorkListItem {
	public cycleDays: number | undefined;
	public animalNumber: string | undefined;
	public litterNumber: number | undefined;
	public isChecked: boolean | undefined;
	public batch: string | undefined;
	public vaccineSubColumns: VaccineSubColumn[] | undefined;
	public hasRetentionTime: boolean | undefined;
	public buildingString: string | undefined;
	public penString: string | undefined;
	public sectionString: string | undefined;
	public stemAnimalId: string | undefined;
	public identifier: string | undefined;
	public useItem: boolean | undefined;
	public itemNumber: number | undefined;
	public order?: number | undefined;
	public ageInWeeks?: number | undefined;
}

// tslint:disable-next-line: max-classes-per-file
export class VaccineSubColumn {
	public subTaskNumber: number | undefined;
	public weekNumber: number | undefined;
	public treatmentDefinitionId: string | undefined;
}

interface PropsFromParent {
	workListSetting: IVaccineListSetting;
	batch: Option;
	week?: Option;
}

export const VaccineWorkListMapStateToProps = (state: SharedAppState) => {
	return {
		pregnancyEvents: state.pregnancyEvents.entities,
		stemAnimals: state.stemAnimals.entities,
		treatments: state.treatments.entities,
		treatmentPlans: state.treatmentPlans.entities,
		treatmentDefinitions: state.treatmentDefinitions.entities,
		workListToTreatmentPlans: state.workListToTreatmentPlans.entities,
		matingBatches: state.matingBatches.entities,
		diagnoses: state.diagnose.entities,
		moveEvents: state.moveEvents.entities,
		locations: memoizeLocation(
			state.locations.buildings,
			state.locations.sections,
			state.locations.pens,
			state.locations.valves,
		),
	};
};

export const VaccineWorkListMapDispatchToProps = (dispatch: Dispatch, props: {}) => {
	return {
		setSowsCount: (count: number) => SetSowsCount(count)(dispatch),
		SetCheckedCount: (count: number, reset?: boolean) => SetCheckedCount(count, reset)(dispatch),
		saveMultipleVaccinesByWorkList: (setting: IVaccineListSetting, items: VaccineWorkListItem[]) =>
			SaveMultipleVaccinesByWorkList(setting, items)(dispatch),
		pregnancyEventGetSyncData: () => pregnancyOperation.GetSyncData()(dispatch),
		stemAnimalGetSyncData: () => stemAnimalOperation.GetSyncData()(dispatch),
		treatmentGetSyncData: () => treatmentOperation.GetSyncData()(dispatch),
		treatmentPlanGetSyncData: () => treatmentPlanOperation.GetSyncData()(dispatch),
		treatmentDefinitionGetSyncData: () => treatmentDefinitionOperation.GetSyncData()(dispatch),
		workListToTreatmentPlanGetSyncData: () => workListToTreatmentPlanOperation.GetSyncData()(dispatch),
		diagnoseGetSyncData: () => diagnoseOperation.GetSyncData()(dispatch),
		moveEventGetSyncData: () => moveEventOperation.GetSyncData()(dispatch),
		getLocations: () => GetLocations()(dispatch)
	};
};

export interface VaccineWorkListState {
	columns: any[];
	columnExte: any[];
	loading: boolean;
	workListData: VaccineWorkListItem[];
	commitAll: boolean;
}

export type VaccineWorkListProps = ReturnType<typeof VaccineWorkListMapStateToProps> &
	ReturnType<typeof VaccineWorkListMapDispatchToProps> &
	PropsFromParent;

// tslint:disable-next-line: max-classes-per-file
export class VaccineListTableConstants {
	public static animalNrWidth = 100;
	public static cycleDaysWidth = 100;
	public static pcsWidth = 60;
	public static amountWidth = 50;
	public static completedWidth = 100;
	public static iconSVGWidth = 25;
}
