import i18next from 'i18next';
import React from 'react';
import { connect } from 'react-redux';
import * as ReactSelect from 'react-select';
import { Dispatch } from 'redux';
import { IUserProfile, IUserSiteDTO } from 'shared/api/api';
import { Activate, GetProfile, UpdateProfile, ActivateBySiteId } from 'shared/state/ducks/profile/operations';
import { GetByProfileAsync, GetSite } from 'shared/state/ducks/site/operations';
import { Languages, LanguageType, localized } from 'shared/state/i18n/i18n';
import { goToPage } from 'web/state/ducks/navigation/actions';
import { ROUTE_CHANGE_PASSWORD, ROUTE_LANGUAGE, ROUTE_SIGN_OUT_REDIRECT } from 'web/state/ducks/navigation/types';
import { WebAppState } from 'web/state/store.web';
import PageContainer from 'web/view/components/page-container/page-container';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import Sync from 'web/view/components/sync/sync';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { changePassword, signOut } from 'web/web-helpers/authentication-helper-web';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import './profile.scss';
import { GetUserSitesForCurrentUserAsync } from 'shared/state/ducks/user-sites/operations';
import Select, { Options } from 'react-select';

const mapStateToProps = (state: WebAppState) => {
	return {
		profile: state.profile.active!,
		// authorizeState: state.auth.authorizeState,
		isConnected: state.offline.isConnected,
		isSyncing: state.offline.isSyncing,
		isAdmin: state.authentication.isAdmin,
		userSites: state.userSites.userSites,
	};
};
const mapDispatchToProps = (dispatch: Dispatch) => ({
	getProfile: () => GetProfile()(dispatch),
	getUserSites: () => GetUserSitesForCurrentUserAsync()(dispatch),
	goToLanguageSelect: () => dispatch(goToPage(ROUTE_LANGUAGE)),
	goToNativeSignOut: () => dispatch(goToPage(ROUTE_SIGN_OUT_REDIRECT)),
	activate: (profileId: string) => Activate(profileId)(dispatch),
	goToNativePasswordChange: () => dispatch(goToPage(ROUTE_CHANGE_PASSWORD)),
	updateProfile: (profile: IUserProfile) => UpdateProfile(profile)(dispatch),
	getSite: (profileId: string) => GetByProfileAsync(profileId)(dispatch),
	activateBySiteId: (siteId: string) => ActivateBySiteId(siteId)(dispatch),
	getSitebySiteId: (siteId: string) => GetSite(siteId)(dispatch),
	userLogout: () =>
		dispatch({
			type: 'USER_LOGOUT',
		}),
});

interface ProfileState {
	selectedLanguage: string;
	web_selectedLangId: string;
}

type ProfileProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class Profile extends React.PureComponent<ProfileProps, ProfileState> {
	constructor(props: ProfileProps) {
		super(props);

		this.state = {
			selectedLanguage: Languages[this.props.profile.language as LanguageType],
			web_selectedLangId: this.props.profile.language!,
		};
		this.props.getUserSites();
		this.props.getProfile();
	}

	public changeLanguage = (lang: string) => {
		const l = this.getLanguageList().find(l => l.value === lang);

		if (l !== undefined) {
			i18next.changeLanguage(l.langId);
			const p = { ...this.props.profile };
			p.language = l.langId;
			this.props.updateProfile(p);
			return true;
		}
		return false;
	};

	public changeSite(siteName: string): boolean {
		const l = this.props.userSites.find(l => l.siteName === siteName);
		if (l !== undefined && !this.props.isAdmin) {
			this.props.activate(l.siteId!);
			this.props.getSitebySiteId(l.siteId!);
			return true;
		} else if (l !== undefined && this.props.isAdmin) {
			this.props.activateBySiteId(l.siteId!);
			this.props.getSitebySiteId(l.siteId!);
			return true;
		}
		return false;
	}

	public getLanguageList() {
		return Object.keys(Languages).map((key, idx) => ({
			value: Languages[Object.keys(Languages)[idx] as LanguageType],
			langId: Object.keys(Languages)[idx] as LanguageType,
		}));
	}

	public render() {
		const content = this.props.profile ? (
			<ViewWeb className="has-profile-container">
				<ViewWeb className="flex-direction-row">
					<TextWeb className="profile-title-styling">{localized('name')}:</TextWeb>
					<TextWeb className="profile-text-styling">{this.props.profile.name}</TextWeb>
				</ViewWeb>
				<ViewWeb className="zindex-hundred">
					<ViewWeb className="flex-direction-row zindex-hundred">
						<TextWeb className="profile-title-styling">{localized('site')}:</TextWeb>
						{this.props.userSites.length > 1 && this.props.isConnected && !this.props.isSyncing ? (
							<ViewWeb className="dropdowns">{this.renderSiteDropdown()}</ViewWeb>
						) : (
							<TextWeb className="profile-text-styling">{this.props.profile.siteName}</TextWeb>
						)}
					</ViewWeb>
					<ViewWeb className="flex-direction-row">
						<TextWeb className="profile-title-styling">{localized('language')}:</TextWeb>
						<ViewWeb className="dropdowns">{this.renderLanguageDropdown()}</ViewWeb>
					</ViewWeb>
				</ViewWeb>

				{this.renderSyncronization()}
				<ViewWeb className="sign-reset-buttons">
					<ViewWeb className="flex-direction-row">
						<this.ChangePasswordButton />
						<this.SignOutButton />
					</ViewWeb>
				</ViewWeb>
			</ViewWeb>
		) : (
			<ViewWeb className="indicator-container">{/* <ActivityIndicator size="large" /> */}</ViewWeb>
		);
		return <PageContainer className="profile-view-container">{content}</PageContainer>;
	}

	public renderConnectionStatusIcon() {
		return (
			<ViewWeb className="flex-direction-row">
				<TextWeb className="profile-title-styling">{localized('status')}:</TextWeb>
				{this.props.isConnected ? (
					<TextWeb className="profile-text-styling">{localized('online')}</TextWeb>
				) : (
					<TextWeb className="profile-text-styling">{localized('offline')}</TextWeb>
				)}
			</ViewWeb>
		);
	}

	public renderSyncronization() {
		return (
			<ViewWeb className="flex-direction-row">
				<TextWeb className="profile-title-styling">{localized('Synchronize')}:</TextWeb>
				<Sync />
			</ViewWeb>
		);
	}

	private getLanguageListForDropdown() {
		return this.getLanguageList().map(x => ({ value: x.langId, label: x.value }));
	}

	public renderLanguageDropdown() {
		return (
			<Select
				className="selectdefault languageDropdown"
				options={this.getLanguageListForDropdown()}
				onChange={(o: any) => {
					if (this.changeLanguage(o.label!)) {
						this.setState({
							...this.state,
							selectedLanguage: o.label!,
							web_selectedLangId: o.value! as string,
						});
					}
				}}
				isDisabled={this.props.profile.language === undefined}
				value={{ label: this.state.selectedLanguage, value: this.state.web_selectedLangId }}
			/>
		);
	}
	private readonly sortProfilesBySiteName: ((a: IUserSiteDTO, b: IUserSiteDTO) => number) | undefined = (upA, upB) =>
		upA.siteName && upB.siteName && upA.siteName > upB.siteName ? 1 : -1;

	private readonly mapSiteOptions: (
		value: IUserSiteDTO,
		index: number,
		array: IUserSiteDTO[],
	) => { value: string; label: string | undefined } = x => ({ value: x.siteId!, label: x.siteName });

	public renderSiteDropdown() {
		return (
			<ReactSelect.default
				className="selectdefault"
				options={this.props.userSites.sort(this.sortProfilesBySiteName).map(this.mapSiteOptions)}
				onChange={(o: any) => {
					this.changeSite(o.label!);
				}}
				//isDisabled={!!this.props.userSites || this.props.isSyncing}
				value={{ label: this.props.profile.siteName, value: this.props.profile.userSiteId }}
			/>
		);
	}

	private SignOutButton = () => {
		return <SkioldButton containerViewStyle={{ marginRight: 10 }} onPress={signOut} title={localized('logout')} />;
	};

	private ChangePasswordButton = () => {
		return <SkioldButton onPress={changePassword} title={localized('CHANGEPASSWORD')} />;
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
