
import { Dispatch } from 'redux';
import { setIsAuthAction, setShouldRefreshAction, setIsAdmin, setPerformingRefreshAction } from './actions';

export function setIsAuth(isAuth: boolean | undefined) {
	return (dispatch: Dispatch<any>) => {
		dispatch(setIsAuthAction(isAuth));
	}
}

export function setShouldRefresh(shouldRefresh: boolean) {
	return (dispatch: Dispatch<any>) => {
		dispatch(setShouldRefreshAction(shouldRefresh));
	}
}

export function SetIsAdmin(isAdmin: boolean) {
	return (dispatch: Dispatch<any>) => {
		dispatch(setIsAdmin(isAdmin));
	}
}

export function setPerformingRefresh(performingRefresh: boolean) {
	return (dispatch: Dispatch<any>) => {
		dispatch(setPerformingRefreshAction(performingRefresh));
	}
}

