import ObjectID from 'bson-objectid';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AmountType, Drug, IAdjustment, IDrug, IDrugWithAdjustment, Waste } from 'shared/api/api';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { saveDrug } from 'shared/state/ducks/drug/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import PageContainer from 'web/view/components/page-container/page-container';
import { showAlert } from 'web/view/components/skiold-alert/skiold-alert';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldFormDatePicker } from 'web/view/components/skiold-components/skiold-date-picker/skiold-form-datepicker';
import { SkioldFormTextField } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-form-text';
import { SkioldFormsWrapper } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { SkioldFormInput } from 'web/view/components/skiold-components/skiold-input/skiold-form-input';
import { SkioldFormIntegerInput } from 'web/view/components/skiold-components/skiold-integer-input/skiold-form-integer-input';
import { Heading } from 'web/view/components/utils/heading';
import 'web/view/styles/stock-styles/add-item.scss';
import { getPigType } from './drug-stock-helper';

interface PropsFromParent {
	drugToEdit?: string;
	isWasteEdit?: boolean;
	drugAdjustmentToEdit?: IDrugWithAdjustment;
	closeModal: () => void;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		siteId: state.profile.active!.siteId,
		profileId: state.profile.active!.id,
		drugs: state.drugs.drugs,
		drugTypes: state.drugType.entities,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	saveDrug: (drug: IDrug, adjustment: IAdjustment) => saveDrug(drug, adjustment)(dispatch),
});

interface State {
	drug: IDrug;
	adjustmentId?: string;
	amount?: number;
	price?: number;
	selectedDate?: Date;
	reason?: string;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

class AddToCurrentDrugStock extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		if (this.props.drugAdjustmentToEdit) {
			this.state = {
				amount: this.props.drugAdjustmentToEdit.amount,
				reason: this.props.drugAdjustmentToEdit.reason,
				price: this.props.drugAdjustmentToEdit.price,
				selectedDate: this.props.drugAdjustmentToEdit.date,
				adjustmentId: this.props.drugAdjustmentToEdit.adjustmentId,
				drug: Drug.fromJS({}),
			};
		} else {
			this.state = {
				amount: undefined,
				price: undefined,
				reason: undefined,
				selectedDate: new Date(),
				adjustmentId: undefined,
				drug: Drug.fromJS({}),
			};
		}
	}

	public render() {
		return (
			<PageContainer>
				{this.props.isWasteEdit ||
				(this.props.drugAdjustmentToEdit && this.props.drugAdjustmentToEdit.type === AmountType.Waste) ? (
					<Heading text={localized('UpdateDrugStockAmount')} />
				) : (
					<Heading text={localized('AddDrug')} />
				)}

				<ViewWeb className="add-item">
					<SkioldFormsWrapper formRows={this.getFormRows()} />
					<ViewWeb className="add-item-button-container">
						<SkioldButton theme="grey" title={localized('Close')} onPress={this.props.closeModal} />
						<SkioldButton title={localized('Save')} onPress={this.save} />
					</ViewWeb>
				</ViewWeb>
			</PageContainer>
		);
	}

	public async UNSAFE_componentWillMount() {
		let selectedDrug = await this.props.drugs.find(
			d =>
				(this.props.drugAdjustmentToEdit !== undefined && d.id === this.props.drugAdjustmentToEdit.id) ||
				d.id === this.props.drugToEdit,
		);
		if (selectedDrug) {
			this.setState({ drug: selectedDrug });
		}
	}
	private save = async () => {
		if (!this.validate()) {
			return;
		}
		if (this.state.drug) {
			let adjustment = {
				type: this.checkIfWasteEdit() ? AmountType.Waste : AmountType.Adjustment,
				id: this.props.drugAdjustmentToEdit
					? this.props.drugAdjustmentToEdit.adjustmentId
					: new ObjectID().toHexString(),
				price: this.state.price,
				amount: this.checkIfWasteEdit()
					? this.props.drugAdjustmentToEdit
						? this.state.amount!
						: -(this.state.drug.total! - this.state.amount!)
					: this.state.amount!,
				date: this.state.selectedDate!,
				registeredById: this.props.profileId,
			} as IAdjustment;
			if (this.checkIfWasteEdit()) {
				const copyOfDrug = { ...this.state.drug };
				let waste = Waste.fromJS(adjustment);
				waste.reason = this.state.reason!;
				await this.props.saveDrug(copyOfDrug, waste);
			} else {
				await this.props.saveDrug(this.state.drug, adjustment);
			}
		}
		this.props.closeModal();
	};

	private checkIfWasteEdit() {
		return (
			this.props.isWasteEdit ||
			(this.props.drugAdjustmentToEdit && this.props.drugAdjustmentToEdit.type === AmountType.Waste)
		);
	}

	private validate() {
		if (this.state.amount === undefined) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_DRUGSTOCK_AMOUNT_NOT_SET));
			return false;
		}
		if (
			this.state.amount! > 0 &&
			this.props.drugAdjustmentToEdit &&
			this.props.drugAdjustmentToEdit.type === AmountType.Waste
		) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_DRUGSTOCK_ERROR_EDITING_WASTE_HAS_TO_BE_NEGATIVE));
			return false;
		} else if (
			this.state.amount! < 0 &&
			this.props.drugAdjustmentToEdit &&
			this.props.drugAdjustmentToEdit.type !== AmountType.Waste
		) {
			showAlert(
				localized(ExceptionMessage.VALIDATION_ERROR_DRUGSTOCK_ERROR_EDITING_ADJUSTMENT_HAS_TO_BE_POSITIVE),
			);
			return false;
		}

		if (this.props.isWasteEdit) {
			if (!this.state.reason) {
				showAlert(localized(ExceptionMessage.VALIDATION_ERROR_DRUGSTOCK_REASON_REQUIRED));
				return false;
			}
		}
		if (!this.state.selectedDate) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_DRUGSTOCK_DATE_NOT_SET));
			return false;
		}
		return true;
	}

	private getFormRows() {
		let formRows = new Array<FormRow>();

		formRows.push(this.getDrugTypeRow());
		formRows.push(this.getPigTypesRow());

		if (this.props.isWasteEdit) {
			formRows.push(this.getCurrentAmountRow());
			formRows.push(this.getAmountRow());
			formRows.push(this.getWasteRow());
			formRows.push(this.getReason());
		} else {
			formRows.push(this.getAmountRow());
			if (!(this.props.drugAdjustmentToEdit && this.props.drugAdjustmentToEdit.type === AmountType.Waste)) {
				formRows.push(this.getPriceRow());
			}
		}
		formRows.push(this.getDatePickerRow());

		return formRows;
	}

	private getWasteRow(): FormRow {
		return {
			name: localized('Waste'),
			component: (
				<SkioldFormTextField>
					{this.props.drugAdjustmentToEdit
						? this.state.drug.total !== undefined &&
						  this.props.drugAdjustmentToEdit.amount !== undefined &&
						  this.state.amount !== undefined
							? this.state.drug.total - this.props.drugAdjustmentToEdit.amount - this.state.amount
							: undefined
						: this.state.drug.total && this.state.amount
						? this.state.drug.total - this.state.amount
						: undefined}
				</SkioldFormTextField>
			),
		};
	}

	private HandleIsNan(wast: number) {}

	private getAmountRow(): FormRow {
		const allowNegative =
			this.props.drugAdjustmentToEdit && this.props.drugAdjustmentToEdit.type === AmountType.Waste;
		return {
			name: this.props.isWasteEdit ? localized('newDrugStockAmount') : localized('amount'),
			component: (
				<SkioldFormIntegerInput
					isNegative={allowNegative}
					isPostive={!allowNegative}
					text={this.state.amount}
					onChangeNumber={this.amountChanged}
				/>
			),
		};
	}

	private getPriceRow(): FormRow {
		return {
			name: localized('price'),
			component: <SkioldFormIntegerInput text={this.state.price} onChangeNumber={this.priceChanged} />,
		};
	}

	private getReason(): FormRow {
		return {
			name: localized('reason'),
			component: <SkioldFormInput text={this.state.reason} onChangeText={this.reasonChanged} />,
		};
	}
	private reasonChanged = (reason?: string) => {
		this.setState({
			reason,
		});
	};

	private getCurrentAmountRow(): FormRow {
		return {
			name: localized('currentDrugStockAmount'),
			component: (
				<SkioldFormTextField>
					{this.props.drugAdjustmentToEdit
						? this.state.drug.total! - this.props.drugAdjustmentToEdit.amount!
						: this.state.drug.total}
				</SkioldFormTextField>
			),
		};
	}

	private priceChanged = (price?: number) => {
		this.setState({
			price,
		});
	};

	private amountChanged = (amount?: number) => {
		this.setState({
			amount,
		});
	};

	private getDatePickerRow(): FormRow {
		return {
			name: this.props.isWasteEdit
				? localized('Waste')
				: localized('AddToDrugStock') + localized('Date').toLowerCase(),
			component: (
				<SkioldFormDatePicker
					theme={'dark'}
					color={'grey'}
					selectedDate={this.state.selectedDate}
					onDateChanged={this.selectedDateChanged}
				/>
			),
		};
	}
	private selectedDateChanged = (newDate: Date) => {
		this.setState({ selectedDate: newDate });
	};

	private getDrugTypeRow(): FormRow {
		return {
			name: localized('drug'),
			component: <SkioldFormTextField className="textAlignRight">{this.getDrugType()}</SkioldFormTextField>,
		};
	}

	private getPigTypesRow(): FormRow {
		return {
			name: localized('pigTypes'),
			component: (
				<SkioldFormTextField>
					{getPigType(
						this.props.drugAdjustmentToEdit
							? this.props.drugAdjustmentToEdit.animalKinds!
							: this.state.drug.animalKinds!,
					)}
				</SkioldFormTextField>
			),
		};
	}

	private getDrugType() {
		let drugType = this.props.drugTypes.find(
			d =>
				(this.props.drugAdjustmentToEdit !== undefined &&
					d.id === this.props.drugAdjustmentToEdit.drugTypeId) ||
				d.id === this.state.drug.drugTypeId,
		);
		if (drugType) {
			return drugType.name ? drugType.name : '';
		}
		return '';
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToCurrentDrugStock);
