import { Action } from 'redux';
import { mergeArrays, removeValueFromArray, upsertValueInArray } from 'shared/helpers/reducer-helpers';
import { isType } from 'typescript-fsa';
import { getData, saveFeedingSubject } from './actions';
import { FeedingSubjectState } from './types';

const initialState: FeedingSubjectState = {
	entities: []
};

const feedingSubjectsReducer = (state: FeedingSubjectState = initialState, action: Action): FeedingSubjectState => {
	if (isType(action, getData.done)) {
		if (action.payload.result.length) {
			state = {
				...state,
				entities: mergeArrays(state.entities, action.payload.result)
			};
		}
	}

	if (isType(action, saveFeedingSubject.started)) {
		if (action.payload && action.payload.isDeleted) {
			state = { ...state, entities: removeValueFromArray(state.entities, action.payload.id!) };
		} else if (action.payload) {
			state = { ...state, entities: upsertValueInArray(state.entities, action.payload) };
		}
	}

	if (isType(action, saveFeedingSubject.failed)) {
		if (action.payload.error.code === 500) {
			if (action.payload.error.prevEntity) {
				state = { ...state, entities: upsertValueInArray(state.entities, action.payload.error.prevEntity) };
			} else if(action.payload.params) {
				state = { ...state, entities: removeValueFromArray(state.entities, action.payload.params.id!) };
			}
		}

		return state;
	}

	if (isType(action, saveFeedingSubject.done)) {
		if (action.payload.params) {
			action.payload.params.id = action.payload.result;

			if (!action.payload.params.isDeleted) {
				state = { ...state, entities: upsertValueInArray(state.entities, action.payload.params) };
			}
		}

		return state;
	}

	return state;
};

export default feedingSubjectsReducer;
