import React, { FC, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GrowthPigsEvent } from 'shared/api/api';
import { upsertGrowthPigsEvent } from 'shared/state/ducks/growth-pig-events/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import PageContainer from 'web/view/components/page-container/page-container';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldFormDecimalInput } from 'web/view/components/skiold-components/skiold-decimal-input/skiold-form-decimal-input';
import { SkioldFormTextField } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-form-text';
import { SkioldFormsWrapper } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { Heading } from 'web/view/components/utils/heading';
import { ViewWeb } from 'web/view/components/utils/web-view';

interface PropsFromParent {
	close?: () => void;
	growthPigsEventIds: string[];
	totalPigCount: number;
	totalPigWeight: number;
}

export const GpeDepartureDistributeWeight: FC<PropsFromParent> = React.memo(
	({ close, growthPigsEventIds, totalPigCount, totalPigWeight }) => {
		const dispatch = useDispatch();

		// Get data
		const gpeArray = useSelector((state: WebAppState) => state.growthPigEvents.growthPigEvents);
		const filteredGpeArray = useMemo(() => {
			return gpeArray.filter(g => g.id && growthPigsEventIds.includes(g.id));
		}, [gpeArray, growthPigsEventIds]);

		const [avgWeight, setAvgWeight] = useState<number | undefined>(
			totalPigWeight && totalPigCount ? Math.round((totalPigWeight / totalPigCount) * 10) / 10 : 0,
		);
		const [totalWeight, setTotalWeight] = useState<number | undefined>(totalPigWeight);

		// UI components
		const getTotalWeanedInPeriodRow = (): FormRow => {
			return {
				name: localized('TotalPigsInPeriod'),
				component: <SkioldFormTextField>{totalPigCount}</SkioldFormTextField>,
			};
		};

		const getAvgWeightRow = (): FormRow => {
			return {
				name: localized('avgWeight'),
				component: (
					<SkioldFormTextField>{avgWeight ? Math.round(avgWeight * 10) / 10 : ''}</SkioldFormTextField>
				),
			};
		};

		const totalWeightChanged = (newNumber: number | undefined) => {
			setTotalWeight(newNumber);
		};
		const getTotalWeightRow = (): FormRow => {
			return {
				name: localized('totalWeightInPeriod'),
				component: (
					<SkioldFormDecimalInput
						onChangeNumber={totalWeightChanged}
						text={totalWeight ? Math.round(totalWeight * 10) / 10 : 0}
					/>
				),
			};
		};

		const closeModal = () => {
			if (close) {
				close();
			}
		};

		const getFormRows = (): any => {
			let formRows = new Array<FormRow>();
			formRows.push(getTotalWeanedInPeriodRow());
			formRows.push(getAvgWeightRow());
			formRows.push(getTotalWeightRow());
			return formRows;
		};

		const calculate = () => {
			setAvgWeight(totalPigCount && totalWeight ? totalWeight / totalPigCount : 0);
		};

		const save = async () => {
			const gpeDtoArray = filteredGpeArray.map(e => {
				let gpe = GrowthPigsEvent.fromJS({});
				gpe.init(e);
				gpe.totalWeight = avgWeight && gpe.amount ? avgWeight * gpe.amount : 0;
				return gpe;
			});
			gpeDtoArray.forEach(async e => await upsertGrowthPigsEvent(e)(dispatch));
			closeModal();
		};

		return (
			<PageContainer>
				<ViewWeb className="show-diagnose-select">
					<ViewWeb>
						<Heading text={localized('allocateWeight')} />
						<SkioldFormsWrapper formRows={getFormRows()} containerClassName="forms-wrapper-container" />
					</ViewWeb>

					<ViewWeb className="diagnose-select-buttons-container">
						<SkioldButton title={localized('Close')} onPress={close} theme="grey" />
						<SkioldButton title={localized('calculate')} onPress={calculate} />
						<SkioldButton title={localized('Save')} onPress={save} />
					</ViewWeb>
				</ViewWeb>
			</PageContainer>
		);
	},
);
