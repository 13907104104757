import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Averted, Gender, IAverted, IPregnancyEvent, IStemAnimal, PregnancyState, StemAnimal } from 'shared/api/api';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { deepCopy } from 'shared/helpers/general-helpers';
import { getLocationString } from 'shared/helpers/location-helper';
import { setupPregnancyEventById } from 'shared/helpers/pregnancy-helper/generel-pregnancy-helpers';
import { sortPregnanciesByDate } from 'shared/helpers/pregnancy-helper/sort-pregnancies';
import { createAndEditValidation } from 'web/web-helpers/pregnancies/register-validation-helper';
import { mergeArraysHashmap } from 'shared/helpers/reducer-helpers';
import { ViewWeb } from 'web/view/components/utils/web-view';
import {
	DeleteLastPregnancyEvent,
	GetSyncData as PregnancyEventGetSyncData,
	SavePregnancyEvent,
} from 'shared/state/ducks/pregnancy-events/operations';
import { GetSyncData as StemAnimalGetSyncData } from 'shared/state/ducks/stem-animals/operations';
import { GetSyncData as ValidationSetupGetSyncData } from 'shared/state/ducks/validation-setup/operations';
import { localized, localizedDynamic } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import MoveEventBase from 'web/view/components/stem-animal/sow-events/move-event/move-event-base';
import { showAlert, ShowConfirmAlert } from '../../skiold-alert/skiold-alert';
import { SkioldButton } from '../../skiold-components/skiold-button/skiold-button';
import { SkioldCheckbox } from '../../skiold-components/skiold-checkbox/skiold-checkbox';
import { SkioldDatePicker } from '../../skiold-components/skiold-date-picker/skiold-date-picker';
import { SkioldFormDecimalInput } from '../../skiold-components/skiold-decimal-input/skiold-form-decimal-input';
import { SkioldFormTextField } from '../../skiold-components/skiold-forms-wrapper/skiold-form-text';
import { SkioldFormsWrapper } from '../../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from '../../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { SkioldFormIntegerInput } from '../../skiold-components/skiold-integer-input/skiold-form-integer-input';
import SkioldPregnancyAnimalInput from '../../stem-animal/stem-animal-input/skiold-pregnancy-animal-input';
import { Scroller } from '../../utils/scroller';
import './register-weaning.scss';
import { validateSowAnimalNumberIsNotDepartured } from 'web/web-helpers/pregnancies/web-prengancy-validation-settings';
import * as UnitsToPenOperations from 'shared/state/ducks/unit-to-pen/operations';
import { GetProcessEquipmentDataSyncData } from 'shared/state/ducks/process-equipment-data/operations';
import * as GeneralSettingsOperations from 'shared/state/ducks/general-settings/operations';
import { DepartureTypes } from 'shared/state/models/departure-types';
import { getLastMoveEventDate, isMoveDateValid } from 'shared/helpers/stemanimal-helper/stemanimal-helper';
import { getDateString } from 'shared/helpers/date-helpers';

interface PropsFromParent {
	pregnancyEventId?: string;
	stemAnimalId?: string;
	closeEditModal?: () => void;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		siteId: state.profile.active!.siteId,
		pregnancyEvents: mergeArraysHashmap(
			state.pregnancyEvents.entities,
			state.pregnancyEvents.departuredPregnancies,
		),
		validationSetup: state.validationSetup.entity,
		departuredStemAnimals: state.stemAnimals.departuredAnimals,
		defaultWeightWeaning: state.generalSettings.entity.defaultWeightWeaning,
		moveEvents: state.moveEvents.entities,
		generalSettings: state.generalSettings.entity,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	getValidationSetup: () => ValidationSetupGetSyncData()(dispatch),
	stemAnimalGetSyncData: () => StemAnimalGetSyncData()(dispatch),
	pregnancyEventGetSyncData: () => PregnancyEventGetSyncData()(dispatch),
	savePregnancyEvent: (pregnancyEvent: IPregnancyEvent) => SavePregnancyEvent(pregnancyEvent)(dispatch),
	deleteLastPregnancyEvent: (pregEvent: IPregnancyEvent) => DeleteLastPregnancyEvent(pregEvent)(dispatch),
	GetProcessEquipmentDataSyncData: async () => (await GetProcessEquipmentDataSyncData())(dispatch),
	getunitsData: () => UnitsToPenOperations.GetSyncData()(dispatch),
	getGeneralSettings: () => GeneralSettingsOperations.GetSyncData()(dispatch),
});

interface WeaningState {
	sow: IStemAnimal;
	weaning: IAverted;
	animalEvents: IPregnancyEvent[];
	prevEvent: IPregnancyEvent;
	avgWeight: number | undefined;
	avgPrice: number | undefined;
	animalNumber: string | undefined;
	registerMoveEvent: boolean;
	toggleFocus: boolean;
	lastMoveDate: Date | undefined;
}

export type WeaningProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;
class RegisterWeaning extends React.PureComponent<WeaningProps, WeaningState> {
	public moveEventSave: (() => Promise<boolean>) | undefined;

	private scrollerRef: Scroller | null | undefined;
	constructor(props: WeaningProps) {
		super(props);
		this.state = {
			sow: StemAnimal.fromJS({}),
			weaning: Averted.fromJS({ date: new Date(), state: PregnancyState.Averted }),
			animalEvents: [],
			prevEvent: {} as IPregnancyEvent,
			avgPrice: undefined,
			avgWeight: this.props.defaultWeightWeaning,
			animalNumber: undefined,
			registerMoveEvent: false,
			toggleFocus: false,
			lastMoveDate: undefined,
		};
		this.animalNumberChanged = this.animalNumberChanged.bind(this);
	}

	public static getDerivedStateFromProps(nextProps: WeaningProps, prevState: WeaningState) {
		if (prevState.avgWeight === undefined && nextProps.defaultWeightWeaning) {
			return {
				avgWeight: nextProps.defaultWeightWeaning,
			};
		}
		return {};
	}

	public componentDidMount() {
		this.props.stemAnimalGetSyncData();
		this.props.pregnancyEventGetSyncData();
		this.props.getValidationSetup();
		this.props.getunitsData();
		this.props.GetProcessEquipmentDataSyncData();
		this.props.getGeneralSettings();

		if (this.props.pregnancyEventId) {
			setupPregnancyEventById(
				this.props.pregnancyEvents,
				this.props.pregnancyEventId,
				this.props.stemAnimalId!,
				this.animalNumberChanged,
				animalNumber => {
					this.setState({ animalNumber });
				},
			);
		}
	}

	public animalNumberChanged(
		sow: IStemAnimal,
		animalEvents: IPregnancyEvent[],
		prevEvent: IPregnancyEvent,
		newPregnancyEvent: IPregnancyEvent,
	) {
		let weaning = newPregnancyEvent as IAverted;
		let avgWeight = this.state.avgWeight;
		let avgPrice;

		if (this.props.closeEditModal && weaning.totalWeight && weaning.numAlive) {
			avgWeight = weaning.totalWeight / weaning.numAlive;
		}

		if (this.props.closeEditModal && weaning.totalPrice && weaning.numAlive) {
			avgPrice = weaning.totalPrice / weaning.numAlive;
		}
		const lastMoveDate = getLastMoveEventDate(sow.id, this.props.moveEvents);
		this.setState({
			sow,
			animalEvents: animalEvents || [],
			prevEvent,
			weaning: newPregnancyEvent as IAverted,
			avgWeight,
			avgPrice,
			registerMoveEvent: !isMoveDateValid(lastMoveDate, newPregnancyEvent.date)
				? false
				: this.state.registerMoveEvent,
			lastMoveDate,
		});
	}

	public finishedDateChanged(newDate: Date) {
		const moveValid = isMoveDateValid(this.state.lastMoveDate, newDate);
		this.setState(prevState => ({
			weaning: { ...prevState.weaning, date: newDate },
			registerMoveEvent: !moveValid ? false : this.state.registerMoveEvent,
		}));
	}

	public pcsChanged(pcs: number | undefined) {
		this.setState(prevState => ({
			weaning: {
				...prevState.weaning,
				numAlive: pcs!,
				totalWeight: this.state.avgWeight && pcs ? this.state.avgWeight * pcs : undefined,
			},
		}));
	}

	public avgWeightChanged(newNumber: number | undefined) {
		const numAlive = this.state.weaning.numAlive ? this.state.weaning.numAlive : 1;
		const totalWeight = parseFloat((Math.round(numAlive * newNumber! * 100) / 100).toString()).toFixed(2);
		const totalWeightConverted = totalWeight.replace(',', '.');
		this.setState(prevState => ({
			weaning: { ...prevState.weaning, totalWeight: +totalWeightConverted },
			avgWeight: newNumber ? newNumber! : undefined,
		}));
	}

	public avgPriceChanged(newNumber: number | undefined) {
		const numAlive = this.state.weaning.numAlive ? this.state.weaning.numAlive : 1;
		const totalPrice = parseFloat((Math.round(numAlive * newNumber! * 100) / 100).toString()).toFixed(2);
		const totalPriceConverted = totalPrice.replace(',', '.');
		this.setState(prevState => ({
			weaning: { ...prevState.weaning, totalPrice: +totalPriceConverted },
			avgPrice: newNumber!,
		}));
	}

	public totalPriceChanged(newNumber: number | undefined) {
		const numAlive = this.state.weaning.numAlive ? this.state.weaning.numAlive : 1;
		const avgPrice = parseFloat((Math.round((newNumber! / numAlive) * 100) / 100).toString()).toFixed(2);
		const avgPriceConverted = avgPrice.replace(',', '.');
		this.setState(prevState => ({
			weaning: { ...prevState.weaning, totalPrice: newNumber! },
			avgPrice: +avgPriceConverted,
		}));
	}

	public toggleIsNursing() {
		const nursing = deepCopy(this.state.weaning.isNursingSow);

		this.setState(prevState => ({
			weaning: { ...prevState.weaning, isNursingSow: !nursing },
		}));
	}

	public toggleMoveEvent() {
		const toggle = !this.state.registerMoveEvent;
		this.setState({ registerMoveEvent: toggle });
	}

	public render() {
		return <ViewWeb className="register-weaning">{this.renderContent()}</ViewWeb>;
	}

	public renderContent() {
		let formRows = this.getFormRows();
		return (
			<ViewWeb>
				{this.renderButtons()}
				<ViewWeb className="move-event-container-zindex">
					{this.state.registerMoveEvent ? (
						<MoveEventBase
							pregnancyEvent={this.state.weaning}
							date={this.state.weaning.date}
							stableRow={(formRow: FormRow) => formRows.push(formRow)}
							penRow={(formRow: FormRow) => formRows.push(formRow)}
							feedCurveRow={(formRow: FormRow) => formRows.push(formRow)}
							fixedDeviationRow={(formRow: FormRow) => formRows.push(formRow)}
							stemAnimal={this.state.sow}
							update={() => this.forceUpdate()}
							gender={Gender.Female}
							save={(method: () => Promise<boolean>) => (this.moveEventSave = method)}
						/>
					) : null}
					<SkioldFormsWrapper formRows={formRows} containerClassName="forms-wrapper-style" />
				</ViewWeb>
			</ViewWeb>
		);
	}

	public save = async () => {
		let errorMessage = await this.validate();
		if (errorMessage !== '') {
			if (errorMessage !== 'ignore') {
				showAlert(localizedDynamic(errorMessage));
			}
			return;
		}

		if (this.state.registerMoveEvent && this.moveEventSave) {
			const valid = await this.moveEventSave();
			if (!valid) {
				return;
			}
		}
		let weaningCopy = deepCopy(this.state.weaning);

		if (this.props.validationSetup) {
			weaningCopy.weaningCode = this.props.validationSetup.afterAvertion;
		}
		this.props.savePregnancyEvent(weaningCopy);
		this.resetComponent();
		this.editDone();
	};

	public removeEvent = async () => {
		let allow = await ShowConfirmAlert(localized(ExceptionMessage.VALIDATION_WARNING_CONFIRM_DELETEION_WEANING));
		if (!allow) {
			return;
		}
		if (this.state.weaning.id === sortPregnanciesByDate(this.state.animalEvents)[0].id) {
			let pregEvent = deepCopy(this.state.weaning);
			pregEvent.isDeleted = true;
			this.props.deleteLastPregnancyEvent(pregEvent);
			this.editDone();
		}
	};

	public async validate() {
		let animalNumberCheck = validateSowAnimalNumberIsNotDepartured(
			this.state.sow,
			this.props.departuredStemAnimals,
			this.state.animalNumber,
		);
		if (animalNumberCheck !== 'ignore') {
			return animalNumberCheck;
		}

		const basicValidation = createAndEditValidation(
			this.props.validationSetup,
			this.props.generalSettings,
			this.state.weaning,
			this.state.prevEvent,
			this.state.animalEvents,
		);
		if ((await basicValidation) !== '') {
			return basicValidation;
		}

		return '';
	}

	private editDone() {
		if (this.props.closeEditModal) {
			this.props.closeEditModal();
		}
	}

	private getFormRows() {
		let formRows = new Array<FormRow>();
		formRows.push(this.getPregnancyAnimalNumberRow());
		formRows.push(this.getLocationRow());
		formRows.push(this.getLastMoveDateRow());
		formRows.push(this.getDateRow());
		formRows.push(this.getAmountRow());
		formRows.push(this.getAvgWeightRow());
		formRows.push(this.getNursingSowRow());

		//TODO: Removed temporarily - Might be used again
		// formRows.push(this.getAvgPriceRow());
		// formRows.push(this.getTotalPriceRow());
		formRows.push(this.getToggleMoveEventRow());
		return formRows;
	}

	private getPregnancyAnimalNumberRow(): FormRow {
		return {
			name: localized('animalNumber'),
			component: (
				<SkioldPregnancyAnimalInput
					onChangeAnimalNumber={this.animalNumberChanged}
					text={this.state.animalNumber}
					pregnancyEvent={this.state.weaning}
					onAnimalNumberChanged={(animalNumber: string | undefined) => this.setState({ animalNumber })}
					editable={!this.props.pregnancyEventId}
					selectTextOnFocus={!this.props.pregnancyEventId}
					toggleFocus={this.state.toggleFocus}
					className={`${
						this.state.sow && this.state.sow.departureType === DepartureTypes.departureTypeShouldDeparture
							? 'should-departure-list-color-with-margin-change'
							: ''
					}`}
				/>
			),
		};
	}

	private getLocationRow(): FormRow {
		return {
			name: localized('location'),
			component: (
				<SkioldFormTextField>{this.state.sow ? getLocationString(this.state.sow) : ''}</SkioldFormTextField>
			),
		};
	}

	private getLastMoveDateRow(): FormRow {
		return {
			name: localized('lastMoved'),
			component: <SkioldFormTextField>{getDateString(this.state.lastMoveDate)}</SkioldFormTextField>,
		};
	}

	private getDateRow(): FormRow {
		const todaysDatePlus3 = new Date();
		todaysDatePlus3.setDate(todaysDatePlus3.getDate() + 3);
		return {
			name: localized('Date'),
			component: (
				<SkioldDatePicker
					onDateChanged={newDate => this.finishedDateChanged(newDate)}
					selectedDate={this.state.weaning.date!}
					theme={'dark'}
					color={'grey'}
					maxDate={todaysDatePlus3}
				/>
			),
		};
	}

	private getAmountRow(): FormRow {
		return {
			name: localized('Pcs'),
			component: (
				<SkioldFormIntegerInput
					onChangeNumber={newText => this.pcsChanged(newText)}
					text={this.state.weaning.numAlive}
				/>
			),
		};
	}

	private getAvgWeightRow(): FormRow {
		return {
			name: localized('avgWeight'),
			component: (
				<SkioldFormDecimalInput
					onChangeNumber={newText => this.avgWeightChanged(newText)}
					text={
						this.state.avgWeight
							? this.state.avgWeight
							: isNaN(
									this.state.weaning.totalWeight! /
										(this.state.weaning.numAlive ? this.state.weaning.numAlive : 1),
							  )
							? undefined
							: this.state.weaning.totalWeight! /
							  (this.state.weaning.numAlive ? this.state.weaning.numAlive : 1)
					}
				/>
			),
		};
	}

	private getNursingSowRow(): FormRow {
		return {
			name: localized('nursingSow'),
			component: (
				<SkioldCheckbox
					onClick={() => {
						this.toggleIsNursing();
					}}
					containerClassName={'inForm'}
					isChecked={this.state.weaning.isNursingSow!}
				/>
			),
		};
	}

	private getAvgPriceRow(): FormRow {
		return {
			name: localized('avgPrice'),
			component: (
				<SkioldFormDecimalInput
					onChangeNumber={newText => this.avgPriceChanged(newText)}
					text={
						this.state.avgPrice
							? isNaN(this.state.avgPrice)
								? undefined
								: this.state.avgPrice
							: isNaN(
									this.state.weaning.totalPrice! /
										(this.state.weaning.numAlive ? this.state.weaning.numAlive : 1),
							  )
							? undefined
							: this.state.weaning.totalPrice! /
							  (this.state.weaning.numAlive ? this.state.weaning.numAlive : 1)
					}
				/>
			),
		};
	}

	private getTotalPriceRow(): FormRow {
		return {
			name: localized('totalPrice'),
			component: (
				<SkioldFormDecimalInput
					onChangeNumber={newText => this.totalPriceChanged(newText)}
					text={isNaN(this.state.weaning.totalPrice!) ? undefined : this.state.weaning.totalPrice}
				/>
			),
		};
	}

	private getToggleMoveEventRow(): FormRow {
		return {
			name: localized('move'),
			component: (
				<SkioldCheckbox
					onClick={() => {
						this.toggleMoveEvent();
					}}
					containerClassName={'inForm'}
					isChecked={this.state.registerMoveEvent}
					disabled={!isMoveDateValid(this.state.lastMoveDate, this.state.weaning.date)}
				/>
			),
		};
	}

	private renderButtons() {
		return (
			<ViewWeb className="button-view-style-upper-corner">
				{this.props.closeEditModal && (
					<SkioldButton
						title={localized('Close')}
						onPress={() => this.props.closeEditModal!()}
						theme="grey"
					/>
				)}

				{this.state.animalEvents.length > 0 &&
					this.props.pregnancyEventId === sortPregnanciesByDate(this.state.animalEvents)[0].id && (
						<SkioldButton title={localized('Delete')} onPress={this.removeEvent} theme="grey" />
					)}

				<SkioldButton title={localized('Save')} onPress={this.save} />
			</ViewWeb>
		);
	}

	// Called after successfull save to remove one-time values
	private async resetComponent() {
		this.setState({
			sow: StemAnimal.fromJS({ animalNumber: '' } as IStemAnimal),
			animalEvents: [],
			weaning: Averted.fromJS({
				date: this.state.weaning ? this.state.weaning.date : new Date(),
				state: PregnancyState.Averted,
			}),
			avgPrice: undefined,
			avgWeight: this.state.avgWeight,
			animalNumber: undefined,
			toggleFocus: !this.state.toggleFocus,
		});

		if (this.scrollerRef) {
			await this.scrollerRef.scrollToTop();
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterWeaning);
