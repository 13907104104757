import { IMatingBatch, MatingBatch } from 'shared/api/api';
import ObjectID from 'bson-objectid';
import moment from 'moment';

export function repeatManuel(
	timesToRepeat: number,
	weekCycle?: number,
	matingPeriod?: number,
	matingBatches?: IMatingBatch[],
	siteId?: string,
) {
	let repeatBatches: IMatingBatch[] = [];
	const daysToAdd = weekCycle! * matingPeriod!;
	if (matingBatches) {
		for (let i = 1; i <= timesToRepeat; i++) {
			for (let batch of matingBatches) {
				let newBatch = MatingBatch.fromJS({
					siteId: siteId,
					id: new ObjectID().toHexString(),
					batchNumber: batch.batchNumber,
					matingPeriodStart: moment(batch.matingPeriodStart)
						.add(daysToAdd * i, 'day')
						.toDate(),
					matingPeriodEnd: moment(batch.matingPeriodEnd)
						.add(daysToAdd * i, 'day')
						.toDate(),
					farrowingPeriodStart: moment(batch.farrowingPeriodStart)
						.add(daysToAdd * i, 'day')
						.toDate(),
					farrowingPeriodEnd: moment(batch.farrowingPeriodEnd)
						.add(daysToAdd * i, 'day')
						.toDate(),
				} as IMatingBatch);
				repeatBatches.push(newBatch);
			}
		}
	}
	return repeatBatches;
}

export function findMinimumDate(sortedPropsMatingBatches: IMatingBatch[], datesToHighlight?: Date[]) {
	const now = new Date();
	let matingBatch = sortedPropsMatingBatches.find(batch => batch.matingPeriodStart! >= now);
	let minimumDate;

	if (matingBatch) {
		minimumDate = matingBatch.matingPeriodStart!;
	}
	if (!matingBatch && sortedPropsMatingBatches.length > 0) {
		matingBatch = sortedPropsMatingBatches[sortedPropsMatingBatches.length - 1];
		if (matingBatch) {
			minimumDate = moment(matingBatch.matingPeriodEnd!).add(1, 'second').toDate();
			if (datesToHighlight) {
				datesToHighlight.push(minimumDate);
			}
		}
	}
	return minimumDate;
}
