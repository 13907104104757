
import actionCreatorFactory from 'typescript-fsa';
import * as types from './types';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';

import { IBMPData } from 'shared/api/api';

const actionCreator = actionCreatorFactory(GenActionFactoryName('BMPDatas'));

export const getSyncData = actionCreator.async<{ lastSyncDate: Date }, IBMPData[]>(
    types.SYNC_BMP
);
