import actionCreatorFactory from 'typescript-fsa';
import { TableColumnWidthInfoGeneric } from './types';

const actionCreator = actionCreatorFactory('tableSettings');
export const RESET_TABLE_SETTINGS = 'RESET_TABLE_SETTINGS';
export const SET_COLUMN_SIZES = 'SET_COLUMN_SIZES';

export const resetTableSettingsAction = actionCreator.async<{}, void>(RESET_TABLE_SETTINGS);

export const setColumnSizeAction = actionCreator<{ key: string; data: Array<TableColumnWidthInfoGeneric<string>> }>(
	SET_COLUMN_SIZES
);
