import React from 'react';
import { Option } from 'react-dropdown';
import IsEqual from 'react-fast-compare';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AnimalType } from 'shared/api/api';
import { GetSyncData as getSyncDataProductionReportSettings } from 'shared/state/ducks/production-report-settings/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { GrowthPigTuneProductionReportOverview } from 'web/view/components/growth-pigs/growth-pigs-reports/growth-pig-tune-production-report/growth-pig-tune-production-report-overview';
import PageContainer from 'web/view/components/page-container/page-container';
import { SkioldDropdown } from 'web/view/components/skiold-components/skiold-dropdown/skiold-dropdown';
import ProductionReport from 'web/view/components/stem-animal/animal-report-list/production-report/production-report';
import { Heading } from 'web/view/components/utils/heading';
import { ViewWeb } from 'web/view/components/utils/web-view';
import './growth-pigs-report-overview.scss';

const mapStateToProps = (state: WebAppState) => {
	return {
		siteId: state.profile.active!.siteId,
		productionReportSettings: state.productionReportSetting.entities.find(
			a =>
				a.animaltype === AnimalType.Weaner ||
				a.animaltype === AnimalType.FRATS ||
				a.animaltype === AnimalType.Finisher,
		),
		navigation: state.navigation,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	getAnimalReportSettingsBySiteId: () => getSyncDataProductionReportSettings()(dispatch),
});

export interface State {
	selected: Option;
	reportOptions: Option[];
	renderTopLeft: JSX.Element;
	renderTopRight: JSX.Element;
	renderTopMiddle?: JSX.Element;
	animalType: AnimalType;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class GrowthPigsReportOverview extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			selected: { value: ' ', label: ' ' },
			renderTopRight: <ViewWeb />,
			renderTopLeft: <ViewWeb />,
			renderTopMiddle: <ViewWeb />,
			reportOptions: [],
			animalType: this.props.navigation.query
				? (this.props.navigation.query['type'] as AnimalType)
				: AnimalType.FRATS,
		};
	}

	public componentDidUpdate(prevProps: Props, prevState: State) {
		const animalType = this.props.navigation.query
			? (this.props.navigation.query['type'] as AnimalType)
			: AnimalType.FRATS;
		if (!IsEqual(prevState.animalType, animalType)) {
			const state = { ...this.state };
			state.animalType = animalType;
			if (state.selected.value === 'tuneProductionReport') {
				state.selected = {
					label: localized('tuneProductionReport' + animalType),
					value: 'tuneProductionReport',
				};
			}
			this.setState(state);
		}
	}

	public componentDidMount() {
		if (this.props.siteId) {
			this.props.getAnimalReportSettingsBySiteId();
		}
	}

	private getProductionType = () =>
		this.props.navigation.query ? (this.props.navigation.query['type'] as AnimalType) : AnimalType.FRATS;

	public render() {
		return (
			<PageContainer className="growth-pigs-report-overview">
				<Heading text={localized('CHOOSEREPORT')} />
				<ViewWeb className="general-padding">
					{/* Header row */}
					<ViewWeb className="animal-report-top-container">
						{/* Left header column */}
						<ViewWeb className="start sizing vertical-center">{this.state.renderTopLeft}</ViewWeb>
						{/* Center header column */}
						<ViewWeb className="align-center">
							<SkioldDropdown
								onValueChanged={this.selectedReportChanged}
								selectedValue={this.state.selected}
								items={this.generateOptionData()}
								containerClassName="select-report-type sizing"
							/>
							{this.state.renderTopMiddle}
						</ViewWeb>

						{/* Right header column */}
						<ViewWeb className="end sizing vertical-center">{this.state.renderTopRight}</ViewWeb>
					</ViewWeb>

					{this.state.selected && this.state.selected.value === 'pReport' && (
						<ProductionReport
							animalType={this.state.animalType}
							topLeft={this.setTopLeft}
							topRight={this.setTopRight}
						/>
					)}
					{this.state.selected && this.state.selected.value === 'tuneProductionReport' && (
						<GrowthPigTuneProductionReportOverview
							animalType={this.state.animalType}
							topLeft={this.setTopLeft}
							topRight={this.setTopRight}
						/>
					)}
				</ViewWeb>
			</PageContainer>
		);
	}

	private setTopLeft = (res: JSX.Element) => {
		this.setState({ renderTopLeft: res });
	};

	private setTopRight = (res: JSX.Element) => {
		this.setState({ renderTopRight: res });
	};

	private setTopMiddle = (res: JSX.Element) => {
		this.setState({ renderTopMiddle: res });
	};

	private generateOptionData = () => [
		{ label: localized('pReport'), value: 'pReport' },
		{ label: localized('tuneProductionReport' + this.getProductionType()), value: 'tuneProductionReport' },
	];

	private selectedReportChanged = (option: Option) => {
		if (option.value !== this.state.selected.value) {
			this.setState({
				selected: option,
				renderTopRight: <ViewWeb />,
				renderTopLeft: <ViewWeb />,
				renderTopMiddle: undefined,
			});
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(GrowthPigsReportOverview);
