import React from 'react';
import { Option } from 'react-dropdown';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
	IMoveToFarrowingLocation,
	IMoveToPregnancyLocation,
	IWorkListSetting,
	MoveToFarrowingLocation,
	MoveToLocationTypes,
	WorkListType,
} from 'shared/api/api';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { deepCopy } from 'shared/helpers/general-helpers';
import {
	GetMoveToOptions,
	ResetDataOnMoveListSetups,
} from 'shared/helpers/work-list-helpers/move-to-helpers/moving-lists-helper';
import { SaveWorkListSetting } from 'shared/state/ducks/work-list-settings/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { showAlert } from '../../skiold-alert/skiold-alert';
import { SkioldButton } from '../../skiold-components/skiold-button/skiold-button';
import { SkioldFormDropdown } from '../../skiold-components/skiold-dropdown/skiold-form-dropdown';
import { SkioldFormsWrapper } from '../../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from '../../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { SkioldFormIntegerInput } from '../../skiold-components/skiold-integer-input/skiold-form-integer-input';
import { getAvailablityRow, getSkioldInputRow } from '../work-list-helper';
import '../list-setup.scss';
import { SkioldCheckbox } from '../../skiold-components/skiold-checkbox/skiold-checkbox';
import './move-to-farrowing-setup.scss';

interface PropsFromParent {
	workListType: WorkListType;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		workListSettings: state.workListSettings.entities,
		siteId: state.profile.active!.siteId,
		//   feedCurves: state.site.site.feedCurves,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	saveWorkListSetting: (movetoPregnancySetup: IMoveToFarrowingLocation) =>
		SaveWorkListSetting(movetoPregnancySetup)(dispatch),
});

export interface MoveToFarrowingSetupState {
	movetoPregSetup: IMoveToFarrowingLocation;
	selectedOption: Option;
	options: Option[];
	usesPens: boolean;
	// selectedFeedCurve: Option;
	// feedCurves: Option[];
}

type MoveToFarrowingSetupProps = ReturnType<typeof mapStateToProps> &
	ReturnType<typeof mapDispatchToProps> &
	PropsFromParent;

class MoveToFarrowingSetup extends React.PureComponent<MoveToFarrowingSetupProps, MoveToFarrowingSetupState> {
	constructor(props: MoveToFarrowingSetupProps) {
		super(props);

		this.state = {
			movetoPregSetup: this.getWorkListSetting(props.workListType),
			options: GetMoveToOptions(),
			selectedOption: { label: '', value: '' },
			usesPens: false,
		};
	}

	public componentDidMount() {
		if (this.state.movetoPregSetup.selectedType !== undefined) {
			this.setState({
				selectedOption: {
					label: localized(this.state.movetoPregSetup.selectedType),
					value: this.state.movetoPregSetup.selectedType,
				},
			});
		}
	}

	public toggleShowOnWeb = () => {
		this.setState(prevState => ({
			movetoPregSetup: { ...prevState.movetoPregSetup, showOnWeb: !this.state.movetoPregSetup.showOnWeb },
		}));
	};

	public toggleShowOnApp = () => {
		this.setState(prevState => ({
			movetoPregSetup: { ...prevState.movetoPregSetup, showOnApp: !this.state.movetoPregSetup.showOnApp },
		}));
	};

	public moveToPregnatSetupChanged = (setup: IMoveToFarrowingLocation) => {
		this.setState({
			movetoPregSetup: setup,
		});
	};

	private animalToListChanged = (option: Option) => {
		this.setState(prevState => ({
			movetoPregSetup: {
				...prevState.movetoPregSetup,
				selectedType: option.value as MoveToLocationTypes,
			},
			selectedOption: option,
		}));
	};

	public render() {
		return (
			<ViewWeb className="list-setup">
				<ViewWeb className="view-container">
					<TextWeb className="setup-header">{localized('Setup')}</TextWeb>
					<SkioldFormsWrapper formRows={this.getFormRows()} containerClassName="wrapper-container" />
					{this.renderButtons()}
				</ViewWeb>
			</ViewWeb>
		);
	}

	private save = () => {
		if (!this.validate(this.state.movetoPregSetup)) {
			return;
		}

		let movetoPregSetup = deepCopy(this.state.movetoPregSetup);
		movetoPregSetup.selectedType = this.state.selectedOption.value as MoveToLocationTypes;
		ResetDataOnMoveListSetups(movetoPregSetup);
		movetoPregSetup.siteId = this.props.siteId;
		this.setState({ movetoPregSetup });
		this.props.saveWorkListSetting(movetoPregSetup);
	};

	private validate(listSetting: IMoveToFarrowingLocation) {
		if (!listSetting.selectedType) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_CHOOSE_ANIMAL_NOT_SET));
			return false;
		}
		if (listSetting.selectedType === MoveToLocationTypes.MatingBatch) {
			if (!listSetting.batchCycleDays) {
				showAlert(localized(ExceptionMessage.VALIDATION_ERROR_CYCLEDAYS_NOT_SET));
				return false;
			}
		}
		if (listSetting.selectedType === MoveToLocationTypes.PregnacyDays) {
			if (
				listSetting.cycleDaysFrom === undefined ||
				listSetting.cycleDaysFrom === null ||
				listSetting.cycleDaysFrom < 0
			) {
				showAlert(localized(ExceptionMessage.VALIDATION_ERROR_PREGNANT_FROM_IS_EMPTY_OR_NEGATIVE));
				return false;
			}
			if (
				listSetting.cycleDaysTo === undefined ||
				listSetting.cycleDaysTo === null ||
				listSetting.cycleDaysTo < listSetting.cycleDaysFrom
			) {
				showAlert(localized(ExceptionMessage.VALIDATION_ERROR_PREGNANT_TO_IS_EMPTY_OR_NEGATIVE));
				return false;
			}
		}

		return true;
	}

	private getFormRows() {
		let formRows = new Array<FormRow>();

		formRows.push(this.getBuildingHeader());

		formRows.push(
			getSkioldInputRow(
				setup => this.moveToPregnatSetupChanged(setup),
				this.state.movetoPregSetup,
				'name',
				localized('name'),
			),
		);

		formRows.push(this.getAnimalToListRow());

		if (this.state.selectedOption.value === MoveToLocationTypes.MatingBatch) {
			formRows.push({
				name: localized('pregnantDays'),
				component: (
					<SkioldFormIntegerInput
						onChangeNumber={this.batchCycleDaysChanged}
						text={this.state.movetoPregSetup.batchCycleDays}
					/>
				),
			});
		}
		if (this.state.selectedOption.value === MoveToLocationTypes.PregnacyDays) {
			formRows.push({
				name: localized('pregnantDays'),
				component: (
					<ViewWeb className="nursing-from-to-container">
						<ViewWeb className="flex-one-row form-padding">
							<TextWeb>{localized('from')}</TextWeb>
							<SkioldFormIntegerInput
								className="days-input"
								onChangeNumber={this.cycleDaysFromDateChanged}
								text={this.state.movetoPregSetup.cycleDaysFrom}
							/>
						</ViewWeb>
						<ViewWeb className="flex-one-row">
							<TextWeb>{localized('to')}</TextWeb>
							<SkioldFormIntegerInput
								className="days-input"
								onChangeNumber={this.cycleDaysToDateChanged}
								text={this.state.movetoPregSetup.cycleDaysTo}
							/>
						</ViewWeb>
					</ViewWeb>
				),
			});
		}
		formRows.push(this.getNegativeCycleDaysRow());
		formRows.push(
			getAvailablityRow(
				this.toggleShowOnWeb,
				this.toggleShowOnApp,
				this.state.movetoPregSetup.showOnWeb!,
				this.state.movetoPregSetup.showOnApp!,
			),
		);
		return formRows;
	}
	private cycleDaysFromDateChanged = (newNumber: number | undefined) =>
		this.valueInMoveToPregnantSetupChanged(newNumber, 'cycleDaysFrom');

	private cycleDaysToDateChanged = (newNumber: number | undefined) =>
		this.valueInMoveToPregnantSetupChanged(newNumber, 'cycleDaysTo');

	private batchCycleDaysChanged = (newNumber: number | undefined) =>
		this.valueInMoveToPregnantSetupChanged(newNumber, 'batchCycleDays');

	private valueInMoveToPregnantSetupChanged = (value: any, key: keyof IMoveToPregnancyLocation) => {
		this.setState(prevState => ({
			movetoPregSetup: {
				...prevState.movetoPregSetup,
				[key]: value,
			},
		}));
	};

	private SetNegativeCycleDaysToFalse = () => {
		this.setState(prevState => ({
			movetoPregSetup: {
				...prevState.movetoPregSetup,
				negativeCycleDays: false,
			},
		}));
	};
	private SetNegativeCycleDaysToTrue = () => {
		this.setState(prevState => ({
			movetoPregSetup: {
				...prevState.movetoPregSetup,
				negativeCycleDays: true,
			},
		}));
	};

	private getBuildingHeader(): FormRow {
		return {
			header: localized(this.props.workListType),
		};
	}

	private getAnimalToListRow(): FormRow {
		return {
			name: localized('chooseAnimalToList'),
			component: (
				<SkioldFormDropdown
					items={this.state.options}
					selectedValue={this.state.selectedOption}
					onValueChanged={this.animalToListChanged}
				/>
			),
		};
	}

	private getNegativeCycleDaysRow(): FormRow {
		return {
			name: localized('cycleDaysShownNegative'),
			component: (
				<ViewWeb className="two-checkbox-container">
					<SkioldCheckbox
						onClick={this.SetNegativeCycleDaysToTrue}
						isChecked={this.state.movetoPregSetup.negativeCycleDays}
					/>
					<TextWeb>{localized('True')}</TextWeb>
					<SkioldCheckbox
						onClick={this.SetNegativeCycleDaysToFalse}
						isChecked={this.state.movetoPregSetup.negativeCycleDays ? false : true}
					/>
					<TextWeb>{localized('False')}</TextWeb>
				</ViewWeb>
			),
		};
	}

	private getWorkListSetting(type: WorkListType) {
		let workListSetting = this.props.workListSettings.find(
			setting => setting.type === type,
		) as MoveToFarrowingLocation;
		if (!workListSetting) {
			workListSetting = MoveToFarrowingLocation.fromJS({ type });
		}
		return workListSetting;
	}

	private renderButtons() {
		let buttons = (
			<ViewWeb className="buttons-container">
				<SkioldButton title={localized('Save')} onPress={this.save} />
			</ViewWeb>
		);

		return buttons;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MoveToFarrowingSetup);
