import { ShowConfirmAlert, showCustomKeyValueAlert } from 'web/view/components/skiold-alert/skiold-alert';
import { ValidationInterval, IStemAnimal } from 'shared/api/api';
import { calculateAgeInWeeks, calculateDays } from 'shared/helpers/general-helpers';
import { localized, localizedInterpolation } from 'shared/state/i18n/i18n';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { StoreContainer } from 'shared/state/store-container';
import { DefaultValidationValues } from 'web/view/pages/validation-setup/default-validation-values';
import { IStemAnimalDepartured } from 'shared/state/ducks/stem-animals';

export async function MatingAgeCheck(startDate: Date, endDate: Date, validationInterval: ValidationInterval) {
	const age = calculateAgeInWeeks(startDate, endDate);
	if (validationInterval.minus === 0 && validationInterval.plan === 0 && validationInterval.plus === 0) {
		return 'ignore';
	}
	//@ts-ignore
	if (age < validationInterval.minus! || age > validationInterval.plus!) {
		let allow = await ShowConfirmAlert(localized(ExceptionMessage.VALIDATION_WARNING_MATING_AGE_OUT_OF_INTERVAL));
		if (!allow) {
			//Don't show error message
			return 'ignore';
		}
	}

	return '';
}

export async function WeaningToMatingCheck(
	startDate: Date,
	endDate: Date,
	validationInterval: ValidationInterval,
	stemAnimalId: string,
) {
	const weaningToMating = calculateDays(startDate, endDate);

	if (weaningToMating < validationInterval.minus! || weaningToMating > validationInterval.plus!) {
		const state = StoreContainer.getState();
		let stemAnimal = state.stemAnimals.entities
			.concat(state.stemAnimals.departuredAnimals as IStemAnimal[])
			.find(a => a.id === stemAnimalId)!;
		let dontAllow = await ShowConfirmAlert(
			localized('animalNr') +
				' ' +
				stemAnimal.animalNumber! +
				' - ' +
				localized(ExceptionMessage.VALIDATION_WARNING_WEANING_TO_MATING_OUT_OF_INTERVAL),
		);
		if (!dontAllow) {
			//Don't show error message
			return 'ignore';
		}
	}

	return '';
}

export async function MatingToFarrowingCheck(startDate: Date, endDate: Date, validationInterval: ValidationInterval) {
	const matingToFarrowing = calculateDays(startDate, endDate);
	//Check if lower and upper limits is valid
	if (
		matingToFarrowing < DefaultValidationValues.MatingToFarrowing_LOWER_LIMIT ||
		matingToFarrowing > DefaultValidationValues.MatingToFarrowing_UPPER_LIMIT
	) {
		await showCustomKeyValueAlert(
			localizedInterpolation(
				ExceptionMessage.VALIDATION_ERROR_INVALID_MATING_TO_FARROWING_INTERVAL,
				matingToFarrowing,
			),
			[{ label: localized('Ok'), value: false }],
		);
		return 'ignore';
	}
	if (
		(validationInterval.minus && matingToFarrowing < validationInterval.minus && validationInterval.minus !== 0) ||
		(validationInterval.plus && matingToFarrowing > validationInterval.plus! && validationInterval.plus !== 0)
	) {
		let allow = await ShowConfirmAlert(
			localizedInterpolation(
				ExceptionMessage.VALIDATION_WARNING_MATING_TO_FARROWING_OUT_OF_INTERVAL,
				matingToFarrowing,
			),
		);
		if (!allow) {
			//Don't show error message
			return 'ignore';
		}
	}

	return '';
}

export async function FarrowingToWeaningCheck(
	startDate: Date,
	endDate: Date,
	validationInterval: ValidationInterval,
	stemAnimalId: string,
) {
	const farrowingToWeaning = calculateDays(startDate, endDate);
	if (
		(farrowingToWeaning < validationInterval.plan! && farrowingToWeaning < validationInterval.minus!) ||
		(farrowingToWeaning > validationInterval.plan! && farrowingToWeaning > validationInterval.plus!)
	) {
		const state = StoreContainer.getState();
		let stemAnimal = state.stemAnimals.entities
			.concat(state.stemAnimals.departuredAnimals as IStemAnimal[])
			.find(a => a.id === stemAnimalId)!;
		let allow = await ShowConfirmAlert(
			ExceptionMessage.VALIDATION_WARNING_FARROWING_TO_WEANING_OUT_OF_INTERVAL(
				stemAnimal.animalNumber,
				farrowingToWeaning,
			),
		);
		if (!allow) {
			//Don't show error message
			return 'ignore';
		}
	}

	return '';
}

export function validateSowAnimalNumberIsNotDepartured(
	stemAnimal: IStemAnimal,
	departuredSows: Array<IStemAnimal | IStemAnimalDepartured>,
	animalNumber: string | undefined,
) {
	if (stemAnimal.animalNumber === undefined && departuredSows.find(a => a.animalNumber === animalNumber)) {
		return ExceptionMessage.VALIDATION_ERROR_ANIMAL_NUM_IS_DEPARTURED;
	} else {
		return 'ignore';
	}
}
