import ObjectID from 'bson-objectid';
import moment from 'moment';
import { Dispatch } from 'redux';
import {
	GrowthPigDepartureEventDto,
	GrowthPigDepartureFromPenEventDto,
	GrowthPigEntranceEventDto,
	GrowthPigEventType,
	GrowthPigMoveEventDto,
	GrowthPigPigletMoveEventDtoAndUpdateEvents,
	GrowthPigsEvent,
	IGrowthPigEntranceEventDto,
	IStemAnimal,
} from 'shared/api/api';
import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import { StoreContainer } from 'shared/state/store-container';
import { SetRetentionTimeOnPens } from '../locations/operations';
import * as Action from './actions';
import { GpeDepartureCount } from './types';
import { updateYoungAnimal } from '../stem-animals/actions';
import { UpdateYoungAnimal } from '../stem-animals/operations';

export const getGrowthPigsEventsBySiteId = () => {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;
	const lastSyncDate = state.growthPigEvents.lastSyncDate;
	const dailyLastSync = state.growthPigEvents.dailyLastSync;
	return AsyncOperationBuilder2(
		Action.getGrowthPigsEventsBySiteId,
		cl => cl.growthPigsEvent_GetBySiteIdV5(siteId, lastSyncDate, dailyLastSync),
		{
			siteId,
			lastSyncDate,
		},
	);
};

export const getGrowthPigsEventsByDates = (dateFrom: Date, dateTo: Date) => {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;
	const dateFromToUse = moment(dateFrom, 'DD/MM/YYYY').startOf('day').toDate();
	const dateToToUse = moment(dateTo, 'DD/MM/YYYY').endOf('day').toDate();
	return AsyncOperationBuilder2(
		Action.GetGrowthPigEventsByDates,
		cl => cl.growthPigsEvent_GetGrowthPigEventsByDates(siteId, dateFromToUse, dateToToUse),
		undefined,
	);
};

export const GetGrowthPigEventRegulations = (dateFrom: Date, dateTo: Date) => {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;
	const dateFromToUse = moment(dateFrom, 'DD/MM/YYYY').startOf('day').toDate();
	const dateToToUse = moment(dateTo, 'DD/MM/YYYY').endOf('day').toDate();
	return AsyncOperationBuilder2(
		Action.GetGrowthPigEventsRegulationsByDates,
		cl => cl.growthPigsEvent_GetGrowthPigEventRegulations(siteId, dateFromToUse, dateToToUse),
		undefined,
	);
};

export const getGrowthPigsEventsByDatesDashboard = (dateFrom: Date, dateTo: Date) => {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;
	const dateFromToUse = moment(dateFrom, 'DD/MM/YYYY').startOf('day').toDate();
	const dateToToUse = moment(dateTo, 'DD/MM/YYYY').endOf('day').toDate();
	return AsyncOperationBuilder2(
		Action.GetGrowthPigEventsByDatesDashboard,
		cl => cl.growthPigsEvent_GetGrowthPigEventsByDates(siteId, dateFromToUse, dateToToUse),
		undefined,
	);
};

export const getGrowthPigsMoveEventsByDates = (dateFrom: Date, dateTo: Date) => {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;
	const dateFromToUse = moment(dateFrom, 'DD/MM/YYYY').startOf('day').toDate();
	const dateToToUse = moment(dateTo, 'DD/MM/YYYY').endOf('day').toDate();
	return AsyncOperationBuilder2(
		Action.GetEventTypesGrowthPigEventsByDates,
		cl => cl.growthPigsEvent_GetByGrowthPigMoveEventsByDates(siteId, dateFromToUse, dateToToUse),
		GrowthPigEventType.GrowthPigMoveEvent,
	);
};

export const getGrowthPigsEntranceEventsByDates = (dateFrom: Date, dateTo: Date) => {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;
	const dateFromToUse = moment(dateFrom, 'DD/MM/YYYY').startOf('day').toDate();
	const dateToToUse = moment(dateTo, 'DD/MM/YYYY').endOf('day').toDate();
	return AsyncOperationBuilder2(
		Action.GetEventTypesGrowthPigEventsByDates,
		cl => cl.growthPigsEvent_GetByGrowthPigEntranceEventsByDates(siteId, dateFromToUse, dateToToUse),
		GrowthPigEventType.GrowthPigEntranceEvent,
	);
};

export const getGrowthPigsDepartureEventsByDates = (dateFrom: Date, dateTo: Date) => {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;
	const dateFromToUse = moment(dateFrom, 'DD/MM/YYYY').startOf('day').toDate();
	const dateToToUse = moment(dateTo, 'DD/MM/YYYY').endOf('day').toDate();
	return AsyncOperationBuilder2(
		Action.GetEventTypesGrowthPigEventsByDates,
		cl => cl.growthPigsEvent_GetGrowthPigDepartureEventsByDates(siteId, dateFromToUse, dateToToUse),
		GrowthPigEventType.GrowthPigDepartureEvent,
	);
};

const setGrowthPigEventType = (events: GrowthPigEntranceEventDto[]) => {
	let gpes: GrowthPigEntranceEventDto[] = [];

	events.forEach(gpe => {
		let newGpe = GrowthPigEntranceEventDto.fromJS({});
		newGpe.init({
			...gpe,
			growthPigEventType: GrowthPigEventType.GrowthPigEntranceEvent,
		} as GrowthPigEntranceEventDto);
		gpes.push(newGpe);
	});
	return gpes;
};

export const upsertGrowthPigsEntranceEvents = (events: GrowthPigEntranceEventDto[], dontHandlePens?: boolean) => {
	const dataWithBundleId = setGpeBundleIdentifierArray(events);
	const eventsWithType = setGrowthPigEventType(dataWithBundleId);

	return async (dispatch: Dispatch<any>) => {
		// Set retention time on to pen
		let pensToUpdate: { [key: string]: Date } = {};
		if (!dontHandlePens && events && events.length > 0) {
			events.forEach(ev => {
				if (ev.toPenId && ev.retentionDate) {
					pensToUpdate[ev.toPenId] = ev.retentionDate;
				}
			});
			SetRetentionTimeOnPens(pensToUpdate)(dispatch);
		}

		dispatch(Action.InitialSaveGrowthPigEvent.started((eventsWithType as unknown[]) as GrowthPigsEvent[]));
		dispatch(
			AsyncOperationBuilder2(
				Action.upsertEntranceEvent,
				cl => cl.growthPigsEvent_PostGrowthPigEntranceEventDto(eventsWithType),
				eventsWithType,
			),
		);
	};
};

const setGrowthPigMovePigletEventType = (events: GrowthPigMoveEventDto[]) => {
	let gpes: GrowthPigMoveEventDto[] = [];

	events.forEach(gpe => {
		let newGpe = GrowthPigMoveEventDto.fromJS({});
		newGpe.init({ ...gpe, growthPigEventType: GrowthPigEventType.PigletMovedEvent } as GrowthPigMoveEventDto);
		gpes.push(newGpe);
	});
	return gpes;
};

export const upsertGrowthPigsPigletMoveEventsAndUpdateEvents = (
	events: GrowthPigMoveEventDto[],
	entranceEvents: GrowthPigsEvent[],
) => {
	const dataWithBundleId = setGpeBundleIdentifierArray(events);
	const eventsWithType = setGrowthPigMovePigletEventType(dataWithBundleId);

	const item = new GrowthPigPigletMoveEventDtoAndUpdateEvents({
		growthPigEntranceEventDto: eventsWithType,
		growthPigsEvents: entranceEvents,
	});

	return async (dispatch: Dispatch<any>) => {
		dispatch(Action.InitialSaveGrowthPigEvent.started((eventsWithType as unknown[]) as GrowthPigsEvent[]));
		dispatch(
			AsyncOperationBuilder2(
				Action.upsertGrowthPigsPigletMoveEventsAndUpdateEvents,
				cl => cl.growthPigsEvent_GrowthPigPigletMoveEventDtoAndUpdateEvents(item),
				item,
			),
		);
	};
};

export const upsertGrowthPigsPigletMoveEventsAndUpsertEvents = (events: GrowthPigMoveEventDto[]) => {
	const dataWithBundleId = setGpeBundleIdentifierArray(events);
	const eventsWithType = setGrowthPigMovePigletEventType(dataWithBundleId);

	return AsyncOperationBuilder2(
		Action.upsertEntranceEvent,
		cl => cl.growthPigsEvent_PostGrowthPigPigletMoveEventDto(eventsWithType),
		eventsWithType,
	);
};

const setGrowthPigMoveEventType = (events: GrowthPigMoveEventDto[]) => {
	let gpes: GrowthPigMoveEventDto[] = [];

	events.forEach(gpe => {
		let newGpe = GrowthPigMoveEventDto.fromJS({});
		newGpe.init({ ...gpe, growthPigEventType: GrowthPigEventType.GrowthPigMoveEvent } as GrowthPigMoveEventDto);
		gpes.push(newGpe);
	});
	return gpes;
};

export const upsertGrowthPigsMoveEvents = (events: GrowthPigMoveEventDto[], dontHandlePens?: boolean) => {
	const dataWithBundleId = setGpeBundleIdentifierArray(events);
	const eventsWithType = setGrowthPigMoveEventType(dataWithBundleId);
	const state = StoreContainer.getState();
	return async (dispatch: Dispatch<any>) => {
		// Set retention time on to pen
		let pensToUpdate: { [key: string]: Date } = {};

		// Only handle pens, if set. Used to handle offline registrations
		if (!dontHandlePens && events && events.length > 0) {
			events.forEach(ev => {
				const toPen = state.locations.pens.find(pen => pen.id === ev.toPenId);
				if (toPen && toPen.id && ev.retentionDate) {
					if (!toPen.retentionTime || toPen.retentionTime < ev.retentionDate) {
						// Update retention time, if newer than current
						pensToUpdate[toPen.id] =
							!pensToUpdate[toPen.id] ||
							(pensToUpdate[toPen.id] && pensToUpdate[toPen.id]! > ev.retentionDate)
								? ev.retentionDate
								: pensToUpdate[toPen.id];
					}
				}
			});
			SetRetentionTimeOnPens(pensToUpdate)(dispatch);
		}

		dispatch(Action.InitialSaveGrowthPigEvent.started((eventsWithType as unknown[]) as GrowthPigsEvent[]));
		dispatch(
			AsyncOperationBuilder2(
				Action.upsertMoveEvent,
				cl => cl.growthPigsEvent_PostGrowthPigMoveEventDto(eventsWithType),
				eventsWithType,
			),
		);
	};
};

export const upsertGrowthPigsEvent = (event: GrowthPigsEvent) => {
	if (!event.bundleIdentifier && !event.id) {
		event.bundleIdentifier = new ObjectID().toHexString();
	}
	if (!event.id) {
		event.id = new ObjectID().toHexString();
	}

	return async (dispatch: Dispatch<any>) => {
		dispatch(
			AsyncOperationBuilder2(
				Action.upsertGrowthPigsEvent,
				cl => cl.growthPigsEvent_UpsertGrowthPigsEvent(event),
				event,
			),
		);
	};
};

export const removeEventsByBundleId = (bundleId: string) => {
	const state = StoreContainer.getState();
	const gpesToDelete = state.growthPigEvents.growthPigEvents
		.filter(e => e.id && e.bundleIdentifier === bundleId && e.growthPigEventType !== GrowthPigEventType.Regulation)
		.map(e => e.id!);

	return async (dispatch: Dispatch<any>) => {
		removeGrowthPigsEventByIds(gpesToDelete)(dispatch);
	};
};

export const removeGrowthPigsEventByIds = (gpeIds: string[]) => {
	const state = StoreContainer.getState();
	const gpeToRemove = state.growthPigEvents.growthPigEvents
		.filter(gpe => gpe.id && gpeIds.includes(gpe.id))
		.map(gpe => GrowthPigsEvent.fromJS({ ...gpe, isDeleted: true }));
	const youngAnimal = state.stemAnimals.departuredAnimals.find(animal =>
		gpeToRemove.map(gpe => gpe.animalIdNumber).includes(animal.idNumber),
	) as IStemAnimal;

	if (youngAnimal) {
		youngAnimal.departureDate = undefined;
		youngAnimal.departureType = undefined;
		youngAnimal.departureReasonId = undefined;
	}

	return async (dispatch: Dispatch<any>) => {
		UpdateYoungAnimal(youngAnimal)(dispatch);
		gpeToRemove.forEach(gpeDeleted => {
			dispatch(
				AsyncOperationBuilder2(
					Action.upsertGrowthPigsEvent,
					cl => cl.growthPigsEvent_UpsertGrowthPigsEvent(gpeDeleted),
					gpeDeleted,
				),
			);
		});
	};
};

const setGrowthPigDepartEventType = (events: GrowthPigDepartureEventDto[]) => {
	let gpes: GrowthPigDepartureEventDto[] = [];

	events.forEach(gpe => {
		if (gpe.growthPigEventType !== GrowthPigEventType.Regulation) {
			let newGpe = GrowthPigDepartureEventDto.fromJS({});
			newGpe.init({
				...gpe,
				growthPigEventType: GrowthPigEventType.GrowthPigDepartureEvent,
			} as GrowthPigDepartureEventDto);
			gpes.push(newGpe);
		} else {
			gpes.push(gpe);
		}
	});
	return gpes;
};

export const upsertGrowthPigsDepartureEvents = (events: GrowthPigDepartureEventDto[]) => {
	const dataWithBundleId = setGpeBundleIdentifierArray(events);
	const eventsWithType = setGrowthPigDepartEventType(dataWithBundleId);

	return async (dispatch: Dispatch<any>) => {
		dispatch(Action.InitialSaveGrowthPigEvent.started((eventsWithType as unknown[]) as GrowthPigsEvent[]));
		dispatch(
			AsyncOperationBuilder2(
				Action.upsertDepartureEvent,
				cl => cl.growthPigsEvent_PostGrowthPigDepartureEventDto(eventsWithType),
				eventsWithType,
			),
		);
	};
};

const setGrowthPigDepartFromPenEventType = (events: GrowthPigDepartureFromPenEventDto[]) => {
	let gpes: GrowthPigDepartureFromPenEventDto[] = [];

	events.forEach(gpe => {
		let newGpe = GrowthPigDepartureFromPenEventDto.fromJS({});
		newGpe.init({
			...gpe,
			growthPigEventType: GrowthPigEventType.GrowthPigPenDepartureEvent,
		} as GrowthPigDepartureFromPenEventDto);
		gpes.push(newGpe);
	});
	return gpes;
};

export const upsertGrowthPigsDepartureFromPenEvents = (events: GrowthPigDepartureFromPenEventDto[]) => {
	const dataWithBundleId = setGpeBundleIdentifierArray(events);
	const eventsWithType = setGrowthPigDepartFromPenEventType(dataWithBundleId);

	return (dispatch: Dispatch<any>) => {
		dispatch(Action.InitialSaveGrowthPigEvent.started((eventsWithType as unknown[]) as GrowthPigsEvent[]));
		dispatch(
			AsyncOperationBuilder2(
				Action.upsertDepartureFromPenEvent,
				cl => cl.growthPigsEvent_PostGrowthPigPenDepartureEventDto(eventsWithType),
				eventsWithType,
			),
		);
	};
};

export const upsertGrowthPigsDepartureFromPenEventsAsync = (events: GrowthPigDepartureFromPenEventDto[]) => {
	const dataWithBundleId = setGpeBundleIdentifierArray(events);
	const eventsWithType = setGrowthPigDepartFromPenEventType(dataWithBundleId);

	return async (dispatch: Dispatch<any>) => {
		dispatch(Action.InitialSaveGrowthPigEvent.started((eventsWithType as unknown[]) as GrowthPigsEvent[]));
		dispatch(
			AsyncOperationBuilder2(
				Action.upsertDepartureFromPenEvent,
				cl => cl.growthPigsEvent_PostGrowthPigPenDepartureEventDto(eventsWithType),
				eventsWithType,
			),
		);
	};
};

export function SaveGrowthPigsEventSyncData() {
	const state = StoreContainer.getState();
	const updates = state.growthPigEvents.growthPigEventsUpdates;
	let promises = new Array<Promise<void>>();
	return async (dispatch: Dispatch<any>) => {
		if (state.growthPigEvents.saveSyncInProgress) {
			return Promise.resolve();
		}
		const updatesFilteredToEntrance = updates.filter(
			gpe => gpe.growthPigEventType === GrowthPigEventType.GrowthPigEntranceEvent,
		);
		const updatesFilteredToMove = updates.filter(
			gpe => gpe.growthPigEventType === GrowthPigEventType.GrowthPigMoveEvent,
		);
		const updatesFilteredToDeparture = updates.filter(
			gpe => gpe.growthPigEventType === GrowthPigEventType.GrowthPigDepartureEvent,
		);
		const updatesFilteredToDepartureFromPen = updates.filter(
			gpe => gpe.growthPigEventType === GrowthPigEventType.GrowthPigPenDepartureEvent,
		);
		const updatesFilteredToMovePiglet = updates.filter(
			gpe => gpe.growthPigEventType === GrowthPigEventType.PigletMovedEvent,
		);

		const updatesFilteredToSold = updates.filter(
			gpe => gpe.growthPigEventType === GrowthPigEventType.PigletDepartureSoldEvent,
		);

		const updatesFilteredToRegulation = updates.filter(
			gpe =>
				gpe.growthPigEventType === GrowthPigEventType.Regulation ||
				gpe.growthPigEventType === GrowthPigEventType.RegulationWeight,
		);

		const updatesGrowthPigsEvents = updates.filter(gpe => gpe instanceof GrowthPigsEvent);

		if (updatesFilteredToEntrance.length > 0) {
			promises.push(
				upsertGrowthPigsEntranceEvents(
					updatesFilteredToEntrance as GrowthPigEntranceEventDto[],
					true,
				)(dispatch),
			);
		}
		if (updatesFilteredToMove.length > 0) {
			promises.push(upsertGrowthPigsMoveEvents(updatesFilteredToMove as GrowthPigMoveEventDto[], true)(dispatch));
		}

		if (updatesFilteredToDeparture.length > 0) {
			promises.push(
				upsertGrowthPigsDepartureEvents(updatesFilteredToDeparture as GrowthPigDepartureEventDto[])(dispatch),
			);
		}
		if (updatesFilteredToDepartureFromPen.length > 0) {
			promises.push(
				upsertGrowthPigsDepartureFromPenEventsAsync(
					updatesFilteredToDepartureFromPen as GrowthPigDepartureFromPenEventDto[],
				)(dispatch),
			);
		}
		if (updatesFilteredToMovePiglet.length > 0) {
			promises.push(
				upsertGrowthPigsPigletMoveEventsAndUpsertEvents(
					updatesFilteredToMovePiglet.filter(e => !e.isDeleted) as GrowthPigMoveEventDto[],
				)(dispatch),
			);
		}
		if (updatesFilteredToSold.length > 0) {
			updatesFilteredToSold.forEach(element => {
				promises.push(upsertGrowthPigsEvent(element as GrowthPigsEvent)(dispatch));
			});
		}
		if (updatesFilteredToRegulation.length > 0) {
			updatesFilteredToRegulation.forEach(update => {
				promises.push(upsertGrowthPigsEvent(GrowthPigsEvent.fromJS(update))(dispatch));
			});
		}

		updatesGrowthPigsEvents.forEach(update => {
			promises.push(upsertGrowthPigsEvent(GrowthPigsEvent.fromJS(update))(dispatch));
		});

		return await Promise.all(promises);
	};
}

export function SetGpeDepartureCount(gpeCount: GpeDepartureCount) {
	return (dispatch: Dispatch<any>) => {
		dispatch(Action.setGpeDepartureCount(gpeCount));
	};
}

const setBundleIdentifier = (id: string, data: any) => {
	let item = { ...data };

	if (data && !data.bundleIdentifier) {
		item.createdOn = new Date();
		item.bundleIdentifier = id;
	}
	return item;
};

export const setGpeBundleIdentifierArray = (data: any[]) => {
	const bundleIdentifier = new ObjectID().toHexString();
	const boundFunc = setBundleIdentifier.bind(setBundleIdentifier, bundleIdentifier);
	return data.map(boundFunc);
};
