import React from 'react';
import { connect } from 'react-redux';
import { PeriodDto, ResultAnalysisMatingsTableItem } from 'shared/api/api';
import { getDateString } from 'shared/helpers/date-helpers';
import { rangeFilterMethodGrid } from 'shared/helpers/general-helpers';
import { NaturalSortDates } from 'shared/helpers/natural-sort';
import { RefType } from 'shared/helpers/ref-type';
import { AnalysisModalMode } from 'shared/state/ducks/analysis/analysis-modal/operation';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { getWebAnimalColorStyleDashboard } from 'web/view/components/dashboard/dashboard-helper';
import SkioldTableGrid, {
	SkioldTableGrid as SkioldTableRef,
} from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { SowListConstants } from 'web/view/components/stem-animal/animal-lists/table-constants';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TotalMatingsGroupedColumns } from 'web/web-helpers/analysis-helper/analysis-helper';
import { GetAnalysisMatingsPdf } from 'web/web-helpers/pdf-helper/analysis-pdf-helpers/analysis-pdf-helper';
import {
	AnalysisResultPerLitterModalTableState,
	defaultSortingResultPerLitterModals,
	getAnalysisAnimalNumberCell,
	getAnalysisLitterNumberCell,
	getShowSowCardCell,
	getSkioldSowCardModal,
	initialAnalysisResultPerLitterModalsTableStates,
} from '../../analysis-helper';

interface PropsFromParent {
	mode: AnalysisModalMode;
	data: ResultAnalysisMatingsTableItem[];
	onFilterChange: (data: ResultAnalysisMatingsTableItem[]) => void;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		profile: state.profile.active!,
	};
};

type Props = ReturnType<typeof mapStateToProps> & PropsFromParent;

export class AnalysisResultsAnalysisMatingsTable extends React.PureComponent<
	Props,
	AnalysisResultPerLitterModalTableState
> {
	constructor(props: Props) {
		super(props);
		this.state = initialAnalysisResultPerLitterModalsTableStates;
	}

	public render() {
		return (
			<ViewWeb className="analysis-modal-table">
				<SkioldTableGrid
					sortHeaderId={defaultSortingResultPerLitterModals}
					groupedColumns={TotalMatingsGroupedColumns()}
					onFiltersChanged={this.props.onFilterChange}
					usesSummaryRow={true}
					data={this.props.data}
					ref={this.setTableRef}
					columns={this.generateColumns}
					ColumnExtensions={this.getColumnExtenstions}
					tableKey={'analysisMatingResultPerLitterTable'}
				/>
				{getSkioldSowCardModal(this.state.sowCardModal, this.closeModal, this.state.stemAnimalId)}
			</ViewWeb>
		);
	}

	public printData = async (periodDto: PeriodDto) => {
		if (this.SkioldTableRef) {
			let data = this.SkioldTableRef.GetSortedData();
			GetAnalysisMatingsPdf(
				data,
				AnalysisModalMode[this.props.mode] + 'Mating.pdf',
				this.props.profile.siteId!,
				Intl.DateTimeFormat().resolvedOptions().timeZone,
				this.props.profile.language,
				periodDto,
			);
		}
	};

	public SkioldTableRef: SkioldTableRef | undefined;

	private readonly setTableRef = (m: any) => (m ? (this.SkioldTableRef = m) : {});

	private getColumnExtenstions = [
		{
			columnName: 'sowCard',
			filteringEnabled: false,
			width: SowListConstants.iconWidth,
		},
		{
			columnName: 'animalNr',
			width: SowListConstants.animalNrWidth,
		},
		{
			columnName: 'litter',
			width: SowListConstants.iDNumberWidth,
		},
		{
			columnName: 'matingsFirstMating',
			width: SowListConstants.pregnantDaysDashboardWidth,
		},
		{
			columnName: 'matingDate',
			width: SowListConstants.iDNumberWidth,
		},
		{
			columnName: 'weaning',
			usesSummary: true,
			width: SowListConstants.iDNumberWidth,
		},
		{
			columnName: 'mating',
			usesSummary: true,
			width: SowListConstants.iDNumberWidth,
		},
		{
			columnName: 'notPregnant',
			usesSummary: true,
			width: SowListConstants.iDNumberWidth,
		},
		{
			columnName: 'abortion',
			usesSummary: true,
			width: SowListConstants.iDNumberWidth,
		},
		{
			columnName: 'soldOrKilled',
			usesSummary: true,
			width: SowListConstants.indexDateWidth,
		},
		{
			columnName: 'deadOrPutDown',
			usesSummary: true,
			width: SowListConstants.indexDateWidth,
		},
	];

	private readonly showSowCard = (stemAnimalId?: string) => {
		if (stemAnimalId) {
			this.setState({ sowCardModal: true, stemAnimalId: stemAnimalId });
		}
	};

	private readonly closeModal = () => {
		this.setState(initialAnalysisResultPerLitterModalsTableStates);
	};

	private readonly getMatingFirstMatingCell = (item: ResultAnalysisMatingsTableItem) =>
		getDateString(item.matingDateFirstMating);

	private readonly getMatingDateCell = (item: ResultAnalysisMatingsTableItem) => getDateString(item.matingDate);

	private readonly getDaysFromWeaningCell = (item: ResultAnalysisMatingsTableItem) => item.daysFromWeaning;

	private readonly getDaysFromMatingCell = (item: ResultAnalysisMatingsTableItem) => item.daysFromMating;

	// matingDate & matingDateFirstMating is null, is means last mating with not pregnant
	private readonly getNotPregnantCell = (item: ResultAnalysisMatingsTableItem) =>
		item.prevNotPregnant ? item.prevNotPregnant : !item.matingDate ? item.notPregnant : '';

	private readonly getAbortionCell = (item: ResultAnalysisMatingsTableItem) => item.abortion;

	private readonly getSoldOrKilledCell = (item: ResultAnalysisMatingsTableItem) => item.soldOrKilled;

	private readonly getDeadOrPutDownCell = (item: ResultAnalysisMatingsTableItem) => item.deadOrPutDown;

	private readonly showSowCardCell = (item: ResultAnalysisMatingsTableItem) =>
		getShowSowCardCell(item, this.showSowCard);

	private generateColumns = [
		{
			name: 'sowCard',
			title: ' ',
			Footer: localized('avg'),
			summaryText: localized('average'),
			sortable: false,
			getCellValue: this.showSowCardCell,
		},
		{
			name: 'animalNr',
			title: localized('animalNr'),
			className: getWebAnimalColorStyleDashboard,
			getCellValue: getAnalysisAnimalNumberCell,
		},
		{
			name: 'litter',
			title: localized('LitterNr'),
			getCellValue: getAnalysisLitterNumberCell,
			filterFunction: rangeFilterMethodGrid,
			sortable: false,
		},
		{
			name: 'matingsFirstMating',
			title: <div className={'alignDivsCenter'}>{localized('matingsFirstMating')}</div>,
			getCellValue: this.getMatingFirstMatingCell,
			sortFunction: NaturalSortDates,
		},
		{
			name: 'matingDate',
			title: localized('MatingDate'),
			getCellValue: this.getMatingDateCell,
			sortable: false,
		},
		{
			name: 'weaning',
			title: localized('Weaning'),
			getCellValue: this.getDaysFromWeaningCell,
			filterFunction: rangeFilterMethodGrid,
			sortable: false,
		},
		{
			name: 'mating',
			title: localized('mating'),
			getCellValue: this.getDaysFromMatingCell,
			filterFunction: rangeFilterMethodGrid,
			sortable: false,
		},
		{
			name: 'notPregnant',
			title: localized('notPregnant'),
			getCellValue: this.getNotPregnantCell,
			filterFunction: rangeFilterMethodGrid,
			sortable: false,
		},
		{
			name: 'abortion',
			title: localized('abortion'),
			getCellValue: this.getAbortionCell,
			filterFunction: rangeFilterMethodGrid,
			sortable: false,
		},
		{
			name: 'soldOrKilled',
			title: localized('soldOrKilled'),
			getCellValue: this.getSoldOrKilledCell,
			filterFunction: rangeFilterMethodGrid,
			sortable: false,
		},
		{
			name: 'deadOrPutDown',
			title: localized('deadOrPutDown'),
			getCellValue: this.getDeadOrPutDownCell,
			filterFunction: rangeFilterMethodGrid,
			sortable: false,
		},
	];
}

export default connect<ReturnType<typeof mapStateToProps>, null, RefType, WebAppState>(mapStateToProps, null, null, {
	forwardRef: true,
})(AnalysisResultsAnalysisMatingsTable);
