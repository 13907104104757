import {
	AnimalType,
	DashboardElaborationTableItem,
	DashboardEntranceDepartureTableItem,
	FileResponse,
	GrowthPigEventType,
	IDiagnose,
	IDrugType,
	IGrowthPigsEvent,
	IReasonDto,
	ITreatment,
	ITreatmentDefinition,
	ITreatmentPlan,
	SkioldOneClient,
} from 'shared/api/api';
import { SkioldDigitalApiBaseUrl } from 'shared/constants';
import { getDateString } from 'shared/helpers/date-helpers';
import { GrowthPigDepartureEventListRow } from 'shared/helpers/growth-pigs-helper/growth-pig-event-list-helper';
import { NaturalSortDates } from 'shared/helpers/natural-sort';
import { localized } from 'shared/state/i18n/i18n';
import { DepartureTypes } from 'shared/state/models/departure-types';
import {
	generateDefaultTreatmentLogTableModel,
	handleMissingPropsPigTreatments,
} from 'web/view/pages/treatment-log/treatment-log-helper';
import { savePdfAs } from 'web/web-helpers/pdf-helper/general-pdf-helper';
import { SummaryRowItem } from '../skiold-components/skiold-table/skiold-table-grouped-grid/skiold-table-grouped-grid-helper';
import { SubtreatmentTableModel, TreatmentTableModel } from '../treatments/treatment-plan/treatment-table-model';
import { DashboardEntranceDepartureModel } from './models/dashboard-entrance-departure-model';

export const generateLast7DaysDeadPutDown = (
	fromDate: Date,
	toDate: Date,
	sectionId: string,
	penId: string,
	growthPigEvents: IGrowthPigsEvent[],
	reasons: IReasonDto[],
	lang: string | undefined,
) => {
	let listData = [] as GrowthPigDepartureEventListRow[];
	growthPigEvents
		.filter(
			gpe =>
				gpe.date &&
				gpe.date >= fromDate &&
				gpe.date <= toDate &&
				gpe.departureType &&
				DepartureTypes.IsDeathType(gpe.departureType) &&
				((gpe.fromSectionId === sectionId && gpe.fromPenId === penId) ||
					(gpe.fromSectionId === sectionId && !penId)),
		)
		.forEach(gpe => {
			if (gpe.amount) {
				const reasonToUse = reasons.find(a => a.reason && a.reason.id === gpe.departureReasonId);

				listData.push({
					avgWeight: gpe.totalWeight ? gpe.totalWeight / gpe.amount : 0,
					departedWeight: gpe.totalWeight,
					amount: gpe.amount,
					date: gpe.date,
					reason:
						reasonToUse &&
						reasonToUse.reason &&
						reasonToUse.reason.name &&
						lang &&
						reasonToUse.reason.name[lang]
							? reasonToUse.reason.name[lang]
							: undefined,

					departuredType: gpe.departureType,
				} as any);
			}
		});
	return listData.sort((a, b) => NaturalSortDates(a, b));
};

export const generateLast7DaysTreated = (
	fromDate: Date,
	toDate: Date,
	sectionId: string,
	penId: string,
	treatments: ITreatment[],
	treatmentPlans: { [key: string]: ITreatmentPlan },
	treatmentDefinitions: { [key: string]: ITreatmentDefinition },
	diagnoses: { [key: string]: IDiagnose },
	drugTypes: {
		[key: string]: IDrugType;
	},
	animalType: AnimalType,
) => {
	const treatmentTableModels: TreatmentTableModel[] = [];

	treatments.forEach(treatment => {
		if (
			treatment.animalType === animalType &&
			treatment.treatmentPlanId &&
			((treatment.sectionId === sectionId && treatment.penId === penId) ||
				(treatment.sectionId === sectionId && !penId)) &&
			treatment.treatmentPlanId &&
			treatment.executedDate &&
			treatment.executedDate >= fromDate &&
			treatment.executedDate <= toDate
		) {
			const treatmentPlan = treatmentPlans[treatment.treatmentPlanId];
			if (treatmentPlan && treatmentPlan.treatmentDefinitionId) {
				const treatmentDefinition = treatmentDefinitions[treatmentPlan.treatmentDefinitionId];
				if (treatmentDefinition && treatmentDefinition.diagnoseId) {
					const diagnose = diagnoses[treatmentDefinition.diagnoseId];
					const subtreatments = getSubTreatmentTableModels(treatmentDefinition, drugTypes);
					if (diagnose) {
						let model = generateDefaultTreatmentLogTableModel(
							treatment,
							undefined,
							treatmentPlan,
							diagnose,
							subtreatments,
							treatmentDefinition,
						);

						handleMissingPropsPigTreatments(model, treatmentPlan, treatment, treatmentDefinitions);
						treatmentTableModels.push(model);
					}
				}
			}
		}
	});

	return treatmentTableModels;
};

const getSubTreatmentTableModels = (
	treatmentDefinition: ITreatmentDefinition | undefined,
	drugTypes: {
		[key: string]: IDrugType;
	},
) => {
	if (!treatmentDefinition) {
		return undefined;
	}
	const subTreatmentModels: SubtreatmentTableModel[] = [];
	if (treatmentDefinition.subTreatments) {
		treatmentDefinition.subTreatments.forEach(subTreatment => {
			if (subTreatment.drugTypeId) {
				const subTreatmentDrugType = drugTypes[subTreatment.drugTypeId];
				const drugAmount = subTreatment.drugAmount!;
				const treatmentType = subTreatment.treatmentType!;

				subTreatmentModels.push(new SubtreatmentTableModel(subTreatmentDrugType!, drugAmount, treatmentType));
			}
		});
	}

	// Undefined has been filtered out, typecast should be safe
	return subTreatmentModels;
};

export const getDashboardEntranceModel = (
	fromDate: Date,
	sectionId: string,
	penId: string,
	growthPigEvents: IGrowthPigsEvent[],
) => {
	// Filter gpes if they entered the location after the fromDate
	let filteredGpes = growthPigEvents.filter(
		event =>
			(event.growthPigEventType === GrowthPigEventType.GrowthPigEntranceEvent ||
				event.growthPigEventType === GrowthPigEventType.GrowthPigMoveEvent ||
				event.growthPigEventType === GrowthPigEventType.PigletMovedEvent) &&
			event.date &&
			event.date >= fromDate &&
			((event.toSectionId === sectionId && event.toPenId === penId) ||
				(event.toSectionId === sectionId && !penId)),
	);
	let dateDictionary: { [key: string]: DashboardEntranceDepartureModel } = {};

	// group by date and growthPigEventType
	filteredGpes.forEach(gpe => {
		if (gpe.date && gpe.growthPigEventType) {
			let key = gpe.date!.getDate() + gpe.growthPigEventType;

			if (
				dateDictionary[key] &&
				dateDictionary[key].pigCount !== undefined &&
				dateDictionary[key].totalWeight !== undefined &&
				gpe.amount &&
				gpe.totalWeight
			) {
				dateDictionary[key].pigCount! += gpe.amount;
				dateDictionary[key].totalWeight! += gpe.totalWeight;
			} else {
				let item = {
					gpeEventType:
						gpe.growthPigEventType === GrowthPigEventType.GrowthPigMoveEvent
							? 'identifierFrom' + gpe.fromProductionType
							: localized(gpe.growthPigEventType),
					dateString: getDateString(gpe.date),
					pigCount: gpe.amount,
					totalWeight: gpe.totalWeight,
				} as DashboardEntranceDepartureModel;
				dateDictionary[key] = item;
			}
		}
	});
	let dashboardData = Object.keys(dateDictionary).reduce((acc: DashboardEntranceDepartureModel[], item) => {
		return acc.concat(dateDictionary[item]);
	}, []);
	return dashboardData.map(item => {
		return {
			...item,
			avgWeight: item.totalWeight && item.pigCount ? (item.totalWeight / item.pigCount).toFixed(1) : 0,
		} as DashboardEntranceDepartureModel;
	});
};

export const getDashboardDepartureModel = (
	fromDate: Date,
	sectionId: string,
	penId: string,
	growthPigEvents: IGrowthPigsEvent[],
) => {
	// Filter gpes if they entered the location after the fromDate
	let filteredGpes = growthPigEvents.filter(
		event =>
			(event.growthPigEventType === GrowthPigEventType.GrowthPigDepartureEvent ||
				event.growthPigEventType === GrowthPigEventType.GrowthPigMoveEvent ||
				event.growthPigEventType === GrowthPigEventType.GrowthPigPenDepartureEvent) &&
			event.date &&
			event.date >= fromDate &&
			((event.fromSectionId === sectionId && event.fromPenId === penId) ||
				(event.fromSectionId === sectionId && !penId)),
	);
	let dateDictionary: { [key: string]: DashboardEntranceDepartureModel } = {};

	// group by date and growthPigEventType
	filteredGpes.forEach(gpe => {
		if (gpe.date && gpe.growthPigEventType) {
			let key = gpe.date!.getDate() + gpe.growthPigEventType + gpe.departureType;

			if (
				dateDictionary[key] &&
				dateDictionary[key].pigCount !== undefined &&
				dateDictionary[key].totalWeight !== undefined &&
				gpe.amount &&
				gpe.totalWeight
			) {
				dateDictionary[key].pigCount! += gpe.amount;
				dateDictionary[key].totalWeight! += gpe.totalWeight;
			} else {
				let item = {
					gpeEventType:
						gpe.growthPigEventType === GrowthPigEventType.GrowthPigMoveEvent
							? 'identifierTo' + gpe.toProductionType
							: gpe.departureType
							? localized(gpe.departureType)
							: '',
					dateString: getDateString(gpe.date),
					pigCount: gpe.amount,
					totalWeight: gpe.totalWeight,
				} as DashboardEntranceDepartureModel;
				dateDictionary[key] = item;
			}
		}
	});
	let dashboardData = Object.keys(dateDictionary).reduce((acc: DashboardEntranceDepartureModel[], item) => {
		return acc.concat(dateDictionary[item]);
	}, []);
	return dashboardData.map(item => {
		return {
			...item,
			avgWeight: item.totalWeight && item.pigCount ? (item.totalWeight / item.pigCount).toFixed(1) : 0,
		} as DashboardEntranceDepartureModel;
	});
};

export function DashboardElaborationPdf(
	entered: DashboardElaborationTableItem[],
	departed: DashboardElaborationTableItem[],
	totalSummaryEntered: string,
	totalSummaryDeparted: string,
	fileName: string,
	siteId: string,
	timeZoneId?: string,
	lang?: string,
	location?: string,
) {
	return new SkioldOneClient(SkioldDigitalApiBaseUrl)
		.growthPigsDashboardEvent_GrowthPigDashboardPrintPdf(
			{ entered, departed, totalSummaryEntered, totalSummaryDeparted } as DashboardEntranceDepartureTableItem,
			siteId,
			lang,
			timeZoneId,
			location,
		)
		.then((fileResponse: FileResponse | null) => {
			savePdfAs(fileResponse, fileName);
		})
		.catch(() => {
			return;
		});
}

export const setTableItems = (summaryRowItems: SummaryRowItem[]) => {
	let items: DashboardElaborationTableItem[] = [];

	for (let item of summaryRowItems) {
		let summaryRow = { gpeEventType: item.key } as DashboardElaborationTableItem;
		summaryRow.isSummaryRow = true;
		summaryRow.isExpanded = item.isExpanded;
		for (let sumVal of item.summaryValues) {
			summaryRow[sumVal.columnKey] = sumVal.columnValue;
		}
		items.push(summaryRow);
		items = items.concat(item.groupedValues);
	}
	return items;
};
