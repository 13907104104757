import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import './sync.scss';
import { WebAppState } from "web/state/store.web";
import { SaveTriggerSync, SaveConnectionStatus } from 'shared/state/ducks/offline/operations';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { SkioldTouchableOpacity } from '../skiold-components/skiold-touchable-opacity';
import { SkioldImage } from '../utils/svg/skiold-image';
import Updater from 'shared/assets/src-assets/opdater.png'

const mapStateToProps = (state: WebAppState) => {
    return {
        isTriggerd: state.offline.isTriggered,
        isConnected: state.offline.isConnected,
        isSyncing: state.offline.isSyncing
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        saveTriggerSync: (trigger: boolean) => SaveTriggerSync(trigger)(dispatch),
        saveConnectionStatus: (isConnected: boolean) => SaveConnectionStatus(isConnected)(dispatch)
    };
};

interface SyncState {
    trigger: boolean;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class Sync extends React.PureComponent<Props, SyncState> {
    public interval: number = 1000 * 60 * 15;
    constructor(props: Props) {
        super(props);
        this.state = {
            trigger: false
        };
    }

    public render() {
        return (
            <ViewWeb className='sync'>
                <SkioldTouchableOpacity onPress={this.triggerSync}>
                    <SkioldImage width={52} height={40} className={`${this.props.isSyncing? 'is-spinning': ''} sync-image-style`} imageData={Updater} />
                </SkioldTouchableOpacity>
            </ViewWeb>
        );
    }
    private  triggerSync =async () => {
        if (!this.props.isTriggerd) {
            //Ensure we have the correct connection state even though the event hasn't fired.
            await this.checkIsConnected();
            this.props.saveTriggerSync(true);
        }
    }

    private async checkIsConnected() {
        let isConnected = navigator.onLine;
        if (this.props.isConnected !== isConnected) {
            this.props.saveConnectionStatus(isConnected);
        }
    }
}

export default (
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Sync)
);
