import React from 'react';
import { SumOfTreatmentsTableModel, TreatmentTableModel } from '../treatment-plan/treatment-table-model';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { calculateTotalDrugAmount } from 'shared/helpers/treatment-helper/treatment-helper';
import { treatmentTableModelType } from './treatment-log-helper';
import { setOneDecimalsAsNumber } from 'shared/helpers/general-helpers';
import { TreatmentUnit } from 'shared/api/api';

export const getDrugAmountCell = (t: SumOfTreatmentsTableModel) => {
	return (
		<ViewWeb className="alignContentRight">
			<DrugAmountText treatment={t} />
		</ViewWeb>
	);
};

interface PropsFromParent {
	treatment: treatmentTableModelType;
}

export class DrugAmountText extends React.PureComponent<PropsFromParent, {}> {
	public render() {
		if (this.props.treatment instanceof TreatmentTableModel) {
			return this.getTreatmentText(this.props.treatment);
		} else if (this.props.treatment) {
			return this.getSumOfTreatmentsText(this.props.treatment);
		} else {
			return <ViewWeb />;
		}
	}

	private getSumOfTreatmentsText(treatmentTableModel: SumOfTreatmentsTableModel) {
		if (!treatmentTableModel || !treatmentTableModel.subtreatmentTableModels) {
			return <ViewWeb />;
		}

		const textElements = treatmentTableModel.subtreatmentTableModels.map((sub, index) => {
			let drugString = '';
			if (treatmentTableModel.usage![index]) {
				drugString = `${treatmentTableModel.usage![index].toFixed(1)} ${sub.drugType!.unitOfMeasurement}`;
			}

			return <TextWeb key={index}>{drugString}</TextWeb>;
		});

		return textElements;
	}

	private getTreatmentText(treatmentTableModel: TreatmentTableModel) {
		if (!treatmentTableModel || !treatmentTableModel.subtreatmentTableModels) {
			return <ViewWeb />;
		}

		const textElements = treatmentTableModel.subtreatmentTableModels.map((sub, index) => {
			let amountOfDrug = Number(
				calculateTotalDrugAmount(
					sub.drugAmount!,
					treatmentTableModel.pigAmount ? treatmentTableModel.pigAmount : treatmentTableModel.pigCount || 1,
					treatmentTableModel.pigWeight ? treatmentTableModel.pigWeight : treatmentTableModel.animalWeight!,
					treatmentTableModel.feedWeight!,
				),
			);
			if (sub.drugAmount!.unitType !== TreatmentUnit.PerAnimal && treatmentTableModel.pigAmount) {
				amountOfDrug = Number(amountOfDrug) / treatmentTableModel.pigAmount;
			}
			let drugString = '';
			if (amountOfDrug) {
				drugString = `${setOneDecimalsAsNumber(amountOfDrug)} ${sub.drugType!.unitOfMeasurement}`;
			}

			return <TextWeb key={index}>{drugString}</TextWeb>;
		});

		return textElements;
	}
}
