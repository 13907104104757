import { Action } from 'redux';
import { IPoolYoungFemale, PoolYoungFemale } from 'shared/api/api';
import {
	mergeArrays,
	removeValueFromArray,
	upsertValueInArray,
	upsertMultipleValuesInArray,
	removeMultipleValuesFromArray,
} from 'shared/helpers/reducer-helpers';
import { SyncableInitialState } from 'shared/state/models/syncable';
import { isType } from 'typescript-fsa';
import { siteChange } from '../profile/actions';
import * as actions from './actions';
import { getSyncPoolYoungFemale } from './actions';
import { PoolYoungFemaleState } from './types';

export const initialState: PoolYoungFemaleState = {
	...SyncableInitialState,
};

const poolYoungFemaleReducer = (state: PoolYoungFemaleState = initialState, action: Action): PoolYoungFemaleState => {
	if (isType(action, getSyncPoolYoungFemale.started)) {
		state = { ...state, syncInProgress: true };
	}

	if (isType(action, getSyncPoolYoungFemale.done)) {
		if (action.payload.params.siteId === action.payload.params.activeSiteId) {
			if (action.payload.result.datas!.length) {
				state = {
					...state,
					entities: mergeArrays(state.entities, action.payload.result.datas!),
					lastSyncDate: action.payload.result.syncTime!,
				};
			}
		}
		state = {
			...state,
			syncInProgress: false,
		};
	}

	if (isType(action, getSyncPoolYoungFemale.failed)) {
		state = { ...state, syncInProgress: false };
	}

	if (isType(action, actions.upsertPoolYoungFemale.started)) {
		if (action.payload && action.payload.isDeleted && action.payload.id) {
			state = {
				...state,
				entities: removeValueFromArray(state.entities, action.payload.id),
				updates: upsertValueInArray(state.updates, action.payload),
				saveSyncInProgress: true,
			};
		} else if (action.payload) {
			state = {
				...state,
				entities: upsertValueInArray(state.entities, action.payload),
				updates: upsertValueInArray(state.updates, action.payload),
				saveSyncInProgress: true,
			};
		}
	}

	if (isType(action, actions.upsertPoolYoungFemale.failed)) {
		if (action.payload.error.code === 500 && action.payload.params) {
			if (action.payload.error.prevEntity) {
				state = { ...state, entities: upsertValueInArray(state.entities, action.payload.error.prevEntity) };
			} else {
				state = { ...state, entities: removeValueFromArray(state.entities, action.payload.params.id!) };
			}
			state = { ...state, updates: removeValueFromArray(state.updates, action.payload.params.id!) };
		}

		state = { ...state, saveSyncInProgress: false };
		return state;
	}

	if (isType(action, actions.upsertPoolYoungFemale.done)) {
		state = { ...state, updates: removeValueFromArray(state.updates, action.payload.result) };
		state = { ...state, saveSyncInProgress: false };
		return state;
	}

	if (isType(action, actions.upsertManyPoolYoungFemale.started)) {
		state = {
			...state,
			entities: upsertMultipleValuesInArray(state.entities, action.payload),
			updates: upsertMultipleValuesInArray(state.updates, action.payload),
			saveSyncInProgress: true,
		};

		return state;
	}

	if (isType(action, actions.upsertManyPoolYoungFemale.failed)) {
		if (action.payload.error.code === 500 && action.payload.params) {
			if (action.payload.error.prevEntity) {
				state = {
					...state,
					entities: upsertMultipleValuesInArray(state.entities, action.payload.error.prevEntity),
				};
			}
			const ids = action.payload.params.map(pyf => pyf.id!);
			state = { ...state, updates: removeMultipleValuesFromArray(state.updates, ids) };
		}
		state = { ...state, saveSyncInProgress: false };

		return state;
	}

	if (isType(action, actions.upsertManyPoolYoungFemale.done)) {
		const ids = action.payload.params.map(pyf => pyf.id!);
		state = { ...state, updates: removeMultipleValuesFromArray(state.updates, ids) };
		state = { ...state, saveSyncInProgress: false };
		return state;
	}

	if (isType(action, actions.deleteManyPoolYoungFemale.started)) {
		if (action.payload === undefined) {
			return state;
		}

		const ids = action.payload.map(pyf => pyf.id!);
		state = {
			...state,
			entities: removeMultipleValuesFromArray(state.entities, ids),
			updates: upsertMultipleValuesInArray(state.updates, action.payload),
			saveSyncInProgress: true,
		};

		return state;
	}

	if (isType(action, actions.deleteManyPoolYoungFemale.failed)) {
		if (action.payload.error.code === 500 && action.payload.params) {
			if (action.payload.error.prevEntity) {
				state = {
					...state,
					entities: upsertMultipleValuesInArray(state.entities, action.payload.error.prevEntity),
				};
			}
			const ids = action.payload.params.map(pyf => pyf.id!);
			state = { ...state, updates: removeMultipleValuesFromArray(state.updates, ids) };
		}
		state = { ...state, saveSyncInProgress: false };

		return state;
	}

	if (isType(action, actions.deleteManyPoolYoungFemale.done)) {
		const ids = action.payload.params.map(pyf => pyf.id!);
		state = { ...state, updates: removeMultipleValuesFromArray(state.updates, ids) };
		state = { ...state, entities: removeMultipleValuesFromArray(state.entities, ids) };
		state = { ...state, saveSyncInProgress: false };
		return state;
	}

	if (isType(action, siteChange.done)) {
		state = initialState;
	}

	//Ensure Date objects are in fact date and not strings - redux-persist serializes dates to string but doesn't deserialize to dates again
	if (action.type === 'persist/REHYDRATE') {
		let a = (action as any) as { payload: PoolYoungFemaleState; key: string };
		if (a.key === 'root') {
			let entities = new Array<PoolYoungFemale>();
			let updates = new Array<PoolYoungFemale>();
			let lastSyncDate = SyncableInitialState.lastSyncDate;

			if (a.payload && a.payload && a.payload.entities) {
				entities = a.payload.entities.map((event: IPoolYoungFemale) => {
					let newEvent = PoolYoungFemale.fromJS({});
					newEvent.init(event);
					return newEvent;
				});
			}

			if (a.payload && a.payload && a.payload.updates) {
				updates = a.payload.updates.map((event: IPoolYoungFemale) => {
					let newEvent = PoolYoungFemale.fromJS({});
					newEvent.init(event);
					return newEvent;
				});
			}

			if (a.payload && a.payload && a.payload.lastSyncDate) {
				lastSyncDate = new Date(a.payload.lastSyncDate);
			}

			state = { ...state, entities, updates, lastSyncDate, syncInProgress: false };
		}
	}

	return state;
};

export default poolYoungFemaleReducer;
