import { SiteAuthorizationUpdate } from 'shared/api/api';
import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import * as Action from 'shared/state/ducks/authorizations/actions';

const wrapFn = <T>(k: string) => (r: T) => ({ [k]: r });

export const LoadSiteAuthorizations = (id: string) => {
	return AsyncOperationBuilder2(Action.load, cl => cl.sites_GetUserAuthorizations(id), id);
};

export const UpdateSiteAuthorization = (id: string, update: SiteAuthorizationUpdate) => {
	return AsyncOperationBuilder2(
		Action.load,
		cl => cl.sites_PostUserAuthorization(id, update),
		new SiteAuthorizationUpdate(update),
		wrapFn('authorizations'),
	);
};

export function UpdateUserInitials(id: string, initials: string) {
	return AsyncOperationBuilder2(
		Action.updateInitials,
		client => client.userProfile_UpdateInitials(id, initials),
		{
			id,
			initials,
		},
	);
}
