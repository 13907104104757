import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
	AnalysisDepartureGpeRequestModel,
	AnalysisDepartureGpeTableData,
	AnimalType,
	IAnalysisDepartureGpeTableData,
	IAnalysisDepartureSowTableData,
	SkioldOneClient,
} from 'shared/api/api';
import PrinterGreyIcon from 'shared/assets/src-assets/png/printer_ikon_grey.png';
import { SkioldDigitalApiBaseUrl } from 'shared/constants';
import { getReasonNameById } from 'shared/helpers/reason-helper/reason-helper';
import { localized, localizedInterpolation } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { AnalysisDepartureTypeGpeTable } from 'web/view/components/analysis/analysis-departure/analysis-departure-gpe-table';
import PageContainer from 'web/view/components/page-container/page-container';
import { SkioldFetch } from 'web/view/components/skiold-components/skiold-fetch/skiold-fetch';
import { WhiteText } from 'web/view/components/Text/white-text';
import { Heading } from 'web/view/components/utils/heading';
import { SkioldIconSpinner } from 'web/view/components/utils/skiold-icon-spinner';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { GetAnalysisDepartureGpePdf } from 'web/web-helpers/pdf-helper/analysis-pdf-helpers/analysis-pdf-helper';
import { ClipLoader } from 'react-spinners';
import { SkioldCheckbox } from '../../components/skiold-components/skiold-checkbox/skiold-checkbox';
import { DepartureTypes } from 'shared/state/models/departure-types';

interface PropsFromParent {}

export const AnalysisOverviewGpeContext = React.createContext({} as any);
export const AnalysisOverviewGpe: FC<PropsFromParent> = React.memo(({}) => {
	const navigationQuery = useSelector((state: WebAppState) => state.navigation);
	const [productionType, setProductionType] = useState(
		navigationQuery.query ? (navigationQuery.query['type'] as AnimalType) : AnimalType.FRATS,
	);
	const siteId = useSelector((state: WebAppState) => state.profile.active!.siteId);
	const reasons = useSelector((state: WebAppState) => state.reasons.entities);
	const language = useSelector((state: WebAppState) =>
		state.profile.active && state.profile.active.language ? state.profile.active.language : 'en',
	);

	const [data, setData] = useState<AnalysisDepartureGpeTableData | undefined>();
	const [fromDate, setFromDate] = useState<Date | undefined>();
	const [toDate, setToDate] = useState<Date | undefined>();
	const [pending, setPending] = useState(false);
	const [asPercentage, setAsPercentage] = useState(false);

	// Departure types
	const [dead, setDead] = useState<boolean>(true);
	const [putDown, setPutDown] = useState<boolean>(true);

	useEffect(() => {
		setData(undefined);
		setProductionType(navigationQuery.query ? (navigationQuery.query['type'] as AnimalType) : AnimalType.FRATS);
	}, [navigationQuery]);

	const fetchData = (dateFrom?: Date, dateTo?: Date) => {
		let allowDepartureTypes: string[] = [];
		if (dead) {
			allowDepartureTypes.push(DepartureTypes.departureTypeDead);
		}
		if (putDown) {
			allowDepartureTypes.push(DepartureTypes.departureTypePutDown);
		}
		setPending(true);
		new SkioldOneClient(SkioldDigitalApiBaseUrl)
			.analysis_AnalysisDepartureGpe({
				siteId,
				fromDate: dateFrom,
				toDate: dateTo,
				animalType: productionType,
				asPercentage: asPercentage,
				allowDepartureTypes,
			} as AnalysisDepartureGpeRequestModel)
			.then((tableData: AnalysisDepartureGpeTableData) => {
				if (tableData) {
					if (tableData.departureReason) {
						const reasonKeys = Object.keys(tableData.departureReason);
						reasonKeys.forEach(key => {
							tableData.departureReason![key].name = getReasonNameById(
								tableData.departureReason![key].name,
								language,
								reasons,
							);
						});
					}
				}
				setData(tableData);
				setPending(false);
			})
			.catch(() => {
				setPending(false);
				return;
			});
	};

	const printData = () => {
		if (data) {
			GetAnalysisDepartureGpePdf(
				data as IAnalysisDepartureGpeTableData,
				localizedInterpolation('AnalysisDepartureAnimalType', productionType) + '.pdf',
				siteId!,
				asPercentage,
				Intl.DateTimeFormat().resolvedOptions().timeZone,
				language,
			);
		}
	};

	const handleSetAsCount = useCallback(() => {
		setAsPercentage(false);
	}, []);

	const handleSetAsPercentage = useCallback(() => {
		setAsPercentage(true);
	}, []);


	return (
		<AnalysisOverviewGpeContext.Provider value={{}}>
			<PageContainer className="analysisOverview">
				<Heading text={localized('ChooseAnalysis')} />

				<ViewWeb className="analysis-top-container flex-row-space-between width-100-pct">
					<ViewWeb className="sizing vertical-center flex-row ">
						<SkioldFetch
							defaultEmpty={true}
							toDate={toDate}
							fromDate={fromDate}
							fetchData={fetchData}
							toDateChange={setToDate}
							fromDateChange={setFromDate}
							isControlled={true}
						/>
						<ViewWeb className={'align-center'}>
							<ViewWeb className="flex-row">
								<WhiteText className="label-maring">{localized('Count')}</WhiteText>
								<SkioldCheckbox isChecked={!asPercentage} onClick={handleSetAsCount} />
								<WhiteText>{localized('Percentage')}</WhiteText>
								<SkioldCheckbox isChecked={asPercentage} onClick={handleSetAsPercentage} />
							</ViewWeb>
							<ViewWeb className="margin-top-15 flex-row">
								<ViewWeb className="flex-row checkbox-margin">
									<WhiteText className="label-maring">{localized('departureTypeDead')}:</WhiteText>
									<SkioldCheckbox isChecked={dead} onClick={setDead} />
								</ViewWeb>
								<ViewWeb className="flex-row checkbox-margin">
									<WhiteText className="label-maring">{localized('departureTypePutDown')}:</WhiteText>
									<SkioldCheckbox isChecked={putDown} onClick={setPutDown} />
								</ViewWeb>
							</ViewWeb>
						</ViewWeb>
					</ViewWeb>
					<WhiteText className="flexOne">
						{localizedInterpolation('AnalysisDepartureAnimalType', productionType)}
					</WhiteText>
					<ViewWeb className="alignItemsCenter flexOne align-end ">
						<SkioldIconSpinner
							containerClassName="default-modal-margin-right"
							title={localized('PrintList')}
							icon={PrinterGreyIcon}
							onPress={printData}
						/>
					</ViewWeb>
				</ViewWeb>
				<ViewWeb className="viewContainer">
					{!pending ? (
						<AnalysisDepartureTypeGpeTable
							tableData={data}
							asPercentage={asPercentage}
							rowStylingIndicator={{ fromTop: 0, fromBottom: 1 }}
						/>
					) : (
						<ViewWeb className="spinner-container-active-analysis">
							<ClipLoader color="#f2ac40" size={70} />
						</ViewWeb>
					)}
				</ViewWeb>
			</PageContainer>
		</AnalysisOverviewGpeContext.Provider>
	);
});
