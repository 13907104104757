import { Dispatch } from 'redux';
import { ILardScanningEvent, IStemAnimal } from 'shared/api/api';
import { SaveLardScanningEvent } from 'shared/state/ducks/lardScanningEvents/operations';
import { localized } from 'shared/state/i18n/i18n';
import { SharedAppState } from 'shared/state/store.shared';
import { ExceptionMessage } from '../exception-message';

export const lardScanningEventMapStateToProps = (state: SharedAppState) => {
	return {
		active: state.profile.active!
	};
};

export const lardScanningEventMapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	saveLardScanningEvent: (lardScanningEvent: ILardScanningEvent) => SaveLardScanningEvent(lardScanningEvent)(dispatch)
});

export interface RegisterSowLardState {
	sow?: IStemAnimal;
	lard: ILardScanningEvent;
	animalNumber: string | undefined;
	toggleFocus: boolean;
}

export function validateSowLardScanningEvent(
	lardScanningEvent: ILardScanningEvent,
	stemId: string | undefined,
	showAlert: (errorMessage: string) => void
) {
	if (!stemId) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_ANIMAL_NOT_FOUND));
		return false;
	}
	if (!lardScanningEvent.date) {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_DATE_NOT_SET));
		return false;
	}
	if (Number(lardScanningEvent.width).toString() === 'NaN') {
		showAlert(localized(ExceptionMessage.VALIDATION_ERROR_LARD_WIDTH_NOT_SET));
		return false;
	}
	return true;
}

export type RegisterSowLardProps = ReturnType<typeof lardScanningEventMapStateToProps> &
	ReturnType<typeof lardScanningEventMapDispatchToProps>;
