import React from 'react';
import DeleteIcon from 'shared/assets/src-assets/png/delete_icon.png';
import PenIcon from 'shared/assets/src-assets/png/pen_icon.png';
import { getDateString } from 'shared/helpers/date-helpers';
import {
	exactNumberFilterMethodGrid,
	numberWithCommas,
	rangeFilterMethodGrid,
	setOneDecimalsAsNumber,
} from 'shared/helpers/general-helpers';
import {
	getPenClassName,
	GrowthPigEntranceEventBundleListRow,
	GrowthPigEntranceEventListTablePropsFromParent,
} from 'shared/helpers/growth-pigs-helper/growth-pig-event-list-helper';
import { NaturalSortDates } from 'shared/helpers/natural-sort';
import { localized } from 'shared/state/i18n/i18n';
import SkioldTableGrid from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { SkioldTouchableOpacity } from 'web/view/components/skiold-components/skiold-touchable-opacity';
import { SkioldImage } from 'web/view/components/utils/svg/skiold-image';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { SowListConstants } from '../../animal-lists/table-constants';

export class GrowthPigEntranceEventTable extends React.Component<GrowthPigEntranceEventListTablePropsFromParent> {
	public render() {
		return (
			<SkioldTableGrid
				ref={this.props.setRef}
				ignoreSetCount={true}
				ColumnExtensions={this.generateColumnExtes}
				columns={this.generateColumns}
				data={this.props.bundleData}
				tableKey={'GrowthPigEntranceEventList'}
				onFiltersChanged={this.props.onFilterChanged}
			/>
		);
	}
	private getToBuildingNameCell = (item: GrowthPigEntranceEventBundleListRow) => item.toBuildingName;
	private getToSectionNameCell = (item: GrowthPigEntranceEventBundleListRow) => item.toSectionName;
	private getAmountCell = (item: GrowthPigEntranceEventBundleListRow) => item.pcs;
	private getTotalWeightCell = (item: GrowthPigEntranceEventBundleListRow) =>
		numberWithCommas(setOneDecimalsAsNumber(item.entranceWeight));
	private getAvgWeightCell = (item: GrowthPigEntranceEventBundleListRow) => setOneDecimalsAsNumber(item.avgWeight);
	private getQuarantineCell = (item: GrowthPigEntranceEventBundleListRow) => getDateString(item.retentionDate);
	private getDateCell = (item: GrowthPigEntranceEventBundleListRow) => getDateString(item.date);
	private getToProductionType = (item: GrowthPigEntranceEventBundleListRow) =>
		item.productionType ? localized(item.productionType) : ' ';

	private getEditCell = (event: GrowthPigEntranceEventBundleListRow) => {
		return (
			<SkioldTouchableOpacity
				onPress={this.props.openEditModal ? this.props.openEditModal : this.props.openBundleEditModal}
				itemFromParent={event}
			>
				<SkioldImage
					width={SowListConstants.iconSVGWidth}
					height={SowListConstants.iconSVGWidth}
					imageData={PenIcon}
				/>
			</SkioldTouchableOpacity>
		);
	};

	private getRemoveCell = (event: GrowthPigEntranceEventBundleListRow) => {
		return (
			<SkioldTouchableOpacity onPress={this.props.removeItem} itemFromParent={event}>
				<SkioldImage
					width={SowListConstants.iconSVGWidth}
					height={SowListConstants.iconSVGWidth}
					imageData={DeleteIcon}
				/>
			</SkioldTouchableOpacity>
		);
	};

	private generateColumns = [
		{
			name: 'edit',
			title: ' ',
			sortable: false,
			filterable: false,
			isFixedLeft: true,
			getCellValue: this.getEditCell,
		},
		{
			name: 'toBuilding',
			title: <ViewWeb className="column-word-wrap">{localized('building')}</ViewWeb>,
			getCellValue: this.getToBuildingNameCell,
		},
		{
			className: getPenClassName,
			name: 'toSection',
			title: <ViewWeb className="column-word-wrap">{localized('section')}</ViewWeb>,
			getCellValue: this.getToSectionNameCell,
			filterFunction: exactNumberFilterMethodGrid,
		},

		{
			name: 'date',
			sortFunction: NaturalSortDates,
			title: localized('Date'),
			getCellValue: this.getDateCell,
		},
		{
			name: 'amount',
			filterFunction: rangeFilterMethodGrid,
			title: localized('Count'),
			getCellValue: this.getAmountCell,
		},
		{
			name: 'avgWeight',
			filterFunction: rangeFilterMethodGrid,
			title: <ViewWeb className="column-word-wrap">{localized('avgWeight')}</ViewWeb>,
			getCellValue: this.getAvgWeightCell,
		},
		{
			name: 'weight',
			filterFunction: rangeFilterMethodGrid,
			title: localized('Weight'),
			getCellValue: this.getTotalWeightCell,
		},

		{
			name: 'quarantine',
			sortable: false,
			filterable: false,
			title: <ViewWeb className="column-word-wrap">{localized('retentionDate')}</ViewWeb>,
			getCellValue: this.getQuarantineCell,
		},
		{
			name: 'productionType',
			title: localized('production'),
			getCellValue: this.getToProductionType,
		},
		{
			name: 'remove',
			title: ' ',
			sortable: false,
			filterable: false,
			isFixedLeft: true,
			getCellValue: this.getRemoveCell,
		},
	];

	private generateColumnExtes = [
		{
			columnName: 'edit',
			width: SowListConstants.iconWidth,
			filteringEnabled: false,
			sortingEnabled: false,
			resizingEnabled: false,
		},

		{
			columnName: 'toBuilding',
			width: SowListConstants.buildingWidth,
		},
		{
			columnName: 'toSection',
			width: SowListConstants.buildingWidth,
		},
		{
			columnName: 'date',
			width: SowListConstants.dateWidth,
		},
		{
			columnName: 'amount',
			width: SowListConstants.countWidth,
		},

		{
			columnName: 'avgWeight',
			width: SowListConstants.avgWeightWidth,
		},
		{
			columnName: 'weight',
			width: SowListConstants.weightWidth,
		},
		{
			columnName: 'quarantine',
			width: SowListConstants.dateWidth,
		},
		{
			columnName: 'productionType',
			width: SowListConstants.locationWidth,
		},
		{
			columnName: 'remove',
			width: SowListConstants.iconWidth,
			filteringEnabled: false,
			sortingEnabled: false,
			resizingEnabled: false,
		},
	];
}
