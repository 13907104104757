import { Dispatch } from 'redux';
import React from 'react';
import { connect } from 'react-redux';

import PenIcon from 'shared/assets/src-assets/png/pen_icon.png';
import { WebAppState } from "web/state/store.web";
import { IDrugType } from 'shared/api/api';
import PageContainer from 'web/view/components/page-container/page-container';
import { Heading } from 'web/view/components/utils/heading';
import { localized } from 'shared/state/i18n/i18n';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { SkioldTable, Row } from 'web/view/components/skiold-components/skiold-table/skiold-table';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldModal } from 'web/view/components/skiold-components/skiold-modal/skiold-modal';
import { SkioldTouchableOpacity } from 'web/view/components/skiold-components/skiold-touchable-opacity';
import { SkioldImage } from 'web/view/components/utils/svg/skiold-image';
import { SowListConstants } from 'web/view/components/stem-animal/animal-lists/table-constants';
import { GetSyncData as DrugTypeGetSyncData } from 'shared/state/ducks/drugTypes/operations';
import './drug-types.scss';
import AddDrugType from 'web/view/components/drugs/drug-type/add-drug-type';
const mapStateToProps = (state: WebAppState) => {
    return {
        drugTypes: state.drugType.entities
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
    getDrugTypes: () => DrugTypeGetSyncData()(dispatch)
});

interface State {
    modalIsOpen: boolean;
    drugTypeToEdit: IDrugType | undefined;
}

type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type StateProps = ReturnType<typeof mapStateToProps>;

type Props = DispatchProps & StateProps;
class DrugTypes extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            modalIsOpen: false,
            drugTypeToEdit: undefined
        };

        this.props.getDrugTypes();
    }

    public render() {
        return (
            <PageContainer className='drug-types'>
                <Heading text={localized('DrugTypes')} />

                <ViewWeb className='drug-types-table-container'>
                    <SkioldTable columns={this.generateColumns()} data={this.props.drugTypes} sortHeaderId={'name'} />
                </ViewWeb>

                <ViewWeb
                    className='add-drug-type-button'
                >
                    <SkioldButton title={localized('AddDrugType')} onPress={() => this.addDrugType()} />
                </ViewWeb>

                <SkioldModal close={() => this.closeModal()} isOpen={this.state.modalIsOpen}>
                    <AddDrugType
                        close={() => this.closeModal()}
                        drugType={this.state.drugTypeToEdit}
                        isOpen={this.state.modalIsOpen}
                    />
                </SkioldModal>
            </PageContainer>
        );
    }

    private closeModal() {
        this.setState({ modalIsOpen: false, drugTypeToEdit: undefined });
    }

    private addDrugType() {
        this.setState({ modalIsOpen: true });
    }

    private edit(drugType: IDrugType) {
        this.setState({ modalIsOpen: true, drugTypeToEdit: drugType });
    }

    private generateColumns() {
        return [
            {
                id: 'type',
                Header: localized('Type'),
                accessor: (d: IDrugType) => localized(d.type!)
            },
            {
                id: 'name',
                Header: localized('name'),
                accessor: (d: IDrugType) => d.name
            },
            {
                id: 'unit',
                Header: localized('Unit'),
                accessor: (d: IDrugType) => d.unitOfMeasurement
            },
            {
                id: 'edit',
                Header: '',
                Cell: (row: Row<IDrugType>) => (
                    <SkioldTouchableOpacity onPress={() => this.edit(row.original)}>
                        <SkioldImage
                            width={SowListConstants.iconSVGWidth}
                            height={SowListConstants.iconSVGWidth}
                            imageData={PenIcon}
                        />
                    </SkioldTouchableOpacity>
                )
            }
        ];
    }
}

export default (
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(DrugTypes)
);
