import { default as ObjectID } from 'bson-objectid';
import { Dispatch } from 'redux';
import { IPregnancyEvent, IStemAnimal, PregnancyEvent } from 'shared/api/api';
import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import { StoreContainer } from 'shared/state/store-container';
import * as Action from './actions';
import { NewPregnancyEventClass } from 'shared/helpers/pregnancy-helper/new-pregnancy-event-class';

export function SavePregnancyEvent(pregnancyEvent: IPregnancyEvent) {
	const state = StoreContainer.getState();
	const animalPregEvents = state.pregnancyEvents.entities[pregnancyEvent.stemAnimalId!];
	const sow = (state.stemAnimals.departuredAnimals as IStemAnimal[]).find(s => pregnancyEvent.stemAnimalId === s.id);
	let prevEntity;
	if (animalPregEvents) {
		prevEntity = animalPregEvents.find(preg => preg.id === pregnancyEvent.id);
	}

	if (!pregnancyEvent.id) {
		pregnancyEvent.id = new ObjectID().toHexString();
	}

	return AsyncOperationBuilder2(
		sow ? Action.saveDeparturedPregnancyEvent : Action.savePregnancyEvent,
		client => client.pregnancyEvent_Upsert(NewPregnancyEventClass(pregnancyEvent)),
		NewPregnancyEventClass(pregnancyEvent),
		prevEntity,
	);
}

export function SavePregnancyEvents(pregnancyEvents: IPregnancyEvent[]) {
	const state = StoreContainer.getState();
	let prevPregs: { [key: string]: IPregnancyEvent } = {};
	pregnancyEvents.forEach(pregEvent => {
		if (pregEvent.stemAnimalId) {
			if (!pregEvent.id) {
				pregEvent.id = new ObjectID().toHexString();
			} else {
				const prevSowPregnancies = state.pregnancyEvents.entities[pregEvent.stemAnimalId];
				if (pregEvent.id && pregEvent.stemAnimalId && prevSowPregnancies) {
					const prevPregEvent = prevSowPregnancies.find(preg => preg.id === pregEvent.id);
					if (prevPregEvent) {
						prevPregs[pregEvent.id] = prevPregEvent;
					}
				}
			}
		}
	});

	return AsyncOperationBuilder2(
		Action.savePregnancyEvents,
		client => client.pregnancyEvent_UpsertMany(pregnancyEvents.map(p => NewPregnancyEventClass(p))),
		{ newPregEvents: pregnancyEvents, prevPregEventHashmap: prevPregs },
	);
}

export function SaveWeaningEvents(pregnancyEvents: IPregnancyEvent[], previousPregnancies: IPregnancyEvent[]) {
	return AsyncOperationBuilder2(
		Action.saveWeaningEvents,
		client =>
			client.pregnancyEvent_UpsertPregnancyEventsWithIgnoredSowState(
				pregnancyEvents.map(p => NewPregnancyEventClass(p)),
			),
		pregnancyEvents.map(p => NewPregnancyEventClass(p)),
		previousPregnancies,
	);
}

export function SavePregnancyEventWithSow(pregnancyEvent: IPregnancyEvent, promiseToAwait: Promise<any>) {
	const state = StoreContainer.getState();
	const animalPregEvents = state.pregnancyEvents.entities[pregnancyEvent.stemAnimalId!];

	let prevEntity;
	if (animalPregEvents) {
		prevEntity = animalPregEvents.find(preg => preg.id === pregnancyEvent.id);
	}

	if (!pregnancyEvent.id) {
		pregnancyEvent.id = new ObjectID().toHexString();
	}
	return AsyncOperationBuilder2(
		Action.savePregnancyEvent,
		client => client.pregnancyEvent_Upsert(NewPregnancyEventClass(pregnancyEvent)),
		NewPregnancyEventClass(pregnancyEvent),
		prevEntity,
		promiseToAwait,
	);
}

export function DeleteLastPregnancyEvent(pregnancyEvent: IPregnancyEvent) {
	return AsyncOperationBuilder2(
		Action.deleteLastPregnancyEvent,
		client => client.pregnancyEvent_Delete(pregnancyEvent.id),
		NewPregnancyEventClass(pregnancyEvent),
	);
}

export function GetSyncData() {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;
	const lastSyncDate = state.pregnancyEvents.lastSyncDate;
	//If data from old version
	if (Array.isArray(state.pregnancyEvents.entities)) {
		return async (dispatch: Dispatch<any>) => {
			dispatch(Action.resetState());
			GetSyncData()(dispatch);
		};
	}

	if (state.pregnancyEvents.syncInProgress) {
		return (dispatch: Dispatch<any>) => {
			return Promise.resolve();
		};
	}

	return AsyncOperationBuilder2(Action.getSyncData, client => client.pregnancyEvent_Sync2(siteId, lastSyncDate), {
		siteId,
		lastSyncDate,
	});
}

export function DeleteLastPregnancyEvents(pregnancyEvents: IPregnancyEvent[]) {
	return AsyncOperationBuilder2(
		Action.deleteLastPregnancyEvents,
		client => client.pregnancyEvent_DeleteMany(pregnancyEvents.map(preg => preg.id!)),
		pregnancyEvents.map(p => NewPregnancyEventClass(p)),
	);
}

export function SaveSyncData() {
	const state = StoreContainer.getState();
	const updates = state.pregnancyEvents.updates;
	let promises = new Array<Promise<void>>();

	return async (dispatch: Dispatch<any>) => {
		if (state.pregnancyEvents.saveSyncInProgress) {
			return Promise.resolve();
		}
		const pregnancyEventsToDelete = updates.filter(preg => preg.isDeleted);
		const pregnancyEventsToUpsert = updates.filter(preg => !preg.isDeleted);
		if (pregnancyEventsToDelete.length > 0) {
			promises.push(DeleteLastPregnancyEvents(pregnancyEventsToDelete)(dispatch));
		}

		if (pregnancyEventsToUpsert.length > 0) {
			promises.push(SavePregnancyEvents(pregnancyEventsToUpsert)(dispatch));
		}

		return await Promise.all(promises);
	};
}

export function GetDeparturedPregnancies() {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;

	return AsyncOperationBuilder2(
		Action.getDeparturedPregnancies,
		client => client.pregnancyEvent_GetDepaturedPregnanciesBySiteId(siteId),
		siteId,
	);
}

export function RemovePregnanciesByAnimal(id: string) {
	return (dispatch: Dispatch<any>) => {
		dispatch(Action.removePregnanciesByAnimal(id));
	};
}
