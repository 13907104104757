import React, { FC, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { ISlaughterSiteDataDto } from 'shared/api/api';
import { getDateString } from 'shared/helpers/date-helpers';
import { setTwoDecimalsAsNumber } from 'shared/helpers/general-helpers';
import { memoizeHashmapLocation } from 'shared/helpers/memoize-getters/memoize-getters';
import { NaturalSortDates } from 'shared/helpers/natural-sort';
import {
	getLocationStringForSlaughterData,
	SlaughterDataTableWidths,
	SlaughterSiteDataDtoExtend,
} from 'shared/helpers/slaughter-data-helper/slaughter-data-helper';
import { localized, localizedDynamic } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import SkioldTableGrid, {
	SkioldTableGrid as SkioldTableRef,
} from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { SkioldCheckbox } from '../../skiold-components/skiold-checkbox/skiold-checkbox';
import { ViewWeb } from '../../utils/web-view';
import { GroupedHeader } from '../../skiold-components/skiold-table/skiold-table-grid/skiold-table-grid-grouped-header';

interface PropsFromParent {
	onDataChanged: (data: SlaughterSiteDataDtoExtend[]) => void;
	setRef: (ref?: SkioldTableRef) => void;
	item: ISlaughterSiteDataDto;
}

export const SlaughterPigsDataTableModal: FC<PropsFromParent> = React.memo(props => {
	const state = useSelector((state: WebAppState) => {
		return {
			data: state.SlaughterDatas.slaughteDatas.filter(
				sd =>
					sd.supplier === props.item.supplier &&
					getDateString(sd.date) === getDateString(props.item.date) &&
					sd.sectionId === props.item.sectionId &&
					sd.buildingId === props.item.buildingId,
			),
			memorizedLoca: memoizeHashmapLocation(
				state.locations.buildings,
				state.locations.sections,
				state.locations.pens,
			),
			siteName: state.site.site.siteName ? state.site.site.siteName : '',
		};
	});
	const commitAll = useState(false);
	const copyOfData = useState(state.data as SlaughterSiteDataDtoExtend[]);

	if (!isEqual(state.data.length, copyOfData[0].length)) {
		copyOfData[1](copyOfData[0].filter(dc => state.data.find(s => s.id === dc.id) !== undefined));
	}
	return (
		<SkioldTableGrid
			ref={props.setRef as any}
			containerClassName="alignSelfCenter"
			className="delieverySlaughterHouseSettingsTable"
			columns={generateColumns(
				copyOfData,
				commitAll,
				state.memorizedLoca,
				state.siteName,
				props.onDataChanged,
				props.item,
			)}
			tableKey="SlaughterPigsDataTableModal"
			ignoreSetCount={true}
			onFiltersChanged={props.onDataChanged}
			ColumnExtensions={generateColumnsExtensions(props.item)}
			data={copyOfData[0]}
			groupedColumns={groupedColumns(props.item)}
		/>
	);
});

function generateColumnsExtensions(item: ISlaughterSiteDataDto) {
	let columns = [
		{
			columnName: 'delieveryDate',
			width: SlaughterDataTableWidths.DateWidth,
		},
		{
			columnName: 'slaughterWeight',
			width: SlaughterDataTableWidths.slaughterWeightWidth,
		},
		{
			columnName: 'meatPercentage',
			width: SlaughterDataTableWidths.meatPercentageWidth,
		},
		{
			columnName: 'gender',
			width: SlaughterDataTableWidths.genderWidth,
		},
		{
			columnName: 'noting',
			width: SlaughterDataTableWidths.notingWidth,
		},
		{
			columnName: 'price',
			width: SlaughterDataTableWidths.priceWidth,
		},
		{
			columnName: 'pricePrKg',
			width: SlaughterDataTableWidths.priceWidth,
		},
		{
			columnName: 'deductionWeight',
			width: SlaughterDataTableWidths.priceWidth,
		},
		{
			columnName: 'deductionMeatPercentage',
			width: SlaughterDataTableWidths.priceWidth,
		},
		{
			columnName: 'deductionDisease',
			width: SlaughterDataTableWidths.priceWidth,
		},
		{
			columnName: 'otherCosts',
			width: SlaughterDataTableWidths.priceWidth,
		},
		{
			columnName: 'listingKg',
			width: SlaughterDataTableWidths.priceWidth,
		},
		{
			columnName: 'type',
			width: SlaughterDataTableWidths.typeWidth,
		},
		{
			columnName: 'aliveWeight',
			width: SlaughterDataTableWidths.aliveWeightWidth,
		},
		{
			columnName: 'location',
			width: SlaughterDataTableWidths.locationWidth,
		},
		{
			columnName: 'slaughterNumber',
			width: SlaughterDataTableWidths.slaughterNumberWidth,
		},

		{
			columnName: 'supplier',
			width: SlaughterDataTableWidths.delieveryNumberWidth,
		},
		{
			columnName: 'diseaseText',
			width: SlaughterDataTableWidths.delieveryNumberWidth,
		},
		{
			columnName: 'priceWithDeduction',
			width: SlaughterDataTableWidths.delieveryNumberWidth,
		},
		{
			columnName: 'edit',
			filteringEnabled: false,
			width: 60,
		},
	];
	if (item.slaughterNumber) {
		columns.splice(10, 0, {
			columnName: 'deductionSpec',
			width: SlaughterDataTableWidths.priceWidth,
		});
	}
	return columns;
}

const groupedColumns = (item: ISlaughterSiteDataDto) => {
	let groupedHeader = [
		{
			title: localized('supplementDeductionPrPig'),
			children: [
				{ columnName: 'deductionWeight' },
				{ columnName: 'deductionMeatPercentage' },
				{ columnName: 'deductionDisease' },
				{ columnName: 'otherCosts' },
			],
		},
	] as GroupedHeader[];

	if (item.slaughterNumber) {
		groupedHeader[0].children.push({ columnName: 'deductionSpec' });
	}
	return groupedHeader;
};

const getSupplier = (sd: SlaughterSiteDataDtoExtend) => sd.supplier;
const getPrice = (sd: SlaughterSiteDataDtoExtend) => (sd.price && sd.price ? setTwoDecimalsAsNumber(sd.price) : 0);
const getMeatPercentage = (sd: SlaughterSiteDataDtoExtend) => setTwoDecimalsAsNumber(sd.meat);
const getSlaughterWeight = (sd: SlaughterSiteDataDtoExtend) => setTwoDecimalsAsNumber(sd.weight);
const getDelieveryDate = (sd: SlaughterSiteDataDtoExtend) => getDateString(sd.date);
const getGenderCell = (sd: SlaughterSiteDataDtoExtend) => sd.gender && localizedDynamic(sd.gender);
const getNotingCell = (sd: SlaughterSiteDataDtoExtend) => sd.noting && localizedDynamic(sd.noting);
const getTypeCell = (sd: SlaughterSiteDataDtoExtend) => sd.type && localized(sd.type);
const getAvgPriceCell = (sd: SlaughterSiteDataDtoExtend) => sd.pricePrPig;
const getAliveWeightCell = (sd: SlaughterSiteDataDtoExtend) => setTwoDecimalsAsNumber(sd.aliveWeight);
const getSlaughterNumberCell = (sd: SlaughterSiteDataDtoExtend) => sd.slaughterNumber;

const getDeductionWeight = (sd: SlaughterSiteDataDtoExtend) => sd.weightToDeduction;
const getDeductionMeatPercentage = (sd: SlaughterSiteDataDtoExtend) => sd.meatPercentageToDeduction;
const getDeductionDisease = (sd: SlaughterSiteDataDtoExtend) => sd.diseaseToDeduction;
const getDeductionSpec = (sd: SlaughterSiteDataDtoExtend) => sd.specToDeduction;
const getListingKg = (sd: SlaughterSiteDataDtoExtend) => sd.listingKg;
const getRemarks = (sd: SlaughterSiteDataDtoExtend) => {
	if (sd.remarks) {
		const translations = sd.remarks
			.filter(e => e)
			.map(
				e =>
					`${localized('DieseaseCodes.' + e) === 'DieseaseCodes.' + e ? e : localized('DieseaseCodes.' + e)}`,
			)
			.join(', ');

		return <div className="diseaseContainer">{translations}</div>;
	}
	return '';
};
const getPriceWithDeduction = (sd: SlaughterSiteDataDtoExtend) => sd.amount;

const generateColumns = (
	dataState: [SlaughterSiteDataDtoExtend[], React.Dispatch<React.SetStateAction<SlaughterSiteDataDtoExtend[]>>],
	commitState: [boolean, React.Dispatch<React.SetStateAction<boolean>>],
	memorizedLocations: { [key: string]: any },
	siteName: string,
	onDataChanged: (data: SlaughterSiteDataDtoExtend[]) => void,
	item: ISlaughterSiteDataDto,
) => {
	const checkBoxUpdateFunction = async (value: boolean, itemFromParent: SlaughterSiteDataDtoExtend) => {
		const dataToUpdate = [...dataState[0]];
		const idToUpdate = dataToUpdate.findIndex(dtu => dtu.id === itemFromParent.id);
		dataToUpdate[idToUpdate] = { ...itemFromParent, isChecked: value };
		commitState[1](false);
		dataState[1](dataToUpdate);
	};
	const columns = [
		{
			name: 'delieveryDate',
			title: localized('Date'),
			sortFunction: NaturalSortDates,
			getCellValue: getDelieveryDate,
		},
		{
			name: 'slaughterWeight',
			title: localized('slaughterWeight'),
			getCellValue: getSlaughterWeight,
		},
		{
			name: 'meatPercentage',
			title: localized('meatPercentage'),
			getCellValue: getMeatPercentage,
		},
		{
			name: 'gender',
			title: localized('Gender'),
			getCellValue: getGenderCell,
		},
		{
			name: 'noting',
			title: localized('noting'),
			getCellValue: getNotingCell,
		},
		{
			name: 'price',
			title: localized('price'),
			getCellValue: getPrice,
		},
		{
			name: 'pricePrKg',
			title: localized('avgPricePrKg'),
			getCellValue: getAvgPriceCell,
		},
		{
			name: 'deductionWeight',
			title: localized('Weight'),
			getCellValue: getDeductionWeight,
		},
		{
			name: 'deductionMeatPercentage',
			title: localized('meatPercentage'),
			getCellValue: getDeductionMeatPercentage,
		},
		{
			name: 'deductionDisease',
			title: localized('Note'),
			getCellValue: getDeductionDisease,
		},
		{
			name: 'otherCosts',
			title: localized('otherCosts'),
		},
		{
			name: 'listingKg',
			title: localized('priceKgBasis'),
			getCellValue: getListingKg,
		},
		{
			name: 'type',
			title: localized('type'),
			getCellValue: getTypeCell,
		},

		{
			name: 'aliveWeight',
			title: localized('aliveWeight'),
			getCellValue: getAliveWeightCell,
		},
		{
			name: 'location',
			title: localized('location'),
			getCellValue: getLocationStringForSlaughterData(memorizedLocations, siteName),
		},
		{
			name: 'slaughterNumber',
			title: localized('slaughterNumber'),
			getCellValue: getSlaughterNumberCell,
		},
		{
			name: 'supplier',
			title: localized('supplier'),
			getCellValue: getSupplier,
		},
		{
			name: 'diseaseText',
			title: localized('noteText'),
			getCellValue: getRemarks,
		},
		{
			name: 'priceWithDeduction',
			title: localized('priceWithDeduction'),
			shouldOverflow: true,
			getCellValue: getPriceWithDeduction,
		},
		{
			name: 'edit',
			title: ' ',
			sortable: false,
			filter: getCommitAllCell(dataState, commitState, onDataChanged),
			getCellValue: getCheckBoxItem(checkBoxUpdateFunction),
		},
	];

	if (item.slaughterNumber) {
		columns.splice(10, 0, {
			name: 'deductionSpec',
			title: localized('spec'),
			getCellValue: getDeductionSpec,
		});
	}

	return columns;
};

const generateCommitAllCheckBox = (
	dataState: [SlaughterSiteDataDtoExtend[], React.Dispatch<React.SetStateAction<SlaughterSiteDataDtoExtend[]>>],
	commitState: [boolean, React.Dispatch<React.SetStateAction<boolean>>],
	onDataChanged: (data: SlaughterSiteDataDtoExtend[]) => void,
) => {
	return (
		<ViewWeb className="checkbox-view-filter-style">
			<SkioldCheckbox
				isChecked={commitState[0]}
				itemFromParent={{ dataState, commitState, onDataChanged }}
				className="checkbox-style-filter"
				onClick={commitAll}
			/>
		</ViewWeb>
	);
};

const commitAll = (
	value: boolean,
	itemFromParent: {
		dataState: [SlaughterSiteDataDtoExtend[], React.Dispatch<React.SetStateAction<SlaughterSiteDataDtoExtend[]>>];
		commitState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
		onDataChanged: (data: SlaughterSiteDataDtoExtend[]) => void;
	},
) => {
	const copyOfData = [...itemFromParent.dataState[0]];
	copyOfData.forEach(d => {
		d.isChecked = value;
	});
	itemFromParent.commitState[1](value);
	itemFromParent.dataState[1](copyOfData);
	itemFromParent.onDataChanged(copyOfData);
};

function getCommitAllCell(
	dataState: [SlaughterSiteDataDtoExtend[], React.Dispatch<React.SetStateAction<SlaughterSiteDataDtoExtend[]>>],
	commitState: [boolean, React.Dispatch<React.SetStateAction<boolean>>],
	onDataChanged: (data: SlaughterSiteDataDtoExtend[]) => void,
) {
	return () => generateCommitAllCheckBox(dataState, commitState, onDataChanged);
}

function getCheckBoxItem(
	checkBoxUpdateFunction: (value: boolean, itemFromParent: SlaughterSiteDataDtoExtend) => Promise<void>,
) {
	return (d: SlaughterSiteDataDtoExtend) => (
		<ViewWeb className="checkbox-view-style">
			<SkioldCheckbox
				isChecked={d.isChecked}
				itemFromParent={d}
				className="checkbox-style"
				onClick={checkBoxUpdateFunction}
			/>
		</ViewWeb>
	);
}
