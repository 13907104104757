import React from 'react';
import { connect } from 'react-redux';
import { Gender, ILardScanningEvent, IStemAnimal, LardScanningEvent, StemAnimal } from 'shared/api/api';
import {
	lardScanningEventMapDispatchToProps,
	lardScanningEventMapStateToProps,
	RegisterSowLardProps,
	RegisterSowLardState,
	validateSowLardScanningEvent,
} from 'shared/helpers/stemanimal-helper/register-lard-event-helper';
import { localized } from 'shared/state/i18n/i18n';
import { showAlert } from 'web/view/components/skiold-alert/skiold-alert';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldDatePicker } from 'web/view/components/skiold-components/skiold-date-picker/skiold-date-picker';
import { SkioldFormDecimalInput } from 'web/view/components/skiold-components/skiold-decimal-input/skiold-form-decimal-input';
import { SkioldFormsWrapper } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { ViewWeb } from 'web/view/components/utils/web-view';
import StemAnimalInput from '../../stem-animal-input/stem-animal-input';
import './register-sow-lard-scanning-event.scss';

export class RegisterSowLard extends React.PureComponent<RegisterSowLardProps, RegisterSowLardState> {
	public moveEventSave: (() => Promise<boolean>) | undefined;

	constructor(props: RegisterSowLardProps) {
		super(props);
		this.state = {
			sow: StemAnimal.fromJS({}),
			lard: LardScanningEvent.fromJS({ date: new Date() } as ILardScanningEvent),
			animalNumber: '',
			toggleFocus: false,
		};
	}

	public animalNumberChanged = (sow: IStemAnimal | undefined) => {
		if (sow) {
			this.setState({ sow });
		} else {
			this.setState({ sow: StemAnimal.fromJS({}) });
		}
	};

	public dateChanged = (newDate: Date) => {
		this.setState(prevState => ({ lard: { ...prevState.lard, date: newDate } }));
	};

	public render() {
		return <ViewWeb className="register-sow-lard">{this.renderContent()}</ViewWeb>;
	}

	public renderContent() {
		let formRows = this.getFormRows();
		return (
			<ViewWeb>
				{this.renderButtons()}
				<ViewWeb className="move-event-container-zindex">
					<SkioldFormsWrapper formRows={formRows} containerClassName="forms-wrapper-style" />
				</ViewWeb>
			</ViewWeb>
		);
	}

	public save = async () => {
		let stemId = this.state.sow && this.state.sow.id;
		if (await validateSowLardScanningEvent(this.state.lard, stemId, showAlert)) {
			let lard = { ...this.state.lard };
			lard.siteId = this.props.active.siteId;
			lard.createdBy = lard.createdBy ? lard.createdBy : this.props.active.id;
			lard.modifiedBy = this.props.active.id;
			lard.stemAnimalId = stemId;
			this.props.saveLardScanningEvent(lard);
			await this.resetComponent();
		}
	};

	private onAnimalNumberChanged = (animalNumber: string | undefined) => this.setState({ animalNumber });

	private getFormRows() {
		let formRows = new Array<FormRow>();
		formRows.push(this.getPregnancyAnimalNumberRow());
		formRows.push(this.getDateRow());
		formRows.push(this.getSowLardWidthRow());
		formRows.push(this.getSowLardWeightRow());
		return formRows;
	}

	private getPregnancyAnimalNumberRow(): FormRow {
		return {
			name: localized('animalNumber'),
			component: (
				<StemAnimalInput
					animalNumber={this.state.animalNumber}
					onAnimalNumberChanged={this.onAnimalNumberChanged}
					onAnimalFound={this.animalNumberChanged}
					gender={Gender.Female}
					inputType={'form'}
					autoFocus={true}
					toggleFocus={this.state.toggleFocus}
				/>
			),
		};
	}

	private getDateRow(): FormRow {
		return {
			name: localized('Date'),
			component: (
				<SkioldDatePicker
					onDateChanged={this.dateChanged}
					selectedDate={this.state.lard.date!}
					theme={'dark'}
					color={'grey'}
				/>
			),
		};
	}

	private getSowLardWidthRow(): FormRow {
		return {
			name: localized('sowLardWidth'),
			component: <SkioldFormDecimalInput onChangeNumber={this.lardWidthChanged} text={this.state.lard.width} />,
		};
	}

	private getSowLardWeightRow(): FormRow {
		return {
			name: localized('Weight'),
			component: (
				<SkioldFormDecimalInput
					numberOfDecimals={1}
					onChangeNumber={this.lardWeightChanged}
					text={this.state.lard.weight}
				/>
			),
		};
	}

	private lardWeightChanged = (number: number | undefined) => {
		this.setState({ lard: { ...this.state.lard, weight: number } });
	};

	private lardWidthChanged = (number: number | undefined) => {
		this.setState({ lard: { ...this.state.lard, width: number } });
	};

	private renderButtons() {
		return (
			<ViewWeb className="button-view-style-upper-corner">
				<SkioldButton title={localized('Save')} onPress={this.save} />
			</ViewWeb>
		);
	}

	// Called after successfull save to remove one-time values
	private async resetComponent() {
		this.setState({
			sow: StemAnimal.fromJS({}),
			lard: LardScanningEvent.fromJS({
				date: new Date(),
				width: this.state.lard.width,
				weight: undefined,
			} as ILardScanningEvent),
			animalNumber: '',
			toggleFocus: false,
		});
	}
}

export default connect(lardScanningEventMapStateToProps, lardScanningEventMapDispatchToProps)(RegisterSowLard);
