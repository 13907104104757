

import * as types from './types'
import { NOT_FOUND } from 'redux-first-router';
import { RouteKey, Routes } from './routes';

// export const goToPage = (type: any , payload?: any) => ({
//   type,
//   payload: payload &&  payload
// })

export const goToPage = (type: RouteKey , payload?: any, query?: any) => {
  const payloadObject = payload ? payload: {}
  const queryObject = query ? query: {}
  const routeSetting = Routes[type] as any
  return {
    type,
    payload: payloadObject,
    query: routeSetting && {...routeSetting.params, ...queryObject}
  }
}


export const notFound = () => ({
  type: NOT_FOUND
})
  
  export const routeHome = () => ({
    type: types.ROUTE_HOME
  })

  export const routeEvent = () => ({
    type: types.ROUTE_EVENTS
  })