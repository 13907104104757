import { Action, applyMiddleware, createStore } from 'redux';
import * as ReduxDevTools from 'redux-devtools-extension';
import { persistCombineReducers, persistReducer, persistStore } from 'redux-persist';
import { PersistPartial } from 'redux-persist/lib/persistReducer';
import thunk from 'redux-thunk';
import { AdjustFeedState } from 'shared/state/ducks/adjust-feed';
import dashboardOverviewReducer from 'shared/state/ducks/dashboard/reducer';
import dashboardGrowthPigsOverviewReducer from 'shared/state/ducks/dashboard-growth-pigs-events/reducer';
import slaughterHouseConnectionReducer from 'shared/state/ducks/connection-slaughter-house/reducer';
import { DeadPigletsEventEventState } from 'shared/state/ducks/dead-piglets-event';
import { LardScanningEventState } from 'shared/state/ducks/lardScanningEvents';
import { MoveEventState } from 'shared/state/ducks/move-events';
import { PregnancyState } from 'shared/state/ducks/pregnancy-events';
import { StemAnimalState } from 'shared/state/ducks/stem-animals';
import { TreatmentState } from 'shared/state/ducks/treatment';
import { TreatmentPlanState } from 'shared/state/ducks/treatment-plan';
import { persistTranslationMiddleware } from 'shared/state/middleware/translation-middleware';
import { StoreContainer } from 'shared/state/store-container';
//Import of shared store
import {
	adjustFeed,
	deadPigletsEvents,
	getDefaultPersistConfig,
	growthPigEvents,
	lardScanningEvents,
	moveEvents,
	pregnancyEvents,
	sharedReducerMap,
	stemAnimals,
	treatmentPlans,
	treatments,
} from 'shared/state/store.shared';
//Web reducers
import navReducer from 'web/state/ducks/navigation/reducer';
import { getStorageEngine } from 'web/state/storage-helper.web'; //Used when creating persistReducers
import { aiLoggerMiddleware } from './middleware/ailogger-middleware';
import { authorizationMiddleware } from './middleware/authorization-middleware';
import SlaughterHouseSupplierDetailsReducer from 'shared/state/ducks/supplier-details-slaughter-house/reducer';
import SlaughterDatasReducer from 'shared/state/ducks/slaughter-data/reducer';
import BMPDataReducer from 'web/state/ducks/bmp/reducer';
import { GrowthPigEventState } from 'shared/state/ducks/growth-pig-events';
import distriwinFeedComponentReducer from 'shared/state/ducks/distriwin-feed-component/reducer';
import distriwinFeedConsumptionReducer from 'shared/state/ducks/distriwin-feed-consumptions/reducer';
import distriwinFeedRegistrationReducer from 'shared/state/ducks/distriwin-feed-registrations/reducer';

//Setup shared persist:
pregnancyEvents.config.storage = getStorageEngine();
stemAnimals.config.storage = getStorageEngine();
moveEvents.config.storage = getStorageEngine();
treatmentPlans.config.storage = getStorageEngine();
treatments.config.storage = getStorageEngine();
adjustFeed.config.storage = getStorageEngine();
lardScanningEvents.config.storage = getStorageEngine();
deadPigletsEvents.config.storage = getStorageEngine();
growthPigEvents.config.storage = getStorageEngine();

const persistReducerMap = {
	pregnancyEvents: persistReducer(pregnancyEvents.config, pregnancyEvents.reducer) as (
		s: PregnancyState,
		a: Action,
	) => PregnancyState & PersistPartial,
	stemAnimals: persistReducer(stemAnimals.config, stemAnimals.reducer) as (
		s: StemAnimalState | undefined,
		a: Action,
	) => StemAnimalState,
	moveEvents: persistReducer(moveEvents.config, moveEvents.reducer) as (
		s: MoveEventState,
		a: Action,
	) => MoveEventState & PersistPartial,
	treatmentPlans: persistReducer(treatmentPlans.config, treatmentPlans.reducer) as (
		s: TreatmentPlanState,
		a: Action,
	) => TreatmentPlanState & PersistPartial,
	treatments: persistReducer(treatments.config, treatments.reducer) as (
		s: TreatmentState,
		a: Action,
	) => TreatmentState & PersistPartial,
	adjustFeed: persistReducer(adjustFeed.config, adjustFeed.reducer) as (
		s: AdjustFeedState,
		a: Action,
	) => AdjustFeedState & PersistPartial,
	lardScanningEvents: persistReducer(lardScanningEvents.config, lardScanningEvents.reducer) as (
		s: LardScanningEventState,
		a: Action,
	) => LardScanningEventState & PersistPartial,
	deadPigletsEvents: persistReducer(deadPigletsEvents.config, deadPigletsEvents.reducer) as (
		s: DeadPigletsEventEventState,
		a: Action,
	) => DeadPigletsEventEventState & PersistPartial,
	growthPigEvents: persistReducer(growthPigEvents.config, growthPigEvents.reducer) as (
		s: GrowthPigEventState,
		a: Action,
	) => GrowthPigEventState & PersistPartial,
};

const webReducerMap = {
	navigation: navReducer.reducer,
	dashboardOverview: dashboardOverviewReducer,
	dashboardGrowthPigsOverview: dashboardGrowthPigsOverviewReducer,
	slaughterhouseConnections: slaughterHouseConnectionReducer,
	SlaughterHouseSupplierDetails: SlaughterHouseSupplierDetailsReducer,
	SlaughterDatas: SlaughterDatasReducer,
	BMPDatas: BMPDataReducer,
	distriwinFeedComponents: distriwinFeedComponentReducer,
	distriwinFeedConsumptions: distriwinFeedConsumptionReducer,
	distriwinFeedRegistrations: distriwinFeedRegistrationReducer,
};

const reducerMap = {
	...sharedReducerMap,
	...persistReducerMap,
	...webReducerMap,
};

let defaultPersistConfig = getDefaultPersistConfig(getStorageEngine());
//Setup with values from shared
const rootPersistConfig = {
	...defaultPersistConfig,

	whitelist: [...defaultPersistConfig.whitelist!],
};

// WebAppState is the keys of the reducerMap, with the values returned by each key's respective reducer
export type WebAppState = { [K in keyof typeof reducerMap]: ReturnType<typeof reducerMap[K]> };

export const AppReducers = persistCombineReducers<WebAppState>(rootPersistConfig, reducerMap as any);

export const rootReducer = (state, action) => {
	return AppReducers(state, action);
};

// tslint:disable-next-line:no-any
declare const window: any;
const composeStore = () => {
	const devToolOpts: ReduxDevTools.EnhancerOptions = {
		shouldHotReload: true,
	};
	let composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
	if (!composeEnhancers) {
		composeEnhancers = ReduxDevTools.composeWithDevTools(devToolOpts);
	}

	const commonMiddlewares = [
		applyMiddleware(thunk),
		applyMiddleware(navReducer.middleware),
		applyMiddleware(persistTranslationMiddleware()),
		applyMiddleware(aiLoggerMiddleware()),
		applyMiddleware(authorizationMiddleware()),
	];

	const webMiddlewares = [];
	const middlewares = commonMiddlewares.concat(webMiddlewares);
	return createStore(rootReducer, composeEnhancers(navReducer.enhancer, ...middlewares));
};

//export const store = composeStore();
let composedStore = composeStore();
StoreContainer.store = composedStore;

export const persistor = persistStore(StoreContainer.store);

export default () => {
	if ((module as any).hot) {
		(module as any).hot.accept(() => {
			const nextReducer = rootReducer;
			StoreContainer.store.replaceReducer(persistReducer(rootPersistConfig, nextReducer) as any);
		});
	}
	return { store: StoreContainer.store, persistor };
};
