import actionCreatorFactory from 'typescript-fsa';
import * as types from './types';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import { IDistriwinFeedRegistration } from 'shared/api/api';

const actionCreator = actionCreatorFactory(GenActionFactoryName('distriwinFeedRegistrations'));

export const getDistriwinFeedRegistrations = actionCreator.async<{ siteId: string }, IDistriwinFeedRegistration[]>(
	types.GET_DISTRIWIN_FEED_REGISTRATIONS,
);
