import { Sorting } from '@devexpress/dx-react-grid';
import memoize from 'memoize-one';
import React from 'react';
import { connect } from 'react-redux';
import { IPregnancyEvent, IScanned, IStemAnimal, PregnancyState, ScanResult } from 'shared/api/api';
import sokort_icon from 'shared/assets/src-assets/png/sokort_icon.png';
import { exactStartsWithMethodGrid } from 'shared/helpers/general-helpers';
import { NaturalSortDates } from 'shared/helpers/natural-sort';
import { calculateLitterByPregnancy } from 'shared/helpers/stemanimal-helper/stemanimal-helper';
import { localized, localizedDynamic } from 'shared/state/i18n/i18n';
import 'web/view/components/event-lists/pregnancy-event-lists/sow-pregnancy-event-list-styles.scss';
import { SkioldModal } from 'web/view/components/skiold-components/skiold-modal/skiold-modal';
import 'web/view/components/skiold-components/skiold-table/skiold-double-header-table.scss';
import SkioldTableGrid, {
	SkioldTableGrid as SkioldTableRef,
} from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { SkioldTouchableOpacity } from 'web/view/components/skiold-components/skiold-touchable-opacity';
import { SowListConstants } from 'web/view/components/stem-animal/animal-lists/table-constants';
import { getActiveSows } from 'web/view/components/stem-animal/stem-animal-input/stem-animal-input-helper';
import { SkioldImage } from 'web/view/components/utils/svg/skiold-image';
import SowCard from 'web/view/pages/sow-card/sow-card';
import { getWebAnimalColorStyleTable } from 'web/web-helpers/general-web-helpers';
import {
	getEventListAnimalNumberCell,
	getEventListLitterCell,
	getEventListPregnancyDateCell,
	PregnancyEventListMapDispatchToProps,
	PregnancyEventListMapStateToProps,
	PregnancyEventListProps,
	PregnancyEventListState,
	ScanningEventListItem,
	ScanningSummaryItem,
} from '../sow-pregnancy-event-list-helper';

export class ScanningEventListTable extends React.PureComponent<PregnancyEventListProps, PregnancyEventListState> {
	public SkioldTableRef: SkioldTableRef | undefined;

	private generateData = memoize((dateFrom, dateTo, departuredSows, pregnancies, departuredPregnancies) =>
		this.genereateListData(),
	);

	private defaultSorting = [{ columnName: 'animalNumber', direction: 'asc' }] as Sorting[];
	private readonly getScanResultCell = (d: ScanningEventListItem) => d.scanResult;

	private readonly showSowCard = (d: ScanningEventListItem) => {
		this.setState({ sowCardModal: true, stemAnimalId: d.stemAnimalId! });
	};
	private readonly getInitCell = (d: ScanningEventListItem) => {
		if (d.pregnancyEvent) {
			const user = this.props.userProfiles.find(u => u.id === d.pregnancyEvent?.createdBy);
			return user?.initials ?? '';
		}
		return ' ';
	};

	constructor(props: PregnancyEventListProps) {
		super(props);
		this.state = {
			columns: this.generateColumns(),
			columnExte: this.generateColumnsExtensions(),
			loading: false,
			commitAll: false,
			stemAnimalId: '',
			sowCardModal: false,
		};
	}

	public genereateListData() {
		let activeSows = getActiveSows();
		let { pregnancyEvents } = this.props;
		let listItems: ScanningEventListItem[] = [];
		this.insertDataIntoListItems(activeSows, listItems, pregnancyEvents);
		if (this.props.departuredSows && this.props.departuredSows.length > 0) {
			this.insertDataIntoListItems(this.props.departuredSows, listItems, this.props.departuredPregnancies);
		}
		this.generateSummary(listItems);
		return listItems;
	}

	public generateColumnsExtensions() {
		let columnExt = [
			{
				columnName: 'sowCard',
				width: SowListConstants.iconWidth,
				filteringEnabled: false,
				sortingEnabled: false,
				resizingEnabled: false,
			},
			{
				columnName: 'animalNumber',
				width: SowListConstants.animalNrWidth,
			},			{
				columnName: 'breedRace',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'litter',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'date',
				width: SowListConstants.entranceDateWidth,
			},
			{
				columnName: 'pregnant',
				width: SowListConstants.animalNrWidth,
			},
		];
		if (this.props.generalSettings.stemAnimalShowInitials) {
			columnExt.push({
				columnName: 'initial',
				width: SowListConstants.animalNrWidth,
			});
		}
		return columnExt;
	}

	public componentDidMount() {
		if (this.SkioldTableRef) {
			this.props.setSowsCount(this.SkioldTableRef.GetSortedData().length);
		}
	}

	public render() {
		return (
			<div className="alignTableCenterDefault">
				<SkioldTableGrid
					tableKey={'scanningEventListTable'}
					columns={this.state.columns}
					ColumnExtensions={this.state.columnExte}
					data={this.generateData(
						this.props.dateFrom,
						this.props.dateTo,
						this.props.departuredSows,
						this.props.pregnancyEvents,
						this.props.departuredPregnancies,
					)}
					ref={this.setTableRef}
					onFiltersChanged={this.onFiltersChanged}
					sortHeaderId={this.defaultSorting}
				/>

				<SkioldModal
					shouldCloseOnOverlayClick={true}
					padding="0"
					isOpen={this.state.sowCardModal}
					close={this.closeModal}
					justify-content="flex-end"
					max-width="calc(100% - 220px)"
				>
					<SowCard stemAnimalIdFromParent={this.state.stemAnimalId} close={this.closeModal} />
				</SkioldModal>
			</div>
		);
	}

	private insertDataIntoListItems(
		stemAnimals: IStemAnimal[],
		listItems: ScanningEventListItem[],
		pregnancyEvents: { [key: string]: IPregnancyEvent[] },
	) {
		stemAnimals.forEach(sow => {
			let sowPregnancyEvents = pregnancyEvents[sow.id!];
			if (sowPregnancyEvents && sowPregnancyEvents.length > 0) {
				let scannedPregnancyEvents = sowPregnancyEvents.filter(a => a.state === PregnancyState.Scanned);
				scannedPregnancyEvents.forEach((pregnancyEvent: any) => {
					if (
						this.props.dateFrom === undefined ||
						this.props.dateTo === undefined ||
						(pregnancyEvent.date! >= this.props.dateFrom && pregnancyEvent.date! <= this.props.dateTo)
					) {
						let item = new ScanningEventListItem();
						item.stemAnimalId = sow.id;
						item.litterNumber = calculateLitterByPregnancy(sow, pregnancyEvent, sowPregnancyEvents);
						item.animalNumber = sow.animalNumber;
						item.scanResult = localizedDynamic(pregnancyEvent.result!);
						item.pregnancyEvent = pregnancyEvent;
						item.breedRace = sow.race
						listItems.push(item);
					}
				});
			}
		});
	}
	private generateSummary(pregnancyEvents: ScanningEventListItem[]) {
		let summaryItem: ScanningSummaryItem = new ScanningSummaryItem();
		pregnancyEvents.forEach(event => {
			if (event.pregnancyEvent!.result !== ScanResult.False) {
				summaryItem.pregnant++;
			} else {
				summaryItem.nonPregnant++;
			}
		});

		summaryItem.sowCount = pregnancyEvents.length.toString();
		this.props.onSummaryChanged(
			<div className="marginTopTen">
				<div className="flexDirectionRow">
					<div className="flexDirectionRowWithWidth">
						<div className="summaryTextStyle"> {localized('Scannings')}: </div>
						<div className="summaryTextStyle"> {summaryItem.sowCount} </div>
					</div>
				</div>
				<div className="flexDirectionRow">
					<div className="flexDirectionRowWithWidth">
						<div className="summaryTextStyle"> {localized('Pregnant')}: </div>
						<div className="summaryTextStyle"> {summaryItem.pregnant} </div>
					</div>
				</div>
				<div className="flexDirectionRow">
					<div className="flexDirectionRowWithWidth">
						<div className="summaryTextStyle"> {localized('NonPregant')}: </div>
						<div className="summaryTextStyle"> {summaryItem.nonPregnant} </div>
					</div>
				</div>
			</div>,
		);
	}
	private onFiltersChanged = (events: ScanningEventListItem[]) => {
		this.generateSummary(events);
	};

	private closeModal = () => {
		this.setState({ stemAnimalId: '', sowCardModal: false });
	};

	private setTableRef = (m: any) => (m ? (this.SkioldTableRef = m) : {});

	private getStemAnimalNumberColor = (tableItem: ScanningEventListItem) => {
		return tableItem && tableItem.stemAnimalId ? getWebAnimalColorStyleTable(tableItem.stemAnimalId) : '';
	};

	private generateColumns() {
		const columns = [
			{
				name: 'sowCard',
				title: ' ',
				headerClassName: 'merged-header',
				sortable: false,
				getCellValue: (d: ScanningEventListItem) => (
					<SkioldTouchableOpacity itemFromParent={d} onPress={this.showSowCard}>
						<SkioldImage
							width={SowListConstants.iconSVGWidth}
							height={SowListConstants.iconSVGWidth}
							imageData={sokort_icon}
						/>
					</SkioldTouchableOpacity>
				),
			},
			{
				name: 'animalNumber',
				headerClassName: 'merged-header',
				title: localized('animalNumber'),
				className: this.getStemAnimalNumberColor,
				filterFunction: exactStartsWithMethodGrid,
				getCellValue: getEventListAnimalNumberCell,
			},			{
				name: 'breedRace',
				title: localized('Race'),
			},
			{
				name: 'litter',
				title: localized('LitterNr'),
				getCellValue: getEventListLitterCell,
			},
			{
				name: 'date',
				title: localized('Date'),
				pathToValue: 'pregnancyEvent',
				sortFunction: NaturalSortDates,
				getCellValue: getEventListPregnancyDateCell,
			},
			{
				name: 'pregnant',
				title: localized('Pregnant'),
				getCellValue: this.getScanResultCell,
			},
		];
		if (this.props.generalSettings.stemAnimalShowInitials) {
			columns.push({
				name: 'initial',
				title: localized('InitialsShort'),
				getCellValue: this.getInitCell,
			});
		}

		return columns;
	}
}

export default connect(PregnancyEventListMapStateToProps, PregnancyEventListMapDispatchToProps, null, {
	forwardRef: true,
})(ScanningEventListTable) as any;
