import { Getter, Plugin, Template, TemplateConnector, TemplatePlaceholder } from '@devexpress/dx-react-core';
import {
	DataTypeProvider,
	FilteringState,
	IntegratedFiltering,
	IntegratedSelection,
	IntegratedSorting,
	IntegratedSummary,
	SelectionState,
	Sorting,
	SortingState,
	SummaryState,
	TableColumnWidthInfo,
} from '@devexpress/dx-react-grid';
import {
	Grid,
	TableBandHeader,
	TableColumnResizing,
	TableFilterRow,
	TableFixedColumns,
	TableHeaderRow,
	TableSummaryRow,
	VirtualTable,
	TableSelection,
} from '@devexpress/dx-react-grid-material-ui';
import { Paper } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import React from 'react';
import isEqual from 'react-fast-compare';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { isOdd, validateTableResizing } from 'shared/helpers/general-helpers';
import { NaturalSort } from 'shared/helpers/natural-sort';
import { RefType } from 'shared/helpers/ref-type';
import { SetSowsCount } from 'shared/state/ducks/stem-animals/operations';
import { setSize } from 'shared/state/ducks/table-settings/operations';
import { TableColumnWidthInfoGeneric } from 'shared/state/ducks/table-settings/types';
import { WebAppState } from 'web/state/store.web';
import { SowListConstants } from 'web/view/components/stem-animal/animal-lists/table-constants';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { GroupedHeader } from './skiold-table-grid-grouped-header';
import './skiold-table-grid.scss';
import { SkioldCheckbox } from '../../skiold-checkbox/skiold-checkbox';
import { localized } from 'shared/state/i18n/i18n';

export interface PropsFromParent {
	data: any[];
	columns: any[];
	ColumnExtensions?: any[];
	footerRows?: SkioldTableGridFooterRow[];
	InlineHeaders?: string[];
	sortHeaderId?: Sorting[];
	style?: any;
	filterable?: boolean;
	sortable?: boolean;
	showNoDataText?: boolean;
	getTrProps?: any;
	rowCount?: number;
	showPagination?: boolean;
	selectable?: boolean;
	groupedColumns?: GroupedHeader[];
	onClickedRow?: (rowData: any) => void;
	onFiltersChanged?: (currentData: any[]) => void;
	ignoreSetCount?: boolean;
	className?: string;
	containerClassName?: string;
	headerCapitalized?: boolean;
	useRowIndex?: boolean;
	resizeAbleEnabled?: boolean;
	tableKey?: string;
	maxWidthForTable?: number;
	usesSummaryRow?: boolean;
	estimatedRowHeight?: number;
	useSelection?: boolean;
	selectionHeader?: string;
	triggerFilterChangedOnDataNotEqual?: boolean;
	selectedChanged?: (selected: any[]) => void;
	handleDynamicBug?: boolean;
	rowStyling?: any;
	rowStylingIndicator?: { fromTop: number; fromBottom: number };
	IsInModal?: boolean;
	lineHeightValue?: number;
}

export class SkioldTableGridFooterRow {
	public className?: string;
	public items: any[] = [];
}

const mapStateToProps = (state: WebAppState) => {
	return {
		tableSettings: state.tableSettings.columnWidthsHashMap,
	};
};

export interface Column {
	id: string;
}

export interface Row<T> {
	original: T;
	index: number;
	column: Column;
}

export interface State {
	selection: React.ReactText[];
	maxWidthForRow: number;
	tableSettings: { [key: string]: Array<TableColumnWidthInfoGeneric<string>> };
	ColumnExtensions?: any[];
	summaryValues?: any[];
}

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	setSowsCount: (count: number) => SetSowsCount(count)(dispatch),
	setSize: (key: string, size: Array<TableColumnWidthInfoGeneric<string>>) => setSize(key, size)(dispatch),
});
type SkioldTableProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;
export class SkioldTableGrid extends React.PureComponent<SkioldTableProps, State> {
	public static defaultProps: Partial<SkioldTableProps> = {
		filterable: true,
		sortHeaderId: undefined,
		sortable: true,
		showNoDataText: false,
		showPagination: false,
		selectable: false,
		ignoreSetCount: false,
		className: '',
		headerCapitalized: true,
		useRowIndex: false,
		estimatedRowHeight: 35,
		resizeAbleEnabled: true,
		lineHeightValue: 40,
		selectionHeader: localized('completed'),
	};

	public componentDidUpdate(prevProps: SkioldTableProps) {
		if (
			prevProps.data.length !== this.props.data.length ||
			(this.props.triggerFilterChangedOnDataNotEqual && !isEqual(prevProps.data, this.props.data))
		) {
			this.changeSelection([]);
			if (this.props.onFiltersChanged) {
				this.props.onFiltersChanged(this.props.data);
			}
		}

		// This is a special case, where column are added dynamically
		if (this.props.handleDynamicBug) {
			this.props.columns.forEach(column => {
				if (column.isFixedLeft) {
					this.fixedLeftColumns.push(column.name);
					this.hasFixedColumns();
				} else if (column.isFixedRight) {
					this.fixedRightColumns.push(column.name);
					this.hasFixedColumns();
				}
				if (this.props.useSelection) {
					this.fixedLeftColumns.push(TableSelection.COLUMN_TYPE);
					this.hasFixedColumns();
				}
				if (column.sortable !== false) {
					this.integratedSortingColumnExtensions.push({
						columnName: column.name,
						compare: (a: any, b: any) => {
							return column.sortFunction ? column.sortFunction(a, b) : NaturalSort(a, b);
						},
						useHiddenSort: column.useHiddenSort,
					});

					// sets the render value. Used to sort differently than the shown value
					if (column.renderValue) {
						this.integratedSortByColumnExtensions.push(column.name);
					}
				}

				if (column.filterable !== false) {
					this.integratedFilterColumnExtensions.push({
						columnName: column.name,
						predicate: (value: any, filter: any) => {
							return column.filterFunction
								? column.filterFunction(value, filter)
								: value
								? value.toString().toLowerCase().includes(filter.value.toString().toLowerCase())
								: false;
						},
					});
				}
			});
		}
	}

	public static getDerivedStateFromProps(nextProps: SkioldTableProps, prevState: State) {
		if (
			!isEqual(nextProps.tableSettings, prevState.tableSettings) ||
			!isEqual(prevState.ColumnExtensions, nextProps.ColumnExtensions)
		) {
			let maxWidthForRow = 0;
			if (
				nextProps.ColumnExtensions &&
				((nextProps.tableKey && !nextProps.tableSettings[nextProps.tableKey]) || !nextProps.tableKey)
			) {
				nextProps.ColumnExtensions.forEach((column, index) => {
					if (column.width) {
						maxWidthForRow = maxWidthForRow + column.width;
					}
				});
			}
			if (nextProps.tableKey && nextProps.tableSettings[nextProps.tableKey]) {
				nextProps.tableSettings[nextProps.tableKey].forEach((column, index) => {
					// Used to handle dynamic and adjusted columns
					let extensionIds = nextProps.ColumnExtensions
						? nextProps.ColumnExtensions.map(ex => ex.columnName)
						: [];
					if (!extensionIds.includes(column.columnName)) {
						return;
					}
					if (column.width) {
						maxWidthForRow = maxWidthForRow + Number(column.width);
					}
				});
			}

			// If not default width
			if (maxWidthForRow > 0) {
				// One extra pixel is necessary to avoid scrollbar
				maxWidthForRow++;
			}

			if (nextProps.useSelection) {
				maxWidthForRow += 100;
			}

			return {
				tableSettings: nextProps.tableSettings,
				ColumnExtensions: nextProps.ColumnExtensions,
				maxWidthForRow: maxWidthForRow,
			};
		}
		return {};
	}

	public virtualTableRef: any;
	public currentData = [...this.props.data];
	public currentFilters: any[] = [];
	public currentSorting: Sorting[] | undefined = this.props.sortHeaderId;
	private didFilterChange: boolean = false;
	private GridRootClassName: string = 'skiold-table-grid';
	private fixedLeftColumns: any[] = [];
	private fixedRightColumns: any[] = [];
	private integratedSortingColumnExtensions: any[] = [];
	private integratedFilterColumnExtensions: any[] = [];
	private fixedColumnsLeft: { [id: string]: number } = {};
	private fixedColumnsRight: { [id: string]: number } = {};
	private AllGroupedHeadersNames: string[] = [];
	private integratedSortByColumnExtensions: any[] = [];

	constructor(props: SkioldTableProps) {
		super(props);

		if (!this.props.ignoreSetCount) {
			this.props.setSowsCount(this.currentData.length);
		}
		if (this.props.onFiltersChanged) {
			this.props.onFiltersChanged(this.currentData);
		}

		let leftIndex = 0;
		let rightIndex = 0;
		this.props.columns.forEach(column => {
			if (column.isFixedLeft) {
				this.fixedLeftColumns.push(column.name);
				this.hasFixedColumns();
			} else if (column.isFixedRight) {
				this.fixedRightColumns.push(column.name);
				this.hasFixedColumns();
			}
			if (this.props.useSelection) {
				this.fixedLeftColumns.push(TableSelection.COLUMN_TYPE);
				this.hasFixedColumns();
			}
			if (column.sortable !== false) {
				this.integratedSortingColumnExtensions.push({
					columnName: column.name,
					compare: (a: any, b: any) => {
						return column.sortFunction ? column.sortFunction(a, b) : NaturalSort(a, b);
					},
					useHiddenSort: column.useHiddenSort,
				});

				// sets the render value. Used to sort differently than the shown value
				if (column.renderValue) {
					this.integratedSortByColumnExtensions.push(column.name);
				}
			}

			if (column.filterable !== false) {
				this.integratedFilterColumnExtensions.push({
					columnName: column.name,
					predicate: (value: any, filter: any, row: any) => {
						return column.filterFunction
							? column.filterFunction(value, filter, row)
							: value
							? value.toString().toLowerCase().includes(filter.value.toString().toLowerCase())
							: false;
					},
				});
			}
		});

		// Used to handle dynamic and adjusted columns
		let extensionIds = this.props.ColumnExtensions ? this.props.ColumnExtensions.map(ex => ex.columnName) : [];

		let maxWidthForRow = this.props.useSelection ? 100 : 0;
		if (
			this.props.ColumnExtensions &&
			(!this.props.resizeAbleEnabled ||
				(this.props.tableKey && !this.props.tableSettings[this.props.tableKey]) ||
				!this.props.tableKey)
		) {
			this.props.ColumnExtensions.forEach((column, index) => {
				if (column.width) {
					maxWidthForRow = maxWidthForRow + column.width;
				}
				this.fixedColumnsLeft[column.columnName] = leftIndex;

				leftIndex = leftIndex + column.width;
				if (column.isFixedRight) {
					rightIndex = rightIndex + column.width;
					this.fixedColumnsRight[column.columnName] = rightIndex;
				}
			});
		} else if (this.props.tableKey && this.props.tableSettings[this.props.tableKey]) {
			this.props.tableSettings[this.props.tableKey].forEach((column, index) => {
				if (!extensionIds.includes(column.columnName)) {
					return;
				}

				if (column.width) {
					maxWidthForRow = maxWidthForRow + Number(column.width);
				}
				this.fixedColumnsLeft[column.columnName] = leftIndex;

				leftIndex = leftIndex + Number(column.width);
				if (this.fixedRightColumns.find(c => c === column.columnName)) {
					rightIndex = rightIndex + Number(column.width);
					this.fixedColumnsRight[column.columnName] = rightIndex;
				}
			});
		}
		if (this.props.groupedColumns) {
			this.props.groupedColumns.forEach(groupedHeader => {
				groupedHeader.children.forEach(groupedHeaderChild => {
					if (groupedHeaderChild.columnName) {
						this.AllGroupedHeadersNames.push(groupedHeaderChild.columnName);
					}
				});
			});
		}
		for (let key in this.fixedColumnsRight) {
			// check if the property/key is defined in the object itself, not in parent
			if (this.fixedColumnsRight.hasOwnProperty(key)) {
				this.fixedColumnsRight[key] = rightIndex - this.fixedColumnsRight[key];
			}
		}

		// If not default width
		if (maxWidthForRow > 0) {
			// One extra pixel is necessary to avoid scrollbar
			maxWidthForRow++;
		}

		this.state = {
			selection: [],
			maxWidthForRow,
			tableSettings: props.tableSettings,
			ColumnExtensions: this.props.ColumnExtensions,
		};
	}

	public setSowsCount = (rowCount: number) => {
		if (this.currentData.length !== rowCount) {
			this.props.setSowsCount(rowCount);
		}
	};

	public getFilterJSXElement(props: any) {
		if (props.column.filter) {
			return <td>{props.column.filter()} </td>;
		} else {
			return (
				<TableFilterRow.Cell
					{...props}
					style={
						props.tableColumn.fixed === 'left'
							? {
									position: 'sticky',
									left: this.fixedColumnsLeft[props.column.name],
									zIndex: 300,
							  }
							: props.tableColumn.fixed === 'right'
							? {
									position: 'sticky',
									right: this.fixedColumnsRight[props.column.name],
									zIndex: 300,
							  }
							: {}
					}
					className={'filter-grid'}
				/>
			);
		}
	}

	public GetSortedData() {
		return this.currentData;
	}

	private getRowIndexes = (props: any) => {
		return new Map(props.rows.map((row, index) => [row, index]));
	};

	private getCellValue = (props: any) => (row, columnName) => {
		return this.props.useRowIndex
			? this.getRealCellValue({ row, index: props.rowIndexes.get(row) }, columnName)
			: this.getRealCellValue(row, columnName);
	};

	private getRealCellValue = (row: any, columnName: string) => {
		let column = this.props.columns.find(col => col.name === columnName);
		if (column) {
			if (column.getCellValue) {
				return column.getCellValue(row);
			} else {
				return row[columnName];
			}
		}
	};

	private RowIndexer = () => {
		return (
			<Plugin>
				<Getter name="rowIndexes" computed={this.getRowIndexes} />
				<Getter name="getCellValue" computed={this.getCellValue} />
			</Plugin>
		);
	};

	public changeFilter = e => {
		this.didFilterChange = true;
	};

	private setTableRef = (m: any) => {
		if (m) {
			this.virtualTableRef = m;
		}
	};

	private setStyle = (isInModal?: boolean) => {
		const viewportWidth = Math.min(document.documentElement.clientWidth, window.innerWidth || 0);
		const maxWidth = viewportWidth - 30;
		return this.state.maxWidthForRow !== 0 && !isInModal
			? ({
					maxWidth: this.state.maxWidthForRow > maxWidth ? maxWidth : this.state.maxWidthForRow,
					overflow: 'hidden',
					width: this.state.maxWidthForRow,
			  } as any)
			: {};
	};

	private generateRootComponent = (props: any) => {
		return (
			<Grid.Root
				{...props}
				className={`${this.GridRootClassName} ${this.props.groupedColumns ? 'double-header' : ''}`}
			/>
		);
	};

	// Format renderValue instead column.name. Used to sort differently than the shown value
	private SortByFormatter = (value: any) => {
		return value.row[value.column.renderValue];
	};
	// The table Provider. Used to sort differently than the shown value
	private SortByTypeProvider = props => <DataTypeProvider formatterComponent={this.SortByFormatter} {...props} />;

	public render() {
		let summaryItems: any[] = [];
		if (this.props.usesSummaryRow && this.props.ColumnExtensions) {
			this.props.ColumnExtensions.forEach(a => {
				if (a.usesSummary) {
					summaryItems.push({
						columnName: a.columnName,
						type: a.allowZero
							? 'allowZero avg'
							: a.summaryMethod
							? 'isSummaryMethod_' + a.columnName
							: 'avg',
					});
				}
			});
		}

		return (
			<div className={this.props.containerClassName} style={this.setStyle(this.props.IsInModal)}>
				<Paper className={`skiold-table-paper-style ${this.props.className} `}>
					<Grid
						rows={this.props.data}
						columns={this.props.columns}
						rootComponent={this.generateRootComponent}
					>
						{/* It is important that RowIndexer is on top of the component hierarchy, because getCellValue is overridden */}
						{this.props.useRowIndex && <this.RowIndexer />}
						<FilteringState
							onFiltersChange={this.changeFilter}
							defaultFilters={[]}
							columnExtensions={this.props.ColumnExtensions}
						/>
						<this.SortByTypeProvider for={this.integratedSortByColumnExtensions} />
						<IntegratedFiltering columnExtensions={this.integratedFilterColumnExtensions} />

						<SelectionState selection={this.state.selection} onSelectionChange={this.OnSelectionChange} />
						<SortingState
							// onSortingChange={this.handleSortingChanged}
							defaultSorting={this.props.sortHeaderId!}
						/>
						<IntegratedSorting columnExtensions={this.integratedSortingColumnExtensions} />
						<IntegratedSelection />
						{this.props.usesSummaryRow && <SummaryState totalItems={summaryItems} />}
						{this.props.usesSummaryRow && <IntegratedSummary calculator={this.SummaryCalculator} />}
						{
							// @ts-ignore
							<VirtualTable
								height="auto"
								containerComponent={this.ContainerComponent}
								cellComponent={this.VirtualTableCellComponent}
								rowComponent={this.VirtualTableRowComponent}
								columnExtensions={this.props.ColumnExtensions}
								ref={this.setTableRef as any}
								estimatedRowHeight={this.props.estimatedRowHeight}
							/>
						}

						{this.props.useSelection && (
							<TableSelection
								selectionColumnWidth={100}
								showSelectAll={true}
								headerCellComponent={({
									allSelected,
									tableRow,
									tableColumn,
									someSelected,
									...props
								}) => {
									return (
										<th
											{...props}
											className={
												` ${
													this.props.headerCapitalized ? 'all-capitalized' : ''
												} header-grid ${(props as any).className}` +
												this.getLineHeightClassName(props.rowSpan)
											}
											// style={this.applyColumnStyle(props)}
										>
											{this.props.selectionHeader}
										</th>
									);
								}}
								cellComponent={(props: any) => {
									return (
										<td className={props.className} style={props.style}>
											<SkioldCheckbox isChecked={props.selected} onClick={props.onToggle} />
										</td>
									);
								}}
							/>
						)}

						{this.props.filterable && (
							<TableFilterRow
								cellComponent={this.TableFilterRowCellComponent}
								rowComponent={this.TableFilterRowRowComponent}
							/>
						)}

						{this.props.tableKey &&
							this.props.columns &&
							this.props.resizeAbleEnabled &&
							this.props.ColumnExtensions &&
							this.props.columns.length === this.props.ColumnExtensions.length && (
								<TableColumnResizing
									minColumnWidth={SowListConstants.iconWidth}
									maxColumnWidth={400}
									onColumnWidthsChange={this.changeColumnWidths}
									columnWidths={
										this.props.tableKey && this.props.tableSettings[this.props.tableKey]
											? validateTableResizing(
													this.props.tableSettings[this.props.tableKey],
													this.props.ColumnExtensions,
											  )
											: this.props.ColumnExtensions
									}
								/>
							)}

						{this.props.usesSummaryRow && (
							<TableSummaryRow totalCellComponent={this.footerTableCellComponent} />
						)}
						<TableHeaderRow
							showSortingControls={true}
							sortLabelComponent={this.SortLabelComponent}
							cellComponent={this.TableHeaderRowCellComponent}
						/>

						{this.props.groupedColumns && (
							<TableBandHeader
								columnBands={this.props.groupedColumns}
								cellComponent={this.TableBandHeaderCellComponent}
							/>
						)}
						<TableFixedColumns
							leftColumns={this.fixedLeftColumns}
							rightColumns={this.fixedRightColumns}
							cellComponent={this.TableFixedColumnsCellComponent}
						/>
						{this.getFilteredRowCount}
						{this.props.footerRows && this.getFooterRows}
					</Grid>
				</Paper>
			</div>
		);
	}

	private SummaryCalculator = (type, rows, getValue) => {
		if (rows) {
			let rowsCopy = [...rows];
			let customType = type.split('_');

			if (customType[0] === 'isSummaryMethod') {
				if (this.props.ColumnExtensions) {
					let summaryMethod = this.props.ColumnExtensions.find(
						ex => ex.summaryMethod && ex.columnName === customType[1],
					);
					if (summaryMethod) {
						return summaryMethod.summaryMethod(rows);
					}
				}
			} else if (type === 'avg') {
				rowsCopy = rowsCopy.filter(
					row => getValue(row) !== undefined && getValue(row) !== null && getValue(row) >= 0,
				);
			}
			return IntegratedSummary.defaultCalculator('avg', rowsCopy, getValue);
		}
		return IntegratedSummary.defaultCalculator('avg', rows, getValue);
	};

	private getFilteredRowCount = (
		<Template name="header">
			<TemplateConnector>
				{({ rows: filteredRows }) => {
					if (!this.props.ignoreSetCount && this.currentData.length !== filteredRows.length) {
						this.setSowsCount(filteredRows.length);
					}
					this.currentData = filteredRows;

					if (this.didFilterChange) {
						if (this.props.onFiltersChanged) {
							this.props.onFiltersChanged(this.currentData);
						}
						this.didFilterChange = false;
					}
					return <TemplatePlaceholder />;
				}}
			</TemplateConnector>
		</Template>
	);
	private getFooterRows = (
		<Template name="footer">
			<TemplateConnector>
				{({ tableColumns, rows }) => {
					let indexCounter = 0;
					const cssProps: React.CSSProperties[] = [];
					tableColumns.forEach((column, index) => {
						if (index === indexCounter) {
							if (column.column && column.column.span) {
								indexCounter += column.column.span;
							} else {
								indexCounter++;
							}
							if (column.width !== undefined) {
								cssProps.push({ width: column.width });
							}
						} else if (column.width) {
							cssProps[cssProps.length - 1] = {
								width: cssProps[cssProps.length - 1].width + column.width,
							};
						}
					});
					let rowCount: number = rows.length;
					return (
						<div>
							{this.props.footerRows &&
								this.props.footerRows.map((footerRow, footerRowIndex) => {
									rowCount += 1;
									return (
										<div
											key={footerRowIndex}
											className={`${footerRow.className} flexDirectionRow footerRowStyle ${
												isOdd(rowCount) ? ' odd' : ''
											}`}
										>
											{cssProps.map((w: React.CSSProperties, widthIndex: number) => (
												<div key={widthIndex} className={'footerRowColumn'} style={w}>
													{footerRow.items[widthIndex]}
												</div>
											))}
										</div>
									);
								})}
						</div>
					);
				}}
			</TemplateConnector>
		</Template>
	);

	private changeColumnWidths = (columnWidths: TableColumnWidthInfo[]) => {
		if (this.props.tableKey) {
			this.props.setSize(this.props.tableKey, columnWidths as Array<TableColumnWidthInfoGeneric<string>>);
		}
	};
	private handleClassName = (tableRow: any, tableColumn: any) => {
		if (tableColumn.column && tableColumn.column.className) {
			if ('string' === typeof tableColumn.column.className) {
				return tableColumn.column.className;
			} else if (tableRow.row) {
				return tableColumn.column.className(tableRow.row);
			}
		}
		return '';
	};

	//non inline functions
	private VirtualTableRowComponent = (props: any) => {
		let className = '';
		if (
			this.props.rowStylingIndicator &&
			props.tableRow.rowId >= this.props.data.length - this.props.rowStylingIndicator.fromBottom
		) {
			className += this.props.rowStyling + ' ';
		}
		if (this.props.rowStylingIndicator && props.tableRow.rowId + 1 <= this.props.rowStylingIndicator.fromTop) {
			className += this.props.rowStyling + ' ';
		}
		if (
			this.props.InlineHeaders &&
			props.row &&
			props.row.name &&
			this.props.InlineHeaders.includes(props.row.name)
		) {
			className += 'inlineHeader ';
		}
		return <VirtualTable.Row {...props} className={className} />;
	};
	private VirtualTableCellComponent = (props: any) => {
		const onCellColumnClicked = () => this.onColumnClicked(props);
		return (
			<VirtualTable.Cell
				className={`${this.handleClassName(props.tableRow, props.tableColumn)} ${
					this.props.onClickedRow && !props.column.rowSelectedDisabledForColumn ? 'pointer' : ''
				} ${props.className} `}
				onClick={onCellColumnClicked}
				{...props}
				style={this.applyColumnStyle(props)}
			/>
		);
	};
	private onColumnClicked = (props: any) => {
		return this.props.onClickedRow && !props.column.rowSelectedDisabledForColumn
			? this.props.onClickedRow(props.row)
			: {};
	};

	private footerTableCellComponent = props => {
		let value: string | number | undefined;
		if ((props.children.props.columnSummaries as any[]).length > 0) {
			value = (props.children.props.columnSummaries as any[])[0].value;
		}

		return (
			<TableSummaryRow.TotalCell className="noPadding" {...props}>
				<ViewWeb className={'footerTextStyle'}>
					{(props.column as any).Footer
						? props.column.Footer
						: value && value !== 'NaN' && value !== 0 && value.toString() !== 'Infinity'
						? Math.round(Number(value) * 10) / 10
						: ''}
				</ViewWeb>
			</TableSummaryRow.TotalCell>
		);
	};
	private TableFilterRowCellComponent = (props: any) => this.getFilterJSXElement(props);
	private TableFilterRowRowComponent = (props: any) => <TableFilterRow.Row {...props} />;
	private TableFixedColumnsCellComponent = (props: any) => {
		return (
			<TableFixedColumns.Cell
				{...props}
				className={`${this.handleClassName(props.tableRow, props.tableColumn)} table-fixed-columns-style`}
			/>
		);
	};

	private TableHeaderRowCellComponent = (props: any) => {
		if (!props.column.header) {
			return (
				<TableHeaderRow.Cell
					{...props}
					className={
						` ${this.props.headerCapitalized ? 'all-capitalized' : ''} header-grid ${props.className}` +
						this.getLineHeightClassName(props.rowSpan)
					}
					style={this.applyColumnStyle(props)}
				/>
			);
		} else {
			return (
				<TableHeaderRow.Cell
					{...props}
					className={
						` ${this.props.headerCapitalized ? 'all-capitalized' : ''} header-grid ${props.className}` +
						this.getLineHeightClassName(props.rowSpan)
					}
					style={this.applyColumnStyle(props)}
				>
					{props.column.header}
				</TableHeaderRow.Cell>
			);
		}
	};

	private getLineHeightClassName(rowSpan?: number) {
		if (rowSpan && rowSpan > 1) {
			return ' headerFixedHeight' + rowSpan * this.props.lineHeightValue!;
		}
		return '';
	}
	private TableBandHeaderCellComponent = (props: any) => {
		let propsCopy = { ...props };
		const groupedColumn =
			this.props.groupedColumns && this.props.groupedColumns.find(a => a.title === props.children);

		if (groupedColumn) {
			if (groupedColumn.colSpan) {
				propsCopy.colSpan = groupedColumn.colSpan;

				propsCopy.style = { right: 0 }; // ToDo introduce handling if there is more title columns that spans multiple columns
			}
			if (groupedColumn.onClick) {
				propsCopy.onClick = groupedColumn.onClick;
				propsCopy.className = propsCopy.className + ' pointerCursor';
			}
			if (groupedColumn.className) {
				propsCopy.className = propsCopy.className + ' ' + groupedColumn.className;
			}
		}

		return (
			<TableBandHeader.Cell
				{...propsCopy}
				className={
					propsCopy.className + ` ${this.props.headerCapitalized ? 'all-capitalized' : ''} header-grid`
				}
			>
				{groupedColumn && groupedColumn.headerContent}
				{propsCopy.children}
			</TableBandHeader.Cell>
		);
	};

	private OnSelectionChange = (selection: any) => this.changeSelection(selection);
	private ContainerComponent = (props: any) => (
		<VirtualTable.Container {...props} className="react-table-container" />
	);

	private SortLabelComponent = (props: any) => this.SortLabel(props);

	private hasFixedColumns() {
		if (this.GridRootClassName === 'skiold-table-grid') {
			this.GridRootClassName = this.GridRootClassName + ' hasFixedColumns';
		}
	}

	private changeSelection(selection: React.ReactText[]) {
		this.setState({ selection });
		if (this.props.selectedChanged) {
			this.props.selectedChanged(selection.map(index => this.props.data[index]));
		}
	}

	private applyColumnStyle(props: any): React.CSSProperties | undefined {
		return props.tableColumn.fixed === 'left'
			? {
					left: this.fixedColumnsLeft[props.column.name],
			  }
			: props.tableColumn.fixed === 'right'
			? {
					right: this.fixedColumnsRight[props.column.name],
			  }
			: props.column.shouldOverflow
			? { overflow: 'visible' }
			: {};
	}

	private SortLabel(props: any) {
		if (this.props.sortable) {
			let className = props.direction
				? props.direction === 'asc'
					? 'sortlabel header-font asc'
					: 'sortlabel header-font dsc'
				: 'sortlabel header-font';
			className = className + (props.column.headerClassName ? ' ' + props.column.headerClassName : '');
			if (this.AllGroupedHeadersNames.length !== 0 && !this.AllGroupedHeadersNames.includes(props.column.name)) {
				className = className + ' sortLabelHeight';
			}
			return (
				<Button disabled={!this.props.sortable} className={className} onClick={props.onSort}>
					{props.children}
				</Button>
			);
		} else {
			let className = 'header-font text-header';
			return <ViewWeb className={className}>{props.children}</ViewWeb>;
		}
	}
}
export default connect<ReturnType<typeof mapStateToProps>, ReturnType<typeof mapDispatchToProps>, RefType, WebAppState>(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{ forwardRef: true },
)(SkioldTableGrid);
