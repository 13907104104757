import React, { CSSProperties } from 'react';
import Draggable from 'react-draggable';
import ReactModal, { Styles } from 'react-modal';
import { Colors } from 'shared/assets/root-assets/colors';
import styled from 'styled-components';
import './skiold-modal.scss';
type Props = {
	overflow: string;
	backgroundImage: string;
};

//Inspiration for draggable functionality found here: https://codesandbox.io/s/30j8xpy181
const ActualContent = styled.div<Props>`
	border-radius: 4px;
	border: 1px solid ${Colors.lighterGrey};
	pointer-events: all;
	background-image: url(/${props => (props.backgroundImage !== 'undefined' ? props.backgroundImage : 'baggrunds-grafik.jpg')});
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	overflow: ${props => (props.overflow !== 'undefined' ? props.overflow : 'auto')};

	.handle {
		height: 25px;
		background: ${Colors.lightestGrey};
		cursor: move;
		width: 100%;
		border-radius: 4px 4px, 0, 0; /* Round upper corners */
	}
`;

export interface PropsFromParent {
	isOpen: boolean;
	padding?: string;
	close: (anyData?: any) => void;
	shouldCloseOnOverlayClick?: boolean;
	justifyContent?: string;
	maxWidth?: string;
	minHeight?: string;
	className?: string;
	showOverlay?: boolean;
	minWidth?: string;
	overflowNone?: boolean;
	backgroundImage?: string;
	children?: React.ReactNode;
}
export class SkioldModal extends React.PureComponent<PropsFromParent> {
	public static defaultProps: Partial<PropsFromParent> = {
		shouldCloseOnOverlayClick: false,
		maxWidth: 'calc(100% - 40px)',
		justifyContent: 'center',
		className: '',
		showOverlay: true,
		minWidth: '800px',
	};

	private styleModalOverrides = {
		overlay: {
			display: 'flex',
			justifyContent: this.props.justifyContent,
			alignItems: 'center',
			zIndex: 2000,
			backgroundColor: this.props.showOverlay ? 'rgba(255, 255, 255, 0.75)' : 'none',
		},
		content: {
			bottom: 'unset',
			overflow: 'visible',
			padding: 0,
			border: 'none',
			borderRadius: 0,
			position: 'static',
			background: 'none',
			pointerEvents: 'none',
			maxWidth: this.props.maxWidth,
			minWidth: this.props.minWidth,
		},
	} as Styles;

	private styles = {
		childrenContainerDivStyle: {
			padding: this.props.padding ? this.props.padding : 10,
			paddingTop: 0,
			maxHeight: 'calc(100vh - 80px)',
			minHeight: this.props.minHeight,
		},
		handleDivStyle: {
			borderBottom: this.props.padding ? 0 : 5,
			borderBottomStyle: 'solid',
			borderBottomColor: '#fff',
		} as CSSProperties,
	};

	private onCloseClicked = () => {
		this.props.close();
	};

	public render() {
		return (
			<ReactModal
				isOpen={this.props.isOpen}
				shouldCloseOnOverlayClick={this.props.shouldCloseOnOverlayClick}
				onRequestClose={this.onCloseClicked}
				style={this.styleModalOverrides}
				className={this.props.className}
			>
				<Draggable handle=".handle" bounds="body">
					<ActualContent
						overflow={this.props.overflowNone ? 'none' : 'auto'}
						backgroundImage={this.props.backgroundImage ?? 'undefined'}
					>
						<React.Fragment>
							<div style={this.styles.handleDivStyle} className="handle" />
							<div style={this.styles.childrenContainerDivStyle}>{this.props.children}</div>
						</React.Fragment>
					</ActualContent>
				</Draggable>
			</ReactModal>
		);
	}
}
