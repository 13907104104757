import { NucleusManagementConnectionsState } from './types';
import { createReducer } from '@reduxjs/toolkit';
import {
	createNucleusManagementConnection,
	deleteNucleusManagementConnection,
	getConnections,
	updateNucleusManagementConnection,
	updateNucleusManagementFetchSettings,
	updateNucleusManagementSendSettings,
} from './operation';

const initialState: NucleusManagementConnectionsState = {
	connections: [],
	loading: false,
};

const nucleusManagementConnectionsReducer = createReducer(initialState, builder => {
	builder.addCase(getConnections.fulfilled, (state, action) => {
		state.connections = action.payload ?? [];
		state.loading = false;
	});

	builder.addCase(getConnections.pending, state => {
		state.loading = true;
	});

	builder.addCase(getConnections.rejected, state => {
		state.loading = false;
	});

	builder.addCase(createNucleusManagementConnection.fulfilled, (state, action) => {
		if (action.payload === undefined || action.payload === null) {
			return;
		}

		state.connections.push(action.payload);
	});

	builder.addCase(updateNucleusManagementConnection.fulfilled, (state, action) => {
		updateResult(state, action);
	});
	builder.addCase(updateNucleusManagementFetchSettings.fulfilled, (state, action) => {
		updateResult(state, action);
	});

	builder.addCase(updateNucleusManagementSendSettings.fulfilled, (state, action) => {
		updateResult(state, action);
	});

	builder.addCase(deleteNucleusManagementConnection.fulfilled, (state, action) => {
		const index = state.connections.findIndex(c => c.id === action.meta.arg);
		if (index > -1) {
			state.connections.splice(index, 1);
		}
	});
});

const updateResult = (state, action) => {
	const result = action.payload;
	if (result === undefined) {
		return;
	}

	const index = state.connections.findIndex(c => c.id === result?.id);
	if (index > -1) {
		state.connections[index] = result;
	}
};

export default nucleusManagementConnectionsReducer;
