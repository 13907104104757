import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ITotalWeaningTableItem, TotalWeaningTableItem } from 'shared/api/api';
import sokort_icon from 'shared/assets/src-assets/png/sokort_icon.png';
import { getDateString } from 'shared/helpers/date-helpers';
import { NaturalSortDates } from 'shared/helpers/natural-sort';
import { GetDashboardGetTotalWeaningTable } from 'shared/state/ducks/dashboard/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import SkioldTableGrid, {
	SkioldTableGrid as SkioldTableRef,
} from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { ViewWeb } from 'web/view/components/utils/web-view';
import SowCard from 'web/view/pages/sow-card/sow-card';
import { GetTotalWeaningTableDashboardPdf } from 'web/web-helpers/pdf-helper/dashboard-pdf-helper.ts/dashboard-pdf-helper';
import { SkioldModal } from '../../skiold-components/skiold-modal/skiold-modal';
import { SkioldTouchableOpacity } from '../../skiold-components/skiold-touchable-opacity';
import { SowListConstants } from '../../stem-animal/animal-lists/table-constants';
import { Heading } from '../../utils/heading';
import { SkioldImage } from '../../utils/svg/skiold-image';
import { defaultSortingDashboardWeaningModals, getWebAnimalColorStyleDashboard } from '../dashboard-helper';
import { DashboardModalHeader } from '../dashboard-modal-header';
import { ClipLoader } from 'react-spinners';
import { nursingDaysSummary } from '../../analysis/analysis-helper';

interface PropsFromParent {
	batchId?: string;
	count?: string;
	closeModal: () => void;
}

interface State {
	sowCardModal: boolean;
	stemAnimalId: string;
	count?: number;
	data: ITotalWeaningTableItem[];
	loading: boolean;
}

const mapStateToProps = (state: WebAppState, props: PropsFromParent) => {
	return {
		matingBatch: state.matingBatches.entities.find(mb => mb.id === props.batchId),
		profile: state.profile.active,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
	return {
		GetDashboardGetTotalWeaningTable: (siteId: string, batchId: string) =>
			GetDashboardGetTotalWeaningTable(siteId, batchId),
	};
};

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

export class TotalWeaningsDashboardTable extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = { sowCardModal: false, stemAnimalId: '', data: [], loading: true, count: Number(props.count) };
	}

	public componentDidMount = () => {
		if (this.props.profile && this.props.profile.siteId && this.props.batchId) {
			this.props
				.GetDashboardGetTotalWeaningTable(this.props.profile.siteId, this.props.batchId)
				.then(d => this.setState({ data: d, loading: false }));
		}
	};
	public render() {
		return (
			<ViewWeb>
				<Heading text={localized('TotalWeaningsTable')} />
				<DashboardModalHeader
					count={this.state.count}
					closeModal={this.props.closeModal}
					matingBatch={this.props.matingBatch}
					printData={this.printData}
				/>

				{this.state.loading ? (
					<ViewWeb className="spinner-container-active-dashboard">
						<ClipLoader color="#f2ac40" size={70} />
					</ViewWeb>
				) : (
					<ViewWeb className="dashboard-modal-table">
						<SkioldTableGrid
							sortHeaderId={defaultSortingDashboardWeaningModals}
							data={this.state.data}
							ref={this.setTableRef}
							columns={this.generateColumns}
							onFiltersChanged={this.onFilterChanged}
							usesSummaryRow={true}
							ColumnExtensions={this.getColumnExtenstions}
							tableKey={'TotalWeaningsDashboardTable'}
						/>
						<SkioldModal
							shouldCloseOnOverlayClick={true}
							padding="0"
							isOpen={this.state.sowCardModal}
							close={this.closeModal}
							justify-content="flex-end"
							max-width="calc(100% - 220px)"
						>
							<SowCard stemAnimalIdFromParent={this.state.stemAnimalId} close={this.closeModal} />
						</SkioldModal>
					</ViewWeb>
				)}
			</ViewWeb>
		);
	}

	private onFilterChanged = (data: ITotalWeaningTableItem[]) => {
		const stemAnimalIds: string[] = [];
		data.forEach(totalWeaningTableItem => {
			if (
				totalWeaningTableItem.sowId &&
				stemAnimalIds.find(stemAnimalId => stemAnimalId === totalWeaningTableItem.sowId) === undefined
			) {
				stemAnimalIds.push(totalWeaningTableItem.sowId);
			}
		});
		if (data.length !== this.state.count) {
			this.setState({ count: stemAnimalIds.length });
		}
	};

	private printData = async () => {
		if (this.SkioldTableRef && this.props.profile && this.props.profile.siteId) {
			let data = this.SkioldTableRef.GetSortedData();
			GetTotalWeaningTableDashboardPdf(
				data,
				'TotalWeanings.pdf',
				this.props.profile.siteId,
				Intl.DateTimeFormat().resolvedOptions().timeZone,
				this.props.profile.language,
			);
		}
	};
	public SkioldTableRef: SkioldTableRef | undefined;
	private setTableRef = (m: any) => (m ? (this.SkioldTableRef = m) : {});
	private weightSummary = (items: TotalWeaningTableItem[]) => {
		let totalWeight = 0;
		let totalPigs = 0;
		items.forEach(item => {
			if (item.weightPerPiglet && item.weanedPerLitter) {
				totalWeight += item.weightPerPiglet * item.weanedPerLitter;
				totalPigs += item.weanedPerLitter;
			}
		});
		return totalWeight && totalPigs ? totalWeight / totalPigs : undefined;
	};

	private getColumnExtenstions = [
		{
			columnName: 'sowCard',
			filteringEnabled: false,
			width: SowListConstants.iconWidth,
		},
		{
			columnName: 'animalNr',
			width: SowListConstants.animalNrWidth,
		},
		{ columnName: 'breedRace', width: SowListConstants.animalNrWidth },
		{
			columnName: 'litter',
			width: SowListConstants.iDNumberWidth,
		},
		{
			columnName: 'nursingDays',
			usesSummary: true,
			width: SowListConstants.iDNumberWidth,
			summaryMethod: nursingDaysSummary,
		},
		{
			columnName: 'weaningDate',
			width: SowListConstants.iDNumberWidth,
		},
		{
			columnName: 'weanedPerLitter',
			width: SowListConstants.iDNumberWidth,
		},
		{
			columnName: 'weightPerPiglet',
			usesSummary: true,
			summaryMethod: this.weightSummary,

			width: SowListConstants.iDNumberWidth,
		},
		{
			columnName: 'nursingSow',
			width: SowListConstants.iDNumberWidth,
		},
	];

	private showSowCard = (stemAnimalId?: string) => {
		if (stemAnimalId) {
			this.setState({ sowCardModal: true, stemAnimalId: stemAnimalId });
		}
	};

	private closeModal = () => {
		this.setState({ stemAnimalId: '', sowCardModal: false });
	};

	private generateColumns = [
		{
			name: 'sowCard',
			title: ' ',
			sortable: false,
			Footer: localized('avg'),
			filterable: false,
			getCellValue: (item: TotalWeaningTableItem) => (
				<SkioldTouchableOpacity itemFromParent={item.sowId} onPress={this.showSowCard}>
					<SkioldImage
						width={SowListConstants.iconSVGWidth}
						height={SowListConstants.iconSVGWidth}
						imageData={sokort_icon}
					/>
				</SkioldTouchableOpacity>
			),
		},
		{
			name: 'animalNr',
			title: localized('animalNr'),
			className: getWebAnimalColorStyleDashboard,
			getCellValue: (item: TotalWeaningTableItem) => item.animalNumber,
		},
		{
			name: 'breedRace',
			title: localized('Race'),
		},
		{
			name: 'litter',
			title: localized('Litters'),
			getCellValue: (item: TotalWeaningTableItem) => item.litterNumber,
		},
		{
			name: 'nursingDays',
			title: localized('NursingDays'),
			getCellValue: (item: TotalWeaningTableItem) => item.nursingDays,
		},
		{
			name: 'weaningDate',
			title: <div className={'alignDivsCenter'}>{localized('weaningDateDashboard')}</div>,
			sortFunction: NaturalSortDates,
			getCellValue: (item: TotalWeaningTableItem) => getDateString(item.weaningDate),
		},
		{
			name: 'weanedPerLitter',
			title: localized('Pcs'),
			getCellValue: (item: TotalWeaningTableItem) => item.weanedPerLitter,
		},
		{
			name: 'weightPerPiglet',
			title: <div className={'alignDivsCenter'}>{localized('weightPerPigDashboard')}</div>,

			getCellValue: (item: TotalWeaningTableItem) => (item.weightPerPiglet ? item.weightPerPiglet : undefined),
		},
		{
			name: 'nursingSow',
			title: localized('nursingSow'),
			getCellValue: (item: TotalWeaningTableItem) => (item.isNursingSow ? localized('True') : localized('False')),
		},
	];
}

export default connect(mapStateToProps, mapDispatchToProps)(TotalWeaningsDashboardTable);
