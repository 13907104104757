import {
	ChangeSet,
	Column,
	ColumnBands,
	EditingState,
	FilteringState,
	IntegratedFiltering,
	IntegratedSelection,
	IntegratedSorting,
	SelectionState,
	Sorting,
	SortingState,
	TableColumnWidthInfo,
} from '@devexpress/dx-react-grid';
import {
	Grid,
	Table,
	TableHeaderRow,
	TableInlineCellEditing,
	TableSelection,
	TableColumnResizing,
	VirtualTable,
	TableFilterRow,
	TableBandHeader,
} from '@devexpress/dx-react-grid-material-ui';
import FormControlLabel from '@mui/material/FormControlLabel';
import GridMUI from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import React, { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { validateTableResizing } from 'shared/helpers/general-helpers';
import { setSize } from 'shared/state/ducks/table-settings/operations';
import { TableColumnWidthInfoGeneric } from 'shared/state/ducks/table-settings/types';
import { WebAppState } from 'web/state/store.web';
import { SowListConstants } from 'web/view/components/stem-animal/animal-lists/table-constants';
import { SkioldCheckbox } from '../../skiold-checkbox/skiold-checkbox';
import { ColumnExtended, ColumnExtension } from './simple-skiold-table';
import './simple-skiold-table.scss';
import Button from '@material-ui/core/Button';
const PREFIX = 'Demo';
const classes = {
	input: `${PREFIX}-input`,
	label: `${PREFIX}-label`,
	container: `${PREFIX}-container`,
	selector: `${PREFIX}-selector`,
};
const StyledGridMUI = styled(GridMUI)(() => ({
	[`&.${classes.container}`]: {
		maxWidth: '18em',
	},
	[`& .${classes.input}`]: {
		fontSize: '14px',
		width: '90px',
	},
	[`& .${classes.label}`]: {
		fontSize: '14px',
	},
	[`& .${classes.selector}`]: {
		height: '32px',
	},
}));
const StyledFormControlLabel = styled(FormControlLabel)(() => ({
	[`&.${classes.label}`]: {
		fontSize: '14px',
	},
}));
interface PropsFromParent {
	columns: ReadonlyArray<ColumnExtended>;

	getRowId: (row: any) => any;
	rows: any[];
	setRows: (data: any[]) => void;
	columnExtensions: ColumnExtension[];
	selected?: Array<number | string>;
	tableKey?: string;
	columnBands?: Array<ColumnBands>;
}

const FocusableCell = ({ onClick, ...restProps }: any) => (
	<VirtualTable.Cell {...restProps} tabIndex={0} onFocus={onClick} />
);

const TableInlineCell = (props: any) => {
	return <TableInlineCellEditing.Cell {...props} />;
};

const generateRootComponent = (props: any) => {
	return <Grid.Root {...props} className={`skiold-table-grid`} />;
};
const ContainerComponent = (props: any) => <VirtualTable.Container {...props} className="react-table-container" />;

const VirtualTableRowComponent = (props: any) => {
	return <VirtualTable.Row {...props} />;
};

const getFilterJSXElement = (props: any) => {
	if (props.column.filter) {
		return <td>{props.column.filter()} </td>;
	} else {
		return <TableFilterRow.Cell {...props} className={'filter-grid'} />;
	}
};

export const SimpelEditSkioldTable: FC<PropsFromParent> = React.memo(
	({ columns, getRowId, setRows, rows, columnExtensions, selected, tableKey, columnBands }) => {
		const [selection, setSelection] = useState<Array<number | string>>([]);
		const dispatch = useDispatch();
		const tableSettings = useSelector((state: WebAppState) => state.tableSettings.columnWidthsHashMap);
		useEffect(() => {
			if (selected) {
				onSelect(selected);
			}
			//eslint-disable-next-line
		}, []);

		const onSelect = (newSelected: Array<string | number>) => {
			setRows(
				rows.map(row => {
					return { ...row, isChecked: newSelected.includes(row.id) };
				}),
			);
			setSelection(newSelected);
		};

		const commitChanges = (changeSet: ChangeSet) => {
			let changedRows;
			if (changeSet.added) {
				const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
				changedRows = [
					...rows,
					...changeSet.added.map((row, index) => ({
						id: startingAddedId + index,
						...row,
					})),
				];
			}

			if (changeSet.changed) {
				changedRows = rows.map(row =>
					row.id && changeSet && changeSet.changed && changeSet.changed[row.id]
						? { ...row, ...changeSet.changed[row.id] }
						: row,
				);
			}
			if (changeSet.deleted) {
				const deletedSet = new Set(changeSet.deleted);
				changedRows = rows.filter(row => row.id && !deletedSet.has(row.id));
			}
			setRows(changedRows);
		};
		const changeColumnWidths = (columnWidths: TableColumnWidthInfo[]) => {
			if (tableKey) {
				dispatch(setSize(tableKey, columnWidths as Array<TableColumnWidthInfoGeneric<string>>));
			}
		};

		const setStyle = () => {
			const columnExtension =
				tableKey && tableSettings[tableKey]
					? validateTableResizing(tableSettings[tableKey], columnExtensions as TableColumnWidthInfo[])
					: (columnExtensions as TableColumnWidthInfo[]);
			let width = 78;
			columnExtension.forEach(element => {
				width += +element.width;
			});

			return { width };
		};

		const SortLabel = (props: any) => {
			let className = props.direction
				? props.direction === 'asc'
					? 'sortlabel header-font asc'
					: 'sortlabel header-font dsc'
				: 'sortlabel header-font';
			className = className + (props.column.headerClassName ? ' ' + props.column.headerClassName : '');
			if (
				columnBands &&
				columnBands.length !== 0 &&
				!columnBands.flatMap(e => e.children?.flatMap(d => d.columnName)).includes(props.column.name)
			) {
				className = className + ' sortLabelHeight';
			}

			return (
				<Button className={className} onClick={props.onSort}>
					{props.children}
				</Button>
			);
		};

		return (
			<div style={setStyle()}>
				<Paper className={'simple-skiold-table'}>
					<Grid rows={rows} columns={columns} getRowId={getRowId} rootComponent={generateRootComponent}>
						<EditingState onCommitChanges={commitChanges} columnExtensions={columnExtensions} />
						<FilteringState defaultFilters={[]} />
						<IntegratedFiltering />
						<SortingState
							// onSortingChange={this.handleSortingChanged}
							defaultSorting={[{ columnName: 'animalNumber' } as Sorting]}
						/>
						<IntegratedSorting />
						<SelectionState selection={selection} onSelectionChange={onSelect} />
						<IntegratedSelection />
						<VirtualTable
							height="auto"
							cellComponent={FocusableCell}
							estimatedRowHeight={35}
							columnExtensions={columnExtensions}
							containerComponent={ContainerComponent}
							rowComponent={VirtualTableRowComponent}
						/>
						<TableColumnResizing
							minColumnWidth={SowListConstants.iconWidth}
							onColumnWidthsChange={changeColumnWidths}
							columnWidths={
								tableKey && tableSettings[tableKey]
									? validateTableResizing(
											tableSettings[tableKey],
											columnExtensions as TableColumnWidthInfo[],
									  )
									: (columnExtensions as TableColumnWidthInfo[])
							}
						/>
						<TableFilterRow cellComponent={getFilterJSXElement} />
						<TableHeaderRow showSortingControls={true} sortLabelComponent={SortLabel} />

						<TableInlineCellEditing
							cellComponent={TableInlineCell}
							startEditAction={'click'}
							selectTextOnEditStart={true}
						/>
						<TableSelection showSelectAll={true} />
						{columnBands && <TableBandHeader columnBands={columnBands} />}
					</Grid>
				</Paper>
			</div>
		);
	},
);
