
import { IValve } from 'shared/api/api';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import actionCreatorFactory from 'typescript-fsa';
import * as types from './types';

const actionCreator = actionCreatorFactory(GenActionFactoryName('processUnits'));



export const getUnits = actionCreator.async<{ siteId: string;}, IValve[]>(
    types.GET_UNITS
);

export const removeProcessEquipment = actionCreator.async<string, void>(types.SAVE_UNIT_LOCATION);

