import React from 'react';
import PageContainer from 'web/view/components/page-container/page-container';
import { ViewWeb } from 'web/view/components/utils/web-view';
import './sow.scss';

interface SowProps {
    // navigation: NavigationScreenProp<{ params: any }>;
}

export default class Sow extends React.PureComponent<SowProps> {
    public render() {
        // const { params } = this.props.navigation.state;
        // const processes: Process[] = params ? params : [];
        return (
            <PageContainer className='sow'>
                <ViewWeb className='sow-scroller'>
                    {/* <ProcessList navigation={this.props.navigation} color={'orange'} data={processes} /> */}
                </ViewWeb>
            </PageContainer>
        );
    }
}
