import React from 'react';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Option } from 'react-dropdown';
import './active-animals-setup.scss';
import { WebAppState } from 'web/state/store.web';
import {
	ActiveAnimalOption,
	ActiveAnimalsReportSetting,
	AnimalReportType,
	IActiveAnimalsReportSetting,
} from 'shared/api/api';
import { SaveAnimalReportSetting } from 'shared/state/ducks/animal-report-settings/operations';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { localized, localizedDynamic } from 'shared/state/i18n/i18n';
import { SkioldFormsWrapper } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { getSkioldInputRow } from 'web/view/components/work-lists/work-list-helper';
import { SkioldCheckbox } from 'web/view/components/skiold-components/skiold-checkbox/skiold-checkbox';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';

interface PropsFromParent {}

const mapStateToProps = (state: WebAppState) => {
	return {
		activeAnimalReportSetting: state.animalReportSettings.entities.find(
			animalReport => animalReport.type === AnimalReportType.ActiveAnimalsReportSetting,
		),
		siteId: state.profile.active!.siteId,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	saveActiveAnimalReportSetting: (activeAnimalReportSetting: IActiveAnimalsReportSetting) =>
		SaveAnimalReportSetting(activeAnimalReportSetting)(dispatch),
});

export interface State {
	activeAnimalReportSetting: IActiveAnimalsReportSetting;
	selectedOption: Option;
	options: Option[];
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

class ActiveAnimalsSetup extends React.PureComponent<Props, State> {
	public static getDerivedStateFromProps(nextProps: Props, prevState: State): Partial<State> {
		return {
			activeAnimalReportSetting: {
				...prevState.activeAnimalReportSetting,
				...nextProps.activeAnimalReportSetting,
			},
		};
	}
	constructor(props: Props) {
		super(props);
		const options = this.getActiveAnimalOptions();
		this.state = {
            activeAnimalReportSetting: ActiveAnimalsReportSetting.fromJS({
				type: AnimalReportType.ActiveAnimalsReportSetting,
				option: ActiveAnimalOption.AnimalNumberOnly,
				showOnWeb: true,
				siteId: props.siteId,
			} as IActiveAnimalsReportSetting),
			selectedOption: options.find(option => option.value === ActiveAnimalOption.AnimalNumberOnly)!,
			options,
		};
	}

	public toggleShowOnWeb = () => {
		return;
	};

	public toggleShowOnApp = () => {
		this.setState(prevState => ({
			activeAnimalReportSetting: {
				...prevState.activeAnimalReportSetting,
				showOnApp: !this.state.activeAnimalReportSetting.showOnApp,
			},
		}));
	};

	public activeAnimalReportSetupChanged(setup: IActiveAnimalsReportSetting) {
		this.setState({
			activeAnimalReportSetting: setup,
		});
	}

	public render() {
		return (
			<ViewWeb>
				<ViewWeb className="active-animals-setup">
					<TextWeb className="setup-text-label">{localized('Setup')}</TextWeb>
					<SkioldFormsWrapper
						formRows={this.getFormRows()}
						containerClassName="setup-forms-wrappoer-container"
					/>

					{this.renderButtons()}
				</ViewWeb>
			</ViewWeb>
		);
	}

	private async save() {
		const { activeAnimalReportSetting } = this.state;
		if (!this.validate(activeAnimalReportSetting)) {
			return;
		}

		this.props.saveActiveAnimalReportSetting(activeAnimalReportSetting);
	}

	private getFormRows() {
		let formRows = new Array<FormRow>();
		const { activeAnimalReportSetting } = this.state;
		formRows.push(this.getBuildingHeader());
		formRows.push(
			getSkioldInputRow(
				setup => this.activeAnimalReportSetupChanged(setup),
				activeAnimalReportSetting,
				'name',
				localized('report'),
			),
		);

		formRows.push({
			name: localizedDynamic('reportAvailableOn'),
			component: (
				<ViewWeb className="report-available-checkbox-container">
					<SkioldCheckbox
						onClick={this.toggleShowOnWeb}
						isChecked={this.state.activeAnimalReportSetting.showOnWeb!}
					/>
					<TextWeb>{localized('Web')}</TextWeb>
				</ViewWeb>
			),
		});

		return formRows;
	}

	private validate(listSetting: IActiveAnimalsReportSetting) {
		return true;
	}

	private getBuildingHeader(): FormRow {
		return {
			header: localized(AnimalReportType.ActiveAnimalsReportSetting),
		};
	}

	private renderButtons() {
		return (
			<ViewWeb className="setup-button-container">
				<SkioldButton title={localized('Save')} onPress={() => this.save()} />
			</ViewWeb>
		);
	}

	private getActiveAnimalOptions() {
		return Object.keys(ActiveAnimalOption).map(k => {
			return {
				label: localizedDynamic(ActiveAnimalOption[k as any]),
				value: ActiveAnimalOption[k as any],
			};
		});
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ActiveAnimalsSetup);
