import ObjectID from 'bson-objectid';
import React from 'react';
import { Option } from 'react-dropdown';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
	AnimalKind,
	AnimalType,
	DrugAmount,
	DrugTypeType,
	TreatmentFrequency,
	IDiagnose,
	ITreatmentDefinition,
	SubTreatment,
	TreatmentDefinition,
	TreatmentType,
	TreatmentUnit,
	IDrugAmount,
} from 'shared/api/api';
import { getDrugTypeTypeOptions } from 'shared/helpers/drug-type-helper/drug-type-helper';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { getTreatmentAnimalKindsOptions } from 'shared/helpers/general-helpers';
import { getAnimalTypesForTreatments } from 'shared/helpers/treatment-helper/treatment-helper';
import { GetSyncData as DiagnoseGetSyncData } from 'shared/state/ducks/diagnoses/operations';
import { GetSyncData as TreatmentDefinitionGetSyncData } from 'shared/state/ducks/treatment-definitions/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { ViewWeb } from 'web/view/components/utils/web-view';
import DiagnosePicker from '../../diagnose/diagnose-picker';
import PageContainer from '../../page-container/page-container';
import { SkioldButton } from '../../skiold-components/skiold-button/skiold-button';
import { SkioldFormDropdown } from '../../skiold-components/skiold-dropdown/skiold-form-dropdown';
import { SkioldFormsWrapper } from '../../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from '../../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { Heading } from '../../utils/heading';
import AddTreatmentDefinition from './add-treatment-definition';
import './add-treatment-definition-wrapper.scss';

interface PropsFromParent {
	close: () => void;
	definition?: ITreatmentDefinition;
}

const mapAppStateToProps = (state: WebAppState) => {
	return {
		diagnoseImages: state.diagnose.diagnoseImages,
		diagnoses: state.diagnose.entities,
		siteId: state.profile.active!.siteId,
		lang: state.profile.active!.language,
		treatmentDefinitions: state.treatmentDefinitions.entities,
		profile: state.profile.active!,
		animalTypes:
			state.navigation.query && state.navigation.query.type
				? (state.navigation.query.type as AnimalType)
				: AnimalType.Sow,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	getDiagnoses: () => DiagnoseGetSyncData()(dispatch),
	treatmentDefinitionGetSyncData: () => TreatmentDefinitionGetSyncData()(dispatch),
});

interface State {
	selectedDiagnose?: IDiagnose;
	definition?: ITreatmentDefinition;
	selectedAnimalKind: Option;
	selectedDrugTypeType: Option;
	animalKinds: AnimalKind[];
}

type Props = ReturnType<typeof mapAppStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

class AddTreatmentDefinitionWrapper extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			selectedDiagnose: undefined,
			definition: this.props.definition,
			selectedAnimalKind: { label: ' ', value: '' },
			animalKinds: [],
			selectedDrugTypeType: { label: localized(DrugTypeType.Medicine), value: DrugTypeType.Medicine },
		};
	}
	public getFormRows(): any {
		let formRows = new Array<FormRow>();

		formRows.push(this.selectAnimalKindRow());
		formRows.push(this.selectDrugTypeType());
		formRows.push(this.selectDiagnoseRow());

		return formRows;
	}
	public selectAnimalKindRow(): FormRow {
		return {
			name: localized('selectAnimalKind'),
			component: (
				<SkioldFormDropdown
					items={getTreatmentAnimalKindsOptions(
						this.props.profile,
						getAnimalTypesForTreatments(this.props.animalTypes),
					)}
					onValueChanged={this.animalKindChanged}
					selectedValue={this.state.selectedAnimalKind}
				/>
			),
		};
	}

	public selectDrugTypeType(): FormRow {
		return {
			name: localized('chooseDrugType'),
			component: (
				<SkioldFormDropdown
					items={getDrugTypeTypeOptions()}
					onValueChanged={this.drugTypeTypeChanged}
					selectedValue={this.state.selectedDrugTypeType}
				/>
			),
		};
	}

	public selectDiagnoseRow(): FormRow {
		return {
			name: localized('selectDiagnoseDescription'),
			component: (
				<DiagnosePicker
					value={this.state.selectedDiagnose}
					onValueChanged={this.diagnoseChanged}
					items={this.getDiagnoses()}
				/>
			),
		};
	}
	public async componentDidMount() {
		this.props.getDiagnoses();
		this.props.treatmentDefinitionGetSyncData();
	}

	public render() {
		if (this.state.definition) {
			return this.showDefinitionEditor();
		} else {
			return this.showDiagnoseSelect();
		}
	}

	private getDiagnose() {
		let diagnose: IDiagnose | undefined;

		if (this.state.definition) {
			diagnose = this.props.diagnoses.find(d => d.id === this.state.definition!.diagnoseId);
		}

		return diagnose;
	}
	private showDiagnoseSelect() {
		return (
			<PageContainer>
				<ViewWeb className="show-diagnose-select">
					<ViewWeb>
						<Heading text={localized('selectDiagnose')} />
						<SkioldFormsWrapper
							formRows={this.getFormRows()}
							containerClassName="forms-wrapper-container"
						/>
					</ViewWeb>

					<ViewWeb className="diagnose-select-buttons-container">
						<SkioldButton title={localized('Close')} onPress={this.props.close} theme="grey" />
						<SkioldButton title={localized('create')} onPress={this.createDefinition} />
					</ViewWeb>
				</ViewWeb>
			</PageContainer>
		);
	}

	private animalKindChanged = (animalKind: Option) => {
		if (
			animalKind.value === AnimalKind.Sow ||
			animalKind.value === AnimalKind.Boar ||
			animalKind.value === AnimalKind.Gilt
		) {
			this.setState({
				animalKinds: [AnimalKind.Boar, AnimalKind.Sow, AnimalKind.Gilt],
				selectedAnimalKind: animalKind,
			});
		} else if (animalKind.value === AnimalKind.YoungFemale) {
			this.setState({ animalKinds: [AnimalKind.YoungFemale], selectedAnimalKind: animalKind });
		} else if (animalKind.value === AnimalKind.Piglet) {
			this.setState({ animalKinds: [AnimalKind.Piglet], selectedAnimalKind: animalKind });
		} else if (animalKind.value === AnimalKind.Weaner) {
			this.setState({ animalKinds: [AnimalKind.Weaner], selectedAnimalKind: animalKind });
		} else if (animalKind.value === AnimalKind.Finisher) {
			this.setState({ animalKinds: [AnimalKind.Finisher], selectedAnimalKind: animalKind });
		}

		this.setState({ selectedDiagnose: undefined });
	};

	private drugTypeTypeChanged = (option: Option) => {
		this.setState({ selectedDrugTypeType: option });
	};

	private getDiagnoses() {
		if (!this.state.animalKinds.length) {
			return [];
		}

		const selectedType = this.state.selectedDrugTypeType!.value as DrugTypeType;
		const isVaccination = selectedType === DrugTypeType.Vaccine;

		let list = this.props.diagnoses.filter(
			d =>
				!d.isDeleted &&
				d.isVaccination === isVaccination &&
				this.state.animalKinds.some(ak => d.animalKinds!.includes(ak)),
		);
		let diagnoseIds = new Array<string>();
		diagnoseIds = diagnoseIds.concat(
			this.props.treatmentDefinitions
				.filter(td => this.state.animalKinds.some(ak => td.animalKinds!.includes(ak)))
				.map(td => td.diagnoseId!),
		);
		//Only return the diagnoses that haven't been created a definitions yet.
		list = list.filter(diag => {
			let result = diagnoseIds.indexOf(diag.id!);
			return result === -1;
		});
		let lang = this.props.lang;
		return list.sort((a, b) => {
			let textA = lang === 'da' ? a.name!.da.toUpperCase() : a.name!.en.toUpperCase();
			let textB = lang === 'da' ? b.name!.da.toUpperCase() : b.name!.en.toUpperCase();
			return textA < textB ? -1 : textA > textB ? 1 : 0;
		});
	}

	private createDefinition = () => {
		let def: ITreatmentDefinition;

		if (!this.validate()) {
			return;
		}

		if (this.state.selectedDiagnose!.isVaccination) {
			def = TreatmentDefinition.fromJS({
				animalKinds: this.state.animalKinds,
				frequency: TreatmentFrequency.Once,
				numberOfTreatments: 1,
				subTreatments: [
					SubTreatment.fromJS({
						id: new ObjectID().toHexString(),
						drugAmount: new DrugAmount({ unitType: TreatmentUnit.PerAnimal, perUnit: 1 } as IDrugAmount),
						treatmentType: TreatmentType.Injection,
						butcherQuarantine: 0,
					}),
				],
			} as ITreatmentDefinition);
		} else {
			def = TreatmentDefinition.fromJS({ animalKinds: this.state.animalKinds } as ITreatmentDefinition);
		}

		def.diagnoseId = this.state.selectedDiagnose!.id;
		this.setState(prevState => {
			return {
				...prevState,
				definition: def,
			};
		});
	};

	private validate(): boolean {
		if (!this.state.selectedDiagnose) {
			this.showErrorMessage(localized(ExceptionMessage.VALIDATION_ERROR_NoDiagnoseID));
			return false;
		}

		if (!this.state.animalKinds.length) {
			this.showErrorMessage(localized(ExceptionMessage.VALIDATION_ERROR_NoAnimalKinds));
			return false;
		}

		return true;
	}

	private showDefinitionEditor() {
		let diagnose = this.getDiagnose();

		if (!diagnose) {
			//If diagnose haven't been set, don't show anything
			return <ViewWeb />;
		}

		let def = this.state.definition!;
		if (!def.animalKinds! || !def.animalKinds!.length) {
			return <ViewWeb />;
		}
		return (
			<AddTreatmentDefinition
				close={this.props.close}
				treatmentDefinition={this.state.definition}
				diagnose={diagnose}
			/>
		);
	}

	private diagnoseChanged = (newDiagnose: IDiagnose | undefined) => {
		this.setState({
			selectedDiagnose: newDiagnose,
		});
	};

	private showErrorMessage(errorMessage: string) {
		alert(errorMessage);
	}
}

export default connect(mapAppStateToProps, mapDispatchToProps)(AddTreatmentDefinitionWrapper);
