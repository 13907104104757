import React from 'react';
import { SkioldUnderlineInput } from '../skiold-input/skiold-underline-input';
import { IntegerChanged } from 'shared/helpers/number-helper';
import { isNullOrUndefined } from 'shared/helpers/general-helpers';

export interface PropsFromParent {
    onChangeNumber: (newText: number | undefined) => void;
    text?: number;
    style?: any;
    editable?: boolean;
    onBlur?: () => void;
    containerStyle?: any;
    containerClassName?: string;
    className?: string;
}

interface State {}

export class SkioldUnderlineIntergerInput extends React.PureComponent<PropsFromParent, State> {
    constructor(props: PropsFromParent) {
        super(props);

        this.state = {};
    }

    public render() {
        return (
            <SkioldUnderlineInput
                onChangeText={newText => this.onTextChanged(newText)}
                editable={this.props.editable === undefined ? true : this.props.editable}
                text={isNullOrUndefined(this.props.text) ? '' : this.props.text + ''}   
                onBlur={this.props.onBlur}
                containerStyle={this.props.containerStyle}
                containerClassName={this.props.containerClassName}
                className={this.props.className}
            />
        );
    }

    private onTextChanged(newText: string) {
        IntegerChanged(newText, valueToSet => {
            this.props.onChangeNumber(valueToSet);
        });
    }
}
