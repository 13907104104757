import React from 'react';
import { localized } from 'shared/state/i18n/i18n';
import { ViewWeb } from '../../../utils/web-view';
import { SkioldButton } from '../../skiold-button/skiold-button';
import './skiold-modal-button-container.scss';

export interface PropsFromParent {
	closeModal: () => void;
	children?: React.ReactNode;
}
export class SkioldModalButtonContainer extends React.PureComponent<PropsFromParent> {
	public render() {
		return (
			<ViewWeb className="skioldModalButtonContainer">
				{this.props.closeModal && (
					<ViewWeb className="vertical-center">
						<SkioldButton title={localized('Close')} onPress={this.props.closeModal} theme="grey" />
					</ViewWeb>
				)}
				{this.props.children}
			</ViewWeb>
		);
	}
}
