import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { SharedAppState } from 'shared/state/store.shared';
import { goToPage } from 'web/state/ducks/navigation/actions';
import { RouteKey } from 'web/state/ducks/navigation/routes';
import { AppRoute, ROUTE_PROFILE } from 'web/state/ducks/navigation/types';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { SkioldTouchableOpacity } from '../skiold-components/skiold-touchable-opacity';
import './navbar.scss';
import NavLogo from './navlogo';
import OverlayMenu from './overlay-menu';

interface NavProps {
	routes: { [K: string]: AppRoute }; //AppRoute };
}

interface NavBarState {}

const mapStateToProps = (state: SharedAppState) => {
	return {
		profile: state.profile.active!,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
	navigate: (type: RouteKey, param?: string) => () => dispatch(goToPage(type, param)),
});

type NavBarProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & NavProps;
export class NavBar extends React.PureComponent<NavBarProps, NavBarState> {
	public render() {
		return (
			<ViewWeb className="navbar">
				<ViewWeb className="navbar-style">
					<ViewWeb className="logo-container">
						<NavLogo />
					</ViewWeb>

					<SkioldTouchableOpacity
						containerClassName="site-container"
						onPress={this.props.navigate(ROUTE_PROFILE)}
					>
						<div className="siteNameFontStyle">{this.props.profile.siteName}</div>
					</SkioldTouchableOpacity>

					<OverlayMenu routes={this.props.routes} />
				</ViewWeb>
			</ViewWeb>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
