

import React from 'react';
import './skiold-image.scss';
import { ViewWeb } from '../web-view';

interface ImagePropsFromParent {
    width?: number | string;
    height?: number | string;
    imageData?: any;
    extraStyle?: any;
    mode?: 'contain' | 'center' | 'stretch' | 'cover' | undefined;
    className?:string;
}

export class SkioldImage extends React.PureComponent<ImagePropsFromParent> {
    public static defaultProps: Partial<ImagePropsFromParent> = {
        mode: 'contain',
        className: '',
    };

    private imageContainerStyle = {
        width: isNaN(Number(this.props.width)) ? this.props.width : Number(this.props.width),
        height: isNaN(Number(this.props.height)) ? this.props.height : Number(this.props.height),
        ...this.props.extraStyle
    };
    
    public render() {
        return (
            <ViewWeb className={this.props.className+ ' skiold-image '+ this.props.mode} style={this.imageContainerStyle}>
                <img alt={''} className={'skiold-image ' + this.props.mode} src={this.props.imageData}  />
                {/* <Image
                    style={this.imageViewStyle}
                    source={this.props.imageData}
                    resizeMode={this.props.mode}
                    resizeMethod="resize"
                /> */}
            </ViewWeb>
        );
    }
}
