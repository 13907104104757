import { InstrumentationKey, CloudRoleName, SkioldDigitalApiBaseUrl } from 'shared/constants';
import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';

type PropertyCollection = { [name: string]: string };

const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: InstrumentationKey,
        loggingLevelTelemetry: 2
    }
});
const telemetryInitializer = (envelope: ITelemetryItem) => {
    if (envelope && envelope.tags) {
        envelope.tags["ai.cloud.role"] = CloudRoleName;
        envelope.tags["ai.cloud.roleInstance"] = SkioldDigitalApiBaseUrl;
    }
}
appInsights.loadAppInsights();
appInsights.addTelemetryInitializer(telemetryInitializer);
appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview

export class Monitoring {
    public static logException(exception: Error) {
        console.error(exception);
        appInsights.trackException({ exception: exception });
    }

    public static logTrace(message: string, properties?: PropertyCollection) {
        console.warn(message);
        appInsights.trackTrace({ message }, properties);
    }
}
