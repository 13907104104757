import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ResultAnalysisMatingsTableItem } from 'shared/api/api';
import { localized } from 'shared/state/i18n/i18n';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { Heading } from '../../../utils/heading';
import { AnalysisModalMapStateToProps, AnalysisModalPropsFromParent } from '../../analysis-helper';
import { AnalysisModalHeader } from '../analysis-modal-header';
import AnalysisResultsAnalysisMatingsTable, {
	AnalysisResultsAnalysisMatingsTable as AnalysisResultsAnalysisMatingsTableRef,
} from './analysis-results-analysis-matings-table';
import { GetAnalysisResultAnalysisMatingsTable } from 'shared/state/ducks/analysis/analysis-modal/operation';
import { generatePeriodDto } from 'web/web-helpers/pdf-helper/analysis-pdf-helpers/analysis-pdf-helper';
import { Spinner } from 'web/view/components/spinner/spinner';
import { ClipLoader } from 'react-spinners';
import { Colors } from 'shared/assets/root-assets/colors';

interface State {
	loading: boolean;
	count: number;
	data: ResultAnalysisMatingsTableItem[];
}

const mapDispatchToProps = (dispatch: Dispatch, props: AnalysisModalPropsFromParent) => {
	return {
		GetAnalysisResultAnalysisMatingsTable: async (siteId: string) =>
			await GetAnalysisResultAnalysisMatingsTable(
				siteId,
				props.fetchDataValue,
				props.mode,
				props.dateFrom,
				props.dateTo,
			),
	};
};

type Props = ReturnType<typeof AnalysisModalMapStateToProps> &
	ReturnType<typeof mapDispatchToProps> &
	AnalysisModalPropsFromParent;

export class AnalysisResultsPerLitterMatingsOverview extends React.PureComponent<Props, State> {
	public AnalysisResultsAnalysisMatingsTableRef: AnalysisResultsAnalysisMatingsTableRef | undefined;
	constructor(props: Props) {
		super(props);
		this.state = { loading: true, data: [], count: 0 };
	}

	public componentDidMount() {
		this.props
			.GetAnalysisResultAnalysisMatingsTable(this.props.siteId)
			.then(tableData => this.setState({ data: tableData, loading: false, count: this.getCount(tableData) }));
	}

	private getCount = (data: ResultAnalysisMatingsTableItem[]) => {
		return [...new Set(data.map(tableItem => tableItem.sowId))].length;
	};

	public render() {
		return (
			<ViewWeb>
				<Heading text={localized('Matings')} />
				<AnalysisModalHeader
					mode={this.props.mode}
					closeModal={this.props.closeModal}
					count={this.state.count}
					value={this.props.fetchDataValue}
					dateFrom={this.props.dateFrom}
					dateTo={this.props.dateTo}
					printData={this.printData}
				/>
				{this.state.loading ? (
					<ViewWeb className="align-items-center basic-padding" >
						<ClipLoader color={Colors.yellow} size={100} />
					</ViewWeb>
				) : (
					<AnalysisResultsAnalysisMatingsTable
						mode={this.props.mode}
						onFilterChange={this.setCount}
						data={this.state.data}
						ref={this.setRef as any}
					/>
				)}
			</ViewWeb>
		);
	}

	private setCount = (data: ResultAnalysisMatingsTableItem[]) => {
		this.setState({ count: this.getCount(data) });
	};

	private setRef = (ref?: AnalysisResultsAnalysisMatingsTableRef) =>
		(this.AnalysisResultsAnalysisMatingsTableRef = ref);

	private printData = async () => {
		if (this.AnalysisResultsAnalysisMatingsTableRef) {
			await this.AnalysisResultsAnalysisMatingsTableRef.printData(
				generatePeriodDto(this.props.dateFrom, this.props.dateTo),
			);
		}
	};
}

export default connect(AnalysisModalMapStateToProps, mapDispatchToProps)(AnalysisResultsPerLitterMatingsOverview);
