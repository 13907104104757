 import React, { FC, useCallback, useEffect, useState } from 'react';
import { ViewWeb } from '../../../../components/utils/web-view';
import { TextWeb } from '../../../../../web-helpers/styled-text-components';
import './nucleus-management-archive.scss';
import { localized } from '../../../../../../shared/state/i18n/i18n';
import  SkioldTableGrid  from '../../../../components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { SkioldTouchableOpacity } from '../../../../components/skiold-components/skiold-touchable-opacity';
import { SkioldImage } from '../../../../components/utils/svg/skiold-image';
import OpenIcon from 'shared/assets/src-assets/png/open_archive.png';
import SendIcon from 'shared/assets/src-assets/png/send_data_grey.png';
import { INucleusManagementArchiveFile } from '../../../../../../shared/api/api';
import { useDispatch, useSelector } from 'react-redux';
import { selectNucleusManagementArchiveFiles } from '../../../../../../shared/state/ducks/nucleus-management-archive/reducer';
import { selectActiveSite } from '../../../../../../shared/state/ducks/site/reducer';
import {
	getNucleusManagementArchiveFiles,
	resendNucleusManagementArchiveFiles,
} from '../../../../../../shared/state/ducks/nucleus-management-archive/operation';
import { SkioldCheckbox } from '../../../../components/skiold-components/skiold-checkbox/skiold-checkbox';
import { getDateString, getTimeDateString } from '../../../../../../shared/helpers/date-helpers';
import { WebAppState } from '../../../../../state/store.web';
import { Spinner } from '../../../../components/spinner/spinner';
import * as pako from 'pako';
import { SkioldModal } from '../../../../components/skiold-components/skiold-modal/skiold-modal';
import { SkioldButton } from '../../../../components/skiold-components/skiold-button/skiold-button';

export const NucleusManagementArchive: FC = React.memo(() => {
	const dispatch = useDispatch();
	const activeSite = useSelector(selectActiveSite);
	const archiveFiles = useSelector(selectNucleusManagementArchiveFiles);
	const pending = useSelector((state: WebAppState) => state.nucleusManagementArchive.loading);
	const [markedForResend, setMarkedForResend] = useState<string[]>([]);
	const [fileText, setFileText] = useState('');
	const [modalOpen, setModalOpen] = useState(false);

	useEffect(() => {
		if (activeSite.id) {
			dispatch(getNucleusManagementArchiveFiles(activeSite.id));
		}
	}, [activeSite.id, dispatch]);

	const openFile = useCallback(
		(itemId: string | undefined) => {
			const file = archiveFiles.find(f => f.id === itemId);
			if (file?.fileContents) {
				const zipData = atob(file.fileContents);
				const dataArray = Uint8Array.from(zipData, c => c.charCodeAt(0));
				const ungzipedData = pako.ungzip(dataArray);
				const decodedText = new TextDecoder().decode(ungzipedData);
				setFileText(decodedText);
				setModalOpen(true);
			}
		},
		[archiveFiles],
	);

	const closeModal = useCallback(() => {
		setModalOpen(false);
		setFileText('');
	}, []);

	const getIsAllChecked = useCallback(() => markedForResend.length === archiveFiles.length, [
		markedForResend,
		archiveFiles,
	]);

	const handleResendClicked = useCallback(() => {
		dispatch(resendNucleusManagementArchiveFiles(markedForResend));
		setMarkedForResend([]);
	}, [dispatch, markedForResend]);

	const handleSelectAll = useCallback(() => {
		if (getIsAllChecked()) {
			setMarkedForResend([]);
		} else {
			setMarkedForResend(archiveFiles.map(f => f.id!));
		}
	}, [archiveFiles, getIsAllChecked]);

	const handleMarkForResend = useCallback(
		(checked: boolean, itemFromParent: INucleusManagementArchiveFile) => {
			if (checked) {
				setMarkedForResend(prevState => [...prevState, itemFromParent.id!]);
			} else {
				const temp = [...markedForResend];
				const index = markedForResend.findIndex(id => id === itemFromParent.id!);
				temp.splice(index, 1);
				setMarkedForResend(temp);
			}
		},
		[markedForResend],
	);

	const columnExtensions = [
		{
			columnName: 'open',
			width: 75,
		},
		{
			columnName: 'fileName',
			width: 250,
		},
		{
			columnName: 'sendDate',
			width: 150,
		},
		{
			columnName: 'checkbox',
			width: 100,
			sortable: false,
		},
	];

	const columns = [
		{
			name: 'open',
			title: localized('Open'),
			getCellValue: (item: INucleusManagementArchiveFile) => (
				<ViewWeb className={'center-cell'}>
					<SkioldTouchableOpacity onPress={openFile} itemFromParent={item.id}>
						<SkioldImage imageData={OpenIcon} width={25} height={25} />
					</SkioldTouchableOpacity>
				</ViewWeb>
			),
			filter: () => null,
		},
		{
			name: 'fileName',
			title: localized('FileName'),
			getCellValue: (item: INucleusManagementArchiveFile) => item.fileName,
			filter: () => null,
		},
		{
			name: 'sendDate',
			title: localized('SentDate'),
			getCellValue: (item: INucleusManagementArchiveFile) => getTimeDateString(item.sentTime),
			filter: () => null,
		},
		{
			name: 'checkbox',
			title: localized('resend'),
			headerClassName: 'merged-header',
			getCellValue: (item: INucleusManagementArchiveFile) => (
				<SkioldCheckbox
					onClick={handleMarkForResend}
					itemFromParent={item}
					isChecked={markedForResend.includes(item.id!)}
				/>
			),
			sortable: false,
			filter: () => (
				<ViewWeb className={'center-cell'}>
					<SkioldCheckbox isChecked={getIsAllChecked()} onClick={handleSelectAll} />
				</ViewWeb>
			),
		},
	];

	return (
		<ViewWeb className={'archive-container'}>
			{pending && <Spinner />}
			<ViewWeb className={'header-row'}>
				<TextWeb className={'title'}>{localized('ArchiveSendData')}</TextWeb>
				<SkioldTouchableOpacity onPress={handleResendClicked} className={'button-container'}>
					<SkioldImage width={60} height={60} imageData={SendIcon} />
					<TextWeb>{localized('resend')}</TextWeb>
				</SkioldTouchableOpacity>
			</ViewWeb>
			<SkioldTableGrid
				columns={columns}
				tableKey={'archivetable'}
				data={archiveFiles}
				ignoreSetCount={true}
				ColumnExtensions={columnExtensions}
			/>
			<SkioldModal
				isOpen={modalOpen}
				close={closeModal}
				shouldCloseOnOverlayClick={true}
				className={'file-text-container'}
				maxWidth={'1250px'}
			>
				<ViewWeb className={'text-box'}>
					{fileText.split('\n').map((s, i) => (
						<TextWeb className={'file-text-view'} key={i}>
							{s}
						</TextWeb>
					))}
				</ViewWeb>
				<SkioldButton title={localized('Close')} onPress={closeModal} theme={'grey'} />
			</SkioldModal>
		</ViewWeb>
	);
});
