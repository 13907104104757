import { Action } from 'redux';
import { mergeArrays } from 'shared/helpers/reducer-helpers';
import { isType } from 'typescript-fsa';
import { getSyncStationData } from './actions';
import { EsfStationDataState } from './types';
import { Station, IStation } from 'shared/api/api';
import { siteChange } from '../profile/actions';
import { SharedAppState } from 'shared/state/store.shared';
import { getSyncModelData } from '../sync/actions';

export const initialState: EsfStationDataState = {
	data: [],
	lastSyncDate: new Date(-8640000000000000),
};

const StationReducer = (state: EsfStationDataState = initialState, action: Action): EsfStationDataState => {
	if (isType(action, getSyncStationData.done)) {
		if (action.payload.params.siteId === action.payload.params.activeSiteId) {
			if (action.payload.result.datas && action.payload.result.datas.length > 0) {
				state = {
					...state,
					data: mergeArrays(state.data, action.payload.result.datas!),
					lastSyncDate: action.payload.result.syncTime,
				};
			}
		}
	}

	if (isType(action, getSyncModelData.done)) {
		if (action.payload.params.siteId === action.payload.params.activeSiteId) {
			if (
				action.payload.result.stations &&
				action.payload.result.stations.datas &&
				action.payload.result.stations.datas.length > 0
			) {
				state = {
					...state,
					data: mergeArrays(state.data, action.payload.result.stations.datas!),
					lastSyncDate: action.payload.result.stations.syncTime,
				};
			}
		}
	}

	if (isType(action, siteChange.done)) {
		state = initialState;
	}

	if (action.type === 'persist/REHYDRATE') {
		let a = (action as any) as { payload: SharedAppState; key: string };
		if (a.key === 'root') {
			let entities = new Array<IStation>();
			let lastSyncDate = new Date(-8640000000000000);
			if (a.payload && a.payload.station && a.payload.station.data) {
				entities = a.payload.station.data.map((t: IStation) => {
					let nt = Station.fromJS({});
					nt.init(t);
					return nt;
				});
			}

			if (a.payload && a.payload.station && a.payload.station.lastSyncDate) {
				lastSyncDate = new Date(a.payload.station.lastSyncDate);
			}

			state = { ...state, data: entities, lastSyncDate };
		}
	}

	return state;
};

export default StationReducer;
