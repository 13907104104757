import React from 'react';
import { connect } from 'react-redux';
import { ResultAnalysisWeaningsTableItem, TotalWeaningTableItem, PeriodDto } from 'shared/api/api';
import { getDateString } from 'shared/helpers/date-helpers';
import { NaturalSortDates } from 'shared/helpers/natural-sort';
import { RefType } from 'shared/helpers/ref-type';
import { AnalysisModalMode } from 'shared/state/ducks/analysis/analysis-modal/operation';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { getWebAnimalColorStyleDashboard } from 'web/view/components/dashboard/dashboard-helper';
import SkioldTableGrid, {
	SkioldTableGrid as SkioldTableRef,
} from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { SowListConstants } from 'web/view/components/stem-animal/animal-lists/table-constants';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { GetAnalysisWeaningsPdf } from 'web/web-helpers/pdf-helper/analysis-pdf-helpers/analysis-pdf-helper';
import {
	AnalysisResultPerLitterModalTableState,
	defaultSortingResultPerLitterModals,
	getAnalysisAnimalNumberCell,
	getAnalysisLitterNumberCell,
	getShowSowCardCell,
	getSkioldSowCardModal,
	initialAnalysisResultPerLitterModalsTableStates,
	nursingDaysSummary,
} from '../../analysis-helper';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';

interface PropsFromParent {
	mode: AnalysisModalMode;
	data: ResultAnalysisWeaningsTableItem[];
	onFilterChange: (data: ResultAnalysisWeaningsTableItem[]) => void;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		profile: state.profile.active!,
	};
};

type Props = ReturnType<typeof mapStateToProps> & PropsFromParent;

export class AnalysisResultsAnalysisWeaningsTable extends React.PureComponent<
	Props,
	AnalysisResultPerLitterModalTableState
> {
	constructor(props: Props) {
		super(props);
		this.state = initialAnalysisResultPerLitterModalsTableStates;
	}

	public render() {
		return (
			<ViewWeb className="analysis-modal-table ">
				<SkioldTableGrid
					sortHeaderId={defaultSortingResultPerLitterModals}
					usesSummaryRow={true}
					onFiltersChanged={this.props.onFilterChange}
					data={this.props.data}
					ref={this.setTableRef}
					columns={this.generateColumns}
					ColumnExtensions={this.getColumnExtenstions}
					tableKey={'analysisWeaningResultPerLitterTable'}
				/>
				{getSkioldSowCardModal(this.state.sowCardModal, this.closeModal, this.state.stemAnimalId)}
			</ViewWeb>
		);
	}

	public printData = async (periodDto: PeriodDto) => {
		if (this.SkioldTableRef) {
			let data = this.SkioldTableRef.GetSortedData();
			GetAnalysisWeaningsPdf(
				data,
				AnalysisModalMode[this.props.mode] + 'Weaning.pdf',
				this.props.profile.siteId!,
				Intl.DateTimeFormat().resolvedOptions().timeZone,
				this.props.profile.language,
				periodDto,
			);
		}
	};

	public SkioldTableRef: SkioldTableRef | undefined;

	private readonly setTableRef = (m: any) => (m ? (this.SkioldTableRef = m) : {});

	private readonly weightSummary = (items: TotalWeaningTableItem[]) => {
		let totalWeight = 0;
		let totalPigs = 0;
		items.forEach(item => {
			if (item.weightPerPiglet && item.weanedPerLitter) {
				totalWeight += item.weightPerPiglet * item.weanedPerLitter;
				totalPigs += item.weanedPerLitter;
			}
		});
		return totalWeight && totalPigs ? totalWeight / totalPigs : undefined;
	};

	// private readonly nursingDaysSummary = (items: ResultAnalysisWeaningsTableItem[]) => {
	// 	let totalDays = 0;
	// 	let count = 0;

	// 	let groupedWeanings = items.groupBy('pregnancyId');
	// 	groupedWeanings.forEach(weanings => {
	// 		let finishedWeaning = weanings.find(wea => !wea.isNursingSow);
	// 		if (finishedWeaning && finishedWeaning.nursingDays !== undefined) {
	// 			totalDays += finishedWeaning.nursingDays;
	// 			count += 1;
	// 		} else {
	// 			let totalNursingsDays = weanings
	// 				.map(wea => wea.nursingDays)
	// 				.reduce((total, days) => {
	// 					let d1 = days ? days : 0;
	// 					let d2 = total ? total : 0;
	// 					return d2 + d1;
	// 				}, 0);

	// 			totalDays += totalNursingsDays ? totalNursingsDays : 0;
	// 			count += 1;
	// 		}
	// 	});
	// 	return totalDays && count ? Math.round((totalDays / count) * 10) / 10 : undefined;
	// };

	private getColumnExtenstions = [
		{
			columnName: 'sowCard',
			filteringEnabled: false,
			width: SowListConstants.iconWidth,
		},
		{
			columnName: 'animalNr',
			width: SowListConstants.animalNrWidth,
		},
		{
			columnName: 'litter',
			width: SowListConstants.iDNumberWidth,
		},
		{
			columnName: 'nursingDays',
			width: 135,
			summaryMethod: nursingDaysSummary,
			usesSummary: true,
		},
		{
			columnName: 'weaningDate',
			width: SowListConstants.departureDateWidth,
		},
		{
			columnName: 'weanedPerLitter',
			width: SowListConstants.iDNumberWidth,
		},
		{
			columnName: 'weightPerPiglet',
			usesSummary: true,
			summaryMethod: this.weightSummary,
			width: SowListConstants.iDNumberWidth,
		},
		{
			columnName: 'nursingSow',
			width: SowListConstants.iDNumberWidth,
		},
	];

	private readonly showSowCard = (stemAnimalId?: string) => {
		if (stemAnimalId) {
			this.setState({ sowCardModal: true, stemAnimalId: stemAnimalId });
		}
	};

	private readonly closeModal = () => {
		this.setState(initialAnalysisResultPerLitterModalsTableStates);
	};

	private readonly showSowCardCell = (item: ResultAnalysisWeaningsTableItem) =>
		getShowSowCardCell(item, this.showSowCard);

	private readonly getNursingDaysCell = (item: ResultAnalysisWeaningsTableItem) => item.nursingDays;

	private readonly getWeaningDateCell = (item: ResultAnalysisWeaningsTableItem) => getDateString(item.weaningDate);

	private readonly getWeanedPerLitterCell = (item: ResultAnalysisWeaningsTableItem) => item.weanedPerLitter;

	private readonly getWeightPerPigletCell = (item: ResultAnalysisWeaningsTableItem) =>
		item.weightPerPiglet ? item.weightPerPiglet : undefined;

	private readonly getIsNursingSowCell = (item: ResultAnalysisWeaningsTableItem) =>
		item.isNursingSow ? localized('True') : localized('False');

	private generateColumns = [
		{
			name: 'sowCard',
			title: ' ',
			Footer: localized('avg'),
			summaryText: localized('average'),
			sortable: false,
			getCellValue: this.showSowCardCell,
		},
		{
			name: 'animalNr',
			title: localized('animalNr'),
			className: getWebAnimalColorStyleDashboard,
			getCellValue: getAnalysisAnimalNumberCell,
		},
		{
			name: 'litter',
			title: localized('LitterNr'),
			getCellValue: getAnalysisLitterNumberCell,
		},
		{
			name: 'nursingDays',
			title: localized('NursingDays'),
			getCellValue: this.getNursingDaysCell,
		},
		{
			name: 'weaningDate',
			title: <div className={'alignDivsCenter'}>{localized('weaningDateDashboard')}</div>,
			sortFunction: NaturalSortDates,
			getCellValue: this.getWeaningDateCell,
		},
		{
			name: 'weanedPerLitter',
			title: localized('Pcs'),
			getCellValue: this.getWeanedPerLitterCell,
		},
		{
			name: 'weightPerPiglet',
			title: <div className={'alignDivsCenter'}>{localized('weightPerPigDashboard')}</div>,
			getCellValue: this.getWeightPerPigletCell,
		},
		{
			name: 'nursingSow',
			title: localized('nursingSow'),
			getCellValue: this.getIsNursingSowCell,
		},
	];
}

export default connect<ReturnType<typeof mapStateToProps>, null, RefType, WebAppState>(mapStateToProps, null, null, {
	forwardRef: true,
})(AnalysisResultsAnalysisWeaningsTable);
