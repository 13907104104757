import moment from 'moment';
import {
	AnimalKind,
	IAverted,
	IFarrowing,
	IFarrowingInProgress,
	IMated,
	IPen,
	IPregnancyEvent,
	IScanned,
	IStemAnimal,
	IValidationSetup,
	PregnancyState,
	PregnancyValidationType,
	Scanned,
	ScanResult,
	SowState,
	IGeneralSettings,
} from 'shared/api/api';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { isEmpty } from 'shared/helpers/general-helpers';
import { memoizeHashmapLocation } from 'shared/helpers/memoize-getters/memoize-getters';
import { sortPregnanciesByDate } from 'shared/helpers/pregnancy-helper/sort-pregnancies';
import { getPreviousEvent, getSowState } from 'shared/helpers/pregnancy-helper/sow-state-helper';
import { localized, localizedDynamic } from 'shared/state/i18n/i18n';
import { StoreContainer } from 'shared/state/store-container';
import { SharedAppState } from 'shared/state/store.shared';
import { ShowConfirmAlert } from 'web/view/components/skiold-alert/skiold-alert';
import {
	FarrowingToWeaningCheck,
	MatingAgeCheck,
	MatingToFarrowingCheck,
	WeaningToMatingCheck,
} from './web-prengancy-validation-settings';

export async function createAndEditValidation(
	validationSetup: IValidationSetup,
	generalSettings: IGeneralSettings,
	newPregnancyEvent: IPregnancyEvent,
	currentPregPreviousEvent: IPregnancyEvent | undefined,
	animalPregs: IPregnancyEvent[] | undefined,
	sow?: IStemAnimal,
	matingBatchNumber?: string,
	useNucleusManagement?: boolean,
) {
	if (!animalPregs) {
		animalPregs = [];
	}

	if (!animalPregs.find(event => event.id === newPregnancyEvent.id)) {
		return await validationHelper(
			validationSetup,
			generalSettings,
			newPregnancyEvent,
			currentPregPreviousEvent,
			animalPregs,
			sow,
			matingBatchNumber,
		);
	} else {
		return await editValidationHelper(
			validationSetup,
			generalSettings,
			newPregnancyEvent,
			currentPregPreviousEvent,
			animalPregs,
			sow,
			matingBatchNumber,
			useNucleusManagement,
		);
	}
}

export async function validationHelper(
	validationSetup: IValidationSetup,
	generalSettings: IGeneralSettings,
	newPregnancyEvent: IPregnancyEvent,
	currentPregPreviousEvent: IPregnancyEvent | undefined,
	animalPregs: IPregnancyEvent[] | undefined,
	sow?: IStemAnimal,
	matingBatchNumber?: string,
) {
	if (validationSetup === null || !validationSetup.validationIntervals || !validationSetup) {
		return ExceptionMessage.VALIDATION_ERROR_SETUP_PREGNANCY_VALIDATION;
	}
	const lastestPregEvent = animalPregs ? sortPregnanciesByDate(animalPregs)[0] : undefined;
	if (!newPregnancyEvent.stemAnimalId) {
		return ExceptionMessage.VALIDATION_ERROR_ANIMAL_NUM_NOT_FOUND;
	}

	if (!newPregnancyEvent.siteId) {
		return ExceptionMessage.VALIDATION_ERROR_REGISTRATION_ERROR_PREG_EVENT;
	}

	if (!newPregnancyEvent.date) {
		return ExceptionMessage.VALIDATION_ERROR_FINISHED_DATE_NOT_SET;
	}

	if (lastestPregEvent && newPregnancyEvent.date! < lastestPregEvent.date!) {
		return ExceptionMessage.VALIDATION_ERROR_FINISHED_DATE_BEFORE_PRE_EVENT;
	}

	if (currentPregPreviousEvent && !isEmpty(currentPregPreviousEvent)) {
		if (!currentPregPreviousEvent.pregnancyId) {
			return ExceptionMessage.VALIDATION_ERROR_REGISTRATION_ERROR_PREG_EVENT;
		}
	} else {
		if (newPregnancyEvent.state !== PregnancyState.Mated) {
			return ExceptionMessage.VALIDATION_ERROR_FINISHED_DATE_NO_PRE_EVENT;
		}
	}
	if (newPregnancyEvent.state === PregnancyState.Averted) {
		const store = StoreContainer.getState() as SharedAppState;
		const memorizedLocations = memoizeHashmapLocation(
			store.locations.buildings,
			store.locations.sections,
			store.locations.pens,
		);
		if (
			!generalSettings.weanedPenId ||
			memorizedLocations[generalSettings.weanedPenId] === undefined ||
			((memorizedLocations[generalSettings.weanedPenId] as IPen) &&
				(memorizedLocations[generalSettings.weanedPenId] as IPen).isDeleted)
		) {
			return ExceptionMessage.VALIDATION_ERROR_NO_DEFAULT_WEANING_PEN;
		}
	}

	if (newPregnancyEvent.state === PregnancyState.Mated) {
		return await matedValidation(
			validationSetup,
			newPregnancyEvent as IMated,
			currentPregPreviousEvent!,
			animalPregs!,
			sow!,
			matingBatchNumber!,
		);
	}

	if (newPregnancyEvent.state === PregnancyState.Scanned) {
		return await scannedValidation(
			validationSetup,
			newPregnancyEvent as IScanned,
			currentPregPreviousEvent!,
			animalPregs!,
		);
	}

	if (newPregnancyEvent.state === PregnancyState.FarrowingInProgress) {
		return await farrowingInProgressValidation(
			validationSetup,
			newPregnancyEvent,
			currentPregPreviousEvent!,
			animalPregs!,
		);
	}

	if (newPregnancyEvent.state === PregnancyState.Farrowing) {
		return await farrowingValidation(
			validationSetup,
			newPregnancyEvent as IFarrowing,
			currentPregPreviousEvent!,
			animalPregs!,
		);
	}

	if (newPregnancyEvent.state === PregnancyState.Averted) {
		return await WeaningValidation(
			validationSetup,
			generalSettings,
			newPregnancyEvent as IAverted,
			currentPregPreviousEvent!,
			animalPregs!,
		);
	}
	return '';
}

export async function WeaningValidation(
	validationSetup: IValidationSetup,
	generalSettings: IGeneralSettings,
	weaning: IAverted,
	prevEvent: IPregnancyEvent,
	animalEvents: IPregnancyEvent[],
) {
	if (!generalSettings.weanedPenId) {
		return ExceptionMessage.VALIDATION_ERROR_NO_DEFAULT_WEANING_PEN;
	}

	if (weaning.numAlive === undefined) {
		return ExceptionMessage.VALIDATION_ERROR_WEANING_NUM_ALIVE_IS_NOT_SET;
	}

	if (weaning.numAlive && weaning.numAlive > 0 && (!weaning.totalWeight || weaning.totalWeight <= 0)) {
		return ExceptionMessage.VALIDATION_ERROR_NO_WEIGHT_SET;
	}

	const sowState = getSowState(animalEvents);

	if (sowState !== SowState.Nursing && sowState !== SowState.PregnantAndNursing) {
		return ExceptionMessage.VALIDATION_ERROR_INVALID_PREG_EVENT;
	}

	if (prevEvent.state === PregnancyState.Farrowing) {
		const farrowingToWeaning = validationSetup.validationIntervals!.find(
			x => x.type === PregnancyValidationType.FarrowingToAverted,
		);
		const isValidMsg = await FarrowingToWeaningCheck(
			prevEvent.date!,
			weaning.date!,
			farrowingToWeaning!,
			weaning.stemAnimalId!,
		);
		if (isValidMsg !== '') {
			return isValidMsg;
		}
	}
	return '';
}

export async function farrowingValidation(
	validationSetup: IValidationSetup,
	farrowed: IFarrowing,
	prevEvent: IPregnancyEvent,
	animalEvents: IPregnancyEvent[],
) {
	const sowState = getSowState(animalEvents);
	if (sowState !== SowState.Pregnant) {
		return ExceptionMessage.VALIDATION_ERROR_INVALID_PREG_EVENT;
	}

	const mated = animalEvents.find(x => x.state === PregnancyState.Mated && x.pregnancyId === prevEvent.pregnancyId);
	if (!mated) {
		return ExceptionMessage.VALIDATION_ERROR_INVALID_PREG_EVENT;
	}

	if (farrowed.numAlive === undefined) {
		return ExceptionMessage.VALIDATION_ERROR_FARROWING_NUM_ALIVE_NOT_SET;
	}

	if (
		(farrowed.numAlive && farrowed.numAlive < 0) ||
		(farrowed.numFemaleAlive && farrowed.numFemaleAlive < 0) ||
		(farrowed.numMaleAlive && farrowed.numMaleAlive < 0)
	) {
		return ExceptionMessage.VALIDATION_ERROR_CANNOT_BE_NEGATIVE;
	}
	const maleAndFemaleSum = farrowed.numFemaleAlive! + farrowed.numMaleAlive!;
	if (maleAndFemaleSum) {
		if (farrowed.numAlive !== maleAndFemaleSum) {
			return ExceptionMessage.VALIDATION_ERROR_INVALID_FARROWING_ALIVE;
		}
	}

	if (farrowed.abortion) {
		if (farrowed.numAlive && farrowed.numAlive !== 0) {
			return ExceptionMessage.VALIDATION_ERROR_NO_ABORTION_IF_ALIVE_PIGS;
		}
	} else {
		const matingToFarrowing = validationSetup.validationIntervals!.find(
			x => x.type === PregnancyValidationType.MatingToFarrowing,
		);
		const isValidMsg = await MatingToFarrowingCheck(mated.date!, farrowed.date!, matingToFarrowing!);
		if (isValidMsg !== '') {
			return isValidMsg;
		}
	}

	return '';
}

export async function scannedValidation(
	validationSetup: IValidationSetup,
	scanned: IScanned,
	prevEvent: IPregnancyEvent,
	animalEvents: IPregnancyEvent[],
) {
	const sowState = getSowState(animalEvents);
	if (sowState !== SowState.Pregnant && sowState !== SowState.PregnantAndNursing) {
		return ExceptionMessage.VALIDATION_ERROR_INVALID_PREG_EVENT;
	}

	if (prevEvent.state === PregnancyState.Mated) {
		if (scanned.date! <= prevEvent.date!) {
			return ExceptionMessage.VALIDATION_ERROR_SCAN_SAME_DAY_AS_MATED;
		}
	}

	if (prevEvent.state === PregnancyState.Scanned) {
		const prevScan = Scanned.fromJS(prevEvent);
		if (prevScan.result === ScanResult.True) {
			return ExceptionMessage.VALIDATION_ERROR_SOW_ALREADY_PREGNANT;
		}
	}
	return '';
}

export async function matedValidation(
	validationSetup: IValidationSetup,
	mated: IMated,
	prevEvent: IPregnancyEvent,
	animalEvents: IPregnancyEvent[],
	sow: IStemAnimal,
	matingBatchNumber: string,
) {
	const sowState = getSowState(animalEvents);
	if (sowState !== SowState.Dry && sowState !== SowState.Nursing) {
		return ExceptionMessage.VALIDATION_ERROR_INVALID_PREG_EVENT;
	}

	if (!matingBatchNumber) {
		return ExceptionMessage.VALIDATION_ERROR_DATE_DOES_NOT_MATCH_MATING_BATCH;
	}

	if (prevEvent && prevEvent.state === PregnancyState.Averted && !(prevEvent as IAverted).isNursingSow) {
		const weaningToMatingInterval = validationSetup.validationIntervals!.find(
			x => x.type === PregnancyValidationType.AvertedToMating,
		);
		const isValidMsg = await WeaningToMatingCheck(
			prevEvent.date!,
			mated.date!,
			weaningToMatingInterval!,
			mated.stemAnimalId!,
		);
		if (isValidMsg !== '') {
			return isValidMsg;
		}
	}

	//Only allow mating before entrance if mating happens at entrance
	if (sow.entranceLitterNumber! < 1) {
		if (moment(mated.date!).isBefore(moment(sow.entranceDate!), 'day')) {
			return ExceptionMessage.VALIDATION_ERROR_FINISHED_DATE_MATING_DATE_YOUNG_FEMALE_ERR;
		}
	}

	//Check for age both if it is mated at entrance and if it is mated after entrance
	if (
		sow.entranceLitterNumber! <= 1 &&
		animalEvents.findIndex(pregEvent => pregEvent.state === PregnancyState.Mated) === -1
	) {
		if (sow.entranceType === 'entranceTypeFromYoungAnimal' && sow.idNumber && sow.birthDate) {
			if (validationSetup.validationIntervals) {
				const matingAgeInterval = validationSetup.validationIntervals.find(
					x => x.type === PregnancyValidationType.MatingAge,
				);
				const isValidMsg = await MatingAgeCheck(sow.birthDate!, mated.date!, matingAgeInterval!);
				if (isValidMsg !== '') {
					return isValidMsg;
				}
			}
		}
	}

	// if (sow.entranceKind === AnimalKind.Gilt && isEmpty(prevEvent)) {
	// 	const entranceDate = new Date(sow.entranceDate!);
	// 	const matedDate = new Date(mated.date!);
	// 	entranceDate.setHours(0, 0, 0, 0);
	// 	matedDate.setHours(0, 0, 0, 0);
	// 	if (entranceDate < matedDate) {
	// 		return ExceptionMessage.VALIDATION_ERROR_GILT_MATING_DATE;
	// 	}
	// }
	if (sowState === SowState.Nursing) {
		let dontAllow = await ShowConfirmAlert(
			localized(ExceptionMessage.VALIDATION_ERROR_YOU_ARE_MATING_A_ANIMAL_THAT_IS_NURSING),
		);
		if (!dontAllow) {
			//Don't show error message
			return 'ignore';
		}
	}

	return '';
}

export async function farrowingInProgressValidation(
	validationSetup: IValidationSetup,
	farrowingInProgress: IFarrowingInProgress,
	prevEvent: IPregnancyEvent,
	animalEvents: IPregnancyEvent[],
) {
	const sowState = getSowState(animalEvents);
	if (sowState !== SowState.Pregnant) {
		return ExceptionMessage.VALIDATION_ERROR_INVALID_PREG_EVENT;
	}

	const mated = animalEvents.find(x => x.state === PregnancyState.Mated && x.pregnancyId === prevEvent.pregnancyId);
	if (!mated) {
		return ExceptionMessage.VALIDATION_ERROR_INVALID_PREG_EVENT;
	}

	const matingToFarrowing = validationSetup.validationIntervals!.find(
		x => x.type === PregnancyValidationType.MatingToFarrowing,
	);
	const isValidMsg = await MatingToFarrowingCheck(mated.date!, farrowingInProgress.date!, matingToFarrowing!);
	if (isValidMsg !== '') {
		return isValidMsg;
	}

	return '';
}

export async function editValidationHelper(
	validationSetup: IValidationSetup,
	generalSettings: IGeneralSettings,
	pregnancyEventToEdit: IPregnancyEvent,
	currentPregPreviousEvent: IPregnancyEvent | undefined,
	animalPregs: IPregnancyEvent[] | undefined,
	sow?: IStemAnimal,
	matingBatchNumber?: string,
	useNucleusManagement?: boolean,
) {
	if (useNucleusManagement) {
		return '';
	}
	//Split pregnancy events in Previous events < eventToEdit <= Later events
	let sortedToBePrevious = sortPregnanciesByDate([...animalPregs!]).reverse();
	const index = sortedToBePrevious.findIndex(x => x.id === pregnancyEventToEdit.id);

	//Replace old element with new
	sortedToBePrevious[index] = pregnancyEventToEdit;
	const laterAndCurrentEvent = sortedToBePrevious.splice(index, animalPregs!.length);

	//Reverse, to get the latest first in array
	sortedToBePrevious.reverse();

	//Re-validate eventToEdit, and all later events
	for (const pregEvent of laterAndCurrentEvent) {
		const prevEvent = getPreviousEvent(sortedToBePrevious, pregEvent);
		const msg = await validationHelper(
			validationSetup,
			generalSettings,
			pregEvent,
			prevEvent,
			sortedToBePrevious,
			sow,
			matingBatchNumber,
		);

		if (msg === 'ignore' && pregEvent.id === pregnancyEventToEdit.id) {
			return msg;
		}
		if (msg !== '' && msg !== 'ignore') {
			return `${localizedDynamic(pregEvent.state!)}: ${localizedDynamic(msg)}`;
		} else {
			sortedToBePrevious.unshift(pregEvent);
		}
	}
	return '';
}
