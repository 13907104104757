import moment from 'moment';
import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker-cssmodules.min.css';
import 'react-datepicker/dist/react-datepicker.min.css';
import { Portal } from 'react-overlays';
import calendarGrey from 'shared/assets/src-assets/png/kalender_ikon_grey@4x.png';
import calendarLightGrey from 'shared/assets/src-assets/png/kalender_ikon_lightgrey@4x.png';
import calenderBlack from 'shared/assets/src-assets/png/kalender_ikon_black@4x.png';
import { setCorrectTimeStamp } from 'shared/helpers/date-helpers';
import { i18nTranslationKey, localized } from 'shared/state/i18n/i18n';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { SkioldImage } from '../../utils/svg/skiold-image';
import './skiold-date-picker.scss';

export interface DatePickerPropsFromParent {
	onDateChanged: (newDate: Date, itemFromParent?: any) => void;
	itemFromParent?: any;
	selectedDate: Date | undefined;
	theme?: 'light' | 'dark';
	color?: 'lightGrey' | 'grey' | 'black';
	showIcon?: boolean;
	text?: i18nTranslationKey;
	fontSize?: number;
	maxDate?: Date;
	minDate?: Date;
	includeTimeStamp?: boolean;
	isEndDate?: boolean;
	minimumDate?: Date | undefined;
	containerClassName?: string;
	textFieldClassName?: string;
	highlightDates?: any[];
	disabled?: boolean;
	openToDate?: Date;
	useMaxDate?: boolean;
	backwardDateSetEndTime?: boolean;
}
interface State {
	selectedDate: Date | undefined;
}
const CalendarContainer = ({ children }: any) => {
	return <Portal>{children}</Portal>;
};

const styles = {
	imageStyle: { marginLeft: 3, marginRight: 3 },
};

export class SkioldDatePicker extends React.PureComponent<DatePickerPropsFromParent, State> {
	public static defaultProps: Partial<DatePickerPropsFromParent> = {
		theme: 'light',
		includeTimeStamp: true,
		useMaxDate: true,
		isEndDate: false,
		containerClassName: '',
		textFieldClassName: '',
		highlightDates: [],
		disabled: false,
		minDate: new Date(-8640000000000000),
		openToDate: undefined,
	};

	public static getDerivedStateFromProps(nextProps: DatePickerPropsFromParent, prevState: State): Partial<State> {
		return {
			selectedDate: setCorrectTimeStamp(
				nextProps.selectedDate,
				nextProps.includeTimeStamp!,
				nextProps.isEndDate!,
				nextProps.backwardDateSetEndTime,
			),
		};
	}

	constructor(props: DatePickerPropsFromParent) {
		super(props);
		this.state = {
			selectedDate: setCorrectTimeStamp(
				props.selectedDate,
				props.includeTimeStamp!,
				props.isEndDate!,
				props.backwardDateSetEndTime,
			),
		};
	}

	public dateChanged = (val: Date) => {
		let date = setCorrectTimeStamp(
			moment(val).toDate(),
			this.props.includeTimeStamp!,
			this.props.isEndDate!,
			this.props.backwardDateSetEndTime,
		);
		this.setState({ selectedDate: date });
		if (date) {
			this.props.onDateChanged(date, this.props.itemFromParent);
		}
	};

	public render() {
		return (
			<ViewWeb className={`skiold-date-picker ${this.props.containerClassName}`}>
				<TextWeb className={`${this.props.theme === 'dark' ? 'text-black' : 'text-white'}`}>
					{this.props.text ? localized(this.props.text) + ':' : ''}
				</TextWeb>

				<DatePicker
					disabled={this.props.disabled}
					dateFormat="dd.MM.yy"
					onChange={this.dateChanged}
					selected={this.state.selectedDate}
					customInput={
						<DatepickerCustomInput
							theme={this.props.theme}
							color={this.props.color}
							showIcon={this.props.showIcon}
							textFieldClassName={this.props.textFieldClassName}
							disabled={this.props.disabled}
						/>
					}
					locale={localized('locale')}
					placeholderText={localized('ChooseDate')}
					{...(this.props.useMaxDate && { maxDate: this.props.maxDate ?? new Date() })}
					minDate={this.props.minDate}
					{...(this.props.minimumDate && { minDate: this.props.minimumDate })}
					popperContainer={CalendarContainer}
					highlightDates={this.props.highlightDates}
					openToDate={this.props.openToDate}
				/>
			</ViewWeb>
		);
	}
}

export interface DatePickerCustomInputPropsFromParent {
	//These are added internally in the DatePicer component
	onClick?: any;
	value?: any;
	showIcon?: boolean;
	textFieldClassName?: any;
	color?: 'lightGrey' | 'grey' | 'black';
	theme?: 'light' | 'dark';
	disabled?: boolean;
}

// tslint:disable-next-line:max-classes-per-file
class DatepickerCustomInput extends React.PureComponent<DatePickerCustomInputPropsFromParent> {
	public static defaultProps: Partial<DatePickerCustomInputPropsFromParent> = {
		theme: 'light',
		color: 'lightGrey',
		showIcon: true,
	};

	public render() {
		let calIcon =
			this.props.color === 'lightGrey'
				? calendarLightGrey
				: this.props.color === 'grey'
				? calendarGrey
				: calenderBlack;
		return (
			<div
				onClick={this.props.onClick}
				className={'date-picker-custom-input ' + (this.props.disabled ? 'isDisabled' : '')}
			>
				<TextWeb
					className={`text-container ${this.props.theme === 'dark' ? 'text-black' : 'text-white'} ${
						this.props.textFieldClassName
					}`}
				>
					{this.props.value}
				</TextWeb>
				{this.props.showIcon && (
					<SkioldImage extraStyle={styles.imageStyle} imageData={calIcon} height={24} width={24} />
				)}
			</div>
		);
	}
}
