import memoize from 'memoize-one';
import React from 'react';
import { Option } from 'react-dropdown';
import { connect } from 'react-redux';
import { LocationType, MoveToLocationTypes, WorkListType } from 'shared/api/api';
import { LocationModel } from 'shared/helpers/location-helper';
import { RefType } from 'shared/helpers/ref-type';
import { checkSaveWorkListStatus } from 'shared/helpers/work-list-helpers/general-work-list-helper';
import {
	getDataByLocationMoveFromPregToPregList,
	getDataByMatingBatchMoveFromPregToPregList,
	moveFromPregToPregListMapDispatchToProps,
	moveFromPregToPregListMapStateToProps,
	MoveFromPregToPregListProps,
	MoveFromPregToPregListState,
} from 'shared/helpers/work-list-helpers/move-to-helpers/move-from-preg-to-preg-list-helper';
import { MovingListTable } from 'shared/helpers/work-list-helpers/move-to-helpers/move-to-data-model';
import {
	checkSectionWorkLists,








	checkSectionWorkListsCorrectly, GenerateInitialData,
	generateMatingBatchSetup,





	getDataByBlankList, getLocationsForMoveToSetup,
	ResetMoveWorkListDataInStore,
	setMovingListPenInStore,
	setMovingListSectionInStore,
	shouldShowPen
} from 'shared/helpers/work-list-helpers/move-to-helpers/moving-lists-helper';
import { SharedAppState } from 'shared/state/store.shared';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { ShowConfirmAlert } from '../../skiold-alert/skiold-alert';
import '../list-setup.scss';
import MoveToListTable from './move-list-table';
import { getRows } from './move-lists-getrows';
import { saveMovingListData } from './moving-lists-setup-helper';

export class MoveFromPregToPregListTable extends React.PureComponent<
	MoveFromPregToPregListProps,
	MoveFromPregToPregListState
> {
	public SkioldTableRef: any;

	private generateData = memoize((moveFromPregToPregSetup, batchId, fromPenId) => this.getData(batchId, fromPenId));
	private generateDataWithSetState = memoize((moveToPregnantSetup, batchId, fromPenId, props) => {
		this.saveMovingListTableData(this.getData(batchId, fromPenId));
	});

	private generateFormRows = memoize(
		(
			moveFromPregToPregSetup,
			toPenId,
			toSectionId,
			locations,
			selectedHeaderSectionUsesPens,
			selectedBatch,
			fromLocation,
			fromPenId,
			fromSectionId,
			fromSectionUsesPens,
		) =>
			getRows(
				this.state.moveFromPregToPregSetup,
				this.state.matingBatchesOptions,
				this.state.selectedBatch,
				this.state.locationString,
				toPenId,
				this.state.toSectionId,
				this.state.locations,
				this.state.selectedHeaderSectionUsesPens,
				undefined,
				this.selectedMatingBatchChanged,
				undefined,
				this.selectedSectionChanged,
				this.selectedPenChanged,
				this.state.fromLocation,
				this.fromSelectedSectionChanged,
				this.fromSelectedPenChanged,
				this.state.fromPenId,
				this.state.fromSectionId,
				this.state.fromSectionUsesPens,
			),
	);

	public async CheckSavePregnancyEventListsStatus() {
		if (await checkSaveWorkListStatus(this.state.moveListData, ShowConfirmAlert)) {
			return await this.SaveWorklist();
		}
		return true;
	}
	constructor(props: MoveFromPregToPregListProps) {
		super(props);
		let setup = generateMatingBatchSetup(
			props.workListSettings,
			this.props.matingBatches,
			WorkListType.MoveFromPregToPregSetting,
		);

		this.state = {
			moveFromPregToPregSetup: setup.moveToSetup,
			matingBatchesOptions: setup.matingBatchesOptions,
			toSectionId: undefined,
			toPenId: undefined,
			fromSectionId: undefined,
			fromPenId: undefined,
			locationString: undefined,
			selectedBatch: setup.selectedOption,
			locations: undefined,
			fromLocation: undefined,
			selectedHeaderSectionUsesPens: false,
			fromSectionUsesPens: false,
			moveListData: [],
			moveListInitData: [],
		};
	}

	public componentDidUpdate() {
		this.generateDataWithSetState(
			this.state.moveFromPregToPregSetup,
			this.state.selectedBatch.value,
			this.state.fromPenId,
			this.props,
		);
	}

	public componentDidMount() {
		let data = GenerateInitialData(
			this.state.moveFromPregToPregSetup,
			new LocationModel(this.props.locations),
			LocationType.Pregnant,
			this.state.toPenId,
			this.state.selectedBatch,
			this.state.fromPenId,
			this.generateData,
		);
		this.setState({
			locations: data.filteredLocations,
			locationString: data.locationString,
			fromLocation: getLocationsForMoveToSetup(new LocationModel(this.props.locations), LocationType.Pregnant),
			moveListData: data.tableData,
			moveListInitData: data.tableData,
		});

		this.props.getWorkListSettingsBySiteId();
		this.props.matingBatchGetSyncData();
		this.props.pregnancyEventGetSyncData();
		this.props.stemAnimalGetSyncData();
		this.props.validationSetupGetSyncData();
		this.props.moveEventGetSyncData();
		this.props.getLocations();
		this.props.getFeedingCurves();
		this.props.getunitsData();
		this.props.getProcessEquipmentSyncData();
	}

	public async ResetWorkList() {
		if (this.state.moveFromPregToPregSetup.selectedType === MoveToLocationTypes.Blank) {
			let resetData = getDataByBlankList([]);
			this.setState({
				toSectionId: undefined,
				toPenId: undefined,
				moveListData: resetData,
				moveListInitData: resetData,
				selectedHeaderSectionUsesPens: false,
			});
		} else {
			let resetData = ResetMoveWorkListDataInStore(this.state.moveListData);
			this.setState({
				toSectionId: undefined,
				toPenId: undefined,
				moveListData: resetData,
				moveListInitData: resetData,
				selectedHeaderSectionUsesPens: false,
			});
		}
	}

	public async SaveWorklist() {
		const wasValid = await saveMovingListData(
			this.state.moveListData,
			this.props.siteId,
			this.state.toPenId,
			this.props.saveMoveEvent,
			this.saveMovingListTableData,
			this.props.esfExists,
			this.state.moveFromPregToPregSetup,
		);
		return wasValid;
	}

	public saveMovingListTableData = (moveListData: MovingListTable[]) => {
		this.setState({
			moveListData: moveListData,
			moveListInitData: moveListData,
		});
	};

	public saveMovingListTableDataTemporary = (moveListData: MovingListTable[]) => {
		this.setState({
			moveListData: moveListData,
		});
	};

	public setSection(section?: string) {
		if (section) {
			let { penId, usesPens } = checkSectionWorkListsCorrectly(section, this.state.toPenId);
			setMovingListSectionInStore(
				section,
				this.state.moveFromPregToPregSetup,
				this.state.moveListData,
				penId,
				usesPens,
				this.saveMovingListTableDataTemporary,
			);
			this.setState({ toSectionId: section, toPenId: penId, selectedHeaderSectionUsesPens: usesPens });
		} else {
			this.setState({ toSectionId: undefined, toPenId: undefined, selectedHeaderSectionUsesPens: false });
		}
	}

	public setPen(pen?: string) {
		if (pen !== '') {
			setMovingListPenInStore(
				pen,
				this.state.moveFromPregToPregSetup,
				this.state.moveListData,
				this.state.toPenId,
				this.saveMovingListTableDataTemporary,
			);
			this.setState({ toPenId: pen });
		}
	}

	public render() {
		return (
			<ViewWeb>
				<ViewWeb className="work-list">
					<ViewWeb className="view-container">
						<ViewWeb className="z-index-1000">
							{this.generateFormRows(
								this.state.moveFromPregToPregSetup,
								this.state.toPenId,
								this.state.toSectionId,
								this.state.locations,
								this.state.selectedHeaderSectionUsesPens,
								this.state.selectedBatch,
								this.state.fromLocation,
								this.state.fromPenId,
								this.state.fromSectionId,
								this.state.fromSectionUsesPens,
							)}
						</ViewWeb>
					</ViewWeb>
				</ViewWeb>
				<MoveToListTable
					movingListSetup={this.state.moveFromPregToPregSetup}
					saveWorkListDataTemporary={this.saveMovingListTableDataTemporary}
					filteredLocations={this.state.locations}
					data={this.state.moveListData}
					tableKey={'moveFromPregToPreg'}
					ref={this.setRef}
					showPen={shouldShowPen(this.state.locations)}
					validateSettingToSubtract={this.props.validationSettingPlan}
					sectionId={this.state.toSectionId}
					penId={this.state.toPenId}
					usesPens={this.state.selectedHeaderSectionUsesPens}
				/>
			</ViewWeb>
		);
	}
	private setRef = (m: any) => (m ? (this.SkioldTableRef = m) : {});
	private selectedSectionChanged = (selectedSectionId: string) => this.setSection(selectedSectionId);
	private selectedPenChanged = (selectedPenId: string) => this.setPen(selectedPenId);

	private fromSelectedSectionChanged = (selectedSectionId: string) => {
		let { penId, usesPens } = checkSectionWorkLists(selectedSectionId, this.state.fromPenId);
		if (penId) {
			this.generateDataWithSetState(
				this.state.moveFromPregToPregSetup,
				this.state.selectedBatch,
				penId,
				this.props,
			);
		}

		this.setState({
			fromSectionId: selectedSectionId,
			fromPenId: penId,
			fromSectionUsesPens: usesPens,
		});
	};
	private fromSelectedPenChanged = (selectedPenId: string) => {
		this.generateDataWithSetState(
			this.state.moveFromPregToPregSetup,
			this.state.selectedBatch,
			selectedPenId,
			this.props,
		);

		this.setState({
			fromPenId: selectedPenId,
		});
	};
	private selectedMatingBatchChanged = (option: Option) => {
		this.generateDataWithSetState(
			this.state.moveFromPregToPregSetup,
			option.value,
			this.state.fromPenId,
			this.props,
		);

		this.setState({
			selectedBatch: option,
		});
	};

	private getData(batchId: string | undefined, penId: string): MovingListTable[] {
		switch (this.state.moveFromPregToPregSetup.selectedType) {
			case MoveToLocationTypes.Location:
				return getDataByLocationMoveFromPregToPregList(
					this.props.pregnancyEvets,
					this.state.moveListData,
					penId,
					this.state.toSectionId,
					this.state.toPenId,
					this.state.moveFromPregToPregSetup,
					this.props.unitToPens,
					this.props.esfFeedingStatus,
					this.props.stations,
					this.props.validationSettingPlan
				);
			case MoveToLocationTypes.MatingBatch:
				return getDataByMatingBatchMoveFromPregToPregList(
					batchId,
					this.props.moveEvents,
					new LocationModel(this.props.locations),
					this.props.matingBatches,
					this.props.pregnancyEvets,
					this.state.moveListData,
					this.state.moveFromPregToPregSetup,
					this.state.toSectionId,
					this.state.toPenId,
					this.props.unitToPens,
					this.props.esfFeedingStatus,
					this.props.stations,this.props.validationSettingPlan
				);
			case MoveToLocationTypes.Blank:
				return getDataByBlankList(this.state.moveListData);
			default:
				return [];
		}
	}
}

export default connect<
	ReturnType<typeof moveFromPregToPregListMapStateToProps>,
	ReturnType<typeof moveFromPregToPregListMapDispatchToProps>,
	RefType,
	SharedAppState
>(moveFromPregToPregListMapStateToProps, moveFromPregToPregListMapDispatchToProps, null, { forwardRef: true })(
	MoveFromPregToPregListTable,
);
