import { Action } from 'redux';
import { DashboardSettings, IDashboardSettings } from 'shared/api/api';
import { SyncableSingleInitialState } from 'shared/state/models/syncable';
import { SharedAppState } from 'shared/state/store.shared';
import { isType } from 'typescript-fsa';
import { DashboardSettingsState } from '.';
import { siteChange } from '../profile/actions';
import * as actions from './actions';
import { GetDashBoardSettings } from './operations';

export const initialState: DashboardSettingsState = {
	...SyncableSingleInitialState,
};

const dashboardSettingsReducer = (
	state: DashboardSettingsState = initialState,
	action: Action,
): DashboardSettingsState => {
	if (isType(action, GetDashBoardSettings.fulfilled as any)) {
		if (action.payload) {
			return { ...state, entity: action.payload as DashboardSettings };
		}
	}

	if (isType(action, actions.getSyncData.started)) {
		state = { ...state, syncInProgress: true };
	}

	if (isType(action, actions.getSyncData.done)) {
		if (action.payload.result.datas) {
			state = {
				...state,
				entity: { ...state.entity, ...action.payload.result.datas },
			};
		}

		state = {
			...state,
			lastSyncDate: action.payload.result.syncTime!,
			syncInProgress: false,
		};
	}

	if (isType(action, actions.getSyncData.failed)) {
		state = { ...state, syncInProgress: false };
	}

	if (isType(action, actions.saveSettings.started)) {
		state = { ...state, update: action.payload };
		state = { ...state, entity: action.payload };
	}

	if (isType(action, actions.saveSettings.failed)) {
		if (action.payload.error.code === 500) {
			if (action.payload.error.prevEntity) {
				state = { ...state, entity: action.payload.error.prevEntity };
			} else {
				state = { ...state, entity: {} as IDashboardSettings };
			}
			state = { ...state, update: {} as IDashboardSettings };
		}

		return state;
	}

	if (isType(action, actions.saveSettings.done)) {
		if (!action.payload.params.isDeleted) {
			state = { ...state, entity: action.payload.params };
		}

		state = { ...state, update: {} as IDashboardSettings };

		return state;
	}

	if (isType(action, siteChange.done)) {
		state = initialState;
	}

	//Ensure Date objects are in fact date and not strings - redux-persist serializes dates to string but doesn't deserialize to dates again
	if (action.type === 'persist/REHYDRATE') {
		let a = (action as any) as { payload: SharedAppState; key: string };
		if (a.key === 'root') {
			let entity = DashboardSettings.fromJS({});
			let update = DashboardSettings.fromJS({});
			let lastSyncDate = new Date(-8640000000000000);

			if (a.payload && a.payload.dashboardSettings && a.payload.dashboardSettings.entity) {
				let ndt = DashboardSettings.fromJS({});
				ndt.init(a.payload.dashboardSettings.entity);
				entity = ndt;
			}

			if (a.payload && a.payload.dashboardSettings && a.payload.dashboardSettings.update) {
				let ndt = DashboardSettings.fromJS({});
				ndt.init(a.payload.dashboardSettings.update);
				update = ndt;
			}

			if (a.payload && a.payload.dashboardSettings && a.payload.dashboardSettings.lastSyncDate) {
				lastSyncDate = new Date(a.payload.dashboardSettings.lastSyncDate);
			}

			state = { ...state, entity, update, lastSyncDate };
		}
	}

	return state;
};

export default dashboardSettingsReducer;
