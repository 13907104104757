import { ITreatmentDefinition, IDrugType, DrugTypeType } from "shared/api/api";
import { localizedDynamic } from "shared/state/i18n/i18n";


export function getDrugsText(treatmentDefinition: ITreatmentDefinition | undefined, drugTypes: IDrugType[]) {
    if (!treatmentDefinition || !treatmentDefinition.subTreatments || !drugTypes) {
        return '';
    }

    let drugNames = new Array<string>();

    treatmentDefinition.subTreatments.forEach(subTreat => {
        const drugType = drugTypes.find(drug => drug.id === subTreat.drugTypeId);

        if (drugType && drugType.name) {
            drugNames.push(drugType.name);
        }
    });

    return drugNames.join(', ');
}

export function getDrugTypeTypeOptions() {
    return Object.keys(DrugTypeType).map(k => {
        return {
            label: localizedDynamic(DrugTypeType[k as any]),
            value: DrugTypeType[k as any]
        };
    });
}
