import React from 'react';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { WebAppState } from 'web/state/store.web';
import { IScanningListSetting, IScanningListSettingBase, ScanningListSetting, WorkListType } from 'shared/api/api';
import { SaveWorkListSetting } from 'shared/state/ducks/work-list-settings/operations';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { localized } from 'shared/state/i18n/i18n';
import { SkioldFormsWrapper } from '../../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from '../../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { SkioldCheckbox } from '../../skiold-components/skiold-checkbox/skiold-checkbox';
import { getAvailablityRow } from '../work-list-helper';
import { SkioldFormInput } from '../../skiold-components/skiold-input/skiold-form-input';
import { showAlert } from '../../skiold-alert/skiold-alert';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { SkioldFormIntegerInput } from '../../skiold-components/skiold-integer-input/skiold-form-integer-input';
import { SkioldButton } from '../../skiold-components/skiold-button/skiold-button';
import './scanning-list-setup.scss';

interface PropsFromParent {
	workListType: WorkListType;
}

const mapStateToProps = (state: WebAppState, props: PropsFromParent) => {
	return {
		scanningListSetting: state.workListSettings.entities.find(wl => wl.type === props.workListType),
		siteId: state.profile.active!.siteId,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	saveWorkListSetting: (scanningListSetting: IScanningListSetting) =>
		SaveWorkListSetting(scanningListSetting)(dispatch),
});

export interface State {
	scanningListSetting: IScanningListSettingBase;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

class ScanningListSetup extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			scanningListSetting: this.props.scanningListSetting
				? (this.props.scanningListSetting as IScanningListSettingBase)
				: this.init(),
		};
	}

	public toggleShowOnWeb = () => {
		this.setState(prevState => ({
			scanningListSetting: {
				...prevState.scanningListSetting,
				showOnWeb: !this.state.scanningListSetting.showOnWeb,
			},
		}));
	};

	public toggleShowOnApp = () => {
		this.setState(prevState => ({
			scanningListSetting: {
				...prevState.scanningListSetting,
				showOnApp: !this.state.scanningListSetting.showOnApp,
			},
		}));
	};

	public render() {
		return (
			<ViewWeb className="scanning-list-setup">
				<ViewWeb className="view-container">
					<TextWeb className="text-style">{localized('Setup')}</TextWeb>
					<SkioldFormsWrapper formRows={this.getFormRows()} containerClassName="wrapper-container" />

					{this.renderButtons()}
				</ViewWeb>
			</ViewWeb>
		);
	}

	public getUseLocationRow(): FormRow {
		return {
			name: localized('location'),
			component: (
				<SkioldCheckbox
					onClick={this.toggleUseLocation}
					isChecked={this.state.scanningListSetting.usesLocation}
				/>
			),
		};
	}

	public toggleUseLocation = () => {
		let scanningListSetting = { ...this.state.scanningListSetting };
		scanningListSetting.usesLocation = !scanningListSetting.usesLocation;
		this.setState({ scanningListSetting });
	};
	private init() {
		return ScanningListSetting.fromJS({
			type: this.props.workListType,
			siteId: this.props.siteId,
		});
	}

	private save = async () => {
		if (!this.validate(this.state.scanningListSetting)) {
			return;
		}
		this.props.saveWorkListSetting(this.state.scanningListSetting);
	};

	private getFormRows() {
		let formRows = new Array<FormRow>();

		formRows.push(this.getWorkListHeader());
		formRows.push(this.getWorkListNameRow());
		formRows.push(this.getDaysAfterMatingRow());
		formRows.push(this.getStateRow());
		formRows.push(this.getUseLocationRow());
		formRows.push(
			getAvailablityRow(
				this.toggleShowOnWeb,
				this.toggleShowOnApp,
				this.state.scanningListSetting.showOnWeb!,
				this.state.scanningListSetting.showOnApp!,
			),
		);

		return formRows;
	}

	private onSetupNameChange = (newName: string) => {
		this.valueInSetupChanged(newName, 'name');
	};

	private valueInSetupChanged(value: any, key: keyof IScanningListSettingBase) {
		this.setState(prevState => ({
			scanningListSetting: {
				...prevState.scanningListSetting,
				[key]: value,
			},
		}));
	}

	private weeksChanged = (newNumber: number | undefined) => {
		this.setState(prevState => ({
			scanningListSetting: {
				...prevState.scanningListSetting,
				daysAfterMating: newNumber ? newNumber * 7 : undefined!,
			},
		}));
	};

	private getWorkListNameRow(): FormRow {
		return {
			name: localized('name'),
			component: (
				<SkioldFormInput onChangeText={this.onSetupNameChange} text={this.state.scanningListSetting.name} />
			),
		};
	}

	private validate(listSetting: IScanningListSettingBase) {
		if (!listSetting.daysAfterMating || (listSetting.daysAfterMating && listSetting.daysAfterMating <= 0)) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_PREGNANT_DAYS_NOT_SET));
			return false;
		}
		return true;
	}

	private getWorkListHeader(): FormRow {
		return {
			header: localized(this.props.workListType),
		};
	}

	private getStateRow(): FormRow {
		return {
			name: localized('condition'),
			component: <TextWeb className="condition-container">{localized('Pregnant')}</TextWeb>,
		};
	}

	private getDaysAfterMatingRow(): FormRow {
		return {
			name: localized('weeksAfterMating'),
			component: (
				<SkioldFormIntegerInput
					onChangeNumber={this.weeksChanged}
					className="days-after-mating-input"
					text={
						this.state.scanningListSetting.daysAfterMating
							? Math.floor(this.state.scanningListSetting.daysAfterMating / 7)
							: undefined
					}
				/>
			),
		};
	}

	private renderButtons() {
		return (
			<ViewWeb className="view-button-container">
				<SkioldButton title={localized('Save')} onPress={this.save} />
			</ViewWeb>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ScanningListSetup);
