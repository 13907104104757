import { Sorting } from '@devexpress/dx-react-grid';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ProcessEquipment } from 'shared/api/api';
import DeleteIcon from 'shared/assets/src-assets/png/delete_icon.png';
import PenIcon from 'shared/assets/src-assets/png/pen_icon.png';
import UnitIcon from 'shared/assets/src-assets/png/Enheder_Grey.png'
import { GetProcessEquipmentData } from 'shared/state/ducks/process-equipment-data/operations';
import { RemoveProcessEquipment } from 'shared/state/ducks/process-equipments/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { SkioldModal } from 'web/view/components/skiold-components/skiold-modal/skiold-modal';
import SkioldTableGrid from '../skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { SkioldTouchableOpacity } from '../skiold-components/skiold-touchable-opacity';
import { SowListConstants } from '../stem-animal/animal-lists/table-constants';
import { SkioldImage } from '../utils/svg/skiold-image';
import { ViewWeb } from '../utils/web-view';
import CreateProcessEquipment from './create-process-equipment';
import './create-process-equipment.scss';
import UnitsTable from './unit-table';
import { ShowConfirmAlert } from '../skiold-alert/skiold-alert';

const mapStateToProps = (state: WebAppState) => {
	return {
		processEquipments: state.processEquipments.entities,
		siteId: state.profile.active ? state.profile.active.siteId : undefined,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	 removeProcessEquipment: (id: string) =>  RemoveProcessEquipment(id)(dispatch),
	 
	getProcessEquipmentSyncData: () =>GetProcessEquipmentData()(dispatch),
});

interface PropsFromParent {}

interface ProcessEquipmentTableState {
	columns: any[];
	columnExte: any[];
	processModalIsOpen: boolean;
	unitsModalIsOpen: boolean;
	processEquipmentIdToEdit: string;

}

type ProcessEquipmentTableProps = ReturnType<typeof mapStateToProps> &
	ReturnType<typeof mapDispatchToProps> &
	PropsFromParent;
export class ProcessEquipmentTable extends React.PureComponent<ProcessEquipmentTableProps, ProcessEquipmentTableState> {
	private defaultSorting = [{ columnName: 'processEquipment', direction: 'asc' }] as Sorting[];
	constructor(props: ProcessEquipmentTableProps) {
		super(props);
		this.state = {
			columns: this.generateColumns(),
			columnExte: this.generateColumnsExtensions(),
			processModalIsOpen: !this.props.processEquipments || this.props.processEquipments.length === 0,
			unitsModalIsOpen: false,
			processEquipmentIdToEdit: '',
			
		};
	}

	public componentDidMount() {
		if(this.props.siteId){
			this.props.getProcessEquipmentSyncData();
		}
	}

	public render() {
		return (
			<ViewWeb className="location-table-container">
			
					<SkioldTableGrid
					tableKey={'processEquipmentListTable'}
						columns={this.state.columns}
						data={this.props.processEquipments}
						ColumnExtensions={this.state.columnExte}
                        sortable={false}
                        filterable={false}
					/>

                
				<SkioldModal close={this.closeProcessModal} isOpen={this.state.processModalIsOpen} padding="0">
					<CreateProcessEquipment
						processEquipmentId={this.state.processEquipmentIdToEdit}
						closeModal={this.closeProcessModal}
					/>
				</SkioldModal>
				<SkioldModal className={'width-500'} close={this.closeProcessModal} isOpen={this.state.unitsModalIsOpen} padding="0">
					<UnitsTable
						processEquipment={this.props.processEquipments.find(pe=> pe.id === this.state.processEquipmentIdToEdit)! }
						closeModal={this.closeProcessModal}
					/>
				</SkioldModal>
			</ViewWeb>
		);
	}

	private closeProcessModal = () => {
		this.setState({ processModalIsOpen: false, unitsModalIsOpen: false });
	};

	public generateColumnsExtensions() {
		return [
			{
				columnName: 'edit',
				sortingEnabled: false,
				filteringEnabled: false,
				resizingEnabled: false,
				width: 72
			},
			{
                columnName: 'name',
                sortingEnabled: false,
				filteringEnabled: false,
				width: 130
			},
			{
                columnName: 'equipment',
                sortingEnabled: false,
				filteringEnabled: false,
				width: 130
			},
			{
				columnName: 'processUnit',
				sortingEnabled: false,
				filteringEnabled: false,
				resizingEnabled: false,
				width: 85
			},
			{
				columnName: 'code',
				sortingEnabled: false,
				filteringEnabled: false,
				resizingEnabled: false,
				width: 150
			},
			{
				columnName: 'remove',
				sortingEnabled: false,
				filteringEnabled: false,
				resizingEnabled: false,
				width: SowListConstants.iconWidth
			}
		];
	}

	private generateColumns() {
		const columns = [
			{
				name: 'edit',
				title: localized('Edit'),
				sortable: false,
				filterable: false,
				isFixedLeft: true,
				getCellValue: (row: ProcessEquipment) => (
					<SkioldTouchableOpacity className={'alignCenter'} onPress={() => this.editProcessEquipment(row.id!)}>
						<SkioldImage
							width={SowListConstants.iconSVGWidth}
							height={SowListConstants.iconSVGWidth}
							imageData={PenIcon}
						/>
					</SkioldTouchableOpacity>
				)
			},
			{
                sortable: false,
				filterable: false,
				name: 'name',
				title: localized('name')
			},
			{
                sortable: false,
				filterable: false,
				name: 'equipment',
				title: localized('processEquipment')
			},
			{
                sortable: false,
				filterable: false,
				name: 'processUnit',
				title: localized('units'),
				getCellValue: (row: ProcessEquipment) => (
					<SkioldTouchableOpacity className={'alignCenter'} onPress={() => this.openUnitsForProcessEquipment(row.id!)}>
						<SkioldImage
							width={SowListConstants.iconSVGWidth}
							height={SowListConstants.iconSVGWidth}
							imageData={UnitIcon}
						/>
					</SkioldTouchableOpacity>
				)
			},
			{
                sortable: false,
				filterable: false,
				name: 'code',
				title: localized('processCode')
			},
			{
                sortable: false,
				filterable: false,
				name: 'remove',
				title: ' ',
				getCellValue: (row: ProcessEquipment) => (
					<SkioldTouchableOpacity  onPress={() => this.removeProcessEquipment(row.id!)}>
						<SkioldImage
							width={SowListConstants.iconSVGWidth}
							height={SowListConstants.iconSVGWidth}
							imageData={DeleteIcon}
						/>
					</SkioldTouchableOpacity>
				)
			}
		];

		return columns;
	}

	private editProcessEquipment = (id: string) => {
        this.setState({processEquipmentIdToEdit: id, processModalIsOpen: true})
    };

	private removeProcessEquipment = async (id: string) => {
	 if(await ShowConfirmAlert(localized('removeProcessEquipment'))){
        this.props.removeProcessEquipment(id);
	 }

    };

	private openUnitsForProcessEquipment = (id: string) => {this.setState({processEquipmentIdToEdit:id , unitsModalIsOpen: true})};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ProcessEquipmentTable);
