import { NativeAppState } from 'native/state/store.native';
import { DistriwinFeedUnitType } from 'shared/api/api';
import { StoreContainer } from 'shared/state/store-container';
import { SharedAppState } from 'shared/state/store.shared';
import { WebAppState } from 'web/state/store.web';

export const getFeedUnitType = (state: SharedAppState | NativeAppState | WebAppState) => {
	const generalSettings = state.generalSettings;
	if (generalSettings && generalSettings.entity && generalSettings.entity.distriwinFeedUnitType) {
		return generalSettings.entity.distriwinFeedUnitType;
	}
	return DistriwinFeedUnitType.FEsoFEsv;
};

export const getFeedUnitTypeFromStore = () => {
	return getFeedUnitType(StoreContainer.getState());
};

export const getFuOrKg = () => {
	let state = StoreContainer.getState();
	return state.generalSettings.entity.showFuOrKg;
};

export const selectFeedingUnitType = (state: WebAppState) =>
	state.generalSettings && state.generalSettings.entity && state.generalSettings.entity.distriwinFeedUnitType
		? state.generalSettings.entity.distriwinFeedUnitType
		: DistriwinFeedUnitType.FEsoFEsv;
