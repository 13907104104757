import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { ResultPerLitterState } from '.';
import { siteChange } from '../../profile/actions';
import * as actions from './actions';
import { clearAnalysisData } from '../actions';

export const initialState: ResultPerLitterState = {
	data: undefined,
	loading: false,
};

const reducer = (
	state: ResultPerLitterState = initialState,
	action: Action
): ResultPerLitterState => {
	if (isType(action, actions.geData.started)) {
		state = { ...state, loading: true };
	}

	if (isType(action, actions.geData.done)) {
		state = {
			...state,
			data: action.payload.result,
			loading: false,
		};
	}
	if (isType(action, actions.geData.failed)) {
		state = { ...state, loading: false };
	}

	if (isType(action, siteChange.done)) {
		state = initialState;
	}

	if (isType(action, clearAnalysisData.done) ) {
		state = initialState;
	}

	return state;
};

export default reducer;
