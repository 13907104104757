import { Dispatch } from 'redux';
import {
	Device,
	IDevice,
	ISiteAuthorizationUpdate,
	IUserProfile,
	IUserProfile2,
	UserProfile,
	UserProfile2,
} from 'shared/api/api';
import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import { StoreContainer } from 'shared/state/store-container';
import * as Action from './actions';

export const Activate = (siteId: string) => {
	return (dispatch: Dispatch<any>) => {
		return AsyncOperationBuilder2(
			Action.activateProfileAsync,
			cl => cl.identityControllerV2_ActivateSiteId(siteId),
			siteId,
		)(dispatch);
	};
};

export const ActivateBySiteId = (siteId: string) => {
	return (dispatch: Dispatch<any>) => {
		return AsyncOperationBuilder2(
			Action.activateSiteIdAdmin,
			cl => cl.identityControllerV2_ActivateSiteIdAdmin(siteId),
			siteId,
		)(dispatch);
	};
};

export const GetProfile = () => {
	return AsyncOperationBuilder2(Action.getProfileAsync, cl => cl.identityControllerV2_Get(), {});
};

export const UpdateProfile = (profile: IUserProfile) => {
	return AsyncOperationBuilder2(
		Action.updateProfileAsync,
		cl => cl.identityControllerV2_Post((profile as unknown) as UserProfile2),
		UserProfile.fromJS(profile),
	);
};

export const ModuleChanged = (profile: ISiteAuthorizationUpdate) => {
	return async (dispatch: Dispatch<any>) => {
		dispatch(Action.moduleChanged.started(profile));
	};
};

export const UpsertDevice = (device: IDevice) => {
	return AsyncOperationBuilder2(Action.upsertDeviceAsync, cl => cl.device_Upsert(device as Device), device as Device);
};

export const RemoveDevice = (hardwareID: string) => {
	return AsyncOperationBuilder2(Action.removeDeviceAsync, cl => cl.device_RemoveDevice(hardwareID), hardwareID);
};

export function SiteChange() {
	return (dispatch: Dispatch<any>) => {
		dispatch(Action.siteChange.done({} as any));
	};
}

export function SetIsResettingState(isResetting: boolean) {
	return (dispatch: Dispatch<any>) => {
		dispatch(Action.setIsResettingState(isResetting));
	};
}

export function GetSyncData() {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;
	const lastSyncDate = state.profile.lastSyncDate;

	if (state.profile.syncInProgress) {
		return (dispatch: Dispatch<any>) => {
			return Promise.resolve();
		};
	}

	return AsyncOperationBuilder2(Action.getSyncData, client => client.userProfile_Sync(siteId, lastSyncDate), {
		siteId,
		lastSyncDate,
	});
}
