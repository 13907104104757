import { Option } from 'react-dropdown';
import { Dispatch } from 'redux';
import {
	Equipment,
	IMatingBatch,
	IMoveEvent,
	IMoveToPregnancyLocation,
	IPregnancyEvent,
	IStemAnimal,
	IUnitToPen,
	LocationType,
	PregnancyValidationType,
	SowState,
} from 'shared/api/api';
import { calculateDays } from 'shared/helpers/general-helpers';
import { getPenBySow, getStemAnimalPen, ILocationModel } from 'shared/helpers/location-helper';
import { memoizeLocation, memoizeValidationSettingPlan } from 'shared/helpers/memoize-getters/memoize-getters';
import { getCycleDays1 } from 'shared/helpers/pregnancy-helper/generel-pregnancy-helpers';
import { calculateAnimalKind, getState, getState1 } from 'shared/helpers/stemanimal-helper/stemanimal-helper';
import {
	checkLocation,
	checkMatingBatch,
	checkSectionWorkLists,
	getCycleDaysByPregnancyEvents,
	InsertOldDataSelectedSettings,
	setMovelistTableItemData,
	movingListsCheckCycleDaysPregnancyDaysByPregnancyEvents,
} from 'shared/helpers/work-list-helpers/move-to-helpers/moving-lists-helper';
import * as EsfStatusOperations from 'shared/state/ducks/esf-status/operations';
import { GetLocations } from 'shared/state/ducks/locations/operations';
import * as matingBatchOperation from 'shared/state/ducks/mating-batch/operations';
import * as moveEventOperation from 'shared/state/ducks/move-events/operations';
import { SaveMoveEvent } from 'shared/state/ducks/move-events/operations';
import * as pregnancyOperation from 'shared/state/ducks/pregnancy-events/operations';
import * as processEquipmentOperation from 'shared/state/ducks/process-equipments/operations';
import * as stemAnimalOperation from 'shared/state/ducks/stem-animals/operations';
import * as UnitsToPenOperations from 'shared/state/ducks/unit-to-pen/operations';
import * as validationSetupOperation from 'shared/state/ducks/validation-setup/operations';
import { GetSyncData } from 'shared/state/ducks/work-list-settings/operations';
import { SharedAppState } from 'shared/state/store.shared';
import { MovingListTable } from './move-to-data-model';

export interface MoveToPregnantListState {
	selectedBatch: Option;
	matingBatchesOptions: Option[];
	locationString: string | undefined;
	moveToPregnantSetup: IMoveToPregnancyLocation;
	locations: ILocationModel | undefined;
	selectedHeaderSectionUsesPens: boolean;
	toSectionId?: string;
	toPenId?: string;
	fromSectionId?: string;
	fromPenId?: string;
	fromLocation: ILocationModel | undefined;
	fromSectionUsesPens: boolean;
	moveListData: MovingListTable[];
	moveListInitData: MovingListTable[];
	isSyncing?: boolean;
	esfExists;
}

export const moveToPregnancyListMapStateToProps = (state: SharedAppState) => {
	return {
		workListSettings: state.workListSettings.entities,
		activeSows: state.stemAnimals.entities,
		pregnancyEvets: state.pregnancyEvents.entities,
		matingBatches: state.matingBatches.entities,
		// feedCurves: state.site.site.feedCurves,
		siteId: state.profile.active!.siteId!,
		moveEvents: state.moveEvents.entities,
		locations: memoizeLocation(
			state.locations.buildings,
			state.locations.sections,
			state.locations.pens,
			state.locations.valves,
		),
		availableCurves: state.esfStatus.availableCurves,
		validationSetup: state.validationSetup.entity,
		processEquipments: state.processEquipments.entities,
		validationSettingPlan: memoizeValidationSettingPlan(
			state.validationSetup.entity,
			PregnancyValidationType.MatingToFarrowing,
		),
		esfExists: state.processEquipments.entities.find(pq => pq.equipment === Equipment.ESF) !== undefined,
		unitToPens: state.unitToPenData.data,
	};
};

export const moveToPregnancyListMapDispatchToProps = (dispatch: Dispatch) => ({
	saveMoveEvent: (moveEvent: IMoveEvent) => SaveMoveEvent(moveEvent)(dispatch),
	getWorkListSettingsBySiteId: () => GetSyncData()(dispatch),
	pregnancyEventGetSyncData: () => pregnancyOperation.GetSyncData()(dispatch),
	stemAnimalGetSyncData: () => stemAnimalOperation.GetSyncData()(dispatch),
	matingBatchGetSyncData: () => matingBatchOperation.GetSyncData()(dispatch),
	validationSetupGetSyncData: () => validationSetupOperation.GetSyncData()(dispatch),
	moveEventGetSyncData: () => moveEventOperation.GetSyncData()(dispatch),
	getLocations: () => GetLocations()(dispatch),
	getFeedingCurves: () => EsfStatusOperations.GetEsfFeedCurveSyncData()(dispatch),
	getunitsData: () => UnitsToPenOperations.GetSyncData()(dispatch),
	getProcessEquipmentSyncData: () => processEquipmentOperation.GetSyncData()(dispatch),
});

export type MoveToPregnantListProps = ReturnType<typeof moveToPregnancyListMapStateToProps> &
	ReturnType<typeof moveToPregnancyListMapDispatchToProps>;

export function getDataByMatingBatchMoveToPregnancyList(
	batchId: string | undefined,
	moveEvents: IMoveEvent[],
	locations: ILocationModel,
	matingBatches: IMatingBatch[],
	pregEvents: { [key: string]: IPregnancyEvent[] },
	moveListData: MovingListTable[],
	moveToPregnantSetup: IMoveToPregnancyLocation,
	toSectionId: string | undefined,
	toPenId: string | undefined,
	unitToPens: IUnitToPen[] | undefined,
	activeSows: IStemAnimal[],
	validateSettingToSubtract: number | undefined,
) {
	let workListData = [] as MovingListTable[];
	const batch = matingBatches.find(matingBatch => matingBatch.id === batchId);
	let isMoveLocationEsf = toPenId && unitToPens && unitToPens.find(utp => utp.penId === toPenId) ? true : false;
	if (batch) {
		for (let sow of activeSows) {
			let state = getState(sow.id!);
			let animalPregEvents = pregEvents[sow.id!];
			if (
				!animalPregEvents ||
				state !== SowState.Pregnant ||
				checkLocation(getStemAnimalPen(sow, moveEvents, locations.pens), locations, LocationType.Pregnant)
			) {
				continue;
			}
			let pregnancyEvents = checkMatingBatch(pregEvents[sow.id!], batch);
			if (pregnancyEvents === undefined || !pregnancyEvents.lastestPregEvent) {
				continue;
			}
			let moveToWorkListItem = new MovingListTable();
			const cycleDays= calculateDays(pregnancyEvents.lastestPregEvent.date!, new Date());
			const negativeCycleDays = validateSettingToSubtract && cycleDays
			? cycleDays - validateSettingToSubtract : 0
			setMovelistTableItemData(
				sow,
				moveToWorkListItem,
				cycleDays,negativeCycleDays,
			);

			moveToWorkListItem.animalKind = calculateAnimalKind(sow);
			let { usesPens } = checkSectionWorkLists(toSectionId, toPenId);
			moveToWorkListItem.sectionId = toSectionId;
			moveToWorkListItem.penId = toPenId;
			moveToWorkListItem.usesPens = usesPens;
			moveToWorkListItem.isMoveLocationEsf = isMoveLocationEsf;
			workListData.push(moveToWorkListItem);
		}
		InsertOldDataSelectedSettings(workListData, moveListData);
	}
	return workListData;
}

export function getDataByLocationMoveToPregnantList(
	pregEvents: { [key: string]: IPregnancyEvent[] },
	moveListData: MovingListTable[],
	penId: string,
	toSectionId: string | undefined,
	toPenId: string | undefined,
	moveToPregnantSetup: IMoveToPregnancyLocation,
	unitToPens: IUnitToPen[] | undefined,
	activeSows: IStemAnimal[],
	validateSettingToSubtract: number | undefined,
) {
	let data: MovingListTable[] = [];
	let isMoveLocationEsf = toPenId && unitToPens && unitToPens.find(utp => utp.penId === toPenId) ? true : false;

	for (let sow of activeSows) {
		let state = getState(sow.id!);
		let animalPregEvents = pregEvents[sow.id!];
		if (!animalPregEvents || state !== SowState.Pregnant) {
			continue;
		}
		let pen = getPenBySow(sow);
		if (pen === undefined || pen.id !== penId) {
			continue;
		}
		let moveToWorkListItem = new MovingListTable();
		const cycleDays = getCycleDaysByPregnancyEvents(pregEvents[sow.id!]);
		const negativeCycleDays = validateSettingToSubtract && cycleDays
		? cycleDays - validateSettingToSubtract : 0
		setMovelistTableItemData(sow, moveToWorkListItem, cycleDays, negativeCycleDays);
		let { usesPens } = checkSectionWorkLists(toSectionId, penId);
		moveToWorkListItem.sectionId = toSectionId;
		moveToWorkListItem.penId = toPenId;
		moveToWorkListItem.usesPens = usesPens;
		moveToWorkListItem.isMoveLocationEsf = isMoveLocationEsf;
		moveToWorkListItem.animalKind = calculateAnimalKind(sow);
		data.push(moveToWorkListItem);
	}
	InsertOldDataSelectedSettings(data, moveListData);
	return data;
}

export function getDataByPregnancyDaysMoveToPregnancyList(
	moveEvents: IMoveEvent[],
	locations: ILocationModel,
	pregEvents: { [key: string]: IPregnancyEvent[] },
	moveListData: MovingListTable[],
	moveToSetup: IMoveToPregnancyLocation,
	moveToPregnantSetup: IMoveToPregnancyLocation,
	toSectionId: string | undefined,
	toPenId: string | undefined,
	unitToPens: IUnitToPen[] | undefined,
	activeSows: IStemAnimal[],
	validateSettingToSubtract: number | undefined,
) {
	let data: MovingListTable[] = [];
	let isMoveLocationEsf = toPenId && unitToPens && unitToPens.find(utp => utp.penId === toPenId) ? true : false;
	for (let sow of activeSows) {
		if (!sow.id) {
			continue;
		}

		let state = getState(sow.id!);
		let animalPregEvents = pregEvents[sow.id!];
		if (
			!animalPregEvents ||
			state !== SowState.Pregnant ||
			checkLocation(getStemAnimalPen(sow, moveEvents, locations.pens), locations, LocationType.Pregnant)
		) {
			continue;
		}
		let cycleDays = movingListsCheckCycleDaysPregnancyDaysByPregnancyEvents(moveToSetup, pregEvents[sow.id]);
		if (cycleDays === undefined) {
			continue;
		}
		let moveToWorkListItem = new MovingListTable();
		const negativeCycleDays = validateSettingToSubtract && cycleDays
			? cycleDays - validateSettingToSubtract : 0
		setMovelistTableItemData(sow, moveToWorkListItem, cycleDays, negativeCycleDays);
		moveToWorkListItem.animalKind = calculateAnimalKind(sow);
		let { usesPens } = checkSectionWorkLists(toSectionId, toPenId);
		moveToWorkListItem.sectionId = toSectionId;
		moveToWorkListItem.penId = toPenId;
		moveToWorkListItem.usesPens = usesPens;
		moveToWorkListItem.isMoveLocationEsf = isMoveLocationEsf;
		data.push(moveToWorkListItem);
	}
	InsertOldDataSelectedSettings(data, moveListData);
	return data;
}

export function getMoveToPregItemByAnimal(
	sow: IStemAnimal,
	pregEvents: IPregnancyEvent[],
	moveEvents: IMoveEvent[],
	locations: ILocationModel,
	toPenId: string | undefined,
	validateSettingToSubtract: number | undefined,
) {
	let state = getState1(sow.id!, pregEvents);
	if (
		!pregEvents ||
		state !== SowState.Pregnant ||
		checkLocation(getStemAnimalPen(sow, moveEvents, locations.pens), locations, LocationType.Pregnant)
	) {
		return undefined;
	}
	let cycleDays = getCycleDays1(sow.id!, pregEvents);
	let moveToWorkListItem = new MovingListTable();
	const negativeCycleDays = validateSettingToSubtract && cycleDays
			? cycleDays - validateSettingToSubtract : 0
	setMovelistTableItemData(sow, moveToWorkListItem, cycleDays, negativeCycleDays);
	moveToWorkListItem.penId = toPenId;
	return moveToWorkListItem;
}
