export default {
	// General terms
	SkioldDigital: 'SKIOLD digital',
	Clear: 'Ryd',
	locale: 'da-dk',
	reset: 'Nulstil',
	site: 'Bedrift',
	enterprise: 'Virksomhed',
	building: 'Bygning',
	section: 'Sektion',
	buildingShort: 'Byg',
	sectionShort: 'Sek',
	Unit: 'Enhed',
	Save: 'Gem',
	Show: 'Vis',
	Edit: 'Rediger',
	Handled: 'Håndteret',
	Delete: 'Slet',
	Cancel: 'Annuller',
	Count: 'Antal',
	Weight: 'Vægt',
	AnimalWeight: 'Vægt gris',
	FeedWeight: 'Vægt foder',
	Result: 'Resultat',
	Saved: 'Gemt',
	Overdue: 'Overskredet',
	Print: 'Print',
	Today: 'I dag',
	Tomorrow: 'I morgen',
	SaveChanges: 'Gem ændringer',
	ChooseDate: 'Vælg dato',
	stable: 'Stald',
	pen: 'Sti',
	ERROR: 'FEJL',
	Confirm: 'Bekræft',
	Close: 'Luk',
	avg: 'Gns.',
	Gender: 'Køn',
	Done: 'Færdig',
	Waiting: 'Venter',
	Running: 'Kører',
	Paused: 'Pauset',
	Cancelled: 'Annulleret',
	Deleted: 'Slettet',
	kg: 'kg',
	AreYouSureYouWantToCancel: 'Er du sikker på, at du vil annullere?',
	NoProcessesText: 'Du har ikke adgang til nogen processer i dette modul...',
	PleaseWait: 'Vent venligst!',
	Saving: 'Gemmer',
	Retry: 'Forsøg igen',
	Ok: 'Ok',
	TableNoData: 'Ingen indhold i tabellen',
	Previous: 'Forrige',
	Next: 'Næste',
	Loading: 'Indlæser',
	January: 'Januar',
	February: 'Februar',
	March: 'Marts',
	April: 'April',
	May: 'Maj',
	June: 'Juni',
	July: 'Juli',
	August: 'August',
	September: 'September',
	October: 'Oktober',
	November: 'November',
	December: 'December',
	PunctualDaySuccessor: '.',
	Animals: 'Dyr',
	week: 'Uge',
	from: 'Fra',
	to: 'Til',
	ml: 'ml',
	outOf: 'ud af',
	back: 'Tilbage',
	failed: 'fejlede',
	bluetooth: 'Bluetooth',
	WillYouContinue: 'Vil du forsætte?',
	PlanAndGoal: 'Plan/mål',
	DefaultWeaningWeight: 'Gns. vægt ved fravænning',
	totalWeanedInPeriod: 'Antal fravænnede i perioden',
	totalWeightInPeriod: 'Total vægt for perioden',
	InitialsShort: 'Init',
	Email: 'Mailadresse',
	or: '{{area}} eller {{area2}}',
	PrintGeneralData: 'Generelle data',
	PrintMomDadData: 'Far/Mor data',

	//Countries
	Denmark: 'Danmark',
	Australia: 'Australien',
	Taiwan: 'Taiwan',
	Sweden: 'Sverige',
	Ecuador: 'Ecuador',
	Norway: 'Norge',
	France: 'Frankrig',
	'South Africa': 'Sydafrika',

	// Home2 module icons
	developer: 'Udvikler',
	order: 'Ordre',
	deliveries: 'Leveringer',
	status: 'Status',
	settings: 'Indstillinger',
	treatments: 'Behandlinger',
	alarms: 'Alarmer',
	climate: 'Klima',
	warnings: 'Advarsler',
	stock: 'Lager',
	plannedOrders: 'Planlagte ordrer',
	reOrders: 'Genbestillinger',
	unmanagedOrders: 'Ubehandlede ordrer',
	plannedDeliveries: 'Planlagte leverancer',
	unmanagedDeliveries: 'Ubehandlede leverancer',
	feedingHistory: 'Foder historik',
	profile: 'Profil',
	online: 'Online',
	offline: 'Offline',
	activities: 'Aktiviteter',
	tasks: 'Opgaver',
	silos: 'Silos',
	sows: 'Søer',
	sowLardWidth: 'Spækmål',
	lardScanning: 'Spæk scanning',
	allocateWeight: 'Fordel vægt',
	pigs: 'Grise',
	distriwin: 'Distriwin',

	// Settings
	SowManagementSetup: 'Søer',
	WeanerManagementSetup: 'Klimagrise',
	FinisherManagementSetup: 'Slagtegrise',
	userManagementSettings: 'Brugerhåndtering',

	'Key performance indicators': 'Nøgletal',
	Value: 'Værdi',

	// Modules
	'Sow Module': 'Søer',
	'Climatepigs Module': 'Klimagrise',
	'Finisher Module': 'Slagtegrise',
	'Settings Module': 'Indstillinger',
	'Status Module': 'Statusmodul',
	'Developer Module': 'Udviklermodul',
	'App Info Module': 'Information',
	'Adjust Feed': 'Juster foder',
	'Pig management': 'Pig management',
	ControllerStatus: 'Kontroller status',
	'Delivery Module': 'Levering',
	'Delivery In': 'Levering ind',
	'Delivery Out': 'Levering ud',
	'Stock Module': 'Lagermodul',
	'Work list': 'Arbejdslister',
	'Feeding stock': 'Foderlager',
	'Sow Card': 'Sokort',
	Controllers: 'Kontrollere',
	CreateTreatment: 'Opret behandling',
	TreatmentList: 'Behandlings liste',
	'Climate Module': 'Klimamodul',
	'Warning Module': 'Advarsler',
	'Alarm Module': 'Alarmer',
	Events: 'Hændelser',
	addMedicine: 'Tilføj medicin',
	addMedicineSow: 'Tilføj medicin',
	addMedicineWeaner: 'Tilføj medicin',
	addMedicineFinisher: 'Tilføj medicin',
	registerTreatmentWeaner: 'Opret behandling',
	treatmentListWeaner: 'Genbehandlinger',
	registerTreatmentFinisher: 'Opret behandling',
	treatmentListFinisher: 'Genbehandlinger',
	ClimateEvents: 'Hændelser',
	FinisherEvents: 'Hændelser',
	weanerPigsEvents: 'Hændelser',
	finisherPigsEvents: 'Hændelser',
	pregnancyEventLists: 'Hændelse liste',
	finishersManagement: 'Management Slagtegrise',
	sowManagement: 'Management Søer',
	weanersManagement: 'Management Klimagrise',
	appFinishersManagement: 'Slagtegrise',
	appSowManagement: 'Søer',
	appWeanersManagement: 'Klimagrise',
	adminPanel: 'Admin',
	AnalysisProcessFinisher: 'Analyse',
	DashboardProcessFinisher: 'Dashboard',
	AnalysisProcessWeaner: 'Analyse',
	DashboardProcessWeaner: 'Dashboard',

	// Sow module processes
	addsow: 'Tilføj so',
	removesow: 'Fjern so',
	movesow: 'Flyt so',
	registertreatment: 'Opret behandling',
	startadjustfeed: 'Juster foder',
	startadjustfeedSow: 'Juster foder',
	startadjustfeedFinisher: 'Juster foder',
	startadjustfeedWeaner: 'Juster foder',
	retryAdjustFeedMenuTitle: 'Fejl Juster Foder',
	assigntask: 'Tildel opgave',
	treatmentlists: 'Behandlingslister',
	departurelist: 'Afgangs liste',
	DepartureWorkListSetting: 'Afgangs liste',
	events: 'Hændelser',
	createTreatment: 'Opret Behandling',
	treatmentList: 'Genbehandlinger',
	TreatmentAppList: 'Opret behandling/genbehandling',
	AddMedicineApp: 'Medicin tilførsel',
	TreatmentWebList: 'Behandlings-/vaccine definitioner',
	sowCard: 'So kort',
	foodlists: 'Foder lister',
	analysisandreports: 'Analyse & rapporter',

	// Deliveries modules processes
	deliveryIn: 'Leverance IND',
	deliveryOut: 'Leverance UD',

	// language&profile
	language: 'Sprog',
	english: 'engelsk',
	swedish: 'svensk',
	danish: 'dansk',
	change: 'Skift',
	name: 'Navn',
	en: 'Engelsk',
	da: 'Dansk',
	logout: 'Log ud',
	Login: 'Log ind',
	CHANGEPASSWORD: 'Skift kodeord',
	EDITPROFILE: 'Rediger profil',

	// overlay menu icons
	OVERLAYPIG: 'Klimagrise og slagtegrise',
	OVERLAYSOW: 'Søer',
	OVERLAYFINISHER: 'Slagtegrise',
	OVERLAYWEANER: 'Klimagrise',
	OVERLAYSTOCK: 'Lager',
	OVERLAYSETTINGS: 'Indstillinger',
	OVERLAYADMIN: 'Admin',
	OVERLAYSTABLE: 'Stald',
	OVERLAYFEEDMILLS: 'Fodermøller',
	OVERLAYVALVES: 'Ventiler',
	OVERLAYNUMBERS: 'Tal',
	OVERLAYOUTPUT: 'Output',
	OVERLAYGROWTH: 'Vækst',
	OVERLAYCLIMATE: 'Klima',
	OVERLAYREPORTS: 'Reporter',
	return: 'retur',
	USER_MANAGEMENT: 'Brugerhåndtering',
	NOACCESSROUTE: 'Du har ikke adgang til denne side.',

	// Admin menu
	ADMIN_DEPARTURE_REASONS: 'Afgangs årsager',
	ADMIN_DIAGNOSES: 'Diagnoser',
	ADMIN_DIAGNOSESCATEGORIES: 'Diagnose kategorier',
	ADMIN_MODULE_LICENCES: 'Modul licenser',
	ADMIN_DRUG_TYPES: 'Præparater typer',
	ADMINROUTE: 'Dette er en admin side.',
	ADMIN_FEED_REGISTRATIONS: 'Foder registreringer',
	ADMIN_BREED_TABLE: 'Race tabel',

	// Sow Management menues
	SOWANIMALS: 'Dyr',
	SOWEVENTS: 'HÆNDELSER',
	SowEvents: 'Hændelser',
	EVENTS: 'Hændelser',
	SOWTREATMENTS: 'Behandlinger',
	SOWMEDICIN: 'Medicin',
	SOWDEPARTUREREASONS: 'Afgangsårsager',
	SOWSETTINGS: 'Indstillinger',

	//Pig management menues
	PIGSETTINGS: 'Indstillinger',

	//Diagnose Picker
	selectDiagnose: 'Vælg diagnose/vaccination',

	// Adjust feed process
	adjustfeed: 'juster foder',
	retryAdjustFeed: 'Fejl Juster Foder',
	fetchDataFromDistriwin: 'Hent data fra distriwin',
	FetchDataFromDistriwinConfirmDialog:
		'Du er nu i gang med at hente nyeste data på alle ventiler i Distriwin. Opdateringen kan tage 5-10 minutter. Vil du forsætte?',

	Time: 'Klokken',
	Sows: 'Søer',
	Pigs: 'Grise',
	FE_Day_Pig: 'FE/dag/gris',
	FE_Day_Sow: 'FE/dag/so',
	Avr_Day: 'Gns./dag',
	'CONST DEV': 'FAST',
	DEV: 'AFV.',
	DEVIATION: 'AFVIGELSE',
	DEVIATIONDAYS: 'AFVIGELSE DAGE',
	DEVPERCENT: 'AFV%',
	DEVIATIONDAYSPERCENT: 'AFV% - DAGE',
	DAYS: 'DAGE',
	WEEK: 'Uge',
	SECTION: 'SEKTION',
	startroute: 'Start rute',
	FEEDINGCURVE: 'Kurve',
	UNNAMEDFEEDINGCURVE: 'Unavngivet',
	NoHistoryAvailable: 'Der er ingen historik tilgængelig',
	FeedingCurveAndRecept: 'Kurve/recept',
	Recipe: 'Recept',
	wentThrough: 'gik igennem',

	ConstantDeviation: 'Fast afvigelse',
	Deviation: 'Afvigelse',
	DeviationDays: 'Afvigelse dage',
	FeedingCurve: 'Foderkurve',
	SowCycle: 'Cyklus dage',
	PenDays: 'Sti dage',
	PigCount: 'Antal grise',
	pigCount: 'Antal grise',
	PigletCount: 'Antal pattegrise',
	PrevValue: 'Gamle',
	NewValue: 'Ny',
	Property: 'Type',
	NoAdjustmentsToShow: ' Ingen justeringer at vise',
	SkipFeeding: 'Spring fodring over',

	//Retry adjust feed
	deleteMarked: 'Slet markerede',
	moveMarked: 'Flyt markerede',
	SureDeleteMarked: 'Er du sikker på at du vil slette alle markerede?',
	sendMarked: 'Send markerede',
	fullSyncFailedMessage: 'Prøv igen, eller kontakt SKIOLD',

	// warnings and alarms
	RECENT_WARNINGS_CAPS: 'ADVARSLER',
	RECENT_ALARMS_CAPS: 'ALARMER',
	details: 'Detaljer',
	DistriWin: 'Udfodring',
	FlexMixPro: 'FoderMøller',

	addwidget: 'TILFØJ WIDGET',
	nowidget: 'Du har ikke flere widgets tilgængelige!',
	HISTORY: 'HISTORIK',
	HISTORYLast7Days: 'HISTORIK sidste 7 dage',
	'ADJUST FEED': 'JUSTER FODER',
	'SELECT SECTION': 'VÆLG SEKTION',

	'Recent Alarms': 'Alarmer',
	'Recent Warnings': 'Advarsler',
	Date: 'Dato',
	retentionDate: 'Slagtefrist dato',
	Event: 'Begivenhed',
	History: 'Historie',
	'Alarms by Definition': 'Alarmer pr Definition',
	'Warnings by Definition': 'Advarsler pr Definition',
	'total alarms': 'Antal alarmer',
	'total warnings': 'Antal advarsler',
	'total active time': 'Total tid i aktiv',
	'average active time': 'Gennemsnitlig tid i aktiv',
	'Feeding System': 'Fodersystem',
	'Milling System': 'Fodermøllesystem',
	slaughterRetention: 'Slagtefrist',
	// Events
	selectevent: 'Vælg hændelse',
	entrance: 'Indgang',
	entry: 'Indgang',
	departure: 'Afgang',
	select: 'Vælg',
	entranceGender: 'Køn',
	animalNumber: 'Dyrnr.',
	animalNumberShorten: 'Dyrnr.',
	animalNumbers: 'Dyrnumre',
	setEntranceDateToMatingDate: 'Skal indgangsdatoen sættes lig løbedatoen ',

	// Entrance
	entranceType: 'Indgangstype',
	entranceTypeBought: 'Købt',
	entranceEventTypeBought: 'Købt',
	entranceTypeFromYoungAnimal: 'Fra ungdyr',
	entranceTypeFromPolt: 'Fra polte',
	selectEntranceType: 'Vælg indgangstype',
	entranceMating: 'Løbning',
	ChooseIdNumber: 'Vælg Id nummer',
	Litters: 'Kuld',
	LitterNr: 'KuldNr.',
	EntrancePrice: 'Indgangspris',
	CreateAnimal: 'Opret dyr',
	PrintSowTable: 'Print Sotavle',
	PrintList: 'Print liste',
	searchYoungAnimal: 'Søg ungdyr',
	youngAnimalNotFound: 'Ingen ungdyr fundet',
	entranceTypeFromYoungFemale: 'Fra polt',
	ValidateAnimalNumberLength: 'Dyrnr. må maks være 6 cifre for at validere op mod Danbred',
	ValidateWrongUsageNumber: 'Ungdyret har brugskode "{{area}}" og er derfor ikke godkendt til KerneStyring',
	ValidateWrongIdNumber: 'IDnummer skal være {{area}} tegn og er derfor ikke godkendt til {{area2}}',
	ValidateAge: 'Dyret skal være minimum {{area}} dage gammel, og er derfor ikke godkendt til {{area2}}',
	ExistingTransponder: 'Eksisterende transponder',
	NewTransponder: "Ny transponder",
	ChangeTransponderNumber: "Skift transponder",
	// Location Picker
	choosenLocationIsEmpty: 'Den valgte lokation er tom',
	chooseStableSection: 'Vælg stald - sektion',
	StableSection: 'Stald - sektion',
	FromStableSection: 'Fra Stald - sektion',
	MoveToLocation: 'Flyt til lokation',
	FromLocation: 'Fra lokation',
	ToLocation: 'Til lokation',
	FromPen: 'Fra sti',
	ToPen: 'Til sti',
	choosePen: 'Vælg sti',
	'Select Section': 'Vælg Sektion',
	SITEANDFARM: 'GÅRD',
	BUILDING: 'BYGNING',
	country: 'Land',
	cvRnum: 'CVRnr.',
	chRnum: 'CHRnr.',
	ownerName: 'Navn',
	ownerAddress: 'Addresse',
	ownerZipCode: 'Postnr.',
	ownerCity: 'By',
	siteAddress: 'Addresse',
	siteZipCode: 'Postnr.',
	siteName: 'Navn',
	siteCity: 'By',
	farmName: 'Gårdnavn',
	address: 'Adressse',
	postalCode: 'Postnummer',
	ZipCode: 'Postnr.',
	Owner: 'Ejer',
	city: 'By',
	SiteName: 'Gårdnavn',
	chrNum: 'CHRnr.',
	createBuilding: 'Opret Bygning/site',
	createSite: 'Opret farm',
	number: 'Nummer',
	units: 'Enheder',
	amountOfSections: 'Antal sektioner',
	startNumber: 'Startnummer',
	amountOfPens: 'Antal stier',
	capacity: 'Kapacitet',
	addSections: 'Tilføj Sektioner',
	addPens: 'Tilføj stier',
	chooseCountry: 'Vælg land',
	isUsingQRCode: 'Bruger QR kode',
	isUsingSections: 'Bruger sektioner',
	isUsingPens: 'Bruger stier',
	letterBehindPen: 'Bogstav bagved sti',
	NameBehindStartNumber: 'Navn (Sættes foran sektionsnummer)',
	longmg: 'pr kilo',
	longml: 'pr liter',

	PEN: 'STI',
	VALVE: 'VENTIL',
	SOWS: 'SØER',
	ANIMALS: 'DYR',
	FE_DAY: 'FE/DAG',
	FIXED: 'FAST',
	FIXEDPERCENT: 'FAST%',
	fixedPercent: 'FAST %',
	WEIGHT: 'VÆGT',

	Active: 'Aktiv',
	Inactive: 'Inaktiv',

	Valve: 'Ventil',
	ValveNr: 'Ventil Numre',
	Pen: 'Sti',

	OK: 'OK',
	Title: 'Titel',
	Location: 'Lokation',
	Status: 'Status',
	Description: 'Beskrivelse',
	'How to Fix': 'Løsningsforslag',
	chooseLocation: 'Vælg lokation',
	'No available curves': 'Ingen tilgængelige kurver',
	Quantity: 'Kvantitet',
	Num: 'Nr.',
	Type: 'Type',
	Add: 'Tilføj',
	sort: 'Sortering',
	EDITSECTION: 'REDIGER SEKTION',
	EDITBUILDING: 'REDIGER BYGNING',
	locationOverview: 'Lokationsoversigt',
	siteSetup: 'Gård/site opsætning',
	siteChrError: 'CHRnr. bliver benyttet af en anden gård',
	peqCodeError: 'Lokationskode bliver benyttet af en anden gård',

	// Departure
	departureTypeSold: 'Solgt',
	removeDeparture: 'Slet afgang',
	departureTypeDead: 'Død',
	departureTypeKilled: 'Slagtet',
	departureTypeToBeKilled: 'Skal slagtes',
	departureTypeShouldDeparture: 'Skal afgå',
	departureTypePutDown: 'Aflivet',
	departureReason: 'Årsag',
	departureType: 'Afgangstype',
	selectDepartureType: 'Afgangstype',
	putDown: 'Aflivet',
	departurePrice: 'Afgangspris',
	selectMarking: 'Vælg Mærkning',
	selectReason: 'Vælg Årsag',
	marking: 'Mærkning',

	// ordered translations
	AdepartureTypeKilled: 'Slagtet',
	BdepartureTypeDead: 'Død',
	CdepartureTypePutDown: 'Aflivet',
	DdepartureTypeSold: 'Solgt',

	// Active sows / Departured sows / Boars / Young animals list
	animalNr: 'Dyrnr.',
	kind: 'Art',
	condition: 'Tilstand',
	entranceDate: 'Indgangsdato',
	Batch: 'Hold',
	transponder: 'Transponder',
	Teats: 'Patter',
	price: 'Pris',
	idNumber: 'IDnummer',
	birthdate: 'Fødselsdag',
	Breed: 'Race',
	dadNr: 'Farnr.',
	dadNumber: 'Farnummer',
	selectDadNumber: 'Vælg farnummer',
	dadID: 'FarID',
	momNr: 'Mornr.',
	momNumber: 'Mornummer',
	selectMomNumber: 'Vælg mornummer',
	momID: 'MorID',
	index: 'Indeks',
	indexDate: 'Indeksdato',
	departureDate: 'Afgangsdato',
	age: 'Alder',
	CreateSow: 'Opret so',
	Days: 'Dage',
	CHOOSELIST: 'VÆLG LISTE',
	cycleDays: 'Cyklusdage',
	minCycleDays: 'Min cyklusdage',
	maxCycleDays: 'Max cyklusdage',
	DryDays: 'Tom dage',
	moveToMatingWeaningDryDays: 'Fravænning, tom dage',
	moveToMatingWeaningDate: 'Fravænning, dato',
	activeSowPdf: 'Aktive stamdyr.pdf',
	dadRace: 'Far race',
	momRace: 'Mor race',
	UsageCode: 'Brugskode',
	DadMomPdf: 'far-mor-data.pdf',
	activeYoungAnimals: 'Aktive ungdyr.pdf',
	// Animals
	activeSows: 'Aktive stamdyr',
	departuredSows: 'Afgåede stamdyr',
	boars: 'Orner',
	youngAnimals: 'Ungdyr',
	activeBoars: 'Aktive orner',
	departedBoars: 'Afgåede orner',
	departedYoungAnimals: 'Afgåede ungdyr',

	// Gender
	female: 'Sogris',
	male: 'Ornegris',
	Female: 'Sogris',
	Male: 'Ornegris',

	// Kinds
	youngFemale: 'Polt',
	gilt: 'Gylt',
	boar: 'Orne',
	Unknown: 'Ukendt',
	YoungFemale: 'Polt',
	Gilt: 'Gylt',
	Sow: 'So',
	Boar: 'Orne',
	Piglet: 'Pattegris',
	YoungAnimal: 'Ungdyr',
	youngFemales: 'Polte',
	sowBoars: 'Søer/Orner',

	// Import
	chooseImportFile: 'Vælg fil',
	chosenFile: 'Valgte fil',
	import: 'Indlæs',
	export: 'Udlæs',
	startImport: 'Start indlæsning',
	specifyWeight: 'Angiv vægt',

	// Export
	ChooseFilePath: 'Vælg fil placering',
	ExportYoungAnimals: 'Udlæs ungdyr',

	// Markings
	Marking: {
		Blue: 'Blå',
		Red: 'Rød',
		Green: 'Grøn',
		Black: 'Sort',
		White: 'Hvid',
		EarmarkRemoved: 'Øremærke fjernet',
		EarmarkAdded: 'Øremærke tilføjet',
	},

	DrugTypes: 'Præparater',
	DrugUseForAnimals: 'Præparat forbrug for dyr',
	DrugType: 'Præparat',
	AddDrugType: 'Opret præparat',
	AddToDrugType: 'Tilføj præparat',
	DrugTypeNotChoosen: 'Vælg Præparat',

	// Reasons
	createReason: 'Opret årsag',
	reasonName: 'Årsagsnavn',
	reasonNameDa: 'Årsagsnavn dansk',
	reasonNameEn: 'Årsagsnavn englesk',
	reasonCode: 'Prioritets kode',
	editReason: 'Rediger årsag',
	reasons: 'Årsager',
	reason: 'Årsag',
	stemAnimalDeparture: 'Afgang',
	reasonDead: 'Død',
	reasonPutDown: 'Aflivet',

	// Pig Types
	piglets: 'Pattegrise',
	polts: 'Polte',

	// Diagnose
	DIAGNOSE_CATEGORIES: 'DIAGNOSE KATEGORIER',
	Diagnose: 'Diagnose',
	categories: 'Kategorier',
	addCategory: 'Tilføj kategori',
	category: 'Diagnose kategori',
	categoryNameEn: 'Kategori navn (en)',
	categoryNameDa: 'Kategori navn (da)',
	priority: 'Prioritet',
	delete: 'Slet',
	DIAGNOSES: 'DIAGNOSER',
	addDiagnose: 'Tilføj diagnose',
	diagnoseNameDa: 'Diagnose navn (da)',
	diagnoseNameEn: 'Diagnose navn (en)',
	diagnoseDescriptionDa: 'Diagnose beskrivelse (da)',
	diagnoseDescriptionEn: 'Diagnose beskrivelse (en)',
	diagnoses: 'Diagnoser',
	DeleteDiagnosePrompt: 'Slet diagnosen? Dette kan ikke fortrydes.',
	isVaccination: 'Vaccination',
	diagnoseName: 'Diagnose',
	pigTypes: 'Dyrtyper',
	pigType: 'Dyrtype',
	diagnoseType: 'Diagnosetype',
	vaccine: 'Vaccinationer',
	treatment: 'Behandling',
	create: 'Opret',
	selectDiagnoseDescription: 'Vælg diagnose/vaccination',
	selectAnimalKind: 'Vælg dyrtype',

	//Analysis
	Analysis: 'Analyse',
	matingsFirstMating: 'Løbedato 1. løbning',
	AllFinishedLitters: '\u2022 Heraf fravænnede kuld, stk.',
	AllNurseWeanings: '\u2022 Heraf ammesøer kuld, stk.',
	analysisResultsPerLitter: 'Resultater fordelt pr. kuld',
	analysisResultsYoungFemale: 'Resultat af poltealder ved løbning',
	analysisElaborationYoungFemaleAge: 'Uddybning poltealder ved løbning',
	ChooseAnalysis: 'Vælg analyse',
	AllWeanings: 'Fravænninger, stk.',
	AvgNursingSow: 'Procent ammesøer',
	All: 'Alle',
	Some: 'Nogle',
	None: 'Ingen',
	Weeks: 'Uger',
	AgePeriod: 'Alder kolonne',
	Elaboration: 'Uddybning',
	SaveSettings: 'Gem indstillinger',
	MatingDate: 'Løbedato',
	TotalPeriod: 'I alt periode',
	PercentageRematings: 'Pct. omløbet',
	PercentageDeparture: 'Pct. afgået',
	MatingsInTotal: 'Løbninger i alt',
	AnalysisResult: {
		//Matings
		Matings: 'Løbninger',
		DistributedMatingPercentage: 'Fordeling af løbninger, pct.',
		TotalMatings: 'Løbninger i alt.',
		TotalFirstMatingToLitter: '1. Løbning, stk.',
		ReturnSows: 'Omløbninger, stk.',
		PercentageReturnSows: 'Pct. omløbninger',
		TotalDepartures: 'Antal afgået, stk.',
		DepartureNonPregnant: 'Ikke drægtig, stk.',
		ThereOfAbortions: 'Kastning, stk.',
		DepartureKilledThisLitter: 'Solgt/slagtet, stk.',
		DepartureDeadThisLitter: 'Død/aflivet, stk.',
		WeaningToFirstMating: 'Fravænning til 1. løbning, dage',
		MatingToNonPregnantDays: 'Løbning til Ikke drægtig, dage',
		MatingToRemating: 'Løbning til omløbning, dage',
		MatingToDeparture: 'Løbning til udsætning, dage',
		//Farrowings
		Farrowings: 'Faringer',
		TotalFarrowings: 'Faringer, stk.',
		DistributedFarrowingsPercentage: 'Fordeling af faringer, pct.',
		AvgAlive: 'Levendefødte, stk.',
		AvgDead: 'Dødfødte, stk.',
		FarrowingRate: 'Faringsprocent',
		NeedFarrowingFirstLitter: 'Manglende faringer 1. kuld, stk.',
		AvgPregnantDays: 'Drægtighedsdage',
		StillbornToAliveRate: 'Dødfødte i pct. af totalfødte',

		//Weanings
		Weanings: 'Fravænninger',
		AllWeanings: 'Fravænninger, stk.',
		AllFinishedLitters: '\u2022 Heraf fravænnede kuld, stk.',
		AllNurseWeanings: '\u2022 Heraf ammesøer, stk.',
		AvgWeanedPerLitter: 'Fravænnet pr. kuld, stk.',
		AvgWeanedPerWeaninig: 'Fravænnet pr. fravænning, stk.',
		AvgWeight: 'Vægt pr. gris, kg.',
		AvgNursingSow: 'Procent ammesøer',
		TotalWeanedPiglets: 'I alt fravænnet, stk.',
		DeadToAliveRate: 'Døde indtil fravænning, pct',
		NeedWeaningFirstLitter: 'Manglende fravænning 1. kuld, stk.',
		AvgNursingDays: 'Diegivningsdage',
		//Pregnancy Analysis
		PercentageRematings: 'Pct. omløbet',
		PercentageDepartured: 'Pct. afgået',
		Percentage: 'Pct.',
		AvgDays: 'Gns. dage',
	},
	WeaningToMating: 'Fravænning til løbning',
	ResultYoungFemaleFirstMating: 'Alder på polte',
	MatingToNonPregnant: 'Løbning til ikke drægtig',
	MatingToMating: 'Løbning til løbning',
	MatingTo: 'Løbning til',
	Departure: 'Afgang',
	Abortion: 'Kastning',
	FromTo: 'Fra - til',
	IntervalDays: 'Interval i dage',
	analysisResultsPregnancy: 'Drægtighedsanalyse',
	AvgDays: 'Gns. dage',
	Percentage: 'Pct.',
	MatingToAbortionAndDeparture: 'Løbning til kastning og afgang',
	analysisDepartureSow: 'Afgangstyper/afgangsårsager Søer',
	AnalysisDeparturePiglets: 'Afgangsårsager pattegrise',
	AnalysisDepartureAnimalType: 'Afgangsårsager {{area}}',
	AnalysisDeparture: {
		NumberOfAnimals: 'Antal dyr',
		PctAnimalLitterNumber: 'PCT. DYR PR. KULDNUMMER',
		CountAnimalLitterNumber: 'ANTAL DYR PR. KULDNUMMER',
		PctAnimalWeek: 'PCT. DYR PR. UGE I PERIODEN',
		CountAnimalWeek: 'ANTAL DYR PR. UGE I PERIODEN',
	},
	TotalPct: 'I alt %',
	TotalWeek: 'Antal dyr pr. uge',
	TotalLitter: 'Antal dyr pr kuld',
	TotalBorn: 'Antal levendefødte',
	MortalityRate: 'Pct. døde af fødte',

	//DashboardOverView
	year: 'År',
	overTen: 'Over 10',
	sowsGilt: 'søer/gylte',
	goalYear: 'Mål',
	overGoalYear: 'Over under',
	averageYear: 'Gns. for år',
	DASHBOARD: 'Dashboard',
	Dashboard: 'Dashboard',
	ageByFold: 'Alder fordelt på læg',
	DaysToMatingFrom: 'Dage til løbning fra',
	DaysFromMatingToDeparture: 'Dage fra løbning til afgang',
	soldOrKilled: 'Solgt/slagtet',
	Sold: 'Solgt',
	SoldOrKilled: 'Solgt/slagtet',
	deadOrPutDown: 'Død/aflivet',
	DeadOrPutDown: 'Død/aflivet',
	notPregnant: 'Ikke drægtig',
	AnimalsToFarrowingTable: 'Løbninger, dyr til faring',
	TotalMatingsTable: 'Løbninger, løbninger i alt',
	TotalDepartureTable: 'Løbninger, afgået fra holdet',
	TotalFarrowingsTable: 'Faringer, faringer i alt',
	NeedFarrowingTable: 'Faringer, manglende faringer til holdet',
	NeedWeaningTable: 'Fravænninger, manglende fravænning til holdet',
	TotalWeaningsTable: 'Fravænninger: fravænnende søer',
	AliveBorn: 'Levendefødte',
	stillborn: 'Dødfødte',
	weightPerPigDashboard: 'Vægt pr. gris',
	weanedPerLitterDashboard: 'Fravænnet pr. kuld',
	weaningDateDashboard: 'Fravænningsdato',
	achievedYear: 'Opnået',
	VALIDATION_ERROR_INTERVALS_MAY_NOT_OVERLAP: 'Intervaller i hver sektion må ikke overlappe',
	fromMatingBatch: 'Fra løbehold',
	toMatingBatch: 'Til løbehold',
	weaningToMating: 'Fravænning til løbning',
	matingToNotPregnant: 'Løbning til ikke drægtig',
	matingToMating: 'Løbning til løbning',
	matingToAbortion: 'Løbning til kastning',
	abortionToMating: 'Kastning til løbning',
	matingToSoldOrKilled: 'Løbning til solgt/slagtet',
	matingTodeadOrPutDown: 'Løbning til død/aflivet',
	matingsFrom: 'Løbninger fra',
	weekBatchPdf: 'Ugehold {{area}}',
	ElaborationMatingBatch: 'Uddybning løbehold {{area}}',
	DeadPiglets: 'Døde pattegrise',
	PctDeadPiglets: 'Pct. døde pattegrise af levende fødte',

	//DashboardSettings
	Dates: 'Datoer',
	rollingYear: 'Rullende 365 dage',
	PerYear: 'Pr. år',
	matingsPcs: 'Løbninger stk.',
	percentageYoungAnimalMatings: '% polte løbninger',
	percentageReMatings: '% omløbere',
	daysFromWeaningToFirstMating: 'Dage fra fravænning til 1. løbning',
	averageWasteDaysPerMatingBatch: 'Gns. spildfoderdage pr. løbehold',
	farrowingsPcs: 'Faringer stk.',
	aliveBornPerFarrowingPcs: 'Levendefødte pr. faring, stk.',
	aliveBornPerFarrowingFirstLegPcs: 'Levendefødte pr. faring 1. læg, stk.',
	deadBornPerFarrowingPcs: 'Dødfødte pr. faring, stk.',
	weanedPerFarrowingPcs: 'Fravænnet pr. faring, stk.',
	weightPerWeanedPig: 'Vægt pr. fravænnet gris, kg',
	stillbornToAliveRate: 'Dødfødte i pct. af totalfødte',
	daysPerMatingBatch: 'Dage pr. løbehold',
	deviationPercent: 'Afvigelse i %',
	dashboardDefinition: 'Definition af År til Dashboard:',
	startAtMatingBatch: 'Start ved løbehold',
	endAtMatingBatch: 'Slut med løbehold',

	//Treatmentplan
	selectTreatmentDefinition: 'Diagnose',
	noDrugTypesForAnimalTypeVaccine:
		'Du skal tilføje et præparat af typen vaccine til lageret for denne dyrtype, for at kunne oprette en vaccinationdefinition',
	noDrugTypesForAnimalTypeMedicine:
		'Du skal tilføje et præparat af typen medicin til lageret for denne dyrtype, for at kunne oprette en diagnosedefinition',
	weightLabel: 'Vægt',
	registerTreatment: 'Opret behandling',
	registerToBeTreated: 'Skal behandles',
	AmountOfPiglets: 'Antal pattegrise',
	AmountOfPigletsShort: 'Antal',
	DrugAmount: 'Medicinmængde',
	DrugAmountShort: 'Mængde',
	DrugAmountPerPig: 'Dosis pr. gris',
	Dosage: 'Dosis',
	EditTreatment: 'Rediger behandling',
	SelectTreatedMoved: 'Hvor mange behandlede grise flyttes?',

	// Error messages
	SYNC_ERROR: 'Noget gik galt, data blev muligvis ikke synkroniseret. Gå ud af og billedet og prøv igen',
	VALIDATION_ERROR_MISSING_AGE_PERIOD: ': Du skal definere en fra og til alder for polt',
	VALIDATION_ERROR_AGE_TO_IS_LESS_THAN_FROM_DATE: ': Til alderen må ikke være mindre end fra alderen',
	VALIDATION_ERROR_DRUGSTOCK_REASON_REQUIRED: 'Du skal skrive årsagen til spildet',
	VALIDATION_ERROR_AnimalNumberNotSet: 'Dyrnummer skal angives',
	VALIDATION_ERROR_AnimalNumberNotFound: 'Dyrnr. findes ikke.',
	VALIDATION_ERROR_TRANSPONDER_ALREADY_IN_USE_BY_SOW: 'Transponder nummer er allerede knyttet til dyrnr. ',
	VALIDATION_ERROR_ANIMAL_NUMBER_ALREADY_IN_USE_BY_SOW: 'Dyrnummer er allerede knyttet til en anden so ',
	VALIDATION_ERROR_NoFeedingSubjectChoosen: 'Der mangler angivelse af foderemne',
	VALIDATION_ERROR_NoFeedingAnimalTypeChoosen: 'Der mangler angivelse af dyrtype',
	VALIDATION_ERROR_ANIMAL_IS_NOT_PREGNANT_ANYMORE_AND_ANIMAL_IS_ON_PREGNANT_LOCATION:
		'Du skal flytte dyret til en placering, som ikke er en drægtighedslokationen',
	VALIDATION_ERROR_ANIMAL_NEEDS_TO_BE_PREGNANT_TO_MOVE_TO_THIS_LOCATION:
		'Dyret skal være drægtig for at kunne flyttes til en drægtigheds lokation',
	VALIDATION_ERROR_ESF_IS_ENABLED_AND_TRANSPONDER_IS_REQUIRED: 'Ingen transponder valgt',
	VALIDATION_ERROR_AnimalNumberIsDepartured: 'Dyrnr. er afgået',
	VALIDATION_ERROR_DRUGSTOCK_USE_ADD_INSTEAD: 'Brug tilførelse i stedet for',
	VALIDATION_ERROR_DRUGSTOCK_ERROR_EDITING_WASTE_HAS_TO_BE_NEGATIVE:
		'En redigering af en opdatering af lagerbeholdning skal være negativ',
	VALIDATION_ERROR_DRUGSTOCK_ERROR_EDITING_ADJUSTMENT_HAS_TO_BE_POSITIVE:
		'En redigering af en tilførelse til medicin, skal være positiv',
	VALIDATION_ERROR_DRUGSTOCK_NOT_SELECTED: 'Ingen præparat valgt',
	VALIDATION_ERROR_FEEDINGSTOCK_NOT_SELECTED: 'Du skal angive foderemnet',
	VALIDATION_ERROR_FEEDINGSTOCK_AMOUNT: 'Ingen mængde valgt',
	VALIDATION_ERROR_FEEDINGSTOCK_PRICE: 'Ingen pris valgt',

	VALIDATION_ERROR_FEEDINGSTOCK_FESOW: 'FEso skal angives - 0 er en lovlig værdi.',
	VALIDATION_ERROR_WEIGHT_REQUIRED_FOR_DEPARTED: 'Du er igang med at flytte nogle grise, uden at angive deres vægt',
	VALIDATION_ERROR_FEEDINGSTOCK_FEPIG: 'FEsv skal angives - 0 er en lovlig værdi.',
	VALIDATION_ERROR_AMOUNT_NOT_SET: 'Antal er ikke sat',
	VALIDATION_ERROR_FEEDINGSTOCK_FOSFOR: 'Ingen fosfor valgt',
	VALIDATION_ERROR_TOTAL_AMOUNT_TO_LOW_TO_CONTAIN_ALREADY_USED_FOOD: 'Din mængden skal minimum være: ',
	VALIDATION_ERROR_TOTAL_AMOUNT_AVAILABLE_WILL_BE_LOWER_THAN_TOTALUSED:
		'Din totale foder mængden af dette foderemne, vil være lavere end det forbrugte',
	VALIDATION_ERROR_DRUGSTOCK_AMOUNT: 'Ingen mængde valgt',
	VALIDATION_ERROR_DRUGSTOCK_DATE: 'Ingen dato valgt',
	VALIDATION_ERROR_AnimalNumberExists: 'Der findes allerede et aktivt dyr med dette nummer',
	VALIDATION_ERROR_EntranceDateNotSet: 'Indgangsdato er ikke sat',
	VALIDATION_ERROR_PenIdNotSet: 'Sti er ikke sat',
	VALIDATION_ERROR_STABLE_SECTION_NOT_SET: 'Stald - sektion er ikke sat',
	VALIDATION_ERROR_NEW_PEN_ID_NOT_SET: 'Sti er ikke sat',
	VALIDATION_ERROR_MOVE_STABLE_SECTION_NOT_SET: 'Flytte Stald - sektion er ikke sat',
	VALIDATION_ERROR_MOVE_NEW_PEN_ID_NOT_SET: 'Flytte sti er ikke sat',
	VALIDATION_ERROR_SiteNotSet: 'Gård ikke sat',
	VALIDATION_ERROR_MatingEqualsOneLitter: 'Der skal være minimum et kuld når Løbning er sat',
	VALIDATION_ERROR_EntranceTypeNotSet: 'Indgangstype er ikke sat',
	VALIDATION_ERROR_AnimalNotFound: 'Dyr ikke fundet',
	VALIDATION_ERROR_DepartureTypeNotSet: 'Afgangstype ikke sat',
	VALIDATION_ERROR_DepartureDateNotSet: 'Afgangsdato ikke sat',
	VALIDATION_ERROR_DATE_NOT_SET: 'Dato ikke sat',
	VALIDATION_ERROR_LARD_WIDTH_NOT_SET: 'Spækmål ikke sat',
	VALIDATION_ERROR_DepartureBoarIsKs: 'Orne er en KS orne',
	VALIDATION_ERROR_DepartureDateMostAfterCurrentDate: 'Afgangsdato skal være efter dags dato',
	VALIDATION_ERROR_COUNTRYID_REQUIRED: 'Gården mangler et country',
	VALIDATION_ERROR_CHRNUM_REQUIRED: 'Gården mangler et CHR nummer',
	VALIDATION_ERROR_SITE_NAME_REQUIRED: 'Gården mangler et navn',
	VALIDATION_ERROR_SITE_ADDRESS_REQUIRED: 'Gården mangler en addresse',
	VALIDATION_ERROR_SITE_ZIPCODE_REQUIRED: 'Gården mangler et postnr.',
	VALIDATION_ERROR_SITE_CITY_REQUIRED: 'Gården mangler en tilhørende by',
	VALIDATION_ERROR_OWNER_NAME_REQUIRED: 'Ejer mangler et navn',
	VALIDATION_ERROR_OWNER_ADDRESS_REQUIRED: 'Ejer mangler en addresse',
	VALIDATION_ERROR_OWNER_ZIPCODE_REQUIRED: 'Ejer mangler et postnr.',
	VALIDATION_ERROR_OWNER_CITY_REQUIRED: 'Ejer mangler en tilhørende by',
	VALIDATION_ERROR_CVRNUM_REQUIRED: 'Ejer mangler et CVR nummer',

	VALIDATION_ERROR_NameNotSet: 'Navn ikke sat',
	VALIDATION_ERROR_UnitNotSet: 'Enhed ikke sat',
	VALIDATION_ERROR_TransponderOccupied: 'Transponder optaget',
	VALIDATION_ERROR_BothNamesRequired: 'Begge navne er påkrævet',
	VALIDATION_ERROR_CategoryRequired: 'Kategori er påkrævet',
	VALIDATION_ERROR_CategoryInUse: 'Kan ikke slette en kategori der er i brug på en diagnose',
	VALIDATION_ERROR_FieldsRequired: 'Alle felter skal være udfyldt',
	VALIDATION_ERROR_FieldsRequired_2: ' skal være udfyldt',
	VALIDATION_ERROR_NoDrugId: 'Der er ikke valgt et præparat',
	VALIDATION_ERROR_AnotherVaccineAlreadyExist: 'Der er defineret  en vaccine',
	VALIDATION_ERROR_CannotBeNegative: 'Værdierne må ikke være negative',
	VALIDATION_ERROR_CannotBeNegativeOrZero: 'Værdierne må ikke være negative eller nul',
	VALIDATION_ERROR_CategoryNotSet: 'Der er ikke valgt en kategori',
	VALIDATION_ERROR_NoDiagnoseID: 'Vælg diagnose',
	VALIDATION_ERROR_NoAnimalKinds: 'Ingen dyrtype sat',
	VALIDATION_ERROR_NoDrugTypeIsChoosed: 'Vælg præparat',
	VALIDATION_ERROR_NoFrequency: 'Vælg interval',
	VALIDATION_ERROR_NoNumberOfTreatments: 'Udfyld antal behandlinger',
	VALIDATION_ERROR_DASHBOARD_ENDMATINGBATCH_NEED_TO_BE_LATER:
		'Du skal sætte afsluttende løbehold til at være efter den nuværende dato',
	VALIDATION_ERROR_DASHBOARD_NEED_LATER_ENDMATINGBATCH:
		'Der skal angives et nyt År for dashboard – Indstillinger, So management, Plan/mål for produktion.',
	VALIDATION_ERROR_RefreshError: 'Fejl, genstart siden',
	VALIDATION_ERROR_NoRetentionTime: 'Udfyld slagtefrist',
	VALIDATION_ERROR_NoTreatmentType: 'Vælg behandlingstype',
	VALIDATION_ERROR_NoDrugAmountValue: 'Udfyld mængde',
	VALIDATION_ERROR_NoPerUnit: 'Udfyld pr. enhed',
	VALIDATION_ERROR_NoUnitType: 'Vælg enhedstype',
	VALIDATION_ERROR_BatchNotSet: 'Udfyld sohold',
	VALIDATION_ERROR_FinishedDateNotSet: 'Vælg dato',
	VALIDATION_ERROR_MatingDateAndYoungFemaleError: ' Løbedato ligger før dyrets indgangsdato',
	VALIDATION_ERROR_InvalidAgeInterval: 'Alderen på polten er uden for interval og derfor ugyldig',
	VALIDATION_ERROR_InvalidPregnancyEvent: 'Hændelsen er ikke lovlig i forhold til dyrets tilstand',
	VALIDATION_ERROR_HasNoPreviousEvent: 'Har ikke en tidligere hændelse',
	VALIDATION_ERROR_SowAlreadyPregnant: 'Soen er allerede gravid',
	VALIDATION_ERROR_DateIsBeforePreviousEvent: 'Datoen er sat før forrige hændelse',
	VALIDATION_ERROR_InvalidMatingToFarrowingInterval: '{{area}} dage mellem løbning og faring er ikke lovlig',
	VALIDATION_ERROR_RegistrationErrorPregnancyEvent: 'Der skete en fejl ved registrering af hændelse',
	VALIDATION_ERROR_NoAbortionIfAlivePigs: 'Der må ikke angives Levende i forbindelse med en kastning',
	VALIDATION_ERROR_InvalidFarrowingToWeaningInterval: 'Interval fra faring til fravænning er ugyldig',
	VALIDATION_ERROR_SET_WHAT_TO_TREAT: 'Du skal vælge om det er alle grise eller kun ornegrise der skal behandles',
	VALIDATION_ERROR_InvalidWeaningToMatingInterval: 'Interval fra fravænning til løbning er ugyldig',
	VALIDATION_ERROR_GiltMatingDateError: 'Løbningsdato skal være før indgangsdato, grisetypen en gylt',
	VALIDATION_ERROR_PregnantDaysNotSet: 'Udfyld drægtighedsdage',
	VALIDATION_ERROR_NursingDaysNotSet: 'Udfyld diegivningsdage',
	VALIDATION_ERROR_StartDateIsNotSet: 'Vælg startsdato',
	VALIDATION_ERROR_WeekCycleIsNotSet: 'Vælg cyklus i uger',
	VALIDATION_ERROR_CycledaysNotSet: 'Cyclus dage ikke sat',
	VALIDATION_ERROR_MatingPeriodIsNotSet: 'Vælg antal dage i løbeperiode',
	VALIDATION_ERROR_PregnantAndNursingDaysExceeded:
		'Drægtighedsdage + diegivningsdage er større end so cyklus i uger.',
	VALIDATION_WARNING_CHANGING_SECTION_OR_BUILDING_WILL_RESULT_IN_RESET_FOR_CHANGED_BUILDINGS_SECTIONS:
		'Du har ændret relation mellem lokationer i Distriwin og SKIOLD digital, dette medfører at der er ventiler som ikke er knyttet til en lokation. Via lokationsoversigten skal du igen knytte ventilerne til en lokation. Skal ændringerne gemmes?',
	VALIDATION_WARNING_SECTION_NOT_BELONGING_TO_SECTION_SELECTED_HAS_NON_SETTED_PENS:
		'Data kan ikke gemmes idet, der ikke er knyttet ventiler til de ændrede sektioner, vil du rette dette?',
	VALIDATION_WARNING_YOU_ARE_CREATING_A_USAGE_THAT_IS_IN_ANOTHER_USAGE_PERIOD:
		'Du er ved at oprette et forbrug, der lapper over en tidligere periode: ',

	VALIDATION_ERROR_NoMatingBatchesGenerated: 'Der er ikke genereret nogle løbehold',
	VALIDATION_ERROR_EndDateNotSet: 'Til dato er ikke blevet sat',
	VALIDATION_ERROR_OverlapsOtherSettingsDates: 'Start eller til dato i indstillinger, overlapper en anden',
	VALIDATION_ERROR_DateDoesNotMatchAMatingBatch:
		'Valgte dato har ikke et løbehold. Lav venligst et løbehold for at fortsætte',
	VALIDATION_ERROR_RegistrationErrorOnMatingBatch: 'Der skete en fejl ved registrering af løbehold',
	VALDIATION_ERROR_UnknownTreatmentDefinitionSet: 'Behandlingsdefinitionen er ukendt af serveren',
	VALIDATION_ERROR_TreatmentPlannedDateNotSet: 'Behandling mangler planlagt dato',
	VALIDATION_ERROR_TreatmentSiteIdNotSet: 'Behandling mangler site id',
	VALIDATION_ERROR_NoDiagnoseSet: 'Diagnose er påkrævet',
	VALIDATION_ERROR_CHECKALL_OR_SELECTPEN: 'Du skal vælge sti eller vælge alle i sektionen',
	VALIDATION_ERROR_NoMarkingSet: 'Markering skal sættes',
	VALIDATION_ERROR_NoWeightSet: 'Vægt er påkrævet',
	VALIDATION_ERROR_ScanCantBeSameDateAsMated: 'Det er ikke muligt at scanne soen på løbningsdagen',
	VALIDATION_ERROR_StartDateIsEarlierThanCurrentDate: 'Startdatoen skal være større end nuværende dato',
	VALIDATION_ERROR_NewStartDateMustBeEqualToExisting:
		'Startdato skal være større end dagsdato og lig med startdato på et af de beregnede løbehold',
	VALIDATION_ERROR_NoneAlive: 'Ingen levende dyr med det nummer',
	VALIDATION_ERROR_TransponderInUse: 'Transponder er allerede i brug',
	VALIDATION_ERROR_SowDoesNotExist: 'Soen eksiterer ikke',
	VALIDATION_ERROR_AnimalNotSet: 'Dyr er ikke sat',
	VALIDATION_ERROR_BuildingNumberNotSet: 'Udfyld staldnummer',
	VALIDATION_ERROR_BuildingNameNotSet: 'Udfyld staldnavn ',
	VALIDATION_ERROR_ProductionFormNotSet: 'Vælg produktionform',
	VALIDATION_ERROR_PRODUCTION_TYPE_NOT_SET: 'Produktions type ikke sat',
	VALIDATION_ERROR_COUNT_NOT_SET: 'Antal ikke sat',
	VALIDATION_ERROR_BuildingNumberAlreadyExists: 'Valgte Staldnummer eksisterer allerede',
	VALIDATION_ERROR_SectionTypeNotSet: 'Vælg type',
	VALIDATION_ERROR_AVG_WEIGHT_NOT_SET: 'Gns. vægt ikke sat',
	VALIDATION_ERROR_DuplicateSectionNumbers:
		'Det er ikke muligt at generere sektioner, da der vil opstå duplikerede sektionsnumre',
	VALIDATION_ERROR_FEEDINGSTOCK_NO_FEEDING_SUBJECT_SELECTED: 'Du skal vælge foder emne',
	VALIDATION_ERROR_DuplicatePenNumbers: 'Det er ikke muligt at generere stier, da der vil opstå duplikerede stinumre',
	VALIDATION_ERROR_RegistrationError: 'Der er sket en fejl ved registrering',
	VALIDATION_ERROR_HasNoSection: 'Er ikke tilknyttet en sektion',
	VALIDATION_ERROR_HasNoBuilding: 'Er ikke tilknyttet en stald',
	VALIDATION_ERROR_SetupValidationSetup: 'Vær venlig at opsætte valideringsintervaller under so indstillinger',
	VALIDATION_ERROR_InvalidPregnantPeriod: 'Drægtighedsperioden skal være i intervallet 100 - 125 dage',
	VALIDATION_ERROR_invalidPlanPeriod: 'Planperioden skal være imellem plan - og plan +',
	VALIDATION_ERROR_InvalidNursingPeriod: 'Diegivningsperioden må ikke være mindre en 21 dage',
	VALIDATION_ERROR_LocationNotSet: 'Lokation er ikke sat',
	VALIDATION_ERROR_InvalidFarrowingAlive: 'Antal levende orne- og sogrise stemmer ikke overens med antal levende',
	VALIDATION_ERROR_IdNumberNotSet: 'Der mangler angivelse af IDnummer.',
	VALIDATION_ERROR_WeaningNumAliveIsNotSet: 'Antal fravænnede grise er ikke sat',
	VALIDATION_ERROR_MoveDateNotSet: 'Flyttedato er ikke sat',
	VALIDATION_ERROR_NewPenIdNotSet: 'Der er ikke valgt en sti',
	VALIDATION_ERROR_NewPenSameAsCurrent: 'Den valgte sti er den nuværende lokation',
	VALIDATION_ERROR_FarrowingNumAliveNotSet: 'Antal levende grise er ikke sat',
	VALIDATION_ERROR_BuildingIsNotEmpty: 'Bygningen indeholder grise, og kan derfor ikke slettes',
	VALIDATION_ERROR_SectionIsNotEmpty: 'Sektion indeholder grise, og kan derfor ikke slettes',
	VALIDATION_ERROR_MatingBatchOutOfRange: 'Der er ikke oprettet løbehold langt nok tilbage i tiden',
	VALIDATION_ERROR_NotAllowedToDeleteDiagnose: 'Diagnosen benyttes stadigvæk på en gård',
	VALIDATION_ERROR_DiagnoseAnimalKindStillInUse: 'Diagnosens grisetype benyttes stadigvæk på en gård',
	VALIDATION_ERROR_ColumnTextIsNotSet: 'Hvis diagnose er valgt, skriv kolonne tekst',
	VALIDATION_ERROR_BoarProcentageIsNotSet: 'Hvis ornegrise er valgt, skriv ornegrise %',
	VALIDATION_ERROR_NursingFromIsEmptyOrNegative: 'Diegivningsdage - Fra, skal udfyldes og må ikke være negativ',
	VALIDATION_ERROR_NursingToIsEmptyOrNegative: 'Diegivningsdage - Til, skal udfyldes og må ikke være mindre end Fra',
	VALIDATION_ERROR_PregnantFromIsEmptyOrNegative: 'Drægtighedsdage - Fra, skal udfyldes og må ikke være negativ',
	VALIDATION_ERROR_PregnantToIsEmptyOrNegative: 'Drægtighedsdage - Til, skal udfyldes og må ikke være mindre end Fra',
	VALIDATION_ERROR_SHOW_ANIMAL_ON_LIST: 'Der er ikke angivet værdier for Vis dyr på listen',
	VALIDATION_ERROR_NursingDaysAreRequiered: 'Diegivningsdage skal udfyldes',
	VALIDATION_ERROR_AvailableOnNeedsToBeSet: 'Liste tilgængelig på skal sættes',
	VALIDATION_ERROR_ChoosePregnancyEvent: 'Vælg graviditets hændelse',
	VALIDATION_ERROR_IncludeLittersTo: 'Til og med kuldnr. skal udfyldes',
	VALIDATION_ERROR_AfterEventDaysGreaterThanZero: 'Dage efterhændelse skal være større end nul',
	VALIDATION_ERROR_ChooseAtleastOneTreatmentDef: 'Vælg mindst en behandlingsdefinition',
	VALIDATION_ERROR_ChooseAnimalNotSet: 'Vælg dyr til liste er ikke sat',
	VALIDATION_ERROR_FEEDINGSTOCK_NO_FEEDING_SUBJECT_NAME: 'Udfyld foderemne navn',
	VALIDATION_ERROR_AMOUNT_EXCEED_STOCK: 'Valgte mængde udskrider lagerindhold',
	VALIDATION_ERROR_NO_CODE_SET: 'Kode er ikke sat',
	VALIDATION_ERROR_INVALID_CODE_SET: 'Vælg en anden kode',
	VALIDATION_ERROR_DEFAULT_CODE_SET: 'Standard kode kan ikke sættes',
	VALIDATION_ERROR_NO_UNITTYPE_SET: 'Vælg enheder',
	VALIDATION_ERROR_NO_PROCESS_EQUIPMENT_SET: 'Vælg process udstyr',
	VALIDATION_ERROR_InsertWithoutTransponder: 'Dyr kan ikke oprettes i ESF uden transponder',
	VALIDATION_ERROR_CycleDayIsEmpty: 'Cyklus dag er ikke sat',
	VALIDATION_ERROR_AnimalNumberIsEmpty: 'Dyr kan ikke opdateres i ESF uden et dyrnummer',
	VALIDATION_ERROR_FeedCurveDoesNotExist: 'Den valgte fodderkurve findes ikke',
	VALIDATION_ERROR_LocationIsEmpty: 'Lokation er ikke sat',
	VALIDATION_ERROR_InvalidAnimalKindOnEsfLocation: 'Den valgte dyrtype kan ikke indsættes på ESF sti',
	VALIDATION_ERROR_FARM_HAS_ESF_BUT_ANIMAL_DOES_NOT_HAVE_TRANSPONDER:
		'Dyret skal have en transponder for, at dyret kan flyttes til en ESF lokation',
	VALIDATION_ERROR_FEED_CURVE_NUMBER_IS_NOT_SET: 'Vælg foderkurve for at flytte dyret til en ESF lokation',
	VALIDATION_ERROR_ANIMALS_NEED_TRANSPONDER: 'Følgende dyr har ikke et transponder nummer',
	VALIDATION_ERROR_INVALID_ESF_DATA: 'Scannet dyr har gammel data',
	VALIDATION_ERROR_BLE_CHOOSE_LOCATION: 'Vælg lokation på appen, for at benytte scanneren',
	VALIDATION_ERROR_SOW_INVALID_FOR_WORKLIST: 'Dyret er ikke gyldig til denne arbejdsliste',
	VALIDATION_ERROR_ENTRACE_SOW_EDIT_LITTER:
		'Kuld må ikke være mindre end 2, da dyret oprindeligt er blevet oprettet som en so',
	VALIDATION_ERROR_MATING_BATCH_1_YEAR:
		'Der skal være et løbehold minimum 1 år frem i tiden. Opret venligst løbehold',
	VALIDATION_ERROR_MUST_BE_START_MATING_BATCH: 'Datoen skal være starten af det løbehold, og senere end nuværende',
	VALIDATION_ERROR_AVG_WEIGHT_POSITIVE: 'Gns. vægten skal være positiv',
	VALIDATION_ERROR_DEAD_PIGLETS_NOT_SET: 'Antal ikke sat - Skal være større end 0',
	VALIDATION_ERROR_MUST_BE_NURSING: 'Soen skal være diegivende',
	VALIDATION_ERROR_NO_FEEDING_ADJUSTMENT: 'Der er ikke foretaget nogle indkøb på foderype før valgte dato',
	VALIDATION_ERROR_DEPARTURE_DATE_IS_EARLIER_THAN_LAST_PREGNANCY:
		'Det er ikke muligt at registrere afgang på dyret idet, der er registreret en hændelse på dyret efter den angivne afgangsdato.',
	VALIDATION_ERROR_NO_DEPARTURE_ON_NURSING_SOW:
		'Det er ikke lovligt at registrere afgang på en so, som er diegivende.',
	VALIDATION_ERROR_MOVE_EVENT_IS_EARLIER_THAN_LAST_EVENT:
		'Datoen for flytning af dyret ligger før datoen for sidste flytning',
	VALIDATION_ERROR_CODE_ALREADY_USED: 'Koden er allerede i brug',
	VALIDATION_ERROR_TYPE_NOT_SET: 'Type ikke sat',
	VALIDATION_ERROR_REASON_NOT_SET: 'Årsag ikke sat',
	VALIDATION_ERROR_NO_SOWS_IN_FILE: 'Ingen søer i filen',
	VALIDATION_ERROR_SOME_SOWS_EXISTS: 'Nogle af søerne i filen finde allerede',
	VALIDATION_ERROR_ANIMAL_ID_NUM_EXISTS: 'Der findes allerede et aktivt dyr med dette idnummer',
	VALIDATION_ERROR_ANIMAL_IS_NOT_YOUNG_FEMALE: 'Dyr(erne) er ikke af typen Polt',
	VALIDATION_ERROR_NO_DEFAULT_WEANING_PEN:
		'Der mangler angivelse af lokation for fravænnede grise. Angives under Indstillinger, Søer, Generelle',
	VALIDATION_ERROR_COUNT_LARGER_THAN_CURRENT_COUNT1: 'Der er kun',
	VALIDATION_ERROR_COUNT_LARGER_THAN_CURRENT_COUNT2: 'grise på valgte lokationer',
	VALIDATION_ERROR_COUNT_LARGER_THAN_CURRENT_COUNT3: 'Der er',
	VALIDATION_ERROR_COUNT_LARGER_THAN_CURRENT_COUNT4: 'grise på lokation',
	VALIDATION_ERROR_COUNT_LARGER_THAN_CURRENT_COUNT5: 'Kan derfor ikke afgå',
	VALIDATION_ERROR_FarrowingOutOfInterval:
		'Det er ikke muligt at tildele IDnumre. Faringen er uden for godkendt interval.',
	VALIDATION_ERROR_DrugHasUsageOrWasteRegistration: 'Præparatet har forbrug eller spild registreringer',
	VALIDATION_ERROR_SET_FEED_CURVE: 'Foderkurve er ikke sat',
	VALIDATION_ERROR_SET_RECIPE: 'Recept er ikke sat',

	//Warning messages
	VALIDATION_WARNING_MatingToFarrowingOutOfInterval:
		' Dyrets har {{area}} drægtighedsdage, dette er udenfor angivet interval, vil du gemme alligevel?',
	VALIDATION_ERROR_YOU_ARE_MATING_A_ANIMAL_THAT_IS_NURSING:
		'Du er ved at løbe et dyr, der er diegivende, vil du gemme alligevel?',

	VALIDATION_WARNING_FarrowingToWeaningOutOfInterval1: 'diegivningsdage er',

	VALIDATION_WARNING_FarrowingToWeaningOutOfInterval2: 'og uden for interval, vil du gemme alligevel?',
	VALIDATION_WARNING_WeaningToMatingOutOfInterval:
		'Perioden fra Fravænning til løbning er uden for det indstillede interval, vil du gemme alligevel?',
	VALIDATION_WARNING_MatingAgeOutOfInterval: 'Dyrets alder er uden for angivet interval, vil du gemme afligevel?',
	VALIDATION_WARNING_ConfirmDeletionAdjustments: 'Slet valgte medicin ændring?',
	VALIDATION_WARNING_ConfirmDeletionFeedingStockAdjustment: 'Er du sikker på, at du vil slette indkøbet?',
	VALIDATION_WARNING_ConfirmDeletionFeedingStockUsage: 'Er du sikker på, at du vil slette forbruget?',
	VALIDATION_WARNING_CONFIRM_CREATETREATMENTSOWYOUNGFEMALE: 'Er det en polt med et dyrnr. du behandler?',
	VALIDATION_WARNING_ConfirmDeletionMated: 'Slet valgte løbning?',
	VALIDATION_WARNING_ConfirmDeletionScanning: 'Slet valgte scanning?',
	VALIDATION_WARNING_ConfirmDeletionFarrowing: 'Slet valgte faring?',
	VALIDATION_WARNING_ConfirmDeletionWeaning: 'Slet valgte fravænning?',
	VALIDATION_WARNING_CapacityExceeded1: 'Kapacitet overskredet på sti:',
	VALIDATION_WARNING_CapacityExceeded2: 'Antal grise på nuværende tidspunkt:',
	VALIDATION_WARNING_CapacityExceeded3: 'Kapaciteten er:',
	VALIDATION_WARNING_ConfirmWarningMessage: 'Vil du gemme alligevel?',
	VALIDATION_WARNING_ConfirmWarningDataNotSavedMessage: 'Der er data i billedet som ikke er gemt. Skal de gemmes?',
	VALIDATION_WARNING_ConfirmWarningMessageMoveEvent:
		'Er du sikker på, at du vil flytte dette dyr til den nye lokation',
	VALIDATION_WARNING_ConfirmDeletionBuilding: 'Vil du slette valgte bygning?',
	VALIDATION_WARNING_ConfirmDeletionSection: 'Vil du slette valgte sektion?',
	VALIDATION_WARNING_ValveGroupSectionContainsOldData: 'Valgte sektion indeholder forældet ventil gruppe data',
	VALIDATION_WARNING_ValveGroupsModificationOld: 'Redigering af ventil gruppe blev ikke gemt, pga. forældet data',
	VALIDATION_WARNING_ValveGroupIsNotEditable:
		'Ventildata er fra i går, derfor er det ikke muligt at foretage justeringer. Via WEB-sitet, Fejl Juster foder, kan du hente nyeste data fra Distriwin',
	VALIDATION_WARNING_ESF_DATA_IS_NOT_UP_TO_DATE: 'Der er data, som ikke er opdateret fra kontrolleren',
	VALIDATION_ERROR_CURVE_INVALID: 'Du skal have valgt en kurve for at kunne gemme',
	VALIDATION_ERROR_FIXED_PERCENT_INVALID: 'Fast % skal være imellem 0-100 for at kunne gemme',
	VALIDATION_WARNING_DELETE_MARKED_YOUNG_ANIMALS: 'Skal de markerede ungdyr slettes?',
	VALIDATION_WARNING_EDIT_GROWTH_PIG_EVENT:
		'Den ændring du er ved at gennemfører medfører en negativ beholdning af grise. Vil du fortsætte?',
	VALIDATION_WARNING_CAPACITY_EXCEEDED: 'Kapacitetsgrænsen på {{area}}% er overskredet, vil du gemme alligevel',
	VALIDATION_WARNING_CAPACITY_EXCEEDED_MANY:
		'Kapacitetsgrænsen på {{area}}% er overskredet på sti {{area2}}, vil du gemme alligevel',

	VALIDATION_WARNING_MOVE_YOUNG_FEMALE_TO_PREGNANT: "Du er ved at flytte Polte til en drægtighedslokation, vil du fortsætte?",
	// SlaughterHouse
	SlaughterHouse: 'Slagteri',
	connectToTican: 'Forbind til Tican',
	DanishCrown: 'Danish Crown',
	Tican: 'Tican',
	loginInformation: 'Login information',
	connectionSlaughterHouse: 'Kommunikation slagteri',
	LoginInformationDanishCrown: 'LOGIN INFORAMTIONER DANISH CROWN',
	UserId: 'Bruger ID:',
	password: 'Password:',
	SupplierNumbers: 'LEVERANDØRNUMER',
	meatPercentage: 'Kød %',
	otherCosts: 'Andre',
	slaughterWeight: 'Slagtevægt',
	checkSlaughterDataWithDepartureData: 'Tjek slagtede med slagtedata',
	departureSkioldDigital: 'Afgang = Slagtet antal',
	departureSlaughterHouse: 'Slagtedata antal',
	difference: 'Difference',
	checkSlaughterData: 'Tjek slagtede/slagtedata',
	finisherData: 'Slagtedata',
	supplier: 'Leverandørnr.',
	chooseLocationForMove: 'Vælg lokation for flytning',
	APig: 'A-svin',
	Discarded: 'Kasserede',
	BesBoar: 'Bes. Orner',
	DiscardedSow: 'Kasseret søer',
	DiscardedBesBoar: 'Kass. bes. Orner',
	RemovedMale: 'Frasort. Hangris',
	OtherDelievered: 'Andre leverede',
	ReturnPig: 'Retur svin',
	Barrow: 'Galt',
	Basic: 'Basis',
	slaughterNumber: 'Slagtenr.',
	noting: 'Notering',
	aliveWeight: 'Levende vægt',
	SavingChangesTheLocationForAllPigsInThisSectionForThisDelieveryForThisDate:
		'Vil du ændre lokationen på denne levering?',
	DoYouWantToChangeTheLocationOfThoseItems: 'Vil du ændre lokationen for det markered slagtedata',
	DoYouWantToDeleteSlaughterDatas: 'Vil du slette det markered slagtedata',
	FinisherDataProcessFinisher: 'Slagtedata',
	Failed_To_Login_Tican: 'Der er opstået en fejl med tican, forsøg venligst igen',
	Error_SlaughterHouse_required: 'Du skal vælge et slagteri',
	createDelievery: 'Opret levendandørnumer',
	avgPricePrKg: 'Kr./kg',
	IncorrectLoginInformation: 'Forkerte login informationer',
	supplementDeductionPrPig: 'Tillæg/fradrag pr. gris',
	spec: 'Spec',
	Note: 'Bemærk',
	priceKgBasis: 'KR/KG BASIS',
	noteText: 'Bemærkninger tekst',
	priceWithDeduction: 'Pris inkl. generelle tillæg/fradrag',
	// Misc
	PromptSureDelete: 'Er du sikker på du vil slette?',
	SetupValidationSetup: 'Udfyld venligst validering under indstillinger',

	//Treatment definition
	TreatmentDefinitions: ' Behandlings-/vaccinedefinitioner',
	drug: 'Præparat',
	chooseDrugType: 'Vælg præparat type',
	RetentionTime: 'Slagtefrist',
	method: 'Metode',
	severity: 'Grad',
	amountPerUnit: 'Mængde pr. enhed',
	duration: 'Varighed',
	interval: 'Interval',
	amount: 'Mængde',
	numberTreatments: 'Antal',
	per: 'pr.',
	addTreatDef: 'Tilføj definition',
	choose: 'Vælg',
	SowBoarGlit: 'So/Orne/Gylt',
	SowGilt: 'So/Gylt',
	AnimalKinds: 'Dyrtyper',
	none: 'Ingen',
	CanBeRegistration: 'Kan dette være en registrering',

	//Treatment type
	Food: 'I foder',
	Water: 'I vand',
	Oral: 'I munden',
	Injection: 'Injektion',
	OnTheSkin: 'På huden',

	//Treatment unit
	perKgPig: 'Kg gris',
	perKgFood: 'Kg foder',
	perAnimal: 'Dyr',

	//Frequency
	once: 'En gang',
	everyDay: 'Hver dag',
	everyOtherDay: 'Hver anden dag',
	everyWeek: 'Hver uge',

	//Vaccination
	event: 'Event',
	Days1: 'Dage 1',
	Days2: 'Dage 2',
	Age1: 'Alder 1',
	Age2: 'Alder 2',
	Age3: 'Alder 3',
	Farrowing: 'Faring',
	Mating: 'Løbning',
	Averting: 'Fravænning',
	Birthdate: 'Fødselsdato',
	chooseDrug: 'Vælg præparat',

	//Treatment log
	TREATMENT_LOG: 'BEHANDLINGER',
	Treatments: 'Behandlinger',
	Treatment: 'Behandling',
	FetchTreatmentPlans: 'Hent',
	ExecutedDate: 'Udført den',
	Reactivate: 'Genaktiver',
	FromDate: 'Fra dato',
	ToDate: 'Til dato',
	User: 'Bruger',
	CountFirstTreatments: 'Antal 1. behandlinger',
	CountRetreatments: 'Antal genbehandlinger',

	//TreatmentDetails
	treatmentDetails: 'Behandlingsdetailjer',
	Treated: 'Behandlet',
	DeleteTreatmentPlan: 'Slet behandlingsforløb',
	DeleteTreatmentPlanPrompt: 'Slet hele behandlingsforløbet? Dette kan ikke fortrydes.',
	TreatmentPlanNotFound: 'Behandlingsforløb ikke fundet',
	PerAnimal: 'Pr. dyr',
	PerPig: 'Pr. gris',
	Total: 'I alt',
	OverdueAlertHeader: 'Behandling overskredet!',
	OverdueAlertMessage: 'Hvornår udførte du behandlingen?',
	OverdueAlertHeaderDepart: 'Død/aflivet',
	OverdueAlertMessageDepart: 'Hvilken dato skal anvendes for registreringen',
	CantRegisterANursingSow: 'Det er ikke lovligt at registrere afgang på en diegivende so',

	//Pregnancy lists
	PregnancyLists: 'Drægtighedslister',
	moveEvents: 'Flytninger',

	//GROWTH PIGS
	VALIDATION_ERROR_WEIGHT_NOT_DISTRIBUTED: 'Der er vægt, som ikke er fordelt',
	VALIDATION_ERROR_TO_MUCH_WEIGHT_DISTRIBUTED: 'Der er fordelt for meget vægt',
	VALIDATION_ERROR_PIGS_NOT_DISTRIBUTED: 'Der er grise, som ikke er fordelt',
	VALIDATION_ERROR_TO_MANY_PIGS_DISTRIBUTED: 'Der er fordelt for mange grise',
	VALIDATION_ERROR_TO_MANY_PIGS_MOVED: 'Der er flyttet for mange grise',
	VALIDATION_ERROR_TO_MANY_PIGS_DEPARTED: 'Der er afgået for mange grise',
	VALIDATION_ERROR_FROM_LOCATION_NOT_SET: 'Fra lokation er ikke sat',
	VALIDATION_ERROR_TO_LOCATION_NOT_SET: 'Til lokation er ikke sat',
	VALIDATION_ERROR_FROM_PRODUCTIONTYPE_NOT_SET: 'Fra produktions type er ikke sat',
	VALIDATION_ERROR_TO_PRODUCTIONTYPE_NOT_SET: 'Til produktions type er ikke sat',
	VALIDATION_ERROR_NO_PIGS_MOVED: 'Du har ikke flyttet nogen grise',
	VALIDATION_ERROR_NO_PIGS_DEPARTED: 'Du har ikke afgået nogen grise',
	GrowthPigEntrance: 'Købte',
	GrowthPigMoveEvent: 'Flytning',
	VALIDATION_REASON_NOT_SET: 'Ingen årsag sat',
	GrowthPigEntranceEvent: 'Købte',
	FromBuilding: 'Fra bygning',
	FromSection: 'Fra sektion',
	ToSection: 'Til sektion',
	ToBuilding: 'Til bygning',
	FromWeaner: 'Fra klimagrise',
	FromFinisher: 'Fra slagtegrise',
	FromPiglet: 'Fra fravænnede',
	GrowthPigDepartureEvent: 'Solgt/slagtet',
	GrowthPigPenDepartureEvent: 'Død/aflivet',
	productionType: 'Produktions type',
	toProductionType: 'Til produktion',
	fromProductionType: 'Fra produktion',
	AmountPigsNotDistributed: 'Antal grise, der ikke er fordelt',
	TotalWeightNotDistributed: 'Kg, der ikke er fordelt',
	PenNumber: 'Sti nr.',
	DistributePigsInSection: 'Fordel grise i sektion',
	SpecifyWeight: 'Fordel vægt',
	Distribute: 'Fordel',
	Bought: 'Købt',
	Moved: 'Flyttet',
	DistributeAll: 'Fordel alle',
	MoveAll: 'Flyt alle',
	DepartureAll: 'Afgang alle',
	weanerPigsEventsList: 'Hændelse liste',
	finisherPigsEventLists: 'Hændelse liste',
	DistributeWeight: 'Fordel vægt',
	fromBuildingName: 'Fra bygning',
	fromSectionName: 'Fra sektion',
	fromPenName: 'Fra sti',
	toBuildingName: 'Til bygning',
	toSectionName: 'Til sektion',
	toPenName: 'Til sti',
	GrowthPigEventsList: 'Hændelse liste',
	EventsList: 'Hændelse liste',
	toProctionType: 'Til produktion',
	fromProctionType: 'Fra produktion',
	YouHaveToSelectLocation: 'Du mangler at angive hvilken lokation dyrene skal knyttes til.',
	DistributeCount: 'Fordel salg',
	DistributeSlaughter: 'Fordel slagt',
	TotalPigsInPeriod: 'Antal grise i perioden',
	ShouldDepartureList: 'Afgangslisten',
	ShouldDepartureListWeaner: 'Afgangslisten',
	ShouldDepartureListFinisher: 'Afgangslisten',
	weanerShouldDepartureOverview: 'Afgangslisten',
	MovePigsOnLocation: 'Flyt grise i sektion',
	finisherShouldDepartureOverview: 'Afgangslisten',
	DistributeMoveFrom: 'Fordel flyt fra',
	DistributeTo: 'Fordel til',
	NegativePigAmountBeforeMove:
		'Der er et negativt antal grise i en/flere stier, hvilket gør at du reelt kun har {{area}} grise at flytte.\nDu bør udligne det negative antal grise i de stier, hvor der er et positivt antal grise.\nVil du flytte {{area}} grise?',
	SoldYoungAnimal: 'Solgt ungdyr',
	SoldSlaughteredYoungAnimal: 'Solgt/slagtet ungdyr',

	// Growth pig reports
	productionKey: 'PRODUKTIONSOMFANG',
	feedKey: 'FODER',
	diverseInformationKey: 'DIVERSE OPLYSNINGER',
	slaughterDataKey: 'SLAGTEDATA',
	stateKey: 'STATUS',
	subResultKey: 'MELLEMRESULTATER',
	FeedUsageKey: 'Foderforbrug',
	showFeedUsage: 'Foderforbrug',
	datagrowthPigPReportSlaughterHouseData: {
		amountBasicPcs: 'Basis, stk',
		amountOverPcs: 'Over, stk',
		amountUnderPcs: 'Under, stk',
		avgSlaughterWeight: 'Gns. Slagtevægt',
		avgMeatPercentage: 'Gns. kød procent',
		avgDiscarded: 'Pct. kasserede',
	},

	datagrowthPigPReportSubResults: {
		feedDays: 'Periodens foderdage',
		periodGrowth: 'Periodens tilvækst, kg',
	},

	datagrowthPigPReportDiverseInformation: {
		growthPerPigKg: 'Tilvækst pr. gris',
		avgWeightAtEntry: 'Gns. vægt ved indsættelse',
		avgWeightAtDeparture: 'Gns. vægt ved afgang',
		feedDaysPerProducedPigs: 'Foderdage pr. prod. grise',
		diversePercentDead: 'Procent døde',
	},
	datagrowthPigPReportProduction: {
		producedPigsPcs: 'Producerede grise, stk.	',
		dailyGrowth: 'Daglig tilvækst',
		referenceDailyGrowth: 'Reference daglig tilvækst',
	},

	datagrowthPigPReportState: {
		activePcs: 'Aktive, stk.',
		totalEntryInPeriod: 'Indgået, stk.',
		entryFromBought: 'Købte, stk.',
		entryFromTransfered: 'Overførte, stk.',
		entryFromWeaning: 'Fravænnet, stk.',
		avgWeightAtEntry: 'Gns. indgået vægt',
		deadPutDownPcs: 'Døde/aflivet, stk.',
		avgWeightDeadPutDown: 'Gns. vægt døde',
		soldTransferedPcs: 'Solgte/overførte, stk.',
		transferedToYoungFemale: 'Til polte, stk.',
		avgWeightSoldTransfered: 'Gns. vægt solgte/overførte',
		delieveredPcs: 'Leverede, stk.',
		avgWeightDelievered: 'Gns. vægt leverede',
		activeWeight: 'Gns. vægt',
		totalSlaughteredRegistrations: 'Afgang. slagtet',
		avgWeightSlaughteredRegistrations: 'Gns. vægt slagtet, kg',
	},

	datagrowthPigPReportFeed: {
		feedPerPigDaily: 'Foder pr. grise daglig, FEsv',
		feedPerKgGrowth: 'Foder pr. kg tilvækst, FEsv',
		referenceFeedUsage: 'Referencefoderudnyttelse pr. kg, FEsv',
		feedPerProducedPig: 'Foder pr. prod. grise, FEsv',
	},
	ProducedPigsPcs: 'Producerede grise, stk.	',
	DailyGrowth: 'Daglig tilvækst, g	',
	ReferenceDailyGrowth: 'Reference daglig tilvækst',
	WeanerReferenceDailyGrowth: 'Reference daglig tilvækst 7-30 kg',
	FinisherReferenceDailyGrowth: 'Reference daglig tilvækst 30-115 kg',
	FRATSReferenceDailyGrowth: 'Reference daglig tilvækst 7-115 kg',
	GrowthPerPigKg: 'Tilvækst pr. gris, kg	',
	AvgWeightAtEntry: 'Gns. vægt ved indsættelse, kg	',
	AvgWeightAtDeparture: 'Gns. vægt ved afgang, kg	',
	FeedDaysPerProducedPigs: 'Foderdage pr. prod. grise	',
	DiversePercentDead: 'Procent døde',
	FRATSActivePcs: 'FRATS, stk.',
	WeanerActivePcs: 'Klimagrise, stk.',
	FinisherActivePcs: 'Slagtegrise, stk.',
	TotalEntryInPeriod: 'Indgået, stk.',
	EntryFromWeaning: '\u2022 Heraf fravænnede, stk.',
	EntryFromBought: '\u2022 Heraf købte, stk.',
	EntryFromTransfered: '\u2022 Heraf overførte, stk.',
	DeadPutDownPcs: 'Døde/aflivet, stk.',
	AvgWeightDeadPutDown: 'Gns. vægt døde, kg	',
	SoldTransferedPcs: ' Solgte/overførte, stk.',
	TransferedToYoungFemale: '\u2022 Heraf til polte',
	AvgWeightSoldTransfered: 'Gns. vægt solgte/overførte, kg.	',
	DelieveredPcs: 'Leverede, stk.',
	TotalSlaughteredRegistrations: 'Afgang. slagtet',
	AvgWeightSlaughteredRegistrations: 'Gns. vægt slagtet, kg',
	AvgWeightDelievered: 'Gns. vægt leverede, kg.',
	FeedDays: 'Periodens foderdage',
	PeriodGrowth: 'Periodens tilvækst, kg	',
	AmountBasicPcs: 'Basis, stk.	',
	AmountOverPcs: 'Over basis, stk.	',
	AmountUnderPcs: 'Under basis, stk.	',
	AvgSlaughterWeight: 'Gns. slagtevægt, kg	',
	AvgMeatPercentage: 'Gns. kødprocent	',
	AvgDiscarded: 'Pct. kasserede',
	FeedPerPigDaily: 'Foder pr. grise daglig, FEsv',
	FeedPerKgGrowth: 'Foder pr. kg tilvækst, FEsv',
	ReferenceFeedUsage: 'Referencefoderudnyttelse pr. kg',
	WeanerReferenceFeedUsage: 'Referencefoderudnyttelse pr. kg  7-30 kg',
	FinisherReferenceFeedUsage: 'Referencefoderudnyttelse pr. kg 30-115 kg',
	FRATSReferenceFeedUsage: 'Referencefoderudnyttelse pr. kg  7-115 kg',
	FeedPerProducedPig: 'Foder pr. prod. grise, FEsv',
	weanerFoodTotal: 'Klimagrisefoder i alt',
	finisherFoodTotal: 'Slagtegrisefoder i alt',
	reportsWeaner: 'Rapporter',
	reportsFinisher: 'Rapporter',
	ReportsWeaner: 'Rapporter',
	ReportsFinisher: 'Rapporter',
	growthPigPReportProduction: 'PRODUKTIONSOMFANG',
	growthPigPReportDiverseInformation: 'DIVERSE OPLYSNINGER',
	growthPigPReportFeed: 'Foder',
	growthPigPReportState: 'STATUS',
	growthPigPReportSubResults: 'MELLEMRESULTATER',
	growthPigPReportSlaughterHouseData: 'SLAGTEDATA',
	Calculated: 'Beregnet',
	Tuned: 'Afstemt',
	tuneProductionReportWeaner: 'Afstem klimagrise',
	tuneProductionReportFinisher: 'Afstem slagtegrise',
	tuneProductionReportFRATS: 'Afstemning FRATS',
	weanerPigsTuneProductionLists: 'Afstem grise',
	finisherPigsTuneProductionLists: 'Afstem grise',
	Tune: 'Afstem',
	ActiveWeight: 'Gns. vægt',
	entered: 'Indgået',
	internalTransfer: 'Interne flytninger',

	//Mating
	mating: 'Løbning',
	Matings: 'Løbninger',
	Scannings: 'Scanninger',
	Farrowings: 'Faringer',
	Farrowed: 'Farret',
	Weanings: 'Fravænninger',
	Grade: 'Karakter',
	NumAlive: 'Antal i live',
	NumAliveTotal: 'I alt levendefødte',
	NumDead: 'Antal døde',
	NumDeadTotal: 'I alt dødfødte',
	TypeAnimalNumber: 'Indtast dyrnr.',
	ValidateAgeAtFirstMating: 'Alder ved første løbning skal være minimum {{area}} dage',
	ValidateSetAgeAtFirstMating:
		'Angiv alder ved første løbning under: Under indstillinger, Søer, Generelle, Hændelser, Løbning',
	ValidateSetMaxDaysAfterBoarDeparture:
		'Angiv Dage efter afgang en orne må anvendes: Under indstillinger, Søer, Generelle, Hændelser, Løbning',
	ValidateMaxDaysAfterBoarDeparture: 'En orne må anvendes max. {{area}} dage efter afgangsdato',
	ValidateNucleusBoarEntranceDate: 'Ornen var ikke indgået i besætningen på valgte dato',
	searchBoars: 'Søg orne',
	//Sow State
	Dry: 'Tom',
	Preg: 'Dræg',
	Nursing: 'Dieg',
	PregnantAndNursing: 'Dræg/Dieg',
	NursingLong: 'Diegivende',

	//Sow State Distriwin
	Gestation: 'Dræg',
	Maternity: 'Dieg',

	//Scanned
	True: 'Ja',
	False: 'Nej',
	Yes: 'Ja',
	No: 'Nej',
	Maybe: '?',
	scanning: 'Scanning',
	Pregnant: 'Drægtig',
	NonPregant: 'Ikke drægtige',

	//Farrowing
	farrowing: 'Faring',
	alive: 'Levende',
	dead: 'Døde',
	aliveFemale: 'Levende sogrise',
	aliveMale: 'Levende ornegrise',
	abortion: 'Kastning',
	farrowingInProgress: 'Farer',
	LitterWeight: 'Kuld vægt',
	Mumified: 'Mumificerede',
	Assign: 'Tildel',
	AssignId: 'Tildel IDnumre',
	EarNotch: 'Øreklip',
	LitterBreed: 'Kuldrace',
	ValidateAssignIdCount:
		'Antallet af grise stemmer ikke overens med det antal grise der er tildelt IDnumre.\n Vil du redigere i tildelte IDnumre?',
	CurrentSecondMatingNumber: 'Aktuel 2. løbenr.',
	ValidateNucleusAbortion: ' Der kan ikke registreres en kastning uden for intervallet {{area}}-{{area2}} dage',
	ValidateNoAssignedRegistrationsCheck: 'Der er ikke foretaget markering af ungdyr, der skal oprettes',
	//weaning
	pcsWithoutDot: 'stk',
	pcs: 'stk.',
	Pcs: 'Stk.',
	avgWeightSlashPig: 'vægt/gris',
	avgPriceSlashPig: 'pris/gris',
	avgWeight: 'Gns. vægt/gris',
	avgWeightShort: 'Gns. vægt',
	totalWeight: 'Total vægt',
	nursingSow: 'Ammeso',
	avgPrice: 'Pris pr. gris',
	totalPrice: 'Total pris',
	Weaning: 'Fravænning',
	nursSow: 'A',
	totalPcs: 'Total stk.',
	avgPcs: 'Gns. stk',
	defaultWeight: 'Standard vægt',

	//Mating batch
	matingBatch: 'Løbehold',
	AmountMatingBatchesPerColumn: 'Antal løbehold pr. kolonne',
	matingBatchUpToAndIncluding: 'Løbehold beregnet til og med',
	weekdayStart: 'Start dag i uge',
	StartDateForBatch: 'Startdato for løbehold',
	daysForMatingPeriod: 'Antal dage i løbeperiode',
	cycleInWeeks: 'So cyklus i uger',
	matingPeriod: 'Løbeperiode',
	FarrowingPeriod: 'Faringsperiode',
	batchNum: 'Hold nr.',
	pregnantDays: 'Drægtighedsdage',
	NursingDays: 'Diegivningsdage',
	nursingDaysIs: 'diegivningsdage',
	calculate: 'Beregn',
	addMatingBatch: 'Tilføj løbehold',
	deleteMatingBatch: 'Slet løbehold',
	weaningPeriod: 'Fravænningsperiode',
	Start: 'Start',
	End: 'Slut',
	deleteMatingBatchesFromDateAndForward: 'Slet Løbehold efter denne dato:',
	repeat: 'Gentag',
	manuel: 'Manuel',
	daysExceeded: 'Antal dage er overskredet',
	batchPerCycle: 'Antal hold - start hold 1 pr.',
	timesToRepeat: 'Antal gange',
	setStartDateToNextAvailableBatch: 'Sæt start dato til næste mulig løbehold?',

	// mating batch report
	matingBatchReport: 'Løbeholdsrapport',
	printMatingBatchReport: 'Print løbeholdsrapport',
	actualWeek: 'Aktuel uge',
	//mating
	TotalMatings: 'Løbninger i alt, stk.',
	YoungAnimalMatings: '\u2022 Heraf polte løbninger, stk.',
	TotalDepartures: 'Antal afgået fra holdet, stk.',
	DepartureNonPregnant: '\u2022 Heraf ikke drægtige',
	DepartureKilled: '\u2022 Heraf slagtet/solgt, stk.',
	DepartureDead: '\u2022 Heraf død/aflivet, stk.',
	AvgWasteDays: 'Gns. spildfoderdage',
	ReturnSows: '\u2022 Heraf omløbere',
	ThereOfAbortions: '\u2022 Heraf kastninger',
	WeaningToFirstMating: 'Dage fra fravænning til 1. løbning',
	AnimalsToFarrowing: 'Dyr til faring, stk.',
	//farrowing
	TotalFarrowings: 'Faringer i alt, stk.',
	NeedFarrowing: 'Manglende faringer til holdet, stk.',
	AvgAlive: 'Gns. levendefødte, stk.',
	AvgDead: 'Gns. dødfødte, stk.',
	FarrowingRate: 'Farings pct. holdet',
	AvgPregnantDays: 'Gns. drægtighedsdage',
	AvgPregnantDaysPreviousBatch: 'Gns. dræg.dage tidligere kuld',
	ThereOfFirstParity: '\u2022 Heraf 1. læg, stk.',
	AvgLivebornFirstParity: 'Gns. levendefødte 1. læg, stk.',
	TotalLiveBorn: 'I alt levendefødte, stk',
	StillbornToAliveRate: 'Dødfødte i pct. af totalfødte',
	FarrowingDate: 'Faringsdato',
	//weaning
	TotalWeanings: 'Fravænnede søer, stk.',
	NursingSows: '\u2022 Heraf ammesøer, stk.',
	NeedWeaning: 'Manglende fravænninger til holdet',
	AvgAlivePerLitter: 'Gns. levendefødte pr. kuld, stk.',
	AvgDeadPerLitter: 'Gns. dødfødte pr. kuld. stk.',
	AvgWeanedPerLitter: 'Gns. fravænnet pr. kuld, stk.',
	AvgWeight: 'Vægt pr. gris, kg.',
	DeadTillWeaning: 'Døde pattegrise, stk.',
	DeadToAliveRate: 'Døde indtil fravænning, pct',
	AvgNursingDays: 'Gns. diegivningsdage',
	NursingSowsCount: 'Ammesøer',
	AvgAliveShort: 'Gns. i live',
	AvgDeadShort: 'Gns. døde',
	AvgPriceShort: 'Gns. pris',
	AvgWeanedPerWeaninig: 'Fravænnede pr. fravænning, stk.',
	TotalWeanedPiglets: 'I alt fravænnet, stk.',
	amountToDistribute: 'Antal grise, der skal fordeles:',
	NoWeaningLocationSet: 'Ingen fravænningslokation sat',
	//Weekdays
	Monday: 'Mandag',
	Tuesday: 'Tirsdag',
	Wednesday: 'Onsdag',
	Thursday: 'Torsdag',
	Friday: 'Fredag',
	Saturday: 'Lørdag',
	Sunday: 'Søndag',

	//FeedingStock
	FeedingUsage: 'Foder forbrug',
	FeedingAdjustment: 'Foder indkøb',
	FeedingAdjustment_purchase: 'Foder indkøb',
	FeedingStock: 'Foderlager',
	FeedingLog: 'Foderlog',
	CreateFood: 'Opret foder',
	CreateUsage: 'Opret forbrug',
	EditFood: 'Rediger foder indkøb',
	EditFoodUsage: 'Rediger foder forbrug',
	CreatePurchase: 'Foder indkøb',
	FeedingType: 'Fodertype',
	FeedingSubject: 'Foderemne',
	FeedingSubjectName: 'Foderemne navn',
	AmountInKgs: 'Mængde i kg',
	FEsoPerKg: 'FEso/kg',
	purchase: 'Indkøb',
	FEsvPerKg: 'FEsv/kg',
	RawProtein: 'Råprotein % af vare',
	FosforGPerKg: 'Fosfor g/kg',
	PricePer100Kgs: 'Pris pr. 100 kg',
	PeriodFrom: 'Periode fra',
	PeriodTo: 'Periode til',
	UsageKg: 'Forbrugte kg',
	UsageFE: 'Forbrugte FE',
	UsageMoney: 'Forbrugte penge',
	UsageFEso: 'Forbrugte FEso',
	UsageFEsv: 'Forbrugte FEsv',
	feedingLog: 'Foderlog',
	FEso: 'FEso',
	FEsv: 'FEsv',
	KG: 'KG',
	NoRelation: 'Ingen relation',
	//Drug Stock
	DrugStock: 'Medicin/vaccine lager',
	adjustmentType: 'Reguleringstype',
	medicineLog: 'Præparatlog',
	registeredBy: 'Registeret af',
	registeredOn: 'Registerings dato',
	AddDrug: 'Tilfør præparat',
	lastRegulation: 'Seneste regulering',
	Waste: 'Spild',
	Usage: 'Forbrug',
	AddToDrugStock: 'Tilførsel',
	UpdateInventory: 'Beholdning',
	MedicineStockStorage: 'Beholdninger',
	UpdateMedicineStock: 'Regulering',
	UpdateDrugStockAmount: 'Opdatér lagerbeholdning',
	currentDrugStockAmount: 'Nuværende lagerbeholdning',
	newDrugStockAmount: 'Ny lagerbeholdning',
	createMedication: 'Opret præparat',
	Medicine: 'Behandlinger',
	Vaccine: 'Vaccinationer',

	//Sow Card
	SOWCARD: 'SOKORT',
	location: 'Lokation',
	LocationShort: 'Lok',
	currentLocation: 'Nuværende lokation',
	newLocation: 'Nye lokation',
	IDnr: 'IDnr.',
	comment: 'Kommentar',
	move: 'Flyt',
	moved: 'Flyttet',
	departed: 'Afgået',
	wasteDays: 'Spilddage',
	wasteDaysShort: 'Sp',
	weaningShort: 'Fravæn',
	pigYearSo: 'Grise/årsso',
	pregnantDaysShort: 'Drg',
	gradeShort: 'K',
	abortionSowCard: 'K',
	nursingDaysShort: 'Dieg',
	aliveShort: 'Lev',
	kilo: 'Kg',
	pregDays: 'Drægdage',
	nursDays: 'Diegdage',
	stillBorn: 'Dødfødte',
	lastMoved: 'Sidst flyttet',
	BreedingNr: 'Avlsnr.',
	BreedingNumberAndNumberBatch: 'Avlsnummer - nummerpuljer',
	//Location types
	Boars: 'Orner',
	Relief: 'Aflastning',
	ReliefFinisher: 'Aflastning slagtegrise',
	ReliefWeaners: 'Aflastning klimagrise',
	Holistic: 'Holistisk',
	Weaners: 'Klimagrise',
	ClimatePigs: 'Klimagrise',
	Finisher: 'Slagtegrise',
	locationType: 'Type',
	Silo: 'Silo',
	PostponeSows: 'Udsæt søer',
	WeanedPiglets: 'Fravænnede',

	//Animal types
	FRATS: 'FRATS',
	FeedMill: 'Fodercentral',
	Weaner: 'Klimagris',
	animalType: 'Grisetype',

	//Production
	production: 'Produktion',
	PigProduction: 'Svineproduktion',
	GrainMill: 'Korncentral',
	Feed: 'Foder',
	Mills: 'Mølleri',

	//ValidationSetup
	lowerLimit: 'Nedre grænse',
	upperLimit: 'Øvre grænse',
	plan: 'Plan',
	Sell: 'Salg',
	ToBeMoved: 'Flyttes',
	validation: 'Validering',
	weaningAnimals: 'Fravænnede grise',
	usingQRForSows: 'Anvendes der QR koder til søerne',
	usingQRForLocations: 'Anvendes der QR koder på lokationer',
	MatingAge: 'Alder ved løbning af ungdyr',
	MatingToFarrowing: 'Løbning til faring',
	FarrowingToWeaning: 'Faring til fravænning',
	AvertedToMating: 'Fra fravænning til 1. løbning',
	FarrowingToAverted: 'Faring til fravænning',
	systemMinimum: 'System minimum',
	systemMaximum: 'System maksimum',
	deviationMinimum: 'Afvigelse plan - minimum',
	deviationMaximim: 'Afvigelse plan - maksimum',
	weanedPigletsMovedToLocation: 'Fravænnede grise overføres til lokation:',

	// Component input
	Component: 'Komponent',
	Destination: 'Destination',
	NewDeliveryIn: 'Ny leverance ind',
	NewDeliveryInBulk: 'Ny bulk-leverance ind',
	NewDeliveryInBag: 'Ny bag-leverance ind',
	NoComponentsReceived: 'Ingen komponenter tilgængelige',
	ChooseComponent: 'Vælg komponent',
	ChooseSupplier: 'Vælg leverandør',
	ChooseTruck: 'Vælg lastbil',
	ChooseSourceSilo: 'Vælg source silo',
	ChooseDestinationSilo: 'Vælg destination silo',
	ChooseWarehouse: 'Vælg varehus',
	Weighing: 'Vejning',
	FirstWeighing: 'Første vejning',
	FirstWeighingShort: '1. vejning',
	SecondWeighing: 'Anden vejning',
	SecondWeighingShort: '2. vejning',
	StartTransport: 'Start transport',
	DeliveryInCreating: 'Ordren oprettes i FlexMix Pro',
	DeliveryInUpdating: 'Ordren opdateres i FlexMix Pro',
	DeliveryInCreatingSuccess: 'Ordren er blevet oprettet',
	DeliveryInUpdatingSuccess: 'Ordren er blevet opdateret',
	DeliveryInCreatingSuccessFmpOnly:
		'Ordren blev oprettet i FlexMix Pro, men der opstod et problem da den skulle gemmes i Skiold Digital',
	DeliveryInUpdatingSuccessFmpOnly:
		'Ordren blev opdateret i FlexMix Pro, men der opstod et problem da den skulle gemmes i Skiold Digital',
	DeliveryInSavingFailed: 'Der opstod et problem under overførslen til FlexMix Pro',
	DeliveryInSavingOrderNumber: 'Ordrenummeret er',
	TotalReceived: 'Total modtaget',
	SaveAndSendOrder: 'Gem og send ordre',
	TotalWeight: 'Samlet vægt',
	CancelDelivery: 'Bekræft lukning af levering',
	Order: 'Ordre',
	ActiveOrders: 'Aktive ordrer',
	OrderNumber: 'Ordrenummer',
	FilterByOrderNumberOrComponent: 'Filtrér efter ordrenummer eller komponent',

	//Work lists
	workLists: 'Arbejdslister',
	saveWorklist: 'Der er registreringer som ikke er gemt, skal de gemmes?',
	WorkLists: 'Arbejdslister',
	ChooseWorkLists: 'Vælg arbejdsliste',
	Synchronize: 'Synkroniser',
	Setup: 'Opsætning',
	pregnantFromDate: 'Drægtig fra dato',
	pregnantToDate: 'Drægtig til dato',
	chooseAnimalToList: 'Vælg dyr til liste',
	MoveTo: 'Flyt til',
	MoveToPregnancyLocation: 'Flyt til drægtighedslokation',
	MoveToFarrowingLocation: 'Flyt til farelokation',
	MoveToMatingLocation: 'Flyt til løbelokation',
	FarrowingListSetting: 'Faringsliste',
	WeaningListSetting: 'Fravænningsliste',
	MatingListSetting: 'Løbeliste',
	ScanningListSetting: 'Scanningliste 1',
	ScanningListSetting2: 'Scanningliste 2',
	LardScanningEventListSetting: 'Spæk scanningliste 1',
	LardScanningEventListSetting2: 'Spæk scanningliste 2',
	listAvailableOn: 'Liste tilgængelig på',
	columnTextShownAs: 'Kolonne tekst/vises som',
	daysAfterMating: 'Dage efter løbning',
	PregnacyDays: 'Drægtighedsdage',
	PregnancyDays: 'Drægtighedsdage',
	MatingBatch: 'Løbehold',
	Web: 'Web',
	App: 'App',
	SetupActivityList: 'Opsæt Aktivitetsliste',
	ActivityList: 'Aktivitets liste',
	activityLists: 'Aktivitets lister',
	expectedFarrowing: 'Forv. faring',
	expectedFarrowingDashboard: 'Forventet faring',
	completed: 'Udført',
	VaccineListSetting: 'Vaccination',
	VaccineListSetting1: 'Vaccination 1',
	VaccineListSetting2: 'Vaccination 2',
	VaccineListSetting3: 'Vaccination 3',
	TasksInFarrowingStableSetting: 'Opgaver i farestald 1',
	TasksInFarrowingStableSetting2: 'Opgaver i farestald 2',
	boarPerLitterPcs: '% ornegrise i kuldet',
	treatmentVaccination: 'Behandling/vaccination',
	toBetreated: 'Behandles',
	allPigs: 'Alle grise',
	boarsPigs: 'Ornegrise',
	daysAfterEvent: 'Dage efter hændelse',
	weeksAfterMating: 'Uger efter løbning',
	weeksAfterFarrowing: 'Uger efter faring',
	weeksAfterEvent: 'Uger efter hændelse',
	vaccineByLitterNumber: 'Vaccination til og med kuldnr.',
	showLocationOnList: 'Lokation vises på liste',
	calcByVaccinationPregnancyType: 'Vaccination beregnes i forhold til',
	showAnimalsOnList: 'Vis dyr på liste (Cyklusdage)',
	expectedFarrowingLong: 'Forventet faring',
	dateForWeaning: 'Dato for fravænning',
	dateForMating: 'Dato for løbning',
	Blank: 'Blank',
	chooseFeedCurve: 'Vælg foderkurve for',
	chooseReader: 'Vælg læser',
	feedCurve: 'Foderkurve',
	MoveFromPregToPregSetting: 'Flyt mellem drægtighedslokationer',
	MoveMatingToMatingSetting: 'Flyt mellem løbelokationer',
	maxDryDays: 'Max tomdage',
	newSow: 'Ny so',
	AgeForYoungFemales: 'Alder på polte',
	AgeYoungFemale: 'Alder (polte)',

	//Animal Report
	MatingBatchReportSetting: 'Løbehold',
	ActiveAnimalsReportSetting: 'Aktive dyr',
	ActivePoolYoungFemale: 'Pulje dyr',
	reports: 'Rapporter',
	report: 'Rapport',
	reportAvailableOn: 'Rapport tilgængelig på',
	AnimalNumberOnly: 'Kun Dyrnummer',
	AnimalNumberPerLocation: 'Dyrnr. pr. lokation',
	howToPresentAnimals: 'Hvordan skal dyrene præsenteres',
	ShowMatingBatchInLeftSide: 'Vis Løbehold i højre side',
	ShowFarrowingDateInRightSide: 'Vis Forventet faringsdato i højre side',
	RepeatAnimalNumberAtBottom: 'Gentag Dyrnr. forneden på sotavlen',
	ShowComment: 'Vis kommentar',
	SowBoardReportSetting: 'Sotavle',

	// Process equipment
	FailedToFetchNewestLocations: 'Det var ikke muligt at hente de nyeste lokationer fra process udstyret',

	processEquipment: 'Procesudstyr',
	removeProcessEquipment: 'Fjern procesudstyr?',
	setPensAutomaticValves: 'Sæt stier på ventiler',
	setPensAutomaticStations: 'Sæt stier på stationer',
	Valves: 'Ventiler',
	Stations: 'Stationer',
	StationNumbers: 'Stations numre',
	penNr: 'STINR.',
	relationProcessEquipment: 'DAN RELATIONER MELLEM LOKATIONER I PROCESUDSTYR OG SKIOLD digital',
	Units: 'Enheder',
	locations: 'LOKATIONER',
	locationsSkioldDigital: 'LOKATIONER SKIOLD digital',
	locationsProcessEquipment: 'LOKATIONER PROCESUDSTYR',
	unitsProcessEquipment: 'ENHEDER PROCESUDSTYR',
	createProcessEquipment: 'Opret procesudstyr',
	chooseProcessEquipment: 'Vælg procesudstyr',
	chooseUnits: 'Vælg enheder',
	relationLocation1: 'Dan relation',
	relationLocation2: 'procesudstyr/lokation',
	bindProcessEquipment: 'KNYT ENHEDER FRA PROCESUDSTYR TIL LOKATIONER I SKIOLD digital',
	unsavedRegisteredData: 'De registrerede data er ikke Gemt. Skal de gemmes?',
	processCode: 'Kode',

	// All animals esf
	AllAnimalsEsfListSetting: 'Alle dyr ESF',
	AWR250StickReader: 'AWR 250 Stick Reader',
	AWR300StickReader: 'AWR 300 Stick Reader',
	Reader: 'Læser',
	cycleDaysShownNegative: 'Cyklus dage vises negativ',
	eatenToday: 'ædt i dag',
	curve: 'Kurve',

	// All animals food list esf
	Yesterday: 'I går',
	DateAndTime: 'Dato, Klok',
	FE: 'FE',
	ShortageEsfListSetting: 'Levnedliste',
	MJEnergy1: 'MJ',
	KCALEnergy1: 'KCAL',
	FEsoFEsvEnergy1: 'FEso',
	FEsoFEsvEnergy2: 'FEsv',
	FEEnergy1: 'FE',
	FEEnergy2: 'FE',
	MJEnergy2: 'MJ',
	KCALEnergy2: 'KCAL',

	LastFourVisits: 'SIDSTE FIRE BESØG',
	Visit0: 'Sidste besøg',
	Visit1: '2. besøg',
	Visit2: '3. besøg',
	Visit3: '4. besøg',
	Rest: 'Rest',
	Eat: 'ædt',
	animalsOnListWhileDaysRationNotUnfed: 'Dyr, der har ædt under\n af dagsration,\nmedtages',

	//Agrident bluetooth
	connectToUnit: 'Forbind til bluetooth enhed',
	disonnect: 'Frakoble',
	startScan: 'Start scanning',
	bleSelect: 'Vælg',
	bleCancel: 'Annuller',
	bleOk: 'Ok',
	bleSave: 'Gem',
	chooseCurve: 'Vælg kurve',
	enterFixed: 'Indsæt fast %',
	model: 'Model',
	ID: 'ID',
	bluetoothIsDisabled: 'Bluetooth er ikke aktiveret',
	TodayFE: 'I dag FE',
	YesterdayFE: 'I går FE',
	Station: 'Station',
	restTodayFE: 'Rest i dag',
	eatenTodayFE: 'Ædt i dag',
	curveFE: 'Kurve',
	restYesterdayFE: 'Rest i går',
	eatenYesterdayFE: 'Ædt i går',
	searchBtUnits: 'Søg enheder',
	enableLocation: 'Placering er påkrævet for at bruge Bluetooth Low Energy service.',
	useGPS: 'Aktiver GPS',

	noCurveChosen: 'Ingen kurve valgt',

	// P-report
	CHOOSEREPORT: 'VÆLG RAPPORT',
	pReport: 'P-rapport',
	period: 'Periode',
	newPeriod: 'Ny periode',
	specialPeriod: 'Special periode',
	ReMatingPercent: 'Omløber, pct.',
	ThereOfGiltsPcs: '\u2022 Heraf gylte, stk.',
	AlivePerLitterPcs: 'Levendefødte pr. kuld, stk.',
	DeadPerLitter: 'Dødfødte pr. kuld, stk.',
	FarrowingPercent: 'Faringsprocent',
	AvgPregnantDaysPerLitter: 'Gns. drægtighedsdage pr. kuld',
	ThereOfFirstLitterPcs: '\u2022 Heraf 1. lægs kuld, stk.',
	FirstLitterPercent: '\u2022 Pct. 1. lægs kuld',
	NursingSowPercent: '\u2022 Pct. ammesøer',
	DeadTillWeaningPercent: 'Døde indtil fravænning, pct.',
	KeyNumbers: 'P-rapport, nøgletal',
	clearPeriods: 'Ryd perioder',
	SubResult: 'Mellemresultater',
	WeanedPerWeaningPcs: 'Fravænnede pr. fravænning, stk.',
	PReportFor: 'P-rapport for:',
	invalidDataIfPrivateSlaughter:
		'Der mangler angivelse af vægt på de slagtede grise, det er derfor ikke muligt at beregne P-rapporten',

	// Production scale
	YearSowPcs: 'Årssøer, stk.',
	WeanedPigletsPcs: 'Fravænnede grise, stk.',
	AvgWeightPerPiglet: 'Gns. vægt pr. gris',
	PercentDeadSowGilts: 'Pct. døde søer af årssøer',
	PeriodFeedDaysSowAndGilt: 'Periodens foderdage søer/gylte',
	PeriodFeedDaysYoungAnimal: 'Periodens foderdage polt',
	ProductionDays: 'Produktive dage',
	NonProductiveDays: 'Spildfoderdage',
	CorrectedDays: 'Korrigerede dage',
	PeriodYearSowPcs: 'Periodens årssøer, stk.',
	ProductionScale: 'Produktionsomfang',

	// Feed
	SowFoodFeYearSow: 'Sofoder FE/årsso',
	ThereOfYoungAnimalFood: '\u2022 Heraf poltefoder, FE/årsso',
	FePerYearSowPerDay: 'FE pr. årsso pr. dag',
	FePerYearYoungFemalePerDay: 'FE pr. polt pr. dag',
	// Reproduction
	WasteDaysPerDay: 'Spildfoder dage pr. kuld',
	ThereOfAdjustedDays: '\u2022 Heraf korrigerede dage',
	LitterPerYearSowPcs: 'Kuld pr. årsso, stk.',
	WeanedPigletsPerYearSowPcs: 'Fravænnede grise pr. årsso, stk.',
	ProducedLitters: 'Producerede kuld',
	Reproduction: 'Reproduktion',

	// Status
	SowAndGiltPcs: 'Søer/gylte, stk',
	SowAndGiltEntrance: '\u2022 Indgået, stk.',
	SowAndGiltDepartured: '\u2022 Afgået, stk.',
	SowAndGiltFeedDaysPcs: 'Foderdage Søer/gylte, stk.',
	SowAndGiltPregnantDays: 'Drægtighedsdage søer/gylte, stk.',
	YoungFemalePcs: 'Polte, stk.',
	YoungFemaleEntrance: '\u2022 Indgået, stk.',
	YoungFemaleEntranceToGilt: '\u2022 Overførte, stk.',
	YoungFemaleDepartured: '\u2022 Afgået, stk.',
	YoungFemaleFeedDaysPcs: 'Foderdage polte, stk.',
	BoarPcs: 'Orner, stk.',
	BoarEntrance: '\u2022 Indgået, stk.',
	BoarDepartured: '\u2022 Afgået, stk.',
	PigletPcs: 'Pattegrise, stk.',
	Departured: 'Afgået, stk.',

	// Feed usage

	FeedUsage: 'Foderforbrug',
	kgTotal: 'Kg i alt',
	feTotal: 'FE i alt',
	fePercent: 'Pct. af FE',
	kgPercent: 'Pct. af KG',
	sowFoodTotal: 'Sofoder i alt',
	youngFemaleFoodTotal: 'Poltefoder i alt',
	pigletFoodTotal: 'Pattegrisefoder i alt',
	allInAllFood: 'I alt forbrugt foder',
	// Sub results
	SubResults: 'Mellemresultater',

	// Dead piglets event
	deadPigletsEvent: 'Døde pattegrise',
	deadPigsEvent: 'Døde grise',
	sowCount: 'Antal søer',
	totalDeadPiglets: 'Antal døde grise',
	avgDeadPerSow: 'Gns. døde pr. so',
	deadPigs: 'Døde grise',
	deadPigletsGreaterThan1: 'Der kan ikke angives et IDnummer når stk. er større end 1',
	growthPigGreaterThan1: 'Der kan ikke angives et IDnummer når antal er større end 1',

	//Feeding report
	printFeedingReport: 'Print foderrapport',
	feedingReport: 'Foderrapport',

	// Pregnancy analysis
	maxBatchesIs27: 'Det er kun muligt at medtage i alt 27 løbehold på rapporten',

	// Resend Esf list
	resendEsfList: 'Gensend data til ESF',
	resend: 'Gensend',
	SowEvent: 'Hændelse',
	MoveEvent: 'Flytning',
	Entrance: 'Indgang',
	RequireSelectionOfPercentagesRed: 'Du skal angive intervallet hvori dyret skal markeres med rødt',
	RequireSelectionOfPercentagesGreen:
		'Du skal angive intervallet hvori dyret skal markeres med grønt, fra værdien, skal være størrere end røds interval til værdi',
	FeedingESFReportSetting: 'Foderrapport indstillinger',
	feedingESFSetupGreenNameText: 'Dyret markeres med grønt når:',
	feedingESFSetupRedNameText: 'Dyret markeres med rødt når:',
	dayRationNotEaten: '% af dagsrationen ikke er ædt',
	dayRationEaten: '% af dagsrationen er ædt',

	// Pool young female
	salePoolYoungFemale: 'Omsætning pulje polte',
	poolYoungFemale: 'Pulje polte',
	IdNumberDisableIfGreaterThan1: 'Det er ikke muligt at angive et IDnummer når Antal er større end 1',

	// Growth pigs events dashboard
	numOfPigs: 'Antal grise',
	entryWeight: 'Indgang',
	weightToday: 'Idag',
	days: 'Antal dage',
	dailyGain: 'Daglig vækst',
	feedPerPigToday: 'Idag',
	feedPerPigCurve: 'Kurve',
	feedPerPigAdjust: 'Justere',
	feedPerPigTotal: 'Foder',
	feedPerPigWeight: 'Vægt',
	totalDayX: 'I alt dag {{area}}',
	deadLastSevenDays: 'Død/aflivet',
	treatedLastSevenDays: 'Behandlet',
	lastSevenDays: 'Sidste 7 dage',
	expectedDeliveryDate: 'Forventet levering ved {{area}}kg i uge',
	expectedDeliveryAdjust: 'Justere',
	feedPerPig: 'Foder pr. gris',
	expectedDelivery: 'Forventet levering',
	lastBalanced: 'Sidst afstemt',
	deadLast7DaysTitle: 'Uddybning Døde/Aflivet',
	treatedLast7DaysTitle: 'Behandlet seneste 7 dage',
	enteredDashboard: 'Indgåede',
	departedDashboard: 'Afgåede',
	dateForBalance: 'Dato for afstemning',
	gpeDashboardMoved: 'Flyttede',
	identifierToFinisher: 'Til slagtegrise',
	identifierToWeaner: 'Til klimagrise',
	identifierToSow: 'Til sostald',
	identifierToYoungFemale: 'Til polte',
	identifierFromFinisher: 'Fra slagtegrise',
	identifierFromWeaner: 'Fra klimagrise',
	identifierFromSow: 'Fra sostald',
	enteredSince: 'Indgået siden',
	departedSince: 'Afgået siden',
	DashboardSubGoal: 'Delmål for foderforbrug/vægt ved',
	DashboardExpectedWeight: 'Vægt ved forventet salg',
	DieseaseCodes: {
		smell: 'Abnorm lugt (ikke normal kønslugt), smag, farve',
		'#': '',
		'181': 'Abnorm lugt (ikke normal kønslugt), smag, farve',
		'131': 'Afmagret',
		'113': 'Afvist fra slagtning, aflivet i ved levende syn, døende',
		'350': 'bughindebetændelse udbredt eller lokalt',
		'431': 'Akut børbetændelse',
		'221': 'Akut hjertesækbetændelse',
		'371': 'Akut leverbetændelse udbredt eller lokalt',
		'287': 'Akut lungehindebetændelse over og under 25%',
		'320': 'Akut mave/tarmbetændelse, akut katarralsk / fibrinøs',
		'402': 'Akut nyrebetændelse',
		'608': 'Akut rødsyge',
		'471': 'Akut yverbetændelse',
		'531': 'Akut, infektiøs ledbetændelse',
		'511': 'Akut, kronisk og lokal knoglemarvsbetændelse blodforgiftning',
		'258': 'Akut/subakut lungebetændelse og lungenekrose over og under 25%',
		'602': 'Ar / trykning',

		'337': 'Ballongris, rektal striktur',
		'903': 'Bidsår',
		'584': 'Byld ben/tå',
		'580': 'Byld i bagpart',
		'570': 'Byld i forpart',
		'203': 'Byld i hjernen, CNS symptomer (stald)',
		'585': 'Byld i hoved, blodøre, krølleøre',
		'577': 'Byld i midterstykke',

		'114': 'Død i stald',
		'111': 'Død ved ankomst',

		'671': 'Forfrysning/ætsning',
		'101': 'Forstyrret almenbefindende, ophidset / kuller / uvane / udmattet',
		'941': 'Forurening galde',
		'930': 'Forurening, gødning',
		'942': 'Forurening, olie',
		'504': 'Frisk halebrud',
		'506': 'Frisk ribbensbrud',
		'501': 'Friskt knoglebrud',

		'502': 'Gammelt knoglebrud',
		'382': 'Gulsot (fysiologisk, neonatal)',
		'381': 'Gulsot (toksisk, infektiøs, følge af leverlidelse)',

		'601': 'Halebid / haleinfektion',
		'600': 'Halebid, lokalt, afgrænset',
		'535': 'Halthed VF, HF, VB, HB',
		'505': 'Helet halebrud',
		'507': 'Helet ribbensbrud',
		'230': 'Hjerteklapbetændelse akut og afhelet (slagtegrise og søer og orne)',
		'542': 'Hofteskred / ledskred',
		'551': 'Højgradig og lavgradig PSE/DFD',

		'503': 'Inficeret knoglebrud, åbent brud > 6 t.',
		'668': 'Injektionsskade',

		'829': 'Kaseøs lymfadenitis / TB lignende forandringer',
		'995': 'Kasseret',
		'120': 'Kredsløbsforstyrrelse (dårlig afblødning), anæmisk udseende, vattersot, væskeansamlinger',
		'352': 'Kronisk bughindebetændelse, byld i bughinde',
		'432': 'Kronisk børbetændelse, tilbageholdt efterbyrd, udfuldbyrdet forløsning, børprolaps',
		'222': 'Kronisk hjertesækbetændelse',
		'532': 'Kronisk ledbetændelse, slidgigt',
		'379': 'Kronisk leverbetændelse, levernekrose',
		'271': 'Kronisk lungebetændelse, aerogene lungebylder',
		'289': 'Kronisk lungehindebetændelse, serositis',
		'325': 'Kronisk mave/tarmbetændelse',
		'412': 'Kronisk nyrebetændelse inkl. nyredegeneration og nyrenekrose, nyrecyster',
		'472': 'Kronisk yverbetændelse',

		'132': 'Mager',
		'890': 'Malign svulst, godartet svulst',
		'336': 'Mavesår',
		'565': 'Mistanke om anmeldepligtig sygdom',
		'815': 'Mistanke om forgiftning eller medicinrester',
		'409': 'Mugnyre',
		'572': 'Muskelatrofi',

		'361': 'Navlebrok, lyskebrok, pungbrok',
		'451': 'Nylig fødsel, kastning, foster sidste tiendedel af graviditeten (mistanke)',
		'250': 'Nysesyge, sinusitis, rhinitis',
		'115': 'Nødslagtet (rundstempling',

		'385': 'Ormepletter',

		'141':
			'Pyæmi, blodforgiftning, pyæmiske lungebylder, miltbetændelse – blodforgiftning, nyrebetændelse - blodforgiftning',

		'331': 'Rektalprolaps, tarmfremfald',
		'993': 'Rundstemplet',

		'643': 'Sarcoptesskab hos svin',
		'446': 'Skederuptur, skedebetændelse, skedeprolaps',
		'615': 'Skuldersår',
		'901': 'Slagmærker, hud ikke menneskepåførte',
		'904': 'Slagmærker, menneskepåførte inkl., overtatovering',
		'912': 'Slagtefejl/maskinskade',
		'603': 'Sår, betændelse, eksem, insektbid',

		'18': 'PSE Kassation 50 %',
		'37': 'Skinnet so',
		'46': 'Farvet aftegn',
		'49': 'Halv orne, tvekønnet',
		'83': 'Afvist pga. medicin',
		'89': 'Manglende sværte',
		'94': 'Farvet aftegn -5 %',
		'96': 'Manglede øremærke',

		'11': 'Ukendt',
		'30': 'Ukendt',
		'34': 'Ukendt',
		'913': 'Ukendt',
		'939': 'Ukendt',
		'973': 'Ukendt',
		'990': 'Ukendt',
	},

	// Balance weaned pigs
	sold: 'Solgt',
	PigletWeanedEvent: 'Fravænnet',
	PigletDepartureSoldEvent: 'Solgt',
	PigletMovedEvent: 'Fravænnede',
	WeanedOnStable: ' Fravænnede på stald',
	SellWeanedPigs: 'Salg fravænnede grise',
	DistributeWeanedPigs: 'Fordel fravænnede grise',
	BalanceWeanedPigs: 'Afstem fravænnede grise',
	balanceWeanedPigs: 'Afstem fravænnede grise',
	NotDistributed: 'Ikke fordelt',
	ToDistributed: 'Fordeles',
	ToBeDistributed: 'Skal fordeles',
	PigletMovedEventDash: 'Fravænnede',

	// General settings
	GeneralSettings: 'Generelle indstillinger',
	General: 'Generelle',
	Generalt: 'Generelt',
	RegisterAliveFemales: 'Registrer levende sogrise',
	RegisterAliveMales: 'Registrer levende ornegrise',
	WeanedPigsToLocation: 'Grise fravænnes til lokation',
	SetupEvent: 'Hændelser',
	SetupRegistration: 'Registrering',
	TreatmentRetentionTime: 'Behandlinger - Slagtefirst',
	HandleRetentionTime: 'Håndter slagtefrist ved flytning',
	IsLettersUsedForAnimal: 'Anvendes der bogstaver i dyrnr.',
	IsLettersUsedForBoar: 'Anvendes der bogstaver i ornenumre.',
	PigDeliveryToPrivateSlaughterhouse: 'Grise leveres til privatslagteri',
	SowRegistrations: 'So registreringer',
	Communication: 'Kommunikation',
	SendDataToDistriwin: 'Skal vægtreguleringer udveksles med Distriwin',
	EnergyIndicatedBy: 'Energi angives i',
	FetchDistriwinFeedingData: 'Skal der hentes foderforbrug fra Distriwin',
	MinAgeInDaysFirstMating: 'Minimums alder på dyr ved 1. løbning',
	MaxDaysAfterBoarDepartureSemen: 'Dage efter afgang en orne må anvendes',
	ShowFamilyTree: 'Vis ikon for stamtræ',
	ShowRaceForLitter: 'Vis racen på kuldet',
	RegisterLitterWeight: 'Registrer kuld vægt på fødte grise',
	RegisterMumifiedPigs: 'Registreres mumificerede grise',
	MapYoungAnimalsToLocation: 'Oprettede ungdyr skal knyttes til lokation',
	ShouldShowDadMomData: 'Skal far/mor data vises',
	ShouldShowInitialsForRegistrations: 'Skal initialer på registreringer vises',
	RegisterEarNotch: 'Registrer øreklip',
	CalculateEarNotch: 'Beregn øreklip',
	WeekStartyDay: 'Uge start dag',
	ShowRaceLine: 'Skal der angives en Race linje',
	UsageShownAs: 'Skal forbruget angives i procent af',
	ShouldUsageBeAttachedToRecipe: 'Skal foderforbruget knyttes til en recept',
	SendEntranceDepartureToDistriwin: 'Skal registrering af ind-/afgange sendes til Distriwin',
	AssignNumbersToBornPiglets: 'Tildel numre til fødte sogrise',
	SendCycleDaysAsNegative: 'Skal der sendes negative cyklus dage',
	RegisterIdForTurnoverPoolYoungAnimals: 'Skal der registreres IDnummer ved omsætning af Pulje Polte',
	NotifyCapacity: 'Advarsel når kapaciteten overskrides med',
	RegisterIdForGpeDeparture: 'Skal der registreres IDnummer ved Afgang',
	ShareDataWithSegesInsight: 'Skal der udveksles data med SEGES InSight',
	// Gpe regulations
	Regulations: 'Reguleringer',
	WeightRegulation: 'Vægt regulering',
	EditRegulation: 'Rediger regulering',

	Updated: 'Opdateret',
	viewControllers: 'Se Kontrollere',
	ControllerNumber: 'Kontroller nummer',
	// Distriwin Component
	createRelation: 'Dan relation',
	VALIDATION_EqualDistriwinAndSkioldEnergy: 'Energi i Distriwin og SKIOLD digital skal være ens',
	FEkg: 'FE/kg',
	MJkg: 'MJ/kg',
	KCALkg: 'KCAL/kg',
	FEsokg: 'FEso/kg',
	FEsvkg: 'FEsv/kg',

	FETotal: 'Total FE',
	KCALTotal: 'Total KCAL',
	MJTotal: 'Total MJ',
	FEsoFEsvTotal: 'Total FE',

	FEPct: 'Pct. af FE',
	KCALPct: 'Pct. af KCAL',
	MJPct: 'Pct. af MJ ',
	FEsoFEsvPct: 'Pct. af FE',
	//Distriwin feed registrations
	Energy: 'Energi',
	CurveRation: ' Kurve ration',

	// Nucleus management
	NucleusManagement: 'KerneStyring',
	UserName: 'Brugernavn',
	Connected: 'Forbundet',
	NoConnectionFound: 'Ingen forbindelse',
	UpdatePassword: 'Opdater kodeord',
	UpdateLogin: 'Opdater login',
	Provider: 'Udbyder',
	Connections: 'Forbindelser',
	BreedingNumber: 'Avlsnummer',
	Fetch: 'Hent',
	FetchData: 'Hent data',
	Send: 'Send',
	SendData: 'Send/udlæs data til excel fil',
	DataSendFrequency: 'Hvor ofte skal data sendes',
	DataSendTime: 'Data sendes',
	Current: 'Aktuelt',
	FinalNumber: 'Slutnummer',
	CreateBreedingNr: 'Opret avlsnr.',
	CreateNumberBatch: 'Opret nummer pulje',
	FirstMatingNr: '1. løbenr',
	SecondMatingNr: '2. løbenr',
	Letter: 'Bogstav',
	SetupBreedingNumber: 'Opsætning Avlsnummer',
	NumberBatch: 'Nummerpulje',
	NumberBatches: 'Nummerpuljer',
	DataFetching: 'Data hentes',
	Daily: 'Hver dag',
	Weekly: 'Hver uge',
	Monthly: 'Hver måned',
	Manual: 'Manuel',
	Weekday: 'Ugedag',
	IndexAnimals: 'Indeks på dyr i besætningen',
	KsBoarHerds: 'KS-orner fra følgende besætninger',
	InvalidHerdsAlert: 'Besætnings formatet er forkert, formatet skal være: xxxx,xxxx format, op til 10 besætninger',
	NotAllInfoAlert: 'Udfyld venligts alle felter',
	FetchSetupNotRelevant: 'Denne gård er ikke tilknyttet et kernestyings licens',
	FileType: 'Filtype',
	NoEmailProvided: 'Udfyld venligst mindst en mail adresse',
	InvalidEmailProvided: 'Den udfyldte mail adresse er ugyldig',
	SendManually: 'Send data',
	CreateConnectionFirstError: 'Du mangler at logge ind på KerneStyrings ubyder på "Forbindelser" siden',
	SelfManaged: 'CEFN (AU)',
	NoNucleusManagementLicence: 'Denne gård har ikke KerneStyring, eller er CEFN (AU)',
	ValidateBreedingNumber: 'Ugyldigt avlsnummer',
	ValidateRace: 'Vælg race',
	ValidateGender: 'Vælg køn',
	ValidateNumberBatc1Start: 'Sæt 1. løbenr.',
	ValidateNumberBatc2Start: 'Sæt start 2. løbenr.',
	ValidateNumberBatc1End: 'Sæt 1. løbenr.',
	ValidateNumberBatc2End: 'Sæt slut 2. løbenr.',
	ValidateCurrentNumberBatch: 'Sæt aktuel 2. løbenr.',
	ValidatePriority: 'Sæt prioritet på rækker med samme race',
	ValidateNoSowFound: 'Der er ikke fundet en so',
	KS: 'KS',
	Nat: 'Nat',
	EntranceAIBoar: 'KS',
	EntranceBoar: 'Nat',
	IsAIBoar: 'Er KS orne',
	ValidateNoBreedRace: 'Ingen kuldrace',
	ValidateNoMaleOrFemale: 'Indtast levende so- eller ornegrise',
	ValidateNoBreedingNumber: 'Soen har ikke et avlsnr.',
	ValidateExistingIdNumbers: 'Følgende IDnumre findes allerde {{area}}',
	CredentialsAreInvalid: 'Brugernavn eller password er forkert',
	Archive: 'Arkiv',
	ArchiveSendData: 'Arkiv sendte data',
	FileArchive: 'Filarkiv',
	FileName: 'Filnavn',
	SentDate: 'Sendt dato',
	Open: 'Åben',
	RaceLine: 'Race linje',

	// Family tree
	FamilyTree: 'Stamtræ',
	Family: {
		TheAnimal: 'Dyret',
		Mother: 'Mor',
		Father: 'Far',
		MotherGrandmother: 'Mormor',
		MotherGrandfather: 'Morfar',
		FatherGrandmother: 'Farmor',
		FatherGrandfather: 'Farfar',
	},

	// Breed table
	DadBreedRace: 'Farrace',
	MomBreedRace: 'Morrace',
	SKIOLD: 'SKIOLD digital',
	DANBRED: 'Danbred',
	DANBRED_NUMBER: 'Danbred tal',
	DANISH_GENETICS: 'Danish Genetics',
	DANISH_GENETICS_ID_NUMMER: 'DanishGenetics Bogstav',
	AddNewBreed: 'Tilføj ny race',
	DanishGenetics: 'Danish Genetics',
	InProgress: 'I gang',

	// Daily feed usage
	DailyFeedUsageInt: 'Foderforbrug {{area}}',
	Day: 'Dag',
	FUCurve: 'FE kurve',
	FUPigDay: 'FE/gris/dag',
	finisherDailyFeedUsage: 'Dagligt foderforbrug',
	weanerDailyFeedUsage: 'Dagligt foderforbrug',
	NoAdjustmentOnDate: 'Der er ikke lavet nogen ændringer på denne dato',
	Old: 'Gammel',
	New: 'Ny',
	Adjustments: 'Ændringer',
	DailyFeedUsageWeaner: 'Overblik dagligt foderforbrug på APP',
	DailyFeedUsageProcessFinisher: 'Overblik dagligt foderforbrug på APP',
	EnergyPrPig: 'Energi pr. gris',

	// Sow Task Worklist
	CalculateTreatment: 'Behandlingen beregnes i forhold til',
	SowWeightAtTreatment: 'Vægt pr. so, der behandles',
	GiltWeightAtTreatment: 'Vægt pr. gylt, der behandles',
	MatingPregnant: 'Løbning - Drægtig',
	FarrowingNursing: 'Faring - Diegivende',
	WeaningDry: 'Fravænning - Tom',
	SowTaskSettings1: 'Opgaver ved søer 1',
	SowTaskSettings2: 'Opgaver ved søer 2',
	SpecifyMLTreatment: 'Angiv ml ved valgte rækker',
	ChoseState: 'Vælg tilstand',
	SetWeight: 'Sæt vægt',

	ChangeFeed: 'Ændre foder',
	FetchSiteInformation: 'Hent gård information',
};
