import ObjectID from 'bson-objectid';
import React from 'react';
import { Option } from 'react-dropdown';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
	DistriwinFeedUnitType,
	Feeding,
	FeedingAdjustment,
	FeedingAmountType,
	FeedingSubject,
	IFeedingAdjustment,
	IFeedingCategory,
	IFeedingSubject,
} from 'shared/api/api';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { SaveFeedingSubject } from 'shared/state/ducks/feeding-subjects/operations';
import { saveFeeding } from 'shared/state/ducks/feeding/operations';
import { localized, localizedInterpolation } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import PageContainer from 'web/view/components/page-container/page-container';
import { showAlert } from 'web/view/components/skiold-alert/skiold-alert';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldFormDropdown } from 'web/view/components/skiold-components/skiold-dropdown/skiold-form-dropdown';
import { SkioldFormsWrapper } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { Heading } from 'web/view/components/utils/heading';
import 'web/view/styles/stock-styles/add-item.scss';
import { SkioldDatePicker } from '../skiold-components/skiold-date-picker/skiold-date-picker';
import { SkioldFormDecimalInput } from '../skiold-components/skiold-decimal-input/skiold-form-decimal-input';
import { SkioldFormTextField } from '../skiold-components/skiold-forms-wrapper/skiold-form-text';
import { SkioldFormInput } from '../skiold-components/skiold-input/skiold-form-input';
import { ViewWeb } from '../utils/web-view';
import { GetFeedingCategory, GetFeedingName, GetFeedingSubject, sortFoodCategories } from './food-helper';
import { NaturalSort } from 'shared/helpers/natural-sort';
import { getAnimalTypesInUse } from 'shared/helpers/general-helpers';
import { SetGlobalDate } from 'shared/state/ducks/general-settings/operations';
import { getFeedUnitType } from 'shared/helpers/feeding/feeding-helper';

interface PropsFromParent {
	feedingSubjectId?: string;
	feedingToEdit?: string;
	adjustmentToEdit?: string;
	closeModal: () => void;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		siteId: state.profile.active!.siteId,
		feedings: state.feeding.feedings,
		feedingCategories: state.feedingCategory.entities,
		feedingSubjects: state.feedingSubject.entities,
		profile: state.profile.active!,
		userProfile: state.profile.active!,
		globalDate: state.generalSettings.globalDate,
		feedUnitType: getFeedUnitType(state),
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	saveFeeding: (feeding: Feeding) => saveFeeding(feeding)(dispatch),
	saveFeedingSubject: (feeding: FeedingSubject) => SaveFeedingSubject(feeding)(dispatch),
	setGlobalDate: (date: Date) => SetGlobalDate(date)(dispatch),
});

interface State {
	amount?: number;
	feeding: Feeding;
	feedingAdjustment: FeedingAdjustment;
	feedingSubjectName?: string;
	feedingCategoryName?: string;
	selectedFeedingCategoryOption?: Option;
	selectedFeedingSubjectOption?: Option;
	hasDefinedFeedSubject: boolean;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

export class AddFood extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		let { feeding, feedingAdjustment, feedingSubjectName, feedingCategoryName } = this.getInitialData();
		if (this.props.feedingToEdit && this.props.adjustmentToEdit) {
			({ feeding, feedingAdjustment, feedingSubjectName, feedingCategoryName } = this.getEditData());
		} else if (this.props.feedingSubjectId) {
			({ feeding, feedingAdjustment, feedingSubjectName, feedingCategoryName } = this.getPurchaseData(
				this.props.feedingSubjectId,
				feedingAdjustment,
			));
		}
		this.state = {
			amount: feedingAdjustment.amount,
			selectedFeedingSubjectOption: { label: '', value: '' },
			feeding,
			feedingAdjustment,
			feedingSubjectName,
			feedingCategoryName,
			selectedFeedingCategoryOption: { label: '', value: '' },
			hasDefinedFeedSubject: false,
		};
	}

	public render() {
		return (
			<PageContainer>
				<Heading
					text={
						this.props.feedingSubjectId
							? localized('CreatePurchase')
							: this.props.adjustmentToEdit
							? localized('EditFood')
							: localized('CreateFood')
					}
				/>
				<ViewWeb className="add-item">
					<SkioldFormsWrapper formRows={this.getFormRows()} />
					<ViewWeb className="add-item-button-container">
						<SkioldButton theme="grey" title={localized('Close')} onPress={this.props.closeModal} />
						<SkioldButton title={localized('Save')} onPress={this.save} />
					</ViewWeb>
				</ViewWeb>
			</PageContainer>
		);
	}

	private save = async () => {
		if (!this.validate()) {
			return;
		}
		let { feeding, date, adjustment } = this.SetInitialSaveData();
		if (!this.props.feedingSubjectId && !this.props.adjustmentToEdit) {
			adjustment = this.setCreateDataForAdjustment(adjustment);
			feeding = this.setCreateDataForFeeding(feeding, date, adjustment);
			if (this.state.feedingSubjectName) {
				const feedingSubjectId = new ObjectID().toHexString();
				const feedingSubject = FeedingSubject.fromJS({
					name: { da: this.state.feedingSubjectName },
					feedingCategoryId: this.state.selectedFeedingCategoryOption!.value!,
					siteId: this.props.siteId,
					id: feedingSubjectId,
				});
				feeding.feedingSubjectId = feedingSubjectId;
				await this.props.saveFeedingSubject(feedingSubject);
			}
		} else if (this.props.feedingSubjectId && !this.props.adjustmentToEdit) {
			adjustment = this.setCreateDataForAdjustment(adjustment);
			feeding.adjustments!.push(adjustment);
			if (adjustment.date) {
				this.props.setGlobalDate(adjustment.date);
			}
		} else if (feeding.adjustments && this.props.adjustmentToEdit) {
			feeding.adjustments[feeding.adjustments!.findIndex(a => a.id === adjustment.id)] = adjustment;
		}
		await this.props.saveFeeding(feeding);
		this.props.closeModal();
	};

	private SetInitialSaveData() {
		let date = new Date();
		let adjustment = {
			...this.state.feedingAdjustment,
			energy1PerKg:
				this.state.feedingAdjustment.energy1PerKg &&
				Number(this.state.feedingAdjustment.energy1PerKg!.toFixed(2)),
			energy2PerKg:
				this.state.feedingAdjustment.energy2PerKg &&
				Number(this.state.feedingAdjustment.energy2PerKg!.toFixed(2)),
			rawProtein:
				this.state.feedingAdjustment.rawProtein && Number(this.state.feedingAdjustment.rawProtein!.toFixed(2)),
			fosforGPerKg:
				this.state.feedingAdjustment.fosforGPerKg &&
				Number(this.state.feedingAdjustment.fosforGPerKg!.toFixed(2)),
			type: FeedingAmountType.FeedingAdjustment,
			amount: this.state.amount!,
			modifiedOn: date,
			modifiedBy: this.props.profile.id,
		} as FeedingAdjustment;
		let feeding = {
			...this.state.feeding,
			lastAdjusted: date,
		} as Feeding;
		if (this.state.feeding.adjustments) {
			feeding.adjustments = [...this.state.feeding.adjustments];
		}
		return { feeding, date, adjustment };
	}

	private validate() {
		if (
			this.state.hasDefinedFeedSubject &&
			this.state.selectedFeedingSubjectOption &&
			this.state.selectedFeedingSubjectOption.label === ''
		) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_FEEDINGSTOCK_NO_FEEDING_SUBJECT_SELECTED));
			return false;
		}

		if (!this.state.hasDefinedFeedSubject && !this.state.feedingSubjectName) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_FEEDINGSTOCK_NO_FEEDING_SUBJECT_NAME));
			return false;
		}

		if (!this.props.feedingSubjectId) {
			if (!this.state.selectedFeedingSubjectOption) {
				showAlert(localized(ExceptionMessage.VALIDATION_ERROR_FEEDINGSTOCK_NOT_SELECTED));
				return false;
			}
		}

		if (!this.state.amount) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_FEEDINGSTOCK_AMOUNT_NOT_SET));
			return false;
		}

		if (
			this.state.feedingAdjustment.energy1PerKg === undefined ||
			this.state.feedingAdjustment.energy1PerKg.toString() === ''
		) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_FEEDINGSTOCK_FESOW_NOT_SET));
			return false;
		}

		if (
			this.state.feedingAdjustment.energy2PerKg === undefined ||
			this.state.feedingAdjustment.energy2PerKg.toString() === ''
		) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_FEEDINGSTOCK_FEPIG_NOT_SET));
			return false;
		}

		// if (
		// 	this.state.feedingAdjustment.fosforGPerKg === undefined ||
		// 	this.state.feedingAdjustment.fosforGPerKg.toString() === ''
		// ) {
		// 	showAlert(localized(ExceptionMessage.VALIDATION_ERROR_FEEDINGSTOCK_FOSFOR_NOT_SET));
		// 	return false;
		// }

		// if (
		// 	this.state.feedingAdjustment.pricePer100Kg === undefined ||
		// 	this.state.feedingAdjustment.pricePer100Kg.toString() === ''
		// ) {
		// 	showAlert(localized(ExceptionMessage.VALIDATION_ERROR_FEEDINGSTOCK_PRICE_NOT_SET));
		// 	return false;
		// }

		if (this.props.adjustmentToEdit) {
			let amountThatNeedToBeAvailable = 0;
			let totalAvailableWithoutTheEditedAdjustment = 0;
			this.state.feeding
				.adjustments!.filter(a => a.isDeleted === false)
				.forEach(adjust => {
					if (adjust.id !== this.props.adjustmentToEdit) {
						if (adjust.type === FeedingAmountType.FeedingUsage) {
							amountThatNeedToBeAvailable = amountThatNeedToBeAvailable + adjust.amount!;
						} else {
							totalAvailableWithoutTheEditedAdjustment =
								totalAvailableWithoutTheEditedAdjustment + adjust.amount!;
						}
					}
				});
			const numberThatHasToBeOverZeroOrZero =
				totalAvailableWithoutTheEditedAdjustment - amountThatNeedToBeAvailable + this.state.amount;
			if (numberThatHasToBeOverZeroOrZero < 0) {
				showAlert(
					localized(ExceptionMessage.VALIDATION_ERROR_TOTAL_AMOUNT_TO_LOW_TO_CONTAIN_ALREADY_USED_FOOD) +
						-(totalAvailableWithoutTheEditedAdjustment - amountThatNeedToBeAvailable),
				);
				return false;
			}
		}
		return true;
	}

	private getFormRows() {
		let formRows = new Array<FormRow>();
		formRows.push(this.getDateRow());
		if (!this.state.feedingCategoryName) {
			this.CreateFoodRows(formRows);
		} else {
			this.PurchaseFoodRows(formRows);
		}

		formRows.push(this.getAmountInKgRow());
		formRows.push(this.getEnergy1PerkgRow());
		this.props.feedUnitType === DistriwinFeedUnitType.FEsoFEsv && formRows.push(this.getEnergy2PerkgRow());
		formRows.push(this.getRawProteinRow());
		formRows.push(this.getFosforRow());
		formRows.push(this.getPricePer100KgRow());
		return formRows;
	}
	private PurchaseFoodRows(formRows: FormRow[]) {
		formRows.push(this.getFeedingCategoryTextRow());
		formRows.push(this.getFeedingSubjectTextRow());
	}

	private CreateFoodRows(formRows: FormRow[]) {
		formRows.push(this.getFeedingTypeRow());
		const dropdownItems = this.getFeedingSubjectsOptions();
		if (dropdownItems.length !== 0) {
			formRows.push(this.getFeedingSubjectRow(dropdownItems));
		}

		if (
			(dropdownItems.length === 0 &&
				this.state.selectedFeedingCategoryOption &&
				this.state.selectedFeedingCategoryOption.value !== '') ||
			this.state.selectedFeedingSubjectOption!.value === '5d528754186cf325b03f7f1b'
		) {
			formRows.push(this.getFeedingSubjectInputRow());
		}
	}

	private getDateRow(): FormRow {
		return {
			name: localized('Date'),
			component: (
				<SkioldDatePicker
					onDateChanged={this.onDateChanged}
					selectedDate={this.state.feedingAdjustment!.date}
					theme={'dark'}
					color={'grey'}
				/>
			),
		};
	}

	private getFeedingCategoryTextRow(): FormRow {
		return {
			name: localized('Type'),
			component: <SkioldFormTextField>{this.state.feedingCategoryName}</SkioldFormTextField>,
		};
	}

	private getFeedingSubjectTextRow(): FormRow {
		return {
			name: localized('FeedingSubject'),
			component: <SkioldFormTextField>{this.state.feedingSubjectName}</SkioldFormTextField>,
		};
	}

	private getFeedingTypeRow(): FormRow {
		return {
			name: localized('FeedingType'),
			component: (
				<SkioldFormDropdown
					items={this.getFeedingCategoriesOptions()}
					selectedValue={this.state.selectedFeedingCategoryOption}
					onValueChanged={this.feedingTypeChanged}
				/>
			),
		};
	}

	private getFeedingSubjectRow(option: Option[]): FormRow {
		return {
			name: localized('FeedingSubject'),
			component: (
				<SkioldFormDropdown
					items={option}
					selectedValue={this.state.selectedFeedingSubjectOption}
					onValueChanged={this.feedingSubjectChanged}
				/>
			),
		};
	}

	private getFeedingSubjectInputRow(): FormRow {
		return {
			name: localized('FeedingSubjectName'),
			component: (
				<SkioldFormInput
					onChangeText={this.onFeedingTextChanged}
					text={this.state.feedingSubjectName ? this.state.feedingSubjectName : ''}
				/>
			),
		};
	}

	private getAmountInKgRow(): FormRow {
		return {
			name: localized('AmountInKgs'),
			component: <SkioldFormDecimalInput onChangeNumber={this.onAmountInKgChanged} text={this.state.amount} />,
		};
	}

	private getEnergy1PerkgRow(): FormRow {
		return {
			name: localizedInterpolation('EnergyKg', this.props.feedUnitType + 'Energy1'),
			component: (
				<SkioldFormDecimalInput
					onChangeNumber={this.onEnergy1PerKgChanged}
					numberOfDecimals={2}
					text={this.state.feedingAdjustment.energy1PerKg}
				/>
			),
		};
	}

	private getEnergy2PerkgRow(): FormRow {
		return {
			name: localizedInterpolation('EnergyKg', this.props.feedUnitType + 'Energy2'),
			component: (
				<SkioldFormDecimalInput
					onChangeNumber={this.onEnergy2PerKgChanged}
					numberOfDecimals={2}
					text={this.state.feedingAdjustment.energy2PerKg}
				/>
			),
		};
	}

	private getRawProteinRow(): FormRow {
		return {
			name: localized('RawProtein'),
			component: (
				<SkioldFormDecimalInput
					onChangeNumber={this.onRawProteinChanged}
					numberOfDecimals={2}
					text={this.state.feedingAdjustment.rawProtein}
				/>
			),
		};
	}

	private getFosforRow(): FormRow {
		return {
			name: localized('FosforGPerKg'),
			component: (
				<SkioldFormDecimalInput
					onChangeNumber={this.onFosforGPerKgChanged}
					numberOfDecimals={2}
					text={this.state.feedingAdjustment.fosforGPerKg}
				/>
			),
		};
	}

	private getPricePer100KgRow(): FormRow {
		return {
			name: localized('PricePer100Kgs'),
			component: (
				<SkioldFormDecimalInput
					onChangeNumber={this.onPricePer100KgChanged}
					numberOfDecimals={2}
					text={this.state.feedingAdjustment.pricePer100Kg}
				/>
			),
		};
	}

	private onAmountInKgChanged = (number: number | undefined) => {
		this.setState({ amount: number ? Number(number.toFixed(1)) : undefined });
	};

	private onEnergy1PerKgChanged = (number: number | undefined) => {
		let feedingAdjustment = { ...this.state.feedingAdjustment } as FeedingAdjustment;
		feedingAdjustment.energy1PerKg = number!;
		if (this.props.feedUnitType !== DistriwinFeedUnitType.FEsoFEsv) {
			feedingAdjustment.energy2PerKg = number!;
		}
		this.setState({
			feedingAdjustment: feedingAdjustment,
		});
	};

	private onEnergy2PerKgChanged = (number: number | undefined) => {
		this.propertyChanged(number, 'energy2PerKg');
	};

	private onRawProteinChanged = (number: number | undefined) => {
		this.propertyChanged(number, 'rawProtein');
	};

	private onFosforGPerKgChanged = (number: number | undefined) => {
		this.propertyChanged(number, 'fosforGPerKg');
	};

	private onPricePer100KgChanged = (number: number | undefined) => {
		this.propertyChanged(number, 'pricePer100Kg');
	};

	private getFeedingCategoriesOptions() {
		if (!this.state.selectedFeedingCategoryOption) {
			return [];
		}
		const animalTypes = getAnimalTypesInUse(this.props.userProfile);
		const sortedFoodCategories = sortFoodCategories(this.props.feedingCategories);
		return sortedFoodCategories
			.filter(fc => fc.animalTypes && fc.animalTypes.some(r => animalTypes.indexOf(r) >= 0))
			.map<Option>((dt: IFeedingCategory) => {
				return {
					label: dt.name!['da'],
					value: dt.id!,
				};
			});
	}

	private getFeedingSubjectsOptions() {
		if (!this.state.selectedFeedingCategoryOption) {
			return [];
		}

		return this.props.feedingSubjects
			.filter(
				feedingSubject =>
					this.state.selectedFeedingCategoryOption &&
					!this.props.feedings.find(a => a.feedingSubjectId === feedingSubject.id) &&
					feedingSubject.feedingCategoryId === this.state.selectedFeedingCategoryOption.value &&
					!feedingSubject.siteId,
			)
			.sort((a, b) => NaturalSort(a.order, b.order))
			.map<Option>((dt: IFeedingSubject) => {
				return {
					label: dt.name!['da'],
					value: dt.id!,
				};
			});
	}

	public onFeedingTextChanged = text => {
		this.setState({ feedingSubjectName: text });
	};

	public onDateChanged = (newDate: Date) => {
		this.propertyChanged(newDate, 'date');
	};

	private setCreateDataForAdjustment(adjustment: FeedingAdjustment) {
		return {
			...adjustment,
			createdBy: this.props.profile.id,
			createdOn: adjustment.modifiedOn,
			id: new ObjectID().toHexString(),
		} as FeedingAdjustment;
	}

	private getAnimalKindsForFeedingCategory(feedingCategoryId: string) {
		return this.props.feedingCategories.find(a => a.id === feedingCategoryId)!.animalTypes;
	}

	private feedingSubjectChanged = (option: Option) => {
		this.setState({ selectedFeedingSubjectOption: option });
	};

	private setCreateDataForFeeding(feeding: Feeding, date: Date, adjustment: FeedingAdjustment): Feeding {
		feeding.siteId = this.props.siteId;
		feeding.adjustments = [adjustment];
		feeding.feedingSubjectId = this.state.selectedFeedingSubjectOption!.value!;
		feeding.animalTypes = this.getAnimalKindsForFeedingCategory(this.state.selectedFeedingCategoryOption!.value!);
		return feeding;
	}

	private getPurchaseData(feedingSubjectId: string, feedingAdjustment: FeedingAdjustment) {
		const feeding = this.props.feedings.find(f => f.feedingSubjectId === feedingSubjectId) as Feeding;
		const filteredAdjustments = feeding.adjustments!.filter(a => a.type === FeedingAmountType.FeedingAdjustment);
		const lastestAdjustment = filteredAdjustments[filteredAdjustments.length - 1] as FeedingAdjustment;
		feedingAdjustment = {
			...feedingAdjustment,
			date: this.props.globalDate,
			energy1PerKg: lastestAdjustment.energy1PerKg,
			energy2PerKg: lastestAdjustment.energy2PerKg,
			fosforGPerKg: lastestAdjustment.fosforGPerKg,
			rawProtein: lastestAdjustment.rawProtein,
			pricePer100Kg: lastestAdjustment.pricePer100Kg,
		} as FeedingAdjustment;

		const { feedingSubjectName, feedingCategoryName } =
			this.getFeedingSubjectNameAndFeedingCategoryName(feedingSubjectId);
		return { feeding, feedingAdjustment, feedingSubjectName, feedingCategoryName };
	}

	private feedingTypeChanged = (option: Option) => {
		if (option.value !== this.state.selectedFeedingCategoryOption!.value) {
			this.setState({ selectedFeedingSubjectOption: { label: '', value: '' }, feedingSubjectName: '' });
		}
		const hasSubject = this.props.feedingSubjects.findIndex(
			feedingSubject => option && feedingSubject.feedingCategoryId === option.value && !feedingSubject.siteId,
		);
		const hasDefinedFeedSubject = hasSubject === -1 ? false : true;
		this.setState({ selectedFeedingCategoryOption: option, hasDefinedFeedSubject, feedingSubjectName: '' });
	};

	private getEditData() {
		const feeding = this.props.feedings.find(f => f.id === this.props.feedingToEdit) as Feeding;
		const feedingAdjustment = feeding.adjustments!.find(
			a => a.id === this.props.adjustmentToEdit,
		) as FeedingAdjustment;
		const { feedingSubjectName, feedingCategoryName } = this.getFeedingSubjectNameAndFeedingCategoryName(
			feeding.feedingSubjectId!,
		);
		return { feeding, feedingAdjustment, feedingSubjectName, feedingCategoryName };
	}

	private getInitialData() {
		const adjustmentDate = new Date();
		const feeding = Feeding.fromJS({ startDate: adjustmentDate });
		const feedingAdjustment = FeedingAdjustment.fromJS({
			date: adjustmentDate,
			type: FeedingAmountType.FeedingAdjustment,
		});
		let feedingSubjectName: string | undefined;
		let feedingCategoryName: string | undefined;
		return { feeding, feedingAdjustment, feedingSubjectName, feedingCategoryName };
	}

	private getFeedingSubjectNameAndFeedingCategoryName(feedingSubjectId: string) {
		const feedingSubject = GetFeedingSubject(this.props.feedingSubjects, feedingSubjectId);
		const feedingCategory = GetFeedingCategory(this.props.feedingCategories, feedingSubject!.feedingCategoryId!);
		const feedingSubjectName = GetFeedingName(feedingSubject!, this.props.profile);
		const feedingCategoryName = GetFeedingName(feedingCategory!, this.props.profile);
		return { feedingSubjectName, feedingCategoryName };
	}

	private propertyChanged(newValue: any, propertyName: keyof IFeedingAdjustment) {
		let feedingAdjustment = { ...this.state.feedingAdjustment } as FeedingAdjustment;
		// @ts-ignore
		feedingAdjustment[propertyName] = newValue;
		if (propertyName === 'date') {
			let feeding = { ...this.state.feeding } as Feeding;
			feeding['startDate'] = newValue;
			this.setState({
				feeding: feeding,
				feedingAdjustment: feedingAdjustment,
			});
		} else {
			this.setState({
				feedingAdjustment: feedingAdjustment,
			});
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AddFood);
