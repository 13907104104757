import { IValveGroupHistory2 } from 'shared/api/api';

export const FETCH_HISTORY = 'FETCH_HISTORY';
export const FETCH_LATEST_HISTORY = 'FETCH_LATEST_HISTORY';

export interface HistoryState {
	histories: IValveGroupHistory2[];
	isLoading: boolean;
	latestHistories: IValveGroupHistory2[];
}
