import { Option } from 'react-dropdown';
import { AnimalKind, IReasonDto } from 'shared/api/api';
import { DepartureTypes } from 'shared/state/models/departure-types';
import { StoreContainer } from 'shared/state/store-container';
import { localized } from '../../state/i18n/i18n';

export const DefaultReasonOption: Option = { label: ' ', value: '' };

export function getReasonOptions(reasonsDto: IReasonDto[], animalKind: AnimalKind, departureType: string): Option[] {
	if (!departureType) {
		return [];
	}

	let state = StoreContainer.getState();

	let reasons: IReasonDto[];

	let isDeathType = DepartureTypes.IsDeathType(departureType);
	reasons = reasonsDto.filter(reason => animalKindValidation(animalKind, reason, isDeathType));

	reasons = sortReasons(reasons);

	let reasonsForSelect: Option[] = reasons.map(reason => {
		return {
			label: reason.reason!.name![
				state.profile.active && state.profile.active.language ? state.profile.active.language : 'da'
			],
			value: reason.reason!.id!,
		};
	});
	reasonsForSelect.unshift(DefaultReasonOption);
	return reasonsForSelect;

	function sortReasons(reasons: IReasonDto[]) {
		reasons = reasons.sort((reasonA, reasonB) => {
			return reasonA!.reason!.priorityCode! > reasonB!.reason!.priorityCode! ? 1 : -1;
		});

		return reasons;
	}
}

export function getReasonOptionsByDepartureType(reasonsDto: IReasonDto[], animalKind: AnimalKind, departureType: string): Option[] {
	if (!departureType) {
		return [];
	}

	let state = StoreContainer.getState();

	let reasons: IReasonDto[];

	reasons = reasonsDto.filter(reason => animalKindAndDepartureTypeValidation(animalKind, reason, departureType));

	reasons = sortReasons(reasons);

	let reasonsForSelect: Option[] = reasons.map(reason => {
		return {
			label: reason.reason!.name![
				state.profile.active && state.profile.active.language ? state.profile.active.language : 'da'
			],
			value: reason.reason!.id!,
		};
	});
	reasonsForSelect.unshift(DefaultReasonOption);
	return reasonsForSelect;

	function sortReasons(reasons: IReasonDto[]) {
		reasons = reasons.sort((reasonA, reasonB) => {
			return reasonA!.reason!.priorityCode! > reasonB!.reason!.priorityCode! ? 1 : -1;
		});

		return reasons;
	}
}
function animalKindAndDepartureTypeValidation(animalKind: AnimalKind, reasonsDto: IReasonDto, departureType: string) {
	if (reasonsDto.reasonSettings) {
		if (
			animalKind === AnimalKind.Boar ||
			animalKind === AnimalKind.Gilt ||
			animalKind === AnimalKind.Sow ||
			animalKind === AnimalKind.YoungFemale
		) {
			if (DepartureTypes.IsDeathType(departureType)) {
				return reasonsDto.reasonSettings.stemAnimalDead;
			} else {
				return reasonsDto.reasonSettings.stemAnimalDeparture;
			}
		}
		if (animalKind === AnimalKind.Piglet) {
			if (departureType === DepartureTypes.departureTypeDead) {
				return reasonsDto.reasonSettings.pigletDead;
			}
			if (departureType === DepartureTypes.departureTypePutDown) {
				return reasonsDto.reasonSettings.pigletPutDown;
			}
		}
		if (animalKind === AnimalKind.Weaner) {
			if (departureType === DepartureTypes.departureTypeDead) {
				return reasonsDto.reasonSettings.weanerDead;
			}
			if (departureType === DepartureTypes.departureTypePutDown) {
				return reasonsDto.reasonSettings.weanerPutDown;
			}
		}
		if (animalKind === AnimalKind.Finisher) {
			if (departureType === DepartureTypes.departureTypeDead) {
				return reasonsDto.reasonSettings.finisherDead;
			}
			if (departureType === DepartureTypes.departureTypePutDown) {
				return reasonsDto.reasonSettings.finisherPutDown;
			}
		}
	}
	return false;
}

function animalKindValidation(animalKind: AnimalKind, reasonsDto: IReasonDto, isDeathType: boolean) {
	if (reasonsDto.reasonSettings) {
		if (
			animalKind === AnimalKind.Boar ||
			animalKind === AnimalKind.Gilt ||
			animalKind === AnimalKind.Sow ||
			animalKind === AnimalKind.YoungFemale
		) {
			if (isDeathType) {
				return reasonsDto.reasonSettings.stemAnimalDead;
			} else {
				return reasonsDto.reasonSettings.stemAnimalDeparture;
			}
		}
		if (animalKind === AnimalKind.Piglet) {
			if (isDeathType) {
				return reasonsDto.reasonSettings.pigletDead;
			}
		}
		if (animalKind === AnimalKind.Weaner) {
			if (isDeathType) {
				return reasonsDto.reasonSettings.weanerDead;
			}
		}
		if (animalKind === AnimalKind.Finisher) {
			if (isDeathType) {
				return reasonsDto.reasonSettings.finisherDead;
			}
		}
	}
	return false;
}

export function getReasonNameById(reasonId: string | undefined, language?: string, reasonDtos?: IReasonDto[]) {
	if (reasonId) {
		if (!reasonId.match(/^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i)) {
			return localized(reasonId);
		}
		let reasons = reasonDtos;
		let langToUse = language;
		if (!reasons) {
			let state = StoreContainer.getState();
			reasons = state.reasons.entities;
			langToUse = state.profile.active && state.profile.active.language ? state.profile.active.language : 'en';
		}
		let reason = reasons.find(reason => reason.reason && reason.reason.id === reasonId);

		if (reason && reason.reason && reason.reason.name && langToUse) {
			return reason.reason.name[langToUse];
		}
	}

	return undefined;
}
function IsDeathType(departureType: string) {
	throw new Error('Function not implemented.');
}

