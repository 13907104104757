import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { AnalysisPregnancyState } from '.';
import { siteChange } from '../../profile/actions';
import * as actions from './actions';
import { clearAnalysisData } from '../actions';

export const initialState: AnalysisPregnancyState = {
	data: undefined,
	loading: false,
};

const reducer = (
	state: AnalysisPregnancyState = initialState,
	action: Action
): AnalysisPregnancyState => {
	if (isType(action, actions.getData.started)) {
		state = { ...state, loading: true };
	}

	if (isType(action, actions.getData.done)) {
		state = {
			...state,
			data: action.payload.result,
			loading: false,
		};
	}
	if (isType(action, actions.getData.failed)) {
		state = { ...state, loading: false };
	}

	if (isType(action, clearAnalysisData.done) ) {
		state = initialState;
	}

	if (isType(action, siteChange.done)) {
		state = initialState;
	}
	return state;
};

export default reducer;
