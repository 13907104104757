import memoize from 'memoize-one';
import moment from 'moment';
import { Dispatch } from 'redux';
import {
	AnimalType,
	GrowthPigEventType,
	GrowthPigsEvent,
	IBuilding,
	IGrowthPigEventDto,
	IGrowthPigsEvent,
	IPen,
	IReasonDto,
	ISection,
} from 'shared/api/api';
import { AddDaysToDate } from 'shared/helpers/date-helpers';
import {
	getGrowthPigsDepartureEventsByDates,
	getGrowthPigsEntranceEventsByDates,
	getGrowthPigsMoveEventsByDates,
	removeEventsByBundleId,
	removeGrowthPigsEventByIds,
} from 'shared/state/ducks/growth-pig-events/operations';
import { LocationsState } from 'shared/state/ducks/locations';
import { localized } from 'shared/state/i18n/i18n';
import { DepartureTypes } from 'shared/state/models/departure-types';
import { SharedAppState } from 'shared/state/store.shared';
import { NaturalSort, NaturalSortDates } from '../natural-sort';
import { memoizeLocation } from '../memoize-getters/memoize-getters';
import { getPenRetentionTime, getSectionRetentionTime } from '../location-helper';
import { DefaultGrowthTypeDepartureTypeOption } from './growth-pig-event-helper';

export const GrowthPigEventListOptionsArray = () => [
	{ label: localized(GrowthPigEventType.GrowthPigEntranceEvent), value: GrowthPigEventType.GrowthPigEntranceEvent },
	{ label: localized(GrowthPigEventType.GrowthPigMoveEvent), value: GrowthPigEventType.GrowthPigMoveEvent },
	{
		label: localized('Departure'),
		value: GrowthPigEventType.GrowthPigDepartureEvent,
	},
	{
		label: localized('WeightRegulation'),
		value: GrowthPigEventType.Regulation,
	},
];

export type GrowthPigSummaryRowItem =
	| GrowthPigEntranceEventListRow
	| GrowthPigMoveEventListRow
	| GrowthPigDepartureEventListRow;

export type GrowthPigSummaryBundleRowItem =
	| GrowthPigEntranceEventBundleListRow
	| GrowthPigMoveEventBundleListRow
	| GrowthPigDepartureEventBundleListRow;

export interface GrowthPigEntranceEventListRow {
	toBuildingName?: string;
	toSectionName?: string;
	toPenName?: string;
	locationString?: string;
	date?: Date;
	pcs?: number;
	avgWeight: number;
	entranceWeight: number;
	retentionDate?: Date;
	productionType?: AnimalType;
	correctProductionType?: AnimalType;
	isLatest?: boolean;
	hasRetentionTime: boolean;
	id?: string;
}

export interface GrowthPigEntranceEventBundleListRow {
	toBuildingName?: string;
	toSectionName?: string;
	locationString?: string;
	date?: Date;
	pcs?: number;
	avgWeight: number;
	entranceWeight: number;
	retentionDate?: Date;
	productionType?: AnimalType;
	correctProductionType?: AnimalType;
	hasRetentionTime: boolean;
	bundleId?: string;
	gpeIds: string[];
	sectionId: string;
	singleEvent?: GrowthPigsEvent;
}

export interface GrowthPigMoveEventListRow {
	fromLocation?: string;
	fromBuildingName?: string;
	fromSectionName?: string;
	fromPenName?: string;
	toBuildingName?: string;
	toSectionName?: string;
	toPenName?: string;
	toLocation?: string;
	date?: Date;
	pcs?: number;
	avgWeight: number;
	weight: number;
	fromProductionType?: AnimalType;
	toProductionType?: AnimalType;
	correctFromProductionType?: AnimalType;
	correctToProductionType?: AnimalType;
	hasFromRetentionTime: boolean;
	hasToRetentionTime: boolean;
	isLatest?: boolean;
	id?: string;
}

export interface GrowthPigMoveEventBundleListRow {
	fromBuildingName?: string;
	fromSectionName?: string;
	toBuildingName?: string;
	toSectionName?: string;
	fromLocation?: string;
	toLocation?: string;
	date?: Date;
	pcs?: number;
	avgWeight: number;
	weight: number;
	fromProductionType?: AnimalType;
	toProductionType?: AnimalType;
	correctFromProductionType?: AnimalType;
	correctToProductionType?: AnimalType;
	hasFromRetentionTime: boolean;
	hasToRetentionTime: boolean;
	bundleId?: string;
	gpeIds: string[];
	fromSectionId: string;
	toSectionId: string;
}

export interface GrowthPigDepartureEventListRow {
	eventType?: GrowthPigEventType;
	fromBuildingName?: string;
	fromSectionName?: string;
	fromPenName?: string;
	locationString?: string;
	date?: Date;
	pcs?: number;
	isLatest?: boolean;
	avgWeight: number;
	departedWeight: number;
	productionType?: AnimalType;
	correctProductionType?: AnimalType;
	reason?: string;
	departuredType?: string;
	hasRetentionTime: boolean;
	id?: string;
	animalIdNumber?: string;
}

export interface GrowthPigDepartureEventBundleListRow {
	eventType?: GrowthPigEventType;
	fromBuildingName?: string;
	fromSectionName?: string;
	locationString?: string;
	date?: Date;
	pcs?: number;
	avgWeight: number;
	departedWeight: number;
	productionType?: AnimalType;
	correctProductionType?: AnimalType;
	reason?: string;
	departuredType?: string;
	hasRetentionTime: boolean;
	bundleId?: string;
	gpeIds: string[];
	fromSectionId: string;
	animalIdNumber?: string;
}

export interface GrowthPigEventListDefaultPropsFromParent {
	productionType: AnimalType;
	routeName?: string;
	navigation?: any;
	topRight?: (topRight: JSX.Element) => void;
	topLeft?: (topLeft: JSX.Element) => void;
	dateFrom?: Date;
	dateTo?: Date;
}

export interface GrowthPigMoveEventListTablePropsFromParent {
	animalType: AnimalType;
	bundleData?: GrowthPigMoveEventBundleListRow[];
	setRef?: (ref: any) => void;
	openEditModal?: (event: GrowthPigMoveEventBundleListRow) => void;
	removeItem?: (event: GrowthPigEntranceEventBundleListRow) => Promise<void> | undefined;
	routeName?: string;
	onFilterChanged?: () => void;
	navigation?: any;
}

export interface GrowthPigEntranceEventListTablePropsFromParent {
	//	data: GrowthPigEntranceEventListRow[];
	bundleData?: GrowthPigEntranceEventBundleListRow[];
	setRef?: (ref: any) => void;
	openEditModal?: (event: GrowthPigEntranceEventListRow) => void;
	removeItem?: (event: GrowthPigEntranceEventBundleListRow) => Promise<void> | undefined;
	routeName?: string;
	onFilterChanged?: () => void;
	openBundleEditModal?: (event: GrowthPigEntranceEventBundleListRow) => void;
	navigation?: any;
}

export interface GrowthPigDepartureEventListTablePropsFromParent {
	bundleData?: GrowthPigDepartureEventBundleListRow[];
	setRef?: (ref: any) => void;
	openEditModal?: (event: GrowthPigDepartureEventBundleListRow) => void;
	removeItem?: (event: GrowthPigEntranceEventBundleListRow) => Promise<void> | undefined;
	routeName?: string;
	onFilterChanged?: () => void;
	usePrivateSlaughterhouse?: boolean;
}

type GrowthPigEventListState =
	| GrowthPigMoveEventListState
	| GrowthPigEntranceEventListState
	| GrowthPigDepartureEventListState;

export const CheckIfGrowthPigEventIsBetweenTwoDates = (
	props: GrowthPigEventListDefaultPropsFromParent,
	productionType: AnimalType,
) => {
	return (gpe: GrowthPigsEvent) =>
		gpe.date &&
		props.dateFrom &&
		props.dateTo &&
		gpe.date >= props.dateFrom &&
		gpe.date <= props.dateTo &&
		productionType === gpe.toProductionType;
};
export const CheckIfMoveGrowthPigEventIsBetweenTwoDates = (
	props: GrowthPigEventListDefaultPropsFromParent,
	productionType: AnimalType,
) => {
	return (gpe: GrowthPigsEvent) =>
		gpe.date &&
		props.dateFrom &&
		props.dateTo &&
		gpe.date >= props.dateFrom &&
		gpe.date <= props.dateTo &&
		(gpe.toProductionType === productionType || gpe.fromProductionType === productionType);
};

export const CheckIfDepartureGrowthPigEventIsBetweenTwoDates = (
	props: GrowthPigEventListDefaultPropsFromParent,
	productionType: AnimalType,
) => {
	return (gpe: GrowthPigsEvent) =>
		gpe.date &&
		props.dateFrom &&
		props.dateTo &&
		gpe.date >= props.dateFrom &&
		gpe.date <= props.dateTo &&
		productionType === gpe.fromProductionType;
};
export interface GrowthPigMoveEventListState extends GrowthPigMoveEventListTablePropsFromParent {
	dateFrom: Date;
	dateTo: Date;
	event?: GrowthPigsEvent;
	bundleEvent?: GrowthPigMoveEventBundleListRow;
}

export interface GrowthPigEntranceEventListState extends GrowthPigEntranceEventListTablePropsFromParent {
	dateFrom: Date;
	dateTo: Date;
	event?: GrowthPigsEvent;
	bundleEvent?: GrowthPigEntranceEventBundleListRow;
}

export interface GrowthPigDepartureEventListState extends GrowthPigDepartureEventListTablePropsFromParent {
	dateFrom: Date;
	dateTo: Date;
	event?: GrowthPigsEvent;
	isDistributeWeightModalOpen: boolean;
	pigCount?: number;
	gpeIds?: string[];
	bundleEvent?: GrowthPigDepartureEventBundleListRow;
	totalWeight?: number;
}

export const GrowthPigEventListMapStateToProps = (state: SharedAppState) => {
	return {
		locations: memoizeLocation(
			state.locations.buildings,
			state.locations.sections,
			state.locations.pens,
			state.locations.valves,
		),
		growthPigsEvents: state.growthPigEvents.growthPigEvents,
		gped: state.growthPigEvents.entities,
		profile: state.profile.active,
		reasons: state.reasons.entities,
		lang: state.profile.active && state.profile.active.language,
		usePrivateSlaughterhouse: state.generalSettings.entity.usePrivateSlaughterhouse,
	};
};

export const GrowthPigEventListEntranceMapDispatchToProps = (dispatch: Dispatch) => ({
	getGrowthPigsEntranceEventsByDates: (dateFrom: Date, dateTo: Date) =>
		getGrowthPigsEntranceEventsByDates(dateFrom, dateTo)(dispatch),
	removeGrowthPigsEvent: removeGrowthPigEventFromListByBundleId(dispatch),
});

export const GrowthPigEventListDepartureMapDispatchToProps = (dispatch: Dispatch) => ({
	getGrowthPigsDepartureEventsByDates: (dateFrom: Date, dateTo: Date) =>
		getGrowthPigsDepartureEventsByDates(dateFrom, dateTo)(dispatch),
	removeGrowthPigsEvent: removeGrowthPigEventFromListByBundleId(dispatch),
});

export const GrowthPigEventListMoveMapDispatchToProps = (dispatch: Dispatch) => ({
	getGrowthPigsMoveEventsByDates: (dateFrom: Date, dateTo: Date) =>
		getGrowthPigsMoveEventsByDates(dateFrom, dateTo)(dispatch),
	removeGrowthPigsEvent: removeGrowthPigEventFromListByBundleId(dispatch),
});

export type GrowthPigEntranceEventListProps = ReturnType<typeof GrowthPigEventListMapStateToProps> &
	ReturnType<typeof GrowthPigEventListEntranceMapDispatchToProps> &
	GrowthPigEventListDefaultPropsFromParent;

export type GrowthPigMoveEventListProps = ReturnType<typeof GrowthPigEventListMapStateToProps> &
	ReturnType<typeof GrowthPigEventListMoveMapDispatchToProps> &
	GrowthPigEventListDefaultPropsFromParent;

export type GrowthPigDepartureEventListProps = ReturnType<typeof GrowthPigEventListMapStateToProps> &
	ReturnType<typeof GrowthPigEventListDepartureMapDispatchToProps> &
	GrowthPigEventListDefaultPropsFromParent;
export const getPenClassName = (item: GrowthPigDepartureEventListRow | GrowthPigEntranceEventListRow) =>
	item.hasRetentionTime ? 'retention-time-list-color' : '';
export const getFromPenClassName = (item: GrowthPigMoveEventListRow) =>
	item.hasFromRetentionTime ? 'retention-time-list-color' : '';
export const getToPenClassName = (item: GrowthPigMoveEventListRow) =>
	item.hasFromRetentionTime ? 'retention-time-list-color' : '';
export const GenerateGrowthPigDepartureEventRowItems = (
	growthPigEvents: IGrowthPigsEvent[],
	locationState: LocationsState,
	reasons: IReasonDto[],
	gped: IGrowthPigEventDto[],
	lang?: string,
) => {
	const listOfGrowthPigDepartureEventRowItems: GrowthPigDepartureEventListRow[] = [];
	const pensWithRetentionTime = getRetentionTimePens(gped);
	growthPigEvents
		.filter(
			gpe =>
				gpe.growthPigEventType === GrowthPigEventType.GrowthPigDepartureEvent ||
				gpe.growthPigEventType === GrowthPigEventType.GrowthPigPenDepartureEvent,
		)
		.forEach(gpe => {
			if (gpe.amount) {
				const pen = locationState.pens.find(p => p.id === gpe.fromPenId);
				const section = locationState.sections.find(p => p.id === gpe.fromSectionId);
				const building = locationState.buildings.find(p => p.id === gpe.fromBuildingId);
				const locationString = GenerateFullLocationString(building, section, pen);
				const reasonToUse = reasons.find(a => a.reason && a.reason.id === gpe.departureReasonId);

				if (pen && section && building) {
					listOfGrowthPigDepartureEventRowItems.push({
						hasRetentionTime: pen.id !== undefined && pensWithRetentionTime.includes(pen.id),
						avgWeight: gpe.totalWeight ? gpe.totalWeight / gpe.amount : 0,
						departedWeight: gpe.totalWeight ? gpe.totalWeight : 0,
						pcs: gpe.amount,
						date: gpe.date,
						id: gpe.id,
						reason:
							reasonToUse &&
							reasonToUse.reason &&
							reasonToUse.reason.name &&
							lang &&
							reasonToUse.reason.name[lang]
								? reasonToUse.reason.name[lang]
								: undefined,
						locationString,
						productionType: gpe.fromProductionType,
						correctProductionType: gpe.correctFromProductionType,
						departuredType: gpe.departureType,
						eventType: gpe.growthPigEventType,
						fromBuildingName: building ? building.name : '',
						fromSectionName: section && building && building.useSections ? section.name : '',
						fromPenName: pen && section && section.usePens ? pen.name : '',
					});
				}
			}
		});
	return listOfGrowthPigDepartureEventRowItems.sort((a, b) =>
		NaturalSort(
			a.locationString ? a.locationString.toLowerCase() : a.locationString,
			b.locationString ? b.locationString.toLowerCase() : b.locationString,
		),
	);
};

export const GenerateGrowthPigDepartureEventBundleRowItems = (
	growthPigEvents: IGrowthPigsEvent[],
	locationState: LocationsState,
	reasons: IReasonDto[],
	lang?: string,
) => {
	const listOfGrowthPigDepartureEventRowItems: GrowthPigDepartureEventBundleListRow[] = [];
	const createRow = (gpe: IGrowthPigsEvent[]) => {
		if (gpe && gpe.length > 0) {
			if (gpe && gpe.length > 0) {
				const firstItem = gpe[0];
				const section = locationState.sections.find(p => p.id === firstItem.fromSectionId);
				const building = locationState.buildings.find(p => p.id === firstItem.fromBuildingId);
				const reasonToUse = reasons.find(a => a.reason && a.reason.id === firstItem.departureReasonId);
				const locationString = GenerateFullLocationString(building, section, undefined);
				let gpeDepartureBundle = {
					locationString,
					eventType: firstItem.growthPigEventType,
					pcs: 0,
					productionType: firstItem.fromProductionType,
					avgWeight: 0,
					departedWeight: 0,
					date: firstItem.date,
					bundleId: firstItem.bundleIdentifier,
					reason:
						reasonToUse &&
						reasonToUse.reason &&
						reasonToUse.reason.name &&
						lang &&
						reasonToUse.reason.name[lang]
							? reasonToUse.reason.name[lang]
							: undefined,
					departuredType: firstItem.departureType,
					fromSectionId: section && section.id,
					animalIdNumber: firstItem.animalIdNumber,
				} as GrowthPigDepartureEventBundleListRow;
				gpeDepartureBundle.fromBuildingName = building ? building.name : '';
				gpeDepartureBundle.fromSectionName = building && section && building.useSections ? section.name : '';

				const gpeIds: string[] = [];
				gpe.forEach(element => {
					if (
						gpeDepartureBundle.departedWeight !== undefined &&
						gpeDepartureBundle.pcs !== undefined &&
						element.amount !== undefined &&
						element.totalWeight !== undefined &&
						element.id
					) {
						gpeDepartureBundle.pcs += element.amount;
						gpeDepartureBundle.departedWeight += element.totalWeight;
						gpeIds.push(element.id);
					}
				});
				if (gpeDepartureBundle.departedWeight !== undefined && gpeDepartureBundle.pcs !== undefined) {
					gpeDepartureBundle.avgWeight = gpeDepartureBundle.departedWeight / gpeDepartureBundle.pcs;
				}
				gpeDepartureBundle.gpeIds = gpeIds;
				listOfGrowthPigDepartureEventRowItems.push(gpeDepartureBundle);
			}
		}
	};
	growthPigEvents
		.filter(
			gpe =>
				gpe.bundleIdentifier &&
				(gpe.growthPigEventType === GrowthPigEventType.GrowthPigDepartureEvent ||
					gpe.growthPigEventType === GrowthPigEventType.GrowthPigPenDepartureEvent) &&
				gpe.departureType !== DepartureTypes.departureTypeShouldDeparture,
		)
		.groupBy('bundleIdentifier')
		.forEach(createRow);

	growthPigEvents
		.filter(
			gpe =>
				!gpe.bundleIdentifier &&
				(gpe.growthPigEventType === GrowthPigEventType.GrowthPigDepartureEvent ||
					gpe.growthPigEventType === GrowthPigEventType.GrowthPigPenDepartureEvent) &&
				gpe.departureType !== DepartureTypes.departureTypeShouldDeparture,
		)
		.groupBy('id')
		.forEach(createRow);
	return listOfGrowthPigDepartureEventRowItems.sort((a, b) =>
		NaturalSort(
			a.fromBuildingName ? a.fromBuildingName.toLowerCase() : a.fromBuildingName,
			b.fromBuildingName ? b.fromBuildingName.toLowerCase() : b.fromBuildingName,
		),
	);
};

export const GenerateGrowthPigEntranceEventRowItems = (
	growthPigEvents: IGrowthPigsEvent[],
	locationState: LocationsState,
	gped: IGrowthPigEventDto[],
) => {
	const listOfGrowthPigEntranceEventRowItems: GrowthPigEntranceEventListRow[] = [];
	const latestEvents: IGrowthPigsEvent[] = getLatestGrowthPigEvents(
		growthPigEvents,
		GrowthPigEventType.GrowthPigEntranceEvent,
	);

	growthPigEvents
		.filter(gpe => gpe.growthPigEventType === GrowthPigEventType.GrowthPigEntranceEvent)
		.forEach(gpe => {
			if (gpe.amount && gpe.totalWeight) {
				const pen = locationState.pens.find(p => p.id === gpe.toPenId);
				const section = locationState.sections.find(p => p.id === gpe.toSectionId);
				const building = locationState.buildings.find(p => p.id === gpe.toBuildingId);
				const locationString = GenerateFullLocationString(building, section, pen);
				if (pen && section && building) {
					const retentionDate = getPenRetentionTime(gpe.toPenId);
					listOfGrowthPigEntranceEventRowItems.push({
						hasRetentionTime: !!retentionDate,
						avgWeight: gpe.totalWeight / gpe.amount,
						entranceWeight: gpe.totalWeight,
						pcs: gpe.amount,
						date: gpe.date,
						id: gpe.id,
						locationString,
						retentionDate: retentionDate,
						productionType: gpe.toProductionType,
						correctProductionType: gpe.correctToProductionType,
						isLatest: latestEvents.find(le => le.id === gpe.id) !== undefined,
						toBuildingName: building ? building.name : '',
						toSectionName: building && section && building.useSections ? section.name : '',
						toPenName: pen && section && section.usePens ? pen.name : '',
					});
				}
			}
		});
	return listOfGrowthPigEntranceEventRowItems.sort((a, b) =>
		NaturalSort(
			a.locationString ? a.locationString.toLowerCase() : a.locationString,
			b.locationString ? b.locationString.toLowerCase() : b.locationString,
		),
	);
};

export const GenerateGrowthPigEntranceEventBundleRowItems = (
	growthPigEvents: IGrowthPigsEvent[],
	locationState: LocationsState,
	gped: IGrowthPigEventDto[],
) => {
	const listOfGrowthPigEntranceEventRowItems: GrowthPigEntranceEventBundleListRow[] = [];

	const createRow = (gpe: IGrowthPigsEvent[]) => {
		if (gpe && gpe.length > 0) {
			const firstItem = gpe[0];
			const section = locationState.sections.find(p => p.id === firstItem.toSectionId);
			const building = locationState.buildings.find(p => p.id === firstItem.toBuildingId);
			const locationString = GenerateFullLocationString(building, section, undefined);
			const retentionDate = section ? getSectionRetentionTime(section.id) : null;
			const singleEvent = gpe.length === 1 && !section?.usePens ? firstItem : null;
			let gpeEntranceBundle = {
				locationString,
				pcs: 0,
				productionType: firstItem.toProductionType,
				avgWeight: 0,
				entranceWeight: 0,
				date: firstItem.date,
				bundleId: firstItem.bundleIdentifier,
				sectionId: section && section.id,
				retentionDate,
				singleEvent,
			} as GrowthPigEntranceEventBundleListRow;
			gpeEntranceBundle.toBuildingName = building ? building.name : '';
			gpeEntranceBundle.toSectionName = building && section && building.useSections ? section.name : '';

			const gpeIds: string[] = [];
			gpe.forEach(element => {
				if (
					gpeEntranceBundle.entranceWeight !== undefined &&
					gpeEntranceBundle.pcs !== undefined &&
					element.amount &&
					element.totalWeight &&
					element.id
				) {
					gpeEntranceBundle.pcs += element.amount;
					gpeEntranceBundle.entranceWeight += element.totalWeight;
					gpeIds.push(element.id);
				}
			});
			if (gpeEntranceBundle.entranceWeight !== undefined && gpeEntranceBundle.pcs !== undefined) {
				gpeEntranceBundle.avgWeight = gpeEntranceBundle.entranceWeight / gpeEntranceBundle.pcs;
			}
			gpeEntranceBundle.gpeIds = gpeIds;

			listOfGrowthPigEntranceEventRowItems.push(gpeEntranceBundle);
		}
	};
	growthPigEvents
		.filter(gpe => gpe.bundleIdentifier && gpe.growthPigEventType === GrowthPigEventType.GrowthPigEntranceEvent)
		.groupBy('bundleIdentifier')
		.forEach(createRow);

	growthPigEvents
		.filter(gpe => !gpe.bundleIdentifier && gpe.growthPigEventType === GrowthPigEventType.GrowthPigEntranceEvent)
		.groupBy('id')
		.forEach(createRow);
	return listOfGrowthPigEntranceEventRowItems.sort((a, b) =>
		NaturalSort(
			a.locationString ? a.locationString.toLowerCase() : a.locationString,
			b.locationString ? b.locationString.toLowerCase() : b.locationString,
		),
	);
};

export const GenerateGrowthPigMoveEventRowItems = (
	growthPigEvents: IGrowthPigsEvent[],
	locationState: LocationsState,
	gped: IGrowthPigEventDto[],
) => {
	const listOfGrowthPigMoveEventRowItems: GrowthPigMoveEventListRow[] = [];
	const latestEvents: IGrowthPigsEvent[] = getLatestGrowthPigEvents(
		growthPigEvents,
		GrowthPigEventType.GrowthPigMoveEvent,
	);
	const pensWithRetentionTime = getRetentionTimePens(gped);
	growthPigEvents
		.filter(
			gpe =>
				gpe.growthPigEventType === GrowthPigEventType.GrowthPigMoveEvent ||
				gpe.growthPigEventType === GrowthPigEventType.PigletMovedEvent,
		)
		.forEach(gpe => {
			if (gpe.amount) {
				const toPen = locationState.pens.find(p => p.id === gpe.toPenId);
				const toSection = locationState.sections.find(p => p.id === gpe.toSectionId);
				const toBuilding = locationState.buildings.find(p => p.id === gpe.toBuildingId);
				const fromPen = locationState.pens.find(p => p.id === gpe.fromPenId);
				const fromSection = locationState.sections.find(p => p.id === gpe.fromSectionId);
				const fromBuilding = locationState.buildings.find(p => p.id === gpe.fromBuildingId);
				if (toPen && toSection && toBuilding && fromPen && fromSection && fromBuilding) {
					const fromLocationString = GenerateFullLocationString(fromBuilding, fromSection, fromPen);
					const toLocationString = GenerateFullLocationString(toBuilding, toSection, toPen);
					const retentionFromDate = getPenRetentionTime(gpe.fromPenId);
					const retentionToDate = getPenRetentionTime(gpe.toPenId);
					listOfGrowthPigMoveEventRowItems.push({
						hasFromRetentionTime: retentionFromDate ? true : false,
						hasToRetentionTime: retentionToDate ? true : false,
						avgWeight: gpe.totalWeight ? gpe.totalWeight / gpe.amount : 0,
						weight: gpe.totalWeight ? gpe.totalWeight : 0,
						pcs: gpe.amount,
						date: gpe.date,
						id: gpe.id,
						fromBuildingName: fromBuilding ? fromBuilding.name : '',
						fromSectionName:
							fromBuilding && fromSection && fromBuilding.useSections ? fromSection.name : '',
						fromPenName: fromPen && fromSection && fromSection.usePens ? fromPen.name : '',
						toBuildingName: toBuilding ? toBuilding.name : '',
						toSectionName: toBuilding && toSection && toBuilding.useSections ? toSection.name : '',
						toPenName: toPen && toSection && toSection.usePens ? toPen.name : '',
						fromLocation: fromLocationString,
						toLocation: toLocationString,
						toProductionType: gpe.toProductionType,
						fromProductionType: gpe.fromProductionType,
						correctToProductionType: gpe.correctToProductionType,
						correctFromProductionType: gpe.correctFromProductionType,
						isLatest: latestEvents.find(le => le.id === gpe.id) !== undefined,
					});
				}
			}
		});
	return listOfGrowthPigMoveEventRowItems.sort((a, b) =>
		NaturalSort(
			a.fromLocation ? a.fromLocation.toLowerCase() : a.fromLocation,
			b.fromLocation ? b.fromLocation.toLowerCase() : b.fromLocation,
		),
	);
};

export const GenerateGrowthPigMoveEventBundleRowItems = (
	growthPigEvents: IGrowthPigsEvent[],
	locationState: LocationsState,
	gped: IGrowthPigEventDto[],
) => {
	const listOfGrowthPigMoveEventRowItems: GrowthPigMoveEventBundleListRow[] = [];

	const createRow = (gpe: IGrowthPigsEvent[]) => {
		if (gpe && gpe.length > 0) {
			const firstItem = gpe[0];
			const toSection = locationState.sections.find(p => p.id === firstItem.toSectionId);
			const toBuilding = locationState.buildings.find(p => p.id === firstItem.toBuildingId);
			const fromSection = locationState.sections.find(p => p.id === firstItem.fromSectionId);
			const fromBuilding = locationState.buildings.find(p => p.id === firstItem.fromBuildingId);
			const fromLocationString = GenerateFullLocationString(fromBuilding, fromSection, undefined);
			const toLocationString = GenerateFullLocationString(toBuilding, toSection, undefined);
			let gpeMoveBundle = {
				fromLocation: fromLocationString,
				toLocation: toLocationString,
				pcs: 0,
				fromProductionType: firstItem.fromProductionType,
				toProductionType: firstItem.toProductionType,
				avgWeight: 0,
				weight: 0,
				date: firstItem.date,
				bundleId: firstItem.bundleIdentifier,
				toSectionId: toSection && toSection.id,
				fromSectionId: fromSection && fromSection.id,
			} as GrowthPigMoveEventBundleListRow;
			gpeMoveBundle.fromBuildingName = fromBuilding ? fromBuilding.name : '';
			gpeMoveBundle.fromSectionName =
				fromBuilding && fromSection && fromBuilding.useSections ? fromSection.name : '';
			gpeMoveBundle.toBuildingName = toBuilding ? toBuilding.name : '';
			gpeMoveBundle.toSectionName = toBuilding && toSection && toBuilding.useSections ? toSection.name : '';

			const gpeIds: string[] = [];
			gpe.forEach(element => {
				if (
					gpeMoveBundle.weight !== undefined &&
					gpeMoveBundle.pcs !== undefined &&
					element.amount &&
					element.totalWeight &&
					element.id
				) {
					gpeMoveBundle.pcs += element.amount;
					gpeMoveBundle.weight += element.totalWeight;
					gpeIds.push(element.id);
				}
			});
			if (gpeMoveBundle.weight !== undefined && gpeMoveBundle.pcs !== undefined) {
				gpeMoveBundle.avgWeight = gpeMoveBundle.weight / gpeMoveBundle.pcs;
			}
			gpeMoveBundle.gpeIds = gpeIds;

			listOfGrowthPigMoveEventRowItems.push(gpeMoveBundle);
		}
	};

	growthPigEvents
		.filter(
			gpe =>
				gpe.bundleIdentifier &&
				(gpe.growthPigEventType === GrowthPigEventType.GrowthPigMoveEvent ||
					gpe.growthPigEventType === GrowthPigEventType.PigletMovedEvent),
		)
		.groupBy('bundleIdentifier')
		.forEach(createRow);
	growthPigEvents
		.filter(
			gpe =>
				!gpe.bundleIdentifier &&
				(gpe.growthPigEventType === GrowthPigEventType.GrowthPigMoveEvent ||
					gpe.growthPigEventType === GrowthPigEventType.PigletMovedEvent),
		)
		.groupBy('id')
		.forEach(createRow);
	return listOfGrowthPigMoveEventRowItems.sort((a, b) =>
		NaturalSort(
			a.fromBuildingName ? a.fromBuildingName.toLowerCase() : a.fromBuildingName,
			b.fromBuildingName ? b.fromBuildingName.toLowerCase() : b.fromBuildingName,
		),
	);
};

export const defaultGrowthPigEventListDates = () => {
	const dateTo = moment().endOf('day').toDate();
	const dateFrom = AddDaysToDate(moment().startOf('day').toDate(), -7);
	return { dateTo: dateTo, dateFrom: dateFrom };
};

const getRetentionTimePens = memoize((gped: IGrowthPigEventDto[]) => {
	let qurantinePens: string[] = [];
	gped.forEach(d => {
		if (d.growthPigEventDistributions) {
			qurantinePens = qurantinePens.concat(
				d.growthPigEventDistributions.filter(ed => ed.hasQuarantine).map(ed => ed.penId!),
			);
		}
	});
	return qurantinePens;
});

export function getLatestGrowthPigEvents(growthPigEvents: IGrowthPigsEvent[], growthPigEventType: GrowthPigEventType) {
	const latestEvents: IGrowthPigsEvent[] = [];
	growthPigEvents
		.sort((a, b) => NaturalSortDates(b.date, a.date))
		.filter(gpe => gpe.growthPigEventType === growthPigEventType)
		.forEach(gpeToCheck => {
			if (
				latestEvents.find(
					gpe => gpe.toPenId === gpeToCheck.toPenId && gpe.toProductionType === gpeToCheck.toProductionType,
				) === undefined
			) {
				latestEvents.push(gpeToCheck);
			}
		});
	return latestEvents;
}

export function removeGrowthPigEventFromListByBundleId(dispatch) {
	return (event: GrowthPigSummaryBundleRowItem) => {
		if (event && event.bundleId) {
			return removeEventsByBundleId(event.bundleId)(dispatch);
		} else if (event && !event.bundleId && event.gpeIds && event.gpeIds.length === 1) {
			return removeGrowthPigsEventByIds(event.gpeIds)(dispatch);
		}

		return;
	};
}

function GenerateFullLocationString(
	building: IBuilding | undefined,
	section: ISection | undefined,
	pen: IPen | undefined,
) {
	return (
		(building && building.name ? building.name : '') +
		(building && building.useSections && section && section.name ? ' - ' + section!.name : '') +
		(building && building.useSections && section && section.name && section.usePens && pen && pen.name
			? ' - ' + pen.name
			: '')
	);
}

export const YoungAnimalDepartures = (animalType: AnimalType) => {
	let options = [
		DefaultGrowthTypeDepartureTypeOption,
		{
			label: localized(DepartureTypes.departureTypeSold),
			value: DepartureTypes.departureTypeSold,
		},
	];
	if (animalType === AnimalType.Finisher) {
		options.push({
			label: localized(DepartureTypes.departureTypeKilled),
			value: DepartureTypes.departureTypeKilled,
		});
	}
	return options;
};
