import { Action } from 'redux';
import { mergeArrays } from 'shared/helpers/reducer-helpers';
import { isType } from 'typescript-fsa';
import { getSyncData, saveData } from './actions';
import { UnitToPenDataState } from './types';
import { IUnitToPen, PenDto, UnitToPen } from 'shared/api/api';
import { siteChange } from '../profile/actions';
import { SharedAppState } from 'shared/state/store.shared';
import { getSyncModelData } from '../sync/actions';
import { StoreContainer } from 'shared/state/store-container';

export const initialUnitToPenDataState: UnitToPenDataState = {
	data: [],
	lastSyncDate: new Date(-8640000000000000),
};

const UnitToPenDataReducer = (
	state: UnitToPenDataState = initialUnitToPenDataState,
	action: Action,
): UnitToPenDataState => {
	if (isType(action, getSyncData.done)) {
		if (action.payload.params.siteId === action.payload.params.activeSiteId) {
			if (action.payload.result.datas && action.payload.result.datas.length > 0) {
				state = {
					...state,
					data: mergeArrays(state.data, action.payload.result.datas!),
					lastSyncDate: action.payload.result.syncTime,
				};
			}
		}
	}

	if (isType(action, getSyncModelData.done)) {
		if (action.payload.params.siteId === action.payload.params.activeSiteId) {
			if (
				action.payload.result.unitToPens &&
				action.payload.result.unitToPens.datas &&
				action.payload.result.unitToPens.datas.length > 0
			) {
				state = {
					...state,
					data: mergeArrays(state.data, action.payload.result.unitToPens.datas!),
					lastSyncDate: action.payload.result.unitToPens.syncTime,
				};
			}
		}
	}
	if (isType(action, saveData.done)) {
		state = {
			...state,
			data: mergeArrays(state.data, [action.payload.params]),
		};
	}
	if (isType(action, siteChange.done)) {
		state = initialUnitToPenDataState;
	}

	if (action.type === 'persist/REHYDRATE') {
		let a = (action as any) as { payload: SharedAppState; key: string };
		if (a.key === 'root') {
			let entities = new Array<IUnitToPen>();
			let lastSyncDate = new Date(-8640000000000000);
			if (a.payload && a.payload.unitToPenData && a.payload.unitToPenData.data) {
				entities = a.payload.unitToPenData.data.map((t: IUnitToPen) => {
					let nt = UnitToPen.fromJS({});
					nt.init(t);
					return nt;
				});
			}

			if (a.payload && a.payload.unitToPenData && a.payload.unitToPenData.lastSyncDate) {
				lastSyncDate = new Date(a.payload.unitToPenData.lastSyncDate);
			}

			state = { ...state, data: entities, lastSyncDate };
		}
	}

	return state;
};

export const PenHasDistriwin = (penId: string) => {
	const state = StoreContainer.getState();
	return state.unitToPenData.data.find(utp => utp.penId === penId);
};

export const SectionHasDistriwin = (sectionId: string) => {
	const state = StoreContainer.getState();
	return state.adjustFeed.valveGroups.find(vg => vg.sectionId === sectionId);
};

export default UnitToPenDataReducer;
