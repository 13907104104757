import { Dispatch } from 'redux';
import { BalancePigEventTotalWeightBalancePigEvents } from 'shared/api/api';
import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import * as Action from './actions';
import { UpsertBalancePigEventTotalWeightBalancePigEvent } from './actions';

export function getBalanceWeanedPigsData(siteId?: string, dateFrom?: Date, dateTo?: Date) {
	return AsyncOperationBuilder2(
		Action.getBalanceWeanedPigsData,
		client => client.balanceWeanedPigs_GetBalancePigEventsByDates(siteId, dateFrom, dateTo),
		siteId,
	);
}

export function upsertBalanceWeanedPigsData(event: BalancePigEventTotalWeightBalancePigEvents, idsToRemove?: string[], totalAmount?: number, totalWeight?: number) {
	const item = {event, idsToRemove, totalAmount, totalWeight} as UpsertBalancePigEventTotalWeightBalancePigEvent
	return (dispatch: Dispatch<any>) => {
		return dispatch(Action.UpsertBalanceWeanedPigsData.started(item));
	};
}
