import ObjectID from 'bson-objectid';
import { CSSProperties } from 'react';
import { Option } from 'react-dropdown';
import { Dispatch } from 'redux';
import {
	AnimalType,
	GrowthPigDepartureEventDto,
	GrowthPigDepartureFromPenEventDto,
	GrowthPigEntranceEventDto,
	GrowthPigEventType,
	GrowthPigMoveEventDto,
	GrowthPigsEvent,
	GrowthPigTuneProduction,
	IGeneralSettings,
	IGrowthPigEntranceEventDto,
	IGrowthPigEventDistribution,
	IGrowthPigMoveEventDto,
	IPen,
	ISection,
	IStemAnimal,
} from 'shared/api/api';
import {
	SetGpeDepartureCount,
	upsertGrowthPigsDepartureEvents,
	upsertGrowthPigsDepartureFromPenEvents,
	upsertGrowthPigsEntranceEvents,
	upsertGrowthPigsEvent,
	upsertGrowthPigsMoveEvents,
} from 'shared/state/ducks/growth-pig-events/operations';
import { localized } from 'shared/state/i18n/i18n';
import { DepartureTypes } from 'shared/state/models/departure-types';
import { SharedAppState } from 'shared/state/store.shared';
import { getBuildingBySectionId, ILocationModel, sortPensByName } from '../location-helper';
import { memoizeHashmapLocation, memoizeLocation } from '../memoize-getters/memoize-getters';
import {
	generateDataForEntranceDistribute,
	generateGrowthPigEntranceEventDto,
	generateGrowthPigEventDataForSectionId,
	generateLocationOptionsForGrowthPigEvents,
} from './growth-pig-event-function-helper';
import { getLatestGrowthPigEvents } from './growth-pig-event-list-helper';
import { GpeDepartureCount } from 'shared/state/ducks/growth-pig-events';
import { SetRetentionTimeOnPens } from 'shared/state/ducks/locations/operations';
import { upsertGrowthPigTuneProduction } from 'shared/state/ducks/growth-pig-tune-production/operations';
import { RouteProps } from 'native/state/ducks/navigation/routes';
import { GetDeparturedAnimals, UpdateYoungAnimal } from 'shared/state/ducks/stem-animals/operations';
import { selectNucleusManagementOrAssignIdAccess } from 'shared/state/ducks/site/reducer';

export const GrowthPigEventOptionsArray = (productionType: AnimalType) => [
	{ label: localized(GrowthPigEventType.GrowthPigEntranceEvent), value: GrowthPigEventType.GrowthPigEntranceEvent },
	{ label: localized(GrowthPigEventType.GrowthPigMoveEvent), value: GrowthPigEventType.GrowthPigMoveEvent },
	{
		label:
			productionType === AnimalType.Weaner
				? localized('Sold')
				: localized(GrowthPigEventType.GrowthPigDepartureEvent),
		value: GrowthPigEventType.GrowthPigDepartureEvent,
	},
	{
		label: localized(GrowthPigEventType.GrowthPigPenDepartureEvent),
		value: GrowthPigEventType.GrowthPigPenDepartureEvent,
	},
	{
		label: localized('WeightRegulation'),
		value: GrowthPigEventType.Regulation,
	},
	{
		label:
			productionType === AnimalType.Weaner
				? localized('SoldYoungAnimal')
				: localized('SoldSlaughteredYoungAnimal'),
		value: 'SoldYoungAnimal',
	},
];
export type GrowthPigEntranceDateProperties = 'date' | 'retentionDate';
export type GrowthPigNumberProperties = 'avgWeight' | 'totalWeight' | 'amount';
export type DistributeTableDataTypes = IGrowthPigMoveEventDto | IGrowthPigEntranceEventDto;

export interface GrowthPigMoveState {
	growthPigEvent?: GrowthPigsEvent;
	modalOpen?: boolean;
	distributeModalOpen?: boolean;
	avgWeight?: number;
	filteredLocation: ILocationModel;
	fromSection?: ISection;
	fromProductionType: AnimalType;
	toProductionType: AnimalType;
	toSection?: ISection;
	toPenId?: string;
	date: Date;
	isLatest?: boolean;
	data: GrowthPigDepartureFromSectionRow[];
	dataToModalForward: GrowthPigDepartureFromSectionRow[];
	showModal?: boolean;
	distribiteToPen?: Option;
	distributeFromPen?: Option;
	distributeCount?: number;
}

export const GrowthPigEventsMapDispatchToProps = (dispatch: Dispatch) => ({
	upsertGrowthPigsDepartureEvents: (events: GrowthPigDepartureEventDto[]) =>
		upsertGrowthPigsDepartureEvents(events)(dispatch),
	upsertGrowthPigsMoveEvents: (events: GrowthPigMoveEventDto[]) => upsertGrowthPigsMoveEvents(events)(dispatch),
	upsertGrowthPigsEntranceEvents: (events: GrowthPigEntranceEventDto[]) =>
		upsertGrowthPigsEntranceEvents(events)(dispatch),
	upsertGrowthPigsDepartureFromPenEvents: (events: GrowthPigDepartureFromPenEventDto[]) =>
		upsertGrowthPigsDepartureFromPenEvents(events)(dispatch),
	upsertGrowthPigsEvent: (event: GrowthPigsEvent) => upsertGrowthPigsEvent(event)(dispatch),
	setGpeDepartureCount: (gpeDepartureCount: GpeDepartureCount) => SetGpeDepartureCount(gpeDepartureCount)(dispatch),
	setRetentionTimeOnPens: (penDateKeyValue: { [key: string]: Date | undefined }, forceClearRetentionDate?: boolean) =>
		SetRetentionTimeOnPens(penDateKeyValue, forceClearRetentionDate)(dispatch),
	upsertMultipleGrowthPigTuneProduction: (tuneProductions: GrowthPigTuneProduction[]) =>
		upsertGrowthPigTuneProduction(tuneProductions)(dispatch),
	updateYoungAnimal: (sow: IStemAnimal) => UpdateYoungAnimal(sow)(dispatch),
	getDeparturedAnimals: () => GetDeparturedAnimals()(dispatch),
});

export const GrowthPigMoveEventBaseMapDispatchToProps = (dispatch: Dispatch) => ({
	upsertGrowthPigsMoveEvents: (events: GrowthPigMoveEventDto[]) => upsertGrowthPigsMoveEvents(events)(dispatch),
});

export const GrowthPigMoveEventBaseMapStateToProps = (state: SharedAppState) => {
	return {
		locations: memoizeHashmapLocation(state.locations.buildings, state.locations.sections, state.locations.pens),
		profile: state.profile.active!,
		growthPigEvents: state.growthPigEvents.entities,
	};
};

export const generateDefaultGrowthPigEventsProps = (state: SharedAppState) => {
	return {
		locations: memoizeLocation(
			state.locations.buildings,
			state.locations.sections,
			state.locations.pens,
			state.locations.valves,
		),
		growthPigsEvents: state.growthPigEvents.entities,
		profile: state.profile.active,
		reasons: state.reasons.entities,
		growthPigEvents: state.growthPigEvents.growthPigEvents,
		handleRetentionTime: state.generalSettings.entity.handleRetentionTime,
		usePrivateSlaughterhouse: state.generalSettings.entity.usePrivateSlaughterhouse,
		hashmapLocations: memoizeHashmapLocation(
			state.locations.buildings,
			state.locations.sections,
			state.locations.pens,
		),
		nucleusManagement: selectNucleusManagementOrAssignIdAccess(state),
		generalSettings: state.generalSettings.entity,
	};
};

export interface GrowthPigDistributeMoveEventPropsFromParent {
	closeModal: (saved?: boolean) => void;
	date: Date;
	data: GrowthPigDepartureFromSectionRow[];
	fromProductionType: AnimalType;
	toProductionType: AnimalType;
	toSectionId: string;
	fromSectionId: string;
	navigation?: any;
	route?: RouteProps;
}

export interface GrowthPigEntranceDistributePropsFromParent {
	closeModal: (saved?: boolean) => void;
	date: Date;
	retentionDate?: Date;
	avgWeight: number;
	amount: number;
	toSectionId: string;
	productionType: AnimalType;
	navigation?: any;
	route?: RouteProps;
	bundleId?: string;
	totalWeight?: number;
}

export interface GrowthPigMoveEventBasePropsFromParent {
	date?: Date;
	maxNumberToMove?: number;
	amountMoved?: number;
	amountRow?: boolean;
	distribution?: IGrowthPigEventDistribution;
	productionType: AnimalType;
	productionTypeRow?: boolean;
	sectionRow?: boolean;
	reverseColors: boolean;
	penRow?: boolean;
	containerStyle: CSSProperties;
	avgWeight?: number;
}

export interface GrowthPigMoveEventBaseState {
	move: boolean;
	toProductionType: AnimalType;
	sectionId?: string;
	penId?: string;
	amount?: number;
	avgWeight?: number;
}

export interface GrowthPigDepartureState {
	growthPigEvent?: GrowthPigsEvent;
	modalOpen?: boolean;
	filteredLocation: ILocationModel;
	fromSection?: ISection;
	fromPenId?: string;
	fromProductionType?: AnimalType;
	departureTypeOption: Option;
	date?: Date;
	data: GrowthPigDepartureFromSectionRow[];
	totalWeightToUse?: number;
	distribiteToPen?: Option;
	distributeFromPen?: Option;
	distributeCount?: number;
}

export interface GrowthPigProductionTypePropsFromParent {
	noPropChangeAllowed?: boolean;
	usedInsideForm?: boolean;
	productionType: AnimalType;
	productionTypeLimitation?: AnimalType;
	allowSetToDefaultFinisher?: boolean;
	dontSetToFrats?: boolean;
	disableYoungFemale?: boolean;
	style?: any;
	disabled?: boolean;
	onProductionTypeChanged: (productionType: AnimalType, itemFromParent?: any) => void;
	itemFromParent?: any;
	theme?: 'light' | 'dark';
}

export const growthPigProductionTypMapStateToProps = (state: SharedAppState) => {
	return {
		locations: memoizeLocation(
			state.locations.buildings,
			state.locations.sections,
			state.locations.pens,
			state.locations.valves,
		),
	};
};

export type GrowthPigProductionTypeProps = ReturnType<typeof growthPigProductionTypMapStateToProps> &
	GrowthPigProductionTypePropsFromParent;

export interface GrowthPigProductionTypePickerState {
	selectedProductionType: Option;
	productionTypeOptions: Option[];
}

export interface GrowthPigEventPropsFromParent {
	productionType: AnimalType;
	growthPigsEvent?: GrowthPigsEvent;
	bundleId?: string;
	isCreateEdit?: boolean;
	navigation?: any;
	route?: RouteProps;
	updateFunction?: (gpe: GrowthPigsEvent) => Promise<void>;
	closeEditModal?: () => void;
}

export function getGrowthPigEventDeparturedAnimalsCount(data: GrowthPigDepartureFromSectionRow[]): number {
	return data.reduce(
		(sum, current) => sum + (current.departedFromSection !== undefined ? current.departedFromSection : 0),
		0,
	);
}

export const GrowthPigEventsMapStateToProps = (state: SharedAppState) => {
	return generateDefaultGrowthPigEventsProps(state);
};

export const GrowthPigDeparturePropsMapStateToProps = (state: SharedAppState, props: GrowthPigEventPropsFromParent) => {
	const defaultGrowthPigEventsProps = generateDefaultGrowthPigEventsProps(state);
	const propsFromParent = {
		...(props.navigation && props.route && props.route.params),
	} as GrowthPigEventPropsFromParent;
	return {
		...defaultGrowthPigEventsProps,
		...propsFromParent,
		departedAnimals: state.stemAnimals.departuredAnimals,
	};
};

export const GrowthPigEntranceDistributePropsMapStateToProps = (
	state: SharedAppState,
	props: GrowthPigEntranceDistributePropsFromParent,
) => {
	const defaultGrowthPigEventsProps = generateDefaultGrowthPigEventsProps(state);
	const propsFromParent = {
		...(props.navigation && props.route && props.route.params),
	} as GrowthPigEntranceDistributePropsFromParent;
	return {
		...defaultGrowthPigEventsProps,
		...propsFromParent,
	};
};

export const GrowthPigDistributeMoveEventPropsMapStateToProps = (
	state: SharedAppState,
	props: GrowthPigDistributeMoveEventPropsFromParent,
) => {
	const defaultGrowthPigEventsProps = generateDefaultGrowthPigEventsProps(state);
	const propsFromParent = {
		...(props.navigation && props.route && props.route.params),
	} as GrowthPigDistributeMoveEventPropsFromParent;
	return {
		...defaultGrowthPigEventsProps,
		...propsFromParent,
	};
};

export const GrowthPigMovePropsMapStateToProps = (state: SharedAppState, props: GrowthPigEventPropsFromParent) => {
	const defaultGrowthPigEventsProps = generateDefaultGrowthPigEventsProps(state);
	const propsFromParent = {
		...(props.navigation && props.route && props.route.params),
	} as GrowthPigEventPropsFromParent;
	return {
		...defaultGrowthPigEventsProps,
		...propsFromParent,
	};
};

export const filterDataGrowthPigDepartureFromSectionRow = (
	d: GrowthPigDepartureFromSectionRow,
): boolean | 0 | undefined => !!(d.departedFromSection || (d.rest && d.rest <= 0));

export type GrowthPigDepartureProps = ReturnType<typeof GrowthPigDeparturePropsMapStateToProps> &
	ReturnType<typeof GrowthPigEventsMapDispatchToProps> &
	GrowthPigEventPropsFromParent;

export type GrowthPigEntranceDistributeProps = ReturnType<typeof GrowthPigEntranceDistributePropsMapStateToProps> &
	ReturnType<typeof GrowthPigEventsMapDispatchToProps> &
	GrowthPigEntranceDistributePropsFromParent;

export type GrowthPigDistributeMoveEventProps = ReturnType<typeof GrowthPigDistributeMoveEventPropsMapStateToProps> &
	ReturnType<typeof GrowthPigEventsMapDispatchToProps> &
	GrowthPigDistributeMoveEventPropsFromParent;

export type GrowthPigMoveProps = ReturnType<typeof GrowthPigMovePropsMapStateToProps> &
	ReturnType<typeof GrowthPigEventsMapDispatchToProps> &
	GrowthPigEventPropsFromParent;

export interface GrowthPigDepartureFromSectionTablePropsFromParent {
	data: GrowthPigDepartureFromSectionRow[];
	departureType?: string;
	isDeparture?: boolean;
	departedFromSectionText: string;
	usesPen?: boolean;
	handleRetentionTime?: boolean;
	usePrivateSlaughterhouse?: boolean;

	// TODO: Could setup with Provider Context
	// Callbacks to change data
	onDepartureFromSectionNumberChanged: (number?: number, itemFromParent?: GrowthPigDepartureFromSectionRow) => void;
	onRestFromSectionNumberChanged?: (number?: number, itemFromParent?: GrowthPigDepartureFromSectionRow) => void;
	onDepartureFromSectionAvgWeightChanged: (
		number?: number,
		itemFromParent?: GrowthPigDepartureFromSectionRow,
	) => void;
}

export interface GrowthPigDistributeTablePropsFromParent {
	onWeightChanged: (number?: number, itemFromParent?: any) => void;
	onTotalWeightChanged?: (number?: number, itemFromParent?: any) => void;
	onAmounthanged: (number?: number, itemFromParent?: any) => void;
	disablePenColumn?: boolean;
	data: DistributeTableDataTypes[];
	onBlur?: () => void;
	latestRetentionDate?: Date;
	onLatestRetentionDateChange?: (date?: Date, itemFromParent?: any) => void;
	handleRetentionTime?: boolean;
}

export interface GrowthPigDistributeFormPropsFromParent {
	toSectionId: string;
	fromPenId?: Option;
	toPenId?: Option;
	disablePenSelect?: boolean;
	amountPigsNotDistributed: number;
	totalWeightNotDistributed: number;
	OnFromPenChanged: (newValue?: Option) => void;
	OnToPenChanged: (newValue?: Option) => void;
	hideAmountPigsNotDistributed?: boolean;
	blacklistedPenIds?: string[];
	feedCurveChanged?: (feedCurve: number) => void;
	recipeChanged?: (recipeNumber: number) => void;
	productionType?: AnimalType;
}
export interface GrowthPigDistributeFormState {
	fromOptions: Option[];
	toOptions: Option[];
}

export type GrowthPigDistributeFormProps = ReturnType<typeof GrowthPigEventsMapStateToProps> &
	GrowthPigDistributeFormPropsFromParent;

export interface GrowthPigEventDistributeAverageWeightPropsFromParent {
	closeModal: () => void;
	onTotalWeightChanged: (totalWeight?: number) => void;
	departureAnimals: number;
	navigation?: any;
	route?: RouteProps;
}

export interface GrowthPigEventDistributeAverageWeightState {
	totalWeight?: number;
	avgWeight?: number;
}

export type GrowthPigMoveEventBaseEventProps = GrowthPigMoveEventBasePropsFromParent &
	ReturnType<typeof GrowthPigMoveEventBaseMapDispatchToProps> &
	ReturnType<typeof GrowthPigMoveEventBaseMapStateToProps>;

export type GrowthPigEntranceEventProps = ReturnType<typeof GrowthPigEventsMapStateToProps> &
	ReturnType<typeof GrowthPigEventsMapDispatchToProps> &
	GrowthPigEventPropsFromParent;

export interface GrowthPigDepartureFromSectionRow extends IGrowthPigEventDistribution {
	penOrder?: number;
	penName?: string;
	buildingId?: string;
	sectionId?: string;
	rest?: number;
	oldAvgWeight: number;
	newAvgWeight?: number;
	departureWeight?: number;
	departedFromSection?: number;
	totalDepartureWeight?: number;
	editable?: boolean;
	moveAllTreated?: MoveAllTreatedEnum;
	retentionDate?: Date;
	bundleIdentifier?: string;
}

export enum MoveAllTreatedEnum {
	All = 1,
	None = 2,
	Some = 3,
}

export const getMoveAllTreatedEnumOptions = () => {
	let moveAllTreatedOptions: any[] = [];

	for (let enumMember in MoveAllTreatedEnum) {
		if (enumMember) {
			let val = parseInt(enumMember, 10);
			let isValueProperty = val >= 0;
			if (isValueProperty) {
				moveAllTreatedOptions.push({ label: localized(MoveAllTreatedEnum[val]), value: val });
			}
		}
	}
	return moveAllTreatedOptions;
};

export interface GrowthPigEntranceState {
	growthPigEvent?: GrowthPigsEvent;
	selectedProductionType: AnimalType;
	retentionDate?: Date;
	date?: Date;
	section?: ISection;
	penId?: string;
	amount?: number;
	avgWeight?: number;
	totalWeight?: number;
	modalOpen: boolean;
	isLatest?: boolean;
	generalSettings?: IGeneralSettings;
	feedCurve?: number;
	recipeNumber?: number;
}

export interface GrowthPigDeparturePenState {
	growthPigEvent?: GrowthPigsEvent;
	filteredLocation: ILocationModel;
	fromSection?: ISection;
	fromPenId?: string;
	fromProductionType?: AnimalType;
	departureTypeOption: Option;
	amount?: number;
	avgWeight?: number;
	date?: Date;
	data: GrowthPigDepartureFromSectionRow[];
	departureReasonIdOption?: Option;
	youngAnimal: IStemAnimal | undefined;
	chooseIdModalIsOpen: boolean;
}

export interface GrowthPigDistributeState {
	fromPenId?: Option;
	toPenId?: Option;
	pensInSection: IPen[];
	data: DistributeTableDataTypes[];
	amountPigsNotDistributed: number;
	totalWeightNotDistributed: number;
	disablePenColumn?: boolean;
	latestRetentionDate?: Date;
	blacklistedPenIds?: string[]; // Used to ignore pens From if from and to section is the same
	feedCurve?: number;
	recipeNumber?: number;
	amount: number;
	totalWeight: number;
}

export const DefaultGrowthTypeDepartureTypeOption: Option = { label: ' ', value: '' };
export const DefaultGrowthTypeDepartureTypeKilledOption = () => {
	return {
		label: localized(DepartureTypes.departureTypeKilled),
		value: DepartureTypes.departureTypeKilled,
	};
};

export const DefaultGrowthTypeDepartureTypeSoldOption = () => {
	return {
		label: localized(DepartureTypes.departureTypeSold),
		value: DepartureTypes.departureTypeSold,
	};
};

export const GrowthTypeDepartureTypesFromSection = (animalType: AnimalType) => {
	const options = [
		{
			label: localized(DepartureTypes.departureTypeSold),
			value: DepartureTypes.departureTypeSold,
		},
	];
	if (animalType === AnimalType.Finisher) {
		options.push({
			label: localized(DepartureTypes.departureTypeKilled),
			value: DepartureTypes.departureTypeKilled,
		});
	}
	options.push({
		label: localized(DepartureTypes.departureTypeShouldDeparture),
		value: DepartureTypes.departureTypeShouldDeparture,
	});
	return options;
};

export const GrowthTypeDepartureTypesFromPen = (removeShouldDepart: boolean) => {
	const type = [
		DefaultGrowthTypeDepartureTypeOption,
		{
			label: localized(DepartureTypes.departureTypePutDown),
			value: DepartureTypes.departureTypePutDown,
		},
		{
			label: localized(DepartureTypes.departureTypeDead),
			value: DepartureTypes.departureTypeDead,
		},
	];
	if (!removeShouldDepart) {
		type.push({
			label: localized(DepartureTypes.departureTypeShouldDeparture),
			value: DepartureTypes.departureTypeShouldDeparture,
		});
	}
	return type;
};

export function editGrowthPigsEventEntrance(state: GrowthPigEntranceState, props: GrowthPigEntranceEventProps) {
	const building = getBuildingBySectionId(state.section && state.section.id, props.hashmapLocations);
	const gpe = { ...state.growthPigEvent } as GrowthPigsEvent;
	if (state.amount === undefined || state.date === undefined) {
		return gpe;
	}

	gpe.amount = state.amount;
	gpe.toBuildingId = building && building.id;
	gpe.toSectionId = state.section && state.section.id;
	gpe.toPenId = state.penId;
	gpe.date = state.date;
	gpe.retentionDate = state.retentionDate;
	gpe.toProductionType =
		state.selectedProductionType === AnimalType.FRATS ? props.productionType : state.selectedProductionType;
	gpe.totalWeight = state.amount && state.avgWeight ? state.amount * state.avgWeight : 0;
	return gpe;
}

export function GenerateIniitialStateGrowthPigDepartureEvent(
	growthPigEventToEdit: GrowthPigsEvent | undefined,
	props: GrowthPigDepartureProps,
	state?: GrowthPigDepartureState,
): GrowthPigDepartureState {
	return {
		growthPigEvent: growthPigEventToEdit,
		fromSection:
			growthPigEventToEdit && growthPigEventToEdit.fromSectionId
				? (props.hashmapLocations[growthPigEventToEdit.fromSectionId] as ISection)
				: undefined,
		fromPenId: growthPigEventToEdit ? growthPigEventToEdit.fromPenId : undefined,
		fromProductionType: state
			? state.fromProductionType
			: growthPigEventToEdit
			? growthPigEventToEdit.fromProductionType
			: props.productionType,
		departureTypeOption:
			growthPigEventToEdit && growthPigEventToEdit.departureType
				? {
						label: localized(growthPigEventToEdit.departureType),
						value: growthPigEventToEdit.departureType,
				  }
				: state
				? state.departureTypeOption
				: props.productionType !== AnimalType.Weaner
				? DefaultGrowthTypeDepartureTypeKilledOption()
				: DefaultGrowthTypeDepartureTypeSoldOption(),
		date: growthPigEventToEdit ? growthPigEventToEdit.date : state ? new Date() : new Date(),
		data:
			growthPigEventToEdit && growthPigEventToEdit.fromSectionId
				? generateGrowthPigEventDataForSectionId(
						props.hashmapLocations[growthPigEventToEdit.fromSectionId] as ISection,
						props.growthPigsEvents,
						props.locations,
						props.productionType,
						growthPigEventToEdit.fromProductionType,
						state && state.date ? new Date() : new Date(),
				  )
				: [],
		filteredLocation: generateLocationOptionsForGrowthPigEvents(
			props.growthPigsEvents,
			props.locations,
			props.productionType,
		),
	};
}

export function getGrowthPigPenDepartureInitialState(
	growthPigEventToEdit: GrowthPigsEvent | undefined,
	props: GrowthPigDepartureProps,
	state?: GrowthPigDeparturePenState,
): GrowthPigDeparturePenState {
	let selectedReasonIdOption = DefaultGrowthTypeDepartureTypeOption;
	if (growthPigEventToEdit) {
		const reason = props.reasons.find(r => r.reason && r.reason.id === growthPigEventToEdit.departureReasonId);
		if (reason && reason.reason && reason.reason.name) {
			selectedReasonIdOption = {
				label: reason.reason.name[props.profile && props.profile.language ? props.profile.language : 'da'],
				value: reason.reason.id!,
			};
		}
	}

	return {
		youngAnimal: undefined,
		chooseIdModalIsOpen: false,
		growthPigEvent: growthPigEventToEdit,
		fromSection:
			growthPigEventToEdit && growthPigEventToEdit.fromSectionId
				? (props.hashmapLocations[growthPigEventToEdit.fromSectionId] as ISection)
				: undefined,
		fromPenId: growthPigEventToEdit ? growthPigEventToEdit.fromPenId : undefined,
		amount: growthPigEventToEdit ? growthPigEventToEdit.amount : undefined,
		avgWeight:
			growthPigEventToEdit && growthPigEventToEdit.amount && growthPigEventToEdit.totalWeight
				? growthPigEventToEdit.totalWeight / growthPigEventToEdit.amount
				: undefined,
		fromProductionType: state
			? state.fromProductionType
			: growthPigEventToEdit
			? growthPigEventToEdit.fromProductionType
			: props.productionType,
		departureTypeOption:
			growthPigEventToEdit && growthPigEventToEdit.departureType
				? { label: localized(growthPigEventToEdit.departureType), value: growthPigEventToEdit.departureType }
				: state
				? state.departureTypeOption
				: DefaultGrowthTypeDepartureTypeOption,
		departureReasonIdOption: selectedReasonIdOption,
		date: growthPigEventToEdit ? growthPigEventToEdit.date : state ? state.date : new Date(),
		data:
			growthPigEventToEdit && growthPigEventToEdit.fromSectionId && growthPigEventToEdit.fromProductionType
				? generateGrowthPigEventDataForSectionId(
						props.hashmapLocations[growthPigEventToEdit.fromSectionId] as ISection,
						props.growthPigsEvents,
						props.locations,
						growthPigEventToEdit.fromProductionType,
						growthPigEventToEdit.fromProductionType,
						state && state.date ? state.date : new Date(),
				  )
				: [],
		filteredLocation: generateLocationOptionsForGrowthPigEvents(
			props.growthPigsEvents,
			props.locations,
			props.productionType,
		),
	};
}

export function generateUpsertGrowthPigDeparturePenEvent(state: GrowthPigDeparturePenState) {
	const gpe = { ...state.growthPigEvent } as GrowthPigsEvent;
	if (state.amount === undefined || state.date === undefined) {
		return gpe;
	}

	gpe.amount = state.amount;
	gpe.fromSectionId = state.fromSection && state.fromSection.id;
	gpe.fromPenId = state.fromPenId;
	gpe.date = state.date;
	gpe.departureReasonId = state.departureReasonIdOption ? state.departureReasonIdOption.value : undefined;
	gpe.departureType = state.departureTypeOption ? state.departureTypeOption.value : undefined;
	gpe.fromProductionType = state.fromProductionType ?? AnimalType.Unknown;
	gpe.totalWeight = state.amount && state.avgWeight ? state.amount * state.avgWeight : 0;
	return gpe;
}

export function UpsertGrowthPigEventAndClose(
	gpe: GrowthPigsEvent,
	upsertGrowthPigsEvent: (gpe: GrowthPigsEvent) => Promise<void>,
	close?: () => void,
) {
	upsertGrowthPigsEvent(gpe);
	if (close) {
		close();
	}
}

export function getGrowthPigsEntranceEventInitialState(
	growthPigEventToEdit: GrowthPigsEvent | undefined,
	props: GrowthPigEntranceEventProps,
): GrowthPigEntranceState {
	return {
		selectedProductionType:
			growthPigEventToEdit && growthPigEventToEdit.toProductionType
				? growthPigEventToEdit.toProductionType
				: props.productionType,
		modalOpen: false,

		growthPigEvent: growthPigEventToEdit,
		date: growthPigEventToEdit ? growthPigEventToEdit.date : new Date(),
		retentionDate: growthPigEventToEdit ? growthPigEventToEdit.retentionDate : undefined,
		section:
			growthPigEventToEdit && growthPigEventToEdit.toSectionId
				? (props.hashmapLocations[growthPigEventToEdit.toSectionId] as ISection)
				: undefined,
		penId: growthPigEventToEdit ? growthPigEventToEdit.toPenId : undefined,
		amount: growthPigEventToEdit ? growthPigEventToEdit.amount : undefined,
		avgWeight:
			growthPigEventToEdit && growthPigEventToEdit.amount && growthPigEventToEdit.totalWeight
				? growthPigEventToEdit.totalWeight / growthPigEventToEdit.amount
				: 0,
		totalWeight: growthPigEventToEdit ? growthPigEventToEdit.totalWeight : undefined,
		isLatest: growthPigEventToEdit
			? getLatestGrowthPigEvents(props.growthPigEvents, GrowthPigEventType.GrowthPigEntranceEvent).find(
					gpe => growthPigEventToEdit.id === gpe.id,
			  ) !== undefined
			: false,
		generalSettings: props.generalSettings,
	};
}

export function getGrowthPigMoveEventInitialState(
	growthPigEventToEdit: GrowthPigsEvent | undefined,
	props: GrowthPigMoveProps,
	productionType: AnimalType,
	currentDate: Date,
	GrowthPigMoveState?: GrowthPigMoveState,
): GrowthPigMoveState {
	const sectionWithAnimalTypeFinisher = props.locations.sections.find(sec => sec.animalType === AnimalType.Finisher);

	return {
		fromSection:
			growthPigEventToEdit && growthPigEventToEdit.fromSectionId
				? (props.hashmapLocations[growthPigEventToEdit.fromSectionId] as ISection)
				: undefined,
		toSection:
			growthPigEventToEdit && growthPigEventToEdit.toSectionId
				? (props.hashmapLocations[growthPigEventToEdit.toSectionId] as ISection)
				: undefined,
		fromProductionType:
			growthPigEventToEdit && growthPigEventToEdit.fromProductionType
				? growthPigEventToEdit.fromProductionType
				: productionType,
		toProductionType:
			growthPigEventToEdit && growthPigEventToEdit.toProductionType
				? growthPigEventToEdit.toProductionType
				: GrowthPigMoveState && GrowthPigMoveState.toProductionType
				? GrowthPigMoveState.toProductionType
				: sectionWithAnimalTypeFinisher !== undefined
				? AnimalType.Finisher
				: productionType,
		growthPigEvent: growthPigEventToEdit,
		avgWeight:
			growthPigEventToEdit && growthPigEventToEdit.amount && growthPigEventToEdit.totalWeight
				? growthPigEventToEdit.totalWeight / growthPigEventToEdit.amount
				: undefined,
		date:
			growthPigEventToEdit && growthPigEventToEdit.date
				? growthPigEventToEdit.date
				: currentDate
				? currentDate
				: new Date(),
		data:
			growthPigEventToEdit && growthPigEventToEdit.fromSectionId && growthPigEventToEdit.fromProductionType
				? generateGrowthPigEventDataForSectionId(
						props.hashmapLocations[growthPigEventToEdit.fromSectionId] as ISection,
						props.growthPigsEvents,
						props.locations,
						props.productionType,
						growthPigEventToEdit.fromProductionType,
						currentDate,
				  )
				: [],
		modalOpen: false,
		toPenId: growthPigEventToEdit ? growthPigEventToEdit.toPenId : undefined,
		filteredLocation: generateLocationOptionsForGrowthPigEvents(
			props.growthPigsEvents,
			props.locations,
			props.productionType,
		),
		dataToModalForward: [],
		isLatest: growthPigEventToEdit
			? getLatestGrowthPigEvents(props.growthPigEvents, GrowthPigEventType.GrowthPigMoveEvent).find(
					gpe => growthPigEventToEdit.id === gpe.id,
			  ) !== undefined
			: false,
	};
}

export function saveGrowthPigEntranceEventFromGrowthPigEntranceEvent(
	state: GrowthPigEntranceState,
	props: GrowthPigEntranceEventProps,
	reset: () => void,
) {
	const itemsToSave: IGrowthPigEntranceEventDto[] = generateDataForEntranceDistribute(
		props.locations.pens,
		state.section && state.section.id,
		state.penId,
		state.amount,
		state.avgWeight,
		state.retentionDate,
		props.profile,
		state.date,
		props.productionType,
		undefined,
		state.feedCurve,
		state.recipeNumber,
	);
	if (itemsToSave.length > 0) {
		props.upsertGrowthPigsEntranceEvents(itemsToSave as GrowthPigEntranceEventDto[]);
	}
	reset();
}

export function getGrowthPigEntranceResetComponentState(props: GrowthPigEntranceEventProps, currentDate?: Date) {
	return {
		date: currentDate,
		retentionDate: undefined,
		section: undefined,
		penId: undefined,
		amount: undefined,
		avgWeight: undefined,
		totalWeight: undefined,
		modalOpen: false,
	};
}

export function saveGrowthPigEntranceEventFromGrowthPigDistribute(
	state: GrowthPigDistributeState,
	props: GrowthPigEntranceDistributeProps,
	close: (saved?: boolean) => void,
) {
	if (props.profile) {
		const itemsToSave: IGrowthPigEntranceEventDto[] = generateGrowthPigEntranceEventDto(
			state.data,
			props.date,
			props.profile.siteId,
			state.feedCurve,
			state.recipeNumber,
		);
		if (itemsToSave.length > 0) {
			props.upsertGrowthPigsEntranceEvents(itemsToSave as GrowthPigEntranceEventDto[]);
		}
		close(true);
	}
}

export function generateGrowthPigDistributeEntranceInitialState(
	props: GrowthPigEntranceDistributeProps,
): GrowthPigDistributeState {
	let data: IGrowthPigEntranceEventDto[] = [];
	let fromPenId: Option | undefined;
	let toPenId: Option | undefined;
	const pensInSection = props.locations.pens.filter(p => p.sectionId === props.toSectionId).sort(sortPensByName);
	const section = props.locations.sections.find(p => p.id === props.toSectionId);

	if (pensInSection.length > 0) {
		fromPenId = { label: pensInSection[0].name!, value: pensInSection[0].id! };
		toPenId = {
			label: pensInSection[pensInSection.length - 1].name!,
			value: pensInSection[pensInSection.length - 1].id!,
		};
		data = generateDataForEntranceDistribute(
			pensInSection,
			props.toSectionId,
			undefined,
			props.amount,
			props.avgWeight,
			props.retentionDate,
			props.profile,
			props.date,
			props.productionType,
			props.growthPigEvents.filter(e => e.bundleIdentifier === props.bundleId),
		);
	}
	// get totalWeight and total amount from data
	const totalAmount = data.reduce((acc, item) => acc + (item.amount ? item.amount : 0), 0);
	const totalWeight = data.reduce((acc, item) => acc + (item.totalWeight ? item.totalWeight : 0), 0);
	return {
		data,
		fromPenId,
		toPenId,
		pensInSection,
		amountPigsNotDistributed: 0,
		totalWeightNotDistributed: 0,
		disablePenColumn: !(section && section.usePens),
		totalWeight,
		amount: totalAmount,
	};
}

export function getMaxNumberForAmountForGrowthPigEvent(
	data: GrowthPigDepartureFromSectionRow[],
	fromPenId?: string,
	growthPigEvent?: GrowthPigsEvent,
) {
	let sum = data
		.filter(a => (a.penId === fromPenId && fromPenId) || !fromPenId)
		.reduce((sum, current) => sum + (current.amount !== undefined ? current.amount : 0), 0);
	if (growthPigEvent && growthPigEvent.amount) {
		sum += growthPigEvent.amount;
	}
	return sum;
}

export const upsertGrowthPigEntranceEvent = async (
	props: GrowthPigEntranceEventProps,
	state: GrowthPigEntranceState,
	showConfirmAlert: (message: string) => Promise<boolean>,
) => {
	if (state.growthPigEvent) {
		const gpeModified = editGrowthPigsEventEntrance(state, props);
		const gpeDto = props.growthPigsEvents.find(gpe => gpe.sectionId === gpeModified.toSectionId);
		if (gpeDto && gpeDto.growthPigEventDistributions) {
			UpsertGrowthPigEventAndClose(gpeModified, props.upsertGrowthPigsEvent, props.closeEditModal);
			return true;
		}
	}
	return false;
};

export const upsertGrowthPigDepartureEvent = async (
	props: GrowthPigDepartureProps,
	state: GrowthPigDepartureState,
	showConfirmAlert: (message: string) => Promise<boolean>,
) => {
	if (state.growthPigEvent && state.date) {
		const gpeModified = state.growthPigEvent;
		gpeModified.date = state.date;
		handleDepartureEvent(props, gpeModified, showConfirmAlert);
	}
};

export const upsertGrowthPigDeparturePenEvent = async (
	props: GrowthPigDepartureProps,
	state: GrowthPigDeparturePenState,
	showConfirmAlert: (message: string) => Promise<boolean>,
) => {
	if (state.growthPigEvent && state.date) {
		const gpeModified = generateUpsertGrowthPigDeparturePenEvent(state);
		gpeModified.date = state.date;
		gpeModified.id =
			props.growthPigsEvent && props.growthPigsEvent.id ? props.growthPigsEvent.id : new ObjectID().toHexString();
		if (props.growthPigsEvent && !props.growthPigsEvent.id) {
			const dataToCheck = state.data.find(gped => gped.penId === gpeModified.fromPenId);
			gpeModified.oldTotalWeight =
				dataToCheck && gpeModified.amount && dataToCheck.oldAvgWeight
					? gpeModified.amount * dataToCheck.oldAvgWeight
					: 0;
			gpeModified.fromProductionType = props.growthPigsEvent && props.growthPigsEvent.fromProductionType;
		}
		await handleDepartureEvent(props, gpeModified, showConfirmAlert);
	}
};

async function handleDepartureEvent(
	props: GrowthPigDepartureProps,
	gpeModified: GrowthPigsEvent,
	showConfirmAlert: (message: string) => Promise<boolean>,
) {
	UpsertGrowthPigEventAndClose(
		GrowthPigsEvent.fromJS(gpeModified),
		props.upsertGrowthPigsEvent,
		props.closeEditModal,
	);
}

export const getGpeBundleIndentifier = (gpe: GrowthPigsEvent) => {
	return gpe.date
		? gpe.date
		: '' + gpe.fromSectionId
		? gpe.fromSectionId
		: '' + gpe.toSectionId
		? gpe.toSectionId
		: '' + gpe.growthPigEventType
		? gpe.growthPigEventType
		: '' + gpe.departureType
		? gpe.departureType
		: '' + gpe.departureReasonId
		? gpe.departureReasonId
		: '';
};
