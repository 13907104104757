import React from 'react';
import isEqual from 'react-fast-compare';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Building, IBuilding, IPen, ISection, Section } from 'shared/api/api';
import { Colors } from 'shared/assets/root-assets/colors';
import DeleteIcon from 'shared/assets/src-assets/png/delete_icon.png';
import AddSectionIcon from 'shared/assets/src-assets/png/ikon_tilfoj_sektion_darkgrey.png';
import AddPenIcon from 'shared/assets/src-assets/png/ikon_tilfoj_sti_darkgrey.png';
import OpretProcesudstyr_Grey from 'shared/assets/src-assets/png/OpretProcesudstyr_Grey.png';
import CreateBuildningIcon from 'shared/assets/src-assets/png/opret_bygning.png';
import PenIcon from 'shared/assets/src-assets/png/pen_icon.png';
import Unsetted_Pens_Icon from 'shared/assets/src-assets/png/not_set_dark_grey.png';
import RelationLokation_Dark_Grey from 'shared/assets/src-assets/png/RelationLokation_Dark_Grey.png';
import RelationLokation_Grey from 'shared/assets/src-assets/png/RelationLokation_Grey.png';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { getAnimalCountByBuilding, getAnimalCountBySection } from 'shared/helpers/location-helper';
import { IntegerChanged } from 'shared/helpers/number-helper';
import {
	GetLocations,
	RemoveBuilding,
	RemoveSection,
	UpsertBuilding,
	UpsertPen,
	UpsertSection,
} from 'shared/state/ducks/locations/operations';
import { GetSyncData as GetMoveEventSyncData } from 'shared/state/ducks/move-events/operations';
import { GetProcessEquipmentData } from 'shared/state/ducks/process-equipment-data/operations';
import { GetSyncData as ProcessEquipmentGetSyncData } from 'shared/state/ducks/process-equipments/operations';
import { GetByProfile } from 'shared/state/ducks/site/operations';
import { GetSyncData as StemAnimalGetSyncData } from 'shared/state/ducks/stem-animals/operations';
import { localized, localizedDynamic } from 'shared/state/i18n/i18n';
import { LocationCreateType } from 'shared/state/models/locations/location-create-type';
import { WebAppState } from 'web/state/store.web';
import CreateLocationWrapper from 'web/view/components/location/location-overview-components/location-create-components/create-location-wrapper';
import EditBuilding from 'web/view/components/location/location-overview-components/location-edit-components/edit-building';
import EditSection from 'web/view/components/location/location-overview-components/location-edit-components/edit-section';
import { LocationTableConstants } from 'web/view/components/location/location-overview-components/location-table-constant';
import {
	calculateCapacity,
	generateTableData,
} from 'web/view/components/location/location-overview-components/location-tree-components/location-overview-helper';
import { LocationTableModel } from 'web/view/components/location/location-overview-components/location-tree-components/location-table-model';
import SiteComponent from 'web/view/components/location/location-overview-components/site-component';
import CreateLocationRelation from 'web/view/components/process-equipment/create-location-relations';
import CreateProcessEquipment from 'web/view/components/process-equipment/create-process-equipment';
import ProcessEquipmentTable from 'web/view/components/process-equipment/process-equipment-table';
import UnitsOnProcessEquipment from 'web/view/components/process-equipment/units-on-process-equipment';
import { showAlert, ShowConfirmAlert } from 'web/view/components/skiold-alert/skiold-alert';
import { SkioldInput } from 'web/view/components/skiold-components/skiold-input/skiold-input';
import { SkioldModal } from 'web/view/components/skiold-components/skiold-modal/skiold-modal';
import { Row, SkioldTable } from 'web/view/components/skiold-components/skiold-table/skiold-table';
import { SkioldTouchableOpacity } from 'web/view/components/skiold-components/skiold-touchable-opacity';
import { SowListConstants } from 'web/view/components/stem-animal/animal-lists/table-constants';
import { SkioldImage } from 'web/view/components/utils/svg/skiold-image';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import './location-overview.scss';

const mapStateToProps = (state: WebAppState) => {
	return {
		site: state.site.site,
		profile: state.profile.active!,
		buildings: state.locations.buildings,
		sections: state.locations.sections,
		pens: state.locations.pens,
		moveEvents: state.moveEvents.entities,
		stemAnimals: state.stemAnimals.entities,
		processEquipments: state.processEquipments.entities,
		processEquipmentData: state.processEquipmentData.entities,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
	return {
		getSiteByProfile: (profileId: string) => GetByProfile(profileId)(dispatch),
		GetProcessEquipmentData: () => GetProcessEquipmentData()(dispatch),
		getSyncData: () => ProcessEquipmentGetSyncData()(dispatch),
		getMoveEventSyncData: () => GetMoveEventSyncData()(dispatch),
		stemAnimalGetSyncData: () => StemAnimalGetSyncData()(dispatch),
		removeSection: (section: ISection) => RemoveSection(section)(dispatch),
		removeBuilding: (building: IBuilding) => RemoveBuilding(building)(dispatch),
		loadBuildings: () => GetLocations()(dispatch),
		updateBuilding: (building: IBuilding) => UpsertBuilding(building)(dispatch),
		updateSection: (section: ISection) => UpsertSection(section)(dispatch),
		updatePen: (pen: IPen) => UpsertPen(pen)(dispatch),
	};
};

export interface State {
	modalIsOpen: boolean;
	filteredLocations: LocationTableModel[];
	completeModelList: LocationTableModel[];
	section: ISection | undefined;
	building: IBuilding | undefined;
	createType: LocationCreateType | undefined;
	modalSelection:
		| 'editBuilding'
		| 'editSection'
		| 'createLocation'
		| 'createLocationRelation'
		| 'createProcessEquipment'
		| 'unitsOnProcessEquipment'
		| undefined;
}

interface PropsFromParent {
	selected: string;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;
class LocationOverview extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			modalIsOpen: false,
			filteredLocations: [],
			completeModelList: generateTableData(
				props.buildings,
				props.sections,
				props.pens,
				props.processEquipmentData,
			),
			section: undefined,
			building: undefined,
			createType: undefined,
			modalSelection: undefined,
		};
	}

	public componentDidUpdate(prevProps: Props, prevState: State) {
		const buildingsEqual = isEqual(prevProps.buildings, this.props.buildings);
		const sectionsEqual = isEqual(prevProps.sections, this.props.sections);
		const pensEqual = isEqual(prevProps.pens, this.props.pens);
		const processEquipmentDataEqual = isEqual(prevProps.processEquipmentData, this.props.processEquipmentData);
		if (!buildingsEqual || !sectionsEqual || !pensEqual || !processEquipmentDataEqual) {
			const completeModelList = generateTableData(
				this.props.buildings,
				this.props.sections,
				this.props.pens,
				this.props.processEquipmentData,
			);
			this.setState({ completeModelList }, () => this.filterBuildings());
		}
	}

	public componentDidMount() {
		if (this.props.profile.siteId) {
			this.props.getSiteByProfile(this.props.profile.id!);
			this.props.loadBuildings();
			this.props.stemAnimalGetSyncData();
			this.props.getMoveEventSyncData();
			this.props.getSyncData();

			this.props.GetProcessEquipmentData();
		}
		this.filterBuildings();
	}

	public render() {
		return (
			<ViewWeb className="location-overview" style={{ width: LocationTableConstants.totalWidth }}>
				{this.renderSiteTopView()}

				{this.props.selected === 'locationOverview' && this.renderTable()}
				{this.props.selected === 'processEquipment' && this.renderProcessEquipment()}
				{this.renderModal()}
			</ViewWeb>
		);
	}

	public renderSiteTopView() {
		return (
			<ViewWeb className="site-top-view">
				{this.props.selected === 'locationOverview' && <SiteComponent />}
				<ViewWeb className="upper-button-container">
					{this.props.selected === 'processEquipment' && (
						<SkioldTouchableOpacity
							className="create-process-equipment-button"
							onPress={this.createProcessEquipment}
						>
							<SkioldImage width="40" height="40" imageData={OpretProcesudstyr_Grey} />
							<TextWeb className="label">{localized('createProcessEquipment')}</TextWeb>
						</SkioldTouchableOpacity>
					)}
					{this.props.selected === 'locationOverview' && (
						<SkioldTouchableOpacity
							className="create-building-button"
							onPress={() => this.createBuilding()}
						>
							<SkioldImage width="40" height="40" imageData={CreateBuildningIcon} />
							<TextWeb className="label">{localized('createBuilding')}</TextWeb>
						</SkioldTouchableOpacity>
					)}
					{this.props.selected === 'locationOverview' && (
						<SkioldTouchableOpacity className="create-building-button" onPress={this.openCreateRelation}>
							<SkioldImage width="40" height="40" imageData={RelationLokation_Grey} />
							<TextWeb className="label">{localized('relationLocation1')}</TextWeb>
							<TextWeb className="label">{localized('relationLocation2')} </TextWeb>
						</SkioldTouchableOpacity>
					)}
				</ViewWeb>
			</ViewWeb>
		);
	}

	public renderProcessEquipment = () => {
		return <ProcessEquipmentTable />;
	};

	public renderTable() {
		return (
			<ViewWeb className="location-table-container">
				<SkioldTable
					columns={this.generateColumns()}
					data={this.state.filteredLocations}
					filterable={false}
					sortHeaderId={'animalNr'}
					sortable={false}
					className="skiold-double-roundheaders-table"
					getTrProps={(state: any, rowInfo: Row<LocationTableModel>, column: any) => {
						//Sets color for table rows
						if (rowInfo.original.isSection === true) {
							return { style: { background: Colors.lightestGrey } };
						}
						if (rowInfo.original.isPen === true) {
							return { style: { background: Colors.lighterGrey } };
						}
						if (rowInfo.original.isBuilding === true) {
							return { style: { background: Colors.white } };
						}
						return {};
					}}
				/>
			</ViewWeb>
		);
	}
	public renderModal() {
		return (
			<SkioldModal close={this.closeModal} isOpen={this.state.modalIsOpen} padding="0">
				{this.state.modalSelection === 'createLocation' && (
					<CreateLocationWrapper
						createType={this.state.createType!}
						building={this.state.building!}
						section={this.state.section}
						close={this.closeModal}
						isOpen={this.state.modalIsOpen}
					/>
				)}
				{this.state.modalSelection === 'editBuilding' && (
					<EditBuilding closeEditModal={this.closeModal} building={this.state.building!} />
				)}
				{this.state.modalSelection === 'editSection' && (
					<EditSection
						closeEditModal={this.closeModal}
						section={this.state.section!}
						building={this.state.building!}
					/>
				)}
				{this.state.modalSelection === 'createLocationRelation' && (
					<CreateLocationRelation closeModal={this.closeModal} />
				)}
				{this.state.modalSelection === 'createProcessEquipment' && (
					<CreateProcessEquipment processEquipmentId={''} closeModal={this.closeModal} />
				)}
				{this.state.modalSelection === 'unitsOnProcessEquipment' && (
					<UnitsOnProcessEquipment
						closeModal={this.closeModal}
						Section={this.state.section!}
						Building={this.state.building!}
					/>
				)}
			</SkioldModal>
		);
	}

	public saveBuilding(buildingModel: LocationTableModel) {
		const building = this.props.buildings.find(building => building.id === buildingModel.buildingId);
		if (building) {
			building.order = buildingModel.order as number;
			this.props.updateBuilding(building);
		}
	}

	public saveSection(sectionModel: LocationTableModel) {
		const section = this.props.sections.find(sec => sec.id === sectionModel.sectionId);

		if (section) {
			section.order = sectionModel.order as number;
			this.props.updateSection(section);
		}
	}

	public savePen(penModel: LocationTableModel) {
		const pen = this.props.pens.find(pen => pen.id === penModel.penId);

		if (pen) {
			pen.order = penModel.order as number;
			this.props.updatePen(pen);
		}
	}

	public orderChanged(text: string, model: LocationTableModel) {
		IntegerChanged(text, valueToSet => {
			const locations = [...this.state.filteredLocations];
			if (model.isBuilding) {
				const index = locations.findIndex(loc => loc.isBuilding! && loc.buildingId === model.buildingId);
				locations[index].order = valueToSet;
			}
			if (model.isSection) {
				const index = locations.findIndex(loc => loc.isSection! && loc.sectionId === model.sectionId);
				locations[index].order = valueToSet;
			}
			if (model.isPen) {
				const index = locations.findIndex(loc => loc.isPen! && loc.penId === model.penId);
				locations[index].order = valueToSet;
			}

			this.setState({
				filteredLocations: locations,
			});
		});
	}

	private closeModal = () => {
		this.setState({ modalIsOpen: false });
	};

	private filterBuildings() {
		const deepCopyLocationList = this.state.completeModelList.map(locationItem => Object.assign({}, locationItem));
		if (this.state.filteredLocations.length < 1) {
			if (deepCopyLocationList) {
				const buildings = deepCopyLocationList.filter(buildingFilter => buildingFilter.isBuilding);

				this.setState({ filteredLocations: buildings });
			}
		} else {
			//Deepcopy to med sure we dont mess the init data
			const buildings = deepCopyLocationList.filter(buildingFilter => buildingFilter.isBuilding);
			const buildingIdsShowChildren = this.state.filteredLocations.map(buildingToExpand => {
				if (buildingToExpand.isBuilding && buildingToExpand.showChildren) {
					return buildingToExpand.buildingId;
				}
				return undefined;
			});
			const sectionIdsShowChildren = this.state.filteredLocations.map(sectionToExpand => {
				if (sectionToExpand.isSection && sectionToExpand.showChildren) {
					return sectionToExpand.sectionId;
				}
				return undefined;
			});

			//Makes sure to fold out same locations after editing
			let tmpCurrentState = buildings.map(locationTableItem => ({
				...locationTableItem,
			}));
			tmpCurrentState.forEach((buildingFromState, i) => {
				if (buildingFromState.isBuilding && buildingIdsShowChildren.includes(buildingFromState.buildingId)) {
					let newTmpState = this.showSections(
						buildingFromState,
						tmpCurrentState.findIndex(
							building => building.buildingId === buildingFromState.buildingId && building.isBuilding!,
						),
						sectionIdsShowChildren,
						tmpCurrentState,
					);
					if (newTmpState) {
						// Fold out sections
						tmpCurrentState = newTmpState;
						if (sectionIdsShowChildren) {
							let sectionsToFoldOut = deepCopyLocationList.filter(
								sectionFilter =>
									sectionFilter.isSection &&
									sectionFilter.buildingId === buildingFromState.buildingId,
							);
							// Fold out Pens
							sectionsToFoldOut.forEach((section, i) => {
								if (section.isSection && sectionIdsShowChildren.includes(section.sectionId!)) {
									let newTmpStatePen = this.showPens(
										section,
										tmpCurrentState.findIndex(
											sec => sec.sectionId === section.sectionId && section.isSection!,
										),
										tmpCurrentState,
									);
									if (newTmpStatePen) {
										tmpCurrentState = newTmpStatePen;
									}
								}
							});
						}
					}
				}
			});

			this.setState({ filteredLocations: tmpCurrentState });
		}

		return [];
	}

	private showSections(
		building: LocationTableModel,
		index: number,
		sectionIdsShowChildren?: Array<string | undefined>,
		tmpFilteredLocations?: LocationTableModel[],
	) {
		const deepCopyLocationList = this.state.completeModelList.map(locationItem => Object.assign({}, locationItem));
		if (deepCopyLocationList && index > -1) {
			const sections = deepCopyLocationList.filter(
				sectionFilter => sectionFilter.isSection && sectionFilter.buildingId === building.buildingId,
			);
			const currentState = tmpFilteredLocations
				? tmpFilteredLocations.map(locationTableItem => ({ ...locationTableItem }))
				: this.state.filteredLocations.map(locationTableItem => ({ ...locationTableItem }));
			if (currentState && currentState[index]) {
				currentState[index].showChildren = !building.showChildren;
				if (currentState[index].showChildren) {
					//Insert rows
					currentState.splice(index + 1, 0, ...sections);
				} else {
					//Remove rows
					const pens = currentState.filter(
						penFilter => penFilter.isPen && penFilter.buildingId === building.buildingId,
					);
					sections.forEach(section => {
						section.showChildren = false;
					});
					const toRemove = pens ? sections.length + pens.length : sections.length;
					currentState.splice(index + 1, toRemove);
				}
				//Fold out pens after editing
				if (sectionIdsShowChildren && tmpFilteredLocations) {
					return currentState;
				}
				this.setState({ filteredLocations: currentState });
			}
		}
		return tmpFilteredLocations;
	}

	private showPens(section: LocationTableModel, index: number, tmpFilteredLocations?: LocationTableModel[]) {
		const deepCopyLocationList = this.state.completeModelList.map(locationItem => Object.assign({}, locationItem));
		if (deepCopyLocationList && index > -1) {
			const pens = deepCopyLocationList.filter(
				penFilter => penFilter.isPen && penFilter.sectionId === section.sectionId,
			);
			const currentState = tmpFilteredLocations
				? tmpFilteredLocations.map(locationTableItem => ({ ...locationTableItem }))
				: this.state.filteredLocations.map(locationTableItem => ({ ...locationTableItem }));
			const showChildren = !section.showChildren;
			if (currentState && currentState[index]) {
				currentState[index].showChildren = showChildren;
				let stateToSet;
				//Insert rows
				if (showChildren) {
					currentState.splice(index + 1, 0, ...pens);
					stateToSet = { filteredLocations: currentState };
				} else {
					//Remove rows
					currentState.splice(index + 1, pens.length);
					stateToSet = { filteredLocations: currentState };
				}

				if (tmpFilteredLocations) {
					return currentState;
				}
				this.setState(stateToSet);
			}
		}
		return tmpFilteredLocations;
	}

	private renderArrow(showChildren: boolean) {
		const foldClass = showChildren ? 'arrow-folded-out' : 'arrow-folded';
		return <ViewWeb className={`folder-arrow ${foldClass}`} />;
	}

	private createProcessEquipment = () => {
		this.setState({
			modalIsOpen: true,
			modalSelection: 'createProcessEquipment',
		});
	};
	private createBuilding() {
		this.setState({
			createType: LocationCreateType.CreateBuilding,
			modalIsOpen: true,
			modalSelection: 'createLocation',
			building: Building.fromJS({}),
			section: undefined,
		});
	}

	private addSection(buildingId: string) {
		const building = this.props.buildings.find(build => build.id === buildingId);
		this.setState({
			createType: LocationCreateType.AddSection,
			modalIsOpen: true,
			building,
			section: undefined,
			modalSelection: 'createLocation',
		});
	}

	private addPen(buildingId: string, sectionId: string) {
		const building = this.props.buildings.find(build => build.id === buildingId);
		const section = this.props.sections.find(sec => sec.id === sectionId);
		this.setState({
			createType: LocationCreateType.AddPen,
			modalIsOpen: true,
			building,
			section,
			modalSelection: 'createLocation',
		});
	}

	private editBuilding(buildingId: string) {
		const building = this.props.buildings.find(building => building.id === buildingId);
		this.setState({ modalIsOpen: true, building, modalSelection: 'editBuilding' });
	}

	private editSection(sectionId: string, buildingId: string) {
		const building = this.props.buildings.find(building => building.id === buildingId);
		const section = this.props.sections.find(section => section.id === sectionId);
		this.setState({ modalIsOpen: true, building, modalSelection: 'editSection', section });
	}

	private async remove(row: LocationTableModel) {
		if (row.isBuilding) {
			if (getAnimalCountByBuilding(row.buildingId!, this.props.pens) > 0) {
				showAlert(localized(ExceptionMessage.VALIDATION_ERROR_BUILDING_IS_NOT_EMPTY));
				return;
			}
			if (await ShowConfirmAlert(localized(ExceptionMessage.VALIDATION_WARNING_CONFIRM_DELETION_BUILDING))) {
				const filterLocationc = this.state.filteredLocations.filter(loc => loc.buildingId !== row.buildingId);
				this.setState({ filteredLocations: filterLocationc });
				this.props.removeBuilding(Building.fromJS({ id: row.buildingId } as IBuilding));
			}
		}
		if (row.isSection) {
			if (getAnimalCountBySection(row.sectionId!, this.props.pens) > 0) {
				showAlert(localized(ExceptionMessage.VALIDATION_ERROR_SECTION_IS_NOT_EMPTY));
				return;
			}
			if (await ShowConfirmAlert(localized(ExceptionMessage.VALIDATION_WARNING_CONFIRM_DELETION_SECTION))) {
				const filterLocationc = this.state.filteredLocations.filter(loc => loc.sectionId !== row.sectionId);
				this.setState({ filteredLocations: filterLocationc });
				this.props.removeSection(Section.fromJS({ id: row.sectionId } as ISection));
			}
		}
	}

	private openCreateRelation = () => {
		this.setState({ modalIsOpen: true, modalSelection: 'createLocationRelation' });
	};

	private generateColumns() {
		return [
			{
				id: 'edit',
				Header: localized('Edit'),
				headerClassName: 'merged-roundheaders',
				width: LocationTableConstants.editWidth,
				Cell: (row: Row<LocationTableModel>) =>
					row.original.isBuilding ? (
						<SkioldTouchableOpacity
							className="edit-button"
							onPress={() => this.editBuilding(row.original.buildingId!)}
						>
							<SkioldImage width="30" height="30" imageData={PenIcon} />
						</SkioldTouchableOpacity>
					) : row.original.isSection ? (
						<SkioldTouchableOpacity
							className="edit-button"
							onPress={() => this.editSection(row.original.sectionId!, row.original.buildingId!)}
						>
							<SkioldImage
								width={SowListConstants.iconSVGWidth}
								height={SowListConstants.iconSVGWidth}
								imageData={PenIcon}
							/>
						</SkioldTouchableOpacity>
					) : (
						<ViewWeb />
					),
			},
			{
				id: 'add',
				Header: localized('Add'),
				headerClassName: 'parent-roundheaders',
				columns: [
					{
						id: 'addSection',
						Header: localized('section'),
						width: LocationTableConstants.sectionAddWidth,
						Cell: (row: Row<LocationTableModel>) =>
							row.original.isBuilding ? (
								<SkioldTouchableOpacity
									className="edit-button"
									onPress={() => this.addSection(row.original.buildingId!)}
								>
									<SkioldImage
										width={SowListConstants.iconSVGWidth}
										height={SowListConstants.iconSVGWidth}
										imageData={AddSectionIcon}
									/>
								</SkioldTouchableOpacity>
							) : (
								<ViewWeb />
							),
					},
					{
						id: 'addPen',
						Header: localized('pen'),
						width: LocationTableConstants.penAddWidth,
						Cell: (row: Row<LocationTableModel>) =>
							row.original.isSection ? (
								<SkioldTouchableOpacity
									className="edit-button"
									onPress={() => this.addPen(row.original.buildingId!, row.original.sectionId!)}
								>
									<SkioldImage width="30" height="30" imageData={AddPenIcon} />
								</SkioldTouchableOpacity>
							) : (
								<ViewWeb />
							),
					},
				],
			},
			{
				id: 'building',
				Header: localized('building'),
				headerClassName: 'parent-roundheaders',
				columns: [
					{
						id: 'buildingNumber',
						Header: localized('Num'),
						width: LocationTableConstants.buildingNumWidth,
						accessor: (d: LocationTableModel) => d.buildingNumber,
					},
					{
						id: 'buildingName',
						Header: localized('name'),
						width: LocationTableConstants.buildingNameWidth,
						Cell: (row: Row<LocationTableModel>) =>
							row.original.isBuilding ? (
								<ViewWeb>
									{row.original.useSections ? (
										<ViewWeb className="folder-container">
											<SkioldTouchableOpacity
												className="height100percent"
												containerClassName="height100percent"
												onPress={() => this.showSections(row.original, row.index)}
											>
												{this.renderArrow(row.original.showChildren!)}

												<TextWeb className="folder-container-label">
													{row.original.buildingName}
												</TextWeb>
											</SkioldTouchableOpacity>
										</ViewWeb>
									) : (
										<TextWeb>{row.original.buildingName}</TextWeb>
									)}
								</ViewWeb>
							) : (
								<ViewWeb />
							),
					},
					{
						id: 'productionForm',
						width: LocationTableConstants.productionWidth,
						Header: localized('production'),
						accessor: (d: LocationTableModel) => localizedDynamic(d.productionForm!),
					},
				],
			},
			{
				id: 'section',
				Header: localized('section'),
				headerClassName: 'parent-roundheaders',
				columns: [
					{
						id: 'sectionName',
						Header: localized('name'),
						width: LocationTableConstants.sectionNumWidth,
						accessor: (d: LocationTableModel) => d.sectionName,
					},
					{
						id: 'sectionAnimalType',
						Header: localized('production'),
						width: LocationTableConstants.sectionTypeWidth,
						accessor: (d: LocationTableModel) => d.productionType && localized(d.productionType),
					},
					{
						id: 'sectionType',
						Header: localized('Type'),
						width: LocationTableConstants.sectionTypeWidth,
						Cell: (row: Row<LocationTableModel>) =>
							row.original.isSection ? (
								<ViewWeb>
									{row.original.usePens ? (
										<ViewWeb className="folder-container">
											<SkioldTouchableOpacity
												className="height100percent"
												containerClassName="height100percent"
												onPress={() => this.showPens(row.original, row.index)}
											>
												{this.renderArrow(row.original.showChildren!)}
												<TextWeb className="folder-container-label">
													{localized(row.original.sectionType!)}
												</TextWeb>
											</SkioldTouchableOpacity>
										</ViewWeb>
									) : (
										<TextWeb>{localized(row.original.sectionType!)}</TextWeb>
									)}
								</ViewWeb>
							) : (
								<ViewWeb />
							),
					},
				],
			},

			{
				id: 'penNumber',
				Header: localized('Pen'),
				width: LocationTableConstants.penWidth,
				headerClassName: 'merged-roundheaders-left',
				accessor: (d: LocationTableModel) => d.penNumber,
			},
			{
				id: 'capacity',
				Header: localized('capacity'),
				width: LocationTableConstants.capacityWidth,
				headerClassName: 'merged-header',
				accessor: (d: LocationTableModel) => {
					if (d.isPen) {
						return d.capacity;
					}
					if (d.isSection) {
						return calculateCapacity(this.props.pens, d.sectionId!);
					}
					if (d.isBuilding) {
						return calculateCapacity(this.props.pens, d.buildingId!, this.props.sections);
					}
					return '';
				},
			},
			{
				id: 'units',
				Header: localized('units'),
				width: LocationTableConstants.unitsWidth,
				headerClassName: 'merged-roundheaders-right',
				Cell: (row: Row<LocationTableModel>) => {
					return !row.original.isPen &&
						((row.original.isBuilding && row.original.useUnits) ||
							(row.original.isSection && row.original.useUnits)) ? (
						<ViewWeb style={{ alignItems: 'center' }}>
							<SkioldTouchableOpacity onPress={() => this.editUnit(row.original)}>
								<SkioldImage
									width={SowListConstants.iconSVGWidth}
									height={SowListConstants.iconSVGWidth}
									imageData={
										row.original.unsettedUnitToPens
											? Unsetted_Pens_Icon
											: RelationLokation_Dark_Grey
									}
								/>
							</SkioldTouchableOpacity>
						</ViewWeb>
					) : (
						<ViewWeb />
					);
				},
			},

			{
				id: 'sort',
				Header: localized('sort'),

				headerClassName: 'parent-roundheaders',
				columns: [
					{
						id: 'orderBuilding',
						Header: localized('building'),
						width: LocationTableConstants.buildingOrderWidth,
						accessor: (d: LocationTableModel) =>
							d.isBuilding ? (
								<SkioldInput
									className="order-input"
									onChangeText={text => this.orderChanged(text, d)}
									text={d.order ? d.order + '' : ''}
									onBlur={() => this.saveBuilding(d)}
								/>
							) : (
								''
							),
					},
					{
						id: 'orderSection',
						Header: localized('section'),
						width: LocationTableConstants.sectionOrderWidth,
						accessor: (d: LocationTableModel) =>
							d.isSection ? (
								<SkioldInput
									className="order-input"
									onChangeText={text => this.orderChanged(text, d)}
									text={d.order ? d.order + '' : ''}
									onBlur={() => this.saveSection(d)}
								/>
							) : (
								''
							),
					},
					{
						id: 'orderPen',
						Header: localized('pen'),
						width: LocationTableConstants.penOrderWidth,
						accessor: (d: LocationTableModel) =>
							d.isPen ? (
								<SkioldInput
									className="order-input"
									onChangeText={text => this.orderChanged(text, d)}
									text={d.order ? d.order + '' : ''}
									onBlur={() => this.savePen(d)}
								/>
							) : (
								''
							),
					},
				],
			},

			{
				id: 'delete',
				headerClassName: 'merged-roundheaders',
				width: SowListConstants.iconWidth,
				sortable: false,
				filterable: false,
				Cell: (row: Row<LocationTableModel>) =>
					row.original.isBuilding || row.original.isSection ? (
						<SkioldTouchableOpacity onPress={() => this.remove(row.original)}>
							<SkioldImage
								width={SowListConstants.iconSVGWidth}
								height={SowListConstants.iconSVGWidth}
								imageData={DeleteIcon}
							/>
						</SkioldTouchableOpacity>
					) : (
						''
					),
			},
		];
	}
	private editUnit(item: LocationTableModel): void {
		if (item.isBuilding) {
			const building = this.props.buildings.find(building => building.id === item.buildingId);
			this.setState({
				modalIsOpen: true,
				building,
				section: undefined,
				modalSelection: 'unitsOnProcessEquipment',
			});
		} else {
			const section = this.props.sections.find(section => section.id === item.sectionId);
			this.setState({
				modalIsOpen: true,
				building: undefined,
				section,
				modalSelection: 'unitsOnProcessEquipment',
			});
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationOverview);
