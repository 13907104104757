import { Action } from 'redux';
import {
	GrowthPigDepartureEventDto,
	GrowthPigDepartureFromPenEventDto,
	GrowthPigEntranceEventDto,
	GrowthPigEventDistribution,
	GrowthPigEventDto,
	GrowthPigEventType,
	GrowthPigMoveEventDto,
	GrowthPigsEvent,
	IGrowthPigEventDto,
	IGrowthPigsEvent,
} from 'shared/api/api';
import {
	mergeArrays,
	mergeArraysNoUnique,
	remove,
	removeMultipleValuesFromArray,
	removeMultipleValuesFromArrayNoUnique,
	removeValueFromArray,
	upsertValueInArray,
} from 'shared/helpers/reducer-helpers';
import { SyncableInitialState } from 'shared/state/models/syncable';
import { isType } from 'typescript-fsa';
import { GrowthPigEventState, GrowthPigsEventUpdatesType } from '.';
import { siteChange } from '../profile/actions';
import {
	GetEventTypesGrowthPigEventsByDates,
	GetGrowthPigEventsByDates,
	GetGrowthPigEventsByDatesDashboard,
	GetGrowthPigEventsRegulationsByDates,
	getGrowthPigsEventsBySiteId,
	InitialSaveGrowthPigEvent,
	setGpeDepartureCount,
	upsertDepartureEvent,
	upsertDepartureFromPenEvent,
	upsertEntranceEvent,
	upsertGrowthPigsEvent,
	upsertGrowthPigsPigletMoveEventsAndUpdateEvents,
	upsertMoveEvent,
} from './actions';
import { GpeDepartureCount } from './types';
import { convertGpeToGrowthPigEventDistribution } from 'shared/helpers/growth-pigs-helper/growth-pig-event-function-helper';

export const initialGrowthPigEventState: GrowthPigEventState = {
	...SyncableInitialState,
	growthPigEvents: [],
	growthPigEventsUpdates: [],
	gpeDepartureCount: {} as GpeDepartureCount,
	dailyLastSync: new Date(-8640000000000000),
};

const growthPigEventsReducer = (
	state: GrowthPigEventState = initialGrowthPigEventState,
	action: Action,
): GrowthPigEventState => {
	if (isType(action, siteChange.done)) {
		state = initialGrowthPigEventState;
	}

	if (isType(action, getGrowthPigsEventsBySiteId.started)) {
		state = { ...state, syncInProgress: true };
	}

	if (isType(action, getGrowthPigsEventsBySiteId.done)) {
		if (action.payload.params.siteId === action.payload.params.activeSiteId) {
			let resultData: IGrowthPigEventDto[] = [];
			let newState = [...state.entities];

			// remove all deleted growth pig evetns
			if (action.payload.result.deletedGrowthPigEvents) {
				newState.forEach(element => {
					if (element.growthPigEventDistributions && action.payload.result.deletedGrowthPigEvents) {
						element.growthPigEventDistributions = removeMultipleValuesFromArrayNoUnique(
							element.growthPigEventDistributions,
							action.payload.result.deletedGrowthPigEvents,
							e => e.growthPigEventId!,
						);
					}
				});
			}
			if (action.payload.result.datas!.length) {
				action.payload.result.datas!.forEach(element => {
					if (element.growthPigEventDistributions && element.growthPigEventDistributions.length) {
						const curr = newState.find(e => e.sectionId === element.sectionId);
						const currGrowthPigEventDistributions =
							curr && curr.growthPigEventDistributions ? curr.growthPigEventDistributions : [];
						const oldData = element.growthPigEventDistributions.find(
							e => e.growthPigEventId === 'old data',
						);

						if (oldData) {
							element.growthPigEventDistributions = mergeArraysNoUnique(
								currGrowthPigEventDistributions.filter(e => oldData.date! <= e.date!),
								element.growthPigEventDistributions,
								e => e.growthPigEventId! + !!(e.amount && e.amount > 0),
								t => false,
							);
							resultData.push(element);
						} else {
							element.growthPigEventDistributions = mergeArraysNoUnique(
								currGrowthPigEventDistributions,
								element.growthPigEventDistributions,
								e => e.growthPigEventId! + !!(e.amount && e.amount > 0),
								t => false,
							);

							resultData.push(element);
						}
					}
				});
			}
			if (resultData) {
				state = {
					...state,
					entities: mergeArraysGrowthPigEvents(newState, resultData),
				};
			}

			state = {
				...state,
				lastSyncDate: action.payload.result.syncTime!,
				dailyLastSync:
					action.payload.result.dailySync &&
						action.payload.result.dailySync > new Date('0001-01-01T00:00:00Z')
						? action.payload.result.dailySync
						: state.dailyLastSync,
				syncInProgress: false,
			};
		} else {
			state = {
				...state,
				syncInProgress: false,
			};
		}
	}
	if (isType(action, InitialSaveGrowthPigEvent.started)) {
		const dataToAdd = action.payload;
		const stateToModify = { ...state };
		const entitiesToModify = [...stateToModify.entities];
		dataToAdd.forEach(gpe => {
			// Handles departures
			if (gpe.fromSectionId && gpe.fromPenId) {
				const indexToModify = entitiesToModify.findIndex(a => a.sectionId === gpe.fromSectionId);
				if (indexToModify < 0) {
					entitiesToModify.push(
						new GrowthPigEventDto({
							productionType: gpe.fromProductionType,
							isDeleted: false,
							sectionId: gpe.fromSectionId,
							hasQuarantine: false,
							growthPigEventDistributions: [getGrowthPigEventDistribution(gpe, true)],
						}),
					);
				} else {
					if (entitiesToModify[indexToModify].growthPigEventDistributions) {
						const gped = { ...entitiesToModify[indexToModify] };
						const gpeds = [...gped.growthPigEventDistributions!];
						const index = gpeds.findIndex(e => e.growthPigEventId === gpe.id && gpe.fromPenId === e.penId);
						if (index > -1) {
							gpeds[index] = getGrowthPigEventDistribution(gpe, true);
						} else {
							gpeds.push(getGrowthPigEventDistribution(gpe, true));
						}
						gped.growthPigEventDistributions = gpeds;
						entitiesToModify[indexToModify] = gped;
					}
				}
			}
			// Handles entries
			if (gpe.toSectionId && gpe.toPenId) {
				const indexToModify = entitiesToModify.findIndex(a => a.sectionId === gpe.toSectionId);
				if (indexToModify < 0) {
					entitiesToModify.push(
						new GrowthPigEventDto({
							productionType: gpe.toProductionType,
							isDeleted: false,
							sectionId: gpe.toSectionId,
							hasQuarantine: false,
							growthPigEventDistributions: [getGrowthPigEventDistribution(gpe)],
						}),
					);
				} else {
					if (entitiesToModify[indexToModify].growthPigEventDistributions) {
						const gped = { ...entitiesToModify[indexToModify] };
						const gpeds = [...gped.growthPigEventDistributions!];
						const index = gpeds.findIndex(e => e.growthPigEventId === gpe.id && gpe.toPenId === e.penId);
						if (index > -1) {
							gpeds[index] = getGrowthPigEventDistribution(gpe);
						} else {
							gpeds.push(getGrowthPigEventDistribution(gpe));
						}

						gped.growthPigEventDistributions = gpeds;
						entitiesToModify[indexToModify] = gped;
					}
				}
			}
		});
		stateToModify.entities = entitiesToModify;
		return stateToModify;
	}

	if (isType(action, getGrowthPigsEventsBySiteId.failed)) {
		state = { ...state, syncInProgress: false };
	}

	if (isType(action, upsertGrowthPigsPigletMoveEventsAndUpdateEvents.started)) {
		const entitiesToUpdate: GrowthPigsEvent[] = [];
		if (action.payload.growthPigEntranceEventDto && action.payload.growthPigsEvents) {
			action.payload.growthPigEntranceEventDto.forEach((gpe: GrowthPigsEventUpdatesType) =>
				entitiesToUpdate.push(GrowthPigsEvent.fromJS(gpe as IGrowthPigsEvent)),
			);
			const copyOfData = [
				...state.growthPigEvents.filter(
					gpe =>
						action.payload.growthPigsEvents!.find(gpeToDelete => gpeToDelete.id === gpe.id) === undefined,
				),
			];
			const growthPigEventsUpdates = mergeArrays(
				state.growthPigEventsUpdates,
				action.payload.growthPigEntranceEventDto,
				true,
			);
			return { ...state, growthPigEventsUpdates, growthPigEvents: mergeArrays(copyOfData, entitiesToUpdate) };
		}
	}
	if (isType(action, upsertMoveEvent.started)) {
		let entities = [...state.entities];

		// const bundleGroup = action.payload.groupBy('bundleIdentifier');
		// bundleGroup.forEach((bundleGpes: GrowthPigMoveEventDto[]) => {
		// 	UpsertStateEntitiesLocal(entities, bundleGpes);
		// });

		const updatedEntities = entities.filter(e => e && !e.isDeleted);

		const entitiesToUpdate: GrowthPigsEvent[] = [];
		action.payload.forEach((gpe: GrowthPigsEventUpdatesType) =>
			entitiesToUpdate.push(GrowthPigsEvent.fromJS(gpe as IGrowthPigsEvent)),
		);
		const copyOfData = [...state.growthPigEvents];
		const growthPigEventsUpdates = mergeArrays(state.growthPigEventsUpdates, action.payload, true);

		return {
			...state,
			growthPigEventsUpdates,

			growthPigEvents: mergeArrays(copyOfData, entitiesToUpdate),
		};
	}

	if (
		isType(action, upsertEntranceEvent.started) ||
		isType(action, upsertDepartureEvent.started) ||
		isType(action, upsertDepartureFromPenEvent.started)
	) {
		const entitiesToUpdate: GrowthPigsEvent[] = [];
		action.payload.forEach((gpe: GrowthPigsEventUpdatesType) =>
			entitiesToUpdate.push(GrowthPigsEvent.fromJS(gpe as IGrowthPigsEvent)),
		);
		const copyOfData = [...state.growthPigEvents];
		const growthPigEventsUpdates = mergeArrays(state.growthPigEventsUpdates, action.payload, true);
		return { ...state, growthPigEventsUpdates, growthPigEvents: mergeArrays(copyOfData, entitiesToUpdate) };
	}

	if (
		isType(action, upsertEntranceEvent.done) ||
		isType(action, upsertDepartureEvent.done) ||
		isType(action, upsertDepartureFromPenEvent.done)
	) {
		const stateToUpdate = { ...state };
		let entities = [...state.entities];

		const result = action.payload.result;
		UpsertStateEntities(entities, result as GrowthPigEventDto);
		if (!result) {
			const fromSection = action.payload.params.length > 0 ? action.payload.params[0] : undefined;
			const idToRemove =
				fromSection instanceof GrowthPigDepartureEventDto ||
					fromSection instanceof GrowthPigDepartureFromPenEventDto
					? fromSection.fromSectionId
					: undefined;
			if (idToRemove) {
				entities = removeGrowthPigEventDtoValueFromArray(entities, idToRemove);
			}
		}
		const updatedEntities = entities.filter(e => e && !e.isDeleted);
		stateToUpdate.entities = [...updatedEntities];
		handleUpsertOfGrowthPigEvents(action, stateToUpdate);
		return stateToUpdate;
	}

	if (isType(action, upsertGrowthPigsEvent.started)) {
		const stateToUpdate = { ...state };
		const growthPigEvents = [...state.growthPigEvents];
		const entities = [...state.entities];

		const result = action.payload;
		const prevObject = growthPigEvents.find(a => a.id === result.id);
		const index = growthPigEvents.findIndex(a => a.id === result.id);
		if (index >= 0) {
			growthPigEvents[index] = result;
		} else {
			growthPigEvents.push(result);
		}
		if (!result.isDeleted) {
			// If to section changed, edit distribution.
			if (prevObject && result && result.toSectionId !== prevObject.toSectionId) {
				const oldEntityIndex = entities.findIndex(e => e.sectionId === getCorrectSectionIdToFind(prevObject));
				if (oldEntityIndex >= 0) {
					const entityToObject = { ...entities[oldEntityIndex] };
					if (entityToObject.growthPigEventDistributions) {
						entityToObject.growthPigEventDistributions = [
							...entityToObject.growthPigEventDistributions,
						].filter(gped => gped.growthPigEventId !== prevObject.id);
						entities[oldEntityIndex] = entityToObject;
					}
				}
			}
			const entityIndexToModify = entities.findIndex(
				gped => gped.sectionId === getCorrectSectionIdToFind(action.payload),
			);

			// If object exist, modify it
			if (entityIndexToModify >= 0) {
				const entityToObject = { ...entities[entityIndexToModify] };
				if (entityToObject.growthPigEventDistributions) {
					const distributions = [...entityToObject.growthPigEventDistributions];
					const distributionIndex = distributions.findIndex(
						gped => gped.growthPigEventId === action.payload.id,
					);

					if (distributionIndex >= 0) {
						distributions[distributionIndex] = getGrowthPigEventDistribution(action.payload);
					} else {
						distributions.push(getGrowthPigEventDistribution(action.payload));
					}

					entityToObject.growthPigEventDistributions = distributions;
					entities[entityIndexToModify] = entityToObject;
				}
			} else {
				entities.push({
					hasQuarantine: false,
					sectionId: getCorrectSectionIdToFind(action.payload),
					isDeleted: false,
					productionType: getCorrectProductionTypeToFind(action.payload),
					growthPigEventDistributions: [getGrowthPigEventDistribution(action.payload)],
				});
			}
		} else {
			// Remove dsitrition if IsDeleted = True
			const fromSectionIndex = entities.findIndex(e => e.sectionId === result.fromSectionId);
			const fromEntity = { ...entities[fromSectionIndex] };
			if (fromEntity.growthPigEventDistributions) {
				fromEntity.growthPigEventDistributions = [...fromEntity.growthPigEventDistributions].filter(
					gped => gped.growthPigEventId !== result.id,
				);
				entities[fromSectionIndex] = fromEntity;
			}

			const toSectionIndex = entities.findIndex(e => e.sectionId === result.toSectionId);
			const toEntity = { ...entities[toSectionIndex] };
			if (toEntity.growthPigEventDistributions) {
				toEntity.growthPigEventDistributions = [...toEntity.growthPigEventDistributions].filter(
					gped => gped.growthPigEventId !== result.id,
				);
				entities[toSectionIndex] = toEntity;
			}
		}

		const updatedEntities = growthPigEvents.filter(e => e && !e.isDeleted);
		stateToUpdate.entities = entities;
		stateToUpdate.growthPigEvents = [...updatedEntities];
		stateToUpdate.growthPigEventsUpdates = upsertValueInArray(stateToUpdate.growthPigEventsUpdates, action.payload);
		return stateToUpdate;
	}

	if (isType(action, upsertMoveEvent.failed)) {
		if (action.payload.error.code === 500) {
			// if (action.payload.error.prevEntity) {
			// 	state = { ...state, entities: upsertValueInArray(state.entities, action.payload.error.prevEntity) };
			// } else {
			// 	state = { ...state, entities: removeValueFromArray(state.entities, action.payload.params.id!) };
			// }
			const idsToRemove: string[] = [];
			action.payload.params.forEach((a: GrowthPigsEventUpdatesType) => {
				if (a.id) {
					idsToRemove.push(a.id);
				}
			});
			const firstItem = action.payload.params.length > 0 ? action.payload.params[0] : undefined;
			let entities = [...state.entities];
			const entityFromIndexToModify = entities.findIndex(
				e => firstItem && e && e.sectionId === firstItem.fromSectionId,
			);
			const entityToIndexToModify = entities.findIndex(
				e => firstItem && e && e.sectionId === firstItem.fromSectionId,
			);
			let entityFrom = { ...entities[entityFromIndexToModify] };
			let entityTo = { ...entities[entityToIndexToModify] };

			const fromDis = entityFrom.growthPigEventDistributions;
			const from = removeMultipleValuesFromArrayNoUnique(
				fromDis ? fromDis : [],
				idsToRemove,
				e => e.growthPigEventId!,
			);

			const toDis = entityTo.growthPigEventDistributions;
			const to = removeMultipleValuesFromArrayNoUnique(toDis ? toDis : [], idsToRemove, e => e.growthPigEventId!);
			entityFrom.growthPigEventDistributions = from;
			entityTo.growthPigEventDistributions = to;

			entities[entityFromIndexToModify] = entityFrom;
			entities[entityToIndexToModify] = entityTo;
			state = {
				...state,
				growthPigEventsUpdates: removeMultipleValuesFromArray(state.growthPigEventsUpdates, idsToRemove),
				entities: entities,
			};
		}
	}

	if (isType(action, upsertMoveEvent.done)) {
		// const fromSection = action.payload.params.length > 0 ? action.payload.params[0] : undefined;
		// const stateToUpdate = handleUpdateMoveDto(state, action, fromSection ? fromSection.fromSectionId : undefined);
		let stateToUpdate = { ...state };
		handleUpsertOfGrowthPigEvents(action, stateToUpdate);
		return stateToUpdate;
	}

	if (isType(action, upsertGrowthPigsEvent.done)) {
		if (action.payload.params && action.payload.params.id) {
			return {
				...state,
				growthPigEventsUpdates: removeValueFromArray(state.growthPigEventsUpdates, action.payload.params.id),
			};
		}

		return state;
	}
	if (isType(action, GetGrowthPigEventsByDates.done)) {
		const stateToUpdate = { ...state };
		stateToUpdate.growthPigEvents = action.payload.result;
		return stateToUpdate;
	}

	if (isType(action, GetGrowthPigEventsRegulationsByDates.done)) {
		const stateToUpdate = { ...state };
		stateToUpdate.growthPigEvents = stateToUpdate.growthPigEvents
			.filter(
				gpe =>
					gpe.growthPigEventType !== GrowthPigEventType.Regulation &&
					gpe.growthPigEventType !== GrowthPigEventType.RegulationWeight,
			)
			.concat(action.payload.result);
		return stateToUpdate;
	}

	if (isType(action, GetGrowthPigEventsByDatesDashboard.done)) {
		const stateToUpdate = { ...state };
		stateToUpdate.growthPigEvents = mergeArrays(stateToUpdate.growthPigEvents, action.payload.result);
		return stateToUpdate;
	}

	if (isType(action, GetEventTypesGrowthPigEventsByDates.done)) {
		const stateToUpdate = { ...state };
		stateToUpdate.growthPigEvents = mergeArrays(stateToUpdate.growthPigEvents, action.payload.result);
		return stateToUpdate;
	}

	if (isType(action, setGpeDepartureCount)) {
		state = { ...state, gpeDepartureCount: action.payload };
	}

	if (action.type === 'persist/REHYDRATE') {
		let a = (action as any) as { payload: GrowthPigEventState; key: string };

		if (a.key === 'growthPigEvents') {
			let entities = new Array<GrowthPigEventDto>();
			let updates = new Array<GrowthPigEventDto>();
			let growthPigEvents = new Array<GrowthPigsEvent>();
			let growthPigEventsUpdates = new Array<GrowthPigsEventUpdatesType>();
			let lastSyncDate = new Date(-8640000000000000);
			let dailyLastSync = new Date(-8640000000000000);

			if (a.payload && a.payload.growthPigEvents && a.payload.entities) {
				entities = a.payload.entities.map((t: IGrowthPigEventDto) => {
					let nt = new GrowthPigEventDto();
					nt.init(t);
					return nt;
				});
			}

			if (a.payload && a.payload && a.payload.updates) {
				updates = a.payload.entities.map((t: IGrowthPigEventDto) => {
					let nt = new GrowthPigEventDto();
					nt.init(t);
					return nt;
				});
			}

			if (a.payload && a.payload.growthPigEvents && a.payload.growthPigEvents) {
				growthPigEvents = a.payload.growthPigEvents.map((t: IGrowthPigsEvent) => {
					let nt = GrowthPigsEvent.fromJS({});
					nt.init(t);
					return nt;
				});
			}

			if (a.payload && a.payload.growthPigEvents && a.payload.growthPigEventsUpdates) {
				const updates = a.payload.growthPigEventsUpdates;

				const updatesFilteredToEntrance = updates.filter(
					gpe => gpe.growthPigEventType === GrowthPigEventType.GrowthPigEntranceEvent,
				);
				const updatesFilteredToDeparture = updates.filter(
					gpe => gpe.growthPigEventType === GrowthPigEventType.GrowthPigDepartureEvent,
				);
				const updatesFilteredToDepartureFromPen = updates.filter(
					gpe => gpe.growthPigEventType === GrowthPigEventType.GrowthPigPenDepartureEvent,
				);
				const updatesFilteredToMovePiglet = updates.filter(
					gpe => gpe.growthPigEventType === GrowthPigEventType.PigletMovedEvent,
				);
				const updatesFilteredToSold = updates.filter(
					gpe => gpe.growthPigEventType === GrowthPigEventType.PigletDepartureSoldEvent,
				);

				const updatesGrowthPigMoveEvent = updates.filter(
					gpe => gpe.growthPigEventType === GrowthPigEventType.GrowthPigMoveEvent,
				);

				const updatesRegulation = updates.filter(
					gpe =>
						gpe.growthPigEventType === GrowthPigEventType.Regulation ||
						gpe.growthPigEventType === GrowthPigEventType.RegulationWeight,
				);

				growthPigEventsUpdates = growthPigEventsUpdates.concat(
					updatesFilteredToEntrance.map(t => {
						let ndt = GrowthPigEntranceEventDto.fromJS(t);
						ndt.init(t);
						return t;
					}),
				);

				growthPigEventsUpdates = growthPigEventsUpdates.concat(
					updatesFilteredToDeparture.map(t => {
						let ndt = GrowthPigDepartureEventDto.fromJS(t);
						ndt.init(t);
						return t;
					}),
				);

				growthPigEventsUpdates = growthPigEventsUpdates.concat(
					updatesFilteredToDepartureFromPen.map(t => {
						let ndt = GrowthPigDepartureFromPenEventDto.fromJS(t);
						ndt.init(t);
						return t;
					}),
				);

				growthPigEventsUpdates = growthPigEventsUpdates.concat(
					updatesFilteredToMovePiglet.map(t => {
						let ndt = GrowthPigMoveEventDto.fromJS(t);
						ndt.init(t);
						return t;
					}),
				);

				growthPigEventsUpdates = growthPigEventsUpdates.concat(
					updatesFilteredToSold.map(t => {
						let ndt = GrowthPigsEvent.fromJS(t);
						ndt.init(t);
						return t;
					}),
				);

				growthPigEventsUpdates = growthPigEventsUpdates.concat(
					updatesRegulation.map(t => {
						let ndt = GrowthPigsEvent.fromJS(t);
						ndt.init(t);
						return t;
					}),
				);

				growthPigEventsUpdates = growthPigEventsUpdates.concat(
					updatesGrowthPigMoveEvent.map(t => {
						let ndt = GrowthPigMoveEventDto.fromJS(t);
						ndt.init(t);
						return t;
					}),
				);
			}

			if (a.payload && a.payload.growthPigEvents && a.payload.lastSyncDate) {
				lastSyncDate = new Date(a.payload.lastSyncDate);
			}

			if (a.payload && a.payload.growthPigEvents && a.payload.dailyLastSync) {
				dailyLastSync = new Date(a.payload.dailyLastSync);
			}

			state = { ...state, growthPigEvents, growthPigEventsUpdates, lastSyncDate, entities, dailyLastSync };
		}
	}

	return state;
};

function getGrowthPigEventDistribution(gpe: IGrowthPigsEvent, isDepart?: boolean): GrowthPigEventDistribution {
	return gpe.growthPigEventType === GrowthPigEventType.GrowthPigDepartureEvent ||
		gpe.growthPigEventType === GrowthPigEventType.GrowthPigPenDepartureEvent ||
		(GrowthPigEventType.GrowthPigMoveEvent && isDepart)
		? ({
			date: gpe.date,
			amount: -(gpe.amount ? gpe.amount : 0),
			totalWeight: -(gpe.oldTotalWeight ? gpe.oldTotalWeight : 0),
			totalWeightCurve: -(gpe.totalWeight ? gpe.totalWeight : 0),
			growthPigEventId: gpe.id,
			penId: gpe.fromPenId,
			productionType: gpe.fromProductionType,
			growthPigEventType: gpe.growthPigEventType,
			isSameProduction: gpe.fromProductionType === gpe.toProductionType,
		} as GrowthPigEventDistribution)
		: ({
			date: gpe.date,
			amount: gpe.amount ? gpe.amount : 0,
			totalWeight:
				gpe.growthPigEventType === GrowthPigEventType.GrowthPigEntranceEvent
					? gpe.totalWeight
						? gpe.totalWeight
						: 0
					: gpe.oldTotalWeight
						? gpe.oldTotalWeight
						: 0,
			totalWeightCurve: gpe.totalWeight ? gpe.totalWeight : 0,
			growthPigEventId: gpe.id,
			penId: gpe.toPenId,
			productionType: gpe.toProductionType,
			growthPigEventType: gpe.growthPigEventType,
			isSameProduction: gpe.fromProductionType === gpe.toProductionType,
		} as GrowthPigEventDistribution);
}

function getCorrectSectionIdToFind(gpe: IGrowthPigsEvent) {
	return gpe.growthPigEventType === GrowthPigEventType.GrowthPigDepartureEvent ||
		gpe.growthPigEventType === GrowthPigEventType.GrowthPigPenDepartureEvent
		? gpe.fromSectionId
		: gpe.toSectionId;
}

function getCorrectProductionTypeToFind(gpe: IGrowthPigsEvent) {
	return gpe.growthPigEventType === GrowthPigEventType.GrowthPigDepartureEvent ||
		gpe.growthPigEventType === GrowthPigEventType.GrowthPigPenDepartureEvent
		? gpe.fromProductionType
		: gpe.toProductionType;
}

function handleUpsertOfGrowthPigEvents(
	action,
	stateToUpdate: {
		growthPigEvents: GrowthPigsEvent[];
		growthPigEventsUpdates: GrowthPigsEventUpdatesType[];
		entities: IGrowthPigEventDto[];
		updates: IGrowthPigEventDto[];
		lastSyncDate: Date;
		syncInProgress: boolean;
		saveSyncInProgress: boolean;
	},
) {
	const idsToRemove: string[] = [];
	action.payload.params.forEach((a: GrowthPigsEventUpdatesType) => {
		if (a.id) {
			idsToRemove.push(a.id);
		}
	});
	stateToUpdate.growthPigEventsUpdates = removeMultipleValuesFromArray(
		stateToUpdate.growthPigEventsUpdates,
		idsToRemove,
	);
}

function removeGrowthPigEventDtoValueFromArray<T extends IGrowthPigEventDto>(arr: T[], id: string): T[] {
	const index = arr.findIndex(x => x.sectionId === id);
	return remove(arr, index);
}

function handleUpdateMoveDto(state: GrowthPigEventState, action, fromSectionId?: string, toSectionId?: string) {
	const stateToUpdate = { ...state };
	let entities = [...state.entities];
	const result = action.payload.result;

	if (result.updateFrom) {
		UpsertStateEntities(entities, result.updateFrom);
	} else if (fromSectionId) {
		entities = removeGrowthPigEventDtoValueFromArray(entities, fromSectionId);
	}

	if (result.updateTo) {
		UpsertStateEntities(entities, result.updateTo);
	} else if (toSectionId) {
		entities = removeGrowthPigEventDtoValueFromArray(entities, toSectionId);
	}
	const updatedEntities = entities.filter(e => e && !e.isDeleted);
	stateToUpdate.entities = [...updatedEntities];
	return stateToUpdate;
}

function UpsertStateEntities(entities: IGrowthPigEventDto[], result: GrowthPigEventDto) {
	const entityIndexToModify = entities.findIndex(
		e => e && result && e.productionType === result.productionType && e.sectionId === result.sectionId,
	);
	if (entityIndexToModify < 0) {
		entities.push(result);
	} else if (result.growthPigEventDistributions !== undefined) {
		const copyOfEntity = { ...entities[entityIndexToModify] };
		copyOfEntity.growthPigEventDistributions = [...result.growthPigEventDistributions];
		copyOfEntity.hasQuarantine = result.hasQuarantine;
		copyOfEntity.isDeleted = result.isDeleted;
		entities[entityIndexToModify] = copyOfEntity;
	}
}

function UpsertStateEntitiesLocal(entities: IGrowthPigEventDto[], params: GrowthPigMoveEventDto[]) {
	const firstItem = params.length > 0 ? params[0] : undefined;

	const entityFromIndexToModify = entities.findIndex(
		e =>
			firstItem &&
			e &&
			e.productionType === firstItem.fromProductionType &&
			e.sectionId === firstItem.fromSectionId,
	);

	const entityToIndexToModify = entities.findIndex(
		e => firstItem && e && e.productionType === firstItem.toProductionType && e.sectionId === firstItem.toSectionId,
	);

	if (entityFromIndexToModify > -1) {
		const copyOfEntity = { ...entities[entityFromIndexToModify] };
		const copyDistribution = copyOfEntity.growthPigEventDistributions
			? copyOfEntity.growthPigEventDistributions
			: [];
		const dtos = params.map(move => convertGpeToGrowthPigEventDistribution(move, true));
		const distribution = mergeArraysNoUnique(
			copyDistribution,
			dtos,
			m => m.growthPigEventId!,
			t => false,
		);
		copyOfEntity.growthPigEventDistributions = distribution;
		entities[entityFromIndexToModify] = copyOfEntity;
	} else {
		const dtos = params.map(move => convertGpeToGrowthPigEventDistribution(move, true));
		entities.push({
			hasQuarantine: false,
			sectionId: firstItem && firstItem.fromSectionId,
			productionType: firstItem && firstItem.fromProductionType,
			growthPigEventDistributions: dtos,
			isDeleted: false,
		} as IGrowthPigEventDto);
	}

	if (entityToIndexToModify > -1) {
		const copyOfEntity = { ...entities[entityToIndexToModify] };
		const copyDistribution = copyOfEntity.growthPigEventDistributions
			? copyOfEntity.growthPigEventDistributions
			: [];
		const dtos = params.map(move => convertGpeToGrowthPigEventDistribution(move, false));
		const distribution = mergeArraysNoUnique(
			copyDistribution,
			dtos,
			m => m.growthPigEventId!,
			t => false,
		);
		copyOfEntity.growthPigEventDistributions = distribution;
		entities[entityToIndexToModify] = copyOfEntity;
	} else {
		const dtos = params.map(move => convertGpeToGrowthPigEventDistribution(move, false));
		entities.push({
			hasQuarantine: false,
			sectionId: firstItem && firstItem.toSectionId,
			productionType: firstItem && firstItem.toProductionType,
			growthPigEventDistributions: dtos,
			isDeleted: false,
		} as IGrowthPigEventDto);
	}
}

function mergeArraysGrowthPigEvents<T extends IGrowthPigEventDto>(oldArray: T[], updatedArray: T[]) {
	if (!updatedArray || updatedArray.length === 0) {
		return oldArray;
	}

	if (!oldArray || oldArray.length === 0) {
		return updatedArray;
	}

	let clonedOld = [...oldArray];
	updatedArray.forEach(updated => {
		const index = clonedOld.findIndex(
			old => old.productionType === updated.productionType && old.sectionId === updated.sectionId,
		);
		merge<T>(index, clonedOld, updated);
	});

	return clonedOld.filter(elem => !elem.isDeleted);
}

function merge<T extends IGrowthPigEventDto>(index: number, clonedOld: T[], updated: T) {
	if (index > -1) {
		if (updated.isDeleted) {
			clonedOld.splice(index, 1);
		} else {
			clonedOld[index] = updated;
		}
	} else {
		if (!updated.isDeleted) {
			clonedOld.push(updated);
		}
	}
}

export default growthPigEventsReducer;
