import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IReason, IReasonDto, Reason, ReasonDto } from 'shared/api/api';
import PenIcon from 'shared/assets/src-assets/png/pen_icon.png';
import { GetSyncData as ReasonsGetSyncData } from 'shared/state/ducks/reasons/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import PageContainer from 'web/view/components/page-container/page-container';
import ReasonModal from 'web/view/components/reasons/reason-modal';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldModal } from 'web/view/components/skiold-components/skiold-modal/skiold-modal';
import { SkioldTable } from 'web/view/components/skiold-components/skiold-table/skiold-table';
import { SkioldTouchableOpacity } from 'web/view/components/skiold-components/skiold-touchable-opacity';
import { Heading } from 'web/view/components/utils/heading';
import { SkioldImage } from 'web/view/components/utils/svg/skiold-image';
import { ViewWeb } from 'web/view/components/utils/web-view';
import './reasons.scss';

const mapStateToProps = (state: WebAppState) => {
	return {
		reasons: state.reasons.entities,
		siteId: state.profile.active!.siteId,
		profile: state.profile.active,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
	return {
		getReasons: () => ReasonsGetSyncData()(dispatch),
	};
};

export interface ReasonsState {
	showModal: boolean;
	reasonDto: IReasonDto;
}

type ReasonsProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
class Reasons extends React.PureComponent<ReasonsProps, ReasonsState> {
	constructor(props: ReasonsProps) {
		super(props);

		this.state = {
			showModal: false,
			reasonDto: new ReasonDto({ reason: Reason.fromJS({}) }),
		};

		this.props.getReasons();
	}

	public render() {
		const columns = this.generateColumns();

		return (
			<PageContainer className="reasons">
				<Heading text={localized('reasons')} />

				<ViewWeb className="reason-table-container">
					<SkioldTable columns={columns} data={this.props.reasons} sortHeaderId={'Code'} />
				</ViewWeb>

				<ViewWeb className="reason-button-container">
					<SkioldButton
						onPress={() =>
							this.setState(
								{
									...this.state,
									reasonDto: new ReasonDto({ reason: Reason.fromJS({ priorityCode: 0 } as IReason) }),
								},
								this.showModal,
							)
						}
						title={localized('createReason')}
					/>
				</ViewWeb>

				<SkioldModal isOpen={this.state.showModal} close={() => this.setState({ showModal: false })}>
					<ReasonModal
						reasonDto={this.state.reasonDto}
						close={() => this.setState({ ...this.state, showModal: false })}
					/>
				</SkioldModal>
			</PageContainer>
		);
	}

	private showModal() {
		this.setState({ showModal: true });
	}

	private generateColumns() {
		return [
			{
				id: 'name',
				Header: localized('reasonName'),
				accessor: (d: IReasonDto) =>
					d.reason !== undefined && d.reason.name
						? d.reason.name[
								this.props.profile && this.props.profile.language ? this.props.profile.language : 'da'
						  ]
						: '',
			},
			{
				id: 'Code',
				Header: localized('reasonCode'),
				accessor: (d: IReasonDto) => (d.reason !== undefined ? d.reason.priorityCode : undefined),
			},
			{
				id: 'update',
				Header: '',
				accessor: (d: IReasonDto) => {
					return (
						<SkioldTouchableOpacity
							onPress={() => {
								this.setState({ reasonDto: d }, this.showModal);
							}}
						>
							<SkioldImage width="30" height="30" imageData={PenIcon} />
						</SkioldTouchableOpacity>
					);
				},
			},
		];
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Reasons);
