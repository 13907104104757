import { IUnitToPen, UnitToPen } from 'shared/api/api';
import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import { StoreContainer } from 'shared/state/store-container';
import * as Action from './actions';

export function GetSyncData() {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;
	const lastSyncDate = state.unitToPenData.lastSyncDate;
	return AsyncOperationBuilder2(
		Action.getSyncData,
		client => client.processEquipmentLocationRelations_GetUnitToPenDataBySiteId(siteId, lastSyncDate),
		{ siteId },
	);
}

export function SaveUnitToPenLocationRelation(item: IUnitToPen) {
	return AsyncOperationBuilder2(
		Action.saveData,
		client => client.processEquipmentLocationRelations_Upsert3(item as UnitToPen),
		item,
	);
}
