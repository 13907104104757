import React from 'react';
import { connect } from 'react-redux';
import { RefType } from 'shared/helpers/ref-type';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';

const mapStateToProps = (state: WebAppState) => {
	return {
		dashboardLitterData:
			state.dashboardOverview.data && state.dashboardOverview.data.allCurrentLitters
				? state.dashboardOverview.data.allCurrentLitters
				: {},
		loading: state.dashboardOverview.loading,
	};
};

type Props = ReturnType<typeof mapStateToProps>;

export class DashBoardOverviewLitterCounter extends React.PureComponent<Props> {
	public render() {
		return (
			!this.props.loading && (
				<ViewWeb className="flexDirectionRow">
					<TextWeb className="yellowText bold paddingRightFive">{localized('ageByFold')}:</TextWeb>
					{this.mapDashboardLitterData()}
				</ViewWeb>
			)
		);
	}

	private mapDashboardLitterData = () => {
		const litterData: JSX.Element[] = [];
		let overTen: number = 0;
		Object.keys(this.props.dashboardLitterData).forEach((litter: string) => {
			overTen = this.AddLitterCounterItems(litter, litterData, overTen);
		});
		if (overTen > 0) {
			litterData.push(this.GetLitterCounterItem(localized('overTen'), overTen));
		}
		return litterData;
	};

	private AddLitterCounterItems(litter: string, litterData: JSX.Element[], overTen: number) {
		const lit = Number(litter);
		if (lit < 10) {
			litterData.push(this.GetLitterCounterItem(litter, this.props.dashboardLitterData[litter]));
		} else if (lit >= 10) {
			const numberToAdd = this.props.dashboardLitterData[litter];
			if (!Number.isNaN(numberToAdd)) {
				overTen += numberToAdd;
			}
		}
		return overTen;
	}

	private GetLitterCounterItem(litterString: string, count) {
		return (
			<ViewWeb key={litterString} className="flexDirectionRow paddingLeftTwenty">
				<TextWeb className="yellowText bold paddingRightFive">{litterString}:</TextWeb>
				<TextWeb className="yellowText bold">{count}</TextWeb>
			</ViewWeb>
		);
	}
}

export default connect<ReturnType<typeof mapStateToProps>, null, RefType, WebAppState>(mapStateToProps, null, null, {
	forwardRef: true,
})(DashBoardOverviewLitterCounter);
