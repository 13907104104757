import React from 'react';
import { Option } from 'react-dropdown';
import { connect } from 'react-redux';
import {
	AnimalKind,
	DeadPigletsEvent,
	Gender,
	IDeadPigletsEvent,
	IStemAnimal,
	PregnancyState,
	StemAnimal,
} from 'shared/api/api';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { getLastPregnancyEventByDate } from 'shared/helpers/pregnancy-helper/generel-pregnancy-helpers';
import {
	DefaultReasonOption,
	getReasonOptions,
	getReasonOptionsByDepartureType,
} from 'shared/helpers/reason-helper/reason-helper';
import {
	deadPigletsEventMapDispatchToProps,
	deadPigletsEventMapStateToProps,
	RegisterDeadPigletsEventProps,
	RegisterDeadPigletsEventState,
	validateDeadPigletsEvent,
} from 'shared/helpers/stemanimal-helper/dead-piglets-event-helper';
import { localized } from 'shared/state/i18n/i18n';
import { DepartureTypes } from 'shared/state/models/departure-types';
import { showAlert } from 'web/view/components/skiold-alert/skiold-alert';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldDatePicker } from 'web/view/components/skiold-components/skiold-date-picker/skiold-date-picker';
import { SkioldFormDropdown } from 'web/view/components/skiold-components/skiold-dropdown/skiold-form-dropdown';
import { SkioldFormsWrapper } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { SkioldFormIntegerInput } from 'web/view/components/skiold-components/skiold-integer-input/skiold-form-integer-input';
import { SkioldTouchableOpacity } from 'web/view/components/skiold-components/skiold-touchable-opacity';
import { SkioldImage } from 'web/view/components/utils/svg/skiold-image';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import StemAnimalInput from '../../stem-animal-input/stem-animal-input';
import './register-dead-piglets-event.scss';
import ForwardArrowGrey from 'shared/assets/src-assets/png/forward_arrow_bottom_grey.png';
import { SkioldModal } from 'web/view/components/skiold-components/skiold-modal/skiold-modal';
import SearchYoungAnimals from '../search-animals/search-young-animals';
import { DefaultDropdownOption } from 'shared/helpers/general-helpers';
import { getYoungAnimals } from 'shared/helpers/stemanimal-helper/stem-animal-input-helper';

export const DepartureTypeOptions = () => [
	DefaultDropdownOption,
	{
		label: localized(DepartureTypes.departureTypeDead),
		value: DepartureTypes.departureTypeDead,
	},
	{
		label: localized(DepartureTypes.departureTypePutDown),
		value: DepartureTypes.departureTypePutDown,
	},
];

export class RegisterDeadPigletsEvent extends React.PureComponent<
	RegisterDeadPigletsEventProps,
	RegisterDeadPigletsEventState
> {
	constructor(props: RegisterDeadPigletsEventProps) {
		super(props);
		this.state = {
			sow: StemAnimal.fromJS({}),
			deadPigletsEvent: DeadPigletsEvent.fromJS({ registrationDate: new Date() } as IDeadPigletsEvent),
			animalNumber: '',
			toggleFocus: false,
			reasonOption: DefaultReasonOption,
			modalIsOpen: false,
			youngAnimal: undefined,
			departureTypeOption: {
				label: localized(DepartureTypes.departureTypeDead),
				value: DepartureTypes.departureTypeDead,
			},
		};
	}

	public animalNumberChanged = (sow: IStemAnimal | undefined) => {
		if (sow) {
			this.setState({ sow });
		} else {
			this.setState({ sow: StemAnimal.fromJS({}) });
		}
	};

	public dateChanged = (newDate: Date) => {
		this.setState(prevState => ({
			deadPigletsEvent: { ...prevState.deadPigletsEvent, registrationDate: newDate },
		}));
	};

	public deadPigletsChanged = (deadPiglets: number | undefined) => {
		this.setState(prevState => ({
			deadPigletsEvent: { ...prevState.deadPigletsEvent, deadPiglets } as IDeadPigletsEvent,
		}));
	};

	public reasonChanged = (reasonOption: Option) => {
		this.setState({ reasonOption });
	};
	private departureTypeChanged = (option: Option) => {
		this.setState({ departureTypeOption: option });
	};

	public render() {
		return (
			<ViewWeb className="register-dead-piglets-event">
				{this.renderButtons()}
				<ViewWeb>
					<SkioldFormsWrapper formRows={this.getFormRows()} containerClassName="forms-wrapper-style" />
				</ViewWeb>
				{this.renderModal()}
			</ViewWeb>
		);
	}

	public save = async () => {
		let stemId = this.state.sow && this.state.sow.id;
		if (await validateDeadPigletsEvent(this.state.deadPigletsEvent, stemId, showAlert, this.state.youngAnimal)) {
			let deadPigletsEvent = { ...this.state.deadPigletsEvent };

			// If we are here, sow is Nursing, find last Farrowing
			let lastFarrowing = getLastPregnancyEventByDate(
				stemId,
				PregnancyState.Farrowing,
				this.props.pregnancies,
				deadPigletsEvent.registrationDate,
			);
			if (lastFarrowing) {
				deadPigletsEvent.pregnancyId = lastFarrowing.id;
			} else {
				showAlert(localized(ExceptionMessage.VALIDATION_ERROR_DATE_NOT_SET));
				return;
			}
			deadPigletsEvent.siteId = this.props.active.siteId;
			deadPigletsEvent.reasonId = this.state.reasonOption.value;
			deadPigletsEvent.stemAnimalId = stemId;
			deadPigletsEvent.youngAnimalId = this.state.youngAnimal?.id;
			deadPigletsEvent.departureType = this.state.departureTypeOption.value;

			if (this.state.youngAnimal) {
				let saveYoungAnimal = { ...this.state.youngAnimal };
				saveYoungAnimal.departureReasonId = this.state.reasonOption.value;
				saveYoungAnimal.departureDate = deadPigletsEvent.registrationDate;
				saveYoungAnimal.departureType = this.state.departureTypeOption.value;
				deadPigletsEvent.youngAnimalIdNumber = saveYoungAnimal.idNumber;
				this.props.youngAnimalDeparture(saveYoungAnimal);
			}

			this.props.saveDeadPigletsEvent(deadPigletsEvent);
			await this.resetComponent();
		}
	};

	private onAnimalNumberChanged = (animalNumber: string | undefined) => this.setState({ animalNumber });
	private openModal = () => {
		if (!this.state.deadPigletsEvent.deadPiglets || this.state.deadPigletsEvent.deadPiglets <= 1) {
			this.setState({ modalIsOpen: true });
		} else {
			showAlert(localized('deadPigletsGreaterThan1'));
		}
	};
	private closeModal = () => this.setState({ modalIsOpen: false });

	private setYoungAnimal = (youngAnimal: IStemAnimal | undefined) => {
		this.setState({
			modalIsOpen: false,
			youngAnimal,
		});
	};
	private getFormRows() {
		let formRows = new Array<FormRow>();
		formRows.push(this.getPregnancyAnimalNumberRow());
		formRows.push(this.getDateRow());
		formRows.push(this.getDeadPigletsRow());
		if (this.props.nucleusManagement || this.props.generalSettings.registerIdForGpeDeparture) {
			formRows.push(this.getIdNumberRow());
		}
		formRows.push(this.getDepartureTypeRow());
		formRows.push(this.getReasonsRow());

		return formRows;
	}

	private getPregnancyAnimalNumberRow(): FormRow {
		return {
			name: localized('animalNumber'),
			component: (
				<StemAnimalInput
					animalNumber={this.state.animalNumber}
					onAnimalNumberChanged={this.onAnimalNumberChanged}
					onAnimalFound={this.animalNumberChanged}
					gender={Gender.Female}
					inputType={'form'}
					autoFocus={true}
					toggleFocus={this.state.toggleFocus}
				/>
			),
		};
	}

	private getDateRow(): FormRow {
		return {
			name: localized('Date'),
			component: (
				<SkioldDatePicker
					onDateChanged={this.dateChanged}
					selectedDate={this.state.deadPigletsEvent.registrationDate!}
					theme={'dark'}
					color={'grey'}
				/>
			),
		};
	}

	private getDeadPigletsRow(): FormRow {
		return {
			name: localized('Pcs'),
			component: (
				<SkioldFormIntegerInput
					onChangeNumber={this.deadPigletsChanged}
					text={this.state.deadPigletsEvent.deadPiglets}
				/>
			),
		};
	}

	private getDepartureTypeRow(): FormRow {
		return {
			name: localized('departureType'),
			component: (
				<SkioldFormDropdown
					items={DepartureTypeOptions()}
					selectedValue={this.state.departureTypeOption}
					onValueChanged={this.departureTypeChanged}
				/>
			),
		};
	}

	private getReasonsRow(): FormRow {
		return {
			name: localized('reasons'),
			component: (
				<SkioldFormDropdown
					items={getReasonOptionsByDepartureType(
						this.props.reasons,
						AnimalKind.Piglet,
						this.state.departureTypeOption.value,
					)}
					selectedValue={this.state.reasonOption}
					onValueChanged={this.reasonChanged}
				/>
			),
		};
	}

	private getIdNumberRow(): FormRow {
		return {
			name: localized('idNumber'),
			component: (
				<SkioldTouchableOpacity className="margin-right-eight" onPress={this.openModal}>
					<ViewWeb className="display-flex-direction-row">
						<TextWeb className="padding-right-five-font-size-sixteen">
							{this.state.youngAnimal?.idNumber || localized('ChooseIdNumber')}
						</TextWeb>
						<ViewWeb className="picker-young-animals-view-style">
							<SkioldImage
								width={18}
								height={18}
								imageData={ForwardArrowGrey}
								className="image-picker-view-style"
							/>
						</ViewWeb>
					</ViewWeb>
				</SkioldTouchableOpacity>
			),
		};
	}

	private renderModal() {
		return (
			<SkioldModal isOpen={this.state.modalIsOpen} close={this.closeModal}>
				<SearchYoungAnimals close={this.setYoungAnimal} youngAnimals={getYoungAnimals()} />
			</SkioldModal>
		);
	}

	private renderButtons() {
		return (
			<ViewWeb className="button-view-style-upper-corner">
				<SkioldButton title={localized('Save')} onPress={this.save} />
			</ViewWeb>
		);
	}

	// Called after successfull save to remove one-time values
	private async resetComponent() {
		this.setState({
			sow: StemAnimal.fromJS({}),
			deadPigletsEvent: DeadPigletsEvent.fromJS({ registrationDate: new Date() } as IDeadPigletsEvent),
			animalNumber: '',
			toggleFocus: false,
			youngAnimal: undefined,
		});
	}
}

export default connect(deadPigletsEventMapStateToProps, deadPigletsEventMapDispatchToProps)(RegisterDeadPigletsEvent);
