import { Action } from 'redux';
import { mergeArrays } from 'shared/helpers/reducer-helpers';
import { isType } from 'typescript-fsa';
import { getUnits } from './actions';
import { ProcessUnitState } from './types';
import { siteChange } from '../profile/actions';

export const initialState: ProcessUnitState = {
	processUnits: [],
	processUnitsLocations: []
};

const processUnitsReducer = (state: ProcessUnitState = initialState, action: Action): ProcessUnitState => {
	if (isType(action, getUnits.done)) {
		if (action.payload.result.length) {
			state = {
				...state,
				processUnits: mergeArrays(state.processUnits, action.payload.result)
			};
		}
	}
	if (isType(action, siteChange.done)) {
		state = initialState;
	}

	return state;
};

export default processUnitsReducer;
