import actionCreatorFactory from 'typescript-fsa';
import * as types from './types';
import { GenActionFactoryName } from 'shared/helpers/redux-helpers';
import { IMatingBatchReports, DashboardTableModel } from 'shared/api/api';

const actionCreator = actionCreatorFactory(GenActionFactoryName('matingBatchReports'));

export const getMatingBatchReportsBySiteAndDate = actionCreator.async<
	{ siteId: string; activeSiteId?: string },
	IMatingBatchReports
>(types.GET_MATING_BATCH_REPORTS_BY_SITE);

export const saveMatingBatchReport = actionCreator.async<IMatingBatchReports, IMatingBatchReports>(
	types.SAVE_MATING_BATCH_REPORT
);

export const getDashboardTableModels = actionCreator.async<
	{ siteId: string; date?: Date; mode: string },
	{ [key: string] : DashboardTableModel[]; }
>(types.GET_DASHBOARD_TABLE_MODELS_FOR_MATING_BATCH_REPORTS);
