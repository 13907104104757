import React from 'react';
import { Colors } from 'shared/assets/root-assets/colors';
import './skiold-input.scss';

export interface TextPropsFromParent {
	onChangeText: (newText: string, itemFromParent?: any) => void;
	itemFromParent?: any;
	label?: string;
	text?: string;
	style?: any;
	theme?: 'light' | 'dark';
	suffix?: string;
	editable?: boolean;
	className?: string;
	multiline?: boolean;
	numberOfLines?: number;
	onBlur?: (e?: any) => void;
	selectTextOnFocus?: boolean;
	autoFocus?: boolean;
	maxLength?: number;
	id?: string;
	type?: string;
	useAsInterger?: boolean;
	placeholder?: string;
}

interface State {
	textColor: string | undefined;
	baseColor: string | undefined;
	tintColor: string | undefined;
	text?: string;
}

// Component representing a Skiold Digital textfield throughout the app/website
export class SkioldInput extends React.PureComponent<TextPropsFromParent, State> {
	public static defaultProps: Partial<TextPropsFromParent> = {
		theme: 'light',
		className: '',
		id: '',
		editable: true,
	};

	public static getDerivedStateFromProps(nextProps: TextPropsFromParent) {
		return {
			text: nextProps.text ? nextProps.text!.toString() : '',
		};
	}

	public input: any; //Any because of missing .focus on TextField typings

	constructor(props: TextPropsFromParent) {
		super(props);
		this.state = {
			textColor: undefined,
			baseColor: undefined,
			tintColor: undefined,
			text: props.text ? props.text : ' ',
		};
	}

	public componentDidMount() {
		if (this.props.theme === 'light') {
			this.setState({ baseColor: Colors.white, textColor: Colors.yellow, tintColor: Colors.yellow });
		} else if (this.props.theme === 'dark') {
			//Use default colors
		}

		if (this.props.autoFocus && this.input) {
			setTimeout(() => {
				this.focus();
			}, 200);
		}
	}

	public focus() {
		if (this.input) {
			this.input.focus();
		}
	}

	public render() {
		return (
			<input
				className={`skiold-input ${this.props.className}`}
				value={this.state.text}
				onChange={this.textChanged}
				maxLength={this.props.maxLength}
				ref={this.setRef}
				style={this.props.style}
				disabled={!this.props.editable}
				type={this.props.type ? this.props.type : 'text'}
				onBlur={this.props.onBlur}
				placeholder={this.props.placeholder}
			/>
		);
	}

	private regexToUse = /[^\d]/g;
	private setRef = (ref: any) => (this.input = ref);
	private textChanged = (event: React.FormEvent<HTMLInputElement> | undefined) => {
		const newText = event ? event.currentTarget.value : '';
		if (this.props.useAsInterger) {
			let textstring = newText.replace(this.regexToUse, '');
			this.setState({ text: textstring });
			this.props.onChangeText(textstring, this.props.itemFromParent);
		} else {
			this.setState({ text: newText });
			this.props.onChangeText(newText, this.props.itemFromParent);
		}
	};
}
