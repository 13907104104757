import React from 'react';
import {
	AnimalType,
	IGrowthPigPReportTableDatasType,
	IMatingBatch,
	IProductionReportItem,
	IProductionReportSetting,
	IUserProfile,
} from 'shared/api/api';
import PrinterGreyIcon from 'shared/assets/src-assets/png/printer_ikon_grey.png';
import { getDateString } from 'shared/helpers/date-helpers';
import { getFirstMatingBatch } from 'shared/helpers/general-helpers';
import { localized } from 'shared/state/i18n/i18n';
import { GrowthPigProductionReportTable } from 'web/view/components/growth-pigs/growth-pigs-reports/growth-pig-production-report';
import PageContainer from 'web/view/components/page-container/page-container';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldFormDatePicker } from 'web/view/components/skiold-components/skiold-date-picker/skiold-form-datepicker';
import SkioldTableGrid from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { GroupedHeader } from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid-grouped-header';
import { Heading } from 'web/view/components/utils/heading';
import { SkioldFetchIcon } from 'web/view/components/utils/skiold-fetch-icon';
import { SkioldIconSpinner } from 'web/view/components/utils/skiold-icon-spinner';
import { ViewWeb } from 'web/view/components/utils/web-view';
import {
	GetGrowthPigProductionReportPdf,
	GetProductionReport,
	GetProductionReportGrowthPigs,
	GetProductionReportPdf,
} from 'web/web-helpers/pdf-helper/production-report-helper';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { PReportFeedUsage } from './p-report-feed-usage-table';
import { PeriodTableItem, PeriodTableRow } from './p-report-period-table';
import './p-report-special-period.scss';
import { ProductionReportTable } from './production-report-table';
import { Option } from 'react-dropdown';

interface PropsFromParent {
	pReportSetting: IProductionReportSetting;
	onDataChanged: (date: Date, item: PeriodTableItem) => void;
	closeModal?: () => void;
	animalType: AnimalType;
	printPdf?: () => void;
	fetchData?: () => void;
	profile: IUserProfile;
	selectedSectionLabel?: string;
	selectedSectionId?: string;
}

interface State {
	columns: any[];
	columnExte: any[];
	pReportSetting: IProductionReportSetting;
	reportData?: IProductionReportItem | IGrowthPigPReportTableDatasType;
	earliestBatch: IMatingBatch | undefined;
	loading: boolean;
}

export class PReportSpecialPeriod extends React.PureComponent<PropsFromParent, State> {
	public static defaultProps: Partial<PropsFromParent> = {};

	constructor(props: PropsFromParent) {
		super(props);
		this.state = {
			columns: this.generateColumns(),
			columnExte: [],
			pReportSetting: props.pReportSetting,
			reportData: undefined,
			earliestBatch: getFirstMatingBatch(),
			loading: false,
		};
	}

	public componentDidMount() {
		this.fetchData();
	}

	public render() {
		const data = this.generateData(this.props.pReportSetting);
		return (
			<PageContainer>
				<Heading text={localized('specialPeriod')}></Heading>
				<ViewWeb className="p-report-special-period">
					<ViewWeb className="row-direction">
						<ViewWeb className="special-period-btn-container">
							<ViewWeb className="special-period-container">
								<SkioldTableGrid
									resizeAbleEnabled={false}
									columns={this.state.columns}
									data={data}
									ColumnExtensions={this.state.columnExte}
									sortable={false}
									filterable={false}
									groupedColumns={this.groupedColumns()}
								/>
							</ViewWeb>
							<SkioldFetchIcon
								className="print-special-period"
								onPress={this.fetchData}
								showSpinner={this.state.loading}
							/>
						</ViewWeb>

						<ViewWeb className="special-period-btn-container">
							<SkioldButton
								className="button-close-style"
								title={localized('Close')}
								onPress={this.props.closeModal}
							/>
							<SkioldIconSpinner
								containerClassName="print-special-period"
								title={'Print'}
								icon={PrinterGreyIcon}
								onPress={this.printPdf}
							/>
						</ViewWeb>
					</ViewWeb>
					{/* <ViewWeb className="special-period-buttons">
					
					<ViewWeb>
						
						
					</ViewWeb>
				</ViewWeb>
				 */}
					<ViewWeb className="report-table">
						{this.props.pReportSetting.animaltype === AnimalType.Sow ? (
							<ProductionReportTable
								pReportSetting={this.props.pReportSetting}
								isSpecialPeriod={true}
								productionReportData={this.state.reportData as IProductionReportItem}
							/>
						) : (
							<GrowthPigProductionReportTable
								pReportSetting={this.state.pReportSetting}
								isSpecialPeriod={true}
								productionReportData={
									this.state.reportData &&
									(this.state.reportData as IGrowthPigPReportTableDatasType).tableData
								}
							/>
						)}
						<PReportFeedUsage
							feedUsageData={
								this.state.reportData && (this.state.reportData as IProductionReportItem).feedUsageItems
							}
						/>
					</ViewWeb>
				</ViewWeb>
			</PageContainer>
		);
	}
	public renderFeedUsageTable = () => {
		return;
	};

	public printPdf = async () => {
		if (this.props.animalType === AnimalType.Sow) {
			await GetProductionReportPdf(
				true,
				this.props.profile.siteId!,
				`${localized('pReport')}  (${localized('specialPeriod')}) ${getDateString(new Date())}.pdf`,
				Intl.DateTimeFormat().resolvedOptions().timeZone,
				this.props.profile.language,
			);
		} else {
			GetGrowthPigProductionReportPdf(
				this.state.reportData as IGrowthPigPReportTableDatasType,
				this.props.animalType,
				this.props.profile.siteId!,
				`${localized('pReport')} ${getDateString(new Date())}.pdf`,
				this.props.selectedSectionLabel ? this.props.selectedSectionLabel : '',
				Intl.DateTimeFormat().resolvedOptions().timeZone,
				this.props.profile.language,
				true,
			);
		}
	};

	private fetchData = async () => {
		this.setState({
			loading: true,
		});
		let report =
			this.props.pReportSetting.animaltype && this.props.pReportSetting.animaltype !== AnimalType.Sow
				? await GetProductionReportGrowthPigs(
						true,
						this.props.profile.siteId!,
						this.props.pReportSetting.animaltype,
						this.props.pReportSetting.animaltype,
						this.props.selectedSectionId ? this.props.selectedSectionId : undefined,
				  )
				: await GetProductionReport(true, this.props.profile.siteId!);

		this.setState({ reportData: report, loading: false, pReportSetting: this.props.pReportSetting });
	};

	private generateData = (pReportSetting: IProductionReportSetting) => {
		const dataItems: PeriodTableRow[] = [];
		let fromItems: PeriodTableItem[] = [
			new PeriodTableItem(localized('FromDate'), pReportSetting.specialFromDate, 1, true),
		];
		let toItems: PeriodTableItem[] = [
			new PeriodTableItem(localized('ToDate'), pReportSetting.specialToDate, 1, false),
		];

		dataItems.push(new PeriodTableRow(fromItems));
		dataItems.push(new PeriodTableRow(toItems));

		return dataItems;
	};

	public generateColumnsExtensions() {
		const columnExtensions: any = [];

		return columnExtensions;
	}

	private groupedColumns = () => {
		const groupedHeader: GroupedHeader[] = [];

		return groupedHeader;
	};

	private generateColumns() {
		const columns: any = [
			{
				name: 'specialPeriod', //sow team
				title: `${localized('specialPeriod')}`,
				sortable: false,
				filterable: false,
				getCellValue: (period: PeriodTableRow) => this.generateCellValue(period.items![0]),
			},
		];

		return columns;
	}

	private generateCellValue = (item: PeriodTableItem) => {
		return (
			<ViewWeb className="period-item-container">
				<TextWeb>{item.name}</TextWeb>
				<SkioldFormDatePicker
					onDateChanged={newDate => this.props.onDataChanged(newDate, item)}
					selectedDate={item.date}
					containerClassName="marginRightTen"
					includeTimeStamp={false}
					theme={'dark'}
					color={'grey'}
					maxDate={new Date(8640000000000000)}
					minDate={this.setMinimumDate(item)}
					isEndDate={item.isFromDate ? false : true}
					disabled={this.props.animalType === AnimalType.Sow && !this.state.earliestBatch}
				/>
			</ViewWeb>
		);
	};

	private setMinimumDate = (item: PeriodTableItem) => {
		let minimumDate = new Date(
			this.props.animalType === AnimalType.Sow && this.state.earliestBatch
				? this.state.earliestBatch.matingPeriodStart!
				: -8640000000000000,
		);
		if (!item.isFromDate && this.props.pReportSetting.specialFromDate) {
			minimumDate = new Date(this.props.pReportSetting.specialFromDate);
		}
		return minimumDate;
	};
}
