import React from 'react';
import DeleteIcon from 'shared/assets/src-assets/png/delete_icon.png';
import PenIcon from 'shared/assets/src-assets/png/pen_icon.png';
import { getDateString } from 'shared/helpers/date-helpers';
import { exactNumberFilterMethodGrid, numberWithCommas, rangeFilterMethodGrid, setOneDecimalsAsNumber } from 'shared/helpers/general-helpers';
import {
	getFromPenClassName,
	getToPenClassName,
	GrowthPigMoveEventListRow,
	GrowthPigMoveEventListTablePropsFromParent,
} from 'shared/helpers/growth-pigs-helper/growth-pig-event-list-helper';
import { NaturalSortDates } from 'shared/helpers/natural-sort';
import { localized } from 'shared/state/i18n/i18n';
import SkioldTableGrid from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { SkioldTouchableOpacity } from 'web/view/components/skiold-components/skiold-touchable-opacity';
import { SkioldImage } from 'web/view/components/utils/svg/skiold-image';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { SowListConstants } from '../../animal-lists/table-constants';

export class GrowthPigMoveEventTable extends React.PureComponent<GrowthPigMoveEventListTablePropsFromParent> {
	public render() {
		return (
			<SkioldTableGrid
				ignoreSetCount={true}
				ColumnExtensions={this.generateColumnExtes}
				onFiltersChanged={this.props.onFilterChanged}
				ref={this.props.setRef}
				tableKey={'growthPigMoveEventListTable'}
				columns={this.generateColumns}
				data={this.props.bundleData}
			/>
		);
	}
	private getFromBuildingNameCell = (item: GrowthPigMoveEventListRow) => item.fromBuildingName;
	private getFromSectionNameCell = (item: GrowthPigMoveEventListRow) => item.fromSectionName;
	private getFromPenNameCell = (item: GrowthPigMoveEventListRow) => item.fromPenName;
	private getToBuildingNameCell = (item: GrowthPigMoveEventListRow) => item.toBuildingName;
	private getToSectionNameCell = (item: GrowthPigMoveEventListRow) => item.toSectionName;
	private getToPenNameCell = (item: GrowthPigMoveEventListRow) => item.toPenName;
	private getAmountCell = (item: GrowthPigMoveEventListRow) => item.pcs;
	private getTotalWeightCell = (item: GrowthPigMoveEventListRow) => numberWithCommas(setOneDecimalsAsNumber(item.weight));
	private getAvgWeightCell = (item: GrowthPigMoveEventListRow) => setOneDecimalsAsNumber(item.avgWeight);
	private getDateCell = (item: GrowthPigMoveEventListRow) => getDateString(item.date);
	private getFromProductionType = (item: GrowthPigMoveEventListRow) =>
		item.fromProductionType ? localized(item.fromProductionType) : ' ';
	private getToProductionType = (item: GrowthPigMoveEventListRow) =>
		item.toProductionType ? localized(item.toProductionType) : ' ';
	private getEditCell = (event: GrowthPigMoveEventListRow) => {
		if (event.fromProductionType !== this.props.animalType) {
			return <ViewWeb></ViewWeb>;
		}
		return (
			<SkioldTouchableOpacity onPress={this.props.openEditModal} itemFromParent={event}>
				<SkioldImage
					width={SowListConstants.iconSVGWidth}
					height={SowListConstants.iconSVGWidth}
					imageData={PenIcon}
				/>
			</SkioldTouchableOpacity>
		);
	};

	private getRemoveCell = (event: GrowthPigMoveEventListRow) => {
		if (event.fromProductionType !== this.props.animalType) {
			return <ViewWeb></ViewWeb>;
		}
		return (
			<SkioldTouchableOpacity onPress={this.props.removeItem} itemFromParent={event}>
				<SkioldImage
					width={SowListConstants.iconSVGWidth}
					height={SowListConstants.iconSVGWidth}
					imageData={DeleteIcon}
				/>
			</SkioldTouchableOpacity>
		);
	};

	private generateColumns = [
		{
			name: 'edit',
			title: ' ',
			sortable: false,
			filterable: false,
			isFixedLeft: true,
			getCellValue: this.getEditCell,
		},
		{
			name: 'date',
			title: localized('Date'),
			getCellValue: this.getDateCell,
			sortFunction: NaturalSortDates,
		},
		{
			name: 'fromProctionType',
			title: <ViewWeb className="column-word-wrap">{localized('fromProctionType')}</ViewWeb>,
			getCellValue: this.getFromProductionType,
		},
		{
			name: 'fromBuilding',
			title: <ViewWeb className="column-word-wrap">{localized('FromBuilding')}</ViewWeb>,
			getCellValue: this.getFromBuildingNameCell,
		},
		{
			name: 'fromSection',
			className: getFromPenClassName,
			title: <ViewWeb className="column-word-wrap">{localized('FromSection')}</ViewWeb>,
			getCellValue: this.getFromSectionNameCell,
			filterFunction: exactNumberFilterMethodGrid,
		},
		{
			name: 'fromPen',
			className: getFromPenClassName,
			title: <ViewWeb className="column-word-wrap">{localized('FromPen')}</ViewWeb>,
			getCellValue: this.getFromPenNameCell,
			filterFunction: rangeFilterMethodNumberGrid,
		},
		{
			name: 'toProctionType',
			title: <ViewWeb className="column-word-wrap">{localized('toProctionType')}</ViewWeb>,
			getCellValue: this.getToProductionType,
		},
		{
			name: 'toBuilding',
			title: <ViewWeb className="column-word-wrap">{localized('ToBuilding')}</ViewWeb>,
			getCellValue: this.getToBuildingNameCell,
		},
		{
			name: 'toSection',
			className: getToPenClassName,
			title: <ViewWeb className="column-word-wrap">{localized('ToSection')}</ViewWeb>,
			getCellValue: this.getToSectionNameCell,
			filterFunction: exactNumberFilterMethodGrid,
		},
		{
			name: 'toPen',
			className: getToPenClassName,
			title: <ViewWeb className="column-word-wrap">{localized('ToPen')}</ViewWeb>,
			getCellValue: this.getToPenNameCell,
			filterFunction: rangeFilterMethodNumberGrid,
		},
		{
			name: 'amount',
			filterFunction: rangeFilterMethodGrid,
			title: localized('Count'),
			getCellValue: this.getAmountCell,
		},
		{
			name: 'weight',
			filterFunction: rangeFilterMethodGrid,
			title: localized('Weight'),
			getCellValue: this.getTotalWeightCell,
		},
		{
			name: 'avgWeight',
			filterFunction: rangeFilterMethodGrid,
			title: <ViewWeb className="column-word-wrap">{localized('avgWeight')}</ViewWeb>,
			getCellValue: this.getAvgWeightCell,
		},
		{
			name: 'remove',
			title: ' ',
			sortable: false,
			filterable: false,
			isFixedLeft: true,
			getCellValue: this.getRemoveCell,
		},
	];

	private generateColumnExtes = [
		{
			columnName: 'edit',
			width: SowListConstants.iconWidth,
			filteringEnabled: false,
			sortingEnabled: false,
			resizingEnabled: false,
		},
		{
			columnName: 'date',
			width: SowListConstants.dateWidth,
		},
		{
			columnName: 'fromProctionType',
			width: SowListConstants.adjustedProctionType,
		},
		{
			columnName: 'fromBuilding',
			width: SowListConstants.buildingWidth,
		},
		{
			columnName: 'fromSection',
			width: SowListConstants.buildingWidth,
		},
		{
			columnName: 'fromPen',
			width: SowListConstants.penWidth,
		},
		{
			columnName: 'toProctionType',
			width: SowListConstants.adjustedProctionType,
		},
		{
			columnName: 'toBuilding',
			width: SowListConstants.buildingWidth,
		},
		{
			columnName: 'toSection',
			width: SowListConstants.buildingWidth,
		},
		{
			columnName: 'toPen',
			width: SowListConstants.penWidth,
		},
		{
			columnName: 'amount',
			width: SowListConstants.countWidth,
		},
		{
			columnName: 'weight',
			width: SowListConstants.weightWidth,
		},
		{
			columnName: 'avgWeight',
			width: SowListConstants.avgWeightWidth,
		},
		{
			columnName: 'remove',
			width: SowListConstants.iconWidth,
			filteringEnabled: false,
			sortingEnabled: false,
			resizingEnabled: false,
		},
	];
}

function rangeFilterMethodNumberGrid(value: any, filterValue: any) {
	if (
		value !== undefined &&
		filterValue &&
		filterValue.value.includes('-') &&
		filterValue.value.indexOf('-') === filterValue.value.lastIndexOf('-')
	) {
		const raw = filterValue.value.split('-');
		let valueOne = Number((raw[0].toString() as string).replace(/[^\d.-]/g, ''));
		let valueTwo = Number((raw[1].toString() as string).replace(/[^\d.-]/g, ''));
		if (!isNaN(valueOne) && !isNaN(valueTwo) && raw[0] !== '' && raw[1] !== '') {
			const rowValue = Number(value);
			return rowValue >= valueOne && rowValue <= valueTwo;
		}
	}
	return String(value).startsWith(filterValue.value);
}
