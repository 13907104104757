import { WebAppState } from 'web/state/store.web';
import { IStemAnimal, IPregnancyEvent } from 'shared/api/api';
import React from 'react';
import { SkioldFormInput } from '../../skiold-components/skiold-input/skiold-form-input';
import { PregnancyAnimalChanged } from 'shared/helpers/pregnancy-helper/pregnancy-animal-changed';

import { connect } from 'react-redux';

export interface PropsFromParent {
	onChangeAnimalNumber: (
		sow: IStemAnimal,
		animalEvents: IPregnancyEvent[],
		prevEvent: IPregnancyEvent,
		newPregnancyEvent: IPregnancyEvent,
		animalNumbner: string,
	) => void;
	text?: string;
	pregnancyEvent: IPregnancyEvent;
	onAnimalNumberChanged: (animalNumber?: string) => void;
	editable?: boolean;
	selectTextOnFocus?: boolean;
	toggleFocus?: boolean;
	className?: string;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		siteId: state.profile.active!.siteId,
		pregnancyEvents: state.pregnancyEvents.entities,
		useLettersForAnimalNumber: state.generalSettings.entity.useLettersForAnimalNumber,
	};
};

interface State {
	animalNumber: string | undefined;
}
type SkioldPregnancyAnimalInputProps = ReturnType<typeof mapStateToProps> & PropsFromParent;
export class SkioldPregnancyAnimalInput extends React.PureComponent<SkioldPregnancyAnimalInputProps, State> {
	public static defaultProps: Partial<SkioldPregnancyAnimalInputProps> = {
		editable: true,
		selectTextOnFocus: true,
		toggleFocus: true,
		className: '',
	};
	private animalNumInputRef: SkioldFormInput | null | undefined;

	constructor(props: SkioldPregnancyAnimalInputProps) {
		super(props);
		this.state = {
			animalNumber: props.text,
		};
	}

	public componentDidUpdate(nextProps: SkioldPregnancyAnimalInputProps) {
		if (nextProps.toggleFocus !== this.props.toggleFocus) {
			this.focus();
		}
	}

	public render() {
		return (
			<SkioldFormInput
				onChangeText={newText => this.onAnimalNumberChanged(newText)}
				text={this.props.text}
				label={'pregEvent'}
				editable={this.props.editable}
				selectTextOnFocus={this.props.selectTextOnFocus}
				ref={ref => {
					this.animalNumInputRef = ref;
				}}
				autoFocus={true}
				className={this.props.className}
				useAsInterger={!this.props.useLettersForAnimalNumber}
			/>
		);
	}

	public focus() {
		if (this.animalNumInputRef) {
			this.animalNumInputRef.focus();
		}
	}

	private onAnimalNumberChanged(animalNumber: string) {
		this.props.onAnimalNumberChanged(animalNumber);

		PregnancyAnimalChanged(
			(sow, animalEvents, prevEvent, newEvent) => {
				this.props.onChangeAnimalNumber(sow, animalEvents, prevEvent, newEvent, animalNumber);
			},
			animalNumber,
			this.props.pregnancyEvent,
			this.props.pregnancyEvents,
		);
	}
}
export default connect(mapStateToProps)(SkioldPregnancyAnimalInput);
