import { Sorting } from '@devexpress/dx-react-grid';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { DrugWithAdjustment, IDrugWithAdjustment } from 'shared/api/api';
import DeleteIcon from 'shared/assets/src-assets/png/delete_icon.png';
import PenIcon from 'shared/assets/src-assets/png/pen_icon.png';
import { getDateString, setCorrectTimeStamp } from 'shared/helpers/date-helpers';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { NaturalSortDates } from 'shared/helpers/natural-sort';
import { DeleteDrugAdjustment, GetDrugsForMedicineLog, Sync } from 'shared/state/ducks/drug/operations';
import { DrugUpdate } from 'shared/state/ducks/drug/types';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import AddToCurrentDrugStock from 'web/view/components/drugs/drug-stock/add-to-current-drug-stock';
import PageContainer from 'web/view/components/page-container/page-container';
import { ShowConfirmAlert } from 'web/view/components/skiold-alert/skiold-alert';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldDatePicker } from 'web/view/components/skiold-components/skiold-date-picker/skiold-date-picker';
import { SkioldModal } from 'web/view/components/skiold-components/skiold-modal/skiold-modal';
import { SowListConstants } from 'web/view/components/stem-animal/animal-lists/table-constants';
import { Heading } from 'web/view/components/utils/heading';
import { SkioldImage } from 'web/view/components/utils/svg/skiold-image';
import { ViewWeb } from 'web/view/components/utils/web-view';
import SkioldTableGrid from '../skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { SkioldTouchableOpacity } from '../skiold-components/skiold-touchable-opacity';
import { getAdjustmentType, getPigType } from './drug-stock/drug-stock-helper';
import './log.scss';

interface PropsFromParent {
	closeModal: () => void;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		siteId: state.profile.active!.siteId,
		drugTypes: state.drugType.entities,
		drugs: state.drugs.drugsWithAdjustments,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	GetDrugsForMedicineLog: (siteId: string, startDate: Date, endDate: Date) =>
		GetDrugsForMedicineLog(siteId, startDate, endDate)(dispatch),
	DeleteDrugAdjustment: (drugWithAdjustment: DrugWithAdjustment) =>
		DeleteDrugAdjustment(drugWithAdjustment)(dispatch),
	Sync: (drugs: DrugUpdate[]) => Sync(drugs)(dispatch),
});

interface State {
	fromDate: Date;
	toDate: Date;
	editModalOpen: boolean;
	drugToEdit: IDrugWithAdjustment | undefined;
	columns: any[];
	columnsExte: any[];
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

class MedicineLog extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		let tempFromDate = setCorrectTimeStamp(new Date(),false,false)!;
		tempFromDate.setMonth(new Date().getMonth() - 1);
		let tempToDate =setCorrectTimeStamp(new Date(),false,true)!
		tempToDate.setMonth(new Date().getMonth());

		this.state = {
			fromDate: tempFromDate,
			toDate: tempToDate,
			editModalOpen: false,
			drugToEdit: undefined,
			columns: this.generateColumns(),
			columnsExte: this.generateColumnsExtensions(),
		};

		if (this.props.siteId) {
			this.props.GetDrugsForMedicineLog(this.props.siteId, this.state.fromDate, this.state.toDate);
		}
	}

	public render() {
		return (
			<PageContainer className="log">
				<Heading text={localized('medicineLog')} />
				<ViewWeb className="stock-overview">
					<ViewWeb className="view-container-style">
						<ViewWeb className="view-style alignCenter">
							<ViewWeb className="date-picker-container">
								<SkioldDatePicker
									onDateChanged={this.fromDateChanged}
									selectedDate={this.state.fromDate!}
									text={'FromDate'}
									containerClassName="datepicker-margin"
								/>
								<SkioldDatePicker
									onDateChanged={this.toDateChanged}
									selectedDate={this.state.toDate!}
									text={'ToDate'}
									containerClassName="datepicker-margin"
								/>
							</ViewWeb>
						</ViewWeb>
					</ViewWeb>

					<ViewWeb className="medicine-log-outer-view">
						<SkioldTableGrid
							tableKey={'medicineLog'}
							ColumnExtensions={this.state.columnsExte}
							columns={this.state.columns}
							data={this.getFilteredData()}
							sortHeaderId={this.defaultSortHeader}
						/>
					</ViewWeb>
					<ViewWeb className="button-container">
						<SkioldButton theme="grey" title={localized('Close')} onPress={this.props.closeModal} />
					</ViewWeb>
				</ViewWeb>
				<SkioldModal padding="0" isOpen={this.state.editModalOpen} close={this.closeModal}>
					{this.state.editModalOpen && (
						<AddToCurrentDrugStock
							drugAdjustmentToEdit={this.state.drugToEdit}
							closeModal={this.closeModal}
						/>
					)}
				</SkioldModal>
			</PageContainer>
		);
	}

	private defaultSortHeader = [{ columnName: 'cycleDays', direction: 'desc' }] as Sorting[];

	public generateColumnsExtensions() {
		return [
			{
				columnName: 'edit',
				filteringEnabled: false,
				width: SowListConstants.iconWidth,
			},
			{
				columnName: 'delete',
				filteringEnabled: false,
				width: SowListConstants.iconWidth,
			},
			{
				columnName: 'drugType',
				width: SowListConstants.treatDefinitionDrugWidth,
			},
			{
				columnName: 'pigTypes',
				width: SowListConstants.entranceDateWidth,
			},
			{
				columnName: 'date',

				width: SowListConstants.dateWidth,
			},
			{
				columnName: 'type',
				width: SowListConstants.adjustmentType,
			},
			{
				columnName: 'amount',
				width: SowListConstants.dateWidth,
			},
			{
				columnName: 'registeredBy',
				width: SowListConstants.registeredBy,
			},
			{
				columnName: 'reason',
				width: SowListConstants.entranceDateWidth,
			},
			{ columnName: 'price', width: SowListConstants.priceWidth },
		];
	}

	private closeModal = () => {
		this.setState({ editModalOpen: false, drugToEdit: undefined });
	};

	private edit(drug: IDrugWithAdjustment) {
		this.setState({ editModalOpen: true, drugToEdit: drug });
	}

	private readonly getDeleteIconCell = (drug: DrugWithAdjustment) => (
		<SkioldTouchableOpacity itemFromParent={drug} onPress={this.onDeleteIconPressed}>
			<SkioldImage
				width={SowListConstants.iconSVGWidth}
				height={SowListConstants.iconSVGWidth}
				imageData={DeleteIcon}
			/>
		</SkioldTouchableOpacity>
	);

	private readonly getDrugTypeCell = (drug: IDrugWithAdjustment) => this.findDrugType(drug);

	private readonly getPigTypeCell = (drug: IDrugWithAdjustment) => getPigType(drug.animalKinds!);

	private readonly getDateCell = (drug: IDrugWithAdjustment) => getDateString(drug.date);

	private readonly getDrugAdjustmentTypeCell = (drug: IDrugWithAdjustment) => getAdjustmentType(drug.type!);

	private readonly getDrugAmountCell = (drug: IDrugWithAdjustment) =>
		drug.amount ? drug.amount.toFixed(0) : drug.amount + ' ml';

	private readonly getRegisteredByCell = (drug: IDrugWithAdjustment) => (drug.registeredBy ? drug.registeredBy : '');

	private readonly getReasonCell = (drug: IDrugWithAdjustment) => (drug.reason ? drug.reason : '');

	private readonly getPriceCell = (drug: IDrugWithAdjustment) => (drug.price ? drug.price : '');
	private generateColumns(): any {
		return [
			{
				name: 'edit',
				title: ' ',
				sortable: false,
				filterable: false,
				width: SowListConstants.iconWidth,
				fixed: 'left',
				getCellValue: this.getEditDrugCell,
			},
			{
				name: 'delete',
				title: ' ',
				sortable: false,
				filterable: false,
				width: SowListConstants.iconWidth,
				fixed: 'left',
				getCellValue: this.getDeleteIconCell,
			},
			{
				name: 'drugType',
				title: localized('DrugTypes'),
				getCellValue: this.getDrugTypeCell,
			},
			{
				name: 'pigTypes',
				title: localized('pigTypes'),
				getCellValue: this.getPigTypeCell,
			},
			{
				name: 'date',
				title: localized('Date'),
				getCellValue: this.getDateCell,
				sortFunction: NaturalSortDates,
			},
			{
				name: 'type',
				title: localized('adjustmentType'),
				width: SowListConstants.adjustmentType,
				getCellValue: this.getDrugAdjustmentTypeCell,
			},
			{
				name: 'amount',
				title: localized('amount'),
				getCellValue: this.getDrugAmountCell,
			},
			{
				name: 'registeredBy',
				title: localized('registeredBy'),
				width: SowListConstants.registeredBy,
				getCellValue: this.getRegisteredByCell,
			},
			{
				name: 'reason',
				title: localized('reason'),
				getCellValue: this.getReasonCell,
			},
			{
				name: 'price',
				title: localized('price'),
				getCellValue: this.getPriceCell,
			},
		];
	}

	private getEditDrugCell = (drug: IDrugWithAdjustment) => (
		<SkioldTouchableOpacity itemFromParent={drug} onPress={this.OnEditDrugCellPressed}>
			<SkioldImage
				width={SowListConstants.iconSVGWidth}
				height={SowListConstants.iconSVGWidth}
				imageData={PenIcon}
			/>
		</SkioldTouchableOpacity>
	);

	private onDeleteIconPressed = async (drug: DrugWithAdjustment) => {
		let allow = await ShowConfirmAlert(localized(ExceptionMessage.VALIDATION_WARNING_CONFIRM_DELETEION_ADJUSTMENT));
		if (!allow) {
			return;
		}

		this.props.DeleteDrugAdjustment(drug);
	};

	private OnEditDrugCellPressed = (drug: IDrugWithAdjustment) => this.edit(drug);

	private findDrugType(drug: IDrugWithAdjustment) {
		let drugType = this.props.drugTypes.find(a => a.id === drug.drugTypeId);
		if (drugType !== undefined) {
			return drugType.name;
		} else {
			return '';
		}
	}

	private getFilteredData() {
		if (!this.state.fromDate || !this.state.toDate) {
			return this.props.drugs;
		}
		const drugs = this.props.drugs.filter(drug => {
			if (drug.date !== undefined) {
				const date = drug.date;
				let res = date && date > this.state.fromDate! && date < this.state.toDate!;
				return res;
			} else {
				return false;
			}
		});
		return drugs;
	}

	private fromDateChanged = (fromDate: Date) => {
		this.setState({ fromDate });
		if (this.props.siteId) {
			this.props.GetDrugsForMedicineLog(this.props.siteId, fromDate, this.state.toDate);
		}
	};

	private toDateChanged = (toDate: Date) => {
		this.setState({ toDate });
		if (this.props.siteId) {
			this.props.GetDrugsForMedicineLog(this.props.siteId, this.state.fromDate, toDate);
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(MedicineLog);
