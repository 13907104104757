import { Action } from 'redux';
import {
	DistriwinTask,
	INotification,
	ISyncNotifcations,
	Notification,
	StemAnimal,
	SyncNotifcations,
} from 'shared/api/api';
import { SharedAppState } from 'shared/state/store.shared';
import { isType } from 'typescript-fsa';
import { siteChange } from '../profile/actions';
import { acknowledgeAlarm, acknowledgeWarning, getAlarms, getWarnings } from './actions';
import { NotificationState } from './types';
import { getControllerTasks } from '../feed-computers/operation';
import { SyncableInitialState } from 'shared/state/models/syncable';
import moment from 'moment';

export const initialState: NotificationState = {
	alarms: SyncNotifcations.fromJS({ datas: [] }),
	warnings: SyncNotifcations.fromJS({ datas: [] }),
	distriwinTasks: [],
	isSyncingAlarms: false,
	isSyncingWarnings: false,
};

const notificationReducer = (state: NotificationState = initialState, action: Action): NotificationState => {
	if (isType(action, getAlarms.started)) {
		return { ...state, isSyncingAlarms: true };
	}

	if (isType(action, getAlarms.failed)) {
		return { ...state, isSyncingAlarms: false };
	}

	if (isType(action, getAlarms.done)) {
		let currentAlarms = { ...state.alarms } as SyncNotifcations;
		currentAlarms.datas = mergeArrays(currentAlarms.datas! ?? [], action.payload.result.datas!);
		currentAlarms.syncTime = action.payload.result.syncTime;
		return {
			...state,
			alarms: currentAlarms,
			isSyncingAlarms: false,
		};
	}

	if (isType(action, getWarnings.started)) {
		return { ...state, isSyncingWarnings: true };
	}

	if (isType(action, getControllerTasks.fulfilled as any)) {
		const statuses = (action.payload as DistriwinTask[]).map(t => t.status ?? 1);
		return { ...state, distriwinTasks: statuses };
	}

	if (isType(action, getWarnings.failed)) {
		return { ...state, isSyncingWarnings: false };
	}

	if (isType(action, getWarnings.done)) {
		let currentWarnings = { ...state.warnings } as SyncNotifcations;
		currentWarnings.datas = mergeArrays(currentWarnings.datas! ?? [], action.payload.result.datas!);
		currentWarnings.syncTime = action.payload.result.syncTime;
		return {
			...state,
			warnings: action.payload.result,
			isSyncingWarnings: false,
		};
	}

	if (isType(action, acknowledgeAlarm.done)) {
		const alarms = { ...state.alarms! } as SyncNotifcations;
		const newAlarms = [...alarms.datas!];
		const index = newAlarms.findIndex(x => x.instanceID === action.payload.params)!;
		newAlarms[index] = { ...newAlarms[index], userAcknowledged: true } as Notification;
		alarms.datas = newAlarms;
		return { ...state, alarms };
	}

	if (isType(action, acknowledgeWarning.done)) {
		const warnings = { ...state.warnings! } as SyncNotifcations;
		const newWarnings = [...warnings.datas!];
		const index = newWarnings.findIndex(x => x.instanceID === action.payload.params)!;
		newWarnings[index] = { ...newWarnings[index], userAcknowledged: true } as Notification;
		return { ...state, warnings };
	}

	if (isType(action, siteChange.done)) {
		state = initialState;
	}

	//Ensure Date objects are in fact date and not strings - redux-persist serializes dates to string but doesn't deserialize to dates again
	if (action.type === 'persist/REHYDRATE') {
		let a = (action as any) as { payload: SharedAppState; key: string };

		if (a.key === 'root') {
			let alarmsData = new Array<Notification>();
			let warningsData = new Array<Notification>();
			let alarms = SyncNotifcations.fromJS({
				datas: [] as Notification[],
				syncTime: new Date(-1000000),
			} as ISyncNotifcations);

			let warnings = SyncNotifcations.fromJS({
				datas: [] as Notification[],
				syncTime: new Date(0),
			} as ISyncNotifcations);

			alarms.syncTime = new Date(-8640000000000000);
			warnings.syncTime = new Date(-8640000000000000);
			if (
				a.payload &&
				a.payload.notifications &&
				a.payload.notifications.alarms &&
				a.payload.notifications.alarms.datas
			) {
				alarmsData = a.payload.notifications.alarms.datas.map((dt: INotification) => {
					let ndt = new Notification();
					ndt.init(dt);
					return ndt;
				});
				alarms.datas = alarmsData;
			}

			if (
				a.payload &&
				a.payload.notifications &&
				a.payload.notifications.warnings &&
				a.payload.notifications.warnings.datas
			) {
				warningsData = a.payload.notifications.warnings.datas.map((dt: INotification) => {
					let ndt = new Notification();
					ndt.init(dt);
					return ndt;
				});
				warnings.datas = warningsData;
			}
			if (
				a.payload &&
				a.payload.notifications &&
				a.payload.notifications.alarms &&
				a.payload.notifications.alarms.syncTime &&
				moment(a.payload.notifications.alarms.syncTime).isValid()
			) {
				alarms.syncTime = new Date(a.payload.notifications.alarms.syncTime);
			}

			if (
				a.payload &&
				a.payload.notifications &&
				a.payload.notifications.warnings &&
				a.payload.notifications.warnings.syncTime &&
				moment(a.payload.notifications.warnings.syncTime).isValid()
			) {
				warnings.syncTime = new Date(a.payload.notifications.warnings.syncTime);
			}
			state = { ...state, alarms, warnings };
		}
	}

	return state;
};

export default notificationReducer;

export function mergeArrays(oldArray: Notification[], updatedArray: Notification[]) {
	if (oldArray && oldArray.length === 0) {
		return updatedArray;
	}

	let clonedOld = [...oldArray];
	updatedArray.forEach(updated => {
		const index = clonedOld.findIndex(old => old.instanceID === updated.instanceID);
		if (index > -1) {
			if (updated.userAcknowledged) {
				clonedOld.splice(index, 1);
			} else {
				clonedOld[index] = updated;
			}
		} else {
			if (!updated.userAcknowledged) {
				clonedOld.push(updated);
			}
		}
	});

	return clonedOld.filter(elem => !elem.userAcknowledged);
}
