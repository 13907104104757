import { Option } from 'react-dropdown';
import { Dispatch } from 'redux';
import {
	AnimalType,
	IDiagnose,
	IMoveEvent,
	IStemAnimal,
	ITreatment,
	ITreatmentDefinition,
	ITreatmentPlan,
	IUserProfile,
} from 'shared/api/api';
import { getStemAnimalPenIdHashmapMoveEvents } from 'shared/helpers/location-helper';
import { memoizeHashmapLocation } from 'shared/helpers/memoize-getters/memoize-getters';
import { findAnimalById } from 'shared/helpers/stemanimal-helper/stem-animal-input-helper';
import { calculateAnimalKind, calculateAnimalKindByDate } from 'shared/helpers/stemanimal-helper/stemanimal-helper';
import {
	findRetentionTimeGrowthPigHashmaps,
	findRetentionTimeHashmaps,
} from 'shared/helpers/treatment-helper/treatment-helper';
import { GetSyncData as DiagnoseGetSyncData } from 'shared/state/ducks/diagnoses/operations';
import { GetSyncData as DrugTypeGetSyncData } from 'shared/state/ducks/drugTypes/operations';
import { GetLocations } from 'shared/state/ducks/locations/operations';
import { GetSyncData as GetMoveEventSyncData } from 'shared/state/ducks/move-events/operations';
import { GetSyncData as ProfileGetSyncData } from 'shared/state/ducks/profile/operations';
import { GetDeparturedAnimals, GetSyncData as StemAnimalGetSyncData } from 'shared/state/ducks/stem-animals/operations';
import { GetSyncData as TreatmentDefinitionGetSyncData } from 'shared/state/ducks/treatment-definitions/operations';
import { GetTreatmentPlansForLog } from 'shared/state/ducks/treatment-plan/operations';
import { GetCompletedTreatmentsInPeriod } from 'shared/state/ducks/treatment/operations';
import { WebAppState } from 'web/state/store.web';
import { SowListConstants } from 'web/view/components/stem-animal/animal-lists/table-constants';
import {
	SubtreatmentTableModel,
	TreatmentTableModel,
} from 'web/view/components/treatments/treatment-plan/treatment-table-model';
import { getWebAnimalColorStyleTable } from 'web/web-helpers/general-web-helpers';

export const treatmentLogMapStateToProps = (state: WebAppState) => {
	return {
		treatmentPlans: state.treatmentPlans.treatmentPlansForLog,
		treatments: state.treatments.treatmentsForLog,
		diagnoses: state.diagnose.entities,
		treatmentDefinitions: state.treatmentDefinitions.entities,
		drugTypes: state.drugType,
		language: state.profile.active!.language,
		siteId: state.profile.active!.siteId,
		profiles: state.profile.entities,
		buildings: state.locations.buildings,
		sections: state.locations.sections,
		pens: state.locations.pens,
		hashmapLocations: memoizeHashmapLocation(
			state.locations.buildings,
			state.locations.sections,
			state.locations.pens,
		),
		moveEvents: state.moveEvents.entities,
		stemAnimals: state.stemAnimals.entities,
		departuredAnimals: state.stemAnimals.departuredAnimals,
		fetchingTreatmentPlans: state.treatmentPlans.fetchingTreatmentPlansForLog,
		fetchingTreatments: state.treatments.fetchingTreatmentsForLog,
		animalTypes:
			state.navigation.query && state.navigation.query.type
				? (state.navigation.query.type as AnimalType)
				: AnimalType.Sow,
	};
};

export const treatmentLogMapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	getDiagnoses: () => DiagnoseGetSyncData()(dispatch),
	treatmentDefinitionGetSyncData: () => TreatmentDefinitionGetSyncData()(dispatch),
	getDrugTypes: () => DrugTypeGetSyncData()(dispatch),
	stemAnimalGetSyncData: () => StemAnimalGetSyncData()(dispatch),
	getTreatmentPlans: (siteId: string, startInterval: Date, endInterval: Date) =>
		GetTreatmentPlansForLog(siteId, startInterval, endInterval)(dispatch),
	getTreatments: (siteId: string, startInterval: Date, endInterval: Date) =>
		GetCompletedTreatmentsInPeriod(siteId, startInterval, endInterval)(dispatch),
	getMoveEventSyncData: () => GetMoveEventSyncData()(dispatch),
	getLocations: () => GetLocations()(dispatch),
	getProfiles: () => ProfileGetSyncData()(dispatch),
	getDeparturedAnimals: () => GetDeparturedAnimals()(dispatch),
});

// tmp dates are used in the datepicker as they shouldnt come into effect before the fetch button is clicked
export interface TreatmentLogState {
	modalIsOpen: boolean;
	startDate: Date;
	endDate: Date;
	fetchingData: boolean; // Made so the spinner does not spin, when fetching data is used inside modal
	selectedDrugType: Option;
	drugTypeOptions: Option[];
	treatmentTableModels: TreatmentTableModel[];
	treatmentToEdit?: ITreatment;
	treatmentLogAnimalTypeOpen?: boolean;
	treatmentLogAnimalTypeData?: TreatmentTableModel[];
	selectedAnimalType: Option;
	animalTypeOptions: Option[];
	showChangeAnimalTypeDropdown?: boolean;
	isSow?: boolean;
}

export type TreatmentLogProps = ReturnType<typeof treatmentLogMapStateToProps> &
	ReturnType<typeof treatmentLogMapDispatchToProps>;

export const defaultDrugTreatmentLogColumnExtensions = [
	{
		columnName: 'date',
		width: SowListConstants.treatmentExecutedDateWidth,
	},
	{
		columnName: 'diagnose',
		width: SowListConstants.treatmetLogDiagnoseWidth,
	},
	{
		columnName: 'drug',
		filteringEnabled: false,
		width: SowListConstants.treatDefinitionDrugWidth,
	},
];

export const pigletAmountTreatmentLogColumnExtension = [
	{
		columnName: 'pigletAmount',
		width: SowListConstants.pigletAmountWidth,
	},
];

export const pigAmountAndWeightTreatmentLogColumnExtension = [
	{
		columnName: 'pigAmount',
		width: SowListConstants.pigletAmountWidth,
	},
	{
		columnName: 'pigWeight',
		width: SowListConstants.pigletAmountWidth,
	},
];

export const defaultDrugAmountTreatmentLogColumnExtension = [
	{
		columnName: 'drugAmount',
		width: SowListConstants.transponderWidth,
		filteringEnabled: false,
	},
	{
		columnName: 'drugAdministration',
		filteringEnabled: false,
		width: SowListConstants.indexDateWidth,
	},
	{
		columnName: 'severity',
		filteringEnabled: false,
		width: SowListConstants.treatmentNumberWidth,
	},
	{
		columnName: 'treatmentNumber',
		width: SowListConstants.treatmentNumberWidth,
	},
	{
		columnName: 'retentionDate',
		width: SowListConstants.treatmentExecutedDateWidth,
	},
	{
		columnName: 'building',
		width: SowListConstants.transponderWidth,
	},
	{
		columnName: 'section',
		width: SowListConstants.sectionWidth,
	},
	{
		columnName: 'pen',
		width: SowListConstants.sectionWidth,
	},
	{
		columnName: 'user',
		width: SowListConstants.indexDateWidth,
	},
	{
		columnName: 'edit',
		filteringEnabled: false,
		width: SowListConstants.iconWidth,
	},
];

export const animalNumberTreatmentLogColumnExtension = [
	{
		columnName: 'animalNumber',
		width: SowListConstants.animalNrWidth,
	},
];

export const generateTreatmentLogColumnData = (
	name: string,
	title: string,
	getCellValue: (obj: any) => JSX.Element | string | number | undefined,
	sortFunction?: (valueA: any, valueB: any) => any,
	filterFunction?: (valueA: any, valueB: any) => boolean,
	className?: any,
	sortable?: boolean,
	filterable?: boolean,
	pathToValue?: string,
) => {
	return {
		name: name,
		title: title,
		getCellValue: getCellValue,
		sortFunction: sortFunction,
		filterFunction: filterFunction,
		className: className,
		sortable: sortable,
		filterable: filterable,
		pathToValue: pathToValue,
	};
};

export function checkAnimalForYoungFemale(
	treatment: ITreatment,
	animalType: AnimalType,
	selectedAnimalType: AnimalType,
	treatmentPlan?: ITreatmentPlan,
) {
	return (
		animalType.includes(AnimalType.Sow) &&
		((selectedAnimalType === AnimalType.Sow && treatmentPlan && !treatmentPlan.stemAnimalId) ||
			(selectedAnimalType === AnimalType.YoungFemale &&
				((treatmentPlan &&
					treatmentPlan.stemAnimalId &&
					treatment.executedDate &&
					!compareAnimalKindToAnimalType(
						selectedAnimalType,
						treatment.executedDate,
						treatmentPlan.stemAnimalId,
					)) ||
					treatment.animalType !== AnimalType.YoungFemale)))
	);
}

function compareAnimalKindToAnimalType(animalType: AnimalType, date: Date, stemAnimalId?: string) {
	const foundAnimal = findAnimalById(stemAnimalId);
	if (foundAnimal) {
		const animalKind = calculateAnimalKindByDate(foundAnimal, date);
		if (animalKind.toString() === animalType.toString()) {
			return true;
		}
	}
	return false;
}

export function handleMissingPropsPigTreatments(
	model: TreatmentTableModel,
	treatmentPlan: ITreatmentPlan | undefined,
	treatment: ITreatment,
	treatmentDefinitions: { [key: string]: ITreatmentDefinition },
) {
	model.pigWeight =
		treatmentPlan && treatmentPlan.avgWeight && treatment.amount
			? treatmentPlan.avgWeight * treatment.amount
			: undefined;
	model.pigAmount = treatmentPlan && treatment.amount ? treatment.amount : undefined;
	model.penId = treatment.penId;
	model.sectionId = treatment ? treatment.sectionId : undefined;
	model.retentionTime =
		treatment && treatment.executedDate
			? findRetentionTimeGrowthPigHashmaps(treatment.executedDate, treatmentPlan, treatmentDefinitions)
			: undefined;
}

export function handleMissingPropsSowTreatments(
	model: TreatmentTableModel,
	treatment: ITreatment,
	animal: IStemAnimal | undefined,
	treatmentPlan: ITreatmentPlan | undefined,
	moveEvents: { [key: string]: IMoveEvent[] },
	treatmentDefinitions: { [key: string]: ITreatmentDefinition },
	treatmentPlanMappedToStemAnimalId: { [stemAnimalId: string]: ITreatmentPlan[] },
	treatmentsMappedToTreatmentPlanId: { [treatmentPlanId: string]: ITreatment[] },
	selectedAnimalType: AnimalType,
) {
	model.pigCount = treatment ? treatment.pigletAmount : undefined;
	model.stemAnimal = animal;
	model.animalWeight = treatment ? treatment.animalWeight : undefined;
	model.penId = treatment ? treatment.penId : undefined;
	if (animal) {
		const currentPen = getStemAnimalPenIdHashmapMoveEvents(animal, moveEvents);
		model.penId = currentPen;
	}

	model.retentionTime =
		treatmentPlan &&
		treatmentPlan.stemAnimalId &&
		findRetentionTimeHashmaps(
			treatmentPlan.stemAnimalId,
			treatmentDefinitions,
			treatmentPlanMappedToStemAnimalId,
			treatmentsMappedToTreatmentPlanId,
			selectedAnimalType,
		);
}

export function generateDefaultTreatmentLogTableModel(
	treatment: ITreatment,
	profile?: IUserProfile,
	treatmentPlan?: ITreatmentPlan,
	diagnose?: IDiagnose,
	subTreatments?: SubtreatmentTableModel[],
	treatmentDefinition?: ITreatmentDefinition,
) {
	let model = new TreatmentTableModel();
	model.executedDate = treatment.executedDate;
	model.diagnose = diagnose;
	model.subtreatmentTableModels = subTreatments!;
	model.feedWeight = treatment ? treatment.feedWeight : undefined;
	model.treatmentId = treatment && treatment.id;
	model.treatmentDefinitionId = treatmentDefinition ? treatmentDefinition.id : undefined;
	model.treatmentNumber = treatment && treatment.treatmentNumber ? treatment.treatmentNumber : undefined;
	model.totalTreatmentNumber =
		treatmentDefinition && treatmentDefinition.numberOfTreatments
			? treatmentDefinition.numberOfTreatments
			: undefined;
	model.executedByUser = profile ? profile.name : undefined;
	model.treatmentPlanId = treatmentPlan ? treatmentPlan.id : undefined;
	model.severity = treatment.severity;
	model.isRegistration = treatment.isRegistration;
	return model;
}

export const getTreatmentStemAnimalNumberColor = (tableItem: TreatmentTableModel) => {
	return tableItem && tableItem && tableItem.stemAnimal ? getWebAnimalColorStyleTable(tableItem.stemAnimal.id) : '';
};
