import React from 'react';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { WebAppState } from 'web/state/store.web';
import { WorkListType, IWorkListSetting, IDepartureWorkListSetting, DepartureWorkListSetting } from 'shared/api/api';
import { SaveWorkListSetting } from 'shared/state/ducks/work-list-settings/operations';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { localized } from 'shared/state/i18n/i18n';
import { SkioldFormsWrapper } from '../../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { deepCopy } from 'shared/helpers/general-helpers';
import { FormRow } from '../../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { getSkioldInputRow, getAvailablityRow } from '../work-list-helper';
import { SkioldButton } from '../../skiold-components/skiold-button/skiold-button';
import '../list-setup.scss';

interface PropsFromParent {
	workListType: WorkListType;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		departureWorkListSetting: state.workListSettings.entities.find(wl => wl.type === WorkListType.DepartureWorkListSetting),
		siteId: state.profile.active!.siteId
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	saveWorkListSetting: (departureWorkListSetting: IDepartureWorkListSetting) => SaveWorkListSetting(departureWorkListSetting)(dispatch)
});

export interface State {
	departureWorkListSetting: IDepartureWorkListSetting;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

class DepartureWorkListSetup extends React.PureComponent<Props, State> {
	public static getDerivedStateFromProps(nextProps: Props, prevState: State): Partial<State> {
		if (!prevState.departureWorkListSetting.id && nextProps.departureWorkListSetting) {
			return {
				departureWorkListSetting: { ...prevState.departureWorkListSetting, ...nextProps.departureWorkListSetting }
			};
		}
		return {};
	}
	constructor(props: Props) {
		super(props);
		this.state = {
			departureWorkListSetting: DepartureWorkListSetting.fromJS({ type: WorkListType.DepartureWorkListSetting })
		};
	}

	public toggleShowOnWeb() {
		this.setState(prevState => ({
			departureWorkListSetting: {
				...prevState.departureWorkListSetting,
				showOnWeb: !this.state.departureWorkListSetting.showOnWeb
			}
		}));
	}

	public toggleShowOnApp() {
		this.setState(prevState => ({
			departureWorkListSetting: {
				...prevState.departureWorkListSetting,
				showOnApp: !this.state.departureWorkListSetting.showOnApp
			}
		}));
	}

	public nameChanged(setup: IWorkListSetting) {
		this.setState({
			departureWorkListSetting: setup
		});
	}

	public render() {
		return (
			<ViewWeb className="list-setup">
				<ViewWeb className="view-container">
					<TextWeb className="text-style">{localized('Setup')}</TextWeb>
					<SkioldFormsWrapper formRows={this.getFormRows()} containerClassName="wrapper-container" />

					{this.renderButtons()}
				</ViewWeb>
			</ViewWeb>
		);
	}

	private async save() {
		if (!this.validate(this.state.departureWorkListSetting)) {
			return;
		}

		let farrowingListSetup = deepCopy(this.state.departureWorkListSetting);
		farrowingListSetup.siteId = this.props.siteId;

		this.props.saveWorkListSetting(farrowingListSetup);
	}

	private getFormRows() {
		let formRows = new Array<FormRow>();

		formRows.push(this.getBuildingHeader());
		formRows.push(
			getSkioldInputRow(
				setup => this.nameChanged(setup),
				this.state.departureWorkListSetting,
				'name',
				localized('name')
			)
		);
		formRows.push(
			getAvailablityRow(
				() => this.toggleShowOnWeb(),
				() => this.toggleShowOnApp(),
				this.state.departureWorkListSetting.showOnWeb!,
				this.state.departureWorkListSetting.showOnApp!
			)
		);

		return formRows;
	}

	private validate(listSetting: IDepartureWorkListSetting) {
		return true;
	}

	private getBuildingHeader(): FormRow {
		return {
			header: localized(this.props.workListType)
		};
	}

	private renderButtons() {
		let buttons = (
			<ViewWeb className="view-button-container">
				<SkioldButton title={localized('Save')} onPress={() => this.save()} />
			</ViewWeb>
		);

		return buttons;
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DepartureWorkListSetup);
