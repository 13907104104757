import React, { FC } from 'react';
import { AnimalType, ESFReader, Equipment } from 'shared/api/api';
import { localized } from 'shared/state/i18n/i18n';
import { SkioldCheckbox } from 'web/view/components/skiold-components/skiold-checkbox/skiold-checkbox';
import { SkioldFormsWrapper } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { useGeneralSetting } from './general-settings-overview';
import { useSelector } from 'react-redux';
import { SharedAppState } from 'shared/state/store.shared';
import { SkioldFormDropdown } from 'web/view/components/skiold-components/skiold-dropdown/skiold-form-dropdown';
import { ESFReaderOptions } from 'shared/helpers/work-list-helpers/esf-list-helpers/generel-esf-list-helper';
import { Option } from 'react-dropdown';

interface Props {
	animalType: AnimalType;
}

export const GeneralSettingsCommunication: FC<Props> = React.memo(({ animalType }) => {
	const { generalSetting, setGeneralSetting } = useGeneralSetting();
	const hasDistriwin = useSelector((state: SharedAppState) =>
		state.processEquipments.entities.find(e => e.equipment === Equipment.Distriwin),
	);
	const hasEsf = useSelector((state: SharedAppState) =>
		state.processEquipments.entities.find(e => e.equipment === Equipment.ESF),
	);
	const getFormRows = () => {
		let formRows = new Array<FormRow>();
		formRows.push(getWorkListHeader());
		if (hasDistriwin) {
			formRows.push(getFetchDistriwinFeeding());
		}
		if (hasEsf && animalType === AnimalType.Sow) {
			formRows.push(getSendCycleDaysAsNegative());
			formRows.push(getReaderRow());
		}

		formRows.push(getShareDataWithSegesInsight());

		return formRows;
	};
	const getWorkListHeader = (): FormRow => {
		return {
			header: localized('Communication'),
		};
	};

	const getFetchDistriwinFeeding = (): FormRow => {
		return {
			name: localized('FetchDistriwinFeedingData'),
			component: (
				<ViewWeb className="two-checkbox-container">
					<SkioldCheckbox
						onClick={SetFetchFeedingDataTrue}
						isChecked={generalSetting.fetchDistriwinFeeding}
					/>
					<TextWeb>{localized('True')}</TextWeb>
					<SkioldCheckbox
						onClick={SetFetchFeedingDataFalse}
						isChecked={!generalSetting.fetchDistriwinFeeding}
					/>
					<TextWeb>{localized('False')}</TextWeb>
				</ViewWeb>
			),
		};
	};

	const getSendCycleDaysAsNegative = (): FormRow => {
		return {
			name: localized('SendCycleDaysAsNegative'),
			component: (
				<ViewWeb className="two-checkbox-container">
					<SkioldCheckbox
						onClick={SetSendCycleDaysAsNegativeTrue}
						isChecked={generalSetting.sendCycleDaysAsNegative}
					/>
					<TextWeb>{localized('True')}</TextWeb>
					<SkioldCheckbox
						onClick={SendCycleDaysAsNegativeFalse}
						isChecked={!generalSetting.sendCycleDaysAsNegative}
					/>
					<TextWeb>{localized('False')}</TextWeb>
				</ViewWeb>
			),
		};
	};

	const getShareDataWithSegesInsight = (): FormRow => {
		return {
			name: localized('ShareDataWithSegesInsight'),
			component: (
				<ViewWeb className="two-checkbox-container">
					<SkioldCheckbox
						onClick={ShareSegesInSightTrue}
						isChecked={generalSetting.segesInsightConsent}
					/>
					<TextWeb>{localized('True')}</TextWeb>
					<SkioldCheckbox
						onClick={ShareSegesInSightFalse}
						isChecked={!generalSetting.segesInsightConsent}
					/>
					<TextWeb>{localized('False')}</TextWeb>
				</ViewWeb>
			),
		};
	};

	const getReaderRow = (): FormRow => {
		return {
			name: localized('Reader'),
			component: (
				<SkioldFormDropdown
					items={ESFReaderOptions}
					selectedValue={{
						value: generalSetting.reader ?? '',
						label: localized(generalSetting.reader ?? ''),
					}}
					onValueChanged={SetReader}
				/>
			),
		};
	};

	const SetFetchFeedingDataTrue = () => {
		setGeneralSetting({ ...generalSetting, fetchDistriwinFeeding: true });
	};

	const SetFetchFeedingDataFalse = () => {
		setGeneralSetting({ ...generalSetting, fetchDistriwinFeeding: false });
	};

	const SetSendCycleDaysAsNegativeTrue = () => {
		setGeneralSetting({ ...generalSetting, sendCycleDaysAsNegative: true });
	};

	const SendCycleDaysAsNegativeFalse = () => {
		setGeneralSetting({ ...generalSetting, sendCycleDaysAsNegative: false });
	};

	const ShareSegesInSightTrue = () => {
		setGeneralSetting({ ...generalSetting, segesInsightConsent: true });
	};

	const ShareSegesInSightFalse = () => {
		setGeneralSetting({ ...generalSetting, segesInsightConsent: false });
	};

	const SetReader = (option: Option) => {
		setGeneralSetting({
			...generalSetting,
			reader: option.value !== ' ' ? (option.value as ESFReader) : undefined,
		});
	};

	return <SkioldFormsWrapper formRows={getFormRows()} containerClassName="wrapper-container" />;
});
