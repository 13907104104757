import { IStemAnimal, Gender } from "shared/api/api";
import { findActiveAnimal } from "shared/helpers/stemanimal-helper/stem-animal-input-helper";
import { StoreContainer } from "shared/state/store-container";

function findSow(animalNumber: string): IStemAnimal | undefined {
    return findActiveAnimal(animalNumber, Gender.Female);
}

function getMatingBatchNumber(date: Date) {
    if (!date) {
        return;
    }
    let dateCopy = new Date(date);
    const state = StoreContainer.getState();
    //Makes sure time of the day isn't a factor
    dateCopy.setHours(0, 0, 0, 0);
    const matingBatch = state.matingBatches.entities.find(
        x => x.matingPeriodStart! <= dateCopy && dateCopy <= x.matingPeriodEnd!
    );
    const batchNumber = matingBatch ? matingBatch.batchNumber + '' : '';
    return batchNumber;
}

const exportFunctions = {
    findSow,
    getMatingBatchNumber
};

export default exportFunctions;
