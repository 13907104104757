import { default as ObjectID } from 'bson-objectid';
import { Dispatch } from 'redux';
import { IMatingBatchSetting, MatingBatchSetting } from 'shared/api/api';
import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import { StoreContainer } from 'shared/state/store-container';
import * as Action from './actions';

export function SaveMatingBatchSetting(matingBatchSetting: IMatingBatchSetting) {
	const state = StoreContainer.getState();
	const prevEntity = state.matingBatchSetting.currentMatingBatchSetting;
	if (!matingBatchSetting.id) {
		matingBatchSetting.id = new ObjectID().toHexString();
	}

	return AsyncOperationBuilder2(
		Action.saveMatingBatchSetting,
		client => client.matingBatchSetting_Upsert(MatingBatchSetting.fromJS(matingBatchSetting)),
		MatingBatchSetting.fromJS(matingBatchSetting),
		prevEntity,
	);
}

export function GetSyncData() {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;
	const lastSyncDate = state.matingBatchSetting.lastSyncDate;
	if (state.matingBatchSetting.syncInProgress) {
		return (dispatch: Dispatch<any>) => {
			return Promise.resolve();
		};
	}
	return AsyncOperationBuilder2(Action.getSyncData, client => client.matingBatchSetting_Sync(siteId, lastSyncDate), {
		siteId,
		lastSyncDate,
	});
}

export function SaveSyncData() {
	const state = StoreContainer.getState();
	const updates = state.matingBatchSetting.updates;
	let promises = new Array<Promise<void>>();

	return (dispatch: Dispatch<any>) => {
		updates.forEach(update => {
			promises.push(SaveMatingBatchSetting(update)(dispatch));
		});

		return Promise.all(promises);
	};
}
