import React from 'react';
import isEqual from 'react-fast-compare';
import { connect } from 'react-redux';
import { Averted, LocationType, WeaningListTypes } from 'shared/api/api';
import { deepCopy, exactStartsWithMethodGrid, rangeFilterMethodGrid } from 'shared/helpers/general-helpers';
import { NaturalSort } from 'shared/helpers/natural-sort';
import { RefType } from 'shared/helpers/ref-type';
import { checkSavePregnancyEventListsStatus } from 'shared/helpers/work-list-helpers/general-work-list-helper';
import { checkSectionWorkLists } from 'shared/helpers/work-list-helpers/move-to-helpers/moving-lists-helper';
import { localized, localizedDynamic } from 'shared/state/i18n/i18n';
import { SharedAppState } from 'shared/state/store.shared';
import 'web/view/components/skiold-components/skiold-table/skiold-double-header-table.scss';
import SkioldTableGrid, {
	SkioldTableGrid as SkioldTableRef,
} from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { getWebAnimalColorStyleTable } from 'web/web-helpers/general-web-helpers';
import { createAndEditValidation } from 'web/web-helpers/pregnancies/register-validation-helper';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import {
	setRealTotalWeight,
	WeaningWorkListItemData,
	WeaningWorkListMapDispatchToProps,
	WeaningWorkListMapStateToProps,
	WeaningWorkListProps,
	WeaningWorkListState,
} from '../../../../../shared/helpers/work-list-helpers/weaning-list-helpers/weaning-work-list-item';
import SkioldFormPenPicker from '../../location/location-picker/skiold-form-pen-picker';
import SkioldStableSectionPicker from '../../location/location-picker/skiold-stable-section-picker';
import { showAlert, ShowConfirmAlert } from '../../skiold-alert/skiold-alert';
import { SkioldCheckbox } from '../../skiold-components/skiold-checkbox/skiold-checkbox';
import { SkioldDatePicker } from '../../skiold-components/skiold-date-picker/skiold-date-picker';
import { SkioldBoldDecimalInput } from '../../skiold-components/skiold-decimal-input/skiold-bold-decimal-input';
import { SkioldDecimalInput } from '../../skiold-components/skiold-decimal-input/skiold-decimal-input';
import { SkioldBoldIntegerInput } from '../../skiold-components/skiold-integer-input/skiold-bold-integer-input';
import { GroupedHeader } from '../../skiold-components/skiold-table/skiold-table-grid/skiold-table-grid-grouped-header';
import { WhiteText } from '../../Text/white-text';
import '../list-setup.scss';
import { genereateWorklistData } from './weaning-list-helper';
import './weaning-work-list.scss';
import { LocationModel } from 'shared/helpers/location-helper';
import { SowListConstants } from '../../stem-animal/animal-lists/table-constants';
import { Sorting } from '@devexpress/dx-react-grid';

export class WeaningWorkListTable extends React.Component<WeaningWorkListProps, WeaningWorkListState> {
	public static getDerivedStateFromProps(nextProps: WeaningWorkListProps, prevState: WeaningWorkListState) {
		let data = genereateWorklistData(nextProps, prevState).sort((a, b) =>
			NaturalSort(b.listItemData.cycleDays, a.listItemData.cycleDays),
		);
		if (!isEqual(data.length, prevState.workListData.length)) {
			nextProps.setSowsCount(data.length);
			nextProps.SetCheckedCount(0, true);
		}
		if (!isEqual(data, prevState.workListData)) {
			return {
				workListData: data,
				defaultWeight: prevState.defaultWeight ? prevState.defaultWeight : nextProps.defaultWeightWeaning,
			};
		}
		if (prevState.defaultWeight === undefined && nextProps.defaultWeightWeaning) {
			return {
				defaultWeight: nextProps.defaultWeightWeaning,
			};
		}
		return {};
	}

	public SkioldTableRef: SkioldTableRef | undefined;
	private groupedColumns: GroupedHeader[] = [
		{
			title: localized('Weaning'),
			children: [
				{ columnName: 'weaningDate' },
				{ columnName: 'kgs' },
				{ columnName: 'pcs' },
				{ columnName: 'IsNursingSow' },
			],
		},
	];
	constructor(props: WeaningWorkListProps) {
		super(props);
		let locations = new LocationModel(this.props.locations);
		locations.sections = this.props.locations.sections.filter(a => a.type === LocationType.Farrowing);
		this.state = {
			countChecked: 0,
			columnExte: this.generateColumnsExtensions(),
			loading: false,
			columns: this.generateColumns(),
			commitAll: false,
			workListData: genereateWorklistData(props, this.state).sort((a, b) =>
				NaturalSort(b.listItemData.cycleDays, a.listItemData.cycleDays),
			),
			defaultWeight: this.props.defaultWeightWeaning,
			fromSectionId: undefined,
			fromPenId: undefined,
			fromLocation: locations,
			fromSectionUsesPens: false,
			countNumAlive: undefined,
		};
	}

	public ResetWorkList() {
		let data = genereateWorklistData(this.props, undefined);
		this.setState({ workListData: data, defaultWeight: undefined });
	}

	public generateColumnsExtensions() {
		return [
			{
				columnName: 'cycleDays',
				width: SowListConstants.cycleDaysWidth,
			},
			{
				columnName: 'animalNumber',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'weaningDate',
				filteringEnabled: false,
				width: SowListConstants.entranceDateWidth,
			},
			{
				columnName: 'kgs',
				filteringEnabled: false,
				width: SowListConstants.priceWidth,
			},
			{
				columnName: 'pcs',
				filteringEnabled: false,
				width: SowListConstants.priceWidth,
			},
			{
				columnName: 'IsNursingSow',
				filteringEnabled: false,
				width: SowListConstants.priceWidth,
			},
			{
				columnName: 'completed',
				width: SowListConstants.entranceDateWidth,
			},
		];
	}

	public componentDidUpdate(prevProps) {
		// Typical usage (don't forget to compare props):
		if (this.props.defaultDate !== prevProps.defaultDate) {
			let workListDataCopy = deepCopy(this.state.workListData);
			workListDataCopy.forEach(item => {
				item.workListData!.Weaning.date = this.props.defaultDate;
			});
			this.setState({ workListData: workListDataCopy });
		}
	}

	public componentDidMount() {
		if (this.SkioldTableRef) {
			this.props.setSowsCount(this.SkioldTableRef.GetSortedData().length);
		}

		this.props.getWorkListSettingsBySiteId();
		this.props.matingBatchGetSyncData();
		this.props.pregnancyEventGetSyncData();
		this.props.stemAnimalGetSyncData();
		this.props.validationSetupGetSyncData();
		this.props.getLocations();
	}

	public async CheckSavePregnancyEventListsStatus() {
		if (await checkSavePregnancyEventListsStatus(this.state.workListData, ShowConfirmAlert)) {
			await this.SaveWorklist();
		}
		return true;
	}

	public shouldComponentUpdate(nextProps: WeaningWorkListProps, nextState: WeaningWorkListState) {
		const stemAnimalsEqual = isEqual(this.props.stemAnimals, nextProps.stemAnimals);
		const workListSettingEqual = isEqual(this.props.workListSetting, nextProps.workListSetting);
		const pregnancyEventsEqual = isEqual(this.props.pregnancyEvents, nextProps.pregnancyEvents);
		const matingBatchesEqual = isEqual(this.props.matingBatches, nextProps.matingBatches);
		const validationSetupEqual = isEqual(this.props.validationSetup, nextProps.validationSetup);
		const workListDataEqual = isEqual(this.state.workListData, nextState.workListData);
		const fromSectionUsesPensEqual = isEqual(this.state.fromSectionUsesPens, nextState.fromSectionUsesPens);
		const fromSectionChanged = isEqual(this.state.fromSectionId, nextState.fromSectionId);
		if (
			!stemAnimalsEqual ||
			!workListSettingEqual ||
			!pregnancyEventsEqual ||
			!matingBatchesEqual ||
			!validationSetupEqual ||
			!workListDataEqual ||
			!fromSectionUsesPensEqual ||
			!fromSectionChanged ||
			this.props.defaultDate !== nextProps.defaultDate
		) {
			return true;
		}

		return false;
	}

	private defaultSortHeaderId = [{ columnName: 'cycleDays', direction: 'desc' }] as Sorting[];

	public render() {
		return (
			<ViewWeb>
				{this.renderTop()}
				<SkioldTableGrid
					tableKey={
						this.props.workListSetting.type
							? this.props.workListSetting.type.toString()
							: 'weaningWorkListTable'
					}
					columns={this.state.columns}
					ColumnExtensions={this.state.columnExte}
					className={'work-list-table'}
					data={this.state.workListData}
					ref={this.setRef}
					sortHeaderId={this.defaultSortHeaderId}
					showPagination={true}
					groupedColumns={this.groupedColumns}
				/>
			</ViewWeb>
		);
	}
	private setRef = (m: any) => (m ? (this.SkioldTableRef = m) : {});
	private defaultWeightTimeOutId: any | undefined = undefined;

	public defaultWeightChanged = (defaultWeight: number | undefined) => {
		if (this.defaultWeightTimeOutId) {
			clearTimeout(this.defaultWeightTimeOutId);
		}
		this.setState({ defaultWeight: defaultWeight });
		this.defaultWeightTimeOutId = setTimeout(() => {
			let dataArrayCopy = deepCopy(this.state.workListData);
			for (let index = 0; index < dataArrayCopy.length; index++) {
				let dataCopy = { ...dataArrayCopy[index] };
				let workListData = { ...dataCopy.workListData! };
				let weaning = { ...workListData.Weaning! };
				weaning.totalWeight = defaultWeight;
				workListData.Weaning = weaning;
				dataCopy.workListData = workListData;
				dataArrayCopy[index] = dataCopy;
			}
			this.setState({ workListData: dataArrayCopy });
		}, 500);
	};

	public renderTop = () => {
		return (
			<ViewWeb className="work-list">
				<ViewWeb className="view-container">
					<ViewWeb className="z-index-1000">
						<ViewWeb className="flex-direction-row flex-one getrows">
							<WhiteText className={'default-weight-label'}>{localized('defaultWeight')}: </WhiteText>
							<ViewWeb className={'underline'}>
								<SkioldDecimalInput
									className="default-input-text"
									onChangeNumber={this.defaultWeightChanged}
									text={this.state.defaultWeight}
								/>
							</ViewWeb>
							<WhiteText className={'default-count-label'}>{localized('Count')}: </WhiteText>
							<WhiteText className={'default-count-label'}>
								{this.state.countNumAlive ? this.state.countNumAlive : ''}{' '}
							</WhiteText>
							{this.props.workListSetting.selectedType === WeaningListTypes.Location && (
								<ViewWeb className="flex-direction-row flex-one margin-left-20">
									<ViewWeb className="flex-direction-row flex-one">
										<ViewWeb className="buttons-no-right-border">
											<TextWeb className="stable-section-text">
												{localized('StableSection')}:
											</TextWeb>
											<SkioldStableSectionPicker
												onStableSectionSelected={this.fromSelectedSectionChanged}
												sectionId={this.state.fromSectionId}
												filteredLocations={this.state.fromLocation}
												isPigProduction={true}
												containerClassName="picker-width"
											/>
										</ViewWeb>
										{this.state.fromSectionUsesPens && (
											<ViewWeb className="buttons-no-right-border">
												<TextWeb className="pen-text">{localized('Pen')}:</TextWeb>
												<SkioldFormPenPicker
													onPenSelected={this.fromSelectedPenChanged}
													filteredLocations={this.state.fromLocation}
													sectionId={this.state.fromSectionId}
													penId={this.state.fromPenId}
													theme="light"
													containerClassName="picker-width"
													usedInsideForm={false}
												/>
											</ViewWeb>
										)}
									</ViewWeb>
								</ViewWeb>
							)}
						</ViewWeb>
					</ViewWeb>
				</ViewWeb>
			</ViewWeb>
		);
	};

	private fromSelectedSectionChanged = (selectedSectionId: string) => {
		let { penId, usesPens } = checkSectionWorkLists(selectedSectionId, this.state.fromPenId);
		if (this.state.fromSectionId !== selectedSectionId) {
			const workListData = genereateWorklistData(this.props, {
				...this.state,
				fromSectionId: selectedSectionId,
				fromPenId: undefined,
				fromSectionUsesPens: usesPens,
			});
			this.setState({
				fromSectionId: selectedSectionId,
				fromPenId: undefined,
				fromSectionUsesPens: usesPens,
				workListData,
			});
		} else {
			this.setState({
				fromSectionId: selectedSectionId,
				fromPenId: penId,
				fromSectionUsesPens: usesPens,
			});
		}
	};
	private fromSelectedPenChanged = (selectedPenId: string) => {
		if (this.state.fromPenId !== selectedPenId) {
			const workListData = genereateWorklistData(this.props, { ...this.state, fromPenId: selectedPenId });
			this.setState({
				fromPenId: selectedPenId,
				workListData,
			});
		} else {
			this.setState({
				fromPenId: selectedPenId,
			});
		}
	};

	public SaveWorklist = async () => {
		let pregnacies = [...this.state.workListData];
		let listOfPromises: Array<Promise<void>> = [];

		this.state.workListData.forEach(async element => {
			if (element.workListData && element.workListData.isChecked) {
				listOfPromises.push(this.SaveWeaning(element));
				pregnacies = pregnacies.filter(
					a => a.workListData!.stemAnimalId !== element.workListData!.stemAnimalId,
				);
			}
		});

		await Promise.all(listOfPromises);

		this.setState({ workListData: pregnacies, commitAll: false });
	};

	private generateCommitAllCheckBox = () => {
		return (
			<ViewWeb className="checkbox-view-filter-style">
				<SkioldCheckbox
					isChecked={this.state.commitAll}
					className="checkbox-style"
					onClick={this.CommitAllClicked}
				/>
			</ViewWeb>
		);
	};

	private getStemAnimalNumberColor = (sow: WeaningWorkListItemData) => {
		return sow.listItemData && sow.listItemData.stemAnimalId
			? getWebAnimalColorStyleTable(sow.listItemData.stemAnimalId)
			: '';
	};

	private getAnimalNumberText = (tableItem: WeaningWorkListItemData) =>
		tableItem.listItemData.isNursingSow ? (
			<ViewWeb className="flex-row-space-evenly">
				<ViewWeb>{tableItem.listItemData.animalNumber}</ViewWeb>
				<ViewWeb className="bold-label-text-filter-red">A</ViewWeb>
			</ViewWeb>
		) : (
			tableItem.listItemData.animalNumber
		);

	private animalNumberFilter = (value: any, filterValue: any, row: WeaningWorkListItemData) => {
		return (
			row.listItemData.animalNumber &&
			String(localized(row.listItemData.animalNumber).toLocaleLowerCase()).startsWith(filterValue.value.toLocaleLowerCase())
		);
	};

	private generateColumns() {
		return [
			{
				name: 'cycleDays',
				title: localized('Days'),
				headerClassName: 'merged-header',
				pathToValue: 'listItemData',
				filterFunction: rangeFilterMethodGrid,
				getCellValue: (d: WeaningWorkListItemData) => d.listItemData.cycleDays,
			},
			{
				name: 'animalNumber',
				headerClassName: 'merged-header',
				title: localized('animalNumber'),
				pathToValue: 'listItemData',
				className: this.getStemAnimalNumberColor,
				filterFunction: this.animalNumberFilter,
				getCellValue: this.getAnimalNumberText,
			},
			{
				name: 'weaningDate',
				title: localized('Date'),
				className: 'overflow-visible',
				sortable: false,
				filterable: false,
				getCellValue: (card: WeaningWorkListItemData) => (
					<SkioldDatePicker
						onDateChanged={newDate => {
							let dataArrayCopy = [...this.state.workListData];
							let index = dataArrayCopy.findIndex(
								b => b.listItemData.stemAnimalId === card.listItemData.stemAnimalId,
							);
							let dataCopy = { ...dataArrayCopy[index] };
							let workListData = { ...dataArrayCopy[index].workListData! };
							let weaning = { ...workListData.Weaning! };
							weaning.date = newDate;
							workListData.Weaning = weaning;
							dataCopy.workListData = workListData;
							dataArrayCopy[index] = dataCopy;

							this.setState({ workListData: dataArrayCopy });
						}}
						selectedDate={card.workListData!.Weaning.date}
						theme={'dark'}
						color={'grey'}
						maxDate={this.allowedWeaningDate()}
					/>
				),
			},
			{
				name: 'pcs',
				title: localized('Pcs'),
				sortable: false,
				filterable: false,
				getCellValue: this.generatePcsCell,
			},
			{
				name: 'kgs',
				title: localized('kilo'),
				sortable: false,
				filterable: false,
				className: 'overflow-visible',
				getCellValue: (card: WeaningWorkListItemData) => (
					<SkioldBoldDecimalInput
						text={card.workListData!.Weaning.totalWeight}
						onChangeNumber={number => {
							let dataArrayCopy = [...this.state.workListData];
							let index = dataArrayCopy.findIndex(
								b => b.listItemData.stemAnimalId === card.listItemData.stemAnimalId,
							);
							let dataCopy = { ...dataArrayCopy[index] };
							let workListData = { ...dataArrayCopy[index].workListData! };
							let weaning = { ...workListData.Weaning! };
							weaning.totalWeight = number;
							workListData.Weaning = weaning;
							dataCopy.workListData = workListData;
							dataArrayCopy[index] = dataCopy;

							this.setState({ workListData: dataArrayCopy });
						}}
						className="text-input-style"
					/>
				),
			},
			{
				name: 'IsNursingSow',
				title: localized('nursSow'),
				className: 'overflow-visible',
				sortable: false,
				filterable: false,
				getCellValue: (d: WeaningWorkListItemData) => (
					<ViewWeb className="Checkbox-view-style">
						<SkioldCheckbox
							isChecked={d.workListData!.Weaning.isNursingSow!}
							className="checkbox-style"
							onClick={() => this.NursingSowClicked(d)}
						/>
					</ViewWeb>
				),
			},
			{
				name: 'completed',
				title: localized('completed'),
				headerClassName: 'merged-header',
				sortable: false,
				filter: this.generateCommitAllCheckBox,
				getCellValue: this.GenerateCompletedCell,
			},
		];
	}

	private generatePcsCell = (card: WeaningWorkListItemData) => (
		<SkioldBoldIntegerInput
			text={card.workListData!.Weaning.numAlive}
			itemFromParent={card}
			onChangeNumber={this.onPcsChanged}
			className="text-input-style"
		/>
	);

	private onPcsChanged = (number: number | undefined, card: WeaningWorkListItemData) => {
		let dataArrayCopy = [...this.state.workListData];
		let index = dataArrayCopy.findIndex(b => b.listItemData.stemAnimalId === card.listItemData.stemAnimalId);
		let dataCopy = { ...dataArrayCopy[index] };
		let workListData = { ...dataArrayCopy[index].workListData! };
		let weaning = { ...workListData.Weaning! };

		// Count and set current weaned
		let currentTotalCount = this.state.countNumAlive ? this.state.countNumAlive : 0;
		let count = weaning.numAlive;
		if (count !== undefined && workListData.isChecked) {
			if (number === undefined) {
				currentTotalCount -= count;
			} else if (count > number) {
				currentTotalCount -= count - number;
			} else {
				currentTotalCount += number - count;
			}
		} else if (number) {
			if (workListData.isChecked) {
				currentTotalCount += number;
			}
		}
		weaning.numAlive = number!;
		workListData.Weaning = weaning;
		dataCopy.workListData = workListData;
		dataArrayCopy[index] = dataCopy;

		this.setState({ workListData: dataArrayCopy, countNumAlive: currentTotalCount });
	};

	private GenerateCompletedCell = (d: WeaningWorkListItemData) => {
		return (
			<ViewWeb className="Checkbox-view-style">
				<SkioldCheckbox
					isChecked={d.workListData!.isChecked}
					className="checkbox-style"
					onClick={async () => {
						this.CommitedClicked(d);
					}}
				/>
			</ViewWeb>
		);
	};

	private CommitedClicked = async (d: WeaningWorkListItemData) => {
		if (!d.workListData!.isChecked) {
			let errorMessage = await createAndEditValidation(
				this.props.validationSetup,
				this.props.generalSettings,
				d.workListData!.Weaning,
				d.listItemData.prevEvent,
				d.listItemData.animalEvents,
			);
			if (errorMessage !== '') {
				if (errorMessage !== 'ignore') {
					showAlert(localizedDynamic(errorMessage));
				}
				return;
			}
		}
		let dataCopy = [...this.state.workListData];
		let index = dataCopy.findIndex(
			a => a.workListData!.Weaning.stemAnimalId === d.workListData!.Weaning.stemAnimalId,
		);
		let worklistData = Object.assign({}, dataCopy[index]);
		let worklistDataToChange = Object.assign({}, worklistData.workListData);
		worklistDataToChange.isChecked = !d.workListData!.isChecked;

		// Count and set current weaned pigs
		let currentTotalCount = this.state.countNumAlive ? this.state.countNumAlive : 0;
		if (worklistDataToChange.isChecked) {
			if (worklistDataToChange.Weaning.numAlive) {
				currentTotalCount += worklistDataToChange.Weaning.numAlive;
			}
		} else {
			if (worklistDataToChange.Weaning.numAlive) {
				currentTotalCount -= worklistDataToChange.Weaning.numAlive;
			}
		}
		this.props.SetCheckedCount(worklistDataToChange.isChecked ? 1 : -1);
		worklistData.workListData = worklistDataToChange;
		dataCopy[index] = worklistData;
		if (this.state.commitAll === true) {
			this.setState({ commitAll: false, workListData: dataCopy, countNumAlive: currentTotalCount });
		} else {
			this.setState({ workListData: dataCopy, countNumAlive: currentTotalCount });
		}
	};

	private NursingSowClicked = (d: WeaningWorkListItemData) => {
		let dataCopy = [...this.state.workListData];
		let index = dataCopy.findIndex(
			a => a.workListData!.Weaning.stemAnimalId === d.workListData!.Weaning.stemAnimalId,
		);
		let worklistData = Object.assign({}, dataCopy[index]);
		let workListDataCopy = { ...worklistData.workListData! };
		let weaningCopy = { ...workListDataCopy.Weaning };
		weaningCopy.isNursingSow = !weaningCopy.isNursingSow;

		workListDataCopy.Weaning = weaningCopy as Averted;
		worklistData.workListData = workListDataCopy;
		dataCopy[index] = worklistData;
		this.setState({ workListData: dataCopy });
	};

	private CommitAllClicked = async () => {
		let dataCopy = [...this.state.workListData];
		let commitAll = !this.state.commitAll;
		let data = this.SkioldTableRef!.GetSortedData();
		let countNumAlive = 0;
		for (const element of data) {
			if (commitAll) {
				const errorMessage = await createAndEditValidation(
					this.props.validationSetup,
					this.props.generalSettings,
					element.workListData!.Weaning,
					element.listItemData.prevEvent,
					element.listItemData.animalEvents,
				);
				if (errorMessage !== '') {
					continue;
				}
			}
			let dataIndex = dataCopy.findIndex(
				a => a.workListData!.Weaning.stemAnimalId! === element.workListData.Weaning.stemAnimalId!,
			);
			let worklistDataCopy = { ...dataCopy[dataIndex] };
			let workListData = { ...worklistDataCopy.workListData! };
			workListData.isChecked = commitAll;

			// Count and set current weaned pigs
			if (commitAll && workListData.Weaning && workListData.Weaning.numAlive) {
				countNumAlive += workListData.Weaning.numAlive;
			}
			worklistDataCopy.workListData = workListData;
			dataCopy[dataIndex] = worklistDataCopy;
		}

		this.props.SetCheckedCount(
			dataCopy.reduce((a, b) => a + (b.workListData && b.workListData.isChecked ? 1 : 0), 0),
			true,
		);

		this.setState({ workListData: dataCopy, commitAll, countNumAlive });
	};

	private async SaveWeaning(weaningWorkListItem: WeaningWorkListItemData) {
		if (weaningWorkListItem.workListData!.isChecked) {
			await this.props.savePregnancyEvent(setRealTotalWeight(weaningWorkListItem.workListData!.Weaning));
		}
	}

	private allowedWeaningDate = () => {
		const todaysDatePlus3 = new Date();
		todaysDatePlus3.setDate(todaysDatePlus3.getDate() + 3);
		return todaysDatePlus3;
	};
}

export default connect<
	ReturnType<typeof WeaningWorkListMapStateToProps>,
	ReturnType<typeof WeaningWorkListMapDispatchToProps>,
	RefType,
	SharedAppState
>(WeaningWorkListMapStateToProps, WeaningWorkListMapDispatchToProps, null, { forwardRef: true })(WeaningWorkListTable);
