import React from 'react';
import isEqual from 'react-fast-compare';
import { connect } from 'react-redux';
import { GrowthPigsEvent } from 'shared/api/api';
import {
	CheckIfDepartureGrowthPigEventIsBetweenTwoDates,
	defaultGrowthPigEventListDates,
	GenerateGrowthPigDepartureEventBundleRowItems,
	GrowthPigDepartureEventBundleListRow,
	GrowthPigDepartureEventListProps,
	GrowthPigDepartureEventListState,
	GrowthPigEventListDepartureMapDispatchToProps,
	GrowthPigEventListMapStateToProps,
} from 'shared/helpers/growth-pigs-helper/growth-pig-event-list-helper';
import { GetByGrowthPigDepartureEventsPdfByDates } from 'shared/helpers/growth-pigs-helper/growth-pig-event-pdf-helper';
import { DepartureTypes } from 'shared/state/models/departure-types';
import { SkioldFetch } from 'web/view/components/skiold-components/skiold-fetch/skiold-fetch';
import { SkioldModal } from 'web/view/components/skiold-components/skiold-modal/skiold-modal';
import { SkioldTableGrid as SkioldTableRef } from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { ViewWeb } from 'web/view/components/utils/web-view';
import GrowthPigEvents from 'web/view/pages/growth-pigs-events/growth-pig-event';
import { renderTopRightGrowthPigDepartureEventLists } from '../growth-pig-event-list-web-helper';
import { GpeDepartureDistributeWeight } from './growth-pig-departure-distribute-weight';
import { GrowthPigDepartureEventTable } from './growth-pig-departure-event-table';
import { GpeSoldSlaughterToEditModal } from './growth-pig-sold-slaughter-edit-modal';

export class GrowthPigDepartureEventList extends React.PureComponent<
	GrowthPigDepartureEventListProps,
	GrowthPigDepartureEventListState
> {
	private skioldTableRef?: SkioldTableRef = undefined;

	constructor(props: GrowthPigDepartureEventListProps) {
		super(props);

		const bundleData = GenerateGrowthPigDepartureEventBundleRowItems(
			this.props.growthPigsEvents.filter(this.filterToProductionType),
			this.props.locations,
			this.props.reasons,
			this.props.lang,
		);
		this.state = {
			bundleData,
			isDistributeWeightModalOpen: false,
			...defaultGrowthPigEventListDates(),
		};
	}

	private filterToProductionType = (gpe: GrowthPigsEvent) => gpe.fromProductionType === this.props.productionType;

	public componentDidUpdate(
		prevProps: GrowthPigDepartureEventListProps,
		prevState: GrowthPigDepartureEventListState,
	) {
		const bundleData = GenerateGrowthPigDepartureEventBundleRowItems(
			this.props.growthPigsEvents.filter(
				CheckIfDepartureGrowthPigEventIsBetweenTwoDates(this.props, this.props.productionType),
			),
			this.props.locations,
			this.props.reasons,
			this.props.lang,
		);
		if (
			!isEqual(prevState.bundleData, bundleData) ||
			!isEqual(prevProps.productionType, this.props.productionType)
		) {
			this.setState({
				bundleData,
			});
		}
		if (!isEqual(prevState.bundleData, this.state.bundleData)) {
			if (this.props.topRight && this.state.bundleData) {
				this.props.topRight(
					renderTopRightGrowthPigDepartureEventLists(
						this.state.bundleData,
						this.printData,
						'Pcs',
						this.openDisitributeWeightModal,
					),
				);
			}
		}
	}

	public render() {
		return (
			<ViewWeb>
				<GrowthPigDepartureEventTable
					onFilterChanged={this.onFilterChanged}
					setRef={this.setRef}
					bundleData={this.state.bundleData}
					openEditModal={this.openEditModal}
					removeItem={this.props.removeGrowthPigsEvent}
					usePrivateSlaughterhouse={this.props.usePrivateSlaughterhouse}
				/>
				<SkioldModal
					shouldCloseOnOverlayClick={true}
					padding="0"
					isOpen={!!this.state.bundleEvent}
					close={this.closeModal}
					justify-content="flex-end"
					max-width="calc(100% - 220px)"
				>
					{this.state.bundleEvent && this.showCorrectDepartureModal()}
				</SkioldModal>
				<SkioldModal
					shouldCloseOnOverlayClick={true}
					padding="0"
					isOpen={this.state.isDistributeWeightModalOpen}
					close={this.closeDisitributeWeightModal}
					justify-content="flex-end"
					max-width="calc(100% - 220px)"
				>
					<GpeDepartureDistributeWeight
						totalPigCount={this.state.pigCount ? this.state.pigCount : 0}
						totalPigWeight={this.state.totalWeight ? this.state.totalWeight : 0}
						growthPigsEventIds={this.state.gpeIds ? this.state.gpeIds : []}
						close={this.closeDisitributeWeightModal}
					/>
				</SkioldModal>
			</ViewWeb>
		);
	}

	private showCorrectDepartureModal = () => {
		return this.state.bundleEvent && !this.state.event ? (
			<GpeSoldSlaughterToEditModal closeModal={this.closeModal} bundle={this.state.bundleEvent} />
		) : (
			<GrowthPigEvents closeEditModal={this.closeModal} event={this.state.event} />
		);
	};

	private openEditModal = (event: GrowthPigDepartureEventBundleListRow) => {
		let gpeEvent;

		if (!DepartureTypes.IsSoldOrSlaughted(event.departuredType)) {
			gpeEvent = this.props.growthPigsEvents.find(gpe => gpe.bundleIdentifier === event.bundleId);
		}
		if (event && !event.bundleId && event.gpeIds.length === 1) {
			gpeEvent = this.props.growthPigsEvents.find(gpe => gpe.id === event.gpeIds[0]);
		}

		this.setState({
			bundleEvent: this.state.bundleData && this.state.bundleData.find(gpe => gpe.bundleId === event.bundleId),
			event: gpeEvent,
		});
	};

	private closeModal = () => {
		this.setState({ bundleEvent: undefined, event: undefined });
	};

	private closeDisitributeWeightModal = () => {
		this.setState({ isDistributeWeightModalOpen: false });
	};

	private openDisitributeWeightModal = () => {
		// Get select departure gpe events
		const data = this.skioldTableRef
			? (this.skioldTableRef.GetSortedData() as GrowthPigDepartureEventBundleListRow[])
			: this.state.bundleData
			? this.state.bundleData
			: [];

		// filter away slaguthered events, because slaguther
		const gpeDataNoSlaughterPigs = data.filter(
			e =>
				(e.departuredType && e.departuredType !== DepartureTypes.departureTypeKilled) ||
				this.props.usePrivateSlaughterhouse,
		);
		let gpeIds: string[] = [];
		let pigCount = 0;
		let totalWeight = 0;
		gpeDataNoSlaughterPigs.forEach(g => {
			pigCount += g.pcs ? g.pcs : 0;
			totalWeight += g.avgWeight && g.pcs ? g.avgWeight * g.pcs : 0;
			gpeIds = gpeIds.concat(g.gpeIds);
		});

		this.setState({ isDistributeWeightModalOpen: true, pigCount, gpeIds, totalWeight });
	};

	private setRef = ref => {
		this.skioldTableRef = ref;
	};

	private onFilterChanged = () => {
		const data = this.skioldTableRef
			? (this.skioldTableRef.GetSortedData() as GrowthPigDepartureEventBundleListRow[])
			: this.state.bundleData
			? this.state.bundleData
			: [];
		if (this.props.topRight) {
			this.props.topRight(
				renderTopRightGrowthPigDepartureEventLists(
					data,
					this.printData,
					'Pcs',
					this.openDisitributeWeightModal,
					this.props.usePrivateSlaughterhouse,
				),
			);
		}
	};

	public componentDidMount() {
		if (this.props.topRight && this.props.topLeft && this.state.bundleData) {
			this.props.topRight(
				renderTopRightGrowthPigDepartureEventLists(
					this.state.bundleData,
					this.printData,
					'Pcs',
					this.openDisitributeWeightModal,
					this.props.usePrivateSlaughterhouse,
				),
			);
			this.props.topLeft(this.renderTopLeft());
		}
	}

	public renderTopLeft = () => {
		return <SkioldFetch toDate={this.state.dateTo} fromDate={this.state.dateFrom} fetchData={this.fetchData} />;
	};

	private printData = async () => {
		if (this.props.profile) {
			await GetByGrowthPigDepartureEventsPdfByDates(
				this.skioldTableRef
					? (this.skioldTableRef.GetSortedData() as GrowthPigDepartureEventBundleListRow[])
							.map(row => row.gpeIds!)
							.reduce((totalIds, ids) => (totalIds ? totalIds.concat(ids) : ids))
					: [],
				'GrowthPigDepartureEvents.pdf',
				this.props.profile.siteId!,
				this.props.productionType,
				Intl.DateTimeFormat().resolvedOptions().timeZone,
				this.props.profile.language,
			);
		}
	};
	private fetchData = (dateFrom: Date, dateTo: Date) => {
		this.setState({ dateFrom: dateFrom, dateTo: dateTo });
		this.props.getGrowthPigsDepartureEventsByDates(dateFrom, dateTo);
	};
}

export default connect(
	GrowthPigEventListMapStateToProps,
	GrowthPigEventListDepartureMapDispatchToProps,
)(GrowthPigDepartureEventList);
