import React from 'react';
import { connect } from 'react-redux';
import { PeriodDto, ResultAnalysisFarrowingsTableItem } from 'shared/api/api';
import { getDateString } from 'shared/helpers/date-helpers';
import { RefType } from 'shared/helpers/ref-type';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { getWebAnimalColorStyleDashboard } from 'web/view/components/dashboard/dashboard-helper';
import SkioldTableGrid, {
	SkioldTableGrid as SkioldTableRef,
} from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { SowListConstants } from 'web/view/components/stem-animal/animal-lists/table-constants';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { GetAnalysisFarrowingsPdf } from 'web/web-helpers/pdf-helper/analysis-pdf-helpers/analysis-pdf-helper';
import {
	AnalysisResultPerLitterModalTableState,
	defaultSortingResultPerLitterModals,
	getAnalysisAnimalNumberCell,
	getAnalysisLitterNumberCell,
	getShowSowCardCell,
	getSkioldSowCardModal,
	initialAnalysisResultPerLitterModalsTableStates,
} from '../../analysis-helper';
import { AnalysisModalMode } from 'shared/state/ducks/analysis/analysis-modal/operation';
import { NaturalSortDates } from 'shared/helpers/natural-sort';

interface PropsFromParent {
	mode: AnalysisModalMode;
	data: ResultAnalysisFarrowingsTableItem[];
	onFilterChange: (data: ResultAnalysisFarrowingsTableItem[]) => void;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		profile: state.profile.active!,
	};
};

type Props = ReturnType<typeof mapStateToProps> & PropsFromParent;

export class AnalysisResultsAnalysisFarrowingsTable extends React.PureComponent<
	Props,
	AnalysisResultPerLitterModalTableState
> {
	constructor(props: Props) {
		super(props);
		this.state = initialAnalysisResultPerLitterModalsTableStates;
	}

	public render() {
		return (
			<ViewWeb className="analysis-modal-table ">
				<SkioldTableGrid
					sortHeaderId={defaultSortingResultPerLitterModals}
					onFiltersChanged={this.props.onFilterChange}
					usesSummaryRow={true}
					data={this.props.data}
					ref={this.setTableRef}
					columns={this.generateColumns}
					ColumnExtensions={this.getColumnExtenstions}
					tableKey={'analysisFarrowingResultPerLitterTable'}
				/>
				{getSkioldSowCardModal(this.state.sowCardModal, this.closeModal, this.state.stemAnimalId)}
			</ViewWeb>
		);
	}

	public printData = async (periodDto: PeriodDto) => {
		if (this.SkioldTableRef) {
			let data = this.SkioldTableRef.GetSortedData();
			GetAnalysisFarrowingsPdf(
				data,
				AnalysisModalMode[this.props.mode] + 'Farrowing.pdf',
				this.props.profile.siteId!,
				Intl.DateTimeFormat().resolvedOptions().timeZone,
				this.props.profile.language,
				periodDto,
			);
		}
	};

	public SkioldTableRef: SkioldTableRef | undefined;

	private setTableRef = (m: any) => (m ? (this.SkioldTableRef = m) : {});

	private getColumnExtenstions = [
		{
			columnName: 'sowCard',
			width: SowListConstants.iconWidth,
			filteringEnabled: false,
		},
		{
			columnName: 'animalNr',
			width: SowListConstants.dateWidth,
		},
		{
			columnName: 'litter',
			width: SowListConstants.iDNumberWidth,
		},
		{
			columnName: 'pregnantDays',
			width: SowListConstants.pregnantDaysDashboardWidth,
			usesSummary: true,
		},
		{
			columnName: 'farrowingDate',
			width: SowListConstants.iDNumberWidth,
		},
		{
			columnName: 'aliveBorn',
			width: SowListConstants.iDNumberWidth,
			allowZero: true,
			usesSummary: true,
		},
		{
			columnName: 'stillborn',
			width: SowListConstants.iDNumberWidth,
			allowZero: true,
			usesSummary: true,
		},
	];

	private readonly showSowCard = (stemAnimalId?: string) => {
		if (stemAnimalId) {
			this.setState({ sowCardModal: true, stemAnimalId: stemAnimalId });
		}
	};

	private readonly closeModal = () => {
		this.setState(initialAnalysisResultPerLitterModalsTableStates);
	};

	private readonly showSowCardCell = (item: ResultAnalysisFarrowingsTableItem) =>
		getShowSowCardCell(item, this.showSowCard);

	private readonly getPregnancyDaysCell = (item: ResultAnalysisFarrowingsTableItem) => item.pregnancyDays;

	private readonly getFarrowingDateCell = (item: ResultAnalysisFarrowingsTableItem) =>
		getDateString(item.farrowingDate);

	private readonly getAliveBornCell = (item: ResultAnalysisFarrowingsTableItem) => item.aliveBorn;

	private readonly getStillBornCell = (item: ResultAnalysisFarrowingsTableItem) => item.stillborn;

	private generateColumns = [
		{
			name: 'sowCard',
			title: ' ',
			Footer: localized('avg'),
			sortable: false,
			filterable: false,
			getCellValue: this.showSowCardCell,
		},
		{
			name: 'animalNr',
			title: localized('animalNr'),
			className: getWebAnimalColorStyleDashboard,
			getCellValue: getAnalysisAnimalNumberCell,
		},
		{
			name: 'litter',
			title: localized('LitterNr'),
			getCellValue: getAnalysisLitterNumberCell,
		},
		{
			name: 'pregnantDays',
			title: localized('PregnancyDays'),
			getCellValue: this.getPregnancyDaysCell,
		},
		{
			name: 'farrowingDate',
			title: localized('FarrowingDate'),
			getCellValue: this.getFarrowingDateCell,
			sortFunction: NaturalSortDates,
		},
		{
			name: 'aliveBorn',
			title: localized('AliveBorn'),
			getCellValue: this.getAliveBornCell,
		},
		{
			name: 'stillborn',
			title: localized('stillborn'),
			getCellValue: this.getStillBornCell,
		},
	];
}

export default connect<ReturnType<typeof mapStateToProps>, null, RefType, WebAppState>(mapStateToProps, null, null, {
	forwardRef: true,
})(AnalysisResultsAnalysisFarrowingsTable);
