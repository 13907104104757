import { Dispatch } from 'redux';
import { IMatingBatchReports, SkioldOneClient, ApiException } from 'shared/api/api';
import { SkioldDigitalApiBaseUrl } from 'shared/constants';
import * as Action from './actions';
import { StoreContainer } from 'shared/state/store-container';
import { getSiteId } from 'shared/helpers/reducer-helpers';
import { MatingBatchReportModalMode } from './types';
import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';

export function GetMatingBatchReportBySiteAndDate(date: Date, siteId: string) {
	return (dispatch: Dispatch<any>) => {
		dispatch(
			Action.getMatingBatchReportsBySiteAndDate.started({
				siteId
			}),
		);
		return new SkioldOneClient(SkioldDigitalApiBaseUrl).matingBatchReport_GetMatingBatchReport(date, siteId).then(
			(MatingBatchReports: IMatingBatchReports) => {
				const state = StoreContainer.getState();
				dispatch(
					Action.getMatingBatchReportsBySiteAndDate.done({
						params: { siteId, activeSiteId: getSiteId(state.profile.active) },
						result: MatingBatchReports,
					}),
				);
			},
			(reject: ApiException) => {
				dispatch(
					Action.getMatingBatchReportsBySiteAndDate.failed({
						params: { siteId },
						error: { code: reject.status, message: reject.response },
					}),
				);
			},
		);
	};
}

export function GetDashboardTableModels(siteId: string, date?: Date, mode?: MatingBatchReportModalMode) {
	let modeText = getModeText();
	return AsyncOperationBuilder2(
		Action.getDashboardTableModels,
		client => client.matingBatchReport_GetDashboardForMatingBatchReport(siteId, date, modeText),
		{ siteId, date, mode: modeText },
	);

	function getModeText() {
		switch (mode) {
			case MatingBatchReportModalMode.matings:
				return 'Matings';
			case MatingBatchReportModalMode.farrowings:
				return 'Farrowings';
			case MatingBatchReportModalMode.weanings:
				return 'Weanings';
			default:
				return '';
		}
	}
}
