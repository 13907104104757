import React from 'react';
import isEqual from 'react-fast-compare';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
	AnimalType,
	GrowthProductionReportSettingDiverseInformationKey,
	GrowthProductionReportSettingFeedKey,
	GrowthProductionReportSettingProductionKey,
	GrowthProductionReportSettingSlaughterDataKey,
	GrowthProductionReportSettingStateKey,
	GrowthProductionReportSettingSubResultKey,
	IProductionReportSetting,
} from 'shared/api/api';
import {
	GetSyncData as GetProductionReportSeting,
	SaveProductionReportSetting,
} from 'shared/state/ducks/production-report-settings/operations';
import { localized, localizedDynamic } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldCheckbox } from 'web/view/components/skiold-components/skiold-checkbox/skiold-checkbox';
import { SkioldFormsWrapper } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { initProductionReportSetting } from '../../stem-animal/animal-report-list/production-report/production-report.-helper';

interface PropsFromParent {
	animalType: AnimalType;
}

const mapStateToProps = (state: WebAppState, props: PropsFromParent) => {
	return {
		productionSetting: state.productionReportSetting.entities,
		sections: state.locations.sections,
		processes: state.profile.active && state.profile.active.processes,
		siteId: state.profile.active!.siteId,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	saveProductionReportSetting: (report: IProductionReportSetting) => SaveProductionReportSetting(report)(dispatch),
	getProductionReportSetting: () => GetProductionReportSeting()(dispatch),
});

export interface State {
	pReportSetting: IProductionReportSetting;
	productionType: AnimalType;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

export class PReportGrowthPigSetup extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		props.getProductionReportSetting();
		const setting = initProductionReportSetting(
			props.productionSetting.find(a => a.animaltype === props.animalType),
			props.siteId!,
			props.animalType,
		);
		this.state = {
			pReportSetting: setting,
			productionType: props.animalType,
		};
	}

	public componentDidUpdate(prevProps: Props) {
		if (!isEqual(prevProps.productionSetting, this.props.productionSetting) && this.props.productionSetting) {
			const setting = initProductionReportSetting(
				this.props.productionSetting.find(a => a.animaltype === this.props.animalType),
				this.props.siteId!,
				this.props.animalType,
			);
			this.setState({
				pReportSetting: setting,
			});
		}
	}

	public render() {
		return (
			<ViewWeb>
				<ViewWeb className="p-report-key-setup">
					<ViewWeb className="title-save-container">
						<SkioldButton className="button-container" title={localized('Save')} onPress={this.save} />
						<TextWeb className="setup-text-label">{localized('Setup')}</TextWeb>
					</ViewWeb>

					<this.renderForm />
				</ViewWeb>
			</ViewWeb>
		);
	}

	// all key numbers
	public renderForm = () => {
		let formRows = new Array<FormRow>();
		formRows.push(this.getHeader('productionKey'));
		const productionKey = Object.keys(GrowthProductionReportSettingProductionKey.fromJS({}));
		productionKey.forEach(key => {
			formRows.push(this.getCheckbox(key, 'growthPigPReportProduction'));
		});
		formRows.push(this.getHeader('feedKey'));
		const feedKey = Object.keys(GrowthProductionReportSettingFeedKey.fromJS({}));
		feedKey.forEach(key => {
			formRows.push(this.getCheckbox(key, 'growthPigPReportFeed'));
		});
		formRows.push(this.getHeader('diverseInformationKey'));
		const diverseInformationKey = Object.keys(GrowthProductionReportSettingDiverseInformationKey.fromJS({}));
		diverseInformationKey.forEach(key => {
			formRows.push(this.getCheckbox(key, 'growthPigPReportDiverseInformation'));
		});
		if (this.state.productionType === AnimalType.Finisher) {
			formRows.push(this.getHeader('slaughterDataKey'));
			const slaughterDataKey = Object.keys(GrowthProductionReportSettingSlaughterDataKey.fromJS({}));
			slaughterDataKey.forEach(key => {
				formRows.push(this.getCheckbox(key, 'growthPigPReportSlaughterHouseData'));
			});
		}

		formRows.push(this.getHeader('stateKey'));
		const stateKey = Object.keys(GrowthProductionReportSettingStateKey.fromJS({}));
		stateKey.forEach(key => {
			if (this.CheckForAnimalType(key)) {
				formRows.push(this.getCheckbox(key, 'growthPigPReportState'));
			}
		});
		formRows.push(this.getHeader('subResultKey'));
		const subResultKey = Object.keys(GrowthProductionReportSettingSubResultKey.fromJS({}));
		subResultKey.forEach(key => {
			formRows.push(this.getCheckbox(key, 'growthPigPReportSubResults'));
		});
		formRows.push(this.getHeader('FeedUsageKey'));
		formRows.push(this.getCheckbox('showFeedUsage'));

		return <SkioldFormsWrapper formRows={formRows} containerClassName="setup-forms-wrappoer-container" />;
	};

	private getCheckbox = (key: string, pathToKey?: string) => {
		return {
			name: localizedDynamic(this.GetLocalizedKey(key, pathToKey)),
			component: (
				<SkioldCheckbox
					itemFromParent={{ key, pathToKey }}
					onClick={this.valueChanged}
					isChecked={
						pathToKey
							? this.state.pReportSetting[pathToKey] && this.state.pReportSetting[pathToKey]
								? this.state.pReportSetting[pathToKey][key]
								: false
							: this.state.pReportSetting[key]
					}
				/>
			),
		};
	};

	private SpecialForFinisherKeys = [
		'delieveredPcs',
		'avgWeightDelievered',
		'totalSlaughteredRegistrations',
		'avgWeightSlaughteredRegistrations',
	];

	private CheckForAnimalType(key) {
		if (this.props.animalType === AnimalType.Weaner && this.SpecialForFinisherKeys.includes(key)) {
			return false;
		}
		return true;
	}

	private GetLocalizedKey = (key: string, pathToKey?: string) => {
		if (pathToKey) {
			return 'data' + pathToKey + '.' + key;
		}
		return key;
	};

	private valueChanged = (value, itemFromParent: { key: string; pathToKey?: string }) => {
		const prevState = { ...this.state };
		const prevStateReport = { ...prevState.pReportSetting };
		if (itemFromParent.pathToKey) {
			if (!prevStateReport[itemFromParent.pathToKey]) {
				const copyOfObject = {};
				copyOfObject[itemFromParent.key] = value;
				prevStateReport[itemFromParent.pathToKey] = copyOfObject;
			} else {
				const copyOfObject = { ...prevStateReport[itemFromParent.pathToKey] };
				copyOfObject[itemFromParent.key] = value;
				prevStateReport[itemFromParent.pathToKey] = copyOfObject;
			}
		} else {
			prevStateReport[itemFromParent.key] = value;
		}
		prevState.pReportSetting = prevStateReport;

		this.setState(prevState);
	};

	private getHeader(header: string): FormRow {
		return {
			header: localizedDynamic(header),
		};
	}
	private save = async () => {
		const { pReportSetting } = this.state;
		if (!this.validate(pReportSetting)) {
			return;
		}
		pReportSetting.siteId = this.props.siteId;
		this.props.saveProductionReportSetting(pReportSetting);
	};

	private validate(pReportSetting: IProductionReportSetting) {
		return true;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PReportGrowthPigSetup);
