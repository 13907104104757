import natsort from 'natsort';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { AnimalType, Building, GrowthPigTuneProduction, IGrowthPigEventDto, ISection, Pen } from 'shared/api/api';
import { getDateString } from 'shared/helpers/date-helpers';
import { rangeFilterMethodGrid, setOneDecimalsAsNumber } from 'shared/helpers/general-helpers';
import { getTunePigProductionDataOnly } from 'shared/helpers/growth-pigs-helper/growth-pig-event-function-helper';
import { getLocationStringBySectionId, getPenNameByPenId } from 'shared/helpers/location-helper';
import { memoizeHashmapLocation, memoizeLocation } from 'shared/helpers/memoize-getters/memoize-getters';
import { LocationsState } from 'shared/state/ducks/locations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { SkioldTableGroupGrid } from 'web/view/components/skiold-components/skiold-table/skiold-table-grouped-grid/Skiold-table-grouped-grid';

export interface PropsFromParent {
	animalType: AnimalType;
	date: Date;
}

interface GrowthPigTuneProductionReportTableData {
	buildingSection?: string;
	pen?: string;
	amount: number;
	avgWeight: number;
	tuneAmount?: number;
	tuneWeight?: number;
	penId?: string;
	diff: number;
	buildingOrder?: number;
	sectionOrder?: number;
	penOrder?: number;
}

export const GrowthPigTuneProductionReportTable: FC<PropsFromParent> = React.memo(props => {
	const gpedData = useSelector((state: WebAppState) => state.growthPigEvents.entities);
	const locations = useSelector((state: WebAppState) =>
		memoizeLocation(
			state.locations.buildings,
			state.locations.sections,
			state.locations.pens,
			state.locations.valves,
		),
	);
	const hashmapLocations = useSelector((state: WebAppState) =>
		memoizeHashmapLocation(state.locations.buildings, state.locations.sections, state.locations.pens),
	);
	const gpetp = useSelector((state: WebAppState) => state.growthPigTuneProduction.growthPigTuneProductions);
	let tableData: GrowthPigTuneProductionReportTableData[] = [];
	handleGrowthPigsEventTableData(gpedData, props, hashmapLocations, locations, tableData);
	handleTuneProductionTableData(gpetp, props, hashmapLocations, tableData);
	tableData = tableData.sort(handleBuildingSectionSort);
	return (
		<SkioldTableGroupGrid
			tableKey={'tuneProductionTableGrowthPigs'}
			rowsToRender={10}
			summaryItems={summaryItems}
			groupedHeaders={groupedHeaders()}
			groupBy="buildingSection"
			columns={columnData()}
			data={tableData}
			sortingDisabled={true}
		/>
	);
});

const summaryItems = [
	{ columnName: 'pen', type: 'pen' },
	{ columnName: 'amount', type: 'amount' },
	{ columnName: 'avgWeight', type: 'avgWeight' },
	{ columnName: 'tuneAmount', type: 'tuneAmount' },
	{ columnName: 'tuneWeight', type: 'tuneWeight' },
	{ columnName: 'diff', type: 'diff' },
];

const groupedHeaders = () => [
	{ key: localized('Calculated'), groupColumnNames: ['amount', 'avgWeight'] },
	{ key: localized('Tuned'), groupColumnNames: ['tuneAmount', 'tuneWeight'] },
];

function handleBuildingSectionSort(
	a: GrowthPigTuneProductionReportTableData,
	b: GrowthPigTuneProductionReportTableData,
) {
	let sorter = natsort();

	if (a.buildingOrder && b.buildingOrder && a.buildingOrder !== b.buildingOrder) {
		return sorter(a.buildingOrder, b.buildingOrder);
	} else if (a.sectionOrder && b.sectionOrder && a.sectionOrder !== b.sectionOrder) {
		return sorter(a.sectionOrder, b.sectionOrder);
	} else if (a.penOrder && b.penOrder && a.penOrder !== b.penOrder) {
		return sorter(a.penOrder, b.penOrder);
	}

	return 0;
}

const columnData = () => [
	{
		columnName: 'buildingSection',
		name: 'buildingSection',
		width: 140,
		title: localized('building') + ' - ' + localized('section'),
		sortingDisabled: true,
	},
	{
		columnName: 'pen',
		name: 'pen',
		width: 140,
		title: localized('pen'),
		summaryMethod: () => undefined,
		sortingDisabled: true,
	},
	{
		columnName: 'amount',
		name: 'amount',
		width: 100,
		title: localized('Count'),
		getCellValue: getAmountCell,
		filterFunction: rangeFilterMethodGrid,
		summaryMethod: amountSummary,
	},
	{
		columnName: 'avgWeight',
		name: 'avgWeight',
		width: 80,
		title: localized('ActiveWeight'),
		filterFunction: rangeFilterMethodGrid,
		getCellValue: getWeightCell,
		summaryMethod: weightSummary,
	},
	{
		columnName: 'tuneAmount',
		name: 'tuneAmount',
		width: 80,
		title: localized('Count'),
		getCellValue: getTuneAmountCell,
		filterFunction: rangeFilterMethodGrid,
		summaryMethod: tuneAmountSummary,
	},
	{
		columnName: 'tuneWeight',
		name: 'tuneWeight',
		width: 80,
		title: localized('ActiveWeight'),
		getCellValue: getTuneWeightCell,
		filterFunction: rangeFilterMethodGrid,
		summaryMethod: tuneWeightSummary,
	},
	{
		columnName: 'diff',
		name: 'diff',
		width: 80,
		title: localized('diff'),
		getCellValue: getDiffCell,
		filterFunction: rangeFilterMethodGrid,
		summaryMethod: tuneDiffSummary,
	},
];

const getDiffCell = (data: GrowthPigTuneProductionReportTableData) => {
	return data.tuneAmount !== undefined && data.diff ? data.diff : undefined;
};

const getTuneAmountCell = (data: GrowthPigTuneProductionReportTableData) => {
	return data.tuneAmount !== undefined ? data.tuneAmount : undefined;
};

const getTuneWeightCell = (data: GrowthPigTuneProductionReportTableData) => {
	return data.tuneWeight !== undefined ? data.tuneWeight : undefined;
};

const getAmountCell = (data: GrowthPigTuneProductionReportTableData) => {
	return data.amount ? data.amount : undefined;
};

const getWeightCell = (data: GrowthPigTuneProductionReportTableData) => {
	return data.avgWeight ? data.avgWeight : undefined;
};

function handleGrowthPigsEventTableData(
	gpedData: IGrowthPigEventDto[],
	props: React.PropsWithChildren<PropsFromParent & { children?: React.ReactNode }>,
	hashmapLocations: { [key: string]: any },
	locations: LocationsState,
	tableData: GrowthPigTuneProductionReportTableData[],
) {
	locations.buildings
		.sort((a, b) => {
			return a.order! > b.order! ? 1 : b.order! > a.order! ? -1 : 0;
		})
		.forEach(building => {
			let sectionsInBuilding = locations.sections
				.filter(p => p.buildingId === building.id)
				.sort((a, b) => {
					return a.order! > b.order! ? 1 : b.order! > a.order! ? -1 : 0;
				});
			sectionsInBuilding
				.filter(sec => sec.animalType === props.animalType)
				.forEach(section => {
					const building = section.buildingId
						? (hashmapLocations[section.buildingId] as Building)
						: undefined;
					let pensInSection = locations.pens
						.filter(p => p.sectionId === section.id)
						.sort((a, b) => {
							return a.order! > b.order! ? 1 : b.order! > a.order! ? -1 : 0;
						});

					// Get pig distribution foreach relevant pen
					const dataDistribution = getTunePigProductionDataOnly(
						section,
						gpedData,
						locations,
						props.animalType,
						props.animalType,
						props.date,
					);
					// Setup a row for each section
					pensInSection.forEach(penToUse => {
						tableData.push({
							sectionOrder: section.order,
							buildingOrder: building && building.order,
							penOrder: penToUse.order,
							sectionId: section.id,
							amount: 0,
							totalWeightCurve: 0,
							tuneAmount: undefined,
							tuneWeight: undefined,
							diff: 0,
							avgWeight: 0,
							buildingSection: getLocationStringBySectionId(section && section.id, hashmapLocations),
							pen: section.usePens ? penToUse.name : '',
							penId: penToUse.id,
						} as GrowthPigTuneProductionReportTableData);
					});

					// Adjust data on each row with pigs
					dataDistribution.forEach(dataDist => {
						const tableItemIndex = tableData.findIndex(td => td.penId === dataDist.penId);
						if (tableItemIndex >= 0) {
							tableData[tableItemIndex].amount = dataDist.amount !== undefined ? dataDist.amount : 0;
							tableData[tableItemIndex].avgWeight =
								dataDist.totalWeightCurve && dataDist.amount
									? setOneDecimalsAsNumber(dataDist.totalWeightCurve / dataDist.amount)!
									: 0;
							tableData[tableItemIndex].diff = dataDist.amount !== undefined ? dataDist.amount : 0;
						} else {
							const penToUse = dataDist.penId ? (hashmapLocations[dataDist.penId] as Pen) : undefined;
							tableData.push({
								sectionOrder: section.order,
								buildingOrder: building && building.order,
								penOrder: penToUse && penToUse.order,
								amount: dataDist.amount !== undefined ? dataDist.amount : 0,
								avgWeight:
									dataDist.totalWeightCurve && dataDist.amount
										? setOneDecimalsAsNumber(dataDist.totalWeightCurve / dataDist.amount)!
										: 0,
								buildingSection: getLocationStringBySectionId(section && section.id, hashmapLocations),
								pen: getPenNameByPenId(dataDist.penId, hashmapLocations),
								penId: dataDist.penId,
								tuneAmount: undefined,
								tuneWeight: undefined,
								diff: dataDist.amount !== undefined ? dataDist.amount : 0,
							});
						}
					});
				});
		});
}

function handleTuneProductionTableData(
	gpetp: GrowthPigTuneProduction[],
	props: React.PropsWithChildren<PropsFromParent & { children?: React.ReactNode }>,
	hashmapLocations: { [key: string]: any },
	tableData: GrowthPigTuneProductionReportTableData[],
) {
	gpetp
		.filter(tp => tp.animalType === props.animalType && getDateString(props.date) === getDateString(tp.date))
		.forEach(tp => {
			if (tp.sectionId && tp.penId) {
				const building = tp.buildingId ? (hashmapLocations[tp.buildingId] as Building) : undefined;
				const section = hashmapLocations[tp.sectionId] as ISection;
				const tableItemIndex = tableData.findIndex(td => td.penId === tp.penId);
				if (tableItemIndex >= 0) {
					tableData[tableItemIndex].tuneAmount = tp.amount !== undefined ? tp.amount : undefined;
					tableData[tableItemIndex].tuneWeight =
						tp.weight && tp.amount ? setOneDecimalsAsNumber(tp.weight / tp.amount)! : undefined;
					tableData[tableItemIndex].diff =
						tableData[tableItemIndex].diff - (tp.amount !== undefined ? tp.amount : 0);
				} else {
					const penToUse = tp.penId ? (hashmapLocations[tp.penId] as Pen) : undefined;
					tableData.push({
						sectionOrder: section.order,
						buildingOrder: building && building.order,
						penOrder: penToUse && penToUse.order,
						amount: 0,
						avgWeight: 0,
						buildingSection: getLocationStringBySectionId(section && section.id, hashmapLocations),
						pen: getPenNameByPenId(tp.penId, hashmapLocations),
						penId: tp.penId,
						tuneAmount: tp.amount !== undefined ? tp.amount : undefined,
						tuneWeight: tp.weight && tp.amount ? setOneDecimalsAsNumber(tp.weight / tp.amount)! : undefined,
						diff: -(tp.amount !== undefined ? tp.amount : 0),
					});
				}
			}
		});
}

const tuneWeightSummary = (items: GrowthPigTuneProductionReportTableData[]) => {
	let totalWeight = 0;
	let totalPigs = 0;
	if (items.find(e => e.tuneAmount === undefined && e.amount)) {
		return undefined;
	}
	items.forEach(item => {
		if (
			item.amount !== undefined &&
			item.avgWeight !== undefined &&
			item.tuneAmount !== undefined &&
			item.tuneWeight !== undefined
		) {
			totalWeight += item.tuneWeight * item.tuneAmount;
			totalPigs += item.tuneAmount;
		}
	});
	return totalWeight && totalPigs ? totalWeight / totalPigs : undefined;
};

const tuneAmountSummary = (items: GrowthPigTuneProductionReportTableData[]) => {
	let totalPigs = 0;
	if (items.find(e => e.tuneAmount === undefined && e.amount)) {
		return undefined;
	}
	items.forEach(item => {
		if (item.tuneAmount) {
			totalPigs += item.tuneAmount;
		}
	});
	return totalPigs ? totalPigs : undefined;
};

const amountSummary = (items: GrowthPigTuneProductionReportTableData[]) => {
	let totalPigs = 0;
	items.forEach(item => {
		totalPigs += item.amount;
	});

	return totalPigs ? totalPigs : undefined;
};

const tuneDiffSummary = (items: GrowthPigTuneProductionReportTableData[]) => {
	let totalDiff = 0;

	if (items.find(e => e.tuneAmount === undefined && e.amount)) {
		return undefined;
	}
	items.forEach(item => {
		if (item.amount !== undefined && item.avgWeight !== undefined) {
			totalDiff += item.diff;
		}
	});
	return totalDiff ? totalDiff : undefined;
};

const weightSummary = (items: GrowthPigTuneProductionReportTableData[]) => {
	let totalWeight = 0;
	let totalPigs = 0;
	items.forEach(item => {
		if (item.amount > 0 && item.avgWeight !== undefined) {
			totalWeight += item.avgWeight * item.amount;
			totalPigs += item.amount;
		}
	});
	return totalWeight && totalPigs ? totalWeight / totalPigs : undefined;
};
