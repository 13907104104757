import React from 'react';
import { FormWrapperItemPropsFromParent } from './skiold-forms-wrapper-types';
import './skiold-forms-wrapper.scss';
import { TextWeb } from 'web/web-helpers/styled-text-components';

export class SkioldFormsWrapperItem extends React.PureComponent<FormWrapperItemPropsFromParent> {
	public static defaultProps: Partial<FormWrapperItemPropsFromParent> = {
		roundCorners: true
	};
	public render() {
		const { formRow} = this.props;
		const rowBackgroundColor = this.props.formRow.doubleHeader
			? 'double-header'
			: this.props.formRowIndex % 2 === 0
			? 'white-row'
			: 'grey-row';

		const firstRowRadiues = this.props.formRowIndex === 0 ? 'first-row' : '';
		const lastRowRadiues = this.props.formRowIndex === this.props.formRowLength - 1 && this.props.roundCorners ? 'last-row' : '';
		return formRow.header ? (
			<div className='skiold-forms-wrapper-item-header-container'>
				<TextWeb className='header-value'>{formRow.header.toLocaleUpperCase()}</TextWeb>
			</div>
		) : (
			<div
				style={this.props.formRow.style}
				className={`skiold-forms-wrapper-item-form-container ${rowBackgroundColor} ${firstRowRadiues} ${lastRowRadiues} ${
					formRow.className
				}`}
			>
				<div className={'skioldFormsWrapperWebLeftColumnStyle'}>
					{formRow.doubleHeader ? (
						<TextWeb className='header-value'>{formRow.doubleHeader.left.toLocaleUpperCase()}</TextWeb>
					) : React.isValidElement(formRow.name) ? (
						formRow.name
					) : (
						<TextWeb className='left-form-text'>{formRow.name}</TextWeb>
					)}
				</div>
				<div className='right-container'>
					<div className={'skioldFormsWrapperWebRightColumnStyle'}>
						{formRow.doubleHeader && formRow.doubleHeader.right ? (
							<TextWeb className='subheader-text header-value'>
								{formRow.doubleHeader.right!.toLocaleUpperCase()}
							</TextWeb>
						) : (
							formRow.component
						)}
					</div>
				</div>
			</div>
		);
	}
}
