import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import {
	deleteLastPregnancyEvent,
	deleteLastPregnancyEvents,
	getDeparturedPregnancies,
	getSyncData,
	removePregnanciesByAnimal,
	resetState,
	saveDeparturedPregnancyEvent,
	savePregnancyEvent,
	savePregnancyEventNoUpdate,
	savePregnancyEvents,
	saveWeaningEvents,
} from './actions';
import { PregnancyState } from './types';
import { SyncableHashmapInitialState } from 'shared/state/models/syncable';
import {
	mergeArraysHashmap,
	removeKeyFromHashmap,
	removeValueFromArray,
	removeValueFromHashmap,
	upsertValueInArray,
	upsertValueInHashmap,
} from 'shared/helpers/reducer-helpers';
import { siteChange } from '../profile/actions';
import { IPregnancyEvent, PregnancyEvent } from 'shared/api/api';
import { NewPregnancyEventClass } from 'shared/helpers/pregnancy-helper/new-pregnancy-event-class';
import { fetchSingleAnimalInfo, getDeparturedAnimalDataByDate } from '../stem-animals/actions';
import { getSyncModelData } from '../sync/actions';

export const initialState: PregnancyState = {
	...SyncableHashmapInitialState,
	departuredPregnancies: {},
};

const pregnancyEventReducer = (state: PregnancyState = initialState, action: Action): PregnancyState => {
	if (isType(action, resetState)) {
		state = initialState;
	}

	if (isType(action, getSyncData.started)) {
		state = { ...state, syncInProgress: true };
	}

	if (isType(action, getSyncData.done)) {
		if (action.payload.result) {
			if (Object.keys(action.payload.result.datas!).length) {
				state = {
					...state,
					entities: mergeArraysHashmap(state.entities, action.payload.result.datas!),
				};
			}

			state = {
				...state,
				lastSyncDate: action.payload.result.syncTime!,
				syncInProgress: false,
			};
		} else {
			state = {
				...state,
				syncInProgress: false,
			};
		}
	}

	if (isType(action, getSyncModelData.done)) {
		if (action.payload.result.pregnancies) {
			if (Object.keys(action.payload.result.pregnancies.datas!).length) {
				state = {
					...state,
					entities: mergeArraysHashmap(state.entities, action.payload.result.pregnancies.datas!),
				};
			}

			state = {
				...state,
				lastSyncDate: action.payload.result.pregnancies.syncTime!,
				syncInProgress: false,
			};
		} else {
			state = {
				...state,
				syncInProgress: false,
			};
		}
	}

	if (isType(action, getSyncData.failed)) {
		state = { ...state, syncInProgress: false };
	}

	if (isType(action, savePregnancyEvents.started)) {
		if (action.payload) {
			action.payload.newPregEvents.forEach(pregEvent => {
				state = {
					...state,
					entities: upsertValueInHashmap(state.entities, pregEvent, pregEvent.stemAnimalId!),
					updates: upsertValueInArray(state.updates, pregEvent),
					saveSyncInProgress: true,
				};
			});
		}

		return state;
	}

	if (isType(action, savePregnancyEvents.failed)) {
		state = { ...state, saveSyncInProgress: false };

		return state;
	}

	if (isType(action, savePregnancyEvents.done)) {
		// handle saved pregnancy events
		action.payload.result.forEach(id => {
			state = {
				...state,
				updates: removeValueFromArray(state.updates, id),
			};
		});

		const newPregnanciesNotUpdated = action.payload.params.newPregEvents.filter(
			pregEvent => pregEvent.id && !action.payload.result.includes(pregEvent.id),
		);
		newPregnanciesNotUpdated.forEach(pregEvent => {
			if (pregEvent.stemAnimalId) {
				state.updates = removeValueFromArray(state.updates, pregEvent.id!);

				if (pregEvent.id && pregEvent.stemAnimalId) {
					const prevPregEvent = action.payload.params.prevPregEventHashmap[pregEvent.id];
					if (!prevPregEvent) {
						state.entities = removeValueFromHashmap(state.entities, pregEvent.id, pregEvent.stemAnimalId);
					} else {
						state.entities = upsertValueInHashmap(state.entities, prevPregEvent, pregEvent.stemAnimalId!);
					}
				}
			}
		});

		state = { ...state, saveSyncInProgress: false };

		return state;
	}

	// Since saveWeaningEvents is WEB only, we dont need update. And atm there isn't at functional sync method for this
	if (isType(action, saveWeaningEvents.started)) {
		action.payload.forEach(pe => {
			state = {
				...state,
				entities: upsertValueInHashmap(state.entities, pe, pe.stemAnimalId!),
				saveSyncInProgress: true,
			};
		});
	}

	if (isType(action, saveWeaningEvents.done)) {
		action.payload.params.forEach(pe => {
			if (pe.id) {
				state = {
					...state,
					saveSyncInProgress: false,
				};
			}
		});
		return state;
	}

	if (isType(action, saveWeaningEvents.failed)) {
		if (action.payload.error.code === 500) {
			const jsonResponse = JSON.parse(action.payload.error.message);
			if (action.payload.error.prevEntity && jsonResponse.enitity) {
				const exceptionResponseEntities = jsonResponse.enitity as string[];
				const prevPreviousToUseAgain = action.payload.error.prevEntity.filter(pe =>
					exceptionResponseEntities.find(a => a === pe.id),
				);
				prevPreviousToUseAgain.forEach(pe => {
					state = {
						...state,
						entities: upsertValueInHashmap(state.entities, pe, pe.stemAnimalId!),
					};
				});
			}
		}

		state = { ...state, saveSyncInProgress: false };

		return state;
	}

	if (isType(action, savePregnancyEvent.started)) {
		state = {
			...state,
			entities: upsertValueInHashmap(state.entities, action.payload, action.payload.stemAnimalId!),
			updates: upsertValueInArray(state.updates, action.payload),
			saveSyncInProgress: true,
		};
	}

	if (isType(action, savePregnancyEvent.failed)) {
		if (action.payload.error.code === 500) {
			if (action.payload.error.prevEntity) {
				state = {
					...state,
					entities: upsertValueInHashmap(
						state.entities,
						action.payload.error.prevEntity,
						action.payload.error.prevEntity.stemAnimalId!,
					),
				};
			} else {
				state = {
					...state,
					entities: removeValueFromHashmap(
						state.entities,
						action.payload.params.id!,
						action.payload.params.stemAnimalId!,
					),
				};
			}
			state = { ...state, updates: removeValueFromArray(state.updates, action.payload.params.id!) };
		}
		state = { ...state, saveSyncInProgress: false };

		return state;
	}

	if (isType(action, savePregnancyEvent.done)) {
		state = {
			...state,
			updates: removeValueFromArray(state.updates, action.payload.result),
			saveSyncInProgress: false,
		};
		return state;
	}

	if (isType(action, saveDeparturedPregnancyEvent.started)) {
		state = {
			...state,
			departuredPregnancies: upsertValueInHashmap(
				state.departuredPregnancies,
				action.payload,
				action.payload.stemAnimalId!,
			),
			updates: upsertValueInArray(state.updates, action.payload),
			saveSyncInProgress: true,
		};
	}

	if (isType(action, saveDeparturedPregnancyEvent.failed)) {
		if (action.payload.error.code === 500) {
			if (action.payload.error.prevEntity) {
				state = {
					...state,
					departuredPregnancies: upsertValueInHashmap(
						state.departuredPregnancies,
						action.payload.error.prevEntity,
						action.payload.error.prevEntity.stemAnimalId!,
					),
				};
			} else {
				state = {
					...state,
					departuredPregnancies: removeValueFromHashmap(
						state.departuredPregnancies,
						action.payload.params.id!,
						action.payload.params.stemAnimalId!,
					),
				};
			}
			state = { ...state, updates: removeValueFromArray(state.updates, action.payload.params.id!) };
		}
		state = { ...state, saveSyncInProgress: false };

		return state;
	}

	if (isType(action, saveDeparturedPregnancyEvent.done)) {
		state = {
			...state,
			updates: removeValueFromArray(state.updates, action.payload.result),
			saveSyncInProgress: false,
		};
		return state;
	}

	if (isType(action, savePregnancyEventNoUpdate.started)) {
		state = {
			...state,
			entities: upsertValueInHashmap(state.entities, action.payload, action.payload.stemAnimalId!),
		};
	}

	if (isType(action, deleteLastPregnancyEvent.started)) {
		state = { ...state, updates: upsertValueInArray(state.updates, action.payload) };
		state = {
			...state,
			entities: removeValueFromHashmap(state.entities, action.payload.id!, action.payload.stemAnimalId!),
		};
		return state;
	}

	if (isType(action, deleteLastPregnancyEvent.done)) {
		state = { ...state, updates: removeValueFromArray(state.updates, action.payload.params.id!) };
		return state;
	}

	if (isType(action, deleteLastPregnancyEvents.started)) {
		if (action.payload) {
			action.payload.forEach(pregEvent => {
				if (pregEvent.id && pregEvent.stemAnimalId) {
					state = {
						...state,
						entities: removeValueFromHashmap(state.entities, pregEvent.id, pregEvent.stemAnimalId),
						updates: upsertValueInArray(state.updates, pregEvent),
					};
				}
			});
		}

		return state;
	}

	if (isType(action, deleteLastPregnancyEvents.done)) {
		if (action.payload.params && action.payload.params.length) {
			action.payload.params.forEach(preg => {
				if (preg.id) {
					state = {
						...state,
						updates: removeValueFromArray(state.updates, preg.id),
					};
				}
			});
		}

		return state;
	}

	if (isType(action, removePregnanciesByAnimal)) {
		state = {
			...state,
			entities: removeKeyFromHashmap(state.entities, action.payload),
		};
	}

	if (isType(action, siteChange.done)) {
		state = initialState;
	}

	if (isType(action, getDeparturedPregnancies.done)) {
		state = {
			...state,
			departuredPregnancies: action.payload.result,
		};
	}

	if (isType(action, getDeparturedAnimalDataByDate.done)) {
		if (action.payload.result.departedPregnancies) {
			state = {
				...state,
				departuredPregnancies: action.payload.result.departedPregnancies,
			};
		}
	}

	if (isType(action, fetchSingleAnimalInfo.done)) {
		if (action.payload.result.stemAnimal && action.payload.result.pregnancies) {
			if (
				action.payload.result.stemAnimal.departureDate &&
				action.payload.result.stemAnimal.departureDate <= new Date()
			) {
				let hashValue = { [action.payload.result.stemAnimal.id!]: action.payload.result.pregnancies };
				state = {
					...state,

					departuredPregnancies: mergeArraysHashmap(state.departuredPregnancies, hashValue),
				};
			} else {
				let hashValue = { [action.payload.result.stemAnimal.id!]: action.payload.result.pregnancies };
				state = {
					...state,

					entities: mergeArraysHashmap(state.entities, hashValue),
				};
			}
		}
	}

	//Ensure Date objects are in fact date and not strings - redux-persist serializes dates to string but dont' deserialize to dates again
	if (action.type === 'persist/REHYDRATE') {
		let a = (action as any) as { payload: PregnancyState; key: string };

		if (a.key === 'pregnancyEvents' && a.payload && a.payload) {
			let entities: { [key: string]: IPregnancyEvent[] } = {};
			let updates = new Array<PregnancyEvent>();
			let lastSyncDate = new Date(-8640000000000000);

			if (a.payload.entities) {
				if (Array.isArray(a.payload.entities)) {
					return initialState;
				}

				let keys = Object.keys(a.payload.entities);
				keys.forEach(key => {
					let array = a.payload.entities[key];
					if (array) {
						entities[key] = array.map((dt: IPregnancyEvent) => {
							let ndt = NewPregnancyEventClass(dt);
							ndt.init(dt);
							return ndt;
						});
					}
				});
			}

			if (a.payload.updates) {
				updates = a.payload.updates.map((dt: IPregnancyEvent) => {
					let ndt = NewPregnancyEventClass(dt);
					ndt.init(dt);
					return ndt;
				});
			}

			if (a.payload.lastSyncDate) {
				lastSyncDate = new Date(a.payload.lastSyncDate);
			}

			state = { ...state, entities, updates, lastSyncDate };
		}
	}

	return state;
};

export default pregnancyEventReducer;
