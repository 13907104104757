import { Sorting } from '@devexpress/dx-react-grid';
import React from 'react';
import { connect } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { Dispatch } from 'redux';
import {
	IAnalysisMatingPregnancyTotalMatingTableItem,
	ITotalMatingTableItem,
	PregnancyState,
	ScanResult,
	Scanned,
	TotalMatingTableItem,
} from 'shared/api/api';
import sokort_icon from 'shared/assets/src-assets/png/sokort_icon.png';
import { getDateString } from 'shared/helpers/date-helpers';
import { rangeFilterMethodGrid } from 'shared/helpers/general-helpers';
import { NaturalSort, NaturalSortDatesUndefinedMax } from 'shared/helpers/natural-sort';
import { GetElaborationAnalysisMatingPregnancy } from 'shared/state/ducks/analysis/analysis-modal/operation';
import {
	GetDashboardDeparturedTotalMatingsTable,
	GetDashboardTotalMatingsTable,
} from 'shared/state/ducks/dashboard/operations';
import { GetDeparturedLardEvents } from 'shared/state/ducks/lardScanningEvents/operations';
import { GetDeparturedMoveEvents } from 'shared/state/ducks/move-events/operations';
import { GetDeparturedPregnancies } from 'shared/state/ducks/pregnancy-events/operations';
import { GetDeparturedAnimals } from 'shared/state/ducks/stem-animals/operations';
import { localized, localizedInterpolation } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import SkioldTableGrid, {
	SkioldTableGrid as SkioldTableRef,
} from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { ViewWeb } from 'web/view/components/utils/web-view';
import SowCard from 'web/view/pages/sow-card/sow-card';
import { GetAnalysisMatingsForTotalMatingPregnancyPdf } from 'web/web-helpers/pdf-helper/analysis-pdf-helpers/analysis-pdf-helper';
import {
	GetMatingBatchWeekPdf,
	GetTotalMatingTableDashboardPdf,
} from 'web/web-helpers/pdf-helper/dashboard-pdf-helper.ts/dashboard-pdf-helper';
import { SkioldModal } from '../../skiold-components/skiold-modal/skiold-modal';
import { SkioldTouchableOpacity } from '../../skiold-components/skiold-touchable-opacity';
import { SowListConstants } from '../../stem-animal/animal-lists/table-constants';
import { Heading } from '../../utils/heading';
import { SkioldImage } from '../../utils/svg/skiold-image';
import {
	defaultSortingDashboardModals,
	getNumberForDashboardTable,
	getWebAnimalColorStyleDashboard,
} from '../dashboard-helper';
import { DashboardModalHeader } from '../dashboard-modal-header';
import './total-matings-dashboard-table.scss';
import { TotalMatingPrintModal } from './total-mating-print-modal';

export enum TotalMatingsModalMode {
	Dashboard,
	MatingPregnancy,
}
interface PropsFromParent {
	batchId?: string;
	dateFrom?: Date;
	dateTo?: Date;
	mode: TotalMatingsModalMode;
	isDeparture?: boolean;
	closeModal: () => void;
}

type TotalMatingTableItemType = ITotalMatingTableItem | IAnalysisMatingPregnancyTotalMatingTableItem;
interface State {
	sowCardModal: boolean;
	stemAnimalId: string;
	data: TotalMatingTableItemType[];
	columns: any[];
	columnsExte: any[];
	loading: boolean;
	showPrintModal: boolean;
}

const mapStateToProps = (state: WebAppState, props: PropsFromParent) => {
	return {
		matingBatch: state.matingBatches.entities.find(mb => mb.id === props.batchId),
		profile: state.profile.active,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: PropsFromParent) => {
	return {
		GetDashboardTotalMatingsTable: (siteId: string, batchId: string) =>
			props.isDeparture
				? GetDashboardDeparturedTotalMatingsTable(siteId, batchId)
				: GetDashboardTotalMatingsTable(siteId, batchId),
		GetElaborationAnalysisMatingPregnancy: (siteId: string) =>
			GetElaborationAnalysisMatingPregnancy(siteId, props.dateFrom, props.dateTo),
		getDeparturedAnimals: () => GetDeparturedAnimals()(dispatch),
		getDeparturedPregnancies: () => GetDeparturedPregnancies()(dispatch),
		getDeparturedMoveEvents: () => GetDeparturedMoveEvents()(dispatch),
		getDeparturedLardEvents: () => GetDeparturedLardEvents()(dispatch),
	};
};

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

export class TotalMatingsDashboardTable extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			sowCardModal: false,
			stemAnimalId: '',
			data: [],
			loading: true,
			columns: this.generateColumns(),
			columnsExte: this.getColumnExtenstions(),
			showPrintModal: false,
		};
	}
	public componentDidMount = () => {
		if (this.props.profile && this.props.profile.siteId) {
			if (this.props.batchId && this.props.mode === TotalMatingsModalMode.Dashboard) {
				this.props
					.GetDashboardTotalMatingsTable(this.props.profile.siteId, this.props.batchId)
					.then(d => this.setState({ data: d, loading: false }));
			} else if (this.props.mode === TotalMatingsModalMode.MatingPregnancy) {
				this.props
					.GetElaborationAnalysisMatingPregnancy(this.props.profile.siteId)
					.then(d => this.setState({ data: d, loading: false }));
			}
		}

		this.props.getDeparturedAnimals();
		this.props.getDeparturedPregnancies();
		this.props.getDeparturedMoveEvents();
		this.props.getDeparturedLardEvents();
	};

	private defaultSorting = [
		{ columnName: 'animalNr', direction: 'asc' },
		{ columnName: 'matingDate', direction: 'asc' },
	] as Sorting[];

	public render() {
		return (
			<ViewWeb className="total-matings-modal-table">
				<Heading
					text={this.props.isDeparture ? localized('TotalDepartureTable') : localized('TotalMatingsTable')}
				/>
				<DashboardModalHeader
					closeModal={this.props.closeModal}
					matingBatch={this.props.matingBatch}
					dateFrom={this.props.dateFrom}
					dateTo={this.props.dateTo}
					printData={this.openPrintModal}
				/>

				{this.state.loading ? (
					<ViewWeb className="alignTextCenter">
						<ClipLoader color="#f2ac40" size={70} />
					</ViewWeb>
				) : (
					<ViewWeb className={'table'}>
						<SkioldTableGrid
							sortHeaderId={
								this.props.mode === TotalMatingsModalMode.Dashboard
									? defaultSortingDashboardModals
									: this.defaultSorting
							}
							usesSummaryRow={true}
							data={this.state.data}
							ref={this.setTableRef}
							columns={this.state.columns}
							ColumnExtensions={this.state.columnsExte}
							tableKey={
								this.props.mode === TotalMatingsModalMode.Dashboard
									? 'TotalMatingsDashboardTable'
									: 'TotalMatingsAnalysisMatingPregnancyTable'
							}
						/>
						<SkioldModal
							shouldCloseOnOverlayClick={true}
							padding="0"
							isOpen={this.state.sowCardModal}
							close={this.closeModal}
							justify-content="flex-end"
							max-width="calc(100% - 220px)"
						>
							<SowCard stemAnimalIdFromParent={this.state.stemAnimalId} close={this.closeModal} />
						</SkioldModal>
						<SkioldModal
							shouldCloseOnOverlayClick={true}
							padding="0"
							isOpen={this.state.showPrintModal}
							close={this.closeModal}
							minWidth="300px"
						>
							<TotalMatingPrintModal
								closeModal={this.closePrintModal}
								printElaboration={this.printData}
								printWeekList={this.printWeekList}
								batchNumber={this.props.matingBatch?.batchNumber}
							/>
						</SkioldModal>
					</ViewWeb>
				)}
			</ViewWeb>
		);
	}

	private openPrintModal = () => {
		this.setState({ showPrintModal: true });
	};

	private closePrintModal = () => {
		this.setState({ showPrintModal: false });
	};

	private printData = async () => {
		if (this.SkioldTableRef && this.props.profile && this.props.profile.siteId) {
			let data = this.SkioldTableRef.GetSortedData();
			if (this.props.mode === TotalMatingsModalMode.Dashboard) {
				GetTotalMatingTableDashboardPdf(
					data,
					this.props.isDeparture
						? 'TotalDepartures.pdf'
						: localizedInterpolation('ElaborationMatingBatch', this.props.matingBatch?.batchNumber) +
								'.pdf',
					this.props.profile.siteId,
					Intl.DateTimeFormat().resolvedOptions().timeZone,
					this.props.profile.language,

					this.props.isDeparture,
					this.props.isDeparture
						? 'TotalDepartures.pdf'
						: localizedInterpolation('ElaborationMatingBatch', this.props.matingBatch?.batchNumber),
				);
			} else if (this.props.mode === TotalMatingsModalMode.MatingPregnancy) {
				GetAnalysisMatingsForTotalMatingPregnancyPdf(
					data,
					'PregnancyAnalysisMatings.pdf',
					this.props.profile.siteId,
					Intl.DateTimeFormat().resolvedOptions().timeZone,
					this.props.profile.language,
				);
			}
		}
	};

	private printWeekList = async () => {
		if (this.SkioldTableRef && this.props.profile && this.props.profile.siteId) {
			let data = this.SkioldTableRef.GetSortedData();
			GetMatingBatchWeekPdf(
				data,
				localizedInterpolation('weekBatchPdf', this.props.matingBatch?.batchNumber) + '.pdf',
				this.props.profile.siteId,
				Intl.DateTimeFormat().resolvedOptions().timeZone,
				this.props.profile.language,
				localizedInterpolation('weekBatchPdf', this.props.matingBatch?.batchNumber),
			);
		}
	};

	public SkioldTableRef: SkioldTableRef | undefined;
	private setTableRef = (m: any) => (m ? (this.SkioldTableRef = m) : {});
	private getColumnExtenstions = () => {
		let columnExte: any[] = [
			{
				columnName: 'sowCard',
				filteringEnabled: false,
				width: SowListConstants.iconWidth,
			},
			{ columnName: 'animalNr', width: SowListConstants.animalNrWidth },
			{ columnName: 'breedRace', width: SowListConstants.animalNrWidth },
			{
				columnName: 'litter',
				width: SowListConstants.iDNumberWidth,
			},
		];
		if (this.props.mode === TotalMatingsModalMode.Dashboard) {
			columnExte.push({
				columnName: 'pregnantDays',
				usesSummary: true,
				width: SowListConstants.iDNumberWidth,
			});
		}
		if (this.props.mode === TotalMatingsModalMode.MatingPregnancy) {
			columnExte.push({
				columnName: 'matingsFirstMating',
				width: SowListConstants.dateWidth,
			});
		}
		if (this.props.mode === TotalMatingsModalMode.MatingPregnancy) {
			columnExte.push({
				columnName: 'matingDate',
				width: SowListConstants.dateWidth,
			});
		}
		columnExte = columnExte.concat([
			{
				columnName: 'weaning',
				usesSummary: true,
				width: SowListConstants.iDNumberWidth,
			},
			{
				columnName: 'notPregnant',
				usesSummary: true,
				width: SowListConstants.iDNumberWidth,
			},
			{
				columnName: 'mating',
				usesSummary: true,
				width: SowListConstants.iDNumberWidth,
			},
			{
				columnName: 'matingToAbortion',
				usesSummary: true,
				width: SowListConstants.iDNumberWidth,
			},
			{
				columnName: 'abortionToMating',
				usesSummary: true,
				width: SowListConstants.iDNumberWidth,
			},
			{
				columnName: 'soldOrKilled',
				usesSummary: true,
				width: SowListConstants.indexDateWidth,
			},
			{
				columnName: 'deadOrPutDown',
				usesSummary: true,
				width: SowListConstants.indexDateWidth,
			},
		]);
		return columnExte;
	};

	private showSowCard = (stemAnimalId?: string) => {
		if (stemAnimalId) {
			this.setState({ sowCardModal: true, stemAnimalId: stemAnimalId });
		}
	};

	private closeModal = () => {
		this.setState({ stemAnimalId: '', sowCardModal: false });
	};

	private readonly getNotPregnantCell = (item: TotalMatingTableItem) => {
		return this.props.mode === TotalMatingsModalMode.Dashboard
			? item.notPregnant
			: item.prevNotPregnant && item['matingDate']
			? item.prevNotPregnant
			: !item['matingDate']
			? item.notPregnant
			: '';
	};

	private readonly GenerateSowCardCell = (item: TotalMatingTableItem) => (
		<SkioldTouchableOpacity itemFromParent={item.sowId} onPress={this.showSowCard}>
			<SkioldImage
				width={SowListConstants.iconSVGWidth}
				height={SowListConstants.iconSVGWidth}
				imageData={sokort_icon}
			/>
		</SkioldTouchableOpacity>
	);

	private readonly GenerateAnimalNrCell = (item: TotalMatingTableItem) =>
		item.prevEvent &&
		item.prevEvent.state === PregnancyState.Scanned &&
		(item.prevEvent as Scanned).result === ScanResult.False ? (
			<ViewWeb className="flex-row-space-evenly">
				<ViewWeb>{item.animalNumber}</ViewWeb>
				<ViewWeb className="bold-label-text-filter-red">X</ViewWeb>
			</ViewWeb>
		) : (
			item.animalNumber
		);

	private readonly GeneratePregnancyDaysCell = (item: TotalMatingTableItemType) =>
		getNumberForDashboardTable(item.pregnancyDays);

	private readonly GenerateLitterNrCell = (item: TotalMatingTableItem) => item.litterNumber;

	private readonly GenerateMatingDateCell = (item: IAnalysisMatingPregnancyTotalMatingTableItem) =>
		getDateString(item.matingDate);

	private readonly GenerateMatingsFirstMatingCell = (item: IAnalysisMatingPregnancyTotalMatingTableItem) =>
		getDateString(item.matingDateFirstMating);

	private readonly GenerateWeaningToMatingCell = (item: TotalMatingTableItem) => item.daysFromWeaning;

	private readonly GenerateMatingToMatingCell = (item: TotalMatingTableItem) => item.daysFromMating;

	private readonly GenerateMatingToAbortionCell = (item: TotalMatingTableItem) => item.abortion;

	private readonly GenerateAbortionToMatingCell = (item: TotalMatingTableItem) => item.abortionToMatingDays;

	private readonly GenerateMatingToSoldOrKilledCell = (item: TotalMatingTableItem) => item.soldOrKilled;

	private readonly GenerateMatingToDeadOrPutDownCell = (item: TotalMatingTableItem) => item.deadOrPutDown;

	private animalNumberFilter = (value: any, filterValue: any, row: TotalMatingTableItem) => {
		return (
			row.animalNumber &&
			String(localized(row.animalNumber).toLocaleLowerCase()).startsWith(filterValue.value.toLocaleLowerCase())
		);
	};

	private NaturalSortAnimalNumber = (data1: any, data2: any) => {
		return NaturalSort(data1.animalNumber, data2.animalNumber);
	};
	private generateColumns = () => {
		let columns: any[] = [
			{
				name: 'sowCard',
				title: ' ',
				Footer: localized('avg'),
				summaryText: localized('average'),
				sortable: false,
				getCellValue: this.GenerateSowCardCell,
			},
			{
				name: 'animalNr',
				title: localized('animalNr'),
				className: getWebAnimalColorStyleDashboard,
				filterFunction: this.animalNumberFilter,
				getCellValue: this.GenerateAnimalNrCell,
				sortFunction: this.NaturalSortAnimalNumber,
				useHiddenSort: true,
			},
			{
				name: 'breedRace',
				title: localized('Race'),
			},
			{
				name: 'litter',
				title: localized('Litters'),
				getCellValue: this.GenerateLitterNrCell,
				filterFunction: rangeFilterMethodGrid,
			},
		];
		if (this.props.mode === TotalMatingsModalMode.Dashboard) {
			columns.push({
				name: 'pregnantDays',
				title: localized('pregnantDays'),
				getCellValue: this.GeneratePregnancyDaysCell,
				filterFunction: rangeFilterMethodGrid,
			});
		}
		if (this.props.mode === TotalMatingsModalMode.MatingPregnancy) {
			columns.push({
				name: 'matingsFirstMating',
				sortFunction: NaturalSortDatesUndefinedMax,
				title: localized('matingsFirstMating'),
				getCellValue: this.GenerateMatingsFirstMatingCell,
			});
		}
		if (this.props.mode === TotalMatingsModalMode.MatingPregnancy) {
			columns.push({
				name: 'matingDate',
				sortFunction: NaturalSortDatesUndefinedMax,
				title: localized('MatingDate'),
				getCellValue: this.GenerateMatingDateCell,
			});
		}
		columns = columns.concat([
			{
				name: 'weaning',
				title: localized('weaningToMating'),
				getCellValue: this.GenerateWeaningToMatingCell,
				filterFunction: rangeFilterMethodGrid,
			},
			{
				name: 'notPregnant',
				title: localized('matingToNotPregnant'),
				getCellValue: this.getNotPregnantCell,
				filterFunction: rangeFilterMethodGrid,
			},
			{
				name: 'mating',
				title: localized('matingToMating'),
				getCellValue: this.GenerateMatingToMatingCell,
				filterFunction: rangeFilterMethodGrid,
			},

			{
				name: 'matingToAbortion',
				title: localized('matingToAbortion'),
				getCellValue: this.GenerateMatingToAbortionCell,
				filterFunction: rangeFilterMethodGrid,
			},
			{
				name: 'abortionToMating',
				title: localized('abortionToMating'),
				getCellValue: this.GenerateAbortionToMatingCell,
				filterFunction: rangeFilterMethodGrid,
			},
			{
				name: 'soldOrKilled',
				title: localized('matingToSoldOrKilled'),
				getCellValue: this.GenerateMatingToSoldOrKilledCell,
				filterFunction: rangeFilterMethodGrid,
			},
			{
				name: 'deadOrPutDown',
				title: localized('matingTodeadOrPutDown'),
				getCellValue: this.GenerateMatingToDeadOrPutDownCell,
				filterFunction: rangeFilterMethodGrid,
			},
		]);
		return columns;
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(TotalMatingsDashboardTable);
