import React, { FC, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { ISlaughterSiteDataDto } from 'shared/api/api';
import MoveIcon from 'shared/assets/src-assets/png/flyt_grey_01.png';
import { getDateString } from 'shared/helpers/date-helpers';
import { setTwoDecimalsAsNumber } from 'shared/helpers/general-helpers';
import { memoizeHashmapLocation } from 'shared/helpers/memoize-getters/memoize-getters';
import { NaturalSortDates } from 'shared/helpers/natural-sort';
import {
	generateDataForOverviewTable,
	getLocationStringForSlaughterData,
	SlaughterDataTableWidths,
} from 'shared/helpers/slaughter-data-helper/slaughter-data-helper';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import SkioldTableGrid, {
	SkioldTableGrid as SkioldTableRef,
} from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { SkioldTouchableOpacity } from '../skiold-components/skiold-touchable-opacity';
import { SowListConstants } from '../stem-animal/animal-lists/table-constants';
import { SkioldImage } from '../utils/svg/skiold-image';

interface PropsFromParent {
	onDataChanged: (data: ISlaughterSiteDataDto[]) => void;
	onEdit: (data: ISlaughterSiteDataDto) => void;
	onRowClicked: (data: ISlaughterSiteDataDto) => void;
	setRef: (ref?: SkioldTableRef) => void;
}

export const SlaughterPigsDataTable: FC<PropsFromParent> = React.memo((props ) => {
	const state = useSelector((state: WebAppState) => {
		return {
			data: state.SlaughterDatas.slaughteDatas,
			memorizedLoca: memoizeHashmapLocation(state.locations.buildings,
				state.locations.sections,
				state.locations.pens),
			siteName: state.site.site.siteName ? state.site.site.siteName : '',
		};
	});

	const data = generateDataForOverviewTable(state.data);
	
	const dataState = useState(data);
	if (!isEqual(data, dataState[0])) {
		dataState[1](data);
	}

	return (
		<SkioldTableGrid
			ref={props.setRef as any}
			triggerFilterChangedOnDataNotEqual={true}
			className="delieverySlaughterHouseSettingsTable"
			columns={generateColumns(props.onEdit, state.memorizedLoca, state.siteName)}
			ignoreSetCount={true}
			tableKey={'SlaughterPigsDataTable'}
			onClickedRow={props.onRowClicked}
			onFiltersChanged={props.onDataChanged}
			ColumnExtensions={generateColumnsExtensions()}
			data={dataState[0]}
		/>
	);
});

function generateColumnsExtensions() {
	return [
		{
			columnName: 'edit',
			filteringEnabled: false,
			width: SowListConstants.iconWidth,
		},
		{
			columnName: 'delieveryDate',
			width: SlaughterDataTableWidths.DateWidth,
		},
		{
			columnName: 'count',
			width: SlaughterDataTableWidths.countWidth,
		},
		{
			columnName: 'slaughterWeight',
			width: SlaughterDataTableWidths.slaughterWeightWidth,
		},
		{
			columnName: 'meatPercentage',
			width: SlaughterDataTableWidths.meatPercentageWidth,
		},
		{
			columnName: 'location',
			width: SlaughterDataTableWidths.locationWidth,
		},
		{
			columnName: 'price',
			width: SlaughterDataTableWidths.priceWidth,
		},
		{
			columnName: 'supplier',
			width: SlaughterDataTableWidths.delieveryNumberWidth,
		},
	];
}

const getDateCell = (sd: ISlaughterSiteDataDto) => getDateString(sd.date);
const getCountCell = (sd: ISlaughterSiteDataDto) => sd.amount;
const getWeightCell = (sd: ISlaughterSiteDataDto) => setTwoDecimalsAsNumber(sd.weight);
const getMeatPercentageCell = (sd: ISlaughterSiteDataDto) => setTwoDecimalsAsNumber(sd.meat);
const getPriceCell = (sd: ISlaughterSiteDataDto) => setTwoDecimalsAsNumber(sd.price);
const getSupplierCell = (sd: ISlaughterSiteDataDto) => sd.supplier;
const generateColumns = (
	onEdit: (data: ISlaughterSiteDataDto) => void,
	memorizedLocations: { [key: string]: any },
	siteName: string,
) => {
	const columns = [
		{
			name: 'edit',
			title: ' ',
			sortable: false,
			filterable: false,
			isFixedLeft: true,
			rowSelectedDisabledForColumn: true,
			getCellValue: generateEditCell(onEdit),
		},
		{
			name: 'delieveryDate',
			title: localized('Date'),
			sortFunction: NaturalSortDates,
			getCellValue: getDateCell,
		},
		{
			name: 'count',
			title: localized('Count'),
			getCellValue: getCountCell,
		},
		{
			name: 'slaughterWeight',
			title: localized('slaughterWeight'),
			getCellValue: getWeightCell,
		},
		{
			name: 'meatPercentage',
			title: localized('meatPercentage'),
			getCellValue: getMeatPercentageCell,
		},
		{
			name: 'location',
			title: localized('location'),
			getCellValue: getLocationStringForSlaughterData(memorizedLocations, siteName),
		},
		{
			name: 'price',
			title: localized('price'),
			getCellValue: getPriceCell,
		},
		{
			name: 'supplier',
			title: localized('supplier'),
			getCellValue: getSupplierCell,
		},
	];
	return columns;
};
function generateEditCell(onEdit: (data: ISlaughterSiteDataDto) => void) {
	return (item: ISlaughterSiteDataDto) => (
		<SkioldTouchableOpacity itemFromParent={item} onPress={onEdit}>
			<SkioldImage
				width={SowListConstants.iconSVGWidth}
				height={SowListConstants.iconSVGWidth}
				imageData={MoveIcon}
			/>
		</SkioldTouchableOpacity>
	);
}
