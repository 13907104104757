import { Action } from 'redux';
import { Feeding } from 'shared/api/api';
import { removeValueFromArray, upsertValueInArray } from 'shared/helpers/reducer-helpers';
import { isType } from 'typescript-fsa';
import { siteChange } from '../profile/actions';
import { getFeedings, saveFeeding } from './actions';
import { FeedingState } from './types';

export const initialState: FeedingState = {
	feedings: [],
	feedingSpinner: false,
};

const feedingReducer = (state: FeedingState = initialState, action: Action): FeedingState => {
	if (isType(action, saveFeeding.started)) {
		if (action.payload.isDeleted && action.payload.id) {
			return {
				...state,
				feedings: removeValueFromArray(state.feedings, action.payload.id),
			};
		}
		return {
			...state,
			feedings: upsertValueInArray(state.feedings, action.payload as Feeding),
		};
	}
	if (isType(action, saveFeeding.done)) {
		if (action.payload.result) {
			return {
				...state,
				feedings: upsertValueInArray(state.feedings, action.payload.result as Feeding),
			};
		}
	}

	if (isType(action, getFeedings.done)) {
		const result = { ...state, feedings: action.payload.result };
		return result;
	}

	if (isType(action, siteChange.done)) {
		state = initialState;
	}

	return state;
};

export default feedingReducer;
