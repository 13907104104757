import React from 'react';
import { SkioldTextField } from 'web/view/components/skiold-components/skiold-text-field/skiold-text-field';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { ViewWeb } from 'web/view/components/utils/web-view';
import './search-young-animals.scss';
import { IStemAnimal } from 'shared/api/api';
import { localized } from 'shared/state/i18n/i18n';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { WhiteText } from 'web/view/components/Text/white-text';
import { Heading } from 'web/view/components/utils/heading';
import IsEqual from 'react-fast-compare';
import { searchIdNumber } from 'shared/helpers/stemanimal-helper/stemanimal-helper';

interface PropsFromParent {
	close: (youngAnimal?: IStemAnimal) => void;
	youngAnimals: IStemAnimal[];
}

interface State {
	idNr: string;
	inputRef?: any;
}

class SearchYoungAnimals extends React.PureComponent<PropsFromParent, State> {
	constructor(props: PropsFromParent) {
		super(props);

		this.state = {
			idNr: '',
		};
	}

	public componentDidUpdate(prevProps: PropsFromParent, prevState: State) {
		if (!IsEqual(prevState.inputRef, this.state.inputRef)) {
			this.state.inputRef.focus();
		}
	}
	private onTextChanged = text => this.setState({ idNr: text });
	private setRef = ref => {
		this.setState({ inputRef: ref });
	};

	public render() {
		return (
			<ViewWeb className="search-young-animals">
				<Heading text={localized('YoungAnimal')}></Heading>
				<ViewWeb className="young-animals-scroll">
					<ViewWeb className="flexDirectionRowSpaceBetween">
						<WhiteText className="searchYoungAnimalText">{localized('searchYoungAnimal')}</WhiteText>{' '}
						<SkioldButton title={localized('Close')} onPress={this.props.close} theme="grey" />{' '}
					</ViewWeb>

					<SkioldTextField
						onChangeText={this.onTextChanged}
						value={this.state.idNr}
						className={'light'}
						theme={'light'}
						ref={this.setRef}
						label={localized('idNumber')}
					/>

					{this.getYoungAnimals()}

					{this.props.youngAnimals.length === 0 && <TextWeb>{localized('youngAnimalNotFound')}</TextWeb>}
				</ViewWeb>
			</ViewWeb>
		);
	}

	private getYoungAnimals() {
		let youngAnimals = searchIdNumber(this.props.youngAnimals, this.state.idNr);
		youngAnimals.sort((a, b) => a.idNumber!.localeCompare(b.idNumber!));
		return youngAnimals.map(ya => {
			return (
				<SkioldButton
					className="get-young-animals-button"
					key={ya.idNumber}
					title={ya.idNumber!}
					onPress={() => this.props.close(ya)}
				/>
			);
		});
	}
}

export default SearchYoungAnimals;
