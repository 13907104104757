import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { DrugTypeState } from '.';
import { SyncableInitialState } from 'shared/state/models/syncable';
import { getSyncData } from './actions';
import { mergeArrays, upsertValueInArray, removeValueFromArray } from 'shared/helpers/reducer-helpers';
import { saveDrugType } from './actions';
import { SharedAppState } from 'shared/state/store.shared';
import { DrugType, IDrugType } from 'shared/api/api';
import { siteChange } from '../profile/actions';
import { getSyncModelData } from '../sync/actions';

export const initialDrugTypeState: DrugTypeState = {
	...SyncableInitialState,
};

const drugTypeReducer = (state: DrugTypeState = initialDrugTypeState, action: Action): DrugTypeState => {
	if (isType(action, getSyncData.started)) {
		state = { ...state, syncInProgress: true };
	}

	if (isType(action, getSyncData.done)) {
		if (action.payload.result && action.payload.result.datas!.length) {
			state = {
				...state,
				entities: mergeArrays(state.entities, action.payload.result.datas!),
				lastSyncDate: action.payload.result.syncTime!,
			};
		}

		state = {
			...state,

			syncInProgress: false,
		};
	}

	if (isType(action, getSyncModelData.done)) {
		if (action.payload.result.drugTypes && action.payload.result.drugTypes.datas!.length) {
			state = {
				...state,
				entities: mergeArrays(state.entities, action.payload.result.drugTypes.datas!),
				lastSyncDate: action.payload.result.drugTypes.syncTime!,
			};
		}

		state = {
			...state,

			syncInProgress: false,
		};
	}

	if (isType(action, getSyncData.failed)) {
		state = { ...state, syncInProgress: false };
	}

	if (isType(action, saveDrugType.started)) {
		state = { ...state, updates: upsertValueInArray(state.updates, action.payload) };

		if (action.payload.isDeleted) {
			state = { ...state, entities: removeValueFromArray(state.entities, action.payload.id!) };
		} else {
			state = { ...state, entities: upsertValueInArray(state.entities, action.payload) };
		}
	}

	if (isType(action, saveDrugType.failed)) {
		if (action.payload.error.code === 500) {
			if (action.payload.error.prevEntity) {
				state = { ...state, entities: upsertValueInArray(state.entities, action.payload.error.prevEntity) };
			} else {
				state = { ...state, entities: removeValueFromArray(state.entities, action.payload.params.id!) };
			}
			state = { ...state, updates: removeValueFromArray(state.updates, action.payload.params.id!) };
		}
		return state;
	}

	if (isType(action, saveDrugType.done)) {
		if (!action.payload.params.isDeleted) {
			state = { ...state, entities: upsertValueInArray(state.entities, action.payload.params) };
		}

		state = { ...state, updates: removeValueFromArray(state.updates, action.payload.result) };

		return state;
	}

	if (isType(action, siteChange.done)) {
		state = initialDrugTypeState;
	}

	//Ensure Date objects are in fact date and not strings - redux-persist serializes dates to string but doesn't deserialize to dates again
	if (action.type === 'persist/REHYDRATE') {
		let a = ((action as any) as { payload: SharedAppState; key: string }) as any;

		if (a.key === 'root') {
			let entities = new Array<DrugType>();
			let updates = new Array<DrugType>();
			let lastSyncDate = new Date(-8640000000000000);

			if (a.payload && a.payload.drugType && a.payload.drugType.entities) {
				entities = a.payload.drugType.entities.map((dt: IDrugType) => {
					let ndt = DrugType.fromJS({});
					ndt.init(dt);
					return ndt;
				});
			}

			if (a.payload && a.payload.drugType && a.payload.drugType.entities) {
				updates = a.payload.drugType.updates.map((dt: IDrugType) => {
					let ndt = DrugType.fromJS({});
					ndt.init(dt);
					return ndt;
				});
			}

			if (a.payload && a.payload.drugType && a.payload.drugType.lastSyncDate) {
				lastSyncDate = new Date(a.payload.drugType.lastSyncDate);
			}

			state = { ...state, entities, updates, lastSyncDate };
		}
	}

	return state;
};

export default drugTypeReducer;
