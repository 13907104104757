import React from 'react';
import { Option } from 'react-dropdown';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
	AnimalKind,
	DefaultFeedCurve,
	ESFReader,
	IMoveFromPregToPregSetting,
	IWorkListSetting,
	MoveFromPregToPregSetting,
	MoveToLocationTypes,
	WorkListType,
} from 'shared/api/api';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { generateFeedCurveOptionsWithLocationCode } from 'shared/helpers/feed-curve-helper';
import { deepCopy } from 'shared/helpers/general-helpers';
import { ESFReaderOptions } from 'shared/helpers/work-list-helpers/esf-list-helpers/generel-esf-list-helper';
import {
	GetMoveFromPregToPregOption,
	ResetDataOnMoveListSetups,
} from 'shared/helpers/work-list-helpers/move-to-helpers/moving-lists-helper';
import { SaveWorkListSetting } from 'shared/state/ducks/work-list-settings/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { showAlert } from '../../skiold-alert/skiold-alert';
import { SkioldButton } from '../../skiold-components/skiold-button/skiold-button';
import { SkioldCheckbox } from '../../skiold-components/skiold-checkbox/skiold-checkbox';
import { SkioldFormDropdown } from '../../skiold-components/skiold-dropdown/skiold-form-dropdown';
import { SkioldFormsWrapper } from '../../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from '../../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { SkioldFormIntegerInput } from '../../skiold-components/skiold-integer-input/skiold-form-integer-input';
import '../list-setup.scss';
import { getAvailablityRow, getSkioldInputRow } from '../work-list-helper';
import './move-to-pregnant-setup.scss';

interface PropsFromParent {
	workListType: WorkListType;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		workListSettings: state.workListSettings.entities,
		siteId: state.profile.active!.siteId,
		feedCurvesOptions: generateFeedCurveOptionsWithLocationCode(state.esfStatus.availableCurves),
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	saveWorkListSetting: (movePregToPregSetup: IMoveFromPregToPregSetting) =>
		SaveWorkListSetting(movePregToPregSetup)(dispatch),
});

export interface MoveFromPregToPregSetupState {
	movePregToPregSetup: IMoveFromPregToPregSetting;
	selectedOption: Option;
	options: Option[];
}

type MoveFromPregToPregSetupProps = ReturnType<typeof mapStateToProps> &
	ReturnType<typeof mapDispatchToProps> &
	PropsFromParent;
class MovePregnantToPregnantSetup extends React.PureComponent<
	MoveFromPregToPregSetupProps,
	MoveFromPregToPregSetupState
> {
	constructor(props: MoveFromPregToPregSetupProps) {
		super(props);
		this.state = {
			movePregToPregSetup: this.getWorkListSetting(props.workListType),
			options: GetMoveFromPregToPregOption(),
			selectedOption: { label: '', value: '' },
		};
	}

	public componentDidMount() {
		let initSetting = {};
		if (this.state.movePregToPregSetup.selectedType !== undefined) {
			initSetting['selectedOption'] = {
				label: localized(this.state.movePregToPregSetup.selectedType),
				value: this.state.movePregToPregSetup.selectedType,
			};
		}
		this.setState(initSetting);
	}

	public toggleShowOnWeb = () => {
		this.setState(prevState => ({
			movePregToPregSetup: {
				...prevState.movePregToPregSetup,
				showOnWeb: !this.state.movePregToPregSetup.showOnWeb,
			},
		}));
	};

	public toggleShowOnApp = () => {
		this.setState(prevState => ({
			movePregToPregSetup: {
				...prevState.movePregToPregSetup,
				showOnApp: !this.state.movePregToPregSetup.showOnApp,
			},
		}));
	};

	public moveFromPregToPregSetupChanged(setup: IMoveFromPregToPregSetting) {
		this.setState({
			movePregToPregSetup: setup,
		});
	}

	public render() {
		return (
			<ViewWeb className="move-to-pregnant-setup">
				<ViewWeb className="view-container">
					<TextWeb className="setup-header">{localized('Setup')}</TextWeb>
					<SkioldFormsWrapper formRows={this.getFormRows()} containerClassName="wrapper-container" />
					{this.renderButtons()}
				</ViewWeb>
			</ViewWeb>
		);
	}

	private save() {
		if (!this.validate(this.state.movePregToPregSetup)) {
			return;
		}

		let movePregToPregSetup = deepCopy(this.state.movePregToPregSetup);
		movePregToPregSetup.selectedType = this.state.selectedOption.value as MoveToLocationTypes;
		ResetDataOnMoveListSetups(movePregToPregSetup);
		movePregToPregSetup.siteId = this.props.siteId;
		this.setState({ movePregToPregSetup });
		this.props.saveWorkListSetting(movePregToPregSetup);
	}

	private validate(listSetting: IMoveFromPregToPregSetting) {
		if (!listSetting.selectedType) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_CHOOSE_ANIMAL_NOT_SET));
			return false;
		}
		if (listSetting.selectedType === MoveToLocationTypes.MatingBatch) {
			if (!listSetting.batchCycleDays) {
				showAlert(localized(ExceptionMessage.VALIDATION_ERROR_CYCLEDAYS_NOT_SET));
				return false;
			}
		}

		return true;
	}

	private getFormRows() {
		let formRows = new Array<FormRow>();
		formRows.push(this.getBuildingHeader());
		formRows.push(
			getSkioldInputRow(
				setup => this.moveFromPregToPregSetupChanged(setup),
				this.state.movePregToPregSetup,
				'name',
				localized('name'),
			),
		);
		formRows.push(this.getAnimalToListRow());
		if (this.state.selectedOption.value === MoveToLocationTypes.MatingBatch) {
			formRows.push({
				name: localized('pregnantDays'),
				component: (
					<SkioldFormIntegerInput
						onChangeNumber={newNumber =>
							this.valueInMoveFromPregToPregSetupChanged(newNumber, 'batchCycleDays')
						}
						text={this.state.movePregToPregSetup.batchCycleDays}
					/>
				),
			});
		}

		formRows.push(this.getNegativeCycleDaysRow());
		formRows.push(this.getFeedCurves());

		formRows.push(
			getAvailablityRow(
				this.toggleShowOnWeb,
				this.toggleShowOnApp,
				this.state.movePregToPregSetup.showOnWeb!,
				this.state.movePregToPregSetup.showOnApp!,
			),
		);

		return formRows;
	}

	private valueInMoveFromPregToPregSetupChanged(value: any, key: keyof IMoveFromPregToPregSetting) {
		this.setState(prevState => ({
			movePregToPregSetup: {
				...prevState.movePregToPregSetup,
				[key]: value,
			},
		}));
	}

	private getBuildingHeader(): FormRow {
		return {
			header: localized(this.props.workListType),
		};
	}

	private getAnimalToListRow(): FormRow {
		return {
			name: localized('chooseAnimalToList'),
			component: (
				<SkioldFormDropdown
					items={this.state.options}
					selectedValue={this.state.selectedOption}
					onValueChanged={this.animalToListChanged}
				/>
			),
		};
	}

	private animalToListChanged = (option: Option) => {
		this.setState(prevState => ({
			movePregToPregSetup: {
				...prevState.movePregToPregSetup,
				selectedType: option.value as MoveToLocationTypes,
			},
			selectedOption: option,
		}));
	};

	private getFeedCurves(): FormRow {
		return {
			name: (
				<ViewWeb className="multiple-row-container">
					<TextWeb className="bold-font-size-sixteen">{localized('chooseFeedCurve')}:</TextWeb>
					<TextWeb className="bold-font-size-sixteen">{localized(AnimalKind.YoungFemale)}</TextWeb>
					<TextWeb className="bold-font-size-sixteen">{localized(AnimalKind.Gilt)}</TextWeb>
					<TextWeb className="bold-font-size-sixteen">{localized(AnimalKind.Sow)}</TextWeb>
				</ViewWeb>
			),
			component: (
				<ViewWeb className="curve-picker-container">
					<ViewWeb className="dummy-filler"></ViewWeb>
					<ViewWeb className="align-picker">
						<this.FeedCurvePicker animalKind={AnimalKind.YoungAnimal} />
					</ViewWeb>
					<ViewWeb className="align-picker">
						<this.FeedCurvePicker animalKind={AnimalKind.Gilt} />
					</ViewWeb>
					<ViewWeb className="align-picker">
						<this.FeedCurvePicker animalKind={AnimalKind.Sow} />
					</ViewWeb>
				</ViewWeb>
			),
			style: { height: 170 },
		};
	}

	private FeedCurvePicker = (props: { animalKind: AnimalKind }) => {
		const curveChanged = (option: Option) => {
			this.setState(prevState => {
				let movePregToPregSetup = deepCopy(prevState.movePregToPregSetup);
				if (!movePregToPregSetup.defaultFeedCurves) {
					movePregToPregSetup.defaultFeedCurves = [];
				}
				let splitValue = option.value.split('-');

				const feedCurveNumber = splitValue.length > 1 ? parseInt(splitValue[1]) : undefined;
				const locationCode = splitValue.length > 1 ? splitValue[0] : undefined;

				if (
					movePregToPregSetup.defaultFeedCurves.findIndex(curve => curve.animalKind === props.animalKind) ===
					-1
				) {
					movePregToPregSetup.defaultFeedCurves.push(
						new DefaultFeedCurve({
							animalKind: props.animalKind,
							feedCurveNumber: feedCurveNumber,
							locationCode: locationCode,
						}),
					);
				} else {
					movePregToPregSetup.defaultFeedCurves = movePregToPregSetup.defaultFeedCurves.map(defaultCurve =>
						defaultCurve.animalKind === props.animalKind
							? new DefaultFeedCurve({
									...defaultCurve,
									feedCurveNumber: feedCurveNumber,
									locationCode: locationCode,
							  })
							: defaultCurve,
					);
				}

				return { movePregToPregSetup };
			});
		};

		return (
			<SkioldFormDropdown
				items={this.props.feedCurvesOptions}
				theme={'dark'}
				containerClassName="grade-dropdown"
				selectedValue={
					this.state.movePregToPregSetup.defaultFeedCurves
						? this.renderOption(
								this.state.movePregToPregSetup.defaultFeedCurves.find(
									defaultCurve => defaultCurve.animalKind === props.animalKind,
								),
						  )
						: { label: '', value: '' }
				}
				onValueChanged={curveChanged}
			/>
		);
	};

	private getNegativeCycleDaysRow(): FormRow {
		return {
			name: localized('cycleDaysShownNegative'),
			component: (
				<ViewWeb className="two-checkbox-container">
					<SkioldCheckbox
						onClick={this.SetNegativeCycleDaysToTrue}
						isChecked={this.state.movePregToPregSetup.negativeCycleDays}
					/>
					<TextWeb>{localized('True')}</TextWeb>
					<SkioldCheckbox
						onClick={this.SetNegativeCycleDaysToFalse}
						isChecked={this.state.movePregToPregSetup.negativeCycleDays ? false : true}
					/>
					<TextWeb>{localized('False')}</TextWeb>
				</ViewWeb>
			),
		};
	}

	private SetNegativeCycleDaysToFalse = () => {
		this.setState(prevState => ({
			movePregToPregSetup: {
				...prevState.movePregToPregSetup,
				negativeCycleDays: false,
			},
		}));
	};

	private SetNegativeCycleDaysToTrue = () => {
		this.setState(prevState => ({
			movePregToPregSetup: {
				...prevState.movePregToPregSetup,
				negativeCycleDays: true,
			},
		}));
	};

	private getWorkListSetting(type: WorkListType) {
		let workListSetting = this.props.workListSettings.find(
			setting => setting.type === type,
		) as MoveFromPregToPregSetting;
		if (!workListSetting) {
			workListSetting = MoveFromPregToPregSetting.fromJS({ type, showOnApp: true });
		}
		return workListSetting;
	}

	private renderButtons() {
		return (
			<ViewWeb className="buttons-container">
				<SkioldButton title={localized('Save')} onPress={() => this.save()} />
			</ViewWeb>
		);
	}

	private renderOption = (defaultCurve: DefaultFeedCurve | undefined) => {
		if (defaultCurve) {
			const curve = this.props.feedCurvesOptions.find(
				curve =>
					defaultCurve.feedCurveNumber !== undefined &&
					defaultCurve.feedCurveNumber !== null &&
					curve.value === defaultCurve.locationCode + '-' + defaultCurve.feedCurveNumber.toString(),
			);

			if (curve) {
				return curve;
			}
		}
		return { label: '', value: '' };
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(MovePregnantToPregnantSetup);
