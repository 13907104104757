import { FileResponse, SkioldOneClient } from 'shared/api/api';
import { SkioldDigitalApiBaseUrl } from 'shared/constants';

import { savePdfAs } from './general-pdf-helper';

export function GetMatingBatchReportPdf(
	date: Date,
	siteId: string,
	fileName: string,
	timezone: string | undefined,
	lang?: string,
) {
	return new SkioldOneClient(SkioldDigitalApiBaseUrl)
		.matingBatchReport_GetMatingBatchReportPdf(date, siteId, timezone, lang)
		.then((fileResponse: FileResponse | null) => {
			savePdfAs(fileResponse, fileName);
		});
}
