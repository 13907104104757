import memoize from 'memoize-one';
import React from 'react';
import { connect } from 'react-redux';
import { exactStartsWithMethodGrid, rangeFilterMethod, rangeFilterMethodGrid } from 'shared/helpers/general-helpers';
import { NaturalSort } from 'shared/helpers/natural-sort';
import { checkSaveWorkListStatus } from 'shared/helpers/work-list-helpers/general-work-list-helper';
import {
	genereateVaccineWorkListData,
	isDistinctBuilding,
	isDistinctPen,
} from 'shared/helpers/work-list-helpers/vaccine-list-helpers/vaccine-work-list-helpers';
import {
	VaccineListTableConstants,
	VaccineSubColumn,
	VaccineWorkListItem,
	VaccineWorkListMapDispatchToProps,
	VaccineWorkListMapStateToProps,
	VaccineWorkListProps,
	VaccineWorkListState,
} from 'shared/helpers/work-list-helpers/vaccine-list-helpers/vaccine-worklist-items';
import { localized } from 'shared/state/i18n/i18n';
import SkioldTableGrid, {
	SkioldTableGrid as SkioldTableRef,
} from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { ShowConfirmAlert } from '../../skiold-alert/skiold-alert';
import { SkioldCheckbox } from '../../skiold-components/skiold-checkbox/skiold-checkbox';
import { GroupedHeader } from '../../skiold-components/skiold-table/skiold-table-grid/skiold-table-grid-grouped-header';
import { getAnimalNumberText, getStemAnimalNumberColor, getWorkListBuildSecString } from '../work-list-helper';
import '../list-setup.scss';
import '../list-setup.scss';
import { Sorting } from '@devexpress/dx-react-grid';
import IsEqual from 'react-fast-compare';
import { VaccinePregnancyType } from 'shared/api/api';

export class VaccineListTable extends React.PureComponent<VaccineWorkListProps, VaccineWorkListState> {
	public SkioldTableRef: SkioldTableRef | undefined;

	private CheckBox = memoize(({ isChecked, identifier }: any) => {
		const onClick = () => {
			const tableItem = this.state.workListData.find(e => e.identifier === identifier);
			if (tableItem) {
				this.commitedClicked(tableItem);
			}
		};
		return <SkioldCheckbox isChecked={isChecked!} onClick={onClick} />;
	});
	constructor(props: VaccineWorkListProps) {
		super(props);
		const workListData = genereateVaccineWorkListData(
			this.props.pregnancyEvents,
			this.props.matingBatches,
			this.props.workListSetting,
			this.props.locations,
			this.props.moveEvents,
			this.props.workListToTreatmentPlans,
			this.props.treatments,
			this.props.treatmentPlans,
			this.props.week?.value,
		).data.sort((a, b) => NaturalSort(b.cycleDays, a.cycleDays));
		this.state = {
			columns: this.generateColumns(workListData),
			loading: false,
			workListData,
			columnExte: this.generateColumnsExtensions(workListData),
			commitAll: false,
		};
	}

	//Only recalculate data when one of the inputs change
	// private generateData = memoize((animals, pregnancies, treatmentPlans, worklistSetting, locations, moveEvents) =>
	//     genereateVaccineWorkListData(this.props, this.state)
	// );

	public ResetWorkList() {
		let data = genereateVaccineWorkListData(
			this.props.pregnancyEvents,
			this.props.matingBatches,
			this.props.workListSetting,
			this.props.locations,
			this.props.moveEvents,
			this.props.workListToTreatmentPlans,
			this.props.treatments,
			this.props.treatmentPlans,
			this.props.week?.value,
		);
		this.setState({ workListData: data.data });
	}

	public componentDidMount() {
		if (this.SkioldTableRef) {
			this.props.setSowsCount(this.SkioldTableRef.GetSortedData().length);
		}

		this.props.pregnancyEventGetSyncData();
		this.props.stemAnimalGetSyncData();
		this.props.diagnoseGetSyncData();
		this.props.moveEventGetSyncData();
		this.props.treatmentDefinitionGetSyncData();
		this.props.treatmentGetSyncData();
		this.props.treatmentPlanGetSyncData();
		this.props.workListToTreatmentPlanGetSyncData();
		this.props.getLocations();
	}

	public async CheckSavePregnancyEventListsStatus() {
		if (await checkSaveWorkListStatus(this.state.workListData, ShowConfirmAlert)) {
			await this.SaveWorklist();
		}
		return true;
	}

	public componentDidUpdate(prevProps: VaccineWorkListProps) {
		if (!IsEqual(this.props.batch.value, prevProps.batch.value)) {
			this.ResetWorkList();
			this.props.SetCheckedCount(0, true);
			this.setState({ commitAll: false });
		}

		if (this.props.week && !IsEqual(this.props.week.value, prevProps.week?.value)) {
			this.ResetWorkList();
			this.props.SetCheckedCount(0, true);
			this.setState({ commitAll: false });
		}
	}

	public getWorkListSetting() {
		const { workListSetting } = this.props;
		return workListSetting;
	}

	private defaultSortHeaderId = [{ columnName: 'cycleDays', direction: 'desc' }] as Sorting[];
	public render() {
		return (
			<ViewWeb className="work-list-table">
				<ViewWeb className="view-container">
					<SkioldTableGrid
						tableKey={
							this.props.workListSetting.type
								? this.props.workListSetting.type.toString() +
								  this.state.columnExte.length.toString() +
								  this.props.workListSetting.id
								: 'vaccineWorklistTable1'
						}
						columns={this.state.columns}
						className={'work-list-table'}
						ref={this.setRef}
						data={
							this.props.workListSetting.vaccinePregnancyType !== VaccinePregnancyType.AgeYoungFemale
								? this.props.batch.value
									? this.state.workListData.filter(a => a.batch === this.props.batch.value)
									: this.state.workListData
								: this.state.workListData
						}
						ColumnExtensions={this.state.columnExte}
						sortHeaderId={this.defaultSortHeaderId}
						showPagination={true}
						groupedColumns={this.groupedColumns()}
					/>
				</ViewWeb>
			</ViewWeb>
		);
	}

	public setRef = (s: any) => {
		if (s) {
			this.SkioldTableRef = s;
			this.forceUpdate();
		}
	};

	public generateColumnsExtensions(data: VaccineWorkListItem[]) {
		const { workListSetting } = this.props;
		let columnExtensions: any = [];
		if (this.props.workListSetting.vaccinePregnancyType !== VaccinePregnancyType.AgeYoungFemale) {
			columnExtensions = [
				{
					columnName: 'cycleDays',
					width: VaccineListTableConstants.cycleDaysWidth,
				},
				{
					columnName: 'litterNumber',
					width: VaccineListTableConstants.cycleDaysWidth,
				},
				{
					columnName: 'animalNumber',
					width: VaccineListTableConstants.animalNrWidth,
				},
				{
					columnName: 'batch',
					width: VaccineListTableConstants.cycleDaysWidth,
				},
			];
		} else {
			columnExtensions = [
				{
					columnName: 'ageInWeeks',
					width: VaccineListTableConstants.cycleDaysWidth,
				},
				{
					columnName: 'animalNumber',
					width: VaccineListTableConstants.animalNrWidth,
				},
			];
		}

		workListSetting.vaccineSubTasks!.forEach(subTask => {
			if (subTask.vaccineDefinitionId) {
				columnExtensions.push({
					columnName: 'weekNumber' + subTask.subTaskNumber,
					width: VaccineListTableConstants.pcsWidth,
				});
			}
		});
		if (workListSetting.showLocationOnList) {
			columnExtensions.push({ columnName: 'buildSection', width: VaccineListTableConstants.cycleDaysWidth });
			if (!isDistinctPen(data)) {
				columnExtensions.push({ columnName: 'penString', width: VaccineListTableConstants.cycleDaysWidth });
			}
		}
		columnExtensions.push({ columnName: 'completed', width: VaccineListTableConstants.completedWidth });

		return columnExtensions;
	}

	public generateCommitAllCheckBox = () => {
		return (
			<ViewWeb className="checkbox-view-filter-style">
				<SkioldCheckbox
					isChecked={this.state.commitAll}
					className="checkbox-style-filter"
					onClick={this.commitAll}
				/>
			</ViewWeb>
		);
	};

	public commitAll = () => {
		const commitAll = !this.state.commitAll;
		this.setState(prevState => {
			const workListData = [...prevState.workListData];
			workListData
				.filter(wld => wld.batch === this.props.batch.value)
				.forEach(element => {
					const index = workListData.findIndex(wld => wld.identifier === element.identifier);
					if (index >= 0) {
						workListData[index] = { ...workListData[index], isChecked: commitAll };
					}
				});
			this.props.SetCheckedCount(
				workListData.reduce((a, b) => a + (b && b.isChecked ? 1 : 0), 0),
				true,
			);
			return { workListData, commitAll };
		});
	};

	public SaveWorklist = () => {
		const isCheckedItems = this.state.workListData.filter(item => item.isChecked);
		const identifiers = isCheckedItems.map(item => item.identifier);
		this.props.saveMultipleVaccinesByWorkList(this.props.workListSetting, isCheckedItems);
		const items = this.state.workListData.filter(data => !identifiers.includes(data.identifier));
		this.setState({ workListData: items });
	};

	private groupedColumns = () => {
		const groupedHeader: GroupedHeader[] = [];
		const { workListSetting } = this.props;
		if (workListSetting && workListSetting.vaccineSubTasks) {
			workListSetting.vaccineSubTasks!.forEach(subTask => {
				if (subTask.vaccineDefinitionId) {
					groupedHeader.push({
						title: subTask.columnText!,
						children: [{ columnName: 'weekNumber' + subTask.subTaskNumber }],
					});
				}
			});
		}
		groupedHeader.push({
			title: localized('location'),
			children: [{ columnName: 'buildSection' }, { columnName: 'penString' }],
		});

		return groupedHeader;
	};

	private getValueByKey(key: keyof VaccineSubColumn, item: VaccineWorkListItem, taskNumber: number) {
		const column = item.vaccineSubColumns!.find(col => col.subTaskNumber === taskNumber);
		if (column) {
			return column[key];
		}
		return '';
	}

	private animalNumberFilter = (value: any, filterValue: any, row: VaccineWorkListItem) => {
		return (
			row.animalNumber &&
			String(localized(row.animalNumber).toLocaleLowerCase()).startsWith(filterValue.value.toLocaleLowerCase())
		);
	};

	private generateColumns(data: VaccineWorkListItem[]) {
		const { workListSetting } = this.props;
		const showBuilding = !isDistinctBuilding(data);
		let columns: any = [];
		if (this.props.workListSetting.vaccinePregnancyType !== VaccinePregnancyType.AgeYoungFemale) {
			columns = [
				{
					name: 'cycleDays',
					title: localized('Days'),
					headerClassName: 'merged-header',
					filterFunction: rangeFilterMethodGrid,
					getCellValue: (tableItem: VaccineWorkListItem) =>
						tableItem.cycleDays && !isNaN(tableItem.cycleDays) ? tableItem.cycleDays : '',
				},
				{
					name: 'litterNumber',
					title: localized('Litters'),
					headerClassName: 'merged-header',
					filterFunction: rangeFilterMethodGrid,
					getCellValue: (tableItem: VaccineWorkListItem) =>
						tableItem.litterNumber && !isNaN(tableItem.litterNumber)
							? tableItem.litterNumber.toString()
							: '',
				},
				{
					name: 'animalNumber',
					headerClassName: 'merged-header',
					title: localized('animalNumber'),
					filterFunction: this.animalNumberFilter,
					className: getStemAnimalNumberColor,
					getCellValue: getAnimalNumberText,
				},
				{
					name: 'batch',
					title: localized('Batch'),
					headerClassName: 'merged-header',
					filterFunction: exactStartsWithMethodGrid,
					getCellValue: (tableItem: VaccineWorkListItem) => (tableItem.batch ? tableItem.batch : ''),
				},
			];
		} else {
			columns = [
				{
					name: 'ageInWeeks',
					title: localized('age'),
					headerClassName: 'merged-header',
					filterFunction: rangeFilterMethodGrid,
					getCellValue: (tableItem: VaccineWorkListItem) =>
						tableItem.ageInWeeks && !isNaN(tableItem.ageInWeeks) ? tableItem.ageInWeeks : '',
				},

				{
					name: 'animalNumber',
					headerClassName: 'merged-header',
					title: localized('animalNumber'),
					filterFunction: this.animalNumberFilter,
					className: getStemAnimalNumberColor,
					getCellValue: getAnimalNumberText,
				},
			];
		}

		workListSetting.vaccineSubTasks!.forEach(subTask => {
			if (subTask.vaccineDefinitionId) {
				columns.push({
					name: 'weekNumber' + subTask.subTaskNumber,
					title: localized('week'),
					filterFunction: rangeFilterMethod,
					getCellValue: (tableItem: VaccineWorkListItem) =>
						this.getValueByKey('weekNumber', tableItem, subTask.subTaskNumber!),
				});
			}
		});

		if (workListSetting.showLocationOnList) {
			columns.push({
				name: 'buildSection',
				title: localized('section'),
				headerClassName: 'merged-header',
				filterFunction: exactStartsWithMethodGrid,
				getCellValue: (tableItem: VaccineWorkListItem) =>
					getWorkListBuildSecString(showBuilding, tableItem.buildingString!, tableItem.sectionString!),
			});
			if (!isDistinctPen(data)) {
				columns.push({
					name: 'penString',
					title: localized('pen'),
					headerClassName: 'merged-header',
					filterFunction: exactStartsWithMethodGrid,
				});
			}
		}

		columns.push({
			name: 'completed',
			title: localized('completed'),
			headerClassName: 'merged-header',
			sortable: false,
			filter: this.generateCommitAllCheckBox,
			getCellValue: this.CheckBox,
		});

		return columns;
	}

	private commitedClicked = (tableItem: VaccineWorkListItem) => {
		const dataIndex = this.state.workListData.findIndex(a => a.identifier === tableItem.identifier);

		this.setState(prevState => {
			const workListData = [...prevState.workListData];
			workListData[dataIndex] = { ...workListData[dataIndex], isChecked: !tableItem.isChecked };
			this.props.SetCheckedCount(workListData[dataIndex].isChecked ? 1 : -1);
			return { workListData, commitAll: false };
		});
	};
}

export default connect(VaccineWorkListMapStateToProps, VaccineWorkListMapDispatchToProps, null, { forwardRef: true })(
	VaccineListTable,
) as any;
