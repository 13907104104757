import memoize from 'memoize-one';
import React from 'react';
import { Option } from 'react-dropdown';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IBuilding, IProcessEquipment, IProcessEquipmentData, ISection } from 'shared/api/api';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { deepCopy2 } from 'shared/helpers/general-helpers';
import {
	GetProcessEquipmentData,
	SaveProcessEquipmentLocationRelation,
} from 'shared/state/ducks/process-equipment-data/operations';
import * as UnitsToPenOperations from 'shared/state/ducks/unit-to-pen/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { ShowConfirmAlert } from '../skiold-alert/skiold-alert';
import { SkioldDropdown } from '../skiold-components/skiold-dropdown/skiold-dropdown';
import SkioldTableGrid, {
	SkioldTableGrid as SkioldTableRef,
} from '../skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { GroupedHeader } from '../skiold-components/skiold-table/skiold-table-grid/skiold-table-grid-grouped-header';
import { ViewWeb } from '../utils/web-view';
import './location-relation-table.scss';
import { LocationRelationTableItem } from './LocationRelation-helper';
import { NaturalSort } from 'shared/helpers/natural-sort';
import { ExtractLocationCode } from 'shared/helpers/process-equipment-helper/process-equipment-helper';

const mapStateToProps = (state: WebAppState) => {
	return {
		unitsToPen: state.unitToPenData.data,
		allProcessEquipmentDatas: state.processEquipmentData.entities,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	saveItem: (item: IProcessEquipmentData, reset: boolean = false) =>
		SaveProcessEquipmentLocationRelation(item, reset)(dispatch),
	getSyncData: () => GetProcessEquipmentData()(dispatch),
	getunitsData: () => UnitsToPenOperations.GetSyncData()(dispatch),
});

interface PropsFromParent {
	processEquipment: IProcessEquipment | undefined;
	loading: boolean;
	save: (method: () => void) => void;
	data: IProcessEquipmentData[] | undefined;
	buildings: IBuilding[] | undefined;
	sections: ISection[] | undefined;
	checkForUnsavedChanges?: (method: () => boolean) => void;
}
type LocationRelationTableProps = ReturnType<typeof mapStateToProps> &
	ReturnType<typeof mapDispatchToProps> &
	PropsFromParent;

interface LocationRelationTableState {
	columns: any[];
	columnExtension: any[];
	locationRelationItems: LocationRelationTableItem[];
	locationRelationItemsCopy: LocationRelationTableItem[];
	groupedHeaders: GroupedHeader[];
	buildingOptions: Option[];
}

export class LocationRelationTable extends React.PureComponent<LocationRelationTableProps, LocationRelationTableState> {
	public SkioldTableRef: SkioldTableRef | undefined;
	private generateData = memoize((processEquipmentData, processEquipment) =>
		LocationRelationTable.getData(processEquipmentData, processEquipment),
	);
	private readonly onSectionOnEquipmentChanged = (section: Option, tableData: LocationRelationTableItem) =>
		this.sectionChanged(section, tableData);

	constructor(props: LocationRelationTableProps) {
		super(props);
		this.state = {
			columns: this.generateColumns(),
			columnExtension: [],
			locationRelationItems: this.generateData(this.props.data, this.props.processEquipment),
			locationRelationItemsCopy: this.generateData(this.props.data, this.props.processEquipment),
			groupedHeaders: this.generateGroupedHeaders(),
			buildingOptions: [{ value: '', label: ' ' }].concat(
				props.buildings
					? props.buildings.map(building => ({
							value: building.id ? building.id : '',
							label:
								(building.buildingNumber ? building.buildingNumber.toString() : '') +
								' ' +
								building.name!,
					  }))
					: [],
			),
		};
	}

	public static getDerivedStateFromProps(
		nextProps: LocationRelationTableProps,
		prevState: LocationRelationTableState,
	): Partial<LocationRelationTableState> {
		if (!nextProps.processEquipment) {
			return {
				locationRelationItems: [],
				locationRelationItemsCopy: [],
			};
		}

		let dataFromProps = LocationRelationTable.getData(nextProps.data!, nextProps.processEquipment);
		let prevLocationItems = [...prevState.locationRelationItems];
		dataFromProps.forEach(item => {
			if (!prevLocationItems.find(tableItem => tableItem.id === item.id)) {
				prevLocationItems.push(item);
			}
		});
		if (
			prevState.locationRelationItemsCopy[0] === undefined ||
			prevState.locationRelationItemsCopy[0].id !== prevLocationItems[0].id
		) {
			return {
				locationRelationItems: prevLocationItems,
				locationRelationItemsCopy: deepCopy2(prevLocationItems),
			};
		}

		return {
			locationRelationItems: prevLocationItems,
		};
	}

	public componentDidUpdate(prevProps: LocationRelationTableProps) {
		if (prevProps.loading !== this.props.loading) {
			this.setState({
				locationRelationItems: this.generateData(this.props.data, this.props.processEquipment),
				locationRelationItemsCopy: this.generateData(this.props.data, this.props.processEquipment),
			});
		}
	}
	public componentDidMount() {
		this.props.save(async () => await this.save());
		if (this.props.checkForUnsavedChanges) {
			this.props.checkForUnsavedChanges(this.checkForUnsavedChanges);
		}
	}

	public checkForUnsavedChanges = () => {
		for (let index = 0; index < this.state.locationRelationItems.length; index++) {
			const item = this.state.locationRelationItems[index];

			let lastLocationRelationItemCopy = this.state.locationRelationItemsCopy.find(lr => lr.id === item.id);
			if (
				lastLocationRelationItemCopy &&
				(item.sectionId !== lastLocationRelationItemCopy.sectionId ||
					(item.buildingId !== lastLocationRelationItemCopy.buildingId &&
						(item.buildingId === undefined || item.sectionId !== undefined)))
			) {
				return true;
			}
		}
		return false;
	};

	private async save() {
		let ArrayOfPromises = Array<Promise<void>>();
		let reset = false;
		for (let index = 0; index < this.state.locationRelationItems.length; index++) {
			const item = this.state.locationRelationItems[index];
			if (this.props.processEquipment) {
				let lastLocationRelationItemCopy = this.state.locationRelationItemsCopy.find(lr => lr.id === item.id);
				if (
					lastLocationRelationItemCopy &&
					(item.sectionId !== lastLocationRelationItemCopy.sectionId ||
						(item.buildingId !== lastLocationRelationItemCopy.buildingId &&
							(item.buildingId === undefined || item.sectionId !== undefined)))
				) {
					let utp = this.props.unitsToPen.find(
						u =>
							this.props.allProcessEquipmentDatas.find(
								a =>
									this.props.processEquipment &&
									this.props.processEquipment.code === ExtractLocationCode(a.code) &&
									u.processEquipmentDataId === a.id &&
									item.sectionCode === a.sectionCode &&
									a.buildingCode === item.actualBuildingCode &&
									!a.isFromImport,
							) !== undefined,
					);
					if (utp !== undefined && reset === false) {
						if (
							await ShowConfirmAlert(
								localized(
									ExceptionMessage.VALIDATION_WARNING_CHANGING_SECTION_OR_BUILDING_WILL_RESULT_IN_RESET_FOR_CHANGED_BUILDINGS_SECTIONS,
								),
							)
						) {
							reset = true;
						}
					}
					if (utp !== undefined && reset) {
						ArrayOfPromises.push(
							this.props.saveItem(
								{
									id: item.id!,
									buildingCode: item.actualBuildingCode,
									sectionCode: item.sectionCode,
									buildingId: item.sectionId ? item.buildingId : undefined,
									sectionId: item.sectionId,
									equipmentType: item.ProcessEquipmentType,
								} as IProcessEquipmentData,
								reset,
							),
						);
					} else if (utp === undefined) {
						ArrayOfPromises.push(
							this.props.saveItem({
								id: item.id!,
								buildingCode: item.actualBuildingCode,
								sectionCode: item.sectionCode,
								buildingId: item.sectionId ? item.buildingId : undefined,
								sectionId: item.sectionId,
								equipmentType: item.ProcessEquipmentType,
							} as IProcessEquipmentData),
						);
					}

					let copyOfItems = [...this.state.locationRelationItemsCopy];
					copyOfItems[index] = { ...item };
					this.setState({ locationRelationItemsCopy: copyOfItems });
				}
			}
		}

		if (ArrayOfPromises.length > 0) {
			await Promise.all(ArrayOfPromises);
			ArrayOfPromises = [];
			ArrayOfPromises.push(this.props.getSyncData());
			ArrayOfPromises.push(this.props.getunitsData());
			await Promise.all(ArrayOfPromises);
		}
	}

	private static getData(dataFromProps: IProcessEquipmentData[], processEquipment: IProcessEquipment | undefined) {
		let data: LocationRelationTableItem[] = [];

		if (dataFromProps && processEquipment) {
			dataFromProps.forEach(item => {
				if (
					ExtractLocationCode(item.code) === processEquipment.code &&
					item.equipmentType === processEquipment.equipment &&
					item.isFromImport
				) {
					data.push({
						id: item.id,
						ProcessEquipmentType: item.equipmentType,
						buildingCodeToShow: item.buildingCode,
						actualBuildingCode: item.buildingCode,
						sectionCode: item.sectionCode,
						buildingId: item.buildingId && item.buildingId.length > 0 ? item.buildingId : undefined,
						sectionId: item.sectionId && item.sectionId.length > 0 ? item.sectionId : undefined,
					});
				}
			});
		}
		return data.sort((locationRelationTableItemA, locationRelationTableItemB) => {
			if (locationRelationTableItemA.actualBuildingCode !== locationRelationTableItemB.actualBuildingCode) {
				return NaturalSort(
					locationRelationTableItemA.actualBuildingCode,
					locationRelationTableItemB.actualBuildingCode,
				);
			} else {
				return NaturalSort(locationRelationTableItemA.sectionCode, locationRelationTableItemB.sectionCode);
			}
		});
	}

	public render() {
		return (
			<ViewWeb className="location-relation-table">
				<SkioldTableGrid
					tableKey={'locationRelationListTable'}
					columns={this.state.columns}
					ref={this.setRef}
					filterable={false}
					data={this.props.loading ? [] : this.state.locationRelationItems}
					ColumnExtensions={this.state.columnExtension}
					showPagination={true}
					groupedColumns={this.generateGroupedHeaders()}
					headerCapitalized={false}
				/>
			</ViewWeb>
		);
	}

	public setRef = (s: any) => {
		if (s) {
			this.SkioldTableRef = s;
		}
	};

	private generateGroupedHeaders = () => {
		const groupedHeader: GroupedHeader[] = [];

		groupedHeader.push({
			title:
				this.props.processEquipment && this.props.processEquipment.equipment
					? localized('locations') + ' ' + this.props.processEquipment.equipment.toUpperCase()
					: localized('locationsProcessEquipment'),
			children: [{ columnName: 'buildingCodeToShow' }, { columnName: 'sectionCode' }],
		});
		groupedHeader.push({
			title: localized('locationsSkioldDigital'),
			children: [{ columnName: 'buildingSkioldDigital' }, { columnName: 'sectionSkioldDigital' }],
		});
		return groupedHeader;
	};

	private getSectionOptionsByBuilding(buildingId) {
		// Don't allow two different controllers to map to the same section/pen
		const forbiddenSectionIds = this.props.allProcessEquipmentDatas
			.filter(
				peq =>
					this.props.processEquipment && ExtractLocationCode(peq.code) !== this.props.processEquipment.code,
			)
			.map(peqSection => peqSection.sectionId);
		return [{ value: '', label: ' ' }].concat(
			this.props.sections && buildingId
				? this.props.sections
						.filter(
							section => section.buildingId === buildingId && !forbiddenSectionIds.includes(section.id),
						)
						.map(section => {
							const label = section.name! + ' ' + localized(section.type!);
							return { value: section.id!, label: label };
						})
				: [],
		);
	}

	private generateColumns = () => {
		const columns: any = [
			{
				name: 'buildingCodeToShow',
				title: localized('building'),
				sortable: false,
			},
			{
				name: 'sectionCode',
				title: localized('section'),
				sortable: false,
			},
			{
				name: 'buildingSkioldDigital',
				title: localized('building'),
				sortable: false,
				getCellValue: (tableData: LocationRelationTableItem) => (
					<SkioldDropdown
						usedInTable={true}
						theme={'dark'}
						menuClassname="max-height-dropdown"
						onValueChanged={(building: Option) =>
							this.buildingChanged(building, tableData.id, tableData.actualBuildingCode)
						}
						items={this.state.buildingOptions}
						selectedValue={
							tableData.buildingId
								? this.state.buildingOptions.find(option => option.value === tableData.buildingId)
								: { value: '', label: ' ' }
						}
						dropdownIdentifier={'location-relation-building'}
					/>
				),
			},
			{
				name: 'sectionSkioldDigital',
				title: localized('section'),
				sortable: false,
				getCellValue: (tableData: LocationRelationTableItem) => {
					const building =
						this.props.buildings && this.props.buildings.find(b => tableData.buildingId === b.id);
					if (building === undefined || building.useSections === false) {
						return <div />;
					} else {
						return (
							<SkioldDropdown
								itemFromParent={tableData}
								usedInTable={true}
								theme={'dark'}
								menuClassname="max-height-dropdown"
								onValueChanged={this.onSectionOnEquipmentChanged}
								items={this.getSectionOptionsByBuilding(tableData.buildingId)}
								selectedValue={this.getSelectedSectionOption(tableData.sectionId)}
								dropdownIdentifier={'location-relation-section'}
							/>
						);
					}
				},
			},
		];
		return columns;
	};

	private getSelectedSectionOption = sectionId => {
		if (sectionId && this.props.sections) {
			const sectionExist = this.props.sections.find(section => section.id === sectionId);
			if (sectionExist) {
				return { value: sectionExist.id!, label: sectionExist.name! + ' ' + localized(sectionExist.type!) };
			}
		}
		return { value: '', label: ' ' };
	};

	private sectionChanged = (sectionOption: Option, dataItem: LocationRelationTableItem) => {
		this.setState(prevState => {
			const locationRelationItems = [...prevState.locationRelationItems];
			const dataIndex = locationRelationItems.findIndex(item => item.id === dataItem.id);
			locationRelationItems[dataIndex] = {
				...locationRelationItems[dataIndex],
				sectionId: sectionOption.value && sectionOption.value.length > 0 ? sectionOption.value : undefined,
			};
			return { locationRelationItems };
		});
	};

	private buildingChanged = (buildingOption: Option, objectID: string | undefined, buildingCode) => {
		this.setState(prevState => {
			const locationRelationItems = [...prevState.locationRelationItems];
			for (const [i, value] of locationRelationItems.entries()) {
				if (value.id === objectID || !value.sectionId) {
					let prevStateItem = prevState.locationRelationItems[i];
					locationRelationItems[i] = {
						...locationRelationItems[i],
						buildingId:
							buildingOption.value && buildingOption.value.length > 0 ? buildingOption.value : undefined,
						sectionId:
							prevStateItem.buildingId === buildingOption.value ? prevStateItem.sectionId : undefined,
					};
				}
			}

			return { locationRelationItems };
		});
	};
}
export default connect(mapStateToProps, mapDispatchToProps)(LocationRelationTable);
