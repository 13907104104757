import React from 'react';
import { IVisit } from 'shared/api/api';
import { getTimeDateString } from 'shared/helpers/date-helpers';
import { localized } from 'shared/state/i18n/i18n';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { SkioldTable } from '../../skiold-components/skiold-table/skiold-table';
import { setTwoDecimalsAsString } from 'shared/helpers/general-helpers';

interface PropsFromParent {
	visit: IVisit | undefined;
	visitNumber: string;
}

interface VisitState {
	visits: IVisit[];
}

export class VisitEsf extends React.PureComponent<PropsFromParent, VisitState> {
	constructor(props) {
		super(props);
		this.state = { visits: [this.props.visit ? this.props.visit : ({} as IVisit)] };
	}
	public render() {
		return <ViewWeb className="sow-card-treatments">{this.renderTable()}</ViewWeb>;
	}

	public renderTable() {
		return (
			<ViewWeb>
				<SkioldTable
					columns={this.generateColumns()}
					data={this.state.visits}
					filterable={false}
					sortable={false}
					showNoDataText={false}
					className="skiold-double-header-table"
				/>
			</ViewWeb>
		);
	}
	private getVisitNumber() {
		switch (this.props.visitNumber) {
			case '0':
				return 'Visit0';
			case '1':
				return 'Visit1';
			case '2':
				return 'Visit2';
			case '3':
				return 'Visit3';
			default: {
				return 'Visit0';
			}
		}
	}

	private generateColumns() {
		return [
			{
				Header: localized(this.getVisitNumber()),
				columns: [
					{
						id: 'DateAndTime',
						Header: localized('DateAndTime'),
						sortable: false,
						filterable: false,
						accessor: this.getDateTimeFromVisit,
					},
					{
						id: 'FE',
						Header: localized('FE'),
						sortable: false,
						filterable: false,
						accessor: this.getFeFromVisit,
					},
					{
						id: 'Station',
						Header: localized('Station'),
						sortable: false,
						filterable: false,
						accessor: this.getStationFromVisit,
					},
				],
			},
		];
	}

	private getDateTimeFromVisit = (visit: IVisit) => (
		<TextWeb>{this.CheckIfVisitHasAllValues(visit) ? getTimeDateString(visit.date) : ' '} </TextWeb>
	);
	private getStationFromVisit = (visit: IVisit) => (
		<TextWeb>{this.CheckIfVisitHasAllValues(visit) ? visit.stationNumber : ' '}</TextWeb>
	);
	private getFeFromVisit = (visit: IVisit) => (
		<TextWeb>{this.CheckIfVisitHasAllValues(visit) ? setTwoDecimalsAsString(visit.feed) : ' '}</TextWeb>
	);

	private CheckIfVisitHasAllValues(visit: IVisit) {
		return visit && visit.stationNumber && visit.date;
	}
}
