import React from 'react';
import { Option } from 'react-dropdown';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ActiveAnimalsReportSetting, AnimalKind, AnimalReportType } from 'shared/api/api';
import * as PoolYoungFemaleOperations from 'shared/state/ducks/pool-young-female/operations';
import { GetDeparturedAnimals, GetYoungFemalesByDate } from 'shared/state/ducks/stem-animals/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { SkioldDropdown } from 'web/view/components/skiold-components/skiold-dropdown/skiold-dropdown';
import { ViewWeb } from 'web/view/components/utils/web-view';
import './active-animals-report-list.scss';
import AnimalNumberOnlyTable from './animal-number-only/animal-number-only-table';
import { GetDeparturedPregnancies } from 'shared/state/ducks/pregnancy-events/operations';

const mapStateToProps = (state: WebAppState) => {
	return {
		animalReportSetting: state.animalReportSettings.entities.find(
			setting => setting.type === AnimalReportType.ActiveAnimalsReportSetting,
		) as ActiveAnimalsReportSetting,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => {
	return {
		getDeparturedAnimals: () => GetDeparturedAnimals()(dispatch),
		poolYoungTableGetSyncDate: () => PoolYoungFemaleOperations.GetSyncData()(dispatch),
		getDeparturedPregnancies: () => GetDeparturedPregnancies()(dispatch),
		getYoungFemalesByDate: (date:Date) => GetYoungFemalesByDate(date)(dispatch),
	};
};

export interface State {
	showTopLeft: boolean;
	selectedAnimalKind: Option;
	animalKinds: AnimalKind[];
}

export interface PropsFromParent {
	topLeft?: (topLeft: JSX.Element) => void;
	topMiddle?: (topLeft: JSX.Element) => void;
	topRight?: (topRight: JSX.Element) => void;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;
class ActiveAnimalReportList extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			showTopLeft: false,
			selectedAnimalKind: { label: localized('SowGilt'), value: AnimalKind.Sow },
			animalKinds: this.getAnimalKind(AnimalKind.Sow),
		};
		this.props.getDeparturedAnimals();
		this.props.getDeparturedPregnancies();
		this.props.poolYoungTableGetSyncDate();
	}

	public componentDidMount() {
		this.renderTopMiddle();
	}

	private getAnimalKind = (animalKind: AnimalKind) => {
		switch (animalKind) {
			case AnimalKind.Sow:
				return [AnimalKind.Sow, AnimalKind.Gilt];
			case AnimalKind.YoungFemale:
				return [AnimalKind.YoungFemale];
			case AnimalKind.Boar:
				return [AnimalKind.Boar];
			default:
				return [];
		}
	};

	public render() {
		return (
			<ViewWeb className="active-animals-report-list">
				{/* TEMP until further impl
					this.props.animalReportSetting?this.props.animalReportSetting.option === ActiveAnimalOption.AnimalNumberOnly */}
				<AnimalNumberOnlyTable
					selectedAnimalKindsText={this.state.selectedAnimalKind.label}
					animalKinds={this.state.animalKinds}
					topLeft={this.setTopLeft}
					topRight={this.setTopRight}
				/>
				{/* {this.state.showTopLeft ? this.topLeft() : <ViewWeb />} */}
			</ViewWeb>
		);
	}

	private optionData: Option[] = [
		{ label: localized('SowGilt'), value: AnimalKind.Sow },
		{ label: localized(AnimalKind.Boar), value: AnimalKind.Boar },
		{ label: localized(AnimalKind.YoungFemale), value: AnimalKind.YoungFemale },
	];

	private renderTopMiddle = () => {
		if (this.props.topMiddle) {
			this.props.topMiddle(
				<SkioldDropdown
					onValueChanged={this.selectedAnimalKindChanged}
					selectedValue={this.state.selectedAnimalKind}
					items={this.optionData}
					containerClassName="select-report-type sizing"
				/>,
			);
		}
	};

	public selectedAnimalKindChanged = (option: Option) => {
		this.setState(
			{
				selectedAnimalKind: option,
				animalKinds: this.getAnimalKind(option.value as AnimalKind),
			},
			() => {
				this.renderTopMiddle();
			},
		);
	};

	private setTopLeft = (res: JSX.Element) => {
		if (this.props.topLeft) {
			this.props.topLeft(res);
		}
	};

	private setTopRight = (res: JSX.Element) => {
		if (this.props.topRight) {
			this.props.topRight(res);
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ActiveAnimalReportList);
