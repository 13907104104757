import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Gender, IStemAnimal } from 'shared/api/api';
import { dateFormatDash, formatDate } from 'shared/helpers/date-helpers';
import { isNullOrUndefined } from 'shared/helpers/general-helpers';
import { UpsertManyYoungAnimal } from 'shared/state/ducks/stem-animals/operations';
import { localized, localizedDynamic } from 'shared/state/i18n/i18n';
import { DepartureTypes } from 'shared/state/models/departure-types';
import PageContainer from 'web/view/components/page-container/page-container';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldDatePicker } from 'web/view/components/skiold-components/skiold-date-picker/skiold-date-picker';
import { WhiteText } from 'web/view/components/Text/white-text';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';

interface PropsFromParent {
	closeModal: () => void;
	youngAnimals: IStemAnimal[] | undefined;
}

const formatGender = (gender: Gender | undefined) => {
	return gender === Gender.Female ? 0 : 1;
};

const format = (data: string | number | undefined) => {
	return isNullOrUndefined(data) ? '' : data;
};

const generateExportData = (youngAnimals: IStemAnimal[] | undefined, date: Date) => {
	let content = 'SKIOLD-DIGITAL-GENERATED\n';
	if (youngAnimals) {
		youngAnimals.forEach(ya => {
			content += `${format(ya.idNumber)},${formatDate(ya.birthDate, dateFormatDash)},${formatGender(
				ya.gender,
			)},${formatDate(date, dateFormatDash)},${format(ya.race)},${format(ya.raceLine)},${format(
				ya.momAnimalNumber,
			)},${format(ya.momIdNumber)},${format(ya.dadAnimalNumber)},${format(ya.dadIdNumber)},${format(
				ya.breedIndex,
			)}\n`;
		});
	}

	return content;
};

export const ExportData: FC<PropsFromParent> = React.memo(({ closeModal, youngAnimals }) => {
	const [date, setDate] = useState<Date>(new Date());
	const dispatch = useDispatch();
	const exportData = () => {
		// Create a blob object with the file content which you want to add to the file
		const file = new Blob([generateExportData(youngAnimals, date)], { type: 'text/plain' });
		const url = URL.createObjectURL(file);
		const link = document.createElement('a');
		link.download = `${localized('youngAnimals')}_${formatDate(date, dateFormatDash)}`;
		link.href = url;
		link.click();
		if (youngAnimals) {
			dispatch(
				UpsertManyYoungAnimal(
					youngAnimals.map(ya => ({
						...ya,
						departureDate: date,
						departureType: DepartureTypes.departureTypeSold,
					})),
				),
			);
			closeModal();
		}
	};

	return (
		<PageContainer>
			<ViewWeb className="align-center">
				<ViewWeb className="flex-row-space-evenly default-spacing default-gap">
					<WhiteText>{localized('departureDate')}:</WhiteText>
					<SkioldDatePicker onDateChanged={setDate} selectedDate={date} />
				</ViewWeb>
				<ViewWeb className="flex-row default-spacing">
					<SkioldButton theme="grey" onPress={closeModal} title={localized('Close')} />
					<SkioldButton onPress={exportData} title={localized('ExportYoungAnimals')} />
				</ViewWeb>
			</ViewWeb>
		</PageContainer>
	);
});
