import { Dispatch } from 'redux';
import { Option } from 'react-dropdown';
import { AnimalType, IBuilding, ISection, LocationType, ProductionForm } from 'shared/api/api';
import { CheckSectionForAnimalType, ILocationModel, getLocationPlaceholder } from '../location-helper';
import { SetCurrentLocation } from 'shared/state/ducks/locations/operations';
import { SharedAppState } from 'shared/state/store.shared';
import { CurrentLocation } from 'shared/state/ducks/locations/types';
import { memoizeLocation } from '../memoize-getters/memoize-getters';

export interface SkioldStableSectionPickerPropsFromParent {
	onStableSectionSelected: (selectedSectionId: string, itemFromParent?: any) => void;
	itemFromParent?: any;
	filteredLocations?: ILocationModel;
	usedInsideForm?: boolean;
	usedInsideTable?: boolean;
	containerClassName?: string;
	penId?: string;
	sectionId?: string;
	isPigProduction?: boolean;
	disabled?: boolean;
	theme?: 'light' | 'dark';
	dropdownIdentifier?: string;
	inputContainerStyle?: any;
	containerStyle?: any;
	rippleInsets?: any;
	itemPadding?: number;
	useShortLocation?: boolean;
	AnimalType?: AnimalType;
	allowYoungFemale?: boolean;
	locationType?: LocationType;
	usePlaceholder?: boolean;
	useCurrentLocationFromParent?: boolean;
	pickerStyle?: any;
}

export const skioldStableSectionPickerMapStateToProps = (state: SharedAppState) => {
	return {
		locations: memoizeLocation(
			state.locations.buildings,
			state.locations.sections,
			state.locations.pens,
			state.locations.valves,
		),
		useCurrentLocation: state.locations.useCurrentLocation,
		currentLocation: state.locations.currentLocation,
	};
};

export const skioldStableSectionPickerMapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	setCurrentLocation: (currentLocation: CurrentLocation) => SetCurrentLocation(currentLocation)(dispatch),
});

export const DefaultStableSectionOption: Option = { label: ' ', value: '' };

export interface SkioldStableSectionPickerState {
	selectedSection: Option;
	penId?: string;
	sectionId?: string;
}

export type skioldStableSectionPickerProps = ReturnType<typeof skioldStableSectionPickerMapStateToProps> &
	ReturnType<typeof skioldStableSectionPickerMapDispatchToProps> &
	SkioldStableSectionPickerPropsFromParent;

export function getStableSections(props: skioldStableSectionPickerProps): Option[] {
	const stableSections: Option[] = props.usePlaceholder ? [getLocationPlaceholder()] : [DefaultStableSectionOption];
	const optionFunc = props.useShortLocation ? getOptionShort : getOption;
	let locationState = props.filteredLocations ? props.filteredLocations : props.locations;
	let sortedSections = sortSections(locationState.sections);
	sortBuildings(locationState.buildings).forEach(building => {
		if (
			!props.isPigProduction ||
			(props.isPigProduction && building.productionForm === ProductionForm.PigProduction)
		) {
			let sections = sortedSections.filter(
				sortSec =>
					sortSec.buildingId === building.id &&
					((sortSec.animalType &&
						props.AnimalType &&
						CheckSectionForAnimalType(
							sortSec,
							props.AnimalType,
							props.locationType,
							props.allowYoungFemale,
						)) ||
						!props.AnimalType),
			);

			sections.forEach(section => {
				if (section) {
					stableSections.push(optionFunc(building, section));
				}
			});
		}
	});

	return stableSections;
}

export function getStableSectionOptions(buildings: IBuilding[], sections: ISection[]): Option[] {
	const stableSections: Option[] = [DefaultStableSectionOption];

	let sortedSections = sortSections(sections);
	sortBuildings(buildings).forEach(building => {
		let sections = sortedSections.filter(sortSec => sortSec.buildingId === building.id);

		sections.forEach(section => {
			if (section) {
				stableSections.push(getOption(building, section));
			}
		});
	});

	return stableSections;
}

export function sortBuildings(buildings: IBuilding[]) {
	return [...buildings].sort((a, b) => (a.order! > b.order! ? 1 : b.order! > a.order! ? -1 : 0));
}

export function sortSections(sections: ISection[]) {
	return [...sections].sort((a, b) => {
		return a.order! > b.order! ? 1 : b.order! > a.order! ? -1 : 0;
	});
}

export function getOptionShort(building: IBuilding, section: ISection) {
	if (building.useSections) {
		return { label: section.name ? section.name : '', value: section.id! };
	} else {
		return { label: building.name ? building.name : '', value: section.id! };
	}
}

export function setLocation(
	penId: string | undefined,
	sectionId: string | undefined,
	props: skioldStableSectionPickerProps,
	state: SkioldStableSectionPickerState,
	compoenent: any,
	stableSectionChanged: (option: Option) => void,
) {
	if (penId || sectionId) {
		let pen = props.locations.pens.find(s => s.id === penId);
		const section = props.locations.sections.find(s => (pen && s.id === pen.sectionId) || s.id === sectionId);

		if (!section) {
			return;
		}
		const building = props.locations.buildings.find(s => s.id === section!.buildingId);
		if (!building) {
			return;
		}
		if (penId) {
			compoenent.setState({ sectionId, penId });
		} else if (sectionId) {
			compoenent.setState({ sectionId });
		}
		stableSectionChanged(getOption(building, section));
		// this.setState({ selectedSection: this.getOption(building, section) });
	} else {
		compoenent.setState({ sectionId: undefined, penId: undefined });
		stableSectionChanged({ label: ' ', value: '' });

		// this.setState({ selectedSection: { label: ' ', value: '' } });
	}
}

export function getOption(building: IBuilding, section: ISection): Option {
	const buildingSectionString = `${building.name} ${building.useSections ? ` - ${section.name}` : ''} `;
	let option = { label: buildingSectionString, value: section.id! }; // The value is just the section, we dont really need the stable
	return option;
}
