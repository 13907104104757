import React from 'react';
import { setOneDecimalsAsNumber } from 'shared/helpers/general-helpers';
import {
	GrowthPigDepartureFromSectionRow,
	GrowthPigDepartureFromSectionTablePropsFromParent,
} from 'shared/helpers/growth-pigs-helper/growth-pig-event-helper';
import { localized } from 'shared/state/i18n/i18n';
import { DepartureTypes } from 'shared/state/models/departure-types';
import { SkioldFormDecimalInput } from '../../skiold-components/skiold-decimal-input/skiold-form-decimal-input';
import { SkioldFormIntegerInput } from '../../skiold-components/skiold-integer-input/skiold-form-integer-input';
import SkioldTableGrid from '../../skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import './growth-pig-distribute.scss';
import { getDateString } from 'shared/helpers/date-helpers';
import { getPenRetentionTime } from 'shared/helpers/location-helper';

export class GrowthPigDepartureFromSectionTable extends React.PureComponent<
	GrowthPigDepartureFromSectionTablePropsFromParent
> {
	public render() {
		return (
			<SkioldTableGrid
				ignoreSetCount={true}
				filterable={false}
				sortable={false}
				ColumnExtensions={this.generateColumnExtes()}
				columns={this.generateColumns()}
				data={this.props.data}
				containerClassName={'growth-distribution-table-max-height'}
			/>
		);
	}
	private getPenClassName = (item: GrowthPigDepartureFromSectionRow) =>
		item.hasQuarantine ? 'retention-time-list-color' : '';
	private getPenNameCell = (item: GrowthPigDepartureFromSectionRow) => item.penName;
	private getRestCell = (item: GrowthPigDepartureFromSectionRow) => (
		<SkioldFormIntegerInput
			className="tableTextStyle"
			text={item.rest}
			maxNumber={item.amount}
			itemFromParent={item}
			onChangeNumber={this.props.onRestFromSectionNumberChanged}
		/>
	);
	private getAmountCell = (item: GrowthPigDepartureFromSectionRow) => item.amount;
	private getRetentionTimeCell = (item: GrowthPigDepartureFromSectionRow) =>
		getDateString(getPenRetentionTime(item.penId));
	private getWeightCell = (item: GrowthPigDepartureFromSectionRow) =>
		this.props.isDeparture && item.totalWeightCurve && item.amount ? (
			setOneDecimalsAsNumber(item.totalWeightCurve / item.amount)
		) : (
			<SkioldFormDecimalInput
				className="tableTextStyle"
				text={item.newAvgWeight ? setOneDecimalsAsNumber(item.newAvgWeight) : undefined}
				itemFromParent={item}
				onChangeNumber={this.props.onDepartureFromSectionAvgWeightChanged}
			/>
		);

	private getDeparturedWeightCell = (item: GrowthPigDepartureFromSectionRow) => (
		<SkioldFormDecimalInput
			className="tableTextStyle"
			text={setOneDecimalsAsNumber(item.newAvgWeight)}
			itemFromParent={item}
			onChangeNumber={this.props.onDepartureFromSectionAvgWeightChanged}
		/>
	);

	private getMovedCell = (item: GrowthPigDepartureFromSectionRow) => (
		<SkioldFormIntegerInput
			className="tableTextStyle"
			text={item.departedFromSection}
			maxNumber={item.amount}
			itemFromParent={item}
			onChangeNumber={this.props.onDepartureFromSectionNumberChanged}
		/>
	);

	private generateColumns = () => {
		const columns = [
			{
				name: 'weight',
				sortable: false,
				filterable: false,
				title: localized('Weight'),
				getCellValue: this.getWeightCell,
			},
			{
				name: 'DepartedFromSection',
				sortable: false,
				filterable: false,
				title: this.props.departedFromSectionText,
				getCellValue: this.getMovedCell,
			},
		] as any[];
		if (this.props.usesPen) {
			columns.unshift({
				name: 'pen',
				className: this.getPenClassName,
				title: localized('PenNumber'),
				getCellValue: this.getPenNameCell,
			});
		}

		if (
			this.props.isDeparture &&
			(this.props.departureType !== DepartureTypes.departureTypeKilled || this.props.usePrivateSlaughterhouse)
		) {
			columns.push({
				name: 'departuredWeight',
				sortable: false,
				filterable: false,
				title: localized('Weight'),
				getCellValue: this.getDeparturedWeightCell,
			});
		}
		if (this.props.onRestFromSectionNumberChanged) {
			columns.push({
				name: 'rest',
				sortable: false,
				filterable: false,
				title: localized('rest'),
				getCellValue: this.getRestCell,
			});
			columns.unshift({
				name: 'amount',
				sortable: false,
				filterable: false,
				title: localized('Count'),
				getCellValue: this.getAmountCell,
			});
		}
		if (!this.props.isDeparture && this.props.handleRetentionTime) {
			columns.push({
				name: 'retentionTime',
				sortable: false,
				filterable: false,
				title: localized('slaughterRetention'),
				getCellValue: this.getRetentionTimeCell,
			});
		}

		return columns;
	};

	private generateColumnExtes = () => {
		const columns = [
			{
				columnName: 'weight',
				width: 96,
			},
			{
				columnName: 'DepartedFromSection',
				width: 96,
			},
		];
		if (this.props.usesPen) {
			columns.unshift({
				columnName: 'pen',
				width: 96,
			});
		}
		if (
			this.props.isDeparture &&
			(this.props.departureType !== DepartureTypes.departureTypeKilled || this.props.usePrivateSlaughterhouse)
		) {
			columns.push({
				columnName: 'departuredWeight',
				width: 96,
			});
		}

		if (this.props.onRestFromSectionNumberChanged) {
			columns.push({
				columnName: 'rest',
				width: 96,
			});
			columns.unshift({
				columnName: 'amount',
				width: 96,
			});
		}
		if (!this.props.isDeparture && this.props.handleRetentionTime) {
			columns.push({
				columnName: 'retentionTime',
				width: 150,
			});
		}
		return columns;
	};
}
