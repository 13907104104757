import React from 'react';
import { Option } from 'react-dropdown';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AnimalType, GrowthPigEventType } from 'shared/api/api';
import { AddDaysToDate } from 'shared/helpers/date-helpers';
import {
	GetGrowthPigEventRegulations,
	getGrowthPigsEventsByDates,
	getGrowthPigsEventsBySiteId,
} from 'shared/state/ducks/growth-pig-events/operations';
import { GetLocations } from 'shared/state/ducks/locations/operations';
import { GetSyncData as ReasonsGetSyncData } from 'shared/state/ducks/reasons/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import PageContainer from 'web/view/components/page-container/page-container';
import { SkioldDropdown } from 'web/view/components/skiold-components/skiold-dropdown/skiold-dropdown';
import GrowthPigDepartureEventList from 'web/view/components/stem-animal/growth-pig-event-list/growth-pig-departure-event-list/growth-pig-departure-event-list';
import GrowthPigEntranceEventList from 'web/view/components/stem-animal/growth-pig-event-list/growth-pig-entrance-event-list/growth-pig-entrance-event-list';
import { GrowthPigEventListOptionsArray } from 'shared/helpers/growth-pigs-helper/growth-pig-event-list-helper';
import GrowthPigMoveEventList from 'web/view/components/stem-animal/growth-pig-event-list/growth-pig-move-event-list/growth-pig-move-event-list';
import { Heading } from 'web/view/components/utils/heading';
import { ViewWeb } from 'web/view/components/utils/web-view';
import './growth-pig-event-list.scss';
import isEqual from 'react-fast-compare';
import moment from 'moment';
import { SkioldFetch } from 'web/view/components/skiold-components/skiold-fetch/skiold-fetch';
import { GrowthPigWeightRegulations } from 'web/view/components/stem-animal/growth-pig-event-list/growth-pig-move-event-list/growth-pig-weight-regulations-list';

interface PropsFromParent {
	closeEditModal?: () => void;
	shouldCloseOnSave?: boolean;
	stemAnimalId?: string;
	theme?: 'light' | 'dark';
	setEventOption?: Option;
	pregnancyEventId?: string;
}

const mapStateToProps = (state: WebAppState, props: any) => {
	return {
		navigation: state.navigation,
		pregnancyEventIdNavigation: state.navigation.query && state.navigation.query.pregnancy,
		stemAnimalIdNavigation: state.navigation.query && state.navigation.query.stemAnimalId,
		setEventOptionNavigation: state.navigation.query && state.navigation.query.selected,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	getGrowthPigsEventsByDates: (dateFrom: Date, dateTo: Date) =>
		getGrowthPigsEventsByDates(dateFrom, dateTo)(dispatch),
	getGrowthPigsEventsBySiteId: () => getGrowthPigsEventsBySiteId()(dispatch),
	getReasons: () => ReasonsGetSyncData()(dispatch),
	GetLocations: () => GetLocations()(dispatch),
	getGrowthPigEventRegulations: (dateFrom: Date, dateTo: Date) =>
		GetGrowthPigEventRegulations(dateFrom, dateTo)(dispatch),
});

export interface GrowthPigventListState {
	events: Option[];
	selected: Option | undefined;
	renderTopRight?: JSX.Element;
	renderTopLeft?: JSX.Element;
	productionType: AnimalType;
	dateTo?: Date;
	dateFrom?: Date;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

export class GrowthPigEventList extends React.PureComponent<Props, GrowthPigventListState> {
	private DefaultEventOption: Option = {
		label: localized(GrowthPigEventType.GrowthPigEntranceEvent),
		value: GrowthPigEventType.GrowthPigEntranceEvent,
	};

	constructor(props: Props) {
		super(props);
		const dateTo = moment().endOf('day').toDate();
		const dateFrom = AddDaysToDate(moment().startOf('day').toDate(), -7);

		this.state = {
			dateFrom: dateFrom,
			dateTo: dateTo,
			selected: this.DefaultEventOption,
			events: GrowthPigEventListOptionsArray(),
			productionType: this.props.navigation.query
				? (this.props.navigation.query['type'] as AnimalType)
				: AnimalType.FRATS,
		};
		this.props.getGrowthPigsEventsBySiteId();
		this.props.getReasons();
		this.props.GetLocations();
		this.props.getGrowthPigsEventsByDates(dateFrom, dateTo);
	}

	public componentDidUpdate(prevProps: Props) {
		if (
			!isEqual(
				prevProps.navigation.query ? (prevProps.navigation.query['type'] as AnimalType) : AnimalType.FRATS,
				this.props.navigation.query ? (this.props.navigation.query['type'] as AnimalType) : AnimalType.FRATS,
			)
		) {
			this.setState({
				productionType: this.props.navigation.query
					? (this.props.navigation.query['type'] as AnimalType)
					: AnimalType.FRATS,
			});
		}
	}

	public renderTopLeft = () => {
		return <SkioldFetch toDate={this.state.dateTo} fromDate={this.state.dateFrom} fetchData={this.fetchData} />;
	};

	private fetchData = (dateFrom: Date, dateTo: Date) => {
		this.setState({ dateFrom: dateFrom, dateTo: dateTo });
		if (this.state.selected) {
			if (this.state.selected.value === GrowthPigEventType.GrowthPigEntranceEvent) {
				this.props.getGrowthPigsEventsByDates(dateFrom, dateTo);
			} else if (this.state.selected.value === GrowthPigEventType.GrowthPigDepartureEvent) {
				this.props.getGrowthPigsEventsByDates(dateFrom, dateTo);
			} else if (this.state.selected.value === GrowthPigEventType.GrowthPigMoveEvent) {
				this.props.getGrowthPigsEventsByDates(dateFrom, dateTo);
			} else if (this.state.selected.value === GrowthPigEventType.Regulation) {
				this.props.getGrowthPigEventRegulations(dateFrom, dateTo);
			}
		}
	};

	public render() {
		return (
			<PageContainer>
				<Heading text={localized('GrowthPigEventsList')} />
				<ViewWeb className="growth-pig-event-list">
					<ViewWeb className="growth-pig-event-content-container">
						<ViewWeb className="growth-pig-event-dropdown-container">
							<ViewWeb className="sizing start vertical-center">{this.renderTopLeft()}</ViewWeb>
							<SkioldDropdown
								onValueChanged={this.onSelectedValueChanged}
								disabled={!!this.props.closeEditModal}
								selectedValue={this.state.selected}
								items={this.state.events}
								containerClassName="dropdown-component-style"
							/>
							<ViewWeb className="end sizing vertical-center">
								{this.state.renderTopRight && this.state.renderTopRight}
							</ViewWeb>
						</ViewWeb>
						{this.state.selected &&
							this.state.selected.value === GrowthPigEventType.GrowthPigEntranceEvent && (
								<GrowthPigEntranceEventList
									productionType={this.state.productionType}
									topLeft={this.setTopLeft}
									topRight={this.setTopRight}
									dateFrom={this.state.dateFrom}
									dateTo={this.state.dateTo}
								/>
							)}
						{this.state.selected &&
							this.state.selected.value === GrowthPigEventType.GrowthPigDepartureEvent && (
								<GrowthPigDepartureEventList
									productionType={this.state.productionType}
									topLeft={this.setTopLeft}
									topRight={this.setTopRight}
									dateFrom={this.state.dateFrom}
									dateTo={this.state.dateTo}
								/>
							)}
						{this.state.selected && this.state.selected.value === GrowthPigEventType.GrowthPigMoveEvent && (
							<GrowthPigMoveEventList
								productionType={this.state.productionType}
								topLeft={this.setTopLeft}
								topRight={this.setTopRight}
								dateFrom={this.state.dateFrom}
								dateTo={this.state.dateTo}
							/>
						)}
						{this.state.selected && this.state.selected.value === GrowthPigEventType.Regulation && (
							<GrowthPigWeightRegulations
								productionType={this.state.productionType}
								topLeft={this.setTopLeft}
								topRight={this.setTopRight}
								dateFrom={this.state.dateFrom}
								dateTo={this.state.dateTo}
							/>
						)}
					</ViewWeb>
				</ViewWeb>
			</PageContainer>
		);
	}

	private setTopLeft = (res: JSX.Element) => {
		this.setState({ renderTopLeft: res });
	};

	private setTopRight = (res: JSX.Element) => {
		this.setState({ renderTopRight: res });
	};

	private onSelectedValueChanged = (selected: Option) => {
		this.setState({ selected });
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(GrowthPigEventList);
