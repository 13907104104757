import React from 'react';
import { Option } from 'react-dropdown';
import IsEqual from 'react-fast-compare';
import { connect } from 'react-redux';
import { PregnancyState, Scanned, ScanResult } from 'shared/api/api';
import { exactStartsWithMethodGrid, rangeFilterMethodGrid } from 'shared/helpers/general-helpers';
import { RefType } from 'shared/helpers/ref-type';
import { checkSaveWorkListStatus } from 'shared/helpers/work-list-helpers/general-work-list-helper';
import { generateDataScanningWorkListMemoized } from 'shared/helpers/work-list-helpers/scanning-list-helpers/scanning-work-list-helper';
import {
	ScanningListMapDispatchToProps,
	ScanningListMapStateToProps,
	ScanningListProps,
	ScanningListState,
	ScanningListTableItem,
} from 'shared/helpers/work-list-helpers/scanning-list-helpers/scanning-work-list-item';
import {
	isDistinctBuilding,
	isDistinctPen,
} from 'shared/helpers/work-list-helpers/vaccine-list-helpers/vaccine-work-list-helpers';
import { localized } from 'shared/state/i18n/i18n';
import { SharedAppState } from 'shared/state/store.shared';
import SkioldTableGrid, {
	SkioldTableGrid as SkioldTableRef,
} from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { defaultSortingWorklists, getWorkListBuildSecString } from 'web/view/components/work-lists/work-list-helper';
import { getWebAnimalColorStyleTable } from 'web/web-helpers/general-web-helpers';
import { GenerateScanResults } from '../../pregnancies/pregnancy-events/register-events-helper';
import { ShowConfirmAlert } from '../../skiold-alert/skiold-alert';
import { SkioldCheckbox } from '../../skiold-components/skiold-checkbox/skiold-checkbox';
import { SkioldDropdown } from '../../skiold-components/skiold-dropdown/skiold-dropdown';
import { GroupedHeader } from '../../skiold-components/skiold-table/skiold-table-grid/skiold-table-grid-grouped-header';
import '../list-setup.scss';
import './scanning-work-list-table.scss';

export class ScanningListTable extends React.PureComponent<ScanningListProps, ScanningListState> {
	public SkioldTableRef: SkioldTableRef | undefined;

	private scanResultOptions = GenerateScanResults();
	constructor(props: ScanningListProps) {
		super(props);

		this.state = {
			columns: [],
			loading: false,
			workListData: [],
			columnExte: [],
			createTreatmentModal: false,
			stemAnimalIdToEdit: '',
			commitAll: false,
			groupedColumns: this.groupedColumns(),
		};
	}

	public componentDidMount() {
		const data = generateDataScanningWorkListMemoized(
			this.props.stemAnimals,
			this.props.pregnancyEvents,
			this.props.matingBatches,
			this.props.workListSetting,
			this.props.locations,
			this.props.moveEvents,
		);
		this.setState({
			workListData: data.data,
			columns: this.generateColumns(data.data),
			columnExte: this.generateColumnsExtensions(data.data),
		});
		this.props.pregnancyEventGetSyncData();
		this.props.stemAnimalGetSyncData();
		this.props.moveEventGetSyncData();
		this.props.getLocations();
		this.props.getWorkListSettingsBySiteId();
	}

	public ResetWorkList() {
		const data = generateDataScanningWorkListMemoized(
			this.props.stemAnimals,
			this.props.pregnancyEvents,
			this.props.matingBatches,
			this.props.workListSetting,
			this.props.locations,
			this.props.moveEvents,
		);
		this.setState({
			workListData: data.data,
			columns: this.generateColumns(data.data),
			columnExte: this.generateColumnsExtensions(data.data),
		});
	}

	public componentDidUpdate(prevProps: ScanningListProps) {
		if (!IsEqual(this.props.batch.value, prevProps.batch.value)) {
			this.ResetWorkList();
			this.props.SetCheckedCount(0, true);
			this.setState({ commitAll: false });
		}
	}

	public getWorkListSetting() {
		const { workListSetting } = this.props;
		return workListSetting;
	}
	public render() {
		return (
			<ViewWeb
				className={
					'scanning-work-list-table align-items-center' +
					(this.props.workListSetting.usesLocation
						? ' have-section' + (!isDistinctPen(this.state.workListData) ? ' have-pen' : '')
						: '')
				}
			>
				<SkioldTableGrid
					tableKey={
						'scanningWorkistTable' +
						(this.props.workListSetting.usesLocation
							? 'Section' + (!isDistinctPen(this.state.workListData) ? 'Pen' : '')
							: '')
					}
					columns={this.state.columns}
					className={'work-list-table'}
					ref={this.setRef}
					data={this.state.workListData.filter(a => a.batchNumber === this.props.batch.value)}
					ColumnExtensions={this.state.columnExte}
					sortHeaderId={defaultSortingWorklists}
					showPagination={true}
					groupedColumns={this.state.groupedColumns}
				/>
			</ViewWeb>
		);
	}

	public async CheckSavePregnancyEventListsStatus() {
		if (await checkSaveWorkListStatus(this.state.workListData, ShowConfirmAlert)) {
			await this.SaveWorklist();
		}
		return true;
	}
	public setRef = (s: any) => {
		if (s) {
			this.SkioldTableRef = s;
			this.forceUpdate();
		}
	};

	public generateColumnsExtensions(data: ScanningListTableItem[]) {
		const columnExtensions: any = [
			{
				columnName: 'cycleDays',
				width: 60,
			},
			{
				columnName: 'litter',
				width: 80,
			},
			{
				columnName: 'animalNumber',
				width: 80,
			},
			{
				columnName: 'batch',
				width: 80,
			},
			{
				columnName: 'weeks',
				width: 60,
				filteringEnabled: false,
			},
			{
				columnName: 'scanResult',
				width: 100,
				filteringEnabled: false,
			},
		];

		if (this.props.workListSetting.usesLocation) {
			columnExtensions.push({ columnName: 'buildSection', width: 70 });
			if (!isDistinctPen(data)) {
				columnExtensions.push({ columnName: 'penString', width: 70 });
			}
		}
		columnExtensions.push({ columnName: 'completed', width: 120 });

		return columnExtensions;
	}

	public generateCommitAllCheckBox = () => {
		return (
			<ViewWeb className="checkbox-view-filter-style">
				<SkioldCheckbox isChecked={this.state.commitAll} className="checkbox-style" onClick={this.commitAll} />
			</ViewWeb>
		);
	};

	public commitAll = () => {
		const commitAll = !this.state.commitAll;
		this.setState(prevState => {
			const workListData = [...prevState.workListData];
			workListData
				.filter(wld => wld.batchNumber === this.props.batch.value)
				.forEach(element => {
					const index = workListData.findIndex(wld => wld.stemAnimalId === element.stemAnimalId);
					if (index >= 0) {
						workListData[index] = { ...workListData[index], isChecked: commitAll };
					}
				});
			this.props.SetCheckedCount(
				workListData.reduce((a, b) => a + (b && b.isChecked ? 1 : 0), 0),
				true,
			);
			return { workListData, commitAll };
		});
	};

	public SaveWorklist = async () => {
		const isCheckedItems = this.state.workListData.filter(item => item.isChecked);
		const animalIds = isCheckedItems.map(item => item.stemAnimalId);
		let listOfPromises: Array<Promise<void>> = [];
		isCheckedItems.forEach(async scannedEventToSave => {
			let scannedEvent = Scanned.fromJS({
				date: new Date(),
				state: PregnancyState.Scanned,
				result: scannedEventToSave.selectedOption.value as ScanResult,
				stemAnimalId: scannedEventToSave.stemAnimalId,
				siteId: this.props.workListSetting.siteId,
				pregnancyId: scannedEventToSave.pregnancyId,
				id: scannedEventToSave.id,
				createdOn: scannedEventToSave.createdOn,
			});
			listOfPromises.push(this.props.savePregnancyEvent(scannedEvent));
		});
		const items = this.state.workListData.filter(data => !animalIds.includes(data.stemAnimalId));
		await Promise.all(listOfPromises);
		this.setState({ workListData: items });
	};

	private groupedColumns = () => {
		const groupedHeader: GroupedHeader[] = [
			{
				title: localized('scanning'),
				children: [{ columnName: 'weeks' }, { columnName: 'scanResult' }],
			},
			{
				title: localized('location'),
				children: [{ columnName: 'buildSection' }, { columnName: 'penString' }],
			},
		];
		return groupedHeader;
	};

	private getStemAnimalNumberColor = (sow: ScanningListTableItem) => {
		return sow && sow ? getWebAnimalColorStyleTable(sow.stemAnimalId) : '';
	};

	private animalNumberFilter = (value: any, filterValue: any, row: ScanningListTableItem) => {
		return (
			row.animalNumber &&
			String(localized(row.animalNumber).toLocaleLowerCase()).startsWith(filterValue.value.toLocaleLowerCase())
		);
	};

	

	private generateColumns(data: ScanningListTableItem[]) {
		const columns: any = [
			{
				name: 'cycleDays',
				title: localized('Days'),
				headerClassName: 'merged-header',
				filterFunction: rangeFilterMethodGrid,
				getCellValue: (tableItem: ScanningListTableItem) =>
					tableItem.cycleDays && !isNaN(tableItem.cycleDays) ? tableItem.cycleDays : '',
			},
			{
				name: 'litter',
				headerClassName: 'merged-header',
				title: localized('Litters'),
				filterFunction: exactStartsWithMethodGrid,
				getCellValue: this.getLitterNumber,
			},
			{
				name: 'animalNumber',
				headerClassName: 'merged-header',
				title: localized('animalNumber'),
				filterFunction: this.animalNumberFilter,
				className: this.getStemAnimalNumberColor,
				getCellValue: this.getAnimalNumber,
			},
			{
				name: 'batch',
				title: localized('Batch'),
				headerClassName: 'merged-header',
				filterFunction: rangeFilterMethodGrid,
				getCellValue: this.getBatchNumber,
			},
			{
				name: 'weeks',
				title: localized('WEEK'),
				sortable: false,
				filterable: false,
				getCellValue: (tableItem: ScanningListTableItem) => tableItem.weeks,
			},
			{
				name: 'scanResult',
				title: localized('Pregnant'),
				sortable: false,
				filterable: false,
				getCellValue: (tableItem: ScanningListTableItem) => (
					<SkioldDropdown
						items={this.scanResultOptions}
						theme={'dark'}
						usedInTable={true}
						className={'inTable'}
						containerClassName="scan-result-dropdown"
						selectedValue={tableItem!.selectedOption}
						onValueChanged={(option: Option) => {
							const dataIndex = this.state.workListData.findIndex(
								a => a.stemAnimalId === tableItem.stemAnimalId,
							);
							let workListDataCopy = [...this.state.workListData];
							let workListDataItemCopy = { ...this.state.workListData[dataIndex] };
							workListDataItemCopy.selectedOption = option;
							workListDataCopy[dataIndex] = workListDataItemCopy;
							this.setState({ workListData: workListDataCopy });
						}}
						dropdownIdentifier={'scanning-worklist-scan'}
					/>
				),
			},
		];

		if (this.props.workListSetting.usesLocation) {
			columns.push({
				name: 'buildSection',
				title: localized('section'),
				headerClassName: 'merged-header',
				filterFunction: exactStartsWithMethodGrid,
				getCellValue: (tableItem: ScanningListTableItem) =>
					getWorkListBuildSecString(
						!isDistinctBuilding(data),
						tableItem.buildingString!,
						tableItem.sectionString!,
					),
			});
			if (!isDistinctPen(data)) {
				columns.push({
					name: 'penString',
					title: localized('pen'),
					headerClassName: 'merged-header',
					filterFunction: exactStartsWithMethodGrid,
				});
			}
		}
		columns.push({
			name: 'completed',
			title: localized('completed'),
			headerClassName: 'merged-header',
			sortable: false,
			filter: this.generateCommitAllCheckBox,
			getCellValue: (tableItem: ScanningListTableItem) => (
				<ViewWeb className="Checkbox-view-style">
					<SkioldCheckbox
						isChecked={tableItem.isChecked!}
						className="checkbox-style"
						onClick={() => {
							this.commitedClicked(tableItem);
						}}
					/>
				</ViewWeb>
			),
		});
		return columns;
	}
	private getLitterNumber = (tableItem: ScanningListTableItem) => tableItem.litter;
	private getBatchNumber = (tableItem: ScanningListTableItem) => tableItem.batchNumber;
	private getAnimalNumber = (tableItem: ScanningListTableItem) => tableItem.animalNumber;

	private commitedClicked = (tableItem: ScanningListTableItem) => {
		const dataIndex = this.state.workListData.findIndex(a => a.stemAnimalId === tableItem.stemAnimalId);

		this.setState(prevState => {
			const workListData = [...prevState.workListData];
			workListData[dataIndex] = { ...workListData[dataIndex], isChecked: !tableItem.isChecked };
			this.props.SetCheckedCount(workListData[dataIndex].isChecked ? 1 : -1);
			return { workListData, commitAll: false };
		});
	};
}

export default connect<
	ReturnType<typeof ScanningListMapStateToProps>,
	ReturnType<typeof ScanningListMapDispatchToProps>,
	RefType,
	SharedAppState
>(ScanningListMapStateToProps, ScanningListMapDispatchToProps, null, { forwardRef: true })(ScanningListTable);
