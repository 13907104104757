import { WeaningWorkListItemData } from 'shared/helpers/work-list-helpers/weaning-list-helpers/weaning-work-list-item';
import { localized } from 'shared/state/i18n/i18n';
import { AllAnimalsEsfWorkListItem } from './esf-list-helpers/all-animals-esf-helpers';
import { MatingWorkListItemData } from './mating-list-helpers/mating-work-list-item';
import { TasksInFarrowingStableItem } from './tasks-in-farrowing-stable-helper/tasks-in-farrowing-stable-item';
import { VaccineWorkListItem } from './vaccine-list-helpers/vaccine-worklist-items';
import { MovingListTable } from './move-to-helpers/move-to-data-model';
import { IMoveToPregnancyLocation, IMoveFromPregToPregSetting, IStemAnimal } from 'shared/api/api';
import { calculateAnimalKind, calculateAnimalKindById } from '../stemanimal-helper/stemanimal-helper';
import { DepartureListTableItem } from './departure-list-helpers/departure-work-list-item';
import { ScanningListTableItem } from './scanning-list-helpers/scanning-work-list-item';
import { LardScanningEventListTableItem } from './lard-scanning-event-list-helpers/lard-scanning-event-work-list-item';

export async function checkSaveWorkListStatus(
	events: Array<
		| VaccineWorkListItem
		| MovingListTable
		| TasksInFarrowingStableItem
		| ScanningListTableItem
		| AllAnimalsEsfWorkListItem
		| DepartureListTableItem
		| LardScanningEventListTableItem
	>,
	confirmAlert: (
		message: string,
		acceptText?: string,
		rejectText?: string,
		customHeader?: string,
	) => Promise<boolean>,
) {
	if (events.find(a => a && a.isChecked) !== undefined) {
		if (await confirmAlert(localized('saveWorklist'), localized('True'), localized('False'))) {
			return true;
		} else {
			return false;
		}
	}
}

export async function checkSaveMoveList(
	events: MovingListTable[],
	confirmAlert: (
		message: string,
		acceptText?: string,
		rejectText?: string,
		customHeader?: string,
	) => Promise<boolean>,
) {
	if (events.find(a => a && a.isChecked && !a.savedFromScanner) !== undefined) {
		if (await confirmAlert(localized('saveWorklist'), localized('True'), localized('False'))) {
			return true;
		} else {
			return false;
		}
	}
}

export async function checkSavePregnancyEventListsStatus(
	events: Array<MatingWorkListItemData | WeaningWorkListItemData>,
	confirmAlert: (
		message: string,
		acceptText?: string,
		rejectText?: string,
		customHeader?: string,
	) => Promise<boolean>,
) {
	if (
		events.find(a => a.workListData && a.workListData!.isChecked) !== undefined &&
		(await confirmAlert(localized('saveWorklist'), localized('True'), localized('False')))
	) {
		return true;
	} else {
		return false;
	}
}

export function getCurveByAnimalKind(
	moveToPregnantSetup: IMoveToPregnancyLocation | IMoveFromPregToPregSetting,
	sow: IStemAnimal,
) {
	if (moveToPregnantSetup.defaultFeedCurves) {
		const kind = calculateAnimalKind(sow);
		const curveToShow = moveToPregnantSetup.defaultFeedCurves.find(
			defaultCurve => defaultCurve.animalKind === kind,
		);
		if (curveToShow) {
			return curveToShow.feedCurveNumber;
		}
	}

	return undefined;
}

export function getCurveItemByAnimalKind(
	moveToPregnantSetup: IMoveToPregnancyLocation | IMoveFromPregToPregSetting,
	sow: string | undefined,
) {
	if (sow && moveToPregnantSetup.defaultFeedCurves) {
		const kind = calculateAnimalKindById(sow);
		const curveToShow = moveToPregnantSetup.defaultFeedCurves.find(
			defaultCurve => defaultCurve.animalKind === kind,
		);
		if (curveToShow) {
			return curveToShow;
		}
	}

	return undefined;
}
