import { Action } from 'redux';
import { removeValueFromArray, updateValueInArray } from 'shared/helpers/reducer-helpers';
import { isType } from 'typescript-fsa';
import { SlaughteDataState } from '.';
import { siteChange } from '../profile/actions';
import * as actions from './actions';

export const initialState: SlaughteDataState = {
    slaughteDatas: [],
};

const SlaughterDatasReducer = (state: SlaughteDataState = initialState, action: Action): SlaughteDataState => {
    if (isType(action, actions.getSlaughterData.done)) {
        const result = { ...state, slaughteDatas: action.payload.result };
        return result;
    }

    if (isType(action, siteChange.done)) {
		state = initialState;
	}

    if (isType(action, actions.postSlaughterDatas.started)) {
        let dataToModify = [...state.slaughteDatas];
        action.payload.forEach(value => {
            dataToModify = updateValueInArray(dataToModify,  value)
        });
       state.slaughteDatas = dataToModify;
    }

    if (isType(action, actions.deleteSlaughterDatas.started)) {
        let dataToModify = [...state.slaughteDatas];
        action.payload.forEach(value => {
            dataToModify = removeValueFromArray(dataToModify,  value)
        });
       state.slaughteDatas = dataToModify;
    }

    return state;
};

export default SlaughterDatasReducer;
