import React from 'react';
import { connect } from 'react-redux';
import { AnimalType } from 'shared/api/api';
import { Colors } from 'shared/assets/root-assets/colors';
import { selectNucleusManagement } from 'shared/state/ducks/site/reducer';
import { localized } from 'shared/state/i18n/i18n';
import styled from 'styled-components';
import { WebAppState } from 'web/state/store.web';
import { SkioldTouchableOpacity } from 'web/view/components/skiold-components/skiold-touchable-opacity';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import AnimalReportSettingsPage from '../animal-report-settings/animal-report-settings';
import DashboardSettingsPage from '../dashboard-settings/dashboard-settings';
import { GeneralSettingsOverview } from '../general-settings-overview/general-settings-overview';
import MatingBatchSettings from '../mating-batch-setting/mating-batch-setting';
import { NucleusManagementSettingsOverview } from '../nucleus-management/settings/nucleus-management-settings-overview';
import ReasonSetting from '../reason-settings/reason-settings';
import ValidationSetupSetting from '../validation-setup/validation-setup';
import WorkListSettings from '../work-list-setting/work-list-settings';
import './sow-management-setup.scss';

const options = {
	matingBatch: 'matingBatch',
	reasons: 'reasons',
	validation: 'validation',
	WorkLists: 'WorkLists',
	AnimalReports: 'AnimalReports',
	PlanAndGoal: 'PlanAndGoal',
	General: 'General',
	NucleusManagement: 'NucleusManagement',
};

interface State {
	selected: keyof typeof options;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		profile: state.profile.active!,
		activeSite: state.site.site,
		nucleusManagement: selectNucleusManagement(state),
	};
};

type Props = ReturnType<typeof mapStateToProps>;

const LinkText = styled.p`
	color: ${Colors.white};
	font-size: 16;
	margin-right: 16px;

	&:hover {
		color: ${Colors.yellow};
	}
`;

class SowManagementSetup extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			selected: 'matingBatch',
		};
	}

	private onPropertyChanged = (itemFromParent: keyof typeof options) => {
		this.setState({ selected: itemFromParent });
	};

	public render() {
		return (
			<ViewWeb className="sow-management-setup">
				<TextWeb className="setup-label">{localized('SowManagementSetup')}</TextWeb>
				<ViewWeb>
					<ViewWeb className="selectable-container">
						<SkioldTouchableOpacity itemFromParent={options.matingBatch} onPress={this.onPropertyChanged}>
							<LinkText>{localized('matingBatch')}</LinkText>
						</SkioldTouchableOpacity>
						<SkioldTouchableOpacity itemFromParent={options.reasons} onPress={this.onPropertyChanged}>
							<LinkText>{localized('reasons')}</LinkText>
						</SkioldTouchableOpacity>
						<SkioldTouchableOpacity itemFromParent={options.validation} onPress={this.onPropertyChanged}>
							<LinkText>{localized('validation')}</LinkText>
						</SkioldTouchableOpacity>
						<SkioldTouchableOpacity itemFromParent={options.PlanAndGoal} onPress={this.onPropertyChanged}>
							<LinkText>{localized('PlanAndGoal')}</LinkText>
						</SkioldTouchableOpacity>
						{this.props.profile.processes &&
						this.props.profile.processes.includes('3f1a9536-c69d-49a4-903d-d59f96488ca6') ? (
							<SkioldTouchableOpacity itemFromParent={options.WorkLists} onPress={this.onPropertyChanged}>
								<LinkText>{localized('workLists')}</LinkText>
							</SkioldTouchableOpacity>
						) : (
							<ViewWeb />
						)}
						<SkioldTouchableOpacity itemFromParent={options.AnimalReports} onPress={this.onPropertyChanged}>
							<LinkText>{localized('reports')}</LinkText>
						</SkioldTouchableOpacity>
						<SkioldTouchableOpacity itemFromParent={options.General} onPress={this.onPropertyChanged}>
							<LinkText>{localized('General')}</LinkText>
						</SkioldTouchableOpacity>
						{this.props.nucleusManagement && (
							<SkioldTouchableOpacity
								itemFromParent={options.NucleusManagement}
								onPress={this.onPropertyChanged}
							>
								<LinkText>{localized('NucleusManagement')}</LinkText>
							</SkioldTouchableOpacity>
						)}
					</ViewWeb>

					{this.state.selected === options.matingBatch && <MatingBatchSettings />}
					{this.state.selected === options.reasons && <ReasonSetting animalType={AnimalType.Sow} />}
					{this.state.selected === options.validation && <ValidationSetupSetting />}
					{this.state.selected === options.WorkLists && <WorkListSettings />}
					{this.state.selected === options.AnimalReports && <AnimalReportSettingsPage />}
					{this.state.selected === options.PlanAndGoal && <DashboardSettingsPage />}
					{this.state.selected === options.General && <GeneralSettingsOverview animalType={AnimalType.Sow} />}
					{this.state.selected === options.NucleusManagement && <NucleusManagementSettingsOverview />}
				</ViewWeb>
			</ViewWeb>
		);
	}
}

const connector = connect(mapStateToProps, {});

export default connector(SowManagementSetup);
