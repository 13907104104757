import React from 'react';
import { Option } from 'react-dropdown';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
	ESFReader,
	IAllAnimalsEsfListSetting,
	IShortageEsfListSetting,
	ShortageEsfListSetting,
	WorkListType,
} from 'shared/api/api';
import { deepCopy } from 'shared/helpers/general-helpers';
import { SaveWorkListSetting } from 'shared/state/ducks/work-list-settings/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { SkioldButton } from '../../skiold-components/skiold-button/skiold-button';
import { SkioldCheckbox } from '../../skiold-components/skiold-checkbox/skiold-checkbox';
import { SkioldFormDropdown } from '../../skiold-components/skiold-dropdown/skiold-form-dropdown';
import { SkioldFormsWrapper } from '../../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from '../../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { SkioldFormIntegerInput } from '../../skiold-components/skiold-integer-input/skiold-form-integer-input';
import { getAvailablityRow, getSkioldInputRow } from '../work-list-helper';
import '../list-setup.scss';

interface PropsFromParent {
	workListType: WorkListType;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		listSetting: state.workListSettings.entities.find(
			wl => wl.type === WorkListType.ShortageEsfListSetting,
		) as IShortageEsfListSetting,
		siteId: state.profile.active!.siteId,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	saveWorkListSetting: (listSetting: IShortageEsfListSetting) => SaveWorkListSetting(listSetting)(dispatch),
});

export interface State {
	listSetting: IShortageEsfListSetting;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

class ShortageEsfListSetup extends React.PureComponent<Props, State> {
	private ESFReaderOptions = [
		{ value: ' ', label: ' ' },
		{ value: ESFReader.AWR250StickReader, label: localized(ESFReader.AWR250StickReader) },
		{ value: ESFReader.AWR300StickReader, label: localized(ESFReader.AWR300StickReader) },
	];
	public static getDerivedStateFromProps(nextProps: Props, prevState: State): Partial<State> {
		if (!prevState.listSetting.id && nextProps.listSetting) {
			return {
				listSetting: { ...prevState.listSetting, ...nextProps.listSetting },
				
			};
		}
		return {};
	}
	constructor(props: Props) {
		super(props);
		const listSetting = ShortageEsfListSetting.fromJS({ type: WorkListType.ShortageEsfListSetting });
		this.state = {
			listSetting: listSetting,
		};
	}

	public toggleShowOnWeb() {
		this.setState(prevState => ({
			listSetting: {
				...prevState.listSetting,
				showOnWeb: !this.state.listSetting.showOnWeb,
			},
		}));
	}

	public toggleShowOnApp() {
		this.setState(prevState => ({
			listSetting: {
				...prevState.listSetting,
				showOnApp: !this.state.listSetting.showOnApp,
			},
		}));
	}

	public listSetupChanged = (setup: IShortageEsfListSetting) => {
		this.setState({
			listSetting: setup,
		});
	};

	public render() {
		return (
			<ViewWeb className="list-setup">
				<ViewWeb className="shortage-view-container">
					<TextWeb className="text-style">{localized('Setup')}</TextWeb>
					<SkioldFormsWrapper formRows={this.getFormRows()} containerClassName="wrapper-container" />
					{this.renderButtons()}
				</ViewWeb>
			</ViewWeb>
		);
	}

	private save = async () => {
		if (!this.validate(this.state.listSetting)) {
			return;
		}

		let listSetup = deepCopy(this.state.listSetting);
		listSetup.siteId = this.props.siteId;
		this.props.saveWorkListSetting(listSetup);
	};

	private getFormRows() {
		let formRows = new Array<FormRow>();

		formRows.push(this.getHeader());
		formRows.push(getSkioldInputRow(this.listSetupChanged, this.state.listSetting, 'name', localized('name')));

		formRows.push(this.getNegativeCycleDaysRow());
		formRows.push(this.getFeedRationRow());
		formRows.push(
			getAvailablityRow(
				() => this.toggleShowOnWeb(),
				() => this.toggleShowOnApp(),
				this.state.listSetting.showOnWeb!,
				this.state.listSetting.showOnApp!,
			),
		);

		return formRows;
	}

	private validate(listSetting: IAllAnimalsEsfListSetting) {
		return true;
	}

	private getHeader(): FormRow {
		return {
			header: localized(this.props.workListType),
		};
	}

	private getFeedRationRow(): FormRow {
		return {
			name: (
				<ViewWeb className="width-100">
					{localized('animalsOnListWhileDaysRationNotUnfed')
						.split('\n')
						.map((i, key) => {
							return (
								<TextWeb className="left-form-text" key={key}>
									{i}
								</TextWeb>
							);
						})}
				</ViewWeb>
			),
			component: (
				<ViewWeb className="tasks-in-farrowing-stable-sub-tasks">
					<ViewWeb className="shortage-setting-flex-row">
						<SkioldFormIntegerInput
							className="shortage-column-text-input"
							onChangeNumber={this.feedRationPercentChanged}
							text={this.state.listSetting.feedRationPercentUnfed}
						/>
						<TextWeb>%</TextWeb>
					</ViewWeb>
					<ViewWeb className="two-checkbox-container align-right">
						<SkioldCheckbox onClick={this.todayClicked} isChecked={this.state.listSetting.today} />
						<TextWeb>{localized('Today')}</TextWeb>
						<SkioldCheckbox onClick={this.yesterdayClicked} isChecked={this.state.listSetting.yesterday} />
						<TextWeb>{localized('Yesterday')}</TextWeb>
					</ViewWeb>
				</ViewWeb>
			),
			style: { height: 100 },
		};
	}

	private feedRationPercentChanged = (number: number | undefined) => {
		this.setState(prevState => ({
			listSetting: {
				...prevState.listSetting,
				feedRationPercentUnfed: number!,
			},
		}));
	};

	private todayClicked = () => {
		this.setState(prevState => ({
			listSetting: {
				...prevState.listSetting,
				today: !this.state.listSetting.today,
			},
		}));
	};

	private yesterdayClicked = () => {
		this.setState(prevState => ({
			listSetting: {
				...prevState.listSetting,
				yesterday: !this.state.listSetting.yesterday,
			},
		}));
	};

	private getNegativeCycleDaysRow(): FormRow {
		return {
			name: localized('cycleDaysShownNegative'),
			component: (
				<ViewWeb className="two-checkbox-container">
					<SkioldCheckbox
						onClick={this.SetNegativeCycleDaysToTrue}
						isChecked={this.state.listSetting.negativeCycleDays}
					/>
					<TextWeb>{localized('True')}</TextWeb>
					<SkioldCheckbox
						onClick={this.SetNegativeCycleDaysToFalse}
						isChecked={!this.state.listSetting.negativeCycleDays}
					/>
					<TextWeb>{localized('False')}</TextWeb>
				</ViewWeb>
			),
		};
	}
	public SetNegativeCycleDaysToFalse = () => {
		this.setState(prevState => ({
			listSetting: {
				...prevState.listSetting,
				negativeCycleDays: false,
			},
		}));
	};

	public SetNegativeCycleDaysToTrue = () => {
		this.setState(prevState => ({
			listSetting: {
				...prevState.listSetting,
				negativeCycleDays: true,
			},
		}));
	};

	private renderButtons() {
		return (
			<ViewWeb className="view-button-container">
				<SkioldButton title={localized('Save')} onPress={this.save} />
			</ViewWeb>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ShortageEsfListSetup);
