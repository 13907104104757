import React, { FC } from 'react';
import { ViewWeb } from '../../../../components/utils/web-view';
import { localized } from '../../../../../../shared/state/i18n/i18n';
import { TextWeb } from '../../../../../web-helpers/styled-text-components';

export const NumberBatchSetupHeader: FC = React.memo(() => {
	return (
		<ViewWeb className={'number-batch-title-row flex-row yellow-background'}>
			<ViewWeb className={'line-height border-right wide-cell'}>{localized('Race')}</ViewWeb>
			<ViewWeb className={'line-height flex-column flex-2'}>
				<ViewWeb className={'border-bottom border-right'}>{localized('Gender').toUpperCase()}</ViewWeb>
				<ViewWeb className={'flex-row'}>
					<TextWeb className={'slim-cell border-right'}>{localized('boar').toUpperCase()}</TextWeb>
					<TextWeb className={'slim-cell border-right'}>{localized('Sow').toUpperCase()}</TextWeb>
				</ViewWeb>
			</ViewWeb>
			<ViewWeb className={'line-height flex-column flex-4'}>
				<ViewWeb className={'border-bottom border-right'}>{localized('startNumber').toUpperCase()}</ViewWeb>
				<ViewWeb className={'flex-row'}>
					<TextWeb className={'wide-cell border-right'}>{localized('FirstMatingNr').toUpperCase()}</TextWeb>
					<TextWeb className={'wide-cell border-right'}>{localized('SecondMatingNr').toUpperCase()}</TextWeb>
				</ViewWeb>
			</ViewWeb>
			<ViewWeb className={'line-height flex-column flex-2'}>
				<ViewWeb className={'border-bottom border-right'}>{localized('Current').toUpperCase()}</ViewWeb>
				<ViewWeb className={'wide-cell border-right width-100-percent'}>
					{localized('SecondMatingNr').toUpperCase()}
				</ViewWeb>
			</ViewWeb>
			<ViewWeb className={'line-height flex-column flex-4'}>
				<ViewWeb className={'border-bottom border-right'}>{localized('FinalNumber').toUpperCase()}</ViewWeb>
				<ViewWeb className={'flex-row'}>
					<TextWeb className={'wide-cell border-right'}>{localized('FirstMatingNr').toUpperCase()}</TextWeb>
					<TextWeb className={'wide-cell border-right'}>{localized('SecondMatingNr').toUpperCase()}</TextWeb>
				</ViewWeb>
			</ViewWeb>
			<ViewWeb className={'break-word wide-cell border-right flex-1'}>
				{localized('priority').toUpperCase()}
			</ViewWeb>
			<ViewWeb className={'line-height slim-cell flex-1'}></ViewWeb>
		</ViewWeb>
	);
});
