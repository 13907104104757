import ObjectID from 'bson-objectid';
import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ISlaughterhouseConnectionDto, SlaughterhouseCompany } from 'shared/api/api';
import {
	GetDanishCrownAuthState,
	PostSlaughterhouseConnectionDto,
} from 'shared/state/ducks/connection-slaughter-house/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldFormsWrapper } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { SkioldFormInput } from 'web/view/components/skiold-components/skiold-input/skiold-form-input';
import { ViewWeb } from 'web/view/components/utils/web-view';
interface PropsFromParent {
	slaughterHouse: SlaughterhouseCompany;
	closeModal: () => void;
}

export const LoginInformationSlaughterHouse: FC<PropsFromParent> = React.memo(props => {
	const state = useSelector((state: WebAppState) => ({
		site: state.site,
		profile: state.profile.active!,
		currentConnection: state.slaughterhouseConnections.slaughterhouseConnections.find(
			shc => shc.slaughterhouse === SlaughterhouseCompany.DanishCrown,
		),
	}));
	const userIdState = useState(state.currentConnection?.userNumber ?? '');
	const passwordState = useState(state.currentConnection?.password ? 'placeholder' : '');
	const dispatch = useDispatch();
	const saveLoginInformation = async (data: ISlaughterhouseConnectionDto) => {
		data.siteId = state.profile.siteId;
		PostSlaughterhouseConnectionDto(data)(dispatch);
		if (await GetDanishCrownAuthState(data)) {
			PostSlaughterhouseConnectionDto(data)(dispatch);
			props.closeModal();
		} else {
			alert(localized('IncorrectLoginInformation'));
		}
	};

	const item = {
		id: state.currentConnection ? state.currentConnection.id : new ObjectID().toHexString(),
		farmNumber: userIdState[0],
		userNumber: userIdState[0],
		password: passwordState[0],
		siteId: state.site.site.id,
		slaughterhouse: SlaughterhouseCompany.DanishCrown,
		createdBy: state.currentConnection ? state.currentConnection.createdBy : state.profile.id,
		createdOn: state.currentConnection ? state.currentConnection.createdOn : new Date(),
		modifiedBy: state.profile.id,
		modifiedOn: new Date(),
	} as ISlaughterhouseConnectionDto;

	return (
		<>
			<SkioldFormsWrapper formRows={GetFormRows(userIdState, passwordState)} />
			<ViewWeb className="button-view-style">
				<SkioldButton theme="grey" title={localized('Close')} onPress={props.closeModal} />
				<SkioldButton itemFromParent={item} title={localized('Save')} onPress={saveLoginInformation} />
			</ViewWeb>
		</>
	);
});

const GetFormRows = (
	userIdState: [string, React.Dispatch<React.SetStateAction<string>>],
	passwordState: [string, React.Dispatch<React.SetStateAction<string>>],
) => {
	let formRows = new Array<FormRow>();
	formRows.push(getSlaughterHouseHeader());
	formRows.push(getUserIdRow(userIdState));
	formRows.push(getPasswordRow(passwordState));
	return formRows;
};

function getUserIdRow(userIdState: [string, React.Dispatch<React.SetStateAction<string>>]) {
	const updateValue = (op: string) => userIdState[1](op);
	return {
		name: localized('UserId'),
		component: <SkioldFormInput text={userIdState[0]} onChangeText={updateValue} />,
	};
}

function getPasswordRow(passwordState: [string, React.Dispatch<React.SetStateAction<string>>]) {
	const updateValue = (op: string) => passwordState[1](op);
	return {
		name: localized('password'),
		component: <SkioldFormInput type="password" text={passwordState[0]} onChangeText={updateValue} />,
	};
}

function getSlaughterHouseHeader(): FormRow {
	return {
		header: localized('LoginInformationDanishCrown'),
	};
}
