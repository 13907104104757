import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import LocationOff from 'shared/assets/src-assets/png/ikon_lokation_grey.png';
import LocationOn from 'shared/assets/src-assets/png/ikon_lokation_yellow.png';
import Sow from 'shared/assets/src-assets/png/sow_grey.png';
import SowOn from 'shared/assets/src-assets/png/sow_on.png';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { OverlayMenuItem } from 'web/view/components/navbar/overlay-menu/menu-item';
import PageContainer from 'web/view/components/page-container/page-container';
import { ViewWeb } from 'web/view/components/utils/web-view';
import AuthMapper from 'web/view/pages/auth-mapper/auth-mapper';
import Site from 'web/view/pages/profile/embed-site';
import SowManagementSetup from 'web/view/pages/sow-management-setup/sow-management-setup';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import './settings.scss';
import SettingsIcon from 'shared/assets/src-assets/png/settings.png';
import SettingsIconOn from 'shared/assets/src-assets/png/settings_on.png';
import weanerIcon from 'shared/assets/src-assets/png/climatepigs_grey.png';
import weanerIconOn from 'shared/assets/src-assets/png/climatepigs_yellow.png';
import Finisher from 'shared/assets/src-assets/png/slaughterpigs_grey.png';
import FinisherOn from 'shared/assets/src-assets/png/slaughterpigs_yellow.png';
import GrowthPigManagementSetup from 'web/view/components/growth-pig-setup/growth-pig-setup-overview';
import { AnimalType } from 'shared/api/api';
import * as GeneralSettingsOperations from 'shared/state/ducks/general-settings/operations';
import { LicenceIds, ModulesIds } from '../../../../shared/constants';

export const mapStateToProps = (state: WebAppState) => {
	return {
		profile: state.profile.active!,
		activeSite: state.site.site,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
	generalSettingsSyncData: () => GeneralSettingsOperations.GetSyncData()(dispatch),
});

type SettingsProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

interface SettingsState {
	content: JSX.Element;
}

class Settings extends React.PureComponent<SettingsProps, SettingsState> {
	constructor(props: SettingsProps) {
		super(props);
		this.state = {
			content: <ViewWeb />,
		};
	}

	public componentDidMount() {
		this.setSiteContent();
		this.props.generalSettingsSyncData();
	}

	private readonly margin12 = { margin: 12 };

	public render() {
		return (
			<PageContainer className="settings">
				<ViewWeb className="inner-row-container">
					<ViewWeb className="inner-column-container">
						<TextWeb className="settings-label">{localized('settings')}</TextWeb>
						<OverlayMenuItem
							name={'siteSetup'}
							svgXmlDataOff={LocationOff}
							svgXmlDataOn={LocationOn}
							textColorOn="orange"
							style={this.margin12}
							onClick={this.setSiteContent}
						/>
						{this.props.profile.modules &&
							this.props.activeSite.activeLicences?.includes(LicenceIds.SowManagement) &&
							this.props.profile.modules.includes(ModulesIds.SowModule) && (
								<OverlayMenuItem
									name={'SowManagementSetup'}
									svgXmlDataOff={Sow}
									svgXmlDataOn={SowOn}
									textColorOn="orange"
									style={this.margin12}
									onClick={this.setSowContent}
								/>
							)}
						{this.props.profile.modules &&
							this.props.activeSite.activeLicences?.includes(LicenceIds.ClimatePigManagement) &&
							this.props.profile.modules.includes(ModulesIds.ClimatePig) && (
								<OverlayMenuItem
									name={'WeanerManagementSetup'}
									svgXmlDataOff={weanerIcon}
									svgXmlDataOn={weanerIconOn}
									textColorOn="orange"
									style={this.margin12}
									onClick={this.setWeanerContent}
								/>
							)}
						{this.props.profile.modules &&
							this.props.activeSite.activeLicences?.includes(LicenceIds.FinisherManagement) &&
							this.props.profile.modules.includes(ModulesIds.FinisherModule) && (
								<OverlayMenuItem
									name={'FinisherManagementSetup'}
									svgXmlDataOff={Finisher}
									svgXmlDataOn={FinisherOn}
									textColorOn="orange"
									style={this.margin12}
									onClick={this.setFinisherContent}
								/>
							)}

						{this.props.profile.modules &&
							this.props.profile.modules.includes(ModulesIds.SettingsModule) && (
								<OverlayMenuItem
									name={'userManagementSettings'}
									svgXmlDataOff={SettingsIcon}
									svgXmlDataOn={SettingsIconOn}
									textColorOn="orange"
									style={this.margin12}
									onClick={this.setUserManagementContent}
								/>
							)}
					</ViewWeb>
					<ViewWeb className="content-container">
						{this.state.content != null ? this.state.content : <ViewWeb />}
					</ViewWeb>
				</ViewWeb>
			</PageContainer>
		);
	}

	private setUserManagementContent = () => {
		this.setState({ ...this.state, content: <AuthMapper /> });
	};
	private setSiteContent = () => {
		this.setState({ ...this.state, content: <Site /> });
	};
	private setFinisherContent = () => {
		this.setState({ ...this.state, content: <GrowthPigManagementSetup AnimalType={AnimalType.Finisher} /> });
	};
	private setWeanerContent = () => {
		this.setState({ ...this.state, content: <GrowthPigManagementSetup AnimalType={AnimalType.Weaner} /> });
	};
	private setSowContent = () => {
		this.setState({ ...this.state, content: <SowManagementSetup /> });
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
