import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import * as actions from './actions';
import { DistriwinFeedConsumptionState } from './types';
import { mergeArrays } from 'shared/helpers/reducer-helpers';
import { SyncableInitialState } from 'shared/state/models/syncable';
import {} from './actions';
import { siteChange } from '../profile/actions';

export const initialState: DistriwinFeedConsumptionState = {
	...SyncableInitialState,
	dtoEntities: [],
	pending: false,
};

const distriwinFeedConsumptionReducer = (
	state: DistriwinFeedConsumptionState = initialState,
	action: Action,
): DistriwinFeedConsumptionState => {
	if (isType(action, actions.getDistriwinFeedConsumptionDtos.started)) {
		state = { ...state, syncInProgress: true, pending: true };
	}
	if (isType(action, actions.getDistriwinFeedConsumptionDtos.failed)) {
		state = { ...state, syncInProgress: false, pending: false };
	}

	if (isType(action, actions.getDistriwinFeedConsumptionDtos.done)) {
		state = {
			...state,
			dtoEntities: action.payload.result,
			syncInProgress: false,
			pending: false,
		};
	}
	if (isType(action, siteChange.done)) {
		state = initialState;
	}

	return state;
};

export default distriwinFeedConsumptionReducer;
