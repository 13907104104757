import { Action } from 'redux';
import { GrowthPigTuneProduction } from 'shared/api/api';
import { mergeArrays, removeMultipleValuesFromArray } from 'shared/helpers/reducer-helpers';
import { SharedAppState } from 'shared/state/store.shared';
import { isType } from 'typescript-fsa';
import { GrowthPigTuneProductionState } from '.';
import { siteChange } from '../profile/actions';
import { getGrowthPigTuneProductionBySiteId, upsertGrowthPigTuneProduction } from './actions';

export const initialGrowthPigEventState: GrowthPigTuneProductionState = {
	growthPigTuneProductions: [],
	updates: [],
	isLoading: false,
};

const growthPigTuneProductionReducer = (
	state: GrowthPigTuneProductionState = initialGrowthPigEventState,
	action: Action,
): GrowthPigTuneProductionState => {
	if (isType(action, siteChange.done)) {
		state = initialGrowthPigEventState;
	}

	if (isType(action, upsertGrowthPigTuneProduction.started)) {
		const stateCopy = { ...state };
		stateCopy.growthPigTuneProductions = mergeArrays(stateCopy.growthPigTuneProductions, action.payload);
		stateCopy.updates = mergeArrays(stateCopy.updates, action.payload);
		state = stateCopy;
	}

	if (isType(action, upsertGrowthPigTuneProduction.done)) {
		const stateCopy = { ...state };
		stateCopy.updates = removeMultipleValuesFromArray(
			stateCopy.updates,
			action.payload.params && action.payload.params.map ? action.payload.params.map(tp => tp.id!) : [],
		);
		state = stateCopy;
	}
	if (isType(action, getGrowthPigTuneProductionBySiteId.started)) {
		state = { ...state, isLoading: true };
	}

	if (isType(action, getGrowthPigTuneProductionBySiteId.failed)) {
		state = { ...state, isLoading: false };
	}

	if (isType(action, getGrowthPigTuneProductionBySiteId.done)) {
		const stateCopy = { ...state };
		stateCopy.growthPigTuneProductions = action.payload.result;
		stateCopy.isLoading = false;
		state = stateCopy;
	}
	if (isType(action, siteChange.done)) {
		state = initialGrowthPigEventState;
	}

	if (action.type === 'persist/REHYDRATE') {
		let a = (action as any) as { payload: SharedAppState; key: string };

		if (a.key === 'root') {
			let growthPigTuneProductions = new Array<GrowthPigTuneProduction>();
			let updates = new Array<GrowthPigTuneProduction>();
			if (
				a.payload &&
				a.payload.growthPigTuneProduction &&
				a.payload.growthPigTuneProduction.growthPigTuneProductions
			) {
				growthPigTuneProductions = a.payload.growthPigTuneProduction.growthPigTuneProductions.map(dt => {
					let ndt = GrowthPigTuneProduction.fromJS({});
					ndt.init(dt);
					return ndt;
				});
			}
			if (a.payload && a.payload.growthPigTuneProduction && a.payload.growthPigTuneProduction.updates) {
				updates = a.payload.growthPigTuneProduction.updates.map(dt => {
					let ndt = GrowthPigTuneProduction.fromJS({});
					ndt.init(dt);
					return ndt;
				});
			}

			state = {
				...state,
				growthPigTuneProductions,
				updates,
			};
		}
	}
	return state;
};

export default growthPigTuneProductionReducer;
