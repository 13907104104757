import { ChartDataset, ChartOptions, ChartType } from 'chart.js';
import 'chartjs-adapter-moment';
import moment from 'moment';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Colors } from 'shared/assets/root-assets/colors';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { IAdjustmentDTO } from '../../../../../../shared/api/api';
import { getByPenForGraph, getGraphForSection } from '../../../../../../shared/state/ducks/feed-usage/operation';
import {
	resetGraph,
	selectFeedUsagePending,
	selectFeedUsageWeanerGraph,
} from '../../../../../../shared/state/ducks/feed-usage/reducer';
import { localized, localizedInterpolation } from '../../../../../../shared/state/i18n/i18n';
import { WebAppState } from '../../../../../state/store.web';
import { useGpeDashboard } from '../../../../pages/dashboard-overview-growth-pigs/dashboard-overview-growth-pigs';
import { ChartPoint, Graph } from '../../../graph/graph';
import { SkioldModal } from '../../../skiold-components/skiold-modal/skiold-modal';
import { Heading } from '../../../utils/heading';
import { ViewWeb } from '../../../utils/web-view';
import { AdjustmentsModal } from './adjustments-modal';
import './feed-usage-graph.scss';
import { FeedUsagePenSlider } from './feed-usage-pen-slider';
import { SkioldDatePicker } from '../../../skiold-components/skiold-date-picker/skiold-date-picker';
import { SkioldButton } from '../../../skiold-components/skiold-button/skiold-button';
import { Spinner } from 'web/view/components/spinner/spinner';

export const FeedUsageGraph: FC = React.memo(() => {
	const dispatch = useDispatch();
	const { isGraphModalOpen, closeGraphModal, sectionId, penId, productionType } = useGpeDashboard();
	const section = useSelector((state: WebAppState) => state.locations.sections.find(s => s.id === sectionId));
	const graphDto = useSelector(selectFeedUsageWeanerGraph);
	const pending = useSelector(selectFeedUsagePending);
	const { growthPigTuneProductions } = useSelector((state: WebAppState) => state.growthPigTuneProduction);

	const [data, setData] = useState<ChartPoint[]>([]);
	const [dataSets, setDataSets] = useState<ChartDataset<ChartType, number[] | ChartPoint[]>[]>([]);
	const [fromDate, setFromDate] = useState(moment().subtract(30, 'days'));
	const [toDate, setToDate] = useState(moment());

	const [adjustmentsOnDate, setAdjustmentsOnDate] = useState<IAdjustmentDTO[] | undefined>([]);
	const [adjustmentDate, setAdjustmentDate] = useState(moment());
	const [adjustmentModalOpen, setAdjustmentModalOpen] = useState(false);
	const [dateChanged, setDateChanged] = useState(false);

	const lastBalanced = useMemo(() => {
		// Set Header data
		if (growthPigTuneProductions && growthPigTuneProductions.length > 0) {
			let fitlered = growthPigTuneProductions.filter(
				tune =>
					productionType === tune.animalType &&
					((tune.sectionId === sectionId && tune.penId === penId) ||
						(tune.sectionId === sectionId && !penId)),
			);
			let latestDate =
				fitlered && fitlered.length > 0
					? fitlered.reduce((a, b) => {
							return moment(a.date!) > moment(b.date!) ? a : b;
					  })
					: undefined;
			return latestDate && latestDate.date ? moment(latestDate.date) : moment().subtract(30, 'days');
		}
		return moment().subtract(30, 'days');
	}, [productionType, growthPigTuneProductions, sectionId, penId]);

	useEffect(() => {
		if (!isGraphModalOpen) {
			return;
		}

		setFromDate(lastBalanced);
		setDateChanged(true);
		// eslint-disable-next-line
	}, [lastBalanced, isGraphModalOpen]);

	useEffect(() => {
		return () => {
			dispatch(resetGraph());
		};
	}, [dispatch]);

	useEffect(() => {
		if (section?.id === undefined || !isGraphModalOpen || pending || section.siteId === undefined || !dateChanged) {
			return;
		}
		if (penId) {
			dispatch(
				getByPenForGraph({
					siteId: section.siteId,
					penId: penId,
					fromDate: fromDate.toDate(),
					toDate: toDate.toDate(),
				}),
			);
		} else {
			dispatch(
				getGraphForSection({
					siteId: section.siteId,
					sectionId: section.id,
					fromDate: fromDate.toDate(),
					toDate: toDate.toDate(),
				}),
			);
		}
		setDateChanged(false);
		// eslint-disable-next-line
	}, [isGraphModalOpen, penId, fromDate, toDate, dateChanged, pending, section]);

	useEffect(() => {
		const extraDataSets: ChartDataset<ChartType, number[] | ChartPoint[]>[] = [];
		const feedCurve = mapToGraphLine(graphDto?.feedUnitsCurve ?? {});
		const weights = mapToGraphLine(graphDto?.weights ?? {});
		const feedUnitsPerDay = mapToGraphLine(graphDto?.feedUnitsPerDay ?? {});

		extraDataSets.push({
			data: weights,
			label: localized('Weight'),
			backgroundColor: Colors.controllersBlue,
			borderColor: Colors.controllersBlue,
			pointBackgroundColor: Colors.controllersBlue,
			yAxisID: 'weight',
			fill: false,
			pointRadius: 0,
		});

		extraDataSets.push({
			data: feedCurve,
			label: localized('feedCurve'),
			backgroundColor: Colors.yellow,
			borderColor: Colors.yellow,
			pointBackgroundColor: Colors.yellow,
			yAxisID: 'y',
			fill: false,
			pointRadius: 0,
		});

		setDataSets(extraDataSets);
		setData(feedUnitsPerDay);
	}, [graphDto]);

	const closeAdjustmentModal = useCallback(() => {
		setAdjustmentModalOpen(false);
	}, []);

	const handleFromDateChanged = useCallback((newDate: Date) => {
		setFromDate(moment(newDate));
		setDateChanged(true);
	}, []);

	const handleToDateChanged = useCallback((newDate: Date) => {
		setToDate(moment(newDate));
		setDateChanged(true);
	}, []);

	const getGraphOptions = useCallback((): ChartOptions => {
		return {
			onClick: (e, items) => {
				const curveItem = items.find(i => i.datasetIndex === 0);
				if (curveItem === undefined || graphDto?.adjustments === undefined) {
					return;
				}
				const dataPointDate = data[curveItem.index].x;
				setAdjustmentsOnDate(graphDto?.adjustments[dataPointDate as string]);
				setAdjustmentModalOpen(true);
				setAdjustmentDate(moment(dataPointDate));
			},
			plugins: {
				legend: {
					display: true,
					labels: {
						color: Colors.lighterGrey,
						usePointStyle: true,
						padding: 20,
					},
					align: 'start',
					position: 'bottom',
				},
				tooltip: {
					callbacks: {
						label: tooltipItem => {
							const label = tooltipItem.dataset.label;
							const value = tooltipItem.parsed.y.toFixed(2);
							if (tooltipItem.datasetIndex === 1) {
								return `${label}: ${value} kg`;
							} else {
								return `${label}: ${value} ${localized('FE')}`;
							}
						},
					},
				},
			},

			scales: {
				x: {
					title: {
						color: Colors.lighterGrey,
						display: true,
						text: localized('Date'),
					},
					type: 'time',
					ticks: {
						color: Colors.lighterGrey,
						display: true,
						minRotation: 45,
					},
					display: true,
					time: {
						minUnit: 'day',
						displayFormats: {
							day: 'DD/MM',
							color: Colors.lighterGrey,
						},
						tooltipFormat: 'DD/MM/YYYY',
					},
					grid: {
						color: Colors.lighterGrey,
						borderDash: [2, 2],
					},
				},
				y: {
					suggestedMin: 0,
					title: {
						color: Colors.lighterGrey,
						display: false,
						text: localized('FE'),
						align: 'end',
					},
					grid: {
						display: true,
						color: Colors.lighterGrey,
						borderDash: [2, 2],
					},
					ticks: {
						color: Colors.lighterGrey,
						display: true,
						callback: tickValue => `${parseFloat(tickValue as string).toFixed(1)} ${localized('FE')}`,
						maxTicksLimit: 20,
					},
				},
				weight: {
					suggestedMin: 0,
					title: {
						color: Colors.lighterGrey,
						display: false,
						text: 'Kg',
					},
					grid: {
						display: false,
						color: Colors.lighterGrey,
						borderDash: [2, 2],
					},
					position: 'right',
					ticks: {
						color: Colors.lighterGrey,
						display: true,
						callback: tickValue => `${parseFloat(tickValue as string).toFixed(2)} Kg`,
						stepSize: 10,
						maxTicksLimit: 20,
					},
				},
			},
			elements: {
				line: {
					tension: 0,
					borderWidth: 2,
				},
				point: {
					radius: ctx => {
						if (graphDto?.adjustments && ctx.raw) {
							return graphDto?.adjustments[(ctx.raw as ChartPoint).x as string] === undefined ? 0 : 3;
						}
						return 0;
					},
				},
			},
		};
	}, [data, graphDto]);

	return (
		<SkioldModal
			shouldCloseOnOverlayClick={true}
			overflowNone={true}
			isOpen={isGraphModalOpen}
			close={closeGraphModal}
			className={'feed-usage-graph-container'}
			backgroundImage={'dark-background-graphic.jpg'}
		>
			<Heading text={localizedInterpolation('DailyFeedUsageInt', section?.name ?? '')} />
			<ViewWeb className={'header-container'}>
				<ViewWeb className={'header-buttons'}>
					<TextWeb>{localized('FromDate')}: </TextWeb>
					<SkioldDatePicker
						onDateChanged={handleFromDateChanged}
						selectedDate={fromDate.toDate()}
						maxDate={new Date()}
						textFieldClassName={'date-display'}
					/>
					<TextWeb>{localized('ToDate')}: </TextWeb>
					<SkioldDatePicker
						onDateChanged={handleToDateChanged}
						selectedDate={toDate.toDate()}
						maxDate={new Date()}
						textFieldClassName={'date-display'}
					/>
				</ViewWeb>
				<ViewWeb className={'feed-usage-slider-container'}>
					<FeedUsagePenSlider />
				</ViewWeb>
				<ViewWeb className={'from-date-info'}>
					<TextWeb>{localized('lastBalanced')}: </TextWeb>
					<TextWeb className={'date-display'}> {lastBalanced.format('DD/MM/YY')}</TextWeb>
				</ViewWeb>
			</ViewWeb>
			<ViewWeb>
				{pending && <Spinner />}
				{isGraphModalOpen && (
					<Graph
						data={data}
						extraDataSets={dataSets}
						lineColor={Colors.lightGreen}
						lineAreaColor={Colors.lightGreen}
						height={'50vh'}
						width={'100%'}
						datasetLabel={`${localized('FE')}/${localized('Day')}`}
						datasetScaleId={'y'}
						options={getGraphOptions()}
					/>
				)}
			</ViewWeb>
			<ViewWeb className={'button-view-style'}>
				<SkioldButton title={localized('Close')} onPress={closeGraphModal} theme={'grey'} />
			</ViewWeb>
			<AdjustmentsModal
				adjustmentModalOpen={adjustmentModalOpen}
				closeAdjustmentModal={closeAdjustmentModal}
				adjustments={adjustmentsOnDate}
				date={adjustmentDate}
			/>
		</SkioldModal>
	);
});

function mapToGraphLine(dict: { [key: string]: number }) {
	return Object.entries(dict).map(([key, value]) => ({ x: key, y: value })) || [];
}
