import { Action } from 'redux';
import { IMatingBatch, MatingBatch } from 'shared/api/api';
import {
	mergeArrays,
	removeMultipleValuesFromArray,
	upsertMultipleValuesInArray,
} from 'shared/helpers/reducer-helpers';
import { SyncableInitialState } from 'shared/state/models/syncable';
import { SharedAppState } from 'shared/state/store.shared';
import { isType } from 'typescript-fsa';
import { siteChange } from '../profile/actions';
import { getSyncModelData } from '../sync/actions';
import { getSyncData, saveMatingBatches, deleteMatingBatchesByDate } from './actions';
import { MatingBatchState } from './types';
export const initialState: MatingBatchState = {
	...SyncableInitialState,
};

const matingBatchReducer = (state: MatingBatchState = initialState, action: Action): MatingBatchState => {
	if (isType(action, getSyncData.started)) {
		state = { ...state, syncInProgress: true };
	}

	if (isType(action, getSyncData.done)) {
		if (action.payload.params.siteId === action.payload.params.activeSiteId) {
			if (action.payload.result && action.payload.result.datas!.length) {
				state = {
					...state,
					entities: mergeArrays(state.entities, action.payload.result.datas!),
					lastSyncDate: action.payload.result.syncTime!,
				};
			}

			state = {
				...state,
				syncInProgress: false,
			};
		} else {
			state = {
				...state,
				syncInProgress: false,
			};
		}
	}

	if (isType(action, getSyncModelData.done)) {
		if (action.payload.params.siteId === action.payload.params.activeSiteId) {
			if (action.payload.result.matingBatches && action.payload.result.matingBatches.datas!.length) {
				state = {
					...state,
					entities: mergeArrays(state.entities, action.payload.result.matingBatches.datas!),
					lastSyncDate: action.payload.result.matingBatches.syncTime!,
				};
			}

			state = {
				...state,
				syncInProgress: false,
			};
		} else {
			state = {
				...state,
				syncInProgress: false,
			};
		}
	}

	if (isType(action, getSyncData.failed)) {
		state = { ...state, syncInProgress: false };
	}

	if (isType(action, saveMatingBatches.started)) {
		let earliestBatch = action.payload.data.sort((a, b) => {
			return a.matingPeriodStart!.getTime() - b.matingPeriodStart!.getTime();
		})[0];
		if (earliestBatch) {
			const extractedIds = state.entities.map(x => {
				if (x.matingPeriodStart! >= earliestBatch.matingPeriodStart!) {
					return x.id!;
				}
				return '';
			});
			state = {
				...state,
				updates: removeMultipleValuesFromArray(state.updates, extractedIds),
				entities: removeMultipleValuesFromArray(state.entities, extractedIds),
			};
		}

		state = {
			...state,
			updates: upsertMultipleValuesInArray(state.updates, action.payload.data),
			entities: upsertMultipleValuesInArray(state.entities, action.payload.data),
			saveSyncInProgress: true,
		};
	}

	if (isType(action, saveMatingBatches.failed)) {
		state = { ...state, saveSyncInProgress: false };
		return state;
	}

	if (isType(action, saveMatingBatches.done)) {
		const extractedIds = action.payload.params.data.map(({ id }) => id!);
		state = {
			...state,
			updates: removeMultipleValuesFromArray(state.updates, extractedIds),
			saveSyncInProgress: false,
		};
		return state;
	}

	if (isType(action, siteChange.done)) {
		state = initialState;
	}

	if (isType(action, deleteMatingBatchesByDate.started)) {
		if (action.payload.deletionDate) {
			state = {
				...state,
				entities: state.entities.filter(
					batch => batch.matingPeriodStart && batch.matingPeriodStart < action.payload.deletionDate,
				),
			};
		}
		state = { ...state, syncInProgress: true };
	}

	if (isType(action, deleteMatingBatchesByDate.done)) {
		state = {
			...state,
			syncInProgress: false,
		};
	}

	if (isType(action, deleteMatingBatchesByDate.failed)) {
		state = { ...state, syncInProgress: false };
	}

	//Ensure Date objects are in fact date and not strings - redux-persist serializes dates to string but doesn't deserialize to dates again
	if (action.type === 'persist/REHYDRATE') {
		let a = ((action as any) as { payload: SharedAppState; key: string }) as any;

		if (a.key === 'root') {
			let entities = new Array<MatingBatch>();
			let updates = new Array<MatingBatch>();
			let lastSyncDate = new Date(-8640000000000000);

			if (a.payload && a.payload.matingBatches && a.payload.matingBatches.entities) {
				entities = a.payload.matingBatches.entities.map((dt: IMatingBatch) => {
					let ndt = MatingBatch.fromJS({});
					ndt.init(dt);
					return ndt;
				});
			}

			if (a.payload && a.payload.matingBatches && a.payload.matingBatches.updates) {
				updates = a.payload.matingBatches.updates.map((dt: IMatingBatch) => {
					let ndt = MatingBatch.fromJS({});
					ndt.init(dt);
					return ndt;
				});
			}

			if (a.payload && a.payload.matingBatches && a.payload.matingBatches.lastSyncDate) {
				lastSyncDate = new Date(a.payload.matingBatches.lastSyncDate);
			}

			state = { ...state, entities, updates, lastSyncDate };
		}
	}

	return state;
};

export default matingBatchReducer;
