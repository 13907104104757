import { NucleusManagementArchiveState } from './types';
import { createReducer } from '@reduxjs/toolkit';
import { getNucleusManagementArchiveFiles, resendNucleusManagementArchiveFiles } from './operation';
import { SharedAppState } from '../../store.shared';

const initialState: NucleusManagementArchiveState = {
	archiveFiles: [],
	loading: false,
};

const nucleusManagementArchiveReducer = createReducer(initialState, builder => {
	builder.addCase(getNucleusManagementArchiveFiles.fulfilled, (state, action) => {
		if (action.payload) {
			state.archiveFiles = action.payload;
		}

		state.loading = false;
	});

	builder.addCase(getNucleusManagementArchiveFiles.pending, state => {
		state.loading = true;
	});

	builder.addCase(getNucleusManagementArchiveFiles.rejected, state => {
		state.loading = false;
	});

	builder.addCase(resendNucleusManagementArchiveFiles.fulfilled, state => {
		state.loading = false;
	});

	builder.addCase(resendNucleusManagementArchiveFiles.pending, state => {
		state.loading = true;
	});

	builder.addCase(resendNucleusManagementArchiveFiles.rejected, state => {
		state.loading = false;
	});
});

export const selectNucleusManagementArchiveFiles = (state: SharedAppState) =>
	state.nucleusManagementArchive.archiveFiles;

export default nucleusManagementArchiveReducer;
