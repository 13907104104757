import ObjectID from 'bson-objectid';
import { Dispatch } from 'redux';
import { ILardScanningEvent, LardScanningEvent } from 'shared/api/api';
import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import { StoreContainer } from 'shared/state/store-container';
import * as Action from './actions';
export function GetSyncData() {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;
	const lastSyncDate = state.lardScanningEvents.lastSyncDate;

	if (state.lardScanningEvents.syncInProgress) {
		return (dispatch: Dispatch<any>) => {
			return Promise.resolve();
		};
	}
	return AsyncOperationBuilder2(Action.getSyncData, client => client.lardScanningEvent_Sync(siteId, lastSyncDate), {
		siteId,
		lastSyncDate,
	});
}
export function SaveLardScanningEvent(lard: ILardScanningEvent) {
	const state = StoreContainer.getState();
	const prevEntity = state.lardScanningEvents.entities.find(currentLard => currentLard.id === lard.id);

	if (!lard.id) {
		lard.id = new ObjectID().toHexString();
	}

	return AsyncOperationBuilder2(
		Action.saveLardScanningEvent,
		client => client.lardScanningEvent_Upsert(LardScanningEvent.fromJS(lard)),
		LardScanningEvent.fromJS(lard),
		prevEntity,
	);
}

export function RemoveLardEvent(id: string) {
	return (dispatch: Dispatch<any>) => {
		dispatch(Action.removeLardScanningEvent.started(id));
	};
}

export function GetDeparturedLardEvents() {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;

	return AsyncOperationBuilder2(
		Action.getDeparturedLardEvents,
		client => client.lardScanningEvent_GetDepaturedLardScanningsBySiteId(siteId),
		{ siteId },
	);
}

export function SaveSyncData() {
	const state = StoreContainer.getState();
	const updates = state.lardScanningEvents.updates;
	let promises = new Array<Promise<void>>();
	return (dispatch: Dispatch<any>) => {
		if (state.lardScanningEvents.saveSyncInProgress) {
			return Promise.resolve();
		}
		updates.forEach(update => {
			promises.push(SaveLardScanningEvent(update)(dispatch));
		});

		return Promise.all(promises);
	};
}
