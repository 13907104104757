export const SkioldDigitalApiBaseUrl = process.env.REACT_APP_SKIOLD_DIGITAL_API_BASEPATH;
export const InstrumentationKey = process.env.REACT_APP_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY!;
export const CloudRoleName = process.env.REACT_APP_CLOUD_ROLE_NAME!;
export const BuildEnvironment = process.env.REACT_NATIVE_SKIOLD_ENVIRONMENT;
export const B2C_CLIENT_ID = process.env.REACT_APP_B2C_CLIENT_ID!;
export const B2C_REDIRECT_URL = `${process.env.REACT_NATIVE_RECIRECT_SCHEME}://oauth2callback`;

export const AuthPostSignInRedirectUri = `${process.env.REACT_APP_BASE_URL}/app/callback`;
export const AuthReauthorizeRedirectUri = `${process.env.REACT_APP_BASE_URL}/reauthorize.html`;
export const AuthPostSignOutRedirectUri = `${process.env.REACT_APP_BASE_URL}/app/signin`;
export const TicanRedirectUri = process.env.REACT_APP_TICAN_CALLBACK;
export const TicanAuthUrl = process.env.REACT_APP_TICAN_AUTH_URL;
export const TicanClientId = process.env.REACT_APP_TICAN_CLIENT_ID;
export const PreAuthRedirectLocationKey = 'preAuthRedirectLocation';
export const AuthClientSecret = '7}qt1$1X218jQrn>DTS9nlJu';

export class LicenceIds {
	public static NonSubscribable = 'non-subscribable';
	public static SowManagement = '7b857ac5-68de-4f3f-a263-8d3b68755766';
	public static SowAdjustFeed = '9312f486-952e-4276-84d7-daf6c6dda6d1';
	public static FinisherManagement = 'f5838b42-4f8b-4734-a352-dd61ed098ebb';
	public static FinisherAdjustFeed = '26b3f714-34ac-4d02-a7f7-196b8d6db84e';
	public static ClimatePigManagement = '7e795932-ee3c-4843-8cfe-14d034b8e3cd';
	public static ClimatePigAdjustFeed = '312ce3de-d074-48c5-8473-907d33a6a6be';
	public static NucleusManagementDanBred = '7daa3849-b3a2-4a82-9072-ef79c23d9812';
	public static NucleusManagementDanishGenetics = 'c537f757-b715-4d5e-a331-dafa2032bd32';
	public static NucleusManagementSelfManaged = '983760fc-3920-4ba0-bc10-c3dcc95b67c9';
}

export const AssignIdAccess = 'AssignIdAccess';

export class ModulesIds {
	public static Alarm = '2fc1fafe-27c4-4eb7-9dfd-88a7b48f55ed';
	public static ClimatePig = 'a807b72e-1749-4532-a394-97ad73bb0b92';
	public static FeedComputersModule = '469b2e11-56c1-4bfb-814b-2ffc7bde0977';
	public static FinisherModule = 'd24682b7-99b3-4ac7-9d27-439ef6d06a24';
	public static NucleusManagement = '485ad8f4-6889-4eab-8d09-4b346558a475';
	public static SettingsModule = 'edb8ed87-8602-4cd6-8ea9-8f5e23937242';
	public static SowModule = 'd2147273-8a50-4ea4-8366-aeea3232bc6f';
	public static Warning = '5295daff-aa09-4097-b086-70b917858f75';
}

export const preferedWidgetOrder: string[] = [
	'4e75fb16-5e09-4f06-9adf-b84287460a39',
	ModulesIds.SowModule,
	ModulesIds.ClimatePig,
	ModulesIds.FinisherModule,

	'stock',
]; //add widget modal order

export const graphColors: string[] = ['darkgray', 'lightgray', 'slategray', 'gray', 'dimgray', 'darkslategrey'];

export const AccessTokenKey = 'SKIOLD_ACCESS_TOKEN';
export const AccessTokenExpireKey = 'SKIOLD_ACCESS_EXPIRE';
export const RefreshTokenKey = 'SKIOLD_REFRESH_TOKEN';
export const RefreshTokenExpireKey = 'SKIOLD_REFRESH_EXPIRE';
export const IdTokenKey = 'SKIOLD_ID_TOKEN';
export const JwtAdminExtension = 'extension_Role';

export const SkioldDigitalResetPasswordPolicy = 'B2C_1_defaultresetpassword';
export const SkioldDigitalSigninPolicy = 'B2C_1_default';
export const DefaultSkioldDigitalSigninPolicy = '?p=B2C_1_default';

//export const defaultB2CTentant = "iteratorit"
export const defaultB2CTentant = 'skioldb2c';

export const B2C_AUTHORIZATION_ENDPOINT =
	'https://' + defaultB2CTentant + '.b2clogin.com/' + defaultB2CTentant + '.onmicrosoft.com/oauth2/v2.0/authorize';
export const B2C_TOKEN_ENDPOINT =
	'https://' + defaultB2CTentant + '.b2clogin.com/' + defaultB2CTentant + '.onmicrosoft.com/oauth2/v2.0/token';
export const B2C_TOKEN_REVOCATION_ENDPOINT =
	'https://' + defaultB2CTentant + '.b2clogin.com/' + defaultB2CTentant + '.onmicrosoft.com//oauth2/v2.0/logout';
export const B2C_TOKEN_DISCOVERY_ENDPOINT =
	'https://' +
	defaultB2CTentant +
	'.b2clogin.com/tfp/' +
	defaultB2CTentant +
	'.onmicrosoft.com/' +
	SkioldDigitalSigninPolicy +
	'/v2.0/.well-known/openid-configuration';

// Old endpoints
// export const B2C_AUTHORIZATION_ENDPOINT="https://login.microsoftonline.com/e9b265dd-5520-4099-bb1b-d431963fa178/oauth2/v2.0/authorize";
// export const B2C_TOKEN_ENDPOINT="https://login.microsoftonline.com/e9b265dd-5520-4099-bb1b-d431963fa178/oauth2/v2.0/token";
// export const B2C_TOKEN_REVOCATION_ENDPOINT="https://login.microsoftonline.com/e9b265dd-5520-4099-bb1b-d431963fa178/oauth2/v2.0/logout";
// export const B2C_TOKEN_DISCOVERY_ENDPOINT="https://login.microsoftonline.com/e9b265dd-5520-4099-bb1b-d431963fa178/v2.0/.well-known/openid-configuration";

export const B2C_TOKEN_ENDPOINT_POLICY = `${B2C_TOKEN_ENDPOINT}${DefaultSkioldDigitalSigninPolicy}`;
export const B2C_TOKEN_REVOCATION_ENDPOINT_POLICY = `${B2C_TOKEN_REVOCATION_ENDPOINT}${DefaultSkioldDigitalSigninPolicy}`;
export const B2C_AUTHORIZATION_ENDPOINT_POLICY = `${B2C_AUTHORIZATION_ENDPOINT}${DefaultSkioldDigitalSigninPolicy}`;
export const B2C_TOKEN_DISCOVERY_ENDPOINT_POLICY = `${B2C_TOKEN_DISCOVERY_ENDPOINT}${DefaultSkioldDigitalSigninPolicy}`;
//Iterator
//export const IdenitiyExperienceFrameworkId = "7ef41113-b078-4ba2-a811-7b2890a2f9c6";

//Skiold
//export const IdenitiyExperienceFrameworkId = '58cf8e8e-9a56-41f8-b55f-0efe8ffd5011';
export const B2C_API_SCOPE =
	'https://' + defaultB2CTentant + '.onmicrosoft.com/' + process.env.REACT_APP_B2C_API + '/user_impersonation';
// export const B2C_API_SCOPE=`https://dgskioldone.onmicrosoft.com/${process.env.REACT_APP_B2C_API}/user_impersonation`;

export const IsDevelopment = BuildEnvironment === 'Development';
export const IsTest = BuildEnvironment === 'Test';
