import { Action } from 'redux';
import { ProductionReportSetting, AnimalType, PReportPeriod } from 'shared/api/api';
import { SyncableInitialState } from 'shared/state/models/syncable';
import { SharedAppState } from 'shared/state/store.shared';
import { isType } from 'typescript-fsa';
import { siteChange } from '../profile/actions';
import * as actions from './actions';
import { saveProductionReportSetting } from './actions';
import { ProductionReportSettingState } from './types';

export const initialState: ProductionReportSettingState = {
	...SyncableInitialState,
};

const productionReportSettingReducer = (
	state: ProductionReportSettingState = initialState,
	action: Action,
): ProductionReportSettingState => {
	if (isType(action, actions.getSyncData.started)) {
		state = { ...state, syncInProgress: true };
	}

	if (isType(action, actions.getSyncData.done)) {
		if (action.payload.result.datas) {
			state = {
				...state,
				entities: action.payload.result.datas,
			};
		}

		state = {
			...state,
			lastSyncDate: action.payload.result.syncTime!,
			syncInProgress: false,
		};
	}

	if (isType(action, actions.getSyncData.failed)) {
		state = { ...state, syncInProgress: false };
	}

	if (isType(action, saveProductionReportSetting.started)) {
		state = { ...state };
		const data = [...state.entities];
		if (action.payload.animaltype !== AnimalType.Sow && action.payload.periods) {
			let fromDates = action.payload.periods
				.filter(e => e.fromDate !== undefined && e.periodNumber !== 5)
				.map(e => e.fromDate!);
			let toDates = action.payload.periods
				.filter(e => e.toDate !== undefined && e.periodNumber !== 5)
				.map(e => e.toDate!);
			let min =
				fromDates && fromDates.length > 0
					? fromDates.reduce( (a, b) => {
							return a < b ? a : b;
					  })
					: undefined;
			let max =
				toDates && toDates.length > 0
					? toDates.reduce( (a, b) => {
							return a > b ? a : b;
					  })
					: undefined;

			if (action.payload.periods.length < 5) {
				action.payload.periods.push({
					periodNumber: 5,
				} as PReportPeriod);
			}
			let period5 = action.payload.periods[4];
			period5.fromDate = min;
			period5.toDate = max;
			action.payload.periods[4] = period5;
		}
		const dataIndexToModify = data.findIndex(d => d.animaltype === action.payload.animaltype);
		if (dataIndexToModify >= 0) {
			data[dataIndexToModify] = action.payload;
		} else {
			data.push(action.payload);
		}

		state.entities = data;
	}

	if (isType(action, saveProductionReportSetting.failed)) {
		if (action.payload.error.code === 500) {
			if (action.payload.error.prevEntity) {
				const data = [...state.entities];
				const dataIndexToModify = data.findIndex(
					d => action.payload.error.prevEntity && d.animaltype === action.payload.error.prevEntity.animaltype,
				);
				data[dataIndexToModify] = action.payload.error.prevEntity;
				state.entities = data;
			}
		}

		return state;
	}

	if (isType(action, saveProductionReportSetting.done)) {
		state = { ...state };
		if (!action.payload.params.isDeleted) {
			const data = [...state.entities];
			const dataIndexToModify = data.findIndex(d => d.animaltype === action.payload.params.animaltype);
			data[dataIndexToModify] = action.payload.params;
			state.entities = data;
		}

		const data = [...state.updates];
		const dataIndexToModify = data.findIndex(d => d.animaltype === action.payload.params.animaltype);
		data[dataIndexToModify] = action.payload.params;
		state.updates = data;

		return state;
	}

	if (isType(action, siteChange.done)) {
		state = initialState;
	}

	//Ensure Date objects are in fact date and not strings - redux-persist serializes dates to string but doesn't deserialize to dates again
	if (action.type === 'persist/REHYDRATE') {
		let a = (action as any) as { payload: SharedAppState; key: string };
		if (a.key === 'root') {
			let entities: ProductionReportSetting[] = [];
			let updates: ProductionReportSetting[] = [];
			let lastSyncDate = new Date(-8640000000000000);

			if (a.payload && a.payload.productionReportSetting && a.payload.productionReportSetting.entities) {
				entities = a.payload.productionReportSetting.entities as ProductionReportSetting[];
			}

			if (a.payload && a.payload.productionReportSetting && a.payload.productionReportSetting.updates) {
				updates = a.payload.productionReportSetting.updates as ProductionReportSetting[];
			}

			if (a.payload && a.payload.productionReportSetting && a.payload.productionReportSetting.lastSyncDate) {
				lastSyncDate = new Date(a.payload.productionReportSetting.lastSyncDate);
			}

			state = { ...state, entities, updates, lastSyncDate };
		}
	}

	return state;
};

export default productionReportSettingReducer;
