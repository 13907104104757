import React from 'react';
import { Option } from 'react-dropdown';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
	AnimalType,
	Building,
	IBuilding,
	IPen,
	ISection,
	LocationType,
	Pen,
	ProductionForm,
	Section,
} from 'shared/api/api';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { deepCopy } from 'shared/helpers/general-helpers';
import { generateAnimalType, generateLocationType } from 'shared/helpers/location-helper';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { UpsertBuilding, UpsertPen, UpsertSection } from 'shared/state/ducks/locations/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import PageContainer from 'web/view/components/page-container/page-container';
import { showAlert } from 'web/view/components/skiold-alert/skiold-alert';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldCheckbox } from 'web/view/components/skiold-components/skiold-checkbox/skiold-checkbox';
import { SkioldFormDropdown } from 'web/view/components/skiold-components/skiold-dropdown/skiold-form-dropdown';
import { SkioldFormsWrapper } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { SkioldFormInput } from 'web/view/components/skiold-components/skiold-input/skiold-form-input';
import { SkioldFormIntegerInput } from 'web/view/components/skiold-components/skiold-integer-input/skiold-form-integer-input';
import './edit-building.scss';
import { validateBuilding } from 'web/web-helpers/web-location-helper';

interface PropsFromParent {
	building: IBuilding;
	closeEditModal?: () => void;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		buildings: state.locations.buildings,
		siteId: state.profile.active!.siteId,
		sections: state.locations.sections,
		pens: state.locations.pens,
	};
};
const DefaultEntranceTypeOption: Option = { label: ' ', value: '' };

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	updateBuilding: (building: IBuilding) => UpsertBuilding(building)(dispatch),
	updateSection: (section: ISection) => UpsertSection(section)(dispatch),
	updatePen: (pen: IPen) => UpsertPen(pen)(dispatch),
});

interface EditBuildingState {
	buildingToEdit: IBuilding;
	productionForms: Option[];
	selectedProductionForm: Option;
	selectedLocationType: Option;
	selectedAnimalType: Option;
	animalTypes: Option[];
	locationTypes: Option[];
	fakeSection: ISection;
	fakePen: IPen;
}
export type EditBuildingProps = ReturnType<typeof mapStateToProps> &
	ReturnType<typeof mapDispatchToProps> &
	PropsFromParent;
export class EditBuilding extends React.PureComponent<EditBuildingProps, EditBuildingState> {
	constructor(props: EditBuildingProps) {
		let sec = props.sections.find(sec => sec.buildingId === props.building.id);
		if (!sec) {
			sec = Section.fromJS({});
		}
		super(props);
		this.state = {
			buildingToEdit: props.building,
			productionForms: this.generateProductionForm(),
			selectedProductionForm: props.building
				? { value: props.building.productionForm!, label: localized(props.building.productionForm!) }
				: DefaultEntranceTypeOption,
			animalTypes: generateAnimalType(undefined),
			selectedAnimalType: sec
				? { value: sec.animalType!, label: localized(sec.animalType!) }
				: DefaultEntranceTypeOption,
			locationTypes: [],
			selectedLocationType: sec ? { value: sec.type!, label: localized(sec.type!) } : DefaultEntranceTypeOption,
			fakeSection: sec,
			fakePen: !props.building.useSections
				? props.pens.find(pen => pen.buildingId === props.building.id)!
				: Pen.fromJS({}),
		};
	}

	public buildingNumberChanged(buildingNumber: number | undefined) {
		this.setState(prevState => ({
			buildingToEdit: { ...prevState.buildingToEdit, buildingNumber } as IBuilding,
		}));
	}

	public buildingNameChanged(buildingName: string) {
		this.setState(prevState => ({ buildingToEdit: { ...prevState.buildingToEdit, name: buildingName } }));
	}

	public setProductionForm(productionForm: Option) {
		this.setState({ selectedProductionForm: productionForm });
	}

	public toggleIsUsingQRCode() {
		const toggle = deepCopy(this.state.buildingToEdit.isUsingQRCode);
		this.setState(prevState => ({ buildingToEdit: { ...prevState.buildingToEdit, isUsingQRCode: !toggle } }));
	}

	public capacityChanged(capacity: number | undefined) {
		this.setState(prevState => ({ fakePen: { ...prevState.fakePen!, capacity: capacity! } }));
	}

	public setAnimalType(animalType: Option) {
		const locationTypes = generateLocationType(this.state.buildingToEdit.productionForm, animalType.value);
		this.setState(prevState => ({
			fakeSection: { ...prevState.fakeSection!, animalType: animalType.value as AnimalType },
			selectedAnimalType: animalType,
			locationTypes,
		}));
	}
	public setLocationType(locationType: Option) {
		this.setState(prevState => ({
			fakeSection: { ...prevState.fakeSection!, type: locationType.value as LocationType },
			selectedLocationType: locationType,
		}));
	}

	public render() {
		return (
			<PageContainer>
				<ViewWeb className="edit-building">
					<ViewWeb className="forms-container">
						<ViewWeb className="z-index-container">
							<SkioldFormsWrapper formRows={this.getFormRows()} containerClassName="wrapper-container" />
						</ViewWeb>

						{this.renderButtons()}
					</ViewWeb>
				</ViewWeb>
			</PageContainer>
		);
	}

	public async save() {
		const editedBuilding = deepCopy(this.state.buildingToEdit);
		editedBuilding.productionForm = this.state.selectedProductionForm.value as ProductionForm;
		if (
			(await !validateBuilding(editedBuilding, this.props.buildings, undefined, undefined)) ||
			(await !this.validateNoSection())
		) {
			return;
		}
		if (!editedBuilding.useSections) {
			this.props.updateSection(this.state.fakeSection!);
			this.props.updatePen(this.state.fakePen!);
		}
		this.props.updateBuilding(editedBuilding);
		this.resetComponent();
	}

	private validateNoSection() {
		if (!this.state.buildingToEdit.useSections) {
			if (!this.state.fakeSection.animalType) {
				showAlert(localized(ExceptionMessage.VALIDATION_ERROR_FIELDS_REQUIRED));
				return false;
			}
			if (!this.state.fakeSection.type) {
				showAlert(localized(ExceptionMessage.VALIDATION_ERROR_SECTION_TYPE_NOT_SET));
				return false;
			}
			if (!this.state.fakePen!.capacity) {
				showAlert(localized(ExceptionMessage.VALIDATION_ERROR_FIELDS_REQUIRED));
				return false;
			}

			if (this.state.fakePen!.capacity! < 1) {
				showAlert(localized(ExceptionMessage.VALIDATION_ERROR_CANNOT_BE_NEGATIVE_OR_ZERO));
				return false;
			}
		}

		return true;
	}

	private getFormRows() {
		const animalType = generateAnimalType(this.state.buildingToEdit.productionForm);
		let formRows = new Array<FormRow>();
		formRows.push(this.getBuildingHeader());
		formRows.push(this.getBuildingNumberRow());
		formRows.push(this.getBuildingNameRow());
		formRows.push(this.getProductionRow());
		if (!this.state.buildingToEdit.useSections) {
			if (this.state.buildingToEdit.productionForm === ProductionForm.PigProduction) {
				formRows.push(this.getAnimalTypeRow(animalType));
			}
			formRows.push(this.getLocationTypeRow());
			formRows.push(this.getCapacityRow());
		}

		//TODO: Might to used later
		// formRows.push(this.getIsUsingQRCodeRow());
		return formRows;
	}

	private getAnimalTypeRow(animalTypes: Option[]): FormRow {
		return {
			name: localized('animalType'),
			component: (
				<SkioldFormDropdown
					items={animalTypes}
					selectedValue={this.state.selectedAnimalType}
					onValueChanged={(animalTypeOption: Option) => this.setAnimalType(animalTypeOption)}
				/>
			),
		};
	}

	private getLocationTypeRow(): FormRow {
		return {
			name: localized('locationType'),
			component: (
				<SkioldFormDropdown
					items={generateLocationType(
						this.state.buildingToEdit.productionForm,
						this.state.selectedAnimalType.value,
					)}
					selectedValue={this.state.selectedLocationType}
					onValueChanged={(locationTypeOption: Option) => this.setLocationType(locationTypeOption)}
				/>
			),
		};
	}

	private getCapacityRow(): FormRow {
		return {
			name: localized('capacity'),
			component: (
				<SkioldFormIntegerInput
					onChangeNumber={newNumber => this.capacityChanged(newNumber)}
					text={this.state.fakePen!.capacity}
				/>
			),
		};
	}

	private getBuildingHeader(): FormRow {
		return {
			header: localized('EDITBUILDING'),
		};
	}

	private getBuildingNumberRow(): FormRow {
		return {
			name: localized('number'),
			component: (
				<SkioldFormIntegerInput
					onChangeNumber={newNumber => this.buildingNumberChanged(newNumber)}
					text={this.state.buildingToEdit.buildingNumber}
				/>
			),
		};
	}

	private getBuildingNameRow(): FormRow {
		return {
			name: localized('name'),
			component: (
				<SkioldFormInput
					onChangeText={newText => this.buildingNameChanged(newText)}
					text={this.state.buildingToEdit.name ? this.state.buildingToEdit.name + '' : ''}
				/>
			),
		};
	}

	private getProductionRow(): FormRow {
		return {
			name: localized('production'),
			component: (
				<SkioldFormDropdown
					items={this.state.productionForms}
					selectedValue={this.state.selectedProductionForm}
					onValueChanged={(productionOption: Option) => this.setProductionForm(productionOption)}
				/>
			),
		};
	}

	private getIsUsingQRCodeRow(): FormRow {
		return {
			name: localized('isUsingQRCode'),
			component: (
				<SkioldCheckbox
					onClick={() => {
						this.toggleIsUsingQRCode();
					}}
					isChecked={this.state.buildingToEdit.isUsingQRCode!}
				/>
			),
		};
	}

	private generateProductionForm() {
		return [
			{ value: '', label: localized('choose') },
			{ value: ProductionForm.PigProduction, label: localized('PigProduction') },
			{ value: ProductionForm.Mills, label: localized('Mills') },
		];
	}

	private renderButtons() {
		let buttons = (
			<ViewWeb className="button-container">
				{this.props.closeEditModal ? (
					<SkioldButton
						title={localized('Close')}
						onPress={() => this.props.closeEditModal!()}
						theme="grey"
					/>
				) : (
					<ViewWeb />
				)}

				<SkioldButton title={localized('Save')} onPress={() => this.save()} />
			</ViewWeb>
		);

		return buttons;
	}

	// Called after successful save to remove one-time values
	private async resetComponent() {
		this.setState({
			buildingToEdit: Building.fromJS({ name: '' } as IBuilding),
			selectedProductionForm: DefaultEntranceTypeOption,
		});
		if (this.props.closeEditModal) {
			this.props.closeEditModal();
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(EditBuilding);
