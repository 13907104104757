import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import { StoreContainer } from 'shared/state/store-container';
import { WebAppState } from 'web/state/store.web';
import * as Action from './actions';

export function GetSyncData() {
	const state = StoreContainer.getState() as WebAppState;
	const lastSyncDate = state.BMPDatas.lastSyncDate;

	return AsyncOperationBuilder2(Action.getSyncData, client => client.bMPData_Get(lastSyncDate), {
		lastSyncDate,
	});
}
