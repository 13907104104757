import React from 'react';

import { connect } from 'react-redux';
import LardScanningEventListTable from './lard-scanning-event-work-list-table';
import {
	LardScanningEventWorkListProps,
	LardScanningEventWorkListState,
	LardScanningEventWorkListmapStateToProps,
	LardScanningEventWorkListmapDispatchToProps,
} from 'shared/helpers/work-list-helpers/lard-scanning-event-list-helpers/lard-scanning-event-work-list-item';
import { ILardScanningEventListSettingBase } from '../../../../../shared/api/api';

export class LardScanningEventWorkList extends React.PureComponent<
	LardScanningEventWorkListProps,
	LardScanningEventWorkListState
> {
	constructor(props: LardScanningEventWorkListProps) {
		super(props);
		this.state = {
			workListSetting: props.workListSettings.find(
				setting => setting.type === props.choosedSetting,
			)! as ILardScanningEventListSettingBase,
		};
	}

	public render() {
		return (
			<LardScanningEventListTable
				ref={this.setRef}
                batch={this.props.batch}
				workListSetting={this.state.workListSetting}
				choosedSetting={this.props.choosedSetting}
			/>
		);
	}

	private setRef = (m: any) => (m ? this.props.refCallback(m) : {});
}

export default connect(LardScanningEventWorkListmapStateToProps, LardScanningEventWorkListmapDispatchToProps, null, {
	forwardRef: true,
})(LardScanningEventWorkList) as any;
